import { getAnalyticsUserPropertiesFromState } from '@/services/Analytics/getAnalyticsUserPropertiesFromState';
import { initializeApp } from 'firebase/app';
import { getAnalytics, setUserId, setUserProperties, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { analyticsService } from './new/service.web';
const app = initializeApp(window.firebaseConfig);
const analytics = getAnalytics(app);
async function trackEvent(event, data = {}) {
    const name = event.replace(/[-.]/g, '_');
    if (name !== 'screen_view') {
        await analyticsService.trackCustomEvent(event, data);
    }
}
function trackClick(step, type, data = {}) {
    trackEvent('click', {
        step,
        type,
        data,
    });
}
function trackPageView(pageName, pagePath) {
    trackEvent('screen_view', { firebase_screen: pageName, firebase_screen_class: pagePath });
}
function refreshUserProperties() {
    const analyticsUserProperties = getAnalyticsUserPropertiesFromState();
    setUserId(analytics, `${analyticsUserProperties.userId}`);
    setUserProperties(analytics, {
        app_version: window.appVersion.number,
        organization_id: `${analyticsUserProperties.organizationId}`,
        organization_name: `${analyticsUserProperties.organizationName}`,
        impersonated_user_id: `${analyticsUserProperties.impersonatedUserId}`,
    });
}
function setup() {
    setUserProperties(analytics, {
        app_version: window.appVersion.number,
    });
    setAnalyticsCollectionEnabled(analytics, true);
    refreshUserProperties();
}
export const WebAnalyticsService = {
    setup,
    refreshUserProperties,
    trackEvent,
    trackClick,
    trackPageView,
};

<template>
    <LayoutPage is-flyout class="checkout-summary" data-test="checkout-summary-page">
        <template #flyoutHeader>
            <LayoutHeader :subline="subline" />
        </template>

        <div class="container-off-canvas-sm bg-white">
            <ProductTitleSection v-bind="getSummary.productTitle" class="mt-4" />
            <HorizontalDivider class="mb-6 mt-12" />

            <OfferSection
                :prices-data="pricesData"
                :waste="getSummary.waste"
                :waste-description="getSummary.wasteDescription"
                :has-fixed-price="getSummary.hasFixedPrice"
                :is-hazardous="getSummary.isHazardous"
                @edit="editSection"
            />

            <NoteSection
                v-model="requestCollection.note"
                :name="'requestCollection.note'"
                :parent-validation-rules="validationRules"
                :show-divider="false"
                @input="updateNote"
            />
        </div>
        <div class="container-off-canvas-sm my-6">
            <span class="font-headline-lg">
                {{ $t('pages.checkout.summary.detailsHeadline') }}
            </span>
            <div>
                <ValidityAndStatusSection :validity-range="getSummary.validityRange" :status="getSummary.status" />
                <BaseButton
                    arrow-right
                    class="mt-2"
                    @click="
                        editSection({
                            section: 'validity',
                            routeName: 'checkout-project-disposal-hazardous-validity',
                        })
                    "
                    >{{ $t('pages.checkout.summary.actions.change.positionValidity') }}</BaseButton
                >
            </div>

            <ConstructionSite
                class="mt-12"
                :construction-site-address="getSummary.constructionSite.address"
                :construction-site-name="getSummary.constructionSite.name"
                :client-name="getSummary.client.name"
                @edit="editSection"
            />
            <GeneralInformation
                v-if="getSummary.isHazardous"
                class="mt-12"
                :info="getSummary.generalInformation"
                preview
                @edit="editSection"
            />
            <section class="mt-12">
                <DisposerCard has-title :disposer="getSummary.disposer" preview />
                <BaseButton
                    class="mt-2 w-full text-right"
                    arrow-right
                    @click="
                        editSection({
                            section: 'disposer',
                            routeName: 'checkout-project-disposal-hazardous-choose-disposer',
                        })
                    "
                >
                    {{ $t('pages.checkout.disposal.summary.actions.change.disposer') }}
                </BaseButton>
            </section>
            <WasteCard class="mt-12" :waste="getSummary.waste" preview @edit="editSection" />
            <WasteDescription
                class="mt-12"
                :waste="getSummary.wasteDescription"
                :is-hazardous="getSummary.isHazardous"
                preview
                @edit="editSection"
            />
            <AnalysisDocuments
                class="mt-12"
                :documents="getSummary.documents.documents"
                :other-documents="getSummary.documents.otherDocuments"
                :images="getSummary.documents.images"
                preview
                @edit="editSection"
            />
            <CarrierCard
                class="mt-12"
                :name="getSummary.carrier.name"
                :address="getSummary.carrier.address"
                :disposal-number="getSummary.carrier.disposalNumber"
                @edit="editSection"
            />
            <BillingMethodCard
                v-if="showBillingMethodCard"
                class="mt-12"
                :billing="getSummary.billing"
                :editable="false"
                @edit="editSection"
            />
        </div>

        <template #sticky>
            <SlideUp active>
                <ButtonGroup>
                    <BaseButton primary type="button" data-test="checkout-summary-confirm-button" @click="save()">
                        {{ $t('pages.checkout.summary.actions.placePosition') }}
                        <template #right>
                            <ArrowRightIcon class="icon--inline" />
                        </template>
                    </BaseButton>
                </ButtonGroup>
            </SlideUp>
        </template>
    </LayoutPage>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { navigationFailure } from '@/services/utils/router';

import ProjectApi from '@/services/Api/Project';

import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import validation from '@/services/validation/mixin';

import Toaster from '@/services/Toaster';

import LayoutPage from '@/components/Layout/Page.v2';
import SlideUp from '@/components/Animation/SlideUp.vue';
import BaseButton from '@/components/Button/Button.vue';
import ButtonGroup from '@/components/Button/ButtonGroup.vue';

import LayoutHeader from '@/pages/Checkout/components/Header.vue';
import ProductTitleSection from '@/pages/Checkout/SummaryPage/partials/ProductTitleSection';
import OfferSection from '@/pages/Checkout/Disposal/ProjectPosition/SummaryPage/OfferSection';
import NoteSection from '@/pages/Checkout/Disposal/ProjectPosition/SummaryPage/NoteSection';
import ConstructionSite from '@/pages/Checkout/SummaryPage/partials/ConstructionSite';
import DisposerCard from '@/pages/Checkout/Disposal/Components/DisposerCard.vue';
import WasteCard from '@/pages/Checkout/Disposal/ProjectPosition/SummaryPage/WasteCard';
import WasteDescription from '@/pages/Checkout/Disposal/ProjectPosition/SummaryPage/WasteDescription';
import AnalysisDocuments from '@/pages/Checkout/Disposal/ProjectPosition/SummaryPage/AnalysisDocuments';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import CarrierCard from '@/pages/Checkout/Disposal/ProjectPosition/SummaryPage/CarrierCard';
import BillingMethodCard from '@/pages/Checkout/Disposal/ProjectPosition/SummaryPage/BillingMethodCard';

import ArrowRightIcon from '@/assets/icons/micro/arrow.svg';
import GeneralInformation from '@/pages/Checkout/Disposal/ProjectPosition/SummaryPage/GeneralInformation';
import HorizontalDivider from '@/_components/HorizontalDivider/HorizontalDivider';
import ValidityAndStatusSection from '@/pages/Checkout/SummaryPage/partials/ValidityAndStatusSection';
import { useLd } from '@/services/LaunchDarkly';
import { CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE } from '@/modules/constructionProjects/pages/routes';

export default {
    name: 'SummaryPage',
    components: {
        ValidityAndStatusSection,
        GeneralInformation,
        BaseButton,
        ButtonGroup,
        SlideUp,
        LayoutPage,

        ArrowRightIcon,

        LayoutHeader,
        AnalysisDocuments,
        BillingMethodCard,
        CarrierCard,
        ProductTitleSection,
        OfferSection,
        NoteSection,
        WasteDescription,
        WasteCard,
        DisposerCard,
        ConstructionSite,
        HorizontalDivider,
    },
    mixins: [validation, statefulMixin, eventHubMixin],
    props: {},
    data() {
        return {
            isLoading: false,
            project: null,
            isAutomaticBGSCreationEnabled: useLd('automatic-bgs-document-creation'),
        };
    },
    computed: {
        ...mapState('projectPosition', [
            'error',
            'product',
            'disposer',
            'pricesData',
            'requestCollection',
            'documents',
        ]),
        ...mapGetters('projectPosition', ['getSummary', 'validationRules', 'validationGroups']),
        subline() {
            if (this.project) return `${this.project.number} (${this.project.name})`;
            return null;
        },
        showBillingMethodCard() {
            return !this.getSummary.isHazardous && this.getSummary.isDumpingSite;
        },
    },
    mounted() {
        this.getProjectInformation();
    },
    methods: {
        ...mapActions('projectPosition', ['postProjectPosition']),
        async getProjectInformation() {
            if (this.requestCollection.project.id) {
                this.project = await ProjectApi.getOneById(this.requestCollection.project.id);
            }
        },
        editSection($event) {
            let routeName = $event.routeName;
            if (this.requestCollection.type === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS) {
                routeName = $event.routeName.replace(/disposal-hazardous/gi, 'disposal-non-hazardous');
            }
            this.$router
                .push({
                    name: this.$root.findRouteName(routeName),
                })
                .catch(navigationFailure);
        },
        updateNote(note) {
            this.$store.commit('projectPosition/SET_REQUEST_COLLECTION', {
                requestCollection: { note },
                property: 'note',
            });
        },
        async save() {
            if (!this.isValid()) return;

            try {
                const order = await this.postProjectPosition({
                    isAutomaticBGSCreationEnabled: this.isAutomaticBGSCreationEnabled,
                });

                Toaster.success(
                    this.$t('pages.checkout.summary.projectPositionSuccessMessage', { number: order.number })
                );

                this.$router
                    .push({
                        name: this.$root.findRouteName(CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE),
                        params: {
                            projectId: order.project.id,
                        },
                        query: this.$route.query,
                    })
                    .catch(navigationFailure);

                this.$store.commit('basket/reset');
                this.$store.commit('projectPosition/RESET');
            } catch (error) {
                Toaster.error(error);
                this.$store.commit('basket/setServerError', error);
            }
        },
    },
};
</script>

import { CONSTRUCTION_PROJECT_LIST_ROUTE } from '@/constructionProjects/constants';
const initialState = {
    constructionProjectId: null,
    entryPoint: null,
    detailsPageEntryPoint: null,
};
const getters = {
    constructionProjectId: state => state.constructionProjectId,
    entryPoint: state => state.entryPoint,
};
const mutations = {
    setConstructionProjectId(state, constructionProjectId) {
        state.constructionProjectId = constructionProjectId;
    },
    setEntryPoint(state, entryPoint) {
        state.entryPoint = entryPoint;
    },
    setDetailsPageEntryPoint(state, entryPoint) {
        state.detailsPageEntryPoint = entryPoint;
    },
    reset(state) {
        state.constructionProjectId = null;
        state.entryPoint = null;
        state.detailsPageEntryPoint = CONSTRUCTION_PROJECT_LIST_ROUTE;
    },
};
const m = {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
};
export default m;

<template>
    <div
        :class="{
            'header-bar-item--button': button,
            'header-bar-item--center': center,
            'header-bar-item--no-x-padding': noXPadding,
        }"
        class="header-bar-item"
        @click="$emit('click', $event)"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'HeaderBarItem',
    props: {
        button: {
            type: Boolean,
            default: false,
        },
        center: {
            type: Boolean,
            default: false,
        },
        noXPadding: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
$spacing: 10px;

.header-bar-item {
    display: flex;
    align-items: center;
    padding: $spacing;

    &:empty {
        display: none;
    }

    & + .header-bar-item {
        padding-left: 0;

        @media only screen and (min-width: $screen-sm) {
            padding-left: $spacing;
        }
    }
}

.header-bar-item--center {
    text-align: center;
    justify-content: center;
}

.header-bar-item--button {
    cursor: pointer;
}

.header-bar-item--button:active {
    opacity: 0.5;
}

.header-bar-item--no-x-padding {
    padding-left: 0;
    padding-right: 0;
}
</style>

<template>
    <div v-if="pages > 0" class="simple-pager">
        <button
            type="button"
            class="simple-pager__nav-button"
            :class="{ 'pagination__nav-button--inactive': page <= 1 }"
            @click="previousPage"
        >
            <ArrowRight class="icon--inline pagination__arrow-left" :height="12" />
        </button>

        <button
            type="button"
            class="simple-pager__nav-button"
            :class="{ 'pagination__nav-button--inactive': page >= pages }"
            @click="nextPage"
        >
            <ArrowRight class="icon--inline" :height="12" />
        </button>
    </div>
</template>

<script>
import ArrowRight from '@/assets/icons/regular/arrow-stripeless.svg';

export default {
    name: 'SimplePager',
    components: {
        ArrowRight,
    },
    props: {
        pages: {
            type: Number,
            default: 0,
        },
        page: {
            type: Number,
            default: 1,
        },
    },
    methods: {
        nextPage() {
            if (this.page >= this.pages) {
                return;
            }

            this.$emit('selected', this.page + 1);
        },

        previousPage() {
            if (this.page <= 1) {
                return;
            }

            this.$emit('selected', this.page - 1);
        },
    },
};
</script>

<style lang="scss" scoped>
.simple-pager {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(min-content, max-content));
    grid-template-areas: 'pagination pagination navigation';
    grid-gap: 20px;
    align-items: center;

    @media screen and (min-width: $screen-xxl) {
        grid-template-areas: 'items pagination navigation';
    }

    @media screen and (min-width: $layout-desktop-min) {
        justify-content: flex-end;
    }

    &__nav-button {
        display: flex;
        color: $color-darkGrey;
        text-align: center;
        cursor: pointer;
        position: relative;
        background-color: transparent;
        font-weight: inherit;
        border: 0;
        outline: 0;
        padding: 0;
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        -webkit-appearance: none;
        text-decoration: none;
        align-items: center;
        justify-content: center;
        width: 40px;

        &:hover {
            opacity: 0.5;
        }
    }

    &__arrow-left {
        transform: rotate(180deg);
    }
}
</style>

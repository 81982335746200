import store from '@/store';
export function getAnalyticsUserPropertiesFromState() {
    const isImpersonated = store.getters['user/isImpersonated'];
    if (isImpersonated) {
        return {
            userId: store.getters['user/impersonatingUserId'],
            impersonatedUserId: store.getters['user/id'],
            organizationId: store.getters['user/organizationId'],
            organizationName: store.getters['user/organizationName'],
        };
    }
    else {
        return {
            userId: store.getters['user/id'],
            impersonatedUserId: null,
            organizationId: store.getters['user/organizationId'],
            organizationName: store.getters['user/organizationName'],
        };
    }
}

<template>
    <div
        class="content-wrapper relative m-auto w-full px-6 lg:px-0"
        data-test="content-wrapper"
        :style="{ maxWidth: `${maxWidth}px` }"
        :class="{ 'px-0': !gutter, 'content-wrapper--wide': maxWidth > 720 }"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ContentWrapper',
    props: {
        maxWidth: {
            type: [Number, String],
            default: 720,
        },
        gutter: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
    .content-wrapper & {
        padding: 0;
    }

    @media screen and (max-width: $screen-xl) {
        max-width: 720px !important;

        &--wide {
            max-width: 100% !important;
        }
    }
}
</style>

import _get from 'lodash/get';
import { computed, onUnmounted, reactive, ref, unref, watch } from 'vue';
import { useCan } from '@/utils/composition-helper';
import { CAPTURE_TRANSPORT } from '@/services/TransportActions';
import { useStateful } from '@/services/stateful/stateful';
import { useTransportAction } from '@/pages/Transport/Actions/composables/useTransportAction';
import { makeCaptureTransportService, } from '@/services/Transport/CaptureTransportService';
import { useVehicleClasses } from '@/pages/Transport/Actions/TransportCapture/useVehicleClasses';
import { ensurePHPTimestamp } from '@/services/utils/date';
import { weighingMethods } from '@/constants/weighingMethods';
import { required, requiredIf, requiredUnless, integer, minLength, maxLength } from '@vuelidate/validators';
import { useValidation } from '@/pages/Transport/Actions/composables/useValidation';
import { removeFalsyValuesFromObject } from '@/utils/helpers';
import mitt from 'mitt';
const getEmptyWeighing = () => ({
    // weighing based
    weighingDate: null,
    deliveryNoteDocuments: [],
    deliveryNoteImages: [],
    // weighing method based
    weighingNumber: null,
    weight: undefined,
    tare: undefined,
    full: undefined,
    shovels: undefined,
    cbm: undefined,
    weigherName: undefined,
});
export const getEmptyForm = () => ({
    weighingMethod: '',
    ...getEmptyWeighing(),
    // general
    serviceDate: null,
    licensePlate: undefined,
    vehicleClassId: undefined,
    externalCarrierOrganizationName: null,
});
export function useTransportCaptureDeliveryAction({ initialValues, onCloseHandler }) {
    const can = useCan();
    const { updateTransport, transport: transportAllTypes, context } = useTransportAction(CAPTURE_TRANSPORT);
    const transport = transportAllTypes;
    if (!transport.value) {
        throw new Error('No transport in store');
    }
    if (!can.value('transportCapture', transport.value)) {
        throw new Error('Unable to capture transport');
    }
    const { isPending, stateful, statefulRun } = useStateful();
    const { vehicleClass, refresh, vehicleClassList } = useVehicleClasses({
        vehicleClassId: transport.value?.vehicleClassId,
    });
    statefulRun('preload', async () => {
        await refresh();
    });
    const serviceAndWeighingDateMax = Date.now();
    const isWeighingDisabled = computed(() => !vehicleClass.value);
    const form = reactive({
        ...getEmptyForm(),
        vehicleClassId: computed(() => vehicleClass.value?.id),
    });
    const isEmptyFullWeighing = () => form.weighingMethod === weighingMethods.EMPTY_FULL;
    const isShovelWeighing = () => form.weighingMethod === weighingMethods.SHOVEL;
    const isCMBWeighing = () => form.weighingMethod === weighingMethods.CBM;
    /**
     * OCR Tracking
     */
    const changedValuesToBeTracked = ref([]);
    watch(initialValues, () => {
        changedValuesToBeTracked.value = [];
    }, { deep: true });
    watch(form, ({ weight, weighingDate, tare, full, shovels, cbm, weighingNumber }) => {
        const previous = unref(initialValues);
        if (!changedValuesToBeTracked.value.includes('quantity') && weight !== previous.quantity)
            changedValuesToBeTracked.value.push('quantity');
        if (!changedValuesToBeTracked.value.includes('date') && weighingDate !== previous.date)
            changedValuesToBeTracked.value.push('date');
        if (!changedValuesToBeTracked.value.includes('tare') && tare !== previous.tare)
            changedValuesToBeTracked.value.push('tare');
        if (!changedValuesToBeTracked.value.includes('full') && full !== previous.full)
            changedValuesToBeTracked.value.push('full');
        if (!changedValuesToBeTracked.value.includes('cbm') && cbm !== previous.cbm)
            changedValuesToBeTracked.value.push('cbm');
        if (!changedValuesToBeTracked.value.includes('shovels') && shovels !== previous.shovels)
            changedValuesToBeTracked.value.push('shovels');
        if (!changedValuesToBeTracked.value.includes('number') && weighingNumber !== previous.number)
            changedValuesToBeTracked.value.push('number');
    }, { deep: true });
    const { setServerErrors, v$ } = useValidation({
        rules: computed(() => ({
            weighingMethod: { required },
            // weighing based
            weighingDate: { required },
            deliveryNoteDocuments: {
                required: requiredUnless(() => !!form.deliveryNoteImages.length),
            },
            deliveryNoteImages: {
                required: requiredUnless(() => !!form.deliveryNoteDocuments.length),
            },
            // weighing method based
            weighingNumber: { required },
            weigherName: {},
            weight: { required: requiredIf(() => isShovelWeighing()), integer },
            shovels: { required: requiredIf(() => isShovelWeighing()), integer },
            tare: { required: requiredIf(() => isEmptyFullWeighing()), integer },
            full: { required: requiredIf(() => isEmptyFullWeighing()), integer },
            cbm: {
                required: requiredIf(() => isCMBWeighing() && !form.cbm?.cbm),
            },
            // general
            serviceDate: { required },
            licensePlate: {
                required: requiredIf(() => !!transport.value?.forSubcontractor),
            },
            vehicleClassId: {
                required: requiredIf(() => !!transport.value?.forSubcontractor),
            },
            externalCarrierOrganizationName: {
                required: requiredIf(() => !!transport.value?.forSubcontractor),
                minLength: minLength(3),
                maxLength: maxLength(100),
            },
        })),
        data: form,
        serverErrorMapping: {
            weighingMethod: ['weighingMethod'],
            deliveryNoteDocuments: ['#', 'originalDeliveryNoteDocuments'],
            deliveryNoteImages: ['#', 'originalDeliveryNoteImages'],
            weighingNumber: ['weighing.weighingNumber'],
            weight: ['weighing.weight'],
            tare: ['weighing.tare'],
            full: ['weighing.full'],
            shovels: ['weighing.shovels'],
            cbm: ['weighing.weight'],
            weigherName: ['weigherName'],
            weighingDate: ['weighingDate'],
            serviceDate: ['serviceDate'],
            licensePlate: ['externalLicensePlate'],
            vehicleClassId: ['vehicleClassId'],
            externalCarrierOrganizationName: ['externalCarrierOrganizationName'],
        },
    });
    const hasValidData = computed(() => {
        return !v$.value.$invalid;
    });
    /**
     * Reset weighing data when user deletes uploaded document or image
     */
    const handleFileDeleted = () => {
        if (!form.weighingMethod)
            return;
        startWeighing(form.weighingMethod);
    };
    const resetWeighing = () => {
        Object.assign(form, getEmptyWeighing());
        form.cbm = getEmptyWeighing().cbm;
        form.deliveryNoteDocuments = [];
        form.deliveryNoteImages = [];
    };
    const startWeighing = (nextWeighingMethod) => {
        resetWeighing();
        Object.assign(form, {
            cbm: {
                weighingNumber: '',
                cbm: {
                    cbm: null,
                    cbmInKg: 0,
                    density: transport.value.product.density,
                },
            },
            'empty-full': {
                weighingNumber: '',
                tare: null,
                full: null,
            },
            shovel: {
                weighingNumber: '',
                weight: null,
                shovels: null,
            },
        }[nextWeighingMethod]);
    };
    const preparePayloadFromState = () => removeFalsyValuesFromObject({
        originalDeliveryNoteDocuments: form.deliveryNoteDocuments,
        originalDeliveryNoteImages: form.deliveryNoteImages,
        weigherName: form.weigherName,
        weighingDate: ensurePHPTimestamp(form.weighingDate),
        serviceDate: ensurePHPTimestamp(form.serviceDate),
        weighingMethod: form.weighingMethod,
        weighing: removeFalsyValuesFromObject({
            weighingNumber: form.weighingNumber,
            weight: form.weight || form.cbm?.cbmInKg,
            tare: form.tare,
            full: form.full,
            shovels: form.shovels,
        }),
        vehicleClassId: transport.value?.forSubcontractor ? form.vehicleClassId : undefined,
        externalLicensePlate: form.licensePlate,
        externalCarrierOrganizationName: transport.value?.forSubcontractor
            ? form.externalCarrierOrganizationName
            : undefined,
    });
    const emitter = mitt();
    const onSubmissionSuccessful = (callback) => {
        emitter.on('submissionSuccessful', callback);
    };
    onUnmounted(() => emitter.all.clear());
    const handleSubmission = async () => {
        v$.value.$clearExternalResults();
        v$.value.$touch();
        await stateful('handleSubmission', async () => {
            if (!context.value)
                throw Error('context is not defined');
            if (!transport.value)
                throw Error('transport is not defined');
            const payload = preparePayloadFromState();
            try {
                const captureTransportService = makeCaptureTransportService(context.value);
                const updatedTransport = await captureTransportService.captureDeliveryTransport(transport.value.id, payload);
                updateTransport(updatedTransport);
                onCloseHandler && onCloseHandler();
                emitter.emit('submissionSuccessful');
            }
            catch (err) {
                const error = _get(err, 'response.data.errors', {});
                setServerErrors(error);
            }
        });
    };
    const showCaptureWeight = computed(() => !!form.weighingMethod);
    return {
        transport,
        vehicleClass,
        vehicleClassList,
        // Weighing
        isWeighingDisabled,
        startWeighing,
        showCaptureWeight,
        handleFileDeleted,
        serviceAndWeighingDateMax,
        isPending,
        v$,
        hasValidData,
        handleSubmission,
        onSubmissionSuccessful,
        changedValuesToBeTracked,
    };
}

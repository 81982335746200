<template>
    <ul
        v-if="tree"
        :class="{
            'tree--root': isRoot,
        }"
        class="tree tree--selection"
    >
        <li
            v-for="(node, index) in tree.children"
            :key="index"
            :data-depth="node.depth"
            class="tree__item"
            data-test="category-radio-buttons"
        >
            <div class="tree__name">
                <CheckboxField
                    v-if="multiple"
                    :value="value"
                    :option="node.item[valueKey]"
                    :disabled="isDisabled(node.item[valueKey])"
                    @input="$emit('input', $event)"
                >
                    {{ node.item.name }}
                </CheckboxField>
                <RadioField
                    v-if="!multiple"
                    :name="treeName"
                    :value="value"
                    :option="node.item[valueKey]"
                    :disabled="subtreeDisabled || isDisabled(node.item[valueKey])"
                    @input="$emit('input', $event)"
                >
                    {{ node.item[displayKey] }}
                </RadioField>
            </div>
            <CategorySelectionTree
                v-if="node.children.length"
                :value="value"
                :tree="node"
                :is-root="false"
                :name="treeName"
                :multiple="multiple"
                :disabled-value="disabledValue"
                :disable-subtree="disableSubtree"
                :subtree-disabled="subtreeDisabled || isDisabledSubtree(node.item[valueKey])"
                @input="$emit('input', $event)"
            />
        </li>
    </ul>
</template>

<script>
import RadioField from '@/components/Form/RadioField';
import CheckboxField from '@/components/Form/CheckboxField';
import Tree from './Tree';

export default {
    name: 'CategorySelectionTree',
    components: {
        CheckboxField,
        RadioField,
    },
    extends: Tree,
    props: {
        name: {
            type: String,
            default: null,
        },
        displayKey: {
            type: String,
            default: 'name',
        },
        valueKey: {
            type: String,
            default: 'id',
        },
        value: {
            type: [Number, Array],
            default: null,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        disabledValue: {
            type: [Number, Array],
            default: null,
        },
        disableSubtree: {
            type: Boolean,
            default: false,
        },
        subtreeDisabled: {
            type: Boolean,
            default: false,
        },
        isRoot: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            treeName: this.name || `e${this._uid}`,
        };
    },
    methods: {
        isDisabled(value) {
            const { disabledValue } = this;
            let isDisabled = false;

            // not set
            if (!disabledValue) {
                return false;
            }

            // is array
            if (disabledValue.push) {
                isDisabled = disabledValue.indexOf(value) !== -1;
            } else {
                // is exact value
                isDisabled = disabledValue === value;
            }

            return isDisabled;
        },

        isDisabledSubtree(value) {
            const { disableSubtree } = this;
            const isDisabled = this.isDisabled(value);

            return this.subtreeDisabled || (disableSubtree ? isDisabled : false);
        },
    },
};
</script>

<style lang="scss">
.tree--selection {
    .tree__item {
        &::before {
            top: 29px;
            bottom: 23px;
            left: -14px;
        }

        &::after {
            top: 21px;
            left: -44px;
            right: calc(100% + 21px);
        }
    }
}
</style>

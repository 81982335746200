import clsx from 'clsx';
const styles = {
    bounce: {
        animation: 'bouncedelay 1.4s infinite ease-in-out both',
    },
    bounceDelay1: {
        animationDelay: '-0.32s',
    },
    bounceDelay2: {
        animationDelay: '-0.16s',
    },
};
const LoadingSpinner = ({ dark, block, spaced }) => {
    return (<div className={clsx(dark && 'text-dark-gray-800', block && 'my-5 mx-[10px] block', spaced && 'flex h-[200px] items-center justify-center')}>
            <div style={{ ...styles.bounce, ...styles.bounceDelay1 }} className="mx-px inline-block h-[10px] w-[10px] rounded-1/2 bg-current"/>
            <div style={{ ...styles.bounce, ...styles.bounceDelay2 }} className="mx-px inline-block h-[10px] w-[10px] rounded-1/2 bg-current"/>
            <div style={styles.bounce} className="mx-px inline-block h-[10px] w-[10px] rounded-1/2 bg-dark-gray-800"/>
        </div>);
};
export default LoadingSpinner;

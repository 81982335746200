<template>
    <LayoutPage class="partner-search-page" screen-name="platform-partnersearch">
        <div slot="pageTitle">{{ $t('pages.platformToolsPartnerSearch.title') }}</div>

        <RoundedTabNavigation
            :value="'management__partner-search'"
            :tabs="TABS"
            @input="$router.push({ name: $event }).catch(() => {})"
        />
        <div class="partner-search-page__inline-filter">
            <LocationField
                :place="place"
                :radius="radius"
                :radius-options="[5, 25, 50, 100, 200]"
                :radius-options-default="25"
                :label="$t('pages.platformToolsPartnerSearch.enterAddress')"
                strict-radius-options
                list-locality
                @placeChange="place = $event"
                @radiusChange="radius = $event"
            />
            <CheckboxField v-model="organizationTypes" option="client">
                <ClientComponentIcon class="icon--inline" />
                &nbsp; {{ $t('pages.platformToolsPartnerSearch.customer') }}
            </CheckboxField>
            <CheckboxField v-model="organizationTypes" option="carrier">
                <CarrierComponentIcon class="icon--inline" />
                &nbsp; {{ $t('pages.platformToolsPartnerSearch.dispatcher') }}
            </CheckboxField>
            <CheckboxField v-model="organizationTypes" option="supplier">
                <SupplierComponentIcon class="icon--inline" />
                &nbsp; {{ $t('pages.platformToolsPartnerSearch.supplier') }}
            </CheckboxField>
        </div>

        <div class="partner-search-page__map-grid">
            <div class="partner-search-page__map-wrapper">
                <div :id="eid" class="partner-search-page__map" />
                <LayerControls
                    :type="mapType"
                    :mode="detailMode"
                    :map="map"
                    @update-type="mapType = $event"
                    @update-mode="detailMode = $event"
                />
                <button
                    primary
                    light
                    class="partner-search-page__scan-viewport-btn"
                    :class="{ 'partner-search-page__scan-viewport-btn--enabled': shouldUpdateList }"
                    @click="scanCurrentViewport()"
                >
                    {{ $t('pages.platformToolsPartnerSearch.updateList') }}
                </button>
            </div>
            <div class="partner-search-page__list-wrapper">
                <div class="partner-search-page__spaced-child">
                    <Words v-if="!hasSearchResults" block bold red spaced-bottom>
                        {{ $t('pages.platformToolsPartnerSearch.hintNoPartnersFound') }}
                    </Words>
                    <Words block bold spaced-bottom>
                        {{
                            $t('pages.platformToolsPartnerSearch.partnersFound', {
                                count: filteredOrganizations.length,
                            })
                        }}
                    </Words>
                </div>

                <div v-scrollable class="partner-search-page__list partner-search-page__spaced-child">
                    <PartnerSearchOrganizationCard
                        v-for="(org, i) in renderedOrganizations"
                        :key="i"
                        :organization="org"
                        :class="{
                            'partner-search-page__item--selected': isSelectedOrganization(org),
                        }"
                        :highlight-distance="isInsideRadius(org.__distance)"
                        :is-selected="isSelectedOrganization(org)"
                        @click="selectOrganization(org, true)"
                    />

                    <PartnerSearchOrganizationCard
                        v-if="renderedSelectedOrganization !== null"
                        :organization="renderedSelectedOrganization"
                        is-selected
                        :highlight-distance="isInsideRadius(renderedSelectedOrganization.__distance)"
                        @click="selectOrganization(renderedSelectedOrganization, true)"
                    />

                    <Card v-if="remainingResultsCount > 0" spaceless-x>
                        <Words block muted centered>
                            {{ $tc('pages.platformToolsPartnerSearch.furtherResults', remainingResultsCount) }}
                        </Words>
                    </Card>
                </div>
            </div>
        </div>
    </LayoutPage>
</template>

<script>
import { mapGetters } from 'vuex';
import _debounce from 'lodash/debounce';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _isFunction from 'lodash/isFunction';
import _sortBy from 'lodash/sortBy';
import { asyncDelay } from '@/services/utils';
import { inBounds } from '@/services/utils/map';
import PartnerSearchApi from '@/services/Api/Administration/PartnerSearch';
import GoogleMaps, { createMap } from '@/services/GoogleMaps';
import Toaster from '@/services/Toaster';

import Card from '@/components/Layout/Card';
import CheckboxField from '@/components/Form/CheckboxField';
import LayerControls from '@/components/Map/LayerControls';
import LayoutPage from '@/components/Layout/Page.v2';
import LocationField from '@/components/Form/LocationField.v2';
import PartnerSearchOrganizationCard from './components/PartnerSearchOrganizationCard';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation';
import Words from '@/components/Typography/Words';

import CarrierComponentIcon from '@/assets/icons/partnerMap/carrier.svg';
import CarrierIcon from '@/assets/icons/partnerMap/carrier.svg?external';
import CarrierMutedIcon from '@/assets/icons/partnerMap/carrier--muted.svg?external';
import ClientComponentIcon from '@/assets/icons/partnerMap/client.svg';
import ClientIcon from '@/assets/icons/partnerMap/client.svg?external';
import ClientMutedIcon from '@/assets/icons/partnerMap/client--muted.svg?external';
import PointIcon from '@/assets/icons/partnerMap/point.svg?external';
import SupplierComponentIcon from '@/assets/icons/partnerMap/supplier.svg';
import SupplierIcon from '@/assets/icons/partnerMap/supplier.svg?external';
import SupplierMutedIcon from '@/assets/icons/partnerMap/supplier--muted.svg?external';

const DEBOUNCE_CHANGED_MAP_CENTER_MS = 400;
const ZOOM_INITIAL = 15;
const MAX_ORGANIZATIONS_IN_LIST = 100;

const ICON_ORG_TYPES = {
    carrier: CarrierIcon,
    supplier: SupplierIcon,
    client: ClientIcon,
};

const ICON_ORG_MUTED_TYPES = {
    carrier: CarrierMutedIcon,
    supplier: SupplierMutedIcon,
    client: ClientMutedIcon,
};

/**
 * TERMINOLOGY
 * --------------------
 *
 * Ref: ObjectBag which holding references to multiple instances eg. { Marker, Organization, Icon }
 *
 */

export default {
    name: 'PartnerSearchPage',
    components: {
        LayoutPage,
        RoundedTabNavigation,
        CheckboxField,
        LocationField,
        Card,
        Words,
        LayerControls,
        PartnerSearchOrganizationCard,

        CarrierComponentIcon,
        SupplierComponentIcon,
        ClientComponentIcon,
    },
    data() {
        return {
            eid: `el${this._uid}`,
            organizations: null,
            filteredOrganizations: [],

            radius: null,
            place: null,
            organizationTypes: ['client', 'carrier', 'supplier'],

            selectedOrganizationRef: null,
            selectedPlaceRef: null,

            map: null,
            mapType: 'roadmap',
            detailMode: 'default',
            markerRefs: {},
            shouldUpdateList: false,
        };
    },
    computed: {
        ...mapGetters('platform', ['initialMapLocation']),

        TABS() {
            return {
                'management__partner-search': this.$t('pages.platformToolsPartnerSearch.radiusSearch'),
                'management__transport-cost-calculator': this.$t(
                    'pages.platformToolsTransportCostCalulator.transportCostCalculator'
                ),
            };
        },

        renderedSelectedOrganization() {
            if (this.selectedOrganizationRef === null) {
                return null;
            }

            const selectedOrgId = this.selectedOrganizationRef.organization.id;

            // do not add the selected organization to the view if it's already in the list
            if (this.renderedOrganizations.map(org => org.id).includes(selectedOrgId)) {
                return null;
            }

            return this.selectedOrganizationRef.organization;
        },

        renderedOrganizations() {
            if (this.selectedOrganizationRef === null) {
                return this.filteredOrganizations.slice(0, MAX_ORGANIZATIONS_IN_LIST);
            }

            return this.filteredOrganizations.slice(0, MAX_ORGANIZATIONS_IN_LIST);
        },

        remainingResultsCount() {
            const count = this.filteredOrganizations.length - this.renderedOrganizations.length;

            if (this.selectedOrganizationRef) {
                const selectedOrgId = this.selectedOrganizationRef.organization.id;

                if (!this.renderedOrganizations.map(org => org.id).includes(selectedOrgId)) {
                    return count - 1 < 0 ? 0 : count - 1;
                }
            }

            return count;
        },

        hasSearchResults() {
            if (this.filteredOrganizations.length > 0) return true;
            if (!this.place) return true;

            return this.place && this.renderedOrganizations.some(org => this.isInsideRadius(org.__distance));
        },
    },
    watch: {
        place(n, o) {
            if (n === null && o !== null) {
                this.selectPlace();
                this.$logger().log('PLACE: removed');
            } else {
                this.selectPlace(n);
                this.$logger().log('PLACE: added');
            }
        },

        radius() {
            this.$logger().log('RADIUS: changed');
            if (this.place) {
                this.filterList();
            } else {
                this.$logger().log('RADIUS: no place available, skip update');
            }
        },

        organizationTypes(value) {
            this.$logger().log('TYPES: changed', value);
            this.filterList();
        },
    },
    created() {
        this.refreshOrganizations();
        this.createMap();
    },
    methods: {
        _get,

        isSelectedOrganization(org) {
            return this.selectedOrganizationRef && this.selectedOrganizationRef.organization._key === org._key;
        },

        async refreshOrganizations() {
            try {
                const organizations = await PartnerSearchApi.getMapList();
                this.organizations = organizations.map(o => {
                    o._key = `${o.type}-${o.id}`;
                    return o;
                });
                this.placeOrganizationsOnMap();
                this.filterList();
            } catch (err) {
                Toaster.error(err);
            }
        },

        isInsideRadius(distance) {
            if (!distance) return false;
            return parseInt(this.radius) >= distance / 1000;
        },

        async createMap() {
            if (this.map !== null) {
                return this.map;
            }

            const google = await GoogleMaps;

            this.map = createMap(google, this.eid, {
                zoom: ZOOM_INITIAL,
                center: this.initialMapLocation,
                disableDefaultUI: true,
                mapTypeId: this.mapType,
            });

            this.map.addListener(
                'center_changed',
                _debounce(() => {
                    this.$logger().log('DEBOUNCED ACTION: center_changed');
                    this.shouldUpdateList = this.detectListUpdateByViewport();
                }, DEBOUNCE_CHANGED_MAP_CENTER_MS)
            );

            return this.map;
        },

        async placeOrganizationsOnMap() {
            if (!this.organizations) return;

            // cleanup existing, remove old once from map
            this.clearMapMarkers();

            this.organizations.forEach(org => {
                this.addOrganizationMarker(org);
            });

            // show all poins on map initially
            await asyncDelay(100);
            this.fitRefsToBounds(Object.values(this.markerRefs));
        },

        clearMapMarkers() {
            Object.keys(this.markerRefs).forEach(k => {
                this.markerRefs[k].marker.setMap(null);
                delete this.markerRefs[k];
            });
        },

        clearDistance() {
            Object.values(this.markerRefs).forEach(ref => {
                this.$set(ref.organization, '__distance', null);
            });
        },

        async getOrganizationMapIcon(type, isMuted = false, isSelected = false) {
            const google = await GoogleMaps;

            if (isSelected) {
                /* @type {google.maps.Icon} */
                return {
                    url: ICON_ORG_TYPES[type],
                    scaledSize: new google.maps.Size(48, 48), // maps adds wierd 16px to the marker
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(24, 48),
                };
            }

            /* @type {google.maps.Icon} */
            return {
                url: isMuted ? ICON_ORG_MUTED_TYPES[type] : ICON_ORG_TYPES[type],
                scaledSize: new google.maps.Size(24, 24), // maps adds wierd 16px to the marker
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(12, 24),
            };
        },

        async addOrganizationMarker(organization) {
            if (this.markerRefs[organization._key]) {
                return this.markerRefs[organization._key];
            }

            const google = await GoogleMaps;

            const marker = new google.maps.Marker({
                map: this.map,
                position: organization.location,
                title: organization.name,
                icon: await this.getOrganizationMapIcon(organization.type),
            });

            marker.addListener('click', () => {
                this.selectOrganization(organization);
            });

            const ref = {
                marker: marker,
                organization: organization,
            };

            this.markerRefs[organization._key] = ref;
            return ref;
        },

        async selectPlace(place = null) {
            // unselect, remove previus
            if (this.selectedPlaceRef) {
                this.selectedPlaceRef.marker.setMap(null);
                this.selectedPlaceRef = null;
                this.clearDistance();
            }

            if (place === null) {
                return;
            }

            const google = await GoogleMaps;

            this.$logger().info('Selected place by address: ', place.label);

            /* @type {google.maps.Icon} */
            const icon = {
                url: PointIcon,
                scaledSize: new google.maps.Size(15, 15), // maps adds wierd 16px to the marker
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(7.5, 7.5),
            };

            const marker = new google.maps.Marker({
                map: this.map,
                position: place.location,
                icon,
                title: place.label,
                zIndex: 1,
            });

            this.filterList(place);

            marker.addListener('click', () => {
                // Remove curren selection on click
                this.selectPlace();
                this.place = null;
            });

            const refs = {
                marker,
                place,
            };

            this.selectedPlaceRef = refs;

            return refs;
        },

        async selectOrganization(organization, fromList = false) {
            let isSame = false;

            if (this.selectedOrganizationRef) {
                const selectedOrganization = this.selectedOrganizationRef.organization;
                isSame = selectedOrganization._key === organization._key;

                const isMuted = !this.filteredOrganizations.map(org => org._key).includes(selectedOrganization._key);
                this.selectedOrganizationRef.marker.setIcon(
                    await this.getOrganizationMapIcon(selectedOrganization.type, isMuted, false)
                );
            }

            // deselect organization if it's same
            if (isSame && !fromList) {
                this.selectedOrganizationRef = null;
                return;
            }

            this.selectedOrganizationRef = this.markerRefs[organization._key];
            this.selectedOrganizationRef.marker.setIcon(
                await this.getOrganizationMapIcon(organization.type, false, true)
            );

            if (!this.isLocationInViewport(organization.location)) {
                this.map.panTo(organization.location);
            }

            await asyncDelay(100);
            const $sel = document.querySelector('.partner-search-organization-card--selected');
            $sel && $sel.scrollIntoView({ behavior: 'smooth' });
        },

        async updateRelativeDistanceBetweenLocationAndOrgs(location, markerRefs = null) {
            const google = await GoogleMaps;

            if (markerRefs === null) {
                markerRefs = Object.values(this.markerRefs);
            }

            if (!_isFunction(location.lat)) {
                location = new google.maps.LatLng(location);
            }

            markerRefs.forEach(ref => {
                const orgLocation = new google.maps.LatLng(ref.organization.location);
                const distance = google.maps.geometry.spherical.computeDistanceBetween(location, orgLocation);
                this.$set(ref.organization, '__distance', distance);
            });
        },

        async centerPlaceBounds(place) {
            if (!place) return;

            const google = await GoogleMaps;

            const northeast = place.geometry.viewport.getNorthEast();
            const southwest = place.geometry.viewport.getSouthWest();

            this.map.fitBounds(
                new google.maps.LatLngBounds(
                    new google.maps.LatLng(southwest.lat(), southwest.lng()), // SW
                    new google.maps.LatLng(northeast.lat(), northeast.lng()) // NE
                )
            );
        },

        async filterList(place = null) {
            let markerRefs = this.filterRefsByOrgStatus();

            place = place || this.place;

            if (place) {
                markerRefs = await this.filterByRadius(place.location, markerRefs);
            }

            // Found something?
            if (markerRefs.length === 0) {
                if (place) {
                    this.centerPlaceBounds(place.place);
                }
            } else {
                const boundRefs = [...markerRefs];

                if (place) {
                    boundRefs.push(this.selectedPlaceRef);
                }

                await this.fitRefsToBounds(boundRefs);
            }

            // update marker which are added / removed
            this.updateMarkerVisibility(markerRefs);

            await asyncDelay(200);

            // detect all active marker in vieport
            const refsInViewport = this.getRefsInViewport();

            // update all marker which are now active and in viewport
            this.updateMarkerVisibility(refsInViewport);

            // update result list
            this.updateFilteredOrganizations(refsInViewport);
        },

        async fitRefsToBounds(markerRefs) {
            const google = await GoogleMaps;

            const bounds = new google.maps.LatLngBounds();
            markerRefs.forEach(ref => {
                bounds.extend(ref.marker.position);
            });
            this.map.fitBounds(bounds);
        },

        updateFilteredOrganizations(markerRefs) {
            if (markerRefs === null) return null;

            const sorts = this.place ? ['__distance'] : ['name'];

            this.filteredOrganizations = _sortBy(
                markerRefs.map(ref => {
                    return ref.organization;
                }),
                sorts
            );
        },

        /**
         * Returns a list of reference objects
         */
        async filterByRadius(location, markerRefs = null) {
            if (this.markerRefs === null) return null;

            if (markerRefs === null) {
                markerRefs = Object.values(this.markerRefs);
            }

            // Filter radius requires that __distance was populated on the organization object
            await this.updateRelativeDistanceBetweenLocationAndOrgs(location, markerRefs);

            return _filter(markerRefs, ref => {
                return this.isInsideRadius(ref.organization.__distance);
            });
        },

        /**
         * Returns a list of reference objects
         */
        filterRefsByOrgStatus() {
            if (this.markerRefs === null) return null;
            const { organizationTypes } = this;

            return _filter(Object.values(this.markerRefs), ref => {
                return organizationTypes.includes(ref.organization.type);
            });
        },

        /**
         * Muteate markers appearance
         */
        updateMarkerVisibility(highlightedRefs = null) {
            if (this.markerRefs === null) return null;

            const { organizationTypes } = this;
            const highlightedOrgIds = highlightedRefs == null ? [] : highlightedRefs.map(ref => ref.organization._key);

            // iterate over all markers
            Object.values(this.markerRefs).forEach(async ref => {
                const orgType = ref.organization.type;
                const isActive = organizationTypes.includes(orgType);
                const isHighlighted = highlightedOrgIds.includes(ref.organization._key);
                const isSelected = this.isSelectedOrganization(ref.organization);
                const marker = ref.marker;

                // update visibility
                marker.setMap(isActive ? this.map : null);

                // no need to change icons if it's inactive
                if (isActive) {
                    marker.setIcon(await this.getOrganizationMapIcon(orgType, !isHighlighted, isSelected));
                }
            });
        },

        /**`
         * Get all refs in viewport
         */
        getRefsInViewport() {
            const bounds = this.map.getBounds();
            const sw = bounds.getSouthWest();
            const ne = bounds.getNorthEast();

            const list = [];

            Object.values(this.markerRefs).forEach(ref => {
                // skip inactive marker
                if (ref.marker.getMap() === null) return;

                if (inBounds(ref.organization.location, sw, ne)) {
                    list.push(ref);
                }
            });

            return list;
        },

        /**`
         * Check if in viewport
         */
        isLocationInViewport(location) {
            const bounds = this.map.getBounds();
            const sw = bounds.getSouthWest();
            const ne = bounds.getNorthEast();

            return inBounds(location, sw, ne);
        },

        scanCurrentViewport() {
            const refsInViewport = this.getRefsInViewport();

            this.updateMarkerVisibility(refsInViewport);
            this.updateFilteredOrganizations(refsInViewport);
            this.shouldUpdateList = false;
        },

        detectListUpdateByViewport() {
            const refsInViewport = this.getRefsInViewport();

            const currIds = this.filteredOrganizations.map(org => org.id).sort();
            const newIds = refsInViewport.map(ref => ref.organization.id).sort();

            return !_isEqual(currIds, newIds);
        },
    },
};
</script>

<style lang="scss">
.partner-search-page {
    background-color: $color-lightMediumGrey;
}

.partner-search-page__inline-filter {
    background-color: $color-white;
    box-shadow: $boxShadow-bottomShort;
    padding: 20px;
    margin-bottom: 30px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fill, 1fr);
    grid-column-gap: 20px;
    align-items: center;
}

.partner-search-page__map-grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 20px;
    height: 60vh;
}

.partner-search-page__map-wrapper {
    position: relative;
    flex: 1 1;
    display: flex;
}

.partner-search-page__list {
    overflow: scroll;
}

.partner-search-page__spaced-child {
    padding-left: 10px;
    padding-right: 10px;
}

.partner-search-page__list-wrapper {
    display: flex;
    flex-flow: column;
    overflow: hidden;
}

.partner-search-page__map {
    background-color: #ccc;
    min-height: 100px;
    flex: 1 1;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
}

.partner-search-page__scan-viewport-btn {
    -webkit-appearance: none;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 0;
    transition:
        transform 0.2s ease-out,
        box-shadow 0.2s ease-out,
        opacity 0.3s ease-out;
    transform: scale(1) translateY(30px);
    padding: 20px;
    box-shadow: $boxShadow-bottomShort;
    will-change: transform;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    outline: none;
    font-weight: $font-weight-bold;
    font-size: 16px;
    opacity: 0;
    pointer-events: none;

    &:active {
        transform: scale(0.9) translateY(10px);
        box-shadow: $boxShadow-card;
    }
}

.partner-search-page__scan-viewport-btn--enabled {
    opacity: 1;
    transform: scale(1) translateY(10px);
    pointer-events: all;
}
</style>

<template>
    <AlmostFullIcon :id="getDomId" />
</template>

<script>
import { animateBubbles } from '@/services/Animation/Animation';
import AlmostFullIcon from '@/assets/icons/freight/almost-full.svg';

export default {
    name: 'AnimatedAlmostFull',
    components: {
        AlmostFullIcon,
    },
    computed: {
        getDomId() {
            return `AnimatedAlmostFullSVG${this._uid}`;
        },
    },
    mounted() {
        animateBubbles(
            [
                `#${this.getDomId} > .svg-almost-full__circle1`,
                `#${this.getDomId} > .svg-almost-full__circle3`,
                `#${this.getDomId} > .svg-almost-full__circle2`,
            ],
            15,
            50,
            -20,
            { x: 13, y: 0 }
        );
    },
};
</script>

<style lang="scss" scoped>
.svg-almost-full__circle3,
.svg-almost-full__circle2 {
    opacity: 0;
}
</style>

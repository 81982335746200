// To add a new language add a new language on phrase with the desired <newLocaleCode> (e.g. 'en-GB'). And follow the steps:
//
// 1. Add to all present locales the key: 'language.{normalizedNEWLocaleCode}.name'
// 2. Add all translation keys to the new language: 'language.{normalizedPRESENTLocaleCode}.name}'
//
// In this repository:
// 3. Add a flag svg of the country your new language belongs to in @/assets/icons/flags/
//
// In this file
// 4. Run 'pnpm run translation:pull'
// 5. Add the new localeCode in: const localeCodes
// 6. Add a new LanguageConfig in: const languages
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en';
import cs from 'date-fns/locale/cs';
import pl from 'date-fns/locale/pl';
// TODO: Replace with uk (Ukraine) after update to date-fns v2
import ru from 'date-fns/locale/ru';
import FlagAT from '@/assets/icons/flags/AT.svg';
import FlagDE from '@/assets/icons/flags/DE.svg';
import FlagPL from '@/assets/icons/flags/PL.svg';
import FlagGB from '@/assets/icons/flags/GB.svg';
import FlagCZ from '@/assets/icons/flags/CZ.svg';
import FlagUA from '@/assets/icons/flags/UA.svg';
// A list of all available locales. The order of the list is important as it is
export const localeCodes = ['cs-CZ', 'de-DE', 'de-AT', 'en-GB', 'pl-PL', 'uk-UA'];
export const languages = [
    {
        locale: 'de-DE',
        flag: FlagDE,
        dateFnsLocale: de,
    },
    {
        locale: 'de-AT',
        flag: FlagAT,
        userflowLocale: 'de-DE',
        dateFnsLocale: de,
    },
    {
        locale: 'en-GB',
        flag: FlagGB,
        dateFnsLocale: en,
    },
    {
        locale: 'cs-CZ',
        flag: FlagCZ,
        dateFnsLocale: cs,
    },
    {
        locale: 'pl-PL',
        flag: FlagPL,
        dateFnsLocale: pl,
    },
    {
        locale: 'uk-UA',
        flag: FlagUA,
        dateFnsLocale: ru,
    },
].map((language) => ({
    userflowLocale: language.locale,
    ...language,
    dateFnsLocale: language.dateFnsLocale,
    flag: language.flag,
}));

import LogService from '@schuettflix/util-log';
import CategoryApi from '@/services/Api/Platform/Category';
import FactoryApi from '@/services/Api/FilterableFactory';
import Toaster from '@/services/Toaster';
import {
    convertedPriceFromKiloToTon,
    convertedPriceFromTonToKilo,
    convertedWeightFromKiloToTon,
    convertedWeightFromTonToKilo,
} from '@/services/utils';

const Log = new LogService('store/waste');

const helpers = {
    updateVariantFromKiloToTon: variant => {
        variant.pricePerTon = convertedPriceFromKiloToTon(variant.pricePerKilogram);
        variant.wasteCapacityPerDayInTon = convertedWeightFromKiloToTon(variant.wasteCapacityPerDayInKilogram);

        delete variant.pricePerKilogram;
        delete variant.wasteCapacityPerDayInKilogram;

        return variant;
    },
    updateVariantFromTonToKilo: (variant, clearPollutionTypeId = false) => {
        variant.pricePerKilogram = convertedPriceFromTonToKilo(variant.pricePerTon);
        variant.wasteCapacityPerDayInKilogram = convertedWeightFromTonToKilo(variant.wasteCapacityPerDayInTon);

        if (clearPollutionTypeId) delete variant.pollutionTypeId;
        delete variant.pricePerTon;
        delete variant.wasteCapacityPerDayInTon;

        return variant;
    },
    async fetchProducts({ commit }, FilterableFactory, filters = null) {
        commit('SET_LOADING', true);
        try {
            const response = await FilterableFactory.filter(filters, null, null);
            commit('SET_PRODUCTS', {
                products: response.items,
            });
        } catch (err) {
            Log.log(err);
            Toaster.error(err);
        }
        commit('SET_LOADING', false);
    },
};

const actions = {
    async fetchCategories({ commit }) {
        commit('SET_LOADING', true);
        try {
            const response = await CategoryApi.getCategoryByType('disposal_type');

            commit('SET_CATEGORIES', response);
        } catch (err) {
            Log.log(err);
            Toaster.error(err);
        }
        commit('SET_LOADING', false);
    },

    async fetchFactoryWasteProducts({ state, commit }, filters = null) {
        const FilterableFactory = new FactoryApi(state.factoryId);
        await helpers.fetchProducts({ state, commit }, FilterableFactory, filters);
    },

    async fetchOrderFactoryWasteProducts({ state, commit }, filters = null) {
        const FilterableFactory = new FactoryApi(state.factoryId);
        FilterableFactory.setResourcePathForFetchingFactoryWasteProducts();
        await helpers.fetchProducts({ state, commit }, FilterableFactory, filters);
    },

    async fetchProductVariants({ state, commit }, { categoryId, productId }) {
        commit('SET_LOADING', true);
        try {
            const FilterableFactory = new FactoryApi(state.factoryId);
            const { items } = await FilterableFactory.getWasteProductVariants(productId);

            /**
             * convert price and weight from kilo to ton
             */
            items.forEach(variant => {
                helpers.updateVariantFromKiloToTon(variant);
            });

            commit('SET_PRODUCT_VARIANTS', { variantsDisposal: items, categoryId, productId });
        } catch (err) {
            Log.log(err);
            Toaster.error(err);
        }
        commit('SET_LOADING', false);
    },

    async fetchPollutionTypes({ state, commit }, { categoryId, productId }) {
        commit('SET_LOADING', true);
        try {
            const FilterableFactory = new FactoryApi(state.factoryId);
            const response = await FilterableFactory.getPollutionTypesForWasteProduct(productId);

            commit('SET_POLLUTION_TYPES', { pollutionTypes: response.items, categoryId, productId });
        } catch (err) {
            Log.log(err);
            Toaster.error(err);
        }
        commit('SET_LOADING', false);
    },

    async updateProductVariants({ state, commit }, { categoryId, productId, variants = [] }) {
        let response;

        commit('SET_LOADING', true);
        try {
            variants.forEach(variant => {
                helpers.updateVariantFromTonToKilo(variant, true);
            });
            const FilterableFactory = new FactoryApi(state.factoryId);
            response = await FilterableFactory.updateWasteProductVariants(productId, variants);

            response.items.forEach(variant => {
                helpers.updateVariantFromKiloToTon(variant);
            });

            commit('SET_PRODUCT_VARIANTS', { variantsDisposal: response.items, categoryId, productId });
        } catch (err) {
            Log.log(err);
            Toaster.error(err);
        }
        commit('SET_LOADING', false);

        return response;
    },

    async createNewVariant({ state, commit }, { categoryId, productId, newVariant = {} }) {
        let response;

        commit('SET_LOADING', true);
        try {
            helpers.updateVariantFromTonToKilo(newVariant);
            const FilterableFactory = new FactoryApi(state.factoryId);
            response = await FilterableFactory.createWasteProductVariant(productId, newVariant);

            response = helpers.updateVariantFromKiloToTon(response);

            commit('ADD_PRODUCT_VARIANTS', { variantsDisposal: [response], categoryId, productId });
        } catch (err) {
            Log.log(err);
            Toaster.error(err);
        }
        commit('SET_LOADING', false);

        return response;
    },

    async deleteProductVariants({ state, commit }, { categoryId, productId, variants = [] }) {
        let response;

        commit('SET_LOADING', true);
        try {
            const FilterableFactory = new FactoryApi(state.factoryId);
            const { items, messages } = await FilterableFactory.removeWasteProductVariants(variants, productId);

            commit('REMOVE_PRODUCT_VARIANTS', {
                removedVariants: items,
                categoryId,
                productId,
                messages: messages,
            });
        } catch (err) {
            Log.log(err);
            Toaster.error(err);
        }
        commit('SET_LOADING', false);

        return response;
    },
};

export default actions;

<template>
    <ul :v-if="!!routes" class="debug-route-tree">
        <li v-for="route in routes" :key="route.name" :data-depth="depth">
            <router-link :to="{ name: route.name }">{{ route.name }}</router-link>
            <RouteTree :routes="route.children" :depth="depth + 1" />
        </li>
    </ul>
</template>

<script>
export default {
    name: 'RouteTree',
    props: {
        routes: {
            type: Array,
            default: () => {
                return [];
            },
        },
        depth: {
            type: Number,
            default: 0,
        },
    },
};
</script>

<style lang="scss">
.debug-route-tree {
    padding: 0;

    li {
        margin-left: 30px;
    }

    a {
        color: #000;
        display: inline-block;
        padding: 5px 0;
    }

    .router-link-exact-active {
        color: rgb(200, 100, 000);
    }

    .router-link-active {
        font-weight: bold;
    }
}
</style>

<template>
    <FlyoutPage data-test="create-new-category-content">
        <HeaderBar slot="header">
            <HeaderBarItem slot="left" button @click="$root.routeBack()">
                <ArrowIcon width="32" height="18" />
            </HeaderBarItem>
            <div slot="headline">
                {{
                    category.id
                        ? $t('pages.categoryFormPage.listItem.editCategory')
                        : $t('pages.categoryFormPage.listItem.newCategory')
                }}
            </div>
            <Words v-if="category.updated" slot="subline" muted>
                {{ $t('pages.categoryFormPage.listItem.modifiedAt') }}
                {{ $d(new Date(category.updated * 1000), 'medium') }}
            </Words>
            <Words v-else-if="category.created" slot="subline" muted>
                {{ $t('pages.categoryFormPage.listItem.createdAt') }}
                {{ $d(new Date(category.created * 1000), 'medium') }}
            </Words>
        </HeaderBar>

        <div class="container-deprecated">
            <form @submit.prevent="handleSubmit">
                <GridRow :count="2" spacing="large">
                    <div class="span-2 span-lg-1">
                        <GridRow :count="1">
                            <TextField
                                v-for="lang in availableLanguageCodes"
                                :key="lang"
                                v-model="category.name[lang]"
                                :label="
                                    $t('pages.categoryFormPage.listItem.nameWithLocale', {
                                        locale: lang.toUpperCase(),
                                    })
                                "
                                :error="getError(`category.name.${lang}`)"
                            />

                            <div>
                                <Headline :level="6"
                                    >{{ $t('pages.categoryFormPage.listItem.categoryPicture.headline') }}
                                </Headline>
                                <div class="category-management__image-upload">
                                    <Thumbnail :src="category.image && category.image.url" inline modal />
                                    <ImageUpload
                                        v-model="category.image"
                                        product-use-case="product_category_image"
                                        inline
                                        @error="handleFileError"
                                    />
                                </div>
                                <ErrorMessage
                                    v-if="fileError"
                                    :message="fileError"
                                    class="span-4"
                                    style="margin-top: 1em"
                                />
                            </div>

                            <div>
                                <Headline :level="6">
                                    {{ $t('pages.categoryFormPage.listItem.categoryStatus.headline') }}
                                </Headline>
                                <ToggleSwitchField v-model="category.isActive" data-test="category-toggle-switch">
                                    {{
                                        $t('pages.categoryFormPage.listItem.categoryStatus.label', {
                                            status: category.isActive
                                                ? $t('pages.categoryFormPage.listItem.categoryStatus.active')
                                                : $t('pages.categoryFormPage.listItem.categoryStatus.inactive'),
                                        })
                                    }}
                                </ToggleSwitchField>
                            </div>
                        </GridRow>
                    </div>

                    <div class="span-2 span-lg-1">
                        <Headline :level="6">{{
                            $t('pages.categoryFormPage.listItem.superCategory.headline')
                        }}</Headline>
                        <SelectionTree
                            v-model="category.parentId"
                            :tree="categoryTree"
                            :disabled-value="category.id"
                            disable-subtree
                            name="parent"
                        />
                    </div>
                </GridRow>
            </form>
        </div>

        <ButtonGroup slot="footer">
            <span v-if="category.id && category.parentId" class="span--place-left" @click="deleteCategory">
                <span class="button__slot button__slot--left">
                    <GarbageIcon slot="left" class="icon--inline" />
                </span>
                <span v-if="$root.isDesktop" data-test="category-delete-button">
                    {{ $t('pages.categoryFormPage.listItem.footer.deleteBtn') }}
                </span>
            </span>
            <Button :to="{ name: $root.findRouteName(managementPageRoute) }" arrow-left primary light>
                {{ $t('pages.categoryFormPage.listItem.footer.cancelBtn') }}
            </Button>
            <Button type="click" primary data-test="category-save-button" @click="handleSubmit">
                <TickIcon slot="left" width="18" height="18" class="icon--inline" />
                {{ $t('pages.categoryFormPage.listItem.footer.nextBtn') }}
            </Button>
        </ButtonGroup>
    </FlyoutPage>
</template>

<script>
import { availableLanguageCodes } from '@/i18n/languages';
import { CONSTRUCTION_PRODUCT, WASTE_PRODUCT } from '@/constants/productTypes';
import { localize, revertLocalizedCollectionValues, localizeObject } from '@/services/utils/localization';
import validate from '@/services/validation/mixin';
import { isRequired } from '@/services/validation/rules';
import Toaster from '@/services/Toaster';

import FlyoutPage from '@/components/Layout/FlyoutPage';
import Headline from '@/components/Typography/Headline';
import Words from '@/components/Typography/Words';
import TextField from '@/components/Form/TextField.v2';
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import Category from '@/services/Api/Platform/Category';
import SelectionTree from '@/components/Tree/SelectionTree';
import TreeNode from '@/components/Tree/TreeNode';
import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import GridRow from '@/components/Layout/GridRow';
import Thumbnail from '@/components/Thumbnail';
import ImageUpload from '@/components/Form/ImageUpload';
import ErrorMessage from '@/components/Form/ErrorMessage';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';

import ArrowIcon from '@/assets/icons/regular/arrow.svg';
import GarbageIcon from '@/assets/icons/regular/garbage.svg';
import TickIcon from '@/assets/icons/micro/tick.svg';

const routeMap = {
    [CONSTRUCTION_PRODUCT]: 'construction',
    [WASTE_PRODUCT]: 'waste',
};

export default {
    name: 'CategoryFormPage',
    components: {
        FlyoutPage,
        Headline,
        Words,
        TextField,
        ToggleSwitchField,
        SelectionTree,
        Thumbnail,
        ImageUpload,
        Button,
        ButtonGroup,
        GridRow,
        ErrorMessage,
        GarbageIcon,
        TickIcon,
        HeaderBar,
        HeaderBarItem,
        ArrowIcon,
    },
    mixins: [validate],
    props: {
        listType: {
            type: String,
            validator: v => [CONSTRUCTION_PRODUCT, WASTE_PRODUCT].includes(v),
            default: CONSTRUCTION_PRODUCT,
        },
    },
    data() {
        return {
            category: {
                id: null,
                name: localize(null),
                parentId: null,
                isActive: false,
                created: null,
                updated: null,
            },
            isPending: false,
            fileError: null,
            categoryTree: null,
            validationRules: {
                'category.name.de': [isRequired()],
            },
            availableLanguageCodes,
        };
    },
    computed: {
        managementPageRoute() {
            return this.$root.findRouteName(`${routeMap[this.listType]}-category`);
        },
    },
    created() {
        const categoryId = this.$route.params.id;
        if (categoryId) {
            Category.getOneById(categoryId)
                .then(category => {
                    this.category = localizeObject(category, ['name']);
                })
                .catch(err => {
                    Toaster.error(err);
                });
        }

        Category.getCategoryByType(this.listType).then(categories => {
            categories = revertLocalizedCollectionValues(categories, ['name']);
            this.$set(this, 'categoryTree', TreeNode.factory(categories));
        });
    },
    methods: {
        async handleSubmit() {
            if (!this.isValid() || this.isPending) {
                return;
            }

            this.isPending = true;

            try {
                await Category.saveCategory(this.category, this.listType);

                this.$router.push({ name: this.$root.findRouteName(this.managementPageRoute) }).catch(() => {
                    // Error handled somewere
                });
            } catch (err) {
                Toaster.error(err);
            }

            this.isPending = false;
        },
        async deleteCategory() {
            if (!confirm(this.$t('pages.categoryFormPage.listItem.confirmMsg')) || this.category.id === null) {
                return;
            }

            try {
                await Category.delete(this.category.id);
                this.$router.push({ name: this.$root.findRouteName(this.managementPageRoute) }).catch(() => {
                    // Error handled somewere
                });
            } catch (err) {
                Toaster.error(err);
            }
        },
        handleFileError(error) {
            this.fileError = error;
        },
    },
};
</script>

<style lang="scss">
.category-management {
    .flyout__inner {
        padding-top: 20px;
    }
}

.category-management__group {
    margin: -30px 0 0 -30px;
}

.category-management__group--vertical {
    display: flex;
    flex-direction: row;
}

.category-management__field-group {
    width: 100%;
    margin: 30px 0 0 30px;
}

.category-management__field {
    & + & {
        margin-top: 20px;
    }
}

.category-management__image-upload {
    display: flex;
    flex-flow: row nowrap;
}

.span--place-left {
    margin-right: auto;
    transition: opacity 0.1s ease-in-out;
    cursor: pointer;
    padding-left: 20px;
    text-align: left;

    &:hover,
    &:focus,
    &:active {
        opacity: 0.7;
        transition: opacity 0.1s ease-in-out;
    }

    @media only screen and (min-width: $layout-desktop-min) {
        padding-left: 0;
        text-align: center;
    }
}
</style>

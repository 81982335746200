<template>
    <ContentWrapper v-if="$root.isDesktop" max-width="1172">
        <AddressFinder
            :existing-address="address"
            :existing-location="location"
            :is-read-only="isReadOnly"
            :is-loading="isLoading"
            :allowed-countries="allowedCountries"
            :error="componentErrors('address')"
            @handleAddressUpdate="$emit('input', $event)"
        />
    </ContentWrapper>
    <ContentWrapper v-else class="mb-8">
        <LocationPicker
            v-model="newLocation"
            enable-search
            ensure-state
            :initial-address="address"
            @input-address="handleLocationPickerAddressUpdate"
            @flyoutOpen="$emit('flyoutOpen', $event)"
        >
            <div slot-scope="{ editLocation }" class="address-picker__address-field" @click="editLocation">
                <TextField
                    :label="$t('components.addressPicker.addressFieldLabel')"
                    :value="addressLabel"
                    readonly
                    disabled
                />
            </div>
        </LocationPicker>
        <OpenLocationCodeField :location="location" />
    </ContentWrapper>
</template>

<script>
import { formatAddressObject } from '@/services/utils/address';
import validate from '@/services/validation/mixin';

import AddressFinder from '@/_components/AddressFinder/AddressFinder';
import ContentWrapper from '@/_components/ContentWrapper/ContentWrapper';
import LocationPicker from '@/pages/Checkout/components/LocationPicker';
import TextField from '@/components/Form/TextField.v2';
import OpenLocationCodeField from '@/components/Form/OpenLocationCodeField';

export default {
    components: {
        AddressFinder,
        ContentWrapper,
        LocationPicker,
        TextField,
        OpenLocationCodeField,
    },
    mixins: [validate],
    props: {
        address: {
            type: Object,
            default: null,
        },
        location: {
            type: Object,
            default: null,
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        allowedCountries: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            newLocation: { ...this.location },
        };
    },
    computed: {
        addressLabel() {
            return formatAddressObject({
                address: this.address,
                includeState: this.includeState,
                includeCountry: this.includeCountry,
            });
        },
    },
    methods: {
        handleLocationPickerAddressUpdate(event) {
            this.$emit('input', {
                address: { ...event },
                location: event.location,
            });
        },
    },
};
</script>

<template>
    <div v-if="canTransportUnplan || canTransportPlan" class="mt-[2px] flex gap-4">
        <SfLink v-if="canTransportUnplan" type="secondary" size="md" @click.stop="triggerAction(UNPLAN_TRANSPORT())">
            <template #leading-icon="iconProps"><SfDeleteIcon v-bind="iconProps" /></template>
            {{ $t('components.transportListBlock.actions.deletePlanning') }}
        </SfLink>
        <SfLink
            v-if="canTransportPlan"
            type="secondary"
            size="md"
            :disabled="!isAllowedToPlanTransport"
            @click.stop="planTransport()"
        >
            <template #leading-icon="iconProps"><SfSwitchIcon v-bind="iconProps" /></template>
            {{ $t('components.transportListBlock.actions.changePlanning') }}
        </SfLink>
    </div>
</template>

<script>
import { UNPLAN_TRANSPORT } from '@/services/TransportActions';

import { SfLink, SfDeleteIcon, SfSwitchIcon } from '@schuettflix/vue-components';

/**
 * !!! ATTENTION !!!
 * This component and all of it's children can not have computed
 * properties since they introduce a performance issues on unmounting
 */
export default {
    name: 'TransportListBlockInlineActions',
    components: {
        SfLink,
        SfDeleteIcon,
        SfSwitchIcon,
    },
    props: {
        transport: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            canTransportUnplan: this.$can('transportUnplan', this.transport),
            canTransportPlan: this.$can('transportPlan', this.transport),
            isAllowedToPlanTransport: this.$can('isAllowedToPlanTransport', this.transport),
        };
    },

    methods: {
        UNPLAN_TRANSPORT: () => UNPLAN_TRANSPORT,
        triggerAction(action) {
            this.$store.dispatch('transportActions/set', {
                action,
                transport: this.transport,
                context: this.routeContext,
            });
        },

        planTransport() {
            this.$store.dispatch('transportPlan/setTransports', [this.transport]);

            this.$router
                .push({
                    name: this.$root.findRouteName('transport-plan'),
                    params: { transportId: this.transport.id },
                    query: this.$route.query,
                })
                .catch(() => {});
        },
    },
};
</script>

import axios from 'axios';
import i18n from '@/i18n';
import { AbstractResourceErrorHandler } from './AbstractResourceErrorHandler';
import ApiError from '@/services/Api/ApiError';
import environmentHint from '@/services/utils/environmentHint';
import * as telemetry from '@/services/Telemetry';
import _toPairs from 'lodash/toPairs';
import { setAuthToken, setLocale, setBaseURL, setAppVersion, setAppName, setImageUploadErrorHandler, setDocumentUploadErrorHandler, } from '@schuettflix/client-file';
const appVersion = process.env.VUE_APP_VERSION;
const createErrorHandler = (messages) => (error) => {
    if (axios.isCancel(error)) {
        return error;
    }
    const imageErrorHandler = new AbstractResourceErrorHandler(messages);
    const newError = imageErrorHandler.handleError(((error) => new ApiError(Number(error.code), error.message, error.response, error))(error));
    if (newError instanceof Error) {
        return newError;
    }
    return error;
};
setImageUploadErrorHandler(createErrorHandler({
    404: 'components.imageUpload.errors.notFound',
    408: 'components.imageUpload.errors.requestTimeout',
}));
setDocumentUploadErrorHandler((error) => {
    const err = createErrorHandler({
        404: 'components.fileUpload.errors.notFound',
        408: 'components.fileUpload.errors.requestTimeout',
    })(error);
    if (err instanceof ApiError) {
        err.message =
            err.response?.data?.errors?.file?.[0] || i18n.t('components.imageDocumentUploader.uploadErrorMsg');
    }
    return err;
});
export class ApiService {
    axios;
    startRequestHandler;
    finishRequestHandler;
    constructor() {
        const headers = { 'Content-Type': 'application/json' };
        setAppName('vue-monolith');
        if (appVersion) {
            headers['X-Client-Version'] = appVersion;
            setAppVersion(appVersion);
        }
        this.axios = axios.create({
            timeout: 180000,
            withCredentials: false,
            headers,
        });
        telemetry.instrumentAxios(this.axios);
    }
    setAuthorizationHeader(token) {
        if (token) {
            setAuthToken(token);
            this.axios.defaults.headers.common['Api-Authorization'] = `Bearer ${token}`;
        }
        else {
            delete this.axios.defaults.headers.common['Api-Authorization'];
        }
    }
    setBaseUrl(baseUrl) {
        let targetBaseUrl = baseUrl;
        // fallback for invalid values
        if ('string' !== typeof targetBaseUrl) {
            targetBaseUrl = '/api';
        }
        const matches = targetBaseUrl.match(/\/\/(.*?:.*?)@/im);
        if (matches) {
            targetBaseUrl = targetBaseUrl.replace(/\/\/.*?:.*?@/im, '//');
            const credentials = btoa(matches[1]);
            this.axios.defaults.headers.common.Authorization = `Basic ${credentials}`;
        }
        setBaseURL(targetBaseUrl);
        this.axios.defaults.baseURL = targetBaseUrl;
        environmentHint(targetBaseUrl);
    }
    setAcceptLanguage(lang) {
        setLocale(lang);
        this.axios.defaults.headers.common['Accept-Language'] = lang;
    }
    mapApiError(err) {
        let message;
        if (axios.isCancel(err) || !axios.isAxiosError(err)) {
            return err;
        }
        if (err.code === 'ECONNABORTED' || err.response?.status === 504) {
            message = i18n.t('abstractresource.errors.requestTimeout');
        }
        else {
            const errorData = err.response?.data;
            const specificErrorMessages = _toPairs(errorData?.errors);
            const [firstErrorMessage] = specificErrorMessages || [];
            const errorMessage = firstErrorMessage?.[1] || errorData?.message;
            message = errorMessage || err.message;
        }
        return new ApiError(err.response?.status, message, err.response, err);
    }
    async get(path, config, opts = {}) {
        try {
            this.startRequest(opts);
            return await this.axios.get(path, config);
        }
        catch (err) {
            throw this.mapApiError(err);
        }
        finally {
            this.finishRequest(opts);
        }
    }
    async post(path, data = undefined, opts = {}) {
        try {
            this.startRequest(opts);
            return await this.axios.post(path, data);
        }
        catch (err) {
            throw this.mapApiError(err);
        }
        finally {
            this.finishRequest(opts);
        }
    }
    async put(path, data, opts = {}) {
        try {
            this.startRequest(opts);
            return await this.axios.put(path, data);
        }
        catch (err) {
            throw this.mapApiError(err);
        }
        finally {
            this.finishRequest(opts);
        }
    }
    async patch(path, data, opts = {}) {
        try {
            this.startRequest(opts);
            return await this.axios.patch(path, data);
        }
        catch (err) {
            throw this.mapApiError(err);
        }
        finally {
            this.finishRequest(opts);
        }
    }
    async delete(path, data, opts = {}) {
        try {
            this.startRequest(opts);
            return await this.axios.delete(path, { data: data });
        }
        catch (err) {
            throw this.mapApiError(err);
        }
        finally {
            this.finishRequest(opts);
        }
    }
    async request(config, opts = {}) {
        try {
            this.startRequest(opts);
            return await this.axios.request(config);
        }
        catch (err) {
            throw this.mapApiError(err);
        }
        finally {
            this.finishRequest(opts);
        }
    }
    setStartRequestHandler(handler) {
        this.startRequestHandler = handler;
    }
    setFinishRequestHandler(handler) {
        this.finishRequestHandler = handler;
    }
    startRequest(opts) {
        this.startRequestHandler?.(opts);
    }
    finishRequest(opts) {
        this.finishRequestHandler?.(opts);
    }
}
export default new ApiService();

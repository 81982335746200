<template>
    <FormWrapper v-if="form" data-test="FactoryOpeningTimes">
        <div class="form__title">{{ $t('components.factoryManagement.factoryOpeningHours.title') }}</div>
        <ErrorMessage v-if="firstError[`openingHours`]" :message="firstError[`openingHours`]" />
        <div class="form__hours-list">
            <FormFieldGroup
                v-for="(day, index) in weekdays"
                :key="index"
                :class="`factory-form-opening-hours-${index}`"
                data-test="openingHoursRow"
                no-bottom-margin
                no-bottom-padding
            >
                <template #title>
                    <Words spaced-bottom>
                        <Words bold>{{ $t(`weekdayNames.${day}`) }}</Words>
                        <ErrorMessage
                            v-if="firstError[`openingHours[${getIndexOfDay(day)}]`]"
                            :message="firstError[`openingHours[${getIndexOfDay(day)}]`]"
                        />
                    </Words>

                    <div class="form__toggle-switch">
                        <Words v-if="hasTimeEntryForDay(day)" spaced-right-tiny bold>{{
                            $t('components.factoryManagement.factoryOpeningHours.opened')
                        }}</Words>
                        <Words v-else spaced-right-tiny bold>{{
                            $t('components.factoryManagement.factoryOpeningHours.closed')
                        }}</Words>
                        <ToggleSwitch
                            small
                            color="green"
                            :disabled="isReadOnly"
                            :value="hasTimeEntryForDay(day)"
                            @input="toggleFactoryActiveState(index, day, $event)"
                        />
                    </div>
                </template>
                <div class="form__grid-row">
                    <template v-if="getIndexOfDay(day) >= 0">
                        <ComponentWrapper width-full margin-bottom-large>
                            <template #component>
                                <TextField
                                    v-maska="maskaPattern"
                                    :value="form.openingHours[getIndexOfDay(day)].start"
                                    :readonly="isReadOnly"
                                    :label="$t('components.factoryManagement.factoryOpeningHours.start')"
                                    :has-error="
                                        !!firstError[`openingHours[${getIndexOfDay(day)}].start`] ||
                                        hasErrors(`form.openingHours.${getIndexOfDay(day)}.start`, submitted) ||
                                        !!firstError[`openingHours[${getIndexOfDay(day)}]`]
                                    "
                                    :error="
                                        firstError[`openingHours[${getIndexOfDay(day)}].start`] ||
                                        getError(`form.openingHours.${getIndexOfDay(day)}.start`, submitted)
                                    "
                                    class="form__text-field form__text-field--opening-hours form__text-field--am"
                                    @input="updateOpeningHours(day, 'start', $event)"
                                />
                            </template>
                        </ComponentWrapper>
                        <ComponentWrapper width-full>
                            <template #component>
                                <TextField
                                    v-maska="maskaPattern"
                                    :value="form.openingHours[getIndexOfDay(day)].end"
                                    :readonly="isReadOnly"
                                    :label="$t('components.factoryManagement.factoryOpeningHours.end')"
                                    :has-error="
                                        !!firstError[`openingHours[${getIndexOfDay(day)}].end`] ||
                                        hasErrors(`form.openingHours.${getIndexOfDay(day)}.end`, submitted) ||
                                        !!firstError[`openingHours[${getIndexOfDay(day)}]`]
                                    "
                                    :error="
                                        firstError[`openingHours[${getIndexOfDay(day)}].end`] ||
                                        getError(`form.openingHours.${getIndexOfDay(day)}.end`, submitted)
                                    "
                                    class="form__text-field form__text-field--opening-hours form__text-field--pm"
                                    @input="updateOpeningHours(day, 'end', $event)"
                                />
                            </template>
                        </ComponentWrapper>
                    </template>
                    <template v-else>
                        <ComponentWrapper width-full margin-bottom-large>
                            <template #component>
                                <TextField
                                    disabled
                                    :readonly="isReadOnly"
                                    :value="DEFAULT_START_TIME"
                                    :label="$t('components.factoryManagement.factoryOpeningHours.start')"
                                    class="form__text-field form__text-field--opening-hours form__text-field--am"
                                />
                            </template>
                        </ComponentWrapper>
                        <ComponentWrapper width-full>
                            <template #component>
                                <TextField
                                    disabled
                                    :readonly="isReadOnly"
                                    :value="DEFAULT_END_TIME"
                                    :label="$t('components.factoryManagement.factoryOpeningHours.end')"
                                    class="form__text-field form__text-field--opening-hours form__text-field--pm"
                                />
                            </template>
                        </ComponentWrapper>
                    </template>
                </div>
            </FormFieldGroup>
        </div>
    </FormWrapper>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _find from 'lodash/find';
import { maska } from 'maska';

import { isRequired } from '@/services/validation/rules';

import sectionResetMixin from '@/plugins/mixins/factoryForm/sectionResetMixin';
import validate from '@/services/validation/mixin';
import sectionMixin from '@/plugins/mixins/factoryForm/sectionMixin';

import ComponentWrapper from '@/_components/ComponentWrapper/ComponentWrapper';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormFieldGroup from '@/_components/FormFieldGroup/FormFieldGroup';
import FormWrapper from '@/_components/FormWrapper/FormWrapper';
import TextField from '@/components/Form/TextField.v2';
import ToggleSwitch from '@/components/Form/ToggleSwitch';
import Words from '@/components/Typography/Words';
import { WEEKDAY, WEEKDAY_LIST } from '@/constants/time';

const DEFAULT_START_TIME = '07:00';
const DEFAULT_END_TIME = '17:00';

const defaultOpeningHours = [
    { weekday: WEEKDAY.MONDAY, end: DEFAULT_END_TIME, start: DEFAULT_START_TIME },
    { weekday: WEEKDAY.TUESDAY, end: DEFAULT_END_TIME, start: DEFAULT_START_TIME },
    { weekday: WEEKDAY.WEDNESDAY, end: DEFAULT_END_TIME, start: DEFAULT_START_TIME },
    { weekday: WEEKDAY.THURSDAY, end: DEFAULT_END_TIME, start: DEFAULT_START_TIME },
    { weekday: WEEKDAY.FRIDAY, end: DEFAULT_END_TIME, start: DEFAULT_START_TIME },
];

export default {
    name: 'FactoryOpeningHours',
    directives: { maska },
    components: {
        ComponentWrapper,
        ErrorMessage,
        FormFieldGroup,
        FormWrapper,
        TextField,
        ToggleSwitch,
        Words,
    },
    mixins: [validate, sectionResetMixin, sectionMixin],
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            DEFAULT_START_TIME,
            DEFAULT_END_TIME,
            weekdays: WEEKDAY_LIST,
        };
    },
    computed: {
        form() {
            return this.value;
        },
        validationRules() {
            const rules = {};
            // Opening times validation
            Object.keys(this.form?.openingHours).forEach(i => {
                rules[`form.openingHours.${i}.start`] = [isRequired()];
                rules[`form.openingHours.${i}.end`] = [isRequired()];
            });

            return rules;
        },
        maskaPattern() {
            return {
                mask: 'TH:MH',
                tokens: { T: { pattern: /[0-2]/ }, H: { pattern: /[0-9]/ }, M: { pattern: /[0-5]/ } },
            };
        },
        hasFactoryOpeningTimes() {
            return this.form?.openingHours?.length > 0;
        },
    },
    watch: {
        value: {
            deep: true,
            handler(data) {
                this.sectionDataBackupOpenStatusReset(data.open);
            },
        },
        form: {
            deep: true,
            handler(sectionData) {
                this.handleEmits(sectionData);
            },
        },
    },
    mounted() {
        this.$emit('dirty-check', false);
        if (!this.hasFactoryOpeningTimes) {
            this.$set(this.sectionDataBackup, 'openingHours', _cloneDeep(defaultOpeningHours));
            this.$set(this.form, 'openingHours', _cloneDeep(defaultOpeningHours));
        } else {
            this.$set(this.sectionDataBackup, 'openingHours', _cloneDeep(this.form.openingHours));
        }
        this.handleEmits(this.form);
    },
    methods: {
        handleEmits(sectionData) {
            this.$emit('dirty-check', this.checkDirty(sectionData.openingHours, this.sectionDataBackup.openingHours));
            this.$emit('confirmation-dialog', null);
            this.$emit('valid-check', this.isValid(false));
        },
        hasTimeEntryForDay(weekday) {
            return !!this.form?.openingHours?.filter(day => day.weekday === weekday)[0];
        },
        getIndexOfDay(weekday) {
            return this.form?.openingHours?.findIndex(obj => obj.weekday === weekday);
        },
        toggleFactoryActiveState(index, weekday, checked) {
            let openingHours = _cloneDeep(this.form?.openingHours);

            if (!openingHours) {
                openingHours = [];
            }

            if (checked) {
                if (_find(openingHours, { weekday })) return;

                openingHours.splice(index, 0, {
                    weekday,
                    start: this.DEFAULT_START_TIME,
                    end: this.DEFAULT_END_TIME,
                });
            } else {
                const foundIndex = this.getIndexOfDay(weekday);
                openingHours.splice(foundIndex, 1);
            }

            this.$set(this.form, 'openingHours', openingHours);
        },
        updateOpeningHours(day, key, value) {
            this.update(`openingHours[${this.getIndexOfDay(day)}].${key}`, value);
        },
    },
};
</script>

<style lang="scss" scoped>
.form {
    &__title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 24px;
    }

    &__hours-list {
        margin-top: 24px;
    }

    &__grid-row {
        display: grid;
        grid-gap: 16px;
        grid-template-columns: 1fr 1fr;
        width: 100%;
    }

    &__text-field {
        width: 100%;
    }
}
</style>

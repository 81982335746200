<template>
    <div>
        <div class="flex flex-col">
            <span class="font-copy-sm text-subdued">
                {{ titles.deliveredQty }}
            </span>
            <span class="font-copy-md-strong mt-2 justify-self-end whitespace-nowrap" data-test="deliveredQty">
                {{ contingentStatistics.deliveredQty | tons }}
            </span>
        </div>
        <div class="flex flex-col">
            <span class="font-copy-sm text-subdued">
                {{ titles.reservedQty }}
            </span>
            <span class="font-copy-md-strong mt-auto whitespace-nowrap" data-test="reservedQty">
                {{ contingentStatistics.reservedQty | tons }}
            </span>
        </div>
        <div class="flex flex-col">
            <span class="font-copy-sm text-subdued">
                {{ $t('pages.project.position.view.main.remaining') }}
            </span>
            <span class="font-copy-md-strong mt-auto whitespace-nowrap" data-test="remainingQty">
                {{ contingentStatistics.remainingQty | tons }}
            </span>
        </div>
    </div>
</template>

<script>
import ContingentStatisticsViewModel from '@/pages/Checkout/components/ContingentSelection/models/ContingentStatisticsViewModel';
import ProjectPositionView from '@/models/ProjectPositionView';

export default {
    name: 'ContingentStatistics',
    props: {
        projectPosition: {
            type: ProjectPositionView,
            required: true,
        },
    },
    computed: {
        contingentStatistics() {
            return this.projectPosition instanceof ProjectPositionView
                ? ContingentStatisticsViewModel.createFromProjectPositionViewModel(this.projectPosition)
                : null;
        },
        titles() {
            if (this.projectPosition.isDisposal) {
                return {
                    deliveredQty: this.$t('components.projectPositionSelectionBlock.stat.disposal.delivered'),
                    reservedQty: this.$t('components.projectPositionSelectionBlock.stat.disposal.reserved'),
                };
            }
            return {
                deliveredQty: this.$t('components.projectPositionSelectionBlock.stat.delivered'),
                reservedQty: this.$t('components.projectPositionSelectionBlock.stat.reserved'),
            };
        },
    },
};
</script>

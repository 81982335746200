<template>
    <div class="list-label">
        <slot />
        <slot name="action" @click="$emit('click', $event)" />
    </div>
</template>

<script>
export default {
    name: 'ListLabel',
};
</script>

<style lang="scss">
.list-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 10px 10px;
    background-color: #f2f2f2;
    font-size: 12px;
    line-height: 15px;
    font-weight: $font-weight-bold;
    box-shadow: 0 15px 20px -20px rgba(0, 0, 0, 0.4);
}
</style>

import Toaster from '@/services/Toaster';
import store from '@/store';

import FactoryManagementPage from '@/pages/FactoryManagement';
import TokenManagementPage from '@/pages/TokenManagement';
import OrganizationFormPage from '@/pages/Organization/OrganizationFormPage';
import PlatformSettings from '@/pages/PlatformSettings/PlatformSettings';
import SettingsPage from './SettingsPage';
import UserListPage from '@/pages/User/UserListPage';
import PreferredCarrierRoute from '@/pages/PreferredCarrier';
import CertificateManagementListPage from '@/pages/CertificateManagement/CertificateManagementListPage';
import CertificateManagementEditPage from '@/pages/CertificateManagement/CertificateManagementEditPage';
import { getVehicleClassRoutes } from '@/pages/VehicleClass';
import { getVehicleManagementRoutes } from '@/pages/Vehicle';

export default {
    path: 'settings',
    name: 'settings',
    component: SettingsPage,
    meta: {
        requiresAuth: true,
    },
    children: [
        {
            path: 'organization-management',
            name: 'organization-management',
            component: OrganizationFormPage,
            props: {
                parentRoute: 'settings',
            },
            beforeEnter: (to, from, next) => {
                if (!store.getters['abilities/can']('viewOrganization', store.getters['user/organization'])) {
                    Toaster.info('You are not allowed to access this page.');
                    return next({ name: from.name });
                }

                return next();
            },
        },
        {
            path: 'user-management',
            name: 'user-management',
            component: UserListPage,
            meta: {
                requiredAbilities: ['haveUserManagement'],
            },
            props: {
                parentRoute: 'settings',
            },
        },
        ...getVehicleClassRoutes('settings'),
        ...getVehicleManagementRoutes('settings'),
        {
            ...FactoryManagementPage,
            props: {
                ...FactoryManagementPage.props,
                parentRoute: 'settings',
            },
        },
        {
            ...TokenManagementPage,
            props: {
                ...TokenManagementPage.props,
                parentRoute: 'settings',
            },
        },
        {
            path: 'platform-settings-management/:group?',
            name: 'platform-settings-management',
            component: PlatformSettings,
            props: {
                parentRoute: 'settings',
            },
            meta: {
                requiredAbilities: ['haveGlobalVariablesManagement'],
            },
        },
        {
            ...PreferredCarrierRoute,
            props: {
                ...PreferredCarrierRoute.props,
                parentRoute: 'settings',
            },
        },
        {
            path: 'certificate-management',
            name: 'certificate-management',
            component: CertificateManagementListPage,
            props: {
                parentRoute: 'settings',
            },
            children: [
                {
                    path: ':certificateId',
                    name: 'certificate-management-edit',
                    component: CertificateManagementEditPage,
                    props: true,
                },
            ],
        },
    ],
};

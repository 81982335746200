import React, { useEffect, useState } from 'react';
import { useVueEmit } from '@/reactBridge/EmitVueEventProvider';
import { useTranslation } from 'react-i18next';
import { PaymentTermsSelection } from '@/features/PaymentTermsSelection';
import clsx from 'clsx';
import { TextField } from '@schuettflix/react-components';
export const PaymentTermsContainer = ({ organizations, paymentTermsProp }) => {
    const { emitVueEvent } = useVueEmit();
    const [paymentTerms, setPaymentTerms] = useState(organizations.map(org => ({ ...org, paymentTermId: 1 })));
    const { t } = useTranslation();
    const sortOrganizationsManually = (paymentTerms) => {
        if (!paymentTerms)
            return null;
        return paymentTerms.sort((a, b) => {
            if (a.organizationType === 'supplier')
                return -1;
            if (b.organizationType === 'supplier')
                return 1;
            return 0;
        });
    };
    const getDefaultPaymentTermsForOrgs = () => {
        return sortOrganizationsManually(organizations.map(({ organizationId, organizationType }) => {
            return {
                organizationId,
                organizationType,
                paymentTermId: 1,
            };
        }));
    };
    const hasUndefinedPaymentTermsFromProps = !paymentTermsProp || paymentTermsProp?.every((paymentTerm) => paymentTerm.paymentTermId === null);
    useEffect(() => {
        // On first render, we need to emit the paymentTerms to set them to the vuex store in vue,
        // which in the first render is basically the organizations object with paymentTermIds equal to null
        // Once the store is set, paymentTermsProp will be set in this component and another useEffect hook takes over
        // to apply changes within the paymentTermsProp to the local component state
        if (paymentTermsProp) {
            setPaymentTerms(paymentTermsProp);
            return;
        }
        const defaultPaymentTerms = getDefaultPaymentTermsForOrgs();
        setPaymentTerms(defaultPaymentTerms);
        emitVueEvent('defaultPaymentTerms', defaultPaymentTerms);
        emitVueEvent('change', defaultPaymentTerms);
    }, []);
    useEffect(() => {
        if (hasUndefinedPaymentTermsFromProps) {
            return;
        }
        setPaymentTerms(paymentTermsProp);
        //es-lint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentTermsProp]);
    if (!paymentTerms)
        return null;
    const handleChangedPaymentTerms = (paymentTermId, paymentTerm) => {
        setPaymentTerms(paymentTerms.map((term) => {
            if (term.organizationId === paymentTerm.organizationId) {
                return { ...term, paymentTermId: paymentTermId };
            }
            return term;
        }));
        emitVueEvent('change', { ...paymentTerm, paymentTermId });
    };
    const hasCarrier = paymentTerms.some(org => org.organizationType === 'carrier');
    const renderPaymentTerms = () => {
        return paymentTerms.map((term, index) => {
            if (!term.paymentTermId)
                return null;
            const org = {
                id: term.organizationId,
                type: term.organizationType,
            };
            return (<PaymentTermsSelection key={`${term.organizationId}-${index}`} paymentTermId={term.paymentTermId} onChange={paymentTermId => handleChangedPaymentTerms(paymentTermId, term)} organizationType={org.type} className={clsx({ 'mb-6': index !== paymentTerms.length - 1 })}/>);
        });
    };
    return (<>
            <span className="font-copy-md-strong">{t('paymentTerms.title')}</span>
            <span className="font-copy-md mb-6 mt-2">{t('pages.checkout.priceAdjustments.paymentTerms.subTitle')}</span>

            {renderPaymentTerms()}
            {!hasCarrier && (<>
                    <span className="font-copy-md-strong mb-2 mt-6">{t('paymentTerms.organizationTypes.carrier')}</span>
                    <TextField disabled label={t('paymentTerms.title')}/>
                    <span className="font-copy-md mt-2 text-disabled">
                        {t('pages.checkout.priceAdjustments.paymentTerms.missingCarrier')}
                    </span>
                </>)}
        </>);
};

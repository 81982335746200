import { render, staticRenderFns } from "./calendar.svg?vue&type=template&id=92a06a98&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_webpack@5.88.2_webpack-cli@5.1.4___lodash@4.17.21_react-d_j5jn3zdz4uzijexvn7gkk7yebe/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
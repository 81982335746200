import router from '@/pages/router';
import store from '@/store';
import DeepLinkService from './DeepLinkService';
import { EventBus } from '@/services/EventBus';
import Toaster from '@/services/Toaster';
import i18n from '@/i18n';
import _isEqual from 'lodash/isEqual';
export async function handleNotificationMessage(message) {
    if (message.receiverUserId !== store.getters['user/id']) {
        try {
            await store.dispatch('user/changeUser', message.receiverUserId);
        }
        catch (err) {
            Toaster.error(String(i18n.t('pushnotification.userChange.error')));
            return;
        }
    }
    const nextRoute = DeepLinkService.getRouteFromMessage(message);
    if (!nextRoute)
        return;
    const currentRoute = router.currentRoute;
    if (currentRoute.name === nextRoute.name && _isEqual(currentRoute.params, nextRoute.params)) {
        EventBus.$emit('notification.route-update');
    }
    else {
        router.push(nextRoute).catch(() => { });
    }
}

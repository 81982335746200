import { forwardRef } from 'react';
import clsx from 'clsx';
export const PdfIcon = forwardRef(({ className, size = 24, ...svgProps }, ref) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48" fill="none" ref={ref} className={clsx('stroke-icon', className)} {...svgProps}>
            <path d="M37.995 18.0273V44H10V4H23.996" stroke="#35343D" strokeWidth="4" strokeMiterlimit="10"/>
            <path d="M40 12.003H30V2" stroke="#FF0000" strokeWidth="4" strokeMiterlimit="9.56677"/>
            <path d="M30.585 3.43066L38.585 11.4304" stroke="#FF0000" strokeWidth="4" strokeMiterlimit="10"/>
            <g clipPath="url(#clip0_1179_45707)">
                <path d="M32.9951 32.007V33.24H29.8981V34.596H32.9301V35.828H29.8981V38.291H28.4951V32.007H32.9951Z" fill="#35343D"/>
                <path d="M26.0962 32.8216C25.3711 32.2315 24.4494 31.9379 23.5166 32H21.2939V38.2842H23.4446C24.4138 38.3628 25.3749 38.0561 26.1194 37.4306C26.4178 37.1256 26.6497 36.762 26.8004 36.3628C26.9511 35.9635 27.0173 35.5374 26.9949 35.1113C27.0186 34.6874 26.9506 34.2635 26.7956 33.8683C26.6405 33.4732 26.4019 33.1162 26.0962 32.8216ZM25.068 36.559C24.6561 36.9061 24.1256 37.0801 23.5881 37.0444H22.6983V33.2238H23.4807C24.0547 33.1767 24.6243 33.3559 25.068 33.723C25.2473 33.9121 25.3856 34.1361 25.4742 34.3811C25.5628 34.6261 25.5999 34.8868 25.583 35.1468C25.601 35.4051 25.5644 35.6643 25.4756 35.9076C25.3869 36.1509 25.2481 36.3728 25.068 36.559Z" fill="#35343D"/>
                <path d="M19.3286 32.5757C18.7116 32.1499 17.966 31.9514 17.219 32.0142H15V38.3005H16.4008V36.5456H17.2369C17.9686 36.6014 18.6981 36.4123 19.3105 36.008C19.5482 35.7963 19.7334 35.532 19.8511 35.2361C19.9688 34.9403 20.0159 34.6211 19.9887 34.3038C20.0141 33.9848 19.9681 33.6641 19.8539 33.3652C19.7397 33.0662 19.5602 32.7965 19.3286 32.5757ZM18.2988 35.0501C18.1675 35.1594 18.0154 35.2408 17.8516 35.2894C17.6879 35.338 17.516 35.3527 17.3463 35.3327H16.4008V33.2199H17.2027C17.5573 33.1935 17.9119 33.2726 18.2216 33.4474C18.337 33.5388 18.4277 33.6576 18.4854 33.793C18.5431 33.9284 18.566 34.0761 18.552 34.2227C18.5731 34.5203 18.4828 34.8152 18.2988 35.0501Z" fill="#35343D"/>
            </g>
            <defs>
                <clipPath id="clip0_1179_45707">
                    <rect width="17.9951" height="6.30054" fill="white" transform="translate(15 32)"/>
                </clipPath>
            </defs>
        </svg>);
});
PdfIcon.displayName = 'DocumentIcon';

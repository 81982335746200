<template>
    <Tag v-if="isDangerous" small bold unbreakable outlined class="waste-tag waste-tag--dangerous">
        <WarningInfoIcon class="warning-info-icon" />
        {{ $t('pages.productManagement.wasteProductEditPage.tags.waste_dangerous') }}
    </Tag>
    <Tag v-else small bold unbreakable outlined class="waste-tag">
        {{ $t('pages.productManagement.wasteProductEditPage.tags.waste_non_dangerous') }}
    </Tag>
</template>
<script>
import Tag from '@/components/Typography/Tag';

import WarningInfoIcon from '@/assets/icons/micro/warning-info.svg';

export default {
    name: 'WasteTag',
    components: { Tag, WarningInfoIcon },
    props: {
        isDangerous: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style lang="scss" scoped>
.waste-tag {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    line-height: 1.25rem;
    border-width: 1px;

    &.waste-tag--dangerous {
        border-color: var(--color-red, red);
    }
}

.warning-info-icon {
    height: 14px;
    width: 14px;
    margin-right: 4px;
    stroke: $color-black;
}
</style>

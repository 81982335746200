import _get from 'lodash/get';
import AbstractFilterableResource from './AbstractFilterableResource';

class Notification extends AbstractFilterableResource {
    async markRead() {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/read`, {});
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async getSubscriptions() {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/subscription`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async updateSubscriptions(data) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/subscription`, data);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Update notification token
     */
    async updatePushNotificationToken(token) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/pushnotificationtoken`, {
                deviceType: _get(window, 'device.platform', 'generic').toLowerCase(),
                token,
            });
            return response.data.token;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }

    /**
     * Delete notification token
     */
    async deletePushNotificationToken(token) {
        try {
            return this.apiService.post(`${this.resourcePath}/pushnotificationtoken/remove`, { token });
        } catch (err) {
            throw this.handleError(err).message;
        }
    }
}

export default new Notification('/notification', {
    401: 'pushnotification.errors.unauthorized',
    403: 'pushnotification.errors.forbidden',
    404: 'pushnotification.errors.notFound',
});

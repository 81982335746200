import AbstractResource from '@/services/Api/AbstractResource';
class NetSuite extends AbstractResource {
    async getNetSuitePaymentTerms() {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/payment-terms`);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err).message;
        }
    }
}
export default new NetSuite('/v2/netsuite');

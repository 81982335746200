<template>
    <div class="dropdown-trigger" @click="$emit('click', $event)">
        <Button type="button" image stretched class="dropdown-trigger__button">
            <slot />
        </Button>
        <ArrowIcon width="14" height="14" class="dropdown-trigger__arrow" />
    </div>
</template>

<script>
import Button from '@/components/Button/Button';
import ArrowIcon from '@/assets/icons/regular/arrow-stripeless--down.svg';

export default {
    name: 'DropDownTrigger',
    components: {
        Button,
        ArrowIcon,
    },
};
</script>

<style scoped lang="scss">
.dropdown-trigger {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: none;
    cursor: pointer;
    display: flex;
    height: 70px;
    line-height: $line-height-base;
    outline: none;
    overflow: hidden;
    padding: 27px 40px 22px 15px;
    position: relative;
    text-overflow: ellipsis;
    transition:
        border-color 0.2s ease,
        padding 0.2s ease;

    &:hover {
        opacity: 0.5;
    }
}

.dropdown-trigger__arrow {
    height: 14px;
    line-height: 1;
    pointer-events: none;
    position: absolute;
    right: 17px;
    top: 37px;
    transform: translateY(-50%);
    transition: top 0.2s ease;
    width: 14px;
    z-index: 11;
}

.dropdown-trigger__button {
    width: 100%;
}

.dropdown-trigger__button::v-deep .button__content {
    width: 100%;
}
</style>

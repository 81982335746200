<template>
    <ContextMenu v-if="hasMenuItems" @click="contextAction($event)" @opened="track()">
        <template #default="{ selectAction }">
            <ContextMenuEntry
                v-for="(menuItem, i) in menuItems"
                :key="i"
                :action="menuItem.action"
                @click="selectAction($event)"
            >
                <component :is="menuItem.icon" slot="left" class="icon--inline" />
                {{ $t(menuItem.title) }}
            </ContextMenuEntry>
        </template>
    </ContextMenu>
</template>

<script>
import routeContext from '@/plugins/mixins/routeContext';
import { ACTIONS } from '@/constants/actions';

import ContextMenu from '@/components/Button/ContextMenu';
import ContextMenuEntry from '@/components/Button/ContextMenuEntry';

import PenIcon from '@/assets/icons/micro/pen.svg';
import StopIcon from '@/assets/icons/micro/stop.svg';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';

export default {
    name: 'OrderContextMenu',
    components: {
        ContextMenu,
        ContextMenuEntry,

        PenIcon,
        StopIcon,
    },
    mixins: [routeContext],
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isPickup() {
            return this.order.lineItemGroups.every(group => group.type === ORDER_LINE_ITEM_GROUP_TYPE.PICKUP);
        },
        isDelivery() {
            return this.order.lineItemGroups.every(group => group.type === ORDER_LINE_ITEM_GROUP_TYPE.DELIVERY);
        },
        isShipment() {
            return this.order.lineItemGroups.every(group => group.type === ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT);
        },
        hasMenuItems() {
            return this.menuItems.length > 0;
        },
        menuItems() {
            const menuItems = [];

            if (this.$can('updateDisposalPriceOnShipmentOrder', this.order) && this.isShipment) {
                menuItems.push({
                    action: ACTIONS.ORDER_DISPOSAL_PRICE_CHANGE,
                    title: 'contextMenu.order.changeDisposalPrice',
                    icon: 'PenIcon',
                });
            }

            if (this.$can('orderForceCancel', this.order)) {
                menuItems.push({
                    action: ACTIONS.ORDER_FORCE_CANCEL,
                    title: 'contextMenu.order.forceCancel',
                    icon: 'StopIcon',
                });
            }

            // TODO check via orderCancel ability
            // TODO only one action and endpoint to cancel an order?
            else if (this.$can('listOrders') && this.isPickup && this.order.status === 'new') {
                menuItems.push({
                    action: ACTIONS.ORDER_CANCEL,
                    title: 'contextMenu.order.cancel',
                    icon: 'StopIcon',
                });
            }

            // TODO check via orderClose ability
            if (this.$can('listOrders') && this.isPickup && this.order.status === 'in_progress') {
                menuItems.push({
                    action: ACTIONS.ORDER_CLOSE,
                    title: 'contextMenu.order.close',
                    icon: 'StopIcon',
                });
            }

            return menuItems;
        },
    },
    methods: {
        contextAction(key) {
            this.$store.dispatch('globalActions/set', {
                action: key,
                subject: this.order,
                context: this.routeContext,
            });
        },
        track() {
            AnalyticsService.trackClick('orderDetailView', 'contextMenuOpen', { orderId: this.order.id });
        },
    },
};
</script>

export const SHIPMENT_FALLBACK_TIME_CONSTRAINT_FROM = '07:00';
export const SHIPMENT_FALLBACK_TIME_CONSTRAINT_TO = '17:00';
export const MONDAY = 'Monday';
export const TUESDAY = 'Tuesday';
export const WEDNESDAY = 'Wednesday';
export const THURSDAY = 'Thursday';
export const FRIDAY = 'Friday';
export const SATURDAY = 'Saturday';
export const SUNDAY = 'Sunday';
export const WEEKDAY = {
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY,
};
export const WEEKDAY_LIST = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];

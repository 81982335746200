<template>
    <ul
        v-if="tree"
        :class="{
            'tree--root': isRoot,
            'tree--clickable': clickHandler,
        }"
        class="tree"
    >
        <li
            v-for="node in tree.children"
            :key="node.item.id"
            :data-depth="node.depth"
            :class="{ 'tree__item--active': isActive(node.item, node) }"
            class="tree__item"
        >
            <div class="tree__name" @click="handleClick(node.item, node)">{{ node.item.name }}</div>
            <Tree
                v-if="node.children.length"
                :tree="node"
                :is-root="false"
                :click-handler="clickHandler"
                :active-handler="activeHandler"
            />
        </li>
    </ul>
</template>

<script>
import TreeNode from './TreeNode';

export default {
    name: 'Tree',
    props: {
        tree: {
            type: TreeNode,
            default: null,
        },
        children: {
            type: Array,
            default: () => [],
        },
        depth: {
            type: Number,
            default: 0,
        },
        clickHandler: {
            type: Function,
            default: null,
        },
        activeHandler: {
            type: Function,
            default: null,
        },
        isRoot: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        handleClick(item, node) {
            this.clickHandler && this.clickHandler(item, node);
        },
        isActive(item, node) {
            return this.activeHandler && this.activeHandler.call(null, item, node);
        },
    },
};
</script>

<style lang="scss">
.tree {
    padding: 0;
}

.tree__item {
    margin-left: 30px;
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 21px;
        bottom: 13px;
        left: -15px;
        width: 1px;
        border-left: 1px dotted $color-grey;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 13px;
        left: -44px;
        right: calc(100% + 20px);
        height: 1px;
        border-top: 1px dotted $color-grey;
    }
}

.tree--root {
    > .tree__item::after {
        display: none;
    }
}

.tree--clickable {
    .tree__name {
        cursor: pointer;

        &:hover {
            font-weight: $font-weight-bold;
        }
    }
}

.tree__item--active {
    > .tree__name {
        color: $color-red;
        font-weight: bold;
    }
}

.tree__name {
    display: inline-block;
    padding: 5px 0;
}
</style>

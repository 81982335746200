<template>
    <LayoutPage class="invoice-list" :child-routes="['invoice-document-list']">
        <template #pageTitle>{{ $t('pages.invoice.title') }}</template>
        <div class="invoice-list__filter">
            <Card spaceless>
                <FilterBox
                    v-model="invoiceListing.filter"
                    :default-filter="invoiceListing.defaultFilter"
                    :endpoint="invoiceListing.endpoint"
                    inline-mode
                    no-padding
                    @update="invoiceListing.refreshList(false, true, true)"
                >
                    <template #default="filterScope">
                        <GridRow :count="4" class="project-list-filter__filters">
                            <TextField
                                v-model="filterScope.filter.search"
                                :label="$t('pages.invoice.filter.search')"
                                class="span-12 span-sm-3"
                            >
                                <template #icon>
                                    <SearchIcon />
                                </template>
                            </TextField>

                            <SelectBox
                                v-model="filterScope.filter.status"
                                :label="$t('pages.invoice.filter.statusHeadline')"
                                :options="statusOptions"
                                :option-value-renderer="option => option"
                                :option-label-renderer="
                                    option =>
                                        option == null
                                            ? $t('pages.invoice.filter.statusAllLabel')
                                            : $t(`status.invoice.${option}`)
                                "
                                class="span-12 span-sm-1"
                            />
                        </GridRow>

                        <FilterSortPagination
                            :result="invoiceListing.result"
                            :filter="filterScope.filter"
                            :sort-options="invoiceListing.sortOptions"
                            :option-label-renderer="value => $t(`pages.invoice.sortings.${value}`)"
                            :hide-sort="!$root.isDesktop"
                            show-refresh
                            spaceless
                            :loading="invoiceListing.isLoading"
                            @refresh="invoiceListing.refreshList(false, true, true)"
                            @pageNumberUpdated="invoiceListing.updatePageNumber($event)"
                        >
                            <FilterBox
                                v-model="invoiceListing.filter"
                                :default-filter="invoiceListing.defaultFilter"
                                :endpoint="invoiceListing.endpoint"
                                :headline="$t('pages.invoice.filterTitle')"
                                :ignore-in-count="[
                                    'search',
                                    'organization',
                                    'user',
                                    'startShippingWindow',
                                    'endShippingWindow',
                                    'transportNumber',
                                    'status',
                                ]"
                                :button-label="$t('pages.order.orderList.filter')"
                                @save="invoiceListing.refreshList(false, true, true)"
                            >
                                <template #default="{ filter: filterFromSlot, updateFilter }">
                                    <InvoiceFilterSet
                                        :filter-scope="{ filter: filterFromSlot }"
                                        :endpoint="invoiceListing.endpoint"
                                        @updateFilter="updateFilter"
                                    />
                                </template>
                            </FilterBox>
                        </FilterSortPagination>
                    </template>
                </FilterBox>
            </Card>
        </div>

        <transition name="fade" mode="out-in">
            <LoadingSpinner v-if="invoiceListing.isLoading" block dark />
            <div v-else>
                <div class="invoice-list__hd">
                    <!-- <BaseButton v-if="$can('createInvoice')" primary @click="openSelectTypeDialog()">
                        <template #left>
                            <PlusIcon class="icon--inline" />
                        </template>
                        {{ $t('pages.invoice.actions.createInvoice') }}
                    </BaseButton> -->
                    <BaseButton
                        v-if="$can('createInvoice')"
                        data-test="create-invoice-button"
                        primary
                        @click="showInvoiceAccountingWizard = true"
                    >
                        <template #left>
                            <PlusIcon class="icon--inline" />
                        </template>
                        {{ $t('pages.invoice.actions.createInvoice') }}
                    </BaseButton>
                    <Words class="invoice-list__headline" block bold>
                        {{ $tc('pages.invoice.countLabel', invoiceListing.resultCount) }}
                    </Words>
                </div>

                <GridTable v-if="invoiceListing.resultCount > 0" columns="1fr 1fr 1fr 1fr 1fr 1fr 1fr">
                    <template #head>
                        <GridTableHead v-stuckable="20">
                            <div>{{ $t('pages.invoice.headlines.number') }}</div>
                            <div>{{ $t('pages.invoice.headlines.creationDate') }}</div>
                            <div>{{ $t('pages.invoice.headlines.companyName') }}</div>
                            <div>{{ $t('pages.invoice.headlines.id') }}</div>
                            <div>{{ $t('pages.invoice.headlines.type') }}</div>
                            <div>{{ $t('pages.invoice.headlines.totalGross') }}</div>
                            <div>{{ $t('pages.invoice.headlines.status') }}</div>
                        </GridTableHead>
                    </template>

                    <GridTableRow v-for="invoice in invoiceListing.resultItems" :key="`${invoice.id}`" spaced pointless>
                        <div class="grid-table__cell">
                            <Words data-test="invoice-number" block>
                                {{ invoice.number }}
                            </Words>
                        </div>

                        <div class="grid-table__cell">
                            <Words block>
                                {{ $d(ensureJSTimestamp(invoice.created), 'short') }}
                            </Words>
                        </div>

                        <div class="grid-table__cell">
                            <Words block>
                                {{ invoice.billingAddressName }}
                            </Words>
                        </div>

                        <div class="grid-table__cell">
                            <Words middle>
                                {{ invoice.customerNumber }}
                            </Words>
                        </div>

                        <div class="grid-table__cell">
                            <Words bold tiny middle>
                                {{ $t(`pages.invoice.type.${invoice.type}`) }}
                            </Words>
                        </div>

                        <div class="grid-table__cell">
                            <Words bold middle>
                                {{ $n(invoice.totalGross, 'currency') }}
                            </Words>
                        </div>

                        <div class="grid-table__cell">
                            <Words
                                block
                                align-right
                                small
                                bold
                                :red="invoice.status === 'cancelled'"
                                :yellow="invoice.status === 'booked'"
                                :green="invoice.status === 'paid'"
                                class="invoice-list__words--baseline"
                            >
                                {{ $t(`status.invoice.${invoice.status}`) }}
                            </Words>
                            <Words v-if="statusTimestamp(invoice)" block tiny align-right>
                                {{ $d(statusTimestamp(invoice), 'short') }} &nbsp;
                                {{ $t('timeSuffix', { time: $d(statusTimestamp(invoice), 'time') }) }}
                            </Words>
                        </div>

                        <div class="grid-table__cell--foot">
                            <div class="invoice-list__buttons">
                                <ButtonLink
                                    data-test="access-all-invoice-documents"
                                    :title="$t('pages.project.view.info.allDocumentsButtonLabel')"
                                    has-arrow-right
                                    @click="
                                        () => {
                                            $router.push({
                                                name: $root.findRouteName('invoice-document-list'),
                                                params: {
                                                    invoiceId: invoice.id,
                                                },
                                            });
                                        }
                                    "
                                />
                                <BaseButton
                                    v-if="invoice.document"
                                    :href="invoice.document.url"
                                    class="invoice-list__button"
                                >
                                    <Words bold arrow-right>
                                        <BillingIcon class="invoice-list__icon icon--inline" />
                                        {{ $t('pages.invoice.actions.downloadPdf') }}
                                        <span class="invoice-list__button-arrow">›</span>
                                    </Words>
                                </BaseButton>
                                <BaseButton
                                    v-if="
                                        $can('createCreditNoteCancellation') &&
                                        invoice.actualType !== 'credit-note-correction'
                                    "
                                    class="invoice-list__button"
                                    @click="showCancellation = invoice.number"
                                >
                                    <Words bold red arrow-right>
                                        <CancelIcon class="invoice-list__icon icon--inline" />
                                        {{ $t('pages.invoice.actions.cancelInvoice') }}
                                        <span class="invoice-list__button-arrow">›</span>
                                    </Words>
                                </BaseButton>
                            </div>
                        </div>
                    </GridTableRow>
                </GridTable>

                <Hint v-if="invoiceListing.resultCount === 0" center transparent>
                    {{ $t('pages.invoice.noResults') }}
                </Hint>

                <Card v-if="invoiceListing.resultCount > 0" spaceless>
                    <Pagination
                        align-right
                        :result="invoiceListing.result"
                        @pageNumberUpdated="invoiceListing.updatePageNumber($event)"
                    />
                </Card>
            </div>
        </transition>

        <CreateInvoice
            :active.sync="showInvoiceAccountingWizard"
            @success="() => invoiceListing.refreshList(false, true, true)"
        />
        <CreateCancellation
            :active.sync="showCancellation"
            :document-number="showCancellation"
            @success="() => invoiceListing.refreshList(false, true, true)"
        />
    </LayoutPage>
</template>

<script>
import { ref } from 'vue';
import { ensureJSTimestamp } from '@/services/utils/date';
import InvoiceApi from '@/services/Api/Platform/Invoice';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import { statusTimestamp } from '@/services/utils/creditNote';
import FilterableListing from '@/services/FilterableListing/FilterableListing';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';

import BaseButton from '@/components/Button/Button';
import Card from '@/components/Layout/Card';
import FilterBox from '@/components/Filter/FilterBox';
import FilterSortPagination from '@/components/Filter/FilterSortPagination';
import GridRow from '@/components/Layout/GridRow';
import GridTable from '@/components/Table/GridTable';
import GridTableHead from '@/components/Table/GridTableHead';
import GridTableRow from '@/components/Table/GridTableRow';
import Hint from '@/components/Typography/Hint';
import InvoiceFilterSet from './components/InvoiceFilterSet';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import Pagination from '@/components/Pagination';
import SelectBox from '@/components/Form/SelectBox.v3';
import TextField from '@/components/Form/TextField.v2';
import Words from '@/components/Typography/Words';
import ButtonLink from '@/_components/ButtonLink/ButtonLink';

import CreateInvoice from './CreateInvoice.vue';
import CreateCancellation from './CreateCancellation.vue';

import BillingIcon from '@/assets/icons/regular/billing.svg';
import CancelIcon from '@/assets/icons/regular/cancel.svg';
import PlusIcon from '@/assets/icons/regular/plus.svg';
import SearchIcon from '@/assets/icons/micro/search.svg';

export default {
    name: 'InvoiceListPage',
    components: {
        ButtonLink,
        BaseButton,
        Card,
        FilterBox,
        FilterSortPagination,
        GridRow,
        GridTable,
        GridTableHead,
        GridTableRow,
        Hint,
        InvoiceFilterSet,
        LayoutPage,
        LoadingSpinner,
        Pagination,
        SelectBox,
        TextField,
        Words,
        CreateInvoice,
        CreateCancellation,

        BillingIcon,
        CancelIcon,
        PlusIcon,
        SearchIcon,
    },
    mixins: [persistentFiltersMixin],
    setup() {
        const { selectCreditNoteDocKey } = useLegalTerms();

        const showInvoiceAccountingWizard = ref(false);
        const showCancellation = ref(false);

        return {
            showInvoiceAccountingWizard,
            selectCreditNoteDocKey,
            showCancellation,
        };
    },
    data() {
        const invoiceListing = new FilterableListing({
            namespace: 'invoice',
            endpoint: InvoiceApi,
            defaultFilter: {
                page: 1,
                perPage: 25,
                sortBy: 'number',
                sortDirection: 'desc',
            },
            sortOptions: ['number', 'created', 'totalGross', 'status', 'type'],
        });

        return {
            dataEndpoint: InvoiceApi,
            invoices: null,
            invoiceListing,
        };
    },
    computed: {
        statusOptions() {
            const options = this.changeableStatusOptions ?? [];
            return { all: null, ...options };
        },

        changeableStatusOptions() {
            return this.invoiceListing.result?.supportedFilters?.status?.options ?? [];
        },
    },
    watch: {
        $route() {
            this.invoiceListing.onRouteChanged();
        },
    },
    created() {
        this.invoiceListing.pairComponent(this);
        this.invoiceListing.refreshList(true);
    },
    methods: {
        ensureJSTimestamp,
        statusTimestamp,

        closeInvoiceCancellation() {
            this.showCancellation = false;
            this.invoiceListing.refreshList(false, true, true);
        },
    },
};
</script>

<style lang="scss">
.invoice-list__words--baseline {
    margin-top: 3px;
}

.invoice-list__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.invoice-list__filter {
    margin-bottom: 30px;
}

.invoice-list__hd {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    padding-right: 15px;
    padding-left: 15px;

    > * + * {
        margin-top: 30px;
    }

    @media screen and (min-width: $layout-desktop-min) {
        flex-direction: row-reverse;
        justify-content: space-between;
        padding-right: 0;
    }
}

.invoice-list__headline {
    margin-right: auto;
}

.invoice-list__button {
    margin-left: 40px;
}

.invoice-list__icon {
    margin-right: 5px;
}

.invoice-list__button-arrow {
    margin-left: 5px;
}
</style>

const state = {
    isEnabled: false,
    isInit: false,
    isAllowed: false,
};

const getters = {
    isEnabled: state => state.isEnabled,
    isInit: state => state.isInit,
    isAllowed: state => state.isAllowed,
};

const mutations = {
    initialize(state) {
        state.isInit = true;
    },
    enable(state) {
        state.isEnabled = true;
    },
    disable(state) {
        state.isEnabled = false;
    },
    allow(state) {
        state.isAllowed = true;
    },
    disallow(state) {
        state.isAllowed = false;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};

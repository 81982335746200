export const COST_CENTER_SOURCE = {
    UNKNOWN: 'unknown',
    LOADING_SITE: 'loadingSite',
    UNLOADING_SITE: 'unloadingSite',
};
export const COST_CENTER_TYPE = {
    UNKNOWN: 'unknown',
    CONSTRUCTION_SITE: 'constructionSite',
    PROJECT: 'project',
};

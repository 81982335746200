<template>
    <div
        :class="{
            'circle-spinner--dark': dark,
            'circle-spinner--tiny': tiny,
        }"
        class="circle-spinner"
    >
        <div class="circle-spinner__spinner">&nbsp;</div>
    </div>
</template>

<script>
export default {
    name: 'CircleSpinner',
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
        tiny: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.circle-spinner {
    position: relative;
    margin: 0px 10px;
}

.circle-spinner--tiny {
    margin: 0;

    .circle-spinner__spinner {
        width: 14px;
        height: 14px;
    }
}

.circle-spinner__spinner {
    width: 20px;
    height: 20px;

    border-radius: 50%;
    border-top: 3px solid rgba(255, 255, 255, 0.2);
    border-right: 3px solid rgba(255, 255, 255, 0.2);
    border-bottom: 3px solid rgba(255, 255, 255, 0.2);
    border-left: 3px solid #ffffff;

    transform: translateZ(0);
    animation: circleSpineAnimation 1.1s infinite linear;
}

.circle-spinner--dark .circle-spinner__spinner {
    border-left-color: #000;
    border-top-color: rgba(0, 0, 0, 0.2);
    border-right-color: rgba(0, 0, 0, 0.2);
    border-bottom-color: rgba(0, 0, 0, 0.2);
}

@keyframes circleSpineAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>

import _intersection from 'lodash/intersection';
import _omit from 'lodash/omit';
import _cloneDeep from 'lodash/cloneDeep';

/**
 * DependencyError
 */
export class DependencyError extends Error {
    constructor(types = [], features = []) {
        super('Dependency missmatch');
        this.types = types;
        this.features = features;
    }
}

class FeatureManager {
    constructor(definition = null) {
        this.definition = null;
        this.features = {};
        this.featureDependencyMap = {};
        this.typeDependencyMap = {};

        if (definition) {
            this.loadDefinition(definition);
        }
    }

    loadDefinition(definition) {
        this.definition = definition;

        let features = {
            ...definition.globalFeatures,
        };

        Object.values(definition.organizationTypes).forEach(typeConfig => {
            features = {
                ...features,
                ...typeConfig.features,
            };
        });

        this.features = features;

        this.updateDependencyMaps();
    }

    _updateFeatureDependency(dependencyMap, featureKey, featureConfig, type = null) {
        if (!dependencyMap[featureKey]) {
            dependencyMap[featureKey] = {
                // Can add feature if one of types present
                types: [],

                // Can remove feature if no of mandatoryTypes present
                mandatoryTypes: [],

                // Alias: MandatoryFeatures since, they remove all dependencies while removed
                features: [],
            };
        }

        const dependencyNode = dependencyMap[featureKey];

        if (type !== null) {
            if (!dependencyNode.types.includes(type)) {
                dependencyNode.types.push(type);
            }

            if (featureConfig.mandatory && !dependencyNode.mandatoryTypes.includes(type)) {
                dependencyNode.mandatoryTypes.push(type);
            }
        }

        // Check if the iterated feature is a dependency somewhere
        Object.keys(this.features).forEach(k => {
            const fc = this.features[k];

            if (fc.requiredFeatures.includes(featureKey)) {
                dependencyNode.features.push(k);
            }
        });

        return dependencyMap;
    }

    _updateTypeDependency(dependencyMap, type) {
        if (!dependencyMap[type]) {
            dependencyMap[type] = {
                types: [],

                // Required features for the given type
                mandatoryFeatures: [],
            };
        }

        const dependencyNode = dependencyMap[type];

        // Check if the iterated type is a dependency somewhere
        Object.keys(this.definition.organizationTypes).forEach(t => {
            const tc = this.definition.organizationTypes[t];

            if (tc.requiredTypes.includes(type)) {
                dependencyNode.types.push(t);
            }
        });

        const typeConfig = this.definition.organizationTypes[type];
        Object.keys(typeConfig.features).forEach(k => {
            const fc = typeConfig.features[k];

            // Find all mandatory features for type
            if (fc.mandatory && !dependencyNode.mandatoryFeatures.includes(k)) {
                dependencyNode.mandatoryFeatures.push(k);
            }
        });

        return dependencyMap;
    }

    updateDependencyMaps() {
        let typeDependencyMap = {};
        let featureDependencyMap = {};

        const { organizationTypes, globalFeatures } = this.definition;

        Object.keys(organizationTypes).forEach(type => {
            const typeConfig = organizationTypes[type];

            typeDependencyMap = this._updateTypeDependency(typeDependencyMap, type);

            Object.keys(typeConfig.features).forEach(featureKey => {
                const feature = typeConfig.features[featureKey];
                featureDependencyMap = this._updateFeatureDependency(featureDependencyMap, featureKey, feature, type);
            });
        });

        Object.keys(globalFeatures).forEach(featureKey => {
            const feature = globalFeatures[featureKey];
            featureDependencyMap = this._updateFeatureDependency(featureDependencyMap, featureKey, feature);
        });

        this.featureDependencyMap = featureDependencyMap;
        this.typeDependencyMap = typeDependencyMap;
    }

    addType(type, currTypes, currFeatures) {
        if (currTypes.includes(type)) return [currTypes, currFeatures];

        const nextTypes = _cloneDeep(currTypes);
        const nextFeatures = _cloneDeep(currFeatures);

        return this._addType(type, nextTypes, nextFeatures);
    }

    addTypes(types, currTypes, currFeatures) {
        let nextTypes = _cloneDeep(currTypes);
        let nextFeatures = _cloneDeep(currFeatures);

        types.forEach(type => {
            [nextTypes, nextFeatures] = this.addType(type, nextTypes, nextFeatures);
        });

        return [nextTypes, nextFeatures];
    }

    _addType(type, currTypes = [], currFeatures = {}) {
        if (currTypes.includes(type)) return [currTypes, currFeatures];

        const { requiredTypes, incompatibleTypes } = this.definition.organizationTypes[type];

        let nextTypes = [...currTypes, type];
        let nextFeatures = { ...currFeatures };

        requiredTypes.forEach(type => {
            [nextTypes, nextFeatures] = this._addType(type, nextTypes, nextFeatures);
        });

        const invalidTypes = _intersection(nextTypes, incompatibleTypes);
        if (invalidTypes.length > 0) {
            throw new DependencyError(invalidTypes);
        }

        const dependencyNode = this.typeDependencyMap[type];
        dependencyNode.mandatoryFeatures.forEach(mandatoryFeatureKey => {
            nextFeatures = this._addFeature(mandatoryFeatureKey, nextFeatures, nextTypes);
        });

        return [nextTypes, nextFeatures];
    }

    _removeType(type, currTypes = [], currFeatures = {}, removingTypes = []) {
        if (!currTypes.includes(type)) return [currTypes, currFeatures];

        // Check for dependencies
        const dependencyNode = this.typeDependencyMap[type];
        dependencyNode.types.forEach(t => {
            if (currTypes.includes(t) && !removingTypes.includes(t)) {
                throw new DependencyError([t]);
            }
        });

        const { requiredTypes, features } = this.definition.organizationTypes[type];

        let nextTypes = currTypes.filter(t => t !== type);
        let nextFeatures = { ...currFeatures };

        requiredTypes.forEach(type => {
            [nextTypes, nextFeatures] = this._removeType(type, nextTypes, nextFeatures, removingTypes);
        });

        Object.keys(features).forEach(featureKey => {
            nextFeatures = this.removeFeature(featureKey, nextFeatures, nextTypes);
        });

        return [nextTypes, nextFeatures];
    }

    removeType(type, currTypes, currFeatures, removingTypes = []) {
        if (!currTypes.includes(type)) return [currTypes, currFeatures];

        const nextTypes = _cloneDeep(currTypes);
        const nextFeatures = _cloneDeep(currFeatures);

        return this._removeType(type, nextTypes, nextFeatures, removingTypes);
    }

    removeTypes(types, currTypes, currFeatures) {
        let nextTypes = _cloneDeep(currTypes);
        let nextFeatures = _cloneDeep(currFeatures);

        types.forEach(type => {
            [nextTypes, nextFeatures] = this.removeType(type, nextTypes, nextFeatures, types);
        });

        return [nextTypes, nextFeatures];
    }

    _addFeature(featureKey, nextFeatures, currTypes, chain = []) {
        // Do we have a circular dependency?
        if (chain.includes(featureKey)) {
            return nextFeatures;
        }

        const dependencyNode = this.featureDependencyMap[featureKey];

        if (dependencyNode.types.length > 0 && _intersection(dependencyNode.types, currTypes).length === 0) {
            throw new DependencyError(dependencyNode.types);
        }

        const feature = this.features[featureKey];

        if (feature.requiredFeatures.length > 0) {
            feature.requiredFeatures.forEach(requiredFeatureKey => {
                nextFeatures = this._addFeature(requiredFeatureKey, nextFeatures, currTypes, [...chain, featureKey]);
            });
        }

        let featureSettings = {};

        const { settings } = this.features[featureKey];

        if (settings) {
            featureSettings = Object.keys(settings).reduce((acc, key) => {
                acc[key] = settings[key].default ? settings[key].default : false;
                return acc;
            }, {});
        }

        return {
            ...nextFeatures,
            [featureKey]: featureSettings,
        };
    }

    addFeature(featureKey, currFeatures, currTypes) {
        if (currFeatures[featureKey]) return currFeatures;

        let nextFeatures = _cloneDeep(currFeatures);

        nextFeatures = this._addFeature(featureKey, nextFeatures, currTypes);

        return nextFeatures;
    }

    addFeatures(featureKeys, currFeatures, currTypes) {
        let nextFeatures = _cloneDeep(currFeatures);

        featureKeys.forEach(featureKey => {
            nextFeatures = this._addFeature(featureKey, nextFeatures, currTypes);
        });

        return nextFeatures;
    }

    _removeFeature(featureKey, nextFeatures, currTypes, chain = []) {
        // Do we have a circular dependency?
        if (chain.includes(featureKey) || !nextFeatures[featureKey]) {
            return nextFeatures;
        }

        const feature = this.features[featureKey];
        if (feature.requiredFeatures.length > 0) {
            feature.requiredFeatures.forEach(requiredFeatureKey => {
                nextFeatures = this._removeFeature(requiredFeatureKey, nextFeatures, currTypes, [...chain, featureKey]);
            });
        }

        // Validate action after the nextFeatures are calculated for that branch

        const dependencyNode = this.featureDependencyMap[featureKey];
        const requiredMandatoryTypes = _intersection(dependencyNode.mandatoryTypes, currTypes);
        const requiredFeatures = _intersection(dependencyNode.features, Object.keys(nextFeatures)).filter(
            fk => !chain.includes(fk)
        );

        // Check if feature is mandatory for any currType
        if (dependencyNode.mandatoryTypes.length > 0 && requiredMandatoryTypes.length > 0) {
            throw new DependencyError(requiredMandatoryTypes);
        }

        // Check if feature is dependent on any other feature in nextFeatures, exclude chain
        if (dependencyNode.features.length > 0 && requiredFeatures.length > 0) {
            throw new DependencyError([], requiredFeatures);
        }

        return _omit(nextFeatures, [featureKey]);
    }

    removeFeature(featureKey, currFeatures, currTypes) {
        if (!currFeatures[featureKey]) return currFeatures;

        let nextFeatures = _cloneDeep(currFeatures);

        nextFeatures = this._removeFeature(featureKey, nextFeatures, currTypes);

        return nextFeatures;
    }

    isFeatureActive(featureKey, currFeatures) {
        return Object.keys(currFeatures).includes(featureKey);
    }

    getTypeDependencies(type, currTypes) {
        return _intersection(this.typeDependencyMap[type].types, currTypes);
    }

    getFeatureDependencies(featureKey, currFeatures, currTypes) {
        try {
            this.removeFeature(featureKey, currFeatures, currTypes);
            return [[], []];
        } catch (err) {
            if (err instanceof DependencyError) {
                return [err.types, err.features];
            }
        }
    }

    getFeatureConfig(featureKey) {
        return this.features[featureKey];
    }

    canRemoveFeature(featureKey, currFeatures, currTypes) {
        try {
            this.removeFeature(featureKey, currFeatures, currTypes);
            return true;
        } catch (err) {
            if (err instanceof DependencyError) {
                return false;
            }
        }
    }

    // add({ types = [], features = [] }) -> next state
    // remove({ types = [], features = [] }) -> next state
    // dependentBy({ types = [], features = [] }) -> object list of dependent types and features
    // isDependent({ types = [], features = [] }) <-- alias with count on dependentBy results
    // generateTypeHint({ types = [], features = [] }) -> Dieser Typ wird durch TypA, TypB voraussgesetzt
    // generateFeatureHint({ types = [], features = [] }) -> Dieses Feature wird durch TypA und FeatureB vorrausgesetzt
}

export default FeatureManager;

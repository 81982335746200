import { revertLocalizedValue } from '@/services/utils/localization';

export default class FreightPricingViewModel {
    constructor() {
        this._info = null;
        this._qty = null; // in kg
    }

    /**
     * Create a view model for material pricing
     *
     * @param data
     * @return {FreightPricingViewModel|null}
     */
    static createFromProjectPositionPayload(data) {
        if (!data) return null;

        const view = new FreightPricingViewModel();

        if (data.product) {
            view.info = revertLocalizedValue(data.product.productName);
        }
        view.qty = data.qty;

        return view;
    }
    /**
     * Create a view model for material pricing
     *
     * @param data
     * @return {FreightPricingViewModel|null}
     */
    static create(data) {
        if (!data) return null;

        const view = new FreightPricingViewModel();

        view.info = data.info;
        view.qty = data.qty;

        return view;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            info: this.info,
            qty: this.qty,
        };
    }

    /**
     * Clone current instance
     * @return {FreightPricingViewModel|null}
     */
    clone() {
        return FreightPricingViewModel.create(this.unfold());
    }

    get info() {
        return this._info;
    }

    set info(value) {
        this._info = value;
    }

    get qty() {
        return this._qty;
    }

    set qty(value) {
        this._qty = value;
    }
}

import _get from 'lodash/get';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';
import _remove from 'lodash/remove';
import mapStyles from '@/config/mapStyles';
import { SECONDS } from '@/services/utils/time';

export const createMap = (google, elementId, options) => {
    options.styles = mapStyles;
    options.gestureHandling = 'greedy';

    return new google.maps.Map(document.getElementById(elementId), options);
};

export const maxZoomService = google => {
    return new google.maps.MaxZoomService();
};

export function getStaticMapUrl(location, zoom = 16, width = 800, height = 400, type = 'roadmap') {
    const parts = [
        `center=${location.lat},${location.lng}`,
        `scale=2`,
        `zoom=${zoom}`,
        `size=${width}x${height}`,
        `maptype=${type}`,
        `key=${window.googleapikey}`,
        `style=feature:water|element:geometry|color:0xe9e9e9|lightness:17`,
        `style=feature:landscape|element:geometry|color:0xf5f5f5|lightness:20`,
        `style=feature:road.highway|element:geometry.fill|color:0xffffff|lightness:17`,
        `style=feature:road.highway|element:geometry.stroke|color:0xffffff|lightness:29|weight:0.2`,
        `style=feature:road.arterial|element:geometry|color:0xffffff|lightness:18`,
        `style=feature:road.local|element:geometry|color:0xffffff|lightness:16`,
        `style=feature:poi|element:geometry|color:0xf5f5f5|lightness:21`,
        `style=feature:poi.park|element:geometry|color:0xdedede|lightness:21`,
        `style=element:labels.text.stroke|visibility:on|color:0xffffff|lightness:16`,
        `style=element:labels.text.fill|color:0x333333|lightness:40`,
        `style=element:labels.icon|visibility:off`,
        `style=feature:transit|element:geometry|color:0xf2f2f2|lightness:19`,
        `style=feature:administrative|element:geometry.fill|color:0xfefefe|lightness:20`,
        `style=feature:administrative|element:geometry.stroke|color:0xfefefe|lightness:17|weight:1.2`,
    ];

    return `https://maps.googleapis.com/maps/api/staticmap?${parts.join('&')}`;
}

const flagIcon = 'https://www.schuettflix.de/assets/images/icons/mapIcons/flag.png';
const pinIcon = 'https://www.schuettflix.de/assets/images/icons/mapIcons/pin.png';

export function orderViewMapUrl(constructionSiteLocation, supplierLocation, width = 800, height = 400) {
    const parts = [
        `scale=4`,
        `size=${width}x${height}`,
        `maptype=roadmap`,
        `key=${window.googleapikey}`,
        `style=feature:water|element:geometry|color:0xe9e9e9|lightness:17`,
        `style=feature:landscape|element:geometry|color:0xf5f5f5|lightness:20`,
        `style=feature:road.highway|element:geometry.fill|color:0xffffff|lightness:17`,
        `style=feature:road.highway|element:geometry.stroke|color:0xffffff|lightness:29|weight:0.2`,
        `style=feature:road.arterial|element:geometry|color:0xffffff|lightness:18`,
        `style=feature:road.local|element:geometry|color:0xffffff|lightness:16`,
        `style=feature:poi|element:geometry|color:0xf5f5f5|lightness:21`,
        `style=feature:poi.park|element:geometry|color:0xdedede|lightness:21`,
        `style=element:labels.text.stroke|visibility:on|color:0xffffff|lightness:16`,
        `style=element:labels.text.fill|color:0x333333|lightness:40`,
        `style=element:labels.icon|visibility:off`,
        `style=feature:transit|element:geometry|color:0xf2f2f2|lightness:19`,
        `style=feature:administrative|element:geometry.fill|color:0xfefefe|lightness:20`,
        `style=feature:administrative|element:geometry.stroke|color:0xfefefe|lightness:17|weight:1.2`,
        `markers=icon:${flagIcon}|${constructionSiteLocation.lat},${constructionSiteLocation.lng}`,
        `markers=icon:${pinIcon}|${supplierLocation.lat},${supplierLocation.lng}`,
    ];

    return `https://maps.googleapis.com/maps/api/staticmap?${parts.join('&')}`;
}

const GoogleMaps = new Promise(resolve => {
    const checkApi = setInterval(() => {
        if (window.google) {
            clearInterval(checkApi);
            resolve(window.google);
        }
    }, 100);
});

export default GoogleMaps;

const distancePromises = [];

export async function getDistance(fromLocation, toLocation) {
    const google = await GoogleMaps;
    const directionsService = new google.maps.DirectionsService();
    const request = {
        origin: fromLocation,
        destination: toLocation,
        travelMode: 'DRIVING',
    };

    const existingPromise = _find(distancePromises, o => _isEqual(o.request, request));

    if (existingPromise) {
        return existingPromise.promise;
    }

    const promise = new Promise((resolve, reject) => {
        directionsService.route(request, (result, status) => {
            if (status === 'OK') {
                const meta = _get(result, 'routes.0.legs.0', null);
                resolve({
                    distance: meta.distance.value,
                    duration: meta.duration.value,
                });
                _remove(distancePromises, o => _isEqual(o.request, request));
            } else {
                reject(status);
                _remove(distancePromises, o => _isEqual(o.request, request));
            }
        });
    });

    distancePromises.push({ request, promise });

    return promise;
}

export async function createMapMarker(MarkerIconPath, location, map) {
    const google = await GoogleMaps;

    /* @type {google.maps.Icon} */
    const icon = {
        url: MarkerIconPath,
        scaledSize: new google.maps.Size(50, 50), // maps adds wierd 16px to the marker
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(14, 44.6),
    };

    return new google.maps.Marker({
        position: location,
        map: map,
        title: 'Destination',
        icon: icon,
        zIndex: 2,
    });
}

/**
 * Prepare google maps api initialization
 */
export function setupGoogleMaps(store) {
    // ============================================================
    // Watch for store update to change API values
    // ============================================================
    store.watch(
        state => state.i18n.locale,
        newValue => {
            loadGoogleMapsScript(newValue);
        },
        {
            immediate: true,
        }
    );
}

/**
 * @param {string} locale
 * @returns {Promise<unknown>}
 */
function loadGoogleMapsScript({ locale }) {
    return new Promise((resolve, reject) => {
        const currentScript = document.getElementById('google-maps-api');
        if (currentScript) {
            // Destroy old API
            currentScript.remove();
            if (window.google) {
                delete window.google.maps;
            }
        }

        // Generate new Google Maps API script
        const newScript = document.createElement('script');
        newScript.id = 'google-maps-api';
        newScript.src = `https://maps.googleapis.com/maps/api/js?key=${window.googleapikey}&libraries=places,geometry&callback=googleMapsAPILoaded&language=${locale}`;

        document.querySelector('head').appendChild(newScript);

        const timeoutId = window.setTimeout(() => {
            delete window.googleMapsAPILoaded;
            reject();
        }, 30 * SECONDS);

        window.googleMapsAPILoaded = () => {
            timeoutId && clearTimeout(timeoutId);
            resolve();
        };
    });
}

<template>
    <div class="user-list">
        <Card v-if="$root.isDesktop" spaceless class="user-list__row-header" :class="userListRowClass">
            <div class="content">
                <Words bold small ellipsis unbreakable>
                    {{ $t('pages.user.userList.tableHeadings.id') }}
                </Words>
                <Words bold small ellipsis unbreakable>
                    {{ $t('pages.user.userList.tableHeadings.firstAndLastName') }}
                </Words>
                <Words bold small ellipsis unbreakable>
                    {{ $t('pages.user.userList.tableHeadings.username') }}
                </Words>
                <Words bold small ellipsis unbreakable class="user-list__xxl-visible">
                    {{ $t('pages.user.userList.tableHeadings.mobileNumber') }}
                </Words>
                <Words bold small ellipsis unbreakable>
                    {{ $t('pages.user.userList.tableHeadings.permissions') }}
                </Words>
                <Words bold small ellipsis unbreakable>
                    {{ $t('pages.user.userList.tableHeadings.status') }}
                </Words>
            </div>
        </Card>

        <transition name="fade" mode="out-in">
            <div :key="activeOrganizationType">
                <div v-for="item in items" :key="item.organization.id" class="user-list__organization gap-4">
                    <Card v-can:listAllUsers spaceless class="user-list__organization-row">
                        <div class="content">
                            <Words bold small ellipsis unbreakable>
                                {{ item.organization.name }} | {{ $t(item.organization.market.code) }}
                                <Words v-if="item.organization.status !== 'approved'" muted>
                                    {{ $t(`pages.user.userList.organizationStatusLabel.${item.organization.status}`) }}
                                </Words>
                            </Words>
                            <div class="user-list__organization-permissions">
                                <Tag v-for="type in item.organization.types" :key="type" black small bold>
                                    {{ $t(`pages.user.userForm.organizationTypes.${type}`) }}
                                </Tag>
                            </div>
                        </div>
                    </Card>

                    <Card
                        v-for="user in item.users"
                        :key="user.id"
                        clickable
                        spaceless
                        :class="userListRowClass"
                        @click="$emit('onClickUser', user)"
                    >
                        <div class="content">
                            <template v-if="$root.isDesktop">
                                <Words ellipsis unbreakable>{{ user.id }}</Words>
                                <Words ellipsis unbreakable>{{ user.firstName }} {{ user.lastName }}</Words>
                                <Words ellipsis unbreakable>{{ user.username }}</Words>
                                <Words ellipsis unbreakable class="user-list__xxl-visible">
                                    {{ assemblePhoneNumber(user.mobile) }}
                                </Words>
                                <div class="user-list__content-columns">
                                    <Words ellipsis unbreakable class="user-list__xxl-visible">
                                        {{ userPermissions(user.permissions) }}
                                    </Words>
                                    <BaseButton
                                        class="user-list__btn"
                                        arrow-right
                                        unbreakable
                                        @click.stop="$emit('onClickPermissions', user)"
                                    >
                                        <template #left>
                                            <InfoIcon class="icon--inline icon--mono" width="13" height="13" />
                                        </template>
                                        {{ $t('pages.user.userList.permissions') }}
                                    </BaseButton>
                                </div>
                                <div class="user-list__content-columns">
                                    <Words unbreakable :green="user.isActive" :muted="!user.isActive">
                                        {{
                                            user.isActive
                                                ? $t('pages.user.userList.filter.statusActive')
                                                : $t('pages.user.userList.filter.statusInactive')
                                        }}
                                    </Words>
                                    <BaseButton
                                        v-if="$can('impersonate', user)"
                                        arrow-right
                                        unbreakable
                                        @click.stop="$emit('onClickImitateUser', user.id)"
                                    >
                                        {{ $t('pages.user.userList.actions.impersonate') }}
                                    </BaseButton>
                                </div>
                            </template>
                            <template v-else>
                                <div class="user-list__mobile-row">
                                    <Avatar
                                        class="user-list__mobile-avatar"
                                        :src="user.image && user.image.url"
                                        size="small"
                                    />
                                    <Words block spaced bold small> {{ user.firstName }} {{ user.lastName }} </Words>
                                    <div style="flex: 1" />
                                    <Words
                                        v-if="user.isActive"
                                        class="user-list__mobile-activity-statue"
                                        green
                                        small
                                        bold
                                    >
                                        {{ $t('pages.user.userList.filter.statusActive') }}
                                    </Words>
                                    <Words v-else class="user-list__mobile-activity-statue" muted small bold>
                                        {{ $t('pages.user.userList.filter.statusInactive') }}
                                    </Words>
                                </div>

                                <div class="user-list__mobile-row">
                                    <div class="user-list__mobile-row__icon ml-4"><VehicleDriverIcon /></div>
                                    <Words small>{{ user.username }}</Words>
                                </div>

                                <div class="user-list__mobile-row">
                                    <div class="user-list__mobile-row__icon ml-4"><PhoneIcon /></div>
                                    <Words small>{{ assemblePhoneNumber(user.mobile, true) }}</Words>
                                </div>

                                <div class="user-list__mobile-row">
                                    <div class="user-list__mobile-row__icon ml-4"><ScrollIcon /></div>
                                    <Words small>{{ userPermissions(user.permissions) }}</Words>
                                </div>

                                <div
                                    v-if="$can('impersonate', user)"
                                    class="user-list__mobile-row user-list__mobile-row--actions"
                                >
                                    <BaseButton arrow-right @click.stop="$emit('onClickImitateUser', user.id)">
                                        {{ $t(`pages.user.userList.actions.impersonate`) }}
                                    </BaseButton>
                                </div>
                            </template>
                        </div>
                    </Card>
                </div>
            </div>
        </transition>

        <Hint v-if="items && items.count === 0" center>
            {{ $t('pages.user.userList.noResults') }}
        </Hint>
    </div>
</template>

<script>
import { assemblePhoneNumber } from '@/services/utils';

import Avatar from '@/components/Avatar';
import BaseButton from '@/components/Button/Button';
import Card from '@/components/Layout/Card';
import Hint from '@/components/Typography/Hint';
import Tag from '@/components/Typography/Tag';
import Words from '@/components/Typography/Words';

import InfoIcon from '@/assets/icons/regular/info.svg';
import PhoneIcon from '@/assets/icons/micro/telephone.svg';
import ScrollIcon from '@/assets/icons/micro/scroll.svg';
import VehicleDriverIcon from '@/assets/icons/micro/fahrer.svg';

export default {
    name: 'UserList',

    components: {
        Avatar,
        BaseButton,
        Card,
        Hint,
        Tag,
        Words,

        InfoIcon,
        PhoneIcon,
        ScrollIcon,
        VehicleDriverIcon,
    },

    props: {
        items: {
            type: Array,
            required: true,
        },

        activeOrganizationType: {
            type: String,
            default: 'all',
        },
    },

    computed: {
        userListRowClass() {
            const classes = ['user-list__row'];

            if (!this.$can('listAllUsers') || this.activeOrganizationType === 'platform') {
                classes.push('user-list__row--without-impersonate');
            }

            return classes;
        },
    },

    methods: {
        assemblePhoneNumber,

        userPermissions(permissions) {
            if (permissions.includes('administration')) {
                return this.$t('pages.user.userList.administration');
            }
            return '------';
        },
    },
};
</script>

<style lang="scss" scoped>
.user-list__row {
    .content {
        @media screen and (min-width: $layout-desktop-min) {
            display: grid;
            grid-template-columns: 50px 2.5fr 2fr 180px 200px;
            column-gap: 20px;
            align-items: center;
        }

        @media screen and (min-width: $screen-xl) {
            grid-template-columns: 50px 2fr 2fr 180px 200px;
        }

        @media screen and (min-width: $screen-xxl) {
            grid-template-columns: 50px 2fr 2fr 2fr minmax(300px, 2fr) minmax(200px, 2fr);
        }
    }
}

.user-list__row--without-impersonate {
    .content {
        @media screen and (min-width: $layout-desktop-min) {
            grid-template-columns: 50px 2.5fr 2fr 180px 60px;
        }

        @media screen and (min-width: $screen-xl) {
            grid-template-columns: 50px 2fr 2fr 180px 60px;
        }

        @media screen and (min-width: $screen-xxl) {
            grid-template-columns: 50px 2fr 2fr 2fr minmax(320px, 2fr) 80px;
        }
    }
}

.user-list__row-header {
    background-color: $color-littleDarkerThanMediumGrey;
    margin-bottom: 20px;
}

.user-list__organization {
    margin-bottom: 30px;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.user-list__organization-row {
    background-color: $color-littleDarkerThanMediumGrey;

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.user-list__organization-permissions {
    span {
        margin-left: 10px;
        &:first-of-type {
            margin-left: 0;
        }
    }
}

.user-list__content-columns {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-list__mobile-row {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
}

.user-list__mobile-row--content {
    padding: 0 16px 16px 16px;
}

.user-list__mobile-avatar {
    margin-left: 16px;
    margin-right: 16px;
}

.user-list__mobile-activity-statue {
    margin-right: 16px;
}

.user-list__mobile-row__icon {
    width: 40px;
    text-align: center;
}

.user-list__mobile-row--actions {
    text-align: right;
    margin-right: 16px;
    justify-content: flex-end;
}

.user-list__name-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.user-list__xxl-visible {
    display: none;

    @media screen and (min-width: $screen-xxl) {
        display: inherit;
    }
}
</style>

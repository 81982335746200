var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Flyout',{staticClass:"organization-selector",attrs:{"active":_vm.organizationSelectorActive,"headline":_vm.$t('pages.user.userForm.newUser.title')},on:{"closed":function($event){return _vm.$emit('closed')}}},[_c('div',[_c('Headline',{staticClass:"container-deprecated",attrs:{"level":6}},[_vm._v(" "+_vm._s(_vm.$t('pages.user.userForm.newUser.typeHeadline'))+" ")]),_c('RoundedTabNavigation',{attrs:{"value":_vm.selectedOrganizationType,"tabs":{
                client: _vm.$t('pages.user.userForm.organizationTypes.client'),
                supplier: _vm.$t('pages.user.userForm.organizationTypes.supplier'),
                carrier: _vm.$t('pages.user.userForm.organizationTypes.carrier'),
                platform: _vm.$t('pages.user.userForm.organizationTypes.platform'),
            }},on:{"input":type => _vm.$emit('updateSelectedOrganizationType', type)}})],1),_c('Card',{staticClass:"organization-selector__list",attrs:{"spaceless":""}},[_c('TextField',{attrs:{"data-test":"search-user","label":_vm.$t('pages.user.userForm.newUser.searchLabel')},on:{"input":_vm.debouncedRefresh},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}},[_c('SearchIcon',{attrs:{"slot":"action"},slot:"action"})],1),_c('TabStages',{staticClass:"organization-selector__stages",attrs:{"active-stage":_vm.selectedOrganizationType}},[_vm._l((['client', 'supplier', 'carrier']),function(stage){return _c('TabStage',{key:stage,attrs:{"stage":stage}},[_c('div',{staticClass:"organization-selector__organizations",attrs:{"spaceless":""}},[(_vm.organizationsByType[stage] && _vm.organizationsByType[stage].items.length > 0)?_c('div',{staticClass:"organization-selector__organizations-list"},_vm._l((_vm.organizationsByType[stage].items),function(organization,i){return _c('div',{key:i,staticClass:"organization-selector__organization",on:{"click":function($event){return _vm.$emit(
                                    'createUserForOrganizationType',
                                    stage,
                                    organization.id,
                                    organization.market.defaultLocale
                                )}}},[_c('Words',{attrs:{"block":""}},[_vm._v(" "+_vm._s(organization.name)+" "),_c('Words',{attrs:{"muted":""}},[_vm._v("("+_vm._s(organization.customerNumber)+")")]),_c('Words',{attrs:{"muted":"","small":"","block":""}},[_c('FormattedAddress',{attrs:{"address":organization.billingAddress}})],1)],1),_c('div',{staticClass:"organization-selector__organization-types"},_vm._l((organization.types),function(type){return _c('Tag',{key:type,attrs:{"black":"","small":"","bold":""}},[_vm._v(" "+_vm._s(_vm.$t(`pages.user.userForm.organizationTypes.${type}`))+" ")])}),1)],1)}),0):(_vm.organizationsByType[stage])?_c('div',{staticClass:"organization-selector__organizations-list organization-selector__organizations-list--empty"},[_c('Words',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$t('pages.user.userList.noOrganizationResults')))])],1):_vm._e()])])}),_c('TabStage',{attrs:{"stage":"platform"}})],2)],1)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
import Vue from 'vue';
import { defaultState } from './state';

const mutations = {
    SET_LOADING(state, loading) {
        Vue.set(state, 'loading', loading);
    },

    SET_FACTORY_ID(state, factoryId) {
        Object.assign(state, defaultState);
        Vue.set(state, 'factoryId', factoryId);
    },

    SET_CATEGORIES(state, categories) {
        const extendedCategories = (categories || []).map(category => ({
            ...category,

            /**
             * extend for products
             */
            isProductsLoading: true,
        }));
        Vue.set(state, 'categories', extendedCategories);
    },

    SET_PRODUCTS(state, { products }) {
        /* sort products by categories */
        products.forEach(product => {
            product.categoryIds.forEach(categoryId => {
                const categoryIndex = state.categories.findIndex(category => category.id === categoryId);

                if (categoryIndex === -1) return;

                Vue.set(state.categories, categoryIndex, {
                    ...state.categories[categoryIndex],
                    isProductsLoading: false,
                });
            });
        });

        const extendedProducts = (products || []).map(product => ({
            ...product,

            /**
             * extend for variants
             */
            isVariantsDisposalLoading: true,
            variantsDisposal: [],

            /**
             * extend for pollution typees
             */
            isPollutionTypesLoading: true,
            pollutionTypes: [],
        }));

        Vue.set(state, 'products', extendedProducts);
    },

    UPDATE_PRODUCT(state, { product, productId }) {
        const productIndex = state.products.findIndex(prod => prod.id === productId);

        Vue.set(state.products, productIndex, {
            ...state.products[productIndex],
            ...product,
        });
    },

    SET_PRODUCT_VARIANTS(state, { productId, variantsDisposal }) {
        const productIndex = state.products.findIndex(product => product.id === productId);

        if (productIndex === -1) return;

        Vue.set(state.products, productIndex, {
            ...state.products[productIndex],
            isVariantsDisposalLoading: false,
            variantsDisposal: (variantsDisposal || []).map(variant => ({
                ...variant,
                isAvailable: variant.isAvailable || false,
            })),
        });
    },

    ADD_PRODUCT_VARIANTS(state, { productId, variantsDisposal }) {
        const productIndex = state.products.findIndex(product => product.id === productId);

        if (productIndex === -1) return;

        const concatenatedVariants = [
            ...(state.products[productIndex].variantsDisposal || []),
            ...(variantsDisposal || []),
        ];

        Vue.set(state.products, productIndex, {
            ...state.products[productIndex],
            isVariantsDisposalLoading: false,
            variantsDisposal: concatenatedVariants.map(variant => ({
                ...variant,
                isAvailable: variant.isAvailable || false,
            })),
        });
    },

    REMOVE_PRODUCT_VARIANTS(state, { removedVariants, productId, messages }) {
        const productIndex = state.products.findIndex(product => product.id === productId);

        if (productIndex === -1) return;

        // remove all successfully removed variants
        removedVariants.forEach(variantToRemove => {
            const variantIndex = state.products[productIndex].variantsDisposal.findIndex(
                variantFromState => variantFromState.id === variantToRemove.id
            );

            if (variantIndex === -1) return;

            Vue.delete(state.products[productIndex].variantsDisposal, variantIndex);
        });

        Vue.set(state.messages, messages);
    },

    SET_POLLUTION_TYPES(state, { productId, pollutionTypes }) {
        const productIndex = state.products.findIndex(product => product?.id === productId);

        if (productIndex === -1) return;

        Vue.set(state.products, productIndex, {
            ...state.products[productIndex],
            isPollutionTypesLoading: false,
            pollutionTypes: pollutionTypes || [],
        });
    },
};

export default mutations;

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{key:_vm.id,class:[
        'accordion',
        {
            'accordion--primary': _vm.isPrimary,
            'accordion--secondary': _vm.isSecondary,
            'accordion--empty': _vm.hideEmptyAccordion,
            'accordion--disabled': _vm.showEmptyAccordion,
            'accordion--header-only': !_vm.hasDefaultSlotContent,
            'accordion--spaced': _vm.spaced,
        },
    ],attrs:{"shadowless":_vm.isSecondary,"spaceless":"","no-padding":""}},[_c('div',{ref:"header",class:[
            'accordion__head',
            {
                'accordion__head--has-preheader': _vm.$scopedSlots.preheader,
                'accordion__head--has-postheader': _vm.$scopedSlots.postheader,
            },
        ],attrs:{"data-test":"accordion-item-content"},on:{"click":_vm.onClick}},[(_vm.$scopedSlots.preheader)?_c('div',{staticClass:"accordion__preheader"},[_vm._t("preheader")],2):_vm._e(),_c('div',{staticClass:"accordion__header"},[_vm._t("header",function(){return [_c('Words',{attrs:{"bold":"","white":""}},[_vm._v("PLACE HEADER HERE")])]})],2),(_vm.$scopedSlots.postheader)?_c('div',{staticClass:"accordion__postheader"},[_vm._t("postheader")],2):_vm._e(),(_vm.hasDefaultSlotContent && _vm.hasContent && _vm.isOpen)?_vm._t("icon-state-open",function(){return [(_vm.isSecondary)?_c('ArrowUpIcon',{staticClass:"accordion__icon icon",attrs:{"width":"19","height":"10"}}):_c('MinusIcon',{staticClass:"accordion__icon icon icon--white",attrs:{"width":"18","height":"18"}})]}):(_vm.hasDefaultSlotContent && _vm.hasContent)?_vm._t("icon-state-close",function(){return [(_vm.isSecondary)?_c('ArrowDownIcon',{staticClass:"accordion__icon icon",attrs:{"width":"19","height":"10"}}):_c('PlusIcon',{staticClass:"accordion__icon icon icon--white",attrs:{"width":"18","height":"18"}})]}):_vm._e()],2),(_vm.isOpen && _vm.hasDefaultSlotContent)?[_vm._t("default")]:_vm._e()],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
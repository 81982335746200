import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Tooltip } from '@schuettflix/react-components';
import { calculateValidityInDays } from '@/constructionProjects/utils/validity';
import { formatDateRange } from '@/services/utils/date';
export const ProjectValidityDate = ({ validFrom, validTo, daysThreshold = 3 }) => {
    const { t } = useTranslation();
    const daysLeft = calculateValidityInDays(validTo);
    const showWarning = daysLeft > 0 && daysLeft <= daysThreshold;
    const validityRange = formatDateRange(new Date(validFrom).getTime(), new Date(validTo).getTime());
    return (<>
            {showWarning ? (<Tag type="warning" label={t('pages.constructionProject.projectCard.expirationWarning', {
                count: daysLeft,
            })}/>) : (<Tooltip text={t('pages.constructionProject.projectCard.tooltip.projectValidity')} position="bottom" size="sm">
                    <div className="font-copy-md whitespace-nowrap break-keep">{validityRange}</div>
                </Tooltip>)}
        </>);
};

export const WebTrackingProvider = {
    getCurrentLocation: () => {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                pos => {
                    resolve(pos.coords);
                },
                err => {
                    reject(err);
                }
            );
        });
    },
};

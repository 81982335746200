import { useRoute } from '@/reactBridge/useRoute';
export const useRouteContext = () => {
    const route = useRoute();
    const routeContext = route.matched
        .map(r => r.meta.context)
        .filter(r => !!r)
        .slice(-1)[0];
    const inRouteContext = (...contexts) => {
        return contexts.includes(routeContext);
    };
    return { routeContext, inRouteContext };
};

import React, { useRef } from 'react';
import clsx from 'clsx';
import { CloseIcon } from '@/constructionProjects/components/icons/CloseIcon';
import { InfoIcon } from '@/constructionProjects/components/icons/InfoIcon';
import { StopIcon } from '@/constructionProjects/components/icons/StopIcon';
import { WarningIcon } from '@/constructionProjects/components/icons/WarningIcon';
import { CheckIcon } from '@/constructionProjects/components/icons/CheckIcon';
import { createPortal } from 'react-dom';
import { Transition } from '@headlessui/react';
// Toast messages are sometimes error objects. This function is taken from the previous Vue implementation
// but we should probably align the callsites to always pass a string.
function formatMessage(message) {
    return message.message ? message.message : message + '';
}
export const ToasterRenderer = ({ toasts, onClose }) => {
    const getIconStrokeCssClass = (severity) => {
        return {
            error: 'stroke-icon-on-critical',
            warning: 'stroke-icon-on-warning',
            success: 'stroke-icon-on-success',
            info: 'stroke-icon-on-primary',
        }[severity];
    };
    const HIDE_TOAST_ANIMATION_DURATION = 500;
    function collapseToast(element, toast) {
        // solution taken from https://css-tricks.com/using-css-transitions-auto-dimensions/#aa-technique-3-javascript
        // and adjusted slightly to include opacity and margin animation
        const sectionHeight = element.scrollHeight;
        const elementTransition = element.style.transition;
        element.style.transition = '';
        // on the next frame (as soon as the previous style change has taken effect),
        // explicitly set the element's height to its current pixel height, so we
        // aren't transitioning out of 'auto'
        requestAnimationFrame(function () {
            element.style.height = sectionHeight + 'px';
            element.style.transition = elementTransition;
            // on the next frame (as soon as the previous style change has taken effect),
            // have the element transition to height: 0
            requestAnimationFrame(function () {
                element.style.height = 0 + 'px';
                element.style.opacity = '0';
                element.style.marginTop = '0';
                setTimeout(() => {
                    onClose(toast);
                }, HIDE_TOAST_ANIMATION_DURATION);
            });
        });
        element.setAttribute('data-collapsed', 'true');
    }
    const toastRef = useRef([]);
    const toastStyle = {
        transition: `height ${HIDE_TOAST_ANIMATION_DURATION}ms ease-in-out, opacity ${HIDE_TOAST_ANIMATION_DURATION}ms ease-in-out, margin ${HIDE_TOAST_ANIMATION_DURATION}ms ease-in-out`,
    };
    const container = document.getElementById('end-of-body');
    if (!container) {
        throw new Error('Could not find end-of-body element');
    }
    return createPortal(<div data-test="toaster" className="pointer-events-none fixed bottom-[env(safe-area-inset-bottom)] z-50 flex flex w-full flex-col items-end px-2 pb-2 md:left-1/4 md:w-1/2">
            {toasts.map((toast, index) => (<Transition className="w-full" key={toast.id} show={toast.isVisible} enter="transition-opacity duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className={clsx('justfy-between pointer-events-auto mt-4 flex h-auto w-full items-center rounded-md', {
                'bg-surface-critical': toast.type === 'error',
                'bg-surface-warning': toast.type === 'warning',
                'bg-surface-success': toast.type === 'success',
                'bg-dark': toast.type === 'info',
            })} style={toastStyle} ref={el => {
                if (toastRef && toastRef.current && el) {
                    toastRef.current[index] = el;
                }
            }} data-collapsed={!toast.isVisible}>
                        <div className="flex w-full items-center p-6">
                            <span className="mr-4">
                                {toast.type === 'error' && (<StopIcon size="sm" strokeCssClass={getIconStrokeCssClass(toast.type)}/>)}
                                {toast.type === 'info' && (<InfoIcon size="sm" strokeCssClass={getIconStrokeCssClass(toast.type)}/>)}
                                {toast.type === 'warning' && (<WarningIcon size="sm" strokeCssClass={getIconStrokeCssClass(toast.type)}/>)}
                                {toast.type === 'success' && (<CheckIcon size="sm" strokeCssClass={getIconStrokeCssClass(toast.type)}/>)}
                            </span>
                            <div className={clsx('font-copy-md-strong', {
                'text-on-critical': toast.type === 'error',
                'text-on-warning': toast.type === 'warning',
                'text-on-success': toast.type === 'success',
                'text-dark': toast.type === 'info',
            })}>
                                {formatMessage(toast.message)}
                            </div>
                            <div className="ml-auto">
                                <CloseIcon className="ml-4 flex-shrink-0" size="sm" onClick={() => {
                collapseToast(toastRef.current[index], toast);
            }} strokeCssClass={getIconStrokeCssClass(toast.type)}/>
                            </div>
                        </div>
                    </div>
                </Transition>))}
        </div>, container);
};

import { ConfigType, ProductAttributeType as AttributeType } from '@/services/ProductCatalog/constants';
import { computed } from 'vue';
import i18n from '@/i18n';
export const useMapProduct = (attributes, initialAttributes, forPlatformAdmins) => {
    const getDropdownOptions = (fieldKey) => {
        const fieldAttributes = getFieldAttributes(fieldKey);
        if (!fieldAttributes || fieldAttributes.config.type === ConfigType.RANGE) {
            return null;
        }
        if (fieldAttributes.config.type === ConfigType.CHOICE) {
            return fieldAttributes.config.choices.map(choice => ({
                value: choice.id,
                label: choice.description ? `${choice.description} (${choice.label})` : choice.label,
                description: forPlatformAdmins
                    ? i18n.t('pages.products.productEdit.attributes.availableInMarkets', {
                        markets: choice.availableMarkets.join(', '),
                    })
                    : undefined,
                disabled: initialAttributes?.includes(choice.id) ?? false,
            }));
        }
        return fieldAttributes.config.choices.map(choice => ({
            value: choice.id,
            label: choice.label,
            disabled: initialAttributes?.includes(choice.id) ?? false,
        }));
    };
    const getFieldAttributes = (fieldKey) => {
        if (!attributes?.value?.length) {
            return null;
        }
        return attributes.value.find(attribute => attribute.key === fieldKey) || null;
    };
    const getColorOptions = computed(() => {
        const attributes = getFieldAttributes(AttributeType.COLOR);
        if (!attributes || attributes.config.type !== ConfigType.COLOR) {
            return null;
        }
        return attributes.config.choices.map(choice => ({
            value: choice.id,
            label: choice.label,
            color: choice.color,
        }));
    });
    const getOptionLabel = (value, options) => {
        if (value === null)
            return i18n.t('pages.products.supplierProductsAdd.nothingSelected');
        const found = options.find(option => option.value === value);
        return found ? found.label : value;
    };
    const hasFieldOptions = (fieldKey) => {
        return !!getDropdownOptions(fieldKey)?.length;
    };
    const getFieldLabel = (fieldKey) => {
        const field = getFieldAttributes(fieldKey);
        return field?.label || '';
    };
    return {
        getDropdownOptions,
        getFieldAttributes,
        getColorOptions,
        getOptionLabel,
        hasFieldOptions,
        getFieldLabel,
    };
};

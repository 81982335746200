<template>
    <div
        :class="{
            'tab-stage--folded': !isSelected,
            'tab-stage--active': isSelected,
        }"
        class="tab-stage"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'TabStage',
    props: {
        stage: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isSelected: false,
        };
    },
    created() {
        this.$on('change-stage', (index, stage) => {
            if (this.stage === stage) {
                this.$parent.$emit('select-stage', index);
                this.isSelected = true;
            } else {
                this.isSelected = false;
            }
        });
    },
};
</script>

<style lang="scss">
.tab-stage {
    width: 100%;
    flex: 1 0 100%;
    max-height: 9999em;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
}

.tab-stage--folded {
    max-height: 0;
}

.tab-stage--active {
    z-index: 1;
}
</style>

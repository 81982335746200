<template>
    <div class="validity-tile">
        <Words bold tiny block>{{ $t('pages.order.orderView.validityTile.title') }}</Words>
        <Words spaced block>
            {{ $t('pages.order.orderView.validityTile.text') }}
            <Words bold>{{ $d(ensureJSTimestamp(expireDate), 'short') }}</Words>
        </Words>
    </div>
</template>

<script>
import { ensureJSTimestamp } from '@/services/utils/date';

import Words from '@/components/Typography/Words';

export default {
    name: 'ValidityTile',

    components: {
        Words,
    },

    props: {
        expireDate: {
            type: [String, Number],
            required: true,
        },
    },

    methods: {
        ensureJSTimestamp,
    },
};
</script>

<style>
.validity-tile {
}
</style>

import axios from 'axios';
const instance = axios.create({
    baseURL: window.constructionProjectApiUrl,
});
export const setAccessToken = (token) => {
    if (token) {
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    else {
        delete instance.defaults.headers.common['Authorization'];
    }
};
export const setAcceptLanguage = (language) => {
    instance.defaults.headers.common['Accept-Language'] = language;
};
export const setAppVersion = (version) => {
    instance.defaults.headers.common['X-Client-Version'] = version;
};
export const httpClient = instance;

import { GetPositionProductGroupsResponseSchema } from '@/modules/constructionProjects/api/position-service-interfaces';
import { GenericPositionHTTPClient } from '@/modules/constructionProjects/services/GenericPositionHTTPClient';
export const getGenericPositionProductGroups = async (positionId) => {
    return GenericPositionHTTPClient.get(`/v1/position/${positionId}/product-group`).then(res => GetPositionProductGroupsResponseSchema.parse(res.data));
};
export function getGenericPositionProductGroupsKey(positionId) {
    return ['generic-positions', positionId, 'product-groups'];
}
export function getGenericPositionProductGroupsQuery(positionId) {
    return {
        queryKey: getGenericPositionProductGroupsKey(positionId),
        queryFn: () => getGenericPositionProductGroups(positionId),
        refetchOnWindowFocus: false,
        suspense: true,
    };
}
export function invalidateGetGenericPositionProductGroupsQuery(queryClient, positionId) {
    queryClient.invalidateQueries(getGenericPositionProductGroupsKey(positionId));
}

const initialState = {
    isTransportHubGrouped: false,
    isTransportCarrierListGrouped: false,
    isTransportHubQueueGrouped: false,
    isTransportClientListGrouped: false,
    isTransportSupplierListGrouped: false,
    isCountryDialogAcknowledged: false,
};

const mutations = {
    setIsTransportHubGrouped(state, payload) {
        state.isTransportHubGrouped = payload;
    },
    setTransportCarrierListGrouped(state, payload) {
        state.isTransportCarrierListGrouped = payload;
    },
    setTransportClientListGrouped(state, payload) {
        state.isTransportClientListGrouped = payload;
    },
    setTransportSupplierListGrouped(state, payload) {
        state.isTransportSupplierListGrouped = payload;
    },
    setTransportHubQueueGrouped(state, payload) {
        state.isTransportHubQueueGrouped = payload;
    },
    setCountryDialogAcknowledged(state, value) {
        state.isCountryDialogAcknowledged = value;
    },
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions: {
        acknowledgeCountryDialog(store) {
            store.commit('setCountryDialogAcknowledged', true);
        },
    },
    getters: {
        isCountryDialogAcknowledged: state => state.isCountryDialogAcknowledged,
    },
};

import _cloneDeep from 'lodash/cloneDeep';
import { MarginType, } from '@/services/ProductCatalog/types';
import { ProductAttributeField, ProductAttributeType } from '@/services/ProductCatalog/constants';
const DEFAULT_MARGIN_VALUE = 3;
export const getDefaultTaxRate = (market) => market.taxRates.find(rate => rate.isDefault);
const prepareFormMarkets = (availableMarkets, productMarkets) => {
    return availableMarkets.map(market => {
        const found = productMarkets.find(entry => entry.marketCode === market.code);
        return {
            taxRateId: found?.taxRateId || getDefaultTaxRate(market)?.id || null,
            marginValue: found?.marginValue === null || found?.marginValue === undefined
                ? DEFAULT_MARGIN_VALUE
                : found?.marginValue,
            marginType: found?.marginType || MarginType.percentage,
            available: !!found?.available,
            marketCode: market.code,
        };
    });
};
const getAttributeValues = (attributeValues, attributes, key) => {
    const attribute = attributes.find(attribute => attribute.key === key);
    if (!attribute) {
        return [];
    }
    return attributeValues.filter(entry => entry.attributeSetId === attribute.id).map(entry => entry.id);
};
export const mapServerResponseToForm = (product, attributes, marketsConfig) => {
    return {
        grainSize: _cloneDeep(product.grainSize),
        markets: prepareFormMarkets(marketsConfig.value, product.markets),
        [ProductAttributeField.GEOLOGY]: getAttributeValues(product.attributeValues, attributes.value, ProductAttributeType.GEOLOGY),
        [ProductAttributeField.GEOGRAPHY]: getAttributeValues(product.attributeValues, attributes.value, ProductAttributeType.GEOGRAPHY),
        [ProductAttributeField.CHARACTERISTICS]: getAttributeValues(product.attributeValues, attributes.value, ProductAttributeType.CHARACTERISTICS),
        [ProductAttributeField.CERT_1]: getAttributeValues(product.attributeValues, attributes.value, ProductAttributeType.CERT_1),
        [ProductAttributeField.CERT_2]: getAttributeValues(product.attributeValues, attributes.value, ProductAttributeType.CERT_2),
        [ProductAttributeField.CERT_3]: getAttributeValues(product.attributeValues, attributes.value, ProductAttributeType.CERT_3),
        [ProductAttributeField.BRAND]: getAttributeValues(product.attributeValues, attributes.value, ProductAttributeType.BRAND),
        [ProductAttributeField.COLOR]: getAttributeValues(product.attributeValues, attributes.value, ProductAttributeType.COLOR),
        [ProductAttributeField.PROCESSING]: getAttributeValues(product.attributeValues, attributes.value, ProductAttributeType.PROCESSING),
    };
};
export const mapFormToServerRequestPayload = (form) => {
    return {
        grainSize: form.grainSize
            ? {
                min: form.grainSize.min,
                max: form.grainSize.max,
            }
            : null,
        markets: form.markets,
        attributeValues: Object.values(ProductAttributeField).reduce((acc, key) => {
            return form[key] ? [...acc, ...form[key]] : acc;
        }, []),
    };
};

<template>
    <div :class="{ 'grid-table__cell--actions-spaced': spaced }" class="grid-table__cell--actions" @click.stop>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'GridTableActionCell',
    props: {
        spaced: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

import axios from 'axios';
import i18n from '@/i18n';
import ApiError from './ApiError';
export class AbstractResourceErrorHandler {
    messages;
    constructor(messages = {}) {
        this.messages = {
            401: 'abstractresource.errors.unauthorized',
            403: 'abstractresource.errors.forbidden',
            404: 'abstractresource.errors.notFound',
            408: 'abstractresource.errors.requestTimeout',
            500: 'abstractresource.errors.serverError',
            ...messages,
        };
    }
    handleError(err) {
        if (axios.isCancel(err)) {
            return err;
        }
        if (err instanceof ApiError && err.code && err.code !== 400) {
            err.message = this.messages[err.code] ? i18n.t(this.messages[err.code]) : err.message;
        }
        return err;
    }
}

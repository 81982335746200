import { useTranslation } from 'react-i18next';
export function FooterSection({ projectCode }) {
    const { t } = useTranslation();
    if (!projectCode) {
        return null;
    }
    return (<footer className="font-numbers-md my-6 flex flex-grow items-end justify-center text-center text-subdued lg:my-0">
            {t('pages.constructionProject.details.footer.projectCode', {
            code: projectCode,
        })}
        </footer>);
}

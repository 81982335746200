import { useTranslation } from 'react-i18next';
import { ProjectCard } from '@/modules/constructionProjects/components/Listing/ProjectCard';
import { ConstructionProjectStatus } from '@/constructionProjects/api/projects';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { findRouteName } from '@/pages/routerUtils';
import { StarIcon } from '@/constructionProjects/components/icons/StarIcon';
import { ActiveProjectsIcon } from '@/constructionProjects/components/icons/ActiveProjectsIcon';
import { CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE } from '@/modules/constructionProjects/pages/routes';
export const ProjectList = ({ items, searchTerm, showStatus, status, showFavoritesPlaceholder = true, }) => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const navigateToDetailPage = (projectUuid) => {
        return vueRouter.push({
            name: findRouteName(CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE),
            params: { id: `${projectUuid}` },
        });
    };
    const translationMap = {
        [ConstructionProjectStatus.Active]: t('pages.projectList.empty.active'),
        [ConstructionProjectStatus.Inactive]: t('pages.projectList.empty.inactive'),
        [ConstructionProjectStatus.Archived]: t('pages.projectList.empty.archived'),
        all: t('pages.projectList.empty.all'),
    };
    if (!items?.length) {
        return (<div className="flex h-full grow flex-col items-center justify-center border border-2 border-dashed p-8 md:mt-12">
                <ActiveProjectsIcon className="inline-block"/>
                <p className="font-copy-md text-center">{translationMap[status || 'all']}</p>
            </div>);
    }
    const projects = items.filter(item => !item.isFavorite);
    const favoriteProjects = items.filter(item => item.isFavorite);
    return (<div>
            {favoriteProjects?.length > 0 ? (<div className="border-b pb-4 md:border-0 md:pb-0">
                    {favoriteProjects.map(item => {
                return (<ProjectCard {...item} key={item.id} showStatus={showStatus} searchTerm={searchTerm} onCardClick={() => navigateToDetailPage(item.id)}/>);
            })}
                </div>) : (showFavoritesPlaceholder && (<div className="flex flex-col items-center justify-center gap-1 rounded-lg border border-2 border-dashed p-6">
                        <StarIcon className="stroke-icon" size={24}/>
                        <p className="font-copy-md-strong text-center">
                            {t('pages.projectList.favorites.empty.title')}
                        </p>
                        <p className="font-copy-sm text-center">{t('pages.projectList.favorites.empty.subtitle')}</p>
                    </div>))}
            <div className="pt-8">
                {projects.map(item => (<ProjectCard {...item} key={item.id} showStatus={showStatus} searchTerm={searchTerm} onCardClick={() => navigateToDetailPage(item.id)}/>))}
            </div>
        </div>);
};

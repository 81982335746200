<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div>
        <div
            :class="{
                'md:grid-cols-4': showIncludeSubcontractorFilter,
                'md:grid-cols-3': !showIncludeSubcontractorFilter,
            }"
            class="mb-4 grid grid-cols-1 gap-4"
        >
            <TextField
                v-model="filterScope.filter.transportNumber"
                :label="$t('pages.transport.filter.transportNumber')"
                data-test="advanced-platform-filter-order-number"
            />

            <SelectBox
                v-model="filterScope.filter.type"
                :label="$t('pages.transportHub.filter.type')"
                :options="typeOptions"
                :option-value-renderer="option => option.value"
                :option-label-renderer="option => option.label"
                data-test="job-type"
            />

            <MultiselectBox
                v-model="filterScope.filter.status"
                :options="availableOptionsForStatus"
                :option-value-renderer="option => option"
                :search-label-renderer="option => statusText(option)"
                :label="$t('pages.transport.filter.statusHeadline')"
                multiple
                searchable
            >
                <template #button="buttonScope">
                    <DefaultInner mode="button" :scope="buttonScope" :label-renderer="statusText" />
                </template>

                <template #option="optionScope">
                    <DefaultInner mode="option" :scope="optionScope" :label-renderer="statusText" />
                </template>
            </MultiselectBox>
            <SelectBox
                v-if="showIncludeSubcontractorFilter"
                v-model="filterScope.filter.includeSubcontractor"
                :label="$t('pages.transport.filter.includeSubcontractor.label')"
                :options="subcontractorOptions"
                :option-value-renderer="option => option.value"
                :option-label-renderer="option => option.label"
            />
        </div>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
            <TimeRangeFieldset
                :from="filterScope.filter.startShippingWindow"
                :to="filterScope.filter.endShippingWindow"
                :label-from="$t('pages.transportHub.filter.timeRangeFrom')"
                :label-to="$t('pages.transportHub.filter.timeRangeTo')"
                hide-options
                type="date"
                class="transport-hub__time-range-fieldset"
                @update-from="$set(filterScope.filter, 'startShippingWindow', ensurePHPTimestamp($event))"
                @update-to="$set(filterScope.filter, 'endShippingWindow', ensurePHPTimestamp($event))"
            />

            <LocationField
                :place="_get(filterScope.filter, 'originGeo.place', null)"
                :radius="_get(filterScope.filter, 'originGeo.radius', null)"
                :radius-options="[5, 25, 50, 100, 200]"
                :radius-options-default="25"
                :label="$t('pages.transport.filter.originGeo')"
                street-less
                strict-radius-options
                @placeChange="$set(filterScope.filter, 'originGeo.place', $event)"
                @radiusChange="$set(filterScope.filter, 'originGeo.radius', $event)"
            />

            <LocationField
                :place="_get(filterScope.filter, 'destinationGeo.place', null)"
                :radius="_get(filterScope.filter, 'destinationGeo.radius', null)"
                :radius-options="[5, 25, 50, 100, 200]"
                :radius-options-default="25"
                :label="$t('pages.transport.filter.destinationGeo')"
                street-less
                strict-radius-options
                @placeChange="$set(filterScope.filter, 'destinationGeo.place', $event)"
                @radiusChange="$set(filterScope.filter, 'destinationGeo.radius', $event)"
            />
        </div>
    </div>
</template>

<script>
import _get from 'lodash/get';
import { ensurePHPTimestamp } from '@/services/utils/date';
import { revertLocalizedValue } from '@/services/utils/localization';
import { statusText } from '@/services/utils/transport';
import routeContext from '@/plugins/mixins/routeContext';

import { useFeatureFlag } from '@/services/FeatureFlags/useFeatureFlags.ts';

import DefaultInner from '@/components/Form/MultiselectBox/DefaultInner.v2';
import MultiselectBox from '@/components/Form/MultiselectBox';
import SelectBox from '@/components/Form/SelectBox.v3';
import TextField from '@/components/Form/TextField.v2';
import TimeRangeFieldset from '@/components/Filter/TimeRangeFieldset.v2';
import LocationField from '@/components/Form/LocationField.v2';

import { TRANSPORT_TYPE } from '@/constants/transportTypes';

export default {
    name: 'AdvancedPlatformFilterSet',
    components: {
        DefaultInner,
        MultiselectBox,
        SelectBox,
        TextField,
        TimeRangeFieldset,
        LocationField,
    },
    mixins: [routeContext],
    props: {
        disableSubcontractorFilter: {
            type: Boolean,
            default: false,
        },
        filterScope: {
            type: Object,
            required: true,
        },
        endpoint: {
            type: Object,
            required: true,
        },
        forcedFilter: {
            type: Object,
            required: true,
        },
        whitelistedFields: {
            type: Array,
            default: null,
        },
    },
    setup() {
        const { isEnabled: isFeatureFlagDisposalEnabled } = useFeatureFlag('disposal_project_order');
        return { isFeatureFlagDisposalEnabled };
    },
    computed: {
        availableOptionsForStatus() {
            if (this.forcedFilter.status && this.forcedFilter.status.length > 0) {
                return this.forcedFilter.status;
            }
            const options = this.endpoint.supportedFilters.status?.options;
            return options ? Object.keys(this.endpoint.supportedFilters.status.options) : [];
        },

        typeOptions() {
            const options = [
                { value: null, label: this.$t('pages.transportHub.typeOptions.all') },
                { value: [TRANSPORT_TYPE.DELIVERY], label: this.$t('pages.transportHub.typeOptions.delivery') },
                { value: [TRANSPORT_TYPE.SHIPMENT], label: this.$t('pages.transportHub.typeOptions.shipment') },
                { value: [TRANSPORT_TYPE.PICKUP], label: this.$t('pages.transportHub.typeOptions.pickup') },
            ];

            if (this.isFeatureFlagDisposalEnabled)
                options.push({
                    value: [TRANSPORT_TYPE.DISPOSAL],
                    label: this.$t('pages.transportHub.typeOptions.disposal'),
                });

            return options;
        },

        showIncludeSubcontractorFilter() {
            if (!this.$can('accessTransportSubcontractorFilter') || this.disableSubcontractorFilter) return false;

            return true;
        },

        subcontractorOptions() {
            return [
                { label: this.$t('pages.transport.filter.includeSubcontractor.options.all'), value: null },
                { label: this.$t('pages.transport.filter.includeSubcontractor.options.include'), value: 'include' },
                { label: this.$t('pages.transport.filter.includeSubcontractor.options.exclude'), value: 'exclude' },
            ];
        },
    },
    methods: {
        ensurePHPTimestamp,
        revertLocalizedValue,
        _get,

        statusText(status) {
            return statusText(this.routeContext, status);
        },
    },
};
</script>

/**
 * Class representing address view model
 */
import { ensureJSTimestamp, formatDateRange, getFormattedDuration } from '@/services/utils/date';
import { revertLocalizedValue } from '@/services/utils/localization';
import i18n from '@/i18n';

export default class TransportListGroupView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     * @return {TransportListGroupView|null}
     */
    static create(data) {
        if (!data) return null;

        const view = new TransportListGroupView();

        view.count = data.count;
        view.orderNumber = data.orderNumber;
        view.qty = data.qty;
        view.productName = data.productName;
        view.clientName = data.clientName;
        view.isProject = data.isProject;
        view.isDangerous = data.isDangerous;
        view.transportType = data.transportType;
        view.freightType = data.freightType;
        view.wasteCode = data.wasteCode;
        view.transports = data.transports;

        // group prices
        view.totalTransportPurchasePrice = data.totalTransportPurchasePrice;
        view.totalRetailPrice = data.totalRetailPrice;
        view.totalMaterialPurchasePrice = data.totalMaterialPurchasePrice;

        return view;
    }

    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     * @return {TransportListGroupView|null}
     */
    static createFromPayload(data) {
        if (!data) return null;

        const view = new TransportListGroupView();

        view.count = data.transportCount;
        view.orderNumber = data.orderNumber;
        view.qty = data.qty;
        view.startDate = data.startDate;
        view.endDate = data.endDate;
        view.productName =
            data.transportType === 'shipment' ? data.freightDescription : revertLocalizedValue(data.productName);
        view.clientName = data.clientName;
        view.isProject = data.isProject;
        view.isDangerous = data.isDangerous || false;
        view.transportType = data.transportType;
        view.freightType = data.freightType;
        view.wasteCode = data.wasteCode;
        view.transports = data.transports;

        // group prices
        view.totalTransportPurchasePrice = data.totalTransportPurchasePrice;
        view.totalRetailPrice = data.totalRetailPrice;
        view.totalMaterialPurchasePrice = data.totalMaterialPurchasePrice;

        view.flixTransportsCount = view.transports.reduce((acc, transport) => {
            return transport.shippingMethod === 'flix' ? acc + 1 : acc;
        }, 0);

        return view;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._count = null;
        this._orderNumber = null;
        this._qty = null;
        this._startDate = null;
        this._endDate = null;
        this._productName = null;
        this._clientName = null;
        this._isProject = null;
        this._isDangerous = null;
        this._transportType = null;
        this._freightType = null;
        this._wasteCode = null;
        this._transports = null;
        this._totalTransportPurchasePrice = null;
        this._totalRetailPrice = null;
        this._totalMaterialPurchasePrice = null;
        this._flixTransportsCount = null;
    }

    /**
     * Unfold view into plain object
     * @return {object}
     */
    unfold() {
        return {
            count: this._count,
            orderNumber: this._orderNumber,
            qty: this._qty,
            startDate: this._startDate,
            endDate: this._endDate,
            productName: this._productName,
            clientName: this._clientName,
            isProject: this._isProject,
            isDangerous: this._isDangerous,
            freightType: this._freightType,
            wasteCode: this._wasteCode,
            transportType: this._transportType,
            transports: this._transports,
            totalTransportPurchasePrice: this._totalTransportPurchasePrice,
            totalMaterialPurchasePrice: this._totalMaterialPurchasePrice,
            totalRetailPrice: this._totalRetailPrice,
        };
    }

    /**
     * Clone the current view
     * @return {TransportListGroupView}
     */
    clone() {
        return TransportListGroupView.create(this.unfold());
    }

    get count() {
        return this._count;
    }

    set count(value) {
        this._count = value ?? 0;
    }

    get orderNumber() {
        return this._orderNumber;
    }

    set orderNumber(value) {
        this._orderNumber = value ?? null;
    }

    get qty() {
        return this._qty;
    }

    set qty(value) {
        this._qty = value ?? null;
    }

    get productName() {
        return this._productName;
    }

    set productName(value) {
        this._productName = value ?? null;
    }

    get clientName() {
        return this._clientName;
    }

    set clientName(value) {
        this._clientName = value ?? null;
    }

    get isProject() {
        return this._isProject;
    }

    set isProject(value) {
        this._isProject = value ?? false;
    }

    get isDangerous() {
        return this._isDangerous;
    }

    set isDangerous(value) {
        this._isDangerous = value ?? false;
    }

    get transportType() {
        return this._transportType;
    }

    set transportType(value) {
        this._transportType = value ?? null;
    }

    get freightType() {
        return this._freightType;
    }

    set freightType(value) {
        this._freightType = value ?? null;
    }

    get wasteCode() {
        return this._wasteCode;
    }

    set wasteCode(value) {
        this._wasteCode = value ?? null;
    }

    get transports() {
        return this._transports;
    }

    set transports(value) {
        this._transports = value ?? [];
    }

    get startDate() {
        return this._startDate;
    }

    set startDate(value) {
        this._startDate = ensureJSTimestamp(value) ?? null;
    }

    get endDate() {
        return this._endDate;
    }

    set endDate(value) {
        this._endDate = ensureJSTimestamp(value) ?? null;
    }

    get displayDate() {
        return formatDateRange(this.startDate, this.endDate);
    }

    get totalTransportPurchasePrice() {
        return this._totalTransportPurchasePrice;
    }

    set totalTransportPurchasePrice(value) {
        this._totalTransportPurchasePrice = value;
    }

    get totalRetailPrice() {
        return this._totalRetailPrice;
    }

    set totalRetailPrice(value) {
        this._totalRetailPrice = value;
    }

    get totalMaterialPurchasePrice() {
        return this._totalMaterialPurchasePrice;
    }

    set totalMaterialPurchasePrice(value) {
        this._totalMaterialPurchasePrice = value;
    }

    get flixTransportsCount() {
        return this._flixTransportsCount;
    }

    set flixTransportsCount(value) {
        this._flixTransportsCount = value ?? 0;
    }

    get hasPreferredTransports() {
        return this.preferredTransportTimes.length > 0;
    }

    get preferredTransportTimes() {
        return this.transports
            .map(transport => {
                return transport.__preferedRemainingSeconds || 0;
            })
            .filter(seconds => {
                return seconds > 0;
            });
    }

    get preferredTransportLabel() {
        if (this.preferredTransportTimes.length == null) {
            return null;
        }

        return i18n.t('components.transportListGroup.preferredTransportsLabel', {
            preferredCount: this.preferredTransportTimes.length,
            transportsCount: this.transports.length,
            time: getFormattedDuration(this.preferredTransportTimes[0], true),
        });
    }
}

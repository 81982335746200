<template>
    <ModalBox
        v-if="isActionAvailable"
        :active="isActive"
        :headline="$t('pages.project.position.action.close.headline')"
        @closed="closeModal()"
    >
        <Words>
            {{ $t('pages.project.position.action.close.message') }}
        </Words>

        <ButtonGroup slot="actions">
            <Button place-left primary arrow-left light @click="closeModal()">
                {{ $t('pages.project.position.action.close.actions.cancel') }}
            </Button>

            <Button :is-loading="isPending" primary @click="closeProjectPosition()">
                {{ $t('pages.project.position.action.close.actions.confirm') }}
            </Button>
        </ButtonGroup>
    </ModalBox>
</template>

<script>
import ProjectPositionApi from '@/services/Api/ProjectPosition';
import ProjectPositionView from '@/models/ProjectPositionView';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { ACTIONS } from '@/constants/actions';
import { EVENT_PROJECT_POSITION_CLOSED } from '@/constants/events';
import { mapGetters } from 'vuex';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ModalBox from '@/components/Modal/ModalBox';
import Words from '@/components/Typography/Words';
import Toaster from '@/services/Toaster';

export default {
    name: 'ProjectPositionCloseAction',
    components: {
        Button,
        ButtonGroup,
        ModalBox,
        Words,
    },
    mixins: [eventHubMixin],
    data() {
        return {
            isPending: false,
        };
    },
    computed: {
        ...mapGetters('globalActions', ['subject', 'action']),

        isActive() {
            return this.action === ACTIONS.PROJECT_POSITION_CLOSE;
        },

        isActionAvailable() {
            return this.subject instanceof ProjectPositionView;
        },
    },
    methods: {
        closeModal() {
            this.$store.dispatch('globalActions/reset', ACTIONS.PROJECT_POSITION_CLOSE);
        },

        async closeProjectPosition() {
            if (this.isPending) return;
            this.isPending = true;

            try {
                await ProjectPositionApi.close(this.subject.id);
                Toaster.success(
                    this.$t('pages.project.position.action.close.success', { number: this.subject.number })
                );
                this.$eventHub.$emit(EVENT_PROJECT_POSITION_CLOSED, this.subject);
            } catch (err) {
                Toaster.error(this.$t('pages.project.position.action.close.errors'));
            }

            this.isPending = false;
            this.closeModal();
        },
    },
};
</script>

export const SELECT_WEIGHING_OPTION = 'selectWeighingOption';
export const FINISH_LOADING = 'finishLoading';
export const CHANGE_TRANSPORT_DELIVERY_DATE = 'changeTransportDeliveryDate';
export const CAPTURE_TRANSPORT = 'captureTransport';
export const UNPLAN_TRANSPORT = 'unplanTransport';
export const REPLAN_TRANSPORT = 'replanTransport';
export const RETURN_TRANSPORT = 'returnTransport';
export const PLATFORM_RETURN_TRANSPORT = 'platform.returnTransport';
export const FAIL_TRANSPORT = 'failTransport';
export const CANCEL_LOADING = 'cancelLoading';
export const CHANGE_VEHICLE = 'changeVehicle';
export const STARTED_DELIVERY = 'startedDelivery';
export const UNLOAD_WITHOUT_CUSTOMER = 'unloadWithoutCustomerOpen';
export const CONFIRM_ARRIVAL = 'confirmArrival';
export const CONFIRM_ARRIVAL_CONFIRM = 'confirmArrivalConfirm';
export const JUSTIFY_DELAY = 'justifyDelay';
export const CONFIRM_ABSENCE_DELIVERY = 'confirmAbsenceDelivery';
export const CANCEL_TRANSPORT = 'cancelTransport';
export const PLATFORM_REVOKE_PREFERRED_STATUS = 'platform.revokePreferredStatus';
export const PLATFORM_UNPLAN_TRANSPORT = 'platform.unplanTransport';
export const TRANSPORT_FEEDBACK = 'transportFeedback';
export const TRANSPORT_FEEDBACK_CONFIRMED = 'transportFeedbackConfirmed';
export const TRANSPORT_SUPPLIER_FEEDBACK = 'transportSupplierFeedback';
export const DECLINE_LOADING = 'declineLoading';
export const CONFIRMED_LOADING = 'confirmedLoading';
export const CONFIRM_LOADING_WITH_ORIGINAL_DELIVERY_NOTE = 'confirmLoadingWithOriginalDeliveryNote';
export const UPDATE_ORIGINAL_DELIVERY_NOTE = 'updateOriginalDeliveryNote';
export const CHANGE_CONFIRM_DELIVERY_IMAGE = 'changeConfirmDeliveryImage';
export const CONFIRM_LOADING_SHIPMENT = 'confirmLoadingShipment';
export const CONFIRM_UNLOADING_SHIPMENT = 'confirmUnloadingShipment';
export const PLATFORM_CONFIRM_TRANSPORT_LOADING_SHIPMENT = 'platformConfirmTransportLoadingShipment';
export const PLATFORM_CONFIRM_TRANSPORT_UNLOADING_SHIPMENT = 'platformConfirmTransportUnloadingShipment';
export const CHANGE_TRANSPORT_LOADING_SHIPMENT = 'changeTransportLoadingShipment';
export const CHANGE_TRANSPORT_UNLOADING_SHIPMENT = 'changeTransportUnloadingShipment';
export const PLATFORM_CHANGE_PRICE_SHIPMENT = 'platform.changePriceShipment';
export const CHANGE_TRANSPORT_LOAD_WINDOW_SHIPMENT = 'changeTransportLoadWindowShipment';
export const CHANGE_TRANSPORT_PRICE_SHIPMENT = 'changeTransportPriceShipment';
export const CHANGE_TRANSPORT_FREIGHT_INFORMATION_SHIPMENT = 'changeTransportFreightInformationShipment';
export const UPDATE_ACCOMPANYING_DOCUMENT_NUMBER = 'updateAccompanyingDocumentNumber';
export const UPDATE_ACCOMPANYING_DOCUMENTS = 'updateAccompanyingDocuments';
export const UPDATE_UNLOADING_INFORMATION = 'updateUnloadingInformation';
export const UPDATE_LOADING_INFORMATION = 'updateLoadingInformation';

export const RECORD_WEIGHING_BY_DRIVER = 'recordWeighingByDriver';

// generic unloading actions for all type of transports
export const CONFIRM_UNLOADING_PER_TON = 'confirmUnloading.perTon';
export const CONFIRM_UNLOADING_PER_TRANSPORT = 'confirmUnloading.perTransport';

import _get from 'lodash/get';

export default class Context {
    constructor(data = {}) {
        Object.keys(data).forEach(key => {
            this[`_${key}`] = data[key];
        });

        // Debug
        this.trace = [];
        this.traceDepth = -1;
        this.abilityName = null;
        this.parentContext = null;
    }

    getValue(key) {
        if (typeof key !== 'string') {
            return key;
        } else if (/(['"])(.*?)\1/.test(key)) {
            return key.match(/(['"])(.*?)\1/)[2];
        }

        return _get(this, `_${key}`, undefined);
    }

    set(key, value) {
        this[`_${key}`] = value;
        return this;
    }

    /**
     * Get SubContext for nested ability calls
     * @param {*} subjectSelector
     */
    getSubContext(subjectSelector) {
        const newContext = Object.assign(Object.create(Object.getPrototypeOf(this)), this);
        newContext.set('subject', this.getValue(subjectSelector));
        newContext.parentContext = this;

        return newContext;
    }

    setAbilityName(abilityName) {
        this.abilityName = abilityName;
    }
}

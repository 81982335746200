export default [
    {
        title: 'components.factoryManagement.subNavigation.nameAddressTitle',
        text: 'components.factoryManagement.subNavigation.nameAddressText',
        to: {
            query: { route: 'name-address' },
        },
        exact: true,
        completed: true,
        optional: false,
    },
    {
        title: 'components.factoryManagement.subNavigation.locationTypeTitle',
        text: 'components.factoryManagement.subNavigation.locationTypeText',
        to: {
            query: { route: 'location-type' },
        },
        exact: true,
        completed: false,
        optional: false,
    },
    {
        title: 'components.factoryManagement.subNavigation.contactTitle',
        text: 'components.factoryManagement.subNavigation.contactText',
        to: {
            query: { route: 'contact' },
        },
        exact: true,
        completed: false,
        optional: false,
    },
    {
        title: 'components.factoryManagement.subNavigation.openingHoursTitle',
        text: 'components.factoryManagement.subNavigation.openingHoursText',
        to: {
            query: { route: 'opening-hours' },
        },
        exact: true,
        completed: false,
        optional: false,
    },
    {
        title: 'components.factoryManagement.subNavigation.weighingTypesTitle',
        text: 'components.factoryManagement.subNavigation.weighingTypesDescription',
        to: {
            query: { route: 'weighing-types' },
        },
        exact: true,
        completed: false,
        optional: false,
    },
    {
        title: 'components.factoryManagement.subNavigation.vehicleRequirementsTitle',
        subTitle: 'components.factoryManagement.subNavigation.vehicleRequirementsSubTitle',
        text: 'components.factoryManagement.subNavigation.vehicleRequirementsText',
        to: {
            query: { route: 'vehicle-requirements' },
        },
        exact: true,
        completed: false,
        optional: true,
    },
    {
        title: 'components.factoryManagement.subNavigation.platformSettingsTitle',
        text: 'components.factoryManagement.subNavigation.platformSettingsText',
        to: {
            query: { route: 'platform-settings' },
        },
        exact: true,
        completed: false,
        optional: true,
        icon: 'PlatformSettings',
        options: {
            restriction: 'user/isPlatformAdministrator',
        },
    },
];

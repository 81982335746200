import { useTranslation } from 'react-i18next';
import { Person } from '@/modules/constructionProjects/components/Person';
import { AvatarList } from '@schuettflix/react-components';
export function PeopleSection({ constructionProject }) {
    const { t } = useTranslation();
    const { responsibleUser, responsibleEmployee, teamMembers } = constructionProject;
    return (<section className="-mb-4 flex items-center justify-end gap-12">
            <Person profileImageUrl={responsibleEmployee.images?.thumbnail} firstName={responsibleEmployee.firstName} lastName={responsibleEmployee.lastName} role={t('pages.constructionProject.detailsPage.responsibleEmployee.title')}/>
            <Person profileImageUrl={responsibleUser.images?.thumbnail} firstName={responsibleUser.firstName} lastName={responsibleUser.lastName} role={t('pages.constructionProject.detailsPage.responsiblePerson.title')}/>
            {teamMembers.length ? <AvatarList maxShown={4} users={teamMembers}/> : null}
        </section>);
}

import { render, staticRenderFns } from "./FlagContainer.vue?vue&type=template&id=52a4cc4f&scoped=true&"
import script from "./FlagContainer.vue?vue&type=script&lang=js&"
export * from "./FlagContainer.vue?vue&type=script&lang=js&"
import style0 from "./FlagContainer.vue?vue&type=style&index=0&id=52a4cc4f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_webpack@5.88.2_webpack-cli@5.1.4___lodash@4.17.21_react-d_j5jn3zdz4uzijexvn7gkk7yebe/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52a4cc4f",
  null
  
)

export default component.exports
<template>
    <div class="supplier-waste-product-details-actions">
        <transition-group name="slide-fade" tag="div" class="row row--actions">
            <template v-if="hasChanges">
                <Words :key="`transition_notification`" bold>
                    <NoticeIcon class="icon--inline notice-icon" />
                    {{ $t(`${i18nKey}.notSaveInfo`) }}
                </Words>

                <Button :key="`transition_discard`" arrow-right class="discard-btn mx-6" @click="$emit('discard')">
                    {{ $t(`${i18nKey}.discard`) }}
                </Button>
                <div :key="`transition_save`" class="flex flex-col-reverse items-center justify-center md:flex-row">
                    <i18n
                        path="components.transportListBlock.termsAndPrivacyLabel"
                        tag="span"
                        class="font-copy-xs mx-4 mt-2 text-subdued md:mt-0"
                    >
                        <a
                            :href="linkLegalTermsAndConditionsSeller"
                            target="_blank"
                            class="font-copy-xs inline text-subdued underline"
                            place="gtc"
                            >{{ $t('components.transportListBlock.termsAndPrivacy.gtcLabel') }}</a
                        >
                        <a
                            :href="linkLegalDataProtectionPolicyGlobal"
                            target="_blank"
                            class="font-copy-xs inline text-subdued underline"
                            place="dpp"
                        >
                            {{ $t('components.transportListBlock.termsAndPrivacy.dppLabel') }}</a
                        >
                    </i18n>

                    <Button primary class="save-btn" @click="$emit('save')">
                        {{ $t(`${i18nKey}.save`) }}
                    </Button>
                </div>
            </template>
        </transition-group>
        <div class="row row--delete">
            <Button class="delete-btn" @click="$emit('delete')">
                <DeleteIcon width="24" height="24" class="icon--inline delete-icon" />
                {{ product.wasteCode }}
                {{ $t(`${i18nKey}.delete`) }}
            </Button>
        </div>
    </div>
</template>

<script>
import Button from '@/components/Button/Button';
import Words from '@/components/Typography/Words';

import DeleteIcon from '@/assets/icons/regular/_garbage.svg';
import NoticeIcon from '@/assets/icons/regular/_notice.svg';
import _isEqual from 'lodash/isEqual';
import { mapGetters } from 'vuex';

export default {
    name: 'SupplierWasteProductDetailsActions',
    components: {
        Button,
        Words,

        DeleteIcon,
        NoticeIcon,
    },
    props: {
        i18nKey: {
            type: String,
            default: 'pages.products.supplierProductsList.wasteProductDetails',
        },
        product: {
            type: Object,
            default: () => ({}),
        },
        comparedVariantsDisposal: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        ...mapGetters('platform', ['linkLegalDataProtectionPolicyGlobal', 'linkLegalTermsAndConditionsSeller']),
        hasChanges() {
            const leftCompare = this.product?.variantsDisposal || [];
            const rightCompare = this.comparedVariantsDisposal;

            return !(_isEqual(leftCompare, rightCompare) && leftCompare.length === rightCompare.length);
        },
        hasVariantsDisposal() {
            return this.product?.variantsDisposal?.length;
        },
    },
};
</script>

<style lang="scss" scoped src="./SupplierWasteProductDetailsActions.scss" />

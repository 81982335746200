<template>
    <GridTable columns="150px auto 150px" class="waste-group">
        <slot slot="head" name="header" />
        <GridRow :count="1">
            <!--
                Waste Groups
            -->
            <Accordion
                ref="accordion"
                :key="group.groupFields.groupName"
                :has-content="hasItems"
                :class="['waste-groups', { 'waste-groups--empty': !hasItems }]"
            >
                <!-- Waste Group Code -->
                <template #preheader>
                    <Words
                        block
                        bold
                        white
                        class="with-separator"
                        :class="{
                            'with-separator--hidden': !group.groupFields.wasteKey,
                        }"
                    >
                        {{ group.groupFields.wasteKey || group.groupFields.wasteCode || '&nbsp;' }}
                    </Words>
                </template>

                <!-- Waste Group Name -->
                <template #header>
                    <Words bold white>
                        {{ group.groupFields.groupName }}
                    </Words>
                    <Words bold red>( {{ group.wasteItems.length }} )</Words>
                </template>

                <!-- Waste Group Tags -->
                <template v-if="groupTags" #postheader>
                    <div class="group-tags">
                        <SfTag v-for="(tag, tagIndex) in groupTags" :key="tagIndex" theme="dark" outline class="ml-2">
                            {{ tag.label }}
                        </SfTag>
                    </div>
                </template>

                <!--
                    Waste Group Items
                -->
                <Accordion
                    v-for="(wasteItem, i) in group.wasteItems"
                    :key="`waste-item-${i}`"
                    data-test="waste-item"
                    :open-on-init="false"
                    type="secondary"
                    @accordion-click="params => editWasteProduct(wasteItem)"
                >
                    <!-- Waste Code -->
                    <template #preheader>
                        <WasteCode block bold :code="wasteItem.wasteCode" :is-dangerous="wasteItem.isDangerous" />
                    </template>

                    <!-- Waste Code Name -->
                    <template #header>
                        <Words block>{{ revertLocalizedValue(wasteItem.productName) }}</Words>

                        <!-- Waste Code Tags -->
                        <template v-if="wasteItem.tags">
                            <SfTag v-for="(tag, tagIndex) in wasteItem.tags" :key="tagIndex" subdued class="mr-2 mt-2">
                                {{ revertLocalizedValue(tag.text) }}
                            </SfTag>
                        </template>
                    </template>

                    <!-- Waste Code State -->
                    <template #postheader>
                        <div
                            class="dot-indicator"
                            :class="{
                                'dot-indicator--available': wasteItem.isActive,
                                'dot-indicator--not-available': !wasteItem.isActive,
                            }"
                        />
                    </template>
                </Accordion>
            </Accordion>
        </GridRow>
    </GridTable>
</template>

<script>
import Accordion from '@/_components/Accordion/Accordion';
import GridRow from '@/components/Layout/GridRow';
import GridTable from '@/components/Table/GridTable';
import WasteCode from '@/pages/ProductManagement/components/WasteCode.vue';
import Words from '@/components/Typography/Words';

import { SfTag } from '@schuettflix/vue-components';

import { revertLocalizedValue } from '@/services/utils/localization';

export default {
    name: 'WasteGroup',
    components: {
        Accordion,
        GridTable,
        GridRow,
        WasteCode,
        Words,

        SfTag,
    },
    props: {
        group: {
            type: Object,
            default: null,
        },
    },
    computed: {
        hasItems() {
            return !!this.group.wasteItems.length;
        },
        groupTags() {
            const tags = [];
            if (this.group.wasteItems.length === 0) {
                tags.push({ label: this.$t('pages.productManagement.wasteGroup.categoryIsEmpty') });
            }
            if (this.group.groupFields.isInactive) {
                tags.push({ label: this.$t('pages.productManagement.wasteGroup.categoryIsInactive') });
            }
            return tags;
        },
    },
    methods: {
        revertLocalizedValue: revertLocalizedValue,
        editWasteProduct(product) {
            if (!this.$can('updateWasteProductCatalog')) return;

            this.$emit('click-product-item', product.id);
        },
    },
};
</script>

<style lang="scss" scoped>
// TODO exclude from data component and move to accorion component
.with-separator {
    position: relative;

    &.with-separator--hidden {
        &::after {
            visibility: hidden;
        }
    }

    &::after {
        content: '';
        height: 30px;
        display: block;
        border-right: 1px solid $color-white;
        position: absolute;
        right: 0;
        top: -6px;
        bottom: 0;
    }
}

.dot-indicator {
    display: block;
    margin-top: 4px;
    margin-right: 8px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    justify-self: end;

    &--available {
        background: $color-green;
    }
    &--not-available {
        background: $color-red;
    }
    &--disabled {
        background: $color-littleDarkerThanMediumGrey;
    }
}

.waste-groups {
    &--empty {
        .words--white,
        .words--red {
            color: $color-grey;
        }

        .with-separator {
            &::after {
                border-color: $color-grey;
            }
        }
        .group-tags {
            .tag {
                color: $color-grey;
                border: 1px solid $color-grey;
            }
        }
    }
}
</style>

<template>
    <div>
        <ProjectPositionCloseAction />
        <ProjectPositionPricesChangeAction />
        <ProjectPositionContingentChangeAction />
        <ProjectPositionNoteChangeAction />
        <ProjectPositionAnalysisChangeAction />
        <ProjectPositionStatusChangeAction />
        <ProjectPositionValidityAndStatusChangeAction />
        <ProjectPositionFreightDescriptionChangeAction />
    </div>
</template>

<script>
import ProjectPositionCloseAction from './ProjectPositionCloseAction';
import ProjectPositionContingentChangeAction from '@/pages/Project/Actions/ProjectPositionContingentChangeAction';
import ProjectPositionPricesChangeAction from './ProjectPositionPricesChangeAction';
import ProjectPositionNoteChangeAction from './ProjectPositionNoteChangeAction';
import ProjectPositionAnalysisChangeAction from './ProjectPositionAnalysisChangeAction';
import ProjectPositionStatusChangeAction from '@/pages/Project/Actions/ProjectPositionStatusChangeAction';
import ProjectPositionValidityAndStatusChangeAction from '@/pages/Project/Actions/ProjectPositionValidityAndStatusChangeAction';
import ProjectPositionFreightDescriptionChangeAction from '@/pages/Project/Actions/ProjectPositionFreightDescriptionChangeAction';

export default {
    name: 'ProjectPositionActions',
    components: {
        ProjectPositionStatusChangeAction,
        ProjectPositionValidityAndStatusChangeAction,
        ProjectPositionCloseAction,
        ProjectPositionContingentChangeAction,
        ProjectPositionPricesChangeAction,
        ProjectPositionNoteChangeAction,
        ProjectPositionAnalysisChangeAction,
        ProjectPositionFreightDescriptionChangeAction,
    },
};
</script>

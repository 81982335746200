import i18n from '@/i18n';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { preciseFloat } from '../utils';

/**
 * Generate names for a given search
 */
export async function generateSearchNamesFrom(savedSearch) {
    const from = [];
    const to = [];

    if (savedSearch.originLocation) {
        from.push(savedSearch.originLabel);
        from.push(`${savedSearch.originRadius}km`);
    } else {
        from.push(i18n.t('pages.transportHub.searchFilter.emptySearchLocationLabel'));
    }

    if (savedSearch.destinationLocation) {
        to.push(savedSearch.destinationLabel);
        to.push(`${savedSearch.destinationRadius}km`);
    } else {
        to.push(i18n.t('pages.transportHub.searchFilter.emptySearchLocationLabel'));
    }

    return { fromName: from.join(' '), toName: to.join(' ') };
}

const LOCATION_PRECISION = 10;

/**
 * Create a comparable identifier for a given filter
 */
export function assembleFilterCheckIdentifier(filter) {
    const vehicleClasses = _cloneDeep(_get(filter, 'vehicleClass', []));
    return [
        preciseFloat(_get(filter, '["originGeo.place"].location.lat') || 0, LOCATION_PRECISION),
        preciseFloat(_get(filter, '["originGeo.place"].location.lng') || 0, LOCATION_PRECISION),
        _get(filter, 'originGeo.radius') || '-',
        preciseFloat(_get(filter, '["destinationGeo.place"].location.lat') || 0, LOCATION_PRECISION),
        preciseFloat(_get(filter, '["destinationGeo.place"].location.lng') || 0, LOCATION_PRECISION),
        _get(filter, 'destinationGeo.radius') || '-',
        vehicleClasses.sort().join(',') || '-',
        (filter.type && filter.type[0]) || '-',
    ].join('_');
}

/**
 * Create a comparable identifier for a given SearchFilter
 */
export function assembleSearchFilterCheckIdentifier(searchFilter) {
    const vehicleClasses = _cloneDeep(_get(searchFilter, 'vehicleClasses', []));

    return [
        preciseFloat(_get(searchFilter, 'originLocation.lat') || 0, LOCATION_PRECISION),
        preciseFloat(_get(searchFilter, 'originLocation.lng') || 0, LOCATION_PRECISION),
        _get(searchFilter, 'originRadius') || '-',
        preciseFloat(_get(searchFilter, 'destinationLocation.lat') || 0, LOCATION_PRECISION),
        preciseFloat(_get(searchFilter, 'destinationLocation.lng') || 0, LOCATION_PRECISION),
        _get(searchFilter, 'destinationRadius') || '-',
        vehicleClasses.sort().join(',') || '-',
        searchFilter.transportType || '-',
    ].join('_');
}

/**
 * Generate checksums for list of searches
 */
export function generateChecksumsForSavedSearches(savedSearches) {
    return savedSearches.map(item => assembleSearchFilterCheckIdentifier(item));
}

/**
 * Convert saved search to filter format
 */
export function createFilterFromSearch(savedSearch, currentFilter = null) {
    if (!savedSearch) return {};

    const filter = {};

    if (_get(savedSearch, 'originLocation.lat')) {
        filter['originGeo.place'] = {
            label: savedSearch.originLabel,
            location: savedSearch.originLocation,
        };
        filter['originGeo.radius'] = `${savedSearch.originRadius}`;
    }

    if (_get(savedSearch, 'destinationLocation.lat')) {
        filter['destinationGeo.place'] = {
            label: savedSearch.destinationLabel,
            location: savedSearch.destinationLocation,
        };
        filter['destinationGeo.radius'] = `${savedSearch.destinationRadius}`;
    }

    if (savedSearch.vehicleClasses && savedSearch.vehicleClasses.length > 0) {
        filter.vehicleClass = savedSearch.vehicleClasses;
    }

    filter.type = savedSearch.transportType ? [savedSearch.transportType] : [];

    // Copy from current filter
    if (currentFilter) {
        filter.sortBy = `${currentFilter.sortBy}`;
        filter.sortDirection = `${currentFilter.sortDirection}`;
    }

    return filter;
}

/**
 * Convert filter to saved search format
 */
export function createSearchFromFilter(filter) {
    const savedSearch = {};

    if (filter['originGeo.place'] && filter['originGeo.radius']) {
        savedSearch.originLabel = filter['originGeo.place'].label;
        savedSearch.originLocation = filter['originGeo.place'].location;
        savedSearch.originRadius = parseInt(filter['originGeo.radius']);
    }

    if (filter['destinationGeo.place'] && filter['destinationGeo.radius']) {
        savedSearch.destinationLabel = filter['destinationGeo.place'].label;
        savedSearch.destinationLocation = filter['destinationGeo.place'].location;
        savedSearch.destinationRadius = parseInt(filter['destinationGeo.radius']);
    }

    savedSearch.vehicleClasses = filter.vehicleClass || [];
    savedSearch.transportType = filter.type && filter.type[0] ? filter.type[0] : null;

    return savedSearch;
}

import AbstractFilterableResource from './AbstractFilterableResource';

class Token extends AbstractFilterableResource {
    async getTokensBySupplierId(supplierId) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/supplier/${supplierId}`, null, {
                trackRequest: true,
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }
}

export default new Token('/v1/tokens');

<template>
    <LayoutPage class="project-view" is-flyout :close-by-x="isProjectPositionEditMode" @close="close()">
        <template v-if="isProjectPositionEditMode" #flyoutHeader>
            <HeaderBar>
                <template #headline>
                    <div>{{ $t('pages.checkout.summary.actions.change.positionValidity') }}</div>
                </template>
                <template #right>
                    <HeaderBarItem button @click="close()">
                        <SfSysCloseIcon size="xs" />
                    </HeaderBarItem>
                </template>
            </HeaderBar>
        </template>

        <template v-else #flyoutHeader>
            <FlyoutHeader />
        </template>
        <LoadingSpinner v-if="isLoading" />

        <template v-else>
            <div class="p-6">
                <span class="font-copy-md-strong mb-2"> {{ $t('pages.project.form.validity.title') }}</span>
                <span class="copy-md">{{
                    $t('pages.checkout.projectPositionValidityAndStatus.validity.description')
                }}</span>
                <TimeRangeFieldset
                    class="mt-6"
                    hide-options
                    :from="validityRange.start"
                    :to="validityRange.end"
                    type="date"
                    :min-from-timestamp="projectValidity.start"
                    @update-from="updateFrom"
                    @update-to="updateTo"
                />
                <SfValidationMessage :text="errorMessage" type="error" />
            </div>
            <div class="p-6">
                <span class="font-copy-md-strong mb-2"> {{ $t('pages.documents.headlines.status') }} </span>
                <span class="copy-md">{{
                    $t('pages.checkout.projectPositionValidityAndStatus.status.description')
                }}</span>
                <ToggleSwitchField
                    :value="status"
                    :disabled="startsInTheFuture || endsInThePast"
                    reverse-label
                    class="mt-6"
                    @input="updateStatus($event)"
                >
                    <span class="font-copy-md-strong"
                        >{{ $t('pages.documents.headlines.status') }}
                        {{ $t(`status.projectPosition.${status ? 'open' : 'inactive'}`) }}</span
                    >
                </ToggleSwitchField>
            </div>
        </template>
        <template #sticky>
            <ButtonGroup>
                <SfLink v-if="isProjectPositionEditMode" bold class="mr-8" @click="close()">
                    {{ $t('components.filterBox.cancel') }}
                </SfLink>
                <SfButton
                    :is-loading="isPending('saveProjectPosition')"
                    size="sm"
                    primary
                    :disabled="!isValid"
                    @click="saveValidityAndStatus()"
                >
                    {{
                        isProjectPositionEditMode
                            ? $t('pages.project.form.mode.edit.submit')
                            : $t('components.pagination.next')
                    }}
                </SfButton>
            </ButtonGroup>
        </template>
    </LayoutPage>
</template>

<script>
import ProjectApi from '@/services/Api/Project';
import ProjectPositionApi from '@/services/Api/ProjectPosition';
import ProjectPositionView from '@/models/ProjectPositionView';
import _cloneDeep from 'lodash/cloneDeep';
import { useStateful } from '@/services/stateful/stateful';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import { ensureJSTimestamp, ensurePHPTimestamp } from '@/services/utils/date';
import { mapState } from 'vuex';
import { navigationFailure } from '@/services/utils/router';
import { ref, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import { format, isToday } from 'date-fns';

import ButtonGroup from '@/components/Button/ButtonGroup';
import FlyoutHeader from '@/pages/Checkout/components/Header.vue';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import TimeRangeFieldset from '@/components/Filter/TimeRangeFieldset.v2';
import Toaster from '@/services/Toaster';
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import { SfButton, SfLink, SfSysCloseIcon, SfValidationMessage } from '@schuettflix/vue-components';

export default {
    name: 'ProjectPositionValidityAndStatusPage',
    components: {
        ButtonGroup,
        FlyoutHeader,
        HeaderBar,
        HeaderBarItem,
        LayoutPage,
        LoadingSpinner,
        SfButton,
        SfLink,
        SfSysCloseIcon,
        SfValidationMessage,
        TimeRangeFieldset,
        ToggleSwitchField,
    },

    props: {
        projectPositionId: {
            type: [Number, String],
            default: null,
        },
    },

    setup() {
        const { t } = useI18n();
        const { isPending, stateful } = useStateful();
        const isLoading = ref(false);
        const validityRange = ref({
            start: Date || null,
            end: Date || null,
        });

        const projectValidity = ref({
            start: Date || null,
            end: Date || null,
        });

        const status = ref(true);
        const errorMessage = ref(null);

        const startsInTheFuture = computed(() => {
            if (validityRange.value.start) {
                const now = new Date();
                const start = new Date(ensureJSTimestamp(validityRange?.value.start));

                if (start > now) {
                    return true;
                }
            }
            return false;
        });

        const endsInThePast = computed(() => {
            if (validityRange.value.end) {
                const now = new Date();
                const end = new Date(ensureJSTimestamp(validityRange?.value.end));
                if (isToday(end)) {
                    return false;
                }

                if (end < now) {
                    return true;
                }
            }
            return false;
        });

        const isWithinProjectValidity = computed(() => {
            if (validityRange?.value.start && validityRange?.value.end) {
                const start = new Date(ensureJSTimestamp(validityRange?.value.start));
                const end = new Date(ensureJSTimestamp(validityRange?.value.end));
                const projectStart = new Date(ensureJSTimestamp(projectValidity?.value.start));
                const projectEnd = new Date(ensureJSTimestamp(projectValidity?.value.end));

                if (
                    (start < projectStart && start.toDateString() !== projectStart.toDateString()) ||
                    (end > projectEnd && end.toDateString() !== projectEnd.toDateString())
                ) {
                    return false;
                }
            }
            return true;
        });

        const isValid = computed(() => {
            if (!validityRange.value.start && !validityRange.value.end) {
                return true;
            }
            if (
                !isWithinProjectValidity.value ||
                endsInThePast.value ||
                !validityRange.value.start ||
                !validityRange.value.end
            ) {
                return false;
            }

            return true;
        });

        watch(startsInTheFuture, value => {
            if (value) {
                status.value = false;
            }
        });

        watch(endsInThePast, value => {
            if (value) {
                errorMessage.value = t('pages.checkout.projectPositionValidityAndStatus.validity.endsInThePastError');
            } else {
                errorMessage.value = null;
            }
        });

        watch(isWithinProjectValidity, value => {
            if (value === false) {
                errorMessage.value = t(
                    'pages.checkout.projectPositionValidityAndStatus.validity.outsideOfProjectValidityError'
                );
            } else {
                errorMessage.value = null;
            }
        });

        return {
            validityRange,
            projectValidity,
            status,
            errorMessage,
            isLoading,
            isPending,
            stateful,

            //computed
            startsInTheFuture,
            endsInThePast,
            isWithinProjectValidity,
            isValid,
        };
    },
    data() {
        return {
            projectPosition: null,
            projectPositionDto: null,
        };
    },
    computed: {
        ...mapState('basket', {
            typeFromBasket: 'type',
            constructionSiteFromBasket: 'constructionSite',
            projectIdFromBasket: 'projectId',
            projectPositionValidityRange: 'validityRange',
            projectPositionStatus: 'status',
        }),
        ...mapState('projectPosition', ['requestCollection']),

        isDisposalOrder() {
            return [
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS,
            ].includes(this.typeFromBasket);
        },

        isProjectPositionEditMode() {
            return !!this.projectPositionId;
        },
    },

    created() {
        if (this.projectPositionId) {
            this.loadProjectPosition();
        } else {
            this.refreshValidityAndStatus();
        }
    },
    methods: {
        updateFrom(value) {
            this.validityRange.start = ensurePHPTimestamp(value);
        },

        updateTo(value) {
            this.validityRange.end = ensurePHPTimestamp(value);
        },

        updateStatus(value) {
            this.status = value;
        },

        async loadProjectPosition() {
            this.isLoading = true;
            const result = await ProjectPositionApi.getOneById(this.projectPositionId);
            this.projectPosition = ProjectPositionView.create(result);
            this.projectPositionDto = result;
            this.status = this.projectPosition.status === 'open';

            this.validityRange = {
                start: new Date(this.projectPosition.validFrom).getTime(),
                end: new Date(this.projectPosition.validTo).getTime(),
            };

            this.projectValidity = {
                start: this.projectPosition.project.validFrom,
                end: this.projectPosition.project.validTo,
            };
            this.isLoading = false;
        },

        async refreshDisposalOrder() {
            this.isLoading = true;
            this.validityRange = {
                start: new Date(this.requestCollection.validityRange?.start).getTime() || null,
                end: new Date(this.requestCollection.validityRange?.end).getTime() || null,
            };

            this.status = this.requestCollection.status === 'open';

            const project = await ProjectApi.getOneById(this.requestCollection.project.id);

            this.projectValidity = {
                start: project.validFrom,
                end: project.validTo,
            };
            this.isLoading = false;
        },

        async refreshNonDisposalOrder() {
            this.isLoading = true;

            this.validityRange = {
                start: new Date(this.projectPositionValidityRange?.start).getTime() || null,
                end: new Date(this.projectPositionValidityRange?.end).getTime() || null,
            };

            const project = await ProjectApi.getOneById(this.projectIdFromBasket);

            this.projectValidity = {
                start: project.validFrom,
                end: project.validTo,
            };

            this.status = this.projectPositionStatus === 'open';
            this.isLoading = false;
        },

        refreshValidityAndStatus() {
            if (this.isDisposalOrder) {
                this.refreshDisposalOrder();
            } else {
                this.refreshNonDisposalOrder();
            }
        },

        saveStep() {
            const positionStatus = this.status ? 'open' : 'inactive';
            const validityRangeStrings = {
                start: this.validityRange.start
                    ? format(new Date(ensureJSTimestamp(this.validityRange.start)), 'YYYY-MM-DD')
                    : null,
                end: this.validityRange.start
                    ? format(new Date(ensureJSTimestamp(this.validityRange.end)), 'YYYY-MM-DD')
                    : null,
            };
            if (this.isDisposalOrder || this.isProjectPositionEditMode) {
                this.$store.commit('projectPosition/SET_PROJECT_POSITION_VALIDITY_RANGE', validityRangeStrings);
                this.$store.commit('projectPosition/SET_PROJECT_POSITION_STATUS', positionStatus);
            } else {
                this.$store.commit('basket/setProjectPositionStatus', positionStatus);
                this.$store.commit('basket/setProjectPositionValidityRange', validityRangeStrings);
            }

            this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(navigationFailure);
        },

        async savePosition() {
            await this.stateful('saveProjectPosition', async () => {
                try {
                    const payload = await ProjectPositionApi.transformProjectPosition({
                        ..._cloneDeep(this.projectPositionDto),
                        status: this.status ? 'open' : 'inactive',
                        validFrom: format(new Date(ensureJSTimestamp(this.validityRange.start)), 'YYYY-MM-DD'),
                        validTo: format(new Date(ensureJSTimestamp(this.validityRange.end)), 'YYYY-MM-DD'),
                    });

                    await ProjectPositionApi.updateProjectPosition(this.projectPositionId, payload);
                    Toaster.success(this.$t('pages.checkout.projectValidityAndStatus.saveSuccessMessage'));
                    this.$emit('projectPositionSaved');
                } catch (err) {
                    Toaster.error(err);
                }
            });
        },

        saveValidityAndStatus() {
            if (this.isProjectPositionEditMode) {
                this.savePosition();
            } else {
                this.saveStep();
            }
        },

        close() {
            if (!this.isProjectPositionEditMode) return;
            this.$emit('close');
        },
    },
};
</script>

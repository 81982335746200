import gsap, { TimelineLite } from 'gsap';
import { POWER2_IN, POWER2_OUT, POWER1_INOUT } from '@/constants/animations';

gsap.config({
    nullTargetWarn: false,
});

/**
 * Animates a series of bubbles' movements vertically. While rising the bubbles may move a distance towards left or right randomly.
 * @param  {string[]} elSelectors Selectors of the bubble elements. Use class selectors for SVGs and SVG elements.
 * @param  {number} maxXMovement A range by which the rising bubble my move horizontally by random
 * @param  {number} startYMovement Start position on the y axis
 * @param  {number} endYMovement End position on the y axis
 * @param  {object} firstSelectorDisplacement={x,y} Displacement of the first selector in elSelectors during the animation.
 * @param  {number} durationOfOneRepeat Defaults to 0.2 seconds.
 * @param  {string} transformOrigin If empty top left corner is origin of animation. Defaults to center.
 * @param  {TimelineLite} timeline Provide one if you want to chain animations.
 * @param  {number} repeat -1 = indefinitely, 0 = no repeat, >0 = number of repetitions. Defaults to -1.
 * @return  {null}
 */
export function animateBubbles(
    elSelectors,
    maxXMovement,
    startYMovement,
    endYMovement,
    firstSelectorDisplacement = { x: 0, y: 0 },
    durationOfOneRepeat = 1.5,
    transformOrigin = '50% 100%',
    timeline = new TimelineLite(),
    repeat = 2
) {
    if (!elSelectors || elSelectors.length === 0) return;

    const delayUntilNextBubbleAnimation = durationOfOneRepeat / elSelectors.length;
    let startOfAnimationInTimeline = 0;
    const selectorDisplacement = { x: 0, y: 0 };

    let isFirst = true;
    for (const elSelector of elSelectors) {
        const displacement = isFirst ? firstSelectorDisplacement : selectorDisplacement;
        isFirst = false;
        animateBubble(
            elSelector,
            maxXMovement,
            startYMovement,
            endYMovement,
            displacement,
            durationOfOneRepeat,
            transformOrigin,
            timeline,
            repeat,
            startOfAnimationInTimeline
        );
        startOfAnimationInTimeline += delayUntilNextBubbleAnimation;
    }
    if (elSelectors.length > 0) {
        const $target = document.querySelector(elSelectors[0]);
        if ($target) {
            timeline.to($target, {
                duration: 0,
                opacity: 1,
                y: 0,
                x: 0,
                ease: POWER2_IN,
                transformOrigin: transformOrigin,
                onComplete() {
                    $target.style.transform = '';
                    $target.style.transition = '';
                    $target.style.transformOrigin = '';
                },
            });
        }
    }
}

/**
 * Animates a bubble movement vertically. While rising the bubble may move a distance towards left or right randomly.
 * @param  {string} elSelector Use class selectors for SVGs and SVG elements.
 * @param  {number} maxXMovement A range by which the rising bubble my move horizontally by random
 * @param  {number} startYMovement Start position on the y axis
 * @param  {number} endYMovement End position on the y axis
 * @param  {object} selectorDisplacement={x,y} Displacement of the element during the animation.
 * @param  {number} durationOfOneRepeat Defaults to 0.2 seconds.
 * @param  {string} transformOrigin If empty top left corner is origin of animation. Defaults to center.
 * @param  {TimelineLite} timeline Provide one if you want to chain animations.
 * @param  {number} repeat -1 = indefinitely, 0 = no repeat, >0 = number of repetitions. Defaults to -1.
 * @param  {number} startOfAnimationInTimeline The starting position of the animation on the timeline
 * @return  {null}
 */
export function animateBubble(
    elSelector,
    maxXMovement,
    startYMovement,
    endYMovement,
    selectorDisplacement = { x: 0, y: 0 },
    durationOfOneRepeat = 1.5,
    transformOrigin = '50% 100%',
    timeline = new TimelineLite(),
    repeat = -1,
    startOfAnimationInTimeline = 0
) {
    const $target = document.querySelector(elSelector);
    if (!$target) return;

    const targetX = Math.floor(maxXMovement * Math.random()) * (Math.round(Math.random()) ? 1 : -1);

    timeline.fromTo(
        $target,
        {
            opacity: 1,
            x: selectorDisplacement.x,
            y: selectorDisplacement.y + startYMovement,
        },
        {
            duration: durationOfOneRepeat,
            opacity: 0,
            x: selectorDisplacement.x + targetX,
            y: selectorDisplacement.y + endYMovement,
            ease: POWER2_OUT,
            transformOrigin: transformOrigin,
            repeat: repeat,
            onComplete() {
                $target.style.transform = '';
                $target.style.transition = '';
                $target.style.transformOrigin = '';
            },
        },
        startOfAnimationInTimeline
    );
}

/**
 * Animates zooming in an element from 0.5x scale and 0% opacity to 1x scale and 100% opacity.
 * @param  {string} elSelector Use class selectors for SVGs and SVG elements.
 * @param  {number} durationOfOneRepeat Defaults to 0.2 seconds.
 * @param  {string} transformOrigin If empty top left corner is origin of animation. Defaults to center.
 * @param  {TimelineLite} timeline Provide one if you want to chain animations.
 * @return {void}
 */
export function animateZoomIn(
    elSelector,
    durationOfOneRepeat = 0.2,
    transformOrigin = '50% 50%',
    timeline = new TimelineLite()
) {
    const $target = document.querySelector(elSelector);
    if (!$target) return;

    timeline.fromTo(
        $target,
        {
            scale: 0.5,
            opacity: 0,
        },
        {
            duration: durationOfOneRepeat,
            scale: 1,
            opacity: 1,
            ease: POWER2_OUT,
            transformOrigin: transformOrigin,
            onComplete() {
                $target.style.transform = '';
                $target.style.transition = '';
                $target.style.transformOrigin = '';
            },
        }
    );
}

/**
 * Animates zooming in and out an element from 1x scale to 1.2x scale and back to 1x scale.
 * @param  {string} elSelector Use class selectors for SVGs and SVG elements.
 * @param  {number} durationOfOneRepeat Defaults to 0.2 seconds.
 * @param  {string} transformOrigin If empty top left corner is origin of animation. Defaults to center.
 * @param  {TimelineLite} timeline Provide one if you want to chain animations.
 * @return {void}
 */
export function animateZoomInOut(
    elSelector,
    durationOfOneRepeat = 0.2,
    transformOrigin = '50% 50%',
    timeline = new TimelineLite()
) {
    const $target = document.querySelector(elSelector);
    if (!$target) return;

    timeline.to($target, {
        duration: durationOfOneRepeat,
        scale: 1.2,
        ease: POWER2_IN,
        repeat: 1,
        yoyo: true,
        transformOrigin: transformOrigin,
        onComplete() {
            $target.style.transform = '';
            $target.style.transition = '';
            $target.style.transformOrigin = '';
        },
    });
}

/**
 * Animates shaking an element horizontally.
 * @param  {string} elSelector Use class selectors for SVGs and SVG elements.
 * @param  {number} durationOfOneRepeat Defaults to 0.2 seconds.
 * @param  {number} deltaX Distance to move. Animation starts at -ceil(dx/2) and returns to original position at the end. Defaults to 20.
 * @param  {TimelineLite} timeline Provide one if you want to chain animations.
 * @param  {number} repeat -1 = indefinitely, 0 = no repeat, >0 = number of repetitions. Defaults to -1.
 * @param  {boolean} yoyo True to revert each animation before starting next repetition. Defaults to true.
 * @return {void}
 */
export function animateShakeHorizontally(
    elSelector,
    durationOfOneRepeat = 0.2,
    deltaX = 20,
    timeline = new TimelineLite(),
    repeat = -1,
    yoyo = true
) {
    const $target = document.querySelector(elSelector);
    if (!$target) return;

    const halfDuration = durationOfOneRepeat / 2;
    const dx = Number.isInteger(deltaX) && deltaX > 0 ? deltaX : 0;
    const dxHalf = Math.ceil(dx / 2);

    timeline.to($target, {
        duration: halfDuration,
        x: `-=${dxHalf}`,
    });
    timeline.to($target, {
        duration: halfDuration,
        x: `+=${dx}`,
        yoyo: yoyo,
        repeat: repeat,
    });
    timeline.to($target, {
        duration: halfDuration,
        x: `-=${dxHalf}`,
        onComplete() {
            $target.style.transform = '';
            $target.style.transition = '';
            $target.style.transformOrigin = '';
        },
    });
}

/**
 * @param  {string} elSelector Use class selectors for SVGs and SVG elements.
 * @param  {number} durationOfOneRepeat Defaults to 1.2 seconds.
 * @param  {number} deltaXy The initial x and y displacement towards top right at start of animation. Defaults to 15px.
 * @param  {TimelineLite} timeline Provide one if you want to chain animations.
 * @return {void}
 */
export function animateMoveInFromTopRight(
    elSelector,
    durationOfOneRepeat = 1.2,
    deltaXy = 15,
    timeline = new TimelineLite()
) {
    const $target = document.querySelector(elSelector);
    if (!$target) return;

    const quarterDuration = durationOfOneRepeat / 4;
    const threeQuartersDuration = durationOfOneRepeat - quarterDuration;

    const dxy = Number.isInteger(deltaXy) && deltaXy > 0 ? deltaXy : 0;
    timeline.to($target, {
        duration: threeQuartersDuration,
        x: `+=${dxy}`,
        y: `-=${dxy}`,
        ease: POWER1_INOUT,
    });
    timeline.to($target, {
        duration: quarterDuration,
        x: `-=${dxy}`,
        y: `+=${dxy}`,
        ease: 'elastic.in(1, 0.75)',
    });
}

/**
 * Swings an element towards the screen, then back, then towards screen and back to initial position.
 * @param  {string} elSelector Use class selectors for SVGs and SVG elements.
 * @param  {number} durationOfOneRepeat Defaults to 2.0 seconds.
 * @param  {number} deltaDegrees The initial degrees to rotate left and right. Defaults to 20 degrees.
 * @param  {string} transformOrigin If empty top left corner is origin of animation. Defaults to center top.
 * @param  {TimelineLite} timeline Provide one if you want to chain animations.
 * @param  {number} repeat -1 = indefinitely, 0 = no repeat, >0 = number of repetitions. Defaults to 1.
 * @param  {boolean} yoyo True to revert each animation before starting next repetition. Defaults to true.
 * @return {void}
 */
export function animateSwingForthAndBack(
    elSelector,
    durationOfOneRepeat = 2.0,
    deltaDegrees = 20,
    transformOrigin = '50% 0%',
    timeline = new TimelineLite(),
    repeat = 1,
    yoyo = true
) {
    const $target = document.querySelector(elSelector);
    if (!$target) return;

    const degrees = Number.isInteger(deltaDegrees) && deltaDegrees > 0 ? deltaDegrees : 0;

    timeline.fromTo(
        $target,
        {
            '--angle': `${degrees}deg`,
            transformOrigin: transformOrigin,
        },
        {
            duration: durationOfOneRepeat,
            '--angle': `${-degrees}deg`,
            ease: POWER1_INOUT,
            yoyo: yoyo,
            repeat: repeat,
            onComplete() {
                $target.style.transform = '';
                $target.style.transition = '';
                $target.style.transformOrigin = '';
            },
        }
    );
    timeline.to($target, {
        duration: durationOfOneRepeat,
        '--angle': `0deg`,
        transformOrigin: transformOrigin,
        ease: POWER1_INOUT,
        onComplete() {
            $target.style.transform = '';
            $target.style.transition = '';
            $target.style.transformOrigin = '';
        },
    });
}

/**
 * Combines animateZoomIn on an element and afterwards animateZoomInOut on another element.
 * Gives a nice eye catcher if first selector is the SVG and the second a group within the
 * SVG since the group will pop out and back in once.
 * @param  {string} svgSelector Use class selectors for SVGs and SVG elements.
 * @param  {string} groupSelector Use class selectors for SVGs and SVG elements.
 */
export function animateZoomInWithGroupZoomInOut(svgSelector, groupSelector) {
    const timeline = new TimelineLite();
    animateZoomIn(svgSelector, 0.2, '50% 50%', timeline);
    animateZoomInOut(groupSelector, 0.1, '50% 50%', timeline);
}

/**
 * Shakes an element horizontally by +/-2px for six times.
 * @param  {string} groupSelector Use class selectors for SVGs and SVG elements.
 */
export function animateGroupShake(groupSelector) {
    const timeline = new TimelineLite();
    animateShakeHorizontally(groupSelector, 0.1, 4, timeline, 6);
}

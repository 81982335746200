import AbstractFilterableResource from './AbstractFilterableResource';

class FactoryDrafts extends AbstractFilterableResource {
    async save(resourceObject) {
        try {
            const requestData = this.mapLocationData(resourceObject);
            const response = await this.apiService.post(`${this.resourcePath}`, requestData);

            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async put(factoryId, routeName, resourceObject) {
        try {
            const response = await this.apiService.put(
                `${this.resourcePath}/${factoryId}/${routeName}`,
                resourceObject
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    mapLocationData(resourceObject) {
        const { city, countryCode, country, street_number, number, postalCode, zip, stateCode, state, street } =
            resourceObject.address;

        return {
            ...resourceObject,
            address: {
                city,
                country: countryCode || country,
                number: street_number || number,
                state: stateCode || state,
                street,
                zip: postalCode || zip,
            },
        };
    }

    async getFactoryListByOrganizationId(organizationId) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/platform/organization/${organizationId}`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new FactoryDrafts('/v1/factory-drafts');

<template>
    <div :class="['image-document-uploader']">
        <slot v-if="!withoutLabel" :count="uploadedDocumentAndPhotoCount">
            <Words bold block spaced-bottom-small>
                {{ $tc('components.imageDocumentUploader.documentsLabel', uploadedDocumentAndPhotoCount) }}
            </Words>
        </slot>

        <template v-if="uploadedDocuments.length + uploadedPhotos.length < limit">
            <Card
                v-if="$root.isApp && !withFileUploadOnMobile"
                no-padding
                spaceless
                :class="{ 'image-document-uploader__document-card-form-border': withFormBorder }"
                class="image-document-uploader__document-card"
            >
                <div class="image-document-uploader__uploader">
                    <div class="image-document-uploader__uploader-placeholder">
                        <Words small>{{ $t('components.imageDocumentUploader.photoPlaceholder') }}</Words>
                    </div>

                    <LoadingSpinner v-if="uploadInProgress" dark />

                    <template v-else>
                        <slot name="photoButtonIfIsApp" :trigger-photo-file-select="triggerPhotoFileSelect">
                            <BaseButton
                                v-if="!withoutImageUpload"
                                :disabled="disabled"
                                arrow-right
                                place-left
                                class="image-document-uploader__uploader-text"
                                @click="triggerPhotoFileSelect()"
                            >
                                {{ $t('components.imageDocumentUploader.photoButtonText') }}
                            </BaseButton>
                        </slot>
                    </template>
                </div>
            </Card>
            <Card
                v-else
                no-padding
                spaceless
                :class="{
                    'image-document-uploader__document-card-form-border': withFormBorder,
                    'image-document-uploader__document-card--no-box-shadow': vertical,
                }"
                class="image-document-uploader__document-card p-0"
            >
                <LoadingSpinner v-if="uploadInProgress" dark class="image-document-uploader__loading-container" />
                <ButtonGroup
                    v-else
                    inline
                    bordered
                    :vertical="vertical"
                    :class="{ 'image-document-uploader__button-group--spaced-bottom': vertical }"
                >
                    <slot
                        name="photoButtonIfIsDesktop"
                        :trigger-photo-file-select="triggerPhotoFileSelect"
                        :disabled="isImageLimitReached"
                        :vertical="vertical"
                    >
                        <BaseButton
                            v-if="!withoutImageUpload"
                            primary
                            light
                            arrow-right
                            block
                            :disabled="isImageLimitReached || disabled"
                            :class="{ 'image-document-uploader__button--spaced-bottom': vertical }"
                            @click="triggerPhotoFileSelect()"
                        >
                            {{ $t('components.imageDocumentUploader.photoButtonText') }}
                        </BaseButton>
                    </slot>
                    <slot
                        name="documentButtonIfIsDesktop"
                        :trigger-document-file-select="triggerDocumentFileSelect"
                        :disabled="isDocumentLimitReached || disabled"
                        :vertical="vertical"
                    >
                        <BaseButton
                            primary
                            light
                            arrow-right
                            block
                            :disabled="isDocumentLimitReached || disabled"
                            :class="{ 'image-document-uploader__button--spaced-bottom': vertical }"
                            @click="triggerDocumentFileSelect()"
                        >
                            {{
                                $t(translationKeyForButtonText) ||
                                $t('components.imageDocumentUploader.defaultDocumentButtonText')
                            }}
                        </BaseButton>
                    </slot>
                </ButtonGroup>
            </Card>
        </template>

        <ErrorMessage
            v-if="limitImage && isImageLimitReached"
            :message="$tc('components.imageDocumentUploader.errors.imageLimit', limitImage)"
            :dark="true"
        />
        <ErrorMessage
            v-if="limitDocument && isDocumentLimitReached"
            :message="$tc('components.imageDocumentUploader.errors.documentLimit', limitDocument)"
            :dark="true"
        />
        <ErrorMessage
            v-if="isOverallLimitReached"
            :message="$tc('components.imageDocumentUploader.errors.overallLimit', limit)"
            :dark="true"
        />

        <!-- uploaded photos -->
        <Card
            v-for="uploadedPhoto in uploadedPhotosReversed"
            :key="uploadedPhoto.uuid"
            no-padding
            spaceless
            :disabled="disabled"
            :class="{ 'image-document-uploader__document-card--has-form-border': withFormBorder }"
            class="image-document-uploader__document-card"
        >
            <div class="image-document-uploader__uploader">
                <Thumbnail
                    class="image-document-uploader__tile-thumbnail"
                    :src="uploadedPhoto.url"
                    :inline="false"
                    modal
                />
                <BaseButton
                    arrow-right
                    place-left
                    :disabled="disabled"
                    class="image-document-uploader__uploader-text"
                    @click="changeUploadedPhoto(uploadedPhoto.uuid)"
                >
                    {{ $t('components.imageDocumentUploader.photoButtonTextChange') }}
                </BaseButton>
                <BaseButton :disabled="disabled" @click="removePhoto(uploadedPhoto.uuid)">
                    <DeleteIcon />
                </BaseButton>
            </div>
        </Card>

        <!-- uploaded documents -->
        <Card
            v-for="uploadedDocument in uploadedDocumentsReversed"
            :key="uploadedDocument.uuid"
            no-padding
            spaceless
            :disabled="disabled"
            :class="{ 'image-document-uploader__document-card-form-border': withFormBorder }"
            class="image-document-uploader__document-card"
        >
            <div class="image-document-uploader__uploader">
                <a class="image-document-uploader__pdf-preview" :href="uploadedDocument.url">
                    <div class="image-document-uploader__zoom-container">
                        <ZoomIcon class="image-document-uploader__zoom-icon" />
                    </div>
                    <PdfIcon class="image-document-uploader__pdf-icon" />
                </a>

                <div
                    :class="{ 'image-document-uploader__title-multi-buttons-horizontal': horizontalDocumentButtons }"
                    class="image-document-uploader__title-multi-buttons"
                >
                    <BaseButton
                        arrow-right
                        place-left
                        :class="{
                            'image-document-uploader__uploader-text-buttons-horizontal': horizontalDocumentButtons,
                        }"
                        class="image-document-uploader__uploader-text"
                        :href="uploadedDocument.url"
                        :disabled="disabled"
                    >
                        {{ uploadedDocument.fileName }}
                    </BaseButton>

                    <BaseButton
                        arrow-right
                        place-left
                        :class="{
                            'image-document-uploader__uploader-text-buttons-horizontal': horizontalDocumentButtons,
                        }"
                        :disabled="disabled"
                        class="image-document-uploader__uploader-text"
                        @click="changeUploadedDocument(uploadedDocument.uuid)"
                    >
                        {{ $t('components.imageDocumentUploader.documentButtonChangeText') }}
                    </BaseButton>
                </div>

                <BaseButton :disabled="disabled" @click="tryRemoveDocument(uploadedDocument.uuid)">
                    <DeleteIcon />
                </BaseButton>
            </div>
        </Card>

        <ErrorMessage v-if="error" :message="error" />

        <!-- hidden document and photo uploaders -->
        <div class="image-document-uploader__hidden-uploaders">
            <ImageUpload
                ref="photoUploader"
                :max-size="fileSize"
                :product-use-case="(productUseCases && productUseCases.image) || null"
                @error="handleUploaderError"
                @input="photoUploaded"
                @uploadingStarted="uploadInProgress = true"
                @uploadingFinished="uploadInProgress = false"
            />

            <FileUploader
                ref="documentUploader"
                :document-type-code="documentTypeCode"
                :product-use-case="(productUseCases && productUseCases.document) || null"
                :file-size="fileSize"
                @error="handleUploaderError"
                @input="documentUploaded"
                @uploadingStarted="uploadInProgress = true"
                @uploadingFinished="uploadInProgress = false"
            />
        </div>
    </div>
</template>

<script>
import Interception from '@/services/utils/interception';
import Toaster from '@/services/Toaster';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Card from '@/components/Layout/Card';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FileUploader from '@/components/Form/FileUploader';
import ImageUpload from '@/components/Form/ImageUpload';
import LoadingSpinner from '@/components/LoadingSpinner';
import Thumbnail from '@/components/Thumbnail';
import Words from '@/components/Typography/Words';

import DeleteIcon from '@/assets/icons/regular/garbage.svg';
import PdfIcon from '@/assets/icons/dokument-pdf.svg';
import ZoomIcon from '@/assets/icons/regular/zoom.svg';

export default {
    name: 'ImageDocumentUploader',

    components: {
        BaseButton,
        ButtonGroup,
        Card,
        ErrorMessage,
        FileUploader,
        ImageUpload,
        LoadingSpinner,
        Thumbnail,
        Words,

        DeleteIcon,
        PdfIcon,
        ZoomIcon,
    },

    props: {
        productUseCases: {
            type: Object,
            default: null,
        },
        images: {
            type: Array,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        documents: {
            type: Array,
            default: null,
        },
        documentTypeCode: {
            type: String,
            default: null,
        },
        limit: {
            type: Number,
            default: 50,
        },
        limitImage: {
            type: Number,
            default: null,
        },
        limitDocument: {
            type: Number,
            default: null,
        },
        vertical: {
            type: Boolean,
            default: false,
        },
        horizontalDocumentButtons: {
            type: Boolean,
            default: false,
        },
        withFormBorder: {
            type: Boolean,
            default: false,
        },
        withoutImageUpload: {
            type: Boolean,
            default: false,
        },
        withoutLabel: {
            type: Boolean,
            default: false,
        },
        withFileUploadOnMobile: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        translationKeyForButtonText: {
            type: String,
            default: null,
        },
        fileSize: {
            type: Number,
            default: 25,
        },
    },

    data() {
        return {
            uploadInProgress: false,
            uploadedDocuments: this.documents ? [...this.documents] : [],
            uploadedPhotos: this.images ? [...this.images] : [],
            replacerUuid: null,
        };
    },

    computed: {
        uploadedDocumentsReversed() {
            return [...this.uploadedDocuments].reverse();
        },

        uploadedPhotosReversed() {
            return [...this.uploadedPhotos].reverse();
        },

        uploadedDocumentAndPhotoCount() {
            if (this.uploadedDocuments.length > 0 || this.uploadedPhotos.length > 0) {
                return this.uploadedDocuments.length + this.uploadedPhotos.length;
            } else {
                return 0;
            }
        },

        isImageLimitReached() {
            if (this.limitImage) {
                return this.uploadedPhotos.length >= this.limitImage;
            }
            return this.isOverallLimitReached;
        },

        isDocumentLimitReached() {
            if (this.limitDocument) {
                return this.uploadedDocuments.length >= this.limitDocument;
            }
            return this.isOverallLimitReached;
        },

        isOverallLimitReached() {
            // return false, if limit for specific file type is set
            if (this.limitImage || this.limitDocument) {
                return false;
            }

            // Don't show the limit warning on a single uploaded file
            if (this.limit === 1) {
                return false;
            }

            return this.uploadedDocuments.length + this.uploadedPhotos.length >= this.limit;
        },
    },

    watch: {
        documents: {
            immediate: true,
            handler(value) {
                this.uploadedDocuments = value ? [...value] : [];
            },
        },

        images: {
            immediate: true,
            handler(value) {
                this.uploadedPhotos = value ? [...value] : [];
            },
        },
    },

    methods: {
        handleUploaderError(error) {
            if (!error) return;
            Toaster.error(error);
        },

        changeUploadedPhoto(uuid) {
            this.replacerUuid = uuid;
            this.$refs.photoUploader.triggerFileSelect();
        },

        changeUploadedDocument(uuid) {
            this.replacerUuid = uuid;
            this.$refs.documentUploader.triggerFileSelect();
        },

        documentUploaded(file) {
            if (!file) return;

            if (this.replacerUuid) {
                const replacerIndex = this.uploadedDocuments.findIndex(document => document.uuid === this.replacerUuid);
                this.uploadedDocuments = [
                    ...this.uploadedDocuments.slice(0, replacerIndex),
                    file,
                    ...this.uploadedDocuments.slice(replacerIndex + 1, this.uploadedDocuments.length),
                ];
                this.replacerUuid = null;
            } else {
                this.uploadedDocuments.push(file);
            }

            this.$emit('updateDocuments', this.uploadedDocuments);
        },

        photoUploaded(file) {
            if (!file) return;

            if (this.replacerUuid) {
                const replacerIndex = this.uploadedPhotos.findIndex(photo => photo.uuid === this.replacerUuid);
                this.uploadedPhotos = [
                    ...this.uploadedPhotos.slice(0, replacerIndex),
                    file,
                    ...this.uploadedPhotos.slice(replacerIndex + 1, this.uploadedPhotos.length),
                ];
                this.replacerUuid = null;
            } else {
                this.uploadedPhotos.push(file);
            }

            this.$emit('updateImages', this.uploadedPhotos);
        },

        removeDocument(uuid) {
            const index = this.uploadedDocuments.findIndex(document => document.uuid === uuid);

            this.uploadedDocuments = [
                ...this.uploadedDocuments.slice(0, index),
                ...this.uploadedDocuments.slice(index + 1, this.uploadedDocuments.length),
            ];

            this.$emit('updateDocuments', this.uploadedDocuments);
        },

        tryRemoveDocument(uuid) {
            if (!this.$listeners?.interceptDocumentRemove) {
                this.removeDocument(uuid);

                return;
            }

            this.$emit('interceptDocumentRemove', new Interception(() => this.removeDocument(uuid)));
        },

        removePhoto(uuid) {
            const index = this.uploadedPhotos.findIndex(photo => photo.uuid === uuid);

            this.uploadedPhotos = [
                ...this.uploadedPhotos.slice(0, index),
                ...this.uploadedPhotos.slice(index + 1, this.uploadedPhotos.length),
            ];
            this.$emit('updateImages', this.uploadedPhotos);
        },

        triggerPhotoFileSelect() {
            this.$refs.photoUploader.triggerFileSelect();
        },

        triggerDocumentFileSelect() {
            this.$refs.documentUploader.triggerFileSelect();
        },
    },
};
</script>

<style lang="scss">
.image-document-uploader {
    &__transport-count-row {
        display: grid;
        grid-template-columns: 34px 55px 34px 1fr 1fr;
        column-gap: 15px;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 24px;

        border-bottom: 1px solid $color-mediumGrey;

        @media screen and (max-width: 768px) {
            column-gap: 0px;
        }
    }

    &__payload-button {
        font-weight: 400;
        justify-self: center;
        width: 34px;
        height: 34px;
        line-height: 34px;
    }

    &__document-card {
        margin-bottom: 15px;

        &--no-box-shadow {
            box-shadow: none;
        }

        &--has-form-border {
            border: 1px solid rgba(0, 0, 0, 0.2);
        }
    }

    &__document-card &__button-group--spaced-bottom {
        background-color: $color-lightMediumGrey;
    }

    &__document-card &__button--spaced-bottom {
        margin-bottom: 15px;
        box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
    }

    &__uploader {
        position: relative;

        display: grid;
        grid-template-columns: 74px 1fr 30px;
        align-items: center;

        &-text {
            margin-left: 20px;

            &-buttons-horizontal {
                align-self: center;
            }
        }

        &-wrapper {
            display: flex;
            align-items: center;
        }

        &-placeholder {
            width: 75px;
            height: 75px;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: $color-lightGrey;
            border: 1px dashed rgba(0, 0, 0, 0.2);
        }
    }

    &__tile-thumbnail {
        width: 75px;
        height: 75px;
    }

    &__hidden-uploaders {
        height: 0px;
        overflow: hidden;
    }

    &__pdf-preview {
        position: relative;
        display: grid;
        justify-content: center;
        align-items: center;

        &:hover {
            opacity: 0.6;
            cursor: pointer;
        }
    }

    &__pdf-icon {
        width: 65px;
        height: 65px;
    }

    &__zoom-container {
        position: absolute;
        bottom: -6px;
        right: -6px;
        z-index: 1;
        background-color: $color-red;
        border-radius: 100%;
        width: 28px;
        height: 28px;
        line-height: 28px;
        box-shadow: $boxShadow-bottomShort;
    }

    &__title-multi-buttons {
        display: grid;
        grid-template-rows: 1fr 1fr;
        row-gap: 10px;
    }

    &__title-multi-buttons-horizontal {
        @media screen and (min-width: $screen-md) {
            grid-template-columns: 10fr 3fr;
            column-gap: 10px;
            grid-template-rows: 1fr;
            row-gap: 0px;
        }
    }

    &__loading-container {
        min-height: 51px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__right-aligned {
        text-align: right;
    }

    &__hidden-uploaders {
        height: 0px;
        overflow: hidden;
    }
}
</style>

export default class MinMaxValueView {
    constructor() {
        this._value = null;
        this._min = null;
        this._max = null;
    }

    static create(data) {
        return this._creatWithFactor(data, 1);
    }

    static createFromCM(data) {
        return this._creatWithFactor(data, 100);
    }

    static createFromCCM(data) {
        return this._creatWithFactor(data, 1000);
    }

    unfold() {
        return this._unfoldWithFactor(1);
    }

    unfoldToCM() {
        return this._unfoldWithFactor(100);
    }

    unfoldToCCM() {
        return this._unfoldWithFactor(1000);
    }

    static _creatWithFactor(data, factor = 1) {
        const view = new MinMaxValueView();

        view.value = data?.value ? data?.value / factor : null;
        view.min = data?.min ? data?.min / factor : null;
        view.max = data?.max ? data?.max / factor : null;

        return view;
    }

    _unfoldWithFactor(factor = 1) {
        return {
            value: this.value ? this.value * factor : null,
            min: this.min ? this.min * factor : null,
            max: this.max ? this.max * factor : null,
        };
    }

    get value() {
        return this._value;
    }

    set value(v) {
        this._value = v ?? null;
    }

    get min() {
        return this._min;
    }

    set min(v) {
        this._min = v ?? null;
    }

    get max() {
        return this._max;
    }

    set max(v) {
        this._max = v ?? null;
    }
}

<template>
    <div class="ppsb-wrap">
        <div class="ppsb__tag">
            <SfTag v-bind="{ [position.typeTag.type]: true }">
                {{ $t(position.typeTag.label) }}
            </SfTag>
        </div>
        <div class="ppsb">
            <div class="ppsb__main">
                <div class="ppsb__hd">
                    <span class="ppsb__title font-copy-md-strong my-2">
                        {{ position.title }}
                    </span>
                    <div
                        v-if="
                            position.freightType === 'waste_dangerous' ||
                            position.type === 'dangerousWaste' ||
                            position.type === 'waste'
                        "
                        class="justfy-center align-center flex"
                    >
                        <SfTag
                            v-if="position.freightType === 'waste_dangerous' || position.type === 'dangerousWaste'"
                            type="error"
                            outline
                        >
                            <template #icon>
                                <SfWarningIcon size="xxs" multi-color />
                            </template>
                            {{ $t(`components.projectPositionSelectionBlock.freightType.waste_dangerous`) }}
                        </SfTag>
                        <template v-else>
                            <SfTag outline>
                                {{ $t(`components.projectPositionSelectionBlock.freightType.waste_non_dangerous`) }}
                            </SfTag>
                        </template>
                        <SfTag
                            v-if="position.disposalProductVariant && position.disposalProductVariant.pollutionType"
                            class="ml-2"
                        >
                            {{ position.disposalProductVariant.pollutionType.label }}
                        </SfTag>
                    </div>
                    <SfTag v-else-if="!!position.freightType" outline>
                        {{ $t(`components.projectPositionSelectionBlock.freightType.${position.freightType}`) }}
                    </SfTag>
                </div>
                <div>
                    <AddressProgress :list="position.addressProgressList" />

                    <div v-if="position.note">
                        <span class="font-copy-sm-strong mb-2 mt-4">
                            {{ $t('components.projectPositionSelectionBlock.note') }}
                        </span>
                        <span class="font-copy-sm mb-4">{{ position.note }}</span>
                    </div>
                </div>
                <div class="ppsb__ft">
                    <div class="ppsb__stats">
                        <div>
                            <span v-if="position.isDisposal" class="font-copy-xs block">
                                {{ $t('components.projectPositionSelectionBlock.stat.disposal.delivered') }}
                            </span>
                            <span v-else class="font-copy-xs block">
                                {{ $t('components.projectPositionSelectionBlock.stat.delivered') }}
                            </span>
                            <span class="font-copy-md-strong block">
                                {{ position.deliveredQty | tons }}
                            </span>
                        </div>
                        <div>
                            <span v-if="position.isDisposal" class="font-copy-xs block">
                                {{ $t('components.projectPositionSelectionBlock.stat.disposal.reserved') }}
                            </span>
                            <span v-else class="font-copy-xs block">
                                {{ $t('components.projectPositionSelectionBlock.stat.reserved') }}
                            </span>
                            <span class="font-copy-md-strong block">
                                {{ position.reservedQty | tons }}
                            </span>
                        </div>
                        <div>
                            <span class="font-copy-xs block">
                                {{ $t('components.projectPositionSelectionBlock.stat.remaining') }}
                            </span>
                            <span class="font-copy-md-strong block">
                                {{ position.remainingQty | tons }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ppsb__action">
                <ArrowIcon height="14" />
            </div>
        </div>
    </div>
</template>

<script>
import ProjectPositionView from '@/models/ProjectPositionView';
import { SfTag, SfWarningIcon } from '@schuettflix/vue-components';

import AddressProgress from '@/components/Transport/AddressProgress';

import ArrowIcon from '@/assets/icons/regular/arrow-right-bold.svg';

export default {
    name: 'ProjectPositionSelectionBlock',
    components: {
        AddressProgress,
        SfTag,
        SfWarningIcon,

        ArrowIcon,
    },
    props: {
        position: {
            type: ProjectPositionView,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.ppsb {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: auto min-content;
}

.ppsb__main {
}

.ppsb__action {
    display: flex;
    align-items: center;
}

.ppsb__hd {
    padding-bottom: 14px;
    margin-bottom: 14px;
    border-bottom: 1px solid $color-lightMediumGrey;
}

.ppsb__tag {
    text-align: right;
}

.ppsb__title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.ppsb__stats {
    display: grid;
    gap: 20px;
    grid-auto-flow: column;
    padding-top: 14px;
    margin-top: 14px;
    border-top: 1px solid $color-lightMediumGrey;
}
</style>

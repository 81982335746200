import { computed, unref } from 'vue';
export function useTransportScreenName(transportRef, suffixRef) {
    return computed(() => {
        const transport = unref(transportRef);
        const suffix = unref(suffixRef);
        if (!transport?.status || !transport?.type) {
            return undefined;
        }
        return [
            'job',
            'detail',
            transport.type === 'shipment' ? 'forwardingorder' : transport.type,
            transport.status,
            suffix,
        ]
            .filter(Boolean)
            .join('-');
    });
}

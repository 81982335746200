<template>
    <ModalBox
        :active="isActive"
        :headline="$t('pages.vehicle.action.delete.headline')"
        :border="false"
        @opened="refreshActiveTransports()"
        @closed="reset()"
    >
        <Words block>
            {{ $t('pages.vehicle.action.delete.description') }}
        </Words>

        <template v-if="isPending('refreshActiveTransports')">
            <LoadingSpinner block dark />
        </template>

        <template v-if="!isDeletable && subject && !isPending()">
            <Words block spaced-top>
                {{ $t('pages.vehicle.action.delete.info', { licensePlate: subject.licensePlate }) }}
            </Words>
            <ul class="user-form__transports-list">
                <li v-for="(transport, i) in activeTransports" :key="i">
                    <router-link
                        :to="{
                            name: $root.findRouteName('transport-view'),
                            params: { transportId: transport.id },
                        }"
                    >
                        {{ $t('pages.vehicle.action.delete.transportLabel', { number: transport.number }) }}
                    </router-link>
                </li>
            </ul>
        </template>

        <template #actions>
            <ButtonGroup>
                <Button :disabled="isPending('deleteVehicle')" primary light arrow-left @click="reset()">
                    {{ $t('pages.vehicle.action.delete.cancel') }}
                </Button>
                <Button
                    :disabled="!isDeletable || isPending()"
                    :is-loading="isPending('deleteVehicle')"
                    primary
                    @click="deleteVehicle()"
                >
                    <template #left>
                        <DeleteSmallIcon class="icon--inline" />
                    </template>
                    {{ $t('pages.vehicle.action.delete.confirm') }}
                </Button>
            </ButtonGroup>
        </template>
    </ModalBox>
</template>

<script>
import PubSubEvent from '@/services/PubSub/PubSubEvent';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { ACTIONS } from '@/constants/actions';
import { EVENT_VEHICLE_DELETED } from '@/constants/events';
import { mapGetters } from 'vuex';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import VehicleApi from '@/services/Api/Vehicle';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import LoadingSpinner from '@/components/LoadingSpinner';
import ModalBox from '@/components/Modal/ModalBox';
import Toaster from '@/services/Toaster';
import Words from '@/components/Typography/Words';

import DeleteSmallIcon from '@/assets/icons/micro/error.svg';

export default {
    name: 'VehicleDeleteAction',
    components: {
        Button,
        ButtonGroup,
        LoadingSpinner,
        ModalBox,
        Words,

        DeleteSmallIcon,
    },
    mixins: [eventHubMixin, statefulMixin],
    data() {
        return {
            activeTransports: null,
        };
    },
    computed: {
        ...mapGetters('globalActions', ['subject', 'action']),

        isActive() {
            return this.action === ACTIONS.VEHICLE_DELETE && this.$can('deleteVehicle', this.subject);
        },

        isDeletable() {
            return this.activeTransports?.length === 0;
        },
    },
    methods: {
        reset() {
            this.$store.dispatch('globalActions/reset', ACTIONS.VEHICLE_DELETE);
            this.activeTransports = null;
        },

        async refreshActiveTransports() {
            await this.statefulRun('refreshActiveTransports', async () => {
                try {
                    this.activeTransports = await VehicleApi.getActiveTransports(this.subject.id);
                } catch (err) {
                    this.$logger().error(err);
                    Toaster.error(this.$t('pages.vehicle.action.delete.unableToLoadActiveTransports'));
                }
            });
        },

        async deleteVehicle() {
            await this.stateful('deleteVehicle', async () => {
                try {
                    await VehicleApi.delete(this.subject.id);
                    this.$eventHub.$emit(EVENT_VEHICLE_DELETED, new PubSubEvent(this.subject));
                    this.reset();
                    Toaster.info(this.$t('pages.vehicle.action.delete.successfullyDeleted'));
                } catch (err) {
                    this.$logger().error(err);
                    Toaster.error(this.$t('pages.vehicle.action.delete.unableToDelete'));
                }
            });
        },
    },
};
</script>

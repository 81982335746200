import React from 'react';
// TODO: Extract to component library
export const VehicleIcon = props => (<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#a)" strokeWidth={2} strokeMiterlimit={10}>
            <path d="M2.003 8 2 17h3M2.996 9h11v8h-2.434M13.996 9.757V5h4.51L22 8.495v9.504" stroke="#35343D"/>
            <path d="M17 19a2 2 0 1 1 0-4 2 2 0 0 1 0 4ZM7 19a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z" stroke="red"/>
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" transform="translate(1 4)" d="M0 0h22v16H0z"/>
            </clipPath>
        </defs>
    </svg>);

<template>
    <div class="container-off-canvas-sm my-6">
        <SfButton v-if="$can('haveOrderAllDocuments') && order.allDocuments" class="mb-6 w-full" @click="downloadAll()">
            <template #trailing-icon><SfDownloadIcon size="xxs" theme="dark" /></template>
            {{ $t('pages.transport.transportViewV2.documentsTab.downloadAllDocuments') }}
        </SfButton>

        <DocumentBlock
            v-if="isDisposal"
            :class="computedCardClasses"
            :title="$t('pages.checkout.projectPositionDisposal.pages.analysis.label')"
            :documents="getFormattedAnalysisDocuments"
        >
            <template #empty>
                <p class="font-copy-md mb-6 text-subdued">
                    {{ $t('pages.transport.transportViewV2.documentsTab.emptyMessageAnalysisNotes') }}
                </p>
                <SfMessageBox type="info">
                    {{
                        $t('pages.transport.transportViewV2.documentsTab.emptyMessageAnalysisHint', {
                            pollutionType: firstLineItemGroup.productVariant.pollutionType.label,
                        })
                    }}
                </SfMessageBox>
            </template>
        </DocumentBlock>

        <DocumentBlock
            v-if="isDisposal"
            :class="computedCardClasses"
            :title="$t('pages.checkout.disposal.summary.tiles.analysis.otherDocuments')"
            :empty-message="$t('pages.transport.transportViewV2.documentsTab.emptyMessageAccompanyingDocuments')"
            :documents="getFormattedAccompanyingDocumentsAndImages"
        />

        <DocumentBlock
            v-can:haveOrderInvoices
            :class="computedCardClasses"
            :title="$t('pages.order.documentsTab.titleInvoice')"
            :empty-message="emptyInvoiceHint"
            :documents="invoiceDocuments"
        />
        <DocumentBlock
            v-if="referencedInvoiceDocuments"
            :class="computedCardClasses"
            :title="$t('pages.order.documentsTab.titleReferencedInvoices')"
            hide-empty-message
            :documents="referencedInvoiceDocuments"
        />

        <DocumentBlock
            :class="computedCardClasses"
            :title="$t('pages.order.documentsTab.titleDeliveryNotes')"
            :empty-message="$t('pages.order.documentsTab.emptyMessageDeliveryNotes')"
            :documents="deliveryNotesDocuments"
        />

        <DocumentBlock
            v-if="creditNotesDocuments"
            :class="computedCardClasses"
            :title="
                $t(
                    selectCreditNoteDocKey({
                        creditNote: `pages.order.documentsTab.titleCreditNoteDoc.creditNote`,
                        selfBilling: `pages.order.documentsTab.titleCreditNoteDoc.selfBilling`,
                    })
                )
            "
            :empty-message="
                $t(
                    selectCreditNoteDocKey({
                        creditNote: `pages.order.documentsTab.emptyMessageCreditNoteDoc.creditNote`,
                        selfBilling: `pages.order.documentsTab.emptyMessageCreditNoteDoc.selfBilling`,
                    })
                )
            "
            :documents="creditNotesDocuments"
        />

        <div
            v-if="firstLineItemGroup.freightImages || firstLineItemGroup.freightDocuments"
            :class="computedCardClasses"
        >
            <div class="font-copy-sm-strong mb-6">
                {{ $t('pages.order.documentsTab.titleFreightDocuments') }}
            </div>

            <DocumentsGalleryBlock
                :images="firstLineItemGroup.freightImages"
                :documents="firstLineItemGroup.freightDocuments"
            />
        </div>
    </div>
</template>

<script>
import _get from 'lodash/get';
import { markDocumentsAsSeenForOrder, isDocumentNewForOrder } from '@/services/utils/spectator';
import routeContext from '@/plugins/mixins/routeContext';
import { CONTEXT_CLIENT } from '@/constants/context';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
import { DISPOSAL_ANALYSIS_DOCUMENT_TYPE, isOrderLineItemGroupTypeDisposal } from '@/constants/disposal.ts';

import { SfMessageBox, SfButton, SfDownloadIcon } from '@schuettflix/vue-components';
import DocumentBlock from '@/components/DocumentBlock';
import DocumentsGalleryBlock from '@/pages/Checkout/components/DocumentsGalleryBlock';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';

export default {
    name: 'OrderDocumentsTab',

    components: {
        DocumentBlock,
        DocumentsGalleryBlock,
        SfMessageBox,
        SfButton,
        SfDownloadIcon,
    },

    mixins: [routeContext],

    props: {
        order: {
            type: Object,
            default: null,
        },
    },

    setup() {
        const { selectCreditNoteDocKey } = useLegalTerms();
        return {
            selectCreditNoteDocKey,
        };
    },

    data() {
        return {
            computedCardClasses: ['card', 'mb-6', 'last:mb-0'],
        };
    },

    computed: {
        isDisposal() {
            return isOrderLineItemGroupTypeDisposal(this.firstLineItemGroup.type);
        },
        firstLineItemGroup() {
            return _get(this.order, 'lineItemGroups[0]');
        },

        isPickup() {
            return this.firstLineItemGroup.type === ORDER_LINE_ITEM_GROUP_TYPE.PICKUP;
        },

        isDelivery() {
            return this.firstLineItemGroup.type === ORDER_LINE_ITEM_GROUP_TYPE.DELIVERY;
        },

        deliveryNotesDocuments() {
            const documents = [];

            this.order.lineItemGroups.forEach(lineItemGroup => {
                lineItemGroup.lineItems.forEach(lineItem => {
                    const document = lineItem.deliveryNoteDocument;
                    if (!document) return;

                    const labelKey = this.inRouteContext(CONTEXT_CLIENT)
                        ? 'pages.order.documentsTab.transportNumberClientLabel'
                        : 'pages.order.documentsTab.transportNumberLabel';

                    documents.push({
                        headline: this.$t(labelKey, {
                            number: lineItem.transportNumber,
                        }),
                        created: document.created,
                        name: document.fileName,
                        url: document.url,
                        isNew: this.isNewDocument(document.created),
                    });
                });
            });

            return documents.length !== 0 ? documents : null;
        },

        lineItemGroupDocuments() {
            return this.firstLineItemGroup.documents;
        },

        invoiceDocuments() {
            const invoiceDocument = this.order.invoiceDocument;
            if (invoiceDocument) {
                return [
                    {
                        headline: this.$t('pages.order.documentsTab.orderNumberLabel', { number: this.order.number }),
                        created: invoiceDocument.created,
                        name: invoiceDocument.fileName,
                        url: invoiceDocument.url,
                        isNew: this.isNewDocument(invoiceDocument.created),
                    },
                ];
            }

            return null;
        },

        emptyInvoiceHint() {
            return this.order.totalRetailPrice === 0
                ? this.$t('pages.order.documentsTab.retailPriceZero')
                : this.$t('pages.order.documentsTab.emptyMessageInvoice');
        },

        referencedInvoiceDocuments() {
            const documents = [];

            if (this.order.referenceInvoices && this.order.referenceInvoices.length) {
                this.order.referenceInvoices.forEach(referenceInvoice => {
                    documents.push({
                        headline: this.$t('pages.order.documentsTab.invoiceNumberLabel', {
                            number: referenceInvoice.number,
                        }),
                        created: referenceInvoice.document.created,
                        name: referenceInvoice.document.fileName,
                        url: referenceInvoice.document.url,
                        isNew: this.isNewDocument(referenceInvoice.document.created),
                    });
                });
            }

            return documents.length !== 0 ? documents : null;
        },

        creditNotesDocuments() {
            const documents = [];
            const uuids = [];

            if (this.order.referenceCreditNotes && this.order.referenceCreditNotes.length) {
                this.order.referenceCreditNotes.forEach(referenceCreditNote => {
                    uuids.push(referenceCreditNote.document.uuid);
                    documents.push({
                        headline: referenceCreditNote.billingAddressName,
                        note: this.$t(
                            `pages.order.documentsTab.creditNoteTypeLabels.${referenceCreditNote.organizationType}`
                        ),
                        created: referenceCreditNote.document.created,
                        name: referenceCreditNote.document.fileName,
                        url: referenceCreditNote.document.url,
                        isNew: this.isNewDocument(referenceCreditNote.date),
                    });
                });
            }

            if (!this.$can('havePlatformOrderManagement')) {
                return documents.length !== 0 ? documents : null;
            }

            this.order.lineItemGroups.forEach(lineItemGroup => {
                lineItemGroup.lineItems.forEach(lineItem => {
                    const carrierDocument = lineItem.carrierCreditNoteDocument;
                    if (carrierDocument && !uuids.includes(carrierDocument.uuid)) {
                        uuids.push(carrierDocument.uuid);
                        documents.push({
                            headline: lineItem.carrierName,
                            note: this.$t('pages.order.documentsTab.creditNoteTypeLabels.carrier'),
                            created: carrierDocument.created,
                            name: carrierDocument.fileName,
                            url: carrierDocument.url,
                            isNew: this.isNewDocument(carrierDocument.created),
                        });
                    }

                    const supplierDocument = lineItem.supplierCreditNoteDocument;
                    if (supplierDocument && !uuids.includes(supplierDocument.uuid)) {
                        uuids.push(supplierDocument.uuid);
                        documents.push({
                            headline: lineItemGroup.supplier.name,
                            note: this.$t('pages.order.documentsTab.creditNoteTypeLabels.supplier'),
                            created: supplierDocument.created,
                            name: supplierDocument.fileName,
                            url: supplierDocument.url,
                            isNew: this.isNewDocument(supplierDocument.created),
                        });
                    }
                });
            });

            return documents.length !== 0 ? documents : null;
        },
        getAnalysisDocumentsFromApi() {
            return this.lineItemGroupDocuments.filter(
                doc => doc.documentType.code === DISPOSAL_ANALYSIS_DOCUMENT_TYPE.DECLARATION
            );
        },
        getFormattedAnalysisDocuments() {
            const formattedDocuments = this.getFormattedDocuments(this.getAnalysisDocumentsFromApi);

            return formattedDocuments.length ? formattedDocuments : null;
        },
        getFormattedAccompanyingDocumentsAndImages() {
            const accompanyingDocumentsFromApi = this.lineItemGroupDocuments.filter(
                doc => doc.documentType.code === DISPOSAL_ANALYSIS_DOCUMENT_TYPE.ACCOMPANYING
            );
            const accompanyingImagesFromApi = this.firstLineItemGroup.accompanyingAnalysisImages;

            const docs = this.getFormattedDocuments(accompanyingDocumentsFromApi);
            const images = this.getFormattedImages(accompanyingImagesFromApi);
            if (docs.length === 0 && images.length === 0) {
                return null;
            }

            return [
                ...this.getFormattedDocuments(accompanyingDocumentsFromApi),
                ...this.getFormattedImages(accompanyingImagesFromApi),
            ];
        },
    },

    created() {
        setTimeout(() => {
            this.$set(this.order, '__hasNewDocuments', false);
            markDocumentsAsSeenForOrder(this.routeContext, this.order);
        }, 2000);
    },

    methods: {
        downloadAll() {
            window.location.href = this.order.allDocuments.url;
        },

        isNewDocument(timestamp) {
            if (this.order === null) return false;
            return isDocumentNewForOrder(this.order.id, timestamp);
        },
        getFormattedImages(images) {
            return images.map(img => ({
                thumbnailUrl: img.url.thumbnail,
                originalUrl: img.url.original,
                url: img.url.large,
                name: img.fileName.replace('png', 'image'), // kind of a hack since the type 'png' being splitted in DocumentBlock.vue is not recognizeable from the SfFile component
                type: 'image',
            }));
        },
        getFormattedDocuments(documents) {
            return documents.map(doc => ({
                created: doc.created,
                name: doc.fileName,
                url: doc.url,
                isNew: this.isNewDocument(doc.created),
            }));
        },
    },
};
</script>

<style lang="scss">
.order-documents-tab__download-card {
    background-color: $color-red;
}

.order-documents-tab__download-card-inner {
    display: grid;
    grid-template-columns: 48px 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 15px;
    color: $color-white;

    svg {
        .f-base,
        .f-highlight {
            fill: $color-white;
        }

        .s-base,
        .s-highlight {
            stroke: $color-white;
        }
    }
}

.order-documents-tab__download-card-info {
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
}
</style>

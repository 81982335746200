<template>
    <div v-if="value" class="product-attribute">
        <p class="font-copy-md-strong">{{ label }}</p>
        <div class="product-attribute__value">{{ value }}</div>
    </div>
</template>

<script>
export default {
    name: 'ProductAttribute',
    components: {},
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
.product-attribute {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    border-bottom: 1px solid #d2d4d5;

    &__value {
        color: #35343d;
    }
}
</style>

import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
export const useDocumentTypes = () => {
    const getDocumentTypeKey = (type) => {
        if (type !== 'credit_note') {
            return `pages.documents.type.${type}`;
        }
        const { selectCreditNoteDocKey } = useLegalTerms();
        return selectCreditNoteDocKey({
            creditNote: 'pages.documents.type.invoice_correction.creditNote',
            selfBilling: 'pages.documents.type.invoice_correction.selfBilling',
        });
    };
    return {
        getDocumentTypeKey,
    };
};

export const getEmptyStructure = data => {
    return {
        // Name and address
        name: '',
        address: {},
        location: {},
        importantInformation: null,
        supplierOrganizationId: null,

        // Contact data
        contact: {
            phone: { countryCode: '', number: '' },
            email: '',
            mobile: { countryCode: '', number: '' },
            fax: { countryCode: '', number: '' },
            website: '',
        },

        // Location Type
        usages: [],
        disposalSettings: {
            factoryType: '',
            landfillClass: null,
            specialHint: '',
        },
        disposalPrincipleDocument: {
            uuid: '',
        },
        disposerNumber: '',

        // Opening hours
        openingHours: [],

        // Factory Number
        factoryNumber: '',

        // Weighing Types and Methods
        weighingTransmissionType: '',
        disposalWeighingTransmissionType: '',
        disposalWeighingMethod: '',
        disposalWeighingCapture: '',

        // Vehicle Requirements
        vehicleRequirements: {},

        // Platform Settings
        platformSettings: {},

        ...data,
    };
};

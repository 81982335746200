import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Button, MessageBox } from '@schuettflix/react-components';
import { TransparentFlagIcon } from '@/constructionProjects/components/icons/TransparentFlagIcon';
import { constructionProjectLocationDraftQuery } from '../queries';
import { useAbility } from '@/reactBridge/useAbility';
import { CreateLocationMissingPermission } from './CreateLocationMissingPermission';
export const LocationEmptyState = ({ constructionProjectId, onCreateLocationClick, }) => {
    const { data: locationDraft } = useQuery({
        ...constructionProjectLocationDraftQuery(constructionProjectId),
        suspense: true,
    });
    const { t } = useTranslation();
    const canCreateConstructionProjectLocation = useAbility('createConstructionProject');
    if (!locationDraft) {
        return null;
    }
    if (!canCreateConstructionProjectLocation) {
        return <CreateLocationMissingPermission />;
    }
    return (<div className="rounded bg-surface p-4 shadow-low">
            <div className="flex items-center">
                <TransparentFlagIcon className="mt-2 h-6 w-6"/>
                <div className="ml-4 mt-2 flex-1">
                    <p className="font-copy-sm mt-1">
                        {[
            [locationDraft.street, locationDraft.addressNumber].filter(Boolean).join(' '),
            [locationDraft.zip, locationDraft.city].filter(Boolean).join(' '),
        ]
            .filter(Boolean)
            .join(', ')}
                    </p>
                    <p className="font-copy-sm text-subdued">
                        {t('pages.constructionProject.projectLocations.locationEmptyState.openLocationCode', {
            code: locationDraft.openLocationCode,
        })}
                    </p>
                </div>
            </div>
            <MessageBox className="mt-4">
                {t('pages.constructionProject.projectLocations.locationEmptyState.infoMessage')}
            </MessageBox>

            <Button className="mt-4 w-full rounded" size="sm" onClick={() => onCreateLocationClick(locationDraft)} label={t('pages.constructionProject.projectLocations.locationEmptyState.completeDraft')} data-test="location-empty-state-complete-button"/>
        </div>);
};

import { asVueComponent } from '@/reactBridge/asVueComponent';
import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useOrderScreenName } from '@/pages/Checkout/analytics/react/useOrderScreenName';
import { Flyout } from '@/constructionProjects/components/Flyout';
import { useTranslation } from 'react-i18next';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { findRouteName } from '@/pages/routerUtils';
import { RecentOrderedProducts } from '@/pages/Checkout/ProductSelection/RecentOrderedProducts';
import { useVuexGetter, useVuexState } from '@/reactBridge/useVuex';
import store from '@/store';
import { trackCheckoutEvent } from '@/pages/Checkout/trackCheckoutEvent';
import { ProductCategoriesList } from '@/pages/Checkout/ProductSelection/ProductCategoriesList';
import { SearchIcon } from '@schuettflix/icons-react';
import { RadioButtonSimple, TextField } from '@schuettflix/react-components';
import { ProductSearchResults, } from '@/pages/Checkout/ProductSearchResults';
import { useDebounce } from '@/hooks/useDebounce';
import { useQuery } from '@tanstack/react-query';
import { searchProducts } from '@/pages/Checkout/queries';
import { useLaunchDarkly } from '@/reactBridge/useLaunchDarkly';
const _ProductSelectionPage = () => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const isPlatformAdministrator = useVuexGetter(store, useCallback(getters => getters['user/isPlatformAdministrator'], []));
    const orderType = useVuexState(store, useCallback(state => state.basket.type, []));
    const locationId = useVuexState(store, useCallback(state => state.basket.constructionSite.constructionProjectLocationId, []));
    const isCustomOrder = useVuexState(store, useCallback(state => state.basket.isCustom, []));
    const savedSearchTerm = useVuexState(store, useCallback(state => state.basket.searchTerm, []));
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm || '');
    const [showSearchResults, setShowSearchResults] = useState(false);
    const debouncedSearchTerm = useDebounce(searchTerm, 1000);
    const [searchMode, setSearchMode] = useState('ALL');
    const isSearchModeToggleEnabled = useLaunchDarkly('product-search-searchmode-toggle');
    const { data: searchResponse, isLoading } = useQuery({
        ...searchProducts(orderType, locationId, debouncedSearchTerm, searchMode === 'ALL' ? undefined : searchMode === 'RECYCLED'),
        enabled: searchTerm !== '',
    });
    useEffect(() => {
        store.commit('basket/setCategoryInfo', null);
        setShowSearchResults(searchTerm !== '');
    }, []);
    useEffect(() => {
        store.commit('basket/setSearchTerm', searchTerm);
    }, [searchResponse]);
    const handleBackButton = () => {
        store.commit('basket/setSearchTerm', null);
        store.commit('basket/setSearchType', null);
        vueRouter.push({ name: findRouteName(vueRouter.currentRoute?.meta?.previous) }).catch(() => { });
    };
    const handleRecentProductSelection = (product) => {
        trackCheckoutEvent('product', 'productSelect', {
            id: product.sellerProductId,
            name: product.productName,
            sku: product.sku,
            tags: product.tags.join(','),
            price: product.price,
            recentOrder: true,
        });
        store.commit('analytics/setProductSelection', {
            selected_by: 'recent_order',
        });
        store.commit('basket/setProductDefinition', product);
        vueRouter
            .push({
            name: findRouteName(vueRouter.currentRoute?.meta?.next),
            query: { lastOrderedAt: product.lastOrderedAt.toString() },
        })
            .catch(() => { });
    };
    const selectSearchResult = (selected) => {
        store.commit('basket/setSearchType', selected.selectedType);
        switch (selected.selectedType) {
            case 'product':
                handleProductSelection(selected);
                break;
            case 'seller':
                handleSellerSelection(selected);
                break;
            case 'category':
                handleCategorySelection(selected);
                break;
            default:
                break;
        }
    };
    const handleProductSelection = (product) => {
        store.commit('analytics/setProductSelection', {
            master_product_id: product.masterProduct.id,
            product_name: product.name,
            search_term: debouncedSearchTerm,
            selected_by: 'search',
        });
        store.commit('basket/setProductDefinition', {
            masterProductId: product.masterProduct.id,
            attributeRange: product.attributeRange,
            attributeIds: product.attributes?.map(attribute => attribute.attributeValueId),
        });
        vueRouter.push({ name: findRouteName(vueRouter.currentRoute?.meta?.next) }).catch(() => { });
    };
    const handleSellerSelection = (seller) => {
        const isCustomDelivery = orderType === 'delivery' && isCustomOrder;
        const isProjectDelivery = orderType === 'project-delivery';
        store.commit('basket/setSupplierInfo', {
            supplier: {
                factoryId: seller?.id,
            },
        });
        isCustomDelivery || isProjectDelivery
            ? vueRouter.push({ name: findRouteName(vueRouter.currentRoute?.meta?.nextSellerProfile) }).catch(() => { })
            : vueRouter.push({ name: findRouteName(vueRouter.currentRoute?.meta?.next) }).catch(() => { });
    };
    const handleCategorySelection = (category) => {
        store.commit('basket/setCategoryInfo', {
            categoryId: category.id,
            categoryName: category.name,
            imageUrl: category.image.small,
            searchTerm: debouncedSearchTerm,
        });
        store.commit('analytics/setProductSelection', {
            selected_by: 'category',
        });
        trackCheckoutEvent('product', 'categorySelect', {
            id: category.id || 0,
            name: category.name,
        });
        vueRouter
            .push({
            name: findRouteName(vueRouter.currentRoute?.meta?.nextCategoryDetail),
            params: {
                searchTerm: debouncedSearchTerm,
            },
        })
            .catch(() => { });
    };
    const handleMoreCategoriesSelection = () => {
        vueRouter.push({ name: findRouteName(vueRouter.currentRoute?.meta?.nextCategoriesList) }).catch(() => { });
    };
    const updateSearchTerm = (term) => {
        setSearchTerm(term);
    };
    const hideSearchResults = () => {
        if (searchTerm !== '') {
            return;
        }
        setShowSearchResults(false);
    };
    return (<Flyout onClose={handleBackButton} show headerTitle={t(vueRouter.currentRoute?.meta?.title)} body={<div className={clsx('flex flex-col bg')}>
                    <div className="bg-surface p-4" onFocus={() => setShowSearchResults(true)} onBlur={() => hideSearchResults()}>
                        <TextField label={t('pages.checkout.productSelection.searchFieldLabel')} className="w-full" data-test="product-search-field" leadingIcon={props => <SearchIcon {...props}/>} value={searchTerm} onChange={event => updateSearchTerm(event.target.value)}/>
                        {isSearchModeToggleEnabled && showSearchResults && (<div className="mt-4 flex flex-row gap-8">
                                <RadioButtonSimple name="searchMode" inputValue="ALL" label={t('pages.checkout.productSelection.searchmode.all')} value={searchMode} onChange={value => setSearchMode(value)}/>
                                <RadioButtonSimple name="searchMode" inputValue="NATURAL" label={t('pages.checkout.productSelection.searchmode.natural')} value={searchMode} onChange={value => setSearchMode(value)}/>
                                <RadioButtonSimple name="searchMode" inputValue="RECYCLED" label={t('pages.checkout.productSelection.searchmode.recycled')} value={searchMode} onChange={value => setSearchMode(value)}/>
                            </div>)}
                    </div>

                    {showSearchResults && (<>
                            {debouncedSearchTerm !== '' && (<ProductSearchResults searchTerm={searchTerm} isLoading={isLoading} categories={searchResponse?.categories || []} products={searchResponse?.products || []} sellers={searchResponse?.sellers || []} onClick={selectSearchResult}/>)}
                            {debouncedSearchTerm === '' && (<main className="flex h-full flex-col items-center justify-center p-8">
                                    <p className="font-copy-lg-strong text-subdued">
                                        {isPlatformAdministrator
                        ? t('pages.checkout.productSelection.searchResults.prompt.headerPA')
                        : t('pages.checkout.productSelection.searchResults.prompt.header')}
                                    </p>
                                    <p className="font-copy-lg mt-2 text-center text-subdued">
                                        {isPlatformAdministrator
                        ? t('pages.checkout.productSelection.searchResults.prompt.textPA')
                        : t('pages.checkout.productSelection.searchResults.prompt.text')}
                                    </p>
                                </main>)}
                        </>)}

                    {!showSearchResults && (<>
                            {!isPlatformAdministrator && (<RecentOrderedProducts orderType={orderType} locationId={locationId} onSelect={handleRecentProductSelection}/>)}
                            {<ProductCategoriesList orderType={orderType} locationId={locationId} onSelect={handleCategorySelection} onMoreSelect={handleMoreCategoriesSelection}/>}
                        </>)}
                </div>} screenName={useOrderScreenName('client-orderprocess-pickup-supplierselection')}/>);
};
export const ProductSelectionPage = asVueComponent(_ProductSelectionPage);

<template>
    <div
        v-if="isOpen"
        :class="{
            'modal-box--open': isVisuallyOpen,
            'modal-box--has-border': border,
        }"
        class="modal-box"
    >
        <div class="modal-box__box">
            <div class="modal-box__head">
                <Button v-if="optionalDialog" class="modal-box__close-button" @click="closeModal">
                    <CloseIcon width="12" height="12" />
                </Button>
                <div v-if="headline" class="modal-box__headline">
                    <Words :large="hasLargeHeadline" bold>{{ headline }}</Words>
                </div>
            </div>
            <div class="modal-box__body scroll-container" :class="{ 'modal-box__body--spaceless': spaceless }">
                <slot />
            </div>
            <div v-if="$slots.actions" class="modal-box__actions">
                <slot name="actions" />
            </div>
            <div v-if="isLoading" class="modal-box__loading-backdrop">
                <LoadingSpinner block dark />
            </div>
        </div>
        <div class="modal-box__fade" @click="handleOptionalExit" />
    </div>
</template>

<script>
import Words from '@/components/Typography/Words';
import Button from '@/components/Button/Button';
import CloseIcon from '@/assets/icons/regular/close.svg';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useAnalyticsService } from '@schuettflix/analytics-vue';

const CLOSE_DELAY = 500;
const OPEN_DELAY = 100;

export default {
    name: 'ModalBox',
    components: {
        LoadingSpinner,
        Button,
        Words,
        CloseIcon,
    },
    props: {
        headline: {
            type: String,
            default: null,
        },
        hasLargeHeadline: {
            type: Boolean,
            default: false,
        },
        optionalDialog: {
            type: Boolean,
            default: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
        spaceless: {
            type: Boolean,
            default: false,
        },
        border: {
            type: Boolean,
            default: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        screenName: {
            type: String,
            default: null,
        },
    },
    setup() {
        const analyticsService = useAnalyticsService();
        return { analyticsService };
    },
    data() {
        return {
            isOpen: this.active,
            isVisuallyOpen: this.active,
            timeout: null,
        };
    },
    watch: {
        active(state) {
            // ensure proper state, force open and close based on state
            if (state === false && this.isVisuallyOpen !== false) {
                this.closeModal();
            }

            if (state === true && this.isOpen !== true) {
                this.openModal();
            }
        },
    },
    created() {
        this.$on('open', this.openModal);
        this.$on('close', this.closeModal);
    },
    methods: {
        openModal() {
            this.isOpen = true;

            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.isVisuallyOpen = true;
            }, OPEN_DELAY);
            this.$emit('opened');
            if (this.screenName) {
                this.analyticsService.enterScreen(this.screenName);
            }
        },
        closeModal() {
            this.isVisuallyOpen = false;

            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.isOpen = false;
                this.$emit('closed');
            }, CLOSE_DELAY);

            if (this.screenName) {
                this.analyticsService.leaveScreen(this.screenName);
            }
        },
        handleOptionalExit() {
            if (this.optionalDialog) {
                this.closeModal();
            }
        },
    },
};
</script>

<style lang="scss">
.modal-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.modal-box__loading-backdrop {
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-box--open {
    .modal-box__fade {
        opacity: 0.9;
    }

    .modal-box__box {
        opacity: 1;
    }
}

.modal-box__fade {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.4s ease;
    background-color: $color-darkGrey;
}

.modal-box__box {
    background-color: $color-white;
    position: fixed;
    z-index: 1;
    width: 90vw;
    max-width: 700px;
    max-height: 90vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease;
    opacity: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.modal-box__head {
    position: relative;
    padding: 20px;
    flex: 0 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.modal-box__body {
    position: relative;
    flex: 0 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.modal-box__body:not(.modal-box__body--spaceless) {
    padding: 0 20px 20px;
}

.modal-box__actions {
    flex: 0 0;
}

.modal-box--has-border .modal-box__actions {
    border-top: 1px solid $color-mediumGrey;
}

.modal-box__headline {
    padding-right: 30px;
}

.modal-box__close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
}
</style>

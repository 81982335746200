<template>
    <DialogModalBase class="help-special-hint">
        <template #content>
            <Words large line-height-large bold block spaced-bottom>{{
                $t('components.factoryManagement.factoryLocationType.modal.specialHint.title')
            }}</Words>
            <Words
                block
                line-height-small
                v-html="$t('components.factoryManagement.factoryLocationType.modal.specialHint.description')"
            />
        </template>
    </DialogModalBase>
</template>
<script>
import DialogModalBase from '@/_components/DialogModal/DialogModalBase';
import Words from '@/components/Typography/Words';

export default {
    name: 'HelpLocationType',
    components: {
        DialogModalBase,
        Words,
    },
};
</script>

<style lang="scss" scoped>
.help-special-hint {
    font-weight: initial;
}
</style>

<template>
    <div
        :class="{ 'message__bubble--right': bubbleRight, 'message__bubble--left-side': bubbleLeftSide }"
        class="message__bubble"
    >
        <div class="message__bubble-text">
            <Words v-if="title" block bold class="message__bubble-title">{{ title }}</Words>
            {{ message }}

            <BubbleSwooshRight
                v-if="bubbleRight"
                class="message__bubble-swoosh message__bubble-swoosh--right"
                width="15"
                height="12"
            />
            <BubbleSwooshLeft v-else class="message__bubble-swoosh" width="15" height="12" />
        </div>
    </div>
</template>

<script>
import Words from '@/components/Typography/Words';

import BubbleSwooshLeft from '@/assets/icons/bubble-swoosh-left.svg';
import BubbleSwooshRight from '@/assets/icons/bubble-swoosh-right.svg';

export default {
    name: 'Message',
    components: {
        Words,

        BubbleSwooshLeft,
        BubbleSwooshRight,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        message: {
            type: String,
            default: null,
        },
        bubbleRight: {
            type: Boolean,
            default: false,
        },
        bubbleLeftSide: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.message__bubble {
    display: flex;
    margin-top: 15px;
}

.message__bubble--right {
    justify-content: flex-end;
}

.message__bubble-text {
    padding: 10px 10px;
    background-color: $color-lightGrey;
    border: 1px solid $color-mediumGrey;
    border-radius: 5px;
    position: relative;
    width: 80%;
}

.message__bubble-swoosh {
    position: absolute;
    bottom: 100%;
    left: 10px;

    .message__bubble--left-side & {
        bottom: auto;
        left: -13px;
        top: 10px;
        transform: rotate(-95deg);
    }
}

.message__bubble-swoosh--right {
    left: auto;
    right: 10px;
}

.message__bubble-title {
    margin-bottom: 5px;
}
</style>

<template>
    <div>
        <VehicleDeleteAction />
    </div>
</template>

<script>
import VehicleDeleteAction from '@/pages/Vehicle/Actions/VehicleDeleteAction';

export default {
    name: 'VehicleActions',
    components: {
        VehicleDeleteAction,
    },
};
</script>

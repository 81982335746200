<template>
    <div
        class="autocomplete-field"
        :class="{ 'autocomplete-field--prediction-shown': isPredictionsShown && predictions.length }"
    >
        <TextField
            v-model="searchInput"
            class="autocomplete-field__input"
            :label="label"
            @blur="hidePredictions"
            @focus="showPredictions"
            @keydown="handleKeyInput"
        />
        <ul v-if="isPredictionsShown && predictions.length" class="autocomplete-field__suggestion-list">
            <li
                v-for="(prediction, index) in predictions"
                :key="index"
                class="autocomplete-field__suggestion"
                :class="{ 'autocomplete-field__suggestion--active': highlightedIndex === index }"
                @mouseover="highlightedIndex = index"
                @click="selectPrediction(prediction)"
            >
                <slot name="prediction" :prediction="prediction" :index="index" />
            </li>
        </ul>
    </div>
</template>

<script>
import _throttle from 'lodash/throttle';

import TextField from '@/components/Form/TextField.v2';

const UPDATE_SEARCH_INPUT_THROTTLE_MS = 1000;

export default {
    name: 'AutocompleteField',
    components: {
        TextField,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        predictions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            searchInput: '',
            highlightedIndex: null,
            isPredictionsShown: false,
            hidePredictionsTimeout: null,
        };
    },
    watch: {
        searchInput: _throttle(
            function (value, oldValue) {
                if (value === '') {
                    this.hidePredictions();
                } else if (value === oldValue || this.hidePredictionsTimeout !== null) {
                    this.hidePredictions();
                } else {
                    this.$emit('search', value);
                }
            },
            UPDATE_SEARCH_INPUT_THROTTLE_MS,
            { trailing: true }
        ),
        predictions(value) {
            if (value && value.length) {
                this.showPredictions();
            }
        },
    },
    methods: {
        hidePredictions() {
            this.hidePredictionsTimeout = setTimeout(() => {
                this.highlightedIndex = null;
                this.isPredictionsShown = false;
                this.hidePredictionsTimeout = null;
            }, 200);
        },
        showPredictions() {
            this.isPredictionsShown = true;
            this.hidePredictionsTimeout && clearTimeout(this.hidePredictionsTimeout);
        },
        selectPrediction(value) {
            this.$emit('select', value);
            this.highlightedIndex = null;
            this.isPredictionsShown = false;
        },
        handleKeyInput(e) {
            if (this.predictions.length === 0) return;

            switch (e.key) {
                case 'Enter':
                    this.selectHighlightedPrediction();
                    break;
                case 'Escape':
                    e.preventDefault();
                    this.$emit('clear');
                    break;
                case 'ArrowDown':
                    this.highlightNextPrediction(e);
                    break;
                case 'ArrowUp':
                    this.highlightPreviousPrediction(e);
                    break;
                default:
                    break;
            }
        },
        selectHighlightedPrediction() {
            this.$emit('select', this.predictions[this.highlightedIndex]);
        },
        highlightNextPrediction(e) {
            e && e.preventDefault();
            const length = this.predictions.length;
            const index = this.highlightedIndex !== null ? this.highlightedIndex : -1;
            this.highlightedIndex = index + 1 >= length ? 0 : index + 1;
        },
        highlightPreviousPrediction(e) {
            e && e.preventDefault();
            const length = this.predictions.length;
            const index = this.highlightedIndex !== null ? this.highlightedIndex : -1;
            this.highlightedIndex = index - 1 < 0 ? length - 1 : index - 1;
        },
    },
};
</script>

<style lang="scss">
.autocomplete-field {
    position: relative;
}

.autocomplete-field--prediction-shown {
    .text-field__input-group {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.autocomplete-field__suggestion-list {
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    width: 100%;
    z-index: 200;
    background-color: #fff;
    list-style: none;
    padding: 0;
    margin: 0;
    box-shadow: $boxShadow-bottom;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 1);
    border-top: 0;
    overflow: hidden;
}

.autocomplete-field__suggestion {
    padding: 15px 10px;
}

.autocomplete-field__suggestion--active {
    background-color: $color-lightGrey;
}
</style>

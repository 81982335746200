<template>
    <div>
        <OrganizationDeleteAction />
    </div>
</template>

<script>
import OrganizationDeleteAction from './OrganizationDeleteAction';
export default {
    name: 'OrganizationActions',
    components: {
        OrganizationDeleteAction,
    },
};
</script>

import _cloneDeep from 'lodash/cloneDeep';

export default {
    props: {
        sectionReset: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            sectionDataBackup: null,
        };
    },
    mounted() {
        this.createSectionDataBackup();
    },
    watch: {
        sectionReset: {
            deep: true,
            handler() {
                if (this.sectionDataBackup) {
                    this.resetSectionData();
                }
            },
        },
    },
    methods: {
        createSectionDataBackup() {
            this.sectionDataBackup = _cloneDeep(this.form);
        },
        resetSectionData() {
            this.$emit('input', this.cloneData(this.sectionDataBackup));
        },
        sectionDataBackupOpenStatusReset(openCloseStatus) {
            if (this.isDraft) {
                return;
            }
            this.sectionDataBackup.open = openCloseStatus;
        },
        cloneData(data) {
            return _cloneDeep(data);
        },
    },
};

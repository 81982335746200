<template>
    <div
        :class="{
            'form-error-message--dark': dark,
            'form-error-message--small': isSmall,
        }"
        class="form-error-message"
    >
        <StopIcon v-if="!isSmall" width="12" class="icon--inline form-error-message__icon" />
        <span class="form-error-message__message">
            {{ message }}
        </span>
    </div>
</template>

<script>
import StopIcon from '@/assets/icons/micro/stop.svg';

export default {
    name: 'ErrorMessage',
    components: {
        StopIcon,
    },
    props: {
        message: {
            type: String,
            required: true,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        isSmall: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.form-error-message {
    position: relative;
    padding: 6px 17px;
    background-color: $sflx-red-800;
    color: $color-white;
    margin-top: 7px;

    svg {
        .f-base {
            fill: $color-white;
        }
        .s-base {
            stroke: $color-white;
        }
    }

    &--small {
        font-size: 12px;
        padding: 4px 4px;
        margin-top: 4px;

        .form-error-message__message {
            padding-left: 4px;
        }
    }
}

.form-error-message__icon {
    position: absolute;
    left: 17px;
    top: 8px;
}

.form-error-message__message {
    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: initial;
    display: inline-block;
    vertical-align: middle;
    padding-left: 17px;
    width: 100%;
}

.form-error-message--dark {
    background-color: $color-white;
    color: $color-red;

    svg {
        .f-base {
            fill: $color-red;
        }
        .s-base {
            stroke: $color-red;
        }
    }
}
</style>

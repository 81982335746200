<template>
    <LayoutPage is-flyout :screen-name="screenName" class="checkout-shipment-loading-page">
        <Header slot="flyoutHeader" />

        <Tile transparent>
            <StepIndicator step="loading" />
        </Tile>

        <Tile transparent>
            <ShipmentAddressPicker
                ref="addressPicker"
                :address="address"
                :error="addressFormError"
                :location="location"
                type="loading"
                @update-address="handleAddressUpdate($event)"
                @update-location="handleLocationUpdate($event)"
                @update-contact="handleContactPersonUpdate($event)"
                @error-message="addressFormError = $event"
            >
                <span slot="headline">{{ $t('pages.checkout.shipmentLoading.address.title') }}</span>
                <span slot="subline">{{ $t('pages.checkout.shipmentLoading.address.description') }}</span>
                <span slot="lastUsedAddressHeadline">
                    {{ $t('pages.checkout.shipmentLoading.address.lastUsedHeadline') }}
                </span>
            </ShipmentAddressPicker>
        </Tile>

        <template v-if="showInfoStep">
            <Tile transparent>
                <ShipmentContact
                    v-model="contact"
                    :headline="$t('pages.checkout.shipmentLoading.contact.title')"
                    :description="$t('pages.checkout.shipmentLoading.contact.description')"
                    :modal-headline="$t('pages.checkout.shipmentLoading.contact.modalTitle')"
                    :modal-description="$t('pages.checkout.shipmentLoading.contact.modalDescription')"
                />
            </Tile>

            <Tile transparent>
                <ShipmentDescription
                    v-model="description"
                    :headline="$t('pages.checkout.shipmentLoading.additionalInformation.hintHeader')"
                    :message="$t('pages.checkout.shipmentLoading.additionalInformation.hintMessage')"
                />
            </Tile>

            <Tile transparent>
                <ShipmentLocation
                    v-model="loadingLocation"
                    :default-location="location"
                    is-loading
                    :headline="$t('pages.checkout.shipmentLoading.additionalInformation.selectLocation')"
                />
            </Tile>

            <Tile transparent>
                <ShipmentImageUpload
                    v-model="photo"
                    :headline="$t('pages.checkout.shipmentLoading.additionalInformation.selectPhotoHeader')"
                />
            </Tile>
        </template>

        <SlideUp slot="sticky" :active="hasValidData">
            <ButtonGroup>
                <Button primary data-test="button-choose-unloading" @click="handleSubmission">
                    {{ $t('pages.checkout.shipmentLoading.actions.nextUnloading') }}
                    <ArrowRightIcon slot="right" class="icon--inline" />
                </Button>
            </ButtonGroup>
        </SlideUp>
    </LayoutPage>
</template>

<script>
import { retrieveZipFromLocation } from '@/services/utils/map';

import LayoutPage from '@/components/Layout/Page.v2';
import Header from './components/Header';
import Tile from '@/components/Layout/Tile';
import ShipmentAddressPicker from './components/ShipmentAddressPicker';
import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ShipmentContact from './components/ShipmentContact';
import ShipmentLocation from './components/ShipmentLocation';
import ShipmentImageUpload from './components/ShipmentImageUpload';
import ShipmentDescription from './components/ShipmentDescription';
import SlideUp from '@/components/Animation/SlideUp';
import StepIndicator from './components/StepIndicator';

import ArrowRightIcon from '@/assets/icons/micro/arrow.svg';
import LocationApi from '@/services/Api/Location';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';

export default {
    name: 'ShipmentLoadingPage',
    components: {
        LayoutPage,
        Header,
        Tile,
        Button,
        ButtonGroup,
        ShipmentAddressPicker,
        ShipmentContact,
        ShipmentLocation,
        ShipmentImageUpload,
        ShipmentDescription,
        SlideUp,
        StepIndicator,

        ArrowRightIcon,
    },
    setup() {
        return {
            screenName: useOrderScreenName('loading'),
        };
    },
    data() {
        return {
            isPending: false,
            address: null,
            location: null,
            addressFormError: null,

            photo: null,
            description: null,
            loadingLocation: null,
            contact: null,

            loadingImportantInformation: null,
            costCenter: null,
            loadingAddressConstructionSiteId: null,
        };
    },
    computed: {
        hasValidData() {
            return !!(this.address && this.address.zip && this.location && !this.addressFormError);
        },

        showInfoStep() {
            return !!(this.address && this.location);
        },
    },
    created() {
        const savedLoading = this.$store.state.basket.shipmentLoading;

        if (savedLoading) {
            Object.keys(savedLoading).forEach(key => {
                this.$set(this, key, savedLoading[key]);
            });
        }
    },
    methods: {
        async handleLocationUpdate(value) {
            this.location = value;
            try {
                const openLocationCode = await LocationApi.getOpenLocationCode(value);
                if (openLocationCode) {
                    this.location.openLocationCode = openLocationCode;
                }
            } catch (err) {
                this.$logger().error(err);
            }
        },

        async handleAddressUpdate(value) {
            if (value && !value.zip) {
                value.zip = await retrieveZipFromLocation(this.location);
            }

            this.address = value;
        },

        handleContactPersonUpdate(person) {
            if (!person) {
                this.contact = null;
                return;
            }

            const contact = {};

            if (person.name || person.firstName || person.lastName) {
                contact.name = person.name ? person.name : `${person.firstName} ${person.lastName}`;
            }

            if (person.phone && person.phone.countryCode && person.phone.number) {
                contact.phone = person.phone;
            }

            if (person.mobile && person.mobile.countryCode && person.mobile.number) {
                contact.phone = person.mobile;
            }

            // Valid contact has name & phone
            this.contact = Object.keys(contact).length === 2 ? contact : null;
        },

        handleSubmission() {
            const { importantInformation, costCenter, ...address } = this.address;

            if (this.address.type === 'constructionSite') {
                this.loadingAddressConstructionSiteId = this.address.id;
            }

            this.$store.commit('basket/setShipmentLoading', {
                address,
                location: this.location,
                loadingLocation: this.loadingLocation,
                loadingImportantInformation: importantInformation,
                description: this.description,
                photo: this.photo,
                contact: this.contact,
                loadingAddressConstructionSiteId: this.loadingAddressConstructionSiteId,
                costCenter,
            });

            this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(err => {
                this.$logger().error(err);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.tf-additional-address {
    width: 100%;
    margin-top: 15px;
}
</style>

<template>
    <div class="progress-bar__wrapper">
        <div v-if="showTitle || showProgressPercentage" class="progress-bar__title">
            <div v-if="showTitle" data-test="bar-title">{{ title }}</div>
            <div v-if="showProgressPercentage" data-test="progress-display">{{ progressDisplay }}</div>
        </div>
        <div
            :class="{
                'progress-bar--small': isSmall,
            }"
            class="progress-bar"
        >
            <div
                class="progress-bar__beam"
                :class="{ 'progress-bar__beam--green': isGreen }"
                :style="`width:${progress}%`"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        value: {
            type: Number,
            default: 0,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
        isSmall: {
            type: Boolean,
            default: false,
        },
        isGreen: {
            type: Boolean,
            default: false,
        },
        showProgressPercentage: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
    },
    computed: {
        /**
         * Progress in percent (between 0 and 100)
         * @return {number}
         */
        progress() {
            const value = ((this.value - this.min) / (this.max - this.min)) * 100;
            return Math.min(Math.max(value, 0), 100);
        },
        /**
         * Determine if the title should be shown
         * @return {boolean}
         */
        showTitle() {
            return this.title !== '';
        },
        /**
         * Output the string of progress
         * @return {string}
         */
        progressDisplay() {
            return `${this.progress}%`;
        },
    },
};
</script>

<style lang="scss" scoped>
.progress-bar {
    height: 12px;
    background-color: $color-littleDarkerThanMediumGrey;
    border-radius: 3px;
    overflow: hidden;

    &__title {
        font-size: 10px;
        font-weight: 700;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__wrapper {
        width: 100%;
    }
}

.progress-bar--small {
    border-radius: 4px;
    height: 4px;
    background-color: #f2f2f2;
}

.progress-bar__beam {
    height: 100%;
    background: $color-red;
    background: linear-gradient(to right, rgba(255, 0, 0, 1) 0%, rgb(160, 0, 0) 100%);

    &--green {
        background: #619b52;
        background: linear-gradient(90deg, #619b52 0%, #a0cd40 100%);
    }
}
</style>

<template>
    <DialogModalBase>
        <template #bannerImage>
            <Banner />
        </template>
        <template #header>
            {{ $t('components.dialogModal.draftCreation.headerText') }}
        </template>
        <template #content>
            <Words line-height-large large bold block spaced-bottom>{{
                $t('components.dialogModal.draftCreation.headline')
            }}</Words>
            <Words
                line-height-small
                block
                :spaced-bottom-large="$root.isDesktop"
                v-html="$t('components.dialogModal.draftCreation.message')"
            />
        </template>
        <template #footer>
            <div class="flex flex-col justify-end md:flex-row">
                <SfButton
                    type="secondary"
                    :size="`${$root.isDesktop ? 'sm' : 'md'}`"
                    :class="{ 'border-t border-light-gray-400': !$root.isDesktop }"
                    data-test="later-button"
                    @click="$emit('secondaryClick')"
                >
                    {{ $t('components.dialogModal.draftCreation.secondaryButtonText') }}
                </SfButton>
                <SfButton
                    :size="`${$root.isDesktop ? 'sm' : 'md'}`"
                    :class="{ 'ml-4': $root.isDesktop }"
                    data-test="complete-location-button"
                    @click="$emit('primaryClick')"
                >
                    {{ $t('components.dialogModal.draftCreation.primaryButtonText') }}
                </SfButton>
            </div>
        </template>
    </DialogModalBase>
</template>
<script>
import Banner from '@/assets/banners/success-banner.svg';
import { SfButton } from '@schuettflix/vue-components';
import DialogModalBase from '@/_components/DialogModal/DialogModalBase';
import Words from '@/components/Typography/Words';

export default {
    name: 'DraftCreation',
    components: {
        Banner,
        SfButton,
        DialogModalBase,
        Words,
    },
};
</script>

<template>
    <GridRow class="organization-form__fieldset" :count="6">
        <Words block bold small class="span-6">
            {{ $t('components.organizationForm.contact.title') }}
        </Words>

        <PhoneFieldset
            :value="organization.phone"
            :readonly="readonly"
            :label="$t('components.organizationForm.contact.phone')"
            :prefix-error="prefixErrors.phoneCountryCode"
            :error="contactFormErrors.phone"
            :class="$root.isDesktop ? 'span-3' : 'span-6'"
            data-test="organization-contact-details-phone"
            @input="phone => updateOrganization('phone', phone)"
        />

        <PhoneFieldset
            :value="organization.mobile"
            :readonly="readonly"
            :label="$t('components.organizationForm.contact.mobile')"
            :prefix-error="prefixErrors.mobileCountryCode"
            :error="contactFormErrors.mobile"
            :class="$root.isDesktop ? 'span-3' : 'span-6'"
            data-test="organization-contact-details-mobile"
            @input="mobile => updateOrganization('mobile', mobile)"
        />

        <PhoneFieldset
            :value="organization.fax"
            :readonly="readonly"
            :label="$t('components.organizationForm.contact.fax')"
            :prefix-error="prefixErrors.faxCountryCode"
            :error="contactFormErrors.fax"
            :class="$root.isDesktop ? 'span-3' : 'span-6'"
            data-test="organization-contact-details-fax"
            @input="fax => updateOrganization('fax', fax)"
        />

        <TextField
            :value="organization.email"
            :readonly="readonly"
            :error="contactFormErrors.email"
            :label="$t('components.organizationForm.contact.email')"
            class="span-6"
            data-test="organization-contact-details-email"
            @input="email => updateOrganization('email', email.trim())"
        />
        <TextField
            :value="organization.web"
            :readonly="readonly"
            :label="$t('components.organizationForm.contact.website')"
            class="span-6"
            data-test="organization-contact-details-website"
            @input="web => updateOrganization('web', web)"
        />
    </GridRow>
</template>

<script>
import GridRow from '@/components/Layout/GridRow';
import Words from '@/components/Typography/Words';
import PhoneFieldset from '@/components/Form/PhoneFieldset';
import validate from '@/services/validation/mixin';
import TextField from '@/components/Form/TextField.v2';

export default {
    name: 'TabContact',

    components: {
        GridRow,
        TextField,
        Words,
        PhoneFieldset,
    },

    mixins: [validate],

    props: {
        organization: {
            type: Object,
            default: () => ({}),
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        contactFormErrors: {
            type: Object,
            default: () => ({
                phone: false,
                mobile: false,
                fax: false,
                email: false,
            }),
        },
        prefixErrors: {
            type: Object,
            default: () => ({
                faxCountryCode: false,
                mobileCountryCode: false,
                phoneCountryCode: false,
            }),
        },
    },

    setup(props, { emit }) {
        const updateOrganization = (key, value) => {
            emit('update:organization', {
                ...props.organization,
                [key]: value,
            });
        };

        return {
            updateOrganization,
        };
    },
};
</script>

<style lang="scss" scoped>
.organization-form__fieldset {
    & + & {
        margin-top: 60px;
    }
}
</style>

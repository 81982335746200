<template>
    <div
        :class="{
            'datetime-picker--active': isVisuallyOpen,
            'datetime-picker--invalid': isInvalid,
            'datetime-picker--disabled': disabled,
            'datetime-picker--desktop': $root.isDesktop,
        }"
        class="datetime-picker"
    >
        <div class="datetime-picker__trigger" @click="open()">
            <slot :is-invalid="isInvalid">Please provide a default</slot>
        </div>
        <template v-if="isActive">
            <div class="datetime-picker__backdrop" @click="closeByBackdrop" />
            <div class="datetime-picker__body" @click="closeByBackdrop">
                <div class="datetime-picker__content" @click="preventContentClicks">
                    <div
                        v-scrollable
                        :class="{
                            'datetime-picker__inner--no-footer': !$slots.bottom,
                        }"
                        class="datetime-picker__inner scroll-container"
                    >
                        <div v-if="$root.isDesktop" class="datetime-picker__desktop-wrapper">
                            <Tile no-border background-transparent>
                                <Words spaced-bottom block bold>{{ $t('components.datetimePicker.timeLabel') }}</Words>
                                <div class="datetime-picker__time-fallback">
                                    <SelectBox
                                        v-model.number="activeHourStart"
                                        :label="$t('components.datetimePicker.hourLabel')"
                                        data-test="time-picker-hour"
                                    >
                                        <option
                                            v-for="(hour, i) in validHoursStart"
                                            :key="i"
                                            :value="hour"
                                            :selected="activeHourStart === hour"
                                        >
                                            {{ formatTimeDigit(hour) }}
                                        </option>
                                    </SelectBox>
                                    <SelectBox
                                        v-model.number="activeMinuteStart"
                                        :label="$t('components.datetimePicker.minuteLabel')"
                                        data-test="time-picker-minute"
                                    >
                                        <option
                                            v-for="(minute, j) in validMinutesStart"
                                            :key="j"
                                            :value="minute"
                                            :selected="activeMinuteStart === minute"
                                        >
                                            {{ formatTimeDigit(minute) }}
                                        </option>
                                    </SelectBox>
                                </div>
                            </Tile>
                        </div>
                        <div v-else class="datetime-picker__wrapper">
                            <div class="datetime-picker__time-tracks">
                                <div
                                    :id="getTrackId('hourStart')"
                                    :class="{ 'datetime-picker__track--disabled': isScrollDisabled }"
                                    class="datetime-picker__track"
                                    @touchstart="handleTouchStart"
                                    @scroll="handleScroll('hourStart', $event)"
                                    @touchend="handleTouchEnd"
                                >
                                    <div class="datetime-picker__track-placeholder" />
                                    <div v-for="(hour, i) in validHoursStart" :key="i" class="datetime-picker__item">
                                        <span class="datetime-picker__item-text">
                                            {{ formatTimeDigit(hour) }}
                                        </span>
                                    </div>
                                    <div class="datetime-picker__track-placeholder" />
                                </div>
                                :
                                <div
                                    :id="getTrackId('minuteStart')"
                                    :class="{ 'datetime-picker__track--disabled': isScrollDisabled }"
                                    class="datetime-picker__track"
                                    @touchstart="handleTouchStart"
                                    @scroll="handleScroll('minuteStart', $event)"
                                    @touchend="handleTouchEnd"
                                >
                                    <div class="datetime-picker__track-placeholder" />
                                    <div
                                        v-for="(minute, i) in validMinutesStart"
                                        :key="i"
                                        class="datetime-picker__item"
                                    >
                                        <span class="datetime-picker__item-text">
                                            {{ formatTimeDigit(minute) }}
                                        </span>
                                    </div>
                                    <div class="datetime-picker__track-placeholder" />
                                </div>
                            </div>

                            <div v-if="isTypeRange" class="datetime-picker__time-tracks">
                                <div
                                    :id="getTrackId('hourEnd')"
                                    :class="{ 'datetime-picker__track--disabled': isScrollDisabled }"
                                    class="datetime-picker__track"
                                    @touchstart="handleTouchStart"
                                    @scroll="handleScroll('hourEnd', $event)"
                                    @touchend="handleTouchEnd"
                                >
                                    <div class="datetime-picker__track-placeholder" />
                                    <div v-for="(hour, i) in validHoursEnd" :key="i" class="datetime-picker__item">
                                        <span class="datetime-picker__item-text">
                                            {{ formatTimeDigit(hour) }}
                                        </span>
                                    </div>
                                    <div class="datetime-picker__track-placeholder" />
                                </div>
                                :
                                <div
                                    :id="getTrackId('minuteEnd')"
                                    :class="{ 'datetime-picker__track--disabled': isScrollDisabled }"
                                    class="datetime-picker__track"
                                    @touchstart="handleTouchStart"
                                    @scroll="handleScroll('minuteEnd', $event)"
                                    @touchend="handleTouchEnd"
                                >
                                    <div class="datetime-picker__track-placeholder" />
                                    <div v-for="(minute, i) in validMinutesEnd" :key="i" class="datetime-picker__item">
                                        <span class="datetime-picker__item-text">
                                            {{ formatTimeDigit(minute) }}
                                        </span>
                                    </div>
                                    <div class="datetime-picker__track-placeholder" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="datetime-picker__footer">
                        <ButtonGroup>
                            <BaseButton v-if="enableReset" primary light place-left @click="clear">
                                {{ $t('components.datetimePicker.clear') }}
                            </BaseButton>
                            <BaseButton primary data-test="time-picker-submit-button" @click="submit">
                                {{ $t('components.datetimePicker.submit') }}
                            </BaseButton>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import _sortBy from 'lodash/sortBy';
import _padStart from 'lodash/padStart';
import { ensureJSTimestamp } from '@/services/utils/date';
import {
    addDays,
    subDays,
    startOfDay,
    endOfDay,
    format,
    getDay,
    getHours,
    getMinutes,
    addHours,
    addMinutes,
    differenceInDays,
    differenceInMinutes,
    isWithinRange,
    isSameDay,
    setMilliseconds,
    setSeconds,
    setMinutes,
    setHours,
} from 'date-fns';
import ResizeObserver from 'resize-observer-polyfill';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Words from '@/components/Typography/Words';
import Tile from '@/components/Layout/Tile';
import SelectBox from '@/components/Form/SelectBox.v2';

const CLOSE_DELAY = 500;
const OPEN_DELAY = 250;
const PLACEHOLDER_COUNT = 1;
const INTERVAL_MINUTES = 5;
const DEFAULT_DAYS_BACK = 30;
const DEFAULT_DAYS_FORWARD = 60;

const take = (value, fallback = null) => {
    return value ? value : fallback;
};

// TODO: TimePicker
// - handle one value
// - handle start, end
// - handle custom labels for one, start/end
// - handle start - end relationship
// - edgecases:
//   - range, max Start time - min step minutes, ensure that it's possible to select the second one

export default {
    name: 'TimePicker',
    components: {
        BaseButton,
        ButtonGroup,
        SelectBox,
        Words,
        Tile,
    },
    props: {
        type: {
            type: String,
            default: 'single',
            validator: v => ['single', 'range'].includes(v),
        },
        active: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Number, Date, String, Object],
            default: null,
        },
        min: {
            type: [Number, Date],
            default: null,
        },
        max: {
            type: [Number, Date],
            default: null,
        },
        minuteSteps: {
            type: Number,
            default: INTERVAL_MINUTES,
        },
        enableReset: {
            type: Boolean,
            default: false,
        },
        returnTimestamp: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            eid: `el${this._uid}`,
            isActive: this.active,
            isVisuallyOpen: false,

            now: new Date(),
            activeHourStart: null,
            activeMinuteStart: null,
            activeHourEnd: null,
            activeMinuteEnd: null,
            isScrollDisabled: false,
        };
    },
    computed: {
        // Minmimum date which can be selected
        minTimestamp() {
            // just pick start of this day
            if (!this.min || !isFinite(this.min)) {
                return startOfDay(Date.now());
            }

            // min and max must be on same day
            if (this.max && !isSameDay(this.ensureDate(this.min), this.ensureDate(this.max))) {
                return startOfDay(Date.now());
            }

            return this.ensureDate(this.min) * 1;
        },

        // Maximum date which can be selected
        maxTimestamp() {
            // just pick end of this day
            if (!this.max || !isFinite(this.max)) {
                return endOfDay(Date.now());
            }

            // min and max must be on same day
            if (this.min && !isSameDay(this.ensureDate(this.min), this.ensureDate(this.max))) {
                return endOfDay(Date.now());
            }

            return this.ensureDate(this.max) * 1;
        },

        isTypeRange() {
            return this.type === 'range';
        },

        validHoursStart() {
            const hours = [];
            const maxHours = getHours(this.maxTimestamp);
            const minHours = getHours(this.minTimestamp);
            const minMinutes = getMinutes(this.minTimestamp);

            // requires one possible combination of start/end for range type
            let last;
            if (minHours >= maxHours) {
                last = maxHours;
            } else {
                last = this.isTypeRange ? maxHours - 1 : maxHours;
            }

            for (let i = minHours; i <= last; i++) {
                if (minHours === i && minMinutes + this.minuteSteps >= 60) {
                    continue;
                }

                hours.push(i);
            }

            return hours;
        },

        validHoursEnd() {
            const hours = [];
            const minHours = Math.max(getHours(this.minTimestamp), this.activeHourStart);
            const last = getHours(this.maxTimestamp);

            for (let i = minHours; i <= last; i++) {
                if (this.activeHourStart === i && this.activeMinuteStart + this.minuteSteps >= 60) {
                    continue;
                }

                hours.push(i);
            }

            return hours;
        },

        validMinutesStart() {
            const minutes = [];
            const activeHour = this.activeHourStart || getHours(this.minTimestamp);

            for (let i = 0; i < 60; i += this.minuteSteps) {
                const nextDate = addMinutes(addHours(startOfDay(this.minTimestamp), activeHour), i);

                if (isWithinRange(nextDate, this.minTimestamp, this.maxTimestamp)) {
                    minutes.push(i);
                }
            }

            return minutes;
        },

        validMinutesEnd() {
            const minutes = [];
            const activeHour = this.activeHourEnd || getHours(this.minTimestamp);

            for (let i = 0; i < 60; i += this.minuteSteps) {
                const nextDate = addMinutes(addHours(startOfDay(this.minTimestamp), activeHour), i);

                if (isWithinRange(nextDate, this.minTimestamp, this.maxTimestamp)) {
                    if (this.activeHourStart === this.activeHourEnd && this.activeMinuteStart >= i) {
                        continue;
                    }

                    minutes.push(i);
                }
            }

            return minutes;
        },

        isInvalid() {
            // Check if value is present
            if (this.value === null) {
                return false;
            }

            if (this.isTypeRange) {
                if (!this.value.start || !this.value.end) {
                    return true;
                }

                const start =
                    typeof this.value.start === 'string'
                        ? this.convertTime(this.value.start)
                        : this.ensureDate(this.value.start);

                const end =
                    typeof this.value.end === 'string'
                        ? this.convertTime(this.value.end)
                        : this.ensureDate(this.value.end);

                if (start.getMinutes() % this.minuteSteps !== 0 || end.getMinutes() % this.minuteSteps !== 0) {
                    return true;
                }

                return !(
                    isWithinRange(start, this.minTimestamp, this.maxTimestamp) &&
                    isWithinRange(end, this.minTimestamp, this.maxTimestamp)
                );
            }

            const value = typeof this.value === 'string' ? this.convertTime(this.value) : this.ensureDate(this.value);

            if (value.getMinutes() % this.minuteSteps !== 0) {
                return true;
            }

            return !isWithinRange(value, this.minTimestamp, this.maxTimestamp);
        },
    },
    watch: {
        active(state) {
            this.changeState(state);
        },
        activeHourStart() {
            this.resetValidValues();
        },
        activeHourEnd() {
            this.resetValidValues();
        },

        // NOTE: workaround: Wrong preselection on activeHourEnd + 1 jump
        // NOTE: sideffect: Snapps scrolling on active* values
        validHoursEnd(value, oldValue) {
            if (value !== oldValue) {
                this.updateTimeSelection(
                    this.activeHourStart,
                    this.activeMinuteStart,
                    this.activeHourEnd,
                    this.activeMinuteEnd
                );
            }
        },
    },
    beforeDestroy() {
        this.resizeObserver && this.resizeObserver.disconnect();
    },
    mounted() {
        this.isVisuallyOpen && this.onVisuallyOpen();

        this.resizeObserver = new ResizeObserver(() => {
            this.isVisuallyOpen && this.onVisuallyOpen();
        });
        this.resizeObserver.observe(this.$el);

        this.active ? this.open(false) : this.close(false);
    },
    methods: {
        getRangeBestMatch(targetDate = null) {
            targetDate = targetDate === null ? new Date() : this.ensureDate(targetDate);

            const found = this.rangeConfig.find(o => {
                return isWithinRange(targetDate, o.start, o.end);
            });

            if (found) return targetDate;

            let bestMatch = null;
            let bestScore = -Infinity;
            const rangeConfig = _sortBy(this.rangeConfig, ['start']);

            for (let i = 0, l = rangeConfig.length; i < l; i++) {
                const curr = rangeConfig[i];

                const score = differenceInMinutes(curr.start, targetDate);

                if (score > bestScore) {
                    bestScore = score;
                    bestMatch = curr.start;
                }

                if (score > 0) {
                    break;
                }
            }

            return bestMatch;
        },

        convertTime(value) {
            if (!value) {
                return null;
            }

            if (typeof value === 'number') {
                return new Date(ensureJSTimestamp(value));
            }

            const time = value.split(':').map(v => parseInt(v));
            let result = new Date();
            result = setMilliseconds(result, 0);
            result = setSeconds(result, 0);
            result = setMinutes(result, time[1]);
            result = setHours(result, time[0]);

            return result;
        },

        getNextValidDate(targetDate = null) {
            targetDate = targetDate === null ? new Date() : this.ensureDate(targetDate);

            const now = new Date();
            const from = this.min ? this.ensureDate(this.min) : startOfDay(subDays(now, DEFAULT_DAYS_BACK));
            const to = this.max ? this.ensureDate(this.max) : endOfDay(addDays(now, DEFAULT_DAYS_FORWARD));

            // min max range check
            if (targetDate < from) {
                return from;
            } else if (targetDate > to) {
                return to;
            }

            return targetDate;
        },

        preselectTime() {
            let start, end;

            if (this.isTypeRange) {
                start =
                    this.value === null || !this.value.start
                        ? this.getNextValidDate()
                        : this.getNextValidDate(this.convertTime(this.value.start));

                end =
                    this.value === null || !this.value.end
                        ? this.getNextValidDate()
                        : this.getNextValidDate(this.convertTime(this.value.end));
            } else {
                start =
                    this.value === null ? this.getNextValidDate() : this.getNextValidDate(this.convertTime(this.value));
            }

            let hoursStart = getHours(start);
            let minutesStart = Math.ceil(getMinutes(start) / this.minuteSteps) * this.minuteSteps;
            let hoursEnd = null;
            let minutesEnd = null;

            if (minutesStart >= 60) {
                hoursStart = hoursStart + Math.floor(minutesStart / 60);
                minutesStart = minutesStart % 60;
            }

            if (this.isTypeRange) {
                hoursEnd = getHours(end);
                minutesEnd = Math.round(getMinutes(end) / this.minuteSteps) * this.minuteSteps;
            }

            this.updateTimeSelection(hoursStart, minutesStart, hoursEnd, minutesEnd);
        },

        updateTimeSelection(hoursStart = null, minutesStart = null, hoursEnd = null, minutesEnd = null) {
            let hourStartIndex = 0;
            let minuteStartIndex = 0;
            let hourEndIndex = 0;
            let minuteEndIndex = 0;

            if (hoursStart !== null) {
                this.validHoursStart.forEach((h, i) => {
                    if (h === hoursStart) {
                        hourStartIndex = i;
                        this.activeHourStart = h;
                    }
                });
            }

            if (hoursEnd !== null && this.isTypeRange) {
                this.validHoursEnd.forEach((h, i) => {
                    if (h === hoursEnd) {
                        hourEndIndex = i;
                        this.activeHourEnd = h;
                    }
                });
            }

            if (minutesStart !== null) {
                this.validMinutesStart.forEach((m, i) => {
                    if (m === minutesStart) {
                        minuteStartIndex = i;
                        this.activeMinuteStart = m;
                    }
                });
            }

            if (minutesEnd !== null && this.isTypeRange) {
                this.validMinutesEnd.forEach((m, i) => {
                    if (m === minutesEnd) {
                        minuteEndIndex = i;
                        this.activeMinuteEnd = m;
                    }
                });
            }

            // do not scroll to index on fallback calendar
            if (this.$root.isDesktop) {
                return;
            }

            hoursStart !== null && this.scrollToIndex('hourStart', hourStartIndex);
            minutesStart !== null && this.scrollToIndex('minuteStart', minuteStartIndex);

            if (this.isTypeRange) {
                hoursEnd !== null && this.scrollToIndex('hourEnd', hourEndIndex);
                minutesEnd !== null && this.scrollToIndex('minuteEnd', minuteEndIndex);
            }
        },

        getViewableTime(value) {
            if (value === null) {
                return null;
            }

            return `${_padStart(getHours(value), 2, '0')}:${_padStart(getMinutes(value), 2, '0')}`;
        },

        submit() {
            const startDate = startOfDay(this.minTimestamp || this.maxTimestamp || Date.now());

            const start = addMinutes(addHours(startDate, this.activeHourStart), this.activeMinuteStart);

            if (this.isTypeRange) {
                const end = addMinutes(addHours(startDate, this.activeHourEnd), this.activeMinuteEnd);

                this.$emit('input', {
                    start: this.returnTimestamp ? start * 1 : this.getViewableTime(start),
                    end: this.returnTimestamp ? end * 1 : this.getViewableTime(end),
                });
            } else {
                this.$emit('input', this.returnTimestamp ? start * 1 : this.getViewableTime(start));
            }

            this.close();
        },

        detectCenteredItem(type) {
            const $track = document.getElementById(this.getTrackId(type));
            const $firstNode = $track.children[0];

            const centerIndex = Math.floor(($track.scrollTop + $track.offsetHeight / 2) / $firstNode.offsetHeight);
            const $centerNode = $track.children[centerIndex];

            if (type === 'hourStart') {
                this.activeHourStart = take(this.validHoursStart[centerIndex - PLACEHOLDER_COUNT]);
            } else if (type === 'hourEnd') {
                this.activeHourEnd = take(this.validHoursEnd[centerIndex - PLACEHOLDER_COUNT]);
            } else if (type === 'minuteStart') {
                this.activeMinuteStart = take(this.validMinutesStart[centerIndex - PLACEHOLDER_COUNT]);
            } else if (type === 'minuteEnd') {
                this.activeMinuteEnd = take(this.validMinutesEnd[centerIndex - PLACEHOLDER_COUNT]);
            }

            // reset values to first entry if current value is not valid
            this.resetValidValues();

            // Add active class to center node
            Array.from($track.querySelectorAll('.datetime-picker__item--active')).forEach(item => {
                if (item !== $centerNode) {
                    item.classList.remove('datetime-picker__item--active');
                }
            });
            if (!$centerNode.classList.contains('datetime-picker__item--active')) {
                $centerNode.classList.add('datetime-picker__item--active');
            }
        },

        resetValidValues() {
            if (!this.validHoursStart.includes(parseInt(this.activeHourStart))) {
                this.activeHourStart = this.validHoursStart[0];
            }

            if (!this.validMinutesStart.includes(parseInt(this.activeMinuteStart))) {
                this.activeMinuteStart = this.validMinutesStart[0];
            }

            if (!this.validHoursEnd.includes(parseInt(this.activeHourEnd))) {
                this.activeHourEnd = this.validHoursEnd[0];
            }

            if (!this.validMinutesEnd.includes(parseInt(this.activeMinuteEnd))) {
                this.activeMinuteEnd = this.validMinutesEnd[0];
            }
        },

        onVisuallyOpen() {
            setTimeout(() => {
                this.preselectTime();
            }, 200);

            if (this.$root.isDesktop) {
                return;
            }
        },

        onClose() {
            this.now = null;
            this.activeHourStart = null;
            this.activeMinuteStart = null;
            this.activeHourEnd = null;
            this.activeMinuteEnd = null;
        },

        provideValidDates() {
            if (this.rangeConfig) {
                return this.rangeConfig;
            }

            const now = new Date();

            const from = this.min ? this.ensureDate(this.min) : startOfDay(subDays(now, DEFAULT_DAYS_BACK));
            const to = this.max ? this.ensureDate(this.max) : endOfDay(addDays(now, DEFAULT_DAYS_FORWARD));
            const numOfDays = Math.abs(differenceInDays(from, to));

            return Array.from({ length: numOfDays + 1 })
                .fill('')
                .map((o, i) => ({
                    start: i === 0 ? from : startOfDay(addDays(from, i)),
                    end: i === numOfDays ? to : endOfDay(addDays(from, i)),
                }));
        },

        ensureDate(date) {
            return date && date.getTime ? date : new Date(ensureJSTimestamp(date));
        },

        clear() {
            this.close();
            this.$emit('input', null);
        },

        // Utils
        // --------------------------------------------------------
        scrollToIndex(type, index) {
            const $track = document.getElementById(this.getTrackId(type));
            const $child = $track && $track.children[index + PLACEHOLDER_COUNT];

            if ($child) {
                $track.scrollTo(0, $child.offsetTop - $child.offsetHeight);
            }
        },

        getTrackId(type) {
            return `${this.eid}-track-${type}`;
        },

        formatDay(timestamp) {
            const weekDay = this.$t(`weekdays.${getDay(timestamp)}`).substring(0, 2);
            const suffix = format(timestamp, 'DD.MM.');

            return `${weekDay}., ${suffix}`;
        },

        formatTimeDigit(num) {
            return ('0' + num).slice(-2);
        },

        preventScroll: _debounce(function () {
            this.isScrollDisabled = false;
        }, 50),

        handleTouchStart(e) {
            if (this.isScrollDisabled) {
                e.preventDefault();
                e.stopPropagation();
            }
        },

        handleTouchEnd() {
            this.isScrollDisabled = true;
            this.preventScroll();
        },

        handleScroll(type, e) {
            // push arg index based on type, to update everything after the type
            this.updateTimeSelectionDebounce(['hourStart', 'minuteStart', 'hourEnd', 'minuteEnd'].indexOf(type) + 1);

            if (this.isScrollDisabled) {
                e.preventDefault();
                e.stopPropagation();
                this.preventScroll();
            }
        },

        // Use argOffset to only update specific values
        updateTimeSelectionDebounce: _debounce(function (argOffset = 0) {
            this.detectCenteredItem('hourStart');
            this.detectCenteredItem('minuteStart');

            if (this.isTypeRange) {
                this.detectCenteredItem('hourEnd');
                this.detectCenteredItem('minuteEnd');
            }

            this.updateTimeSelection(
                argOffset > 0 ? null : this.activeHourStart,
                argOffset > 1 ? null : this.activeMinuteStart,
                argOffset > 2 ? null : this.activeHourEnd,
                argOffset > 3 ? null : this.activeMinuteEnd
            );
        }, 10),

        // Flyout Like Mechanic
        // -----------------------------------------------

        open(notify = true) {
            if (this.disabled) return;
            notify && this.$emit('opening');
            this.isActive = true;

            setTimeout(
                () => {
                    this.isVisuallyOpen = true;
                    this.onVisuallyOpen();

                    notify && this.$emit('visually-opened');
                },
                notify ? OPEN_DELAY : 0
            );
            notify && this.$emit('opened');
        },

        close(notify = true) {
            notify && this.$emit('closing');
            this.isVisuallyOpen = false;
            this.onClose();

            setTimeout(
                () => {
                    this.isActive = false;
                },
                notify ? CLOSE_DELAY : 0
            );

            notify && this.$emit('closed');
        },

        changeState(state) {
            if (this.isActive !== state) {
                state ? this.open() : this.close();
            }
        },

        closeByBackdrop() {
            if (this.isVisuallyOpen) {
                this.changeState(false);
            }
        },

        preventContentClicks(e) {
            e.stopPropagation();
        },
    },
};
</script>

<style lang="scss">
body[class*='js--flyout-open'] {
    overflow: hidden;
}

.datetime-picker__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color-darkGrey, 0.9);
    z-index: 1000;
    min-height: var(--view-height);

    visibility: hidden;
    opacity: 0;

    transition:
        visibility 0s 0.3s,
        opacity 0.3s ease-out;

    .datetime-picker--active > & {
        visibility: visible;
        opacity: 1;

        transition: opacity 0.3s ease-out;
    }
}

.datetime-picker__body {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: 1000;

    .datetime-picker--desktop > & {
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .datetime-picker--active > & {
        opacity: 1;
        animation-name: flipInX;
        animation-duration: 0.3s;
        animation-timing-function: ease-out;
    }
}

@keyframes flipInX {
    from {
        opacity: 0;
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg) translateY(40px);
        animation-timing-function: ease-out;
    }

    to {
        opacity: 1;
        transform:
            perspective(400px),
            rotate3d(1, 0, 0, 0deg) translateY(0px);
    }
}

.datetime-picker__router-content,
.datetime-picker__content {
    width: 100%;
    max-width: 100%;
    background-color: $color-white;
    // height: var(--view-height);
    max-height: var(--view-height);
}

.datetime-picker__content {
    display: flex;
    flex-flow: column nowrap;

    .datetime-picker--desktop & {
        max-width: 600px;
    }
}

.datetime-picker__header,
.datetime-picker__footer {
    flex: 0 0 auto;
}

.datetime-picker__inner {
    overflow: auto;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
}

.datetime-picker__inner-spacer {
    display: block;
    width: 100%;
    height: 0;
    overflow: hidden;
    flex-shrink: 0;

    // iOS 11.0
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
        height: constant(safe-area-inset-bottom);
    }

    // iOS 11.2
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
        height: env(safe-area-inset-bottom);
    }
}

.datetime-picker__footer {
    border-top: 1px solid $color-mediumGrey;
}

.datetime-picker__wrapper {
    position: relative;
    margin: 10px;
    display: flex;
    flex-flow: row nowrap;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.8);
        height: 50px;
        pointer-events: none;
        z-index: 1;
    }

    &::before {
        top: 0;
        border-bottom: 1px solid $color-mediumGrey;
    }

    &::after {
        bottom: 0;
        border-top: 1px solid $color-mediumGrey;
    }
}

.datetime-picker__track {
    height: 150px;
    width: 100%;
    overflow: scroll;
    scroll-snap-type: y mandatory;
    display: grid;
    grid-auto-rows: 50px;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
}

.datetime-picker__desktop-wrapper {
    background-color: $color-lightGrey;
}

.datetime-picker__track-placeholder,
.datetime-picker__item {
    height: 50px;
    width: 100%;
    line-height: 50px;
    text-align: center;
    scroll-snap-align: center;
    position: relative;
}

.datetime-picker__item-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
}

.datetime-picker__time-tracks {
    display: grid;
    grid-template-columns: 1fr 5px 1fr;
    width: 100%;
    font-weight: bold;
    align-items: center;
}

.datetime-picker__time-fallback {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;

    > * {
        width: 100%;
    }
}

.datetime-picker--disabled {
    opacity: $opacity-disabled;
}
</style>

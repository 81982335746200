<template>
    <div class="debug-pane">
        <div v-if="!!$slots.head" class="debug-pane__head">
            <slot name="head" />
        </div>
        <div v-if="!!$slots.body" class="debug-pane__body">
            <slot name="body" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pane',
};
</script>

<style lang="scss">
.debug-pane {
    margin: 20px auto;
    border: 5px solid #eee;
}

.debug-pane__head {
    font-family: 'Courier New', Courier, monospace;
    line-height: 1em;
    background-color: #eee;
    padding: 5px 10px;
}

.debug-pane__body {
    line-height: 1em;
    padding: 5px 10px;
}
</style>

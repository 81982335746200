<template>
    <LayoutPage :parent-route="parentRoute" class="preferred-carrier-page" screen-name="seller-preferredcarrier">
        <div slot="pageTitle">
            {{ $t('pages.preferredCarriers.title') }}
        </div>

        <GridRow :count="1" spacing="large" class="container-deprecated preferred-carrier-page__container">
            <Words block>{{ $t('pages.preferredCarriers.hint') }}</Words>

            <template v-for="preferredCarrierOption in maxPreferredCarriers">
                <MultiselectBox
                    v-if="selectedPreferredCarriers"
                    :key="preferredCarrierOption"
                    v-model="selectedPreferredCarriers[preferredCarrierOption - 1]"
                    screen-name="seller-preferredcarrier-create"
                    :endpoint="endpoint"
                    :filter="{
                        organizationType: 'carrier',
                    }"
                    :option-value-renderer="option => option.id"
                    :label="$t(`pages.preferredCarriers.label.carrier${preferredCarrierOption}`)"
                    :search-label-renderer="
                        option => `${option.name} ${formatAddressObject({ address: option.billingAddress })}`
                    "
                    :search-field-label="$t('pages.preferredCarriers.label.searchField')"
                    class="organization-form__multiselect"
                    searchable
                >
                    <PreferredCarrierInner slot="button" slot-scope="buttonScope" mode="button" :scope="buttonScope" />
                    <PreferredCarrierInner slot="option" slot-scope="optionScope" mode="option" :scope="optionScope" />
                </MultiselectBox>
            </template>

            <Words block spaced-top>{{ $t('pages.preferredCarriers.windowInfo') }}</Words>
            <dl class="organization-form__details">
                <dt>{{ $t('pages.preferredCarriers.windowDefaultDelivery') }}</dt>
                <dd>
                    {{
                        $t('minuteSuffix', {
                            time: features.preferred_carrier.defaultDeliveryWindow,
                        })
                    }}
                </dd>
                <dt>{{ $t('pages.preferredCarriers.windowOnTimeDelivery') }}</dt>
                <dd>
                    {{
                        $t('minuteSuffix', {
                            time: features.preferred_carrier.onTimeDeliveryWindow,
                        })
                    }}
                </dd>
            </dl>
        </GridRow>

        <ButtonGroup slot="sticky">
            <Button v-if="parentRoute" primary light arrow-right :disabled="isPending" @click="goToParent()">
                {{ $t('pages.organization.form.actions.cancel') }}
            </Button>

            <Button primary :disabled="isPending" :is-loading="isPending" @click="save()">
                {{ $t('pages.organization.form.actions.save') }}
            </Button>
        </ButtonGroup>
    </LayoutPage>
</template>

<script>
import _get from 'lodash/get';
import { mapGetters } from 'vuex';
import OrganizationApi from '@/services/Api/Organization';
import PreferredCarrierApi from '@/services/Api/PreferredCarrier';
import { formatAddressObject } from '@/services/utils/address';
import Toaster from '@/services/Toaster';

import LayoutPage from '@/components/Layout/Page.v2';
import GridRow from '@/components/Layout/GridRow';
import Words from '@/components/Typography/Words';
import MultiselectBox from '@/components/Form/MultiselectBox';
import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import PreferredCarrierInner from '@/components/Form/MultiselectBox/PreferredCarrierInner';

export default {
    name: 'PreferredCarrier',
    components: {
        LayoutPage,
        GridRow,
        Words,
        MultiselectBox,
        Button,
        ButtonGroup,
        PreferredCarrierInner,
    },
    props: {
        parentRoute: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            maxPreferredCarriers: 3,
            availableCarriers: [],
            selectedPreferredCarriers: [],
            isPending: false,

            endpoint: OrganizationApi,
        };
    },
    computed: {
        ...mapGetters('user', ['features', 'organization']),
    },
    created() {
        this.refreshData();
    },
    methods: {
        formatAddressObject,

        async refreshData() {
            try {
                const response = await PreferredCarrierApi.getOneById(this.organization.id);
                this.selectedPreferredCarriers = response.carrierOrganizations.map(org => org.id);
            } catch (err) {
                Toaster.error(err);
            }
        },

        async save() {
            if (this.isPending) {
                return;
            }

            this.isPending = true;

            try {
                const carrierOrganizations = this.selectedPreferredCarriers
                    .filter(record => record)
                    .map(record => ({ id: record }));

                const response = await PreferredCarrierApi.save({
                    id: this.organization.id,
                    supplierOrganization: {
                        id: this.organization.id,
                    },
                    carrierOrganizations,
                });

                this.selectedPreferredCarriers = response.carrierOrganizations.map(org => org.id);

                Toaster.success(this.$t('pages.preferredCarriers.saveSuccess'));
            } catch (err) {
                const data = _get(err, 'response.data.errors', null);
                if (data) {
                    const message = Object.values(data).flat(20).join(', ');
                    Toaster.error(message);
                } else {
                    Toaster.error(err);
                }
            }

            this.isPending = false;
        },

        goToParent() {
            if (!this.parentRoute) {
                return;
            }

            return this.$router.push({ name: this.$root.findRouteName(this.parentRoute) }).catch(() => {});
        },
    },
};
</script>

<style lang="scss">
.preferred-carrier-page__container {
    margin-top: 20px;
}
</style>

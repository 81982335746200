import _distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import { setMilliseconds, setSeconds, setHours, setMinutes, differenceInMilliseconds } from 'date-fns';
import differenceInHours from 'date-fns/difference_in_hours';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import i18n from '@/i18n';
import { getDateFnsLocaleByLocaleCode } from '@/i18n/languages';

/**
 * Convert php timestamp to js timestamp
 * @param {int} timestamp
 */
export function ensureJSTimestamp(timestamp) {
    if (timestamp === null || timestamp === undefined) {
        return null;
    }

    timestamp = parseInt(timestamp);

    if (!timestamp) {
        return null;
    }

    if (timestamp <= 9999999999) {
        return timestamp * 1000;
    }

    return timestamp;
}

/**
 * Convert js timestamp to php timestamp
 * @param {int} timestamp
 * @returns {number} php timestamp
 */
export function ensurePHPTimestamp(timestamp) {
    if (timestamp === null || timestamp === undefined) {
        return null;
    }

    timestamp = parseInt(timestamp);

    if (!timestamp) {
        return null;
    }

    if (timestamp > 9999999999) {
        return parseInt(timestamp / 1000);
    }

    return timestamp;
}

/**
 * Remap data input to time range format
 * @param {object|null} data
 * @return {{startTime: (string|null), endTime: (string|null)}}
 */
export function ensureTimeRange(data) {
    return {
        startTime: data?.startTime || data?.start || null,
        endTime: data?.endTime || data?.end || null,
    };
}

/**
 * Get a distance between a timestamp and now in words (localized)
 * https://date-fns.org/v1.29.0/docs/distanceInWordsToNow
 *
 * @param {int} timestamp
 * @param {object} options
 */
export function distanceInWordsToNow(timestamp, options = {}) {
    if (!timestamp) {
        return null;
    }

    const locale = getDateFnsLocaleByLocaleCode(i18n.locale) || getDateFnsLocaleByLocaleCode(i18n.fallbackLocale);

    return _distanceInWordsToNow(ensureJSTimestamp(timestamp), {
        addSuffix: true,
        locale,
        ...options,
    });
}

/**
 * Format hours and minutes
 *
 * @param {int} hours
 * @param {int} minutes
 * @return {string}
 */
export function formatHourMinutes(hours, minutes) {
    const parts = [];

    if (hours > 0) {
        parts.push(i18n.t('hourSuffix', { time: hours }));
    }

    if (minutes > 0 || hours <= 0) {
        parts.push(i18n.t('minuteSuffix', { time: minutes }));
    }

    return parts.join(' ');
}

/**
 * Format arrival time
 * @param {int} timestamp
 * @return {string}
 */
export function formatArrivalTime(timestamp) {
    timestamp = ensureJSTimestamp(timestamp);

    const now = new Date();
    const hours = differenceInHours(timestamp, now);
    let minutes = differenceInMinutes(timestamp, now) % 60;

    if (hours <= 0 && minutes < 0) {
        minutes = 0;
    }

    return formatHourMinutes(hours, minutes);
}

/**
 * Format ms delay in words, which is rounded to the minute
 * @param {int} ms
 * @return {string}
 */
export function getFormattedDurationFromMS(ms) {
    return getFormattedDuration(ms / 1000);
}

/**
 * Format delay from seconds to words
 * @param {number} seconds
 * @param {boolean} roundMinutesDown
 * @return {string}
 */
export function getFormattedDuration(seconds, roundMinutesDown = false) {
    const rawMinutes = Math.abs(seconds) / 60;
    const minutes = roundMinutesDown ? Math.floor(rawMinutes) : Math.ceil(rawMinutes);
    const restMinutes = minutes % 60;
    const hours = Math.floor(minutes / 60);

    return formatHourMinutes(hours, restMinutes);
}

/**
 * convert values like "18:00" to timestamp
 * @param {String} value
 * @param {number|null} baseTimestamp
 */
export function convertColonedTime(value, baseTimestamp = null) {
    const date = convertColonedTimeToDate(value, baseTimestamp);

    return date ? date.getTime() : date;
}

/**
 * convert values like "18:00" to timestamp
 * @param {String} value
 * @param {number|null} baseTimestamp
 */
export function convertColonedTimeToDate(value, baseTimestamp = null) {
    if (!value) {
        return null;
    }

    const time = value.split(':').map(v => parseInt(v));
    let result = baseTimestamp === null ? new Date() : new Date(ensureJSTimestamp(baseTimestamp));
    result = setMilliseconds(result, 0);
    result = setSeconds(result, 0);
    result = setMinutes(result, time[1]);
    result = setHours(result, time[0]);

    return result;
}

/**
 * Get time difference from colon time in milliseconds
 *
 * @param {string} startTime 16:00
 * @param {string} endTime 17:00
 * @return {number}
 */
export function colonTimeDifference(startTime, endTime) {
    const startDate = convertColonedTime(startTime);
    const endDate = convertColonedTime(endTime);
    return differenceInMilliseconds(endDate, startDate);
}

/**
 * Format given dates as range
 * @param {number} from
 * @param {number} [to]
 * @param {string} [format]
 * @returns {string}
 */
export function formatDateRange(from, to = null, format = 'short') {
    if (!from) {
        return '';
    }

    const fromText = i18n.d(ensureJSTimestamp(from), format);
    const toText = to ? i18n.d(ensureJSTimestamp(to), format) : null;
    const parts = [fromText];

    if (toText && fromText !== toText) {
        parts.push(toText);
    }

    return parts.join(' - ');
}

/**
 * Format given times as range
 * @param {string} from
 * @param {string} [to]
 * @returns {string}
 */
export function formatTimeRange(from, to) {
    if (!from) {
        return '';
    }

    const fromText = to && from !== to ? from : i18n.t('timeSuffix', { time: from });
    const toText = to ? i18n.t('timeSuffix', { time: to }) : null;

    return toText && fromText !== toText ? `${fromText} - ${toText}` : `${fromText}`;
}

/**
 * Format given minutes as interval
 * @param {number} minutes
 * @returns {{unit: string, value: number}}
 */
export function formatInterval(minutes) {
    if (!minutes) {
        return { unit: 'minutes', value: 0 };
    }

    if (minutes >= 1440) {
        return { unit: 'days', value: Math.round(minutes / 1440) };
    }

    if (minutes >= 60) {
        return { unit: 'hours', value: Math.round(minutes / 60) };
    }

    return { unit: 'minutes', value: minutes };
}

/**
 * parseJSON from date-fns v2
 * @see https://github.com/date-fns/date-fns/blob/fadbd4eb7920bf932c25f734f3949027b2fe4887/src/parseJSON/index.ts
 *
 * @param {string} dateString
 * @returns {Date}
 */
export function parseJSON(dateString) {
    const parts = dateString.match(
        /(\d{4})-(\d{2})-(\d{2})[T ](\d{2}):(\d{2}):(\d{2})(?:\.(\d{0,7}))?(?:Z|(.)(\d{2}):?(\d{2})?)?/
    );
    if (parts) {
        // Group 8 matches the sign
        return new Date(
            Date.UTC(
                +parts[1],
                +parts[2] - 1,
                +parts[3],
                +parts[4] - (+parts[9] || 0) * (parts[8] == '-' ? -1 : 1),
                +parts[5] - (+parts[10] || 0) * (parts[8] == '-' ? -1 : 1),
                +parts[6],
                +((parts[7] || '0') + '00').substring(0, 3)
            )
        );
    }
    return new Date(NaN);
}

<template>
    <LayoutPage class="logbook" screen-name="carrier-logbook-list" data-test="logbook-page">
        <template #pageTitle>{{ $t('pages.logbook.title') }}</template>

        <template #mobileHeader>
            <HeaderBar>
                <template #headline>
                    <span>{{ $t('pages.logbook.title') }}</span>
                </template>
                <template #right>
                    <HeaderBarItem>
                        <LogbookContextMenu
                            :disabled="!transports || transports.count === 0"
                            @click="contextAction($event)"
                        />
                    </HeaderBarItem>
                </template>
            </HeaderBar>
        </template>

        <FilterBox
            v-model="filter"
            class="logbook__inline-filter mb-6"
            :default-filter="filter"
            :endpoint="dataEndpoint"
            inline-mode
            @update="refreshList()"
        >
            <template #default="filterScope">
                <div class="logbook__filter-content">
                    <TimeRangeFieldset
                        :from="filterScope.filter.startServiceDateTimestamp"
                        :to="filterScope.filter.endServiceDateTimestamp"
                        type="date"
                        required
                        @update-from="$set(filterScope.filter, 'startServiceDateTimestamp', ensurePHPTimestamp($event))"
                        @update-to="$set(filterScope.filter, 'endServiceDateTimestamp', ensurePHPTimestamp($event))"
                    />

                    <LogbookContextMenu
                        v-if="$root.isDesktop"
                        :disabled="!transports || transports.count === 0"
                        @click="contextAction($event)"
                    />
                </div>
            </template>
        </FilterBox>

        <section v-if="transports && transports.count > 0" class="mx-4 mb-8 mt-4 lg:mx-0 lg:mt-0">
            <CarrierTransportItem
                v-for="transport in transports.items"
                :key="transport.id"
                :transport="transport"
                view="carrier"
                @click="selectTransport(transport.id)"
            />
            <MoreResultsButton v-if="transports.count - transports.items.length > 0" :result="transports" fade-out />
        </section>
        <EmptyList
            v-else
            :headline="$t('pages.transportHub.noResults')"
            :description="$t('pages.logbook.noResult')"
            :is-loading="isLoading"
        />

        <template #subpages>
            <Flyout route="logbook__transport-view" size="small" no-header />

            <Flyout
                :active="pdfEmailAddressOpen"
                size="small"
                :headline="$t('pages.logbook.emailForm.title')"
                @closed="pdfEmailAddressOpen = false"
            >
                <Tile no-border>
                    <Words block spaced-bottom>
                        {{ $t('pages.logbook.emailForm.description') }}
                    </Words>

                    <TextField v-model="email" label="E-Mail" type="text" />
                </Tile>

                <template #bottom>
                    <ButtonGroup>
                        <BaseButton
                            :disabled="isPending"
                            primary
                            light
                            arrow-left
                            place-left
                            @click="pdfEmailAddressOpen = false"
                        >
                            {{ $t('pages.logbook.emailForm.cancel') }}
                        </BaseButton>
                        <BaseButton :is-loading="isPending" primary @click="sendEmail()">
                            {{ $t('pages.logbook.emailForm.submit') }}
                        </BaseButton>
                    </ButtonGroup>
                </template>
            </Flyout>
        </template>
    </LayoutPage>
</template>

<script>
import _get from 'lodash/get';
import { startOfDay, endOfDay } from 'date-fns';
import TransportListApi from '@/services/Api/TransportList';
import LogbookApi from '@/services/Api/Logbook';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import { ensurePHPTimestamp } from '@/services/utils/date';
import Toaster from '@/services/Toaster';
import { CONTEXT_CARRIER_DRIVER } from '@/constants/context';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import FilterBox from '@/components/Filter/FilterBox';
import Flyout from '@/components/Layout/Flyout';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import LayoutPage from '@/components/Layout/Page.v2';
import LogbookContextMenu from './components/LogbookContextMenu';
import MoreResultsButton from '@/components/Filter/MoreResultsButton';
import TextField from '@/components/Form/TextField.v2';
import Tile from '@/components/Layout/Tile';
import TimeRangeFieldset from '@/components/Filter/TimeRangeFieldset.v2';
import CarrierTransportItem from '@/components/List/TransportItem/Carrier.vue';
import EmptyList from '@/components/List/EmptyList.vue';
import Words from '@/components/Typography/Words';

import { navigationFailure } from '@/services/utils/router';

const defaultStart = startOfDay(new Date()) * 1;
const defaultEnd = endOfDay(new Date()) * 1;

const transportListApi = new TransportListApi(CONTEXT_CARRIER_DRIVER);

export default {
    name: 'LogbookPage',
    components: {
        BaseButton,
        ButtonGroup,
        FilterBox,
        Flyout,
        HeaderBar,
        HeaderBarItem,
        LayoutPage,
        LogbookContextMenu,
        MoreResultsButton,
        TextField,
        Tile,
        TimeRangeFieldset,
        CarrierTransportItem,
        EmptyList,
        Words,
    },
    mixins: [persistentFiltersMixin],
    data() {
        return {
            isLoading: false,
            transports: null,
            cancelSource: null,
            dataEndpoint: transportListApi,
            filter: this.assembleFilter('transport', {
                page: 1,
                perPage: 25,
                sortBy: 'serviceDateTimestamp',
                status: ['delivered', 'confirmation_pending', 'failed'],
                startServiceDateTimestamp: ensurePHPTimestamp(defaultStart),
                endServiceDateTimestamp: ensurePHPTimestamp(defaultEnd),
            }),
            defaultFilter: {
                page: 1,
                perPage: 25,
                sortBy: 'serviceDateTimestamp',
                status: ['delivered', 'confirmation_pending', 'failed'],
                startServiceDateTimestamp: ensurePHPTimestamp(defaultStart),
                endServiceDateTimestamp: ensurePHPTimestamp(defaultEnd),
            },
            email: null,
            pdfEmailAddressOpen: false,
            isPending: false,
        };
    },
    created() {
        this.refreshList(true);
    },
    mounted() {
        this.email = _get(this.$store.getters['user/info'], 'user.email', null);
    },
    methods: {
        ensurePHPTimestamp,

        async refreshList(isInitial = false) {
            this.isLoading = true;

            this.persistFilter('transport', this.filter, this.defaultFilter);

            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.cancelSource = transportListApi.createCancelTokenSource();

            try {
                const result = await transportListApi.filter(
                    this.filter,
                    null,
                    null,
                    this.cancelSource,
                    { user: this.$store.getters['user/id'] },
                    !isInitial
                );

                this.transports = result;

                if (isInitial) {
                    this.filter = {
                        ...this.filter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }

            this.isLoading = false;
        },

        selectTransport(transportId) {
            this.$router
                .push({
                    name: `${this.$route.name}__transport-view`,
                    params: { transportId },
                })
                .catch(navigationFailure);
        },

        contextAction(action) {
            switch (action) {
                case 'help':
                    this.$eventHub.$emit('page.platformContact', true);
                    return;
                case 'createPdf':
                    this.createPdf();
                    return;
                case 'sendEmail':
                    this.pdfEmailAddressOpen = true;
                    return;
            }
        },

        async createPdf() {
            if (this.isPending) return;
            this.isPending = true;

            try {
                Toaster.info(this.$t('pages.logbook.info.pdf'));

                const response = await LogbookApi.createPdf(
                    this.filter.startServiceDateTimestamp,
                    this.filter.endServiceDateTimestamp
                );

                window.open(response.url);
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(this.$t('pages.logbook.error.pdf'));
            }

            this.isPending = false;
        },

        async sendEmail() {
            if (this.isPending) return;
            this.isPending = true;

            try {
                await LogbookApi.sendEmail(
                    this.email,
                    this.filter.startServiceDateTimestamp,
                    this.filter.endServiceDateTimestamp
                );

                Toaster.error(this.$t('pages.logbook.success.email', { email: this.email }));
                this.pdfEmailAddressOpen = false;
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(this.$t('pages.logbook.error.email'));
            }

            this.isPending = false;
        },
    },
};
</script>

<style lang="scss">
.logbook__inline-filter {
    background-color: $color-white;
}

.logbook__filter-content {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 20px;
    align-items: center;
}

.logbook__email-icon {
    .f-base {
        fill: $color-black;
    }
}

.logbook__transport-list-empty {
    padding: 30px;
    text-align: center;
}
</style>

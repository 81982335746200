<template>
    <div
        :class="[
            {
                'select-input--shadow': shadow && !dark,
                'select-input--small': small,
            },
        ]"
        class="select-input select-input--sorting"
    >
        <SortingDirection :value="direction" :dark="dark" :small="small" @input="handleDirectionChange" />
        <div class="select-input__wrapper">
            <select
                :id="eid"
                :value="value"
                :class="{ 'select-input__input--no-value': value === null && label }"
                class="select-input__input"
                @change="handleSelectionChange"
            >
                <slot />
            </select>
            <label
                v-if="label && value === null"
                :for="eid"
                :class="[{ 'select-input__label--dark': dark }]"
                class="select-input__label"
            >
                {{ label }}
            </label>
            <ArrowDownIcon class="select-input__arrow select-input__arrow--down" />
        </div>
    </div>
</template>

<script>
import SelectBox from './SelectBox';
import SortingDirection from '@/components/Form/SortingDirection';

import ArrowDownIcon from '@/assets/icons/regular/arrow-stripeless--down.svg';

export default {
    name: 'SortingSelectBox',
    components: {
        SortingDirection,
        ArrowDownIcon,
    },
    extends: SelectBox,
    props: {
        direction: {
            type: String,
            default: 'asc',
            validator: value => ['asc', 'desc'].indexOf(value) !== -1,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            eid: `el${this._uid}`,
        };
    },
    methods: {
        handleSelectionChange($event) {
            this.$emit('input', $event.target.value);
            this.$emit('update');
        },
        handleDirectionChange($event) {
            this.$emit('direction-update', $event);
            this.$emit('update');
        },
    },
};
</script>

<style lang="scss">
.select-input--sorting {
    display: flex;
    flex-flow: row nowrap;
    padding-left: 0;

    .select-input__input,
    .select-input__label {
        padding-left: 0;
    }

    .select-input__label--dark {
        background-color: $color-lightGrey;
    }
}

.select-input--small {
    height: 50px;

    .select-input__label {
        line-height: 50px;
    }
}
</style>

<template>
    <div>
        <Words v-if="headline" block bold spaced-bottom>{{ headline }}</Words>
        <LocationPicker
            :value="value || defaultLocation"
            :title="
                $t(
                    isLoading
                        ? 'pages.checkout.shipmentLoading.additionalInformation.loadingButtonLabel'
                        : 'pages.checkout.shipmentLoading.additionalInformation.unloadingButtonLabel'
                )
            "
            enable-reset
            :initial-address="value || defaultLocation"
            map-type="satellite"
            @input="updateLocation"
            @resetMap="resetLocation"
        >
            <template #default="{ editLocation, showLocation }">
                <Card no-padding spaceless>
                    <div class="checkout-additional-information__tile-button">
                        <div
                            v-if="locationIsDefault"
                            slot="left"
                            class="checkout-additional-information__tile-button-placeholder"
                            @click="editLocation"
                        >
                            <Words small centered>
                                {{
                                    $t(
                                        isLoading
                                            ? 'pages.checkout.shipmentLoading.additionalInformation.loadingThumbnailLabel'
                                            : 'pages.checkout.shipmentLoading.additionalInformation.unloadingThumbnailLabel'
                                    )
                                }}
                            </Words>
                        </div>
                        <div v-else class="checkout-additional-information__location-thumbnail">
                            <Thumbnail
                                :img-src="staticMap"
                                :modal-action="showLocation"
                                :inline="false"
                                class="checkout-additional-information__tile-thumbnail"
                                modal
                            />
                            <FlagIcon :height="52" :width="52" class="checkout-additional-information__location-flag" />
                        </div>

                        <BaseButton
                            arrow-right
                            place-left
                            class="checkout-additional-information__tile-button-text"
                            @click="editLocation"
                        >
                            {{
                                $t(
                                    isLoading
                                        ? `pages.checkout.shipmentLoading.additionalInformation.loadingButtonLabel${
                                              !locationIsDefault ? 'Change' : ''
                                          }`
                                        : `pages.checkout.shipmentLoading.additionalInformation.unloadingButtonLabel${
                                              !locationIsDefault ? 'Change' : ''
                                          }`
                                )
                            }}
                        </BaseButton>

                        <BaseButton v-if="!locationIsDefault && !isEditLocation" slot="right" @click="resetLocation">
                            <DeleteIcon />
                        </BaseButton>
                    </div>
                </Card>
            </template>
        </LocationPicker>
    </div>
</template>

<script>
import { getStaticMapUrl } from '@/services/GoogleMaps';
import { locationsEqual } from '@/services/utils/map';

import Words from '@/components/Typography/Words';
import LocationPicker from './LocationPicker';
import Card from '@/components/Layout/Card';
import Thumbnail from '@/components/Thumbnail';
import BaseButton from '@/components/Button/Button';

import FlagIcon from '@/assets/icons/flag-centered.svg';
import DeleteIcon from '@/assets/icons/regular/garbage.svg';

export default {
    name: 'ShipmentLocation',

    components: {
        Words,
        LocationPicker,
        Card,
        Thumbnail,
        BaseButton,

        FlagIcon,
        DeleteIcon,
    },

    props: {
        value: {
            type: Object,
            default: null,
        },
        defaultLocation: {
            type: Object,
            default: null,
        },
        headline: {
            type: String,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isEditLocation: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        staticMap() {
            if (!this.value) return null;
            return getStaticMapUrl(this.value, 16, 200, 200, 'satellite');
        },
        locationIsDefault() {
            if (this.isEditLocation) return false;
            if (!this.defaultLocation || !this.value) return true;

            return locationsEqual(this.defaultLocation, this.value);
        },
    },

    methods: {
        resetLocation() {
            this.$emit('input', this.defaultLocation || null);
        },

        updateLocation(value) {
            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss"></style>

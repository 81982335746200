import { getHours, getMinutes, getTime, isWithinRange, setHours, setMinutes } from 'date-fns';
import { MINUTE, DAY, inDays } from '@/services/utils/time';
import { convertColonedTime, ensureJSTimestamp } from '@/services/utils/date';
import OutOfBoundsError from '@/services/PlanningAssistant/OutOfBoundsError';

/**
 * @param {Array} availableDays
 * @param {Number} timestamp
 * @param {Number} dayIndex
 * @return {boolean}
 */
function isInAvailableRange(availableDays, timestamp, dayIndex) {
    if (dayIndex > availableDays.length - 1) return false;
    return isWithinRange(timestamp, availableDays[dayIndex].start, availableDays[dayIndex].end);
}

/**
 * @param {Number} interval
 * @param {Date|Number} startDate
 * @param {Number} count
 */
function validateArguments(interval, startDate, count) {
    if (!Number.isInteger(interval) || !startDate || !Number.isInteger(count)) {
        throw new Error('Invalid arguments provided for calculation');
    }
}

/**
 * @param {startTime: Number, endTime: Number} constraint
 */
function validateTimeConstraint(constraint) {
    const LOSE_TIME_PATTERN = /\d:\d/;
    if (!constraint || !LOSE_TIME_PATTERN.test(constraint.startTime) || !LOSE_TIME_PATTERN.test(constraint.endTime)) {
        throw new Error('Invalid time constraint provided');
    }
}

/**
 * Return mapping function for restricting by time constraint
 *
 * @param {null|{startTime: string, endTime: string}} timeConstraint
 * @return {(function(*): (null|{start: number, end: number}))|*}
 */
function applyTimeConstraint(timeConstraint) {
    if (!timeConstraint) {
        return v => v;
    }

    return day => {
        const start = Math.max(convertColonedTime(timeConstraint.startTime, day.start), ensureJSTimestamp(day.start));
        const end = Math.min(convertColonedTime(timeConstraint.endTime, day.end), ensureJSTimestamp(day.end));

        if (start > end) {
            return null;
        }

        return { start, end };
    };
}

/**
 * Get a filter for removing all smaller dates from a given range config
 *
 * @param {Date} date
 * @return {function}
 */
function applyFilterLaterDays(date) {
    if (!date) {
        return v => v;
    }

    return d => d.end >= date;
}

/**
 * Ensure range config uses js timestamp
 *
 * @param rangeConfig
 * @return {null|*}
 */
export function prepareRangeConfig(rangeConfig) {
    if (!rangeConfig) {
        return null;
    }

    return rangeConfig.map(day => {
        return {
            start: ensureJSTimestamp(day.start),
            end: ensureJSTimestamp(day.end),
        };
    });
}

export default class PlanningAssistant {
    constructor(rangeConfig = null, timeConstraint = null) {
        this._rangeConfig = prepareRangeConfig(rangeConfig);
        this._timeConstraint = null;

        if (timeConstraint) {
            this.setTimeConstraint(timeConstraint);
        }
    }

    /**
     * @param {startTime: Number, endTime: Number} timeConstraint
     * @return {PlanningAssistant}
     */
    setTimeConstraint(timeConstraint) {
        validateTimeConstraint(timeConstraint);

        this._timeConstraint = timeConstraint;
        return this;
    }

    /**
     * Get a list of available days from a given starting point
     * @param {Date|Number} startDate
     * @return {Array}
     */
    getAvailableDays(startDate = null) {
        if (this._rangeConfig === null) {
            throw new Error('Invalid rangeConfig provided');
        }

        return (
            this._rangeConfig
                .map(applyTimeConstraint(this._timeConstraint))
                .filter(v => !!v)
                .filter(applyFilterLaterDays(startDate)) ?? []
        );
    }

    /**
     * Calculate exact dates by intervals from a starting date
     *
     * @param {Number} intervalInMinutes
     * @param {Date|Number} startDate
     * @param {Number} datesCount
     * @return {Number[]}
     */
    calculateDates(intervalInMinutes, startDate, datesCount) {
        validateArguments(intervalInMinutes, startDate, datesCount);

        // Convert minutes to milliseconds
        const interval = intervalInMinutes * MINUTE;

        if (interval === 0) {
            return Array.from({ length: datesCount }).fill(startDate * 1);
        }

        if (interval % DAY === 0) {
            return this.calculateDaysByDays(inDays(intervalInMinutes * MINUTE), startDate, datesCount);
        }

        return this.calculateDaysByMinutes(intervalInMinutes, startDate, datesCount);
    }

    /**
     * Calculate day intervals from a starting date
     *
     * @param {Number} intervalInDays
     * @param {Date|Number} startDate
     * @param {Number} datesCount
     * @return {Number[]}
     */
    calculateDaysByDays(intervalInDays, startDate, datesCount) {
        const availableDays = this.getAvailableDays(startDate);

        validateArguments(intervalInDays, startDate, datesCount);

        // Convert days to milliseconds
        const interval = intervalInDays * DAY;

        const dates = [];
        let currentTime = startDate * 1;
        let currentDayIndex = 0;

        for (let i = 0; i < datesCount; i++) {
            if (currentDayIndex >= availableDays.length) {
                break;
            }

            dates.push(Math.min(currentTime, availableDays[currentDayIndex].end));
            currentDayIndex += inDays(interval);

            if (currentDayIndex >= availableDays.length) {
                break;
            }

            currentTime = getTime(
                setMinutes(setHours(availableDays[currentDayIndex].start, getHours(startDate)), getMinutes(startDate))
            );
        }

        if (dates.length !== datesCount) {
            throw new OutOfBoundsError('Not enough available dates for calculation', dates);
        }

        return dates;
    }

    /**
     * Calculate day intervals from a starting date
     *
     * @param {Number} intervalInMinutes
     * @param {Date|Number} startDate
     * @param {Number} datesCount
     * @return {Number[]}
     */
    calculateDaysByMinutes(intervalInMinutes, startDate, datesCount) {
        const availableDays = this.getAvailableDays(startDate);

        validateArguments(intervalInMinutes, startDate, datesCount);

        // Convert minutes to milliseconds
        const interval = intervalInMinutes * MINUTE;

        const dates = [];
        let currentTime = startDate * 1;
        let currentDayIndex = 0;

        if (interval % DAY === 0) {
            throw new Error('Logical error: Do not use this function for day intervals');
        }

        dayLoop: for (let i = 0; i < datesCount; i++) {
            if (currentDayIndex >= availableDays.length) {
                break;
            }

            // currentTime is before day starts
            if (currentTime < availableDays[currentDayIndex].start) {
                currentTime = availableDays[currentDayIndex].start;
            }

            while (!isInAvailableRange(availableDays, currentTime, currentDayIndex)) {
                currentDayIndex += 1;

                if (currentDayIndex >= availableDays.length) {
                    break dayLoop;
                }

                currentTime = availableDays[currentDayIndex].start;
            }

            dates.push(currentTime);
            currentTime += interval;
        }

        if (dates.length !== datesCount) {
            throw new OutOfBoundsError('Not enough available dates for calculation', dates);
        }

        return dates;
    }
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('RenderlessAddressPrediction',{attrs:{"value":_vm.value,"input-id":_vm.eid,"list-locality":_vm.listLocality,"focus-street-number":_vm.focusStreetNumber && !_vm.allowIncompleteAddress,"street-less":_vm.streetLess,"include-state":_vm.includeState,"include-country":_vm.includeCountry,"allowed-countries":_vm.allowedCountries},on:{"input":function($event){return _vm.$emit('input', $event)},"plus-code":function($event){return _vm.$emit('plus-code', $event)}},scopedSlots:_vm._u([{key:"default",fn:function({
            inputAttrs,
            inputEvents,
            predictions,
            reset,
            selectPrediction,
            predictionIsStreet,
            predictionHasStreetNumber,
            predictionIsArea,
            highlightedIndex,
            highlightIndex,
            handleBlur,
        }){return _c('div',{staticClass:"enhanced-address-field"},[_c('SfTextField',_vm._g(_vm._b({class:_vm.inputClassName,attrs:{"id":_vm.eid,"data-test":"input-location-address","autocomplete":"off","readonly":_vm.readonly,"label":_vm.label || String(_vm.$t('components.addressField.searchLabel')),"placeholder":String(_vm.$t('components.addressField.searchLabel')),"validation-message":_vm.error || (_vm.hasError && ' ')},on:{"focus":_vm.focused,"keypress":_vm.handleKeypress,"keydown":_vm.handleKeypress,"blur":function($event){return _vm.delayedBlured($event, handleBlur)},"input":value => !value && reset()},scopedSlots:_vm._u([{key:"leading-icon",fn:function(iconProps){return [(_vm.isLoading)?_c('SfReloadIcon',_vm._b({staticClass:"animate-spin"},'SfReloadIcon',iconProps,false)):_c('SfMapMarkerIcon',_vm._b({},'SfMapMarkerIcon',iconProps,false))]}}],null,true)},'SfTextField',inputAttrs,false),inputEvents)),(predictions && predictions.length > 0)?_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFocused),expression:"isFocused"}],staticClass:"enhanced-address-field__suggestion-list"},_vm._l((predictions),function(prediction,index){return _c('li',{key:index,staticClass:"enhanced-address-field__suggestion",class:{
                    'enhanced-address-field__suggestion--active': false,
                    'enhanced-address-field__suggestion--highlighted': highlightedIndex === index,
                },on:{"mouseover":function($event){return highlightIndex(index)},"mousedown":function($event){_vm.cancelDelayedBlur(_vm.allowIncompleteAddress || predictionHasStreetNumber(prediction))},"click":function($event){$event.stopPropagation();$event.preventDefault();return selectPrediction(prediction, true)},"touchmove":_vm.handleTouchMove,"touchend":function($event){$event.stopPropagation();$event.preventDefault();_vm.handleTouchEnd(() =>
                        selectPrediction(
                            prediction,
                            // trigger street number focus in input field
                            !_vm.allowIncompleteAddress && !predictionHasStreetNumber(prediction),

                            // unfocus
                            predictionIsArea(prediction) ||
                                _vm.allowIncompleteAddress ||
                                predictionHasStreetNumber(prediction)
                        )
                    )}}},[_c('div',{staticClass:"enhanced-address-field__suggestion-main-part",attrs:{"data-test":"address-dropdown"}},[_vm._v(" "+_vm._s(prediction.structured_formatting.main_text)+" ")]),(!_vm.noSublines && prediction.structured_formatting.secondary_text)?_c('div',{staticClass:"enhanced-address-field__suggestion-sub-part"},[_vm._v(" "+_vm._s(prediction.structured_formatting.secondary_text)+" ")]):_vm._e(),(!_vm.noButtons)?[(predictionIsStreet(prediction) && !_vm.allowIncompleteAddress)?_c('Words',{attrs:{"small":"","bold":"","block":"","spaced-top-small":""}},[_vm._v(" "+_vm._s(_vm.$t('components.addressField.addStreetNumber'))+" ")]):(predictionIsArea(prediction) || _vm.allowIncompleteAddress)?_c('Words',{attrs:{"small":"","bold":"","block":"","spaced-top-small":""}},[_vm._v(" "+_vm._s(_vm.$t('components.addressField.selectOnMap'))+" ")]):(
                            !predictionIsArea(prediction) &&
                            predictionHasStreetNumber(prediction) &&
                            predictions.length === 1
                        )?_c('Words',{attrs:{"small":"","bold":"","block":"","spaced-top-small":""}},[_vm._v(" "+_vm._s(_vm.$t('components.addressField.selectAddress'))+" ")]):_vm._e()]:_vm._e()],2)}),0):_vm._e()],1)}}])})
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
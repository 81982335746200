import ApiService from '@/services/Api/ApiService';
import UrlBuilder from '@/services/Api/UrlBuilder';
import AbstractResource from './AbstractResource';

export default class AbstractProductResource extends AbstractResource {
    constructor(resourcePath = null) {
        super();
        this.apiService = ApiService;
        this.resourcePath = resourcePath;
    }

    /**
     * Save data
     * @param {urlParameters} data
     * @param {object} data
     */
    async patch(urlParameters, data) {
        try {
            const url = UrlBuilder.replaceUrlParamsWithValues(this.resourcePath, urlParameters);
            const response = await super.patch(url, data);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Save data
     * @param {urlParameters} data
     * @param {object} data
     */
    async put(urlParameters, data) {
        try {
            const url = UrlBuilder.replaceUrlParamsWithValues(this.resourcePath, urlParameters);
            const response = await super.put(url, data);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Get one resource object
     * @param {int} id
     */
    async getOneById(urlParameters) {
        try {
            const url = UrlBuilder.replaceUrlParamsWithValues(this.resourcePath, urlParameters);
            const response = await this.apiService.get(url);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

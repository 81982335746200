import AbstractFilterableResource from './AbstractFilterableResource';

class Order extends AbstractFilterableResource {
    async cancelOrder(orderId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${orderId}/cancel`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async forceCancelOrder(orderId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${orderId}/force-cancel`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async closeOrder(orderId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${orderId}/close`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new Order('/order');

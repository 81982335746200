import { BrowserService } from '@/services/Browser/BrowserService';
import Toaster from '@/services/Toaster';
import { useCreateOrderByGenericPosition } from './useCreateGenericOrderByGenericPosition';
import { useCallback } from 'react';
import { useVuexGetter } from '@/reactBridge/useVuex';
import store from '@/store';
import { useTranslation } from 'react-i18next';
export const useCreateGenericOrderByPositionAndForward = (positionId) => {
    const { t } = useTranslation();
    const genericOrderUrl = useVuexGetter(store, useCallback(getters => getters['platform/genericOrderUrl'], []));
    const createGenericOrder = useCreateOrderByGenericPosition(positionId);
    return {
        isLoading: createGenericOrder.isLoading,
        mutateAsync: async () => {
            try {
                const order = await createGenericOrder.mutateAsync();
                BrowserService.open(`${genericOrderUrl}/order/${order.id}`);
            }
            catch (error) {
                Toaster.error(t('pages.genericPosition.details.orderCreationError'));
            }
        },
    };
};

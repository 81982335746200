import { MutationCache, QueryCache } from '@tanstack/query-core';
import { QueryClient } from '@tanstack/react-query';
import Ability from '@/services/Api/Ability';
import Authenticator from '@/services/Api/Authenticator';
import ProjectApi from '@/services/Api/Project';
import PlatformApi from '@/services/Api/Platform/Utils';
import NetsuiteApi from '@/services/Api/NetSuite';
import OrganizationApi from '@/services/Api/Organization';
// Mutation and Query caches are shared between Vue and React
export const mutationCache = new MutationCache();
export const queryCache = new QueryCache();
// This query client is only used for React components
export const queryClient = new QueryClient({
    mutationCache,
    queryCache,
});
export const platformInfoQuery = {
    queryKey: ['platform', 'info'],
    queryFn: () => PlatformApi.getPlatformInfo(),
    cacheTime: Infinity,
};
export const abilitiesQuery = {
    queryKey: ['abilities'],
    queryFn: () => Ability.getAll(),
};
export const userInfoQuery = {
    queryKey: ['user', 'info'],
    queryFn: () => Authenticator.requestUserInfo(),
};
export const projectsKeys = {
    namespace: ['projects'],
    byId: (id) => [...projectsKeys.namespace, id],
};
export const netsuitePaymentTermsQuery = {
    queryKey: ['organization', 'paymentTerms'],
    queryFn: () => NetsuiteApi.getNetSuitePaymentTerms(),
};
export const organizationByIdQuery = (id) => ({
    queryKey: ['organization', id],
    queryFn: () => OrganizationApi.getOneById(id),
});
export const projectByIdQuery = (id) => ({
    queryKey: projectsKeys.byId(id),
    queryFn: () => ProjectApi.getOneById(id),
});
export async function prefetchQueries() {
    await Promise.allSettled([
        queryClient.prefetchQuery(abilitiesQuery),
        queryClient.prefetchQuery(userInfoQuery),
        queryClient.prefetchQuery(platformInfoQuery),
    ]);
}

import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { SearchIcon } from '@schuettflix/icons-react';
import { TextField } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
import { SellerProductList } from './SellerProductList';
export const SellerProductsSection = ({ items, className, onSelect }) => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState(false);
    const selectProduct = (product, masterProductId) => {
        if (isLoading) {
            return;
        }
        setLoading(true);
        onSelect?.({ ...product, masterProductId });
    };
    const filteredProducts = useMemo(() => items.map(masterProduct => ({
        ...masterProduct,
        products: masterProduct.products.filter(product => product.name.toLowerCase().includes(searchTerm.toLowerCase())),
    })), [items, searchTerm]);
    return (<div className={clsx(className)}>
            <div>
                <TextField value={searchTerm} label={t('pages.checkout.supplierProfilePage.productSearch.label')} className="w-full pb-6" disabled={isLoading} leadingIcon={props => <SearchIcon {...props}/>} onChange={event => setSearchTerm(event.target.value)} data-test="supplier-product-search"/>
                {filteredProducts?.map(masterProduct => masterProduct.products.length === 0 ? null : (<div key={masterProduct.id}>
                            <div className="font-copy-lg-strong mb-[20px] pt-2">{masterProduct.label}</div>
                            <SellerProductList products={masterProduct.products} masterProductLabel={masterProduct.label} isLoading={isLoading} onSelect={product => {
                selectProduct(product, masterProduct.id);
            }}/>
                        </div>))}
            </div>
        </div>);
};

import { useRouteParams } from '@/reactBridge/useRouteParams';
import clsx from 'clsx';
import { useConstructionProjectAdminFavorite } from '@/modules/constructionProjects/hooks/useConstructionProjectFavorite';
import { StarFilledIcon } from '@/constructionProjects/components/icons/StarFilledIcon';
import { StarIcon } from '@/constructionProjects/components/icons/StarIcon';
function FavoriteStar({ isFavorite, activeClass = 'fill-icon', className, onClick }) {
    return (<button onClick={onClick} className={clsx('grid h-[40px] w-[40px] place-content-center rounded-full bg-transparent hover:bg-surface-hovered', className)}>
            {isFavorite ? (<StarFilledIcon data-test="construction-project-details-page-star-icon" className={clsx('cursor-pointer', activeClass)} size={24}/>) : (<StarIcon data-test="construction-project-details-page-star-icon" className="cursor-pointer stroke-icon" size={24}/>)}
        </button>);
}
export function FavoriteAdminStar(props) {
    const { id: constructionProjectId } = useRouteParams();
    const { isFavorite, toggleFavorite } = useConstructionProjectAdminFavorite(constructionProjectId);
    return <FavoriteStar isFavorite={isFavorite} onClick={toggleFavorite} {...props}/>;
}

<template>
    <div
        :class="[
            'draggable-toggle-switch',
            `draggable-toggle-switch--color-${color}`,
            {
                'draggable-toggle-switch--active': value,
                'draggable-toggle-switch--disabled': disabled,
                'draggable-toggle-switch--small': small,
                'draggable-toggle-switch--dark-mode': darkMode,
            },
        ]"
        data-test="draggable-toggle-switch"
        @click.stop="!disabled && $emit('input', !value)"
    >
        <div class="draggable-toggle-switch__track">
            <DraggableContainer
                :value="!value"
                :animation-speed="0.9"
                :disabled="disabled"
                horizontal
                @input="handleInput(!$event)"
            >
                <div class="draggable-toggle-switch__orb" />
            </DraggableContainer>
        </div>
    </div>
</template>

<script>
import DraggableContainer from '@/components/Draggable/DraggableContainer';

export default {
    name: 'ToggleSwitch',
    components: {
        DraggableContainer,
    },
    props: {
        name: {
            type: String,
            default: null,
        },
        value: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: 'green',
            validator: v => ['red', 'green'].includes(v),
        },
        darkMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            eid: `el${this._uid}`,
        };
    },
    methods: {
        handleInput(value) {
            if (value !== this.value) {
                this.$emit('input', value);
            }
        },
    },
};
</script>

<style lang="scss">
.draggable-toggle-switch {
    position: relative;
    min-height: 32px;

    display: inline-block;
    vertical-align: middle;
    top: -1px;

    width: 64px;
    height: 32px;
    border: 1px solid $color-darkGrey;
    border-radius: 16px;
    transition:
        border 0.2s ease-in,
        background-color 0.2s ease-in;
    background-color: $color-darkGrey;

    &--dark-mode {
        background-color: #1f1920;
    }

    &__track {
        position: relative;
        margin: 1px;
        width: 64px;
        height: 27px;
    }

    &__orb {
        background-color: $color-white;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }

    &--disabled {
        background-color: $color-darkGrey;
        border-color: $color-darkGrey;
    }

    &--disabled &__orb {
        background-color: $color-white;
    }

    &--small {
        min-height: 16px;
        width: 36px;
        height: 16px;

        .draggable__handle {
            position: relative;
            top: -7px;
            left: -3px;
        }
    }

    &--small &__track {
        width: 32px;
        height: 20px;
    }

    &--small &__orb {
        width: 24px;
        height: 24px;
    }

    &--active {
        background-color: $color-toggle;
        border-color: $color-toggle;
    }

    &--color-green#{&}--disabled {
        background-color: $color-lightGrey-400;
        border-color: $color-lightGrey-400;
    }

    &--active#{&}--small .draggable__handle {
        left: auto;
        right: -6px;
    }
}
</style>

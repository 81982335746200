<template>
    <LayoutPage is-flyout :screen-name="screenName" class="checkout-constructionSite-selection">
        <FlyoutHeader
            slot="flyoutHeader"
            :headline="
                !isDisposal
                    ? $t('pages.checkout.constructionProjectLocationSelection.title')
                    : $t('pages.checkout.constructionProjectLocationSelection.disposalTitle')
            "
        />
        <ReactBridge
            v-if="
                constructionSites &&
                constructionSites.count === 0 &&
                !isPlatformAdministrator &&
                !$can('createConstructionProject')
            "
            :react-component="CreateLocationMissingPermission"
            class="h-full"
        />

        <div
            v-else
            ref="constructionSiteSelectionRef"
            data-test="construction-site-selection-page"
            :class="{ 'mt-6': !$can('requestPhoneOrder') }"
            class="checkout-constructionSite-selection__content container-off-canvas-sm mb-6"
        >
            <portal-target name="checkoutGuideConstructionSiteSelectionMessage" />

            <p v-if="isCustom" class="font-copy-md mb-6">
                {{ $t('pages.checkout.constructionProjectLocationSelection.custom.description') }}
            </p>

            <FilterBox
                ref="constructionSiteSelectionFilter"
                v-model="filter"
                data-test="construction-site-filter"
                class="checkout-constructionSite-selection__filter"
                :class="{
                    'checkout-constructionSite-selection__filter-sticky': showHeaderFilter,
                }"
                :default-filter="filter"
                :endpoint="dataEndpoint"
                inline-mode
                @update="refreshList()"
            >
                <template #default="filterScope">
                    <TextField
                        v-model="filterScope.filter.search"
                        :label="
                            !isDisposal
                                ? $t('pages.checkout.constructionProjectLocationSelection.search')
                                : $t('pages.checkout.constructionProjectLocationSelection.disposalSearch')
                        "
                    >
                        <template #icon><SearchIcon /></template>
                    </TextField>
                </template>
            </FilterBox>

            <BaseButton
                v-if="$can('createConstructionSite') || isPlatformAdministrator"
                class="mb-4"
                data-test="add-new-unloading-point-button"
                @click="openCreateFlyout"
            >
                {{ $t('pages.checkout.constructionProjectLocationSelection.createLocation.button') }}
                <template #left>
                    <PlusIcon class="icon--inline" />
                </template>
            </BaseButton>

            <transition name="fade" mode="out-in">
                <LoadingCard v-if="!constructionSites && isLoading" />
                <List v-else-if="constructionSites && constructionSites.count > 0" class="bg-transparent">
                    <button
                        v-for="(constructionSite, index) in constructionSites.items"
                        :key="index"
                        class="card card--hovered card--focussed mb-4 w-full text-left"
                        :class="[
                            {
                                'card--selected !cursor-pointer': selectedConstructionSite === constructionSite.id,
                            },
                        ]"
                        :data-test="`project-position-waste-details-factory-${index}`"
                        @click="selectConstructionSite(constructionSite)"
                        @keyup.enter="selectConstructionSite(constructionSite)"
                    >
                        <ConstructionSiteItem :item="constructionSite" data-test="construction-site-selection-item" />
                    </button>

                    <MoreResultsButton
                        v-if="constructionSites.count - constructionSites.items.length > 0"
                        :result="constructionSites"
                        fade-out
                    />
                </List>

                <ReactBridge
                    v-if="constructionSites && constructionSites.count === 0 && !isPlatformAdministrator"
                    :react-component="LocationEmptyState"
                    :props="{
                        constructionProjectId: constructionProjectId,
                        onCreateLocationClick: onCreateLocationFromDraft,
                    }"
                    class="h-full"
                />
            </transition>

            <p
                v-if="!$can('createConstructionSite') && !isCustom"
                class="font-copy-sm text-subdued"
                v-html="$t('pages.checkout.constructionProjectLocationSelection.notice')"
            />

            <Flyout
                :active="isCreateOpen"
                :headline="$t('pages.checkout.constructionProjectLocationSelection.createLocationFlyout.title')"
                screen-name="client-location-create"
                no-spacer
                size="small"
                @closed="closeCreateFlyout"
            >
                <ReactBridge
                    :react-component="CreateLocation"
                    :props="{
                        constructionProjectId,
                        onCreate: onLocationCreate,
                        locationDraft,
                        onCancel: () => {
                            isCreateOpen = false;
                        },
                    }"
                    class="h-full"
                />
            </Flyout>
        </div>
    </LayoutPage>
</template>

<script>
import LayoutPage from '@/components/Layout/Page.v2';
import FlyoutHeader from './components/Header';
import BaseButton from '@/components/Button/Button';
import Flyout from '@/components/Layout/Flyout';
import List from '@/components/List/List';
import ConstructionSiteItem from '@/components/List/ConstructionSiteItem';
import MoreResultsButton from '@/components/Filter/MoreResultsButton';
import FilterBox from '@/components/Filter/FilterBox';
import TextField from '@/components/Form/TextField.v2';
import { CreateLocation } from '@/constructionProjects/components/CreateLocation';
import ReactBridge from '@/reactBridge/ReactBridge';

import PlusIcon from '@/assets/icons/regular/plus.svg';
import SearchIcon from '@/assets/icons/micro/search.svg';

import ConstructionSiteApi from '@/services/Api/ConstructionSite';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import Toaster from '@/services/Toaster';
import { mapGetters, mapState } from 'vuex';
import { trackCheckoutEvent } from './trackCheckoutEvent';

import LoadingCard from '@/pages/Checkout/Disposal/ProjectPosition/Partials/LoadingCard.vue';

import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import {
    MPS_PLATFORM_ORDER_CUSTOM_DELIVERY_CONSTRUCTION_SITES,
    MPS_PLATFORM_ORDER_PROJECT_DELIVERY_CONSTRUCTION_SITES,
    MPS_CUSTOMER_ORDER_DELIVERY_CONSTRUCTION_SITES,
    MPS_CUSTOMER_ORDER_PICKUP_CONSTRUCTION_SITES,
} from '@/constants/marketPermissions';
import { LocationEmptyState } from '@/constructionProjects/components/LocationEmptyState';
import { CreateLocationMissingPermission } from '@/constructionProjects/components/CreateLocationMissingPermission';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { CONSTRUCTION_PROJECT_DETAILS_ROUTE } from '@/constructionProjects/constants';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';

export default {
    name: 'ConstructionSiteSelectionPage',
    components: {
        LayoutPage,
        FlyoutHeader,
        BaseButton,
        Flyout,
        List,
        PlusIcon,
        ConstructionSiteItem,
        MoreResultsButton,
        FilterBox,
        TextField,
        SearchIcon,
        LoadingCard,
        ReactBridge,
    },
    mixins: [persistentFiltersMixin],
    setup() {
        return {
            screenName: useOrderScreenName('locationselection'),
        };
    },
    data() {
        return {
            constructionSites: null,
            isLoading: false,
            cancelSource: null,
            dataEndpoint: ConstructionSiteApi,
            filter: this.assembleFilter('constructionSite', {
                page: 1,
                perPage: 25,
                isActive: 1,
            }),
            defaultFilter: {
                page: 1,
                perPage: 25,
                isActive: 1,
            },
            isCreateOpen: false,
            showHeaderFilter: false,
            filterElementPosition: 0,
            locationDraft: null,
            CreateLocation,
            LocationEmptyState,
            CreateLocationMissingPermission,
        };
    },
    computed: {
        ...mapGetters('user', ['isPlatformAdministrator']),
        ...mapState('basket', ['clientInfo', 'isCustom']),
        ...mapState('basket', {
            typeFromBasket: 'type',
            constructionSiteFromBasket: 'constructionSite',
            projectIdFromBasket: 'projectId',
        }),
        ...mapState('constructionProject', ['constructionProjectId']),
        hideMessages() {
            return !!this.filter.search && this.showHeaderFilter;
        },
        selectedConstructionSite() {
            return this.constructionSiteFromBasket?.id || -1;
        },
        showInactiveProjects() {
            return [
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DELIVERY,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS,
            ].includes(this.typeFromBasket);
        },
        isDisposal() {
            return [
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS,
            ].includes(this.typeFromBasket);
        },
        operations() {
            if (this.isCustom && this.typeFromBasket === 'delivery') {
                return MPS_PLATFORM_ORDER_CUSTOM_DELIVERY_CONSTRUCTION_SITES;
            }
            if (this.isCustom && this.typeFromBasket === 'project-delivery') {
                return MPS_PLATFORM_ORDER_PROJECT_DELIVERY_CONSTRUCTION_SITES;
            }
            if (!this.isCustom && this.typeFromBasket === 'delivery') {
                return MPS_CUSTOMER_ORDER_DELIVERY_CONSTRUCTION_SITES;
            }
            if (!this.isCustom && this.typeFromBasket === 'pickup') {
                return MPS_CUSTOMER_ORDER_PICKUP_CONSTRUCTION_SITES;
            }
            return null;
        },
    },
    created() {
        this.refreshList(true);
    },
    mounted() {
        const { offsetTop } = this.$refs.constructionSiteSelectionFilter.$el;
        this.filterElementPosition = offsetTop;

        this.$refs.constructionSiteSelectionRef.parentElement.addEventListener(
            'scroll',
            this.setHeaderFilterVisibility
        );
    },
    methods: {
        selectConstructionSite(constructionSite) {
            const { supervisor } = constructionSite;

            // The supervisor is probably deleted
            if (supervisor.firstName === 'Gelöschter' && supervisor.lastName === 'Benutzer' && !supervisor.isActive) {
                Toaster.info(this.$t('pages.constructionSite.constructionSiteList.changeSupervisorErrorMessage'), {
                    actionCallback: () => {
                        this.$router
                            .push({
                                name: CONSTRUCTION_PROJECT_DETAILS_ROUTE,
                                params: { id: this.constructionProjectId },
                            })
                            .catch(() => {});
                    },
                    actionLabel: this.$t('pages.constructionSite.constructionSiteList.changeSupervisorErrorAction'),
                });
                return;
            }

            trackCheckoutEvent('constructionSite', 'select', {
                position: this.constructionSites.items.indexOf(constructionSite) + 1,
            });

            this.$store.commit('basket/setConstructionSite', constructionSite);

            if (this.isDisposal) {
                this.$store.commit('projectPosition/SET_PROJECT_POSITION_CONSTRUCTION_SITE', constructionSite);
                this.$store.commit('projectPosition/SET_PROJECT_POSITION_CLIENT_NAME', this.clientInfo.client.name);
            }

            this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(() => {});
        },

        onCreateLocationFromDraft(draft) {
            this.locationDraft = draft;
            this.openCreateFlyout();
        },

        openCreateFlyout() {
            this.isCreateOpen = true;
            AnalyticsService.trackEvent('construction_project', {
                step: 'location_addform_opened',
                constructionProjectId: this.constructionProjectId,
                funnelType: this.typeFromBasket,
            });
        },

        onLocationCreate() {
            AnalyticsService.trackEvent('construction_project', {
                step: 'location_added',
                constructionProjectId: this.constructionProjectId,
                funnelType: this.typeFromBasket,
            });
            // TODO remove setTimeout as soon as location list is used instead of construction site list
            setTimeout(this.closeCreateFlyout, 1000);
        },

        closeCreateFlyout() {
            this.isCreateOpen = false;
            this.locationDraft = null;
            this.refreshList();
        },

        async refreshList(isInitial = false) {
            this.isLoading = true;

            this.persistFilter('constructionSite', this.filter, this.defaultFilter);

            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.cancelSource = ConstructionSiteApi.createCancelTokenSource();

            try {
                const additionalFilter = {};
                if (this.isCustom) {
                    additionalFilter.clientOrganization = this.clientInfo.client.id;
                }
                if (this.constructionProjectId) {
                    additionalFilter.constructionProject = this.constructionProjectId;
                }
                if (this.showInactiveProjects) {
                    additionalFilter.isActive = null;
                }

                const result = await ConstructionSiteApi.filter(
                    {
                        ...this.filter,
                        ...additionalFilter,
                        operations: this.operations,
                    },
                    null,
                    null,
                    this.cancelSource
                );

                this.constructionSites = result;

                if (isInitial === true) {
                    this.filter = {
                        ...this.filter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                    Toaster.error(err.message);
                }
            }

            this.isLoading = false;
        },

        setHeaderFilterVisibility() {
            const { scrollTop } = this.$refs.constructionSiteSelectionRef.parentElement;

            if (scrollTop > this.filterElementPosition) {
                this.showHeaderFilter = true;
            } else {
                this.showHeaderFilter = false;
            }
        },
    },
};
</script>

<style lang="scss">
.checkout-constructionSite-selection {
    background-color: $color-lightMediumGrey;
}

.checkout-constructionSite-selection__container {
    margin-left: 15px;
    margin-right: 15px;
}

.checkout-constructionSite-selection__filter {
    margin-bottom: 20px;

    .filter-box__body {
        padding: 0px;
    }
}

.checkout-constructionSite-selection__inline-filter {
    background-color: $color-white;
    box-shadow: $boxShadow-bottomShort;
    margin-bottom: 30px;

    .filter-box__body {
        display: flex;
        flex-flow: row nowrap;
        padding: 7px 0 0 10px;

        .input-field {
            flex-grow: 1;
        }
    }

    .filter-bar__refresh {
        border-right: 0;
    }

    .filter-bar__refresh,
    .filter-bar__actions {
        .button:not(:disabled) {
            background-color: transparent;
        }
    }
}

.checkout-constructionSite-selection__filter-sticky {
    background-color: $color-white;
    position: sticky;
    top: 0px;
    z-index: 1000;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
}
</style>

<template>
    <div class="feature-setting-bool-field-type">
        <TextField :value="value" type="number" step="0.001" :label="setting.label" @input="update" />
        <div v-if="setting.default !== undefined">
            <Words block tiny>
                {{ $t('pages.organization.featureSettingsFieldTypes.numberFieldType.defaultValue') }}
            </Words>
            <Words block bold>{{ $n(setting.default) }}</Words>
        </div>
    </div>
</template>

<script>
import TextField from '@/components/Form/TextField.v2';
import Words from '@/components/Typography/Words';

export default {
    name: 'NumberFieldType',
    components: {
        TextField,
        Words,
    },
    props: {
        value: {
            type: Number,
            default: null,
        },
        setting: {
            type: Object,
            required: true,
        },
    },
    methods: {
        update(value) {
            let nextValue;
            const { type } = this.setting;

            if (value === null || value === '') {
                nextValue = null;
            } else if (type === 'float') {
                nextValue = parseFloat(value);
            } else {
                nextValue = parseInt(parseFloat(value));
            }

            this.$emit('input', nextValue);
        },
    },
};
</script>

<style lang="scss">
.feature-setting-bool-field-type {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    align-items: center;
}
</style>

<template>
    <transition name="fade">
        <div
            v-if="options.active"
            class="fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-black/50 p-4"
        >
            <div class="relative w-full max-w-[646px] bg-white md:m-0" data-test="dialog-modal-content-wrapper">
                <Close
                    v-if="options.closeWithCross"
                    class="absolute right-4 top-4 z-10 h-6 w-6 cursor-pointer md:right-6 md:top-6"
                    @click="closeModal"
                />
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import Close from '@/assets/icons/regular/close_24.svg';

export default {
    name: 'DialogModal',
    components: {
        Close,
    },
    mixins: [eventHubMixin],
    props: {
        options: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        this.$eventHub.$emit('dialogModalStatus', true);
    },
    methods: {
        closeModal() {
            this.$emit('close');
            this.$eventHub.$emit('dialogModalStatus', false);
        },
    },
};
</script>

<style lang="scss" scoped>
.dialog-modal {
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
}
</style>

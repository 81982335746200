import FactoryDraftsApi from '@/services/Api/FactoryDrafts';

class FactoryDraftsService {
    async save(factoryDraft) {
        return FactoryDraftsApi.save(factoryDraft);
    }

    async put(factoryId, routeName, factoryDraft) {
        return FactoryDraftsApi.put(factoryId, routeName, factoryDraft);
    }

    async publish(factoryId) {
        return FactoryDraftsApi.put(factoryId, 'publish');
    }
}

export default new FactoryDraftsService();

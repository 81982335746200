import NotificationsPage from './NotificationsPage';
import NotificationTestPage from './NotificationTestPage';
import NotificationApi from '@/services/Api/Notification';
import DeepLinkService from '@/services/Notification/DeepLinkService';
import Toaster from '@/services/Toaster';
import i18n from '@/i18n';
import store from '@/store';

export default [
    {
        path: '/notifications',
        name: 'notifications',
        component: NotificationsPage,
        meta: {
            title: 'pages.notifications.title',
            noBackArrow: true,
        },
    },
    {
        path: '/notificationTest',
        name: 'notificationTest',
        component: NotificationTestPage,
    },
    {
        // Legacy route for old notification links, can be removed in the future
        path: '/notification/:notificationId',
        name: 'notificationsLinkLegacy',
        beforeEnter: async ({ params: { notificationId } }, from, next) => {
            try {
                const notification = await NotificationApi.getOneById(notificationId);
                const target = DeepLinkService.getRouteFromMessage(notification);
                next(target);
            } catch ({ message }) {
                Toaster.error(i18n.t(message));
                next({ name: 'home' });
            }
        },
    },
    {
        path: '/notification/:notificationId/user/:userId',
        name: 'notificationsLink',
        beforeEnter: async ({ params: { notificationId, userId } }, from, next) => {
            try {
                await store.dispatch('user/changeUser', userId);

                const notification = await NotificationApi.getOneById(notificationId);
                const target = DeepLinkService.getRouteFromMessage(notification);
                next(target);
            } catch ({ message }) {
                if (message === 'userLink.errors.notFound') {
                    Toaster.error(i18n.t('pushnotification.errors.forbidden'));
                } else {
                    Toaster.error(i18n.t(message));
                }
                next({ name: 'home' });
            }
        },
    },
];

import AbstractFilterableResource from './AbstractFilterableResource';

export default class FilterableFactory extends AbstractFilterableResource {
    constructor(factoryId, resourcePath = '/factory', messages = null) {
        super(resourcePath, messages);

        this.factoryId = factoryId;
        this.resourcePathBase = `${resourcePath}/${factoryId}`;
        this.resourcePath = `${this.resourcePathBase}/products/waste`;
    }

    async getWasteProduct(productId, params = null) {
        try {
            const response = await this.apiService.get(`${this.resourcePathBase}/product/${productId}`, params);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async getWasteProductVariants(productId) {
        try {
            const response = await this.apiService.get(`${this.resourcePathBase}/product/${productId}/waste/variants`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async getActiveWasteProductVariants(factoryId, productId) {
        try {
            const response = await this.apiService.get(
                `order/factory/${factoryId}/product/${productId}/waste/variants`
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async createWasteProductVariant(productId, variant = {}) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePathBase}/product/${productId}/waste/variant`,
                variant
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async updateWasteProductVariants(productId, variants = []) {
        try {
            const response = await this.apiService.patch(
                `${this.resourcePathBase}/product/${productId}/waste/variants`,
                {
                    items: variants,
                }
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async removeWasteProductVariants(variants = [], productId) {
        try {
            const response = await this.apiService.delete(
                `${this.resourcePathBase}/product/${productId}/waste/variants`,
                {
                    items: variants,
                }
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async getPollutionTypesForWasteProduct(productId) {
        try {
            const response = await this.apiService.get(
                `${this.resourcePathBase}/product/${productId}/waste/pollutiontypes`
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    setResourcePathForFetchingFactoryWasteProducts() {
        this.resourcePath = `order/factory/${this.factoryId}/products/waste`;
    }
}

import LocationView from '@/views/LocationView/LocationView';
import LocationList from '@/views/LocationList/LocationList';

export default {
    path: 'factory-management',
    name: 'factory-management',
    component: LocationList,
    meta: {
        requiredAbilities: ['haveFactoryManagement'],
    },
    children: [
        {
            path: 'factory/:factoryId',
            name: 'factory',
            component: LocationView,
            props: true,
            children: [
                {
                    path: 'edit',
                    name: 'factoryEdit',
                    component: LocationView,
                    props: true,
                    meta: {
                        type: 'factoryEdit',
                    },
                },
            ],
        },
        {
            path: 'factory-draft/:factoryId',
            name: 'factoryDraft',
            component: LocationView,
            props: true,
            children: [
                {
                    path: 'edit',
                    name: 'factoryDraftEdit',
                    component: LocationView,
                    props: true,
                    meta: {
                        type: 'factoryDraftEdit',
                    },
                },
            ],
        },
    ],
};

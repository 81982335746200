import { getDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
export const useWeekDays = () => {
    const { t } = useTranslation();
    const weekDays = [
        { key: 1, name: t('pages.checkout.pickupSupplierSelection.supplierInfo.weekDaysName.monday') },
        { key: 2, name: t('pages.checkout.pickupSupplierSelection.supplierInfo.weekDaysName.tuesday') },
        { key: 3, name: t('pages.checkout.pickupSupplierSelection.supplierInfo.weekDaysName.wednesday') },
        { key: 4, name: t('pages.checkout.pickupSupplierSelection.supplierInfo.weekDaysName.thursday') },
        { key: 5, name: t('pages.checkout.pickupSupplierSelection.supplierInfo.weekDaysName.friday') },
        { key: 6, name: t('pages.checkout.pickupSupplierSelection.supplierInfo.weekDaysName.saturday') },
        { key: 7, name: t('pages.checkout.pickupSupplierSelection.supplierInfo.weekDaysName.sunday') },
    ];
    const getWeekDayName = (key) => {
        const weekDay = weekDays.find(day => day.key === key);
        return weekDay?.name || null;
    };
    const getCurrentWeekDayNumber = () => {
        const weekDay = getDay(new Date());
        return weekDay === 0 ? 7 : weekDay;
    };
    return { getWeekDayName, weekDays, getCurrentWeekDayNumber };
};

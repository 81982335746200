<template>
    <Words :block="block" :bold="bold" :large="large" :x-large="xLarge" class="waste-code">{{ label }}</Words>
</template>

<script>
import { codeLabel } from '../utility/waste';
import Words from '@/components/Typography/Words';
export default {
    name: 'WasteCode',
    components: {
        Words,
    },
    props: {
        code: {
            type: String,
            default: '',
        },
        isDangerous: {
            type: Boolean,
            default: false,
        },
        block: {
            type: Boolean,
            default: false,
        },
        bold: {
            type: Boolean,
            default: false,
        },
        large: {
            type: Boolean,
            default: false,
        },
        xLarge: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        label() {
            const codeLabelResult = codeLabel(this.code, this.isDangerous);
            return codeLabelResult ? codeLabelResult : '';
        },
    },
};
</script>

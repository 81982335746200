<template>
    <div>
        <ModalBox
            ref="paymentInfoChangedDirectDebitEnabledModalRef"
            optional-dialog
            :border="true"
            has-large-headline
            :headline="$t('components.organizationForm.paymentInfo.modal.paymentInfo.title')"
            v-on="$listeners"
        >
            <Words block>
                {{ $t('components.organizationForm.paymentInfo.modal.paymentInfo.description.directDebitEnabled') }}
            </Words>
            <PaymentInfoChangeModal
                slot="actions"
                @close="resetChanges('paymentInfoChangedDirectDebitEnabledModalRef')"
                @confirm="closeModalAndSave('paymentInfoChangedDirectDebitEnabledModalRef')"
            />
        </ModalBox>
        <ModalBox
            ref="paymentInfoChangedDirectDebitDisabledModalRef"
            optional-dialog
            :border="true"
            has-large-headline
            :headline="$t('components.organizationForm.paymentInfo.modal.paymentMethod.title')"
        >
            <Words block>
                {{ $t('components.organizationForm.paymentInfo.modal.paymentInfo.description.directDebitDisabled') }}
            </Words>
            <PaymentInfoChangeModal
                slot="actions"
                @close="resetChanges('paymentInfoChangedDirectDebitDisabledModalRef')"
                @confirm="resetToInvoice('paymentInfoChangedDirectDebitDisabledModalRef')"
            />
        </ModalBox>
        <ModalBox
            ref="returnToInvoiceModalRef"
            optional-dialog
            :border="true"
            has-large-headline
            :headline="$t('components.organizationForm.paymentInfo.modal.paymentMethod.title')"
        >
            <Words spaced-bottom spaced-top>
                {{ $t('components.organizationForm.paymentInfo.modal.paymentMethod.description') }}
            </Words>
            <PaymentMethodChangeModal
                slot="actions"
                @close="resetToDirectDebit('returnToInvoiceModalRef')"
                @confirm="resetMandateAndSave('returnToInvoiceModalRef')"
            />
        </ModalBox>
    </div>
</template>

<script>
import { PAYMENT_METHODS } from '@/constants/paymentMethods';
import PaymentMethodChangeModal from '@/pages/Organization/partials/TabPaymentInfo/PaymentMethodChangeModal';
import PaymentInfoChangeModal from '@/pages/Organization/partials/TabPaymentInfo/PaymentInfoChangeModal';
import ModalBox from '@/components/Modal/ModalBox';
import Words from '@/components/Typography/Words';
import _cloneDeep from 'lodash/cloneDeep';

export default {
    name: 'PaymentInfoModalWrapper',
    components: {
        PaymentMethodChangeModal,
        PaymentInfoChangeModal,
        ModalBox,
        Words,
    },
    props: {
        organization: {
            type: Object,
            default: null,
        },
        originalOrganization: {
            type: Object,
            default: null,
        },
        modalRefToTrigger: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            updatedOrganization: null,
        };
    },
    watch: {
        modalRefToTrigger(newValue) {
            if (newValue) {
                this.$refs[this.modalRefToTrigger].$emit('open');
            }
        },
    },
    methods: {
        closeModal(modalRef) {
            this.$refs[modalRef].$emit('close');
            this.$emit('block-save-by-modal', { modalRefToTrigger: modalRef, blocked: false });
        },
        closeModalAndSave(modal) {
            this.closeModal(modal);
            this.$emit('save-from-modal');
        },
        resetChanges(modal) {
            this.closeModal(modal);
            this.$emit('update-organization', this.originalOrganization);
        },
        resetToDirectDebit(modalRef) {
            this.closeModal(modalRef);
            this.updatedOrganization = _cloneDeep(this.organization);
            this.updatedOrganization.paymentInfo.method = PAYMENT_METHODS.DIRECT_DEBIT;
            this.$emit('update-organization', this.updatedOrganization);
        },
        resetToInvoice(modalRef) {
            this.closeModal(modalRef);
            this.updatedOrganization = _cloneDeep(this.organization);
            this.updatedOrganization.paymentInfo.method = PAYMENT_METHODS.INVOICE;
            this.$emit('update-organization', this.updatedOrganization);
            this.$emit('save-from-modal');
        },
        resetMandateAndSave(modalRef) {
            this.updatedOrganization = _cloneDeep(this.organization);
            delete this.updatedOrganization.mandateReference;
            this.$emit('update-organization', this.updatedOrganization);
            this.closeModalAndSave(modalRef);
        },
    },
};
</script>

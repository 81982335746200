<template>
    <div class="project-position-list">
        <div class="project-position-list__hd">
            <Words class="project-position-list__headline" block bold>
                {{ $tc('pages.project.position.list.countLabel', count) }}
            </Words>
        </div>
        <transition name="fade" mode="out-in">
            <LoadingSpinner v-if="isLoading" block dark />
            <template v-else>
                <div v-if="count">
                    <template v-for="(projectPosition, i) in projectPositions">
                        <Card
                            v-if="showFacet(i)"
                            :key="`${projectPosition.id}--project`"
                            :spaceless-x="$root.isDesktop"
                            dark
                        >
                            <div class="project-position-list__facet">
                                <Words
                                    small
                                    bold
                                    class="project-position-list__cell project-position-list__cell--devided"
                                >
                                    {{ projectPosition.project.number }}
                                </Words>
                                <Words small class="project-position-list__cell">
                                    {{ projectPosition.project.name }}
                                </Words>

                                <Words>
                                    {{ $t('pages.project.position.list.validityLabel') }}:
                                    {{ projectPosition.project.validityRange }}
                                </Words>
                            </div>
                        </Card>
                        <ProjectPositionListBlock
                            :key="`${projectPosition.id}--position`"
                            class="mb-8"
                            :project-position="projectPosition"
                            @showMorePrices="showMorePrices"
                            @openAnalysis="openAnalysis"
                        />
                    </template>
                    <slot name="footer" />
                </div>
                <Hint v-else class="project-position-list__hint" center transparent>
                    {{ $t('pages.project.position.list.noResults') }}
                </Hint>
            </template>
        </transition>

        <ModalBox ref="pricesModal" :headline="$t('pages.project.position.quotePrices.modal')" spaceless>
            <div v-if="costPreview" class="project-position-list__modal-section">
                <ProjectPositionCostPreview
                    :material="costPreview.material"
                    :transports="costPreview.transports"
                    :shipping="costPreview.shipping"
                    :billing-method="projectPositionBillingMethod"
                    show-purchase-prices
                    show-shipping-prices
                    :show-only-materials="isSupplier"
                />
            </div>

            <ProjectPositionAdditionalItems
                v-if="customPositions"
                class="project-position-list__modal-section"
                :custom-positions="customPositions"
                show-purchase-prices
            />

            <PaymentTerms
                v-if="hasPaymentTerms"
                class="px-[20px]"
                :payment-terms="projectPositionToShow.paymentTerms"
                :project-position-type="projectPositionToShow.type"
            />
        </ModalBox>
        <FlyoutComponent
            :route="analysisRoute"
            :screen-name="isSupplier ? 'seller-project-analysis' : 'carrier-project-analysis'"
            size="small"
        />
    </div>
</template>

<script>
import { PROJECT_POSITION_TYPE_DELIVERY } from '@/constants/projectPositionTypes';
import TransportPricingCollectionView from '@/pages/Checkout/components/PriceAdjustment/models/TransportPricingCollectionView';

import Card from '@/components/Layout/Card';
import Hint from '@/components/Typography/Hint';
import LoadingSpinner from '@/components/LoadingSpinner';
import MaterialPricingView from '@/pages/Checkout/components/PriceAdjustment/models/MaterialPricingView';
import ModalBox from '@/components/Modal/ModalBox';
import ProjectPositionAdditionalItems from '@/pages/Project/components/ProjectPositionAdditionalItems';
import PaymentTerms from '@/pages/Project/components/PaymentTerms';
import ProjectPositionCostPreview from '@/pages/Project/components/ProjectPositionView/ProjectPositionCostPreview';
import ProjectPositionListBlock from '@/components/Project/ProjectPositionListBlock';
import Words from '@/components/Typography/Words';
import { CONTEXT_SUPPLIER } from '@/constants/context';
import routeContext from '@/plugins/mixins/routeContext';
import FlyoutComponent from '@/components/Layout/Flyout';
import { useLd } from '@/services/LaunchDarkly';

export default {
    name: 'ProjectPositionList',
    components: {
        FlyoutComponent,
        Card,
        Hint,
        LoadingSpinner,
        ModalBox,
        ProjectPositionAdditionalItems,
        ProjectPositionCostPreview,
        ProjectPositionListBlock,
        Words,
        PaymentTerms,
    },
    mixins: [routeContext],
    props: {
        projectPositions: {
            type: Array,
            required: true,
        },
        count: {
            type: Number,
            default: 0,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            costPreview: null,
            customPositions: null,
            projectPositionBillingMethod: '',
            projectPositionType: null,
            projectPositionToShow: null,
            showPaymentTerms: useLd('payment-terms-modal'),
        };
    },

    computed: {
        isSupplier() {
            return this.inRouteContext(CONTEXT_SUPPLIER);
        },
        analysisRoute() {
            return this.$root.findRouteName('analysis');
        },
        hasPaymentTerms() {
            return this.projectPositionToShow?.paymentTerms?.length > 0;
        },
    },

    methods: {
        showFacet(index) {
            // First
            if (index === 0 && this.projectPositions.length > 0) return true;

            const prev = this.projectPositions[index - 1] || this.projectPositions[0];
            const curr = this.projectPositions[index];

            return prev.project?.id !== curr.project?.id;
        },

        /**
         * @param {ProjectPositionView} projectPosition
         */
        showMorePrices(projectPosition) {
            const projectPositionObj = projectPosition.unfold();
            this.projectPositionBillingMethod = projectPositionObj.billingMethod;

            this.costPreview = {
                material: MaterialPricingView.createFromProjectPositionPayload(projectPositionObj),
                transports: TransportPricingCollectionView.createFromVehicleClasses(projectPositionObj.vehicleClasses),
                shipping:
                    projectPosition.type === PROJECT_POSITION_TYPE_DELIVERY
                        ? projectPosition.quotationPrices.shipping
                        : null,
            };

            this.customPositions = projectPositionObj.customPositions?.filter(
                customPosition => customPosition.creditTo
            );

            this.projectPositionType = projectPosition.type;
            this.projectPositionToShow = projectPosition;
            this.$refs.pricesModal?.openModal();
        },

        openAnalysis(projectPosition) {
            this.$router.push({
                name: this.$root.findRouteName('analysis'),
                params: {
                    positionId: projectPosition.id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.project-position-list {
}

.project-position-list__hd {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    padding-right: 15px;
    padding-left: 15px;

    > * + * {
        margin-top: 30px;
    }

    @media screen and (min-width: $layout-desktop-min) {
        flex-direction: row-reverse;
        justify-content: space-between;
        padding-right: 0;
    }
}

.project-position-list__headline {
    margin-right: auto;
}

.project-position-list__hint {
    margin-top: 15px;
}

.project-position-list__facet {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 40px;

    @media screen and (max-width: 600px) {
        grid-template-columns: auto 1fr;
        row-gap: 15px;

        > :last-child {
            grid-column: 1/-1;
        }
    }
}

.project-position-list__cell {
    height: 100%;
    display: flex;
    align-items: center;
}

.project-position-list__cell--devided {
    padding-right: 20px;
    margin-right: -20px;
    border-right: 1px solid #fff;
}

.project-position-list__modal-section {
    padding: 20px;
    border-top: $border-solid-2px;
}
</style>

<template>
    <component
        :is="colspan ? 'td' : 'div'"
        v-if="resultCount > 0"
        :colspan="colspan"
        :class="{
            'more-results-button--fade-out': fadeOut,
            'more-results-button--table-cell': colspan,
        }"
        class="more-results-button"
    >
        <BaseButton v-if="showPaginationBtn" :disabled="result.isLoadingMore" light primary block @click="loadMore">
            <Words v-if="!result.isLoadingMore" bold>
                <PlusIcon width="10" height="10" class="icon--inline" />
                {{ $t('components.moreResultsButton.loadMore', { count: resultCount }) }}
            </Words>
            <span v-else>
                <LoadingSpinner />&nbsp;
                {{ $t('components.moreResultsButton.loading') }}
            </span>
        </BaseButton>
    </component>
</template>

<script>
import FilterResult from '@/services/Api/FilterResult';
import LoadingSpinner from '@/components/LoadingSpinner';
import BaseButton from '@/components/Button/Button';
import Words from '@/components/Typography/Words';
import PlusIcon from '@/assets/icons/micro/plus.svg';
import Toaster from '@/services/Toaster';

export default {
    name: 'MoreResultsButton',
    components: {
        LoadingSpinner,
        BaseButton,
        Words,
        PlusIcon,
        Toaster,
    },
    props: {
        fadeOut: {
            type: Boolean,
            default: false,
        },
        result: {
            type: FilterResult,
            required: true,
        },
        colspan: {
            type: Number,
            default: null,
        },
    },
    computed: {
        remainingResults() {
            return this.result.count - this.result.items.length;
        },
        resultCount() {
            return this.result.appliedFilter.perPage >= this.remainingResults
                ? this.remainingResults
                : this.result.appliedFilter.perPage;
        },
        showPaginationBtn() {
            return this.remainingResults > 0;
        },
    },
    methods: {
        async loadMore() {
            try {
                await this.result.loadMore();
            } catch (err) {
                Toaster.error(this.$t('pages.checkout.productSelection.temporaryUnavailable'));
            }
        },
    },
};
</script>

<style lang="scss">
.more-results-button {
    width: 100%;
}

.more-results-button--table-cell {
    padding: 0 !important;
}

.more-results-button--fade-out {
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 160px;
        bottom: 100%;
        position: absolute;
        pointer-events: none;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+1,1+75 */
        background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 1%,
            rgba(255, 255, 255, 1) 95%,
            rgba(255, 255, 255, 1) 100%
        );
    }
}
</style>

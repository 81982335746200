<template>
    <div class="email-list-fieldset">
        <div v-if="predefinedEmails && predefinedEmails.length" class="email-list-fieldset__predefined-emails">
            <Headline :level="6">{{ $t('pages.invoice.documentForm.recipients') }}</Headline>
            <Button
                v-for="recipient in predefinedEmails"
                :key="recipient"
                middle
                class="invoice-form__tag"
                @click.stop.prevent
            >
                <Words small>{{ recipient }}</Words>
            </Button>
        </div>

        <GridRow :count="6" class="email-list-fieldset__grid-row">
            <div class="span-6">
                <Words block small bold spaced-bottom>
                    {{
                        predefinedEmails && predefinedEmails.length
                            ? $t('pages.invoice.documentForm.additionalRecipients')
                            : $t('pages.invoice.documentForm.recipients')
                    }}
                </Words>
                <TextField
                    v-model="emailInput"
                    :error="getError('emailInput') || error"
                    :label="$t('pages.invoice.documentForm.emailLabel')"
                    :data-test="`${dataTest}-input`"
                    @keyup.enter.native="addEmail"
                />
            </div>
            <div class="invoice-form__tags" :class="$root.isDesktop ? 'span-4' : 'span-6'">
                <Button
                    v-for="recipient in value"
                    :key="recipient"
                    middle
                    class="invoice-form__tag"
                    @click="removeRecipient(recipient)"
                >
                    <Words small>{{ recipient }}</Words
                    ><DeleteIcon
                        v-if="!minListLength || (minListLength && minListLength < value.length)"
                        class="icon--inline email-list-fieldset__delete-icon"
                    />
                </Button>
            </div>
            <Button
                primary
                type="submit"
                class="invoice-form__submit-email"
                :class="$root.isDesktop ? 'span-2' : 'span-6'"
                :data-test="`${dataTest}-button`"
                @click="addEmail"
            >
                + {{ $t('pages.invoice.documentForm.actions.add') }}
            </Button>
        </GridRow>
    </div>
</template>

<script>
import _uniq from 'lodash/uniq';
import _pull from 'lodash/pull';
import { isEmail } from '@/services/validation/rules';
import validate from '@/services/validation/mixin';

import Headline from '@/components/Typography/Headline';
import Words from '@/components/Typography/Words';
import TextField from '@/components/Form/TextField.v2';
import GridRow from '@/components/Layout/GridRow';
import Button from '@/components/Button/Button';

import DeleteIcon from '@/assets/icons/micro/error.svg';

export default {
    name: 'EmailListFieldset',
    components: {
        Headline,
        Words,
        TextField,
        GridRow,
        Button,
        DeleteIcon,
    },
    mixins: [validate],
    props: {
        predefinedEmails: {
            type: Array,
            default: () => [],
        },
        minListLength: {
            type: Number,
            default: null,
        },
        dataTest: {
            type: String,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        value: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            emailInput: '',
        };
    },
    methods: {
        addEmail() {
            const emails = (this.emailInput || '')
                .split(',')
                .filter(v => v)
                .map(v => v.trim());

            const errors = emails
                .map(email => {
                    return isEmail()(email);
                })
                .filter(v => v !== true);

            if (errors.length !== 0) {
                this.$set(this.errors, 'emailInput', errors);
                return;
            }
            this.clearSingleValidation('emailInput');

            this.emailInput = '';
            const recipients = _uniq(this.value.concat(emails)).filter(v => !this.predefinedEmails.includes(v));

            this.$emit('input', recipients);
        },

        removeRecipient(email) {
            if (!this.minListLength || (this.minListLength && this.minListLength < this.value.length)) {
                const recipients = [...this.value];
                _pull(recipients, email);
                this.$emit('input', [...recipients]);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.email-list-fieldset__predefined-emails {
    margin-bottom: 30px;
}

.email-list-fieldset__delete-icon {
    margin-left: 10px;
}

.email-list-fieldset__grid-row {
    margin-bottom: 0;
}
</style>

import { useIconSize } from '@/services/utils/useIconSize';
export function SfDeleteIcon({ size, ...svgProps }) {
    const iconSize = useIconSize(size);
    return (<svg width={iconSize} height={iconSize} viewBox="0 0 24 24" aria-hidden="true" fill="none" className="svg-icon" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <g clipPath="url(#DeleteIcon__a)" strokeWidth="2" strokeMiterlimit="10">
                <path d="M18.9998 6H3M17 11V9M5 9V22H10M8.99951 9V19M13 9V11M13 6.31928V2H9V6.5" className="stroke-icon"/>
                <path d="M16.4991 21.998C18.9844 21.998 20.9991 19.9833 20.9991 17.498 20.9991 15.0128 18.9844 12.998 16.4991 12.998 14.0139 12.998 11.9991 15.0128 11.9991 17.498 11.9991 19.9833 14.0139 21.998 16.4991 21.998ZM14.7315 15.7305 18.2315 19.2305M14.7314 19.2305 18.2314 15.7305" className="stroke-icon-dark-accent"/>
            </g>
            <defs>
                <clipPath id="DeleteIcon__a">
                    <path fill="#fff" transform="translate(3 1)" d="M0 0H18.999V21.997H0z"/>
                </clipPath>
            </defs>
        </svg>);
}

<template>
    <div :class="{ 'thumbnail--inline': inline }" class="thumbnail">
        <div
            :class="{ 'thumbnail--present': hasImage && (modal || !!modalAction) }"
            class="thumbnail__container"
            @click="onClick"
        >
            <img v-if="hasImage" :src="thumbnail" class="thumbnail__image" alt="" />
            <div v-else class="thumbnail__placeholder" />
        </div>

        <Button v-if="modal || !!modalAction" class="thumbnail__zoom-icon" @click="onClick">
            <PenIcon v-if="editable" class="icon--inline" width="16" />
            <ZoomIcon v-else-if="modal" class="icon--inline icon--mono" />
        </Button>

        <ModalBox v-if="modal" ref="imageModal">
            <img v-if="hasImage && imgSrc" :src="imgSrc" class="thumbnail__modal-image" alt="" />
            <img v-else-if="hasImage" :src="src.large" class="thumbnail__modal-image" alt="" />
            <div v-else>
                {{ $t('components.thumbnail.noImage') }}
            </div>
        </ModalBox>
    </div>
</template>

<script>
import ModalBox from '@/components/Modal/ModalBox';
import Button from '@/components/Button/Button';

import PenIcon from '@/assets/icons/regular/pen.svg';
import ZoomIcon from '@/assets/icons/regular/zoom.svg';

export default {
    name: 'ThumbnailV2',
    components: {
        ModalBox,
        Button,

        PenIcon,
        ZoomIcon,
    },
    props: {
        src: {
            type: Object,
            default: () => {},
        },
        imgSrc: {
            type: String,
            default: null,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        modal: {
            type: Boolean,
            default: false,
        },
        modalAction: {
            type: Function,
            default: null,
        },
        size: {
            type: String,
            default: 'medium',
            validator: v => ['thumbnail', 'small', 'medium'].includes(v),
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        thumbnail() {
            if (this.imgSrc) return this.imgSrc;

            if (!this.src) {
                return null;
            }

            return this.inline ? this.src.small : this.src[this.size];
        },

        hasImage() {
            if (this.imgSrc) return true;
            return this.src && Object.keys(this.src).length > 0;
        },
    },
    methods: {
        onClick() {
            if (this.modalAction) {
                this.modalAction();
            } else if (this.modal) {
                this.$refs['imageModal'].$emit('open');
            } else {
                this.$emit('click');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.thumbnail {
    position: relative;
    width: 100%;
}

.thumbnail--inline {
    display: inline-block;
}

.thumbnail__container {
    position: relative;
    height: 100%;
    background-color: $color-lightGrey;
    border: 1px solid $color-mediumGrey;
    overflow: hidden;
    box-sizing: border-box;

    .thumbnail--inline & {
        width: 70px;
        height: 70px;

        @media screen and (min-width: $screen-md) {
            width: 50px;
            height: 50px;
        }
    }
}

.thumbnail--present {
    cursor: pointer;

    &:hover {
        .thumbnail__image {
            transform: scale(1.05);
        }
    }
}

.thumbnail__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.thumbnail__modal-image {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}

.thumbnail__zoom-icon {
    position: absolute;
    bottom: 6px;
    right: 6px;
    z-index: 1;
    background-color: $color-white;
    border-radius: 100%;
    width: 28px;
    height: 28px;
    line-height: 28px;
    box-shadow: $boxShadow-bottomShort;
    pointer-events: none;
}
</style>

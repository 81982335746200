<template>
    <div
        :class="{
            'multiselect-box--inline': inline,
        }"
        class="multiselect-box"
        :data-test="dataTest"
    >
        <BaseButton
            :disabled="disabled"
            :image="image"
            :icon="icon"
            :arrow-right="arrowRight"
            :dark="dark"
            :data-test="dataTest && `${dataTest}-button`"
            :transparent="transparent"
            stretched
            :primary="primary"
            :levitate="levitate"
            :light="light"
            :block="block"
            :align-left="alignLeft"
            @click="openFlyout"
        >
            <slot
                :label="label"
                :selected-options="selectedOptions"
                :selected-option="selectedOptions && selectedOptions[0]"
                name="button"
            >
                <span>{{ label }}</span>
                <Words muted middle>{{ selectedOptions.length }} selected</Words>
            </slot>
            <slot slot="right" name="icon" />
        </BaseButton>

        <Flyout
            :active="flyoutActive"
            size="small"
            :screen-name="screenName"
            :data-test="dataTest && `${dataTest}-flyout`"
            @closed="closeFlyout"
        >
            <template #header>
                <HeaderBar>
                    <template #left>
                        <SfIconButton size="sm" :has-background="false" @click="closeFlyout">
                            <template #icon>
                                <SfSysArrowLeftIcon size="xs" />
                            </template>
                        </SfIconButton>
                    </template>
                    <template #headline>
                        <div class="font-copy-lg-strong">{{ label }}</div>
                    </template>
                    <template #right>
                        <HeaderBarItem v-if="!disableReset" @click="unsetOptions">
                            <span class="font-copy-lg-strong cursor-pointer">
                                {{ $t('components.multiselectBox.resetFilterLabel') }}
                            </span>
                            <SfIconButton class="ml-1" size="sm" :has-background="false">
                                <template #icon>
                                    <SfSysCloseIcon size="xs" />
                                </template>
                            </SfIconButton>
                        </HeaderBarItem>
                    </template>
                </HeaderBar>
            </template>

            <slot name="additionalOptionContents" />

            <div class="multiselect-box__container">
                <GridRow v-if="searchable" key="search" :count="1" spacing="large" align="center">
                    <TextField
                        v-model="searchString"
                        :label="searchFieldLabel || $t('components.multiselectBox.searchLabel')"
                        data-test="project-search-employee"
                        class="span-1"
                    >
                        <template #icon>
                            <SearchIcon width="18" height="18" />
                        </template>
                    </TextField>
                </GridRow>

                <LoadingSpinner v-if="isLoading" block dark />
                <template v-else>
                    <GridRow
                        v-if="multiple"
                        key="list"
                        :count="1"
                        spacing="large"
                        align="center"
                        classes="grid-row--bordered"
                        :data-test="dataTest && `${dataTest}-list`"
                    >
                        <CheckboxField
                            v-for="(option, i) in visibleOptions"
                            :key="i"
                            v-model="selectedOptions"
                            :option="option"
                            :data-test="dataTest && `${dataTest}-item`"
                            :image="image"
                            :icon="icon"
                        >
                            <slot :option="option" name="option" :search-string="searchString">
                                {{ option }}
                            </slot>
                        </CheckboxField>
                    </GridRow>
                    <GridRow
                        v-else
                        :count="1"
                        spacing="large"
                        align="center"
                        classes="grid-row--bordered"
                        :data-test="dataTest && `${dataTest}-list`"
                    >
                        <BaseButton
                            v-for="(option, i) in visibleOptions"
                            :key="i"
                            :image="image"
                            :icon="icon"
                            :data-test="dataTest && `${dataTest}-item`"
                            :disabled="disableOptionHandler(option)"
                            :class="{
                                [optionClass]: optionClass,
                                [disabledOptionClass]: disableOptionHandler(option),
                            }"
                            stretched
                            class="multiselect-box__option-button"
                            @click="saveSingleOption(option)"
                        >
                            <slot :option="option" :is-active="isActive(option)" name="option">
                                {{ option }}
                            </slot>
                        </BaseButton>

                        <Words
                            v-if="endpoint && remoteOptions && remoteOptions.count === 0"
                            key="noResults"
                            block
                            centered
                        >
                            {{ $t('components.multiselectBox.noSearchResults') }}
                        </Words>

                        <MoreResultsButton
                            v-if="endpoint && remoteOptions && remoteOptions.count - remoteOptions.items.length > 0"
                            key="more-results"
                            :result="remoteOptions"
                        />
                    </GridRow>
                </template>
            </div>

            <slot name="additionalInformation" :close-flyout="closeFlyout" />

            <template #bottom>
                <ButtonGroup v-if="multiple">
                    <BaseButton arrow-right primary @click="saveOptions">
                        {{ $t('components.multiselectBox.accept') }}
                    </BaseButton>
                </ButtonGroup>
                <slot v-if="customSaveButton" name="customSaveButton" :close-flyout="closeFlyout" />
            </template>
        </Flyout>
    </div>
</template>

<script>
import Toaster from '@/services/Toaster';
import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import _filter from 'lodash/filter';
import _isArray from 'lodash/isArray';
import _isEqual from 'lodash/isEqual';
import _isObject from 'lodash/isObject';
import _sortBy from 'lodash/sortBy';
import _toString from 'lodash/toString';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import CheckboxField from '@/components/Form/CheckboxField';
import Flyout from '@/components/Layout/Flyout';
import GridRow from '@/components/Layout/GridRow';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import LoadingSpinner from '@/components/LoadingSpinner';
import MoreResultsButton from '@/components/Filter/MoreResultsButton';
import SearchIcon from '@/assets/icons/micro/search.svg';
import TextField from '@/components/Form/TextField.v2';
import Words from '@/components/Typography/Words';

import { SfSysCloseIcon, SfSysArrowLeftIcon, SfIconButton } from '@schuettflix/vue-components';

const SEARCH_DEBOUNCE_MS = 500;

export default {
    name: 'MultiselectBox',
    components: {
        BaseButton,
        ButtonGroup,
        CheckboxField,
        Flyout,
        GridRow,
        HeaderBar,
        HeaderBarItem,
        LoadingSpinner,
        MoreResultsButton,
        SearchIcon,
        TextField,
        Words,

        SfSysCloseIcon,
        SfSysArrowLeftIcon,
        SfIconButton,
    },
    props: {
        name: {
            type: String,
            default: null,
        },
        value: {
            type: [Array, Number, String, Boolean, Object],
            default: null,
            validator: v => {
                // In arrays, only primitive data types are supported
                if (_isArray(v)) {
                    return !v.some(_isObject);
                }

                return true;
            },
        },
        options: {
            type: [Array, Object],
            default: null,
        },
        dataTest: {
            type: String,
            default: null,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        searchable: {
            type: Boolean,
            default: false,
        },
        optionValueRenderer: {
            type: Function,
            default: option => option,
        },
        searchLabelRenderer: {
            type: Function,
            default: option => option,
        },
        label: {
            type: String,
            default: 'Options',
        },
        searchFieldLabel: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disableReset: {
            type: Boolean,
            default: false,
        },
        image: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: Boolean,
            default: false,
        },
        arrowRight: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        primary: {
            type: Boolean,
            default: false,
        },
        levitate: {
            type: Boolean,
            default: false,
        },
        light: {
            type: Boolean,
            default: false,
        },
        alignLeft: {
            type: Boolean,
            default: false,
        },
        disableOptionHandler: {
            type: Function,
            default: () => false,
        },
        optionClass: {
            type: String,
            default: null,
        },
        disabledOptionClass: {
            type: String,
            default: 'multiselect-box__option-button--disabled',
        },
        disableOnSelectFlyoutClose: {
            type: Boolean,
            default: false,
        },
        customSaveButton: {
            type: Boolean,
            default: false,
        },
        block: {
            type: Boolean,
            default: false,
        },
        // Filter
        filter: {
            type: Object,
            default: null,
        },
        // Endpoint has to provide a 'ids' filter
        endpoint: {
            type: Object,
            default: null,
        },
        // Convert input/output value to identifier for endpoint selection eg. {} -> {}.id or 12 -> 12
        valueIdentifierRenderer: {
            type: Function,
            default: v => v,
        },
        screenName: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            flyoutActive: false,
            searchString: null,
            selectedOptions: [],
            remoteOptions: null,
            selectedRemoteOptions: null,
            cancelSource: null,
            selectedCancelSource: null,
            isLoading: false,
        };
    },
    computed: {
        visibleOptions() {
            // Use remote response
            if (this.endpoint) {
                // Remote options are still pending
                if (this.remoteOptions === null) {
                    return [];
                }

                let selectedItems = [];
                let restItems = this.remoteOptions.items;

                // prepend selected items
                if (this.selectedRemoteOptions.length > 0) {
                    selectedItems = [...this.selectedRemoteOptions];

                    const selectedIds = selectedItems.map(item => item.id);

                    // exclude selected from results since they are added above
                    restItems = _filter(restItems, item => !selectedIds.includes(item.id));
                }

                return [...selectedItems, ...restItems];
            }

            return _filter(this.options, option => {
                return this.searchString
                    ? this.searchLabelRenderer(option).toLowerCase().includes(String(this.searchString).toLowerCase())
                    : true;
            });
        },
    },
    watch: {
        value: 'checkOptionsUpdate',
        options: 'checkOptionsUpdate',
        searchString: 'refreshWithDebounce',
    },
    created() {
        this.refreshSelectedOptions();
        this.$on('openMultiselectFlyout', this.openFlyout);
        this.$on('closeMultiselectFlyout', this.closeFlyout);
    },
    methods: {
        async checkOptionsUpdate(val, old) {
            if (!_isEqual(val, old)) {
                this.selectedOptions = await this.getSelectedOptions();
            }
        },
        async getSelectedOptions() {
            if (this.endpoint) {
                return this.getSelectedRemoteOptions();
            }

            return _filter(this.options, option => {
                if (_isArray(this.value)) {
                    return this.value.map(_toString).includes(_toString(this.optionValueRenderer(option)));
                } else if (_isObject(this.value)) {
                    return _isEqual(this.optionValueRenderer(option), this.value);
                } else {
                    return _toString(this.optionValueRenderer(option)) === _toString(this.value);
                }
            });
        },
        openFlyout() {
            this.searchString = null;
            this.flyoutActive = true;
            this.refreshOptions();
        },
        closeFlyout() {
            this.refreshSelectedOptions();
            this.flyoutActive = false;
            this.remoteOptions = null;
        },
        unsetOptions() {
            this.selectedOptions = [];
            this.$emit('input', null);
        },
        saveSingleOption(option) {
            this.selectedOptions = [option];
            this.$emit('input', this.optionValueRenderer(option), option);
            !this.disableOnSelectFlyoutClose && this.closeFlyout();
        },
        saveOptions() {
            this.$set(this, 'selectedOptions', _sortBy(this.selectedOptions, this.optionValueRenderer));
            const values = this.selectedOptions.map(this.optionValueRenderer);
            this.$emit('input', values);
            this.closeFlyout();
        },

        refreshWithDebounce: _debounce(function () {
            this.refreshOptions();
        }, SEARCH_DEBOUNCE_MS),

        async refreshOptions() {
            if (!this.endpoint) return;
            this.isLoading = true;

            try {
                this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
                this.cancelSource = this.endpoint.createCancelTokenSource();

                const filter = this.filter !== null ? this.filter : {};

                // apply search string
                filter.search = this.searchString || null;

                this.remoteOptions = await this.endpoint.filter(filter, null, null, this.cancelSource);
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                    Toaster.error(err.message);
                }
            }

            this.isLoading = false;
        },

        async refreshSelectedOptions() {
            this.selectedOptions = await this.getSelectedOptions();
        },

        async refreshSelectedRemoteOptions() {
            this.isLoading = true;

            // If no value provided, or endpoint not set, reset remote options
            if (!this.value || !this.endpoint) {
                this.selectedRemoteOptions = [];
                this.isLoading = false;
                return;
            }

            const values = this.multiple ? this.value : [this.value];

            const ids = values.map(v => this.valueIdentifierRenderer(v));

            try {
                this.selectedCancelSource && this.selectedCancelSource.cancel('canceled-previous-call');
                this.selectedCancelSource = this.endpoint.createCancelTokenSource();

                // new instance of the filter, which will be modified
                const filter = _cloneDeep(this.filter !== null ? this.filter : {});
                filter.ids = ids;

                const response = await this.endpoint.filter(filter, null, null, this.selectedCancelSource);
                this.selectedRemoteOptions = response.items;
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }

            this.isLoading = false;
        },

        async getSelectedRemoteOptions() {
            await this.refreshSelectedRemoteOptions();
            return this.selectedRemoteOptions;
        },

        isActive(option) {
            return _isEqual(this.value, this.optionValueRenderer(option));
        },
    },
};
</script>

<style lang="scss">
.multiselect-box {
    > button {
        display: block;
        width: 100%;
        text-align: left;
    }
}

.multiselect-box--inline {
    > button {
        display: inline-block;
        width: auto;
        text-align: left;
    }
}
.multiselect-box__option-button {
    text-align: left;
}

.multiselect-box__container {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.multiselect-box__option--spaced {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.multiselect-box__headline-label {
    line-height: 18px;
}

.multiselect-box__close-button {
    position: relative;
    top: 2px;
}
</style>

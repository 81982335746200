<template>
    <div
        :class="{
            'select-input--shadow': shadow && !dark,
            'select-input--dark': dark,
            'select-input--small': small,
            'select-input--tiny': tiny,
            'select-input--invalid': error || hasError,
        }"
        class="select-input"
    >
        <div class="select-input__wrapper">
            <select
                :id="eid"
                :value="value"
                :class="{ 'select-input__input--no-value': value === null && label }"
                class="select-input__input"
                @change="$emit('input', $event.target.value)"
            >
                <slot />
            </select>
            <label
                v-if="label && value === null"
                :for="eid"
                :class="{ 'select-input__label--dark': dark }"
                class="select-input__label"
            >
                {{ label }}
            </label>
            <ArrowDownIcon class="select-input__arrow select-input__arrow--down" />
        </div>
        <ErrorMessage v-if="error" :message="error" :dark="dark" />
    </div>
</template>

<script>
import ArrowDownIcon from '@/assets/icons/regular/arrow-stripeless--down.svg';
import ErrorMessage from '@/components/Form/ErrorMessage';

export default {
    name: 'SelectBox',
    components: {
        ArrowDownIcon,
        ErrorMessage,
    },
    props: {
        value: {
            type: [String, Number, Boolean],
            default: null,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        shadow: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        small: {
            type: Boolean,
            default: false,
        },
        tiny: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            eid: `el${this._uid}`,
        };
    },
};
</script>

<style lang="scss">
.select-input {
    position: relative;
}

.select-input--shadow {
    box-shadow: $boxShadow-full;
}

.select-input__wrapper {
    position: relative;
}

.select-input__input {
    display: block;
    padding: 26px 44px 26px 20px;
    height: 70px;
    width: 100%;
    outline: none;
    border-radius: 0;
    border: 0 none;
    background-color: $color-white;
    font-size: inherit;
    font-family: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;

    .select-input--small & {
        padding: 12px 44px 12px 20px;
        height: auto;
        background-color: transparent;
        font-size: 0.9em;
        line-height: 42px - 12px - 12px;
    }

    .select-input--tiny & {
        padding: 9px 22px 9px 8px;
        height: auto;
        background-color: transparent;
        font-size: 0.9em;
        line-height: 42px - 9px - 9px;
    }

    &.select-input__input--no-value {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 0;
    }
}

.select-input__arrow {
    width: 14px;
    height: 14px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 11;

    .select-input--small &,
    .select-input--tiny & {
        width: 10px;
        height: 10px;
    }

    .select-input--tiny & {
        right: 8px;
    }
}

.select-input__label {
    pointer-events: none;
    line-height: 70px;
    display: block;
    position: relative;
    padding: 0 44px 0 20px;
    height: 70px;
    width: 100%;
    z-index: 10;
    background-color: $color-white;
}

.select-input--dark {
    .select-input__input {
        background-color: $color-lightGrey;
    }
}

.select-input__label--dark {
    background-color: $color-lightGrey;
}
</style>

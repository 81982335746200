import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
const sortingOptions = [
    'id',
    'number',
    'status',
    'cancelled',
    'urgency',
    'created',
    'totalNet',
    'completed',
    'totalGross',
    'orderNumber',
    'organization',
    'transportNumber',
    'creditNoteTotalCarrier',
    'shippingWindowEnd',
    'creditNoteTotalSupplier',
    'serviceDataTimestamp',
    'shippingWindowStart',
    'supplierOrganizationName',
    'carrierOrganizationName',
    'confirmLoadingTimestamp',
];
export const useSortingOptions = () => {
    const { selectCreditNoteDocKey } = useLegalTerms();
    const getSortingTranslationKey = (option) => {
        const translationKeyMappings = {
            number: selectCreditNoteDocKey({
                creditNote: `pages.creditNoteDoc.sortings.number.creditNote`,
                selfBilling: `pages.creditNoteDoc.sortings.number.selfBilling`,
            }),
        };
        return translationKeyMappings[option] || `pages.creditNoteDoc.sortings.${option}`;
    };
    return {
        getSortingTranslationKey,
    };
};

import { useAsyncState } from '@vueuse/core';
import { onScopeDispose, ref, unref, watch } from 'vue';
import TransportApi from '@/services/Api/Transport';
import { useRouteContext } from '@/utils/composition-helper';
import { asyncDebounce, keyedDebounce } from '@/services/utils/debounceHelpers';
import mitt from 'mitt';
import isEqual from 'lodash/isEqual';
const DEBOUNCE_TIME = 500; // in milliseconds
export const useWeighingNoteDuplicateCheck = ({ transportId: transportIdMaybeRef, weighingNumber: weighingNumberMaybeRef, weight: weightMaybeRef, weighingDate: weighingDateMaybeRef, additionalKey: additionalKeyMaybeRef, }) => {
    const { routeContext } = useRouteContext();
    // check if the given weighing note is a duplicate
    const emitter = mitt();
    const onCancel = (cb) => {
        emitter.on('cancel', cb);
    };
    onScopeDispose(() => {
        emitter.all.clear();
    });
    const { state: isWeighingNoteDuplicate, isLoading: isWeighingNoteDuplicateLoading, execute, } = useAsyncState(async () => {
        emitter.emit('cancel');
        const transportId = unref(transportIdMaybeRef);
        const weighingNumber = unref(weighingNumberMaybeRef);
        const weight = unref(weightMaybeRef);
        const weighingDate = unref(weighingDateMaybeRef);
        const context = unref(routeContext);
        const additionalKey = unref(additionalKeyMaybeRef);
        if (!weighingNumber || !weight || !weighingDate)
            return false;
        return await checkWeighingNoteDuplicateDebounced(`${transportId}${additionalKey || ''}`, {
            context,
            weighingDate,
            weighingNumber,
            weight,
            onCancel,
        });
    }, false, {
        immediate: false,
        resetOnExecute: false,
    });
    watch([
        transportIdMaybeRef,
        weighingNumberMaybeRef,
        weightMaybeRef,
        weighingDateMaybeRef,
        routeContext,
        additionalKeyMaybeRef,
    ], (newParams, oldParams) => {
        if (isEqual(newParams, oldParams))
            return;
        execute();
    }, { immediate: true });
    // submit user's reaction to the warning from duplicate weighing note check
    const stateMachineForIsDuplicate = {
        // see https://schuettflix.atlassian.net/browse/AP-1030 for an explanation of the states
        state: ref('no duplicate'),
        transitions: {
            'no duplicate': {
                true: 'ignored',
                false: 'no duplicate',
            },
            ignored: {
                true: 'ignored',
                false: 'corrected',
            },
            corrected: {
                true: 'ignored',
                false: 'corrected',
            },
        },
        doTransition(isDuplicate) {
            this.state.value = this.transitions[this.state.value]?.[isDuplicate ? 'true' : 'false'];
        },
    };
    watch(isWeighingNoteDuplicate, isWeighingNoteDuplicate => stateMachineForIsDuplicate.doTransition(isWeighingNoteDuplicate));
    const submitWeighingNoteDuplicateStatus = async () => {
        const context = unref(routeContext);
        const transportId = unref(transportIdMaybeRef);
        const status = unref(stateMachineForIsDuplicate.state);
        const transportApi = new TransportApi(context);
        transportApi.submitWeighingNoteDuplicateStatus({ transportId, status });
    };
    return {
        isWeighingNoteDuplicate,
        isWeighingNoteDuplicateLoading,
        submitWeighingNoteDuplicateStatus,
    };
};
// creating this function outside of the composable scope to ensure
// that debouncing works across different usages of the composable.
// In combination with `keyedDebounce`, this means even when different
// components will use the composable with the same transportId
// only one API call will be triggered.
const checkWeighingNoteDuplicateDebounced = keyedDebounce(async ({ weighingDate, weighingNumber, weight, context, onCancel }) => {
    const transportApi = new TransportApi(context);
    const abortController = new AbortController();
    onCancel(() => {
        abortController.abort();
    });
    try {
        return await transportApi.checkWeighingNoteDuplicate({
            weighingDate,
            weighingNumber,
            weight,
            signal: abortController.signal,
        });
    }
    catch (e) {
        return false;
    }
}, DEBOUNCE_TIME, {}, asyncDebounce);

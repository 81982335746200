<template>
    <details :open="openOnInit" class="details" @click="onClick">
        <summary ref="header" class="details__summary">
            <div class="details__header">
                <Words class="details__header__main" :title="header" ellipsis bold>{{ header || '' }}</Words>
                <Words class="details__header__subheader" :title="subheader" ellipsis>{{ subheader || '' }}</Words>
            </div>
            <div class="details__action">
                <div class="details__action__action">
                    <slot name="action" />
                </div>
                <ArrowDownIcon
                    :class="['details__action__caret', { 'details__action__caret--open': isOpen }]"
                    width="18"
                    height="18"
                />
            </div>
        </summary>

        <main @click.stop>
            <slot />
        </main>
    </details>
</template>

<script>
import ArrowDownIcon from '@/assets/icons/regular/arrow-stripeless--down.svg';
import Words from '@/components/Typography/Words.vue';

export default {
    name: 'ShowDetails',
    components: {
        ArrowDownIcon,
        Words,
    },
    props: {
        openOnInit: {
            type: Boolean,
            default: false,
        },
        header: {
            type: [String, Number],
            required: true,
        },
        subheader: {
            type: [String, Number],
            default: '',
        },
    },
    data() {
        return {
            isOpen: this.openOnInit,
        };
    },
    methods: {
        onClick() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>

<style lang="scss" scoped>
.details {
    width: 100%;
    border-bottom: 1px solid $sflx-grey-400;

    &__summary {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 92px;
        width: 100%;
        list-style: none;
        cursor: pointer;
        padding: 24px 0;

        &::-webkit-details-marker {
            display: none;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        gap: 4px;
        flex: 1 1 auto;
        min-width: 0;

        &__main {
            margin-bottom: 4px;
            flex: 0 1 auto;
            min-width: 0;
            white-space: nowrap;
        }

        &__subheader {
            display: block;
            flex: 0 1 auto;
            min-width: 0;
            white-space: nowrap;
            color: $color-dark-grey-400;
        }
    }

    &__action {
        margin-right: 16px;
        display: flex;
        align-items: center;
        padding-left: 8px;
        font-weight: bold;
        gap: 24px;

        &__action {
            margin-left: 16px;
        }

        &__caret {
            transition: transform 0.2s ease-in-out;

            &--open {
                transform: rotateZ(-180deg);
            }
        }
    }

    &[open] {
        padding-bottom: 2rem;
    }
}
</style>

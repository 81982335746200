<template>
    <section class="summary-product-title-section">
        <h2 class="font-headline-xl-strong mb-4 flex flex-wrap items-start" data-test="summary-product-name">
            <span v-if="prefix" class="mr-4 shrink-0">{{ prefix }}</span>
            <span>{{ title }}</span>
            <span class="grid">
                <SfTag v-for="(tag, i) in tags" v-bind="tag" :key="i" class="ml-4 mt-1 shrink-0">
                    {{ tag.text }}
                </SfTag>
            </span>
        </h2>

        <div v-if="description" class="font-copy-md mt-4">{{ description }}</div>

        <slot />
    </section>
</template>

<script>
import { SfTag } from '@schuettflix/vue-components';

export default {
    name: 'ProductTitleSection',
    components: {
        SfTag,
    },
    props: {
        prefix: {
            type: [Number, String],
            default: null,
        },
        title: {
            type: String,
            required: true,
        },
        tags: {
            type: Array,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
    },
};
</script>

<template>
    <ModalBox
        ref="languageMismatchModal"
        class="tracking-info-modal"
        :active="showPopup"
        :optional-dialog="false"
        @closed="decline('languageMismatchModal')"
    >
        <h2 class="font-copy-xl-strong mb-4">{{ $t('pages.login.languageMismatchModal.title', loginLocale) }}</h2>
        <p class="font-copy-md mb-4">
            {{ $t('pages.login.languageMismatchModal.description', loginLocale) }}
        </p>

        <div class="mb-12 mt-6 flex items-center">
            <Component :is="savedTerritory" class="mr-2" />
            <span class="font-copy-md-strong">
                {{ savedLanguageName }}
            </span>

            <div class="mx-4">
                <ArrowRightIcon />
            </div>

            <Component :is="loginTerritory" class="mr-2" />
            <span class="font-copy-md-strong">
                {{ loginLanguageName }}
            </span>
        </div>

        <footer class="flex items-center justify-end">
            <SfButton type="secondary" size="sm" @click="decline('languageMismatchModal')">
                {{ $t('pages.login.languageMismatchModal.button.dontEdit', loginLocale) }}
            </SfButton>
            <SfButton type="primary" size="sm" @click="accept()">
                {{ $t('pages.login.languageMismatchModal.button.edit', loginLocale) }}
            </SfButton>
        </footer>
    </ModalBox>
</template>

<script>
import { mapGetters } from 'vuex';
import { SfButton } from '@schuettflix/vue-components';

import ModalBox from '@/components/Modal/ModalBox';
import UserApi from '@/services/Api/UserV2';
import { loadLanguageAsync } from '@/i18n';

import {
    getLanguageFromLocale,
    getFlagFromLocale,
    findClosestMatchingSupportedLocale,
    getDisplayNameOfLanguage,
    defaultLocale,
} from '@/i18n/languages';

import ArrowRightIcon from '@/assets/icons/regular/arrow-right.svg';

import LogService from '@schuettflix/util-log';

const Log = new LogService('pages/Login/LanguageMismatchModal');

export default {
    name: 'LanguageMismatchModal',
    components: {
        SfButton,
        ModalBox,
        ArrowRightIcon,
    },

    props: {
        userLocale: {
            type: String,
            required: false,
            default: defaultLocale,
        },
    },

    data() {
        const loginLocale = sessionStorage.getItem('loginLocale') || '';
        const loginLanguage = getLanguageFromLocale(loginLocale);
        const loginLanguageName = getDisplayNameOfLanguage(loginLocale, loginLanguage);
        const loginTerritory = getFlagFromLocale(loginLocale);
        const savedLocale = findClosestMatchingSupportedLocale(this.userLocale);
        const savedLanguage = getLanguageFromLocale(savedLocale);
        const savedLanguageName = getDisplayNameOfLanguage(savedLocale, savedLanguage);
        const savedTerritory = getFlagFromLocale(savedLocale);

        return {
            loginLocale,
            loginLanguage,
            loginLanguageName,
            loginTerritory,
            savedLanguage,
            savedLanguageName,
            savedTerritory,
            showPopup: loginLocale.split('-')[0] !== savedLocale.split('-')[0],
        };
    },

    computed: {
        ...mapGetters('user', {
            currentUserId: 'id',
        }),
    },

    created() {
        loadLanguageAsync(this.loginLocale);
    },

    methods: {
        decline(ref) {
            this.cleanSessionStorage();
            this.$refs[ref].$emit('close');
        },
        async accept() {
            try {
                const user = await UserApi.getOneById(this.currentUserId);

                user.locale = this.loginLocale;

                const updatedUser = await UserApi.saveUserData(this.currentUserId, user);

                this.$store.dispatch('i18n/changeLocale', {
                    locale: updatedUser.locale,
                });
                this.decline('languageMismatchModal');
            } catch (err) {
                Log.error('Error while updating locale:', err);
            }
        },

        cleanSessionStorage() {
            sessionStorage.removeItem('loginLocale');
        },
    },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.enableMobile)?_c('div',{staticClass:"tab-navigation",class:{
        'tab-navigation--stretch': _vm.stretch,
        'tab-navigation--lineless': _vm.lineless,
        'tab-navigation--spaced': _vm.spaced,
        'tab-navigation--align-left': _vm.alignLeft,
        'tab-navigation--dark-line': _vm.darkLine,
    }},[(_vm.stages)?_c('div',{staticClass:"tab-navigation__track flex flex-nowrap"},_vm._l((_vm.stages),function(stage,key){return _c('TabNavigationItem',{key:key,attrs:{"stage":_vm.stages && _vm.stages.push ? stage : key,"active":_vm.isActive(stage, key)}},[_vm._v(" "+_vm._s(stage)+" ")])}),1):_c('div',{staticClass:"tab-navigation__track flex flex-nowrap"},[_vm._t("default")],2),_c('div',{staticClass:"tab-navigation__highlight",style:({ left: `${_vm.highlight.left}px`, width: `${_vm.highlight.width}px` })})]):_c('div',{staticClass:"tab-navigation tab-navigation--mobile",class:{ 'tab-navigation--no-shadow': _vm.noShadow }},[_vm._t("default"),(_vm.stages)?_vm._l((_vm.stages),function(stage,key){return _c('TabNavigationItem',{key:key,attrs:{"stage":_vm.stages && _vm.stages.push ? stage : key,"active":_vm.isActive(stage, key)}},[_vm._v(" "+_vm._s(stage)+" ")])}):_vm._e(),_c('div',{staticClass:"tab-navigation__highlight",style:({ left: `${_vm.highlight.left}px`, width: `${_vm.highlight.width}px` })})],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
    <div>
        <p class="font-copy-md-strong m-0 mb-2 lg:font-copy-lg-strong">
            {{ $t('pages.homeV2.pickupTitle') }}
        </p>
        <p class="font-copy-sm mb-[12px] lg:font-copy-md lg:mb-6">
            {{ $t('pages.homeV2.pickupDescription') }}
        </p>
        <template v-if="activePickupOrders">
            <Card
                v-for="order in activePickupOrders.items"
                :key="order.id"
                spaceless-x
                clickable
                data-test="dashboard-pickup-tile"
                @click="goToActivePickupOrder(order.id)"
            >
                <ActiveOrderPickupItem
                    :order="order"
                    :line-item-group="order.lineItemGroups[0]"
                    @click="goToActivePickupOrder(order.id)"
                    @add="goToPickupOrderStartPage"
                />
            </Card>
            <EmptyState
                v-if="activePickupOrders.count === 0"
                class="mb-4"
                icon="PickupEmptyIcon"
                :text="$t('pages.homeV2.noActivePickupOrders')"
            />
        </template>
    </div>
</template>

<script>
import OrderApi from '@/services/Api/Order';

import ActiveOrderPickupItem from '@/pages/Home/components/ActiveOrderPickupItem';
import Card from '@/components/Layout/Card';
import EmptyState from '@/pages/Home/components/EmptyState';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';

const POLLING_INTERVAL = 60000;

export default {
    name: 'HomeSectionPickup',
    components: {
        ActiveOrderPickupItem,
        Card,
        EmptyState,
    },
    data() {
        return {
            activePickupOrders: null,
            activePickupOrdersCancelSource: null,
        };
    },
    mounted() {
        this.refreshActivePickupOrders();
        this.$gracefulInterval(this.refreshActivePickupOrders.bind(this), POLLING_INTERVAL);
    },
    beforeDestroy() {
        this.activePickupOrdersCancelSource && this.activePickupOrdersCancelSource.cancel('canceled-previous-call');
    },
    methods: {
        async refreshActivePickupOrders() {
            this.activePickupOrdersCancelSource && this.activePickupOrdersCancelSource.cancel('canceled-previous-call');
            this.activePickupOrdersCancelSource = OrderApi.createCancelTokenSource();

            try {
                this.activePickupOrders = await OrderApi.filter(
                    {
                        type: ORDER_LINE_ITEM_GROUP_TYPE.PICKUP,
                        status: ['new', 'in_progress'],
                        canStartPickup: true,
                    },
                    null,
                    null,
                    this.activePickupOrdersCancelSource
                );
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }
        },

        goToActivePickupOrder(orderId) {
            this.$router
                .push({
                    name: this.$root.findRouteName('order__order-list__order-view'),
                    params: { orderId },
                })
                .catch(() => {});
        },

        goToPickupOrderStartPage(order) {
            this.$router
                .push({
                    name: this.$root.findRouteName('logistics__pickup-active-trips__pickup-start-order'),
                    params: { orderId: order.id },
                })
                .catch(() => {});
        },
    },
};
</script>

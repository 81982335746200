import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
export const MoreCategoriesCard = ({ count, className, onSelect }) => {
    const { t } = useTranslation();
    return (<div className={clsx('flex h-[220px] w-[200px] cursor-pointer flex-col items-center justify-center gap-1 rounded-md border-0 bg-surface shadow-low transition-shadow duration-300 hover:shadow-high', className)} data-test="show-more-categories-card" onClick={() => {
            onSelect();
        }}>
            <div className={'font-headline-xl-strong text'}>+{count}</div>
            <div className={'font-copy-md text-subdued'}>{t('More categories')}</div>
        </div>);
};

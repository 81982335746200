<template>
    <LayoutPage data-test="court-list-page" screen-name="seller-activeloading-list">
        <div slot="pageTitle">{{ $t('pages.courtList.title') }}</div>

        <TransportSearch
            v-if="allTransports"
            :transports="allTransports"
            route-name="transport-view"
            @input="searchString = $event"
        />

        <div v-show="!searchString">
            <LoadingSpinner v-if="!transports" key="spinner" block dark />

            <transition name="fade">
                <div v-if="transports && transports.count > 0">
                    <div v-for="(transportFacet, index) in transportFacets" :key="index">
                        <ListLabel v-stuckable="10">
                            {{ statusText(transportFacet.appliedFacetValue.join('.'), transportFacet.count) }}
                        </ListLabel>
                        <List inner-shadow>
                            <TransportItem
                                v-for="(transport, i) in transportFacet.items"
                                :key="i"
                                :item="transport"
                                is-supplier
                                data-test="supplier-transport-item"
                                @click="selectTransport(transport.id)"
                            />
                        </List>
                        <MoreResultsButton
                            v-if="transportFacet.count - transportFacet.items.length > 0"
                            :result="transportFacet"
                        />
                    </div>
                </div>

                <Hint v-if="!isLoading && transports && transports.count === 0" center transparent>
                    {{ $t('pages.home.supplier.noTransports') }}
                </Hint>
            </transition>
        </div>

        <Flyout slot="subpages" route="transport-view" size="small" no-header />
    </LayoutPage>
</template>

<script>
import { statusText } from '@/services/utils/transport';
import TransportListApi from '@/services/Api/TransportList';
import routeContext from '@/plugins/mixins/routeContext';

import Flyout from '@/components/Layout/Flyout';
import Hint from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import List from '@/components/List/List';
import ListLabel from '@/components/List/ListLabel';
import LoadingSpinner from '@/components/LoadingSpinner';
import MoreResultsButton from '@/components/Filter/MoreResultsButton';
import TransportItem from '@/components/List/TransportItem';
import TransportSearch from '@/components/Transport/TransportSearch';

const UPDATE_INTERVAL = 10000;

const transportListApi = new TransportListApi('supplier');

export default {
    name: 'CourtListPage',
    components: {
        Flyout,
        Hint,
        LayoutPage,
        List,
        ListLabel,
        LoadingSpinner,
        MoreResultsButton,
        TransportItem,
        TransportSearch,
    },
    mixins: [routeContext],
    data() {
        return {
            searchString: null,
            transports: null,
            isLoading: false,
            cancelSource: null,
        };
    },
    computed: {
        allTransports() {
            let list = [];

            if (this.transports && this.transports.items) {
                this.transports.items.forEach(transportFacet => {
                    list = list.concat(transportFacet.items);
                });
            }

            return list;
        },
        transportFacets() {
            return this.transports.items.filter(item => item.count > 0);
        },
    },
    mounted() {
        this.refreshList();

        this.$gracefulInterval(this.refreshList.bind(this), UPDATE_INTERVAL);
    },
    methods: {
        statusText(status) {
            return statusText(this.routeContext, status);
        },

        selectTransport(transportId) {
            this.$router
                .push({
                    name: `${this.$route.name}__transport-view`,
                    params: { transportId },
                })
                .catch(() => {});
        },

        async refreshList() {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;

            // cancel previous request
            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.cancelSource = transportListApi.createCancelTokenSource();

            try {
                const result = await transportListApi.filter(
                    {
                        status: ['checked_in', 'checked_in_loading', 'started', 'loaded'],
                    },
                    'status',
                    [['checked_in'], ['checked_in_loading'], ['started'], ['loaded']],
                    this.cancelSource,
                    {}
                );
                this.transports = result;
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }

            this.isLoading = false;
        },
    },
};
</script>

<style lang="scss"></style>

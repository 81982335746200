<template>
    <DialogModalBase>
        <template #content>
            <Words line-height-large large bold block spaced-bottom>{{
                $t('components.dialogModal.deleteFactory.headline')
            }}</Words>
            <Words
                line-height-small
                block
                :spaced-bottom-large="$root.isDesktop"
                v-html="$t('components.dialogModal.deleteFactory.message')"
            />
        </template>
        <template #footer>
            <div class="flex flex-col justify-end md:flex-row">
                <SfButton
                    type="secondary"
                    :size="`${$root.isDesktop ? 'sm' : 'md'}`"
                    :class="{ 'border-t border-light-gray-400': !$root.isDesktop }"
                    @click="$emit('secondaryClick')"
                >
                    {{ $t('components.dialogModal.deleteFactory.secondaryButtonText') }}
                </SfButton>
                <SfButton
                    :size="`${$root.isDesktop ? 'sm' : 'md'}`"
                    :class="{ 'ml-4': $root.isDesktop }"
                    @click="$emit('primaryClick')"
                >
                    {{ $t('components.dialogModal.deleteFactory.primaryButtonText') }}
                </SfButton>
            </div>
        </template>
    </DialogModalBase>
</template>
<script>
import { SfButton } from '@schuettflix/vue-components';
import DialogModalBase from '@/_components/DialogModal/DialogModalBase';
import Words from '@/components/Typography/Words';

export default {
    name: 'DeleteFactory',
    components: {
        SfButton,
        DialogModalBase,
        Words,
    },
};
</script>

import axios from 'axios';
import { AbstractResourceErrorHandler } from './AbstractResourceErrorHandler';
import ApiService from './ApiService';
class Certificate extends AbstractResourceErrorHandler {
    apiService;
    resourcePath;
    constructor(resourcePath, messages = {}) {
        super(messages);
        this.apiService = ApiService;
        this.resourcePath = resourcePath;
    }
    createCancelTokenSource() {
        return axios.CancelToken.source();
    }
    async getAll() {
        try {
            const response = await this.apiService.get(this.resourcePath);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async getAllByOrganizationForPlatform(id) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/platform/${id}`);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async getOneById(id) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/${id}`);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async save(data) {
        try {
            let response;
            if (data.id) {
                response = await this.apiService.put(`${this.resourcePath}`, data);
            }
            else {
                response = await this.apiService.post(`${this.resourcePath}`, data);
            }
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async delete(id) {
        try {
            return this.apiService.delete(`${this.resourcePath}/${id}`, undefined);
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async getCertificateTypes() {
        try {
            const response = await this.apiService.get('/v1/certificates/types');
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
}
export const certificateListCacheKey = (organizationId, isPlatformAdmin = false) => {
    const key = ['certificates', `${organizationId}`];
    if (isPlatformAdmin) {
        key.push('platformAdmin');
    }
    return key;
};
export const certificateCacheKey = (id) => ['certificate', id];
export const certificateTypesCacheKey = () => ['certificateTypes'];
export const certificateFactoriesCacheKey = (organizationId) => [
    'certificateFactories',
    `${organizationId}`,
];
export const certificateMutationKey = (id) => [`mutateCertificate${id ?? 'New'}`];
export default new Certificate('/v1/certificates');

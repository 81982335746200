import { render, staticRenderFns } from "./WasteTag.vue?vue&type=template&id=ed3929d0&scoped=true&"
import script from "./WasteTag.vue?vue&type=script&lang=js&"
export * from "./WasteTag.vue?vue&type=script&lang=js&"
import style0 from "./WasteTag.vue?vue&type=style&index=0&id=ed3929d0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_webpack@5.88.2_webpack-cli@5.1.4___lodash@4.17.21_react-d_j5jn3zdz4uzijexvn7gkk7yebe/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed3929d0",
  null
  
)

export default component.exports
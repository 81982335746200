import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoryCard } from '@/pages/Checkout/ProductSelection/CategoryCard';
import { Grid, Keyboard, Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useQuery } from '@tanstack/react-query';
import { categoriesList } from '@/pages/Checkout/queries';
import { LoadingSpinner } from '@schuettflix/react-components';
import { MoreCategoriesCard } from '@/pages/Checkout/ProductSelection/MoreCategoriesCard';
export const ProductCategoriesList = ({ orderType, locationId, onSelect, onMoreSelect, }) => {
    const categoriesMaxOnFirstPage = 2;
    const { t } = useTranslation();
    const { data, isLoading } = useQuery(categoriesList(orderType, locationId));
    const strippedCategories = useMemo(() => {
        return data?.slice(0, categoriesMaxOnFirstPage) || [];
    }, [data]);
    const DESKTOP_WIDTH = 420;
    const breakpoints = {
        [DESKTOP_WIDTH]: {
            slidesPerView: 2.2,
        },
    };
    const totalCategories = data?.length || 0;
    const withMoreButton = totalCategories > categoriesMaxOnFirstPage;
    const selectCategory = (category) => {
        onSelect({
            ...category,
            selectedType: 'category',
        });
    };
    return (<div className="w-auto px-4 pt-8 lg:px-6">
            <div className="font-copy-md-strong">
                {t('pages.checkout.selectProduct.categorySelection.sectionHeader')}
            </div>
            {isLoading && <LoadingSpinner className="mx-auto p-6 text-center"/>}
            {!isLoading && strippedCategories && (<Swiper autoHeight={true} spaceBetween={10} slidesPerView={1.7} breakpoints={breakpoints} freeMode={{
                enabled: true,
                sticky: true,
            }} modules={[Mousewheel, Keyboard, Grid]} mousewheel={{
                thresholdDelta: 10,
            }} slideToClickedSlide={true} threshold={10} keyboard className="py-6">
                    {strippedCategories?.map(category => (<SwiperSlide key={category.name}>
                            <CategoryCard imgSrc={category.image.small} title={category.name} size="lg" description={t('pages.checkout.selectProduct.categorySelection.categoryCard.availableProducts', { count: category.availableProductsCount })} dataTest="category-card" onSelect={() => selectCategory(category)}/>
                        </SwiperSlide>))}
                    {withMoreButton && (<SwiperSlide key="more">
                            <MoreCategoriesCard count={totalCategories - strippedCategories.length} className="mr-4" onSelect={onMoreSelect}/>
                        </SwiperSlide>)}
                </Swiper>)}
        </div>);
};

<template>
    <div class="dropdown" :class="{ 'dropdown--invalid': error }" @click="toggleDropdown">
        <WasteLabel :big-placeholder-active="bigPlaceholderActive" :placeholder="placeholder" />
        <div class="dropdown__label">
            <span class="dropdown__label-main">{{ firstOption }}</span>
            <span class="dropdown__label-other-choices">{{ allChoicesNumber }}</span>
        </div>
        <slot name="body" />
        <ArrowDownIcon class="select-input__arrow" :class="{ 'select-input__arrow--rotate': rotateArrow }" />
    </div>
</template>

<script>
import ArrowDownIcon from '@/assets/icons/regular/arrow-stripeless--down.svg';
import WasteLabel from '@/pages/ProductManagement/components/WasteLabel';

export default {
    name: 'WasteDropdown',
    components: {
        ArrowDownIcon,
        WasteLabel,
    },
    props: {
        firstOption: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        bigPlaceholderActive: {
            type: Boolean,
            default: true,
        },
        allChoicesNumber: {
            type: String,
            default: '',
        },
        error: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            rotateArrow: false,
        };
    },
    methods: {
        toggleDropdown() {
            this.$emit('toggle');
            this.rotateArrow = !this.rotateArrow;
        },
    },
};
</script>

<style lang="scss" scoped>
.dropdown {
    position: relative;
    height: 70px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    &__label {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 17px;

        &-main {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: auto;
            max-width: 80%;
            margin-top: 27px;
        }

        &-other-choices {
            height: 100%;
            display: flex;
            align-items: center;
            padding-top: 26px;
            margin-left: 4px;
        }
    }

    &--invalid {
        border-color: $color-red;
    }
}

.select-input__arrow {
    width: 14px;
    height: 14px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 11;

    &--rotate {
        transform: rotate(180deg);
    }
}
</style>

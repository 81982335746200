<template>
    <div>
        <div>
            <span class="font-copy-sm-strong mb-6">
                {{ $t('pages.order.orderView.offerAndInvoiceTab.invoice') }}
            </span>

            <div v-for="(eachGroup, i) in order.invoiceData.groups" :key="i" class="mb-12">
                <div class="invoice-block__row">
                    <span class="font-copy-md-strong col-start-1 mb-6">
                        {{
                            $t(
                                {
                                    credit_note: selectCreditNoteDocKey({
                                        creditNote: `pages.order.orderView.offerAndInvoiceTab.invoice_correction.creditNote`,
                                        selfBilling: `pages.order.orderView.offerAndInvoiceTab.invoice_correction.selfBilling`,
                                    }),
                                }[eachGroup.type] || `pages.order.orderView.offerAndInvoiceTab.${eachGroup.type}`
                            )
                        }}
                    </span>
                    <span
                        v-if="eachGroup.type !== transportShipment && !isOrderLineItemGroupTypeDisposal(eachGroup.type)"
                        class="invoice-block__last-column cursor-pointer"
                        @click="openModal('invoiceBlockCostsInfo')"
                    >
                        <SfInfoIcon multi-color size="xs" />
                    </span>
                </div>

                <template v-if="!isDisposal">
                    <div v-for="(eachCost, j) in sortCosts(eachGroup)" :key="j">
                        <LineItem v-if="eachCost.label" class="mb-0 mt-2">
                            <template #label>
                                <span v-if="eachCost.label">
                                    <span
                                        v-if="showCostPrefixPerTransport(eachGroup, eachCost)"
                                        class="font-numbers-md-strong inline whitespace-nowrap"
                                    >
                                        {{ eachCost.qty }} &times;
                                    </span>
                                    <span
                                        v-else-if="showCostPrefixPerTon(eachGroup, eachCost)"
                                        class="font-numbers-md-strong inline whitespace-nowrap"
                                    >
                                        {{ $n(eachCost.qty / 1000, 'qty') }} {{ $t(`units.ton`) }}
                                    </span>
                                    <span class="font-copy-md-strong inline">
                                        {{ eachCost.label }}
                                    </span>
                                </span>

                                <span v-else class="font-copy-sm-strong col-start-1">
                                    {{
                                        $t(
                                            {
                                                credit_note: selectCreditNoteDocKey({
                                                    creditNote: `pages.order.orderView.offerAndInvoiceTab.invoice_correction.creditNote`,
                                                    selfBilling: `pages.order.orderView.offerAndInvoiceTab.invoice_correction.selfBilling`,
                                                }),
                                            }[eachGroup.type] ||
                                                `pages.order.orderView.offerAndInvoiceTab.${eachGroup.type}`
                                        )
                                    }}
                                </span>
                            </template>
                            <template #middleSection>
                                <span
                                    v-if="
                                        (eachCost.unitPrice && eachCost.type === 'material-transport') ||
                                        eachCost.type === 'material'
                                    "
                                    class="invoice-block__middle-column font-copy-md"
                                >
                                    {{ $n(eachCost.unitPrice * 1000, 'currency') }} / {{ $t(`units.ton`) }}
                                </span>
                                <span
                                    v-else-if="
                                        eachCost.unitPrice &&
                                        eachCost.type === 'transport' &&
                                        eachGroup.type === transportShipment
                                    "
                                    class="invoice-block__middle-column font-copy-md"
                                >
                                    {{ $n(eachCost.unitPrice, 'currency') }} /
                                    {{
                                        order.lineItemGroups[0].billingMethod === 'weight'
                                            ? $t(`units.ton`)
                                            : $t(`units.transport`)
                                    }}
                                </span>
                            </template>
                            <template #endSection>
                                <span class="invoice-block__last-column font-copy-md">
                                    {{ $n(eachCost.total, 'currency') }}
                                </span>
                            </template>
                        </LineItem>
                    </div>
                </template>
                <template v-else>
                    <template v-for="(costGroup, j) in sortCosts(eachGroup)">
                        <LineItem v-if="costGroup.label" :key="`label-${j}`" class="mb-0 mt-2">
                            <template #label>
                                <span class="font-copy-md-strong inline">
                                    {{ disposalQty(costGroup.qty) }}
                                </span>
                                <span class="font-copy-md-strong inline">
                                    {{ costGroup.label }}
                                </span>
                            </template>
                            <template #middleSection>
                                {{ disposalPrice(costGroup.unitPrice) }}
                            </template>
                            <template #endSection>
                                {{ disposalTotalPrice(costGroup.total) }}
                            </template>
                        </LineItem>
                        <LineItem v-if="costGroup.label" :key="`vehicle-class-${j}`" class="mt-2">
                            <template #label>
                                {{ vehicleClassAndCount }}
                            </template>
                        </LineItem>
                    </template>
                </template>

                <CustomPositionOverviewSection
                    v-if="hasCustomPosition"
                    class="mt-12"
                    :custom-positions="customPositions"
                    :no-of-transports="transportCount"
                />
            </div>

            <div class="bg p-2">
                <LineItem has-bold-label>
                    <template #label>
                        {{ $t('pages.checkout.summary.costTable.totalNet') }}
                    </template>
                    <template #endSection>
                        <span class="font-copy-md-strong">
                            {{ $n(order.invoiceData.totalNet, 'currency') }}
                        </span>
                    </template>
                </LineItem>
                <div
                    v-for="(key, i) in Object.keys(order.invoiceData.totalTax)"
                    :key="i"
                    class="mb-2 grid grid-cols-[1fr,80px,90px] gap-x-0 md:grid-cols-[2fr,170px,1fr]"
                >
                    <span class="font-copy-md col-start-1 whitespace-nowrap">
                        {{ $t('pages.checkout.summary.costTable.vat', { vat: key }) }}
                    </span>
                    <span class="font-copy-md col-start-3 justify-self-end">
                        {{ $n(order.invoiceData.totalTax[key], 'currency') }}
                    </span>
                </div>
                <LineItem class="mb-0 mt-2">
                    <template #label>
                        {{ $t('pages.checkout.summary.costTable.totalGross') }}
                    </template>
                    <template #endSection>
                        {{ $n(order.invoiceData.totalGross, 'currency') }}
                    </template>
                </LineItem>
            </div>

            <HorizontalDivider size="6" />

            <span class="font-copy-sm col-start-1 text-subdued">
                {{ $t('pages.order.orderView.invoiceStatus') }}
            </span>
            <div class="mt-2 flex justify-between">
                <span class="font-copy-md-strong">
                    {{ $t('pages.order.orderView.invoiceNumber', { number: order.invoiceData.number }) }}
                </span>
                <!-- @TODO make the following a component -->
                <span
                    class="font-copy-md-strong"
                    :class="{
                        'text-success': order.invoiceData.status === 'paid',
                        'text-critical': order.invoiceData.status === 'cancelled',
                        'text-warning': order.invoiceData.status === 'created' || order.invoiceData.status === 'booked',
                    }"
                >
                    {{ $t(`status.invoice.${order.invoiceData.status}`) }}
                </span>
            </div>
        </div>

        <ModalBox ref="invoiceBlockCostsInfo" :headline="$t('pages.checkout.summary.costTable.infoModal.headline')">
            {{
                order.lineItemGroups[0].type === 'transport-delivery'
                    ? $t('pages.checkout.summary.costTable.infoModal.text')
                    : $t('pages.checkout.summary.costTable.infoModal.textPickup')
            }}

            <ButtonGroup>
                <SfButton size="sm" @click="closeModal('invoiceBlockCostsInfo')">
                    {{ $t('pages.checkout.summary.costTable.infoModal.buttonLabel') }}
                </SfButton>
            </ButtonGroup>
        </ModalBox>
    </div>
</template>

<script>
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';

import ModalBox from '@/components/Modal/ModalBox';
import ButtonGroup from '@/components/Button/ButtonGroup';

import LineItem from '@/_components/LineItem/LineItem.vue';
import HorizontalDivider from '@/_components/HorizontalDivider/HorizontalDivider';
import { SfButton, SfInfoIcon } from '@schuettflix/vue-components';
import CustomPositionOverviewSection from '@/pages/Checkout/components/PriceAdjustment/CustomPositionOverviewSection.vue';
import { isOrderLineItemGroupTypeDisposal } from '@/constants/disposal';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';
import { BILLING_METHODS } from '@/constants/billingTypes';
import { getTranslationForBillingMethodUnit } from '@/services/utils/translation';

export default {
    name: 'InvoiceBlock',

    components: {
        ModalBox,
        ButtonGroup,
        CustomPositionOverviewSection,
        LineItem,
        HorizontalDivider,

        SfButton,
        SfInfoIcon,
    },

    props: {
        order: {
            type: Object,
            required: true,
        },
    },

    setup() {
        const { selectCreditNoteDocKey } = useLegalTerms();
        return {
            selectCreditNoteDocKey,
        };
    },

    computed: {
        transportShipment() {
            return ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT;
        },

        isDisposal() {
            return isOrderLineItemGroupTypeDisposal(this.firstOrderLineItemGroup?.type);
        },
        firstOrderLineItemGroup() {
            return this.order?.lineItemGroups[0];
        },
        vehicleClassAndCount() {
            const usedVehicleCount = this.firstOrderLineItemGroup?.lineItems?.filter(transport => {
                return ['delivered', 'confirmation_pending'].includes(transport.transportStatus);
            });
            return `${usedVehicleCount?.length} ${String.fromCodePoint(0x00d7)} ${
                this.firstOrderLineItemGroup?.lineItems[0]?.vehicleName
            }`;
        },
        hasCustomPosition() {
            return this.firstOrderLineItemGroup?.customPositions?.length > 0;
        },
        customPositions() {
            return this.firstOrderLineItemGroup?.customPositions;
        },
        transportCount() {
            return this.firstOrderLineItemGroup.lineItems.length;
        },
    },

    methods: {
        isOrderLineItemGroupTypeDisposal,
        openModal(refName) {
            this.$refs[refName].$emit('open');
        },

        closeModal(refName) {
            this.$refs[refName].$emit('close');
        },

        sortCosts(group) {
            if (group.type !== this.transportShipment) {
                return group.costs;
            }

            const sort = ['transport', 'manual'];

            return [...group.costs].sort((a, b) => {
                const aIndex = sort.indexOf(a.type) === -1 ? Infinity : sort.indexOf(a.type);
                const bIndex = sort.indexOf(b.type) === -1 ? Infinity : sort.indexOf(b.type);

                if (aIndex < bIndex) {
                    return -1;
                }
                if (aIndex > bIndex) {
                    return 1;
                }
                return 0;
            });
        },

        showCostPrefixPerTransport(group, cost) {
            const { billingMethod } = this.order.lineItemGroups[0];

            return (
                billingMethod === BILLING_METHODS.FIXED_PRICE &&
                cost.type === 'transport' &&
                group.type === ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT
            );
        },
        showCostPrefixPerTon(group, cost) {
            const { billingMethod } = this.order.lineItemGroups[0];

            if (
                billingMethod === BILLING_METHODS.WEIGHT &&
                cost.type === 'transport' &&
                group.type === ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT
            ) {
                return true;
            }

            return cost.type === 'material-transport' || cost.type === 'material';
        },

        disposalPrice(unitPrice) {
            const { billingMethod } = this.order.lineItemGroups[0];
            const unit = getTranslationForBillingMethodUnit(billingMethod);
            if (billingMethod === BILLING_METHODS.FIXED_PRICE) {
                return `${this.$n(unitPrice, 'currency')} / ${unit}`;
            }
            return `${this.$n(unitPrice * 1000, 'currency')} / ${unit}`;
        },

        disposalTotalPrice(totalPrice) {
            return this.$n(totalPrice, 'currency');
        },

        disposalQty(qty) {
            return `${this.$n(qty / 1000, 'qty')} ${this.$t('units.ton')}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.invoice-block {
    padding-top: 12px;
    padding-bottom: 12px;
}

.invoice-block__section--highlighted-back {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    background-color: $color-lightMediumGrey;
}

.invoice-block__section--small-bottom-spacing {
    margin-bottom: 4px;
}

.invoice-block__row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 3px 0px;

    @media screen and (max-width: $screen-sm) {
        grid-template-columns: 1fr 80px 90px;
    }
}

.invoice-block__row--spaced-top {
    margin-top: 20px;
}

.invoice-block__row--spaced-bottom {
    margin-bottom: 20px;
}

.invoice-block__middle-column {
    justify-self: flex-end;
}

.invoice-block__last-column {
    justify-self: flex-end;
    grid-column-start: 3;
}
</style>

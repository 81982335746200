<template>
    <LayoutPage is-flyout :screen-name="screenName">
        <Header slot="flyoutHeader" />

        <div class="checkout-delivery-window-selection">
            <div class="container-deprecated">
                <Words v-if="isDisposal" muted small block spaced>
                    {{ $t('pages.checkout.disposalWindowSelection.hint') }}
                </Words>
                <Words v-else muted small block spaced>
                    {{ $t('pages.checkout.deliveryWindowSelection.hint') }}
                </Words>

                <div class="checkout-delivery-window-selection__calendar">
                    <Calendar v-model="selectedDates" type="range" :allowed-dates="availableDates" />

                    <SlideUpDown :active="selectedDates.length === 1">
                        <ChatBubble>
                            <Words v-if="isDisposal">{{ $t('pages.checkout.disposalWindowSelection.notice') }}</Words>
                            <Words v-else>{{ $t('pages.checkout.deliveryWindowSelection.notice') }}</Words>
                        </ChatBubble>
                    </SlideUpDown>
                </div>

                <template v-if="selectedDates.length">
                    <Words block bold tiny class="checkout-delivery-window-selection__selection-headline">
                        {{ getWindowDeliveryTitle }}
                    </Words>
                    <Words block bold spaced-bottom class="checkout-delivery-window-selection__selection-text">
                        {{ formatedDates }}
                    </Words>
                </template>
            </div>
        </div>

        <ButtonGroup slot="sticky">
            <Button
                :disabled="!hasValidData || isPending"
                primary
                type="button"
                data-test="button-apply-calendar"
                @click="submitSelection"
            >
                {{ $t('pages.checkout.deliveryWindowSelection.submit') }}
                <ArrowRightIcon slot="right" class="icon--inline" />
            </Button>
        </ButtonGroup>
    </LayoutPage>
</template>

<script>
import { format, isSameDay, closestTo } from 'date-fns';
import _find from 'lodash/find';
import _uniq from 'lodash/uniq';
import _get from 'lodash/get';
import { mapState, mapGetters } from 'vuex';
import { ensureJSTimestamp } from '@/services/utils/date';

import LayoutPage from '@/components/Layout/Page.v2';
import Header from './components/Header';
import Words from '@/components/Typography/Words';
import ChatBubble from '@/components/Typography/ChatBubble';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Button from '@/components/Button/Button';
import Calendar from '@/components/Form/Calendar';
import SlideUpDown from '@/components/Animation/SlideUpDown';

import ArrowRightIcon from '@/assets/icons/micro/arrow.svg';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';

export default {
    name: 'DeliveryWindowSelectionPage',
    components: {
        LayoutPage,
        Header,
        Words,
        ChatBubble,
        ButtonGroup,
        Button,
        Calendar,
        SlideUpDown,
        ArrowRightIcon,
    },
    setup() {
        return {
            screenName: useOrderScreenName('deliverywindowselection'),
        };
    },
    data() {
        return {
            selectedDates: [],
            isPending: false,
        };
    },
    computed: {
        ...mapState('basket', ['constructionSite', 'deliveryMethod', 'availableShippingMethods']),
        ...mapGetters('basket', ['isDisposal']),

        shippingMethod() {
            if (!this.availableShippingMethods) return null;
            return this.availableShippingMethods.default;
        },

        getWindowDeliveryTitle() {
            if (this.isDisposal) {
                return this.$t('pages.checkout.deliveryWindowSelection.deliveryWindowTitle.loadingRange');
            } else {
                return this.$t('pages.checkout.deliveryWindowSelection.deliveryWindowTitle.deliveryRange');
            }
        },
        availableDates() {
            if (!this.shippingMethod) return [];

            const deliveryDays = this.shippingMethod.deliveryDays.map(o =>
                format(ensureJSTimestamp(o.start), 'YYYY-MM-DD')
            );

            if (this.maxRange && this.selectedDates.length === 1) {
                const index = deliveryDays.indexOf(this.selectedDates[0]);

                return deliveryDays.slice(
                    Math.max(0, index + 1 - this.maxRange),
                    Math.min(deliveryDays.length, index + this.maxRange)
                );
            }

            return deliveryDays;
        },

        maxRange() {
            return _get(this.shippingMethod, 'additionalInformation.maxWindowLength', null);
        },

        hasValidData() {
            return (
                this.selectedDates.length &&
                this.selectedDates.length <= 2 &&
                this.selectedDates.every(o => this.availableDates.includes(o))
            );
        },

        formatedDates() {
            return this.selectedDates.map(s => format(s, 'DD.MM.YYYY')).join(' - ');
        },
    },
    mounted() {
        if (!this.deliveryMethod.deliveryWindow) return;

        const deliveryDays = this.shippingMethod.deliveryDays.map(o =>
            format(ensureJSTimestamp(o.start), 'YYYY-MM-DD')
        );

        const selectedDates = _uniq([
            format(ensureJSTimestamp(this.deliveryMethod.deliveryWindow.start), 'YYYY-MM-DD'),
            format(ensureJSTimestamp(this.deliveryMethod.deliveryWindow.end), 'YYYY-MM-DD'),
        ]).map(o => {
            return !deliveryDays.includes(o) ? format(closestTo(o, deliveryDays), 'YYYY-MM-DD') : o;
        });

        this.selectedDates = _uniq(selectedDates);
    },
    methods: {
        submitSelection() {
            if (this.isPending) return;
            this.isPending = true;

            if (this.hasValidData) {
                let deliveryWindow = this.selectedDates.map(d => {
                    return _find(this.shippingMethod.deliveryDays, o => isSameDay(ensureJSTimestamp(o.start), d));
                });

                deliveryWindow = {
                    start: Math.min(...deliveryWindow.map(o => o.start)),
                    end: Math.max(...deliveryWindow.map(o => o.end)),
                };

                this.$store.commit('basket/setDeliveryWindow', deliveryWindow);

                const basketTranports = this.$store.state.basket.transports;
                const newTransports = basketTranports.transports.map(transport => {
                    return {
                        ...transport,
                        date: deliveryWindow.start,
                        error: null,
                    };
                });
                this.$store.commit('basket/setTransports', {
                    ...basketTranports,
                    transports: newTransports,
                });

                this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(() => {});
            }

            this.isPending = false;
        },
    },
};
</script>

<style lang="scss">
.checkout-delivery-window-selection__calendar {
    margin-bottom: 35px;
}

.checkout-delivery-window-selection__selection-headline {
    margin-bottom: 1em;
}
</style>

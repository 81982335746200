// Use this mixin to declare/perform stateful actions
import { asyncDelay } from '@/services/utils';

/**
 * @deprecated Consider using the useStateful() composable instead
 */
export default {
    data() {
        return {
            statefulList: [],
        };
    },

    methods: {
        async blockState(stateKey, delayMS = 1000) {
            await this.stateful(stateKey, async () => {
                await asyncDelay(delayMS);
            });
        },

        /**
         * Attempt to perform a stateful interaction, if statelist is empty
         * @param {string} stateKey
         * @param {function} fn
         * @return {Promise<*>}
         */
        async stateful(stateKey, fn) {
            if (this.isPending()) return;
            this.startStateTracking(stateKey);
            const result = await fn();
            this.stopStateTracking(stateKey);
            return result;
        },

        /**
         * Perform a stateful interaction, ignore current statelist
         * @param {string} stateKey
         * @param {function} fn
         * @return {Promise<*>}
         */
        async statefulRun(stateKey, fn) {
            this.startStateTracking(stateKey);
            const result = await fn();
            this.stopStateTracking(stateKey);
            return result;
        },

        /**
         * Start state tracking
         * @param {string} stateKey
         */
        startStateTracking(stateKey) {
            if (this.statefulList.indexOf(stateKey) !== -1) {
                return false;
            }

            this.statefulList.push(stateKey);
            return true;
        },

        /**
         * Stop state tracking
         * @param {string} stateKey
         */
        stopStateTracking(stateKey) {
            const index = this.statefulList.indexOf(stateKey);

            if (index === -1) {
                return false;
            }

            this.statefulList.splice(this.statefulList.indexOf(stateKey), 1);
            return true;
        },

        /**
         * Check if the current state is pending
         * Example arguments: [AND AND AND] or [AND AND AND]
         *
         * @param  {...any} conditions
         */
        isPending(...conditions) {
            // check all pending actions
            if (conditions === undefined || conditions.length === 0) {
                return this.statefulList.length > 0;
            }

            return conditions.some(curr => {
                if (!Array.isArray(curr)) {
                    curr = [curr];
                }
                return curr.every(i => this.statefulList.includes(i));
            });
        },
    },
};

export const useAddressFormatter = () => {
    return {
        /**
         * Format Address function for use within the LocationPickerInput component.
         * @param address
         */
        format(address) {
            // Intentionally not using Google Maps' formatted address to circumvent plus-codes being shown
            return [
                [address.street, address.houseNumber].filter(Boolean).join(' '),
                [address.zip, address.city].filter(Boolean).join(' '),
                address.country,
            ]
                .filter(Boolean)
                .join(', ');
        },
        /**
         * Format legacy address attempts to format an address based on multiple shapes
         * @param address
         */
        formatLegacyAddress(address) {
            // Intentionally not using Google Maps' formatted address to circumvent plus-codes being shown
            return [
                [address.street, address.houseNumber, address.number, address.streetNumber, address.addressNumber]
                    .filter(Boolean)
                    .join(' '),
                [address.zip, address.city].filter(Boolean).join(' '),
                address.country,
            ]
                .filter(Boolean)
                .join(', ');
        },
    };
};

<template>
    <div>
        <ModalBox :active="isActive" :headline="$t('pages.order.action.cancel.headline')" @closed="reset()">
            <Words>
                {{ $t('pages.order.action.cancel.message') }}
            </Words>

            <ButtonGroup slot="actions">
                <Button place-left primary arrow-left light @click="reset()">
                    {{ $t('pages.order.action.cancel.actions.cancel') }}
                </Button>

                <Button :is-loading="isPending" primary @click="cancelOrder()">
                    {{ $t('pages.order.action.cancel.actions.confirm') }}
                </Button>
            </ButtonGroup>
        </ModalBox>
    </div>
</template>

<script>
import OrderApi from '@/services/Api/Order';
import PubSubEvent from '@/services/PubSub/PubSubEvent';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { ACTIONS } from '@/constants/actions';
import { EVENT_ORDER_CANCELED, EVENT_ORDER_UPDATED } from '@/constants/events';
import { mapGetters } from 'vuex';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ModalBox from '@/components/Modal/ModalBox';
import Toaster from '@/services/Toaster';
import Words from '@/components/Typography/Words';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';

export default {
    name: 'OrderCancelAction',
    components: {
        Button,
        ButtonGroup,
        ModalBox,
        Words,
    },
    mixins: [eventHubMixin],
    data() {
        return {
            isPending: false,
        };
    },
    computed: {
        ...mapGetters('globalActions', ['subject', 'action']),

        isActive() {
            return this.action === ACTIONS.ORDER_CANCEL;
        },
    },
    methods: {
        reset() {
            this.$store.dispatch('globalActions/reset', ACTIONS.ORDER_CANCEL);
        },

        async cancelOrder() {
            if (this.isPending) return;
            this.isPending = true;

            try {
                await OrderApi.cancelOrder(this.subject.id);
                Toaster.success(this.$t('pages.order.action.cancel.success', { number: this.subject.number }));
                this.$eventHub.$emit(EVENT_ORDER_UPDATED, new PubSubEvent(this.subject));
                this.$eventHub.$emit(EVENT_ORDER_CANCELED, new PubSubEvent(this.subject));
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(this.$t(err.message));
            }

            AnalyticsService.trackClick('orderDetailView', 'cancelOrder', { orderId: this.subject.id });

            this.isPending = false;
            this.reset();
        },
    },
};
</script>

<template>
    <div id="notification-center">
        <NotificationAlert v-for="notification in notifications" :key="notification.hash" :message="notification" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NotificationAlert from './NotificationAlert';

export default {
    name: 'NotificationCenter',
    components: {
        NotificationAlert,
    },
    computed: {
        ...mapGetters('notification', ['notifications']),
    },
};
</script>

<style lang="scss">
#notification-center {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
}
</style>

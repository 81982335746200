<template>
    <Pane>
        <div slot="head">
            <strong>Endpoint</strong>
            <br />
            <small>{{ apiBaseUrl }}</small>
        </div>
        <div slot="body">
            <select class="debug-endpoint-switcher" @change="changeEndpoint">
                <option v-for="(value, key) in list" :key="key" :value="value" :selected="value == apiBaseUrl">
                    {{ key }}
                </option>
            </select>
        </div>
    </Pane>
</template>

<script>
import { mapGetters } from 'vuex';
import Pane from '@/components/Debug/Pane/Pane.vue';

const ENDPOINT_LIST = {
    local: 'https://schuettflix.local.dev.schuett.tech/api',
    netsuite: 'https://backend.netsuite.schuett.tech/',
    int: 'https://int.schuett.tech/api',
    ppe: 'https://ppe.schuett.tech/api',
    stage: 'https://backend.stage.schuett.tech',
    main: 'https://master.dev.schuett.tech/api',
};

export default {
    name: 'EndpointSwitcher',
    components: {
        Pane,
    },
    computed: {
        ...mapGetters('platform', ['apiBaseUrl']),

        list() {
            const list = { ...ENDPOINT_LIST };

            if (!Object.values(list).includes(this.apiBaseUrl)) {
                list['Custom Endpoint'] = this.apiBaseUrl;
            }

            return list;
        },
    },
    methods: {
        changeEndpoint(e) {
            this.$store.commit('platform/setApiBaseUrl', e.target.value);
        },
    },
};
</script>

<style lang="scss">
.debug-endpoint-switcher {
    font-size: 1.5em;
    width: 100%;
    display: block;
}
</style>

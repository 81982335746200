import ConstructionSiteView from '@/models/ConstructionSiteView';
import FactoryView from '@/models/FactoryView';
import OrganizationView from '@/models/OrganizationView';
import ProductView from '@/models/ProductView';
import ProjectView from '@/models/ProjectView';
import QuotationPricesView from '@/models/QuotationPricesView';
import { ContingentProgressView } from '@/components/ContingentProgress';
import _orderBy from 'lodash/orderBy';
import { toKgPrice, toTonPrice } from '@/services/utils/units';
import AddressView from '@/models/AddressView';
import tons from '@/plugins/filters/tons';
import UserView from '@/models/UserView';
import { ensureJSTimestamp } from '@/services/utils/date';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import { differenceInCalendarDays } from 'date-fns';
const PROJECT_POSITION_VALIDITY_THRESHOLD = 7;
/**
 * Class representing project position view model
 */
export default class ProjectPositionView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     * @returns {ProjectPositionView}
     */
    static create(data) {
        if (!data) return null;

        const projectPosition = new ProjectPositionView();
        projectPosition.id = data.id;
        projectPosition.number = data.number;
        projectPosition.type = data.type;
        projectPosition.status = data.status;
        projectPosition.qty = data.qty;
        projectPosition.deliveredQty = data.deliveredQty;
        projectPosition.reservedQty = data.reservedQty;
        projectPosition.orderCount = data.lineItemGroupCount;
        projectPosition.quotationPrices = QuotationPricesView.createFromProjectPositionPayload(data);
        projectPosition.project = ProjectView.create(data.project);
        projectPosition.constructionSite = ConstructionSiteView.create(data.constructionSite);
        projectPosition.product = ProductView.create(data.product, data?.productVariant);
        projectPosition.factory = FactoryView.create(data.factory);
        projectPosition.carrier = OrganizationView.create(data.carrier);
        projectPosition.vehicleClasses = data?.vehicleClasses;
        projectPosition.paymentTerms = data?.paymentTerms;
        projectPosition.image = data.image ?? data.product?.image;
        if (data.type === 'shipment') {
            projectPosition.loadingAddress = AddressView.create(data.loadingAddress);
            projectPosition.loadingName = data.loadingName;
            projectPosition.unloadingAddress = AddressView.create(data.unloadingAddress);
            projectPosition.unloadingName = data.unloadingName;
            projectPosition.freightDescription = data.freightDescription;
            projectPosition.freightType = data.freightType;
            projectPosition.billingMethod = data.billingMethod;
            projectPosition.loadingAddressCostCenter = data.loadingAddressCostCenter;
            projectPosition.loadingAddressType = data.loadingAddressType;
            projectPosition.unloadingAddressCostCenter = data.unloadingAddressCostCenter;
            projectPosition.unloadingAddressType = data.unloadingAddressType;
        }
        if (
            data.type === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS ||
            data.type === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS
        ) {
            projectPosition.loadingAddress = AddressView.create(data.constructionSite?.address);
            projectPosition.loadingName = data.constructionSite?.name;
            projectPosition.unloadingAddress = AddressView.create(data.factory?.address);
            projectPosition.unloadingName = data.factory?.name;
            projectPosition.freightType = data.freightType;
            projectPosition.billingMethod = data.billingMethod;
            projectPosition.wasteCode = data.product?.wasteCode;
            projectPosition.disposalProductVariant = data.productVariant;
            projectPosition.disposalProduct = data.product;
            projectPosition.disposerName = data.disposerName;
            projectPosition.producerNumber = data?.producerNumber;
            projectPosition.disposalProofNumber = data?.disposalProofNumber;
            projectPosition.carrierNumber = data?.carrier?.carrierNumber;
            projectPosition.materialPurchaseUnitPrice = data.materialPurchaseUnitPrice;
            projectPosition.materialRetailUnitPrice = data.materialRetailUnitPriceWithStateWasteCompanyFee;
        }
        projectPosition.materialPurchaseUnitPrice = data.materialPurchaseUnitPrice;
        projectPosition.materialRetailUnitPrice = data.materialRetailUnitPrice;
        projectPosition.weighingAtLoadingPoint = data.weighingAtLoadingPoint;
        projectPosition.weighingAtUnloadingPoint = data.weighingAtUnloadingPoint;
        projectPosition.weighingBillingBasis = data.weighingBillingBasis;
        projectPosition.disposalPricePerTon = data.disposalPricePerTon;
        projectPosition.disposalPricePerTransport = data.disposalPricePerTransport;
        projectPosition.isDisposal =
            data.type === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS ||
            data.type === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS;

        if (data.supplierName && !projectPosition.isDisposal) {
            projectPosition.supplier = OrganizationView.create({
                name: data.supplierName,
            });
        }

        if (data.vehicleClasses) {
            projectPosition.vehicleClasses = data.vehicleClasses.map(vcInfo => {
                return {
                    purchasePrice: toTonPrice(vcInfo.purchasePrice),
                    retailPrice: toTonPrice(vcInfo.retailPrice),
                    vehicleClass: vcInfo.vehicleClass,
                };
            });
        }

        if (data.customPositions) {
            projectPosition.customPositions = data.customPositions;
        }

        projectPosition.contingentProgress = ContingentProgressView.create({
            max: projectPosition.qty,
            primary: projectPosition.deliveredQty,
            secondary: projectPosition.reservedQty,
        });

        if (data.validFrom && data.validTo) {
            projectPosition.validFrom = data.validFrom;
            projectPosition.validTo = data.validTo;
        }

        projectPosition.note = data?.note;
        projectPosition.noteUpdated = data?.noteUpdated;
        projectPosition.noteUpdatedBy = UserView.create(data?.noteUpdatedBy);

        return projectPosition;
    }

    updateContingentProgressView() {
        if (this.contingentProgress) {
            this.contingentProgress.max = this.qty;
            this.contingentProgress.primary = this.deliveredQty;
            this.contingentProgress.secondary = this.reservedQty;
        }
    }

    /**
     * Create a view model
     */
    constructor() {
        this._id = null;
        this._number = null;
        this._type = null;
        this._project = null;
        this._status = null;
        this._product = null;
        this._disposalProduct = null;
        this._disposalProductVariant = null;
        this._constructionSite = null;
        this._factory = null;
        this._qty = null;
        this._deliveredQty = null;
        this._reservedQty = null;
        this._orderCount = null;
        this._contingentProgress = null;
        this._quotationPrices = null;
        this._carrier = null;
        this._supplier = null;
        this._disposerName = null;
        this._vehicleClasses = null;
        this._paymentTerms = null;
        this._loadingAddress = null;
        this._loadingName = null;
        this._unloadingAddress = null;
        this._unloadingName = null;
        this._loadingAddressCostCenter = null;
        this._loadingAddressType = null;
        this._unloadingAddressCostCenter = null;
        this._unloadingAddressType = null;
        this._freightDescription = null;
        this._freightType = null;
        this._weighingAtLoadingPoint = null;
        this._weighingAtUnloadingPoint = null;
        this._weighingBillingBasis = null;
        this._customPositions = null;
        this._billingMethod = null;
        this._note = null;
        this._noteUpdated = null;
        this._noteUpdatedBy = null;
        this._disposalPricePerTon = null;
        this._isDisposal = null;
        this._wasteCode = null;
        this._disposalProofNumber = null;
        this._producerNumber = null;
        this._materialPurchaseUnitPrice = null;
        this._materialRetailUnitPrice = null;
        this._validFrom = null;
        this._validTo = null;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            id: this.id,
            number: this.number,
            type: this.type,
            loadingAddress: this.loadingAddress?.unfold(),
            loadingName: this.loadingName,
            unloadingAddress: this.unloadingAddress?.unfold(),
            unloadingName: this.loadingName,
            loadingAddressCostCenter: this.loadingAddressCostCenter,
            loadingAddressType: this.loadingAddressType,
            unloadingAddressCostCenter: this.unloadingAddressCostCenter,
            unloadingAddressType: this.unloadingAddressType,
            freightDescription: this.freightDescription,
            freightType: this.freightType,
            project: this.project?.unfold(),
            status: this.status,
            product: this.product?.unfold(),
            producerNumber: this.producerNumber,
            disposalProofNumber: this.disposalProofNumber,
            disposalProduct: this.disposalProduct,
            disposalProductVariant: this.disposalProductVariant,
            constructionSite: this.constructionSite?.unfold(),
            factory: this.factory?.unfold(),
            qty: this.qty,
            deliveredQty: this.deliveredQty,
            reservedQty: this.reservedQty,
            lineItemGroupCount: this.orderCount,
            contingentProgress: this.contingentProgress?.unfold(),
            quotationPrices: this.quotationPrices?.unfold(),
            carrier: this.carrier?.unfold(),
            supplier: this.supplier?.unfold(),
            vehicleClasses:
                (this.vehicleClasses &&
                    this.vehicleClasses.map(vcInfo => {
                        return {
                            purchasePrice: vcInfo.purchasePrice ? toKgPrice(vcInfo.purchasePrice) : null,
                            retailPrice: vcInfo.retailPrice ? toKgPrice(vcInfo.retailPrice) : null,
                            vehicleClass: vcInfo.vehicleClass,
                        };
                    })) ||
                null,
            paymentTerms: this.paymentTerms,
            customPositions: this.customPositions,
            billingMethod: this.billingMethod,
            note: this.note,
            noteUpdated: this.noteUpdated,
            noteUpdatedBy: this.noteUpdatedBy?.unfold(),
            disposalPricePerTon: this.disposalPricePerTon,
            disposalPricePerTransport: this.disposalPricePerTransport,
            isDisposal: this.isDisposal,
            wasteCode: this.wasteCode,
            materialPurchaseUnitPrice: this.materialPurchaseUnitPrice,
            materialRetailUnitPrice: this.materialRetailUnitPrice,
            validFrom: this.validFrom,
            validTo: this.validTo,
        };
    }

    /**
     * Clone the current view
     * @return {ProjectPositionView}
     */
    clone() {
        return ProjectPositionView.create(this.unfold());
    }

    /**
     * Get id
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * Set id
     * @param {number} value
     */
    set id(value) {
        this._id = value;
    }

    /**
     * Get number
     * @returns {string}
     */
    get number() {
        return this._number;
    }

    /**
     * Set number
     * @param {string} value
     */
    set number(value) {
        this._number = value;
    }

    /**
     * Get type
     * @returns {string}
     */
    get type() {
        return this._type;
    }

    /**
     * Set type
     * @param {string} value
     */
    set type(value) {
        this._type = value;
    }

    /**
     * Set loadingAddress
     * @param value
     */
    set loadingAddress(value) {
        this._loadingAddress = value;
    }

    /**
     * Get loadingAddress
     * @returns {null}
     */
    get loadingAddress() {
        return this._loadingAddress;
    }

    /**
     * Set loadingName
     * @param {string} value
     */
    set loadingName(value) {
        this._loadingName = value;
    }
    /**
     * Get loadingName
     * @returns {string}
     */
    get loadingName() {
        return this._loadingName;
    }

    /**
     * Set unloadingAddress
     * @param value
     */
    set unloadingAddress(value) {
        this._unloadingAddress = value;
    }

    /**
     * Get unloadingAddress
     * @returns {null}
     */
    get unloadingAddress() {
        return this._unloadingAddress;
    }

    /**
     * Set unloadingName
     * @param {string} value
     */
    set unloadingName(value) {
        this._unloadingName = value;
    }

    /**
     * Get unloadingName
     * @returns {string}
     */
    get unloadingName() {
        return this._unloadingName;
    }

    /**
     * Set loadingAddressCostCenter
     * @param {string} value
     */
    set loadingAddressCostCenter(value) {
        this._loadingAddressCostCenter = value;
    }

    /**
     * Get loadingAddressCostCenter
     * @returns {string}
     */
    get loadingAddressCostCenter() {
        return this._loadingAddressCostCenter;
    }

    /**
     * Set loadingAddressType
     * @param {string} value
     */
    set loadingAddressType(value) {
        this._loadingAddressType = value;
    }

    /**
     * Get _loadingAddressType
     * @returns {string}
     */
    get loadingAddressType() {
        return this._loadingAddressType;
    }

    /**
     * Set unloadingAddressCostCenter
     * @param {string} value
     */
    set unloadingAddressCostCenter(value) {
        this._unloadingAddressCostCenter = value;
    }

    /**
     * Get unloadingAddressCostCenter
     * @returns {string}
     */
    get unloadingAddressCostCenter() {
        return this._unloadingAddressCostCenter;
    }

    /**
     * Set unloadingAddressType
     * @param {string} value
     */
    set unloadingAddressType(value) {
        this._unloadingAddressType = value;
    }

    /**
     * Get __unloadingAddressCostCenter
     * @returns {string}
     */
    get unloadingAddressType() {
        return this._unloadingAddressType;
    }

    /**
     * Set freightDescription
     * @param {string} value
     */
    set freightDescription(value) {
        this._freightDescription = value;
    }

    /**
     * Get freightDescription
     * @returns {string}
     */
    get freightDescription() {
        return this._freightDescription;
    }

    /**
     * Set freightType
     * @param {string} value
     */
    set freightType(value) {
        this._freightType = value;
    }

    /**
     * Get freightType
     * @returns {string}
     */
    get freightType() {
        return this._freightType;
    }

    /**
     * Set weighingAtLoadingPoint
     * @param {string} value
     */
    set weighingAtLoadingPoint(value) {
        this._weighingAtLoadingPoint = value;
    }

    /**
     * Get weighingAtLoadingPoint
     * @returns {string}
     */
    get weighingAtLoadingPoint() {
        return this._weighingAtLoadingPoint;
    }

    /**
     * Set weighingAtUnloadingPoint
     * @param {string} value
     */
    set weighingAtUnloadingPoint(value) {
        this._weighingAtUnloadingPoint = value;
    }

    /**
     * Get weighingAtUnloadingPoint
     * @returns {string}
     */
    get weighingAtUnloadingPoint() {
        return this._weighingAtUnloadingPoint;
    }

    /**
     * Set weighingBillingBasis
     * @param {string} value
     */
    set weighingBillingBasis(value) {
        this._weighingBillingBasis = value;
    }

    /**
     * Get weighingBillingBasis
     * @returns {string}
     */
    get weighingBillingBasis() {
        return this._weighingBillingBasis;
    }

    /**
     * Get project
     * @returns {ProjectView}
     */
    get project() {
        return this._project;
    }

    /**
     * Set project
     * @param {ProjectView} value
     */
    set project(value) {
        this._project = value;
    }

    /**
     * Get status
     * @returns {string}
     */
    get status() {
        return this._status;
    }

    /**
     * Set status
     * @param {string} value
     */
    set status(value) {
        this._status = value;
    }

    /**
     * Get product
     * @returns {ProductView}
     */
    get product() {
        return this._product;
    }

    /**
     * Set product
     * @param {ProductView} value
     */
    set product(value) {
        this._product = value;
    }

    /**
     * Set product
     * @param {object} value
     */
    set disposalProduct(value) {
        this._disposalProduct = value;
    }

    /**
     * Get disposalProduct
     * @returns {object}
     */
    get disposalProduct() {
        return this._disposalProduct;
    }

    /**
     * Get disposalProductVariant
     * @returns {ProductView}
     */
    get disposalProductVariant() {
        return this._disposalProductVariant;
    }

    /**
     * Set disposalProductVariant
     * @param {ProductView} value
     */
    set disposalProductVariant(value) {
        this._disposalProductVariant = value;
    }

    /**
     * Get construction site
     * @returns {ConstructionSiteView}
     */
    get constructionSite() {
        return this._constructionSite;
    }

    /**
     * Set construction site
     * @param {ConstructionSiteView} value
     */
    set constructionSite(value) {
        this._constructionSite = value;
    }

    /**
     * Get factory
     * @returns {FactoryView}
     */
    get factory() {
        return this._factory;
    }

    /**
     * Set factory
     * @param {FactoryView} value
     */
    set factory(value) {
        this._factory = value;
    }

    /**
     * Get qty
     * @returns {number}
     */
    get qty() {
        return this._qty;
    }

    /**
     * Set qty
     * @param {number} value
     */
    set qty(value) {
        this._qty = value;
        this.updateContingentProgressView();
    }

    /**
     * Get delivered qty
     * @returns {number}
     */
    get deliveredQty() {
        return this._deliveredQty;
    }

    /**
     * Set delivered qty
     * @param {number} value
     */
    set deliveredQty(value) {
        this._deliveredQty = value;
        this.updateContingentProgressView();
    }

    /**
     * Get reserved qty
     * @returns {number}
     */
    get reservedQty() {
        return this._reservedQty;
    }

    /**
     * Set reserved qty
     * @param {number} value
     */
    set reservedQty(value) {
        this._reservedQty = value;
        this.updateContingentProgressView();
    }

    /**
     * Get payment terms
     * @returns {[]}
     */
    get paymentTerms() {
        return this._paymentTerms;
    }

    /**
     * Set payment terms
     * @param {[]} value
     */
    set paymentTerms(value) {
        this._paymentTerms = value;
    }

    /**
     * Get remaining qty
     * @returns {number}
     */
    get remainingQty() {
        return this.qty - this.deliveredQty - this.reservedQty;
    }

    /**
     * Get order count
     * @returns {number}
     */
    get orderCount() {
        return this._orderCount;
    }

    /**
     * Set order count
     * @param {number} value
     */
    set orderCount(value) {
        this._orderCount = value;
    }

    /**
     * Get contingent progress
     * @returns {ContingentProgressView}
     */
    get contingentProgress() {
        return this._contingentProgress;
    }

    /**
     * Set contingent progress
     * @param {ContingentProgressView} value
     */
    set contingentProgress(value) {
        this._contingentProgress = value;
    }

    /**
     * Get quotation prices
     * @returns {QuotationPricesView}
     */
    get quotationPrices() {
        return this._quotationPrices;
    }

    /**
     * Set quotation prices
     * @param {QuotationPricesView} value
     */
    set quotationPrices(value) {
        this._quotationPrices = value;
    }

    /**
     * Get carrier
     * @returns {OrganizationView}
     */
    get carrier() {
        return this._carrier;
    }

    /**
     * Set carrier
     * @param {OrganizationView} value
     */
    set carrier(value) {
        this._carrier = value;
    }

    /**
     * Get supplier
     * @returns {OrganizationView}
     */
    get supplier() {
        return this._supplier;
    }

    /**
     * Set supplier
     * @param {OrganizationView} value
     */
    set supplier(value) {
        this._supplier = value;
    }

    /**
     * Get disposer
     * @returns {object}
     */
    get disposerName() {
        return this._disposerName;
    }

    /**
     * Set disposerName
     * @param {object} value
     */
    set disposerName(value) {
        this._disposerName = value;
    }

    /**
     * Get vehicle classes
     * @returns {[object]}
     */
    get vehicleClasses() {
        return this._vehicleClasses;
    }

    /**
     * Set vehicle classes
     * @param {[object]} value
     */
    set vehicleClasses(value) {
        this._vehicleClasses = value ?? [];
    }

    /**
     * Get custom positions
     * @returns {[object]}
     */
    get customPositions() {
        return this._customPositions;
    }

    /**
     * Set custom positions
     * @param {[object]} value
     */
    set customPositions(value) {
        this._customPositions = value ?? null;
    }

    /**
     * Get billing method
     * @returns {[object]}
     */
    get billingMethod() {
        return this._billingMethod;
    }

    /**
     * Set billing method
     * @param {[object]} value
     */
    set billingMethod(value) {
        this._billingMethod = value ?? null;
    }

    /**
     * Get note
     * @returns {string}
     */
    get note() {
        return this._note;
    }

    /**
     * Set note
     * @param {string} value
     */
    set note(value) {
        this._note = value;
    }

    /**
     * Get note updated
     * @returns {number|null}
     */
    get noteUpdated() {
        return this._noteUpdated;
    }

    /**
     * Set note updated
     * @param {number|null} value
     */
    set noteUpdated(value) {
        this._noteUpdated = ensureJSTimestamp(value);
    }

    /**
     * Get note updated by
     * @returns {UserView|null}
     */
    get noteUpdatedBy() {
        return this._noteUpdatedBy;
    }

    /**
     * Set note updated by
     * @param {UserView|null} value
     */
    set noteUpdatedBy(value) {
        this._noteUpdatedBy = value ?? null;
    }

    /**
     * Get disposal price per transport
     * @returns {number|null}
     */
    get disposalPricePerTransport() {
        return this._disposalPricePerTransport;
    }

    /**
     * Set disposal price per transport
     * @param {number|null} value
     */
    set disposalPricePerTransport(value) {
        this._disposalPricePerTransport = value ?? null;
    }

    /**
     * Get disposal price per ton
     * @returns {number|null}
     */
    get disposalPricePerTon() {
        return this._disposalPricePerTon;
    }

    /**
     * Set disposal price per ton
     * @param {number|null} value
     */
    set disposalPricePerTon(value) {
        this._disposalPricePerTon = value ?? null;
    }

    /* ----- */

    /**
     * Get type tag
     * @returns {object}
     */
    get typeTag() {
        return {
            type: 'default',
            label: `model.ProjectPositionView.type.${this.type}`,
        };
    }

    /**
     * Get status tag
     * @returns {object}
     */
    get statusTag() {
        let type;
        switch (this.status) {
            case 'open':
                type = 'positive';
                break;
            case 'inactive':
            case 'created':
                type = 'default';
                break;
            case 'closed':
                type = 'error';
                break;
        }
        return {
            type,
            label: `status.projectPosition.${this.status === 'created' ? 'inactive' : this.status}`,
        };
    }

    /**
     * Get tags
     * @returns {Array}
     */
    get tags() {
        return [this.typeTag, this.statusTag];
    }

    /**
     * Get address progress list (for AddressProgress :list)
     * @returns {Array}
     */
    get addressProgressList() {
        if (this.type === 'shipment') {
            return [
                {
                    headline: this.loadingName || this.loadingAddress.additionalAddress,
                    withoutAdditionalAddress: Boolean(this.loadingName),
                    address: this.loadingAddress,
                },
                {
                    headline: this.unloadingName || this.unloadingAddress.additionalAddress,
                    withoutAdditionalAddress: Boolean(this.unloadingName),
                    address: this.unloadingAddress,
                },
            ];
        } else if (this.isDisposal) {
            return [
                {
                    headline: this.constructionSite?.name,
                    address: this.constructionSite?.address,
                },
                {
                    headline: this.disposerName,
                    address: this.factory?.address,
                },
            ];
        }
        return [
            {
                headline: this.supplier?.name,
                address: this.factory?.address,
            },
            {
                headline: this.constructionSite?.name,
                address: this.constructionSite?.address,
            },
        ];
    }

    /**
     * Has a project position a carrier assigned
     * @return {boolean}
     */
    get hasCarrierAssigned() {
        return this.carrier !== null;
    }

    /**
     * Get biggest vehicle class info
     * @returns {object}
     */
    get biggestVehicleClassInfo() {
        if (this.vehicleClasses === null || this.vehicleClasses.length === 0) {
            return null;
        }

        const vehicleClasses = _orderBy(this.vehicleClasses, ['vehicleClass.permissibleTotalWeight'], ['desc']);

        return vehicleClasses[0];
    }

    /**
     * Get title
     * @returns {string}
     */
    get title() {
        if (this.type === 'shipment') {
            return `${tons(this.qty)} ${this.freightDescription}`;
        } else if (this.type === 'waste') {
            return `${tons(this.qty)} ${this.wasteCode} ${this.product.productNameLocalized}`;
        } else if (this.type === 'dangerousWaste') {
            return `${tons(this.qty)} ${this.wasteCode}* ${this.product.productNameLocalized}`;
        } else {
            return `${tons(this.qty)} ${this.product ? this.product.productNameLocalized : ''}`;
        }
    }

    /**
     * Get isDisposal
     * @returns {boolean}
     */
    get isDisposal() {
        return this._isDisposal;
    }

    /**
     * Set isDisposal
     * @param {boolean} value
     */
    set isDisposal(value) {
        this._isDisposal = value;
    }

    /**
     * Get wasteCode
     * @returns {string}
     */
    get wasteCode() {
        return this._wasteCode;
    }

    /**
     * Set wasteCode
     * @param {string} value
     */
    set wasteCode(value) {
        this._wasteCode = value;
    }

    /**
     * Get disposalProofNumber
     * @returns {string|null}
     */
    get disposalProofNumber() {
        return this._disposalProofNumber;
    }

    /**
     * Set disposalProofNumber
     * @param {string} value
     */
    set disposalProofNumber(value) {
        this._disposalProofNumber = value;
    }

    /**
     * Get producerNumber
     * @returns {string|null}
     */
    get producerNumber() {
        return this._producerNumber;
    }

    /**
     * Set producerNumber
     * @param {string} value
     */
    set producerNumber(value) {
        this._producerNumber = value;
    }

    /**
     * Set materialPurchaseUnitPrice
     * @param {number|null} value
     */

    set materialPurchaseUnitPrice(value) {
        this._materialPurchaseUnitPrice = value || null;
    }

    /**
     * Get materialPurchaseUnitPrice
     */
    get materialPurchaseUnitPrice() {
        return this._materialPurchaseUnitPrice;
    }

    /**
     * Set materialRetailUnitPrice
     * @param {number|null} value
     */

    set materialRetailUnitPrice(value) {
        this._materialRetailUnitPrice = value || null;
    }

    /**
     * Get materialRetailUnitPrice
     */
    get materialRetailUnitPrice() {
        return this._materialRetailUnitPrice;
    }

    /**
     *  Get validFrom
     */
    get validFrom() {
        return this._validFrom;
    }

    /**
     * Set validFrom
     * @param {string} value
     */
    set validFrom(value) {
        this._validFrom = value;
    }

    /**
     * Get validTo
     */
    get validTo() {
        return this._validTo;
    }

    /**
     * Set validTo
     * @param {string} value
     */

    set validTo(value) {
        this._validTo = value;
    }

    /**
     * Get remaining validity in days
     * @return {number|null}
     */
    get remainingValidityInDays() {
        if (this.validTo === null) {
            return null;
        }

        return differenceInCalendarDays(this.validTo, new Date()) + 1;
    }

    /**
     * Get show remaining validity
     * @return {boolean}
     */
    get showRemainingValidity() {
        if (this.remainingValidityInDays === null || this.remainingValidityInDays < 0) {
            return false;
        }

        return this.remainingValidityInDays <= PROJECT_POSITION_VALIDITY_THRESHOLD;
    }

    /**
     * Get ability to toggle project position state
     * @return {boolean}
     */
    get shouldBeAbleToToggleProjectPositionState() {
        if (this.remainingValidityInDays === null) {
            return true;
        }

        return this.remainingValidityInDays !== null && this.remainingValidityInDays > 0;
    }
}

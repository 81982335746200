import { asVueComponent } from '@/reactBridge/asVueComponent';
import { useState } from 'react';
import { Transition } from '@headlessui/react';
import Toaster from '@/services/Toaster';
import { PaymentTermsSelection } from '@/features/PaymentTermsSelection';
const _ReactPlaygroundPage = () => {
    const [isShowing, setIsShowing] = useState(false);
    const handleChangedPaymentTerm = (value, organizationType) => {
        console.log('value', value, 'org type', organizationType);
    };
    const paymentTermCarrierOrganization = {
        id: 15,
        type: 'carrier',
    };
    const paymentTermSupplierOrganization = {
        id: 14,
        type: 'supplier',
    };
    return (<>
            <PaymentTermsSelection paymentTermId={1} onChange={paymentTerm => handleChangedPaymentTerm(paymentTerm, 'carrier')} organizationType={paymentTermCarrierOrganization?.type}/>
            <PaymentTermsSelection paymentTermId={5} onChange={paymentTerm => handleChangedPaymentTerm(paymentTerm, 'supplier')} organizationType={paymentTermSupplierOrganization.type}/>
            <button onClick={() => setIsShowing(isShowing => !isShowing)}>Toggle Animated div</button>
            <Transition show={isShowing} enter="transition-opacity duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
                <div className="mb-4 block bg-surface-dark text-on-critical">I will appear and disappear.</div>
            </Transition>

            <button onClick={() => {
            Toaster.success('ex labore veniam est sint proident excepteur consectetur non ex et veniam id quis cupidatat ut deserunt ad nisi culpa');
        }}>
                Trigger success
            </button>
            <br />
            <button onClick={() => {
            Toaster.error('ex labore veniam est sint proident excepteur consectetur non ex et veniam id quis cupidatat ut deserunt ad nisi culpa');
        }}>
                Trigger erorr
            </button>
            <br />
            <button onClick={() => {
            Toaster.info('ex labore veniam est sint proident excepteur consectetur non ex et veniam id quis cupidatat ut deserunt ad nisi culpa');
        }}>
                Trigger info
            </button>
            <br />
            <button onClick={() => {
            Toaster.warning('short warning');
        }}>
                Trigger warning
            </button>
            <br />
        </>);
};
export const ReactPlaygroundPage = asVueComponent(_ReactPlaygroundPage);

import { toKgPrice, toTonPrice } from '@/services/utils/units';
import { revertLocalizedValue } from '@/services/utils/localization';

export default class MaterialPricingView {
    constructor() {
        this._info = null;
        this._purchaseUnitPrice = null;
        this._retailUnitPrice = null;
        this._qty = null; // in kg
    }

    /**
     * Create a view model for material pricing
     *
     * @param data
     * @return {MaterialPricingView|null}
     */
    static createFromCustomOrder(data) {
        if (!data) return null;

        const view = new MaterialPricingView();

        view.info = data.info;
        view.purchaseUnitPrice = toTonPrice(data.purchasePrice);
        view.retailUnitPrice = toTonPrice(data.retailPrice);
        view.qty = data.qty;

        return view;
    }

    /**
     * Create a view model for material pricing
     *
     * @param data
     * @return {MaterialPricingView|null}
     */
    static createFromProjectPositionPayload(data) {
        if (!data) return null;

        const view = new MaterialPricingView();

        if (data.product) {
            view.info = revertLocalizedValue(data.product.productName);
        }

        view.purchaseUnitPrice = toTonPrice(data.materialPurchaseUnitPrice);
        view.retailUnitPrice = toTonPrice(
            data.materialRetailUnitPriceWithStateWasteCompanyFee || data.materialRetailUnitPrice
        );
        view.qty = data.qty;

        return view;
    }
    /**
     * Create a view model for material pricing
     *
     * @param data
     * @return {MaterialPricingView|null}
     */
    static create(data) {
        if (!data) return null;

        const view = new MaterialPricingView();

        view.info = data.info;
        view.purchaseUnitPrice = data.purchaseUnitPrice ? toTonPrice(data.purchaseUnitPrice) : null;
        view.retailUnitPrice = data.retailUnitPrice ? toTonPrice(data.retailUnitPrice) : null;
        view.qty = data.qty;

        return view;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            info: this.info,
            purchaseUnitPrice: this.purchaseUnitPrice >= 0 ? toKgPrice(this.purchaseUnitPrice) : null,
            retailUnitPrice: this.retailUnitPrice >= 0 ? toKgPrice(this.retailUnitPrice) : null,
            qty: this.qty,
        };
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfoldToCustomOrder() {
        return {
            info: this.info,
            purchasePrice: this.purchaseUnitPrice >= 0 ? toKgPrice(this.purchaseUnitPrice) : null,
            retailPrice: this.retailUnitPrice >= 0 ? toKgPrice(this.retailUnitPrice) : null,
            qty: this.qty,
        };
    }

    /**
     * Clone current instance
     * @return {MaterialPricingView|null}
     */
    clone() {
        return MaterialPricingView.create(this.unfold());
    }

    get info() {
        return this._info;
    }

    set info(value) {
        this._info = value;
    }

    get purchaseUnitPrice() {
        return this._purchaseUnitPrice;
    }

    set purchaseUnitPrice(value) {
        this._purchaseUnitPrice = value;
    }

    get retailUnitPrice() {
        return this._retailUnitPrice;
    }

    set retailUnitPrice(value) {
        this._retailUnitPrice = value;
    }

    get qty() {
        return this._qty;
    }

    set qty(value) {
        this._qty = value;
    }

    // Getter --------------------

    get purchasePriceTotal() {
        return this.purchaseUnitPrice * (this.qty / 1000);
    }

    get retailPriceTotal() {
        return this.retailUnitPrice * (this.qty / 1000);
    }
}

/**
 * Interval proxy for the component lifecycle
 *
 * @param {*} callback
 * @param {*} ms
 */
export default function interval(callback, ms) {
    const intervalId = setInterval(() => {
        // cleanup if the compoinent is unmounted, destroyed or garbage collected
        if (this === undefined || this === null || this._isDestroyed || !this._isMounted) {
            clearInterval(intervalId);
            return;
        }
        callback();
    }, ms);

    return intervalId;
}

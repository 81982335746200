<template>
    <ProjectContingentSelectionPage
        :maximum-qty="maximumQty"
        :project-position-id="projectPositionId"
        :weight="savedQty"
        :basket-product="basketProduct"
        :basket-type="basketType"
        @updateWeightInStore="updateWeightInStore"
        @projectPositionSaved="$emit('projectPositionSaved', $event)"
        @close="$emit('close', $event)"
    />
</template>

<script>
import GlobalVariables from '@/services/GlobalVariables';
import ProjectContingentSelectionPage from '@/pages/Checkout/ProjectContingentSelectionPage';
import { mapGetters } from 'vuex';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';

export default {
    name: 'QuantitySelection',

    components: { ProjectContingentSelectionPage },

    props: {
        projectPositionId: {
            type: [Number, String],
            default: null,
        },
    },

    data: () => ({
        maximumQty: null,
        savedQty: null,
    }),

    computed: {
        ...mapGetters('projectPosition', {
            basketType: 'getBasketType',
            basketProduct: 'getProduct',
            basketQuantity: 'getQuantity',
        }),
    },

    async created() {
        let gVar = 'order_method_project_maximum_order_qty_for_dangerous_waste';
        if (this.basketType === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS) {
            gVar = 'order_method_project_maximum_order_qty';
        }
        this.maximumQty = await GlobalVariables.get(gVar, 0);

        if (null === this.basketQuantity) return;
        this.savedQty = this.basketQuantity / 1000;
    },

    methods: {
        updateWeightInStore(weightInKg) {
            this.$store.commit('projectPosition/SET_REQUEST_COLLECTION', {
                requestCollection: { qty: weightInKg },
                property: 'qty',
            });
        },
    },
};
</script>

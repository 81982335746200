<template>
    <!-- table for desktop view -->
    <DataTable v-if="!isMobileView">
        <template #header>
            <tr>
                <th>{{ $t('pages.orderQuantity.table.product') }}</th>
                <th>{{ $t('pages.orderQuantity.table.quantity') }}</th>
                <th>
                    {{ $t('pages.orderQuantity.table.turnover') }}
                    <span class="ml-3 cursor-pointer" @click="$emit('openTurnoverInfoModal')">
                        <InfoIcon width="20" height="20" class="icon--inline" />
                    </span>
                </th>
            </tr>
        </template>

        <tr v-for="product in products" :key="product.id">
            <td class="w-3/4 max-w-0">
                <div class="flex min-h-16 truncate">
                    <SfThumbnail
                        :src="product.image && product.image.url"
                        :file-thumbnail-src="product.image && product.image.url && product.image.url.thumbnail"
                        :file-src="product.image && product.image.url && product.image.url.original"
                        :translations="{
                            noImage: $t('components.thumbnail.noImage'),
                        }"
                        modal
                    />
                    <div v-if="!isMaterial(product)" class="ml-6 self-center truncate">
                        <div class="mb-1 flex items-center">
                            <span class="font-headline-md-strong whitespace-nowrap">
                                {{ product.productWasteCode }}<span v-if="isDangerousWaste(product)">*</span>
                            </span>
                            <SfTag class="ml-4 whitespace-nowrap">
                                {{ product.productVariantPollutionTypeLabel }}
                            </SfTag>
                        </div>

                        <span class="block truncate">{{ revertLocalizedValue(product.productName) }}</span>
                    </div>
                    <div v-else class="ml-6 self-center">
                        <span class="whitespace-nowrap">{{ revertLocalizedValue(product.productName) }}</span>
                    </div>
                </div>
            </td>

            <td>{{ product.qty }} {{ $t('units.kilogram') }}</td>

            <td>
                <span class="font-bold">{{ $n(product.revenue, 'currency') }}</span>
            </td>
        </tr>
    </DataTable>

    <!-- tile item list for mobile view -->
    <List v-else inner-shadow>
        <ListLabel v-stuckable="10">
            {{ $t('pages.orderQuantity.table.product') }}
        </ListLabel>
        <Tile v-for="product in products" :key="product.id" no-border>
            <div class="flex min-h-16 flex-row items-center">
                <SfThumbnail
                    :src="product.image && product.image.url"
                    :file-thumbnail-src="product.image && product.image.url && product.image.url.thumbnail"
                    :file-src="product.image && product.image.url && product.image.url.original"
                    :translations="{
                        noImage: $t('components.thumbnail.noImage'),
                    }"
                />

                <div class="ml-6 md:flex md:w-full md:flex-row md:items-center md:justify-between">
                    <div v-if="!isMaterial(product)" class="self-center sm-max:w-full md:w-2/4">
                        <div class="mb-1 flex items-center">
                            <span class="font-headline-md-strong whitespace-nowrap">
                                {{ product.productWasteCode }}<span v-if="isDangerousWaste(product)">*</span>
                            </span>
                            <SfTag class="ml-4 whitespace-nowrap">{{ product.productVariantPollutionTypeLabel }}</SfTag>
                        </div>

                        <span class="block overflow-hidden whitespace-normal">
                            {{ revertLocalizedValue(product.productName) }}
                        </span>
                    </div>
                    <span v-else class="block md:ml-6 md:w-2/4">
                        {{ revertLocalizedValue(product.productName) }}
                    </span>
                    <span class="mt-2 block md:ml-6">{{ product.qty }} {{ $t('units.kilogram') }}</span>
                    <span class="mt-2 block font-bold md:ml-6">
                        {{ $n(product.revenue, 'currency') }}
                    </span>
                </div>
            </div>
        </Tile>
    </List>
</template>

<script>
import { SfTag, SfThumbnail } from '@schuettflix/vue-components';
import { revertLocalizedValue } from '@/services/utils/localization';

import DataTable from '@/components/Table/DataTable';
import List from '@/components/List/List';
import ListLabel from '@/components/List/ListLabel';
import Tile from '@/components/Layout/Tile';

import InfoIcon from '@/assets/icons/regular/info.svg';

export default {
    name: 'OrderQuantityTable',

    components: {
        SfTag,
        SfThumbnail,
        DataTable,
        List,
        ListLabel,
        Tile,

        InfoIcon,
    },

    props: {
        products: {
            type: Array,
            default: () => [],
        },

        isMobileView: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        revertLocalizedValue,
        isMaterial(product) {
            return product.productType === 'material';
        },
        isDangerousWaste(product) {
            return product.productIsDangerous;
        },
    },
};
</script>

<template>
    <div class="sub-navigation-context-menu__wrapper">
        <button
            class="sub-navigation-context-menu__button"
            :class="{ 'sub-navigation-context-menu__button--is-active': contextMenuIsActive }"
            @click="toggleContextMenu"
        >
            <ContextMenu
                v-if="!contextMenuIsActive"
                class="sub-navigation-context-menu__icon sub-navigation-context-menu__icon--context-menu"
                data-test="sub-navigation-context-menu"
            />
            <CloseMenu
                v-if="contextMenuIsActive"
                class="sub-navigation-context-menu__icon sub-navigation-context-menu__icon--close-menu"
                data-test="sub-navigation-context-menu-close"
            />
        </button>

        <div
            v-if="contextMenuIsActive"
            class="sub-navigation-context-menu"
            data-test="sub-navigation-context-menu-delete"
            @click="onContextMenuClicked"
        >
            <Garbage />
            {{ $t('components.factoryManagement.subNavigationContextMenu.deleteLocation') }}
        </div>
    </div>
</template>

<script>
import ContextMenu from '@/assets/icons/regular/context_menu.svg';
import CloseMenu from '@/assets/icons/regular/close.svg';
import Garbage from '@/assets/icons/regular/garbage.svg';

export default {
    name: 'SubNavigationContextMenu',
    components: {
        ContextMenu,
        CloseMenu,
        Garbage,
    },
    data() {
        return {
            contextMenuIsActive: false,
        };
    },
    methods: {
        toggleContextMenu() {
            this.contextMenuIsActive = !this.contextMenuIsActive;
        },
        onContextMenuClicked() {
            this.contextMenuIsActive = false;
            this.$emit('contextMenuClicked');
        },
    },
};
</script>

<style lang="scss" scoped>
.sub-navigation-context-menu {
    align-items: center;
    background: #fff;
    color: $color-darkGrey;
    cursor: pointer;
    display: flex;
    filter: drop-shadow(0px 5px 12px rgba(0, 0, 0, 0.2));
    font-size: 14px;
    font-weight: 700;
    min-width: 190px;
    padding: 11px 18px 11px 20px;
    position: absolute;
    right: 0;
    white-space: nowrap;
    z-index: 1;

    &__wrapper {
        max-width: 52px;
        position: relative;
    }

    &__button {
        appearance: none;
        background: none;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        height: 35px;
        margin-left: 40px;
        padding: 0;
        width: 35px;

        &--is-active {
            background-color: #f2f2f2;
        }
    }

    &__icon {
        margin: 0 auto;

        &--close-menu {
            height: 18px;
            width: 21px;
        }
    }

    svg {
        margin-right: 10px;
    }
}
</style>

import { forwardRef } from 'react';
import clsx from 'clsx';
import { useIconSize } from '@/services/utils/useIconSize';
export const CloseIcon = forwardRef(({ className, size = 'xs', strokeCssClass, ...svgProps }, ref) => {
    const iconSize = useIconSize(size);
    return (<svg width={iconSize} height={iconSize} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} className={clsx('cursor-pointer', className, strokeCssClass)} {...svgProps}>
                <path d="m2.667 2.667 10.666 10.667M2.667 13.336 13.335 2.667" strokeWidth={1.333} strokeMiterlimit={10}/>
            </svg>);
});
CloseIcon.displayName = 'CloseIcon';

import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _cloneDeep from 'lodash/cloneDeep';
import _includes from 'lodash/includes';
import Authenticator from '@/services/Api/Authenticator';
import UserLinkApi from '@/services/Api/UserLink';
import ApiService from '@/services/Api/ApiService';
import ImpersonationApi from '@/services/Api/Platform/Impersonation';
import LogService from '@schuettflix/util-log';
import Toaster from '@/services/Toaster';
import SpectatorService from '@/services/SpectatorService';
import { EventBus } from '@/services/EventBus';
import i18n from '@/i18n';
import { parseToken, checkJWTValid } from '@/services/utils/jwt';
import { USER_TYPE } from '@/constants/userTypes';

import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { analyticsService } from '@/services/Analytics/new';
import * as telemetry from '@/services/Telemetry';
import { identify } from '@/plugins/userflow';
import { queryClient, userInfoQuery } from '@/reactBridge/queryClient';
import { CancelledError } from '@tanstack/react-query';

const Log = new LogService('store/user');

const USERINFO_UPDATE_INTERVAL = 30000;
let userInfoInterval = null;

const initialUserState = {
    token: null,
    primaryToken: null,
    originToken: null,
    tokenData: null,
    info: null,
    infoPending: false,
    showImpersonationNote: false,
    originalLocation: null,
    weightPreference: null,
    userLinks: null,
    impersonationPending: false,
};

const userGetters = {
    isClient: state => state.info?.types?.includes(USER_TYPE.CLIENT) ?? false,
    isPlatformAdministrator: state => (state.info?.types ? _includes(state.info.types, USER_TYPE.PLATFORM) : false),
    token: state => state.token,
    tokenData: state => state.tokenData,
    primaryToken: state => state.primaryToken,
    originToken: state => state.originToken,
    isLoggedIn: state => state.token !== null,
    priceAccess: state => _get(state, 'tokenData.priceAccess', false),
    type: state => _get(state, 'tokenData.type', null),
    role: state => `${_get(state, 'info.types', []).join(',')}/${_get(state, 'info.user.permissions', []).join(',')}`,
    firstName: state => _get(state, 'tokenData.firstName', null),
    lastName: state => _get(state, 'tokenData.lastName', null),
    fullName: state => `${_get(state, 'tokenData.firstName', null)} ${_get(state, 'tokenData.lastName', null)}`,
    id: state => _get(state, 'tokenData.userId', null),
    username: state => _get(state, 'info.user.username', null),
    locale: state => _get(state, 'info.user.locale', null),
    organizationId: state => _get(state, 'tokenData.organizationId', null),
    info: state => state.info,
    isInfoPending: state => state.infoPending,
    organizationName: state => _get(state, 'info.organization.name', null),
    organizationMarket: state => _get(state, 'info.organization.market', null),
    isBlocked: state => _get(state, 'info.organization.isBlocked', false),
    orderDeliveryAvailable: state => _get(state, 'info.organization.orderDeliveryAvailable', false),
    orderPickupAvailable: state => _get(state, 'info.organization.orderPickupAvailable', false),
    profileImage: state => _get(state, 'info.user.image', null),
    isTermsOfUseAccepted: state => !!_get(state, 'info.user.termsConfirmed', null),
    isTermsOfServiceAccepted: state => !!_get(state, 'info.organization.termsConfirmed', null),
    impersonatingUserId: state => {
        const primaryToken = state.primaryToken;
        if (primaryToken) {
            const tokenData = parseToken(primaryToken);
            if (tokenData) {
                return tokenData.userId;
            }
        }
        return null;
    },
    isImpersonated: (_state, _getters, _rootState, rootGetters) => rootGetters['abilities/can']('unimpersonate'),
    showImpersonationNote: state => state.showImpersonationNote,
    originalLocation: state => state.originalLocation,
    hasPermissionToOrderDelivery: state => _get(state, 'info.permissions.orderTypes.delivery', false),
    hasPermissionToOrderPickup: state => _get(state, 'info.permissions.orderTypes.pickup', false),
    hasPermissionToOrderByPhone: state => _get(state, 'info.permissions.orderTypes.phone', false),
    hasPermissionToPerformDelivery: state => _get(state, 'info.organization.deliveryAllowed', false),
    hasPermissionToPerformShipment: state => _get(state, 'info.organization.shipmentAllowed', false),
    lastVehicleId: state => _get(state, 'info.user.lastVehicleId', null),
    weightPreference: state => state.weightPreference,
    userLinks: state => state.userLinks,
    isOrigin: state => !state.originToken,
    originUserId: state => _get(parseToken(state.originToken), 'userId', null),
    hasLinkedUsers: state => state.userLinks && state.userLinks.links && state.userLinks.links.length > 0,
    linkedUsers: (state, getters) => {
        const links = _cloneDeep(_get(state, 'userLinks.links', null));
        if (!links) return [];

        links.unshift({
            targetUser: state.userLinks.originUser,
            notificationAppInboxCount: state.userLinks.notificationAppInboxCount,
            address: state.userLinks.address,
        });

        return links.filter(o => o.targetUser.id !== getters.id);
    },
    linkedUsersNotificationCount: (state, getters) => {
        return getters.linkedUsers ? getters.linkedUsers.reduce((acc, o) => acc + o.notificationAppInboxCount, 0) : 0;
    },
    permissions: state => _get(state, 'info.permissions', []),
    organization: state => _get(state, 'info.organization', []),
    user: state => _get(state, 'info.user', []),
    organizationTypes: state => _get(state, 'info.organization.types', []),
    features: state => _get(state, 'info.features', []),
    market: state => _get(state, 'info.organization.market', null),
    analyticsUser: (_state, getters) => {
        const isImpersonated = getters.isImpersonated;

        if (isImpersonated) {
            return {
                id: getters.impersonatingUserId,
                impersonatedUserId: getters.id,
            };
        } else {
            return {
                id: getters.id,
                impersonatedUserId: null,
            };
        }
    },
};

const userMutations = {
    setTokenData(state, { token }) {
        const tokenData = parseToken(token);

        state.token = token;
        state.tokenData = tokenData;
    },

    loginSuccess(state, { token }) {
        userMutations.setTokenData(state, { token });
        state.weightPreference = _get(state.tokenData, 'preferences.weightMethod', null);
    },

    logout(state) {
        queryClient.clear();
        state.token = null;
        state.primaryToken = null;
        state.tokenData = null;
        state.info = null;
        state.userLinks = null;
    },

    backupPrimaryToken(state) {
        const impersonationId = _get(parseToken(state.token), 'impersonationId', null);

        // do not back up impersonated token
        if (impersonationId !== null) {
            return;
        }

        state.primaryToken = state.token;
        state.originalLocation = window.location.href;
    },

    backupOriginToken(state) {
        state.originToken = state.token;
    },

    removeOriginToken(state) {
        state.originToken = null;
    },

    setImpersonationNoteVisibility(state, status) {
        state.showImpersonationNote = status;
    },

    setInfo(state, info) {
        identify(info.user, info.organization);
        state.info = info;
    },

    setInfoPending(state, status) {
        state.infoPending = status;
    },

    updateWeightPreference(state, method) {
        state.weightPreference = method;
    },

    setUserLinks(state, userLinks) {
        state.userLinks = userLinks;
    },

    setImpersonationPending(state, status) {
        state.impersonationPending = status;
    },

    removeUserLink(state, id) {
        const userLinks = state.userLinks;
        userLinks.links = _filter(userLinks.links, o => o.targetUser.id !== id);
        state.userLinks = userLinks;
    },
};

const userActions = {
    updateTokenData({ getters, commit }) {
        if (!getters.token) return;

        commit('setTokenData', { token: getters.token });
    },

    async login({ commit, dispatch }, credentials) {
        try {
            const token = await Authenticator.requestToken(credentials);
            if (!checkJWTValid(token)) {
                throw new Error('Received invalid token format');
            }
            commit('loginSuccess', { token });
            // Set explicitly before sending notification token, because of async
            ApiService.setAuthorizationHeader(token);
            dispatch('platform/updateInfo', null, { root: true });
            dispatch('updateUserInfo');
            dispatch('updateUserLinks');
            dispatch('notification/registerToken', null, { root: true });
        } catch (err) {
            commit('logout');
            throw err;
        }
    },

    async updateUserInfo(context) {
        if (!context.getters.token) return;

        context.commit('setInfoPending', true);
        try {
            // User info is fetched through the Query Client to keep both Vuex and the Query Client in sync
            const data = await queryClient.fetchQuery(userInfoQuery);
            context.commit('setInfo', data);
            await context.dispatch('i18n/changeLocale', { locale: _get(data, 'user.locale', null) }, { root: true });
            context.commit('setInfoPending', false);
            await analyticsService.setCurrentUser({
                appVersion: process.env.VUE_APP_VERSION,
                ...context.getters.analyticsUser,
            });
            telemetry.setUser({
                username: context.getters.username,
                ...context.getters.analyticsUser,
            });
            return;
        } catch (err) {
            context.commit('setInfoPending', false);
            if (err instanceof CancelledError) {
                return;
            }
            throw err;
        }
    },

    async updateUserLinks({ getters, commit }) {
        if (!getters.token) return;

        const userLinks = await UserLinkApi.getLinks();
        commit('setUserLinks', userLinks);
    },

    async changeUser({ state, getters, commit, dispatch }, targetUserId) {
        if (!state.token) return;
        if (parseInt(targetUserId) === parseInt(getters.id)) return;

        let token;
        const { originToken, primaryToken, tokenData } = state;
        const originUserId = _get(parseToken(originToken), 'userId', null);

        // originToken is empty if we're on the origin user,
        // so we instead send the id from tokenData since that is the origin user in this case
        const analyticsOriginUserId = originUserId ?? tokenData.userId;
        AnalyticsService.trackEvent('user_switch', {
            originUserId: analyticsOriginUserId,
            fromUserId: state.tokenData.userId,
            targetUserId: targetUserId,
        });

        if (originUserId && targetUserId === originUserId) {
            if (primaryToken === null) {
                commit('logout');
                commit('removeOriginToken');
            }
            token = originToken;
        } else {
            try {
                token = await UserLinkApi.getLinkToken(targetUserId);
            } catch (err) {
                Log.error(err);
                throw err;
            }

            if (!checkJWTValid(token)) {
                throw new Error('Received invalid token format');
            }

            if (!state.originToken) {
                commit('backupOriginToken');
            }
        }

        commit('loginSuccess', { token });
        ApiService.setAuthorizationHeader(token);

        await dispatch('updateUserInfo');
        dispatch('updateUserLinks');

        setTimeout(() => {
            Toaster.info(i18n.t('pages.userLink.changedUserNote', { username: getters.username }));

            AnalyticsService.refreshUserProperties();
        }, 1000);
    },

    async logout({ getters, commit, dispatch }) {
        // Send event to notify other modules about user logout to perform actions
        // eslint-disable-next-line vue/custom-event-name-casing
        EventBus.$emit('user.logout');

        if (getters.originToken) {
            ApiService.setAuthorizationHeader(getters.originToken);
            commit('removeOriginToken');
        }

        if (getters.isImpersonated) {
            await dispatch('stopImpersonation');
            return false;
        }

        SpectatorService.forgetEverything();
        dispatch('notification/deleteCurrentToken', null, { root: true });
        commit('logout');
        ApiService.setAuthorizationHeader(null);
        dispatch('platform/updateInfo', null, { root: true });
        return true;
    },

    async forceLogout({ getters, state, dispatch }) {
        Log.info('Your token has expired, please login again.');

        if (state.originToken) {
            const currentUserName = getters.username;
            await dispatch('changeUser', _get(parseToken(state.originToken), 'userId', null));

            setTimeout(() => {
                Toaster.info(i18n.t('pages.userLink.forcedLogoutNote', { username: currentUserName }));
            }, 1000);
            return true;
        }

        return dispatch('logout');
    },

    async impersonate({ state, commit, dispatch }, userId) {
        const impersonationId = _get(parseToken(state.token), 'impersonationId', null);

        AnalyticsService.trackEvent('impersonate_user', { impersonatedUserId: userId });

        // do not back up impersonated token
        if (impersonationId !== null || state.impersonationPending) {
            return;
        }

        commit('setImpersonationPending', true);
        try {
            const token = await ImpersonationApi.impersonate(userId);

            if (!checkJWTValid(token)) {
                throw new Error('Received invalid token format');
            }

            commit('backupPrimaryToken');
            commit('loginSuccess', { token });

            ApiService.setAuthorizationHeader(token);
            await dispatch('updateUserInfo');
            commit('setImpersonationNoteVisibility', true);
        } catch (err) {
            Log.error(err);
            Toaster.error(err);
        }
        commit('setImpersonationPending', false);
    },

    async stopImpersonation({ state, commit, dispatch, getters }) {
        commit('setImpersonationNoteVisibility', false);
        const { primaryToken } = state;

        AnalyticsService.trackEvent('stop_impersonate_user');

        // Send event to notify other modules about impersonation stop to perform actions
        // eslint-disable-next-line vue/custom-event-name-casing
        EventBus.$emit('impersonation.stop');

        try {
            if (getters.originToken) {
                ApiService.setAuthorizationHeader(getters.originToken);
                commit('removeOriginToken');
            }

            // try to stop impersonation gracefully
            try {
                await ImpersonationApi.stopImpersonation();
            } catch (err) {
                Log.error(err);
                Toaster.error(err);
            }

            commit('logout');
            commit('loginSuccess', { token: primaryToken });
            ApiService.setAuthorizationHeader(primaryToken);
            await dispatch('updateUserInfo');

            setTimeout(() => {
                Toaster.info(
                    i18n.t('components.impersonationBar.stoppedImpersonationNote', {
                        name: getters.fullName,
                    })
                );
            }, 1000);
        } catch (err) {
            Log.error(err);
            Toaster.error(err.message);
        }
    },

    setupUserInfoPolling({ dispatch }) {
        if (userInfoInterval) {
            return;
        }

        userInfoInterval = setInterval(() => {
            dispatch('updateUserInfo');
            dispatch('updateUserLinks');
        }, USERINFO_UPDATE_INTERVAL);
    },
};

export default {
    namespaced: true,
    state: initialUserState,
    getters: userGetters,
    mutations: userMutations,
    actions: userActions,
};

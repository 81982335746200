<template>
    <div class="form-wrapper" data-test="formWrapper">
        <div v-if="hasTitleSlot" class="font-headline-lg-strong mb-12 flex items-center" data-test="form-wrapper-title">
            <slot name="title" />
        </div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'FormWrapper',
    computed: {
        hasTitleSlot() {
            return !!this.$slots.title;
        },
    },
};
</script>

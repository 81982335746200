import { forwardRef } from 'react';
// TODO extract to component library, this is just temporary until icon PR is merged
export const TransparentFlagIcon = forwardRef(({ className, ...svgProps }, ref) => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} className={className} {...svgProps}>
                <g clipPath="url(#clip0_409_241)">
                    <path d="M6.00505 21.002L6 2.00195" stroke="#35343D" strokeWidth="2.00001" strokeMiterlimit="10"/>
                    <path d="M7.005 3H15.572L12.259 6.341L15.572 9.503H7.005" stroke="#FF0000" strokeWidth="2.00001" strokeMiterlimit="10"/>
                </g>
                <defs>
                    <clipPath id="clip0_409_241">
                        <rect width="13.0681" height="19.0017" fill="white" transform="translate(5 2)"/>
                    </clipPath>
                </defs>
            </svg>);
});
TransparentFlagIcon.displayName = 'TransparentFlagIcon';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { constructionProjectByIdAsAdminQuery, constructionProjectByIdQuery, constructionProjectKeys, } from '@/constructionProjects/queries';
import { updateFavoriteStatus, updateFavoriteStatusAsAdmin, } from '@/constructionProjects/api/projects';
export function useConstructionProjectClientFavorite(constructionProjectId) {
    return useFavorite(constructionProjectId, constructionProjectKeys.byId(constructionProjectId), constructionProjectByIdQuery(constructionProjectId), updateFavoriteStatus);
}
export function useConstructionProjectAdminFavorite(constructionProjectId) {
    return useFavorite(constructionProjectId, constructionProjectKeys.byId(constructionProjectId), constructionProjectByIdAsAdminQuery(constructionProjectId), updateFavoriteStatusAsAdmin);
}
function useFavorite(constructionProjectId, queryKey, getConstructionProjectQueryOptions, mutationFn) {
    const queryClient = useQueryClient();
    const { data: isFavorite } = useQuery({
        ...getConstructionProjectQueryOptions,
        suspense: true,
        select: data => data.isFavorite,
    });
    const { mutateAsync } = useMutation({
        mutationFn,
        onMutate: async ({ isFavorite }) => {
            await queryClient.cancelQueries({
                queryKey: queryKey,
            });
            const previousConstructionProject = queryClient.getQueryData(constructionProjectKeys.byId(constructionProjectId));
            queryClient.setQueryData(queryKey, old => old !== undefined ? { ...old, isFavorite } : undefined);
            return { previousConstructionProject };
        },
        onError: (_, __, context) => {
            queryClient.setQueryData(queryKey, context?.previousConstructionProject);
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: queryKey,
            });
        },
    });
    const toggleFavorite = () => {
        void mutateAsync({
            constructionProjectId,
            isFavorite: !isFavorite,
        });
    };
    return {
        isFavorite,
        toggleFavorite,
    };
}

<template>
    <LayoutPage class="products-list" screen-name="seller-supplyproducts-list">
        <template #pageTitle>
            {{ activePageTitle }}
            <PopUp variant="fixed-width-400">
                <InfoIcon :height="18" :width="18" class="icon--inline product-list__popup-icon" />
                <template #content>
                    <p class="font-copy-sm">{{ activePageDescription }}</p>
                </template>
            </PopUp>
        </template>

        <div class="my-6 flex min-h-[70px] flex-col items-center justify-between px-4 md:px-0 lg:flex-row">
            <div class="flex w-full flex-col items-center gap-4 md:gap-6 lg:w-auto lg:flex-row">
                <LoadingSpinner v-if="isLoadingFactories" block dark />
                <template v-else>
                    <DropdownSelect
                        v-model="factoryIdModel"
                        :options="factoryOptions"
                        :label="selectedLocationTypeLabel"
                        data-test="factory-select"
                        filterable
                        class="md:min-w-[380px]"
                    >
                        <template #right>
                            <div
                                :class="[
                                    'h-[10px] w-[10px] rounded-full',
                                    { 'bg-green-500': isSelectedFactoryOpen },
                                    { 'bg-brand-700': !isSelectedFactoryOpen },
                                ]"
                            />
                        </template>
                        <template #option="{ label, option }">
                            <div class="flex w-full items-center justify-between">
                                <div class="flex flex-col">
                                    <span class="text-sm text-dark-gray-200">
                                        {{ locationTypeLabel(option) }}
                                    </span>
                                    <span>{{ label }}</span>
                                </div>
                                <div
                                    :class="[
                                        'h-[10px] w-[10px] rounded-full',
                                        { 'bg-green-500': option.open },
                                        { 'bg-brand-700': !option.open },
                                    ]"
                                />
                            </div>
                        </template>
                    </DropdownSelect>
                    <div class="mb-12 w-full whitespace-nowrap text-left md:mb-0 lg:mb-2 lg:w-auto lg:text-center">
                        {{ selectedFactoryAddress }}
                    </div>
                </template>
            </div>
            <div class="flex w-full justify-end gap-6 lg:w-auto">
                <SfButton
                    type="primary"
                    data-test="add-product-button"
                    :size="`${$root.isDesktop ? 'sm' : 'md'}`"
                    :is-loading="isLoading"
                    class="w-full lg:w-auto"
                    @click="addNewProduct()"
                >
                    <template #leading-icon>
                        <SfSysPlusIcon size="xxs" :theme="isLoading ? 'light' : 'dark'" class="align-middle" />
                    </template>
                    {{ $t('pages.products.supplierProductsList.addProduct') }}
                </SfButton>
            </div>
        </div>

        <RoundedTabNavigation
            v-if="activeTab"
            :value="activeTab"
            :tabs="activePageTabs"
            dark-line
            spaced
            class="pt-2 lg:pt-0"
            @input="selectTab($event)"
        />
        <Card shadowless spaceless>
            <section class="flex flex-col gap-6 lg:flex-row">
                <SfInput
                    v-model="searchTerm"
                    :label="$t('pages.products.supplierProductsList.search.bulkGoods.label')"
                    data-test="material-search"
                    class="flex-1"
                    :disabled="isLoading"
                >
                    <template #leading-icon>
                        <SfSysSearchIcon size="xxs" />
                    </template>
                </SfInput>
                <DropdownSelect
                    v-model="sortBy"
                    :label="$t('pages.products.supplierProductsList.sorting.label')"
                    :options="sortingOptions"
                    :disabled="isLoading"
                    data-test="sorting-select"
                    class="w-full max-w-full lg:max-w-[320px]"
                />
            </section>
        </Card>

        <LoadingSpinner v-if="isLoadingProducts" block dark />

        <div
            v-else-if="productListEmpty"
            class="flex min-h-[200px] flex-col items-center justify-center text-dark-gray-800 lg:mt-8"
        >
            <SfMaterialIcon size="lg" multi-color class="mt-8" />
            <p class="font-headline-xl-strong mt-8 text-center lg:text-left">
                {{ $t('pages.products.supplierProductsList.emptyState.header') }}
            </p>
            <p class="font-copy-md mt-2 text-center lg:text-left">
                {{ $t('pages.products.supplierProductsList.emptyState.text') }}
            </p>
            <BaseButton
                light
                :disabled="isLoading"
                data-test="cta-add-product-button"
                class="mb-8 mt-6 bg-white px-[24px] py-4 lg:mb-0"
                @click="addNewProduct()"
            >
                {{ $t('pages.products.supplierProductsList.emptyState.buttonText') }}
            </BaseButton>
        </div>

        <template v-else>
            <template v-if="foundProducts.length !== 0">
                <ProductsTable
                    class="mt-6"
                    :items="sortedProducts"
                    :highlight="searchTerm"
                    data-test="products-table"
                    @edit="editProduct"
                    @duplicate="addNewProduct"
                    @remove="askProductDelete"
                />
                <Card v-if="meta && meta.totalPages > 1" spaceless>
                    <SimplePager
                        :page="meta.currentPage"
                        :pages="meta.totalPages"
                        data-test="products-pager"
                        @selected="pageNumberUpdated"
                    />
                </Card>
            </template>
            <Words v-else class="mt-16" muted block centered>
                {{ $t('pages.products.supplierProductsList.productsNotFound') }}
            </Words>
        </template>

        <ProductDeleteConfirmationModal
            :show="productToDelete !== null"
            :is-loading="isDeletingProduct"
            @accept="deleteProduct(productToDelete)"
            @decline="declineProductDelete"
        />

        <router-view @add="fetchProducts(factoryId, page)" />
    </LayoutPage>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import _sortBy from 'lodash/sortBy';
import SupplierProductApi from '@/services/Api/Supplier/Product';
import FactoryApi from '@/services/Api/Factory';

import { SfSysPlusIcon, SfMaterialIcon, SfSysSearchIcon, SfButton, SfInput } from '@schuettflix/vue-components';
import BaseButton from '@/components/Button/Button';
import Card from '@/components/Layout/Card';
import DropdownSelect from '@/_components/DropdownSelect/DropdownSelect';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import PopUp from '@/_components/PopUp/PopUp';
import ProductsTable from '@/pages/SupplierProducts/components/ProductsTable/ProductsTable';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation.vue';
import SimplePager from '@/_components/SimplePager/SimplePager';
import Toaster from '@/services/Toaster';

import InfoIcon from '@/assets/icons/regular/info.svg';
import Words from '@/components/Typography/Words';
import { USAGES } from '@/constants/disposal';
import ProductDeleteConfirmationModal from '@/pages/SupplierProducts/components/ProductDeleteConfirmationModal/ProductDeleteConfirmationModal';

const PRODUCT_TYPE = {
    SELLING: 'selling',
    DISPOSAL: 'disposal',
};

export default {
    name: 'SupplierProductListPage',
    components: {
        ProductDeleteConfirmationModal,
        Words,
        DropdownSelect,
        SimplePager,

        /* Components */
        SfButton,
        BaseButton,
        Card,
        LayoutPage,
        LoadingSpinner,
        PopUp,
        ProductsTable,
        RoundedTabNavigation,
        SfInput,

        /* Icons */
        SfMaterialIcon,
        SfSysPlusIcon,
        SfSysSearchIcon,
        InfoIcon,
    },

    props: {
        page: {
            type: [Number, String],
            default: 1,
        },
        factoryId: {
            type: [Number, String],
            default: null,
        },
    },

    data() {
        return {
            isLoadingProducts: true,
            isLoadingFactories: true,
            isDeletingProduct: false,
            factories: [],
            products: null,
            meta: null,
            searchTerm: '',
            sortBy: null,
            selectedTab: null,
            productToDelete: null,
        };
    },

    computed: {
        activePageTitle() {
            let navigationTitleKey = 'management-products';
            if (this.$can('listSupplierProducts') || this.$can('viewSupplierWasteProducts')) {
                if (this.$can('listSupplierProducts') && !this.$can('viewSupplierWasteProducts')) {
                    navigationTitleKey = 'management-products-supplier';
                }
                if (!this.$can('listSupplierProducts') && this.$can('viewSupplierWasteProducts')) {
                    navigationTitleKey = 'management-products-waste';
                }
            }

            return this.$t(`components.navigation.labels.${navigationTitleKey}`);
        },

        activePageDescription() {
            let pageDescription = this.$t('pages.products.description');
            if (this.$can('listSupplierProducts') && !this.$can('listSupplierWasteProducts')) {
                pageDescription = this.$t('pages.products.descriptionSupplier');
            }
            if (!this.$can('listSupplierProducts') && this.$can('listSupplierWasteProducts')) {
                pageDescription = this.$t('pages.products.descriptionWaste');
            }
            return pageDescription;
        },

        activePageTabs() {
            const tabs = {};

            if (this.isSellingBulkGoods(this.selectedFactoryId)) {
                tabs[PRODUCT_TYPE.SELLING] = this.$t('pages.products.supplierProductsList.tab.bulkGoods');
            }

            if (this.isAcceptingDisposal(this.selectedFactoryId)) {
                tabs[PRODUCT_TYPE.DISPOSAL] = this.$t('pages.products.supplierProductsList.tab.disposal');
            }

            return tabs;
        },

        activeTab() {
            const tabs = this.activePageTabs;
            const firstKey = Object.keys(tabs)[0];

            return this.selectedTab || firstKey;
        },

        productListEmpty() {
            return !this.isLoading && _isEmpty(this.products);
        },

        foundProducts() {
            if (!this.searchTerm) {
                return this.products || [];
            }

            const term = this.searchTerm.toUpperCase();

            return this.products.filter(
                product =>
                    (product?.customName || '').toUpperCase().includes(term) ||
                    (product?.name || '').toUpperCase().includes(term) ||
                    (product?.partnerSku || '').toUpperCase().includes(term)
            );
        },

        sortedProducts() {
            if (!this.foundProducts.length) {
                return [];
            }

            const sorted = _sortBy(this.foundProducts, product => {
                return this.sortBy.field !== 'price'
                    ? product[this.sortBy.field]
                    : parseFloat(product[this.sortBy.field]);
            });

            return this.sortBy.direction === 'asc' ? sorted : sorted.reverse();
        },

        factoryOptions() {
            return this.factories.map(entry => ({
                value: entry.id,
                label: entry.name,
                id: entry.id,
                open: entry.open,
                disposalSettings: entry.disposalSettings,
                usages: entry.usages,
            }));
        },

        sortingOptions() {
            return [
                {
                    value: {
                        field: 'name',
                        direction: 'asc',
                    },
                    label: this.$t('pages.products.supplierProductsList.sorting.nameAsc'),
                },
                {
                    value: {
                        field: 'name',
                        direction: 'desc',
                    },
                    label: this.$t('pages.products.supplierProductsList.sorting.nameDesc'),
                },
                {
                    value: {
                        field: 'customName',
                        direction: 'asc',
                    },
                    label: this.$t('pages.products.supplierProductsList.sorting.customNameAsc'),
                },
                {
                    value: {
                        field: 'customName',
                        direction: 'desc',
                    },
                    label: this.$t('pages.products.supplierProductsList.sorting.customNameDesc'),
                },
                {
                    value: {
                        field: 'partnerSku',
                        direction: 'asc',
                    },
                    label: this.$t('pages.products.supplierProductsList.sorting.partnerSkuAsc'),
                },
                {
                    value: {
                        field: 'partnerSku',
                        direction: 'desc',
                    },
                    label: this.$t('pages.products.supplierProductsList.sorting.partnerSkuDesc'),
                },
                {
                    value: {
                        field: 'price',
                        direction: 'asc',
                    },
                    label: this.$t('pages.products.supplierProductsList.sorting.priceAsc'),
                },
                {
                    value: {
                        field: 'price',
                        direction: 'desc',
                    },
                    label: this.$t('pages.products.supplierProductsList.sorting.priceDesc'),
                },
            ];
        },

        selectedFactoryAddress() {
            const found = this.factories.find(entry => entry.id === this.selectedFactoryId);

            return found ? this.formatAddress(found.address) : '';
        },

        selectedFactoryId() {
            const foundInOptions = this.factoryOptions.find(option => option.value === this.factoryId);

            if (!this.factoryId || !foundInOptions) {
                return this.firstFactory;
            }

            return Number(this.factoryId);
        },

        isLoading() {
            return this.isLoadingFactories || this.isLoadingProducts;
        },

        firstFactory() {
            return this.factoryOptions[0]?.value || null;
        },

        factoryIdModel: {
            get() {
                return this.selectedFactoryId;
            },
            async set(value) {
                const usages = this.factories.find(factory => factory.id === value)?.usages || [];
                if (!usages.includes(USAGES.SELLING)) {
                    return this.redirectToDisposalPage(value);
                }
                await this.addFactoryToUrl(value);
                await this.fetchProducts(value);
            },
        },

        isSelectedFactoryOpen() {
            const found = this.factories.find(factory => factory.id === Number(this.selectedFactoryId));

            return found?.open || false;
        },

        selectedLocationTypeLabel() {
            return this.locationTypeLabel(this.selectedFactory);
        },

        selectedFactory() {
            return this.factories.find(factory => factory.id === this.selectedFactoryId);
        },
    },

    watch: {
        '$route.query.factoryId': function (value) {
            if (!value) {
                this.addFactoryToUrl(this.selectedFactoryId, true);
            }
        },
    },

    async mounted() {
        this.sortBy = this.sortingOptions?.[0]?.value || null;
        await this.fetchFactories();

        const usages = this.selectedFactory?.usages || [];

        if (!usages.includes(USAGES.SELLING)) {
            this.redirectToDisposalPage(this.selectedFactoryId);
        }
        await this.addFactoryToUrl(this.selectedFactoryId, true);
        await this.fetchProducts(this.selectedFactoryId, this.page);
    },

    methods: {
        async fetchProducts(factoryId, page) {
            if (factoryId === null) {
                return;
            }

            this.isLoadingProducts = true;

            if (!this.$can('listSupplierProducts')) return;
            try {
                const response = await SupplierProductApi.getSellerProductList(factoryId, page);
                this.products = response.items;
                this.meta = response.meta;
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                    Toaster.error(this.$t(err.message));
                }
            } finally {
                this.isLoadingProducts = false;
            }
        },

        async fetchFactories() {
            this.isLoadingFactories = true;

            try {
                this.factories = await FactoryApi.getAll();
                // this.factoryId = this.factories[0]?.id || null;
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                    Toaster.error(this.$t(err.message));
                }
            } finally {
                this.isLoadingFactories = false;
            }

            if (!this.factoryId) {
                this.$router.replace({ query: { factoryId: this.selectedFactoryId } });
            }
        },

        askProductDelete(productId) {
            if (!this.$can('deleteSupplierProducts')) {
                return;
            }

            this.productToDelete = productId;
        },

        async deleteProduct(productId) {
            this.isDeletingProduct = true;
            try {
                await SupplierProductApi.deleteProduct(productId);
                this.productToDelete = null;
                Toaster.success(this.$t('pages.products.supplierProductsList.delete.success'));
                await this.fetchProducts(this.factoryId, this.page);
            } catch (error) {
                const serverError = error?.response?.data?.find(entry => entry.property_path === '')?.message;
                Toaster.error(serverError || this.$t('pages.products.supplierProductsList.delete.error'));
            } finally {
                this.isDeletingProduct = false;
            }
        },

        declineProductDelete() {
            this.productToDelete = null;
        },

        pageNumberUpdated(page) {
            this.$router.push({
                name: 'management__supplier-products',
                query: {
                    page,
                    factoryId: this.factoryId,
                },
            });
            this.fetchProducts(this.factoryId, page);
        },

        addNewProduct(copy = null) {
            this.$router.push({
                name: `${this.$route.name}__add`,
                query: {
                    factoryId: this.factoryId,
                    ...(copy ? { copy } : {}),
                },
            });
        },

        editProduct(productId) {
            if (!this.$can('updateSupplierProducts')) {
                return;
            }

            this.$router.push({
                name: 'management__supplier-products-edit',
                params: { id: productId },
                query: {
                    factoryId: this.factoryId,
                },
            });
        },

        formatAddress(address) {
            const { city, number, zip, street } = address;
            const displayStreet = street || this.$t('pages.products.supplierProductsList.address.noStreet');
            const displayNumber = number || this.$t('pages.products.supplierProductsList.address.noNumber');
            return `${displayStreet} ${displayNumber}, ${zip} ${city}`;
        },

        selectTab(tab) {
            if (tab === PRODUCT_TYPE.DISPOSAL) {
                this.isLoadingProducts = true;
                this.redirectToDisposalPage(this.factoryId);
            }
        },

        redirectToDisposalPage(factoryId) {
            this.$router.push({
                name: 'management__supplier-disposal',
                query: {
                    factory: window.btoa(factoryId),
                    productType: window.btoa(PRODUCT_TYPE.DISPOSAL),
                },
            });
        },

        async addFactoryToUrl(factoryId, replaceUrl = false) {
            if (Number(this.factoryId) === Number(factoryId)) {
                return;
            }

            const method = replaceUrl ? 'replace' : 'push';

            return this.$router[method]({ query: { factoryId } });
        },

        findFactoryById(factoryId) {
            return this.factories.find(factory => factory.id === factoryId);
        },

        isSellingBulkGoods(factoryId) {
            return this.findFactoryById(factoryId)?.usages.includes(USAGES.SELLING);
        },

        isAcceptingDisposal(factoryId) {
            return this.findFactoryById(factoryId)?.usages.includes(USAGES.DISPOSAL);
        },

        locationTypeLabel(factory) {
            if (!factory) return '';
            const labelParts = [];

            if (factory.usages.includes(PRODUCT_TYPE.SELLING)) {
                labelParts.push(this.$t('pages.organization.factoryList.type.selling'));
            }

            if (factory.usages.includes(PRODUCT_TYPE.DISPOSAL)) {
                const disposalType = factory.disposalSettings.factoryType;
                const disposalLabel = this.$t(`pages.organization.factoryList.type.${disposalType}`);
                const landfillClass = factory.disposalSettings.landfillClass
                    ? ` (${factory.disposalSettings.landfillClass})`
                    : '';

                labelParts.push(`${disposalLabel}${landfillClass}`);
            }

            return labelParts.join(', ');
        },
    },
};
</script>

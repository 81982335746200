var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('div',{staticClass:"card mt-4 w-full text-left",class:[
            {
                'card--hovered card--focussed ': !_vm.preview,
            },
        ]},[_c('span',{staticClass:"font-copy-sm-strong mb-6"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.disposal.summary.tiles.analysis.title'))+" ")]),(_vm.documents && _vm.documents.length > 0)?_c('DocumentsGalleryBlock',{attrs:{"documents":_vm.documents,"empty-message":_vm.$t('pages.transport.transportViewV2.documentsTab.emptyMessageAccompanyingDocuments')}}):_vm._e(),_c('HorizontalDivider',{staticClass:"my-4"}),_c('span',{staticClass:"font-copy-sm mb-4 block text-subdued"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.disposal.summary.tiles.analysis.otherDocuments'))+" ")]),_c('DocumentsGalleryBlock',{attrs:{"documents":_vm.otherDocuments,"images":_vm.images,"empty-message":_vm.$t('pages.checkout.disposal.summary.tiles.analysis.noOtherDocsAvailable')}})],1),(_vm.editable)?_c('div',{staticClass:"mt-2 text-right"},[_c('SfLink',{attrs:{"bold":""},on:{"click":function($event){return _vm.$emit('edit', {
                    section: 'analysis',
                    routeName: 'checkout-project-disposal-hazardous-analysis',
                })}}},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.disposal.summary.actions.change.analysis'))+" ")])],1):_vm._e()])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
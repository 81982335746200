<template>
    <div class="wrapper" @click="setState">
        <div class="radio-card" :class="radioButtonStates" data-test="radio-card">
            <div class="radio-card__button">
                <div class="radio-card__button__icon" />
            </div>
            <div class="radio-card__body">
                <div class="radio-card__body__title">
                    <div class="radio-card__body__radio" data-test="radio">
                        <input
                            v-if="!isReadOnly"
                            ref="radio"
                            class="radio-card__input"
                            type="radio"
                            :disabled="isDisabled"
                            :value="value"
                            :name="name"
                            :checked="isChecked"
                            :data-test="radioDataTest"
                            @change="updateInput"
                            @click.stop
                        />
                    </div>
                    <Words v-if="title" bold data-test="title">{{ title }}</Words>
                </div>
                <label v-if="description" :for="id" class="radio-card__body__description" data-test="description">
                    <Words small spaced-top-tiny>{{ description }}</Words>
                </label>
            </div>
        </div>
        <Words v-if="errorMessage" small spaced-top-small block is-error data-test="error" class="error-message">
            {{ errorMessage }}
        </Words>
    </div>
</template>
<script>
import Words from '@/components/Typography/Words';
import { uuid } from 'vue-uuid';

export default {
    name: 'RadioButtonCard',
    components: {
        Words,
    },
    model: {
        prop: 'modelValue',
        event: 'change',
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        errorMessage: {
            type: String,
            default: null,
        },
        modelValue: {
            type: [String, Number],
            default: null,
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        radioDataTest: {
            type: String,
            default: 'radio-card-input',
        },
    },
    data() {
        return {
            id: uuid.v4(),
        };
    },
    computed: {
        radioButtonStates() {
            return {
                'radio-card--is-active': this.isChecked,
                'radio-card--has-error': !!this.errorMessage,
                'radio-card--is-disabled': this.isDisabled,
                'radio-card--is-readonly': this.isReadOnly,
            };
        },
        isChecked() {
            return this.value === this.modelValue;
        },
    },
    methods: {
        updateInput() {
            const value = this.$refs.radio.value;
            this.$emit('change', value);
        },
        setState() {
            if (this.isDisabled || this.isReadOnly) {
                return;
            }
            this.$refs.radio.checked = true;
            this.updateInput();
        },
    },
};
</script>
<style scoped lang="scss">
.wrapper {
    width: 100%;

    .radio-card {
        display: grid;
        grid-template-columns: 40px auto;
        padding: 16px;
        height: 100%;
        background-color: white;
        border: 2px solid transparent;
        transition:
            box-shadow 0.2s ease-in-out,
            background-color 0.3s ease-in-out;

        input[type='radio'] {
            opacity: 0;
            position: absolute;
        }

        &:not(&--is-disabled):not(&--is-readonly):not(&--is-active) {
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

            &:hover {
                box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
                cursor: pointer;
            }

            &:active,
            &:focus {
                outline: 0;
                box-shadow: none;
            }

            &:focus-within {
                outline: 4px solid $color-focus-green;
            }
        }

        &__button {
            position: relative;

            &__icon {
                width: 24px;
                height: 24px;
                position: absolute;
                border-radius: 50%;
                border: 1px solid;

                &::after {
                    content: '';
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    border-radius: 50%;
                    background-color: white;
                    width: 16px;
                    height: 16px;
                }
            }
        }

        &--is-active {
            &:not(.radio-card--is-disabled) {
                .radio-card__button {
                    &__icon {
                        border-color: white !important;
                    }
                }
            }
            &.radio-card--is-disabled {
                border: 2px solid #d2d4d5;
                .radio-card__button {
                    &__icon {
                        &::after {
                            content: '';
                            background-color: #d2d4d5;
                        }
                    }
                }
            }
        }

        &__body {
            &__title {
                margin: 3px 0;
            }
            &__label {
                cursor: pointer;
            }
        }

        &--is-active:not(&--is-disabled) {
            background-color: $color-darkGrey;
            color: white;
        }

        &--has-error {
            border: 1px solid $color-error;
        }

        &--is-active &--is-disabled {
            border: 2px solid $sflx-grey-400;
            .radio-card__button {
                &__icon {
                    &::after {
                        background-color: $sflx-grey-400;
                    }
                }
            }
        }

        &--is-disabled {
            position: relative;
            color: #7c7f84;
            background-color: $sflx-grey-100;

            .radio-card & {
                border: 2px solid tomato;
            }

            .radio-card__button {
                &__icon {
                    border-color: $sflx-grey-400;
                }
            }

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                cursor: not-allowed;
            }
        }
    }

    .error-message {
        color: $color-error;
    }
}
</style>

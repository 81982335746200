<template>
    <div
        class="filter-bar"
        :class="{
            'filter-bar--hidden': hideBar,
            'filter-bar--transparent': transparent,
            'filter-bar--border': border,
            'filter-bar--dark-border': darkBorder,
            'filter-bar--no-spacing': noSpacing,
        }"
    >
        <div v-if="$slots.filter" class="filter-bar__filter">
            <slot name="filter" />
        </div>

        <div v-if="$slots.sorting" class="filter-bar__sorting">
            <slot name="sorting" />
        </div>

        <div
            v-if="showRefresh"
            :class="{
                'filter-bar__refresh--loading': loading,
            }"
            class="filter-bar__refresh"
        >
            <BaseButton primary light icon @click="$emit('refresh')">
                <RefreshIcon />
            </BaseButton>
        </div>

        <div v-if="$slots.actions" class="filter-bar__actions">
            <slot name="actions" />
        </div>
    </div>
</template>

<script>
import BaseButton from '@/components/Button/Button';
import RefreshIcon from '@/assets/icons/regular/refresh.svg';

export default {
    name: 'FilterBar',
    components: {
        BaseButton,
        RefreshIcon,
    },
    props: {
        showRefresh: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        hideBar: {
            type: Boolean,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        darkBorder: {
            type: Boolean,
            default: false,
        },
        noSpacing: {
            type: Boolean,
            default: false,
        },
        border: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss">
.filter-bar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    background-color: transparent;
    padding: 15px 0;

    & > *:last-child {
        padding-right: 0;
        border: 0 none;
    }

    &.filter-bar--transparent {
        background-color: transparent;

        & > * {
            background-color: transparent;
        }
    }
}

.filter-bar--border {
    --separator-color: #{$color-mediumGrey};
    & > * {
        align-self: center;
        background-color: $color-lightGrey;
        border-right: 1px solid var(--separator-color);
    }
}

.filter-bar--dark-border {
    --separator-color: #{$color-grey};
}

.filter-bar--hidden {
    display: block;
    height: 0px;
    overflow: hidden;
    padding: 0px;
}

.filter-bar__sorting {
    display: flex;
    align-items: center;

    > * {
        background-color: $color-lightGrey;
        .filter-bar--transparent & {
            background-color: transparent;
        }
    }

    .sorting-direction,
    .select-input__input.select-input__input,
    .select-input__label {
        background-color: $color-lightGrey;
        height: 50px;

        .filter-bar--transparent & {
            background-color: transparent;
        }
    }
}

@keyframes filterBarRefreshRotate {
    0% {
        transform: rotate(125deg);
    }
    100% {
        transform: rotate(485deg);
    }
}

.filter-bar__refresh {
    .button--primary.button--light {
        min-width: 0;

        .filter-bar--transparent & {
            background-color: transparent;
        }
    }

    svg {
        animation: filterBarRefreshRotate 0.5s linear infinite;
        animation-play-state: paused;
    }
}

.filter-bar__refresh--loading {
    svg {
        animation-play-state: running;
    }
}

.filter-bar__filter {
    padding-right: 20px;
    flex-grow: 1;

    > * {
        height: 50px;
        line-height: 50px;
    }
}

.filter-bar__sorting {
    .filter-box__body {
        padding: 0;
    }
}

.filter-bar:not(.filter-bar--transparent) .filter-bar__refresh,
.filter-bar:not(.filter-bar--transparent) .filter-bar__actions {
    .button:not(:disabled) {
        background-color: $color-lightGrey;
    }
}

.filter-bar--no-spacing {
    margin: 0px;
    padding: 0px;
}
</style>

/**
 * Custom ability checks, used to be merged with remote definitions
 */
export default {
    true: ['true'],

    // NOTE: Copied from backend abilities because needed on start
    unimpersonate: ['not', ['isNull', 'user.impersonationId']],

    // NOTE: Divergent from backend
    updateOrganizationPlatformSettings: [
        'and',
        ['hasType', 'platform'],
        ['hasPermission', 'organization_management'],
        ['not', ['eq', 'organization.id', 'subject.id']],
    ],
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CustomPriceAdjustmentsPage',{attrs:{"state":_vm.basket,"prices":_vm.pricesData,"transports":_vm.currentTransports,"waste-meta-data":_vm.wasteMetaData,"can-save-section":_vm.canSaveSection,"section":_vm.section,"is-project":"","project-position-id":_vm.projectPositionId},on:{"edit-project-position":_vm.editDisposalProjectPosition,"calculate-project-position":_vm.calculateDisposalProjectPosition,"close-section":_vm.closeSection,"save-section":_vm.saveSection,"save-project-position":_vm.saveProjectPosition,"reset-price-data":_vm.resetPriceData,"submit":_vm.submit,"close":_vm.close},scopedSlots:_vm._u([{key:"paymentTerms",fn:function(){return [_c('ReactBridge',{attrs:{"react-component":_vm.PaymentTermsContainer,"props":{
                organizations: _vm.getPaymentTerms({ disposer: _vm.disposer, carrierInfo: _vm.carrierInfo }, 'disposal'),
                paymentTermsProp: _vm.paymentTermsFromApi || _vm.requestCollection.paymentTerms,
                paymentTermsProp: _vm.isProjectPositionEditMode
                    ? _vm.requestCollection.paymentTerms
                    : _vm.paymentTermsFromApi || _vm.requestCollection.paymentTerms,
            }},on:{"change":_vm.handlePaymentTermsChange,"defaultPaymentTerms":_vm.setDefaultPaymentTerms}})]},proxy:true},{key:"billingMethod",fn:function(){return [_c('div',{staticClass:"mt-12"},[_c('span',{staticClass:"font-copy-md-strong mb-4"},[_vm._v(_vm._s(_vm.$t('pages.checkout.disposal.summary.tiles.billingMethod.title')))]),_c('span',{staticClass:"font-copy-md"},[_vm._v(_vm._s(_vm.getBillingMethod))])]),_c('HorizontalDivider',{attrs:{"size":"12"}})]},proxy:true},{key:"productCosts",fn:function({ error }){return [(_vm.hasWaste)?_c('WasteProductSection',{attrs:{"prices-data":_vm.pricesData,"error":error,"waste-meta-data":_vm.wasteMetaData},on:{"edit":function($event){return _vm.editSection('waste')}}}):_vm._e()]}},{key:"transports",fn:function(){return [(_vm.hasTransport)?_c('TransportSection',{staticClass:"mt-12",attrs:{"basket-type":_vm.getBasketType,"fixed-price":_vm.hasFixedPrice,"transport-distance":_vm.pricesData.distance,"transport-duration":_vm.pricesData.duration,"transport-price-unit":_vm.transportPriceUnit,"transports":_vm.currentTransports},on:{"edit":function($event){return _vm.editSection('transport')}}}):_vm._e()]},proxy:true},{key:"customPosition",fn:function(){return [_c('ProjectCustomPositionsSection',{staticClass:"mt-12",attrs:{"custom-positions":_vm.current.customPositions,"is-pending":_vm.isPending()},on:{"edit":_vm.editCustomPosition,"remove":_vm.removeCustomPosition,"add":_vm.addCustomPosition}})]},proxy:true},{key:"materialTransport",fn:function(){return undefined},proxy:true},{key:"shipping",fn:function(){return undefined},proxy:true},{key:"costPreview",fn:function(){return [(
                _vm.hasPreparedTransporstForCustomPositions &&
                _vm.pricesDataFromStore.totalPricesPerSelectedVehicleClass &&
                _vm.current &&
                _vm.current.waste
            )?_c('CostPreviewSection',{staticClass:"mt-12",attrs:{"transports":_vm.pricesDataFromStore.totalPricesPerSelectedVehicleClass,"material":{
                ..._vm.current.waste.unfold(),
                extension: `${_vm.wasteMetaData.wasteCode}${_vm.isHazardous ? '*' : ''}, ${
                    _vm.wasteMetaData.pollutionType.label
                }`,
            },"fixed-price":_vm.hasFixedPrice}}):_vm._e()]},proxy:true},{key:"totals",fn:function(){return [(_vm.hasPreparedTransporstForCustomPositions && _vm.pricesDataFromStore.totalPrice)?_c('TotalsSection',{staticClass:"mt-12",attrs:{"fixed-price":_vm.hasFixedPrice,"current":_vm.current,"material":{
                qty: _vm.requestCollection.qty || 0,
                info: `${_vm.wasteMetaData.wasteCode}${_vm.isHazardous ? '*' : ''}, ${_vm.wasteMetaData.pollutionType.label}`,
            },"max-payload-transport":_vm.current.maxPayloadTransport,"total-transport-count":_vm.pricesDataFromStore.totalPrice.transportsOfLargestVehicleClassNeeded,"total-purchase-price":_vm.hasFixedPrice
                    ? _vm.pricesDataFromStore.totalPrice.purchasePriceByTransport
                    : _vm.pricesDataFromStore.totalPrice.purchasePriceByTon,"total-retail-price":_vm.hasFixedPrice
                    ? _vm.pricesDataFromStore.totalPrice.retailPriceByTransport
                    : _vm.pricesDataFromStore.totalPrice.retailPriceByTon,"type":'project-disposal'}}):_vm._e()]},proxy:true},{key:"editWaste",fn:function(){return [(_vm.section === 'waste')?_c('WasteProductSection',{attrs:{"prices-data":_vm.pricesData,"is-edit-mode":"","waste-meta-data":_vm.wasteMetaData},on:{"edit":function($event){return _vm.editSection('waste')}}}):_vm._e()]},proxy:true},{key:"editTransports",fn:function(){return [(_vm.section === 'transport')?_c('section',_vm._l((_vm.current.transport.collection),function(vehicleClass,index){return _c('WasteTransportEditSection',{key:vehicleClass.id,attrs:{"original-vehicle-class":_vm.original.transport.collection[index],"fixed-price":_vm.hasFixedPrice,"show-enable-controls":""},on:{"selected-custom-price-unit":_vm.setCustomTransportPriceUnit},model:{value:(_vm.current.transport.collection[index]),callback:function ($$v) {_vm.$set(_vm.current.transport.collection, index, $$v)},expression:"current.transport.collection[index]"}})}),1):_vm._e()]},proxy:true},{key:"editCustomPosition",fn:function(){return [(_vm.section === 'customPosition')?_c('CustomPositionEditSection',{attrs:{"is-disposal":"","process-i18n-key":"projectShipmentPriceAdjustments"},on:{"change":_vm.checkIfCanSaveSection},model:{value:(_vm.customPositionData),callback:function ($$v) {_vm.customPositionData=$$v},expression:"customPositionData"}}):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
    <LayoutPage
        class="vehicle-list"
        screen-name="carrier-vehicle-list"
        :parent-route="parentRoute"
        :child-routes="childRoutes"
    >
        <template #desktopHeaderNavigation>
            <BaseButton arrow-left small class="page__navigation-back-button" @click="goToSettingsPage()">
                {{ $t('pages.settingsV2.title') }}
            </BaseButton>
        </template>
        <template #pageTitle>
            {{ $route.params.pageTitle ? $route.params.pageTitle : $t('pages.vehicle.list.title') }}
        </template>

        <template #mobileHeader>
            <HeaderBar>
                <template #left>
                    <HeaderBarItem v-if="parentRoute" button @click="goToSettingsPage()">
                        <ArrowIcon width="32" height="18" />
                    </HeaderBarItem>
                </template>

                <template #headline>
                    {{ $route.params.pageTitle ? $route.params.pageTitle : $t('pages.vehicle.list.title') }}
                </template>

                <template #right>
                    <HeaderBarItem v-if="!$root.isDesktop">
                        <BaseButton @click="openCreateDialog()">
                            <PlusIcon class="icon--inline" />
                        </BaseButton>
                    </HeaderBarItem>
                </template>
            </HeaderBar>
        </template>

        <FilterBox
            v-model="filter"
            :default-filter="defaultFilter"
            :endpoint="dataEndpoint"
            inline-mode
            no-padding
            @update="refreshList()"
        >
            <template #default="filterScope">
                <Card v-if="!$root.isDesktop" spaceless class="relative z-10">
                    <TextField v-model="filterScope.filter.search" :label="$t('pages.vehicle.list.search')" />
                </Card>
                <FilterBar :hide-bar="!$root.isDesktop" transparent show-refresh @refresh="refreshList()">
                    <template #filter>
                        <TextField v-model="filterScope.filter.search" :label="$t('pages.vehicle.list.search')" small />
                    </template>

                    <template #sorting>
                        <SortingSelectBox
                            ref="sortingBoxFlyout"
                            v-model="filterScope.filter.sortBy"
                            :direction="filterScope.filter.sortDirection"
                            :label="$t('pages.vehicle.list.actions.sortBy')"
                            primary
                            light
                            small
                            @direction-update="filterScope.filter.sortDirection = $event"
                            @sort-update="filterScope.filter.sortBy = $event"
                        >
                            <option v-for="option in sortOptions" :key="option" :value="option">
                                {{ $t(`pages.vehicle.list.sortings.${option}`) }}
                            </option>
                        </SortingSelectBox>
                    </template>

                    <template #actions>
                        <BaseButton v-if="$can('createVehicle')" primary light transparent @click="openCreateDialog()">
                            <template #left>
                                <PlusIcon class="icon--inline" />
                            </template>
                            {{ $t('pages.vehicle.list.actions.addVehicle') }}
                        </BaseButton>
                    </template>
                </FilterBar>
            </template>
        </FilterBox>

        <transition name="fade" mode="out-in">
            <LoadingSpinner v-if="isLoading" block dark />

            <DataTable v-else-if="vehicles && vehicles.count > 0" clickable>
                <template v-if="$root.isDesktop" #header>
                    <tr>
                        <th>{{ $t('pages.vehicle.list.tableHeadings.image') }}</th>
                        <th>{{ $t('pages.vehicle.list.tableHeadings.vehicleClass') }}</th>
                        <th>{{ $t('pages.vehicle.list.tableHeadings.licensePlate') }}</th>
                        <th>{{ $t('pages.vehicle.list.tableHeadings.emptyWeight') }}</th>
                        <th>{{ $t('pages.vehicle.list.tableHeadings.permissibleTotalWeight') }}</th>
                        <th>{{ $t('pages.vehicle.list.tableHeadings.status') }}</th>
                    </tr>
                </template>

                <template v-for="vehicle in vehicles.items">
                    <tr v-if="$root.isDesktop" :key="vehicle.id" @click="editVehicle(vehicle)">
                        <td @click.stop>
                            <SfThumbnail
                                v-if="!!vehicle.image && vehicle.image?.url"
                                :file-thumbnail-src="vehicle?.image?.url?.thumbnail"
                                :file-src="vehicle?.image?.url?.original"
                                :translations="{
                                    noImage: $t('components.thumbnail'),
                                }"
                                :modal="!!vehicle.image"
                            />
                            <TruckPlaceholderImage v-else class="w-[48px]" />
                        </td>
                        <td>
                            <VehicleClassIconSet
                                :icon="vehicle.vehicleClass.icon"
                                :attribute="setVehicleIconAttribute(vehicle.attributes)"
                                width="60"
                                height="25"
                            />
                            &nbsp;
                            <Words middle>{{ vehicle.vehicleClass.name }}</Words>
                        </td>
                        <td>{{ vehicle.licensePlate }}</td>
                        <td>{{ vehicle.emptyWeight }} kg</td>
                        <td>{{ vehicle.permissibleTotalWeight }} kg</td>
                        <td>
                            <Words v-if="vehicle.isActive" green>
                                {{ $t('pages.vehicle.list.filter.statusActive') }}
                            </Words>
                            <Words v-else muted>{{ $t('pages.vehicle.list.filter.statusInactive') }}</Words>
                        </td>
                    </tr>

                    <VehicleRowMobile v-else :key="vehicle.id" :vehicle="vehicle" @click="editVehicle(vehicle)" />
                </template>
                <tr>
                    <MoreResultsButton
                        v-if="vehicles.count - vehicles.items.length > 0"
                        :result="vehicles"
                        :colspan="8"
                        fade-out
                    />
                </tr>
            </DataTable>

            <div
                v-else-if="vehicles && vehicles.count === 0"
                class="vehicle-list__add-vehicle-cta container-deprecated"
            >
                <Headline :level="2">
                    {{ $t('pages.vehicle.list.addVehicleTitle') }}
                </Headline>
                <Words block spaced-bottom>
                    {{ $t('pages.vehicle.list.addVehicleDescription') }}
                </Words>
                <BaseButton primary class="vehicle-list__add-vehicle-cta-button" @click="openCreateDialog()">
                    <template #left>
                        <PlusIcon class="icon--inline" />
                    </template>
                    {{ $t('pages.vehicle.list.actions.addVehicle') }}
                </BaseButton>
            </div>
        </transition>

        <template #subpages>
            <Flyout route="vehicle-management__vehicle-new" size="full" appear-from="bottom" background-grey />
        </template>
    </LayoutPage>
</template>

<script>
import _intersection from 'lodash/intersection';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { navigationFailure } from '@/services/utils/router';
import { EVENT_VEHICLE_CREATED, EVENT_VEHICLE_DELETED, EVENT_VEHICLE_UPDATED } from '@/constants/events';

import BaseButton from '@/components/Button/Button';
import Card from '@/components/Layout/Card';
import DataTable from '@/components/Table/DataTable';
import FilterBar from '@/components/Filter/FilterBar';
import FilterBox from '@/components/Filter/FilterBox';
import Flyout from '@/components/Layout/Flyout';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Headline from '@/components/Typography/Headline';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import MoreResultsButton from '@/components/Filter/MoreResultsButton';
import SortingSelectBox from '@/components/Form/SortingSelectBox';
import TextField from '@/components/Form/TextField.v2';
import VehicleApi from '@/services/Api/Vehicle';
import VehicleClassIconSet, { setVehicleIconAttribute } from '@/components/IconSet/VehicleClassIconSet';
import VehicleRowMobile from '@/pages/Vehicle/components/VehicleRowMobile';
import Words from '@/components/Typography/Words';

import ArrowIcon from '@/assets/icons/regular/arrow.svg';
import PlusIcon from '@/assets/icons/regular/plus.svg';

import TruckPlaceholderImage from '@/assets/icons/regular/truck-placeholder.svg';
import { SfThumbnail } from '@schuettflix/vue-components';

export default {
    name: 'VehicleListPage',
    components: {
        BaseButton,
        Card,
        DataTable,
        FilterBar,
        FilterBox,
        Flyout,
        HeaderBar,
        HeaderBarItem,
        Headline,
        LayoutPage,
        LoadingSpinner,
        MoreResultsButton,
        SortingSelectBox,
        TextField,
        VehicleClassIconSet,
        VehicleRowMobile,
        Words,

        ArrowIcon,
        PlusIcon,

        TruckPlaceholderImage,
        SfThumbnail,
    },
    mixins: [persistentFiltersMixin, eventHubMixin],
    props: {
        parentRoute: {
            type: String,
            default: null,
        },
    },
    setup() {
        return {
            setVehicleIconAttribute,
        };
    },
    data() {
        return {
            vehicles: null,
            isCreateDialogOpen: false,
            selectedVehicle: null,
            isLoading: false,
            dataEndpoint: VehicleApi,
            filter: this.assembleFilter('vehicle', {
                page: 1,
                perPage: 25,
            }),
            defaultFilter: {
                page: 1,
                perPage: 25,
            },
        };
    },
    computed: {
        sortOptions() {
            return _intersection(VehicleApi.supportedSorts, [
                'vehicleClass',
                'licensePlate',
                'emptyWeight',
                'permissibleTotalWeight',
                'isActive',
            ]);
        },

        childRoutes() {
            return ['management__settings__vehicle-management__vehicle-edit'];
        },
    },
    created() {
        this.refreshList(true);
        this.subscribe(EVENT_VEHICLE_UPDATED, () => {
            this.refreshList();
        });
        this.subscribe(EVENT_VEHICLE_CREATED, () => {
            this.refreshList();
        });
        this.subscribe(EVENT_VEHICLE_DELETED, () => {
            this.refreshList();
        });
    },
    methods: {
        async refreshList(isInitial = false) {
            this.isLoading = true;

            this.persistFilter('vehicle', this.filter, this.defaultFilter);

            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.cancelSource = VehicleApi.createCancelTokenSource();

            try {
                const result = await VehicleApi.filter(this.filter, null, null, this.cancelSource);
                this.vehicles = result;

                if (isInitial === true) {
                    this.filter = {
                        ...this.filter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }

            this.isLoading = false;
        },
        openCreateDialog() {
            if (!this.$can('createVehicle')) return;
            this.$router
                .push({ name: this.$root.findRouteName(`vehicle-management__vehicle-new`) })
                .catch(navigationFailure);
        },
        editVehicle(vehicle) {
            if (!this.$can('updateVehicle', vehicle)) return;
            this.$router
                .push({
                    name: this.$root.findRouteName(`vehicle-management__vehicle-edit`),
                    params: { vehicleId: vehicle.id },
                })
                .catch(navigationFailure);
        },
        openSortBox() {
            this.$refs.sortingBoxFlyout.$emit('openSortingBox');
        },
        goToSettingsPage() {
            this.$router.push({ name: this.$root.findRouteName('settings') }).catch(() => {});
        },
    },
};
</script>

<style lang="scss">
.vehicle-list__add-vehicle-cta {
    display: flex;
    flex-direction: column;
    align-items: center;

    .words {
        max-width: 600px;
        text-align: center;
    }
}

.vehicle-list__add-vehicle-cta-button {
    margin-top: 20px;
}
</style>

import React from 'react';
// TODO: Extract to component library
export const MaterialIcon = props => (<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#a)" strokeWidth={2} strokeMiterlimit={10}>
            <path d="m20.123 16.365-5.294-5.293-4.595 4.595" stroke="#35343D"/>
            <path d="M10.99 19.041h11" stroke="red"/>
            <path d="m20.655 11.256-5.825-5.84-3.529 3.534M11.294 13.193l-2.121-2.12-5.232 5.231" stroke="#35343D"/>
            <path d="M8 19.041H2" stroke="red"/>
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" transform="translate(2 4)" d="M0 0h20v16H0z"/>
            </clipPath>
        </defs>
    </svg>);

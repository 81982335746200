<template>
    <Flyout v-if="isActionAvailable" :active="isActive" size="small" no-header @closed="closeFlyout()">
        <ProjectContingentSelectionPageMaterial
            :project-position-id="subject.id"
            :basket-type-prop="basketType"
            @projectPositionSaved="handleProjectPositionSaved()"
            @close="closeFlyout()"
        />
    </Flyout>
</template>

<script>
import ProjectPositionView from '@/models/ProjectPositionView';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { ACTIONS } from '@/constants/actions';
import { EVENT_PROJECT_POSITION_UPDATED } from '@/constants/events';
import { mapGetters } from 'vuex';

import Flyout from '@/components/Layout/Flyout';
import ProjectContingentSelectionPageMaterial from '@/pages/Checkout/ProjectContingentSelectionPageMaterial';

export default {
    name: 'ProjectPositionContingentChangeAction',
    components: { ProjectContingentSelectionPageMaterial, Flyout },
    mixins: [eventHubMixin],
    computed: {
        ...mapGetters('globalActions', ['subject', 'action']),

        isActive() {
            return this.action === ACTIONS.PROJECT_POSITION_CONTINGENT_CHANGE;
        },

        basketType() {
            return this.subject.type;
        },

        /**
         * Should this action be available based on the current conditions
         */
        isActionAvailable() {
            const projectPosition = this.subject;
            return projectPosition instanceof ProjectPositionView;
        },
    },
    methods: {
        closeFlyout() {
            this.$store.dispatch('globalActions/reset', ACTIONS.PROJECT_POSITION_CONTINGENT_CHANGE);
        },
        handleProjectPositionSaved() {
            this.$eventHub.$emit(EVENT_PROJECT_POSITION_UPDATED, this.subject);
            this.closeFlyout();
        },
    },
};
</script>

<template>
    <div class="shipment-time-picker">
        <Words v-if="headline" block bold spaced-bottom>{{ headline }}</Words>
        <Words v-if="description" block spaced-bottom>{{ description }}</Words>

        <div class="shipment-time-picker__time">
            <TimePicker
                ref="startTime"
                :value="value && value.startTime"
                :minute-steps="minuteSteps"
                :min="minStartTime"
                :max="maxStartTime"
                :disabled="disabled"
                data-test="picker-start-time"
                @input="updateStartTime"
            >
                <template #default="{ isInvalid }">
                    <TextField
                        :value="value && value.startTime"
                        readonly
                        disabled
                        :has-error="isInvalid"
                        :label="$t('components.timeRangeFieldset.fromLabel')"
                    >
                        <ClockIcon slot="icon" />
                    </TextField>
                </template>
            </TimePicker>

            <TimePicker
                ref="endTime"
                :value="value && value.endTime"
                :min="minEndTime"
                :max="maxEndTime"
                :minute-steps="minuteSteps"
                :disabled="!value || !value.startTime || disabled"
                data-test="picker-end-time"
                @input="updateEndTime"
            >
                <template #default="{ isInvalid }">
                    <TextField
                        :value="value && value.endTime"
                        readonly
                        disabled
                        :has-error="isInvalid"
                        :label="$t('components.timeRangeFieldset.toLabel')"
                    >
                        <ClockIcon slot="icon" />
                    </TextField>
                </template>
            </TimePicker>
        </div>
    </div>
</template>

<script>
import { addMinutes, setHours, setMinutes, setSeconds } from 'date-fns';
import { convertColonedTimeToDate } from '@/services/utils/date';

import Words from '@/components/Typography/Words';
import TextField from '@/components/Form/TextField.v2';
import TimePicker from '@/components/Form/TimePicker.v3';

import ClockIcon from '@/assets/icons/micro/clock.svg';

const MINUTE_STEPS = 15;

export default {
    name: 'TimeRangePicker',

    components: {
        Words,
        TextField,
        TimePicker,

        ClockIcon,
    },

    props: {
        value: {
            type: Object,
            default: null,
        },
        headline: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        minMaxRange: {
            type: Object,
            default: null,
        },
    },

    computed: {
        minuteSteps() {
            return MINUTE_STEPS;
        },

        minStartTime() {
            return convertColonedTimeToDate(this.minMaxRange?.startTime);
        },

        maxStartTime() {
            const fallbackMax = convertColonedTimeToDate(this.minMaxRange?.endTime) ?? Infinity;
            const valueMax = convertColonedTimeToDate(this.value?.endTime) ?? Infinity;

            if (fallbackMax === null && valueMax === null) {
                return null;
            }

            return Math.min(valueMax, fallbackMax);
        },

        minEndTime() {
            // TODO cleanup: Johannes & Markus – don't judge us!11
            if (!this.value || !this.value.startTime) return null;

            const [hours, minutes] = this.value.startTime.split(':').map(o => Number.parseInt(o));

            if (hours === 0 && minutes === 0) {
                return null;
            }

            let date = setMinutes(setSeconds(new Date(), 0), 0);
            date = setHours(date, hours);
            date = addMinutes(date, minutes - 1); // it works

            return date;
        },

        maxEndTime() {
            return convertColonedTimeToDate(this.minMaxRange?.endTime);
        },
    },

    methods: {
        updateStartTime(value) {
            this.$emit('input', {
                ...this.value,
                startTime: value,
            });
        },

        updateEndTime(value) {
            this.$emit('input', {
                ...this.value,
                endTime: value,
            });
        },
    },
};
</script>

<style lang="scss">
.shipment-time-picker__time {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
}
</style>

import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { ToggleSwitch } from '@schuettflix/react-components';
import { useVueEmit } from '@/reactBridge/EmitVueEventProvider';
export default function AccompanyingDisposalDocumentToggle({ title, text, toggleOnLabel, toggleOffLabel, automaticAccompanyingDocumentCreation, }) {
    const [isAutomaticallyCreated, setIsAutomaticallyCreated] = useState(automaticAccompanyingDocumentCreation || false);
    const { emitVueEvent } = useVueEmit();
    const handleToggle = () => {
        setIsAutomaticallyCreated(isAutomaticallyCreated => !isAutomaticallyCreated);
    };
    useEffect(() => {
        emitVueEvent('automaticBGSCreationToggle', { value: isAutomaticallyCreated });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAutomaticallyCreated]);
    return (<div>
            <div>
                <p className={clsx('font-copy-md-strong mb-2')}>{title}</p>
                <span className={clsx('font-copy-md mb-6')}>{text}</span>
            </div>

            <div className="flex flex-row items-center justify-between">
                <p className={clsx('font-copy-md-strong')}>{isAutomaticallyCreated ? toggleOnLabel : toggleOffLabel}</p>
                <ToggleSwitch checked={isAutomaticallyCreated} onChange={handleToggle}/>
            </div>
        </div>);
}

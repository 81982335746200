<template>
    <LayoutPage screen-name="reporting" class="reporting">
        <div slot="pageTitle">{{ $t('pages.reporting.title') }}</div>

        <FilterBar :loading="isLoading" show-refresh transparent @refresh="refreshData">
            <div slot="sorting" class="reporting__filter-box">
                <template>
                    <div class="reporting__selected-time-range reporting__selected-time-range--desktop">
                        <Words middle> {{ $t('pages.reporting.selectedTimeRange') }}&nbsp; </Words>
                        <Words bold middle> {{ $d(fromDate, 'short') }} – {{ $d(toDate, 'short') }} </Words>
                    </div>
                    <CalendarIcon width="24" />
                    <SelectBox
                        :value="selectedTimeRange"
                        primary
                        light
                        small
                        class="select-input--time-range"
                        @input="handleTimeRangeSelection($event)"
                    >
                        <option v-for="(option, key) in timeRangeOptions" :key="key" :value="option">
                            {{ $t(`pages.reporting.timeRanges.${key}`) }}
                        </option>
                    </SelectBox>
                </template>
            </div>
        </FilterBar>

        <div class="reporting__selected-time-range reporting__selected-time-range--mobile">
            <Words middle> {{ $t('pages.reporting.selectedTimeRange') }}&nbsp; </Words>
            <Words bold middle> {{ $d(fromDate, 'short') }} – {{ $d(toDate, 'short') }} </Words>
        </div>

        <LoadingSpinner v-if="isLoading && !report" block dark />

        <transition-group tag="div" name="fade">
            <div v-if="logisticReport" key="logistic-report" class="container-deprecated">
                <Words v-if="materialReport" block bold class="reporting__headline">
                    {{ $t('pages.reporting.headlines.logistic') }}
                </Words>

                <div class="reporting__grid">
                    <NumberWithIcon
                        :transports="logisticReport.transportTotalCount"
                        :revenue="logisticReport.transportTotalSum"
                    />
                    <TwoNumbers
                        v-if="logisticReport.transportDelayedCount || logisticReport.penaltySum"
                        :count="logisticReport.transportDelayedCount"
                        :sum="logisticReport.penaltySum"
                    />
                    <VehicleClassList
                        v-if="logisticReport.topVehicleClasses && logisticReport.topVehicleClasses.length"
                        :items="logisticReport.topVehicleClasses"
                    />
                </div>
            </div>

            <div v-if="materialReport" key="material-report" class="container-deprecated">
                <Words v-if="logisticReport" block bold class="reporting__headline">
                    {{ $t('pages.reporting.headlines.material') }}
                </Words>

                <div class="reporting__grid">
                    <NumberWithIcon
                        :transports="materialReport.transportTotalCount"
                        :revenue="materialReport.transportTotalSum"
                    />
                    <ProductList
                        v-if="materialReport.topProductsByQty && materialReport.topProductsByQty.length"
                        :headline="$t('pages.reporting.panels.productList.headlineQty')"
                        :items="materialReport.topProductsByQty"
                        type="qty"
                    />
                    <ProductList
                        v-if="materialReport.topProductsByTotal && materialReport.topProductsByTotal.length"
                        :headline="$t('pages.reporting.panels.productList.headlineRevenue')"
                        :items="materialReport.topProductsByTotal"
                        type="revenue"
                    />
                </div>
            </div>
        </transition-group>
    </LayoutPage>
</template>

<script>
import addDays from 'date-fns/add_days';
import subDays from 'date-fns/sub_days';
import getTime from 'date-fns/get_time';

import Toaster from '@/services/Toaster';
import ReportingApi from '@/services/Api/Reporting';

import FilterBar from '@/components/Filter/FilterBar';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import NumberWithIcon from './components/NumberWithIcon';
import TwoNumbers from '@/pages/Reporting/components/TwoNumbers';
import VehicleClassList from '@/pages/Reporting/components/VehicleClassList';
import SelectBox from '@/components/Form/SelectBox';
import ProductList from './components/ProductList';
import Words from '@/components/Typography/Words';

import CalendarIcon from '@/assets/icons/regular/calendar.svg';

export default {
    name: 'ReportingPage',
    components: {
        FilterBar,
        LayoutPage,
        LoadingSpinner,
        NumberWithIcon,
        TwoNumbers,
        VehicleClassList,
        SelectBox,
        ProductList,
        Words,
        CalendarIcon,
    },
    data() {
        return {
            report: null,
            isLoading: false,
            selectedTimeRange: -30,
            fromDate: null,
            toDate: null,
            type: null,
        };
    },
    computed: {
        logisticReport() {
            return this.report && this.report.logistic;
        },
        materialReport() {
            return this.report && this.report.material;
        },
        timeRangeOptions() {
            const options = {};

            if (this.$can('viewForecastReport')) {
                options.next30days = 30;
                options.next7days = 7;
            }

            if (this.$can('viewHistoryReport')) {
                options.past7days = -7;
                options.past30days = -30;
            }

            return options;
        },
    },
    created() {
        if (!this.$can('viewHistoryReport')) {
            this.selectedTimeRange = 30;
        }
        this.getTimeRange();
        this.refreshData();
    },
    methods: {
        getTimeRange() {
            const today = new Date();

            const baseDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            if (this.selectedTimeRange < 0) {
                this.fromDate = subDays(baseDate, Math.abs(this.selectedTimeRange));
                this.toDate = today;
                this.type = 'history';
            } else {
                this.fromDate = today;
                this.toDate = addDays(baseDate, this.selectedTimeRange);
                this.type = 'forecast';
            }
        },

        handleTimeRangeSelection(event) {
            this.selectedTimeRange = event;
            this.getTimeRange();
            this.refreshData();
        },

        async refreshData() {
            this.isLoading = true;

            try {
                if (this.type === 'history') {
                    this.report = await ReportingApi.getHistory(getTime(this.fromDate));
                } else {
                    this.report = await ReportingApi.getForecast(getTime(this.toDate));
                }
                this.isLoading = false;
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(err);
            }
        },
    },
};
</script>

<style lang="scss">
.reporting__headline {
    margin-top: 20px;
    margin-bottom: 20px;
}

.reporting__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.reporting__section {
    background-color: $color-white;
    padding: 20px;
    margin: 0 0 20px;
    box-shadow: $boxShadow-bottomShort;
    width: 100%;
    align-self: start;
}

.reporting__filter-box {
    display: flex;
    align-items: center;
}

.reporting__selected-time-range {
    height: 45px;
    line-height: 45px;
}

.reporting__selected-time-range--desktop {
    display: none;
    border-right: 1px solid $color-mediumGrey;
    padding-right: 30px;
    margin-right: 20px;

    @media screen and (min-width: $screen-md) {
        display: block;
    }
}

.reporting__selected-time-range--mobile {
    display: block;
    padding: 0 20px;
    margin-bottom: 20px;

    @media screen and (min-width: $screen-md) {
        display: none;
    }
}

.select-input--time-range {
    display: inline-block;
    position: relative;
    z-index: 1;

    .select-input__label {
        pointer-events: none;
        line-height: 70px;
        display: block;
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        padding: 0 20px 0 0;
        height: 70px;
        z-index: 10;
    }

    .select-input__input {
        padding-top: 0;
        padding-bottom: 0;
    }

    .select-input__label--dark {
        background-color: $color-lightGrey;
    }
}
</style>

import _orderBy from 'lodash/orderBy';
import TransportPricingView from '@/pages/Checkout/components/PriceAdjustment/models/TransportPricingView';
import WasteTransportPricingView from '@/pages/Checkout/Disposal/ProjectPosition/PriceAdjustment/Models/WasteTransportPricingView';

export default class TransportPricingCollectionView {
    constructor() {
        this._collection = [];
    }

    /**
     * Create a view model for transport pricing
     *
     * @param quote
     * @param data
     * @return {TransportPricingCollectionView|null}
     */
    static createFromCustomOrder(quote, data) {
        if (!data) return null;

        const view = new TransportPricingCollectionView();

        Object.keys(data).forEach(vehicleClassId => {
            view.add(
                TransportPricingView.createFromCustomOrder(quote, {
                    ...data[vehicleClassId],
                    distance: quote.lineItemGroups[0].lineItems[0].totalDistance,
                    duration: quote.lineItemGroups[0].lineItems[0].totalDuration,
                    id: vehicleClassId,
                })
            );
        });

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param {object} data
     * @param {object} calculatedVehicleClassesSet
     * @return {TransportPricingCollectionView|null}
     */
    static createFromProjectPositionPayload(data, calculatedVehicleClassesSet) {
        if (!data?.vehicleClasses) return null;

        const view = new TransportPricingCollectionView();

        const activeVCSet = {};
        data.vehicleClasses.forEach(vcInfo => {
            activeVCSet[vcInfo.vehicleClass.id] = vcInfo;
        });

        const activeVCIds = Object.keys(activeVCSet);

        const firstVC = calculatedVehicleClassesSet[Object.keys(calculatedVehicleClassesSet)[0]];
        const distance = firstVC?.distance;
        const duration = firstVC?.duration;

        // Iterate over all vc provided from calculate-project-position endpoint
        Object.keys(calculatedVehicleClassesSet).forEach(vehicleClassId => {
            // If it's an active vc, add it per payload
            if (activeVCIds.includes(vehicleClassId)) {
                view.add(
                    TransportPricingView.createFromProjectPositionPayload({
                        ...activeVCSet[vehicleClassId],
                        distance,
                        duration,
                    })
                );
            } else {
                // Add vc as if its from calculate-project-position
                view.add(
                    TransportPricingView.createFromProjectPositionBasket({
                        ...calculatedVehicleClassesSet[vehicleClassId],
                        qty: calculatedVehicleClassesSet[vehicleClassId].payload,
                        count: 1,
                        id: vehicleClassId,
                    })
                );
            }
        });

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {TransportPricingCollectionView|null}
     */
    static createFromProjectPositionBasket(data) {
        if (!data) return null;

        const view = new TransportPricingCollectionView();

        Object.keys(data).forEach(vehicleClassId => {
            view.add(
                TransportPricingView.createFromProjectPositionBasket({
                    ...data[vehicleClassId],
                    qty: data[vehicleClassId].payload,
                    count: 1,
                    id: vehicleClassId,
                })
            );
        });

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {TransportPricingCollectionView|null}
     */
    static createFromDisposalPricesPayload(data) {
        if (!data) return null;

        const view = new TransportPricingCollectionView();

        data.map(vehicleData => {
            view.add(WasteTransportPricingView.createFromDisposalPricesPayload(vehicleData));
        });

        return view;
    }

    /**
     * Create a view model for transport pricing from vehicle classes
     *
     * @param data
     * @return {TransportPricingCollectionView|null}
     */
    static createFromVehicleClasses(data) {
        if (!data) return null;

        const view = new TransportPricingCollectionView();

        Object.keys(data).forEach(key => {
            view.add(TransportPricingView.createFromVehicleClass(data[key]));
        });

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {TransportPricingCollectionView|null}
     */
    static create(data) {
        if (!data) return null;

        const view = new TransportPricingCollectionView();

        Object.keys(data).forEach(vehicleClassId => {
            view.add(
                TransportPricingView.create({
                    ...data[vehicleClassId],
                    id: vehicleClassId,
                })
            );
        });

        return view;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        const data = {};

        this.collection.forEach(item => {
            data[item.id] = item.unfold();
        });

        return data;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfoldToCustomOrder(billingMethod) {
        const data = {};

        this.collection.forEach(item => {
            data[item.id] = item.unfoldToCustomOrder(billingMethod);
        });

        return data;
    }

    /**
     * Clone current instance
     * @return {TransportPricingView|null}
     */
    clone() {
        return TransportPricingCollectionView.create(this.unfold());
    }

    /**
     * @param {TransportPricingView} item
     */
    add(item) {
        this.collection = _orderBy([...this.collection, item], ['payload'], ['asc']);
    }

    /**
     * get collection
     * @return {[TransportPricingView]}
     */
    get collection() {
        return this._collection;
    }

    /**
     * set collection
     * @param value
     */
    set collection(value) {
        this._collection = value;
    }

    // Getter --------------------

    get enabled() {
        return this.collection.filter(i => i.enabled);
    }
}

import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
const eventName = 'product';
export function trackLeaveForm(type, hasSaved, categoryId, productId, inputValue, factoryId, validationError) {
    AnalyticsService.trackEvent(eventName, {
        step: `${type}_product`,
        success: hasSaved,
        categoryId,
        productId,
        inputValue,
        factoryId,
        validationError,
    });
}
export function trackSetInputValue(type, inputName, inputValue, productId, categoryId, factoryId) {
    AnalyticsService.trackEvent(eventName, {
        step: `${type}_product_select`,
        name: inputName,
        inputType: inputValue,
        productId,
        factoryId,
        categoryId,
    });
}

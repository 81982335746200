<template>
    <LayoutPage class="project-position-list-page" :screen-name="screenName">
        <div slot="pageTitle">{{ $t('pages.project.position.list.headline') }}</div>

        <template #mobileHeader>
            <HeaderBar is-main>
                <div slot="headline">
                    {{ $t('pages.project.position.list.headline') }}
                </div>

                <HeaderBarItem slot="right">
                    <FilterSort
                        :sort-by="projectPositionListing.filter.seedSortBy"
                        :sort-direction="projectPositionListing.filter.sortDirection"
                        :sort-options="projectPositionListing.sortOptions"
                        :option-label-renderer="value => $t(`components.projectFilter.sorting.${value}`)"
                        @sort-by-update="projectPositionListing.filter.seedSortBy = $event"
                        @sort-direction-update="projectPositionListing.filter.sortDirection = $event"
                        @updated-sorting="projectPositionListing.refreshList(false, true)"
                    />
                </HeaderBarItem>
            </HeaderBar>
        </template>

        <ProjectPositionListFilter
            v-model="projectPositionListing.filter"
            :projects="projectPositionListing.result"
            :sort-options="projectPositionListing.sortOptions"
            :is-loading="projectPositionListing.isLoading"
            :endpoint="projectPositionListing.endpoint"
            @input="projectPositionListing.refreshList(false, true)"
            @pageNumberUpdated="projectPositionListing.updatePageNumber($event)"
        />

        <ProjectPositionList
            :project-positions="projectPositionListing.resultItems"
            :count="projectPositionListing.resultCount"
            :is-loading="projectPositionListing.isLoading"
        >
            <Card v-if="projectPositionListing.resultCount" slot="footer" spaceless>
                <Pagination
                    align-right
                    :result="projectPositionListing.result"
                    @pageNumberUpdated="projectPositionListing.updatePageNumber($event)"
                />
            </Card>
        </ProjectPositionList>
    </LayoutPage>
</template>

<script>
import FilterableListing from '@/services/FilterableListing/FilterableListing';
import ProjectPositionApi from '@/services/Api/ProjectPosition';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import routeContext from '@/plugins/mixins/routeContext';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import { mapGetters } from 'vuex';
import { useRouteContext } from '@/utils/composition-helper';
import { CONTEXT_CARRIER, CONTEXT_SUPPLIER } from '@/constants/context';

import Card from '@/components/Layout/Card';
import FilterSort from '@/components/Filter/FilterSort';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import LayoutPage from '@/components/Layout/Page.v2';
import Pagination from '@/components/Pagination';
import ProjectPositionList from '@/pages/Project/components/ProjectPositionList/ProjectPositionList';
import ProjectPositionListFilter from '@/pages/Project/components/ProjectPositionList/ProjectPositionListFilter';
import ProjectPositionView from '@/models/ProjectPositionView';
import { computed } from 'vue';

const DEFAULT_PAGE_COUNT = 25;

export default {
    name: 'ProjectPositionListPage',
    components: {
        Card,
        FilterSort,
        HeaderBar,
        HeaderBarItem,
        LayoutPage,
        Pagination,
        ProjectPositionList,
        ProjectPositionListFilter,
    },
    mixins: [persistentFiltersMixin, eventHubMixin, routeContext],
    setup() {
        const { inRouteContext } = useRouteContext();

        const screenName = computed(() => {
            if (inRouteContext(CONTEXT_SUPPLIER)) {
                return 'seller-project-list';
            }

            if (inRouteContext(CONTEXT_CARRIER)) {
                return 'carrier-project-list';
            }

            return undefined;
        });

        return {
            screenName,
        };
    },
    data() {
        return {
            projectPositionListing: null,
        };
    },
    computed: {
        ...mapGetters('user', ['organizationId']),
    },
    watch: {
        $route() {
            this.projectPositionListing.onRouteChanged();
        },
    },
    created() {
        this.projectPositionListing = this.createListing();
        this.projectPositionListing.pairComponent(this);
        this.projectPositionListing.refreshList(true);
    },
    methods: {
        createListing() {
            const defaultRestriction = {
                sortBy: 'number',
                seedSortBy: 'projectNumber',
            };

            if (this.inRouteContext(CONTEXT_SUPPLIER)) {
                defaultRestriction.supplierOrganization = this.organizationId;
            }

            if (this.inRouteContext(CONTEXT_CARRIER)) {
                defaultRestriction.carrierOrganization = this.organizationId;
            }

            return new FilterableListing({
                namespace: 'projectPosition',
                endpoint: ProjectPositionApi,
                defaultFilter: {
                    status: 'open',
                    page: 1,
                    perPage: DEFAULT_PAGE_COUNT,
                    sortBy: 'number',
                    sortDirection: 'desc',
                    seedSortBy: 'projectNumber',
                },
                sortOptions: ['number'],
                defaultRestriction,
                transformCallback: item => ProjectPositionView.create(item),
            });
        },
    },
};
</script>

<style lang="scss" scoped></style>

<template>
    <div class="project-position-block shadow-low">
        <div
            v-if="projectPosition.showRemainingValidity"
            class="flex items-center justify-center bg-surface-warning py-2"
        >
            <SfWarningIcon size="xxs" class="mr-1" />
            <span class="font-copy-sm">
                {{ $tc('components.ProjectRemainingValidityTag.remaining', projectPosition.remainingValidityInDays) }}
            </span>
        </div>
        <div
            v-if="isInactive && !projectPosition.showRemainingValidity"
            class="flex items-center justify-center bg-surface-warning py-2"
        >
            <SfWarningIcon size="xxs" class="mr-1" />
            <span class="font-copy-sm">{{ $t('components.ProjectRemainingValidityTag.none') }}</span>
        </div>
        <div class="bg-surface p-6">
            <div class="flex flex-wrap lg:flex-nowrap lg:border-b lg:pb-6 lg:pt-4">
                <div class="project-position-block__head-meta">
                    <span class="font-copy-xs mb-1">
                        {{ $t('components.projectPositionListBlock.label.number') }}
                    </span>
                    <span class="font-copy-md-strong">
                        {{ projectPosition.number }}
                    </span>
                </div>
                <div
                    v-if="projectPosition.loadingAddress && (isClient || isPlatform)"
                    class="project-position-block__head-meta"
                >
                    <span class="font-copy-xs mb-1">
                        {{ $t('components.projectPositionListBlock.label.loading') }}
                    </span>
                    <template v-if="projectPosition.loadingName">
                        <span class="font-copy-md-strong">
                            {{ projectPosition.loadingName }}
                        </span>

                        <FormattedAddress :address="projectPosition.loadingAddress" small class="mt-1" />
                    </template>
                    <template
                        v-else-if="!projectPosition.loadingName && projectPosition.loadingAddress.additionalAddress"
                    >
                        <span class="font-copy-md-strong">
                            {{ projectPosition.loadingAddress.additionalAddress }}
                        </span>

                        <FormattedAddress
                            :address="projectPosition.loadingAddress"
                            hide-additional-address
                            small
                            class="mt-1"
                        />
                    </template>
                    <template v-else>
                        <FormattedAddress :address="projectPosition.loadingAddress" class="font-copy-md-strong" />

                        <span v-if="projectPosition.weighingAtLoadingPoint" class="font-copy-xs mt-1 inline-block">
                            {{ $t('components.projectPositionListBlock.label.weighing') }}
                        </span>

                        <span
                            v-if="projectPosition.weighingBillingBasis === 'loading'"
                            class="font-copy-xs ml-1 mt-1 inline-block"
                        >
                            {{ $t('components.projectPositionListBlock.label.billingBase') }}
                        </span>
                    </template>
                </div>
                <div
                    v-if="projectPosition.unloadingAddress && (isClient || isPlatform)"
                    class="project-position-block__head-meta"
                >
                    <span class="font-copy-xs mb-1">
                        {{ $t('components.projectPositionListBlock.label.unloading') }}
                    </span>
                    <template v-if="projectPosition.unloadingName">
                        <span class="font-copy-md-strong">
                            {{ projectPosition.unloadingName }}
                        </span>

                        <FormattedAddress :address="projectPosition.unloadingAddress" small class="mt-1" />
                    </template>
                    <template
                        v-else-if="!projectPosition.unloadingName && projectPosition.unloadingAddress.additionalAddress"
                    >
                        <span class="font-copy-md-strong">
                            {{ projectPosition.unloadingAddress.additionalAddress }}
                        </span>

                        <FormattedAddress
                            :address="projectPosition.unloadingAddress"
                            hide-additional-address
                            small
                            class="mt-1"
                        />
                    </template>
                    <template v-else>
                        <FormattedAddress :address="projectPosition.unloadingAddress" class="font-md-strong" />

                        <span v-if="projectPosition.weighingAtUnloadingPoint" class="font-copy-xs mt-1 inline-block">
                            {{ $t('components.projectPositionListBlock.label.weighing') }}
                        </span>

                        <span
                            v-if="projectPosition.weighingBillingBasis === 'unloading'"
                            class="font-copy-xs ml-1 mt-1 inline-block"
                        >
                            {{ $t('components.projectPositionListBlock.label.billingBase') }}
                        </span>
                    </template>
                </div>
                <div
                    v-if="projectPosition.constructionSite && (isClient || isPlatform) && isProjectTypeDelivery"
                    class="project-position-block__head-meta"
                >
                    <span class="font-copy-xs mb-1">
                        {{ $t('components.projectPositionListBlock.label.loading') }}
                    </span>
                    <span class="font-copy-md-strong">
                        {{ projectPosition.factory.name }}
                    </span>

                    <FormattedAddress v-if="projectPosition.factory" :address="projectPosition.factory.address" small />
                </div>
                <div
                    v-if="projectPosition.constructionSite && (isClient || isPlatform) && !projectPosition.isDisposal"
                    class="project-position-block__head-meta"
                >
                    <span class="font-copy-xs mb-1">
                        {{ $t('components.projectPositionListBlock.label.constructionSite') }}
                    </span>
                    <span class="font-copy-md-strong">
                        {{ projectPosition.constructionSite.name }}
                    </span>

                    <FormattedAddress :address="projectPosition.constructionSite.address" small />
                </div>
                <div
                    v-if="projectPosition.supplier && projectPosition.type !== 'delivery'"
                    class="project-position-block__head-meta"
                >
                    <span class="font-copy-xs mb-1">
                        {{
                            isClient || isPlatform || isCarrier
                                ? $t('components.projectPositionListBlock.label.supplier')
                                : $t('components.projectPositionListBlock.label.factory')
                        }}
                    </span>

                    <span class="font-copy-md-strong">
                        {{ isSupplier ? projectPosition.factory.name : projectPosition.supplier.name }}
                    </span>

                    <FormattedAddress v-if="projectPosition.factory" :address="projectPosition.factory.address" small />
                </div>

                <div class="project-position-block__head-tag gap-x-4">
                    <SfTag
                        v-for="tag in projectPosition.tags"
                        :key="tag.label"
                        class="whitespace-nowrap"
                        :type="tag.type"
                        :subdued="tag.type === 'default' && tag.label.includes('inactive')"
                    >
                        {{ $t(tag.label) }}
                    </SfTag>
                </div>
            </div>
            <div class="project-position-block__body">
                <div
                    v-if="projectPosition.freightDescription && projectPosition.freightType"
                    class="project-position-block__product-info flex items-center"
                >
                    <div><SfMaterialIcon size="xs" class="mr-4" /></div>
                    <span
                        v-if="projectPosition.freightDescription"
                        class="freight-type-description font-copy-md align-middle"
                    >
                        {{ projectPosition.freightDescription }}
                    </span>
                    <SfTag type="default" outline class="freight-type-tag font-copy-xs whitespace-nowrap">
                        {{
                            $t(`components.projectPositionListBlock.freightType.${projectPosition.freightType}`)
                        }}</SfTag
                    >
                </div>
                <div
                    v-if="projectPosition && projectPosition.product && projectPosition.product.productNameLocalized"
                    class="project-position-block__product-info flex flex-wrap items-center gap-x-4 gap-y-2"
                >
                    <span class="flex items-center gap-x-4 overflow-auto">
                        <span class="min-w-6">
                            <SfMaterialIcon size="xs" />
                        </span>
                        <WasteCode
                            v-if="projectPosition.wasteCode"
                            as="span"
                            is-bold
                            font-size="md"
                            :code="projectPosition.wasteCode"
                            :is-hazardous="projectPosition.product.isHazardousWaste"
                        />
                        <span class="font-copy-md overflow-hidden text-ellipsis whitespace-nowrap">
                            {{ projectPosition.product.productNameLocalized }}
                        </span>
                    </span>
                    <div v-if="projectPosition.isDisposal" class="flex flex-nowrap gap-2">
                        <SfTag type="default" class="font-copy-sm whitespace-nowrap">
                            {{ projectPosition.product.variantDisposal.pollutionType.label }}
                        </SfTag>
                        <SfTag
                            v-if="projectPosition.product.isHazardousWaste"
                            type="error"
                            class="font-copy-sm whitespace-nowrap"
                            subdued
                        >
                            <SfWarningIcon size="xxs" class="mr-1" />
                            {{ $t('pages.project.position.view.freightType.waste_dangerous_plural') }}
                        </SfTag>
                    </div>
                </div>

                <ProjectPositionContingentProgress
                    :project-position="projectPosition"
                    class="project-position-block__progress-info"
                    size="md"
                />
                <span
                    v-if="isClient || isPlatform"
                    class="project-position-block__order-button font-copy-md ml-auto hidden whitespace-nowrap lg:block"
                >
                    <SfLink class="font-copy-md-strong">
                        {{
                            $t('components.projectPositionListBlock.actions.openOrder', {
                                count: projectPosition.orderCount,
                            })
                        }}
                    </SfLink>
                </span>
                <div v-if="isSupplier" class="project-position-block__prices">
                    <span class="font-copy-md">
                        {{ $n(projectPosition.quotationPrices.material.purchasePrice, 'currency') }} /
                        {{ getTranslationForBillingMethodUnit }}
                    </span>
                </div>

                <div v-if="isCarrier && projectPosition.biggestVehicleClassInfo" class="project-position-block__prices">
                    <span class="font-copy-xs text-subdued">
                        {{ projectPosition.biggestVehicleClassInfo.vehicleClass.name }}
                    </span>
                    <span class="mt- font-copy-md mt-2">
                        {{ $n(projectPosition.biggestVehicleClassInfo.purchasePrice, 'currency') }} /
                        {{ getTranslationForBillingMethodUnit }}
                    </span>
                </div>

                <div class="project-position-block__show-more-prices">
                    <SfLink class="font-copy-md-strong" @click="$emit('showMorePrices', projectPosition)">
                        {{ $t('components.projectPositionListBlock.actions.showMorePrices') }}
                    </SfLink>
                </div>
            </div>

            <div
                v-if="(isCarrier || isSupplier) && projectPosition.isDisposal"
                class="project-position-block__foot mt-6 border-t pt-4"
            >
                <div class="flex flex-col gap-6 lg:flex-row">
                    <template v-if="showImportantNumbersTile">
                        <span v-if="projectPosition.disposalProofNumber">
                            <span class="font-copy-xs text-subdued">
                                {{ $t('disposal.disposalCertificateNumber.label') }}</span
                            >
                            <span class="font-copy-md mt-2">{{ projectPosition.disposalProofNumber }}</span>
                        </span>
                        <div v-if="projectPosition.disposalProofNumber" class="border-b border-r" />
                        <span>
                            <span class="font-copy-xs text-subdued">
                                {{ $t('disposal.producerNumber.title') }}
                            </span>
                            <span class="font-copy-md mt-2">{{
                                projectPosition.producerNumber
                                    ? projectPosition.producerNumber
                                    : $t('pages.transport.transportViewV2.loadingDetail.notSpecifiedLabel')
                            }}</span>
                        </span>
                        <div class="border-b border-r" />
                        <span v-if="projectPosition.carrierNumber">
                            <span class="font-copy-xs text-subdued">{{ $t('disposal.carrierNumber.title') }}</span>
                            <span class="font-copy-md mt-2">{{ projectPosition.carrierNumber }}</span>
                        </span>
                        <div v-if="projectPosition.carrierNumber" class="border-b border-r" />
                        <span>
                            <span class="font-copy-xs text-subdued">{{
                                $t(
                                    'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.factoryDisposerNumber.title'
                                )
                            }}</span>
                            <span class="font-copy-md mt-2">{{
                                projectPosition.factory?.disposerNumber
                                    ? projectPosition.factory?.disposerNumber
                                    : $t('pages.transport.transportViewV2.loadingDetail.notSpecifiedLabel')
                            }}</span>
                        </span>
                    </template>
                    <SfLink class="font-copy-md-strong ml-auto mt-auto" @click="$emit('openAnalysis', projectPosition)">
                        {{ $t('pages.project.position.quotePrices.analysisModal') }}
                    </SfLink>
                </div>
            </div>

            <div v-if="showNote" class="project-position-block__foot mt-6 border-t pt-4">
                <span class="font-copy-xs mb-1">
                    {{ $t('components.projectPositionListBlock.label.note') }}
                </span>

                <span class="font-copy-md truncate whitespace-nowrap">
                    {{ projectPosition.note }}
                </span>

                <span
                    v-if="isClient || isPlatform"
                    class="project-position-block__order-button font-copy-md ml-auto mt-6 block whitespace-nowrap text-right lg:hidden"
                >
                    <SfLink class="font-copy-md-strong">
                        {{
                            $t('components.projectPositionListBlock.actions.openOrder', {
                                count: projectPosition.orderCount,
                            })
                        }}
                    </SfLink>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import ProjectPositionView from '@/models/ProjectPositionView';
import { getTranslationForBillingMethodUnit } from '@/services/utils/translation';
import routeContext from '@/plugins/mixins/routeContext';
import { CONTEXT_CARRIER, CONTEXT_CLIENT, CONTEXT_PLATFORM, CONTEXT_SUPPLIER } from '@/constants/context';
import { PROJECT_POSITION_TYPE_DANGEROUS_WASTE } from '@/constants/projectPositionTypes';

import WasteCode from '@/_components/WasteCode/WasteCode.vue';
import FormattedAddress from '@/components/FormattedAddress';
import ProjectPositionContingentProgress from '@/components/Project/ProjectPositionContingentProgress';

import { SfWarningIcon, SfTag, SfMaterialIcon, SfLink } from '@schuettflix/vue-components';

export default {
    name: 'ProjectPositionListBlock',
    components: {
        WasteCode,
        FormattedAddress,
        ProjectPositionContingentProgress,

        SfLink,
        SfMaterialIcon,
        SfWarningIcon,
        SfTag,
    },
    mixins: [routeContext],
    props: {
        projectPosition: {
            type: ProjectPositionView,
            required: true,
        },
    },
    computed: {
        getTranslationForBillingMethodUnit() {
            return getTranslationForBillingMethodUnit(this.projectPosition.billingMethod);
        },
        isPlatform() {
            return this.inRouteContext(CONTEXT_PLATFORM);
        },
        isClient() {
            return this.inRouteContext(CONTEXT_CLIENT);
        },

        isCarrier() {
            return this.inRouteContext(CONTEXT_CARRIER);
        },

        isSupplier() {
            return this.inRouteContext(CONTEXT_SUPPLIER);
        },

        isHazardousWaste() {
            return PROJECT_POSITION_TYPE_DANGEROUS_WASTE === this.projectPosition.type;
        },

        isProjectTypeDelivery() {
            return this.projectPosition.type === 'delivery';
        },

        isInactive() {
            return this.projectPosition.status !== 'open';
        },

        showImportantNumbersTile() {
            return this.projectPosition.type === PROJECT_POSITION_TYPE_DANGEROUS_WASTE;
        },

        showNote() {
            if (!this.projectPosition.note) return false;

            return this.isClient || this.isPlatform;
        },
    },
};
</script>

<style lang="scss">
$project-position-block-border: 1px solid $color-mediumGrey;

.project-position-block {
    // NOTE: required for contingent tooltips
    overflow: hidden;
    .freight-type-description {
        margin-right: 16px;
    }
    .freight-type-tag {
        margin-left: 0;
    }
}

.project-position-block__head-meta {
    order: 2;
    flex-basis: 100%;
    padding-bottom: 24px;
    border-bottom: $project-position-block-border;

    & + & {
        padding-top: 24px;
    }

    @media screen and (min-width: $layout-desktop-min) {
        order: 1;
        flex-basis: auto;
        padding: 0 24px 0 0;
        border-bottom: none;

        & + & {
            padding-top: 0;
            padding-left: 24px;
            border-left: $project-position-block-border;
        }
    }
}

.project-position-block__head-tag {
    display: flex;
    justify-content: flex-end;
    flex-basis: 100%;
    order: 1;

    @media screen and (min-width: $layout-desktop-min) {
        flex-basis: auto;
        flex-grow: 1;
        align-items: flex-start;
    }

    > * + * {
        margin-left: 15px;
    }
}

.project-position-block__body {
    padding-top: 24px;
    display: grid;
    align-items: center;
    grid-template-columns: minmax(0, 9fr) 3fr;
    grid-template-areas:
        'product upper'
        'progress lower';
    gap: 16px;

    @media screen and (max-width: 600px) {
        grid-template-columns: 100%;
        grid-template-areas:
            'product'
            'progress'
            'upper'
            'lower';
    }
}

.project-position-block__progress-info {
    grid-area: progress;
}

.project-position-block__product-info {
    grid-area: product;
}

.project-position-block__prices {
    justify-self: flex-end;
    grid-area: upper;
}

.project-position-block__show-more-prices {
    justify-self: flex-end;
    grid-area: lower;
}
</style>

<template>
    <div class="transport-list-group">
        <div class="card--action card-dark font-copy-md text-dark lg:card--md lg:flex-nowrap" @click="toggle()">
            <div class="flex w-full flex-wrap items-center gap-6 md:flex-nowrap md:border-b md:pb-6">
                <div class="order-1 flex items-center gap-2">
                    <!-- COUNTER -->
                    <div class="flex flex-nowrap items-center whitespace-nowrap font-bold">
                        <template v-if="isPlanView">
                            <CheckboxField
                                :multiple="isPlanningSelectable"
                                :on-dark-surface="isPlanningSelectable"
                                :black="!isPlanningSelectable"
                                class="mt-px"
                                :name="'group-' + group.orderNumber"
                                :option="planCheckOptions"
                                :value="planCheckValue"
                                :disabled="!isPlanningSelectable"
                                @input="togglePlanGroup"
                            />
                        </template>

                        {{ group.count }}
                        {{ $tc('components.transportListGroup.transportCountLabel', group.count) }}
                    </div>

                    <!-- NUMBER -->
                    <div class="group-separator order-2 shrink-0 font-bold lg:w-auto">#{{ group.orderNumber }}</div>
                </div>

                <div
                    class="order-4 flex w-full shrink gap-2 truncate border-t pt-6 md:order-3 md:w-auto md:border-t-0 md:pt-0"
                >
                    <div class="flex w-full gap-2 truncate md:w-auto">
                        <!-- GROUP NAME -->
                        <div class="flex flex-nowrap items-center gap-2 truncate md:grow lg:w-auto">
                            <div class="flex items-center">
                                <SfMaterialIcon size="xs" theme="dark" class="mr-2" /> {{ group.qty | tons }}
                            </div>
                            <WasteCode
                                v-if="group.wasteCode"
                                font-size="md"
                                class="text-dark"
                                :code="group.wasteCode"
                                :is-hazardous="group.isDangerous"
                                :is-bold="false"
                            />
                            <div class="w-full shrink truncate">
                                {{ group.productName }}
                            </div>
                        </div>

                        <!-- TAGS -->
                        <div v-if="showGroupTags" class="flex grow flex-wrap lg:shrink-0">
                            <SfTag v-if="isShipment && group.freightType" outline>
                                {{ $t(`productTypes.tags.${group.freightType}`) }}
                            </SfTag>
                            <SfTag v-if="isDisposal && group.isDangerous" type="error" outline>
                                <SfWarningIcon size="xxs" class="mr-2" />
                                {{ $t(`components.projectPositionListBlock.freightType.waste_dangerous`) }}
                            </SfTag>
                        </div>
                    </div>
                </div>
                <!-- ADDITIONAL INFOS -->
                <div class="order-3 grow">
                    <div class="flex items-center justify-end gap-6">
                        <div v-if="group.hasPreferredTransports" v-tooltip.top="group.preferredTransportLabel">
                            <StarIcon />
                        </div>

                        <SfProjectsIcon v-if="group.isProject" size="xs" theme="dark" />

                        <TransportGroupPrice :group="group" :context="context" />

                        <SfTag type="default" theme="dark">
                            {{
                                isSupplier
                                    ? $t(`components.transportListBlock.supplierType.${group.transportType}`)
                                    : $t(`components.transportListBlock.type.${group.transportType}`)
                            }}
                        </SfTag>

                        <!-- TOGGLE -->
                        <SfSysChevronUpIcon v-if="isActive" size="xs" theme="dark" />
                        <SfSysChevronDownIcon v-else size="xs" theme="dark" />
                    </div>
                </div>
            </div>
            <div class="flex w-full flex-wrap items-center gap-6 pt-6">
                <!-- PURCHASED VEHICLE CLASS -->
                <div v-if="isShipment" class="group-separator group-separator--mobile flex shrink-0 lg:w-auto">
                    <div class="flex flex-nowrap items-center truncate">
                        <SfVehicleIcon size="xs" theme="dark" class="mr-2 shrink-0" />
                        {{ vehicleName }}
                    </div>
                </div>

                <!-- DATE -->
                <div
                    class="group-separator flex shrink-0 items-center lg:w-auto"
                    :class="{ 'w-full md:w-auto': isDelivery }"
                >
                    <SfCalendarIcon size="xs" theme="dark" class="mr-2 shrink-0" />
                    <div>
                        {{ group.displayDate }}

                        <div v-if="group.flixTransportsCount > 0" class="text-xs font-bold text-brand-primary">
                            {{
                                $tc('components.transportListGroup.flixTransportsCountLabel', group.flixTransportsCount)
                            }}
                        </div>
                    </div>
                </div>

                <!-- ADDRESS -->
                <div class="flex items-center justify-center gap-2" :class="{ 'group-separator': isPlatform }">
                    <div class="flex items-center gap-2">
                        <SfRouteIcon size="xs" theme="dark" class="shrink-0" />
                        <div>
                            <div class="flex">
                                {{ transportFromAddress?.zip }} {{ transportFromAddress?.city }},
                                {{ transportFromAddress?.country }}
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center gap-2">
                        <span>-</span>
                        <div>
                            <div class="flex">
                                {{ transportsToAddress?.zip }} {{ transportsToAddress?.city }},
                                {{ transportsToAddress?.country }}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- CLIENT -->
                <div v-if="isPlatform" class="flex w-full shrink items-center gap-2 truncate lg:w-auto">
                    <SfOrganizationsIcon theme="dark" size="xs" /> {{ group.clientName }}
                </div>
            </div>
        </div>
        <div v-if="isActive">
            <slot :is-planning-selectable="isPlanningSelectable" :is-planning-enabled="isPlanningEnabled" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CONTEXT_CLIENT, CONTEXT_PLATFORM, CONTEXT_SUPPLIER, CONTEXT_CARRIER } from '@/constants/context';
import { TRANSPORT_TYPE } from '@/constants/transportTypes';

import TransportListGroupView from '@/components/Transport/TransportListGroupView';
import TransportGroupPrice from '@/components/Transport/partials/TransportGroupPrice.vue';
import CheckboxField from '@/components/Form/CheckboxField';
import WasteCode from '@/_components/WasteCode/WasteCode.vue';

import StarIcon from '@/assets/icons/star_rating.svg';

import {
    SfCalendarIcon,
    SfMaterialIcon,
    SfProjectsIcon,
    SfRouteIcon,
    SfSysChevronDownIcon,
    SfSysChevronUpIcon,
    SfTag,
    SfVehicleIcon,
    SfWarningIcon,
    SfOrganizationsIcon,
} from '@schuettflix/vue-components';

export default {
    name: 'TransportListGroup',
    components: {
        TransportGroupPrice,
        CheckboxField,
        StarIcon,
        WasteCode,
        SfOrganizationsIcon,
        SfCalendarIcon,
        SfMaterialIcon,
        SfProjectsIcon,
        SfRouteIcon,
        SfSysChevronDownIcon,
        SfSysChevronUpIcon,
        SfTag,
        SfVehicleIcon,
        SfWarningIcon,
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        indeterminate: {
            type: Boolean,
            default: false,
        },
        checked: {
            type: Boolean,
            default: false,
        },
        group: {
            type: TransportListGroupView,
            required: true,
        },
        context: {
            type: String,
            default: CONTEXT_CLIENT,
        },
    },
    data() {
        return {
            isActive: false,
            isIndeterminate: false,
            isChecked: false,
        };
    },
    computed: {
        ...mapGetters({
            planTransports: 'transportPlan/transports',
            planTransportCount: 'transportPlan/transportCount',
            planActiveGroup: 'transportPlan/activeGroup',
        }),
        isMobile() {
            return !this.$root.isDesktop;
        },
        isPlatform() {
            return this.context === CONTEXT_PLATFORM;
        },
        isSupplier() {
            return this.context === CONTEXT_SUPPLIER;
        },
        isDelivery() {
            return this.group.transportType === TRANSPORT_TYPE.DELIVERY;
        },
        isShipment() {
            return this.group.transportType === TRANSPORT_TYPE.SHIPMENT;
        },
        isDisposal() {
            return this.group.transportType === TRANSPORT_TYPE.DISPOSAL;
        },
        vehicleName() {
            const firstTransport = this.group?.transports[0];
            return firstTransport ? firstTransport.vehicleName : '';
        },
        showGroupTags() {
            return (this.isShipment && this.group.freightType) || (this.isDisposal && this.group.isDangerous);
        },
        isPlanningEnabled() {
            return this.planActiveGroup === this.group.orderNumber;
        },
        isPlanView() {
            return (
                this.context === CONTEXT_CARRIER &&
                this.group.transports.every(
                    transport => this.$can('transportPlan', transport) && transport.status === 'assigned'
                )
            );
        },
        isPlanningSelectable() {
            return this.isPlanView;
        },
        isAllChecked() {
            return this.isPlanningEnabled && this.planTransportCount === this.group.count;
        },
        isPlanIndeterminate() {
            return this.isPlanningEnabled && this.planTransportCount < this.group.count && this.planTransportCount > 0;
        },
        planCheckOptions() {
            return this.group.transports.map(transport => transport.id);
        },
        planCheckValue() {
            return this.planTransports.map(transport => transport.id);
        },

        transportFromAddress() {
            return this.group.transports[0].loadingAddress;
        },

        transportsToAddress() {
            if (this.isDelivery) {
                return this.group.transports[0].constructionSite?.address;
            }
            return this.group.transports[0].unloadingAddress ?? this.group.transports[0].constructionSite?.address;
        },
    },
    created() {
        this.isActive = this.active;
        this.isIndeterminate = this.indeterminate;
    },
    methods: {
        toggle() {
            this.isActive = !this.isActive;
            this.$emit('update', this.isActive);
        },
        togglePlanGroup() {
            if (this.isPlanningEnabled && !this.isPlanIndeterminate) {
                this.$store.dispatch('transportPlan/disable', this.group.orderNumber);
                return;
            }
            this.$store.dispatch(
                'transportPlan/setTransports',
                this.group.transports.filter(transport => this.$can('transportPlan', transport))
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.group-separator {
    @apply relative
        after:absolute
        after:right-0
        after:top-1/2
        after:hidden
        after:h-6
        after:-translate-y-1/2
        after:border-r
        after:border-white
        md:pr-6
        md:after:block;

    &--mobile {
        @apply pr-6 after:block;
    }
}
</style>

import AbstractResource from './AbstractResource';

class UserLink extends AbstractResource {
    async getLinks() {
        try {
            const response = await this.apiService.get(this.resourcePath);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async addLink(username, password) {
        try {
            const response = await this.apiService.post(this.resourcePath, { username, password });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async deleteLink(id) {
        try {
            const response = await this.apiService.delete(`${this.resourcePath}/${id}`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async getLinkToken(id) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/token/${id}`);
            return response.data.token;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new UserLink('/user/link', {
    401: 'authenticator.errors.unauthorized',
    403: 'authenticator.errors.forbidden',
    404: 'userLink.errors.notFound',
    418: 'authenticator.errors.iAmATeapot',
    423: 'userLink.errors.locked',
    426: 'userLink.errors.needsReauthentication',
});

import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';

const getChildNodes = (items, parentValue, currentDepth, key, parentKey, sortKey, sortDirection) => {
    const children = [];
    _orderBy(items, [sortKey], [sortDirection])
        .filter(item => {
            return item[parentKey] === parentValue;
        })
        .forEach(item => {
            children.push(
                new TreeNode(
                    item,
                    getChildNodes(items, item[key], currentDepth + 1, key, parentKey, sortKey, sortDirection),
                    currentDepth + 1,
                    sortKey
                )
            );
        });
    return children;
};

const getParentValue = items => {
    const allIds = items.map(item => item.id);

    const parent = items.find(item => !allIds.includes(item.parentId));

    return _get(parent, 'parentId', null);
};

export default class TreeNode {
    constructor(item, children, depth = 0, isRoot = false) {
        this.item = item;
        this.children = children;
        this.depth = depth;
        this.isRoot = isRoot;
    }

    static factory(
        items,
        key = 'id',
        parentKey = 'parentId',
        parentValue = undefined,
        sortKey = 'sort',
        sortDirection = 'asc'
    ) {
        if (!parentValue) parentValue = getParentValue(items);

        items.forEach(item => {
            if (item.wasteCode) {
                item.name = item.wasteCode + ' ' + item.name;
            }
        });

        return new TreeNode(
            null,
            getChildNodes(items, parentValue, 0, key, parentKey, sortKey, sortDirection),
            0,
            true
        );
    }
}

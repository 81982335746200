import Vue from 'vue';
import smoothscroll from 'smoothscroll-polyfill';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { AnalyticsPlugin } from '@schuettflix/analytics-vue';

import App from '@/App';
import Setup from '@/setup/Setup';
import router, { findRouteName, routeBack } from '@/pages/router';
import store from '@/store';
import i18n from '@/i18n';
import { i18nSetup } from '@/i18n/i18nSetup';
import apiSetup from '@/services/Api/apiSetup';
import prototypes from '@/plugins/prototypes';
import filters from '@/plugins/filters';
import directives from '@/plugins/directives';
import abilityChecker from '@/plugins/abilityChecker';
import PortalVue from 'portal-vue';
import VTooltip from 'v-tooltip';

import { parseVersion } from './services/utils';

import FeatureFlagsService from '@/services/FeatureFlags/FeatureFlagsService';
import { setupGoogleMaps } from '@/services/GoogleMaps';
import { analyticsService } from '@/services/Analytics/new';
import { mutationCache, queryCache } from '@/reactBridge/queryClient';

import { installSflxApps } from './installSflxApps';

// ============================================================
// Default Styling
// ============================================================
import '@/scss/main.scss'; // DEPRECATED

/*
 * We can't import from `tailwindcss/tailwind.css` because it will be splitted into
 * a separate chunk. This affects CSS specificity and breaks some styles.
 */
import '@/scss/tailwind.css';

import LogService from '@schuettflix/util-log';

const Log = new LogService('Application');

// ============================================================
// General Error Reporting and Bug Tracing
// ============================================================
window.onerror = function (message, source, lineno, colno, error) {
    Log.error({ location: 'global', message, source, lineno, colno, error });
};

// Type for the bootstrap livecycle options
// interface BootstrapConfiguration {
//     isApp: boolean;
//     onCreate?: (vueInstance: VueInstance) => void;
//     onDeviceReady?: (vueInstance: VueInstance) => void;
//     onVersionMismatch?: () => void;
// }
export function bootstrapApplication(bootstrapConfiguration) {
    // ============================================================
    // Default configs and constants
    // ============================================================
    Vue.config.productionTip = false;
    window.appVersion = parseVersion(process.env.VUE_APP_VERSION);

    const DESKTOP_WIDTH = 1024;
    const DEBUG_MENU_ENABLED = (process.env.VUE_APP_DEBUG_MENU_ENABLED ?? '').toLowerCase() === 'true';
    const INTERVAL_PLATFORM_UPDATE_INFO_MS = 30 * 60 * 60 * 1000;

    // ============================================================
    // Setup ApiService and axios interceptors
    // ============================================================
    apiSetup({ onVersionMismatch: bootstrapConfiguration.onVersionMismatch });

    // ============================================================
    // Setup Google Maps APIs
    // ============================================================
    setupGoogleMaps(store);

    // ============================================================
    // Setup I18n
    // ============================================================
    i18nSetup();

    // ============================================================
    // Install SFLX Apps
    // ============================================================
    installSflxApps({
        analyticsService,
    });

    // Component aware utilities
    Vue.use(prototypes);
    Vue.use(filters);
    Vue.use(directives);
    Vue.use(abilityChecker, { store });
    Vue.use(PortalVue);
    Vue.use(VTooltip);
    Vue.use(VueQueryPlugin, { queryClientConfig: { mutationCache, queryCache } });
    Vue.use(AnalyticsPlugin, {
        devToolsInProd: true,
        service: analyticsService,
    });

    // ============================================================
    // Vue Error Reporting and Bug Tracing
    // ============================================================
    Vue.use(new LogService());
    Vue.config.warnHandler = (msg, vm, trace) => {
        Log.warn({ location: 'vue', warning: msg, vueComponent: vm, trace });
    };
    Vue.config.errorHandler = (err, vm, info) => {
        Log.error({ location: 'vue', error: err, vueComponent: vm, info });
    };

    // ============================================================
    // Helper functions and setup
    // ============================================================
    smoothscroll.polyfill();

    // ============================================================
    // Setup Vue
    // ============================================================
    new Vue({
        router,
        store,
        i18n,
        data: {
            isIOS:
                !!navigator.platform &&
                (/iPad|iPhone|iPod/.test(navigator.platform) ||
                    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)),
            isApp: bootstrapConfiguration.isApp,
            isDesktop: null,
            scrollingAnimationId: null,
            debugMenuEnabled: DEBUG_MENU_ENABLED,
            isAppActive: false,
        },
        beforeCreate() {
            if (bootstrapConfiguration.onDeviceReady) {
                document.addEventListener('deviceready', () => bootstrapConfiguration.onDeviceReady(this));
            }
            this.$store.dispatch('abilities/updateAbilities');
        },
        created() {
            window.addEventListener('resize', () => this.detectLayout());
            this.detectLayout();
            this.$store.commit('platform/setOffline', false);
            this.$store.commit('platform/setIsApp', false);

            i18n.locale = store.getters['i18n/locale'];

            this.$store.commit('request/reset');
            this.$store.dispatch('platform/updateInfo');
            this.$store.dispatch('user/updateUserInfo');
            this.$store.dispatch('user/updateUserLinks');

            FeatureFlagsService.setupPolling();

            this.$gracefulInterval(async () => {
                await this.$store.dispatch('platform/updateInfo');
            }, INTERVAL_PLATFORM_UPDATE_INFO_MS);

            if (bootstrapConfiguration.onCreate) {
                bootstrapConfiguration.onCreate(this);
            }

            analyticsService.enable();
        },
        methods: {
            routeBack,
            findRouteName,
            detectLayout() {
                this.isDesktop = document.body.offsetWidth >= DESKTOP_WIDTH;
            },
            forceRerender() {
                this.$store.commit('platform/forceRerender');
            },
        },
        render: function (h) {
            if (this.$store.state.abilities.abilities) {
                return h(App);
            }

            return h(Setup);
        },
    }).$mount('#app');
}

import i18n from '@/i18n';
/**
 * This function transforms takes the scoped variables within a list of global
 * variables and builds a table displaying the default, market and country scope
 * values.
 *
 * @param variables list of global variables
 * @returns table object containing headings and rows
 */
export function formatScopedVariablesTable(variables) {
    const scopedVariables = variables.filter(variable => variable.isScoped);
    const columns = [
        {
            displayName: i18n.t('pages.platformSettings.name'),
            accessor: row => ({
                name: row.name,
                description: row.description,
            }),
        },
        {
            displayName: i18n.t('pages.platformSettings.defaultValue'),
            accessor: row => row.defaultValue ?? '-',
        },
        {
            displayName: i18n.t('pages.platformSettings.marketDefault'),
            accessor: row => row.value ?? '-',
        },
    ];
    // Maps countries to a list of scopes that have a value for that country
    const countryScopes = new Map();
    for (const variable of scopedVariables) {
        for (const scope of variable.scope) {
            if (countryScopes.has(scope.country)) {
                countryScopes.get(scope.country)?.add(scope.scope);
            }
            else {
                countryScopes.set(scope.country, new Set([scope.scope]));
            }
        }
    }
    // Builds the columns for the scoped values.
    for (const [country, scopes] of countryScopes) {
        for (const scope of scopes) {
            columns.push({
                displayName: `${country} ${scope}`,
                accessor: row => {
                    // We are doing the equivalent of a `find` here.
                    // Using for .. of is a limitation of typescripts type inference.
                    // @see https://github.com/microsoft/TypeScript/issues/44373
                    for (const rowScopes of row.scope) {
                        // `scope` comes from the outer context of the closure.
                        // It represents the heading of the column and we use it
                        // to find the correct value of the scope per row.
                        if (rowScopes.scope === scope) {
                            return {
                                scope,
                                value: rowScopes.value,
                            };
                        }
                    }
                    return {
                        scope,
                        value: '-',
                    };
                },
            });
        }
    }
    return {
        headings: columns.map(({ displayName }) => displayName),
        rows: scopedVariables.map(variable => {
            const [name, defaultValue, marketDefaultValue, ...scopedValues] = columns.map(column => column.accessor(variable));
            return {
                name,
                defaultValue,
                marketDefaultValue,
                scopedValues,
                variable,
            };
        }),
    };
}

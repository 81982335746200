<template>
    <div class="transport-date" data-test="transport-time">
        <span v-if="label" class="mb-2 text-subdued" :class="labelClass">{{ label }}</span>
        <span class="flex items-center gap-x-4 gap-y-1">
            <SfInfoIcon v-if="isWarning" size="xxs" class="shrink-0" />
            <span class="flex w-max items-center gap-x-4 gap-y-1" :class="[fontClass, { 'flex-wrap': isFlexWrapable }]">
                <!-- SINGLE DATE -->
                <template v-if="shouldShowSingleDate">
                    <span>{{ $d(ensureJSTimestamp(dateFrom), 'short') }}</span>
                </template>

                <!-- DATE RANGE -->
                <template v-else>
                    <span v-if="dateFrom">
                        {{ $d(ensureJSTimestamp(dateFrom), 'short') }}
                        <span class="mx-1">&ndash;</span>
                        {{ $d(ensureJSTimestamp(dateTo), 'short') }}
                    </span>
                </template>

                <!-- SINGLE TIME -->
                <span v-if="shouldShowSingleTimeByDate" class="whitespace-nowrap">
                    {{ $t('timeSuffix', { time: $d(ensureJSTimestamp(dateFrom), 'time') }) }}
                </span>
                <span v-if="shouldShowSingleTimeByTime">
                    {{ formatTime(timeFrom) }}
                </span>

                <!-- TIME RANGE -->
                <span v-if="shouldShowTimeRangeByTime">
                    {{ formatTime(timeFrom) }} <span class="mx-1">&ndash;</span> {{ formatTime(timeTo) }}
                </span>
            </span>
        </span>
    </div>
</template>

<script>
import Vue from 'vue';
import { isSameDay } from 'date-fns';
import { ensureJSTimestamp } from '@/services/utils/date';

import { SfInfoIcon } from '@schuettflix/vue-components';

export default Vue.extend({
    name: 'TransportDate',
    components: {
        SfInfoIcon,
    },
    props: {
        label: {
            type: String,
            default: null,
        },
        dateFrom: {
            type: Number,
            default: 0,
        },
        dateTo: {
            type: Number,
            default: 0,
        },
        timeFrom: {
            type: [Number, String],
            default: 0,
        },
        timeTo: {
            type: [Number, String],
            default: 0,
        },
        isWarning: {
            type: Boolean,
            default: false,
        },
        hideTime: {
            type: Boolean,
            default: false,
        },
        labelClass: {
            type: [String, Object],
            default: 'font-copy-sm',
        },
        fontClass: {
            type: [String, Object, Array],
            default: 'font-copy-md',
        },
        isFlexWrapable: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        shouldShowSingleDate() {
            if (!this.dateFrom) return false;

            const dateFrom = new Date(ensureJSTimestamp(this.dateFrom));
            const dateTo = new Date(ensureJSTimestamp(this.dateTo || this.dateFrom));

            return isSameDay(dateFrom, dateTo);
        },
        shouldShowSingleTimeByDate() {
            return !this.hideTime && !this.timeFrom;
        },
        shouldShowSingleTimeByTime() {
            if (this.hideTime) return false;

            if (typeof this.timeFrom === 'string')
                return this.timeFrom && (!this.timeTo || this.timeFrom === this.timeTo);

            const timeFrom = new Date(ensureJSTimestamp(this.timeFrom));
            const timeTo = new Date(ensureJSTimestamp(this.timeTo));

            return (
                this.timeFrom &&
                (!this.timeTo ||
                    (timeFrom.getUTCHours() === timeTo.getUTCHours() &&
                        timeFrom.getUTCMinutes() === timeTo.getUTCMinutes()))
            );
        },
        shouldShowTimeRangeByTime() {
            if (this.hideTime) return false;

            if (typeof this.timeFrom === 'string') return this.timeFrom && this.timeTo && this.timeTo !== this.timeFrom;

            const timeFrom = new Date(ensureJSTimestamp(this.timeFrom));
            const timeTo = new Date(ensureJSTimestamp(this.timeTo));

            return (
                this.timeFrom &&
                this.timeTo &&
                (timeFrom.getUTCHours() !== timeTo.getUTCHours() || timeFrom.getUTCMinutes() !== timeTo.getUTCMinutes())
            );
        },
    },
    methods: {
        ensureJSTimestamp,
        formatTime(time) {
            if (typeof time === 'string') return this.$t('timeSuffix', { time });
            return this.$t('timeSuffix', { time: this.$d(ensureJSTimestamp(time), 'time') });
        },
    },
});
</script>

import { BILLING_METHODS } from '@/constants/billingTypes';
import i18n from '@/i18n';
/**
 * @param {BillingMethodsType} billingType string with billing type
 */
export function getTranslationForBillingMethodUnit(billingType) {
    if (billingType && ![BILLING_METHODS.FIXED_PRICE, BILLING_METHODS.WEIGHT].includes(billingType)) {
        throw new Error('The given billing type is invalid');
    }
    return {
        [BILLING_METHODS.WEIGHT]: i18n.t('units.ton'),
        [BILLING_METHODS.FIXED_PRICE]: i18n.t('units.transport'),
    }[billingType || BILLING_METHODS.WEIGHT];
}
export function getTranslationFromTranslationMap(translationMap) {
    const key = i18n.locale;
    return translationMap[key] || translationMap['de'] || translationMap['en'] || '';
}

<template>
    <nav class="sub-navigation">
        <header class="sub-navigation__header">
            <slot name="header" />
            <SubNavigationContextMenu
                v-if="showContextMenu"
                class="sub-navigation__context-menu"
                @contextMenuClicked="$emit('contextMenuClicked')"
            />
        </header>
        <ul v-if="hasNavItems" class="sub-navigation__list">
            <li v-for="(navItem, index) in navItems" :key="index" class="sub-navigation__item">
                <SubNavigationRouterLink
                    :nav-item="navItem"
                    :class="{ 'sub-navigation__link--no-progress': !progressCheckmarks }"
                    is-query-string-link
                    @routerChange="$emit('routerChange', $event)"
                >
                    <div v-if="progressCheckmarks" class="sub-navigation__progress">
                        <SubNavIcon :nav-item="navItem" />
                    </div>
                    <div class="sub-navigation__content">
                        <div class="sub-navigation__title">{{ $t(navItem.title) }}</div>
                        <div v-if="navItem.subTitle" class="sub-navigation__sub-title">
                            {{ $t(navItem.subTitle) }}
                        </div>
                        <div class="sub-navigation__text">
                            {{ $t(navItem.text) }}
                        </div>
                    </div>
                    <Arrow v-if="arrowDisplay(navItem)" class="sub-navigation__arrow" />
                </SubNavigationRouterLink>
            </li>
        </ul>
        <slot v-else />
        <div class="sub-navigation__back-link">
            <slot name="footer" />
        </div>
    </nav>
</template>

<script>
import Arrow from '@/assets/icons/regular/arrow-right-bold.svg';
import SubNavIcon from '@/_components/SubNavigation/SubNavIcon';
import SubNavigationContextMenu from '@/_components/SubNavigationContextMenu/SubNavigationContextMenu';
import SubNavigationRouterLink from '@/_components/SubNavigationRouterLink/SubNavigationRouterLink';

export default {
    name: 'SubNavigation',
    components: {
        Arrow,
        SubNavIcon,
        SubNavigationContextMenu,
        SubNavigationRouterLink,
    },
    props: {
        navItems: {
            type: Array,
            default: () => [],
        },
        progressCheckmarks: {
            type: Boolean,
            default: false,
        },
        showContextMenu: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        hasNavItems() {
            return this.navItems.length > 0;
        },
        hasFooterSlot() {
            return !!this.$slots.footer;
        },
    },
    methods: {
        arrowDisplay(navItem) {
            return this.$route.query.route !== navItem.to.query.route;
        },
    },
};
</script>

<style lang="scss" scoped>
$copy-font-size: 10px;
$title-font-size: 14px;
$border-color: #d8d8d8;

$headerTitleBarHeight: 80px;
$mobileNavContainerHeight: 73px;

.sub-navigation {
    background-color: #fff;
    border-right: 1px solid $border-color;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
    z-index: 1;
    overflow-y: hidden;
    position: relative;

    height: calc(
        100vh - (#{$headerTitleBarHeight} + #{$mobileNavContainerHeight})
    ); // header bar height + mobile nav height

    @media screen and (min-width: $layout-desktop-min) {
        height: calc(100vh - #{$headerTitleBarHeight}); // button height + header bar height
    }

    &__header {
        align-items: center;
        border-bottom: 1px solid $border-color;
        display: flex;
        justify-content: space-between;
        padding: 26px 26px 26px 24px;
        position: sticky;
        top: 0;
        background: white;
        z-index: 10;
    }

    &__list {
        list-style: none;
        margin: 0;
        padding-left: 0;
        overflow-y: auto;
    }

    &__item {
        border-bottom: 1px solid $border-color;
        position: relative;
        width: 100%;
    }

    ::v-deep &__link {
        align-items: center;
        color: inherit;
        cursor: pointer;
        display: flex;
        padding: 24px 65px 24px 0;
        text-decoration: none;
        width: 100%;

        &.sub-navigation__active,
        &:hover {
            background: #f2f2f2;
        }

        &--no-progress {
            padding-left: 24px;
        }
    }

    &__title {
        font-size: $title-font-size;
        font-weight: 700;
    }

    &__text {
        color: #605f64;
        font-size: $copy-font-size;
        margin-top: 4px;
    }

    &__arrow {
        width: 8px;
        height: 14px;
        position: absolute;
        right: 27px;
    }

    &__progress {
        padding: 0 16px 0 24px;
    }

    &__back-link {
        color: $color-darkGrey;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
        padding: 16px 24px 16px;
        text-decoration: none;
        position: sticky;
        bottom: 1px;
        background-color: white;
        border-top: 1px solid $border-color;
        margin-top: auto;

        svg {
            margin-right: 11px;
            transform: rotate(180deg);
        }
    }
}
</style>

<template>
    <div>
        <Headline :level="6">Produktbild</Headline>
        <div class="image-upload">
            <Thumbnail :src="value && value.url" inline modal />
            <ImageUpload
                :value="value"
                inline
                product-use-case="product_image"
                @error="handleFileError"
                @input="uploadImage"
            />
        </div>
        <ErrorMessage v-if="fileError" :message="fileError" class="span-4" style="margin-top: 1em" />
    </div>
</template>
<script>
import ErrorMessage from '@/components/Form/ErrorMessage';
import ImageUpload from '@/components/Form/ImageUpload';
import Headline from '@/components/Typography/Headline';
import Thumbnail from '@/components/Thumbnail';

export default {
    name: 'ProductImageUpload',
    components: { ErrorMessage, Headline, ImageUpload, Thumbnail },
    props: {
        value: {
            type: Object,
            default: undefined,
        },
    },
    data: () => ({
        fileError: null,
    }),
    methods: {
        handleFileError(error) {
            this.fileError = error;
        },
        uploadImage(image) {
            this.$emit('input', image);
        },
    },
};
</script>
<style lang="scss" scoped>
.image-upload {
    display: flex;
    flex-flow: row nowrap;
}
</style>

<template>
    <LayoutPage
        class="settings-page"
        :screen-name="isPlatformAdministrator ? 'platform-settings' : 'settings'"
        :child-routes="childRoutes"
    >
        <template #pageTitle>
            {{ $t('pages.settingsV2.title') }}
        </template>

        <div class="settings-page__container">
            <Card
                v-for="settingsItem in settingsItems"
                :key="settingsItem.name"
                spaceless
                class="settings-page__card"
                clickable
                :data-test="`settings-page-card-${settingsItem.name}`"
                @click="openRoute(settingsItem.route, settingsItem.name)"
            >
                <div class="settings-page__card-title-container">
                    <div class="settings-page__card-icon">
                        <NavigationIcon :icon="settingsItem.icon" class="icon--inline" />
                    </div>
                    <Words bold block>
                        {{ $t(`pages.settingsV2.titles.${settingsItem.name}`) }}
                    </Words>
                </div>
                <Words block small class="settings-page__description">
                    {{ $t(`pages.settingsV2.descriptions.${settingsItem.name}`) }}
                </Words>
                <BaseButton
                    class="settings-page__button"
                    @click.stop="openRoute(settingsItem.route, settingsItem.name)"
                >
                    <ArrowIcon class="icon--inline settings-page__arrow-icon" />
                </BaseButton>
            </Card>
        </div>
    </LayoutPage>
</template>

<script>
import { mapGetters } from 'vuex';
import { isPlatform, isSupplier } from '@/services/utils/organization';

import BaseButton from '@/components/Button/Button';
import Card from '@/components/Layout/Card';
import LayoutPage from '@/components/Layout/Page.v2';
import NavigationIcon from '@/components/Layout/Navigation/NavigationIcon';
import Words from '@/components/Typography/Words';

import ArrowIcon from '@/assets/icons/regular/arrow.svg';
import { useFeatureFlag } from '@/services/FeatureFlags/useFeatureFlags';

export default {
    name: 'SettingsPageV2',

    components: {
        BaseButton,
        Card,
        LayoutPage,
        NavigationIcon,
        Words,

        ArrowIcon,
    },

    setup() {
        const { isEnabled: isFeatureFlagDisposalCertificateManagementEnabled } = useFeatureFlag(
            'disposal_certificate_management'
        );

        return {
            isFeatureFlagDisposalCertificateManagementEnabled,
        };
    },

    computed: {
        ...mapGetters('user', ['user', 'isPlatformAdministrator']),

        childRoutes() {
            return [
                'management__settings__organization-management',
                'management__settings__user-management',
                'management__settings__vehicle-management',
                'management__settings__vehicle-class-management',
                'management__settings__vehicle-class-management__vehicle-edit',
                'management__settings__factory-management',
                'management__settings__platform-settings-management',
                'management__settings__preferred-carrier',
                'management__settings__organization-migration-list',
                'management__settings__organization-migration-list__organization-migration-detail',
                'management__settings__token-management',
                'management__settings__certificate-management',
                'management__settings__certificate-management__certificate-management-view',
            ];
        },

        settingsItems() {
            const items = [];

            if (this.$can('haveOrganizationManagement')) {
                items.push({
                    name: 'platformOrganizationManagement',
                    route: 'organization-management',
                    icon: 'stammdaten',
                });
            } else if (this.$can('viewOrganization', this.user.organization)) {
                items.push({
                    name: this.$can('updateOrganization', this.user.organization)
                        ? 'adminOrganizationManagement'
                        : 'organizationManagement',
                    route: 'organization-management',
                    icon: 'stammdaten',
                });
            }

            if (this.$can('haveUserManagement') && !this.$can('listAllUsers')) {
                items.push({
                    name: 'userManagement',
                    route: 'management__settings__user-management',
                    icon: 'nutzerverwaltung',
                });
            }

            if (this.$can('haveVehicleClassesManagement')) {
                items.push({
                    name: 'platformVehicleManagement',
                    route: 'vehicle-class-management',
                    icon: 'fahrzeugklassenmangement',
                });
            }

            if (this.$can('haveVehicleManagement')) {
                items.push({
                    name: 'vehicleManagement',
                    route: 'vehicle-management',
                    icon: 'fahrzeugklassenmangement',
                });
            }

            if (this.$can('haveFactories')) {
                items.push({
                    name: 'adminFactoryManagement',
                    route: 'factory-management',
                    icon: 'werke',
                });
            }

            if (this.$can('haveGlobalVariablesManagement')) {
                items.push({
                    name: 'platformManagement',
                    route: 'platform-settings-management',
                    icon: 'plattform_einstellungen',
                });
            }

            if (this.$can('havePreferredCarriers')) {
                items.push({
                    name: 'preferredCarrierManagement',
                    route: 'preferred-carrier',
                    icon: 'partnerspeditionen',
                });
            }

            if (isSupplier(this.user.organization.types) && this.$can('updateOrganization', this.user.organization)) {
                items.push({
                    name: 'tokenManagement',
                    route: 'token-management',
                    icon: 'migration',
                });
            }

            if (
                this.isFeatureFlagDisposalCertificateManagementEnabled &&
                this.$can('listDisposalCertificates') &&
                !isPlatform(this.user.organization.types)
            ) {
                items.push({
                    name: 'certificateManagement',
                    route: 'certificate-management',
                    icon: 'zertifikate',
                });
            }

            return items;
        },
    },

    methods: {
        openRoute(route, pageTitle) {
            this.$router
                .push({
                    name: this.$root.findRouteName(route),
                    params: { pageTitle: this.$t(`pages.settingsV2.titles.${pageTitle}`) },
                })
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss">
.settings-page {
    background-color: $color-littleDarkerThanLightMediumGrey;
}

.settings-page__container {
    margin: 20px auto 0px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 15px;
    row-gap: 15px;
    max-width: $layout-desktop-min;

    @media screen and (max-width: $layout-desktop-max) {
        margin: 15px;
        grid-template-columns: 1fr;
    }
}

.settings-page__card {
    display: flex;

    .card__content {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

.settings-page__card-title-container {
    align-items: center;
    display: grid;
    grid-template-columns: 48px auto;
}

.settings-page__card-icon {
    width: 48px;

    svg {
        width: 32px;
    }
}

.settings-page__description {
    flex: 1;
    margin-left: 48px;
    margin-top: 15px;
    hyphens: auto;
}

.settings-page__button {
    display: block;
    margin-left: auto;
    margin-top: 20px;
}

.settings-page__arrow-icon {
    transform: rotate(180deg);
}
</style>

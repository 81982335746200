import { useVuexState } from '@/reactBridge/useVuex';
import store from '@/store';
import { useTranslation } from 'react-i18next';
export const useMoneyFormatter = () => {
    const { t } = useTranslation();
    const locale = useVuexState(store, state => state.i18n.locale);
    const currencyCode = useVuexState(store, state => state.platform.info.market.currencyCode);
    return {
        formatPerTon: (amount) => {
            if (amount === null || amount === undefined) {
                return '';
            }
            const priceOptions = { style: 'currency', currency: currencyCode };
            const localePrice = new Intl.NumberFormat(locale, priceOptions).format(amount);
            return `${localePrice}/${t('units.ton')}`;
        },
    };
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flyout__content flyout__content--page",class:{
        'flyout__content--grey-background': _vm.backgroundGrey,
        'flyout__content--no-flex': _vm.noFlex,
    }},[(_vm.showHeader || _vm.$slots.header)?_c('div',{staticClass:"flyout__header"},[_vm._t("header",function(){return [(_vm.showHeader)?_c('HeaderBar',{scopedSlots:_vm._u([(!_vm.closeByX)?{key:"left",fn:function(){return [_c('HeaderBarItem',{attrs:{"button":"","data-test":"flyout-back-button"},on:{"click":_vm.closeFlyout}},[_c('SfSysArrowLeftIcon',{attrs:{"size":"xs"}})],1)]},proxy:true}:null,{key:"headline",fn:function(){return [_vm._v(_vm._s(_vm.headline || _vm.$t(_vm.$route.meta.title)))]},proxy:true},(_vm.subline)?{key:"subline",fn:function(){return [_vm._v(_vm._s(_vm.subline))]},proxy:true}:null,(_vm.closeByX)?{key:"right",fn:function(){return [_c('HeaderBarItem',{attrs:{"button":"","data-test":"flyout-close-button"},on:{"click":_vm.closeFlyout}},[_c('SfSysCloseIcon',{attrs:{"size":"xs"}})],1)]},proxy:true}:null],null,true)}):_vm._e()]}),_vm._t("subheader")],2):_vm._e(),_c('div',_vm._g({directives:[{name:"scrollable",rawName:"v-scrollable"}],staticClass:"flyout__inner scroll-container",class:{
            'flyout__inner--no-header': !_vm.$slots.header,
            'flyout__inner--no-footer': !_vm.$slots.footer,
        }},_vm.scrollContainerEvents),[_vm._t("default"),_vm._t("additional"),_c('div',{staticClass:"flyout__inner-spacer"},[_vm._v(" ")])],2),(_vm.$slots.footer)?_c('div',{staticClass:"flyout__footer"},[_vm._t("footer")],2):_vm._e()])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
    <LayoutPage
        is-flyout
        class="checkout-price-adjustments"
        :close-by-x="isProjectPositionEditMode"
        @close="closeFlyout()"
    >
        <template v-if="isProjectPositionEditMode" #pageTitle>
            <span>
                {{ $t('pages.checkout.priceAdjustments.titleChangePrices') }}
            </span>
        </template>
        <template v-if="!isProjectPositionEditMode" #flyoutHeader>
            <FlyoutHeader />
        </template>

        <LoadingSpinner v-if="!current" dark block />

        <transition name="fade">
            <div v-if="current" class="container-off-canvas-sm my-6">
                <ReactBridge
                    :react-component="PaymentTermsContainer"
                    :props="{
                        organizations: getPaymentTerms({ supplierInfo, carrierInfo }, 'shipment'),
                        paymentTermsProp: isProjectPositionEditMode
                            ? paymentTermsFromStore
                            : paymentTermsFromApi || paymentTermsFromStore,
                    }"
                    @change="handlePaymentTermsChange"
                    @defaultPaymentTerms="setDefaultPaymentTerms"
                />
                <HorizontalDivider size="12" />
                <DisposalCostSection
                    v-if="hasDisposalCost"
                    :is-price-per-transport="shipmentBillingDetails.billingMethod === 'fixedPrice'"
                    :value="disposalPrice"
                    @input="setDisposalPrice"
                />

                <TransportSection
                    v-if="current.transport"
                    class="mt-12"
                    :basket-type="basketType"
                    :fixed-price="!!shipmentBillingDetails && shipmentBillingDetails.billingMethod === 'fixedPrice'"
                    :transport-distance="pricesData.distance"
                    :transport-duration="pricesData.duration"
                    :transport-price-unit="transportPriceUnit"
                    :transports="current.transport"
                    :show-distance-notice="
                        basketType === 'shipment' || pricesData.type === pricesData.constructor.TYPE_PROJECT_SHIPMENT
                    "
                    @edit="editSection('transport')"
                />

                <ProjectCustomPositionsSection
                    v-if="hasCustomPositions"
                    class="mt-12"
                    :custom-positions="current.customPositions"
                    :is-pending="isPending()"
                    @edit="editCustomPosition($event)"
                    @remove="removeCustomPosition($event)"
                    @add="addCustomPosition($event)"
                />

                <TotalsSection
                    v-if="current.transport && current.transport.enabled.length > 0"
                    class="mt-12"
                    :fixed-price="!!shipmentBillingDetails && shipmentBillingDetails.billingMethod === 'fixedPrice'"
                    :current="current"
                    :material="current.freight"
                    :max-payload-transport="current.maxPayloadTransport"
                    :type="pricesData.type"
                />

                <Hint v-if="isProjectPositionEditMode" show-label transparent spaceless-x>
                    {{ $t('pages.checkout.priceAdjustments.changeProjectPositionPricesHint') }}
                </Hint>
            </div>
        </transition>

        <template #sticky>
            <SlideUp :active="hasValidData">
                <ButtonGroup>
                    <BaseButton :disabled="isPending()" primary light place-left @click="resetPriceData()">
                        {{ $t('pages.checkout.priceAdjustments.resetData') }}
                    </BaseButton>
                    <BaseButton
                        v-if="isProjectPositionEditMode"
                        :disabled="isPending()"
                        :is-loading="isPending('saveProjectPosition')"
                        primary
                        @click="saveProjectPosition()"
                    >
                        {{ $t('pages.checkout.priceAdjustments.savePricePosition') }}
                    </BaseButton>
                    <BaseButton
                        v-else
                        :disabled="isPending()"
                        primary
                        data-test="save-price-adjustment-button"
                        @click="submit()"
                    >
                        {{
                            isProject
                                ? $t('pages.checkout.projectShipmentPriceAdjustments.submitProjectPosition')
                                : $t('pages.checkout.priceAdjustments.submit')
                        }}
                    </BaseButton>
                </ButtonGroup>
            </SlideUp>
        </template>

        <template #subpages>
            <Flyout
                v-if="current"
                :active="section !== null"
                size="small"
                background-grey
                class="checkout-price-adjustments__flyout-edit"
                @closed="closeSection"
            >
                <template #header>
                    <HeaderBar>
                        <template v-if="section" #headline>
                            <div>
                                {{ $t(`pages.checkout.projectShipmentPriceAdjustments.${section}.changeLabel`) }}
                            </div>
                        </template>
                        <template #right>
                            <HeaderBarItem button @click="closeSection()">
                                <CloseIcon width="18" height="18" />
                            </HeaderBarItem>
                        </template>
                    </HeaderBar>
                </template>

                <template v-if="section === 'transport'">
                    <div class="checkout-price-adjustments__edit-transports container-off-canvas-sm my-6">
                        <template v-for="(vehicleClass, index) in sectionData.collection">
                            <TransportEditSection
                                :key="vehicleClass.id"
                                v-model="sectionData.collection[index]"
                                :original-vehicle-class="original.transport.collection[index]"
                                :fixed-price="
                                    !!shipmentBillingDetails && shipmentBillingDetails.billingMethod === 'fixedPrice'
                                "
                                :transport-price-unit="transportPriceUnit"
                                :show-enable-controls="
                                    basketType === 'project-shipment' ||
                                    pricesData.type === pricesData.constructor.TYPE_PROJECT_SHIPMENT
                                "
                            />
                        </template>
                    </div>
                </template>

                <Tile v-if="section === 'customPosition'" transparent border-dark>
                    <CustomPositionEditSection
                        v-model="sectionData"
                        process-i18n-key="projectShipmentPriceAdjustments"
                        @change="checkIfCanSaveSection($event)"
                    />
                </Tile>

                <template #bottom>
                    <ButtonGroup>
                        <BaseButton
                            :disabled="isPending() || !canSaveSubpage"
                            primary
                            data-test="shipment-add-button"
                            @click="saveSection()"
                        >
                            {{ submitButtonLabel }}
                        </BaseButton>
                    </ButtonGroup>
                </template>
            </Flyout>
        </template>
    </LayoutPage>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import { mapMutations, mapState } from 'vuex';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import { CONTEXT_PLATFORM } from '@/constants/context';
import routeContext from '@/plugins/mixins/routeContext';
import PriceAdjustmentView from '@/pages/Checkout/components/PriceAdjustment/models/PriceAdjustmentView';
import MaterialPricingView from '@/pages/Checkout/components/PriceAdjustment/models/MaterialPricingView';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import { getFormattedDuration } from '@/services/utils/date';
import CustomOrder from '@/services/Api/CustomOrder';
import ProjectApi from '@/services/Api/Project';
import ProjectPositionApi from '@/services/Api/ProjectPosition';
import Toaster from '@/services/Toaster';
import ShippingPricingView from '@/pages/Checkout/components/PriceAdjustment/models/ShippingPricingView';
import CustomPositionPricingItemView from '@/pages/Checkout/components/PriceAdjustment/models/CustomPositionPricingItemView';
import CustomPositionPricingSetView from '@/pages/Checkout/components/PriceAdjustment/models/CustomPositionPricingSetView';
import TransportPricingCollectionView from '@/pages/Checkout/components/PriceAdjustment/models/TransportPricingCollectionView';
import {
    TRANSPORT_FREIGHT_TYPE_WASTE_DANGEROUS,
    TRANSPORT_FREIGHT_TYPE_WASTE_NON_DANGEROUS,
} from '@/constants/transportFreightTypes';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import CustomPositionEditSection from '@/pages/Checkout/components/PriceAdjustment/CustomPositionEditSection';
import DisposalCostSection from '@/pages/Checkout/components/PriceAdjustment/DisposalCostSection';
import Flyout from '@/components/Layout/Flyout';
import FlyoutHeader from './components/Header';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Hint from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import ProjectCustomPositionsSection from '@/pages/Checkout/components/PriceAdjustment/ProjectCustomPositionsSection';
import SlideUp from '@/components/Animation/SlideUp';
import Tile from '@/components/Layout/Tile';
import TotalsSection from '@/pages/Checkout/components/PriceAdjustment/TotalsSection';
import TransportEditSection from '@/pages/Checkout/components/PriceAdjustment/TransportEditSection';
import TransportSection from '@/pages/Checkout/components/PriceAdjustment/TransportSection';
import { PaymentTermsContainer } from '@/pages/Checkout/PaymentTerms/PaymentTermsContainer';
import ReactBridge from '@/reactBridge/ReactBridge';

import CloseIcon from '@/assets/icons/regular/close.svg';
import { navigationFailure } from '@/services/utils/router';
import HorizontalDivider from '@/_components/HorizontalDivider/HorizontalDivider';
import { usePaymentTerms } from '@/pages/Checkout/PaymentTerms/usePaymentTerms';

export default {
    name: 'CustomPriceAdjustmentsPageShipment',
    components: {
        HorizontalDivider,
        BaseButton,
        ButtonGroup,
        CustomPositionEditSection,
        DisposalCostSection,
        Flyout,
        FlyoutHeader,
        HeaderBar,
        HeaderBarItem,
        Hint,
        LayoutPage,
        LoadingSpinner,
        ProjectCustomPositionsSection,
        SlideUp,
        Tile,
        TotalsSection,
        ReactBridge,

        TransportEditSection,
        TransportSection,

        CloseIcon,
    },
    mixins: [statefulMixin, routeContext],
    props: {
        projectPositionId: {
            type: [Number, String],
            default: null,
        },
    },
    setup() {
        const { getPaymentTerms, preparePaymentTermDataForStore, paymentTermsFromApi } = usePaymentTerms();
        return {
            PaymentTermsContainer,
            getPaymentTerms,
            preparePaymentTermDataForStore,
            paymentTermsFromApi,
        };
    },
    data() {
        return {
            section: null,
            sectionData: {},
            pricesData: null,
            projectPosition: null,
            calculationData: null,
            canSaveSection: false,
        };
    },
    computed: {
        ...mapState('basket', {
            constructionSite: 'constructionSite',
            isCustom: 'isCustom',
            product: 'product',
            projectId: 'projectId',
            qty: 'qty',
            quote: 'quote',
            shipmentBillingDetails: 'shipmentBillingDetails',
            shipmentLoading: 'shipmentLoading',
            shipmentQtyAndDocument: 'shipmentQtyAndDocument',
            shipmentUnloading: 'shipmentUnloading',
            supplierInfo: 'supplierInfo',
            type: 'type',
            loadedPricesData: 'pricesData',
            disposalPrice: 'disposalPrice',
            carrierInfo: 'carrierInfo',
            paymentTermsFromStore: 'paymentTerms',
            defaultPaymentTerms: 'defaultPaymentTerms',
        }),

        isPlatform() {
            return this.isCustom || this.inRouteContext(CONTEXT_PLATFORM);
        },

        current() {
            return this.pricesData?.current;
        },

        original() {
            return this.pricesData?.original;
        },

        hasCustomPositions() {
            return this.pricesData?.current?.customPositions?.collection.length > 0;
        },

        transportPriceUnit() {
            if (!this.shipmentBillingDetails) return 'ton';
            const { billingMethod } = this.shipmentBillingDetails;
            return BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_SHIPMENT === this.basketType &&
                billingMethod === 'fixedPrice'
                ? 'transport'
                : 'ton';
        },

        isProject() {
            return (
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_SHIPMENT === this.basketType || this.isProjectPositionEditMode
            );
        },

        isProjectPositionEditMode() {
            return !!this.projectPositionId;
        },

        hasValidData() {
            if (this.isProject) {
                return this.pricesData?.current?.enabledTransports?.length > 0;
            }

            return true;
        },

        basketType() {
            return this.isProjectPositionEditMode ? BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_SHIPMENT : this.type;
        },

        canSaveSubpage() {
            /* currently only the customPosition subpage is validated */
            if (this.section === 'customPosition') {
                return this.canSaveSection;
            }

            return true;
        },

        hasDisposalCost() {
            const isShipment = this.basketType === 'project-shipment';
            const hasWasteFreight = [
                TRANSPORT_FREIGHT_TYPE_WASTE_NON_DANGEROUS,
                TRANSPORT_FREIGHT_TYPE_WASTE_DANGEROUS,
            ].includes(this.shipmentQtyAndDocument?.type);
            return this.isCustom && isShipment && hasWasteFreight && !this.isProjectPositionEditMode;
        },

        submitButtonLabel() {
            return this.isProject
                ? this.$t('components.pagination.next')
                : this.$t('pages.checkout.priceAdjustments.submitData');
        },
    },
    created() {
        this.refreshPriceData();
    },
    methods: {
        ...mapMutations('basket', ['setDisposalPrice']),

        setDefaultPaymentTerms(terms) {
            this.$store.commit('basket/SET_DEFAULT_PAYMENT_TERMS', terms);
        },

        handlePaymentTermsChange(paymentTerm) {
            const paymentTermsForStore = this.preparePaymentTermDataForStore(paymentTerm, this.paymentTermsFromStore);
            this.$store.commit('basket/SET_PAYMENT_TERMS', paymentTermsForStore);
        },

        getFormattedDuration,
        refreshPriceData() {
            if (!this.isPlatform) return;
            if (this.isProjectPositionEditMode) {
                this.refreshPriceDataForEditAction();
            } else if (this.isProject) {
                this.refreshPriceDataDuringCheckout();
            }
        },

        async refreshPriceDataForEditAction() {
            this.projectPosition = await ProjectPositionApi.getOneById(this.projectPositionId);

            this.calculationData = await ProjectApi.calculateShipmentPosition(
                this.projectPosition?.project?.id,
                this.projectPosition?.loadingLocation,
                this.projectPosition?.unloadingLocation
            );

            if (this.projectPosition.paymentTerms.length === 0 && !!this.projectPosition.carrier) {
                this.paymentTermsFromApi = [
                    {
                        organizationId: this.projectPosition.carrier.id,
                        organizationType: 'carrier',
                        paymentTermId: 1,
                    },
                ];
            } else {
                this.paymentTermsFromApi = this.projectPosition.paymentTerms;
            }

            this.$store.commit('basket/SET_PAYMENT_TERMS', this.paymentTermsFromApi);
            this.setDefaultPaymentTerms(this.paymentTermsFromApi);
            this.pricesData = PriceAdjustmentView.createFromProjectShipmentPositionPayload(
                this.projectPosition,
                this.calculationData
            );
        },

        async refreshPriceDataDuringCheckout() {
            const original = await ProjectApi.calculateShipmentPosition(
                this.projectId,
                this.shipmentLoading.location,
                this.shipmentUnloading.location
            );

            const current = this.loadedPricesData || original;

            this.pricesData = PriceAdjustmentView.createFromProjectShipmentPositionBasket(
                parseInt(this.qty),
                this.shipmentQtyAndDocument?.description,
                original,
                current
            );
        },

        async saveData(data) {
            const response = await CustomOrder.save({
                id: this.quote.id,
                ...data,
            });
            this.pricesData = PriceAdjustmentView.createFromCustomOrder(this.quote, response);
            return response;
        },

        async savePriceData() {
            if (!this.isPlatform || this.isProject) return;

            await this.stateful('save', async () => {
                try {
                    await this.saveData(this.current.unfoldToCustomOrder(this.shipmentBillingDetails?.billingMethod));
                } catch (err) {
                    Toaster.error(err);
                }
            });
        },

        async resetPriceData() {
            if (!this.isPlatform) return;

            if (this.isProject) {
                this.pricesData.current = this.pricesData.original.clone();
                this.$store.commit('basket/RESET_PAYMENT_TERMS');
                return;
            }

            await this.stateful('save', async () => {
                try {
                    await this.saveData(
                        this.pricesData.original.unfoldToCustomOrder(this.shipmentBillingDetails?.billingMethod)
                    );
                    Toaster.info(this.$t('pages.checkout.priceAdjustments.resetted'));
                } catch (err) {
                    Toaster.error(err);
                }
            });
        },

        closeSection() {
            this.canSaveSection = false;
            this.section = null;
        },

        editSection(section) {
            const sectionView = this.current[section];

            if (
                sectionView instanceof MaterialPricingView ||
                sectionView instanceof ShippingPricingView ||
                sectionView instanceof TransportPricingCollectionView
            ) {
                this.sectionData = sectionView.clone();
            } else {
                // Whatever this is, we probably do not support it...
                this.$logger().error(`"${section}" is currently not supported`);
                return;
            }

            this.section = section;
        },

        async saveSection() {
            if (this.section === 'customPosition') {
                const position = this.sectionData;
                if (position.positionIndex !== null) {
                    this.$set(
                        this.current.customPositions.get(position.vehicleClassId).positions,
                        position.positionIndex,
                        position
                    );
                } else {
                    this.current.customPositions.get(position.vehicleClassId).addPosition(position);
                }
            } else {
                if ('transport' === this.section) {
                    this.initializeNewCustomPositions(this.sectionData);
                }
                this.$set(this.current, this.section, this.sectionData);
            }

            await this.savePriceData();
            this.closeSection();
        },

        initializeNewCustomPositions(editedTransportPricingCollectionView) {
            const enabledVehicleClasses = editedTransportPricingCollectionView.enabled;
            this.pricesData.current.customPositions = CustomPositionPricingSetView.createFromEnabledVehicleClasses(
                enabledVehicleClasses,
                this.current.customPositions
            );
        },

        editCustomPosition({ cid, position }) {
            const pos = position.clone();
            pos.positionIndex = cid;
            this.sectionData = pos;
            this.section = 'customPosition';
        },

        removeCustomPosition({ vid, cid }) {
            const positions = this.current.customPositions.get(vid);
            positions.removePosition(cid);
        },

        addCustomPosition({ vid, count, vehiclePayload }) {
            const position = new CustomPositionPricingItemView();
            position.vehicleClassId = vid;
            position.transportDistance = this.pricesData.distance;
            position.vehiclePayload = vehiclePayload;
            position.count = count;

            this.sectionData = position;
            this.section = 'customPosition';
        },

        getCustomPositionsPositionsLength(vid) {
            return this.current?.customPositions?.[vid]?.positions?.length;
        },

        submit() {
            if (!this.hasValidData) return;

            //count is removed from the payload to the api as its not supported by the api anymore
            const pricesData = this.current.unfold();
            const customPositionsSingleChildObj = Object.keys(pricesData.customPositions)?.[0];
            if (customPositionsSingleChildObj) {
                pricesData.customPositions[customPositionsSingleChildObj].positions.forEach(cp => delete cp.count);
            }

            this.$store.commit('basket/setPricesData', pricesData);

            this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(navigationFailure);
        },

        async saveProjectPosition() {
            if (!this.hasValidData) return;
            await this.stateful('saveProjectPosition', async () => {
                const projectPosition = _cloneDeep(this.projectPosition);
                const vehicleClasses = [];

                this.current.transport.enabled.forEach(transportPricing => {
                    const vc = transportPricing.unfold();

                    vehicleClasses.push({
                        purchasePrice: vc.purchaseUnitPrice,
                        retailPrice: vc.retailUnitPrice,
                        vehicleClass: {
                            id: vc.id,
                        },
                    });
                });
                projectPosition.vehicleClasses = vehicleClasses;
                projectPosition.paymentTerms = this.paymentTermsFromStore;

                const vehicleClassId = this.pricesData?.current?.customPositions?.collection[0]?.vehicleClassId;

                if (vehicleClassId !== null) {
                    const customPositions = this.pricesData?.current?.customPositions?.unfoldToCustomOrder();
                    projectPosition.customPositions = customPositions[vehicleClassId].positions;
                }

                try {
                    await ProjectPositionApi.save(projectPosition);
                    Toaster.success(
                        this.$t('pages.checkout.priceAdjustments.savePricePositionSuccessMessage', {
                            number: projectPosition.number,
                        })
                    );
                    this.$emit('projectPositionSaved');
                } catch (err) {
                    Toaster.error(err);
                }
            });
        },

        closeFlyout() {
            if (!this.isProjectPositionEditMode) return;
            this.$emit('close');
        },

        checkIfCanSaveSection({ isValid }) {
            this.canSaveSection = isValid;
        },
    },
};
</script>

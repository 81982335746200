<template>
    <section class="shipping-section">
        <header class="mb-8">
            <h3 class="font-copy-md-strong">
                {{ $t('pages.checkout.priceAdjustments.shipping.headline') }}
            </h3>
        </header>

        <BaseButton
            class="card card--hovered card--focussed font-copy-md-strong mb-6 flex w-full items-center justify-center p-6"
            @click="$emit('edit')"
        >
            {{ $t('pages.checkout.priceAdjustments.shipping.changeLabel') }}
        </BaseButton>

        <div class="card">
            <ul class="font-copy-md m-0 list-none p-0">
                <li>
                    <PriceRow
                        :label="$t('general.purchasePrice.abbreviation')"
                        :price-per-weight="shipping.purchasePriceTotal"
                        :price-per-transport="shipping.purchaseUnitPrice"
                        :show-unit-per-weight="false"
                        mute-fixed-price-highlights
                        reverse-prices
                        show-both-prices
                    />
                </li>
                <li>
                    <PriceRow
                        class="mt-2"
                        :label="$t('general.retailPrice.abbreviation')"
                        :price-per-weight="shipping.retailPriceTotal"
                        :price-per-transport="shipping.retailUnitPrice"
                        :show-unit-per-weight="false"
                        mute-fixed-price-highlights
                        reverse-prices
                        show-both-prices
                    />
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
import ShippingPricingView from '@/pages/Checkout/components/PriceAdjustment/models/ShippingPricingView';

import BaseButton from '@/components/Button/Button';
import PriceRow from '@/pages/Checkout/Disposal/Components/PriceRow';

export default {
    name: 'ShippingSection',
    components: {
        BaseButton,
        PriceRow,
    },
    props: {
        shipping: {
            type: ShippingPricingView,
            required: true,
        },
        showTotals: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss"></style>

export let AnalyticsService = {
    setup: () => console.warn('setup not implemented'),
    refreshUserProperties: () => console.warn('refreshUserProperties not implemented'),
    trackPageView: () => console.warn('trackPageView not implemented'),
    trackEvent: async () => console.warn('trackEvent not implemented'),
    trackClick: () => console.warn('trackClick not implemented'),
};
export function setAnalyticsService(service) {
    AnalyticsService = service;
}

<template>
    <div class="truck-placeholder-thumbnail">
        <TruckPlaceholderImage class="carrier-information__thumbnail" />
        <span class="truck-placeholder-thumbnail__text">{{ $t('pages.vehicle.list.noThumbnailLabel') }}</span>
    </div>
</template>

<script>
import TruckPlaceholderImage from '@/assets/icons/regular/truck-placeholder.svg';

export default {
    name: 'TruckPlaceholderThumbnail',
    components: {
        TruckPlaceholderImage,
    },
};
</script>

<style lang="scss" scoped>
.truck-placeholder-thumbnail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 77px;
    height: 77px;
    background-color: #f2f2f2;
}

.truck-placeholder-thumbnail__text {
    font-size: 7px;
    font-weight: bold;
    color: #ccc;
    text-align: center;
    margin-top: 3px;
}
</style>

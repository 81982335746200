<template>
    <Flyout v-if="isActionAvailable" :active="isActive" size="small" no-header @closed="closeFlyout()">
        <component
            :is="priceAdjustmentsComponent"
            :project-position-id="subject.id"
            @projectPositionSaved="handleProjectPositionSaved()"
            @close="closeFlyout()"
        />
    </Flyout>
</template>

<script>
import ProjectPositionView from '@/models/ProjectPositionView';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { ACTIONS } from '@/constants/actions';
import { EVENT_PROJECT_POSITION_UPDATED } from '@/constants/events';
import {
    PROJECT_POSITION_TYPE_DELIVERY,
    PROJECT_POSITION_TYPE_SHIPMENT,
    PROJECT_POSITION_TYPE_WASTE,
    PROJECT_POSITION_TYPE_DANGEROUS_WASTE,
} from '@/constants/projectPositionTypes';
import { mapGetters } from 'vuex';

import CustomPriceAdjustmentsPageMaterial from '@/pages/Checkout/CustomPriceAdjustmentsPageMaterial';
import Flyout from '@/components/Layout/Flyout';
import CustomPriceAdjustmentsPageShipment from '@/pages/Checkout/CustomPriceAdjustmentsPageShipment.vue';
import CustomPriceAdjustmentsPageDisposal from '@/pages/Checkout/Disposal/ProjectPosition/CustomPriceAdjustmentsPageDisposal';

export default {
    name: 'ProjectPositionPricesChangeAction',
    components: {
        CustomPriceAdjustmentsPageMaterial,
        Flyout,
        CustomPriceAdjustmentsPageShipment,
        CustomPriceAdjustmentsPageDisposal,
    },
    mixins: [eventHubMixin],
    computed: {
        ...mapGetters('globalActions', ['subject', 'action']),

        isActive() {
            return this.action === ACTIONS.PROJECT_POSITION_PRICES_CHANGE;
        },

        isActionAvailable() {
            return this.subject instanceof ProjectPositionView;
        },

        /**
         * Get price adjustment component depending on project position type
         * @return {string|null}
         */
        priceAdjustmentsComponent() {
            if (this.subject?.type === PROJECT_POSITION_TYPE_DELIVERY) {
                return CustomPriceAdjustmentsPageMaterial.name;
            }

            if (this.subject?.type === PROJECT_POSITION_TYPE_SHIPMENT) {
                return CustomPriceAdjustmentsPageShipment.name;
            }

            if ([PROJECT_POSITION_TYPE_WASTE, PROJECT_POSITION_TYPE_DANGEROUS_WASTE].includes(this.subject?.type)) {
                return CustomPriceAdjustmentsPageDisposal.name;
            }

            return null;
        },
    },
    methods: {
        closeFlyout() {
            this.$store.dispatch('globalActions/reset', ACTIONS.PROJECT_POSITION_PRICES_CHANGE);
        },
        handleProjectPositionSaved() {
            this.$eventHub.$emit(EVENT_PROJECT_POSITION_UPDATED, this.subject);
            this.closeFlyout();
        },
    },
};
</script>

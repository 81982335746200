<template>
    <div class="layer-controls">
        <div :id="eidBackdrop" class="layer-controls__backdrop" @click.stop="close" />
        <Button :id="eidToggle" class="layer-controls__trigger" orb @click.stop="open">
            <LayerIcon width="24" />
        </Button>
        <div
            :id="eidPane"
            :class="{
                'layer-controls__panel--open': isOpen,
            }"
            class="layer-controls__panel"
        >
            <div class="layer-controls__content">
                <template v-if="type !== null">
                    <Headline :level="6" block bold class="layer-controls__headline">
                        {{ $t('components.map.layerControls.mapTypeHeadline') }}
                    </Headline>
                    <div
                        v-for="(imgPath, t) in mapTypes"
                        :key="`map-type-${t}`"
                        :class="{
                            'layer-controls__tile--active': activeMapType === t,
                            [`layer-controls__tile--${t}`]: true,
                        }"
                        class="layer-controls__tile"
                        @click.stop="updateMapType(t)"
                    >
                        <div :style="`background-image: url('${imgPath}')`" class="layer-controls__tile-icon" />
                        <div>{{ $t(`components.map.layerControls.mapTypes.${t}`) }}</div>
                    </div>
                </template>

                <template v-if="mode !== null">
                    <Headline :level="6" block bold class="layer-controls__headline">
                        {{ $t('components.map.layerControls.modeHeadline') }}
                    </Headline>
                    <div
                        v-for="(imgPath, m) in modes"
                        :key="`mode-${m}`"
                        :class="{
                            'layer-controls__tile--active': activeMode === m,
                            [`layer-controls__tile--${m}`]: true,
                        }"
                        class="layer-controls__tile"
                        @click.stop="updateMode(m)"
                    >
                        <div :style="`background-image: url('${imgPath}')`" class="layer-controls__tile-icon" />
                        <div>{{ $t(`components.map.layerControls.modes.${m}`) }}</div>
                    </div>
                </template>
            </div>
            <Button class="layer-controls__close-button" @click.stop="close">
                <CloseLayerIcon width="14" height="14" />
            </Button>
        </div>
    </div>
</template>

<script>
import GoogleMaps from '@/services/GoogleMaps';
import gsap from 'gsap';

import Button from '@/components/Button/Button';
import Headline from '@/components/Typography/Headline';

import LayerIcon from '@/assets/icons/regular/layer.svg';
import CloseLayerIcon from '@/assets/icons/regular/close.svg';

import RoadmapPath from '@/assets/icons/map/standard-map.svg?external';
import SatellitePath from '@/assets/icons/map/satellite-map.svg?external';
import TrafficPath from '@/assets/icons/map/traffic-map.svg?external';

const mapTypes = {
    roadmap: RoadmapPath,
    satellite: SatellitePath,
};

const modes = {
    default: RoadmapPath,
    traffic: TrafficPath,
};

export default {
    name: 'LayerControls',
    components: {
        LayerIcon,
        CloseLayerIcon,
        Button,
        Headline,
    },
    props: {
        type: {
            type: [String, Boolean],
            default: null,
        },
        mode: {
            type: [String, Boolean],
            default: null,
        },
        map: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            eid: `el${this._uid}`,
            eidToggle: `el${this._uid}-toggle`,
            eidPane: `el${this._uid}-pane`,
            eidBackdrop: `el${this._uid}-backdrop`,
            isOpen: false,
            isOpenTimeout: null,
            activeMapType: this.type ? this.type : 'roadmap',
            mapTypes,
            activeMode: this.mode ? this.mode : 'default',
            modes,
            trafficLayer: null,
        };
    },
    watch: {
        type(value) {
            this.activeMapType = value;
        },
        mode(value) {
            this.activeMode = value;
        },
        map() {
            this.updateMapType(this.activeMapType);
            this.updateMode(this.activeMode);
        },
    },
    methods: {
        open() {
            this.isOpen = true;
            const $pane = document.getElementById(this.eidPane);

            gsap.to($pane, {
                duration: 0.3,
                scaleX: 1,
                scaleY: 1,
                opacity: 1,
                backgroundColor: '#fff',
            });

            gsap.to($pane.children, {
                duration: 0.1,
                opacity: 1,
            });
            document.getElementById(this.eidBackdrop).style.display = 'block';
        },

        close() {
            this.isOpen = false;
            const $toggle = document.getElementById(this.eidToggle);
            const $pane = document.getElementById(this.eidPane);

            $toggle.style.transition = 'none';
            gsap.fromTo(
                $toggle,
                {
                    scaleX: 0.5,
                    scaleY: 0.5,
                },
                {
                    duration: 0.3,
                    scaleX: 1,
                    scaleY: 1,
                    onComplete() {
                        $toggle.style.transform = '';
                        $toggle.style.transition = '';
                    },
                }
            );

            gsap.to($pane, {
                duration: 0.3,
                scaleX: 0.7,
                scaleY: 0.7,
                opacity: 0,
                backgroundColor: 'transparent',
            });

            gsap.to($pane.children, {
                duration: 0.1,
                opacity: 0,
            });
            document.getElementById(this.eidBackdrop).style.display = 'none';
        },

        updateMapType(value) {
            if (this.map !== null) {
                this.map.setMapTypeId(value);

                if (this.activeMode === 'traffic') {
                    this.updateDetailMode('traffic');
                }
            }

            if (this.activeMapType !== value) {
                this.activeMapType = value;
                this.$emit('update-type', value);
            }
        },

        updateMode(value) {
            this.updateDetailMode(value);

            if (this.activeMode !== value) {
                this.activeMode = value;
                this.$emit('update-mode', value);
            }
        },

        async updateDetailMode(mode) {
            if (this.map === null) return;

            const google = await GoogleMaps;

            if (mode === 'traffic') {
                this.trafficLayer = this.trafficLayer ? this.trafficLayer : new google.maps.TrafficLayer();
                this.trafficLayer.setMap(this.map);

                // satellite doesn't show trafic layer
                if (this.activeMapType === 'satellite') {
                    this.map.setMapTypeId('hybrid');
                }
            } else {
                this.trafficLayer && this.trafficLayer.setMap(null);
                if (this.activeMapType === 'satellite') {
                    this.map.setMapTypeId('satellite');
                }
            }
        },
    },
};
</script>

<style lang="scss">
.layer-controls__backdrop {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: none;
}

.layer-controls__backdrop--open {
    display: block;
}

.layer-controls__trigger {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 50px;
    height: 50px;
    transition: transform 0.2s ease-out;
    transform: scale(1);
    box-shadow: $boxShadow-bottomShort;
    will-change: transform;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;

    &:active {
        transform: scale(0.7);
    }

    &.layer-controls__trigger--prevent-transition {
        transition: none;
    }
}

.layer-controls__panel {
    overflow: hidden;
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: 0;
    will-change: opacity, transform;
    // transition: opacity 0.3s linear, transform 0.2s ease-out;
    pointer-events: none;
    padding: 20px;
    box-shadow: $boxShadow-bottomShort;
    background-color: #fff;
    transform: scale(0.7);
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
}

.layer-controls__panel--open {
    pointer-events: all;
}

.layer-controls__close-button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px 20px 10px 10px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
}

.layer-controls__content {
    display: grid;
    grid-template-columns: 80px 80px;
    grid-column-gap: 20px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;

    > .layer-controls__headline:first-child {
        margin-top: 0;
    }
}

.layer-controls__headline {
    grid-column: 1/-1;
}

.layer-controls__tiles {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.layer-controls__tile {
    cursor: pointer;
    font-size: 12px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
}

.layer-controls__tile-icon {
    display: block;
    box-sizing: border-box;
    border: 3px solid lightgrey;
    width: 100%;
    height: 0;
    padding-top: 100%;
    background-color: lightgrey;
    margin-bottom: 5px;
    background-size: cover;
    background-repeat: no-repeat;
}

.layer-controls__tile--active {
    color: $color-red;

    .layer-controls__tile-icon {
        border-color: $color-red;
    }
}
</style>

import { useTranslation } from 'react-i18next';
import { Tag } from '@schuettflix/react-components';
import { calculateSummaryVales } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/utils/calculateSummaryVales';
import { formatCurrency, formatPercentage } from '@/services/utils';
import { usePlatformInfo } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/hooks/usePlatformInfo';
function TotalCost({ title, value, type }) {
    return (<div className="flex flex-col items-end gap-2 md:items-start">
            <div className="font-copy-sm text-right md:text-left">{title}</div>
            <div>
                <Tag label={value} subdued type={type} className="font-numbers-md-strong text-right md:text-left"/>
            </div>
        </div>);
}
function getTagType(value, reverseFlag = false) {
    if (value === null || value === undefined) {
        return 'default';
    }
    const normalizedValue = reverseFlag ? -value : value;
    if (normalizedValue > 0)
        return 'positive';
    if (normalizedValue < 0)
        return 'error';
    return 'default';
}
export function SummarySection({ organizations }) {
    const { t } = useTranslation();
    const { data: platformInfo } = usePlatformInfo();
    const platformCurrencyCode = platformInfo?.platform.market.currencyCode;
    const summaryValues = calculateSummaryVales(organizations?.flatMap(({ totals }) => totals) || []);
    return (<div className="border-t border-t-active py-6">
            <div className="flex flex-col items-end gap-6 md:flex-row md:justify-end md:gap-8">
                <TotalCost title={t('pages.genericPosition.revenue')} value={formatCurrency(summaryValues.revenue, platformCurrencyCode)} type={getTagType(summaryValues.revenue)}/>
                <TotalCost title={t('pages.genericPosition.cost')} value={formatCurrency(summaryValues.costs, platformCurrencyCode)} type={getTagType(summaryValues.costs, true)}/>
                <TotalCost title={t('pages.genericPosition.operatingProfit')} value={formatCurrency(summaryValues.profit, platformCurrencyCode)} type={getTagType(summaryValues.profit)}/>
                <TotalCost title={t('pages.genericPosition.indicativeTakeRate')} value={formatPercentage(summaryValues.takeRate)} type={getTagType(summaryValues.takeRate)}/>
            </div>
        </div>);
}

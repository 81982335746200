import { GenericPositionHTTPClient } from '@/modules/constructionProjects/services/GenericPositionHTTPClient';
export const inactivateGenericPositionById = async (positionId) => {
    return GenericPositionHTTPClient.patch(`/v1/position/${positionId}/inactivate`);
};
export function inactivateGenericPositionByIdKey(positionId) {
    return ['generic-positions', positionId, 'inactivate'];
}
export function inactivateGenericPositionByIdMutation(positionId) {
    return {
        mutationKey: inactivateGenericPositionByIdKey(positionId),
        mutationFn: () => inactivateGenericPositionById(positionId),
    };
}

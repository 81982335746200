<template>
    <div class="shipment-billing-details-tile">
        <span class="font-copy-sm-strong mb-6">
            {{ $t('pages.checkout.summary.billingMethod.title') }}
        </span>
        <span class="font-copy-md">
            {{ $t(`pages.checkout.shipmentBillingDetails.billingMethodLabel.${shipmentBillingDetails.billingMethod}`) }}
        </span>
        <template v-if="shipmentBillingDetails.billingMethod === 'weight'">
            <SfInfoWithContext
                v-if="weightTitle.length"
                class="mt-6"
                size="sm"
                :context="$t('pages.checkout.summary.billingMethod.weightHeadline')"
            >
                <span class="font-copy-md mb-1">
                    {{ weightTitle }}
                </span>
            </SfInfoWithContext>

            <template
                v-if="shipmentBillingDetails.weighingAtLoadingPoint && shipmentBillingDetails.weighingAtUnloadingPoint"
            >
                <span class="font-copy-sm-strong text-brand-primary">
                    {{ $t('pages.checkout.summary.billingMethod.weightHint') }}
                </span>
                <span class="font-copy-md inline">
                    {{ $t('pages.checkout.summary.billingMethod.weightRelevant') }}
                </span>
                <span class="font-copy-md inline">
                    {{
                        shipmentBillingDetails.weighingBillingBasis === 'loading'
                            ? $t('pages.checkout.shipmentBillingDetails.weighingAtLoadingPointLabel')
                            : $t('pages.checkout.shipmentBillingDetails.weighingAtUnloadingPointLabel')
                    }}
                </span>
            </template>
        </template>
    </div>
</template>

<script>
import { SfInfoWithContext } from '@schuettflix/vue-components';

export default {
    name: 'ShipmentBillingDetailsTile',
    components: { SfInfoWithContext },
    props: {
        shipmentBillingDetails: {
            type: [Object],
            required: true,
        },
    },
    computed: {
        weightTitle() {
            if (
                this.shipmentBillingDetails.weighingAtLoadingPoint &&
                this.shipmentBillingDetails.weighingAtUnloadingPoint
            ) {
                return (
                    this.$t(`pages.checkout.shipmentBillingDetails.weighingAtLoadingPointLabel`) +
                    ', ' +
                    this.$t(`pages.checkout.shipmentBillingDetails.weighingAtUnloadingPointLabel`)
                );
            } else {
                if (this.shipmentBillingDetails.weighingAtLoadingPoint) {
                    return this.$t(`pages.checkout.shipmentBillingDetails.weighingAtLoadingPointLabel`);
                } else {
                    return this.$t(`pages.checkout.shipmentBillingDetails.weighingAtUnloadingPointLabel`);
                }
            }
        },
    },
};
</script>

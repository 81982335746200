import { COST_CENTER_TYPE, COST_CENTER_SOURCE } from '@/constants/costCenterTypes';
import { locationsEqual } from '@/services/utils/map';

export default class CostCenterViewModel {
    constructor() {
        this._costCenters = [];
    }

    /**
     * @param {object|null} data
     */
    static create(data) {
        const viewModel = new CostCenterViewModel();

        if (data?.costCenters instanceof Array) {
            viewModel._costCenters = [...data.costCenters];
        }

        return viewModel;
    }

    /**
     * @param {SummaryView} summaryViewModel
     * @param {object|null} project
     */
    static createFromSummaryViewModelAndProject(summaryViewModel, project) {
        const constructionSiteCostCenter = CostCenterViewModel.extractConstructionSiteCostCenter(summaryViewModel);
        const loadingAndUnloadingSiteCostCenters =
            CostCenterViewModel.extractLoadingAndUnloadingSiteCostCenters(summaryViewModel);
        const projectCostCenter = CostCenterViewModel.extractProjectCostCenter(project);
        const costCenters = [
            ...constructionSiteCostCenter,
            ...loadingAndUnloadingSiteCostCenters,
            ...projectCostCenter,
        ];

        return CostCenterViewModel.create({ costCenters, project });
    }

    /**
     * @param {SummaryView} summaryViewModel
     * @return {object[]}
     */
    static extractConstructionSiteCostCenter(summaryViewModel) {
        const costCenters = [];

        if (summaryViewModel?.constructionSite?.costcenter) {
            const constructionSite = summaryViewModel.constructionSite;
            const constructionSiteLocation = constructionSite.location;

            let source = COST_CENTER_SOURCE.UNKNOWN;
            if (
                summaryViewModel.loadingSiteLocation &&
                constructionSiteLocation &&
                locationsEqual(summaryViewModel.loadingSiteLocation, constructionSiteLocation)
            ) {
                source = COST_CENTER_SOURCE.LOADING_SITE;
            } else if (
                summaryViewModel.unloadingSiteLocation &&
                constructionSiteLocation &&
                locationsEqual(summaryViewModel.unloadingSiteLocation, constructionSiteLocation)
            ) {
                source = COST_CENTER_SOURCE.UNLOADING_SITE;
            }
            costCenters.push({
                costCenter: constructionSite.costcenter,
                costCenterTypes: [{ type: COST_CENTER_TYPE.CONSTRUCTION_SITE, source: source }],
            });
        }

        return costCenters;
    }

    /**
     * @param {SummaryView} summaryViewModel
     * @return {object[]}
     */
    static extractLoadingAndUnloadingSiteCostCenters(summaryViewModel) {
        const costCenters = [];
        if (summaryViewModel?.loadingAddressCostCenter) {
            const type =
                COST_CENTER_TYPE.CONSTRUCTION_SITE === summaryViewModel.loadingAddressType
                    ? COST_CENTER_TYPE.CONSTRUCTION_SITE
                    : COST_CENTER_TYPE.UNKNOWN;
            costCenters.push({
                costCenter: summaryViewModel.loadingAddressCostCenter,
                costCenterTypes: [{ type: type, source: COST_CENTER_SOURCE.LOADING_SITE }],
            });
        }
        if (summaryViewModel?.unloadingAddressCostCenter) {
            const type =
                COST_CENTER_TYPE.CONSTRUCTION_SITE === summaryViewModel.unloadingAddressType
                    ? COST_CENTER_TYPE.CONSTRUCTION_SITE
                    : COST_CENTER_TYPE.UNKNOWN;
            costCenters.push({
                costCenter: summaryViewModel.unloadingAddressCostCenter,
                costCenterTypes: [{ type: type, source: COST_CENTER_SOURCE.UNLOADING_SITE }],
            });
        }
        return costCenters;
    }

    /**
     * @param {ProjectView} project
     * @return {object[]}
     */
    static extractProjectCostCenter(project) {
        const costCenters = [];

        if (project) {
            costCenters.push({
                costCenter: project?.costCenter,
                costCenterTypes: [{ type: COST_CENTER_TYPE.PROJECT, source: COST_CENTER_SOURCE.UNKNOWN }],
            });
        }

        return costCenters;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            costCenters: Array.from(this.costCenters),
        };
    }

    /**
     * Clone current instance
     * @return {CostCenterViewModel}
     */
    clone() {
        return CostCenterViewModel.create(this.unfold());
    }

    /**
     * @return {object[]} cost centers
     */
    get costCenters() {
        return this._costCenters;
    }
}

import OrganizationView from '@/models/OrganizationView';
import ImageView from '@/models/ImageView';
import PhoneNumberView from '@/models/PhoneNumberView';

/**
 * Class representing user view model
 */
export default class UserView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     */
    static create(data) {
        if (!data) return null;

        const user = new UserView();

        user.id = data.id;
        user.email = data.email;
        user.firstName = data.firstName;
        user.lastName = data.lastName;
        user.username = data.username;
        user.mobile = PhoneNumberView.create(data.mobile);
        user.organization = OrganizationView.create(data.organization);
        user.image = ImageView.create(data.image);

        return user;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._id = null;
        this._email = null;
        this._firstName = null;
        this._image = null;
        this._lastName = null;
        this._mobile = null;
        this._organization = null;
        this._username = null;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            id: this.id,
            email: this.email,
            firstName: this.firstName,
            image: this.image,
            lastName: this.lastName,
            mobile: this.mobile?.unfold(),
            organization: this.organization?.unfold(),
            username: this.username,
        };
    }

    /**
     * Clone the current view
     * @return {UserView}
     */
    clone() {
        return UserView.create(this.unfold());
    }

    /**
     * Get id
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * Set id
     * @param {number} value
     */
    set id(value) {
        this._id = value ?? null;
    }

    /**
     * Get email
     * @returns {string}
     */
    get email() {
        return this._email;
    }

    /**
     * Set email
     * @param {string} value
     */
    set email(value) {
        this._email = value ?? null;
    }

    /**
     * Get firstName
     * @returns {string}
     */
    get firstName() {
        return this._firstName;
    }

    /**
     * Set firstName
     * @param {string} value
     */
    set firstName(value) {
        this._firstName = value ?? null;
    }

    /**
     * Get firstName
     * @returns {ImageView}
     */
    get image() {
        return this._image;
    }

    /**
     * Set image
     * @param {ImageView} value
     */
    set image(value) {
        this._image = value ?? null;
    }

    /**
     * Get lastName
     * @returns {string}
     */
    get lastName() {
        return this._lastName;
    }

    /**
     * Set lastName
     * @param {string} value
     */
    set lastName(value) {
        this._lastName = value ?? null;
    }

    /**
     * Get mobile
     * @returns {PhoneNumberView}
     */
    get mobile() {
        return this._mobile;
    }

    /**
     * Set mobile
     * @param {PhoneNumberView} value
     */
    set mobile(value) {
        this._mobile = value ?? null;
    }

    /**
     * Get organization
     * @returns {OrganizationView}
     */
    get organization() {
        return this._organization;
    }

    /**
     * Set organization
     * @param {OrganizationView} value
     */
    set organization(value) {
        this._organization = value ?? null;
    }

    /**
     * Get username
     * @returns {string}
     */
    get username() {
        return this._username;
    }

    /**
     * Set username
     * @param {string} value
     */
    set username(value) {
        this._username = value ?? null;
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }
}

import AbstractFilterableResource from '@/services/Api/AbstractFilterableResource';

class Project extends AbstractFilterableResource {
    /**
     * Close Project
     *
     * @param {number} projectId
     * @returns {Promise<*>}
     */
    async close(projectId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${projectId}/close`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Get Project Position Calculation
     *
     * @param {string|number} projectId
     * @param {number} constructionSiteId
     * @param {number} productId
     * @param {number} factoryId
     * @returns {Promise<*>}
     */
    async calculateDeliveryPosition(projectId, constructionSiteId, productId, sellerProductId, factoryId) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${projectId}/calculate-delivery-position`,
                {
                    constructionSite: { id: constructionSiteId },
                    sellerProductId: sellerProductId,
                    product: { id: productId },
                    factory: { id: factoryId },
                }
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Get Project Position Calculation
     *
     * @param {string|number} projectId
     * @param {number} constructionSiteId
     * @param {number} factoryProductVariantId
     * @param {number|null} stateWasteCompanyId
     * @param {number|null} customPurchaseUnitPrice
     * @param {number|null} customRetailUnitPrice
     * @param {Object|null} enabledVehicleClassPrices
     * @param {Object|null} customPositions
     * @returns {Promise<*>}
     */
    async calculateDisposalPosition(
        projectId,
        constructionSiteId,
        factoryProductVariantId,
        stateWasteCompanyId = null,
        customPurchaseUnitPrice = null,
        customRetailUnitPrice = null,
        enabledVehicleClassPrices = null,
        customPositions = null,
        projectPositionQuantityInKg = null
    ) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${projectId}/calculate-waste-position`, {
                constructionSiteId: constructionSiteId,
                factoryProductVariantId,
                stateWasteCompanyId,
                customPurchaseUnitPrice,
                customRetailUnitPrice,
                enabledVehicleClassPrices,
                customPositions,
                projectPositionQuantityInKg,
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Get Project Position Calculation
     *
     * @param {string|number} projectId
     * @param {object} loadingLocation
     * @param {object} unloadingLocation
     * @returns {Promise<*>}
     */
    async calculateShipmentPosition(projectId, loadingLocation, unloadingLocation) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${projectId}/calculate-shipment-position`,
                {
                    loadingLocation: loadingLocation,
                    unloadingLocation: unloadingLocation,
                }
            );
            const calculationData = { freight: {}, transport: {} };
            if (response.data?.items instanceof Array) {
                response.data.items.forEach(item => (calculationData.transport[item.vehicleClassId] = item));
            }
            return calculationData;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Get Projects by Construction Project ID
     *
     * @param {string} constructionProjectId
     * @returns {Promise<*>}
     */
    async projectsByConstructionProjectId(constructionProjectId) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}`, {
                params: {
                    constructionProject: constructionProjectId,
                    page: 1,
                    perPage: 1,
                    sortBy: 'number',
                    sortDirection: 'desc',
                },
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async createGenericPosition(constructionProjectId) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${constructionProjectId}/create-generic-position`
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new Project('/project');

import md5 from 'blueimp-md5';
import store from '@/store';
import LogService from '@schuettflix/util-log';
import { iterateParse } from '@/services/utils';
import _omit from 'lodash/omit';
import { PushNotifications } from '@capacitor/push-notifications';
import { pushNotificationActionPerformedHandler } from './pushNotifcationActionPerformedHandler';

const Log = new LogService('services/Notification/NotificationService');

function dumpMessage(message) {
    return {
        title: message.title,
        message: message.message,
        data: message.data,
    };
}

function getHash(message) {
    return md5(`${message.id}-${JSON.stringify(dumpMessage(message))}`);
}

export function isValidMessage(message) {
    const neededKeys = ['data', 'title', 'body', 'type', 'id', 'hash'];
    let valid = true;

    neededKeys.forEach(key => {
        if (!Object.prototype.hasOwnProperty.call(message, key)) {
            valid = false;
        }
    });

    return valid;
}

function parseMessage(notification) {
    const message = {
        id: notification.id || Date.now().toString(),
        type: notification.data.messageKey || 'default',
        title: notification.title || '',
        body: notification.body || '',
        receiverUserId: notification.data.receiverUserId || '',
        data: _omit(notification.data, ['messageKey', 'receiverUserId']),
    };

    message.hash = getHash(message);

    return message;
}

function onPushNotificationReceived(notification) {
    // Fix for iOS, as message is not deeply parsed as JSON
    const parsedData = iterateParse(notification);
    const message = parseMessage(parsedData);

    Log.log('notification event:', dumpMessage(message));

    notify(message);
}

function notify(message) {
    if (!isValidMessage(message)) {
        throw new Error('Given object is not in supported format.');
    }

    store.commit('notification/addNotification', message);
}

export async function initializeNotifications() {
    const permissionStatus = await PushNotifications.requestPermissions();
    Log.info('Requesting permissions for push notifications', permissionStatus);

    switch (permissionStatus.receive) {
        case 'prompt':
        case 'prompt-with-rationale':
        case 'granted': {
            PushNotifications.addListener('registration', token => {
                Log.info('Registration for push notifications uses token', token.value);
                store.dispatch('notification/registerToken', token.value);
            });
            PushNotifications.addListener('registrationError', error => {
                Log.error('Error during push notification registration: ', error);
            });
            PushNotifications.addListener('pushNotificationReceived', notification => {
                Log.info('received', notification);
                onPushNotificationReceived(notification);
            });
            PushNotifications.addListener('pushNotificationActionPerformed', pushNotificationActionPerformedHandler);
            await PushNotifications.register();
            break;
        }
        case 'denied': {
            Log.info('user denied permission');
        }
    }
}

export * from './handleNotificationMessage';

<template>
    <div class="file-uploader">
        <slot :trigger-file-select="triggerFileSelect" />

        <input
            id="fileUpload"
            ref="fileUploader"
            type="file"
            class="needsclick file-uploader__hidden"
            :accept="acceptedFileTypes"
            @change="onFileChange"
        />
    </div>
</template>

<script>
import { watch, ref } from 'vue';
import { useDocumentUpload } from '@/services/useClientFile';
import { fileTypeString, isFileTypeAccepted } from '@/services/utils/file';

const DEFAULT_MAX_FILE_SIZE = 25;

export default {
    name: 'FileUploader',
    props: {
        documentTypeCode: {
            type: String,
            default: null,
        },
        productUseCase: {
            type: String,
            default: null,
        },
        fileSize: {
            type: Number,
            default: null,
        },
    },
    setup(props, { emit }) {
        const { uploadDocument, onEverySuccess, onEveryError, progress, uploadedDocumentData } = useDocumentUpload();

        const fileUploader = ref(null);

        onEverySuccess(data => {
            emit('input', data);
        });

        onEveryError(error => {
            emit('error', error);
        });

        watch(progress, value => emit('progress', value));

        const uploadFile = async file => {
            emit('uploadingStarted');
            await uploadDocument({
                file,
                meta: {
                    productUseCase: props.productUseCase,
                    documentTypeCode: props.documentTypeCode,
                },
            });
            emit('uploadingFinished');
            fileUploader.value = '';
        };

        return {
            uploadFile,
            progress,
            uploadedDocumentData,
        };
    },
    computed: {
        acceptedFileTypes() {
            return this.acceptedFileTypesAsArray.join(', ');
        },
        acceptedFileTypesAsArray() {
            return ['application/pdf'];
        },
    },
    methods: {
        onFileChange($event) {
            this.$emit('error', null);
            const files = $event.target.files;
            const file = (files.length > 0 && files[0]) || null;

            const maxFileSize = (this.fileSize || DEFAULT_MAX_FILE_SIZE) * 1024 * 1024;

            if (!file) {
                return;
            }

            if (!isFileTypeAccepted(this.acceptedFileTypes, file.type)) {
                this.$emit(
                    'error',
                    this.$t('components.imageDocumentUploader.errors.dataTypeNotSupported', {
                        fileTypeString: fileTypeString(this.acceptedFileTypesAsArray),
                    })
                );
                return;
            }

            if (file.size > maxFileSize) {
                this.$emit(
                    'error',
                    this.$t('components.imageUpload.limitExceeded', { size: this.fileSize || DEFAULT_MAX_FILE_SIZE })
                );
            } else {
                this.uploadFile(file);
            }
        },

        triggerFileSelect() {
            const clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false, // disable: fastclick's cancel
            });

            this.$refs.fileUploader.value = null;
            this.$refs.fileUploader.dispatchEvent(clickEvent);
        },
    },
};
</script>

<style lang="scss">
.file-uploader__hidden {
    display: none;
}
</style>

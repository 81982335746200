import { forwardRef } from 'react';
import clsx from 'clsx';
import { useIconSize } from '@/services/utils/useIconSize';
export const DirectionIcon = forwardRef(({ className, multicolor = false, size = 'xs', ...svgProps }, ref) => {
    const iconSize = useIconSize(size);
    return (<svg width={iconSize} height={iconSize} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" ref={ref} className={clsx('stroke-icon', className)} {...svgProps}>
                <g clipPath="url(#a)" strokeWidth={2} strokeMiterlimit={10}>
                    <path d="M11.118 11.986 11.122 21M11.108 2.002l.009 3.985" stroke="#35343D"/>
                    <path d="m19.617 8.988-3.5-3H3.618l1.5 3-1.5 3h12.5l3.499-3Z" className={multicolor ? 'stroke-icon-accent' : 'stroke-icon'}/>
                </g>
                <defs>
                    <clipPath id="a">
                        <path fill="#fff" transform="translate(2 2)" d="M0 0h19.153v19.002H0z"/>
                    </clipPath>
                </defs>
            </svg>);
});
DirectionIcon.displayName = 'DirectionIcon';

import React from 'react';
import { createLocation, createLocationAsAdmin, } from '@/constructionProjects/api/projects';
import { useTranslation } from 'react-i18next';
import { useMarketCountryCode } from '@/constructionProjects/hooks/useMarketCountryCode';
import { useMarketLanguage } from '@/constructionProjects/hooks/useMarketLanguage';
import { useMutation, useQuery } from '@tanstack/react-query';
import ConstructionSiteApi from '@/services/Api/ConstructionSite';
import { FormField } from '@/constructionProjects/components/FormField';
import { Button, TextField } from '@schuettflix/react-components';
import { Controller } from 'react-hook-form';
import { LocationPickerInput } from '@schuettflix/react-components';
import { TrafficabilitySelection } from '@/constructionProjects/components/TrafficabilitySelection';
import { DevTool } from '@hookform/devtools';
import { z } from 'zod';
import { queryClient } from '@/reactBridge/queryClient';
import Toaster from '@/services/Toaster';
import { constructionProjectKeys, constructionProjectByIdQuery, constructionProjectByIdAsAdminQuery, } from '@/constructionProjects/queries';
import { useZodForm } from '@/reactBridge/useZodForm';
import { InfoIcon } from '@schuettflix/icons-react';
import { GeolocationService } from '@/services/Geolocation/GeolocationService';
import LocationApi from '@/services/Api/Location';
import PlatformApi from '@/services/Api/Platform/Utils';
import useIsPlatformAdmin from '@/hooks/useIsPlatformAdmin';
const MAX_NAME_CHARACTERS = 130;
export const CreateLocation = ({ constructionProjectId, locationDraft, onCreate, onCancel, disableSubmit, }) => {
    const { t } = useTranslation();
    const country = useMarketCountryCode();
    const language = useMarketLanguage();
    const isPlatformAdmin = useIsPlatformAdmin();
    const [locationCode, setLocationCode] = React.useState(locationDraft?.openLocationCode ?? '');
    const schema = z.object({
        name: z
            .string()
            .min(1, t('pages.constructionProject.createLocation.errors.name.required'))
            .max(MAX_NAME_CHARACTERS, t('pages.constructionProject.createLocation.errors.name.maxLengthDynamic')),
        location: z.object({
            address: z.object({
                formatted: z.string().nullable(),
                country: z.string().nullable(),
                state: z.string().nullable(),
                city: z.string(),
                street: z.string().nullable(),
                streetNumber: z.string().nullable(),
                zipCode: z.string(),
                placeId: z.string().nullable(),
            }),
            coordinates: z.object({
                latitude: z.number().min(-90).max(90),
                longitude: z.number().min(-180).max(180),
            }),
        }),
        note: z.string().optional(),
        vehicleAccessibility: z.number({
            required_error: t('pages.constructionProject.createLocation.errors.maximumTrafficability.required'),
        }),
    });
    const { data: constructionProject } = useQuery({
        ...(isPlatformAdmin
            ? constructionProjectByIdAsAdminQuery(constructionProjectId)
            : constructionProjectByIdQuery(constructionProjectId)),
        suspense: true,
    });
    const { data: trafficabilityOptions } = useQuery(['trafficability-options'], () => ConstructionSiteApi.getTrafficabilityOptions());
    const { control, register, handleSubmit, setError, formState: { errors, isValid }, } = useZodForm({
        defaultValues: {
            location: {
                address: {
                    formatted: null,
                    country: constructionProject?.country || locationDraft?.country,
                    state: constructionProject?.state || locationDraft?.state,
                    city: constructionProject?.city || locationDraft?.city,
                    zipCode: constructionProject?.zip || locationDraft?.zip,
                    street: constructionProject?.street || locationDraft?.street,
                    streetNumber: constructionProject?.addressNumber || locationDraft?.addressNumber,
                    placeId: null,
                },
                coordinates: {
                    latitude: constructionProject?.geoCoordinate?.latitude || locationDraft?.latitude,
                    longitude: constructionProject?.geoCoordinate?.longitude || locationDraft?.longitude,
                },
            },
        },
        mode: 'onChange',
        schema,
    });
    const mapServerErrors = (errors) => {
        for (const [key, error] of Object.entries(errors)) {
            // @ts-expect-error These are backend keys and are untypable
            setError(key, {
                type: 'server',
                message: error.message,
            });
        }
    };
    const mutation = useMutation({
        mutationFn: (data) => isPlatformAdmin
            ? createLocationAsAdmin(data, constructionProjectId)
            : createLocation(data, constructionProjectId),
        onSuccess: async (response) => {
            onCreate(response);
            await queryClient.invalidateQueries(constructionProjectKeys.locations(constructionProjectId));
            Toaster.success(t('pages.constructionProject.createLocation.toaster.success'));
        },
        onError: (error) => {
            switch (error.response?.status) {
                case 400: {
                    const fieldErrors = error.response?.data?.fieldErrors;
                    mapServerErrors(fieldErrors);
                    break;
                }
                default: {
                    Toaster.error(t('pages.constructionProject.createLocation.toaster.unspecifiedServerError'));
                    break;
                }
            }
        },
    });
    const onSubmit = handleSubmit(data => {
        const formData = {
            name: data.name,
            vehicleAccessibility: data.vehicleAccessibility,
            note: data.note,
            latitude: data.location.coordinates.latitude,
            longitude: data.location.coordinates.longitude,
            city: data.location.address.city,
            zip: data.location.address.zipCode,
            street: data.location.address.street,
            addressNumber: data.location.address.streetNumber,
        };
        mutation.mutate(formData);
    });
    return (<form onSubmit={onSubmit} className="flex h-full flex-col justify-between" autoComplete="off">
            <div className="flex flex-col overflow-auto p-4">
                <FormField title={t('pages.constructionProject.createLocation.name.title')} description={t('pages.constructionProject.createLocation.name.description')}>
                    <TextField {...register('name')} className="mb-12" label={t('pages.constructionProject.createLocation.name.label')} data-test="create-location-name-field" errorMessage={errors.name?.message
            ? t(errors.name?.message, { maxCharacters: MAX_NAME_CHARACTERS })
            : undefined}/>
                </FormField>
                <FormField title={t('pages.constructionProject.createLocation.location.title')} description={t('pages.constructionProject.createLocation.location.description')} className="mb-4">
                    <Controller name="location" control={control} render={({ field }) => (<LocationPickerInput country={country} language={language} defaultMapType={google.maps.MapTypeId.SATELLITE} label={t('pages.constructionProject.createLocation.location.picker.label')} title={t('pages.constructionProject.createLocation.location.picker.title')} onLocationCodeChange={setLocationCode} translationMap={{
                addressFieldLabel: t('pages.constructionProject.createForm.addressField.label'),
                getCurrentPositionError: t('components.locationPicker.getCurrentPosition.error'),
                locationCodeLabel: t('components.locationPicker.locationCode.label'),
                mapTypeHeadline: t('components.locationPicker.mapType.headline'),
                mapTypeStandard: t('components.locationPicker.mapType.standard'),
                mapTypeSatellite: t('components.locationPicker.mapType.satellite'),
                setButtonLabel: t('pages.constructionProject.createForm.addressField.locationPicker.setButton.label'),
            }} triggerToasterError={Toaster.error.bind(Toaster)} getCurrentLocation={GeolocationService.getCurrentLocation.bind(GeolocationService)} getOpenLocationCode={LocationApi.getOpenLocationCode.bind(LocationApi)} getPlatformInfo={PlatformApi.getPlatformInfo.bind(PlatformApi)} queryClient={queryClient} {...field}/>)}/>
                    <TextField label={t('pages.constructionProject.createLocation.locationCode.label')} value={locationCode} disabled trailingIcon={props => <InfoIcon {...props} disabled={false}/>}/>
                </FormField>
                <TextField {...register('note')} label={t('pages.constructionProject.createLocation.importantInformation.label')} className="mb-12" data-test="create-location-note-field" trailingIcon={props => <InfoIcon {...props}/>}/>
                <FormField title={t('pages.constructionProject.createLocation.maximumTrafficability.title')} description={t('pages.constructionProject.createLocation.maximumTrafficability.description')}>
                    <Controller name="vehicleAccessibility" control={control} 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render={({ field: { ref, ...fieldProps } }) => {
            return <TrafficabilitySelection options={trafficabilityOptions} {...fieldProps}/>;
        }}/>
                </FormField>
                <p className="font-copy-sm mt-2 text-critical">{errors.vehicleAccessibility?.message}</p>
            </div>

            <hr className="border-divider"/>

            <div className="sticky bottom-0 flex w-full flex-row ">
                <Button className="flex-1" variant="secondary" label={t('pages.constructionProject.createLocation.cancelButton.label')} onClick={onCancel} type="button"/>

                <Button disabled={mutation.isLoading || disableSubmit || !isValid} className="flex-1" label={t('pages.constructionProject.createLocation.createButton.label')} type="submit" data-test="save-new-unloading-point"/>

                <DevTool control={control}/>
            </div>
        </form>);
};

<template>
    <div
        v-if="isOpen"
        :class="{
            'modal-actions--open': isVisuallyOpen,
        }"
        class="modal-actions"
    >
        <div
            class="modal-actions__box scroll-container"
            :class="{
                'modal-actions__box--inline': isInline,
            }"
        >
            <ButtonGroup vertical inline>
                <slot />
            </ButtonGroup>
        </div>
        <div class="modal-actions__fade" @click="handleOptionalExit" />
        <SfIconButton v-if="optionalDialog" size="md" class="modal-actions__close-button" @click="handleOptionalExit">
            <template #icon="iconProps"><SfSysCloseIcon v-bind="iconProps" /></template>
        </SfIconButton>
    </div>
</template>

<script>
import eventHubMixin from '@/plugins/mixins/eventHubMixin';

import ButtonGroup from '@/components/Button/ButtonGroup';

import { SfIconButton, SfSysCloseIcon } from '@schuettflix/vue-components';

const CLOSE_DELAY = 500;
const OPEN_DELAY = 100;

export default {
    name: 'ModalActions',
    components: {
        ButtonGroup,
        SfIconButton,
        SfSysCloseIcon,
    },
    mixins: [eventHubMixin],
    props: {
        optionalDialog: {
            type: Boolean,
            default: true,
        },
        eventIdentifier: {
            type: String,
            default: null,
        },
        isInline: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
            isVisuallyOpen: false,
            openTimeout: null,
            closeTimeout: null,
        };
    },
    created() {
        this.$on('open', this.openModal);
        this.$on('close', this.closeModal);

        if (this.eventIdentifier !== null) {
            this.subscribe(`${this.eventIdentifier}.open`, () => {
                this.openModal();
            });
            this.subscribe(`${this.eventIdentifier}.close`, () => {
                this.closeModal();
            });
        }
    },
    methods: {
        openModal() {
            this.closeTimeout && clearTimeout(this.closeTimeout);
            this.isOpen = true;

            this.openTimeout = setTimeout(() => {
                this.isVisuallyOpen = true;
            }, OPEN_DELAY);
            this.$emit('opened');
        },
        closeModal() {
            this.openTimeout && clearTimeout(this.openTimeout);
            this.isVisuallyOpen = false;

            this.closeTimeout = setTimeout(() => {
                this.isOpen = false;
            }, CLOSE_DELAY);

            this.$emit('closed');
        },
        handleOptionalExit() {
            if (this.optionalDialog) {
                this.closeModal();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-actions {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.modal-actions--open {
    .modal-actions__fade {
        opacity: 0.9;
    }

    .modal-actions__box {
        transform: translate(-50%, 0%);
    }
}

.modal-actions__fade {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.4s ease;
    background-color: $color-darkGrey;
}

.modal-actions__box {
    background-color: transparent;
    position: fixed;
    z-index: 1;
    width: 100vw;
    max-height: 90vh;
    bottom: var(--impersonation-bar-height);
    left: 50%;
    transform: translate(-50%, 120%);
    transition: transform 0.4s ease;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $layout-desktop-min) {
        max-width: 600px;
    }

    &--inline {
        bottom: 0;
    }
}

.modal-actions__close-button {
    position: absolute;
    top: 20px;
    right: 10px;
    padding: 10px;

    svg {
        .f-base {
            fill: $color-white;
        }

        .s-base {
            stroke: $color-white;
        }
    }
}
</style>

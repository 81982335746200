import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
if (process.env.NODE_ENV === 'production') {
    initializeFaro({
        url: 'https://faro-collector-prod-eu-west-0.grafana.net/collect/b1add0501519541f792347539e7975e3',
        app: {
            name: 'Schüttflix Frontend',
            version: process.env.VUE_APP_VERSION,
            environment: process.env.VUE_APP_ENVIRONMENT,
        },
        instrumentations: [
            // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
            ...getWebInstrumentations(),
        ],
    });
}

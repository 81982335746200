<template>
    <ChooseBillingTypePage
        :billing-method="billingMethod"
        :is-valid="hasValidData"
        :is-loading="isPending('submit')"
        @form-change="updateForm"
        @submit="submit"
    >
        <template #additionalContent="slotProps">
            <div class="mt-12">
                <p class="font-copy-md-strong mb-2">
                    {{ $t('pages.checkout.shipmentBillingDetails.weightHeadline') }}
                </p>
                <p class="font-copy-md mb-6">
                    {{ $t('pages.checkout.shipmentBillingDetails.weightDescription') }}
                </p>

                <CheckboxCard
                    v-model="weighingAtLoadingPoint"
                    :title="$t('pages.checkout.shipmentBillingDetails.weighingAtLoadingPointLabel')"
                    :is-disabled="slotProps.form.billingMethod !== 'weight'"
                    :is-small-checkbox="false"
                    class="mb-4"
                >
                    <FormattedAddress class="font-copy-sm mt-1 text-subdued" :address="shipmentLoading.address" />
                </CheckboxCard>
                <CheckboxCard
                    v-model="weighingAtUnloadingPoint"
                    :title="$t('pages.checkout.shipmentBillingDetails.weighingAtUnloadingPointLabel')"
                    :is-disabled="slotProps.form.billingMethod !== 'weight'"
                    :is-small-checkbox="false"
                >
                    <FormattedAddress class="font-copy-sm mt-1 text-subdued" :address="shipmentUnloading.address" />
                </CheckboxCard>
                <SfMessageBox
                    v-if="
                        weighingAtLoadingPoint && weighingAtUnloadingPoint && slotProps.form.billingMethod === 'weight'
                    "
                    class="mt-6"
                    :headline="$t('pages.checkout.shipmentBillingDetails.hintLabel')"
                    type="warning"
                >
                    {{ $t('pages.checkout.shipmentBillingDetails.hintMessage') }}

                    <div class="mt-4">
                        <RadioField v-model="weighingBillingBasis" class="mb-2" option="loading" dynamic-size small>
                            <div class="font-copy-md-strong">
                                {{ $t('pages.checkout.shipmentBillingDetails.weighingAtLoadingPointLabel') }}
                            </div>
                        </RadioField>

                        <RadioField v-model="weighingBillingBasis" option="unloading" dynamic-size small>
                            <div class="font-copy-md-strong">
                                {{ $t('pages.checkout.shipmentBillingDetails.weighingAtUnloadingPointLabel') }}
                            </div>
                        </RadioField>
                    </div>
                </SfMessageBox>
            </div>
        </template>
    </ChooseBillingTypePage>
</template>

<script>
import { mapState } from 'vuex';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import QuoteService from '@/services/QuoteService';

import CheckboxCard from '@/_components/CheckboxCard/CheckboxCard';
import FormattedAddress from '@/components/FormattedAddress';
import RadioField from '@/components/Form/RadioField';

import { trackCheckoutEvent } from './trackCheckoutEvent';
import ChooseBillingTypePage from '@/pages/Checkout/components/ProjectPosition/ChooseBillingTypePage';
import { SfMessageBox } from '@schuettflix/vue-components';
import { BILLING_METHODS } from '@/constants/billingTypes';

export default {
    name: 'ShipmentBillingDetailsPage',

    components: {
        CheckboxCard,
        FormattedAddress,
        RadioField,

        ChooseBillingTypePage,

        SfMessageBox,
    },

    mixins: [statefulMixin],

    data() {
        return {
            billingMethod: null,
            weighingBillingBasis: 'loading',
            weighingAtLoadingPoint: false,
            weighingAtUnloadingPoint: false,
        };
    },

    computed: {
        ...mapState('basket', ['shipmentBillingDetails', 'shipmentLoading', 'shipmentUnloading', 'isCustom']),

        hasValidData() {
            if (this.billingMethod === null) return false;

            if (this.billingMethod === 'weight') {
                return this.weighingAtLoadingPoint || this.weighingAtUnloadingPoint;
            }

            return true;
        },
    },

    created() {
        if (this.shipmentBillingDetails) {
            this.billingMethod = this.shipmentBillingDetails.billingMethod;
            this.weighingBillingBasis = this.shipmentBillingDetails.weighingBillingBasis;
            this.weighingAtLoadingPoint = this.shipmentBillingDetails.weighingAtLoadingPoint;
            this.weighingAtUnloadingPoint = this.shipmentBillingDetails.weighingAtUnloadingPoint;
        }
    },
    methods: {
        updateForm(form) {
            this.billingMethod = form.billingMethod;
            if (form.billingMethod === BILLING_METHODS.FIXED_PRICE) {
                this.weighingAtLoadingPoint = false;
                this.weighingAtUnloadingPoint = false;
            }
        },
        async submit() {
            if (!this.hasValidData) return;

            await this.stateful('submit', async () => {
                const payload = {
                    billingMethod: this.billingMethod,
                    weighingBillingBasis:
                        this.weighingAtLoadingPoint && this.weighingAtUnloadingPoint
                            ? this.weighingBillingBasis
                            : this.weighingAtLoadingPoint
                            ? 'loading'
                            : 'unloading',
                    weighingAtLoadingPoint: !!this.weighingAtLoadingPoint,
                    weighingAtUnloadingPoint: !!this.weighingAtUnloadingPoint,
                };
                this.$store.commit('basket/setShipmentBillingDetails', payload);

                if (!this.isCustom) {
                    trackCheckoutEvent('shipmentBilling', 'selectBillingMethod', payload);

                    try {
                        await QuoteService.saveQuote();
                    } catch (err) {
                        this.$store.commit('basket/setServerError', err);
                        return;
                    }
                }

                this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(() => {});
            });
        },
    },
};
</script>

<template>
    <div class="numpad-input">
        <Button
            v-for="key in [1, 2, 3, 4, 5, 6, 7, 8, 9]"
            :key="key"
            class="numpad-input__button"
            :data-test="`numpad-button-${key}`"
            orb
            levitate
            :disabled="keysDisabled"
            @click="keypress(String(key), true)"
        >
            <Words large>{{ key }}</Words>
        </Button>
        <Button
            v-if="precision > 0"
            class="numpad-input__button"
            data-test="numpad-button-decimal"
            orb
            levitate
            :disabled="keysDisabled || containsDecimal"
            @click="keypress('.', true)"
        >
            <Words large>,</Words>
        </Button>
        <span v-else />
        <Button
            class="numpad-input__button"
            data-test="numpad-button-0"
            orb
            levitate
            :disabled="keysDisabled"
            @click="keypress('0', true)"
        >
            <Words large>0</Words>
        </Button>
        <Button
            class="numpad-input__button"
            data-test="numpad-button-delete"
            orb
            levitate
            @click="keypress('Backspace', true)"
        >
            <DeleteIcon width="18" height="18" />
        </Button>
    </div>
</template>

<script>
import Button from '@/components/Button/Button';
import Words from '@/components/Typography/Words';

import DeleteIcon from '@/assets/icons/keyboard_delete.svg';
import { preciseFloor } from '@/services/utils';

export default {
    name: 'NumpadInput',
    components: {
        Button,
        Words,

        DeleteIcon,
    },
    props: {
        value: {
            type: String,
            default: '0',
        },
        precision: {
            type: Number,
            default: 1,
        },
        disabledKeys: {
            type: Boolean,
            default: false,
        },
        maxCharLength: {
            type: Number,
            default: 1000,
        },
    },
    data() {
        return {
            valueString: '0',
            keysDisabled: false,
            containsDecimal: false,
        };
    },
    watch: {
        value() {
            this.processValue();
        },
        disabledKeys() {
            this.processValue();
        },
    },
    created() {
        this.processValue();
    },
    mounted() {
        window.addEventListener('keyup', this.handleKeyUp);
        window.addEventListener('paste', this.handlePaste);
    },
    beforeDestroy() {
        window.removeEventListener('keyup', this.handleKeyUp);
        window.removeEventListener('paste', this.handlePaste);
    },
    methods: {
        handleKeyUp(event) {
            if (/Backspace$/.test(event.key)) {
                this.keypress(event.key);
            }
            if (event.key === 'Escape') {
                this.valueString = '';
                this.keypress(this.valueString);
            }
            if (!this.keysDisabled) {
                if (/^[0-9]$/.test(event.key)) {
                    this.keypress(event.key);
                }
                if (!this.containsDecimal && /^[,.]$/.test(event.key)) {
                    this.keypress('.');
                }
            }
        },
        handlePaste(event) {
            if (this.keysDisabled) {
                return;
            }
            event.stopPropagation();
            event.preventDefault();

            const clipboard = event.clipboardData || window.clipboardData;
            const pastedData = preciseFloor(parseFloat(clipboard.getData('Text').replace(/,/g, '.')), this.precision);

            if (isNaN(pastedData)) {
                return;
            }
            this.valueString = pastedData;
            this.keypress('');
        },
        processValue() {
            this.valueString = this.value;
            this.keysDisabled =
                this.disabledKeys ||
                (this.valueString.includes('.') && this.valueString.split('.')[1].length >= this.precision);
            this.containsDecimal = this.valueString.includes('.');
        },

        keypress(value, isNumberCounter = false) {
            if (!isNumberCounter) return;
            let newValue = this.valueString;

            if (value === 'Backspace') {
                newValue = this.valueString.substring(0, this.valueString.length - 1);
            } else {
                newValue += value;
            }

            newValue = newValue.replace(/^0+(?=\d)/, '');
            if (newValue.length <= 0) {
                newValue = '0';
            }

            this.keysDisabled = newValue.includes('.') && newValue.split('.')[1].length >= this.precision;
            this.containsDecimal = newValue.includes('.');

            if (newValue.length > this.maxCharLength) {
                return;
            }

            this.valueString = newValue;
            this.$emit('input', this.valueString);
        },
    },
};
</script>

<style lang="scss">
.numpad-input {
    display: grid;
    grid-template-columns: repeat(3, 58px);
    grid-column-gap: 46px;
    grid-auto-rows: 58px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

@media (max-width: 275px) {
    .numpad-input {
        grid-column-gap: 20px;
    }
}

.numpad-input__button {
    font-weight: 400;
    justify-self: center;
    width: 34px;
    height: 34px;
    line-height: 34px;
}
</style>

<template>
    <LayoutPage is-flyout :screen-name="screenName" class="checkout-shipment-loading-page">
        <Header slot="flyoutHeader" />

        <Tile transparent>
            <StepIndicator step="unloading" />
        </Tile>

        <Tile transparent>
            <ShipmentAddressPicker
                ref="addressPicker"
                :address="address"
                :location="location"
                :error="hasIdenticalLocationsError || addressFormError"
                type="unloading"
                @update-address="handleAddressUpdate($event)"
                @update-location="handleLocationUpdate($event)"
                @update-contact="handleContactPersonUpdate($event)"
                @error-message="addressFormError = $event"
            >
                <span slot="headline">{{ $t('pages.checkout.shipmentUnloading.address.title') }}</span>
                <span slot="subline">{{ $t('pages.checkout.shipmentUnloading.address.description') }}</span>
                <span slot="lastUsedAddressHeadline">
                    {{ $t('pages.checkout.shipmentUnloading.address.lastUsedHeadline') }}
                </span>
            </ShipmentAddressPicker>
        </Tile>

        <template v-if="showInfoStep">
            <Tile transparent>
                <ShipmentContact
                    v-model="contact"
                    :headline="$t('pages.checkout.shipmentUnloading.contact.title')"
                    :description="$t('pages.checkout.shipmentUnloading.contact.description')"
                    :modal-headline="$t('pages.checkout.shipmentUnloading.contact.modalTitle')"
                    :modal-description="$t('pages.checkout.shipmentUnloading.contact.modalDescription')"
                />
            </Tile>

            <Tile transparent>
                <ShipmentDescription
                    v-model="description"
                    :headline="$t('pages.checkout.shipmentUnloading.additionalInformation.hintHeader')"
                    :message="$t('pages.checkout.shipmentUnloading.additionalInformation.hintMessage')"
                />
            </Tile>

            <Tile transparent>
                <ShipmentLocation
                    v-model="loadingLocation"
                    :default-location="location"
                    :headline="$t('pages.checkout.shipmentUnloading.additionalInformation.selectLocation')"
                />
            </Tile>

            <Tile transparent>
                <ShipmentImageUpload
                    v-model="photo"
                    :headline="$t('pages.checkout.shipmentUnloading.additionalInformation.selectPhotoHeader')"
                />
            </Tile>
        </template>

        <SlideUp slot="sticky" :active="hasValidData">
            <ButtonGroup>
                <Button primary data-test="button-save-select-jobs" @click="handleSubmission">
                    {{ nextStepActionLabel }}
                    <ArrowRightIcon slot="right" class="icon--inline" />
                </Button>
            </ButtonGroup>
        </SlideUp>
    </LayoutPage>
</template>

<script>
import { retrieveZipFromLocation } from '@/services/utils/map';
import { mapState } from 'vuex';
import GoogleMaps from '@/services/GoogleMaps';
import { preciseFloat } from '@/services/utils';

import LayoutPage from '@/components/Layout/Page.v2';
import Header from './components/Header';
import Tile from '@/components/Layout/Tile';
import ShipmentAddressPicker from './components/ShipmentAddressPicker';
import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ShipmentContact from './components/ShipmentContact';
import ShipmentLocation from './components/ShipmentLocation';
import ShipmentImageUpload from './components/ShipmentImageUpload';
import ShipmentDescription from './components/ShipmentDescription';
import SlideUp from '@/components/Animation/SlideUp';
import StepIndicator from './components/StepIndicator';

import ArrowRightIcon from '@/assets/icons/micro/arrow.svg';
import LocationApi from '@/services/Api/Location';
import { trackCheckoutEvent } from './trackCheckoutEvent';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';

export default {
    name: 'ShipmentUnloadingPage',
    components: {
        LayoutPage,
        Header,
        Tile,
        Button,
        ButtonGroup,
        ShipmentAddressPicker,
        ShipmentContact,
        ShipmentLocation,
        ShipmentImageUpload,
        ShipmentDescription,
        SlideUp,
        StepIndicator,

        ArrowRightIcon,
    },
    setup() {
        return {
            screenName: useOrderScreenName('unloading'),
        };
    },
    data() {
        return {
            isPending: false,
            address: null,
            location: null,
            addressFormError: null,

            photo: null,
            description: null,
            loadingLocation: null,
            contact: null,

            loadingImportantInformation: null,
            costCenter: null,
            unloadingAddressConstructionSiteId: null,
        };
    },
    computed: {
        ...mapState('basket', ['shipmentLoading', 'isCustom']),

        hasValidData() {
            return !!(
                this.address &&
                this.address.zip &&
                this.location &&
                !this.hasIdenticalLocationsError &&
                !this.addressFormError
            );
        },

        hasIdenticalLocationsError() {
            if (this.shipmentLoading.location?.openLocationCode === this.location?.openLocationCode) {
                return this.$t('pages.checkout.shipmentUnloading.errors.identicalLocations');
            }
            return null;
        },

        showInfoStep() {
            return !!(this.address && this.location);
        },

        nextStepActionLabel() {
            const { nextLabel } = this.$route.meta;
            const defaultLabel = 'pages.checkout.shipmentLoading.actions.nextTransports';

            return this.$t(nextLabel || defaultLabel);
        },
    },
    created() {
        const savedUnloading = this.$store.state.basket.shipmentUnloading;

        if (savedUnloading) {
            Object.keys(savedUnloading).forEach(key => {
                this.$set(this, key, savedUnloading[key]);
            });
        }
    },
    methods: {
        async handleLocationUpdate(value) {
            this.location = value;
            try {
                const openLocationCode = await LocationApi.getOpenLocationCode(value);
                if (openLocationCode) {
                    this.$set(this.location, 'openLocationCode', openLocationCode);
                }

                if (this.hasIdenticalLocationsError) return;

                await this.trackDistance();
            } catch (err) {
                this.$logger().error(err);
            }
        },

        async handleAddressUpdate(value) {
            if (value && !value.zip) {
                value.zip = await retrieveZipFromLocation(this.location);
            }

            this.address = value;
        },

        handleContactPersonUpdate(person) {
            if (!person) {
                this.contact = null;
                return;
            }

            const contact = {};

            if (person.name || person.firstName || person.lastName) {
                contact.name = person.name ? person.name : `${person.firstName} ${person.lastName}`;
            }

            if (person.phone && person.phone.countryCode && person.phone.number) {
                contact.phone = person.phone;
            }

            if (person.mobile && person.mobile.countryCode && person.mobile.number) {
                contact.phone = person.mobile;
            }

            // Valid contact has name & phone
            this.contact = Object.keys(contact).length == 2 ? contact : null;
        },

        handleSubmission() {
            if (!this.hasValidData) return;
            const { importantInformation, costCenter, ...address } = this.address;

            if (this.address.type === 'constructionSite') {
                this.unloadingAddressConstructionSiteId = this.address.id;
            }

            this.$store.commit('basket/setShipmentUnloading', {
                address,
                location: this.location,
                loadingLocation: this.loadingLocation,
                loadingImportantInformation: importantInformation,
                description: this.description,
                photo: this.photo,
                contact: this.contact,
                unloadingAddressConstructionSiteId: this.unloadingAddressConstructionSiteId,
                costCenter,
            });

            this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(err => {
                this.$logger().error(err);
            });
        },

        async trackDistance() {
            if (this.isCustom || !this.location || !this.shipmentLoading?.location) return;

            const google = await GoogleMaps;
            const distance = google.maps.geometry.spherical.computeDistanceBetween(
                new google.maps.LatLng(this.location),
                new google.maps.LatLng(this.shipmentLoading.location)
            );

            trackCheckoutEvent('shipmentUnloading', 'calculatedDistance', {
                distance: preciseFloat(distance / 1000),
            });
        },
    },
};
</script>

import { ref } from 'vue';
import { uploadImage, uploadDocument } from '@schuettflix/client-file';
export const STATES = {
    NONE: 'none',
    SAVING: 'saving',
    SUCCESS: 'success',
    ERROR: 'error',
};
const createUploadComposable = (uploadMethod) => {
    const state = ref(STATES.NONE);
    const progress = ref(0);
    const uploadedData = ref(undefined);
    let _onSuccess = undefined;
    let _onError = undefined;
    const upload = async ({ file, meta }) => {
        if (state.value === STATES.SAVING) {
            return;
        }
        state.value = STATES.SAVING;
        try {
            const { data } = (await uploadMethod({
                file,
                onUploadProgressPercent(p) {
                    progress.value = p;
                },
                meta: meta,
            }));
            // This is no type error, but TS has difficulties to handle this
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            uploadedData.value = data;
            state.value = STATES.SUCCESS;
            if (_onSuccess) {
                _onSuccess(data);
            }
        }
        catch (error) {
            state.value = STATES.ERROR;
            if (_onError) {
                _onError(error);
            }
        }
        finally {
            progress.value = 0;
            setTimeout(() => {
                state.value = STATES.NONE;
            }, 1500);
        }
        return uploadedData.value;
    };
    const onEverySuccess = (callback) => {
        _onSuccess = callback;
    };
    const onEveryError = (callback) => {
        _onError = callback;
    };
    return {
        state,
        progress,
        uploadedData,
        onEverySuccess,
        onEveryError,
        upload,
    };
};
export const useDocumentUpload = () => {
    const { state, progress, uploadedData, onEverySuccess, onEveryError, upload } = createUploadComposable(uploadDocument);
    return {
        state,
        progress,
        uploadedDocumentData: uploadedData,
        onEverySuccess,
        onEveryError,
        uploadDocument: upload,
    };
};
export const useImageUpload = () => {
    const { state, progress, uploadedData, onEverySuccess, onEveryError, upload } = createUploadComposable(uploadImage);
    return {
        state,
        progress,
        uploadedImageData: uploadedData,
        onEverySuccess,
        onEveryError,
        uploadImage: upload,
    };
};

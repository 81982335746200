<template>
    <BaseButton
        :disabled="isPending"
        :class="{
            'current-location-control--loading': isPending,
        }"
        orb
        middle
        class="current-location-control"
        @click="findCurrentLocation"
    >
        <CurrentLocationIcon width="24" height="24" class="icon--middle" />
    </BaseButton>
</template>

<script>
import BaseButton from '@/components/Button/Button';

import CurrentLocationIcon from '@/assets/icons/regular/marker.svg';
import Toaster from '@/services/Toaster';
import { GeolocationService } from '@/services/Geolocation/GeolocationService';

export default {
    name: 'CurrentLocationControl',
    components: {
        BaseButton,

        CurrentLocationIcon,
    },
    props: {
        map: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isPending: false,
        };
    },
    methods: {
        async findCurrentLocation() {
            this.setPendingState(true);
            try {
                this.updateLocation(await GeolocationService.getCurrentLocation());
            } catch (error) {
                Toaster.error(this.$t('components.locationPicker.getCurrentPosition.error'));
                this.$logger().error(error);
            } finally {
                this.setPendingState(false);
            }
        },

        setPendingState(isPending) {
            this.isPending = isPending;
            this.$emit('isPending', isPending);
        },

        updateLocation(location) {
            if (this.map) {
                this.map.setZoom(16);
                this.map.setCenter(location);
            }
            this.$emit('input', location);
        },
    },
};
</script>

<style lang="scss" scoped>
.current-location-control {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 50px;
    height: 50px;
    transition: transform 0.2s ease-out;
    transform: scale(1);
    box-shadow: $boxShadow-bottomShort;
    will-change: transform;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;

    &:active {
        transform: scale(0.7);
    }
}

@keyframes spinning {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.current-location-control--loading {
    animation: spinning 1s linear infinite;
}
</style>

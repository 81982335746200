<template>
    <div class="flex w-full justify-between p-6">
        <QuantityInfoWithContextBlock
            class="md:whitespace-nowrap"
            :title="$t(orderedQtyString)"
            :quantity="total"
            :is-shipment="isShipment"
        />
        <QuantityInfoWithContextBlock
            class="ml-12 whitespace-nowrap"
            :title="$t(deliveredQtyString)"
            :quantity="delivered"
            :is-shipment="isShipment"
        />
        <SfProgressBar size="sm" class="mb-2 ml-4 !items-end" :primary="progress" />
        <QuantityInfoWithContextBlock
            class="ml-4 md:whitespace-nowrap"
            :title="$t(remainingQtyString)"
            :quantity="remaining"
            :is-shipment="isShipment"
        />
    </div>
</template>

<script>
import { SfProgressBar } from '@schuettflix/vue-components';
import QuantityInfoWithContextBlock from '@/pages/Order/components/QuantityInfoWithContextBlock';

export default {
    name: 'QuantityInfo',
    components: {
        QuantityInfoWithContextBlock,
        SfProgressBar,
    },
    props: {
        total: {
            type: Number,
            default: null,
        },
        delivered: {
            type: Number,
            default: null,
        },
        orderType: {
            type: String,
            default: 'delivery',
            validator: v => ['delivery', 'pickup', 'shipment', 'waste', 'dangerous-waste'].includes(v),
        },
    },
    computed: {
        remaining() {
            if ([this.total, this.delivered].includes(null)) return null;

            return Math.max(0, this.total - this.delivered);
        },

        progress() {
            if ([this.total, this.delivered].includes(null)) return 0;

            return Math.min(Math.round((100 * this.delivered) / this.total), 100);
        },

        small() {
            return !this.$root.isDesktop;
        },

        isShipment() {
            return this.orderType === 'shipment';
        },

        orderedQtyString() {
            return {
                'dangerous-waste': 'pages.order.orderTransports.quantityInfo.ordered.dangerous-waste',
                waste: 'pages.order.orderTransports.quantityInfo.ordered.waste',
                delivery: 'pages.order.orderTransports.quantityInfo.ordered.delivery',
                pickup: 'pages.order.orderTransports.quantityInfo.ordered.pickup',
                shipment: 'pages.order.orderTransports.quantityInfo.ordered.shipment',
            }[this.orderType];
        },

        deliveredQtyString() {
            return {
                'dangerous-waste': 'pages.order.orderTransports.quantityInfo.delivered.dangerous-waste',
                waste: 'pages.order.orderTransports.quantityInfo.delivered.waste',
                delivery: 'pages.order.orderTransports.quantityInfo.delivered.delivery',
                pickup: 'pages.order.orderTransports.quantityInfo.delivered.pickup',
                shipment: 'pages.order.orderTransports.quantityInfo.delivered.shipment',
            }[this.orderType];
        },

        remainingQtyString() {
            return {
                'dangerous-waste': 'pages.order.orderTransports.quantityInfo.remaining.dangerous-waste',
                waste: 'pages.order.orderTransports.quantityInfo.remaining.waste',
                delivery: 'pages.order.orderTransports.quantityInfo.remaining.delivery',
                pickup: 'pages.order.orderTransports.quantityInfo.remaining.pickup',
                shipment: 'pages.order.orderTransports.quantityInfo.remaining.shipment',
            }[this.orderType];
        },
    },
};
</script>

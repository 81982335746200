<template>
    <div>
        <Card key="waste-table" :class="['waste-table']" :shadowless="true" spaceless no-padding>
            <div class="waste-table__head">
                <Words tiny bold class="pl-4">{{ $t('pages.products.productsList.tableHeadings.wasteCode') }}</Words>
                <Words tiny bold class="pl-[12px]">{{ $t('pages.products.productsList.tableHeadings.name') }}</Words>
                <Words tiny bold class="pr-4">{{ $t('pages.products.productsList.tableHeadings.availability') }}</Words>
            </div>
        </Card>
        <div v-if="products && products.length > 0" class="waste-groups-iteration">
            <!-- Waste Groups -->
            <Accordion
                v-for="category in categories"
                ref="accordion"
                :key="category.id"
                :has-content="category.hasContent"
                :class="['waste-groups', { 'waste-groups--empty': !category.hasContent }]"
            >
                <!-- Waste Group Code -->
                <template #preheader>
                    <Words
                        block
                        bold
                        white
                        class="with-separator"
                        :class="{
                            'with-separator--hidden': !category.wasteCode,
                        }"
                    >
                        {{ category.wasteCode }}
                    </Words>
                </template>

                <!-- Waste Group Name -->
                <template #header>
                    <Words bold white>
                        {{ revertLocalizedValue(category.name) }}
                    </Words>
                    <Words v-if="!category.isProductsLoading" bold red> ({{ category.counter }}) </Words>
                </template>

                <!--
                    Waste Group Items Accordion
                -->
                <Accordion
                    v-for="(product, i) in products[category.id]"
                    :key="`waste-item-${i}`"
                    :open-on-init="false"
                    class="accordion-waste-subcategory"
                    type="secondary"
                    @accordion-click="
                        params =>
                            fetchWasteVariants(
                                !product.isVariantsDisposalLoading,
                                category.id,
                                product.id,
                                params.isOpen
                            )
                    "
                    @accordion-update="
                        params =>
                            fetchWasteVariants(
                                !product.isVariantsDisposalLoading,
                                category.id,
                                product.id,
                                params.isOpen
                            )
                    "
                >
                    <!-- Waste Code -->
                    <template #preheader>
                        <WasteCode block bold :code="product.wasteCode" :is-dangerous="product.isDangerous" />
                    </template>

                    <!-- Waste Code Name -->
                    <template #header>
                        <div class="header-wrap">
                            <Words>{{ revertLocalizedValue(product.productName) }}</Words>
                            <Words
                                v-if="priceInformationExists(returnPriceInformation(product))"
                                bold
                                unbreakable
                                class="price-range-header"
                            >
                                {{ returnPriceInformation(product) }}
                            </Words>
                            <Words v-else unbreakable class="price-range-header">
                                {{ $t('pages.products.productsList.supplier.noPrice') }}
                            </Words>
                        </div>
                    </template>

                    <!-- Waste Code State -->
                    <template #postheader>
                        <div
                            class="dot-indicator"
                            :class="{
                                'dot-indicator--available': product.factoryData.isAvailable,
                                'dot-indicator--not-available': !product.factoryData.isAvailable,
                            }"
                        />
                    </template>

                    <!-- Waste Item Content -->
                    <div class="accordion-subcategory-body">
                        <SupplierWasteProductDetails
                            :category-id="category.id"
                            :factory-id="factoryId"
                            :product="product"
                        />
                    </div>
                </Accordion>
            </Accordion>
        </div>
        <Hint v-else center>
            {{ $t('pages.products.productsList.noResults') }}
        </Hint>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import _isNumber from 'lodash/isNumber';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import _isString from 'lodash/isString';

import { revertLocalizedValue } from '@/services/utils/localization';

import Accordion from '@/_components/Accordion/Accordion';
import Card from '@/components/Layout/Card';
import SupplierWasteProductDetails from '@/pages/SupplierProducts/components/SupplierWasteProductDetails/SupplierWasteProductDetails';
import WasteCode from '@/pages/ProductManagement/components/WasteCode.vue';
import Words from '@/components/Typography/Words';
import Hint from '@/components/Typography/Hint';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';

export default {
    name: 'DisposalTypeContent',
    components: {
        Accordion,
        Card,
        SupplierWasteProductDetails,
        WasteCode,
        Words,
        Hint,
    },

    mixins: [persistentFiltersMixin],

    props: {
        filters: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            isInternalPending: [],
            defaultFilter: {
                page: 1,
                perPage: 500,
            },
        };
    },

    computed: {
        ...mapState('waste', ['isLoading']),

        ...mapGetters('waste', ['getProductsOrderedByCategory', 'getCategories', 'getFactoryId']),

        categories() {
            return this.getCategories;
        },

        factoryId() {
            return this.getFactoryId;
        },

        products() {
            return this.getProductsOrderedByCategory;
        },
    },
    watch: {
        filters: {
            deep: true,
            handler(newFilter, oldFilter) {
                // update products related to set filters
                if (!_isEqual(newFilter, oldFilter)) this.fetchProducts();
            },
        },
        factoryId: {
            handler(newValue, oldValue) {
                if (newValue && newValue !== oldValue) {
                    this.refreshProductsAndCategories();
                }
            },
        },
    },
    mounted() {
        this.refreshProductsAndCategories();
    },

    methods: {
        revertLocalizedValue,
        _isNumber,

        priceInformationExists(priceInfo) {
            return _isString(priceInfo);
        },

        returnPriceInformation(product) {
            const { factoryData } = product;
            if (!factoryData.hasFactoryAnyAvailableProductVariant) return '';

            if (
                !Object.prototype.hasOwnProperty.call(factoryData.pricePerTonRange, 'from') &&
                !Object.prototype.hasOwnProperty.call(factoryData.pricePerTonRange, 'to')
            )
                return false;
            return this.returnPriceRange(factoryData);
        },
        returnPriceRange(factoryData) {
            const pricePerTonRange = factoryData.pricePerTonRange;
            let separator = '';
            let from = '';
            let to = '';
            if (pricePerTonRange.from >= 0) {
                from = this.$n(pricePerTonRange.from, 'currency');
            }

            if (pricePerTonRange.to >= 0) {
                to = this.$n(pricePerTonRange.to, 'currency');
                separator = ' – ';
            }

            if (from === to || from === '') {
                from = '';
                separator = '';
            }

            return `${from}${separator}${to}`;
        },

        fetchWasteVariants(loaded, categoryId, productId, isOpen) {
            if (loaded || !isOpen) return;

            if (!this.isInternalPending[`${productId}_pollution-types`]) {
                this.isInternalPending[`${productId}_pollution-types`] = true;
                this.fetchPollutionTypes(categoryId, productId);
            }

            if (!this.isInternalPending[`${productId}_product-variants`]) {
                this.isInternalPending[`${productId}_product-variants`] = true;
                this.fetchProductVariants(categoryId, productId);
            }
        },

        async refreshProductsAndCategories() {
            if (!this.getFactoryId) return;
            await this.fetchCategories();
            await this.fetchProducts();
        },

        async fetchCategories() {
            await this.$store.dispatch('waste/fetchCategories');
        },

        async fetchProducts() {
            if (!this.categories.length) return;

            let filters = _cloneDeep(this.filters);
            delete filters.factory;
            delete filters.type;
            filters = { ...filters, sortBy: 'wasteCode', sortDirection: 'asc' };

            this.persistFilter('product', filters, this.defaultFilter);

            await this.$store.dispatch('waste/fetchFactoryWasteProducts', filters);
        },

        async fetchProductVariants(categoryId, productId) {
            await this.$store.dispatch('waste/fetchProductVariants', { categoryId, productId, filters: this.filters });
            this.isInternalPending[`${productId}_pollution-types`] = false;
        },

        async fetchPollutionTypes(categoryId, productId) {
            await this.$store.dispatch('waste/fetchPollutionTypes', { filters: this.filters, categoryId, productId });
            this.isInternalPending[`${productId}_product-variants`] = false;
        },
    },
};
</script>

<style lang="scss" scoped src="./DisposalTypeContent.scss" />

import { GoogleMap } from '@react-google-maps/api';
import mapStyles from '@/config/mapStyles';
import React, { useEffect, useState } from 'react';
import PinIconSmallPath from '@/assets/icons/pin--38px.svg?external';
import clsx from 'clsx';
export const Map = ({ markers = [], className }) => {
    const DEFAULT_MARKER_SIZE = 40;
    const [map, setMap] = useState(null);
    const onLoad = (googleMap) => {
        setMap(googleMap);
    };
    useEffect(() => {
        const { google } = window;
        if (!map) {
            return;
        }
        const mapMarkers = markers.map(({ lat, lng, icon, size = DEFAULT_MARKER_SIZE }) => new google.maps.Marker({
            position: new google.maps.LatLng({ lat, lng }),
            map,
            icon: {
                url: icon || PinIconSmallPath,
                scaledSize: new google.maps.Size(size || DEFAULT_MARKER_SIZE, size || DEFAULT_MARKER_SIZE), // maps adds wierd 16px to the marker
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(size / 2 || 20, size ? size * 0.945 : 37.8), // 0.945 factor
            },
        }));
        const bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < mapMarkers.length; i++) {
            bounds.extend(mapMarkers[i]?.getPosition());
        }
        map.fitBounds(bounds);
        return () => {
            mapMarkers.forEach(marker => marker.setMap(null));
        };
    }, [map, markers]);
    return (<GoogleMap onLoad={onLoad} options={{
            styles: mapStyles,
            disableDefaultUI: true,
        }} mapContainerClassName={clsx('w-full', className)}/>);
};

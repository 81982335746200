import { ProductAttributeField, ProductAttributeType } from '@/services/ProductCatalog/constants';
const map = {
    [ProductAttributeField.GEOLOGY]: ProductAttributeType.GEOLOGY,
    [ProductAttributeField.GEOGRAPHY]: ProductAttributeType.GEOGRAPHY,
    [ProductAttributeField.CHARACTERISTICS]: ProductAttributeType.CHARACTERISTICS,
    [ProductAttributeField.CERT_1]: ProductAttributeType.CERT_1,
    [ProductAttributeField.CERT_2]: ProductAttributeType.CERT_2,
    [ProductAttributeField.CERT_3]: ProductAttributeType.CERT_3,
    [ProductAttributeField.BRAND]: ProductAttributeType.BRAND,
    [ProductAttributeField.COLOR]: ProductAttributeType.COLOR,
    [ProductAttributeField.PROCESSING]: ProductAttributeType.PROCESSING,
};
export const fieldToType = (field) => {
    return map[field] || field;
};
export const typeToField = (type) => {
    const flippedMap = Object.fromEntries(Object.entries(map).map(([k, v]) => [v, k]));
    return flippedMap[type] || type;
};

<template>
    <div>
        <section class="quotation-prices__section" :class="[...computedClasses]" data-test="payment-terms">
            <span
                class="font-copy-sm-strong m-0 block"
                :class="{ 'mb-6': isPlatformAdmin(), 'mb-2': !isPlatformAdmin() }"
            >
                {{ $t('paymentTerms.title') }}
            </span>
            <div class="grid grid-cols-1 gap-y-4 md:grid-cols-2 md:gap-y-[48px]">
                <template v-if="paymentTerms?.length">
                    <div
                        v-for="(orgPaymentTerm, key) in getTermsIncludingDescription"
                        :key="key"
                        class="flex flex-col items-start gap-y-2"
                    >
                        <span v-if="isPlatformAdmin()" class="font-copy-sm-strong">{{
                            orgLabel(orgPaymentTerm.organizationType)
                        }}</span>
                        <span class="font-copy-sm break-all" data-test="payment-term-description">
                            {{ orgPaymentTerm.description }}
                        </span>
                    </div>
                </template>
                <div v-if="noCarrierPaymentTerm" class="flex flex-col items-start gap-y-2">
                    <span v-if="isPlatformAdmin()" class="font-copy-sm-strong">{{ $t('disposal.carrier.title') }}</span>
                    <span class="font-copy-sm" data-test="payment-term-description"> --- </span>
                    <span class="font-copy-sm">
                        {{ $t('paymentTerms.noCarrier') }}
                    </span>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { CONTEXT_CARRIER, CONTEXT_SUPPLIER, CONTEXT_PLATFORM } from '@/constants/context';
import NetSuiteV1 from '@/services/Api/NetSuiteV1';
import routeContext from '@/plugins/mixins/routeContext';

export default {
    name: 'PaymentTerms',
    mixins: [routeContext],
    props: {
        supplierOrganizationId: {
            type: Number,
            required: true,
        },
        carrierOrganizationId: {
            type: Number,
            required: true,
        },
        projectPositionType: {
            type: String,
            required: true,
        },
        paymentTerms: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            supplierOrganization: null,
            carrierOrganization: null,
            termsIncludingDescription: [],
        };
    },
    computed: {
        computedClasses() {
            const styling = [
                'border-t-[2px] border-t-[#eaeaea]',
                'p-[20px]',
                {
                    'pl-0': !this.inRouteContext(CONTEXT_PLATFORM),
                },
            ];

            return [...styling];
        },
        noCarrierPaymentTerm() {
            if (this.isPlatformAdmin()) {
                return (
                    (this.paymentTerms.length == 1 && this.paymentTerms[0].organizationType !== 'carrier') ||
                    this.paymentTerms.length == 0
                );
            }

            if (this.isCarrier()) {
                return this.paymentTerms.length == 0;
            }

            return false;
        },
        getTermsIncludingDescription() {
            return this.termsIncludingDescription.filter(
                paymentTerm =>
                    (this.isSupplierOrPlatformAdmin() && paymentTerm.organizationType == 'supplier') ||
                    (this.isCarrierOrPlatformAdmin() && paymentTerm.organizationType == 'carrier')
            );
        },
    },
    async created() {
        await this.fetchTermsIncludingDescription();
    },
    methods: {
        async fetchTermsIncludingDescription() {
            this.paymentTerms.map(async paymentTerm => {
                const fetchedPaymentTerm = await NetSuiteV1.getNetSuitePaymentTerm(paymentTerm.paymentTermId);
                paymentTerm.description = fetchedPaymentTerm.description;
                this.termsIncludingDescription = [...this.termsIncludingDescription, paymentTerm];
            });
        },
        orgLabel(orgType) {
            if (orgType === 'supplier') {
                return this.$t('paymentTerms.organizationTypes.supplier');
            }

            if (orgType === 'carrier') {
                return this.$t('disposal.carrier.title');
            }
        },
        isPlatformAdmin() {
            return this.inRouteContext(CONTEXT_PLATFORM);
        },
        isSupplierOrPlatformAdmin() {
            return this.inRouteContext(CONTEXT_SUPPLIER) || this.isPlatformAdmin();
        },
        isCarrier() {
            return this.inRouteContext(CONTEXT_CARRIER);
        },
        isCarrierOrPlatformAdmin() {
            return this.isCarrier() || this.isPlatformAdmin();
        },
    },
};
</script>

<style lang="scss" scoped>
// Allowing reusage of legacy styles since this is a to-be-done refactoring asap

.quotation-prices__section {
    & + & {
        border-top: $border-solid-2px;
    }
}

.quotation-prices__table {
    & + & {
        margin-top: 10px;
        padding-top: 10px;
        border-top: $border-solid-1px;
    }
}

.quotation-prices__table-data {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-row-gap: 5px;

    > *:nth-child(2n) {
        text-align: right;
    }
}
</style>

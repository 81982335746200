// https://github.com/schuettflix/backend/blob/2e4cb9b1e1153c046654570a92bab4bf8ea08d74/src/Enum/BillingInterval.php
import { AvailableMarkets } from '@/services/MarketPermission/constants';
export const BILLING_INTERVAL = {
    ON_ORDER_CLOSED: 'on_order_closed',
    MANUALLY: 'manually',
};
export const BILLING_GROUPS = ['order', 'project', 'project_position'];
export const BILLING_INTERVALS = ['on_order_closed', 'manually', 'daily', 'weekly', 'bi_weekly', 'monthly'];
export const VALID_BILLING_INTERVALS = [
    { group: 'project', interval: 'manually', markets: [AvailableMarkets.CZ, AvailableMarkets.PL] }, // Legacy `manually` from before billing intervals
    { group: 'order', interval: 'on_order_closed' }, // Legacy `on_order_closed` from before billing intervals
    // New ones
    { group: 'project', interval: 'daily' },
    { group: 'project', interval: 'weekly' },
    { group: 'project', interval: 'bi_weekly' },
    { group: 'project', interval: 'monthly' },
    { group: 'project_position', interval: 'daily' },
    { group: 'project_position', interval: 'weekly' },
    { group: 'project_position', interval: 'bi_weekly' },
    { group: 'project_position', interval: 'monthly' },
];
export const PRIVILEGED_INTERVALS = ['manually', 'bi_weekly', 'monthly'];
export function getIntervals(options) {
    return VALID_BILLING_INTERVALS.map(interval => {
        const id = `${interval.group}_${interval.interval}`;
        return {
            id,
            interval,
            canBeDowngradedTo: !PRIVILEGED_INTERVALS.includes(interval.interval),
        };
    }).filter(interval => {
        if (!options.isManualEnabled && interval.interval.interval === 'manually') {
            return false;
        }
        if (options.type === 'credit_note' && interval.interval.group === 'order') {
            return false;
        }
        return true;
    });
}

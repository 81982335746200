import { ConfigType, ProductAttributeType } from '@/services/ProductCatalog/constants';
export class AttributeSetMapper {
    product;
    attributes;
    mappedData;
    constructor(product, attributes) {
        this.product = product;
        this.attributes = attributes;
        this.mappedData = this.mapData();
    }
    getFieldData(field) {
        return this.mappedData[field] || null;
    }
    getFieldValue(field) {
        return this.getFieldData(field)?.value || null;
    }
    mapData() {
        return Object.values(ProductAttributeType).reduce((result, field) => {
            const value = this.getMappedAttributeValue(this.findAttribute(field));
            if (value) {
                result[field] = value;
            }
            return result;
        }, {});
    }
    getMappedAttributeValue(attribute) {
        if (!attribute) {
            return null;
        }
        const found = this.product.attributeValues.filter(value => value.attributeSetId === attribute.id);
        if (!found.length) {
            return null;
        }
        const isMultiple = attribute.config.type === ConfigType.CHOICE
            ? attribute.config.multiple
            : attribute.config.type === ConfigType.COLOR;
        if (isMultiple) {
            return {
                attributeSetId: found[0].attributeSetId,
                label: attribute.label,
                valueLabel: found.map(item => item.label),
                value: found.map(item => item.id),
                multiple: isMultiple,
            };
        }
        else {
            return {
                attributeSetId: found[0].attributeSetId,
                label: attribute.label,
                valueLabel: found[0].label,
                value: found[0].id,
                multiple: isMultiple,
            };
        }
    }
    findAttribute(field) {
        const item = this.attributes.find(attribute => attribute.key === field);
        return item || null;
    }
}

/**
 * Graceful interval will wait for async requests before continue with cycle
 * it returns a clear closure which breaks the execution
 *
 * @param {*} callback
 * @param {*} ms
 */
export default function gracefulInterval(callback, ms) {
    let intervalId;
    let cleared = false;

    const cycle = async function cycle() {
        // cleanup if the compoinent is unmounted, destroyed or garbage collected
        if (cleared || this === undefined || this === null || this._isDestroyed || !this._isMounted) {
            return;
        }

        if ((await callback()) === false) {
            cleared = true;
        } else {
            intervalId = setTimeout(cycle.bind(this), ms);
        }
    };

    intervalId = setTimeout(cycle.bind(this), ms);

    return () => {
        cleared = true;
        clearTimeout(intervalId);
    };
}

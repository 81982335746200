import { forwardRef } from 'react';
// TODO extract to component library, this is just temporary until icon PR is merged
export const ProjectOrderIcon = forwardRef(({ className, ...props }, ref) => {
    return (<svg width="80" height="42" viewBox="0 0 80 42" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} ref={ref} {...props}>
            <g clipPath="url(#clip0_3077_525745)">
                <path d="M77.6847 33.1977H75.0682V31.9365C75.0682 31.8607 75.0976 31.7879 75.1503 31.7334C75.203 31.679 75.2748 31.6472 75.3506 31.6447H77.6847C77.7172 31.6447 77.7483 31.6576 77.7712 31.6805C77.7942 31.7035 77.8071 31.7346 77.8071 31.7671V33.0753C77.8071 33.1078 77.7942 33.1389 77.7712 33.1618C77.7483 33.1848 77.7172 33.1977 77.6847 33.1977V33.1977Z" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M76.8753 25.4329V19.0047C76.8753 18.8499 76.8139 18.7015 76.7044 18.5921C76.595 18.4827 76.4466 18.4212 76.2918 18.4212H65.3459C65.2221 18.4197 65.1011 18.4584 65.001 18.5313C64.901 18.6042 64.8272 18.7076 64.7906 18.8259L64.5647 19.5318C64.4421 19.8918 64.4421 20.2823 64.5647 20.6424C64.7462 21.2528 65.0523 21.8189 65.4638 22.3049C65.8753 22.7909 66.3832 23.1863 66.9553 23.4659L75.9906 24.9341C76.3605 24.9963 76.6966 25.1871 76.9394 25.473C77.1822 25.7589 77.3162 26.1214 77.3177 26.4965V31.6259" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M61.7318 13.6588H60.8847C60.7466 13.6588 60.6139 13.713 60.5153 13.8098C60.4168 13.9067 60.3601 14.0383 60.3577 14.1765V35.6918" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M76.7624 18.6471C76.637 17.6474 76.3774 16.6692 75.9906 15.7388C75.8943 15.5377 75.7551 15.3601 75.5827 15.2187C75.4104 15.0772 75.209 14.9752 74.993 14.92C72.395 14.2581 69.7299 13.8949 67.0495 13.8377L61.7318 13.64" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M69.4871 13.9977L64.7812 11.88C64.5952 11.7678 64.3881 11.6948 64.1728 11.6657C63.9575 11.6366 63.7386 11.6518 63.5294 11.7106C63.3006 11.7971 63.0958 11.937 62.9321 12.1188C62.7684 12.3005 62.6505 12.5187 62.5882 12.7553L62.3247 13.6965L68.1224 13.9318" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M68.0283 18.6659V23.4847" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M77.5623 33.1976V34.4306L76.2447 34.9953L74.6259 35.6917H70.3812" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M46.0706 34.9482H35.3976" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M70.3812 34.4306C70.3793 33.8312 70.1997 33.2458 69.8652 32.7485C69.5306 32.2511 69.0561 31.8642 68.5016 31.6365C67.9472 31.4089 67.3376 31.3507 66.7501 31.4695C66.1626 31.5883 65.6235 31.8786 65.201 32.3037C64.7785 32.7289 64.4915 33.2698 64.3764 33.858C64.2613 34.4462 64.3232 35.0554 64.5543 35.6084C64.7854 36.1615 65.1753 36.6336 65.6747 36.9651C66.1741 37.2965 66.7606 37.4724 67.36 37.4706C67.7583 37.4706 68.1527 37.3918 68.5205 37.2388C68.8883 37.0858 69.2222 36.8616 69.503 36.5791C69.7838 36.2965 70.0059 35.9612 70.1566 35.5925C70.3073 35.2238 70.3836 34.8289 70.3812 34.4306Z" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M27.8118 34.4306C27.8099 33.8312 27.6303 33.2458 27.2958 32.7485C26.9612 32.2511 26.4867 31.8642 25.9322 31.6365C25.3778 31.4089 24.7682 31.3507 24.1807 31.4695C23.5932 31.5883 23.0541 31.8786 22.6316 32.3037C22.2091 32.7289 21.9221 33.2698 21.807 33.858C21.6919 34.4462 21.7538 35.0554 21.9849 35.6084C22.216 36.1615 22.6059 36.6336 23.1053 36.9651C23.6047 37.2965 24.1912 37.4724 24.7906 37.4706C25.1889 37.4706 25.5833 37.3918 25.9511 37.2388C26.3189 37.0858 26.6528 36.8616 26.9336 36.5791C27.2144 36.2965 27.4365 35.9612 27.5872 35.5925C27.7379 35.2238 27.8143 34.8289 27.8118 34.4306V34.4306Z" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M35.3977 34.4306C35.3958 33.8316 35.2165 33.2466 34.8823 32.7494C34.5482 32.2523 34.0742 31.8653 33.5203 31.6374C32.9664 31.4095 32.3573 31.3508 31.7701 31.4688C31.1828 31.5868 30.6437 31.8762 30.2208 32.3004C29.7979 32.7246 29.5102 33.2646 29.394 33.8522C29.2779 34.4398 29.3384 35.0487 29.5681 35.6019C29.7977 36.1551 30.1862 36.6279 30.6843 36.9605C31.1825 37.2931 31.7681 37.4706 32.3671 37.4706C32.7658 37.4706 33.1607 37.3919 33.529 37.239C33.8973 37.0861 34.2318 36.862 34.5133 36.5796C34.7949 36.2972 35.0179 35.962 35.1697 35.5932C35.3214 35.2244 35.3989 34.8293 35.3977 34.4306Z" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M54.2306 34.4306C54.2287 33.8316 54.0494 33.2466 53.7153 32.7494C53.3812 32.2523 52.9072 31.8653 52.3533 31.6374C51.7993 31.4095 51.1903 31.3508 50.603 31.4688C50.0158 31.5868 49.4767 31.8762 49.0538 32.3004C48.6309 32.7246 48.3432 33.2646 48.227 33.8522C48.1108 34.4398 48.1714 35.0487 48.401 35.6019C48.6307 36.1551 49.0191 36.6279 49.5173 36.9605C50.0155 37.2931 50.601 37.4706 51.2 37.4706C51.5988 37.4706 51.9936 37.3919 52.3619 37.239C52.7302 37.0861 53.0648 36.862 53.3463 36.5796C53.6278 36.2972 53.8509 35.962 54.0026 35.5932C54.1544 35.2244 54.2318 34.8293 54.2306 34.4306V34.4306Z" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M37.5812 31.127H36.9036" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M28.9412 31.127H28.0847" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M37.6753 34.9294H35.4259" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M19.8494 35.3718V30.9294H21.4871" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M77.2235 27.0235L75.7365 26.7694" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M74.2682 17.3482V20.1906" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M75.7177 37.8565H59.0118" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14.2871 38.0541H8.79059" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M54.7106 38.0541H17.1483" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M55.087 31.1647H58.5694V24.04V17.2541" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M48.1788 31.127H39.6235" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M58.5694 34.9294H54.2306" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M79.0212 29.1035H69.2518" stroke="#FF0000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M67.2941 29.1035H67.1624" stroke="#FF0000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16.64 20.1906L18.6353 28.8306" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M55.6706 17.0753H23.9059" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M58.5694 11.6447V17.2541" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M25.0541 40.4918H68.16" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M64.3577 35.6918H62.9647" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M44.7341 10.4023C44.7341 10.4023 50.3812 10.1859 52.9035 14.8071" stroke="#FF0000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M42.353 10.1765C42.2046 9.35364 41.8288 8.58875 41.2683 7.96835C40.7078 7.34794 39.9848 6.89678 39.1812 6.66589" stroke="#FF0000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M33.12 22.8635H14.0894" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M52.913 3.68234L56.1883 6.95764" stroke="#FF0000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M56.1883 3.68234L52.913 6.95764" stroke="#FF0000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12.6588 8.04C13.8647 8.04 14.8423 7.0624 14.8423 5.85647C14.8423 4.65054 13.8647 3.67294 12.6588 3.67294C11.4529 3.67294 10.4753 4.65054 10.4753 5.85647C10.4753 7.0624 11.4529 8.04 12.6588 8.04Z" stroke="#FF0000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.75297 34.0824C9.66781 34.0824 10.4094 33.3407 10.4094 32.4259C10.4094 31.511 9.66781 30.7694 8.75297 30.7694C7.83812 30.7694 7.0965 31.511 7.0965 32.4259C7.0965 33.3407 7.83812 34.0824 8.75297 34.0824Z" stroke="#FF0000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M31.8777 1.53647C32.0659 1.53647 36.0753 2.47764 36.2636 8.9247" stroke="#FF0000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M34.9647 8.92471C35.7308 8.91945 36.4786 9.15839 37.0997 9.60686C37.7208 10.0553 38.1828 10.69 38.4188 11.4188" stroke="#FF0000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M38.673 2.73177C38.673 2.73177 43.2189 1.58353 45.5247 8.37883" stroke="#FF0000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M38.8612 13.1506L43.6989 14.807" stroke="#FF0000" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9.8541 25.4612L6.20233 29.4894" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1.01648 27.1176L10.7577 15.0047L22.9929 12.5859C23.2617 12.5329 23.5405 12.5828 23.7741 12.7258C24.0077 12.8687 24.179 13.0943 24.2541 13.3576C24.3311 13.6128 24.3112 13.8873 24.1983 14.1287C24.0855 14.3701 23.8875 14.5614 23.6424 14.6659L15.6612 18.1671" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14.9583 17.9443L14.323 17.4276C14.1214 17.2636 13.825 17.2942 13.661 17.4958L12.8475 18.4962C12.6835 18.6978 12.714 18.9942 12.9157 19.1582L13.5509 19.6748C13.7526 19.8388 14.049 19.8083 14.2129 19.6066L15.0265 18.6063C15.1905 18.4046 15.1599 18.1082 14.9583 17.9443Z" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12.8753 18.4682L5.88239 25.1694" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.5153 19.6541L9.37415 24.4823" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.77411 23.3529L10.6353 25.8094" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.56471 24.04L6.83295 26.0353" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.33881 13.4141L11.8023 14.7977" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M17.7223 13.6306L8.33881 11.7859" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.68939 13.2353C8.178 13.2353 8.5741 12.8392 8.5741 12.3506C8.5741 11.862 8.178 11.4659 7.68939 11.4659C7.20078 11.4659 6.80469 11.862 6.80469 12.3506C6.80469 12.8392 7.20078 13.2353 7.68939 13.2353Z" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.68945 13.2259V18.7035" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M25.6282 6.24235L23.5765 11.6071L25.2988 15.3153L35.6518 14.5059L33.1012 12.4447C32.1488 11.6788 31.4098 10.6804 30.9553 9.54588L28.5365 3.58824L28.2824 2.53412L25.6282 6.24235Z" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M28.2823 2.52472L30.1176 1.57413" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M26.7576 4.66116L29.9388 13.047" stroke="#1F1920" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_3077_525745">
                    <rect width="80" height="40.4706" fill="white" transform="translate(0 0.764709)"/>
                </clipPath>
            </defs>
        </svg>);
});
ProjectOrderIcon.displayName = 'ProjectOrderIcon';

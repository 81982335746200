import TransportApi from '@/services/Api/Transport';
import LogService from '@schuettflix/util-log';

const Log = new LogService('components/Map/Transport', {
    domain: 'Transport',
});

export default class Transport {
    constructor(context, transportData) {
        this.transportApi = new TransportApi(context);
        this.id = transportData.id;

        this.rawTransport = transportData;
        this.setTransportData(transportData);

        // used to fetch only additional waypoints
        this.latestWaypointTimestamp = transportData.created;

        // list of waypoints
        this.waypoints = [];
        this.isPollingWaypoints = false;
        this.pollingWaypointsInterval = null;

        // callback when the location changes
        this.onLocationChange = () => {};
        this.onDataChange = () => {};

        this.testDriveInterval = null;
    }

    /**
     * Set transport data on current object
     * used to update transport data
     *
     * @param {object} transportData
     */
    setTransportData(transportData) {
        // status
        this.status = transportData.status;
        this.lastDataUpdate = transportData.updated || transportData.created;
        this.rawTransport = transportData;

        if (transportData.type !== 'shipment') {
            this.constructionSite = {
                location: transportData.constructionSite.location,
                headline: transportData.constructionSite.name,
                address: transportData.constructionSite.address,
            };

            this.supplier = {
                location: transportData.loadingLocation,
                headline: transportData.supplier.name,
                address: transportData.loadingAddress || transportData.supplier.billingAddress,
            };
        }

        // Location object
        this.currentLocation = transportData.currentLocation;
        this.latestPosition = { ...transportData.currentLocation };

        //
        this.arrivalTime = transportData.arrivalTime;
        this.destinationWaitingTimestamp = transportData.destinationWaitingTimestamp;

        // km
        this.remainingDistance = transportData.remainingDistance;

        // is transport behind skedule
        this.isLate = transportData.isLate;
    }

    async refreshData() {
        const transport = await this.transportApi.getOneById(this.id);
        const previousUpdated = this.lastDataUpdate;
        this.setTransportData(transport);

        // compare updated_at timestamp with previous call to determin where the data has changed
        if (previousUpdated !== this.lastDataUpdate) {
            this.onDataChange(this);
        }

        return this;
    }

    async refreshWaypoints(status = null) {
        try {
            const trackingItems = await this.transportApi.getTrackingByTimestamp(
                this.id,
                this.latestWaypointTimestamp,
                status
            );

            let lastWaypointTimestamp = null;

            // convert to waypoints
            const waypoints = trackingItems.reverse().map(item => {
                lastWaypointTimestamp = item.timestamp;
                return {
                    ...item.location,
                    rotation: item.heading,
                };
            });

            if (lastWaypointTimestamp === null || this.latestWaypointTimestamp === lastWaypointTimestamp) {
                return;
            }

            this.waypoints = this.waypoints.concat(waypoints);

            this.latestWaypointTimestamp = lastWaypointTimestamp;
            this.latestPosition = waypoints[waypoints.length - 1];

            this.onLocationChange(this.latestPosition, this);
        } catch (err) {
            Log.warn(err);
        }
    }
}

import _get from 'lodash/get';
import AbstractResource from './AbstractResource';

class Authenticator extends AbstractResource {
    /**
     * Request a new JWT with credentials
     */
    async requestToken({ username, password, locale }) {
        try {
            const response = await this.apiService.post('/auth', { username, password, locale });
            return response.data.token;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }

    /**
     * Request api informtaion
     * used for debug purposes
     */
    async requestInfo() {
        try {
            const response = await this.apiService.get('/info');
            return response.data;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }

    /**
     * Request user information
     */
    async requestUserInfo() {
        try {
            const response = await this.apiService.get('/v2/me');
            return response.data;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }

    /**
     * Reset password
     *
     * @param username
     * @returns {Promise<any>}
     */
    async resetPassword(username) {
        try {
            const response = await this.apiService.post('/v2/user/passwordreset', { username });
            return response.data.items;
        } catch ({ code, message, response }) {
            throw _get(response, 'data.errors.username[0]', this.messages[code] || message);
        }
    }

    /**
     * Change Password
     *
     * @param password
     * @param newPassword
     * @returns {Promise<any>}
     */
    async changePassword(password, newPassword) {
        try {
            const response = await this.apiService.post('/v2/user/passwordchange', { password, newPassword });
            return response.data.items;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Username lost
     *
     * @param countryCode
     * @param number
     * @returns {Promise<any>}
     */
    async usernameLost(countryCode, number) {
        try {
            const response = await this.apiService.post('/v2/user/usernameforget', { countryCode, number });
            return response.data.items;
        } catch ({ code, message, response }) {
            throw _get(response, 'data.errors.username[0]', this.messages[code] || message);
        }
    }
}

export default new Authenticator(null, {
    401: 'authenticator.errors.unauthorized',
    403: 'authenticator.errors.forbidden',
    404: 'authenticator.errors.notFound',
    418: 'authenticator.errors.iAmATeapot',
});

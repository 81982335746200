var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-upload",class:{
        'image-upload--inline': _vm.inline,
        'image-upload--light': _vm.light,
    }},[_c('button',{staticClass:"progress-button",class:{
            'progress-button--loading': _vm.isStateSaving,
            'progress-button--success': _vm.isStateSuccess,
            'progress-button--error': _vm.isStateError,
            'progress-button--hidden': _vm.passiveMode,
            needsclick: true,
        },attrs:{"disabled":_vm.isStateSaving,"type":"button","data-test":"button-confirm-delivery-upload-photo"},on:{"click":_vm.triggerFileSelect}},[_c('span',{staticClass:"progress-button__content"},[(_vm.isStateSaving)?_c('span',[_vm._v(_vm._s(_vm.$t('components.imageUpload.uploading')))]):_c('span',[_c('ImageIcon',{staticClass:"progress-button__icon icon--inline"}),_vm._v("    "+_vm._s(_vm.label || _vm.$t('components.imageUpload.selectImage'))+" ")],1)]),_c('span',{staticClass:"progress-button__progress"},[_c('span',{staticClass:"progress-button__progress-inner",style:({ width: `${_vm.progress}%` })})])]),(_vm.$root.isApp)?_c('ModalActions',{ref:"selectSource"},[_c('BaseButton',{attrs:{"primary":""},on:{"click":_vm.appSelectImage}},[_vm._v(" "+_vm._s(_vm.$t('components.imageUpload.selectExistingImage'))+" "),_c('ImageIcon',{staticClass:"progress-button__icon icon--inline",attrs:{"slot":"left"},slot:"left"})],1),_c('BaseButton',{attrs:{"primary":""},on:{"click":_vm.appTakeImage}},[_vm._v(" "+_vm._s(_vm.$t('components.imageUpload.newImage'))+" "),_c('CameraIcon',{staticClass:"progress-button__icon icon--inline",attrs:{"slot":"left"},slot:"left"})],1)],1):_c('input',{ref:"imageUploader",staticClass:"image-upload__input needsclick",attrs:{"id":"file","type":"file","accept":_vm.acceptedFileTypes},on:{"change":_vm.onFileChange}})],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
import LogService from '@schuettflix/util-log';
import _get from 'lodash/get';
import { assembleQueryFilter } from '@/plugins/mixins/persistentFiltersMixin';
import { CONTEXT_CARRIER_DRIVER, CONTEXT_SUPPLIER, CONTEXT_CARRIER, CONTEXT_CLIENT, CONTEXT_PLATFORM, CONTEXT_CLIENT_DRIVER, } from '@/constants/context';
import { CONSTRUCTION_PROJECT_DETAILS_ROUTE, CONSTRUCTION_PROJECT_POSITION_DETAILS_ROUTE, } from '@/constructionProjects/constants';
const Log = new LogService('services/Notification/DeepLinkService');
const testRoute = () => ({
    route: 'notificationTest',
    queryCallback: data => data,
});
const checkoutCheckRoute = context => {
    if (context !== CONTEXT_CLIENT) {
        return null;
    }
    return {
        route: 'order__order-list__order-quote-view',
        messageParam: 'data.id',
        routeParam: 'quoteId',
    };
};
const orderRoute = context => {
    if (context === CONTEXT_PLATFORM) {
        return {
            route: 'order-management__order-list__order-view',
            messageParam: 'data.id',
            routeParam: 'orderId',
        };
    }
    return {
        route: 'order__order-list__order-view',
        messageParam: 'data.id',
        routeParam: 'orderId',
    };
};
const transportRoute = context => {
    switch (context) {
        case CONTEXT_CARRIER_DRIVER:
            return {
                route: 'logistics__active-trips__transport-view',
                messageParam: 'data.id',
                routeParam: 'transportId',
            };
        case CONTEXT_CLIENT_DRIVER:
            return {
                route: 'logistics__pickup-active-trips__transport-view',
                messageParam: 'data.id',
                routeParam: 'transportId',
            };
        case CONTEXT_CARRIER:
            return {
                route: 'logistics__transport-list__transport-view',
                messageParam: 'data.id',
                routeParam: 'transportId',
            };
        case CONTEXT_SUPPLIER:
            return {
                route: 'materials__transport-list__transport-view',
                messageParam: 'data.id',
                routeParam: 'transportId',
            };
        case CONTEXT_CLIENT:
            return {
                route: 'order__transport-list__transport-view',
                messageParam: 'data.id',
                routeParam: 'transportId',
            };
        case CONTEXT_PLATFORM:
            return {
                route: 'order-management__transport-hub__transport-view',
                messageParam: 'data.id',
                routeParam: 'transportId',
            };
        default:
            return null;
    }
};
const preferredTransportHubRoute = context => {
    if (context !== CONTEXT_CARRIER) {
        return null;
    }
    return {
        route: 'logistics__transport-hub',
        query: {
            transportForced: assembleQueryFilter({
                page: 1,
                perPage: 25,
                status: ['new'],
            }),
        },
    };
};
const transportListRoute = context => {
    switch (context) {
        case CONTEXT_SUPPLIER:
            return {
                route: 'materials__transport-list',
            };
        case CONTEXT_CARRIER:
            return {
                route: 'logistics__transport-list',
            };
        default:
            return null;
    }
};
const transportHazardousWasteWithoutAccompanyingDocumentNumberListRoute = context => {
    if (context !== CONTEXT_CARRIER) {
        return null;
    }
    return {
        route: 'logistics__transport-list',
        messageParam: 'data',
        queryCallback() {
            return {
                transportForced: assembleQueryFilter({
                    perPage: 25,
                    own: true,
                    status: [],
                }),
                transport: assembleQueryFilter({
                    type: ['disposal'],
                    isProductDangerous: true,
                    isMissingAccompanyingDocumentNumber: true,
                    sortBy: 'urgency',
                    sortDirection: 'asc',
                    status: [
                        'checked_in',
                        'checked_in_loading',
                        'confirmation_pending',
                        'destination_waiting',
                        'in_transit',
                        'loaded',
                        'planned',
                        'ready_to_deliver',
                        'started',
                        'unloading_delayed',
                    ],
                }),
            };
        },
    };
};
const transportHubRoute = context => {
    if (context !== CONTEXT_CARRIER) {
        return null;
    }
    return {
        route: 'logistics__transport-hub',
        messageParam: 'data',
        queryCallback({ searchFilterId = null }) {
            return {
                searchFilterId,
                transportForced: assembleQueryFilter({
                    status: ['new'],
                }),
            };
        },
    };
};
const projectRoute = context => {
    if (context !== CONTEXT_CLIENT) {
        return null;
    }
    return {
        route: CONSTRUCTION_PROJECT_DETAILS_ROUTE,
        messageParam: 'data.constructionProjectId',
        routeParam: 'id',
    };
};
const activeTripsRoute = context => {
    if (context !== CONTEXT_CARRIER_DRIVER) {
        return null;
    }
    return {
        route: 'logistics__active-trips',
    };
};
const projectPositionRoute = context => {
    if (context === CONTEXT_SUPPLIER || context === CONTEXT_CARRIER) {
        const routeName = context === CONTEXT_SUPPLIER ? 'materials__project-position-list' : 'logistics__project-position-list';
        return {
            route: routeName,
            messageParam: 'data',
            queryCallback({ number }) {
                return {
                    projectPosition: assembleQueryFilter({
                        search: number,
                    }),
                };
            },
        };
    }
    return {
        route: CONSTRUCTION_PROJECT_POSITION_DETAILS_ROUTE,
        paramsHandler: ({ constructionProjectId, projectPositionId }) => {
            return {
                ...(constructionProjectId ? { id: constructionProjectId } : {}),
                ...(projectPositionId ? { positionId: projectPositionId } : {}),
            };
        },
    };
};
const orderQuantityRoute = context => {
    if (context !== CONTEXT_SUPPLIER) {
        return null;
    }
    return {
        route: 'materials__order-quantity',
    };
};
const carrierFilteredTransportListByNumber = context => {
    if (context !== CONTEXT_CARRIER) {
        return null;
    }
    return {
        route: 'logistics__transport-list',
        messageParam: 'data',
        queryCallback({ number, transportType }) {
            return {
                transport: assembleQueryFilter({
                    transportNumber: number,
                    type: [transportType],
                    own: true,
                    sortBy: 'urgency',
                    sortDirection: 'asc',
                }),
            };
        },
    };
};
const supplierFilteredTransportListByNumber = context => {
    if (context !== CONTEXT_SUPPLIER) {
        return null;
    }
    return {
        route: 'materials__transport-list',
        messageParam: 'data',
        queryCallback({ number, transportType }) {
            return {
                transport: assembleQueryFilter({
                    transportNumber: number,
                    type: [transportType],
                    sortBy: 'urgency',
                    sortDirection: 'asc',
                }),
            };
        },
    };
};
const messageTypes = {
    test: testRoute,
    'quote.pendingApproval.delivery': checkoutCheckRoute,
    'quote.pendingApproval.pickup': checkoutCheckRoute,
    'quote.rejected.delivery': checkoutCheckRoute,
    'quote.rejected.pickup': checkoutCheckRoute,
    'quote.rejectedWithReason.delivery': checkoutCheckRoute,
    'quote.rejectedWithReason.pickup': checkoutCheckRoute,
    'quote.accepted.delivery': checkoutCheckRoute,
    'quote.accepted.pickup': checkoutCheckRoute,
    'quote.cancelled.pickup': checkoutCheckRoute,
    'quote.reminded.pickup': checkoutCheckRoute,
    'transport.loaded.delivery': transportRoute,
    'transport.loaded.pickup': transportRoute,
    'transport.inTransit.delivery': transportRoute,
    'transport.inTransit.shipment': transportRoute,
    'transport.approaching': transportRoute,
    'transport.rejectLoading': transportRoute,
    'transport.destinationWaiting': transportRoute,
    'transport.confirmArrival.delivery.in_transit': transportRoute,
    'transport.confirmArrival.shipment.started': transportRoute,
    'transport.confirmArrival.shipment.in_transit': transportRoute,
    'transport.unloadingDelayed': transportRoute,
    'transport.confirmationPending': transportRoute,
    'transport.failed.delivery': transportRoute,
    'transport.failed.pickup': transportRoute,
    'transport.failed': transportRoute,
    'transport.unloadingDelayCausedByCustomer': transportRoute,
    'transport.unloadingDelayCausedByDriver': transportRoute,
    'transport.unloadingDelayConfirmationPending': transportRoute,
    'transport.replan': transportRoute,
    'transport.preferred.available': preferredTransportHubRoute,
    'transport.planned.customer.delivery': transportRoute,
    'transport.planned.customer.shipment': transportRoute,
    'transport.planned.driver.delivery': activeTripsRoute,
    'transport.planned.driver.shipment': activeTripsRoute,
    'transport.revise_plan.customer.delivery': transportRoute,
    'transport.revise_plan.customer.shipment': transportRoute,
    'transport.new.supplier.delivery': transportListRoute,
    'transport.new.carrier': transportHubRoute,
    'transport.started.delivery': transportRoute,
    'transport.loading_confirmed': transportRoute,
    'transport.delivered.customer.delivery': transportRoute,
    'transport.delivered.customer.pickup': transportRoute,
    'transport.started.pickup': transportRoute,
    'transport.delivered.dispatcher.delivery': transportRoute,
    'transport.unplan.driver': transportRoute,
    'transport.revise_plan.driver.delivery': transportRoute,
    'transport.revise_plan.driver.shipment': transportRoute,
    'transport.delivered.carrier.delivery': transportRoute,
    'transport.started.supplier.pickup': orderQuantityRoute,
    'custom_order.new.carrier': carrierFilteredTransportListByNumber,
    'custom_order.new.customer': orderRoute,
    'order.transports.validity.expired': orderRoute,
    'transport.delivered.shipment': transportRoute,
    'project.created': projectRoute,
    'project.position.waste.created.client': projectPositionRoute,
    'project.position.waste.created.supplier': projectPositionRoute,
    'project.position.waste.created.carrier': projectPositionRoute,
    'project.position.created.carrier': projectPositionRoute,
    'project.position.delivery.created.client': projectPositionRoute,
    'project.position.delivery.created.supplier': projectPositionRoute,
    'project.position.analysisdocument.added.supplier': projectPositionRoute,
    'project.position.status_changed': projectPositionRoute,
    'project_order.new.carrier': carrierFilteredTransportListByNumber,
    'project_order.delivery.new.supplier': supplierFilteredTransportListByNumber,
    'project.position.shipment.created.client': projectPositionRoute,
    'transport.assigned.remind_not_planned': transportListRoute,
    'transport.complete_automatic_weighing.delivery': transportRoute,
    'transport.complete_automatic_weighing.pickup': transportRoute,
    'disposal.transport.missing_accompanying_document_number': transportRoute,
    'disposal.transport.carrier.remind.unmaintained.accompanying_document_number': transportHazardousWasteWithoutAccompanyingDocumentNumberListRoute,
};
class DeepLinkService {
    hasMessageRoute(message) {
        const handler = messageTypes[message.type];
        if (!handler)
            return false;
        return !!handler(message.data.context);
    }
    getRouteFromMessage(message) {
        const handler = messageTypes[message.type];
        // Message does not have a link
        if (!handler) {
            return null;
        }
        const link = handler(message.data.context);
        if (!link) {
            Log.log(`route configuration for message type ${message.type} not found`);
            return null;
        }
        let params = {};
        let query = {};
        if (link.routeParam && link.messageParam) {
            params[link.routeParam] = _get(message, link.messageParam);
        }
        if (link.paramsHandler) {
            params = link.paramsHandler(message.data);
        }
        if (link.queryCallback) {
            if (link.messageParam) {
                query = link.queryCallback(_get(message, link.messageParam));
            }
            else {
                /*
                 * ToDo: This is a strange edge case I do not know, we should remove.
                 * For the TS refactoring I have kept the logic here to not destroy anything.
                 * Maybe a full refactoring of the whole API should be done.
                 */
                query = link.queryCallback(message);
            }
        }
        return {
            name: link.route,
            params,
            query: link.query || { ...query },
        };
    }
}
export default new DeepLinkService();

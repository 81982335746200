import NotificationApi from '@/services/Api/Notification';
import LogService from '@schuettflix/util-log';
import _cloneDeep from 'lodash/cloneDeep';

const Log = new LogService('services/Notification/NotificationSubscriptionService');

class NotificationSubscriptionService {
    constructor() {
        this.data = null;
    }

    async refreshSubscriptions() {
        try {
            this.data = await NotificationApi.getSubscriptions();
        } catch (err) {
            Log.error(err);
            throw err;
        }
    }

    /** @typedef {object} Subscriptions
     * @property {object[]} subscriptions
     * @property {string} subscriptions.category
     * @property {object} subscriptions.channels
     * @property {boolean} subscriptions.channels.email
     * @property {boolean} subscriptions.channels.push
     * @property {number} userId
     *
     * @returns {Promise<Subscriptions['subscriptions']>}
     */

    async getSubscriptions() {
        await this.refreshSubscriptions();

        return this.data ? _cloneDeep(this.data.subscriptions) : [];
    }

    async getSubscribedConstructionSiteIds() {
        await this.refreshSubscriptions();

        return _cloneDeep(this.data.constructionSites) || [];
    }

    async hasConstructionSiteSubscription(constructionSiteId) {
        constructionSiteId = parseInt(constructionSiteId);
        await this.refreshSubscriptions();

        return (this.data.constructionSites || []).includes(constructionSiteId);
    }

    async updateSubscriptions(subscriptions) {
        await this.refreshSubscriptions();

        this.data.subscriptions = subscriptions;

        this.data = await NotificationApi.updateSubscriptions(this.data);
    }

    async subscribeConstructionSite(constructionSiteId) {
        constructionSiteId = parseInt(constructionSiteId);
        await this.refreshSubscriptions();

        if (!this.data.constructionSites) {
            this.data.constructionSites = [];
        }

        const constructionSites = this.data.constructionSites;

        if (!constructionSites.includes(constructionSiteId)) {
            constructionSites.push(constructionSiteId);

            this.data = await NotificationApi.updateSubscriptions(this.data);
        }
    }

    async unsubscribeConstructionSite(constructionSiteId) {
        constructionSiteId = parseInt(constructionSiteId);
        await this.refreshSubscriptions();

        if (!this.data.constructionSites) {
            this.data.constructionSites = [];
        }

        const constructionSites = this.data.constructionSites;

        if (constructionSites.includes(constructionSiteId)) {
            constructionSites.splice(constructionSites.indexOf(constructionSiteId), 1);
            this.data.constructionSites = constructionSites;

            this.data = await NotificationApi.updateSubscriptions(this.data);
        }
    }

    async updateConstructionSiteSubscription(constructionSiteId, status) {
        if (status) {
            await this.subscribeConstructionSite(constructionSiteId);
        } else {
            await this.unsubscribeConstructionSite(constructionSiteId);
        }
    }
}

export default new NotificationSubscriptionService();

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormWrapper',{staticClass:"factory-location-type",attrs:{"data-test":"FactoryLocationType"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"factory-location-type__container"},[_vm._v(" "+_vm._s(_vm.$t('components.factoryManagement.factoryLocationType.title'))+" ")])]},proxy:true}])},[_c('FormFieldGroup',{staticClass:"factory-location-type__container",class:{ 'divider-bottom': _vm.isUsageDisposalActive },attrs:{"small-bottom-margin":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('Words',{attrs:{"bold":""}},[_vm._v(_vm._s(_vm.$t('components.factoryManagement.factoryLocationType.sectionLocationUsage.title')))])]},proxy:true}])},[_c('ComponentWrapper',{attrs:{"border-bottom":_vm.disposalNumberActive},scopedSlots:_vm._u([{key:"componentText",fn:function(){return [(_vm.isDisposalActiveInCurrentMarket)?_c('Words',[_vm._v(_vm._s(_vm.$t('components.factoryManagement.factoryLocationType.sectionLocationUsage.description'))+" ")]):_c('Words',[_vm._v(" "+_vm._s(_vm.$t( 'components.factoryManagement.factoryLocationType.sectionLocationUsage.nonGermanMarketDescription' ))+" ")])]},proxy:true},{key:"component",fn:function(){return [_c('div',{staticClass:"mb-0 flex flex-col gap-4 md:flex-row"},[_c('div',{staticClass:"md:w-1/2"},[_c('CheckboxCard',{staticClass:"h-full",attrs:{"model-value":_vm.form.usages,"title":_vm.$t(
                                    'components.factoryManagement.factoryLocationType.sectionLocationUsage.cards.selling.title'
                                ),"description":_vm.$t(
                                    'components.factoryManagement.factoryLocationType.sectionLocationUsage.cards.selling.description'
                                ),"is-read-only":_vm.isReadOnly,"is-disabled":_vm.isUsageSellerDisabled,"checkbox-value":"selling","is-icon-full-size":"","data-test":"factory-location-type-checkbox-selling"},on:{"change":function($event){return _vm.update('usages', $event)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SellingTransportIcon')]},proxy:true}])}),(_vm.isUsageSellerDisabled)?_c('ButtonLink',{attrs:{"title":_vm.$t(
                                    'components.factoryManagement.factoryLocationType.sectionLocationUsage.cards.notUnlocked'
                                ),"align-right":"","has-arrow-right":"","spaced-top":""},on:{"click":_vm.openContactFlyout}}):_vm._e()],1),(_vm.isDisposalActiveInCurrentMarket)?_c('div',{staticClass:"md:w-1/2"},[_c('CheckboxCard',{staticClass:"h-full",attrs:{"model-value":_vm.form.usages,"title":_vm.$t(
                                    'components.factoryManagement.factoryLocationType.sectionLocationUsage.cards.disposal.title'
                                ),"description":_vm.$t(
                                    'components.factoryManagement.factoryLocationType.sectionLocationUsage.cards.disposal.description'
                                ),"is-read-only":_vm.isReadOnly,"is-disabled":_vm.isUsageDisposalDisabled,"checkbox-value":"disposal","is-icon-full-size":"","data-test":"factory-location-type-checkbox-disposal"},on:{"change":function($event){return _vm.update('usages', $event)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DisposalTransportIcon')]},proxy:true}],null,false,28850420)}),(_vm.isUsageDisposalDisabled)?_c('ButtonLink',{attrs:{"title":_vm.$t(
                                    'components.factoryManagement.factoryLocationType.sectionLocationUsage.cards.notUnlocked'
                                ),"align-right":"","has-arrow-right":"","spaced-top":""},on:{"click":_vm.openContactFlyout}}):_vm._e()],1):_vm._e()])]},proxy:true}])})],1),(_vm.showUsageConfigurationForm)?_c('FormFieldGroup',{staticClass:"factory-location-type__container",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('Words',{attrs:{"bold":""}},[_vm._v(_vm._s(_vm.$t('components.factoryManagement.factoryLocationType.sectionWasteAcceptance.title')))])]},proxy:true}],null,false,2603339836)},[_c('div',{staticClass:"factory-location-type__configuration"},[_c('ComponentWrapper',{scopedSlots:_vm._u([{key:"componentText",fn:function(){return [_c('div',{staticClass:"mb-1 flex justify-between gap-4 leading-5"},[_vm._v(" "+_vm._s(_vm.$t( 'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.disposalTypeConfiguration.description' ))+" ")])]},proxy:true},{key:"componentHelpLink",fn:function(){return [_c('HelpLink',{attrs:{"type":"helpLocationType","data-test":"help-link-disposal-type"}})]},proxy:true},{key:"component",fn:function(){return [_c('RadioCardSet',{attrs:{"value":_vm.form.disposalSettings.factoryType,"options":_vm.factoryTypeOptions,"name":"disposal-type","is-read-only":_vm.isReadOnly,"data-test":"radio-card-disposal-type","error":_vm.firstError['disposalSettings.factoryType'] ||
                            _vm.getError('form.disposalSettings.factoryType', _vm.submitted)},on:{"input":function($event){return _vm.update('disposalSettings.factoryType', $event)}}})]},proxy:true}],null,false,2239808321)}),(_vm.showLandFillClassifications)?_c('ComponentWrapper',{attrs:{"error":_vm.firstError['disposalSettings.landfillClass'] ||
                    _vm.getError('form.disposalSettings.landfillClass', _vm.submitted)},scopedSlots:_vm._u([{key:"componentText",fn:function(){return [_c('div',{staticClass:"mb-1 flex justify-between gap-4 leading-5"},[_c('Words',{attrs:{"block":""}},[_vm._v(_vm._s(_vm.$t( 'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.disposalTypeConfiguration.landfillClasses.description' ))+" ")])],1)]},proxy:true},{key:"componentHelpLink",fn:function(){return [_c('HelpLink',{attrs:{"type":"helpLandfillClass","data-test":"help-link-landfill-classes"}})]},proxy:true},{key:"component",fn:function(){return [_c('RadioCardSet',{attrs:{"value":_vm.form.disposalSettings.landfillClass,"options":_vm.landfillClassOptions,"name":"landfill-classes","data-test":"landfill-classes","is-read-only":_vm.isReadOnly,"is-disabled":!_vm.allowDkChange,"error":_vm.firstError['disposalSettings.landfillClass'] ||
                            _vm.getError('form.disposalSettings.landfillClass', _vm.submitted)},on:{"input":function($event){return _vm.update('disposalSettings.landfillClass', $event)}}})]},proxy:true}],null,false,2807369437)}):_vm._e(),(_vm.showFileUpload)?_c('ComponentWrapper',{scopedSlots:_vm._u([{key:"componentText",fn:function(){return [_c('div',{staticClass:"mb-1 flex justify-between gap-4 leading-5"},[_c('Words',[_vm._v(_vm._s(_vm.fileUploaderMessage))])],1)]},proxy:true},{key:"componentHelpLink",fn:function(){return [_c('HelpLink',{attrs:{"type":"helpFileUploader","data-test":"help-link-file-uploader"}})]},proxy:true},{key:"component",fn:function(){return [_c('FileUploader',{attrs:{"existing-file":_vm.form.disposalPrincipleDocument,"upload-file-message":_vm.uploadFileMessage,"file-upload-progress":_vm.progress,"accepted-file-types":_vm.acceptedFileTypes,"response":_vm.fileUploadResponse,"error":_vm.firstError['disposalPrincipleDocument.uuid'] ||
                            _vm.getError('form.disposalPrincipleDocument.uuid', _vm.submitted),"data-test":"file-uploader"},on:{"fileUpload":_vm.uploadFile,"cancelUpload":_vm.cancelUpload}})]},proxy:true}],null,false,3136421257)}):_vm._e(),(_vm.showDisposerNumberField)?_c('ComponentWrapper',{scopedSlots:_vm._u([{key:"componentText",fn:function(){return [_c('Words',[_vm._v(_vm._s(_vm.$t('components.factoryManagement.factoryLocationType.sectionWasteAcceptance.description')))])]},proxy:true},{key:"componentTitle",fn:function(){return [_c('Words',[_vm._v(" "+_vm._s(_vm.$t( 'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.factoryDisposerNumber.title' ))+" ")])]},proxy:true},{key:"component",fn:function(){return [_c('DisposalNumber',{attrs:{"location":_vm.form.location,"address":_vm.form.address,"disposal-number-string":_vm.form.disposerNumber,"is-read-only":_vm.isReadOnly,"error":_vm.firstError['disposerNumber'] || _vm.getError('form.disposerNumber', _vm.submitted),"data-test":"DisposerNumber"},on:{"disposalNumber":_vm.setDisposerNumber}})]},proxy:true}],null,false,622698185)}):_vm._e(),(_vm.showFileUpload)?_c('ComponentWrapper',{scopedSlots:_vm._u([{key:"componentTitle",fn:function(){return [_c('div',{staticClass:"mb-1 flex justify-between gap-4 leading-5"},[_c('Words',{attrs:{"bold":""}},[_vm._v(" "+_vm._s(_vm.$t( 'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.disposalTypeConfiguration.specialHint.title' ))+" ")])],1)]},proxy:true},{key:"componentHelpLink",fn:function(){return [_c('HelpLink',{attrs:{"type":"helpSpecialHint","data-test":"help-link-special-hint"}})]},proxy:true},{key:"component",fn:function(){return [_c('TextField',{attrs:{"value":_vm.form.disposalSettings.specialHint,"label":_vm.$t(
                                'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.disposalTypeConfiguration.specialHint.placeholder'
                            ),"type":"textarea","readonly":_vm.isReadOnly,"has-error":!!_vm.firstError['disposalSettings.specialHint'] ||
                            _vm.hasErrors('form.disposalSettings.specialHint', _vm.submitted),"error":_vm.firstError['disposalSettings.specialHint'] ||
                            _vm.getError('form.disposalSettings.specialHint', _vm.submitted),"rows":4},on:{"input":function($event){return _vm.update('disposalSettings.specialHint', $event)}}})]},proxy:true}],null,false,1939366271)}):_vm._e()],1)]):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
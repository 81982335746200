export default {
    path: 'transports',
    name: 'transport-list',
    component: () =>
        import(
            /* webpackChunkName: "transport" */
            './TransportListPage'
        ),
    meta: {
        requiresAuth: true,
        noBackArrow: true,
    },
    children: [
        {
            path: 'plan',
            name: 'transport-plan',
            component: () =>
                import(
                    /* webpackChunkName: "transport" */
                    './TransportListBulkPlanPage'
                ),
            children: [
                {
                    include: 'vehicle-new',
                },
            ],
        },
        {
            include: 'transport-view',
        },
    ],
};

export const transportViewRoute = {
    path: 'transport/:transportId',
    name: 'transport-view',
    component: () =>
        import(
            /* webpackChunkName: "transport" */
            './TransportViewPageV2'
        ),
    props: true,
    meta: {
        noHeader: true,
    },
    children: [
        {
            path: 'loading',
            name: 'transport-loading',
            component: () =>
                import(
                    /* webpackChunkName: "transport" */
                    './TransportLoadingPage'
                ),
            props: true,
            meta: {
                noHeader: true,
            },
        },
        {
            path: 'map',
            name: 'transport-map',
            component: () =>
                import(
                    /* webpackChunkName: "transport" */
                    './components/TransportMapContent'
                ),
            props: true,
            meta: {
                noHeader: true,
            },
        },
        {
            include: 'order-view',
        },
    ],
};

<template>
    <GridRow :count="4" spacing="small" class="span-4">
        <TextField
            :value="organization.paymentInfo.iban"
            :readonly="readonly"
            :error="errors.iban"
            :label="$t('components.organizationForm.paymentInfo.iban')"
            :class="{
                'span-3': $root.isDesktop,
                'span-4': !$root.isDesktop,
            }"
            data-test="organizationForm-paymentInfo-iban"
            @input="updatePaymentInformation({ ...organization.paymentInfo, iban: $event })"
        />
        <TextField
            :value="organization.paymentInfo.bic"
            :readonly="readonly"
            :error="errors.bic"
            :label="$t('components.organizationForm.paymentInfo.bic')"
            :class="$root.isDesktop ? 'span-1' : 'span-4'"
            data-test="organizationForm-paymentInfo-bic"
            @input="updatePaymentInformation({ ...organization.paymentInfo, bic: $event })"
        />
        <TextField
            :value="organization.paymentInfo.name"
            :readonly="readonly"
            :error="errors.name"
            :label="$t('components.organizationForm.paymentInfo.name')"
            :class="$root.isDesktop ? 'span-2' : 'span-4'"
            data-test="organizationForm-paymentInfo-name"
            @input="updatePaymentInformation({ ...organization.paymentInfo, name: $event })"
        />
        <TextField
            :value="organization.paymentInfo.owner"
            :readonly="readonly"
            :error="errors.owner"
            :label="$t('components.organizationForm.paymentInfo.owner')"
            :class="$root.isDesktop ? 'span-2' : 'span-4'"
            data-test="organizationForm-paymentInfo-owner"
            @input="updatePaymentInformation({ ...organization.paymentInfo, owner: $event })"
        />
    </GridRow>
</template>

<script>
import TextField from '@/components/Form/TextField.v2';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import { isChangedPaymentInfo, isDirectDebit } from '@/services/utils/organization';
import GridRow from '@/components/Layout/GridRow';
export default {
    name: 'BankDetailsForm',
    components: { GridRow, TextField },
    props: {
        organization: {
            type: Object,
            default: null,
        },
        originalOrganization: {
            type: Object,
            default: null,
        },
        isGlobalVariableDirectDebitActive: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Object,
            default: () => ({
                iban: false,
                bic: false,
                name: false,
                owner: false,
            }),
        },
    },
    data() {
        return {
            updatedOrganization: null,
        };
    },
    computed: {
        isChangedPaymentInfo() {
            return isChangedPaymentInfo(this.updatedOrganization, this.originalOrganization);
        },
        isDirectDebit() {
            return isDirectDebit(this.updatedOrganization);
        },
        shouldShowPaymentDetailsChangedWithDirectDebitEnabledModal() {
            return this.isGlobalVariableDirectDebitActive && this.isChangedPaymentInfo && this.isDirectDebit;
        },
    },
    methods: {
        updatePaymentInformation(paymentInfo) {
            if (_isEqual(this.organization.paymentInfo, paymentInfo)) {
                return;
            }

            this.updatedOrganization = _cloneDeep(this.organization);
            this.updatedOrganization.paymentInfo = paymentInfo;

            this.$emit('update-payment-details', this.updatedOrganization);

            if (this.originalOrganization.paymentInfo.iban === null) {
                //no blocking modal needed if entering bank details for the first time
                return;
            }

            if (!this.isGlobalVariableDirectDebitActive) {
                this.$emit('block-save-by-modal', {
                    modalRefToTrigger: 'paymentInfoChangedDirectDebitDisabledModalRef',
                    blocked: this.isChangedPaymentInfo && this.isDirectDebit,
                });
            } else {
                this.$emit('block-save-by-modal', {
                    modalRefToTrigger: 'paymentInfoChangedDirectDebitEnabledModalRef',
                    blocked: this.shouldShowPaymentDetailsChangedWithDirectDebitEnabledModal,
                });
            }
        },
    },
};
</script>

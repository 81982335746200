<template>
    <div>
        <div
            v-if="isImpersonated"
            class="impersonation-bar transition-opacity duration-200"
            :class="{
                'opacity-5 hover:opacity-100': isToasterActive,
            }"
        >
            <div class="impersonation-bar__info">
                <div class="impersonation-bar__label">{{ $t('components.impersonationBar.label') }}:</div>
                <div class="impersonation-bar__user">{{ fullName }}</div>
                <div class="impersonation-bar__organization">{{ organizationName }}</div>
            </div>
            <BaseButton primary dark class="impersonation-bar__button" @click.stop="stopImpersonation">
                {{ $t('components.impersonationBar.logoutLabel') }}
            </BaseButton>
        </div>
        <ModalBox
            ref="impersonationDialog"
            :optional-dialog="false"
            :headline="$t('components.impersonationBar.headline')"
        >
            {{ $t('components.impersonationBar.message', { name: fullName }) }}
            <ButtonGroup slot="actions">
                <BaseButton primary light @click="stopImpersonation">
                    {{ $t('components.impersonationBar.logoutLabel') }}
                </BaseButton>
                <BaseButton primary @click="confirmDialog">
                    {{ $t('components.impersonationBar.confirmLabel') }}
                </BaseButton>
            </ButtonGroup>
        </ModalBox>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ModalBox from '@/components/Modal/ModalBox';
import { useToaster } from '@/components/Alert/Toaster.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ImpersonationBar',
    components: {
        BaseButton,
        ButtonGroup,
        ModalBox,
    },
    setup() {
        const { isToasterActive } = useToaster();
        return { isToasterActive };
    },
    computed: {
        ...mapGetters('user', ['info', 'fullName', 'isImpersonated', 'organizationName', 'showImpersonationNote']),
    },
    watch: {
        showImpersonationNote: {
            initialised: true,
            handler(value) {
                this.$refs.impersonationDialog.$emit(value ? 'open' : 'close');
            },
        },
    },
    created() {
        setTimeout(() => {
            this.$refs.impersonationDialog.$emit(this.showImpersonationNote ? 'open' : 'close');
        }, 1000);
    },
    methods: {
        confirmDialog() {
            this.$store.commit('user/setImpersonationNoteVisibility', false);
            // !!! Workaround - EventBus handlers and interval updates are not reinitialized
            window.location.reload();
        },
        async stopImpersonation() {
            await this.$store.dispatch('user/stopImpersonation');

            if (this.$store.getters['user/originalLocation']) {
                window.location = this.$store.getters['user/originalLocation'];
            } else {
                this.$router.push({ name: 'home' }).catch(() => {});
            }

            this.$store.commit('platform/forceRerender');
        },
    },
});
</script>

<style lang="scss">
.impersonation-bar {
    position: relative;
    width: 100%;
    height: var(--impersonation-bar-height);
    overflow: hidden;
    padding: 10px;
    background-color: red;
    color: #fff;
    z-index: 999999;
    display: flex;

    padding-bottom: calc(10px + env(safe-area-inset-bottom));
    height: calc(var(--impersonation-bar-height) + env(safe-area-inset-bottom));
    top: calc(0px - env(safe-area-inset-bottom));
}

.impersonation-bar__info {
    flex: 1 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    overflow: hidden;
}

.impersonation-bar__organization {
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.impersonation-bar__user {
    font-size: 14px;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.impersonation-bar__label {
    font-size: 10px;
}

.impersonation-bar__button {
    display: block;
    flex: 0 0;
    margin-left: 10px;

    &.button > .button__body {
        font-size: 14px;
        min-height: 30px;
    }
}

#app {
    --impersonation-bar-height: 0px;
}

#app.js--impersonated {
    --impersonation-bar-height: 70px;
    --view-height: calc(100vh - var(--impersonation-bar-height));
    --view-height: calc(100vh - var(--impersonation-bar-height) - env(safe-area-inset-bottom));

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 5px solid red;
        pointer-events: none;
        z-index: 9999;
        box-shadow: inset 0px 0px 25px rgba(255, 0, 0, 0.5);
    }

    @media screen and (min-width: $layout-desktop-min) {
        padding-bottom: 70px;

        &::before {
            position: fixed;
        }

        .impersonation-bar {
            position: fixed;
            top: auto;
            bottom: 0;
        }
    }
}
</style>

<template>
    <Pane>
        <div slot="head">
            <strong>{{ $route.name }}</strong>
            <br />
            <small>{{ $route.fullPath }}</small>
        </div>
        <RouteTree v-if="isOpen" slot="body" :routes="routes" class="route-inspector__tree" />
        <button v-else slot="body" @click="isOpen = true">Display route tree</button>
    </Pane>
</template>

<script>
import Pane from '@/components/Debug/Pane/Pane.vue';
import RouteTree from './RouteTree.vue';

export default {
    name: 'RoutInspector',
    components: {
        RouteTree,
        Pane,
    },
    data() {
        return {
            routes: [],
            isOpen: false,
        };
    },
    created() {
        this.routes = this.$router.options.routes;
    },
};
</script>

<style lang="scss"></style>

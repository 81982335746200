<template>
    <div>
        <ErrorPage v-if="serverError" />
        <ProjectPositionSelectionErrorPage v-else-if="projectPositionSelectionErrors" />
        <router-view v-else />
        <PlanningAssistantFlup @submit="handlePlanningAssistantInput" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { navigationFailure } from '@/services/utils/router';
import ErrorPage from '@/pages/Checkout/ErrorPage';
import PlanningAssistantFlup from '@/pages/Checkout/components/PlanningAssistantFlup';
import ProjectPositionSelectionErrorPage from '@/pages/Checkout/ProjectPositionSelectionErrorPage';

export default {
    name: 'CheckoutRenderer',
    components: {
        ErrorPage,
        PlanningAssistantFlup,
        ProjectPositionSelectionErrorPage,
    },
    computed: {
        ...mapState('basket', ['serverError', 'projectPositionSelectionErrors']),
        ...mapGetters('basket', ['isDisposal']),
    },
    methods: {
        handlePlanningAssistantInput() {
            const process = this.isDisposal ? 'disposal' : 'delivery';
            const shippingMethodRoutes = [
                this.$root.findRouteName('checkout-delivery-shipping-method'),
                this.$root.findRouteName('checkout-custom-delivery-shipping-method'),
                this.$root.findRouteName(`checkout-project-position-${process}-shipping-method`),
            ];
            if (shippingMethodRoutes.includes(this.$route.name)) {
                this.$router
                    .push({ name: this.$root.findRouteName(this.$route.meta.nextDateSelect) })
                    .catch(navigationFailure);
            }
        },
    },
};
</script>

<style></style>

<template>
    <div class="time-range-fieldset">
        <GridRow
            :count="hideOptions || !$root.isDesktop ? 2 : 4"
            margin-less
            :gap="gap"
            class="time-range-fieldset__grid"
        >
            <SelectBox v-if="!hideOptions" v-model="rangeType" class="span-2" @input="updateRangeSuggestion">
                <option value>{{ $t('components.timeRangeFieldset.option.custom') }}</option>
                <option v-for="(range, i) in suggestionList" :key="i" :value="i">
                    {{ $t(`components.timeRangeFieldset.option.${i}`) }}
                </option>
            </SelectBox>

            <DatetimePicker :value="from" :min="minFromTimestamp" :type="type" enable-reset @input="updateFrom">
                <TextField
                    :value="from ? $d(ensureJSTimestamp(from), 'short') : null"
                    :label="labelFrom || $t('components.timeRangeFieldset.fromLabel')"
                    :no-border="noBorder"
                    readonly
                    disabled
                    class="time-range-fieldset__text-field"
                >
                    <Button
                        v-if="from"
                        slot="action"
                        class="text-field__reset"
                        tabindex="-1"
                        @click.prevent.stop="updateFrom(null)"
                    >
                        <ResetIcon width="13" />
                    </Button>
                    <CalendarIcon slot="icon" />
                </TextField>
            </DatetimePicker>

            <DatetimePicker :value="to" :min="getMinToTimestamp()" :type="type" enable-reset @input="updateTo">
                <TextField
                    :value="to ? $d(ensureJSTimestamp(to), 'short') : null"
                    :label="labelTo || $t('components.timeRangeFieldset.toLabel')"
                    :no-border="noBorder"
                    readonly
                    disabled
                    class="time-range-fieldset__text-field"
                >
                    <Button
                        v-if="to"
                        slot="action"
                        class="text-field__reset"
                        tabindex="-1"
                        @click.prevent.stop="updateTo(null)"
                    >
                        <ResetIcon width="13" />
                    </Button>

                    <CalendarIcon slot="icon" />
                </TextField>
            </DatetimePicker>
        </GridRow>
    </div>
</template>

<script>
import _findKey from 'lodash/findKey';
import { ensureJSTimestamp } from '@/services/utils/date';

import DatetimePicker from '@/components/Form/DatetimePicker';
import GridRow from '@/components/Layout/GridRow';
import SelectBox from '@/components/Form/SelectBox.v2';
import TextField from '@/components/Form/TextField.v2';
import Button from '@/components/Button/Button';

import startOfToday from 'date-fns/start_of_today';
import endOfToday from 'date-fns/end_of_today';
import startOfYesterday from 'date-fns/start_of_yesterday';
import endOfYesterday from 'date-fns/end_of_yesterday';
import startOfWeek from 'date-fns/start_of_week';
import lastDayOfWeek from 'date-fns/last_day_of_week';
import startOfDay from 'date-fns/start_of_day';
import endOfDay from 'date-fns/end_of_day';
import startOfMonth from 'date-fns/start_of_month';
import endOfMonth from 'date-fns/end_of_month';
import subMonths from 'date-fns/sub_months';
import subWeeks from 'date-fns/sub_weeks';
import getTime from 'date-fns/get_time';
import subQuarters from 'date-fns/sub_quarters';
import startOfQuarter from 'date-fns/start_of_quarter';
import endOfQuarter from 'date-fns/end_of_quarter';
import startOfYear from 'date-fns/start_of_year';
import endOfYear from 'date-fns/end_of_year';
import subYears from 'date-fns/sub_years';

import ResetIcon from '@/assets/icons/micro/error.svg';
import CalendarIcon from '@/assets/icons/micro/calendar.svg';

export default {
    name: 'TimeRangeFieldset',
    components: {
        TextField,
        DatetimePicker,
        GridRow,
        SelectBox,
        Button,
        ResetIcon,
        CalendarIcon,
    },
    props: {
        type: {
            type: String,
            default: 'datetime',
            validator: v => ['date', 'datetime'].indexOf(v) !== -1,
        },
        from: {
            type: Number,
            default: null,
        },
        to: {
            type: Number,
            default: null,
        },
        hideOptions: {
            type: Boolean,
            default: false,
        },
        labelFrom: {
            type: String,
            default: null,
        },
        labelTo: {
            type: String,
            default: null,
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        minFromTimestamp: {
            type: Number,
            default: null,
        },
        minToTimestamp: {
            type: Number,
            default: null,
        },
        gap: {
            type: String,
            default: 'small',
            validator: value => ['default', 'large', 'small', 'none'].indexOf(value) !== -1,
        },
        showQuarterOptions: {
            type: Boolean,
            default: false,
        },
        showYearOptions: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rangeType: null,
            externalChange: false,
        };
    },
    computed: {
        suggestedRange() {
            if (!this.suggestionList[this.rangeType]) {
                return null;
            }

            return this.suggestionList[this.rangeType];
        },

        suggestionList() {
            let list;
            list = {
                today: this.getTodayOption(),
                yesterday: this.getYesterdayOption(),
                thisWeek: this.getThisWeekOption(),
                lastWeek: this.getLastWeekOption(),
                thisMonth: this.getThisMonthOption(),
                lastMonth: this.getLastMonthOption(),
            };
            if (this.showQuarterOptions) {
                list = {
                    ...list,
                    thisQuarter: this.getThisQuarterOption(),
                    lastQuarter: this.getLastQuarterOption(),
                };
            }
            if (this.showYearOptions) {
                list = {
                    ...list,
                    thisYear: this.getThisYearOption(),
                    lastYear: this.getLastYearOption(),
                };
            }
            return list;
        },
    },
    watch: {
        from: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) this.updateSuggestion();
            },
            immediate: true,
        },
        to: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) this.updateSuggestion();
            },
            immediate: true,
        },
    },
    mounted() {
        this.updateSuggestion();
    },
    methods: {
        updateSuggestion() {
            this.externalChange = true;
            const range = _findKey(this.suggestionList, o => {
                return Math.floor(o.from / 1000) === this.from && Math.floor(o.to / 1000) === this.to;
            });

            if (range) {
                this.rangeType = range;
            }

            if (this.from === null && this.to === null) {
                this.rangeType = null;
            }

            this.externalChange = false;
        },

        getTodayOption() {
            return {
                from: startOfToday().getTime(),
                to: endOfToday().getTime(),
            };
        },
        getYesterdayOption() {
            return {
                from: startOfYesterday().getTime(),
                to: endOfYesterday().getTime(),
            };
        },
        getThisWeekOption() {
            return {
                from: startOfWeek(new Date(), { weekStartsOn: 1 }).getTime(),
                to: endOfDay(lastDayOfWeek(new Date(), { weekStartsOn: 1 })).getTime(),
            };
        },
        getLastWeekOption() {
            return {
                from: startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }).getTime(),
                to: endOfDay(lastDayOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 })).getTime(),
            };
        },
        getThisMonthOption() {
            return {
                from: startOfMonth(new Date()).getTime(),
                to: endOfMonth(new Date()).getTime(),
            };
        },
        getLastMonthOption() {
            return {
                from: startOfMonth(subMonths(new Date(), 1)).getTime(),
                to: endOfMonth(subMonths(new Date(), 1)).getTime(),
            };
        },
        getThisQuarterOption() {
            return {
                from: startOfQuarter(new Date()).getTime(),
                to: endOfQuarter(new Date()).getTime(),
            };
        },
        getLastQuarterOption() {
            return {
                from: startOfQuarter(subQuarters(new Date(), 1)).getTime(),
                to: endOfQuarter(subQuarters(new Date(), 1)).getTime(),
            };
        },
        getThisYearOption() {
            return {
                from: startOfYear(new Date()).getTime(),
                to: endOfYear(new Date()).getTime(),
            };
        },
        getLastYearOption() {
            return {
                from: startOfYear(subYears(new Date(), 1)).getTime(),
                to: endOfYear(subYears(new Date(), 1)).getTime(),
            };
        },
        updateRangeSuggestion() {
            if (!this.externalChange && this.suggestedRange !== null) {
                this.$emit('update-from', this.suggestedRange.from);
                this.$emit('update-to', this.suggestedRange.to);
            }
        },
        updateFrom(value) {
            this.rangeType = null;

            if (value === null) {
                this.$emit('update-from', null);
            } else {
                this.$emit('update-from', this.type === 'date' ? getTime(startOfDay(value)) : getTime(value));
            }

            if (value !== null && this.to !== null && ensureJSTimestamp(this.to) < value) {
                this.$emit('update-to', null);
            }
        },
        updateTo(value) {
            this.rangeType = null;

            if (value === null) {
                this.$emit('update-to', null);
            } else {
                this.$emit('update-to', this.type === 'date' ? getTime(endOfDay(value)) : getTime(value));
            }
        },
        getMinToTimestamp() {
            if (this.from) {
                return ensureJSTimestamp(this.from);
            }

            if (this.minToTimestamp) {
                return ensureJSTimestamp(this.minToTimestamp);
            }

            return null;
        },
        ensureJSTimestamp,
    },
};
</script>

<style lang="scss">
.time-range-fieldset__grid {
    width: 100%;
}

.time-range-fieldset__text-field {
    cursor: pointer;

    input,
    label {
        cursor: pointer;
    }
}
</style>

<template>
    <span>
        <template v-if="!hasIconField(navItem)">
            <div v-if="navItem.completed" class="progress__check">
                <CheckMark />
            </div>
            <div v-else class="progress__circle" />
        </template>
        <template v-else>
            <div class="progress__icon">
                <component :is="navItem.icon" />
            </div>
        </template>
    </span>
</template>

<script>
import CheckMark from '@/assets/icons/regular/checkmark.svg';
import PlatformSettings from '@/assets/icons/platform-settings.svg';

export default {
    name: 'SubNavIcon',
    components: {
        CheckMark,
        PlatformSettings,
    },
    props: {
        navItem: {
            type: Object,
            required: true,
        },
    },
    methods: {
        hasIconField(navItem) {
            return navItem?.icon !== undefined;
        },
    },
};
</script>

<style lang="scss" scoped>
.progress {
    &__check {
        color: $color-success;
    }

    &__check,
    &__icon {
        width: 24px;
    }

    &__icon {
        height: 24px;
    }

    &__circle {
        border-radius: 50%;
        border: 1px solid $color-darkGrey;
        height: 20px;
        margin-right: 4px;
        width: 20px;
    }
}
</style>

<template>
    <div class="step-indicator">
        <div :class="{ 'step-indicator__icon--active': step === 'loading' }" class="step-indicator__icon">
            <LoadingIcon width="14" />
        </div>
        <Words :muted="step !== 'loading'" bold>
            {{ $t('pages.checkout.components.stepIndicator.loading') }}
        </Words>
        <div class="step-indicator__separator" />
        <Words :muted="step !== 'unloading'" bold>
            {{ $t('pages.checkout.components.stepIndicator.unloading') }}
        </Words>
        <div :class="{ 'step-indicator__icon--active': step === 'unloading' }" class="step-indicator__icon">
            <UnloadingIcon width="14" />
        </div>
    </div>
</template>

<script>
import Words from '@/components/Typography/Words';

import LoadingIcon from '@/assets/icons/loading_site.svg';
import UnloadingIcon from '@/assets/icons/unloading_site.svg';

export default {
    name: 'StepIndicator',
    components: {
        Words,

        LoadingIcon,
        UnloadingIcon,
    },
    props: {
        step: {
            type: String,
            default: null,
            validator: v => ['loading', 'unloading'].includes(v),
        },
    },
};
</script>

<style lang="scss">
.step-indicator {
    display: grid;
    grid-template-columns: 58px auto 1fr auto 58px;
    grid-column-gap: 10px;
    align-items: center;
}

.step-indicator__icon {
    position: relative;
    width: 58px;
    height: 58px;
    border: 3px solid $color-lightGrey;
    display: flex;
    justify-content: center;
    border-radius: 50px;
    align-items: center;
    box-shadow: 0 0 0 2px $color-littleDarkerThanMediumGrey;
}

.step-indicator__icon--active {
    background-color: $color-red;

    svg {
        .s-base {
            stroke: $color-white;
        }
    }
}

.step-indicator__separator {
    width: 100%;
    height: 2px;
    background-color: $color-littleDarkerThanMediumGrey;
}
</style>

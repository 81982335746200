import gsap from 'gsap';
import GoogleMaps, { createMap, createMapMarker } from '@/services/GoogleMaps';
import { normalizeLocation } from '@/services/utils/map';

import FlagIconPath from '@/assets/icons/flag.svg?external';

const ZOOM_ALL_MAX = 18;
const ZOOM_CENTER_LOCATION = 9;
const ZOOM_INITIAL = 9;
const ZOOM_CONSTRUCTION_SITE_FOCUS = 15;

export default {
    methods: {
        async createInitialMap() {
            if (this.map !== null) {
                return this.map;
            }

            const google = await GoogleMaps;

            this.map = createMap(google, 'map', {
                zoom: ZOOM_INITIAL,
                center: { lat: 52.02377828289762, lng: 8.533637725680705 },
                disableDefaultUI: true,
                mapTypeId: this.mapType,
            });
        },

        async centerAllPoints() {
            const map = await this.createMap();
            const google = await GoogleMaps;

            if (this.selectedSupplier) {
                this.updateSupplierPinById(this.selectedSupplier.supplier.factoryId, false);
                this.selectedSupplier = null;
            }

            const bounds = new google.maps.LatLngBounds();
            Object.keys(this.suppliersSet).forEach(factoryId => {
                const supplierSet = this.suppliersSet[factoryId];
                bounds.extend(supplierSet.marker.position);
            });

            if (this.destinationMarker) {
                bounds.extend(this.destinationMarker.getPosition());
            }

            map.fitBounds(bounds);
            if (map.getZoom() > ZOOM_ALL_MAX) {
                map.setZoom(ZOOM_INITIAL);
            }

            return true;
        },

        toggleSupplierDetail(status = null) {
            const $detail = document.getElementById('supplier-view-page__detail-view');
            if (status === null) {
                status = $detail.style.height === '0px';
            }

            if (status) {
                gsap.set($detail, { height: 'auto' });
                gsap.from($detail, { duration: 0.3, height: 0 });
            } else {
                gsap.to($detail, { duration: 0.2, height: 0 });
            }
        },

        async centerLocationOnMap({ lat, lng }, zoom = ZOOM_CENTER_LOCATION) {
            const map = await this.createMap();

            if (zoom) {
                map.setZoom(zoom);
            }
            map.panTo({ lat, lng });
        },

        async updateDestination(location = null, zoom = false) {
            // remove destination marker if no location given
            if (location === null) {
                if (this.destinationMarker !== null) {
                    this.destinationMarker.setMap(null);
                    this.destinationMarker = null;
                }
                return;
            }

            const map = await this.createMap();

            if (this.destinationMarker === null) {
                this.destinationMarker = await createMapMarker(FlagIconPath, location, map);

                this.destinationMarker.addListener('click', () => {
                    this.centerLocationOnMap(
                        normalizeLocation(this.destinationMarker.getPosition()),
                        ZOOM_CONSTRUCTION_SITE_FOCUS
                    );
                });

                // focus initially
                if (zoom) {
                    this.centerLocationOnMap(normalizeLocation(this.destinationMarker.getPosition()), zoom);
                }
            }

            this.destinationMarker.setPosition(location);
        },
    },
};

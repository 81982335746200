import GlobalVariablesApi from '@/services/Api/GlobalVariables';
import store from '@/store';

/**
 * Helper service for handling global variables
 */
class GlobalVariables {
    /**
     * Get a single value or return fallback
     * @param {*} key
     * @param {*} fallback
     */
    async get(key, fallback = null) {
        if (!store.getters['abilities/can']('viewGlobalVariable', { globalVariableId: key })) {
            return fallback;
        }

        try {
            const data = await GlobalVariablesApi.getById(key);
            return data.value ?? data.defaultValue;
        } catch (err) {
            return fallback;
        }
    }
}

export default new GlobalVariables();

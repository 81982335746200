import { RouteSource } from '@/_components/Map/Source/RouteSource';
import Map from '@/_components/Map/Map';
import { LngLatBounds } from 'maplibre-gl';
function calcBounds(coords) {
    /**
     * Lat, Lng
     */
    let maxN, maxS, maxE, maxW;
    for (let i = 0; i < coords.length; i++) {
        // 0: lng (longitude)
        // 1: lat (latitude)
        if (maxN == undefined) {
            maxN = coords[i][1];
        }
        if (maxS == undefined) {
            maxS = coords[i][1];
        }
        if (maxE == undefined) {
            maxE = coords[i][0];
        }
        if (maxW == undefined) {
            maxW = coords[i][0];
        }
        if (coords[i][1] <= maxN) {
            maxN = coords[i][1];
        }
        if (coords[i][1] >= maxS) {
            maxS = coords[i][1];
        }
        if (coords[i][0] >= maxE) {
            maxE = coords[i][0];
        }
        if (coords[i][0] <= maxW) {
            maxW = coords[i][0];
        }
    }
    // Check if maxW, maxS, maxE, and maxN are defined and are numbers
    if (!(typeof maxW === 'number' && typeof maxS === 'number' && typeof maxE === 'number' && typeof maxN === 'number')) {
        throw new Error('Invalid coordinates: Some values are undefined or not numbers');
    }
    return new LngLatBounds({ lng: maxW, lat: maxS }, { lng: maxE, lat: maxN });
}
function calcBoundsByRoutes(routes) {
    const coords = routes.flat();
    return calcBounds(coords);
}
const RouteMap = ({ routes }) => {
    const mapProps = {};
    const hasAnyRouteCoordinates = routes.length > 0 && routes.some(route => route.length > 0);
    // WHY? Because if we don't have any coordinates, we can't calculate the map positioning
    if (hasAnyRouteCoordinates) {
        mapProps.bounds = calcBoundsByRoutes(routes);
    }
    return (<Map {...mapProps}>
            <>
                {routes.map((route, index) => (<RouteSource key={index} coordinates={route}/>))}
            </>
        </Map>);
};
export default RouteMap;

import { FullscreenControl, Map as ReactMap, NavigationControl } from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';
const Map = ({ bounds, children }) => {
    const initialViewState = {};
    if (bounds) {
        initialViewState.bounds = bounds;
    }
    return (<ReactMap 
    // See https://ui.docs.amplify.aws/react/connected-components/geo#mapview
    initialViewState={initialViewState} style={{ height: '100%', width: '100%' }} mapStyle={`https://maps.geo.${window.mapConfigTransportCostCalculator.region}.amazonaws.com/maps/v0/maps/${window.mapConfigTransportCostCalculator.mapName}/style-descriptor?key=${window.mapConfigTransportCostCalculator.apiKey}`}>
            {children}
            <NavigationControl />
            <FullscreenControl />
        </ReactMap>);
};
export default Map;

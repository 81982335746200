<template>
    <div
        :class="{
            'draggable-iconized-toggle-switch--active': value,
            'draggable-iconized-toggle-switch--disabled': disabled,
        }"
        class="draggable-iconized-toggle-switch"
        @click.stop="!disabled && $emit('input', !value)"
    >
        <div class="draggable-iconized-toggle-switch__track">
            <div class="draggable-iconized-toggle-switch__icon-track">
                <div class="draggable-iconized-toggle-switch__icon-wrapper">
                    <slot name="inactive" />
                </div>
                <div class="draggable-iconized-toggle-switch__icon-wrapper">
                    <slot name="active" />
                </div>
            </div>
            <DraggableContainer
                :value="!value"
                :animation-speed="0.9"
                :disabled="disabled"
                horizontal
                @input="handleInput(!$event)"
            >
                <div class="draggable-iconized-toggle-switch__orb">
                    <div v-if="value" class="draggable-iconized-toggle-switch__orb-inner">
                        <slot name="active" />
                    </div>
                    <div v-else class="draggable-iconized-toggle-switch__orb-inner">
                        <slot name="inactive" />
                    </div>
                </div>
            </DraggableContainer>
        </div>
    </div>
</template>

<script>
import DraggableContainer from '@/components/Draggable/DraggableContainer';

export default {
    name: 'IconizedToggleSwitch',
    components: {
        DraggableContainer,
    },
    props: {
        name: {
            type: String,
            default: null,
        },
        value: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            eid: `el${this._uid}`,
        };
    },
    methods: {
        handleInput(value) {
            if (value !== this.value) {
                this.$emit('input', value);
            }
        },
    },
};
</script>

<style lang="scss">
.draggable-iconized-toggle-switch {
    min-height: 37px;

    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;

    width: 90px;
    height: 37px;
    border-radius: 22px;
    transition:
        border 0.2s ease-in,
        background-color 0.2s ease-in;

    border: 1px solid $color-lightMediumGrey;
    background-color: $color-lightMediumGrey;
}

.draggable-iconized-toggle-switch__track {
    position: relative;
    margin: 1px;
    width: 86px;
    height: 33px;
}

.draggable-iconized-toggle-switch--disabled {
    background-color: $color-littleDarkerThanMediumGrey;
    border-color: $color-littleDarkerThanMediumGrey;
    opacity: 0.5;

    .draggable-iconized-toggle-switch__orb {
        background-color: $color-grey;
        box-shadow: none;
    }
}

.draggable-iconized-toggle-switch__orb {
    background-color: $color-white;
    width: 43px;
    height: 33px;
    border-radius: 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.draggable-iconized-toggle-switch__icon-track {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 9px 5px 10px;
}

.draggable-iconized-toggle-switch__orb-inner {
    width: 24px;
    height: 24px;

    svg {
        rect,
        path {
            fill: $color-red;
        }
    }
}

.draggable-iconized-toggle-switch__icon-wrapper {
    width: 24px;
    height: 24px;

    svg {
        rect,
        path {
            fill: $color-grey;
        }
    }
}
</style>

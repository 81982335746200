import AbstractResource from '../AbstractResource';

class UserLink extends AbstractResource {
    async getLinksForUser(id) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/${id}`);
            return response.data.links;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async addLink(originUserId, targetUserId) {
        try {
            const response = await this.apiService.post(this.resourcePath, {
                originUser: { id: originUserId },
                targetUser: { id: targetUserId },
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async deleteLink(originUserId, targetUserId) {
        try {
            const response = await this.apiService.delete(`${this.resourcePath}/${originUserId}/${targetUserId}`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new UserLink('/administration/user/link');

/**
 * @param {array} sortSetList
 * @param {array} supportedSorts
 * @return {*}
 */
export function getWhitelistedSortSetOptions(sortSetList, supportedSorts) {
    if (!sortSetList || !supportedSorts) {
        return [];
    }

    return sortSetList.filter(sortingKey => {
        const sort = sortingKey.split('__');
        return supportedSorts.includes(sort[0]);
    });
}

/**
 * Join sort parts to sort set string
 *
 * @param {string} sortBy
 * @param {string} sortDirection
 * @return {string}
 */
export function joinSortSet(sortBy, sortDirection) {
    return [sortBy, sortDirection].join('__');
}

/**
 * Split sort set string into parts
 * @param sortSet
 * @return {{sortDirection: *, sortBy: *}}
 */
export function splitSortSet(sortSet) {
    const sort = sortSet.split('__');
    return {
        sortBy: sort[0],
        sortDirection: sort[1],
    };
}

<template>
    <div class="user-link-tile">
        <div class="user-link-tile__header">
            <Words bold block small class="user-link-tile__username">
                <Words v-if="!noNotifications && hasNotifications" red>●&nbsp;</Words>
                {{ user.username }}
            </Words>
            <div v-if="!noDelete" @click.stop="$emit('delete', userLink)">
                <SfIconButton size="md" :has-background="false">
                    <template #icon="iconProps"><SfDeleteIcon v-bind="iconProps" /></template>
                </SfIconButton>
            </div>
        </div>
        <div class="user-link-tile__content">
            <Avatar :src="user.image && user.image.url" class="user-link-tile__image" size="small" />
            <div class="user-link-tile__info">
                <Words block small spaced-bottom>{{ user.firstName }} {{ user.lastName }}</Words>
                <Words block small>{{ user.organization.name }} ({{ user.organization.customerNumber }})</Words>
                <Words block small muted>
                    <FormattedAddress :address="userLink.address" />
                </Words>
            </div>
        </div>
    </div>
</template>

<script>
import Avatar from '@/components/Avatar';
import FormattedAddress from '@/components/FormattedAddress';
import Words from '@/components/Typography/Words';

import { SfDeleteIcon, SfIconButton } from '@schuettflix/vue-components';

export default {
    name: 'UserLinkTile',
    components: {
        FormattedAddress,
        Words,
        Avatar,
        SfDeleteIcon,
        SfIconButton,
    },
    props: {
        userLink: {
            type: Object,
            required: true,
        },
        noDelete: {
            type: Boolean,
            default: false,
        },
        noNotifications: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        user() {
            return this.userLink.targetUser;
        },
        hasNotifications() {
            return this.userLink.notificationAppInboxCount > 0;
        },
    },
};
</script>

<style lang="scss">
.user-link-tile__header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.user-link-tile__content {
    display: grid;
    grid-template-columns: 48px 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 15px;
}
.user-link-tile__info {
    .formatted-address {
        font-size: 12px;
        line-height: 14px;
    }
}
</style>

async function getCurrentLocation() {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(position => {
            const { latitude, longitude } = position.coords;
            resolve({ lat: latitude, lng: longitude });
        }, error => {
            reject(error);
        });
    });
}
export const WebGeolocationProvider = {
    getCurrentLocation,
};

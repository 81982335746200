import { Clipboard } from '@capacitor/clipboard';
import LogService from '@schuettflix/util-log';
import Toaster from '@/services/Toaster';
const Log = new LogService('services/Clipboard/NativeClipboard');
class NativeClipboard {
    successMessage;
    errorMessage;
    /**
     * Create Native Clipboard view
     * @param {string} successMessage Message shown on success
     * @param {string} errorMessage  Message shown on error
     */
    constructor(successMessage, errorMessage) {
        this.successMessage = String(successMessage);
        this.errorMessage = String(errorMessage);
    }
    /**
     * Copy to Clipboard
     * @param {string} type clipboard copy type: 'string', 'image', 'url' or 'label'. Image must be in Data URL format to copy.
     * @param {string} value value (typeof `string`)
     * @returns {void}
     */
    async writeToClipboard(type, value) {
        try {
            await Clipboard.write({
                [`${type}`]: value,
            });
            Toaster.success(this.successMessage);
        }
        catch (error) {
            Log.log(`Error writing to clipboard (type: ${type}): ${JSON.stringify(error)}`);
            Toaster.error(this.errorMessage);
            throw error;
        }
    }
    /**
     * Read from Clipboard
     * @returns {ReadResult}
     */
    async readFromClipboard() {
        const { type, value } = await Clipboard.read();
        return { type, value };
    }
}
export default NativeClipboard;

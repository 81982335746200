<template>
    <div :id="`${eid}-stage-parent`" class="draw-field">
        <div :id="`${eid}-stage`" class="draw-field__stage" />
    </div>
</template>

<script>
import Konva from 'konva';
import ResizeObserver from 'resize-observer-polyfill';
import { dataURItoBlob } from '@/services/utils';

// andoird is laggy on 2x
const PIXEL_RATIO = 1;

export default {
    name: 'DrawField',
    props: {
        value: {
            type: Blob,
            default: null,
        },
        width: {
            type: [Number, String],
            default: 300,
        },
        height: {
            type: [Number, String],
            default: 300,
        },
    },
    data() {
        return {
            eid: `el${this._uid}`,
            isPaint: false,
            lastPointerPosition: null,
            drawInit: false,
            drawImage: null,
            resizeObserver: null,
        };
    },
    watch: {
        value(newVal, oldVal) {
            if (newVal !== oldVal && newVal === null) {
                this.clear();
            }
        },
        height(value) {
            if (!this.stage) return;
            if (value && value > 1) {
                this.rescaleStage();
            }
        },
        width(value) {
            if (!this.stage) return;
            if (value && value > 1) {
                this.rescaleStage();
            }
        },
    },
    beforeDestroy() {
        this.resizeObserver && this.resizeObserver.disconnect();
    },
    mounted() {
        this.resizeObserver = new ResizeObserver(() => {
            this.rescaleStage();
            this.resize();
        });
        this.resizeObserver.observe(this.$el);

        this.setupEditor();
    },
    methods: {
        setupEditor() {
            Konva.pixelRatio = window.devicePixelRatio * PIXEL_RATIO;

            this.stage = new Konva.Stage({
                container: `${this.eid}-stage`,
                width: parseInt(this.width),
                height: parseInt(this.height),
            });

            this.rescaleStage();

            this.drawLayer = new Konva.Layer();
            this.stage.add(this.drawLayer);

            this.draw();
        },

        rescaleStage() {
            const stageParent = document.getElementById(`${this.eid}-stage-parent`);

            const stageWidth = parseInt(this.width);
            const stageHeight = parseInt(this.height);
            const scale = stageParent.offsetWidth / stageWidth;

            this.stage.width(stageWidth * scale);
            this.stage.height(stageHeight * scale);
            this.stage.draw();
        },

        clear() {
            this.drawLayer.destroyChildren();
            this.drawContext.clearRect(0, 0, this.drawCanvas.width, this.drawCanvas.height);
            this.drawContext.beginPath();
            this.stage.draw();
            this.draw();
            this.$emit('input', null);
        },

        resize() {
            this.unbindDrawEvents();
            this.clear();
            this.draw();
        },

        draw() {
            this.drawLayer.moveToTop();
            this.drawCanvas = document.createElement('canvas');
            this.drawCanvas.width = this.stage.width();
            this.drawCanvas.height = this.stage.height();

            this.drawImage = new Konva.Image({
                image: this.drawCanvas,
                width: this.stage.width(),
                height: this.stage.height(),
                fill: '#fff',
            });

            this.drawLayer.add(this.drawImage);
            this.stage.draw();

            this.drawContext = this.drawCanvas.getContext('2d');
            this.drawContext.strokeStyle = '#000';
            this.drawContext.lineJoin = 'round';
            this.drawContext.lineWidth = 5;

            this.isPaint = false;
            this.lastPointerPosition = null;

            this.bindDrawEvents();
        },

        bindDrawEvents() {
            this.stage.addEventListener('mousedown touchstart', this.onDrawMousedown.bind(this));
            this.stage.addEventListener('mouseup mouseleave touchend', this.onDrawMouseup.bind(this));
            this.stage.addEventListener('mousemove touchmove', this.onDrawMousemove.bind(this));
        },

        unbindDrawEvents() {
            this.stage.removeEventListener('mousedown touchstart', this.onDrawMousedown.bind(this));
            this.stage.removeEventListener('mouseup mouseleave touchend', this.onDrawMouseup.bind(this));
            this.stage.removeEventListener('mousemove touchmove', this.onDrawMousemove.bind(this));
        },

        onDrawMousedown() {
            this.isPaint = true;
            this.lastPointerPosition = this.stage.getPointerPosition();
            this.drawCircleOnCurrentPosition();
        },

        onDrawMouseup() {
            this.isPaint = false;
            this.save();
            this.lastPointerPosition = null;
        },

        onDrawMousemove() {
            if (!this.isPaint) {
                return;
            }

            this.drawContext.globalCompositeOperation = 'source-over';
            this.drawContext.beginPath();

            let localPos = {
                x: this.lastPointerPosition.x - this.drawImage.x(),
                y: this.lastPointerPosition.y - this.drawImage.y(),
            };

            this.drawContext.moveTo(localPos.x, localPos.y);
            const pos = this.stage.getPointerPosition();
            localPos = {
                x: pos.x - this.drawImage.x(),
                y: pos.y - this.drawImage.y(),
            };

            this.drawContext.lineTo(localPos.x, localPos.y);
            this.drawContext.closePath();
            this.drawContext.stroke();

            this.lastPointerPosition = pos;
            this.drawLayer.batchDraw();
        },
        drawCircleOnCurrentPosition() {
            const currPos = this.stage.getPointerPosition();

            this.drawLayer.add(
                new Konva.Ellipse({
                    x: currPos.x - this.drawImage.x(),
                    y: currPos.y - this.drawImage.y(),
                    width: 5,
                    height: 5,
                    fill: 'black',
                })
            );

            this.stage.draw();
        },

        save() {
            if (this.lastPointerPosition === null) {
                return;
            }

            const imageDataURL = this.stage.toDataURL({
                mimeType: 'image/jpeg',
                quality: 1,
                pixelRatio: window.devicePixelRatio * PIXEL_RATIO,
            });

            const file = dataURItoBlob(imageDataURL);

            this.$emit('input', file);
        },
    },
};
</script>

<style lang="scss">
.draw-field__stage {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;
}

.draw-field__stage {
    overflow: hidden;

    > * {
        width: 100%;
        height: 0;
        padding-top: 50%;
    }
}
</style>

import Transport from '@/services/Api/Transport';
import { unref } from 'vue';
export function useCameraAppUsed({ transportId: transportIdMaybeRef }) {
    const onCameraAppUsage = (type) => {
        const transportId = unref(transportIdMaybeRef);
        if (!type)
            return;
        // actually the context for this api call is not relevant,
        // so we just pass any valid context here ('platform')
        new Transport('platform').recordCameraAppUsed({
            type,
            transportId: transportId,
        });
    };
    return {
        onCameraAppUsage,
    };
}

<template>
    <div class="show-more">
        <div
            ref="content"
            :class="{
                'show-more__content--visible': isVisible,
            }"
            class="show-more__content"
            :style="`--line-clamp: ${lines};`"
        >
            <slot />
        </div>
        <Button v-if="isTruncated || isVisible" :dark="dark" class="show-more__button" @click="toggle()">
            <component
                :is="isVisible ? 'MinusIcon' : 'PlusIcon'"
                slot="left"
                width="14"
                height="14"
                :class="{
                    'icon--white': dark,
                }"
                class="icon--inline"
            />
            {{ isVisible ? $t('components.ShowMoreText.labelLessText') : $t('components.ShowMoreText.labelMoreText') }}
        </Button>
    </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill';
import Button from '@/components/Button/Button';

import PlusIcon from '@/assets/icons/micro/plus.svg';
import MinusIcon from '@/assets/icons/micro/minus.svg';

export default {
    name: 'ShowMore',
    components: {
        Button,

        PlusIcon,
        MinusIcon,
    },
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
        lines: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            isTruncated: false,
            isVisible: false,
        };
    },
    updated() {
        this.calculateTruncated();
    },
    mounted() {
        this.calculateTruncated();

        this.resizeObserver = new ResizeObserver(() => {
            this.calculateTruncated();
        });
        this.resizeObserver.observe(this.$el);
    },
    beforeDestroy() {
        this.resizeObserver && this.resizeObserver.disconnect();
    },
    methods: {
        calculateTruncated() {
            if (!this.$refs.content) return;
            const { offsetWidth, scrollWidth, offsetHeight, scrollHeight } = this.$refs.content;
            this.isTruncated = offsetWidth < scrollWidth || offsetHeight < scrollHeight;
        },

        toggle() {
            this.isVisible = !this.isVisible;
        },
    },
};
</script>

<style scoped lang="scss">
.show-more__content {
    display: -webkit-box;
    -webkit-line-clamp: var(--line-clamp, 1);
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    > * {
        display: block;
    }
}

.show-more__content + .show-more__button {
    margin-top: 10px;
}

.show-more__content--visible {
    display: block;
}
</style>

<template>
    <GridRow :count="2" spacing="tiny" class="span-4 inline md:grid">
        <RadioButtonCard
            name="payment-methods"
            :value="PAYMENT_METHODS.INVOICE"
            radio-data-test="invoice-radio"
            :model-value="organization.paymentInfo.method"
            :title="$t('components.organizationForm.paymentInfo.paymentMethod.invoice.radioButtonTitle')"
            :is-disabled="readonly"
            @change="updatePaymentMethod($event)"
        />

        <RadioButtonCard
            name="payment-methods"
            class="mt-4 md:mt-0"
            :value="PAYMENT_METHODS.DIRECT_DEBIT"
            radio-data-test="direct-debit-radio"
            :model-value="organization.paymentInfo.method"
            :is-disabled="!isGlobalVariableDirectDebitActive || readonly"
            :title="$t('components.organizationForm.paymentInfo.paymentMethod.directDebit.radioButtonTitle')"
            @change="updatePaymentMethod($event)"
        />
    </GridRow>
</template>

<script>
import RadioButtonCard from '@/_components/RadioButtonCard/RadioButtonCard';
import _cloneDeep from 'lodash/cloneDeep';
import { isDirectDebit } from '@/services/utils/organization';
import _isEqual from 'lodash/isEqual';
import GridRow from '@/components/Layout/GridRow';

import { PAYMENT_METHODS } from '@/constants/paymentMethods';

export default {
    name: 'PaymentMethodChoice',
    components: { GridRow, RadioButtonCard },
    props: {
        organization: {
            type: Object,
            default: null,
        },
        originalOrganization: {
            type: Object,
            default: null,
        },
        isGlobalVariableDirectDebitActive: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            updatedOrganization: null,
        };
    },
    computed: {
        isDirectDebit() {
            return isDirectDebit(this.organization);
        },
        PAYMENT_METHODS() {
            return PAYMENT_METHODS;
        },
    },
    methods: {
        updatePaymentMethod(newPaymentMethod) {
            this.updatedOrganization = _cloneDeep(this.organization);
            this.updatedOrganization.paymentInfo.method = newPaymentMethod;
            this.$emit('block-save-by-modal', {
                modalRefToTrigger: 'returnToInvoiceModalRef',
                blocked: this.isReturningToInvoice(),
            });
            this.$emit('update-organization', this.updatedOrganization);
        },
        isReturningToInvoice() {
            if (!isDirectDebit(this.originalOrganization) || isDirectDebit(this.updatedOrganization)) {
                return false;
            }
            return !_isEqual(this.originalOrganization.paymentInfo.method, this.updatedOrganization.paymentInfo.method);
        },
    },
};
</script>

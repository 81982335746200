<template>
    <button
        :class="isActiveByEvent || active ? 'font-copy-sm-strong' : 'font-copy-sm'"
        class="p-4"
        @click="handleClick"
    >
        <slot />
    </button>
</template>

<script>
import _get from 'lodash/get';

export default {
    name: 'TabNavigationItem',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        stage: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isActiveByEvent: this.active,
        };
    },
    watch: {
        active(isActive) {
            isActive && this.handleClick();
        },
    },
    created() {
        if (this.stage != null) {
            this.$on('change-stage', stage => {
                this.isActiveByEvent = stage === this.stage;
            });
        }

        // parent suggested a stage change
        this.$on('trigger-stage', (stage = null) => {
            if (this.stage !== stage) {
                return;
            }

            this.handleClick();
        });
    },
    mounted() {
        setTimeout(() => {
            this.active && this.handleClick();
        }, 200);
    },
    methods: {
        handleClick(e) {
            this.$parent.$emit('select-stage', this.getStage(), this.$el);
            if (e) {
                this.$emit('click', e);
            }
        },
        getStage() {
            if (this.stage != null) {
                return this.stage;
            }

            return _get(this.$slots, 'default[0].text');
        },
    },
};
</script>

<template>
    <div class="color__grid" data-test="color-select">
        <div
            v-for="(color, index) in availableColors"
            :key="color.name"
            class="color__card"
            :class="{ 'color__card--disabled': color.disabled }"
            @click="onClick(color)"
        >
            <button
                class="color__swatch"
                :class="{ 'color__swatch--selected': isColorSelected(color.value) }"
                :style="`background-color: ${color.color}`"
                :data-test="`color-select-option-${index}`"
            />
            <div class="color__name">
                {{ color.label }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        colors: {
            type: Array,
            default: () => [],
        },
        disablePreselection: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            availableColors: this.colors,
        };
    },
    mounted() {
        if (!this.disablePreselection) {
            return;
        }

        this.availableColors = this.colors.map(color => {
            return {
                ...color,
                disabled: this.isColorSelected(color.value),
            };
        });
    },
    methods: {
        isColorSelected(color) {
            return this.value?.includes(color);
        },

        onClick(color) {
            if (color.disabled) {
                return;
            }

            const value = this.value ? [...this.value] : [];

            if (this.isColorSelected(color.value)) {
                const index = value.indexOf(color.value);
                value.splice(index, 1);
            } else {
                value.push(color.value);
            }
            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss" scoped>
.color {
    &__grid {
        display: flex;
        flex-wrap: wrap;
    }
    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(720px / 7);
        padding: 1rem 2rem;
    }
    &__swatch {
        width: 56px;
        height: 56px;
        border: none;
        cursor: pointer;
        border-radius: 50%;
        margin-bottom: 1rem;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);

        &--selected {
            border: 2px solid #e5e5e5;
            outline: 2px solid #35343d;
        }
    }

    &__card {
        &--disabled {
            .color__name {
                color: gray;
            }
            .color__swatch {
                cursor: initial;
                outline-color: gray;
            }
        }
    }
}
</style>

import AbstractFilterableResource from '../AbstractFilterableResource';

class SupplierList extends AbstractFilterableResource {
    async filterSupplierInfos(
        params = {},
        facetFilterKey = null,
        facetFilterValues = [],
        cancelSource = null,
        forcedParams = {},
        silence = false
    ) {
        return this.filterEndpoint(
            this.resourcePath,
            params,
            facetFilterKey,
            facetFilterValues,
            cancelSource,
            forcedParams,
            silence
        );
    }
}

export default new SupplierList('/supplier/list');

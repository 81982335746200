<template>
    <div :class="`default-attribute-renderer--level-${nestingLevel}`" class="default-attribute-renderer">
        <Words v-if="nestingLevel === 0" block bold spaced-bottom>
            {{ translateAttributeName(attributeOption.value) }}
        </Words>
        <Words v-if="hasAttributeDescription(attributeOption.value)" block spaced-bottom>
            {{ translateAttributeDescription(attributeOption.value) }}
        </Words>
        <GridRow :count="columns" margin-less>
            <div
                v-for="option in attributeOption.options"
                :key="option.identifier"
                :class="`span-md-1 span-${columns}`"
            >
                <CheckboxCard
                    v-if="attributeOption.isMultiple"
                    :model-value="scopedValue"
                    :title="translateAttributeName(option.value)"
                    :value="option.value"
                    @change="$emit('change', option)"
                />
                <RadioButtonCard
                    v-else
                    :key="option.identifier"
                    :model-value="scopedValue"
                    :title="translateAttributeName(option.value)"
                    :value="option.value"
                    :name="attributeOption.value"
                    :class="`span-md-1 span-${columns}`"
                    @change="$emit('change', option)"
                />
                <UnavailableAlert v-if="isNotPurchasableAttribute(purchasableAttributes, option.value)" />
            </div>
        </GridRow>
    </div>
</template>

<script>
import {
    translateAttributeName,
    hasAttributeDescription,
    translateAttributeDescription,
    isNotPurchasableAttribute,
} from '@/services/AttributeManager/utils';

import CheckboxCard from '@/_components/CheckboxCard/CheckboxCard';
import GridRow from '@/components/Layout/GridRow';
import RadioButtonCard from '@/_components/RadioButtonCard/RadioButtonCard';
import Words from '@/components/Typography/Words';
import UnavailableAlert from '@/pages/Vehicle/components/UnavailableAlert';

export default {
    name: 'DefaultAttributeRenderer',
    components: {
        GridRow,
        RadioButtonCard,
        CheckboxCard,
        UnavailableAlert,
        Words,
    },
    props: {
        scopedValue: {
            type: [Array, String],
            default: null,
        },
        purchasableAttributes: {
            type: Array,
            default: null,
        },
        attributeOption: {
            type: Object,
            default: null,
        },
        nestingLevel: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        columns() {
            const count = Object.keys(this.attributeOption?.options).length;
            return Math.min(count, 3);
        },
    },
    methods: {
        translateAttributeName,
        hasAttributeDescription,
        translateAttributeDescription,
        isNotPurchasableAttribute,
    },
};
</script>

<style scoped></style>

<template>
    <LayoutPage :child-routes="['playground__filterable-listing', 'playground__geolocation']">
        <div slot="pageTitle">Playground</div>

        <h1 class="font-headline-lg-strong">It is a long established fact</h1>
        <p class="font-copy-sm mb-4">
            It is a long established fact that a reader will be distracted by the readable content of a page when
            looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of
            letters, as opposed to using 'Content here, content here', making it look like readable English. Many
            desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a
            search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved
            over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
        </p>

        <SfMessageBox class="mb-4">
            The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to
            using 'Content here, content here', making it look like readable English. Many desktop publishing packages
            and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will
            uncover many web sites still in their infancy.
        </SfMessageBox>
        <SfMessageBox class="mb-4" type="warning">
            The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to
            using 'Content here, content here', making it look like readable English. Many desktop publishing packages
            and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will
            uncover many web sites still in their infancy.
        </SfMessageBox>
        <SfMessageBox class="mb-4" type="success">
            The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to
            using 'Content here, content here', making it look like readable English. Many desktop publishing packages
            and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will
            uncover many web sites still in their infancy.
        </SfMessageBox>
        <SfMessageBox class="mb-4" type="error">
            The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to
            using 'Content here, content here', making it look like readable English. Many desktop publishing packages
            and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will
            uncover many web sites still in their infancy.
        </SfMessageBox>
    </LayoutPage>
</template>

<script>
import { SfMessageBox } from '@schuettflix/vue-components';
import LayoutPage from '@/components/Layout/Page.v2';

export default {
    name: 'PlaygroundPage',
    components: {
        LayoutPage,
        SfMessageBox,
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss">
.playground {
    padding: 40px;
    max-width: 1800px;
}

.playground-wizard {
    max-width: 900px;
    margin: auto;
}
</style>

import { Avatar } from '@schuettflix/react-components';
import { AvatarList } from '@schuettflix/react-components';
import { FavoriteStar } from '@/constructionProjects/components/FavoriteStar';
import { MapMarkerIcon } from '@/constructionProjects/components/icons/MapMarkerIcon';
import { constructionProjectByIdQuery, constructionProjectCountsQuery } from '@/constructionProjects/queries';
import { formatAddress } from '@/constructionProjects/utils/formatAddress';
import { useRouteParams } from '@/reactBridge/useRouteParams';
import { useQueries, useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import React from 'react';
import { formatDateRange } from '@/services/utils/date';
import { Button, Tag } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
import DocumentsIcon from '@/constructionProjects/components/icons/DocumentsIcon';
import store from '@/store';
import { ConstructionProjectStatus } from '@/constructionProjects/api/projects';
import { CONSTRUCTION_PROJECT_CLIENT_SETTINGS_ROUTE, CONSTRUCTION_PROJECT_DETAILS_ROUTE, CONSTRUCTION_PROJECT_DOCUMENTS_ROUTE, CONSTRUCTION_PROJECT_POSITIONS_ROUTE, DAYS_LEFT_NOTIFICATION_THRESHOLD, } from '@/constructionProjects/constants';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { MenuTile } from '@/constructionProjects/components/MenuTile';
import { useAbility } from '@/reactBridge/useAbility';
import { ProjectsIcon } from '@/constructionProjects/components/icons/ProjectsIcon';
import { assembleQueryFilter } from '@/plugins/mixins/persistentFiltersMixin';
import { MaterialIcon } from '@/constructionProjects/components/icons/MaterialIcon';
import { VehicleIcon } from '@/constructionProjects/components/icons/VehicleIcon';
import { PdfIcon } from '@/constructionProjects/components/icons/PdfIcon';
import { SettingsIcon } from '@/constructionProjects/components/icons/SettingsIcon';
import { useRoute } from '@/reactBridge/useRoute';
import { calculateValidityInDays } from '@/constructionProjects/utils/validity';
import { WarningSection } from '@/constructionProjects/pages/ConstructionProjectDetails/WarningSection';
const Person = ({ profileImageUrl, firstName, lastName, role }) => {
    return (<div className="flex items-center gap-4">
            <Avatar className="h-12 w-12" firstName={firstName} lastName={lastName} imageUrl={profileImageUrl}/>
            <div className="flex flex-col gap-2">
                <p className="font-copy-sm text-subdued">{role}</p>
                <p className="font-copy-md-strong text">{`${firstName} ${lastName}`}</p>
            </div>
        </div>);
};
const PeopleSection = () => {
    const { id } = useRouteParams();
    const { t } = useTranslation();
    const constructionProject = useQuery({
        ...constructionProjectByIdQuery(id),
        suspense: true,
    });
    if (!constructionProject.data) {
        return null;
    }
    const responsiblePlatformAdmin = constructionProject.data.responsibleEmployee;
    const responseibleUser = constructionProject.data.responsibleUser;
    const teamMembers = constructionProject.data.teamMembers;
    return (<section className="-mb-4 flex items-center justify-end gap-12">
            <Person profileImageUrl={responsiblePlatformAdmin.images?.thumbnail} firstName={responsiblePlatformAdmin.firstName} lastName={responsiblePlatformAdmin.lastName} role={t('pages.constructionProject.detailsPage.responsibleEmployee.title')}/>
            <Person profileImageUrl={responseibleUser.images?.thumbnail} firstName={responseibleUser.firstName} lastName={responseibleUser.lastName} role={t('pages.constructionProject.detailsPage.responsiblePerson.title')}/>
            {teamMembers.length ? <AvatarList maxShown={4} users={teamMembers}/> : null}
        </section>);
};
const DetailsSection = () => {
    const { id } = useRouteParams();
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const canSubmitProjectOrder = useAbility('createProjectPositionQuote');
    const canCreateDeliveryQuote = useAbility('createDeliveryQuote');
    const canCreateShipmentQuote = useAbility('createShipmentQuote');
    const canCreatePickupQuote = useAbility('createPickupQuote');
    const canOrder = canSubmitProjectOrder || canCreateDeliveryQuote || canCreateShipmentQuote || canCreatePickupQuote;
    const constructionProject = useQuery({
        ...constructionProjectByIdQuery(id),
        suspense: true,
    });
    if (!constructionProject.data) {
        return null;
    }
    const isProjectActive = constructionProject.data.status === ConstructionProjectStatus.Active;
    const validityRange = formatDateRange(new Date(constructionProject.data.validFrom).getTime(), new Date(constructionProject.data.validTo).getTime());
    const daysLeft = calculateValidityInDays(constructionProject.data.validTo);
    const showWarning = daysLeft > 0 && daysLeft <= DAYS_LEFT_NOTIFICATION_THRESHOLD;
    const handleOrderButtonClicked = async () => {
        store.commit('constructionProject/setConstructionProjectId', id);
        store.commit('constructionProject/setEntryPoint', { name: CONSTRUCTION_PROJECT_DETAILS_ROUTE, params: { id } });
        await vueRouter.push({ name: 'order__checkout' });
    };
    return (<section className="space-y-6">
            <div className="card min-h-[200px] rounded p-6 shadow-flat">
                <div className="flex items-start justify-between">
                    <span className="font-headline-md-strong text">{constructionProject.data.name}</span>
                    <FavoriteStar activeClass="fill-icon-accent" className="flex-shrink-0 -translate-y-2 translate-x-2"/>
                </div>
                <div className="font-copy-md grid h-[80px] grid-cols-[16px_minmax(0,_1fr)] items-start gap-2 pb-6 pt-2 leading-4">
                    {constructionProject.data.description && (<>
                            <DocumentsIcon size="xs"/>
                            <p>{constructionProject.data.description}</p>
                        </>)}
                    <MapMarkerIcon />
                    <p>{formatAddress(constructionProject.data)}</p>
                </div>
                <div className="flex gap-12">
                    <div className="space-y-2">
                        <p className="font-copy-sm text-subdued">
                            {t('pages.constructionProject.detailsPage.costField.title')}
                        </p>
                        <p className="font-copy-md-strong text">{constructionProject.data.costCenter || '-'}</p>
                    </div>
                    <div className="space-y-2">
                        <p className="font-copy-sm text-subdued">
                            {t('pages.constructionProject.detailsPage.validityField.title')}
                        </p>
                        <p className="font-copy-md-strong text">
                            <span>{validityRange}</span>
                            {showWarning && (<Tag className="ml-2" type="warning" label={t('pages.constructionProject.projectCard.expirationWarning', {
                count: daysLeft,
            })}/>)}
                        </p>
                    </div>
                </div>
            </div>
            <Button data-test="construction-project-details-page-order-button" label={t('pages.constructionProject.details.button.placeOrder')} className="float-right rounded" size="sm" onClick={handleOrderButtonClicked} disabled={!canOrder || !isProjectActive}/>
        </section>);
};
const LinksSection = () => {
    const route = useRoute();
    const { id } = useRouteParams();
    const { t } = useTranslation();
    const canListProjectPositions = useAbility('listProjectPositions');
    const canListTransports = useAbility('listClientTransports');
    const canListOrders = useAbility('listOrders');
    const [counts, constructionProject] = useQueries({
        queries: [
            {
                ...constructionProjectCountsQuery(id),
                suspense: true,
            },
            {
                ...constructionProjectByIdQuery(id),
                suspense: true,
            },
        ],
    });
    if (!counts.data || !constructionProject.data) {
        return null;
    }
    const parentRoute = {
        name: route.name,
        params: route.params,
        backTitle: constructionProject.data.name,
    };
    return (<div className="grid grid-cols-[repeat(auto-fit,_minmax(368px,_1fr))]">
            {canListProjectPositions && (<MenuTile className="card rounded shadow-low hover:shadow-high" dataTest="construction-project-details-page-positions-link" to={{
                name: CONSTRUCTION_PROJECT_POSITIONS_ROUTE,
                params: { id },
            }} icon={ProjectsIcon} label={t('pages.constructionProject.details.links.positionsLabel')} description={t('pages.constructionProject.details.links.positionsDescription', {
                count: counts.data.projectPositionCount,
            })}/>)}
            {canListOrders && (<MenuTile className="card rounded shadow-low hover:shadow-high" dataTest="construction-project-details-page-orders-link" to={{
                name: 'order__order-list',
                query: {
                    parentRoute: JSON.stringify(parentRoute),
                    order: assembleQueryFilter({
                        constructionProject: id,
                        status: ['in_progress', 'new'],
                    }),
                    quote: assembleQueryFilter({
                        constructionProject: id,
                    }),
                },
            }} icon={MaterialIcon} label={t('pages.constructionProject.details.links.ordersLabel')} description={t('pages.constructionProject.details.links.ordersDescription', {
                count: counts.data.orderCount,
            })}/>)}
            {canListTransports && (<MenuTile className="card rounded shadow-low hover:shadow-high" dataTest="construction-project-details-page-transports-link" to={{
                name: 'order__transport-list',
                query: {
                    parentRoute: JSON.stringify(parentRoute),
                    transport: assembleQueryFilter({
                        constructionProject: id,
                    }),
                },
            }} icon={VehicleIcon} label={t('pages.constructionProject.details.links.transportsLabel')} description={t('pages.constructionProject.details.links.transportsDescription', {
                count: counts.data.transportCount,
            })}/>)}
            <MenuTile className="card rounded shadow-low hover:shadow-high" dataTest="construction-project-details-page-documents-link" to={{ name: CONSTRUCTION_PROJECT_DOCUMENTS_ROUTE, params: { id } }} icon={PdfIcon} label={t('pages.constructionProject.details.links.documentsLabel')}/>
            <MenuTile className="card rounded shadow-low hover:shadow-high" dataTest="construction-project-details-page-project-settings-link" to={{
            name: `${CONSTRUCTION_PROJECT_DETAILS_ROUTE}__${CONSTRUCTION_PROJECT_CLIENT_SETTINGS_ROUTE}`,
            params: { id },
        }} icon={() => <SettingsIcon multicolor size={'sm'}/>} label={t('pages.constructionProject.details.button.projectSettings.title')} description={t('pages.constructionProject.details.button.projectSettings.subtitle')}/>
        </div>);
};
const Footer = () => {
    const { t } = useTranslation();
    const { id } = useRouteParams();
    const { data: projectCode } = useQuery({
        ...constructionProjectByIdQuery(id),
        suspense: true,
        select: data => data?.projectCode,
    });
    if (!projectCode) {
        return null;
    }
    return (<footer className="font-numbers-md flex flex-grow items-end justify-center text-center text-subdued">
            {t('pages.constructionProject.details.footer.projectCode', {
            code: projectCode,
        })}
        </footer>);
};
export const ConstructionProjectDetailsDesktop = ({ className }) => {
    return (<div className={clsx('flex flex-grow flex-col gap-12 overflow-auto p-6', className)}>
            <PeopleSection />
            <DetailsSection />
            <WarningSection />
            <LinksSection />
            <Footer />
        </div>);
};

<template>
    <LayoutPage is-flyout screen-name="notifications" @close="$parent.$emit('close')">
        <div slot="pageTitle">{{ $t('pages.notifications.title') }}</div>

        <div class="notifications">
            <LoadingSpinner v-if="!notificationsResult" block dark />

            <transition name="fade">
                <div
                    v-if="notificationsResult && notificationsResult.count > 0"
                    data-test="notification-list"
                    class="notifications__content"
                >
                    <List spaced class="notifications__list">
                        <NotificationItem
                            v-for="(notification, i) in notificationsResult.items"
                            :key="i"
                            :notification="notification"
                            :has-action="hasAction(notification)"
                            data-test="notification-item"
                            @click="goTo(notification)"
                        />
                    </List>

                    <div
                        v-if="notificationsResult.count - notificationsResult.items.length > 0"
                        class="container-deprecated"
                    >
                        <MoreResultsButton :result="notificationsResult" />
                    </div>
                </div>

                <div v-if="notificationsResult && notificationsResult.count === 0" class="notifications__content">
                    <Tile background-transparent>
                        <Words>{{ $t('pages.notifications.noResults') }}</Words>
                    </Tile>
                </div>
            </transition>
        </div>
    </LayoutPage>
</template>

<script>
import NotificationApi from '@/services/Api/Notification';
import DeepLinkService from '@/services/Notification/DeepLinkService';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';

import LayoutPage from '@/components/Layout/Page.v2';
import Words from '@/components/Typography/Words';
import Tile from '@/components/Layout/Tile';
import List from '@/components/List/List';
import NotificationItem from './components/NotificationItem';
import LoadingSpinner from '@/components/LoadingSpinner';
import MoreResultsButton from '@/components/Filter/MoreResultsButton';

export default {
    name: 'NotificationPage',
    components: {
        LayoutPage,
        Words,
        Tile,
        List,
        NotificationItem,
        LoadingSpinner,
        MoreResultsButton,
    },
    mixins: [eventHubMixin],
    data() {
        return {
            notificationsResult: null,
        };
    },
    created() {
        NotificationApi.filter().then(result => {
            this.notificationsResult = result;

            NotificationApi.markRead();
        });
    },
    methods: {
        goTo(notification) {
            this.$router.push(DeepLinkService.getRouteFromMessage(notification)).catch(() => {});
            this.$eventHub.$emit('page.notifications', false);
        },

        hasAction(notification) {
            if (notification.type === 'broadcast') return false;
            return DeepLinkService.hasMessageRoute(notification);
        },
    },
};
</script>

<style lang="scss">
.notifications {
    margin-bottom: 20px;
}

.notifications__list {
    padding: 20px 10px;
}
</style>

<template>
    <div
        :class="{
            'image-upload-editor--shadow': shadow,
            'image-upload-editor--inline': inline,
        }"
        class="image-upload-editor"
    >
        <ErrorMessage v-if="fileError" :message="fileError" class="span-4" style="margin-top: 1em" />
        <div v-if="value" class="image-upload-editor__results">
            <Thumbnail :src="value.url" class="image-upload-editor__thumbnail" modal />
            <BaseButton
                v-if="!readonly"
                arrow-right
                class="image-upload-editor__change-button"
                data-test="button-image-change"
                @click="changeUploadedImage"
            >
                {{ changeLabel || $t('pages.checkout.additionalInformation.components.changeImage') }}
            </BaseButton>
            <BaseButton v-if="!readonly" class="image-upload-editor__delete-button" @click="deleteUploadedImage">
                <DeleteIcon />
            </BaseButton>
        </div>
        <div :class="{ 'image-upload-editor__button-hidden': value || readonly }" class="image-upload-editor__button">
            <div class="image-upload-editor__button-inner">
                <ImageUpload
                    ref="imageUploader"
                    :value="value"
                    :light="light"
                    :disable-upload="editor"
                    :product-use-case="productUseCase"
                    :label="newLabel"
                    @error="handleUploadError"
                    @input="handleFileSelect"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { watch, getCurrentInstance } from 'vue';
import { useImageUpload } from '@/services/useClientFile';
import Toaster from '@/services/Toaster';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';

import ImageUpload from '@/components/Form/ImageUpload';
import Thumbnail from '@/components/Thumbnail';
import BaseButton from '@/components/Button/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';

import DeleteIcon from '@/assets/icons/regular/garbage.svg';

export default {
    name: 'ImageUploadTile',
    components: {
        ImageUpload,
        Thumbnail,
        BaseButton,
        ErrorMessage,
        DeleteIcon,
    },
    mixins: [eventHubMixin],
    props: {
        value: {
            type: Object,
            default: null,
        },
        shadow: {
            type: Boolean,
            default: false,
        },
        light: {
            type: Boolean,
            default: false,
        },
        editor: {
            type: Boolean,
            default: false,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        newLabel: {
            type: String,
            default: null,
        },
        changeLabel: {
            type: String,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        productUseCase: {
            type: String,
            default: null,
        },
    },
    setup(props, { emit }) {
        const { uploadImage, onEverySuccess, onEveryError, progress, uploadedImageData } = useImageUpload();

        const instance = getCurrentInstance().proxy;

        onEverySuccess(data => {
            emit('input', data);
            instance.$eventHub.$emit('imageEditorClose');
        });

        onEveryError(err => {
            emit('error', err);
            instance.$eventHub.$emit('imageEditorClose');
            Toaster.error(instance.$t(err));
        });

        watch(progress, value => instance.$eventHub.$emit('imageEditorUploadProgress', value));

        const upload = async file => {
            if (props.readonly) return;
            await uploadImage({
                file,
                meta: {
                    productUseCase: props.productUseCase,
                },
            });
            instance.$eventHub.$emit('imageEditorUploadProgress', 0);
        };

        return {
            upload,
            uploadedImageData,
        };
    },
    data() {
        return {
            fileError: null,
        };
    },
    created() {
        this.subscribe('image-editor-upload', file => {
            this.upload(file);
        });

        this.subscribe('imageEditorClosed', () => {
            this.$refs.imageUploader.resetInput();
        });
    },
    methods: {
        handleFileSelect(file) {
            if (!file || this.readonly) {
                return;
            }

            if (this.editor) {
                this.$eventHub.$emit('image-editor-open', file);
            } else {
                this.$emit('input', file);
            }
        },

        changeUploadedImage() {
            if (this.readonly) return;
            this.$refs.imageUploader.triggerFileSelect();
            this.fileError = null;
        },

        deleteUploadedImage() {
            if (this.readonly) return;
            this.$emit('input', null);
            this.fileError = null;
        },

        handleUploadError(error) {
            this.fileError = error;
        },
    },
};
</script>

<style lang="scss"></style>

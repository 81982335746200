<template>
    <Flyout
        v-if="isActionAvailable"
        :active="isOpen"
        :headline="$t(`pages.tagManagement.tagManagementActions.headline.${isEdit ? 'edit' : 'new'}`)"
        size="small"
        @closed="closedFlyout()"
    >
        <Tile>
            <GridRow :count="1">
                <TextField
                    v-for="lang in availableLanguageCodes"
                    :key="lang"
                    v-model="tag.text[lang]"
                    :label="`${$t('pages.tagManagement.tagManagementActions.textLabel')} ${lang.toUpperCase()}`"
                    :error="getError(`tag.text.${lang}`)"
                    @input="clearSingleValidation(`tag.text.${lang}`)"
                />
            </GridRow>
        </Tile>

        <ButtonGroup slot="bottom">
            <span v-if="isEdit" class="span--place-left" @click="deleteTag">
                <span class="button__slot button__slot--left">
                    <GarbageIcon slot="left" class="icon--inline" />
                </span>
                <span v-if="$root.isDesktop">
                    {{ $t('pages.tagManagement.tagManagementActions.actions.delete') }}
                </span>
            </span>

            <Button :disabled="isPending" primary light @click="isOpen = false">
                {{ $t('pages.tagManagement.tagManagementActions.actions.back') }}
            </Button>
            <Button :disabled="invalidForm || isPending" primary @click="handleSubmission">
                {{ $t(`pages.tagManagement.tagManagementActions.actions.${isEdit ? 'save' : 'add'}`) }}
            </Button>
        </ButtonGroup>
    </Flyout>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { availableLanguageCodes } from '@/i18n/languages';
import { isRequired } from '@/services/validation/rules';
import { localize } from '@/services/utils/localization';
import PubSubEvent from '@/services/PubSub/PubSubEvent';
import TagApi from '@/services/Api/Platform/Tag';
import Toaster from '@/services/Toaster';
import validate from '@/services/validation/mixin';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Flyout from '@/components/Layout/Flyout';
import GridRow from '@/components/Layout/GridRow';
import TextField from '@/components/Form/TextField.v2';
import Tile from '@/components/Layout/Tile';

import GarbageIcon from '@/assets/icons/regular/garbage.svg';

export default {
    name: 'TagManagementAction',
    components: {
        Button,
        ButtonGroup,
        Flyout,
        GridRow,
        TextField,
        Tile,

        GarbageIcon,
    },
    mixins: [validate, eventHubMixin],
    data() {
        return {
            isOpen: false,
            isEdit: false,
            isPending: false,
            tag: null,
            availableLanguageCodes,
            validationRules: {
                'tag.text.de': [isRequired()],
            },
        };
    },
    computed: {
        isActionAvailable() {
            return this.$can('createTag') || this.$can('updateTag');
        },
        invalidForm() {
            return !(this.tag && this.tag.text && this.tag.text.de);
        },
    },
    created() {
        this.reset();

        this.subscribe('tag.actions.newTag.open', text => {
            this.reset();
            if (text) {
                this.tag.text.de = text;
            }
            this.isEdit = false;
            this.isOpen = true;
        });
        this.subscribe('tag.actions.editTag.open', tag => {
            this.reset();
            this.isEdit = true;
            this.tag = _cloneDeep(tag);
            this.isOpen = true;
        });
    },
    methods: {
        reset() {
            this.clearValidation();
            this.tag = {
                text: localize(null),
            };
        },

        closedFlyout() {
            this.isOpen = false;
            this.$emit('closed');
        },

        async handleSubmission() {
            if (this.isPending || !this.isValid()) return;
            this.isPending = true;
            try {
                const tag = await TagApi.save(this.tag);
                const eventName = this.isEdit ? 'tag.update' : 'tag.new';
                this.$eventHub.$emit(eventName, new PubSubEvent(tag));
                this.closedFlyout();
                Toaster.success(
                    this.$t(
                        `pages.tagManagement.tagManagementActions.successMessage.${this.isEdit ? 'updated' : 'added'}`,
                        {
                            name: tag.text.de,
                        }
                    )
                );
            } catch (err) {
                const errorMapping = {};
                this.availableLocales.forEach(locale => {
                    errorMapping[`tag.text.${locale}`] = `text.${locale}`;
                });
                this.parseServerValidation(_get(err, 'response.data.errors', {}), errorMapping);
            }

            this.isPending = false;
        },

        async deleteTag() {
            if (this.isPending || !this.isValid()) return;
            this.isPending = true;

            try {
                const tag = await TagApi.delete(this.tag.id);
                this.$eventHub.$emit('tag.delete', new PubSubEvent(tag));
                this.closedFlyout();
                Toaster.success(this.$t('pages.tagManagement.tagManagementActions.successMessage.deleted'));
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(err.message);
            }

            this.isPending = false;
        },
    },
};
</script>

<style lang="scss"></style>

<template>
    <div>
        <GridRow class="organization-form__fieldset" :count="1">
            <Words block bold small middle spaced-bottom-small>
                <span>
                    {{
                        $t(
                            selectCreditNoteDocKey({
                                creditNote: `components.organizationForm.emailPreferences.invoiceInterval.headline.creditNote`,
                                selfBilling: `components.organizationForm.emailPreferences.invoiceInterval.headline.selfBilling`,
                            })
                        )
                    }}
                </span>
                <template v-if="!isSchuettflixOrganization">
                    &nbsp;&nbsp;
                    <BaseButton @click="openModal('emailPreferencesInvoiceIntervalInfo')">
                        <InfoIcon class="icon--inline" width="18" height="18" />
                    </BaseButton>
                </template>
            </Words>

            <GridRow v-if="!isSchuettflixOrganization" :count="1" margin-less>
                <MultiselectBox
                    v-if="organizationData"
                    v-model="organizationData.invoiceEmailInterval"
                    :options="invoiceEmailIntervalOptions"
                    :option-value-renderer="option => option"
                    :label="$t('components.organizationForm.emailPreferences.invoiceIntervalSelectLabel')"
                    class="organization-form__multiselect"
                    disable-reset
                >
                    <DefaultInner
                        slot="button"
                        slot-scope="buttonScope"
                        mode="button"
                        :scope="buttonScope"
                        :label-renderer="option => $t(`pages.organization.view.value.emailInterval.${option}`)"
                    />

                    <DefaultInner
                        slot="option"
                        slot-scope="optionScope"
                        mode="option"
                        :scope="optionScope"
                        :label-renderer="option => $t(`pages.organization.view.value.emailInterval.${option}`)"
                    />
                </MultiselectBox>
            </GridRow>

            <GridRow :count="1" margin-less>
                <EmailListFieldset
                    v-model="organizationData.invoiceRecipientEmails"
                    :min-list-length="1"
                    :error="errors.invoiceRecipientEmails"
                    data-test="invoice-recipient"
                />

                <CheckboxField v-model="organizationData.emailReceiptAsZip">
                    {{ $t('components.organizationForm.emailPreferences.emailReceiptAsZip') }}
                </CheckboxField>

                <CheckboxField v-model="organizationData.emailDeliveryNotesAttachedToReceipt">
                    {{
                        $t(
                            selectCreditNoteDocKey({
                                creditNote: `components.organizationForm.emailPreferences.emailDeliveryNotesAttachedToReceipt-2.creditNote`,
                                selfBilling: `components.organizationForm.emailPreferences.emailDeliveryNotesAttachedToReceipt-2.selfBilling`,
                            })
                        )
                    }}
                </CheckboxField>
            </GridRow>
        </GridRow>

        <GridRow class="organization-form__fieldset" :count="1">
            <Words block bold small middle spaced-bottom-small>
                {{ $t('components.organizationForm.emailPreferences.deliveryNote') }}
                <template v-if="!isSchuettflixOrganization">
                    &nbsp;&nbsp;
                    <BaseButton @click="openModal('emailPreferencesDeliveryNoteInfo')">
                        <InfoIcon class="icon--inline" width="18" height="18" />
                    </BaseButton>
                </template>
            </Words>

            <GridRow :count="1" spacing="none" margin-less>
                <MultiselectBox
                    v-if="organization"
                    v-model="organizationData.deliveryNoteEmailInterval"
                    :options="EMAIL_INTERVAL_OPTIONS"
                    :option-value-renderer="option => option"
                    :label="$t('components.organizationForm.emailPreferences.deliveryNoteSelectLabel')"
                    class="organization-form__multiselect"
                    disable-reset
                >
                    <DefaultInner
                        slot="button"
                        slot-scope="buttonScope"
                        mode="button"
                        :scope="buttonScope"
                        :label-renderer="option => $t(`pages.organization.view.value.emailInterval.${option}`)"
                    />

                    <DefaultInner
                        slot="option"
                        slot-scope="optionScope"
                        mode="option"
                        :scope="optionScope"
                        :label-renderer="option => $t(`pages.organization.view.value.emailInterval.${option}`)"
                    />
                </MultiselectBox>
            </GridRow>

            <GridRow :count="1" margin-less>
                <EmailListFieldset
                    v-model="organizationData.deliveryNoteRecipientEmails"
                    :min-list-length="1"
                    :error="errors.deliveryNoteRecipientEmails"
                    data-test="delivery-note-recipient"
                />

                <CheckboxField v-model="organizationData.emailDeliveryNoteAsZip">
                    {{ $t('components.organizationForm.emailPreferences.emailDeliveryNoteAsZip') }}
                </CheckboxField>
            </GridRow>
        </GridRow>

        <ModalBox
            ref="emailPreferencesInvoiceIntervalInfo"
            :headline="
                $t(
                    selectCreditNoteDocKey({
                        creditNote: `components.organizationForm.emailPreferences.invoiceInterval.info.headline.creditNote`,
                        selfBilling: `components.organizationForm.emailPreferences.invoiceInterval.info.headline.selfBilling`,
                    })
                )
            "
        >
            {{
                $t(
                    selectCreditNoteDocKey({
                        creditNote: `components.organizationForm.emailPreferences.invoiceInterval.info.description.creditNote`,
                        selfBilling: `components.organizationForm.emailPreferences.invoiceInterval.info.description.selfBilling`,
                    })
                ) +
                '\n\n' +
                $t('components.modalChangedInvoiceInterval.description')
            }}
        </ModalBox>

        <ModalBox
            ref="emailPreferencesDeliveryNoteInfo"
            :headline="$t('components.organizationForm.emailPreferences.deliveryNoteInfoHeadline')"
        >
            {{ $t('components.organizationForm.emailPreferences.deliveryNoteInfoDescription') }}
        </ModalBox>

        <ModalChangedInvoiceInterval
            v-if="showModalChangedInvoiceInterval"
            :old-value="$t(`pages.organization.view.value.emailInterval.${oldInterval}`)"
            :new-value="$t(`pages.organization.view.value.emailInterval.${organization.invoiceEmailInterval}`)"
            @close="() => (showModalChangedInvoiceInterval = false)"
        />
    </div>
</template>

<script>
import { getStateMapping } from '@/services/StateMapping';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';

import BaseButton from '@/components/Button/Button';
import CheckboxField from '@/components/Form/CheckboxField';
import DefaultInner from '@/components/Form/MultiselectBox/DefaultInner.v2';
import EmailListFieldset from '@/components/Form/EmailListFieldset';
import GridRow from '@/components/Layout/GridRow';
import ModalBox from '@/components/Modal/ModalBox';
import MultiselectBox from '@/components/Form/MultiselectBox';
import Words from '@/components/Typography/Words';
import ModalChangedInvoiceInterval from './ModalChangedInvoiceInterval';

import InfoIcon from '@/assets/icons/regular/info.svg';
import { ref, watch, computed } from 'vue';

const DIRECT_EMAIL_INTERVAL_OPTION = 'directly';
const NEVER_EMAIL_INTERVAL_OPTION = 'never';
const EMAIL_INTERVAL_OPTIONS = [
    DIRECT_EMAIL_INTERVAL_OPTION,
    'daily',
    'weekly',
    'biweekly',
    NEVER_EMAIL_INTERVAL_OPTION,
];

export default {
    name: 'TabEmail',

    components: {
        BaseButton,
        CheckboxField,
        DefaultInner,
        EmailListFieldset,
        GridRow,
        ModalBox,
        MultiselectBox,
        Words,
        ModalChangedInvoiceInterval,
        InfoIcon,
    },

    mixins: [],

    props: {
        organization: {
            type: Object,
            default: () => ({}),
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Object,
            default: () => ({
                invoiceRecipientEmails: false,
                deliveryNoteRecipientEmails: false,
            }),
        },
    },

    setup(props, { emit }) {
        const { selectCreditNoteDocKey } = useLegalTerms();

        const requiresDirectInvoiceEmailInterval = ({ emailDeliveryNotesAttachedToReceipt, emailReceiptAsZip }) =>
            emailDeliveryNotesAttachedToReceipt && !emailReceiptAsZip;

        const invoiceEmailIntervalOptions = computed(() => {
            return [DIRECT_EMAIL_INTERVAL_OPTION, NEVER_EMAIL_INTERVAL_OPTION];
        });

        const organization = ref(props.organization);
        const oldInterval = ref(null);
        const showModalChangedInvoiceInterval = ref(false);
        watch(props.organization, value => {
            organization.value = value;
        });

        watch(
            organization,
            () => {
                if (
                    requiresDirectInvoiceEmailInterval(organization.value) &&
                    !invoiceEmailIntervalOptions.value.includes(organization.value.invoiceEmailInterval)
                ) {
                    oldInterval.value = organization.value.invoiceEmailInterval;
                    organization.value.invoiceEmailInterval = DIRECT_EMAIL_INTERVAL_OPTION;
                    showModalChangedInvoiceInterval.value = true;
                }
                emit('update:organization', organization.value);
            },
            { deep: true }
        );

        return {
            showModalChangedInvoiceInterval,
            organizationData: organization,
            invoiceEmailIntervalOptions,
            selectCreditNoteDocKey,
            oldInterval,
        };
    },

    data() {
        return {
            EMAIL_INTERVAL_OPTIONS,
        };
    },

    computed: {
        stateMapping: getStateMapping,

        isSchuettflixOrganization() {
            return this.organization.types.includes('platform');
        },
    },

    methods: {
        openModal(refName) {
            this.$refs[refName].$emit('open');
        },

        closeModal(refName) {
            this.$refs[refName].$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.organization-form__fieldset {
    & + & {
        margin-top: 60px;
    }
}
</style>

<template>
    <a
        class="button-link"
        :href="link"
        :target="targetBlank ? '_blank' : false"
        :class="buttonClasses"
        @click="$emit('click', $event)"
    >
        <span data-test="title">{{ title }}</span>
        <span v-if="hasArrowRight" data-test="arrow-right" class="button-link__arrow-right">›</span>
    </a>
</template>

<script>
export default {
    name: 'ButtonLink',
    props: {
        alignRight: {
            type: Boolean,
            default: false,
        },
        link: {
            type: [String, Boolean],
            default: false,
        },
        targetBlank: {
            type: Boolean,
            default: false,
        },
        hasArrowRight: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
        spacedBottom: {
            type: Boolean,
            default: false,
        },
        spacedTop: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        buttonClasses() {
            return {
                'button-link--align-right': this.alignRight,
                'button-link--spaced-bottom': this.spacedBottom,
                'button-link--spaced-top': this.spacedTop,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.button-link {
    color: $color-darkGrey;
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;

    &--align-right {
        justify-content: flex-end;
    }

    &--spaced-bottom {
        margin-bottom: 10px;
    }

    &--spaced-top {
        margin-top: 10px;
    }

    &__arrow-right {
        margin-left: 6px;
    }

    &[href] {
        text-decoration: none;
    }
}
</style>

import LogService from '@schuettflix/util-log';

import _transform from 'lodash/transform';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import _isNull from 'lodash/isNull';
import { userLocale } from '@/sflxAppsSharedStates';

const Log = new LogService('services/utils');

// list of german phone prefixes with 2 or three digits
// extracted from https://www.bundesnetzagentur.de/SharedDocs/Downloads/DE/Sachgebiete/Telekommunikation/Unternehmen_Institutionen/Nummerierung/Rufnummern/ONRufnr/Vorwahlverzeichnis_ONB.zip.zip;jsessionid=D5650366807D234B82507461DB67A983?__blob=publicationFile&v=246
// cut -d';' -f 1  NVONB.INTERNET.20210505.ONB.csv  | awk '{if ($1>=1 && $1<=1000) print $1}' | xargs | sed -e 's/ /,/g'
const germanPhonePrefixes =
    '201,202,203,208,209,211,212,214,221,228,231,234,241,251,261,271,281,291,30,331,335,340,341,345,351,355,361,365,371,375,381,385,391,395,40,421,431,441,451,461,471,481,491,511,521,531,541,551,561,571,581,591,611,621,631,641,651,661,671,681,69,711,721,731,741,751,761,771,781,791,811,821,831,841,851,861,871,881,89,906,911,921,931,941,951,961,971,981,991'.split(
        ','
    );

const getPrecision = num => {
    let numAsStr = Number(num).toFixed(10);
    numAsStr = numAsStr.replace(/0+$/g, '');

    return String(numAsStr).replace('.', '').length - Number(num).toFixed().length;
};

// Workaround helper method for iOS push notification parsing
export function parseIfJSON(str) {
    try {
        return JSON.parse(str);
    } catch (e) {
        return str;
    }
}

// Workaround helper method for iOS push notification parsing
export function iterateParse(obj) {
    Object.keys(obj).forEach(property => {
        obj[property] = parseIfJSON(obj[property]);
        if (typeof obj[property] === 'object') {
            iterateParse(obj[property]);
        }
    });

    return obj;
}

export function dataURItoBlob(dataURI) {
    let bytestring;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        bytestring = atob(dataURI.split(',')[1]);
    } else {
        bytestring = unescape(dataURI.split(',')[1]);
    }

    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(bytestring.length);
    for (let i = 0; i < bytestring.length; i++) {
        ia[i] = bytestring.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}

export function preciseFloat(value, precision = 2) {
    return parseFloat(parseFloat(value).toFixed(precision));
}

export function preciseFloor(value, precision = 2) {
    const modifier = 10 ** precision;
    const roundedValue = Math.floor(parseFloat(value) * modifier) / modifier;
    return parseFloat(roundedValue.toFixed(precision));
}

export function preciseCeil(value, precision = 2) {
    const modifier = 10 ** precision;
    const roundedValue = Math.ceil(parseFloat(value) * modifier) / modifier;
    return parseFloat(roundedValue.toFixed(precision));
}

export function preciseRound(value, precision = 2) {
    const modifier = 10 ** precision;
    return Math.round(parseFloat(value) * modifier) / modifier;
}

export function convertToPercentage(float, precision = 2) {
    if (!float) {
        return 0;
    }
    return parseFloat((float * 100).toFixed(precision));
}

export function convertToDecimal(percentage, precision = 4) {
    if (!percentage) {
        return 0;
    }
    return parseFloat((percentage / 100).toFixed(precision));
}

export function countDecimals(value) {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1].length || 0;
}

export function isNullableValue(value) {
    const isEmptyString = !value && _isString(value);
    if (isEmptyString || _isNull(value)) return true;
    return false;
}

export function convertedPriceFromTonToKilo(tonValue) {
    if (isNullableValue(tonValue)) return null;
    const precision = getPrecision(tonValue);
    const number = Number(tonValue);

    return (number * Math.pow(10, precision)) / (1000 * Math.pow(10, precision));
}

export function convertedPriceFromKiloToTon(kiloValue) {
    if (isNullableValue(kiloValue)) return null;
    const number = Number(kiloValue);

    return number * 1000;
}

export function convertedWeightFromTonToKilo(tonValue) {
    if (isNullableValue(tonValue)) return null;

    const number = Number(tonValue);

    return number * 1000;
}

export function convertedWeightFromKiloToTon(kiloValue) {
    if (isNullableValue(kiloValue)) return null;
    const precision = getPrecision(kiloValue);
    const number = Number(kiloValue);

    return (number * Math.pow(10, precision)) / (1000 * Math.pow(10, precision));
}

export function assemblePhoneNumber(phoneNumberModel, format = false) {
    if (!phoneNumberModel) {
        Log.warn('utils.js#assemblePhoneNumber: Got no model');
        return '';
    }

    if (!phoneNumberModel.countryCode || !phoneNumberModel.number) {
        return '';
    }

    if (format) {
        // for api compability
        return formatPhoneNumber(phoneNumberModel);
    }
    return `${phoneNumberModel.countryCode}${phoneNumberModel.number}`;
}

export function formatPhoneNumber({ countryCode, number }) {
    let numbers = [];
    let rest = number;

    if (countryCode && countryCode.endsWith('49')) {
        const prefix = germanPhonePrefixes.find(item => number.startsWith(item));
        if (prefix) {
            numbers.push(prefix);
            rest = number.substring(prefix.length);
        } else {
            // assume 4 digits for all non-known phone prefixes
            numbers.push(number.substring(0, 4));
            rest = number.substring(4);
        }
    }
    // split rest of the number in blocks of three digits
    numbers = numbers.concat(rest.match(/\d\d?\d?/g));

    return `${countryCode} ${numbers.join(' ')}`;
}

export function formatCurrency(amount, currencyCode) {
    return new Intl.NumberFormat(userLocale.value, {
        style: 'currency',
        currency: currencyCode,
    }).format(amount);
}

export function formatPercentage(amount) {
    return new Intl.NumberFormat(userLocale.value, {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);
}

export function copyToClipboard(text) {
    // clipboard api supported?
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
    } else {
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    return true;
}

/**
 * IE detection
 * https://codepen.io/gapcode/pen/vEJNZN
 */
export function getIEVersion() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    // other browser
    return 0;
}

export function parseVersion(versionString) {
    const major = parseInt(versionString.split('.')[0]);
    const minor = parseInt(versionString.split('.')[1]);
    const patch = parseInt(versionString.split('.')[2]);
    const normalized = major * 1_000_000 + minor * 1_000 + patch; // 6.71.0 => 6071000
    return {
        number: versionString,
        normalized,
        major,
        minor,
        patch,
    };
}

/**
 * Pause execution on async functions
 * @param {*} ms
 */
export async function asyncDelay(ms) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}

/**
 * Flatten an object
 * https://stackoverflow.com/a/19101235/1704139
 *
 * @param {object} data
 */
export function flatten(data) {
    const result = {};
    function recurse(cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
            let l;
            for (let i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop + '[' + i + ']');
            if (l === 0) result[prop] = [];
        } else {
            let isEmpty = true;
            for (const p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop + '.' + p : p);
            }
            if (isEmpty && prop) result[prop] = {};
        }
    }
    recurse(data, '');
    return result;
}

/**
 * Escape string for Regexp
 * https://github.com/sindresorhus/escape-string-regexp/blob/master/index.js
 * @param {string} string
 */
export function escapeStringRegexp(string) {
    if (typeof string !== 'string') {
        throw new TypeError('Expected a string');
    }

    return string.replace(/[|\\{}()[\]^$+*?.-]/g, '\\$&');
}

/**
 * Create new Deep Mapped Object
 *
 * @param {Object} obj
 * @param {Function} iterator
 * @param {any} context
 */
export function deepMap(obj, iterator, context) {
    return _transform(obj, function (result, val, key) {
        result[key] = _isObject(val) ? deepMap(val, iterator, context) : iterator.call(context, val, key, obj);
    });
}

export function nl2br(str) {
    return str.replace(/\n/g, '<br />');
}

/**
 * Linearly interpolates between a and b by t.
 *
 * @param {number} a The start value
 * @param {number} b The end value
 * @param {number} t The interpolation value between the two numbers 0-1
 * @return {number}
 */
export function lerp(a, b, t) {
    return a * t + b * (1 - t);
}

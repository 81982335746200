<template>
    <div class="tag-select">
        <div class="relative">
            <TextField
                v-model="searchInput"
                data-test="search-input"
                :has-error="hasError"
                :label="label"
                class="tag-select__input"
                :class="{ 'tag-select__input--raised': isOpen }"
                inline-label
                @input="searchTags"
                @keydown.enter.prevent.stop.exact="createTag"
                @keydown.esc="onBlur"
                @keydown.down.prevent="hoverNext"
                @keydown.up.prevent="hoverPrev"
            />
            <ul
                v-if="isOpen"
                ref="suggestions"
                data-test="suggestions"
                class="tag-select__suggestions absolute left-0 top-full m-0 max-h-[312px] w-full list-none overflow-y-auto border-solid border-light-gray-100 bg-white p-0 shadow-flat"
                :class="{ 'bottom-full top-[unset]': positionTop }"
            >
                <template v-for="(tag, index) in preparedSuggestions">
                    <li
                        v-if="!tag.newTag || (tag.newTag && canAddNewTag)"
                        :key="tag.id"
                        class="tag-select__suggestion p-2"
                        :class="{
                            'bg-light-gray-100': hoveredSuggestion === index,
                        }"
                        @mouseover="hoverThis(index)"
                        @click.stop="tag.newTag ? createTag() : selectTag(tag)"
                    >
                        <span v-if="!tag.newTag" data-test="tag">{{ tag.label }}</span>
                        <div v-else class="flex justify-between" data-test="new-tag">
                            <p class="font-copy-md-strong">{{ searchInput }}</p>
                            <p class="font-copy-sm" :class="{ 'tag-select__new-tag--disabled': !canAddNewTag }">
                                {{ $t('pages.tagManagement.tagListFieldset.addTag') }} ›
                            </p>
                        </div>
                    </li>
                </template>
            </ul>
            <div v-show="isOpen" class="tag-select__backdrop" @click="onBlur" />
        </div>
        <div class="mt-2">
            <SfTag
                v-for="tag in tags"
                :key="tag.id"
                data-test="remove-tag"
                :action="true"
                class="mr-2"
                @close-tag="removeTag(tag.id)"
            >
                {{ tag.label }}
            </SfTag>
        </div>
    </div>
</template>

<script>
import { SfTag } from '@schuettflix/vue-components';
import TextField from '@/components/Form/TextField.v2';

export default {
    name: 'TagSelect',
    components: {
        SfTag,
        TextField,
    },
    props: {
        tags: {
            type: Array,
            default: () => [],
        },
        suggestions: {
            type: Array,
            default: () => [],
        },
        newTagMinLength: {
            type: Number,
            default: 3,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            searchInput: '',
            positionTop: false,
            hoveredSuggestion: null,
        };
    },
    computed: {
        isOpen() {
            return !!this.searchInput;
        },

        preparedSuggestions() {
            if (this.suggestions) {
                const found = this.suggestions?.find(suggestion => suggestion.label === this.searchInput);

                if (!found) {
                    return [{ label: this.searchInput, newTag: true }, ...this.suggestions];
                }

                return this.suggestions;
            }

            return null;
        },

        canAddNewTag() {
            return this.searchInput.length >= this.newTagMinLength;
        },
    },
    watch: {
        isOpen(isOpened) {
            if (isOpened) {
                this.$nextTick(this.positionSuggestions);
            }
        },
    },
    methods: {
        searchTags() {
            this.$emit('search', this.searchInput);
        },

        createTag() {
            if (!this.searchInput) return;

            if (this.hasTag(this.searchInput)) {
                this.searchInput = '';
                return;
            }

            this.$emit('add', this.searchInput);
            this.onBlur();
        },

        selectTag(tag) {
            this.$emit('select', tag);
            this.onBlur();
        },

        removeTag(id) {
            this.$emit('remove', id);
        },

        hasTag(tag) {
            return !!this.tags.filter(item => item.label === tag).length;
        },

        onBlur() {
            this.searchInput = '';
            this.hoveredSuggestion = null;
        },

        positionSuggestions() {
            const { suggestions } = this.$refs;
            const { top, height } = suggestions.getBoundingClientRect();
            const bottomFringe = top + height;

            this.positionTop = bottomFringe > window.innerHeight;
        },

        hoverNext() {
            if (!this.isOpen) return;

            if (this.hoveredSuggestion === null) {
                this.hoveredSuggestion = 0;
                this.scrollToTop();
                return;
            }

            if (this.hoveredSuggestion === this.preparedSuggestions.length - 1) {
                this.hoveredSuggestion = 0;
                this.scrollToTop();
                return;
            }

            this.hoveredSuggestion++;
        },

        hoverThis(index) {
            if (!this.isOpen) return;

            this.hoveredSuggestion = index;
        },

        hoverPrev() {
            if (!this.isOpen) return;

            if (this.hoveredSuggestion === null) {
                this.hoveredSuggestion = this.preparedSuggestions.length - 1;
                this.scrollToBottom();
                return;
            }

            if (this.hoveredSuggestion === 0) {
                this.hoveredSuggestion = this.preparedSuggestions.length - 1;
                this.scrollToBottom();
                return;
            }

            this.hoveredSuggestion--;
        },

        scrollToTop() {
            const { suggestions } = this.$refs;
            suggestions.scroll({ top: 0, behavior: 'smooth' });
        },

        scrollToBottom() {
            const { suggestions } = this.$refs;
            suggestions.scroll({ top: suggestions.scrollHeight, behavior: 'smooth' });
        },
    },
};
</script>

<style lang="scss">
.tag-select {
    &__suggestions {
        z-index: 200;
    }

    &__suggestion {
        cursor: pointer;
    }

    &__input {
        &--raised {
            position: relative;
            z-index: 200;
        }
    }

    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background-color: transparent;
    }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layer-controls"},[_c('div',{staticClass:"layer-controls__backdrop",attrs:{"id":_vm.eidBackdrop},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}}),_c('Button',{staticClass:"layer-controls__trigger",attrs:{"id":_vm.eidToggle,"orb":""},on:{"click":function($event){$event.stopPropagation();return _vm.open.apply(null, arguments)}}},[_c('LayerIcon',{attrs:{"width":"24"}})],1),_c('div',{staticClass:"layer-controls__panel",class:{
            'layer-controls__panel--open': _vm.isOpen,
        },attrs:{"id":_vm.eidPane}},[_c('div',{staticClass:"layer-controls__content"},[(_vm.type !== null)?[_c('Headline',{staticClass:"layer-controls__headline",attrs:{"level":6,"block":"","bold":""}},[_vm._v(" "+_vm._s(_vm.$t('components.map.layerControls.mapTypeHeadline'))+" ")]),_vm._l((_vm.mapTypes),function(imgPath,t){return _c('div',{key:`map-type-${t}`,staticClass:"layer-controls__tile",class:{
                        'layer-controls__tile--active': _vm.activeMapType === t,
                        [`layer-controls__tile--${t}`]: true,
                    },on:{"click":function($event){$event.stopPropagation();return _vm.updateMapType(t)}}},[_c('div',{staticClass:"layer-controls__tile-icon",style:(`background-image: url('${imgPath}')`)}),_c('div',[_vm._v(_vm._s(_vm.$t(`components.map.layerControls.mapTypes.${t}`)))])])})]:_vm._e(),(_vm.mode !== null)?[_c('Headline',{staticClass:"layer-controls__headline",attrs:{"level":6,"block":"","bold":""}},[_vm._v(" "+_vm._s(_vm.$t('components.map.layerControls.modeHeadline'))+" ")]),_vm._l((_vm.modes),function(imgPath,m){return _c('div',{key:`mode-${m}`,staticClass:"layer-controls__tile",class:{
                        'layer-controls__tile--active': _vm.activeMode === m,
                        [`layer-controls__tile--${m}`]: true,
                    },on:{"click":function($event){$event.stopPropagation();return _vm.updateMode(m)}}},[_c('div',{staticClass:"layer-controls__tile-icon",style:(`background-image: url('${imgPath}')`)}),_c('div',[_vm._v(_vm._s(_vm.$t(`components.map.layerControls.modes.${m}`)))])])})]:_vm._e()],2),_c('Button',{staticClass:"layer-controls__close-button",on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_c('CloseLayerIcon',{attrs:{"width":"14","height":"14"}})],1)],1)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
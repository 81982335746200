<template>
    <LayoutPage :child-routes="['playground__filterable-listing']">
        <div slot="pageTitle">Playground: Geocoding</div>
        <div class="location-picker-stage">
            <LocationPicker v-model="newLocation" enable-search ensure-state @input-address="newAddress = $event">
                <div slot-scope="{ editLocation }" class="address-picker__address-field" @click="editLocation">
                    <div v-if="newLocation">
                        {{ newLocation }}
                        <br />
                        {{ newAddress }}
                    </div>
                    <div v-else>Select location</div>
                </div>
            </LocationPicker>
        </div>
        <div id="map" class="map"></div>
    </LayoutPage>
</template>

<script>
import { mapGetters } from 'vuex';
import LayoutPage from '@/components/Layout/Page.v2';
import GoogleMaps, { createMap } from '@/services/GoogleMaps';
import _debounce from 'lodash/debounce';
import { retrieveStateByLocation } from '@/services/utils/map';
import LocationPicker from '@/pages/Checkout/components/LocationPicker';

export default {
    name: 'GeocodingPlaygroundPage',
    components: {
        LocationPicker,
        LayoutPage,
    },
    computed: {
        ...mapGetters('platform', ['initialMapLocation']),
    },
    created() {
        this.createMap();
    },
    data() {
        return {
            newAddress: null,
            newLocation: null,
        };
    },
    methods: {
        createMap() {
            const marker = [];

            GoogleMaps.then(google => {
                const map = createMap(google, 'map', {
                    zoom: 11,
                    center: this.initialMapLocation,
                    disableDefaultUI: true,
                });

                map.addListener(
                    'center_changed',
                    _debounce(async () => {
                        this.$logger().log('Detected change: center position');
                        marker.forEach(m => m.setMap(null));

                        const state = await retrieveStateByLocation(map.getCenter(), (l, i, zip) => {
                            console.log('Iteration:', i, zip);
                            marker.push(
                                new google.maps.Marker({
                                    position: l,
                                    map,
                                    zIndex: 10,
                                })
                            );
                        });

                        // const distances  = [0, 500, 1000, 1500, 2000, 2500];
                        // const directions = ['north', 'east', 'south', 'west'];
                        // const centeredLocation = map.getCenter();
                        //
                        // directions.forEach(dir => {
                        //     distances.forEach(dist => {
                        //         const l = moveLocation(centeredLocation, dist, dir);
                        //         marker.push(new google.maps.Marker({
                        //             position: l,
                        //             map,
                        //             zIndex: 10,
                        //         }));
                        //     })
                        // })

                        console.log('STATE', state);

                        this.$logger().log('Updated: center position');
                    }, 1000)
                );
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.map {
    width: 100%;
    height: 100%;
}

.map::after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    background-color: red;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.location-picker-stage {
    margin-bottom: 10px;
}
</style>

<template>
    <Card spaceless no-padding class="table-card" :class="{ 'table-card--inactive': !value.isActive }">
        <Words block class="head">{{ value.title }}</Words>
        <div class="qty">
            <TextField
                v-model.number="value.minimumQuantity"
                :error="getError('value.minimumQuantity')"
                :disabled="!value.isActive"
                is-small-error-message
                tiny
                type="number"
                :precision="4"
                class="tf"
                @input="validate()"
            />
            <Words block class="slash">/</Words>
            <TextField
                v-model.number="value.maximumQuantity"
                :error="getError('value.maximumQuantity')"
                :disabled="!value.isActive"
                is-small-error-message
                tiny
                type="number"
                :precision="4"
                class="tf"
                @input="validate()"
            />
        </div>
        <div class="margin">
            <TextField
                v-model.number="value.productMargin"
                :error="getError('value.productMargin')"
                :disabled="!value.isActive"
                is-small-error-message
                tiny
                type="number"
                :precision="4"
                class="tf"
                @input="validate()"
            />
            <SelectBox
                v-model="value.productMarginType"
                :disabled="!value.isActive"
                class="select"
                tiny
                @input="value.productMargin = null"
            >
                <option v-for="(marginType, i) in productMarginTypes" :key="i" :value="marginType">
                    {{ $t(`pages.productManagement.wasteProductVariantTable.marginTypes.${marginType}`) }}
                </option>
            </SelectBox>
        </div>
        <ToggleSwitch v-model="value.isActive" class="switch" @input="validate()" />
    </Card>
</template>

<script>
import Card from '@/components/Layout/Card';
import Words from '@/components/Typography/Words';
import ToggleSwitch from '@/components/Form/ToggleSwitch';
import TextField from '@/components/Form/TextField.v2';
import SelectBox from '@/components/Form/SelectBox.v2';
import validate from '@/services/validation/mixin';
import { isRequired, isFloat, greaterThan } from '@/services/validation/rules';
import { MARGIN_TYPE_ABSOLUTE, MARGIN_TYPE_PERCENTAGE } from '@/services/Api/Platform/Product';

export default {
    name: 'WasteProductVariantsTableRow',
    components: {
        Card,
        TextField,
        ToggleSwitch,
        SelectBox,
        Words,
    },
    mixins: [validate],
    props: {
        value: {
            type: Object,
            required: true,
        },
        validationId: {
            type: String,
            required: true,
        },
        stateKey: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            validationRules: {
                'value.minimumQuantity': [isRequired(), isFloat()],
                'value.maximumQuantity': [isRequired(), isFloat(), greaterThan('value.minimumQuantity')],
                'value.productMargin': [isRequired(), isFloat()],
            },
        };
    },
    computed: {
        productMarginTypes() {
            return [MARGIN_TYPE_PERCENTAGE, MARGIN_TYPE_ABSOLUTE];
        },
    },
    methods: {
        validate() {
            if (this.isValid() || !this.value.isActive) {
                this.clearValidation();
                this.$emit('onValidation', {
                    validationId: this.validationId,
                    isValid: true,
                });
                return;
            }
            this.$emit('onValidation', {
                validationId: this.validationId,
                isValid: false,
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.table-card:not(:last-of-type) {
    margin: 0 0 2px;
}
.table-card::v-deep .card__content {
    display: grid;
    grid-template-columns: auto 250px 250px 150px;
    align-items: center;
    min-height: 70px;
}
.table-card--inactive {
    background: $color-lightMediumGrey;
    &::v-deep .words {
        color: $color-border;
    }
    .tf::v-deep {
        .text-field__input-group {
            background: $color-lightMediumGrey;
        }
        .text-field__input {
            color: $color-border;
        }
    }
    .select::v-deep {
        .select-box__input-group {
            background: $color-lightMediumGrey;
        }
        .select-box__input {
            color: $color-border;
            opacity: 1;
        }
    }
}
.head {
    margin: 0 15px;
}
.qty {
    display: flex;
    margin: 0 15px;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
}
.tf {
    width: 80px;
}
.slash {
    margin: 0 0.5rem;
}
.margin {
    display: flex;
    margin: 0 15px;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
}
.switch {
    margin: 0 15px;
}
.select {
    width: 95px;
}
</style>

<template>
    <Flyout
        v-if="(organizationType !== null && organizationId !== null) || userId !== null"
        :screen-name="screenName"
        :active="isFlyoutOpen"
        :headline="$t(`pages.user.userForm.${mode}User.title`)"
        class="user-form"
        size="medium"
        @opened="resetForm"
        @closed="$emit('closed')"
    >
        <div class="user-form__content">
            <RoundedTabNavigation v-model="activeTab" :tabs="userFormTabs" class="user-form__tab-navigation" />

            <!-- USER_INFORMATION_FORM -->
            <Card v-if="activeTab === 'info'" no-padding>
                <Tile no-padding-bottom>
                    <GridRow :count="12" spacing="default">
                        <div class="span-1">
                            <AvatarUpload v-model="userData.image" size="medium" />
                        </div>

                        <TextField
                            v-model="userData.firstName"
                            :label="$t('pages.user.userForm.placeholder.firstName')"
                            :has-error="hasErrors('userData.firstName')"
                            :error="getError('userData.firstName')"
                            type="text"
                            class="span-11 span-sm-5"
                        />

                        <TextField
                            v-model="userData.lastName"
                            :label="$t('pages.user.userForm.placeholder.lastName')"
                            :has-error="hasErrors('userData.lastName')"
                            :error="getError('userData.lastName')"
                            type="text"
                            class="span-12 span-sm-6"
                        />

                        <TextField
                            v-model="userData.username"
                            :label="$t('pages.user.userForm.placeholder.username')"
                            :has-error="hasErrors('userData.username')"
                            :error="getError('userData.username')"
                            type="text"
                            class="span-12 span-sm-6"
                        />

                        <TextField
                            v-model.trim="userData.email"
                            :label="$t('pages.user.userForm.placeholder.emailWithAsterisk')"
                            :has-error="hasErrors('userData.email')"
                            :error="getError('userData.email')"
                            type="text"
                            class="span-12 span-sm-6"
                        />

                        <PhoneFieldset
                            v-model="userData.mobile"
                            class="span-12 span-sm-6"
                            :label="$t('pages.user.userForm.placeholder.mobileNumber')"
                            :prefix-error="getError('factoryForm.phone.countryCode')"
                            :error="getError('userData.mobile.number')"
                            @blur="partialValidation('userData.mobile.number')"
                        >
                            <BaseButton slot="action" @click="openMobileInfo">
                                <InfoIcon />
                            </BaseButton>
                        </PhoneFieldset>

                        <SelectBox
                            v-model="userData.locale"
                            class="span-12 span-sm-6"
                            :label="$t('pages.user.userForm.placeholder.locale')"
                            :options="localeOptions"
                            :option-value-renderer="option => option.locale"
                            :option-label-renderer="option => option.displayName"
                        />
                    </GridRow>
                </Tile>

                <Tile>
                    <GridRow :count="12" spacing="default" margin-less>
                        <div class="span-6">
                            <Headline :level="6" class="user-form__headline">
                                {{ $t('pages.user.userForm.statusLabel') }}
                            </Headline>
                            <ToggleSwitchField v-model="userData.isActive" :disabled="isSelf(userData.id)">
                                {{
                                    userData.isActive
                                        ? $t('pages.user.userForm.statusActiveLabel')
                                        : $t('pages.user.userForm.statusInactiveLabel')
                                }}
                            </ToggleSwitchField>
                        </div>
                    </GridRow>
                </Tile>

                <Tile v-if="userData.id && isPlatform">
                    <GridRow :count="2" spacing="default" margin-less>
                        <div>
                            <TextField
                                :label="$t('pages.user.userForm.resetPassword.label')"
                                value="*************"
                                disabled
                                readonly
                                type="text"
                                class="user-form__cell-padding"
                            />
                        </div>
                        <BaseButton :disabled="isPending()" primary @click="openResetPasswordDialog">
                            {{ $t('pages.user.userForm.resetPassword.buttonLabel') }}
                        </BaseButton>
                    </GridRow>
                </Tile>

                <Hint>
                    <div v-if="!userData.id">
                        <Words red bold>{{ $t('pages.user.userForm.newUser.hint.label') }}:</Words>
                        {{ $t('pages.user.userForm.newUser.hint.message') }}
                    </div>

                    {{ $t('pages.user.userForm.newUser.emailAddressHint') }}
                </Hint>
            </Card>
            <!-- USER_INFORMATION_FORM_ENDS -->

            <!-- PERMISSION_TAB -->
            <Card v-if="activeTab === 'permissions'" class="user-form__permissions">
                <div class="user-form__features">
                    <template v-for="group in Object.keys(availablePermissionGroups)">
                        <Tag v-if="group !== 'global'" :key="group" bold black small>
                            {{ $t(`pages.user.userForm.organizationTypes.${group}`) }}
                        </Tag>
                    </template>
                </div>

                <div v-for="group in Object.keys(availablePermissionGroups)" :key="group" class="user-form__permission">
                    <BaseButton
                        class="user-form__permission-row-container"
                        @click="toggleActivePermissionAccordion(group)"
                    >
                        <div class="user-form__permission-type-row">
                            <Words weight-normal v-html="$t(`pages.user.userForm.permissions.${group}`)" />

                            <div class="user-form__permission-icon">
                                <template v-if="activePermissionAccordion.includes(group)"
                                    ><MinusIcon class="icon--red"
                                /></template>
                                <template v-else><PlusIcon /></template>
                            </div>
                        </div>
                    </BaseButton>

                    <div v-if="activePermissionAccordion.includes(group)" class="user-form__permission-type">
                        <div
                            v-for="permission in Object.keys(availablePermissionGroups[group])"
                            :key="permission"
                            class="user-form__permission-row"
                        >
                            <CheckboxField
                                :value="userData.permissions.includes(permission)"
                                :disabled="!canTogglePermission(permission, group)"
                                class="user-form__permission-checkbox"
                                @input="togglePermission(permission, group)"
                            >
                                <div class="user-form__permission-info">
                                    <Words small block bold class="user-form__permission-title">
                                        {{ availablePermissionGroups[group][permission].title }}
                                    </Words>
                                    <Words block class="user-form__permission-description">
                                        {{ availablePermissionGroups[group][permission].description }}
                                    </Words>
                                    <Words
                                        v-if="showPermissionHint(availablePermissionGroups[group][permission])"
                                        tiny
                                        block
                                        spaced-top-tiny
                                    >
                                        <Words red bold>
                                            {{ $t('pages.user.userForm.permissionHint') }}
                                        </Words>
                                        <Words muted>
                                            {{ permissionHintText(group, permission) }}
                                        </Words>
                                    </Words>
                                </div>
                            </CheckboxField>
                        </div>
                    </div>
                </div>
            </Card>
            <!-- PERMISSION_TAB_ENDS -->

            <!-- USER_LINK_TAB -->
            <Card
                v-if="activeTab === 'userLinks' && isPlatform && userId !== null"
                class="user-form__user-links-tab"
                no-padding
            >
                <Tile no-border>
                    <Words>{{ $t('pages.user.userForm.userLinks.description') }}</Words>
                </Tile>
                <Tile>
                    <Words block bold spaced-bottom>{{ $t('pages.user.userForm.userLinks.label') }}</Words>
                    <AutocompleteField
                        :label="$t('pages.user.userForm.userLinks.autocompleteLabel')"
                        :predictions="userLinkPredictions"
                        @search="updatePrediction"
                        @select="addUserLink($event)"
                        @clear="clearPredictions"
                    >
                        <div slot="prediction" slot-scope="scope" class="user-form__user-link-suggestion">
                            <Words class="user-form__user-link-suggestion-item">
                                {{ scope.prediction.username }},<br />
                                {{ scope.prediction.organization.name }} ({{
                                    scope.prediction.organization.customerNumber
                                }})
                            </Words>
                            <Words class="user-form__user-link-suggestion-link">
                                {{ $t('pages.user.userForm.userLinks.linkUser') }}
                            </Words>
                        </div>
                    </AutocompleteField>
                </Tile>

                <template v-if="userLinks && userLinks.length > 0">
                    <Tile no-border transparent no-padding-bottom>
                        <Words small bold block>{{ $t('pages.user.userForm.userLinks.headline') }}</Words>
                    </Tile>
                    <div v-for="userLink in userLinks" :key="userLink.targetUser.id">
                        <Card>
                            <UserLinkTile :user-link="userLink" no-notifications @delete="removeUserLink($event)" />
                            <BaseButton
                                v-if="userLink.needsReauthentication"
                                slot="action"
                                class="user-form__user-link-reauth-button"
                                block
                                primary
                                light
                                arrow-right
                                @click="addUserLink(userLink.targetUser)"
                            >
                                {{ $t('pages.user.userForm.userLinks.reauthenticate') }}
                            </BaseButton>
                        </Card>
                        <Hint v-if="userLink.needsReauthentication" transparent spaceless-top>
                            <Words red>{{ $t('pages.user.userForm.userLinks.needsReauthentication') }}</Words>
                        </Hint>
                    </div>
                </template>
                <template v-else>
                    <Words class="container-deprecated" small bold block spaced-top spaced-bottom>
                        {{ $t('pages.user.userForm.userLinks.noLinkedUser') }}
                    </Words>
                </template>
            </Card>
            <!-- USER_LINK_TAB_ENDS -->
        </div>

        <ModalBox ref="resetPasswordInfo" :headline="$t('pages.user.userForm.resetPassword.modalHeadline')">
            {{ $t('pages.user.userForm.resetPassword.modalDescription', { name: userData.username }) }}
            <ButtonGroup slot="actions">
                <BaseButton :disabled="isPending()" primary light @click="closeResetPasswordDialog">
                    {{ $t('pages.user.userForm.resetPassword.cancelAction') }}
                </BaseButton>
                <BaseButton
                    :disabled="isPending()"
                    :is-loading="isPending('resetPassword')"
                    primary
                    @click="resetPassword"
                >
                    {{ $t('pages.user.userForm.resetPassword.submitAction') }}
                </BaseButton>
            </ButtonGroup>
        </ModalBox>

        <ModalBox ref="mobileInfo" :headline="$t('pages.user.userForm.mobileNumberInfo.headline')">
            {{ $t('pages.user.userForm.mobileNumberInfo.description') }}
        </ModalBox>

        <ModalBox
            :active="requestedSaveConfirmation"
            :headline="$t('pages.user.userForm.permissionInfo.headline')"
            @closed="requestedSaveConfirmation = false"
        >
            <Words pre>{{ $t('pages.user.userForm.permissionInfo.description') }}</Words>
            <ButtonGroup slot="actions">
                <BaseButton
                    :disabled="isPending()"
                    :is-loading="isPending('save')"
                    primary
                    place-left
                    @click="saveData()"
                >
                    {{ $t('pages.user.userForm.permissionInfo.submitAction') }}
                </BaseButton>
                <BaseButton :disabled="isPending()" primary light @click="switchToPermissions()">
                    {{ $t('pages.user.userForm.permissionInfo.changePermissionsAction') }}
                </BaseButton>
            </ButtonGroup>
        </ModalBox>

        <Flyout :active="confirmDeleteUser" no-header @closed="closedConfirmDeleteUserDialog">
            <ConfirmationScreen v-if="confirmDeleteUser" @close="closedConfirmDeleteUserDialog">
                <DeleteUserIcon slot="icon" />

                <div slot="content">
                    <Headline :level="3">
                        {{ $t('pages.user.userForm.delete.headline') }}
                    </Headline>

                    <Words block>
                        {{ $t('pages.user.userForm.delete.description') }}
                    </Words>

                    <Words v-if="userData.role === 'purchaser' || userData.role === 'admin'" block>
                        {{ $t('pages.user.userForm.delete.supervisorHint') }}
                    </Words>
                </div>
                <div v-if="activeTransports && activeTransports.length > 0" slot="additional-content">
                    <Words>
                        {{
                            $t('pages.user.userForm.delete.info', {
                                name: `${userData.firstName} ${userData.lastName}`,
                            })
                        }}
                    </Words>
                    <ul class="user-form__transports-list">
                        <li v-for="(transport, i) in activeTransports" :key="i">
                            <router-link
                                :to="{
                                    name: transportViewRoute,
                                    params: { transportId: transport.id },
                                }"
                            >
                                {{ $t('pages.user.userForm.delete.transportLabel', { number: transport.number }) }}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </ConfirmationScreen>

            <ButtonGroup slot="bottom">
                <BaseButton primary light arrow-left @click="closedConfirmDeleteUserDialog">
                    {{ $t('pages.user.userForm.delete.cancel') }}
                </BaseButton>
                <BaseButton :disabled="activeTransports && activeTransports.length > 0" primary @click="deleteUser">
                    <DeleteSmallIcon slot="left" class="icon--inline" />
                    {{ $t('pages.user.userForm.delete.confirm') }}
                </BaseButton>
            </ButtonGroup>
        </Flyout>

        <ButtonGroup slot="bottom">
            <BaseButton
                v-if="userData.id && !isSelf(userData.id)"
                :disabled="isPending()"
                class="span--place-left"
                @click="openConfirmDeleteUserDialog"
            >
                <span class="button__slot button__slot--left">
                    <GarbageIcon slot="left" class="icon--inline" />
                </span>
                <span v-if="$root.isDesktop">
                    {{ $t('pages.user.userForm.actions.delete') }}
                </span>
            </BaseButton>
            <BaseButton :disabled="isPending()" primary light arrow-left @click="closeForm">
                {{ $t('pages.user.userForm.actions.cancel') }}
            </BaseButton>
            <BaseButton
                :disabled="isPending() || !allowedToUpdateUser"
                :is-loading="isPending('save')"
                primary
                @click="saveDataWithHint()"
            >
                <TickIcon slot="left" class="icon--inline" />
                {{ $t('pages.user.userForm.actions.save') }}
            </BaseButton>
        </ButtonGroup>
    </Flyout>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import _intersection from 'lodash/intersection';
import { mapGetters } from 'vuex';
import { findClosestMatchingSupportedLocale, getAllLanguagesInLocale } from '@/i18n/languages';
import { findCountryCodeByLocale } from '@/config/countryCodes';
import { isRequired, isEmail, isPhoneNumber, isUsername, minLength, maxLength } from '@/services/validation/rules';
import UserApi from '@/services/Api/UserV2';
import AdministrationUserLinkApi from '@/services/Api/Administration/UserLink';
import OrganizationApi from '@/services/Api/Organization';
import Toaster from '@/services/Toaster';
import validate from '@/services/validation/mixin';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import TransportListApi from '@/services/Api/TransportList';

import AutocompleteField from '@/components/Form/AutocompleteField';
import AvatarUpload from '@/components/Form/AvatarUpload';
import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Card from '@/components/Layout/Card';
import CheckboxField from '@/components/Form/CheckboxField';
import ConfirmationScreen from '@/components/Layout/ConfirmationScreen';
import Flyout from '@/components/Layout/Flyout';
import GridRow from '@/components/Layout/GridRow';
import Headline from '@/components/Typography/Headline';
import Hint from '@/components/Typography/Hint';
import ModalBox from '@/components/Modal/ModalBox';
import PhoneFieldset from '@/components/Form/PhoneFieldset';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation';
import SelectBox from '@/components/Form/SelectBox.v3';
import Tag from '@/components/Typography/Tag';
import TextField from '@/components/Form/TextField.v2';
import Tile from '@/components/Layout/Tile';
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import UserLinkTile from '@/components/UserLink/UserLinkTile';
import Words from '@/components/Typography/Words';

import DeleteSmallIcon from '@/assets/icons/micro/error.svg';
import DeleteUserIcon from '@/assets/icons/confirmation/delete-user.svg';
import GarbageIcon from '@/assets/icons/regular/garbage.svg';
import InfoIcon from '@/assets/icons/regular/info.svg';
import MinusIcon from '@/assets/icons/micro/minus.svg';
import PlusIcon from '@/assets/icons/micro/plus.svg';
import TickIcon from '@/assets/icons/micro/tick.svg';

export default {
    name: 'UserForm',
    components: {
        AutocompleteField,
        AvatarUpload,
        BaseButton,
        ButtonGroup,
        Card,
        ConfirmationScreen,
        Flyout,
        GridRow,
        Headline,
        Hint,
        ModalBox,
        PhoneFieldset,
        RoundedTabNavigation,
        SelectBox,
        Tag,
        TextField,
        Tile,
        ToggleSwitchField,
        UserLinkTile,
        Words,
        CheckboxField,

        DeleteSmallIcon,
        DeleteUserIcon,
        GarbageIcon,
        InfoIcon,
        MinusIcon,
        PlusIcon,
        TickIcon,
    },

    mixins: [validate, statefulMixin],

    props: {
        userId: {
            type: [Number, String],
            default: null,
        },
        organizationId: {
            type: [Number, String],
            default: null,
        },
        organizationLocale: {
            type: String,
            default: null,
        },
        organizationType: {
            type: String,
            default: null,
            validator: v => ['platform', 'carrier', 'supplier', 'client'].includes(v),
        },
        active: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            activeTab: 'info',
            confirmDeleteUser: false,
            activeTransports: null,
            currentOrganizationType: this.organizationType,
            isFlyoutOpen: this.active,
            userData: this.getDataReset(),
            userLinkPredictions: [],
            availablePermissionGroups: [],
            activePermissionAccordion: [],
            validationRules: {
                'userData.firstName': [isRequired()],
                'userData.lastName': [isRequired()],
                'userData.username': [isRequired(), minLength(5), maxLength(40), isUsername()],
                'userData.email': [isEmail()],
                'userData.mobile.countryCode': [isRequired()],
                'userData.mobile.number': [
                    isRequired('validation.phoneNumberIsRequired'),
                    isPhoneNumber(),
                    minLength(3),
                    maxLength(30),
                ],
            },
            userLinks: null,
            requestedSaveConfirmation: false,
        };
    },

    computed: {
        ...mapGetters('user', ['info', 'isPlatformAdministrator']),
        ...mapGetters('i18n', {
            currentLocale: 'locale',
        }),

        screenName() {
            if (this.isPlatformAdministrator) {
                return this.mode === 'new' ? 'platform-user-create' : 'platform-user-edit';
            } else {
                return this.mode === 'new' ? 'user-create' : 'user-edit';
            }
        },

        localeOptions() {
            return getAllLanguagesInLocale(this.currentLocale);
        },

        userFormTabs() {
            const tabs = {
                info: this.$t('pages.user.userForm.tabs.info'),
                permissions: this.$t('pages.user.userForm.tabs.permissions'),
            };

            if (this.isPlatform && this.userId !== null) {
                tabs.userLinks = this.$t('pages.user.userForm.tabs.userLinks');
            }

            return tabs;
        },

        mode() {
            return this.userId ? 'edit' : 'new';
        },

        isPlatform() {
            return this.$can('listAllUsers');
        },

        allowedToUpdateUser() {
            if (!this.userId) return true;
            return this.$can('updateUser', this.userData);
        },

        transportViewRoute() {
            const currentUserType = this.info.types[0];
            let routeName = '';

            if (currentUserType === 'client') {
                routeName = this.$root.findRouteName('order__transport-list__transport-view');
            }

            if (currentUserType === 'carrier') {
                routeName = this.$root.findRouteName('logistics__transport-list__transport-view');
            }

            if (currentUserType === 'supplier') {
                routeName = this.$root.findRouteName('materials__transport-list__transport-view');
            }

            if (currentUserType === 'platform') {
                routeName = this.$root.findRouteName('order-management__transport-list__transport-view');
            }

            return routeName;
        },
    },

    watch: {
        active(value, oldValue) {
            this.isFlyoutOpen = value;

            if (value !== oldValue && value) {
                this.resetForm();
            }
        },

        organizationType(value) {
            this.currentOrganizationType = value;
        },
    },

    methods: {
        isSelf(id) {
            return this.$store.getters['user/tokenData'].userId === id;
        },

        async resetForm() {
            this.activeTab = 'info';
            this.clearValidation();
            this.activeTransports = null;
            this.activePermissionAccordion = [];
            this.userData = this.getDataReset();
            this.requestedSaveConfirmation = false;

            if (this.userId) {
                await this.loadUser();
                await this.loadOrganizationPermissions(this.userData.organization.id);
                this.isPlatform && (await this.loadUserLinks());
            } else {
                await this.loadOrganizationPermissions();
            }
        },

        getDataReset() {
            return {
                firstName: '',
                lastName: '',
                username: '',
                mobile: {
                    countryCode: findCountryCodeByLocale(this.organizationLocale) || '',
                    number: '',
                },
                locale: findClosestMatchingSupportedLocale(this.organizationLocale),
                role: '',
                priceAccess: true,
                isActive: true,
                permissions: [],
            };
        },

        async loadUser() {
            const user = await UserApi.getOneById(this.userId);
            this.userData = {
                ...user,
                /*
                 * It is not guarantteed that the configured user locale is available.
                 * For example the user may have a preexisting locale `de` that doesn't match
                 * with `de-DE` or `de-AT`.
                 *
                 * Because of that we have to first match the locale with the available locales on the client.
                 */
                locale: findClosestMatchingSupportedLocale(user.locale),
            };
            this.currentOrganizationType = this.userData.type;
        },

        async loadUserLinks() {
            this.userLinks = await AdministrationUserLinkApi.getLinksForUser(this.userId);
        },

        async loadOrganizationPermissions(organizationId) {
            const orgId = organizationId ? organizationId : this.organizationId;
            const response = await OrganizationApi.getAvailablePermissions(orgId);
            const groups = {};
            Object.keys(response).forEach(key => {
                if (Object.keys(response[key]).length > 0) {
                    groups[key] = response[key];
                }
            });

            this.availablePermissionGroups = groups;
        },

        async saveDataWithHint() {
            if (this.userData.permissions.length === 0) {
                this.requestedSaveConfirmation = true;
                return;
            }

            await this.saveData();
        },

        switchToPermissions() {
            this.requestedSaveConfirmation = false;
            this.activeTab = 'permissions';
        },

        async saveData(close = true) {
            await this.stateful('save', async () => {
                if (!this.isValid()) {
                    return;
                }

                this.$set(
                    this.userData.mobile,
                    'number',
                    this.userData.mobile.number.replace(/\D/g, '').replace(/^0+/, '')
                );

                try {
                    let data = _cloneDeep({
                        ...this.userData,
                        organization: {
                            id: this.userData.organizationId || this.organizationId,
                        },
                    });

                    // backend is unable to handle the following keys
                    data = _omit(data, [
                        'type',
                        'organizationTermsConfirmed',
                        'termsConfirmed',
                        'organizationName',
                        'organizationStatus',
                        'organizationNumber',
                    ]);

                    await UserApi.save(data);

                    Toaster.success(this.$t('pages.user.userForm.newUser.userEditedSuccessfully'));

                    if (close) {
                        this.closeForm();
                    }
                } catch (err) {
                    this.$logger().error(err);
                    this.parseServerValidation(_get(err, 'response.data.errors', {}), {
                        'userData.firstName': 'firstName',
                        'userData.lastName': 'lastName',
                        'userData.username': 'username',
                        'userData.email': 'email',
                        'userData.mobile.number': 'mobile.number',
                        'userData.role': 'role',
                    });
                    Toaster.error(err);
                }
            });
        },

        async deleteUser() {
            try {
                await UserApi.delete(this.userData.id);
                this.closeForm();
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(this.$t('pages.user.userForm.delete.unableToDelete'));
            }
        },

        openMobileInfo() {
            this.$refs.mobileInfo.$emit('open');
        },

        closeForm() {
            this.isFlyoutOpen = false;
            this.confirmDeleteUser = false;
            this.$emit('closed');
        },

        async openConfirmDeleteUserDialog() {
            const currentUserType = this.info.types[0];

            if (!this.userData.permissions.includes('drive')) {
                this.confirmDeleteUser = true;
                return;
            }

            const transportListApi = new TransportListApi(currentUserType);

            try {
                const activeTransports = await transportListApi.filter({
                    driver: this.userData.id,
                    notInStatus: ['delivered', 'failed', 'cancelled'],
                });

                this.activeTransports = activeTransports.items;
                this.confirmDeleteUser = true;
            } catch (err) {
                this.$logger().error(err);
            }
        },

        closedConfirmDeleteUserDialog() {
            this.activeTransports = null;
            this.confirmDeleteUser = false;
        },

        async openResetPasswordDialog() {
            try {
                await this.saveData(false);
                this.$refs.resetPasswordInfo.$emit('open');
            } catch (err) {
                // don't open if save failed
            }
        },

        closeResetPasswordDialog() {
            this.$refs.resetPasswordInfo.$emit('close');
        },

        async resetPassword() {
            await this.stateful('resetPassword', async () => {
                try {
                    await UserApi.resetPassword(this.userData.id);
                    Toaster.success(
                        this.$t('pages.user.userForm.resetPassword.successMessage', {
                            phone: `${this.userData.mobile.countryCode}${this.userData.mobile.number}`,
                        })
                    );
                } catch (err) {
                    this.$logger().error(err);
                    Toaster.error(err.message);
                }
                this.closeResetPasswordDialog();
            });
        },

        async updatePrediction(value) {
            this.cancelPredictionUpdate && this.cancelPredictionUpdate.cancel('canceled-previous-call');
            this.cancelPredictionUpdate = UserApi.createCancelTokenSource();

            try {
                const perPage = this.userLinks ? this.userLinks.length + 6 : 6;
                const users = await UserApi.filter(
                    {
                        search: value,
                        isActive: true,
                        organizationType: ['client', 'supplier', 'carrier'],
                        perPage,
                    },
                    null,
                    [],
                    this.cancelOrderSource
                );

                let filteredIds = [this.userId];
                if (this.userLinks) {
                    filteredIds = [...filteredIds, ...this.userLinks.map(o => o.targetUser.id)];
                }

                this.userLinkPredictions = users.items.filter(o => !filteredIds.includes(o.id)).slice(0, 5);
            } catch (err) {
                Toaster.error(err.message);
            }
        },

        clearPredictions() {
            this.cancelPredictionUpdate && this.cancelPredictionUpdate.cancel('canceled-previous-call');
            this.userLinkPredictions = [];
        },

        async addUserLink(user) {
            try {
                const { targetUser: linkedUser } = await AdministrationUserLinkApi.addLink(this.userId, user.id);
                this.loadUserLinks();
                this.clearPredictions();
                Toaster.success(this.$t('pages.user.userForm.userLinks.linkAdded', { username: linkedUser.username }));
            } catch (err) {
                const [originError, targetError] = [
                    _get(err, 'response.data.errors.originUser.id[0]', null),
                    _get(err, 'response.data.errors.targetUser.id[0]', null),
                ];
                Toaster.error(originError || targetError || err.message);
            }
        },

        async removeUserLink(userLink) {
            try {
                const { targetUser: linkedUser } = await AdministrationUserLinkApi.deleteLink(
                    this.userId,
                    userLink.targetUser.id
                );
                this.loadUserLinks();
                Toaster.success(
                    this.$t('pages.user.userForm.userLinks.linkDeleted', { username: linkedUser.username })
                );
            } catch (err) {
                Toaster.error(err.messsage);
            }
        },

        toggleActivePermissionAccordion(groupName) {
            if (this.activePermissionAccordion.includes(groupName)) {
                this.activePermissionAccordion.splice(this.activePermissionAccordion.indexOf(groupName), 1);
            } else {
                this.activePermissionAccordion.push(groupName);
            }
        },

        isDependentPermission(permission, group) {
            const { permissions: newPermissions } = this.userData;
            const selectedGroup = this.availablePermissionGroups[group];

            let isDependent = false;

            newPermissions.forEach(eachPermission => {
                if (!selectedGroup[eachPermission]) return;
                if (selectedGroup[eachPermission].requiredPermissions.includes(permission)) {
                    isDependent = true;
                }
            });

            return isDependent;
        },

        togglePermission(permission, group) {
            const { permissions: newPermissions } = this.userData;

            // check if already added
            const alreadyAvailable = newPermissions.includes(permission);

            const availablePermissionsInGroup = Object.keys(this.availablePermissionGroups[group]);

            if (!alreadyAvailable) {
                const requiredPermissions = this.availablePermissionGroups[group][permission].requiredPermissions ?? [];

                // apply the depended permissions, skip all required but unavailable options
                const dependedPermissions = _intersection(requiredPermissions, availablePermissionsInGroup);
                newPermissions.push(...dependedPermissions);

                // add the permission
                newPermissions.push(permission);
            } else {
                // check if its a dependent permission
                if (this.isDependentPermission(permission, group)) {
                    // do nothing
                } else {
                    newPermissions.splice(newPermissions.indexOf(permission), 1);
                }
            }

            this.userData.permissions = _uniq(newPermissions);
        },

        canTogglePermission(permission, group) {
            if (this.isDependentPermission(permission, group)) return false;
            if (!this.userId) return true;

            if (permission === 'administration') {
                return this.$can('updateUserAdministrativeSettings');
            }

            return true;
        },

        showPermissionHint(permissionObj) {
            return permissionObj.requiredPermissions.length > 0;
        },

        permissionHintText(permissionGroup, permission) {
            const requiredPermissions = this.availablePermissionGroups[permissionGroup][permission].requiredPermissions;
            const translatedPermissions = requiredPermissions.map(requiredPermission => {
                const key = _get(this.availablePermissionGroups, `${permissionGroup}.${requiredPermission}.title`, '');
                return `"${key}"`;
            });

            return this.$tc('pages.user.userForm.permissionHintText', translatedPermissions.length, {
                permissions: translatedPermissions.join(', '),
            });
        },
    },
};
</script>

<style lang="scss">
.user-form {
    .flyout__inner {
        background-color: $color-lightMediumGrey;
    }
}

.user-form__headline {
    margin-top: 0;
    margin-bottom: 20px;
}

.user-form__cell-padding {
    margin-top: 10px;
}

.user-form__transports-list {
    margin-top: 40px;
    padding-left: 0;
    list-style: none;

    a {
        color: $color-red;
        white-space: nowrap;

        &::before {
            content: '›';
            display: inline-block;
            margin-right: 10px;
        }
    }

    li + li {
        margin-top: 15px;
    }
}

.span--place-left {
    margin-right: auto;
    transition: opacity 0.1s ease-in-out;
    cursor: pointer;
    padding-left: 20px;
    text-align: left;

    &:hover,
    &:focus,
    &:active {
        opacity: 0.7;
        transition: opacity 0.1s ease-in-out;
    }

    @media only screen and (min-width: $layout-desktop-min) {
        padding-left: 0;
        text-align: center;
    }
}

.user-form__option {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.user-form__options-text {
    flex-grow: 1;
}

.user-form__user-link-suggestion {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.user-form__user-link-suggestion-link {
    margin-left: 15px;
    white-space: nowrap;
}

.user-form__user-links-tab {
    padding-bottom: 5px;
}

.user-form__user-link-reauth-button {
    margin-top: 15px;
    border-top: 1px solid $color-lightMediumGrey !important;
}

.user-form__content {
    background-color: $color-lightMediumGrey;
}

.user-form__tab-navigation {
    margin: 0px 15px;
}

.user-form__permissions {
    padding: 15px 15px;
}

.user-form__permission {
    border-top: 1px solid $color-darkGrey;

    &:last-of-type {
        border-bottom: 1px solid $color-darkGrey;
    }
}

.user-form__features {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;

    span {
        margin-left: 10px;
    }
}

.user-form__permission-row {
    margin-bottom: 25px;
}

.user-form__permission-type-row {
    display: grid;
    grid-template-columns: 1fr 60px;
    align-items: center;
    margin: 20px 0px;
}

.user-form__permission-type {
    margin-top: 20px;
}

.user-form__country-code-field {
    grid-column-start: 1;
    grid-column-end: 3;
}

.user-form__country-number-field {
    grid-column-start: 3;
    grid-column-end: -1;
}

.user-form__permission-icon {
    justify-self: end;
    margin-right: 20px;

    .f-base,
    .f-highlight {
        fill: $color-black;
    }

    .s-base,
    .s-highlight {
        stroke: $color-black;
    }
}

.user-form__permission-row-container {
    width: 100%;
    text-align: left;
}

.user-form__permission-checkbox {
    .checkbox-input__input,
    .checkbox-input__label {
        width: 20px;
        height: 20px;
    }

    .checkbox-input__orb {
        width: 10px;
    }
}

.user-form__permission-title {
    margin-bottom: 4px;
}

.user-form__permission-description {
    line-height: 18px;
}
</style>

import React from 'react';
export const FavoriteProjectsIcon = ({ className }) => (<svg width={140} height={140} fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <g strokeLinecap="round" strokeLinejoin="round">
            <path d="m46.035 76.585-8.73 2.82-13.22-40.92 33.31-10.76 5.19 16.06" stroke="#35343D" strokeWidth={2.33}/>
            <path d="m34.545 44.824 11.9-3.84M51.675 39.294l.95-.31M53.515 45.005l.95-.31M36.385 50.535l11.9-3.84M38.235 56.244l11.9-3.84M40.075 61.955l10.54-3.4" stroke="#35343D" strokeWidth={2.5}/>
            <path d="m75.555 40.774 2.82-9.04 33.54 9.99-12.28 41.21-4.85-1.56" stroke="#35343D" strokeWidth={2.33}/>
            <path d="m83.395 42.875 11.98 3.57M100.645 48.014l.96.29M98.935 53.764l.96.29M97.215 59.514l.96.29M95.505 65.264l.96.29M85.665 49.815l8 2.38M86.565 56.344l5.38 1.6M86.785 62.664l3.45 1.03" stroke="#35343D" strokeWidth={2.5}/>
            <path d="m53.085 80.244-3-30.03 34.83-3.48 4.09 40.95" stroke="red" strokeWidth={2.33}/>
            <path d="m68.325 55.565 4.48 7.42 8.55.47-5.55 6.6 2.21 8.41-7.91-3.34-7.19 4.72.67-8.67-6.66-5.49 8.34-2.01 3.06-8.11ZM23.715 79.725v9.26M19.085 84.356h9.27" stroke="red" strokeWidth={2.33}/>
            <path d="m121.915 55.004-6.55 6.55M115.365 55.004l6.55 6.55" stroke="#35343D" strokeWidth={2.33}/>
            <path d="M20.805 29.175a2.725 2.725 0 0 0 0-5.45c-1.51 0-2.72 1.22-2.72 2.72 0 1.5 1.22 2.72 2.72 2.72v.01Z" stroke="red" strokeWidth={2.33}/>
            <path d="M67.815 41.165c1.5 0 2.72-1.22 2.72-2.72 0-1.5-1.22-2.72-2.72-2.72-1.5 0-2.72 1.22-2.72 2.72 0 1.5 1.22 2.72 2.72 2.72Z" stroke="#35343D" strokeWidth={2.33}/>
            <path d="M108.815 80.174c1.5 0 2.72-1.22 2.72-2.72 0-1.5-1.22-2.72-2.72-2.72-1.5 0-2.72 1.22-2.72 2.72 0 1.5 1.22 2.72 2.72 2.72Z" stroke="red" strokeWidth={2.33}/>
            <path d="M58.635 101.734v-6h19v6h-19Z" stroke="#35343D" strokeWidth={2.33}/>
            <path d="M77.085 116.265h-42.95v-28.54h68v28.54h-6.05" stroke="#35343D" strokeWidth={2.33}/>
        </g>
    </svg>);

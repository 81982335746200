import AbstractResource from './AbstractResource';
export var MarketPermissionOperand;
(function (MarketPermissionOperand) {
    MarketPermissionOperand["INTERSECTION"] = "INTERSECTION";
    MarketPermissionOperand["UNION"] = "UNION";
})(MarketPermissionOperand || (MarketPermissionOperand = {}));
class Market extends AbstractResource {
    async getPermission(operation) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/permission/${operation}`);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async getPermissions(operand, operations) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/permission/merge?operations[]=${operations.join('&operations[]=')}&operand=${operand}`);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
}
export default new Market('/v1/market');

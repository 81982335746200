import { ensurePHPTimestamp } from '@/services/utils/date';
import _cloneDeep from 'lodash/cloneDeep';

const initialState = {
    transports: [],
    planInterval: null,
    planSubcontractor: false,
};

const getters = {
    enabled: state => (state.transports?.length ?? 0) > 0,
    activeGroup: state => state.transports?.[0]?.orderNumber,
    transports: state => state.transports,
    maxTotalWeight: state => {
        const minValue = Math.min(
            ...(state.transports
                ?.map(transport => transport?.constructionSite?.maximumTrafficability)
                .filter(Boolean) ?? [])
        );

        return minValue === Infinity ? undefined : minValue;
    },
    minPayload: state => {
        const maxValue = Math.max(...(state.transports?.map(transport => transport?.qty)?.filter(Boolean) ?? []));

        return maxValue === -Infinity ? undefined : maxValue;
    },
    hasTransport: state => transport => {
        return state.transports?.find(item => item.id === transport.id) !== undefined;
    },
    transportCount: state => state.transports.length,
    planInterval: state => state.planInterval,
    planSubcontractor: state => state.planSubcontractor,
};

const mutations = {
    setTransports(state, transports) {
        state.transports = transports;
    },

    setPlanInterval(state, planInterval) {
        state.planInterval = planInterval;
    },

    setPlanSubcontractor(state, value) {
        state.planSubcontractor = value;
    },
};

const actions = {
    disable({ commit, getters }, orderNumber = null) {
        if (!orderNumber || getters.activeGroup === orderNumber) {
            commit('setTransports', []);
            commit('setPlanInterval', null);
        }
    },

    addTransport({ commit, getters }, transport) {
        if (transport.orderNumber !== getters.activeGroup) {
            commit('setTransports', [transport]);
            return;
        }

        commit('setTransports', [...getters.transports, transport]);
    },

    removeTransport({ commit, getters }, transport) {
        const transports = getters.transports.filter(item => item.id !== transport.id);

        if (!transports.length) {
            commit('setPlanInterval', null);
            commit('setPlanSubcontractor', false);
        }

        commit('setTransports', transports);
    },

    toggleTransport({ getters, dispatch }, transport) {
        if (getters.hasTransport(transport)) {
            dispatch('removeTransport', transport);
            return;
        }

        dispatch('addTransport', transport);
    },

    setTransports({ commit }, transports) {
        commit('setTransports', transports);
        commit('setPlanInterval', null);
        commit(
            'setPlanSubcontractor',
            transports.every(({ forSubcontractor }) => forSubcontractor)
        );
    },

    setVehicle({ commit, getters }, { vehicle }) {
        const transports = getters.transports.map(transport => ({
            ..._cloneDeep(transport),
            vehicle,
        }));

        commit('setTransports', transports);
    },

    setDriver({ commit, getters }, { driver }) {
        const transports = getters.transports.map(transport => ({
            ..._cloneDeep(transport),
            driver,
        }));

        commit('setTransports', transports);
    },

    addPlanData({ commit, getters }, { dates, interval }) {
        const transports = getters.transports.map((transport, index) => ({
            ..._cloneDeep(transport),
            plannedDeliveryTime: ensurePHPTimestamp(dates[index]),
        }));

        commit('setTransports', transports);
        commit('setPlanInterval', interval ?? null);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

import _debounce from 'lodash/debounce';
/**
 * Creates a memoized version of `_.debounce` that can be keyed by a string.
 *
 * @returns The debounced function, keyed by a string.
 */
export const keyedDebounce = (...debounceArgsAndDebounceFunction) => {
    const debouncedFunctionMap = {};
    const [functionToDebounce, wait, debounceOptions, debounceFunction = _debounce] = debounceArgsAndDebounceFunction;
    return (key, ...params) => {
        debouncedFunctionMap[key] =
            debouncedFunctionMap[key] || debounceFunction(functionToDebounce, wait, debounceOptions);
        return debouncedFunctionMap[key](...params);
    };
};
/**
 * A utility function that debounces a function. Every function call returns a Promise that
 * will eventually will resolve when the debounced function is called. This makes sure
 * that return values are consistent.
 * @template TCallback The type of the function to debounce.
 * @param debounceArgs - The arguments for the _debounce function.
 * @returns A function that returns a promise that resolves with the result of the debounced function.
 */
export const asyncDebounce = (...debounceArgs) => {
    const [functionToDebounce, ...debounceOtherArgs] = debounceArgs;
    const promiseQueue = [];
    const debouncedFunction = _debounce(async (...args) => {
        const currentPromiseQueue = [...promiseQueue];
        promiseQueue.length = 0;
        try {
            const result = await functionToDebounce(...args);
            currentPromiseQueue.forEach(({ resolve }) => resolve(result));
            return result;
        }
        catch (error) {
            currentPromiseQueue.forEach(({ reject }) => reject(error));
        }
    }, ...debounceOtherArgs);
    const returnFunction = (...args) => new Promise((resolve, reject) => {
        promiseQueue.push({ resolve, reject });
        debouncedFunction(...args);
    });
    returnFunction.cancel = () => {
        promiseQueue.forEach(({ reject }) => reject('canceled'));
        promiseQueue.length = 0;
        debouncedFunction.cancel();
    };
    returnFunction.flush = debouncedFunction.flush;
    return returnFunction;
};

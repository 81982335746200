import AbstractResource from '../AbstractResource';

class User extends AbstractResource {
    async impersonate(userId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${userId}/impersonate`);
            return response.data.token;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async stopImpersonation() {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/unimpersonate`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new User('/platform/user');

<template>
    <div class="active-transport-item" @click="$emit('click', $event)">
        <div class="active-transport-item__split-between">
            <Words>
                {{ revertLocalizedValue(transport.product.productName) }}
            </Words>
            <Words block tiny bold>
                {{ statusText(transport.status) }}
            </Words>
        </div>
        <div class="active-transport-item__label-table">
            <ConstructionSiteIcon width="14" class="icon--muted" />
            <Words small muted block>{{ transport.constructionSite.name }}</Words>

            <PinIcon width="14" class="icon--muted" />
            <Words small muted>{{ transport.supplier.name }}</Words>

            <TruckIcon width="14" height="18" class="icon--muted" />
            <div class="active-transport-item__vehicle-info">
                <div>
                    <Words small>{{ transport.vehicleName }}</Words>
                    <Words small muted> / {{ getEmptyWeight(transport.vehicle) | tons }} </Words>
                </div>
                <Words small>{{ transport.vehicle.licensePlate }}</Words>
            </div>
        </div>
    </div>
</template>

<script>
import { statusIsBefore, statusIsAfter, statusText } from '@/services/utils/transport';
import { revertLocalizedValue } from '@/services/utils/localization';
import { mapFilters } from '@/plugins/filters';
import routeContext from '@/plugins/mixins/routeContext';

import Words from '@/components/Typography/Words';

import PinIcon from '@/assets/icons/micro/pin2.svg';
import ConstructionSiteIcon from '@/assets/icons/micro/construction-site.svg';
import TruckIcon from '@/assets/icons/micro/truck.svg';

export default {
    name: 'ActiveTransportPickupItem',
    components: {
        Words,
        PinIcon,
        ConstructionSiteIcon,
        TruckIcon,
    },
    mixins: [routeContext],
    props: {
        transport: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        isInTransit() {
            return this.item.status === 'in_transit';
        },

        isInProgress() {
            return statusIsBefore(this.item.status, 'unloading_delayed');
        },

        isDelivered() {
            return statusIsAfter(this.transport.status, 'destination_waiting');
        },
    },
    methods: {
        getEmptyWeight(vehicle) {
            return vehicle.permissibleTotalWeight - vehicle.emptyWeight;
        },

        ...mapFilters(['tons']),
        revertLocalizedValue,
        statusIsBefore,
        statusIsAfter,

        statusText(status) {
            return statusText(this.routeContext, status);
        },
    },
};
</script>

<style lang="scss">
.active-transport-item {
    cursor: pointer;
    padding: 18px 10px 13px;
}

.active-transport-item__split-between {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.active-transport-item__label-table {
    display: grid;
    grid-template-columns: 14px 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-top: 10px;
}

.active-transport-item__vehicle-info {
    display: grid;
    grid-template-columns: 2fr 3fr;
}
</style>

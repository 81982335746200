<template>
    <div class="contact-item">
        <Tile no-border>
            <div v-if="!isPlatformOrganization" class="contact-item__user">
                <Avatar
                    v-if="person"
                    :src="person.image && person.image.url"
                    class="contact-item__image"
                    size="small"
                />
                <div class="contact-item__text">
                    <Words bold block>{{ name }}</Words>
                    <Words v-if="role" block>{{ role }}</Words>
                </div>
            </div>

            <div class="contact-item__options">
                <GridRow :count="2" class="contact-item__grid-row" spacing="none">
                    <Words class="contact-item__number" bold>
                        {{ phone }}
                    </Words>
                    <div class="flex justify-end gap-2">
                        <a :href="`tel:${phone}`">
                            <SfIconButton>
                                <template #icon="iconProps">
                                    <SfCallIcon v-bind="iconProps" />
                                </template>
                            </SfIconButton>
                        </a>
                        <a v-if="person" :href="`sms:${phone}`">
                            <SfIconButton>
                                <template #icon="iconProps">
                                    <SfContactIcon v-bind="iconProps" />
                                </template>
                            </SfIconButton>
                        </a>
                    </div>
                </GridRow>
            </div>
        </Tile>
    </div>
</template>

<script>
import { assemblePhoneNumber } from '@/services/utils';

import Tile from '@/components/Layout/Tile';
import GridRow from '@/components/Layout/GridRow';
import Words from '@/components/Typography/Words';
import Avatar from '@/components/Avatar';

import { SfCallIcon, SfContactIcon, SfIconButton } from '@schuettflix/vue-components';

export default {
    name: 'ContactItem',
    components: {
        Tile,
        GridRow,
        Words,
        Avatar,
        SfIconButton,
        SfCallIcon,
        SfContactIcon,
    },
    props: {
        type: {
            type: String,
            default: null,
        },
        person: {
            type: Object,
            default: null,
        },
        organization: {
            type: Object,
            default: null,
        },
        isPlatformOrganization: {
            type: Boolean,
            default: false,
        },
        phoneNumber: {
            type: [Object, String],
            default: null,
        },
        isSimplePhoneNumber: {
            type: Boolean,
            default: null,
        },
    },
    computed: {
        name() {
            if (this.isPlatformOrganization) {
                return this.assemblePhoneNumber(this.phoneNumber);
            }

            if (this.person) {
                return `${this.person.firstName} ${this.person.lastName}`;
            }

            if (this.organization) {
                return this.organization.name;
            }

            return '';
        },

        role() {
            if (['supervisor', 'applicant', 'driver'].includes(this.type)) {
                return this.$t(`components.contactOptions.roles.${this.type}`);
            }

            return '';
        },

        phone() {
            if (this.isPlatformOrganization) {
                return this.assemblePhoneNumber(this.phoneNumber);
            }

            if (this.person) {
                return this.assemblePhoneNumber(this.person.mobile);
            }

            if (this.organization) {
                if (this.organization.mobile && this.organization.mobile.number) {
                    return this.assemblePhoneNumber(this.organization.mobile);
                }

                if (this.organization.phone && this.organization.phone.number) {
                    return this.assemblePhoneNumber(this.organization.phone);
                }
            }

            if (this.isSimplePhoneNumber) {
                return this.phoneNumber.replace(/\s/g, '');
            }

            return '';
        },
    },
    methods: {
        assemblePhoneNumber,
    },
};
</script>

<style lang="scss">
.contact-item__user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin-bottom: 20px;
}

.contact-item__image {
    flex: 0 0 32px;
    display: inline-block;
    vertical-align: middle;
    min-width: 32px;
    margin-right: 10px;
}

.contact-item__text {
    flex-grow: 1;
    vertical-align: middle;
}

.contact-item__text--no-image {
    padding-left: 0;
}

.contact-item__grid-row {
    vertical-align: middle;
    height: 50px;
    line-height: 50px;
}

.contact-item__number {
    vertical-align: middle;
    display: inline;
}

.contact-item__orb {
    text-align: right;
    vertical-align: middle;
    display: inline-block;
    width: 100%;
}

.contact-item__link {
    width: 50px;
    height: 50px;
    display: inline-block;
    vertical-align: middle;
    line-height: 50px;
    text-align: center;
    border-radius: 100%;
    box-shadow: $boxShadow-full;

    svg {
        display: inline-block;
        vertical-align: middle;
    }
}
</style>

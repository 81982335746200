<template>
    <div
        class="supplier-tile"
        :class="{
            'supplier-tile--best-price': showBestPriceBadge,
            'supplier-tile--highlight': highlight,
            'cursor-pointer': clickable,
            'supplier-tile--eco-badge': showEcoBadge,
            'supplier-tile--no-border': noBorder,
            'supplier-tile--levitate': levitate,
            'supplier-tile--spaceless': spaceless,
        }"
        data-test="supplier-tile"
        @click="$emit('tile-action', supplierInfo)"
    >
        <div v-if="showBestPriceBadge" class="supplier-tile__best-price-badge">
            <SchuettflixLogo width="100" height="13" class="supplier-tile__best-price-badge-image" />
            {{ $t('pages.checkout.deliverySupplierSelection.bestPrice') }}
        </div>

        <div>
            <div
                v-if="showHeadline"
                class="mb-6 flex flex-nowrap items-center justify-between"
                :class="{ 'cursor-pointer': hasAction }"
                @click="$emit('headline-action', carrier)"
            >
                <span class="font-copy-sm-strong">
                    {{ headline || $t('components.supplierTile.headline') }}
                </span>
                <span v-if="isPlatform" class="font-numbers-sm text-subdued">
                    {{ supplierInfo.supplier.customerNumber }}
                </span>

                <SfIconButton v-if="hasAction" size="sm" :has-background="false">
                    <template #icon="iconProps"><SfSysChevronRightIcon v-bind="iconProps" /></template>
                </SfIconButton>
            </div>

            <div class="supplier-tile__info flex flex-row flex-nowrap justify-between">
                <div class="w-full">
                    <!-- IMAGE -->
                    <div v-if="!hideImage" class="mb-4 w-full">
                        <img
                            v-if="supplierInfo.supplier.logo"
                            :src="supplierInfo.supplier.logo.url.logo"
                            alt=""
                            class="block h-auto w-auto max-w-full"
                        />
                        <div
                            v-else
                            class="font-copy-sm-strong max-w-[200px] border border-2 border-dashed px-4 py-4 text-center text-xs text-subdued"
                        >
                            {{ $t('components.supplierTile.missingLogo') }}
                        </div>
                    </div>

                    <!-- Supplier name -->
                    <div>
                        <span class="font-copy-md-strong mb-2" :class="{ '': infoPrice && $can('seePrices') }">
                            {{ supplierInfo.supplier.name }}
                        </span>
                        <span v-if="showFactoryName" class="font-copy-sm-strong">
                            {{ supplierInfo.supplier.factoryName }}
                        </span>
                        <FormattedAddress
                            v-if="showAddress"
                            class="mt-1"
                            decorate-location-code
                            small
                            :address="supplierAddressObject"
                        />

                        <!-- Disposal -->
                        <div
                            v-if="supplierInfo.supplier.factoryType || supplierInfo.supplier.disposerNumber"
                            class="mt-4 flex items-center gap-4"
                        >
                            <SfTag v-if="supplierInfo.supplier.factoryType">
                                {{ $t(`pages.organization.factoryList.type.${supplierInfo.supplier.factoryType}`) }}
                                <span v-if="isFactoryTypeOfLandfill" class="ml-1">
                                    {{ getLandfillClassName }}
                                </span>
                            </SfTag>
                            <span v-if="supplierInfo.supplier.disposerNumber" class="font-numbers-sm text-subdued">
                                {{ supplierInfo.supplier.disposerNumber }}
                            </span>
                        </div>
                    </div>

                    <!-- Supplier Information -->
                    <p
                        v-if="showImportantInfo && supplierInfo.supplier.factoryImportantInformation"
                        muted
                        small
                        spaced-top-small
                        block
                        break-words
                        class="font-copy-sm mt-4 break-words text-subdued"
                    >
                        {{ supplierInfo.supplier.factoryImportantInformation }}
                    </p>

                    <!-- Distance -->
                    <p
                        v-if="showDistance"
                        muted
                        small
                        spaced-top-small
                        block
                        break-words
                        class="font-copy-sm mt-4 break-words text-subdued"
                    >
                        {{ $t('components.supplierTile.constructionSiteDistance') }}:
                        <span v-if="supplierInfo.distance || supplierInfo.duration" class="font-bold">
                            {{ distanceInfo }}
                        </span>
                        <template v-else-if="!distanceCalculated">
                            {{ $t('components.supplierTile.constructionSiteDistanceCalculation') }}
                        </template>
                        <template v-else>
                            {{ $t('components.supplierTile.constructionSiteDistanceNotAvailable') }}
                        </template>
                    </p>
                </div>

                <div v-if="hasSidebar" class="ml-4">
                    <div
                        v-if="infoPrice && $can('seePrices')"
                        class="supplier-tile__info-price-tag"
                        :class="{ 'supplier-tile__info-price-tag--logo': supplierInfo.supplier.logo }"
                    >
                        <PriceTag>
                            {{ $n(infoPrice * 1000, 'currency') }}&nbsp;/&nbsp;t
                            <template v-if="infoPriceSuffix" #suffix>{{ infoPriceSuffix }}</template>
                        </PriceTag>
                    </div>

                    <SfIconButton
                        v-if="showContactOrb"
                        :href="`tel:${assemblePhoneNumber(supplierInfo.supplier.phone)}`"
                        tag="a"
                    >
                        <template #icon="iconProps"><SfCallIcon v-bind="iconProps" /></template>
                    </SfIconButton>
                </div>
            </div>
        </div>

        <!-- Overall Rating -->
        <div v-if="showOverallRating" small class="supplier-tile__inline-rating">
            <span class="font-copy-sm">{{ $t('components.supplierTile.rating.overall') }}</span>

            <StarIcon
                v-for="i in 3"
                :key="i"
                :class="{ 'supplier-tile__rating-star--active': i <= getRatingStarValue('overall') }"
                class="supplier-tile__rating-star supplier-tile__rating-star--inline"
            />
            <span class="font-copy-sm ml-4">
                <span class="font-bold">{{ $n(_get(supplierInfo, 'rating.overall', 0), 'float') }}</span>
                ({{ _get(supplierInfo, 'rating.overallCount', 0) }})
            </span>
        </div>

        <!-- Material Rating -->
        <div v-if="showMaterialRating" small class="supplier-tile__inline-rating">
            <span class="font-copy-sm">{{ $t('components.supplierTile.rating.material') }}</span>

            <StarIcon
                v-for="i in 3"
                :key="i"
                :class="{ 'supplier-tile__rating-star--active': i <= getRatingStarValue('material') }"
                class="supplier-tile__rating-star supplier-tile__rating-star--inline"
            />
            <span class="font-copy-sm ml-4">
                <span class="font-bold">{{ $n(_get(supplierInfo, 'rating.material', 0), 'float') }}</span>
                ({{ _get(supplierInfo, 'rating.materialCount', 0) }})
            </span>
        </div>

        <!-- Opening Hours -->
        <div
            v-if="businessHoursType !== 'none' && supplierInfo.openingTimestamp && supplierInfo.closingTimestamp"
            :class="{ '-mb-4 border-t border-t-divider': businessHoursAccordion }"
        >
            <BusinessHours
                :opening-timestamp="supplierInfo.openingTimestamp"
                :closing-timestamp="supplierInfo.closingTimestamp"
                :opening-times="openingTimes"
                :accordion="businessHoursAccordion"
            />
        </div>

        <!-- Eco Badge -->
        <div v-if="showEcoBadge" class="supplier-tile__eco-badge">
            <EnvIcon class="supplier-tile__eco-badge-icon" />
            <div>
                <Words block small bold>
                    {{ $t('pages.checkout.deliverySupplierSelection.ecoHeadline') }}
                </Words>
                <Words block small>
                    {{
                        $t('pages.checkout.deliverySupplierSelection.ecoDescription', {
                            distance: $n(supplierInfo.distance / 1000, 'distance'),
                        })
                    }}
                </Words>
            </div>
        </div>

        <!-- Show More -->
        <div v-if="showMore" class="flex items-center justify-between">
            <SfLink tag="button" bold @click.stop="$emit('more-action', supplierInfo)">
                {{ $t('components.supplierTile.detailPageLabel') }}
            </SfLink>
            <div
                v-if="morePrice !== null && morePrice !== undefined && $can('seePrices')"
                class="supplier-tile__more-price-tag"
            >
                <PriceTag>{{ $n(morePrice * 1000, 'currency') }}&nbsp;/&nbsp;t</PriceTag>
            </div>
        </div>

        <div v-if="hasAdditionalSupplierInformation" class="mt-4 flex flex-col items-start gap-2">
            <!-- Supplier Phone Number -->
            <SfLink
                v-if="showPhoneNumber && supplierInfo.supplier.phone"
                :href="`tel:${assemblePhoneNumber(supplierInfo.supplier.phone)}`"
                target="_blank"
                size="sm"
                bold
            >
                <template #leading-icon="iconProps">
                    <PhoneMicroIcon width="16" height="16" v-bind="iconProps" />
                </template>
                {{ assemblePhoneNumber(supplierInfo.supplier.phone) }}
            </SfLink>

            <!-- Supplier email -->
            <SfLink v-if="showEmail" :href="`mailto:${supplierInfo.supplier.email}`" target="_blank" size="sm" bold>
                <template #leading-icon="iconProps">
                    <EmailIcon width="16" height="16" v-bind="iconProps" />
                </template>
                {{ supplierInfo.supplier.email }}
            </SfLink>

            <!-- Supplier website -->
            <SfLink
                v-if="showWebsite && supplierInfo.supplier.web"
                :href="supplierInfo.supplier.web"
                target="_blank"
                size="sm"
                bold
            >
                <template #leading-icon="iconProps">
                    <InternetIcon width="16" height="16" v-bind="iconProps" />
                </template>
                {{ supplierInfo.supplier.web }}
            </SfLink>
        </div>

        <div v-if="showDescription && supplierInfo.supplier.description" class="border-t pt-4">
            <Words bold block spaced-bottom>{{ $t('components.supplierTile.description') }}</Words>
            <Words small block>
                <TextBlock :text="supplierInfo.supplier.description" />
            </Words>
        </div>

        <div v-if="showRating && rating" class="supplier-tile__rating border-t pt-4">
            <Words bold block>{{ $t('components.supplierTile.rating.title') }}</Words>
            <div class="supplier-tile__rating-item">
                <Words>
                    {{ $t('components.supplierTile.rating.overall') }}
                </Words>
                <div class="supplier-tile__rating-rating">
                    <StarIcon
                        v-for="i in 3"
                        :key="i"
                        :class="{ 'supplier-tile__rating-star--active': i <= roundValue(rating.overall.value) }"
                        class="supplier-tile__rating-star supplier-tile__rating-star--huge"
                    />
                    <Words v-if="rating.overall.count" block tiny class="supplier-tile__rating-count">
                        <Words bold>{{ $n(rating.overall.value, 'float') }}</Words>
                        ({{ rating.overall.count }})
                    </Words>
                </div>
            </div>
            <div class="supplier-tile__rating-list">
                <div v-for="(r, k) in rating.parts" :key="k" class="supplier-tile__rating-item">
                    <Words small>
                        {{ $t(`components.supplierTile.rating.${k}`) }}
                    </Words>
                    <div class="supplier-tile__rating-rating">
                        <StarIcon
                            v-for="i in 3"
                            :key="i"
                            :class="{ 'supplier-tile__rating-star--active': i <= roundValue(r.value) }"
                            class="supplier-tile__rating-star"
                        />
                        <Words v-if="rating.overall.count" tiny block class="supplier-tile__rating-count">
                            <Words bold>{{ $n(r.value, 'float') }}</Words>
                            ({{ r.count }})
                        </Words>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { assemblePhoneNumber, asyncDelay } from '@/services/utils';
import { getFormattedDuration } from '@/services/utils/date';
import { getDistance } from '@/services/GoogleMaps';
import routeContext from '@/plugins/mixins/routeContext';
import { CONTEXT_PLATFORM } from '@/constants/context';
import _uniq from 'lodash/uniq';
import _get from 'lodash/get';

import { LANDFILL_CODE_TO_NAME_MAPPING, FACTORY_TYPES } from '@/constants/disposal';

import BusinessHours from '@/components/Supplier/BusinessHours';
import FormattedAddress from '@/components/FormattedAddress';
import PriceTag from '@/components/PriceTag';
import TextBlock from '@/components/Typography/TextBlock';
import Words from '@/components/Typography/Words';

import StarIcon from '@/assets/icons/star_rating.svg';
import PhoneMicroIcon from '@/assets/icons/micro/phone.svg';
import EmailIcon from '@/assets/icons/micro/email.svg';
import InternetIcon from '@/assets/icons/micro/internet.svg';
import SchuettflixLogo from '@/assets/schuettflix_logo.svg';
import EnvIcon from '@/assets/icons/environment.svg';

import { SfTag, SfLink, SfIconButton, SfCallIcon, SfSysChevronRightIcon } from '@schuettflix/vue-components';

async function distanceCalculation(supplier, constructionSite, retryCount, delayMultiplier = 1) {
    try {
        return await getDistance(supplier.location, constructionSite.location);
    } catch (err) {
        --retryCount;
        if (retryCount === 0) throw err;
        await asyncDelay(1000 * delayMultiplier);
        return distanceCalculation(supplier, constructionSite, retryCount, delayMultiplier + 1);
    }
}

export default {
    name: 'SupplierTile',
    components: {
        BusinessHours,
        FormattedAddress,
        PriceTag,
        TextBlock,
        Words,

        StarIcon,
        PhoneMicroIcon,
        EmailIcon,
        InternetIcon,
        SchuettflixLogo,
        EnvIcon,

        SfTag,
        SfLink,
        SfIconButton,
        SfCallIcon,
        SfSysChevronRightIcon,
    },
    mixins: [routeContext],
    props: {
        supplierInfo: {
            type: Object,
            required: true,
        },
        constructionSite: {
            type: Object,
            default: null,
        },
        headline: {
            type: String,
            default: null,
        },
        showHeadline: {
            type: Boolean,
            default: false,
        },
        hasAction: {
            type: Boolean,
            default: false,
        },
        showAddress: {
            type: Boolean,
            default: false,
        },
        showDistance: {
            type: Boolean,
            default: false,
        },
        infoPrice: {
            type: Number,
            default: null,
        },
        infoPriceSuffix: {
            type: String,
            default: null,
        },
        showContactOrb: {
            type: Boolean,
            default: false,
        },
        showOverallRating: {
            type: Boolean,
            default: false,
        },
        showMaterialRating: {
            type: Boolean,
            default: false,
        },
        businessHoursType: {
            type: String,
            validator: v => ['none', 'default', 'open', 'accordion'].includes(v),
            default: 'none',
        },
        showMore: {
            type: Boolean,
            default: false,
        },
        morePrice: {
            type: Number,
            default: null,
        },
        showPhoneNumber: {
            type: Boolean,
            default: false,
        },
        showEmail: {
            type: Boolean,
            default: false,
        },
        showWebsite: {
            type: Boolean,
            default: false,
        },
        showDescription: {
            type: Boolean,
            default: false,
        },
        showRating: {
            type: Boolean,
            default: false,
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        disableDistanceFetch: {
            type: Boolean,
            default: false,
        },
        highlight: {
            type: Boolean,
            default: false,
        },
        showBestPriceBadge: {
            type: Boolean,
            default: false,
        },
        showEcoBadge: {
            type: Boolean,
            default: false,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
        levitate: {
            type: Boolean,
            default: false,
        },
        spaceless: {
            type: Boolean,
            default: false,
        },
        hideImage: {
            type: Boolean,
            default: false,
        },
        showFactoryName: {
            type: Boolean,
            default: false,
        },
        showImportantInfo: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            distanceCalculated: false,
        };
    },
    computed: {
        computedRatingClasses() {
            return 'flex flex-row flex-nowrap items-center justify-start gap-2';
        },

        isPlatform() {
            return this.inRouteContext(CONTEXT_PLATFORM);
        },

        hasAdditionalSupplierInformation() {
            return (
                (this.showPhoneNumber && this.supplierInfo.supplier.phone) ||
                this.showEmail ||
                (this.showWebsite && this.supplierInfo.supplier.web) ||
                (this.showDescription && this.supplierInfo.supplier.description)
            );
        },

        hasSidebar() {
            return (this.infoPrice && this.$can('seePrices')) || this.showContactOrb;
        },

        businessHoursAccordion() {
            return !!(this.businessHoursType === 'accordion' && this.supplierInfo.supplier.openingTimes);
        },

        openingTimes() {
            return ['accordion', 'open'].includes(this.businessHoursType)
                ? this.supplierInfo.supplier.openingTimes
                : [];
        },

        rating() {
            if (!this.supplierInfo.rating || Object.keys(this.supplierInfo.rating).length === 0) {
                return null;
            }

            const keys = _uniq(Object.keys(this.supplierInfo.rating).map(i => i.match(/^[a-z]+/)[0]));

            const ratings = keys.reduce((acc, key) => {
                return {
                    ...acc,
                    [key]: {
                        value: this.supplierInfo.rating[key],
                        count: this.supplierInfo.rating[`${key}Count`],
                    },
                };
            }, {});

            const { overall, ...parts } = ratings;
            return { overall, parts };
        },

        supplierAddressObject() {
            const supplier = this.supplierInfo?.supplier;
            return {
                address: supplier?.factoryAddress,
                location: supplier?.location,
            };
        },

        distanceInfo() {
            const parts = [];
            if (this.supplierInfo.distance) {
                parts.push(`${this.$n(this.supplierInfo.distance / 1000, 'distance')} ${this.$t('units.kilometer')}`);
            }
            if (this.supplierInfo.duration) {
                parts.push(getFormattedDuration(this.supplierInfo.duration));
            }
            return parts.join(', ');
        },

        getLandfillClassName() {
            return LANDFILL_CODE_TO_NAME_MAPPING[this.supplierInfo.supplier.landfillClass];
        },

        isFactoryTypeOfLandfill() {
            return FACTORY_TYPES.LANDFILL === this.supplierInfo.supplier.factoryType;
        },
    },
    watch: {
        constructionSite() {
            this.fetchDistanceInfo();
        },
        supplier() {
            this.fetchDistanceInfo();
        },
        showDistance() {
            this.fetchDistanceInfo();
        },
    },
    mounted() {
        this.fetchDistanceInfo();
    },
    methods: {
        _get,
        assemblePhoneNumber,
        getFormattedDuration,

        roundValue(value) {
            return Math.round(value);
        },

        getRatingStarValue(name) {
            return Math.round(_get(this.supplierInfo, `rating.${name}`, 0));
        },

        async fetchDistanceInfo() {
            this.distanceCalculated = false;

            if (
                (this.supplierInfo.distance && this.supplierInfo.duration) ||
                this.disableDistanceFetch ||
                !this.showDistance
            ) {
                this.distanceCalculated = true;
            } else if (this.supplierInfo.supplier.location && this.constructionSite && this.constructionSite.location) {
                try {
                    const { distance, duration } = await distanceCalculation(
                        this.supplierInfo.supplier,
                        this.constructionSite,
                        3
                    );
                    this.$set(this.supplierInfo, 'distance', distance);
                    this.$set(this.supplierInfo, 'duration', duration);
                } catch (err) {
                    this.$logger().log(`Error catching distance: ${err}`);
                }
                this.distanceCalculated = true;
            }
        },
    },
};
</script>

<style lang="scss">
$supplier-tile-vertical-spacing: 24px;
$supplier-tile-border-size: 2px;
$supplier-tile-horizontal-margin: 2px;
$supplier-tile-horizontal-spacing: 15px - $supplier-tile-border-size - $supplier-tile-horizontal-margin;
$supplier-tile-horizontal-spacing-lg: 25px - $supplier-tile-border-size - $supplier-tile-horizontal-margin;

.supplier-tile {
    display: grid;
    margin: 0 $supplier-tile-horizontal-margin;
    padding: 20px 0;
    grid-template-columns: $supplier-tile-horizontal-spacing 1fr $supplier-tile-horizontal-spacing;
    grid-column-gap: 0;
    grid-row-gap: $supplier-tile-vertical-spacing;
    background-color: $color-white;
    border: $supplier-tile-border-size solid $color-white;
    border-bottom-color: $color-lightGrey;

    @media only screen and (min-width: $layout-desktop-min) {
        grid-template-columns: $supplier-tile-horizontal-spacing-lg 1fr $supplier-tile-horizontal-spacing-lg;
    }

    > * {
        grid-column: 2 / 3;
    }
}

.supplier-tile--no-border {
    border-bottom-color: $color-white;
}

.supplier-tile--highlight,
.supplier-tile--no-border.supplier-tile--highlight {
    border-color: $color-red;
}

.supplier-tile--best-price {
    padding-top: 0;
}

.supplier-tile__best-price-badge {
    grid-column: 1 / -1;
    justify-self: end;
    background-color: $color-red;
    color: $color-white;
    padding: 8px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-size: 12px;
    line-height: 1;
}

.supplier-tile__best-price-badge-image {
    margin-right: 10px;

    .f-highlight,
    path {
        fill: $color-white;
    }
}

.supplier-tile__info-price-tag--logo {
    margin-top: 5px;
}

.supplier-tile__inline-rating {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    > * + * {
        margin-left: 13px;
    }
}

.supplier-tile__rating-star {
    width: 16px;
    height: 16px;
    path {
        transition: fill 0.2s ease;
    }

    & + & {
        margin-left: 10px;
    }
}

.supplier-tile__rating-star--inline {
    width: 11px;
    height: 11px;

    & + & {
        margin-left: 6px;
    }
}

.supplier-tile__rating-star--huge {
    width: 20px;
    height: 20px;
}

.supplier-tile__rating-star--active {
    path {
        fill: $color-yellow;
    }
}

.supplier-tile__eco-badge {
    background-color: rgba($color-green, 0.15);
    color: $color-green;
    margin-top: $supplier-tile-vertical-spacing;
    padding: 6px $supplier-tile-horizontal-spacing;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    grid-column: 1 / -1;
    justify-self: start;

    @media only screen and (min-width: $layout-desktop-min) {
        padding-left: $supplier-tile-horizontal-spacing-lg;
        padding-right: $supplier-tile-horizontal-spacing-lg;
    }
}

.supplier-tile__eco-badge-icon {
    margin-right: 6px;
}

.supplier-tile__text-icon {
    display: grid;
    grid-template-columns: 14px 1fr;
    grid-column-gap: 10px;
}

.supplier-tile__description {
    border-top: $border-solid-2px;
    padding-top: $supplier-tile-vertical-spacing;
}

.supplier-tile__rating {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $supplier-tile-vertical-spacing;
}

.supplier-tile__rating-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
}

.supplier-tile__rating-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.supplier-tile__rating-rating {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
}

.supplier-tile__rating-count {
    width: 8ch;
    text-align: right;
}

.supplier-tile--levitate {
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
    transition:
        transform 0.2s ease,
        box-shadow 0.2s ease;
    margin-bottom: 20px;
}

.supplier-tile--levitate:active {
    transform: translateY(5px);
    box-shadow: $boxShadow-card;
}

.supplier-tile--spaceless {
    grid-template-columns: 0px 1fr 0px;
    margin: 0px;
    padding: 0px;
    border: 0;
}
</style>

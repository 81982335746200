<template>
    <LayoutPage
        class="factory-list"
        screen-name="seller-factory-list"
        :parent-route="parentRoute"
        :child-routes="childRoutes"
    >
        <div slot="pageTitle">
            {{ $route.params.pageTitle ? $route.params.pageTitle : $t('pages.organization.factoryList.pageTitle') }}
        </div>

        <Card spaceless class="factory-list__filter-card">
            <FilterBox
                v-model="filter"
                :default-filter="filter"
                :endpoint="dataEndpoint"
                inline-mode
                no-padding
                @update="refreshList()"
            >
                <template #default="filterScope">
                    <TextField
                        v-model="filterScope.filter.search"
                        small
                        :label="$t('pages.organization.factoryList.factorySearchLabel')"
                    />
                </template>
            </FilterBox>
        </Card>

        <div v-if="!hasAnyFactory" class="factory-list__add-factory-cta">
            <Headline :level="2">
                {{ $t('pages.organization.factoryList.newFactoryLabel') }}
            </Headline>
            <Words block spaced-bottom>
                {{ $t('pages.organization.factoryList.noFactoryAvailable') }}
            </Words>
            <BaseButton
                v-if="$can('createFactory')"
                primary
                class="factory-list__add-factory-cta-button"
                @click="openFactoryDraftFlyout()"
            >
                <PlusIcon slot="left" class="icon--inline" />
                {{ $t('pages.organization.factoryList.newFactoryLabel') }}
            </BaseButton>
        </div>

        <template v-else>
            <div class="factory-list__new-plant-row">
                <BaseButton
                    v-if="$can('createFactory')"
                    class="factory-list__create-button"
                    primary
                    data-test="factory-list-create-button"
                    @click="openFactoryDraftFlyout()"
                >
                    <PlusIcon slot="left" class="icon--inline" />
                    {{ $t('pages.organization.factoryList.newFactoryLabel') }}
                </BaseButton>
            </div>
            <!-- List of draft locations -->
            <div
                v-if="hasFactoryDrafts"
                v-can:createFactory
                class="factory-list__listing factory-list__listing--drafts"
            >
                <div class="factory-list__new-plant-row">
                    <Words :spaced-top="!$root.isDesktop">
                        <Words bold>
                            {{ $t('pages.organization.factoryList.incompleteLocations') }}
                        </Words>
                    </Words>
                </div>
                <div v-if="$root.isDesktop" class="factory-list__row factory-list__row-header">
                    <Words bold small>{{ $t('pages.organization.factoryList.tableHeaders.ID') }}</Words>
                    <Words bold small>{{ $t('pages.organization.factoryList.tableHeaders.name') }}</Words>
                    <Words bold small>{{ $t('pages.organization.factoryList.tableHeaders.address') }}</Words>
                </div>

                <transition name="fade" mode="out-in">
                    <div>
                        <Card
                            v-for="factoryDraft in factoryDrafts"
                            :key="factoryDraft.id"
                            class="factory-list__card mb-4"
                            spaceless
                            @click="openFactoryDraft(factoryDraft)"
                        >
                            <div class="content">
                                <div class="factory-list__row">
                                    <Words v-if="!$root.isDesktop" tiny spaced-bottom-tiny>
                                        {{ $t('pages.organization.factoryList.tableHeaders.ID') }}
                                    </Words>
                                    <Words :spaced-bottom="!$root.isDesktop">{{ factoryDraft.factoryNumber }}</Words>

                                    <Words v-if="!$root.isDesktop" tiny spaced-bottom-tiny>
                                        {{ $t('pages.organization.factoryList.tableHeaders.name') }}
                                    </Words>
                                    <Words bold :spaced-bottom="!$root.isDesktop">{{ factoryDraft.name }}</Words>

                                    <Words v-if="!$root.isDesktop" tiny spaced-bottom-tiny>
                                        {{ $t('pages.organization.factoryList.tableHeaders.address') }}
                                    </Words>
                                    <Words :spaced-bottom="!$root.isDesktop">
                                        <FormattedAddress hide-location-code two-line-address :address="factoryDraft" />
                                    </Words>

                                    <div>&nbsp;</div>
                                    <div class="factory-list__button-wrapper">
                                        <ProgressDisplay :progress="factoryDraft.completionPercentage" />
                                        <BaseButton class="factory-list__button" arrow-right align-right>
                                            {{ $t('pages.organization.factoryList.completeLocation') }}
                                        </BaseButton>
                                    </div>
                                </div>

                                <transition name="fade">
                                    <div
                                        v-if="isUpdatingFactoryId === factoryDraft.id"
                                        class="factory-list__row-inactive"
                                    >
                                        &nbsp;
                                    </div>
                                </transition>
                            </div>
                        </Card>
                    </div>
                </transition>
            </div>

            <!-- List of complete locations -->
            <div v-if="hasCompleteFactories" class="factory-list__listing factory-list__listing--complete">
                <div class="factory-list__new-plant-row">
                    <Words :spaced-top="!$root.isDesktop">
                        <Words bold>
                            {{
                                $tc('pages.organization.factoryList.factoryCountLabel', totalFactoryCount, {
                                    count: totalFactoryCount,
                                })
                            }}
                        </Words>
                        <Words class="factory-list__open-closed">
                            {{
                                $tc('pages.organization.factoryList.openCloseLabel', totalFactoryCount, {
                                    open: openFactoryCount,
                                    close: closeFactoryCount,
                                })
                            }}
                        </Words>
                    </Words>
                </div>

                <div v-if="$root.isDesktop" class="factory-list__row factory-list__row-header">
                    <Words bold small>{{ $t('pages.organization.factoryList.tableHeaders.ID') }}</Words>
                    <Words bold small>{{ $t('pages.organization.factoryList.tableHeaders.name') }}</Words>
                    <Words bold small>{{ $t('pages.organization.factoryList.tableHeaders.address') }}</Words>
                    <Words bold small>{{ $t('pages.organization.factoryList.tableHeaders.type') }}</Words>
                    <Words bold small>{{ $t('pages.organization.factoryList.tableHeaders.status') }}</Words>
                </div>

                <transition name="fade" mode="out-in">
                    <Words v-if="factories && factories.count === 0" block muted small centered spaced-top>
                        {{ $t('pages.organization.factoryList.noFactoryFound') }}
                    </Words>

                    <div v-else-if="factories">
                        <Card
                            v-for="factory in factories.items"
                            :key="factory.id"
                            class="factory-list__card mb-4"
                            spaceless
                            data-test="factory-list-card"
                            @click="openFactory(factory)"
                        >
                            <div class="content">
                                <div class="factory-list__row">
                                    <Words v-if="!$root.isDesktop" tiny spaced-bottom-tiny>
                                        {{ $t('pages.organization.factoryList.tableHeaders.ID') }}
                                    </Words>
                                    <Words :spaced-bottom="!$root.isDesktop">{{ factory.factoryNumber }}</Words>

                                    <Words v-if="!$root.isDesktop" tiny spaced-bottom-tiny>
                                        {{ $t('pages.organization.factoryList.tableHeaders.name') }}
                                    </Words>
                                    <Words bold :spaced-bottom="!$root.isDesktop">{{ factory.name }}</Words>

                                    <Words v-if="!$root.isDesktop" tiny spaced-bottom-tiny>
                                        {{ $t('pages.organization.factoryList.tableHeaders.address') }}
                                    </Words>
                                    <Words :spaced-bottom="!$root.isDesktop">
                                        <FormattedAddress hide-location-code two-line-address :address="factory" />
                                    </Words>

                                    <Words v-if="!$root.isDesktop" tiny spaced-bottom-tiny>
                                        {{ $t('pages.organization.factoryList.tableHeaders.type') }}
                                    </Words>
                                    <Words :spaced-bottom="!$root.isDesktop">{{ formatTypeFactory(factory) }}</Words>

                                    <Words v-if="!$root.isDesktop" tiny spaced-bottom-small>
                                        {{ $t('pages.organization.factoryList.tableHeaders.status') }}
                                    </Words>
                                    <div>
                                        <ToggleSwitch
                                            color="green"
                                            :disabled="isReadOnly(factory)"
                                            :value="factory.open"
                                            @input="onToggleFactoryState(factory)"
                                        />
                                    </div>
                                </div>

                                <transition name="fade">
                                    <div v-if="isUpdatingFactoryId === factory.id" class="factory-list__row-inactive">
                                        &nbsp;
                                    </div>
                                </transition>
                            </div>
                        </Card>
                    </div>
                </transition>
            </div>
        </template>

        <LocationDraftFlyout
            :is-active="factoryFlyoutOpen"
            :organization-id="supplierOrganizationId || organization.id"
            @closed="factoryFlyoutOpen = false"
            @updateList="refreshList()"
        />
    </LayoutPage>
</template>

<script>
import FactoryApi from '@/services/Api/Factory';
import FactoryDraftsApi from '@/services/Api/FactoryDrafts';
import Toaster from '@/services/Toaster';
import factoryMixin from '@/plugins/mixins/factoryForm/factory';
import { LANDFILL_CLASSES } from '@/constants/disposal';
import { hasAddressWithLocationCode } from '@/services/utils/address';
import { mapActions, mapGetters } from 'vuex';

import { PARENT_ROUTE_NAME } from '@/constants/factoryForm';

import BaseButton from '@/components/Button/Button';
import Card from '@/components/Layout/Card';
import LocationDraftFlyout from '@/_components/LocationDraftFlyout/LocationDraftFlyout';
import FilterBox from '@/components/Filter/FilterBox';
import FormattedAddress from '@/components/FormattedAddress';
import Headline from '@/components/Typography/Headline';
import LayoutPage from '@/components/Layout/Page.v2';
import ProgressDisplay from '@/_components/ProgressDisplay/ProgressDisplay';
import TextField from '@/components/Form/TextField.v2';
import ToggleSwitch from '@/components/Form/ToggleSwitch';
import Words from '@/components/Typography/Words';

import PlusIcon from '@/assets/icons/regular/plus.svg';
import _cloneDeep from 'lodash/cloneDeep';

export default {
    name: 'LocationList',
    components: {
        BaseButton,
        Card,
        LocationDraftFlyout,
        FilterBox,
        FormattedAddress,
        Headline,
        LayoutPage,
        ProgressDisplay,
        TextField,
        ToggleSwitch,
        Words,

        PlusIcon,
    },
    mixins: [factoryMixin],
    beforeRouteUpdate(to, from, next) {
        const isFactoryView = to?.params?.factoryId;
        this.setNavigationCollapsed(isFactoryView);

        if (to.name === this.$root.findRouteName(PARENT_ROUTE_NAME)) {
            this.refreshList();
        }

        next();
    },
    props: {
        parentRoute: {
            type: String,
            default: null,
        },
        supplierOrganizationId: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            factories: null,
            factoryDrafts: null,
            dataEndpoint: FactoryApi,
            isUpdatingFactoryId: null,
            filter: {
                search: '',
            },
            factoryFlyoutOpen: false,
            selectedFactoryId: null,
            landfillClasses: [...LANDFILL_CLASSES],
        };
    },
    computed: {
        ...mapGetters('user', ['organization', 'isPlatformAdministrator']),
        hasAnyFactory() {
            return this.hasFactoryDrafts || this.hasCompleteFactories;
        },
        hasCompleteFactories() {
            return !!this.factories?.count;
        },
        hasFactoryDrafts() {
            return !!this.factoryDrafts?.length;
        },
        totalFactoryCount() {
            return this.factories?.items?.length ?? 0;
        },
        openFactoryCount() {
            if (!this.factories) return 0;
            return this.factories.items.filter(factory => factory.open === true).length;
        },
        closeFactoryCount() {
            if (!this.factories) return 0;
            return this.factories.items.filter(factory => factory.open === false).length;
        },
        childRoutes() {
            return [
                'management__settings__factory-management__factory',
                'management__settings__factory-management__factory__factoryEdit',
            ];
        },
    },
    created() {
        this.refreshList();
        this.setSupplierOrganizationId(
            this.isPlatformAdministrator ? this.$route.params?.supplierOrganizationId : this.organization.id
        );
    },
    methods: {
        ...mapActions('session', ['setNavigationCollapsed']),
        ...mapActions('factoryManagement', ['setSupplierOrganizationId']),
        hasAddressWithLocationCode,

        formatTypeFactory(factory) {
            if (!factory) return;
            const arrFactoryType = [];
            if (factory.usages.includes('selling')) {
                arrFactoryType.push(this.$t('pages.organization.factoryList.type.selling'));
            }
            if (factory.usages.includes('disposal')) {
                const factoryType = factory?.disposalSettings?.factoryType;
                if (!factoryType) return;
                if (factoryType === 'landfill') {
                    const factoryLandfillClass = factory?.disposalSettings?.landfillClass;
                    if (this.landfillClasses.includes(factoryLandfillClass)) {
                        arrFactoryType.push(
                            `${this.$t(`pages.organization.factoryList.type.${factoryType}`)} (${this.$t(
                                'components.factoryManagement.landfillTypes.' + factoryLandfillClass
                            )})`
                        );
                    } else {
                        arrFactoryType.push(this.$t(`pages.organization.factoryList.type.${factoryType}`));
                    }
                } else {
                    arrFactoryType.push(this.$t('pages.organization.factoryList.type.' + factoryType));
                }
            }

            return arrFactoryType.join(', ');
        },

        openFactoryDraftFlyout() {
            this.factoryFlyoutOpen = true;
        },

        openFactory(factory = null) {
            let routerParams = null;

            if (factory) {
                routerParams = {
                    name: this.$root.findRouteName('factoryEdit'),
                    params: {
                        factoryId: factory.id,
                        factory: factory,
                        parentRoute: this.$route.name,
                        parentRouteName: this.$t('pages.backTitle.factories'),
                        ...this.$route.params,
                    },
                    query: { route: 'name-address' },
                };
            }

            this.$router.push(routerParams);
        },

        openFactoryDraft(factory = null) {
            let routerParams = null;

            if (factory) {
                routerParams = {
                    name: this.$root.findRouteName('factoryDraftEdit'),
                    params: {
                        factoryId: factory.id,
                        factory: factory,
                        parentRoute: this.$route.name,
                        parentRouteName: this.$t('pages.backTitle.factories'),
                        ...this.$route.params,
                    },
                    query: { route: 'name-address' },
                };
            }

            this.$router.push(routerParams);
        },

        async refreshFactoryDraftList() {
            // cancel previous request
            this.cancelSourceFactoryDrafts && this.cancelSourceFactoryDrafts.cancel('canceled-previous-call');
            this.cancelSourceFactoryDrafts = FactoryDraftsApi.createCancelTokenSource();

            try {
                let factoryDrafts;
                const supplierId = this.supplierOrganizationId || this.$route.params?.supplierOrganizationId;
                if (supplierId && this.$can('listFactoryDraftsBySupplierOrganizationId')) {
                    factoryDrafts = await FactoryDraftsApi.getFactoryListByOrganizationId(supplierId);
                    this.factoryDrafts = factoryDrafts?.items?.reverse();
                    return;
                }
                factoryDrafts = await FactoryDraftsApi.getAll();
                this.factoryDrafts = factoryDrafts.reverse();
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                    Toaster.error(this.$t(err.message));
                }
            }
        },

        async refreshFactoryList() {
            // cancel previous request
            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.cancelSource = FactoryApi.createCancelTokenSource();

            try {
                const supplierId = this.supplierOrganizationId || this.$route.params?.supplierOrganizationId;
                this.factories = await FactoryApi.filter(
                    { ...this.filter, supplierId: supplierId },
                    null,
                    null,
                    this.cancelSource
                );
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                    Toaster.error(this.$t(err.message));
                }
            }
        },

        refreshList() {
            return Promise.all([this.refreshFactoryList(), this.refreshFactoryDraftList()]);
        },

        isReadOnly(factory) {
            const factoryClone = _cloneDeep(factory);

            // This is a hack to handle the server response being inconsistent with abilities
            if (!factoryClone.supplierOrganization?.id) {
                factoryClone['supplierOrganization'] = {
                    id: factoryClone.supplierOrganizationId,
                };
            }

            return !this.$can('updateFactory', factoryClone);
        },

        async onToggleFactoryState(factory) {
            this.isUpdatingFactoryId = factory.id;
            this.toggleOpenCloseStatus(factory.id).then(result => {
                this.$set(factory, 'open', result.open);
                this.isUpdatingFactoryId = null;
            });
        },

        async updateFactory(factory) {
            if (this.isReadOnly(factory)) {
                return;
            }

            this.isUpdatingFactoryId = factory.id;

            try {
                const result = await FactoryApi.save({ ...factory, open: !factory.open });
                this.$set(factory, 'open', result.open);
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(this.$t(err.message));
            }

            this.isUpdatingFactoryId = null;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.factory-list {
    &__button-wrapper {
        display: flex;
        justify-content: flex-end;
    }

    &__open-closed {
        color: #7c7f84;
    }

    &__listing {
        &--drafts {
            border-bottom: 1px solid $color-lightGrey-400;
            margin-bottom: 32px;
            padding-bottom: 32px;
        }

        &--complete {
            margin-bottom: 32px;
        }
    }
}

.factory-list__create-button {
    margin-left: auto;
}

.factory-list__add-factory-cta {
    text-align: center;

    @media screen and (max-width: $layout-desktop-max) {
        margin: 15px;
    }
}

.factory-list__add-factory-cta-button {
    margin-left: auto;
    margin-right: auto;
}

.factory-list__row {
    display: grid;
    grid-gap: 8px;
    align-items: center;
    grid-template-columns: 105px 2fr 3fr 2fr 300px;

    @media screen and (max-width: $layout-desktop-max) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.factory-list__row-header {
    background-color: $color-lightGrey-400;
    margin-top: 8px;
    padding: 15px;
}

.factory-list__new-plant-row {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: $layout-desktop-max) {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin: 15px;
    }
}

.factory-list__card {
    cursor: pointer;
    margin: 8px 0px !important;
    position: relative;
}

.factory-list__row-inactive {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(255, 255, 255, 0.9);
}

.factory-list__toggle-button {
    cursor: pointer;
}
</style>

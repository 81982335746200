<template>
    <div class="vehicle-attribute-selection">
        <component
            :is="getMappedAttributeRenderer(attributeOption.value)"
            :scoped-value="getScopedValue(attributeOption)"
            :nesting-level="nestingLevel"
            :attribute-option="attributeOption"
            :purchasable-attributes="purchasableAttributes"
            @change="$emit('change', $event)"
        />

        <div v-if="attributeOption.options" class="vehicle-attribute-selection__options">
            <template v-for="option in attributeOption.options">
                <VehicleAttributeSelection
                    v-if="value.includes(option.value) && option.options"
                    :key="option.identifier"
                    :value="value"
                    :nesting-level="nestingLevel + 1"
                    :attribute-option="option"
                    :purchasable-attributes="purchasableAttributes"
                    @change="$emit('change', $event)"
                />
            </template>
        </div>
    </div>
</template>

<script>
import DefaultAttributeRenderer from '@/pages/Vehicle/components/VehicleAttributeSelection/DefaultAttributeRenderer';
import TroughTypeAttributeRenderer from '@/pages/Vehicle/components/VehicleAttributeSelection/TroughTypeAttributeRenderer';
import Words from '@/components/Typography/Words';

export default {
    name: 'VehicleAttributeSelection',
    components: {
        DefaultAttributeRenderer,
        TroughTypeAttributeRenderer,
        Words,
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        purchasableAttributes: {
            type: Array,
            default: null,
        },
        attributeOption: {
            type: Object,
            default: null,
        },
        nestingLevel: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        getMappedAttributeRenderer(attributeName) {
            switch (attributeName) {
                case 'trough_type':
                    return 'TroughTypeAttributeRenderer';
                default:
                    return 'DefaultAttributeRenderer';
            }
        },

        /**
         * Remap value list to local value, for radio and checkbox components
         * @param attributeOption
         * @return {*[]|*[]|*|null}
         */
        getScopedValue(attributeOption) {
            const flatOptions = Object.values(attributeOption.options).map(option => option.value);
            const options = this.value.filter(v => flatOptions.includes(v)) ?? [];
            if (attributeOption.isMultiple) {
                return options;
            }
            return options[0] ?? null;
        },
    },
};
</script>

<style scoped lang="scss">
.vehicle-attribute-selection__options,
.vehicle-attribute-selection + .vehicle-attribute-selection {
    margin-top: 50px;
}
</style>

import React from 'react';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { FlyoutPage } from '@/constructionProjects/components/FlyoutPage';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { findRouteName } from '@/pages/routerUtils';
import { useOrderScreenName } from '@/pages/Checkout/analytics/react/useOrderScreenName';
import { useTranslation } from 'react-i18next';
import { CategoryCard } from './ProductSelection/CategoryCard';
import { LoadingSpinner } from '@schuettflix/react-components';
import { useQuery } from '@tanstack/react-query';
import { categoriesList } from '@/pages/Checkout/queries';
import { useVuexState } from '@/reactBridge/useVuex';
import store from '@/store';
import { trackCheckoutEvent } from '@/pages/Checkout/trackCheckoutEvent';
const _CategoriesListPage = () => {
    const { vueRouter } = useVueRouter();
    const { t } = useTranslation();
    const orderType = useVuexState(store, state => state.basket.type);
    const locationId = useVuexState(store, state => state.basket.constructionSite?.constructionProjectLocationId);
    const { data, isLoading } = useQuery(categoriesList(orderType, locationId));
    const handleBackButton = () => {
        return vueRouter.push({ name: findRouteName(vueRouter.currentRoute?.meta?.previous) });
    };
    const handleCategorySelection = (category) => {
        store.commit('basket/setCategoryInfo', {
            categoryId: category.id,
            categoryName: category.name,
            imageUrl: category.image.small,
            fromCategoriesList: true,
        });
        store.commit('analytics/setProductSelection', {
            selected_by: 'category',
        });
        trackCheckoutEvent('product', 'categorySelect', {
            id: category.id || 0,
            name: category.name,
        });
        vueRouter
            .push({
            name: findRouteName(vueRouter.currentRoute?.meta?.nextCategoryDetail),
        })
            .catch(() => { });
    };
    const screenName = useOrderScreenName('categorieslist');
    return (<FlyoutPage onCloseAnimationEnd={handleBackButton} headerTitle={t('pages.checkout.selectProduct.categorySelection.header')} body={<div>
                    {isLoading && <LoadingSpinner className="mx-auto p-6 text-center"/>}
                    {!isLoading && data && (<div className="px-4 py-6">
                            <div className="grid grid-cols-2 gap-4">
                                {data.map(category => (<CategoryCard key={category.id} imgSrc={category.image.small} title={category.name} block description={t('pages.checkout.selectProduct.categorySelection.categoryCard.availableProducts', { count: category.availableProductsCount })} dataTest="category-card" onSelect={() => handleCategorySelection(category)}/>))}
                            </div>
                        </div>)}
                </div>} screenName={screenName}/>);
};
export const CategoriesListPage = asVueComponent(_CategoriesListPage);

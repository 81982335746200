<template>
    <LayoutPage class="order-view-page" :screen-name="screenName" is-flyout>
        <template #flyoutHeader>
            <HeaderBar>
                <template #left>
                    <HeaderBarItem button @click="$root.routeBack()">
                        <SfSysArrowLeftIcon size="xs" />
                    </HeaderBarItem>
                </template>

                <template v-if="order" #headline>{{
                    $t('pages.order.orderView.title', { number: order.number })
                }}</template>
                <template v-if="order" #subline>{{ $d(ensureJSTimestamp(order.created), 'short') }}</template>

                <template v-if="order" #right>
                    <ContactOptions v-if="$can('listAllOrders')" :item="order" identifier="order" />
                    <HeaderBarItem v-else-if="showClientContactOptions" button @click="openSupplierDetails()">
                        <SfContactIcon class="mt-2" size="xs" multi-color />
                    </HeaderBarItem>
                    <HeaderBarItem>
                        <OrderContextMenu ref="contextMenu" :order="order" />
                    </HeaderBarItem>
                </template>
            </HeaderBar>
        </template>

        <template v-if="order">
            <OrderViewMap
                :construction-sitelocation="originLocation"
                :supplier-location="destinationLocation"
                :order="order"
                :height="250"
            />
            <OrderHeaderDetails :order="order" />

            <a :id="`tab-navigation-anchor-${eid}`" />

            <div class="order-view-page__tab-navigation">
                <!-- due to "max-height: 100%" of ftscroll_container
                we have a weird dissapearance issue of the tab navigation bar,
                currently doing a quick fix with css, but need to take a deeper look into it -->
                <div class="order-view-page__tab-navigation-inner">
                    <TabNavigation :value="activeNavigation" dark-line spaced @input="updateActiveNavigation">
                        <TabNavigationItem
                            v-for="(navigationComponent, key) in navigationItems"
                            :key="key"
                            :stage="key"
                        >
                            <span
                                :class="{
                                    'order-view-page__tab-navigation-label--new':
                                        order.__hasNewDocuments && key === 'documents',
                                }"
                                class="order-view-page__tab-navigation-label"
                            >
                                {{ $t(`pages.order.orderView.navigationItems.${key}`) }}
                            </span>
                        </TabNavigationItem>
                    </TabNavigation>
                </div>
            </div>

            <div class="tab-content-wrapper bg">
                <!-- render component according to active navigation -->
                <component :is="navigationItems[activeNavigation]" :order="order" :project-position="projectPosition" />
            </div>
        </template>

        <template v-else>
            <LoadingSpinner class="order-view-page__loading-spinner" dark />
        </template>

        <Flyout route="order-view__order-pickup-start" no-header size="small" />
        <Flyout route="order-view__order-supplier-view" no-header size="small" />
        <Flyout route="order-view__order-transports" :screen-name="`${screenName}-joblist`" size="medium" no-header>
            <router-view slot="router-view" :order="order" />
        </Flyout>
    </LayoutPage>
</template>

<script>
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';

import ftscroller from 'ftscroller';
import { ensureJSTimestamp } from '@/services/utils/date';
import OrderApi from '@/services/Api/Order';
import Toaster from '@/services/Toaster';
import { orderHasNewDocuments } from '@/services/utils/spectator';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import routeContext from '@/plugins/mixins/routeContext';
import { EVENT_ORDER_UPDATED } from '@/constants/events';
import { isOrderLineItemGroupTypeDisposal } from '@/constants/disposal';

import ContactOptions from '@/components/ContactOptions';
import Flyout from '@/components/Layout/Flyout';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import ModalActions from '@/components/Modal/ModalActions';
import ModalBox from '@/components/Modal/ModalBox';
import OfferAndInvoiceTab from './components/OfferAndInvoiceTab';
import OrderContextMenu from '@/pages/Order/components/OrderContextMenu';
import OrderDocumentsTab from './components/OrderDocumentsTab';
import OrderHeaderDetails from './components/OrderHeaderDetails';
import OrderOverviewTab from './components/OrderOverviewTab';
import OrderViewMap from './components/OrderViewMap';
import StopIcon from '@/assets/icons/micro/stop.svg';
import TabNavigation from '@/components/Tab/TabNavigation';
import TabNavigationItem from '@/components/Tab/TabNavigationItem';
import Words from '@/components/Typography/Words';

import ArrowIcon from '@/assets/icons/regular/arrow.svg';
import KebabMenuIcon from '@/assets/icons/regular/kebab-menu.svg';
import MessageIcon from '@/assets/icons/regular/message.svg';
import ProjectPositionApi from '@/services/Api/ProjectPosition';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';
import { SfContactIcon, SfSysArrowLeftIcon } from '@schuettflix/vue-components';
import { useGetters } from 'vuex-composition-helpers';

const navigationItems = {
    overview: OrderOverviewTab,
    documents: OrderDocumentsTab,
};

const UPDATE_INTERVAL = 20000;

export default {
    name: 'OrderViewPageV2',

    components: {
        OrderContextMenu,
        OrderHeaderDetails,
        ContactOptions,
        Flyout,
        LayoutPage,
        HeaderBar,
        HeaderBarItem,
        LoadingSpinner,
        ModalActions,
        ModalBox,
        OfferAndInvoiceTab,
        OrderOverviewTab,
        OrderViewMap,
        StopIcon,
        TabNavigation,
        TabNavigationItem,
        Words,
        ArrowIcon,
        KebabMenuIcon,
        MessageIcon,
        SfSysArrowLeftIcon,
        SfContactIcon,
    },

    mixins: [eventHubMixin, routeContext],

    props: {
        orderId: {
            type: [String, Number],
            default: null,
        },
    },

    setup() {
        const { isPlatformAdministrator } = useGetters('user', ['isPlatformAdministrator']);
        const screenName = isPlatformAdministrator.value ? 'platform-order-detail' : 'client-order-detail';
        return {
            screenName,
        };
    },

    data() {
        return {
            eid: `el${this._uid}`,
            activeNavigation: Object.keys(navigationItems)[0],
            isLoading: false,
            isPending: false,
            order: null,
            scroller: null,
            projectPosition: null,
        };
    },

    computed: {
        navigationItems() {
            const additionalItems = {};

            if (this.$can('seePrices')) {
                additionalItems.offersAndInvoices = OfferAndInvoiceTab;
            }

            return Object.assign({}, navigationItems, additionalItems);
        },

        showClientContactOptions() {
            if (this.$can('listOrders')) {
                return this.order.lineItemGroups[0].type === ORDER_LINE_ITEM_GROUP_TYPE.PICKUP;
            }

            return false;
        },

        orderLineItem() {
            return this.order.lineItemGroups[0];
        },

        originLocation() {
            if (this.isShipment || this.isDisposal) {
                return this.order.lineItemGroups[0].loadingSiteLocation;
            }

            return (
                this.order.lineItemGroups[0].unloadingSiteLocation ||
                this.order.lineItemGroups[0].constructionSite.location
            );
        },

        destinationLocation() {
            if (this.isShipment) {
                return this.order.lineItemGroups[0].unloadingSiteLocation;
            }

            if (this.isDisposal) {
                return this.order.lineItemGroups[0].supplier.location;
            }

            return this.order.lineItemGroups[0].loadingLocation;
        },

        isPickup() {
            return this.order.lineItemGroups.every(g => g.type === ORDER_LINE_ITEM_GROUP_TYPE.PICKUP);
        },

        isShipment() {
            return this.order.lineItemGroups.every(g => g.type === ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT);
        },

        isDisposal() {
            return this.order.lineItemGroups.every(g => isOrderLineItemGroupTypeDisposal(g.type));
        },
    },

    watch: {
        orderId(newId, oldId) {
            if (String(newId) !== String(oldId)) {
                this.refresh();
            }
        },
    },

    created() {
        this.refresh();

        this.subscribe(EVENT_ORDER_UPDATED, e => {
            if (String(this.orderId) === String(e.subject.id)) {
                this.refresh();
            }
        });

        this.subscribe('transport.update', () => {
            this.refresh();
        });
    },

    mounted() {
        this.initializeScroller();
        this.$gracefulInterval(this.refresh.bind(this), UPDATE_INTERVAL);
    },

    methods: {
        ensureJSTimestamp,
        async refresh() {
            try {
                this.isLoading = true;
                this.order = await OrderApi.getOneById(this.orderId);

                if (this.order.lineItemGroups[0].projectPositionId) {
                    await this.fetchProjectPosition();
                }

                this.$set(this.order, '__hasNewDocuments', orderHasNewDocuments(this.routeContext, this.order));
            } catch (err) {
                this.$logger().error(err);
                this.isLoading = false;
                Toaster.error(err);
            }
        },

        async fetchProjectPosition() {
            try {
                this.projectPosition = await ProjectPositionApi.getOneById(
                    this.order.lineItemGroups[0].projectPositionId
                );
            } catch (err) {
                this.$logger().error(err);
            }
        },

        openSupplierDetails() {
            this.$router
                .push({
                    name: this.$root.findRouteName('order-supplier-view'),
                    params: {
                        orderId: this.orderId,
                        factoryId: this.order.lineItemGroups[0].factoryId,
                    },
                    query: this.$route.query,
                })
                .catch(() => {});
        },

        updateActiveNavigation(stage) {
            const tabNavigation = document.getElementById(`tab-navigation-anchor-${this.eid}`);
            if (tabNavigation.getBoundingClientRect().top - tabNavigation.parentNode.getBoundingClientRect().top < 0) {
                tabNavigation.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                });
            }

            AnalyticsService.trackClick('orderDetailView', 'tabNavigationChange', {
                orderId: this.orderId,
                activeTab: this.activeNavigation,
            });

            setTimeout(() => {
                this.activeNavigation = stage;
            }, 300);
        },

        initializeScroller() {
            if (this.scroller && this.$el) return;

            let retries = 0;

            this.$gracefulInterval(() => {
                retries++;
                if (retries > 10) {
                    return false;
                }

                const wrapper = this.$el.querySelector('.order-view-page__tab-navigation');
                if (!wrapper) {
                    return true;
                }

                this.scroller = ftscroller.FTScroller(wrapper, {
                    scrollbars: false,
                    scrollingY: false,
                    bounceBezier: ftscroller.CubicBezier(0.7, 0, 0.9, 0.6),
                    updateOnWindowResize: true,
                });
                return false;
            }, 1000);
        },
    },
};
</script>

<style lang="scss">
.order-view-page {
    height: 100%;
    background-color: $color-littleDarkerThanLightMediumGrey;
}

.order-view-page__tab-navigation {
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 9;
    font-size: 0;
    border-bottom: 2px solid #ddddde;

    .tab-navigation__track {
        white-space: nowrap;
    }

    > * {
        bottom: -2px;
    }

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 30px;
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 25%,
            rgba(255, 255, 255, 0) 100%
        );

        z-index: 1;
    }

    &::after {
        left: auto;
        right: 0;
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 25%,
            rgba(255, 255, 255, 0) 100%
        );
    }
}

.order-view-page__tab-navigation > .ftscroller_container {
    max-height: none;
}

.order-view-page__tab-navigation-inner {
    padding-left: 15px;
    padding-right: 15px;
}

.order-view-page__loading-spinner {
    padding: 120px;
}

.order-view-page__tab-navigation-label {
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        right: -10px;
        top: 0;
        width: 5px;
        height: 5px;
        border-radius: 50px;
        background-color: $color-red;
        opacity: 0;
        transition: opacity 0.2s ease-out;
    }
}

.order-view-page__tab-navigation-label--new {
    &::after {
        opacity: 1;
    }
}
</style>

import PlatformUtils from '@/services/Api/Platform/Utils';
import LogService from '@schuettflix/util-log';
import { parseVersion } from '@/services/utils';
import { updateContext } from '@/services/LegalTerms/LegalTerms';
import { normalizeLocation } from '@/services/utils/map';
import { platformInfoQuery, queryClient } from '@/reactBridge/queryClient';
const Log = new LogService('store/platform');
const initialState = {
    apiBaseUrl: window.apibaseurl,
    constructionProjectApiUrl: window.constructionProjectApiUrl,
    gatewayUrl: window.gatewayUrl,
    orderServiceUrl: window.baseUrlOrder,
    positionServiceUrl: window.baseUrlPosition,
    fulfillmentServiceUrl: window.baseUrlFulfillment,
    info: null,
    isMaintenanceActive: false,
    isOffline: false,
    isApp: false,
    isDeviceReady: false,
    isUpdateRequired: false,
    isApiIncompatible: false,
    forcedRerenderKey: Date.now(),
    isCheckingForUpdate: false,
    isUpdateInProgress: false,
    downloadProgress: null,
    openingTimes: null,
    registrationActive: false,
    helpCenterActive: false,
    orderPhoneNumber: null,
    disposalContactData: null,
    countryCheck: {},
    accountingEmail: null,
    serviceEmail: null,
    servicePhone: null,
    orderNotAvailableComingSoonMessageActive: false,
    legal: null,
    platformRegistrationLink: null,
    initialMapLocation: null,
    allPlatformActions: [],
    genericOrderUrl: window.genericOrderUrl,
};
const getters = {
    apiBaseUrl: (state) => state.apiBaseUrl,
    constructionProjectApiUrl: (state) => state.constructionProjectApiUrl,
    gatewayUrl: (state) => state.gatewayUrl,
    orderServiceUrl: (state) => state.orderServiceUrl,
    positionServiceUrl: (state) => state.positionServiceUrl,
    fulfillmentServiceUrl: (state) => state.fulfillmentServiceUrl,
    countryCheck: (state) => state.countryCheck,
    info: (state) => state.info,
    isMaintenanceActive: (state) => state.isMaintenanceActive,
    isOffline: (state) => state.isOffline,
    isApp: (state) => state.isApp,
    isDeviceReady: (state) => state.isDeviceReady,
    isUpdateRequired: (state) => state.isUpdateRequired,
    isApiIncompatible: (state) => state.isApiIncompatible,
    forcedRerenderKey: (state) => state.forcedRerenderKey,
    isCheckingForUpdate: (state) => state.isCheckingForUpdate,
    isUpdateInProgress: (state) => state.isUpdateInProgress,
    downloadProgress: (state) => state.downloadProgress,
    isRegistrationActive: (state) => state.registrationActive,
    isHelpCenterActive: (state) => state.helpCenterActive,
    openingTimes: (state) => state.openingTimes,
    orderPhoneNumber: (state) => state.orderPhoneNumber,
    disposalContactData: (state) => state.disposalContactData,
    serviceEmail: (state) => state.serviceEmail,
    accountingEmail: (state) => state.accountingEmail,
    servicePhone: (state) => state.servicePhone,
    orderNotAvailableComingSoonMessageActive: (state) => state.orderNotAvailableComingSoonMessageActive,
    linkLegalAoesp: (state) => state.legal?.legal_terms_and_conditions_aoesp ?? null,
    linkLegalDataProtectionPolicyGlobal: (state) => state.legal?.legal_data_protection_policy_global ?? null,
    legalDataProtectionPolicyLogin: (state) => state.legal?.legal_data_protection_policy_login ?? null,
    linkLegalImprintGlobal: (state) => state.legal?.legal_imprint_global ?? null,
    legalImprintLogin: (state) => state.legal?.legal_imprint_login ?? null,
    linkLegalTermsAndConditionsCarrier: (state) => state.legal?.legal_terms_and_conditions_carrier ?? null,
    linkLegalTermsAndConditionsCustomer: (state) => state.legal?.legal_terms_and_conditions_customer ?? null,
    linkLegalTermsAndConditionsSeller: (state) => state.legal?.legal_terms_and_conditions_seller ?? null,
    linkLegalTermsOfUse: (state) => state.legal?.legal_terms_of_use ?? null,
    platformRegistrationLink: (state) => state.platformRegistrationLink,
    initialMapLocation: (state) => state.initialMapLocation ?? { lat: 52.02377828289762, lng: 8.533637725680705 },
    allPlatformActions: (state) => state.allPlatformActions,
    genericOrderUrl: (state) => state.genericOrderUrl,
};
const mutations = {
    setApiBaseUrl(state, payload) {
        state.apiBaseUrl = payload;
    },
    setOrderServiceUrl(state, payload) {
        state.orderServiceUrl = payload;
    },
    setPositionServiceUrl(state, payload) {
        state.positionServiceUrl = payload;
    },
    setFulfillmentServiceUrl(state, payload) {
        state.fulfillmentServiceUrl = payload;
    },
    setCountryCheck(state, countryCheck) {
        state.countryCheck = countryCheck;
    },
    setMaintenanceMode(state, payload) {
        state.isMaintenanceActive = payload;
    },
    setOffline(state, payload) {
        state.isOffline = payload;
    },
    setIsApp(state, payload) {
        state.isApp = payload;
        if (true === payload) {
            document.body.classList.add('is--app');
        }
    },
    setIsDeviceReady(state, payload) {
        state.isDeviceReady = payload;
    },
    setUpdateRequired(state, payload) {
        state.isUpdateRequired = payload;
    },
    setApiIncompatible(state, payload) {
        state.isApiIncompatible = payload;
    },
    setInfo(state, payload) {
        state.info = payload;
    },
    setOpeningTimes(state, payload) {
        if (payload && payload.openingTimes) {
            payload.openingTimes = payload.openingTimes.filter(entry => {
                return entry.start !== '00:00' && entry.start !== entry.end;
            });
        }
        state.openingTimes = payload;
    },
    forceRerender(state) {
        state.forcedRerenderKey = Date.now();
    },
    setCheckingForUpdate(state) {
        state.isCheckingForUpdate = true;
    },
    resetCheckingForUpdate(state) {
        state.isCheckingForUpdate = false;
    },
    setUpdateInProgress(state) {
        state.isCheckingForUpdate = false;
        state.isUpdateInProgress = true;
    },
    setDownloadProgress(state, { receivedBytes, totalBytes }) {
        let percentage = 0;
        if (receivedBytes >= 0 && totalBytes > 0 && receivedBytes <= totalBytes) {
            percentage = Math.round((receivedBytes * 100) / totalBytes);
        }
        state.downloadProgress = {
            receivedBytes,
            totalBytes,
            percentage,
        };
    },
    resetDownloadProgress(state) {
        state.downloadProgress = null;
    },
    setUpdateInstalled(state) {
        state.isCheckingForUpdate = false;
        state.isUpdateInProgress = false;
        state.isApiIncompatible = false;
    },
    setRegistrationActive(state, payload) {
        state.registrationActive = payload;
    },
    setHelpCenterActive(state, payload) {
        state.helpCenterActive = payload;
    },
    setDisposalContactData(state, payload) {
        state.disposalContactData = payload;
    },
    setOrderPhoneNumber(state, payload) {
        state.orderPhoneNumber = payload;
    },
    setAccountingEmail(state, payload) {
        state.accountingEmail = payload;
    },
    setServiceEmail(state, payload) {
        state.serviceEmail = payload;
    },
    setServicePhone(state, payload) {
        state.servicePhone = payload;
    },
    setOrderNotAvailableComingSoonMessageActive(state, payload) {
        state.orderNotAvailableComingSoonMessageActive = payload;
    },
    setLegal(state, payload) {
        state.legal = payload;
    },
    setPlatformRegistrationLink(state, payload) {
        state.platformRegistrationLink = payload;
    },
    setRawInitialMapLocation(state, payload) {
        if (typeof payload !== 'string')
            return;
        const [lat, lng] = payload.split(',');
        if (!lat || !lng)
            return;
        state.initialMapLocation = normalizeLocation({ lat, lng });
    },
    setAllPlatformActions(state, payload) {
        state.allPlatformActions = payload;
    },
};
const actions = {
    async updateInfo({ commit }) {
        try {
            const data = await queryClient.fetchQuery(platformInfoQuery);
            const { documentUseSelfBilling, platform, openingTimes, registrationActive, helpCenterActive, orderPhoneNumber, disposalContactData, countryCheck, serviceEmail, accountingEmail, servicePhone, orderNotAvailableComingSoonMessageActive, legal, platformRegistrationLink, initialMapLocation, allPlatformActions, } = data;
            updateContext({
                documentUseSelfBilling: documentUseSelfBilling,
            });
            commit('setCountryCheck', countryCheck);
            commit('setInfo', platform);
            commit('setRegistrationActive', registrationActive);
            commit('setHelpCenterActive', helpCenterActive);
            if (openingTimes)
                openingTimes.openingTimes = Object.values(openingTimes.openingTimes);
            commit('setOpeningTimes', openingTimes);
            commit('setOrderPhoneNumber', orderPhoneNumber);
            commit('setDisposalContactData', disposalContactData);
            commit('setServiceEmail', serviceEmail);
            commit('setAccountingEmail', accountingEmail);
            commit('setServicePhone', servicePhone);
            commit('setOrderNotAvailableComingSoonMessageActive', orderNotAvailableComingSoonMessageActive);
            commit('setLegal', legal);
            commit('setPlatformRegistrationLink', platformRegistrationLink);
            commit('setRawInitialMapLocation', initialMapLocation);
            commit('setAllPlatformActions', allPlatformActions);
        }
        catch (err) {
            Log.log(err);
        }
    },
    async checkVersion({ commit }) {
        try {
            const version = await PlatformUtils.getPlatformVersion();
            // is valid app version
            if (/\d+\.\d+\.\d+/.test(version)) {
                commit('setUpdateRequired', parseVersion(version).major > window.appVersion.major);
            }
            else {
                commit('setUpdateRequired', false);
            }
        }
        catch (err) {
            Log.log(err);
        }
    },
};
export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

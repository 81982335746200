import _isFunction from 'lodash/isFunction';
import _pick from 'lodash/pick';
import _isArray from 'lodash/isArray';
import _intersection from 'lodash/intersection';
import _cloneDeep from 'lodash/cloneDeep';

/**
 * Reduce filter options by forced filter values
 *
 * @param {*} supportedFilters
 * @param {*} forcedFilter
 */
const reduceSupportedFilterOptions = (supportedFilters, forcedFilter) => {
    Object.keys(forcedFilter).forEach(key => {
        const forcedValue = forcedFilter[key];
        const supportedFilter = supportedFilters[key];
        if (supportedFilter && ['multiselect', 'select'].includes(supportedFilter.type)) {
            supportedFilter.options = _pick(supportedFilter.options, forcedValue);
        }
    });

    return supportedFilters;
};

/**
 * Remove default forced filters from applied filter values
 *
 * @param {*} filter
 * @param {*} forcedFilter
 */
const processAppliedFilterValues = (filter, forcedFilter) => {
    filter = _cloneDeep(filter);
    Object.keys(forcedFilter).forEach(key => {
        const forcedValue = forcedFilter[key];
        const value = filter[key];

        // remove default forced filter from applied filters
        if (
            _isArray(value) &&
            _isArray(forcedValue) &&
            _intersection(value, forcedValue).length === forcedValue.length
        ) {
            delete filter[key];
        }
    });

    return filter;
};

/**
 * Filter response
 */
export default class FilterResult {
    constructor(responseData, requestedFilter, requestedForcedFilter = null, isFacetatedResult = false) {
        // is facetation result bag
        this.isFacetatedResult = isFacetatedResult;

        // raw response data
        this.responseData = responseData;

        // these filters were send to the server
        this.requestedFilter = requestedFilter;

        // these filters were send to the server
        this.requestedForcedFilter = requestedForcedFilter;

        // these filters are supported by the server
        this.supportedFilters = reduceSupportedFilterOptions(responseData.supportedFilters, requestedForcedFilter);

        // these filters were applied to that request
        this.appliedFilter = {
            ...processAppliedFilterValues(responseData.filter, requestedForcedFilter),
            sortBy: responseData.sortBy,
            sortDirection: responseData.sortDirection,
        };

        // these filters were applied to that request but are not intersected/processed with the forcedFilter
        this.unfilteredAppliedFilter = {
            ...responseData.filter,
            sortBy: responseData.sortBy,
            sortDirection: responseData.sortDirection,
        };

        // these are the supported sort properties
        this.supportedSorts = responseData.supportedSorts;

        // applied main sorting property
        this.seedSortBy = responseData.seedSortBy;

        // applied sorting property
        this.sortBy = responseData.sortBy;

        // applied sorting direction
        this.sortDirection = responseData.sortDirection;

        // applied sorting direction
        this.aggregation = responseData.aggregation || {};

        // response items
        this.items = responseData.items || [];

        // response count
        this.count = responseData.count;

        // pagination cursor
        this.position = responseData.position ?? null;

        // load more indicator...
        this.isLoadingMore = false;

        this.remainingCount = this.count - this.items.length;

        // transform items after updates
        this._transformCallback = null;
    }

    /**
     * Set facet filter key
     *
     * if this value is set, the response is a facetated child response
     *
     * @param {String} filterName
     */
    setFacetFilter(filterName) {
        this.facetFilter = filterName;
        this.appliedFacetValue = this.unfilteredAppliedFilter[filterName];
    }

    /**
     * Experimental: load more items from the same context!
     */
    async loadMore() {
        if (!_isFunction(this.filterEndpoint)) {
            throw new Error('Filter endpoint must be a callable function');
        }

        const currentPage = this.appliedFilter.page || 2;
        const maxPage = this.supportedFilters.page.max || 0;

        // can't go further
        if (currentPage >= maxPage) {
            return false;
        }

        const newParams = {
            ...this.requestedFilter,
            page: currentPage + 1,
        };

        this.isLoadingMore = true;

        const result = await this.filterEndpoint(newParams);
        // transform items, if callback was applied
        const performTransformResult = this.performTransform(result.items);
        const newItems = await Promise.resolve(performTransformResult);

        this.isLoadingMore = false;
        this.appliedFilter = result.appliedFilter;
        this.items = [...this.items, ...newItems];
        this.remainingCount = result.count - this.items.length;
        return this.items;
    }

    async loadPage(page) {
        if (!_isFunction(this.filterEndpoint)) {
            throw new Error('Filter endpoint must be a callable function');
        }

        const maxPage = this.supportedFilters.page.max || 0;

        if (page > maxPage || page < 1) {
            return false;
        }

        const newParams = {
            ...this.requestedFilter,
            page,
        };

        this.isLoadingMore = true;

        return this.filterEndpoint(newParams).then(result => {
            this.isLoadingMore = false;
            this.appliedFilter = result.appliedFilter;
            this.items = result.items;
            this.remainingCount = result.count - this.items.length;
            return this.items;
        });
    }

    /**
     * Experimental: set reference to the endpoint filter method
     */
    setFilterEndpoint(filterEndpoint) {
        this.filterEndpoint = filterEndpoint;
    }

    /**
     * Experimental: perform a transform operation on all the items
     * This will be executed on first transformation and after loading more
     * @param callback
     */
    transform(callback) {
        this._transformCallback = callback;
        this.items = this.performTransform(this.items);
    }
    async transformAsync(callback) {
        this._transformCallback = callback;
        this.items = await this.performTransform(this.items);
    }

    /**
     * Perform transformation
     *
     * @param items
     * @returns {*}
     */
    performTransform(items) {
        if (this._transformCallback === null) return items;

        if (this._transformCallback.constructor.name === 'AsyncFunction') {
            return Promise.all(items.map(this._transformCallback));
        }

        return items.map(this._transformCallback);
    }
}

import Vue from 'vue';
import _isEqual from 'lodash/isEqual';

import { defaultState } from './state.js';
import { toKgPrice } from '@/services/utils/units';

import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';

const mutations = {
    RESET(state, payload = null) {
        Object.assign(state, defaultState());
        if (payload) {
            for (const property in payload) {
                if (property === 'requestCollection')
                    for (const p in payload[property]) {
                        Vue.set(state.requestCollection, p, payload[property][p]);
                    }
                else Vue.set(state, property, payload[property]);
            }
        }
    },
    RESET_WITH_RESTORED_DATA(state, restoredStorage) {
        const type = state.requestCollection.type;
        const project = state.requestCollection.project;
        const billingMethod = state.requestCollection.billingMethod;
        const validityRange = state.requestCollection.validityRange;
        const status = state.requestCollection.status;

        /**
         * Restore necessary data
         */
        mutations.RESET(state, {
            ...restoredStorage,
            requestCollection: {
                type,
                project,
                validityRange,
                status,
                ...(restoredStorage?.requestCollection || {}),
            },
        });

        // add preselected values based on basket type
        if (BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS === type) {
            mutations.SET_BILLING_METHOD(state, 'weight');
        } else if (billingMethod) {
            mutations.SET_BILLING_METHOD(state, billingMethod);
        }
    },
    RESET_CARRIER_SELECTION(state) {
        Vue.set(state, 'putToTransportMarket', null);
        Vue.set(state, 'carrierInfo', null);
    },
    SET_ERROR(state, error) {
        Vue.set(state, 'error', error);
    },
    SET_STATE_WASTE_COMPANIES(state, stateWasteCompanies) {
        Vue.set(state, 'stateWasteCompanies', stateWasteCompanies);
    },
    SET_IS_BILLED_BY_STATE_WASTE_COMPANY(state, isBilledByStateWasteCompany) {
        Vue.set(state, 'isBilledByStateWasteCompany', isBilledByStateWasteCompany);
    },
    SET_PROJECT_POSITION_SELECTION_ERRORS(state, errors) {
        Vue.set(state, 'projectPositionSelectionErrors', errors);
    },
    SET_PROJECT_POSITION_CONSTRUCTION_SITE(state, constructionSite, resetProducerNumber = true) {
        // Resetting the store when the selected construction site is changed
        if (!_isEqual(constructionSite, state.constructionSite) && state.constructionSite) {
            mutations.RESET_WITH_RESTORED_DATA(state, {});
        }

        Vue.set(state, 'constructionSite', constructionSite);
        Vue.set(state.requestCollection, 'constructionSite', { id: constructionSite.id });

        if (resetProducerNumber && state.constructionSiteState !== constructionSite.address?.state) {
            Vue.set(state.requestCollection, 'producerNumber', null);
        }

        Vue.set(state, 'constructionSiteState', constructionSite.address?.state);
    },
    SET_PROJECT_POSITION_CLIENT_NAME(state, clientName) {
        Vue.set(state, 'clientName', clientName);
    },
    SET_PROJECT_POSITION_PRODUCT(state, product) {
        // Resetting the store when the selected product is changed
        if (!_isEqual(product.id, state.product.id)) {
            const constructionSite = state.constructionSite;

            mutations.RESET_WITH_RESTORED_DATA(state, {
                // Step "Construction site"
                constructionSite,

                // Step "Choose Disposer / Facility"
                disposer: state.disposer,

                // Step "General Information"
                isBilledByStateWasteCompany: state.isBilledByStateWasteCompany,
                stateWasteCompanies: state.stateWasteCompanies,

                // Step "Check Project Position"
                clientName: state.clientName,

                requestCollection: {
                    // Step "Construction site"
                    constructionSite: state.requestCollection.constructionSite,

                    // Step "Allgemeine Informationen"
                    producerNumber: state.requestCollection.producerNumber,
                    disposalProofNumber: state.requestCollection.disposalProofNumber,
                    billingByStateWasteCompanyId: state.requestCollection.billingByStateWasteCompanyId,
                    automaticAccompanyingDocumentCreation:
                        state.requestCollection.automaticAccompanyingDocumentCreation,
                },
            });
            mutations.SET_PROJECT_POSITION_CONSTRUCTION_SITE(state, constructionSite, false);
        }

        Vue.set(state, 'product', product);
    },
    RESET_PROJECT_POSITION_PRODUCT(state) {
        Vue.set(state, 'product', defaultState().product);

        mutations.RESET_REQUEST_COLLECTION(state, {
            property: 'factoryProductVariantId',
        });
    },
    SET_PROJECT_POSITION_PRODUCT_VARIANTS(state, variants) {
        Vue.set(state.product, 'variants', variants);
    },

    SET_PROJECT_POSITION_PRODUCT_VARIANT(state, productVariant) {
        // Resetting the store when the selected product variant is changed
        if (!_isEqual(productVariant.id, state.requestCollection.factoryProductVariantId)) {
            const constructionSite = state.constructionSite;

            mutations.RESET_WITH_RESTORED_DATA(state, {
                // Step "Construction site"
                constructionSite,

                // Step "Choose Disposer / Facility"
                disposer: state.disposer,

                // Step "General Information"
                isBilledByStateWasteCompany: state.isBilledByStateWasteCompany,
                stateWasteCompanies: state.stateWasteCompanies,

                // Step "Check Project Position"
                clientName: state.clientName,

                // Step "Product"
                product: state.product,

                requestCollection: {
                    // Step "Construction site"
                    constructionSite: state.requestCollection.constructionSite,

                    // Step "Allgemeine Informationen"
                    producerNumber: state.requestCollection.producerNumber,
                    disposalProofNumber: state.requestCollection.disposalProofNumber,
                    billingByStateWasteCompanyId: state.requestCollection.billingByStateWasteCompanyId,
                    automaticAccompanyingDocumentCreation:
                        state.requestCollection.automaticAccompanyingDocumentCreation,
                },
            });
            mutations.SET_PROJECT_POSITION_CONSTRUCTION_SITE(state, constructionSite, false);
        }

        Vue.set(state.requestCollection, 'factoryProductVariantId', productVariant.id);
        mutations.SET_PROJECT_POSITION_VARIANT_NOTE(state, productVariant.note);
    },
    SET_PROJECT_POSITION_VARIANT_NOTE(state, note) {
        Vue.set(state, 'factoryProductVariantNote', note);
    },
    SET_PROJECT_POSITION_EXEMPTION_TRANSLATION(state, translation) {
        Vue.set(state, 'exemptionTranslation', translation);
    },
    SET_PROJECT_POSITION_DISPOSER(state, disposer) {
        Vue.set(state, 'disposer', disposer);
    },
    RESET_PROJECT_POSITION_DISPOSER(state) {
        Vue.set(state, 'disposer', defaultState().disposer);
    },
    SET_CARRIER_NUMBER_FROM_ORGANIZATION(state, carrierNumber) {
        Vue.set(state, 'carrierNumberFromOrganization', carrierNumber);
    },
    RESET_CARRIER_NUMBER_FROM_ORGANIZATION(state) {
        Vue.set(state, 'carrierNumberFromOrganization', null);
    },
    SET_CARRIER_INFO(state, carrierInfo) {
        Vue.set(state, 'carrierInfo', carrierInfo);
    },
    RESET_CARRIER_INFO(state) {
        Vue.set(state, 'carrierInfo', defaultState().carrierInfo);
    },
    SET_PROJECT_POSITION_DOCUMENTS(state, documents) {
        Vue.set(state, 'documents', documents);
        Vue.set(state.requestCollection, 'documentUuids', [...documents.map(d => d.uuid)]);
    },
    SET_PROJECT_POSITION_OTHER_DOCUMENTS(state, otherDocuments) {
        Vue.set(state, 'otherDocuments', otherDocuments);
    },
    SET_PROJECT_POSITION_IMAGES(state, images) {
        Vue.set(state, 'images', images);
        Vue.set(
            state.requestCollection,
            'accompanyingAnalysisImageUuids',
            images.map(d => d.uuid)
        );
    },
    SET_PROJECT_PUT_POSITION_TO_TRANSPORT_MARKET(state, putToTransportMarket) {
        Vue.set(state, 'putToTransportMarket', putToTransportMarket);
    },
    SET_BILLING_METHOD(state, billingMethod) {
        Vue.set(state.requestCollection, 'billingMethod', billingMethod);
    },

    SET_PROJECT_POSITION_VALIDITY_RANGE(state, validityRange) {
        Vue.set(state.requestCollection, 'validityRange', validityRange);
    },

    SET_PROJECT_POSITION_STATUS(state, status) {
        Vue.set(state.requestCollection, 'status', status);
    },

    RESET_PROJECT_POSITION_EXEMPTION(state) {
        Vue.set(state.requestCollection.analysis, 'exemption', defaultState().requestCollection.analysis.exemption);
    },

    SET_PRICES_DATA(state, pricesData) {
        Vue.set(state, 'pricesData', pricesData);
    },

    SET_CUSTOM_PRICES(state, customPrices) {
        Vue.set(state, 'customPrices', {
            purchaseUnitPrice: customPrices.purchaseUnitPrice,
            retailUnitPrice: customPrices.retailUnitPrice,
        });
    },

    SET_AUTOMATIC_ACCOMPANYING_DOCUMENT_CREATION(state, payload) {
        Vue.set(state.requestCollection, 'automaticAccompanyingDocumentCreation', payload);
    },

    SET_TEMPORARY_TRANSPORT_PRICES(state, temporaryTransportPrices) {
        Vue.set(state, 'temporaryTransportPrices', temporaryTransportPrices);
    },

    SET_CUSTOM_PRICE_DATA(state, { wastePrices, transportPrices, customPositions }) {
        const materialPurchaseUnitPrice =
            state.customPrices.purchaseUnitPrice ?? toKgPrice(wastePrices.purchaseUnitPrice);
        const materialRetailUnitPrice = state.customPrices.retailUnitPrice ?? toKgPrice(wastePrices.retailUnitPrice);
        Vue.set(state.requestCollection, 'materialPurchaseUnitPrice', materialPurchaseUnitPrice);
        Vue.set(state.requestCollection, 'materialRetailUnitPrice', materialRetailUnitPrice);
        Vue.set(state.requestCollection, 'vehicleClasses', transportPrices);
        Vue.set(state.requestCollection, 'customPositions', customPositions);
    },

    RESET_CUSTOM_PRICE_DATA(state) {
        Vue.set(state.requestCollection, 'materialPurchaseUnitPrice', null);
        Vue.set(state.requestCollection, 'materialRetailUnitPrice', null);
        Vue.set(state.requestCollection, 'vehicleClasses', null);
        Vue.set(state.requestCollection, 'customPositions', null);
        Vue.set(state, 'temporaryTransportPrices', null);
        Vue.set(state, 'pricesData', null);
    },

    RESET_PAYMENT_TERMS(state) {
        Vue.set(state.requestCollection, 'paymentTerms', state.defaultPaymentTerms);
    },

    SET_DEFAULT_PAYMENT_TERMS(state, defaultPaymentTerms) {
        Vue.set(state, 'defaultPaymentTerms', defaultPaymentTerms);
    },

    SET_PAYMENT_TERMS(state, paymentTerms) {
        Vue.set(state.requestCollection, 'paymentTerms', paymentTerms);
    },

    RESET_PRETREATMENT(state) {
        Vue.set(state.requestCollection, 'pretreatmentDescription', null);
    },

    // SET REQUEST COLLECTION FOR LATER API CALL
    SET_REQUEST_COLLECTION(state, { requestCollection, property = null }) {
        if (!property) Vue.set(state, 'requestCollection', requestCollection);
        else Vue.set(state.requestCollection, property, requestCollection[property]);
    },
    RESET_REQUEST_COLLECTION(state, { property = null }) {
        if (!property) Vue.set(state, 'requestCollection', defaultState().requestCollection);
        else Vue.set(state.requestCollection, property, defaultState().requestCollection[property]);
    },
};

export default mutations;

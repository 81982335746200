import { TRANSPORT_TYPE } from '@/constants/transportTypes';

export default {
    computed: {
        shipmentAddressList() {
            const { loadingAddress, loadingLocation, unloadingAddress, unloadingLocation } = this.transport;

            return [
                {
                    headline:
                        this.transport.loadingName ||
                        loadingAddress.additionalAddress ||
                        `${loadingAddress.zip} ${loadingAddress.city}`,
                    address: {
                        ...loadingAddress,
                        location: loadingLocation,
                    },
                    hasInformation: Boolean(this.transport.loadingSiteInformation),
                    type: 'loading',
                },
                {
                    headline:
                        this.transport.unloadingName ||
                        unloadingAddress.additionalAddress ||
                        `${unloadingAddress.zip} ${unloadingAddress.city}`,
                    address: {
                        ...unloadingAddress,
                        location: unloadingLocation,
                    },
                    type: 'unloading',
                    hasInformation: Boolean(this.transport.unloadingSiteInformation),
                },
            ];
        },

        disposalAdressList() {
            const { constructionSite, supplier, unloadingName, unloadingAddress, unloadingLocation } = this.transport;
            return [
                {
                    type: 'loading',
                    headline: constructionSite.name,
                    address: {
                        ...constructionSite.address,
                        location: constructionSite.location,
                    },
                    maximumTrafficability: constructionSite.maximumTrafficability,
                },
                {
                    type: 'unloading',
                    headline: unloadingName || supplier?.name,
                    address: {
                        ...(unloadingAddress || supplier?.billingAddress),
                        location: unloadingLocation,
                    },
                },
            ];
        },

        defaultAdressList() {
            const { supplier, constructionSite, loadingName, loadingAddress, loadingLocation } = this.transport;
            return [
                {
                    headline: loadingName || supplier.name,
                    address: {
                        ...(loadingAddress || supplier?.billingAddress),
                        location: loadingLocation,
                    },
                },
                {
                    headline: constructionSite.name,
                    address: {
                        ...constructionSite.address,
                        location: constructionSite.location,
                    },
                    maximumTrafficability: constructionSite.maximumTrafficability,
                },
            ];
        },

        addressList() {
            if (!this.transport) return [];

            if (this.transport.type === TRANSPORT_TYPE.SHIPMENT) {
                return this.shipmentAddressList;
            }

            if (this.transport.type === TRANSPORT_TYPE.DISPOSAL) {
                return this.disposalAdressList;
            }

            return this.defaultAdressList;
        },
    },
};

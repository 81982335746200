<template>
    <div
        class="client-tile"
        :class="{
            'client-tile--clickable': clickable,
            'client-tile--no-border': noBorder,
            'client-tile--levitate': levitate,
            'client-tile--spaceless': spaceless,
        }"
        @click="$emit('tile-action', organization)"
    >
        <div v-if="showHeadline" class="client-tile__headline">
            <Words bold tiny>{{ $t('components.clientTile.headline') }}</Words>
            <Words v-if="isPlatform" tiny muted block>{{ organization.customerNumber }}</Words>
            <Button v-if="hasAction">
                <ArrowEditIcon width="10" height="14" />
            </Button>
        </div>
        <div>
            <Words bold block>
                {{ organization.name }}
            </Words>
            <Words v-if="showAddress" block small muted spaced-top-tiny>
                <FormattedAddress decorate-location-code :address="organization.billingAddress" />
            </Words>
        </div>
    </div>
</template>

<script>
import { CONTEXT_PLATFORM } from '@/constants/context';

import FormattedAddress from '@/components/FormattedAddress.vue';
import OrganizationView from '@/models/OrganizationView';

import routeContext from '@/plugins/mixins/routeContext';

import Words from '@/components/Typography/Words';

import ArrowEditIcon from '@/assets/icons/regular/arrow-stripeless.svg';

export default {
    name: 'ClientTile',
    components: {
        FormattedAddress,
        Words,

        ArrowEditIcon,
    },

    mixins: [routeContext],

    props: {
        organization: {
            type: [Object, OrganizationView],
            required: true,
        },
        hasAction: {
            type: Boolean,
            default: false,
        },
        showHeadline: {
            type: Boolean,
            default: false,
        },
        showAddress: {
            type: Boolean,
            default: false,
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
        levitate: {
            type: Boolean,
            default: false,
        },
        spaceless: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isPlatform() {
            return this.inRouteContext(CONTEXT_PLATFORM);
        },
    },
};
</script>

<style lang="scss">
$client-tile-vertical-spacing: 15px;
$client-tile-border-size: 2px;
$client-tile-horizontal-margin: 2px;
$client-tile-horizontal-spacing: 15px - $client-tile-border-size - $client-tile-horizontal-margin;
$client-tile-horizontal-spacing-lg: 25px - $client-tile-border-size - $client-tile-horizontal-margin;

.client-tile {
    display: grid;
    margin: 0 $client-tile-horizontal-margin;
    padding: 20px 0;
    grid-template-columns: $client-tile-horizontal-spacing 1fr $client-tile-horizontal-spacing;
    grid-column-gap: 0;
    grid-row-gap: $client-tile-vertical-spacing;
    background-color: $color-white;
    border: $client-tile-border-size solid $color-white;
    border-bottom-color: $color-lightGrey;

    @media only screen and (min-width: $layout-desktop-min) {
        grid-template-columns: $client-tile-horizontal-spacing-lg 1fr $client-tile-horizontal-spacing-lg;
    }

    > * {
        grid-column: 2 / 3;
    }
}

.client-tile--no-border {
    border-bottom-color: $color-white;
}

.client-tile--clickable {
    cursor: pointer;
}

.client-tile__headline {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.client-tile--levitate {
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
    transition:
        transform 0.2s ease,
        box-shadow 0.2s ease;
    margin-bottom: 20px;
}

.client-tile--levitate:active {
    transform: translateY(5px);
    box-shadow: $boxShadow-card;
}

.client-tile--spaceless {
    grid-template-columns: 0px 1fr 0px;
    margin: 0px;
    padding: 0px;
    border: 0;
}
</style>

import { forwardRef } from 'react';
import { useIconSize } from '@/services/utils/useIconSize';
export const InfoIcon = forwardRef(({ className, multicolor = false, size = 'xs', strokeCssClass, ...svgProps }, ref) => {
    const iconSize = useIconSize(size);
    return (<svg width={iconSize} height={iconSize} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} ref={ref} {...svgProps}>
                <g clipPath="url(#clip0_2615_2069754)">
                    <path d="M22 12C22 9.3479 20.9465 6.80443 19.0712 4.92908C17.1959 3.05373 14.6524 2.00011 12.0003 2C9.34813 1.99997 6.80455 3.05352 4.92912 4.92888C3.0537 6.80424 2.00006 9.3478 2 12C2.00006 14.6522 3.0537 17.1958 4.92912 19.0711C6.80455 20.9465 9.34813 22 12.0003 22C14.6524 21.9999 17.1959 20.9463 19.0712 19.0709C20.9465 17.1956 22 14.6521 22 12Z" stroke="#35343D" strokeWidth="2" strokeMiterlimit="10" className={strokeCssClass ?? 'stroke-icon'}/>
                    <path d="M12.022 16.9744V9.99219" stroke="#35343D" strokeWidth="2" strokeMiterlimit="10" className={multicolor ? 'stroke-icon-accent' : strokeCssClass ?? 'stroke-icon'}/>
                    <path d="M12.022 8.99352V6.99023" stroke="#35343D" strokeWidth="2" strokeMiterlimit="10" className={multicolor ? 'stroke-icon-accent' : strokeCssClass ?? 'stroke-icon'}/>
                </g>
                <defs>
                    <clipPath id="clip0_2615_2069754">
                        <rect width="22" height="22" fill="white" transform="translate(1 1)"/>
                    </clipPath>
                </defs>
            </svg>);
});
InfoIcon.displayName = 'InfoIcon';

import PaymentTermsSelectionPage from './PaymentTermsSelectionPage.vue';

export default {
    path: 'payment-terms-selection',
    name: 'payment-terms-selection',
    component: PaymentTermsSelectionPage,
    props: true,
    meta: {
        requiresAuth: true,
        noBackArrow: true,
        requiredAbilities: ['netSuitePaymentTermsEligibilityManagement'],
    },
};

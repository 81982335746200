import { WASTE_PRODUCT } from '@/constants/productTypes';

import CategoryFormPage from './CategoryFormPage';
import CategoryListPage from './CategoryListPage';
import ProductEditPage from './ProductEditPage.vue';
import ProductListPage from './ProductListPage';
import ProductManagementPage from './ProductManagementPage';
import SortPage from './SortPage';
import WasteFormPage from './WasteFormPage';
import WasteListPage from './WasteListPage';
import WasteOverviewPage from './WasteOverviewPage';
import WasteProductStates from './WasteProductStates';

export default {
    path: 'product-management',
    name: 'product-management',
    component: ProductManagementPage,
    meta: {
        requiresAuth: true,
        requiredAbilities: ['listProductCatalog'],
    },
    children: [
        {
            path: 'products',
            name: 'product-list',
            component: ProductListPage,
            props: {
                parentRoute: 'product-management',
            },
        },

        {
            path: 'products/:id',
            name: 'product-edit',
            component: ProductEditPage,
            props: route => {
                return {
                    productId: route.params.id,
                    parentRoute: 'product-list',
                };
            },
        },

        {
            path: 'waste',
            name: 'waste-product',
            component: WasteOverviewPage,
            redirect: 'waste/products',
            props: {
                parentRoute: 'product-management',
            },

            children: [
                {
                    path: 'products',
                    name: 'waste-product-list',
                    component: WasteListPage,
                    props: { listType: WASTE_PRODUCT },

                    children: [
                        {
                            path: 'edit/:id',
                            name: 'waste-product-edit',
                            component: WasteFormPage,
                            props: { listType: WASTE_PRODUCT },
                            children: [
                                {
                                    path: 'variants',
                                    name: 'waste-product-variants',
                                    component: WasteProductStates,
                                    props: { listType: WASTE_PRODUCT },
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'categories',
                    name: 'waste-category',
                    component: CategoryListPage,
                    props: { listType: WASTE_PRODUCT },
                    children: [
                        {
                            path: 'add',
                            name: 'waste-category-add',
                            component: CategoryFormPage,
                            props: { listType: WASTE_PRODUCT },
                        },
                        {
                            path: 'edit/:id',
                            name: 'waste-category-edit',
                            component: CategoryFormPage,
                            props: { listType: WASTE_PRODUCT },
                        },
                    ],
                },
                {
                    path: 'sort',
                    name: 'waste-sort',
                    component: SortPage,
                    props: { listType: WASTE_PRODUCT },
                },
            ],
        },
    ],
};

<template>
    <label
        :class="{
            'radio-field--boxed': boxed,
            'radio-field--error': error,
            'radio-field--active': isActive,
            'radio-field--disabled': disabled,
        }"
        class="radio-field"
        :data-test="dataTest"
    >
        <div
            v-if="labelBeforeRadio"
            :class="{
                'radio-field__label--disabled': disabled,
            }"
            class="radio-field__label"
        >
            <slot />
        </div>
        <Radio
            :value="value"
            :name="name"
            :option="option"
            :disabled="disabled"
            :error="error"
            :small="small || boxed || (dynamicSize && $root.isDesktop)"
            :data-test="dataTest"
            class="radio-field__input"
            @input="$emit('input', $event)"
        />
        <div
            v-if="!labelBeforeRadio"
            :class="{
                'radio-field__label--disabled': disabled,
            }"
            class="radio-field__label"
        >
            <slot />
        </div>
    </label>
</template>

<script>
import Radio from '@/components/Form/Radio';

export default {
    name: 'RadioField',
    components: {
        Radio,
    },
    props: {
        name: {
            type: String,
            default: null,
        },
        value: {
            type: [Object, String, Boolean, Array, Number],
            default: null,
        },
        option: {
            type: [Object, String, Boolean, Array, Number],
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        boxed: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        dynamicSize: {
            type: Boolean,
            default: false,
        },
        labelBeforeRadio: {
            type: Boolean,
            default: false,
        },
        dataTest: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            eid: `el${this._uid}`,
        };
    },
    computed: {
        isActive() {
            return this.value === this.option;
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.radio-field {
    display: flex;
}

.radio-field--boxed {
    background-color: $color-white;
    border: 1px solid $color-littleDarkerThanMediumGrey;
    padding: 15px 12px;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
}

.radio-field--boxed.radio-field--active {
    border-color: $color-red;
}

.radio-field__input {
    flex: 0 0;
    margin-right: 15px;
}
.radio-field__label + .radio-field__input {
    margin-right: 0;
}
.radio-field__label {
    flex: 1 1;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: initial;
}

.radio-field--disabled {
    cursor: initial;
}

.radio-field__label--disabled {
    opacity: 0.2;
    color: $color-base;
    cursor: initial;
}
</style>

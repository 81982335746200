<template>
    <component :is="getTransportHubComponent()" />
</template>

<script>
import { CONTEXT_CARRIER, CONTEXT_PLATFORM } from '@/constants/context';
import routeContext from '@/plugins/mixins/routeContext';

import TransportHubPlatformPage from './TransportHubPlatformPage';
import TransportHubQueuePage from './TransportHubQueuePage';

export default {
    name: 'TransportHubPage',
    components: {
        TransportHubPlatformPage,
        TransportHubQueuePage,
    },
    mixins: [routeContext],
    methods: {
        getTransportHubComponent() {
            if (this.inRouteContext(CONTEXT_CARRIER) && this.$can('haveTransportHub')) {
                return TransportHubQueuePage;
            }

            if (this.inRouteContext(CONTEXT_PLATFORM) && this.$can('listPlatformTransportHub')) {
                return TransportHubPlatformPage;
            }

            return 'div';
        },
    },
};
</script>

<style lang="scss">
.transport-hub__grid-bordered {
    & > * {
        align-self: center;
        padding: 0 15px;
        border-right: 1px solid $color-mediumGrey;
    }

    & > *:first-child {
        padding-left: 0;
    }

    & > .location-field {
        padding-right: 0;
    }

    & > *:last-child {
        padding-right: 0;
        border-right: 0 none;
    }
}

.transport-hub__grid-no-border {
    & > * {
        align-self: center;
        padding: 0 10px;
    }

    & > *:first-child {
        padding-left: 0;
    }

    & > .location-field {
        padding-right: 0;
    }

    & > *:last-child {
        padding-right: 0;
        border-right: 0 none;
    }
}
</style>

<template>
    <div
        :class="{
            'date-picker--active': isVisuallyOpen,
            'date-picker--invalid': isInvalid,
            'date-picker--disabled': disabled,
            'date-picker--desktop': $root.isDesktop,
        }"
        class="date-picker"
    >
        <div class="date-picker__trigger" @click="open()">
            <slot :is-invalid="isInvalid">Please provide a default</slot>
        </div>
        <template v-if="isActive">
            <div class="date-picker__backdrop" @click="closeByBackdrop" />
            <div class="date-picker__body" @click="closeByBackdrop">
                <div class="date-picker__content" @click="preventContentClicks">
                    <div
                        v-scrollable
                        :class="{
                            'date-picker__inner--no-footer': !$slots.bottom,
                        }"
                        class="date-picker__inner scroll-container"
                    >
                        <div class="date-picker__desktop-wrapper">
                            <Tile background-transparent>
                                <Calendar
                                    v-model="selectedDates"
                                    :type="calendarType"
                                    :allowed-dates="availableDates"
                                    :max-range="maxRange"
                                    :max-range-in-work-days="maxRangeInWorkDays"
                                    :min-date="min ? ensureDate(min) : null"
                                    :max-date="max ? ensureDate(max) : null"
                                />
                            </Tile>
                        </div>
                    </div>
                    <div class="date-picker__footer">
                        <ButtonGroup>
                            <Button v-if="enableReset" primary light place-left @click="clear">
                                {{ $t('components.datetimePicker.clear') }}
                            </Button>
                            <Button :disabled="!canSubmit" primary data-test="button-apply-calendar" @click="submit">{{
                                $t('components.datetimePicker.submit')
                            }}</Button>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { ensureJSTimestamp } from '@/services/utils/date';
import { startOfDay, endOfDay, format } from 'date-fns';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Tile from '@/components/Layout/Tile';
import Calendar from '@/components/Form/Calendar';

const CLOSE_DELAY = 500;
const OPEN_DELAY = 250;

// TODO: If range config is provided and range is selected with first day, return start of range config and end of range config for that day

export default {
    name: 'DatePicker',
    components: {
        Button,
        ButtonGroup,
        Calendar,
        Tile,
    },
    props: {
        type: {
            type: String,
            default: 'single',
            validator: v => ['single', 'range', 'mixed'].includes(v),
        },
        active: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Number, Date, Object],
            default: null,
        },
        min: {
            type: [Number, Date],
            default: null,
        },
        max: {
            type: [Number, Date],
            default: null,
        },
        rangeConfig: {
            type: Array,
            default: null,
        },
        enableReset: {
            type: Boolean,
            default: false,
        },
        maxRange: {
            type: Number,
            default: null,
        },
        maxRangeInWorkDays: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            eid: `el${this._uid}`,
            isActive: this.active,
            isVisuallyOpen: false,

            selectedDates: [],
        };
    },
    computed: {
        canSubmit() {
            return this.selectedDates.length > 0 && !this.isInvalid;
        },
        isInvalid() {
            if (this.value === null) {
                return false;
            }

            // must be included in available dates
            if (this.rangeConfig !== null && !this.selectedDates.every(o => this.availableDates.includes(o))) {
                return true;
            }

            if (this.type === 'mixed' && this.selectedDates.length < 1) {
                return true;
            }

            // requires 2 bvalues
            if (this.type === 'range' && this.selectedDates.length !== 2) {
                return true;
            }

            // must provide a single value
            return this.type === 'single' && this.selectedDates.length !== 1;
        },

        calendarType() {
            return this.type === 'mixed' ? 'range' : this.type;
        },

        availableDates() {
            if (this.rangeConfig === null) {
                return null;
            }

            return this.rangeConfig.map(o => format(ensureJSTimestamp(o.start), 'YYYY-MM-DD'));
        },
    },
    watch: {
        active(state) {
            this.changeState(state);
        },
        value() {
            this.preselectDates();
        },
    },
    mounted() {
        this.active ? this.open(false) : this.close(false);
        this.preselectDates();
    },
    methods: {
        submit() {
            let deliveryWindow;

            // single mode
            if (this.type === 'single') {
                deliveryWindow = startOfDay(this.selectedDates[0]) * 1;
            } else {
                // multi mode
                deliveryWindow = {
                    start: startOfDay(this.selectedDates[0]) * 1,
                    end: this.selectedDates[1] ? endOfDay(this.selectedDates[1]) * 1 : null,
                };
            }

            this.$emit('input', deliveryWindow);
            this.close();
        },

        ensureDate(date) {
            return date && date.getTime ? date : new Date(ensureJSTimestamp(date));
        },

        clear() {
            this.close();
            this.$emit('input', null);
        },

        preselectDates() {
            if (this.value === null) {
                this.selectedDates = [];
                return;
            }

            const dates = [];

            if (this.type === 'single') {
                dates[0] = format(ensureJSTimestamp(this.value), 'YYYY-MM-DD');
            } else {
                dates[0] = this.value.start ? format(ensureJSTimestamp(this.value.start), 'YYYY-MM-DD') : null;
                if (this.value.end) {
                    dates[1] = format(ensureJSTimestamp(this.value.end), 'YYYY-MM-DD');
                }
            }

            this.selectedDates = dates;
        },

        // -----------------------------------------------

        open(notify = true) {
            if (this.disabled) return;
            notify && this.$emit('opening');
            this.isActive = true;
            this.preselectDates();

            setTimeout(
                () => {
                    this.isVisuallyOpen = true;

                    notify && this.$emit('visually-opened');
                },
                notify ? OPEN_DELAY : 0
            );
            notify && this.$emit('opened');
        },

        close(notify = true) {
            notify && this.$emit('closing');
            this.isVisuallyOpen = false;
            this.preselectDates();

            setTimeout(
                () => {
                    this.isActive = false;
                },
                notify ? CLOSE_DELAY : 0
            );

            notify && this.$emit('closed');
        },

        changeState(state) {
            if (this.isActive !== state) {
                state ? this.open() : this.close();
            }
        },

        closeByBackdrop() {
            if (this.isVisuallyOpen) {
                this.changeState(false);
            }
        },

        preventContentClicks(e) {
            e.stopPropagation();
        },
    },
};
</script>

<style lang="scss">
body[class*='js--flyout-open'] {
    overflow: hidden;
}

.date-picker__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color-darkGrey, 0.9);
    z-index: 1000;
    min-height: var(--view-height);

    visibility: hidden;
    opacity: 0;

    transition:
        visibility 0s 0.3s,
        opacity 0.3s ease-out;

    .date-picker--active > & {
        visibility: visible;
        opacity: 1;

        transition: opacity 0.3s ease-out;
    }
}

.date-picker__body {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: 1000;

    .date-picker--desktop > & {
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .date-picker--active > & {
        opacity: 1;
        animation-name: flipInX;
        animation-duration: 0.3s;
        animation-timing-function: ease-out;
    }
}

@keyframes flipInX {
    from {
        opacity: 0;
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg) translateY(40px);
        animation-timing-function: ease-out;
    }

    to {
        opacity: 1;
        transform:
            perspective(400px),
            rotate3d(1, 0, 0, 0deg) translateY(0px);
    }
}

.date-picker__router-content,
.date-picker__content {
    width: 100%;
    max-width: 100%;
    background-color: $color-white;
    // height: var(--view-height);
    max-height: var(--view-height);
}

.date-picker__content {
    display: flex;
    flex-flow: column nowrap;

    .date-picker--desktop & {
        max-width: 600px;
    }
}

.date-picker__header,
.date-picker__footer {
    flex: 0 0 auto;
}

.date-picker__inner {
    overflow: auto;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
}

.date-picker__inner-spacer {
    display: block;
    width: 100%;
    height: 0;
    overflow: hidden;
    flex-shrink: 0;

    // iOS 11.0
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
        height: constant(safe-area-inset-bottom);
    }

    // iOS 11.2
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
        height: env(safe-area-inset-bottom);
    }
}

.date-picker__footer {
    border-top: 1px solid $color-mediumGrey;
}

.date-picker__wrapper {
    position: relative;
    margin: 10px;
    display: flex;
    flex-flow: row nowrap;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.8);
        height: 50px;
        pointer-events: none;
        z-index: 1;
    }

    &::before {
        top: 0;
        border-bottom: 1px solid $color-mediumGrey;
    }

    &::after {
        bottom: 0;
        border-top: 1px solid $color-mediumGrey;
    }
}

.date-picker__track {
    height: 150px;
    width: 100%;
    overflow: scroll;
    scroll-snap-type: y mandatory;
    display: grid;
    grid-auto-rows: 50px;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
}

.date-picker__desktop-wrapper {
    background-color: $color-lightGrey;
}

.date-picker__track-placeholder,
.date-picker__item {
    height: 50px;
    width: 100%;
    line-height: 50px;
    text-align: center;
    scroll-snap-align: center;
    position: relative;
}

.date-picker__item-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
}

.date-picker__time-tracks {
    display: grid;
    grid-template-columns: 1fr 5px 1fr;
    width: 100%;
    font-weight: bold;
    align-items: center;
}

.date-picker__time-fallback {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;

    > * {
        width: 100%;
    }
}

.date-picker--disabled {
    opacity: $opacity-disabled;
}
</style>

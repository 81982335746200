import clsx from 'clsx';
export const CategoryCard = ({ imgSrc, title, description, size = 'md', block = false, dataTest, onSelect, }) => {
    return (<div className={clsx('flex cursor-pointer flex-col rounded border-0 bg-surface shadow-low transition-shadow duration-300 hover:shadow-high', {
            'w-full': block,
            'w-[200px]': !block,
        })} data-test={dataTest} onClick={() => {
            onSelect();
        }}>
            <div className={'w-full rounded-t-md bg-dark-gray-700'}>
                <img src={imgSrc} className={clsx('w-full rounded-t border-t-0 object-cover', {
            'h-[160px]': size === 'lg',
            'h-[100px]': size === 'md',
        })} alt=""/>
            </div>

            <div className={'flex h-full flex-col p-2'}>
                <div className={'flex flex-col gap-1'}>
                    <span className={'font-copy-md-strong text'}>{title}</span>
                    <span className={'font-copy-md text-subdued'}>{description}</span>
                </div>
            </div>
        </div>);
};

<template>
    <div :class="`vehicle-class-attribute-set--level-${nestingLevel}`" class="vehicle-class-attribute-set">
        <Words v-if="nestingLevel === 0" block bold small>{{ translateAttributeName(attributeSet.value) }}</Words>
        <CheckboxField
            v-else
            :value="value"
            :name="attributeSet.value"
            :disabled="readonly"
            multiple
            :option="flatOptions"
            @input="$emit('input', $event)"
        >
            {{ translateAttributeName(attributeSet.value) }}
        </CheckboxField>
        <div class="vehicle-class-attribute-set__options">
            <div
                v-for="option in attributeSet.options"
                :key="option.identifier"
                class="vehicle-class-attribute-set__option"
            >
                <AttributeSetAvailabilitySelection
                    v-if="option.options"
                    :value="value"
                    :readonly="readonly"
                    :nesting-level="nestingLevel + 1"
                    :attribute-set="option"
                    @input="$emit('input', $event)"
                />
                <CheckboxField
                    v-else
                    :value="value"
                    :name="option.value"
                    :disabled="readonly"
                    :option="option.value"
                    @input="$emit('input', $event)"
                >
                    {{ translateAttributeName(option.value) }}
                </CheckboxField>
            </div>
        </div>
    </div>
</template>

<script>
import _flatMap from 'lodash/flatMap';
import { translateAttributeName } from '@/services/AttributeManager/utils';

import Words from '@/components/Typography/Words';
import CheckboxField from '@/components/Form/CheckboxField';

export default {
    name: 'AttributeSetAvailabilitySelection',
    components: {
        CheckboxField,
        Words,
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        attributeSet: {
            type: Object,
            default: null,
        },
        nestingLevel: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        flatOptions() {
            const options = this.attributeSet?.options;
            if (!options) return [];

            // TODO: check if flat map is necessary....?
            return _flatMap(options, option => {
                if (option.options) {
                    return option.options;
                }

                return option.value;
            });
        },
    },
    methods: {
        translateAttributeName,
    },
};
</script>

<style scoped lang="scss">
.vehicle-class-attribute-set + .vehicle-class-attribute-set {
    margin-top: 40px;
}

.vehicle-class-attribute-set,
.vehicle-class-attribute-set__options {
    display: grid;
    gap: 10px;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
}

.vehicle-class-attribute-set--level-1 {
    .vehicle-class-attribute-set__options {
        margin-left: 45px;
    }
}
</style>

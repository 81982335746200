<template>
    <Words block spaced-top yellow small>
        <WarningIcon width="12" class="icon--inline icon--yellow" />
        &nbsp;
        {{ $t('pages.vehicleClass.unavailableAttributeHint') }}
    </Words>
</template>

<script>
import Words from '@/components/Typography/Words';

import WarningIcon from '@/assets/icons/micro/warning.svg';

export default {
    name: 'UnavailableAlert',
    components: {
        Words,

        WarningIcon,
    },
};
</script>

<template>
    <div class="category-sort-management">
        <GridRow :count="2" spacing="large">
            <div>
                <Tree :tree="categoryTree" :click-handler="editCategory" :active-handler="isActiveHandler" />
                <BaseButton :disabled="!!sortCategory || isPending" primary @click="editCategory()">
                    {{ $t('pages.categorySortManagement.actions.editRoot') }}
                </BaseButton>
            </div>
            <transition name="fade">
                <div v-if="sortCategory">
                    <div class="category-sort-management__actions">
                        <BaseButton :disabled="isPending" primary light @click="cancelSorting">
                            {{ $t('pages.categorySortManagement.actions.cancel') }}
                        </BaseButton>
                        <BaseButton :disabled="isPending" primary @click="submitChanges">
                            {{ $t('pages.categorySortManagement.actions.save') }}
                        </BaseButton>
                    </div>
                    <Draggable
                        v-model="sortCategory.items"
                        v-bind="dragOptions"
                        @input="handleSortChange"
                        @start="isDragging = true"
                        @end="isDragging = false"
                    >
                        <transition-group :name="isDragging ? 'transition-list' : null">
                            <div
                                v-for="item in sortCategory.items"
                                :key="item.id"
                                :class="`category-sort-management__item--${item.type}`"
                                class="category-sort-management__item"
                            >
                                <Words block muted bold small>
                                    {{ $t(`pages.categorySortManagement.type.${item.type}`) }}
                                </Words>
                                {{ revertLocalizedValue(item.name) }}
                            </div>
                        </transition-group>
                    </Draggable>
                </div>
                <div v-else class="category-sort-management__note">
                    {{ $t('pages.categorySortManagement.noSelectionNote') }}
                </div>
            </transition>
        </GridRow>
    </div>
</template>

<script>
import _orderBy from 'lodash/orderBy';
import { revertLocalizedValue, revertLocalizedCollectionValues } from '@/services/utils/localization';
import { CONSTRUCTION_PRODUCT } from '@/constants/productTypes';
import TreeNode from '@/components/Tree/TreeNode';
import Toaster from '@/services/Toaster';

import Draggable from 'vuedraggable';
import CategoryApi from '@/services/Api/Platform/Category';
import GridRow from '@/components/Layout/GridRow';
import Tree from '@/components/Tree/Tree';
import Words from '@/components/Typography/Words';
import BaseButton from '@/components/Button/Button';

export default {
    name: 'SortPage',
    components: {
        BaseButton,
        Tree,
        GridRow,
        Words,
        Draggable,
    },
    props: {
        listType: {
            type: String,
            validator: v => [CONSTRUCTION_PRODUCT].includes(v),
            default: CONSTRUCTION_PRODUCT,
        },
    },
    data() {
        return {
            categories: [],
            sortCategory: null,
            isDragging: false,
            isPending: false,
        };
    },
    computed: {
        categoryTree() {
            return TreeNode.factory(revertLocalizedCollectionValues(this.categories, ['name']));
        },
        dragOptions() {
            return {
                ghostClass: 'category-sort-management__item-ghost',
            };
        },
    },
    created() {
        this.refreshCategories();
    },
    methods: {
        revertLocalizedValue,

        async refreshCategories() {
            CategoryApi.getCategoryByType(this.listType).then(categories => {
                this.categories = categories;
            });
        },

        async editCategory(subject = null) {
            let id = subject === null ? null : subject.id;
            this.sortCategory = null;

            // find the id of the construction_type
            if (!id) {
                const rootCategories = await CategoryApi.getSortById();
                rootCategories.items.forEach(item => {
                    if (this.listType === revertLocalizedValue(item.name)) {
                        id = item.id;
                    }
                });
            }

            const sortCategory = await CategoryApi.getSortById(id);

            this.sortCategory = {
                id: sortCategory.id,
                items: _orderBy(sortCategory.items, ['sort'], ['asc']),
            };
        },

        isActiveHandler(item) {
            return this.sortCategory && item.id === this.sortCategory.id;
        },

        handleSortChange() {
            this.sortCategory.items.map((item, i) => {
                item.sort = i;
            });
        },

        cancelSorting() {
            this.sortCategory = null;
        },

        async submitChanges() {
            this.isPending = true;
            try {
                await CategoryApi.updateSortById(this.sortCategory.id, this.sortCategory);
                this.sortCategory = null;
                this.refreshCategories();
                Toaster.success(this.$t('pages.categorySortManagement.successMessage'));
            } catch (err) {
                Toaster.error(err);
            }
            this.isPending = false;
        },
    },
};
</script>

<style lang="scss">
.category-sort-management {
    margin-top: 20px;
}

.category-sort-management__note {
    text-align: center;
}

.category-sort-management__actions {
    display: flex;
    flex-direction: row-reverse nowrap;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;
}

.category-sort-management__item {
    padding: 20px 20px 20px 40px;
    background: $color-white;
    margin-bottom: 10px;
    cursor: move;
    box-shadow: 0;
    margin: 0 0 10px 0;
    transition: margin 0.3s ease-out;
    position: relative;

    &::before {
        content: ':::';
        display: inline-block;
        font-size: 21px;
        letter-spacing: 2px;
        opacity: 0.8;
        position: absolute;
        right: 19px;
        top: 53%;
        transform: translateY(-50%) rotate(90deg);
    }
}

.category-sort-management__item--product {
    border-left: 5px solid $color-grey;
}

.category-sort-management__item-ghost {
    opacity: 0.5;
    margin-left: 20px;
    margin-right: 20px;
}

.transition-list-item-move {
    transition: all 0.5s;
}

.transition-list-item-enter,
.transition-list-item-leave-to {
    opacity: 0;
    transform: translateY(30px);
}

.transition-list-item-leave-active {
    position: absolute;
}
</style>

<template>
    <div v-if="items" class="products-table">
        <GridTable columns="60px auto 180px 250px 200px 100px 40px" class="products-table__table">
            <template #head>
                <GridTableHead v-if="$root.isDesktop && !$root.isApp" class="products-table__head">
                    <Words v-if="!isMobileOrApp" bold>
                        {{ $t('pages.products.supplierProductsList.productTable.id') }}
                    </Words>
                    <Words bold>{{ $t('pages.products.supplierProductsList.productTable.product') }}</Words>
                    <Words bold>{{ $t('pages.products.supplierProductsList.productTable.price') }}</Words>
                    <Words bold>{{ $t('pages.products.supplierProductsList.productTable.category') }}</Words>
                    <Words bold>{{ $t('pages.products.supplierProductsList.productTable.reference') }}</Words>
                    <Words bold>{{ $t('pages.products.supplierProductsList.productTable.status') }}</Words>
                </GridTableHead>
            </template>
            <template v-for="(item, index) in items">
                <GridTableRow
                    :key="`${item.id}-row`"
                    class="products-table__row"
                    :class="{
                        'py-4': isMobileOrApp,
                        'min-h-[80px]': !isMobileOrApp,
                        'cursor-default': item.availableOnMarket === false,
                        'bg-surface-disabled': item.availableOnMarket === false,
                    }"
                    :data-test="`product-table-row-${index}`"
                    :use-mobile-rows="isMobileOrApp"
                >
                    <div v-if="!isMobileOrApp" class="products-table__cell h-12 lg:h-auto" @click="edit(item)">
                        <div
                            class="flex flex-col items-start justify-center"
                            :class="{ 'gap-1': item.productDefinitionId }"
                        >
                            <Words>{{ item.productDefinitionId }}</Words>
                        </div>
                    </div>
                    <div class="products-table__cell h-12 lg:h-auto" @click="edit(item)">
                        <Words v-if="isMobileOrApp" class="w-[90px]" bold>{{
                            $t('pages.products.supplierProductsList.productTable.product')
                        }}</Words>
                        <div class="flex flex-col items-start justify-center" :class="{ 'gap-1': item.customName }">
                            <Words>{{ item.name }}</Words>
                            <Words class="text-subdued" small>{{ item.customName }}</Words>
                        </div>
                    </div>
                    <div class="products-table__cell h-12 lg:h-auto" @click="edit(item)">
                        <Words v-if="isMobileOrApp" class="w-[90px]" bold>{{
                            $t('pages.products.supplierProductsList.productTable.price')
                        }}</Words>
                        <Words>{{ $n(item.price, 'currency') }}</Words>
                    </div>
                    <div class="products-table__cell products-table__categories h-12 lg:h-auto" @click="edit(item)">
                        <Words v-if="isMobileOrApp" class="w-[90px]" bold>
                            {{ $t('pages.products.supplierProductsList.productTable.category') }}
                        </Words>
                        <Words>
                            {{ listCategories(item.productCategories) }}
                        </Words>
                    </div>
                    <div
                        v-if="item.partnerSku || !isMobileOrApp"
                        class="products-table__cell h-12 lg:h-auto"
                        @click="edit(item)"
                    >
                        <Words v-if="isMobileOrApp" class="w-[90px]" bold>{{
                            $t('pages.products.supplierProductsList.productTable.reference')
                        }}</Words>
                        <Words>{{ item.partnerSku }}</Words>
                    </div>
                    <div class="products-table__cell h-12 lg:h-auto">
                        <Words v-if="isMobileOrApp" class="w-[90px]" bold>{{
                            $t('pages.products.supplierProductsList.productTable.status')
                        }}</Words>
                        <Words
                            :class="{
                                'products-table__cell--active': item.available,
                                'products-table__cell--inactive': !item.available,
                            }"
                        >
                            {{
                                item.available
                                    ? $t('pages.products.supplierProductsList.productTable.active')
                                    : $t('pages.products.supplierProductsList.productTable.inactive')
                            }}
                        </Words>
                    </div>
                    <div
                        class="absolute bottom-4 right-4 flex h-12 w-full items-center justify-end lg:relative lg:bottom-auto lg:h-auto lg:justify-center"
                    >
                        <SfContextMenu
                            v-if="(item.availableOnMarket ? menuActionsForActive : menuActionsForInactive).length"
                            :actions="item.availableOnMarket ? menuActionsForActive : menuActionsForInactive"
                            data-test="product-list-context-menu"
                            align="left"
                            @context-menu-edit="edit(item)"
                            @context-menu-duplicate="duplicate(item)"
                            @context-menu-remove="remove(item)"
                        />
                    </div>
                </GridTableRow>
                <SfMessageBox
                    v-if="item.availableOnMarket === false"
                    :key="`${item.id}-message`"
                    type="warning"
                    :headline="
                        $t('pages.products.supplierProductsList.productTable.deactivatedProduct.title', {
                            product: item.name,
                        })
                    "
                >
                    {{ $t('pages.products.supplierProductsList.productTable.deactivatedProduct.message') }}
                    <SfLink bold @click="remove(item)">
                        {{ $t('pages.products.supplierProductsList.productTable.deactivatedProduct.deleteLink') }}
                    </SfLink>
                </SfMessageBox>
            </template>
        </GridTable>
    </div>
</template>

<script>
import { SfContextMenu, SfEditIcon, SfCopyIcon, SfDeleteIcon, SfMessageBox, SfLink } from '@schuettflix/vue-components';

import GridTable from '@/components/Table/GridTable';
import GridTableHead from '@/components/Table/GridTableHead';
import GridTableRow from '@/components/Table/GridTableRow';
import Words from '@/components/Typography/Words';

export default {
    name: 'ProductsTable',
    components: {
        SfContextMenu,
        SfCopyIcon, // eslint-disable-line vue/no-unused-components
        SfEditIcon, // eslint-disable-line vue/no-unused-components
        SfMessageBox,
        SfLink,

        GridTableRow,
        GridTableHead,
        GridTable,
        Words,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        menuActionsForActive() {
            return this.getMenuActions(true);
        },
        menuActionsForInactive() {
            return this.getMenuActions(false);
        },

        isMobileOrApp() {
            return !this.$root.isDesktop || this.$root.isApp;
        },
    },
    methods: {
        getMenuActions(isActiveOnMarket) {
            const options = [];

            if (isActiveOnMarket) {
                if (this.$can('updateSupplierProducts')) {
                    options.push({
                        id: 'edit',
                        icon: SfEditIcon,
                        label: this.$t('pages.products.supplierProductsList.contextMenu.edit.label'),
                    });
                }

                options.push({
                    id: 'duplicate',
                    icon: SfCopyIcon,
                    label: this.$t('pages.products.supplierProductsList.contextMenu.copy.label'),
                });
            }

            if (this.$can('deleteSupplierProducts')) {
                options.push({
                    id: 'remove',
                    icon: SfDeleteIcon,
                    label: this.$t('pages.products.supplierProductsList.contextMenu.delete.label'),
                });
            }

            return options;
        },
        listCategories(categories) {
            const productCategories = [];
            categories.forEach(category => productCategories.push(category.label));
            return productCategories.join(', ');
        },

        edit(product) {
            if (product.availableOnMarket === false) {
                return;
            }
            this.$emit('edit', product.id);
        },

        duplicate(product) {
            if (product.availableOnMarket === false) {
                return;
            }
            this.$emit('duplicate', product.id);
        },

        remove(product) {
            this.$emit('remove', product.id);
        },
    },
};
</script>

<style lang="scss" scoped>
.products-table {
    &__head {
        border-bottom: 1px solid $color-paleGrey;
        background: $sflx-grey-400;
        width: 100%;
        padding: 0;
        display: grid;
        align-items: center;
        box-shadow: none;

        ::v-deep .words {
            padding: 8px 16px;
        }
    }

    &__table {
        box-shadow: none;
    }

    &__cell {
        padding: 0 16px;
        display: flex;
        align-items: center;

        @media screen and (min-width: $screen-lg) {
            padding: 20px 16px;
        }

        &--active {
            color: $color-green;
        }

        &--inactive {
            color: $color-error;
        }
    }

    &__sizes {
        flex: 0 0 90px;
    }

    &__row {
        &:not(:nth-last-child(1)) {
            border-bottom: 1px solid $sflx-grey-400;
        }

        &:hover {
            box-shadow: none;
            outline: none;
        }

        box-shadow: none;
        outline: none;
        z-index: unset;
    }

    &__not-supported {
        padding: 5px 20px;
        background: lighten($color-error, 50);
    }
}
</style>

import VehicleListPage from '@/pages/Vehicle/VehicleListPage';
import VehicleFormPage from '@/pages/Vehicle/VehicleFormPage';

export const createVehicleFormRoute = {
    path: 'new',
    name: 'vehicle-new',
    component: VehicleFormPage,
    meta: {
        requiredAbilities: ['createVehicle'],
    },
};

export const getVehicleManagementRoutes = parentRouteName => {
    return [
        {
            path: 'vehicle-management',
            name: 'vehicle-management',
            component: VehicleListPage,
            props: {
                parentRoute: parentRouteName,
            },
            meta: {
                requiredAbilities: ['haveVehicleManagement'],
            },
            children: [
                {
                    include: 'vehicle-new',
                },
                {
                    path: ':vehicleId',
                    name: 'vehicle-edit',
                    props: route => {
                        return {
                            ...route.params,
                            parentRoute: 'vehicle-management',
                        };
                    },
                    component: VehicleFormPage,
                },
            ],
        },
    ];
};

// @preval

// Returns a pre-evaluated map of display names for each locale based on the translation files.
// Examples:
//      de-DE -> de-DE -> Deutsch, de-DE -> en-GB -> Englisch, de-DE -> pl-PL -> Polnisch, de-DE -> de-AT -> Deutsch (Österreich)
//      en-GB -> de-DE -> German, en-GB -> en-GB -> English, en-GB -> pl-PL -> Polish

const fs = require('fs');
const path = require('path');

const files = [];
const locales = [];
for (const file of fs.readdirSync(path.join(__dirname, './translations'))) {
    if (!file.endsWith('.json')) {
        continue;
    }

    const locale = file.replace('.json', '');
    locales.push(locale);
    files.push(path.join(__dirname, './translations', file));
}

const displayNames = {};
for (const [i, file] of files.entries()) {
    const currentLocale = locales[i];
    const currentLocaleMessages = JSON.parse(fs.readFileSync(file, 'utf8'));

    if (!displayNames[currentLocale]) {
        displayNames[currentLocale] = {};
    }

    const currentLocaleDisplayNames = displayNames[currentLocale];

    for (const locale of locales) {
        const normalizedLocale = locale.replace('-', '').toLowerCase();
        try {
            currentLocaleDisplayNames[locale] = currentLocaleMessages.languages[normalizedLocale].name;
        } catch (e) {
            throw new Error(
                `Missing translation of language ${normalizedLocale} in ${currentLocale}. ` +
                    `Go to https://app.phrase.com/accounts/schuttflix/projects/frontend/locales and add a ` +
                    `translation for the key "languages.${normalizedLocale}.name" in the locale ${currentLocale}.`
            );
        }
    }
}

module.exports = displayNames;

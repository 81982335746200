<template>
    <div class="load-window">
        <div class="load-window__section">
            <ShipmentDatePicker
                v-model="loadWindow.date"
                :available-days="availableDays"
                :range="availableRange"
                :description="descriptionDate || $t(`components.loadWindowEditor.${type}.date.description`)"
                :disabled="disabled"
                @input="handleInput"
            />
        </div>
        <div class="load-window__section">
            <TimeRangePicker
                v-model="loadWindow.time"
                :description="descriptionTime || $t(`components.loadWindowEditor.${type}.time.description`)"
                :disabled="disabled || disabledTime"
                @input="handleInput"
            />
        </div>
    </div>
</template>

<script>
import { greaterThan, isDayMatchingRangeConfig } from '@/services/validation/rules';

import ShipmentDatePicker from '@/pages/Checkout/components/ShipmentDatePicker';
import TimeRangePicker from '@/pages/Checkout/components/TimeRangePicker';

export default {
    name: 'LoadWindow',
    components: {
        ShipmentDatePicker,
        TimeRangePicker,
    },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        type: {
            type: String,
            required: true,
            validator: t => ['loading', 'unloading'].includes(t),
        },
        descriptionDate: {
            type: String,
            default: null,
        },
        descriptionTime: {
            type: String,
            default: null,
        },
        availableDays: {
            type: Array,
            default: null,
        },
        availableRange: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disabledTime: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loadWindow: {},
        };
    },
    computed: {
        isComplete() {
            const { date, time } = this.loadWindow;

            return !!(date && date.start && time && time.startTime && time.endTime);
        },
        isValid() {
            const { date, time } = this.loadWindow;

            return (
                this.isComplete &&
                (time.startTime === time.endTime || greaterThan(time.startTime, null, false)(time.endTime) === true) &&
                isDayMatchingRangeConfig(this.availableDays)(date) === true
            );
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(newValue) {
                this.loadWindow = newValue || {
                    date: null,
                    time: null,
                };
            },
        },
    },
    methods: {
        handleInput() {
            this.$set(this.loadWindow, 'isValid', this.isValid);
            this.$emit('input', this.loadWindow);
        },
    },
};
</script>

<style lang="scss" scoped>
.load-window__section {
    margin-bottom: 20px;
}
</style>

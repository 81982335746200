<template>
    <div>
        <div v-if="mode === 'button'" class="default-inner__button">
            <span v-if="scope.selectedOptions && scope.selectedOptions.length == 1">
                <Words block tiny weight-normal spaced-bottom-tiny muted v-html="scope.label" />
                <Words block weight-normal v-html="labelRenderer(scope.selectedOptions[0])" />
            </span>
            <span v-else-if="scope.selectedOptions.length > 1">
                <Words block tiny weight-normal spaced-bottom-tiny muted v-html="scope.label" />
                <Words block weight-normal>
                    <span v-html="labelRenderer(scope.selectedOptions[0])" />, +
                    <span v-html="scope.selectedOptions.length - 1" />
                </Words>
            </span>
            <Words v-else block weight-normal>
                {{ $t('components.multiselectBox.defaultSelectLabel', { label: scope.label }) }}
            </Words>
            <ArrowIcon width="14" height="14" class="person-inner__arrow" />
        </div>
        <template v-else>
            <Words v-if="scope.isActive" red bold block>
                <span v-html="labelRenderer(scope.option)" />&emsp;
                <TickIcon class="icon--inline icon--red" />
            </Words>

            <Words v-else bold block v-html="labelRenderer(scope.option)" />
        </template>
    </div>
</template>

<script>
import Words from '@/components/Typography/Words';

import ArrowIcon from '@/assets/icons/regular/arrow-stripeless--down.svg';
import TickIcon from '@/assets/icons/micro/tick.svg';

export default {
    name: 'DefaultInner',

    components: {
        Words,

        ArrowIcon,
        TickIcon,
    },

    props: {
        mode: {
            type: String,
            required: true,
            validator: v => ['button', 'option'].includes(v),
        },
        scope: {
            type: Object,
            required: true,
        },
        labelRenderer: {
            type: Function,
            default: option => option,
        },
    },
};
</script>

<style lang="scss">
.default-inner__button {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px 0 20px;
    min-height: 70px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}
</style>

<template>
    <div class="supplier-waste-product-details">
        <ShowMoreText
            class="description"
            :label-more-text="$t('components.ShowMoreText.labelMoreText')"
            :label-less-text="$t('components.ShowMoreText.labelLessText')"
        >
            <Words v-if="product.description && revertLocalizedValue(product.description)" pre>
                {{ revertLocalizedValue(product.description) }}
            </Words>
        </ShowMoreText>
        <div class="tags-wrap">
            <!-- DANGEROUSIBILITY -->
            <WasteTag v-if="product.isDangerous" is-dangerous />

            <!-- AVAILABILITY -->
            <div class="tags-availability-btn-wrap">
                <div class="tags-availability">
                    <Tag
                        :class="['tags-availability__tag']"
                        bold
                        small
                        :yellow="!product.isActive"
                        :green="product.isActive"
                        unbreakable
                    >
                        {{ $t(returnTagAvailability) }}
                    </Tag>
                    <Words :class="['tags-availability__text']" small>
                        {{ $t(returnLabelAvailability) }}
                    </Words>
                </div>
            </div>

            <div
                v-if="
                    product.isActive &&
                    !(isVariantsTableEnabled || product.variantsDisposal.length) &&
                    !(product.isVariantsDisposalLoading || product.isPollutionTypesLoading)
                "
                class="mt-24 flex items-center"
            >
                <i18n
                    path="components.transportListBlock.termsAndPrivacyLabel"
                    tag="span"
                    class="font-copy-xs ml-auto mr-4 pl-2 text-subdued"
                >
                    <a
                        :href="linkLegalTermsAndConditionsSeller"
                        target="_blank"
                        class="font-copy-xs inline text-subdued underline"
                        place="gtc"
                        >{{ $t('components.transportListBlock.termsAndPrivacy.gtcLabel') }}</a
                    >
                    <a
                        :href="linkLegalDataProtectionPolicyGlobal"
                        target="_blank"
                        class="font-copy-xs inline text-subdued underline"
                        place="dpp"
                    >
                        {{ $t('components.transportListBlock.termsAndPrivacy.dppLabel') }}</a
                    >
                </i18n>
                <Button :class="['supplier-waste-product-details__offer']" primary @click="enableVariantsTable">
                    {{ $t(`${i18nKey}.offerOnSchuettflix`) }}
                </Button>
            </div>
        </div>

        <LoadingSpinner v-if="product.isVariantsDisposalLoading || product.isPollutionTypesLoading" block dark />

        <template v-else>
            <SupplierWasteProductDetailsVariantTable
                v-if="isVariantsTableEnabled || !!product.variantsDisposal.length"
                :i18n-key="i18nKey"
                :product="product"
                @deleted-waste-variants="updateVariantsTableVisibility"
            />
        </template>
    </div>
</template>

<script>
import { revertLocalizedValue } from '@/services/utils/localization';
import Button from '@/components/Button/Button';
import LoadingSpinner from '@/components/LoadingSpinner';
import Tag from '@/components/Typography/Tag';
import ShowMoreText from '@/_components/ShowMoreText/ShowMoreText';
import Words from '@/components/Typography/Words';
import SupplierWasteProductDetailsVariantTable from '@/pages/SupplierProducts/components/SupplierWasteProductDetailsVariantTable/SupplierWasteProductDetailsVariantTable';

import WasteTag from '@/pages/ProductManagement/components/WasteTag';
import { mapGetters } from 'vuex';

export default {
    name: 'SupplierWasteProductDetails',
    components: {
        Button,
        LoadingSpinner,
        Tag,
        ShowMoreText,
        SupplierWasteProductDetailsVariantTable,
        WasteTag,
        Words,
    },

    props: {
        i18nKey: {
            type: String,
            default: 'pages.products.supplierProductsList.wasteProductDetails',
        },
        categoryId: {
            type: Number,
            default: 0,
            required: true,
        },
        product: {
            type: Object,
            default: () => ({}),
        },
        factoryId: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            newVariant: {
                pollutionSelect: null,
                tfPricePerTon: 0,
                tfAcceptedQuantityPerDay: 0,
            },

            isVariantsTableEnabled: false,
        };
    },

    computed: {
        ...mapGetters('platform', ['linkLegalDataProtectionPolicyGlobal', 'linkLegalTermsAndConditionsSeller']),
        returnTagAvailability() {
            return this.product.isActive ? 'components.tag.isAvailable' : 'components.tag.notAvailable';
        },
        returnLabelAvailability() {
            return this.product.isActive
                ? 'pages.products.productsList.supplier.tag.labelIsAvailable'
                : 'pages.products.productsList.supplier.tag.labelNotAvailable';
        },
    },

    methods: {
        revertLocalizedValue,
        enableVariantsTable() {
            this.isVariantsTableEnabled = true;
        },
        updateVariantsTableVisibility() {
            this.isVariantsTableEnabled = !!this.product.variantsDisposal.length;
        },
    },
};
</script>

<style lang="scss" scoped src="./SupplierWasteProductDetails.scss" />

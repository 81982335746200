/**
 * Rules definition
 */
export default {
    true() {
        return true;
    },
    false() {
        return false;
    },
    not([check], context) {
        return !this.checkRule(check, context);
    },
    and(checks, context) {
        return checks.every(check => this.checkRule(check, context));
    },
    or(checks, context) {
        return checks.some(check => this.checkRule(check, context));
    },
    isNull([source], context) {
        const value = context.getValue(source);
        return value === null || value === undefined;
    },
    eq(keys, context) {
        const first = context.getValue(keys[0]);
        return keys.slice(1).every(k => String(context.getValue(k)) === String(first));
    },
    neq(keys, context) {
        const first = context.getValue(keys[0]);
        return !keys.slice(1).every(k => String(context.getValue(k)) === String(first));
    },
    lt([source, target], context) {
        return Number(context.getValue(source)) < Number(context.getValue(target));
    },
    lte([source, target], context) {
        return Number(context.getValue(source)) <= Number(context.getValue(target));
    },
    gt([source, target], context) {
        return Number(context.getValue(source)) > Number(context.getValue(target));
    },
    gte([source, target], context) {
        return Number(context.getValue(source)) >= Number(context.getValue(target));
    },
    in([needle, haystack], context) {
        const sourceArray = context.getValue(haystack) || [];
        return sourceArray.includes(context.getValue(needle));
    },
    ability([abilityName, subjectSelector], context) {
        if (!subjectSelector) {
            return this.can(abilityName, context);
        }

        return this.can(abilityName, context.getSubContext(subjectSelector));
    },
    hasType([type], context) {
        const types = context.getValue('types') || [];
        return types.includes(type);
    },
    hasFeature([feature], context) {
        const features = context.getValue('features') || [];
        return Object.prototype.hasOwnProperty.call(features, feature);
    },
    hasActiveFeature([feature], context) {
        const features = context.getValue('features') || [];

        const hasFeature = Object.prototype.hasOwnProperty.call(features, feature);

        if (!hasFeature) return false;

        // Features without active setting are always active
        if (!Object.prototype.hasOwnProperty.call(features[feature], 'active')) {
            return true;
        }

        return features[feature].active === true;
    },
    hasPermission([permission], context) {
        const permissions = context.getValue('permissions') || [];
        return permissions.includes(permission);
    },

    inArray([source, target], context) {
        return [...(context.getValue(target) || [])].includes(context.getValue(source));
    },
};

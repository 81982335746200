<template>
    <LayoutPage is-flyout class="checkout-project-position-type-selection-page">
        <FlyoutHeader slot="flyoutHeader" />

        <div class="container-off-canvas-sm my-6">
            <LoadingCard v-if="isLoading" />
            <template v-else>
                <section
                    v-if="!supplierHasProducts"
                    class="flex h-full w-full flex-col items-center justify-center"
                    data-test="choose-waste-no-waste-products-available"
                >
                    <DisposalNonHazardousIcon width="100" height="60" class="mb-8" />
                    <h3 class="font-headline-lg-strong mb-2">
                        {{
                            $t(
                                'pages.checkout.projectPositionDisposal.pages.chooseWaste.noWasteProductsAvailable.title'
                            )
                        }}
                    </h3>
                    <p class="font-copy-sm mb-6">
                        {{
                            $t(
                                'pages.checkout.projectPositionDisposal.pages.chooseWaste.noWasteProductsAvailable.description'
                            )
                        }}
                    </p>

                    <BaseButton
                        class="w-auto"
                        primary
                        type="button"
                        data-test="back-to-disposer-selection-button"
                        @click="
                            goBack({
                                routeName: $root.findRouteName($route.meta.previous),
                            })
                        "
                    >
                        {{
                            $t('pages.checkout.projectPositionDisposal.pages.chooseWaste.noWasteProductsAvailable.cta')
                        }}
                    </BaseButton>
                </section>
                <section v-else>
                    <SfMessageBox class="mb-8">
                        {{ $t('pages.checkout.projectPositionDisposal.pages.chooseWaste.hint') }}
                    </SfMessageBox>

                    <SfAccordion>
                        <template v-for="category in categories">
                            <SfAccordionItem
                                v-if="category.hasContent"
                                :key="category.id"
                                :data-test="`project-position-waste-details-category-${category.id}`"
                                :open-on-init="hasCategorySelectedProduct(category)"
                                class="mb-4 shadow"
                                icon-type="plus-minus"
                                has-background
                                @accordion-click="params => selectCategory(category)"
                            >
                                <template #title>
                                    <span class="font-copy-md-strong mb-1 block">
                                        {{ category.wasteCode }}
                                    </span>
                                    <span class="font-copy-sm">
                                        {{ revertLocalizedValue(category.name) }}
                                    </span>
                                </template>

                                <div v-if="category.hasContent" class="space-4">
                                    <button
                                        v-for="(product, i) in products[category.id]"
                                        :key="product.id"
                                        class="card card--hovered card--focussed w-full text-left"
                                        :class="[
                                            {
                                                'card--selected !cursor-pointer': isSelectedProduct(product),
                                                'mt-4': i > 0,
                                            },
                                        ]"
                                        :data-test="`project-position-waste-details-product-${product.id}`"
                                        @click="setProduct(product)"
                                        @keyup.enter="setProduct(product)"
                                    >
                                        <WasteCode
                                            is-bold
                                            is-block
                                            font-size="md"
                                            class="mb-1"
                                            :code="product.wasteCode"
                                            :is-hazardous="product.isDangerous"
                                        />
                                        <span class="font-copy-sm">
                                            {{ revertLocalizedValue(product.productName) }}
                                        </span>
                                    </button>
                                </div>
                            </SfAccordionItem>
                        </template>
                    </SfAccordion>
                </section>
            </template>
        </div>
    </LayoutPage>
</template>

<script>
import FlyoutHeader from '@/pages/Checkout/components/Header.vue';
import LayoutPage from '@/components/Layout/Page.v2.vue';
import { mapGetters, mapState } from 'vuex';
import { revertLocalizedValue } from '@/services/utils/localization';
import { navigationFailure } from '@/services/utils/router';
import BaseButton from '@/components/Button/Button';
import { SfAccordion, SfAccordionItem, SfMessageBox } from '@schuettflix/vue-components';

import DisposalNonHazardousIcon from '@/assets/icons/orderItems/disposal.svg';

import WasteCode from '@/_components/WasteCode/WasteCode.vue';
import LoadingCard from '@/pages/Checkout/Disposal/ProjectPosition/Partials/LoadingCard.vue';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';

export default {
    name: 'ChooseWaste',
    components: {
        SfAccordion,
        SfAccordionItem,
        BaseButton,
        FlyoutHeader,
        LayoutPage,

        WasteCode,
        SfMessageBox,
        LoadingCard,

        DisposalNonHazardousIcon,
    },
    props: {
        projectId: {
            type: [Number, String],
            default: null,
        },
    },
    data() {
        return {
            selectedCategory: null,
            isLoading: false,
        };
    },
    computed: {
        ...mapState('projectPosition', ['requestCollection', 'product', 'disposer']),
        ...mapState('waste', { unorderedProducts: 'products' }),
        ...mapState('basket', {
            typeFromBasket: 'type',
            constructionSiteFromBasket: 'constructionSite',
        }),
        ...mapGetters('waste', ['getProductsOrderedByCategory', 'getCategories']),
        categories() {
            return this.getCategories;
        },
        products() {
            return this.getProductsOrderedByCategory;
        },
        supplierHasProducts() {
            return this.categories.some(category => {
                return this.products[category.id] !== undefined;
            });
        },
        isHazardousWaste() {
            return this.requestCollection.type === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS;
        },
        selectedProduct() {
            return this.product.id;
        },
    },
    mounted() {
        this.isLoading = true;
        this.refreshProductsAndCategories();
        this.$store.commit('waste/SET_FACTORY_ID', this.disposer.factoryId);
    },
    methods: {
        revertLocalizedValue,

        isSelectedProduct(product) {
            return this.selectedProduct === product.id;
        },

        isAnyProductSelected() {
            return this.unorderedProducts.some(product => this.isSelectedProduct(product));
        },

        hasCategorySelectedProduct(category) {
            if (!category.hasContent) return false;

            const selectedProductWithinCategory = this.products[category.id].find(
                product => product.id === this.selectedProduct
            );

            return typeof selectedProductWithinCategory !== 'undefined' && !!selectedProductWithinCategory;
        },
        async refreshProductsAndCategories() {
            await this.fetchCategories();
            await this.fetchProducts();
            this.isLoading = false;
        },

        async fetchCategories() {
            await this.$store.dispatch('waste/fetchCategories');
        },
        async fetchProducts() {
            if (!this.categories.length) return;
            const filters = {
                isActive: true,
                isAvailable: true,
                sortBy: 'wasteCode',
                isDangerous: this.isHazardousWaste,
                sortDirection: 'asc',
            };

            await this.$store.dispatch('waste/fetchOrderFactoryWasteProducts', filters);

            if (!this.isAnyProductSelected()) {
                this.$store.commit('projectPosition/RESET_PROJECT_POSITION_PRODUCT');
            }
        },
        selectCategory(category) {
            this.selectedCategory = category;
        },
        setProduct(product) {
            this.$store.commit('projectPosition/SET_PROJECT_POSITION_PRODUCT', product);

            this.$router
                .push({
                    name: this.$root.findRouteName(this.$route.meta.next),
                })
                .catch(navigationFailure);
        },
        goBack() {
            this.$router
                .push({
                    name: this.$root.findRouteName(this.$route.meta.previous),
                })
                .catch(navigationFailure);
        },
    },
};
</script>

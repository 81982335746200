import { appAccounting } from '@schuettflix/app-accounting';
import { appOrderDetails } from '@schuettflix/app-order-details';
import { accessToken, userLocale, gatewayUrl, baseUrl, baseUrlOrder, baseUrlFulfillment, baseUrlCopernicus as baseUrlConstructionProject, } from './sflxAppsSharedStates';
export const appAccountingWC = appAccounting;
export const installSflxApps = ({ analyticsService }) => {
    appAccountingWC.install({
        accessToken,
        gatewayUrl,
        userLocale,
        hostAppVersion: process.env.VUE_APP_VERSION,
        analytics: {
            service: analyticsService,
        },
    });
    appOrderDetails.install({
        accessToken,
        userLocale,
        baseUrlMonolith: baseUrl,
        baseUrlOrder,
        baseUrlFulfillment,
        baseUrlConstructionProject,
    });
};

<template>
    <div class="price-tag" :class="{ 'price-tag--suffix': $slots.suffix }">
        <p class="price-tag__price">
            <slot />
        </p>
        <p v-if="$slots.suffix" class="price-tag__suffix">
            <slot name="suffix" />
        </p>
    </div>
</template>

<script>
export default {
    name: 'PriceTag',
};
</script>

<style lang="scss">
.price-tag {
    background-color: $color-mediumGrey;
    padding: 8px 10px;
    margin: 0 6px;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    text-align: center;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 12px;
        top: 0;
        bottom: 0;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMS42IDMwLjMiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDExLjYgMzAuMzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtmaWxsOiNFNUU1RTU7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTEuNiwxNC43YzAsMy0xLjEsNS44LTIuOCw4Yy0xLjUsMS44LTIuNCw0LjEtMi40LDYuNXYxLjJINS4ydi0xLjJjMC0yLjQtMC45LTQuNy0yLjQtNi41CgljLTEuNy0yLjEtMi44LTQuOS0yLjgtOGMwLTMuMSwxLjEtNS44LDIuOC04YzEuNS0xLjgsMi40LTQuMSwyLjQtNi41VjBoMS4ydjAuMmMwLDIuNCwwLjksNC43LDIuNCw2LjUKCUMxMC41LDguOCwxMS42LDExLjYsMTEuNiwxNC43eiIvPgo8L3N2Zz4K');
        background-size: cover;
        background-position: center center;
    }

    &::before {
        left: -6px;
    }

    &::after {
        right: -6px;
    }
}

.price-tag--suffix {
    padding-top: 4px;
    padding-bottom: 4px;
}

.price-tag__price {
    font-weight: $font-weight-bold;
    font-size: 12px;
    line-height: 1.2;
    margin: 0;
}

.price-tag__suffix {
    font-size: 10px;
    line-height: 1.3;
    color: rgba($color-black, 0.4);
    margin: 0;
}
</style>

import { asyncDelay, preciseFloat } from '@/services/utils';
import GoogleMaps from '@/services/GoogleMaps';
import { mapStateShortByName } from '@/services/StateMapping';
import { formatAddress } from '@/services/utils/address';

/**
 * Normalize locations from google lat() lng() to plain objects
 */
export function normalizeLocation(location, precision = 10) {
    const lat = location.lat.call ? location.lat() : location.lat;
    const lng = location.lng.call ? location.lng() : location.lng;

    return { lat: preciseFloat(lat, precision), lng: preciseFloat(lng, precision) };
}

/**
 * Compare location data, with a specific precision
 * @return {boolean} comparison of both location or false if at least one location is null
 */
export function locationsEqual(locationA, locationB, precision = 10) {
    if (!locationA || !locationB) {
        return false;
    }

    const a = normalizeLocation(locationA, precision);
    const b = normalizeLocation(locationB, precision);

    return a.lat === b.lat && a.lng === b.lng;
}

/**
 * @typedef {{
 *   label: string,
 *   state: string | null,
 *   stateCode: string | null,
 *   country: string | null,
 *   countryCode: string | null,
 *   postalCode: string | null,
 *   city: string | null,
 *   street: string | null,
 *   street_number: string | null,
 *   placeId: string | null,
 *   location: {lat: number, lng: number} | null,
 *   place: google.maps.GeocoderResult,
 *   hasStreetNumber: boolean,
 *   streetComponent: string | null,
 * }} StructuredAddress
 */

/**
 * Reformat google place result to a usable format
 * @param {google.maps.GeocoderResult | google.maps.places.PlaceResult} place
 * @param {Object} fallbackValues
 * @return {StructuredAddress}
 */
// eslint-disable-next-line complexity
export function structurizePlaceResult(place, fallbackValues = {}) {
    const fullLabel = place.formatted_address ? place.formatted_address.replace('Unnamed Road, ', '') : null;

    let hasStreetNumber = false;
    let streetComponent = null;
    const addressComponents = {};
    place.address_components.forEach(component => {
        if (component.types.includes('street_number')) {
            hasStreetNumber = true;
        }
        if (component.types.includes('route')) {
            streetComponent = component;
        }

        if (!component.types.includes('plus_code')) {
            addressComponents[`${component.types[0]}_long`] = component.long_name;
            addressComponents[`${component.types[0]}_short`] = component.short_name;
        }
    });

    const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
    };

    const street = addressComponents.route_long === 'Unnamed Road' ? null : addressComponents.route_long;

    return {
        label: fullLabel || fallbackValues.label || null,
        state: addressComponents.administrative_area_level_1_long || fallbackValues.label || null,
        stateCode:
            mapStateShortByName(addressComponents.administrative_area_level_1_short || null) ||
            fallbackValues.label ||
            null,
        country: addressComponents.country_long || fallbackValues.country || null,
        countryCode: addressComponents.country_short || fallbackValues.countryCode || null,
        postalCode: addressComponents.postal_code_long || fallbackValues.postalCode || null,
        // Locality is normally the city name , but some cities like Prague are devided into political/adminstrative destricts
        // therefore google will not return a locality but a political type to that we will fallback
        city:
            addressComponents.locality_long ||
            addressComponents.political_long ||
            addressComponents.administrative_area_level_2_long ||
            fallbackValues.city ||
            null,
        street: street || fallbackValues.street || null,
        street_number: addressComponents.street_number_long || fallbackValues.street_number || null,
        placeId: fallbackValues.placeId || null, // must be provided later one
        location: location,
        place,
        hasStreetNumber,
        streetComponent: streetComponent || fallbackValues.streetComponent || null,
    };
}

/**
 * @param {string} countryCode
 * @param {string} postcode
 * @returns {boolean}
 */
function isPostcodeLooselyValid(countryCode, postcode) {
    switch (countryCode.toLowerCase()) {
        case 'de':
            return postcode?.length === 5;
        case 'pl':
        case 'cz':
            /**
             * In case of no address
             * postal code only has two digits
             */
            return postcode?.length === 2 || postcode?.length > 4;
        default:
            return !!postcode;
    }
}

/**
 * Reformat google place result to a usable format
 * @param {google.maps.GeocoderResult[]} places
 * @return {StructuredAddress}
 */
export function structurizePlaceResultWithErrorCorrection(places) {
    const address = structurizePlaceResult(places[0]);

    for (let i = 1; i < places.length; i++) {
        if (isPostcodeLooselyValid(address.countryCode, address.postalCode)) {
            break;
        }

        const nextAddress = structurizePlaceResult(places[i]);
        address.postalCode = nextAddress.postalCode;
    }

    return address;
}

/**
 * Geocode data
 * @param {Object} options
 */
async function geocodeFirstResult(options) {
    const result = await geocode(options);
    return result !== null ? result[0] : null;
}

/**
 * Geocode data
 * @param {Object} options
 */
async function geocode(options) {
    return new Promise((resolve, reject) => {
        GoogleMaps.then(google => {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(options, (results, status) => {
                if (status === 'OK' && results.length > 0) {
                    resolve(results);
                } else {
                    reject(null);
                }
            });
        });
    });
}

/**
 * Reverse geocode location
 * @param {Object} location
 */
export async function locationToAddress(location) {
    return geocodeFirstResult({ location });
}

/**
 * Convert Location -> StructurizedAddress
 * @param {Object} location
 * @returns {Promise<unknown>}
 */
export async function locationToStructurizedAddress(location) {
    const places = await geocode({ location });
    let place = structurizePlaceResultWithErrorCorrection(places);

    // fallback mechanism for un-retrievable postalCodes
    if (!place.postalCode) {
        place = await retryGeocoding(place);
    }

    return place;
}

/**
 * Fallback mechanism for retrieving incomplete data from google maps
 * by reverse geocoding address -> location -> address
 * @param place
 * @returns {Promise<unknown>}
 */
async function retryGeocoding(place) {
    const result = await addressToLocation(place);
    const location = result?.geometry?.location;

    if (!location) {
        return result;
    }

    const nextResult = await geocode(location);
    return structurizePlaceResultWithErrorCorrection(nextResult);
}

export function replacePlaceLabelWithFormattedAddress(place) {
    const address = placeToAddress({ place });
    place.label = formatAddress({ address, includeCountry: true });
}

/**
 * Geocode address
 * @param {Object} address
 */
export async function addressToLocation(address) {
    const { street, street_number, postalCode, city, country } = address;
    const addressString = `${street} ${street_number}, ${postalCode} ${city}, ${country}`;

    return await geocode({ address: addressString });
}

/**
 * Get zip for location
 * @param {Object} location
 */
export async function retrieveZipFromLocation(location) {
    const result = await locationToStructurizedAddress(location);
    return result.postalCode;
}

/**
 * Calculate if point is in bounds
 * https://stackoverflow.com/a/10940116/1704139
 *
 * @param point
 * @param sw
 * @param ne
 * @returns {boolean}
 */
export function inBounds(point, sw, ne) {
    const { lat, lng } = point;
    const eastBound = lng < ne.lng();
    const westBound = lng > sw.lng();
    let inLng;

    if (ne.lng() < sw.lng()) {
        inLng = eastBound || westBound;
    } else {
        inLng = eastBound && westBound;
    }

    const inLat = lat > sw.lat() && lat < ne.lat();
    return inLat && inLng;
}

/**
 * Retrieve state for a given location by try and error
 *
 * @param location
 * @param iterationCallback
 * @return {Promise<unknown>}
 */
export async function retrieveStateByLocation(location, iterationCallback) {
    const loc = normalizeLocation(location);

    const distances = [0, 500, 1000, 1500, 2000, 2500, 3000];
    const directions = ['north', 'east', 'south', 'west'];

    for (const i in distances) {
        const distance = distances[i];
        for (const j in directions) {
            if (i == 0 && j > 0) continue;

            const direction = directions[j];
            const l = moveLocation(loc, distance, direction);
            const state = await _retrieveStateByLocation(l);

            iterationCallback && iterationCallback(l, distance, direction, state);

            if (state) return state;

            // throttle api requests
            await asyncDelay(1000);
        }
    }

    return null;
}

async function _retrieveStateByLocation(location) {
    try {
        const address = await locationToAddress(location);
        return structurizePlaceResult(address).stateCode;
    } catch (e) {
        return null;
    }
}

/**
 * Move a location a certain way
 *
 * https://stackoverflow.com/a/38424087/1704139
 *
 * TODO: there is an issue that meters are not actually meters
 *
 * @param location
 * @param distanceMeters
 * @param direction
 * @return {object|null}
 */
export function moveLocation(location, distanceMeters, direction = 'north') {
    location = normalizeLocation(location);
    direction = direction.toLowerCase();
    const { lat: xx_lat, lng: xx_long } = location;

    const EQUATOR_CIRCUMFERENCE = 6371000;
    const POLAR_CIRCUMFERENCE = 6356800;

    const m_per_deg_long = 360 / POLAR_CIRCUMFERENCE;
    const rad_lat = (xx_lat * Math.PI) / 180;
    const m_per_deg_lat = 360 / (Math.cos(rad_lat) * EQUATOR_CIRCUMFERENCE);

    const deg_diff_long = distanceMeters * m_per_deg_long;
    const deg_diff_lat = distanceMeters * m_per_deg_lat;

    const xx_north_lat = xx_lat + deg_diff_long;
    const xx_south_lat = xx_lat - deg_diff_long;

    const xx_east_long = xx_long + deg_diff_lat;
    const xx_west_long = xx_long - deg_diff_lat;

    if (['north', 'south'].includes(direction)) {
        return {
            ...location,
            lat: direction === 'north' ? xx_north_lat : xx_south_lat,
        };
    }

    return {
        ...location,
        lng: direction === 'east' ? xx_east_long : xx_west_long,
    };
}

/**
 * Maps a Google Places result to address
 * @param {object} place
 * @return {{
 *  zip: (string|null),
 *  country: (string|null),
 *  number: (string|null),
 *  city: (string|null),
 *  street: (string|null),
 *  state: (string|null)
 * }}
 */
export function placeToAddress({ place }) {
    return {
        country: place.countryCode ?? null,
        state: place.stateCode ?? null,
        city: place.city ?? null,
        zip: place.postalCode ?? null,
        street: place.street ?? null,
        number: place.street_number ?? null,
    };
}

<template>
    <div class="flag-container flex gap-2">
        <Component :is="currentCountry" class="flag" />

        <SfLink bold theme="dark" @click="$emit('updateLanguage')">
            {{ getLanguageName() }}
        </SfLink>
    </div>
</template>

<script>
import { SfLink } from '@schuettflix/vue-components';

import { getFlagFromLocale, getLanguageFromLocale, getDisplayNameOfLanguage } from '@/i18n/languages';

import { mapGetters } from 'vuex';

export default {
    name: 'FlagContainer',

    components: {
        SfLink,
    },

    emits: ['updateLanguage'],

    computed: {
        ...mapGetters('i18n', {
            currentLocale: 'locale',
        }),

        currentCountry() {
            return getFlagFromLocale(this.currentLocale);
        },
    },

    methods: {
        getLanguageName() {
            return getDisplayNameOfLanguage(this.currentLocale, getLanguageFromLocale(this.currentLocale));
        },
    },
};
</script>
<style scoped>
.flag-container .flag {
    position: relative;
    top: 3px;
}
</style>

import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
/**
 * This File Service is used by the FileUploader.v2.vue component for other components use useClientFile.ts
 */
import { uploadImage, uploadDocument, ProductUseCaseImage, ProductUseCaseDocument } from '@schuettflix/client-file';
import { FileLogService as LogService } from '@/logger/FileLogService';
import Toaster from '@/services/Toaster';
import i18n from '@/i18n';
import _isString from 'lodash/isString';
import _isObject from 'lodash/isObject';
import { Capacitor } from '@capacitor/core';
export const removeConflictingProps = (props) => {
    delete props.size;
    return props;
};
export const isFileDocument = (file) => _isString(file.url);
export const isFileImage = (file) => _isObject(file.url);
/**
 * General File Service
 *
 * @class FileService
 */
class FileService {
    log;
    constructor() {
        this.log = new LogService('services/File');
    }
    /**
     * Upload document or image
     */
    async uploadDocumentOrImage({ file, progressCallback, productUseCases = {}, abortController, acceptedFileTypes = {
        pdf: ['application/pdf'],
        image: ['image/jpeg', 'image/jpg', 'image/png'],
    }, documentType = undefined, disableToast = false, }) {
        try {
            const { name, type } = file;
            if (acceptedFileTypes.image.includes(type)) {
                // upload file
                const { data } = await uploadImage({
                    file,
                    onUploadProgressPercent: progressCallback,
                    meta: {
                        productUseCase: productUseCases.image || ProductUseCaseImage.WasteImage,
                    },
                    signal: abortController?.signal,
                });
                if (!disableToast)
                    Toaster.success(String(i18n.t('components.fileUploader.actions.success.image', { fileName: name })));
                return { type: 'image', image: data };
            }
            else if (acceptedFileTypes.pdf.includes(file.type)) {
                // upload file
                const { data } = await uploadDocument({
                    file,
                    onUploadProgressPercent: progressCallback,
                    meta: {
                        productUseCase: productUseCases.document ||
                            ProductUseCaseDocument.WasteDocument,
                        documentTypeCode: documentType,
                    },
                    signal: abortController?.signal,
                });
                if (!disableToast)
                    Toaster.success(String(i18n.t('components.fileUploader.actions.success.document', { fileName: name })));
                return { type: 'document', document: data };
            }
            else {
                throw new Error(i18n.t('components.fileUploader.actions.error.fileType', { fileType: type }));
            }
        }
        catch (err) {
            this.log.error(err);
            throw err;
        }
    }
    async downloadFile({ uri, type = 'application/pdf', path, folder = Directory.Data, isApp = false, }) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            try {
                const file_request = await fetch(uri);
                const file_blob = await file_request.blob();
                const reader = new FileReader();
                reader.readAsDataURL(file_blob);
                reader.onloadend = async () => {
                    if (isApp || Capacitor.isNativePlatform()) {
                        await Filesystem.checkPermissions();
                        const downloadedFile = await Filesystem.downloadFile({
                            path: path,
                            url: uri,
                            directory: folder,
                            recursive: false,
                        });
                        const fileOpenerOptions = {
                            filePath: downloadedFile.path || '',
                            contentType: type || file_blob.type,
                        };
                        await FileOpener.open(fileOpenerOptions).catch(err => {
                            this.log.error(err);
                            Toaster.error(err);
                        });
                    }
                    else {
                        const href = URL.createObjectURL(file_blob);
                        const link = document.createElement('a');
                        link.href = href;
                        link.download = path;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                    resolve(true);
                };
            }
            catch (err) {
                reject(err);
            }
        });
    }
    async downloadDocument(document) {
        await this.downloadFile({
            uri: document.url,
            path: document.path,
            folder: Directory.Data,
            type: document.type || 'application/pdf',
        });
    }
    async downloadImage(image) {
        await this.downloadFile({
            uri: image.url.original,
            path: image.fileName,
            folder: Directory.Library,
            type: image.type || 'image/jpeg',
        });
    }
}
export default new FileService();

import { useCan, useRouteContext } from '@/utils/composition-helper';
import { computed } from 'vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { transportProgress } from '@/services/utils/transport';
export const transportProps = {
    transport: {
        type: Object,
        required: true,
    },
};
export function useTransport(props) {
    const { inRouteContext, routeContext } = useRouteContext();
    const can = useCan();
    const { useGetters } = createNamespacedHelpers('user');
    const { id: currentUserId, permissions } = useGetters(['id', 'permissions']);
    const isPlatform = computed(() => inRouteContext('platform'));
    const isClient = computed(() => inRouteContext('client'));
    const isSupplier = computed(() => inRouteContext('supplier'));
    const isCarrier = computed(() => inRouteContext('carrier'));
    const isPlatformOrClient = computed(() => isPlatform.value || isClient.value);
    const isSupplierOrCarrier = computed(() => isSupplier.value || isCarrier.value);
    const isDelivery = computed(() => props.transport?.type === 'delivery');
    const isShipment = computed(() => props.transport?.type === 'shipment');
    const isPickup = computed(() => props.transport?.type === 'pickup');
    const isDisposal = computed(() => props.transport?.type === 'disposal');
    const isDriver = computed(() => inRouteContext('carrierDriver') || (isPickup.value && props.transport?.driver?.id === currentUserId.value));
    const isDriverOfTransport = computed(() => props.transport?.driver?.id === currentUserId.value);
    /**
     * Address List based on transport type
     */
    const defaultAddressList = (transport) => {
        const { supplier, loadingAddress, constructionSite } = transport;
        return [
            {
                headline: supplier?.name,
                address: loadingAddress || supplier?.billingAddress,
            },
            {
                headline: constructionSite.name,
                address: constructionSite.address,
                maximumTrafficability: constructionSite.maximumTrafficability,
            },
        ];
    };
    const shipmentAddressList = (transport) => {
        const { loadingName, loadingAddress, loadingSiteInformation, unloadingName, unloadingAddress, unloadingSiteInformation, } = transport;
        return [
            {
                headline: loadingName ||
                    loadingAddress?.additionalAddress ||
                    `${loadingAddress?.zip} ${loadingAddress?.city}`,
                address: loadingAddress,
                hasInformation: Boolean(loadingSiteInformation),
                type: 'loading',
            },
            {
                headline: unloadingName ||
                    unloadingAddress?.additionalAddress ||
                    `${unloadingAddress?.zip} ${unloadingAddress?.city}`,
                address: unloadingAddress,
                type: 'unloading',
                hasInformation: Boolean(unloadingSiteInformation),
            },
        ];
    };
    const disposalAddressList = (transport) => {
        const { unloadingName, unloadingAddress, constructionSite } = transport;
        return [
            {
                type: 'loading',
                headline: constructionSite.name,
                address: constructionSite.address,
                maximumTrafficability: constructionSite?.maximumTrafficability,
            },
            {
                type: 'unloading',
                headline: unloadingName,
                address: unloadingAddress,
            },
        ];
    };
    const addressProgressList = computed(() => {
        let list = [];
        if (isShipment.value) {
            list = shipmentAddressList(props.transport);
        }
        else if (isDisposal.value) {
            list = disposalAddressList(props.transport);
        }
        else {
            list = defaultAddressList(props.transport);
        }
        return {
            list,
            progress: transportProgress(props.transport.status),
        };
    });
    return {
        can,
        inRouteContext,
        routeContext,
        isPlatform,
        isClient,
        isSupplier,
        isCarrier,
        isPlatformOrClient,
        isSupplierOrCarrier,
        isDelivery,
        isShipment,
        isPickup,
        isDisposal,
        isDriver,
        isDriverOfTransport,
        currentUserId,
        permissions,
        addressProgressList,
    };
}

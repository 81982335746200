var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.isWeighingNoteDuplicate.isDuplicate)?_c(_setup.SfMessageBox,{attrs:{"headline":_setup.isWeighingNoteDuplicate.duplicatesCount
            ? String(
                  _vm.$t('components.transport.duplicateWeighingNoteWarning.headlineWithCounter', {
                      counter: _setup.isWeighingNoteDuplicate.duplicatesCount,
                  })
              )
            : String(_vm.$t('components.transport.duplicateWeighingNoteWarning.headline')),"data-test":"transport-duplicate-weighing-note-warning"},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c(_setup.SfLink,{attrs:{"bold":""},on:{"click":function($event){return _vm.$emit('replaceWeighingNote')}}},[_vm._v(" "+_vm._s(_vm.$t('components.transport.duplicateWeighingNoteWarning.replaceWeighingNote'))+" ")])]},proxy:true}],null,false,806571246)},[_vm._v(" "+_vm._s(_vm.$t('components.transport.duplicateWeighingNoteWarning.content'))+" ")]):_vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
    <div class="telephone-contact">
        <Flyout :active="telephoneOrderContactFlyoutActive" no-header size="small" @closed="closeFlyout">
            <ConfirmationScreen @close="closeFlyout">
                <div slot="content">
                    <div class="telephone-contact__avatar">
                        <TelephoneContactAvatar />
                    </div>

                    <Tile no-border no-padding-bottom>
                        <Words block bold spaced-bottom>
                            {{ $t('telephoneOrderContact.title') }}
                        </Words>

                        <Words block spaced small>
                            {{ $t('telephoneOrderContact.descriptionLine1') }}
                        </Words>

                        <Words small>
                            {{ $t('telephoneOrderContact.descriptionLine2') }}
                        </Words>
                    </Tile>

                    <Tile no-border>
                        <Words block bold small spaced>
                            {{ $t('telephoneOrderContact.timeHourTitle') }}
                        </Words>

                        <BusinessHours
                            :opening-times="computedOpeningTimes"
                            :opening-timestamp="computedOpeningTimestamp"
                            :closing-timestamp="computedClosingTimestamp"
                        />
                    </Tile>
                </div>
            </ConfirmationScreen>

            <div v-if="$root.isDesktop" class="telephone-contact__button">
                <Button block primary levitate @click="openPhoneCheckout">
                    {{ $t('telephoneOrderContact.telephoneButton') }}

                    <ArrowRightIcon slot="right" width="14" height="14" />
                </Button>
            </div>

            <Button v-else slot="bottom" block primary levitate @click="openPhoneCheckout">
                {{ $t('telephoneOrderContact.telephoneButton') }}

                <ArrowRightIcon slot="right" width="14" height="14" />
            </Button>
        </Flyout>
    </div>
</template>

<script>
import Flyout from '@/components/Layout/Flyout';
import Tile from '@/components/Layout/Tile';
import Words from '@/components/Typography/Words';
import Button from '@/components/Button/Button';
import BusinessHours from '@/components/Supplier/BusinessHours';
import ConfirmationScreen from '@/components/Layout/ConfirmationScreen';
import TelephoneContactAvatar from '@/components/TelephoneContactAvatar';

import ArrowRightIcon from '@/assets/icons/regular/arrow-stripeless.svg';
import { mapGetters } from 'vuex';

export default {
    name: 'TelephoneOrderContact',

    components: {
        Flyout,
        Tile,
        Words,
        Button,
        BusinessHours,
        ConfirmationScreen,
        TelephoneContactAvatar,

        ArrowRightIcon,
    },

    props: {
        telephoneOrderContactFlyoutActive: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapGetters('platform', ['openingTimes', 'orderPhoneNumber']),

        computedOpeningTimes() {
            return this.openingTimes?.openingTimes || [];
        },

        computedOpeningTimestamp() {
            return this.openingTimes?.openingTimestamp;
        },

        computedClosingTimestamp() {
            return this.openingTimes?.closingTimestamp;
        },
    },

    methods: {
        closeFlyout() {
            this.$eventHub.$emit('page.telephoneOrderContact', false);
        },

        openPhoneCheckout() {
            window.location.href = `tel:${this.orderPhoneNumber}`;
        },
    },
};
</script>

<style lang="scss">
.telephone-contact__header {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 40px;
}

.telephone-contact__avatar {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 12px;
}

.telephone-contact__button {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 40px;
}
</style>

<template>
    <div
        class="tile-trigger gap-6 pl-4"
        :class="{
            'tile-trigger--no-left': !$slots.left,
            'tile-trigger--loading': isLoading,
            'tile-trigger--spaced-top': spacedTop,
            'tile-trigger--spaced-top-small': spacedTopSmall,
            'tile-trigger--spaced-grid': spacedGrid,
            'tile-trigger--primary': primary,
            'tile-trigger--big': isSize('big'),
        }"
        @click="handleClick"
    >
        <div v-if="$slots.left" class="self-center justify-self-end">
            <slot name="left" />
        </div>
        <div class="tile-trigger__content">
            <slot />
        </div>
        <div v-if="!noArrow" class="tile-trigger__right">
            <CircleSpinner v-if="isLoading" dark class="tile-trigger__spinner" />
            <ArrowRightIcon v-else width="14" height="14" />
        </div>
        <div v-else-if="$slots.right" class="tile-trigger__right">
            <slot name="right" />
        </div>
    </div>
</template>

<script>
import CircleSpinner from '@/components/Spinners/CircleSpinner';

import ArrowRightIcon from '@/assets/icons/regular/arrow-stripeless.svg';

export default {
    name: 'TileTrigger',
    components: {
        CircleSpinner,

        ArrowRightIcon,
    },
    props: {
        spacedTop: {
            type: Boolean,
            default: false,
        },
        primary: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'normal',
            validator: v => ['normal', 'big'].includes(v),
        },
        noArrow: {
            type: Boolean,
            default: false,
        },
        spacedTopSmall: {
            type: Boolean,
            default: false,
        },
        spacedGrid: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        isSize(size) {
            return this.size === size;
        },

        handleClick(e) {
            if (this.isLoading) return;
            this.$emit('click', e);
        },
    },
};
</script>

<style lang="scss">
.tile-trigger {
    background-color: #fff;
    width: 100%;
    display: grid;
    grid-template-columns: 75px 1fr 50px;
    padding: 15px 0;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
    align-items: center;
    cursor: pointer;
    transition:
        transform 0.2s ease,
        box-shadow 0.2s ease;
    position: relative;
    z-index: 1;
}

.tile-trigger:active,
.tile-trigger--loading {
    transform: translateY(5px);
    box-shadow: $boxShadow-card;
}

.tile-trigger--loading {
    cursor: default;
}

.tile-trigger__content {
    align-items: center;

    > * + * {
        margin-top: 5px;
    }
}

.tile-trigger__left,
.tile-trigger__right {
    justify-self: center;
    align-self: center;
}

.tile-trigger--no-left {
    .tile-trigger__content {
        grid-column: 1 / span 2;
    }

    .tile-trigger__right {
        grid-column: 3 / 3;
    }
}

.tile-trigger--spaced-top {
    margin-top: 20px;
}

.tile-trigger--spaced-grid {
    grid-gap: 15px;
}

.tile-trigger--primary {
    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: initial;
    color: $color-white;
    background-color: $color-red;

    svg {
        .f-base,
        .f-highlight {
            fill: $color-white;
        }

        .s-base,
        .s-highlight {
            stroke: $color-white;
        }
    }
}

.tile-trigger--big {
    padding: 24px 0 22px 20px;
}

.tile-trigger--spaced-top-small {
    margin-top: 12px;
}

.tile-trigger__spinner {
    margin: -1px 0;
}
</style>

import React from 'react';
export const CancelIllustration = ({ className }) => (<svg width={140} height={140} fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="m105.45 96.97-8.16 4.41M112.09 105.77l-14.29 7.71M111.869 118.23l-9.68 5.41" stroke="red" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.83 76.15v7.55M19.83 56.79v-3.33M85.59 76.15v7.99M19.83 102.94v21.09M85.59 102.94v20.54M85.59 56.79v-3.33M53.71 83.68l-10.99-8.06M85.55 110.71l-11.1-8.14M29.94 102.57l-10.11 8.14M65.21 75.97l-11.14 8.17M92.88 75.62H13.09V57.36h79.79v18.26Z" stroke="#1F1920" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="m18.87 71.02 4.09-9.21M35.04 71.02l4.09-9.21M51.21 71.02l4.09-9.21M67.38 71.02l4.09-9.21M83.54 71.02l4.1-9.21" stroke="red" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M92.88 102.39H13.09V84.14h79.79v18.25Z" stroke="#1F1920" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="m18.87 97.79 4.09-9.21M35.04 97.79l4.09-9.21M51.21 97.79l4.09-9.21M67.38 97.79l4.09-9.21M83.54 97.79l4.1-9.21" stroke="red" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M118.189 124.25h-28.46l14.23-43.08 14.23 43.08ZM72.26 124.25h50.8M111.03 37.04c8.571 0 15.52-6.948 15.52-15.52S119.601 6 111.03 6c-8.572 0-15.52 6.948-15.52 15.52s6.948 15.52 15.52 15.52ZM111.03 37.04v65.67" stroke="#1F1920" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="m118.23 14.5-14.11 14.1M103.74 14.14 118.2 28.6" stroke="red" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M28.71 123.86H11.65M50.29 131.27H25.77M62.06 117.78h-5.5M111.29 129.31h17.26M13.09 115.78H3.8M119.92 112.66h16.28" stroke="#1F1920" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>);

const state = {
    navigationCollapsed: false,
};

const getters = {};

const mutations = {
    setNavigationCollapsed(state, navigationState) {
        state.navigationCollapsed = navigationState;
    },
    toggleNavigationCollapsed(state) {
        state.navigationCollapsed = !state.navigationCollapsed;
    },
};

const actions = {
    setNavigationCollapsed({ commit }, navigationState) {
        commit('setNavigationCollapsed', navigationState);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

import React, { Fragment, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Transition } from '@headlessui/react';
import { ArrowLeftIcon } from '@/constructionProjects/components/icons/ArrowLeftIcon';
import { useAnalyticsService } from '@schuettflix/analytics-react';
const FlyoutHeader = ({ backgroundColor, title, subtitle, onClose, actions }) => {
    return (<div className="box-border flex h-[80px] shrink-0 items-center justify-between border-b border-b-divider px-4" style={{ backgroundColor }} data-test="flyout-header">
            <div className="flex w-full items-center">
                <ArrowLeftIcon size="sm" className="mr-4 flex-shrink-0 cursor-pointer" onClick={onClose}/>
                <div className="flex flex-col overflow-hidden">
                    <p className="font-copy-md-strong truncate">{title}</p>
                    {subtitle && <p className={'truncate text-subdued'}>{subtitle}</p>}
                </div>
                {actions && <div className="ml-auto flex-shrink-0">{actions}</div>}
            </div>
        </div>);
};
const flyoutTargetContainer = document.getElementById('end-of-body');
if (!flyoutTargetContainer) {
    throw new Error('Could not find end-of-body element');
}
export const Flyout = ({ appear = false, body, footer, show, onClose, onCloseAnimationEnd, headerTitle, headerSubtitle, headerBackgroundColor, headerActions, screenName, }) => {
    const analyticsService = useAnalyticsService();
    useEffect(() => {
        if (show) {
            analyticsService.enterScreen(screenName);
        }
    }, [analyticsService, screenName, show]);
    const onFlyoutClose = () => {
        analyticsService.leaveScreen(screenName);
        onClose();
    };
    return createPortal(<Transition.Root as={Fragment} appear={appear} show={show} afterLeave={onCloseAnimationEnd}>
            <div className="relative">
                <Transition.Child as="div" className="fixed inset-x-0 bottom-0 top-[env(safe-area-inset-top)] bg-overlay" enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0"/>
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pl-10 pointer-events-none fixed bottom-0 right-0 top-[env(safe-area-inset-top)] flex max-w-full">
                            <Transition.Child as="div" className="pointer-events-auto relative w-screen max-w-lg" enter="transform transition ease-in-out duration-300" enterFrom="translate-x-full" enterTo="translate-x-0" leave="transform transition ease-in-out duration-100" leaveFrom="translate-x-0" leaveTo="translate-x-full">
                                <div className="shadow-xl flex h-full flex-col bg-white">
                                    <FlyoutHeader backgroundColor={headerBackgroundColor} title={headerTitle} subtitle={headerSubtitle} actions={headerActions} onClose={onFlyoutClose}/>
                                    <div className="relative flex-1 overflow-y-auto bg">{body}</div>
                                    {footer && <div className="border-t border-t-divider px-4 py-6">{footer}</div>}
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </div>
        </Transition.Root>, flyoutTargetContainer);
};

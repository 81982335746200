import { computed, reactive, ref } from 'vue';
import { integer, minValue, required, maxLength } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { mapErrors, remapErrors } from '@/services/validation/serverErrorsMapper';
import { optional } from '@/services/validation/validators';
import { ProductAttributeField as Field } from '@/services/ProductCatalog/constants';
const form = reactive({
    minimumGuaranteedAmount: 0,
    price: '0',
    customName: '',
    partnerSku: '',
    tags: [],
    isBlockedOnSpotMarket: false,
    [Field.GEOLOGY]: null,
    [Field.GEOGRAPHY]: null,
    [Field.CHARACTERISTICS]: null,
    [Field.CERT_1]: null,
    [Field.CERT_2]: null,
    [Field.CERT_3]: null,
    [Field.PROCESSING]: null,
    [Field.COLOR]: [],
    [Field.BRAND]: null,
});
const rules = computed(() => ({
    minimumGuaranteedAmount: {
        required,
        integer,
        minValue: minValue(0),
    },
    price: {
        required,
    },
    geology: {
        maxLength: maxLength(256),
    },
    geography: {
        maxLength: maxLength(256),
    },
    customName: {
        maxLength: maxLength(256),
    },
    partnerSku: {
        maxLength: maxLength(256),
    },
    tags: { optional },
    isBlockedOnSpotMarket: { optional },
    [Field.CHARACTERISTICS]: { optional },
    [Field.CERT_1]: { optional },
    [Field.CERT_2]: { optional },
    [Field.CERT_3]: { optional },
    [Field.PROCESSING]: { optional },
    [Field.COLOR]: { optional },
    [Field.BRAND]: { optional },
}));
const setServerErrors = (errors) => {
    const remappedErrors = remapErrors(errors);
    $externalResults.value = mapErrors(remappedErrors);
};
const $externalResults = ref({});
const v$ = useVuelidate(rules, form, { $externalResults });
const attributes = ref([]);
export { form, attributes, v$, setServerErrors };

<template>
    <LayoutPage is-flyout :screen-name="screenName" class="checkout-shipment-document-page">
        <Header slot="flyoutHeader" />

        <div class="container-deprecated">
            <Words block spaced-top>{{ $t('pages.checkout.shipmentSchedulingPage.message') }}</Words>

            <TileTrigger spaced-top size="big" data-test="button-flexible-transport-period" @click="nextWindowSelect()">
                <Words bold>{{ $t('pages.checkout.shipmentSchedulingPage.shippingWindowType.default') }}</Words>
            </TileTrigger>

            <TileTrigger
                spaced-top
                size="big"
                data-test="button-schedule-loading-time"
                @click="nextDateSelect('loading')"
            >
                <Words bold>{{ $t('pages.checkout.shipmentSchedulingPage.shippingWindowType.loading') }}</Words>
            </TileTrigger>

            <TileTrigger
                v-if="!isDisposal"
                spaced-top
                size="big"
                data-test="button-schedule-unloading-time"
                @click="nextDateSelect('unloading')"
            >
                <Words bold>{{ $t('pages.checkout.shipmentSchedulingPage.shippingWindowType.unloading') }}</Words>
            </TileTrigger>
        </div>
    </LayoutPage>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import Header from './components/Header';
import LayoutPage from '@/components/Layout/Page.v2';
import TileTrigger from '@/components/Button/TileTrigger';
import Words from '@/components/Typography/Words';
import { trackCheckoutEvent } from '@/pages/Checkout/trackCheckoutEvent';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';

export default {
    name: 'ShipmentSchedulingPage',
    components: {
        Header,
        LayoutPage,
        TileTrigger,
        Words,
    },
    setup() {
        return {
            screenName: useOrderScreenName('typeselection'),
        };
    },
    computed: {
        ...mapState('basket', ['isCustom']),
        ...mapGetters('basket', ['isDisposal']),
    },
    methods: {
        nextDateSelect(type) {
            this.$store.commit('basket/setPlanningMethod', type);
            this.trackSelectedType(type);
            this.$router
                .push({
                    name: this.$root.findRouteName(this.$route.meta.nextDateSelect),
                })
                .catch(() => {});
        },

        nextWindowSelect() {
            this.$store.commit('basket/setPlanningMethod', 'default');
            this.trackSelectedType('default');
            this.$router.push({ name: this.$root.findRouteName(this.$route.meta.nextWindowSelect) }).catch(() => {});
        },

        trackSelectedType(type) {
            if (!this.isCustom) {
                trackCheckoutEvent('shipmentScheduling', 'selectPlanningType', {
                    type,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>

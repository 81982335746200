import MinMaxValueView from '@/models/VehicleClass/MinMaxValueView';

export default class DimensionView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     */
    static create(data) {
        const view = new DimensionView();

        view.height = MinMaxValueView.create(data?.height);
        view.length = MinMaxValueView.create(data?.length);
        view.width = MinMaxValueView.create(data?.width);

        return view;
    }

    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     */
    static createFromCM(data) {
        const view = new DimensionView();

        view.height = MinMaxValueView.createFromCM(data?.height);
        view.length = MinMaxValueView.createFromCM(data?.length);
        view.width = MinMaxValueView.createFromCM(data?.width);

        return view;
    }

    unfold() {
        return {
            height: this.height?.unfold(),
            length: this.length?.unfold(),
            width: this.width?.unfold(),
        };
    }

    unfoldToCM() {
        return {
            height: this.height?.unfoldToCM(),
            length: this.length?.unfoldToCM(),
            width: this.width?.unfoldToCM(),
        };
    }

    constructor() {
        this.height = MinMaxValueView.create({});
        this.length = MinMaxValueView.create({});
        this.width = MinMaxValueView.create({});
    }
}

import { getProjects, getFavoriteConstructionProjects, getConstructionProjectById, getLocations, getLocationDraftByConstructionProjectId, getOrganizationOptions, getConstructionProjectCounts, getNotificationSetting, getAdminOrganizationOptions, getAdminOrganizations, getConstructionProjectAdminById, getAdminConstructionProjects, getAdminConstructionProjectsMetadata, getConstructionProjectCountsAsAdmin, } from './api/projects';
export const constructionProjectKeys = {
    namespace: ['construction-projects'],
    list: () => [...constructionProjectKeys.namespace, 'list'],
    favorites: () => [...constructionProjectKeys.namespace, 'favorites'],
    byId: (id) => [...constructionProjectKeys.namespace, id],
    byIdForAdmin: (id) => [...constructionProjectKeys.namespace, 'admin', id],
    counts: (id) => [...constructionProjectKeys.byId(id), 'counts'],
    notificationSetting: (id) => [...constructionProjectKeys.byId(id), 'notification-setting'],
    locations: (constructionProjectId) => [...constructionProjectKeys.byId(constructionProjectId), 'locations'],
    locationDraft: (constructionProjectId) => [...constructionProjectKeys.byId(constructionProjectId), 'location-draft'],
    organizationOptions: () => [...constructionProjectKeys.namespace, 'organizations', 'options'],
    adminOrganizationOptions: (organizationId) => [...constructionProjectKeys.namespace, 'organizations', organizationId, 'options'],
    adminOrganizations: () => [...constructionProjectKeys.namespace, 'adminOrganizations'],
    adminConstructionProjects: ({ page, size, status, searchTerm, responsibleEmployeeId, organizationList, }) => [
        ...constructionProjectKeys.allAdminConstructionProjects(),
        page,
        size,
        status,
        searchTerm,
        responsibleEmployeeId,
        organizationList,
    ],
    allAdminConstructionProjects: () => [...constructionProjectKeys.namespace, 'adminConstructionProjects'],
    adminConstructionProjectsMetadata: () => [...constructionProjectKeys.namespace, 'adminConstructionProjectsMetadata'],
    monolithProjectId: (id) => ['project-id', id],
};
export const constructionProjectListQuery = {
    queryKey: constructionProjectKeys.list(),
    queryFn: getProjects,
};
export const constructionProjectFavoritesQuery = {
    queryKey: constructionProjectKeys.favorites(),
    queryFn: getFavoriteConstructionProjects,
};
export const constructionProjectByIdQuery = (id) => {
    return {
        queryKey: constructionProjectKeys.byId(id),
        queryFn: () => getConstructionProjectById(id),
    };
};
export const constructionProjectByIdAsAdminQuery = (id) => {
    return {
        queryKey: constructionProjectKeys.byId(id),
        queryFn: () => getConstructionProjectAdminById(id),
    };
};
export const constructionProjectCountsQuery = (id) => {
    return {
        queryKey: constructionProjectKeys.counts(id),
        queryFn: () => getConstructionProjectCounts(id),
    };
};
export const constructionProjectCountsAsAdminQuery = (id) => {
    return {
        queryKey: constructionProjectKeys.counts(id),
        queryFn: () => getConstructionProjectCountsAsAdmin(id),
    };
};
export const constructionProjectNotificationSettingQuery = (id) => {
    return {
        queryKey: constructionProjectKeys.notificationSetting(id),
        queryFn: () => getNotificationSetting(id),
    };
};
export const constructionProjectLocationsQuery = (constructionProjectId) => {
    return {
        queryKey: constructionProjectKeys.locations(constructionProjectId),
        queryFn: () => getLocations(constructionProjectId),
    };
};
export const constructionProjectLocationDraftQuery = (constructionProjectId) => {
    return {
        queryKey: constructionProjectKeys.locationDraft(constructionProjectId),
        queryFn: () => getLocationDraftByConstructionProjectId(constructionProjectId),
    };
};
export const constructionProjectOrganizationOptionsQuery = {
    queryKey: constructionProjectKeys.organizationOptions(),
    queryFn: getOrganizationOptions,
};
export const adminConstructionProjectOrganizationOptionsQuery = (organizationId) => {
    return {
        queryKey: constructionProjectKeys.adminOrganizationOptions(organizationId),
        queryFn: () => getAdminOrganizationOptions(organizationId),
    };
};
export const adminConstructionProjectOrganizationsQuery = {
    queryKey: constructionProjectKeys.adminOrganizations(),
    queryFn: getAdminOrganizations,
};
export const adminConstructionProjectsQuery = ({ page, size, status, searchTerm, responsibleEmployeeId, organizationList, }) => {
    return {
        queryKey: constructionProjectKeys.adminConstructionProjects({
            page,
            size,
            status,
            searchTerm,
            responsibleEmployeeId,
            organizationList,
        }),
        queryFn: () => getAdminConstructionProjects({ page, size, status, searchTerm, responsibleEmployeeId, organizationList }),
        structuralSharing: false,
    };
};
export const adminConstructionProjectsMetadataQuery = () => {
    return {
        queryKey: constructionProjectKeys.adminConstructionProjectsMetadata(),
        queryFn: () => getAdminConstructionProjectsMetadata(),
        // needed to fix https://linear.app/schuettflix/issue/ASKP-35/probleme-beim-offnen-von-projekten-in-der-app
        // this is caused by loading > 7000 organizations and tanstack query trying to diff them
        // on iOS devices the call stack is only 8k items big
        structuralSharing: false,
    };
};

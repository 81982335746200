var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contingent-progress",class:{ 'contingent-progress--dark': _vm.hasDarkTheme }},[_vm._t("before"),_c('div',{staticClass:"contingent-progress__bar"},[(0 < _vm.view.primaryProgress)?_c('div',{staticClass:"contingent-progress__track contingent-progress__track--delivered",style:({ width: `${_vm.view.primaryProgress}%` })},[(_vm.$slots.primary && _vm.$root.isDesktop)?_c('div',{staticClass:"contingent-progress__tooltip",class:{
                    'contingent-progress__tooltip--right-oriented': _vm.useRightOrientedLeftTooltip,
                }},[_vm._t("primary")],2):_vm._e()]):_vm._e(),(0 < _vm.view.secondaryProgress)?_c('div',{staticClass:"contingent-progress__track contingent-progress__track--secondary",style:({ width: `${_vm.view.secondaryProgress}%` })},[(_vm.$slots.secondary && _vm.$root.isDesktop)?_c('div',{staticClass:"contingent-progress__tooltip",class:{
                    'contingent-progress__tooltip--right-oriented': _vm.useRightOrientedMiddleTooltip,
                    'contingent-progress__tooltip--left-oriented': _vm.useLeftOrientedMiddleTooltip,
                }},[_vm._t("secondary")],2):_vm._e()]):_vm._e(),(0 < _vm.view.remainingProgress)?_c('div',{staticClass:"contingent-progress__track contingent-progress__track--negative",style:({ width: `${_vm.view.remainingProgress}%` })},[(_vm.$slots.default && _vm.$root.isDesktop)?_c('div',{staticClass:"contingent-progress__tooltip",class:{
                    'contingent-progress__tooltip--left-oriented': _vm.useLeftOrientedRightTooltip,
                }},[_vm._t("default")],2):_vm._e()]):_vm._e()]),_vm._t("after")],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
import i18n from '@/i18n';
export function getCurrencySign() {
    return i18n.n(1, 'currencyNoCents').replace('1', '').trim();
}
export function getCurrencySignByCode(currencyCode) {
    const currencies = {
        EUR: '€',
        CZK: 'Kč',
        PLN: 'zł',
    };
    return currencies[currencyCode] || currencyCode;
}

<template>
    <div class="ppcp">
        <div v-if="showOnlyMaterials">
            <div class="ppcp__bd">
                <span class="font-copy-sm-strong mb-6">
                    {{ $t('pages.project.position.quotePrices.material') }}
                </span>

                <div class="quotation-prices__table">
                    <span class="font-copy-sm-strong mb-6">
                        {{ material.info }}
                    </span>
                    <div class="quotation-prices__table-data">
                        <template>
                            <div class="flex justify-between">
                                <span class="font-copy-sm">
                                    {{ $t('pages.project.position.quotePrices.retailPrice') }}
                                </span>
                                <span class="font-copy-sm">
                                    {{ $n(material.purchaseUnitPrice, 'currency') }} /
                                    {{ $t('units.ton') }}
                                </span>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="ppcp__hd" :class="{ 'ppcp__hd--spacedBottom-small': doDisplayItemsInOneLine }">
                <Words bold v-bind="sizeProp" block spaced-bottom> {{ headline }} </Words>
            </div>
            <div class="ppcp__bd">
                <template v-for="(vehicleClass, vid) in transports.collection">
                    <div
                        v-if="vehicleClass.enabled"
                        :key="vid"
                        class="ppcp__item"
                        :class="{ 'ppcp__item-in-one-line': doDisplayItemsInOneLine }"
                    >
                        <Words
                            bold
                            v-bind="sizeProp"
                            :block="!doDisplayItemsInOneLine"
                            :spaced-top="!doDisplayItemsInOneLine"
                            :spaced-bottom="!doDisplayItemsInOneLine"
                        >
                            {{ vehicleClass.count }} &times; {{ vehicleClass.info }}

                            <template v-if="showFullPrices && material.info">- {{ material.info }}</template>
                        </Words>

                        <div class="ppcp__table">
                            <template v-if="showFullPrices">
                                <Words v-bind="sizeProp">
                                    {{ $t('components.projectPositionCostPreview.purchasePriceLabel') }}
                                </Words>
                                <div class="ppcp__price-table">
                                    <Words v-bind="sizeProp" class="ta-r">
                                        {{ $n(vehicleClass.getPurchaseUnitPriceForMaterial(material), 'currency') }} /
                                        {{ $t(`units.ton`) }}
                                    </Words>
                                    <Words v-bind="sizeProp">
                                        {{ $n(vehicleClass.getPurchasePriceTotalsForMaterial(material), 'currency') }}
                                    </Words>
                                </div>
                                <Words v-bind="sizeProp">
                                    {{ $t('components.projectPositionCostPreview.retailPriceLabel') }}
                                </Words>
                                <div class="ppcp__price-table">
                                    <Words v-bind="sizeProp" class="ta-r">
                                        {{ $n(vehicleClass.getRetailUnitPriceForMaterial(material), 'currency') }} /
                                        {{ $t(`units.ton`) }}
                                    </Words>
                                    <Words v-bind="sizeProp">
                                        {{ $n(vehicleClass.getRetailPriceTotalsForMaterial(material), 'currency') }}
                                    </Words>
                                </div>
                            </template>
                            <template v-else>
                                <Words v-bind="sizeProp">
                                    {{ material.info }}
                                </Words>
                                <div class="ppcp__price-table">
                                    <Words v-if="showFixedPrices" v-bind="sizeProp" data-test="price-per-transport">
                                        {{ $n(getPurchaseOrRetailPriceForMaterial(vehicleClass), 'currency') }}
                                        /
                                        {{ $t(`units.transport`) }}
                                    </Words>
                                    <Words v-else v-bind="sizeProp" data-test="price-per-ton">
                                        {{
                                            $n(
                                                showPurchasePrices
                                                    ? getVehicleClassPrices(vehicleClass).purchaseUnitPrice
                                                    : getVehicleClassPrices(vehicleClass).retailUnitPrice,
                                                'currency'
                                            )
                                        }}
                                        /
                                        {{ $t(`units.ton`) }}
                                    </Words>
                                    <Words v-bind="sizeProp" bold>
                                        {{
                                            $n(
                                                showPurchasePrices
                                                    ? getVehicleClassPrices(vehicleClass).purchasePriceTotals
                                                    : getVehicleClassPrices(vehicleClass).retailPriceTotals,
                                                'currency'
                                            )
                                        }}
                                    </Words>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>

                <div v-if="showShippingPrices && shipping && hasShippingPrice" class="ppcp__item">
                    <div class="ppcp__shipping-table">
                        <Words v-bind="sizeProp" block bold>
                            {{ $t('components.projectPositionCostPreview.shippingLabel') }}
                        </Words>
                        <Words v-bind="sizeProp" bold>
                            {{ $n(showPurchasePrices ? shipping.purchasePrice : shipping.retailPrice, 'currency') }}
                            /
                            {{ $t('units.transport') }}
                        </Words>
                    </div>
                </div>

                <Words block spaced-top v-bind="sizeProp">
                    <Words red bold>{{ $t('components.projectPositionCostPreview.hintLabel') }}</Words>
                    {{
                        isCarrier
                            ? $t('components.projectPositionCostPreview.hintCarrier')
                            : $t('components.projectPositionCostPreview.hint')
                    }}
                </Words>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MaterialPricingView from '@/pages/Checkout/components/PriceAdjustment/models/MaterialPricingView';
import TransportPricingCollectionView from '@/pages/Checkout/components/PriceAdjustment/models/TransportPricingCollectionView';
import { PricePositionView } from '@/models/QuotationPricesView';
import { CONTEXT_CARRIER } from '@/constants/context';
import { BILLING_METHODS } from '@/constants/billingTypes';
import { useRouteContext } from '@/utils/composition-helper';
import _isNull from 'lodash/isNull';

import Words from '@/components/Typography/Words';

export default {
    name: 'ProjectPositionCostPreview',
    components: {
        Words,
    },
    props: {
        transports: {
            type: TransportPricingCollectionView,
            required: true,
        },
        material: {
            type: MaterialPricingView,
            required: true,
        },
        shipping: {
            type: PricePositionView,
            default: null,
        },
        size: {
            type: String,
            default: 'small',
            validator: v => ['tiny', 'small', 'sizeNormal', 'large'].includes(v),
        },
        showFullPrices: {
            type: Boolean,
            default: false,
        },
        showPurchasePrices: {
            type: Boolean,
            default: false,
        },
        showShippingPrices: {
            type: Boolean,
            default: false,
        },
        billingMethod: {
            type: String,
            default: '',
        },
        showOnlyMaterials: {
            type: Boolean,
            default: false,
        },
    },

    setup() {
        const { inRouteContext } = useRouteContext();

        return {
            inRouteContext,
        };
    },

    computed: {
        ...mapGetters('user', ['isPlatformAdministrator']),
        hasShippingPrice() {
            return this.shipping?.purchasePrice ?? this.shipping?.retailPrice;
        },
        showFixedPrices() {
            return !this.isPlatformAdministrator && this.billingMethod === BILLING_METHODS.FIXED_PRICE;
        },
        sizeProp() {
            return { [this.size]: true };
        },
        headline() {
            return this.showFullPrices
                ? this.$t('components.projectPositionCostPreview.headline.minimal')
                : this.$t('components.projectPositionCostPreview.headline.descriptive');
        },
        isCarrier() {
            return this.inRouteContext(CONTEXT_CARRIER);
        },
        doDisplayItemsInOneLine() {
            return !this.showFullPrices && !this.material.info;
        },
    },
    methods: {
        getPurchaseOrRetailPriceForMaterial(vehicleClass) {
            return this.showPurchasePrices
                ? vehicleClass.getPurchasePriceTotalsForMaterial(this.material)
                : vehicleClass.getRetailPriceTotalsForMaterial(this.material);
        },
        getVehicleClassPrices(vehicleClass) {
            if (this.isCarrier) {
                /*
                 * Ugly hack coming up. Reason: We need to only show the transport prices for carriers, not transport + material prices.
                 * For organizations who are client and carrier, this is a problem because backend will return both transport + material prices.
                 * It is not easily possible in the BE to fix this, so we decided to fix it here in the FE.
                 */
                return {
                    purchaseUnitPrice: vehicleClass.purchaseUnitPrice.perTon,
                    retailUnitPrice: 0,
                    purchasePriceTotals: vehicleClass.purchasePriceTotals.getPerTon(),
                    retailPriceTotals: 0,
                };
            } else {
                return {
                    purchaseUnitPrice: !_isNull(this.material.purchaseUnitPrice)
                        ? vehicleClass.getPurchaseUnitPriceForMaterial(this.material)
                        : null,
                    retailUnitPrice: vehicleClass.getRetailUnitPriceForMaterial(this.material),
                    purchasePriceTotals: !_isNull(this.material.purchaseUnitPrice)
                        ? vehicleClass.getPurchasePriceTotalsForMaterial(this.material)
                        : null,
                    retailPriceTotals: vehicleClass.getRetailPriceTotalsForMaterial(this.material),
                };
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.ppcp__hd {
    margin-bottom: 30px;
}

.ppcp__hd--spacedBottom-small {
    margin-bottom: 15px;
}

.ppcp__item {
    & + & {
        margin-top: 20px;
        border-top: 1px solid $color-lightMediumGrey;
    }
}

.ppcp__item-in-one-line {
    display: grid;
    grid-template-columns: 3fr max-content;
    grid-column-gap: 20px;
    grid-row-gap: 5px;
    padding: 15px 0;

    & + & {
        margin-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }
}

.ppcp__table {
    display: grid;
    grid-template-columns: 3fr max-content;
    grid-column-gap: 20px;
    grid-row-gap: 5px;
}

.ppcp__price-table {
    display: grid;
    grid-template-columns: 1fr 100px;
    grid-column-gap: 10px;
    text-align: right;
    white-space: nowrap;
}

.ppcp__shipping-table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    padding-top: 20px;

    > :nth-child(even) {
        text-align: right;
    }
}

.ta-r {
    text-align: right;
}
</style>

import MaterialPricingView from '@/pages/Checkout/components/PriceAdjustment/models/MaterialPricingView';
import FreightPricingViewModel from '@/pages/Checkout/components/PriceAdjustment/models/FreightPricingViewModel';
import ShippingPricingView from '@/pages/Checkout/components/PriceAdjustment/models/ShippingPricingView';
import TransportPricingCollectionView from '@/pages/Checkout/components/PriceAdjustment/models/TransportPricingCollectionView';
import CustomPositionPricingSetView from '@/pages/Checkout/components/PriceAdjustment/models/CustomPositionPricingSetView';
import WastePricingView from '@/pages/Checkout/Disposal/ProjectPosition/PriceAdjustment/Models/WastePricingView';

/**
 * Price data instance
 * eg. current / original
 */
export default class PricingDataView {
    constructor() {
        this._material = null;
        this._waste = null;
        this._freight = null;
        this._shipping = null;
        this._transport = null;
        this._customPositions = null;

        this._distance = null;
        this._duration = null;
    }

    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} quote
     * @param {object|null} material
     * @param {object|null} shipping
     * @param {object|null} transport
     * @param {object|null} customPositions
     * @returns {PricingDataView}
     */
    static createFromCustomOrder(
        quote,
        { material = null, shipping = null, transport = null, customPositions = null }
    ) {
        const instance = new PricingDataView();

        // Material
        instance.material = MaterialPricingView.createFromCustomOrder(material);

        // Shipping cost
        instance.shipping = ShippingPricingView.create(shipping);

        // Fake vehicleClasses
        instance.transport = TransportPricingCollectionView.createFromCustomOrder(quote, transport);

        // Additional cost center (shipment)
        instance.customPositions = CustomPositionPricingSetView.createFromCustomOrder(customPositions);

        instance.distance = quote?.lineItemGroups[0]?.lineItems[0]?.totalDistance;
        instance.duration = quote?.lineItemGroups[0]?.lineItems[0]?.totalDuration;

        return instance;
    }

    /**
     * Create a view model from data (e.g. API response data)
     * @param qty
     * @param {object|null} material
     * @param {object|null} freight
     * @param {object|null} shipping
     * @param {object|null} transport
     * @param {object|null} customPositions
     * @returns {PricingDataView}
     */
    static createFromProjectPositionBasket({
        qty = null,
        material = null,
        freight = null,
        shipping = null,
        transport = null,
        customPositions = null,
    }) {
        const instance = new PricingDataView();

        if (material) {
            instance.material = MaterialPricingView.create({
                ...material,
                qty,
            });
        }

        if (freight) {
            instance.freight = FreightPricingViewModel.create({
                ...freight,
                qty,
            });
        }

        instance.shipping = ShippingPricingView.create(shipping);

        instance.transport = TransportPricingCollectionView.createFromProjectPositionBasket(transport);

        instance.distance = instance?.transport?.collection?.[0]?.distance;
        instance.duration = instance?.transport?.collection?.[0]?.duration;

        if (customPositions) {
            instance.customPositions =
                CustomPositionPricingSetView.createFromProjectShipmentPositionBasket(customPositions);
        }

        return instance;
    }

    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     * @returns {PricingDataView}
     */
    static createFromDisposalPricesPayload(data) {
        const instance = new PricingDataView();

        instance.waste = WastePricingView.create(data.productPrices);

        instance.transport = TransportPricingCollectionView.createFromDisposalPricesPayload(data.transport);

        instance.distance = instance?.transport?.collection?.[0]?.distance;
        instance.duration = instance?.transport?.collection?.[0]?.duration;

        if (data.customPositions) {
            instance.customPositions = CustomPositionPricingSetView.createFromDisposalPayload(
                data.customPositions,
                data.transport[0].vehicleClassId
            );
        }

        return instance;
    }

    /**
     * Extend a view model from data (e.g. API response data) of type TYPE_PROJECT_SHIPMENT
     *
     * @param {string|null} freightDescription The description of the freight
     * @param {object|null} customPositions
     */
    extendFromProjectShipmentPositionBasket(freightDescription, { customPositions = null }) {
        this.freight.info = freightDescription;
        this.customPositions = CustomPositionPricingSetView.createFromProjectShipmentPositionBasket(customPositions);
    }

    /**
     * Extend a view model from data (e.g. API response data) of type TYPE_PROJECT_SHIPMENT
     *
     * @param {object} freightDescription The description of the freight
     * @param {number} qty The description of the freight
     * @param {object|null} customPositions
     */
    extendFromProjectShipmentPositionPayload(
        { freightDescription, qty, vehicleClasses },
        { customPositions = null },
        calculationData
    ) {
        this.freight = FreightPricingViewModel.create({
            info: freightDescription,
            qty,
        });

        this.customPositions = CustomPositionPricingSetView.createFromProjectShipmentPositionPayload(
            customPositions,
            vehicleClasses,
            calculationData.transport
        );
    }

    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} projectPosition
     * @param {object} calculationData
     * @returns {PricingDataView}
     */
    static createFromProjectPositionPayload(projectPosition, calculationData) {
        const instance = new PricingDataView();

        // Material
        instance.material = MaterialPricingView.createFromProjectPositionPayload(projectPosition);

        // Shipping cost
        instance.shipping = ShippingPricingView.createFromProjectPositionPayload(projectPosition);

        // Fake vehicleClasses
        instance.transport = TransportPricingCollectionView.createFromProjectPositionPayload(
            projectPosition,
            calculationData.transport
        );

        instance.distance = instance?.transport?.collection?.[0].distance;
        instance.duration = instance?.transport?.collection?.[0].duration;

        return instance;
    }

    /**
     * Create a view model from data (e.g. API response data)
     * @param qty
     * @param {object|null} material
     * @param {object|null} shipping
     * @param {object|null} transport
     * @param {object|null} customPositions
     * @returns {PricingDataView}
     */
    static create({
        material = null,
        freight = null,
        shipping = null,
        transport = null,
        customPositions = null,
        distance = null,
        duration = null,
        waste = null,
    }) {
        const instance = new PricingDataView();

        // Material
        instance.material = MaterialPricingView.create(material);

        // Waste
        instance.waste = WastePricingView.create(waste);

        // Freight
        instance.freight = FreightPricingViewModel.create(freight);

        // Shipping cost
        instance.shipping = ShippingPricingView.create(shipping);

        // Fake vehicleClasses
        instance.transport = TransportPricingCollectionView.createFromProjectPositionBasket(transport);

        // Additional cost center (shipment)
        instance.customPositions = CustomPositionPricingSetView.create(customPositions);

        instance.distance = distance;
        instance.duration = duration;

        return instance;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            material: this.material?.unfold(),
            waste: this.waste?.unfold(),
            freight: this.freight?.unfold(),
            shipping: this.shipping?.unfold(),
            transport: this.transport?.unfold(),
            customPositions: this.customPositions?.unfold(),
            distance: this.distance,
            duration: this.duration,
        };
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfoldToCustomOrder(billingMethod) {
        return {
            material: this.material?.unfoldToCustomOrder(),
            shipping: this.shipping?.unfold(),
            transport: this.transport?.unfoldToCustomOrder(billingMethod),
            customPositions: this.customPositions?.unfoldToCustomOrder(),
        };
    }

    /**
     * Clone the current view
     * @return {PricingDataView}
     */
    clone() {
        return PricingDataView.create(this.unfold());
    }

    /**
     * @return {MaterialPricingView|null}
     */
    get material() {
        return this._material;
    }

    /**
     * @param {MaterialPricingView|null} value
     */
    set material(value) {
        this._material = value ?? null;
    }

    /**
     * @return {WastePricingView|null}
     */
    get waste() {
        return this._waste;
    }

    /**
     * @param {WastePricingView|null} value
     */
    set waste(value) {
        this._waste = value ?? null;
    }

    /**
     * @return {FreightPricingViewModel|null}
     */
    get freight() {
        return this._freight;
    }

    /**
     * @param {FreightPricingViewModel|null} value
     */
    set freight(value) {
        this._freight = value ?? null;
    }

    /**
     * @return {ShippingPricingView|null}
     */
    get shipping() {
        return this._shipping;
    }

    /**
     * @param {ShippingPricingView|object|null} value
     */
    set shipping(value) {
        this._shipping = value ?? null;
    }

    /**
     * @return {TransportPricingCollectionView|null}
     */
    get transport() {
        return this._transport;
    }

    /**
     * @param {TransportPricingCollectionView|null} value
     */
    set transport(value) {
        this._transport = value ?? null;
    }

    /**
     * @return {CustomPositionPricingSetView|null}
     */
    get customPositions() {
        return this._customPositions;
    }

    /**
     * @param {CustomPositionPricingSetView|null} value
     */
    set customPositions(value) {
        this._customPositions = value ?? null;
    }

    /** @returns {number} */
    get customPositionsCount() {
        return !this.customPositions ? 0 : Object.keys(this.customPositions).length;
    }

    /** @returns {number} */
    get transportPurchasePriceTotal() {
        let price = 0;

        if (this.transport) {
            price += this.transport.collection.reduce((acc, vc) => acc + vc.purchasePriceTotals.getPerTransport(), 0);
        }
        price += this.customPositionsPurchasePriceTotal;

        return price;
    }

    /** @returns {number} */
    get transportRetailPriceTotal() {
        let price = 0;

        if (this.transport) {
            price += this.transport.collection.reduce((acc, vc) => acc + vc.retailPriceTotals.getPerTransport(), 0);
        }
        price += this.customPositionsRetailPriceTotal;

        return price;
    }

    /** @returns {number} */
    get customPositionsPurchasePriceTotal() {
        let price = 0;

        if (this.customPositions) {
            this.customPositions.collection.forEach(vcp => {
                if (!vcp.positions) return;

                price += vcp.positions.reduce((acc, cp) => acc + cp.qty * cp.purchaseUnitPrice, 0) * vcp.count;
            });
        }

        return price;
    }

    /** @returns {number} */
    get customPositionsRetailPriceTotal() {
        let price = 0;

        if (this.customPositions) {
            this.customPositions.collection.forEach(vcp => {
                if (!vcp.positions) return;

                price += vcp.positions.reduce((acc, cp) => acc + cp.qty * cp.retailUnitPrice, 0) * vcp.count;
            });
        }

        return price;
    }

    /** @returns {number} */
    get materialTransportPurchasePrice() {
        let price = 0;

        if (this.material) {
            price += this.material.purchasePriceTotal;
        }

        if (this.transport) {
            price += this.transport.collection.reduce((acc, vc) => acc + vc.purchasePriceTotals.getPerTon(), 0);
        }

        return price;
    }

    /** @returns {number} */
    get materialTransportRetailPrice() {
        let price = 0;

        if (this.material) {
            price += this.material.retailPriceTotal;
        }

        if (this.transport) {
            price += this.transport.collection.reduce((acc, vc) => acc + vc.retailPriceTotals.getPerTon(), 0);
        }

        return price;
    }

    /** @returns {number} */
    get purchaseTotalPrice() {
        let price = this.materialTransportPurchasePrice;

        if (this.shipping) {
            price += this.shipping.purchasePriceTotal;
        }

        return price;
    }

    /** @returns {number} */
    get retailTotalPrice() {
        let price = this.materialTransportRetailPrice;

        if (this.shipping) {
            price += this.shipping.retailPriceTotal;
        }

        return price;
    }

    /** @returns {number|null} */
    get distance() {
        return this._distance;
    }

    /**
     * Set distance
     * @param {number} value
     */
    set distance(value) {
        this._distance = value ?? null;
    }

    /** @returns {number|null} */
    get duration() {
        return this._duration;
    }

    /**
     * Set duration
     * @param {number} value
     */
    set duration(value) {
        this._duration = value ?? null;
    }

    /**
     * @return {TransportPricingView[]}
     */
    get enabledTransports() {
        return this.transport.collection.filter(vc => vc.enabled);
    }

    /**
     * @returns {TransportPricingView|null}
     */
    get maxPayloadTransport() {
        if (this.enabledTransports.length === 0) {
            return null;
        }

        return this.enabledTransports.reduce(
            (prev, cur) => {
                return prev.payload > cur.payload ? prev : cur;
            },
            { payload: 0 }
        );
    }

    /**
     * @param {number} transportPrice per Ton price
     * @param {number} payload
     * @param {number} materialQty
     * @param {number|null} materialUnitPrice per Ton price
     * @returns {number}
     * @private
     */
    _calculateTotalContingentPrice(transportPrice, payload, materialQty, materialUnitPrice = null) {
        return (transportPrice + materialUnitPrice) * materialQty;
    }

    /**
     * Calculates the purchase or retail total price.
     *
     * @param transportUnitPrice purchaseUnitPrice or retailUnitPrice of a TransportPricingView
     * @param transportPayload payload of a TransportPricingView
     * @param {number} qty this.material.qty or this.freight.qty
     * @param {number|null} unitPrice either (this.material.purchaseUnitPrice or this.material.retailUnitPrice) or null if using this.freight.qty
     * @return {null|number}
     */
    getTotalPriceForContingent({ transportUnitPrice, transportPayload, qty, unitPrice }) {
        if (!transportUnitPrice && !transportPayload) return null;

        return this._calculateTotalContingentPrice(
            transportUnitPrice.getPerTon(),
            transportPayload / 1000,
            qty / 1000,
            unitPrice
        );
    }

    /** @returns {number|null} */
    get purchaseTotalPriceForContingent() {
        const transport = this.maxPayloadTransport;

        if (!transport) return null;

        return this._calculateTotalContingentPrice(
            transport.purchaseUnitPrice.getPerTon(),
            transport.payload / 1000,
            this.material.qty / 1000,
            this.material.purchaseUnitPrice
        );
    }

    /** @returns {number|null} */
    get retailTotalPriceForContingent() {
        const transport = this.maxPayloadTransport;

        if (!transport) return null;

        return this._calculateTotalContingentPrice(
            transport.retailUnitPrice.getPerTon(),
            transport.payload / 1000,
            this.material.qty / 1000,
            this.material.retailUnitPrice
        );
    }

    /** @returns {number} */
    get customPositionsPurchasePriceTotalForMaxPayloadTransport() {
        let price = 0;

        if (this.customPositions && this.maxPayloadTransport) {
            const maxPayload = this.maxPayloadTransport.payload;
            this.customPositions.collection.forEach(vcp => {
                if (!vcp.positions || vcp.payload !== maxPayload) return;

                price += vcp.positions.reduce((acc, cp) => acc + cp.qty * cp.purchaseUnitPrice, 0) * vcp.count;
            });
        }

        return price;
    }

    /** @returns {number} */
    get customPositionsRetailPriceTotalForMaxPayloadTransport() {
        let price = 0;

        if (this.customPositions && this.maxPayloadTransport) {
            const maxPayload = this.maxPayloadTransport.payload;
            this.customPositions.collection.forEach(vcp => {
                if (!vcp.positions || vcp.payload !== maxPayload) return;

                price += vcp.positions.reduce((acc, cp) => acc + cp.qty * cp.retailUnitPrice, 0) * vcp.count;
            });
        }

        return price;
    }

    /** @return {number} */
    get totalTransportCount() {
        if (!this.maxPayloadTransport) {
            return 0;
        }
        return Math.ceil(this.freight.qty / this.maxPayloadTransport.payload);
    }

    /** @returns {number|null} */
    get purchaseTotalPriceForContingentAndCustomPositions() {
        const transport = this.maxPayloadTransport;
        let price = this.getTotalPriceForContingent({
            transportUnitPrice: transport?.purchaseUnitPrice,
            transportPayload: transport?.payload,
            qty: this.freight.qty,
            unitPrice: null,
        });

        if (price != null) {
            price += this.totalTransportCount * this.customPositionsPurchasePriceTotalForMaxPayloadTransport;
        }
        return price;
    }

    /** @returns {number|null} */
    get retailTotalPriceForContingentAndCustomPositions() {
        const transport = this.maxPayloadTransport;
        let price = this.getTotalPriceForContingent({
            transportUnitPrice: transport?.retailUnitPrice,
            transportPayload: transport?.payload,
            qty: this.freight.qty,
            unitPrice: null,
        });
        if (price != null) {
            price += this.totalTransportCount * this.customPositionsRetailPriceTotalForMaxPayloadTransport;
        }
        return price;
    }
}

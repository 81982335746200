<template>
    <Flyout
        screen-name="useraccount-settings-account-delete"
        :active="myAccountFlyoutActive"
        size="small"
        show-header
        :headline="$t('pages.deleteAccount.headline')"
        @closed="closeDeleteAccountFlyout"
    >
        <Tile no-border>
            <Words block bold>
                {{ $t('pages.deleteAccount.intro.topic') }}
            </Words>
            <Words block spaced class="text-pre" v-html="$t('pages.deleteAccount.intro.text')" />
        </Tile>
        <Tile no-border class="delete-account__tile--grey">
            <Words block bold spaced>
                {{ $t('pages.deleteAccount.cta.topic') }}
            </Words>
            <Words block spaced v-html="$t('pages.deleteAccount.cta.text')" />

            <ButtonGroup floating class="platform-contact__button-group">
                <Button :href="`mailto:service@schuettflix.de`" primary light arrow-right block>
                    <Words bold> service@schuettflix.de </Words>
                    <template #left>
                        <MailIcon width="28" height="28" class="icon--inline" />
                    </template>
                </Button>
            </ButtonGroup>
        </Tile>
    </Flyout>
</template>

<script>
import Flyout from '@/components/Layout/Flyout';

import Tile from '@/components/Layout/Tile';
import Words from '@/components/Typography/Words';
import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';

import MailIcon from '@/assets/icons/regular/email.svg';

export default {
    name: 'DeleteAccount',
    components: {
        Flyout,
        MailIcon,
        Tile,
        Words,
        Button,
        ButtonGroup,
    },
    props: {
        myAccountFlyoutActive: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        closeDeleteAccountFlyout() {
            this.$eventHub.$emit('page.deleteAccount', false);
        },
    },
};
</script>

<style lang="scss" scoped>
.delete-account__tile--grey {
    background-color: $color-lightGrey;
}

.text-pre {
    white-space: pre-wrap;
}
</style>

import PreferredCarrierPage from './PreferredCarrierPage';

export default {
    path: 'preferred-carrier',
    component: PreferredCarrierPage,
    name: 'preferred-carrier',
    meta: {
        requiresAuth: true,
        requiredAbilities: ['havePreferredCarriers'],
    },
};

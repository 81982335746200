import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { ChevronDownIcon } from '@schuettflix/icons-react';
import { useWindowSize } from 'react-use';
export function SlideUpDown({ className, header, active, children }) {
    const [isOpen, setIsOpen] = useState(active ? true : false);
    const [height, setHeight] = useState('');
    const contentRef = useRef(null);
    const size = useWindowSize();
    useEffect(() => {
        setHeight(`${contentRef.current?.scrollHeight}px`);
    }, [size]);
    const toggleSlide = () => {
        setIsOpen(!isOpen);
    };
    return (<div className={className}>
            <a className="flex cursor-pointer select-none flex-row items-center gap-2" onClick={() => toggleSlide()}>
                {header}
                <ChevronDownIcon className={clsx('shrink-0 transition-all', isOpen && 'rotate-180')} size="xs"/>
            </a>
            <div style={{ height: isOpen ? height : '0px' }} className={clsx('mt-1 overflow-hidden transition-[height]')}>
                <div ref={contentRef}>{children}</div>
            </div>
        </div>);
}

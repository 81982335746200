<template>
    <div class="quotation-prices">
        <section
            v-if="view.material && view.material.purchasePrice !== null && view.material.retailPrice !== null"
            class="quotation-prices__section"
            data-test="quotation-prices-material"
        >
            <span class="font-copy-sm-strong m-0 mb-4 block">
                {{ $t('pages.project.position.quotePrices.material') }}
            </span>
            <div class="quotation-prices__table">
                <span class="font-copy-sm-strong m-0 mb-1 block">
                    {{ view.material.title }}
                </span>
                <div class="quotation-prices__table-data">
                    <template v-if="view.material.purchasePrice !== null">
                        <span class="font-copy-sm">{{ $t('pages.project.position.quotePrices.purchasePrice') }}</span>
                        <span class="font-copy-sm">
                            {{ $n(view.material.purchasePrice, 'currency') }} /
                            {{ $t('units.ton') }}
                        </span>
                    </template>
                    <template v-if="view.material.retailPrice !== null">
                        <span class="font-copy-sm">{{ $t('pages.project.position.quotePrices.retailPrice') }}</span>
                        <span class="font-copy-sm">
                            {{ $n(view.material.retailPrice, 'currency') }} /
                            {{ $t('units.ton') }}
                        </span>
                    </template>
                    <template v-if="view.material.retailPriceWithStateWasteCompanyFee">
                        <span class="font-copy-sm">
                            {{ $t('pages.project.position.quotePrices.retailPriceIncludingStateWasteCompanyFee') }}
                        </span>
                        <span class="font-copy-sm">
                            {{ $n(view.material.retailPriceWithStateWasteCompanyFee, 'currency') }} /
                            {{ $t('units.ton') }}
                        </span>
                    </template>
                </div>
            </div>
        </section>
        <section v-if="view.transports" class="quotation-prices__section" data-test="quotation-prices-transports">
            <span class="font-copy-sm-strong m-0 mb-4 block">
                {{ $t('pages.project.position.quotePrices.transports') }}
            </span>
            <div v-for="(t, i) in view.transports" :key="i" class="quotation-prices__table">
                <span class="font-copy-sm-strong mb-1 block">
                    {{ t.title }}
                </span>
                <div class="quotation-prices__table-data">
                    <template v-if="t.purchasePrice !== null">
                        <span class="font-copy-sm">{{ $t('pages.project.position.quotePrices.purchasePrice') }}</span>
                        <span class="font-copy-sm" data-test="purchase-price-and-unit">
                            {{ $n(returnPrice(i, 'purchasePrice'), 'currency') }} /
                            {{ $t(returnPriceUnit) }}
                        </span>
                    </template>
                    <template v-if="t.retailPrice !== null">
                        <span class="font-copy-sm">{{ $t('pages.project.position.quotePrices.retailPrice') }}</span>
                        <span class="font-copy-sm" data-test="retail-price-and-unit">
                            {{ $n(returnPrice(i, 'retailPrice'), 'currency') }} /
                            {{ $t(returnPriceUnit) }}
                        </span>
                    </template>
                </div>
            </div>
        </section>
        <section
            v-if="view.shipping && view.shipping.purchasePrice !== null && view.shipping.retailPrice !== null"
            class="quotation-prices__section"
            data-test="quotation-prices-shipping"
        >
            <span class="font-copy-sm-strong m-0 mb-4 block">
                {{ $t('pages.project.position.quotePrices.shipping') }}
            </span>
            <div class="quotation-prices__table">
                <div class="quotation-prices__table-data">
                    <template v-if="view.shipping.purchasePrice !== null">
                        <span class="font-copy-sm">{{ $t('pages.project.position.quotePrices.purchasePrice') }}</span>
                        <span class="font-copy-sm">
                            {{ $n(view.shipping.purchasePrice, 'currency') }} /
                            {{ $t('units.transport') }}
                        </span>
                    </template>
                    <template v-if="view.shipping.retailPrice !== null">
                        <span class="font-copy-sm">{{ $t('pages.project.position.quotePrices.retailPrice') }}</span>
                        <span class="font-copy-sm">
                            {{ $n(view.shipping.retailPrice, 'currency') }} /
                            {{ $t('units.transport') }}
                        </span>
                    </template>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import QuotationPricesView from '@/models/QuotationPricesView';
import { BILLING_METHODS } from '@/constants/billingTypes';
import { PROJECT_POSITION_TYPE_SHIPMENT } from '@/constants/projectPositionTypes';

export default {
    name: 'ProjectPositionQuotePrices',
    props: {
        view: {
            type: QuotationPricesView,
            required: true,
        },
        projectType: {
            type: String,
            required: true,
        },
        vehicleClasses: {
            type: Array,
            required: true,
        },
        billingMethod: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            unit: '',
        };
    },
    computed: {
        returnPriceUnit() {
            return this.unit;
        },
    },
    methods: {
        returnPrice(transportIndex, priceType) {
            const vehicle = this.vehicleClasses[transportIndex];
            if (
                this.projectType === PROJECT_POSITION_TYPE_SHIPMENT &&
                this.billingMethod === BILLING_METHODS.FIXED_PRICE
            ) {
                this.unit = 'units.transport';
                return (vehicle[priceType] / 1000) * vehicle.vehicleClass.payload;
            }
            this.unit = 'units.ton';
            return vehicle[priceType];
        },
    },
};
</script>

<style lang="scss" scoped>
.quotation-prices__section {
    padding: 20px;

    & + & {
        border-top: $border-solid-2px;
    }
}

.quotation-prices__table {
    & + & {
        margin-top: 10px;
        padding-top: 10px;
        border-top: $border-solid-1px;
    }
}

.quotation-prices__table-data {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-row-gap: 5px;

    > *:nth-child(2n) {
        text-align: right;
    }
}
</style>

<template>
    <LayoutPage>
        <div slot="headline">Test Notification Response</div>

        <Dump v-if="data" :data="data" />
    </LayoutPage>
</template>

<script>
import LayoutPage from '@/components/Layout/Page.v2';
import Dump from '@/components/Debug/Dump/Dump';

export default {
    name: 'NotificationTestPage',
    components: {
        LayoutPage,
        Dump,
    },
    computed: {
        data() {
            return this.$route.query;
        },
    },
};
</script>

<style lang="scss"></style>

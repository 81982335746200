<template>
    <div
        class="carrier-tile"
        :class="{
            'cursor-pointer': clickable,
        }"
        @click="$emit('tile-action', carrier)"
    >
        <div
            v-if="showHeadline"
            class="mb-6 flex flex-nowrap items-center justify-between"
            :class="{ 'cursor-pointer': hasAction }"
            @click="$emit('headline-action', carrier)"
        >
            <span class="font-copy-sm-strong">
                {{ $t('components.carrierTile.headline') }}
            </span>
            <span v-if="isPlatform" class="font-numbers-sm text-subdued">
                {{ carrier.customerNumber }}
            </span>

            <SfIconButton v-if="hasAction" size="sm" :has-background="false">
                <template #icon="iconProps"><SfSysChevronRightIcon v-bind="iconProps" /></template>
            </SfIconButton>
        </div>

        <!-- Carrier name -->
        <div>
            <span class="font-copy-md-strong">
                {{ carrier.name }}
            </span>
            <FormattedAddress
                v-if="showAddress"
                class="mt-1"
                decorate-location-code
                subdued
                :address="carrier.billingAddress"
            />
        </div>

        <div v-if="showEmail || hasWebsite" class="mt-4 flex flex-col items-start gap-2">
            <!-- Carrier email -->
            <SfLink v-if="showEmail" :href="`mailto:${carrier.email}`" target="_blank" size="sm" bold>
                <template #leading-icon="iconProps">
                    <EmailIcon width="16" height="16" v-bind="iconProps" />
                </template>
                {{ carrier.email }}
            </SfLink>

            <!-- Carrier website -->
            <SfLink v-if="hasWebsite" :href="carrier.web" target="_blank" size="sm" bold>
                <template #leading-icon="iconProps">
                    <InternetIcon width="16" height="16" v-bind="iconProps" />
                </template>
                {{ carrier.web }}
            </SfLink>
        </div>

        <!-- Carrier description -->
        <div v-if="showDescription && carrier.description" class="mt-6 border-t border-divider pt-6">
            <span bold block class="font-copy-md-strong mb-2">
                {{ $t('components.carrierTile.description') }}
            </span>
            <div class="font-copy-md">
                <TextBlock :text="carrier.description" />
            </div>
        </div>
    </div>
</template>

<script>
import routeContext from '@/plugins/mixins/routeContext';
import { CONTEXT_PLATFORM } from '@/constants/context';

import FormattedAddress from '@/components/FormattedAddress';
import TextBlock from '@/components/Typography/TextBlock';

import EmailIcon from '@/assets/icons/micro/email.svg';
import InternetIcon from '@/assets/icons/micro/internet.svg';

import { SfLink, SfIconButton, SfSysChevronRightIcon } from '@schuettflix/vue-components';

export default {
    name: 'CarrierTile',
    components: {
        FormattedAddress,
        TextBlock,

        EmailIcon,
        InternetIcon,

        // components
        SfLink,
        SfIconButton,
        SfSysChevronRightIcon,
    },
    mixins: [routeContext],
    props: {
        carrier: {
            type: Object,
            required: true,
        },
        showHeadline: {
            type: Boolean,
            default: false,
        },
        hasAction: {
            type: Boolean,
            default: false,
        },
        showAddress: {
            type: Boolean,
            default: false,
        },
        showEmail: {
            type: Boolean,
            default: false,
        },
        showWebsite: {
            type: Boolean,
            default: false,
        },
        showDescription: {
            type: Boolean,
            default: false,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isPlatform() {
            return this.inRouteContext(CONTEXT_PLATFORM);
        },
        hasWebsite() {
            return this.showWebsite && this.carrier.web;
        },
    },
};
</script>

import { GetPositionResponseSchema } from '@/modules/constructionProjects/api/position-service-interfaces';
import { GenericPositionHTTPClient } from '@/modules/constructionProjects/services/GenericPositionHTTPClient';
export const getGenericPositionById = async (positionId) => {
    return GenericPositionHTTPClient.get(`/v1/position/${positionId}`).then(res => GetPositionResponseSchema.parse(res.data));
};
export function getGenericPositionByIdKey(positionId) {
    return ['generic-positions', positionId];
}
export function getGenericPositionByIdQuery(positionId) {
    return {
        queryKey: getGenericPositionByIdKey(positionId),
        queryFn: () => getGenericPositionById(positionId),
        refetchOnWindowFocus: false,
        suspense: true,
    };
}
export function invalidateGetGenericPositionByIdQuery(queryClient, positionId) {
    queryClient.invalidateQueries(getGenericPositionByIdKey(positionId));
}

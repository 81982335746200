import { onUnmounted } from 'vue';
import { EventBus } from '@/services/EventBus';
import { useStore } from 'vuex-composition-helpers';
export function useEventHub() {
    const store = useStore();
    const eventUnsubscriptionCallbacks = new Set();
    onUnmounted(() => {
        eventUnsubscriptionCallbacks.forEach(unsubscribe => unsubscribe());
    });
    const subscribe = (event, callback) => {
        EventBus.$on(event, callback);
        const unsubscribe = () => {
            EventBus.$off(event, callback);
        };
        eventUnsubscriptionCallbacks.add(unsubscribe);
        return unsubscribe;
    };
    const subscribeMutation = (mutationName, callback) => {
        const unsubscribe = store.subscribe(({ type, payload }) => {
            if (type === mutationName) {
                callback(payload);
            }
        });
        eventUnsubscriptionCallbacks.add(unsubscribe);
        return unsubscribe;
    };
    return {
        subscribe,
        subscribeMutation,
    };
}

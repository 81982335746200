<template>
    <span
        :class="{
            'tag--red': red,
            'tag--white': white,
            'tag--yellow': yellow,
            'tag--green': green,
            'tag--black': black,
            'tag--small': small,
            'tag--tiny': tiny,
            'tag--bold': bold,
            'tag--unbreakable': unbreakable,
            'tag--outlined': outlined,
        }"
        class="tag"
    >
        <slot />
    </span>
</template>

<script>
export default {
    name: 'Tag',
    props: {
        red: {
            type: Boolean,
            default: false,
        },
        white: {
            type: Boolean,
            default: false,
        },
        yellow: {
            type: Boolean,
            default: false,
        },
        green: {
            type: Boolean,
            default: false,
        },
        black: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        bold: {
            type: Boolean,
            default: false,
        },
        unbreakable: {
            type: Boolean,
            default: false,
        },
        tiny: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
$border-width-outlined: 1px;
$border-width-outlined-bold: 2px;

.tag {
    display: inline-block;
    background-color: $color-mediumGrey;
    color: $color-darkGrey;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 14px;
    box-decoration-break: clone;

    strong {
        font-weight: $font-weight-bold;
        white-space: nowrap;
    }
}

.tag--red {
    background-color: $color-red;
    color: $color-white;
}

.tag--white {
    background-color: $color-white;
    color: $color-darkGrey;
}

.tag--yellow {
    background-color: $color-yellow;
    color: $color-white;
}

.tag--green {
    background-color: $color-green;
    color: $color-white;
}

.tag--black {
    background-color: #000;
    color: $color-white;
}

.tag--small {
    font-size: 12px;
    line-height: 14px;
}

.tag--tiny {
    font-size: 10px;
    line-height: 11px;
    padding: 2px 5px;
}

.tag--bold {
    font-weight: $font-weight-bold;

    &.tag--outlined {
        border: $border-width-outlined-bold solid;
    }
}

.tag--unbreakable {
    white-space: nowrap;
}

.tag--outlined {
    border: $border-width-outlined solid;
    background-color: transparent;
    box-sizing: border-box;

    &.tag--small {
        padding: 2px 8px;
    }
    &.tag--tiny {
        padding: 0px 1px;
    }

    &.tag--red {
        color: $color-red;
        border-color: $color-red;
    }

    &.tag--white {
        color: $color-white;
        border-color: $color-white;
    }

    &.tag--yellow {
        color: $color-yellow;
        border-color: $color-yellow;
    }

    &.tag--green {
        color: $color-green;
        border-color: $color-green;
    }

    &.tag--black {
        color: #000;
        border-color: #000;
    }
}
</style>

<template>
    <div class="flex flex-wrap gap-12">
        <div class="truncate">
            <span class="font-copy-sm text-subdued">{{ $t('components.transportListBlock.productLabel') }}</span>
            <span class="relative top-[-3px] mt-2 flex w-full items-center truncate">
                <SfMaterialIcon class="mr-2 shrink-0" size="xs" />
                <TransportListBlockFreightDescription :transport="transport" highlight />
            </span>
        </div>
        <div v-if="transport.totalLoading">
            <span class="font-copy-sm text-subdued">{{ $t('components.transportListBlock.loadedLabel') }}</span>
            <span class="font-copy-md-strong mt-2">{{ transport.totalLoading | tons }}</span>
        </div>
    </div>
</template>

<script>
import { revertLocalizedValue } from '@/services/utils/localization';
import { SfMaterialIcon } from '@schuettflix/vue-components';

import TransportListBlockFreightDescription from '../TransportListBlock/TransportListBlockFreightDescription.vue';

import { TRANSPORT_TYPE } from '@/constants/transportTypes';

/**
 * !!! ATTENTION !!!
 * This component and all of it's children can not have computed
 * properties since they introduce a performance issues on unmounting
 */
export default {
    name: 'TransportListBlockSupplierProductInfo',
    components: {
        TransportListBlockFreightDescription,
        SfMaterialIcon,
    },
    props: {
        transport: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            TRANSPORT_TYPE: TRANSPORT_TYPE,
        };
    },
    methods: {
        revertLocalizedValue,
    },
};
</script>

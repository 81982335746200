<template>
    <div class="mandate-reference span-4" :class="{ 'mandate-reference--disabled': !getMandateReferenceUrl() }">
        <Words block bold size-normal spaced-bottom-medium>
            {{ $t('components.organizationForm.paymentInfo.mandateReference') }}
        </Words>
        <Words block size-normal spaced-bottom-medium>
            {{ mandateReference.name }}
        </Words>
        <Words block muted small class="mandate-reference__info-color">
            {{ getMandateReferenceAcceptedAtInfo() }}
        </Words>
        <Card spaceless-x class="mandate-reference__document span-4" shadowless>
            <Words block bold size-normal spaced-bottom-medium>
                {{ $t('components.organizationForm.paymentInfo.debit') }}
            </Words>
            <Words block size-normal>
                {{ $t('components.organizationForm.paymentInfo.debitHint') }}
            </Words>
            <Button
                v-if="mandateReference.document.url"
                :href="getMandateReferenceUrl()"
                class="mandate-reference__document-download"
            >
                <Words class="mandate-reference__document-download-content">
                    <PdfIcon />
                    <Words class="mandate-reference__document-download-content-text">
                        {{ $t('components.organizationForm.paymentInfo.pdfDownloadHint') }}
                        <span>›</span>
                    </Words>
                </Words>
            </Button>
        </Card>
    </div>
</template>

<script>
import { format } from 'date-fns';
import Words from '@/components/Typography/Words';
import Card from '@/components/Layout/Card';
import Button from '@/components/Button/Button';

import PdfIcon from '@/assets/icons/regular/pdf_2022_v1.svg';

export default {
    name: 'MandateReference',

    components: {
        Words,
        Card,
        Button,

        PdfIcon,
    },

    props: {
        mandateReference: {
            type: Object,
            default: null,
        },
        organization: {
            type: Object,
            default: null,
        },
    },
    methods: {
        getMandateReferenceAcceptedAtInfo() {
            const info = {
                acceptedBy: this.mandateReference.acceptedByUserName,
                date: format(new Date(this.mandateReference.acceptedAt), 'DD.MM.YYYY'),
                time: format(new Date(this.mandateReference.acceptedAt), 'H:mm'),
            };

            return this.$t('components.organizationForm.paymentInfo.mandateReferenceTime', info);
        },
        getMandateReferenceUrl() {
            return !this.$can('updateOrganization', this.organization) ? null : this.mandateReference.document.url;
        },
    },
};
</script>

<style lang="scss" scoped>
.organization-form__fieldset {
    & + & {
        margin-top: 60px;
    }

    .mandate-reference {
        margin-top: 44px;

        &__info-color {
            color: $color-lightDarkGrey;
        }

        &__document {
            background: $color-mediumGrey;
            height: 164px;

            &-download {
                display: flex;
                align-items: center;
                margin-top: 16px;

                &-content {
                    display: flex;
                    align-items: center;

                    &-text {
                        margin-left: 16px;
                    }
                }
            }
        }

        &--disabled {
            color: $color-dark-grey-200;

            svg {
                opacity: 0.5;
            }
        }
    }
}
</style>

import ProductSearch from '@/services/Api/ProductSearch';
import ProductSelectionResult from '@/services/Api/ProductSelectionResult';
import ProductApi from '@/services/Api/Product';
import SellerApi from '@/services/Api/Seller';
export const checkoutKeys = {
    namespace: ['checkout'],
    productSearch: (orderType, locationId, query, isRecycled) => [...checkoutKeys.namespace, ['product-search'], orderType, locationId, query, isRecycled],
    productSearchOld: (orderType, locationId, query) => [...checkoutKeys.namespace, ['product-search'], orderType, locationId, query],
    categories: (orderType, locationId) => [...checkoutKeys.namespace, ['categories'], orderType, locationId],
    recentProducts: (locationId, orderType) => [...checkoutKeys.namespace, ['recent-products'], locationId, orderType],
    supplierProfile: (factoryId, locationId) => [...checkoutKeys.namespace, ['supplier-profile'], factoryId, locationId],
    masterProductsByCategory: (categoryId, orderType, locationId, query) => [...checkoutKeys.namespace, ['master-products-by-category'], categoryId, orderType, locationId, query],
    orderRestrictions: (orderType) => [...checkoutKeys.namespace, ['order-restrictions', orderType]],
    productByAttributes: (locationId, masterProductId, orderType, attributeRange, attributes) => [
        ...checkoutKeys.namespace,
        ['product-by-attribute', locationId, masterProductId, orderType, attributeRange, attributes],
    ],
    attributeOptionsAvailable: (locationId, masterProductId, orderType, attributeRange, attributes, attributeSetId) => [
        ...checkoutKeys.namespace,
        [
            'product-by-attribute',
            locationId,
            masterProductId,
            orderType,
            attributeRange,
            attributes,
            attributeSetId,
        ],
    ],
    sellersByProductAttributes: (locationId, masterProductId, orderType, attributeRange, attributes, options) => [
        ...checkoutKeys.namespace,
        [
            'seller-by-product-attribute',
            locationId,
            masterProductId,
            orderType,
            attributeRange,
            attributes,
            options,
        ],
    ],
    deliverySellers: (locationId, masterProductId, payload) => [...checkoutKeys.namespace, ['delivery-seller', locationId, masterProductId, payload]],
};
export const recentlyOrderedProducts = (locationId, orderType) => {
    return {
        queryKey: checkoutKeys.recentProducts(locationId, orderType),
        queryFn: () => ProductSelectionResult.getRecentProducts(locationId, orderType),
    };
};
export const getOrderRestrictions = (orderType) => {
    return {
        queryKey: checkoutKeys.orderRestrictions(orderType),
        queryFn: () => ProductApi.getOrderRestrictions(orderType),
    };
};
export const getProductByAttributes = (orderType, locationId, masterProductId, attributeRange, attributes) => {
    return {
        queryKey: checkoutKeys.productByAttributes(orderType, locationId, masterProductId, attributeRange, attributes),
        queryFn: () => ProductApi.getProductByAttributes(orderType, locationId, masterProductId, attributeRange, attributes),
    };
};
export const getAttributeOptionsAvailable = (orderType, locationId, masterProductId, attributeRange, attributes, attributeSetId) => {
    return {
        queryKey: checkoutKeys.attributeOptionsAvailable(orderType, locationId, masterProductId, attributeRange, attributes, attributeSetId),
        queryFn: () => ProductApi.getAttributeOptionsAvailable(orderType, locationId, masterProductId, attributeRange, attributes, attributeSetId),
    };
};
export const getSellersByProductAttributes = (orderType, locationId, masterProductId, attributeRange, attributes, options) => {
    return {
        queryKey: checkoutKeys.sellersByProductAttributes(orderType, locationId, masterProductId, attributeRange, attributes, options),
        queryFn: () => ProductApi.getSellersByProductAttributes(orderType, locationId, masterProductId, attributeRange, attributes, options),
    };
};
export const getDeliverySellers = (locationId, masterProductId, payload) => {
    return {
        queryKey: checkoutKeys.deliverySellers(locationId, masterProductId, payload),
        queryFn: () => ProductApi.getDeliverySellers(locationId, masterProductId, payload),
    };
};
export const searchProducts = (orderType, locationId, query, isRecycled) => {
    return {
        queryKey: checkoutKeys.productSearch(orderType, locationId, query, isRecycled),
        queryFn: () => ProductSearch.getProducts(orderType, locationId, query, isRecycled),
    };
};
export const searchProductsOld = (orderType, locationId, query) => {
    return {
        queryKey: checkoutKeys.productSearchOld(orderType, locationId, query),
        queryFn: () => ProductSearch.getProducts(orderType, locationId, query.value),
    };
};
export const supplierProfile = (factoryId, locationId) => {
    return {
        queryKey: checkoutKeys.supplierProfile(factoryId, locationId),
        queryFn: () => SellerApi.getSellerProfile(factoryId, locationId),
    };
};
export const getMasterProductsByCategory = (categoryId, orderType, locationId, query = '') => {
    return {
        queryKey: checkoutKeys.masterProductsByCategory(categoryId, orderType, locationId, query),
        queryFn: () => ProductSearch.getMasterProductsByCategory(categoryId, orderType, locationId, query),
    };
};
export const categoriesList = (orderType, locationId) => {
    return {
        queryKey: checkoutKeys.categories(orderType, locationId),
        queryFn: () => ProductApi.getCategories(orderType, locationId),
    };
};

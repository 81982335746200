import { FACTORY_TYPES, LANDFILL_CODE_TO_NAME_MAPPING, USAGES } from '@/constants/disposal';
import { PROJECT_POSITION_TYPE_WASTE, PROJECT_POSITION_TYPE_DANGEROUS_WASTE } from '@/constants/projectPositionTypes';
import { TRANSPORT_TYPE } from '@/constants/transportTypes';

export const isDisposalFacility = usages => usages.includes(USAGES.DISPOSAL);

export const isMaterialFacility = usages => usages.includes(USAGES.SELLING);

export const isLandfillFacility = (usages, factoryType) =>
    usages.includes(USAGES.DISPOSAL) && factoryType === FACTORY_TYPES.LANDFILL;

export const isPlantFacility = (usages, factoryType) =>
    usages.includes(USAGES.DISPOSAL) && factoryType === FACTORY_TYPES.PLANT;

export const isDumpingSiteFacility = (usages, factoryType) =>
    usages.includes(USAGES.DISPOSAL) && factoryType === FACTORY_TYPES.DUMPING_SITE;

export const isDisposerNumberFormat = number => {
    const regex = /^[A-Z](\/?[A-Z0-9]{8})*\/?$/;
    return regex.test(number);
};

export const getLandfillClassName = (landfillClass, inBrackets = true) => {
    return inBrackets
        ? `(${LANDFILL_CODE_TO_NAME_MAPPING[landfillClass]})`
        : LANDFILL_CODE_TO_NAME_MAPPING[landfillClass];
};
export const isDisposalTransport = transport => transport?.type === TRANSPORT_TYPE.DISPOSAL;

export const isProjectPositionTypeDisposal = type => {
    return [PROJECT_POSITION_TYPE_WASTE, PROJECT_POSITION_TYPE_DANGEROUS_WASTE].includes(type);
};

import ImageView from '@/models/ImageView';
import { revertLocalizedValue } from '@/services/utils/localization';

export default class ProductView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     * @param {object} disposalProductVariant
     * @return {ProductView|null}
     */
    static create(data, disposalProductVariant = null) {
        if (!data) return null;

        const product = new ProductView();

        product.id = data.id;
        product.density = data.density;
        product.isRC = data.isRC;
        product.productMargin = data.productMargin;
        product.productMarginType = data.productMarginType;
        product.productName = data.productName;
        product.sku = data.sku;
        product.type = data.type;
        product.image = ImageView.create(data.image);
        product.variantDelivery = ProductVariantView.create(data.variantDelivery);
        product.variantPickup = ProductVariantView.create(data.variantPickup);
        product.variantDisposal = disposalProductVariant ? ProductVariantView.create(disposalProductVariant) : null;
        product.wasteCode = data?.wasteCode;
        product.isHazardousWaste = data?.isDangerous;

        return product;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._id = null;
        this._density = null;
        this._image = null;
        this._isRC = null;
        this._productMargin = null;
        this._productMarginType = null;
        this._productName = null;
        this._sku = null;
        this._type = null;
        this._variantDelivery = null;
        this._variantPickup = null;
        this._variantDisposal = null;
        this._wasteCode = null;
        this._pollutionType = null;
        this._isHazardousWaste = null;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            id: this.id,
            density: this.density,
            image: this.image?.unfold(),
            isRC: this.isRC,
            productMargin: this.productMargin,
            productMarginType: this.productMarginType,
            productName: this.productName,
            sku: this.sku,
            type: this.type,
            variantDelivery: this.variantDelivery,
            variantPickup: this.variantPickup,
        };
    }

    /**
     * Clone the current view
     * @return {ProductView}
     */
    clone() {
        return ProductView.create(this.unfold());
    }

    /**
     * Get id
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * Set id
     * @param {number} value
     */
    set id(value) {
        this._id = value;
    }

    /**
     * Get density
     * @returns {number}
     */
    get density() {
        return this._density;
    }

    /**
     * Set density
     * @param {number} value
     */
    set density(value) {
        this._density = value;
    }

    /**
     * Get image
     * @returns {ImageView}
     */
    get image() {
        return this._image;
    }

    /**
     * Set image
     * @param {ImageView} value
     */
    set image(value) {
        this._image = value;
    }

    /**
     * Is recycling material?
     * @returns {boolean}
     */
    get isRC() {
        return this._isRC;
    }

    /**
     * Set is recycling material?
     * @param {boolean} value
     */
    set isRC(value) {
        this._isRC = value;
    }

    /**
     * Get product margin
     * @returns {number}
     */
    get productMargin() {
        return this._productMargin;
    }

    /**
     * Set product margin
     * @param {number} value
     */
    set productMargin(value) {
        this._productMargin = value;
    }

    /**
     * Get product margin type
     * @returns {string}
     */
    get productMarginType() {
        return this._productMarginType;
    }

    /**
     * Set product margin type
     * @param {string} value
     */
    set productMarginType(value) {
        this._productMarginType = value;
    }

    /**
     * Get localized product name structure
     * @returns {object}
     */
    get productName() {
        return this._productName;
    }

    /**
     * Set localized product name structure
     * @param {object|null} value
     */
    set productName(value) {
        this._productName = value;
    }

    /**
     * Get sku
     * @returns {string}
     */
    get sku() {
        return this._sku;
    }

    /**
     * Set sku
     * @param {string} value
     */
    set sku(value) {
        this._sku = value;
    }

    /**
     * Get type
     * @returns {string}
     */
    get type() {
        return this._type;
    }

    /**
     * Set type
     * @param {string} value
     */
    set type(value) {
        this._type = value;
    }

    /**
     * Get variant pickup
     * @return {ProductVariantView|null}
     */
    get variantPickup() {
        return this._variantPickup;
    }

    /**
     * Set variant pickup
     * @param {ProductVariantView} value
     */
    set variantPickup(value) {
        this._variantPickup = value ?? null;
    }

    /**
     * Get variant delivery
     * @return {ProductVariantView|null}
     */
    get variantDelivery() {
        return this._variantDelivery;
    }

    /**
     * Set variant delivery
     * @param {ProductVariantView} value
     */
    set variantDelivery(value) {
        this._variantDelivery = value ?? null;
    }

    /**
     * Get variant disposal
     * @return {ProductVariantView|null}
     */
    get variantDisposal() {
        return this._variantDisposal;
    }

    /**
     * Set variant disposal
     * @param {ProductVariantView} value
     */
    set variantDisposal(value) {
        this._variantDisposal = value ?? null;
    }

    /**
     * Get localized product name
     * @returns {string}
     */
    get productNameLocalized() {
        return revertLocalizedValue(this.productName);
    }

    /**
     * Get wasteCode
     * @return {string|null}
     */
    get wasteCode() {
        return this._wasteCode;
    }

    /**
     * Set wasteCode
     * @param {string} value
     */
    set wasteCode(value) {
        this._wasteCode = value ?? null;
    }

    /**
     * Get pollutionType
     * @return {string|null}
     */
    get pollutionType() {
        return this._pollutionType;
    }

    /**
     * Set pollutionType
     * @param {string} value
     */
    set pollutionType(value) {
        this._pollutionType = value ?? null;
    }

    /**
     * Get isHazardousWaste
     * @return {boolean|null}
     */
    get isHazardousWaste() {
        return this._isHazardousWaste;
    }

    /**
     * Set isHazardousWaste
     * @param {boolean} value
     */
    set isHazardousWaste(value) {
        this._isHazardousWaste = value ?? null;
    }
}

export class ProductVariantView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     * @return {ProductVariantView|null}
     */
    static create(data) {
        if (!data) return null;

        const view = new ProductVariantView();

        view.isActive = data.isActive;
        view.maximumQty = data.maximumQty;
        view.minimumQty = data.minimumQty;
        view.pollutionType = data?.pollutionType;

        return view;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._isActive = null;
        this._maximumQty = null;
        this._minimumQty = null;
        this._pollutionType = null;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            isActive: this.isActive,
            maximumQty: this.maximumQty,
            minimumQty: this.minimumQty,
        };
    }

    /**
     * Clone the current view
     * @return {ProductVariantView}
     */
    clone() {
        return ProductVariantView.create(this.unfold());
    }

    /**
     * Get active state
     * @return {boolean|null}
     */
    get isActive() {
        return this._isActive;
    }

    /**
     * Set active state
     * @param {boolean} value
     */
    set isActive(value) {
        this._isActive = value ?? null;
    }

    /**
     * Get maximum quantity
     * @return {number|null}
     */
    get maximumQty() {
        return this._maximumQty;
    }

    /**
     * Set maximum quantity
     * @param {number} value
     */
    set maximumQty(value) {
        this._maximumQty = value ?? null;
    }

    /**
     * Get minimum quantity
     * @return {number|null}
     */
    get minimumQty() {
        return this._minimumQty;
    }

    /**
     * Set minimum quantity
     * @param {number} value
     */
    set minimumQty(value) {
        this._minimumQty = value ?? null;
    }

    /**
     * Get pollution type
     * @return {Object|null}
     */
    get pollutionType() {
        return this._pollutionType;
    }

    /**
     * Set minimum quantity
     * @param {string} value
     */
    set pollutionType(value) {
        this._pollutionType = value ?? null;
    }
}

const sizeMap = {
    xxs: 12,
    xs: 16,
    sm: 24,
    md: 32,
    lg: 48,
    xl: 64,
    xxl: 96,
};
export function useIconSize(size) {
    if (size === undefined) {
        return '1em';
    }
    return sizeMap[size];
}

import React, { useMemo, useState } from 'react';
import { Header } from '@/constructionProjects/components/Header';
import { useTranslation } from 'react-i18next';
import { useRouteQuery } from '@/reactBridge/useRouteQuery';
import { useAbility } from '@/reactBridge/useAbility';
import { ConstructionProjectStatus, updateFavoriteStatus, } from '@/constructionProjects/api/projects';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { constructionProjectKeys, constructionProjectListQuery } from '@/constructionProjects/queries';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';
import { Button, Tag, TextField, AvatarList } from '@schuettflix/react-components';
import { CONSTRUCTION_PROJECT_CREATE_ROUTE, CONSTRUCTION_PROJECT_DETAILS_ROUTE, CONSTRUCTION_PROJECT_LIST_ROUTE, DAYS_LEFT_NOTIFICATION_THRESHOLD, } from '@/constructionProjects/constants';
import { StarFilledIcon } from '@/constructionProjects/components/icons/StarFilledIcon';
import { StarIcon } from '@/constructionProjects/components/icons/StarIcon';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { SearchIcon } from '@/constructionProjects/components/icons/SearchIcon';
import Fuse from 'fuse.js';
import { CancelIllustration } from '@/constructionProjects/components/icons/CancelIllustration';
import { ConstructionProjectsIcon } from '@/constructionProjects/components/icons/ConstructionProjectsIcon';
import { formatDateRange } from '@/services/utils/date';
import { MapMarkerIcon } from '@/constructionProjects/components/icons/MapMarkerIcon';
import { formatAddress } from '@/constructionProjects/utils/formatAddress';
import { FavoritableHint } from '@/constructionProjects/components/FavoritableHint';
import { calculateValidityInDays } from '../utils/validity';
const EmptyConstructionProjectsList = ({ description }) => {
    return (<div className="mb-4 rounded border border-dashed border-disabled p-6 text-center">
            <ConstructionProjectsIcon className="inline-block"/>
            <p className="font-copy-md mt-4 text-disabled">{description}</p>
        </div>);
};
const EmptySearchResults = () => {
    const { t } = useTranslation();
    return (<div className="flex flex-col items-center justify-center px-4 text-center">
            <CancelIllustration />
            <p className="font-headline-md-strong mt-4">{t('pages.constructionProject.search.empty.title')}</p>
            <p className="font-copy-md mt-2">{t('pages.constructionProject.search.empty.description')}</p>
        </div>);
};
const ProjectCardsDesktop = ({ favoriteItems, nonFavoriteItems, showFavoritableHint = false, }) => {
    const { vueRouter } = useVueRouter();
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const mutation = useMutation(updateFavoriteStatus, {
        onMutate: ({ constructionProjectId, isFavorite }) => {
            const previousResponse = queryClient.getQueryData(constructionProjectKeys.list());
            if (previousResponse) {
                queryClient.setQueryData(constructionProjectKeys.list(), previousProjects => {
                    if (!previousProjects) {
                        return previousProjects;
                    }
                    return {
                        ...previousProjects,
                        constructionProjects: previousProjects.constructionProjects.map(project => project.id === constructionProjectId ? { ...project, isFavorite } : project),
                    };
                });
            }
            return { previousResponse };
        },
        onError: (_error, _variables, context) => {
            queryClient.setQueryData(constructionProjectKeys.list(), context?.previousResponse);
        },
    });
    const handleCardClick = (constructionProjectId) => {
        vueRouter.push({ name: CONSTRUCTION_PROJECT_DETAILS_ROUTE, params: { id: constructionProjectId } });
    };
    const handleUpdateFavoriteStatus = (e, constructionProjectId, isFavorite) => {
        e.stopPropagation();
        mutation.mutate({ constructionProjectId, isFavorite });
        AnalyticsService.trackEvent('construction_project', {
            step: isFavorite ? 'construction_project_favorite' : 'construction_project_unfavorite',
            constructionProjectId,
        });
    };
    const renderCard = (item) => {
        const formatedAddress = formatAddress({
            city: item.city,
            street: item.street,
            zipCode: item.zip,
            streetNumber: item.addressNumber,
            country: item.country,
        });
        const validityRange = formatDateRange(new Date(item.validFrom).getTime(), new Date(item.validTo).getTime());
        const daysLeft = calculateValidityInDays(item.validTo);
        const showWarning = daysLeft > 0 && daysLeft <= DAYS_LEFT_NOTIFICATION_THRESHOLD;
        return (<div className="mb-[1px] grid cursor-pointer grid-cols-[3fr_repeat(2,_minmax(0,_2fr))_1fr] items-center gap-4 bg-surface p-6 py-4 pl-0 first:rounded-t last:rounded-b hover:bg-surface-hovered" data-test="construction-project-card" key={item.id} onClick={() => handleCardClick(item.id)}>
                <div className="flex items-center overflow-hidden">
                    <div className="mr-4 h-[40px] w-4 flex-shrink-0 rounded-r" style={{ backgroundColor: item.color }}/>
                    <p className="font-copy-md-strong truncate" title={item.name}>
                        {item.name}
                    </p>
                </div>
                <div className="flex items-center gap-2" title={formatedAddress}>
                    <MapMarkerIcon className="flex-shrink-0"/>
                    {`${item.zip} ${item.city}`}
                </div>
                <div className="flex items-center gap-2">
                    <span>{validityRange}</span>
                    {showWarning && (<Tag type="warning" label={t('pages.constructionProject.projectCard.expirationWarning', {
                    count: daysLeft,
                })}/>)}
                </div>
                <div className="flex justify-between">
                    <AvatarList users={[item.responsibleUser, ...(item.teamMembers ?? [])]} size="sm" className="items-center"/>
                    <button className="grid h-[40px] w-[40px] place-content-center rounded-full bg-transparent hover:bg-surface-secondary-hovered" title={!item.isFavorite
                ? t('pages.constructionProject.desktopList.item.unfiiledIcon.title')
                : t('pages.constructionProject.desktopList.item.fiiledIcon.title')} onClick={event => handleUpdateFavoriteStatus(event, item.id, !item.isFavorite)}>
                        {item.isFavorite ? (<StarFilledIcon className="fill-icon-accent" size={24}/>) : (<StarIcon className="stroke-icon" size={24}/>)}
                    </button>
                </div>
            </div>);
    };
    if (!favoriteItems.length && !nonFavoriteItems.length) {
        return (<div className="flex flex-col items-center justify-center px-4 text-center">
                <CancelIllustration />
                <p className="font-headline-md-strong mt-4">{t('pages.constructionProject.search.empty.title')}</p>
                <p className="font-copy-md mt-2">{t('pages.constructionProject.search.empty.description')}</p>
            </div>);
    }
    return (<>
            {showFavoritableHint && <FavoritableHint className="mb-12"/>}
            {favoriteItems.length > 0 ? (<div className="mb-12 rounded shadow-low">{favoriteItems.map(renderCard)}</div>) : null}
            {nonFavoriteItems.length > 0 ? (<div className="rounded shadow-low">{nonFavoriteItems.map(renderCard)}</div>) : null}
        </>);
};
export const ConstructionProjectListDesktop = () => {
    const { vueRouter } = useVueRouter();
    const { activeTab } = useRouteQuery();
    const { t } = useTranslation();
    const canManageConstructionProject = useAbility('createConstructionProject');
    const [searchTerm, setSearchTerm] = useState('');
    const constructionProjectsQuery = useQuery({
        ...constructionProjectListQuery,
        staleTime: Infinity,
        suspense: true,
    });
    const [favoritedProjectIds] = useState(constructionProjectsQuery.data
        ? new Set(constructionProjectsQuery.data.constructionProjects.filter(p => p.isFavorite).map(p => p.id))
        : new Set());
    const fuse = useMemo(() => {
        const searchData = constructionProjectsQuery.data?.constructionProjects.map(project => ({
            original: project,
            name: project.name,
            description: project.description,
            costCenter: project.costCenter,
            address: [project.street, project.addressNumber, project.zip, project.city, project.state]
                .filter(Boolean)
                .join(' '),
            responsibleUserFullName: `${project.responsibleUser.firstName} ${project.responsibleUser.lastName}`,
            teamMemberFullNames: project.teamMembers?.map(member => `${member.firstName} ${member.lastName}`),
        }));
        return new Fuse(searchData ?? [], {
            keys: [
                { name: 'name', weight: 1 },
                { name: 'description', weight: 0.7 },
                { name: 'address', weight: 0.5 },
                { name: 'costCenter', weight: 0.4 },
                { name: 'responsibleUserFullName', weight: 0.3 },
                { name: 'teamMemberFullNames', weight: 0.3 },
            ],
            ignoreLocation: true,
            useExtendedSearch: true,
            threshold: 0.3,
        });
    }, [constructionProjectsQuery.data?.constructionProjects]);
    if (!constructionProjectsQuery.data) {
        return null;
    }
    const filteredProjectList = (searchTerm) => {
        if (searchTerm.trim().length === 0) {
            return constructionProjectsQuery.data.constructionProjects;
        }
        else {
            return fuse.search(searchTerm).map(result => result.item.original);
        }
    };
    const TABS = [
        {
            id: ConstructionProjectStatus.Active,
            name: t('pages.constructionProject.activeTab.headline'),
            component: () => {
                const projects = filteredProjectList(searchTerm).filter(p => p.status === ConstructionProjectStatus.Active);
                if (!constructionProjectsQuery.data?.constructionProjects.some(p => p.status === ConstructionProjectStatus.Active)) {
                    return (<EmptyConstructionProjectsList description={t('pages.constructionProject.activeProjects.emptyState.description')}/>);
                }
                if (searchTerm.trim().length !== 0 && projects.length === 0) {
                    return <EmptySearchResults />;
                }
                return (<ProjectCardsDesktop favoriteItems={projects.filter(p => favoritedProjectIds.has(p.id))} nonFavoriteItems={projects.filter(p => !favoritedProjectIds.has(p.id))} showFavoritableHint={projects.filter(p => favoritedProjectIds.has(p.id)).length === 0}/>);
            },
            count: constructionProjectsQuery.data.constructionProjects.filter(p => p.status === ConstructionProjectStatus.Active).length,
        },
        {
            id: ConstructionProjectStatus.Inactive,
            name: t('pages.constructionProject.inactiveTab.headline'),
            component: () => {
                const projects = filteredProjectList(searchTerm).filter(p => p.status === ConstructionProjectStatus.Inactive);
                if (!constructionProjectsQuery.data?.constructionProjects.some(p => p.status === ConstructionProjectStatus.Inactive)) {
                    return (<EmptyConstructionProjectsList description={t('pages.constructionProject.inactiveProjects.emptyState.description')}/>);
                }
                if (searchTerm.trim().length !== 0 && projects.length === 0) {
                    return <EmptySearchResults />;
                }
                return (<ProjectCardsDesktop favoriteItems={projects.filter(p => favoritedProjectIds.has(p.id))} nonFavoriteItems={projects.filter(p => !favoritedProjectIds.has(p.id))}/>);
            },
            count: constructionProjectsQuery.data.constructionProjects.filter(p => p.status === ConstructionProjectStatus.Inactive).length,
        },
        {
            id: ConstructionProjectStatus.Archived,
            name: t('pages.constructionProject.archivedTab.headline'),
            component: () => {
                const projects = filteredProjectList(searchTerm).filter(p => p.status === ConstructionProjectStatus.Archived);
                if (!constructionProjectsQuery.data?.constructionProjects.some(p => p.status === ConstructionProjectStatus.Archived)) {
                    return (<EmptyConstructionProjectsList description={t('pages.constructionProject.archivedProjects.emptyState.description')}/>);
                }
                if (searchTerm.trim().length !== 0 && projects.length === 0) {
                    return <EmptySearchResults />;
                }
                return (<ProjectCardsDesktop favoriteItems={projects.filter(p => favoritedProjectIds.has(p.id))} nonFavoriteItems={projects.filter(p => !favoritedProjectIds.has(p.id))}/>);
            },
            count: null,
        },
    ];
    const activeTabIndex = TABS.findIndex(item => item.id === activeTab);
    const selectedIndex = activeTabIndex > -1 ? activeTabIndex : 0;
    return (<div className="flex h-full flex-col overflow-auto bg">
            <Header desktopTitle={t('pages.constructionProject.projects.headline')} mobileTitle={t('pages.constructionProject.projects.headline')}/>

            <Tab.Group selectedIndex={selectedIndex} onChange={index => {
            vueRouter.push({
                path: vueRouter.currentRoute.path,
                query: { activeTab: TABS[index].id },
            });
        }}>
                <Tab.List className="m-6 mb-0 flex h-12 pl-1">
                    {TABS.map(tab => (<Tab key={tab.id} className={({ selected }) => clsx('font-copy-sm box-border h-full p-4 outline-none', selected && 'font-copy-sm-strong rounded-t bg-surface')}>
                            {tab.name} {tab.count !== null ? `(${tab.count})` : null}
                        </Tab>))}
                </Tab.List>
                <div className="mx-6 bg-surface p-6">
                    <TextField label={t('pages.constructionProject.search.placeholder')} variant="sm" leadingIcon={props => <SearchIcon {...props}/>} value={searchTerm} onChange={event => setSearchTerm(event.target.value)}/>
                </div>

                <div className="flex-1 bg px-6 py-6">
                    {canManageConstructionProject && (<Button size="sm" className="mb-12 ml-auto hidden rounded lg:flex" label={t('pages.constructionProject.desktopList.createButton')} data-test="construction-project-list-create-button" onClick={() => vueRouter.push({
                name: `${CONSTRUCTION_PROJECT_LIST_ROUTE}__${CONSTRUCTION_PROJECT_CREATE_ROUTE}`,
            })}/>)}
                    <Tab.Panels>
                        {TABS.map(({ id, component: Component }) => (<Tab.Panel key={id}>
                                <Component />
                            </Tab.Panel>))}
                    </Tab.Panels>
                </div>
            </Tab.Group>
        </div>);
};

<template>
    <LayoutPage
        class="bg"
        screen-name="client-order-list"
        :parent-route="parentRoute"
        :back-title="parentRoute?.backTitle"
    >
        <template #pageTitle>{{ $t('pages.order.orderList.title') }}</template>

        <template #mobileHeader>
            <HeaderBar>
                <template v-if="parentRoute" #left>
                    <HeaderBarItem button @click="goToParent()">
                        <ArrowIcon width="32" height="18" />
                    </HeaderBarItem>
                </template>

                <template #headline>
                    <div>{{ $t('pages.order.orderList.title') }}</div>
                </template>

                <template v-if="!$root.isDesktop" #right>
                    <HeaderBarItem>
                        <FilterBox
                            v-if="activeTab === 'requests'"
                            v-model="quoteFilter"
                            screen-name="client-order-filter"
                            :default-filter="defaultQuoteFilter"
                            :endpoint="quoteEndpoint"
                            :headline="$t('pages.order.filter.ordersFilterHeadline')"
                            :ignore-in-count="['organization', 'user', 'clientOrganization', 'client']"
                            @save="refreshQuoteList()"
                        >
                            <template #default="{ filter: filterFromSlot, updateFilter }">
                                <QuoteFilterSet
                                    :filter-scope="{ filter: filterFromSlot }"
                                    :endpoint="quoteEndpoint"
                                    @updateFilter="updateFilter"
                                />
                            </template>
                        </FilterBox>

                        <FilterBox
                            v-else
                            v-model="orderFilter"
                            screen-name="client-order-filter"
                            :default-filter="defaultOrderFilter"
                            :endpoint="orderEndpoint"
                            :headline="$t('pages.order.filter.ordersFilterHeadline')"
                            :ignore-in-count="['organization', 'user', 'status']"
                            @save="refreshOrderList()"
                        >
                            <template #default="{ filter: filterFromSlot, updateFilter }">
                                <OrderFilterSet
                                    :filter-scope="{ filter: filterFromSlot }"
                                    :endpoint="orderEndpoint"
                                    @updateFilter="updateFilter"
                                />
                            </template>
                        </FilterBox>
                    </HeaderBarItem>
                </template>
            </HeaderBar>
        </template>

        <RoundedTabNavigation :value="activeTab" :tabs="orderViewTabs" @input="updateTabNavigation" />
        <Card spaceless>
            <div class="grid grid-cols-[1fr] items-center gap-x-[10px]">
                <FilterSortPagination
                    v-if="items"
                    :result="items"
                    hide-sort
                    show-refresh
                    spaceless
                    :loading="isLoading"
                    @refresh="refreshOrderList()"
                    @pageNumberUpdated="pageNumberUpdated"
                >
                    <template v-if="$root.isDesktop">
                        <FilterBox
                            v-if="activeTab === 'requests'"
                            v-model="quoteFilter"
                            screen-name="client-order-filter"
                            :default-filter="defaultQuoteFilter"
                            :endpoint="quoteEndpoint"
                            :headline="$t('pages.order.filter.ordersFilterHeadline')"
                            :ignore-in-count="['organization', 'user', 'clientOrganization', 'client']"
                            :button-label="$t('pages.order.orderList.filter')"
                            @save="refreshQuoteList()"
                        >
                            <template #default="{ filter: filterFromSlot, updateFilter }">
                                <QuoteFilterSet
                                    :filter-scope="{ filter: filterFromSlot }"
                                    :endpoint="quoteEndpoint"
                                    @updateFilter="updateFilter"
                                />
                            </template>
                        </FilterBox>
                        <FilterBox
                            v-else
                            v-model="orderFilter"
                            screen-name="client-order-filter"
                            :default-filter="defaultOrderFilter"
                            :endpoint="orderEndpoint"
                            :headline="$t('pages.order.filter.ordersFilterHeadline')"
                            :ignore-in-count="['organization', 'user', 'status']"
                            :button-label="$t('pages.order.orderList.filter')"
                            @save="refreshOrderList()"
                        >
                            <template #default="{ filter: filterFromSlot, updateFilter }">
                                <OrderFilterSet
                                    :filter-scope="{ filter: filterFromSlot }"
                                    :endpoint="orderEndpoint"
                                    @updateFilter="updateFilter"
                                />
                            </template>
                        </FilterBox>
                    </template>
                </FilterSortPagination>
            </div>
        </Card>

        <transition name="fade" mode="out-in">
            <div v-if="items && items.count > 0">
                <Card
                    v-for="item in items.items"
                    :key="item.id"
                    :spaceless-x="$root.isDesktop"
                    data-test="transport-list-block"
                    class="my-4"
                >
                    <OrderItemBlock :order-view="item" @click="selectItem(item)" />
                </Card>

                <Card spaceless>
                    <Pagination :result="orders" align-right @pageNumberUpdated="pageNumberUpdated" />
                </Card>
            </div>

            <Hint v-if="items && items.count === 0" center>
                {{ $t(`pages.order.orderList.${activeTab === 'request' ? 'quotes' : 'orders'}.noResults`) }}
            </Hint>
        </transition>

        <template #subpages>
            <div>
                <Flyout route="order-view" size="small" no-header screen-name="client-order-detail" />
                <Flyout route="order-quote-view" size="small" no-header />
            </div>
        </template>
    </LayoutPage>
</template>

<script>
import { useRoute } from 'vue-router/composables';
import _get from 'lodash/get';
import { ensurePHPTimestamp } from '@/services/utils/date';
import { revertLocalizedValue } from '@/services/utils/localization';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import OrderApi from '@/services/Api/Order';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import { clientQuoteApi } from '@/services/Api/Quote';
import { EVENT_ORDER_UPDATED } from '@/constants/events';

import Card from '@/components/Layout/Card';
import FilterBox from '@/components/Filter/FilterBox';
import FilterSortPagination from '@/components/Filter/FilterSortPagination';
import Flyout from '@/components/Layout/Flyout';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Hint from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import OrderFilterSet from './OrderFilterSet';
import OrderItemBlock from '@/components/List/OrderItemBlock';
import Pagination from '@/components/Pagination';
import QuoteFilterSet from './QuoteFilterSet';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation';
import OrderItemBlockView from '@/components/List/OrderItemBlockView';
import ArrowIcon from '@/assets/icons/regular/arrow.svg';

export default {
    name: 'ClientOrderList',
    components: {
        Card,
        FilterBox,
        FilterSortPagination,
        Flyout,
        HeaderBar,
        HeaderBarItem,
        Hint,
        LayoutPage,
        OrderFilterSet,
        OrderItemBlock,
        Pagination,
        QuoteFilterSet,
        RoundedTabNavigation,
        ArrowIcon,
    },
    mixins: [persistentFiltersMixin, eventHubMixin],
    setup() {
        // The parent route is used to redirect back to the screen that initially linked to the transport list.
        // Otherwise, on mobile, when we add the transport list from another page the user has no way to get back.
        // We use a query param to pass the parent route to the transport list so that we can link back even we the
        // use has opened subpages like transport details in the mean time.
        let parentRoute;

        const route = useRoute();
        try {
            parentRoute = JSON.parse(route.query.parentRoute);
        } catch {
            // noop parent route stays undefined
        }

        return {
            parentRoute,
        };
    },
    data() {
        return {
            visibleList: localStorage.getItem('pages.order.list.selectedList'),

            tabCounts: {
                inProgress: 0,
                closed: 0,
                canceled: 0,
                requests: 0,
            },
            cancelTabCountSources: {},
            predefinedTabFilters: {
                inProgress: {
                    endpoint: OrderApi,
                    filter: { status: ['in_progress', 'new'] },
                },
                closed: {
                    endpoint: OrderApi,
                    filter: { status: ['closed'] },
                },
                canceled: {
                    endpoint: OrderApi,
                    filter: { status: ['canceled'] },
                },
                requests: {
                    endpoint: clientQuoteApi,
                    filter: {},
                },
            },

            isLoading: true,
            cancelSource: null,

            orders: null,
            orderEndpoint: OrderApi,
            orderCancelSource: null,
            orderFilter: this.assembleFilter('order', {
                page: 1,
                perPage: 25,
                status: ['in_progress', 'new'],
            }),
            defaultOrderFilter: {
                page: 1,
                perPage: 25,
                status: ['in_progress'],
            },

            quotes: null,
            quoteEndpoint: clientQuoteApi,
            quoteCancelSource: null,
            quoteFilter: this.assembleFilter('quote', {
                page: 1,
                perPage: 25,
            }),
            defaultQuoteFilter: {
                page: 1,
                perPage: 25,
            },
        };
    },
    computed: {
        activeTab() {
            if (this.visibleList === 'requests') {
                return 'requests';
            }

            if (this.orderFilter.status) {
                return this.snakeToCamel(this.orderFilter.status[0]);
            }
            // default tab "In Bearbeitung" (inProgress)
            return 'inProgress';
        },
        orderViewTabs() {
            const tabs = {};
            const keys = Object.keys(this.predefinedTabFilters);

            keys.forEach(key => {
                tabs[key] = `${this.$t('pages.order.tabs.' + key)} (${this.tabCounts[key]})`;
            });

            return tabs;
        },
        items() {
            if (this.activeTab === 'requests') {
                return this.quotes;
            }

            return this.orders;
        },
    },
    watch: {
        visibleList(newListType) {
            if (newListType === 'requests') {
                // clear any order filters on url
                this.persistFilter('order', this.defaultOrderFilter, this.defaultOrderFilter);
            } else {
                // clear any quote filter on url
                this.persistFilter('quote', this.defaultQuoteFilter, this.defaultQuoteFilter);
            }
        },
        /*
         * When changing the construction project filter on either quotes or orders,
         * we keep the other filter in sync so that all lists are filtered by the same construction project.
         */
        'orderFilter.constructionProject'(newConstructionProject) {
            if (this.quoteFilter.constructionProject !== newConstructionProject) {
                this.quoteFilter.constructionProject = newConstructionProject;
            }

            this.refreshTabCounts();
        },
        'quoteFilter.constructionProject'(newConstructionProject) {
            if (this.orderFilter.constructionProject !== newConstructionProject) {
                this.orderFilter.constructionProject = newConstructionProject;
            }

            this.refreshTabCounts();
        },
    },
    created() {
        const predefinedTab = _get(this.$route, 'params.predefinedTab', null);
        if (predefinedTab) {
            this.updateTabNavigation(predefinedTab, true);
        }

        this.refreshTabCounts();
        this.refreshOrderList(true);
        this.refreshQuoteList(true);

        this.subscribe(EVENT_ORDER_UPDATED, () => {
            this.refreshOrderList();
        });
    },
    methods: {
        updateTabNavigation(tab, isInitial = false) {
            if (tab === 'requests') {
                // load linked filter
                const queryFilter = isInitial ? this.assembleFilter('quote', this.defaultQuoteFilter) : {};
                this.quoteFilter = {
                    constructionProject: this.quoteFilter.constructionProject,
                    ...this.defaultQuoteFilter,
                    ...queryFilter,
                    ...this.predefinedTabFilters[tab].filter,
                };
                localStorage.setItem('pages.order.list.selectedList', 'requests');
                this.visibleList = 'requests';
                this.refreshQuoteList();
            } else {
                this.orderFilter = {
                    constructionProject: this.orderFilter.constructionProject,
                    ...this.defaultOrderFilter,
                    ...this.predefinedTabFilters[tab].filter,
                };
                localStorage.setItem('pages.order.list.selectedList', 'orders');
                this.visibleList = 'orders';
                this.refreshOrderList();
            }
        },

        pageNumberUpdated(number) {
            this.$eventHub.$emit('page.actions.scroll-top');
            if (this.activeTab === 'requests') {
                this.quoteFilter.page = number;
                this.refreshQuoteList();
            } else {
                this.orderFilter.page = number;
                this.refreshOrderList();
            }
        },

        snakeToCamel(str) {
            return str.replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''));
        },

        async refreshOrderList(isInitial) {
            this.isLoading = true;

            this.persistFilter('order', this.orderFilter, this.defaultOrderFilter);

            this.orderCancelSource && this.orderCancelSource.cancel('canceled-previous-call');
            this.orderCancelSource = this.orderEndpoint.createCancelTokenSource();

            try {
                const result = await this.orderEndpoint.filter(this.orderFilter, null, null, this.orderCancelSource);
                result.transform(item => OrderItemBlockView.create(item));
                this.orders = result;

                if (isInitial === true) {
                    this.orderFilter = {
                        ...this.orderFilter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }

            this.isLoading = false;
        },

        async refreshQuoteList(isInitial) {
            this.isLoading = true;

            this.persistFilter('quote', this.quoteFilter, this.defaultQuoteFilter);

            this.quoteCancelSource && this.quoteCancelSource.cancel('canceled-previous-call');
            this.quoteCancelSource = this.quoteEndpoint.createCancelTokenSource();

            try {
                const result = await this.quoteEndpoint.filter(this.quoteFilter, null, null, this.quoteCancelSource);
                result.transform(item => OrderItemBlockView.create(item));
                this.quotes = result;

                if (isInitial === true) {
                    this.quoteFilter = {
                        ...this.quoteFilter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }

            this.isLoading = false;
        },

        selectItem(item) {
            if (this.activeTab === 'requests') {
                this.selectQuote(item);
                return;
            }

            this.selectOrder(item);
        },

        selectOrder(order) {
            if (!this.$can('viewOrder', order)) {
                return;
            }

            this.$router
                .push({
                    name: this.$root.findRouteName('order-list__order-view'),
                    params: { orderId: order.id },
                    query: this.$route.query,
                })
                .catch(() => {});
        },

        selectQuote(quote) {
            this.$router
                .push({
                    name: this.$root.findRouteName('order-quote-view'),
                    params: { quoteId: quote.id },
                    query: this.$route.query,
                })
                .catch(() => {});
        },

        async fetchFilterCount(predefinedTabKey) {
            const predefinedTab = this.predefinedTabFilters[predefinedTabKey];

            this.cancelTabCountSources[predefinedTabKey] &&
                this.cancelTabCountSources[predefinedTabKey].cancel('canceled-previous-count-only-call');
            this.cancelTabCountSources[predefinedTabKey] = predefinedTab.endpoint.createCancelTokenSource();

            const filter = {
                ...predefinedTab.filter,
            };

            filter.constructionProject =
                predefinedTabKey === 'requests'
                    ? this.quoteFilter.constructionProject
                    : this.orderFilter.constructionProject;

            try {
                const result = await predefinedTab.endpoint.filter(filter);
                return result.count;
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-count-only-call') {
                    this.$logger().error(err);
                }
            }
        },

        async refreshTabCounts() {
            Object.keys(this.predefinedTabFilters).forEach(async key => {
                this.$set(this.tabCounts, key, await this.fetchFilterCount(key));
            });
        },

        goToParent() {
            if (this.parentRoute) {
                this.$router.push(this.parentRoute).catch(() => {});
            }
        },

        ensurePHPTimestamp,
        revertLocalizedValue,
    },
};
</script>

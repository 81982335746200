import { isRef, ref, unref, watch } from 'vue';
import { useEventHub } from '@/plugins/composables/useEventHub';
import { EVENT_PROJECT_POSITION_CLOSED } from '@/constants/events';
import { useStateful } from '@/services/stateful/stateful';
import { getConstructionProjectById } from '../api/projects';
export function useConstructionProject(id, { onError } = {}) {
    const { stateful } = useStateful();
    const project = ref(null);
    const loadData = async () => {
        await stateful('refreshProject', async () => {
            try {
                project.value = await getConstructionProjectById(unref(id));
            }
            catch (err) {
                onError?.(err);
            }
        });
    };
    const eventHub = useEventHub();
    eventHub.subscribe(EVENT_PROJECT_POSITION_CLOSED, loadData);
    if (isRef(id)) {
        watch(id, loadData);
    }
    loadData();
    return {
        data: project,
    };
}

import AbstractFilterableResource from './AbstractFilterableResource';

class VehicleClass extends AbstractFilterableResource {
    /**
     * Get order types
     */
    async getOrderTypes() {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/order-types`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Get attribute set by identifier
     * @param {string} attributeSetIdentifier
     */
    async getAttributeSet(attributeSetIdentifier) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/attributes/${attributeSetIdentifier}`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Get available countries
     */
    async getAvailableCountries() {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/available-countries`);
            return response.data?.availableCountries ?? [];
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new VehicleClass('/vehicle-class/v2');

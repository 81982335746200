<template>
    <LayoutPage screen-name="client-activetrip-list">
        <template #pageTitle>
            {{ $t('pages.home.client.title') }}
        </template>

        <transition name="fade">
            <div
                v-if="activeTransports && activeTransports.count > 0"
                data-id="client-current-transports"
                class="container-deprecated pickup-active-trips__section"
            >
                <Words block bold class="client-page__headline">
                    {{ $tc('pages.home.client.activeTransportsHeadline', activeTransports.count) }}
                </Words>
                <Words block muted small spaced-bottom>
                    {{ $tc('pages.home.client.activeTransportsMessage', activeTransports.count) }}
                </Words>
                <List>
                    <ActiveTransportPickupItem
                        v-for="transport in activeTransports.items"
                        :key="transport.id"
                        :transport="transport"
                        @click="selectTransport(transport.id)"
                    />
                </List>
            </div>
        </transition>

        <transition name="fade">
            <div
                v-if="orders && orders.count > 0"
                data-id="client-current-transports"
                class="container-deprecated pickup-active-trips__section"
            >
                <Words block bold class="client-page__headline">
                    {{ $t('pages.home.client.startPickupHeadline') }}
                </Words>
                <Words block muted small spaced-bottom>
                    {{ $t('pages.home.client.startPickupMessage') }}
                </Words>
                <List>
                    <ActiveOrderPickupItem
                        v-for="order in orders.items"
                        :key="order.id"
                        :order="order"
                        :line-item-group="order.lineItemGroups[0]"
                        @click="selectOrder(order.id)"
                        @add="startPickupOrder"
                    />
                </List>
            </div>
            <Hint v-else>
                {{ $t('pages.home.client.activeTransportsNoResults') }}
            </Hint>
        </transition>

        <div slot="subpages">
            <Flyout screen-name="client-activetrip-start" route="pickup-start-order" size="small" no-header />
            <Flyout route="transport-view" size="small" no-header />
            <Flyout route="order-view" size="small" no-header />
        </div>
    </LayoutPage>
</template>

<script>
import { mapGetters } from 'vuex';
import TransportListApi from '@/services/Api/TransportList';
import OrderApi from '@/services/Api/Order';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { CONTEXT_CLIENT } from '@/constants/context';
import { EVENT_ORDER_UPDATED } from '@/constants/events';

import ActiveOrderPickupItem from '@/pages/Home/components/ActiveOrderPickupItem';
import ActiveTransportPickupItem from '@/pages/Home/components/ActiveTransportPickupItem';
import Flyout from '@/components/Layout/Flyout';
import LayoutPage from '@/components/Layout/Page.v2';
import List from '@/components/List/List';
import Words from '@/components/Typography/Words';
import Hint from '@/components/Typography/Hint';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';

const UPDATE_INTERVAL = 10000;

const transportListApi = new TransportListApi(CONTEXT_CLIENT);

export default {
    name: 'PickupActiveTrips',

    components: {
        ActiveOrderPickupItem,
        ActiveTransportPickupItem,
        Flyout,
        LayoutPage,
        List,
        Words,
        Hint,
    },

    mixins: [eventHubMixin],

    data() {
        return {
            activeTransports: null,
            orders: null,
            orderHotlineNumber: null,
        };
    },

    computed: {
        ...mapGetters('user', {
            currentUserId: 'id',
        }),

        ...mapGetters('platform', { platformInfo: 'info' }),
    },

    created() {
        this.refreshActiveTransports();
        this.refreshOrders();

        this.$gracefulInterval(async () => {
            await this.refreshActiveTransports();
            await this.refreshOrders();
        }, UPDATE_INTERVAL);

        this.subscribe(EVENT_ORDER_UPDATED, () => {
            this.refreshOrders();
        });
        this.subscribeMutation('transportActions/updateTransport', () => {
            this.refreshActiveTransports();
            this.refreshOrders();
        });
    },

    methods: {
        selectTransport(transportId) {
            this.$router
                .push({
                    name: 'logistics__pickup-active-trips__transport-view',
                    params: { transportId },
                })
                .catch(() => {});
        },

        selectOrder(orderId) {
            this.$router
                .push({
                    name: 'logistics__pickup-active-trips__order-view',
                    params: { orderId },
                })
                .catch(() => {});
        },

        async refreshActiveTransports() {
            try {
                this.activeTransports = await transportListApi.filter({
                    type: 'pickup',
                    status: ['started', 'checked_in', 'pickup_loaded'],
                    driver: this.currentUserId,
                });
            } catch (err) {
                this.$logger().error(err);
            }
        },

        async refreshOrders() {
            try {
                this.orders = await OrderApi.filter({
                    type: ORDER_LINE_ITEM_GROUP_TYPE.PICKUP,
                    status: ['new', 'in_progress'],
                    canStartPickup: true,
                });
            } catch (err) {
                this.$logger().error(err);
            }
        },

        startPickupOrder(order) {
            this.$router
                .push({
                    name: `${this.$route.name}__pickup-start-order`,
                    params: { orderId: order.id },
                })
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss">
.pickup-active-trips__section {
    margin-bottom: 40px;
}
</style>

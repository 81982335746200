import gsap, { TimelineLite } from 'gsap';
import { MotionPathPlugin } from 'gsap/dist/MotionPathPlugin';
import { lerp } from '@/services/utils';

// Register gsap plugins
gsap.registerPlugin(MotionPathPlugin);

function queryElement(selector) {
    return document.querySelector(selector);
}

function hideElement(selector) {
    const el = queryElement(selector);
    if (el) {
        el.classList.add('price-illustration--u--hide');
    }
}

export function showElement(selector) {
    const el = queryElement(selector);
    if (el) {
        el.classList.remove('price-illustration--u--hide');
    }
}

export function toggleElement(selector, condition) {
    condition ? showElement(selector) : hideElement(selector);
}

export function triggerWeight(selector) {
    const timeline = new TimelineLite();

    gsap.set(selector, {
        transformStyle: 'preserve-3d',
        transformOrigin: '50% 50%',
    });

    timeline.to(selector, {
        duration: 0.25,
        motionPath: {
            path: [
                {
                    x: 0,
                    y: 4,
                },
                {
                    x: 0,
                    y: 0,
                },
            ],
            curviness: 1,
        },
        ease: 'power1.easeOut',
    });
}

export async function upperMovement(selector) {
    const timeline = new TimelineLite();

    return new Promise(resolve => {
        gsap.set(selector, {
            transformStyle: 'preserve-3d',
            transformOrigin: '50% 50%',
            x: 0,
            y: 80,
        });

        timeline.from(selector, {
            duration: 0.5,
            x: 0,
            y: 80,
            opacity: 0,
        });
        timeline.to(selector, {
            duration: 0.5,
            x: 0,
            y: -60,
            opacity: 1,
        });
        timeline.from(selector, {
            duration: 0.5,
            x: 0,
            y: 80,
            opacity: 0,
        });
        timeline.to(selector, {
            duration: 0.5,
            x: 0,
            y: -60,
            opacity: 1,
        });
        timeline.from(selector, {
            duration: 0.5,
            x: 0,
            y: 80,
            opacity: 0,
        });
        timeline.to(selector, {
            duration: 0.5,
            x: 0,
            y: 0,
            opacity: 1,
            onComplete: resolve,
        });
    });
}

export function spinByY(selector) {
    gsap.set(selector, {
        transformStyle: 'preserve-3d',
        transformOrigin: '50% 50%',
    });

    gsap.to(selector, {
        duration: 1,
        rotation: '+=1440',
        ease: 'power3.easeOut',
    });
}

export function positionTopPercent(selector, progress, min, max) {
    const value = lerp(min, max, progress / 100);

    gsap.set(selector, {
        transformOrigin: '50% 50%',
        y: value,
    });
}

const LS_OVERSEER_ACCESS_KEY = 'overseer-entries';
class OverseerService {
    constructor() {
        this.data = {};
        this.loadStorage();
    }

    /**
     * Check if a revisionNumber has been seen
     * @param {*} identifier
     * @param {*} revisionNumber
     */
    hasSeen(identifier, revisionNumber) {
        // is value already tracked?
        if (this.data[identifier] !== undefined) {
            // check if tracked value is older than the new one
            return this.data[identifier] >= revisionNumber;
        } else {
            // track value
            this.data[identifier] = 0;
            this.persistStorage();
        }
    }

    /**
     * Mark a revisionNumber as seen
     *
     * @param {*} identifier
     * @param {*} revisionNumber
     */
    markSeen(identifier, revisionNumber) {
        // track revisionNumber per identifier
        this.data[identifier] = revisionNumber;
        this.persistStorage();
    }

    /**
     * Mark a revisionNumber as seen if not tracked before
     *
     * @param {*} identifier
     * @param {*} revisionNumber
     */
    markSeenIfNew(identifier, revisionNumber) {
        if (!this.data[identifier]) {
            this.markSeen(identifier, revisionNumber);
        }
    }

    /**
     * Clear storage
     */
    forgetEverything() {
        this.data = {};
        this.persistStorage();
    }

    /**
     * Load storage
     */
    loadStorage() {
        const raw = window.localStorage.getItem(LS_OVERSEER_ACCESS_KEY);
        try {
            this.data = JSON.parse(raw) || {};
        } catch (_) {
            //
        }
    }

    /**
     * Persist storage
     */
    persistStorage() {
        window.localStorage.setItem(LS_OVERSEER_ACCESS_KEY, JSON.stringify(this.data));
    }
}

export default new OverseerService();

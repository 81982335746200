import clsx from 'clsx';
import { InfoIcon } from '@/pages/Transport/components/icons/InfoIcon';
import { CopyIcon } from '@/pages/Transport/components/icons/CopyIcon';
import { Tooltip } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
import { useVueEmit } from '@/reactBridge/EmitVueEventProvider';
const defaultDocumentNumber = '00000000000000';
export default function DisposalDocumentNumber({ documentNumber, context = 'default', emptyState = true, }) {
    const disposalDocumentNumber = documentNumber ?? defaultDocumentNumber;
    const lastDigit = disposalDocumentNumber[disposalDocumentNumber.length - 1];
    const numberWithoutLastDigit = disposalDocumentNumber.slice(0, -1);
    const EmptyStateDocumentNumber = () => {
        const { t } = useTranslation();
        return (<div className="mb-4 flex items-center">
                <span className="font-copy-md mr-4">---</span>
                <Tooltip text={t('pages.transport.transportViewV2.loadingTab.accompanyingDocumentNumber.notEntered')} size="md">
                    <InfoIcon />
                </Tooltip>
            </div>);
    };
    const DocumentNumber = ({ numberWithoutLastDigit, disposalDocumentNumber, lastDigit }) => {
        const { emitVueEvent } = useVueEmit();
        const handleCopy = () => {
            emitVueEvent('copyToClipboard');
        };
        return (<div className="mb-1 flex flex-wrap items-center md:mb-0">
                {numberWithoutLastDigit.split('').map((digit, index) => {
                return (<span key={index}>
                            <span className={clsx('flex', 'items-center', 'justify-center', 'h-8', 'text-xl', 'border-l', 'border-t', 'border-b', 'bg-surface', 'p-1', 'mb-1', 'w-[20px]', 'text-center', index === numberWithoutLastDigit.length - 1 && 'border-r', disposalDocumentNumber === defaultDocumentNumber &&
                        'bg-surface-disabled text-disabled')}>
                                {digit}
                            </span>
                        </span>);
            })}
                <span className={clsx('flex', 'h-8', 'items-center', 'justify-center', 'border', 'ml-1', 'bg-surface', 'p-1', 'mb-1', 'text-xl', 'w-[20px]', disposalDocumentNumber === defaultDocumentNumber && 'bg-surface-disabled text-disabled')}>
                    {lastDigit}
                </span>

                {disposalDocumentNumber != defaultDocumentNumber && (<span className={clsx('mb-1 ml-2', {
                    'ml-0 md:ml-2': disposalDocumentNumber.length === 14,
                })} onClick={handleCopy}>
                        <CopyIcon size="xs"/>
                    </span>)}
            </div>);
    };
    const _DisposalDocumentNumber = () => {
        if (emptyState && disposalDocumentNumber == defaultDocumentNumber) {
            return <EmptyStateDocumentNumber />;
        }
        if (context == 'default') {
            const formattedDocumentNumber = numberWithoutLastDigit + ' ' + lastDigit;
            return <span className="font-copy-md font-numbers-md break-all">{formattedDocumentNumber}</span>;
        }
        return (<DocumentNumber lastDigit={lastDigit} disposalDocumentNumber={disposalDocumentNumber} numberWithoutLastDigit={numberWithoutLastDigit}/>);
    };
    return _DisposalDocumentNumber();
}

import SupplierTagApi from '@/services/Api/Supplier/Tag.js';
import _debounce from 'lodash/debounce';
import { ref } from 'vue';
export const useTags = (v$) => {
    const availableTags = ref([]);
    const selectTag = (tag) => {
        const index = v$.value.tags.$model.findIndex((entry) => entry.id === tag.id);
        if (index !== -1) {
            v$.value.tags.$model.splice(index, 1);
        }
        v$.value.tags.$model.push(tag);
        v$.value.tags.$touch();
    };
    const createTag = async (tagLabel) => {
        const createdTag = (await SupplierTagApi.createTag(tagLabel));
        selectTag(createdTag);
    };
    const removeTag = (tagId) => {
        const index = v$.value.tags.$model.findIndex((tag) => tag.id === tagId);
        v$.value.tags.$model.splice(index, 1);
        v$.value.tags.$touch();
    };
    let debouncedSearch;
    const searchTag = async (tagLabel) => {
        if (debouncedSearch) {
            debouncedSearch.cancel();
        }
        debouncedSearch = _debounce(async () => {
            availableTags.value = (await SupplierTagApi.getTagsList(tagLabel));
        }, 500);
        debouncedSearch();
    };
    return {
        // Data
        availableTags,
        // Methods
        createTag,
        removeTag,
        searchTag,
        selectTag,
    };
};

<template>
    <div class="component-wrapper" :class="propertyStyles">
        <div class="component-wrapper__head">
            <div>
                <div
                    v-if="hasComponentTextSlot"
                    class="component-wrapper__text"
                    :class="{ 'component-wrapper__text--large-spacing': !hasComponentSmallTextSlot }"
                >
                    <slot name="componentText" />
                </div>
                <div v-if="hasComponentSmallTextSlot" class="component-wrapper__text-small">
                    <slot name="componentSmallText" />
                </div>
                <div v-if="hasComponentTitleSlot" class="component-wrapper__title">
                    <slot name="componentTitle" />
                </div>
            </div>
            <div v-if="hasHelpLinkSlot">
                <slot name="componentHelpLink" />
            </div>
        </div>

        <div v-if="hasComponentSlot" class="component-wrapper__component">
            <slot name="component" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ComponentWrapper',
    props: {
        borderBottom: {
            type: Boolean,
            default: false,
        },
        widthFull: {
            type: Boolean,
            default: false,
        },
        marginBottomLarge: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        propertyStyles() {
            return {
                'component-wrapper--border-bottom': this.borderBottom,
                'component-wrapper--full-width': this.widthFull,
                'component-wrapper--margin-bottom-large': this.marginBottomLarge,
            };
        },
        hasComponentTextSlot() {
            return !!this.$slots.componentText;
        },
        hasComponentSmallTextSlot() {
            return !!this.$slots.componentSmallText;
        },
        hasComponentTitleSlot() {
            return !!this.$slots.componentTitle;
        },
        hasHelpLinkSlot() {
            return !!this.$slots.componentHelpLink;
        },
        hasComponentSlot() {
            return !!this.$slots.component;
        },
    },
};
</script>

<style lang="scss" scoped>
.component-wrapper {
    margin-bottom: 40px;

    &__head {
        display: flex;
        justify-content: space-between;
    }

    &--border-bottom {
        border-bottom: 1px solid $color-lightGrey-400;
        margin-bottom: 40px;
        padding-bottom: 40px;
    }

    &--full-width {
        width: 100%;
    }

    &--margin-bottom-large {
        margin-bottom: 48px;
    }

    &__text {
        font-size: $font-size-base;
        font-weight: $font-weight-regular;
        line-height: 1.285;
        margin-bottom: 4px;

        &--large-spacing {
            margin-bottom: 24px;
        }
    }

    &__text-small {
        font-size: $font-size-tiny;
        font-weight: $font-weight-bold;
        line-height: 1.219;
        margin-bottom: 24px;
    }

    &__title {
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
        line-height: 1.333;
        margin-bottom: 8px;
    }

    &__error {
        margin-bottom: 10px;
    }
}
</style>

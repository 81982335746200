import { AbstractResourceErrorHandler } from '@/services/Api/AbstractResourceErrorHandler';
import ApiService from './ApiService';
class ProductSelectionResult extends AbstractResourceErrorHandler {
    apiService;
    resourcePath;
    constructor(resourcePath, messages = {}) {
        super(messages);
        this.apiService = ApiService;
        this.resourcePath = resourcePath;
    }
    getRecentProducts = async (locationId, orderType) => {
        const response = await this.apiService.get(`${this.resourcePath}/${orderType}/product-selection/location/${locationId}`);
        return response.data;
    };
}
export default new ProductSelectionResult('/order/checkout/process');

import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';
export const USAGES = {
    SELLING: 'selling',
    DISPOSAL: 'disposal',
};
export const FACTORY_TYPES = {
    LANDFILL: 'landfill', // Deponie
    PLANT: 'plant', // Anlage
    DUMPING_SITE: 'dumping_site', // Kippstelle
};
export const LANDFILL_CLASSES = ['DK0', 'DK1', 'DK2', 'DK3'];
export const LANDFILL_CODE_TO_NAME_MAPPING = {
    DK0: 'DK 0',
    DK1: 'DK I',
    DK2: 'DK II',
    DK3: 'DK III',
    DK4: 'DK IV',
};
export const DISPOSAL_ANALYSIS_DOCUMENT_TYPE = {
    DECLARATION: 'waste_declaration_analysis',
    ACCOMPANYING: 'waste_accompanying_analysis_document',
};
export const isOrderLineItemGroupTypeDisposal = (type) => {
    return (ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_HAZARDOUS_WASTE === type ||
        ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_NON_HAZARDOUS_WASTE === type);
};

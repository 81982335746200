var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('LayoutPage',{attrs:{"is-flyout":"","close-by-x":_vm.isProjectPositionEditMode,"data-test":"project-position-analysis-documents-upload"},on:{"close":function($event){return _vm.closeFlyout()}},scopedSlots:_vm._u([(_vm.isProjectPositionEditMode)?{key:"pageTitle",fn:function(){return [_c('span',{staticClass:"font-copy-md"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.projectPositionDisposal.pages.analysis.changeAnalysis'))+" ")])]},proxy:true}:{key:"flyoutHeader",fn:function(){return [_c('FlyoutHeader')]},proxy:true},{key:"sticky",fn:function(){return [_c('SlideUp',{attrs:{"active":""}},[_c('ButtonGroup',{attrs:{"mobile-breakpoint":"component-library"}},[_c('SfButton',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t('forms.buttons.label.confirmAndContinue'))+" ")])],1)],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"container-off-canvas-sm my-6"},[_c('p',{staticClass:"font-copy-md-strong mb-2"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.projectPositionDisposal.pages.analysis.label'))+" ")]),(_vm.isLoading)?_c('LoadingCard'):[_c('p',{staticClass:"font-copy-md",class:'mb-6'},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.projectPositionDisposal.pages.analysis.uploadDeclarationAnalysis'))+" ")]),_c('input',{attrs:{"type":"hidden","readonly":"","name":"requestCollection.documentUuids"},domProps:{"value":_vm.documents}}),_c('SfValidationMessage',{staticClass:"mb-8",attrs:{"text":_vm.getError('requestCollection.documentUuids'),"type":"error"}},[_c('FileUploader',{attrs:{"documents":_vm.documents,"enable-image-upload":false,"product-use-cases":{
                        image: 'waste_image',
                        document: 'waste_document',
                    },"document-upload-type":_vm.wasteAnalysisDeclaration,"is-native":_vm.isNative,"maximum-file-size-in-mb":8},on:{"update:documents":function($event){return _vm.handleAnalysisDocumentsUpdate($event, false)}}})],1),_c('div',{staticClass:"font-copy-md-strong mb-6"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.projectPositionDisposal.pages.analysis.otherDocuments'))+" ")]),(_vm.isLoading)?_c('LoadingCard'):_vm._e(),_c('FileUploader',{attrs:{"documents":_vm.otherDocuments,"images":_vm.images,"product-use-cases":{
                    image: 'waste_image',
                    document: 'waste_document',
                },"document-upload-type":_vm.wasteAnalysisAccompanyingDocument,"is-native":_vm.isNative,"maximum-file-size-in-mb":8},on:{"update:images":_vm.handleImagesUpdate,"update:documents":function($event){return _vm.handleAnalysisDocumentsUpdate($event, true)}}})]],2)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
    <div
        class="site-tile"
        :class="{
            'site-tile--clickable': clickable,
            'site-tile--no-border': noBorder,
            'site-tile--levitate': levitate,
            'site-tile--spaceless': spaceless,
        }"
        @click="$emit('tile-action', site)"
    >
        <div
            v-if="showHeadline"
            class="flex flex-nowrap items-center justify-between"
            :class="{ 'cursor-pointer': hasAction }"
            @click="$emit('headline-action', site)"
        >
            <span class="font-copy-sm-strong">
                {{ title || $t(`components.siteTile.headline.${type}`) }}
            </span>

            <SfIconButton v-if="hasAction" size="sm" :has-background="false">
                <template #icon="iconProps"><SfSysChevronRightIcon v-bind="iconProps" /></template>
            </SfIconButton>
        </div>

        <!-- Site name and address -->
        <div v-if="showAddress && site.address">
            <template v-if="site.name">
                <span class="font-copy-md-strong">
                    {{ site.name }}
                </span>
                <FormattedAddress class="mt-1" decorate-location-code :address="site" />
            </template>
            <template v-else>
                <span class="font-copy-md-strong">
                    {{ site.address.additionalAddress }}
                </span>
                <FormattedAddress class="mt-1" decorate-location-code hide-additional-address :address="site" />
            </template>

            <!-- Site Information -->
            <p v-if="showImportantInfo && site.importantInformation" class="font-copy-sm mt-4 break-words text-subdued">
                {{ site.importantInformation }}
            </p>
        </div>

        <div v-if="showDate && !site.hasPlanning" :class="computedDateClasses">
            <SfCalendarIcon size="xs" multi-color />
            <div>
                <TransportDate
                    :date-from="site.dateFrom"
                    :date-to="site.dateTo"
                    :time-from="site.timeFrom"
                    :time-to="site.timeTo"
                />
            </div>
        </div>

        <div v-if="showDate && site && site.isPlanningBase && site.timeFrom">
            <div :class="computedDateClasses">
                <SfTimeIcon size="xs" multi-color />
                <div>
                    <TransportDate
                        :label="$t(`components.siteTile.managedTimes`)"
                        :time-from="site.timeFrom"
                        :time-to="site.timeTo"
                    />
                </div>
            </div>
        </div>

        <div v-if="showDate && site.isPlanningBase && site.shippingDate" :class="computedDateClasses">
            <SfCalendarIcon size="xs" multi-color />
            <div>
                <TransportDate
                    :label="
                        $t(
                            site.isPlanningBaseLoading
                                ? `components.siteTile.loadingOn`
                                : `components.siteTile.unloadingOn`
                        )
                    "
                    :date-from="site.shippingDate"
                    :time-from="site.shippingDate"
                />
            </div>
        </div>

        <SfInfoWithContext v-if="showContactInfo && site.contactPerson" important>
            <span>{{ site.contactPerson }}</span>
            <template v-if="site.contactPhone" #subcontent>
                <span>{{ assemblePhoneNumber(site.contactPhone, true) }}</span>
            </template>
        </SfInfoWithContext>

        <div v-if="showHeadline" class="font-copy-sm-strong">
            {{ $t(`components.siteTile.detailHeadline.${type}`) }}
        </div>

        <div v-if="showThumbnails" class="site-tile__thumbs flex flex-wrap gap-8">
            <div v-if="site.location" class="relative">
                <SfThumbnail
                    :file-src="staticMap"
                    :translations="{
                        noImage: $t('components.thumbnail'),
                    }"
                    @click="openMap"
                />
            </div>

            <SfThumbnail
                v-if="site.image"
                :file-thumbnail-src="site.image.url.thumbnail"
                :file-src="site.image.url.original"
                :translations="{
                    noImage: $t('components.thumbnail'),
                }"
                modal
            />
        </div>

        <p v-if="showInformation && site.information" class="font-copy-sm">
            {{ site.information }}
        </p>

        <Flyout
            v-if="site.location"
            :active="mapOpen"
            :headline="
                'loading' === type
                    ? $t('pages.checkout.summary.loadingSite.mapHeadline')
                    : $t('pages.checkout.summary.unloadingSite.mapHeadline')
            "
            class="site-tile__map"
            @closed="closeMap"
        >
            <UnloadingMap :location="site.location" map-type="satellite" />
        </Flyout>
    </div>
</template>

<script>
import { getStaticMapUrl } from '@/services/GoogleMaps';
import { ensureJSTimestamp } from '@/services/utils/date';
import { assemblePhoneNumber } from '@/services/utils';

import FormattedAddress from '@/components/FormattedAddress';
import Flyout from '@/components/Layout/Flyout';
import UnloadingMap from '@/components/Order/UnloadingMap';
import SummaryView from '@/pages/Checkout/SummaryView';

import TransportDate from '@/components/Transport/partials/TransportDate.vue';

import {
    SfInfoWithContext,
    SfThumbnail,
    SfIconButton,
    SfCalendarIcon,
    SfTimeIcon,
    SfSysChevronRightIcon,
} from '@schuettflix/vue-components';

export default {
    name: 'SiteTile',
    components: {
        FormattedAddress,
        Flyout,
        UnloadingMap,

        TransportDate,

        // components
        SfInfoWithContext,
        SfThumbnail,
        SfIconButton,
        SfCalendarIcon,
        SfTimeIcon,
        SfSysChevronRightIcon,
    },
    props: {
        item: {
            type: [Object, SummaryView],
            required: true,
        },
        type: {
            type: String,
            required: true,
            validator: v => ['loading', 'unloading'].includes(v),
        },
        title: {
            type: String,
            default: null,
        },
        hasAction: {
            type: Boolean,
            default: false,
        },
        showHeadline: {
            type: Boolean,
            default: false,
        },
        showAddress: {
            type: Boolean,
            default: false,
        },
        showDate: {
            type: Boolean,
            default: false,
        },
        showThumbnails: {
            type: Boolean,
            default: false,
        },
        showInformation: {
            type: Boolean,
            default: false,
        },
        showContactInfo: {
            type: Boolean,
            default: false,
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
        levitate: {
            type: Boolean,
            default: false,
        },
        spaceless: {
            type: Boolean,
            default: false,
        },
        showImportantInfo: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            mapOpen: false,
        };
    },
    computed: {
        computedDateClasses() {
            return 'grid grid-cols-[32px_1fr] items-center justify-between gap-4';
        },

        site() {
            let result = {};
            if (this.item) {
                const planningAndShippingDetails = {
                    hasPlanning: 'planned' === this.item.planningMethod,
                    isPlanningBase: this.item.planningBase === this.type,
                    isPlanningBaseLoading: 'loading' === this.item.planningBase,
                    shippingDate: ensureJSTimestamp(this.item.shippingDate),
                };
                result =
                    'loading' === this.type
                        ? this.createLoadingSite(planningAndShippingDetails)
                        : this.createUnloadingSite(planningAndShippingDetails);
            }
            return result;
        },

        staticMap() {
            if (!this.site.location) return null;
            return getStaticMapUrl(this.site.location, 16, 200, 200, 'satellite');
        },
    },
    methods: {
        assemblePhoneNumber,

        openMap() {
            this.mapOpen = true;
        },
        closeMap() {
            this.mapOpen = false;
        },
        selectUnloadingSiteLocation() {
            this.$emit('change-action');
        },
        createLoadingSite(planningAndShippingDetails) {
            return planningAndShippingDetails
                ? {
                      name: this.item.loadingName || null,
                      information: this.item.loadingSiteInformation || null,
                      image: this.item.loadingSiteImage || null,
                      location: this.item.loadingSiteLocation,
                      dateFrom: ensureJSTimestamp(this.item.loadingDateFrom),
                      dateTo: ensureJSTimestamp(this.item.loadingDateTo),
                      timeFrom: this.item.loadingTimeFrom || null,
                      timeTo: this.item.loadingTimeTo || null,
                      address: this.item.loadingAddress || null,
                      contactPerson: this.item.loadingContactPerson || null,
                      contactPhone: this.item.loadingContactPhone || null,
                      importantInformation: this.item.loadingImportantInformation || null,
                      hasPlanning: planningAndShippingDetails.hasPlanning,
                      isPlanningBase: planningAndShippingDetails.isPlanningBase,
                      isPlanningBaseLoading: planningAndShippingDetails.isPlanningBaseLoading,
                      shippingDate: planningAndShippingDetails.shippingDate,
                  }
                : {};
        },
        createUnloadingSite(planningAndShippingDetails) {
            return planningAndShippingDetails
                ? {
                      name: this.item.unloadingName || null,
                      information: this.item.unloadingSiteInformation || null,
                      image: this.item.unloadingSiteImage || null,
                      location: this.item.unloadingSiteLocation || null,
                      dateFrom: ensureJSTimestamp(this.item.unloadingDateFrom),
                      dateTo: ensureJSTimestamp(this.item.unloadingDateTo),
                      timeFrom: this.item.unloadingTimeFrom || null,
                      timeTo: this.item.unloadingTimeTo || null,
                      address: this.item.unloadingAddress || null,
                      additionalAddress: this.item.unloadingAdditionalAddress || null,
                      contactPerson: this.item.unloadingContactPerson || null,
                      contactPhone: this.item.unloadingContactPhone || null,
                      importantInformation: this.item.unloadingImportantInformation || null,
                      hasPlanning: planningAndShippingDetails.hasPlanning,
                      isPlanningBase: planningAndShippingDetails.isPlanningBase,
                      isPlanningBaseLoading: planningAndShippingDetails.isPlanningBaseLoading,
                      shippingDate: planningAndShippingDetails.shippingDate,
                  }
                : {};
        },
    },
};
</script>

<style lang="scss" scoped>
$site-tile-vertical-spacing: 24px;
$site-tile-border-size: 2px;
$site-tile-horizontal-margin: 2px;
$site-tile-horizontal-spacing: 15px - $site-tile-border-size - $site-tile-horizontal-margin;
$site-tile-horizontal-spacing-lg: 25px - $site-tile-border-size - $site-tile-horizontal-margin;

.site-tile {
    display: grid;
    margin: 0 $site-tile-horizontal-margin;
    padding: 20px 0;
    grid-template-columns: $site-tile-horizontal-spacing 1fr $site-tile-horizontal-spacing;
    grid-column-gap: 0;
    grid-row-gap: $site-tile-vertical-spacing;
    background-color: $color-white;
    border: $site-tile-border-size solid $color-white;
    border-bottom-color: $color-lightGrey;

    @media only screen and (min-width: $layout-desktop-min) {
        grid-template-columns: $site-tile-horizontal-spacing-lg 1fr $site-tile-horizontal-spacing-lg;
    }

    > * {
        grid-column: 2 / 3;
    }
}

.site-tile--no-border {
    border-bottom-color: $color-white;
}

.site-tile--clickable {
    cursor: pointer;
}

.site-tile--levitate {
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
    transition:
        transform 0.2s ease,
        box-shadow 0.2s ease;
    margin-bottom: 20px;
}

.site-tile--levitate:active {
    transform: translateY(5px);
    box-shadow: $boxShadow-card;
}

.site-tile--spaceless {
    grid-template-columns: 0 1fr 0;
    margin: 0;
    padding: 0;
    border: 0;
}
</style>

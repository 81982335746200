var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filter-bar",class:{
        'filter-bar--hidden': _vm.hideBar,
        'filter-bar--transparent': _vm.transparent,
        'filter-bar--border': _vm.border,
        'filter-bar--dark-border': _vm.darkBorder,
        'filter-bar--no-spacing': _vm.noSpacing,
    }},[(_vm.$slots.filter)?_c('div',{staticClass:"filter-bar__filter"},[_vm._t("filter")],2):_vm._e(),(_vm.$slots.sorting)?_c('div',{staticClass:"filter-bar__sorting"},[_vm._t("sorting")],2):_vm._e(),(_vm.showRefresh)?_c('div',{staticClass:"filter-bar__refresh",class:{
            'filter-bar__refresh--loading': _vm.loading,
        }},[_c('BaseButton',{attrs:{"primary":"","light":"","icon":""},on:{"click":function($event){return _vm.$emit('refresh')}}},[_c('RefreshIcon')],1)],1):_vm._e(),(_vm.$slots.actions)?_c('div',{staticClass:"filter-bar__actions"},[_vm._t("actions")],2):_vm._e()])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
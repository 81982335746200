var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LayoutPage',{staticClass:"checkout-summary",attrs:{"is-flyout":"","data-test":"checkout-summary-page"},scopedSlots:_vm._u([{key:"flyoutHeader",fn:function(){return [_c('LayoutHeader',{attrs:{"subline":_vm.subline}})]},proxy:true},{key:"sticky",fn:function(){return [_c('SlideUp',{attrs:{"active":""}},[_c('ButtonGroup',[_c('BaseButton',{attrs:{"primary":"","type":"button","data-test":"checkout-summary-confirm-button"},on:{"click":function($event){return _vm.save()}},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('ArrowRightIcon',{staticClass:"icon--inline"})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.summary.actions.placePosition'))+" ")])],1)],1)]},proxy:true}])},[_c('div',{staticClass:"container-off-canvas-sm bg-white"},[_c('ProductTitleSection',_vm._b({staticClass:"mt-4"},'ProductTitleSection',_vm.getSummary.productTitle,false)),_c('HorizontalDivider',{staticClass:"mb-6 mt-12"}),_c('OfferSection',{attrs:{"prices-data":_vm.pricesData,"waste":_vm.getSummary.waste,"waste-description":_vm.getSummary.wasteDescription,"has-fixed-price":_vm.getSummary.hasFixedPrice,"is-hazardous":_vm.getSummary.isHazardous},on:{"edit":_vm.editSection}}),_c('NoteSection',{attrs:{"name":'requestCollection.note',"parent-validation-rules":_vm.validationRules,"show-divider":false},on:{"input":_vm.updateNote},model:{value:(_vm.requestCollection.note),callback:function ($$v) {_vm.$set(_vm.requestCollection, "note", $$v)},expression:"requestCollection.note"}})],1),_c('div',{staticClass:"container-off-canvas-sm my-6"},[_c('span',{staticClass:"font-headline-lg"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.summary.detailsHeadline'))+" ")]),_c('div',[_c('ValidityAndStatusSection',{attrs:{"validity-range":_vm.getSummary.validityRange,"status":_vm.getSummary.status}}),_c('BaseButton',{staticClass:"mt-2",attrs:{"arrow-right":""},on:{"click":function($event){return _vm.editSection({
                        section: 'validity',
                        routeName: 'checkout-project-disposal-hazardous-validity',
                    })}}},[_vm._v(_vm._s(_vm.$t('pages.checkout.summary.actions.change.positionValidity')))])],1),_c('ConstructionSite',{staticClass:"mt-12",attrs:{"construction-site-address":_vm.getSummary.constructionSite.address,"construction-site-name":_vm.getSummary.constructionSite.name,"client-name":_vm.getSummary.client.name},on:{"edit":_vm.editSection}}),(_vm.getSummary.isHazardous)?_c('GeneralInformation',{staticClass:"mt-12",attrs:{"info":_vm.getSummary.generalInformation,"preview":""},on:{"edit":_vm.editSection}}):_vm._e(),_c('section',{staticClass:"mt-12"},[_c('DisposerCard',{attrs:{"has-title":"","disposer":_vm.getSummary.disposer,"preview":""}}),_c('BaseButton',{staticClass:"mt-2 w-full text-right",attrs:{"arrow-right":""},on:{"click":function($event){return _vm.editSection({
                        section: 'disposer',
                        routeName: 'checkout-project-disposal-hazardous-choose-disposer',
                    })}}},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.disposal.summary.actions.change.disposer'))+" ")])],1),_c('WasteCard',{staticClass:"mt-12",attrs:{"waste":_vm.getSummary.waste,"preview":""},on:{"edit":_vm.editSection}}),_c('WasteDescription',{staticClass:"mt-12",attrs:{"waste":_vm.getSummary.wasteDescription,"is-hazardous":_vm.getSummary.isHazardous,"preview":""},on:{"edit":_vm.editSection}}),_c('AnalysisDocuments',{staticClass:"mt-12",attrs:{"documents":_vm.getSummary.documents.documents,"other-documents":_vm.getSummary.documents.otherDocuments,"images":_vm.getSummary.documents.images,"preview":""},on:{"edit":_vm.editSection}}),_c('CarrierCard',{staticClass:"mt-12",attrs:{"name":_vm.getSummary.carrier.name,"address":_vm.getSummary.carrier.address,"disposal-number":_vm.getSummary.carrier.disposalNumber},on:{"edit":_vm.editSection}}),(_vm.showBillingMethodCard)?_c('BillingMethodCard',{staticClass:"mt-12",attrs:{"billing":_vm.getSummary.billing,"editable":false},on:{"edit":_vm.editSection}}):_vm._e()],1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
    <div>
        <div class="font-copy-sm mb-2 text-subdued">
            {{ $t(`components.usedAddress.type.${item.type || 'default'}`) }}
        </div>
        <div v-if="item.name" class="font-copy-md-strong">{{ item.name }}</div>
        <FormattedAddress v-else :address="item" tag="div" hide-location-code bold />
        <FormattedAddress :address="item" tag="div" decorate-location-code />
    </div>
</template>

<script>
import FormattedAddress from '@/components/FormattedAddress';

export default {
    name: 'UsedAddressItem',

    components: {
        FormattedAddress,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    computed: {
        addressAsTitle() {
            const { street, number } = this.item.address;
            return { street, number };
        },
    },
};
</script>

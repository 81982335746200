<template>
    <div class="radio-card-set" :class="{ 'radio-card-set--with-error': !!error }">
        <div v-if="error" data-test="radio-card-set-error" class="radio-card-set__error mb-2">
            <ErrorMessage :message="error" />
        </div>
        <div class="flex flex-col gap-4 md:flex-row">
            <RadioButtonCard
                v-for="option in options"
                :key="`${option.title}-${option.value}`"
                v-model="valueModel"
                :value="option.value"
                :name="name"
                data-test="radio-card-entry"
                :is-read-only="isReadOnly"
                :is-disabled="isDisabled"
                :title="option.title"
                :description="option.description"
            />
        </div>
    </div>
</template>

<script>
import ErrorMessage from '@/components/Form/ErrorMessage';
import RadioButtonCard from '@/_components/RadioButtonCard/RadioButtonCard';

export default {
    name: 'RadioCardSet',
    components: { ErrorMessage, RadioButtonCard },
    props: {
        value: {
            type: [Number, String, Object, null],
            default: null,
        },
        options: {
            type: Array,
            default: () => [],
        },
        name: {
            type: String,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        valueModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>

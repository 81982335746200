import { useCallback, useEffect, useMemo, useRef, useSyncExternalStore } from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
// Adapted from https://github.com/facebook/react/blob/dd2d6522754f52c70d02c51db25eb7cbd5d1c8eb/packages/use-sync-external-store/src/useSyncExternalStoreWithSelector.js
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useVuex(store, snapshot, selector, isEqual = _isEqual) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const subscribe = useCallback(store.subscribe.bind(store), [store]);
    const getSnapshot = useCallback(() => store[snapshot], [store, snapshot]);
    const instRef = useRef(null);
    if (instRef.current === null) {
        instRef.current = {
            hasValue: false,
            value: null,
        };
    }
    const inst = instRef.current;
    const getSelection = useMemo(() => {
        let hasMemo = false;
        let memoizedSelection;
        const memoizedSelector = (nextSnapshot) => {
            if (!hasMemo) {
                hasMemo = true;
                const nextSelection = _cloneDeep(selector(nextSnapshot));
                if (isEqual !== undefined) {
                    if (inst.hasValue && inst.value !== null) {
                        const currentSelection = inst.value;
                        if (isEqual(currentSelection, nextSelection)) {
                            memoizedSelection = currentSelection;
                            return currentSelection;
                        }
                    }
                }
                memoizedSelection = nextSelection;
                return nextSelection;
            }
            const prevSelection = memoizedSelection;
            const nextSelection = _cloneDeep(selector(nextSnapshot));
            if (isEqual(prevSelection, nextSelection)) {
                return prevSelection;
            }
            memoizedSelection = nextSelection;
            return nextSelection;
        };
        return () => memoizedSelector(getSnapshot());
    }, [selector, isEqual, inst.hasValue, inst.value, getSnapshot]);
    const value = useSyncExternalStore(subscribe, getSelection);
    useEffect(() => {
        inst.hasValue = true;
        inst.value = value;
    }, [inst, value]);
    return value;
}
export function useVuexState(store, selector, isEqual = _isEqual) {
    return useVuex(store, 'state', selector, isEqual);
}
export function useVuexGetter(store, selector, isEqual = _isEqual) {
    return useVuex(store, 'getters', selector, isEqual);
}

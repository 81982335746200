import ExternalCarrier from '@/services/Api/ExternalCarrier';
import { useCan } from '@/utils/composition-helper';
import { useAsyncState } from '@vueuse/core';
import { unref } from 'vue';
export const useExternalCarrierOrganizationNameList = (carrierIdMaybeRef) => {
    const can = useCan().value;
    const { state: externalCarrierOrganizationNameList } = useAsyncState(async () => {
        const carrierId = unref(carrierIdMaybeRef);
        if (!carrierId ||
            !can('listCarrierSubcontractors', {
                id: carrierId,
            }))
            return [];
        return await ExternalCarrier.getExternalCarrierOrganizationNames(carrierId);
    }, []);
    return { externalCarrierOrganizationNameList };
};

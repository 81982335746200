<template>
    <LayoutPage is-flyout :screen-name="screenName" class="checkout-shipment-vehicleClass-page">
        <Header slot="flyoutHeader" />

        <div class="container-deprecated">
            <Words block spaced-top>
                {{ $t('pages.checkout.shipmentVehicleClassPage.intro') }}
            </Words>

            <div data-test="container-shipment-vehicle-class" class="checkout-shipment-vehicleClass-page__list">
                <Hint v-if="showHintNotAvailableVehicleClasses">
                    <i18n path="pages.checkout.shipmentVehicleClassPage.hint" tag="span">
                        <Button place="phoneNumber" :href="`tel:${assemblePhoneNumber(info.phone)}`" small>
                            <Words bold>{{ assemblePhoneNumber(info.phone, true) }}</Words>
                        </Button>
                    </i18n>
                </Hint>

                <TileTrigger
                    v-for="vehicleClass in availableVehicleClasses"
                    :key="vehicleClass.id"
                    spaced-top
                    size="normal"
                    :class="{
                        'checkout-shipment-vehicle-class-page-tile--active': shipmentVehicleClassId === vehicleClass.id,
                    }"
                    class="checkout-shipment-vehicleClass-page__tile"
                    @click="selectVehicleClass(vehicleClass)"
                >
                    <template #left>
                        <VehicleClassIconSet class="h-[40px] w-auto" :icon="vehicleClass.icon" />
                    </template>
                    <div class="checkout-shipment-vehicleClass-page__tile-info-grid">
                        <Words block bold>{{ vehicleClass.name }}</Words>
                        <Words unbreakable>
                            {{ $t('pages.checkout.shipmentVehicleClassPage.payloadLabel') }}
                            {{ vehicleClass.payload | tons }}
                        </Words>
                    </div>
                </TileTrigger>
            </div>
        </div>
    </LayoutPage>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Toaster from '@/services/Toaster';
import VehicleClassApi from '@/services/Api/VehicleClass';
import { assemblePhoneNumber } from '@/services/utils';

import Button from '@/components/Button/Button';
import Header from './components/Header';
import Hint from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import TileTrigger from '@/components/Button/TileTrigger';
import VehicleClassIconSet from '@/components/IconSet/VehicleClassIconSet';
import Words from '@/components/Typography/Words';
import { ORDER_TYPE_CLIENT, ORDER_TYPE_CUSTOM, ORDER_TYPE_PROJECT } from '@/constants/orderTypes';
import { trackCheckoutEvent } from '@/pages/Checkout/trackCheckoutEvent';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';

export default {
    name: 'ShipmentVehicleClassPage',

    components: {
        Button,
        Header,
        Hint,
        LayoutPage,
        TileTrigger,
        VehicleClassIconSet,
        Words,
    },

    setup() {
        return {
            screenName: useOrderScreenName('vehicleclassselection'),
        };
    },

    data() {
        return {
            availableVehicleClasses: [],
            showHintNotAvailableVehicleClasses: false,
        };
    },

    computed: {
        ...mapState('basket', ['shipmentVehicleClassId', 'isCustom', 'projectPosition']),
        ...mapGetters('platform', ['info']),
        ...mapGetters('basket', ['isDisposal']),
    },

    created() {
        this.getAvailableVehicleClasses();
    },

    methods: {
        async getAvailableVehicleClasses() {
            try {
                let params = {
                    type: this.isDisposal ? 'disposal' : 'shipment',
                    isActive: true,
                    sortBy: 'permissibleTotalWeight',
                    sortDirection: 'desc',
                    orderType: this.isCustom ? ORDER_TYPE_CUSTOM : ORDER_TYPE_CLIENT,
                };

                if (this.projectPosition) {
                    params = {
                        ...params,
                        projectPositionId: this.projectPosition.id,
                        orderType: ORDER_TYPE_PROJECT,
                    };
                }

                const { items } = await VehicleClassApi.filter(params);
                this.availableVehicleClasses = items;

                if (this.availableVehicleClasses.length === 0) {
                    this.showHintNotAvailableVehicleClasses = true;
                }
            } catch (error) {
                Toaster.error(error);
            }
        },

        selectVehicleClass(vehicleClass) {
            const vehicleClassIdMutation = this.isDisposal ? 'setDisposalVehicleClassId' : 'setShipmentVehicleClassId';

            this.$store.commit(`basket/${vehicleClassIdMutation}`, vehicleClass.id);
            if (this.isDisposal) {
                this.$store.commit('basket/setTransports', {
                    transports: [
                        {
                            id: vehicleClass.id,
                            icon: vehicleClass.icon,
                            actualLoad: vehicleClass.payload,
                            name: vehicleClass.name,
                            numAxes: vehicleClass.numAxes,
                            payload: vehicleClass.payload,
                            loadPercentage: 100,
                        },
                    ],
                });
            }

            if (!this.isCustom) {
                const eventName = this.isDisposal ? 'disposalVehicleClass' : 'shipmentVehicleClass';
                trackCheckoutEvent(eventName, 'selectVehicleClass', {
                    id: vehicleClass.id,
                    name: vehicleClass.name,
                    numAxes: vehicleClass.numAxes,
                    permissibleTotalWeight: vehicleClass.permissibleTotalWeight,
                });
            }

            this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(() => {});
        },

        assemblePhoneNumber,
    },
};
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.checkout-shipment-vehicleClass-page__list {
    margin-top: 40px;
}

.checkout-shipment-vehicleClass-page__tile-info-grid {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    > *:first-child {
        margin-right: 8px;
    }
}

.checkout-shipment-vehicle-class-page-tile--active {
    border: 2px solid $color-red;
}
</style>

<template>
    <table class="opening-times w-full table-auto border-collapse">
        <tbody>
            <tr v-for="i in range" :key="i">
                <td class="w-2/5" :class="{ 'pt-1': i }">
                    <span class="font-copy-md">{{ $t(`weekdays.${i}`) }}</span>
                </td>
                <td v-if="getOpeningDay(i)" :class="{ 'pt-1': i }">
                    <span class="font-copy-md">
                        {{ getOpeningDay(i).start }} - {{ $t('timeSuffix', { time: getOpeningDay(i).end }) }}
                    </span>
                </td>
                <td v-else :class="{ 'pt-1': i }">
                    <span class="font-copy-md">{{ $t('components.openingTimes.closed') }}</span>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import _find from 'lodash/find';

export default {
    name: 'OpeningTimes',
    props: {
        openingTimes: {
            type: Array,
            required: true,
        },
        todayTop: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        range() {
            const dayRange = [1, 2, 3, 4, 5, 6, 7];
            if (this.todayTop) {
                dayRange.push(...dayRange.splice(0, (new Date().getDay() || 7) - 1));
            }

            return dayRange;
        },
    },
    methods: {
        getOpeningDay(index) {
            return _find(this.openingTimes, { dayOfWeek: index });
        },
    },
};
</script>

<template>
    <div class="data-table__wrapper">
        <table :class="{ 'data-table--clickable': clickable }" class="data-table">
            <thead v-if="$slots.header" class="data-table__thead">
                <slot name="header" />
            </thead>
            <tbody class="data-table__tbody" @mouseover="onMouseover" @mouseout="onMouseout">
                <slot />
            </tbody>
            <tfoot v-if="$slots.footer" class="data-table__tfoot">
                <slot name="footer" />
            </tfoot>
        </table>
    </div>
</template>

<script>
import _find from 'lodash/find';

export default {
    name: 'DataTable',
    props: {
        clickable: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onMouseover(e) {
            const tr = _find(e.path, { nodeName: 'TR' });

            if (
                !tr ||
                (tr && tr.classList.contains('data-table__section-heading')) ||
                (tr && tr.classList.contains('data-table__row--disabled-highlight'))
            ) {
                return;
            }

            const prevSibling = this.getPrevSibling(tr);
            const nextSibling = this.getNextSibling(tr);

            tr.classList && tr.classList.add('data-table__curr-row');
            prevSibling && prevSibling.classList.add('data-table__prev-row');
            nextSibling && nextSibling.classList.add('data-table__next-row');
        },
        onMouseout(e) {
            const tr = _find(e.path, { nodeName: 'TR' });

            if (!tr) return;

            const prevSibling = this.getPrevSibling(tr);
            const nextSibling = this.getNextSibling(tr);

            tr.classList && tr.classList.remove('data-table__curr-row');
            prevSibling && prevSibling.classList.remove('data-table__prev-row');
            nextSibling && nextSibling.classList.remove('data-table__next-row');
        },

        getPrevSibling(node) {
            const sibling = node.previousSibling;
            if (sibling && ['#text', '#comment'].includes(sibling.nodeName)) {
                return this.getPrevSibling(sibling);
            }
            return sibling;
        },
        getNextSibling(node) {
            const sibling = node.nextSibling;
            if (sibling && ['#text', '#comment'].includes(sibling.nodeName)) {
                return this.getNextSibling(sibling);
            }
            return sibling;
        },
    },
};
</script>

<style lang="scss" scoped>
.data-table__wrapper {
    overflow-y: hidden;
    overflow-x: scroll;
}

.data-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 0 none;
    background: $color-white;

    .status--active {
        color: $color-green;
    }

    .status--inactive {
        color: $color-darkGrey;
    }

    td,
    th {
        border: 0;
        border-bottom: 3px solid $color-lightGrey;
        background: transparent;
        font-size: inherit;
        margin: 0;
        overflow: visible;
        padding: 8px 16px;

        &:first-child {
            border-left-width: 0;
        }

        &--left {
            text-align: left;
        }

        &--center {
            text-align: center;
        }

        &--right {
            text-align: right;
        }

        &.has-error {
            background-color: rgba($color-red, 0.5);
        }
    }
}

.data-table__thead {
    // Not working in Safari and Edge
    // box-shadow: $boxShadow-bottom;

    th {
        background-color: $color-lightGrey-400;
        border: 0 none;
        color: $color-black;
        text-align: left;
        font-size: 10px;
        vertical-align: middle;
    }
}

.data-table__tbody > tr:last-child > td {
    border-bottom-width: 0;
}

.data-table__thead + .data-table__tbody > tr:first-child td,
.data-table__thead + .data-table__tbody > tr:first-child th {
}

.data-table__thead + .data-table__tbody > tr:not(.data-table__section-heading).data-table__prev-row:first-child td,
.data-table__thead + .data-table__tbody > tr:not(.data-table__section-heading).data-table__prev-row:first-child th {
    box-shadow:
        inset 0 20px 20px -22px rgba($color-black, 0.2),
        inset 0 -20px 20px -22px rgba($color-black, 0.2);
}

.data-table__tbody > tr > th,
.data-table__section-heading > th {
    background-color: $color-mediumGrey;
    text-align: left;
    font-weight: $font-weight-regular;
    padding: 30px 15px 15px;
    border-color: $color-mediumGrey;
}

.data-table__section-heading + tr > td {
    box-shadow: inset 0 20px 20px -22px rgba($color-black, 0.2);
}

.data-table__section-heading + tr.data-table__prev-row > td {
    box-shadow:
        inset 0 20px 20px -22px rgba($color-black, 0.2),
        inset 0 -20px 20px -22px rgba($color-black, 0.2);
}

.data-table--clickable {
    tbody > tr {
        cursor: pointer;
    }
}

.data-table__section-heading--pointless > th,
.data-table__section-heading--pointless > td {
    cursor: default;
}

.data-table__prev-row {
    background: $color-white;

    td {
        box-shadow: inset 0 -20px 20px -22px rgba($color-black, 0.2);
        border-bottom: 3px solid $color-white;
    }
}

.data-table__curr-row {
    td {
        border-bottom: 3px solid $color-white;
    }
}

.data-table__next-row {
    background: $color-white;

    td {
        box-shadow: inset 0 20px 20px -22px rgba($color-black, 0.2);
    }
}
</style>

import AbstractResource from '../AbstractResource';

class Impersonation extends AbstractResource {
    async impersonate(userId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/impersonate/${userId}`);
            return response.data.token;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async stopImpersonation() {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/unimpersonate`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new Impersonation('/impersonation');

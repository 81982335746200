<template>
    <Flyout
        screen-name="useraccount-userlink"
        :active="active"
        class="user-link-form"
        size="small"
        :headline="$t('pages.userLinkForm.headline')"
        @closed="close()"
    >
        <Tile no-border transparent no-padding-bottom>
            <Words block>{{ $t('pages.userLinkForm.description') }}</Words>
        </Tile>

        <form @submit.prevent="submit">
            <Tile no-border transparent no-padding-bottom>
                <TextField
                    v-model.trim="username"
                    :label="$t('pages.userLinkForm.usernameLabel')"
                    :has-error="hasErrors('username')"
                    :error="getError('username')"
                    type="text"
                >
                    <AvatarIcon slot="icon" width="18" height="18" />
                </TextField>
            </Tile>

            <Tile no-border transparent no-padding-bottom>
                <TextField
                    v-model.trim="password"
                    :label="$t('pages.userLinkForm.passwordLabel')"
                    :has-error="hasErrors('password')"
                    :error="getError('password')"
                    type="password"
                >
                    <LockIcon slot="icon" width="18" height="18" />
                </TextField>
            </Tile>

            <Tile no-border transparent no-padding-bottom>
                <ErrorMessage v-if="errorMessage" :message="$t(errorMessage)" dark />
            </Tile>
        </form>

        <Tile no-border transparent>
            <Hint transparent spaceless>
                <Words red bold>{{ $t('pages.userLinkForm.notice.prefix') }}</Words>
                {{ $t('pages.userLinkForm.notice.text') }}
            </Hint>
        </Tile>

        <ButtonGroup slot="bottom">
            <Button :disabled="isPending" primary @click="submit">
                {{ $t('pages.userLinkForm.submit') }}
                <ArrowRightIcon slot="right" class="icon--inline" />
            </Button>
        </ButtonGroup>
    </Flyout>
</template>

<script>
import _get from 'lodash/get';
import UserLinkApi from '@/services/Api/UserLink';
import Toaster from '@/services/Toaster';
import validate from '@/services/validation/mixin';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { isRequired } from '@/services/validation/rules';

import Flyout from '@/components/Layout/Flyout';
import Words from '@/components/Typography/Words';
import TextField from '@/components/Form/TextField.v2';
import Hint from '@/components/Typography/Hint';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Button from '@/components/Button/Button';
import Tile from '@/components/Layout/Tile';
import ErrorMessage from '@/components/Form/ErrorMessage';

import AvatarIcon from '@/assets/icons/micro/avatar.svg';
import LockIcon from '@/assets/icons/micro/lock.svg';
import ArrowRightIcon from '@/assets/icons/micro/arrow.svg';

export default {
    name: 'UserLinkForm',
    components: {
        Flyout,
        Words,
        TextField,
        Hint,
        ButtonGroup,
        Button,
        Tile,
        ErrorMessage,
        AvatarIcon,
        LockIcon,
        ArrowRightIcon,
    },
    mixins: [validate, eventHubMixin],
    props: {
        user: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            isPending: false,
            active: false,
            username: null,
            password: null,
            errorMessage: null,
            validationRules: {
                username: [isRequired('pages.userLinkForm.validation.usernameIsRequired')],
                password: [isRequired('pages.userLinkForm.validation.passwordIsRequired')],
            },
        };
    },
    created() {
        this.subscribe('userLinkAdd', () => {
            this.active = true;
        });

        this.subscribe('userLinkUpdate', username => {
            this.username = username;
            this.active = true;
        });
    },
    mounted() {
        if (this.user && this.user.username) {
            this.username = this.user.username;
        }
    },
    methods: {
        close() {
            this.active = false;
            this.username = null;
            this.password = null;
            this.errorMessage = null;
            this.clearValidation();
        },

        async submit() {
            if (!this.isValid() || this.isPending) return;

            this.isPending = true;

            try {
                const { targetUser: user } = await UserLinkApi.addLink(this.username, this.password);
                this.$store.dispatch('user/updateUserLinks');

                this.close();
                Toaster.success(this.$t('pages.userLinkForm.success', { username: user.username }));
            } catch (err) {
                this.parseServerValidation(_get(err, 'response.data.errors', {}), {
                    username: 'username',
                    password: 'password',
                });

                if (!this.hasErrors()) {
                    this.errorMessage = err.message;
                }
            }

            this.isPending = false;
        },
    },
};
</script>

<style lang="scss"></style>

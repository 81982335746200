import React from 'react';
export const ConstructionProjectsIcon = ({ className }) => (<svg width="108" height="96" viewBox="0 0 108 96" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M30.035 54.5851L21.305 57.4051L8.08496 16.4851L41.395 5.7251L46.585 21.7851" stroke="#35343D" strokeWidth="2.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.5449 22.8249L30.4449 18.9849" stroke="#35343D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M35.6749 17.2949L36.6249 16.9849" stroke="#35343D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M37.515 23.0053L38.465 22.6953" stroke="#35343D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.385 28.5353L32.285 24.6953" stroke="#35343D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.235 34.2453L34.135 30.4053" stroke="#35343D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M24.075 39.9552L34.415 36.6152" stroke="#35343D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M59.5549 18.7749L62.3749 9.73486L95.9149 19.7249L83.6349 60.9349L78.7849 59.3749" stroke="#35343D" strokeWidth="2.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M67.3949 20.875L79.3749 24.445" stroke="#35343D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M84.6449 26.0146L85.6049 26.3046" stroke="#35343D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M82.9349 31.7646L83.8949 32.0546" stroke="#35343D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M81.215 37.5146L82.175 37.8046" stroke="#35343D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M79.5049 43.2646L80.4649 43.5546" stroke="#35343D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M69.6349 27.8047L77.6649 30.1947" stroke="#35343D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M70.1849 34.2251L75.9549 35.9451" stroke="#35343D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M71.0149 40.7349L74.2349 41.6949" stroke="#35343D" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M37.085 58.2449L34.085 28.2149L68.915 24.7349L73.005 65.6849" stroke="#FF0000" strokeWidth="2.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M42.965 36.615L55.405 35.375" stroke="#FF0000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M60.875 34.8251L61.865 34.7251" stroke="#FF0000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M61.475 40.7948L62.465 40.6948" stroke="#FF0000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M62.075 46.765L63.075 46.665" stroke="#FF0000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M62.6649 52.7348L63.6649 52.6348" stroke="#FF0000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M43.5649 42.5847L56.0049 41.3447" stroke="#FF0000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M44.1649 48.5549L56.6049 47.3149" stroke="#FF0000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M44.755 54.5247L57.195 53.2847" stroke="#FF0000" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.71497 57.7251V66.9851" stroke="#FF0000" strokeWidth="2.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.08496 62.355H12.355" stroke="#FF0000" strokeWidth="2.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M105.915 33.0049L99.365 39.5549" stroke="#35343D" strokeWidth="2.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M99.365 33.0049L105.915 39.5549" stroke="#35343D" strokeWidth="2.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.80496 7.1751C6.30496 7.1751 7.52496 5.9551 7.52496 4.4551C7.52496 2.9551 6.31496 1.7251 4.80496 1.7251C3.29496 1.7251 2.08496 2.9451 2.08496 4.4451C2.08496 5.9451 3.30496 7.1651 4.80496 7.1651V7.1751Z" stroke="#FF0000" strokeWidth="2.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M51.815 19.1651C53.315 19.1651 54.535 17.9451 54.535 16.4451C54.535 14.9451 53.315 13.7251 51.815 13.7251C50.315 13.7251 49.095 14.9451 49.095 16.4451C49.095 17.9451 50.315 19.1651 51.815 19.1651Z" stroke="#35343D" strokeWidth="2.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M92.815 58.1749C94.315 58.1749 95.535 56.9549 95.535 55.4549C95.535 53.9549 94.315 52.7349 92.815 52.7349C91.315 52.7349 90.095 53.9549 90.095 55.4549C90.095 56.9549 91.315 58.1749 92.815 58.1749Z" stroke="#FF0000" strokeWidth="2.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M42.635 79.7349V73.7349H61.635V79.7349H42.635Z" stroke="#35343D" strokeWidth="2.33" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M61.085 94.2651H18.135V65.7251H86.135V94.2651H80.085" stroke="#35343D" strokeWidth="2.33" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>);

<template>
    <div class="content-imprint">
        <div class="headlines">
            <h1>{{ $t('components.imprint.headline') }}</h1>
        </div>
        <p>
            <strong>Schüttflix GmbH</strong><br />
            Kaiserstraße 24<br />
            33330 Gütersloh<br />
            {{ $t('components.imprint.phoneAbbr') }}: +49 5241 60130-0<br />
            {{ $t('components.imprint.email') }}: service@schuettflix.de<br />
            {{ $t('components.imprint.website') }}: <a href="https://schuettflix.de">schuettflix.de</a><br />
        </p>
        <p>
            <strong>{{ $t('components.imprint.managingDirector') }}:</strong><br />
            Christian Hülsewig<br />
            Nils Klose
        </p>
        <p>
            <strong>{{ $t('components.imprint.companyHeadquarter') }}</strong
            ><br />
            AG Gütersloh HRB-Nr. 11151<br />
            {{ $t('components.imprint.vatIdAbbr') }}: DE 319 494 364<br />
        </p>
        <p>
            <strong>{{ $t('components.imprint.contentResponsible') }}:</strong><br />
            Christian Hülsewig<br />
            Kaiserstraße 24<br />
            33330 Gütersloh<br />
            {{ $t('components.imprint.phoneAbbr') }}: +49 5241 60130-0<br />
            {{ $t('components.imprint.email') }}: service@schuettflix.de<br />
        </p>
        <i18n path="components.imprint.onlineDisputeResolution" tag="p">
            <a href="http://ec.europa.eu/consumers/odr" target="_blank">http://ec.europa.eu/consumers/odr</a>
        </i18n>
    </div>
</template>

<script>
export default {
    name: 'ImprintContent',
};
</script>

<style lang="scss">
.content-imprint {
    ul {
        list-style: none;
        padding: 0;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    li {
        padding-left: 15px;
        position: relative;
        margin-bottom: 15px;
    }

    li:before {
        content: '+';
        color: $color-red;
        position: absolute;
        left: 0;
    }
}
</style>

import Transport from '@/services/Api/Transport';
function makeCaptureDeliveryTransport(api) {
    return async (transportId, payload) => {
        return await api.captureDelivery(transportId, payload);
    };
}
function makeCaptureShipmentTransport(api) {
    return async (transportId, payload) => {
        return await api.captureShipment(transportId, payload);
    };
}
export function makeCaptureTransportService(context) {
    const transportApi = new Transport(context);
    return {
        captureDeliveryTransport: makeCaptureDeliveryTransport(transportApi),
        captureShipmentTransport: makeCaptureShipmentTransport(transportApi),
    };
}

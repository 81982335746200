<template>
    <DialogModalBase>
        <template #content>
            <Words large line-height-large bold block spaced-bottom>{{
                $t('components.factoryManagement.factoryLocationType.modal.locationType.title')
            }}</Words>
            <Words
                block
                line-height-small
                spaced-bottom-large
                v-html="$t('components.factoryManagement.factoryLocationType.modal.locationType.description')"
            />
            <div class="more-link">
                {{ $t('components.factoryManagement.factoryLocationType.modal.locationType.moreInfo') }}
                <ButtonLink
                    :title="$t('components.factoryManagement.factoryLocationType.modal.locationType.moreInfoLinkText')"
                    link="https://support.schuettflix.de/de/knowledge/was-ist-der-unterschied-zwischen-anlage-deponie-und-kippstelle"
                    has-arrow-right
                    target-blank
                />
            </div>
        </template>
    </DialogModalBase>
</template>

<script>
import ButtonLink from '@/_components/ButtonLink/ButtonLink';
import DialogModalBase from '@/_components/DialogModal/DialogModalBase';
import Words from '@/components/Typography/Words';

export default {
    name: 'HelpLocationType',
    components: {
        DialogModalBase,
        Words,
        ButtonLink,
    },
};
</script>

<style lang="scss" scoped>
.more-link {
    display: flex;
    margin-top: 15px;
    gap: 4px;
}
</style>

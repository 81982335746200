import i18n from '@/i18n';

export default function (value, precision = null) {
    let val = parseFloat(value) / 1000;

    if (precision !== null) {
        val = val.toFixed(precision);
    }

    return `${i18n.n(val, 'float')} km`;
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"address-progress relative h-auto place-self-start"},[_c('ul',{ref:"listReference",class:{
            'm-0': _vm.spaceless,
        }},_vm._l((_vm.computedList),function(item,i){return _c('li',{key:i,class:_vm.computedItemClasses,style:(`padding-left: ${_vm.orbPadding}px`)},[_c('div',{staticClass:"pt-px",class:_vm.additionalItemClasses,on:{"click":function($event){return _vm.copyToClipboard(item.original)}}},[_c('span',{staticClass:"font-copy-md-strong"},[_vm._v(_vm._s(item.headline))]),(item.subline)?_c('span',{staticClass:"font-copy-md text-subdued"},[_vm._v(_vm._s(item.subline))]):_vm._e(),(item.address)?_c('FormattedAddress',{staticClass:"mt-1",attrs:{"address":item.address,"hide-additional-address":"","hide-location-code":"","subdued":"","small":""}}):_vm._e(),_vm._t("default",null,{"item":item,"isFirst":0 === i,"isLast":_vm.list.length - 1 === i,"index":i})],2)])}),0),_c('div',{ref:"progressBarReference",class:[
            'absolute bottom-4 left-[7px] top-4 w-[10px]',
            'border-l border-l-4 border-l-white',
            'border-r border-r-4 border-r-white',
        ]},[_c('div',{class:[
                'absolute inset-0 w-1',
                'border-l border-l-2 border-dotted ',
                {
                    'border-l-active': !_vm.muted,
                    'border-l-disabled': _vm.muted,
                },
            ]}),_c('div',{class:_vm.computedProgressClasses,style:(`height: ${_vm.progress}%`)})])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
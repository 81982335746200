var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-field",class:{
        'text-field--invalid': _vm.error || _vm.hasError,
        'text-field--focused': _vm.isFocused,
        'text-field--filled': _vm.hasValue || _vm.ghost,
        'text-field--dark': _vm.dark,
        'text-field--small': _vm.small,
        'text-field--tiny': _vm.tiny,
        'text-field--ghost': _vm.ghost,
        'text-field--no-label': !_vm.label,
        'text-field--theme-dark': _vm.isDarkTheme,
        'text-field--inline-label': _vm.label && _vm.inlineLabel,
        'text-field--hidden': _vm.type === 'hidden',
    },attrs:{"data-test":_vm.dataTest}},[(_vm.type === 'textarea')?_c('textarea',{staticClass:"text-field__textarea",class:{
            'text-field__input--filled': _vm.hasValue,
            'text-field__input--iconized': _vm.$slots.icon,
            'text-field__input--style-uppercase': _vm.styledUppercase,
            'text-field__input--disabled': _vm.disabled,
            'text-field__input--readonly': _vm.readonly,
        },attrs:{"id":_vm.eid,"name":_vm.ename,"placeholder":_vm.label,"rows":_vm.rows,"disabled":_vm.disabled || _vm.readonly,"minlength":_vm.minLength,"maxlength":_vm.maxLength},domProps:{"value":_vm.value},on:{"keyup":_vm.updateValue,"change":_vm.updateValue,"blur":function($event){return _vm.$emit('blur', $event)},"focus":function($event){return _vm.$emit('focus', $event)},"keypress":function($event){return _vm.$emit('keypress', $event)},"keydown":function($event){return _vm.$emit('keydown', $event)}}}):_c('div',{staticClass:"text-field__input-group",class:{ 'text-field__input-group--no-border': _vm.noBorder }},[_c('input',_vm._b({staticClass:"text-field__input",class:{
                'text-field__input--filled': _vm.hasValue,
                'text-field__input--iconized': _vm.$slots.icon,
                'text-field__input--style-uppercase': _vm.styledUppercase,
                'text-field__input--disabled': _vm.disabled,
                'text-field__input--readonly': _vm.readonly,
                'text-field__input--type-number': _vm.type === 'number',
                'text-field__input--text-align-right': _vm.inputTextAlignRight,
            },attrs:{"id":_vm.eid,"name":_vm.ename,"type":_vm.type,"data-test":`${_vm.dataTest}-input`,"disabled":_vm.disabled || _vm.readonly,"readonly":_vm.ghost,"step":_vm.step,"autocomplete":_vm.autocomplete ? 'on' : 'off',"minlength":_vm.minLength,"maxlength":_vm.maxLength},domProps:{"value":_vm.convertedValue},on:{"keyup":function($event){return _vm.updateValue($event)},"change":_vm.updateValue,"blur":_vm.blured,"focus":_vm.focused,"keypress":function($event){return _vm.$emit('keypress', $event)},"keydown":_vm.handleKeyDown,"wheel":function($event){return $event.target.blur()}}},'input',_vm.onlyNaturalNumber ? { pattern: '\d*' } : {},false)),(_vm.suffix)?_c('span',{staticClass:"text-field__input--suffix",class:{ focused: _vm.isFocused || _vm.hasValue }},[_vm._v(" "+_vm._s(_vm.suffix)+" ")]):_vm._e(),(_vm.label)?_c('label',{staticClass:"text-field__label",attrs:{"data-test":"label","for":_vm.eid}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.labelFootnote)?_c('sup',{attrs:{"data-test":"label-footnote"}},[_vm._v(_vm._s(_vm.labelFootnote))]):_vm._e()]):_vm._e(),(_vm.$slots.action)?_c('div',{staticClass:"text-field__slot text-field__slot--action"},[_vm._t("action")],2):_vm._e(),(_vm.$slots.icon)?_c('div',{staticClass:"text-field__icon"},[_vm._t("icon")],2):_vm._e(),(_vm.hasValue && !_vm.$slots.action && !_vm.readonly && !_vm.disabled && !_vm.tiny)?_c('BaseButton',{staticClass:"text-field__reset",attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.clearValue()}}},[_c('ResetIcon',{attrs:{"width":"13"}})],1):_vm._e()],1),(_vm.error)?_c('ErrorMessage',{attrs:{"message":_vm.error,"dark":_vm.dark,"is-small":_vm.isSmallErrorMessage,"data-test":`${_vm.dataTest}-error`}}):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"font-copy-md-strong m-0 mb-2 lg:font-copy-lg-strong"},[_vm._v(" "+_vm._s(_vm.$t('pages.homeV2.documentsTitle'))+" ")]),_c('p',{staticClass:"font-copy-sm mb-[12px] lg:font-copy-md lg:mb-6"},[_vm._v(" "+_vm._s(_vm.$t('pages.homeV2.documentsDescription'))+" ")]),_c('div',{staticClass:"grid gap-x-[20px] gap-y-[20px] lg:grid-cols-[1fr,1fr,1fr,1fr]"},[(_vm.invoiceCount)?_c('StatusCard',{attrs:{"icon":"InvoiceIcon","title":_vm.$t('pages.homeV2.invoiceCardTitle'),"description":_vm.$tc('pages.homeV2.invoiceCardDescription', _vm.invoiceCount),"count":_vm.invoiceCount},on:{"click":function($event){return _vm.goToInvoiceListPage()}}}):_vm._e(),(_vm.creditNoteCount)?_c('StatusCard',{attrs:{"icon":"CreditIcon","title":_vm.$t('pages.homeV2.creditNoteCardTitle'),"description":_vm.$tc(
                    _vm.selectCreditNoteDocKey({
                        creditNote: `pages.homeV2.creditNoteDocCardDescription.creditNote`,
                        selfBilling: `pages.homeV2.creditNoteDocCardDescription.selfBilling`,
                    }),
                    _vm.creditNoteCount
                ),"count":_vm.creditNoteCount},on:{"click":function($event){return _vm.goToCreditNotePage()}}}):_vm._e()],1),(!_vm.invoiceCount && !_vm.creditNoteCount)?_c('EmptyState',{staticClass:"mb-4",attrs:{"icon":"DocumentEmptyIcon","text":_vm.$t('pages.homeV2.noNewDocuments')}}):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
import { assemblePhoneNumber } from '@/services/utils';

/**
 * Class representing phone number view model
 */
export default class PhoneNumberView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     * @return {PhoneNumberView|null}
     */
    static create(data) {
        if (!data) return null;
        const phoneNumber = new PhoneNumberView();

        phoneNumber.countryCode = data.countryCode;
        phoneNumber.number = data.number;

        return phoneNumber;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._countryCode = null;
        this._number = null;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            countryCode: this.countryCode,
            number: this.number,
        };
    }

    /**
     * Clone the current view
     * @return {PhoneNumberView}
     */
    clone() {
        return PhoneNumberView.create(this.unfold());
    }

    /**
     * Get countryCode
     * @returns {string}
     */
    get countryCode() {
        return this._countryCode;
    }

    /**
     * Set countryCode
     * @param {string} value
     */
    set countryCode(value) {
        this._countryCode = value;
    }

    /**
     * Get number
     * @returns {string}
     */
    get number() {
        return this._number;
    }

    /**
     * Set number
     * @param {string} value
     */
    set number(value) {
        this._number = value;
    }

    get formattedPhoneNumber() {
        return assemblePhoneNumber(this, true);
    }

    toString() {
        return assemblePhoneNumber(this);
    }
}

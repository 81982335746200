<template>
    <div class="debug-dump">
        <PrintObject :printable-object="data" />
    </div>
</template>

<script>
import PrintObject from 'vue-print-object';
import 'vue-print-object/dist/vue-print-object.css';

export default {
    name: 'Dump',
    components: {
        PrintObject,
    },
    props: {
        data: {
            type: [Object, Array],
            default: () => {
                return {};
            },
        },
    },
};
</script>

<style lang="scss">
.debug-dump {
    margin: 20px auto;
    border: 5px solid #eee;
    overflow: scroll;
}
</style>

import LogService from '@schuettflix/util-log';
import WasteProduct from '@/services/Api/Waste/WasteProduct';

const Log = new LogService('store/platform');

const initialState = {
    wasteStates: [],
};

const getters = {
    getWasteStates: state => state.wasteStates,
};

const mutations = {
    SET_WASTE_STATES(state, states) {
        state.wasteStates = states;
    },
};

const actions = {
    async fetchWasteProductVariantStates({ commit }, { productId }) {
        try {
            if (productId) {
                const product = await WasteProduct.getOneById({ productId });
                commit('SET_WASTE_STATES', product.states);
            }
        } catch (err) {
            Log.log(err);
        }
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

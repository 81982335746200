<template>
    <div class="avatar-upload-editor">
        <ModalBox ref="cropperModal" @close="destroy">
            <div class="avatar-upload-editor__cropper-image">
                <img
                    v-if="image"
                    :key="imageKey"
                    ref="img"
                    :src="blobToImageUrl(image)"
                    alt=""
                    @load.stop="createCropper"
                />
            </div>
            <ButtonGroup slot="actions" inline>
                <Button primary light block @click="$refs.cropperModal.$emit('close')">
                    {{ $t('components.avatarUpload.cancelButton') }}
                </Button>
                <Button primary block @click="cropImage">
                    {{ $t('components.avatarUpload.saveButton') }}
                </Button>
            </ButtonGroup>
        </ModalBox>
    </div>
</template>

<script>
import 'cropperjs/dist/cropper.css';

import Cropper from 'cropperjs';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { dataURItoBlob } from '@/services/utils';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ModalBox from '@/components/Modal/ModalBox';

export default {
    name: 'AvatarUploadEditor',
    components: {
        Button,
        ButtonGroup,
        ModalBox,
    },
    mixins: [eventHubMixin],
    data() {
        return {
            image: null,
            triggerId: null,
            cropper: null,
            imageKey: null,
        };
    },
    created() {
        this.subscribe('avatar-editor-open', ({ triggerId, blob }) => {
            this.open(triggerId, blob);
        });

        this.subscribe('avatar-editor-close', () => {
            this.destroy();
            this.$refs.cropperModal.$emit('close');
        });
    },
    methods: {
        blobToImageUrl(blob) {
            return URL.createObjectURL(blob);
        },
        destroy() {
            if (this.cropper) {
                this.cropper.destroy();
            }
            this.cropper = null;
            this.image = null;
            this.imageKey = null;
            this.triggerId = null;
        },
        open(triggerId, blob) {
            this.triggerId = triggerId;
            this.image = blob;
            this.imageKey = Date.now();
            this.$refs.cropperModal && this.$refs.cropperModal.$emit('open');
        },
        createCropper() {
            this.cropper = new Cropper(this.$refs.img, {
                aspectRatio: 1,
                autoCropArea: 1,
                viewMode: 1,
                movable: false,
                zoomable: false,
            });
        },
        cropImage() {
            const imageDataURL = this.cropper.getCroppedCanvas({ width: 512, height: 512 }).toDataURL();
            const blob = dataURItoBlob(imageDataURL);

            this.$eventHub.$emit('avatar-editor-upload', {
                triggerId: this.triggerId,
                blob,
            });

            this.destroy();
            this.$refs.cropperModal.$emit('close');
        },
    },
};
</script>

<style lang="scss">
.avatar-upload-editor {
    .modal-box__box {
        max-width: 400px;
    }
}

.avatar-upload-editor__cropper-image {
    max-width: 400px;
    height: 300px;

    img {
        max-width: 100%;
        height: 100%;
    }
}
</style>

/**
 * @deprecated Use the formatAddress hook from /src/utils/useAddressFormatter instead.
 * Format Address function for use within the ConstructionProject module meant to convert the address building blocks into a readable format.
 */
export function formatAddress(address) {
    return [
        [address.street, address.streetNumber].filter(Boolean).join(' '),
        [address.zipCode, address.city].filter(Boolean).join(' '),
        address.country,
    ]
        .filter(Boolean)
        .join(', ');
}

<template>
    <DialogModalBase>
        <template #content>
            <Words large line-height-large bold block spaced-bottom
                >{{ $t('components.factoryManagement.factoryLocationType.modal.fileUploader.title') }}
            </Words>
            <Words
                block
                line-height-small
                v-html="$t('components.factoryManagement.factoryLocationType.modal.fileUploader.description')"
            />
        </template>
    </DialogModalBase>
</template>
<script>
import DialogModalBase from '@/_components/DialogModal/DialogModalBase';
import Words from '@/components/Typography/Words';

export default {
    name: 'HelpFileUploader',
    components: {
        DialogModalBase,
        Words,
    },
};
</script>

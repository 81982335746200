<template>
    <div v-if="hasVariant" :class="['supplier-waster-product-variant']">
        <div :class="['row', { 'row--not-available': !pollutionType.isActive }]">
            <Words bold block class="title">{{ pollutionType.label }}</Words>

            <TextField
                v-model.number="variant.pricePerTon"
                small
                inline-label
                type="number"
                step="0.01"
                class="tf"
                :label="getCurrencySign()"
                :precision="2"
                @input="
                    $emit('update', {
                        id: variant.id,
                        key: 'pricePerTon',
                        value: $event,
                        type: 'number',
                    })
                "
            />

            <TextField
                v-model.number="variant.wasteCapacityPerDayInTon"
                small
                inline-label
                type="number"
                step="0.01"
                class="tf"
                label="t"
                :precision="2"
                @input="
                    $emit('update', {
                        id: variant.id,
                        key: 'wasteCapacityPerDayInTon',
                        value: $event,
                        type: 'number',
                    })
                "
            />

            <div class="cell4">
                <PopUp class="pop-up-info" variant="fixed-width-220" position="top" arrow-size="small">
                    <InfoIcon :width="14" :height="14" class="icon--inline" />
                    <div slot="content">
                        <Words tiny block class="info-words">
                            {{ $t(`${i18nKey}.hint`) }}
                        </Words>
                    </div>
                </PopUp>
                <Words ellipsis unbreakable tiny class="info-words">
                    {{ tonPerWeek }} {{ $t(`${i18nKey}.tonPerWeek`) }}
                </Words>
            </div>
            <div v-if="variant.note" class="cell5 cell5--edit">
                <Words ellipsis unbreakable small class="note">
                    {{ variant.note }}
                </Words>
                <Button small icon @click="$emit('update-note', { id: variant.id, note: variant.note })">
                    <EditIcon width="20" height="20" class="icon--inline edit-icon" />
                </Button>
            </div>
            <div v-else class="cell5">
                <Button
                    arrow-right
                    small
                    class="add-note-btn"
                    @click="$emit('update-note', { id: variant.id, note: variant.note })"
                >
                    <Words>
                        {{ $t(`${i18nKey}.addNote`) }}
                    </Words>
                </Button>
            </div>

            <div class="cell6">
                <ToggleSwitchField
                    v-model="variant.isAvailable"
                    small
                    @input="value => $emit('update', { id: variant.id, key: 'isAvailable', value, type: 'boolean' })"
                >
                    <Words small bold unbreakable>
                        {{ $t(`${i18nKey}.switchLabel`) }}
                    </Words>
                </ToggleSwitchField>

                <Button class="delete-button" icon @click="$emit('delete', { id: variant.id })">
                    <DeleteIcon width="20" height="20" class="icon--inline delete-icon" />
                </Button>
            </div>
        </div>
        <div v-if="!pollutionType.isActive" class="row row--not-available-after">
            <Words yellow>
                <WarningInfoIcon class="icon--inline warning-info-icon" />
                {{ $t(`${i18nKey}.notAvailable`) }}
            </Words>
        </div>
    </div>
</template>

<script>
import Button from '@/components/Button/Button';
import PopUp from '@/_components/PopUp/PopUp';
import TextField from '@/components/Form/TextField.v2';
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import Words from '@/components/Typography/Words';

import DeleteIcon from '@/assets/icons/regular/_garbage.svg';
import EditIcon from '@/assets/icons/regular/_pen.svg';
import InfoIcon from '@/assets/icons/regular/_info.svg';
import WarningInfoIcon from '@/assets/icons/micro/warning-info.svg';
import { preciseFloat } from '@/services/utils';

import { getCurrencySign } from '@/services/utils/currency';

export default {
    name: 'SupplierWasteProductDetailsVariant',
    components: {
        Button,
        PopUp,
        TextField,
        ToggleSwitchField,
        Words,

        DeleteIcon,
        EditIcon,
        InfoIcon,
        WarningInfoIcon,
    },

    props: {
        i18nKey: {
            type: String,
            default: 'pages.products.supplierProductsList.wasteProductDetails',
        },
        variant: {
            type: Object,
            default: () => ({}),
        },
        pollutionTypes: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        hasVariant() {
            return !!Object.keys(this.variant).length;
        },
        tonPerWeek() {
            const number = preciseFloat((this.variant.wasteCapacityPerDayInTon || 0) * 5, 2);
            return this.$n(number);
        },
        pollutionType() {
            return this.pollutionTypes.find(pollution => pollution.id === this.variant.pollutionTypeId);
        },
        isVariantPollutionTypeActive() {
            return this.pollutionType?.isActive || false;
        },
    },

    methods: {
        getCurrencySign,
    },
};
</script>

<style lang="scss" scoped src="./SupplierWasteProductDetailsVariant.scss" />

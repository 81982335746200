<template>
    <div
        :class="{
            'text-field--invalid': error || hasError,
            'text-field--focused': isFocused,
            'text-field--filled': hasValue || ghost,
            'text-field--dark': dark,
            'text-field--small': small,
            'text-field--tiny': tiny,
            'text-field--ghost': ghost,
            'text-field--no-label': !label,
            'text-field--theme-dark': isDarkTheme,
            'text-field--inline-label': label && inlineLabel,
            'text-field--hidden': type === 'hidden',
        }"
        class="text-field"
        :data-test="dataTest"
    >
        <textarea
            v-if="type === 'textarea'"
            :id="eid"
            :name="ename"
            :placeholder="label"
            :rows="rows"
            :value="value"
            :disabled="disabled || readonly"
            :minlength="minLength"
            :maxlength="maxLength"
            :class="{
                'text-field__input--filled': hasValue,
                'text-field__input--iconized': $slots.icon,
                'text-field__input--style-uppercase': styledUppercase,
                'text-field__input--disabled': disabled,
                'text-field__input--readonly': readonly,
            }"
            class="text-field__textarea"
            @keyup="updateValue"
            @change="updateValue"
            @blur="$emit('blur', $event)"
            @focus="$emit('focus', $event)"
            @keypress="$emit('keypress', $event)"
            @keydown="$emit('keydown', $event)"
        />
        <div v-else class="text-field__input-group" :class="{ 'text-field__input-group--no-border': noBorder }">
            <input
                :id="eid"
                :name="ename"
                :type="type"
                :data-test="`${dataTest}-input`"
                :class="{
                    'text-field__input--filled': hasValue,
                    'text-field__input--iconized': $slots.icon,
                    'text-field__input--style-uppercase': styledUppercase,
                    'text-field__input--disabled': disabled,
                    'text-field__input--readonly': readonly,
                    'text-field__input--type-number': type === 'number',
                    'text-field__input--text-align-right': inputTextAlignRight,
                }"
                :value="convertedValue"
                :disabled="disabled || readonly"
                :readonly="ghost"
                v-bind="onlyNaturalNumber ? { pattern: '\d*' } : {}"
                :step="step"
                :autocomplete="autocomplete ? 'on' : 'off'"
                :minlength="minLength"
                :maxlength="maxLength"
                class="text-field__input"
                @keyup="updateValue($event)"
                @change="updateValue"
                @blur="blured"
                @focus="focused"
                @keypress="$emit('keypress', $event)"
                @keydown="handleKeyDown"
                @wheel="$event.target.blur()"
            />
            <span v-if="suffix" class="text-field__input--suffix" :class="{ focused: isFocused || hasValue }">
                {{ suffix }}
            </span>
            <label v-if="label" data-test="label" :for="eid" class="text-field__label">
                {{ label }}
                <sup v-if="labelFootnote" data-test="label-footnote">{{ labelFootnote }}</sup>
            </label>
            <div v-if="$slots.action" class="text-field__slot text-field__slot--action">
                <slot name="action" />
            </div>
            <div v-if="$slots.icon" class="text-field__icon">
                <slot name="icon" />
            </div>
            <BaseButton
                v-if="hasValue && !$slots.action && !readonly && !disabled && !tiny"
                class="text-field__reset"
                tabindex="-1"
                @click="clearValue()"
            >
                <ResetIcon width="13" />
            </BaseButton>
        </div>
        <ErrorMessage
            v-if="error"
            :message="error"
            :dark="dark"
            :is-small="isSmallErrorMessage"
            :data-test="`${dataTest}-error`"
        />
    </div>
</template>

<script>
import _throttle from 'lodash/throttle';
import format from 'date-fns/format';
import { preciseRound } from '@/services/utils';

import BaseButton from '@/components/Button/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import numberMixins from '@/services/utils/inputValidation.js';

import ResetIcon from '@/assets/icons/micro/error.svg';

export default {
    name: 'TextField',
    components: {
        ResetIcon,
        BaseButton,
        ErrorMessage,
    },
    mixins: [numberMixins],
    props: {
        id: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        value: {
            type: [String, Number],
            default: null,
        },
        type: {
            type: String,
            default: 'text',
            validator(value) {
                return ['text', 'textarea', 'email', 'password', 'number', 'date', 'hidden'].indexOf(value) !== -1;
            },
        },
        dataTest: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: null,
        },
        isSmallErrorMessage: {
            type: Boolean,
            default: null,
        },
        //@TODO: either remove precision or step as both props do essentially the same
        precision: {
            type: Number,
            default: null,
        },
        rows: {
            type: Number,
            default: 6,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        step: {
            type: String,
            default: null,
        },
        minLength: {
            type: Number,
            default: null,
        },
        maxLength: {
            type: Number,
            default: null,
        },
        autocomplete: {
            type: Boolean,
            default: false,
        },
        updateMode: {
            type: [String, Array],
            default: () => ['keyup', 'change'],
        },
        focusInitially: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        tiny: {
            type: Boolean,
            default: false,
        },
        inputTextAlignRight: {
            type: Boolean,
            default: false,
        },
        styledUppercase: {
            type: Boolean,
            default: false,
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        allowNegativeNumbers: {
            type: Boolean,
            default: false,
        },
        onlyNaturalNumber: {
            type: Boolean,
            default: false,
        },
        ghost: {
            type: Boolean,
            default: false,
        },
        isDarkTheme: {
            type: Boolean,
            default: false,
        },
        labelFootnote: {
            type: String,
            default: null,
        },
        inlineLabel: {
            type: Boolean,
            default: false,
        },
        allowAlphanumericOnly: {
            type: Boolean,
            default: false,
        },
        suffix: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            isFocused: false,
        };
    },

    computed: {
        eid() {
            return this.id || `el${this._uid}`;
        },
        ename() {
            return this.name || `el${this._uid}`;
        },
        hasValue() {
            return (this.value !== null && this.value !== '') || this.type === 'date';
        },
        derivedPrecision() {
            if (!this.step?.split) return null;
            const parts = this.step.split('.');
            if (parts.length !== 2) return null;

            return parts[1].length;
        },
        convertedValue() {
            if (this.value && this.type === 'date') {
                return format(new Date(this.value * 1000), 'YYYY-MM-DD');
            }

            const precision = this.precision ? parseInt(this.precision, 10) : this.derivedPrecision;
            if (this.value && this.type === 'number' && precision) {
                let fixedDigits = 0;
                if (this.value.toString().split('.')[1]) {
                    fixedDigits = this.value.toString().split('.').pop().length;
                }
                return preciseRound(this.value, precision).toFixed(Math.min(fixedDigits, precision));
            }

            return this.value?.toString();
        },
    },

    watch: {
        value(value) {
            this.$emit('triggered-input', value);
        },
    },
    mounted() {
        if (this.focusInitially && !this.$root.isIOS) {
            setTimeout(() => {
                const $el = document.getElementById(this.eid);
                $el && $el.focus();
            }, 300);
        }
    },
    methods: {
        updateValue(e) {
            if (this.type == 'number' && [',', '.'].includes(e.key)) return;
            if (this.readonly || this.disabled) return;
            if (
                (this.updateMode.push && !this.updateMode.includes(e.type)) ||
                (!this.updateMode.push && this.updateMode !== e.type)
            ) {
                return;
            }

            let value = e.target.value;

            if (value && this.type === 'date') {
                value = format(Date.parse(value), 'X');
            }

            if (value && this.onlyNaturalNumber && this.type === 'number') {
                value = Math.abs(parseInt(value.replace(/\.|,/, ''), 10) || 0);

                this.$nextTick(() => {
                    e.target.value = value;
                });
            }

            this.$emit('input', value);
            this.gracefullyNotifyAboutChange(value);
        },

        handleKeyDown(e) {
            this.allowAlphanumericOnly && this.allowAlphanumericsOnly(e);
            // Firefox does not prevent invalid input on number types
            // so it has to be handled manually
            let allowedKeys = [8, 9, 46, 37, 38, 39, 40, 110];

            if (!this.onlyNaturalNumber) {
                allowedKeys = [...allowedKeys, 188, 190];
            }

            const isWhitelistedKey =
                allowedKeys.includes(e.keyCode) ||
                e.metaKey ||
                e.ctrlKey ||
                (this.allowNegativeNumbers && e.keyCode === 189);
            const isNumber = (e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106);
            if (this.type === 'number' && !isNumber && !isWhitelistedKey) {
                e.preventDefault();
                return;
            }

            this.$emit('keydown', e);
        },

        clearValue() {
            if (this.readonly || this.disabled) return;
            this.$emit('input', null);
        },

        gracefullyNotifyAboutChange: _throttle(function (value) {
            if (this.readonly || this.disabled) return;
            this.$emit('update', value);
        }, 100),

        focused(e) {
            this.isFocused = true;
            this.$emit('focus', e);
        },

        blured(e) {
            this.isFocused = false;
            this.$emit('blur', e);
        },
    },
};
</script>

<style lang="scss">
.text-field {
    display: block;
    position: relative;

    &--hidden {
        display: inline-block;
        height: 0;
        width: 0;
        visibility: hidden;
        position: absolute;
    }
}

.text-field--theme-dark {
    .text-field__input-group {
        background-color: #e2e2e2;
    }
}

.text-field--ghost {
    pointer-events: none;

    .text-field__input-group {
        background-color: transparent;
        border-color: transparent;
    }

    .text-field__label {
        color: transparent;
    }
}

.text-field__textarea {
    display: block;
    width: 100%;
    padding: 15px 15px 13px;
    font-size: 14px;
    line-height: $line-height-base;
    font-family: $font-family;
    outline: none;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-appearance: none;
    transition: border-color 0.2s ease;
    resize: vertical;

    .text-field--focused & {
        border-color: rgba(0, 0, 0, 1);
    }

    .text-field--invalid & {
        border-color: $sflx-red-800;
    }
}

.text-field__input-group {
    display: flex;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;
    overflow: hidden;
    height: 70px;
    transition: border-color 0.2s ease;

    .text-field--dark & {
        border-color: transparent;
    }

    .text-field--focused & {
        border-color: rgba(0, 0, 0, 1);
    }

    .text-field--invalid & {
        border-color: $sflx-red-800;
    }

    .text-field--small & {
        height: 50px;
    }

    .text-field--tiny & {
        height: 40px;

        .text-field__input {
            padding: 10px;
            font-size: 12px;
        }
    }
}

.text-field__input-group--no-border {
    border: none;
}

.text-field__slot--action {
    padding-top: 25px;
    padding-right: 15px;
    transition: padding-top 0.2s ease;

    > .button {
        font-size: 11px;
    }

    > * {
        font-weight: $font-weight-regular;
    }

    .text-field--small & {
        padding-top: 15px;
    }
}

.text-field__input {
    display: block;
    width: 100%;
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 27px 40px 22px 15px;
    font-size: 14px;
    line-height: $line-height-base;
    font-family: $font-family;
    outline: none;
    border-radius: 0;
    transition: padding 0.2s ease;

    &:invalid {
        box-shadow: none;
    }
}

.text-field__input--suffix {
    color: #64656c;
    padding: 27px 16px 22px 15px;
    transition: padding 0.2s ease;

    &.focused {
        padding-right: 2.25rem;
        padding-top: 2.15rem;
    }

    .text-field--small & {
        padding: 17px 16px 22px 15px;

        &.focused {
            padding-right: 2.25rem;
        }
    }
}

.text-field__input--iconized {
    padding-left: 40px;

    & + .text-field__label {
        left: 40px;
    }
}

.text-field__input--text-align-right {
    text-align: right;
}

.text-field__input--readonly {
    color: $color-black;
}

.text-field--focused:not(.text-field--no-label),
.text-field--filled:not(.text-field--no-label) {
    .text-field__label {
        opacity: 0.7;
        font-size: 10px;
        top: 20px;
        left: 15px;
    }

    .text-field__input {
        padding-bottom: 6px;
    }

    .text-field__icon,
    .text-field__reset {
        top: 37px;
    }

    &.text-field--small {
        .text-field__label {
            top: 13px;
        }

        .text-field__icon,
        .text-field__reset {
            top: 27px;
        }
    }
}

.text-field__label {
    font-size: 14px;
    line-height: 1;
    position: absolute;
    color: inherit;
    top: 30px;
    left: 15px;
    transition: all 0.2s ease;

    .text-field--small & {
        top: 20px;
    }
}

.text-field__icon,
.text-field__reset {
    line-height: 1;
    position: absolute;
    top: 30px;
    transition: top 0.2s ease;

    .text-field--small & {
        top: 20px;
    }
}

.text-field__icon {
    left: 15px;

    svg {
        width: 13px;
        height: 13px;
    }
}

.text-field__reset {
    right: 17px;
    opacity: 0;
    transition: opacity 0.2s ease;

    .text-field:hover &,
    .text-field--focused & {
        opacity: 1;
    }
}

.text-field--invalid {
    .text-field__textarea,
    .text-field__input {
        opacity: 1 !important;
        border-color: $sflx-red-800;
    }

    .text-field__slot--action {
        border-color: $sflx-red-800;
    }

    .text-field__input-group {
        svg {
            .f-base {
                fill: $color-red;
            }

            .s-base {
                stroke: $color-red;
            }
        }
    }
}

.text-field--dark {
    .text-field__textarea,
    .text-field__input-group {
        box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
    }
}

.text-field__input--style-uppercase {
    text-transform: uppercase;
}

.text-field__input--disabled {
    pointer-events: none;

    .form__text-field--opening-hours & {
        cursor: not-allowed;
        pointer-events: all;
    }
}

.text-field__input--type-number {
    -moz-appearance: textfield;
}

.text-field__input--type-number::-webkit-inner-spin-button,
.text-field__input--type-number::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.text-field--inline-label {
    &.text-field--small:not(.text-field--no-label) {
        .text-field__input {
            padding: 22px 40px 22px 15px;
        }
        .text-field__label {
            left: auto;
            right: 15px;
            top: 50%;
            transform: translate(0, -50%);
        }
        .text-field__reset {
            left: auto;
            right: 15px;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .text-field__reset {
            right: 10px;
            background: $color-white;
        }
    }
}
</style>

<template>
    <component
        :is="'h' + level"
        :class="{
            [`headline--h${level}`]: true,
            [`headline--spaced-bottom-${spacedBottom}`]: true,
            'headline--bold': bold,
            'headline--centered': centered,
        }"
        class="headline"
    >
        <slot>Lorem ipsum dolor sit H{{ level }}</slot>
    </component>
</template>

<script>
export default {
    name: 'Headline',
    props: {
        level: {
            type: Number,
            default: 1,
            validator(v) {
                return v > 0 && v < 7;
            },
        },
        bold: {
            type: Boolean,
            default: false,
        },
        centered: {
            type: Boolean,
            default: false,
        },
        spacedBottom: {
            type: String,
            default: 'default',
            validator: v => ['default', 'small', 'large'].indexOf(v) !== -1,
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.headline {
    color: inherit;
    font-weight: $font-weight-regular;
    margin-top: 1em;
    margin-bottom: 1em;
}

h1,
.h1,
.headline--h1 {
    font-size: $font-size-h1;
    line-height: $line-height-h1;
}

h2,
.h2,
.headline--h2 {
    font-size: $font-size-h2;
    line-height: $line-height-h2;
}

h3,
.h3,
.headline--h3 {
    font-size: $font-size-h3;
    line-height: $line-height-h3;
}

h4,
.h4,
.headline--h4 {
    font-size: $font-size-h4;
    line-height: $line-height-h4;
}

h5,
.h5,
.headline--h5 {
    font-size: $font-size-h5;
    line-height: $line-height-h5;
}

h6,
.h6,
.headline--h6 {
    font-size: $font-size-h6;
    line-height: $line-height-h6;
    font-weight: $font-weight-bold;
    margin-top: 2em;
    margin-bottom: 0.5em;
}

.headline--spaced-bottom-small {
    margin-bottom: 2em;
}

.headline--spaced-bottom-large {
    margin-bottom: 3em;
}

.headline--bold {
    font-weight: $font-weight-bold;
}

.headline--centered {
    text-align: center;
}
</style>

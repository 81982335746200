import i18n from '@/i18n';
import _get from 'lodash/get';
import _isString from 'lodash/isString';
import { format, isAfter, isSameDay } from 'date-fns';
import { ensureJSTimestamp } from '@/services/utils/date';
import { unref } from 'vue';
import _isNull from 'lodash/isNull';

/**
 * Resolve option at validation time
 * used to bind fields together: greaterThan( otherField)
 * @param {*} option
 */
const resolveOption = (context, option) => {
    // Try to retrieve option from context
    if (_isString(option)) {
        return unref(_get(context, option, option));
    }
    return option;
};

// Validation rules
// ---------------------------------------------------
// Known issue: validation message does not update on locale change

/**
 * @return {function(*): boolean|string}
 */
export const isRequired = (translationKey = null) => {
    return v => {
        return !!v || v === 0 || i18n.t(translationKey || 'validation.isRequired');
    };
};

export const isOneOf = (translationKey = null, choices = []) => {
    return v => {
        return choices.includes(v) || i18n.t(translationKey || 'validation.isOneOf', { choices: choices.join(', ') });
    };
};

export const isNotNullOrUndefined = (translationKey = null) => {
    return v => {
        return (v !== null && v !== undefined) || i18n.t(translationKey || 'validation.isRequired');
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const isNumber = (translationKey = null) => {
    return v => {
        if (!v) {
            return true;
        }
        const val = parseInt(v);
        return (!isNaN(val) && /^-?\d*$/.test(v)) || i18n.t(translationKey || 'validation.isNumber');
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const isPhoneNumber = (translationKey = null) => {
    return v => {
        if (!v) {
            return true;
        }
        return /^[\d\-_\(\)\\\/ \.]+$/.test(v) || i18n.t(translationKey || 'validation.isPhoneNumber');
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const isAlphanumeric = (translationKey = null) => {
    return v => {
        return /^[a-z0-9]+$/i.test(v) || i18n.t(translationKey || 'validation.isAlphanumeric');
    };
};

export const isAlphanumericWithSpacesAndGermanSpecialCharacters = (translationKey = null) => {
    return v => {
        return (
            /^[A-Za-z0-9\u00DF\u00E4\u00F6\u00FC\u00C4\u00D6\u00DC\s]+$/i.test(v) ||
            i18n.t(translationKey || 'validation.isAlphanumericWithSpacesAndGermanSpecialCharacters')
        );
    };
};

export const isAlphanumericWithSpacesDashesAndGermanSpecialCharacters = (translationKey = null) => {
    return v => {
        return (
            /^[A-Za-z0-9? äÄöÖüÜß-]+$/i.test(v) ||
            i18n.t(translationKey || 'validation.isAlphanumericWithSpacesDashesAndGermanSpecialCharacters')
        );
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const isWeighingNumber = (translationKey = null) => {
    return v => {
        return /^[a-z0-9#._\-\/ |\\:;–—,]+$/i.test(v) || i18n.t(translationKey || 'validation.isWeighingNumber');
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const isFloat = (translationKey = null) => {
    return v => {
        if (!v) {
            return true;
        }
        const val = parseFloat(v);
        return (!isNaN(val) && /^-?\d*[\.,]?\d*$/.test(v)) || i18n.t(translationKey || 'validation.isFloat');
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const minLength = (rawMin, translationKey = null) => {
    return function (v) {
        if (!v) {
            return true;
        }

        const min = resolveOption(this, rawMin);

        return v.length >= min || i18n.t(translationKey || 'validation.minLength', { min });
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const greaterThan = (rawMin, translationKey = null, isNumber = true) => {
    return function (v) {
        const value = isNumber ? Number(v) : v;
        const min = isNumber ? Number(resolveOption(this, rawMin)) : resolveOption(this, rawMin);

        const isEmpty = !v && v !== 0;

        return isEmpty || value > min || i18n.t(translationKey || 'validation.greaterThan', { min: i18n.n(min) });
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const greaterOrEqualThan = (rawMin, translationKey = null) => {
    return function (v) {
        const value = Number(v);
        const min = Number(resolveOption(this, rawMin));
        const isEmpty = !v && v !== 0;

        return (
            isEmpty || value >= min || i18n.t(translationKey || 'validation.greaterOrEqualThan', { min: i18n.n(min) })
        );
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const greaterOrEqualThanWithNullCheck = (rawMin, translationKey = null) => {
    return function (v) {
        const value = Number(v);
        const min = Number(resolveOption(this, rawMin));

        return (
            (!_isNull(v) && value >= min) ||
            i18n.t(translationKey || 'validation.greaterOrEqualThan', { min: i18n.n(min) })
        );
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const lowerThan = (rawMax, translationKey = null) => {
    return function (v) {
        const value = Number(v);
        const max = Number(resolveOption(this, rawMax));
        const isEmpty = !v && v !== 0;

        return isEmpty || value < max || i18n.t(translationKey || 'validation.lowerThan', { max: i18n.n(max) });
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const lowerOrEqualThan = (rawMax, translationKey = null) => {
    return function (v) {
        const value = Number(v);
        const max = Number(resolveOption(this, rawMax));
        const isEmpty = !v && v !== 0;

        return isEmpty || value <= max || i18n.t(translationKey || 'validation.lowerOrEqualThan', { max: i18n.n(max) });
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const maxLength = (rawMax, translationKey = null) => {
    return function (v) {
        if (!v) {
            return true;
        }

        const max = resolveOption(this, rawMax);

        return v.length <= max || i18n.t(translationKey || 'validation.maxLength', { max });
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const betweenLength = (rawMin, rawMax, translationKey = null) => {
    return function (v) {
        if (!v) {
            return true;
        }

        const min = resolveOption(this, rawMin);
        const max = resolveOption(this, rawMax);

        return (
            (v.length >= min && v.length < max) || i18n.t(translationKey || 'validation.betweenLength', { min, max })
        );
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const isEmail = (translationKey = null) => {
    const emailRegex = /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Z0-9-]+\.)+[A-Z]{2,6}$/i;

    return v => {
        if (!v) {
            return true;
        }
        return emailRegex.test(v) || i18n.t(translationKey || 'validation.isEmail');
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const isUsername = (translationKey = null) => {
    // 5-40 letters and numbers, no leading ., - or _
    const usernameRegex = /^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,38}[a-zA-Z0-9]$/i;

    return v => {
        return usernameRegex.test(v) || i18n.t(translationKey || 'validation.isUsername');
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const isScopePLZ = (translationKey = null) => {
    return v => {
        return /^[\d\*]{5}$/.test(v) || i18n.t(translationKey || 'validation.scopePLZ');
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const isTaxIdOrVatId = (translationKey = null) => {
    const UstIdRegex = /^de[0-9]{9}$/i;

    function isValidVatId(value) {
        value = value.replace(/[^0-9]/g, '');

        let isValid = true;

        if (value.length !== 11) {
            isValid = false;
        }

        if (value.charAt(0) === '0') {
            isValid = false;
        }

        const digits = value.split('');
        const first10Digits = [...digits];
        first10Digits.pop();

        const countDigits = first10Digits.reduce((acc, e) => {
            acc[e] = e in acc ? acc[e] + 1 : 1;
            return acc;
        }, {});

        if (Object.keys(countDigits).length !== 9 && Object.keys(countDigits).length !== 8) {
            isValid = false;
        }

        if (!isValid) return false;

        let product = 10;

        for (let i = 0; i <= 9; i++) {
            let sum = (parseInt(digits[i]) + product) % 10;
            if (sum === 0) {
                sum = 10;
            }
            product = (sum * 2) % 11;
        }

        let checksum = 11 - product;

        if (checksum === 10) {
            checksum = 0;
        }

        if (parseInt(value.charAt(10)) !== checksum) {
            isValid = false;
        }

        return isValid;
    }

    return v => {
        if (!v) {
            return true;
        }

        return UstIdRegex.test(v) || isValidVatId(v) || i18n.t(translationKey || 'validation.isUstOrVatId');
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const isDayMatchingRangeConfig = (rangeConfigKey, translationKey = null) => {
    const formatDate = d => format(ensureJSTimestamp(d), 'YYYY-MM-DD');

    return function (v) {
        const rangeConfig = resolveOption(this, rangeConfigKey);

        const availableDays = rangeConfig.map(o => formatDate(o.start));

        const days = [];

        if (v.start) {
            days.push(formatDate(v.start));
        }

        if (v.end) {
            days.push(formatDate(v.end));
        }

        const result = days.every(o => availableDays.includes(o));

        return result || i18n.t(translationKey || 'validation.isDayMatchingRangeConfig');
    };
};

/**
 * @return {function(*): boolean|string}
 */
export const isSameDayOrLater = (rawMinDate, translationKey = null) => {
    return function (v) {
        const minDate = new Date(resolveOption(this, rawMinDate));
        const givenDate = new Date(v);
        return (
            isSameDay(givenDate, minDate) ||
            isAfter(givenDate, minDate) ||
            i18n.t(translationKey || 'validation.isSameDayOrLater')
        );
    };
};

export const isDisposerNumberFormatDE = (correctWasteStateLetter, translationKey = null) => {
    const regex = /^[A-Z](\/?[A-Z0-9]{8})*\/?$/;
    return v => {
        return (
            (regex.test(v) && v && correctWasteStateLetter === v.charAt(0)) ||
            i18n.t(translationKey || 'validation.isDisposerNumberFormat')
        );
    };
};

export const hasDocuments = (translationKey = null) => {
    return v => {
        return !!v?.length || i18n.t(translationKey || 'validation.isRequired');
    };
};

<template>
    <div v-if="overlayVisible" :class="`overlay--${overlayType}`" class="overlay">
        <div
            :class="{ 'overlay__video-wrapper--loaded': isVideoLoaded }"
            class="overlay__video-wrapper overlay--resizeable"
        >
            <video
                src="@/assets/video/schuettflix-login.mp4"
                autoplay
                playsinline
                preload="auto"
                webkit-playsinline
                muted
                class="login__video"
                loop
                @loadeddata="finishVideoLoading"
            />
        </div>

        <div class="overlay__content">
            <UpdateAvailableIcon v-if="overlayType === 'update'" width="150" height="150" class="overlay__icon" />
            <ConstructionSiteIcon v-else width="150" height="150" class="overlay__icon" />

            <Words bold block spaced class="overlay__headline">
                {{ $t(`components.overlay.${overlayType}.headline`) }}
            </Words>
            <Words block>
                {{ $t(`components.overlay.${overlayType}.description`) }}
            </Words>

            <div v-if="overlayType === 'updating' && downloadProgress">
                <div class="overlay-update-progressbar">
                    <div
                        :style="{ width: `${downloadProgress.percentage}%` }"
                        class="overlay-update-progressbar__progress"
                    />
                </div>
            </div>

            <BaseButton
                v-if="$root.isApp && overlayType === 'update'"
                :href="platforms[platform]"
                primary
                dark
                arrow-right
                class="overlay__button"
            >
                {{ $t(`components.overlay.update.downloadLabel.${platform}`) }}
            </BaseButton>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { findClosestMatchingSupportedLocale } from '@/i18n';

import Words from '@/components/Typography/Words';
import BaseButton from '@/components/Button/Button';

import ConstructionSiteIcon from '@/assets/icons/confirmation/error-order.svg';
import UpdateAvailableIcon from '@/assets/icons/confirmation/rate-delivery.svg';

export default {
    name: 'Overlay',
    components: {
        Words,
        BaseButton,
        ConstructionSiteIcon,
        UpdateAvailableIcon,
    },
    data() {
        return {
            isVideoLoaded: false,
            platform: this.$root.isIOS ? 'ios' : 'android',
            platforms: {
                ios: 'https://itunes.apple.com/app/id1449835901',
                android: 'https://play.google.com/store/apps/details?id=com.schuettflix.app',
            },
        };
    },
    computed: {
        ...mapGetters('platform', [
            'isMaintenanceActive',
            'isOffline',
            'isUpdateRequired',
            'isUpdateInProgress',
            'downloadProgress',
        ]),
        ...mapGetters('i18n', ['locale']),

        overlayType() {
            if (this.isOffline) {
                return 'offline';
            }

            if (this.isMaintenanceActive) {
                return 'maintenance';
            }

            if (this.isUpdateRequired) {
                return 'update';
            }

            if (this.isUpdateInProgress) {
                return 'updating';
            }

            return null;
        },

        overlayVisible() {
            return this.isMaintenanceActive || this.isOffline || this.isUpdateRequired || this.isUpdateInProgress;
        },
    },
    created() {
        if (this.overlayVisible) {
            const overlayLocale = findClosestMatchingSupportedLocale(navigator.language);
            this.setLocale(overlayLocale);
        }
    },
    methods: {
        finishVideoLoading() {
            this.isVideoLoaded = true;
        },

        async setLocale(locale) {
            await this.$store.dispatch('i18n/changeLocale', {
                locale,
            });
        },
    },
};
</script>

<style lang="scss">
.overlay__video-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &::before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #af0000;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
    }
}

.overlay__video-wrapper--loaded::before {
    opacity: 0;
}

.overlay__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    background-color: $color-red;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    flex-direction: column;
}

.overlay__icon {
    path {
        stroke: $color-white !important;
    }
}

.overlay__content {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.overlay__headline {
    margin-top: 40px;
}

.overlay .words {
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;
}

.overlay--update {
    padding-bottom: 70px;
}

.button.overlay__button {
    position: fixed;
    bottom: 0;

    // iOS 11.0
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
        padding-bottom: calc(10px + constant(safe-area-inset-bottom));
    }

    // iOS 11.2
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
        padding-bottom: calc(10px + env(safe-area-inset-bottom));
    }
}

.overlay-update-progressbar {
    width: 100%;
    min-width: 300px;
    background-color: transparent;
    height: 16px;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 40px;
    border: 2px solid #eef1f3;
    padding: 2px;
}

.overlay-update-progressbar__progress {
    transition: width 0.3s ease;
    height: 8px;
    background: #eef1f3;
    width: 0;
    border-radius: 4px;
}
</style>

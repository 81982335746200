import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { FlyoutPage } from '@/constructionProjects/components/FlyoutPage';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { ConstructionProjectAdminEditForm } from '@/constructionProjects/pages/ConstructionProjectEdit/ConstructionProjectAdminEditForm';
const _ConstructionProjectAdminEditForm = () => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    return (<FlyoutPage screenName="platform-constructionproject-edit" onCloseAnimationEnd={() => vueRouter.back()} headerTitle={t('pages.constructionProject.editForm.header.title')} body={<Suspense>
                    <ConstructionProjectAdminEditForm />
                </Suspense>}/>);
};
export const ConstructionProjectAdminEditFlyout = asVueComponent(_ConstructionProjectAdminEditForm);

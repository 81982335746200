var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isActive)?_c('div',{staticClass:"flyout",class:{
        'flyout--active': _vm.isVisuallyOpen,
        'flyout--small': _vm.size === 'small',
        'flyout--medium': _vm.size === 'medium',
        'flyout--large': _vm.size === 'large',
        'flyout--full': _vm.size === 'full',
        'flyout--grey-background': _vm.backgroundGrey,
        [`flyout--from-${_vm.appearFrom}`]: true,
    },attrs:{"data-test":_vm.dataTest}},[_c('div',{staticClass:"flyout__backdrop"}),_c('div',{staticClass:"flyout__body"},[(_vm.route)?_c('div',{staticClass:"flyout__router-content",on:{"click":_vm.preventContentClicks}},[_vm._t("router-view",function(){return [_c('router-view')]})],2):_c('div',{staticClass:"flyout__content",on:{"click":_vm.preventContentClicks}},[(!_vm.noHeader || _vm.$slots.header)?_c('div',{staticClass:"flyout__header"},[_vm._t("header",function(){return [(!_vm.noHeader)?_c('HeaderBar',{attrs:{"transparent":_vm.headerTransparent},scopedSlots:_vm._u([{key:"left",fn:function(){return [(!_vm.closeByX)?_c('HeaderBarItem',{attrs:{"button":""},on:{"click":_vm.closeFlyout}},[_c('SfSysArrowLeftIcon',{attrs:{"size":"xs"}})],1):_vm._e()]},proxy:true},{key:"headline",fn:function(){return [_vm._v(_vm._s(_vm.headline || _vm.$t(_vm.$route.meta.title)))]},proxy:true},(_vm.subline)?{key:"subline",fn:function(){return [_vm._v(_vm._s(_vm.subline))]},proxy:true}:null,{key:"right",fn:function(){return [(_vm.closeByX)?_c('HeaderBarItem',{attrs:{"button":""},on:{"click":_vm.closeFlyout}},[(_vm.closeText)?_c('Words',{attrs:{"bold":""}},[_vm._v(_vm._s(_vm.closeText))]):_vm._e(),_c('SfSysCloseIcon',{attrs:{"size":"xs"}})],1):_vm._e()]},proxy:true}],null,true)}):_vm._e()]},{"closeFlyout":_vm.closeFlyout}),_vm._t("subheader")],2):_vm._e(),_c('div',{directives:[{name:"scrollable",rawName:"v-scrollable"}],staticClass:"flyout__inner scroll-container",class:{
                    'flyout__inner--no-header': _vm.noHeader && !_vm.$slots.header,
                    'flyout__inner--no-footer': !_vm.$slots.bottom,
                }},[_vm._t("default"),(!_vm.noSpacer)?_c('div',{staticClass:"flyout__inner-spacer"},[_vm._v(" ")]):_vm._e()],2),(_vm.$slots.bottom)?_c('div',{staticClass:"flyout__footer",class:{ 'flyout__footer-border': !_vm.noBorder }},[_vm._t("bottom")],2):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
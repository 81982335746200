<template>
    <div class="contingent-progress" :class="{ 'contingent-progress--dark': hasDarkTheme }">
        <slot name="before" class="contingent-progress__number" />
        <div class="contingent-progress__bar">
            <div
                v-if="0 < view.primaryProgress"
                class="contingent-progress__track contingent-progress__track--delivered"
                :style="{ width: `${view.primaryProgress}%` }"
            >
                <div
                    v-if="$slots.primary && $root.isDesktop"
                    class="contingent-progress__tooltip"
                    :class="{
                        'contingent-progress__tooltip--right-oriented': useRightOrientedLeftTooltip,
                    }"
                >
                    <slot name="primary" />
                </div>
            </div>
            <div
                v-if="0 < view.secondaryProgress"
                class="contingent-progress__track contingent-progress__track--secondary"
                :style="{ width: `${view.secondaryProgress}%` }"
            >
                <div
                    v-if="$slots.secondary && $root.isDesktop"
                    class="contingent-progress__tooltip"
                    :class="{
                        'contingent-progress__tooltip--right-oriented': useRightOrientedMiddleTooltip,
                        'contingent-progress__tooltip--left-oriented': useLeftOrientedMiddleTooltip,
                    }"
                >
                    <slot name="secondary" />
                </div>
            </div>
            <div
                v-if="0 < view.remainingProgress"
                class="contingent-progress__track contingent-progress__track--negative"
                :style="{ width: `${view.remainingProgress}%` }"
            >
                <div
                    v-if="$slots.default && $root.isDesktop"
                    class="contingent-progress__tooltip"
                    :class="{
                        'contingent-progress__tooltip--left-oriented': useLeftOrientedRightTooltip,
                    }"
                >
                    <slot />
                </div>
            </div>
        </div>
        <slot name="after" />
    </div>
</template>

<script>
/**
 * PropType for progress sections
 */
export class ContingentProgressView {
    static create(data) {
        const view = new ContingentProgressView();

        view.min = data.min || 0;
        view.max = data.max || 100;
        view.primary = data.primary || 0;
        view.secondary = data.secondary || 0;

        return view;
    }

    unfold() {
        return {
            min: this.min,
            max: this.max,
            primary: this.primary,
            secondary: this.secondary,
        };
    }

    constructor() {
        this.min = 0;
        this.max = 100;
        this.primary = 0;
        this.secondary = 0;
    }

    get primaryProgress() {
        return (this.primary * 100) / this.max;
    }
    get secondaryProgress() {
        return (this.secondary * 100) / this.max;
    }
    get remainingProgress() {
        return Math.max(100 - this.primaryProgress - this.secondaryProgress, 0);
    }
}

import themeMixin from '@/plugins/mixins/themeMixin';

const TOOLTIP_ALIGNMENT_THRESHOLD = 18;

export default {
    name: 'ContingentProgress',
    mixins: [themeMixin],
    props: {
        view: {
            type: ContingentProgressView,
            default: () => ContingentProgressView.create(),
        },
    },
    computed: {
        useLeftOrientedRightTooltip() {
            return TOOLTIP_ALIGNMENT_THRESHOLD > this.view.remainingProgress;
        },
        useLeftOrientedMiddleTooltip() {
            return TOOLTIP_ALIGNMENT_THRESHOLD > this.view.secondaryProgress + this.view.remainingProgress;
        },

        useRightOrientedLeftTooltip() {
            return TOOLTIP_ALIGNMENT_THRESHOLD > this.view.primaryProgress;
        },
        useRightOrientedMiddleTooltip() {
            return TOOLTIP_ALIGNMENT_THRESHOLD > this.view.secondaryProgress + this.view.primaryProgress;
        },
    },
};
</script>

<style lang="scss" scoped>
.contingent-progress {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.contingent-progress__number {
    flex-grow: 1;
    flex-shrink: 0;
}

.contingent-progress__bar {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0 10px;
    width: 100%;
    height: 5px;
    display: flex;
    border-radius: 50px;
    align-self: center;
    justify-content: flex-end;
    background: $color-red;
    background: linear-gradient(to right, #f00 0%, $color-darkRed 100%);
}

.contingent-progress__track--negative {
    background-color: $color-mediumGrey;
    width: 100%;
}

.contingent-progress__track--secondary {
    --color-track-secondary: #{$color-mediumGrey};
    background-image: linear-gradient(
        90deg,
        transparent 25%,
        var(--color-track-secondary) 25%,
        var(--color-track-secondary) 50%,
        transparent 50%,
        transparent 75%,
        var(--color-track-secondary) 75%,
        var(--color-track-secondary) 100%
    );
    background-size: 9px 9px;
    background-position: left center;
    width: 0;
}

.contingent-progress__track {
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        bottom: -10px;
        left: 0;
        right: 0;
    }
}

.contingent-progress__tooltip {
    position: absolute;
    bottom: 30px;
    box-shadow: $boxShadow-card--levitate;
    background-color: #fff;
    padding: 5px;
    border-radius: 3px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    opacity: 0;
    transition: all 0.2s ease-out;

    &::before {
        content: '▼';
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        text-align: center;
        bottom: -15px;
        color: #fff;
        transform: scale(1.6, 1.1);
        text-shadow: $boxShadow-card--levitate;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        background-color: #fff;
        left: 0;
        right: 0;
        bottom: 0;
        height: 5px;
    }
}

.contingent-progress__tooltip--left-oriented {
    transform: translateX(calc(-100% + 10px));

    &::before {
        transform: translateX(calc(50% - 10px));
    }
}

.contingent-progress__tooltip--right-oriented {
    transform: translateX(calc(0% - 10px));

    &::before {
        transform: translateX(calc(-50% + 10px));
    }
}

.contingent-progress__track:hover .contingent-progress__tooltip {
    bottom: 16px;
    opacity: 1;
}

/*
    theme: dark
*/
.contingent-progress--dark {
    .contingent-progress__track--secondary {
        --color-track-secondary: #{$theme-dark-background-color};
    }
}
</style>

import ChildRenderer from '@/components/Router/ChildRenderer.vue';
import CustomClientSelectionPage from '@/pages/Checkout/CustomClientSelectionPage.vue';
import ConstructionSiteSelectionPage from '@/pages/Checkout/ConstructionSiteSelectionPage.vue';
import { hasStoreEntries, checkoutGuard } from '@/pages/Checkout';
import QuantitySelectionPage from '@/pages/Checkout/QuantitySelectionPage.vue';
import SchedulingPage from '@/pages/Checkout/SchedulingPage.vue';
import DeliveryWindowSelectionPage from '@/pages/Checkout/DeliveryWindowSelectionPage.vue';
import DeliveryTimeSelectionPage from '@/pages/Checkout/DeliveryTimeSelectionPage.vue';
import { DeliverySupplierSelectionPage } from '@/pages/Checkout/DeliverySupplierSelectionPage.tsx';
import CustomCarrierSelectionPage from '@/pages/Checkout/CustomCarrierSelectionPage.vue';
import AdditionalInformationPage from '@/pages/Checkout/AdditionalInformationPage.vue';
import CustomPriceAdjustmentsPageMaterial from '@/pages/Checkout/CustomPriceAdjustmentsPageMaterial.vue';
import SummaryPage from '@/pages/Checkout/SummaryPage/SummaryPage.vue';
import store from '@/store';
import ShipmentLoadingPage from '@/pages/Checkout/ShipmentLoadingPage.vue';
import ShipmentUnloadingPage from '@/pages/Checkout/ShipmentUnloadingPage.vue';
import ShipmentVehicleClassPage from '@/pages/Checkout/ShipmentVehicleClassPage.vue';
import ShipmentDocumentPage from '@/pages/Checkout/ShipmentDocumentPage.vue';
import ShipmentSchedulingPage from '@/pages/Checkout/ShipmentSchedulingPage.vue';
import ShipmentWindowSelectionPage from '@/pages/Checkout/ShipmentWindowSelectionPage.vue';
import ShipmentTimeSelectionPage from '@/pages/Checkout/ShipmentTimeSelectionPage.vue';
import ShipmentBillingDetailsPage from '@/pages/Checkout/ShipmentBillingDetailsPage.vue';
import { ProductDefinitionPage } from '@/pages/Checkout/ProductDefinitionPage';
import { ProductSelectionPage } from '@/pages/Checkout/ProductSelectionPage';
import { SellerProfilePage } from '@/pages/Checkout/SellerProfilePage';
import { CategoryDetailsPage } from '@/pages/Checkout/CategoryDetailsPage';
import { findRouteName } from '@/pages/routerUtils';
import { CategoriesListPage } from '@/pages/Checkout/CategoriesListPage';

export const customDeliveryRoute = {
    path: 'custom-delivery',
    name: 'custom-delivery',
    component: ChildRenderer,
    meta: {
        name: 'checkout-custom-delivery',
        requiresAuth: true,
        requiredAbilities: ['createCustomQuote'],
    },
    children: [
        {
            path: '',
            name: 'checkout-custom-delivery',
            component: CustomClientSelectionPage,
            meta: {
                previous: 'home',
                next: 'checkout-custom-delivery-constructionSite-selection',
                title: 'pages.checkout.clientSelection.title',
            },
        },
        {
            path: 'constructionSite-selection',
            name: 'checkout-custom-delivery-constructionSite-selection',
            component: ConstructionSiteSelectionPage,
            meta: {
                previous: 'checkout-custom-delivery',
                next: 'checkout-custom-delivery-product-selection',
                title: 'pages.checkout.constructionSiteSelection.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'product-selection',
            name: 'checkout-custom-delivery-product-selection',
            component: ProductSelectionPage,
            meta: {
                previous: 'checkout-custom-delivery-constructionSite-selection',
                next: 'checkout-custom-delivery-product-definition',
                nextSellerProfile: 'checkout-custom-delivery-seller-profile',
                nextCategoryDetail: 'checkout-custom-delivery-category-details',
                nextCategoriesList: 'checkout-custom-delivery-categories-list',
                title: 'pages.checkout.productSelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite']),
        },
        {
            path: 'categories-list',
            name: 'checkout-custom-delivery-categories-list',
            component: CategoriesListPage,
            meta: {
                previous: 'checkout-custom-delivery-product-selection',
                nextCategoryDetail: 'checkout-custom-delivery-category-details',
                next: 'checkout-custom-delivery-product-definition',
                title: 'pages.checkout.productSelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite']),
        },
        {
            path: 'category-details',
            name: 'checkout-custom-delivery-category-details',
            component: CategoryDetailsPage,
            meta: {
                previous: 'checkout-custom-delivery-product-selection',
                previousCategoriesList: 'checkout-custom-delivery-categories-list',
                next: 'checkout-custom-delivery-product-definition',
                title: 'pages.checkout.categorySelection.title',
            },
        },
        {
            path: 'seller-profile',
            name: 'checkout-custom-delivery-seller-profile',
            component: SellerProfilePage,
            meta: {
                previous: 'checkout-custom-delivery-product-selection',
                next: 'checkout-custom-delivery-quantity-selection',
            },
        },
        {
            path: 'product-definition',
            name: 'checkout-custom-delivery-product-definition',
            component: ProductDefinitionPage,
            meta: {
                previous: 'checkout-custom-delivery-product-selection',
                previousWithCategorySelected: 'checkout-custom-delivery-category-details',
                next: 'checkout-custom-delivery-quantity-selection',
            },
            beforeEnter: checkoutGuard(['product', 'constructionSite']),
        },
        {
            path: 'quantity',
            name: 'checkout-custom-delivery-quantity-selection',
            component: QuantitySelectionPage,
            meta: {
                previous: 'checkout-custom-delivery-product-definition',
                previousWithSellerSelected: 'checkout-custom-delivery-seller-profile',
                next: 'checkout-custom-delivery-shipping-method',
                title: 'pages.checkout.quantitySelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product']),
        },
        {
            path: 'delivery-type',
            name: 'checkout-custom-delivery-shipping-method',
            component: SchedulingPage,
            meta: {
                previous: 'checkout-custom-delivery-quantity-selection',
                next: 'checkout-custom-delivery-supplier-selection',
                nextDateSelect: 'checkout-custom-delivery-time-selection',
                nextWindowSelect: 'checkout-custom-delivery-window-selection',
                title: 'pages.checkout.schedulingPage.delivery.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product', 'transports']),
        },
        {
            path: 'delivery-window',
            name: 'checkout-custom-delivery-window-selection',
            component: DeliveryWindowSelectionPage,
            meta: {
                previous: 'checkout-custom-delivery-shipping-method',
                next: 'checkout-custom-delivery-supplier-selection',
                title: 'pages.checkout.deliveryWindowSelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product', 'transports']),
        },
        {
            path: 'delivery-time',
            name: 'checkout-custom-delivery-time-selection',
            component: DeliveryTimeSelectionPage,
            meta: {
                previous: 'checkout-custom-delivery-shipping-method',
                next: 'checkout-custom-delivery-supplier-selection',
                title: 'pages.checkout.deliveryTimeSelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product', 'transports', 'deliveryMethod']),
        },
        {
            path: 'supplier-selection',
            name: 'checkout-custom-delivery-supplier-selection',
            component: DeliverySupplierSelectionPage,
            meta: {
                previous: 'checkout-custom-delivery-shipping-method',
                previousDateSelect: 'checkout-custom-delivery-time-selection',
                previousWindowSelect: 'checkout-custom-delivery-window-selection',
                next: 'checkout-custom-delivery-carrier-selection',
                title: 'pages.checkout.deliverySupplierSelection.title',
            },
            beforeEnter: (to, from, next) => {
                const isAllowed = hasStoreEntries(['constructionSite', 'product', 'transports', 'deliveryMethod']);

                if (!isAllowed) {
                    next({ name: findRouteName(to.meta.previous) });
                    return false;
                }

                const isSellerSelected = store.state.basket.isSellerSelected;

                if (isSellerSelected) {
                    return next({ name: findRouteName(to.meta.next) });
                }

                return next();
            },
        },
        {
            path: 'carrier-selection',
            name: 'checkout-custom-delivery-carrier-selection',
            component: CustomCarrierSelectionPage,
            meta: {
                previous: 'checkout-custom-delivery-supplier-selection',
                previousWithSellerSelected: 'checkout-custom-delivery-shipping-method',
                next: 'checkout-custom-delivery-additional-information',
                title: 'pages.checkout.carrierSelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product', 'transports', 'deliveryMethod']),
        },
        {
            path: 'additional-information',
            name: 'checkout-custom-delivery-additional-information',
            component: AdditionalInformationPage,
            meta: {
                previous: 'checkout-custom-delivery-carrier-selection',
                next: 'checkout-custom-delivery-price-adjustments',
                title: 'pages.checkout.additionalInformation.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product', 'transports', 'deliveryMethod', 'supplierInfo']),
        },
        {
            path: 'price-adjustments',
            name: 'checkout-custom-delivery-price-adjustments',
            component: CustomPriceAdjustmentsPageMaterial,
            meta: {
                previous: 'checkout-custom-delivery-additional-information',
                next: 'checkout-custom-delivery-summary',
                title: 'pages.checkout.priceAdjustments.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product', 'transports', 'deliveryMethod', 'supplierInfo']),
        },
        {
            path: 'summary',
            name: 'checkout-custom-delivery-summary',
            component: SummaryPage,
            meta: {
                previous: 'checkout-custom-delivery-price-adjustments',
                next: 'checkout',
                noHeader: true,
                title: 'pages.checkout.summary.title',
            },
            beforeEnter: checkoutGuard([
                'constructionSite',
                'product',
                'transports',
                'additionalInformation',
                'deliveryMethod',
                'supplierInfo',
            ]),
        },
    ],
    beforeEnter(to, from, next) {
        store.state.basket.type ? next() : next({ name: 'home' });
    },
};

export const customShipmentRoute = {
    path: 'custom-shipment',
    name: 'custom-shipment',
    component: ChildRenderer,
    meta: {
        name: 'checkout-custom-shipment',
        requiresAuth: true,
        requiredAbilities: ['createCustomQuote'],
    },
    children: [
        {
            path: '',
            name: 'checkout-custom-shipment',
            component: CustomClientSelectionPage,
            meta: {
                previous: 'home',
                next: 'checkout-custom-shipment-loading',
                title: 'pages.checkout.clientSelection.title',
            },
        },
        {
            path: 'loading',
            name: 'checkout-custom-shipment-loading',
            component: ShipmentLoadingPage,
            meta: {
                previous: 'checkout-custom-shipment',
                next: 'checkout-custom-shipment-unloading',
                title: 'pages.checkout.shipmentLoading.title',
            },
        },
        {
            path: 'unloading',
            name: 'checkout-custom-shipment-unloading',
            component: ShipmentUnloadingPage,
            meta: {
                previous: 'checkout-custom-shipment-loading',
                next: 'checkout-custom-shipment-vehicle-class',
                title: 'pages.checkout.shipmentUnloading.title',
            },
        },
        {
            path: 'vehicle-class',
            name: 'checkout-custom-shipment-vehicle-class',
            component: ShipmentVehicleClassPage,
            meta: {
                previous: 'checkout-custom-shipment-unloading',
                next: 'checkout-custom-shipment-vehicle-and-document',
                title: 'pages.checkout.shipmentVehicleClassPage.title',
            },
        },
        {
            path: 'document',
            name: 'checkout-custom-shipment-vehicle-and-document',
            component: ShipmentDocumentPage,
            meta: {
                previous: 'checkout-custom-shipment-vehicle-class',
                next: 'checkout-custom-shipment-planning-type',
                title: 'pages.checkout.shipmentDocumentPage.title',
            },
        },
        {
            path: 'planning-type',
            name: 'checkout-custom-shipment-planning-type',
            component: ShipmentSchedulingPage,
            meta: {
                previous: 'checkout-custom-shipment-vehicle-and-document',
                next: 'checkout-custom-shipment-billing-selection',
                nextDateSelect: 'checkout-custom-shipment-time-selection',
                nextWindowSelect: 'checkout-custom-shipment-window-selection',
                title: 'pages.checkout.shipmentSchedulingPage.title',
            },
        },
        {
            path: 'shipment-window',
            name: 'checkout-custom-shipment-window-selection',
            component: ShipmentWindowSelectionPage,
            meta: {
                previous: 'checkout-custom-shipment-planning-type',
                next: 'checkout-custom-shipment-billing-selection',
                title: 'pages.checkout.shipmentWindowSelection.title',
            },
        },
        {
            path: 'shipment-time',
            name: 'checkout-custom-shipment-time-selection',
            component: ShipmentTimeSelectionPage,
            meta: {
                previous: 'checkout-custom-shipment-planning-type',
                next: 'checkout-custom-shipment-billing-selection',
                title: 'pages.checkout.shipmentTimeSelection.title',
            },
        },
        {
            path: 'billing',
            name: 'checkout-custom-shipment-billing-selection',
            component: ShipmentBillingDetailsPage,
            meta: {
                previous: 'checkout-custom-shipment-planning-type',
                previousDateSelect: 'checkout-custom-shipment-time-selection',
                previousWindowSelect: 'checkout-custom-shipment-window-selection',
                next: 'checkout-custom-shipment-carrier-selection',
                title: 'pages.checkout.shipmentBillingDetails.title',
            },
        },
        {
            path: 'carrier-selection',
            name: 'checkout-custom-shipment-carrier-selection',
            component: CustomCarrierSelectionPage,
            meta: {
                previous: 'checkout-custom-shipment-billing-selection',
                next: 'checkout-custom-shipment-price-adjustments',
                title: 'pages.checkout.carrierSelection.title',
            },
        },
        {
            path: 'price-adjustments',
            name: 'checkout-custom-shipment-price-adjustments',
            component: CustomPriceAdjustmentsPageMaterial,
            meta: {
                previous: 'checkout-custom-shipment-carrier-selection',
                next: 'checkout-custom-shipment-summary',
                title: 'pages.checkout.priceAdjustments.title',
            },
        },
        {
            path: 'summary',
            name: 'checkout-custom-shipment-summary',
            component: SummaryPage,
            meta: {
                previous: 'checkout-custom-shipment-price-adjustments',
                next: 'checkout-main',
                noHeader: true,
                title: 'pages.checkout.summary.title',
            },
            beforeEnter: checkoutGuard(['shipmentLoading', 'shipmentUnloading', 'shipmentQtyAndDocument']),
        },
    ],
    beforeEnter(to, from, next) {
        store.state.basket.type ? next() : next({ name: 'home' });
    },
};

<template>
    <Card :spaceless="$root.isDesktop" class="project-position-list-filter">
        <FilterBox
            v-model="filter"
            :default-filter="filter"
            :endpoint="endpoint"
            inline-mode
            no-padding
            @update="handleUpdate"
        >
            <template #default="filterScope">
                <GridRow :count="4" class="project-position-list-filter__filters">
                    <TextField
                        v-model="filterScope.filter.search"
                        :label="$t('pages.project.position.list.filter.searchLabel')"
                        class="span-12 span-sm-3"
                    >
                        <SearchIcon slot="icon" />
                    </TextField>

                    <SelectBox
                        v-model="filterScope.filter.status"
                        :label="$t('pages.project.position.list.filter.statusLabel')"
                        :options="statusOptions"
                        :option-value-renderer="option => option"
                        :option-label-renderer="(option, key) => $t(`status.project.${key}`)"
                        class="span-12 span-sm-1"
                    />
                </GridRow>

                <FilterSortPagination
                    :result="projects"
                    :filter="filterScope.filter"
                    :sort-options="sortOptions"
                    :option-label-renderer="value => $t(`components.projectFilter.sorting.${value}`)"
                    :hide-sort="!$root.isDesktop"
                    show-refresh
                    :loading="isLoading"
                    @refresh="handleUpdate"
                    @pageNumberUpdated="$emit('pageNumberUpdated', $event)"
                >
                    <div v-if="$root.isDesktop" />
                </FilterSortPagination>
            </template>
        </FilterBox>
    </Card>
</template>

<script>
import AbstractFilterableResource from '@/services/Api/AbstractFilterableResource';
import FilterResult from '@/services/Api/FilterResult';

import FilterBox from '@/components/Filter/FilterBox';
import FilterSortPagination from '@/components/Filter/FilterSortPagination';
import GridRow from '@/components/Layout/GridRow';
import SelectBox from '@/components/Form/SelectBox.v3';
import TextField from '@/components/Form/TextField.v2';

import SearchIcon from '@/assets/icons/micro/search.svg';
import Card from '@/components/Layout/Card';

export default {
    name: 'ProjectPositionListFilter',
    components: { Card, FilterBox, FilterSortPagination, GridRow, SelectBox, TextField, SearchIcon },
    props: {
        value: {
            type: Object,
            required: true,
        },
        endpoint: {
            type: AbstractFilterableResource,
            required: true,
        },
        projects: {
            type: FilterResult,
            default: null,
        },
        sortOptions: {
            type: Array,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            filter: null,
        };
    },
    computed: {
        statusOptions() {
            const { status } = this.endpoint.supportedFilters;
            return status ? { all: null, ...status.options } : [];
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(newFilter) {
                this.filter = newFilter;
            },
        },
    },
    methods: {
        handleUpdate() {
            this.$emit('input', this.filter);
        },
    },
};
</script>

<style lang="scss" scoped>
.project-position-list-filter {
    margin-bottom: 50px;
}

.project-position-list-filter__filters {
    margin-bottom: 0;
}
</style>

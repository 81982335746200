import AddressView from '@/models/AddressView';

/**
 * Class representing address view model
 */
export default class LocationInfoView {
    /**
     * @param {object} data
     * @return {LocationInfoView|null}
     */
    static createFromLoadingLocation(data) {
        if (!data?.loadingAddress) return null;

        const view = new LocationInfoView();

        view.name = data.loadingName;
        view.address = AddressView.create(data.loadingAddress);
        view.addressCostCenter = data.loadingAddressCostCenter;
        view.addressType = data.loadingAddressType;

        return view;
    }

    /**
     * @param {object} data
     * @return {LocationInfoView|null}
     */
    static createFromUnloadingLocation(data) {
        if (!data?.unloadingAddress) return null;

        const view = new LocationInfoView();

        view.name = data.unloadingName;
        view.address = AddressView.create(data.unloadingAddress);
        view.addressCostCenter = data.unloadingAddressCostCenter;
        view.addressType = data.unloadingAddressType;

        return view;
    }

    /**
     * @param {object} data
     * @return {LocationInfoView|null}
     */
    static createFromConstructionSite(data) {
        if (!data?.constructionSite) return null;

        const view = new LocationInfoView();

        view.name = data.name;
        view.address = AddressView.create(data.constructionSite.address);

        return view;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._name = null;
        this._address = null;
        this._addressCostCenter = null;
        this._addressType = null;
    }

    /**
     * Get name
     * @returns {string}
     */
    get name() {
        return this._name;
    }

    /**
     * Set name
     * @param {string} value
     */
    set name(value) {
        this._name = value ?? null;
    }

    /**
     * Get address
     * @returns {AddressView}
     */
    get address() {
        return this._address;
    }

    /**
     * Set address
     * @param {AddressView} value
     */
    set address(value) {
        this._address = value ?? null;
    }

    /**
     * Get addressCostCenter
     * @returns {string}
     */
    get addressCostCenter() {
        return this._addressCostCenter;
    }

    /**
     * Set addressCostCenter
     * @param {string} value
     */
    set addressCostCenter(value) {
        this._addressCostCenter = value ?? null;
    }

    /**
     * Get addressType
     * @returns {string}
     */
    get addressType() {
        return this._addressType;
    }

    /**
     * Set addressType
     * @param {string} value
     */
    set addressType(value) {
        this._addressType = value ?? null;
    }
}

<template>
    <div
        data-test="construction-site-item"
        class="construction-site-list-item relative"
        :class="{
            'cursor-pointer': clickable,
        }"
        @click="$emit('click', $event)"
    >
        <div class="flex items-center gap-4">
            <div class="grid flex-grow grid-cols-[20px_1fr] gap-4">
                <SfFlagIcon :disabled="!item.isActive" size="xs" multi-color class="mt-2" />

                <div class="construction-site-address overflow-hidden">
                    <span
                        class="font-copy-md-strong mb-2 line-clamp-2"
                        :class="{ 'text-disabled': !item.isActive }"
                        :title="item.name"
                    >
                        {{ item.name }}
                    </span>
                    <FormattedAddress
                        decorate-location-code
                        has-location-code
                        :address="item"
                        :subdued="!item.isActive"
                    />
                    <span
                        v-if="showImportantInfo && item.importantInformation"
                        class="font-copy-sm mt-2"
                        :class="{ 'text-disabled': !item.isActive }"
                    >
                        {{ item.importantInformation }}
                    </span>
                </div>
            </div>

            <SfIconButton v-if="clickable" size="sm" :has-background="false" class="shrink-0">
                <template #icon="iconProps"><SfSysChevronRightIcon v-bind="iconProps" /></template>
            </SfIconButton>
        </div>

        <div class="mt-4 flex flex-wrap gap-4">
            <SfTag class="whitespace-nowrap" subdued>
                {{
                    $t('components.constructionSiteItem.trafficabilityLabel', {
                        0: tons(item.maximumTrafficability),
                    })
                }}
            </SfTag>
            <span v-if="theRealSupervisor && !isSupervisorDeleted" class="font-copy-sm flex items-center gap-2">
                <Avatar size="tiny" :src="theRealSupervisor.image && theRealSupervisor.image.url" />
                <span> {{ theRealSupervisor.firstName }} {{ theRealSupervisor.lastName }} </span>
            </span>
        </div>

        <span v-if="isSupervisorDeleted" class="font-copy-sm mt-4 text-critical">
            {{ $t('components.constructionSiteItem.supervisorDeleted') }}
        </span>

        <SfNotificationIcon
            v-if="hasNotificationSubscribtion || hasNotificationSubscribtion === false"
            size="xs"
            class="absolute right-0 top-0"
            :multi-color="hasNotificationSubscribtion"
            :disabled="hasNotificationSubscribtion === false"
        />
    </div>
</template>

<script>
import Avatar from '@/components/Avatar';
import FormattedAddress from '@/components/FormattedAddress';

import { mapFilters } from '@/plugins/filters';

import {
    SfTag,
    SfIconButton,
    SfFlagIcon,
    SfSysChevronRightIcon,
    SfNotificationIcon,
} from '@schuettflix/vue-components';

export default {
    name: 'ConstructionSiteItem',
    components: {
        Avatar,
        FormattedAddress,

        // components
        // eslint-disable-next-line vue/no-unused-components
        SfTag,
        SfIconButton,
        SfFlagIcon,
        SfSysChevronRightIcon,
        SfNotificationIcon,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        hasNotificationSubscribtion: {
            type: Boolean,
            default: null,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
        supervisor: {
            type: Object,
            default: null,
        },
        showImportantInfo: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isSupervisorDeleted() {
            const supervisor = this.theRealSupervisor;
            if (!supervisor) return false;

            // FIXME: Better introduce a property "isDeleted" instead of testing firstName and lastName
            // The suprtvisor is probably deleted
            if (supervisor.firstName === 'Gelöschter' && supervisor.lastName === 'Benutzer' && !supervisor.isActive) {
                return true;
            }

            return false;
        },

        theRealSupervisor() {
            if (this.supervisor) {
                return this.supervisor;
            }

            return this.item.supervisor ? this.item.supervisor : null;
        },
    },
    methods: {
        ...mapFilters(['tons']),
    },
};
</script>

import { computed, watch, onUnmounted, unref } from 'vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import mitt from 'mitt';
export const useTransportActionLifecycle = (transportActionNameMaybeRef) => {
    const { useGetters, useMutations, useActions } = createNamespacedHelpers('transportActions');
    const { transport, context, action } = useGetters(['transport', 'context', 'action']);
    const { resetAction } = useMutations(['resetAction']);
    const { set: setTransportAction } = useActions(['set']);
    const isActionOpen = computed(() => unref(transportActionNameMaybeRef) === action.value);
    const closeAction = () => resetAction(unref(transportActionNameMaybeRef));
    const emitter = mitt();
    const onActionClose = (onCloseCallback) => {
        emitter.on('closed', onCloseCallback);
    };
    const onActionOpen = (onOpenCallback) => {
        emitter.on('opened', onOpenCallback);
    };
    const openAction = () => {
        setTransportAction({
            action: unref(transportActionNameMaybeRef),
            transport: transport.value,
            context: context.value,
        });
    };
    onUnmounted(() => {
        emitter.all.clear();
    });
    watch(isActionOpen, isActionOpen => {
        if (isActionOpen)
            emitter.emit('opened');
        else
            emitter.emit('closed');
    }, { immediate: true });
    return {
        isActionOpen,
        openAction,
        closeAction,
        onActionClose,
        onActionOpen,
    };
};

<template>
    <Pane class="debug-mobile-device-info-pane">
        <div slot="head">Mobile device</div>
        <div slot="body">
            <div v-if="!windowDevice">window.device object is unknown</div>
            <div v-else class="debug-mobile-device-info">
                window.device:
                <Dump v-if="typeof windowDevice === 'object'" :data="windowDevice" />
            </div>
            <div v-if="!capacitorPlugins">window.Capacitor.PluginHeaders object is unknown</div>
            <div v-else class="debug-mobile-device-info">
                Capacitor Plugins:
                <Dump v-if="typeof capacitorPlugins === 'object'" :data="capacitorPlugins" />
            </div>
            <div v-if="!capacitorDeviceInfo">window.Capacitor.Plugins.Device object is unknown</div>
            <div v-else class="debug-mobile-device-info">
                Capacitor Device Info:
                <Dump v-if="typeof capacitorDeviceInfo === 'object'" :data="capacitorDeviceInfo" />
            </div>
        </div>
    </Pane>
</template>

<script>
import Pane from '@/components/Debug/Pane/Pane.vue';
import Dump from '@/components/Debug/Dump/Dump.vue';

export default {
    name: 'MobileDeviceInfo',
    components: {
        Dump,
        Pane,
    },
    data: () => ({
        capacitorDeviceInfo: null,
    }),
    computed: {
        windowDevice() {
            return this.$root.isApp && 'device' in window ? window.device : null;
        },
        capacitorPlugins() {
            return this.$root.isApp && 'Capacitor' in window && 'PluginHeaders' in window.Capacitor
                ? window.Capacitor.PluginHeaders.map(element => {
                      return element.name;
                  })
                : null;
        },
    },
    async mounted() {
        if (
            this.$root.isApp &&
            'Capacitor' in window &&
            'Plugins' in window.Capacitor &&
            'Device' in window.Capacitor.Plugins
        ) {
            this.capacitorDeviceInfo = await window.Capacitor.Plugins.Device.getInfo();
        }
    },
};
</script>

<style lang="scss" scoped>
.debug-mobile-device-info-pane {
    width: 95%;
}
.debug-mobile-device-info {
    width: 100%;
}
</style>

<template>
    <button
        :class="{ 'card--selected': isSelected }"
        class="partner-search-organization-card card mb-4"
        @click="$emit('click', organization)"
    >
        <div class="partner-search-organization-card__head">
            <component :is="iconComponent" class="icon--inline" />

            <Words v-if="organization.__distance" block bold :red="highlightDistance">
                {{ organization.__distance | km }}
            </Words>
        </div>
        <Words block bold large spaced-bottom-tiny>{{ organization.name }}</Words>
        <Words v-if="organization.factoryName" block small spaced-bottom-tiny>{{ organization.factoryName }}</Words>
        <Words block small>
            <FormattedAddress decorate-location-code :address="organization" />
        </Words>

        <div
            v-if="organization.phone || organization.mobile || organization.email"
            class="partner-search-organization-card__foot"
        >
            <Words block bold spaced-bottom>{{ $t('pages.platformToolsPartnerSearch.contactInfo.title') }}</Words>

            <div v-if="organization.phone" class="partner-search-organization-card__contact-info">
                <Words small>{{ $t('pages.platformTools.phone') }}</Words>
                <Words small>{{ assemblePhoneNumber(organization.phone, true) }}</Words>
            </div>
            <div v-if="organization.mobile" class="partner-search-organization-card__contact-info">
                <Words small>{{ $t('pages.platformTools.phone') }}</Words>
                <Words small>{{ assemblePhoneNumber(organization.mobile, true) }}</Words>
            </div>
            <div v-if="organization.email" class="partner-search-organization-card__contact-info">
                <Words small>{{ $t('pages.platformTools.email') }}</Words>
                <Words small>{{ organization.email }}</Words>
            </div>
        </div>
    </button>
</template>

<script>
import { assemblePhoneNumber } from '@/services/utils';

import Card from '@/components/Layout/Card';
import FormattedAddress from '@/components/FormattedAddress';
import Words from '@/components/Typography/Words';

import CarrierComponentIcon from '@/assets/icons/partnerMap/carrier.svg';
import SupplierComponentIcon from '@/assets/icons/partnerMap/supplier.svg';
import ClientComponentIcon from '@/assets/icons/partnerMap/client.svg';

const TYPE_ICONS = {
    carrier: CarrierComponentIcon,
    supplier: SupplierComponentIcon,
    client: ClientComponentIcon,
};

export default {
    name: 'PartnerSearchOrganizationCard',
    components: {
        Card,
        FormattedAddress,
        Words,

        CarrierComponentIcon,
        SupplierComponentIcon,
        ClientComponentIcon,
    },
    props: {
        organization: {
            type: Object,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        highlightDistance: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        iconComponent() {
            return TYPE_ICONS[this.organization.type];
        },
    },
    methods: {
        assemblePhoneNumber,
    },
};
</script>

<style lang="scss" scoped>
.partner-search-organization-card__head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}

.partner-search-organization-card__foot {
    margin-top: 30px;
}

.partner-search-organization-card__contact-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
}
</style>

export var ProductAttributeType;
(function (ProductAttributeType) {
    ProductAttributeType["BRAND"] = "product_catalog.attribute_set.brand.label";
    ProductAttributeType["GEOGRAPHY"] = "product_catalog.attribute_set.geography.label";
    ProductAttributeType["GEOLOGY"] = "product_catalog.attribute_set.geology.label";
    ProductAttributeType["COLOR"] = "product_catalog.attribute_set.color.label";
    ProductAttributeType["CHARACTERISTICS"] = "product_catalog.attribute_set.characteristic.label";
    ProductAttributeType["CERT_1"] = "product_catalog.attribute_set.application.label";
    ProductAttributeType["CERT_2"] = "product_catalog.attribute_set.standard.label";
    ProductAttributeType["CERT_3"] = "product_catalog.attribute_set.specification.label";
    ProductAttributeType["PROCESSING"] = "product_catalog.attribute_set.processing.label";
})(ProductAttributeType || (ProductAttributeType = {}));
export var ProductAttributeField;
(function (ProductAttributeField) {
    ProductAttributeField["BRAND"] = "brand";
    ProductAttributeField["GEOGRAPHY"] = "geography";
    ProductAttributeField["GEOLOGY"] = "geology";
    ProductAttributeField["COLOR"] = "color";
    ProductAttributeField["CHARACTERISTICS"] = "characteristics";
    ProductAttributeField["CERT_1"] = "application";
    ProductAttributeField["CERT_2"] = "standard";
    ProductAttributeField["CERT_3"] = "specification";
    ProductAttributeField["PROCESSING"] = "processing";
})(ProductAttributeField || (ProductAttributeField = {}));
export const TypeToFieldMap = {
    [ProductAttributeType.BRAND]: ProductAttributeField.BRAND,
    [ProductAttributeType.GEOGRAPHY]: ProductAttributeField.GEOGRAPHY,
    [ProductAttributeType.GEOLOGY]: ProductAttributeField.GEOLOGY,
    [ProductAttributeType.CHARACTERISTICS]: ProductAttributeField.CHARACTERISTICS,
    [ProductAttributeType.COLOR]: ProductAttributeField.COLOR,
    [ProductAttributeType.CERT_1]: ProductAttributeField.CERT_1,
    [ProductAttributeType.CERT_2]: ProductAttributeField.CERT_2,
    [ProductAttributeType.CERT_3]: ProductAttributeField.CERT_3,
    [ProductAttributeType.PROCESSING]: ProductAttributeField.PROCESSING,
};
export const GRAIN_SIZE_TYPE = 'product_catalog.attribute_set.grain_size.label';
export var ConfigType;
(function (ConfigType) {
    ConfigType["RANGE"] = "RANGE";
    ConfigType["CHOICE"] = "CHOICE";
    ConfigType["COLOR"] = "COLOR";
})(ConfigType || (ConfigType = {}));

<template>
    <LayoutPage is-flyout :screen-name="screenName" class="shipment-window-selection-page">
        <template #flyoutHeader>
            <HeaderComponent />
        </template>

        <div class="container-off-canvas-sm relative h-full w-full py-6">
            <section class="mb-8">
                <p class="font-copy-md-strong mb-4">
                    {{ $t('pages.checkout.shipmentWindowSelection.loadingHeadline') }}
                </p>
                <LoadWindow
                    v-model="loadingWindow"
                    type="loading"
                    :available-days="availableLoadingTimes && availableLoadingTimes.days"
                    :available-range="availableLoadingTimes && availableLoadingTimes.range"
                    :disabled="!availableLoadingTimes"
                    :disabled-time="loadingWindow.date === null"
                    @input="handleLoadingWindowUpdate"
                />
            </section>

            <section>
                <p class="font-copy-md-strong mb-4">
                    {{ $t('pages.checkout.shipmentWindowSelection.unloadingHeadline') }}
                </p>
                <LoadWindow
                    v-model="unloadingWindow"
                    type="unloading"
                    :available-days="availableUnloadingDays"
                    :available-range="availableUnloadingTimes && availableUnloadingTimes.range"
                    :disabled="!availableUnloadingTimes || !hasValidLoadingData"
                    :disabled-time="unloadingWindow.date === null"
                    @input="handleUnloadingWindowUpdate"
                />
            </section>
        </div>

        <template #sticky>
            <SlideUp :active="hasValidLoadingData && hasValidUnloadingData">
                <ButtonGroup>
                    <ButtonComponent primary data-test="button-apply-flexible-period" @click="submit()">
                        {{ $t('pages.checkout.shipmentWindowSelection.submit') }}
                    </ButtonComponent>
                </ButtonGroup>
            </SlideUp>
        </template>
    </LayoutPage>
</template>

<script>
import { ensureJSTimestamp } from '@/services/utils/date';
import { mapState } from 'vuex';
import PlanningApi from '@/services/Api/Planning';
import Toaster from '@/services/Toaster';
import { greaterThan, isDayMatchingRangeConfig } from '@/services/validation/rules';
import _cloneDeep from 'lodash/cloneDeep';
import GlobalVariables from '@/services/GlobalVariables';
import getDay from 'date-fns/get_day';
import { SHIPMENT_FALLBACK_TIME_CONSTRAINT_FROM, SHIPMENT_FALLBACK_TIME_CONSTRAINT_TO } from '@/constants/time';

import ButtonComponent from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import HeaderComponent from './components/Header';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadWindow from '@/pages/Transport/Actions/components/LoadWindow';
import SlideUp from '@/components/Animation/SlideUp';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';

export default {
    name: 'ShipmentWindowSelectionPage',
    components: {
        ButtonComponent,
        ButtonGroup,
        HeaderComponent,
        LayoutPage,
        LoadWindow,
        SlideUp,
    },
    setup() {
        return {
            screenName: useOrderScreenName('deliverywindowselection'),
        };
    },
    data() {
        return {
            defaultShipmentTimeFrom: SHIPMENT_FALLBACK_TIME_CONSTRAINT_FROM,
            defaultShipmentTimeTo: SHIPMENT_FALLBACK_TIME_CONSTRAINT_TO,
            loadingWindow: this.getEmptyWindow(),
            unloadingWindow: this.getEmptyWindow(),

            availableLoadingTimes: null,
            availableUnloadingTimes: null,
        };
    },
    computed: {
        ...mapState('basket', ['shipmentLoading', 'shipmentUnloading', 'shipmentShippingWindow', 'projectId']),
        ...mapState('platform', ['openingTimes']),

        availableUnloadingDays() {
            if (!this.availableUnloadingTimes || !this.loadingWindow.date?.start) return [];

            const loadingDayStart = this.loadingWindow.date?.start;

            return this.availableUnloadingTimes.days.filter(day => ensureJSTimestamp(day.start) >= loadingDayStart);
        },

        hasValidLoadingData() {
            const { date, time } = this.loadingWindow;

            if (!date || !this.availableLoadingTimes || !time?.startTime || !time?.endTime) {
                return false;
            }

            return (
                isDayMatchingRangeConfig(this.availableLoadingTimes.days)(date) === true &&
                (time.startTime === time.endTime || greaterThan(time.startTime, null, false)(time.endTime) === true)
            );
        },

        hasValidUnloadingData() {
            const { date, time } = this.unloadingWindow;

            if (!date || !this.availableUnloadingTimes || !time?.startTime || !time.endTime) {
                return false;
            }

            return (
                isDayMatchingRangeConfig(this.availableUnloadingTimes.days)(date) === true &&
                (time.startTime === time.endTime || greaterThan(time.startTime, null, false)(time.endTime) === true)
            );
        },
    },
    async created() {
        // Checks if we are creating a forwarding order (custom shipment)
        if (/custom-shipment-window-selection/.test(this.$route.name)) {
            this.defaultShipmentTimeFrom = await GlobalVariables.get(
                'default_shipment_planning_start_time_for_order',
                SHIPMENT_FALLBACK_TIME_CONSTRAINT_FROM
            );
            this.defaultShipmentTimeTo = await GlobalVariables.get(
                'default_shipment_planning_end_time_for_order',
                SHIPMENT_FALLBACK_TIME_CONSTRAINT_TO
            );
            // All other shipments
        } else {
            const dayOfWeek = getDay(window.date?.start);
            const times = this.openingTimes.openingTimes.find(ot => ot.dayOfWeek === dayOfWeek);

            // Checks if platform opening times are set for the selected day
            if (times?.start && times?.end) {
                this.defaultShipmentTimeFrom = times.start;
                this.defaultShipmentTimeTo = times.end;
            } else {
                this.defaultShipmentTimeFrom = SHIPMENT_FALLBACK_TIME_CONSTRAINT_FROM;
                this.defaultShipmentTimeTo = SHIPMENT_FALLBACK_TIME_CONSTRAINT_TO;
            }
        }
        await this.refreshAvailableTimes();
        this.presetData();
    },
    methods: {
        getEmptyWindow() {
            return {
                time: null,
                date: null,
            };
        },

        async refreshAvailableTimes() {
            try {
                const transportType = 'shipment';
                const projectId = this.projectId;

                const loadingTimes = await PlanningApi.getTimes({
                    transportType,
                    zip: this.shipmentLoading?.address.zip,
                    state: this.shipmentLoading?.address.state,
                    country: this.shipmentLoading?.address.country,
                    projectId,
                });

                this.availableLoadingTimes = {
                    ...loadingTimes,
                    range: loadingTimes.range ? parseInt(loadingTimes.range) : null,
                };

                const unloadingTimes = await PlanningApi.getTimes({
                    transportType,
                    zip: this.shipmentUnloading?.address.zip,
                    state: this.shipmentUnloading?.address.state,
                    country: this.shipmentLoading?.address.country,
                    projectId,
                });

                this.availableUnloadingTimes = {
                    ...unloadingTimes,
                    range: unloadingTimes.range ? parseInt(unloadingTimes.range) : null,
                };
            } catch (err) {
                Toaster.error(err);
            }
        },

        presetData() {
            if (this.shipmentShippingWindow?.loading) {
                this.loadingWindow = _cloneDeep(this.shipmentShippingWindow.loading);
                this.unloadingWindow = _cloneDeep(this.shipmentShippingWindow.unloading);
            }
        },

        handleLoadingWindowUpdate(window) {
            // Reset both
            if (!window) {
                this.loadingWindow = this.getEmptyWindow();
                this.unloadingWindow = this.getEmptyWindow();
                return;
            }

            let time = window.time;

            if (!time && window.date?.start) {
                time = {
                    startTime: this.defaultShipmentTimeFrom,
                    endTime: this.defaultShipmentTimeTo,
                };
            }

            this.loadingWindow = {
                date: window.date,
                time,
            };
        },

        handleUnloadingWindowUpdate(window) {
            // Reset
            if (!window) {
                this.unloadingWindow = this.getEmptyWindow();
                return;
            }

            this.unloadingWindow = {
                date: window.date,
                time: window.time?.startTime ? window.time : _cloneDeep(this.loadingWindow.time),
            };
        },

        submit() {
            if (!this.hasValidLoadingData || !this.hasValidUnloadingData) return;

            this.$store.commit('basket/setShipmentShippingWindow', {
                loading: this.loadingWindow,
                unloading: this.unloadingWindow,
            });

            this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(() => {});
        },
    },
};
</script>

<style lang="scss"></style>

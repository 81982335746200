import { PRIVILEGED_INTERVALS, getIntervals } from '@/constants/billingInterval';
import { PaymentTermsSelection } from '@/features/PaymentTermsSelection';
import GlobalVariables from '@/services/GlobalVariables';
import { Checkbox, ComboBox } from '@schuettflix/planum-react';
import { MessageBox } from '@schuettflix/react-components';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
function IntervalSelector(props) {
    const { t } = useTranslation();
    const [currentValue] = useState(props.value);
    const INTERVALS = getIntervals({ type: props.type, isManualEnabled: props.isManualEnabled });
    const showWarning = currentValue &&
        props.value &&
        PRIVILEGED_INTERVALS.includes(currentValue.interval) &&
        !PRIVILEGED_INTERVALS.includes(props.value.interval);
    if (props.isLoading) {
        return (<div className="animate-pulse" role="status">
                <div className="h-[4.5rem] rounded bg-surface-hovered"/>
            </div>);
    }
    return (<div className="flex flex-col gap-2">
            <ComboBox menuTrigger="focus" label={props.label} isDisabled={props.isDisabled && !props.canDowngrade} selectedKey={INTERVALS.find(i => i.interval.group === props.value?.group && i.interval.interval === props.value?.interval)?.id ?? null} onSelectionChange={key => {
            const interval = INTERVALS.find(i => i.id === key);
            if (interval && props.onChange) {
                props.onChange(interval.interval);
            }
        }}>
                {INTERVALS.map(interval => {
            const label = t(`pages.organization.form.interval.${interval.id}`);
            return (<ComboBox.Option isDisabled={props.canDowngrade && !interval.canBeDowngradedTo} key={interval.id} id={interval.id} label={label} textValue={label}/>);
        })}
            </ComboBox>
            {showWarning && (<MessageBox type="warning">
                    <p>
                        You selected a shorter interval than guaranteed by Schüttflix, you cannot come back to this
                        interval on your own after saving.
                    </p>
                </MessageBox>)}
        </div>);
}
export default function UnifiedAccounting(props) {
    const { t } = useTranslation();
    const update = useCallback((value) => {
        props.onChange({ ...props.organization, ...value });
    }, [props]);
    const [isManualMarket, setIsManualMarket] = useState(null);
    // Load the global variable on mount
    useEffect(() => {
        GlobalVariables.get('finance_allow_monthly_invoice_billing_interval', false).then(value => {
            setIsManualMarket(value);
        });
    }, []);
    const isClient = props.organization.types.includes('client');
    const isCarrier = props.organization.types.includes('carrier');
    const isSupplier = props.organization.types.includes('supplier');
    const canEditAccounting = props.abilities.organizationAccountingSettingManagement;
    const canDowngrade = !props.isPlatformUser && props.abilities.updateOrganization;
    const PaymentTerms = useCallback(({ type }) => {
        const selector = `${type}PaymentTerm`;
        const terms = props.organization[selector];
        if (!terms)
            return null;
        return (<PaymentTermsSelection disabled={!canEditAccounting} showLabel={false} organizationType={type} paymentTermId={terms.id} onChange={id => {
                if (id === null)
                    return;
                terms.id = id;
                props.organization[selector] = terms;
                props.onChange(props.organization);
            }}/>);
    }, [props, canEditAccounting]);
    const isLoading = isManualMarket === null;
    return (<div className="flex flex-col gap-4">
            <h2 className="font-headline-md-strong">{t('pages.organization.accounting.headline')}</h2>

            <div className="flex flex-col gap-12">
                {isClient && (<div className="flex flex-col gap-4">
                        <h3 className="font-copy-md-strong">{t('pages.organization.accounting.client.title')}</h3>
                        {props.isPlatformUser && (<Checkbox isDisabled={!props.abilities.updateOrganizationExcludedFromDunning} label={t('pages.oragization.accounting.form.checkbox.noDunning')} isSelected={props.organization.excludedFromDunning} onChange={excludedFromDunning => update({ excludedFromDunning })}/>)}
                        <div className="grid grid-cols-2 gap-4">
                            <PaymentTerms type="client"/>
                            <IntervalSelector isLoading={isLoading} isDisabled={!canEditAccounting} canDowngrade={canDowngrade} isManualEnabled={isManualMarket ?? false} type="invoice" label={t('pages.organization.accounting.form.interval.label')} value={{
                group: props.organization.invoiceBillingGroup,
                interval: props.organization.invoiceBillingInterval,
            }} onChange={interval => update({
                invoiceBillingInterval: interval.interval,
                invoiceBillingGroup: interval.group,
            })}/>
                        </div>
                    </div>)}

                {(isSupplier || isCarrier) && (<div className="flex flex-col gap-4">
                        <h3 className="font-copy-md-strong">{t('pages.organization.accounting.supplier.title')}</h3>
                        <Checkbox isDisabled={!canEditAccounting} label={t('pages.organization.form.partnerInvoicingSwitch')} isSelected={props.organization.isPartnerInvoicingEnabled} onChange={isPartnerInvoicingEnabled => update({ isPartnerInvoicingEnabled })}/>
                        {isSupplier && (<div className="flex flex-col gap-2">
                                <div className="font-copy-md">
                                    {t('pages.organization.accounting.form.supplier.title')}
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    <PaymentTerms type="supplier"/>
                                    <IntervalSelector isLoading={isLoading} label={t('pages.organization.accounting.form.interval.label')} isDisabled={props.organization.isPartnerInvoicingEnabled || !canEditAccounting} canDowngrade={canDowngrade} isManualEnabled={isManualMarket ?? false} type="credit_note" value={{
                    group: props.organization.creditNoteSupplierBillingGroup,
                    interval: props.organization.creditNoteSupplierBillingInterval,
                }} onChange={interval => update({
                    creditNoteSupplierBillingInterval: interval.interval,
                    creditNoteSupplierBillingGroup: interval.group,
                })}/>
                                </div>
                            </div>)}
                        {isCarrier && (<div className="flex flex-col gap-2">
                                <div className="font-copy-sm-strong">
                                    {t('pages.organization.accounting.form.carrier.title')}
                                </div>
                                <div className="grid grid-cols-2 gap-4">
                                    <PaymentTerms type="carrier"/>
                                    <IntervalSelector isLoading={isLoading} label={t('pages.organization.accounting.form.interval.label')} isDisabled={props.organization.isPartnerInvoicingEnabled || !canEditAccounting} canDowngrade={canDowngrade} isManualEnabled={isManualMarket ?? false} type="credit_note" value={{
                    group: props.organization.creditNoteCarrierBillingGroup,
                    interval: props.organization.creditNoteCarrierBillingInterval,
                }} onChange={interval => update({
                    creditNoteCarrierBillingInterval: interval.interval,
                    creditNoteCarrierBillingGroup: interval.group,
                })}/>
                                </div>
                            </div>)}
                    </div>)}
            </div>
        </div>);
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert-dialog",class:{
        'alert-dialog--error': _vm.isType('error'),
        'alert-dialog--success': _vm.isType('success'),
        'alert-dialog--warning': _vm.isType('warning'),
        'alert-dialog--info': _vm.isType('info'),
        [`alert-dialog--${_vm.type}`]: true,
        'alert-dialog--micro': _vm.micro,
        'alert-dialog--dark': _vm.dark,
    }},[_c('div',{staticClass:"alert-dialog__icon",class:{ 'alert-dialog__icon--primry': _vm.primary }},[(_vm.isIconType('error'))?_c('StopIcon',{staticClass:"icon--inline",attrs:{"width":"12"}}):_vm._e(),(_vm.isIconType('success'))?_c('CheckmarkIcon',{staticClass:"icon--inline",attrs:{"width":"12"}}):_vm._e(),(_vm.isIconType('warning'))?_c('WarningIcon',{staticClass:"icon--inline",attrs:{"width":"12"}}):_vm._e(),(_vm.isIconType('info') && _vm.primary)?_c('InfoIconPrimary',{staticClass:"icon--inline"}):(_vm.isIconType('info'))?_c('InfoIcon',{staticClass:"icon--inline",attrs:{"width":"12"}}):_vm._e()],1),_c('div',{staticClass:"alert-dialog__message"},[_vm._t("default"),(_vm.$slots.action && _vm.vertical)?_c('div',{staticClass:"alert-dialog__button"},[_vm._t("action")],2):_vm._e()],2),(_vm.$slots.action && !_vm.vertical)?_c('div',{staticClass:"alert-dialog__button"},[_vm._t("action")],2):_vm._e()])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
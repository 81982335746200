<template>
    <div :style="`grid-template-columns: ${columns}`" class="grid-table__head">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'GridTableHead',
    computed: {
        columns() {
            let columns = null;
            let parent = null;

            while (columns === null) {
                parent = parent === null ? this.$parent : parent.parentNode;

                if (!parent) {
                    break;
                }

                columns = parent.columns ? parent.columns : null;
            }

            return columns;
        },
    },
};
</script>

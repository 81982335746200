import { ensureJSTimestamp } from '@/services/utils/date';
import OrganizationView from '@/models/OrganizationView';
import LocationInfoView from '@/models/LocationInfoView';
import UserView from '@/models/UserView';
import ConstructionSiteView from '@/models/ConstructionSiteView';
import ProductView from '@/models/ProductView';
import i18n from '@/i18n';
import _uniq from 'lodash/uniq';
import _mapValues from 'lodash/mapValues';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';
import { isOrderLineItemGroupTypeDisposal } from '@/constants/disposal';
import { getTranslationFromTranslationMap } from '@/services/utils/translation';

/**
 * Class representing address view model
 */
export default class OrderItemBlockView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     * @return {OrderItemBlockView|null}
     */
    static create(data) {
        if (!data) return null;

        const lineItemGroup = data.lineItemGroups[0];

        const view = new OrderItemBlockView();

        const variantDisposal = lineItemGroup?.product?.pollutionType
            ? { isActive: true, maximumQty: null, minimumQty: null, pollutionType: lineItemGroup.product.pollutionType }
            : null;

        view.id = data.id;
        view.genericOrderId = data.genericOrderId;
        view.number = data.number;
        view.created = data.created;
        view.orderType = lineItemGroup?.type;
        view.lineItemGroups = data.lineItemGroups;
        view.orderStatus = view.isGenericOrder ? data.status : lineItemGroup?.status || data.status;
        view.type = data.type;
        view.transportType = lineItemGroup?.type;
        view.invoiceStatus = data.invoiceStatus;
        view.invoiceGrossTotal = data.invoiceGrossTotal;
        view.totalGrossRetailPrice = data.totalGrossRetailPrice;
        view.projectPositionId = lineItemGroup?.projectPositionId;
        view.freightType = lineItemGroup?.freightType;
        view.freightDescription = lineItemGroup?.freightDescription;
        view.qty = lineItemGroup?.qty;
        view.expireDate = lineItemGroup?.expireDate;
        view.planningBase = lineItemGroup?.planningBase;
        view.lineItems = lineItemGroup?.lineItems;
        view.client = OrganizationView.create(data.client);
        view.createdBy = UserView.create(data.createdBy);
        view.supervisor = UserView.create(data.supervisor);
        view.verifiedBy = UserView.create(data.verifiedBy);
        view.constructionSite = ConstructionSiteView.create(
            this.isGenericOrder ? this.primaryProduct.lineItemGroup.constructionSite : lineItemGroup?.constructionSite
        );
        view.supplier = OrganizationView.create(lineItemGroup?.supplier);
        view.product = ProductView.create(lineItemGroup?.product, variantDisposal);
        view.shippingMethod = lineItemGroup?.shippingMethod;
        view.planningMethod = lineItemGroup?.planningMethod;
        view.shippingWindowStart = lineItemGroup?.shippingWindowStart;
        view.shippingWindowEnd = lineItemGroup?.shippingWindowEnd;

        if (
            [
                ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_NON_HAZARDOUS_WASTE,
                ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_HAZARDOUS_WASTE,
                ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT,
            ].includes(lineItemGroup.type)
        ) {
            view.loading = LocationInfoView.createFromLoadingLocation(lineItemGroup);
            view.unloading = LocationInfoView.createFromUnloadingLocation(lineItemGroup);

            view.loadingDateFrom = lineItemGroup?.loadingDateFrom;
            view.loadingDateTo = lineItemGroup?.loadingDateTo;
            view.unloadingDateFrom = lineItemGroup?.unloadingDateFrom;
            view.unloadingDateTo = lineItemGroup?.unloadingDateTo;
        }
        if ([ORDER_LINE_ITEM_GROUP_TYPE.DELIVERY, ORDER_LINE_ITEM_GROUP_TYPE.PICKUP].includes(lineItemGroup.type)) {
            view.loading = LocationInfoView.createFromLoadingLocation(lineItemGroup);
        }

        return view;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._id = null;
        this._number = null;
        this._created = null;
        this._orderType = null;
        this._orderStatus = null;
        this._type = null;
        this._transportType = null;
        this._invoiceStatus = null;
        this._invoiceGrossTotal = null;
        this._totalGrossRetailPrice = null;
        this._projectPositionId = null;
        this._freightType = null;
        this._freightDescription = null;
        this._qty = null;
        this._expireDate = null;
        this._planningBase = null;
        this._lineItems = null;
        this._client = null;
        this._supplier = null;
        this._loading = null;
        this._unloading = null;
        this._createdBy = null;
        this._supervisor = null;
        this._verifiedBy = null;
        this._constructionSite = null;
        this._product = null;
        this._shippingMethod = null;
        this._planningMethod = null;
        this._loadingDateFrom = null;
        this._loadingDateTo = null;
        this._unloadingDateFrom = null;
        this._unloadingDateTo = null;
        this._shippingWindowStart = null;
        this._shippingWindowEnd = null;
        this._lineItemGroups = null;
    }

    /**
     * @returns {Object}
     */
    get id() {
        return this._id;
    }

    /**
     * @param {Object} value
     */
    set id(value) {
        this._id = value ?? null;
    }

    /**
     * @returns {string | undefined}
     */
    get genericOrderId() {
        return this._genericOrderId;
    }

    /**
     * @param {string | undefined} value
     */
    set genericOrderId(value) {
        this._genericOrderId = value;
    }

    /**
     * @returns {number|null}
     */
    get number() {
        return this._number;
    }

    /**
     * @param {number} value
     */
    set number(value) {
        this._number = value ?? null;
    }

    /**
     * @returns {number}
     */
    get created() {
        return this._created;
    }

    /**
     * @param {number} value
     */
    set created(value) {
        this._created = ensureJSTimestamp(value);
    }

    /**
     * @returns {string}
     */
    get orderType() {
        return this._orderType;
    }

    /**
     * @param {string} value
     */
    set orderType(value) {
        this._orderType = value ?? null;
    }

    /**
     * @returns {string}
     */
    get orderStatus() {
        return this._orderStatus;
    }

    /**
     * @param {string} value
     */
    set orderStatus(value) {
        this._orderStatus = value ?? null;
    }

    /**
     * @returns {string}
     */
    get type() {
        return this._type;
    }

    /**
     * @param {string} value
     */
    set type(value) {
        this._type = value ?? null;
    }

    /**
     * @returns {string}
     */
    get transportType() {
        return this._transportType;
    }

    /**
     * @param {string} value
     */
    set transportType(value) {
        this._transportType = value ?? null;
    }

    /**
     * @returns {string}
     */
    get invoiceStatus() {
        return this._invoiceStatus;
    }

    /**
     * @param {string} value
     */
    set invoiceStatus(value) {
        this._invoiceStatus = value ?? null;
    }

    /**
     * @returns {number}
     */
    get invoiceGrossTotal() {
        return this._invoiceGrossTotal;
    }

    /**
     * @param {number|null} value
     */
    set invoiceGrossTotal(value) {
        this._invoiceGrossTotal = value ?? null;
    }

    /**
     * @returns {number}
     */
    get totalGrossRetailPrice() {
        return this._totalGrossRetailPrice;
    }

    /**
     * @param {number|null} value
     */
    set totalGrossRetailPrice(value) {
        this._totalGrossRetailPrice = value ?? null;
    }

    /**
     * @returns {number}
     */
    get projectPositionId() {
        return this._projectPositionId;
    }

    /**
     * @param {number|null} value
     */
    set projectPositionId(value) {
        this._projectPositionId = value ?? null;
    }

    /**
     * @returns {string}
     */
    get freightType() {
        return this._freightType;
    }

    /**
     * @param {string|null} value
     */
    set freightType(value) {
        this._freightType = value ?? null;
    }

    /**
     * @returns {string}
     */
    get freightDescription() {
        return this._freightDescription;
    }

    /**
     * @param {string|null} value
     */
    set freightDescription(value) {
        this._freightDescription = value ?? null;
    }

    /**
     * @returns {number}
     */
    get qty() {
        if (this.isGenericOrder) return this.primaryProduct.lineItemGroup.qty;
        return this._qty / 1000;
    }

    /**
     * @param {number|null} value
     */
    set qty(value) {
        this._qty = value ?? null;
    }

    get qtyUnitTranslationKey() {
        if (!this.isGenericOrder) return 'unitsPluralized.ton';

        const unitTranslationKeyMap = {
            DAY: 'unitsPluralized.day',
            HOUR: 'unitsPluralized.hour',
            KILOMETER: 'unitsPluralized.kilometer',
            KILOGRAM: 'unitsPluralized.kilogram',
            TON: 'unitsPluralized.ton',
            CUBIC_METER: 'unitsPluralized.cbm',
            CUBIC_CENTIMETER: 'unitsPluralized.cubicCentimeter',
            LITER: 'unitsPluralized.liter',
            PIECE: 'unitsPluralized.piece',
            TRANSPORT: 'unitsPluralized.transport',
            FLAT_RATE: 'unitsPluralized.flatRate',
        };
        return unitTranslationKeyMap[this.primaryProduct.lineItemGroup.qtyUnit] || 'unitsPluralized.ton';
    }

    /**
     * @returns {number}
     */
    get expireDate() {
        return this._expireDate;
    }

    /**
     * @param {number|null} value
     */
    set expireDate(value) {
        this._expireDate = ensureJSTimestamp(value);
    }

    /**
     * @returns {string}
     */
    get planningBase() {
        return this._planningBase;
    }

    /**
     * @param {string|null} value
     */
    set planningBase(value) {
        this._planningBase = value ?? null;
    }

    /**
     * @returns {Array}
     */
    get lineItems() {
        return this._lineItems;
    }

    /**
     * @param {Array|null} value
     */
    set lineItems(value) {
        this._lineItems = value ?? null;
    }

    /**
     * @returns {OrganizationView}
     */
    get client() {
        return this._client;
    }

    /**
     * @param {OrganizationView} value
     */
    set client(value) {
        this._client = value ?? null;
    }

    /**
     * @returns {OrganizationView}
     */
    get supplier() {
        return this._supplier;
    }

    /**
     * @param {OrganizationView} value
     */
    set supplier(value) {
        this._supplier = value ?? null;
    }

    /**
     * @returns {LocationInfoView|null}
     */
    get loading() {
        return this._loading;
    }

    /**
     * @param {LocationInfoView} value
     */
    set loading(value) {
        this._loading = value ?? null;
    }

    /**
     * @returns {LocationInfoView|null}
     */
    get unloading() {
        return this._unloading;
    }

    /**
     * Set order
     * @param {LocationInfoView} value
     */
    set unloading(value) {
        this._unloading = value ?? null;
    }

    /**
     * @returns {UserView|null}
     */
    get createdBy() {
        return this._createdBy;
    }

    /**
     * Set order
     * @param {UserView} value
     */
    set createdBy(value) {
        this._createdBy = value ?? null;
    }

    /**
     * @returns {UserView|null}
     */
    get supervisor() {
        return this._supervisor;
    }

    /**
     * Set order
     * @param {UserView} value
     */
    set supervisor(value) {
        this._supervisor = value ?? null;
    }

    /**
     * @returns {UserView|null}
     */
    get verifiedBy() {
        return this._verifiedBy;
    }

    /**
     * Set order
     * @param {UserView} value
     */
    set verifiedBy(value) {
        this._verifiedBy = value ?? null;
    }

    /**
     * @returns {ConstructionSiteView|null}
     */
    get constructionSite() {
        return this._constructionSite;
    }

    /**
     * Set order
     * @param {ConstructionSiteView} value
     */
    set constructionSite(value) {
        this._constructionSite = value ?? null;
    }

    /**
     * @returns {ProductView|null}
     */
    get product() {
        return this._product;
    }

    /**
     * Set order
     * @param {ProductView} value
     */
    set product(value) {
        this._product = value ?? null;
    }

    /**
     * @returns {string|null}
     */
    get shippingMethod() {
        return this._shippingMethod;
    }

    /**
     * Set order
     * @param {string} value
     */
    set shippingMethod(value) {
        this._shippingMethod = value ?? null;
    }

    /**
     * @returns {string|null}
     */
    get planningMethod() {
        return this._planningMethod;
    }

    /**
     * Set order
     * @param {string} value
     */
    set planningMethod(value) {
        this._planningMethod = value ?? null;
    }

    /**
     * @returns {number|null}
     */
    get loadingDateFrom() {
        return this._loadingDateFrom;
    }

    /**
     * Set order
     * @param {number} value
     */
    set loadingDateFrom(value) {
        this._loadingDateFrom = ensureJSTimestamp(value);
    }

    /**
     * @returns {number|null}
     */
    get loadingDateTo() {
        return this._loadingDateTo;
    }

    /**
     * Set order
     * @param {number} value
     */
    set loadingDateTo(value) {
        this._loadingDateTo = ensureJSTimestamp(value);
    }

    /**
     * @returns {number|null}
     */
    get unloadingDateFrom() {
        return this._unloadingDateFrom;
    }

    /**
     * Set order
     * @param {number} value
     */
    set unloadingDateFrom(value) {
        this._unloadingDateFrom = ensureJSTimestamp(value);
    }

    /**
     * @returns {number|null}
     */
    get unloadingDateTo() {
        return this._unloadingDateTo;
    }

    /**
     * Set order
     * @param {number} value
     */
    set unloadingDateTo(value) {
        this._unloadingDateTo = ensureJSTimestamp(value);
    }

    /**
     * @returns {number|null}
     */
    get shippingWindowStart() {
        return this._shippingWindowStart;
    }

    /**
     * Set order
     * @param {number} value
     */
    set shippingWindowStart(value) {
        this._shippingWindowStart = ensureJSTimestamp(value);
    }

    /**
     * @returns {number|null}
     */
    get shippingWindowEnd() {
        return this._shippingWindowEnd;
    }

    /**
     * Set order
     * @param {number} value
     */
    set shippingWindowEnd(value) {
        this._shippingWindowEnd = ensureJSTimestamp(value);
    }

    get lineItemGroups() {
        return this._lineItemGroups;
    }

    set lineItemGroups(value) {
        this._lineItemGroups = value;
    }

    /**
     * @return {boolean}
     */
    get showProjectIndicator() {
        return !!this.projectPositionId;
    }

    get totalTransportAmount() {
        return this.lineItems?.length ?? 0;
    }

    get vehicleName() {
        return this.lineItems[0]?.vehicleName ?? null;
    }

    get isPickup() {
        return this.transportType === ORDER_LINE_ITEM_GROUP_TYPE.PICKUP;
    }

    get isDisposal() {
        return isOrderLineItemGroupTypeDisposal(this.transportType);
    }

    get isDelivery() {
        return this.transportType === ORDER_LINE_ITEM_GROUP_TYPE.DELIVERY;
    }

    get isShipment() {
        return this.transportType === ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT;
    }

    get isGenericOrder() {
        return this.orderType === ORDER_LINE_ITEM_GROUP_TYPE.GENERIC_ORDER;
    }

    get isInvoicePaid() {
        return this.invoiceStatus === 'paid';
    }

    get isInvoiceCancelled() {
        return this.invoiceStatus === 'cancelled';
    }

    get isInvoicePending() {
        return ['created', 'booked'].includes(this.invoiceStatus);
    }

    get isStatusSuccess() {
        return ['closed', 'pending'].includes(this.orderStatus);
    }

    get isStatusWarning() {
        return ['new', 'in_progress'].includes(this.orderStatus);
    }

    get showExpireDate() {
        return this.expireDate && this.isStatusWarning;
    }

    get isStatusDanger() {
        return ['canceled', 'rejected'].includes(this.orderStatus);
    }

    get hasFulfilmentRange() {
        return this.isDelivery || this.isShipment || this.isDisposal || this.isGenericOrder;
    }

    get fulfilmentRangeLabel() {
        if (this.isGenericOrder) {
            return i18n.t('components.orderItemBlock.fulfilmentBase.serviceDate');
        }

        let base = 'loading';
        if (this.isDelivery || (this.isShipment && this.planningBase === 'unloading')) {
            base = 'unloading';
        }

        return i18n.t(`components.orderItemBlock.fulfilmentBase.${base}`);
    }

    get fulfilmentRange() {
        const getMinMax = () => {
            if (this.isGenericOrder) {
                return this.lineItemGroups.reduce(
                    (acc, lineItemGroup) => {
                        const values = [
                            lineItemGroup.shippingWindowStart,
                            lineItemGroup.shippingWindowEnd,
                            acc.min,
                            acc.max,
                        ]
                            .filter(v => v !== null)
                            .map(ensureJSTimestamp);
                        return {
                            min: Math.min(...values),
                            max: Math.max(...values),
                        };
                    },
                    { min: null, max: null }
                );
            }

            if (this.shippingMethod === 'flix' || this.planningMethod === 'planned') {
                return this.lineItems?.reduce(
                    (acc, item) => {
                        if (!item.shippingDate) return acc;
                        const shippingDate = ensureJSTimestamp(item.shippingDate);
                        return {
                            min: shippingDate < acc.min || acc.min === null ? shippingDate : acc.min,
                            max: shippingDate > acc.max || acc.max === null ? shippingDate : acc.max,
                        };
                    },
                    { min: null, max: null }
                );
            }

            if (this.isShipment) {
                return {
                    min: this.loadingDateFrom,
                    max: this.unloadingDateTo,
                };
            }

            return {
                min: this.shippingWindowStart,
                max: this.shippingWindowEnd,
            };
        };

        const { min, max } = getMinMax();

        let range = [];
        if (min !== null) {
            range.push(min);
        }

        if (max !== null) {
            range.push(max);
        }

        if (range.length === 0) {
            return null;
        }

        range = range.map(v => i18n.d(v, 'short'));

        return _uniq(range).join(' - ');
    }

    get hasPeople() {
        return this.createdBy || this.supervisor || this.verifiedBy;
    }

    get productName() {
        if (this.isGenericOrder) {
            return getTranslationFromTranslationMap(this.primaryProduct.lineItemGroup.product?.productName || {});
        }
        if (this.isDisposal) {
            return `${this.product?.wasteCode || ''}${this.product.isHazardousWaste ? '*' : ''} ${
                this.product?.productNameLocalized
            }`;
        }
        return this.product?.productNameLocalized;
    }

    /** @returns {{material: object, transport: object, service: object} | null} */
    get primaryLineItemGroupMap() {
        if (!this.isGenericOrder) return null;

        const productTypeMap = {
            material: ['MATERIAL_TRANSPORT', 'MATERIAL'],
            transport: ['MATERIAL_TRANSPORT', 'TRANSPORT'],
            service: ['MATERIAL_SERVICE', 'TRANSPORT_SERVICE'],
        };

        return _mapValues(productTypeMap, productTypes =>
            this.lineItemGroups.find(lineItemGroup => productTypes.includes(lineItemGroup.productType))
        );
    }

    /** @returns {{material?: string, transport?: string, service?: string}} */
    get primaryCompanyNameMap() {
        if (!this.isGenericOrder) return {};

        return _mapValues(this.primaryLineItemGroupMap, lineItemGroup => lineItemGroup?.partner?.name);
    }

    /** @returns {{icon: 'material' | 'transport' | 'service', lineItemGroup: object}} */
    get primaryProduct() {
        const { material, transport, service } = this.primaryLineItemGroupMap || {};
        if (material) return { icon: 'material', lineItemGroup: material };
        if (transport) return { icon: 'transport', lineItemGroup: transport };
        if (service) return { icon: 'service', lineItemGroup: service };

        return { icon: 'material', lineItemGroup: this.lineItemGroups[0] };
    }

    /** @returns {'material' | 'transport' | 'service'} */
    get productIcon() {
        return this.primaryProduct.icon;
    }
}

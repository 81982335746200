<template>
    <LayoutPage is-flyout :screen-name="screenName" class="checkout-shipment-time-selection-page csts-page">
        <Header slot="flyoutHeader" />

        <div class="container-deprecated">
            <Headline :level="3">
                {{ $tc(`pages.checkout.shipmentTimeSelection.headline.${planningMethod}`, shipmentTransportCount) }}
            </Headline>

            <template v-if="shipmentTransportCount > 1">
                <Words block spaced-top>
                    {{ $t('pages.checkout.shipmentTimeSelection.subLine') }}
                </Words>

                <Button class="csts__planing-assistant-btn" @click="requestPlanningAssistance">
                    {{ $t('pages.checkout.shipmentTimeSelection.planningAssistant') }} &rsaquo;
                </Button>
            </template>
        </div>

        <template v-if="!isInitializing">
            <div v-if="hasShipmentTransports">
                <DatetimePicker
                    v-for="(transport, i) in transports.transports"
                    :key="i"
                    :value="ensureJSTimestamp(transport.date)"
                    :minute-steps="allowedMinuteSteps"
                    :range-config="rangeConfig"
                    :active="hasSingleTransport"
                    @input="updateTransport(i, $event)"
                    @visually-opened="resetTransportError(i)"
                >
                    <div class="csts__transport" data-test="button-select-date-and-time">
                        <div class="csts__truck-icon">
                            <VehicleClassIconSet :icon="transport.icon" />
                        </div>
                        <Words bold small class="csts__truck-name">
                            {{ transport.name }}
                        </Words>
                        <div class="csts__date-picker">
                            <span v-if="transport.date">
                                {{ $d(ensureJSTimestamp(transport.date), 'short') }},
                                {{ $t('timeSuffix', { time: $d(ensureJSTimestamp(transport.date), 'time') }) }}
                            </span>
                            <span v-else>
                                {{ $t('pages.checkout.shipmentTimeSelection.chooseDateAndTime') }}
                            </span>
                        </div>
                        <TickIcon
                            :style="{ visibility: transport.date ? 'visible' : 'hidden' }"
                            width="16"
                            height="16"
                            class="csts__tick-icon"
                        />
                    </div>

                    <div v-if="transport.error && transport.error.shippingDate" class="csts__transport-errors">
                        <ErrorMessage
                            v-for="(error, index) in transport.error.shippingDate"
                            :key="index"
                            :message="error"
                            class="csts__transport-error-msg"
                        />
                    </div>
                </DatetimePicker>
            </div>

            <SlideUp slot="sticky" :active="isShipmentTransportsValid">
                <ButtonGroup>
                    <Button primary type="button" data-test="button-apply-scheduled-period" @click="next">
                        {{ $t('pages.checkout.shipmentTimeSelection.submitButtonLabel') }}
                        <ArrowRightIcon slot="right" class="icon--inline" />
                    </Button>
                </ButtonGroup>
            </SlideUp>
        </template>
    </LayoutPage>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapState, mapGetters } from 'vuex';
import { ensureJSTimestamp, ensurePHPTimestamp } from '@/services/utils/date';
import getTime from 'date-fns/get_time';
import VehicleClassApi from '@/services/Api/VehicleClass';
import PlanningApi from '@/services/Api/Planning';
import Toaster from '@/services/Toaster';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import DatetimePicker from '@/components/Form/DatetimePicker';
import ErrorMessage from '@/components/Form/ErrorMessage';
import Header from './components/Header';
import Headline from '@/components/Typography/Headline';
import LayoutPage from '@/components/Layout/Page.v2';
import SlideUp from '@/components/Animation/SlideUp';
import VehicleClassIconSet from '@/components/IconSet/VehicleClassIconSet';
import Words from '@/components/Typography/Words';

import ArrowRightIcon from '@/assets/icons/micro/arrow.svg';
import TickIcon from '@/assets/icons/micro/tick.svg';
import { prepareRangeConfig } from '@/services/PlanningAssistant/PlanningAssistant';
import { useState } from 'vuex-composition-helpers';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';

export default {
    name: 'ShipmentTimeSelectionPage',
    components: {
        Button,
        ButtonGroup,
        DatetimePicker,
        ErrorMessage,
        Header,
        Headline,
        LayoutPage,
        SlideUp,
        VehicleClassIconSet,
        Words,

        ArrowRightIcon,
        TickIcon,
    },
    setup() {
        const { planningMethod } = useState('basket', ['planningMethod']);

        const screenName = planningMethod.value
            ? useOrderScreenName(`${planningMethod.value}timeselection`)
            : undefined;

        return {
            screenName,
        };
    },
    data() {
        return {
            isInitializing: true,
            selectedVehicleClass: null,
        };
    },
    computed: {
        ...mapState('basket', [
            'shipmentPlanningConfig',
            'shipmentQtyAndDocument',
            'planningMethod',
            'shipmentLoading',
            'shipmentUnloading',
            'shipmentVehicleClassId',
            'transports',
            'projectId',
        ]),

        ...mapGetters('basket', ['allowedMinuteSteps']),

        /**
         * Get the shipment transport count.
         *
         * This value can diverge from state.transports.transports.length because it is set in a previous step and the transport
         * state (state.transports) is build up later based on this count.
         * @return {number|null}
         */
        shipmentTransportCount() {
            return this.shipmentQtyAndDocument?.transportCount ?? null;
        },

        /**
         * Checks if the shipment transports state has any transports
         * @return {boolean}
         */
        hasShipmentTransports() {
            return this.transports?.transports?.length > 0;
        },

        /**
         * Checks if the shipment transports state is valid: at least one transport is present and all has a planned date
         * @return {boolean}
         */
        isShipmentTransportsValid() {
            return this.hasShipmentTransports && this.hasAllShipmentTransportsADate;
        },

        /**
         * Checks if all transports have a planned date.
         * @return {boolean}
         */
        hasAllShipmentTransportsADate() {
            return this.transports?.transports?.every(t => !!t.date) ?? true;
        },

        /**
         * Checks if all transports have no planned date.
         * @return {boolean}
         */
        hasAllShipmentTransportsNoDate() {
            return this.transports?.transports?.every(t => !t.date) ?? true;
        },

        /**
         * Get the shipment data based on the current shipping window type (loading or unloading)
         * @return {object|null}
         */
        shipment() {
            return (
                {
                    loading: this.shipmentLoading,
                    unloading: this.shipmentUnloading,
                }[this.planningMethod] ?? null
            );
        },

        /**
         * Get the federal states for the loading and unloading address of a shipment
         * @return {array}
         */
        shipmentFederalStates() {
            return [this.shipmentLoading.address.state, this.shipmentUnloading.address.state];
        },

        rangeConfig() {
            return this.shipmentPlanningConfig?.days ?? null;
        },

        hasSingleTransport() {
            return this.transports?.transports?.length === 1;
        },
    },
    async created() {
        await this.loadSelectedVehicleClass();
        this.prepareTransports();
        await this.loadAvailableDays();

        if (this.shipmentTransportCount > 1 && this.hasAllShipmentTransportsNoDate) {
            this.requestPlanningAssistance();
        }

        this.isInitializing = false;
    },
    methods: {
        ...mapActions('basket', ['requestPlanningAssistance']),

        ensureJSTimestamp,

        /**
         * Initialize and set the transport state. If transports are already present, merge and update the present ones
         */
        prepareTransports() {
            const transports = [];

            for (let i = 0; i < this.shipmentTransportCount; i++) {
                const oldTransport = _cloneDeep(this.transports?.transports?.[i]);

                const newTransport = {
                    ...this.selectedVehicleClass,
                    date: oldTransport?.date ?? null,
                    error: oldTransport?.error ?? null,
                };

                transports.push({ ...newTransport });
            }

            this.$store.commit('basket/setTransports', { transports });
        },

        /**
         * Load the vehicleClass by the previous selected shipmentVehicleClassId
         * @return {Promise<void>}
         */
        async loadSelectedVehicleClass() {
            try {
                const response = await VehicleClassApi.filter({ type: 'shipment' });
                this.selectedVehicleClass = response.items.find(vc => vc.id === this.shipmentVehicleClassId);
            } catch (error) {
                Toaster.error(error);
            }
        },

        /**
         * Load the available days (days with start and end timestamp)
         * @return {Promise<void>}
         */
        async loadAvailableDays() {
            try {
                const transportType = 'shipment';
                const { address } = this.shipment;
                const { zip, country } = address;
                const state = this.shipmentFederalStates;
                const projectId = this.projectId;

                const planningConfig = await PlanningApi.getTimes({
                    transportType,
                    country,
                    zip,
                    state,
                    projectId,
                });

                // Transform range config for FE use
                planningConfig.days = prepareRangeConfig(planningConfig.days);

                this.$store.commit('basket/setShipmentPlanningConfig', planningConfig);
            } catch (err) {
                Toaster.error(err);
            }
        },

        /**
         * Reset transport error for a given transport by index
         * @param {number} index
         */
        resetTransportError(index) {
            this.$store.commit('basket/resetTransportError', index);
        },

        /**
         * Update a transport (date) for a given transport by index
         * @param {number} index
         * @param {string} date timestamp
         */
        updateTransport(index, date) {
            const transports = _cloneDeep(this.transports);
            transports.transports[index].date = ensurePHPTimestamp(getTime(date));
            this.$store.commit('basket/setTransports', transports);
        },

        /**
         * Go to the next checkout step
         */
        next() {
            this.$logger().log('Submit selected dates');
            if (this.isShipmentTransportsValid) {
                this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(() => {});
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.csts__transport {
    background-color: $color-white;
    padding: 21px 10px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-top: 2px;
}

.csts__truck-icon {
    flex: 0 0 52px;
    text-align: right;

    > * {
        height: 25px;
        width: auto;
    }
}

.csts__truck-name {
    flex: 1 1 15%;
    text-align: center;
    padding: 0 10px;
}

.csts__date-picker {
    flex: 1 0 20%;
    text-align: center;
}

.csts__planing-assistant-btn {
    margin: 20px 0;
}

.csts__tick-icon {
    fill: $color-green;
    margin-right: 10px;
}

.csts__transport-error-msg {
    margin-top: 0;
    margin-bottom: 7px;
}
</style>

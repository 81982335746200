import { formatDateRange } from '@/services/utils/date';
import { calculateValidityInDays } from '@/constructionProjects/utils/validity';
import { DAYS_LEFT_NOTIFICATION_THRESHOLD } from '@/constructionProjects/constants';
export function useConstructionProjectValidity({ validFrom, validTo, }) {
    const validityRange = formatDateRange(new Date(validFrom).getTime(), new Date(validTo).getTime());
    const daysLeft = calculateValidityInDays(new Date(validTo).toISOString());
    const showWarning = daysLeft > 0 && daysLeft <= DAYS_LEFT_NOTIFICATION_THRESHOLD;
    return {
        validityRange,
        daysLeft,
        showWarning,
    };
}

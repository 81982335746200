import AbstractFilterableResource from './AbstractFilterableResource';
import { serializeAsArray } from '@/services/Api/querySerializer';
class ProductApi extends AbstractFilterableResource {
    /**
     * Get order restrictions by order type
     * @param {string} orderType which is used to order the product
     */
    async getOrderRestrictions(orderType) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/order/restrictions/order-type/${orderType}`);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    /**
     * Get product definition by hash and order type
     */
    async getProductByAttributes(orderType, locationId, masterProductId, attributeRange, attributes) {
        try {
            const type = orderType === 'project-delivery' ? 'delivery' : orderType;
            const response = await this.apiService.get(`${this.resourcePath}/product-configuration/${type}/location/${locationId}/master-product/${masterProductId}`, {
                params: { attributeRange, attributes },
                paramsSerializer: serializeAsArray,
            });
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async getDeliverySellers(locationId, masterProductId, payload) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/seller-selection/delivery/location/${locationId}/master-product/${masterProductId}`, payload);
            return response?.data || [];
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async getSellersByProductAttributes(orderType, locationId, masterProductId, attributeRange, attributes, options) {
        try {
            const type = orderType === 'project-delivery' ? 'delivery' : orderType;
            const response = await this.apiService.get(`${this.resourcePath}/seller-selection/${type}/location/${locationId}/master-product/${masterProductId}`, {
                params: { attributeRange, attributes, ...options },
                paramsSerializer: serializeAsArray,
            });
            return response?.data || [];
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async getCategories(orderType, locationId) {
        try {
            const type = orderType === 'project-delivery' ? 'delivery' : orderType;
            const response = await this.apiService.get(`${this.resourcePath}/order/available-categories/${type}/location/${locationId}`);
            return response?.data?.categories || [];
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async getAttributeOptionsAvailable(orderType, locationId, masterProductId, attributeRange, attributes, attributeSetId) {
        try {
            const type = orderType === 'project-delivery' ? 'delivery' : orderType;
            const response = await this.apiService.get(`${this.resourcePath}/product-configuration/${type}/location/${locationId}/master-product/${masterProductId}/attribute-set/${attributeSetId}/available-options`, {
                params: { attributeRange, attributes },
                paramsSerializer: serializeAsArray,
            });
            return response?.data || [];
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
}
export default new ProductApi('');

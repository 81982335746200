var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-input",class:{
        'select-input--shadow': _vm.shadow && !_vm.dark,
        'select-input--dark': _vm.dark,
        'select-input--small': _vm.small,
        'select-input--tiny': _vm.tiny,
        'select-input--invalid': _vm.error || _vm.hasError,
    }},[_c('div',{staticClass:"select-input__wrapper"},[_c('select',{staticClass:"select-input__input",class:{ 'select-input__input--no-value': _vm.value === null && _vm.label },attrs:{"id":_vm.eid},domProps:{"value":_vm.value},on:{"change":function($event){return _vm.$emit('input', $event.target.value)}}},[_vm._t("default")],2),(_vm.label && _vm.value === null)?_c('label',{staticClass:"select-input__label",class:{ 'select-input__label--dark': _vm.dark },attrs:{"for":_vm.eid}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('ArrowDownIcon',{staticClass:"select-input__arrow select-input__arrow--down"})],1),(_vm.error)?_c('ErrorMessage',{attrs:{"message":_vm.error,"dark":_vm.dark}}):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
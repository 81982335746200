<template>
    <div class="waste-list">
        <portal to="waste-management-filter-portal">
            <FilterBox
                v-model="filter"
                :default-filter="filter"
                :endpoint="dataEndpoint"
                inline-mode
                no-padding
                @update="refreshList()"
            >
                <template #default="filterScope">
                    <div class="waste-list__filter">
                        <TextField
                            v-model="filterScope.filter.search"
                            :label="$t('pages.productManagement.searchLabel')"
                        />
                    </div>
                </template>
            </FilterBox>
        </portal>

        <div class="waste-list__info-display">
            <Words bold class="headline">{{
                $tc('pages.productManagement.wasteListPage.productsLabel', wasteItemsTotalCount)
            }}</Words>
            <Words v-if="wasteItemsInactiveCount" muted class="sub-headline"
                >({{ wasteItemsActiveCount }} {{ $t('pages.productManagement.wasteListPage.available') }},
                {{ wasteItemsInactiveCount }} {{ $t('pages.productManagement.wasteListPage.notAvailable') }})
            </Words>
            <Words v-else muted class="sub-headline"
                >({{ wasteItemsActiveCount }} {{ $t('pages.productManagement.wasteListPage.available') }})
            </Words>
        </div>

        <GridRow :count="1" spacing="large">
            <LoadingSpinner v-if="isPending('refresh')" block dark />

            <transition name="fade" mode="out-in">
                <div>
                    <template v-if="wasteGroups && wasteGroups.length > 0">
                        <div class="waste-list__groups">
                            <WasteGroup
                                v-for="(wasteGroup, w) in wasteGroups"
                                :key="w"
                                :group="wasteGroup"
                                @click-product-item="onClickWasteGroup"
                            >
                                <GridTableHead
                                    v-if="w === 0"
                                    slot="header"
                                    v-stuckable="20"
                                    class="waste-group__table-head"
                                >
                                    <Words small bold block>
                                        {{ $t('pages.productManagement.wasteListPage.wasteCode') }}
                                    </Words>
                                    <Words small bold block>
                                        {{ $t('pages.productManagement.wasteListPage.name') }}
                                    </Words>
                                    <Words small bold block align-right>
                                        {{ $t('pages.productManagement.wasteListPage.availability') }}
                                    </Words>
                                </GridTableHead>
                            </WasteGroup>
                        </div>
                    </template>
                    <Hint v-else-if="wasteGroups && wasteGroups.length === 0" center>
                        {{ $t('pages.productManagement.wasteListPage.noResults') }}
                    </Hint>
                </div>
            </transition>
        </GridRow>

        <Flyout
            size="small"
            screen-name="platform-productmanagement-waste-edit"
            :route="productEditRoute"
            no-header
            @closed="refreshList()"
        />
    </div>
</template>

<script>
import _intersection from 'lodash/intersection';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import { preciseFloat } from '@/services/utils';
import { structurizeSortedWasteCategories, structurizeWasteGroups } from './utility/waste';
import { revertLocalizedValue, revertLocalizedCollectionValues } from '@/services/utils/localization';
import { WASTE_PRODUCT } from '@/constants/productTypes';
import CategoryApi from '@/services/Api/Platform/Category';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import ProductApi from '@/services/Api/Platform/Product';
import Toaster from '@/services/Toaster';

import FilterBox from '@/components/Filter/FilterBox';
import Flyout from '@/components/Layout/Flyout';
import GridRow from '@/components/Layout/GridRow';
import GridTableHead from '@/components/Table/GridTableHead';
import Hint from '@/components/Typography/Hint';
import LoadingSpinner from '@/components/LoadingSpinner';
import TextField from '@/components/Form/TextField.v2';
import WasteGroup from './components/WasteGroup';
import Words from '@/components/Typography/Words';

const MARGIN_TYPE_PERCENTAGE = 'percentage';
const MARGIN_TYPE_ABSOLUTE = 'absolute';

const routeMap = {
    [WASTE_PRODUCT]: 'waste',
};

export default {
    name: 'WasteListPage',
    components: {
        FilterBox,
        Flyout,
        GridRow,
        GridTableHead,
        Hint,
        LoadingSpinner,
        TextField,
        WasteGroup,
        Words,
    },
    mixins: [persistentFiltersMixin, statefulMixin],
    props: {
        listType: {
            type: String,
            validator: v => WASTE_PRODUCT === v,
            default: WASTE_PRODUCT,
        },
    },
    data() {
        return {
            wasteCode: null,
            wasteGroups: [],
            cancelSource: null,
            dataEndpoint: ProductApi,
            filter: this.assembleFilter('product', {
                page: 1,
                perPage: 500,
                type: this.listType,
                sortBy: 'sku',
            }),
            defaultFilter: {
                page: 1,
                perPage: 500,
                type: this.listType,
            },
            wasteItemsTotalCount: 0,
            wasteItemsActiveCount: 0,
            wasteItemsInactiveCount: 0,
        };
    },
    computed: {
        sortOptions() {
            return _intersection(ProductApi.supportedSorts, ['name', 'category', 'sku']);
        },
        productMarginTypes() {
            return {
                percentage: MARGIN_TYPE_PERCENTAGE,
                absolute: MARGIN_TYPE_ABSOLUTE,
            };
        },
        productEditRoute() {
            return this.$root.findRouteName(`${routeMap[this.listType]}-product-edit`);
        },
        isWasteProduct() {
            return this.listType === WASTE_PRODUCT;
        },
    },
    watch: {
        listType(newType, oldType) {
            if (newType !== oldType) {
                this.filter.type = newType;
                this.defaultFilter.type = newType;
                this.wasteResponse = null;
                this.refreshList();
            }
        },
    },
    created() {
        this.refreshList(true);
    },
    methods: {
        revertLocalizedValue,
        revertLocalizedCollectionValues,
        preciseFloat,

        onClickWasteGroup(productId) {
            this.$router
                .push({
                    name: this.productEditRoute,
                    params: { id: productId },
                })
                .catch(err => {
                    this.$logger().error(err);
                });
        },

        async refreshList(isInitial = false) {
            await this.stateful('refresh', async () => {
                const categories = await CategoryApi.getCategoryByType(this.listType);
                const wasteCategories = structurizeSortedWasteCategories(categories);

                // persist filter
                this.persistFilter('product', this.filter, this.defaultFilter);

                // cancel previous request
                this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
                this.cancelSource = ProductApi.createCancelTokenSource();

                try {
                    const wasteResponse = await ProductApi.filter(this.filter, null, null, this.cancelSource);
                    let displayableWasteGroups = structurizeWasteGroups(wasteCategories, wasteResponse.items);

                    if (wasteResponse?.appliedFilter?.search) {
                        displayableWasteGroups = displayableWasteGroups.filter(
                            wasteGroup => wasteGroup?.wasteItems?.length
                        );
                    }
                    this.wasteGroups = displayableWasteGroups;
                    this.wasteItemsTotalCount = wasteResponse.items.length;
                    this.wasteItemsActiveCount = wasteResponse.items.filter(item => item.isActive).length;
                    this.wasteItemsInactiveCount = this.wasteItemsTotalCount - this.wasteItemsActiveCount;

                    if (isInitial === true) {
                        this.filter = {
                            ...this.filter,
                            ...wasteResponse.appliedFilter,
                        };
                    }
                } catch (err) {
                    if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                        this.$logger().error(err);
                        Toaster.error(this.$t(err.message));
                    }
                }
            });
        },

        assembleTagLables(tags) {
            return tags.map(tag => revertLocalizedValue(tag.text)).join(', ');
        },

        edit(item) {
            this.$router
                .push({
                    name: this.$root.findRouteName('product-management_edit'),
                    params: {
                        id: item.id,
                    },
                })
                .catch(err => {
                    this.$logger().error(err);
                });
        },

        editProduct(product) {
            if (!this.$can('updateWasteProductCatalog')) return;

            this.$router
                .push({
                    name: this.productEditRoute,
                    params: { id: product.id },
                })
                .catch(err => {
                    this.$logger().error(err);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.waste-list {
    margin-top: 15px;

    &__info-display {
        padding: 15px;
        .headline {
            font-weight: 700;
        }
        .sub-headline {
            margin-left: 15px;
        }
    }

    .waste-list__filter {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr auto auto;
        align-items: center;
    }
}

.waste-group__table-head {
    padding-left: 18px;
    display: grid;
    grid-template-columns: 150px auto 150px;
    gap: 20px;
    .words {
        padding-left: 0;
    }
}
</style>

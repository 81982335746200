<template>
    <LayoutPage
        class="organization-form"
        :screen-name="isPlatformAdministrator ? 'platform-organization-detail' : 'organization-detail'"
        :is-flyout="!isPage"
        :class="{ 'organization-form--flyout': !isPage }"
        :parent-route="{ name: $root.findRouteName(parentRoute), query: $route.query }"
        @close="handleClose()"
    >
        <template #pageTitle>
            <div v-if="$route.params.pageTitle">
                {{ $route.params.pageTitle }}
            </div>
            <div v-else>
                {{ isNewOrganization ? $t('pages.organization.form.titleAdd') : organization.name }}
            </div>
        </template>

        <LoadingSpinner v-if="isPending('load')" block dark />

        <InfoMessage
            v-else-if="errorCode"
            transparent
            :title="$t(`pages.organization.error.${errorCode}.headline`)"
            :text="$t(`pages.organization.error.${errorCode}.msg`)"
            :cta="$t('pages.organization.error.cta')"
            @cta-click="$router.push({ name: 'management__organization-list' })"
        >
            <template #icon>
                <SfOrganizationsIcon />
            </template>
        </InfoMessage>

        <div
            v-else-if="step === 'types' && isNewOrganization"
            class="container-deprecated organization-form__container-types"
        >
            <div class="organization-form__alert-box">
                <Alert type="error" icon-type="info" primary colorized>
                    {{ $t('pages.organization.form.irreversibleHint') }}
                </Alert>
            </div>

            <div class="organization-form__market-headline">
                <Words block bold spaced-top spaced-bottom>
                    {{ $t('pages.organization.form.market.headline') }}
                </Words>
                <Words block spaced-top spaced-bottom>
                    {{ $t(market.name) }}
                </Words>
            </div>

            <div class="organization-form__types-head">
                <Words block bold spaced-top spaced-bottom>
                    {{ $t('pages.organization.form.activity.headline') }}
                </Words>
                <Words block spaced-bottom>
                    {{ $t('pages.organization.form.activity.description') }}
                </Words>
            </div>

            <LoadingSpinner v-if="isPending('loadDefinition')" block dark />
            <template v-else>
                <Card v-for="(typeDefinition, type) in definition.organizationTypes" :key="type" spaceless-x>
                    <div class="organization-form__type-card">
                        <CheckboxField
                            :value="newTypes"
                            :disabled="isDependentType(type, typeDefinition)"
                            :option="type"
                            :data-test="`organization-type-${type}-checkbox`"
                            @input="toggleType(type, $event)"
                        >
                            <div class="organization-form__type-card-info">
                                <Words block bold spaced-bottom :data-test="`${type}-organization-checkbox`">
                                    {{ typeDefinition.headline }}
                                </Words>
                                <Words block spaced-bottom-small>
                                    {{ typeDefinition.description }}
                                </Words>
                                <Hint v-if="type === 'merchant'" show-label transparent spaceless>
                                    {{ getRequiredTypesHint(type) }}
                                </Hint>
                            </div>
                        </CheckboxField>
                        <Tag black bold>
                            {{ typeDefinition.title }}
                        </Tag>
                    </div>
                </Card>
            </template>
        </div>
        <div v-else :class="{ 'container-deprecated': $root.isDesktop }" class="organization-form__container-forms">
            <div>
                <RoundedTabNavigation
                    v-model="activeTab"
                    :tabs="availableTabs"
                    query="org-form-tab"
                    @input="handleTabSwitch()"
                >
                    <template #right="{ tab }">
                        <span
                            v-if="(isGroupValid(tab) && isNewOrganization) || hasGroupErrors(tab) || isNewOrganization"
                            class="organization-form__group-status-icon"
                        >
                            <SuccessIcon
                                v-if="isGroupValid(tab) && isNewOrganization"
                                class="icon--inline icon--green"
                                width="14"
                            />
                            <ErrorIcon v-else-if="hasGroupErrors(tab)" class="icon--inline icon--red" width="14" />
                            <EmptyFrameIcon v-else-if="isNewOrganization" class="icon--inline icon--muted" width="14" />
                        </span>
                    </template>
                </RoundedTabNavigation>
            </div>
            <Card spaceless-x>
                <div
                    :class="{
                        'organization-form__content--centered': isPage,
                        'organization-form__content--full': !isPage,
                    }"
                    class="organization-form__content"
                >
                    <!-- MAIN -->
                    <TabMain
                        v-if="activeTab === 'main'"
                        v-model="organization"
                        :original-organization-carrier-number="originalOrganization.carrierNumber"
                        :billing-address-countries="billingAddressCountries"
                        :billing-address-countries-loading="isPending('refreshBillingAddressSettings')"
                        :errors="{
                            name: getError('organization.name'),
                            billingAddress: {
                                invalid: getError('organization.billingAddress.invalid'),
                                street: getError('organization.billingAddress.street'),
                                number: getError('organization.billingAddress.number'),
                                zip: getError('organization.billingAddress.zip'),
                                city: getError('organization.billingAddress.city'),
                                country: getError('organization.billingAddress.country'),
                                state: getError('organization.billingAddress.state'),
                            },
                            taxId: getError('organization.taxId'),
                            vatId: getError('organization.vatId'),
                        }"
                        :readonly="readonly"
                        :has-errors="{
                            billingAddress: {
                                invalid: hasErrors('organization.billingAddress.invalid'),
                            },
                        }"
                    />
                    <!-- MAIN -->

                    <!-- CONTACT -->
                    <TabContact
                        v-if="activeTab === 'contact'"
                        :organization.sync="organization"
                        :readonly="readonly"
                        :contact-form-errors="{
                            phone: getError('organization.phone.number'),
                            mobile: getError('organization.mobile.number'),
                            fax: getError('organization.fax.number'),
                            email: getError('organization.email'),
                        }"
                        :prefix-errors="{
                            faxCountryCode: getError('organization.fax.countryCode'),
                            mobileCountryCode: getError('organization.mobile.countryCode'),
                            phoneCountryCode: getError('organization.phone.countryCode'),
                        }"
                    />
                    <!-- CONTACT -->

                    <!-- PAYMENT INFO -->
                    <TabPaymentInfo
                        v-if="activeTab === 'paymentInfo'"
                        :modal-ref-to-trigger="modalRefToTrigger"
                        :original-organization="originalOrganization"
                        :organization.sync="organization"
                        :readonly="readonly"
                        :errors="{
                            iban: getError('organization.paymentInfo.iban'),
                            bic: getError('organization.paymentInfo.bic'),
                            name: getError('organization.paymentInfo.name'),
                            owner: getError('organization.paymentInfo.owner'),
                            mandateReference: getError('organization.mandateReference'),
                        }"
                        :is-new-organization="isNewOrganization"
                        @update-organization="updateOrganization"
                        @block-save-by-modal="blockSaveByModal"
                        @save-from-modal="saveFromModal"
                    />
                    <!-- PAYMENT INFO -->

                    <!-- COMPANY PROFILE -->
                    <TabCompanyProfile
                        v-if="activeTab === 'companyProfile'"
                        :organization.sync="organization"
                        :readonly="readonly"
                        :errors="{
                            description: getError('organization.description'),
                        }"
                    />
                    <!-- COMPANY PROFILE -->

                    <!-- EMAIL -->
                    <TabEmail
                        v-if="activeTab === 'email' && $can('updateOrganization', organization)"
                        :organization.sync="organization"
                        :readonly="readonly"
                        :errors="{
                            invoiceRecipientEmails: getError('organization.invoiceRecipientEmails'),
                            deliveryNoteRecipientEmails: getError('organization.deliveryNoteRecipientEmails'),
                        }"
                    />
                    <!-- EMAIL -->

                    <!-- ACCOUNTING -->
                    <template v-if="activeTab === 'accounting'">
                        <div v-if="isNewAccountingTab">
                            <TabUnifiedAccountingWrapper :organization.sync="organization" />
                            <hr class="my-12 border border-light-gray-200" />
                            <TabPaymentInfo
                                :modal-ref-to-trigger="modalRefToTrigger"
                                :original-organization="originalOrganization"
                                :organization.sync="organization"
                                :readonly="readonly"
                                :errors="{
                                    iban: getError('organization.paymentInfo.iban'),
                                    bic: getError('organization.paymentInfo.bic'),
                                    name: getError('organization.paymentInfo.name'),
                                    owner: getError('organization.paymentInfo.owner'),
                                    mandateReference: getError('organization.mandateReference'),
                                }"
                                :is-new-organization="isNewOrganization"
                                @update-organization="updateOrganization"
                                @block-save-by-modal="blockSaveByModal"
                                @save-from-modal="saveFromModal"
                            />
                        </div>
                        <TabAccounting
                            v-else
                            :organization.sync="organization"
                            :errors="{
                                carrierPaymentTermId: getError('organization.carrierPaymentTermId.id'),
                                clientPaymentTermId: getError('organization.clientPaymentTermId.id'),
                                supplierPaymentTermId: getError('organization.supplierPaymentTermId.id'),
                            }"
                        />
                    </template>

                    <!-- SETTINGS -->
                    <TabSettings
                        v-if="activeTab === 'settings' && $can('updateOrganizationPlatformSettings', organization)"
                        :organization.sync="organization"
                        :original-organization="originalOrganization"
                        :is-new-organization="isNewOrganization"
                        :definition="definition"
                        :available-employees="availableEmployees"
                        :key-account-list="keyAccountList"
                        :readonly="readonly"
                    />
                </div>
            </Card>
        </div>
        <template #sticky>
            <SlideUp :active="canProceed" class="organization-form__footer">
                <ButtonGroup>
                    <Words v-if="!isNewOrganization" block bold class="organization-form__changed-hint">
                        <ErrorIcon class="icon--inline icon--mono" />
                        &nbsp; {{ $t('pages.organization.form.unsavedChangesLabel') }}
                    </Words>
                    <BaseButton
                        v-if="step === 'types'"
                        primary
                        data-test="continue-button"
                        @click="preselectValuesAndContinue()"
                    >
                        {{ $t('pages.organization.form.actions.continue') }}
                    </BaseButton>
                    <template v-else>
                        <BaseButton :disabled="isPending()" primary light arrow-right @click="discardChanges()">
                            {{ $t('pages.organization.form.actions.cancel') }}
                        </BaseButton>

                        <BaseButton
                            :disabled="isPending()"
                            :is-loading="isPending('save')"
                            primary
                            data-test="save-button"
                            @click="save()"
                        >
                            {{ $t('pages.organization.form.actions.save') }}
                        </BaseButton>
                    </template>
                </ButtonGroup>
            </SlideUp>
        </template>
    </LayoutPage>
</template>

<script>
import { findCountryCodeByLocale } from '@/config/countryCodes';
import { CONTEXT_PLATFORM } from '@/constants/context';
import { EVENT_ORGANIZATION_DELETED } from '@/constants/events';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import pageMixin from '@/plugins/mixins/pageMixin';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import OrganizationApi from '@/services/Api/Organization';
import { billingAddressSettingsApi } from '@/services/Api/Platform/BillingAddressSettings';
import EmployeeApi from '@/services/Api/Platform/User';
import FeatureManagerService from '@/services/FeatureManager/FeatureManager';
import { getPreselectedFeatures } from '@/services/FeatureManager/PreselectedFeatures';
import { useLd } from '@/services/LaunchDarkly';
import Toaster from '@/services/Toaster';
import { ensureJSTimestamp } from '@/services/utils/date';
import { detectSignatureChanges, updateChangedSignature } from '@/services/utils/observable';
import validate from '@/services/validation/mixin';
import { greaterThan, isEmail, isPhoneNumber, isRequired, maxLength, minLength } from '@/services/validation/rules';
import LogService from '@schuettflix/util-log';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import { mapGetters } from 'vuex';

import Alert from '@/components/Alert';
import SlideUp from '@/components/Animation/SlideUp';
import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import CheckboxField from '@/components/Form/CheckboxField';
import Card from '@/components/Layout/Card';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation';
import Hint from '@/components/Typography/Hint';
import Tag from '@/components/Typography/Tag';
import Words from '@/components/Typography/Words';
import InfoMessage from '@/pages/Home/components/InfoMessage';
import TabAccounting from '@/pages/Organization/partials/TabAccounting.vue';
import TabCompanyProfile from '@/pages/Organization/partials/TabCompanyProfile.vue';
import TabContact from '@/pages/Organization/partials/TabContact';
import TabUnifiedAccountingWrapper from '@/pages/Organization/partials/TabUnifiedAccountingWrapper';
import TabEmail from '@/pages/Organization/partials/TabEmail';
import TabMain from '@/pages/Organization/partials/TabMain.vue';
import TabPaymentInfo from '@/pages/Organization/partials/TabPaymentInfo/TabPaymentInfo.vue';
import TabSettings from '@/pages/Organization/partials/TabSettings.vue';
import { useEditBlockOrdersForNewOrganizationsEnabled } from '@/services/FeatureFlags/useFeatureFlags';
import { SfOrganizationsIcon } from '@schuettflix/vue-components';

import SuccessIcon from '@/assets/icons/regular/check-mark--rounded.svg';
import EmptyFrameIcon from '@/assets/icons/regular/frame--rounded.svg';
import ErrorIcon from '@/assets/icons/regular/warning.svg';

import GlobalVariables from '@/services/GlobalVariables';
import { isDirectDebit } from '@/services/utils/organization';

import { PAYMENT_METHODS } from '@/constants/paymentMethods';
import { ref, watch } from 'vue';

const EMAIL_INTERVAL_OPTIONS = ['directly', 'daily', 'weekly', 'biweekly', 'never'];
const DEFAULT_TAB = 'main';

const Log = new LogService('pages/Organization/OrganizationFormPage');

const signatureCollection = {};
window.signatureCollection = signatureCollection;

function getEmptyPhoneNumber(marketCode) {
    return {
        countryCode: marketCode ? findCountryCodeByLocale(marketCode) : '+49',
        number: null,
    };
}

const featureManager = new FeatureManagerService();
window.featureManager = featureManager;

export default {
    name: 'OrganizationFormPage',
    components: {
        Alert,
        BaseButton,
        ButtonGroup,
        Card,
        CheckboxField,
        Hint,
        InfoMessage,
        SfOrganizationsIcon,
        LayoutPage,
        LoadingSpinner,
        RoundedTabNavigation,
        SlideUp,
        TabContact,
        TabCompanyProfile,
        TabEmail,
        TabMain,
        TabPaymentInfo,
        TabSettings,
        TabAccounting,
        TabUnifiedAccountingWrapper,
        Tag,
        Words,

        EmptyFrameIcon,
        ErrorIcon,
        SuccessIcon,
    },
    mixins: [validate, statefulMixin, eventHubMixin, pageMixin],
    props: {
        organizationId: {
            type: [Number, String],
            default: null,
        },
        parentRoute: {
            type: String,
            default: null,
        },
    },
    setup() {
        const errorCode = ref(null);
        const isNewAccountingTab = useLd('organization-accounting-tab');
        const editBlockOrdersForNewOrganizationsEnabled = useEditBlockOrdersForNewOrganizationsEnabled();

        watch(editBlockOrdersForNewOrganizationsEnabled, newValue => {
            if (!newValue) {
                this.organization.isBlocked = true;
            }
        });

        return {
            errorCode,
            editBlockOrdersForNewOrganizationsEnabled,
            isNewAccountingTab,
        };
    },
    data() {
        return {
            newTypes: [],
            step: _get(this.$router, 'currentRoute.meta.isCreateForm', false) ? 'types' : 'form',
            activeTab: DEFAULT_TAB,
            availableEmployees: [],
            definition: null,
            organization: this.getEmptyOrganization(),
            originalOrganization: this.getEmptyOrganization(),
            EMAIL_INTERVAL_OPTIONS,
            removeDocumentInterception: null,
            keyAccountList: [],
            modalRefBlockingSave: null,
            modalRefToTrigger: null,
            billingAddressCountries: [],
        };
    },

    computed: {
        ...mapGetters('user', {
            currentUserId: 'id',
            isPlatformAdministrator: 'isPlatformAdministrator',
            userOrganizationId: 'organizationId',
            userOrganizationMarket: 'organizationMarket',
            info: 'info',
            market: 'market',
        }),
        ...detectSignatureChanges(signatureCollection, {
            hasOrganizationChanged: 'organization',
        }),
        availableTabs() {
            const tabs = {
                main: this.$t('pages.organization.form.tabs.main'),
                contact: this.$t('pages.organization.form.tabs.contact'),
                companyProfile: this.$t('pages.organization.form.tabs.companyProfile'),
                accounting: this.$t('pages.organization.form.tabs.accounting'),
            };

            if (!this.isNewAccountingTab) {
                tabs.paymentInfo = this.$t('pages.organization.form.tabs.paymentInfo');
            }

            if (this.$can('updateOrganization', this.organization)) {
                tabs.email = this.$t('pages.organization.form.tabs.email');
            }

            if (this.$can('updateOrganizationPlatformSettings', this.organization)) {
                tabs.settings = this.$t('pages.organization.form.tabs.settings');
            }
            return tabs;
        },

        isPage() {
            return !this.isNewOrganization;
        },

        isNewOrganization() {
            return _get(this.$router, 'currentRoute.meta.isCreateForm', false);
        },
        canProceed() {
            if (
                this.isPending('load') ||
                (this.$can('updateOrganizationPlatformSettings', this.organization) && this.definition === null)
            ) {
                return false;
            }

            if (this.step === 'types') {
                return this.newTypes.length > 0;
            }

            if (!this.canSaveCarrierNumber(this.organization, this.originalOrganization)) {
                return false;
            }

            return this.hasOrganizationChanged;
        },
        currentOrganizationId() {
            if (this.isNewOrganization) return null;

            return this.organizationId === null ? this.userOrganizationId : this.organizationId;
        },

        readonly() {
            return (
                this.$can('viewOrganization', this.organization) && !this.$can('updateOrganization', this.organization)
            );
        },

        validationRulesForBillingAddress() {
            if (this.isDirectDebit || !this.isClient || (this.isClient && (this.isSupplier || this.isCarrier))) {
                return {
                    'organization.billingAddress.street': [isRequired()],
                    'organization.billingAddress.number': [isRequired()],
                    'organization.billingAddress.zip': [isRequired()],
                    'organization.billingAddress.city': [isRequired()],
                    'organization.billingAddress.state': [isRequired()],
                    'organization.billingAddress.country': [isRequired()],
                };
            }
            return {
                'organization.billingAddress.street': [],
                'organization.billingAddress.number': [],
                'organization.billingAddress.zip': [],
                'organization.billingAddress.city': [],
                'organization.billingAddress.state': [],
                'organization.billingAddress.country': [],
            };
        },

        validationRules() {
            let rules = {
                'organization.name': [isRequired(), maxLength(83)], // the max length requirement is due to limitations within Netsuite

                ...this.validationRulesForBillingAddress,

                'organization.phone.countryCode': [isRequired()],
                'organization.phone.number': [isRequired(), isPhoneNumber(), minLength(3), maxLength(30)],
                // TODO: handle dependend required validation for countryCode -> number
                'organization.fax.countryCode': [], //isRequired()
                'organization.fax.number': [isPhoneNumber(), minLength(3), maxLength(30)],
                'organization.mobile.countryCode': [],
                'organization.mobile.number': [isPhoneNumber(), minLength(3), maxLength(30)],
                'organization.email': [isRequired(), isEmail()],
                ...this.validationRulesForPaymentInfo(),
                'organization.taxId': [],
                'organization.vatId': [],

                'organization.invoiceRecipientEmails': [
                    minLength(1, 'components.organizationForm.emailPreferences.minRecipientEmailsValidation'),
                ],
                'organization.deliveryNoteRecipientEmails': [
                    minLength(1, 'components.organizationForm.emailPreferences.minRecipientEmailsValidation'),
                ],
                'organization.skontoInformation': [greaterThan(0)],

                'organization.carrierPaymentTerm.id': [],
                'organization.supplierPaymentTerm.id': [],
                'organization.clientPaymentTerm.id': [],
            };

            if (this.organization.types.includes('supplier') || this.organization.types.includes('carrier')) {
                rules = {
                    ...rules,
                    ...this.validationRulesForPaymentInfo(true),
                };
            }

            return rules;
        },

        validationGroups() {
            return {
                main: [
                    'organization.name',
                    'organization.billingAddress.street',
                    'organization.billingAddress.number',
                    'organization.billingAddress.zip',
                    'organization.billingAddress.city',
                    'organization.billingAddress.state',
                    'organization.billingAddress.country',
                    'organization.taxId',
                    'organization.vatId',
                ],
                contact: [
                    'organization.phone.countryCode',
                    'organization.phone.number',
                    'organization.fax.countryCode',
                    'organization.fax.number',
                    'organization.mobile.countryCode',
                    'organization.mobile.number',
                    'organization.email',
                ],
                paymentInfo: [
                    'organization.paymentInfo.name',
                    'organization.paymentInfo.owner',
                    'organization.paymentInfo.iban',
                    'organization.paymentInfo.bic',
                ],
                accounting: [
                    'organization.clientPaymentTerm.id',
                    'organization.carrierPaymentTerm.id',
                    'organization.supplierPaymentTerm.id',
                ],
                companyProfile: [],
                email: ['organization.invoiceRecipientEmails', 'organization.deliveryNoteRecipientEmails'],
                settings: [],
            };
        },

        isPlatform() {
            return this.info?.types?.includes(CONTEXT_PLATFORM) || false;
        },

        hasRequiredPaymentDetails() {
            return this.isDirectDebit || !this.isClient;
        },
        isClient() {
            return this.organization.types.includes('client');
        },
        isSupplier() {
            return this.organization.types.includes('supplier');
        },
        isCarrier() {
            return this.organization.types.includes('carrier');
        },
        isDirectDebit() {
            return isDirectDebit(this.organization);
        },
    },

    async created() {
        if (this.currentOrganizationId) {
            await this.refreshOrganization();
        }

        this.refreshDefinition();
        this.refreshAvailableEmployees();
        this.refreshConfigForKeyAccount();
        this.refreshBillingAddressSettings();

        this.subscribe(EVENT_ORGANIZATION_DELETED, organization => {
            if (organization && this.organization && organization.id === parseInt(this.organization.id)) {
                this.$root.routeBack();
            }
        });
    },

    methods: {
        ensureJSTimestamp,

        validationRulesForPaymentInfo() {
            if (this.hasRequiredPaymentDetails) {
                const validationRules = {
                    'organization.paymentInfo.name': [isRequired()],
                    'organization.paymentInfo.owner': [isRequired()],
                    'organization.paymentInfo.iban': [isRequired()],
                    'organization.paymentInfo.bic': [isRequired()],
                };
                if (this.isDirectDebit) {
                    validationRules['organization.mandateReference'] = [isRequired()];
                }
                return validationRules;
            }
            return {
                'organization.paymentInfo.name': [],
                'organization.paymentInfo.owner': [],
                'organization.paymentInfo.iban': [],
                'organization.paymentInfo.bic': [],
                'organization.mandateReference': [],
            };
        },

        blockSaveByModal($event) {
            if ($event.blocked) {
                this.modalRefBlockingSave = $event.modalRefToTrigger;
            } else {
                this.modalRefToTrigger = null;
                this.modalRefBlockingSave = null;
            }
        },

        saveFromModal() {
            this.modalRefBlockingSave = null;
            this.save();
        },
        canSaveCarrierNumber(organization, originalOrganization) {
            if (organization.carrierNumber?.length === 1) {
                organization.carrierNumber = null;

                if (originalOrganization.carrierNumber && organization.carrierNumber === null) {
                    return true;
                }
            }

            return true;
        },

        async refreshBillingAddressSettings() {
            await this.statefulRun('refreshBillingAddressSettings', async () => {
                try {
                    const settings = await billingAddressSettingsApi.getBillingAddressSettings();
                    this.billingAddressCountries = settings.countries;

                    if (this.isNewOrganization) {
                        this.organization.billingAddress.country =
                            this.billingAddressCountries.find(
                                country => country.code === this.userOrganizationMarket.code
                            )?.code ?? null;
                    }
                } catch (err) {
                    Log.error(`Failed to get billing address settings`, err);
                }
            });
        },

        getEmptyOrganization() {
            return {
                types: [],
                name: null,
                taxId: null,
                vatId: null,
                billingAddress: {
                    additionalAddress: null,
                    street: null,
                    number: null,
                    zip: null,
                    city: null,
                    state: null,
                    country: null,
                },
                phone: getEmptyPhoneNumber(this.market?.code),
                fax: getEmptyPhoneNumber(this.market?.code),
                mobile: getEmptyPhoneNumber(this.market?.code),
                email: null,
                web: null,
                market: {
                    name: null,
                },
                paymentInfo: {
                    method: PAYMENT_METHODS.INVOICE,
                    iban: null,
                    bic: null,
                    name: null,
                    owner: null,
                },
                invoiceEmailInterval: EMAIL_INTERVAL_OPTIONS[0],
                invoiceRecipientEmails: [],
                deliveryNoteEmailInterval: EMAIL_INTERVAL_OPTIONS[0],
                deliveryNoteRecipientEmails: [],
                salesStaff: {
                    id: null,
                },
                accountManager: {
                    id: null,
                },
                isActive: true,
                isBlocked: !this.editBlockOrdersForNewOrganizationsEnabled,
                features: {},
                emailReceiptAsZip: false,
                emailDeliveryNotesAttachedToReceipt: false,
                emailDeliveryNoteAsZip: false,
                clientPaymentTerm: {
                    id: null,
                },
                carrierPaymentTerm: {
                    id: null,
                },
                supplierPaymentTerm: {
                    id: null,
                },
                excludedFromDunning: false,
                keyAccount: null,
                carrierNumber: null,
            };
        },

        /**
         * @param {object} organizationData
         */
        updateOrganizationState(organizationData) {
            const organization = {
                ...this.getEmptyOrganization(),
                ...organizationData,
            };

            organization.skontoInformation = undefined; //since this is a deprecated prop, it will be set to undefined to avoid side effects
            this.organization = organization;
            this.originalOrganization = _cloneDeep(organization);

            updateChangedSignature(signatureCollection, 'organization', organization);
        },

        async refreshOrganization() {
            await this.statefulRun('load', async () => {
                try {
                    const result = await OrganizationApi.getOneById(this.currentOrganizationId);
                    this.updateOrganizationState(result);
                } catch (err) {
                    this.errorCode = err.code;
                }
            });
        },

        async refreshDefinition() {
            if (!this.$can('updateOrganizationPlatformSettings', this.organization)) return;
            await this.statefulRun('loadDefinition', async () => {
                try {
                    this.definition = await OrganizationApi.getDefinition();
                    featureManager.loadDefinition(this.definition);
                } catch (err) {
                    Toaster.error(err);
                }
            });
        },

        async refreshAvailableEmployees() {
            if (!this.$can('updateOrganizationPlatformSettings', this.organization)) return;

            try {
                this.availableEmployees = await EmployeeApi.getAll();
            } catch (err) {
                Toaster.error(err);
            }

            if (this.isNewOrganization) {
                this.organization.salesStaff.id = this.currentUserId;
            }
        },

        toggleType(type, newTypes) {
            let status = false;
            if (newTypes.includes(type)) {
                status = true;
            }

            try {
                let nextTypes;

                if (status) {
                    [nextTypes] = featureManager.addType(type, this.newTypes);
                } else {
                    [nextTypes] = featureManager.removeType(type, this.newTypes);
                }

                this.newTypes = nextTypes;
            } catch (err) {
                Toaster.error(err);
            }
        },

        getRequiredTypesHint(type) {
            let nextTypes;

            try {
                [nextTypes] = featureManager.addType(type, this.newTypes);
                // remove current type from list
                nextTypes = nextTypes.filter(t => t != type).sort();
            } catch (err) {
                this.$logger().error(err);
            }

            const typeLabels = nextTypes.map(t => this.definition.organizationTypes[t].headline);

            return this.$t('pages.organization.form.boxHint', { label: typeLabels.join('", "') });
        },

        isDependentType(checkedType) {
            const dependentByTypes = featureManager.getTypeDependencies(checkedType, this.newTypes);

            return dependentByTypes.length > 0;
        },

        handleClose() {
            if (this.step !== 'types') {
                this.step = 'types';
                return;
            }

            this.$parent.$emit('close');
        },

        activateFirstTabWithError() {
            Object.keys(this.validationGroups).forEach(validationGroupKey => {
                if (this.validationGroups[validationGroupKey].includes(Object.keys(this.getAllErrors())[0])) {
                    this.activeTab = validationGroupKey;
                    this.handleTabSwitch();
                }
            });
        },

        async save() {
            this.modalRefToTrigger = this.modalRefBlockingSave;
            if (!!this.modalRefBlockingSave || !this.$can('updateOrganization', this.organization)) return;
            await this.stateful('save', async () => {
                if (!this.isValid()) {
                    this.activateFirstTabWithError();
                    return;
                }

                try {
                    const organizationPayload = _cloneDeep(this.organization);

                    this.originalOrganization = _cloneDeep(this.organization);

                    const result = await OrganizationApi.save(organizationPayload);

                    this.updateOrganizationState(result);

                    this.$emit('updated', this.organization);

                    if (this.isNewOrganization) {
                        this.$parent.$emit('close');
                    }
                } catch (err) {
                    if (err.code !== 400) {
                        Toaster.error(err);
                    }
                    this.$logger().error(err);

                    const errorMapping = {
                        'organization.name': 'name',
                        'organization.billingAddress.street': 'billingAddress.street',
                        'organization.billingAddress.number': 'billingAddress.number',
                        'organization.billingAddress.zip': 'billingAddress.zip',
                        'organization.billingAddress.city': 'billingAddress.city',
                        'organization.billingAddress.state': 'billingAddress.state',
                        'organization.billingAddress.country': 'billingAddress.country',
                        'organization.billingAddress.invalid': '#',
                        'organization.phone.countryCode': 'phone.countryCode',
                        'organization.phone.number': 'phone.number',
                        'organization.fax.countryCode': 'fax.countryCode',
                        'organization.fax.number': 'fax.number',
                        'organization.mobile.countryCode': 'mobile.countryCode',
                        'organization.mobile.number': 'mobile.number',
                        'organization.email': 'email',
                        'organization.taxId': 'taxId',
                        'organization.vatId': 'vatId',
                        'organization.skontoInformation': 'skontoInformation',
                        'organization.paymentInfo.iban': 'paymentInfo.iban',
                        'organization.paymentInfo.bic': 'paymentInfo.bic',
                        'organization.paymentInfo.name': 'paymentInfo.name',
                        'organization.paymentInfo.owner': 'paymentInfo.owner',
                    };

                    this.parseServerValidation(_get(err, 'response.data.errors', {}), errorMapping);
                    this.activateFirstTabWithError();
                }
            });
        },

        discardChanges() {
            this.clearValidation();
            this.organization = _cloneDeep(this.originalOrganization);

            if (this.isNewOrganization) {
                this.step = 'types';
            } else {
                this.activeTab = Object.keys(this.availableTabs).includes(this.activeTab)
                    ? this.activeTab
                    : DEFAULT_TAB;
            }
        },
        handleTabSwitch() {
            this.focusTopError();
        },
        async preselectValuesAndContinue() {
            const featureManagerByDefinition = new FeatureManagerService(this.definition);

            const preselectedFeatures = await getPreselectedFeatures();

            let nextTypes = [];
            let nextFeatures = {};

            try {
                [nextTypes, nextFeatures] = featureManagerByDefinition.addTypes(this.newTypes, nextTypes, nextFeatures);

                this.newTypes.forEach(type => {
                    nextFeatures = featureManagerByDefinition.addFeatures(
                        preselectedFeatures[type],
                        nextFeatures,
                        nextTypes
                    );
                });
                this.organization.types = nextTypes;
                this.organization.features = nextFeatures;
                this.organization.market = this.market;
                this.organization.phone = getEmptyPhoneNumber(this.market?.code);
                this.organization.fax = getEmptyPhoneNumber(this.market?.code);
                this.organization.mobile = getEmptyPhoneNumber(this.market?.code);
                this.clearValidation();
            } catch (err) {
                Toaster.error(err);
            }

            this.activeTab = DEFAULT_TAB;
            this.step = 'form';
        },

        updateOrganization(newOrganization) {
            this.organization = newOrganization;
        },

        async refreshConfigForKeyAccount() {
            if (!this.$can('updateOrganizationPlatformSettings', this.organization)) return;

            const list = await GlobalVariables.get('platform_key_account_list', '');
            const options = [
                {
                    label: this.$t('components.organizationForm.notSetSelectBoxLabel'),
                    value: null,
                },
            ];

            list.split(',')
                .map(i => i.trim())
                .filter(i => i.length)
                .forEach(i => {
                    options.push({
                        label: i,
                        value: i,
                    });
                });

            this.keyAccountList = options;
        },
    },
};
</script>

<style lang="scss" scoped>
.organization-form {
    &__alert-box {
        margin-bottom: 48px;
    }

    &__market-headline {
        margin-bottom: 48px;
    }

    &__container {
        &-types {
            margin-top: 50px;
        }

        &-forms {
            margin-top: 30px;
        }
    }

    &__type-card {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 15px;
        align-items: flex-start;

        &-info {
            margin-top: 8px;
            line-height: normal;
        }
    }

    &__types-head {
        margin-bottom: 20px;
    }

    &__content {
        margin-top: 30px;
        margin-bottom: 30px;
        max-width: 730px;

        &--full {
            max-width: none;
        }

        &--centered {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.organization-form__changed-hint {
    margin-right: auto;
}

.organization-form__group-status-icon {
    margin-left: 10px;
}

.organization-form__footer {
    box-shadow: $boxShadow-card;
}
</style>

export default class ShippingPricingView {
    constructor() {
        this._shippingMethod = null;
        this._purchaseUnitPrice = null;
        this._retailUnitPrice = null;
        this._count = null;
    }

    /**
     * Create a view model for shipping pricing
     *
     * @param data
     * @return {ShippingPricingView|null}
     */
    static createFromProjectPositionPayload(data) {
        if (!data) return null;

        const view = new ShippingPricingView();

        view.purchaseUnitPrice = data.shippingPurchasePrice;
        view.retailUnitPrice = data.shippingRetailPrice;

        return view;
    }

    /**
     * Create a view model for shipping pricing
     *
     * @param data
     * @return {ShippingPricingView|null}
     */
    static create(data) {
        if (!data) return null;

        const view = new ShippingPricingView();

        view.shippingMethod = data.info;
        view.purchaseUnitPrice = data.purchasePrice;
        view.retailUnitPrice = data.retailPrice;
        view.count = data.count;

        return view;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            info: this.shippingMethod,
            purchasePrice: this.purchaseUnitPrice,
            retailPrice: this.retailUnitPrice,
            count: this.count,
        };
    }

    /**
     * Clone current instance
     * @return {ShippingPricingView|null}
     */
    clone() {
        return ShippingPricingView.create(this.unfold());
    }

    get shippingMethod() {
        return this._shippingMethod;
    }

    set shippingMethod(value) {
        this._shippingMethod = value ?? null;
    }

    get purchaseUnitPrice() {
        return this._purchaseUnitPrice;
    }

    set purchaseUnitPrice(value) {
        this._purchaseUnitPrice = value ?? null;
    }

    get retailUnitPrice() {
        return this._retailUnitPrice;
    }

    set retailUnitPrice(value) {
        this._retailUnitPrice = value ?? null;
    }

    get count() {
        return this._count;
    }

    set count(value) {
        this._count = value ?? 1;
    }

    // Getter --------------------

    get purchasePriceTotal() {
        return this.purchaseUnitPrice * this.count;
    }

    get retailPriceTotal() {
        return this.retailUnitPrice * this.count;
    }
}

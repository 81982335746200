<template>
    <div>
        <div
            v-if="mode === 'button'"
            class="grid h-18 w-full grid-cols-[auto_1fr_20px] items-center justify-between rounded border bg-surface p-4"
        >
            <VehicleClassIconSet
                :icon="(scope.selectedOption && scope.selectedOption.icon) || null"
                :class="vehicleClasses"
            />
            <div v-if="scope.selectedOption">
                <div>{{ scope.selectedOption.name }}</div>
                <div class="font-copy-xs">
                    {{ $t('components.multiselectBox.vehicleClass.payload') }} {{ scope.selectedOption.payload | tons }}
                </div>
            </div>
            <SfSysChevronDownIcon size="xxs" />
        </div>
        <div v-else class="grid h-12 w-full grid-cols-[auto_1fr_20px] items-center justify-between">
            <VehicleClassIconSet :icon="scope.option.icon" :class="vehicleClasses" />
            <div>
                <div>{{ scope.option.name }}</div>
                <div class="font-copy-xs">
                    {{ $t('components.multiselectBox.vehicleClass.payload') }} {{ scope.option.payload | tons }}
                </div>
            </div>
            <SfSysCheckIcon v-if="scope.isActive" size="xxs" />
        </div>
    </div>
</template>

<script>
import { SfSysChevronDownIcon, SfSysCheckIcon } from '@schuettflix/vue-components';
import VehicleClassIconSet from '@/components/IconSet/VehicleClassIconSet';

export default {
    name: 'VehicleClassInner',
    components: {
        VehicleClassIconSet,

        SfSysCheckIcon,
        SfSysChevronDownIcon,
    },
    props: {
        mode: {
            type: String,
            required: true,
            validator: v => ['button', 'option'].includes(v),
        },
        scope: {
            type: Object,
            required: true,
        },
        vehicleClasses: {
            type: String,
            default: 'w-16',
        },
    },
};
</script>

import { encode, decode } from 'js-base64';
import _isEqual from 'lodash/isEqual';
import _clone from 'lodash/clone';

export function assembleQueryFilter(filter) {
    // Using public base64 implementation, to hande characters outside the Latin1 range.
    // https://stackoverflow.com/a/45725439
    return encode(JSON.stringify(filter));
}

export default {
    methods: {
        assembleFilter(name, filter) {
            const loadedQuery = this.$router.currentRoute.query[name];

            if (!loadedQuery) {
                return filter;
            }

            try {
                // Using public base64 implementation, to hande characters outside the Latin1 range.
                // https://stackoverflow.com/a/45725439
                return JSON.parse(decode(loadedQuery));
            } catch (e) {
                return filter;
            }
        },

        persistFilter(name, filter, defaultFilter) {
            const query = _clone(this.$router.currentRoute.query);

            if (_isEqual(filter, defaultFilter)) {
                delete query[name];
            } else {
                query[name] = this.assembleQueryFilter(filter);
            }

            this.$router
                .replace({
                    path: this.$router.currentRoute.path,
                    query: query,
                })
                .catch(() => {});
        },

        assembleQueryFilter,

        isQueryFilterActive(name, filter) {
            return this.$router.currentRoute.query[name] === this.assembleQueryFilter(filter);
        },

        isQueryFilterSet(name) {
            return !!this.$router.currentRoute.query[name];
        },
    },
};

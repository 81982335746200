import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ConstructionProjectStatus, createConstructionProject, } from '../../api/projects';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import Toaster from '@/services/Toaster';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { constructionProjectKeys, constructionProjectOrganizationOptionsQuery } from '@/constructionProjects/queries';
import { CONSTRUCTION_PROJECT_LIST_ROUTE } from '@/constructionProjects/constants';
import { useUserId } from '@/constructionProjects/hooks/useUserId';
import { ConstructionProjectForm, useConstructionProjectForm, } from '@/constructionProjects/pages/ConstructionProjectCreate/ConstructionProjectForm';
import { addDays, format } from 'date-fns';
import { useIsApp } from '@/constructionProjects/hooks/useIsApp';
import { useBreakpoints } from '@/constructionProjects/hooks/useBreakpoints';
export const ConstructionProjectClientCreateForm = () => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const queryClient = useQueryClient();
    const userId = useUserId() ?? undefined;
    const { data: organizationOptions } = useQuery({
        ...constructionProjectOrganizationOptionsQuery,
        suspense: true,
    });
    const mutation = useMutation(createConstructionProject, {
        onSuccess: () => {
            AnalyticsService.trackEvent('construction_project', { step: 'construction_project_created' });
            queryClient.invalidateQueries(constructionProjectKeys.list());
            Toaster.success(t('pages.constructionProject.createForm.toaster.success'));
        },
        onError: (error) => {
            if (!error.response || error.response.status > 400) {
                Toaster.error(t('pages.constructionProject.createForm.toaster.unspecifiedServerError'));
            }
        },
    });
    const date = new Date();
    const isApp = useIsApp();
    const { isSmallerOrEqual } = useBreakpoints();
    const { form } = useConstructionProjectForm({
        defaultValues: {
            color: null,
            validFrom: format(date, 'YYYY-MM-DD'),
            validTo: isApp || isSmallerOrEqual('md') ? format(addDays(date, 30), 'YYYY-MM-DD') : undefined,
            teamMembers: [],
            active: true,
            responsibleUserId: userId,
            orderConfirmationMails: [],
        },
        costCenterMandatory: organizationOptions?.organizationMetadata?.costCenterMandatory ?? false,
    });
    const onSubmitConstructionProjectCreateForm = (formData) => {
        const data = {
            name: formData.name,
            description: formData.description,
            validFrom: new Date(formData.validFrom).toISOString(),
            validTo: new Date(formData.validTo).toISOString(),
            costCenter: formData.costCenter,
            color: formData.color,
            active: formData.active,
            bounds: [],
            responsibleUserId: formData.responsibleUserId,
            teamMembers: Array.from(new Set(formData.teamMembers)),
            city: formData.location.address.city,
            zip: formData.location.address.zipCode,
            street: formData.location.address.street,
            addressNumber: formData.location.address.streetNumber,
            geoCoordinate: formData.location.coordinates,
            orderConfirmationMails: formData.orderConfirmationMails,
        };
        mutation.mutate(data, {
            onSuccess: () => {
                const projectActiveStatus = data.active
                    ? ConstructionProjectStatus.Active
                    : ConstructionProjectStatus.Inactive;
                vueRouter.replace({
                    name: CONSTRUCTION_PROJECT_LIST_ROUTE,
                    query: { activeTab: projectActiveStatus },
                });
            },
            onError: (error) => {
                if (error.response?.status === 400) {
                    const fieldErrors = error.response?.data?.fieldErrors;
                    for (const [key, error] of Object.entries(fieldErrors)) {
                        form.setError(
                        // @ts-expect-error These are backend keys and are untypable
                        key, {
                            type: 'server',
                            message: error.message,
                        }, { shouldFocus: true });
                    }
                }
            },
        });
    };
    if (!organizationOptions) {
        return null;
    }
    return (<ConstructionProjectForm form={form} organizationUsers={organizationOptions.users} organizationEmail={organizationOptions.organizationMetadata.email} submissionLoading={mutation.isLoading} onCancelClick={() => vueRouter.back()} onSubmit={onSubmitConstructionProjectCreateForm} submitButtonLabel={t('pages.constructionProject.createForm.submitButton.label')} cancelButtonLabel={t('pages.constructionProject.createForm.cancelButton.label')} costCenterMandatory={organizationOptions.organizationMetadata.costCenterMandatory}/>);
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-management"},[_c('GridRow',{attrs:{"count":4,"spacing":"large"}},[_c('ButtonGroup',{directives:[{name:"can",rawName:"v-can:createCategories",arg:"createCategories"}],staticClass:"span-3",attrs:{"inline":""}},[_c('Button',{attrs:{"primary":"","data-test":"create-category-button"},on:{"click":function($event){_vm.$router.push({ name: _vm.$root.findRouteName(_vm.categoryAddRoute) })}}},[_vm._v(" "+_vm._s(_vm.$t('pages.categoryListPage.newCategory'))+" ")])],1),_c('DataTable',{staticClass:"span-3",attrs:{"clickable":"","data-test":"data-table"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('pages.categoryListPage.table.header.category')))]),_c('th',[_vm._v(_vm._s(_vm.$t('pages.categoryListPage.table.header.status')))])])]},proxy:true}])},_vm._l((_vm.categories),function(category){return _c('tr',{key:category.id,on:{"click":function($event){_vm.$router
                        .push({
                            name: _vm.$root.findRouteName(_vm.categoryEditRoute),
                            params: { id: category.id },
                        })
                        .catch(() => {
                            // Error handled somewere
                        })}}},[_c('td',[_c('Thumbnail',{staticStyle:{"vertical-align":"middle","margin-right":"10px"},attrs:{"src":category.image && category.image.url,"inline":""}}),_vm._v(" "+_vm._s(_vm.revertLocalizedValue(category.name))+" ")],1),_c('td',[_vm._v(" "+_vm._s(category.isActive ? _vm.$t('pages.categoryListPage.table.body.status.active') : _vm.$t('pages.categoryListPage.table.body.status.inactive'))+" ")])])}),0),_c('Tree',{attrs:{"tree":_vm.categoryTree,"click-handler":_vm.editCategory}})],1),_c('Flyout',{attrs:{"route":_vm.categoryAddRoute,"no-header":""},on:{"closed":function($event){return _vm.loadCategories()}}}),_c('Flyout',{attrs:{"route":_vm.categoryEditRoute,"no-header":""},on:{"closed":function($event){return _vm.loadCategories()}}})],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
    <div
        :class="{
            'avatar-box--huge': size === 'huge',
            'avatar-box--big': size === 'big',
            'avatar-box--md': size === 'md',
            'avatar-box--medium': size === 'medium',
            'avatar-box--small': size === 'small',
            'avatar-box--xsmall': size === 'xsmall',
            'avatar-box--tiny': size === 'tiny',
            'avatar-box--middle': middle,
        }"
        class="avatar-box"
    >
        <div class="avatar-box__cage">
            <img v-if="hasImage" :src="thumbnail" class="avatar-box__image" />
            <FallbackImage v-else class="avatar-box__icon" />
        </div>
    </div>
</template>

<script>
import FallbackImage from '@/assets/avatar.svg';

export default {
    name: 'AvatarComponent',
    components: {
        FallbackImage,
    },
    props: {
        src: {
            type: Object,
            default: () => {},
        },
        size: {
            type: String,
            required: true,
            validator: value => {
                return ['huge', 'big', 'medium', 'md', 'small', 'xsmall', 'tiny'].indexOf(value) !== -1;
            },
        },
        middle: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        thumbnail() {
            switch (this.size) {
                case 'huge':
                case 'big':
                    return this.src.large;
                case 'md':
                case 'medium':
                    return this.src.medium;
                case 'small':
                case 'xsmall':
                case 'tiny':
                default:
                    return this.src.thumbnail;
            }
        },

        hasImage() {
            return this.src && Object.keys(this.src).length > 0;
        },
    },
};
</script>

<style lang="scss">
.avatar-box {
    display: inline-block;
    border: 1px solid rgba($color-darkGrey, 0.1);
    border-radius: 100%;
    overflow: hidden;
    background-color: $color-white;
}

.avatar-box--middle {
    vertical-align: middle;
}

.avatar-box--huge {
    width: 150px;
    height: 150px;

    .avatar-box__cage {
        border-width: 5px;
    }
}

.avatar-box--big {
    width: 128px;
    height: 128px;

    .avatar-box__cage {
        border-width: 5px;
    }
}

.avatar-box--medium {
    width: 64px;
    height: 64px;

    .avatar-box__cage {
        border-width: 2px;
    }
}

.avatar-box--md {
    width: 48px;
    height: 48px;

    .avatar-box__cage {
        border-width: 1px;
    }
}

.avatar-box--small {
    width: 32px;
    height: 32px;

    .avatar-box__cage {
        border-width: 1px;
    }
}

.avatar-box--xsmall {
    width: 28px;
    height: 28px;

    .avatar-box__cage {
        border-width: 1px;
    }
}

.avatar-box--tiny {
    width: 21px;
    height: 21px;

    .avatar-box__cage {
        border-width: 0;
    }
}

.avatar-box__icon,
.avatar-box__image {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.avatar-box__cage {
    border: 5px solid transparent;
    border-radius: 100%;
    overflow: hidden;
    height: 100%;
}
</style>

import { ensurePHPTimestamp } from '@/services/utils/date';
import AbstractResource from './AbstractResource';

class Reporting extends AbstractResource {
    async getForecast(endTimestamp) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/forecast`, {
                endDate: ensurePHPTimestamp(endTimestamp),
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async getHistory(endTimestamp) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/history`, {
                endDate: ensurePHPTimestamp(endTimestamp),
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new Reporting('/report');

import { useAddressFormatter } from '@/utils/useAddressFormatter';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
export const SellerProfileCard = ({ className, organizationName, address, distanceInMeters, organizationLogo, }) => {
    const { format } = useAddressFormatter();
    const { t } = useTranslation();
    return (<div className={clsx('w-full rounded bg-surface p-4 shadow-low', className)} data-test="supplier-info-card">
            {organizationLogo.logo ? (<div className="mb-4">
                    <img src={organizationLogo.logo} alt={organizationName} className="max-h-12 max-w-full"/>
                </div>) : null}
            <div className="flex flex-col gap-1">
                <p className="font-copy-md-strong">{organizationName}</p>
                <p className="font-copy-md">{format(address)}</p>
                <p className="font-copy-md text-subdued">
                    {t('pages.checkout.supplierProfilePage.supplierInfo.distance', {
            distance: (distanceInMeters / 1000).toFixed(1),
        })}
                </p>
            </div>
        </div>);
};

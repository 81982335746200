<template>
    <ModalBox :active="true" :optional-dialog="false" @closed="close()">
        <Words block bold large spaced-bottom>
            {{ $t('components.modalChangedInvoiceInterval.headline') }}
        </Words>

        <Words block spaced-bottom>
            {{ $t('components.modalChangedInvoiceInterval.description') }}
        </Words>

        <div class="mb-4 flex items-center justify-end px-6 md:mb-8">
            <Words bold spaced-left-tiny>
                <span>
                    {{ oldValue }}
                </span>
            </Words>

            <div class="p-4">
                <ArrowRightIcon />
            </div>

            <Words bold spaced-left-tiny>
                <span>
                    {{ newValue }}
                </span>
            </Words>
        </div>

        <ButtonGroup inline>
            <BaseButton primary @click="close()">
                <Words bold>{{ $t('components.modalChangedInvoiceInterval.ok') }}</Words>
            </BaseButton>
        </ButtonGroup>
    </ModalBox>
</template>

<script>
import ModalBox from '@/components/Modal/ModalBox';
import ButtonGroup from '@/components/Button/ButtonGroup';
import BaseButton from '@/components/Button/Button';

import Words from '@/components/Typography/Words';

import ArrowRightIcon from '@/assets/icons/regular/arrow-right.svg';

export default {
    name: 'ModalChangedInvoiceInterval',
    components: {
        Words,
        BaseButton,
        ButtonGroup,
        ModalBox,
        ArrowRightIcon,
    },
    props: {
        oldValue: {
            type: String,
            required: true,
            default: null,
        },
        newValue: {
            type: String,
            required: true,
            default: null,
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>

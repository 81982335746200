<template>
    <component :is="iconComponent" />
</template>

<script>
import abfaelle from '@/assets/icons/navigation.v2/abfaelle.svg';
import auftraege from '@/assets/icons/navigation.v2/auftraege.svg';
import auftragsboerse from '@/assets/icons/navigation.v2/auftragsboerse.svg';
import auftragsmanagement from '@/assets/icons/navigation.v2/auftragsmanagement.svg';
import auswertungen from '@/assets/icons/navigation.v2/auswertungen.svg';
import baustoffe from '@/assets/icons/navigation.v2/baustoffe.svg';
import bauvorhaben from '@/assets/icons/navigation.v2/bauvorhaben.svg';
import bestellen from '@/assets/icons/navigation.v2/bestellen.svg';
import bestellmengen from '@/assets/icons/navigation.v2/bestellmengen.svg';
import bestellungen from '@/assets/icons/navigation.v2/bestellungen.svg';
import buchhaltung from '@/assets/icons/navigation.v2/buchhaltung.svg';
import dokumente from '@/assets/icons/navigation.v2/dokumente.svg';
import einstellungen from '@/assets/icons/navigation.v2/einstellungen.svg';
import fahrtenbuch from '@/assets/icons/navigation.v2/fahrtenbuch.svg';
import fahrzeugklassenmangement from '@/assets/icons/navigation.v2/fahrzeugklassenmangement.svg';
import gutschriften from '@/assets/icons/navigation.v2/gutschriften.svg';
import logistik from '@/assets/icons/navigation.v2/logistik.svg';
import meine_abholungen from '@/assets/icons/navigation.v2/meine_abholungen.svg';
import meine_beladungen from '@/assets/icons/navigation.v2/meine_beladungen.svg';
import meine_fahrten from '@/assets/icons/navigation.v2/meine_fahrten.svg';
import migration from '@/assets/icons/navigation.v2/migration.svg';
import neue_bestellungen from '@/assets/icons/navigation.v2/neue_bestellungen.svg';
import nutzer from '@/assets/icons/navigation.v2/nutzer.svg';
import nutzerverwaltung from '@/assets/icons/navigation.v2/nutzerverwaltung.svg';
import organisationen from '@/assets/icons/navigation.v2/organisationen.svg';
import partnerspeditionen from '@/assets/icons/navigation.v2/partnerspeditionen.svg';
import partnersuche from '@/assets/icons/navigation.v2/partnersuche.svg';
import plattform_einstellungen from '@/assets/icons/navigation.v2/plattform_einstellungen.svg';
import produkte from '@/assets/icons/navigation.v2/produkte.svg';
import produktkatalog from '@/assets/icons/navigation.v2/produktkatalog.svg';
import projekte from '@/assets/icons/navigation.v2/projekte.svg';
import rechnungen from '@/assets/icons/navigation.v2/rechnungen.svg';
import stammdaten from '@/assets/icons/navigation.v2/stammdaten.svg';
import transporte from '@/assets/icons/navigation.v2/transporte.svg';
import tag_management from '@/assets/icons/navigation.v2/tag_management.svg';
import uebersicht from '@/assets/icons/navigation.v2/uebersicht.svg';
import verwaltung from '@/assets/icons/navigation.v2/verwaltung.svg';
import werke from '@/assets/icons/navigation.v2/werke.svg';
import zertifikate from '@/assets/icons/navigation.v2/zertifikate.svg';
import zonen from '@/assets/icons/navigation.v2/zonen.svg';

const icons = {
    abfaelle,
    auftraege,
    auftragsboerse,
    auftragsmanagement,
    auswertungen,
    baustoffe,
    bauvorhaben,
    bestellen,
    bestellmengen,
    bestellungen,
    buchhaltung,
    dokumente,
    einstellungen,
    fahrtenbuch,
    fahrzeugklassenmangement,
    gutschriften,
    logistik,
    meine_abholungen,
    meine_beladungen,
    meine_fahrten,
    migration,
    neue_bestellungen,
    nutzer,
    nutzerverwaltung,
    organisationen,
    partnerspeditionen,
    partnersuche,
    plattform_einstellungen,
    produkte,
    produktkatalog,
    projekte,
    rechnungen,
    stammdaten,
    transporte,
    tag_management,
    uebersicht,
    verwaltung,
    werke,
    zertifikate,
    zonen,
};

export default {
    name: 'NavigationIcon',
    props: {
        icon: {
            type: String,
            required: true,
            validator: v => Object.keys(icons).includes(v),
        },
    },
    computed: {
        iconComponent() {
            return icons[this.icon];
        },
    },
};
</script>

<style lang="scss"></style>

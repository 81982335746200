var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GridRow',{staticClass:"user-filter-set container-deprecated",attrs:{"count":2,"spacing":"large"}},[(_vm.isPlatformAdmin)?_c('div',{staticClass:"span-2"},[_c('TextField',{attrs:{"label":_vm.$t('pages.user.userList.filter.organizationName')},model:{value:(_vm.filterScope.filter.organizationName),callback:function ($$v) {_vm.$set(_vm.filterScope.filter, "organizationName", $$v)},expression:"filterScope.filter.organizationName"}})],1):_vm._e(),_c('div',{staticClass:"span-2"},[_c('TextField',{attrs:{"label":_vm.$t('pages.user.userList.filter.mobile')},model:{value:(_vm.filterScope.filter.mobile),callback:function ($$v) {_vm.$set(_vm.filterScope.filter, "mobile", $$v)},expression:"filterScope.filter.mobile"}})],1),_c('div',{staticClass:"span-2"},[_c('TextField',{attrs:{"label":_vm.$t('pages.user.userList.filter.email')},model:{value:(_vm.filterScope.filter.email),callback:function ($$v) {_vm.$set(_vm.filterScope.filter, "email", $$v)},expression:"filterScope.filter.email"}})],1),_c('div',{staticClass:"span-2"},[_c('MultiselectBox',{attrs:{"options":[
                {
                    value: true,
                    label: _vm.$t('pages.user.userList.filter.statusActive'),
                },
                {
                    value: false,
                    label: _vm.$t('pages.user.userList.filter.statusInactive'),
                },
            ],"option-value-renderer":option => option.value,"search-label-renderer":option => option.label,"label":_vm.$t('pages.user.userList.filter.status')},scopedSlots:_vm._u([{key:"button",fn:function(buttonScope){return [_c('DefaultInner',{attrs:{"dark":""}},[(buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1)?_c('span',[_c('Words',{attrs:{"bold":"","block":"","small":""}},[_vm._v(" "+_vm._s(buttonScope.label)+" ")]),_c('Words',{attrs:{"muted":"","block":""}},[_vm._v(" "+_vm._s(buttonScope.selectedOptions[0].label)+" ")])],1):_c('span',{attrs:{"muted":"","middle":""}},[_vm._v(" "+_vm._s(_vm.$t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }))+" ")])])]}},{key:"option",fn:function(optionScope){return [(optionScope.isActive)?_c('Words',{attrs:{"red":""}},[_vm._v(" "+_vm._s(optionScope.option.label)+" "),_c('TickIcon',{staticClass:"icon--inline icon--red"})],1):_c('Words',[_vm._v(" "+_vm._s(optionScope.option.label)+" ")])]}}]),model:{value:(_vm.filterScope.filter.isActive),callback:function ($$v) {_vm.$set(_vm.filterScope.filter, "isActive", $$v)},expression:"filterScope.filter.isActive"}})],1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
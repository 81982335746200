<template>
    <Flyout
        v-if="isLoggedIn && info"
        screen-name="useraccount-changeuser"
        :active="active"
        size="small"
        show-header
        close-by-x
        :headline="$t('pages.userLink.headline')"
        @closed="close()"
    >
        <FlyoutPage background-grey>
            <div class="container-off-canvas-sm bg-white py-6">
                <span class="font-copy-xs-strong mb-2">{{ $t('pages.userLink.loggedInAs') }}</span>
                <UserLinkTile :user-link="currentUser" no-delete />
            </div>
            <div class="container-off-canvas-sm my-6">
                <span class="font-copy-sm-strong mb-4">{{ $t('pages.userLink.changeUser') }}</span>
                <template v-for="userLink in linkedUsers">
                    <Card
                        :key="userLink.targetUser.id"
                        levitate
                        spaceless
                        class="mb-4 last:mb-0"
                        @click="handleClick(userLink)"
                    >
                        <UserLinkTile
                            :user-link="userLink"
                            :no-delete="userLink.targetUser.id === originUserId"
                            @delete="deleteLink($event)"
                        />
                        <SfMessageBox v-if="userLink.needsReauthentication" type="error" size="sm" class="mt-4">
                            {{ $t('pages.userLink.needsReauthentication') }}
                        </SfMessageBox>
                        <template v-if="userLink.needsReauthentication" #action>
                            <SfButton size="sm" class="block w-full" type="secondary">
                                {{ $t('pages.user.userForm.userLinks.reauthenticate') }}
                            </SfButton>
                        </template>
                    </Card>
                </template>
            </div>

            <Flyout :active="showDeleteConfirmation" no-header size="small">
                <ConfirmationScreen v-if="userToDelete" @close="declineDelete()">
                    <template #icon><DeleteUserLinkIcon /></template>

                    <template #content>
                        <h3 class="font-headline-lg mb-4">
                            {{ $t('pages.userLink.deleteConfirmation.headline') }}
                        </h3>

                        <p class="font-copy-md">
                            {{
                                $t('pages.userLink.deleteConfirmation.description', {
                                    username: userToDelete.targetUser.username,
                                })
                            }}
                        </p>
                    </template>
                </ConfirmationScreen>

                <template #bottom>
                    <ButtonGroup mobile-breakpoint="component-library">
                        <SfButton size="sm" type="secondary" @click="declineDelete()">
                            {{ $t('pages.userLink.deleteConfirmation.cancel') }}
                        </SfButton>
                        <SfButton size="sm" @click="confirmDelete()">
                            {{ $t('pages.userLink.deleteConfirmation.submit') }}
                        </SfButton>
                    </ButtonGroup>
                </template>
            </Flyout>

            <template #footer>
                <ButtonGroup mobile-breakpoint="component-library">
                    <SfButton size="sm" @click="$eventHub.$emit('userLinkAdd')">
                        {{ $t('pages.userLink.addUserLink') }}
                        <template #leading-icon="iconProps">
                            <SfUserIcon v-bind="iconProps" />
                        </template>
                    </SfButton>
                </ButtonGroup>
            </template>
        </FlyoutPage>
    </Flyout>
</template>

<script>
import UserLinkApi from '@/services/Api/UserLink';
import Toaster from '@/services/Toaster';
import { mapGetters } from 'vuex';
import { asyncDelay } from '@/services/utils';

import ButtonGroup from '@/components/Button/ButtonGroup';
import Card from '@/components/Layout/Card';
import ConfirmationScreen from '@/components/Layout/ConfirmationScreen';
import Flyout from '@/components/Layout/Flyout';
import FlyoutPage from '@/components/Layout/FlyoutPage';
import UserLinkTile from '@/components/UserLink/UserLinkTile';

import DeleteUserLinkIcon from '@/assets/icons/confirmation/delete-userlink.svg';
import { SfButton, SfMessageBox, SfUserIcon } from '@schuettflix/vue-components';

export default {
    name: 'UserLinkList',
    components: {
        ButtonGroup,
        Card,
        ConfirmationScreen,
        DeleteUserLinkIcon,
        Flyout,
        FlyoutPage,
        UserLinkTile,
        SfButton,
        SfUserIcon,
        SfMessageBox,
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            userToDelete: null,
        };
    },
    computed: {
        ...mapGetters('user', ['info', 'isLoggedIn', 'isOrigin', 'linkedUsers', 'originUserId']),

        currentUser() {
            const organization = this.info.organization;

            return {
                targetUser: this.info.user,
                notificationAppInboxCount: 0,
                address: organization.billingAddress,
            };
        },

        showDeleteConfirmation() {
            return this.userToDelete !== null;
        },
    },
    methods: {
        close() {
            this.$eventHub.$emit('userLinkListFlyoutToggled', false);
        },

        declineDelete() {
            this.userToDelete = null;
        },

        async confirmDelete() {
            try {
                const { targetUser: user } = await UserLinkApi.deleteLink(this.userToDelete.targetUser.id);

                this.userToDelete = null;
                this.$store.commit('user/removeUserLink', user.id);
                Toaster.success(this.$t('pages.userLink.userLinkDeleted', { username: user.username }));
            } catch (err) {
                Toaster.error(err.message);
            }
        },

        deleteLink(userLink) {
            this.userToDelete = userLink;
        },

        async handleClick(userLink) {
            if (userLink.needsReauthentication) {
                this.$eventHub.$emit('userLinkUpdate', userLink.targetUser.username);
            } else {
                this.$eventHub.$emit('userLinkListFlyoutToggled', false);
                this.$eventHub.$emit('userNavigationToggled', false);

                try {
                    await this.$router.push({ name: 'home' });
                    // call change user anyway, on success or failure navigation
                    await this.changeUser(userLink.targetUser.id);
                } catch (err) {
                    await this.changeUser(userLink.targetUser.id);
                }
            }
        },

        async changeUser(targetUserID) {
            await asyncDelay(1000);
            try {
                await this.$store.dispatch('user/changeUser', targetUserID);
                this.$store.commit('platform/forceRerender');
            } catch (err) {
                Toaster.error(this.$t(err.message));
            }
        },
    },
};
</script>

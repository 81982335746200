import { forwardRef } from 'react';
import clsx from 'clsx';
// TODO extract to component library, this is just temporary until icon PR is merged
export const MapMarkerIcon = forwardRef(({ className, ...svgProps }, ref) => {
    const iconSize = '16';
    return (<svg width={iconSize} height={iconSize} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} className={clsx('stroke-icon', className)} {...svgProps}>
            <path d="M12 2C8.13333 2 5.00003 5.48451 5.00003 9.78079C5.00003 14.8417 12 21.5 12 21.5C12 21.5 19 14.8749 19 9.78079C19 5.48451 15.8667 2 12 2Z" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M12.497 9.43764C12.161 9.52361 11.8085 9.52065 11.474 9.42901C11.1396 9.33737 10.8346 9.16022 10.5889 8.9149C10.3433 8.66958 10.1654 8.36451 10.0728 8.02957C9.98012 7.69463 9.97587 7.34132 10.0604 7.00424C10.1485 6.65596 10.329 6.33807 10.5828 6.08431C10.8365 5.83055 11.1541 5.65038 11.5017 5.5629C11.838 5.47646 12.1909 5.47915 12.5259 5.57072C12.8608 5.66228 13.1662 5.83956 13.4121 6.08517C13.6581 6.33078 13.836 6.63627 13.9286 6.97166C14.0212 7.30704 14.0252 7.66079 13.9402 7.99819C13.8516 8.34634 13.6707 8.66398 13.4166 8.91741C13.1625 9.17083 12.8447 9.35059 12.497 9.43761" strokeWidth="2" strokeMiterlimit="10"/>
        </svg>);
});
MapMarkerIcon.displayName = 'MapMarkerIcon';

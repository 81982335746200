import clsx from 'clsx';
import { useLayoutEffect, useRef, useState } from 'react';
const gradientValues = 'hsl(0, 0%, 0%) 0%, hsla(0, 0%, 0%, 0.6) 19%, hsla(0, 0%, 0%, 0.4) 34%, hsla(0, 0%, 0%, 0.3) 47%, hsla(0, 0%, 0%, 0.2) 56.5%, hsla(0, 0%, 0%, 0.12) 65%, hsla(0, 0%, 0%, 0.1) 73%, hsla(0, 0%, 0%, 0.05) 80.2%, hsla(0, 0%, 0%, 0.038) 86.1%, hsla(0, 0%, 0%, 0.018) 91%, hsla(0, 0%, 0%, 0.005) 95.2%, hsla(0, 0%, 0%, 0.001) 98.2%, hsla(0, 0%, 0%, 0) 100%';
export default function ScrollContainer({ children, className }) {
    const scrollRef = useRef(null);
    const contentRef = useRef(null);
    const [canScrollTop, setCanScrollTop] = useState(false);
    const [canScrollBottom, setCanScrollBottom] = useState(false);
    useLayoutEffect(() => {
        const scrollEl = scrollRef.current;
        const contentEl = contentRef.current;
        let animationFrame;
        const updateScrollPosition = () => {
            animationFrame && cancelAnimationFrame(animationFrame);
            animationFrame = requestAnimationFrame(() => {
                const { scrollTop, scrollHeight, clientHeight } = scrollEl;
                setCanScrollTop(scrollTop > 0);
                setCanScrollBottom(scrollHeight > Math.round(scrollTop + clientHeight));
            });
        };
        scrollEl.addEventListener('scroll', updateScrollPosition);
        const scrollResizeObserver = new ResizeObserver(() => updateScrollPosition());
        scrollResizeObserver.observe(scrollEl);
        const innerResizeObserver = new ResizeObserver(() => updateScrollPosition());
        innerResizeObserver.observe(contentEl);
        return () => {
            scrollEl.removeEventListener('scroll', updateScrollPosition);
            setCanScrollTop(false);
            setCanScrollBottom(false);
            scrollResizeObserver.disconnect();
            innerResizeObserver.disconnect();
        };
    }, []);
    return (<div className={clsx('relative isolate flex flex-col overflow-hidden', className)}>
            <div className={clsx('absolute inset-x-0 top-0 z-10 h-6 transition-opacity duration-300', canScrollTop ? 'opacity-10' : 'opacity-0')} style={{ background: `linear-gradient(to bottom, ${gradientValues})` }}></div>
            <div className={clsx('absolute inset-x-0 bottom-0 z-10 h-6 transition-opacity duration-300', canScrollBottom ? 'opacity-10' : 'opacity-0')} style={{ background: `linear-gradient(to top, ${gradientValues})` }}></div>
            <div ref={scrollRef} className="h-full overflow-x-hidden overflow-y-scroll">
                <div ref={contentRef}>{children}</div>
            </div>
        </div>);
}

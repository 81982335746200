export const ACTIONS = {
    ORDER_CANCEL: 'action.order.cancel',
    ORDER_CLOSE: 'action.order.close',
    ORDER_FORCE_CANCEL: 'action.order.forceCancel',
    ORGANIZATION_DELETE: 'action.organization.delete',
    PROJECT_CHANGE: 'action.project.change',
    PROJECT_POSITION_CLOSE: 'action.projectPosition.close',
    PROJECT_POSITION_PRICES_CHANGE: 'action.projectPosition.changePrices',
    PROJECT_POSITION_CONTINGENT_CHANGE: 'action.projectPosition.changeContingent',
    PROJECT_POSITION_NOTE_CHANGE: 'action.projectPosition.changeNote',
    PROJECT_POSITION_FREIGHT_DESCRIPTION_CHANGE: 'action.projectPosition.changeFreightDescription',
    ORDER_DISPOSAL_PRICE_CHANGE: 'action.order.changeDisposalPrice',
    PROJECT_POSITION_STATUS_CHANGE: 'action.projectPosition.changeStatus',
    PROJECT_POSITION_DISPOSAL_PRICE_CHANGE: 'action.projectPosition.changeDisposalPrice',
    PROJECT_POSITION_DISPOSAL_ANALYSIS_CHANGE: 'action.projectPosition.changeDisposalAnalysis',
    VEHICLE_DELETE: 'action.vehicle.delete',
    PROJECT_POSITION_VALIDITY_AND_STATUS_CHANGE: 'action.projectPosition.changeValidityAndStatus',
    PROJECT_ARCHIVE: 'action.constructionProject.archive',
};

<template>
    <div :class="{ mt30: rowIndex > 0 }">
        <Words block bold spaced-bottom>
            {{ $t(`pages.productManagement.wasteProductVariantPage.${value.type}`) }}
        </Words>
        <div class="waste-product-variant-table">
            <GridTable columns="auto 250px 250px 150px" class="table">
                <GridTableHead>
                    <Words tiny bold block>{{
                        $t('pages.productManagement.wasteProductVariantTable.burdening')
                    }}</Words>
                    <Words small bold block>{{
                        $t('pages.productManagement.wasteProductVariantTable.minMaxQty')
                    }}</Words>
                    <Words small bold block>{{ $t('pages.productManagement.wasteProductVariantTable.margin') }}</Words>
                    <Words small bold block>
                        {{ $t('pages.productManagement.wasteProductVariantTable.availability') }}
                    </Words>
                </GridTableHead>
                <WasteProductVariantsTableRow
                    v-for="(productVariant, index) in value.productVariants"
                    :key="productVariant.id"
                    v-model="value.productVariants[index]"
                    :validation-id="`${validationId}.${productVariant.id}`"
                    @onValidation="onValidation"
                />
            </GridTable>
        </div>
    </div>
</template>

<script>
import Words from '@/components/Typography/Words';
import GridTable from '@/components/Table/GridTable';
import GridTableHead from '@/components/Table/GridTableHead';
import WasteProductVariantsTableRow from './WasteProductVariantsTableRow';

export default {
    name: 'WasteProductVariantsTable',
    components: {
        GridTable,
        GridTableHead,
        WasteProductVariantsTableRow,
        Words,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        rowIndex: {
            type: Number,
            default: 0,
        },
        validationId: {
            type: String,
            required: true,
        },
        stateKey: {
            type: String,
            default: null,
        },
    },
    methods: {
        onValidation(payload) {
            this.$emit('onValidation', payload);
        },
    },
};
</script>
<style lang="scss" scoped>
.waste-product-variant-table {
    display: block;
}
</style>

<template>
    <router-view />
</template>

<script>
import { useLd } from '../../services/LaunchDarkly';
import { useRouter } from 'vue-router/composables';

export default {
    name: 'PlaygroundPageRenderer',
    setup() {
        const getAndHandleAccessPlaygroundFlagValue = async () => {
            const router = useRouter();
            await useLd().client.waitUntilReady();
            const useLaunchDarkly = useLd();
            const hasAccessToPlaygroundPages = useLaunchDarkly.flags['access-to-playground-pages'];
            if (!hasAccessToPlaygroundPages) {
                router.push({ name: 'dashboard' });
            }
        };

        getAndHandleAccessPlaygroundFlagValue();
    },
};
</script>

<style></style>

import OrderQuantityListPage from '@/pages/OrderQuantity/OrderQuantityListPage';

export default {
    path: 'order-quantity',
    name: 'order-quantity',
    component: OrderQuantityListPage,
    meta: {
        requiresAuth: true,
        requiredAbilities: ['listSupplierProductCapacity'],
    },
};

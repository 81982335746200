import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
const types = ['transport', 'creditnote', 'manual', 'cancellation'];
export const useTypes = () => {
    const { selectCreditNoteDocKey } = useLegalTerms();
    const selectSpecificSelfBillingKeyByOrgType = ({ selfBilling, invoiceCorrection, }, organizationType) => {
        const isSelfBilling = organizationType && ['carrier', 'supplier'].includes(organizationType);
        return isSelfBilling ? selfBilling : invoiceCorrection;
    };
    const getTypeKey = (docType, organizationType) => {
        const translationKeyMappings = {
            manual: selectCreditNoteDocKey({
                creditNote: `pages.creditNoteDoc.type.manual.creditNote`,
                selfBilling: selectSpecificSelfBillingKeyByOrgType({
                    selfBilling: `pages.creditNoteDoc.type.manual.selfBilling`,
                    invoiceCorrection: `pages.creditNoteDoc.type.manual.invoiceCorrection`,
                }, organizationType),
            }),
            transport: selectCreditNoteDocKey({
                creditNote: `pages.creditNoteDoc.type.transport.creditNote`,
                selfBilling: selectSpecificSelfBillingKeyByOrgType({
                    selfBilling: `pages.creditNoteDoc.type.transport.selfBilling`,
                    invoiceCorrection: `pages.creditNoteDoc.type.transport.invoiceCorrection`,
                }, organizationType),
            }),
            creditnote: selectCreditNoteDocKey({
                creditNote: `pages.creditNoteDoc.type.creditnote.creditNote`,
                selfBilling: selectSpecificSelfBillingKeyByOrgType({
                    selfBilling: `pages.creditNoteDoc.type.creditNote.selfBilling`,
                    invoiceCorrection: `pages.creditNoteDoc.type.creditNote.invoiceCorrection`,
                }, organizationType),
            }),
            cancellation: `pages.creditNoteDoc.type.cancellation`,
        };
        return translationKeyMappings[docType] || `pages.creditNoteDoc.type.${docType}`;
    };
    return {
        selectSpecificSelfBillingKeyByOrgType,
        getTypeKey,
    };
};

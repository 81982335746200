<template>
    <Flyout
        :active="active"
        size="small"
        background-grey
        :headline="
            isUpdate
                ? $t(`pages.transport.confirmFreightShipment.${loadingType}.titleEdit`)
                : $t(`pages.transport.confirmFreightShipment.${loadingType}.title`)
        "
        @closed="resetAction()"
    >
        <Tile transparent no-border>
            <Words>
                {{ $t(`pages.transport.confirmFreightShipment.${loadingType}.description`) }}
            </Words>
        </Tile>

        <Tile transparent no-border>
            <SignatureEditor
                request-signee
                :signee="signee"
                @inputSignature="signature = $event"
                @inputSignee="signee = $event"
            >
                <TileTrigger v-if="!signature" size="big" spaced-grid data-test="button-capture-signature">
                    <SignIcon slot="left" width="70" height="70" />
                    <Words bold>
                        {{ $t(`pages.transport.confirmFreightShipment.${loadingType}.signLabel`) }}
                    </Words>
                </TileTrigger>
                <Card v-else spaceless>
                    <div class="transport-update-freight-shipment__thumbnail-grid">
                        <div @click.stop.prevent>
                            <Thumbnail :img-src="signature.url.original" :inline="false" modal />
                        </div>
                        <div>
                            <Words block tiny spaced-bottom-tiny>
                                {{ $t(`pages.transport.confirmFreightShipment.${loadingType}.signedBy`) }}
                            </Words>
                            <Words block bold elipsis>{{ signee }}</Words>
                        </div>
                        <Button @click.prevent.stop="clearSignature()">
                            <DeleteIcon />
                        </Button>
                    </div>
                </Card>

                <template #headline>
                    {{ $t(`pages.transport.confirmFreightShipment.${loadingType}.signLabel`) }}
                </template>
                <template #message>
                    {{ $t(`pages.transport.confirmFreightShipment.${loadingType}.signMessage`) }}
                </template>
            </SignatureEditor>
        </Tile>

        <Tile transparent no-border>
            <ImageDocumentUploader
                :images="images"
                :documents="documents"
                :product-use-cases="{
                    image: 'delivery_confirmation_image',
                    document: 'delivery_confirmation_document',
                }"
                :limit="5"
                class="transport-update-freight-shipment__document-uploader"
                @updateImages="images = $event"
                @updateDocuments="documents = $event"
            >
                <template #default="{ count }">
                    <Words bold block tiny spaced-bottom-small>
                        {{ $tc(`pages.transport.confirmFreightShipment.${loadingType}.documentsLabel`, count) }}
                    </Words>
                </template>
            </ImageDocumentUploader>
        </Tile>

        <SlideUp slot="bottom" :active="isDataValid">
            <Hint transparent show-label>
                {{ $t(`pages.transport.confirmFreightShipment.${loadingType}.legalNote`) }}
            </Hint>
            <ButtonGroup>
                <Button
                    primary
                    :disabled="!canSubmit || isPending()"
                    :is-loading="isPending('submit')"
                    data-test="button-confirm-shipment-loading"
                    @click="submit()"
                >
                    {{ $t(`pages.transport.confirmFreightShipment.${loadingType}.confirmLoading`) }}
                </Button>
            </ButtonGroup>
        </SlideUp>
    </Flyout>
</template>

<script>
import { LOADING_TYPE_LOADING, LOADING_TYPE_UNLOADING } from '@/constants/loadingTypes';

import { mapGetters } from 'vuex';
import { asyncDelay } from '@/services/utils';
import TransportService from '@/services/TransportService';
import statefulMixin from '@/plugins/mixins/statefulMixin';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Card from '@/components/Layout/Card';
import Flyout from '@/components/Layout/Flyout';
import Hint from '@/components/Typography/Hint';
import ImageDocumentUploader from '@/components/Form/ImageDocumentUploader';
import SignatureEditor from '@/components/Form/SignatureEditor';
import SlideUp from '@/components/Animation/SlideUp';
import Thumbnail from '@/components/Thumbnail';
import Tile from '@/components/Layout/Tile';
import TileTrigger from '@/components/Button/TileTrigger';
import Words from '@/components/Typography/Words';

import DeleteIcon from '@/assets/icons/regular/garbage.svg';
import SignIcon from '@/assets/icons/signed-document.svg';

const WAITING_DELAY = 2000;

export default {
    name: 'SignatureFlyout',
    components: {
        Button,
        ButtonGroup,
        Card,
        Flyout,
        Hint,
        ImageDocumentUploader,
        SignatureEditor,
        SlideUp,
        Thumbnail,
        Tile,
        TileTrigger,
        Words,

        DeleteIcon,
        SignIcon,
    },
    mixins: [statefulMixin],
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        isUpdate: {
            type: Boolean,
            default: false,
        },
        loadingType: {
            type: String,
            required: true,
            validator: v => [LOADING_TYPE_LOADING, LOADING_TYPE_UNLOADING].includes(v),
        },
        currentLocation: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            canSubmit: false,

            signee: null,
            signature: null,

            images: null,
            documents: null,
        };
    },
    computed: {
        ...mapGetters('transportActions', ['transport', 'action', 'context']),

        isDataValid() {
            const hasAttachments =
                (this.images && this.images.length > 0) || (this.documents && this.documents.length > 0);

            return !!(hasAttachments || this.signature);
        },
    },
    watch: {
        active: {
            immediate: true,
            handler(isActive) {
                isActive && this.isUpdate && this.prefillData();
            },
        },

        isDataValid: {
            immediate: true,
            handler(value) {
                if (!value) {
                    this.canSubmit = false;
                    return;
                }

                setTimeout(() => {
                    this.canSubmit = true;
                }, WAITING_DELAY);
            },
        },
    },
    methods: {
        prefillData() {
            switch (this.loadingType) {
                case 'loading':
                    this.images = this.transport.confirmLoadingImages;
                    this.documents = this.transport.confirmLoadingDocuments;
                    this.signature = this.transport.confirmLoadingSignature;
                    this.signee = this.transport.confirmLoadingName;
                    break;
                case 'unloading':
                    this.images = this.transport.confirmUnloadingImages;
                    this.documents = this.transport.confirmUnloadingDocuments;
                    this.signature = this.transport.confirmUnloadingSignature;
                    this.signee = this.transport.confirmUnloadingName;
                    break;
            }
        },

        async submit() {
            await this.stateful('submit', async () => {
                let payload;

                switch (this.loadingType) {
                    case 'loading':
                        payload = {
                            confirmLoadingImages: this.images,
                            confirmLoadingDocuments: this.documents,
                            confirmLoadingLocation: this.isUpdate
                                ? this.transport.confirmLoadingLocation
                                : this.currentLocation,
                            confirmLoadingSignature: this.signature,
                            confirmLoadingName: this.signee,
                        };
                        break;
                    case 'unloading':
                        payload = {
                            confirmUnloadingImages: this.images,
                            confirmUnloadingDocuments: this.documents,
                            confirmUnloadingLocation: this.isUpdate
                                ? this.transport.confirmUnloadingLocation
                                : this.currentLocation,
                            confirmUnloadingSignature: this.signature,
                            confirmUnloadingName: this.signee,
                        };
                        break;
                }

                try {
                    await TransportService.updateFreightShipment(
                        this.context,
                        this.transport.id,
                        payload,
                        this.loadingType,
                        this.isUpdate
                    );

                    await asyncDelay(500);
                    this.resetAction();
                } catch (err) {
                    this.$logger().error(err);
                }
            });
        },

        clear() {
            this.clearSignature();
            this.images = null;
            this.documents = null;
            this.qty = null;
        },

        clearSignature() {
            this.signature = null;
            this.signee = null;
        },

        resetAction() {
            this.clear();
            this.$emit('closed');
        },
    },
};
</script>

<style lang="scss" scoped>
.transport-update-freight-shipment__thumbnail-grid {
    display: grid;
    grid-template-columns: 80px 1fr 40px;
    grid-gap: 15px;
    align-items: center;
}

.transport-update-freight-shipment__document-uploader {
    margin-top: 30px;
}
</style>

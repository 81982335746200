<template>
    <Alert type="info" primary dark>
        {{ $t('pages.organization.featureSettingsFieldTypes.boolFieldType.typeNotSupported', { type: settings.type }) }}
    </Alert>
</template>

<script>
import Alert from '@/components/Alert';

export default {
    name: 'DefaultFieldType',
    components: {
        Alert,
    },
    props: {
        setting: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss"></style>

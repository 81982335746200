import { reactive, set, watch } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import { useRoute, useRouter } from 'vue-router/composables';
import ProjectPositionApi from '@/services/Api/ProjectPosition';
import FilterableListing from '@/services/FilterableListing/FilterableListing';
import ProjectPositionView from '@/models/ProjectPositionView';
import { ConstructionProjectLogger } from '@/constructionProjects/logger';
import { ProjectPositionWithGenericPositionsApi } from '@/modules/constructionProjects/services/ProjectPositionWithGenericPositionApi';
import { useLd } from '@/services/LaunchDarkly';
const logger = new ConstructionProjectLogger(__filename);
const NAMESPACE = 'projectPosition';
const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_SORT_DIRECTION = 'desc';
export function useConstructionProjectPositionListingAsAdmin(constructionProjectId) {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const isGenericPositionEnabled = useLd('show-generic-positions-to-pa');
    const projectPositionApi = isGenericPositionEnabled.value
        ? new ProjectPositionWithGenericPositionsApi()
        : ProjectPositionApi;
    const projectPositionListing = reactive(new FilterableListing({
        namespace: NAMESPACE,
        endpoint: projectPositionApi,
        defaultFilter: {
            page: 1,
            perPage: DEFAULT_PAGE_SIZE,
            sortBy: 'number',
            sortDirection: DEFAULT_SORT_DIRECTION,
        },
        defaultRestriction: {
            constructionProject: constructionProjectId,
        },
        sortOptions: ['number'],
        // @ts-expect-error TODO: transformCallback type is not correct
        transformCallback: item => {
            if (item.type) {
                return ProjectPositionView.create(item);
            }
            // GenericPositions do not need a view
            return item;
        },
        // @ts-expect-error TODO: restrictionLabelRenderer type is not correct
        restrictionLabelRenderer: (restrictionKey, count) => {
            return t(`pages.project.position.tab.label.${restrictionKey}`) + (count ? ` (${count})` : '');
        },
    }));
    watch(() => route.query[projectPositionListing.restrictionNamespace], () => projectPositionListing.onRouteChanged());
    projectPositionListing.addRestriction('open', {
        status: 'open',
    });
    projectPositionListing.addRestriction('inactive', {
        status: ['inactive', 'created'],
    });
    projectPositionListing.addRestriction('closed', {
        status: 'closed',
    });
    projectPositionListing.pairComponent({
        $router: router,
        $set: set,
        $logger: () => logger,
    });
    void projectPositionListing.refreshList(true);
    return projectPositionListing;
}

import { AbstractResourceErrorHandler } from '@/services/Api/AbstractResourceErrorHandler';
import ApiService from './ApiService';
class ProductSearch extends AbstractResourceErrorHandler {
    apiService;
    resourcePath;
    constructor(resourcePath, messages = {}) {
        super(messages);
        this.apiService = ApiService;
        this.resourcePath = resourcePath;
    }
    getProducts = async (orderType, locationId, query, isRecycled) => {
        if (orderType === 'project-delivery') {
            orderType = 'delivery';
        }
        const response = await this.apiService.get(`/v1/product-search/${orderType}/location/${locationId}`, {
            params: {
                searchTerm: query,
                isRecycled,
            },
        });
        return response.data;
    };
    getMasterProductsByCategory = async (categoryId, orderType, locationId, query = '') => {
        if (orderType === 'project-delivery') {
            orderType = 'delivery';
        }
        const response = await this.apiService.get(`/order/product-search/available-master-products/category/${categoryId}/${orderType}/location/${locationId}`, {
            params: {
                searchTerm: query,
            },
        });
        return response.data;
    };
}
export default new ProductSearch('');

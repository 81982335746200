import interval from './interval';
import gracefulInterval from './gracefulInterval';
import uuid from './uuid';
import { EventBus } from '@/services/EventBus';

export default {
    install(Vue) {
        Vue.prototype.$interval = interval;
        Vue.prototype.$gracefulInterval = gracefulInterval;
        Vue.prototype.$uuid = uuid;
        Vue.prototype.$eventHub = EventBus;
    },
};

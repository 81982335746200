<template>
    <Tile :transparent="transparent" no-padding no-border-bottom>
        <div class="no-order-process-active-information">
            <slot name="icon" />
            <div>
                <Headline bold :level="2">{{ title }}</Headline>
                <Words v-html="text" />
                <div class="mt-8">
                    <SfButton
                        v-if="cta"
                        :size="`${$root.isDesktop ? 'sm' : 'md'}`"
                        :class="{ 'border-t border-light-gray-400': !$root.isDesktop }"
                        @click="$emit('cta-click')"
                    >
                        {{ cta }}
                    </SfButton>
                </div>
            </div>
        </div>
    </Tile>
</template>

<script>
import Tile from '@/components/Layout/Tile';
import Words from '@/components/Typography/Words';
import Headline from '@/components/Typography/Headline';
import { SfButton } from '@schuettflix/vue-components';

export default {
    name: 'InfoMessage',
    components: {
        Headline,
        Words,
        Tile,
        SfButton,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        cta: {
            type: String,
            default: null,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.no-order-process-active-information {
    display: grid;
    justify-items: center;
    gap: 24px;
    text-align: center;
    padding: 48px;
    margin-bottom: 80px;
    line-height: 20px;

    .headline--h2 {
        margin: 0 0 8px 0;
    }
}
</style>

<template>
    <LayoutPage class="transport-hub" screen-name="carrier-jobexchange" data-test="transport-hub-page">
        <template #pageTitle>{{ $t(`pages.transportHub.title.carrier`) }}</template>

        <template #mobileHeader>
            <HeaderBar>
                <template #headline>{{ $t(`pages.transportHub.title.carrier`) }}</template>
                <template #right>
                    <HeaderBarItem v-if="!$root.isDesktop">
                        <FilterBox
                            v-model="filter"
                            screen-name="carrier-jobexchange-filter"
                            no-padding
                            :default-filter="defaultFilter"
                            :endpoint="transportListApi"
                            :forced-filter="forcedFilter"
                            :ignore-in-count="ignoreInFilterCount"
                            @save="refreshList(false, true, true)"
                        >
                            <template #default="{ filter: filterFromSlot, updateFilter }">
                                <TransportFilterSet
                                    class="container-deprecated"
                                    :filter-scope="{ filter: filterFromSlot }"
                                    :endpoint="transportListApi"
                                    :whitelisted-fields="whitelistedFilters"
                                    @updateFilter="updateFilter"
                                />
                            </template>
                        </FilterBox>
                    </HeaderBarItem>

                    <HeaderBarItem v-if="!$root.isDesktop">
                        <FilterSort
                            :sort-by="filter.sortBy"
                            :sort-direction="filter.sortDirection"
                            :sort-options="sortOptions"
                            :option-label-renderer="value => $t(`pages.transportHub.sortings.${value}`)"
                            has-sort-set-options
                            @sort-by-update="filter.sortBy = $event"
                            @sort-direction-update="filter.sortDirection = $event"
                            @updated-sorting="refreshList(false, true, true)"
                        />
                    </HeaderBarItem>
                </template>
            </HeaderBar>
        </template>

        <Alert v-if="isBlocked" dark>
            <i18n path="pages.transportHub.isBlocked" tag="span">
                <span place="name">{{ organizationName }}</span>
                <BaseButton place="contactLink" @click="openGlobalPage('page.platformContact')">
                    {{ $t('pages.transportHub.blockedContactLink') }}
                </BaseButton>
            </i18n>
        </Alert>

        <Alert v-else-if="Object.keys(availableTabs).length === 0" dark>
            {{ $t('pages.transportHub.tabs.nothingAvailable', { organization: organizationName }) }}
        </Alert>

        <template v-else>
            <div class="transport-hub__advanced-filter-head">
                <RoundedTabNavigation
                    :value="activeTab"
                    :tabs="availableTabs"
                    :full-width-tabs="!$root.isDesktop"
                    @input="updatePredefinedFilter"
                />
                <div class="transport-hub__advanced-filter-head-actions">
                    <BaseButton arrow-right :disabled="isSavedSearch || !canSaveSearch" @click="triggerSaveSearch()">
                        <template #left>
                            <HeartFullIcon v-if="isSavedSearch" class="icon--inline" width="24" height="24" />
                            <HeartEmptyIcon v-else class="icon--inline" width="24" height="24" />
                        </template>
                        {{
                            isSavedSearch
                                ? $t('pages.transportHub.searchFilter.actions.savedSearch')
                                : $t('pages.transportHub.searchFilter.actions.saveSearch')
                        }}
                    </BaseButton>
                    <BaseButton :arrow-right="$root.isDesktop" @click="openSavedSearches()">
                        <template #left>
                            <HeartListEmptyIcon class="icon--inline" width="24" height="24" />
                        </template>
                        <span>
                            {{ $t('pages.transportHub.searchFilter.actions.listSearches') }}
                        </span>
                    </BaseButton>
                    <BaseButton v-if="$root.isDesktop" arrow-right @click="resetFilter()">
                        {{ $t('pages.transportHub.searchFilter.actions.resetFilter') }}
                    </BaseButton>
                </div>
            </div>

            <FilterBox
                v-model="filter"
                :default-filter="filter"
                :endpoint="transportListApi"
                :forced-filter="forcedFilter"
                inline-mode
                class="transport-hub__inline-filter"
                @update="refreshList(false, true, true)"
            >
                <template #default="filterScope">
                    <div class="grid grid-cols-[1fr] gap-4 md:grid-cols-[1fr_1fr_150px] xl:grid-cols-[1fr_1fr_200px]">
                        <LocationField
                            :place="_get(filterScope.filter, 'originGeo.place', null)"
                            :radius="_get(filterScope.filter, 'originGeo.radius', null)"
                            :radius-options="[5, 25, 50, 100, 200]"
                            :radius-options-default="25"
                            :label="$t('pages.transport.filter.originGeo')"
                            data-test="transport-filter-origin"
                            street-less
                            :is-loading="isAllowedCountriesLoading"
                            strict-radius-options
                            :allowed-countries="allowedCountries"
                            @placeChange="$set(filterScope.filter, 'originGeo.place', $event)"
                            @radiusChange="$set(filterScope.filter, 'originGeo.radius', $event)"
                        />
                        <LocationField
                            :place="_get(filterScope.filter, 'destinationGeo.place', null)"
                            :radius="_get(filterScope.filter, 'destinationGeo.radius', null)"
                            :radius-options="[5, 25, 50, 100, 200]"
                            :radius-options-default="25"
                            :label="$t('pages.transport.filter.destinationGeo')"
                            street-less
                            strict-radius-options
                            :is-loading="isAllowedCountriesLoading"
                            :allowed-countries="allowedCountries"
                            @placeChange="$set(filterScope.filter, 'destinationGeo.place', $event)"
                            @radiusChange="$set(filterScope.filter, 'destinationGeo.radius', $event)"
                        />
                        <SelectBox
                            v-model="filterScope.filter.type"
                            :label="$t('pages.transportHub.filter.type')"
                            :options="typeOptions"
                            :option-value-renderer="option => option.value"
                            :option-label-renderer="option => option.label"
                        />
                    </div>
                    <FilterSortPagination
                        :result="transports"
                        :filter="filterScope.filter"
                        :sort-options="sortOptions"
                        :option-label-renderer="value => $t(`pages.transportHub.sortings.${value}`)"
                        :hide-sort="!$root.isDesktop"
                        show-refresh
                        has-sort-set-options
                        show-grouping-toggle
                        :loading="isLoading"
                        @refresh="refreshList(false, true, true)"
                        @pageNumberUpdated="pageNumberUpdated"
                    >
                        <div
                            class="transport-hub__filter-bar-left flex w-full flex-wrap gap-4 md:w-auto md:shrink-0 md:flex-nowrap"
                        >
                            <FilterBox
                                v-if="$root.isDesktop"
                                v-model="filter"
                                class="shrink-0"
                                screen-name="carrier-jobexchange-filter"
                                no-padding
                                :default-filter="defaultFilter"
                                :endpoint="transportListApi"
                                :forced-filter="forcedFilter"
                                :ignore-in-count="ignoreInFilterCount"
                                data-test="transport-hub-more-filter"
                                :button-label="$t('pages.transportHub.filter.moreFilterButtonLabel')"
                                @save="refreshList(false, true, true)"
                            >
                                <template #default="{ filter: filterFromSlot, updateFilter }">
                                    <TransportFilterSet
                                        class="container-deprecated"
                                        :filter-scope="{ filter: filterFromSlot }"
                                        :endpoint="transportListApi"
                                        :whitelisted-fields="whitelistedFilters"
                                        @updateFilter="updateFilter"
                                    />
                                </template>
                            </FilterBox>
                            <SfToggleSwitch
                                class="mr-4 shrink-0"
                                :model-value="isTransportHubQueueGrouped"
                                size="md"
                                :label="$t('pages.transportHub.filter.grouping')"
                                :disabled="isLoading"
                                @change="handleListGrouping"
                            />
                            <SfToggleSwitch
                                v-if="isPlatformActionChecksEnabled"
                                v-model="filter.checkOrganizationPlatformActions"
                                class="mr-4 shrink-0 whitespace-nowrap"
                                size="md"
                                :label="$t('pages.transportHub.filter.certificates')"
                                :disabled="isLoading"
                                @change="refreshList(false, true, true)"
                            />
                        </div>
                    </FilterSortPagination>
                </template>
            </FilterBox>

            <div class="px-4 lg:px-0">
                <transition name="fade" mode="out-in">
                    <div v-if="isLoading" key="transportLoadingIndicatorBlocks">
                        <TransportListLoadingBlock
                            v-for="(item, i) in Array(defaultFilter.perPage)"
                            :key="i"
                            :class="{
                                'mt-4': i > 0,
                            }"
                        />
                    </div>
                    <div v-else-if="transports && transports.count > 0" key="transportBlocks">
                        <div v-if="isTransportHubQueueGrouped" class="space-y-6">
                            <TransportListGroup
                                v-for="group in transportsItemGroups"
                                :key="group.orderNumber"
                                :group="group"
                                context="carrier"
                            >
                                <TransportListBlockList
                                    :transports="group.transports"
                                    :show-pick-button-handler="showPickButton"
                                    :is-pick-transport-pending-handler="isPickTransportPending"
                                    :is-picked-handler="isPicked"
                                    :is-unavailable-handler="isUnavailable"
                                    hide-project-indicator
                                    is-grouped
                                    context="carrier"
                                    @select-transport="selectTransport"
                                    @plan-transport="planTransport"
                                    @pick-transport="pickTransport"
                                />
                            </TransportListGroup>
                        </div>

                        <TransportListBlockList
                            v-else
                            :transports="transportsItemGroups[0].transports"
                            :show-pick-button-handler="showPickButton"
                            :is-pick-transport-pending-handler="isPickTransportPending"
                            :is-unavailable-handler="isUnavailable"
                            :is-picked-handler="isPicked"
                            hide-project-indicator
                            context="carrier"
                            @select-transport="selectTransport"
                            @plan-transport="planTransport"
                            @pick-transport="pickTransport"
                        />

                        <Card spaceless class="transport-hub__bottom-pagination">
                            <Pagination
                                v-if="transports"
                                align-right
                                :result="transports"
                                @pageNumberUpdated="pageNumberUpdated"
                            />
                        </Card>
                    </div>
                    <Hint v-else-if="transports && transports.count === 0" center transparent>
                        {{ $t('pages.transportHub.noResults') }}
                    </Hint>
                </transition>
            </div>
        </template>

        <template #subpages><Flyout route="transport-view" size="small" no-header /></template>

        <Flyout
            :active="savedSearchListOpen"
            screen-name="carrier-jobexchange-savedsearches"
            :headline="$t('pages.transportHub.searchFilter.actions.listSearches')"
            close-by-x
            background-grey
            @closed="savedSearchListOpen = false"
        >
            <template #subheader>
                <div v-if="defaultSavedSearch" class="transport-hub__search-filter-list-head">
                    <div class="transport-hub__search-filter-list-head-card">
                        <SearchFilterBlock
                            :search-filter="defaultSavedSearch"
                            @select="selectSavedSearch($event)"
                            @deleted="refreshSavedSearches()"
                            @updated="refreshSavedSearches()"
                        />
                    </div>
                </div>
            </template>
            <div class="transport-hub__search-filter-list-body">
                <Card v-for="savedSearch in listSavedSearches" :key="savedSearch.id" spaceless-x clickable>
                    <SearchFilterBlock
                        :search-filter="savedSearch"
                        @select="selectSavedSearch($event)"
                        @deleted="refreshSavedSearches()"
                        @updated="refreshSavedSearches()"
                    />
                </Card>
                <Card v-if="!isSavedSearch && canSaveSearch" spaceless-x>
                    <Words block bold spaced-bottom>
                        {{ $t('pages.transportHub.searchFilter.saveLastSearchHeadline') }}
                    </Words>
                    <Words block spaced-bottom>
                        {{ $t('pages.transportHub.searchFilter.saveLastSearchDescription') }}
                    </Words>
                    <BaseButton primary pull-right @click="triggerSaveSearch()">
                        {{ $t('pages.transportHub.searchFilter.saveLastSearchLabel') }}
                    </BaseButton>
                </Card>
            </div>
        </Flyout>

        <ModalBox
            :active="saveSearchOpen"
            :headline="$t('pages.transportHub.searchFilter.actions.saveSearch')"
            spaceless
            @closed="saveSearchOpen = false"
        >
            <Tile no-border>
                <Words block bold spaced-bottom-small>
                    {{ $t('pages.transportHub.searchFilter.settingsLabel') }}
                </Words>
                <Words block>
                    {{ $t('pages.transportHub.searchFilter.settingsDescription') }}
                </Words>
            </Tile>
            <Tile no-border no-padding-top>
                <TextField v-model="searchFilterData.name" :label="$t('pages.transportHub.searchFilter.nameLabel')" />
                <div class="transport-hub-queue-page__search-settings-list">
                    <div>
                        <Words block tiny spaced-bottom-tiny>
                            {{ $t('pages.transportHub.searchFilter.fromLabel') }}
                        </Words>
                        <Words>{{ searchFilterLabels.fromName }}</Words>
                    </div>
                    <div>
                        <Words block tiny spaced-bottom-tiny>
                            {{ $t('pages.transportHub.searchFilter.toLabel') }}
                        </Words>
                        <Words>{{ searchFilterLabels.toName }}</Words>
                    </div>
                    <div>
                        <Words block tiny spaced-bottom-tiny>
                            {{ $t('pages.transportHub.searchFilter.vehicleCLasses') }}
                        </Words>
                        <Words>
                            {{
                                selectedVehicleClassLabels
                                    ? selectedVehicleClassLabels
                                    : $t('pages.transportHub.searchFilter.emptySearchLocationLabel')
                            }}
                        </Words>
                    </div>
                    <div>
                        <Words block tiny spaced-bottom-tiny>
                            {{ $t('pages.transportHub.searchFilter.transportType') }}
                        </Words>
                        <Words>
                            {{
                                !searchFilterData.transportType
                                    ? $t('pages.transportHub.typeOptions.all')
                                    : $t(`pages.transportHub.typeOptions.${searchFilterData.transportType}`)
                            }}
                        </Words>
                    </div>
                </div>
            </Tile>
            <Tile no-border>
                <Words block bold spaced-bottom-small>
                    {{ $t('pages.transportHub.searchFilter.notificationsLabel') }}
                </Words>
                <Words block spaced-bottom>
                    {{ $t('pages.transportHub.searchFilter.notificationsDescription') }}
                </Words>
                <ToggleSwitchField v-model="searchFilterData.notificationEnabled">
                    {{
                        searchFilterData.notificationEnabled
                            ? $t('pages.transportHub.searchFilter.notificationsActive')
                            : $t('pages.transportHub.searchFilter.notificationsInactive')
                    }}
                </ToggleSwitchField>
            </Tile>
            <Tile no-border>
                <Words block bold spaced-bottom-small>
                    {{ $t('pages.transportHub.searchFilter.defaultSearchLabel') }}
                </Words>
                <Words block spaced-bottom>
                    {{ $t('pages.transportHub.searchFilter.defaultSearchDescription') }}
                </Words>
                <ToggleSwitchField v-model="searchFilterData.default">
                    {{
                        searchFilterData.default
                            ? $t('pages.transportHub.searchFilter.defaultSearchActive')
                            : $t('pages.transportHub.searchFilter.defaultSearchInactive')
                    }}
                </ToggleSwitchField>
            </Tile>
            <Tile>
                <Hint show-label spaceless transparent>
                    {{ $t('pages.transportHub.searchFilter.hint') }}
                </Hint>
            </Tile>
            <template #actions>
                <ButtonGroup inline>
                    <BaseButton primary light block :disabled="isPending('saveSearch')" @click="saveSearchOpen = false">
                        {{ $t('pages.transportHub.searchFilter.actions.cancel') }}
                    </BaseButton>
                    <BaseButton
                        primary
                        block
                        :disabled="isSavedSearch || !canSaveSearch"
                        :is-loading="isPending('saveSearch')"
                        @click="saveSearchFilter()"
                    >
                        {{ $t('pages.transportHub.searchFilter.actions.saveSearch') }}
                    </BaseButton>
                </ButtonGroup>
            </template>
        </ModalBox>
    </LayoutPage>
</template>

<script>
import _set from 'lodash/set';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import { useFeatureFlag } from '@/services/FeatureFlags/useFeatureFlags.ts';

import { navigationFailure } from '@/services/utils/router';
import pageMixin from '@/plugins/mixins/pageMixin';
import { ensurePHPTimestamp } from '@/services/utils/date';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { useCan, useGetCountedAbilityCheck } from '@/utils/composition-helper.ts';
import { useMarketPermissions, MarketPermissionOperand } from '@/services/MarketPermission/useMarketPermission';
import {
    MPS_CARRIER_TRANSPORT_EXCHANGE_PICKABLE_TRANSPORTS_SHIPMENT,
    MPS_CARRIER_TRANSPORT_EXCHANGE_PICKABLE_TRANSPORTS_DELIVERY,
    MPS_CARRIER_TRANSPORT_EXCHANGE_PICKABLE_TRANSPORTS_DISPOSAL,
} from '@/constants/marketPermissions';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import SearchFilterApi from '@/services/Api/SearchFilter';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import Toaster from '@/services/Toaster';
import TransportListApi from '@/services/Api/TransportList';
import TransportListGroupedApi from '@/services/Api/TransportListGrouped';
import TransportApi from '@/services/Api/Transport';
import {
    generateSearchNamesFrom,
    assembleFilterCheckIdentifier,
    generateChecksumsForSavedSearches,
    createFilterFromSearch,
    createSearchFromFilter,
} from '@/services/utils/search-filter';
import { getWhitelistedSortSetOptions, joinSortSet, splitSortSet } from '@/services/utils/filter';
import TransportListGroupView from '@/components/Transport/TransportListGroupView';

import Alert from '@/components/Alert';
import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Card from '@/components/Layout/Card';
import FilterBox from '@/components/Filter/FilterBox';
import FilterSort from '@/components/Filter/FilterSort';
import FilterSortPagination from '@/components/Filter/FilterSortPagination';
import Flyout from '@/components/Layout/Flyout';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Hint from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import LocationField from '@/components/Form/LocationField.v2';
import ModalBox from '@/components/Modal/ModalBox';
import Pagination from '@/components/Pagination';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation';
import SearchFilterBlock from './components/SearchFilterBlock';
import SelectBox from '@/components/Form/SelectBox.v3';
import TextField from '@/components/Form/TextField.v2';
import Tile from '@/components/Layout/Tile';
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import TransportFilterSet from '@/pages/Transport/components/TransportFilterSet';
import TransportListBlockList from '@/pages/Transport/components/TransportListBlockList';
import TransportListGroup from '@/components/Transport/TransportListGroup';
import TransportListLoadingBlock from '@/components/Transport/TransportListLoadingBlock.vue';
import Words from '@/components/Typography/Words';

import HeartEmptyIcon from '@/assets/icons/regular/heart--empty.svg';
import HeartFullIcon from '@/assets/icons/regular/heart--full.svg';
import HeartListEmptyIcon from '@/assets/icons/regular/heart-list--empty.svg';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';

import { TRANSPORT_TYPE } from '@/constants/transportTypes';
import { useLd } from '@/services/LaunchDarkly';

import { SfToggleSwitch } from '@schuettflix/vue-components';

const UPDATE_INTERVAL = 5000;
const DEFAULT_PAGE_COUNT = 25;

let skipReAssembleFilter = false;

const transportApi = new TransportApi('carrier');
const transportListUngroupedApi = new TransportListApi('carrier');
const transportListGroupedApi = new TransportListGroupedApi('carrier');

const SORT_GROUPS = {
    ungrouped: {
        all: ['created__desc', 'created__asc', 'urgency__asc'],
    },
    grouped: {
        all: ['transportNumber__desc', 'transportDate__desc'],
    },
};

export default defineComponent({
    name: 'TransportHubQueuePage',
    components: {
        Alert,
        BaseButton,
        ButtonGroup,
        Card,
        FilterBox,
        FilterSort,
        FilterSortPagination,
        Flyout,
        HeaderBar,
        HeaderBarItem,
        Hint,
        LayoutPage,
        LocationField,
        ModalBox,
        Pagination,
        RoundedTabNavigation,
        SearchFilterBlock,
        SelectBox,
        TextField,
        Tile,
        ToggleSwitchField,
        TransportFilterSet,
        TransportListBlockList,
        TransportListLoadingBlock,
        TransportListGroup,
        Words,

        HeartEmptyIcon,
        HeartFullIcon,
        HeartListEmptyIcon,
        SfToggleSwitch,
    },
    mixins: [pageMixin, persistentFiltersMixin, eventHubMixin, statefulMixin],
    setup() {
        const can = useCan();
        const getCountedAbilityCheck = useGetCountedAbilityCheck();
        const { isLoading: isAllowedCountriesLoading, allowedCountries } = useMarketPermissions(
            MarketPermissionOperand.UNION,
            [
                ...(can.value('listCarrierShipmentTransports')
                    ? [MPS_CARRIER_TRANSPORT_EXCHANGE_PICKABLE_TRANSPORTS_SHIPMENT]
                    : []),
                ...(can.value('listCarrierDeliveryTransports')
                    ? [MPS_CARRIER_TRANSPORT_EXCHANGE_PICKABLE_TRANSPORTS_DELIVERY]
                    : []),
                ...(can.value('listCarrierDisposalTransports')
                    ? [MPS_CARRIER_TRANSPORT_EXCHANGE_PICKABLE_TRANSPORTS_DISPOSAL]
                    : []),
            ]
        );

        const { isEnabled: isFeatureFlagDisposalEnabled } = useFeatureFlag('disposal_project_order');
        const isPlatformActionChecksEnabled = useLd('platform-action-checks');

        return {
            isAllowedCountriesLoading,
            allowedCountries,
            isFeatureFlagDisposalEnabled,
            isPlatformActionChecksEnabled,
            getCountedAbilityCheck,
        };
    },
    data() {
        return {
            transportListGroupedApi,
            transportListUngroupedApi,
            currentPageCount: 1,
            pickTransportPending: null,
            transports: null,
            isLoading: false,
            cancelSource: null,
            countCancelSource: null,
            activeTransports: [],
            pickedTransports: [],
            exclusiveTransports: [],
            preferredPartnerTransportsCount: null,
            activeFilterCount: 0,
            defaultFilter: {
                page: 1,
                perPage: DEFAULT_PAGE_COUNT,
                status: ['new'],
                sortBy: null,
                sortDirection: null,
                checkOrganizationPlatformActions: this.isPlatformActionChecksEnabled,
            },
            filter: this.assembleFilter('transport', {
                page: 1,
                perPage: DEFAULT_PAGE_COUNT,
                status: ['new'],
                sortBy: null,
                sortDirection: null,
                checkOrganizationPlatformActions: this.isPlatformActionChecksEnabled,
            }),
            forcedFilter: this.assembleFilter('transportForced', {
                perPage: DEFAULT_PAGE_COUNT,
                selfOrdered: false,
                status: ['new'],
            }),
            activePredefinedForcedFilter: 'all',
            predefinedForcedFilters: {
                all: {
                    status: ['new'],
                    selfOrdered: false,
                },
            },
            lastTimestampForNewItems: new Date(),
            saveSearchOpen: false,
            savedSearchListOpen: false,
            searchFilterData: {},
            savedSearches: null,
            currentFilterChecksum: null,
            savedSearchesChecksums: [],
            searchFilterLabels: {},
        };
    },
    computed: {
        ...mapGetters('user', ['organizationName', 'organizationId', 'isBlocked']),
        ...mapState('localPreferences', ['isTransportHubQueueGrouped']),

        transportListApi() {
            return this.isTransportHubQueueGrouped ? this.transportListGroupedApi : this.transportListUngroupedApi;
        },

        predefinedFilters() {
            return {
                all: {
                    ...splitSortSet(this.isTransportHubQueueGrouped ? 'transportNumber__desc' : 'created__desc'),
                },
            };
        },
        availableTabs() {
            const tabs = {};
            tabs.all = this.$t(`pages.transportHub.tabs.availableTransports`, {
                count: (this.transports && this.transports.count) || 0,
            });
            return tabs;
        },

        sortOptions() {
            const currentSortingConfiguration = SORT_GROUPS[this.isTransportHubQueueGrouped ? 'grouped' : 'ungrouped'];
            const currentSorting = currentSortingConfiguration[this.activePredefinedForcedFilter];

            return getWhitelistedSortSetOptions(currentSorting, this.transportListApi.supportedSorts);
        },
        whitelistedFilters() {
            return ['transportNumber', 'vehicleClass', 'startShippingWindow', 'endShippingWindow'];
        },
        ignoreInFilterCount() {
            return [
                'organizationType',
                'user',
                'organization',
                'status',
                'destinationGeo',
                'originGeo',
                'startShippingWindow',
                'endShippingWindow',
                'type',
                'selfOrdered',
            ];
        },
        activeTab() {
            return 'all';
        },

        defaultSavedSearch() {
            return this.getDefaultSavedSearch();
        },

        listSavedSearches() {
            return this.getRelevantSavedSearches().filter(item => !item.default);
        },

        canSaveSearch() {
            return !!(
                (_get(this.filter, 'originGeo.place') && _get(this.filter, 'originGeo.radius')) ||
                (_get(this.filter, 'destinationGeo.place') && _get(this.filter, 'destinationGeo.radius'))
            );
        },

        isSavedSearch() {
            // Compare filter object and the currenty applied currentSavedSearch
            return this.savedSearchesChecksums.includes(this.currentFilterChecksum);
        },

        selectedVehicleClassLabels() {
            if (!this.searchFilterData.vehicleClasses) {
                return null;
            }

            const vehicleClasses = _get(this.transportListApi, 'supportedFilters.vehicleClass.options', {});

            return this.searchFilterData.vehicleClasses
                .map(vehicleClassId => {
                    return vehicleClasses[vehicleClassId] ? vehicleClasses[vehicleClassId].name : null;
                })
                .filter(v => v)
                .join(', ');
        },

        typeOptions() {
            const options = [
                { value: null, label: this.$t('pages.transportHub.typeOptions.all') },
                { value: [TRANSPORT_TYPE.DELIVERY], label: this.$t('pages.transportHub.typeOptions.delivery') },
                { value: [TRANSPORT_TYPE.SHIPMENT], label: this.$t('pages.transportHub.typeOptions.shipment') },
            ];

            if (this.isFeatureFlagDisposalEnabled)
                options.push({
                    value: [TRANSPORT_TYPE.DISPOSAL],
                    label: this.$t('pages.transportHub.typeOptions.disposal'),
                });

            return options;
        },

        selectedSortOption() {
            return joinSortSet(this.filter.sortBy, this.filter.sortDirection);
        },
        transportsItemGroups() {
            if (this.isTransportHubQueueGrouped) {
                return this.transports.items;
            }

            return [
                {
                    orderNumber: '00000',
                    transports: this.transports.items,
                },
            ];
        },
    },
    watch: {
        filter: {
            deep: true,
            immediate: true,
            handler() {
                this.currentFilterChecksum = assembleFilterCheckIdentifier(this.filter);
            },
        },
        $route() {
            if (skipReAssembleFilter) {
                skipReAssembleFilter = false;
                return;
            }

            // Stop reloading the page on child route navigations
            if (
                this.isQueryFilterActive('transportForced', this.forcedFilter) &&
                this.isQueryFilterActive('transport', this.filter)
            ) {
                return;
            }

            this.checkActiveFilters();
            this.filter = this.assembleFilter('transport', this.defaultFilter);
            this.forcedFilter = this.assembleFilter(
                'transportForced',
                this.predefinedForcedFilters[this.activePredefinedForcedFilter]
            );
            if (this.predefinedFilters[this.activePredefinedForcedFilter]) {
                this.filter = this.assembleFilter(
                    'transport',
                    this.predefinedFilters[this.activePredefinedForcedFilter]
                );
            }

            // Load defaults only when the page was called without any filter params
            if (this.$router.currentRoute.query['transport'] === undefined) {
                this.applyDefaultSavedSearch();
            }

            this.refreshList();
        },
    },
    async created() {
        // Block everything elese, we need the users prefferences...
        await this.prepareSavedSearches();

        this.updateDefaultSort();
        this.refreshList(false);
        this.checkActiveFilters();
        this.$gracefulInterval(this.updateListData.bind(this), UPDATE_INTERVAL);

        this.subscribe('transport-returned', () => {
            this.refreshList();
        });

        this.subscribeMutation('transportActions/updateTransport', () => {
            this.refreshList();
        });
    },
    methods: {
        ensurePHPTimestamp,
        _get,
        _set,

        pageNumberUpdated(number) {
            this.$eventHub.$emit('page.actions.scroll-top');
            this.filter.page = number;
            this.refreshList();
        },

        updatePredefinedFilter(propertyValue) {
            this.transports = null;

            this.forcedFilter = _cloneDeep(this.predefinedForcedFilters[propertyValue]);
            this.persistFilter('transportForced', this.forcedFilter);

            // assmeble suggested filter
            if (this.predefinedFilters[propertyValue]) {
                this.filter = _cloneDeep(this.predefinedFilters[propertyValue]);
                this.persistFilter('transport', this.filter, this.defaultFilter);
            }

            // we triggered the filter, so do not assmemble filters back to state
            skipReAssembleFilter = true;

            this.checkActiveFilters();
            this.applyDefaultSavedSearch();
            this.refreshList();
        },

        checkActiveFilters() {
            this.activePredefinedForcedFilter = 'all';

            Object.keys(this.predefinedForcedFilters).forEach(key => {
                const predefinedFilter = this.predefinedForcedFilters[key];
                if (this.isQueryFilterActive('transportForced', predefinedFilter)) {
                    this.activePredefinedForcedFilter = key;
                }
            });

            this.updateDefaultSort();
        },

        async updateListData() {
            await this.checkActiveTransports();
            this.checkHighlightState();
        },

        checkHighlightState() {
            const now = Date.now() / 1000;

            if (!this.transports) {
                return;
            }

            this.$nextTick(() => {
                // Handle grouped transports
                this.transportsItemGroups.forEach(group => {
                    group.transports.forEach(transport => {
                        if (transport.preferredUntil) {
                            this.$set(
                                transport,
                                '__preferedRemainingSeconds',
                                parseInt(transport.preferredUntil - now)
                            );
                        }
                    });
                });
            });
        },

        selectTransport(transportId, preselectedNavigation = null) {
            const route = {
                name: `${this.$route.name}__transport-view`,
                params: { transportId, preselectedNavigation },
                query: this.$route.query,
            };

            this.$router.push(route).catch(navigationFailure);
        },
        planTransport(transport) {
            this.$router
                .push({
                    name: `${this.$route.name}__transport-plan`,
                    params: { transportId: transport.id },
                    query: this.$route.query,
                })
                .catch(navigationFailure);
        },

        isUnavailable(transportId) {
            return (
                this.activeTransports[transportId] === 0 &&
                !this.pickedTransports.includes(transportId) &&
                this.pickTransportPending !== transportId
            );
        },

        isPicked(transportId) {
            return this.pickedTransports.includes(transportId);
        },

        showPickButton(transportId) {
            return (
                this.activeTransports &&
                (this.activeTransports[transportId] !== 0 || this.pickTransportPending === transportId)
            );
        },

        async refreshList(isInitial = false, resetPagination = false, skipApplyFilter = false) {
            // organization block
            if (this.isBlocked) return;

            this.isLoading = true;

            // check if we have to reset the pagination
            if (resetPagination) {
                this.filter.page = 1;
            }

            // persist filter
            this.persistFilter('transport', this.filter, this.defaultFilter);
            this.persistFilter('transportForced', this.forcedFilter, this.defaultFilter);
            skipReAssembleFilter = skipApplyFilter;

            // cancel previous request
            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.cancelSource = this.transportListApi.createCancelTokenSource();

            try {
                const result = await this.transportListApi.filter(
                    {
                        ...this.filter,
                        checkOrganizationPlatformActions: !!this.filter.checkOrganizationPlatformActions,
                    },
                    null,
                    null,
                    this.cancelSource,
                    this.forcedFilter
                );

                // transform items into views
                if (this.isTransportHubQueueGrouped) {
                    result.transform(item => TransportListGroupView.createFromPayload(item));
                }

                this.transports = result;
                this.pickedTransports = [];
                this.exclusiveTransports = [];

                this.checkHighlightState();

                if (isInitial === true) {
                    this.filter = {
                        ...this.filter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }

            this.isLoading = false;
        },

        async pickTransport(transportId) {
            if (this.pickTransportPending != null) return;
            this.pickTransportPending = transportId;

            try {
                const transport = await transportApi.assign(transportId);
                this.pickedTransports.push(transportId);
                AnalyticsService.trackEvent('transport', {
                    step: 'picked',
                    transportId: transport.id,
                    orderNumber: transport.orderNumber,
                    organizationId: this.organizationId,
                });
            } catch (err) {
                this.$logger().error(err);
            }

            this.checkActiveTransports();
            this.pickTransportPending = null;
        },

        getActiveTransports() {
            const transportIds = this.isTransportHubQueueGrouped
                ? this.transports.items.flatMap(transportGroup =>
                      transportGroup.transports.map(transport => transport.id)
                  )
                : this.transports.items.map(transport => transport.id);

            return transportIds.reduce((o, k) => _set(o, k, true), {});
        },

        async checkActiveTransports() {
            if (this.transports && this.transports.count > 0) {
                try {
                    this.activeTransports = await this.transportListApi.checkTransportAvailability(
                        this.getActiveTransports()
                    );
                } catch (err) {
                    this.$logger().error(err);
                    Toaster.error(err);
                }
            }
        },

        // ------------------------------------------- Saved Searches ------------------------------------------

        /**
         * This will initialize saved searches
         */
        async prepareSavedSearches() {
            await this.refreshSavedSearches();

            const searchFilterId = this.$router.currentRoute.query['searchFilterId'];
            if (searchFilterId) {
                const loadedSavedSearch = this.getSavedSearchById(searchFilterId);

                if (loadedSavedSearch) {
                    this.applySavedSearch(loadedSavedSearch);
                }
            }

            // Load defaults only when the page was called without any filter params
            else if (
                this.$router.currentRoute.query['transport'] === undefined &&
                this.$router.currentRoute.query['transportForced'] === undefined
            ) {
                this.applyDefaultSavedSearch();
            }
        },

        /**
         * Apply default saved search if provided
         */
        applyDefaultSavedSearch() {
            const defaultSavedSearch = this.getDefaultSavedSearch();

            // No saved default search available for that type
            if (!defaultSavedSearch) return;

            this.applySavedSearch(defaultSavedSearch);
        },

        /**
         * Refresh the list of available searches
         */
        async refreshSavedSearches() {
            this.savedSearches = await SearchFilterApi.getAll();
            this.savedSearchesChecksums = generateChecksumsForSavedSearches(this.savedSearches);
        },

        /**
         * Filter searches for a specific transport type
         */
        getRelevantSavedSearches() {
            return this.savedSearches || [];
        },

        /**
         * Get default search
         */
        getDefaultSavedSearch() {
            const list = this.getRelevantSavedSearches();
            return list.find(searchItem => searchItem.default);
        },

        /**
         * Get a specific search
         */
        getSavedSearchById(savedSearchId) {
            const list = this.getRelevantSavedSearches();
            return list.find(searchItem => Number(searchItem.id) === Number(savedSearchId));
        },

        /**
         * Apply saved search to the current filter
         */
        applySavedSearch(savedSearch) {
            this.filter = createFilterFromSearch(savedSearch, this.filter);
        },

        openSavedSearches() {
            this.savedSearchListOpen = true;
        },

        async triggerSaveSearch() {
            const search = createSearchFromFilter({
                ...this.filter,
                ...this.forcedFilter,
            });

            this.searchFilterLabels = await generateSearchNamesFrom(search);
            this.searchFilterData = {
                ...search,
                name: this.getDecoratedNameWithDuplicationSuffix(
                    this.generateSearchNameFromLabels(this.searchFilterLabels)
                ),
                notificationEnabled: true,
                default: false,
            };
            this.saveSearchOpen = true;
        },

        generateSearchNameFromLabels(searchFilterLabels) {
            const name = this.$t('pages.transportHub.searchFilter.searchNameTemplate', {
                from: searchFilterLabels.fromName,
                to: searchFilterLabels.toName,
            });
            return name;
        },

        getDecoratedNameWithDuplicationSuffix(name) {
            // list with all names
            const list = this.savedSearches.map(search => search.name);

            // list without duplication indicator
            const rawNames = list.map(n => n.replace(/(\s+\(\d+\))/, '').trim());

            // list with duplications
            const duplications = [];

            rawNames.map((n, i) => {
                if (n !== name.trim()) return;
                duplications.push(list[i]);
            });

            // No duplications found
            if (duplications.length === 0) {
                return name.trim();
            }

            let lastNumber = 1;

            duplications.forEach(n => {
                const result = n.match(/\((\d+)\)/);
                if (result) {
                    const number = parseInt(result[1]);
                    lastNumber = lastNumber < number ? number : lastNumber;
                }
            });

            return `${name.trim()} (${lastNumber + 1})`;
        },

        async saveSearchFilter() {
            await this.stateful('saveSearch', async () => {
                try {
                    await SearchFilterApi.save({
                        ...this.searchFilterData,
                        name: this.getDecoratedNameWithDuplicationSuffix(this.searchFilterData.name.trim()),
                    });
                    await this.refreshSavedSearches();
                    this.saveSearchOpen = false;
                } catch (err) {
                    this.$logger().error(err);
                }
            });
        },

        selectSavedSearch(savedSearch) {
            this.savedSearchListOpen = false;
            this.applySavedSearch(savedSearch);
            this.refreshList();
        },

        resetFilter() {
            this.filter = _cloneDeep(this.defaultFilter);
            this.updateDefaultSort();
            this.refreshList(false, true, true);
        },

        handleListGrouping(value) {
            if (this.transports?.items) {
                this.transports.items = [];
            }

            this.$store.commit('localPreferences/setTransportHubQueueGrouped', value);

            this.updateDefaultSort();
            this.refreshList(false, true, true);
        },
        updateDefaultSort() {
            const isCurrentSortAvailable = this.sortOptions.includes(this.selectedSortOption);

            if (!this.filter.sortBy || !isCurrentSortAvailable) {
                const sortFilter = this.predefinedFilters[this.activePredefinedForcedFilter];
                this.filter.sortBy = sortFilter.sortBy;
                this.filter.sortDirection = sortFilter.sortDirection;
            }
        },
        isPickTransportPending(transportId) {
            return this.pickTransportPending === transportId;
        },
    },
});
</script>

<!-- @NOTE Cannot be scoped because it's also used in `TransportListClientPage.vue` -->
<style lang="scss">
.transport-hub {
    background-color: $color-lightMediumGrey;
}

.transport-hub__inline-filter {
    background-color: $color-white;
    box-shadow: $boxShadow-bottomShort;
    margin-bottom: 30px;
}

.transport-hub__advanced-filters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;

    @media screen and (max-width: $layout-desktop-min) {
        grid-template-columns: 1fr;
        grid-row-gap: 15px;
    }
}

.transport-hub__bottom-pagination {
    margin-top: 20px;

    .card__content {
        padding-left: 20px;
        padding-right: 20px;

        @media screen and (max-width: $layout-desktop-min) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.transport-hub__advanced-filter-head {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;

    > *:last-child {
        justify-content: flex-end;
        align-self: flex-end;
    }

    @media screen and (max-width: $layout-desktop-min) {
        grid-template-columns: 1fr;

        > *:last-child {
            justify-content: space-between;
        }
    }
}

.transport-hub__advanced-filter-head-actions {
    margin-bottom: 15px;

    > * + * {
        margin-left: 30px;
    }

    @media screen and (max-width: $layout-desktop-min) {
        grid-row: 1;
        margin-bottom: 0;
        margin-top: 15px;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
    }
}

.transport-hub-queue-page__search-settings-list {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.transport-hub__search-filter-list-head {
    background-color: #fff;
    padding: 20px 45px;
}

.transport-hub__search-filter-list-body {
    margin: 0 30px 20px;
}

.transport-hub__search-filter-list-head-card {
    cursor: pointer;
}

// .transport-hub__filter-bar-left {
//     display: grid;
//     grid-template-columns: auto auto 1fr;
//     gap: 25px;

//     @media screen and (max-width: $layout-desktop-min) {
//         grid-row: 1;
//         grid-column: 1 / -1;
//         margin-top: 15px;
//         margin-bottom: 25px;
//     }
// }
</style>

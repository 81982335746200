var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",class:[
        'formatted-address',
        {
            'font-copy-md-strong': _vm.bold && !_vm.small,
            'font-copy-md': !_vm.bold && !_vm.small,
            'font-copy-sm-strong': _vm.bold && _vm.small,
            'font-copy-sm': !_vm.bold && _vm.small,
            'text-subdued': _vm.subdued,
            'pt-1': !_vm.twoLineAddress,
            'text-brand-primary': _vm.isHighlighted,
        },
    ]},[(!_vm.twoLineAddress)?[_vm._v(_vm._s(_vm.formattedAddress))]:_c('span',{domProps:{"innerHTML":_vm._s(_vm.twoLineFormattedAddress)}}),(_vm.hasLocationCode && !_vm.hideLocationCode)?_c('span',{staticClass:"font-copy-sm",class:{ 'text-subdued': _vm.decorateLocationCode }},[_vm._v(" "+_vm._s(_vm.$t('components.openLocationCodeField.label'))+": "),_c('span',{staticClass:"font-numbers-sm inline pl-1 text-subdued"},[_vm._v(_vm._s(_vm.location.openLocationCode))])]):_vm._e()],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
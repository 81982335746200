<template>
    <div
        v-if="item.type === 'group' && $root.isDesktop"
        class="nav-group"
        :class="{ 'nav-group--collapsed': navigationCollapsed }"
        :data-test="dataTest"
    >
        <div class="nav-group__spacer" :class="{ 'nav-group__spacer--collapsed': navigationCollapsed }" />
        <div
            class="nav-group__title"
            :class="{ 'nav-group__title--collapsed': navigationCollapsed }"
            v-html="$t(`components.navigation.labels.${item.title}`)"
        />
        <NavigationRenderer v-for="(child, i) in item.items" :key="i" :item="child" :level="level + 1" />
    </div>
    <div
        v-else-if="item.type === 'group'"
        class="nav-item"
        :class="{ 'router-link-active': isActiveGroup && !preventIsActiveState }"
        :data-test="dataTest"
        @click="$emit('subnavigation', item)"
    >
        <NavigationIcon :icon="item.icon" class="nav-item__icon" width="24" />
        <div class="nav-item__text" v-html="$t(`components.navigation.labels.${item.title}`)" />
    </div>
    <router-link
        v-else
        :to="{ name: item.route }"
        :exact="item.exact"
        class="nav-item"
        :active-class="preventIsActiveState ? 'router-link--prevent-active' : null"
        :exact-active-class="preventIsActiveState ? 'router-link--prevent-active' : null"
        :class="{
            'router-link-active': isActiveItem,
            'nav-item--collapsed': navigationCollapsed,
        }"
        :title="linkTitle"
        :data-test="dataTest"
        @click.native="$emit('subnavigation', null)"
    >
        <NavigationIcon v-if="showIcon" :icon="item.icon" class="nav-item__icon" width="24" />
        <PlusIcon v-if="showIcon && item.plusIcon" class="nav-item__plus-icon" />
        <div v-if="showText" class="nav-item__text" :class="{ 'nav-item__text--no-icon': !showIcon }">
            {{ $t(`components.navigation.labels.${item.title}`) }}
            <span v-if="!showIcon">&nbsp;›</span>
        </div>
        <div v-if="item.count" class="nav-item__count" :class="{ 'nav-item__count--overlay': navigationCollapsed }">
            {{ item.count }}
        </div>
    </router-link>
</template>

<script>
import { mapState } from 'vuex';

import NavigationIcon from './NavigationIcon';

import PlusIcon from '@/assets/icons/navigation.v2/plus.svg';

export default {
    name: 'NavigationRenderer',
    components: {
        NavigationIcon,

        PlusIcon,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        overlayItem: {
            type: Object,
            default: null,
        },
        level: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapState('session', ['navigationCollapsed']),

        showIcon() {
            return this.navigationCollapsed || !this.item.hideIconExpanded;
        },

        showText() {
            return !this.$root.isDesktop || !this.navigationCollapsed;
        },

        linkTitle() {
            const key = this.navigationCollapsed ? 'labels' : 'descriptions';
            return this.$t(`components.navigation.${key}.${this.item.title}`);
        },

        dataTest() {
            return `navigation-${this.item.title}-link`;
        },

        preventIsActiveState() {
            if (this.overlayItem !== null) {
                return this.overlayItem !== this.item;
            }

            return false;
        },

        isActiveGroup() {
            if (!this.item.route) return false;

            if (this.overlayItem !== null) {
                return this.overlayItem === this.item;
            }

            return (
                this.$route.matched.some(
                    r => r.name === this.item.route || r.path.startsWith(`/${this.item.route}/`)
                ) ||
                (this.item.alias && this.item.alias.includes(this.$router.currentRoute.name))
            );
        },

        isActiveItem() {
            return this.item.alias && this.item.alias.includes(this.$router.currentRoute.name);
        },
    },
    methods: {
        toggleSubNavigation() {
            this.$refs.subnavigation.$emit('toggle');
        },
    },
};
</script>

<style lang="scss">
.nav-group {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 0;
    flex-shrink: 0;
}

.nav-group__spacer {
    border-top: 1px solid $color-littleDarkerThanMediumGrey;
    padding: 0 0 20px 0;
    margin: 20px 20px 0 40px;
}

.nav-group__spacer--collapsed {
    margin-left: 7px;
    margin-right: 7px;
}

.nav-group__title {
    font-weight: $font-weight-bold;
    font-size: 14px;
    height: 40px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px 0 40px;
}

.nav-group__title--collapsed {
    font-size: 10px;
    padding-left: 7px;
    padding-right: 7px;
    justify-content: center;
    text-align: center;
}

.nav-item {
    --nav-height: 40px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    font-weight: $font-weight-bold;
    font-size: 10px;
    color: inherit;
    height: var(--nav-height);
    position: relative;
    padding: 0 20px 0 40px;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    text-decoration: none;

    &.router-link-active {
        background: linear-gradient(to bottom, rgba(234, 234, 234, 1) 0%, rgba(234, 234, 234, 0) 100%);
    }

    @media screen and (min-width: $layout-desktop-min) {
        &:hover,
        &.router-link-active {
            background: $color-lightMediumGrey;
        }
    }

    @media screen and (max-width: $layout-desktop-max) {
        --nav-height: 73px;

        flex-flow: column nowrap;
        flex-grow: 1;
        border-top: 1px solid $color-white;
        justify-content: center;
        padding: 0 2px;

        &.router-link-active {
            border-top-color: $color-red;
        }
    }
}

.nav-item {
    // iOS 11.0
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
        height: calc(var(--nav-height) + constant(safe-area-inset-bottom));
        padding-bottom: constant(safe-area-inset-bottom);
    }

    // iOS 11.2
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
        height: calc(var(--nav-height) + env(safe-area-inset-bottom));
        padding-bottom: env(safe-area-inset-bottom);
    }
}

.nav-item--collapsed {
    justify-content: center;
    padding: 0 7px;
}

.nav-item__icon {
    margin-right: 10px;

    .nav-item--collapsed & {
        margin-right: 0;
    }

    @media screen and (max-width: $layout-desktop-max) {
        margin-right: 0;
        margin-bottom: 8px;
    }
}

.nav-item__plus-icon {
    position: absolute;
    top: 6px;
    right: 27%;
    width: 14px;
}

.nav-item__text {
    @media screen and (max-width: $layout-desktop-max) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        text-align: center;
    }
}

.nav-item__text--no-icon {
    color: $color-red;
}

.nav-item__count {
    background: $color-red;
    min-width: 19px;
    border-radius: 4px;
    text-align: center;
    color: $color-white;
    margin-left: auto;
    padding: 0 2px;
    font-size: 10px;
}

.nav-item__count--overlay {
    position: absolute;
    top: 4px;
    right: 17%;
}
</style>

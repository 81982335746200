<template>
    <TabNavigation class="span-4" dark-line spaced>
        <TabNavigationItem
            :active="isActiveRoute('accounting__credit-note')"
            stage="credit-note"
            @click="$router.push({ name: 'accounting__credit-note' }).catch(() => {})"
        >
            {{
                $t(
                    selectCreditNoteDocKey({
                        creditNote: `pages.creditNoteDoc.tabs.overview.creditNote`,
                        selfBilling: `pages.creditNoteDoc.tabs.overview.selfBilling`,
                    })
                )
            }}
        </TabNavigationItem>
        <TabNavigationItem
            v-if="!hideOldUI"
            :active="isActiveRoute('accounting__credit-note-carrier')"
            stage="credit-note-carrier"
            @click="$router.push({ name: 'accounting__credit-note-carrier' }).catch(() => {})"
        >
            {{
                $t(
                    selectCreditNoteDocKey({
                        creditNote: `pages.creditNoteDoc.tabs.carrier.creditNote`,
                        selfBilling: `pages.creditNoteDoc.tabs.carrier.selfBilling`,
                    })
                )
            }}
        </TabNavigationItem>
        <TabNavigationItem
            v-if="!hideOldUI"
            :active="isActiveRoute('accounting__credit-note-supplier')"
            stage="credit-note-supplier"
            @click="$router.push({ name: 'accounting__credit-note-supplier' }).catch(() => {})"
        >
            {{
                $t(
                    selectCreditNoteDocKey({
                        creditNote: `pages.creditNoteDoc.tabs.supplier.creditNote`,
                        selfBilling: `pages.creditNoteDoc.tabs.supplier.selfBilling`,
                    })
                )
            }}
        </TabNavigationItem>
    </TabNavigation>
</template>

<script>
import TabNavigation from '@/components/Tab/TabNavigation';
import TabNavigationItem from '@/components/Tab/TabNavigationItem';
import { useLd } from '@/services/LaunchDarkly';
import _findIndex from 'lodash/findIndex';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';

export default {
    name: 'CreditNoteNavigation',
    components: {
        TabNavigation,
        TabNavigationItem,
    },
    setup() {
        const { selectCreditNoteDocKey } = useLegalTerms();
        const hideOldUI = useLd('hide-old-manual-credit-note-ui');
        return {
            hideOldUI,
            selectCreditNoteDocKey,
        };
    },
    methods: {
        isActiveRoute(routeName) {
            return _findIndex(this.$route.matched, { name: routeName }) > -1;
        },
    },
};
</script>

<style lang="scss"></style>

<template>
    <div class="shipment-contact">
        <Words v-if="headline" block bold spaced-bottom>{{ headline }}</Words>
        <Words v-if="description" block muted spaced-bottom>{{ description }}</Words>
        <Card spaceless @click="openContact()">
            <div class="shipment-contact__contact-card">
                <ContactIcon />
                <div v-if="value && (value.name || value.phone)">
                    <Words block bold>{{ value.name }}</Words>
                    <Words v-if="value.phone" block>{{ assemblePhoneNumber(value.phone, true) }}</Words>
                </div>
                <div v-else>
                    <Button arrow-right place-left @click="openContact()">
                        {{ $t('pages.checkout.shipmentLoading.contact.button') }}
                    </Button>
                </div>
                <div v-if="value && (value.name || value.phone)">
                    <Button @click.stop="deleteContact()">
                        <DeleteIcon />
                    </Button>
                </div>
            </div>
        </Card>

        <ModalBox ref="contactModal" :headline="modalHeadline" @closed="closedModal()">
            <div>
                <Words v-if="modalDescription" block>{{ modalDescription }}</Words>
                <Tile transparent no-padding-left-right no-padding-bottom>
                    <TextField
                        v-model="contactName"
                        :label="$t('pages.checkout.shipmentLoading.contact.nameLabel')"
                        @blur="partialValidation('contactName')"
                    />
                </Tile>
                <Tile transparent no-padding-left-right no-padding-bottom>
                    <PhoneFieldset
                        v-model="contactPhone"
                        :label="$t('pages.checkout.shipmentLoading.contact.phoneLabel')"
                        :prefix-error="getError('contactPhone.countryCode')"
                        @blur="partialValidation('contactPhone.countryCode')"
                        @keydown="debounceValidation('contactPhone.number')"
                    />
                </Tile>
            </div>
            <ButtonGroup slot="actions" inline>
                <Button primary light block @click="closeContact()">
                    {{ $t('components.avatarUpload.cancelButton') }}
                </Button>
                <Button primary block :disabled="isInvalid" @click="saveContact()">
                    {{ $t('components.avatarUpload.saveButton') }}
                </Button>
            </ButtonGroup>
        </ModalBox>
    </div>
</template>

<script>
import { isRequired, isPhoneNumber } from '@/services/validation/rules';
import validation from '@/services/validation/mixin';
import _debounce from 'lodash/debounce';
import { assemblePhoneNumber } from '@/services/utils';

import Words from '@/components/Typography/Words';
import Card from '@/components/Layout/Card';
import Button from '@/components/Button/Button';
import ModalBox from '@/components/Modal/ModalBox';
import Tile from '@/components/Layout/Tile';
import TextField from '@/components/Form/TextField.v2';
import ButtonGroup from '@/components/Button/ButtonGroup';
import PhoneFieldset from '@/components/Form/PhoneFieldset';

import ContactIcon from '@/assets/icons/micro/avatar.svg';
import DeleteIcon from '@/assets/icons/regular/garbage.svg';

export default {
    name: 'ShipmentContact',

    components: {
        Words,
        Card,
        Button,
        ModalBox,
        Tile,
        TextField,
        ButtonGroup,
        PhoneFieldset,

        ContactIcon,
        DeleteIcon,
    },

    mixins: [validation],

    props: {
        value: {
            type: Object,
            default: null,
        },
        headline: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        modalHeadline: {
            type: String,
            default: null,
        },
        modalDescription: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            contactName: null,
            contactPhone: {
                countryCode: '+49',
            },
            validationRules: {
                contactName: [isRequired()],
                'contactPhone.countryCode': [isRequired()],
                'contactPhone.number': [isRequired(), isPhoneNumber()],
            },
        };
    },

    computed: {
        isInvalid() {
            return !(this.contactName && this.contactPhone && this.isValid());
        },
    },

    methods: {
        assemblePhoneNumber,

        closeContact() {
            this.contactName = null;
            this.resetPhone();
            this.clearValidation();
            this.$refs.contactModal.$emit('close');
        },

        closedModal() {
            this.contactName = null;
            this.resetPhone();
            this.clearValidation();
        },

        openContact() {
            this.contactName = this.value ? this.value.name : null;
            if (this.value && this.value.phone) {
                this.contactPhone = this.value.phone;
            }
            this.$refs.contactModal.$emit('open');
        },

        saveContact() {
            this.$emit('input', {
                name: this.contactName,
                phone: {
                    countryCode: this.contactPhone.countryCode,
                    number: this.contactPhone.number.replace(/\D/g, ''),
                },
            });
            this.closeContact();
        },

        resetPhone() {
            this.contactPhone = {
                countryCode: '+49',
            };
        },

        deleteContact() {
            this.resetPhone();
            this.$emit('input', null);
        },

        debounceValidation: _debounce(function (key) {
            this.partialValidation(key);
        }, 1000),
    },
};
</script>

<style lang="scss">
.shipment-contact__contact-card {
    display: grid;
    grid-template-columns: auto 1fr 30px;
    grid-column-gap: 15px;
    align-items: center;
    padding: 12px 0;
    cursor: pointer;
}
</style>

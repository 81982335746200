import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { FlyoutPage } from '@/constructionProjects/components/FlyoutPage';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { ConstructionProjectClientCreateForm } from '@/constructionProjects/pages/ConstructionProjectCreate/ConstructionProjectClientCreateForm';
const _ClientCreateConstructionProjectFlyout = () => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    return (<FlyoutPage screenName="client-constructionproject-create" onCloseAnimationEnd={() => vueRouter.back()} headerTitle={t('pages.constructionProject.createForm.header.title')} body={<div className="flex h-full flex-col bg">
                    <Suspense>
                        <ConstructionProjectClientCreateForm />
                    </Suspense>
                </div>}/>);
};
export const ConstructionProjectClientFlyout = asVueComponent(_ClientCreateConstructionProjectFlyout);

import ApiService from '@/services/Api/ApiService';
import { AbstractResourceErrorHandler } from './AbstractResourceErrorHandler';
class ExternalCarrier extends AbstractResourceErrorHandler {
    constructor() {
        super({});
    }
    async getExternalCarrierOrganizationNames(carrierId) {
        try {
            const response = await ApiService.get(`/carrier/${carrierId}/external-carrier-organization-names`);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
}
export default new ExternalCarrier();

import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import clsx from 'clsx';
export default function DisposalCertificateValidityInfo({ certificates, disabled, }) {
    const { t } = useTranslation();
    const certificateStatusTextColorClass = {
        valid: 'text-success',
        invalid: 'text-critical',
        expires_soon: 'text-warning',
    };
    const certificateStatusTranslations = {
        valid: t('pages.checkout.projectPositionDisposal.pages.chooseDisposer.certificate.valid'),
        invalid: t('pages.checkout.projectPositionDisposal.pages.chooseDisposer.certificate.expired'),
        expires_soon: t('pages.checkout.projectPositionDisposal.pages.chooseDisposer.certificate.aboutToExpire'),
    };
    const formattedValidityDate = (certificateDate) => {
        return format(certificateDate, 'DD.MM.YYYY');
    };
    const certificateElements = certificates.map((certificate, i) => {
        return (<div key={i} className="mt-2 flex flex-col">
                <div className="mb-1 flex justify-between">
                    <span className={clsx('font-copy', { 'text-disabled': disabled })}>{certificate.name}</span>
                    {certificate.expiresAt && (<span className={`${certificateStatusTextColorClass[certificate.status]} font-copy-md-strong`}>
                            {certificateStatusTranslations[certificate.status]}
                        </span>)}
                </div>
                {certificate.expiresAt && (<span className="font-copy text-subdued">{formattedValidityDate(certificate.expiresAt)}</span>)}
            </div>);
    });
    return <>{certificateElements}</>;
}

<template>
    <ContextMenu v-if="menuItems.length" @click="contextAction($event)">
        <template #default="{ selectAction }">
            <ContextMenuEntry
                v-for="(menuItem, i) in menuItems"
                :key="i"
                :action="menuItem.action"
                @click="selectAction($event)"
            >
                <component :is="menuItem.icon" slot="left" class="icon--inline" />
                {{ $t(menuItem.title) }}
            </ContextMenuEntry>
        </template>
    </ContextMenu>
</template>

<script>
import ProjectPositionView from '@/models/ProjectPositionView';
import routeContext from '@/plugins/mixins/routeContext';
import { ACTIONS } from '@/constants/actions';

import ContextMenu from '@/components/Button/ContextMenu';
import ContextMenuEntry from '@/components/Button/ContextMenuEntry';

import PenIcon from '@/assets/icons/micro/pen.svg';
import StopIcon from '@/assets/icons/micro/stop.svg';
import CalendarIcon from '@/assets/icons/micro/calendar.svg';
import SettingsIcon from '@/assets/icons/micro/settings.svg';
import {
    PROJECT_POSITION_TYPE_DANGEROUS_WASTE,
    PROJECT_POSITION_TYPE_SHIPMENT,
    PROJECT_POSITION_TYPE_WASTE,
} from '@/constants/projectPositionTypes';

export default {
    name: 'ProjectPositionContextMenu',
    components: {
        ContextMenu,
        ContextMenuEntry,

        PenIcon,
        StopIcon,
        CalendarIcon,
        SettingsIcon,
    },
    mixins: [routeContext],
    props: {
        projectPosition: {
            type: ProjectPositionView,
            required: true,
        },
    },
    computed: {
        menuItems() {
            const menuItems = [];

            if (this.$can('updateProjectPosition', this.projectPosition)) {
                menuItems.push(
                    {
                        action: ACTIONS.PROJECT_POSITION_PRICES_CHANGE,
                        title: 'contextMenu.projectPosition.changePrices',
                        icon: 'PenIcon',
                    },
                    {
                        action: ACTIONS.PROJECT_POSITION_CONTINGENT_CHANGE,
                        title: 'contextMenu.projectPosition.changeContingent',
                        icon: 'PenIcon',
                    },
                    {
                        action: ACTIONS.PROJECT_POSITION_VALIDITY_AND_STATUS_CHANGE,
                        title: 'contextMenu.projectPosition.changeValidity',
                        icon: 'CalendarIcon',
                    }
                );
            }

            if (this.$can('updateDisposalPriceOnShipmentPosition', this.projectPosition)) {
                menuItems.push({
                    action: ACTIONS.PROJECT_POSITION_DISPOSAL_PRICE_CHANGE,
                    title: 'contextMenu.projectPosition.changeDisposalPrice',
                    icon: 'PenIcon',
                });
            }

            if (
                this.$can('updateProjectPosition', this.projectPosition) &&
                [PROJECT_POSITION_TYPE_DANGEROUS_WASTE, PROJECT_POSITION_TYPE_WASTE].includes(this.projectPosition.type)
            ) {
                menuItems.push({
                    action: ACTIONS.PROJECT_POSITION_DISPOSAL_ANALYSIS_CHANGE,
                    title: 'contextMenu.projectPosition.changeAnalysis',
                    icon: 'PenIcon',
                });
            }

            if (this.$can('updateProjectPositionNote', this.projectPosition)) {
                menuItems.push({
                    action: ACTIONS.PROJECT_POSITION_NOTE_CHANGE,
                    title: this.projectPosition.note
                        ? 'contextMenu.projectPosition.updateNote'
                        : 'contextMenu.projectPosition.addNote',
                    icon: 'PenIcon',
                });
            }

            if (
                this.$can('toggleProjectPositionStatus', this.projectPosition) &&
                this.projectPosition.shouldBeAbleToToggleProjectPositionState
            ) {
                menuItems.push({
                    action: ACTIONS.PROJECT_POSITION_STATUS_CHANGE,
                    title:
                        this.projectPosition.status === 'open'
                            ? 'contextMenu.projectPosition.deactivateLabel'
                            : 'contextMenu.projectPosition.activateLabel',
                    icon: 'SettingsIcon',
                });
            }

            if (this.$can('closeProjectPosition', this.projectPosition)) {
                menuItems.push({
                    action: ACTIONS.PROJECT_POSITION_CLOSE,
                    title: 'contextMenu.projectPosition.close',
                    icon: 'StopIcon',
                });
            }

            if (
                this.$can('updateProjectPosition', this.projectPosition) &&
                this.projectPosition.type === PROJECT_POSITION_TYPE_SHIPMENT
            ) {
                menuItems.push({
                    action: ACTIONS.PROJECT_POSITION_FREIGHT_DESCRIPTION_CHANGE,
                    title: 'contextMenu.projectPosition.changeFreightDescription',
                    icon: 'PenIcon',
                });
            }

            return menuItems;
        },
    },
    methods: {
        contextAction(key) {
            this.$store.dispatch('globalActions/set', {
                action: key,
                subject: this.projectPosition,
                context: this.routeContext,
            });
        },
    },
};
</script>

<template>
    <ContextMenu v-if="hasMenuItems" @click="contextAction($event)">
        <template #default="{ selectAction }">
            <ContextMenuEntry
                v-for="(menuItem, i) in menuItems"
                :key="i"
                :action="menuItem.action"
                @click="selectAction($event)"
            >
                <component :is="menuItem.icon" slot="left" class="icon--inline" />
                {{ $t(menuItem.title) }}
            </ContextMenuEntry>
        </template>
    </ContextMenu>
</template>

<script>
import VehicleView from '@/models/Vehicle/VehicleView';
import routeContext from '@/plugins/mixins/routeContext';
import { ACTIONS } from '@/constants/actions';

import ContextMenu from '@/components/Button/ContextMenu';
import ContextMenuEntry from '@/components/Button/ContextMenuEntry';

import DeleteSmallIcon from '@/assets/icons/micro/error.svg';

export default {
    name: 'VehicleContextMenu',
    components: {
        ContextMenu,
        ContextMenuEntry,

        DeleteSmallIcon,
    },
    mixins: [routeContext],
    props: {
        vehicle: {
            type: VehicleView,
            required: true,
        },
    },
    computed: {
        hasMenuItems() {
            return this.menuItems.length > 0;
        },
        menuItems() {
            const menuItems = [];

            if (this.$can('deleteVehicle', this.vehicle)) {
                menuItems.push({
                    action: ACTIONS.VEHICLE_DELETE,
                    title: 'contextMenu.vehicle.delete',
                    icon: 'DeleteSmallIcon',
                });
            }

            return menuItems;
        },
    },
    methods: {
        contextAction(key) {
            this.$store.dispatch('globalActions/set', {
                action: key,
                subject: this.vehicle,
                context: this.routeContext,
            });
        },
    },
};
</script>

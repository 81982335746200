import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ConstructionProjectStatus } from '@/constructionProjects/api/projects';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { MenuTile } from '@/constructionProjects/components/MenuTile';
import { constructionProjectByIdAsAdminQuery } from '@/constructionProjects/queries';
import { Link } from '@schuettflix/react-components';
import { FlyoutPage } from '@/constructionProjects/components/FlyoutPage';
import { Modal } from '@/constructionProjects/components/Modal';
import { CONSTRUCTION_PROJECT_ADMIN_EDIT_ROUTE } from '@/constructionProjects/constants';
import { useConstructionProjectRouteParams } from '@/modules/constructionProjects/hooks/useConstructionProjectRouteParams';
import { useConstructionProjectAdminArchive } from '@/modules/constructionProjects/hooks/useConstructionProjectArchive';
import { InfoIcon } from '@/constructionProjects/components/icons/InfoIcon';
import { CONSTRUCTION_PROJECT_ADMIN_SETTINGS_ROUTE } from '@/modules/constructionProjects/pages/routes';
import { findRouteName } from '@/pages/routerUtils';
function PageContent({ constructionProject }) {
    const { t } = useTranslation();
    const [isOpenArchiveConfirmation, setIsOpenArchiveConfirmation] = useState(false);
    const { mutateAsync: archiveProject } = useConstructionProjectAdminArchive(constructionProject.id);
    const isNotArchivedProject = constructionProject.status !== ConstructionProjectStatus.Archived;
    const handleArchiveProject = () => {
        setIsOpenArchiveConfirmation(false);
        void archiveProject();
    };
    return (<div className="flex h-full flex-col overflow-auto bg p-4">
            <section className="pb-12">
                <p className="font-headline-md-strong mb-2">
                    {t('pages.constructionProject.projectSettings.sections.general.title')}
                </p>
                <p className="font-copy-md mb-6">
                    {t('pages.constructionProject.projectSettings.sections.general.description')}
                </p>
                <ul className="divide-y divide-light-gray-400 rounded bg-surface shadow-low">
                    <MenuTile dataTest="construction-project-settings-page-project-edit-link" to={{
            name: findRouteName(`${CONSTRUCTION_PROJECT_ADMIN_SETTINGS_ROUTE}__${CONSTRUCTION_PROJECT_ADMIN_EDIT_ROUTE}`),
            params: { id: constructionProject.id },
        }} icon={() => <InfoIcon multicolor size={'sm'}/>} label={t('pages.constructionProject.projectSettings.button.projectInfos.title')} description={t('pages.constructionProject.projectSettings.button.projectInfos.subtitle')}/>
                </ul>
            </section>

            {isNotArchivedProject && (<section className="pb-12">
                    <p className="font-headline-md-strong mb-2">
                        {t('pages.constructionProject.projectSettings.sections.archive.title')}
                    </p>
                    <p className="font-copy-md mb-6">
                        {t('pages.constructionProject.projectSettings.sections.archive.description')}
                    </p>
                    <div className="flex justify-end">
                        <Link label={t('pages.constructionProject.projectSettings.sections.archive.button.text')} onClick={() => setIsOpenArchiveConfirmation(true)} className="font-copy-md-strong"/>
                    </div>
                </section>)}
            {isNotArchivedProject && (<Modal isOpen={isOpenArchiveConfirmation} onClose={() => setIsOpenArchiveConfirmation(false)} onConfirm={handleArchiveProject} title={t('pages.constructionProject.projectSettings.archive.modal.title')} subtitle={t('pages.constructionProject.projectSettings.archive.modal.subtitle')} cancelButtonLabel={t('pages.constructionProject.projectSettings.archive.modal.onCancel')} confirmButtonLabel={t('pages.constructionProject.projectSettings.archive.modal.onConfirm')}/>)}
        </div>);
}
const _ConstructionProjectAdminSettings = () => {
    const { projectUuid } = useConstructionProjectRouteParams();
    const { t } = useTranslation();
    const { data: constructionProject } = useQuery({
        ...constructionProjectByIdAsAdminQuery(projectUuid),
        suspense: true,
    });
    const { vueRouter } = useVueRouter();
    if (!constructionProject) {
        return null;
    }
    return (<FlyoutPage screenName="platform-constructionproject-settings" onCloseAnimationEnd={() => vueRouter.back()} headerTitle={constructionProject.name} headerSubtitle={t('pages.constructionProject.projectSettings.header.title')} headerBackgroundColor={constructionProject.color} body={<PageContent constructionProject={constructionProject}/>}/>);
};
export const ConstructionProjectAdminSettings = asVueComponent(_ConstructionProjectAdminSettings);

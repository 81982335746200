import { Layer, Source, Marker } from 'react-map-gl/maplibre';
import React from 'react';
const FlagComponent = (props) => (<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width={50} height={50} {...props}>
        <ellipse cx={18} cy={44.6} fill="#d8d8d8" rx={7.5} ry={2.6}/>
        <path fill="red" d="M19.4 3.3h17.7c1.2 0 1.8 1.4 1 2.2l-3.6 4.2c-.6.7-.6 1.7 0 2.4l3.6 4.2c.7.8.3 2.1-.7 2.3h-18c-.9 0-1.6-.7-1.6-1.6V4.9c0-.9.7-1.6 1.6-1.6" className="f-highlight"/>
        <path d="M17.8 43.7V3.3" className="st2" style={{
        fill: 'none',
        stroke: '#000',
        strokeWidth: 2.61,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
    }}/>
    </svg>);
const PinCenteredComponent = (props) => (<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 100 100" {...props}>
        <ellipse cx={50.2} cy={50.9} rx={7.5} ry={2.6} style={{
        fill: '#d8d8d8',
    }}/>
        <path d="m50.1 50.2-.1-43" style={{
        fill: 'none',
        stroke: '#000',
        strokeWidth: 2.61,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
    }}/>
        <path d="M61.3 13.2c0-6.4-5.2-11.5-11.6-11.5S38.2 6.9 38.2 13.3s5.2 11.5 11.6 11.5c6.4 0 11.5-5.2 11.5-11.6" style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: 'red',
    }}/>
    </svg>);
function buildFeatureCollection(coordinates) {
    return {
        type: 'FeatureCollection',
        lineMetrics: true,
        features: [
            {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: coordinates,
                },
            },
        ],
    };
}
export const RouteSource = ({ coordinates }) => {
    const [startLng, startLat] = coordinates[0];
    const [endLng, endLat] = coordinates[coordinates.length - 1];
    return (<Source type={'geojson'} data={buildFeatureCollection(coordinates)}>
            <Layer id="lineOutlineLayer" type="line" source="my-data" beforeId="road-shield-rtype_6" layout={{
            'line-join': 'round',
            'line-cap': 'round',
        }} paint={{
            'line-color': 'rgba(25, 103, 210, 1)',
            'line-width': 8,
        }}/>
            <Layer id="lineLayer" type="line" source="my-data" beforeId="road-shield-rtype_6" layout={{
            'line-join': 'round',
            'line-cap': 'round',
        }} paint={{
            'line-color': 'rgba(52,176,255,1)',
            'line-width': 5,
        }}/>

            <Marker latitude={startLat} longitude={startLng} anchor="center">
                <div>
                    <PinCenteredComponent width="60" height="60"/>
                </div>
            </Marker>

            <Marker latitude={endLat} longitude={endLng} anchor="center">
                <div>
                    <FlagComponent width="40" height="30"/>
                </div>
            </Marker>
        </Source>);
};

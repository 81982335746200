<template>
    <LoadingSpinner v-if="isLoading" dark class="waste-form__loading-indicator" />
    <FlyoutPage v-else data-test="product-management">
        <HeaderBar slot="header">
            <HeaderBarItem slot="left" button @click="$root.routeBack()">
                <ArrowIcon width="32" height="18" />
            </HeaderBarItem>
            <div slot="headline">{{ $t('pages.productManagement.wasteProductEditPage.headline') }}</div>
            <Words v-if="product.lastUpdatedAt" slot="subline" muted>
                {{
                    $t('pages.productManagement.wasteProductEditPage.subline.lastUpdatedAt', {
                        date: $d(new Date(product.lastUpdatedAt * 1000), 'medium'),
                    })
                }}
            </Words>
        </HeaderBar>

        <div class="product-management">
            <form @submit.prevent="handleSubmit">
                <GridRow :count="1" spacing="large">
                    <div v-if="wasteCode" class="header">
                        <div class="col1">
                            <Words tiny bold spaced-bottom block>
                                {{ $t('pages.productManagement.wasteProductEditPage.wasteSku') }}
                            </Words>
                            <div class="waste-wrap">
                                <WasteCode
                                    x-large
                                    bold
                                    :code="wasteCode"
                                    :is-dangerous="product.isDangerous"
                                    class="waste-code"
                                    data-test="waste-code"
                                />
                                <WasteTag :is-dangerous="product.isDangerous" />
                            </div>
                        </div>
                        <div class="col2">
                            <Words tiny bold spaced-bottom block align-right>
                                {{ $t('pages.productManagement.wasteProductEditPage.availability') }}
                            </Words>
                            <ToggleSwitchField v-model="product.isActive" />
                        </div>
                    </div>
                </GridRow>
                <GridRow :count="1">
                    <TextField
                        :key="`productproductname-de`"
                        v-model="product.productName.de"
                        :label="$t('pages.productManagement.wasteProductEditPage.productName')"
                        :error="getError(`product.productName.de`)"
                        class="span-1"
                    />
                </GridRow>
                <!-- Product Categories -->
                <GridRow
                    v-if="$root.isDesktop"
                    :class="{ 'product-management__categories-desktop': $root.isDesktop }"
                    :count="1"
                >
                    <WasteDropdownRenderer
                        v-model="product.categoryIds"
                        :nr-of-other-choices="nrOfOtherChoices"
                        :is-big-placeholder-active="isBigPlaceholderActive"
                        :placeholder="$t('pages.productManagement.wasteProductEditPage.productCategoryPlaceholder')"
                        :first-option="getFirstOptionName"
                        :tree="categoryTree"
                        :is-open="isOpen"
                        :error="getError('product.categoryIds')"
                        @toggle="isOpen = !isOpen"
                    />
                </GridRow>
                <GridRow v-else :count="1">
                    <SelectionTree v-model="product.categoryIds" :tree="categoryTree" multiple name="parent" />
                </GridRow>
                <GridRow :count="1">
                    <ProductImageUpload v-model="product.image" class="span-4" />
                </GridRow>
                <GridRow :count="1">
                    <div class="span-1">
                        <Headline :level="6">
                            {{ $t('pages.productManagement.wasteProductEditPage.productVariants') }}
                        </Headline>
                        <BaseButton type="button" primary block @click="clickShowProductVariants">
                            {{ $t('pages.productManagement.wasteProductEditPage.productVariantsShow') }}
                            <ArrowIcon slot="right" width="18" height="18" class="icon--inline icon--rotate-180" />
                        </BaseButton>
                    </div>
                </GridRow>
                <GridRow class="row--margin-top" :count="1" spacing="small">
                    <Words tiny bold block>
                        {{ $t('pages.productManagement.wasteProductEditPage.productDescription') }}
                    </Words>
                    <TextField v-model="product.description.de" type="textarea" class="span-1" />
                </GridRow>
                <GridRow class="row--margin-top" :count="1" spacing="small">
                    <Words tiny bold block>
                        {{ $t('pages.productManagement.wasteProductEditPage.productDescriptionAllowed') }}
                    </Words>
                    <TextField v-model="product.contains" type="textarea" class="span-1" />
                </GridRow>
                <GridRow class="row--margin-top" :count="1" spacing="small">
                    <Words tiny bold block>
                        {{ $t('pages.productManagement.wasteProductEditPage.productDescriptionNotAllowed') }}
                    </Words>
                    <TextField v-model="product.cannotContain" type="textarea" class="span-1" />
                </GridRow>
                <GridRow class="row--margin-top" :count="1" spacing="small">
                    <TextField
                        v-model.number="product.density"
                        :error="getError('product.density')"
                        type="number"
                        step="0.01"
                        :label="$t('pages.productManagement.wasteProductEditPage.productDensity')"
                        class="span-1"
                    />
                </GridRow>
                <GridRow :count="1" spacing="small">
                    <TagListFieldset v-model="product.tags" />
                </GridRow>
                <GridRow :count="1" spacing="small">
                    <TextField
                        v-model="product.sku"
                        :label="$t('pages.productManagement.wasteProductEditPage.sku')"
                        :error="getError('product.sku')"
                        class="span-1"
                    />
                </GridRow>
            </form>
        </div>

        <ButtonGroup slot="footer" class="btn-group">
            <BaseButton :to="{ name: listPageRoute }" arrow-left align-left primary light class="btn-reset">
                {{ $t('pages.productManagement.wasteProductEditPage.buttons.reset') }}
            </BaseButton>
            <BaseButton type="button" primary data-test="confirm-edit-waste-product" @click="handleSubmit">
                {{ $t('pages.productManagement.wasteProductEditPage.buttons.save') }}
                <ArrowIcon slot="right" width="18" height="18" class="icon--inline icon--rotate-180" />
            </BaseButton>
        </ButtonGroup>
        <Flyout
            size="large"
            screen-name="platform-productmanagement-waste-edit-variants"
            :route="productVariantsEditRoute"
            no-header
        />
    </FlyoutPage>
</template>

<script>
import _throttle from 'lodash/throttle';
import _cloneDeep from 'lodash/cloneDeep';
import { isRequired, isFloat, minLength } from '@/services/validation/rules';
import { localize, revertLocalizedCollectionValues } from '@/services/utils/localization';
import { CONSTRUCTION_PRODUCT, WASTE_PRODUCT } from '@/constants/productTypes';
import Category from '@/services/Api/Platform/Category';
import WasteProduct from '@/services/Api/Waste/WasteProduct';
import Toaster from '@/services/Toaster';
import validate from '@/services/validation/mixin';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Flyout from '@/components/Layout/Flyout';
import FlyoutPage from '@/components/Layout/FlyoutPage';
import GridRow from '@/components/Layout/GridRow';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Headline from '@/components/Typography/Headline';
import ProductImageUpload from './components/ProductImageUpload';
import SelectionTree from '@/components/Tree/SelectionTree';
import TextField from '@/components/Form/TextField.v2';
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import TreeNode from '@/components/Tree/TreeNode';
import WasteCode from '@/pages/ProductManagement/components/WasteCode';
import WasteTag from '@/pages/ProductManagement/components/WasteTag';
import Words from '@/components/Typography/Words';
import WasteDropdownRenderer from '@/pages/ProductManagement/components/WasteDropdownRenderer/WasteDropdownRenderer';
import LoadingSpinner from '@/components/LoadingSpinner';
import TagListFieldset from './components/TagListFieldset';

import ArrowIcon from '@/assets/icons/regular/arrow.svg';

const THROTTLE_DELAY = 3000;

const routeMap = {
    [WASTE_PRODUCT]: 'waste',
};

export default {
    name: 'WasteFormPage',
    components: {
        BaseButton,
        ButtonGroup,
        Flyout,
        FlyoutPage,
        GridRow,
        HeaderBar,
        HeaderBarItem,
        Headline,
        ProductImageUpload,
        SelectionTree,
        TextField,
        ToggleSwitchField,
        WasteCode,
        WasteTag,
        Words,
        WasteDropdownRenderer,
        LoadingSpinner,
        TagListFieldset,

        ArrowIcon,
    },
    mixins: [validate],
    props: {
        listType: {
            type: String,
            validator: v => [CONSTRUCTION_PRODUCT, WASTE_PRODUCT].includes(v),
            // TODO: do we need the list type anymore?
            default: WASTE_PRODUCT,
        },
    },
    data() {
        let productProperties = {
            id: null,
            productName: localize(null),
            categoryIds: [],
            sku: null,
            description: localize(null),
            contains: null,
            cannotContain: null,
            density: null,
            image: null,
            created: null,
            updated: null,
            tags: [],
            states: [],
            isActive: null,
            variantDisposal: {
                isActive: false,
            },
            wasteCode: null,
            lastUpdatedAt: null,
        };

        if (this.listType === WASTE_PRODUCT) {
            productProperties = {
                ...productProperties,
                variantDisposal: {
                    isActive: false,
                },
            };
        }

        return {
            isLoading: false,
            isOpen: false,
            product: productProperties,
            categoryTree: null,
            wasteCode: null,
        };
    },
    computed: {
        validationRules() {
            return {
                'product.productName.de': [isRequired()],
                'product.categoryIds': [minLength(1)],
                'product.density': [isRequired(), isFloat()],
                'product.sku': [isRequired()],
            };
        },
        getFirstOptionName() {
            const firstCatId = this.product?.categoryIds[0];
            const firstActiveChild = this.categoryTree?.children.find(child => child.item.id === firstCatId);
            return firstActiveChild ? firstActiveChild.item.name : '';
        },
        isBigPlaceholderActive() {
            return this.product?.categoryIds?.length === 0;
        },
        nrOfOtherChoices() {
            const activeChildren = this.categoryTree?.children.filter(child =>
                this.product?.categoryIds?.includes(child.item.id)
            );
            return activeChildren?.length > 1 ? `(${activeChildren?.length - 1})` : '';
        },
        listPageRoute() {
            return this.$root.findRouteName(`${routeMap[this.listType]}-product-list`);
        },
        productVariantsEditRoute() {
            return this.$root.findRouteName(`${routeMap[this.listType]}-product-variants`);
        },
    },
    created() {
        this.product.id = this.$route.params.id;
        this.loadProductData(this.product.id);
    },
    methods: {
        async loadProductData(productId = null) {
            try {
                this.isLoading = true;
                if (productId) {
                    const product = await WasteProduct.getOneById({ productId });
                    this.product = Object.assign(this.product, product);
                    this.wasteCode = this.product.wasteCode;
                }

                const categories = await Category.getCategoryByType(this.listType);
                this.categoryTree = TreeNode.factory(revertLocalizedCollectionValues(categories, ['name']));
                this.isLoading = false;
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(err);
            }
        },
        handleSubmit: _throttle(
            function () {
                if (!this.isValid()) {
                    return;
                }
                const productToBeSaved = this.returnProductToBeSaved(this.product);

                if (productToBeSaved.variantDisposal) {
                    productToBeSaved.type = 'disposal_type';
                }
                WasteProduct.put({ productId: this.product.id }, productToBeSaved)
                    .then(() => {
                        this.$router.push({ name: this.listPageRoute }).catch(err => {
                            this.$logger().error(err);
                        });
                    })
                    .catch(err => {
                        Toaster.error(err);
                    });
            },
            THROTTLE_DELAY,
            {
                trailing: false,
            }
        ),
        returnProductToBeSaved(product) {
            const productToBeSaved = _cloneDeep(product);

            if (productToBeSaved.image) productToBeSaved.image = productToBeSaved.image.uuid;

            const tagIds = productToBeSaved.tags.map(tagObj => tagObj.id);
            productToBeSaved.tagIds = tagIds;

            // Remove unnecessary fields to adapt to the data structure that Backend needs
            const unnecessaryFields = [
                'created',
                'isDangerous',
                'productType',
                'type',
                'updated',
                'variantDisposal',
                'tags',
            ];
            unnecessaryFields.forEach(field => this.$delete(productToBeSaved, field));
            return productToBeSaved;
        },
        close() {
            this.$router.push({ name: this.listPageRoute }).catch(err => {
                this.$logger().error(err);
            });
        },
        clickShowProductVariants() {
            this.$router
                .push({
                    name: this.productVariantsEditRoute,
                    params: { id: this.product.id, states: this.product.states },
                })
                .catch(err => {
                    this.$logger().error(err);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.waste-form__loading-indicator {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    .col2 {
        display: inline-flex;
        flex-direction: column;
        align-content: center;
        align-items: flex-end;
    }
}
.waste-wrap {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}
.waste-code {
    margin-right: 1rem;
}

.warning-icon {
    display: inline-block;
    margin-right: 0.25rem;
}
.tf-disabled::v-deep .text-field__input--disabled {
    background: $color-littleDarkerThanLightMediumGrey;
}
.btn-group {
    justify-content: space-between;
}
.btn-reset {
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: auto;
}

.product-management {
    padding: 20px 25px 45px;
    background: $color-mediumGrey;

    &__categories-desktop {
        display: inline-block;
        width: 100%;
    }
}

.product-management__group {
    margin: -30px 0 0 -30px;
}

.product-management__group--vertical {
    display: flex;
    flex-direction: row;
}

.product-management__field-group {
    width: 100%;
    margin: 30px 0 0 30px;
}

.product-management__field {
    & + & {
        margin-top: 20px;
    }
}

.span--place-left {
    margin-right: auto;
    transition: opacity 0.1s ease-in-out;
    cursor: pointer;
    padding-left: 20px;
    text-align: left;

    &:hover,
    &:focus,
    &:active {
        opacity: 0.7;
        transition: opacity 0.1s ease-in-out;
    }

    @media only screen and (min-width: $layout-desktop-min) {
        padding-left: 0;
        text-align: center;
    }
}

.product-management__section {
    background-color: $color-white;
    padding: 0 25px;
    margin-left: -25px;
    margin-right: -25px;
}

.row--margin-top {
    margin-top: 24px;
}
</style>

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { useQuery } from '@tanstack/react-query';
import { constructionProjectByIdAsAdminQuery } from '@/constructionProjects/queries';
import { Header } from '@/constructionProjects/components/Header';
import { useConstructionProjectRouteParams } from '@/modules/constructionProjects/hooks/useConstructionProjectRouteParams';
import { CONSTRUCTION_PROJECT_ADMIN_POSITIONS_ROUTE } from '@/modules/constructionProjects/pages/routes';
import { useProductGroupsByPositionId } from '../hooks/useProductGroupsByPositionId';
import { useRoute } from '@/reactBridge/useRoute';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { GenericPositionActions } from './GenericPositionActions';
import { useGenericPositionById } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/hooks/useGenericPositionById';
const _GenericPositionDetailsHeader = () => {
    const { projectUuid } = useConstructionProjectRouteParams();
    const currentRoute = useRoute();
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const { data: constructionProject } = useQuery({
        ...constructionProjectByIdAsAdminQuery(projectUuid),
        suspense: true,
    });
    const { data: position } = useGenericPositionById(currentRoute.params.positionId);
    const { data: productGroups } = useProductGroupsByPositionId(currentRoute.params.positionId);
    if (!constructionProject || !position || !productGroups?.length) {
        return null;
    }
    return (<Header desktopTitle={position.positionNumber} mobileTitle={position.positionNumber} navigationTitle={t('pages.genericPosition.details.header.title')} onBack={() => vueRouter.push({ name: CONSTRUCTION_PROJECT_ADMIN_POSITIONS_ROUTE })} backgroundColor={constructionProject.color}>
            <GenericPositionActions genericPositionId={currentRoute.params.positionId}/>
        </Header>);
};
export const GenericPositionDetailsHeader = asVueComponent(_GenericPositionDetailsHeader);

<template>
    <GridRow :count="1" spacing="large">
        <DataTable class="vehicle-class-scope-table">
            <template #header>
                <tr>
                    <th scope="col">{{ $t('pages.vehicleClassFormPage.scope') }}</th>
                    <th scope="col">
                        {{
                            $t('pages.vehicleClass.scopeForm.purchasePriceKmWithCurrency', {
                                currency: getCurrencySign(),
                            })
                        }}
                    </th>
                    <th scope="col">
                        {{
                            $t('pages.vehicleClass.scopeForm.salesPriceKmWithCurrency', { currency: getCurrencySign() })
                        }}
                    </th>
                    <th scope="col">
                        {{
                            $t('pages.vehicleClass.scopeForm.purchasePriceMinuteWithCurrency', {
                                currency: getCurrencySign(),
                            })
                        }}
                    </th>
                    <th scope="col">
                        {{
                            $t('pages.vehicleClass.scopeForm.salesPriceMinuteWithCurrency', {
                                currency: getCurrencySign(),
                            })
                        }}
                    </th>
                    <th scope="col">{{ $t('pages.vehicleClassFormPage.minPurchasePrice') }}</th>
                    <th scope="col">{{ $t('pages.vehicleClassFormPage.minSalesPrice') }}</th>
                    <th scope="col">{{ $t('pages.vehicleClassFormPage.outwardReturn') }}</th>
                    <th scope="col">&nbsp;</th>
                </tr>
            </template>

            <tr v-for="(entry, index) in vehicleClass.scopeItems" :key="index">
                <td>
                    <Words unbreakable>
                        <Words bold spaced-right>{{ entry.country }}</Words>
                        <Words>{{ entry.scope }}</Words>
                    </Words>
                    <ErrorMessage
                        v-if="hasErrors(`scopeItems.${index}.scope`) || hasErrors(`scopeItems.${index}.country`)"
                        :message="getError(`scopeItems.${index}.scope`) || getError(`scopeItems.${index}.country`)"
                    />
                </td>
                <td>
                    <Words muted small>
                        {{ entry.formattedPurchasePriceKm }}
                    </Words>
                    <ErrorMessage
                        v-if="hasErrors(`scopeItems.${index}.purchasePriceKm`)"
                        :message="getError(`scopeItems.${index}.purchasePriceKm`)"
                    />
                </td>
                <td>
                    <Words small>{{ entry.formattedSalesPriceKm }}</Words>
                    <ErrorMessage
                        v-if="hasErrors(`scopeItems.${index}.salesPriceKm`)"
                        :message="getError(`scopeItems.${index}.salesPriceKm`)"
                    />
                </td>
                <td>
                    <Words muted small block>{{ entry.formattedPurchasePriceMinute }}</Words>
                    <Words tiny muted block>{{ entry.formattedPurchasePriceHour }}</Words>
                    <ErrorMessage
                        v-if="hasErrors(`scopeItems.${index}.purchasePriceHour`)"
                        :message="getError(`scopeItems.${index}.purchasePriceHour`)"
                    />
                </td>
                <td>
                    <Words small block>{{ entry.formattedSalesPriceMinute }}</Words>
                    <Words tiny block>{{ entry.formattedSalesPriceHour }}</Words>
                    <ErrorMessage
                        v-if="hasErrors(`scopeItems.${index}.salesPriceHour`)"
                        :message="getError(`scopeItems.${index}.salesPriceHour`)"
                    />
                </td>
                <td>
                    <Words small block>{{ entry.formattedMinPurchasePriceTransport }}</Words>
                    <Words tiny block>{{ entry.formattedMinPurchasePriceTon }}</Words>
                    <ErrorMessage
                        v-if="hasErrors(`scopeItems.${index}.minPurchasePriceTransport`)"
                        :message="getError(`scopeItems.${index}.minPurchasePriceTransport`)"
                    />
                </td>
                <td>
                    <Words small block>{{ entry.formattedMinSalesPriceTransport }}</Words>
                    <Words tiny block>{{ entry.formattedMinSalesPriceTon }}</Words>
                    <ErrorMessage
                        v-if="hasErrors(`scopeItems.${index}.minPurchasePriceTransport`)"
                        :message="getError(`scopeItems.${index}.minPurchasePriceTransport`)"
                    />
                </td>
                <td>
                    <TickIcon
                        v-if="entry.isDoubleTransportEffort"
                        width="18"
                        height="18"
                        class="icon--inline icon--green"
                    />
                    <Words v-else>—</Words>
                </td>
                <td class="vehicle-class-scope-table__button-row" align="right" style="white-space: nowrap">
                    <Button icon @click="$emit('edit', entry)">
                        <PenIcon />
                    </Button>
                    <Button :disabled="entry.isDefault" icon @click="$emit('remove', entry)">
                        <GarbageIcon />
                    </Button>
                </td>
            </tr>
        </DataTable>
    </GridRow>
</template>

<script>
import VehicleClassView from '@/models/VehicleClass/VehicleClassView';
import validate from '@/services/validation/mixin';
import { getCurrencySign } from '@/services/utils/currency';

import Button from '@/components/Button/Button';
import DataTable from '@/components/Table/DataTable';
import ErrorMessage from '@/components/Form/ErrorMessage';
import GridRow from '@/components/Layout/GridRow';
import Words from '@/components/Typography/Words';

import GarbageIcon from '@/assets/icons/regular/garbage.svg';
import PenIcon from '@/assets/icons/regular/pen.svg';
import TickIcon from '@/assets/icons/micro/tick.svg';

export default {
    name: 'VehicleClassScopeTable',
    components: {
        Button,
        DataTable,
        ErrorMessage,
        GridRow,
        Words,

        GarbageIcon,
        PenIcon,
        TickIcon,
    },
    mixins: [validate],
    props: {
        vehicleClass: {
            type: VehicleClassView,
            default: null,
        },
        parentErrors: {
            type: Object,
            default: () => {},
        },
    },
    watch: {
        // Move error binding to child
        parentErrors: {
            immediate: true,
            handler(next) {
                this.errors = next;
            },
        },
    },
    methods: {
        getCurrencySign,
    },
};
</script>

<style lang="scss" scoped>
.vehicle-class-scope-table {
    margin-bottom: 1.5em;
}

.vehicle-class-scope-table__button-row > .button {
    margin: 0 10px;
}
</style>

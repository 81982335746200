<template>
    <Flyout
        class="organization-selector"
        :active="organizationSelectorActive"
        :headline="$t('pages.user.userForm.newUser.title')"
        @closed="$emit('closed')"
    >
        <div>
            <Headline class="container-deprecated" :level="6">
                {{ $t('pages.user.userForm.newUser.typeHeadline') }}
            </Headline>

            <RoundedTabNavigation
                :value="selectedOrganizationType"
                :tabs="{
                    client: $t('pages.user.userForm.organizationTypes.client'),
                    supplier: $t('pages.user.userForm.organizationTypes.supplier'),
                    carrier: $t('pages.user.userForm.organizationTypes.carrier'),
                    platform: $t('pages.user.userForm.organizationTypes.platform'),
                }"
                @input="type => $emit('updateSelectedOrganizationType', type)"
            />
        </div>

        <Card spaceless class="organization-selector__list">
            <TextField
                v-model="searchTerm"
                data-test="search-user"
                :label="$t('pages.user.userForm.newUser.searchLabel')"
                @input="debouncedRefresh"
            >
                <SearchIcon slot="action" />
            </TextField>
            <TabStages :active-stage="selectedOrganizationType" class="organization-selector__stages">
                <TabStage v-for="stage in ['client', 'supplier', 'carrier']" :key="stage" :stage="stage">
                    <div class="organization-selector__organizations" spaceless>
                        <div
                            v-if="organizationsByType[stage] && organizationsByType[stage].items.length > 0"
                            class="organization-selector__organizations-list"
                        >
                            <div
                                v-for="(organization, i) in organizationsByType[stage].items"
                                :key="i"
                                class="organization-selector__organization"
                                @click="
                                    $emit(
                                        'createUserForOrganizationType',
                                        stage,
                                        organization.id,
                                        organization.market.defaultLocale
                                    )
                                "
                            >
                                <Words block>
                                    {{ organization.name }}
                                    <Words muted>({{ organization.customerNumber }})</Words>
                                    <Words muted small block>
                                        <FormattedAddress :address="organization.billingAddress" />
                                    </Words>
                                </Words>
                                <div class="organization-selector__organization-types">
                                    <Tag v-for="type in organization.types" :key="type" black small bold>
                                        {{ $t(`pages.user.userForm.organizationTypes.${type}`) }}
                                    </Tag>
                                </div>
                            </div>
                        </div>
                        <div
                            v-else-if="organizationsByType[stage]"
                            class="organization-selector__organizations-list organization-selector__organizations-list--empty"
                        >
                            <Words small>{{ $t('pages.user.userList.noOrganizationResults') }}</Words>
                        </div>
                    </div>
                </TabStage>

                <TabStage stage="platform" />
            </TabStages>
        </Card>
    </Flyout>
</template>

<script>
import _debounce from 'lodash/debounce';
import OrganizationApi from '@/services/Api/Organization';
import Toaster from '@/services/Toaster';

import Card from '@/components/Layout/Card';
import Flyout from '@/components/Layout/Flyout';
import FormattedAddress from '@/components/FormattedAddress';
import Headline from '@/components/Typography/Headline';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation';
import TabStage from '@/components/Tab/TabStage';
import TabStages from '@/components/Tab/TabStages';
import Tag from '@/components/Typography/Tag';
import TextField from '@/components/Form/TextField.v2';
import Words from '@/components/Typography/Words';

import SearchIcon from '@/assets/icons/micro/search.svg';

const DEBOUNCE_MS = 300;

export default {
    name: 'OrganizationSelector',

    components: {
        Card,
        Flyout,
        FormattedAddress,
        Headline,
        RoundedTabNavigation,
        TabStage,
        TabStages,
        Tag,
        TextField,
        Words,

        SearchIcon,
    },

    props: {
        organizationSelectorActive: {
            type: Boolean,
            required: true,
        },
        selectedOrganizationType: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            organizationsByType: {},
            searchTerm: '',
            cancelSource: null,
        };
    },

    watch: {
        organizationSelectorActive(newValue) {
            if (newValue === true) {
                this.searchTerm = '';
            }
        },
        selectedOrganizationType() {
            if (!this.organizationSelectorActive) return;
            this.refreshOrgansiationList();
        },
    },

    methods: {
        debouncedRefresh: _debounce(function () {
            this.refreshOrgansiationList();
        }, DEBOUNCE_MS),

        async refreshOrgansiationList() {
            try {
                this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
                this.cancelSource = OrganizationApi.createCancelTokenSource();

                const result = await OrganizationApi.filter(
                    { organizationType: this.selectedOrganizationType, search: this.searchTerm },
                    null,
                    null,
                    this.cancelSource
                );
                this.$set(this.organizationsByType, this.selectedOrganizationType, result);
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                    Toaster.error(err.message);
                }
            }
        },
    },
};
</script>

<style lang="scss">
.organization-selector__organizations {
    width: 100%;
    display: flex;
    flex-flow: column;
}

.organization-selector__organizations-list {
    flex: 1;
    margin-top: 20px;
}

.organization-selector__organizations-list--empty {
    text-align: center;
}

.organization-selector__organization {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: $color-border-light-grey 1px solid;

    &:last-of-type {
        border-bottom: none;
    }
}

.organization-selector__stages {
    flex: 1;
    display: flex;

    .tab-stage {
        display: flex;
    }
}

.organization-selector {
    .flyout__inner {
        background-color: $color-lightMediumGrey;
    }
}

.organization-selector__organization-types {
    text-align: right;
    margin-left: 10px;
    > * + * {
        margin-left: 10px;
    }
    > span:first-of-type {
        margin-bottom: 5px;
    }
}
</style>

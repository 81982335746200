import { useVuexState } from '@/reactBridge/useVuex';
import store from '@/store';
import { MPS_CUSTOMER_ORDER_DELIVERY_SUPPLIER_LOCATIONS, MPS_CUSTOMER_ORDER_PICKUP_SUPPLIER_LOCATIONS, MPS_PLATFORM_ORDER_CUSTOM_DELIVERY_SUPPLIER_LOCATIONS, MPS_PLATFORM_ORDER_PROJECT_DELIVERY_SUPPLIER_LOCATIONS, } from '@/constants/marketPermissions';
export default () => {
    const isCustomOrder = useVuexState(store, state => state.basket.isCustom);
    const orderType = useVuexState(store, state => state.basket.type);
    const shippingMethod = useVuexState(store, state => state.basket?.deliveryMethod?.name || 'default');
    const orderAmount = useVuexState(store, state => state.basket.qty);
    const attributeRange = useVuexState(store, state => state.basket.product.attributeRange);
    const attributes = useVuexState(store, state => state.basket.product.attributeIds);
    const transports = useVuexState(store, state => state.basket?.transports || []);
    const getOperation = () => {
        if (isCustomOrder && orderType === 'delivery') {
            return MPS_PLATFORM_ORDER_CUSTOM_DELIVERY_SUPPLIER_LOCATIONS;
        }
        if (isCustomOrder && orderType === 'project-delivery') {
            return MPS_PLATFORM_ORDER_PROJECT_DELIVERY_SUPPLIER_LOCATIONS;
        }
        if (!isCustomOrder && orderType === 'delivery') {
            return MPS_CUSTOMER_ORDER_DELIVERY_SUPPLIER_LOCATIONS;
        }
        if (!isCustomOrder && orderType === 'pickup') {
            return MPS_CUSTOMER_ORDER_PICKUP_SUPPLIER_LOCATIONS;
        }
        return null;
    };
    const getTransports = () => {
        return (transports?.transports?.map((transport) => {
            return {
                shippingDate: transport.date,
                qty: transport.actualLoad,
                vehicleClassId: transport.id,
            };
        }) || []);
    };
    return {
        generateDeliveryPayload: () => {
            const operation = getOperation();
            return {
                qty: orderAmount,
                operations: operation ? [operation] : null,
                attributeRange: attributeRange,
                attributes: attributes,
                orderAmount,
                ...(orderType === 'project-delivery'
                    ? {}
                    : {
                        transports: getTransports(),
                        shippingMethod,
                    }),
            };
        },
    };
};

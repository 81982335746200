<template>
    <ModalBox
        :active="isActive"
        :headline="
            isNew
                ? $t('pages.project.position.action.noteChange.headline.add')
                : $t('pages.project.position.action.noteChange.headline.edit')
        "
        optional-dialog
        class="project-position-change-note"
        @closed="closeFlyout()"
    >
        <template v-if="isActionAvailable">
            <Words block spaced-top spaced-bottom>
                {{ $t('pages.project.position.action.noteChange.message') }}
            </Words>

            <Words block bold spaced-bottom-small>
                {{ $t('pages.project.position.action.noteChange.inputLabel') }}
            </Words>
            <TextField
                v-model="note"
                type="textarea"
                :has-error="hasErrors('note')"
                :error="getError('note')"
                :label="$t('pages.project.position.action.noteChange.inputPlaceholder')"
            />
            <Words v-if="subject.noteUpdated" block tiny muted spaced-top-tiny>
                {{
                    $t('pages.project.position.action.noteChange.updateByLabel', {
                        date: $d(subject.noteUpdated, 'short'),
                        name: subject.noteUpdatedBy.fullName,
                    })
                }}
            </Words>
        </template>
        <ButtonGroup slot="actions">
            <Button :disabled="isPending()" :is-loading="isPending('saveNote')" primary @click="saveNote()">
                {{
                    isNew
                        ? $t('pages.project.position.action.noteChange.submit.add')
                        : $t('pages.project.position.action.noteChange.submit.edit')
                }}
                <ArrowRightIcon slot="right" class="icon--inline" />
            </Button>
        </ButtonGroup>
    </ModalBox>
</template>

<script>
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import validation from '@/services/validation/mixin';
import { ACTIONS } from '@/constants/actions';
import { EVENT_PROJECT_POSITION_UPDATED } from '@/constants/events';
import { mapGetters } from 'vuex';
import ProjectPositionView from '@/models/ProjectPositionView';
import Toaster from '@/services/Toaster';
import { maxLength } from '@/services/validation/rules';
import statefulMixin from '@/plugins/mixins/statefulMixin';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ModalBox from '@/components/Modal/ModalBox';
import ProjectPositionApi from '@/services/Api/ProjectPosition';
import TextField from '@/components/Form/TextField.v2';
import Words from '@/components/Typography/Words';

import ArrowRightIcon from '@/assets/icons/micro/arrow.svg';

export default {
    name: 'ProjectPositionNoteChangeAction',
    components: {
        Button,
        ButtonGroup,
        ModalBox,
        TextField,
        Words,

        ArrowRightIcon,
    },
    mixins: [eventHubMixin, statefulMixin, validation],
    data() {
        return {
            isNew: true,
            note: null,
            validationRules: {
                note: [maxLength(255)],
            },
        };
    },
    computed: {
        ...mapGetters('globalActions', ['subject', 'action']),

        isActive() {
            return this.action === ACTIONS.PROJECT_POSITION_NOTE_CHANGE;
        },

        isActionAvailable() {
            return this.subject instanceof ProjectPositionView;
        },
    },
    watch: {
        isActive(value) {
            if (value) {
                this.isNew = !this.subject.note;
                this.note = this.subject.note;
            }
        },
    },
    methods: {
        closeFlyout() {
            this.$store.dispatch('globalActions/reset', ACTIONS.PROJECT_POSITION_NOTE_CHANGE);
        },
        async saveNote() {
            if (!this.isValid()) return;
            await this.stateful('saveNote', async () => {
                try {
                    await ProjectPositionApi.updateNote(this.subject.id, this.note);

                    Toaster.success(
                        this.isNew
                            ? this.$t('pages.project.position.action.noteChange.success.add')
                            : this.$t('pages.project.position.action.noteChange.success.edit')
                    );

                    this.$eventHub.$emit(EVENT_PROJECT_POSITION_UPDATED, this.subject);
                    this.closeFlyout();
                } catch (err) {
                    Toaster.error(err);
                }
            });
        },
    },
};
</script>

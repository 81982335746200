<template>
    <div class="driving-time">
        <div class="driving-time__data">
            <TickIcon v-if="hasArrived" class="driving-time__icon icon--green" />
            <FlagIcon
                v-else
                :class="{
                    'icon--green': !transport.isLate && transport.remainingDistance,
                    'icon--red': transport.isLate && transport.remainingDistance,
                    'icon--grey': !transport.remainingDistance,
                }"
                class="driving-time__icon"
            />
            <Words
                v-if="transport.remainingDistance"
                :green="!transport.isLate"
                :red="transport.isLate"
                :bold="!weightNormal"
                small
            >
                {{ $t('components.arrivalTime.roundabout') }}
                {{ formatArrivalTime(transport.arrivalTime) }}
                ({{ $n(transport.remainingDistance / 1000, 'distance') }} {{ $t('units.kilometer') }})
            </Words>
            <Words v-else muted bold small>
                {{ $t('components.arrivalTime.noData') }}
            </Words>
        </div>
    </div>
</template>

<script>
import Words from '@/components/Typography/Words';
import FlagIcon from '@/assets/icons/micro/flag.svg';
import TickIcon from '@/assets/icons/micro/tick.svg';
import { formatArrivalTime } from '@/services/utils/date';
import { statusIsAfter } from '@/services/utils/transport';

export default {
    name: 'DrivingTime',
    components: {
        Words,
        FlagIcon,
        TickIcon,
    },
    props: {
        transport: {
            type: Object,
            required: true,
        },
        weightNormal: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasArrived() {
            return statusIsAfter(this.transport.status, 'destination_waiting');
        },
    },
    methods: {
        formatArrivalTime,
    },
};
</script>

<style lang="scss">
.driving-time__data {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.driving-time__icon {
    flex: 0 0 14px;
    display: inline-block;
    vertical-align: middle;
    min-width: 14px;
    margin-right: 10px;

    svg {
        width: 100%;
        height: auto;
    }
}
</style>

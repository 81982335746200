import _isArray from 'lodash/isArray';

const state = {
    subject: null,
    action: null,
};

const getters = {
    subject: state => state.subject,
    action: state => state.action,
};

const mutations = {
    updateSubject(state, subject) {
        if (state.subject && state.subject.id === subject.id) {
            state.subject = { ...state.subject, ...subject };
        } else {
            state.subject = subject;
        }
    },

    setSubject(state, subject) {
        state.subject = subject;
    },

    setAction(state, action) {
        state.action = action;
    },

    resetAction(state, action = null) {
        if (!action) {
            state.action = null;
        } else if (_isArray(action) && action.includes(state.action)) {
            state.action = null;
        } else if (state.action === action) {
            state.action = null;
        }
    },
};

const actions = {
    set({ commit }, { action, subject, update = false }) {
        commit('setAction', action);
        commit(update ? 'updateSubject' : 'setSubject', subject);
    },

    reset({ commit }, action) {
        commit('resetAction', action);
        commit('setSubject', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

<template>
    <FlyoutPage
        id="supplier-view-page"
        :class="{ 'supplier-view-page--map': isMapMode }"
        class="supplier-view-page"
        show-header
        :headline="$t('pages.supplier.title')"
        @close="$root.routeBack()"
    >
        <div
            :class="{ 'supplier-view-page__map-wrapper--locked': !isMapMode }"
            class="supplier-view-page__map-wrapper"
            @click="showMapDetails"
        >
            <div id="map" class="transport-map__map" @click.stop />

            <Button v-if="isMapMode" orb class="transport-map__center-all-button" @click.stop="centerAllPoints">
                <CenterAllIcon width="24" />
            </Button>

            <LayerControls
                v-if="isMapMode"
                :type="mapType"
                :mode="detailMode"
                :map="map"
                @update-type="mapType = $event"
                @update-mode="detailMode = $event"
            />
        </div>

        <div :class="{ 'supplier-view-page__content--foled': isMapMode }" class="supplier-view-page__content">
            <LoadingSpinner v-if="!supplierInfo" block dark class="supplier-view-page__loading-indicator" />

            <SupplierTile
                v-if="supplierInfo && isDetailMode"
                :supplier-info="supplierInfo"
                :show-distance="!orderStatus || ['new', 'in_progress'].includes(orderStatus)"
                :construction-site="constructionSite"
                show-headline
                show-address
                show-factory-name
                business-hours-type="open"
                show-contact-orb
                show-phone-number
                show-email
                show-website
                show-description
                show-rating
                no-border
                class="supplier-view-page__detail-full"
            />
        </div>

        <template #footer>
            <div class="supplier-view-page__footer">
                <div
                    id="supplier-view-page__detail-view"
                    :class="{
                        'supplier-view-page__detail-view--active': isMapMode && supplierInfo,
                    }"
                    class="supplier-view-page__detail-view"
                >
                    <div v-if="supplierInfo" class="supplier-view-page__detail-view-content">
                        <SupplierTile
                            :supplier-info="supplierInfo"
                            :construction-site="constructionSite"
                            :more-price="supplierInfo.productPrice"
                            show-address
                            show-more
                            show-distance
                            show-contact-orb
                            show-overall-rating
                            business-hours-type="default"
                            @more-action="showDetails"
                        />
                    </div>
                </div>
            </div>
        </template>
    </FlyoutPage>
</template>

<script>
import { mapGetters } from 'vuex';
import { asyncDelay } from '@/services/utils';
import SupplierApi from '@/services/Api/Supplier';
import OrderApi from '@/services/Api/Order';
import GoogleMaps, { createMap } from '@/services/GoogleMaps';
import GoogleMapsMixin from '@/plugins/mixins/googleMaps';

import Button from '@/components/Button/Button';
import FlyoutPage from '@/components/Layout/FlyoutPage';
import LayerControls from '@/components/Map/LayerControls';
import LoadingSpinner from '@/components/LoadingSpinner';
import SupplierTile from '@/components/Supplier/SupplierTile';

import CenterAllIcon from '@/assets/icons/regular/zoom-out.svg';
import PinIconSmallPath from '@/assets/icons/pin--38px.svg?external';
import PinIconLargePath from '@/assets/icons/pin--55px.svg?external';

const ZOOM_INITIAL = 9;
const ZOOM_SUPPLIER_FOCUS = 15;

export default {
    name: 'SupplierViewPage',
    components: {
        Button,
        FlyoutPage,
        LayerControls,
        LoadingSpinner,
        SupplierTile,
        CenterAllIcon,
    },
    mixins: [GoogleMapsMixin],
    props: {
        factoryId: {
            type: [String, Number],
            required: true,
        },
        orderId: {
            type: [String, Number],
            required: true,
        },
        showMapInitially: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isMapMode: !!this.showMapInitially,
            isMapFullyOpen: !!this.showMapInitially,
            map: null,
            mapType: 'roadmap',
            detailMode: 'default',
            destinationMarker: null,
            suppliersSet: {},

            sortBy: 'distance',
            sortOptions: {
                distance: 'asc',
                duration: 'asc',
                productPrice: 'asc',
                rating: 'desc',
            },

            orderStatus: null,

            isDetailMode: true,
            supplierInfo: null,
            constructionSite: null,
        };
    },
    computed: {
        ...mapGetters('platform', ['initialMapLocation']),
    },
    created() {
        this.createMap();

        if (this.showMapInitially) {
            setTimeout(() => {
                this.showMapDetails();
            }, 600);
        }
    },
    mounted() {
        this.refresh();
    },
    methods: {
        async toggleMode(nextMode = null) {
            if (nextMode === 'detail') {
                this.isDetailMode = true;
            } else if (nextMode === null) {
                nextMode = !this.isMapMode ? 'map' : 'list';
            }

            if (!this.isMapMode) {
                document.querySelector('#supplier-view-page > .flyout__inner').scroll({
                    top: 0,
                    behavior: 'smooth',
                });
            }

            this.isMapFullyOpen = false;

            this.isMapMode = nextMode === 'map';

            if (this.supplierInfo) {
                this.updateSupplierPinById(this.supplierInfo.supplier.factoryId, this.isMapMode);
                this.centerLocationOnMap(this.supplierInfo.supplier.location, ZOOM_SUPPLIER_FOCUS);
            }

            if (this.isMapMode && this.supplierInfo) {
                this.toggleSupplierDetail(true);
            }
            this.isMapFullyOpen = true;

            if (nextMode === 'map') {
                await asyncDelay(300);
            }

            if (nextMode !== 'detail') {
                this.isDetailMode = false;
            }
        },

        showDetails() {
            this.updateSupplierPinById(this.supplierInfo.supplier.factoryId, true);
            this.toggleSupplierDetail(false);
            this.toggleMode('detail');
            this.centerLocationOnMap(this.supplierInfo.supplier.location, ZOOM_SUPPLIER_FOCUS);
        },

        showMapDetails() {
            this.updateSupplierPinById(this.supplierInfo.supplier.factoryId, true);
            this.toggleMode('map');
            this.centerLocationOnMap(this.supplierInfo.supplier.location, ZOOM_SUPPLIER_FOCUS);
        },

        async createMap() {
            if (this.map !== null) {
                return this.map;
            }

            const google = await GoogleMaps;

            this.map = createMap(google, 'map', {
                zoom: ZOOM_INITIAL,
                center: this.initialMapLocation,
                disableDefaultUI: true,
                mapTypeId: this.mapType,
            });

            return this.map;
        },

        async addSupplier(supplierInfo) {
            const { supplier } = supplierInfo;
            const google = await GoogleMaps;
            const map = await this.createMap();
            const { lat, lng } = supplier.location;

            // cant show transport on map without position
            if (!lat || !lng || this.suppliersSet[supplier.factoryId]) {
                return false;
            }

            /* @type {google.maps.Icon} */
            const icon = {
                url: PinIconSmallPath,
                scaledSize: new google.maps.Size(40, 40), // maps adds wierd 16px to the marker
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(20, 37.8), // 0.945 factor
            };

            const marker = new google.maps.Marker({
                position: { lat, lng },
                map: map,
                icon: icon,
                zIndex: supplier.factoryId + 3,
            });

            const supplierFocusHandler = async () => {
                const supplierSet = this.suppliersSet[supplier.factoryId];

                this.centerLocationOnMap(supplierSet.supplier.location, ZOOM_SUPPLIER_FOCUS);
            };

            marker.addListener('click', supplierFocusHandler);

            this.$set(this.suppliersSet, supplier.factoryId, {
                marker,
                supplier,
                supplierInfo,
            });

            return true;
        },

        async updateSupplierPinById(factoryId, focus = false) {
            const google = await GoogleMaps;
            const supplierSet = this.suppliersSet[factoryId];

            if (!supplierSet) {
                return;
            }

            if (focus) {
                supplierSet.marker.setIcon({
                    url: PinIconLargePath,
                    scaledSize: new google.maps.Size(56, 56), // maps adds wierd 16px to the marker
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(28, 52.92), // 0.945 factor
                });
            } else {
                supplierSet.marker.setIcon({
                    url: PinIconSmallPath,
                    scaledSize: new google.maps.Size(38, 38), // maps adds wierd 16px to the marker
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(19, 35.91), // 0.945 factor
                });
            }
        },

        async refresh() {
            this.supplierInfo = await SupplierApi.getOneById(this.factoryId);
            if (this.orderId) {
                const order = await OrderApi.getOneById(this.orderId);
                this.constructionSite = order.lineItemGroups[0].constructionSite;
                this.orderStatus = order.status;
                this.updateDestination(this.constructionSite.location);
            }

            this.addSupplier(this.supplierInfo);
            this.centerLocationOnMap(this.supplierInfo.supplier.location, ZOOM_SUPPLIER_FOCUS);
        },
    },
};
</script>

<style lang="scss">
.supplier-view-page {
    > .flyout__inner {
        background-color: $color-white;
    }
}

.supplier-view-page--map {
    > .flyout__inner {
        overflow: hidden;
    }
}

.supplier-view-page__map {
    background-color: #eee;
    min-height: 100px;
    flex: 1 1;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.supplier-view-page__map-wrapper {
    position: relative;
    background-color: orange;
    width: 100%;
    height: 200px;
    display: flex;

    transition:
        background-color 0.2s ease-out,
        height 0.3s ease-out;
    will-change: height, background-color;

    .supplier-view-page--map & {
        background-color: lightcoral;
        height: 100%;
        flex-grow: 1;
    }
}

.supplier-view-page__map-wrapper--locked {
    &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.supplier-view-page__item {
    position: relative;
    background-color: $color-white;
    z-index: 1;
}

.supplier-view-page__list-toggle {
    width: 100%;
    height: 50px;
    max-height: 0;
    transition: max-height 0.3s ease-in;
    will-change: max-height;
    opacity: 1;
    overflow: hidden;

    .button {
        height: 100%;
    }
}

.supplier-view-page__list-toggle--active {
    max-height: 50px;
}

.supplier-view-page__detail-actions {
    // position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    max-height: 0;
    transition: max-height 0.3s linear;
    overflow: hidden;
}

.supplier-view-page__detail-actions--active {
    max-height: 70px;
}

.supplier-view-page__content {
    width: 100%;
    max-height: 10000px;
    transition: max-height 0.3s ease-out;
    will-change: max-height;
    // overflow: hidden;
    min-height: 200px;

    padding: 0 15px;
    background: $color-white;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 200px;
        background: #f6f6f6; /* Old browsers */
        background: -moz-linear-gradient(top, #f6f6f6 0%, #feffff 100%);
        background: -webkit-linear-gradient(top, #f6f6f6 0%, #feffff 100%);
        background: linear-gradient(to bottom, #f6f6f6 0%, #feffff 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f6f6f6', endColorstr='#feffff', GradientType=0);
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 2;
        box-shadow: $boxShadow-top;
        top: 0;
        left: 15px;
        right: 15px;
        height: 200px;
    }

    .supplier-view-page--map & {
        min-height: 0;
    }
}

.supplier-view-page__list {
    position: relative;
    z-index: 3;
}

.supplier-view-page__content--foled {
    max-height: 0;
}

.supplier-view-page__detail-view {
    height: auto;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 1100;
    height: 0;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+25&0+0,1+25 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%);
}

.supplier-view-page__detail-view-content {
    margin: 15px;
    box-shadow: $boxShadow-top;
    background-color: $color-white;
}

.supplier-view-page__footer {
    position: relative;
}

.supplier-view-page__loading-indicator {
    position: relative;
    z-index: 1;
}

.supplier-view-page__detail-full {
    background-color: $color-white;
    position: relative;
    z-index: 3;
}

.supplier-view-page__sort-button--twist {
    svg {
        transform: rotate(180deg);
    }
}

.supplier-view-page__sort-trigger {
    > .button {
        padding: 0;
        min-width: 0;
    }
}

.supplier-view-page__detail-view--initially-hidden {
    height: 0;
}
</style>

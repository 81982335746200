<template>
    <div class="facilities-dropdown">
        <div class="facilities-dropdown__selected-element" :class="{ open: open }" @click="toggleFacilitiesDropdown">
            <div class="facilities-dropdown__option facilities-dropdown__selected-element-option">
                <Words v-if="labels" tiny ellipsis muted class="facilities-dropdown__option-label">{{
                    labels[selected.index]
                }}</Words>
                <Words ellipsis class="mr-[20px]">{{ selected.option }}</Words>
            </div>

            <div v-if="showChevron">
                <ArrowUpIcon class="select-box__arrow select-box__arrow--up" :class="{ 'icon--rotate-180': !open }" />
            </div>
        </div>
        <div class="facilities-dropdown__items z-10" :class="{ selectHide: !open }">
            <ul>
                <li v-for="(option, i) of options" :key="i" @click="selectOption(option, i, keys[i])">
                    <div class="facilities-dropdown__option facilities-dropdown__items-option">
                        <Words
                            v-if="labels"
                            tiny
                            ellipsis
                            muted
                            class="facilities-dropdown__option-label facilities-dropdown__items-option-label"
                            >{{ labels[i] }}</Words
                        >
                        <Words ellipsis spaced-right-tiny>{{ option }}</Words>
                        <div v-if="isOpen" :class="{ 'dot--green': isOpen[i], 'dot--red': !isOpen[i] }" />
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import ArrowUpIcon from '@/assets/icons/regular/arrow-stripeless--up.svg';
import Words from '@/components/Typography/Words';

export default {
    name: 'FacilitiesDropdown',
    components: {
        ArrowUpIcon,
        Words,
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
        initialSelectedKey: {
            type: [String, Number],
            default: null,
        },
        labels: {
            type: Array,
            required: false,
            default: null,
        },
        isOpen: {
            type: Array,
            required: false,
            default: null,
        },
        keys: {
            type: Array,
            required: true,
            default: null,
        },
        showChevron: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            selected: {
                option: this.options[0],
                index: 0,
            },
            open: false,
        };
    },
    mounted() {
        if (this.initialSelectedKey) {
            const index = this.keys.findIndex(key => key === this.initialSelectedKey);
            this.selected = {
                option: this.options[index],
                index,
            };
        }
        this.$emit('input', this.keys[this.selected.index], true);
    },
    methods: {
        toggleFacilitiesDropdown() {
            this.open = !this.open;
        },
        selectOption(selectedOption, optionIndex, selectedOptionKey) {
            this.selected = {
                option: selectedOption,
                index: optionIndex,
            };

            this.open = false;
            this.$emit('input', selectedOptionKey);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@import '@/scss/_mixins.scss';

.facilities-dropdown {
    $border-radius: 6px;
    position: relative;
    width: 100%;
    outline: none;
    cursor: pointer;

    ul {
        padding: 0;
        margin: 0;
    }

    li {
        list-style: none;
    }

    &__selected-element {
        &__option {
            border: 0;
        }
    }

    &__option {
        border-top: 1px solid $color-mediumGrey;
        white-space: nowrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        background-color: $color-white;
        color: $color-black;
        user-select: none;
        padding: 32px 16px 16px 12px;
    }

    &__option-label {
        position: absolute;
        top: 15px;
        left: 12px;
    }

    &__selected-element {
        &-option {
            border-radius: $border-radius;
        }
        &open > &-option {
            border-top-right-radius: $border-radius;
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__items {
        position: absolute;
        width: 100%;
        top: 65px;
        max-height: 350px;
        overflow: auto;
        border-bottom-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        &-option {
            padding-left: 20px;
            font-weight: bold;
            &:hover {
                background-color: $color-lightGrey;
            }
        }
        &-option-label {
            left: 20px;
            font-weight: normal;
        }
    }
}

.dot {
    &--red {
        @include create-dot(10, var(--color-red));
    }
    &--green {
        @include create-dot(10, $color-green);
    }
}

.selectHide {
    display: none;
}
</style>

import { getLd } from '@/services/LaunchDarkly';
import { useEffect, useState } from 'react';
export function useLaunchDarkly(featureFlag, defaultValue = false) {
    const ld = getLd();
    const [value, setValue] = useState(ld?.flags?.[featureFlag] ?? defaultValue);
    useEffect(() => {
        const handler = (changes) => {
            setValue(changes[featureFlag]?.current);
        };
        ld?.client?.on('change', handler);
        return () => ld?.client.off('change', handler);
    }, [ld, featureFlag]);
    return value;
}

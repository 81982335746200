import axios from 'axios';
import AbstractResource from '@/services/Api/AbstractResource';
import FilterResult from '@/services/Api/FilterResult';

import _isArray from 'lodash/isArray';
import _isPlainObject from 'lodash/isPlainObject';
import _isBoolean from 'lodash/isBoolean';
import _isString from 'lodash/isString';
import _includes from 'lodash/includes';
import _intersection from 'lodash/intersection';
import _toString from 'lodash/toString';
import _set from 'lodash/set';

export const clearNullableValues = obj => {
    return JSON.parse(
        JSON.stringify(obj, (key, value) => {
            return value === null ? undefined : value;
        })
    );
};

export const processParams = (rawParams, forcedParams = null) => {
    const mergeParams = {};

    const cleanupProps = [];

    // prepare params, by remapping nested arguments
    Object.keys(rawParams)
        .sort()
        .forEach(key => {
            if (/\./.test(key)) {
                cleanupProps.push(key);
                const value = rawParams[key];
                _set(mergeParams, key, value);
            }
        });

    const params = {
        ...rawParams,
        ...mergeParams,
    };

    // remove object.nested.key'ish values
    cleanupProps.forEach(key => {
        delete params[key];
    });

    const data = { ...params };

    Object.keys(params).forEach(key => {
        let value = params[key];

        // transform bool values to 1 and 0
        if (_isBoolean(value)) {
            data[key] = params[key] ? 1 : 0;
        }

        // do not send empty strings
        if (_isString(value) && value === '') {
            delete data[key];
        }

        // do not send empty values
        if (_isArray(value)) {
            value = value.filter(v => v !== null);
            if (value.length === 0) {
                delete data[key];
            } else {
                data[key] = value;
            }
        }

        // LocationField -> GoogleApi -> {place: {google place object}, radius: 25}
        // is location object
        if (_isPlainObject(value) && Object.prototype.hasOwnProperty.call(value, 'place')) {
            if (value.place && value.place.location) {
                const location = value.place.location;
                data[key] = `${location.lat},${location.lng}`;

                // radius is optional
                const radius = parseInt(value.radius);
                if (radius > 0) {
                    data[key] += `,${radius}`;
                }
            } else {
                delete data[key];
            }

            // is location object
        } else if (
            _isPlainObject(value) &&
            (Object.prototype.hasOwnProperty.call(value, 'lat') ||
                Object.prototype.hasOwnProperty.call(value, 'radius'))
        ) {
            if (value.lat && value.lng) {
                data[key] = `${value.lat},${value.lng}`;

                // radius is optional
                const radius = parseInt(value.radius);
                if (radius > 0) {
                    data[key] += `,${radius}`;
                }
            } else {
                delete data[key];
            }
        }
    });

    // inteersect params add params
    if (forcedParams !== null) {
        Object.keys(forcedParams).forEach(key => {
            const forcedValue = forcedParams[key];
            if (data[key] === undefined) {
                data[key] = forcedValue;
            } else {
                //eg. status ->  ['loaded', 'ready_to_deliver']
                if (_isArray(forcedValue) && _isArray(data[key])) {
                    let intersectedValue = _intersection(data[key], forcedValue);
                    if (intersectedValue.length === 0) {
                        intersectedValue = forcedValue;
                    }
                    data[key] = intersectedValue;
                } else {
                    // fallback
                    data[key] = forcedValue;
                }
            }
        });
    }

    return data;
};

/**
 * AbstractResource
 */
export default class AbstractFilterableResource extends AbstractResource {
    /**
     * @param {string|null} resourcePath
     * @param {Object} messages
     */
    constructor(resourcePath = null, messages = {}) {
        super(resourcePath, messages);

        this.hasFilterSupport = true;

        // this will be populated with data from the first request
        this.supportedFilters = {};
        this.supportedSorts = [];
    }

    createCancelTokenSource() {
        return axios.CancelToken.source();
    }

    /**
     * Get all resource objects
     * @param {int} id
     */
    getAll() {
        return super.getAll().then(res => res.items);
    }

    filter(
        params = {},
        facetFilterKey = null,
        facetFilterValues = [],
        cancelSource = null,
        forcedParams = {},
        silence = false
    ) {
        return this.filterEndpoint(
            this.resourcePath,
            params,
            facetFilterKey,
            facetFilterValues,
            cancelSource,
            forcedParams,
            silence
        );
    }

    /**
     * @param {object} params
     */
    filterEndpoint(
        endpoint = null,
        params = {},
        facetFilterKey = null,
        facetFilterValues = [],
        cancelSource = null,
        forcedParams = {},
        silence = false
    ) {
        const resourcePath = endpoint === null ? this.resourcePath : endpoint;
        const requestedForcedParams = processParams(forcedParams);
        const requestedParams = processParams(params, requestedForcedParams);

        // facetation
        if (facetFilterKey !== null) {
            if (facetFilterValues.length === 0) {
                throw new Error('Facet values are required for the facetation');
            }

            const promises = [];
            const filterRestriction = requestedParams[facetFilterKey];

            facetFilterValues.forEach(value => {
                // check if this facet value is excluded by the filter
                if (filterRestriction) {
                    if (_isArray(filterRestriction) && _isArray(value)) {
                        // -> (fac(et fi)lter)
                        value = _intersection(filterRestriction.map(_toString), value.map(_toString));
                        if (value.length === 0) {
                            return;
                        }
                    } else if (
                        _isArray(filterRestriction) &&
                        filterRestriction.length > 0 &&
                        !_includes(filterRestriction, value)
                    ) {
                        // is not included in the array
                        return;
                    } else if (filterRestriction != value) {
                        // soft check since "5" can match 5
                        // is not the exact value
                        return;
                    }
                }

                promises.push(
                    this.filterEndpoint(
                        resourcePath,
                        {
                            ...params,
                            [facetFilterKey]: value,
                        },
                        null,
                        [],
                        cancelSource,
                        forcedParams,
                        silence
                    )
                );
            });

            // Filter excluded facetation
            if (promises.length === 0) {
                throw new Error('Filter configuration excluded facetation');
            }

            return Promise.all(promises).then(results => {
                let itemsCount = 0;

                // let every response know taht it's a facet response
                results.map(result => {
                    itemsCount += result.count;
                    result.setFacetFilter(facetFilterKey);
                    result.setFilterEndpoint(this.filter.bind(this));
                });

                const firstResult = results[0];
                const facetResponseData = {
                    ...firstResult.responseData,
                    filter: {
                        ...firstResult.responseData.filter,
                        [facetFilterKey]: filterRestriction,
                    },
                    items: results,
                    count: itemsCount,
                };

                return new FilterResult(facetResponseData, requestedParams, requestedForcedParams, true);
            });
        }

        return new Promise((resolve, reject) => {
            const args = { params: requestedParams };

            // cancel previous requests
            if (cancelSource) {
                args.cancelToken = cancelSource.token;
            }

            this.apiService
                .get(resourcePath, args, { trackRequest: !silence })
                .then(res => {
                    const filterResult = new FilterResult(res.data, requestedParams, requestedForcedParams);
                    filterResult.setFilterEndpoint(this.filter.bind(this));
                    this.supportedFilters = filterResult.supportedFilters;
                    this.supportedSorts = filterResult.supportedSorts;
                    resolve(filterResult);
                })
                .catch(err => {
                    reject(this.handleError(err));
                });
        });
    }
}

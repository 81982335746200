<template>
    <FlyoutPage>
        <div v-if="!isInitiallyPending">
            <LanguageMismatchModal v-if="injectLanguageMismatchModal" :user-locale="locale" />

            <BaseButton class="confirmation-screen__close" @click="declineTerms">
                <CloseIcon width="12" height="12" />
            </BaseButton>
            <div v-if="!isTermsOfUseAccepted" class="confirmation-gate">
                <div v-if="$can('confirmUserTerms')">
                    <div class="confirmation-gate__inner">
                        <PrivacyPolicyIcon class="confirmation-gate__teaser-icon" />
                        <Headline :level="2">{{ $t('pages.confirmationGate.termsOfUse.headline') }}</Headline>
                        <Words>
                            <i18n :path="getTermsOfUsage()" tag="span">
                                <a
                                    place="privacyPolicyLink"
                                    class="terms-hint__link"
                                    :href="linkLegalDataProtectionPolicyGlobal"
                                    target="_blank"
                                >
                                    {{ $t('pages.confirmationGate.termsOfUse.privacyPolicyLink') }}
                                </a>
                                <a
                                    place="termsOfUseLink"
                                    class="terms-hint__link"
                                    :href="linkLegalTermsOfUse"
                                    target="_blank"
                                >
                                    {{ $t('pages.confirmationGate.termsOfUse.termsOfUseLink') }}
                                </a>
                                <a place="aoesp" class="terms-hint__link" :href="linkLegalAoesp" target="_blank">
                                    {{ $t('pages.confirmationGate.termsOfUse.aoesp') }}
                                </a>
                            </i18n>
                        </Words>
                    </div>
                </div>
                <div v-else>
                    <PrivacyPolicyIcon class="confirmation-gate__teaser-icon" />
                    <Headline :level="2">{{ $t('pages.confirmationGate.termsOfUse.headline') }}</Headline>
                    <Words block>
                        {{ $t(`pages.confirmationGate.termsOfUse.platformAdminNote`) }}
                    </Words>
                </div>
            </div>
            <div v-else-if="!isTermsOfServiceAccepted" class="confirmation-gate">
                <div v-if="$can('confirmOrganizationTerms')">
                    <div class="confirmation-gate__inner">
                        <TermsOfServiceIcon class="confirmation-gate__teaser-icon" />
                        <Headline :level="2">{{ $t('pages.confirmationGate.termsOfService.headline') }}</Headline>
                        <Words block spaced>
                            {{ $t('pages.confirmationGate.termsOfService.messageIntro') }}
                        </Words>
                        <Words block spaced>
                            {{ $t('pages.confirmationGate.termsOfService.text') }}
                        </Words>

                        <Words v-if="isClient(organizationTypes)" spaced block>
                            <a class="terms-hint__link" :href="linkLegalTermsAndConditionsCustomer" target="_blank">
                                {{ $t(`pages.confirmationGate.termsOfService.customer`) }}
                            </a>
                        </Words>

                        <Words v-if="isSupplier(organizationTypes)" spaced block>
                            <a class="terms-hint__link" :href="linkLegalTermsAndConditionsSeller" target="_blank">
                                {{ $t(`pages.confirmationGate.termsOfService.seller`) }}
                            </a>
                        </Words>
                        <Words v-if="isCarrier(organizationTypes)" block spaced>
                            <a class="terms-hint__link" :href="linkLegalTermsAndConditionsCarrier" target="_blank">
                                {{ $t(`pages.confirmationGate.termsOfService.carrier`) }}
                            </a>
                        </Words>
                    </div>
                    <Hint>
                        <Words red bold>{{ $t('pages.confirmationGate.termsOfService.hint.label') }}:</Words>
                        {{
                            $t('pages.confirmationGate.termsOfService.hint.message', {
                                termsOfServiceLabel: $t('pages.confirmationGate.termsOfService.termsLabel'),
                            })
                        }}
                    </Hint>
                </div>
                <div v-else class="confirmation-gate__inner">
                    <div class="confirmation-gate__inner">
                        <TermsOfServiceIcon class="confirmation-gate__teaser-icon" />
                        <Headline :level="2">
                            {{ $t('pages.confirmationGate.termsOfService.userNoteHeadline') }}
                        </Headline>
                        <Words v-if="isImpersonated" block>
                            {{ $t(`pages.confirmationGate.termsOfService.platformAdminNote`) }}
                        </Words>
                        <Words v-else block>
                            {{ $t(`pages.confirmationGate.termsOfService.userNote`) }}
                        </Words>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <ButtonGroup v-if="$can('confirmUserTerms') && !isInitiallyPending">
                <BaseButton
                    :disabled="isPending()"
                    :is-loading="isPending('acceptTermsOfUse')"
                    primary
                    @click="acceptTermsOfUse"
                    >{{ $t('pages.confirmationGate.termsOfUse.acceptLabel') }}</BaseButton
                >
            </ButtonGroup>
            <ButtonGroup v-else-if="$can('confirmOrganizationTerms') && !isInitiallyPending">
                <BaseButton
                    :disabled="isButtonDisabled || isPending()"
                    :is-loading="isPending('acceptTermsOfService')"
                    primary
                    @click="acceptTermsOfService"
                >
                    {{ $t('pages.confirmationGate.termsOfService.acceptLabel') }}
                </BaseButton>
            </ButtonGroup>
        </template>
    </FlyoutPage>
</template>

<script>
import { defineComponent } from 'vue';
import pageMixin from '@/plugins/mixins/pageMixin';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import OrganizationApi from '@/services/Api/Organization';
import UserV2Api from '@/services/Api/UserV2';
import Toaster from '@/services/Toaster';
import { isCarrier, isClient, isSupplier } from '@/services/utils/organization';
import { AvailableMarkets } from '@/services/MarketPermission/constants';
import { mapActions, mapGetters } from 'vuex';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import FlyoutPage from '@/components/Layout/FlyoutPage';
import Headline from '@/components/Typography/Headline';
import Hint from '@/components/Typography/Hint';
import Words from '@/components/Typography/Words';

import PrivacyPolicyIcon from '@/assets/icons/confirmation/privacy-policy.svg';
import TermsOfServiceIcon from '@/assets/icons/confirmation/terms-of-service.svg';
import CloseIcon from '@/assets/icons/regular/close.svg';

import LanguageMismatchModal from '@/pages/Login/LanguageMismatchModal';

const BUTTON_CHANGED_TIMEOUT_MS = 2000;

export default defineComponent({
    name: 'ConfirmationGatePage',
    components: {
        Headline,
        Words,
        Hint,
        BaseButton,
        ButtonGroup,
        FlyoutPage,
        CloseIcon,
        PrivacyPolicyIcon,
        TermsOfServiceIcon,
        LanguageMismatchModal,
    },
    mixins: [pageMixin, statefulMixin],
    data() {
        return {
            injectLanguageMismatchModal: false,
            isButtonDisabled: false,
        };
    },
    computed: {
        ...mapGetters('user', [
            'type',
            'organizationId',
            'isImpersonated',
            'isTermsOfUseAccepted',
            'isTermsOfServiceAccepted',
            'isInfoPending',
            'info',
            'locale',
            'organizationTypes',
        ]),

        ...mapGetters('platform', [
            'linkLegalDataProtectionPolicyGlobal',
            'linkLegalTermsOfUse',
            'linkLegalTermsAndConditionsCarrier',
            'linkLegalTermsAndConditionsCustomer',
            'linkLegalTermsAndConditionsSeller',
            'linkLegalAoesp',
        ]),

        isInitiallyPending() {
            return !this.info || this.isInfoPending;
        },
    },
    watch: {
        isTermsOfUseAccepted() {
            this.checkTermsStatus();
        },
        isTermsOfServiceAccepted() {
            this.checkTermsStatus();
        },
    },
    mounted() {
        this.checkTermsStatus();
    },
    methods: {
        isClient,
        isSupplier,
        isCarrier,

        ...mapActions('user', ['logout', 'updateUserInfo']),
        declineTerms() {
            this.logout().then(shouldRedirect => {
                if (shouldRedirect) {
                    this.$router
                        .push({
                            name: 'login',
                        })
                        .catch(() => {});
                }
            });
        },
        async acceptTermsOfUse() {
            await this.stateful('acceptTermsOfUse', async () => {
                try {
                    await UserV2Api.acceptTerms();
                    await this.updateUserInfo();
                    this.checkTermsStatus();
                    this.isButtonDisabled = true;
                    setTimeout(() => {
                        this.isButtonDisabled = false;
                    }, BUTTON_CHANGED_TIMEOUT_MS);
                } catch (err) {
                    this.$logger().error(err);
                    Toaster.error(err.message);
                }
            });
        },
        async acceptTermsOfService() {
            await this.stateful('acceptTermsOfService', async () => {
                try {
                    await OrganizationApi.acceptTerms();
                    await this.updateUserInfo();
                    this.checkTermsStatus();
                } catch (err) {
                    this.$logger().error(err);
                    Toaster.error(err.message);
                }
            });
        },
        checkTermsStatus() {
            this.injectLanguageMismatchModal = sessionStorage.getItem('loginLocale') !== null;
            if (this.isTermsOfUseAccepted && this.isTermsOfServiceAccepted) {
                this.$router.replace({ path: this.$route.query.redirect || '/' }).catch(() => {});
            }
        },
        isAustrianMarket() {
            return this.info?.organization?.market?.code === AvailableMarkets.AT;
        },
        getTermsOfUsage() {
            return this.isAustrianMarket()
                ? 'pages.confirmationGate.termsOfUse.extendedMessage'
                : 'pages.confirmationGate.termsOfUse.message';
        },
    },
});
</script>

<style lang="scss">
.confirmation-gate {
    margin: auto;
    max-width: 700px;
}

.confirmation-gate__inner {
    padding: 20px;
}

.confirmation-gate__teaser-icon {
    display: block;
    width: 80%;
    max-width: 250px;
    max-height: 200px;
    height: 80%;
    margin: 30px auto;
}

.terms-hint__link,
.terms-hint__link:visited,
.terms-hint__link:hover,
.terms-hint__link:focus,
.terms-hint__link:active {
    color: $color-red;
    text-decoration: underline;
    cursor: pointer;
}
</style>

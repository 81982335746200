export default class SimpleDimensionView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     */
    static create(data) {
        const view = new SimpleDimensionView();

        view.height = data?.height;
        view.length = data?.length;
        view.width = data?.width;

        return view;
    }

    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     */
    static createFromCM(data) {
        const view = new SimpleDimensionView();

        view.height = data?.height ? data?.height / 100 : null;
        view.length = data?.length ? data?.length / 100 : null;
        view.width = data?.width ? data?.width / 100 : null;

        return view;
    }

    unfold() {
        return {
            height: this.height.unfold(),
            length: this.length.unfold(),
            width: this.width.unfold(),
        };
    }

    unfoldToCM() {
        return {
            height: this.height ? this.height * 100 : null,
            length: this.length ? this.length * 100 : null,
            width: this.width ? this.width * 100 : null,
        };
    }

    constructor() {
        this.height = null;
        this.length = null;
        this.width = null;
    }
}

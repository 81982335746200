<template>
    <Flyout
        :active="isPlanningAssistantActive"
        no-header
        size="small"
        appear-from="bottom"
        @closed="cleanup()"
        @opening="init()"
    >
        <HeaderBar slot="header">
            <div slot="headline">
                {{ $t('components.planningAssistant.title') }}
            </div>
            <HeaderBarItem slot="right" button @click="discardPlanningAssistance()">
                <CloseIcon width="18" height="18" />
            </HeaderBarItem>
        </HeaderBar>

        <template v-if="isPlanningAssistantActive">
            <Tile background-transparent no-border>
                <Words>
                    {{ $t(`components.planningAssistant.description.${translationTrailingPath}`) }}
                </Words>
            </Tile>

            <Tile v-if="timeConstraint" background-transparent no-border>
                <Headline :level="3" class="planning-assistant__headline">
                    {{ $t(`components.planningAssistant.headlineTimeConstraint.${translationTrailingPath}`) }}
                </Headline>
                <TimeRangePicker v-model="timeConstraint" :min-max-range="absoluteTimeRange" />
            </Tile>

            <Tile background-transparent no-border>
                <Headline :level="3" class="planning-assistant__headline">
                    {{ $t(`components.planningAssistant.headlineStartDate.${translationTrailingPath}`) }}
                </Headline>

                <DatetimePicker v-model="startDate" :range-config="rangeConfig" :minute-steps="15">
                    <div slot-scope="{ isInvalid }">
                        <TileTrigger size="big" no-arrow>
                            <Words v-if="!startDate" block bold :red="isInvalid">
                                {{ $t('components.planningAssistant.dateSelectLabel') }}
                            </Words>
                            <Words v-else block bold :red="isInvalid">
                                {{
                                    $t(`components.planningAssistant.dateFormat.${dateType(startDate)}`, {
                                        weekday: $t(`weekdays.${getDay(startDate)}`),
                                        date: $d(startDate, 'short'),
                                        time: $d(startDate, 'time'),
                                    })
                                }}
                            </Words>
                        </TileTrigger>
                    </div>
                </DatetimePicker>
            </Tile>
            <Tile background-transparent no-border>
                <Headline :level="3">
                    {{ $t(`components.planningAssistant.headlineInterval.${translationTrailingPath}`) }}
                </Headline>

                <IntervalFieldset
                    v-model="interval"
                    :limits="intervalLimits"
                    class="planning-assistant__interval-input"
                />

                <ErrorMessage
                    v-if="!!outOfBoundsError"
                    class="planning-assistant__interval-error"
                    :message="
                        $t('components.planningAssistant.notice.maximumDate', {
                            date: $d(maxDate, 'short'),
                            time: $d(maxDate, 'time'),
                        })
                    "
                />

                <Words
                    v-if="!outOfBoundsError && lastTransportDate"
                    small
                    block
                    muted
                    class="planning-assistant__info-last-transport"
                >
                    <InfoIcon class="planning-assistant__info-last-transport-icon" />
                    <span
                        v-if="interval === 0"
                        v-html="
                            $t(`components.planningAssistant.notice.allTransports.${translationTrailingPath}`, {
                                date: $d(lastTransportDate, 'shortest'),
                                time: $d(lastTransportDate, 'time'),
                            })
                        "
                    />
                    <span
                        v-if="interval > 0"
                        v-html="
                            $t(`components.planningAssistant.notice.lastTransport.${translationTrailingPath}`, {
                                date: $d(lastTransportDate, 'shortest'),
                                time: $d(lastTransportDate, 'time'),
                            })
                        "
                    />
                </Words>
            </Tile>

            <SlideUp slot="bottom" :active="hasValidData">
                <ButtonGroup>
                    <Button :disabled="!hasValidData" primary type="button" @click="handleSubmission">
                        {{ $t(`components.planningAssistant.submitButton.${translationTrailingPath}`) }}
                        <ArrowRightIcon slot="right" class="icon--inline" />
                    </Button>
                </ButtonGroup>
            </SlideUp>
        </template>
    </Flyout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import _cloneDeep from 'lodash/cloneDeep';
import { isToday, isTomorrow, getDay } from 'date-fns';
import { ensurePHPTimestamp, colonTimeDifference, ensureTimeRange } from '@/services/utils/date';
import { inMinutes } from '@/services/utils/time';
import PlanningAssistant from '@/services/PlanningAssistant/PlanningAssistant';
import OutOfBoundsError from '@/services/PlanningAssistant/OutOfBoundsError';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import DatetimePicker from '@/components/Form/DatetimePicker';
import ErrorMessage from '@/components/Form/ErrorMessage';
import Flyout from '@/components/Layout/Flyout';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Headline from '@/components/Typography/Headline';
import IntervalFieldset from '@/components/Form/IntervalFieldset';
import TimeRangePicker from '@/pages/Checkout/components/TimeRangePicker';
import SlideUp from '@/components/Animation/SlideUp';
import Tile from '@/components/Layout/Tile';
import TileTrigger from '@/components/Button/TileTrigger';
import Words from '@/components/Typography/Words';

import ArrowRightIcon from '@/assets/icons/micro/arrow.svg';
import CloseIcon from '@/assets/icons/regular/close.svg';
import InfoIcon from '@/assets/icons/regular/info.svg';
import { trackCheckoutEvent } from '../trackCheckoutEvent';

export default {
    name: 'PlanningAssistantFlup',
    components: {
        Button,
        ButtonGroup,
        DatetimePicker,
        ErrorMessage,
        Flyout,
        HeaderBar,
        HeaderBarItem,
        Headline,
        IntervalFieldset,
        TimeRangePicker,
        SlideUp,
        Tile,
        TileTrigger,
        Words,

        ArrowRightIcon,
        CloseIcon,
        InfoIcon,
    },
    data() {
        return {
            planningAssistant: null,
            startDate: null,
            interval: null,
            transportDates: [],
            outOfBoundsError: null,
            timeConstraint: ensureTimeRange(null),
        };
    },
    computed: {
        ...mapState('basket', [
            'isPlanningAssistantActive',
            'planningMethod',
            'deliveryMethod',
            'transports',
            'type',
            'shipmentPlanningConfig',
        ]),
        ...mapState('basket', {
            storedTimeConstraint: 'timeConstraint',
        }),
        ...mapGetters('basket', ['baseType']),

        isShipment() {
            return this.baseType === 'shipment';
        },

        translationTrailingPath() {
            let path = this.baseType;

            if (this.isShipment) {
                path = `${path}.${this.planningMethod}`;
            }

            return path;
        },

        absoluteTimeRange() {
            if (this.deliveryMethod) {
                return ensureTimeRange(this.deliveryMethod.additionalInformation.absoluteWindow);
            }

            return ensureTimeRange(this.shipmentPlanningConfig.additionalInformation.absoluteWindow);
        },

        rangeConfig() {
            return this.planningAssistant?.getAvailableDays() ?? [];
        },

        lastTransportDate() {
            return this.transportDates ? this.transportDates[this.transportDates.length - 1] : null;
        },

        maxDate() {
            return this.outOfBoundsError?.lastEntry ?? null;
        },

        intervalLimits() {
            if (this.timeConstraint) {
                const diffInMilliseconds = colonTimeDifference(
                    this.timeConstraint.startTime,
                    this.timeConstraint.endTime
                );

                return {
                    hours: inMinutes(diffInMilliseconds),
                };
            }

            return null;
        },

        hasValidData() {
            return this.transportDates.length > 0 && this.outOfBoundsError === null;
        },
    },
    watch: {
        startDate() {
            this.calculateDates();
        },
        interval() {
            this.calculateDates();
        },
        timeConstraint(next) {
            this.startDate = null;
            this.transportDates = [];
            this.outOfBoundsError = null;

            if (this.planningAssistant) {
                this.planningAssistant.setTimeConstraint(next);
            }

            this.$nextTick(() => {
                this.interval = null;
            });
        },
    },
    mounted() {
        // on page reload, the flyout does not trigger the animated opening event
        if (this.isPlanningAssistantActive) {
            this.init();
        }
    },
    methods: {
        ...mapActions('basket', ['discardPlanningAssistance']),
        getDay,

        /**
         * Initialize
         */
        init() {
            this.prepareTimeConstraint();
            this.preparePlanningAssistant();
        },

        /**
         * Set the time constraint
         */
        prepareTimeConstraint() {
            const additionalInformation = this.deliveryMethod
                ? this.deliveryMethod.additionalInformation
                : this.shipmentPlanningConfig.additionalInformation;

            // Initial
            let timeConstraint = ensureTimeRange(additionalInformation.defaultWindow);

            // set constraint from store
            if (this.storedTimeConstraint) {
                timeConstraint = ensureTimeRange(this.storedTimeConstraint);
            }

            this.timeConstraint = timeConstraint;
        },

        preparePlanningAssistant() {
            const rawRangeConfig = this.deliveryMethod
                ? this.deliveryMethod.deliveryDays
                : this.shipmentPlanningConfig.days;

            this.planningAssistant = new PlanningAssistant(rawRangeConfig, this.timeConstraint);
        },

        calculateDates() {
            this.outOfBoundsError = null;

            if (this.interval === null || this.startDate === null) {
                return;
            }

            try {
                const count = this.transports.transports.length;
                this.transportDates = this.planningAssistant.calculateDates(this.interval, this.startDate, count);
            } catch (err) {
                if (err instanceof OutOfBoundsError) {
                    this.outOfBoundsError = err;
                    return;
                }
                this.$logger().error(err);
            }
        },

        dateType(date) {
            if (isToday(date)) return 'today';
            if (isTomorrow(date)) return 'tomorrow';
            return 'default';
        },

        cleanup() {
            this.discardPlanningAssistance();
            this.planningAssistant = null;
            this.startDate = null;
            this.interval = null;
            this.transportDates = [];
            this.outOfBoundsError = false;
            this.timeConstraint = ensureTimeRange(null);
        },

        handleSubmission() {
            if (!this.transportDates.length || this.outOfBoundsError) return;

            trackCheckoutEvent('planningAssistent', 'submit', {
                interval: this.interval,
            });

            const transports = _cloneDeep(this.transports);

            transports.transports = transports.transports.map((transport, i) => {
                transport.date = ensurePHPTimestamp(this.transportDates[i]);
                transport.error = null;
                return transport;
            });

            this.$store.commit('basket/setTransports', transports);
            this.$store.commit('basket/setTimeConstraint', this.timeConstraint);
            this.$store.commit('basket/setPlanningInterval', this.interval);
            this.$emit('submit', transports);

            this.cleanup();
        },
    },
};
</script>

<style lang="scss">
.planning-assistant__close-button {
    text-align: right;
}

.planning-assistant__headline {
    margin-top: 0;
}

.planning-assistant__interval-input {
    margin-bottom: 30px;
}

.planning-assistant__info-last-transport {
    display: flex;
    align-items: center;
}

.planning-assistant__info-last-transport-icon {
    margin-right: 10px;
    width: 16px;
    height: 16px;
}
</style>

<template>
    <div
        id="app"
        :key="forcedRerenderKey"
        class="isolate"
        :class="{ 'js--impersonated': isImpersonated, 'user-select--disabled': isApp }"
        @click="closeResourceCenter"
    >
        <NotificationCenter />
        <router-view />
        <UserNavigation v-if="isLoggedIn && info" :user-navigation-flyout-active="userNavigationFlyoutActive" />
        <MyAccount v-if="isLoggedIn && info" :my-account-flyout-active="myAccountFlyoutActive" />
        <DeleteAccount v-if="isLoggedIn && info" :my-account-flyout-active="deleteAccountFlyoutActive" />
        <NotificationManagement v-if="isLoggedIn && info" :active="notificationManagementFlyoutActive" />
        <ConstructionSiteSubscriptions v-if="isLoggedIn && info" :active="constructionSiteSubscriptionsFlyoutActive" />
        <LegalInformation
            :terms-of-service-flyout-active="termsOfServiceFlyoutActive"
            :terms-of-use-flyout-active="termsOfUseFlyoutActive"
            :licenses-flyout-active="licensesFlyoutActive"
            :imprint-flyout-active="imprintFlyoutActive"
            :cookies-flyout-active="cookiesFlyoutActive"
            :privacy-policy-flyout-active="privacyPolicyFlyoutActive"
        />
        <Flyout
            :active="notificationsFlyoutActive"
            no-header
            size="small"
            @closed="$eventHub.$emit('page.notifications', false)"
        >
            <NotificationsPage />
        </Flyout>
        <UserLinkList :active="userLinkListFlyoutActive" />
        <UserLinkForm />
        <PlatformContact :platform-contact-flyout-active="platformContactFlyoutActive" />
        <TelephoneOrderContact :telephone-order-contact-flyout-active="telephoneOrderContactFlyoutActive" />
        <ImageUploadEditor />
        <AvatarUploadEditor />
        <Toolbar v-if="$root.debugMenuEnabled" />
        <Overlay />
        <ImpersonationBar />

        <OrderActions />
        <TransportActions />
        <OrganizationActions />
        <ProjectPositionActions />
        <TrackingInfoModal />
        <VehicleActions />
        <CommandDialog v-if="isLoggedIn && isCommandPaletteEnabled" />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';

import AvatarUploadEditor from '@/components/Form/AvatarUploadEditor';
import ConstructionSiteSubscriptions from '@/components/Notification/ConstructionSiteSubscriptions';
import Flyout from '@/components/Layout/Flyout';
import ImageUploadEditor from '@/components/Form/ImageUploadEditor';
import ImpersonationBar from '@/pages/components/ImpersonationBar';
import LegalInformation from '@/components/LegalInformation/LegalInformation';
import MyAccount from '@/components/MyAccount';
import DeleteAccount from '@/components/DeleteAccount';
import NotificationCenter from '@/components/Notification/NotificationCenter';
import NotificationManagement from '@/components/Notification/NotificationManagement';
import NotificationsPage from '@/pages/Notifications/NotificationsPage';
import Overlay from '@/components/Overlay/Overlay';
import PlatformContact from '@/components/PlatformContact';
import TelephoneOrderContact from '@/components/TelephoneOrderContact';
import Toolbar from '@/components/Debug/Toolbar/Toolbar';
import UserLinkForm from '@/pages/components/UserLinkForm';
import UserLinkList from '@/pages/components/UserLinkList';
import UserNavigation from '@/components/UserNavigation';

import OrderActions from '@/pages/Order/Actions/OrderActions';
import OrganizationActions from '@/pages/Organization/Actions/OrganizationActions';
import ProjectPositionActions from '@/pages/Project/Actions/ProjectPositionActions';
import TransportActions from '@/pages/Transport/Actions/TransportActions';
import TrackingInfoModal from '@/pages/Legal/TrackingInfoModal';
import VehicleActions from '@/pages/Vehicle/Actions/VehicleActions';
import CommandDialog from '@/components/Command/CommandDialog';

import userflow from 'userflow.js';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { useLd } from '@/services/LaunchDarkly';

export default defineComponent({
    name: 'App',
    components: {
        AvatarUploadEditor,
        ConstructionSiteSubscriptions,
        Flyout,
        ImageUploadEditor,
        ImpersonationBar,
        LegalInformation,
        MyAccount,
        DeleteAccount,
        NotificationCenter,
        NotificationManagement,
        NotificationsPage,
        Overlay,
        PlatformContact,
        TelephoneOrderContact,
        Toolbar,
        UserLinkForm,
        UserLinkList,
        UserNavigation,

        OrderActions,
        OrganizationActions,
        ProjectPositionActions,
        TransportActions,
        TrackingInfoModal,
        VehicleActions,
        CommandDialog: asVueComponent(CommandDialog),
    },
    mixins: [eventHubMixin],
    setup() {
        return {
            isCommandPaletteEnabled: useLd('use-k-command-palette'),
        };
    },
    data() {
        return {
            cookiesFlyoutActive: false,
            imprintFlyoutActive: false,
            myAccountFlyoutActive: false,
            platformContactFlyoutActive: false,
            telephoneOrderContactFlyoutActive: false,
            privacyPolicyFlyoutActive: false,
            termsOfServiceFlyoutActive: false,
            deleteAccountFlyoutActive: false,
            termsOfUseFlyoutActive: false,
            licensesFlyoutActive: false,
            userNavigationFlyoutActive: false,
            notificationManagementFlyoutActive: false,
            constructionSiteSubscriptionsFlyoutActive: false,
            userLinkListFlyoutActive: false,
            notificationsFlyoutActive: false,
            isApp: this.$root.isApp,
        };
    },
    computed: {
        ...mapGetters('user', ['isLoggedIn', 'info', 'isImpersonated', 'locale']),
        ...mapGetters('platform', ['forcedRerenderKey']),
    },
    created() {
        this.$store.dispatch('user/updateTokenData');

        this.subscribe('cookiesToggled', opened => {
            this.cookiesFlyoutActive = opened;
        });

        this.subscribe('page.imprint', opened => {
            this.imprintFlyoutActive = opened;
        });

        this.subscribe('myAccountToggled', opened => {
            this.myAccountFlyoutActive = opened;
        });

        this.subscribe('page.deleteAccount', opened => {
            this.deleteAccountFlyoutActive = opened;
        });

        this.subscribe('page.notificationManagement', opened => {
            this.notificationManagementFlyoutActive = opened;
        });

        this.subscribe('page.constructionSiteSubscriptions', opened => {
            this.constructionSiteSubscriptionsFlyoutActive = opened;
        });

        this.subscribe('page.platformContact', opened => {
            this.platformContactFlyoutActive = opened;
        });

        this.subscribe('page.telephoneOrderContact', opened => {
            this.telephoneOrderContactFlyoutActive = opened;
        });

        this.subscribe('page.privacyPolicy', opened => {
            this.privacyPolicyFlyoutActive = opened;
        });

        this.subscribe('page.termsOfService', opened => {
            this.termsOfServiceFlyoutActive = opened;
        });

        this.subscribe('page.termsOfUse', opened => {
            this.termsOfUseFlyoutActive = opened;
        });

        this.subscribe('page.licenses', opened => {
            this.licensesFlyoutActive = opened;
        });

        this.subscribe('userNavigationToggled', opened => {
            this.userNavigationFlyoutActive = opened;
        });

        this.subscribe('userLinkListFlyoutToggled', opened => {
            this.userLinkListFlyoutActive = opened;
        });

        this.subscribe('page.notifications', opened => {
            this.notificationsFlyoutActive = opened;
        });

        this.subscribe('page.actions.scroll-top', () => {
            const el = document.querySelector('.page__main');
            el &&
                el.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
        });
    },
    methods: {
        closeResourceCenter() {
            userflow.closeResourceCenter();
        },
    },
});
</script>

<style lang="scss">
.user-select--disabled {
    html & {
        *:not(input):not(textarea) {
            user-select: none;
            -webkit-touch-callout: none;
        }
    }
}
</style>

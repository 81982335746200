<template>
    <div v-if="showComponent" class="unloading-site">
        <div class="font-copy-sm-strong mb-6">
            {{ title || siteTypeHeading }}
        </div>

        <div class="unloading-site__thumbs flex flex-wrap gap-8">
            <div v-if="location" class="relative">
                <SfThumbnail
                    :file-src="staticMap"
                    :translations="{
                        noImage: $t('components.thumbnail'),
                    }"
                    @click="openMap"
                />
            </div>

            <SfThumbnail
                v-if="image"
                :file-thumbnail-src="image.url.thumbnail"
                :file-src="image.url.original"
                :translations="{
                    noImage: $t('components.thumbnail'),
                }"
                modal
            />
        </div>

        <span
            v-if="information"
            class="font-copy-sm-strong mt-6 break-words"
            @click="() => copyNoteToClipboard(information)"
        >
            {{ information }}
        </span>

        <Flyout :active="mapOpen" :headline="siteTypeHeading" @closed="closeMap">
            <UnloadingMap :location="location" map-type="satellite" />
        </Flyout>
    </div>
</template>

<script>
import { getStaticMapUrl } from '@/services/GoogleMaps';

import Flyout from '@/components/Layout/Flyout';
import UnloadingMap from './UnloadingMap';

import SummaryView from '@/pages/Checkout/SummaryView';

import { SfThumbnail } from '@schuettflix/vue-components';
import { useI18n } from 'vue-i18n-composable';
import NativeClipboard from '@/services/Clipboard/NativeClipboard';

export default {
    name: 'UnloadingSite',
    components: {
        Flyout,
        UnloadingMap,

        SfThumbnail,
    },
    props: {
        item: {
            type: [Object, SummaryView],
            required: true,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
        siteType: {
            type: String,
            default: 'unloadingSite',
            validator: v => ['loadingSite', 'unloadingSite'].includes(v),
        },
        title: {
            type: String,
            default: null,
        },
    },
    setup() {
        const { t } = useI18n();

        const copyNoteToClipboard = note => {
            if (!note) return;
            const nativeClipboard = new NativeClipboard(
                t('components.transportNote.copyToClipboard.success'),
                t('components.transportNote.copyToClipboard.error')
            );
            nativeClipboard.writeToClipboard('string', note);
        };

        return {
            copyNoteToClipboard,
        };
    },
    data() {
        return {
            mapOpen: false,
        };
    },
    computed: {
        isLoadingSite() {
            return 'loadingSite' === this.siteType;
        },
        showComponent() {
            if (this.isLoadingSite) {
                return this.item.loadingSiteLocation || this.item.loadingSiteImage || this.item.loadingSiteInformation;
            }
            return (
                this.item.unloadingSiteLocation || this.item.unloadingSiteImage || this.item.unloadingSiteInformation
            );
        },
        staticMap() {
            if (this.isLoadingSite) {
                if (!this.item.loadingSiteLocation) return null;
                return getStaticMapUrl(this.item.loadingSiteLocation, 16, 200, 200, 'satellite');
            }

            if (!this.item.unloadingSiteLocation) return null;
            return getStaticMapUrl(this.item.unloadingSiteLocation, 16, 200, 200, 'satellite');
        },
        siteTypeHeading() {
            return {
                unloadingSite: this.$t('pages.checkout.summary.unloadingSite.headline'),
                loadingSite: this.$t('components.transportTile.loadingSite'),
            }[this.siteType];
        },

        location() {
            if (this.isLoadingSite) return this.item.loadingSiteLocation;
            return this.item.unloadingSiteLocation;
        },

        image() {
            if (this.isLoadingSite) return this.item.loadingSiteImage;
            return this.item.unloadingSiteImage;
        },

        information() {
            if (this.isLoadingSite) return this.item.loadingSiteInformation;
            return this.item.unloadingSiteInformation;
        },
    },
    methods: {
        openMap() {
            this.mapOpen = true;
        },
        closeMap() {
            this.mapOpen = false;
        },
        selectUnloadingSiteLocation() {
            this.$emit('change-action');
        },
    },
};
</script>

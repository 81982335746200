import { computed, reactive, ref } from 'vue';
import { helpers, maxValue, minValue, required, numeric } from '@vuelidate/validators';
import { MarginType } from '@/services/ProductCatalog/types';
import useVuelidate from '@vuelidate/core';
import { mapErrors } from '@/services/validation/serverErrorsMapper';
import { oneOf, optional } from '@/services/validation/validators';
import { getEmptyMasterProduct, getEmptyMasterProductForm, } from '@/pages/ProductManagement/utility/empty-data-provider';
const form = reactive(getEmptyMasterProductForm());
const product = reactive(getEmptyMasterProduct());
const attributes = ref([]);
export const useEditForm = () => {
    const rules = computed(() => {
        const minGrainSize = Number(product.grainSize?.min);
        const maxGrainSize = Number(product.grainSize?.max);
        return {
            grainSize: {
                min: {
                    numeric,
                    ...(product.grainSize ? { maxValue: maxValue(minGrainSize), minValue: minValue(0) } : {}),
                },
                max: {
                    numeric,
                    ...(product.grainSize ? { minValue: minValue(maxGrainSize) } : {}),
                },
            },
            markets: {
                $each: helpers.forEach({
                    marginValue: {
                        required,
                        numeric,
                    },
                    marginType: { oneOf: oneOf(Object.values(MarginType)) },
                    available: { optional },
                    taxRateId: { optional },
                }),
            },
        };
    });
    const setServerErrors = (errors) => {
        $externalResults.value = mapErrors(errors);
    };
    const $externalResults = ref({});
    const v$ = useVuelidate(rules, form, { $externalResults });
    return { form, v$, setServerErrors, attributes, product };
};

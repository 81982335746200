<template>
    <div>
        <ModalBox :active="isActive" :headline="$t('pages.order.action.forceCancel.headline')" @closed="reset()">
            <Words>
                {{ $t('pages.order.action.forceCancel.message') }}
            </Words>

            <ButtonGroup slot="actions">
                <Button place-left primary arrow-left light @click="reset()">
                    {{ $t('pages.order.action.forceCancel.actions.cancel') }}
                </Button>

                <Button :is-loading="isPending" primary @click="cancelOrder()">
                    {{ $t('pages.order.action.forceCancel.actions.confirm') }}
                </Button>
            </ButtonGroup>
        </ModalBox>
        <ModalBox
            v-if="isCancelDetailsModalActive"
            :active="isCancelDetailsModalActive"
            :headline="$t('pages.order.action.forceCancel.details.headline')"
            @closed="closeCancelDetails()"
        >
            <Words spaced-bottom>
                {{ $tc('pages.order.action.forceCancel.details.message.notStarted', transports.notStarted.length) }}
                {{ $tc('pages.order.action.forceCancel.details.message.started', transports.started.length) }}
            </Words>

            <div class="tlbm">
                <div v-for="transport in transports.started" :key="transport.id" class="tlbm__item">
                    <VehicleClassIconSet :icon="transport.vehicleClassIcon" class="tlbm__icon" />
                    <Words>{{ transport.vehicleName }}</Words>
                    <Words bold>{{ transport.number }}</Words>
                    <Words bold red class="tlbm__status">{{ statusText(transport.statusLabel) }}</Words>
                </div>
            </div>

            <ButtonGroup slot="actions">
                <Button primary @click="closeCancelDetails()">
                    {{ $t('pages.order.action.forceCancel.details.ok') }}
                </Button>
            </ButtonGroup>
        </ModalBox>
    </div>
</template>

<script>
import OrderApi from '@/services/Api/Order';
import PubSubEvent from '@/services/PubSub/PubSubEvent';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import routeContext from '@/plugins/mixins/routeContext';
import { ACTIONS } from '@/constants/actions';
import { EVENT_ORDER_CANCELED, EVENT_ORDER_UPDATED } from '@/constants/events';
import { mapGetters } from 'vuex';
import { statusText, statusIsAfter } from '@/services/utils/transport';
import Toaster from '@/services/Toaster';
import TransportView from '@/models/TransportView';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ModalBox from '@/components/Modal/ModalBox';
import VehicleClassIconSet from '@/components/IconSet/VehicleClassIconSet';
import Words from '@/components/Typography/Words';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';

export default {
    name: 'OrderForceCancelAction',
    components: {
        Button,
        ButtonGroup,
        ModalBox,
        VehicleClassIconSet,
        Words,
    },
    mixins: [eventHubMixin, routeContext],
    data() {
        return {
            isPending: false,
            orderBeforeCancel: null,
            orderAfterCancel: null,
            isCancelDetailsModalActive: false,
        };
    },
    computed: {
        ...mapGetters('globalActions', ['subject', 'action']),

        isActive() {
            return this.action === ACTIONS.ORDER_FORCE_CANCEL;
        },

        /**
         * All transports, that are not already delivered, failed or cancelled.
         * @return {{notStarted: TransportView[], started: TransportView[]}}
         */
        transports() {
            /**
             * @property {TransportView[]} notStarted
             * @property {TransportView[]} started
             */
            const transports = {
                notStarted: [],
                started: [],
            };

            this.orderBeforeCancel?.lineItemGroups.forEach((lineItemGroup, i) => {
                lineItemGroup.lineItems.forEach((lineItem, j) => {
                    const { transportStatus } = lineItem;
                    if (['delivered', 'failed', 'cancelled'].includes(transportStatus)) return;

                    const lineItemAfterCancel = this.orderAfterCancel.lineItemGroups[i].lineItems[j];
                    const transportView = TransportView.createFromLineItem(lineItemAfterCancel);

                    if (statusIsAfter(transportStatus, 'started')) {
                        transports.started.push(transportView);
                    } else {
                        transports.notStarted.push(transportView);
                    }
                });
            });

            return transports;
        },

        wasAnyStartedTransportCanceled() {
            return this.transports.started.length > 0;
        },

        hasOrderStatusChanged() {
            return this.orderBeforeCancel.status !== this.orderAfterCancel.status;
        },
    },
    methods: {
        reset() {
            if (!this.isCancelDetailsModalActive) {
                this.orderBeforeCancel = null;
            }
            this.closeAction();
        },

        closeAction() {
            this.$store.dispatch('globalActions/reset', ACTIONS.ORDER_FORCE_CANCEL);
        },

        openCancelDetails() {
            this.closeAction();
            this.isCancelDetailsModalActive = true;
        },

        closeCancelDetails() {
            this.reset();
            this.isCancelDetailsModalActive = false;
        },

        async cancelOrder() {
            if (this.isPending) return;
            this.isPending = true;

            try {
                this.orderBeforeCancel = Object.assign({}, this.subject);
                this.orderAfterCancel = await OrderApi.forceCancelOrder(this.subject.id);

                if (this.hasOrderStatusChanged) {
                    switch (this.orderAfterCancel.status) {
                        case 'canceled':
                            Toaster.success(
                                this.$t('pages.order.action.forceCancel.success.canceled', {
                                    number: this.subject.number,
                                })
                            );
                            break;
                        case 'closed':
                            Toaster.success(
                                this.$t('pages.order.action.forceCancel.success.closed', {
                                    number: this.subject.number,
                                })
                            );
                            break;
                    }
                }

                this.$eventHub.$emit(EVENT_ORDER_UPDATED, new PubSubEvent(this.subject));
                this.$eventHub.$emit(EVENT_ORDER_CANCELED, new PubSubEvent(this.subject));
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(this.$t(err.message));
            }

            AnalyticsService.trackClick('orderDetailView', 'cancelOrder', { orderId: this.subject.id });
            this.isPending = false;

            this.wasAnyStartedTransportCanceled ? this.openCancelDetails() : this.reset();
        },

        statusText(status) {
            return statusText(this.routeContext, status);
        },
    },
};
</script>

<style lang="scss" scoped>
.tlbm {
}

.tlbm__item {
    display: grid;
    grid-template-columns: 64px 48px 96px 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    align-items: center;
    padding: 15px 0;

    & + & {
        border-top: $border-solid-1px;
    }
}

.tlbm__icon {
    width: auto;
    height: 24px;
}

.tlbm__status {
    text-align: right;
}
</style>

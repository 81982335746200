<template>
    <div id="setup">
        <AppLoader :loading="loading" @logoClick="handleLogoClick" @retry="retry" />
        <Overlay />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import AppLoader from '@/components/AppLoader';
import Overlay from '@/components/Overlay/Overlay';

export default {
    name: 'Setup',

    components: {
        AppLoader,
        Overlay,
    },

    data() {
        return {
            clickCount: 0,
        };
    },

    computed: {
        ...mapState('abilities', ['loading']),
    },

    methods: {
        retry() {
            window.location.reload();
        },

        handleLogoClick() {
            if (this.loading) return;

            if (this.clickCount > 19) {
                const input = window.prompt('Api Base Url: ' + window.apibaseurl);
                if (input) {
                    this.$store.commit('platform/setApiBaseUrl', input);
                } else {
                    this.$store.commit('platform/setApiBaseUrl', window.apibaseurl);
                }
                this.clickCount = 0;
            } else {
                this.clickCount++;
            }
        },
    },
};
</script>

<style lang="scss"></style>

import React from 'react';
import clsx from 'clsx';
import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
import { useTranslation } from 'react-i18next';
export const SellerProductEntry = ({ product, className, label, isLoading = false, onSelect }) => {
    const { formatPerTon } = useMoneyFormatter();
    const { t } = useTranslation();
    const selectProduct = () => {
        if (isLoading) {
            return;
        }
        onSelect?.(product);
    };
    return (<div className={clsx('grid w-full grid-cols-[auto_1fr] rounded p-4 shadow-low', isLoading ? 'cursor-default bg-surface-subdued' : 'cursor-pointer bg-surface', className)} onClick={selectProduct} data-test="supplier-product-card">
            <div className="overflow-hidden">
                <div className="font-copy-md-strong mb-1 ">{label}</div>
                <div className="font-copy-sm  text-subdued">{product.name}</div>
            </div>
            <div className="font-copy-sm text-right">
                <div className="mb-1">{formatPerTon(product.price)}</div>
                <div className="text-subdued">{t('pages.checkout.supplierProfilePage.productCard.price')}</div>
            </div>
        </div>);
};

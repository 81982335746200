import solver from '@uandi/javascript-lp-solver';
import _orderBy from 'lodash/orderBy';

const pricePrecision = 2;

// transform euro in cents
function getNormalizedPrice(value) {
    return Math.round(value * Math.pow(10, pricePrecision));
}

function getTransport(vehicle, load) {
    return {
        id: vehicle.id,
        icon: vehicle.icon,
        actualLoad: load,
        name: vehicle.name,
        numAxes: vehicle.numAxes,
        payload: vehicle.payload,
        loadPercentage: Math.floor((load / vehicle.payload) * 100),
    };
}

class TransportOptimization {
    constructor() {
        this.optimize = 'cost';
        this.opType = 'min';

        this.variables = {};
        this.ints = {};
        this.fixedPriceFactor = 0;
    }

    // Set price factor for calculation, in euro
    setFixedPriceFactor(price) {
        this.fixedPriceFactor = getNormalizedPrice(price);
    }

    setVehicles(vehicles = []) {
        this.vehicles = _orderBy(vehicles, ['payload'], ['desc']);
        this.variables = {};
        this.ints = {};

        vehicles.forEach(item => {
            this.variables[item.id] = {
                payload: item.payload,
                cost:
                    getNormalizedPrice(item.salesPriceKm) +
                    getNormalizedPrice(item.salesPriceHour) +
                    this.fixedPriceFactor,
            };

            this.ints[item.id] = 1;
        });
    }

    getTransports(load) {
        const model = {
            optimize: this.optimize,
            opType: this.opType,
            constraints: {
                payload: {
                    min: load,
                },
            },
            variables: this.variables,
            ints: this.ints,
        };

        const variableCount = Object.keys(model.variables).length;

        if (variableCount <= 0 || load <= 0) {
            return {
                result: 0,
                transports: [],
            };
        }

        const transports = [];

        if (variableCount <= 1) {
            const vehicle = this.vehicles[0];
            const remains = load % vehicle.payload;

            const count = Math.ceil((load - remains) / vehicle.payload);

            for (let i = 0; i < count; i++) {
                transports.push(getTransport(vehicle, vehicle.payload));
            }

            if (remains > 0) {
                transports.push(getTransport(vehicle, remains));
            }

            return {
                result: transports.count * this.variables[vehicle.id].cost,
                transports: transports,
            };
        }

        const result = solver.Solve(model);

        let actualLoad = 0;

        this.vehicles.forEach(vehicle => {
            if (!result[vehicle.id]) {
                return;
            }

            for (let i = 0; i < result[vehicle.id]; i++) {
                let vehicleLoad = vehicle.payload;

                if (actualLoad + vehicleLoad > load) {
                    vehicleLoad = load - actualLoad;
                }

                transports.push(getTransport(vehicle, vehicleLoad));

                actualLoad += vehicleLoad;
            }
        });

        return {
            result: result.result,
            transports,
        };
    }
}

export default new TransportOptimization();

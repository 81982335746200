<template>
    <ModalBox
        ref="deleteProductModal"
        class="delete-product-modal"
        optional-dialog
        :border="false"
        has-large-headline
        :headline="$t('pages.products.supplierProductsList.wasteProductDetails.deleteProductModal.headline')"
    >
        <Words block class="intro">
            {{ $t('pages.products.supplierProductsList.wasteProductDetails.deleteProductModal.intro1') }}
        </Words>

        <Words block class="intro">
            {{ $t('pages.products.supplierProductsList.wasteProductDetails.deleteProductModal.intro2') }}
        </Words>

        <Words block class="intro">
            {{ $t('pages.products.supplierProductsList.wasteProductDetails.deleteProductModal.intro3') }}
        </Words>

        <ButtonGroup slot="actions">
            <Button primary light arrow-right @click="closeDeleteWasteProductModal()">
                {{ $t('pages.products.supplierProductsList.wasteProductDetails.deleteProductModal.cancel') }}
            </Button>
            <Button primary arrow-right @click="confirmDeleteWasteProduct()">
                {{ $t('pages.products.supplierProductsList.wasteProductDetails.deleteProductModal.confirm') }}
            </Button>
        </ButtonGroup>
    </ModalBox>
</template>
<script>
import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ModalBox from '@/components/Modal/ModalBox';
import Words from '@/components/Typography/Words';

export default {
    name: 'SupplierWasteProductDetailsDeleteProductModal',
    components: {
        Button,
        ButtonGroup,
        ModalBox,
        Words,
    },
    methods: {
        closeDeleteWasteProductModal() {
            this.$refs.deleteProductModal.$emit('close');

            this.$emit('accept');
        },
        confirmDeleteWasteProduct() {
            this.closeDeleteWasteProductModal();
        },
    },
};
</script>

<style lang="scss" scoped src="./SupplierWasteProductDetailsDeleteProductModal.scss" />

<template>
    <SelectBox
        v-if="$root.isDesktop && hasSortSetOptions"
        :value="currentValue"
        :options="sortOptions"
        :option-value-renderer="option => option"
        :option-label-renderer="optionLabelRenderer"
        tiny
        class="filter-sort-pagination__sorting"
        @input="selectedSortHandler($event)"
    />
    <div
        v-else-if="$root.isDesktop"
        :class="[
            {
                'select-input--shadow': shadow && !dark,
                'select-input--small': small,
            },
        ]"
        class="select-input select-input--sorting"
    >
        <SortingDirection :value="direction" :dark="dark" :small="small" @input="$emit('direction-update', $event)" />

        <div class="select-input__wrapper">
            <select
                :id="eid"
                :value="value"
                class="select-input__input select-input__input--no-value"
                @change="$emit('sort-update', $event.target.value)"
            >
                <option v-for="option in sortOptions" :key="option" :value="option">
                    {{ optionLabelRenderer(option) }}
                </option>
            </select>

            <label
                :for="eid"
                :class="[{ 'select-input__label--dark': dark, 'select-input__label--no-arrow': hideSortArrow }]"
                class="select-input__label"
            >
                {{ value ? optionLabelRenderer(value) : label }}
            </label>

            <ArrowDownIcon v-if="!hideSortArrow" class="select-input__arrow select-input__arrow--down" />
        </div>
    </div>

    <div v-else>
        <MultiselectBox
            ref="multiselectBox"
            disable-reset
            disable-on-select-flyout-close
            custom-save-button
            option-class="sorting-select-box-mobile__option"
            :label="$t('components.sortSelectBox.title')"
            :value="currentValue"
            :options="sortOptions"
            :option-value-renderer="option => option"
            @input="updateValue($event)"
        >
            <template #button>
                <span class="sorting-select-box-mobile__icon"><SfSortingIcon multi-color size="xs" /></span>
            </template>

            <template v-if="!hasSortSetOptions" #additionalOptionContents>
                <div class="sorting-select-box-mobile__top-padding" />
                <div class="sorting-select-box-mobile__sort-directions">
                    <BaseButton
                        class="sorting-select-box-mobile__sort-direction-button"
                        :class="{ 'sorting-select-box-mobile__sort-direction-button--active': direction === 'asc' }"
                        @click="$emit('direction-update', 'asc')"
                    >
                        <div class="flex items-center justify-center">
                            <ArrowUpIcon class="sorting-select-box-mobile__arrow" />
                            {{ $t('components.sortSelectBox.asc') }}
                        </div>
                    </BaseButton>
                    <BaseButton
                        class="sorting-select-box-mobile__sort-direction-button"
                        :class="{ 'sorting-select-box-mobile__sort-direction-button--active': direction === 'desc' }"
                        @click="$emit('direction-update', 'desc')"
                    >
                        <div class="flex items-center justify-center">
                            <ArrowUpIcon
                                class="sorting-select-box-mobile__arrow sorting-select-box-mobile__arrow--down"
                            />
                            {{ $t('components.sortSelectBox.desc') }}
                        </div>
                    </BaseButton>
                </div>
            </template>

            <template #option="scope">
                <div
                    class="sorting-select-box-mobile__each-option"
                    :class="{ 'sorting-select-box-mobile__each-option--active': scope.isActive }"
                >
                    <span>{{ optionLabelRenderer(scope.option) }}</span>
                    <span v-if="scope.isActive">
                        <TickIcon class="sorting-select-box-mobile__each-option__tick" />
                    </span>
                </div>
            </template>

            <template #customSaveButton="scope">
                <BaseButton primary @click="scope.closeFlyout">
                    {{ $t('components.multiselectBox.accept') }}
                </BaseButton>
            </template>
        </MultiselectBox>
    </div>
</template>

<script>
import { joinSortSet, splitSortSet } from '@/services/utils/filter';

import BaseButton from '@/components/Button/Button';
import MultiselectBox from '@/components/Form/MultiselectBox';
import SelectBox from '@/components/Form/SelectBox.v3';
import SortingDirection from '@/components/Form/SortingDirection';

import ArrowDownIcon from '@/assets/icons/regular/arrow-stripeless--down.svg';
import ArrowUpIcon from '@/assets/icons/regular/arrow-stripeless--up.svg';
import TickIcon from '@/assets/icons/micro/tick.svg';

import { SfSortingIcon } from '@schuettflix/vue-components';

export default {
    name: 'SortingSelectBoxMobile',

    components: {
        SelectBox,
        BaseButton,
        MultiselectBox,
        SortingDirection,

        ArrowDownIcon,
        ArrowUpIcon,
        TickIcon,

        SfSortingIcon,
    },

    props: {
        value: {
            type: [String, Number, Boolean],
            default: null,
        },

        dark: {
            type: Boolean,
            default: false,
        },

        shadow: {
            type: Boolean,
            default: false,
        },

        label: {
            type: String,
            default: null,
        },

        tiny: {
            type: Boolean,
            default: false,
        },

        error: {
            type: String,
            default: null,
        },

        hasError: {
            type: Boolean,
            default: false,
        },

        direction: {
            type: String,
            default: 'asc',
            validator: value => ['asc', 'desc'].indexOf(value) !== -1,
        },

        small: {
            type: Boolean,
            default: false,
        },

        sortOptions: {
            type: Array,
            default: () => [],
        },

        optionLabelRenderer: {
            type: Function,
            default: value => {
                return value.toString();
            },
        },

        hideSortArrow: {
            type: Boolean,
            default: false,
        },

        hasSortSetOptions: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            eid: `el${this._uid}`,
        };
    },
    computed: {
        currentValue() {
            return this.hasSortSetOptions ? joinSortSet(this.value, this.direction) : this.value;
        },
    },

    created() {
        this.$on('openSortingBox', () => {
            this.$refs.multiselectBox.$emit('openMultiselectFlyout');
        });
        this.$on('closeSortingBox', () => {
            this.$refs.multiselectBox.$emit('closeMultiselectFlyout');
        });
    },

    methods: {
        joinSortSet,

        selectedSortHandler(value) {
            const sort = splitSortSet(value);

            this.$emit('sort-update', sort.sortBy);
            this.$emit('direction-update', sort.sortDirection);
        },

        updateValue(value) {
            if (this.hasSortSetOptions) {
                this.selectedSortHandler(value);
                return;
            }

            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss" scoped>
.select-input {
    display: flex;
    align-items: center;
}

.select-input--sorting {
    display: flex;
    flex-flow: row nowrap;
    padding-left: 0;

    .select-input__input,
    .select-input__label {
        padding-left: 0;
    }

    .select-input__label--dark {
        background-color: $color-lightGrey;
    }
}

.select-input--small {
    height: 50px;

    .select-input__label {
        line-height: 50px;
        height: auto;
    }
}

.select-input__label--no-arrow {
    padding-right: 0;
}

.sorting-select-box-mobile__arrow {
    margin-right: 10px;
}

.sorting-select-box-mobile__arrow--down {
    transform: rotate(180deg);
}

.sorting-select-box-mobile__sort-directions {
    position: fixed;
    width: 100%;
    top: 80px;
    display: flex;
    padding: 20px 20px 0px 20px;
    background: white;
    border-bottom: 1px solid $color-littleDarkerThanLightMediumGrey;
    z-index: 1000;
}

.sorting-select-box-mobile__top-padding {
    min-height: 80px;
    width: 100%;
    z-index: 1000;
}

.sorting-select-box-mobile__sort-direction-button {
    opacity: 0.3;
    flex: 1;
    padding: 10px 0px 30px 0px;
}

.sorting-select-box-mobile__sort-direction-button:hover {
    opacity: 1;
}

.sorting-select-box-mobile__sort-direction-button--active {
    opacity: 1;
    color: $color-red;
    stroke: $color-red;
}

.sorting-select-box-mobile__option:hover {
    opacity: 1;
}

.sorting-select-box-mobile__each-option {
    display: flex;
    justify-content: space-between;

    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 400;
}

.sorting-select-box-mobile__each-option--active {
    font-weight: 600;
}

.sorting-select-box-mobile__each-option__tick {
    fill: $color-red;
}
</style>

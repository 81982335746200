import UserListPage from '@/pages/User/UserListPage';

export default {
    path: 'user-management',
    name: 'user-management',
    component: UserListPage,
    meta: {
        requiredAbilities: ['haveUserManagement'],
    },
};

import { forwardRef } from 'react';
import { useIconSize } from '@/services/utils/useIconSize';
export const SettingsIcon = forwardRef(({ className, multicolor = false, size = 'xs', ...svgProps }, ref) => {
    const iconSize = useIconSize(size);
    return (<svg width={iconSize} height={iconSize} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} {...svgProps} ref={ref}>
                <g clipPath="url(#clip0_376_1211)">
                    <path d="M12.0113 4.697V1" stroke="#35343D" strokeWidth="2" strokeMiterlimit="10"/>
                    <path d="M6.84916 6.83518L4.23471 4.2207" stroke="#35343D" strokeWidth="2" strokeMiterlimit="10"/>
                    <path d="M4.01295 12.0117H1" stroke="#35343D" strokeWidth="2" strokeMiterlimit="10"/>
                    <path d="M6.84916 17.1582L4.23471 19.7727" stroke="#35343D" strokeWidth="2" strokeMiterlimit="10"/>
                    <path d="M12.0113 19.2969V22.9939" stroke="#35343D" strokeWidth="2" strokeMiterlimit="10"/>
                    <path d="M17.1724 17.1582L19.7868 19.7727" stroke="#35343D" strokeWidth="2" strokeMiterlimit="10"/>
                    <path d="M19.3113 11.9971H23.0083" stroke="#35343D" strokeWidth="2" strokeMiterlimit="10"/>
                    <path d="M17.1724 6.83518L19.7868 4.2207" stroke="#35343D" strokeWidth="2" strokeMiterlimit="10"/>
                    <path d="M19.9955 12C19.9955 9.87832 19.1529 7.84348 17.6531 6.3432C16.1534 4.84292 14.1193 4.00005 11.9983 4C9.87717 4.00001 7.84293 4.84288 6.34299 6.34314C4.84306 7.8434 4.00027 9.87821 4 12C4.00027 14.1218 4.84306 16.1567 6.34299 17.6569C7.84293 19.1572 9.87717 20 11.9983 20C14.1193 20 16.1534 19.1571 17.6531 17.6568C19.1529 16.1565 19.9955 14.1217 19.9955 12Z" stroke="#35343D" strokeWidth="2" strokeMiterlimit="10"/>
                    <path d="M20.0084 11.9973C20.0084 9.87632 19.1658 7.84218 17.6661 6.34241C16.1663 4.84264 14.1322 4.00005 12.0112 4" stroke="#35343D" strokeWidth="2" strokeMiterlimit="10"/>
                    <path className={multicolor ? 'stroke-icon-accent' : 'stroke-icon'} d="M16 12.0005C16.0001 10.9396 15.5789 9.9222 14.8289 9.17197C14.0789 8.42174 13.0617 8.00015 12.001 8C10.94 7.9999 9.92243 8.42131 9.17211 9.17154C8.42178 9.92177 8.00016 10.9394 8 12.0005C8.0001 12.5258 8.10367 13.046 8.3048 13.5314C8.50593 14.0167 8.80067 14.4577 9.17221 14.8291C9.54374 15.2005 9.98478 15.495 10.4701 15.6959C10.9555 15.8968 11.4757 16.0002 12.001 16C12.5262 16 13.0462 15.8966 13.5314 15.6956C14.0166 15.4946 14.4574 15.2 14.8288 14.8286C15.2001 14.4572 15.4947 14.0163 15.6957 13.5311C15.8966 13.0458 16 12.5257 16 12.0005V12.0005Z" strokeWidth="1.90197" strokeMiterlimit="10"/>
                </g>
                <defs>
                    <clipPath id="clip0_376_1211">
                        <rect width="22" height="22" fill="white" transform="translate(1 1)"/>
                    </clipPath>
                </defs>
            </svg>);
});
SettingsIcon.displayName = 'SettingsIcon';

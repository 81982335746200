import { useRouter } from 'vue-router/composables';
import { encode, decode } from 'js-base64';
import _isEqual from 'lodash/isEqual';
import _clone from 'lodash/clone';
export function assembleQueryFilter(filter) {
    // Using public base64 implementation, to hande characters outside the Latin1 range.
    // https://stackoverflow.com/a/45725439
    return encode(JSON.stringify(filter));
}
export function usePersistentFilters() {
    const router = useRouter();
    const assembleFilter = (name, filter) => {
        const loadedQuery = router.currentRoute.query[name];
        if (!loadedQuery) {
            return filter;
        }
        try {
            // Using public base64 implementation, to hande characters outside the Latin1 range.
            // https://stackoverflow.com/a/45725439
            return JSON.parse(decode(loadedQuery));
        }
        catch (e) {
            return filter;
        }
    };
    const persistFilter = (name, filter, defaultFilter) => {
        const query = _clone(router.currentRoute.query);
        if (_isEqual(filter, defaultFilter)) {
            delete query[name];
        }
        else {
            query[name] = assembleQueryFilter(filter);
        }
        router
            .replace({
            path: router.currentRoute.path,
            query: query,
        })
            .catch(() => { });
    };
    const isQueryFilterActive = (name, filter) => {
        return router.currentRoute.query[name] === assembleQueryFilter(filter);
    };
    const isQueryFilterSet = (name) => {
        return !!router.currentRoute.query[name];
    };
    return {
        assembleQueryFilter,
        assembleFilter,
        persistFilter,
        isQueryFilterActive,
        isQueryFilterSet,
    };
}

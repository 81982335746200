import _isFunction from 'lodash/isFunction';
export default class PubSubEvent {
    constructor(subject = null, callback = null) {
        this.subject = subject;
        this.callback = callback;
    }

    /**
     * Call callbacks when the work is done
     */
    finish() {
        if (_isFunction(this.callback)) {
            this.callback(this.subject);
        }
    }
}

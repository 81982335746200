import AbstractResource from '@/services/Api/AbstractResource';

class Address extends AbstractResource {
    /**
     * get last used addresses for clientOrganization
     * @param {Object} params
     */
    async getUsed({ type, clientOrganizationId, count = 3, operations = null }) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/used`, {
                params: {
                    type,
                    clientOrganizationId,
                    count,
                    operations,
                },
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new Address('/address');

import localeDisplayNames from './displayNames';
import { languages, localeCodes } from './languages.config';
export const availableLocales = localeCodes;
export const availableLanguageCodes = Array.from(new Set(availableLocales.map(locale => locale.split('-')[0])));
export const defaultLocale = 'de-DE';
export function getDisplayNameOfLanguage(locale, language) {
    const availableLocale = findClosestMatchingSupportedLocale(locale);
    const availableLanguage = findClosestMatchingSupportedLocale(language);
    return localeDisplayNames[availableLocale][availableLanguage];
}
export function getAllLanguagesWithNativeDisplayName() {
    return languages.map(({ locale }) => ({
        locale,
        displayName: getDisplayNameOfLanguage(locale, locale),
    }));
}
export function getAllLanguagesInLocale(locale) {
    return availableLocales.map(lang => ({
        locale: lang,
        displayName: getDisplayNameOfLanguage(locale, lang),
    }));
}
export function getLanguageFromLocale(locale) {
    return locale.split('-')[0];
}
export function getCountryFromLocale(locale) {
    if (locale.length === 2) {
        return locale.toUpperCase();
    }
    return locale.split('-')[1] || null;
}
// This function is needed because the market names are saved in the form "dede" and not "de-DE"
// TODO: use one single convention for backend, FE and phrase,
export function getLowercaseLocale(locale) {
    return locale.replace('-', '').toLowerCase();
}
export function findClosestMatchingSupportedLocale(locale) {
    if (!locale) {
        return defaultLocale;
    }
    return (
    // 1. exact match
    availableLocales.find(l => l === locale) ||
        // 2. only language matches
        availableLocales.find(l => l.startsWith(getLanguageFromLocale(locale))) ||
        // 3. fallback to default
        defaultLocale);
}
export function getFlagFromLocale(locale) {
    const getFlag = (locale) => languages.find(lang => lang.locale === locale)?.flag;
    return getFlag(locale) || getFlag(defaultLocale);
}
export function getUserflowLocaleByLocaleCode(localeCode) {
    return languages.find(lang => lang.locale === localeCode)?.userflowLocale || localeCode;
}
export function getDateFnsLocaleByLocaleCode(localeCode) {
    return languages.find(lang => lang.locale === localeCode)?.dateFnsLocale;
}

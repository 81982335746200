<template>
    <div class="login">
        <div v-if="isIE" class="login__ie-note">
            <Words small>{{ $t('pages.login.ie.message') }}</Words>
            <Words block class="login__ie-note-links">
                <a href="https://www.google.com/chrome/" target="_blank">
                    {{ $t('pages.login.ie.browserLabel.chrome') }}
                </a>
                <a href="http://www.mozilla.org/firefox/new/" target="_blank">
                    {{ $t('pages.login.ie.browserLabel.firefox') }}
                </a>
            </Words>
        </div>

        <div :class="{ 'login__video-wrapper--loaded': isVideoLoaded }" class="login__video-wrapper login--resizeable">
            <video
                ref="video"
                src="@/assets/video/schuettflix-login.mp4"
                autoplay
                playsinline
                preload="auto"
                webkit-playsinline
                muted
                class="login__video"
                loop
                @loadeddata="finishVideoLoading"
            />
        </div>

        <LoginForm
            @passwordLost="passwordLostOpen = true"
            @usernameLost="usernameLostOpen = true"
            @updateLanguage="languageSelectOpen = true"
        />

        <UserNameResetFlyout
            v-if="usernameLostOpen"
            @close="usernameLostOpen = false"
            @messageSent="openUsernameLostConfirmation()"
        />

        <MessageConfirmFlyout
            v-if="usernameLostConfirmationOpen"
            :button-text="$t('pages.login.usernameLostConfirmation.close')"
            :description="$t('pages.login.usernameLostConfirmation.description')"
            :headline="$t('pages.login.usernameLostConfirmation.headline')"
            @close="usernameLostConfirmationOpen = false"
        />

        <PasswordResetFlyout
            v-if="passwordLostOpen"
            @close="passwordLostOpen = false"
            @messageSent="openPasswordResetConfirmation()"
        />

        <LanguageSelectFlyout
            :active="languageSelectOpen"
            :value="locale"
            @select="setLocale($event)"
            @closed="languageSelectOpen = false"
        />

        <MessageConfirmFlyout
            v-if="passwordResetConfirmationOpen"
            :button-text="$t('pages.login.passwordResetConfirmation.close')"
            :description="$t('pages.login.passwordResetConfirmation.description')"
            :headline="$t('pages.login.passwordResetConfirmation.headline')"
            @close="passwordResetConfirmationOpen = false"
        />
    </div>
</template>

<script>
import ObjectFitVideos from 'object-fit-videos';
import { mapGetters } from 'vuex';

import { getIEVersion } from '@/services/utils';

import LanguageSelectFlyout from '@/components/LanguageSelectFlyout';
import Words from '@/components/Typography/Words';

import MessageConfirmFlyout from './components/MessageConfirmFlyout';
import LoginForm from './components/LoginForm';
import PasswordResetFlyout from './components/PasswordResetFlyout';
import UserNameResetFlyout from './components/UserNameResetFlyout';

import { findClosestMatchingSupportedLocale } from '@/i18n/languages';

export default {
    name: 'LoginPage',
    components: {
        LoginForm,
        MessageConfirmFlyout,
        PasswordResetFlyout,
        Words,
        UserNameResetFlyout,
        LanguageSelectFlyout,
    },

    data: () => ({
        passwordLostOpen: false,
        passwordResetConfirmationOpen: false,
        usernameLostOpen: false,
        usernameLostConfirmationOpen: false,
        languageSelectOpen: false,
        isVideoLoaded: false,
        isIE: getIEVersion(),
    }),

    computed: {
        ...mapGetters('user', ['isLoggedIn']),
        ...mapGetters('platform', ['countryCheck']),
        ...mapGetters('localPreferences', ['isCountryDialogAcknowledged']),
        ...mapGetters('i18n', ['locale']),

        isApp() {
            return this.$root.isApp;
        },
    },

    created() {
        if (this.isLoggedIn) {
            this.$router.replace({ name: 'home' }).catch(() => {
                // empty catch to prevent sonar warning
            });
        }

        const browserLocale = findClosestMatchingSupportedLocale(navigator.language || navigator.userLanguage);
        this.setLocale(browserLocale);
    },

    mounted() {
        ObjectFitVideos(this.$refs.video);
    },

    methods: {
        openPasswordResetConfirmation() {
            this.passwordLostOpen = false;
            this.passwordResetConfirmationOpen = true;
        },

        openUsernameLostConfirmation() {
            this.usernameLostOpen = false;
            this.usernameLostConfirmationOpen = true;
        },

        finishVideoLoading() {
            this.isVideoLoaded = true;
        },

        async setLocale(locale) {
            await this.$store.dispatch('i18n/changeLocale', {
                locale,
            });
            this.languageSelectOpen = false;
        },
    },
};
</script>
<style lang="scss">
.login {
    background-color: $color-red;
    min-height: var(--view-height);
}

.login__video-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &::before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #af0000;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
    }
}

.login__video-wrapper--loaded::before {
    opacity: 0;
}

.login__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
}

.login__ie-note {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px 200px 20px 20px;
    background-color: $color-darkRed;
    color: $color-white;
    z-index: 1;
}

.login__ie-note-links {
    margin-top: 10px;

    a {
        color: $color-white;
        text-decoration: underline;
    }

    > a + a {
        margin-left: 20px;
    }
}
</style>

import Vue from 'vue';
import _isEqual from 'lodash/isEqual';
import {
    greaterOrEqualThanWithNullCheck,
    greaterThan,
    hasDocuments,
    isAlphanumericWithSpacesAndGermanSpecialCharacters,
    isDisposerNumberFormatDE,
    isNotNullOrUndefined,
    isRequired,
    maxLength,
    minLength,
} from '@/services/validation/rules';
import { defaultState } from './state.js';
import { stateIdentifiers } from '@/services/Disposal/StateIdentifiers';
import { revertLocalizedValue } from '@/services/utils/localization';

import { convertedPriceFromKiloToTon, convertedWeightFromKiloToTon } from '@/services/utils';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import { FACTORY_TYPES } from '@/constants/disposal';

const getters = {
    isDefaultState: state => {
        return _isEqual(state, defaultState());
    },

    getDisposalProducerNumber: state => {
        return state.requestCollection.producerNumber;
    },

    getProductVariants: state => {
        if (!(state.product?.variants || []).length) return [];

        return state.product.variants.map(variant => ({
            ...variant,
            price: convertedPriceFromKiloToTon(variant.pricePerKilogram),
            wasteCapacityPerDay: convertedWeightFromKiloToTon(variant.wasteCapacityPerDayInKilogram),
        }));
    },

    getBasketType: state => {
        return state.requestCollection.type;
    },

    getProduct: state => {
        return state.product;
    },

    getQuantity: state => {
        return state.requestCollection.qty;
    },

    getProjectPositionType: state => {
        return state.requestCollection.type;
    },

    getPutToTransportMarket: state => {
        return state.putToTransportMarket;
    },

    getSummary: state => {
        const isHazardous = state.requestCollection.type === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS;
        const isDumpingSite = state.disposer.factoryType === FACTORY_TYPES.DUMPING_SITE;
        const hasFixedPrice = state.requestCollection.billingMethod === 'fixedPrice';
        const tags = [];
        const selectedStateWasteCompany = state.stateWasteCompanies.filter(c => {
            return c.id === state.requestCollection.billingByStateWasteCompanyId;
        });

        if (state.pricesData?.product?.pollutionType?.label) {
            tags.push({ text: state.pricesData.product.pollutionType.label });
        }

        return {
            isHazardous,
            isDumpingSite,
            hasFixedPrice,
            productTitle: {
                prefix: Vue.filter('tons')(state.requestCollection.qty),
                title: `${state.product.wasteCode}${isHazardous ? '*' : ''}`,
                description: revertLocalizedValue(state.product.productName) || null,
                tags,
            },
            constructionSite: {
                address: {
                    address: { ...(state.constructionSite?.address || {}), location: state.constructionSite?.location },
                },
                name: state.constructionSite?.name,
            },
            generalInformation: {
                disposalProofNumber: state.requestCollection.disposalProofNumber,
                producerNumber: state.requestCollection.producerNumber,
                stateWasteCompanyText: selectedStateWasteCompany?.[0]?.name,
                stateWasteCompanyFee: selectedStateWasteCompany?.[0]?.feeInPercent,
            },
            client: {
                name: state.clientName,
            },
            disposer: state.disposer,
            waste: {
                code: state.pricesData?.product?.wasteCode,
                description: revertLocalizedValue(state.product.productName) || null,
                isHazardous,
            },
            wasteDescription: {
                pollutionType: state.pricesData?.product?.pollutionType.label,
                note: state.factoryProductVariantNote,
                isPretreated: state.requestCollection.isPretreated,
                consistency: state.requestCollection.consistency,
                pretreatmentDescription: state.requestCollection.pretreatmentDescription,
                additionalInternalTitle: state.requestCollection.additionalInternalTitle,
            },
            documents: {
                documents: state.documents,
                otherDocuments: state.otherDocuments,
                images: state.images,
            },
            carrier: {
                name: state?.carrierInfo?.name,
                disposalNumber: state?.requestCollection?.carrierNumber,
                address: state?.carrierInfo?.billingAddress,
            },
            billing: {
                billingMethod: state.requestCollection.billingMethod,
            },
            validityRange: state.requestCollection.validityRange,
            status: state.requestCollection.status,
        };
    },

    validationRules: state => {
        const isHazardous = state.requestCollection.type === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS;

        // Step "General informationen"
        const producerNumberRules = [];
        const disposalProofNumberRules = [];
        const billingByStateWasteCompanyIdRules = [];

        if (isHazardous) {
            if (state.isBilledByStateWasteCompany)
                billingByStateWasteCompanyIdRules.push(isRequired(), greaterThan(0, 'validation.isRequired'));

            const correctWasteStateLetter = stateIdentifiers.find(stateIdentifier => {
                return stateIdentifier.shortName.toLowerCase() === state?.constructionSiteState?.toLowerCase();
            })?.wasteStateLetter;

            producerNumberRules.push(
                isRequired(),
                minLength(2, 'validation.isRequired'),
                isDisposerNumberFormatDE(
                    correctWasteStateLetter,
                    'components.disposalNumber.producerNumber.errorMessage'
                )
            );
            disposalProofNumberRules.push(isRequired(), minLength(12), maxLength(12));
        }

        // Step "Quantity"
        const qtyRules = [];
        if (isHazardous) {
            qtyRules.push(isRequired(), greaterThan(0, 'validation.isRequired'));
        }

        // Step "Carrier Select"
        const carrierRules = [];
        if (state.carrierInfo) {
            carrierRules.push(isRequired(), greaterThan(0, 'validation.isRequired'));
        }

        // Step "Waste Details"
        const pretreatmentDescriptionRules = [isAlphanumericWithSpacesAndGermanSpecialCharacters(), maxLength(250)];
        if (state.requestCollection.isPretreated)
            pretreatmentDescriptionRules.push(isRequired(), isNotNullOrUndefined());

        // Step "Analysis"
        const documentUuidsRules = [hasDocuments()];

        // Step Adjust Prices
        const customPricesDataRules = { purchaseUnitPrice: [], retailUnitPrice: [] };
        const originalPricesDataRules = { purchaseUnitPrice: [], retailUnitPrice: [] };
        if (state.pricesData?.product?.customPrices) {
            customPricesDataRules['purchaseUnitPrice'].push(greaterOrEqualThanWithNullCheck(0));
            customPricesDataRules['retailUnitPrice'].push(greaterOrEqualThanWithNullCheck(0));
        } else {
            originalPricesDataRules['purchaseUnitPrice'].push(greaterOrEqualThanWithNullCheck(0));
            originalPricesDataRules['retailUnitPrice'].push(greaterOrEqualThanWithNullCheck(0));
        }

        return {
            // Step "Order type"
            'requestCollection.type': [isRequired()],
            'requestCollection.project.id': [isRequired()],

            // Step "Construction site"
            'requestCollection.constructionSite.id': [isRequired()],

            // Step "General informationen"
            'requestCollection.producerNumber': producerNumberRules,
            'requestCollection.disposalProofNumber': disposalProofNumberRules,
            'requestCollection.billingByStateWasteCompanyId': billingByStateWasteCompanyIdRules,

            // Step "Choose Disposer / Facility"
            'disposer.factoryId': [isRequired(), greaterThan(0, 'validation.isRequired')],

            // Step "Choose Waste"

            // Step "Quantity"
            'requestCollection.qty': qtyRules,

            // Step "Carrier selection"
            'requestCollection.carrier.id': carrierRules,

            // Step "Waste Details"
            'requestCollection.factoryProductVariantId': [isRequired(), greaterThan(0, 'validation.isRequired')],
            'requestCollection.isPretreated': [isNotNullOrUndefined()],
            'requestCollection.pretreatmentDescription': pretreatmentDescriptionRules,
            'requestCollection.consistency': [isRequired()],
            'requestCollection.additionalInternalTitle': [maxLength(255)],

            // Step "Analysis"
            'requestCollection.documentUuids': documentUuidsRules,
            'requestCollection.accompanyingAnalysisImageUuids': [],

            // Step "Set Prices"
            'requestCollection.vehicleClasses': [isNotNullOrUndefined()],
            'pricesData.product.customPrices.purchaseUnitPrice': customPricesDataRules['purchaseUnitPrice'],
            'pricesData.product.customPrices.retailUnitPrice': customPricesDataRules['retailUnitPrice'],
            'pricesData.product.originalPrices.purchaseUnitPrice': originalPricesDataRules['purchaseUnitPrice'],
            'pricesData.product.originalPrices.retailUnitPrice': originalPricesDataRules['retailUnitPrice'],

            // Step "Summary"
            'requestCollection.note': [maxLength(255)],
        };
    },

    validationGroups: () => {
        return {
            // Step "Type"
            orderType: ['requestCollection.type', 'requestCollection.project.id'],

            // Step "Construction site"
            constructionSite: ['requestCollection.constructionSite.id'],

            // Step "Allgemeine Informationen"
            generalInformation: [
                'requestCollection.type',
                'requestCollection.constructionSite.id',
                'requestCollection.project.id',
                'requestCollection.producerNumber',
                'requestCollection.disposalProofNumber',
                'requestCollection.billingByStateWasteCompanyId',
            ],

            // Step "Entsorger"
            disposer: ['disposer.factoryId'],

            // Step "Choose Waste"
            chooseWaste: [],

            // Step "Quantity"
            quantity: ['requestCollection.qty'],

            // Step "Carrier Selection"
            chooseCarrier: ['requestCollection.carrier.id'],

            // Step "Waste Details"
            wasteDetailsStepProductSelected: ['requestCollection.factoryProductVariantId'],

            wasteDetailsStepProductVariantSelected: [
                'requestCollection.factoryProductVariantId',
                'requestCollection.isPretreated',
                'requestCollection.pretreatmentDescription',
            ],

            wasteDetailsStepIsPretreatmentSelected: [
                'requestCollection.factoryProductVariantId',
                'requestCollection.isPretreated',
                'requestCollection.pretreatmentDescription',
                'requestCollection.consistency',
            ],

            wasteDetailsStepIsConsistencySelected: [
                'requestCollection.factoryProductVariantId',
                'requestCollection.isPretreated',
                'requestCollection.pretreatmentDescription',
                'requestCollection.consistency',
                'requestCollection.additionalInternalTitle',
            ],

            // Step "Analysis"
            analysis: ['requestCollection.documentUuids', 'requestCollection.accompanyingAnalysisImageUuids'],

            // Step "Set Prices"
            setPrices: [
                'requestCollection.vehicleClasses',
                'pricesData.product.customPrices.purchaseUnitPrice',
                'pricesData.product.customPrices.retailUnitPrice',
                'pricesData.product.originalPrices.purchaseUnitPrice',
                'pricesData.product.originalPrices.retailUnitPrice',
            ],

            // Step "Summary"
            summary: ['requestCollection.note'],
        };
    },
};

export default getters;

<template>
    <div class="vehicle-row-mobile">
        <div class="vehicle-row-mobile__thumbnail">
            <Thumbnail
                v-if="vehicle.image && vehicle.image.url"
                :modal="!!vehicle.image"
                :src="vehicle.image && vehicle.image.url"
                class="carrier-information__thumbnail"
            />
            <TruckPlaceholderThumbnail v-else />
        </div>

        <div class="vehicle-row-mobile__info" @click="$emit('click', vehicle)">
            <span class="vehicle-row-mobile__top-line">
                <div class="vehicle-row-mobile__truck-icon">
                    <VehicleClassIconSet :icon="vehicle.vehicleClass.icon" height="25" />
                </div>
                <Words bold>{{ vehicle.vehicleClass.name }}</Words>
            </span>
            <Words tiny class="vehicle-row-mobile__info__headline">
                {{ $t('pages.vehicle.list.tableHeadings.emptyWeight') }}
            </Words>
            <Words>
                <KGIcon class="vehicle-row-mobile__info__icon" />
                {{ vehicle.emptyWeight }} kg
            </Words>
        </div>

        <div class="vehicle-row-mobile__info" @click="$emit('click', vehicle)">
            <span class="vehicle-row-mobile__top-line">
                <Words bold>{{ vehicle.licensePlate }}</Words>
            </span>
            <Words tiny class="vehicle-row-mobile__info__headline">
                {{ $t('pages.vehicle.list.tableHeadings.permissibleTotalWeight') }}
            </Words>
            <Words>
                <KGIcon class="vehicle-row-mobile__info__icon" />
                {{ vehicle.permissibleTotalWeight }} kg
            </Words>
        </div>

        <div class="vehicle-row-mobile__activity-status">
            <Words v-if="vehicle.isActive" green small bold>
                {{ $t('pages.vehicle.list.filter.statusActive') }}
            </Words>
            <Words v-else muted small bold>{{ $t('pages.vehicle.list.filter.statusInactive') }}</Words>
        </div>
    </div>
</template>

<script>
import Thumbnail from '@/components/Thumbnail';
import VehicleClassIconSet from '@/components/IconSet/VehicleClassIconSet';
import Words from '@/components/Typography/Words';
import TruckPlaceholderThumbnail from '@/pages/Vehicle/components/TruckPlaceholderThumbnail';

import KGIcon from '@/assets/icons/micro/kg.svg';

export default {
    name: 'VehicleRowMobile',

    components: {
        TruckPlaceholderThumbnail,
        Thumbnail,
        VehicleClassIconSet,
        Words,

        KGIcon,
    },

    props: {
        vehicle: { type: Object, required: true },
    },
};
</script>

<style lang="scss">
.vehicle-row-mobile {
    position: relative;
    display: flex;
    padding: 16px 0px;
    border-bottom: 1px solid $color-mediumGrey;
    background-color: $color-white;

    &:hover {
        background-color: $color-littleDarkerThanLightMediumGrey;
    }
}

.vehicle-row-mobile__thumbnail {
    display: flex;
    align-items: center;
    margin: 0px 16px;
}

.vehicle-row-mobile__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:last-of-type {
        margin-right: 16px;
    }
}

.vehicle-row-mobile__top-line {
    height: 54px;
    display: flex;
    align-items: center;
}

.vehicle-row-mobile__truck-icon {
    width: 68px;
}

.vehicle-row-mobile__info__headline {
    margin-bottom: 8px;
}

.vehicle-row-mobile__info__icon {
    margin-right: 6px;
}

.vehicle-row-mobile__activity-status {
    position: absolute;
    top: 8px;
    right: 12px;
}
</style>

<template>
    <SortingSelectBoxMobile
        :value="sortBy"
        :direction="sortDirection"
        :label="label || $t('pages.transportHub.actions.sortBy')"
        :sort-options="sortOptions"
        :option-label-renderer="optionLabelRenderer"
        primary
        light
        small
        hide-sort-arrow
        desktop-style-button-for-mobile
        :has-sort-set-options="hasSortSetOptions"
        @direction-update="setSortDirection"
        @sort-update="setSortBy"
        @input="setSortBy"
    />
</template>

<script>
import SortingSelectBoxMobile from '@/components/Form/SortingSelectBoxMobile';

export default {
    name: 'FilterSort',

    components: {
        SortingSelectBoxMobile,
    },

    props: {
        sortBy: {
            type: String,
            default: null,
        },

        sortDirection: {
            type: String,
            default: null,
        },

        sortOptions: {
            type: Array,
            default: () => [],
        },

        optionLabelRenderer: {
            type: Function,
            default: () => {
                return value => value;
            },
        },

        label: {
            type: String,
            default: null,
        },

        hasSortSetOptions: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        setSortBy(sortBy) {
            this.$emit('sort-by-update', sortBy);
            this.$emit('updated-sorting');
        },

        setSortDirection(sortDirection) {
            this.$emit('sort-direction-update', sortDirection);
            this.$emit('updated-sorting');
        },
    },
};
</script>

<style lang="scss"></style>

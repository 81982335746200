var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"waste-product-section"},[(!_vm.isEditMode)?_c('span',{staticClass:"font-copy-md-strong block"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.priceAdjustments.material.headline'))+" ")]):_vm._e(),(_vm.isBilledByStateWasteCompany && !_vm.isEditMode)?_c('SfMessageBox',{staticClass:"mb-6 mt-2"},[_vm._v(" "+_vm._s(_vm.$t('disposal.stateWasteCompany.billingByStateWasteCompany'))+" ")]):_vm._e(),_c('div',{staticClass:"card w-full text-left",class:{ 'mt-6': !_vm.isBilledByStateWasteCompany && !_vm.isEditMode }},[_c('div',{staticClass:"flex flex-wrap items-center"},[_c('div',{staticClass:"font-copy-md-strong"},[_vm._v(" "+_vm._s(_vm._f("tons")(_vm.requestCollection.qty))+" ")]),_c('WasteCode',{staticClass:"ml-4",attrs:{"font-size":"md","is-hazardous":_vm.isHazardous,"code":_vm.wasteMetaData.wasteCode}}),_c('SfTag',{staticClass:"ml-auto"},[_vm._v(" "+_vm._s(_vm.wasteMetaData.pollutionType.label)+" ")])],1),_c('span',{staticClass:"font-copy-sm mt-4"},[_vm._v(_vm._s(_vm.wasteMetaData.name))]),(_vm.isEditMode)?_c('span',{staticClass:"font-copy-md mt-8 text-subdued"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.priceAdjustments.transport.initialCalculatedPrices'))+" ")]):_vm._e(),_c('PriceRow',{class:{
                'mt-8': !_vm.isEditMode,
                'mt-4': _vm.isEditMode,
            },attrs:{"label":_vm.$t('general.purchasePrice.abbreviation'),"price-per-weight":_vm.purchaseUnitPrice,"show-both-prices":false}}),_c('PriceRow',{staticClass:"mt-2",attrs:{"label":_vm.$t('general.retailPrice.abbreviation'),"price-per-weight":_vm.retailUnitPrice,"show-both-prices":false}}),(
                _vm.isBilledByStateWasteCompany &&
                !_vm.isEditMode &&
                (_vm.priceIncludingStateWasteCompanyFee || !_vm.purchaseUnitPrice)
            )?_c('PriceRow',{staticClass:"mt-2",attrs:{"label":_vm.$t('pages.checkout.priceAdjustments.retailPriceIncludingStateWasteCompanyFee'),"price-per-weight":_vm.priceIncludingStateWasteCompanyFee,"show-both-prices":false}}):_vm._e(),(_vm.isEditMode)?[_c('HorizontalDivider',{staticClass:"my-8"}),_c('span',{staticClass:"font-copy-md text-subdued"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.priceAdjustments.newPrices'))+" ")]),_c('div',{staticClass:"mt-2 flex justify-between"},[_c('span',{staticClass:"font-copy-md-strong flex items-center"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.priceAdjustments.purchasePriceLabel'))+" ")]),_c('SfInput',{staticClass:"ml-4",attrs:{"size":"sm","type":"number","placeholder":"0","suffix":`${_vm.getCurrencySign()} / ${_vm.$t('units.ton')}`,"format-regex":_vm.validationRegEx.NUMBERS_COMMA_AND_DOTS,"value":_vm.customPurchaseUnitPriceInTons},on:{"input":function($event){return _vm.setPrice(+$event, 'purchaseUnitPrice')}}})],1),_c('div',{staticClass:"mt-2 flex justify-between"},[_c('span',{staticClass:"font-copy-md-strong flex items-center"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.priceAdjustments.retailPriceLabel'))+" ")]),_c('SfInput',{staticClass:"ml-4",attrs:{"size":"sm","type":"number","placeholder":"0","suffix":`${_vm.getCurrencySign()} / ${_vm.$t('units.ton')}`,"format-regex":_vm.validationRegEx.NUMBERS_COMMA_AND_DOTS,"value":_vm.customRetailUnitPriceInTons},on:{"input":function($event){return _vm.setPrice(+$event, 'retailUnitPrice')}}})],1)]:_vm._e(),(!!_vm.error)?_c('ErrorMessage',{attrs:{"message":_vm.error}}):_vm._e()],2),(!_vm.isEditMode)?_c('div',{staticClass:"mt-2 text-right"},[_c('BaseButton',{attrs:{"arrow-right":""},on:{"click":function($event){return _vm.$emit('edit')}}},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.priceAdjustments.waste.changeLabel'))+" ")])],1):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
    <div class="tag-list-fieldset">
        <GridRow :count="1">
            <div class="tag-list-fieldset__input-group">
                <Headline v-if="noLabel" :level="6">{{ $t('pages.tagManagement.tagListFieldset.headline') }}</Headline>
                <TextField
                    v-model="searchInput"
                    :error="getError('searchInput') || error"
                    :label="$t('pages.tagManagement.tagListFieldset.searchLabel')"
                    @blur="hidePredictions"
                    @focus="showPredictions"
                    @keydown="handleKeyInput"
                />
                <ul v-if="isPredictionsShown && predictions.length" class="tag-list-fieldset__suggestion-list">
                    <template v-for="(prediction, index) in predictions">
                        <li
                            v-if="prediction.type === 'new'"
                            :key="`${prediction.type}-${index}`"
                            :class="{ 'tag-list-fieldset__suggestion--active': highlightedIndex === index }"
                            class="tag-list-fieldset__suggestion tag-list-fieldset__suggestion--new"
                            @mouseover="highlightedIndex = index"
                            @click="createNewTag(searchInput)"
                        >
                            <Words bold>{{ searchInput }}</Words>
                            <Words small underlined>{{ $t('pages.tagManagement.tagListFieldset.addTag') }} ›</Words>
                        </li>
                        <li
                            v-else-if="prediction.type === 'used' && prediction.payload"
                            :key="`${prediction.type}-${index}`"
                            :class="{ 'tag-list-fieldset__suggestion--active': highlightedIndex === index }"
                            class="tag-list-fieldset__suggestion tag-list-fieldset__suggestion--used"
                            @mouseover="highlightedIndex = index"
                            @click.stop.prevent="clearPredictions() && clearValue()"
                        >
                            <Words bold>{{ revertLocalizedValue(prediction.payload.text) }}</Words>
                            <Words small muted unbreakable>
                                &nbsp;&nbsp;{{ $t('pages.tagManagement.tagListFieldset.tagInUse') }}
                            </Words>
                        </li>
                        <li
                            v-else
                            :key="`${prediction.type}-${index}`"
                            :class="{ 'tag-list-fieldset__suggestion--active': highlightedIndex === index }"
                            class="tag-list-fieldset__suggestion"
                            @mouseover="highlightedIndex = index"
                            @click="selectPrediction(prediction)"
                        >
                            <Words v-html="highlightMatch(revertLocalizedValue(prediction.text))" />
                            <Words block tiny muted v-html="assembleAdditionalValues(prediction.text)" />
                        </li>
                    </template>
                </ul>
            </div>
            <div class="flex gap-2">
                <SfTag v-for="tag in value" :key="tag.id" action @close-tag="removeTag(tag)">
                    {{ revertLocalizedValue(tag.text) }}
                </SfTag>
            </div>
        </GridRow>

        <TagManagementAction />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _throttle from 'lodash/throttle';
import _uniqBy from 'lodash/uniqBy';
import _remove from 'lodash/remove';
import TagApi from '@/services/Api/Platform/Tag';
import { revertLocalizedValue } from '@/services/utils/localization';
import validate from '@/services/validation/mixin';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';

import Headline from '@/components/Typography/Headline';
import Words from '@/components/Typography/Words';
import TextField from '@/components/Form/TextField.v2';
import GridRow from '@/components/Layout/GridRow';
import TagManagementAction from '@/pages/ProductManagement/components/Actions/TagManagementAction';

import { SfTag } from '@schuettflix/vue-components';

const UPDATE_PREDICTION_THROTTLE_MS = 1000;

export default {
    name: 'TagListFieldset',
    components: {
        Headline,
        Words,
        TextField,
        GridRow,
        TagManagementAction,
        SfTag,
    },
    mixins: [validate, eventHubMixin],
    props: {
        minListLength: {
            type: Number,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        value: {
            type: Array,
            default: () => [],
        },
        noLabel: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            searchInput: '',
            predictions: [],
            highlightedIndex: null,
            isPredictionsShown: false,
            hidePredictionsTimeout: null,
        };
    },
    computed: {
        ...mapGetters('i18n', ['availableLocales', 'locale']),
    },
    watch: {
        searchInput(value, oldValue) {
            if (value === '') {
                this.hidePredictions();
            } else if (value === oldValue || this.hidePredictionsTimeout !== null) {
                this.hidePredictions();
            } else {
                this.suggest(value);
            }
        },
    },
    created() {
        this.subscribe('tag.new', e => {
            this.addTag(e.subject);
        });
    },
    methods: {
        revertLocalizedValue,

        addTag(tag) {
            const tags = _uniqBy([...(this.value || []), tag], 'id');
            this.$emit('input', tags);
            this.$nextTick(() => {
                this.$set(this, 'searchInput', null);
            });
        },

        removeTag(tag) {
            if (!this.minListLength || (this.minListLength && this.minListLength < this.value.length)) {
                const tags = _remove([...this.value], item => {
                    return item.id !== tag.id;
                });
                this.$emit('input', tags);
            }
        },

        suggest(value) {
            this.$nextTick(function () {
                if (value) {
                    this.updatePredictions(value);
                }
            });
        },

        createNewTag(text) {
            this.$eventHub.$emit('tag.actions.newTag.open', text);

            this.clearPredictions();
            this.$nextTick(() => {
                this.searchInput = '';
            });
        },

        updatePredictions: _throttle(async function (value) {
            // cancel previous request
            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.cancelSource = TagApi.createCancelTokenSource();

            try {
                const tagList = this.value || [];
                const excludeIds = tagList.map(v => v.id);
                const result = await TagApi.filter(
                    {
                        startsWith: value,
                        exclude: excludeIds,
                        perPage: 5,
                    },
                    null,
                    null,
                    this.cancelSource
                );
                const predicitons = [...result.items];

                let hasExactMatch = false;
                let inUseExactMatch = null;
                predicitons.forEach(tag => {
                    if (revertLocalizedValue(tag.text).toLowerCase() === value.toLowerCase()) {
                        hasExactMatch = true;
                        return false;
                    }
                    return true;
                });

                tagList.forEach(tag => {
                    if (revertLocalizedValue(tag.text).toLowerCase() === value.toLowerCase()) {
                        inUseExactMatch = tag;
                        return false;
                    }
                    return true;
                });

                if (inUseExactMatch) {
                    predicitons.unshift({
                        type: 'used',
                        payload: inUseExactMatch,
                    });
                } else if (!hasExactMatch) {
                    predicitons.unshift({
                        type: 'new',
                    });
                }

                this.predictions = predicitons;
                this.showPredictions();
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }
        }, UPDATE_PREDICTION_THROTTLE_MS),

        clearValue() {
            this.$nextTick(() => {
                this.searchInput = '';
            });
        },

        clearPredictions() {
            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.predictions = [];
            this.highlightedIndex = null;
        },

        hidePredictions() {
            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.hidePredictionsTimeout = setTimeout(() => {
                this.highlightedIndex = null;
                this.isPredictionsShown = false;
                this.hidePredictionsTimeout = null;
            }, 200);
        },

        showPredictions() {
            this.isPredictionsShown = true;
            this.hidePredictionsTimeout && clearTimeout(this.hidePredictionsTimeout);
        },

        selectPrediction(prediction) {
            this.addTag(prediction);
            this.clearPredictions();
        },

        highlightMatch(string) {
            const searchString = (this.searchInput || '').trim();

            if (!searchString) {
                return string;
            }

            const pattern = RegExp(`(${searchString})`, 'gi');
            return string.replace(pattern, '<span class="tag-list-fieldset__search-highlight">$1</span>');
        },

        assembleAdditionalValues(lacalizedValue) {
            const list = [];
            Object.keys(lacalizedValue).forEach(key => {
                if (this.locale !== key && lacalizedValue[key]) {
                    list.push(lacalizedValue[key]);
                }
            });

            if (list.length === 0) {
                return '';
            }

            return this.highlightMatch(list.join(', '));
        },

        handleKeyInput(e) {
            if (this.predictions.length === 0) return;

            if (e.key === 'Enter') {
                this.selectHighlightedPrediction();
            }

            if (e.key === 'Escape') {
                e.preventDefault();
                this.clearPredictions();
            }

            if (e.key === 'ArrowDown') {
                this.highlightNextPrediction(e);
            }

            if (e.key === 'ArrowUp') {
                this.highlightPreviousPrediction(e);
            }
        },

        selectHighlightedPrediction() {
            const selected = this.predictions[this.highlightedIndex];
            if (selected && selected.type === 'new') {
                this.createNewTag(this.searchInput);
            } else if (selected && selected.type === 'used') {
                this.clearPredictions();
                this.clearValue();
            } else if (selected && !selected.type) {
                this.selectPrediction(selected);
            }
            this.hidePredictions();
        },

        highlightNextPrediction(e) {
            e && e.preventDefault();
            const length = this.predictions.length;
            const index = this.highlightedIndex !== null ? this.highlightedIndex : -1;
            if (index + 1 >= length) {
                this.highlightedIndex = 0;
            } else {
                this.highlightedIndex = index + 1;
            }
        },

        highlightPreviousPrediction(e) {
            e && e.preventDefault();
            const length = this.predictions.length;
            const index = this.highlightedIndex !== null ? this.highlightedIndex : -1;
            if (index - 1 < 0) {
                this.highlightedIndex = length - 1;
            } else {
                this.highlightedIndex = index - 1;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.tag-list-fieldset__submit-email {
    align-self: flex-start;
}

.tag-list-fieldset__input-group {
    position: relative;
}

.tag-list-fieldset__suggestion-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 200;
    background-color: $color-white;
    list-style: none;
    padding: 0;
    margin: 0;
    box-shadow: $boxShadow-bottom;
}

.tag-list-fieldset__suggestion {
    padding: 15px 10px;
}

.tag-list-fieldset__suggestion--active {
    background-color: $color-lightGrey;
}

.tag-list-fieldset__suggestion--new {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.tag-list-fieldset__search-highlight {
    font-weight: $font-weight-bold;
    color: $color-red;
}

.tag-list-fieldset__suggestion-additional-locales {
    margin-left: 10px;
}
</style>

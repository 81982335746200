<template>
    <div>
        <div
            class="checkbox-card"
            :class="checkboxStates"
            data-test="checkbox-card"
            tabindex="0"
            :aria-checked="isChecked"
            :aria-disabled="isDisabled"
            :aria-readonly="isReadOnly"
            role="checkbox"
            @click="toggle"
            @keyup.space.enter.prevent="toggle"
            @keypress.space.prevent
            @keydown.space.prevent
        >
            <div
                v-if="hasFullSizeIcon"
                class="checkbox-card__image checkbox-card__image--full-size"
                data-test="full-size-icon"
            >
                <slot name="icon" />
            </div>
            <div class="checkbox-card__body">
                <div class="checkbox-card__body__icon-section">
                    <div v-if="hasSmallIcon" class="checkbox-card__body__icon" data-test="small-icon">
                        <slot name="icon" />
                    </div>
                    <div
                        v-if="hasCheckbox"
                        class="checkbox-card__body__checkbox"
                        :class="{ 'checkbox-card__body__checkbox--checked': isChecked }"
                        data-test="checkbox"
                    >
                        <div v-if="isChecked" data-test="tick">
                            <CheckMarkIcon class="checkbox-card__check-icon" />
                        </div>
                    </div>
                </div>
                <div class="checkbox-card__body__text-section">
                    <Words v-if="title" bold data-test="title" class="checkbox-card__body__title-text">
                        {{ title }}
                    </Words>
                    <Words
                        v-if="description"
                        data-test="description"
                        class="checkbox-card__description"
                        small
                        spaced-top-tiny
                    >
                        {{ description }}
                    </Words>
                    <slot />
                </div>
            </div>
        </div>
        <Words v-if="!!error" small spaced-top-tiny block is-error data-test="error">{{ error }}</Words>
    </div>
</template>

<script>
import Words from '@/components/Typography/Words';

import CheckMarkIcon from '@/assets/icons/micro/tick.svg';

export default {
    name: 'CheckboxCard',
    components: {
        CheckMarkIcon,
        Words,
    },
    model: {
        prop: 'modelValue',
        event: 'change',
    },
    props: {
        // Value that will be added to model if selected
        checkboxValue: {
            type: [String, Number, Boolean],
            default: null,
        },

        // Model that contains all selected values
        modelValue: {
            type: [Array, Boolean],
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        isIconFullSize: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
    },
    computed: {
        isArrayModel() {
            return Array.isArray(this.modelValue);
        },
        isChecked() {
            if (this.isArrayModel) {
                return this.modelValue.includes(this.checkboxValue);
            }

            return !!this.modelValue;
        },
        checkboxStates() {
            return {
                'checkbox-card--is-checked': this.isChecked,
                'checkbox-card--has-error': !!this.error,
                'checkbox-card--is-disabled': this.isDisabled,
                'checkbox-card--is-readonly': this.isReadOnly,
            };
        },
        hasSmallIcon() {
            return this.$slots.icon && !this.isIconFullSize;
        },
        hasFullSizeIcon() {
            return this.$slots.icon && this.isIconFullSize;
        },
        hasCheckbox() {
            return !this.$slots.icon;
        },
    },
    methods: {
        toggle() {
            if (this.isReadOnly || this.isDisabled) {
                return;
            }

            let payload = null;

            if (this.isArrayModel) {
                payload = this.isChecked
                    ? this.modelValue.filter(value => value !== this.checkboxValue)
                    : [...this.modelValue, this.checkboxValue];
            } else {
                payload = !this.isChecked;
            }

            this.$emit('change', payload);
        },
    },
};
</script>

<style lang="scss" scoped>
.checkbox-card {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 16px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border: 2px solid transparent;
    transition: box-shadow 0.2s ease-in-out;
    cursor: pointer;

    &:not(&--is-disabled) {
        &:active,
        &:focus,
        &:focus-within {
            outline: 4px solid #9bc2c6;
        }
    }

    &:not(&--is-checked):not(&--is-readonly) {
        &:hover {
            box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
        }
    }

    &--is-readonly {
        cursor: not-allowed;
    }

    &--is-disabled#{&}--is-checked {
        border: 2px solid $sflx-grey-400;
    }

    &--is-checked {
        border-color: $sflx-grey-800;
        box-shadow: none;
    }

    &--has-error {
        border: 1px solid $sflx-red-800;
    }

    &--is-disabled {
        position: relative;
        box-shadow: none;

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(247, 247, 247, 0.6);
            cursor: not-allowed;
        }
    }

    &__body {
        display: flex;
        align-items: flex-start;
        gap: 16px;

        &__icon {
            > * {
                width: 24px;
                height: 24px;
            }
        }

        &__checkbox {
            border: 1px solid $sflx-grey-800;
            width: 24px;
            height: 24px;
            position: relative;

            &--checked {
                background-color: $sflx-grey-800;
            }
        }

        &__icon-section {
            display: flex;
            align-items: flex-start;
        }

        &__text-section {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__image {
        > * {
            max-width: 24px;
            max-height: 24px;
        }

        &--full-size {
            display: flex;
            justify-content: center;
            align-items: center;

            & > * {
                max-width: 80px;
                max-height: 100%;
            }
        }
    }

    &__check-icon {
        display: inline-block;
        background-color: transparent;
        width: 18px;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: $color-white;
    }
}
</style>

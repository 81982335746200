import { GenericPositionHTTPClient } from '@/modules/constructionProjects/services/GenericPositionHTTPClient';
export async function createGenericOrderByGenericPositionId(positionId) {
    return GenericPositionHTTPClient.post(`/v1/position/${positionId}/order`).then(res => res.data);
}
export function createGenericOrderByGenericPositionIdKey(positionId) {
    return ['generic-positions', positionId, 'generic-order', 'create'];
}
export function createGenericOrderByGenericPositionIdMutation(positionId) {
    return {
        mutationKey: createGenericOrderByGenericPositionIdKey(positionId),
        mutationFn: () => createGenericOrderByGenericPositionId(positionId),
    };
}

import clsx from 'clsx';
import { useIconSize } from '@/services/utils/useIconSize';
export function SfSysPlusIcon({ size, disabled, dark, className, ...svgProps }) {
    const iconSize = useIconSize(size);
    return (<svg width={iconSize} height={iconSize} viewBox="0 0 24 24" aria-hidden="true" fill="none" className={clsx({
            'stroke-icon': !dark && !disabled,
            'stroke-icon-dark': dark && !disabled,
            'stroke-icon-dark-disabled': disabled,
        }, className)} xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M12 2V21.9994M2 12H22" strokeWidth="4" strokeMiterlimit="10"/>
        </svg>);
}

import { useRoute } from '@/reactBridge/useRoute';
import { useQuery } from '@tanstack/react-query';
import { getOldProjectIdByUuid } from '@/constructionProjects/api/projects';
import { constructionProjectKeys } from '@/constructionProjects/queries';
import { useEffect, useState } from 'react';
export function useConstructionProjectRouteParams() {
    const currentRoute = useRoute();
    const [projectId, setProjectId] = useState();
    const projectUuid = currentRoute.params.id;
    const { data } = useQuery({
        queryKey: constructionProjectKeys.monolithProjectId(projectUuid),
        queryFn: () => getOldProjectIdByUuid(projectUuid),
        enabled: !!projectUuid,
    });
    useEffect(() => {
        setProjectId(data);
    }, [data]);
    return {
        projectId,
        projectUuid,
    };
}

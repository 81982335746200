import { deepMap } from '@/services/utils';
import _isEqual from 'lodash/isEqual';

function normalizeObjectSignature(obj) {
    return deepMap(obj, v => (v === '' ? null : v));
}

/**
 * Detect changes on object signatures
 *
 * Usage:
 * -----------------------------------
 *
 * const collection = {};
 *
 * computed: {
 *   ...detectSignatureChanges(collection, {
 *      hasUserChanged: 'user'
 *   })
 * }
 *
 * @param {Object} signatures
 * @param {Object} config
 */
export function detectSignatureChanges(signatures, config) {
    const computedConfig = {};

    Object.keys(config).forEach(computedGetter => {
        const prop = config[computedGetter];
        computedConfig[computedGetter] = function () {
            const normalized = normalizeObjectSignature(this[prop]);

            if (!signatures[prop]) {
                signatures[prop] = normalized;
                return false;
            }

            return !_isEqual(signatures[prop], normalized);
        };
    });

    return computedConfig;
}

/**
 * Update signature of a tracked state prop
 *
 * @param {Object} signatures
 * @param {string} prop
 * @param {Object} object
 */
export function updateChangedSignature(signatures, prop, object) {
    signatures[prop] = normalizeObjectSignature(object);
}

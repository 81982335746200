/* eslint-disable react-hooks/rules-of-hooks */
import { useRoute } from 'vue-router/composables';
import { useRouteContext } from '@/utils/composition-helper';
import { CONTEXT_CLIENT, CONTEXT_PLATFORM } from '@/constants/context';
import { useGetters } from 'vuex-composition-helpers';
export function useOrderScreenName(name) {
    const route = useRoute();
    const userType = useUserType();
    const orderType = getOrderType(route.path);
    return [userType, 'orderprocess', orderType, name].filter(Boolean).join('-');
}
function useUserType() {
    const { isPlatformAdministrator } = useGetters('user', ['isPlatformAdministrator']);
    const { inRouteContext } = useRouteContext();
    if (isPlatformAdministrator.value || inRouteContext(CONTEXT_PLATFORM)) {
        return CONTEXT_PLATFORM;
    }
    if (inRouteContext(CONTEXT_CLIENT)) {
        return CONTEXT_CLIENT;
    }
}
function getOrderType(path) {
    if (isProcessDelivery(path) || isProcessProjectDelivery(path) || isCustomDelivery(path)) {
        return 'delivery';
    }
    if (isProcessShipment(path) || isProcessProjectShipment(path) || isCustomShipment(path)) {
        return 'forwardingorder';
    }
    if (isProjectPositionDelivery(path)) {
        return 'projectorder-delivery';
    }
    if (isProjectPositionShipment(path)) {
        return 'projectorder-forwardingorder';
    }
    if (isProjectDisposalHazardous(path)) {
        return 'disposal-hazardouswaste';
    }
    if (isProjectPositionDisposal(path)) {
        return 'projectorder-disposal';
    }
    if (isProcessProjectPosition(path)) {
        return 'projectorder';
    }
    if (isProcessPickup(path)) {
        return 'pickup';
    }
}
function isProcessDelivery(path) {
    return path.includes('process/delivery');
}
function isProcessProjectDelivery(path) {
    return path.includes('process/project-delivery');
}
function isProcessShipment(path) {
    return path.includes('process/shipment');
}
function isProcessProjectShipment(path) {
    return path.includes('process/project-shipment');
}
function isProjectPositionDelivery(path) {
    return path.includes('project-position-delivery');
}
function isProjectPositionShipment(path) {
    return path.includes('project-position-shipment');
}
function isProjectPositionDisposal(path) {
    return path.includes('project-position-disposal');
}
function isProjectDisposalHazardous(path) {
    return path.includes('project-disposal-hazardous');
}
function isProcessProjectPosition(path) {
    return path.includes('process/project-position');
}
function isProcessPickup(path) {
    return path.includes('process/pickup');
}
function isCustomDelivery(path) {
    return path.includes('custom-delivery');
}
function isCustomShipment(path) {
    return path.includes('custom-shipment');
}

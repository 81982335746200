import { ref, computed, unref } from 'vue';
import flatten, { unflatten } from 'flat';
import { focusTopError } from '@/services/utils/dom';
import _get from 'lodash/get';
import _trim from 'lodash/trim';
import _last from 'lodash/last';
/**
 * Parse server validation
 */
export function parseServerValidation(err, mapping) {
    const data = _get(err, 'response.data.errors', {});
    const errors = {};
    const flatData = flatten(data);
    Object.keys(mapping).forEach(to => {
        let fromPatterns = mapping[to];
        if (!Array.isArray(fromPatterns)) {
            fromPatterns = [fromPatterns];
        }
        fromPatterns.forEach(from => {
            const fromRegex = new RegExp(`${from.replace(/\./g, '\\.').replace(/\*/g, '(.*)')}(\\..*)?`);
            let toIndex = 0;
            const toRegex = to.replace(/\*/g, () => {
                toIndex++;
                return `$${toIndex}`;
            });
            Object.keys(flatData).forEach(key => {
                if (!fromRegex.test(key))
                    return;
                const newKey = key.replace(fromRegex, toRegex);
                const subKey = _trim(_last(key.match(fromRegex)), '.');
                if (!errors[newKey]) {
                    errors[newKey] = [];
                }
                const newErrors = subKey
                    ? Object.values(unflatten({ [subKey]: flatData[key] }))
                    : Object.values(flatData[key]);
                errors[newKey] = [...errors[newKey], ...newErrors];
            });
        });
    });
    return errors;
}
/**
 * Validate configured rules and return errors object
 */
function validateState(ruleSet, context) {
    const errors = {};
    const plainRuleSet = unref(ruleSet);
    Object.keys(plainRuleSet).forEach(key => {
        const rules = plainRuleSet[key];
        const messages = rules
            .map(ruleCallback => {
            const target = _get(context, key);
            return ruleCallback.call(context, unref(target));
        })
            .filter(result => typeof result !== 'boolean');
        if (messages.length) {
            errors[key] = messages;
        }
    });
    return errors;
}
export default function useValidator(rules, context) {
    const errors = ref({});
    const isValid = computed(() => Object.keys(errors.value).length === 0);
    const clearValidation = () => {
        errors.value = {};
    };
    const clearSingleValidation = (key) => {
        delete errors.value[key];
    };
    const hasErrors = (key) => {
        return _get(errors.value, key, []).length > 0;
    };
    const getErrors = (key) => {
        return _get(errors.value, key, []);
    };
    const getAllErrors = () => errors.value;
    const getError = (key) => {
        return getErrors(key)[0] ?? null;
    };
    const hasAnyErrors = () => Object.keys(errors.value).length > 0;
    /**
     * Validate configured rules against the current data store
     * passive: do not apply errors to the data store
     */
    const validate = (passive = false) => {
        const validationErrors = validateState(rules, context);
        const errorCount = Object.keys(validationErrors).length;
        if (!passive) {
            errors.value = validationErrors;
            if (errorCount > 0) {
                focusTopError();
            }
        }
        return errorCount === 0;
    };
    /**
     * Partial validation, will apply errors to a given key
     */
    const validatePart = (key) => {
        const plainRules = unref(rules);
        if (!plainRules[key]) {
            return true;
        }
        const validationErrors = validateState({
            [key]: plainRules[key],
        }, context);
        if (validationErrors[key]) {
            errors.value[key] = validationErrors[key];
            return false;
        }
        else {
            const newErrors = { ...errors.value };
            delete newErrors[key];
            errors.value = newErrors;
            return true;
        }
    };
    const applyServerValidation = (err, mapping) => {
        errors.value = parseServerValidation(err, mapping);
    };
    return {
        clearValidation,
        clearSingleValidation,
        hasErrors,
        getErrors,
        getAllErrors,
        getError,
        validate,
        validatePart,
        hasAnyErrors,
        applyServerValidation,
        isValid,
        __errors__: errors,
    };
}

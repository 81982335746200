<template>
    <svg class="price-illustration" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330.89999 151.81999">
        <g id="price-ill__arrow-1" class="price-illustration--u--hide">
            <polyline class="price-illustration-5" points="129.8 15.77 135.36 10.21 140.92 15.77" />
            <line class="price-illustration-5" x1="135.38" y1="58.99" x2="135.38" y2="12.43" />
        </g>
        <rect
            id="price-ill__truck-background"
            class="price-illustration-10"
            x="109.82328"
            y="76.1253"
            width="211.07671"
            height="88.6947"
        />
        <g id="price-ill__street">
            <line class="price-illustration-6" x1="127.86" y1="139.53" x2="111.58" y2="139.53" />
            <line class="price-illustration-6" x1="309.69" y1="138.92999" x2="260.23999" y2="138.92999" />
            <line class="price-illustration-6" x1="247.5" y1="139.53" x2="136.33" y2="139.53" />
            <line class="price-illustration-6" x1="159.63" y1="150.39999" x2="287.23999" y2="150.39999" />
            <line class="price-illustration-6" x1="56.79" y1="128.83" x2="1.42" y2="128.83" />
            <path class="price-illustration-4" d="M37.78,123.43s2.74-11.62,18.17-8.88" />
        </g>
        <g id="price-ill__money">
            <g id="price-ill__geld-1">
                <path
                    class="price-illustration-1"
                    d="M125.73,116.7v11.56c0,3.76-14.52,6.83-32.46,6.86s-32.47-3-32.48-6.73V116.7"
                />
                <ellipse
                    class="price-illustration-2"
                    cx="93.27334"
                    cy="116.32534"
                    rx="32.47014"
                    ry="6.80003"
                    transform="translate(-0.24292 0.19519) rotate(-0.11975)"
                />
            </g>
            <g id="price-ill__geld-2" class="price-illustration--u--hide">
                <path
                    class="price-illustration-1"
                    d="M125.73,104.7v11.56c0,3.76-14.52,6.83-32.46,6.86s-32.47-3-32.48-6.73V104.7"
                />
                <ellipse
                    class="price-illustration-2"
                    cx="93.26826"
                    cy="104.32532"
                    rx="32.47014"
                    ry="6.80003"
                    transform="translate(-0.21784 0.19516) rotate(-0.11975)"
                />
            </g>
            <g id="price-ill__geld-3" class="price-illustration--u--hide">
                <path
                    class="price-illustration-1"
                    d="M125.73,92.7v11.56c0,3.76-14.52,6.83-32.46,6.86s-32.47-3-32.48-6.73V92.7"
                />
                <ellipse
                    class="price-illustration-2"
                    cx="93.26318"
                    cy="92.32529"
                    rx="32.47014"
                    ry="6.80003"
                    transform="translate(-0.19276 0.19512) rotate(-0.11975)"
                />
            </g>
            <g id="price-ill__geld-4" class="price-illustration--u--hide">
                <path
                    class="price-illustration-1"
                    d="M125.73,80.7V92.26c0,3.76-14.52,6.83-32.46,6.86s-32.47-3-32.48-6.73V80.7"
                />
                <ellipse
                    class="price-illustration-3"
                    cx="93.26844"
                    cy="80.32486"
                    rx="32.47014"
                    ry="6.80003"
                    transform="translate(-0.16803 0.19552) rotate(-0.12)"
                />
            </g>
            <g id="price-ill__geld-5" class="price-illustration--u--hide">
                <path
                    class="price-illustration-1"
                    d="M125.73,68.7V80.26c0,3.76-14.52,6.83-32.46,6.86s-32.47-3-32.48-6.73V68.7"
                />
                <ellipse
                    class="price-illustration-3"
                    cx="93.26332"
                    cy="68.32483"
                    rx="32.47014"
                    ry="6.80003"
                    transform="translate(-0.14289 0.19548) rotate(-0.12)"
                />
            </g>
            <g id="price-ill__geld-6" class="price-illustration--u--hide">
                <path
                    class="price-illustration-1"
                    d="M125.73,56.7V68.26c0,3.76-14.52,6.83-32.46,6.86s-32.47-3-32.48-6.73V56.7Z"
                />
                <ellipse
                    class="price-illustration-3"
                    cx="93.26819"
                    cy="56.3248"
                    rx="32.47014"
                    ry="6.80003"
                    transform="translate(-0.11776 0.19546) rotate(-0.12)"
                />
            </g>
            <g id="price-ill__geld-7" class="price-illustration--u--hide">
                <path
                    class="price-illustration-1"
                    d="M125.73,44.7V56.26c0,3.76-14.52,6.83-32.46,6.86s-32.47-3-32.48-6.73V44.7"
                />
                <ellipse
                    class="price-illustration-3"
                    cx="93.26305"
                    cy="44.32478"
                    rx="32.47014"
                    ry="6.80003"
                    transform="translate(-0.09263 0.19543) rotate(-0.12)"
                />
            </g>
        </g>
        <circle id="price-ill__circle-1" class="price-illustration-4" cx="118.44" cy="20.91" r="4.9" />
        <circle id="price-ill__circle-2" class="price-illustration-4" cx="303.72" cy="42.04" r="4.9" />
        <g id="price-ill__cross-1">
            <line class="price-illustration-4" x1="173.67999" y1="39.64" x2="173.67999" y2="25.93" />
            <line class="price-illustration-4" x1="166.83" y1="32.79" x2="180.53999" y2="32.79" />
        </g>
        <g id="price-ill__cross-2">
            <line class="price-illustration-4" x1="39.93" y1="85.32" x2="49.62" y2="95.01" />
            <line class="price-illustration-4" x1="49.62" y1="85.32" x2="39.93" y2="95.01" />
        </g>
        <g id="price-ill__arrow-2" class="price-illustration--u--hide">
            <polyline class="price-illustration-5" points="69.33 7.04 74.9 1.48 80.46 7.04" />
            <line class="price-illustration-5" x1="74.92" y1="28.97" x2="74.92" y2="3.7" />
        </g>
        <PriceIllustrationTruck id="price-ill__truck" :axes="axes" />
        <g id="price-ill__euro" class="price-illustration--u--hide">
            <ellipse class="price-illustration-8" cx="49.63" cy="103.99" rx="32.44" ry="32.6" />
            <ellipse class="price-illustration-9" cx="49.63" cy="103.99" rx="26.19" ry="26.32" />
        </g>
    </svg>
</template>

<script>
import {
    showElement,
    toggleElement,
    triggerWeight,
    upperMovement,
    positionTopPercent,
} from '@/services/Animation/utils.js';
import PriceIllustrationTruck from '@/pages/Checkout/components/ShipmentPriceAdjustments/PriceIllustrationTruck';

export default {
    name: 'PriceIllustration',
    components: {
        PriceIllustrationTruck,
    },

    props: {
        progress: {
            type: Number,
            default: 0,
        },

        axes: {
            type: Number,
            default: 2,
            validator: v => [2, 3, 4, 5].includes(v),
        },
    },

    data() {
        return {
            stage: 0,
            movingArrow: false,
        };
    },

    watch: {
        progress: {
            immediate: true,
            handler() {
                this.updateStage();
                this.updateFirstArrow();
            },
        },
        stage: {
            immediate: true,
            handler(next, prev) {
                this.updateView(next, prev);
            },
        },
    },

    created() {
        // Wait until the stage is ready
        setTimeout(() => {
            this.updateStage();
            this.updateFirstArrow();
            this.updateView(this.stage, 0);
        }, 1000);
    },

    methods: {
        updateStage() {
            const p = this.progress;
            let stage = 1;

            // Tune stages
            stage = p > 10 ? 2 : stage;
            stage = p > 20 ? 3 : stage;
            stage = p > 30 ? 4 : stage;
            stage = p > 40 ? 5 : stage;
            stage = p > 50 ? 6 : stage;
            stage = p > 60 ? 7 : stage;
            stage = p > 70 ? 8 : stage;
            stage = p > 80 ? 9 : stage;
            stage = p > 90 ? 10 : stage;

            this.stage = stage;
        },

        updateView(next, prev = 0) {
            const isIncreasing = prev < next;

            toggleElement('#price-ill__geld-2', next > 1);
            toggleElement('#price-ill__geld-3', next > 2);
            toggleElement('#price-ill__geld-4', next > 3);
            toggleElement('#price-ill__geld-5', next > 4);
            toggleElement('#price-ill__geld-6', next > 5);
            toggleElement('#price-ill__geld-7', next > 7);

            toggleElement('#price-ill__euro', next > 9);
            showElement('#price-ill__arrow-2');

            if (isIncreasing && next > 1 && next < 8) {
                triggerWeight('#price-ill__money');
            }

            toggleElement('#price-ill__arrow-1', next > 7);
            if (isIncreasing && next > 7) {
                this.upperMovementArrow();
            }
        },

        updateFirstArrow() {
            const remappedPercent = Math.min((100 / 70) * this.progress, 100);
            positionTopPercent('#price-ill__arrow-2', remappedPercent, 0, 70);
        },

        async upperMovementArrow() {
            if (this.movingArrow) return;
            this.movingArrow = true;
            await upperMovement('#price-ill__arrow-1');
            this.movingArrow = false;
        },
    },
};
</script>

<style lang="scss">
.price-illustration-1,
.price-illustration-2,
.price-illustration-3,
.price-illustration-8,
.price-illustration-10 {
    fill: #fff;
}

.price-illustration-1,
.price-illustration-2,
.price-illustration-3,
.price-illustration-4,
.price-illustration-5,
.price-illustration-7,
.price-illustration-8,
.price-illustration-9 {
    stroke: red;
}

.price-illustration-1,
.price-illustration-2,
.price-illustration-3,
.price-illustration-4,
.price-illustration-5,
.price-illustration-6,
.price-illustration-7,
.price-illustration-8,
.price-illustration-9 {
    stroke-linecap: round;
    stroke-linejoin: round;
}

.price-illustration-1,
.price-illustration-4 {
    stroke-width: 2.88px;
}

.price-illustration-2 {
    stroke-width: 2.88001px;
}

.price-illustration-3 {
    stroke-width: 2.88001px;
}

.price-illustration-4,
.price-illustration-5,
.price-illustration-6,
.price-illustration-7,
.price-illustration-9 {
    fill: none;
}

.price-illustration-5 {
    stroke-width: 2.96px;
}

.price-illustration-6 {
    stroke: #000;
    stroke-width: 2.84px;
}

.price-illustration-7 {
    stroke-width: 3px;
}

.price-illustration-8,
.price-illustration-9 {
    stroke-width: 2.91px;
}

.price-illustration--u--hide {
    display: none;
}
</style>

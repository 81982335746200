var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"loading-input-message grid grid-cols-[80px_1fr] items-center justify-center gap-6 bg-surface p-4",class:[
        {
            'border-b-4': _vm.hasValidationResponse,
            'border-success': _vm.isSuccess,
            'border-warning': _vm.isWarning,
            'border-critical': _vm.isError,
        },
    ],attrs:{"data-test":"loading-input-message"}},[_c(_vm.icon,{tag:"component",staticClass:"-mx-2 -my-4 w-[112px]"}),_c('div',[_c('p',{staticClass:"font-copy-md-strong"},[_vm._v(_vm._s(_vm.headline))]),(_vm.content)?_c('p',{staticClass:"font-copy-md"},[_vm._v(" "+_vm._s(_vm.content)+" ")]):_vm._e(),(_vm.showPhoneNumber)?_c('SfLink',{staticClass:"mt-2 !flex",attrs:{"href":`tel:${_vm.assemblePhoneNumber(_vm.info.phone)}`,"bold":""}},[_vm._v(" "+_vm._s(_vm.formatPhoneNumber(_vm.info.phone))+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { MaterialIcon, WarningIcon } from '@schuettflix/icons-react';
import { useTranslation } from 'react-i18next';
import { Link, LoadingSpinner, Tag } from '@schuettflix/react-components';
import { differenceInCalendarDays } from 'date-fns';
import clsx from 'clsx';
import { Button } from '@schuettflix/planum-react';
import { useCreateGenericOrderByPositionAndForward } from '../../hooks/useCreateGenericOrderByGenericPositionAndForward';
const REMAINING_VALIDITY_THRESHOLD_IN_DAYS = 7;
function _GenericPositionListBlock({ genericPosition }) {
    const { t } = useTranslation();
    const createGenericOrder = useCreateGenericOrderByPositionAndForward(genericPosition.id);
    const remainingValidityInDays = () => {
        if (!genericPosition.validTo) {
            return 0;
        }
        return Math.max(0, differenceInCalendarDays(genericPosition.validTo, new Date()) + 1);
    };
    const showRemainingValidity = () => {
        if (!genericPosition.validTo || !genericPosition.active) {
            return false;
        }
        return remainingValidityInDays() <= REMAINING_VALIDITY_THRESHOLD_IN_DAYS;
    };
    const statusTag = () => {
        const isActive = genericPosition.active;
        const status = genericPosition.status;
        return {
            type: status === 'CLOSED' ? 'error' : isActive ? 'positive' : 'default',
            label: status === 'CLOSED'
                ? 'status.projectPosition.closed'
                : isActive
                    ? 'status.projectPosition.open'
                    : 'status.projectPosition.inactive',
        };
    };
    const remainingValidityLabel = () => {
        // react-i18n seems to have issues resolving zero translation key in this vuetified component
        return remainingValidityInDays() === 0
            ? t('components.ProjectRemainingValidityTag.remainingDays.zero')
            : t('components.ProjectRemainingValidityTag.remainingDays', {
                count: remainingValidityInDays(),
            });
    };
    return (<div className="overflow-hidden shadow-low">
            {showRemainingValidity() && (<div className="flex items-center justify-center bg-surface-warning py-2">
                    <WarningIcon size="xxs" className="mr-1"/>
                    <span className="font-copy-sm">{remainingValidityLabel()}</span>
                </div>)}
            <div className="bg-surface p-6">
                <div className="flex flex-wrap lg:flex-nowrap lg:border-b lg:pb-6 lg:pt-4">
                    <div className={clsx('order-2 basis-full border-b border-b-light-gray-200 pb-6', 'lg:order-1 lg:basis-auto lg:border-b-0 lg:py-0 lg:pl-0 lg:pr-6')}>
                        <span className="font-copy-xs mb-1">
                            {t('components.projectPositionListBlock.label.number')}
                        </span>
                        <span className="font-copy-md-strong">{genericPosition.positionNumber}</span>
                    </div>

                    <div className="order-1 flex basis-full justify-end gap-4 gap-x-4 lg:grow lg:basis-auto lg:items-start">
                        <Tag className="whitespace-nowrap" type={statusTag().type} label={t(statusTag().label)}/>
                    </div>
                </div>
                <div className="grid grid-cols-1 items-center gap-4 pt-6 lg:grid-cols-[minmax(0,9fr)_3fr]">
                    <div className="flex items-center">
                        <div>
                            <MaterialIcon size="sm" monochrome className="mr-4"/>
                        </div>
                        <span className="font-copy-md mr-4 align-middle">{genericPosition.leadingProduct}</span>
                    </div>

                    <span className="font-copy-md ml-auto hidden gap-4 whitespace-nowrap lg:flex">
                        <Link className="font-copy-md-strong" label={t('components.projectPositionListBlock.actions.openOrder', {
            count: genericPosition.orderCount,
        })}/>
                        {genericPosition.status !== 'OPEN' && (<Button prominence="secondary" stature="md" leadingSlot={createGenericOrder.isLoading ? <LoadingSpinner /> : undefined} onPress={createGenericOrder.mutateAsync} isDisabled={!genericPosition.active || createGenericOrder.isLoading}>
                                {t('pages.genericPosition.details.startOrder')}
                            </Button>)}
                    </span>
                </div>
                {genericPosition.note && (<div className="mt-6 border-t pt-4">
                        <span className="font-copy-xs mb-1">{t('components.projectPositionListBlock.label.note')}</span>
                        <span className="font-copy-md truncate whitespace-nowrap">{genericPosition.note}</span>
                    </div>)}
            </div>
        </div>);
}
export const GenericPositionListBlock = asVueComponent(_GenericPositionListBlock);

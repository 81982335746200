import { ComboBox } from '@schuettflix/planum-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { netsuitePaymentTermsQuery } from '@/reactBridge/queryClient';
export const PaymentTermsSelection = ({ organizationType = 'client', showLabel = true, paymentTermId, onChange, ...props }) => {
    const { t } = useTranslation();
    const paymentTermsQueryResult = useQuery({
        ...netsuitePaymentTermsQuery,
        suspense: true,
    });
    if (!paymentTermsQueryResult.data?.paymentTerms?.client)
        return null;
    const paymentTerms = paymentTermsQueryResult.data.paymentTerms[organizationType] || paymentTermsQueryResult.data.paymentTerms.client;
    if (!paymentTermId && paymentTermsQueryResult.data?.marketDefaultPaymentTermIds) {
        onChange(paymentTermsQueryResult.data.marketDefaultPaymentTermIds[organizationType]);
    }
    const getOrganizationTypeTitle = (type = 'client') => {
        return {
            supplier: 'paymentTerms.organizationTypes.supplier',
            carrier: 'paymentTerms.organizationTypes.carrier',
            client: 'paymentTerms.organizationTypes.client',
        }[type];
    };
    return (<>
            {showLabel && (<span className="font-copy-md-strong mb-2">{t(getOrganizationTypeTitle(organizationType))}</span>)}
            <ComboBox menuTrigger="focus" label={t('paymentTerms.title')} isDisabled={props.disabled} selectedKey={paymentTermId} onSelectionChange={key => {
            if (typeof key === 'string') {
                return;
            }
            onChange(key);
        }}>
                {paymentTerms.map(paymentTerm => (<ComboBox.Option key={paymentTerm.id} id={paymentTerm.id} label={`${paymentTerm.description} (${paymentTerm.externalId})`} textValue={`${paymentTerm.description} (${paymentTerm.externalId})`}/>))}
            </ComboBox>
        </>);
};

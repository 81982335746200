<template>
    <pre class="text-block" v-text="text" />
</template>

<script>
export default {
    name: 'TextBlock',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.text-block {
    margin: 0;
    font-family: inherit;
    white-space: pre-wrap;
}
</style>

<template>
    <LayoutPage is-flyout :screen-name="screenName" class="checkout-shipment-billing-details-page">
        <Header slot="flyoutHeader" />

        <div class="container-off-canvas-sm my-6">
            <p class="font-copy-md-strong mb-2">{{ $t('pages.checkout.shipmentBillingDetails.subtitle') }}</p>
            <p class="font-copy-md mb-6">{{ $t('pages.checkout.shipmentBillingDetails.description') }}</p>

            <RadioButtonCard
                class="mb-4"
                name="choose-billing-type-selection"
                value="fixedPrice"
                data-test="radio-price-per-job"
                :is-disabled="isBillingMethodTransportDisabled"
                :model-value="form.billingMethod"
                :title="$t('pages.checkout.shipmentBillingDetails.billingMethodLabel.fixedPrice')"
                :description="$t('pages.checkout.shipmentBillingDetails.billingMethodDescription.fixedPrice')"
                :error="errors.billingMethod"
                @change="updateBillingMethod"
            />
            <RadioButtonCard
                class="mb-4"
                name="choose-billing-type-selection"
                value="weight"
                data-test="radio-price-by-weighing"
                :model-value="form.billingMethod"
                :title="$t('pages.checkout.shipmentBillingDetails.billingMethodLabel.weight')"
                :description="$t('pages.checkout.shipmentBillingDetails.billingMethodDescription.weight')"
                @change="updateBillingMethod"
            />
            <ErrorMessage v-if="errors.billingMethod" :message="errors.billingMethod" />
            <slot name="additionalContent" :form="form" />
        </div>

        <SlideUp slot="sticky" active>
            <ButtonGroup>
                <Button
                    :disabled="!isValid || isLoading"
                    :is-loading="isLoading"
                    primary
                    type="button"
                    data-test="button-set-billing-type"
                    @click="submit()"
                >
                    {{ $t('pages.checkout.shipmentBillingDetails.nextPage') }}
                </Button>
            </ButtonGroup>
        </SlideUp>
    </LayoutPage>
</template>

<script>
import statefulMixin from '@/plugins/mixins/statefulMixin';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ErrorMessage from '@/components/Form/ErrorMessage';
import RadioButtonCard from '@/_components/RadioButtonCard/RadioButtonCard';
import Header from '@/pages/Checkout/components/Header';
import LayoutPage from '@/components/Layout/Page.v2';
import SlideUp from '@/components/Animation/SlideUp';
import { useOrderScreenName } from '../../analytics/vue/useOrderScreenName';

export default {
    name: 'ShipmentBillingDetailsPage',

    components: {
        Button,
        ButtonGroup,
        ErrorMessage,
        RadioButtonCard,
        SlideUp,

        LayoutPage,
        Header,
    },

    mixins: [statefulMixin],

    props: {
        billingMethod: {
            type: String,
            default: null,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
        isBillingMethodTransportDisabled: {
            type: Boolean,
            default: false,
        },
    },

    setup() {
        return {
            screenName: useOrderScreenName('billingselection'),
        };
    },

    data() {
        return {
            form: {
                billingMethod: this.billingMethod,
            },
        };
    },

    watch: {
        form: {
            deep: true,
            immediate: false,
            handler(newForm) {
                this.$emit('form-change', newForm);
            },
        },
    },

    methods: {
        async submit() {
            if (!this.isValid) return;

            this.$emit('submit', {
                payload: {
                    billingMethod: this.billingMethod,
                },
            });
        },

        updateBillingMethod(value) {
            this.form.billingMethod = value;
        },
    },
};
</script>

import axios from 'axios';
import { accessToken, userLocale, marketCode } from '@/sflxAppsSharedStates';
export const CustomRequestHTTPClient = axios.create({
    baseURL: window.baseUrlCustomRequest,
    headers: {
        'Accept-Language': userLocale.value,
        Authorization: `Bearer ${accessToken.value}`,
        'X-Client-Version': process.env.VUE_APP_VERSION,
        'X-Operating-Market': marketCode.value,
    },
});
accessToken.onUpdate(token => {
    CustomRequestHTTPClient.defaults.headers['Authorization'] = `Bearer ${token}`;
});
userLocale.onUpdate(locale => {
    CustomRequestHTTPClient.defaults.headers['Accept-Language'] = locale;
});
marketCode.onUpdate(marketCode => {
    CustomRequestHTTPClient.defaults.headers['X-Operating-Market'] = marketCode;
});

import screens from '@/utils/tailwindScreens';
import { useEffect, useState } from 'react';
export const useBreakpoints = () => {
    const screenWidth = useWindowWidth();
    return {
        isGreater: (breakpoint) => {
            return screenWidth > screens.realScreensAsNumbers[breakpoint];
        },
        isSmallerOrEqual: (breakpoint) => {
            return screenWidth <= screens.realScreensAsNumbers[breakpoint];
        },
        isGreaterOrEqual: (breakpoint) => {
            return screenWidth >= screens.realScreensAsNumbers[breakpoint];
        },
    };
};
const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return width;
};

import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { FlyoutPage } from '@/constructionProjects/components/FlyoutPage';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { ConstructionProjectAdminCreateForm } from '@/constructionProjects/pages/ConstructionProjectCreate/ConstructionProjectAdminCreateForm';
import { LoadingSpinner } from '@schuettflix/react-components';
import { CONSTRUCTION_PROJECT_ADMIN_LIST_ROUTE } from '@/modules/constructionProjects/pages/routes';
const _PlatformAdminCreateConstructionProjectFlyout = () => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    return (<FlyoutPage screenName="platform-constructionproject-create" onCloseAnimationEnd={() => vueRouter.back()} headerTitle={t('pages.constructionProject.createForm.header.title')} body={<Suspense fallback={<LoadingSpinner block spaced/>}>
                    <ConstructionProjectAdminCreateForm onSubmit={() => {
                vueRouter.push({
                    name: CONSTRUCTION_PROJECT_ADMIN_LIST_ROUTE,
                });
            }} onCancel={() => vueRouter.back()}/>
                </Suspense>}/>);
};
export const ConstructionProjectAdminFlyout = asVueComponent(_PlatformAdminCreateConstructionProjectFlyout);

import { forwardRef } from 'react';
// TODO extract to component library, this is just temporary until icon PR is merged
export const AvatarIcon = forwardRef(({ className, ...props }, ref) => {
    return (<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 128 128" xmlSpace="preserve" className={className} ref={ref} {...props}>
            <path className="f-highlight" fill="#f00" d="M64,0C28.7,0,0,28.7,0,64c0,17.2,6.8,32.8,17.8,44.3c2.5-11.5,12.1-20.4,24-21.8c5.3,6.1,12.8,10.5,23,10.5
	c10.2,0,17.7-4.4,23.1-10.3c11.3,1.4,20.5,9.7,23.5,20.3C121.7,95.6,128,80.5,128,64C128,28.7,99.3,0,64,0z M65.3,91.5
	c-23,0-25.9-31.4-25.9-31.4h52.2C91.5,60.2,88.3,91.5,65.3,91.5z M99.2,52.4c0,1.2-1,2.1-2.1,2.1H33.2c-1.2,0-2.1-1-2.1-2.2V49
	c0-1.2,1-2.1,2.1-2.1h4.3c0-0.3,0-0.5,0-0.7c0-11.1,6.4-20.7,15.8-25.3l3.9,14.5l4.1-1.2l-4.2-14.9c0.1,0,0.1,0,0.1,0
	c0.3-2.3,2.5-4.3,4.9-4.3h7.3c2.3,0,4.5,1.8,4.8,4.2l-4.3,15.1l4.1,1.2L78,20.9c9.3,4.6,15.7,14.2,15.7,25.3c0,0.3,0,0.5,0,0.7h3.4
	c1.2,0,2.1,1,2.1,2.1V52.4z"/>
            <circle id="path-3_1_" style={{ display: 'none', fill: 'none', stroke: '#35343D', strokeOpacity: '0.1' }} cx="128.5" cy="-0.5" r="64"/>
        </svg>);
});
AvatarIcon.displayName = 'AvatarIcon';

import _cloneDeep from 'lodash/cloneDeep';
import _isObject from 'lodash/isObject';
import _isArray from 'lodash/isArray';
import _merge from 'lodash/merge';
import LogService from '@schuettflix/util-log';

const Log = new LogService('services/utils');

/**
 * Transform array notation to object notation where the key is the route name
 *
 * @param {Array|Object} reusableRoutes
 */
export function prepareIncludes(reusableRoutes) {
    if (!reusableRoutes) return {};

    if (!_isArray(reusableRoutes) && _isObject(reusableRoutes)) {
        return reusableRoutes;
    }

    const routesMap = {};

    reusableRoutes.forEach(route => {
        if (!route.name) {
            throw new Error('Routes must have a name');
        }
        routesMap[route.name] = route;
    });

    return routesMap;
}

/**
 * Replace include placeholder with it's apropriate component,
 * or remove them if no component for that key is provided
 *
 * @param {object} reusableRoutes
 * @param {array} routes
 * @param {array} processedRoutes
 */
export function replaceIncludes(reusableRoutes, routes, processedRoutes = []) {
    const processedRoutesArray = [...processedRoutes];

    if (!_isObject(reusableRoutes)) {
        throw new Error('reusableRoutes must be in { route-name: {name: route-name} } format');
    }

    if (!Array.isArray(routes)) {
        throw new Error('routes must be provided as an array');
    }

    const routeList = [];

    routes.forEach(route => {
        // add route names as well
        if (!processedRoutesArray.includes(route.name)) {
            processedRoutesArray.push(route.name);
        }

        if (route.include) {
            const replacement = reusableRoutes[route.include];

            if (!replacement) {
                throw new Error(`Include for "${route.include}" not provided`);
            }

            // do not include recursive (child) includes
            if (!processedRoutesArray.includes(route.include)) {
                processedRoutesArray.push(route.include);
                route = _cloneDeep(replacement);
            } else {
                return;
            }
        }

        // apply includes on childrens children
        if (route.children && route.children.length) {
            route.children = replaceIncludes(reusableRoutes, route.children, processedRoutesArray);
        }

        routeList.push(route);
    });

    return routeList;
}

/**
 * Assemble route names
 * @param {object} route
 * @param {string} namespace
 */
export function assembleRouteNames(route, namespace = null) {
    if (!_isObject(route)) {
        throw new Error('We literally need a route for that');
    }

    // generated names with proper namespace
    const routeName = route.name;
    let name = null;
    if (routeName !== undefined && routeName !== 'root') {
        name = namespace ? `${namespace}__${routeName}` : routeName;
    }

    // iterate recoursive over all children
    if (route.children && route.children.length) {
        route.children = route.children.map(child => {
            return assembleRouteNames(child, name !== null ? name : namespace);
        });
    }

    if (name != null) {
        route.name = name;
    } else if (routeName !== 'root') {
        delete route.name;
    }

    return route;
}

/**
 * Generate route trees
 * - resolve includes
 *
 * @param {array|object} reusableRoutes
 * @param {array} routes
 */
export function resolveIncludes(reusableRoutes, routes) {
    // key value mapping based on route names
    const preparedReusableRoutes = prepareIncludes(reusableRoutes);

    // replace incudes with actual routes
    const preparedRoutes = replaceIncludes(preparedReusableRoutes, routes);

    // generate names based on tree
    const namedRoutes = preparedRoutes.map(item => assembleRouteNames(item));

    return assignParents(namedRoutes);
}

/**
 * @param {Array} routes
 * @param {Object|null} parent
 * @return {Array}
 */
export function assignParents(routes, parent = null) {
    if (!routes) return [];

    const nextRoutes = [];

    routes.forEach(route => {
        const next = {
            ...route,
            parent: parent,
        };
        next.children = assignParents(route.children, next);
        nextRoutes.push(next);
    });

    return nextRoutes;
}

/**
 * Find current route in tree
 *
 * @param {array} routes
 * @param {string} currentRouteName
 */
export function getCurrentRoute(routes, currentRouteName) {
    let node = null;

    for (const route of routes) {
        // if route name is not included in needle?
        if (route.name !== 'root' && !currentRouteName.match(`^${route.name}`)) {
            continue;
        }

        // is it exactly what we are searching?
        if (route.name === currentRouteName) {
            node = route;
            break;
        }

        // are there children? - continue searching
        if (route.children && route.children.length) {
            node = getCurrentRoute(route.children, currentRouteName);
            if (node !== null) {
                break;
            }
        }
    }

    return node;
}

/**
 * Get next possible route
 * - look back
 * - look ahead
 *
 * @param {array} routes
 * @param {string} currentRouteName
 * @param {string} partialName
 */
export function getNextRoute(routes, currentRouteName, partialName) {
    const currentRoute = getCurrentRoute(routes, currentRouteName);
    if (currentRoute === null) {
        throw new Error(`Current route could not be found in routes (${currentRouteName})`);
    }

    // Does the current route match the searching route?
    if (currentRouteName.match(`${partialName}$`)) {
        return currentRoute;
    }

    // recursive start

    let searchStart = currentRoute;
    let routeMatch = null;
    let iterations = 0;

    do {
        if (searchStart.name.match(`${partialName}$`)) {
            routeMatch = searchStart;
            break;
        }

        routeMatch = findRouteAhead(searchStart, partialName);

        iterations++;
        if (iterations > 100) {
            throw new Error('Exceeded iterations');
        }

        searchStart = searchStart.parent;
    } while (routeMatch === null && searchStart);

    // global fallback
    if (routeMatch === null) {
        for (const i in routes) {
            const route = routes[i];

            routeMatch = findRouteAhead(route, partialName);

            if (routeMatch !== null) {
                break;
            }
        }
    }

    return routeMatch;
}

/**
 * Find route in child generation
 * !!! Atention: shorter route goes first!
 * home__order-view before home__transport-view__order-view
 *
 * @param {object} route
 * @param {string} partialName
 */
function findRouteAhead(route, partialName) {
    let node = null;

    if (route.children && route.children.length) {
        // access map
        const table = flattenTable(route.children, 'name', 'children');

        // find route name
        Object.keys(table)
            .sort((a, b) => a.length - b.length)
            .some(key => {
                const item = table[key];
                if (key.match(`${partialName}$`)) {
                    node = item;
                    return true;
                }
                return false;
            });
    }

    return node;
}

/**
 * Flatten a collection to access table
 *
 * @param {array} collection
 * @param {string} key
 * @param {string} childKey
 * @param {object} table
 */
export function flattenTable(collection, key, childKey, table = null) {
    table = table || {};

    collection.forEach(item => {
        table[item[key]] = item;
        const children = item[childKey];
        if (children) {
            flattenTable(children, key, childKey, table);
        }
    });

    return table;
}

/**
 * Decorate a route, and modify it's properties
 * returns a new copy of the object
 *
 * @param {*} routeConfig
 * @param {*} additionalConfig
 */
export function decorateRoute(routeConfig, additionalConfig = {}) {
    // Requires cloneDeep anyway for multiple usage of same route
    return _merge(_cloneDeep(routeConfig), additionalConfig);
}

/**
 * Catch and log unexpected navigation failure
 *
 * @param {Error|null} failure
 */
export function navigationFailure(failure) {
    Log.error(failure?.message || 'Unexpected navigation failure');
}

<template>
    <article class="checkout-price-adjustments__vehicle-class mt-6 first:mt-0">
        <div class="card">
            <header
                :class="['flex items-start justify-between', { 'mb-8': !position.creditTo, 'mb-4': position.creditTo }]"
            >
                <h3 class="font-copy-md-strong">{{ position.qty }} x {{ position.name }}</h3>
                <BaseButton
                    small
                    :disabled="isPending"
                    :alt="$t('pages.checkout.projectShipmentPriceAdjustments.customPosition.deleteButton')"
                    :title="$t('pages.checkout.projectShipmentPriceAdjustments.customPosition.deleteButton')"
                    @click="$emit('remove')"
                >
                    <SfDeleteIcon size="xs" multi-color />
                </BaseButton>
            </header>

            <ul class="font-copy-md m-0 list-none p-0">
                <li>
                    <div class="font-copy-md mt-2 grid grid-cols-2 grid-rows-1 gap-0">
                        <div class="font-copy-md">
                            {{ $t('pages.checkout.priceAdjustments.purchasePriceLabel') }}
                        </div>
                        <div class="truncate whitespace-nowrap text-right">
                            {{ $n(position.purchaseUnitPrice, 'currency') }} / {{ position.unit }}
                        </div>
                    </div>
                </li>
                <li>
                    <div class="font-copy-md mt-2 grid grid-cols-2 grid-rows-1 gap-0">
                        <div class="font-copy-md">{{ $t('pages.checkout.priceAdjustments.retailPriceLabel') }}</div>
                        <div class="truncate whitespace-nowrap text-right">
                            {{ $n(position.retailUnitPrice, 'currency') }} / {{ position.unit }}
                        </div>
                    </div>
                </li>
            </ul>

            <HorizontalDivider class="my-4" />

            <div v-if="position.creditTo !== 'none'" class="mb-4 flex items-center text-subdued">
                <SfInfoIcon size="xxs" class="mr-2" />
                {{ $t(`pages.checkout.priceAdjustments.customPosition.creditTo.${position.creditTo}`) }}
                {{ $t('pages.checkout.priceAdjustments.customPosition.creditTo.label') }}
            </div>

            <ul class="font-copy-md m-0 list-none p-0">
                <li>
                    <div class="font-copy-md mt-2 grid grid-cols-2 grid-rows-1 gap-0">
                        <div class="font-copy-md">
                            {{ $t('pages.checkout.priceAdjustments.purchasePriceLabel') }}
                        </div>
                        <div class="font-copy-md-strong truncate whitespace-nowrap text-right">
                            {{ $n(position.purchaseUnitPriceTotal, 'currency') }}
                        </div>
                    </div>
                </li>
                <li>
                    <div class="font-copy-md mt-2 grid grid-cols-2 grid-rows-1 gap-0">
                        <div class="font-copy-md">{{ $t('pages.checkout.priceAdjustments.retailPriceLabel') }}</div>
                        <div class="font-copy-md-strong truncate whitespace-nowrap text-right">
                            {{ $n(position.retailUnitPriceTotal, 'currency') }}
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="mt-2 block text-right">
            <BaseButton :disabled="isPending" arrow-right @click="$emit('edit')">
                {{ $t('pages.checkout.projectShipmentPriceAdjustments.customPosition.editButton') }}
            </BaseButton>
        </div>
    </article>
</template>

<script>
import CustomPositionPricingItemView from '@/pages/Checkout/components/PriceAdjustment/models/CustomPositionPricingItemView';

import BaseButton from '@/components/Button/Button';
import HorizontalDivider from '@/_components/HorizontalDivider/HorizontalDivider.vue';

import { SfDeleteIcon, SfInfoIcon } from '@schuettflix/vue-components';

export default {
    name: 'ProjectCustomPositionItem',
    components: {
        BaseButton,
        HorizontalDivider,

        SfDeleteIcon,
        SfInfoIcon,
    },
    props: {
        vehicleIndex: {
            type: [String, Number],
            required: true,
        },
        positionIndex: {
            type: Number,
            required: true,
        },
        position: {
            type: CustomPositionPricingItemView,
            required: true,
        },
        isPending: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        editPosition() {
            this.$emit('edit');
        },
    },
};
</script>
<!--
<style lang="scss" scoped>
.hr-separator {
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
    align-items: center;
    margin: 10px 0;

    &:before,
    &:after {
        content: '';
        display: block;
        height: 2px;
        background-color: $color-mediumGrey;
        flex: 1 1 auto;
    }
}

.table-show {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
}
.ta-right {
    text-align: right;
}

.buttons {
    margin-top: 10px;
}

.button {
    margin: 0 20px;

    &:last-child {
        margin-right: 0;
    }

    &:first-child {
        margin-left: 0;
    }
}
</style>
-->

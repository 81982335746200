import CustomPositionPricingView from '@/pages/Checkout/components/PriceAdjustment/models/CustomPositionPricingView';
import Vue from 'vue';

export default class CustomPositionPricingSetView {
    constructor() {
        this._map = {};
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {CustomPositionPricingSetView|null}
     */
    static createFromCustomOrder(data) {
        if (!data) return null;

        const view = new CustomPositionPricingSetView();

        Object.keys(data).forEach(vehicleClassId => {
            view.set(
                vehicleClassId,
                CustomPositionPricingView.createFromCustomOrder({
                    ...data[vehicleClassId],
                    vehicleClassId: vehicleClassId,
                })
            );
        });

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param positions
     * @return {CustomPositionPricingSetView|null}
     */
    static createFromDisposalPayload(positions, vehicleClassId) {
        if (!positions) return null;

        const view = new CustomPositionPricingSetView();

        view.set(
            vehicleClassId,
            CustomPositionPricingView.createFromDisposalPayload({
                positions,
                vehicleClassId: vehicleClassId,
            })
        );

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {CustomPositionPricingSetView|null}
     */
    static createFromProjectShipmentPositionBasket(data) {
        return CustomPositionPricingSetView.create(data);
    }

    /**
     * Create a view model for transport pricing
     *
     * @param {object} data
     * @param {object[]} vehicleClasses
     * @param {object} transport
     * @return {CustomPositionPricingSetView|null}
     */
    static createFromProjectShipmentPositionPayload(data, vehicleClasses, transport) {
        if (!data) return null;

        const view = new CustomPositionPricingSetView();
        const highestVehicleClass = vehicleClasses[0]?.vehicleClass?.id;

        view.set(
            highestVehicleClass,
            CustomPositionPricingView.createFromProjectShipmentPositionPayload({
                data: data,
                vehicleClass: vehicleClasses[0],
                vehicleClassId: highestVehicleClass,
                transport,
            })
        );

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param {[TransportPricingView]} transportPricingView
     * @param {CustomPositionPricingSetView|null} currentCustomPositionPricingSetView
     * @return {CustomPositionPricingSetView|null}
     */
    static createFromEnabledVehicleClasses(transportPricingView, currentCustomPositionPricingSetView) {
        let resultView = null;
        if (transportPricingView instanceof Array) {
            const enabledVehicleClasses = transportPricingView.map(vehicleClass => vehicleClass.id);

            resultView = currentCustomPositionPricingSetView
                ? CustomPositionPricingSetView.create(
                      currentCustomPositionPricingSetView.unfoldKeys(enabledVehicleClasses)
                  )
                : new CustomPositionPricingSetView();

            transportPricingView.forEach(vehicleClass => {
                if (!resultView.get(vehicleClass.id)) {
                    resultView.set(
                        vehicleClass.id,
                        CustomPositionPricingView.create({
                            count: 1,
                            info: vehicleClass.info,
                            payload: vehicleClass.payload,
                            vehicleClassId: vehicleClass.id,
                        })
                    );
                }
            });

            let deselectedCustomPositionPricingView = null;
            if (currentCustomPositionPricingSetView) {
                const currentHighestVehicleClassId = Math.max(
                    ...Object.keys(currentCustomPositionPricingSetView._map).map(key => parseInt(key))
                );
                const newHighestVehicleClassId = Math.max(...Object.keys(resultView._map).map(key => parseInt(key)));
                deselectedCustomPositionPricingView =
                    newHighestVehicleClassId < currentHighestVehicleClassId
                        ? currentCustomPositionPricingSetView.get(currentHighestVehicleClassId)
                        : null;
            }
            resultView.moveAllCustomPositionsToHighestVehicleClassAndKeepOnlyThat(deselectedCustomPositionPricingView);
        }

        return resultView;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param {[WasteTransportPricingView]} transportPricingView
     * @param {CustomPositionPricingSetView|null} currentCustomPositionPricingSetView
     * @return {CustomPositionPricingSetView|null}
     */
    static createFromEnabledVehicleClassesDisposal(transportPricingView, currentCustomPositionPricingSetView) {
        let resultView = null;
        if (transportPricingView instanceof Array) {
            const enabledVehicleClasses = transportPricingView.map(vehicleClass => vehicleClass.vehicleClassId);

            resultView = currentCustomPositionPricingSetView
                ? CustomPositionPricingSetView.create(
                      currentCustomPositionPricingSetView.unfoldKeys(enabledVehicleClasses)
                  )
                : new CustomPositionPricingSetView();

            transportPricingView.forEach(vehicleClass => {
                if (!resultView.get(vehicleClass.vehicleClassId)) {
                    resultView.set(
                        vehicleClass.vehicleClassId,
                        CustomPositionPricingView.create({
                            count: 1,
                            info: vehicleClass.info,
                            payload: vehicleClass.payload,
                            vehicleClassId: vehicleClass.vehicleClassId,
                        })
                    );
                }
            });

            let deselectedCustomPositionPricingView = null;
            if (currentCustomPositionPricingSetView) {
                const currentHighestVehicleClassId = Math.max(
                    ...Object.keys(currentCustomPositionPricingSetView._map).map(key => parseInt(key))
                );
                const newHighestVehicleClassId = Math.max(...Object.keys(resultView._map).map(key => parseInt(key)));
                deselectedCustomPositionPricingView =
                    newHighestVehicleClassId < currentHighestVehicleClassId
                        ? currentCustomPositionPricingSetView.get(currentHighestVehicleClassId)
                        : null;
            }
            resultView.moveAllCustomPositionsToHighestVehicleClassAndKeepOnlyThat(deselectedCustomPositionPricingView);
        }

        return resultView;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {CustomPositionPricingSetView|null}
     */
    static create(data) {
        if (!data) return null;

        const view = new CustomPositionPricingSetView();

        Object.keys(data).forEach(vehicleClassId => {
            view.set(
                vehicleClassId,
                CustomPositionPricingView.create({
                    ...data[vehicleClassId],
                    vehicleClassId: vehicleClassId,
                })
            );
        });

        return view;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfoldToCustomOrder() {
        const data = {};

        this.collection.forEach(item => {
            data[item.vehicleClassId] = item.unfoldToCustomOrder();
        });

        return data;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        const data = {};

        this.collection.forEach(item => {
            data[item.vehicleClassId] = item.unfold();
        });

        return data;
    }

    /**
     * Unfold data
     * @param {[number]} keys
     * @return {object}
     */
    unfoldKeys(keys) {
        const data = {};

        this.collection.forEach(item => {
            if (keys && keys.includes(item.vehicleClassId)) {
                data[item.vehicleClassId] = item.unfold();
            }
        });

        return data;
    }

    /**
     * Clone current instance
     * @return {CustomPositionPricingSetView|null}
     */
    clone() {
        return CustomPositionPricingSetView.create(this.unfold());
    }

    /**
     * Set item in set
     * @param {string|int} key
     * @param {CustomPositionPricingView} item
     */
    set(key, item) {
        this._map[key] = item;
    }

    /**
     * Get item from set
     * @param {string|int} key
     * @return {CustomPositionPricingView}
     */
    get(key) {
        return this._map[key] ?? null;
    }

    get collection() {
        return Object.values(this._map);
    }

    /**
     * Check whether any position is set
     * @return {boolean}
     */
    get hasAnyPositions() {
        return this.collection.some(customPosition => customPosition.positions.length > 0);
    }

    /**
     * Transfers the custom positions to the highest vehicle class. If provided, saves the custom
     * positions from a deselected CustomPositionPricingSetView as well.
     *
     * @param {CustomPositionPricingView|null} deselectedCustomPositionPricingView
     */
    moveAllCustomPositionsToHighestVehicleClassAndKeepOnlyThat(deselectedCustomPositionPricingView) {
        const vehicleClassIdsInMap = Object.keys(this._map).map(key => parseInt(key));
        const highestVehicleClassId = Math.max(...vehicleClassIdsInMap);
        const targetCustomPositionPricingView = this.get(highestVehicleClassId);

        if (!targetCustomPositionPricingView) return;

        if (deselectedCustomPositionPricingView) {
            deselectedCustomPositionPricingView.positions.forEach(position =>
                targetCustomPositionPricingView.addPosition(position)
            );
        }

        for (const vehicleClassId of vehicleClassIdsInMap) {
            if (vehicleClassId === highestVehicleClassId) continue;

            const inferiorCustomPositionPricingView = this.get(vehicleClassId);
            inferiorCustomPositionPricingView.positions.forEach(position =>
                targetCustomPositionPricingView.addPosition(position)
            );
            Vue.delete(this._map, vehicleClassId);
        }

        // replacing transferred vehicle class properties to match target vehicle class
        targetCustomPositionPricingView.positions.forEach(position => {
            position.vehicleClassId = targetCustomPositionPricingView.vehicleClassId;
            position.vehiclePayload = targetCustomPositionPricingView.payload;
            position.count = targetCustomPositionPricingView.count;
        });
    }
}

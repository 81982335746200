<template>
    <FlyoutPage>
        <div class="pickup-started-confirmation">
            <ConfirmationScreen @close="done">
                <StartedTransportIcon slot="icon" />

                <div slot="content">
                    <Headline :level="3">
                        {{ $t('pages.order.orderView.pickupStartedConfirmation.headline') }}
                    </Headline>

                    <i18n path="pages.order.orderView.pickupStartedConfirmation.description" tag="div">
                        <Words red place="number" data-test="checkout-confirmation-order-number">{{
                            transport.number
                        }}</Words>
                        <Words block place="br" />
                    </i18n>

                    <Words block spaced>{{ transport.supplier.name }}</Words>
                    <br />
                    <Words block spaced>
                        <FormattedAddress :address="transport.loadingAddress" />
                    </Words>
                </div>

                <div slot="additional-content">
                    <Words block bold>
                        {{ $t('pages.order.orderView.pickupStartedConfirmation.openinghours.headline') }}
                    </Words>
                    <br />

                    <OpeningTimes v-if="supplierInfo" :opening-times="supplierInfo.supplier.openingTimes" />
                </div>
            </ConfirmationScreen>
        </div>

        <ButtonGroup slot="footer">
            <Button primary data-test="pickup-confirm-checkout-button" @click="done">{{
                $t('pages.order.orderView.pickupStartedConfirmation.done')
            }}</Button>
        </ButtonGroup>
    </FlyoutPage>
</template>

<script>
import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ConfirmationScreen from '@/components/Layout/ConfirmationScreen';
import FlyoutPage from '@/components/Layout/FlyoutPage';
import FormattedAddress from '@/components/FormattedAddress';
import Headline from '@/components/Typography/Headline';
import Words from '@/components/Typography/Words';
import OpeningTimes from '@/components/Supplier/OpeningTimes';
import SupplierApi from '@/services/Api/Supplier';

import StartedTransportIcon from '@/assets/icons/confirmation/started-transport.svg';

export default {
    name: 'PickupStartedConfirmationScreen',
    components: {
        ButtonGroup,
        Button,
        ConfirmationScreen,
        FlyoutPage,
        FormattedAddress,
        Words,
        Headline,
        OpeningTimes,
        StartedTransportIcon,
    },
    props: {
        transport: {
            type: Object,
            required: true,
        },
        orderId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            number: null,
            supplierInfo: null,
        };
    },
    created() {
        this.getSupplierInfo();
    },
    methods: {
        done() {
            this.$router
                .push({
                    name: this.$root.findRouteName('transport-view'),
                    params: {
                        orderId: this.orderId,
                        transportId: this.transport.id,
                    },
                })
                .catch(() => {});
        },

        async getSupplierInfo() {
            this.supplierInfo = await SupplierApi.getOneById(this.transport.factoryId);
        },
    },
};
</script>

<style lang="scss">
.pickup-started-confirmation {
    position: relative;
}

.pickup-started-confirmation__close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
}

.pickup-started-confirmation__icon {
    display: block;
    width: 95px;
    height: auto;
    margin: 0 auto;
    padding: 40px 0 50px;
}

.pickup-started-confirmation__info {
    margin: 1.5em 0;

    strong {
        font-weight: normal;
        color: $color-red;
    }
}

.pickup-started-confirmation__description,
.pickup-started-confirmation__openinghours {
    margin: 1.5em 0;
}

.pickup-started-confirmation__openinghours-headline {
    margin: 30px 0 10px;
}
</style>

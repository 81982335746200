<template>
    <div class="project-position-offer-block">
        <div v-if="pricingData.material" class="project-position-offer-block__section">
            <Words bold block class="project-position-offer-block__headline">
                {{ $t('components.projectPositionOfferBlock.materialHeadline') }}
            </Words>
            <div class="project-position-offer-block__grid">
                <Words tiny>
                    {{ $t('components.projectPositionOfferBlock.purchasePriceLabel') }}
                </Words>
                <Words tiny>{{ $n(pricingData.material.purchaseUnitPrice, 'currency') }} / {{ $t('units.ton') }}</Words>

                <Words tiny>
                    {{ $t('components.projectPositionOfferBlock.retailPriceLabel') }}
                </Words>
                <Words tiny>{{ $n(pricingData.material.retailUnitPrice, 'currency') }} / {{ $t('units.ton') }}</Words>
            </div>
        </div>

        <div class="project-position-offer-block__section">
            <Words bold block class="project-position-offer-block__headline">
                {{ $t('components.projectPositionOfferBlock.transportHeadline') }}
            </Words>

            <template v-for="vehicleClass in collection">
                <template v-if="vehicleClass.enabled">
                    <Words
                        :key="`${vehicleClass.id}-headline`"
                        tiny
                        bold
                        block
                        spaced-bottom-tiny
                        class="project-position-offer-block__grid-headline"
                    >
                        {{ vehicleClass.info }}
                    </Words>
                    <div :key="`${vehicleClass.id}-body`" class="project-position-offer-block__grid">
                        <Words tiny>
                            {{ $t('components.projectPositionOfferBlock.purchasePriceLabel') }}
                        </Words>
                        <Words v-if="hasFixedPrice" tiny>
                            {{ $n(vehicleClass.purchaseUnitPrice.getPerTransport(), 'currency') }} /
                            {{ $t('units.transport') }}
                        </Words>
                        <Words v-else tiny>
                            {{ $n(vehicleClass.purchaseUnitPrice.getPerTon(), 'currency') }} / {{ $t('units.ton') }}
                        </Words>

                        <Words tiny>
                            {{ $t('components.projectPositionOfferBlock.retailPriceLabel') }}
                        </Words>
                        <Words v-if="hasFixedPrice" tiny>
                            {{ $n(vehicleClass.retailUnitPrice.getPerTransport(), 'currency') }} /
                            {{ $t('units.transport') }}
                        </Words>
                        <Words v-else tiny>
                            {{ $n(vehicleClass.retailUnitPrice.getPerTon(), 'currency') }} / {{ $t('units.ton') }}
                        </Words>
                    </div>
                </template>
            </template>
        </div>

        <div v-if="pricingData.shipping" class="project-position-offer-block__section">
            <Words bold block class="project-position-offer-block__headline">
                {{ $t('components.projectPositionOfferBlock.shippingHeadline') }}
            </Words>
            <div class="project-position-offer-block__grid">
                <Words tiny>
                    {{ $t('components.projectPositionOfferBlock.purchasePriceLabel') }}
                </Words>
                <Words tiny>
                    {{ $n(pricingData.shipping.purchaseUnitPrice, 'currency') }} / {{ $t('units.transport') }}
                </Words>

                <Words tiny>
                    {{ $t('components.projectPositionOfferBlock.retailPriceLabel') }}
                </Words>
                <Words tiny>
                    {{ $n(pricingData.shipping.retailUnitPrice, 'currency') }} / {{ $t('units.transport') }}
                </Words>
            </div>
        </div>

        <div v-if="pricingData.customPositions.hasAnyPositions" class="project-position-offer-block__section">
            <Words bold block class="project-position-offer-block__headline">
                {{ $t('components.projectPositionOfferBlock.customPositionsHeadline') }}
            </Words>
            <template v-for="customPositionsCollection in pricingData.customPositions.collection">
                <template v-for="(position, i) in customPositionsCollection.positions">
                    <Words
                        :key="`headline-${i}`"
                        tiny
                        bold
                        block
                        spaced-bottom-tiny
                        class="project-position-offer-block__grid-headline"
                    >
                        {{ position.qty }} &times;
                        {{ position.name }}
                    </Words>
                    <div :key="`body-${i}`" class="project-position-offer-block__grid">
                        <Words tiny>
                            {{ $t('components.projectPositionOfferBlock.purchasePriceLabel') }}
                        </Words>
                        <Words tiny>
                            {{ $n(position.purchaseUnitPriceTotal, 'currency') }} / {{ $t('units.transport') }}
                        </Words>

                        <Words tiny>
                            {{ $t('components.projectPositionOfferBlock.retailPriceLabel') }}
                        </Words>
                        <Words tiny>
                            {{ $n(position.retailUnitPriceTotal, 'currency') }} / {{ $t('units.transport') }}
                        </Words>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import PricingDataView from '@/pages/Checkout/components/PriceAdjustment/models/PricingDataView';
import Words from '@/components/Typography/Words';
import _orderBy from 'lodash/orderBy';

export default {
    name: 'ProjectPositionOfferBlock',
    components: { Words },
    props: {
        pricingData: {
            type: [PricingDataView, Object],
            required: true,
        },
        hasFixedPrice: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        collection() {
            return _orderBy(this.pricingData.transport.collection, ['payload'], ['desc']);
        },
    },
};
</script>

<style lang="scss" scoped>
.project-position-offer-block__title {
    margin-bottom: 25px;
}

.project-position-offer-block__section + .project-position-offer-block__section {
    margin-top: 25px;
}

.project-position-offer-block__headline {
    margin-bottom: 10px;
}

.project-position-offer-block__grid-headline {
    margin-top: 10px;
}

.project-position-offer-block__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;

    > :nth-child(even) {
        text-align: right;
    }
}
</style>

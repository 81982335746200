<template>
    <div class="feature-settings">
        <Words block bold small spaced-bottom>
            {{ $t('pages.organization.featureSettings.settings') }}
        </Words>
        <div v-for="(setting, key) in featureDefinition.settings" :key="key" class="feature-settings__group">
            <Words block small bold>
                {{ setting.title }}
            </Words>
            <Words block spaced-bottom>
                {{ setting.description }}
            </Words>
            <component
                :is="getComponentByFieldType(setting.type)"
                :setting="setting"
                :value="provideValue(key)"
                @input="handleSettingUpdate(key, $event)"
            />
        </div>
    </div>
</template>

<script>
import BoolFieldType from './FeatureSettingsFieldTypes/BoolFieldType';
import DefaultFieldType from './FeatureSettingsFieldTypes/DefaultFieldType';
import NumberFieldType from './FeatureSettingsFieldTypes/NumberFieldType';
import Words from '@/components/Typography/Words';

export default {
    name: 'FeatureSettings',
    components: {
        BoolFieldType,
        DefaultFieldType,
        NumberFieldType,
        Words,
    },
    props: {
        feature: {
            type: Object,
            required: true,
        },
        featureDefinition: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getComponentByFieldType(type) {
            switch (type) {
                case 'bool':
                    return BoolFieldType;
                case 'int':
                case 'float':
                    return NumberFieldType;
                default:
                    return DefaultFieldType;
            }
        },

        handleSettingUpdate(key, value) {
            this.$emit('input', {
                ...this.feature,
                [key]: value,
            });
        },

        provideValue(key) {
            const value = this.feature[key];

            if (value !== undefined) {
                return value;
            }

            const fallbackValue = this.featureDefinition.settings[key].default;
            if (fallbackValue !== undefined) {
                return fallbackValue;
            }

            return null;
        },
    },
};
</script>

<style lang="scss">
.feature-settings__group {
    margin-top: 30px;
}
</style>

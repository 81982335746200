import apiService from '@/services/Api/ApiService';
import { AbstractResourceErrorHandler } from '@/services/Api/AbstractResourceErrorHandler';
class BillingAddressSettingsApi extends AbstractResourceErrorHandler {
    client;
    constructor(client) {
        super();
        this.client = client;
    }
    async getBillingAddressSettings() {
        try {
            const response = await this.client.get(`/v1/billing-address-settings`);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
}
export const billingAddressSettingsApi = new BillingAddressSettingsApi(apiService);

<template>
    <div
        :class="{
            'filter-sort-pagination--show-grouping-toggle': showGroupingToggle,
            'filter-sort-pagination--show-refresh': showRefresh,
            'mt-4': !spaceless,
        }"
        class="flex flex-wrap items-center justify-between gap-4"
    >
        <div class="w-auto md:shrink-0">
            <slot :filter="filter" :result="result" />
        </div>

        <div class="filter-sort-pagination flex flex-row flex-wrap gap-4">
            <FilterSort
                v-if="!hideSort && filter"
                :sort-by="filter.sortBy"
                :sort-direction="filter.sortDirection"
                :sort-options="sortOptions"
                :option-label-renderer="optionLabelRenderer"
                :has-sort-set-options="hasSortSetOptions"
                :class="{ 'filter-sort-pagination__sorting--separated': !hasSortSetOptions }"
                class="filter-sort-pagination__sorting"
                @sort-by-update="filter.sortBy = $event"
                @sort-direction-update="filter.sortDirection = $event"
            />

            <Pagination
                v-if="result"
                class="filter-sort-pagination__pagination shrink-0 grow basis-0"
                :result="result"
                @pageNumberUpdated="page => $emit('pageNumberUpdated', page)"
            />

            <div
                v-if="showRefresh"
                :class="{
                    'filter-sort-pagination__refresh--loading': loading,
                }"
                class="filter-sort-pagination__refresh"
            >
                <Button primary light icon @click="$emit('refresh')">
                    <RefreshIcon />
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/Button/Button';
import FilterResult from '@/services/Api/FilterResult';
import FilterSort from './FilterSort';
import Pagination from '@/components/Pagination';

import RefreshIcon from '@/assets/icons/regular/refresh.svg';

export default {
    name: 'FilterSortPagination',

    components: {
        Button,
        FilterSort,
        Pagination,

        RefreshIcon,
    },

    props: {
        result: {
            type: FilterResult,
            default: null,
        },

        filter: {
            type: Object,
            default: () => {},
        },

        sortOptions: {
            type: Array,
            default: () => [],
        },

        optionLabelRenderer: {
            type: Function,
            default: () => {
                return value => value;
            },
        },

        showGroupingToggle: {
            type: Boolean,
            default: false,
        },

        showRefresh: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        hideSort: {
            type: Boolean,
            default: false,
        },

        spaceless: {
            type: Boolean,
            default: false,
        },

        hasSortSetOptions: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.filter-sort-pagination {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
    align-items: center;
    grid-auto-columns: auto;
    grid-auto-flow: column;

    @media screen and (max-width: $screen-md) {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;

        &.filter-sort-pagination--show-refresh {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-areas:
                'slot slot sorting sorting'
                'pagination pagination pagination refresh';
            row-gap: 10px;
        }

        &.filter-sort-pagination--show-grouping-toggle {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-areas:
                'slot slot sorting sorting'
                'pagination pagination pagination refresh';
            grid-auto-flow: row;
            row-gap: 10px;
        }

        > * {
            grid-area: slot;
        }

        .filter-sort-pagination__pagination {
            grid-area: pagination;
        }

        .filter-sort-pagination__sorting {
            grid-area: sorting;
        }

        .filter-sort-pagination__refresh {
            grid-area: refresh;
        }
    }
}

.filter-sort-pagination__sorting--separated {
    border-right: 1px solid $color-lightMediumGrey;
    padding-right: 20px;
    margin-right: 10px;
}

.filter-sort-pagination__refresh {
    display: flex;

    @media screen and (max-width: $layout-desktop-max) {
        justify-content: flex-end;
        height: 40px;
    }

    .button--primary.button--light {
        min-width: 0;
        width: 40px;
        height: 40px;
        padding: 0;

        @media screen and (max-width: $layout-desktop-max) {
            height: 40px;
        }

        > * {
            min-height: 0;
        }

        .filter-sort-pagination--transparent & {
            background-color: transparent;
        }
    }

    svg {
        animation: filterBarRefreshRotate 0.5s linear infinite;
        animation-play-state: paused;
    }
}

.filter-sort-pagination__refresh--loading {
    svg {
        animation-play-state: running;
    }
}
</style>

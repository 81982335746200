export default {
    props: {
        theme: {
            type: String,
            default: 'light',
            validator: v => ['light', 'dark'].includes(v),
        },
    },
    computed: {
        hasDarkTheme() {
            return this.theme === 'dark';
        },
    },
};

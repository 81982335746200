<template>
    <div class="transport-list-block-list space-y-6">
        <button
            v-for="transport in transports"
            :key="`group-${transport.id}`"
            :disabled="isLoading"
            data-test="transport-list-tile"
            class="card card--hovered card--focussed block w-full text-left lg:card--md"
            @click.once="selectTransport(transport.id)"
        >
            <TransportListBlock
                :context="context"
                :transport="transport"
                :is-mobile="!$root.isDesktop"
                :hide-project-indicator="isGrouped || hideProjectIndicator"
                :hide-transport-type="isGrouped"
                :hide-freight-type="isGrouped"
                :is-unavailable="isUnavailableHandler(transport.id)"
                :is-picked="isPickedHandler(transport.id)"
                :is-grouped-view="isGrouped"
                @address-item="selectTransport(transport.id, $event)"
                @preferred-counter="showPreferredCarriersHandler(transport)"
            >
                <template v-if="isSelectable" #[bulkCheckSlot]>
                    <div v-if="isGrouped" class="lg:mr-auto" @click.stop="">
                        <CheckboxField
                            :name="'transport-' + transport.id"
                            :value="isSelected(transport)"
                            black="black"
                            class="lg:mt-[3px]"
                            @input="toggleTransport(transport)"
                        >
                            <span class="lg:hidden">
                                {{ $t('components.transportListBlock.bulk.selectSingle') }}
                            </span>
                        </CheckboxField>
                    </div>
                </template>
                <template v-if="$root.isDesktop" #action>
                    <SfButton
                        v-if="showPickButtonHandler(transport.id)"
                        size="sm"
                        :is-loading="isPickTransportPendingHandler(transport.id)"
                        :disabled="
                            !!isPickTransportPendingHandler(transport.id) ||
                            !$can('pickTransport', transport) ||
                            !$can('isAllowedToAssignTransport', transport)
                        "
                        data-test="transport-list-block-pick-button"
                        class="ml-4"
                        @click.stop="pickTransport(transport.id)"
                    >
                        <template #leading-icon="iconProps"><SfSysPlusIcon v-bind="iconProps" /></template>
                        {{ $t('pages.transportHub.actions.pick') }}</SfButton
                    >
                    <SfButton
                        v-else-if="
                            isCarrier && $can('transportPlan', transport) && isAssigned(transport) && !isPlanDisabled
                        "
                        :disabled="!$can('isAllowedToPlanTransport', transport)"
                        size="sm"
                        data-test="transport-list-block-plan-button"
                        class="ml-4"
                        @click.stop="planTransport(transport)"
                    >
                        {{ $t(`pages.transport.transportListCarrier.actions.plan.${transport.status}`) }}
                    </SfButton>
                </template>
                <template #notification>
                    <div
                        v-if="
                            (showPickButtonHandler(transport.id) && !$can('isAllowedToAssignTransport', transport)) ||
                            (isCarrier &&
                                $can('transportPlan', transport) &&
                                (isAssigned(transport) || isPlanned(transport)) &&
                                !isPlanDisabled &&
                                !$can('isAllowedToPlanTransport', transport))
                        "
                        class="mt-4 w-full text-right"
                    >
                        <div class="ml-auto w-auto">
                            <PopUp position="top" variant="fixed-width-400" class="text-left">
                                <SfInfoWithContext>
                                    <SfInfoIcon size="xxs" class="mt-[2px]" />
                                    <span class="truncate">
                                        {{
                                            $t('ability.isAllowedToPerformActionOnTransport.transportItem.not.headline')
                                        }}
                                    </span>
                                </SfInfoWithContext>
                                <template #content>
                                    <p>
                                        {{
                                            $t(
                                                'ability.isAllowedToPerformActionOnTransport.transportItem.not.description'
                                            )
                                        }}
                                    </p>
                                </template>
                            </PopUp>
                        </div>
                    </div>
                </template>
            </TransportListBlock>

            <template v-if="!$root.isDesktop">
                <div
                    v-if="
                        // assign
                        (showPickButtonHandler(transport.id) && !$can('isAllowedToAssignTransport', transport)) ||
                        // plan / replan
                        (isCarrier &&
                            $can('transportPlan', transport) &&
                            (isAssigned(transport) || isPlanned(transport)) &&
                            !isPlanDisabled &&
                            !$can('isAllowedToPlanTransport', transport))
                    "
                    class="mt-4 w-full text-right"
                >
                    <div class="ml-auto w-auto">
                        <PopUp position="top" variant="fixed-width-400" class="text-left">
                            <SfInfoWithContext>
                                <SfInfoIcon size="xxs" class="mt-[2px]" />
                                <span class="truncate">
                                    {{ $t('ability.isAllowedToPerformActionOnTransport.transportItem.not.headline') }}
                                </span>
                            </SfInfoWithContext>
                            <template #content>
                                <p>
                                    {{
                                        $t('ability.isAllowedToPerformActionOnTransport.transportItem.not.description')
                                    }}
                                </p>
                            </template>
                        </PopUp>
                    </div>
                </div>
                <ButtonGroup class="relative -bottom-[2px] -mx-4 -mb-4 mt-4" inline bordered>
                    <SfButton
                        type="secondary"
                        theme="light"
                        class="block"
                        size="sm"
                        @click.stop="selectTransport(transport.id)"
                    >
                        {{ $t('pages.transport.transportListCarrier.actions.details') }}</SfButton
                    >
                    <SfButton
                        v-if="isPlatform && transport.status === 'planned'"
                        class="block"
                        @click.stop="openUnplanTransport(transport)"
                    >
                        <template #leading-icon="iconProps">
                            <SfWasteIcon v-bind="iconProps" />
                        </template>
                        {{ $t(`pages.transport.transportListCarrier.actions.unplan`) }}
                    </SfButton>
                    <SfButton
                        v-else-if="isCarrier && $can('transportPlan', transport) && !isPlanDisabled"
                        :disabled="!$can('isAllowedToPlanTransport', transport)"
                        size="sm"
                        class="block"
                        @click.stop="planTransport(transport)"
                    >
                        {{ $t(`pages.transport.transportListCarrier.actions.plan.${transport.status}`) }}
                    </SfButton>
                    <SfButton
                        v-if="showPickButtonHandler(transport.id)"
                        size="sm"
                        data-test="transport-list-block-pick-button"
                        :is-loading="isPickTransportPendingHandler(transport.id)"
                        :disabled="
                            !!isPickTransportPendingHandler(transport.id) ||
                            !$can('pickTransport', transport) ||
                            !$can('isAllowedToAssignTransport', transport)
                        "
                        @click.stop="pickTransport(transport.id)"
                    >
                        <template #leading-icon="iconProps">
                            <SfSysPlusIcon v-bind="iconProps" />
                        </template>
                        {{ $t('pages.transportHub.actions.pick') }}
                    </SfButton>
                </ButtonGroup>
            </template>
        </button>
    </div>
</template>

<script>
import { CONTEXT_SUPPLIER, CONTEXT_CARRIER, CONTEXT_CLIENT, CONTEXT_PLATFORM } from '@/constants/context';

import ButtonGroup from '@/components/Button/ButtonGroup';
import TransportListBlock from '@/components/Transport/TransportListBlock';
import CheckboxField from '@/components/Form/CheckboxField';
import PopUp from '@/_components/PopUp/PopUp';

import { SfButton, SfSysPlusIcon, SfWasteIcon, SfInfoIcon, SfInfoWithContext } from '@schuettflix/vue-components';

export default {
    name: 'TransportListBlockList',
    components: {
        ButtonGroup,
        TransportListBlock,
        CheckboxField,
        PopUp,
        // components
        SfButton,
        SfWasteIcon,
        SfInfoIcon,
        SfSysPlusIcon,
        SfInfoWithContext,
    },
    props: {
        transports: {
            type: Array,
            required: true,
        },
        showPreferredCarriersHandler: {
            type: Function,
            default: () => false,
        },
        showPickButtonHandler: {
            type: Function,
            default: () => false,
        },
        pickTransportHandler: {
            type: Function,
            default: () => false,
        },
        isPickTransportPendingHandler: {
            type: Function,
            default: () => false,
        },
        isPickedHandler: {
            type: Function,
            default: () => false,
        },
        isUnavailableHandler: {
            type: Function,
            default: () => false,
        },
        isGrouped: {
            type: Boolean,
            default: false,
        },
        hideProjectIndicator: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        context: {
            type: String,
            default: 'client',
        },
        isPlanDisabled: {
            type: Boolean,
            default: false,
        },
        isSelectable: {
            type: Boolean,
            default: false,
        },
        selectedTransports: {
            type: Set,
            default: () => new Set(),
        },
    },
    computed: {
        isSupplier() {
            return this.context === CONTEXT_SUPPLIER;
        },

        isCarrier() {
            return this.context === CONTEXT_CARRIER;
        },

        isClient() {
            return this.context === CONTEXT_CLIENT;
        },

        isPlatform() {
            return this.context === CONTEXT_PLATFORM;
        },

        bulkCheckSlot() {
            return this.$root.isDesktop ? 'bulkCheck' : 'bulkCheckMobile';
        },
    },
    methods: {
        selectTransport(transportId, preselectedNavigation = null) {
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$emit('select-transport', transportId, preselectedNavigation);
        },

        openUnplanTransport(transport) {
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$emit('open-unplan-transport', transport);
        },

        planTransport(transport) {
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$emit('plan-transport', transport);
        },

        pickTransport(transportId) {
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$emit('pick-transport', transportId);
        },

        toggleTransport(transport) {
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$emit('toggle-transport', transport);
        },

        isSelected(transport) {
            return this.selectedTransports.has(transport.id);
        },

        isAssigned(transport) {
            return transport.status === 'assigned';
        },

        isPlanned(transport) {
            return transport.status === 'planned';
        },
    },
};
</script>

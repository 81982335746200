import clsx from 'clsx';
import { useIconSize } from '@/services/utils/useIconSize';
import { forwardRef } from 'react';
// TODO extract to component library, this is just temporary until icon PR is merged
export const NotificationIcon = forwardRef(({ size = 'xs', className, multicolor = false, ...props }, ref) => {
    const iconSize = useIconSize(size);
    return (<svg width={iconSize} height={iconSize} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={clsx('svg-icon', className)} ref={ref} {...props}>
                <g clipPath="url(#clip0_409_420)">
                    <path d="M16.9999 14.5617V8.91209C16.9999 7.60932 16.4731 6.35993 15.5354 5.43873C14.5977 4.51753 13.326 4 11.9999 4V4C10.6738 4 9.40202 4.51753 8.46434 5.43873C7.52666 6.35993 6.99988 7.60932 6.99988 8.91209V14.5617C6.73725 14.5617 6.47719 14.6126 6.23456 14.7113C5.99192 14.81 5.77146 14.9547 5.58575 15.1372C5.40005 15.3196 5.25274 15.5362 5.15224 15.7746C5.05173 16.0129 5 16.2684 5 16.5264V18H18.9992V16.5259C18.9992 16.2679 18.9474 16.0125 18.847 15.7742C18.7465 15.5359 18.5992 15.3194 18.4136 15.137C18.2279 14.9546 18.0075 14.81 17.765 14.7113C17.5224 14.6126 17.2625 14.5617 16.9999 14.5617H16.9999Z" stroke="#35343D" strokeWidth="2.00001" strokeMiterlimit="10"/>
                    <path d="M12.0019 3.24786V1" stroke="#35343D" strokeWidth="2.00001" strokeMiterlimit="10"/>
                    <path d="M10.0201 18.9962V20.0062C10.0201 20.5339 10.2297 21.04 10.6028 21.4131C10.976 21.7863 11.482 21.9959 12.0098 21.9959V21.9959C12.271 21.9959 12.5298 21.9445 12.7712 21.8445C13.0126 21.7445 13.2319 21.5979 13.4167 21.4131C13.6014 21.2284 13.748 21.009 13.848 20.7676C13.948 20.5262 13.9995 20.2675 13.9995 20.0062V18.9961" className={multicolor ? 'stroke-icon-accent' : 'stroke-icon'} strokeWidth="2.00001"/>
                    <path d="M12.0019 3V1" stroke="#35343D" strokeWidth="2.00001" strokeMiterlimit="10"/>
                    <path d="M10 18.9962V20.0062C10 20.5339 10.2107 21.04 10.5858 21.4131C10.9609 21.7863 11.4696 21.9959 12 21.9959V21.9959C12.2626 21.9959 12.5227 21.9445 12.7654 21.8445C13.008 21.7445 13.2285 21.5979 13.4142 21.4131C13.5999 21.2284 13.7472 21.009 13.8478 20.7676C13.9483 20.5262 14 20.2675 14 20.0062V18.9961" className={multicolor ? 'stroke-icon-accent' : 'stroke-icon'} strokeWidth="2.00001"/>
                </g>
                <defs>
                    <clipPath id="clip0_409_420">
                        <rect width="15.9992" height="21.9968" fill="white" transform="translate(4 1)"/>
                    </clipPath>
                </defs>
            </svg>);
});
NotificationIcon.displayName = 'NotificationIcon';

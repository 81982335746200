<template>
    <div class="bg-surface p-6">
        <div v-if="client" class="flex">
            <span class="font-copy-sm">{{ client.name }}</span>
            <span class="font-numbers-sm ml-auto">{{ client.customerNumber }}</span>
        </div>
        <div v-if="client" class="mt-2 border-b" />
        <div class="mt-4 flex">
            <SfProjectsIcon v-if="order.projectName" size="xs" multi-color class="mr-4" />
            <span v-if="order.projectName" class="font-copy-md-strong">
                {{ order.projectName }}
            </span>
        </div>
        <div v-if="isShipment">
            <div class="mt-6 flex">
                <SfMapMarkerIcon size="xs" multi-color />
                <div class="ml-4">
                    <span
                        v-if="originAddress.name || originAddress.formattedAddress.address"
                        class="font-copy-md-strong"
                    >
                        {{ originAddress.name || originAddress.formattedAddress.address.additionalAddress }}
                    </span>
                    <FormattedAddress
                        small
                        decorate-location-code
                        :address="originAddress.formattedAddress"
                        :hide-additional-address="Boolean(originAddress.formattedAddress.address.additionalAddress)"
                    />
                </div>
            </div>
            <div class="mt-6 flex">
                <SfMapMarkerIcon size="xs" multi-color />
                <div class="ml-4">
                    <span
                        v-if="destinationAddress.name || destinationAddress.formattedAddress.address"
                        class="font-copy-md-strong"
                    >
                        {{ destinationAddress.name || destinationAddress.formattedAddress.address.additionalAddress }}
                    </span>
                    <FormattedAddress
                        decorate-location-code
                        :address="destinationAddress.formattedAddress"
                        :hide-additional-address="
                            Boolean(destinationAddress.formattedAddress.address.additionalAddress)
                        "
                    />
                </div>
            </div>
        </div>
        <div v-else>
            <div class="mt-6 flex">
                <SfFlagIcon multi-color size="xs" class="mr-4 flex-shrink-0" />
                <div class="flex flex-col overflow-hidden">
                    <span class="font-copy-md-strong mb-1 truncate">{{ destinationAddress.name }}</span>
                    <FormattedAddress small decorate-location-code :address="destinationAddress" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import routeContext from '@/plugins/mixins/routeContext';
import { CONTEXT_PLATFORM } from '@/constants/context';
import _get from 'lodash/get';

import FormattedAddress from '@/components/FormattedAddress';

import { SfProjectsIcon, SfFlagIcon, SfMapMarkerIcon } from '@schuettflix/vue-components';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';

export default {
    name: 'OrderHeaderDetails',

    components: {
        FormattedAddress,

        SfProjectsIcon,
        SfFlagIcon,
        SfMapMarkerIcon,
    },

    mixins: [routeContext],

    props: {
        order: {
            type: Object,
            default: null,
        },
    },

    computed: {
        originAddress() {
            if (this.isShipment) {
                return {
                    name: _get(this.order, 'lineItemGroups[0].loadingName', null),
                    formattedAddress: {
                        address: _get(this.order, 'lineItemGroups[0].loadingAddress', null),
                        location: _get(this.order, 'lineItemGroups[0].loadingLocation', null),
                    },
                };
            }

            return null;
        },

        destinationAddress() {
            if (this.isShipment) {
                return {
                    name: _get(this.order, 'lineItemGroups[0].unloadingName', null),
                    formattedAddress: {
                        address: _get(this.order, 'lineItemGroups[0].unloadingAddress', null),
                        location: _get(this.order, 'lineItemGroups[0].unloadingLocation', null),
                    },
                };
            }

            return _get(this.order, 'lineItemGroups[0].constructionSite', null);
        },

        client() {
            if (!this.inRouteContext(CONTEXT_PLATFORM)) {
                return null;
            }

            return this.order ? this.order.client : null;
        },

        isShipment() {
            return this.order.lineItemGroups.every(g => g.type === ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT);
        },
    },
};
</script>

import AbstractFilterableResource from './AbstractFilterableResource';
class CostCalculator extends AbstractFilterableResource {
    async getTransportCosts(loadingCoordinates, unloadingCoordinates, vehicleClassIds, emissionType, co2Class) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/cost-calculator/vehicle-class/price`, {
                loadingLocation: {
                    lat: loadingCoordinates.latitude,
                    lng: loadingCoordinates.longitude,
                },
                unloadingLocation: {
                    lat: unloadingCoordinates.latitude,
                    lng: unloadingCoordinates.longitude,
                },
                vehicleClassIds,
                emissionType,
                co2Class,
            });
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
}
export default new CostCalculator('/v2/transport');

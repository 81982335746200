/**
 * Class representing address view model
 */
export default class AddressView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     * @return {AddressView|null}
     */
    static create(data) {
        if (!data) return null;

        const address = new AddressView();

        address.city = data.city;
        address.country = data.country;
        address.number = data.number;
        address.state = data.state;
        address.additionalAddress = data?.additionalAddress;
        address.street = data.street;
        address.zip = data.zip;

        return address;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._city = null;
        this._country = null;
        this._number = null;
        this._state = null;
        this._additionalAddress = null;
        this._street = null;
        this._zip = null;
    }

    /**
     * Unfold view into plain object
     * @return {object}
     */
    unfold() {
        return {
            city: this._city,
            country: this._country,
            number: this._number,
            state: this._state,
            additionalAddress: this._additionalAddress,
            street: this._street,
            zip: this._zip,
        };
    }

    /**
     * Clone the current view
     * @return {AddressView}
     */
    clone() {
        return AddressView.create(this.unfold());
    }

    /**
     * Get city
     * @returns {string}
     */
    get city() {
        return this._city;
    }

    /**
     * Set city
     * @param {string} value
     */
    set city(value) {
        this._city = value;
    }

    /**
     * Get country
     * @returns {string}
     */
    get country() {
        return this._country;
    }

    /**
     * Set country
     * @param {string} value
     */
    set country(value) {
        this._country = value;
    }

    /**
     * Get number
     * @returns {string}
     */
    get number() {
        return this._number;
    }

    /**
     * Set number
     * @param {string} value
     */
    set number(value) {
        this._number = value;
    }

    /**
     * Get state
     * @returns {string}
     */
    get state() {
        return this._state;
    }

    /**
     * Set state
     * @param {string} value
     */
    set state(value) {
        this._state = value;
    }

    /**
     * Get additionalAddress
     * @returns {string}
     */
    get additionalAddress() {
        return this._additionalAddress;
    }

    /**
     * Set additionalAddress
     * @param {string} value
     */
    set additionalAddress(value) {
        this._additionalAddress = value;
    }

    /**
     * Get street
     * @returns {string}
     */
    get street() {
        return this._street;
    }

    /**
     * Set street
     * @param {string} value
     */
    set street(value) {
        this._street = value;
    }

    /**
     * Get zip
     * @returns {string}
     */
    get zip() {
        return this._zip;
    }

    /**
     * Set zip
     * @param {string} value
     */
    set zip(value) {
        this._zip = value;
    }
}

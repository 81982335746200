import React from 'react';
export const ActiveProjectsIcon = ({ className }) => (<svg width={140} height={140} fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <g id="icons_schuettflix_icon_141_active projects" clipPath="url(#clip0_8511_53181)">
            <g id="active_projects">
                <g id="Group">
                    <path id="Vector" d="M30.035 54.0163L21.305 56.8363L8.08496 15.9163L41.395 5.15625L46.585 21.2162" stroke="#35343D" strokeWidth={2.33} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_2" d="M18.5449 22.2541L30.4449 18.4141" stroke="#35343D" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_3" d="M35.675 16.7241L36.625 16.4141" stroke="#35343D" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_4" d="M37.5149 22.435L38.4649 22.125" stroke="#35343D" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_5" d="M20.385 27.965L32.285 24.125" stroke="#35343D" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_6" d="M22.2349 33.6759L34.1349 29.8359" stroke="#35343D" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_7" d="M24.075 39.3869L34.415 36.0469" stroke="#35343D" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <g id="Group_2">
                    <path id="Vector_8" d="M59.5549 18.2041L62.3749 9.16406L95.9149 19.1541L83.6349 60.3641L78.7849 58.8041" stroke="#35343D" strokeWidth={2.33} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_9" d="M67.395 20.3047L79.375 23.8747" stroke="#35343D" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_10" d="M84.645 25.4453L85.605 25.7353" stroke="#35343D" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_11" d="M82.9348 31.1953L83.8948 31.4853" stroke="#35343D" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_12" d="M81.2148 36.9453L82.1748 37.2353" stroke="#35343D" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_13" d="M79.5049 42.6953L80.4649 42.9853" stroke="#35343D" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_14" d="M69.635 27.2344L77.665 29.6244" stroke="#35343D" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_15" d="M70.1848 33.6562L75.9548 35.3763" stroke="#35343D" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_16" d="M71.0149 40.1641L74.2349 41.1241" stroke="#35343D" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <g id="Group_3">
                    <path id="Vector_17" d="M37.085 57.6741L34.085 27.6441L68.915 24.1641L73.005 65.1141" stroke="#FF0000" strokeWidth={2.33} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_18" d="M42.9648 36.0447L55.4048 34.8047" stroke="#FF0000" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_19" d="M60.875 34.2562L61.865 34.1562" stroke="#FF0000" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_20" d="M61.4749 40.225L62.4649 40.125" stroke="#FF0000" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_21" d="M62.075 46.1938L63.075 46.0938" stroke="#FF0000" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_22" d="M62.665 52.1625L63.665 52.0625" stroke="#FF0000" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_23" d="M43.5649 42.0134L56.0049 40.7734" stroke="#FF0000" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_24" d="M44.165 47.9822L56.605 46.7422" stroke="#FF0000" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_25" d="M44.7549 53.9509L57.1949 52.7109" stroke="#FF0000" strokeWidth={2.5} strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <g id="Group_4">
                    <path id="Vector_26" d="M7.71484 57.1562V66.4162" stroke="#FF0000" strokeWidth={2.33} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_27" d="M3.08496 61.7891H12.355" stroke="#FF0000" strokeWidth={2.33} strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <g id="Group_5">
                    <path id="Vector_28" d="M105.915 32.4375L99.365 38.9875" stroke="#35343D" strokeWidth={2.33} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_29" d="M99.365 32.4375L105.915 38.9875" stroke="#35343D" strokeWidth={2.33} strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <path id="Vector_30" d="M4.80496 6.60625C6.30496 6.60625 7.52496 5.38625 7.52496 3.88625C7.52496 2.38625 6.31496 1.15625 4.80496 1.15625C3.29496 1.15625 2.08496 2.37625 2.08496 3.87625C2.08496 5.37625 3.30496 6.59625 4.80496 6.59625V6.60625Z" stroke="#FF0000" strokeWidth={2.33} strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_31" d="M51.815 18.5963C53.315 18.5963 54.535 17.3763 54.535 15.8763C54.535 14.3763 53.315 13.1562 51.815 13.1562C50.315 13.1562 49.095 14.3763 49.095 15.8763C49.095 17.3763 50.315 18.5963 51.815 18.5963Z" stroke="#35343D" strokeWidth={2.33} strokeLinecap="round" strokeLinejoin="round"/>
                <path id="Vector_32" d="M92.815 57.6041C94.315 57.6041 95.535 56.3841 95.535 54.8841C95.535 53.3841 94.315 52.1641 92.815 52.1641C91.315 52.1641 90.095 53.3841 90.095 54.8841C90.095 56.3841 91.315 57.6041 92.815 57.6041Z" stroke="#FF0000" strokeWidth={2.33} strokeLinecap="round" strokeLinejoin="round"/>
                <g id="Group_6">
                    <path id="Vector_33" d="M42.635 79.1641V73.1641H61.635V79.1641H42.635Z" stroke="#35343D" strokeWidth={2.33} strokeLinecap="round" strokeLinejoin="round"/>
                    <path id="Vector_34" d="M61.085 93.6962H18.135V65.1562H86.135V93.6962H80.085" stroke="#35343D" strokeWidth={2.33} strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_8511_53181">
                <rect width="106.15" height="94.87" fill="white" transform="translate(0.925049)"/>
            </clipPath>
        </defs>
    </svg>);

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-box",class:{
        'select-box--invalid': _vm.error || _vm.hasError,
        'select-box--focused': false && _vm.isFocused,
        'select-box--filled': _vm.hasValue,
        'select-box--dark': _vm.dark,
        'select-box--medium': _vm.medium,
        'select-box--small': _vm.small,
        'select-box--tiny': _vm.tiny,
    }},[_c('div',{staticClass:"select-box__input-group"},[_c('select',{staticClass:"select-box__input",class:{
                'select-box__input--filled': _vm.hasValue,
                'select-box__input--iconized': _vm.$slots.icon,
                'select-box__input--readonly': _vm.readonly,
                'select-box__input--without-label': !_vm.label,
            },attrs:{"id":_vm.eid,"disabled":_vm.disabled || _vm.readonly,"data-test":`${_vm.dataTest}-select`},domProps:{"value":_vm.processedValue},on:{"change":_vm.update,"blur":_vm.blured,"focus":_vm.focused}},[_vm._t("default")],2),(_vm.label)?_c('label',{staticClass:"select-box__label",attrs:{"for":_vm.eid}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.$slots.icon)?_c('div',{staticClass:"select-box__icon"},[_vm._t("icon")],2):_vm._e(),_c('ArrowDownIcon',{staticClass:"select-box__arrow select-box__arrow--down",class:{ 'select-box__arrow--readonly': _vm.readonly }}),(_vm.hasValue && !_vm.preventReset && !_vm.readonly)?_c('Button',{staticClass:"select-box__reset",attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.$emit('input', null)}}},[_c('ResetIcon',{attrs:{"width":"13"}})],1):_vm._e()],1),(_vm.error)?_c('ErrorMessage',{attrs:{"message":_vm.error,"dark":_vm.dark}}):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
    <LayoutPage
        class="vehicle-form-page"
        :screen-name="isNew ? 'carrier-vehicle-create' : 'carrier-vehicle-edit'"
        :parent-route="parentRoute"
        :is-flyout="isNew"
        close-by-x
        @close="$root.routeBack()"
    >
        <template #pageTitle>{{ pageTitle }}</template>

        <template v-if="!$root.isDesktop && vehicleForm" #mobileHeaderRight>
            <HeaderBarItem>
                <VehicleContextMenu :vehicle="vehicleForm" />
            </HeaderBarItem>
        </template>

        <div class="vehicle-form-page__container">
            <div v-if="isReady" class="vehicle-form-page__group">
                <div class="vehicle-form-page__group-head vehicle-form-page__group-head--split">
                    <div class="vehicle-form-page__group-head-actions">
                        <ToggleSwitchField
                            v-model="vehicleForm.isActive"
                            :small="$root.isDesktop"
                            :reverse-label="!$root.isDesktop"
                        >
                            <Words bold>{{ $t('pages.vehicle.form.statusLabel') }}</Words>
                        </ToggleSwitchField>

                        <VehicleContextMenu v-if="$root.isDesktop" :vehicle="vehicleForm" />
                    </div>
                    <div>
                        <Words block bold>{{ $t('pages.vehicle.form.groups.vehicleClassHeadline') }}</Words>
                        <Words block spaced-top-small>{{ $t('pages.vehicle.form.groups.vehicleClassMessage') }}</Words>
                    </div>
                </div>

                <div class="vehicle-form-page__card-grid">
                    <Card
                        v-for="vehicleClass in vehicleClasses.items"
                        :key="vehicleClass.id"
                        spaceless
                        :levitate-hover="vehicleForm.vehicleClassId !== vehicleClass.id"
                        :class="{ 'vehicle-form-page__card--active': vehicleForm.vehicleClassId === vehicleClass.id }"
                        class="vehicle-form-page__card"
                        clickable
                        @click="selectVehicleClass(vehicleClass)"
                    >
                        <div class="vehicle-form-page__card-inner">
                            <VehicleClassIconSet
                                :icon="vehicleClass.icon"
                                class="vehicle-form-page__card-icon vehicle-form-page__card-icon--right"
                            />
                            <div>
                                <Words block bold>{{ vehicleClass.name }}</Words>
                                <Words block muted small spaced-top-tiny>
                                    {{ $tc('pages.vehicle.form.numAxes', vehicleClass.numAxes) }}&nbsp;
                                    {{ vehicleClass.payload.value | tons }} {{ $t('pages.vehicle.form.payloadLabel') }}
                                </Words>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>

            <template v-if="selectedVehicleClass">
                <div class="vehicle-form-page__separator" data-group="general" />

                <div class="vehicle-form-page__group">
                    <div class="vehicle-form-page__group-head">
                        <Words block bold>{{ $t('pages.vehicle.form.groups.generalHeadline') }}</Words>
                    </div>

                    <div class="vehicle-form-page__base-info-grid">
                        <ImageUploadArea
                            v-model="vehicleForm.image"
                            :edit-modal-headline="$t('pages.vehicle.form.photoEditModalHeadline')"
                            :delete-label="$t('pages.vehicle.form.photoDeleteLabel')"
                            :photo-select-text="$t('pages.vehicle.form.photoButtonText')"
                            :photo-change-text="$t('pages.vehicle.form.photoButtonTextChange')"
                            class="vehicle-form-page__image-upload"
                            @delete="handleImageRemoval"
                        />
                        <GridRow :count="2" margin-less>
                            <TextField
                                v-model="vehicleForm.licensePlate"
                                :error="getError('vehicleForm.licensePlate')"
                                :label="$t('pages.vehicle.form.licensePlate')"
                                class="span-2"
                                styled-uppercase
                            />

                            <TextField
                                v-model.number="vehicleForm.emptyWeight"
                                :error="getError('vehicleForm.emptyWeight')"
                                :label="$t('pages.vehicle.form.emptyWeight')"
                                only-natural-number
                                class="span-2 span-md-1"
                                type="number"
                                step="1"
                            />

                            <TextField
                                v-model="vehicleForm.permissibleTotalWeight"
                                :error="getError('vehicleForm.permissibleTotalWeight')"
                                :label="$t('pages.vehicle.form.permissibleTotalWeight')"
                                only-natural-number
                                class="span-2 span-md-1"
                                type="number"
                                step="1"
                            />
                        </GridRow>
                    </div>
                </div>

                <div class="vehicle-form-page__group">
                    <div class="vehicle-form-page__group-head">
                        <Words block bold>{{ $t('pages.vehicle.form.groups.sizesHeadline') }}</Words>
                    </div>

                    <GridRow :count="3" margin-less>
                        <TextField
                            v-model="vehicleForm.dimension.length"
                            type="number"
                            step="0.01"
                            :error="getError('vehicleForm.dimension.length')"
                            :label="$t('pages.vehicle.form.dimension.length')"
                            class="span-3 span-md-1"
                        />
                        <TextField
                            v-model="vehicleForm.dimension.width"
                            type="number"
                            step="0.01"
                            :error="getError('vehicleForm.dimension.width')"
                            :label="$t('pages.vehicle.form.dimension.width')"
                            class="span-3 span-md-1"
                        />
                        <TextField
                            v-model="vehicleForm.dimension.height"
                            type="number"
                            step="0.01"
                            :error="getError('vehicleForm.dimension.height')"
                            :label="$t('pages.vehicle.form.dimension.height')"
                            class="span-3 span-md-1"
                        />
                    </GridRow>
                </div>

                <div class="vehicle-form-page__group">
                    <Alert v-if="!attributeSet || isPending('refreshAttributeSet')" type="info">{{
                        $t('pages.vehicleClassFormPage.attributes_pending')
                    }}</Alert>
                    <VehicleAttributeManager
                        v-else
                        v-model="vehicleForm.attributes"
                        :attribute-set="attributeSet"
                        :purchasable-attributes="purchasableAttributes"
                    />
                </div>
            </template>
        </div>

        <template #sticky>
            <SlideUp :active="isReady">
                <ButtonGroup>
                    <i18n
                        path="components.transportListBlock.termsAndPrivacyLabel"
                        tag="span"
                        class="font-copy-xs pr-2 text-subdued"
                    >
                        <a
                            :href="linkLegalTermsAndConditionsCarrier"
                            target="_blank"
                            class="font-copy-xs inline text-subdued underline"
                            place="gtc"
                            @click.stop
                        >
                            {{ $t('components.transportListBlock.termsAndPrivacy.gtcLabel') }}
                        </a>
                        <a
                            :href="linkLegalDataProtectionPolicyGlobal"
                            target="_blank"
                            class="font-copy-xs inline text-subdued underline"
                            place="dpp"
                            @click.stop
                        >
                            {{ $t('components.transportListBlock.termsAndPrivacy.dppLabel') }}</a
                        >
                    </i18n>
                    <Button :disabled="isPending()" primary transparent light arrow-left @click="$root.routeBack()">
                        {{ $t('pages.vehicle.form.cancel') }}
                    </Button>
                    <Button :disabled="isPending()" :is-loading="isPending('save')" primary @click="save()">
                        {{ $t('pages.vehicle.form.save') }}
                    </Button>
                </ButtonGroup>
            </SlideUp>
        </template>
    </LayoutPage>
</template>

<script>
import _get from 'lodash/get';
import { greaterOrEqualThan, isRequired, lowerOrEqualThan } from '@/services/validation/rules';
import VehicleApi from '@/services/Api/Vehicle';
import Toaster from '@/services/Toaster';
import validate from '@/services/validation/mixin';
import VehicleClassApi from '@/services/Api/VehicleClass';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import VehicleClassView from '@/models/VehicleClass/VehicleClassView';
import VehicleView from '@/models/Vehicle/VehicleView';
import { EVENT_VEHICLE_UPDATED, EVENT_VEHICLE_DELETED, EVENT_VEHICLE_CREATED } from '@/constants/events';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import AttributeManager from '@/services/AttributeManager/AttributeManager';
import PubSubEvent from '@/services/PubSub/PubSubEvent';

import Alert from '@/components/Alert';
import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Card from '@/components/Layout/Card';
import GridRow from '@/components/Layout/GridRow';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import ImageUploadArea from '@/pages/Vehicle/components/ImageUploadArea';
import LayoutPage from '@/components/Layout/Page.v2';
import SlideUp from '@/components/Animation/SlideUp';
import TextField from '@/components/Form/TextField.v2';
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import VehicleAttributeManager from '@/pages/Vehicle/components/VehicleAttributeManager';
import VehicleClassIconSet from '@/components/IconSet/VehicleClassIconSet';
import VehicleContextMenu from '@/pages/Vehicle/components/VehicleContextMenu';
import Words from '@/components/Typography/Words';

export default {
    name: 'VehicleFormPage',
    components: {
        Alert,
        Button,
        ButtonGroup,
        GridRow,
        ImageUploadArea,
        TextField,
        LayoutPage,
        ToggleSwitchField,
        VehicleClassIconSet,
        Words,
        Card,
        HeaderBarItem,
        VehicleContextMenu,
        SlideUp,
        VehicleAttributeManager,
    },
    mixins: [validate, statefulMixin, eventHubMixin],
    props: {
        vehicleId: {
            type: [String, Number],
            default: null,
        },
        parentRoute: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            vehicleForm: this.getEmptyVehicle(),
            vehicleClasses: null,
            attributeSet: null,
        };
    },
    computed: {
        isNew() {
            return !this.vehicleId;
        },
        pageTitle() {
            return this.isNew ? this.$t(`pages.vehicle.form.title.new`) : this.vehicleForm.licensePlate?.toUpperCase();
        },
        isReady() {
            if (this.vehicleId && this.vehicleForm.id && this.vehicleClasses !== null) {
                return true;
            }

            return this.vehicleClasses !== null;
        },
        selectedVehicleClass() {
            const { vehicleClassId } = this.vehicleForm;
            return this.vehicleClasses?.items.find(vc => vc.id === vehicleClassId) ?? null;
        },
        purchasableAttributes() {
            if (!this.selectedVehicleClass) return null;
            // Insert all intermediate states to purchasable attributes
            const attributeManager = new AttributeManager(this.attributeSet);
            return attributeManager.prepare(this.selectedVehicleClass.availableAttributes);
        },
        validationRules() {
            const rules = {
                'vehicleForm.vehicleClassId': [isRequired()],
                'vehicleForm.licensePlate': [isRequired()],
            };

            const vc = this.selectedVehicleClass;

            if (!vc) {
                return rules;
            }

            return {
                ...rules,

                'vehicleForm.emptyWeight': [
                    isRequired(),
                    lowerOrEqualThan(vc.emptyWeight.max),
                    greaterOrEqualThan(vc.emptyWeight.min),
                ],
                'vehicleForm.permissibleTotalWeight': [
                    isRequired(),
                    lowerOrEqualThan(vc.permissibleTotalWeight.max),
                    greaterOrEqualThan(vc.permissibleTotalWeight.min),
                ],
                'vehicleForm.dimension.width': [
                    lowerOrEqualThan(vc.dimension.width.max),
                    greaterOrEqualThan(vc.dimension.width.min),
                ],
                'vehicleForm.dimension.height': [
                    lowerOrEqualThan(vc.dimension.height.max),
                    greaterOrEqualThan(vc.dimension.height.min),
                ],
                'vehicleForm.dimension.length': [
                    lowerOrEqualThan(vc.dimension.length.max),
                    greaterOrEqualThan(vc.dimension.length.min),
                ],
            };
        },
    },
    created() {
        this.refresh();

        this.subscribe(EVENT_VEHICLE_DELETED, event => {
            if (event.subject.id === this.vehicleId) {
                this.$root.routeBack();
            }
        });
    },
    methods: {
        async refresh() {
            await this.refreshVehicle();
            await this.loadVehicleClasses();
            await this.refreshAttributeSet();
        },

        getEmptyVehicle() {
            return VehicleView.create({
                isActive: true,
            });
        },

        async refreshVehicle() {
            if (!this.vehicleId) return;

            await this.statefulRun('refreshVehicle', async () => {
                try {
                    const result = await VehicleApi.getOneById(this.vehicleId);
                    this.vehicleForm = VehicleView.create(result);
                } catch (err) {
                    this.$logger().error(err);
                }
            });
        },

        setPermissibleTotalWeight() {
            this.vehicleForm.permissibleTotalWeight = this.selectedVehicleClass?.permissibleTotalWeight.value ?? null;
        },
        async loadVehicleClasses() {
            await this.statefulRun('loadVehicleClasses', async () => {
                try {
                    const types = [];

                    if (this.$can('createVehicleForPickup')) {
                        types.push('pickup');
                    }

                    if (this.$can('createVehicleForDelivery')) {
                        types.push('delivery');
                    }

                    if (this.$can('createVehicleForShipment')) {
                        types.push('shipment');
                    }

                    this.vehicleClasses = await VehicleClassApi.filter({ type: types });
                    this.vehicleClasses.transform(item => VehicleClassView.create(item));
                } catch (err) {
                    this.$logger().log(err);
                }
            });
        },

        async selectVehicleClass(vehicleClass) {
            const hasVehicleClass = this.vehicleForm.vehicleClassId !== null;
            this.vehicleForm.vehicleClassId = vehicleClass.id;
            this.setPermissibleTotalWeight();
            this.clearValidation();
            await this.refreshAttributeSet();
            if (!hasVehicleClass) {
                this.scrollTo('[data-group="general"]');
            }
        },

        async refreshAttributeSet() {
            if (!this.vehicleClasses || !this.selectedVehicleClass) return;
            this.attributeSet = null;
            await this.statefulRun('refreshAttributeSet', async () => {
                try {
                    this.attributeSet = await VehicleClassApi.getAttributeSet(this.selectedVehicleClass.identifier);
                } catch (err) {
                    Toaster.error(err);
                }
            });
        },

        async save() {
            if (!this.isValid()) return;

            await this.stateful('save', async () => {
                try {
                    const attributeManager = new AttributeManager(this.attributeSet);
                    const vehicleData = this.vehicleForm.unfoldToEditForm();

                    const vehicle = await VehicleApi.save({
                        ...vehicleData,
                        attributes: attributeManager.finalize(vehicleData.attributes),
                    });

                    this.$eventHub.$emit(
                        this.isNew ? EVENT_VEHICLE_CREATED : EVENT_VEHICLE_UPDATED,
                        new PubSubEvent(vehicle)
                    );
                    this.$root.routeBack();
                    Toaster.success(
                        this.isNew
                            ? this.$t('pages.vehicle.form.successfullyCreated')
                            : this.$t('pages.vehicle.form.successfullyUpdated')
                    );
                } catch (err) {
                    this.$logger().error(err);
                    this.parseServerValidation(_get(err, 'response.data.errors', {}), {
                        'vehicleForm.vehicleClassId': 'vehicleClassId',
                        'vehicleForm.licensePlate': 'licensePlate',
                        'vehicleForm.emptyWeight': 'emptyWeight',
                        'vehicleForm.permissibleTotalWeight': 'permissibleTotalWeight',
                        'vehicleForm.image': 'image',
                        'vehicleForm.dimension.length': 'dimension.length',
                        'vehicleForm.dimension.width': 'dimension.width',
                        'vehicleForm.dimension.height': 'dimension.height',
                    });
                }
            });
        },

        handleImageRemoval() {
            this.vehicleForm.image = null;
        },

        scrollTo(selector) {
            const $el = document.querySelector(selector);
            if ($el) {
                $el.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.vehicle-form-page__container {
    padding: 0 16px;
    margin: 24px auto;
    width: 100%;
    max-width: 720px;

    @media only screen and (min-width: $layout-desktop-min) {
        margin-top: 50px;
    }
}

.vehicle-form-page__group + .vehicle-form-page__group {
    margin-top: 50px;
}

.vehicle-form-page__group-head {
    margin-bottom: 24px;
}

.vehicle-form-page__group-head-actions {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    gap: 16px;
    align-items: center;

    @media only screen and (max-width: $layout-desktop-max) {
        margin-bottom: 48px;
    }
}

.vehicle-form-page__group-head--split {
    @media only screen and (min-width: $layout-desktop-min) {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
    }
}

.vehicle-form-page__card-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @media only screen and (min-width: $screen-md) {
        grid-template-columns: 1fr 1fr;
    }
}

.vehicle-form-page__card {
    border: 2px solid transparent;
}

.vehicle-form-page__card--active {
    border-color: $color-black;
}

.vehicle-form-page__card-inner {
    display: grid;
    grid-template-columns: 5fr 6fr;
    gap: 16px;
    align-items: center;

    @media only screen and (min-width: $screen-md) {
        min-height: 98px;
    }
}

.vehicle-form-page__card-icon {
    height: 54px;
    max-width: 100%;
}

.vehicle-form-page__card-icon--right {
    justify-self: flex-end;
}

.vehicle-form-page__separator {
    margin-top: 50px;
    border-top: 1px solid $color-littleDarkerThanMediumGrey;
    margin-bottom: 50px;
}

.vehicle-form-page__base-info-grid {
    display: grid;
    grid-template-columns: 230px auto;
    gap: 16px;

    @media only screen and (max-width: $screen-md) {
        grid-template-columns: 1fr;
    }
}

.vehicle-form-page__base-info-subgrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 16px;
}

.vehicle-form-page__image-upload {
    width: 100%;
    height: 100%;
    max-height: 160px;
}
</style>

<template>
    <component
        :is="block ? 'p' : 'span'"
        v-tooltip.top="tooltip"
        :class="{
            'words--block': block,
            'words--bold': bold,
            'words--red': red,
            'words--white': white,
            'words--yellow': yellow,
            'words--green': green,
            'words--muted': muted,
            'words--error': isError,
            'words--tiny': tiny,
            'words--size-normal': sizeNormal,
            'words--large': large,
            'words--x-large': xLarge,
            'words--small': small,
            'words--middle': middle,
            'words--spaced': spaced,
            'words--ellipsis': ellipsis,
            'words--unbreakable': unbreakable,
            'words--selectable': selectable,
            'words--spaced-bottom': spacedBottom,
            'words--spaced-bottom-small': spacedBottomSmall,
            'words--spaced-bottom-medium': spacedBottomMedium,
            'words--spaced-bottom-tiny': spacedBottomTiny,
            'words--spaced-bottom-large': spacedBottomLarge,
            'words--spaced-top': spacedTop,
            'words--spaced-top-small': spacedTopSmall,
            'words--spaced-top-tiny': spacedTopTiny,
            'words--spaced-top-large': spacedTopLarge,
            'words--spaced-right': spacedRight,
            'words--spaced-right-tiny': spacedRightTiny,
            'words--spaced-left': spacedLeft,
            'words--spaced-left-small': spacedLeftSmall,
            'words--spaced-left-tiny': spacedLeftTiny,
            'words--underlined': underlined,
            'words--centered': centered,
            'words--align-left': alignLeft,
            'words--align-right': alignRight,
            'words--pre': pre,
            'words--weight-normal': weightNormal,
            'words--break-words': breakWords,
            'words--line-height--small': lineHeightSmall,
            'words--line-height--large': lineHeightLarge,
        }"
        class="words"
    >
        <slot />
    </component>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill';
export default {
    name: 'WordsComponent',
    props: {
        block: {
            type: Boolean,
            default: false,
        },
        bold: {
            type: Boolean,
            default: false,
        },
        large: {
            type: Boolean,
            default: false,
        },
        xLarge: {
            type: Boolean,
            default: false,
        },
        sizeNormal: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        tiny: {
            type: Boolean,
            default: false,
        },
        red: {
            type: Boolean,
            default: false,
        },
        white: {
            type: Boolean,
            default: false,
        },
        yellow: {
            type: Boolean,
            default: false,
        },
        green: {
            type: Boolean,
            default: false,
        },
        muted: {
            type: Boolean,
            default: false,
        },
        middle: {
            type: Boolean,
            default: false,
        },
        spaced: {
            type: Boolean,
            default: false,
        },
        isError: {
            type: Boolean,
            default: false,
        },
        ellipsis: {
            type: Boolean,
            default: false,
        },
        unbreakable: {
            type: Boolean,
            default: false,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        spacedBottom: {
            type: Boolean,
            default: false,
        },
        spacedBottomMedium: {
            type: Boolean,
            default: false,
        },
        spacedBottomSmall: {
            type: Boolean,
            default: false,
        },
        spacedBottomTiny: {
            type: Boolean,
            default: false,
        },
        spacedBottomLarge: {
            type: Boolean,
            default: false,
        },
        spacedTop: {
            type: Boolean,
            default: false,
        },
        spacedTopSmall: {
            type: Boolean,
            default: false,
        },
        spacedTopTiny: {
            type: Boolean,
            default: false,
        },
        spacedTopLarge: {
            type: Boolean,
            default: false,
        },
        spacedRight: {
            type: Boolean,
            default: false,
        },
        spacedRightTiny: {
            type: Boolean,
            default: false,
        },
        spacedLeft: {
            type: Boolean,
            default: false,
        },
        spacedLeftSmall: {
            type: Boolean,
            default: false,
        },
        spacedLeftTiny: {
            type: Boolean,
            default: false,
        },
        spacedHorizontally: {
            type: Boolean,
            default: false,
        },
        underlined: {
            type: Boolean,
            default: false,
        },
        centered: {
            type: Boolean,
            default: false,
        },
        alignLeft: {
            type: Boolean,
            default: false,
        },
        alignRight: {
            type: Boolean,
            default: false,
        },
        pre: {
            type: Boolean,
            default: false,
        },
        weightNormal: {
            type: Boolean,
            default: false,
        },
        breakWords: {
            type: Boolean,
            default: false,
        },
        lineHeightSmall: {
            type: Boolean,
            default: false,
        },
        lineHeightLarge: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            truncated: false,
            resizeObserver: null,
        };
    },
    computed: {
        tooltip() {
            if (!this.ellipsis) return null;
            if (!this.truncated) return null;

            return this.$slots?.default?.[0].text || null;
        },
    },
    mounted() {
        if (!this.ellipsis) return;

        this.calculateTruncated();

        this.resizeObserver = new ResizeObserver(() => {
            this.calculateTruncated();
        });
        this.resizeObserver.observe(this.$el);
    },
    beforeDestroy() {
        this.resizeObserver && this.resizeObserver.disconnect();
    },
    methods: {
        calculateTruncated() {
            const { offsetWidth, scrollWidth } = this.$el;
            this.truncated = offsetWidth < scrollWidth;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.words {
    font-family: $font-family;
    color: inherit;
}

.words--block {
    display: block;
    margin: 0;
}

.words--bold {
    font-weight: $font-weight-bold;
}

.words--red {
    color: $color-red;
}

.words--white {
    color: $color-white;
}

.words--yellow {
    color: $color-yellow;
}

.words--green {
    color: $color-green;
}

.words--muted {
    color: $color-grey;
}

.words--error {
    color: $sflx-red-800;
}

.words--middle {
    vertical-align: middle;
}

.words--large {
    font-size: 18px;
    line-height: 24px;
}

.words--x-large {
    font-size: 24px;
    line-height: 29px;
}

.words--line-height--small {
    line-height: 20px;
}

.words--line-height--large {
    line-height: 28px;
}

.words--size-normal {
    font-size: $font-size-base;
    line-height: 18px;
}

.words--small {
    font-size: $font-size-small;
    line-height: 14px;
}

.words--tiny {
    font-size: $font-size-tiny;
    line-height: 13px;
}

.words--spaced {
    margin-top: 16px;
    margin-bottom: 16px;
}

.words--spaced-bottom {
    margin-bottom: 16px;
}

.words--spaced-bottom-medium {
    margin-bottom: 8px;
}

.words--spaced-bottom-small {
    margin-bottom: 8px;
}

.words--spaced-bottom-tiny {
    margin-bottom: 5px;
}

.words--spaced-bottom-large {
    margin-bottom: 24px;
}

.words--spaced-top {
    margin-top: 16px;
}

.words--spaced-right-tiny {
    margin-right: 5px;
}

.words--spaced-left-small {
    margin-left: 8px;
}

.words--spaced-top-small {
    margin-top: 10px;
}

.words--spaced-top-tiny {
    margin-top: 5px;
}

.words--spaced-top-large {
    margin-top: 24px;
}

.words--spaced-right {
    margin-right: 15px;
}

.words--spaced-right-tiny {
    margin-right: 5px;
}

.words--spaced-left {
    margin-left: 15px;
}

.words--spaced-left-tiny {
    margin-left: 5px;
}

.words--ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
}
.words--unbreakable {
    white-space: nowrap;
}

.words--selectable {
    user-select: text;
}

.words--underlined {
    text-decoration: underline;
}

.words--centered {
    text-align: center;
}

.words--align-right {
    text-align: right;
}

.words--align-left {
    text-align: left;
}

.words--pre {
    white-space: pre-line;
}

.words--weight-normal {
    font-weight: $font-weight-regular;
}

.words--break-words {
    word-wrap: break-word;
}
</style>

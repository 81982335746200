import AddressView from '@/models/AddressView';
import ImageView from '@/models/ImageView';
import PhoneNumberView from '@/models/PhoneNumberView';

export default class SupplierFactoryInfoView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     */
    static create(data) {
        const info = new SupplierFactoryInfoView();

        info.id = data.id;
        info.customerNumber = data.customerNumber;
        info.name = data.name;
        info.billingAddress = AddressView.create(data.billingAddress);
        info.web = data.web;
        info.logo = ImageView.create(data.logo);
        info.description = data.description;
        info.phone = PhoneNumberView.create(data.phone);
        info.email = data.email;
        info.fax = PhoneNumberView.create(data.fax);
        info.mobile = PhoneNumberView.create(data.mobile);
        info.location = data.location;
        info.factoryAddress = AddressView.create(data.factoryAddress);
        info.factoryName = data.factoryName;
        info.factoryNumber = data.factoryNumber;
        info.factoryId = data.factoryId;

        return info;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._id = null;
        this._customerNumber = null;
        this._name = null;
        this._billingAddress = null;
        this._web = null;
        this._logo = null;
        this._description = null;
        this._phone = null;
        this._email = null;
        this._fax = null;
        this._mobile = null;
        this._location = null;
        this._factoryAddress = null;
        this._factoryName = null;
        this._factoryNumber = null;
        this._factoryId = null;
    }

    /**
     * Get id
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * Set id
     * @param {number} value
     */
    set id(value) {
        this._id = value ?? null;
    }

    /**
     * Get customer number
     * @returns {string}
     */
    get customerNumber() {
        return this._customerNumber;
    }

    /**
     * Set customer number
     * @param {string} value
     */
    set customerNumber(value) {
        this._customerNumber = value ?? null;
    }

    /**
     * Get name
     * @returns {string}
     */
    get name() {
        return this._name;
    }

    /**
     * Set name
     * @param {string} value
     */
    set name(value) {
        this._name = value ?? null;
    }

    /**
     * Get supplier billing address
     * @returns {AddressView}
     */
    get billingAddress() {
        return this._billingAddress;
    }

    /**
     * Set supplier billing address
     * @param {AddressView} value
     */
    set billingAddress(value) {
        this._billingAddress = value ?? null;
    }

    /**
     * Get web
     * @returns {string}
     */
    get web() {
        return this._web;
    }

    /**
     * Set web
     * @param {string} value
     */
    set web(value) {
        this._web = value ?? null;
    }

    /**
     * Get logo
     * @returns {ImageView}
     */
    get logo() {
        return this._logo;
    }

    /**
     * Set logo
     * @param {ImageView} value
     */
    set logo(value) {
        this._logo = value ?? null;
    }

    /**
     * Get description
     * @returns {string}
     */
    get description() {
        return this._description;
    }

    /**
     * Set description
     * @param {string} value
     */
    set description(value) {
        this._description = value ?? null;
    }

    /**
     * Get phone
     * @returns {PhoneNumberView}
     */
    get phone() {
        return this._phone;
    }

    /**
     * Set phone
     * @param {PhoneNumberView} value
     */
    set phone(value) {
        this._phone = value ?? null;
    }

    /**
     * Get email
     * @returns {string}
     */
    get email() {
        return this._email;
    }

    /**
     * Set email
     * @param {string} value
     */
    set email(value) {
        this._email = value ?? null;
    }

    /**
     * Get fax
     * @returns {PhoneNumberView}
     */
    get fax() {
        return this._fax;
    }

    /**
     * Set fax
     * @param {PhoneNumberView} value
     */
    set fax(value) {
        this._fax = value ?? null;
    }

    /**
     * Get mobile
     * @returns {PhoneNumberView}
     */
    get mobile() {
        return this._mobile;
    }

    /**
     * Set mobile
     * @param {PhoneNumberView} value
     */
    set mobile(value) {
        this._mobile = value ?? null;
    }

    /**
     * Get location
     * @returns {object}
     */
    get location() {
        return this._location;
    }

    /**
     * Set location
     * @param {object|null} value
     */
    set location(value) {
        this._location = value ?? null;
    }

    /**
     * Get factory address
     * @returns {AddressView}
     */
    get factoryAddress() {
        return this._factoryAddress;
    }

    /**
     * Set factory address
     * @param {AddressView} value
     */
    set factoryAddress(value) {
        this._factoryAddress = value ?? null;
    }

    /**
     * Get factory name
     * @returns {string}
     */
    get factoryName() {
        return this._factoryName;
    }

    /**
     * Set factory name
     * @param {string} value
     */
    set factoryName(value) {
        this._factoryName = value ?? null;
    }

    /**
     * Get factory number
     * @returns {string}
     */
    get factoryNumber() {
        return this._factoryNumber;
    }

    /**
     * Get factory number
     * @param {string} value
     */
    set factoryNumber(value) {
        this._factoryNumber = value ?? null;
    }

    /**
     * Get factory id
     * @returns {number}
     */
    get factoryId() {
        return this._factoryId;
    }

    /**
     * Set factory id
     * @param {number} value
     */
    set factoryId(value) {
        this._factoryId = value ?? null;
    }
}

<template>
    <div
        v-if="supplierOrganizationId"
        class="form-copy-organization-data"
        :class="{ 'form-copy-organization-data--right': placeRight }"
        data-test="formCopyOrganizationData"
        @click="getRequestedOrganizationFields()"
    >
        <slot />
    </div>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import { mapGetters } from 'vuex';
import OrganizationApi from '@/services/Api/Organization';

export default {
    name: 'FormCopyOrganizationData',
    props: {
        fieldNames: {
            type: Array,
            default: () => [],
        },
        objectToReplace: {
            type: String,
            default: '',
        },
        organizationId: {
            type: [Number, null],
            default: null,
        },
        placeRight: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            copiedData: {},
            organization: null,
        };
    },
    computed: {
        ...mapGetters('factoryManagement', {
            supplierOrganizationId: 'getSupplierOrganizationId',
        }),
    },
    methods: {
        async fetchOrganization() {
            this.organization = await OrganizationApi.getOneById(this.supplierOrganizationId);
        },
        async getRequestedOrganizationFields() {
            if (!this.organization && this.supplierOrganizationId) {
                await this.fetchOrganization();
            }
            this.fieldNames.forEach(field => {
                if (typeof field === 'object') {
                    const key = Object.keys(field)[0];
                    const fieldValue = field[Object.keys(field)[0]];

                    this.copiedData[fieldValue] = this.organization[key];
                } else if (this.organization[field] !== undefined) {
                    this.copiedData[field] = this.organization[field];
                }
            });

            if (!_isEmpty(this.objectToReplace)) {
                this.copiedData[this.objectToReplace] = {
                    ...this.copiedData,
                };
                this.fieldNames.forEach(field => delete this.copiedData[field]);
            }

            this.$emit('datatransfer', this.copiedData);
        },
    },
};
</script>

<style lang="scss" scoped>
.form-copy-organization-data {
    color: $color-darkGrey;

    &--right {
        margin-left: auto;
    }
}
</style>

import { mapGetters } from 'vuex';
import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';

import GoogleMaps, { createMap } from '@/services/GoogleMaps';
import { locationsEqual, replacePlaceLabelWithFormattedAddress, structurizePlaceResult } from '@/services/utils/map';
import { emptyAddress } from '@/services/utils/address';

import FlagIconPath from '@/assets/icons/flag.svg?external';

const DEBOUNCE_CHANGED_MAP_CENTER_MS = 500;

export default {
    computed: {
        ...mapGetters('platform', ['initialMapLocation']),
    },
    methods: {
        createGoogleMap(google, initialZoom = null, options = {}) {
            return createMap(google, `${this.eid}-map`, {
                zoom: initialZoom,
                center: this.isLocationEmpty(this.centerLocation) ? this.initialMapLocation : this.centerLocation,
                disableDefaultUI: true,
                mapTypeId: this.mapType,
                ...options,
            });
        },
        createDestinationMarker(google) {
            const icon = {
                url: FlagIconPath,
                scaledSize: new google.maps.Size(50, 50), // maps adds weird 16px to the marker
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(14, 44.6),
            };

            return new google.maps.Marker({
                position: this.centerLocation,
                map: this.map,
                icon: icon,
                zIndex: 2,
            });
        },
        eventListenerCenterChanged() {
            this.map.addListener(
                'center_changed',
                _debounce(async () => {
                    this.$logger().log('Detected change: center position');

                    if (!this.editMode) {
                        this.$logger().log('// skip update, read only');
                        return;
                    }

                    // skip update, map was updated as a side effect
                    if (this.programaticMapUpdate) {
                        this.programaticMapUpdate = false;
                        return;
                    }

                    // skip update if already equals
                    if (locationsEqual(this.map.getCenter(), this.centerLocation)) {
                        this.$logger().log('// skip update if already equals');
                        return;
                    }

                    // reverse gocode address, if map was updated by hand and not by address search
                    if (this.enableSearch) {
                        this.$logger().log('reverse geocode map center');
                        this.handleAddressUpdateByLocation(this.map.getCenter());
                        return;
                    }

                    this.$logger().log('Updated: center position');
                    this.centerLocation = this.map.getCenter();
                }, DEBOUNCE_CHANGED_MAP_CENTER_MS)
            );
        },
        getLocationInfo(location) {
            return new Promise((resolve, reject) => {
                GoogleMaps.then(google => {
                    const geocoder = new google.maps.Geocoder();
                    geocoder.geocode({ location }, (results, status) =>
                        this.resolveGeocodePromise(resolve, reject, results, status)
                    );
                });
            });
        },
        resolveGeocodePromise(resolve, reject, results, status) {
            if (results?.length > 0 && status === 'OK') {
                resolve(results[0]);
            } else {
                reject(null);
            }
        },
        async initializeAddressUpdate(address, location) {
            this.$logger().log('initialize address location');
            const useDefaultAddress = this.isLocationEmpty(location);
            if (useDefaultAddress) {
                location = this.initialMapLocation;
            }

            if (this.enableSearch) {
                if (address) {
                    this.address = address;
                } else {
                    const locationInfo = await this.getLocationInfo(location);
                    const place = this.normalizePlaceResult(locationInfo);

                    // to avoid display of the default location the
                    // address returned from google is overwritten
                    this.address = useDefaultAddress ? emptyAddress : place;
                }
            }

            this.centerLocation = location;

            // google maps is not firing center_changed event initially
            this.centerLocationOnMap(location);
        },
        isLocationEmpty(location) {
            return _isEmpty(location) || !location?.lat || !location?.lng;
        },
        normalizePlaceResult(locationInfo) {
            const place = structurizePlaceResult(locationInfo);
            replacePlaceLabelWithFormattedAddress(place);
            return place;
        },
    },
};

<template>
    <div>
        <Alert type="info" icon-type="warning" class="mb-2">
            {{ $t('pages.organization.form.settingNotSeenByOrganization') }}
        </Alert>

        <template v-if="showInvoiceBillingInterval">
            <h3 class="font-copy-lg-strong mb-4 mt-6 md:col-span-2">
                {{ $t('pages.organization.form.invoiceInterval.title') }}
            </h3>

            <ReactBridge
                :react-component="$options.Combobox"
                :props="{
                    label: $t('pages.organization.form.invoiceInterval.label'),
                    options: Object.values($options.BILLING_INTERVAL).map(value => ({
                        value,
                        label: $t(`pages.organization.form.invoiceInterval.values.${value}`),
                    })),
                    value: org.invoiceBillingInterval,
                    onChange: updateInvoiceBillingInterval,
                }"
            />
            <Alert
                v-if="org.invoiceBillingInterval === $options.BILLING_INTERVAL.MANUALLY"
                type="warning"
                icon-type="warning"
                class="mb-2 mt-2"
            >
                {{ $t('pages.organization.form.monthlyInvoiceWarning') }}
            </Alert>
        </template>

        <template v-if="org.types.includes('carrier') || org.types.includes('supplier')">
            <h3 class="font-copy-lg-strong mb-4 mt-6 md:col-span-2">
                {{ $t('pages.organization.form.partnerInvoicingLabel') }}
            </h3>
            <ToggleSwitchField
                v-model="org.isPartnerInvoicingEnabled"
                data-test="company-settings-toggle-is-partner-invoicing-enabled"
                name="organization-partner-invoicing-switch"
                :disabled="!$can('organizationAccountingSettingManagement')"
            >
                {{ $t('pages.organization.form.partnerInvoicingSwitch') }}
            </ToggleSwitchField>
        </template>

        <h3 class="font-copy-lg-strong mb-4 mt-6 md:col-span-2">
            {{ $t('components.organizationForm.netsuite.title') }}
        </h3>

        <Alert v-if="!org.isExported" type="info" icon-type="warning" dark class="mb-2">
            {{ $t('pages.organization.form.organizationNotYetExported') }}
        </Alert>

        <Alert v-else type="success" class="mb-2">
            {{
                $t('pages.organization.form.organizationHasBeenExported', {
                    date: $d(ensureJSTimestamp(org.exportedAt), 'short'),
                    time: $t('timeSuffix', {
                        time: $d(ensureJSTimestamp(org.exportedAt), 'time'),
                    }),
                })
            }}
        </Alert>

        <div class="mt-4 grid md:grid-cols-2">
            <div v-if="org.types.includes('client')" class="flex flex-col md:col-span-2 md:flex-row">
                <div class="flex-1">
                    <ReactBridge
                        :react-component="$options.PaymentTermsSelection"
                        :props="{
                            disabled: !$can('organizationAccountingSettingManagement'),
                            organizationType: 'client',
                            paymentTermId: org.clientPaymentTerm?.id || null,
                            onChange: id => updatePaymentTerm('client', id),
                        }"
                    />
                </div>
                <div class="flex-1 md:pl-8 md:pt-8">
                    <ToggleSwitchField
                        v-model="org.excludedFromDunning"
                        data-test="company-settings-toggle-exclude-from-dunning"
                        name="organization-lock-switch"
                        class="mt-4"
                        :disabled="!$can('updateOrganizationExcludedFromDunning')"
                    >
                        {{
                            org.excludedFromDunning
                                ? $t('components.organizationForm.netsuite.excludedFromDunningActive')
                                : $t('components.organizationForm.netsuite.excludedFromDunningInactive')
                        }}
                    </ToggleSwitchField>
                </div>
            </div>

            <div v-if="org.types.includes('carrier')">
                <ReactBridge
                    :react-component="$options.PaymentTermsSelection"
                    :props="{
                        disabled: !$can('organizationAccountingSettingManagement'),
                        organizationType: 'carrier',
                        paymentTermId: org.carrierPaymentTerm?.id || null,
                        onChange: id => updatePaymentTerm('carrier', id),
                    }"
                />
            </div>
            <div v-if="org.types.includes('carrier')" class="hidden md:block" />

            <div v-if="org.types.includes('supplier')">
                <ReactBridge
                    :react-component="$options.PaymentTermsSelection"
                    :props="{
                        disabled: !$can('organizationAccountingSettingManagement'),
                        organizationType: 'supplier',
                        paymentTermId: org.supplierPaymentTerm?.id || null,
                        onChange: id => updatePaymentTerm('supplier', id),
                    }"
                />
            </div>
            <div v-if="org.types.includes('supplier')" class="hidden md:block" />
        </div>
    </div>
</template>

<script>
import Alert from '@/components/Alert';
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import { BILLING_INTERVAL } from '@/constants/billingInterval';
import { USER_TYPE } from '@/constants/userTypes';
import { PaymentTermsSelection } from '@/features/PaymentTermsSelection.tsx';
import ReactBridge from '@/reactBridge/ReactBridge.vue';
import { queryClient } from '@/reactBridge/queryClient';
import GlobalVariables from '@/services/GlobalVariables';
import { ensureJSTimestamp } from '@/services/utils/date';
import { Combobox } from '@schuettflix/react-components';
import { ref } from 'vue';

export default {
    name: 'TabAccountingSettings',
    PaymentTermsSelection,
    Combobox,
    BILLING_INTERVAL,

    components: {
        Alert,
        ToggleSwitchField,
        ReactBridge,
    },

    props: {
        organization: {
            type: Object,
            default: () => ({}),
            required: true,
        },
    },

    setup(props, { emit }) {
        const org = ref(props.organization);

        async function updatePaymentTerm(type, id) {
            const types = {
                client: 'clientPaymentTerm',
                carrier: 'carrierPaymentTerm',
                supplier: 'supplierPaymentTerm',
            };

            const organization = org.value;
            organization[types[type]].id = id;
            org.value = organization;

            await queryClient.invalidateQueries(['organization', id]);

            emit('update:organization', org);
        }

        const showInvoiceBillingInterval = ref(false);
        if (org.value.types.includes(USER_TYPE.CLIENT)) {
            GlobalVariables.get('finance_allow_monthly_invoice_billing_interval', false).then(value => {
                showInvoiceBillingInterval.value = value === true;
            });
        }

        function updateInvoiceBillingInterval(interval) {
            org.value.invoiceBillingInterval = interval;
            queryClient.invalidateQueries(['organization', id]);
            emit('update:organization', org);
        }

        return {
            updateInvoiceBillingInterval,
            updatePaymentTerm,
            org,
            showInvoiceBillingInterval,
        };
    },

    methods: {
        ensureJSTimestamp,
    },
};
</script>

/**
 * Millisecond
 * @type {number}
 */
export const MILLISECOND = 1;

/**
 * Plural alias for MILLISECOND
 * @type {number}
 */
export const MILLISECONDS = MILLISECOND;

/**
 * Second in milliseconds
 * @type {number}
 */
export const SECOND = 1000;

/**
 * Plural alias for SECOND
 * @type {number}
 */
export const SECONDS = SECOND;

/**
 * Minute in milliseconds
 * @type {number}
 */
export const MINUTE = 60 * SECONDS;

/**
 * Plural alias for MINUTE
 * @type {number}
 */
export const MINUTES = MINUTE;

/**
 * Hour in milliseconds
 * @type {number}
 */
export const HOUR = 60 * MINUTES;

/**
 * Plural alias for HOUR
 * @type {number}
 */
export const HOURS = HOUR;

/**
 * Day in milliseconds
 * @type {number}
 */
export const DAY = 24 * HOURS;

/**
 * Plural alias for DAY
 * @type {number}
 */
export const DAYS = DAY;

/**
 * Convert ms to seconds
 * @param ms
 * @return {number}
 */
export function inSeconds(ms) {
    return ms / SECOND;
}

/**
 * Convert ms to minutes
 * @param ms
 * @return {number}
 */
export function inMinutes(ms) {
    return ms / MINUTES;
}

/**
 * Convert ms to hours
 * @param ms
 * @return {number}
 */
export function inHours(ms) {
    return ms / HOURS;
}

/**
 * Convert ms to days
 * @param ms
 * @return {number}
 */
export function inDays(ms) {
    return ms / DAYS;
}

import Toaster from '@/services/Toaster';
import { scrollToFirstError } from '@/services/validation/error-scroller';
import { mapErrors } from '@/services/validation/serverErrorsMapper';
import { getValidationMessage, useValidationMessages } from '@/_components/composables/validationMessages';
import { useVuelidate } from '@vuelidate/core';
import { reactive, ref, unref } from 'vue';
import { parseServerValidation } from '@/services/validation/mixin';
import _mapKeys from 'lodash/mapKeys';
import _findKey from 'lodash/findKey';
const createDefaultServerMapping = (dataKeys) => Object.fromEntries(dataKeys.map(key => [key, [key]]));
const convertErrorToVuelidateFormat = (errors, serverErrorMapping, dataKeys) => {
    // new format
    if (Array.isArray(errors)) {
        const mappedForVuelidate = mapErrors(errors);
        const mappedWithServerMapping = _mapKeys(mappedForVuelidate, (_, serverKey) => _findKey(serverErrorMapping, serverKeyMaybeArray => Array.isArray(serverKeyMaybeArray)
            ? serverKeyMaybeArray.includes(serverKey)
            : serverKeyMaybeArray === serverKey) || serverKey);
        return mappedWithServerMapping;
    }
    // old format
    return parseServerValidation(errors?.response?.data?.errors || errors, Object.assign(createDefaultServerMapping(dataKeys), serverErrorMapping));
};
export const useValidation = ({ rules, data, serverErrorMapping = {}, }) => {
    const $externalResults = ref({});
    const setServerErrors = (errors) => {
        $externalResults.value = convertErrorToVuelidateFormat(errors, serverErrorMapping, Object.keys(data));
    };
    const v$ = useVuelidate(rules, data, { $externalResults });
    return { setServerErrors, v$ };
};
export const useForm = ({ data, rules, apiMethod, onSuccess = () => { }, }) => {
    const { v$, setServerErrors } = useValidation({ data, rules });
    const isLoading = ref(false);
    const { translations } = useValidationMessages();
    const submit = async () => {
        const isValid = await v$.value.$validate();
        if (!isValid) {
            scrollToFirstError();
            const firstError = v$.value.$errors[0];
            const propertyNameOfFieldWithError = v$.value.$errors[0].$property;
            const translationMessages = translations(ref(v$.value[propertyNameOfFieldWithError]));
            const translationMessage = translationMessages[firstError.$validator];
            translationMessage && Toaster.error(translationMessage);
            return;
        }
        try {
            isLoading.value = true;
            const unwrappedData = reactive(unref(data));
            const apiResult = await apiMethod(unwrappedData);
            onSuccess(apiResult);
        }
        catch (error) {
            Toaster.error(getValidationMessage(error));
            setServerErrors(error.response?.data || []);
            scrollToFirstError();
        }
        finally {
            isLoading.value = false;
        }
    };
    return { v$, isLoading, submit };
};

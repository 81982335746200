<template>
    <FormWrapper>
        <template #title>
            <ContentWrapper>
                {{ $t('components.factoryManagement.factoryNameAddress.title') }}
            </ContentWrapper>
        </template>
        <ContentWrapper>
            <FormFieldGroup>
                <template #title>
                    <Words bold>{{ $t('components.factoryManagement.factoryNameAddress.locationName') }}</Words>
                </template>
                <ComponentWrapper>
                    <template #componentText>
                        <Words block>{{ $t('components.factoryManagement.factoryNameAddress.locationText') }}</Words>
                    </template>
                    <template #component>
                        <TextField
                            :value="form.name"
                            :readonly="isReadOnly"
                            :label="$t('components.factoryManagement.factoryNameAddress.name')"
                            :has-error="!!firstError[`name`] || hasErrors('form.name', submitted)"
                            :error="firstError[`name`] || getError('form.name', submitted)"
                            data-test="factory-management-name-input"
                            @input="update('name', $event)"
                        />
                    </template>
                </ComponentWrapper>
            </FormFieldGroup>
        </ContentWrapper>

        <ContentWrapper>
            <FormFieldGroup no-bottom-margin>
                <template #title>
                    <Words bold>{{ $t('components.factoryManagement.factoryNameAddress.address') }}</Words>
                    <FormCopyOrganizationData
                        v-if="!isReadOnly"
                        :field-names="[{ billingAddress: 'address' }, 'location']"
                        data-test="form-copy-organization-data-name-address"
                        @datatransfer="$emit('datatransfer', $event)"
                    >
                        <Button arrow-right>
                            <span class="text-sm md:text-md">{{
                                $t('components.factoryManagement.factoryView.transferFromMasterData')
                            }}</span>
                        </Button>
                    </FormCopyOrganizationData>
                </template>
                <Words block>{{ $t('components.factoryManagement.factoryNameAddress.addressText') }}</Words>
            </FormFieldGroup>
        </ContentWrapper>

        <AddressFinderLocationPicker
            :address="form.address"
            :location="form.location"
            :is-read-only="isReadOnly"
            :is-loading="isAllowedCountriesLoading"
            :allowed-countries="allowedCountries"
            @input="handleAddressUpdate"
            @flyoutOpen="flyoutOpen"
        />

        <ContentWrapper v-if="hasAddressLocation">
            <FormFieldGroup no-bottom-margin>
                <ComponentWrapper>
                    <template #componentText>
                        <Words block>{{
                            $t('components.factoryManagement.factoryNameAddress.importantInformationText')
                        }}</Words>
                    </template>
                    <template #component>
                        <TextField
                            :value="form.importantInformation"
                            :readonly="isReadOnly"
                            :label="$t('components.factoryManagement.factoryNameAddress.importantInformation')"
                            :has-error="
                                !!firstError['importantInformation'] ||
                                hasErrors('form.importantInformation', submitted)
                            "
                            :error="
                                firstError['importantInformation'] || getError('form.importantInformation', submitted)
                            "
                            @input="update('importantInformation', $event)"
                        />
                    </template>
                </ComponentWrapper>
            </FormFieldGroup>
        </ContentWrapper>
    </FormWrapper>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';

import validate from '@/services/validation/mixin';
import nameAddressMixin from '@/plugins/mixins/factoryForm/nameAddressMixin';
import sectionResetMixin from '@/plugins/mixins/factoryForm/sectionResetMixin';
import sectionMixin from '@/plugins/mixins/factoryForm/sectionMixin';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { useMarketPermission } from '@/services/MarketPermission/useMarketPermission';

import { isRequired } from '@/services/validation/rules';

import AddressFinderLocationPicker from '@/_components/AddressFinderLocationPicker/AddressFinderLocationPicker';
import Button from '@/components/Button/Button';
import ComponentWrapper from '@/_components/ComponentWrapper/ComponentWrapper';
import ContentWrapper from '@/_components/ContentWrapper/ContentWrapper';
import FormCopyOrganizationData from '@/_components/FormCopyOrganizationData/FormCopyOrganizationData';
import FormFieldGroup from '@/_components/FormFieldGroup/FormFieldGroup';
import FormWrapper from '@/_components/FormWrapper/FormWrapper';
import TextField from '@/components/Form/TextField.v2';
import Words from '@/components/Typography/Words';

import { MPS_SUPPLIER_FACTORY_MANAGEMENT } from '@/constants/marketPermissions';

export default {
    name: 'FactoryNameAddress',
    components: {
        AddressFinderLocationPicker,
        ComponentWrapper,
        ContentWrapper,
        Button,
        FormCopyOrganizationData,
        FormFieldGroup,
        FormWrapper,
        TextField,
        Words,
    },
    mixins: [validate, nameAddressMixin, sectionResetMixin, sectionMixin, eventHubMixin],
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { allowedCountries, isLoading: isAllowedCountriesLoading } = useMarketPermission(
            MPS_SUPPLIER_FACTORY_MANAGEMENT
        );

        return {
            allowedCountries,
            isAllowedCountriesLoading,
        };
    },
    computed: {
        validationRules() {
            return {
                // Name and Address Validation
                'form.name': [isRequired()],
                'form.address.street': [],
                'form.address.number': [],
                'form.address.zip': [],
                'form.address.city': [isRequired()],
                'form.address.state': [isRequired()],
                'form.address.country': [isRequired()],
                'form.location.lng': [isRequired()],
                'form.location.lat': [isRequired()],
                'form.importantInformation': [],
            };
        },
    },
    watch: {
        value: {
            deep: true,
            handler(data) {
                this.sectionDataBackupOpenStatusReset(data.open);
            },
        },
        form: {
            deep: true,
            handler(sectionData) {
                // When the factory is published the user is directed to the NameAddress view. At this point a modal will be visible.
                // Here we look to see if that modal is active and the current factory is published
                if (this.$route.query.modal && this.$route.meta.type === 'factoryEdit') {
                    this.createSectionDataBackup();
                }
                this.$emit('confirmation-dialog', null);
                this.$emit('dirty-check', this.checkDirty(sectionData, this.sectionDataBackup));
                this.$emit('valid-check', this.isValid(false));
            },
        },
    },
    mounted() {
        this.$emit('dirty-check', false);
    },
    methods: {
        handleLocationUpdate(location) {
            this.update('location', location);
        },
        handleAddressUpdate({ address, location }) {
            const valueClone = _cloneDeep(this.value);
            _set(valueClone, 'address', address);
            _set(valueClone, 'location', location);

            this.$emit('input', valueClone);
        },
        flyoutOpen($event) {
            this.$eventHub.$emit('dialogModalStatus', $event);
        },
    },
};
</script>

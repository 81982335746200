<template>
    <SfTag type="error" class="whitespace-nowrap">
        <SfInfoIcon size="xxs" class="mr-2" theme="dark" />
        {{ $tc('components.transportListBlock.validityTag.label', count) }}
    </SfTag>
</template>

<script>
import { SfTag, SfInfoIcon } from '@schuettflix/vue-components';

/**
 * !!! ATTENTION !!!
 * This component and all of it's children can not have computed
 * properties since they introduce a performance issues on unmounting
 */
export default {
    name: 'TransportListBlockValidityTag',
    components: {
        SfTag,
        SfInfoIcon,
    },
    props: {
        count: {
            type: Number,
            required: true,
        },
    },
};
</script>

<template>
    <component
        :is="tag"
        :class="[
            'formatted-address',
            {
                'font-copy-md-strong': bold && !small,
                'font-copy-md': !bold && !small,
                'font-copy-sm-strong': bold && small,
                'font-copy-sm': !bold && small,
                'text-subdued': subdued,
                'pt-1': !twoLineAddress,
                'text-brand-primary': isHighlighted,
            },
        ]"
    >
        <template v-if="!twoLineAddress">{{ formattedAddress }}</template>
        <span v-else v-html="twoLineFormattedAddress" />
        <span
            v-if="hasLocationCode && !hideLocationCode"
            class="font-copy-sm"
            :class="{ 'text-subdued': decorateLocationCode }"
        >
            {{ $t('components.openLocationCodeField.label') }}:
            <span class="font-numbers-sm inline pl-1 text-subdued">{{ location.openLocationCode }}</span>
        </span>
    </component>
</template>
<script>
import isEmpty from 'lodash/isEmpty';
import { formatAddressObject } from '@/services/utils/address';

export default {
    name: 'FormattedAddress',
    props: {
        address: {
            type: Object,
            required: true,
        },
        hideAdditionalAddress: {
            type: Boolean,
            default: false,
        },
        hideLocationCode: {
            type: Boolean,
            default: false,
        },
        includeCountry: {
            type: Boolean,
            default: false,
        },
        includeState: {
            type: Boolean,
            default: false,
        },
        twoLineAddress: {
            type: Boolean,
            default: false,
        },
        decorateLocationCode: {
            type: Boolean,
            default: false,
        },
        hideCountryCode: {
            type: Boolean,
            default: false,
        },
        bold: {
            type: Boolean,
            default: false,
        },
        subdued: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        tag: {
            type: String,
            default: 'span',
        },
        isHighlighted: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        location() {
            return this.address?.address?.location ?? this.address?.location;
        },
        hasLocationCode() {
            return Boolean(this.location?.openLocationCode);
        },
        twoLineFormattedAddress() {
            const address = this.address?.address || this.address;
            if (!address) return '';

            return `${this.streetName(address.street)} ${this.streetNumber(address.number)}<div class="font-copy-sm">${
                address.zip
            } ${address.city}, ${address.country}<div>`;
        },

        formattedAddress() {
            const { includeCountry, includeState } = this;
            const address = this.address?.address || this.address;

            if (!address) return '';

            return formatAddressObject({
                address,
                location: this.location,
                includeCountry,
                includeState,
                withAdditionalAddress: !this.hideAdditionalAddress,
                includeCountryCode: !this.hideCountryCode,
            });
        },
    },
    methods: {
        streetName(street) {
            return isEmpty(street) ? this.$t('pages.organization.factoryList.noStreet') : street;
        },
        streetNumber(number) {
            return number === '0' || number === 0 || number === undefined
                ? this.$t('pages.organization.factoryList.noNumber')
                : number;
        },
    },
};
</script>

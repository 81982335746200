import _cloneDeep from 'lodash/cloneDeep';

import FactoriesApi from '@/services/Api/Factories';
import FactoryDraftsApi from '@/services/Api/FactoryDrafts';
import FactoryDraftsService from '@/services/FactoryDraftsService';
import Toaster from '@/services/Toaster';

import { mapActions } from 'vuex';

import { FACTORY_TYPES, USAGES } from '@/constants/disposal';
import { FACTORY_MANAGEMENT_META_TYPES, FACTORY_ROUTE_NAMES } from '@/constants/factoryForm';
import _merge from 'lodash/merge';

import store from '@/store';

/**
 * Cleanup irrelevant data from the payload
 *
 * @param {Object} factory
 * @return {Object}
 */
export const cleanupFactoryPayload = factory => {
    const form = _cloneDeep(factory);
    const userIsPlatformAdmin = store.getters['user/isPlatformAdministrator'];

    if (!userIsPlatformAdmin && form?.platformSettings) {
        delete form.platformSettings;
    }

    if (form.disposalSettings?.factoryType === FACTORY_TYPES.DUMPING_SITE) {
        delete form.disposerNumber;
    }

    if (form.disposalSettings && form.disposalSettings?.factoryType !== FACTORY_TYPES.LANDFILL) {
        delete form.disposalSettings.landfillClass;
    }

    if ((form?.usages || []).includes(USAGES.DISPOSAL)) {
        return form;
    }

    delete form.disposalSettings;
    delete form.disposalPrincipleDocument;
    delete form.disposerNumber;

    return form;
};

export const routeMapping = (route, mapping) => {
    return route in mapping ? mapping[route] : route;
};

export default {
    methods: {
        ...mapActions('validation', {
            populateErrors: 'populate',
            cleanupErrors: 'cleanup',
        }),
        updateDataFromOrganization(payload) {
            Object.keys(payload).forEach(field => {
                this.$set(this.factory, field, payload[field]);
            });
        },
        async saveFactory(factory, showToast = true) {
            try {
                const payload = cleanupFactoryPayload(factory);
                const mapping = routeMapping(this.$route.query.route, {
                    'name-address': 'name-and-address',
                    'weighing-types': 'weighing-type',
                });
                const factoryData =
                    this.$route.meta.type === FACTORY_MANAGEMENT_META_TYPES.FACTORY_EDIT
                        ? await FactoriesApi.put(factory.id, mapping, payload)
                        : await FactoryDraftsService.put(factory.id, mapping, payload);
                _merge(factory, factoryData);

                this.$set(this, 'factory', factory);
                this.factoryBackup = _cloneDeep(this.factory);

                this.isDirty = false;
                if (showToast) {
                    Toaster.success(this.$t('components.factoryManagement.factoryView.factorySavedToast'));
                }
                this.cleanupErrors();
                return true;
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(this.$t('components.toaster.callbackErrorMessage'));

                if (err.response) {
                    this.populateErrors(err.response?.data);
                }

                return false;
            }
        },
        async deleteFactoryRequest(isPublished) {
            await this.stateful('delete', async () => {
                try {
                    if (isPublished) {
                        await FactoriesApi.delete(this.factory.id);
                    } else {
                        await FactoryDraftsApi.delete(this.factory.id);
                    }
                    this.$emit('closed');
                    this.$emit('updateList');
                    Toaster.success(this.$t('components.factoryManagement.factoryView.factoryDeletedToast'));

                    this.$nextTick(() => {
                        if (this.parentRoute?.path !== '') {
                            this.$router
                                .push({
                                    path: this.parentRoute.path,
                                })
                                .then(() => this.setNavigationCollapsed(false));
                        } else {
                            this.$router
                                .push({
                                    name: this.$root.findRouteName(FACTORY_ROUTE_NAMES.PARENT_ROUTE_NAME),
                                })
                                .then(() => this.setNavigationCollapsed(false));
                        }
                    });
                } catch (err) {
                    this.$logger().log(err);
                    Toaster.error(err);
                }
            });
        },
        async publishDraftFactory(factoryId) {
            try {
                return await FactoryDraftsService.publish(factoryId);
            } catch (err) {
                this.$logger().log(err);
            }
        },
        async toggleOpenCloseStatus(factoryId) {
            try {
                const factory = await FactoriesApi.toggleOpenClose(factoryId);
                if (factory) {
                    Toaster.success(this.$t('components.factoryManagement.factoryView.factorySavedToast'));
                    return factory;
                }
            } catch (err) {
                this.$logger().log(err);
            }
        },
    },
};

<template>
    <div class="cost-overview-sections">
        <div class="mb-2 flex flex-wrap justify-between">
            <span class="font-copy-md-strong">
                {{ $t('pages.checkout.disposal.summary.tiles.costPreview.headline') }}
            </span>
            <span class="font-copy-md ml-auto text-subdued">
                {{
                    fixedPrice
                        ? `${getCurrencySign()} / ${$t('units.transport')}`
                        : $t('pages.checkout.disposal.summary.tiles.costPreview.note')
                }}
            </span>
        </div>

        <div v-for="(vehicleClass, vid) in selectedTransports" :key="vid" :class="['mt-6', 'first:mt-0']">
            <header class="mb-2 flex items-start justify-between">
                <h3 class="font-copy-md-strong">
                    {{ vehicleClass.count }} x {{ vehicleClass.name }}
                    <template v-if="material.extension"> - {{ material.extension }}</template>
                </h3>
            </header>
            <ul class="font-copy-md m-0 list-none p-0">
                <li>
                    <PriceRow
                        :label="$t('general.purchasePrice.fullTitle')"
                        :price-per-weight="!fixedPrice ? vehicleClass.purchasePricePerTon : null"
                        :price-per-transport="fixedPrice ? vehicleClass.purchasePricePerTransport : null"
                        :fixed-price="fixedPrice"
                        show-both-prices
                    />
                </li>
                <li>
                    <PriceRow
                        class="mt-2"
                        :label="$t('general.retailPrice.fullTitle')"
                        :price-per-weight="!fixedPrice ? vehicleClass.retailPricePerTon : null"
                        :price-per-transport="fixedPrice ? vehicleClass.retailPricePerTransport : null"
                        :fixed-price="fixedPrice"
                        show-both-prices
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import MaterialPricingView from '@/pages/Checkout/components/PriceAdjustment/models/MaterialPricingView';
import TransportPricingCollectionView from '@/pages/Checkout/components/PriceAdjustment/models/TransportPricingCollectionView';

import PriceRow from '@/pages/Checkout/Disposal/Components/PriceRow';
import { getCurrencySign } from '@/services/utils/currency';

export default {
    name: 'CostOverviewSection',
    components: {
        PriceRow,
    },
    props: {
        transports: {
            type: [TransportPricingCollectionView, Array],
            required: true,
        },
        material: {
            type: [MaterialPricingView, Object],
            required: true,
        },
        fixedPrice: {
            type: Boolean,
            default: false,
        },
        note: {
            type: String,
            default: null,
        },
    },
    computed: {
        selectedTransports() {
            if (this.transports instanceof TransportPricingCollectionView)
                return this.transports.collection
                    .map(transport => ({
                        ...transport,
                        count: transport.count,
                        name: `${transport.info} - ${this.material.info}`,
                        purchasePricePerTon: transport.getPurchaseUnitPriceForMaterial(this.material),
                        purchasePricePerTransport: transport.getPurchasePriceTotalsForMaterial(this.material),
                        retailPricePerTon: transport.getRetailUnitPriceForMaterial(this.material),
                        retailPricePerTransport: transport.getRetailPriceTotalsForMaterial(this.material),
                    }))
                    .filter(transport => transport._enabled);

            return this.transports.map(transport => ({
                ...transport,
                count: transport.count || 1,
                name: transport.vehicleClassName || transport.name,
                purchasePricePerTon: transport.customPrice.purchasePricePerTon,
                purchasePricePerTransport: transport.customPrice.purchasePricePerTransport,
                retailPricePerTon: transport.customPrice.retailPricePerTon,
                retailPricePerTransport: transport.customPrice.retailPricePerTransport,
            }));
        },
    },
    methods: {
        getCurrencySign,
    },
};
</script>

<style lang="scss"></style>

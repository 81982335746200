import { getStateName } from '@/services/StateMapping';

import { getCountryCode, getCountryName } from '@/services/CountryMapping';
import { storeInstance } from '@/utils/instances';

/**
 * @deprecated Use the formatAddress hook from /src/utils/useAddressFormatter instead.
 * Format a standard address object to a oneliner
 * should only be used internally as replaced/wrapped by formatAddressObject below
 *
 * @param {object} Object with the required key 'address' an address object model
 *     optional keys are:
 *         separator: a string used for concatenation of address parts
 *         withAdditionalAddress: a Boolean determining if additional address is rendered
 *         includeState: a Boolean determining if state is rendered in the address
 *         includeCountry: a Boolean determining if country name is rendered in the address
 *         includeCountryCode: a Boolean determining if conuntry code is rendered in the address
 * @return {string} a string representation of the address
 */
export function formatAddress({
    address,
    separator = ', ',
    withAdditionalAddress = true,
    includeState = false,
    includeCountry = false,
    includeCountryCode = true,
} = {}) {
    if (!address) {
        return '';
    }

    let { street, countryCode } = address;
    const { additionalAddress, number, city, zip } = address;
    const locality = [zip, city].filter(part => part).join(' ');

    const parts = [];

    if (additionalAddress && withAdditionalAddress) {
        parts.push(additionalAddress);
    }

    if (street) {
        if (number) {
            street += ` ${number}`;
        }
        parts.push(street);
    }

    if (locality) {
        parts.push(locality);
    }

    if (includeState) {
        const state = getStateName(address.state, address.state);
        if (state) {
            parts.push(state);
        }
    }

    if (includeCountry) {
        const country = getCountryName(address.country, address.country);

        if (includeCountry) {
            parts.push(country);
        }
    }

    if (includeCountryCode) {
        countryCode = getCountryCode(address.country);
        if (countryCode) {
            parts.push(countryCode);
        }
    }

    return parts.join(separator);
}

// TODO check formatConstructionSiteAddress() usage
// TODO: check hasAddressWithLocationCode() usage
/**
 * Format a address object or an address container to a oneliner
 * remove Unnamed Roads (as reported by google) and outputs
 * latitude/longitude as fallback
 *
 * @param {object} object either an address model or an object containing the address model
 *    optional properties include a location model and parameters passed to formatAddress
 */
export function formatAddressObject(
    {
        address: givenAddress,
        location,
        separator = ', ',
        includeCountry = false,
        includeState = false,
        withAdditionalAddress = true,
        includeCountryCode = true,
    } = {},
    { useLocationCodeAddressIsIncomplete } = {
        useLocationCodeAddressIsIncomplete: false,
    }
) {
    const address = givenAddress?.unfold ? givenAddress.unfold() : givenAddress;
    const loc = location ?? address?.location;

    if (!address && !loc) {
        return '';
    }

    const { lat, lng } = loc ?? {};

    const UNNAMED_ROAD = 'Unnamed Road';

    const formattedCoordinates = lat && lng ? `${lat}, ${lng}` : '';
    const isAddressIncomplete = !(
        address?.street &&
        address?.number &&
        address?.zip &&
        address?.city &&
        address?.country
    );
    const showLocationCode = !!(useLocationCodeAddressIsIncomplete && isAddressIncomplete && formattedCoordinates);

    if (!showLocationCode && (address?.street || address?.zip || address?.city))
        return formatAddress({
            address: {
                ...address,
                street: address?.street === UNNAMED_ROAD ? '' : address.street,
            },
            separator,
            withAdditionalAddress,
            includeCountry,
            includeState,
            includeCountryCode,
        });

    return formattedCoordinates;
}

export function hasAddressWithLocationCode({ address, location } = {}) {
    const loc = location ?? address?.location;
    const UNNAMED_ROAD = 'Unnamed Road';

    return Boolean(!(address && loc) || address?.street === UNNAMED_ROAD || (address && !('number' in address)));
}

export const emptyAddress = {
    label: '',
    location,
    street: '',
    city: '',
    street_number: '',
    number: '',
    postalCode: '',
    zip: '',
    countryCode: '',
};

/**
 * Validate allowed country codes for place-autocomplete api
 * Source: https://developers.google.com/maps/documentation/javascript/places-autocomplete
 *
 * @param {string[]} countryList
 * @returns {boolean}
 */
export function allowedPlaceAutocompleteCountriesValidator(countryList) {
    if (!Array.isArray(countryList)) {
        return false;
    }
    return countryList.length && countryList.length <= 5 && countryList.every(country => country.length === 2);
}

/**
 * Get a list of fallback countries for a place autocompletion restriction
 * @returns {string[]}
 */
export function getFallbackPlaceAutocompletionCountries() {
    const store = storeInstance.get();
    // NOTE: Since the market does not contain the country code, we use market code as fallback
    const marketCountry = store.getters['user/market']?.code;
    return marketCountry ? [marketCountry] : [];
}

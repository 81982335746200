import VehicleClassApi from '@/services/Api/VehicleClass';
import { ref } from 'vue';
export function useVehicleClasses({ vehicleClassId }) {
    const vehicleClass = ref();
    const vehicleClassList = ref([]);
    const preselect = () => {
        vehicleClass.value = vehicleClassList.value.find(vc => vc.id === vehicleClassId) ?? undefined;
    };
    const refresh = async () => {
        vehicleClassList.value = await VehicleClassApi.getAll();
        if (!vehicleClass.value) {
            preselect();
        }
    };
    return {
        vehicleClass,
        vehicleClassList,
        refresh,
    };
}

<template>
    <SfInfoWithContext
        v-if="transport.forSubcontractor"
        class="transport-list-block__meta-item-inner"
        :context="$t('components.transportListBlock.participants.carrier')"
        truncate
    >
        <span class="grid grid-cols-[auto_1fr] items-center gap-2">
            <SfPartnershipIcon size="xs" />
            <span class="truncate">
                {{
                    $t('components.transportListBlock.subcontractor.prefix', {
                        companyName: transportCarrier(),
                    })
                }}
            </span>
        </span>
    </SfInfoWithContext>
    <div v-else class="flex gap-6" :class="{ 'flex-wrap': isMobile }">
        <SfInfoWithContext truncate :context="$t('components.transportListBlock.participants.carrier')">
            <span class="truncate">
                <template v-if="transportCarrier()">{{ transportCarrier() }}</template>
                <template v-else>&dash;</template>
            </span>
        </SfInfoWithContext>
        <SfInfoWithContext truncate :context="$t('components.transportListBlock.licensePlateLabel')">
            <span class="truncate">
                <template v-if="transport.vehicle">{{ transport.vehicle.licensePlate }}</template>
                <template v-else>&dash;</template>
            </span>
        </SfInfoWithContext>
        <SfInfoWithContext truncate :context="$t('components.transportListBlock.verhicleClassLabel')">
            <span class="truncate">
                <template v-if="transport.vehicleName">
                    {{ transport.vehicleName }} / {{ transport.vehiclePayload | tons }}
                </template>
                <template v-else>&dash;</template>
            </span>
        </SfInfoWithContext>

        <!-- Disposal accompanying document number -->
        <div v-if="transport.product && transport.product.isDangerous">
            <span class="font-copy-sm mb-2 text-subdued">
                {{ $t('components.activityCards.disposal.planned.accompanyingDocumentNumberLabel') }}
            </span>
            <ReactBridge
                :react-component="DisposalDocumentNumber"
                :props="{
                    documentNumber: transport.accompanyingDocumentNumber,
                }"
            />
        </div>
    </div>
</template>

<script>
import ReactBridge from '@/reactBridge/ReactBridge.vue';
import DisposalDocumentNumber from '@/_components/DisposalDocumentNumber/DisposalDocumentNumber';
import { TRANSPORT_TYPE } from '@/constants/transportTypes';
import { SfInfoWithContext, SfPartnershipIcon } from '@schuettflix/vue-components';
/**
 * !!! ATTENTION !!!
 * This component and all of it's children can not have computed
 * properties since they introduce a performance issues on unmounting
 */
export default {
    name: 'TransportListBlockCarrierInfo',
    components: {
        ReactBridge,
        SfInfoWithContext,
        SfPartnershipIcon,
    },
    props: {
        transport: {
            type: Object,
            required: true,
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            DisposalDocumentNumber,
        };
    },
    methods: {
        transportCarrier() {
            if (this.transport.type === TRANSPORT_TYPE.PICKUP) {
                return this.transport.client.name;
            }

            return (this.transport.carrier && this.transport.carrier.name) || null;
        },
    },
};
</script>

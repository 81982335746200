export const MPS_CUSTOMER_CONSTRUCTION_SITE_LOCATIONS = 'customer::construction_site_locations';
export const MPS_SUPPLIER_FACTORY_MANAGEMENT = 'supplier::factory_management';
export const MPS_CARRIER_TRANSPORT_EXCHANGE_PICKABLE_TRANSPORTS_SHIPMENT =
    'carrier::transport_exchange_pickable_transports_shipment';
export const MPS_CARRIER_TRANSPORT_EXCHANGE_PICKABLE_TRANSPORTS_DISPOSAL =
    'carrier::transport_exchange_pickable_transports_disposal';
export const MPS_CARRIER_TRANSPORT_EXCHANGE_PICKABLE_TRANSPORTS_DELIVERY =
    'carrier::transport_exchange_pickable_transports_delivery';
export const MPS_PLATFORM_ORDER_CUSTOM_DELIVERY_CONSTRUCTION_SITES =
    'platform::order_custom_delivery_construction_sites';
export const MPS_PLATFORM_ORDER_PROJECT_DELIVERY_CONSTRUCTION_SITES =
    'platform::order_project_delivery_construction_sites';
export const MPS_CUSTOMER_ORDER_DELIVERY_CONSTRUCTION_SITES = 'customer::order_delivery_construction_sites';
export const MPS_CUSTOMER_ORDER_PICKUP_CONSTRUCTION_SITES = 'customer::order_pickup_construction_sites';
export const MPS_PLATFORM_ORDER_CUSTOM_DELIVERY_SUPPLIER_LOCATIONS =
    'platform::order_custom_delivery_supplier_locations';
export const MPS_PLATFORM_ORDER_PROJECT_DELIVERY_SUPPLIER_LOCATIONS =
    'platform::order_project_delivery_supplier_locations';
export const MPS_CUSTOMER_ORDER_DELIVERY_SUPPLIER_LOCATIONS = 'customer::order_delivery_supplier_locations';
export const MPS_CUSTOMER_ORDER_PICKUP_SUPPLIER_LOCATIONS = 'customer::order_pickup_supplier_locations';
export const MPS_CUSTOMER_ORDER_SHIPMENT_LOADING_UNLOADING_LOCATIONS =
    'customer::order_shipment_loading_unloading_locations';
export const MPS_PLATFORM_ORDER_CUSTOM_SHIPMENT_LOADING_UNLOADING_LOCATIONS =
    'platform::order_custom_shipment_loading_unloading_locations';
export const MPS_PLATFORM_ORDER_PROJECT_SHIPMENT_LOADING_UNLOADING_LOCATIONS =
    'platform::order_project_shipment_loading_unloading_locations';

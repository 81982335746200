<template>
    <MaximumWeightIcon :id="getDomId" />
</template>

<script>
import { animateZoomInWithGroupZoomInOut } from '@/services/Animation/Animation';
import MaximumWeightIcon from '@/assets/icons/freight/maximum-weight.svg';

export default {
    name: 'AnimatedMaximumWeight',
    components: {
        MaximumWeightIcon,
    },
    computed: {
        getDomId() {
            return `AnimatedMaximumWeightSVG${this._uid}`;
        },
    },
    mounted() {
        animateZoomInWithGroupZoomInOut(
            `#${this.getDomId} > .svg-maximum-weight`,
            `#${this.getDomId} > .svg-maximum-weight__hand`
        );
    },
};
</script>

<style lang="scss" scoped></style>

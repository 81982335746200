import { useIsMutating, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import CertificateApi, { certificateCacheKey, certificateListCacheKey, certificateMutationKey, } from '@/services/Api/Certificate';
import HeaderBar from './components/HeaderBar';
import SfSysArrowLeftIcon from './icons/SfSysArrowLeftIcon';
import { useVueEmit } from '@/reactBridge/EmitVueEventProvider';
import { Button, Combobox } from '@schuettflix/react-components';
import FlyoutPageContent from './components/FlyoutPageContent';
import CertificateForm from './components/CertificateForm';
import { useId, useRef, useState } from 'react';
import { ensureJSTimestamp } from '@/services/utils/date';
import LoadingSpinner from './components/LoadingSpinner';
import { useAppDimension } from '@/reactBridge/useAppDimension';
import clsx from 'clsx';
import { useScreenName } from '@schuettflix/analytics-react';
export default function CertificateManagementEditFlyoutPage({ certificateId, organizationId, }) {
    useScreenName('certificate-edit');
    const { t } = useTranslation();
    const { emitVueEvent } = useVueEmit();
    const { data: certificateData, isLoading: isCertLoading } = useQuery(certificateCacheKey(certificateId), () => CertificateApi.getOneById(certificateId));
    const queryClient = useQueryClient();
    const isCertificateMutating = useIsMutating(certificateMutationKey(certificateId));
    const formId = useId();
    const formRef = useRef(null);
    const { isDesktop } = useAppDimension();
    const [isDirty, setIsDirty] = useState(false);
    const savedHandler = () => {
        emitVueEvent('saveCertificate');
        queryClient.invalidateQueries(certificateCacheKey(certificateId));
        queryClient.invalidateQueries(certificateListCacheKey(organizationId));
    };
    const header = (<HeaderBar arrow={<button onClick={() => emitVueEvent('closeFlyout')}>
                    <SfSysArrowLeftIcon size="sm"/>
                </button>} headline={t('pages.certificateManagement.update.headline')} subline={certificateData?.updated
            ? t('pages.certificateManagement.update.lastChanged', {
                val: ensureJSTimestamp(certificateData.updated),
                formatParams: {
                    val: {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    },
                },
            })
            : undefined}/>);
    const footer = (<div className={clsx({
            'flex justify-end border-t border-divider bg-surface px-4 py-[20px]': isDesktop,
        })}>
            {isDirty && (<Button variant="secondary" className={clsx({ 'w-full border-t border-divider': !isDesktop })} size={isDesktop ? 'sm' : 'md'} label={t('pages.certificateManagement.update.actions.reset')} onClick={() => formRef.current?.reset()}/>)}
            <Button className={clsx({ 'w-full': !isDesktop })} size={isDesktop ? 'sm' : 'md'} label={t('pages.certificateManagement.update.actions.update')} type="submit" form={formId} disabled={!isDirty || isCertLoading || !!isCertificateMutating}/>
        </div>);
    return (<FlyoutPageContent header={header} footer={footer}>
            {isCertLoading && (<div className="container-content flex items-center justify-center py-6">
                    <LoadingSpinner />
                </div>)}
            {certificateData && (<div className="container-content flex flex-col gap-12 py-6">
                    {/* Cert Type */}
                    <div>
                        <p className="mb-6">{t('pages.certificateManagement.update.typeSelection.title')}</p>
                        {certificateData.certificateType && (<Combobox value={certificateData.certificateType.id} label={t('pages.certificateManagement.add.typeSelection.label')} className="pointer-events-none brightness-95" onChange={() => { }} options={[
                    {
                        label: certificateData.certificateType.documentType.label,
                        value: certificateData.certificateType.id,
                    },
                ]}/>)}
                    </div>

                    {/* Cert information */}
                    <CertificateForm ref={formRef} certificateId={certificateData.id} formId={formId} organizationId={organizationId} formData={{
                certificateTypeId: certificateData.certificateType.id,
                certificateTypeIdentifier: certificateData.certificateType.documentType.code,
                document: certificateData.document,
                factories: certificateData.factories?.map((item) => item.id.toString()),
                validityEndDate: certificateData.validityEndDate ?? null,
                canTransportWaste: certificateData.canTransportWaste,
            }} defaultDate={certificateData.validityEndDate ?? null} onSaved={savedHandler} certificateType={certificateData.certificateType} onDirtyChange={status => setIsDirty(status)}/>

                    <div />
                </div>)}
        </FlyoutPageContent>);
}

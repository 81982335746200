<template>
    <GridRow :count="2" spacing="large" class="user-filter-set container-deprecated">
        <div v-if="isPlatformAdmin" class="span-2">
            <TextField
                v-model="filterScope.filter.organizationName"
                :label="$t('pages.user.userList.filter.organizationName')"
            />
        </div>

        <div class="span-2">
            <TextField v-model="filterScope.filter.mobile" :label="$t('pages.user.userList.filter.mobile')" />
        </div>

        <div class="span-2">
            <TextField v-model="filterScope.filter.email" :label="$t('pages.user.userList.filter.email')" />
        </div>

        <div class="span-2">
            <MultiselectBox
                v-model="filterScope.filter.isActive"
                :options="[
                    {
                        value: true,
                        label: $t('pages.user.userList.filter.statusActive'),
                    },
                    {
                        value: false,
                        label: $t('pages.user.userList.filter.statusInactive'),
                    },
                ]"
                :option-value-renderer="option => option.value"
                :search-label-renderer="option => option.label"
                :label="$t('pages.user.userList.filter.status')"
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1">
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ buttonScope.selectedOptions[0].label }}
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <Words v-if="optionScope.isActive" red>
                        {{ optionScope.option.label }}&nbsp;<TickIcon class="icon--inline icon--red" />
                    </Words>
                    <Words v-else>
                        {{ optionScope.option.label }}
                    </Words>
                </template>
            </MultiselectBox>
        </div>
    </GridRow>
</template>

<script>
import { ensurePHPTimestamp } from '@/services/utils/date';
import { revertLocalizedValue } from '@/services/utils/localization';
import routeContext from '@/plugins/mixins/routeContext';
import { CONTEXT_PLATFORM } from '@/constants/context';

import DefaultInner from '@/components/Form/MultiselectBox/DefaultInner';
import GridRow from '@/components/Layout/GridRow';
import MultiselectBox from '@/components/Form/MultiselectBox';
import TextField from '@/components/Form/TextField.v2';
import Words from '@/components/Typography/Words';

import TickIcon from '@/assets/icons/micro/tick.svg';

export default {
    name: 'UserFilterSet',
    components: {
        DefaultInner,
        GridRow,
        MultiselectBox,
        TextField,
        Words,
        TickIcon,
    },
    mixins: [routeContext],
    props: {
        filterScope: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isPlatformAdmin() {
            return this.inRouteContext(CONTEXT_PLATFORM);
        },
    },
    methods: {
        ensurePHPTimestamp,
        revertLocalizedValue,
    },
};
</script>

<style lang="scss">
.user-filter-set {
    margin-top: 25px;
    margin-bottom: 15px;
}
</style>

import { useCallback, useState } from 'react';
export function useSearchInput() {
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const closeSearch = useCallback(() => {
        setIsSearchOpen(false);
        setSearchQuery('');
    }, []);
    const openSearch = useCallback(() => {
        setIsSearchOpen(true);
    }, []);
    return {
        closeSearch,
        openSearch,
        isSearchOpen,
        searchQuery,
        setSearchQuery,
    };
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Alert',{staticClass:"mb-2",attrs:{"type":"info","icon-type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('pages.organization.form.settingNotSeenByOrganization'))+" ")]),(_vm.showInvoiceBillingInterval)?[_c('h3',{staticClass:"font-copy-lg-strong mb-4 mt-6 md:col-span-2"},[_vm._v(" "+_vm._s(_vm.$t('pages.organization.form.invoiceInterval.title'))+" ")]),_c('ReactBridge',{attrs:{"react-component":_vm.$options.Combobox,"props":{
                label: _vm.$t('pages.organization.form.invoiceInterval.label'),
                options: Object.values(_vm.$options.BILLING_INTERVAL).map(value => ({
                    value,
                    label: _vm.$t(`pages.organization.form.invoiceInterval.values.${value}`),
                })),
                value: _vm.org.invoiceBillingInterval,
                onChange: _vm.updateInvoiceBillingInterval,
            }}}),(_vm.org.invoiceBillingInterval === _vm.$options.BILLING_INTERVAL.MANUALLY)?_c('Alert',{staticClass:"mb-2 mt-2",attrs:{"type":"warning","icon-type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('pages.organization.form.monthlyInvoiceWarning'))+" ")]):_vm._e()]:_vm._e(),(_vm.org.types.includes('carrier') || _vm.org.types.includes('supplier'))?[_c('h3',{staticClass:"font-copy-lg-strong mb-4 mt-6 md:col-span-2"},[_vm._v(" "+_vm._s(_vm.$t('pages.organization.form.partnerInvoicingLabel'))+" ")]),_c('ToggleSwitchField',{attrs:{"data-test":"company-settings-toggle-is-partner-invoicing-enabled","name":"organization-partner-invoicing-switch","disabled":!_vm.$can('organizationAccountingSettingManagement')},model:{value:(_vm.org.isPartnerInvoicingEnabled),callback:function ($$v) {_vm.$set(_vm.org, "isPartnerInvoicingEnabled", $$v)},expression:"org.isPartnerInvoicingEnabled"}},[_vm._v(" "+_vm._s(_vm.$t('pages.organization.form.partnerInvoicingSwitch'))+" ")])]:_vm._e(),_c('h3',{staticClass:"font-copy-lg-strong mb-4 mt-6 md:col-span-2"},[_vm._v(" "+_vm._s(_vm.$t('components.organizationForm.netsuite.title'))+" ")]),(!_vm.org.isExported)?_c('Alert',{staticClass:"mb-2",attrs:{"type":"info","icon-type":"warning","dark":""}},[_vm._v(" "+_vm._s(_vm.$t('pages.organization.form.organizationNotYetExported'))+" ")]):_c('Alert',{staticClass:"mb-2",attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.$t('pages.organization.form.organizationHasBeenExported', { date: _vm.$d(_vm.ensureJSTimestamp(_vm.org.exportedAt), 'short'), time: _vm.$t('timeSuffix', { time: _vm.$d(_vm.ensureJSTimestamp(_vm.org.exportedAt), 'time'), }), }))+" ")]),_c('div',{staticClass:"mt-4 grid md:grid-cols-2"},[(_vm.org.types.includes('client'))?_c('div',{staticClass:"flex flex-col md:col-span-2 md:flex-row"},[_c('div',{staticClass:"flex-1"},[_c('ReactBridge',{attrs:{"react-component":_vm.$options.PaymentTermsSelection,"props":{
                        disabled: !_vm.$can('organizationAccountingSettingManagement'),
                        organizationType: 'client',
                        paymentTermId: _vm.org.clientPaymentTerm?.id || null,
                        onChange: id => _vm.updatePaymentTerm('client', id),
                    }}})],1),_c('div',{staticClass:"flex-1 md:pl-8 md:pt-8"},[_c('ToggleSwitchField',{staticClass:"mt-4",attrs:{"data-test":"company-settings-toggle-exclude-from-dunning","name":"organization-lock-switch","disabled":!_vm.$can('updateOrganizationExcludedFromDunning')},model:{value:(_vm.org.excludedFromDunning),callback:function ($$v) {_vm.$set(_vm.org, "excludedFromDunning", $$v)},expression:"org.excludedFromDunning"}},[_vm._v(" "+_vm._s(_vm.org.excludedFromDunning ? _vm.$t('components.organizationForm.netsuite.excludedFromDunningActive') : _vm.$t('components.organizationForm.netsuite.excludedFromDunningInactive'))+" ")])],1)]):_vm._e(),(_vm.org.types.includes('carrier'))?_c('div',[_c('ReactBridge',{attrs:{"react-component":_vm.$options.PaymentTermsSelection,"props":{
                    disabled: !_vm.$can('organizationAccountingSettingManagement'),
                    organizationType: 'carrier',
                    paymentTermId: _vm.org.carrierPaymentTerm?.id || null,
                    onChange: id => _vm.updatePaymentTerm('carrier', id),
                }}})],1):_vm._e(),(_vm.org.types.includes('carrier'))?_c('div',{staticClass:"hidden md:block"}):_vm._e(),(_vm.org.types.includes('supplier'))?_c('div',[_c('ReactBridge',{attrs:{"react-component":_vm.$options.PaymentTermsSelection,"props":{
                    disabled: !_vm.$can('organizationAccountingSettingManagement'),
                    organizationType: 'supplier',
                    paymentTermId: _vm.org.supplierPaymentTerm?.id || null,
                    onChange: id => _vm.updatePaymentTerm('supplier', id),
                }}})],1):_vm._e(),(_vm.org.types.includes('supplier'))?_c('div',{staticClass:"hidden md:block"}):_vm._e()])],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
import { useSyncExternalStore } from 'react';
export function usePreferredColorScheme() {
    const subscribe = (cb) => {
        const media = window.matchMedia('(prefers-color-scheme: dark)');
        media.addEventListener('change', cb);
        return () => media.removeEventListener('change', cb);
    };
    const getSnapshot = () => {
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
    };
    const prefersDarkColorScheme = useSyncExternalStore(subscribe, getSnapshot);
    return prefersDarkColorScheme ? 'dark' : 'light';
}

<template>
    <LayoutPage is-flyout :screen-name="screenName" class="checkout-shipment-document-page">
        <BaseHeader slot="flyoutHeader" />

        <LoadingSpinner v-if="isPending('loadSelectedVehicleClass')" block dark />
        <template v-else>
            <div v-if="showOnlyShipmentFreightInformation" class="container-deprecated">
                <ShipmentFreightInformation
                    v-if="showShipmentFreightInformation"
                    v-model="freightInformation"
                    class="checkout-shipment-document-page__content-gap"
                />
            </div>

            <template v-else-if="selectedVehicleClass">
                <div class="container-deprecated">
                    <Words block bold spaced-top spaced-bottom-small>
                        {{ $t('pages.checkout.shipmentDocumentPage.qtyTitle') }}
                    </Words>
                    <Hint show-label spaceless transparent>
                        {{ $t('pages.checkout.shipmentDocumentPage.qtyDescription') }}
                    </Hint>

                    <!-- selected vehicle class transport -->
                    <Card spaceless-x class="checkout-shipment-document-page__transport-box">
                        <div class="checkout-shipment-document-page__payload-info">
                            <div>
                                <Words
                                    data-test="calculated-tons"
                                    class="checkout-shipment-document-page__payload-info-number"
                                >
                                    {{ $n(calculatedTons) }}
                                </Words>
                                <span class="checkout-shipment-document-page__payload-info-unit">
                                    {{ $t('units.ton') }}
                                </span>
                            </div>
                            <div class="checkout-shipment-document-page__approx-ball">&ap;</div>
                            <div>
                                <Words class="checkout-shipment-document-page__payload-info-number">
                                    {{ $n(calculatedCbm) }}
                                </Words>
                                <span class="checkout-shipment-document-page__payload-info-unit">
                                    {{ $t('units.cbm') }}
                                </span>
                            </div>
                        </div>
                        <div class="checkout-shipment-document-page__transports-info">
                            <VehicleClassIconSet width="64" height="32" :icon="selectedVehicleClass.icon" />
                            <div class="checkout-shipment-document-page__payload-info-wrapper">
                                <Words bold>{{ selectedVehicleClass.name }}</Words>
                                <Words data-test="vehicle-payload">
                                    {{ $t('pages.checkout.shipmentDocumentPage.payloadLabel') }}
                                    {{ selectedVehicleClass.payload | tons }}
                                </Words>
                            </div>

                            <div class="checkout-shipment-document-page__stepper">
                                <SfNumberCounter
                                    :count="transportCount"
                                    :max="maximumTransportsForQty"
                                    @change="setTransportCount($event)"
                                />
                            </div>
                        </div>
                        <ErrorMessage
                            v-if="contingentError"
                            class="checkout-shipment-document-page__transport-count-error"
                            :message="contingentError"
                        />
                    </Card>
                    <ShipmentFreightInformation
                        v-if="showShipmentFreightInformation"
                        v-model="freightInformation"
                        class="checkout-shipment-document-page__content-gap"
                    />
                </div>
            </template>

            <SlideUp slot="sticky" :active="hasValidData">
                <ButtonGroup>
                    <BaseButton
                        :disabled="!hasValidData"
                        primary
                        type="button"
                        data-test="button-continue-setup-shipment"
                        @click="handleSubmission()"
                    >
                        <template v-if="basketType === 'project-shipment'">
                            {{ $t('pages.checkout.shipmentFreight.nextPage') }}
                        </template>
                        <template v-else>
                            {{ $t('pages.checkout.shipmentDocumentPage.nextPage') }}
                        </template>
                    </BaseButton>
                </ButtonGroup>
            </SlideUp>
        </template>
    </LayoutPage>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getFormattedDuration } from '@/services/utils/date';
import Toaster from '@/services/Toaster';
import VehicleClassApi from '@/services/Api/VehicleClass';
import { preciseFloat } from '@/services/utils';
import ProjectPositionView from '@/models/ProjectPositionView';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import { mapFilters } from '@/plugins/filters';
import statefulMixin from '@/plugins/mixins/statefulMixin';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Card from '@/components/Layout/Card';
import ErrorMessage from '@/components/Form/ErrorMessage';
import BaseHeader from './components/Header';
import Hint from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import { SfNumberCounter } from '@schuettflix/vue-components';
import ShipmentFreightInformation from '@/pages/Checkout/components/ShipmentFreightInformation';
import SlideUp from '@/components/Animation/SlideUp';
import VehicleClassIconSet from '@/components/IconSet/VehicleClassIconSet';
import Words from '@/components/Typography/Words';

import { navigationFailure } from '@/services/utils/router';
import { trackCheckoutEvent } from '@/pages/Checkout/trackCheckoutEvent';
import { get_cbm_per_ton } from '@/services/utils/units';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';
import { useState } from 'vuex-composition-helpers';
import { computed } from 'vue';

export default {
    name: 'ShipmentDocumentPage',

    components: {
        BaseButton,
        ButtonGroup,
        Card,
        ErrorMessage,
        BaseHeader,
        Hint,
        LayoutPage,
        LoadingSpinner,
        SfNumberCounter,
        ShipmentFreightInformation,
        SlideUp,
        VehicleClassIconSet,
        Words,
    },

    mixins: [statefulMixin],

    setup() {
        const { type: basketType } = useState('basket', ['type']);

        const showShipmentFreightInformation = computed(() => {
            return (
                basketType.value !== BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_SHIPMENT &&
                basketType.value !== BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DISPOSAL_HAZARDOUS &&
                basketType.value !== BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DISPOSAL_NON_HAZARDOUS
            );
        });

        const screenName = useOrderScreenName(
            showShipmentFreightInformation.value ? 'quantityselection' : 'freightdetail'
        );

        return {
            basketType,
            screenName,
            showShipmentFreightInformation,
        };
    },

    data() {
        return {
            selectedVehicleClass: null,

            calculatedDistance: null,
            transportCount: 1,

            freightInformation: {
                description: '',
                documents: [],
                images: [],
                type: null,
                disposalProcedure: null,
            },
        };
    },

    computed: {
        ...mapState('basket', {
            shipmentLoading: 'shipmentLoading',
            shipmentUnloading: 'shipmentUnloading',
            shipmentQtyAndDocument: 'shipmentQtyAndDocument',
            shipmentVehicleClassId: 'shipmentVehicleClassId',
            disposalVehicleClassId: 'disposalVehicleClassId',
            isCustom: 'isCustom',
            projectPosition: state =>
                state.projectPosition ? ProjectPositionView.create(state.projectPosition) : null,
            transports: 'transports',
        }),
        ...mapGetters('basket', ['isDisposal']),

        /**
         * Check if all required data are valid
         * @return {boolean}
         */
        hasValidData() {
            const valid = [];
            const hasTransports = this.transportCount > 0;

            if (this.showShipmentFreightInformation) {
                valid.push(this.freightInformation.isValid);
            }

            if (
                this.basketType === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_SHIPMENT ||
                this.basketType === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS ||
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS
            ) {
                valid.push(!this.contingentError);
            }

            valid.push(hasTransports);

            return valid.every(test => test === true);
        },

        /**
         * Validate against remaining quantity +1 additional payload of the selected vehicle class and returns an
         * error message if the contingent is exceeded
         *
         * For non-disposal orders, it is possible to load more than the contingent as long as it is only one vehicle that increases the total
         * quantity above the contingent.
         *
         * For disposal, its not possible to load more than the contingent.
         * @return {string|boolean}
         */
        contingentError() {
            if (!this.projectPosition) return false;

            const remainingQty =
                this.projectPosition.remainingQty + (this.isDisposal ? 0 : this.selectedVehicleClass?.payload);

            if (this.calculatedKg > remainingQty) {
                return this.$t('pages.checkout.quantitySelection.contingentExceededError', {
                    qty: this.tons(remainingQty),
                });
            }

            return false;
        },

        /**
         * Get maximum number of transports a user can select based on the remainingQty
         *
         * @return {number}
         */
        maximumTransportsForQty() {
            if (!this.projectPosition) return this.maxTransportsCount;

            const maxTransports = this.projectPosition.qty / this.selectedVehicleClass?.payload;

            let formattedMaxTransports = this.isDisposal ? Math.floor(maxTransports) : Math.ceil(maxTransports);

            if (formattedMaxTransports > this.maxTransportsCount) {
                formattedMaxTransports = this.maxTransportsCount;
            }

            return formattedMaxTransports;
        },

        /**
         * Maximum allowed transports per order
         * @return {number}
         */
        maxTransportsCount() {
            return this.selectedVehicleClass?.maximumTransportCount;
        },
        /**
         * Total payload in kilograms
         * @return {number}
         */
        calculatedKg() {
            return this.selectedVehicleClass?.payload * this.transportCount;
        },

        /**
         * Total payload in tons
         * @return {number}
         */
        calculatedTons() {
            return this.calculatedKg / 1000;
        },

        showCbmForLoadedQuantity() {
            return this.isDisposal;
        },

        /**
         * Total payload in cubic metre
         * @return {number}
         */
        calculatedCbm() {
            if (this.showCbmForLoadedQuantity) {
                return get_cbm_per_ton(this.calculatedKg, this.projectPosition.product.density, null, 1);
            }
            return (this.selectedVehicleClass.loadingVolume * this.transportCount) / 1000;
        },

        /**
         * Display and request only freight information
         * @return {boolean}
         */
        showOnlyShipmentFreightInformation() {
            return this.basketType === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_SHIPMENT;
        },
    },

    watch: {
        selectedVehicleClass(vehicleClass) {
            if (!vehicleClass) return;

            if (this.transportCount > this.maxTransportsCount) {
                this.transportCount = this.maxTransportsCount;
            }
        },
    },

    created() {
        this.getSelectedVehicleClass();

        if (this.shipmentQtyAndDocument) {
            this.transportCount = this.shipmentQtyAndDocument.transportCount;

            const { description, documents, images, type, disposalProcedure } = this.shipmentQtyAndDocument;
            this.freightInformation = { description, documents, images, type, disposalProcedure };
        }

        if (this.isDisposal) {
            this.transportCount = this.transports.transports.length;
        }
    },

    methods: {
        ...mapFilters(['tons']),

        getFormattedDuration,

        setTransportCount(count) {
            this.transportCount = count;

            if (this.isDisposal) {
                const transports = [];
                Array(count)
                    .fill(0)
                    .forEach(() => {
                        transports.push({
                            id: this.selectedVehicleClass.id,
                            icon: this.selectedVehicleClass.icon,
                            actualLoad: this.selectedVehicleClass.payload,
                            name: this.selectedVehicleClass.name,
                            numAxes: this.selectedVehicleClass.numAxes,
                            payload: this.selectedVehicleClass.payload,
                            loadPercentage: 100,
                        });
                    });
                this.$store.commit('basket/setTransports', {
                    transports: transports,
                });
            }
        },

        async getSelectedVehicleClass() {
            await this.stateful('loadSelectedVehicleClass', async () => {
                try {
                    const type = this.isDisposal ? 'disposal' : 'shipment';
                    const vehicleClassId = this.isDisposal ? this.disposalVehicleClassId : this.shipmentVehicleClassId;
                    const response = await VehicleClassApi.filter({ type });
                    this.selectedVehicleClass = response.items.find(vc => vc.id === vehicleClassId);
                } catch (error) {
                    Toaster.error(error);
                }
            });
        },

        handleSubmission() {
            if (this.hasValidData) {
                const {
                    description,
                    documents = [],
                    images = [],
                    type = 'material',
                    disposalProcedure,
                } = this.freightInformation;

                const mutation = this.isDisposal ? 'setDisposalQtyAndDocument' : 'setShipmentQtyAndDocument';
                this.$store.commit(`basket/${mutation}`, {
                    transportCount: this.transportCount,
                    description,
                    documents,
                    images,
                    type,
                    disposalProcedure,
                });

                if (!this.isCustom) {
                    const eventName = this.isDisposal ? 'disposalDocument' : 'shipmentDocument';
                    trackCheckoutEvent(eventName, 'selectQty', {
                        qty: preciseFloat(this.calculatedTons),
                        description,
                    });
                }

                this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(navigationFailure);
            }
        },
    },
};
</script>

<style lang="scss">
.checkout-shipment-document-page__content-gap {
    margin-top: 30px;
}

.checkout-shipment-document-page__payload-button {
    font-weight: 400;
    justify-self: center;
    width: 34px;
    height: 34px;
    line-height: 34px;
}

.checkout-shipment-document-page__transport-box {
    margin-top: 30px;
}

.checkout-shipment-document-page__payload-info-wrapper {
    display: flex;
    flex-flow: row wrap;

    > * {
        margin-right: 8px;
    }
}

.checkout-shipment-document-page__payload-info {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
    border-bottom: 1px solid $color-mediumGrey;

    > * {
        text-align: center;
    }
}

.checkout-shipment-document-page__transports-info {
    display: grid;
    grid-template-columns: 64px auto auto;
    grid-template-areas: 'info info steps';
    gap: 20px;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 15px;

    @media screen and (max-width: 450px) {
        grid-template-columns: auto auto auto;
        grid-template-areas:
            'info info info '
            'steps steps steps';
    }
}

.checkout-shipment-document-page__payload-info-number {
    font-size: 28px;
    @media screen and (min-width: $layout-desktop-max) {
        font-size: 32px;
    }
}

.checkout-shipment-document-page__payload-info-unit {
    font-size: 16px;
    margin-left: 5px;
    @media screen and (min-width: $layout-desktop-max) {
        font-size: 21px;
    }
}

.checkout-shipment-document-page__approx-ball {
    text-align: center;
    background-color: $color-white;
    border: 1px solid $color-mediumGrey;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 21px;
    line-height: 40px;
    vertical-align: middle;
    padding-bottom: 1px; // optical adjustment
}

.checkout-shipment-document-page__stepper {
    display: grid;
    grid-area: steps;
    justify-items: center;
}

.checkout-shipment-document-page__payload-number {
    font-size: 32px;
}

.checkout-shipment-document-page__transport-count-error {
    margin-top: 0;
}
</style>

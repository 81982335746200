import TransportHubPage from './TransportHubPage';

export default {
    path: 'transport-hub',
    name: 'transport-hub',
    component: TransportHubPage,
    meta: {
        requiresAuth: true,
        noBackArrow: true,
        requiredAbilities: ['haveTransportHub'],
    },
    children: [
        {
            include: 'transport-view',
        },
    ],
};

<template>
    <div
        class="file-uploader__wrapper"
        data-test="file-uploader-wrapper"
        @dragenter="draggedOver = true"
        @dragleave="draggedOver = false"
        @dragover.prevent
        @drop.prevent
    >
        <ErrorMessage v-if="error" :message="error" />
        <div
            v-if="!fileIsUploading"
            class="file-uploader"
            :class="{ 'file-uploader--uploading': fileIsUploading, 'file-uploader--dragged-file': draggedOver }"
            data-test="file-uploader"
            @click="toggleFileSelection"
            @drop.prevent="handleDraggedFile"
        >
            <input
                ref="fileUploaderFileInput"
                type="file"
                class="file-uploader__input"
                data-test="file-uploader-input"
                :accept="acceptedFileTypes"
                :multiple="allowMultipleFileUpload"
                @change="handleSelectedFile"
            />
            <Words block bold class="file-uploader__text" v-html="uploadFileMessage" />
            <span class="file-uploader__maximum-size">{{ maximumFileSize }}</span>
        </div>
        <div v-else class="file-uploader" :class="{ 'file-uploader--uploading': fileIsUploading }">
            <div class="file-uploader__inner file">
                <div class="file__icon">
                    <PDFIcon />
                </div>
                <div class="file__progress">
                    <div class="file__details">
                        <div class="file__name" :class="{ 'file__name--information': displayFileInformation }">
                            <Words bold>{{ fileName }}</Words>
                        </div>
                        <div class="file__progress-number" style="position: relative">
                            <transition name="fade">
                                <Words v-if="!fileUploadComplete && !allowReattempt" key="1" bold class="icon"
                                    >{{ fileUploadProgress }}%</Words
                                >
                                <div v-else-if="fileUploadComplete && !allowReattempt" key="2" class="icon icon--tick">
                                    <CheckmarkIcon />
                                </div>
                                <div v-else class="icon icon--reattempt">
                                    <RefreshIcon data-test="file-upload-reattempt-upload" @click="reattemptUpload()" />
                                </div>
                            </transition>
                        </div>
                    </div>
                    <ProgressBar is-green is-small :value="fileUploadProgress" class="file-uploader__progress-bar" />
                    <div v-if="errorMessage" class="file__error-message">{{ errorMessage }}</div>
                </div>
                <Button class="file__actions" @click="removeUploadedFile">
                    <CancelIcon v-if="!fileUploadComplete" data-test="file-upload-cancel-upload" />
                </Button>
            </div>
            <transition name="fade">
                <div v-if="displayFileInformation" class="file-uploader__display">
                    <div class="file-uploader__inner file cursor-pointer" @click="onDownload(existingFile)">
                        <div class="file__icon">
                            <PDFIcon />
                        </div>
                        <div class="file__details">
                            <div class="file__name" :class="{ 'file__name--display': displayFileInformation }">
                                <Words bold>{{ fileName }}</Words>
                            </div>
                        </div>
                        <Button
                            v-if="!isReadOnly"
                            class="file__actions"
                            data-test="file-upload-remove-uploaded-file"
                            @click="removeUploadedFile"
                        >
                            <TrashIcon />
                        </Button>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import Toaster from '@/services/Toaster';
import { isFileTypeAccepted, fileTypeString } from '@/services/utils/file';

import { FILE_UPLOAD } from '@/constants/fileupload';

import Button from '@/components/Button/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import ProgressBar from '@/_components/ProgressBar/ProgressBar';
import Words from '@/components/Typography/Words';

import CancelIcon from '@/assets/icons/fileupload/cancel.svg';
import CheckmarkIcon from '@/assets/icons/regular/checkmark.svg';
import PDFIcon from '@/assets/icons/fileupload/pdf.svg';
import RefreshIcon from '@/assets/icons/fileupload/refresh.svg';
import TrashIcon from '@/assets/icons/fileupload/trash.svg';

const MAX_FILE_SIZE = 20;

export default {
    name: 'FileUploader',
    components: {
        Button,
        ProgressBar,
        Words,
        CancelIcon,
        CheckmarkIcon,
        ErrorMessage,
        PDFIcon,
        RefreshIcon,
        TrashIcon,
    },
    props: {
        uploadFileMessage: {
            type: String,
            default: '',
        },
        acceptedFileTypes: {
            type: String,
            default: 'image/jpeg, image/jpg, image/png',
        },
        allowMultipleFileUpload: {
            type: Boolean,
            default: false,
        },
        fileUploadProgress: {
            type: Number,
            default: 0,
        },
        response: {
            type: Object,
            default: () => ({}),
        },
        existingFile: {
            type: Object,
            default: () => ({}),
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        error: {
            type: [String, null],
            default: null,
        },
    },
    data() {
        return {
            fileIsUploading: false,
            fileUploadComplete: false,
            displayFileInformation: false,
            draggedOver: false,
            allowReattempt: false,
            file: null,
            fileName: '',
            errorMessage: '',
        };
    },
    computed: {
        hasMessage() {
            return !_isEmpty(this.message);
        },
        maximumFileSize() {
            return this.$t(
                'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.fileUploader.maximumFileSize',
                {
                    size: MAX_FILE_SIZE,
                }
            );
        },
        maximumFileSizeExceeded() {
            return this.$t(
                'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.fileUploader.limitExceeded',
                {
                    size: MAX_FILE_SIZE,
                }
            );
        },
        acceptedFileTypesAsArray() {
            return this.acceptedFileTypes.split(', ');
        },
    },
    watch: {
        existingFile: {
            immediate: true,
            handler() {
                if (!_isEmpty(this.existingFile?.uuid)) {
                    this.fileUploadComplete = true;
                    this.fileIsUploading = true;
                    this.displayFileInformation = true;
                    this.fileName = this.existingFile?.fileName;
                }
            },
        },
        response() {
            if (this.response.type === FILE_UPLOAD.SUCCESS) {
                this.fileUploadComplete = true;
                setTimeout(() => (this.displayFileInformation = true), 1000);
            } else {
                this.errorMessage = this.$t(
                    'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.fileUploader.uploadFailed'
                );
                this.allowReattempt = true;
            }
        },
    },
    methods: {
        async onDownload(file) {
            const imageOrDocument = await fetch(file.url?.original || file.urlPath);
            const blob = await imageOrDocument.blob();
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = file.fileName;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        toggleFileSelection() {
            this.$refs.fileUploaderFileInput.click();
        },
        removeUploadedFile() {
            this.resetFileUpload();
            this.$emit(FILE_UPLOAD.CANCEL_UPLOAD);
        },
        handleDraggedFile($event) {
            this.file = $event.dataTransfer.files[0];
            this.handleUploadFile();
        },
        handleSelectedFile($event) {
            this.file = $event.target.files[0];
            this.handleUploadFile();
        },
        handleUploadFile() {
            const maxFileSize = MAX_FILE_SIZE * 1024 * 1024;

            if (!this.file) {
                return;
            }

            if (!isFileTypeAccepted(this.acceptedFileTypes, this.file.type)) {
                Toaster.error(
                    this.$t('components.imageDocumentUploader.errors.dataTypeNotSupported', {
                        fileTypeString: fileTypeString(this.acceptedFileTypesAsArray),
                    })
                );
                return;
            }

            if (this.file.size > maxFileSize) {
                this.fileIsUploading = true;
                this.fileName = this.file.name;
                this.errorMessage = this.maximumFileSizeExceeded;
                this.$emit(FILE_UPLOAD.ERROR, this.maximumFileSizeExceeded);
            } else {
                this.fileIsUploading = true;
                this.fileName = this.file.name;
                this.$emit(FILE_UPLOAD.FILE_UPLOAD, this.file);
            }
        },
        reattemptUpload() {
            this.errorMessage = '';
            this.handleUploadFile();
        },
        resetFileUpload() {
            this.fileName = '';
            this.fileType = '';
            this.errorMessage = '';
            this.file = null;
            this.fileIsUploading = false;
            this.displayFileInformation = false;
            this.fileUploadComplete = false;
            this.draggedOver = false;
            this.allowReattempt = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.file-uploader {
    align-items: center;
    background: $color-white;
    border: 1px dashed $color-border-light-grey;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 48px 24px;
    position: relative;
    transition: all ease-in 250ms;
    width: 100%;

    &:hover,
    &--dragged-file {
        background-color: $color-mediumGrey;
        transition: all ease-in 300ms;

        &.button--default {
            opacity: 1;
        }
    }

    &--uploading {
        &:hover {
            background-color: $color-white;
            transition: none;
            cursor: auto;
        }
    }

    &__text {
        margin-bottom: 4px;
        pointer-events: none;
    }

    &__maximum-size {
        font-size: 12px;
        line-height: 1.333;
        font-weight: 500;
        color: #7c7f84;
        pointer-events: none;
    }

    &__input {
        display: none;
    }

    &__message {
        display: flex;
    }

    &__wrapper {
        .message {
            &__link {
                color: $color-darkGrey;
                display: flex;
                font-size: 10px;

                svg {
                    height: 14px;
                    margin-right: 4px;
                    width: 14px;
                }

                a {
                    color: inherit;
                    font-size: inherit;
                }
            }
        }
    }

    &__display {
        position: absolute;
        inset: 0;
        background: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;

        .file__name {
            animation: moveFileName 1s forwards;
            opacity: 0;
            position: absolute;
            top: -5px;
        }

        .file__actions {
            .file-uploader & {
                width: 24px;
                height: 24px;
                margin-left: auto;

                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    &__inner {
        max-width: 530px;
        width: 100%;
        padding: 0 20px;

        @media screen and (min-width: $screen-lg) {
            padding: 0;
        }
    }

    &__progress-bar {
        margin-top: 8px;
    }

    .file {
        align-items: center;
        display: flex;
        position: relative;
        width: 100%;

        &__progress {
            flex: 1;
        }

        &__details {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        &__icon {
            flex: 0;
            margin-right: 16px;

            svg {
                width: auto;
                height: 24px;
            }
        }

        &__actions {
            cursor: pointer;
            height: 14px;
            margin-left: 16px;
            width: 14px;
        }

        &__progress-number {
            font-size: 10px;

            .icon {
                position: absolute;
                right: 0;
                top: -6px;

                &--reattempt {
                    cursor: pointer;
                }

                &--tick {
                    color: $color-success;
                }
            }
        }

        &__error-message {
            position: absolute;
            font-size: 12px;
            color: $color-error;
            bottom: -20px;
        }
    }

    @keyframes moveFileName {
        from {
            opacity: 0;
            top: -6px;
        }
        to {
            opacity: 1;
            top: 2px;
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
}
</style>

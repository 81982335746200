<template>
    <SfProgressBar
        theme="light"
        :size="progressSize"
        :primary="contingentStatistics.deliveredQty"
        :secondary="contingentStatistics.reservedQty"
        :min="1"
        :max="projectPosition.qty"
    >
        <template #tooltip-default>
            <span class="text-subdued">
                {{ $t('components.projectPositionSelectionBlock.stat.remaining') }}
            </span>
            <span class="font-copy-sm-strong"> {{ contingentStatistics.remainingQty | tons }}</span>
        </template>
        <template #tooltip-primary>
            <span class="text-subdued">
                {{ titles.deliveredQty }}
            </span>
            <span class="font-copy-sm-strong">
                {{ contingentStatistics.deliveredQty | tons }}
            </span>
        </template>
        <template #tooltip-secondary>
            <span class="text-subdued">
                {{ titles.reservedQty }}
            </span>
            <span class="font-copy-sm-strong">
                {{ contingentStatistics.reservedQty | tons }}
            </span>
        </template>
        <template #before>
            <span :class="{ 'font-copy-sm': progressSize === 'sm', 'font-copy-md': progressSize !== 'sm' }">
                {{ contingentStatistics.deliveredQty | tons }}
            </span>
        </template>
        <template #after>
            <span :class="{ 'font-copy-sm': progressSize === 'sm', 'font-copy-md': progressSize !== 'sm' }">
                {{ projectPosition.qty | tons }}</span
            >
        </template>
    </SfProgressBar>
</template>

<script>
import ProjectPositionView from '@/models/ProjectPositionView';

import { SfProgressBar } from '@schuettflix/vue-components';
import ContingentStatisticsViewModel from '@/pages/Checkout/components/ContingentSelection/models/ContingentStatisticsViewModel';

export default {
    name: 'ProjectPositionContingentProgress',
    components: { SfProgressBar },
    props: {
        projectPosition: {
            type: ProjectPositionView,
            required: true,
        },
        size: {
            type: String,
            default: 'sm',
            validator: v => {
                return ['sm', 'md', 'lg'].includes(v);
            },
        },
    },
    computed: {
        contingentStatistics() {
            return this.projectPosition instanceof ProjectPositionView
                ? ContingentStatisticsViewModel.createFromProjectPositionViewModel(this.projectPosition)
                : null;
        },
        progressSize() {
            return this.size || (this.$root.isDesktop ? 'lg' : 'sm');
        },
        titles() {
            if (this.projectPosition.isDisposal) {
                return {
                    deliveredQty: this.$t('components.projectPositionSelectionBlock.stat.disposal.delivered'),
                    reservedQty: this.$t('components.projectPositionSelectionBlock.stat.disposal.reserved'),
                };
            }

            return {
                deliveredQty: this.$t('components.projectPositionSelectionBlock.stat.delivered'),
                reservedQty: this.$t('components.projectPositionSelectionBlock.stat.reserved'),
            };
        },
    },
};
</script>

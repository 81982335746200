import ActiveTrips from './ActiveTrips';
import OrderStartPickupPage from '@/pages/Order/OrderStartPickupPage';

export default {
    path: 'active-trips',
    name: 'active-trips',
    component: ActiveTrips,
    meta: {
        requiresAuth: true,
    },
    children: [
        {
            include: 'order-view',
        },
        {
            include: 'transport-view',
        },
        {
            path: 'pickup/start/:orderId',
            name: 'pickup-start-order',
            component: OrderStartPickupPage,
            props: true,
            children: [
                {
                    include: 'vehicle-new',
                },
            ],
        },
    ],
};

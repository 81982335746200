import DimensionView from '@/models/VehicleClass/DimensionView';
import MinMaxValueView from '@/models/VehicleClass/MinMaxValueView';
import ScopeItemView from '@/models/VehicleClass/ScopeItemView';
import UserView from '@/models/UserView';
import { ensureJSTimestamp } from '@/services/utils/date';

export default class VehicleClassView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     */
    static create(data) {
        if (!data) return null;

        const view = new VehicleClassView();

        view.id = data.id ?? null;
        view.identifier = data.identifier ?? null;
        view.name = data.name ?? null;
        view.numAxes = data.numAxes ?? null;
        view.icon = data.icon ?? null;
        view.isActive = data.isActive ?? null;
        view.availableAttributes = data.availableAttributes ?? null;
        view.availableOrderTypes = data.availableOrderTypes ?? null;
        view.loadingDurationBuffer = data.loadingDurationBuffer ?? null;
        view.supportedMinimumLoad = data.supportedMinimumLoad ?? null;
        view.supportedTotalWeight = data.supportedTotalWeight ?? null;
        view.unloadingDurationBuffer = data.unloadingDurationBuffer ?? null;
        view.updated = ensureJSTimestamp(data.updated) ?? null;
        view.updatedBy = UserView.create(data.updatedBy) ?? null;
        view.marketValueUpdated = ensureJSTimestamp(data.marketValueUpdated) ?? null;
        view.marketValueUpdatedBy = UserView.create(data.marketValueUpdatedBy) ?? null;

        view.dimension = DimensionView.createFromCM(data.dimension);

        if (Array.isArray(data.scopeItems)) {
            view.scopeItems = data.scopeItems.map(item => {
                return ScopeItemView.create({
                    ...item,
                    payload: data.payload,
                });
            });
        }

        // Remap old min/max/values
        view.emptyWeight = MinMaxValueView.create({
            value: data.emptyWeight,
            min: data.emptyWeightMin,
            max: data.emptyWeightMax,
        });

        view.payload = MinMaxValueView.create({
            value: data.payload,
            min: data.payloadMin,
            max: data.payloadMax,
        });

        view.permissibleTotalWeight = MinMaxValueView.create({
            value: data.permissibleTotalWeight,
            min: data.permissibleTotalWeightMin,
            max: data.permissibleTotalWeightMax,
        });

        view.loadingVolume = MinMaxValueView.createFromCCM({
            value: data.loadingVolume,
            min: data.loadingVolumeMin,
            max: data.loadingVolumeMax,
        });

        return view;
    }

    unfold() {
        const loadingVolume = this.loadingVolume?.unfoldToCCM();

        return {
            id: this.id,
            name: this.name,
            numAxes: this.numAxes,
            icon: this.icon,
            isActive: this.isActive,
            availableAttributes: this.availableAttributes,
            availableOrderTypes: this.availableOrderTypes,
            dimension: this.dimension?.unfoldToCM(),
            loadingDurationBuffer: this.loadingDurationBuffer,
            supportedMinimumLoad: this.supportedMinimumLoad,
            supportedTotalWeight: this.supportedTotalWeight,
            unloadingDurationBuffer: this.unloadingDurationBuffer,
            scopeItems: this.scopeItems?.map(s => s.unfold()),

            // Remap old min/max/values
            emptyWeight: this.emptyWeight?.value,
            emptyWeightMin: this.emptyWeight?.min,
            emptyWeightMax: this.emptyWeight?.max,

            payload: this.payload?.value,
            payloadMin: this.payload?.min,
            payloadMax: this.payload?.max,

            permissibleTotalWeight: this.permissibleTotalWeight?.value,
            permissibleTotalWeightMin: this.permissibleTotalWeight?.min,
            permissibleTotalWeightMax: this.permissibleTotalWeight?.max,

            loadingVolume: loadingVolume?.value,
            loadingVolumeMin: loadingVolume?.min,
            loadingVolumeMax: loadingVolume?.max,
        };
    }

    constructor() {
        this.id = null;
        this.identifier = null;
        this.name = null;
        this.numAxes = null;
        this.icon = null;
        this.availableAttributes = null;
        this.availableOrderTypes = null;
        this.dimension = null;
        this.loadingDurationBuffer = null;
        this.loadingVolume = null;
        this.supportedMinimumLoad = null;
        this.supportedTotalWeight = null;
        this.unloadingDurationBuffer = null;
        this.scopeItems = null;
        this.updated = null;
        this.updatedBy = null;
        this.marketValueUpdated = null;
        this.marketValueUpdatedBy = null;

        // Old min/max/values
        this.emptyWeight = null;
        this.payload = null;
        this.permissibleTotalWeight = null;
    }
}

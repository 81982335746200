<template>
    <div
        v-if="!enableMobile"
        :class="{
            'tab-navigation--stretch': stretch,
            'tab-navigation--lineless': lineless,
            'tab-navigation--spaced': spaced,
            'tab-navigation--align-left': alignLeft,
            'tab-navigation--dark-line': darkLine,
        }"
        class="tab-navigation"
    >
        <div v-if="stages" class="tab-navigation__track flex flex-nowrap">
            <TabNavigationItem
                v-for="(stage, key) in stages"
                :key="key"
                :stage="stages && stages.push ? stage : key"
                :active="isActive(stage, key)"
            >
                {{ stage }}
            </TabNavigationItem>
        </div>
        <div v-else class="tab-navigation__track flex flex-nowrap">
            <slot />
        </div>
        <div
            :style="{ left: `${highlight.left}px`, width: `${highlight.width}px` }"
            class="tab-navigation__highlight"
        />
    </div>

    <div v-else class="tab-navigation tab-navigation--mobile" :class="{ 'tab-navigation--no-shadow': noShadow }">
        <slot />

        <template v-if="stages">
            <TabNavigationItem
                v-for="(stage, key) in stages"
                :key="key"
                :stage="stages && stages.push ? stage : key"
                :active="isActive(stage, key)"
            >
                {{ stage }}
            </TabNavigationItem>
        </template>

        <div
            :style="{ left: `${highlight.left}px`, width: `${highlight.width}px` }"
            class="tab-navigation__highlight"
        />
    </div>
</template>

<script>
import TabNavigationItem from '@/components/Tab/TabNavigationItem';

export default {
    name: 'TabNavigation',
    components: {
        TabNavigationItem,
    },
    props: {
        value: {
            type: [String, Number],
            default: null,
        },
        stages: {
            type: [Array, Object],
            default: null,
        },
        activeFirst: {
            type: Boolean,
            default: true,
        },
        stretch: {
            type: Boolean,
            default: false,
        },
        lineless: {
            type: Boolean,
            default: false,
        },
        spaced: {
            type: Boolean,
            default: false,
        },
        alignLeft: {
            type: Boolean,
            default: false,
        },
        darkLine: {
            type: Boolean,
            default: false,
        },
        enableMobile: {
            type: Boolean,
            default: false,
        },
        noShadow: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            highlight: {
                $el: null,
                left: 0,
                width: 0,
            },
            observer: null,
        };
    },
    watch: {
        value(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.notifyChildren('trigger-stage', this.value);
            }
        },
    },
    created() {
        this.$on('select-stage', (stage, $el) => {
            this.highlight.$el = $el;
            setTimeout(() => {
                this.highlightElement($el);
            }, 100);

            if (this.value !== stage) {
                this.$emit('input', stage);
            }

            this.notifyChildren('change-stage', stage);
        });

        this.observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type !== 'characterData' && mutation.target !== 'text') return;
                this.$nextTick(() => this.handleResize());
            });
        });

        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        this.observer.disconnect();
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        // default value
        if (this.value === null && this.activeFirst) {
            setTimeout(() => {
                const firstChild = this.$children[0];
                firstChild && firstChild.$emit('trigger-stage');
            }, 100);
        } else {
            setTimeout(() => {
                // initial stage select
                this.notifyChildren('trigger-stage', this.value);
            }, 100);
        }

        this.observer.observe(this.$el, { characterData: true, subtree: true });
    },
    methods: {
        notifyChildren(eventType, ...args) {
            this.$children.forEach(child => {
                child.$emit(eventType, ...args);
            });
        },

        handleResize() {
            window.requestAnimationFrame(() => {
                const { $el } = this.highlight;
                $el && this.highlightElement($el);
            });
        },

        highlightElement($el) {
            this.$set(this.highlight, '$el', $el);
            this.$set(this.highlight, 'left', $el.offsetLeft);
            this.$set(this.highlight, 'width', $el.offsetWidth);

            if (this.enableMobile) {
                const scrollPadding = 30;
                const parentsViewportWidth = this.$el.offsetWidth;
                const elementsViewportPosition = $el.getClientRects()[0] ? $el.getClientRects()[0].x : 0;
                const elementsPositionFromLeft = $el.offsetLeft;
                const elementsTotalWidth = $el.scrollWidth + scrollPadding;
                const elementsTotalWidthFromLeft = elementsPositionFromLeft + elementsTotalWidth;
                const elementsScrollPosition = elementsTotalWidthFromLeft - parentsViewportWidth;

                if (elementsViewportPosition - scrollPadding < 0) {
                    this.$el.scrollTo({ left: elementsPositionFromLeft - scrollPadding, behavior: 'smooth' });
                }

                if (elementsViewportPosition + elementsTotalWidth > parentsViewportWidth) {
                    this.$el.scrollTo({ left: elementsScrollPosition, behavior: 'smooth' });
                }
            }
        },
        isActive(stage, key) {
            if (this.stages && this.stages.push) {
                return `${this.value}` === `${stage}`;
            }
            return `${this.value}` === `${key}`;
        },
    },
};
</script>

<style lang="scss">
.tab-navigation {
    width: 100%;
    border-bottom: $border-solid-2px;
    position: relative;
}

.tab-navigation--spaced {
    .tab-navigation__track {
        > * {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

.tab-navigation--stretch {
    .tab-navigation__track {
        display: flex;

        > * {
            flex: 1;
        }
    }
}

.tab-navigation--lineless {
    border-color: transparent;

    .tab-navigation__track {
        > * {
            flex: 1;
        }
    }
}

.tab-navigation--dark-line {
    border-color: $color-mediumGrey;
}

.tab-navigation--align-left {
    .tab-navigation__track {
        > * {
            text-align: left;
            margin-right: 12px;
        }

        > *:last-child {
            margin-right: 0;
        }
    }
}

.tab-navigation__highlight {
    background-color: $color-red;
    height: 2px;
    position: absolute;
    bottom: -2px;
    z-index: 1;
    will-change: left, width;
    transition:
        left 0.4s ease-in-out,
        width 0.3s ease-in-out;
}

.tab-navigation--mobile {
    display: flex;
    overflow: scroll;
    border-bottom: none;
    padding: 4px 0px;

    .tab-navigation-item {
        min-width: max-content;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 18px;
        padding-bottom: 10px;
    }

    .tab-navigation__highlight {
        bottom: 0px;
    }

    &::-webkit-scrollbar {
        display: none;
        width: 0 !important;
        height: 0 !important;
    }

    &.tab-navigation--no-shadow {
        box-shadow: none;
    }
}
</style>

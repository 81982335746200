export const useStatusTextColorMapper = status => {
    return {
        paid: 'success',
        closed: 'success',
        cancelled: 'critical',
        canceled: 'critical',
        new: 'warning',
        in_progress: 'warning',
        booked: 'warning',
        created: 'warning',
    }[status];
};

import { z } from 'zod';
export const ToastSchema = z.object({
    id: z.string(),
    time: z.number(),
    delay: z.number(),
    duration: z.number(),
    actionCallback: z.nullable(z.function()),
    isActionPending: z.boolean(),
    errorHandler: z.nullable(z.function()),
    actionLabel: z.string(),
    message: z.string(),
    type: z.enum(['success', 'error', 'warning', 'info']),
    isVisible: z.boolean(),
    timeoutHide: z.nullable(z.number()),
});

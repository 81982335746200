import React from 'react';
import { useTranslation } from 'react-i18next';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { useQuery } from '@tanstack/react-query';
import { constructionProjectByIdAsAdminQuery } from '@/constructionProjects/queries';
import { Header } from '@/constructionProjects/components/Header';
import { CONSTRUCTION_PROJECT_ADMIN_LIST_ROUTE } from '@/modules/constructionProjects/pages/routes';
import { useConstructionProjectRouteParams } from '@/modules/constructionProjects/hooks/useConstructionProjectRouteParams';
import { FavoriteAdminStar } from '@/modules/constructionProjects/components/FavoriteStar';
export const ConstructionProjectAdminDetailsHeader = () => {
    const { projectUuid } = useConstructionProjectRouteParams();
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const { data: constructionProject } = useQuery({
        ...constructionProjectByIdAsAdminQuery(projectUuid),
        suspense: true,
    });
    if (!constructionProject) {
        return null;
    }
    return (<Header desktopTitle={constructionProject.name} mobileTitle={constructionProject.name} navigationTitle={t('pages.constructionProject.projects.headline')} onBack={() => vueRouter.push({ name: CONSTRUCTION_PROJECT_ADMIN_LIST_ROUTE })} backgroundColor={constructionProject.color}>
            <div className="flex space-x-4">
                <FavoriteAdminStar />
            </div>
        </Header>);
};

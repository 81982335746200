import AbstractResource from './AbstractResource';

class Content extends AbstractResource {
    /**
     * @param {*} name
     */
    async getPage(name) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/${name}`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new Content('/content');

import React from 'react';
import { VueRouterLink } from '@/reactBridge/VueRouterLink';
import { ChevronRightIcon } from '@/constructionProjects/components/icons/ChevronRightIcon';
import clsx from 'clsx';
export const MenuTile = ({ className, to, label, description, icon: Icon, dataTest, appendPath }) => (<VueRouterLink className={clsx('flex min-h-18 items-center p-4', className)} data-test={dataTest} to={to} append={appendPath}>
        <Icon />
        <div className="ml-4 flex-1">
            <h2 className="font-copy-md mb-1">{label}</h2>
            {description && <p className="font-copy-sm text-subdued">{description}</p>}
        </div>
        <ChevronRightIcon size="xs"/>
    </VueRouterLink>);

import AccountingUI from './AccountingUI.vue';
export default [
    {
        path: 'ui',
        name: 'ui',
        component: AccountingUI,
        meta: {
            requiresAuth: true,
            noBackArrow: true,
            requiredAbilities: ['haveInvoiceManagement'],
        },
    },
];

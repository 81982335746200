import VehicleClassListPage from '@/pages/VehicleClass/VehicleClassListPage';
import VehicleClassFormPage from '@/pages/VehicleClass/VehicleClassFormPage';

export const getVehicleClassRoutes = parentRouteName => {
    return [
        {
            path: 'vehicle-class-management',
            name: 'vehicle-class-management',
            component: VehicleClassListPage,
            props: {
                parentRoute: parentRouteName,
            },
            meta: {
                requiresAuth: true,
                requiredAbilities: ['haveVehicleClassesManagement'],
            },
            children: [
                {
                    path: ':id',
                    name: 'vehicle-class-edit',
                    component: VehicleClassFormPage,
                    meta: {
                        requiredAbilities: ['haveVehicleClassesManagement'],
                    },
                    props: route => {
                        return {
                            ...route.params,
                            parentRoute: 'vehicle-class-management',
                        };
                    },
                },
            ],
        },
    ];
};

import ChildRenderer from '@/components/Router/ChildRenderer';
import PartnerSearchPage from './PartnerSearchPage';

export default {
    path: 'platform-tools',
    component: ChildRenderer,
    meta: {
        requiresAuth: true,
        noBackArrow: true,
        requiredAbilities: ['havePartnerSearch'],
    },
    children: [
        {
            path: '',
            name: 'partner-search',
            component: PartnerSearchPage,
        },
    ],
};

<template>
    <LayoutPage is-flyout class="checkout-project-position-selection-error-page">
        <HeaderBar slot="flyoutHeader">
            <HeaderBarItem slot="left" button @click="goBack">
                <ArrowIcon width="32" height="18" />
            </HeaderBarItem>
            <div slot="headline">
                {{ $t('pages.checkout.projectPositionSelection.error.title') }}
            </div>
            <HeaderBarItem slot="right" button @click="goBack">
                <CloseIcon width="18" height="18" />
            </HeaderBarItem>
        </HeaderBar>

        <div class="container-deprecated">
            <div class="error-hero">
                <ThumbDownIcon class="error-hero__icon" />
                <Headline centered :level="3">
                    {{ $t('pages.checkout.projectPositionSelection.error.description') }}
                </Headline>
                <Words v-for="error in projectPositionSelectionErrors" :key="error.key" block centered spaced-bottom>
                    {{ $t(`pages.checkout.projectPositionSelection.error.type.${error.key}`, error.val) }}
                </Words>
            </div>

            <div class="error-contact">
                <Words block bold spaced-bottom>
                    {{ $t('pages.checkout.projectPositionSelection.error.contact.hint') }}
                </Words>
                <Button arrow-right @click="openContact">
                    {{ $t('pages.checkout.projectPositionSelection.error.contact.action') }}
                </Button>
            </div>
        </div>

        <ButtonGroup slot="sticky">
            <Button primary @click="goBack">
                <ArrowIcon slot="left" height="10" class="icon--left" />
                {{ $t('pages.checkout.projectPositionSelection.error.confirm') }}
            </Button>
        </ButtonGroup>
    </LayoutPage>
</template>

<script>
import { mapState } from 'vuex';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import HeaderBar from '@/components/Header/HeaderBar';
import Headline from '@/components/Typography/Headline';
import LayoutPage from '@/components/Layout/Page.v2';
import Words from '@/components/Typography/Words';

import ArrowIcon from '@/assets/icons/regular/arrow.svg';
import CloseIcon from '@/assets/icons/regular/close.svg';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import ThumbDownIcon from '@/assets/icons/confirmation/error-order.svg';

export default {
    name: 'ProjectPositionSelectionErrorPage',
    components: {
        Words,
        HeaderBarItem,
        Button,
        ButtonGroup,
        LayoutPage,
        HeaderBar,
        Headline,

        ArrowIcon,
        CloseIcon,
        ThumbDownIcon,
    },
    computed: {
        ...mapState('basket', ['projectPositionSelectionErrors']),
    },
    methods: {
        goBack() {
            this.$store.commit('basket/setProjectPositionSelectionErrors', null);
        },
        openContact() {
            this.$eventHub.$emit('page.platformContact', true);
        },
    },
};
</script>

<style lang="scss" scoped>
.checkout-project-position-selection-error-page {
    background-color: $color-lightMediumGrey;
}

.error-hero {
    display: flex;
    flex-flow: column nowrap;
    align-content: center;
    margin-bottom: 40px;
}

.error-hero__icon {
    width: 100px;
    margin: 30px auto 10px;
}

.error-contact {
    text-align: center;
}
</style>

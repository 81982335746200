<template>
    <div class="feature-accordion">
        <div :class="{ 'feature-accordion__head--dark': dark }" class="feature-accordion__head" @click="trigger()">
            <div v-if="$slots.before" class="feature-accordion__before">
                <slot name="before" :is-open="isOpen" />
            </div>
            <div class="feature-accordion__title">
                {{ title }}
            </div>
            <MinusIcon v-if="isOpen" :class="{ 'icon--white': dark }" class="icon--mono feature-accordion__icon" />
            <PlusIcon v-else :class="{ 'icon--white': dark }" class="icon--mono feature-accordion__icon" />
        </div>
        <SlideUp ref="slideContainer" :active="isOpen" :duration="0.4" ease="linear">
            <div
                v-if="$slots.default"
                :class="{ 'feature-accordion__body--spaceless-top': !dark }"
                class="feature-accordion__body"
            >
                <div class="feature-accordion__content">
                    <slot>Please provide any content for the default slot</slot>
                </div>
                <div v-if="$slots.footer" class="feature-accordion__footer">
                    <slot name="footer" />
                </div>
            </div>
        </SlideUp>
    </div>
</template>

<script>
import SlideUp from '@/components/Animation/SlideUp';

import PlusIcon from '@/assets/icons/micro/plus.svg';
import MinusIcon from '@/assets/icons/micro/minus.svg';

export default {
    name: 'FeatureAccordion',
    components: {
        SlideUp,

        PlusIcon,
        MinusIcon,
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        identifier: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        contentChanges: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        isOpen() {
            return this.value.includes(this.identifier);
        },
    },
    watch: {
        contentChanges() {
            this.$refs.slideContainer.updateContent();
        },
    },
    methods: {
        trigger(status = null) {
            const { isOpen, value, identifier } = this;

            if (status === null) {
                status = !isOpen;
            }

            if (status && !isOpen) {
                this.$emit('input', [...value, identifier]);
            } else if (!status && isOpen) {
                this.$emit(
                    'input',
                    value.filter(v => v !== identifier)
                );
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.feature-accordion {
    border-bottom: 1px solid $color-grey;
}
.feature-accordion__head {
    padding: 20px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    cursor: pointer;
}

.feature-accordion__head--dark {
    background-color: $color-darkGrey;
    color: #fff;
}

.feature-accordion__body {
    padding: 20px 0;
}

.feature-accordion__body--spaceless-top {
    padding-top: 0;
}

.feature-accordion__content {
    padding: 0 20px 20px;
}

.feature-accordion__before {
    margin-right: 15px;
}

.feature-accordion__icon {
    justify-self: flex-end;
}

.feature-accordion__footer {
    background-color: $color-lightMediumGrey;
    padding: 20px;
}
</style>

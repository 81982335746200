import { ProductAttributeField as Field } from '@/services/ProductCatalog/constants';
export const getEmptyProduct = () => ({
    attributeValues: [],
    available: false,
    customName: '',
    factoryId: 0,
    grainSize: {
        min: 0,
        max: 0,
        unit: '',
    },
    id: '',
    productDefinitionId: '',
    masterProductId: '',
    minimumGuaranteedAmount: 0,
    name: '',
    partnerSku: '',
    price: '',
    productCategories: [],
    tags: [],
    isBlockedOnSpotMarket: false,
});
export const getEmptyProductForm = () => ({
    available: true,
    price: '',
    category: '',
    masterProductId: '',
    minimumGuaranteedAmount: 0,
    grainSize: {
        min: 0,
        max: 0,
    },
    isBlockedOnSpotMarket: false,
    // optional
    partnerSku: '',
    tags: [],
    customName: '',
    [Field.GEOLOGY]: null,
    [Field.GEOGRAPHY]: null,
    [Field.CHARACTERISTICS]: null,
    [Field.CERT_1]: null,
    [Field.CERT_2]: null,
    [Field.CERT_3]: null,
    [Field.PROCESSING]: null,
    [Field.BRAND]: null,
    [Field.COLOR]: [],
});

<template>
    <div
        :class="{
            'radio-input--active': isActive,
            'radio-input--disabled': disabled,
            'radio-input--small': small,
            'radio-input--error': error,
        }"
        class="radio-input"
        @click.stop
    >
        <input
            :id="eid"
            ref="radio"
            :checked="isActive"
            :disabled="disabled"
            :name="name"
            :value="option"
            :data-test="`${dataTest}-input`"
            type="radio"
            class="radio-input__input"
            @change="$emit('input', option)"
        />
        <div :for="eid" class="radio-input__label">
            <span class="radio-input__orb" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Radio',
    props: {
        name: {
            type: String,
            default: null,
        },
        value: {
            type: [Object, String, Boolean, Array, Number],
            default: null,
        },
        option: {
            type: [Object, String, Boolean, Array, Number],
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        dataTest: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            eid: `el${this._uid}`,
        };
    },
    computed: {
        isActive() {
            return this.value === this.option;
        },
    },
};
</script>

<style lang="scss">
.radio-input {
    --input-width: 34px;
    --orb-width: 21px;

    display: inline-block;
    vertical-align: middle;
    width: var(--input-width);
    height: var(--input-width);
    position: relative;
    // top: -1px;
}

.radio-input--active {
    .radio-input__orb {
        background-color: $color-red;
    }
}

.radio-input--disabled {
    .radio-input__label {
        background-color: $color-mediumGrey;
        border-color: rgba(0, 0, 0, 0.2);
        opacity: 0.3;
    }

    &.radio-input--active {
        .radio-input__orb {
            background-color: $color-darkGrey;
        }
    }

    .radio-input__input {
        cursor: initial;
    }
}

.radio-input__input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 1;
    width: var(--input-width);
    height: var(--input-width);
    padding: 0;
    margin: 0;
}

.radio-input__input:focus,
.radio-input__input:active {
    outline: 0;
}

.radio-input__label {
    display: inline-block;
    vertical-align: middle;
    width: var(--input-width);
    height: var(--input-width);
    border: 1px solid $color-grey;
    border-radius: 16px;
    position: relative;
    transition:
        border 0.2s ease-in,
        background-color 0.2s ease-in;
    background-color: transparent;
}

.radio-input__orb {
    display: inline-block;
    background-color: transparent;
    width: var(--orb-width);
    height: var(--orb-width);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.radio-input--small {
    --input-width: 20px;
    --orb-width: 10px;
}

.radio-input--error .radio-input__label {
    border-color: $color-error;
    color: $color-error;
}
</style>

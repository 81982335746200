import { useTranslation } from 'react-i18next';
import { useOrganization } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/hooks/useOrganization';
import { ProductSummary } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/components/ProductSummary';
import { PRODUCT_CATEGORY_LABELS } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/utils/ProductCategoryLabels';
import { sortByProductCategory } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/utils/sortByProductCategory';
function CustomerTransactions(props) {
    const { t } = useTranslation();
    const { data: organization } = useOrganization(props.organizationId);
    return (<div>
            <div className="font-copy-sm mb-1">{t('pages.genericPosition.customer')}</div>
            <div className="font-headline-md-strong mb-4 overflow-hidden text-ellipsis whitespace-nowrap">
                {organization?.name}
            </div>
            <div className="flex flex-col gap-6">
                {props.transactions.map(transaction => (<div key={transaction.productId}>
                        <div className="font-copy-sm-strong">
                            {t(PRODUCT_CATEGORY_LABELS[transaction.productCategory])}
                        </div>
                        <ProductSummary marketCode={props.marketCode} transaction={transaction} financeSettings={props.financeSettings}/>
                    </div>))}
            </div>
        </div>);
}
export function CustomerProductsSection(props) {
    const organizationsWithCustomerTransactions = props.organizations
        .map(org => {
        return {
            ...org,
            transactions: org.transactions
                .filter(transaction => transaction.functionalRoles.includes('CUSTOMER'))
                .sort(sortByProductCategory),
        };
    })
        .filter(org => org.transactions.length > 0);
    return (<div>
            {organizationsWithCustomerTransactions.map(customerTransactions => (<CustomerTransactions key={customerTransactions.orgId} organizationId={customerTransactions.orgId} marketCode={props.marketCode} financeSettings={customerTransactions.financeSettings} transactions={customerTransactions.transactions}/>))}
        </div>);
}

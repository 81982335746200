var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tile",class:{
        'tile--no-padding': _vm.noPadding,
        'tile--no-padding-top': _vm.noPaddingTop,
        'tile--no-padding-left-right': _vm.noPaddingLeftRight,
        'tile--no-padding-bottom': _vm.noPaddingBottom,
        'tile--no-border': _vm.noBorder,
        'tile--no-border-bottom': _vm.noBorderBottom,
        'tile--background-transparent': _vm.backgroundTransparent || _vm.transparent,
        'tile--border-top': _vm.borderTop,
        'tile--border-dark': _vm.borderDark,
        'tile--border-spaced': _vm.borderSpaced,
        'tile--clickable': _vm.clickable,
        'tile--dark': _vm.dark,
    },on:{"click":function($event){return _vm.$emit('click', $event)}}},[_vm._t("default")],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'draggable-toggle-switch',
        `draggable-toggle-switch--color-${_vm.color}`,
        {
            'draggable-toggle-switch--active': _vm.value,
            'draggable-toggle-switch--disabled': _vm.disabled,
            'draggable-toggle-switch--small': _vm.small,
            'draggable-toggle-switch--dark-mode': _vm.darkMode,
        },
    ],attrs:{"data-test":"draggable-toggle-switch"},on:{"click":function($event){$event.stopPropagation();!_vm.disabled && _vm.$emit('input', !_vm.value)}}},[_c('div',{staticClass:"draggable-toggle-switch__track"},[_c('DraggableContainer',{attrs:{"value":!_vm.value,"animation-speed":0.9,"disabled":_vm.disabled,"horizontal":""},on:{"input":function($event){return _vm.handleInput(!$event)}}},[_c('div',{staticClass:"draggable-toggle-switch__orb"})])],1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
    <div class="image-upload-area">
        <div class="image-upload-area__inner">
            <Thumbnail
                v-if="value"
                :src="value && value.url"
                :modal-action="triggerOverlay"
                editable
                class="image-upload-area__thumbnail"
            />
            <button v-else class="image-upload-area__placeholder" @click="$refs.imageUploader.triggerFileSelect()">
                <div>
                    <PlusIcon v-if="progress === 0" class="icon--inline" />
                    <Words v-else block large>{{ progress }}%</Words>
                    <Words block bold spaced-top-small>{{ photoSelectText }}</Words>
                </div>
            </button>

            <ModalBox ref="imageModal" :headline="editModalHeadline" :border="false">
                <img
                    v-if="value && value.url"
                    :src="value.url.large"
                    class="image-upload-area__modal-image"
                    :alt="editModalHeadline"
                />
                <div v-else>
                    {{ $t('components.thumbnail.noImage') }}
                </div>
                <template v-if="value" #actions>
                    <ButtonGroup :vertical="!$root.isDesktop">
                        <ButtonComponent primary light @click="$refs.imageUploader.triggerFileSelect()">
                            <template #left><PenIcon /></template>
                            {{ photoChangeText }}
                        </ButtonComponent>

                        <ButtonComponent primary light @click="triggerDelete()">
                            <template #left><DeleteIcon /></template>
                            {{ deleteLabel }}
                        </ButtonComponent>
                    </ButtonGroup>
                </template>
            </ModalBox>
        </div>
        <div class="image-upload-area__img-tile-button-hidden-img-uploader">
            <ImageUpload
                ref="imageUploader"
                :disable-upload="true"
                @error="handleImageUploadError"
                @input="handleImageSelect"
            />
        </div>
    </div>
</template>

<script>
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { getCurrentInstance, watch, ref } from 'vue';
import { useImageUpload } from '@/services/useClientFile';
import Toaster from '@/services/Toaster';

import ButtonComponent from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ImageUpload from '@/components/Form/ImageUpload';
import Thumbnail from '@/pages/Vehicle/components/Thumbnail.v2';
import Words from '@/components/Typography/Words';
import ModalBox from '@/components/Modal/ModalBox';

import DeleteIcon from '@/assets/icons/regular/garbage.svg';
import PlusIcon from '@/assets/icons/micro/plus.svg';
import PenIcon from '@/assets/icons/regular/pen.svg';

const meta = {
    productUseCase: 'vehicle_image',
};

export default {
    name: 'ImageUploadArea',

    components: {
        ButtonComponent,
        ButtonGroup,
        ImageUpload,
        Thumbnail,
        Words,
        ModalBox,

        DeleteIcon,
        PlusIcon,
        PenIcon,
    },

    mixins: [eventHubMixin],

    props: {
        value: {
            type: Object,
            default: null,
        },
        editModalHeadline: {
            type: String,
            default: null,
        },
        deleteLabel: {
            type: String,
            default: null,
        },
        photoSelectText: {
            type: String,
            default: null,
        },
        photoChangeText: {
            type: String,
            default: null,
        },
        enableEditing: {
            type: Boolean,
            default: false,
        },
    },

    setup(props, { emit }) {
        const { uploadImage, onEverySuccess, onEveryError, progress } = useImageUpload();

        const imageUploader = ref(null);
        const instance = getCurrentInstance().proxy;

        onEverySuccess(data => {
            emit('input', data);
            imageUploader.value.resetInput();
            if (props.enableEditing) {
                instance.$eventHub.$emit('imageEditorClose');
            }
        });

        onEveryError(error => {
            if (props.enableEditing) {
                instance.$eventHub.$emit('imageEditorClose');
            }
            Toaster.error(this.$t(error));
        });

        watch(progress, value => {
            if (props.enableEditing) {
                instance.$eventHub.$emit('imageEditorUploadProgress', value);
            }
        });

        return {
            progress,
            uploadImage,
        };
    },

    mounted() {
        this.subscribe('image-editor-upload', file => {
            this.uploadImage({
                file,
                meta,
            });
        });

        this.subscribe('imageEditorClosed', () => {
            this.$refs.imageUploader.resetInput();
        });
    },

    methods: {
        triggerOverlay() {
            this.$refs.imageModal.$emit('open');
        },

        triggerDelete() {
            this.$refs.imageModal.$emit('close');
            this.$emit('delete');
            this.progress = 0;
        },

        handleImageSelect(file) {
            this.progress = 0;
            if (!file) return;
            if (this.enableEditing) {
                this.$eventHub.$emit('image-editor-open', file);
            } else {
                this.uploadImage({
                    file,
                    meta,
                });
            }
        },

        handleImageUploadError(error) {
            if (error) this.$emit('error', error);
        },
    },
};
</script>

<style lang="scss" scoped>
.image-upload-area__inner {
    width: 100%;
    height: 100%;
    display: flex;
}

.image-upload-area__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px;

    background-color: $color-white;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.image-upload-area__placeholder:hover {
    border-color: rgba(0, 0, 0, 0.5);
}

.image-upload-area__modal-image {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}

.image-upload-area__img-tile-button-hidden-img-uploader {
    height: 0;
    overflow: hidden;
}
</style>

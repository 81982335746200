<template>
    <div v-if="showComponent">
        <template v-if="hasFeedback && showCompactRating">
            <div v-if="transport.feedback.overall" class="transport-hub-platform__rating">
                <span class="font-copy-sm text-subdued">
                    {{ $t('pages.transportHub.platform.labels.ratingOverall') }}:
                </span>
                <StarRating class="mt-2" :value="transport.feedback.overall" small view />
            </div>
            <div v-else-if="transport.feedback.material" class="transport-hub-platform__rating">
                <span class="font-copy-sm text-subdued">
                    {{ $t('pages.transportHub.platform.labels.ratingQuality') }}:
                </span>
                <StarRating class="mt-2" :value="transport.feedback.material" small view />
            </div>
        </template>
        <template v-else-if="hasFeedback">
            <div v-if="transport.feedback.overall" class="transport-hub-platform__rating">
                <span class="font-copy-sm text-subdued">
                    {{ $t('pages.transportHub.platform.labels.ratingQuality') }}:
                </span>
                <StarRating class="mt-2" :value="transport.feedback.overall" small view />
            </div>
            <div v-if="transport.feedback.material" class="transport-hub-platform__rating">
                <span class="font-copy-sm text-subdued">
                    {{ $t('pages.transportHub.platform.labels.ratingQuality') }}:
                </span>
                <StarRating class="mt-2" :value="transport.feedback.material" small view />
            </div>
            <div v-if="transport.feedback.speed" class="transport-hub-platform__rating">
                <span class="font-copy-sm text-subdued">
                    {{ $t('pages.transportHub.platform.labels.ratingSpeed') }}:
                </span>
                <StarRating class="mt-2" :value="transport.feedback.speed" small view />
            </div>
            <div v-if="transport.feedback.friendliness" class="transport-hub-platform__rating">
                <span class="font-copy-sm text-subdued">
                    {{ $t('pages.transportHub.platform.labels.ratingFriendliness') }}:
                </span>
                <StarRating class="mt-2" :value="transport.feedback.friendliness" small view />
            </div>
        </template>
        <span v-else-if="transport.status === 'delivered'" class="font-copy-sm text-subdued">
            {{ $t('pages.transport.transportListSupplier.noRating') }}
        </span>
    </div>
</template>

<script>
import routeContext from '@/plugins/mixins/routeContext';
import { CONTEXT_SUPPLIER } from '@/constants/context';

import StarRating from '@/components/Form/StarRating';

/**
 * !!! ATTENTION !!!
 * This component and all of it's children can not have computed
 * properties since they introduce a performance issues on unmounting
 */
export default {
    name: 'TransportListBlockFeedback',
    components: {
        StarRating,
    },
    mixins: [routeContext],
    props: {
        transport: {
            type: Object,
            required: true,
        },
        context: {
            type: String,
            default: 'client',
        },
    },
    data() {
        const { transport } = this;

        return {
            showCompactRating: this.inRouteContext(CONTEXT_SUPPLIER) || this.context === CONTEXT_SUPPLIER,
            hasFeedback:
                transport.feedback &&
                (transport.feedback.material || transport.feedback.friendliness || transport.feedback.speed),
            showComponent: this.hasFeedback && this.transport.status === 'delivered',
        };
    },
};
</script>

<style lang="scss"></style>

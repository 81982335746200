var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tile-trigger gap-6 pl-4",class:{
        'tile-trigger--no-left': !_vm.$slots.left,
        'tile-trigger--loading': _vm.isLoading,
        'tile-trigger--spaced-top': _vm.spacedTop,
        'tile-trigger--spaced-top-small': _vm.spacedTopSmall,
        'tile-trigger--spaced-grid': _vm.spacedGrid,
        'tile-trigger--primary': _vm.primary,
        'tile-trigger--big': _vm.isSize('big'),
    },on:{"click":_vm.handleClick}},[(_vm.$slots.left)?_c('div',{staticClass:"self-center justify-self-end"},[_vm._t("left")],2):_vm._e(),_c('div',{staticClass:"tile-trigger__content"},[_vm._t("default")],2),(!_vm.noArrow)?_c('div',{staticClass:"tile-trigger__right"},[(_vm.isLoading)?_c('CircleSpinner',{staticClass:"tile-trigger__spinner",attrs:{"dark":""}}):_c('ArrowRightIcon',{attrs:{"width":"14","height":"14"}})],1):(_vm.$slots.right)?_c('div',{staticClass:"tile-trigger__right"},[_vm._t("right")],2):_vm._e()])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
import PlaygroundPage from './PlaygroundPage';
import GeocodingPlaygroundPage from './GeocodingPlaygroundPage';
// import PlaygroundFlyoutPage from './PlaygroundFlyoutPage';
// import FormPlaygroundPage from './FormPlaygroundPage';
// import RenderlessPlaygroundPage from './RenderlessPlaygroundPage';
// import DatetimePlaygroundPage from './DatetimePlaygroundPage';
import TransportListBlockPlaygroundPage from './TransportListBlockPlaygroundPage';
import { ReactPlaygroundPage } from './ReactPlaygroundPage';
// import DropdownPlaygroundPage from './DropdownPlaygroundPage';
// import MapsPlaygroundPage from './MapsPlaygroundPage';
// import FakeassignPlaygroundPage from './FakeassignPlaygroundPage';
import ButtonsPlaygroundPage from './ButtonsPlaygroundPage';
// import TrackingPage from './TrackingPage';
import FilterableListingPlaygroundPage from './FilterableListingPlaygroundPage';
import CheckoutRenderer from '@/pages/Checkout/components/CheckoutRenderer';
import ComponentsPlaygroundPage from './ComponentsPlaygroundPage';
import PlaygroundPageRenderer from './PlaygroundPageRenderer';

export default {
    path: '/playground',
    name: 'playground',
    component: PlaygroundPageRenderer,
    children: [
        {
            path: 'library',
            name: 'library',
            component: ComponentsPlaygroundPage,
        },
        {
            path: 'buttons',
            name: 'buttons',
            component: ButtonsPlaygroundPage,
        },
        {
            path: 'general',
            name: 'general',
            component: PlaygroundPage,
        },
        {
            path: 'buttons',
            name: 'buttons',
            component: ButtonsPlaygroundPage,
        },
        {
            path: 'react',
            name: 'react',
            component: ReactPlaygroundPage,
        },
        {
            path: 'transport',
            name: 'transport',
            component: TransportListBlockPlaygroundPage,
        },
        {
            path: 'geolocation',
            name: 'geolocation',
            component: GeocodingPlaygroundPage,
        },
        {
            path: 'filterable-listing',
            name: 'filterable-listing',
            component: FilterableListingPlaygroundPage,
        },
        {
            path: 'process',
            name: 'checkout-main',
            component: CheckoutRenderer,
            children: [],
        },
        // {
        //     path: 'generic-order',
        //     name: 'generic-order',
        //     component: AppOrder,
        //     children: [],
        // },
    ],
};

// see https://help.phrase.com/help/working-with-pluralization
const PHRASE_PLURALIZATION_CATEGORIES = ['zero', 'one', 'two', 'few', 'many', 'other'];
function isPhrasePluralizedKey(obj, path = []) {
    if (typeof obj !== 'object') {
        return false;
    }
    const somePluralized = Object.keys(obj).some(key => PHRASE_PLURALIZATION_CATEGORIES.includes(key));
    const onlyPluralized = Object.entries(obj).every(([key, value]) => PHRASE_PLURALIZATION_CATEGORIES.includes(key) && typeof value === 'string');
    if (process.env.NODE_ENV === 'development' && !onlyPluralized && somePluralized) {
        const namespace = `${path.join('.')}.*`;
        // eslint-disable-next-line no-console
        console.warn(`[phrase] Found key namespace ${namespace} that contains pluralized keywords. Consider renaming the ` +
            'key without using any of the following words as last segment: ' +
            PHRASE_PLURALIZATION_CATEGORIES.join(', '));
    }
    return onlyPluralized;
}
function mapPhrasePluralizedKeyToVueI18nKey(obj) {
    return PHRASE_PLURALIZATION_CATEGORIES.map(category => obj[category])
        .filter(Boolean)
        .join(' | ');
}
function recursivelyConvertPhraseMessagesToVueI18nMessages(node, path = []) {
    if (typeof node !== 'object') {
        return node;
    }
    const entries = Object.entries(node).map(([key, value]) => {
        const newPath = [...path, key];
        if (isPhrasePluralizedKey(value, newPath)) {
            return [key, mapPhrasePluralizedKeyToVueI18nKey(value)];
        }
        else {
            return [key, recursivelyConvertPhraseMessagesToVueI18nMessages(value, newPath)];
        }
    });
    return Object.fromEntries(entries);
}
export function convertPhraseMessagesToVueI18nMessages(node) {
    return recursivelyConvertPhraseMessagesToVueI18nMessages(node);
}

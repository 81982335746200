var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LayoutPage',{staticClass:"checkout-project-position-type-selection-page",attrs:{"is-flyout":""}},[_c('FlyoutHeader',{attrs:{"slot":"flyoutHeader"},slot:"flyoutHeader"}),_c('div',{staticClass:"container-off-canvas-sm my-6"},[(_vm.isLoading)?_c('LoadingCard'):[(!_vm.supplierHasProducts)?_c('section',{staticClass:"flex h-full w-full flex-col items-center justify-center",attrs:{"data-test":"choose-waste-no-waste-products-available"}},[_c('DisposalNonHazardousIcon',{staticClass:"mb-8",attrs:{"width":"100","height":"60"}}),_c('h3',{staticClass:"font-headline-lg-strong mb-2"},[_vm._v(" "+_vm._s(_vm.$t( 'pages.checkout.projectPositionDisposal.pages.chooseWaste.noWasteProductsAvailable.title' ))+" ")]),_c('p',{staticClass:"font-copy-sm mb-6"},[_vm._v(" "+_vm._s(_vm.$t( 'pages.checkout.projectPositionDisposal.pages.chooseWaste.noWasteProductsAvailable.description' ))+" ")]),_c('BaseButton',{staticClass:"w-auto",attrs:{"primary":"","type":"button","data-test":"back-to-disposer-selection-button"},on:{"click":function($event){_vm.goBack({
                            routeName: _vm.$root.findRouteName(_vm.$route.meta.previous),
                        })}}},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.projectPositionDisposal.pages.chooseWaste.noWasteProductsAvailable.cta'))+" ")])],1):_c('section',[_c('SfMessageBox',{staticClass:"mb-8"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.projectPositionDisposal.pages.chooseWaste.hint'))+" ")]),_c('SfAccordion',[_vm._l((_vm.categories),function(category){return [(category.hasContent)?_c('SfAccordionItem',{key:category.id,staticClass:"mb-4 shadow",attrs:{"data-test":`project-position-waste-details-category-${category.id}`,"open-on-init":_vm.hasCategorySelectedProduct(category),"icon-type":"plus-minus","has-background":""},on:{"accordion-click":params => _vm.selectCategory(category)},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"font-copy-md-strong mb-1 block"},[_vm._v(" "+_vm._s(category.wasteCode)+" ")]),_c('span',{staticClass:"font-copy-sm"},[_vm._v(" "+_vm._s(_vm.revertLocalizedValue(category.name))+" ")])]},proxy:true}],null,true)},[(category.hasContent)?_c('div',{staticClass:"space-4"},_vm._l((_vm.products[category.id]),function(product,i){return _c('button',{key:product.id,staticClass:"card card--hovered card--focussed w-full text-left",class:[
                                        {
                                            'card--selected !cursor-pointer': _vm.isSelectedProduct(product),
                                            'mt-4': i > 0,
                                        },
                                    ],attrs:{"data-test":`project-position-waste-details-product-${product.id}`},on:{"click":function($event){return _vm.setProduct(product)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.setProduct(product)}}},[_c('WasteCode',{staticClass:"mb-1",attrs:{"is-bold":"","is-block":"","font-size":"md","code":product.wasteCode,"is-hazardous":product.isDangerous}}),_c('span',{staticClass:"font-copy-sm"},[_vm._v(" "+_vm._s(_vm.revertLocalizedValue(product.productName))+" ")])],1)}),0):_vm._e()]):_vm._e()]})],2)],1)]],2)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
    <div class="lazy-loading-indicator" :style="{ width: `${customWidth}%` }">&nbsp;</div>
</template>

<script>
export default {
    name: 'LazyLoadingIndicator',

    props: {
        width: {
            type: Number,
            default: null,
        },
    },

    computed: {
        customWidth() {
            if (this.width) return this.width;

            return Math.round(Math.random() * (100 - 70) + 70);
        },
    },
};
</script>

<style lang="scss">
.lazy-loading-indicator {
    position: relative;
    width: 240px;
    max-width: 100%;
    height: 12px;
    max-height: 100%;
    background-color: $color-lightGrey;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
            to right,
            rgba(255, 0, 0, 0) 0%,
            $color-lightMediumGrey 20%,
            rgba(255, 0, 0, 0) 40%
        );
        background-size: 200% 100%;
        background-position: 0% 50%;
        animation: lazyLoadingAnimation 0.6s linear infinite;
    }
}

@keyframes lazyLoadingAnimation {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: -200% 50%;
    }
}
</style>

import { weighingTransmissionTypes } from '@/constants/weighingTransmissionTypes';

export function isManualWeighing(weighingTransmissionType) {
    return weighingTransmissionType === weighingTransmissionTypes.MANUAL;
}

export function isAutomaticWeighing(weighingTransmissionType) {
    return weighingTransmissionType === weighingTransmissionTypes.AUTOMATIC;
}

export function hasWeighingTransmissionType(weighingTransmissionType) {
    return !!weighingTransmissionType;
}

import numpadNumber from './numpadNumber';
import tons from './tons';
import km from './km';

export function mapFilters(filters) {
    return filters.reduce((result, filter) => {
        result[filter] = function (...args) {
            return this.$options.filters[filter](...args);
        };
        return result;
    }, {});
}

export default {
    install(Vue) {
        Vue.filter('numpadNumber', numpadNumber);
        Vue.filter('tons', tons);
        Vue.filter('km', km);
    },
};

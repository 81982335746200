/**
 * Optimistic check for deleted users
 * @param userData
 * @return {boolean}
 */
export function isUserDeleted(userData) {
    if (!userData) return false;
    // The user is probably deleted
    return userData.firstName === 'Gelöschter' && userData.lastName === 'Benutzer';
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAbility } from '@/reactBridge/useAbility';
import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
import { useQuery } from '@tanstack/react-query';
import { recentlyOrderedProducts } from '@/pages/Checkout/queries';
import { LoadingSpinner } from '@schuettflix/react-components';
import { ActiveProjectsIcon } from '@/constructionProjects/components/icons/ActiveProjectsIcon';
const ProductPrice = ({ price }) => {
    const { t } = useTranslation();
    const { formatPerTon } = useMoneyFormatter();
    return (<>
            <p className="text-subdued">{t('pages.checkout.productSelect.price.leadingText')}</p>
            <p>{formatPerTon(price)}</p>
        </>);
};
const ProductsList = ({ products, onSelect }) => {
    const { t } = useTranslation();
    const canSeePrices = useAbility('seePrices');
    const productAvailability = (product) => {
        if (!product.available) {
            return <span className="text-subdued">{t('pages.checkout.productSelect.price.availability')}</span>;
        }
        if (canSeePrices) {
            return <ProductPrice price={product.price}/>;
        }
    };
    const formatCategory = (arr) => {
        return arr.join(', ');
    };
    return (<ul className="flex flex-col gap-[1px] overflow-hidden rounded shadow-low">
            {products?.map((product, index) => (<li className="grid cursor-pointer grid-cols-[auto_1fr] items-center bg-surface p-4 hover:bg-surface-hovered" key={index} onClick={() => onSelect(product)}>
                    <div className="line-clamp-2 overflow-hidden">
                        <h2 className="font-copy-md-strong mb-1 truncate" title={formatCategory(product.category)}>
                            {formatCategory(product.category)}
                        </h2>
                        <p className="font-copy-sm truncate text-subdued" title={product.productName}>
                            {product.productName}
                        </p>
                    </div>

                    <div className="font-copy-sm text-right">{productAvailability(product)}</div>
                </li>))}
        </ul>);
};
export const RecentOrderedProducts = ({ locationId, orderType, onSelect }) => {
    const { t } = useTranslation();
    const { data, isLoading } = useQuery({
        ...recentlyOrderedProducts(locationId, orderType),
        placeholderData: [],
    });
    return (<div className="m-4 lg:m-6">
            <p className="font-copy-md-strong my-6">{t('pages.checkout.recentProductSelection.title')}</p>
            {isLoading && <LoadingSpinner className="mx-auto p-6 text-center"/>}
            {!isLoading && !data?.length && (<div className="text-center">
                    <ActiveProjectsIcon className="inline-block"/>
                    <p className="font-copy-md-strong mb-2">
                        {t('pages.checkout.productSelect.recentProduct.emptyState.title')}
                    </p>
                    <p className="font-copy-md">
                        {t('pages.checkout.productSelect.recentProduct.emptyState.subtitle')}
                    </p>
                </div>)}
            {!isLoading && data && <ProductsList products={data} onSelect={onSelect}/>}
        </div>);
};

import { GenericPositionHTTPClient } from '@/modules/constructionProjects/services/GenericPositionHTTPClient';
export const activateGenericPositionById = async (positionId) => {
    return GenericPositionHTTPClient.patch(`/v1/position/${positionId}/activate`);
};
export function activateGenericPositionByIdKey(positionId) {
    return ['generic-positions', positionId, 'activate'];
}
export function activateGenericPositionByIdMutation(positionId) {
    return {
        mutationKey: activateGenericPositionByIdKey(positionId),
        mutationFn: () => activateGenericPositionById(positionId),
    };
}

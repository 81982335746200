<template>
    <div class="phone-fieldset" :data-test="`${dataTest}-wrapper`">
        <div class="phone-fieldset__fields" :class="{ 'phone-fieldset__fields--joined': joinedStyle }">
            <SelectBox
                :value="innerValue.countryCode"
                :label="prefixLabel || $t('components.phoneFieldSet.prefix')"
                class="phone-fieldset__select"
                :has-error="!!prefixError || hasPrefixError"
                :readonly="readonly"
                :disabled="disabled"
                :dark="dark"
                :data-test="dataTest"
                @input="update('countryCode', $event)"
            >
                <option v-for="(code, i) in countryCodes" :key="i" :value="code.code">
                    {{ code.code }}
                </option>
            </SelectBox>
            <TextField
                :value="innerValue.number"
                :label="label || $t('components.phoneFieldSet.phoneNumber')"
                :has-error="!!error || hasError"
                :focus-initially="focusInitially"
                :readonly="readonly"
                :disabled="disabled"
                :dark="dark"
                :data-test="dataTest"
                class="phone-fieldset__input"
                @input="update('number', $event)"
                @keydown="$emit('keydown', $event)"
                @blur="$emit('blur', $event)"
            >
                <slot v-if="$slots.action" slot="action" name="action" />
            </TextField>
        </div>
        <ErrorMessage v-if="error" :message="error" :dark="dark" />
    </div>
</template>

<script>
import countryCodes from '@/config/countryCodes';

import TextField from '@/components/Form/TextField.v2';
import SelectBox from '@/components/Form/SelectBox.v2';
import ErrorMessage from '@/components/Form/ErrorMessage';

export default {
    name: 'PhoneFieldset',
    components: {
        TextField,
        SelectBox,
        ErrorMessage,
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        prefixLabel: {
            type: String,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        prefixError: {
            type: String,
            default: null,
        },
        hasPrefixError: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        focusInitially: {
            type: Boolean,
            default: false,
        },
        joinedStyle: {
            type: Boolean,
            default: false,
        },
        dataTest: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            innerValue: {},
        };
    },
    computed: {
        countryCodes: () => countryCodes,
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.innerValue = value || {};
            },
        },
    },
    methods: {
        update(field, value) {
            this.$emit('input', {
                ...this.innerValue,
                [field]: value,
            });
        },
    },
};
</script>

<style lang="scss">
.phone-fieldset {
    &__fields {
        display: flex;
        width: 100%;

        &--joined {
            .select-box__input-group {
                border-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            .text-field__input-group {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    &__select {
        width: 120px;
        position: relative;
        z-index: 1;

        .select-box__input-group {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
        }
    }

    &__input {
        flex-grow: 1;

        .text-field__input-group {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}
</style>

import { useGetters, useMutations } from 'vuex-composition-helpers';
import { useTransportActionLifecycle } from './useTransportActionLifecycle';
import { computed } from 'vue';
export const useTransportAction = (transportActionName) => {
    const transportActionNames = Array.isArray(transportActionName)
        ? transportActionName
        : [transportActionName];
    const { transport, context } = useGetters('transportActions', ['transport', 'context']);
    const { updateTransport } = useMutations('transportActions', ['updateTransport']);
    const transportActionLifecycles = transportActionNames.map(useTransportActionLifecycle);
    const isActionOpen = computed(() => transportActionLifecycles.some(({ isActionOpen }) => isActionOpen.value));
    const openAction = transportActionLifecycles[0].openAction;
    const closeAction = () => transportActionLifecycles.forEach(({ closeAction }) => closeAction());
    const onActionOpen = (cb) => transportActionLifecycles.forEach(({ onActionOpen }) => onActionOpen(cb));
    const onActionClose = (cb) => transportActionLifecycles.forEach(({ onActionClose }) => onActionClose(cb));
    return {
        transport,
        context,
        updateTransport: updateTransport,
        // lifecycle
        isActionOpen,
        openAction,
        closeAction,
        onActionOpen,
        onActionClose,
    };
};

/* eslint-disable complexity */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { getSellersByProductAttributes } from '@/pages/Checkout/queries';
import { findRouteName } from '@/pages/routerUtils';
import { Button, Tag } from '@schuettflix/react-components';
import { TimeIcon, CallIcon, OrganizationsIcon, MaterialIcon } from '@schuettflix/icons-react';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { useQuery } from '@tanstack/react-query';
import LoadingSpinner from '@/pages/CertificateManagement/components/LoadingSpinner';
import { useVuexState } from '@/reactBridge/useVuex';
import store from '@/store';
import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
import { useAddressFormatter } from '@/utils/useAddressFormatter';
import { Map } from '@/pages/Checkout/components/Map';
import FlagIcon from '@/assets/icons/flag.svg?external';
import clsx from 'clsx';
import SupplierApi from '@/services/Api/Supplier';
import QuoteService from '@/services/QuoteService';
import { useWeekDays } from '@/services/utils/useWeekDays';
import { sortBy } from 'lodash';
import { useOrderScreenName } from './analytics/react/useOrderScreenName';
import { Flyout } from '@/constructionProjects/components/Flyout';
import { useAbility } from '@/reactBridge/useAbility';
import useSupplierRequestPlayload from '@/pages/Checkout/useSupplierRequestPlayload';
const SupplierTile = ({ factoryId, sellerOrganizationName, price, address, masterProductName, productName, distanceInMeters, organizationLogo, sellerLocationOpenNow, openingTimes, expanded, phone, email, website, className, organizationDescription, onClick, }) => {
    const { t } = useTranslation();
    const { formatPerTon } = useMoneyFormatter();
    const { format } = useAddressFormatter();
    const { getCurrentWeekDayNumber, getWeekDayName } = useWeekDays();
    const canSeePrices = useAbility('seePrices');
    const getOpenStatusText = () => {
        const weekDay = getCurrentWeekDayNumber();
        const openingTime = openingTimes.find(openingTime => openingTime.dayOfWeek === weekDay);
        return t('pages.checkout.pickupSupplierSelection.supplierCard.openingTimes.endTime', {
            endTime: openingTime?.end,
        });
    };
    const getClosedStatusText = () => {
        const weekDay = getCurrentWeekDayNumber();
        const nextOpenWeekDay = openingTimes.find(openingTime => openingTime.dayOfWeek > weekDay);
        if (nextOpenWeekDay) {
            return t('pages.checkout.pickupSupplierSelection.supplierCard.nextOpeningTime.title', {
                weekDay: getWeekDayName(nextOpenWeekDay.dayOfWeek),
                startTime: nextOpenWeekDay.start,
                endTime: nextOpenWeekDay.end,
            });
        }
        const nextOpenWeekDayAfterWeekend = sortBy(openingTimes, 'dayOfWeek')[0];
        return t('pages.checkout.pickupSupplierSelection.supplierCard.nextOpeningTime.title', {
            weekDay: getWeekDayName(nextOpenWeekDayAfterWeekend.dayOfWeek),
            startTime: nextOpenWeekDayAfterWeekend.start,
            endTime: nextOpenWeekDayAfterWeekend.end,
        });
    };
    return (<div className={clsx('w-full cursor-pointer rounded bg-surface', expanded ? 'h-full p-8' : 'p-4 shadow-low', className)} onClick={() => onClick(factoryId)} data-test="pickup-supplier-selection-select-button">
            <div className="mb-2 flex justify-between">
                <img src={organizationLogo.small} alt={sellerOrganizationName} className="max-h-12 max-w-full"/>
                {canSeePrices && <div className="font-headline-md-strong">{formatPerTon(price)}</div>}
            </div>

            <div>
                <div className="font-headline-md-strong">{sellerOrganizationName}</div>
                <div className="font-copy-md">{format(address)}</div>
                <div className="font-copy-md mt-1 text-subdued">
                    {t('pages.checkout.pickupSupplierSelection.supplierCard.distance', {
            distance: (distanceInMeters / 1000).toFixed(1),
        })}
                </div>
            </div>

            <hr className="border-1 my-4 border-solid border-divider"/>

            <div className="flex flex-col gap-4">
                <div className="flex justify-between gap-2">
                    {expanded && <MaterialIcon size="sm"/>}
                    <div className="min-w-0  flex-1">
                        <div className="font-headline-md-strong">{masterProductName}</div>
                        <div className="font-copy-md">{productName}</div>
                    </div>
                </div>

                <div className="flex justify-between gap-2">
                    <TimeIcon size="sm"/>
                    {sellerLocationOpenNow ? (<div className="font-headline-md-strong flex flex-1 justify-between">
                            {getOpenStatusText()}
                            <div className="ml-4 flex flex-col">
                                <Tag label={t('pages.checkout.pickupSupplierSelection.supplierCard.tagOpen.label')} className="mr-2" type="positive"/>
                            </div>
                        </div>) : (<div className="font-headline-md-strong flex flex-1 justify-between">
                            {getClosedStatusText()}
                            <div className="ml-4 flex flex-col">
                                <Tag label={t('pages.checkout.pickupSupplierSelection.supplierCard.tagClose.label')} className="mr-2 " subdued/>
                            </div>
                        </div>)}
                </div>
            </div>
            {expanded && (<main className={clsx({ 'flex flex-col': !organizationDescription })}>
                    <OpeningTimes openingTimes={openingTimes} className="pr-2 pt-2"/>
                    <hr className="border-1 my-4 border-solid border-divider"/>
                    <ContactData phone={phone} email={email} website={website} className={clsx('pt-2', { 'flex-1': !organizationDescription })}/>

                    {organizationDescription && (<>
                            <hr className="border-1 my-4 border-solid border-divider"/>
                            <div className="flex justify-between gap-2">
                                <OrganizationsIcon size="sm"/>
                                <div className="min-w-0 flex-1">
                                    <div className="font-headline-md-strong mb-2">
                                        {t('pages.checkout.pickupSupplierSelection.supplierInfo.companyProfile.title')}
                                    </div>
                                    <div className="font-copy-md">{organizationDescription}</div>
                                </div>
                            </div>
                        </>)}
                </main>)}
        </div>);
};
const OpeningTimes = ({ openingTimes, className }) => {
    const { t } = useTranslation();
    const { weekDays } = useWeekDays();
    const mappedWeekDays = weekDays.map(entry => {
        const openingTime = openingTimes.find(openingTime => openingTime.dayOfWeek === entry.key);
        return {
            ...entry,
            start: openingTime?.start,
            end: openingTime?.end,
        };
    });
    return (<div className={clsx('flex flex-col justify-between gap-1', className)}>
            {mappedWeekDays.map(entry => {
            return (<div key={entry.key} className="flex justify-between">
                        <div className="flex gap-2">
                            <div className="w-6"></div>
                            <div>{entry.name}</div>
                        </div>
                        <div>
                            {entry.start
                    ? `${entry.start} - ${entry.end}`
                    : `${t('pages.checkout.pickupSupplierSelection.supplierInfo.weekendsTime.closed')}`}
                        </div>
                    </div>);
        })}
        </div>);
};
const ContactData = ({ email, phone, website, className }) => {
    const { t } = useTranslation();
    return (<>
            <div className="flex gap-2">
                <CallIcon size="sm"/>
                <div className="font-headline-md-strong flex min-w-0 flex-1">
                    {t('pages.checkout.pickupSupplierSelection.contactData.header')}
                </div>
            </div>

            <div className={clsx('flex flex-col gap-2', className)}>
                <div className="flex gap-2">
                    <div className="w-6"></div>
                    <div>
                        {phone?.countryCode}
                        {phone?.number}
                    </div>
                </div>
                <div className="flex gap-2">
                    <div className="w-6"></div>
                    <div>{email}</div>
                </div>
                <div className="flex gap-2">
                    <div className="w-6"></div>
                    <div>{website}</div>
                </div>
            </div>
        </>);
};
const Suppliers = ({ selected, onSupplierSelect }) => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const orderType = useVuexState(store, state => state.basket.type);
    const locationId = useVuexState(store, state => state.basket.constructionSite.constructionProjectLocationId);
    const masterProductId = useVuexState(store, state => state.basket.product.masterProductId);
    const attributeRange = useVuexState(store, state => state.basket.product.attributeRange);
    const attributes = useVuexState(store, state => state.basket.product.attributeIds);
    const { lat, lng } = useVuexState(store, state => state.basket.constructionSite.location);
    const { generateDeliveryPayload } = useSupplierRequestPlayload();
    const { qty: orderAmount, operations } = generateDeliveryPayload();
    const { data: suppliers, isLoading } = useQuery({
        ...getSellersByProductAttributes(orderType, locationId, masterProductId, attributeRange, attributes, {
            orderAmount,
            operations,
        }),
    });
    if (isLoading) {
        return <LoadingSpinner spaced/>;
    }
    if (!isLoading && suppliers?.length === 0) {
        return (<div className="font-copy-md flex justify-center pt-8">
                {t('pages.checkout.deliverySupplierSelection.noSuppliers')}
            </div>);
    }
    const showDetails = (factoryId) => {
        onSupplierSelect(factoryId);
    };
    const markers = suppliers
        ?.filter(supplier => (selected ? supplier.factoryId === selected : true))
        .map(supplier => ({
        lat: supplier.address.latitude || 0,
        lng: supplier.address.longitude || 0,
    })) || [];
    markers.push({
        lat,
        lng,
        icon: FlagIcon,
        size: 50,
    });
    const selectedSupplier = suppliers?.find(supplier => supplier.factoryId === selected);
    const submitSupplier = async () => {
        if (!selectedSupplier) {
            return;
        }
        const supplierInfo = await SupplierApi.getOneById(selected);
        store.commit('basket/setSupplierInfo', {
            ...supplierInfo,
            sellerProductId: selectedSupplier.sellerProductId,
        });
        try {
            await QuoteService.saveQuote();
        }
        catch (err) {
            store.commit('basket/setServerError', err);
        }
        await vueRouter.push({ name: findRouteName(vueRouter.currentRoute?.meta?.next) }).catch(() => { });
    };
    return (<>
            <div className="h-[154px]">
                <Map markers={markers} className="h-[154px]"/>
            </div>
            <div className={clsx('h-full w-full', { 'px-4 py-6': !selectedSupplier })}>
                {selectedSupplier && (<>
                        <SupplierTile {...selectedSupplier} onClick={() => { }} expanded className="mb-[72px]"/>
                        <Button label={t('pages.checkout.pickupSupplierSelection.supplierInfo.button.label')} className="absolute bottom-0 w-full rounded-none" onClick={() => submitSupplier()} data-test="pickup-supplier-info-submit-button"/>
                    </>)}
                {!selectedSupplier &&
            suppliers?.map(supplier => (<div className="mb-4" key={supplier.sellerProductId}>
                            <SupplierTile {...supplier} onClick={showDetails} expanded={false}/>
                        </div>))}
            </div>
        </>);
};
const _PickupSupplierSelectionPage = () => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [show, setShow] = useState(true);
    const handleBackButton = () => {
        if (!selectedSupplier) {
            setShow(false);
            // This is a hack to make the back animation work
            setTimeout(() => {
                return vueRouter.push({ name: findRouteName(vueRouter.currentRoute?.meta?.previous) });
            }, 300);
            return;
        }
        setSelectedSupplier(null);
    };
    return (<>
            <Flyout onClose={handleBackButton} show={show} headerTitle={selectedSupplier
            ? t('pages.checkout.pickupSupplierSelection.supplierInfo.title')
            : t('pages.checkout.pickupSupplierSelection.suppliersList.title')} body={<div className={clsx('flex h-full flex-col overflow-y-auto', !selectedSupplier ? 'bg' : 'bg-surface')}>
                        <Suppliers selected={selectedSupplier} onSupplierSelect={factoryId => setSelectedSupplier(factoryId)}/>
                    </div>} screenName={useOrderScreenName('supplierselection')}/>
        </>);
};
export const PickupSupplierSelectionPage = asVueComponent(_PickupSupplierSelectionPage);

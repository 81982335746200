import { reactive, ref, computed, watch, unref } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import GlobalVariables from '@/services/GlobalVariables';
import { generateTranslatedErrorMessage, useApiSubscription } from '@/services/Api/useApiSubscription';
import { isFileDocument, isFileImage } from '@/services/FileService';
export function useOCRProcessing({ doFileProcessing, file, productUseCases, expectResponseProperties = [], 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
emit, }) {
    const fileType = computed(() => {
        if (file.value && isFileDocument(file.value))
            return 'document';
        return 'image';
    });
    const timeoutBasedOnProductUseCases = ref({});
    GlobalVariables.get('platform_ocr_timeout', '{}').then(value => {
        timeoutBasedOnProductUseCases.value = JSON.parse(value);
    });
    const productUseCase = computed(() => {
        if (!file.value)
            return null;
        if (isFileDocument(file.value) && 'document' in productUseCases)
            return productUseCases.document;
        if (isFileImage(file.value) && 'image' in productUseCases)
            return productUseCases.image;
        return null;
    });
    const timeout = computed(() => productUseCase.value && productUseCase.value in timeoutBasedOnProductUseCases.value
        ? timeoutBasedOnProductUseCases.value[productUseCase.value]
        : 10000);
    const timeoutIdentifier = ref(null);
    const resetTimeout = () => {
        if (timeoutIdentifier.value) {
            window.clearTimeout(timeoutIdentifier.value);
            timeoutIdentifier.value = null;
        }
    };
    const isFileProcessed = ref(false);
    const reset = () => {
        setMessage();
        isFileProcessed.value = false;
    };
    const exitAnimation = (validate = true) => {
        if (!isDone.value && timeoutIdentifier.value) {
            emit('tracking', {
                step: 'ocr_finished',
                fail_reason: 'user_cancelled',
            });
            resetTimeout();
        }
        if (validate)
            validateResponse();
        isFileProcessed.value = true;
    };
    const onExitAnimation = (validate = true) => exitAnimation(validate);
    /**
     * Subscription
     */
    const successResponse = ref(null);
    const errorResponse = ref(null);
    // check if OCR response is fully done
    const isOCRCompleted = computed(() => !!successResponse.value);
    // enforce fileUUID to be null if not doFileProcessing
    const fileUuid = computed(() => file.value?.uuid || null);
    const doProcessing = computed(() => doFileProcessing.value && timeout.value);
    const uuid = computed(() => timeoutIdentifier.value && doProcessing.value && !isOCRCompleted.value ? fileUuid.value : null);
    const subscription = useApiSubscription('/v2/{fileType}/{uuid}/data/{productUseCase}', {
        params: { uuid, fileType, productUseCase },
    });
    watch(subscription, subscription => {
        // set success and error data
        if (subscription.data?.status === 'completed' && subscription.data)
            successResponse.value = subscription.data;
        if (subscription.error)
            errorResponse.value = subscription.error;
    });
    watch(fileUuid, (uuid, previous) => {
        resetTimeout();
        if (!uuid)
            return;
        if (!doProcessing.value) {
            exitAnimation(false);
            return;
        }
        if (uuid !== previous) {
            // reset success and error data
            successResponse.value = null;
            errorResponse.value = null;
        }
        if (uuid !== previous && timeout.value)
            timeoutIdentifier.value = window.setTimeout(() => {
                if (!isDone.value)
                    emit('tracking', {
                        step: 'ocr_finished',
                        fail_reason: 'timed_out',
                    });
                resetTimeout();
                exitAnimation();
            }, timeout.value);
    }, { immediate: true });
    /**
     * Response Handling
     */
    const isUploading = computed(() => !fileUuid.value && doProcessing.value);
    const isProcessing = computed(() => !!fileUuid.value && doProcessing.value);
    const isDone = computed(() => !!successResponse.value || !!errorResponse.value);
    const isAnimationRunning = computed(() => (isUploading.value || isProcessing.value || isDone.value) && !isFileProcessed.value);
    watch(isProcessing, (isProcessing, previous) => {
        if (isProcessing && !previous) {
            emit('tracking', {
                step: 'ocr_started',
            });
        }
    });
    const message = reactive({
        isVisible: false,
        type: 'error',
        title: null,
        description: null,
    });
    const setMessage = ({ isVisible = false, type = 'error', title = null, description = null } = {}) => {
        message.isVisible = isVisible;
        message.type = type;
        message.title = title;
        message.description = description;
    };
    const { t } = useI18n();
    const hasFoundAll = computed(() => typeof successResponse.value === 'object' &&
        unref(expectResponseProperties).every(i => successResponse.value && Object.prototype.hasOwnProperty.call(successResponse.value, i)));
    const hasFoundSome = computed(() => !hasFoundAll.value &&
        typeof successResponse.value === 'object' &&
        unref(expectResponseProperties).some(i => successResponse.value && Object.prototype.hasOwnProperty.call(successResponse.value, i)));
    const hasFoundNone = computed(() => !successResponse.value && !errorResponse.value);
    const validateResponse = () => {
        if (!doProcessing.value)
            return setMessage();
        // found all
        if (hasFoundAll.value)
            return setMessage({
                isVisible: true,
                type: 'success',
                title: t('components.transport.ocr.fileScanAnimation.message.success.v2.title'),
                description: t('components.transport.ocr.fileScanAnimation.message.success.v2.description'),
            });
        // something found
        if (hasFoundSome.value)
            return setMessage({
                isVisible: true,
                type: 'warning',
                description: t('components.transport.ocr.fileScanAnimation.message.info.description'),
            });
        // nothing found, error or timeout
        setMessage({
            isVisible: true,
            type: 'error',
            description: (errorResponse.value && generateTranslatedErrorMessage(errorResponse.value)) ||
                t('components.transport.ocr.fileScanAnimation.message.error.description'),
        });
    };
    watch(isDone, isDone => {
        if (isDone) {
            validateResponse();
            emit('tracking', {
                step: 'ocr_finished',
            });
        }
    });
    return {
        response: computed(() => ({ data: successResponse.value, error: errorResponse.value?.message || null })),
        message,
        isProcessing,
        isDone,
        isAnimationRunning,
        isFileProcessed,
        onExitAnimation,
        hasFoundAll,
        hasFoundSome,
        hasFoundNone,
        reset,
    };
}

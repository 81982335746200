import { invoke, until, useElementVisibility } from '@vueuse/core';
import { computed } from 'vue';
export const useVisibility = ({ elementRef, scrollTarget = computed(() => elementRef.value?.closest('.scroll-container')), }) => {
    const isVisible = useElementVisibility(elementRef, {
        scrollTarget,
    });
    const onceVisibleCallbacks = [];
    invoke(async () => {
        await until(isVisible).toBeTruthy();
        onceVisibleCallbacks.forEach(cb => cb());
    });
    /**
     * Receives a callback that is executed as soon as the element is visible.
     * If the element is already visible, the callback gets executed immediately
     */
    const onceVisible = (callback) => (isVisible.value ? callback : onceVisibleCallbacks.push(callback));
    return { onceVisible, isVisible };
};

<template>
    <div>
        <GridRow class="organization-form__fieldset">
            <span class="span-2 font-copy-sm-strong block leading-[14px]">
                {{ $t('components.organizationForm.nameHeadline') }}
            </span>
            <TextField
                v-model="model.name"
                :readonly="readonly"
                :error="errors.name"
                :label="$t('components.organizationForm.name')"
                class="span-2"
                data-test="organization-form-name"
            />
        </GridRow>
        <GridRow class="organization-form__fieldset" :count="6">
            <span class="span-6 font-copy-sm-strong block leading-[14px]"
                >{{ getBillingAddressTranslationForKey('title') }}
            </span>

            <ErrorMessage
                v-if="hasErrors.billingAddress.invalid"
                :message="errors.billingAddress.invalid"
                class="span-6"
            />

            <TextField
                v-model="model.billingAddress.additionalAddress"
                :readonly="readonly"
                :label="getBillingAddressTranslationForKey('additionalAddress')"
                class="span-6"
                data-test="organization-form-billingAddress-additionalAddress"
            />
            <TextField
                v-model="model.billingAddress.street"
                :readonly="readonly"
                :error="errors.billingAddress.street"
                :label="getBillingAddressTranslationForKey('street')"
                :class="$root.isDesktop ? 'span-5' : 'span-4'"
                data-test="organization-form-billingAddress-street"
            />
            <TextField
                v-model="model.billingAddress.number"
                :readonly="readonly"
                :error="errors.billingAddress.number"
                :label="getBillingAddressTranslationForKey('number')"
                :class="$root.isDesktop ? 'span-1' : 'span-2'"
                data-test="organization-form-billingAddress-number"
            />

            <TextField
                v-model="model.billingAddress.zip"
                :readonly="readonly"
                :error="errors.billingAddress.zip"
                :label="getBillingAddressTranslationForKey('zip')"
                :class="$root.isDesktop ? 'span-1' : 'span-2'"
                data-test="organization-form-billingAddress-zip"
            />
            <TextField
                v-model="model.billingAddress.city"
                :readonly="readonly"
                :error="errors.billingAddress.city"
                :label="getBillingAddressTranslationForKey('city')"
                :class="$root.isDesktop ? 'span-5' : 'span-4'"
                data-test="organization-form-billingAddress-city"
            />

            <SelectBox
                v-model="model.billingAddress.country"
                :readonly="readonly || !billingAddressCountries.length"
                :error="errors.billingAddress.country"
                :label="getBillingAddressTranslationForKey('country')"
                :options="billingAddressCountries"
                :option-value-renderer="country => country.code"
                :option-label-renderer="country => $t(country.name)"
                class="span-3"
                data-test="organization-form-billingAddress-country"
            >
                <template v-if="billingAddressCountriesLoading" #icon>
                    <CircleSpinner dark tiny />
                </template>
            </SelectBox>

            <SelectBox
                v-model="model.billingAddress.state"
                :readonly="readonly || !billingAddressStates.length"
                :error="errors.billingAddress.state"
                :label="getBillingAddressTranslationForKey('state')"
                :options="billingAddressStates"
                :option-value-renderer="state => state.code"
                :option-label-renderer="state => $t(state.name)"
                class="span-3"
                data-test="organization-form-billingAddress-state"
            >
                <template v-if="billingAddressCountriesLoading" #icon>
                    <CircleSpinner dark tiny />
                </template>
            </SelectBox>
        </GridRow>
        <GridRow class="organization-form__fieldset" :count="6">
            <span class="span-3 font-copy-sm-strong block leading-[14px]">
                {{ $t('components.organizationForm.tax.title') }}
            </span>
            <span class="span-3 font-copy-sm-strong block leading-[14px]">
                {{ $t('components.organizationForm.vat.title') }}
            </span>

            <TextField
                v-model="model.taxId"
                :readonly="readonly"
                :error="errors.taxId"
                :label="$t('components.organizationForm.tax.taxId')"
                class="span-3"
                data-test="organization-form-tax-id"
            />

            <TextField
                v-model="model.vatId"
                :readonly="readonly"
                :error="errors.vatId"
                :label="$t('components.organizationForm.tax.vatId')"
                class="span-3"
                data-test="organization-form-vat-id"
            />
        </GridRow>
        <GridRow v-if="canDoDisposalTransports && model.id && isGermanMarket" class="organization-form__fieldset">
            <span class="span-3 font-copy-sm-strong block leading-[14px]">
                {{ $t('disposal.carrierNumber.title') }}
            </span>
            <div class="span-3 w-full">
                <DisposalNumber
                    v-model="model.carrierNumber"
                    :address="model.billingAddress"
                    :disposal-number-string="model.carrierNumber"
                    is-location-state-read-only
                    accept-empty-disposal-number
                    class="span-3"
                    :error-message="getCarrierNumberError"
                    name="organization.carrierNumber"
                />
            </div>
        </GridRow>

        <GridRow v-if="isSchuettflixOrganization" class="organization-form__fieldset" :count="6">
            <span class="span-6 font-copy-sm-strong block leading-[14px]">
                {{ $t('components.organizationForm.directDebit.title') }}
            </span>
            <TextField
                v-model="model.creditorId"
                :readonly="readonly"
                :error="errors.creditorId"
                :label="$t('components.organizationForm.directDebit.creditorId')"
                class="span-6"
                data-test="organization-form-direct-debit-creditor-id"
            />
        </GridRow>
    </div>
</template>

<script>
import { AvailableMarkets } from '@/services/MarketPermission/constants';
import ErrorMessage from '@/components/Form/ErrorMessage';
import GridRow from '@/components/Layout/GridRow';
import SelectBox from '@/components/Form/SelectBox.v3';
import TextField from '@/components/Form/TextField.v2';

import CircleSpinner from '@/components/Spinners/CircleSpinner.vue';

import DisposalNumber from '@/_components/DisposalNumber/DisposalNumber.vue';

export default {
    name: 'TabMain',

    components: {
        ErrorMessage,
        GridRow,
        SelectBox,
        TextField,

        CircleSpinner,

        DisposalNumber,
    },

    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Object,
            default: () => ({
                name: false,
                billingAddress: {
                    invalid: false,
                    additionalAddress: false,
                    street: false,
                    number: false,
                    city: false,
                    zip: false,
                    country: false,
                    state: false,
                },
                taxId: false,
                vatId: false,
            }),
        },
        hasErrors: {
            type: Object,
            default: () => ({
                billingAddress: {
                    invalid: false,
                },
            }),
        },
        billingAddressCountries: {
            type: Array,
            default: () => [],
        },
        billingAddressCountriesLoading: {
            type: Boolean,
            default: false,
        },
        originalOrganizationCarrierNumber: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            organization: {},
        };
    },

    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        isSchuettflixOrganization() {
            return this.organization?.types?.includes('platform');
        },
        canDoDisposalTransports() {
            return this.$can('doDisposalTransports', this.organization);
        },
        getCarrierNumberError() {
            return (this.originalOrganizationCarrierNumber && this.organization.carrierNumber?.length < 9) ||
                (this.organization.carrierNumber && this.organization.carrierNumber?.length < 9)
                ? 'components.disposalNumber.carrierNumber.errorMessage'
                : null;
        },
        billingAddressStates() {
            return (
                this.billingAddressCountries.find(country => country.code === this.organization.billingAddress.country)
                    ?.states ?? []
            );
        },
        isGermanMarket() {
            return this.organization.market.code === AvailableMarkets.DE;
        },
    },

    created() {
        this.organization = this.value;
    },
    methods: {
        getBillingAddressTranslationForKey(translationKey) {
            return this.$t(`components.organizationForm.billingAddress.${translationKey}`);
        },
    },
};
</script>

<style lang="scss" scoped>
.organization-form__fieldset {
    & + & {
        margin-top: 60px;
    }
}
</style>

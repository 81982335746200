import { typeToField } from '../ProductCatalog/FieldTypeMapper';
import _set from 'lodash/set';
import _get from 'lodash/get';
/**
 * Maps server validation error response to pass to the vuelidate model
 */
export const mapErrors = (errors) => {
    return errors?.reduce?.((result, error) => {
        if (!result[error.property_path]) {
            _set(result, error.property_path, []);
        }
        _set(result, error.property_path, [..._get(result, error.property_path), error.message]);
        return result;
    }, {});
};
export const remapErrors = (errors) => {
    return errors.map(error => ({
        property_path: typeToField(error.property_path),
        message: error.message,
    }));
};

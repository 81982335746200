import _isArray from 'lodash/isArray';
const initialState = {
    transport: null,
    action: null,
    context: null,
    currentTransportStatus: null,
    props: {},
};
const getters = {
    transport: (state) => state.transport,
    action: (state) => state.action,
    context: (state) => state.context,
    currentTransportStatus: (state) => state.currentTransportStatus,
    props: (state) => state.props,
};
const mutations = {
    updateTransport(state, transport) {
        if (state.transport && state.transport.id === transport.id) {
            state.transport = { ...state.transport, ...transport };
        }
        else {
            state.transport = transport;
        }
    },
    setTransport(state, transport) {
        state.transport = transport;
    },
    setAction(state, { action, context }) {
        state.action = action;
        state.context = context;
    },
    internalSetCurrentTransportStatus(state, transportStatus) {
        state.currentTransportStatus = transportStatus;
    },
    setProps(state, props = {}) {
        state.props = props;
    },
    resetAction(state, action = null) {
        const reset = () => {
            state.action = null;
            state.context = null;
            state.props = {};
        };
        if (!action) {
            reset();
        }
        else if (_isArray(action) && action.includes(state.action)) {
            reset();
        }
        else if (state.action === action) {
            reset();
        }
    },
};
const actions = {
    set({ commit }, { action, transport, context = null, update = false, props = {}, }) {
        commit('setAction', { action, context });
        commit('setProps', props || {});
        commit(update ? 'updateTransport' : 'setTransport', transport);
        commit('internalSetCurrentTransportStatus', transport?.status || null);
    },
    setCurrentTransportStatus({ commit }, transportStatus = null) {
        commit('internalSetCurrentTransportStatus', transportStatus);
    },
};
export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

<template>
    <div class="filter-box" :class="{ 'filter-box--no-padding': noPadding }">
        <div v-if="inlineMode" class="filter-box__body">
            <slot :filter="filter" :update-filter="updateFilter" />
        </div>

        <BaseButton
            v-if="!inlineMode && !hideFilterIcon"
            class="filter-box__button mt-1"
            :data-test="dataTest && `${dataTest}-button`"
            @click="openFlyout"
        >
            <span class="flex flex-grow items-center gap-2">
                <SfFilterNotificationButton :filter-counter="activeCount" @click="openFlyout" />
                <slot name="button">
                    <span v-if="buttonLabel" class="filter-box__button-label font-copy-md-strong">
                        {{ buttonLabel }}
                    </span>
                </slot>
            </span>
        </BaseButton>

        <MountingPortal mount-to="#end-of-body" append>
            <div class="isolate">
                <Flyout
                    v-if="!inlineMode"
                    :screen-name="screenName"
                    :active="isFlyoutOpen"
                    data-test="filterbox-flyout"
                    @closed="cancelAndCloseFlyout"
                >
                    <HeaderBar slot="header">
                        <HeaderBarItem slot="left" button @click="closeFlyout">
                            <ArrowIcon width="32" height="18" />
                        </HeaderBarItem>
                        <div slot="headline">{{ $t('components.filterBox.headline') }}</div>
                        <HeaderBarItem slot="right">
                            <BaseButton arrow-right @click="resetFilter(true)">
                                {{ $t('components.filterBox.resetFilterLabel') }}
                            </BaseButton>
                        </HeaderBarItem>
                    </HeaderBar>

                    <form @submit.prevent="saveFilter(closeFormOnAutomaticSubmit)">
                        <div class="filter-box__body">
                            <slot :filter="filter" :update-filter="updateFilter" />
                        </div>
                    </form>

                    <ButtonGroup slot="bottom">
                        <BaseButton primary light arrow-left @click="cancelAndCloseFlyout">{{
                            $t('components.filterBox.cancel')
                        }}</BaseButton>
                        <BaseButton primary data-test="filterbox-flyout-confirm" @click="saveFilter">
                            <template #prefix>
                                <span v-if="isLoading"> <LoadingSpinner />&nbsp; </span>
                                <span v-else-if="resultsCount > 0">{{ resultsCount }}&nbsp;</span>
                            </template>
                            <span>{{ $tc('components.filterBox.showResultsWithCount', resultsCount) }}</span>
                        </BaseButton>
                    </ButtonGroup>
                </Flyout>
            </div>
        </MountingPortal>
    </div>
</template>

<script>
import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Flyout from '@/components/Layout/Flyout';
import LoadingSpinner from '@/components/LoadingSpinner';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import ArrowIcon from '@/assets/icons/regular/arrow.svg';

import _debounce from 'lodash/debounce';
import _cloneDeep from 'lodash/cloneDeep';
import _pickBy from 'lodash/pickBy';
import _omit from 'lodash/omit';
import _omitBy from 'lodash/omitBy';
import _isArray from 'lodash/isArray';
import _identity from 'lodash/identity';
import _groupBy from 'lodash/groupBy';
import _difference from 'lodash/difference';
import _uniq from 'lodash/uniq';
import Toaster from '@/services/Toaster';

import { computed } from 'vue';

import { SfFilterNotificationButton } from '@schuettflix/vue-components';

const UPDATE_DEBOUNCE_MS = 1500;

export const useTwoWayBindingComputedFilter =
    ({ props, emit }) =>
    filterName =>
        computed({
            get() {
                return props.filterScope?.filter?.[filterName];
            },
            set(filterValue) {
                emit('updateFilter', {
                    filterName,
                    filterValue,
                });
            },
        });

export default {
    name: 'FilterBox',
    components: {
        ButtonGroup,
        BaseButton,
        Flyout,
        LoadingSpinner,
        HeaderBar,
        HeaderBarItem,
        ArrowIcon,
        SfFilterNotificationButton,
    },
    props: {
        screenName: {
            type: String,
            default: undefined,
        },
        /**
         * @type {import('vue-i18n').TranslateResult}
         */
        headline: {
            type: String,
            default: 'Filter',
        },
        defaultFilter: {
            type: Object,
            default: null,
        },
        forcedFilter: {
            type: Object,
            default: () => {},
        },
        value: {
            type: Object,
            default: null,
        },
        endpoint: {
            type: Object,
            required: true,
        },
        inlineMode: {
            type: Boolean,
            default: false,
        },
        ignoreInCount: {
            type: Array,
            default: () => [],
        },
        dataTest: {
            type: String,
            default: undefined,
        },
        noPadding: {
            type: Boolean,
            default: false,
        },
        hideFilterIcon: {
            type: Boolean,
            default: false,
        },
        buttonLabel: {
            type: String,
            default: null,
        },
        closeFormOnAutomaticSubmit: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            filter: _cloneDeep(this.value),
            storedFilter: _cloneDeep(this.value),
            localDefaultFilter: _cloneDeep(this.defaultFilter),
            isFlyoutOpen: false,
            resultsCount: null,
            lastResult: null,
            externalChange: false,
            cancelSource: null,
            isLoading: false,
        };
    },
    computed: {
        activeCountKeys() {
            let activeFilter = _pickBy(this.filter, _identity);

            const ignoreInCount = ['perPage', 'page', 'sortBy', 'sortDirection', ...this.ignoreInCount];

            activeFilter = _omit(activeFilter, ignoreInCount);

            // remove empty values from count
            activeFilter = _omitBy(activeFilter, value => {
                if (_isArray(value)) {
                    return value.length === 0;
                }

                if (value === null) {
                    return true;
                }

                return false;
            });

            activeFilter = _groupBy(Object.keys(activeFilter), value => {
                return value.replace(/^(end|start)/, '');
            });

            // remove object like keys, eg. originGeo.place
            activeFilter = _uniq(
                Object.keys(activeFilter).map(key => {
                    return key.replace(/\..*/, '');
                })
            );

            // cleanup ignored attributes (again)
            activeFilter = _difference(activeFilter, ignoreInCount);

            return Object.values(activeFilter);
        },
        activeCount() {
            return this.activeCountKeys.length;
        },
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                this.$logger().log('External change');
                this.externalChange = true;
                this.filter = _cloneDeep(value);
                this.storedFilter = _cloneDeep(value);
            },
        },
        filter: {
            deep: true,
            handler() {
                this.$logger().log(
                    'Change detected — External:',
                    this.externalChange,
                    '— Inline Mode:',
                    this.inlineMode
                );

                // do not refresh on external change
                if (this.externalChange) {
                    this.$set(this, 'externalChange', false);
                    this.$logger().log('Update external change: ', this.externalChange);
                    return;
                }

                this.handleLocalFilterChange();
                this.$emit('activeCountUpdated', this.activeCount);
            },
        },
    },
    created() {
        this.$logger().enabled = false;

        this.$on('openFilterBox', this.openFlyout);
        this.$on('closeFilterBox', this.closeFlyout);
        this.$emit('activeCountUpdated', this.activeCount);
    },
    methods: {
        updateFilter({ filterName, filterValue } = {}) {
            this.$set(this.filter, filterName, filterValue);
        },
        openFlyout() {
            // store initial filter for cancel action
            this.storedFilter = _cloneDeep(this.filter);

            this.resetFilter();
            this.refreshList();

            this.isFlyoutOpen = true;
        },
        closeFlyout() {
            this.isFlyoutOpen = false;
        },
        cancelAndCloseFlyout() {
            // restore initial filter for cancel action
            this.filter = _cloneDeep(this.storedFilter);
            this.closeFlyout();
        },
        handleLocalFilterChange: _debounce(function () {
            if (this.inlineMode) {
                this.$logger().log('Changed: notify about update');
                this.$emit('input', this.filter);
                this.$emit('update');
            } else if (this.isFlyoutOpen) {
                this.$logger().log('Refresh filter count');
                this.refreshList();
            }
        }, UPDATE_DEBOUNCE_MS),
        resetFilter(isHard) {
            this.filter = isHard ? _cloneDeep(this.localDefaultFilter) : _cloneDeep(this.value);
        },
        saveFilter(closeOnSubmit = true) {
            this.$logger().log('Saved: notify about update');
            this.$emit('input', this.filter);
            this.$emit('save');

            if (!closeOnSubmit) return;
            this.closeFlyout();
        },
        async refreshList() {
            // cancel previous request
            this.isLoading = true;
            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.cancelSource = this.endpoint.createCancelTokenSource();

            if (!this.inlineMode) {
                this.filter.page = 1;
            }

            try {
                this.lastResult = await this.endpoint.filter(this.filter, null, null, this.cancelSource, {
                    ...this.forcedFilter,
                    countOnly: true,
                });
                this.resultsCount = this.lastResult.count;
            } catch (err) {
                this.resultsCount = 0;

                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                    Toaster.error(err);
                }
            }

            this.isLoading = false;
        },
    },
};
</script>

<style lang="scss">
.filter-box__body {
    padding: 20px 15px;

    @media only screen and (min-width: $layout-desktop-min) {
        padding: 20px 25px;
    }
}

.filter-box__button {
    position: relative;
}

.filter-box__button-label {
    font-weight: $font-weight-regular;
}

.filter-box__button-count {
    display: block;
    width: 20px;
    height: 20px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    font-weight: $font-weight-bold;
    background-color: $color-red;
    color: $color-white;
    border-radius: 100%;
    position: absolute;
    top: -7px;
    left: -9px;
}

.filter-box--no-padding {
    padding: 0;

    .filter-box__body {
        padding: 0;
    }
}
</style>

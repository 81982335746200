import 'react-cmdk/dist/cmdk.css';
import CommandPalette, { filterItems, getItemIndex, useHandleOpenCommandPalette } from 'react-cmdk';
import { useState } from 'react';
import { CreditNotesIcon, DocumentsIcon, InvoicesIcon, JobsIcon, OrderManagementIcon, OrdersIcon, OrganizationsIcon, PartnershipIcon, ProductsIcon, ProjectsIcon, SettingsIcon, TransporthubIcon, UserSearchIcon, } from '@schuettflix/icons-react';
import { useTranslation } from 'react-i18next';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
import { useCan } from '@/reactBridge/useAbility';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { usePreferredColorScheme } from '@/reactBridge/usePreferredColorScheme';
import { fromNumberAsString, NumberType } from '@/services/utils/numberType';
function withColorScheme(Icon) {
    return function IconWithColorScheme() {
        const colorScheme = usePreferredColorScheme();
        return <Icon on={colorScheme === 'dark' ? 'primary' : undefined}/>;
    };
}
const KCommandPalette = () => {
    const can = useCan();
    const { t } = useTranslation();
    const { selectCreditNoteDocKey } = useLegalTerms();
    const [page, setPage] = useState('root');
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const onChangeOpen = (isOpen) => {
        setOpen(isOpen);
        setPage('root');
        setSearch('');
    };
    const { vueRouter } = useVueRouter();
    useHandleOpenCommandPalette(setOpen);
    const filterItemsWithAbility = (items) => {
        return items.filter(item => {
            if (item.abilityCheck) {
                return item.abilityCheck(can);
            }
            if (item.ability) {
                return can(item.ability);
            }
            return true;
        });
    };
    const filteredItems = filterItems([
        {
            id: 'Order',
            heading: t('components.navigation.labels.order-management'),
            items: filterItemsWithAbility([
                {
                    id: 'projects',
                    children: t('components.navigation.labels.projects'),
                    icon: ProjectsIcon,
                    closeOnSelect: false,
                    onClick: () => {
                        setPage('projects');
                    },
                    ability: 'haveProjectManagement',
                },
                {
                    id: 'orders',
                    children: t('components.navigation.labels.order-management-orders'),
                    icon: OrderManagementIcon,
                    closeOnSelect: false,
                    onClick: () => {
                        setPage('orders');
                    },
                    ability: 'listOrders',
                },
                {
                    id: 'jobs',
                    children: t('components.navigation.labels.order-management-transports'),
                    icon: TransporthubIcon,
                    closeOnSelect: false,
                    onClick: () => {
                        setPage('jobs');
                    },
                    ability: 'havePlatformTransportManagement',
                },
            ]),
        },
        {
            id: 'accounting',
            heading: t('components.navigation.labels.accounting'),
            items: filterItemsWithAbility([
                {
                    id: 'Invoices',
                    children: t('components.navigation.labels.accounting-invoices'),
                    icon: InvoicesIcon,
                    closeOnSelect: false,
                    onClick: () => {
                        setPage('invoices');
                    },
                    ability: 'haveInvoiceManagement',
                },
                {
                    id: 'CreditNotes',
                    children: selectCreditNoteDocKey({
                        creditNote: t('components.navigation.labels.accounting-invoice-corrections.creditNote'),
                        selfBilling: t('components.navigation.labels.accounting-invoice-corrections.selfBilling'),
                    }),
                    icon: CreditNotesIcon,
                    closeOnSelect: false,
                    onClick: () => {
                        setPage('creditNotes');
                    },
                    ability: 'haveCreditNoteManagement',
                },
                {
                    id: 'PaymentTerms',
                    children: t('components.navigation.labels.accounting-paymentTerms'),
                    icon: SettingsIcon,
                    onClick: () => {
                        vueRouter.push({ name: 'accounting__payment-terms-selection' });
                    },
                    ability: 'netSuitePaymentTermsEligibilityManagement',
                },
            ]),
        },
        {
            id: 'management',
            heading: t('components.navigation.labels.management'),
            items: filterItemsWithAbility([
                {
                    id: 'organizations',
                    children: t('components.navigation.labels.management-organisations'),
                    icon: OrganizationsIcon,
                    onClick: () => {
                        vueRouter.push({ name: 'management__organization-list' });
                    },
                    ability: 'haveOrganizationManagement',
                },
                {
                    id: 'users',
                    children: t('components.navigation.labels.management-users'),
                    icon: UserSearchIcon,
                    onClick: () => {
                        vueRouter.push({ name: 'management__user-management' });
                    },
                    abilityCheck: can => can('haveUserManagement') && can('listAllUsers'),
                },
                {
                    id: 'productCatalog',
                    children: t('components.navigation.labels.management-product-catalog'),
                    icon: ProductsIcon, // TODO: replace with correct icon
                    onClick: () => {
                        vueRouter.push({ name: 'management__product-management' });
                    },
                    ability: 'listProductCatalog',
                },
                {
                    id: 'partnerSearch',
                    children: t('components.navigation.labels.management-partner-search'),
                    icon: PartnershipIcon, // TODO: replace with correct icon
                    onClick: () => {
                        vueRouter.push({ name: 'management__partner-search' });
                    },
                    ability: 'havePartnerSearch',
                },
                {
                    id: 'receipts',
                    children: selectCreditNoteDocKey({
                        creditNote: t('components.navigation.labels.accounting-invoice-corrections.creditNote'),
                        selfBilling: t('components.navigation.labels.accounting-invoice-corrections.selfBilling'),
                    }),
                    icon: DocumentsIcon,
                    onClick: () => {
                        vueRouter.push({ name: 'management__documents' });
                    },
                    ability: 'haveReceipts',
                },
                {
                    id: 'supplierProducts',
                    children: (can => {
                        if (can('listSupplierProducts') && !can('viewSupplierWasteProducts')) {
                            return t('components.navigation.labels.management-products-supplier');
                        }
                        if (!can('listSupplierProducts') && can('viewSupplierWasteProducts')) {
                            return t('components.navigation.labels.management-products-waste');
                        }
                        return t('components.navigation.labels.management-products');
                    })(can),
                    icon: OrganizationsIcon,
                    onClick: () => {
                        vueRouter.push({ name: 'management__organization-list' });
                    },
                    abilityCheck: can => can('listSupplierProducts') || can('viewSupplierWasteProducts'),
                },
                {
                    id: 'reports',
                    children: t('components.navigation.labels.management-reporting'),
                    icon: 'PresentationChartLineIcon', // TODO: replace with correct icon
                    onClick: () => {
                        vueRouter.push({ name: 'management__reporting' });
                    },
                    ability: 'haveReports',
                },
                {
                    id: 'settings',
                    children: t('components.navigation.labels.management-settings'),
                    icon: SettingsIcon,
                    onClick: () => {
                        vueRouter.push({ name: 'management__settings' });
                    },
                },
            ]),
        },
    ].filter(group => group.items.length > 0), search);
    const searchAccountingDocument = () => {
        switch (page) {
            case 'invoices':
                // eslint-disable-next-line no-case-declarations
                const invoiceSearchFilter = {
                    page: 1,
                    perPage: 25,
                    sortBy: 'number',
                    sortDirection: 'desc',
                    search: search.trim(),
                };
                window.location.href = '#/accounting/invoice?invoice=' + btoa(JSON.stringify(invoiceSearchFilter));
                break;
            case 'creditNotes':
                // eslint-disable-next-line no-case-declarations
                const creditNoteSearchFilter = {
                    page: 1,
                    perPage: 25,
                    sortBy: 'number',
                    sortDirection: 'desc',
                    numberSearch: search.trim(),
                };
                window.location.href =
                    '#/accounting/credit-note?credit-notes=' + btoa(JSON.stringify(creditNoteSearchFilter));
                break;
            default:
            // empty for the moment
        }
    };
    const searchProjects = () => {
        const projectSearchFilter = {
            status: 'open',
            page: 1,
            perPage: 25,
            sortBy: 'number',
            sortDirection: 'desc',
            search: search.trim(),
        };
        window.location.href = '#/order-management/projects?project=?' + btoa(JSON.stringify(projectSearchFilter));
    };
    const searchOrders = () => {
        const orderSearchFilter = {
            page: 1,
            perPage: 25,
            sortBy: 'created',
            sortDirection: 'desc',
            search: search.trim(),
        };
        window.location.href = '#/order-management/orders?order=' + btoa(JSON.stringify(orderSearchFilter));
    };
    const searchJobsByTransportNumber = () => {
        const jobSearchFilter = {
            page: 1,
            perPage: 25,
            seedSortBy: 'status',
            sortBy: 'urgency',
            sortDirection: 'asc',
            organization: 1,
            user: 1,
            transportNumber: search.trim(),
        };
        window.location.href = '#/order-management/transport-hub?transport=' + btoa(JSON.stringify(jobSearchFilter));
    };
    const searchJobsByWeighingNoteNumber = () => {
        const jobSearchFilter = {
            page: 1,
            perPage: 25,
            seedSortBy: 'status',
            sortBy: 'urgency',
            sortDirection: 'asc',
            organization: 1,
            user: 1,
            weighingNoteNumber: search.trim(),
        };
        window.location.href = '#/order-management/transport-hub?transport=' + btoa(JSON.stringify(jobSearchFilter));
    };
    const InvoiceSearch = (<CommandPalette.FreeSearchAction label={'Search Invoice'} icon={withColorScheme(InvoicesIcon)} onClick={searchAccountingDocument}/>);
    const CreditNoteSearch = (<CommandPalette.FreeSearchAction label={'Search Credit Note'} icon={withColorScheme(CreditNotesIcon)} onClick={searchAccountingDocument}/>);
    const ProjectSearch = (<CommandPalette.FreeSearchAction label={'Search Project'} icon={withColorScheme(ProjectsIcon)} onClick={searchProjects}/>);
    const OrderSearch = (<CommandPalette.FreeSearchAction label={'Search Orders'} icon={withColorScheme(OrdersIcon)} onClick={searchOrders}/>);
    const JobSearch = (<>
            <CommandPalette.FreeSearchAction label={'Search Jobs by "Transport Number"'} icon={withColorScheme(JobsIcon)} onClick={searchJobsByTransportNumber}/>
            <CommandPalette.FreeSearchAction label={'Search Jobs by "Weighing Note Number"'} icon={withColorScheme(JobsIcon)} onClick={searchJobsByWeighingNoteNumber}/>
        </>);
    let rootPageContent = <div />;
    if (filteredItems.length > 0) {
        rootPageContent = filteredItems.map(list => (<CommandPalette.List key={list.id} heading={list.heading}>
                {list.items.map(({ id, 
            // @ts-expect-error abilityCheck is a custom property on the JsonStructure
            // defined above. It destructed here so it's not passed to the ListItem
            abilityCheck: _abilityCheck, icon, ...rest }) => (<CommandPalette.ListItem key={id} index={getItemIndex(filteredItems, id)} icon={withColorScheme(icon)} {...rest}/>))}
            </CommandPalette.List>));
    }
    else {
        const searchByNumberType = new Map([
            [NumberType.INVOICE, InvoiceSearch],
            [NumberType.CREDIT_NOTE, CreditNoteSearch],
            [NumberType.ORDER, OrderSearch],
            [NumberType.PROJECT, ProjectSearch],
            [NumberType.JOB, JobSearch],
        ]);
        const numberType = fromNumberAsString(search);
        const numberTypeSearch = searchByNumberType.get(numberType);
        if (numberTypeSearch !== undefined) {
            rootPageContent = numberTypeSearch;
        }
        else {
            rootPageContent = <CommandPalette.FreeSearchAction disabled={true} label={'Nothing found'}/>;
        }
    }
    return (<CommandPalette onChangeSearch={setSearch} onChangeOpen={onChangeOpen} search={search} isOpen={open} page={page}>
            <CommandPalette.Page id="root">{rootPageContent}</CommandPalette.Page>

            <CommandPalette.Page id="invoices" searchPrefix={['Invoices']}>
                {InvoiceSearch}
            </CommandPalette.Page>
            <CommandPalette.Page id="creditNotes" searchPrefix={['Credit Notes']}>
                {CreditNoteSearch}
            </CommandPalette.Page>
            <CommandPalette.Page id="projects" searchPrefix={['Projects']}>
                {ProjectSearch}
            </CommandPalette.Page>
            <CommandPalette.Page id="orders" searchPrefix={['Orders']}>
                {OrderSearch}
            </CommandPalette.Page>
            <CommandPalette.Page id="jobs" searchPrefix={['Jobs']}>
                {JobSearch}
            </CommandPalette.Page>
        </CommandPalette>);
};
export default KCommandPalette;

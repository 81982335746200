<template>
    <div class="platform-contact">
        <Flyout
            screen-name="contact"
            :active="platformContactFlyoutActive"
            :headline="$t('platformContact.headline')"
            size="small"
            @closed="closeFlyout"
        >
            <Tile no-border no-padding-bottom>
                <Words block bold>
                    {{ $t('platformContact.intro') }}
                </Words>
            </Tile>

            <Tile no-border>
                <Words block bold spaced>
                    {{ $t('platformContact.openingTimesHeadline') }}
                </Words>

                <OpeningTimes v-if="openingTimes" :opening-times="openingTimes.openingTimes" />
            </Tile>

            <Tile no-border class="platform-contact__tile--grey">
                <Words block bold spaced>
                    {{ $t('platformContact.headlineOptions') }}
                </Words>
                <Words block spaced v-html="$t('platformContact.introService')" />

                <ButtonGroup floating class="platform-contact__button-group">
                    <Button :href="`mailto:${serviceEmail}`" primary light arrow-right block>
                        <Words bold>
                            {{ serviceEmail }}
                        </Words>
                        <MailIcon slot="left" width="28" height="28" class="icon--inline" />
                    </Button>
                </ButtonGroup>
            </Tile>

            <Tile no-border class="platform-contact__tile--grey">
                <Words block spaced>
                    {{
                        $t(
                            selectCreditNoteDocKey({
                                creditNote: `platformContact.introPayment-2.creditNote`,
                                selfBilling: `platformContact.introPayment-2.selfBilling`,
                            })
                        )
                    }}
                </Words>
                <ButtonGroup floating vertical class="platform-contact__button-group">
                    <Button :href="`mailto:${accountingEmail}`" primary light arrow-right block>
                        <Words bold>
                            {{ accountingEmail }}
                        </Words>
                        <MailIcon slot="left" width="28" height="28" class="icon--inline" />
                    </Button>
                </ButtonGroup>
            </Tile>

            <Tile no-border no-padding-bottom>
                <Words block bold spaced>
                    {{ $t('platformContact.introPhone') }}
                </Words>
            </Tile>
            <List v-if="servicePhone">
                <ContactItem :phone-number="servicePhone" is-simple-phone-number />
            </List>
        </Flyout>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ContactItem from '@/components/List/ContactItem';
import Flyout from '@/components/Layout/Flyout';
import List from '@/components/List/List';
import OpeningTimes from '@/components/Supplier/OpeningTimes';
import Tile from '@/components/Layout/Tile';
import Words from '@/components/Typography/Words';

import MailIcon from '@/assets/icons/regular/email.svg';

export default {
    name: 'PlatformContact',
    components: {
        Button,
        ButtonGroup,
        ContactItem,
        Flyout,
        List,
        OpeningTimes,
        Tile,
        Words,
        MailIcon,
    },
    props: {
        platformContactFlyoutActive: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { selectCreditNoteDocKey } = useLegalTerms();
        return {
            selectCreditNoteDocKey,
        };
    },

    computed: {
        ...mapGetters('platform', ['openingTimes', 'serviceEmail', 'accountingEmail', 'servicePhone']),
    },

    methods: {
        closeFlyout() {
            this.$eventHub.$emit('page.platformContact', false);
        },
    },
};
</script>

<style lang="scss">
.platform-contact__tile--grey {
    background-color: $color-lightGrey;
}

.platform-contact__button-group {
    margin: 0;
}
</style>

<template>
    <Card spaceless clickable dark @click="$emit('click')">
        <Words block bold spaced-bottom-small white class="status-card__title">
            {{ title }}
        </Words>
        <div class="status-card__content">
            <component :is="icon" class="status-card__icon" />
            <div class="status-card__info">
                <Words block white class="status-card__number">
                    {{ count }}
                </Words>
                <Words block bold tiny white>
                    {{ description }}
                </Words>
            </div>
            <ArrowIcon class="status-card__arrow-icon" />
        </div>
    </Card>
</template>

<script>
import Card from '@/components/Layout/Card';
import Words from '@/components/Typography/Words';

import ArrowIcon from '@/assets/icons/regular/arrow-right-bold.svg';
import CreditIcon from '@/assets/icons/homeIcons/credits.svg';
import InvoiceIcon from '@/assets/icons/homeIcons/invoice.svg';
import MyLoadingsIcon from '@/assets/icons/homeIcons/my-loadings.svg';
import MyTransportsIcon from '@/assets/icons/homeIcons/my-transports.svg';
import MyTripsIcon from '@/assets/icons/homeIcons/my-trips.svg';
import OrderIcon from '@/assets/icons/homeIcons/orders.svg';
import TransportHubIcon from '@/assets/icons/homeIcons/transport-hub.svg';
import TransportIcon from '@/assets/icons/homeIcons/transports.svg';

export default {
    name: 'StatusCard',

    components: {
        Card,
        Words,

        ArrowIcon,
        CreditIcon,
        InvoiceIcon,
        MyLoadingsIcon,
        MyTransportsIcon,
        MyTripsIcon,
        OrderIcon,
        TransportHubIcon,
        TransportIcon,
    },

    props: {
        title: {
            type: String,
            default: 'Title',
        },
        count: {
            type: Number,
            default: 0,
        },
        description: {
            type: String,
            default: 'Description',
        },
        icon: {
            type: String,
            default: 'TransportIcon',
            validator: value =>
                [
                    'CreditIcon',
                    'InvoiceIcon',
                    'MyLoadingsIcon',
                    'MyTransportsIcon',
                    'MyTripsIcon',
                    'OrderIcon',
                    'TransportHubIcon',
                    'TransportIcon',
                ].includes(value),
        },
    },
};
</script>

<style lang="scss">
.status-card__title {
    font-size: 8px;
}

.status-card__content {
    display: grid;
    grid-template-columns: 45px 1fr auto;
}

.status-card__icon {
    max-width: 32px;
    max-height: 32px;
}

.status-card__info {
    display: flex;
    align-items: flex-end;

    @media screen and (min-width: $layout-desktop-min) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.status-card__number {
    font-size: 40px;
    font-weight: bold;
    line-height: 30px;
    margin-right: 10px;

    @media screen and (min-width: $layout-desktop-min) {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

.status-card__arrow-icon {
    height: 14px;
    align-self: end;
    fill: white;
}
</style>

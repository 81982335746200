import { computed } from 'vue';
import { useI18n } from 'vue-i18n-composable';
import { LOADING_STAGES } from '@/components/Form/LoadingInput.v2/loadingStages';
import AnimatedAlmostFull from '@/components/Animation/AnimatedAlmostFull.vue';
import AnimatedHeavyWeight from '@/components/Animation/AnimatedHeavyWeight.vue';
import AnimatedLightTour from '@/components/Animation/AnimatedLightTour.vue';
import AnimatedMaximumWeight from '@/components/Animation/AnimatedMaximumWeight.vue';
import AnimatedPointCharge from '@/components/Animation/AnimatedPointCharge.vue';
import FeatherLightIcon from '@/assets/icons/freight/feather-light.svg';
import DefaultIcon from '@/assets/icons/freight/default.svg';
export const useMessage = ({ loadingType, stage, hasValidationResponse, }) => {
    const { t, te } = useI18n();
    // translations
    const translations = computed(() => ({
        default: {
            loading: {
                headline: t('components.loadingInput.loadingInputMessages.loading.default.v2.headline'),
                content: t('components.loadingInput.loadingInputMessages.loading.default.v2.content'),
            },
            unloading: {
                headline: t('components.loadingInput.loadingInputMessages.unloading.default.v2.headline'),
                content: t('components.loadingInput.loadingInputMessages.unloading.default.v2.content'),
            },
        }[loadingType],
        // valid cases
        [LOADING_STAGES.FEATHER_LIGHT]: {
            loading: {
                headline: t('components.loadingInput.loadingInputMessages.loading.stage0.v2.headline'),
                content: te('components.loadingInput.loadingInputMessages.loading.stage0.v2.content')
                    ? t('components.loadingInput.loadingInputMessages.loading.stage0.v2.content')
                    : null,
            },
            unloading: {
                headline: t('components.loadingInput.loadingInputMessages.unloading.stage0.v2.headline'),
                content: te('components.loadingInput.loadingInputMessages.unloading.stage0.v2.content')
                    ? t('components.loadingInput.loadingInputMessages.unloading.stage0.v2.content')
                    : null,
            },
        }[loadingType],
        [LOADING_STAGES.LIGHT_TOUR]: {
            loading: {
                headline: t('components.loadingInput.loadingInputMessages.loading.stage1.v2.headline'),
                content: te('components.loadingInput.loadingInputMessages.loading.stage1.v2.content')
                    ? t('components.loadingInput.loadingInputMessages.loading.stage1.v2.content')
                    : null,
            },
            unloading: {
                headline: t('components.loadingInput.loadingInputMessages.unloading.stage1.v2.headline'),
                content: te('components.loadingInput.loadingInputMessages.unloading.stage1.v2.content')
                    ? t('components.loadingInput.loadingInputMessages.unloading.stage1.v2.content')
                    : null,
            },
        }[loadingType],
        [LOADING_STAGES.ALMOST_FULL]: {
            loading: {
                headline: t('components.loadingInput.loadingInputMessages.loading.stage2.v2.headline'),
                content: te('components.loadingInput.loadingInputMessages.loading.stage2.v2.content')
                    ? t('components.loadingInput.loadingInputMessages.loading.stage2.v2.content')
                    : null,
            },
            unloading: {
                headline: t('components.loadingInput.loadingInputMessages.unloading.stage2.v2.headline'),
                content: te('components.loadingInput.loadingInputMessages.unloading.stage2.v2.content')
                    ? t('components.loadingInput.loadingInputMessages.unloading.stage2.v2.content')
                    : null,
            },
        }[loadingType],
        [LOADING_STAGES.POINT_CHARGE]: {
            loading: {
                headline: t('components.loadingInput.loadingInputMessages.loading.stage3.v2.headline'),
                content: te('components.loadingInput.loadingInputMessages.loading.stage3.v2.content')
                    ? t('components.loadingInput.loadingInputMessages.loading.stage3.v2.content')
                    : null,
            },
            unloading: {
                headline: t('components.loadingInput.loadingInputMessages.unloading.stage3.v2.headline'),
                content: te('components.loadingInput.loadingInputMessages.unloading.stage3.v2.content')
                    ? t('components.loadingInput.loadingInputMessages.unloading.stage3.v2.content')
                    : null,
            },
        }[loadingType],
        [LOADING_STAGES.HEAVY_WEIGHT]: {
            loading: {
                headline: t('components.loadingInput.loadingInputMessages.loading.stage4.v2.headline'),
                content: te('components.loadingInput.loadingInputMessages.loading.stage4.v2.content')
                    ? t('components.loadingInput.loadingInputMessages.loading.stage4.v2.content')
                    : null,
            },
            unloading: {
                headline: t('components.loadingInput.loadingInputMessages.unloading.stage4.v2.headline'),
                content: te('components.loadingInput.loadingInputMessages.unloading.stage4.v2.content')
                    ? t('components.loadingInput.loadingInputMessages.unloading.stage4.v2.content')
                    : null,
            },
        }[loadingType],
        // invalid cases
        [LOADING_STAGES.EXCEED_MAXIMUM]: {
            loading: {
                headline: t('components.loadingInput.loadingInputMessages.loading.stage5.v2.headline'),
                content: te('components.loadingInput.loadingInputMessages.loading.stage5.v2.content')
                    ? t('components.loadingInput.loadingInputMessages.loading.stage5.v2.content')
                    : null,
            },
            unloading: {
                headline: t('components.loadingInput.loadingInputMessages.unloading.stage5.v2.headline'),
                content: te('components.loadingInput.loadingInputMessages.unloading.stage5.v2.content')
                    ? t('components.loadingInput.loadingInputMessages.unloading.stage5.v2.content')
                    : null,
            },
        }[loadingType],
        [LOADING_STAGES.BELOW_MINIMUM]: {
            loading: {
                headline: t('components.loadingInput.loadingInputMessages.loading.stage6.v2.headline'),
                content: te('components.loadingInput.loadingInputMessages.loading.stage6.v2.content')
                    ? t('components.loadingInput.loadingInputMessages.loading.stage6.v2.content')
                    : null,
            },
            unloading: {
                headline: t('components.loadingInput.loadingInputMessages.unloading.stage6.v2.headline'),
                content: te('components.loadingInput.loadingInputMessages.unloading.stage6.v2.content')
                    ? t('components.loadingInput.loadingInputMessages.unloading.stage6.v2.content')
                    : null,
            },
        }[loadingType],
    }));
    // Icons
    const icons = computed(() => ({
        default: DefaultIcon,
        [LOADING_STAGES.FEATHER_LIGHT]: FeatherLightIcon,
        [LOADING_STAGES.LIGHT_TOUR]: AnimatedLightTour,
        [LOADING_STAGES.ALMOST_FULL]: AnimatedAlmostFull,
        [LOADING_STAGES.POINT_CHARGE]: AnimatedPointCharge,
        [LOADING_STAGES.HEAVY_WEIGHT]: AnimatedHeavyWeight,
        [LOADING_STAGES.BELOW_MINIMUM]: AnimatedMaximumWeight,
        [LOADING_STAGES.EXCEED_MAXIMUM]: AnimatedMaximumWeight,
    }));
    const isSuccess = computed(() => [LOADING_STAGES.POINT_CHARGE].includes(stage.value));
    const isWarning = computed(() => [
        LOADING_STAGES.FEATHER_LIGHT,
        LOADING_STAGES.LIGHT_TOUR,
        LOADING_STAGES.ALMOST_FULL,
        LOADING_STAGES.HEAVY_WEIGHT,
    ].includes(stage.value));
    const isError = computed(() => [LOADING_STAGES.BELOW_MINIMUM, LOADING_STAGES.EXCEED_MAXIMUM].includes(stage.value));
    const showPhoneNumber = computed(() => {
        return (hasValidationResponse.value &&
            [LOADING_STAGES.BELOW_MINIMUM, LOADING_STAGES.EXCEED_MAXIMUM].includes(stage.value));
    });
    return {
        headline: computed(() => hasValidationResponse.value ? translations.value[stage.value].headline : translations.value.default.headline),
        content: computed(() => hasValidationResponse.value ? translations.value[stage.value].content : translations.value.default.content),
        showPhoneNumber,
        icon: computed(() => (hasValidationResponse.value ? icons.value[stage.value] : icons.value.default)),
        isSuccess,
        isWarning,
        isError,
    };
};

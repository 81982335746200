<template>
    <Card
        :key="id"
        :class="[
            'accordion',
            {
                'accordion--primary': isPrimary,
                'accordion--secondary': isSecondary,
                'accordion--empty': hideEmptyAccordion,
                'accordion--disabled': showEmptyAccordion,
                'accordion--header-only': !hasDefaultSlotContent,
                'accordion--spaced': spaced,
            },
        ]"
        :shadowless="isSecondary"
        spaceless
        no-padding
    >
        <div
            ref="header"
            :class="[
                'accordion__head',
                {
                    'accordion__head--has-preheader': $scopedSlots.preheader,
                    'accordion__head--has-postheader': $scopedSlots.postheader,
                },
            ]"
            data-test="accordion-item-content"
            @click="onClick"
        >
            <!-- HEADER SLOTS -->
            <div v-if="$scopedSlots.preheader" class="accordion__preheader"><slot name="preheader" /></div>
            <div class="accordion__header">
                <slot name="header">
                    <Words bold white>PLACE HEADER HERE</Words>
                </slot>
            </div>
            <div v-if="$scopedSlots.postheader" class="accordion__postheader"><slot name="postheader" /></div>

            <!-- STATE ICONS -->
            <slot v-if="hasDefaultSlotContent && hasContent && isOpen" name="icon-state-open">
                <ArrowUpIcon v-if="isSecondary" width="19" height="10" class="accordion__icon icon" />
                <MinusIcon v-else class="accordion__icon icon icon--white" width="18" height="18" />
            </slot>
            <slot v-else-if="hasDefaultSlotContent && hasContent" name="icon-state-close">
                <ArrowDownIcon v-if="isSecondary" width="19" height="10" class="accordion__icon icon" />
                <PlusIcon v-else class="accordion__icon icon icon--white" width="18" height="18" />
            </slot>
        </div>

        <!-- CONTENT -->
        <template v-if="isOpen && hasDefaultSlotContent">
            <slot />
        </template>
    </Card>
</template>

<script>
import * as uuid from 'uuid';

/* Components */
import Card from '@/components/Layout/Card';
import Words from '@/components/Typography/Words';

/* Icons */
import ArrowDownIcon from '@/assets/icons/regular/arrow-stripeless--down.svg';
import ArrowUpIcon from '@/assets/icons/regular/arrow-stripeless--up.svg';
import MinusIcon from '@/assets/icons/micro/minus.svg';
import PlusIcon from '@/assets/icons/micro/plus.svg';

export default {
    name: 'AccordionComponent',
    components: {
        /* Components */
        Card,
        Words,

        /* Icons */
        ArrowDownIcon,
        ArrowUpIcon,
        MinusIcon,
        PlusIcon,
    },
    props: {
        type: {
            type: String,
            default: 'primary',
            enum: ['primary', 'secondary'],
            validator: value => {
                return ['primary', 'secondary'].includes(value);
            },
        },
        hasContent: {
            type: Boolean,
            default: true,
        },
        spaced: {
            type: Boolean,
            default: false,
        },
        openOnInit: {
            type: Boolean,
            default: true,
        },
        showEmptyAccordion: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            id: uuid.v4(),
            isOpen: this.openOnInit,
        };
    },
    computed: {
        isPrimary() {
            return this.type === 'primary';
        },
        isSecondary() {
            return this.type === 'secondary';
        },
        hasDefaultSlotContent() {
            return !!this.$slots.default;
        },
        hideEmptyAccordion() {
            return !this.hasContent && this.showEmptyAccordion === false;
        },
    },

    updated() {
        this.$emit('accordion-update', { isOpen: this.isOpen });
    },

    methods: {
        onClick() {
            // toggle state
            this.isOpen = !this.isOpen;

            this.$emit('accordion-click', { isOpen: this.isOpen });
        },
    },
};
</script>

<style lang="scss" scoped src="./Accordion.scss" />

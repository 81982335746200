<template>
    <div>
        <p class="font-copy-md-strong m-0 mb-2 lg:font-copy-lg-strong" v-html="$t('pages.homeV2.logisticsTitle')" />
        <p class="font-copy-sm mb-[12px] lg:font-copy-md lg:mb-6">
            {{ $t('pages.homeV2.logisticsDescription') }}
        </p>

        <template v-if="currentTripsCarrier">
            <Card
                v-for="transport in currentTripsCarrier.items"
                :key="transport.id"
                spaceless-x
                clickable
                @click="goToCarrierDriverTransportView(transport.id)"
            >
                <TransportListBlock :transport="transport" context="carrier" :is-mobile="!$root.isDesktop" />
            </Card>
        </template>

        <template v-if="currentTripsClient">
            <Card
                v-for="transport in currentTripsClient.items"
                :key="transport.id"
                spaceless-x
                clickable
                @click="goToClientTransportView(transport.id)"
            >
                <TransportListBlock :transport="transport" context="client" :is-mobile="!$root.isDesktop" />
            </Card>
        </template>

        <EmptyState
            v-if="showEmptyPlaceholder"
            icon="TripsEmptyIcon"
            :text="$t('pages.homeV2.noActiveTrips')"
            class="mb-4"
        />

        <div class="grid gap-x-[20px] gap-y-[20px] lg:grid-cols-[1fr,1fr,1fr,1fr]">
            <StatusCard
                v-if="transportHubCount"
                icon="TransportHubIcon"
                :title="$t('pages.homeV2.transportHubCardTitle')"
                :description="$tc('pages.homeV2.transportHubDescriptionTitle', transportHubCount)"
                :count="transportHubCount"
                @click="goToLogisticsTransportHub()"
            />
            <StatusCard
                v-if="assignedTransportCount"
                icon="TransportIcon"
                :title="$t('pages.homeV2.assignedTransportCard')"
                :description="$tc('pages.homeV2.assignedTransportDescription', assignedTransportCount)"
                :count="assignedTransportCount"
                @click="goToLogisticsTransportList()"
            />
            <StatusCard
                v-if="plannedTransportCount"
                icon="MyTripsIcon"
                data-test="assigned-transport-button"
                :title="$t('pages.homeV2.myTripsCardTitle')"
                :description="$tc('pages.homeV2.myTripsCardDescription', plannedTransportCount)"
                :count="plannedTransportCount"
                @click="goToLogisticsMyTrips()"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _find from 'lodash/find';
import _get from 'lodash/get';

import TransportListApi from '@/services/Api/TransportList';
import SearchFilterApi from '@/services/Api/SearchFilter';

import { createFilterFromSearch } from '@/services/utils/search-filter';
import { assembleQueryFilter } from '@/plugins/mixins/persistentFiltersMixin';
import { TRANSPORT_TYPE } from '@/constants/transportTypes';

import Card from '@/components/Layout/Card';
import EmptyState from '@/pages/Home/components/EmptyState';
import StatusCard from '@/pages/Home/components/StatusCard';
import TransportListBlock from '@/components/Transport/TransportListBlock';

const carrierTransportListApi = new TransportListApi('carrier');
const carrierDriverTransportListApi = new TransportListApi('carrierDriver');
const clientTransportListApi = new TransportListApi('client');

const POLLING_INTERVAL = 60000;

export default {
    name: 'HomeSectionLogistic',
    components: {
        Card,
        EmptyState,
        StatusCard,
        TransportListBlock,
    },
    data() {
        return {
            currentTripsCarrier: null,
            currentTripsCarrierCancelSource: null,
            currentTripsClient: null,
            currentTripsClientCancelSource: null,
            transportHubCount: null,
            transportHubCountCancelSource: null,
            assignedTransportCount: null,
            assignedTransportCountCancelSource: null,
            plannedTransportCount: null,
            plannedTransportCountCancelSource: null,
            savedTransportHubSearches: null,
        };
    },
    computed: {
        ...mapGetters('user', { currentUserId: 'id' }),

        showEmptyPlaceholder() {
            const { currentTripsCarrier, currentTripsClient } = this;

            const isEmptyCarrier = currentTripsCarrier === null ? true : currentTripsCarrier.count === 0;
            const isEmptyClient = currentTripsClient === null ? true : currentTripsClient.count === 0;

            return isEmptyCarrier && isEmptyClient;
        },
    },
    mounted() {
        this.refreshData();
        this.$gracefulInterval(this.refreshData.bind(this), POLLING_INTERVAL);
    },
    beforeDestroy() {
        this.currentTripsCarrierCancelSource && this.currentTripsCarrierCancelSource.cancel('canceled-previous-call');
        this.currentTripsClientCancelSource && this.currentTripsClientCancelSource.cancel('canceled-previous-call');
        this.transportHubCountCancelSource && this.transportHubCountCancelSource.cancel('canceled-previous-call');
        this.assignedTransportCountCancelSource &&
            this.assignedTransportCountCancelSource.cancel('canceled-previous-call');
        this.plannedTransportCountCancelSource &&
            this.plannedTransportCountCancelSource.cancel('canceled-previous-call');
        this.assignedShipmentTransportCountCancelSource &&
            this.assignedShipmentTransportCountCancelSource.cancel('canceled-previous-call');
        this.assignedShipmentTransportCountCancelSource = carrierTransportListApi.createCancelTokenSource();
    },
    methods: {
        async refreshData() {
            if (this.$can('listActiveTrips')) {
                this.refreshCurrentTripsCarrier();
                this.refreshPlannedTransportCount();
            }
            if (this.$can('listActivePickupTrips')) {
                this.refreshCurrentTripsClient();
            }
            if (this.$can('haveTransportHub')) {
                this.refreshTransportHubCount();
                this.refreshAssignedTransportCount();
            }
        },

        async refreshCurrentTripsCarrier() {
            this.currentTripsCarrierCancelSource &&
                this.currentTripsCarrierCancelSource.cancel('canceled-previous-call');
            this.currentTripsCarrierCancelSource = carrierDriverTransportListApi.createCancelTokenSource();

            try {
                this.currentTripsCarrier = await carrierDriverTransportListApi.filter(
                    {
                        status: [
                            'started',
                            'checked_in',
                            'checked_in_loading',
                            'loaded',
                            'ready_to_deliver',
                            'in_transit',
                            'destination_waiting',
                            'unloading_delayed',
                        ],
                    },
                    null,
                    null,
                    this.currentTripsCarrierCancelSource
                );
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }
        },

        async refreshCurrentTripsClient() {
            this.currentTripsClientCancelSource && this.currentTripsClientCancelSource.cancel('canceled-previous-call');
            this.currentTripsClientCancelSource = clientTransportListApi.createCancelTokenSource();

            try {
                this.currentTripsClient = await clientTransportListApi.filter(
                    {
                        status: ['started', 'checked_in', 'pickup_loaded'],
                        type: TRANSPORT_TYPE.PICKUP,
                        driver: this.currentUserId,
                    },
                    null,
                    null,
                    this.currentTripsClientCancelSource
                );
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }
        },

        async refreshTransportHubCount() {
            this.transportHubCountCancelSource && this.transportHubCountCancelSource.cancel('canceled-previous-call');
            this.transportHubCountCancelSource = carrierTransportListApi.createCancelTokenSource();

            try {
                if (!this.savedTransportHubSearches) {
                    this.savedTransportHubSearches = await SearchFilterApi.getAll();
                }

                const search = _find(this.savedTransportHubSearches, { default: true });
                const filter = {
                    ...createFilterFromSearch(search),
                    status: ['new'],
                    countOnly: true,
                };

                const response = await carrierTransportListApi.filter(
                    filter,
                    null,
                    null,
                    this.transportHubCountCancelSource
                );

                this.transportHubCount = _get(response, 'count', 0);
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }
        },

        async refreshAssignedTransportCount() {
            this.assignedTransportCountCancelSource &&
                this.assignedTransportCountCancelSource.cancel('canceled-previous-call');
            this.assignedTransportCountCancelSource = carrierTransportListApi.createCancelTokenSource();

            this.assignedShipmentTransportCountCancelSource &&
                this.assignedShipmentTransportCountCancelSource.cancel('canceled-previous-call');
            this.assignedShipmentTransportCountCancelSource = carrierTransportListApi.createCancelTokenSource();

            try {
                const assignedDeliveryResponse = await carrierTransportListApi.filter(
                    {
                        status: ['assigned'],
                        type: [TRANSPORT_TYPE.DELIVERY],
                        countOnly: true,
                    },
                    null,
                    null,
                    this.assignedTransportCountCancelSource
                );

                const assignedShipmentResponse = await carrierTransportListApi.filter(
                    {
                        status: ['assigned'],
                        type: [TRANSPORT_TYPE.SHIPMENT],
                        countOnly: true,
                    },
                    null,
                    null,
                    this.assignedShipmentTransportCountCancelSource
                );

                const assignedDisposalResponse = await carrierTransportListApi.filter(
                    {
                        status: ['assigned'],
                        type: [TRANSPORT_TYPE.DISPOSAL],
                        countOnly: true,
                    },
                    null,
                    null,
                    this.assignedTransportCountCancelSource
                );

                this.assignedTransportCount =
                    _get(assignedDeliveryResponse, 'count', 0) +
                    _get(assignedShipmentResponse, 'count', 0) +
                    _get(assignedDisposalResponse, 'count', 0);
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }
        },

        async refreshPlannedTransportCount() {
            this.plannedTransportCountCancelSource &&
                this.plannedTransportCountCancelSource.cancel('canceled-previous-call');
            this.plannedTransportCountCancelSource = carrierDriverTransportListApi.createCancelTokenSource();

            try {
                const response = await carrierDriverTransportListApi.filter(
                    {
                        status: ['planned'],
                        countOnly: true,
                    },
                    null,
                    null,
                    this.plannedTransportCountCancelSource
                );

                this.plannedTransportCount = _get(response, 'count', 0);
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }
        },

        goToCarrierDriverTransportView(transportId) {
            this.$router
                .push({
                    name: this.$root.findRouteName('logistics__active-trips__transport-view'),
                    params: { transportId: transportId },
                })
                .catch(() => {});
        },

        goToClientTransportView(transportId) {
            this.$router
                .push({
                    name: this.$root.findRouteName('order__transport-list__transport-view'),
                    params: { transportId: transportId },
                })
                .catch(() => {});
        },

        goToLogisticsTransportHub() {
            this.$router.push({ name: this.$root.findRouteName('logistics__transport-hub') }).catch(() => {});
        },

        goToLogisticsTransportList() {
            this.$router
                .push({
                    name: this.$root.findRouteName('logistics__transport-list'),
                    query: {
                        transportForced: assembleQueryFilter({
                            perPage: 50,
                            own: true,
                            status: ['assigned'],
                        }),
                    },
                })
                .catch(() => {});
        },

        goToLogisticsMyTrips() {
            this.$router.push({ name: this.$root.findRouteName('logistics__active-trips') }).catch(() => {});
        },
    },
};
</script>

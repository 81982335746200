<template>
    <DialogModalBase>
        <template #content>
            <Words line-height-large large bold block spaced-bottom>{{
                $t('components.dialogModal.locationTypeConfirmDisposal.headline')
            }}</Words>
            <Words line-height-small block :spaced-bottom-large="$root.isDesktop">{{
                $t('components.dialogModal.locationTypeConfirmDisposal.message')
            }}</Words>
        </template>
        <template #footer>
            <div class="flex flex-col justify-end md:flex-row">
                <SfButton
                    type="secondary"
                    :size="`${$root.isDesktop ? 'sm' : 'md'}`"
                    :class="{ 'border-t border-light-gray-400': !$root.isDesktop }"
                    @click="$emit('secondaryClick', { methodName: 'defaultLocationType' })"
                >
                    {{ $t('components.dialogModal.locationTypeConfirmDisposal.secondaryButtonText') }}
                </SfButton>
                <SfButton
                    :size="`${$root.isDesktop ? 'sm' : 'md'}`"
                    :class="{ 'ml-4': $root.isDesktop }"
                    @click="$emit('primaryClick', { methodName: 'defaultLocationType' })"
                >
                    {{ $t('components.dialogModal.locationTypeConfirmDisposal.primaryButtonText') }}
                </SfButton>
            </div>
        </template>
    </DialogModalBase>
</template>
<script>
import { SfButton } from '@schuettflix/vue-components';
import DialogModalBase from '@/_components/DialogModal/DialogModalBase';
import Words from '@/components/Typography/Words';

export default {
    name: 'LocationTypeConfirmDisposal',
    components: {
        SfButton,
        DialogModalBase,
        Words,
    },
};
</script>

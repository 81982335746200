<template>
    <LayoutPage screen-name="seller-wasteproducts-list" class="products-list fixed-bottom-space">
        <div slot="pageTitle">
            {{ activePageTitle }}
            <PopUp variant="fixed-width-400">
                <InfoIcon :height="18" :width="18" class="icon--inline product-list__popup-icon" />
                <Words slot="content" block class="product-list__popup" small>
                    {{ activePageDescription }}
                </Words>
            </PopUp>
        </div>

        <LoadingSpinner v-if="isLoadingFactories && !hasFetchedFactories" block dark />

        <template v-if="hasFetchedFactories">
            <Card class="product-list__factory-card" spaceless-x>
                <div class="product-list__factory-card-content">
                    <FacilitiesDropdown
                        :options="facilitiesDropdownSelectOptions"
                        :labels="facilitiesDropdownLabels"
                        :is-open="facilitiesDropdownLocationOpenState"
                        :keys="facilitiesDropdownKeys"
                        :initial-selected-key="selectedFactoryId"
                        show-chevron
                        @input="setSelectedFactoryId"
                    />
                    <div>
                        <Words style="margin-right: 20px" white>
                            {{
                                $t('pages.products.productsList.factoryNumber', {
                                    factoryNumber: selectedFactory.factoryNumber,
                                })
                            }}
                        </Words>
                        <Words white>
                            <FormattedAddress :address="selectedFactory.address" />
                        </Words>
                    </div>
                    <ToggleSwitchField
                        dark-mode
                        :disabled="isUpdatingFactory"
                        :value="selectedFactory.open"
                        @input="switchFactoryStatus(!selectedFactory.open)"
                    >
                        <Words bold white>
                            {{
                                selectedFactory.open
                                    ? $t('components.factoryForm.factoryOpen')
                                    : $t('components.factoryForm.factoryClose')
                            }}
                        </Words>
                    </ToggleSwitchField>
                </div>
            </Card>

            <div class="product-list__tab-container">
                <RoundedTabNavigation
                    :tabs="availableTabs"
                    :value="PRODUCT_TYPES.waste"
                    class="products-list__tab-navigaton"
                    @input="updateTabNavigation"
                />
            </div>
        </template>

        <!-- FILTER BAR -->
        <FilterBox
            v-if="hasWasteOrMaterialProduct"
            v-show="hasWasteOrMaterialProduct"
            v-model="filter"
            :default-filter="filter"
            :endpoint="dataEndpoint"
            class="transport-hub__inline-filter"
            inline-mode
            @update="refreshProductList()"
        >
            <template #default="filterScope">
                <GridRow :count="4" spacing="none">
                    <TextField
                        v-model="filterScope.filter.search"
                        :label="getSearchBoxPlaceholderText"
                        class="span-2"
                    />
                    <SelectBox
                        v-model="filterScope.filter.isAvailable"
                        :label="$t('pages.products.productsList.filter.availabilityLabel')"
                    >
                        <option value>
                            {{ $t('pages.products.productsList.filter.availabilityDefault') }}
                        </option>
                        <option value="1">
                            {{ $t('pages.products.productsList.availability.available') }}
                        </option>
                        <option value="0">
                            {{ $t('pages.products.productsList.availability.unavailable') }}
                        </option>
                    </SelectBox>
                    <SelectBox
                        v-model="filterScope.filter.isActive"
                        :label="$t('pages.products.productsList.filter.statusLabel')"
                    >
                        <option value>
                            {{ $t('pages.products.productsList.filter.statusDefault') }}
                        </option>
                        <option value="1">
                            {{ $t('pages.products.productsList.status.active') }}
                        </option>
                        <option value="0">
                            {{ $t('pages.products.productsList.status.inactive') }}
                        </option>
                    </SelectBox>
                </GridRow>
            </template>
        </FilterBox>

        <span v-if="noFactoryAvailable" class="font-copy-md-strong text-center">
            {{ $t('pages.products.productsList.noFactoriesAvailable') }}
        </span>

        <DisposalTypeContent v-if="!noFactoryAvailable" :factory-id="selectedFactoryId" :filters="filter" />

        <Flyout
            :active="editOpen"
            :headline="selectedProduct && revertLocalizedValue(selectedProduct.productName)"
            :subline="selectedProduct && lastChanged(selectedProduct)"
            size="small"
            @closed="(editOpen = false) && (selectedProduct = null)"
        >
            <ProductForm
                :active-list-type="PRODUCT_TYPES.waste"
                :factory-id="selectedFactoryId"
                :product="selectedProduct"
                @close="editOpen = false"
                @updated="refreshProductList()"
            />
        </Flyout>
    </LayoutPage>
</template>

<script>
import { mapState } from 'vuex';
import _findIndex from 'lodash/findIndex';
import _isNumber from 'lodash/isNumber';
import _orderBy from 'lodash/orderBy';
import _cloneDeep from 'lodash/cloneDeep';
import { ensureJSTimestamp } from '@/services/utils/date';
import { revertLocalizedValue } from '@/services/utils/localization';
import FactoryApi from '@/services/Api/Factory';
import { navigationFailure } from '@/services/utils/router';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import SupplierProductApi from '@/services/Api/Supplier/Product';

import Card from '@/components/Layout/Card';
import DisposalTypeContent from '@/pages/SupplierProducts/components/DisposalTypeContent/DisposalTypeContent.vue';
import FacilitiesDropdown from '@/_components/FacilitiesDropdown/FacilitiesDropdown';
import FilterBox from '@/components/Filter/FilterBox';
import Flyout from '@/components/Layout/Flyout';
import FormattedAddress from '@/components/FormattedAddress';
import GridRow from '@/components/Layout/GridRow';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import PopUp from '@/_components/PopUp/PopUp';
import ProductForm from './components/ProductForm/ProductForm';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation';
import SelectBox from '@/components/Form/SelectBox.v2';
import TextField from '@/components/Form/TextField.v2';
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import { LANDFILL_CODE_TO_NAME_MAPPING, USAGES } from '@/constants/disposal';

import Words from '@/components/Typography/Words';
import InfoIcon from '@/assets/icons/regular/info.svg';

const PRODUCT_TYPES = {
    waste: 'disposal_type',
    material: 'construction_type',
};

export default {
    name: 'DisposalListPage',
    components: {
        /* Components */
        Card,
        DisposalTypeContent,
        FacilitiesDropdown,
        FormattedAddress,
        FilterBox,
        Flyout,
        GridRow,
        LayoutPage,
        LoadingSpinner,
        PopUp,
        ProductForm,
        RoundedTabNavigation,
        SelectBox,
        TextField,
        ToggleSwitchField,
        Words,

        /* Icons */
        InfoIcon,
    },

    mixins: [persistentFiltersMixin, statefulMixin],

    data() {
        return {
            cancelSource: null,
            dataEndpoint: SupplierProductApi,
            editOpen: false,
            factories: null,
            isActive: null,
            isLoadingFactories: null,
            isLoadingProducts: false,
            isUpdatingFactory: false,
            noFactoryAvailable: false,
            products: null,
            selectedFactoryId: null,
            selectedProduct: null,
            filter: this.assembleFilter('product', {
                page: 1,
                perPage: 500,
            }),
            defaultFilter: {
                page: 1,
                perPage: 500,
            },
            PRODUCT_TYPES,
        };
    },

    computed: {
        activePageTitle() {
            let navigationTitleKey = 'management-products';
            if (this.$can('listSupplierProducts') || this.$can('viewSupplierWasteProducts')) {
                if (this.$can('listSupplierProducts') && !this.$can('viewSupplierWasteProducts')) {
                    navigationTitleKey = 'management-products-supplier';
                }
                if (!this.$can('listSupplierProducts') && this.$can('viewSupplierWasteProducts')) {
                    navigationTitleKey = 'management-products-waste';
                }
            }

            return this.$t(`components.navigation.labels.${navigationTitleKey}`);
        },
        activePageDescription() {
            let pageDescription = this.$t('pages.products.description');
            if (this.$can('listSupplierProducts') && !this.$can('listSupplierWasteProducts')) {
                pageDescription = this.$t('pages.products.descriptionSupplier');
            }
            if (!this.$can('listSupplierProducts') && this.$can('listSupplierWasteProducts')) {
                pageDescription = this.$t('pages.products.descriptionWaste');
            }
            return pageDescription;
        },
        hasFetchedFactories() {
            return this.factories && this.factories.length > 0;
        },
        availableTabs() {
            const usages = this.factories?.find(factory => factory.id === this.selectedFactoryId)?.usages;
            let tabLabel = '';
            const availableTabs = {};
            usages?.forEach(usage => {
                tabLabel = this.returnTabLabel(usage);
                if (usage === 'selling') {
                    availableTabs.construction_type = tabLabel;
                } else {
                    availableTabs.disposal_type = tabLabel;
                }
            });
            //this sorting is required to always show Baustoffe tab first in the roundedTabNav component
            const sortedAvailableTabs = {};
            Object.keys(availableTabs)
                .sort()
                .forEach(key => {
                    sortedAvailableTabs[key] = availableTabs[key];
                });
            return sortedAvailableTabs;
        },
        getSearchBoxPlaceholderText() {
            return this.$t('pages.products.productsList.waste.searchLabel');
        },
        selectedFactory() {
            if (!this.factories) return null;
            return this.factories[_findIndex(this.factories, { id: Number(this.selectedFactoryId) })];
        },
        facilitiesDropdownSelectOptions() {
            const sortedFactories = this.orderFactoriesByOpenStateAndName();
            return sortedFactories.map(factory => factory.name);
        },
        facilitiesDropdownLabels() {
            const sortedFactories = this.orderFactoriesByOpenStateAndName();
            return sortedFactories.map(factory => {
                const label = [];
                if (this.isMaterialFactory(factory)) label.push(this.$t('pages.organization.factoryList.type.selling'));
                if (this.isWasteFactory(factory)) {
                    if (!('disposalSettings' in factory) || !('factoryType' in factory.disposalSettings)) return;
                    let landfillClassificationLabel = this.$t(
                        `pages.organization.factoryList.type.${factory.disposalSettings.factoryType}`
                    );
                    if (this.isLandfillFactory(factory)) {
                        landfillClassificationLabel +=
                            ' ' + LANDFILL_CODE_TO_NAME_MAPPING[factory.disposalSettings.landfillClass];
                    }
                    label.push(landfillClassificationLabel);
                }
                return label.join(', ');
            });
        },
        facilitiesDropdownLocationOpenState() {
            const sortedFactories = this.orderFactoriesByOpenStateAndName();
            return sortedFactories.map(factory => factory.open);
        },
        facilitiesDropdownKeys() {
            const sortedFactories = this.orderFactoriesByOpenStateAndName();
            return sortedFactories.map(factory => factory.id);
        },
        hasWasteOrMaterialProduct() {
            return this.products || (this.categories && this.categories.length > 0);
        },
        ...mapState('waste', ['categories', 'factoryId']),
    },
    created() {
        this.getUrlFilters(true);
    },
    async mounted() {
        await this.refreshFactoryList(true);
        await this.refreshProductList(true);
    },
    methods: {
        revertLocalizedValue,
        _isNumber,

        async getUrlFilters(init = false) {
            if (init) {
                if (this.isQueryFilterSet('factory')) {
                    this.selectedFactoryId = Number(this.assembleFilter('factory', null));
                }

                if (this.isQueryFilterSet('product')) {
                    this.filter = this.assembleFilter('product', this.defaultFilter);
                }
            }
        },

        returnTabLabel(usage) {
            return usage === 'selling' ? this.$t('pages.products.construction') : this.$t('pages.products.titleWaste');
        },

        async setSelectedFactoryId(factoryId) {
            /*
             * Once factories are loaded from the api, the facilities dropdown is rendered.
             * On mount, this component selects the first of its options (if no default value was provided),
             * which in turn calls this function via events with isInitial == true
             */

            const usages = this.factories.find(factory => factory.id === factoryId)?.usages || [];

            if (!usages.includes(USAGES.DISPOSAL)) {
                return this.$router.push({ name: 'management__supplier-products', query: { factoryId: factoryId } });
            }

            if (factoryId !== this.factoryId) {
                this.$store.commit('waste/SET_FACTORY_ID', factoryId);
            }
            this.selectedFactoryId = factoryId;

            await this.refreshProductList();
        },

        updateTabNavigation(tab) {
            if (tab === PRODUCT_TYPES.material) {
                return this.$router.push({
                    name: 'management__supplier-products',
                    query: {
                        factoryId: this.selectedFactoryId,
                    },
                });
            }
        },

        async refreshProductList() {
            if (!this.$can('listSupplierProducts')) return;
            this.$router
                .push({
                    path: this.$route.path,
                    query: {
                        product: this.assembleQueryFilter(this.filter),
                        productType: this.assembleQueryFilter(PRODUCT_TYPES.waste),
                        factory: this.assembleQueryFilter(this.selectedFactoryId),
                    },
                })
                .catch(() => navigationFailure);
        },

        editProduct(product) {
            this.selectedProduct = _cloneDeep(product);
            this.editOpen = true;
        },

        lastChanged(product) {
            if (!product.updated) {
                return '';
            }

            const timeValue = ensureJSTimestamp(product.updated);
            const date = this.$d(timeValue, 'short');
            const time = this.$d(timeValue, 'time');

            return `${this.$t('pages.products.productsForm.lastChanged')} ${date} ${this.$t('timeSuffix', {
                time: time,
            })}`;
        },

        async refreshFactoryList(isInitial) {
            this.isLoadingFactories = true;

            try {
                this.factories = await FactoryApi.getAll();

                if (isInitial) {
                    if (this.factories.length === 0) {
                        this.noFactoryAvailable = true;
                        this.isLoadingFactories = false;
                        return;
                    }

                    if (this.selectedFactoryId === null) await this.setSelectedFactoryId(this.factories[0].id);
                }
            } catch (err) {
                this.$logger().log(err);
            }

            this.isLoadingFactories = false;
        },

        async switchFactoryStatus(status) {
            if (this.isUpdatingFactory) {
                return;
            }

            this.isUpdatingFactory = true;
            try {
                const latestFactoryObject = await FactoryApi.getOneById(this.selectedFactoryId);
                await FactoryApi.save({ ...latestFactoryObject, open: status });
                this.refreshFactoryList();
            } catch (err) {
                this.$logger().log(err);
            }
            this.isUpdatingFactory = false;
        },
        isMaterialFactory(factory) {
            if (!factory) {
                return false;
            }
            return factory.usages.includes('selling');
        },
        isWasteFactory(factory) {
            if (!factory) {
                return false;
            }
            return factory.usages.includes('disposal');
        },
        isLandfillFactory(factory) {
            return 'landfillClass' in factory.disposalSettings;
        },
        orderFactoriesByOpenStateAndName() {
            return _orderBy(this.factories, ['open', 'name'], ['desc', 'asc']);
        },
    },
};
</script>

<style lang="scss" scoped>
.product-list__tab-container {
    display: grid;
    grid-template-columns: 1fr auto;
}

.products-list__categories {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
        padding: 0;
        margin: 0;
        width: 100%;
    }
}

.products-list__thumbnail-cell {
    display: flex;

    .product-list__thumbnail-cell-text {
        max-width: 400px;
    }
}

.product-list__thumbnail-cell-text {
    display: inline-block;
    vertical-align: middle;
}

.product-list__popup-icon {
    margin-left: 20px;
}

.product-list__factory-card {
    margin-top: 20px;
    margin-bottom: 50px;
    background-color: $color-darkGrey;
}

.product-list__factory-card-content {
    display: grid;
    grid-template-columns: 1fr 2fr 220px;
    grid-column-gap: 20px;
    align-items: center;
}

.product-list__sorting-box {
    margin-top: 15px;
    margin-left: auto;
}

.product-list__title {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 15px;
}

.product-list__copy-factory-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
</style>

import { CHECKOUT_BASE_TYPE_SHIPMENT } from '@/constants/checkoutTypes';
import _cloneDeep from 'lodash/cloneDeep';
export const usePaymentTerms = () => {
    const getPaymentTerms = (data, checkoutType) => {
        const orgPaymentTerms = [];
        if (data.carrierInfo?.id) {
            orgPaymentTerms.push({
                organizationId: data?.carrierInfo?.id,
                organizationType: 'carrier',
                paymentTermId: null,
            });
        }
        if (checkoutType !== CHECKOUT_BASE_TYPE_SHIPMENT) {
            if (data.disposer?.id) {
                orgPaymentTerms.push({
                    organizationId: data.disposer.id,
                    organizationType: 'supplier',
                    paymentTermId: null,
                });
            }
            if (data.supplierInfo?.supplier?.id) {
                orgPaymentTerms.push({
                    organizationId: data.supplierInfo.supplier.id,
                    organizationType: 'supplier',
                    paymentTermId: null,
                });
            }
        }
        return orgPaymentTerms;
    };
    const preparePaymentTermDataForStore = (newPaymentTerm, paymentTermsFromStore) => {
        let paymentTerms = _cloneDeep(paymentTermsFromStore) || [];
        if ('organizationType' in newPaymentTerm &&
            paymentTerms.find(pt => pt.organizationType === newPaymentTerm.organizationType) === undefined) {
            paymentTerms.push(newPaymentTerm);
            //the api returns an array, which we use to set the state
        }
        else if (Array.isArray(newPaymentTerm)) {
            paymentTerms = newPaymentTerm;
            //if the updated term already exists in the array, we need to update it
        }
        else {
            for (let i = 0; i < paymentTerms.length; i++) {
                if (paymentTerms[i].organizationType === newPaymentTerm.organizationType &&
                    paymentTerms[i].paymentTermId !== newPaymentTerm.paymentTermId) {
                    paymentTerms[i].paymentTermId = newPaymentTerm.paymentTermId;
                    break;
                }
            }
        }
        return paymentTerms;
    };
    return {
        getPaymentTerms,
        preparePaymentTermDataForStore,
        paymentTermsFromApi: null,
    };
};

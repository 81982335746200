<template>
    <div
        v-if="showComponent()"
        class="font-copy-md inline-flex w-full flex-wrap gap-x-4 gap-y-1 lg:flex-nowrap"
        :class="{ 'lg:whitespace-nowrap': !doNotTruncateDescription }"
    >
        <template v-if="transport.freightDescription || (!hideFreightType && transport.freightType)">
            <span
                class="mt-[2px]"
                :class="{ truncate: !doNotTruncateDescription, 'whitespace-normal': doNotTruncateDescription }"
            >
                {{ transport.freightDescription }}
            </span>
            <SfTag v-if="!hideFreightType && transport.freightType" outline>
                {{ $t(`productTypes.tags.${transport.freightType}`) }}
            </SfTag>
        </template>
        <template v-else-if="!isShipment">
            <span :class="{ 'font-bold': highlight }">{{ transport.qty | tons }}</span>
            <span v-if="transport.product.wasteCode">
                {{ transport.product.wasteCode }}<template v-if="transport.product.isDangerous">*</template>
            </span>
            <span :class="{ truncate: !doNotTruncateDescription, 'whitespace-normal': doNotTruncateDescription }">
                {{ revertLocalizedValue(transport.product.productName) }}
            </span>
        </template>
        <slot />
    </div>
</template>

<script>
import { revertLocalizedValue } from '@/services/utils/localization';
import { SfTag } from '@schuettflix/vue-components';

import { TRANSPORT_TYPE } from '@/constants/transportTypes';

/**
 * !!! ATTENTION !!!
 * This component and all of it's children can not have computed
 * properties since they introduce a performance issues on unmounting
 */
export default {
    name: 'TransportListBlockFreightDescription',
    components: {
        SfTag,
    },
    props: {
        transport: {
            type: Object,
            required: true,
        },
        highlight: {
            type: Boolean,
            default: false,
        },
        hideFreightType: {
            type: Boolean,
            default: false,
        },
        doNotTruncateDescription: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isShipment: this.transport.type === TRANSPORT_TYPE.SHIPMENT,
            showComponent: () => {
                if (this.isShipment) {
                    return (
                        !!this.transport.freightDescription || (!this.hideFreightType && !!this.transport.freightType)
                    );
                }

                return (
                    !!this.transport.qty ||
                    !!this.transport.product?.wasteCode ||
                    this.transport.product?.productName.length
                );
            },
        };
    },
    methods: {
        revertLocalizedValue,
    },
};
</script>

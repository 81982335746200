<template>
    <div
        v-if="$slots.default"
        class="flex items-center justify-center"
        :class="{
            'left-2/4 right-2/4': isFlyout,
        }"
        data-test="button-group-wrapper"
    >
        <div
            class="button-group-wrapper__items relative flex w-full items-center px-4 py-4"
            :class="{
                'justify-end': itemsRight,
                'justify-start': itemsLeft,
            }"
            data-test="button-group-wrapper-items"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ButtonGroupWrapper',
    props: {
        isFlyout: {
            type: Boolean,
            default: false,
        },
        itemsRight: {
            type: Boolean,
            default: false,
        },
        itemsLeft: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.button-group-wrapper {
    &__items {
        background-color: $color-littleDarkerThanLightMediumGrey;
        border-top: 1px solid #d2d4d5;

        .factory-draft-flyout__buttons & {
            background-color: $color-lightMediumGrey;
        }

        &--thin {
            max-width: 766px;
        }

        button {
            margin: 0 8px;
            width: 100%;

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }

            @media screen and (min-width: $layout-desktop-min) {
                width: auto;
            }
        }
    }
}
</style>

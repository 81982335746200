import { useVueEmit } from '@/reactBridge/EmitVueEventProvider';
import { Button, Select } from '@schuettflix/react-components';
import { useIsMutating, useQuery, useQueryClient } from '@tanstack/react-query';
import { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CertificateForm from './components/CertificateForm';
import FlyoutPageContent from './components/FlyoutPageContent';
import HeaderBar from './components/HeaderBar';
import SfSysCloseIcon from './icons/SfSysCloseIcon';
import CertificateApi, { certificateListCacheKey, certificateMutationKey, certificateTypesCacheKey, } from '@/services/Api/Certificate';
import ComboboxLoading from './components/ComboboxLoading';
import { useAppDimension } from '@/reactBridge/useAppDimension';
import clsx from 'clsx';
import { DOCUMENT_TYPES } from '@/constants/documentTypes';
import { addYears, format } from 'date-fns';
export default function CertificateManagementAddFlyoutPage({ organizationId }) {
    const { t } = useTranslation();
    const { emitVueEvent } = useVueEmit();
    const [certificateType, setCertificateType] = useState(null);
    const queryClient = useQueryClient();
    const isCertificateMutating = useIsMutating(certificateMutationKey());
    const formId = useId();
    const { isDesktop } = useAppDimension();
    const { data: certificateTypes, isLoading: isCertificateTypesLoading } = useQuery(certificateTypesCacheKey(), () => CertificateApi.getCertificateTypes());
    const handleCertTypeChange = (option) => {
        setCertificateType(!option ? null : certificateTypes?.find(item => item.id === option) ?? null);
    };
    const certTypeOptions = certificateTypes?.map(item => ({
        label: item.documentType.label,
        value: item.id,
    })) ?? [];
    const certificateSavedHandler = () => {
        emitVueEvent('saveCertificate');
        queryClient.invalidateQueries(certificateListCacheKey(organizationId));
    };
    const getDefaultValidityDate = function (certificate) {
        const allowedDocumentTypesForDefaultDate = [
            DOCUMENT_TYPES.CERTIFICATE_SUBCONTRACTOR,
            DOCUMENT_TYPES.CERTIFICATE_FACTORY_TRAFFIC,
        ];
        if (allowedDocumentTypesForDefaultDate.includes(certificate?.documentType?.code)) {
            const currentDate = new Date();
            const dateOneYearFromNow = addYears(currentDate, 1);
            return format(dateOneYearFromNow, 'YYYY-MM-DD');
        }
        return null;
    };
    const header = (<HeaderBar headline={t('pages.certificateManagement.add.title')} action={<button onClick={() => emitVueEvent('closeFlyout')}>
                    <SfSysCloseIcon size="sm"/>
                </button>}/>);
    const footer = (<div className={clsx({
            'container-content flex justify-end border-t border-divider bg-surface py-[20px]': isDesktop,
        })}>
            <Button className={clsx({ 'w-full': !isDesktop })} size={isDesktop ? 'sm' : 'md'} label={t('pages.certificateManagement.add.actions.add')} type="submit" form={formId} disabled={isCertificateTypesLoading || !!isCertificateMutating}/>
        </div>);
    return (<FlyoutPageContent header={header} footer={footer}>
            <div className="container-content flex flex-col gap-12 py-6">
                {/* Cert Type */}
                <div>
                    <p className="mb-6">{t('pages.certificateManagement.add.typeSelection.title')}</p>
                    {isCertificateTypesLoading && <ComboboxLoading />}
                    {certificateTypes && (<Select value={certificateType ? certificateType.id : null} label={t('pages.certificateManagement.add.typeSelection.label')} onChange={handleCertTypeChange} className={isCertificateMutating ? 'pointer-events-none brightness-95' : ''} options={certTypeOptions}/>)}
                </div>

                {/* Cert information */}
                {certificateType && (<CertificateForm formId={formId} organizationId={organizationId} onSaved={certificateSavedHandler} defaultDate={getDefaultValidityDate(certificateType)} certificateType={certificateType}/>)}

                <div />
            </div>
        </FlyoutPageContent>);
}

<template>
    <div
        :class="{
            'header-bar--transparent': transparent,
        }"
        class="header-bar"
    >
        <div v-if="$slots.left" class="header-bar__slot header-bar__slot--left">
            <slot name="left" />
        </div>
        <div class="header-bar__slot header-bar__slot--main">
            <div class="header-bar__content">
                <div
                    v-if="$slots.headline"
                    class="header-bar__headline font-copy-md-strong"
                    data-test="header-bar-headline"
                >
                    <slot name="headline" />
                </div>
                <div v-if="$slots.subline" class="header-bar__subline font-copy-sm mt-1 !text-subdued">
                    <slot name="subline" />
                </div>
                <div v-if="$slots.body" class="header-bar__body">
                    <slot name="body" />
                </div>
            </div>
        </div>
        <div v-if="$slots.right" class="header-bar__slot header-bar__slot--right">
            <slot name="right">
                <!-- 1…n HeaderBarItems -->
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderBar',
    props: {
        transparent: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.header-bar {
    display: flex;
    align-items: center;
    height: 80px;
    min-height: 80px;
    width: 100%;
    background-color: $color-white;
    border-bottom: 1px solid $color-mediumGrey;
    color: $color-base;

    @media only screen and (max-width: $layout-desktop-min) {
        padding-left: 10px;
        padding-right: 10px;
    }

    @media only screen and (min-width: $layout-desktop-min) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.header-bar--transparent {
    background-color: transparent;
    border-color: transparent;
}

.header-bar__slot {
    display: flex;
    align-items: center;
}

.header-bar__slot--left {
}

.header-bar__slot--main {
    flex: 1;
    padding-left: 10px;
    padding-right: 10px;
}

.header-bar__slot--right {
}

.header-bar__content {
    width: 100%;
}

.header-bar__headline {
}

.header-bar__subline {
}

.header-bar__headline + .header-bar__subline {
    margin-top: 4px;
}

.header-bar__body {
    width: 100%;
}
</style>

import i18n from '@/i18n';

const stateList = ['BW', 'BY', 'BE', 'BB', 'HB', 'HH', 'HE', 'MV', 'NI', 'NW', 'RP', 'SL', 'SN', 'ST', 'SH', 'TH'];

// maps an internal two letter state abbreviation to a localized full name of a german bundesland
export function getStateName(shortName, fallback = null) {
    const short = shortName?.toUpperCase();
    if (!stateList.includes(short)) {
        return fallback;
    }

    return i18n.t(`stateMapping.states.${short}`);
}

// helper function used to map state names returned by google maps API
export function mapStateShortByName(name) {
    if (!name) {
        return;
    }

    switch (name.toLowerCase()) {
        case 'berlin':
            return 'BE';
        case 'nrw':
            return 'NW';
        case 'nds':
            return 'NI';
        case 'sa':
            return 'ST';
        default:
            return name.toUpperCase();
    }
}

export function getStateMapping() {
    const obj = {};
    stateList.forEach(stateCode => {
        obj[stateCode] = getStateName(stateCode);
    });
    return obj;
}

const context = {
    documentUseSelfBilling: false,
};
export const updateContext = (newContext) => {
    if (context.documentUseSelfBilling === newContext.documentUseSelfBilling)
        return;
    context.documentUseSelfBilling = newContext.documentUseSelfBilling;
};
export const selectCreditNoteDocKey = ({ creditNote, selfBilling }) => {
    return makeSelectCreditNoteDocKey(context)({
        creditNote,
        selfBilling,
    });
};
export const makeSelectCreditNoteDocKey = (context) => ({ creditNote, selfBilling }) => {
    return context?.documentUseSelfBilling ? selfBilling : creditNote;
};

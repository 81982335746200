<template>
    <DialogModalBase>
        <template #content>
            <Words large line-height-large bold block spaced-bottom
                >{{ $t('components.factoryManagement.factoryLocationType.modal.landfillClasses.title') }}
            </Words>
            <Words block bold>
                {{ $t('components.factoryManagement.factoryLocationType.modal.landfillClasses.dkzero.title') }}
            </Words>
            <Words line-height-small block spaced-bottom>
                {{ $t('components.factoryManagement.factoryLocationType.modal.landfillClasses.dkzero.description') }}
            </Words>
            <Words block bold>
                {{ $t('components.factoryManagement.factoryLocationType.modal.landfillClasses.dkone.title') }}
            </Words>
            <Words line-height-small block spaced-bottom>
                {{ $t('components.factoryManagement.factoryLocationType.modal.landfillClasses.dkone.description') }}
            </Words>
            <Words line-height-small block bold>
                {{ $t('components.factoryManagement.factoryLocationType.modal.landfillClasses.dktwo.title') }}
            </Words>
            <Words line-height-small block spaced-bottom>
                {{ $t('components.factoryManagement.factoryLocationType.modal.landfillClasses.dktwo.description') }}
            </Words>
            <Words block bold>
                {{ $t('components.factoryManagement.factoryLocationType.modal.landfillClasses.dkthree.title') }}
            </Words>
            <Words line-height-small block>
                {{ $t('components.factoryManagement.factoryLocationType.modal.landfillClasses.dkthree.description') }}
            </Words>
        </template>
    </DialogModalBase>
</template>
<script>
import DialogModalBase from '@/_components/DialogModal/DialogModalBase';
import Words from '@/components/Typography/Words';

export default {
    name: 'HelpLandFillClass',
    components: {
        DialogModalBase,
        Words,
    },
};
</script>

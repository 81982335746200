import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import Fuse from 'fuse.js';
import { ConstructionProjectStatus } from '@/constructionProjects/api/projects';
import { CancelIllustration } from '../icons/CancelIllustration';
import { ConstructionProjectsList } from '../ConstructionProjectList';
import { constructionProjectListQuery } from '@/constructionProjects/queries';
const EmptySearchResults = () => {
    const { t } = useTranslation();
    return (<div className="flex flex-col items-center justify-center px-4 text-center">
            <CancelIllustration />
            <p className="font-headline-md-strong mt-4">{t('pages.constructionProject.search.empty.title')}</p>
            <p className="font-copy-md mt-2">{t('pages.constructionProject.search.empty.description')}</p>
        </div>);
};
export const ConstructionProjectSearchResults = ({ searchQuery }) => {
    const { t } = useTranslation();
    const query = useQuery({
        ...constructionProjectListQuery,
        suspense: true,
    });
    const fuse = useMemo(() => {
        const searchData = query.data?.constructionProjects.map(project => ({
            original: project,
            name: project.name,
            description: project.description,
            costCenter: project.costCenter,
            address: [project.street, project.addressNumber, project.zip, project.city, project.state]
                .filter(Boolean)
                .join(' '),
            responsibleUserFullName: `${project.responsibleUser.firstName} ${project.responsibleUser.lastName}`,
            teamMemberFullNames: project.teamMembers?.map(member => `${member.firstName} ${member.lastName}`),
        }));
        return new Fuse(searchData ?? [], {
            keys: [
                { name: 'name', weight: 1 },
                { name: 'description', weight: 0.7 },
                { name: 'address', weight: 0.5 },
                { name: 'costCenter', weight: 0.4 },
                { name: 'responsibleUserFullName', weight: 0.3 },
                { name: 'teamMemberFullNames', weight: 0.3 },
            ],
            ignoreLocation: true,
            useExtendedSearch: true,
            threshold: 0.3,
        });
    }, [query.data?.constructionProjects]);
    const searchResults = useMemo(() => {
        const groupedByStatus = {
            [ConstructionProjectStatus.Active]: [],
            [ConstructionProjectStatus.Inactive]: [],
            [ConstructionProjectStatus.Archived]: [],
        };
        if (searchQuery.trim().length === 0) {
            for (const project of query.data?.constructionProjects ?? []) {
                groupedByStatus[project.status].push(project);
            }
        }
        else {
            for (const result of fuse.search(searchQuery)) {
                groupedByStatus[result.item.original.status].push(result.item.original);
            }
        }
        return groupedByStatus;
    }, [fuse, query.data?.constructionProjects, searchQuery]);
    const hasSearchResults = searchResults.ACTIVE.length > 0 || searchResults.INACTIVE.length > 0 || searchResults.ARCHIVED.length > 0;
    return (<div className="flex-1 space-y-8 overflow-auto px-4 py-6">
            {hasSearchResults ? (<>
                    {searchResults.ACTIVE.length > 0 && (<ConstructionProjectsList projects={searchResults.ACTIVE} title={t('pages.constructionProject.activeProjects.title')}/>)}
                    {searchResults.INACTIVE.length > 0 && (<ConstructionProjectsList projects={searchResults.INACTIVE} title={t('pages.constructionProject.inactiveProjects.title')}/>)}
                    {searchResults.ARCHIVED.length > 0 && (<ConstructionProjectsList projects={searchResults.ARCHIVED} title={t('pages.constructionProject.archivedProjects.title')} favoritable={false}/>)}
                </>) : (<EmptySearchResults />)}
        </div>);
};

<template>
    <div :class="`trough-type-attribute-renderer--level-${nestingLevel}`" class="trough-type-attribute-renderer">
        <Words v-if="nestingLevel === 0" block bold spaced-bottom>
            {{ translateAttributeName(attributeOption.value) }}
        </Words>
        <Words v-if="hasAttributeDescription(attributeOption.value)" block spaced-bottom>
            {{ translateAttributeDescription(attributeOption.value) }}
        </Words>
        <GridRow :count="2" margin-less>
            <div v-for="option in attributeOption.options" :key="option.identifier" class="span-2 span-md-1">
                <Card
                    spaceless
                    :levitate-hover="!isOptionActive(option.value)"
                    :class="{ 'trough-type-attribute-renderer__card--active': isOptionActive(option.value) }"
                    class="trough-type-attribute-renderer__card"
                    clickable
                    @click="$emit('change', option)"
                >
                    <div class="trough-type-attribute-renderer__card-inner">
                        <component
                            :is="optionIcon(option.value)"
                            class="trough-type-attribute-renderer__card-icon trough-type-attribute-renderer__card-icon--right"
                        />
                        <Words block bold>{{ translateAttributeName(option.value) }}</Words>
                    </div>
                </Card>
                <UnavailableAlert v-if="isNotPurchasableAttribute(purchasableAttributes, option.value)" />
            </div>
        </GridRow>
    </div>
</template>

<script>
import {
    translateAttributeName,
    hasAttributeDescription,
    translateAttributeDescription,
    getTransformedAttributeKey,
    isNotPurchasableAttribute,
} from '@/services/AttributeManager/utils';

import Card from '@/components/Layout/Card';
import CheckboxCard from '@/_components/CheckboxCard/CheckboxCard';
import GridRow from '@/components/Layout/GridRow';
import RadioButtonCard from '@/_components/RadioButtonCard/RadioButtonCard';
import Words from '@/components/Typography/Words';
import UnavailableAlert from '@/pages/Vehicle/components/UnavailableAlert';

import ThroughTypeThermalIcon from '@/assets/icons/vehicleClassAttributes/trough_type--thermal.svg';
import ThroughTypeStandardIcon from '@/assets/icons/vehicleClassAttributes/trough_type--standard.svg';

export default {
    name: 'TroughTypeAttributeRenderer',
    components: {
        Card,
        GridRow,
        RadioButtonCard,
        CheckboxCard,
        UnavailableAlert,
        Words,

        ThroughTypeStandardIcon,
        ThroughTypeThermalIcon,
    },
    props: {
        scopedValue: {
            type: [Array, String],
            default: null,
        },
        purchasableAttributes: {
            type: Array,
            default: null,
        },
        attributeOption: {
            type: Object,
            default: null,
        },
        nestingLevel: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        translateAttributeName,
        hasAttributeDescription,
        translateAttributeDescription,
        isNotPurchasableAttribute,

        optionIcon(value) {
            switch (getTransformedAttributeKey(value)) {
                case 'trough_type--thermal':
                    return 'ThroughTypeThermalIcon';
                case 'trough_type--standard':
                default:
                    return 'ThroughTypeStandardIcon';
            }
        },

        isOptionActive(value) {
            return this.attributeOption.isMultiple ? this.scopedValue.includes(value) : this.scopedValue === value;
        },
    },
};
</script>

<style scoped lang="scss">
.trough-type-attribute-renderer__card {
    border: 2px solid transparent;
}

.trough-type-attribute-renderer__card--active {
    border-color: $color-black;
}

.trough-type-attribute-renderer__card-inner {
    display: grid;
    grid-template-columns: 5fr 6fr;
    gap: 16px;
    align-items: center;

    @media only screen and (min-width: $screen-md) {
        min-height: 98px;
    }
}

.trough-type-attribute-renderer__card-icon {
    max-height: 89px;
}

.trough-type-attribute-renderer__card-icon--right {
    justify-self: flex-end;
}
</style>

<template>
    <div class="open-location-code-field">
        <TextField
            readonly
            disabled
            :is-dark-theme="isDarkTheme"
            :value="openLocationCode"
            :label="$t('components.openLocationCodeField.label')"
            data-test="location-code"
            class="olc-text-field"
        >
            <PinIcon v-if="!hideIcon" slot="icon" />
        </TextField>
        <Button class="olc-help-btn" tabindex="-1" @click="openHelpModal()">
            <HelpIcon class="icon--inline icon--mono" width="14" height="14" />
        </Button>
        <ModalBox ref="olcHelpModal" :headline="$t('components.openLocationCodeField.helpModal.headline')">
            <Words block>
                {{ $t('components.openLocationCodeField.helpModal.description') }}
            </Words>
        </ModalBox>
    </div>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';

import statefulMixin from '@/plugins/mixins/statefulMixin';
import LocationApi from '@/services/Api/Location';

import Button from '@/components/Button/Button';
import TextField from '@/components/Form/TextField.v2';
import Words from '@/components/Typography/Words';
import ModalBox from '@/components/Modal/ModalBox';

import HelpIcon from '@/assets/icons/regular/help.svg';
import PinIcon from '@/assets/icons/micro/pin.svg';

const DEFAULT_VALUE = '-';

export default {
    name: 'OpenLocationCodeField',
    components: {
        Button,
        ModalBox,
        TextField,
        Words,

        HelpIcon,
        PinIcon,
    },
    mixins: [statefulMixin],
    props: {
        location: {
            type: Object,
            default: null,
        },
        isDarkTheme: {
            type: Boolean,
            default: false,
        },
        hideIcon: {
            type: Boolean,
            default: false,
        },
        defaultValue: {
            type: String,
            default: DEFAULT_VALUE,
        },
    },
    data() {
        return {
            openLocationCode: this.defaultValue,
        };
    },
    watch: {
        location: {
            immediate: true,
            async handler(newLocation) {
                if (_isEmpty(newLocation)) {
                    this.openLocationCode = this.defaultValue;
                    return;
                }

                this.openLocationCode =
                    (await LocationApi.getOpenLocationCode(newLocation)) ?? this.defaultValue ?? DEFAULT_VALUE;
            },
        },
    },
    methods: {
        openHelpModal() {
            this.$refs.olcHelpModal.$emit('open');
        },
    },
};
</script>

<style lang="scss" scoped>
.open-location-code-field {
    position: relative;
    display: block;

    ::v-deep svg,
    ::v-deep path {
        fill: $color-darkGrey;
    }
}

.olc-help-btn {
    position: absolute;
    bottom: 18px;
    right: 15px;
}

.olc-text-field {
    ::v-deep .text-field__input--disabled {
        background: $color-littleDarkerThanLightMediumGrey;
        font-weight: 700;
        color: #35343d;
        font-size: 14px;
    }
}
</style>

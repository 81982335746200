import { ProductAttributeField } from '@/services/ProductCatalog/constants';
export const getEmptyMasterProduct = () => ({
    id: '',
    image: null,
    grainSize: {
        min: 0,
        max: 0,
        unit: 'mm',
    },
    density: 0,
    label: '',
    markets: [],
    recycled: false,
    attributeValues: [],
    productCategories: [],
    productSchemaId: '',
});
export const getEmptyMasterProductForm = () => ({
    grainSize: {
        min: 0,
        max: 0,
    },
    markets: [],
    [ProductAttributeField.GEOLOGY]: [],
    [ProductAttributeField.GEOGRAPHY]: [],
    [ProductAttributeField.CHARACTERISTICS]: [],
    [ProductAttributeField.CERT_1]: [],
    [ProductAttributeField.CERT_2]: [],
    [ProductAttributeField.CERT_3]: [],
    [ProductAttributeField.BRAND]: [],
    [ProductAttributeField.COLOR]: [],
    [ProductAttributeField.PROCESSING]: [],
});

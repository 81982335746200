<template>
    <div :class="{ 'list--spaced': spaced, 'list--inner-shadow': innerShadow }" class="list">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'List',
    props: {
        spaced: {
            type: Boolean,
            default: false,
        },
        innerShadow: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.list {
    width: 100%;
    background-color: $color-white;

    > *:not(:last-child) {
        border-bottom: 2px solid $color-lightGrey;
    }
}

.list--spaced {
    background-color: transparent;

    > * {
        background-color: $color-white;
        box-shadow: $boxShadow-full;
        border-bottom: 0 !important;
    }

    > :not(:last-child) {
        margin-bottom: 20px;
    }
}

.list--inner-shadow {
    box-shadow: inset 0 20px 20px -22px rgba($color-black, 0.2);
}
</style>

import { createContext, useContext } from 'react';
const EmitVueEventContext = createContext(() => { });
export const useVueEmit = () => {
    const emitVueEvent = useContext(EmitVueEventContext);
    return {
        emitVueEvent,
    };
};
export const EmitVueEventProvider = props => {
    return <EmitVueEventContext.Provider value={props.emitVueEvent}>{props.children}</EmitVueEventContext.Provider>;
};

import AbstractFilterableResource from './AbstractFilterableResource';

class Factories extends AbstractFilterableResource {
    async toggleOpenClose(factoryId) {
        try {
            const response = await this.apiService.put(`${this.resourcePath}/${factoryId}/toggle-open-close`);
            if (response.status === 200) {
                return response.data;
            }
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async put(factoryId, routeName, resourceObject) {
        try {
            const response = await this.apiService.put(
                `${this.resourcePath}/${factoryId}/${routeName}`,
                resourceObject
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new Factories('/v1/factories');

<template>
    <ContextMenu @click="$emit('click', $event)">
        <template #default="{ selectAction }">
            <ContextMenuEntry :disabled="disabled" action="createPdf" @click="selectAction($event)">
                <PdfIcon slot="left" class="icon--inline" />
                {{ $t('pages.logbook.actions.pdf') }}
            </ContextMenuEntry>
            <ContextMenuEntry :disabled="disabled" action="sendEmail" @click="selectAction($event)">
                <EmailIcon slot="left" width="14" height="14" class="icon--inline logbook__email-icon" />
                {{ $t('pages.logbook.actions.email') }}
            </ContextMenuEntry>
            <ContextMenuEntry action="help" @click="selectAction($event)">
                <InfoIcon slot="left" class="icon--inline" />
                {{ $t('contextMenu.transport.contactSchuettflix') }}
            </ContextMenuEntry>
        </template>
    </ContextMenu>
</template>

<script>
import ContextMenu from '@/components/Button/ContextMenu';
import ContextMenuEntry from '@/components/Button/ContextMenuEntry';

import PdfIcon from '@/assets/icons/micro/pdf.svg';
import EmailIcon from '@/assets/icons/micro/email.svg';
import InfoIcon from '@/assets/icons/micro/info.svg';

export default {
    name: 'LogbookContextMenu',
    components: {
        ContextMenu,
        ContextMenuEntry,

        PdfIcon,
        EmailIcon,
        InfoIcon,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss"></style>

<template>
    <div class="feature-setting-bool-field-type">
        <ToggleSwitchField :value="value" @input="$emit('input', $event)">{{ setting.label }}</ToggleSwitchField>
        <div v-if="setting.default !== undefined">
            <Words block tiny>{{
                $t('pages.organization.featureSettingsFieldTypes.boolFieldType.defaultValue')
            }}</Words>
            <Words block bold>{{
                setting.default
                    ? $t('pages.organization.featureSettingsFieldTypes.boolFieldType.on')
                    : $t('pages.organization.featureSettingsFieldTypes.boolFieldType.off')
            }}</Words>
        </div>
    </div>
</template>

<script>
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import Words from '@/components/Typography/Words';

export default {
    name: 'BoolFieldType',
    components: {
        ToggleSwitchField,
        Words,
    },
    props: {
        value: {
            type: Boolean,
            default: null,
        },
        setting: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.feature-setting-bool-field-type {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    align-items: center;
}
</style>

<template>
    <LayoutPage is-flyout :screen-name="screenName" data-test="checkout-confirmation-page">
        <div slot="flyoutHeader" />
        <div class="checkout-confirmation">
            <BaseButton class="checkout-confirmation__close" @click="done">
                <CloseIcon width="12" height="12" />
            </BaseButton>

            <div class="container-deprecated">
                <PlacedOrderIcon class="checkout-confirmation__icon" />

                <Headline :level="3" class="checkout-confirmation__headline">
                    {{
                        $t(`pages.checkout.confirmation.${messageType}.headline`, {
                            clientName: quote.project ? quote.project.client.name : quote.client.name,
                        })
                    }}
                </Headline>

                <Words
                    block
                    class="checkout-confirmation__info"
                    data-test="checkout-confirmation-order-number"
                    v-html="$t(`pages.checkout.confirmation.${messageType}.orderInfo`, { number })"
                />

                <Words v-if="basketType === 'delivery'" block class="checkout-confirmation__description">
                    {{
                        $t(`pages.checkout.confirmation.${messageType}.description`, {
                            clientSupervisor: `${quote.supervisor.firstName} ${quote.supervisor.lastName}`,
                        })
                    }}
                </Words>

                <div v-if="basketType === 'pickup' && messageType === 'default'">
                    <i18n
                        v-if="quote.lineItemGroups[0].__expireDate"
                        path="pages.checkout.summary.expireDateConfirmationLabel"
                        tag="span"
                    >
                        <Words bold place="date">{{
                            $d(ensureJSTimestamp(quote.lineItemGroups[0].__expireDate), 'short')
                        }}</Words>
                    </i18n>
                    <dir v-if="hasOrderListing" class="checkout-confirmation__optional-action">
                        <BaseButton arrow-right @click="openOrderPage">
                            {{ $t('pages.checkout.summary.showOrderAndstartNow') }}
                        </BaseButton>
                    </dir>
                </div>
                <BaseButton v-else-if="hasOrderListing" arrow-right @click="openOrderPage">
                    {{ $t('pages.checkout.confirmation.goToOrder') }}
                </BaseButton>
            </div>
        </div>

        <ButtonGroup slot="sticky">
            <BaseButton place-left primary light data-test="checkout-confirmation-done-button" @click="done">{{
                $t('pages.checkout.confirmation.done')
            }}</BaseButton>
            <BaseButton v-if="quote.orderNumber || quote.project" primary @click="startNewOrder">
                {{ $t('pages.checkout.confirmation.newOrder') }}
            </BaseButton>
        </ButtonGroup>
    </LayoutPage>
</template>

<script>
import SupplierApi from '@/services/Api/Supplier';
import _cloneDeep from 'lodash/cloneDeep';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import { ensureJSTimestamp } from '@/services/utils/date';
import { navigationFailure } from '@/services/utils/router';

import LayoutPage from '@/components/Layout/Page.v2';
import ButtonGroup from '@/components/Button/ButtonGroup';
import BaseButton from '@/components/Button/Button';
import Headline from '@/components/Typography/Headline';

import Words from '@/components/Typography/Words';
import PlacedOrderIcon from '@/assets/icons/confirmation/placed-order.svg';
import CloseIcon from '@/assets/icons/regular/close.svg';
import { mapState } from 'vuex';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';

export default {
    name: 'ConfirmationPage',
    components: {
        LayoutPage,
        ButtonGroup,
        BaseButton,
        Words,
        Headline,
        PlacedOrderIcon,
        CloseIcon,
    },
    setup() {
        return {
            screenName: useOrderScreenName('confirmation'),
        };
    },
    data() {
        return {
            quote: null,
        };
    },
    computed: {
        ...mapState('basket', {
            basketType: 'type',
            isCustom: 'isCustom',
        }),

        messageType() {
            if (this.quote.customOrder) {
                return 'custom';
            }
            return this.quote.status === 'pending' ? 'applicant' : 'default';
        },
        number() {
            return this.quote.status === 'pending' ? this.quote.number : this.quote.orderNumber;
        },
        hasOrderListing() {
            return this.$can('listOrders') || this.$can('havePlatformOrderManagement');
        },
    },
    created() {
        this.quote = _cloneDeep(this.$store.state.basket.confirmedQuote);
        this.hydrateExpireDate(this.quote);
    },
    methods: {
        ensureJSTimestamp,

        done() {
            this.$router.push({ name: 'home' }).catch(navigationFailure);
        },

        goToProjectPositionCheckout() {
            this.$router
                .push({
                    name: this.$root.findRouteName('checkout-project-position'),
                })
                .catch(navigationFailure);
        },

        startNewOrder() {
            this.$store.dispatch('basket/reset');

            const orderType = this.quote.lineItemGroups[0].type;
            const type = this.basketType || orderType.replace('transport-', '');
            const isTypeProjectPosition = [
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DELIVERY,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_SHIPMENT,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DISPOSAL_HAZARDOUS,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DISPOSAL_NON_HAZARDOUS,
            ].includes(type);

            if (isTypeProjectPosition) {
                this.goToProjectPositionCheckout();
                return;
            }

            if (this.quote.customOrder) {
                this.$store.dispatch('basket/init', { type, isCustom: true });
                this.$store.commit('projectPosition/RESET');
                this.$router
                    .push({ name: this.$root.findRouteName(`checkout-custom-${type}`) })
                    .catch(navigationFailure);
            } else {
                this.$store.dispatch('basket/init', { type });
                this.$store.commit('projectPosition/RESET');
                this.$router.push({ name: this.$root.findRouteName(`checkout-${type}`) }).catch(navigationFailure);
            }
        },

        openOrderPage() {
            if (this.$can('havePlatformOrderManagement')) {
                this.$router
                    .push({
                        name: 'order-management__order-list__order-quote-view',
                        params: { quoteId: this.quote.id },
                    })
                    .catch(navigationFailure);
            } else if (this.$can('listOrders')) {
                this.$router
                    .push({ name: 'order__order-list__order-quote-view', params: { quoteId: this.quote.id } })
                    .catch(navigationFailure);
            }
        },

        async hydrateExpireDate(quote) {
            if (quote.lineItemGroups) {
                quote.lineItemGroups.forEach(async lineItemGroup => {
                    if (lineItemGroup.type !== ORDER_LINE_ITEM_GROUP_TYPE.PICKUP) return;

                    const data = await SupplierApi.getExpireDateById(lineItemGroup.factoryId);
                    if (data.pickupExpireDate) {
                        this.$set(lineItemGroup, '__expireDate', data.pickupExpireDate);
                    }
                });
            }
        },
    },
};
</script>

<style lang="scss">
.checkout-confirmation {
    position: relative;
}

.checkout-confirmation__close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
}

.checkout-confirmation__icon {
    display: block;
    width: 95px;
    height: auto;
    margin: 0 auto;
    padding: 40px 0 50px;
}

.checkout-confirmation__info {
    margin: 1.5em 0;

    strong {
        font-weight: normal;
        color: $color-red;
    }
}

.checkout-confirmation__description {
    margin: 1.5em 0;
}

.checkout-confirmation__optional-action {
    margin-top: 30px;
    padding: 0;
}
</style>

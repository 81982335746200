import ReactBridge from './ReactBridge.vue';
import { defineComponent, h, onUpdated, ref } from 'vue';
import camelCase from 'lodash/camelCase';
import isEqual from 'lodash/isEqual';
import upperFirst from 'lodash/upperFirst';
const convertKeysToCamelCase = (record) => {
    const recordEntries = Object.entries(record);
    const recordEntriesWithCamelCaseKeys = recordEntries.map(([key, value]) => [camelCase(key), value]);
    return Object.fromEntries(recordEntriesWithCamelCaseKeys);
};
const prefixKeysWithOn = (record) => {
    const recordEntries = Object.entries(record);
    const recordEntriesWithCamelCaseKeys = recordEntries.map(([key, value]) => [`on${upperFirst(key)}`, value]);
    return Object.fromEntries(recordEntriesWithCamelCaseKeys);
};
/**
 * Convert a React component to a Vue component. Runtime event handlers and props are reactive
 * and will be passed to the react component
 *
 * @param component - React component to convert.
 * @returns Vue component
 */
export function asVueComponent(component, { initialProps = {}, vModel: { prop: modelProp = 'value', event: modelEvent = 'input' } = {}, } = {}) {
    return defineComponent({
        model: {
            prop: modelProp,
            event: modelEvent,
        },
        setup(_props, ctx) {
            // making attrs reactive, see https://vuejs.org/guide/components/attrs.html#accessing-fallthrough-attributes-in-javascript
            const calculateFallThroughPropsAndListeners = () => ({
                ...ctx.attrs,
                ...convertKeysToCamelCase(ctx.attrs),
                ...prefixKeysWithOn(convertKeysToCamelCase(ctx.listeners)),
            });
            const fallThroughPropsAndListeners = ref(calculateFallThroughPropsAndListeners());
            onUpdated(() => {
                const newFallThroughPropsAndListeners = calculateFallThroughPropsAndListeners();
                if (!isEqual(fallThroughPropsAndListeners.value, newFallThroughPropsAndListeners))
                    fallThroughPropsAndListeners.value = newFallThroughPropsAndListeners;
            });
            return {
                fallThroughPropsAndListeners,
            };
        },
        render() {
            return h(ReactBridge, {
                props: {
                    reactComponent: component,
                    props: {
                        ...initialProps,
                        ...this.fallThroughPropsAndListeners,
                    },
                },
            });
        },
    });
}

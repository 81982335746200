<template>
    <router-view v-if="isReplacedChildView" />
    <div v-else class="page" :class="{ 'page--flyout': isFlyout, 'page--bg-gray': backgroundGrey }">
        <header class="page__header" :class="{ 'lg:px-4 lg:py-2': !isFlyout }">
            <slot v-if="isFlyout" name="flyoutHeader">
                <HeaderBar>
                    <template #left>
                        <HeaderBarItem v-if="!closeByX" button @click="closeFlyout">
                            <SfSysArrowLeftIcon size="xs" />
                        </HeaderBarItem>
                    </template>

                    <template #headline>
                        <slot name="pageTitle" />
                    </template>

                    <template #right>
                        <HeaderBarItem v-if="closeByX" button @click="closeFlyout">
                            <SfSysCloseIcon size="xs" />
                        </HeaderBarItem>
                    </template>
                </HeaderBar>
            </slot>
            <slot v-else-if="$root.isDesktop" name="desktopHeader">
                <div class="page__header-nav overflow-hidden pr-12">
                    <slot name="desktopHeaderNavigation">
                        <div
                            v-if="parentRoute"
                            class="mb-1 flex cursor-pointer items-center hover:opacity-50"
                            @click="goToParent()"
                        >
                            <SfSysArrowLeftIcon size="xxs" class="mr-2 flex-shrink-0" />
                            <span class="font-copy-sm-strong truncate">{{ computedBackTitle }}</span>
                        </div>
                        <div
                            v-if="backTitle && !parentRoute"
                            class="mb-1 flex cursor-pointer items-center hover:opacity-50"
                            @click="$emit('parentRouteClick')"
                        >
                            <SfSysArrowLeftIcon size="xxs" class="mr-2 flex-shrink-0" />
                            <span class="font-copy-sm-strong truncate">{{ computedBackTitle }}</span>
                        </div>
                    </slot>
                    <div class="page__title font-headline-lg truncate">
                        <slot name="pageTitle" />
                    </div>
                </div>

                <div class="page__header-actions flex h-full items-center">
                    <div
                        class="page__help mr-8 flex cursor-pointer items-center gap-2 hover:opacity-50"
                        data-test="resource-center"
                        @click="openResourceCenter"
                    >
                        <HelpIcon />
                        <p class="font-copy-sm">{{ $t('components.layout.page.header.help') }}</p>
                        <SfSysChevronRightIcon size="xxs" />
                    </div>

                    <div
                        class="page__notification-bell relative mr-[28px] flex h-full cursor-pointer items-center hover:opacity-50"
                        @click="openGlobalPage('page.notifications')"
                    >
                        <SfNotificationIcon size="xs" multi-color />
                        <span
                            v-if="unreadCount"
                            class="font-copy-xs-strong absolute left-[14px] top-[14px] flex h-4 w-4 items-center justify-center rounded-full bg-brand-500 text-white"
                            >{{ unreadCount }}</span
                        >
                    </div>

                    <div
                        class="flex h-full cursor-pointer items-center border-l border-light-gray-200 pl-[20px] hover:opacity-50"
                        @click="openGlobalPage('userNavigationToggled')"
                    >
                        <img v-if="thumbnail" :src="thumbnail" class="h-7 w-7 rounded-full ring ring-1 ring-offset-1" />
                        <FallbackImage v-else class="h-7 w-7 rounded-full ring ring-1 ring-offset-1" />

                        <p class="font-copy-md-strong mx-2 whitespace-nowrap" data-test="header-user-name">
                            {{ fullName }}
                        </p>
                        <SfSysChevronDownIcon size="xxs" />
                    </div>
                </div>
            </slot>
            <slot v-else name="mobileHeader">
                <HeaderBar>
                    <template #left>
                        <HeaderBarItem v-if="parentRoute" button @click="closeFlyout">
                            <SfSysArrowLeftIcon size="xs" />
                        </HeaderBarItem>
                    </template>

                    <template #headline>
                        <slot name="pageTitle" />
                    </template>

                    <template v-if="$slots.mobileHeaderRight" #right>
                        <slot name="mobileHeaderRight" />
                    </template>
                </HeaderBar>
            </slot>
            <div
                :class="{ 'page__pending-request--active': isRequestPending }"
                class="page__pending-request"
                data-test="pending-request"
            />
        </header>
        <div class="page__main">
            <main class="page__main-content">
                <slot />
                <div v-if="!isFlyout && $slots.sticky" class="page__main-sticky-footer">
                    <slot name="sticky" />
                </div>
            </main>
        </div>
        <footer v-if="isFlyout" class="page__footer page__footer--flyout">
            <slot name="sticky" />
        </footer>

        <div class="page__subpages">
            <slot name="subpages" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import userflow from 'userflow.js';

import pageMixin from '@/plugins/mixins/pageMixin';

import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';

import HelpIcon from '@/assets/icons/regular/help.svg';
import {
    SfNotificationIcon,
    SfSysArrowLeftIcon,
    SfSysChevronDownIcon,
    SfSysChevronRightIcon,
    SfSysCloseIcon,
} from '@schuettflix/vue-components';
import FallbackImage from '@/assets/avatar.svg';
import { useRoute } from 'vue-router/composables';
import { computed, onMounted, onUnmounted, watch } from 'vue';
import { useAnalyticsService } from '@schuettflix/analytics-vue';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Page',

    components: {
        HeaderBar,
        HeaderBarItem,
        HelpIcon,
        SfNotificationIcon,
        SfSysArrowLeftIcon,
        SfSysChevronRightIcon,
        SfSysChevronDownIcon,
        FallbackImage,
        SfSysCloseIcon,
    },

    mixins: [pageMixin],

    props: {
        isFlyout: {
            type: Boolean,
            default: false,
        },
        childRoutes: {
            type: Array,
            default: () => [],
        },
        closeByX: {
            type: Boolean,
            default: false,
        },
        parentRoute: {
            type: [String, Object],
            default: null,
        },
        backTitle: {
            type: String,
            default: null,
        },
        backgroundGrey: {
            type: Boolean,
            default: false,
        },
        screenName: {
            type: String,
            default: undefined,
        },
    },

    setup(props) {
        const analyticsService = useAnalyticsService();
        const route = useRoute();

        const isReplacedChildView = computed(() => {
            return props.childRoutes.some(partialRouteName => route.name.match(partialRouteName));
        });

        if (props.screenName) {
            onMounted(() => {
                if (!isReplacedChildView.value) {
                    analyticsService?.enterScreen(props.screenName);
                }
            });

            onUnmounted(() => {
                if (!isReplacedChildView.value) {
                    analyticsService?.leaveScreen(props.screenName);
                }
            });

            watch(isReplacedChildView, (newValue, oldValue) => {
                if (!newValue && oldValue) {
                    analyticsService?.enterScreen(props.screenName);
                } else if (newValue && !oldValue) {
                    analyticsService?.leaveScreen(props.screenName);
                }
            });
        }

        return {
            isReplacedChildView,
        };
    },

    computed: {
        ...mapGetters('notification', ['unreadCount']),
        ...mapGetters('user', ['fullName', 'profileImage']),
        ...mapGetters('request', ['isRequestPending']),

        thumbnail() {
            return this.profileImage?.url?.thumbnail;
        },

        computedBackTitle() {
            if (this.backTitle) {
                return this.backTitle;
            }

            return {
                settings: this.$t('pages.backTitle.settings'),
                factories: this.$t('pages.backTitle.factories'),
                'project-list': this.$t('pages.backTitle.project-list'),
                'product-list': this.$t('pages.backTitle.product-list'),
                'product-management': this.$t('pages.backTitle.product-management'),
                'organization-list': this.$t('pages.backTitle.organization-list'),
                'management__organization-list': this.$t('pages.backTitle.organization-list'),
                'vehicle-management': this.$t('pages.backTitle.vehicle-management'),
                'supplier-products': this.$t('pages.backTitle.supplier-products'),
                'vehicle-class-management': this.$t('pages.backTitle.vehicle-class-management'),
            }[this.parentRoute?.name || this.parentRoute];
        },
    },

    created() {
        this.setupUserInfoPolling();
        this.fetchUnreadCount();
    },

    methods: {
        ...mapActions('user', ['setupUserInfoPolling']),
        ...mapActions('notification', ['fetchUnreadCount']),

        goToParent() {
            if (!this.parentRoute) {
                return;
            }

            this.$router.push(
                typeof this.parentRoute === 'object'
                    ? this.parentRoute
                    : { name: this.$root.findRouteName(this.parentRoute) }
            );
        },
        openResourceCenter(event) {
            event.stopPropagation();
            userflow.toggleResourceCenter();
        },
        closeFlyout() {
            this.$emit('close', true);
        },
    },
};
</script>

<style lang="scss">
.page {
    --background-color: #{$color-littleDarkerThanLightMediumGrey};

    display: grid;
    height: 100%;
    grid-template-rows: var(--header-height) 1fr auto;
    grid-template-areas:
        'header'
        'content'
        'footer';
}

.page--bg-gray {
    --background-color: #{$color-lightMediumGrey};
}

.page--flyout {
    --header-height: auto;
    height: 100%;

    .page__main {
        overflow: auto;
    }

    .page__main-content {
        padding: 0;
        display: flex;
        flex-flow: column nowrap;
    }
}

.page__header {
    grid-area: header;
    background-color: $color-white;
    justify-content: space-between;
    align-items: center;
    position: relative;
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;

    @media screen and (min-width: $layout-desktop-min) {
        border-bottom: 1px solid $color-mediumGrey;
    }

    .page--flyout & {
        display: block;
    }
}

.page__main {
    grid-area: content;
    background-color: var(--background-color);
    overflow: auto;

    .page--flyout & {
        padding: 0;
    }

    display: flex;
    flex-flow: column nowrap;
}

.page__main-content {
    flex: 1 0 auto;

    @media screen and (min-width: $layout-desktop-min) {
        padding: 20px;
    }

    .factory-view & {
        @media screen and (min-width: $layout-desktop-min) {
            padding: 0;
        }
    }
}

.page__main-footer {
    flex: 0 0 40px;
}

.page__main-sticky-footer {
    position: sticky;
    bottom: 0;
    background: $color-white;
    z-index: 1;

    .page--flyout & {
        margin: 0;
    }
}

.page__footer {
    background-color: $color-lightGrey;
    border-top: 1px solid $color-mediumGrey;
    display: none;

    @media screen and (min-width: $layout-desktop-min) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        padding: 5px 20px;
    }

    .page--flyout & {
        display: block;
        padding: 0;
    }
}

.page__pending-request {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background: linear-gradient(to right, rgba(255, 0, 0, 0) 0%, $color-red 20%, rgba(255, 0, 0, 0) 40%);
    background-size: 200% 100%;
    background-position: 0% 50%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    animation: pendingRequestAnimation 1s linear infinite;
    animation-play-state: paused;
}

.page__pending-request--active {
    opacity: 1;
    animation-play-state: running;
}

@keyframes pendingRequestAnimation {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: -200% 50%;
    }
}

.page__navigation-back-button {
    margin-bottom: 5px;

    .button__body {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}
</style>

<template>
    <LayoutPage is-flyout :screen-name="screenName" class="project-position-selection-page">
        <HeaderComponent
            slot="flyoutHeader"
            :headline="$t('pages.constructionProject.selectionPage.positionSelection.title')"
        />
        <portal-target name="checkoutGuideConstructionSiteSelectionMessage" />
        <div class="container-deprecated project-position-selection-page__content">
            <template>
                <LoadingSpinner v-if="projectList.isLoading" block dark />
                <template v-else>
                    <template v-if="filteredProjectPositions?.length > 0">
                        <Card
                            v-for="position in filteredProjectPositions"
                            :key="position.id"
                            class="position-list__card mb-4"
                            spaceless-x
                            clickable
                            @click="selectPosition(position)"
                        >
                            <ProjectPositionSelectionBlock :position="position" />
                        </Card>
                    </template>
                    <div v-else class="flex flex-col items-center justify-center px-4 text-center">
                        <CancelIllustration />
                        <p class="font-headline-md-strong mt-4">
                            {{ $t('pages.constructionProject.selectionPage.emptyState.title') }}
                        </p>
                        <p class="font-copy-md mt-2">
                            {{ $t('pages.constructionProject.selectionPage.emptyState.description') }}
                        </p>
                        <SfButton class="mt-6 h-[48px] rounded" @click="goToContactPage">
                            {{ $t('pages.constructionProject.selectionPage.emptyState.button.label') }}
                        </SfButton>
                    </div>
                </template>
            </template>
        </div>
    </LayoutPage>
</template>

<script>
import FilterableListing from '@/services/FilterableListing/FilterableListing';
import ProjectFacadeView from '@/models/ProjectFacadeView';
import ProjectInfoApi from '@/services/Api/ProjectInfo';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import {
    CHECKOUT_PROJECT_POSITION_DELIVERY_ROUTE_ENTRY_POINT,
    CHECKOUT_PROJECT_POSITION_DISPOSAL_HAZARDOUS_ROUTE_ENTRY_POINT,
    CHECKOUT_PROJECT_POSITION_DISPOSAL_NON_HAZARDOUS_ROUTE_ENTRY_POINT,
    CHECKOUT_PROJECT_POSITION_SHIPMENT_ROUTE_ENTRY_POINT,
} from '@/constants/routeNames';
import { TRANSPORT_TYPE_DELIVERY, TRANSPORT_TYPE_SHIPMENT } from '@/constants/transportTypes';
import { formatAddressObject } from '@/services/utils/address';
import { mapGetters, mapState } from 'vuex';
import { navigationFailure } from '@/services/utils/router';

import Card from '@/components/Layout/Card';
import HeaderComponent from './components/Header';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import ProjectPositionSelectionBlock from '@/pages/Checkout/components/ProjectPositionSelection/ProjectPositionSelectionBlock';
import { SfButton } from '@schuettflix/vue-components';

import CancelIllustration from '@/assets/icons/orderProject/empty-project-order.svg';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';

export default {
    name: 'ProjectPositionSelectionPage',
    components: {
        Card,
        HeaderComponent,
        LayoutPage,
        LoadingSpinner,
        ProjectPositionSelectionBlock,

        SfButton,

        CancelIllustration,
    },
    setup() {
        return {
            screenName: useOrderScreenName('positionselection'),
        };
    },
    data() {
        const projectList = new FilterableListing({
            namespace: 'project-list',
            endpoint: ProjectInfoApi,
            transformCallback: item => ProjectFacadeView.create(item),
        });

        return {
            projectList,
            activeProjects: [],
        };
    },
    computed: {
        ...mapState('basket', ['projectPositionSelectionErrors']),
        ...mapGetters('constructionProject', ['constructionProjectId']),
        filteredProjectPositions() {
            if (!this.constructionProjectId) {
                return [];
            }

            return this.projectList.resultItems.find(
                project => project.constructionProjectId === this.constructionProjectId
            )?.positions;
        },
    },
    created() {
        this.projectList.pairComponent(this);
        this.projectList.filter = {
            constructionProject: this.constructionProjectId,
        };
        this.projectList.refreshList(true);
    },
    methods: {
        goToContactPage() {
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$eventHub.$emit('page.platformContact', true);
        },
        /**
         * Custom validation rules for all project positions
         *
         * @param {ProjectPositionView} projectPosition
         * @return {Array}
         */
        getCustomValidationRulesForPosition(projectPosition) {
            if (!projectPosition) return [];

            return [
                {
                    rule: () => {
                        return projectPosition.hasCarrierAssigned ? projectPosition.carrier.isActive : true;
                    },
                    error: () => {
                        return {
                            key: 'carrierInactive',
                            val: {
                                carrierName: projectPosition.carrier.name,
                            },
                        };
                    },
                },
            ];
        },
        /**
         * Custom validation rules for delivery project positions only
         *
         * @param {ProjectPositionView} projectPosition
         * @return {Array}
         */
        getCustomValidationRulesForPositionTypeDelivery(projectPosition) {
            if (!projectPosition) return [];

            return [
                ...this.getCustomValidationRulesForPosition(projectPosition),
                {
                    rule: () => {
                        return projectPosition.constructionSite?.isActive && !projectPosition.constructionSite?.deleted;
                    },
                    error: () => {
                        return {
                            key: 'constructionSiteInactive',
                        };
                    },
                },
                {
                    rule: () => {
                        return projectPosition.factory?.isOpen;
                    },
                    error: () => {
                        return {
                            key: 'supplierFactoryClosed',
                            val: {
                                supplierFactoryName: projectPosition.factory.name,
                                supplierFactoryAddress: formatAddressObject(projectPosition.factory || undefined),
                            },
                        };
                    },
                },
            ];
        },

        /**
         * Custom validation rules for disposal project positions only
         *
         * @param {ProjectPositionView} projectPosition
         * @return {Array}
         */
        getCustomValidationRulesForPositionTypeDisposal(projectPosition) {
            if (!projectPosition) return [];

            return [
                ...this.getCustomValidationRulesForPosition(projectPosition),
                {
                    rule: () => {
                        return projectPosition.constructionSite?.isActive && !projectPosition.constructionSite?.deleted;
                    },
                    error: () => {
                        return {
                            key: 'constructionSiteInactive',
                        };
                    },
                },
                {
                    rule: () => {
                        return (
                            projectPosition.disposalProduct?.isActive &&
                            projectPosition.disposalProductVariant?.isActive &&
                            projectPosition.disposalProductVariant?.pollutionType?.isAvailable
                        );
                    },
                    error: () => {
                        return {
                            key: 'disposalProductInactive',
                            val: {
                                avvNumber: projectPosition.wasteCode,
                                pollution: projectPosition.disposalProductVariant.pollutionType.label,
                                supplierName: projectPosition.disposerName,
                            },
                        };
                    },
                },
                {
                    rule: () => {
                        return projectPosition.factory?.isOpen;
                    },
                    error: () => {
                        return {
                            key: 'supplierFactoryClosed',
                            val: {
                                supplierFactoryName: projectPosition.factory.name,
                                supplierFactoryAddress: formatAddressObject(projectPosition.factory || undefined),
                            },
                        };
                    },
                },
            ];
        },
        /**
         * Custom validation rules for shipment project positions only
         *
         * @param {ProjectPositionView} projectPosition
         * @return {Array}
         */
        getCustomValidationRulesForPositionTypeShipment(projectPosition) {
            if (!projectPosition) return [];

            return [...this.getCustomValidationRulesForPosition(projectPosition)];
        },
        /**
         * Select position
         * @param {ProjectPositionView} position
         */
        selectPosition(position) {
            if (!position) return;

            let validationRules;
            let checkoutEntryPoint;

            switch (position.type) {
                case TRANSPORT_TYPE_DELIVERY:
                    validationRules = this.getCustomValidationRulesForPositionTypeDelivery(position);
                    checkoutEntryPoint = CHECKOUT_PROJECT_POSITION_DELIVERY_ROUTE_ENTRY_POINT;
                    this.$store.dispatch('basket/init', {
                        type: BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DELIVERY,
                    });
                    this.$store.commit('basket/setProjectPositionDelivery', position);
                    break;
                case TRANSPORT_TYPE_SHIPMENT:
                    validationRules = this.getCustomValidationRulesForPositionTypeShipment(position);
                    checkoutEntryPoint = CHECKOUT_PROJECT_POSITION_SHIPMENT_ROUTE_ENTRY_POINT;
                    this.$store.dispatch('basket/init', {
                        type: BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_SHIPMENT,
                    });
                    this.$store.commit('basket/setProjectPositionShipment', position);
                    break;
                case BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS:
                    validationRules = this.getCustomValidationRulesForPositionTypeDisposal(position);
                    checkoutEntryPoint = CHECKOUT_PROJECT_POSITION_DISPOSAL_HAZARDOUS_ROUTE_ENTRY_POINT;
                    this.$store.dispatch('basket/init', {
                        type: BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DISPOSAL_HAZARDOUS,
                    });
                    this.$store.commit('basket/setProjectPositionDisposal', position);
                    break;
                case BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS:
                    validationRules = this.getCustomValidationRulesForPositionTypeDisposal(position);
                    checkoutEntryPoint = CHECKOUT_PROJECT_POSITION_DISPOSAL_NON_HAZARDOUS_ROUTE_ENTRY_POINT;
                    this.$store.dispatch('basket/init', {
                        type: BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DISPOSAL_NON_HAZARDOUS,
                    });
                    this.$store.commit('basket/setProjectPositionDisposal', position);
                    break;
            }
            this.$store.commit('projectPosition/RESET');

            const errors = validationRules.filter(vr => !vr.rule()).map(vr => vr.error());
            this.$store.commit('basket/setProjectPositionSelectionErrors', errors.length ? errors : null);

            if (!this.projectPositionSelectionErrors) {
                this.$router
                    .push({
                        name: this.$root.findRouteName(checkoutEntryPoint),
                    })
                    .catch(navigationFailure);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/* Page */

.project-position-selection-page__content {
    padding-top: 20px;
    padding-bottom: 20px;
}

/* Project list */

.project-list__item {
    border-bottom: 1px solid $color-littleDarkerThanLittleDarkerThanMediumGrey;
}

/* Project item */
.project-item {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: auto min-content;
}

.project-item__pill {
    margin-left: 5px;
}

.project-item__action {
    display: flex;
    align-items: center;
}

/* Position list */
.position-list__card {
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
    transition:
        transform 0.2s ease,
        box-shadow 0.2s ease;

    &:active {
        transform: translateY(5px);
        box-shadow: $boxShadow-card;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}
</style>

import { revertLocalizedCollectionValues } from '@/services/utils/localization';
import i18n from '@/i18n';

export function structurizeWasteGroup(wasteCategory, wasteItems) {
    const capturingGroups = wasteCategory?.name.match(/^(\d\d \d\d) (.*)/);

    const categoryKey = capturingGroups ? capturingGroups[1] : '';
    const categoryGroupName = capturingGroups ? capturingGroups[2] : wasteCategory.name;
    return {
        groupFields: {
            wasteKey: categoryKey,
            wasteCode: wasteCategory.wasteCode,
            groupName: categoryGroupName,
            isInactive: !wasteCategory.isActive,
        },
        wasteItems: wasteItems ? wasteItems.filter(item => item.categoryIds.includes(wasteCategory.id)) : [],
    };
}

export function structurizeWasteGroups(wasteCategories, wasteItems) {
    const wasteGroups = [];
    for (const category of wasteCategories) {
        const { id, name, isActive, wasteCode } = category;
        const wasteCategory = { id: parseInt(id), name, isActive, wasteCode };
        const wasteGroup = structurizeWasteGroup(wasteCategory, wasteItems);

        wasteGroups.push(wasteGroup);
    }

    const uncategorizedItems = wasteItems ? wasteItems.filter(item => !item.categoryIds.length) : [];

    if (uncategorizedItems.length) {
        wasteGroups.unshift({
            groupFields: {
                wasteKey: '',
                groupName: i18n.t('pages.productManagement.wasteGroup.uncategorizedGroupName'),
                isInactive: false,
            },
            wasteItems: uncategorizedItems,
        });
    }

    return wasteGroups;
}

export function structurizeSortedWasteCategories(categories) {
    const sortedCategories = categories?.sort((a, b) => a.sort - b.sort);
    const wasteCategories = [];

    revertLocalizedCollectionValues(sortedCategories, ['name']).forEach(({ id, name, isActive, wasteCode }) => {
        wasteCategories.push({
            id,
            name,
            isActive,
            wasteCode,
        });
    });
    return wasteCategories;
}

export function codeLabel(code, isDangerous) {
    if (code) {
        return `${code}${isDangerous ? '*' : ''}`;
    }
    return null;
}

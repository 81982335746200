import i18n from '@/i18n';
export const useValidationMessages = () => {
    const translations = (model) => {
        return {
            required: i18n.t('validations.required'),
            requiredIf: i18n.t('validations.requiredIf'),
            requiredUnless: i18n.t('validations.required'),
            email: i18n.t('validations.email'),
            minLength: i18n.t('validations.minLength', { min: model.value?.minLength?.$params?.min ?? '' }),
            maxLength: i18n.t('validations.maxLength', { max: model.value?.maxLength?.$params?.max ?? '' }),
            minValue: i18n.t('validations.minValue', {
                min: model.value?.minValue?.$params?.min ?? '',
            }),
            maxValue: i18n.t('validations.maxValue', {
                max: model.value?.maxValue?.$params?.max ?? '',
            }),
            numeric: i18n.t('validations.numeric'),
            integer: i18n.t('validations.integer'),
            oneOf: i18n.t('validations.oneOf'),
            maxDigits: i18n.t('validations.maxDigits', { max: model.value?.maxDigits?.$params?.max ?? '' }),
            minDigits: i18n.t('validations.minDigits', { min: model.value?.minDigits?.$params?.min ?? '' }),
            digitsOnly: i18n.t('validations.digitsOnly'),
        };
    };
    return {
        translations,
    };
};
export const getValidationMessage = (error) => {
    const errors = error.response?.data || [];
    if (!Array.isArray(errors))
        // @ts-expect-error a type for the error response is missing here
        return error.response?.data?.errors?.children?.file?.errors[0] || error.response?.data?.message || error;
    return errors.map(err => err.message).join('\n');
};

import _isNumber from 'lodash/isNumber';

/**
 * Block any letter for number inputs
 * @param {event} e
 * @returns {boolean}
 */
export const blockAnyLetterForNumberInput = e =>
    !['Backspace', 'Tab', 'Shift'].includes(e.key) &&
    (Number.isNaN(parseFloat(e.key)) || !_isNumber(parseFloat(e.key))) &&
    e.preventDefault();

/**
 * Block any character except lower and uppercase letters as well as numbers
 * @param {event} e
 * @returns {boolean}
 */
export const blockNonAlphanumericCharactersFromInput = e => {
    const regex = /^[a-z0-9]$/i;
    return !['Backspace', 'Tab', 'Shift'].includes(e.key) && !regex.test(e.key) && e.preventDefault();
};

export default {
    methods: {
        allowNumbersOnly(e) {
            return blockAnyLetterForNumberInput(e);
        },
        allowAlphanumericsOnly(e) {
            return blockNonAlphanumericCharactersFromInput(e);
        },
    },
};

/** Class representing organization view model */
import AddressView from '@/models/AddressView';

export default class OrganizationView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     * @return {OrganizationView|null}
     */
    static create(data) {
        if (!data) return null;

        const organization = new OrganizationView();

        organization.id = data.id;
        organization.number = data.number;
        organization.name = data.name;
        organization.isActive = data.isActive;
        organization.disposalProofNumber = data?.disposalProofNumber;
        organization.carrierNumber = data?.carrierNumber;

        if (data.billingAddress) {
            organization.billingAddress = AddressView.create(data.billingAddress);
        }

        return organization;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._id = null;
        this._number = null;
        this._name = null;
        this._billingAddress = null;
        this._isActive = null;
        this._disposalProofNumber = null;
        this._carrierNumber = null;
    }

    /**
     * Unfold view into plain object
     * @return {object}
     */
    unfold() {
        return {
            id: this.id,
            number: this.number,
            name: this.name,
            billingAddress: this.billingAddress?.unfold(),
            isActive: this.isActive,
            disposalProofNumber: this.disposalProofNumber,
        };
    }

    /**
     * Clone the current view
     * @return {OrganizationView}
     */
    clone() {
        return OrganizationView.create(this.unfold());
    }

    /**
     * Get id
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * Set id
     * @param {number} value
     */
    set id(value) {
        this._id = value ?? null;
    }

    /**
     * Get number
     * @returns {string}
     */
    get number() {
        return this._number;
    }

    /**
     * Set number
     * @param {string} value
     */
    set number(value) {
        this._number = value ?? null;
    }

    /**
     * Get name
     * @returns {string}
     */
    get name() {
        return this._name;
    }

    /**
     * Set name
     * @param {string} value
     */
    set name(value) {
        this._name = value ?? null;
    }

    /**
     * Get billing address
     * @return {AddressView|null}
     */
    get billingAddress() {
        return this._billingAddress;
    }

    /**
     * Set billing address
     * @param value
     */
    set billingAddress(value) {
        this._billingAddress = value ?? null;
    }

    /**
     * Get active state
     * @returns {boolean|null}
     */
    get isActive() {
        return this._isActive ?? null;
    }

    /**
     * Set active state
     * @param {boolean} value
     */
    set isActive(value) {
        this._isActive = value;
    }

    /**
     * Get disposalProofNumber
     * @returns {string|null}
     */
    get disposalProofNumber() {
        return this._disposalProofNumber ?? null;
    }

    /**
     * Set disposalProofNumber
     * @param {string} value
     */
    set disposalProofNumber(value) {
        this._disposalProofNumber = value;
    }

    /**
     * Get carrierNumber
     * @returns {string|null}
     */
    get carrierNumber() {
        return this._carrierNumber;
    }

    /**
     * Set carrierNumber
     * @param {string} value
     */
    set carrierNumber(value) {
        this._carrierNumber = value;
    }
}

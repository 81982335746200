import AbstractResource from '@/services/Api/AbstractResource';

class NetSuiteV1 extends AbstractResource {
    async getNetSuitePaymentTerm(paymentTermId) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/payment-term/${paymentTermId}`);
            return response.data;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }
}

export default new NetSuiteV1('/v1/netsuite');

import { useTranslation } from 'react-i18next';
import { CloseIcon } from '@schuettflix/icons-react';
import { ActionCard, SectionInfo } from '@schuettflix/react-components';
const getPlatformActionTitleKey = (action) => `pages.organization.form.platformActions.${action}.title`;
const getPlatformActionDescriptionKey = (action) => `pages.organization.form.platformActions.${action}.description`;
const PlatformActionSection = ({ allPlatformActionCodes, organizationPlatformActionCodes, }) => {
    const { t } = useTranslation();
    return (<SectionInfo title={t('pages.organization.form.platformActionsTitle')} className={'mt-8'}>
            {allPlatformActionCodes.map(platformAction => {
            const hasPlatformAction = organizationPlatformActionCodes.includes(platformAction);
            const icon = (!hasPlatformAction && CloseIcon) || undefined;
            return (<ActionCard key={platformAction} description={t(getPlatformActionDescriptionKey(platformAction))} onClick={() => { }} icon={icon} state={hasPlatformAction ? 'success' : 'default'} title={t(getPlatformActionTitleKey(platformAction))} variant="primary" className={'mb-2'}/>);
        })}
        </SectionInfo>);
};
export default PlatformActionSection;

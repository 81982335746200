export default class ApiError extends Error {
    code;
    response;
    translationKey;
    rawError;
    constructor(code, message, response, rawError) {
        if (rawError.message === 'Network Error') {
            code = 408;
            message = message ? message : rawError.message;
        }
        super(message);
        this.message = message;
        this.translationKey = response?.data?.translationKey;
        this.code = code;
        this.response = response;
        this.rawError = rawError;
    }
}

<template>
    <LayoutPage>
        <div slot="pageTitle">Playground for <code>@schuettflix/vue-components</code></div>

        <div class="container">
            <h2 class="font-headline-xl mb-6">Essentials</h2>

            <h3 class="font-headline-xl-strong mb-4">Xlarge It is a long established fact</h3>
            <h3 class="font-headline-xl mb-4">Xlarge It is a long established fact</h3>
            <h3 class="font-headline-lg-strong mb-4">Large It is a long established fact</h3>
            <h3 class="font-headline-lg mb-4">Large It is a long established fact</h3>
            <h3 class="font-headline-md-strong mb-4">Medium It is a long established fact</h3>
            <h3 class="font-headline-md mb-4">Medium It is a long established fact</h3>

            <p class="font-copy-md-strong mb-4">
                It is a long established fact that a reader will be distracted by the readable content of a page when
                looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution
                of letters, as opposed to using 'Content here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a
                search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have
                evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
            </p>
            <p class="font-copy-md mb-4">
                It is a long established fact that a reader will be distracted by the readable content of a page when
                looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution
                of letters, as opposed to using 'Content here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a
                search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have
                evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
            </p>
            <p class="font-copy-sm-strong mb-4">
                It is a long established fact that a reader will be distracted by the readable content of a page when
                looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution
                of letters, as opposed to using 'Content here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a
                search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have
                evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
            </p>
            <p class="font-copy-sm mb-4">
                It is a long established fact that a reader will be distracted by the readable content of a page when
                looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution
                of letters, as opposed to using 'Content here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a
                search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have
                evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
            </p>
            <p class="font-copy-xs-strong mb-4">
                It is a long established fact that a reader will be distracted by the readable content of a page when
                looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution
                of letters, as opposed to using 'Content here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a
                search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have
                evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
            </p>
            <p class="font-copy-xs mb-4">
                It is a long established fact that a reader will be distracted by the readable content of a page when
                looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution
                of letters, as opposed to using 'Content here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a
                search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have
                evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
            </p>

            <p class="font-overline mb-4">Overline for Numbers</p>
            <p class="font-numbers">+4901234567890</p>
            <p class="font-numbers-strong">+4901234567890</p>
            <p class="font-numbers-sm">+4901234567890</p>
            <p class="font-numbers-sm-strong">+4901234567890</p>
            <p class="font-numbers-md">+4901234567890</p>
            <p class="font-numbers-md-strong">+4901234567890</p>

            <h2 class="font-headline-xl mb-6 mt-8">Alerts</h2>

            <SfMessageBox class="mb-4" headline="Headline">
                The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed
                to using 'Content here, content here', making it look like readable English. Many desktop publishing
                packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
            </SfMessageBox>
            <SfMessageBox class="mb-4" type="warning">
                The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed
                to using 'Content here, content here', making it look like readable English. Many desktop publishing
                packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
            </SfMessageBox>
            <SfMessageBox class="mb-4" type="success">
                The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed
                to using 'Content here, content here', making it look like readable English. Many desktop publishing
                packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
            </SfMessageBox>
            <SfMessageBox class="mb-4" type="error">
                The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed
                to using 'Content here, content here', making it look like readable English. Many desktop publishing
                packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
            </SfMessageBox>
            <SfMessageBox class="mb-4" size="sm">
                The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed
                to using 'Content here, content here', making it look like readable English. Many desktop publishing
                packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem
                ipsum' will uncover many web sites still in their infancy.
            </SfMessageBox>

            <h2 class="font-headline-xl mb-6 mt-8">Base</h2>

            <h3 class="font-headline-md mb-4 mt-6">Links</h3>

            <SfLink href="#" block>Example Link</SfLink>
            <SfLink href="#" block bold>Strong Link </SfLink>
            <SfLink href="#" block>
                <template #leading-icon="iconProps"><SfCalendarIcon v-bind="iconProps" /></template>
                Link with Leading Icon
            </SfLink>
            <SfLink href="#" block>
                Link with Trailing Icon
                <template #trailing-icon="iconProps"><SfCancelIcon v-bind="iconProps" /></template>
            </SfLink>
            <SfLink href="#" block data-test="SfLink" target="_blank"
                >External Link with href and target property</SfLink
            >
            <SfLink href="#" block>
                Link with No Icon but really long text to test if arrow breaks in a way we don't want it to break So
                let's give it a try
            </SfLink>

            <h3 class="font-headline-md mb-4 mt-6">Tags</h3>

            <div class="mb-4 flex flex-wrap gap-2 bg p-4">
                <SfTag action>Example Tag with Action Button</SfTag>
                <SfTag>Example Tag</SfTag>
                <SfTag subdued>Example Tag</SfTag>
                <SfTag outline>Example Tag</SfTag>
                <SfTag type="warning">Example Tag</SfTag>
                <SfTag type="warning" subdued>Example Tag</SfTag>
                <SfTag type="warning" outline>Example Tag</SfTag>
                <SfTag type="error">Example Tag</SfTag>
                <SfTag type="error" subdued>Example Tag</SfTag>
                <SfTag type="error" outline>Example Tag</SfTag>
                <SfTag type="positive">Example Tag</SfTag>
                <SfTag type="positive" subdued>Example Tag</SfTag>
                <SfTag type="positive" outline>Example Tag</SfTag>
            </div>

            <div class="flex flex-wrap gap-2 bg-dark p-4">
                <SfTag theme="dark" action>Example Tag with Action Button</SfTag>
                <SfTag theme="dark">Example Tag</SfTag>
                <SfTag theme="dark" subdued>Example Tag</SfTag>
                <SfTag theme="dark" outline>Example Tag</SfTag>
                <SfTag theme="dark" type="warning">Example Tag</SfTag>
                <SfTag theme="dark" type="warning" subdued>Example Tag</SfTag>
                <SfTag theme="dark" type="warning" outline>Example Tag</SfTag>
                <SfTag theme="dark" type="error">Example Tag</SfTag>
                <SfTag theme="dark" type="error" subdued>Example Tag</SfTag>
                <SfTag theme="dark" type="error" outline>Example Tag</SfTag>
                <SfTag theme="dark" type="positive">Example Tag</SfTag>
                <SfTag theme="dark" type="positive" subdued>Example Tag</SfTag>
                <SfTag theme="dark" type="positive" outline>Example Tag</SfTag>
            </div>

            <h2 class="font-headline-xl mb-6 mt-8">Form Elements</h2>

            <form action="#" method="post" class="grid grid-cols-2 gap-4">
                <SfInput name="form-element-1" />
                <SfInput name="form-element-2" placeholder="Placeholder is placed here" />
                <SfInput theme="light" label="Label" />
                <SfInput
                    name="form-element-3"
                    label="Label with Placeholder"
                    placeholder="Placeholder is placed here"
                />
                <SfInput
                    name="form-element-4"
                    label="Label with value"
                    value="Some value with longer content can be tested here"
                />
                <SfInput
                    name="form-element-5"
                    suffix="A long suffix can be placed here as well"
                    value="Some value with longer content or anything else can be tested here"
                />
                <SfInput
                    name="form-element-6"
                    label="Label with suffix"
                    suffix="€ / t"
                    value="Some value with longer content can be tested here"
                />
                <SfInput
                    name="form-element-7"
                    label="Label with value"
                    disabled
                    value="Some value with longer content can be tested here"
                />
                <SfInput
                    name="form-element-8"
                    label="Label with formatted value (RegEx)"
                    value="16.78"
                    :format-regex="/^\d*((\.|,)\d{1,2})?/g"
                />
                <SfInput
                    name="form-element-9"
                    label="Label with error message"
                    validation-type="error"
                    validation-message="Some error has occured. Please fix it!"
                    value="Some value with longer content can be tested here"
                />
                <SfInput
                    name="form-element-10"
                    label="Label with success message"
                    validation-type="success"
                    validation-message="No errors have occured. Feel free and enjoy it!"
                    value="Some value with longer content can be tested here"
                />
                <SfInput name="form-element-11" type="date" />
                <SfInput
                    name="form-element-12"
                    label="Label with Placeholder and suffix"
                    size="sm"
                    suffix="€ / t"
                    placeholder="Placeholder is placed here"
                />
                <SfInput
                    type="date"
                    name="form-element-12"
                    label="Label with Placeholder and suffix"
                    size="sm"
                    suffix="€ / t"
                    placeholder="Placeholder is placed here"
                />
                <span class="col-span-2 mt-8">
                    <SfRadioButtonSimple
                        id="Foo"
                        input-value="foo"
                        label="Option"
                        description="Optional description of the provided option, explaining what will happen when this option is selected."
                        name="groupName"
                        :disabled="false"
                        validation-message=""
                    />
                    <SfRadioButtonSimple
                        id="Bar"
                        input-value="bar"
                        label="Option"
                        description="Optional description of the provided option, explaining what will happen when this option is selected."
                        name="groupName"
                        :disabled="false"
                        validation-message=""
                    />
                </span>
            </form>

            <h2 class="font-headline-xl mb-6 mt-8">Progress Bars</h2>

            <SfProgressBar class="mt-2" />
            <SfProgressBar class="mt-2" :primary="50" />
            <SfProgressBar class="mt-2" :secondary="70" />
            <SfProgressBar class="mt-2" :primary="50" :secondary="70" />
            <SfProgressBar class="mt-2" :primary="20" :secondary="50">
                <template #before>10t</template>
                <template #after>150t</template>
            </SfProgressBar>
            <SfProgressBar class="mt-2" :primary="20" :secondary="50" :min="10" :max="150">
                <template #before>10t</template>
                <template #tooltip-primary><span class="block text-subdued">Ausgeliefert</span>20 t</template>
                <template #tooltip-secondary><span class="block text-subdued">Reserviert</span>50 t</template>
                <template #tooltip-default><span class="block text-subdued">Verbleibend</span>150 t</template>
                <template #after>150t</template>
            </SfProgressBar>
            <SfProgressBar class="mt-2" :primary="20" :secondary="50" :min="10" :max="150" show-tooltip-as-label>
                <template #before>10t</template>
                <template #tooltip-primary><span class="block text-subdued">Ausgeliefert</span>20 t</template>
                <template #tooltip-secondary><span class="block text-subdued">Reserviert</span>50 t</template>
                <template #tooltip-default><span class="block text-subdued">Verbleibend</span>150 t</template>
                <template #after>150t</template>
            </SfProgressBar>

            <h2 class="font-headline-xl mb-6 mt-8">Buttons</h2>

            <div class="grid grid-cols-4 gap-4">
                <SfButton>Primary Button</SfButton>
                <SfButton type="secondary">Secondary Button</SfButton>
                <SfButton type="floating">Floating Button</SfButton>
                <SfButton :disabled="true">Disabled Button</SfButton>
                <SfButton size="sm">Small Primary Button</SfButton>
                <SfButton size="sm" type="secondary">Small Secondary Button</SfButton>
                <SfButton size="sm" type="floating">Small Floating Button</SfButton>
                <SfButton size="sm" :disabled="true">Small Disabled Button</SfButton>

                <SfButton
                    ><template #leading-icon="iconProps"><SfCalendarIcon v-bind="iconProps" /></template>Primary
                    Button</SfButton
                >
                <SfButton type="secondary"
                    ><template #leading-icon="iconProps"><SfCalendarIcon v-bind="iconProps" /></template>Secondary
                    Button</SfButton
                >
                <SfButton type="floating"
                    >Floating Button<template #trailing-icon="iconProps"><SfCancelIcon v-bind="iconProps" /></template
                ></SfButton>
                <SfButton :disabled="true"
                    >Disabled Button<template #trailing-icon="iconProps"><SfCancelIcon v-bind="iconProps" /></template
                ></SfButton>
                <SfButton size="sm"
                    ><template #leading-icon="iconProps"><SfCalendarIcon v-bind="iconProps" /></template>Small Primary
                    Button</SfButton
                >
                <SfButton size="sm" type="secondary"
                    ><template #leading-icon="iconProps"><SfCalendarIcon v-bind="iconProps" /></template>Small Secondary
                    Button</SfButton
                >
                <SfButton size="sm" type="floating"
                    >Small Floating Button<template #trailing-icon="iconProps"
                        ><SfCancelIcon v-bind="iconProps" /></template
                ></SfButton>
                <SfButton size="sm" :disabled="true"
                    >Small Disabled Button<template #trailing-icon="iconProps"
                        ><SfCancelIcon v-bind="iconProps" /></template
                ></SfButton>
            </div>
        </div>
    </LayoutPage>
</template>

<script>
import LayoutPage from '@/components/Layout/Page.v2';
import {
    // Components
    SfMessageBox,
    SfProgressBar,
    SfButton,
    SfLink,
    SfTag,
    SfInput,
    SfRadioButtonSimple,

    // Icons
    SfCalendarIcon,
    SfCancelIcon,
} from '@schuettflix/vue-components';

export default {
    name: 'ComponentsPlaygroundPage',
    components: {
        LayoutPage,

        // Components
        SfMessageBox,
        SfProgressBar,
        SfButton,
        SfLink,
        SfTag,
        SfInput,
        SfRadioButtonSimple,

        // Icons
        SfCalendarIcon,
        SfCancelIcon,
    },
    data() {
        return {};
    },
};
</script>

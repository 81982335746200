<template>
    <PointChargeIcon :id="getDomId" />
</template>

<script>
import { animateMoveInFromTopRight } from '@/services/Animation/Animation';
import PointChargeIcon from '@/assets/icons/freight/point-charge.svg';

export default {
    name: 'AnimatedPointCharge',
    components: {
        PointChargeIcon,
    },
    computed: {
        getDomId() {
            return `AnimatedPointChargeSVG${this._uid}`;
        },
    },
    mounted() {
        animateMoveInFromTopRight(`#${this.getDomId} > .svg-point-charge__arrow`);
    },
};
</script>

<style lang="scss" scoped></style>

<template>
    <LayoutPage :back-title="$t('pages.invoice.title')" :parent-route="parentRoute">
        <template #pageTitle>
            {{ $t('pages.documents.title') }}
        </template>
        <template #mobileHeader>
            <HeaderBar>
                <template #headline> {{ $t('pages.documents.title') }}</template>
            </HeaderBar>
        </template>

        <LoadingSpinner v-if="documentListing.isLoading" block dark />
        <div v-else class="container-content lg:px-0">
            <div class="mt-8 hidden justify-end md:flex">
                <SfButton
                    v-if="documentListing.resultCount > 0 || documentListing.resultItems.length > 0"
                    type="primary"
                    size="sm"
                    @click="downloadAllDocuments"
                >
                    {{ $t('pages.projects.documents.downloadAllLabel') }}
                    <template #trailing-icon="iconProps">
                        <SfDownloadIcon v-bind="iconProps" />
                    </template>
                </SfButton>
            </div>

            <SfFileList
                v-if="!!documentListing.resultCount"
                class="mt-8"
                :files="
                    documentListing.resultItems.map(item => ({
                        download: true,
                        type: 'pdf',
                        url: item.document.url,
                        created: item.document.created,
                        name: item.document.fileName,
                        document: item.document,
                    }))
                "
                has-background
                :translations="{
                    preview: {
                        delete: 'Delete',
                        download: 'Download',
                        edit: 'Edit',
                    },
                }"
                @download-file="onDownloadFile"
            />
        </div>
        <HintComponent
            v-if="documentListing.resultCount === 0 && documentListing.resultItems.length === 0"
            center
            transparent
        >
            {{ $t('pages.documents.noResults') }}
        </HintComponent>
    </LayoutPage>
</template>

<script>
import FilterableListing from '@/services/FilterableListing/FilterableListing';
import InvoiceApi from '@/services/Api/Platform/Invoice';
import InvoiceDocumentsApi from '@/services/Api/InvoiceDocuments';
import { ensureJSTimestamp } from '@/services/utils/date';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { format } from 'date-fns';

import HeaderBar from '@/components/Header/HeaderBar';
import HintComponent from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import { SfDownloadIcon, SfButton, SfFileList } from '@schuettflix/vue-components';

export default {
    name: 'InvoiceDocumentListPage',
    components: {
        HeaderBar,
        HintComponent,
        LayoutPage,
        SfButton,

        LoadingSpinner,
        SfDownloadIcon,
        SfFileList,
    },
    props: {
        parentRoute: {
            type: String,
            default: null,
        },
    },

    setup() {
        const { selectCreditNoteDocKey } = useLegalTerms();
        const downloadSingleDocument = item => {
            window.location.href = item.document.url;
            AnalyticsService.trackEvent('accounting', {
                step: 'invoice_document_list_download',
                fileExtension: 'pdf',
                fileName: item.document.fileName,
                linkUrl: item.document.url,
            });
        };
        const onDownloadFile = ({ file }) => {
            downloadSingleDocument(file);
        };
        return {
            downloadSingleDocument,
            onDownloadFile,
            selectCreditNoteDocKey,
        };
    },
    data() {
        const documentListing = new FilterableListing({
            namespace: 'invoice-documents',
            endpoint: new InvoiceDocumentsApi(this.$route.params.invoiceId),
            defaultFilter: {
                page: 1,
                perPage: 100,
                search: '',
                status: null,
                documentType: null,
                sortDirection: 'desc',
            },
            sortOptions: ['created'],
        });
        return {
            documentListing,
            invoice: null,
        };
    },
    computed: {
        downloadUrl() {
            return this.documentListing.result?.responseData?.downloadUrl;
        },
    },
    watch: {
        $route() {
            this.documentListing.onRouteChanged();
        },
    },
    created() {
        this.documentListing.pairComponent(this);
        this.documentListing.refreshList(true);
        this.refreshInvoice();
    },
    methods: {
        ensureJSTimestamp,

        getDocumentTypeTranslationKey(type) {
            if (type === 'credit-note') {
                return this.selectCreditNoteDocKey({
                    creditNote: 'pages.projects.documents.type.creditNote',
                    selfBilling: 'pages.projects.documents.type.selfBilling',
                });
            }
            return `pages.projects.documents.type.${type}`;
        },

        async refreshInvoice() {
            this.invoice = await InvoiceApi.getOneById(this.$route.params.invoiceId);
        },

        downloadAllDocuments() {
            window.location.href = this.downloadUrl;
            AnalyticsService.trackEvent('accounting', {
                step: 'invoice_document_list_download',
                fileExtension: 'zip',
                linkUrl: this.downloadUrl,
                fileName: this.$route.params.invoiceId + '_' + format(new Date(), 'DD.MM.YYYY') + '.zip',
            });
        },
    },
};
</script>

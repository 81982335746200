import { useQuery } from '@tanstack/react-query';
import { getPositionProduct } from '@/modules/constructionProjects/api/customRequest';
import OrganizationApi from '@/services/Api/Organization';
export const useProductGroupOrganizations = (productGroups) => {
    const query = useQuery({
        queryKey: ['productGroupOrganizations', productGroups],
        queryFn: async () => {
            if (!productGroups?.[0]?.products) {
                return [];
            }
            const products = await Promise.all(productGroups[0].products.map(async (product) => {
                return await getPositionProduct({ positionProductId: product.id });
            }));
            const orgs = await Promise.all(products.slice(0, 2).map(async (product, index) => {
                const productFilters = [
                    // Filter for org 1
                    ['MATERIAL', 'MATERIAL_TRANSPORT', 'MATERIAL_SERVICE'],
                    // Filter for org 2
                    ['TRANSPORT', 'TRANSPORT_SERVICE'],
                ];
                return {
                    id: product.partnerOrganizationId,
                    details: ((await OrganizationApi.getOneById(product.partnerOrganizationId)) ?? {
                        name: '',
                        billingAddress: {
                            city: '',
                            country: '',
                            number: '',
                            state: '',
                            street: '',
                            zip: '',
                        },
                    }),
                    productCategories: products
                        .filter(({ partnerOrganizationId, productCategory }) => partnerOrganizationId === product.partnerOrganizationId &&
                        productFilters[index].includes(productCategory))
                        .map(({ productCategory }) => productCategory),
                };
            }));
            return orgs;
        },
        refetchOnWindowFocus: false,
    });
    return query;
};

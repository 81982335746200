import { GenericPositionHTTPClient } from '@/modules/constructionProjects/services/GenericPositionHTTPClient';
import { ListGenericPositionsResponseSchema, } from '@/modules/constructionProjects/api/position-service-interfaces';
export async function listGenericPositions(criteria, signal) {
    return GenericPositionHTTPClient.get(`/v1/positions`, {
        params: criteria,
        signal,
    }).then(response => ListGenericPositionsResponseSchema.parse(response.data));
}
export function listGenericPositionsKey(criteria) {
    return ['generic-positions', criteria];
}
export function listGenericPositionsQuery(criteria) {
    return {
        queryKey: listGenericPositionsKey(criteria),
        queryFn: ({ signal }) => listGenericPositions(criteria, signal),
    };
}
export function invalidateListGenericPositionsQuery(queryClient) {
    queryClient.invalidateQueries({
        queryKey: ['generic-positions'],
    });
}

<template>
    <LayoutPage
        class="factory-view"
        data-test="LocationView"
        :screen-name="isPlatformAdministrator ? 'admin-factory-edit' : 'seller-factory-edit'"
        :back-title="parentRouteName"
        @parentRouteClick="goBackToListView()"
    >
        <template #pageTitle>
            <div class="factory-view__page-title">
                <ArrowLeft v-if="!$root.isDesktop" class="mr-4" @click="toggleSubNavigation" />
                {{ factoryName }}
            </div>
        </template>
        <FactoryContainer :sub-navigation-is-hidden="subNavigationIsHidden">
            <template #sidebar>
                <SubNavigation
                    :nav-items="subNavigationItems"
                    progress-checkmarks
                    :show-context-menu="showContextMenu"
                    @routerChange="changeRouteUsingQueryString"
                    @contextMenuClicked="openDialogModalConfirmation('deleteFactory')"
                >
                    <template #header>
                        <ProgressBar
                            v-if="!isFactory"
                            :value="factory.completionPercentage"
                            :title="$t('components.factoryManagement.factoryView.locationProfileProgress')"
                            is-small
                            is-green
                            show-progress-percentage
                        />
                        <div v-else>
                            <Words block small bold>{{
                                $t('components.factoryManagement.factoryView.locationStatus')
                            }}</Words>
                            <div class="factory-view__status">
                                <ToggleSwitch
                                    small
                                    color="green"
                                    :disabled="isReadonly"
                                    :value="factoryIsOpen"
                                    @input="onToggleFactoryState"
                                />
                                <Words v-if="!factoryIsOpen" bold block>{{
                                    $t('components.factoryManagement.factoryView.businessHours.closed')
                                }}</Words>
                                <Words v-else bold block>{{
                                    $t('components.factoryManagement.factoryView.businessHours.open')
                                }}</Words>
                            </div>
                        </div>
                    </template>
                    <template #footer>
                        <SfLink data-test="location-view-back-to-location-list" bold @click="goBackToListView()">
                            <template #leading-icon="iconProps">
                                <SfSysChevronLeftIcon v-bind="iconProps" />
                            </template>
                            {{ $t('components.factoryManagement.factoryView.toLocationOverview') }}
                        </SfLink>
                    </template>
                </SubNavigation>
            </template>
            <template v-if="!isLoading && factory">
                <FactoryNameAddress
                    v-if="isFactoryNameAddress"
                    v-model="factory"
                    :is-draft="isFactoryDraft"
                    :is-factory="isFactory"
                    :section-reset="formSectionReset"
                    :is-read-only="isReadonly"
                    :submitted="formSubmitted"
                    @confirmation-dialog="handleModal"
                    @dirty-check="handleDirtyState"
                    @valid-check="handleValidState"
                    @datatransfer="updateDataFromOrganization"
                />

                <ContentWrapper v-if="isFactoryLocationType">
                    <FactoryLocationType
                        v-model="factory"
                        :is-draft="isFactoryDraft"
                        :is-factory="isFactory"
                        :organization="factoryOrganization"
                        :section-reset="formSectionReset"
                        :submitted="formSubmitted"
                        :is-read-only="isReadonly"
                        :allow-dk-change="allowDkChange"
                        @uploadingStarted="uploadInProgress = true"
                        @uploadingFinished="uploadInProgress = false"
                        @confirmation-dialog="handleModal"
                        @dirty-check="handleDirtyState"
                        @valid-check="handleValidState"
                        @datatransfer="updateDataFromOrganization"
                    />
                </ContentWrapper>

                <ContentWrapper v-if="isFactoryContact">
                    <FactoryContact
                        v-model="factory"
                        :is-draft="isFactoryDraft"
                        :is-factory="isFactory"
                        :section-reset="formSectionReset"
                        :submitted="formSubmitted"
                        :is-read-only="isReadonly"
                        @confirmation-dialog="handleModal"
                        @dirty-check="handleDirtyState"
                        @valid-check="handleValidState"
                        @datatransfer="updateDataFromOrganization"
                    />
                </ContentWrapper>
                <ContentWrapper v-if="isFactoryOpeningHours">
                    <FactoryOpeningHours
                        v-model="factory"
                        :is-draft="isFactoryDraft"
                        :is-factory="isFactory"
                        :section-reset="formSectionReset"
                        :submitted="formSubmitted"
                        :is-read-only="isReadonly"
                        @confirmation-dialog="handleModal"
                        @dirty-check="handleDirtyState"
                        @valid-check="handleValidState"
                        @datatransfer="updateDataFromOrganization"
                    />
                </ContentWrapper>
                <ContentWrapper v-if="isFactoryVehicleRequirements">
                    <FactoryVehicleRequirements
                        v-model="factory"
                        :is-draft="isFactoryDraft"
                        :is-factory="isFactory"
                        :section-reset="formSectionReset"
                        :submitted="formSubmitted"
                        @confirmation-dialog="handleModal"
                        @dirty-check="handleDirtyState"
                        @valid-check="handleValidState"
                        @datatransfer="updateDataFromOrganization"
                    />
                </ContentWrapper>
                <ContentWrapper v-if="isFactoryWeighingTypes">
                    <FactoryWeighingTypes
                        v-model="factory"
                        :is-draft="isFactoryDraft"
                        :is-factory="isFactory"
                        :section-reset="formSectionReset"
                        :submitted="formSubmitted"
                        :features="factoryOrganization.features"
                        :usages="factory.usages"
                        :disposal-settings="factory.disposalSettings"
                        @confirmation-dialog="handleModal"
                        @dirty-check="handleDirtyState"
                        @valid-check="handleValidState"
                        @datatransfer="updateDataFromOrganization"
                    />
                </ContentWrapper>
                <ContentWrapper v-if="isFactoryPlatformSettings">
                    <FactoryPlatformSettings
                        v-model="factory"
                        :is-draft="isFactoryDraft"
                        :is-factory="isFactory"
                        :section-reset="formSectionReset"
                        :submitted="formSubmitted"
                        :features="factoryOrganization.features"
                        @confirmation-dialog="handleModal"
                        @dirty-check="handleDirtyState"
                        @valid-check="handleValidState"
                        @datatransfer="updateDataFromOrganization"
                    />
                </ContentWrapper>
            </template>

            <template #buttons>
                <ButtonGroupWrapper v-if="!isReadonly" items-right>
                    <i18n
                        path="components.transportListBlock.termsAndPrivacyLabel"
                        tag="span"
                        class="font-copy-xs px-4 text-subdued md:ml-auto"
                    >
                        <a
                            :href="linkLegalTermsAndConditionsSeller"
                            target="_blank"
                            class="font-copy-xs inline text-subdued underline"
                            place="gtc"
                            >{{ $t('components.transportListBlock.termsAndPrivacy.gtcLabel') }}</a
                        >
                        <a
                            :href="linkLegalDataProtectionPolicyGlobal"
                            target="_blank"
                            class="font-copy-xs inline text-subdued underline"
                            place="dpp"
                        >
                            {{ $t('components.transportListBlock.termsAndPrivacy.dppLabel') }}</a
                        >
                    </i18n>
                    <BaseButton
                        v-if="isDirty && $root.isDesktop"
                        arrow-right
                        data-test="location-view-button-discard"
                        @click="openDialogModalConfirmation('cancelEdit')"
                    >
                        {{ $t('components.factoryManagement.factoryView.discardChanges') }}
                    </BaseButton>

                    <BaseButton
                        v-if="isFactory"
                        primary
                        :disabled="!canSave"
                        data-test="location-view-button-save"
                        @click="handleFormDataSubmission()"
                        >{{ $t('components.factoryManagement.factoryView.save') }}</BaseButton
                    >

                    <BaseButton
                        v-if="isFactoryDraft"
                        primary
                        :disabled="!canSave"
                        data-test="location-view-button-saveAndContinue"
                        @click="handleFormDataSubmission()"
                        >{{ $t('components.factoryManagement.factoryView.saveAndContinue') }}</BaseButton
                    >
                </ButtonGroupWrapper>
            </template>
        </FactoryContainer>

        <DialogModal v-if="dialogModal.active" :options="dialogModal" @close="closeModal">
            <component
                :is="dialogModal.type"
                :factory="factory"
                @primaryClick="dialogModalFunctionCall({ modalType: dialogModal.type, buttonType: 'primary' }, $event)"
                @secondaryClick="
                    dialogModalFunctionCall({ modalType: dialogModal.type, buttonType: 'secondary' }, $event)
                "
                @toggleSwitch="onToggleFactoryState"
            />
        </DialogModal>
    </LayoutPage>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _camelCase from 'lodash/camelCase';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _merge from 'lodash/merge';
import _startCase from 'lodash/startCase';

import FactoryApi from '@/services/Api/Factory';
import FactoryDraftsApi from '@/services/Api/FactoryDrafts';
import Organization from '@/services/Api/Organization';

import { FACTORY_COMPONENTS, FACTORY_ROUTE_NAMES, FACTORY_MANAGEMENT_META_TYPES } from '@/constants/factoryForm';
import { FACTORY_TYPES, USAGES } from '@/constants/disposal';

import { weighingTransmissionTypes } from '@/constants/weighingTransmissionTypes';

import statefulMixin from '@/plugins/mixins/statefulMixin';
import factoryMixin from '@/plugins/mixins/factoryForm/factory';
import dialogModalMixin from '@/_components/DialogModal/mixins/dialogModal';

import subNavigationData from '@/views/LocationView/subNavigationData';

import SuccessfulCreation from '@/_components/DialogModal/modalContent/SuccessfulCreation';
import DeleteFactory from '@/_components/DialogModal/modalContent/DeleteFactory';
import CancelEdit from '@/_components/DialogModal/modalContent/CancelEdit';
import NotSaved from '@/_components/DialogModal/modalContent/NotSaved';
import NotSavedParentRoute from '@/_components/DialogModal/modalContent/NotSavedParentRoute';
import LocationTypeConfirmSelling from '@/_components/DialogModal/modalContent/LocationTypeConfirmSelling';
import LocationTypeConfirmDisposal from '@/_components/DialogModal/modalContent/LocationTypeConfirmDisposal';
import LocationTypeConfirmPlant from '@/_components/DialogModal/modalContent/LocationTypeConfirmPlant';
import LocationTypeConfirmLandfill from '@/_components/DialogModal/modalContent/LocationTypeConfirmLandfill';
import LocationTypeConfirmDumpingSite from '@/_components/DialogModal/modalContent/LocationTypeConfirmDumpingSite';
import LocationTypeConfirmCombined from '@/_components/DialogModal/modalContent/LocationTypeConfirmCombined';

import { SfLink, SfSysChevronLeftIcon } from '@schuettflix/vue-components';

import FactoryContainer from '@/_components/FactoryContainer/FactoryContainer';
import ContentWrapper from '@/_components/ContentWrapper/ContentWrapper';
import Arrow from '@/assets/icons/regular/arrow-right-bold.svg';
import ArrowLeft from '@/assets/icons/regular/arrow.svg';
import BaseButton from '@/components/Button/Button';
import ButtonGroupWrapper from '@/_components/ButtonGroupWrapper/ButtonGroupWrapper';
import DialogModal from '@/_components/DialogModal/DialogModal';
import FactoryContact from '@/views/FactoryContact/FactoryContact';
import FactoryLocationType from '@/views/FactoryLocationType/FactoryLocationType';
import FactoryNameAddress from '@/views/FactoryNameAddress/FactoryNameAddress';
import FactoryOpeningHours from '@/views/FactoryOpeningHours/FactoryOpeningHours';
import FactoryVehicleRequirements from '@/views/FactoryVehicleRequirements/FactoryVehicleRequirements';
import FactoryPlatformSettings from '@/views/FactoryPlatformSettings/FactoryPlatformSettings';
import FactoryWeighingTypes from '@/views/FactoryWeighingTypes/FactoryWeighingTypes';
import LayoutPage from '@/components/Layout/Page.v2';
import ProgressBar from '@/_components/ProgressBar/ProgressBar';
import SubNavigation from '@/_components/SubNavigation/SubNavigation';
import ToggleSwitch from '@/components/Form/ToggleSwitch';
import Words from '@/components/Typography/Words';
import { getEmptyStructure } from '@/views/LocationView/defaultData';

export default {
    name: 'LocationView',
    components: {
        ArrowLeft,
        SuccessfulCreation,
        DeleteFactory,
        CancelEdit,
        NotSaved,
        NotSavedParentRoute,
        LocationTypeConfirmSelling,
        LocationTypeConfirmDisposal,
        LocationTypeConfirmPlant,
        LocationTypeConfirmLandfill,
        LocationTypeConfirmDumpingSite,
        LocationTypeConfirmCombined,
        FactoryContainer,
        ContentWrapper,
        Arrow,
        BaseButton,
        ButtonGroupWrapper,
        DialogModal,
        FactoryContact,
        FactoryLocationType,
        FactoryNameAddress,
        FactoryOpeningHours,
        FactoryVehicleRequirements,
        FactoryPlatformSettings,
        FactoryWeighingTypes,
        LayoutPage,
        ProgressBar,
        SubNavigation,
        ToggleSwitch,
        Words,

        SfLink,
        SfSysChevronLeftIcon,
    },
    mixins: [statefulMixin, factoryMixin, dialogModalMixin],

    beforeRouteEnter(to, from, next) {
        /**
         * The user has been redirected here from the factory draft view.
         * At this point we reset the factory and factory backup and recalculate the navigation
         * Modal is set to true so that is displayed as well.
         */
        if (to?.query?.modal) {
            next(vm => {
                vm.factory = to.params.factory;
                vm.factoryBackup = to.params.factory;
                vm.setupFactoryForm();

                vm.dialogModal = {
                    type: 'successfulCreation',
                    active: true,
                };
            });
        } else {
            next();
        }
    },
    data() {
        return {
            subNavigationIsHidden: false,
            isFlyoutOpen: false,
            isLoading: true,
            dialogModal: {},
            formSectionReset: {},
            isDirty: false,
            formSubmitted: false,
            factory: getEmptyStructure(),
            factoryBackup: {},
            formIsValid: false,
            toggleSwitchUsed: false,
            formContentHasResized: null,
            nextRoute: {},
            subNavigationItems: [],
            currentSectionData: null,
            confirmationModal: null,
            sections: [
                'nameAddress',
                'locationType',
                'contact',
                'openingHours',
                'vehicleRequirements',
                'platformSettings',
            ],
            resetFields: [],
            factoryOrganization: null,
            uploadInProgress: false,
            parentRoute: '',
            formSections: {
                nameAddress: {
                    mandatoryFields: ['name', 'address', 'location'],
                    valid: true,
                },
                locationType: {
                    mandatoryFields: ['usages'],
                    valid: true,
                },
                contact: {
                    mandatoryFields: ['contact.phone', 'contact.email'],
                    valid: true,
                },
                openingHours: {
                    mandatoryFields: ['openingHours'],
                    valid: true,
                },
                vehicleRequirements: {
                    mandatoryFields: ['vehicleRequirements'],
                    valid: true,
                },
                weighingTypes: {
                    mandatoryFields: ['weighingTransmissionType', 'disposalWeighingTransmissionType'],
                    valid: true,
                },
                platformSettings: {
                    mandatoryFields: [''],
                    valid: true,
                },
            },
        };
    },
    computed: {
        ...mapGetters('user', {
            organization: 'organization',
            userInfo: 'info',
            isPlatformAdministrator: 'isPlatformAdministrator',
        }),
        ...mapGetters('platform', ['linkLegalDataProtectionPolicyGlobal', 'linkLegalTermsAndConditionsSeller']),
        defaultSubNav() {
            return subNavigationData.filter(
                subNavItem =>
                    this.$store.getters[subNavItem?.options?.restriction] !== false &&
                    (this.isFactoryDraft
                        ? subNavItem.to.query.route !== FACTORY_ROUTE_NAMES.PLATFORM_SETTINGS
                        : subNavItem)
            );
        },
        factoryName() {
            return this.factory ? this.factory.name : '';
        },
        factoryId() {
            return this.$route.params.factoryId;
        },
        factoryIsOpen() {
            return !!this.factory?.open;
        },
        parentRouteName() {
            return this.$route.params.parentRouteName || this.$t('pages.organization.factoryList.pageTitle');
        },
        currentComponentName() {
            return `Factory${_startCase(this.$route.query.route).replaceAll(' ', '')}`;
        },
        isEditMode() {
            return this.$route.name === this.$root.findRouteName('factoryEdit');
        },
        isFactoryNameAddress() {
            return this.currentComponentName === FACTORY_COMPONENTS.FACTORY_NAME_ADDRESS;
        },
        isFactoryLocationType() {
            return this.currentComponentName === FACTORY_COMPONENTS.FACTORY_LOCATION_TYPE;
        },
        isFactoryContact() {
            return this.currentComponentName === FACTORY_COMPONENTS.FACTORY_CONTACT;
        },
        isFactoryOpeningHours() {
            return this.currentComponentName === FACTORY_COMPONENTS.FACTORY_OPENING_HOURS;
        },
        isFactoryVehicleRequirements() {
            return this.currentComponentName === FACTORY_COMPONENTS.FACTORY_VEHICLE_REQUIREMENTS;
        },
        isFactoryPlatformSettings() {
            return this.currentComponentName === FACTORY_COMPONENTS.FACTORY_PLATFORM_SETTINGS;
        },
        isFactoryWeighingTypes() {
            return this.currentComponentName === FACTORY_COMPONENTS.FACTORY_WEIGHING_TYPES;
        },
        isFactoryComponent() {
            return [
                FACTORY_COMPONENTS.FACTORY_NAME_ADDRESS,
                FACTORY_COMPONENTS.FACTORY_LOCATION_TYPE,
                FACTORY_COMPONENTS.FACTORY_CONTACT,
                FACTORY_COMPONENTS.FACTORY_OPENING_HOURS,
                FACTORY_COMPONENTS.FACTORY_VEHICLE_REQUIREMENTS,
                FACTORY_COMPONENTS.FACTORY_PLATFORM_SETTINGS,
                FACTORY_COMPONENTS.FACTORY_WEIGHING_TYPES,
            ].includes(this.currentComponentName);
        },
        isReadonly() {
            return (
                this.$can('viewOrganization', this.organization) && !this.$can('updateOrganization', this.organization)
            );
        },
        allowDkChange() {
            return this.factoryBackup.disposalSettings.factoryType !== FACTORY_TYPES.LANDFILL;
        },
        canSave() {
            if (this.isFactoryOpeningHours && this.isFactoryDraft && !this.currentStepIsCompleted) {
                return this.formIsValid;
            }
            return this.isDirty && !this.uploadInProgress && this.formIsValid;
        },
        currentStepIsCompleted() {
            const currentStep = this.subNavigationItems.findIndex(
                obj => obj.to.query.route === this.$route.query.route
            );

            const item = this.subNavigationItems[currentStep];
            return item.optional || item.completed;
        },
        isFactoryDraft() {
            return this.$route.meta.type === FACTORY_MANAGEMENT_META_TYPES.FACTORY_DRAFT_EDIT;
        },
        isFactory() {
            return this.$route.meta.type === FACTORY_MANAGEMENT_META_TYPES.FACTORY_EDIT;
        },
        factoryContactData: {
            get: function () {
                return this.factory?.contact || this.factory;
            },
            set: function (contactData) {
                if (this.factory?.contact) {
                    this.factory.contact = contactData;
                } else {
                    this.factory = contactData;
                }
            },
        },
        showContextMenu() {
            return [this.$can('deleteFactory', this.factory)].some(item => item === true);
        },
    },
    watch: {
        isFactoryComponent() {
            if (!this.isFactoryComponent) {
                this.$router.push({
                    name: this.$root.findRouteName(FACTORY_ROUTE_NAMES.PARENT_ROUTE_NAME),
                });
            }
        },
    },
    async mounted() {
        this.isLoading = true;
        this.setNavigationCollapsed(true);
        this.configureSubNavigation();

        if (this.factoryId && this.$route.meta.type === FACTORY_MANAGEMENT_META_TYPES.FACTORY_EDIT) {
            if (this.factory && !_isEmpty(this.factory.factoryNumber)) {
                this.setupFactoryForm();
            } else {
                await this.fetchFactory();
            }
        } else if (this.factoryId && this.$route.meta.type === FACTORY_MANAGEMENT_META_TYPES.FACTORY_DRAFT_EDIT) {
            if (this.factory && !_isEmpty(this.factory.factoryNumber)) {
                this.updateCompleteSections();
            } else {
                await this.fetchFactoryDraft();
            }
        }

        await this.fetchOrganization();
        this.setParentRoute();
        this.setInitialTab();
        this.isLoading = false;
    },
    methods: {
        ...mapActions('session', ['setNavigationCollapsed']),
        ...mapActions('validation', { cleanupServerErrors: 'cleanup' }),
        ...mapActions('factoryManagement', ['setSupplierOrganizationId']),
        async fetchFactory() {
            this.factory = await FactoryApi.getOneById(this.factoryId);
            this.factoryBackup = _cloneDeep(this.factory);

            this.setSupplierOrganizationId(this.factory.supplierOrganizationId || this.factory.supplierOrganization.id);
            this.setupFactoryForm();
        },
        async fetchOrganization() {
            this.factoryOrganization = await Organization.getOneById(
                this.factory.supplierOrganizationId || this.factory.supplierOrganization.id
            );
        },
        async fetchFactoryDraft() {
            const response = await FactoryDraftsApi.getOneById(this.factoryId);

            const mergedData = _merge({}, getEmptyStructure(), response);

            this.factory = mergedData;
            this.factoryBackup = _cloneDeep(mergedData);

            this.setSupplierOrganizationId(this.factory.supplierOrganizationId || this.factory.supplierOrganization.id);
            this.setupFactoryForm();
        },
        handleModal(confirmationModal) {
            this.confirmationModal = confirmationModal;
        },
        handleDirtyState(isDirty) {
            this.cleanupServerErrors();
            this.isDirty = isDirty;
        },
        handleValidState(isValid) {
            this.formIsValid = isValid;
        },
        formReset() {
            this.formSectionReset = {
                name: this.currentComponentName,
            };
            this.formSubmitted = false;
        },
        updateCompleteSections() {
            this.sections.forEach(sectionName => {
                const validFields = [];
                this.formSections[sectionName]?.mandatoryFields?.forEach(field => {
                    if (field.includes('.')) {
                        const fieldSplit = field.split('.');
                        validFields.push(
                            _has(this.factory[fieldSplit[0]], fieldSplit[1]) &&
                                !_isEmpty(_get(this.factory[fieldSplit[0]], fieldSplit[1], null))
                        );
                    } else {
                        validFields.push(_has(this.factory, field) && !_isEmpty(_get(this.factory, field, null)));
                    }
                });
                if (this.formSections[sectionName]) {
                    this.formSections[sectionName].valid = !validFields.includes(false);
                }
            });

            this.updateSubNavigation();
        },
        configureSubNavigation() {
            this.subNavigationItems.map(subNavItem => {
                subNavItem.to.name = this.$route.meta.type;
            });
        },
        updateSubNavigation() {
            this.subNavigationItems = _cloneDeep(this.defaultSubNav).map(subNavItem => {
                const routeName = _camelCase(subNavItem.to.query.route);
                subNavItem.completed = !!this.formSections[routeName]?.valid;
                return subNavItem;
            });
        },
        setupFactoryForm() {
            this.updateCompleteSections();
        },
        setParentRoute() {
            const routeName = this.isPlatformAdministrator
                ? FACTORY_ROUTE_NAMES.PARENT_ADMIN_ROUTE_NAME
                : FACTORY_ROUTE_NAMES.PARENT_ROUTE_NAME;

            this.parentRoute = {
                name: this.$root.findRouteName(routeName),
                params: {
                    supplierOrganizationId: this.factory?.supplierOrganizationId,
                },
            };
        },
        async onToggleFactoryState() {
            this.toggleOpenCloseStatus(this.factory.id).then(result => {
                this.$set(this.factory, 'open', result.open);
                this.$set(this.factoryBackup, 'open', result.open);
            });
        },
        openDialogModalConfirmation(type) {
            this.dialogModal = {
                type,
                closeWithCross: true,
                active: true,
            };
        },
        changeRouteUsingQueryString(routerChange) {
            if (routerChange.query.route === this.$route.query.route) {
                return;
            }

            this.nextRoute = {
                ...routerChange,
                name: this.$root.findRouteName(routerChange.name),
            };
            if (this.isDirty) {
                this.dialogModal = {
                    type: 'notSaved',
                    closeWithCross: true,
                    active: true,
                };
            } else {
                this.$router
                    .push({
                        ...this.nextRoute,
                    })
                    .then(() => this.toggleSubNavigation());
            }
        },
        goBackToListView() {
            this.setNavigationCollapsed(false);
            if (this.isDirty) {
                this.dialogModal = {
                    type: 'notSavedParentRoute',
                    closeWithCross: true,
                    active: true,
                };
            } else {
                this.$router.push(this.parentRoute);
            }
        },

        /**
         * Gets called on mounted. If we are in draft mode then this is executed to determine
         * the next incomplete tab to be active/visible
         */
        setInitialTab() {
            if (this.$route.meta.type === FACTORY_MANAGEMENT_META_TYPES.FACTORY_EDIT) {
                return;
            }

            const incompleteTabs = [];
            this.subNavigationItems.forEach(tab => {
                if (!tab.completed) {
                    incompleteTabs.push(tab);
                }
            });

            // We're only interested in the first item in the list as that's the "next available tab"
            const tabToShow = incompleteTabs.length > 0 ? incompleteTabs[0] : this.subNavigationItems[0];

            if (this.$route.query.route === tabToShow?.to?.query?.route) {
                return;
            }

            this.$router.replace({
                query: {
                    route: tabToShow?.to?.query?.route,
                },
            });
        },
        async handleFormDataSubmission() {
            if (!this.canSave) {
                return;
            }

            this.formSubmitted = true;

            if (!this.formIsValid) {
                return;
            }

            // If the form needs a dialogModal to show, then display
            if (this.confirmationModal) {
                this.dialogModal = {
                    type: this.confirmationModal,
                    active: true,
                    closeWithCross: true,
                };

                return;
            }

            if (await this.saveFactory(this.prepareForSave(this.factory), this.currentStepIsCompleted)) {
                if (this.factory.completionPercentage !== 100) {
                    // Tick our current section
                    await this.completedCurrentSection();
                    // Move on to the next section, if one is available
                    this.changeSelectedSection();
                } else {
                    // send request to publish endpoint
                    const publishedFactory = await this.publishDraftFactory(this.factory.id);

                    // when factory is returned, redirect to completed factory with ID
                    if (publishedFactory) {
                        this.$router.replace({
                            name: this.$root.findRouteName('factoryEdit'),
                            params: {
                                factoryId: publishedFactory.id,
                                factory: publishedFactory,
                            },
                            query: {
                                route: 'name-address',
                                modal: true,
                            },
                        });
                    }
                }
            }
        },
        prepareForSave(factory) {
            const data = _cloneDeep(factory);

            const hasDisposal = Array.isArray(data.usages) && data.usages.includes(USAGES.DISPOSAL);

            if (!hasDisposal) {
                delete data.disposalSettings;
                delete data.disposalPrincipleDocument;
                delete data.disposerNumber;
            }

            if (
                hasDisposal &&
                data.disposalSettings.factoryType !== FACTORY_TYPES.LANDFILL &&
                data.disposalSettings.landfillClass
            ) {
                delete data.disposalSettings.landfillClass;
            }

            if (hasDisposal && data.disposalSettings.factoryType === FACTORY_TYPES.DUMPING_SITE) {
                delete data.disposerNumber;
            }

            if (hasDisposal && data.disposalWeighingTransmissionType !== weighingTransmissionTypes.MANUAL) {
                delete data.disposalWeighingMethod;
            }

            return data;
        },
        async completedCurrentSection() {
            const index = this.defaultSubNav.findIndex(obj => obj.to.query.route === this.$route.query.route);
            await this.$set(this.subNavigationItems[index], 'completed', true);
        },
        changeSelectedSection() {
            if (this.isFactory) {
                this.resetFormData();
                return;
            }
            const index = this.defaultSubNav.findIndex(obj => obj.to.query.route === this.$route.query.route);
            const nextIndex = index + 1;
            if (typeof this.defaultSubNav[nextIndex] !== 'undefined') {
                // Change the route to the new query string
                this.$router.replace({
                    query: { route: this.defaultSubNav[nextIndex].to.query.route },
                    params: {
                        ...this.$route.params,
                    },
                });
            }

            this.resetFormData();
        },
        resetFormData() {
            this.isDirty = false;
            this.formIsValid = false;
            this.formSubmitted = false;
            this.confirmationModal = null;
        },
        toggleSubNavigation() {
            this.subNavigationIsHidden = !this.subNavigationIsHidden;
        },
    },
};
</script>

<style lang="scss" scoped>
.back-link {
    align-items: center;
    display: flex;

    svg {
        transform: rotate(180deg);
        margin-right: 5px;
        position: relative;
        top: 2px;
    }
}

.factory-view {
    &__page-title {
        display: flex;
        align-items: center;
        gap: 16px;
    }
    &__wrapper {
        background-color: $color-base-grey;
        display: flex;
        width: 100%;
        height: 100%;
    }

    &__container {
        max-width: 720px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    &__forms {
        align-items: center;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding-left: 320px;
        width: 100%;
    }

    &__status {
        display: flex;
        align-items: center;
        margin-top: 10px;

        p {
            margin-left: 8px;
        }
    }

    &__button-group {
        max-width: 760px;
        width: 100%;

        &.content-overflow {
            margin-left: -15px;
        }

        button {
            margin-left: 40px;

            &.button--default {
                color: $color-darkGrey;
            }
        }

        .button-group__shadow {
            height: 13px;
            left: 20px;
            right: 20px;
            background: linear-gradient(
                to bottom,
                rgba(53, 52, 61, 0) 0%,
                rgba(53, 52, 61, 0.01) 2%,
                rgba(53, 52, 61, 0.64) 98%,
                rgba(53, 52, 61, 0.65) 100%
            );
            position: absolute;
            top: -13px;
            opacity: 10%;
        }
    }
}
</style>

import _omit from 'lodash/omit';
import AbstractFilterableResource, { clearNullableValues } from '@/services/Api/AbstractFilterableResource';
import { PROJECT_POSITION_TYPE_DANGEROUS_WASTE, PROJECT_POSITION_TYPE_WASTE } from '@/constants/projectPositionTypes';

export class ProjectPosition extends AbstractFilterableResource {
    /**
     * Close Project Position
     *
     * @param {number} projectPositionId
     * @returns {Promise<*>}
     */
    async close(projectPositionId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${projectPositionId}/close`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Update Project Position Status
     *
     * @returns {Promise<*>}
     */
    async updateStatus(projectPositionId, updatedStatus) {
        try {
            const response = await this.apiService.put(
                `${this.resourcePath}/${projectPositionId}/status/${updatedStatus}`
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Update Project Position note
     *
     * @param {number} projectPositionId
     * @param {string|null} note
     * @returns {Promise<*>}
     */
    async updateNote(projectPositionId, note) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${projectPositionId}/update-note`, {
                note: note,
            });

            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Create Project Position
     *
     * @returns {Promise<*>}
     */
    async createProjectPosition(payload) {
        try {
            const clearedPayload = clearNullableValues(payload);
            const response = await this.apiService.post(this.resourcePath, clearedPayload);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Update Project Position
     *
     * @param {number} id id of the resource to update
     * @param {Object} payload object data to update
     * @returns {Promise<*>}
     */
    async updateProjectPosition(id, payload) {
        try {
            const clearedPayload = clearNullableValues(payload);

            const response = await this.apiService.post(`${this.resourcePath}/${id}`, clearedPayload);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Transform Project Position DTO to api format, needed for waste project position format
     *
     * @param {Object} payload object data to transform
     * @returns {Object}
     */
    async transformProjectPosition(payload) {
        if (![PROJECT_POSITION_TYPE_WASTE, PROJECT_POSITION_TYPE_DANGEROUS_WASTE].includes(payload.type)) {
            return payload;
        }

        const { data: responseVariants } = await this.apiService.get(
            `order/factory/${payload.factory.id}/product/${payload.product.id}/waste/variants`
        );

        const factoryProductVariantId = responseVariants.items.find(
            ({ pollutionTypeId }) => pollutionTypeId === payload.productVariant.pollutionType.id
        ).id;

        const transformedPayload = _omit(
            {
                ...payload,
                analysis: {
                    exemption: payload.exemption,
                },
                carrierNumber: payload.carrier?.carrierNumber,
                factoryProductVariantId,
                vehicleClasses: payload.vehicleClasses.map(t => ({
                    vehicleClass: { id: t.vehicleClass?.id },
                    purchasePrice: t.purchasePrice,
                    retailPrice: t.retailPrice,
                })),
            },
            [
                'factory',
                'reservedQty',
                'created',
                'createdBy',
                'updated',
                'product',
                'exemption',
                'productVariant',
                'disposerName',
                'number',
                'deliveredQty',
                'lineItemGroupCount',
                ...(payload.type === PROJECT_POSITION_TYPE_DANGEROUS_WASTE ? ['analysis'] : []),
            ]
        );

        return clearNullableValues(transformedPayload);
    }
}

export default new ProjectPosition('/project-position');

<template>
    <ProjectContingentSelectionPage
        :maximum-qty="maximumQty"
        :project-position-id="projectPositionId"
        :weight="savedQty"
        :basket-product="basketProduct"
        :basket-type="basketType"
        @updateWeightInStore="updateWeightInStore"
        @projectPositionSaved="$emit('projectPositionSaved', $event)"
        @close="$emit('close', $event)"
    />
</template>

<script>
import GlobalVariables from '@/services/GlobalVariables';
import ProjectContingentSelectionPage from '@/pages/Checkout/ProjectContingentSelectionPage';
import { mapState } from 'vuex';

export default {
    name: 'ProjectContingentSelectionPageMaterial',

    components: { ProjectContingentSelectionPage },

    props: {
        projectPositionId: {
            type: [Number, String],
            default: null,
        },
        basketTypeProp: {
            type: String,
            default: null,
        },
    },

    data: () => ({
        maximumQty: null,
        savedQty: null,
    }),

    computed: {
        ...mapState('basket', {
            basketProduct: 'product',
            basketQuantity: 'qty',
            type: 'type',
        }),
        basketType() {
            return this.basketTypeProp || this.type;
        },
    },

    async created() {
        this.maximumQty = await GlobalVariables.get('order_method_project_maximum_order_qty', 0);

        if (null === this.basketQuantity) return;
        this.savedQty = this.basketQuantity / 1000;
    },

    methods: {
        updateWeightInStore(weightInKg) {
            this.$store.commit('basket/setQty', weightInKg);
        },
    },
};
</script>

<template>
    <Button
        :class="[
            {
                'sorting-direction--dark': dark,
                'sorting-direction--small': small,
            },
        ]"
        class="sorting-direction"
        @click="toggleDirection"
    >
        <ArrowUpIcon
            :class="[{ 'sorting-direction__arrow--active': value === 'asc' }]"
            class="sorting-direction__arrow sorting-direction__arrow--up"
        />
        <ArrowUpIcon
            :class="[{ 'sorting-direction__arrow--active': value === 'desc' }]"
            class="sorting-direction__arrow sorting-direction__arrow--down"
        />
    </Button>
</template>

<script>
import Button from '@/components/Button/Button';

import ArrowUpIcon from '@/assets/icons/regular/arrow-stripeless--up.svg';

export default {
    name: 'SortingDirection',
    components: {
        ArrowUpIcon,
        Button,
    },
    props: {
        value: {
            type: String,
            default: 'asc',
            validator: value => ['asc', 'desc'].indexOf(value) !== -1,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            eid: `el${this._uid}`,
        };
    },
    methods: {
        toggleDirection() {
            this.$emit('input', this.value === 'asc' ? 'desc' : 'asc');
        },
    },
};
</script>

<style lang="scss">
.sorting-direction {
    position: relative;
    padding: 26px 20px;
    width: 54px;
    height: 70px;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    background-color: $color-white;

    &:hover,
    &:focus,
    &:active {
        opacity: 1;
    }
}

.sorting-direction--dark {
    background-color: $color-lightGrey;
}

.sorting-direction__arrow {
    position: absolute;
    left: 20px;
    width: 14px;
    height: 14px;
    opacity: 0.4;
    cursor: pointer;
}

.sorting-direction__arrow--active {
    opacity: 1;
}

.sorting-direction__arrow--up {
    top: 20px;
}

.sorting-direction__arrow--down {
    bottom: 20px;
    transform: rotate(180deg);
}

.sorting-direction--small {
    height: 50px;
    padding: 16px 20px;

    .sorting-direction__arrow--up {
        top: 10px;
    }

    .sorting-direction__arrow--down {
        bottom: 10px;
    }
}
</style>

<template>
    <div
        :class="[
            `button-group--mobile-bp-${mobileBreakpoint}`,
            {
                'button-group--vertical': vertical,
                'button-group--floating': floating,
                'button-group--inline': inline || floating,
                'button-group--transparent': transparent,
                'button-group--bordered': bordered,
                'button-group--children-spaced': childrenSpaced,
                'button-group--spacing-large': spacingLarge,
                'flex w-full flex-col md:flex-row': isCssFlex,
            },
        ]"
        class="button-group"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ButtonGroup',
    props: {
        vertical: {
            type: Boolean,
            default: false,
        },
        floating: {
            type: Boolean,
            default: false,
        },
        inline: {
            type: Boolean,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        bordered: {
            type: Boolean,
            default: false,
        },
        childrenSpaced: {
            type: Boolean,
            default: false,
        },
        spacingLarge: {
            type: Boolean,
            default: false,
        },
        mobileBreakpoint: {
            type: String,
            default: 'default',
            validator: v => ['default', 'component-library'].includes(v),
        },
        isCssFlex: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;

    > * {
        width: 100%;
        text-align: center;
    }

    > *:not(:first-child) {
        margin-left: 1px;
    }

    &--mobile-bp-default {
        @media screen and (min-width: $layout-desktop-min) {
            padding: 20px 24px;
            justify-content: flex-end;

            > * {
                width: auto;
            }
        }

        &.button-group--spacing-large {
            @media screen and (min-width: $layout-desktop-min) {
                padding: 16px 24px 24px;
                justify-content: flex-end;

                > * {
                    width: auto;
                }
            }
        }
    }

    &--mobile-bp-component-library {
        @media screen and (min-width: $screen-md) {
            padding: 20px 24px;
            justify-content: flex-end;

            > * {
                width: auto;
            }
        }

        &.button-group--spacing-large {
            @media screen and (min-width: $screen-md) {
                padding: 16px 24px 24px;
                justify-content: flex-end;

                > * {
                    width: auto;
                }
            }
        }
    }

    &.button-group--bordered {
        border-top: 1px solid $color-mediumGrey;

        &:not(.button-group--vertical) > *:not(:first-child) {
            border-left: 1px solid $color-mediumGrey;
        }

        &.button-group--vertical {
            border-top: none;
        }
    }
}

.button-group--transparent {
    background-color: transparent;
}

.button-group--vertical {
    flex-direction: column;

    > *:not(:first-child) {
        margin-top: 1px;
        margin-left: 0;
    }

    &.button-group--bordered {
        > * {
            border-top: 1px solid $color-mediumGrey;
        }
    }
}

.button-group--floating {
    margin: 20px;
    box-shadow: $boxShadow-bottom;
}

.button-group--inline {
    background-color: transparent;
    padding: 0 !important;

    &.button-group--vertical {
        background-color: $color-white;
    }
}

.button-group--children-spaced {
    button {
        &:first-of-type {
            margin-right: 20px;
        }

        &:last-of-type {
            margin-left: 20px;
        }
    }
}
</style>

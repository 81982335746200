<template>
    <div>
        <div>
            <div class="mb-8 pr-1">
                <span class="font-copy-sm-strong mb-6">
                    {{ $t('pages.order.orderView.offerAndInvoiceTab.offer') }}
                </span>
            </div>

            <div :class="!isPlatform && data.isBaseTypeShipment ? null : 'mb-6'">
                <div :class="gridRowClasses">
                    <span class="font-copy-md-strong">
                        {{
                            $t(
                                {
                                    credit_note: selectCreditNoteDocKey({
                                        creditNote: `pages.order.orderView.offerAndInvoiceTab.invoice_correction.creditNote`,
                                        selfBilling: `pages.order.orderView.offerAndInvoiceTab.invoice_correction.selfBilling`,
                                    }),
                                }[data.type] || `pages.order.orderView.offerAndInvoiceTab.${data.type}`
                            )
                        }}
                    </span>

                    <span v-if="hasAction" class="col-start-3 cursor-pointer justify-self-end" @click="$emit('action')">
                        <ArrowEditIcon width="10" height="14" />
                    </span>

                    <span
                        v-else-if="
                            !data.isBaseTypeShipment &&
                            !data.isTypeProjectPositionDelivery &&
                            !data.isTypeProjectPositionDisposal
                        "
                        class="col-start-3 cursor-pointer justify-self-end"
                        @click="openModal('offerBlockcostsInfo')"
                    >
                        <SfInfoIcon multi-color size="xs" />
                    </span>
                </div>
            </div>

            <!-- START: PA - order details for all orders -->
            <div v-if="isPlatform">
                <!-- Order details: delivery, pickup, disposal -->
                <template v-if="!data.isBaseTypeShipment">
                    <LineItem has-bold-label>
                        <template #label>
                            <span v-if="!data.isTypeProjectPositionDisposal">
                                {{ data.qty | tons }}
                                {{ data.productNameLocalized }}
                            </span>
                            <span v-else>
                                {{ data.qty | tons }}
                                {{ data.product.wasteCode }}<span v-if="data.product.isHazardousWaste">*</span>,
                                {{ pollutionType }}
                            </span>
                        </template>
                    </LineItem>

                    <LineItem v-if="!data.isTypeProjectPositionDisposal">
                        <template #label>
                            {{ priceTypeTranslation(PURCHASE) }}
                        </template>
                        <template #endSection>
                            {{ $n(data.purchaseUnitPrice, 'currency') }} /
                            {{ getTranslationForBillingMethodUnit(WEIGHT) }}
                        </template>
                    </LineItem>
                    <LineItem v-if="data.isTypeProjectPositionDisposal">
                        <template #label>
                            {{ priceTypeTranslation(PURCHASE) }}
                        </template>
                        <template #middleSection>
                            <!-- here -->
                            {{ productPrice(PURCHASE) }} /
                            {{ getTranslationForBillingMethodUnit(data.billingMethod) }}
                        </template>
                        <template #endSection>
                            {{ $n(data.totalPurchaseMaterialTransportPrice, 'currency') }}
                        </template>
                    </LineItem>

                    <LineItem v-if="!data.isTypeProjectPositionDisposal">
                        <template #label>
                            {{ priceTypeTranslation(RETAIL) }}
                        </template>
                        <template #endSection>
                            {{ $n(data.unitPrice, 'currency') }} / {{ getTranslationForBillingMethodUnit(WEIGHT) }}
                        </template>
                    </LineItem>

                    <!-- START: Disposal retail price -->
                    <LineItem
                        v-if="data.isTypeProjectPositionDisposal && !!!projectPosition.billingByStateWasteCompany"
                    >
                        <template #label>
                            {{ priceTypeTranslation(RETAIL) }}
                        </template>
                        <template #middleSection>
                            <!-- here -->
                            {{ productPrice(RETAIL) }} / {{ getTranslationForBillingMethodUnit(data.billingMethod) }}
                        </template>
                        <template #endSection>
                            {{ $n(data.totalRetailMaterialTransportPrice, 'currency') }}
                        </template>
                    </LineItem>
                    <!-- END: Disposal retail price -->

                    <!-- START: Disposal retail price INCLUDING state waste company fee -->
                    <template v-if="data.isTypeProjectPositionDisposal && !!projectPosition.billingByStateWasteCompany">
                        <LineItem>
                            <template #label>{{ priceTypeTranslation(RETAIL) }}</template>
                            <template #middleSection>
                                {{
                                    $n(
                                        (projectPosition.materialRetailUnitPrice * data.qty +
                                            data.transportRetailPrice) /
                                            (data.qty / 1000),
                                        'currency'
                                    )
                                }}
                                /
                                {{ getTranslationForBillingMethodUnit(WEIGHT) }}
                            </template>
                            <template #endSection>
                                {{
                                    $n(
                                        projectPosition.materialRetailUnitPrice * data.qty + data.transportRetailPrice,
                                        'currency'
                                    )
                                }}
                            </template>
                        </LineItem>
                        <LineItem>
                            <template #label>
                                {{ $t('pages.checkout.priceAdjustments.retailPriceIncludingStateWasteCompanyFee') }}
                            </template>
                            <template #middleSection>
                                {{ $n(data.unitPrice, 'currency') }}
                                /
                                {{ getTranslationForBillingMethodUnit(WEIGHT) }}
                            </template>
                            <template #endSection>
                                {{ $n(data.totalRetailMaterialTransportPrice, 'currency') }}
                            </template>
                        </LineItem>
                    </template>
                    <!-- END: Disposal retail price INCLUDING state waste company fee-->
                </template>

                <!-- Sales & retail price per vehicle class for delivery, forwarding, pickup (where this section is empty) -->
                <template v-if="!data.isTypeProjectPositionDisposal">
                    <div v-for="vehicleClass in data.groupedVehicleClasses" :key="vehicleClass.classId" class="mt-6">
                        <div class="mb-2" :class="gridRowClasses">
                            <span class="font-copy-md-strong col-start-1">
                                {{ vehicleClass.count }} &times; {{ vehicleClass.name }}
                            </span>
                        </div>
                        <div class="mb-2" :class="gridRowClasses">
                            <span class="font-copy-md col-start-1">
                                {{ priceTypeTranslation(PURCHASE) }}
                            </span>
                            <span class="font-copy-md flex items-center justify-self-end whitespace-nowrap">
                                <template v-if="data.billingMethod === 'fixedPrice'">
                                    {{ $n(vehicleClass.purchasePrice.perTransport, 'currency') }} /
                                    {{ getTranslationForBillingMethodUnit(FIXED_PRICE) }} &nbsp;
                                    <PopUp position="left">
                                        <SfInfoIcon multi-color size="xs" />
                                        <template #content>
                                            <span class="font-copy-md whitespace-nowrap">
                                                {{ $n(vehicleClass.purchasePrice.perTransport, 'currency') }} /
                                                {{ getTranslationForBillingMethodUnit(FIXED_PRICE) }}
                                            </span>
                                            <span class="font-copy-md whitespace-nowrap">
                                                {{ $n(vehicleClass.purchasePrice.perKm, 'currency') }} /
                                                {{ $t(`units.kilometer`) }}
                                            </span>
                                            <span class="font-copy-md whitespace-nowrap">
                                                {{ $n(vehicleClass.purchasePrice.perTon, 'currency') }}
                                                / {{ getTranslationForBillingMethodUnit(WEIGHT) }}
                                            </span>
                                        </template>
                                    </PopUp>
                                </template>
                                <template v-else>
                                    {{ $n(vehicleClass.purchaseUnitPrice, 'currency') }} /
                                    {{
                                        data.billingMethod === 'weight'
                                            ? getTranslationForBillingMethodUnit(WEIGHT)
                                            : $t(`units.${data.isTypeShipment ? 'kilometer' : 'ton'}`)
                                    }}
                                </template>
                            </span>
                            <span class="font-copy-md col-start-3 justify-self-end">
                                {{ $n(vehicleClass.purchaseTotalPrice, 'currency') }}
                            </span>
                        </div>
                        <div :class="gridRowClasses">
                            <span class="font-copy-md col-start-1">
                                {{ priceTypeTranslation(RETAIL) }}
                            </span>
                            <span class="font-copy-md flex items-center justify-self-end whitespace-nowrap">
                                <template v-if="data.billingMethod === 'fixedPrice'">
                                    {{ $n(vehicleClass.retailPrice.perTransport, 'currency') }} /
                                    {{ getTranslationForBillingMethodUnit(FIXED_PRICE) }} &nbsp;
                                    <PopUp position="left">
                                        <SfInfoIcon multi-color size="xs" />
                                        <template #content>
                                            <span class="font-copy-md whitespace-nowrap">
                                                {{ $n(vehicleClass.retailPrice.perTransport, 'currency') }}
                                                / {{ getTranslationForBillingMethodUnit(FIXED_PRICE) }}
                                            </span>
                                            <span class="font-copy-md whitespace-nowrap">
                                                {{ $n(vehicleClass.retailPrice.perKm, 'currency') }} /
                                                {{ $t(`units.kilometer`) }}
                                            </span>
                                            <span class="font-copy-md whitespace-nowrap">
                                                {{ $n(vehicleClass.retailPrice.perTon, 'currency') }}
                                                / {{ getTranslationForBillingMethodUnit(WEIGHT) }}
                                            </span>
                                        </template>
                                    </PopUp>
                                </template>
                                <template v-else>
                                    {{ $n(vehicleClass.unitPrice, 'currency') }} /
                                    {{
                                        data.billingMethod === 'weight'
                                            ? getTranslationForBillingMethodUnit(WEIGHT)
                                            : $t(`units.${data.isTypeShipment ? 'kilometer' : 'ton'}`)
                                    }}
                                </template>
                            </span>
                            <span class="font-copy-md col-start-3 justify-self-end">
                                {{ $n(vehicleClass.price, 'currency') }}
                            </span>
                        </div>
                    </div>
                </template>

                <!-- Additional disposal positions -->
                <template v-if="data.isTypeProjectPositionDisposal">
                    <CustomPositionOverviewSection
                        v-if="hasCustomPosition"
                        class="mt-12"
                        :custom-positions="data.customPositions"
                        :no-of-transports="data.totalTransports"
                        :use-abbreviated-titles="false"
                    />
                </template>
            </div>
            <!-- END: PA - order details for all orders -->

            <!-- START: Client order & project order summary page -->
            <div v-else>
                <template v-if="!data.isBaseTypeShipment">
                    <div class="grid grid-cols-[1fr,80px,90px] items-center gap-x-0 md:grid-cols-3">
                        <span v-if="!data.isTypeProjectPositionDisposal" class="font-copy-md-strong col-span-2">
                            {{ data.qty | tons }}
                            {{ data.productNameLocalized }}
                        </span>
                        <span v-else class="font-copy-md-strong col-span-1">
                            {{ data.qty | tons }}
                            {{ data.product.wasteCode }}<span v-if="data.product.isHazardousWaste">*</span>
                            {{ pollutionType }}
                        </span>
                        <span
                            :class="{
                                'col-start-3 justify-self-end font-bold': !data.isTypeProjectPositionDisposal,
                                'col-span-1 text-right': data.isTypeProjectPositionDisposal,
                            }"
                        >
                            <span v-if="!data.hasEqualPrices">&oslash;</span>
                            {{ productPrice(RETAIL, false) }}
                            / {{ getTranslationForBillingMethodUnit(data.billingMethod) }}
                        </span>
                        <span v-if="data.isTypeProjectPositionDisposal" class="font-copy-md col-span-1 text-right">
                            {{ $n(data.initialRetailPrice, 'currency') }}
                        </span>
                    </div>
                </template>
                <div
                    v-for="vehicleClass in data.groupedVehicleClasses"
                    :key="vehicleClass.classId"
                    class="mt-2 flex items-center"
                    :class="gridRowClasses"
                >
                    <span
                        class="font-copy-md"
                        :class="{
                            'col-span-3': data.isTypeProjectPositionDisposal,
                            'col-span-1': !data.isTypeProjectPositionDisposal,
                        }"
                    >
                        {{ vehicleClass.count }} &times; {{ vehicleClass.name }}
                    </span>
                    <template
                        v-if="data.type !== 'project-position-waste' && data.type !== 'project-position-dangerousWaste'"
                    >
                        <div class="flex items-center justify-self-end">
                            <span v-if="!vehicleClass.isEqual">&oslash;</span>
                            <template v-if="data.billingMethod === 'fixedPrice'">
                                <span class="font-copy-md">
                                    {{ $n(vehicleClass.retailPrice.perTransport, 'currency') }} /
                                    {{ $t('units.transport') }} &nbsp;
                                </span>
                            </template>
                            <template v-else>
                                <span class="font-copy-md">
                                    {{ $n(vehicleClass.unitPrice, 'currency') }} /
                                    {{ getTranslationForBillingMethodUnit(WEIGHT) }}
                                </span>
                            </template>
                            <template v-if="data.isBaseTypeShipment && data.billingMethod === 'fixedPrice'">
                                <PopUp position="left">
                                    <SfInfoIcon multi-color size="xs" />
                                    <template #content>
                                        <span class="font-copy-sm whitespace-nowrap">
                                            {{ $n(vehicleClass.retailPrice.perTransport, 'currency') }}
                                            / {{ $t('units.transport') }}
                                        </span>
                                        <span class="font-copy-sm whitespace-nowrap">
                                            {{ $n(vehicleClass.retailPrice.perKm, 'currency') }} /
                                            {{ $t(`units.kilometer`) }}
                                        </span>
                                        <span class="font-copy-sm whitespace-nowrap">
                                            {{ $n(vehicleClass.retailPrice.perTon, 'currency') }}
                                            / {{ getTranslationForBillingMethodUnit(WEIGHT) }}
                                        </span>
                                    </template>
                                </PopUp>
                            </template>
                        </div>
                    </template>
                    <span
                        v-if="data.type !== 'project-position-waste' && data.type !== 'project-position-dangerousWaste'"
                        class="font-copy-md col-start-3 justify-self-end"
                    >
                        {{ $n(vehicleClass.price, 'currency') }}
                    </span>
                </div>
            </div>
            <!-- END: Client order & project order summary page -->

            <!-- START: PA - additional order costs for custom delivery & custom shipment orders -->
            <!-- @TODO: refactor to always include additional costs and shipping costs in custom positions -->
            <div v-if="isPlatform && hasAdditionalCustomOrderPositions" class="mt-12">
                <LineItem has-bold-label>
                    <template #label>
                        <!-- additional cost: custom delivery order
                             custom shipment order - shipping costs
                         -->
                        {{
                            data.isBaseTypeShipment
                                ? $t('pages.checkout.summary.costTable.additionalCost')
                                : $t('pages.checkout.summary.costTable.shippingCost')
                        }}
                    </template>
                </LineItem>
                <LineItem class="mt-6">
                    <template #label>
                        {{ priceTypeTranslation(PURCHASE) }}
                    </template>
                    <template #endSection>
                        {{ $n(data.shippingPurchasePrice, 'currency') }}
                    </template>
                </LineItem>
                <LineItem>
                    <template #label>
                        {{ priceTypeTranslation(RETAIL) }}
                    </template>
                    <template #endSection>
                        {{ $n(data.shippingRetailPrice, 'currency') }}
                    </template>
                </LineItem>
            </div>
            <!-- END: PA - additional order details for sales & retail price of additional shipment positions -->

            <!-- START: Client - additional order details for retail price of additional shipment positions -->
            <div v-else-if="hasAdditionalCustomOrderPositionRetailPrice" class="my-12">
                <div :class="gridRowClasses">
                    <span class="font-copy-md-strong col-start-1">
                        {{
                            data.isBaseTypeShipment
                                ? $t('pages.checkout.summary.costTable.additionalCost')
                                : $t('pages.checkout.summary.costTable.shippingCost')
                        }}
                    </span>

                    <span class="font-copy-md col-start-3 justify-self-end">
                        {{ $n(data.shippingRetailPrice, 'currency') }}
                    </span>
                </div>
            </div>
            <!-- END: Client - additional order details for retail price of additional shipment positions -->

            <!-- START: PA - Totals section -->
            <template v-if="isPlatform">
                <div class="mt-12 bg p-2">
                    <LineItem has-bold-label>
                        <template #label>
                            {{ $t('pages.checkout.summary.costTable.totalNet') }}
                        </template>
                    </LineItem>
                    <LineItem>
                        <template #label>
                            {{ priceTypeTranslation(PURCHASE) }}
                        </template>
                        <template #endSection>
                            {{ $n(data.totalPurchasePrice, 'currency') }}
                        </template>
                    </LineItem>
                    <LineItem>
                        <template #label>
                            {{ priceTypeTranslation(RETAIL) }}
                        </template>
                        <template #endSection>
                            {{ $n(data.totalRetailPrice, 'currency') }}
                        </template>
                    </LineItem>
                </div>

                <div v-if="data.taxRate" class="mt-1 bg p-2">
                    <LineItem has-bold-label>
                        <template #label>
                            {{
                                $t('pages.checkout.summary.costTable.vat', {
                                    vat: data.taxRate,
                                })
                            }}
                        </template>
                    </LineItem>
                    <LineItem>
                        <template #label>
                            {{ priceTypeTranslation(PURCHASE) }}
                        </template>
                        <template #endSection>
                            {{ $n(data.totalPurchasePrice * (data.taxRate / 100), 'currency') }}
                        </template>
                    </LineItem>
                    <LineItem>
                        <template #label>
                            {{ priceTypeTranslation(RETAIL) }}
                        </template>
                        <template #endSection>
                            {{ $n(data.totalGrossRetailPrice - data.totalRetailPrice, 'currency') }}
                        </template>
                    </LineItem>
                </div>

                <div class="mt-1 bg p-2">
                    <LineItem has-bold-label>
                        <template #label>
                            {{ $t('pages.checkout.summary.costTable.totalGross') }}
                        </template>
                    </LineItem>
                    <LineItem>
                        <template #label>
                            {{ priceTypeTranslation(PURCHASE) }}
                        </template>
                        <template #endSection>
                            {{ $n(data.totalPurchasePriceInclTax, 'currency') }}
                        </template>
                    </LineItem>
                    <LineItem>
                        <template #label>
                            {{ priceTypeTranslation(RETAIL) }}
                        </template>
                        <template #endSection>
                            {{ $n(data.totalGrossRetailPrice, 'currency') }}
                        </template>
                    </LineItem>
                </div>
            </template>
            <!-- END: PA - Totals section -->

            <!-- START: Client Additional disposal positions -->
            <template v-if="data.isTypeProjectPositionDisposal && !isPlatform">
                <CustomPositionOverviewSection
                    v-if="hasCustomPosition"
                    class="mt-12"
                    :custom-positions="data.customPositions"
                    :no-of-transports="data.totalTransports"
                />
            </template>
            <!-- End: Client Additional disposal positions -->

            <!-- START: Client - Totals section -->
            <div v-if="!isPlatform" class="mt-12 bg p-2">
                <LineItem has-bold-label>
                    <template #label>
                        {{ $t('pages.checkout.summary.costTable.totalNet') }}
                    </template>
                    <template #endSection>
                        <span class="font-copy-md-strong">
                            {{ $n(data.totalRetailPrice, 'currency') }}
                        </span>
                    </template>
                </LineItem>
                <LineItem v-if="data.taxRate">
                    <template #label>
                        {{
                            $t('pages.checkout.summary.costTable.vat', {
                                vat: data.taxRate,
                            })
                        }}
                    </template>
                    <template #endSection>
                        {{ $n(data.totalGrossRetailPrice - data.totalRetailPrice, 'currency') }}
                    </template>
                </LineItem>
                <LineItem>
                    <template #label>
                        {{ $t('pages.checkout.summary.costTable.totalGross') }}
                    </template>
                    <template #endSection>
                        {{ $n(data.totalGrossRetailPrice, 'currency') }}
                    </template>
                </LineItem>
            </div>
            <!-- END: Client - Totals section -->
        </div>

        <ModalBox ref="offerBlockcostsInfo" :headline="$t('pages.checkout.summary.costTable.infoModal.headline')">
            {{
                data.isTypeDelivery || data.isTypeProjectPositionDelivery
                    ? $t('pages.checkout.summary.costTable.infoModal.text')
                    : $t('pages.checkout.summary.costTable.infoModal.textPickup')
            }}

            <ButtonGroup>
                <BaseButton primary @click="closeModal('offerBlockcostsInfo')">
                    {{ $t('pages.checkout.summary.costTable.infoModal.buttonLabel') }}
                    <template #right>
                        <SmallArrowIcon class="icon--inline" />
                    </template>
                </BaseButton>
            </ButtonGroup>
        </ModalBox>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import routeContext from '@/plugins/mixins/routeContext';
import { CONTEXT_PLATFORM } from '@/constants/context';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
import { PRICE_TYPES } from '@/constants/priceTypes';
import { BILLING_METHODS } from '@/constants/billingTypes';
import { getTranslationForBillingMethodUnit } from '@/services/utils/translation';

import ModalBox from '@/components/Modal/ModalBox';
import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import PopUp from '@/_components/PopUp/PopUp';
import CustomPositionOverviewSection from '@/pages/Checkout/components/PriceAdjustment/CustomPositionOverviewSection.vue';

import { SfInfoIcon } from '@schuettflix/vue-components';
import SmallArrowIcon from '@/assets/icons/micro/arrow.svg';
import ArrowEditIcon from '@/assets/icons/regular/arrow-stripeless.svg';
import OfferBlockView from '@/pages/Order/components/OfferBlockView';
import LineItem from '@/_components/LineItem/LineItem.vue';

export default defineComponent({
    name: 'OfferBlockV2',

    components: {
        ModalBox,
        BaseButton,
        ButtonGroup,
        LineItem,
        PopUp,
        CustomPositionOverviewSection,

        ArrowEditIcon,
        SfInfoIcon,
        SmallArrowIcon,
    },

    mixins: [routeContext],

    props: {
        data: {
            type: OfferBlockView,
            required: true,
        },
        hasAction: {
            type: Boolean,
            default: false,
        },
        projectPosition: {
            type: Object,
            default: null,
        },
    },

    setup() {
        const { selectCreditNoteDocKey } = useLegalTerms();
        return {
            selectCreditNoteDocKey,
        };
    },

    computed: {
        isPlatform() {
            // Depends on summary page or order view
            return this.inRouteContext(CONTEXT_PLATFORM) || this.$can('createCustomQuote');
        },
        hasCustomPosition() {
            return !!this.data?.customPositions?.length;
        },
        gridRowClasses() {
            return 'grid items-center grid-cols-[1fr,80px,90px] gap-x-0 md:grid-cols-[2fr,170px,1fr]';
        },
        pollutionType() {
            return this.data?.product?.variantDisposal?.pollutionType?.label;
        },
        hasAdditionalCustomOrderPositions() {
            return this.data.shippingRetailPrice || this.data.shippingPurchasePrice;
        },
        hasAdditionalCustomOrderPositionRetailPrice() {
            return this.data.shippingRetailPrice;
        },
    },

    created() {
        this.PURCHASE = PRICE_TYPES.PURCHASE;
        this.RETAIL = PRICE_TYPES.RETAIL;
        this.WEIGHT = BILLING_METHODS.WEIGHT;
        this.FIXED_PRICE = BILLING_METHODS.FIXED_PRICE;
    },

    methods: {
        openModal(refName) {
            this.$refs[refName].$emit('open');
        },
        closeModal(refName) {
            this.$refs[refName].$emit('close');
        },
        productPrice(priceType, isOnlyDisposal = true) {
            if (this.data.billingMethod === null) return this.$n(this.data.unitPrice, 'currency');
            if (priceType === PRICE_TYPES.PURCHASE) {
                return {
                    [BILLING_METHODS.WEIGHT]: this.$n(this.data.purchaseUnitPrice, 'currency'),
                    [BILLING_METHODS.FIXED_PRICE]: this.$n(this.data.purchasePricePerTransport, 'currency'),
                }[this.data.billingMethod];
            }
            return {
                [BILLING_METHODS.WEIGHT]: this.$n(this.data.unitPrice, 'currency'),
                [BILLING_METHODS.FIXED_PRICE]: isOnlyDisposal
                    ? this.$n(this.data.retailPricePerTransport, 'currency')
                    : this.$n(this.data.lineItemTotalRetailPrice, 'currency'),
            }[this.data.billingMethod];
        },
        priceTypeTranslation(priceType) {
            return {
                [PRICE_TYPES.PURCHASE]: this.$t('pages.order.orderView.offerAndInvoiceTab.purchasePrice'),
                [PRICE_TYPES.RETAIL]: this.$t('pages.order.orderView.offerAndInvoiceTab.retailPrice'),
            }[priceType];
        },
        getTranslationForBillingMethodUnit(billingType) {
            return getTranslationForBillingMethodUnit(billingType);
        },
    },
});
</script>

<style lang="scss" scoped>
.offer-block__row {
    display: grid;
    grid-template-columns: 2fr 170px 1fr;
    margin: 3px 0px;

    @media screen and (max-width: $screen-sm) {
        grid-template-columns: 1fr 80px 90px;
    }
}
</style>

<template>
    <GridRow :count="2" spacing="large">
        <div v-if="supportsFilter('type')" class="span-2">
            <Words bold block spaced>{{ $t('pages.documents.filter.type') }}</Words>
            <MultiselectBox
                v-model="type"
                :options="Object.keys(endpoint.supportedFilters.type.options)"
                :option-value-renderer="option => option"
                :search-label-renderer="option => option"
                :label="$t('pages.documents.filter.type')"
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length > 0">
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ $t(getDocumentTypeKey(buttonScope.selectedOptions[0])) }}
                                <span v-if="buttonScope.selectedOptions.length > 1">
                                    , +{{ buttonScope.selectedOptions.length - 1 }}
                                </span>
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <Words :red="optionScope.isActive" bold block class="multiselect__option--spaced">
                        {{ $t(getDocumentTypeKey(optionScope.option)) }}
                        <TickIcon v-if="optionScope.isActive" class="icon--inline icon--red" />
                    </Words>
                </template>
            </MultiselectBox>
        </div>

        <div v-if="supportsFilter('status')" class="span-2">
            <Words bold block spaced>{{ $t('pages.documents.filter.status') }}</Words>
            <MultiselectBox
                v-model="status"
                :options="Object.keys(endpoint.supportedFilters.status.options)"
                :option-value-renderer="option => option"
                :search-label-renderer="option => option"
                :label="$t('pages.documents.filter.status')"
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length > 0">
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ $t(`pages.documents.filterStatus.${buttonScope.selectedOptions[0]}`) }}
                                <span v-if="buttonScope.selectedOptions.length > 1">
                                    , +{{ buttonScope.selectedOptions.length - 1 }}
                                </span>
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <Words :red="optionScope.isActive" bold block class="multiselect__option--spaced">
                        {{ $t(`pages.documents.filterStatus.${optionScope.option}`) }}
                        <TickIcon v-if="optionScope.isActive" class="icon--inline icon--red" />
                    </Words>
                </template>
            </MultiselectBox>
        </div>

        <div v-if="supportsFilter(['startCreated', 'endCreated'])" class="span-2">
            <Words bold block spaced>{{ $t('pages.documents.filter.createdDateRange') }}</Words>
            <TimeRangeFieldset
                :from="filterScope.filter.startCreated"
                :to="filterScope.filter.endCreated"
                type="date"
                @update-from="$set(filterScope.filter, 'startCreated', ensurePHPTimestamp($event))"
                @update-to="$set(filterScope.filter, 'endCreated', ensurePHPTimestamp($event))"
            />
        </div>

        <div v-if="supportsFilter(['startPaid', 'endPaid'])" class="span-2">
            <Words bold block spaced>{{ $t('pages.documents.filter.paidDateRange') }}</Words>
            <TimeRangeFieldset
                :from="filterScope.filter.startPaid"
                :to="filterScope.filter.endPaid"
                type="date"
                @update-from="$set(filterScope.filter, 'startPaid', ensurePHPTimestamp($event))"
                @update-to="$set(filterScope.filter, 'endPaid', ensurePHPTimestamp($event))"
            />
        </div>

        <div v-if="supportsFilter('number')" class="span-2">
            <TextField v-model="number" :label="$t('pages.documents.filter.number')" />
        </div>

        <div v-if="supportsFilter('orderNumber')" class="span-2">
            <TextField v-model="orderNumber" :label="$t('pages.documents.filter.orderNumber')" />
        </div>

        <div v-if="supportsFilter('transportNumber')" class="span-2">
            <TextField v-model="transportNumber" :label="$t('pages.documents.filter.transportNumber')" />
        </div>

        <div v-if="supportsFilter('deliveryNoteNumber')" class="span-2">
            <TextField v-model="deliveryNoteNumber" :label="$t('pages.documents.filter.deliveryNoteNumber')" />
        </div>
    </GridRow>
</template>

<script>
import DefaultInner from '@/components/Form/MultiselectBox/DefaultInner';
import GridRow from '@/components/Layout/GridRow';
import MultiselectBox from '@/components/Form/MultiselectBox';
import TimeRangeFieldset from '@/components/Filter/TimeRangeFieldset.v2';
import TextField from '@/components/Form/TextField.v2';
import TickIcon from '@/assets/icons/micro/tick.svg';
import Words from '@/components/Typography/Words';
import { ensurePHPTimestamp } from '@/services/utils/date';
import _isArray from 'lodash/isArray';
import _intersection from 'lodash/intersection';

import { useDocumentTypes } from '../useDocumentTypes';
import { useTwoWayBindingComputedFilter } from '@/components/Filter/FilterBox';
import { useScreenName } from '@schuettflix/analytics-vue';

export default {
    name: 'DocumentsFilterSet',
    components: {
        DefaultInner,
        GridRow,
        MultiselectBox,
        TimeRangeFieldset,
        TextField,
        TickIcon,
        Words,
    },
    props: {
        filterScope: {
            type: Object,
            required: true,
        },
        endpoint: {
            type: Object,
            required: true,
        },
        whitelistedFields: {
            type: Array,
            default: null,
        },
    },
    setup(props, { emit }) {
        useScreenName('documents-filter');
        const { getDocumentTypeKey } = useDocumentTypes();
        const createTwoWayBindingComputedFilter = useTwoWayBindingComputedFilter({ props, emit });

        return {
            getDocumentTypeKey,

            type: createTwoWayBindingComputedFilter('type'),
            status: createTwoWayBindingComputedFilter('status'),
            number: createTwoWayBindingComputedFilter('number'),
            orderNumber: createTwoWayBindingComputedFilter('orderNumber'),
            transportNumber: createTwoWayBindingComputedFilter('transportNumber'),
            deliveryNoteNumber: createTwoWayBindingComputedFilter('deliveryNoteNumber'),
        };
    },
    methods: {
        ensurePHPTimestamp,

        supportsFilter(filterName) {
            if (!this.endpoint.supportedFilters) {
                return false;
            }

            const checkProps = _isArray(filterName) ? filterName : [filterName];

            // if filterName does not appear fully in whitelistedFields,.. then the filter is not supported
            if (
                _isArray(this.whitelistedFields) &&
                _intersection(this.whitelistedFields, checkProps).length !== checkProps.length
            ) {
                return false;
            }

            return _intersection(Object.keys(this.endpoint.supportedFilters), checkProps).length === checkProps.length;
        },
    },
};
</script>

<style lang="scss"></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"playground playground--styleguide"},[_c('h1',[_vm._v("Welcome to the Playground")]),_c('ReactBridge',{attrs:{"react-component":_vm.SwipeButton,"props":{
            idleMessage: _vm.$t('pages.checkout.summary.actions.sendRequest'),
            successMessage: _vm.$t('pages.checkout.summary.actions.placeOrderSuccess'),
            processingMessage: _vm.$t('pages.checkout.summary.actions.placeOrderProcessingSuccess'),
            onSuccess: () => {},
            disabled: false,
        }}}),_c('ToggleSwitch',{model:{value:(_vm.isToggled),callback:function ($$v) {_vm.isToggled=$$v},expression:"isToggled"}}),_c('br'),_c('br'),_c('ToggleSwitch',{attrs:{"disabled":""},model:{value:(_vm.isToggled),callback:function ($$v) {_vm.isToggled=$$v},expression:"isToggled"}}),_c('br'),_vm._v(" "+_vm._s(_vm.isToggled)+" "),_c('br'),_c('br'),_c('button',{on:{"click":function($event){return _vm.triggerSuccessAlert()}}},[_vm._v("Trigger success")]),_c('br'),_c('button',{on:{"click":function($event){return _vm.triggerErrorAlert()}}},[_vm._v("Trigger error")]),_c('br'),_c('button',{on:{"click":function($event){return _vm.triggerInfoAlert()}}},[_vm._v("Trigger info")]),_c('br'),_c('button',{on:{"click":function($event){return _vm.triggerWarningAlert()}}},[_vm._v("Trigger warning")])],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hint",class:{
        'hint--tiny': _vm.tiny,
        'hint--center': _vm.center,
        'hint--spaceless': _vm.spaceless,
        'hint--spaceless-top': _vm.spacelessTop,
        'hint--spaceless-x': _vm.spacelessX,
        'hint--transparent': _vm.transparent,
    }},[(_vm.showLabel)?_vm._t("label",function(){return [_c('span',{staticClass:"inline text-critical",class:{ 'font-copy-sm-strong': !_vm.tiny, 'font-copy-xs-strong': _vm.tiny }},[_vm._v(" "+_vm._s(_vm.$t('components.hint.label'))+"  ")])]}):_vm._e(),_c('span',{staticClass:"inline",class:{ 'font-copy-sm': !_vm.tiny, 'font-copy-xs': _vm.tiny }},[_vm._t("default")],2)],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
export default function (rawValue, precision = null) {
    const value = `${rawValue}`;
    let val = value;

    if (precision !== null) {
        if (precision === 0) {
            val = value.split('.')[0];
        } else {
            const term = new RegExp(`(\\.\\d{${precision}})(\\d+)$`);
            val = value.replace(term, '$1');
        }
    }

    return val.replace('.', ',');
}

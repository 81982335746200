const calculateOffset = (items, index) => {
    const keys = [];
    let size = 0;

    items.forEach(el => {
        const i = parseInt(el.dataset.stuck);
        if (i > index && !keys.includes(i)) {
            keys.push(i);
            size += el.clientHeight;
        }
    });

    return size;
};

export default {
    bind(el, { value }) {
        // current stuck index
        el.dataset.stuck = value;
        el.classList.add('js--stuckable');
        el.style.zIndex = value;

        const checkScrollContainer = () => {
            const offset = calculateOffset(document.querySelectorAll('.js--stuckable'), value);
            el.style.top = `${offset}px`;
        };
        window.addEventListener('resize', checkScrollContainer);

        const observer = new MutationObserver(() => {
            checkScrollContainer();
        });
        observer.observe(el, { childList: true, subtree: true });

        el.$destroy = () => {
            window.removeEventListener('resize', checkScrollContainer);
            el.classList.remove('js--stuckable');
            observer.disconnect();
        };
        setTimeout(checkScrollContainer, 100);
    },
    unbind(el) {
        el.$destroy();
    },
};

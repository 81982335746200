<template>
    <LayoutPage
        is-flyout
        class="checkout-contingent-selection"
        data-test="checkout-contingent-flyout"
        :close-by-x="isProjectPositionEditMode"
        @close="closeFlyout()"
    >
        <span v-if="isProjectPositionEditMode" slot="pageTitle">
            {{ $t('pages.checkout.projectContingentSelection.titleChangeContingent') }}
        </span>
        <FlyoutHeader v-else slot="flyoutHeader" :headline="$t('pages.checkout.projectContingentSelection.headline')" />

        <LoadingSpinner v-if="isLoading" block dark />

        <div v-else class="container-off-canvas-sm my-6">
            <p class="font-copy-md mb-6">{{ $t('pages.checkout.projectContingentSelection.message') }}</p>

            <template v-if="showProjectContingentIndicator">
                <ContingentStatistics class="flex justify-between" :project-position="projectPosition" />
                <ProjectPositionContingentProgress size="sm" :project-position="projectPosition" class="mt-4" />
            </template>

            <p class="font-copy-sm-strong mb-2 mt-8">
                {{ quantityLabel }}
            </p>

            <div class="checkout-contingent-selection__grid">
                <div
                    class="checkout-contingent-selection__field"
                    :class="{ 'checkout-contingent-selection__field--disabled': isTypeVolume }"
                >
                    <TextField
                        :id="`${eid}-weight`"
                        :disabled="isTypeVolume"
                        :value="quantityWeight"
                        focus-initially
                        type="number"
                        only-natural-number
                        data-test="project-weight-quantity"
                        @input="setWeight($event)"
                        @keypress.enter="handleSubmission()"
                    />
                    <span class="font-copy-xs">{{ $t('units.ton') }}</span>
                </div>

                <template v-if="isConversionEnabled">
                    <BaseButton orb @click="switchQtyType()">
                        <span class="font-copy-xl-strong">=</span>
                    </BaseButton>

                    <div
                        class="checkout-contingent-selection__field"
                        :class="{ 'checkout-contingent-selection__field--disabled': isTypeWeight }"
                    >
                        <TextField
                            :id="`${eid}-volume`"
                            :disabled="isTypeWeight"
                            :value="quantityVolume"
                            type="number"
                            step="0.1"
                            data-test="project-volume-quantity"
                            @input="setVolume($event)"
                            @keypress.enter="handleSubmission()"
                        />
                        <span class="font-copy-xs">{{ $t('units.cbm') }}</span>
                    </div>

                    <BaseButton
                        orb
                        levitate
                        data-test="checkout-quantity-selection-unit-switch-button"
                        @click="switchQtyType()"
                    >
                        <ArrowLeftRightIcon
                            :class="{ 'icon--rotate-180': isTypeVolume }"
                            class="checkout-quantity-selection__arrow-icon"
                        />
                    </BaseButton>
                </template>
            </div>
            <ErrorMessage v-if="getError('quantityWeight')" :message="getError('quantityWeight')" />
            <ErrorMessage
                v-if="showFallsBelowMinQuantityError"
                :message="
                    $t('pages.checkout.projectContingentSelection.fallsBelowMinQtyError', {
                        tons: $n(convertedWeightFromKiloToTon(maximuminimumContingentWeightQty), 'qty'),
                    })
                "
            />
        </div>

        <SlideUp slot="sticky" active>
            <ButtonGroup>
                <BaseButton
                    v-if="isProjectPositionEditMode"
                    :disabled="isPending()"
                    :is-loading="isPending('saveProjectPosition')"
                    primary
                    @click="saveProjectPosition()"
                >
                    {{ $t('pages.checkout.projectContingentSelection.updateContingent') }}
                </BaseButton>
                <BaseButton
                    v-else
                    primary
                    data-test="project-button-submit-weight"
                    type="button"
                    class="checkout-contingent-selection__process-button"
                    @click="handleSubmission"
                >
                    {{ $t('pages.checkout.projectContingentSelection.submitButton') }}
                    <ArrowRightIcon slot="right" class="icon--inline" />
                </BaseButton>
            </ButtonGroup>
        </SlideUp>
    </LayoutPage>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';

import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import { convert_cbm_to_kg, convert_kg_to_cbm, get_cbm_per_ton } from '@/services/utils/units';
import { convertedWeightFromKiloToTon } from '@/services/utils';
import ProjectPositionApi from '@/services/Api/ProjectPosition';
import validate from '@/services/validation/mixin';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ContingentStatistics from '@/pages/Checkout/components/ContingentSelection/ContingentStatistics';
import ErrorMessage from '@/components/Form/ErrorMessage';
import Header from './components/Header';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import ProjectPositionContingentProgress from '@/components/Project/ProjectPositionContingentProgress';
import SlideUp from '@/components/Animation/SlideUp';
import TextField from '@/components/Form/TextField.v2';
import Toaster from '@/services/Toaster';

import ArrowLeftRightIcon from '@/assets/icons/regular/arrow-left-right.svg';
import ArrowRightIcon from '@/assets/icons/micro/arrow.svg';
import ProjectPositionView from '@/models/ProjectPositionView';
import { navigationFailure } from '@/services/utils/router';
import { greaterThan, lowerOrEqualThan, isRequired } from '@/services/validation/rules';

const QTY_TYPE_WEIGHT = 'weight';
const QTY_TYPE_VOLUME = 'volume';

export default {
    name: 'ProjectContingentSelectionPage',

    components: {
        BaseButton,
        ButtonGroup,
        ContingentStatistics,
        ErrorMessage,
        FlyoutHeader: Header,
        LayoutPage,
        LoadingSpinner,
        ProjectPositionContingentProgress,
        SlideUp,
        TextField,

        ArrowLeftRightIcon,
        ArrowRightIcon,
    },

    mixins: [statefulMixin, validate],

    props: {
        projectPositionId: {
            type: [Number, String],
            default: null,
        },
        maximumQty: {
            type: Number,
            default: 0,
        },
        weight: {
            type: Number,
            default: null,
        },
        basketProduct: {
            type: Object,
            default: null,
        },
        basketType: {
            type: String,
            default: null,
        },
    },

    data: () => ({
        projectPosition: null,
        projectPositionDto: null,
        quantityType: QTY_TYPE_WEIGHT,
        quantityWeight: null,
        quantityVolume: 0,
    }),

    computed: {
        validationRules() {
            return {
                quantityWeight: [
                    isRequired(),
                    greaterThan(0),
                    lowerOrEqualThan(
                        convertedWeightFromKiloToTon(this.maximumQty),
                        this.$t('pages.checkout.projectContingentSelection.exceededMaxQtyError', {
                            tons: this.$n(convertedWeightFromKiloToTon(this.maximumQty), 'qty'),
                        })
                    ),
                ],
            };
        },

        eid() {
            return `el${this._uid}`;
        },

        isLoading() {
            return this.isProjectPositionEditMode && this.isPending();
        },

        product() {
            return this.isProjectPositionEditMode ? this.projectPosition.product : this.basketProduct;
        },

        isProjectPositionEditMode() {
            return Boolean(this.projectPositionId);
        },

        showProjectContingentIndicator() {
            return this.isProjectPositionEditMode && this.projectPosition;
        },

        isTypeWeight() {
            return this.quantityType === QTY_TYPE_WEIGHT;
        },

        isTypeVolume() {
            return this.quantityType === QTY_TYPE_VOLUME;
        },

        hasInvalidValues() {
            const ton = parseFloat(this.quantityWeight);
            const hasNoValue = null === this.maximumQty || isNaN(ton);
            const isOutOfBounds =
                this.showExceedsMaxQuantityError ||
                (this.isProjectPositionEditMode ? this.showFallsBelowMinQuantityError : 0 >= ton);

            return hasNoValue || isOutOfBounds;
        },

        hasExceededMaxQty() {
            const ton = parseFloat(this.quantityWeight);
            return ton * 1000 > this.maximumQty;
        },

        showExceedsMaxQuantityError() {
            return this.hasExceededMaxQty && null !== this.maximumQty;
        },

        minimumContingentWeight() {
            return this.projectPosition ? this.projectPosition.deliveredQty + this.projectPosition.reservedQty : null;
        },

        hasBelowMinQty() {
            const ton = parseFloat(this.quantityWeight);
            return ton * 1000 < this.minimumContingentWeight;
        },

        showFallsBelowMinQuantityError() {
            return this.isProjectPositionEditMode && this.hasBelowMinQty && null !== this.minimumContingentWeight;
        },

        hasValidData() {
            return 0 < this.quantityWeight && this.quantityWeight * 1000 !== this.projectPositionDto.qty;
        },

        isConversionEnabled() {
            return this.projectPosition
                ? 'shipment' !== this.projectPosition.type
                : BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_SHIPMENT !== this.basketType;
        },

        //remove as soon as density is also provided in kg per cbm instead of ton per cmb
        hasDensityInKgPerSqm() {
            return [
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS,
            ].includes(this.basketType);
        },

        quantityLabel() {
            return this.$t(
                `pages.checkout.projectContingentSelection.label.${
                    this.isConversionEnabled ? 'weightAndVolume' : 'weight'
                }`
            );
        },
    },

    watch: {
        weight(newValue) {
            this.setWeight(newValue);
        },
        hasInvalidValues(newValue, oldValue) {
            if (newValue !== oldValue) {
                // rerun validation
                this.isValid();
            }
        },
    },

    created() {
        this.stateful('loadPositionData', async () => {
            if (this.isProjectPositionEditMode) {
                await this.loadProjectPosition();
            }
        });
    },

    methods: {
        convertedWeightFromKiloToTon,
        async loadProjectPosition() {
            try {
                const result = await ProjectPositionApi.getOneById(this.projectPositionId);
                this.projectPosition = ProjectPositionView.create(result);
                this.projectPositionDto = result; // store DTO for POST because quotationPrices can't be unfolded
                this.setWeight(this.projectPosition.qty / 1000);
            } catch (err) {
                Toaster.error(err);
            }
        },

        switchQtyType() {
            this.quantityType = this.quantityType === QTY_TYPE_WEIGHT ? QTY_TYPE_VOLUME : QTY_TYPE_WEIGHT;
            const key = this.quantityType === QTY_TYPE_VOLUME ? `${this.eid}-volume` : `${this.eid}-weight`;
            setTimeout(() => {
                document.getElementById(key)?.focus();
            }, 400);
        },

        setWeight(ton) {
            this.quantityWeight = ton > 0 ? ton : '';

            if (this.isConversionEnabled && !this.hasDensityInKgPerSqm) {
                this.quantityVolume = convert_kg_to_cbm(ton * 1000, this.product.density, 1);
            }

            if (this.projectPosition) {
                this.projectPosition.qty = this.quantityWeight * 1000;
            }

            //remove as soon as density is also provided in kg per cbm instead of ton per cmb
            if (this.hasDensityInKgPerSqm) {
                this.quantityVolume = get_cbm_per_ton(ton * 1000, this.product.density, null, 1);
            }
        },

        setVolume(cbm) {
            this.quantityVolume = cbm;
            this.quantityWeight = Math.round(convert_cbm_to_kg(cbm, this.product.density, 1) / 100) / 10;

            if (this.projectPosition) {
                this.projectPosition.qty = this.quantityWeight * 1000;
            }

            //remove as soon as density is also provided in kg per cbm instead of ton per cmb
            if (this.hasDensityInKgPerSqm) {
                this.quantityWeight = Math.round(convert_cbm_to_kg(cbm, this.product.density, null, 1) * 100) / 100;
            }
        },

        handleSubmission() {
            if (!this.isValid() || this.hasInvalidValues) {
                return;
            }

            this.$emit('updateWeightInStore', this.quantityWeight * 1000);
            this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(navigationFailure);
        },

        closeFlyout() {
            if (!this.isProjectPositionEditMode) return;
            this.$emit('close');
        },

        async saveProjectPosition() {
            if (!this.hasValidData) return;
            await this.stateful('saveProjectPosition', async () => {
                try {
                    const payload = await ProjectPositionApi.transformProjectPosition({
                        ..._cloneDeep(this.projectPositionDto),
                        qty: this.quantityWeight * 1000,
                    });
                    await ProjectPositionApi.save(payload);

                    Toaster.success(
                        this.$t('pages.checkout.projectContingentSelection.savePositionSuccessMessage', {
                            number: this.projectPosition.number,
                        })
                    );
                    this.$emit('projectPositionSaved');
                } catch (err) {
                    Toaster.error(err);
                }
            });
        },
    },
};
</script>

<style lang="css" scoped>
.checkout-contingent-selection__grid {
    display: grid;
    grid-template-columns: 1fr auto 1fr auto;
    gap: 20px;
    align-items: center;
}

.checkout-contingent-selection__field-label {
    margin-bottom: 10px;
    margin-top: 30px;
}

.checkout-contingent-selection__field {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
    align-items: center;
}

.checkout-contingent-selection__field--disabled {
    opacity: 0.5;
}

.checkout-contingent-selection__contingent-statistics {
    margin: 0px 25px;
}

.checkout-contingent-selection__contingent-progress {
    padding: 10px 25px 0px;
}
</style>

<template>
    <div class="avatar-upload">
        <BaseButton block class="avatar-upload__button" @click="triggerUpload">
            <div class="avatar-upload__button-inner">
                <Avatar
                    :src="(uploadedImageData && uploadedImageData.url) || (value && value.url)"
                    :size="size"
                    class="avatar-upload__avatar"
                />
                <div v-if="progress === 0 || progress === 100" class="avatar-upload__orb">
                    <EditIcon v-if="uploadedImageData || value" width="16" height="16" />
                    <PlusIcon v-else width="16" height="16" />
                </div>
                <span
                    :class="{ 'avatar-upload__progress--active': progress > 0 && progress < 100 }"
                    class="avatar-upload__progress"
                >
                    {{ progress }}%
                </span>
            </div>
        </BaseButton>
        <BaseButton v-if="showTextButton" arrow-right block class="avatar-upload__text-button" @click="triggerUpload">
            {{ $t('components.avatarUpload.textButton') }}
        </BaseButton>
        <ImageUpload
            ref="imageUploader"
            :value="value"
            passive-mode
            disable-upload
            @input="handleFileSelect"
            @error="handleError"
        />
    </div>
</template>

<script>
import { ref } from 'vue';
import { useImageUpload } from '@/services/useClientFile';

import eventHubMixin from '@/plugins/mixins/eventHubMixin';

import BaseButton from '@/components/Button/Button';
import Avatar from '@/components/Avatar';
import ImageUpload from '@/components/Form/ImageUpload';
import PlusIcon from '@/assets/icons/micro/plus.svg';
import EditIcon from '@/assets/icons/micro/pen.svg';

import Toaster from '@/services/Toaster';

export default {
    name: 'AvatarUpload',
    components: {
        Avatar,
        BaseButton,
        ImageUpload,
        PlusIcon,
        EditIcon,
    },
    mixins: [eventHubMixin],
    props: {
        value: {
            type: Object,
            default: null,
        },
        size: {
            type: String,
            default: 'big',
        },
        showTextButton: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const imageUploader = ref(null);
        const { uploadImage, onEverySuccess, onEveryError, progress, uploadedImageData } = useImageUpload();

        onEverySuccess(data => {
            emit('input', data);
            imageUploader.value.resetInput();
        });

        onEveryError(error => {
            uploadedImageData.value = undefined;
            Toaster.error(error);
        });

        return {
            uploadImage,
            progress,
            uploadedImageData,
            imageUploader,
        };
    },
    data() {
        return {
            fileError: null,
            image: null,
        };
    },
    created() {
        this.subscribe('avatar-editor-upload', ({ triggerId, blob }) => {
            if (triggerId === this._uid) {
                this.uploadImage({
                    file: blob,
                    meta: {
                        productUseCase: 'profile_image',
                    },
                });
            }
        });
    },
    methods: {
        blobToImageUrl(blob) {
            return URL.createObjectURL(blob);
        },
        triggerUpload() {
            this.fileError = null;
            this.$refs.imageUploader.triggerFileSelect();
        },
        handleError(error) {
            if (error !== null) {
                Toaster.error(error);
            }
        },
        handleFileSelect(file) {
            if (!file) {
                return;
            }

            // open editor
            this.$eventHub.$emit('avatar-editor-open', {
                triggerId: this._uid,
                blob: file,
            });
        },
    },
};
</script>

<style lang="scss">
.avatar-upload {
    position: relative;
    display: inline-block;
}

.avatar-upload__avatar {
    display: block;

    svg {
        .f-base,
        .f-highlight {
            fill: $color-mediumGrey;
        }
    }
}

.avatar-upload__orb {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 28px;
    height: 28px;
    background-color: $color-red;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: $boxShadow-bottomShort;

    svg {
        .f-base,
        .f-highlight {
            fill: $color-white;
        }

        .s-base,
        .s-highlight {
            stroke: $color-white;
        }
    }
}

.avatar-upload__button-inner {
    position: relative;
    display: inline-block;
}

.avatar-upload__progress {
    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    background-color: rgba($color-red, 0.8);
    border-radius: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: $color-white;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
}

.avatar-upload__progress--active {
    opacity: 1;
}

.avatar-upload__text-button {
    margin-top: 20px;
}
</style>

<template>
    <LayoutPage class="bg" :screen-name="screenName" is-flyout>
        <Header slot="flyoutHeader" />

        <div>
            <transition name="fade" mode="out-in">
                <Tile v-if="!selectedClient" transparent border-dark no-padding-top>
                    <Headline :level="3">
                        {{ $t('pages.checkout.clientSelection.selectClientHeadline') }}
                    </Headline>

                    <TextField v-model="searchTerm" :label="$t('pages.invoice.selectOrganization.searchLabel')">
                        <SearchIcon slot="icon" />
                    </TextField>
                    <LoadingSpinner v-if="isLoading" block dark />
                    <div v-else-if="clients && clients.count > 0" class="mt-6">
                        <button
                            v-for="(organization, i) in clients.items"
                            :key="i"
                            class="card card--hovered card--focussed w-full text-left"
                            :class="{
                                'mt-4': i > 0,
                            }"
                            @click="selectOrganization(organization)"
                        >
                            <div class="items-top flex justify-between">
                                <span class="font-copy-md-strong">{{ organization.name }}</span>
                                <span class="font-copy-sm whitespace-nowrap pl-4 leading-5 text-light-gray-700"
                                    >ID: {{ organization.customerNumber }}</span
                                >
                            </div>

                            <div class="font-copy-sm mt-1">
                                <FormattedAddress :address="organization.billingAddress" />
                            </div>
                        </button>
                        <MoreResultsButton class="mt-4" :result="clients" />
                    </div>
                    <div v-else-if="clients" class="mt-6">
                        <Words small>{{ $t('pages.invoice.selectOrganization.noOrganizationResults') }}</Words>
                    </div>
                </Tile>
                <div v-else-if="selectedClient">
                    <CustomClientSelectionDetailsPage
                        :client="selectedClient"
                        @changeClient="changeClient"
                        @change="changeCustomerDetails"
                    />
                </div>
            </transition>
        </div>

        <SlideUp slot="sticky" :active="!hasInvalidState">
            <ButtonGroup transparent>
                <Button primary :disabled="hasInvalidState" @click="submit">
                    {{ $t('pages.checkout.clientSelection.actions.submit') }}
                </Button>
            </ButtonGroup>
        </SlideUp>
    </LayoutPage>
</template>

<script>
import { defineComponent } from 'vue';
import OrganizationApi from '@/services/Api/Organization';
import Toaster from '@/services/Toaster';
import _debounce from 'lodash/debounce';
import { mapState } from 'vuex';
import { navigationFailure } from '@/services/utils/router';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Header from './components/Header';
import Headline from '@/components/Typography/Headline';
import FormattedAddress from '@/components/FormattedAddress';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import MoreResultsButton from '@/components/Filter/MoreResultsButton';
import SlideUp from '@/components/Animation/SlideUp';
import TextField from '@/components/Form/TextField.v2';
import Tile from '@/components/Layout/Tile';
import Words from '@/components/Typography/Words';

import SearchIcon from '@/assets/icons/micro/search.svg';
import CustomClientSelectionDetailsPage from '@/pages/Checkout/CustomClientSelectionDetailsPage.vue';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';

const SEARCH_DEBOUNCE_MS = 300;

export default defineComponent({
    name: 'CustomClientSelectionPage',
    components: {
        CustomClientSelectionDetailsPage,
        Button,
        ButtonGroup,
        Header,
        Headline,
        FormattedAddress,
        LayoutPage,
        LoadingSpinner,
        MoreResultsButton,
        SlideUp,
        TextField,
        Tile,
        Words,

        SearchIcon,
    },
    setup() {
        return {
            screenName: useOrderScreenName('clientselection'),
        };
    },
    data() {
        return {
            searchTerm: '',
            clients: null,
            isPending: false,
            isLoading: false,
            dateError: false,

            selectedClient: null,
            selectedConstructionProjectId: null,
            selectedPurchaser: null,
            detailsIsValid: false,
        };
    },
    computed: {
        ...mapState('basket', {
            clientInfo: 'clientInfo',
            basketType: 'type',
        }),

        hasInvalidState() {
            return this.selectedClient === null || !this.detailsIsValid;
        },
    },
    watch: {
        searchTerm() {
            this.updateOrganizations();
        },
    },
    async created() {
        await this.loadSavedData();
        await this.refreshClients();
    },
    methods: {
        async loadSavedData() {
            if (!this.clientInfo) return;

            // Skip organization validation, since the client no longer pulls all data

            this.selectedClient = this.clientInfo.client;
        },

        updateOrganizations: _debounce(function () {
            this.refreshClients();
        }, SEARCH_DEBOUNCE_MS),

        async refreshClients() {
            this.isLoading = true;

            const features = {
                delivery: ['client_delivery'],
                shipment: [],
                pickup: ['client_pickup'],
            };

            try {
                this.clients = await OrganizationApi.filter({
                    search: this.searchTerm,
                    organizationType: 'client',
                    isBlocked: false,
                    isActive: true,
                    features: features[this.basketType],
                    isTermsConfirmed: true,
                });
            } catch (err) {
                Toaster.error(err.message);
            }

            this.isLoading = false;
        },

        selectOrganization(organization) {
            this.searchTerm = '';
            this.selectedClient = organization;
        },

        changeClient() {
            this.searchTerm = '';
            this.selectedClient = null;
            this.selectedConstructionProjectId = null;
            this.selectedPurchaser = null;
            this.detailsIsValid = false;
        },

        changeCustomerDetails({ selectedConstructionProjectId, selectedPurchaser, isValid }) {
            this.selectedConstructionProjectId = selectedConstructionProjectId;
            this.selectedPurchaser = selectedPurchaser;
            this.detailsIsValid = isValid;
        },

        submit() {
            if (this.hasInvalidState) return;
            this.$store.commit('basket/setClientInfo', {
                client: this.selectedClient,
                purchaser: this.selectedPurchaser,
            });
            if (this.selectedConstructionProjectId) {
                this.$store.commit('constructionProject/setConstructionProjectId', this.selectedConstructionProjectId);
            }
            this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(navigationFailure);
        },
    },
});
</script>

<template>
    <div>
        <Tile transparent border-dark border-spaced no-padding-top>
            <Headline :level="3">
                {{ $t('pages.checkout.clientSelection.clientHeadline') }}
            </Headline>
            <div class="mb-4 grid grid-cols-2 items-end" style="grid-template-columns: 64px 1fr">
                <ClientIcon class="w-full" />
                <div class="mb-2">
                    <span class="font-copy-md-strong">{{ selectedClient.name }}</span>
                    <span class="font-copy-sm text-subdued">ID: {{ selectedClient.customerNumber }}</span>
                    <span class="font-copy-sm text-subdued">
                        <FormattedAddress :address="selectedClient.billingAddress" />
                    </span>
                </div>
            </div>
            <div class="text-right">
                <CustomButton arrow-right @click="changeClient()">
                    {{ $t('pages.checkout.clientSelection.actions.changeClient') }}
                </CustomButton>
            </div>
        </Tile>
        <Tile transparent no-border>
            <span class="font-copy-md-strong mb-4 block">
                {{ $t('pages.checkout.clientSelection.constructionProjectTitle') }}
            </span>
            <span class="font-copy-md mb-4 block text-subdued">
                {{ $t('pages.checkout.clientSelection.constructionProjectDescription') }}
            </span>
            <ReactCombobox
                v-model="selectedConstructionProjectId"
                data-test="construction-project"
                :label="$t('pages.checkout.clientSelection.constructionProjectLabel')"
                :options="constructionProjectOptions"
                :search-fn="constructionProjectSearch"
            />
        </Tile>
        <Tile transparent no-border>
            <span class="font-copy-md-strong mb-4 block">
                {{ $t('pages.checkout.clientSelection.purchaserTitle') }}
            </span>
            <span class="font-copy-md mb-4 block text-subdued">
                {{ $t('pages.checkout.clientSelection.purchaserDescription') }}
            </span>
            <ReactCombobox
                v-model="selectedPurchaserId"
                data-test="buyer-in-construction-project"
                :label="$t('pages.checkout.clientSelection.purchaserLabel')"
                :options="purchaserOptions"
            />
        </Tile>
    </div>
</template>

<script>
import { computed, defineComponent, ref, toRefs, watch } from 'vue';
import AdministrationUserApi from '@/services/Api/UserV2';
import * as ConstructionProjectApi from '@/constructionProjects/api/projects';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import CustomButton from '@/components/Button/Button';
import Headline from '@/components/Typography/Headline';
import FormattedAddress from '@/components/FormattedAddress';
import Tile from '@/components/Layout/Tile';
import ReactCombobox from '@/reactBridge/Wrappers/ReactCombobox/ReactCombobox.vue';

import ClientIcon from '@/assets/icons/organizations/client.svg';
import { useI18n } from 'vue-i18n-composable';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';
import { useScreenName } from '@schuettflix/analytics-vue';

export default defineComponent({
    name: 'CustomClientSelectionDetailsPage',
    components: {
        ReactCombobox,
        CustomButton,
        Headline,
        FormattedAddress,
        Tile,

        ClientIcon,
    },
    props: {
        client: { type: Object, default: null },
    },
    setup(props, { emit }) {
        const screenName = useOrderScreenName('clientselection-detail');
        useScreenName(screenName);
        const { client: selectedClient } = toRefs(props);
        const { useGetters: useConstructionProjectGetters } = createNamespacedHelpers('constructionProject');
        const { constructionProjectId: storedConstructionProjectId } = useConstructionProjectGetters([
            'constructionProjectId',
        ]);
        const { useState: useBasketState } = createNamespacedHelpers('basket');
        const { clientInfo } = useBasketState(['clientInfo']);

        const { locale } = useI18n();
        const localizeComparator = computed(() => new Intl.Collator(locale.value));

        const purchasers = ref([]);
        const purchaserOptions = computed(() =>
            purchasers.value
                .map(item => ({
                    value: item.id,
                    label: `${item.firstName} ${item.lastName}`,
                }))
                .sort((a, b) => localizeComparator.value.compare(a.label, b.label))
        );

        const selectedPurchaserId = ref(clientInfo.value?.purchaser?.id ?? null);
        const selectedPurchaser = computed(
            () => purchasers.value.find(item => item.id === selectedPurchaserId.value) ?? null
        );
        const selectedConstructionProjectId = ref(clientInfo.value ? storedConstructionProjectId.value : null ?? null);

        const constructionProjects = ref([]);
        const constructionProjectOptions = computed(() =>
            constructionProjects.value.map(item => {
                const addressString = [item.street, item.addressNumber, item.zip, item.city, item.country]
                    .filter(Boolean)
                    .join(' ');

                return {
                    value: item.id,
                    label: item.name,
                    description: item.description,
                    address: addressString,
                };
            })
        );

        watch(
            [selectedPurchaser, selectedConstructionProjectId],
            ([selectedPurchaser, selectedConstructionProjectId]) => {
                emit('change', {
                    selectedPurchaser: selectedPurchaser,
                    selectedConstructionProjectId,
                    isValid: selectedPurchaser !== null && selectedConstructionProjectId !== null,
                });
            }
        );

        watch(selectedConstructionProjectId, selectedConstructionProjectId => {
            const constructionProject = constructionProjects.value.find(
                item => item.id === selectedConstructionProjectId
            );
            selectedPurchaserId.value = constructionProject?.responsibleUser?.id ?? null;
        });

        return {
            selectedClient,
            selectedPurchaser,
            selectedPurchaserId,
            selectedConstructionProjectId,
            changeClient: $event => {
                emit('changeClient', $event);
            },
            purchaserOptions,
            purchasers,
            constructionProjects,
            constructionProjectOptions,
        };
    },
    async created() {
        await Promise.all([this.refreshPurchaserOptions(), this.refreshConstructionProjects()]);
    },
    methods: {
        async refreshPurchaserOptions() {
            const result = await AdministrationUserApi.filter({
                perPage: 100,
                organization: this.selectedClient.id,
                isActive: true,
                orPermissions: ['order', 'administration'],
            });
            this.purchasers = result.items;
        },

        async refreshConstructionProjects() {
            const { constructionProjects } = await ConstructionProjectApi.getConstructionProjectsByOrganization(
                this.selectedClient.id
            );
            this.constructionProjects = constructionProjects.filter(item => item.status === 'ACTIVE');
        },

        constructionProjectSearch(options, query) {
            if (!query.trim()) return options;
            return options.filter(option => {
                if (option.label.toLowerCase().includes(query.toLowerCase())) return true;
                if (option.description.toLowerCase().includes(query.toLowerCase())) return true;
                if (option.address.toLowerCase().includes(query.toLowerCase())) return true;
                return false;
            });
        },
    },
});
</script>

import TransportList from '@/services/Api/TransportList';
import { patchFilterParams, patchFilterResponse } from '@/services/utils/transport';
import _omit from 'lodash/omit';

export default class TransportListGrouped extends TransportList {
    async filter(
        params = {},
        facetFilterKey = null,
        facetFilterValues = [],
        cancelSource = null,
        forcedParams = {},
        silence = false
    ) {
        const resourcePath = `${this.getResourcePathByContext()}/grouped`;

        const response = await this.filterEndpoint(
            resourcePath,
            patchFilterParams(this.context, _omit(params, ['seedSortBy'])),
            facetFilterKey,
            facetFilterValues,
            cancelSource,
            patchFilterParams(this.context, _omit(forcedParams, ['seedSortBy'])),
            silence
        );

        // Map status based on context
        if (response.isFacetatedResult) {
            response.items.forEach(filterResult => {
                patchFilterResponse(this.context, filterResult, true);
            });
        }

        patchFilterResponse(this.context, response, true);
        return response;
    }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.to ? 'router-link' : _vm.href ? 'a' : 'button',{tag:"component",staticClass:"button",class:[
        ..._vm.computedClasses,
        {
            'button--default': !_vm.primary,
            'button--primary': _vm.primary,
            'button--dark': _vm.dark,
            'button--light': _vm.light,
            'button--transparent': _vm.transparent,
            'button--orb': _vm.orb,
            'button--pill': _vm.pill,
            'button--icon': _vm.icon,
            'button--image': _vm.image,
            'button--block': _vm.block,
            'button--stretched': _vm.stretched,
            'button--progress': _vm.progress !== null,
            'button--weight-normal': _vm.weightNormal,
            'button--align-left': _vm.alignLeft,
            'button--align-right': _vm.alignRight,
            'button--place-left': _vm.placeLeft,
            'button--place-right': _vm.placeRight,
            'button--no-focus': _vm.$root.isApp || _vm.noFocus,
            'button--is-loading': _vm.isLoading,
            'button--levitate': _vm.levitate,
            'button--link': _vm.link,
            'button--small': _vm.small,
            'button--adjust-icon': _vm.adjustIcon,
            'button--unbreakable': _vm.unbreakable,
            'button--auto': _vm.autoFlow,
            [`button--variant-${_vm.variant}`]: _vm.variant,
        },
    ],attrs:{"to":_vm.to,"href":_vm.href,"target":_vm.target,"disabled":_vm.disabled || _vm.isLoading,"type":"button"},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('span',{staticClass:"button__body"},[(_vm.$slots.left)?_c('span',{staticClass:"button__slot button__slot--left"},[_vm._t("left")],2):_vm._e(),(_vm.arrowLeft)?_c('span',{staticClass:"button__arrow button__arrow--left"},[_vm._v("‹")]):_vm._e(),_c('span',{staticClass:"button__content",class:{ 'button__content--loading': _vm.isLoading && (_vm.arrowLeft || _vm.$slots.left) }},[_vm._t("prefix"),_vm._t("default"),_vm._t("suffix")],2),(_vm.primary)?_c('span',{staticClass:"button__loading-spinner",class:{
                'button__loading-spinner--with-right-content': _vm.arrowRight || _vm.$slots.right,
                'button__loading-spinner--with-left-content': _vm.arrowLeft || _vm.$slots.left,
            }},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isLoading)?_c('CircleSpinner'):_vm._e()],1)],1):_vm._e(),(_vm.arrowRight)?_c('span',{staticClass:"button__arrow button__arrow--right"},[_vm._v("›")]):_vm._e(),(_vm.$slots.right)?_c('span',{staticClass:"button__slot button__slot--right"},[_vm._t("right")],2):_vm._e()]),(_vm.progress !== null)?_c('span',{staticClass:"button__progress",style:(`width: ${_vm.progress}%`)}):_vm._e()])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
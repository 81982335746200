<template>
    <label
        :class="{ 'toggle-switch-field--reversed': reverseLabel }"
        class="toggle-switch-field"
        @click.stop="handleInput(!value)"
    >
        <ToggleSwitch
            :value="value"
            :name="name"
            :small="small"
            :color="color"
            :disabled="disabled"
            :data-test="dataTest"
            dark-mode
            class="toggle-switch-field__input"
            @input="handleInput($event)"
        />
        <Words
            :class="{
                'toggle-switch-field__label--disabled': disabled,
            }"
            class="toggle-switch-field__label"
        >
            <slot />
        </Words>
    </label>
</template>

<script>
import ToggleSwitch from '@/components/Form/ToggleSwitch';
import Words from '@/components/Typography/Words';

export default {
    name: 'ToggleSwitchField',
    components: {
        Words,
        ToggleSwitch,
    },
    props: {
        name: {
            type: String,
            default: null,
        },
        value: {
            type: Boolean,
            default: null,
        },
        dataTest: {
            type: String,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        reverseLabel: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: 'green',
            validator: v => ['green'].includes(v),
        },
        darkMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isListInput: this.value && this.value.push && this.option !== null,
        };
    },
    computed: {
        isActive() {
            // if it's an array, check if it contains the option
            if (this.isListInput) {
                return this.value.indexOf(this.option) !== -1;
            }

            return this.value;
        },
    },
    methods: {
        handleInput(value) {
            if (this.disabled) return;
            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss" scoped>
.toggle-switch-field {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.toggle-switch-field--reversed {
    flex-direction: row-reverse;

    .toggle-switch-field__input {
        margin-left: 15px;
        margin-right: 0;
    }
}

.toggle-switch-field__input {
    flex: 0 0;
    margin-right: 15px;
}

.toggle-switch-field__label {
    flex: 1 1;
    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: initial;
}

.toggle-switch-field__label--disabled {
    opacity: 0.4;
    color: $color-base;
}
</style>

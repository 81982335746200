import apiService from '@/services/Api/ApiService';
import { AbstractResourceErrorHandler } from './AbstractResourceErrorHandler';
class GlobalVariableApi extends AbstractResourceErrorHandler {
    client;
    constructor(client) {
        super();
        this.client = client;
    }
    async getAll() {
        try {
            const response = await this.client.get('/global-variable');
            return response.data.items;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async getById(id) {
        try {
            const response = await this.client.get(`/global-variable/${id}`);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async updateById(id, data) {
        try {
            const response = await this.client.post(`/global-variable/${id}`, data);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async getAvailableCountries() {
        try {
            const response = await this.client.get('/global-variable/available-countries');
            return response.data.availableCountries;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
}
export default new GlobalVariableApi(apiService);

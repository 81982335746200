<template>
    <LayoutPage screen-name="seller-orderquantity">
        <template #pageTitle>
            <div>{{ $t('pages.orderQuantity.headline') }}</div>
        </template>

        <template #mobileHeader>
            <HeaderBar>
                <template #headline>
                    {{ $t('pages.orderQuantity.headline') }}
                </template>
                <template #right>
                    <HeaderBarItem>
                        <MobileFilterBox
                            v-if="!$root.isDesktop"
                            screen-name="seller-orderquantity-filter"
                            :filter-model="filter"
                            :default-filters="defaultFilter"
                            :filter-data-endpoint="apiEndpoint"
                            :min-timestamp="getSelectionMinTimestamp"
                            :available-types="availableTypeOptions"
                            :factory-options="factoryOptions"
                            @filterUpdated="updateAppliedFilters"
                            @openInfoModal="openModal('typeInfo')"
                        />
                    </HeaderBarItem>
                </template>
            </HeaderBar>
        </template>

        <div class="container-deprecated">
            <!-- page description -->
            <span class="font-copy-md mb-4" :class="{ 'mt-4': !$root.isDesktop }">
                {{ $t('pages.orderQuantity.subline') }}
            </span>
        </div>

        <!-- advanced desktop filter -->
        <DesktopFilterBox
            v-if="$root.isDesktop"
            screen-name="seller-orderquantity-filter"
            :filter-model="filter"
            :default-filters="defaultFilter"
            :filter-data-endpoint="apiEndpoint"
            :min-timestamp="getSelectionMinTimestamp"
            :available-types="availableTypeOptions"
            :factory-options="factoryOptions"
            @filterUpdated="updateAppliedFilters"
            @openInfoModal="openModal('typeInfo')"
        />

        <!-- sorting section, only available for desktop view -->
        <FilterBar v-if="$root.isDesktop" :loading="isLoading">
            <template #sorting>
                <SortingSelectBox
                    v-model="filter.sortBy"
                    :direction="filter.sortDirection"
                    primary
                    light
                    small
                    @input="sortingValueUpdated"
                    @direction-update="sortingDirectionUpdated"
                >
                    <option v-for="option in sortOptions" :key="option" :value="option">
                        {{ $t(`pages.orderQuantity.sorting.${option}`) }}
                    </option>
                </SortingSelectBox>
            </template>
        </FilterBar>

        <!-- loading indicator -->
        <LoadingSpinner v-if="!orderQuantities" block dark />

        <!-- data table, optimized for mobile view -->
        <transition name="fade">
            <OrderQuantityTable
                v-if="orderQuantities && orderQuantities.items && orderQuantities.items.length > 0"
                :products="orderQuantities.items"
                :is-mobile-view="!$root.isDesktop"
                @openTurnoverInfoModal="openModal('turnoverInfo')"
            />

            <Hint v-else-if="orderQuantities" center>
                {{ $t('pages.orderQuantity.table.noResult') }}
            </Hint>
        </transition>

        <!-- order type information for the supplier -->
        <ModalBox ref="typeInfo" :optional-dialog="false">
            {{ $t('pages.orderQuantity.filter.typeInfo') }}
            <template #actions>
                <SfButton size="sm" @click="closeModal('typeInfo')">
                    {{ $t('pages.orderQuantity.filter.infoConfirm') }}
                </SfButton>
            </template>
        </ModalBox>

        <!-- turnover disclaimer -->
        <ModalBox ref="turnoverInfo" :optional-dialog="false">
            {{ $t('pages.orderQuantity.table.turnoverInfo') }}
            <template #actions>
                <SfButton size="sm" @click="closeModal('turnoverInfo')">
                    {{ $t('pages.orderQuantity.filter.infoConfirm') }}
                </SfButton>
            </template>
        </ModalBox>
    </LayoutPage>
</template>

<script>
import SupplierProductCapacityAPI from '@/services/Api/Supplier/Capacity';
import Toaster from '@/services/Toaster';
import _get from 'lodash/get';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import { startOfToday } from 'date-fns';

import DesktopFilterBox from '@/pages/OrderQuantity/components/DesktopFilterBox';
import FilterBar from '@/components/Filter/FilterBar';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Hint from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import MobileFilterBox from '@/pages/OrderQuantity/components/MobileFilterBox';
import ModalBox from '@/components/Modal/ModalBox';
import OrderQuantityTable from '@/pages/OrderQuantity/components/OrderQuantityTable';
import SortingSelectBox from '@/components/Form/SortingSelectBox';

import { SfButton } from '@schuettflix/vue-components';

export default {
    name: 'OrderQuantityListPage',

    components: {
        DesktopFilterBox,
        FilterBar,
        HeaderBar,
        HeaderBarItem,
        Hint,
        LayoutPage,
        LoadingSpinner,
        MobileFilterBox,
        ModalBox,
        OrderQuantityTable,
        SortingSelectBox,

        SfButton,
    },

    mixins: [persistentFiltersMixin],

    data() {
        return {
            isLoading: true,
            apiEndpoint: SupplierProductCapacityAPI,
            cancelProducCapacitySource: null,
            orderQuantities: null,
            filter: this.assembleFilter('products', {
                sortBy: 'qty',
                sortDirection: 'desc',
            }),
            defaultFilter: {
                sortBy: 'qty',
                sortDirection: 'desc',
            },
        };
    },

    computed: {
        sortOptions() {
            return _get(this.apiEndpoint, 'supportedSorts', []);
        },
        getSelectionMinTimestamp() {
            return startOfToday().getTime();
        },
        availableTypeOptions() {
            const types = _get(this.apiEndpoint, 'supportedFilters.type.options', {});
            return Object.keys(types).filter(type => type === 'reserved' || type === 'confirmed');
        },
        factoryOptions() {
            return _get(this.apiEndpoint, 'supportedFilters.factory.options', {});
        },
    },

    created() {
        this.getOrderQuantities(true);
    },

    methods: {
        updateAppliedFilters(newFilters) {
            this.filter = Object.assign({}, this.filter, newFilters);
            this.getOrderQuantities();
        },

        sortingValueUpdated(newValue) {
            this.filter.sortBy = newValue;
            this.getOrderQuantities();
        },

        sortingDirectionUpdated(newDirection) {
            this.filter.sortDirection = newDirection;
            this.getOrderQuantities();
        },

        openModal(refName) {
            this.$refs[refName].$emit('open');
        },

        closeModal(refName) {
            this.$refs[refName].$emit('close');
        },

        async getOrderQuantities(isInitial = false) {
            // show the loading indicator
            this.isLoading = true;

            // persist filter
            this.persistFilter('products', this.filter, this.defaultFilter);

            // cancel previous request
            this.cancelProducCapacitySource && this.cancelProducCapacitySource.cancel('canceled-previous-call');
            this.cancelProducCapacitySource = SupplierProductCapacityAPI.createCancelTokenSource();

            try {
                // perform api request and set the data
                const result = await SupplierProductCapacityAPI.filter(
                    this.filter,
                    null,
                    null,
                    this.cancelProducCapacitySource
                );
                this.orderQuantities = result;

                if (isInitial) {
                    this.filter = {
                        ...this.filter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                    Toaster.error(err.message);
                }
            }

            // turn off the loading indiecator
            this.isLoading = false;
        },
    },
};
</script>

<style></style>

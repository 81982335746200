import { FACTORY_ROUTE_NAMES, FACTORY_MANAGEMENT_META_TYPES } from '@/constants/factoryForm';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';

export default {
    mixins: [eventHubMixin],
    methods: {
        dialogModalFunctionCall({ modalType, buttonType }, $event) {
            if ($event) {
                this[$event?.methodName](buttonType);
            } else {
                this[modalType](buttonType);
            }
        },
        defaultLocationType(type) {
            if (type === 'primary') {
                this.confirmationModal = null;
                this.handleFormDataSubmission();
            } else {
                this.formReset();
            }

            this.closeModal();
        },
        notSaved(type) {
            if (type === 'primary') {
                this.formReset();
                this.$nextTick(() => {
                    this.$router.push({
                        ...this.nextRoute,
                    });
                });
                this.confirmationModal = null;
            }

            this.closeModal();
        },
        notSavedParentRoute(type) {
            if (type === 'primary') {
                this.formReset();
                this.$nextTick(() => {
                    this.$router.push(
                        typeof this.parentRoute === 'object'
                            ? this.parentRoute
                            : { name: this.$root.findRouteName(this.parentRoute) }
                    );
                });
                this.confirmationModal = null;
            }

            this.closeModal();
        },
        cancelEdit(type) {
            if (type === 'primary') {
                this.formReset();
                this.confirmationModal = null;
            }

            this.closeModal();
        },
        async deleteFactory(type) {
            if (type === 'primary') {
                await this.deleteFactoryRequest(this.isFactory);
                this.confirmationModal = null;
            }

            this.closeModal();
        },
        successfulCreation(type) {
            if (type === 'primary') {
                this.dialogModal = {};
                this.$router.replace({
                    query: { route: FACTORY_ROUTE_NAMES.NAME_ADDRESS },
                });
                this.confirmationModal = null;
                this.$set(this, 'factoryBackup', this.factory);
            } else {
                this.$router.push({
                    name: this.$root.findRouteName(FACTORY_ROUTE_NAMES.PARENT_ROUTE_NAME),
                });
                this.confirmationModal = null;
            }
        },
        draftCreation(type) {
            if (type === 'primary') {
                this.$router.push({
                    name: this.$root.findRouteName(FACTORY_MANAGEMENT_META_TYPES.FACTORY_DRAFT_EDIT),
                    params: {
                        factoryId: this.factory.id,
                        factory: this.factory,
                        parentRoute: this.$route.name,
                        parentRouteName: this.$t('pages.backTitle.factories'),
                        type: 'incomplete',
                    },
                    query: { route: FACTORY_ROUTE_NAMES.LOCATION_TYPE },
                });
                this.confirmationModal = null;
            }

            this.$emit('closed');
        },
        closeModal() {
            this.dialogModal = {};
            this.$eventHub.$emit('dialogModalStatus', false);
        },
    },
};

import ManualBillingUI from './ManualBillingUI.vue';
export default [
    {
        path: 'manual-billing',
        name: 'manual-billing',
        component: ManualBillingUI,
        meta: {
            requiresAuth: true,
            noBackArrow: true,
            requiredAbilities: ['haveInvoiceManagement'],
        },
    },
];

export default class Interception {
    /**
     * Interception constructor
     * @param {function|null} onContinue
     * @param {function|null} onBreak
     */
    constructor(onContinue = () => {}, onBreak = () => {}) {
        this.initialize(onContinue, onBreak);
    }

    /**
     * Initializes the instance
     * @param onContinue
     * @param onBreak
     * @returns {Promise<void>}
     */
    async initialize(onContinue, onBreak) {
        this._promise = new Promise((resolve, reject) => {
            this.continue = resolve;
            this.break = reject;
        });

        try {
            this._isPending = true;

            await this._promise;

            onContinue();
        } catch {
            onBreak();
        } finally {
            this._isPending = false;
        }
    }

    /**
     * Continues the process after the interception
     */
    continue() {}

    /**
     * Breaks the process after the interception
     */
    break() {}

    /**
     * Get pending state
     * @returns {boolean}
     */
    get isPending() {
        return this._isPending;
    }

    /**
     * Get assigned {Promise} object
     * @returns {Promise}
     */
    get step() {
        return this._promise;
    }
}

import ProjectPositionListPage from '@/pages/Project/ProjectPositionListPage';
import ProjectPositionViewPage from '@/pages/Project/ProjectPositionViewPage';
import ProjectGenericPositionDetailsPage from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/ConstructionProjectAdminGenericPositionDetailsPage.vue';
import ChildRenderer from '@/components/Router/ChildRenderer';
import ConstructionSiteSelectionPage from '@/pages/Checkout/ConstructionSiteSelectionPage';
import { ProductSelectionPage } from '@/pages/Checkout/ProductSelectionPage';
import ProjectContingentSelectionPageMaterial from '@/pages/Checkout/ProjectContingentSelectionPageMaterial';
import { DeliverySupplierSelectionPage } from '@/pages/Checkout/DeliverySupplierSelectionPage';
import CustomCarrierSelectionPage from '@/pages/Checkout/CustomCarrierSelectionPage';
import CustomPriceAdjustmentsPageMaterial from '@/pages/Checkout/CustomPriceAdjustmentsPageMaterial';
import store from '@/store';
import { hasStoreEntries, checkoutGuard } from '@/pages/Checkout';
import CheckoutRenderer from '@/pages/Checkout/components/CheckoutRenderer';
import ConfirmationPage from '@/pages/Checkout/ConfirmationPage';
import ProjectPositionTypeSelectionPage from '@/pages/Checkout/ProjectPositionTypeSelectionPage';
import ShipmentLoadingPage from '@/pages/Checkout/ShipmentLoadingPage';
import ShipmentUnloadingPage from '@/pages/Checkout/ShipmentUnloadingPage';
import ShipmentDocumentPage from '@/pages/Checkout/ShipmentDocumentPage';
import ShipmentBillingDetailsPage from '@/pages/Checkout/ShipmentBillingDetailsPage';
import CustomPriceAdjustmentsPageShipment from '@/pages/Checkout/CustomPriceAdjustmentsPageShipment.vue';
import GeneralInformation from '@/pages/Checkout/Disposal/ProjectPosition/GeneralInformation.vue';
import ChooseDisposer from '@/pages/Checkout/Disposal/ProjectPosition/ChooseDisposer.vue';
import ChooseWaste from '@/pages/Checkout/Disposal/ProjectPosition/ChooseWaste.vue';
import WasteDetails from '@/pages/Checkout/Disposal/ProjectPosition/WasteDetails.vue';
import Analysis from '@/pages/Checkout/Disposal/ProjectPosition/Analysis.vue';
import SummaryPage from '@/pages/Checkout/SummaryPage/SummaryPage';
import SummaryPageDisposal from '@/pages/Checkout/Disposal/ProjectPosition/SummaryPage.vue';
import QuantitySelection from '@/pages/Checkout/Disposal/ProjectPosition/QuantitySelection';
import CustomPriceAdjustmentsPageDisposal from '@/pages/Checkout/Disposal/ProjectPosition/CustomPriceAdjustmentsPageDisposal';
import ProjectAnalysisAndDocumentsFlyout from '@/components/Project/ProjectAnalysisAndDocumentsFlyout';
import { ConstructionProjectAdminEditFlyout } from '@/constructionProjects/pages/ConstructionProjectEdit/ConstructionProjectAdminEditFlyout';
import { ConstructionProjectAdminFlyout } from '@/constructionProjects/pages/ConstructionProjectCreate/ConstructionProjectAdminFlyout';
import {
    CONSTRUCTION_PROJECT_ADMIN_CREATE_ROUTE,
    CONSTRUCTION_PROJECT_ADMIN_EDIT_ROUTE,
} from '@/constructionProjects/constants';
import ProjectPositionValidityAndStatusPage from '@/pages/Project/ProjectPositionValidityAndStatusPage';
import { ProductDefinitionPage } from '@/pages/Checkout/ProductDefinitionPage';
import { SellerProfilePage } from '@/pages/Checkout/SellerProfilePage';
import { findRouteName } from '@/pages/routerUtils';
import { CategoriesListPage } from '@/pages/Checkout/CategoriesListPage';
import { CategoryDetailsPage } from '@/pages/Checkout/CategoryDetailsPage';
import {
    CONSTRUCTION_PROJECT_ADMIN_CHECKOUT_ROUTE,
    CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE,
    CONSTRUCTION_PROJECT_ADMIN_DOCUMENTS_ROUTE,
    CONSTRUCTION_PROJECT_ADMIN_LEGACY_POSITION_DETAILS_ROUTE,
    CONSTRUCTION_PROJECT_ADMIN_LIST_ROUTE,
    CONSTRUCTION_PROJECT_ADMIN_POSITION_DETAILS_ROUTE,
    CONSTRUCTION_PROJECT_ADMIN_POSITIONS_ROUTE,
    CONSTRUCTION_PROJECT_ADMIN_SETTINGS_ROUTE,
} from '@/modules/constructionProjects/pages/routes';
import ConstructionProjectAdminDetailsPage from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminDetails/ConstructionProjectAdminDetailsPage.vue';
import ConstructionProjectAdminSettingsPage from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminSettingsPage.vue';
import ConstructionProjectAdminCheckoutProjectPage from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminCheckoutProjectPage.vue';
import ConstructionProjectAdminPositionsPage from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminPositionsPage.vue';
import ConstructionProjectAdminDocumentsPage from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminDocuments/ConstructionProjectAdminDocumentsPage.vue';
import { ConstructionProjectAdminListPage } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminList/ConstructionProjectAdminListPage';
import GenericOrderDetails from '@/pages/Order/components/GenericOrderDetails.vue';
import { routerInstance } from '@/utils/instances';
import { CONTEXT_PLATFORM } from '@/constants/context';

const projectPositionDisposalHazardous = {
    path: 'project-disposal-hazardous',
    name: 'checkout-project-disposal-hazardous',
    component: ChildRenderer,
    redirect: to => {
        return {
            name: `${to.name}__checkout-project-disposal-hazardous-constructionSite-selection`,
            query: to.query,
        };
    },
    meta: {
        requiresAuth: true,
        requiredAbilities: ['haveProjectManagement'],
    },
    children: [
        {
            path: '',
            name: 'checkout-project-disposal-hazardous-constructionSite-selection',
            component: ConstructionSiteSelectionPage,
            meta: {
                previous: 'checkout-project-position-type-selection',
                next: 'checkout-project-disposal-hazardous-general-information',
                title: 'pages.checkout.constructionSiteSelection.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'general-information',
            name: 'checkout-project-disposal-hazardous-general-information',
            component: GeneralInformation,
            meta: {
                previous: 'checkout-project-disposal-hazardous-constructionSite-selection',
                next: 'checkout-project-disposal-hazardous-choose-disposer',
                title: 'pages.checkout.projectPositionDisposal.hazardous.pages.generalInformation',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'choose-disposer',
            name: 'checkout-project-disposal-hazardous-choose-disposer',
            component: ChooseDisposer,
            meta: {
                previous: 'checkout-project-disposal-hazardous-general-information',
                next: 'checkout-project-disposal-hazardous-choose-waste',
                title: 'pages.checkout.projectPositionDisposal.pages.chooseDisposer.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'choose-waste',
            name: 'checkout-project-disposal-hazardous-choose-waste',
            component: ChooseWaste,
            meta: {
                previous: 'checkout-project-disposal-hazardous-choose-disposer',
                next: 'checkout-project-disposal-hazardous-waste-details',
                title: 'pages.checkout.projectPositionDisposal.pages.chooseWaste.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'waste-details',
            name: 'checkout-project-disposal-hazardous-waste-details',
            component: WasteDetails,
            meta: {
                previous: 'checkout-project-disposal-hazardous-choose-waste',
                next: 'checkout-project-disposal-hazardous-analysis',
                title: 'pages.checkout.projectPositionDisposal.pages.wasteDetails.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'analysis',
            name: 'checkout-project-disposal-hazardous-analysis',
            component: Analysis,
            meta: {
                previous: 'checkout-project-disposal-hazardous-waste-details',
                next: 'checkout-project-disposal-hazardous-quantity',
                title: 'pages.checkout.projectPositionDisposal.pages.wasteAnalysisDocuments',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'quantity',
            name: 'checkout-project-disposal-hazardous-quantity',
            component: QuantitySelection,
            meta: {
                previous: 'checkout-project-disposal-hazardous-analysis',
                next: 'checkout-project-disposal-hazardous-choose-carrier',
                title: 'pages.checkout.projectPositionDisposal.pages.quantity',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'choose-carrier',
            name: 'checkout-project-disposal-hazardous-choose-carrier',
            component: CustomCarrierSelectionPage,
            meta: {
                previous: 'checkout-project-disposal-hazardous-quantity',
                next: 'checkout-project-disposal-hazardous-prices',
                title: 'pages.checkout.projectPositionDisposal.pages.chooseCarrier.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'prices',
            name: 'checkout-project-disposal-hazardous-prices',
            component: CustomPriceAdjustmentsPageDisposal,
            meta: {
                previous: 'checkout-project-disposal-hazardous-choose-carrier',
                next: 'checkout-project-disposal-hazardous-validity',
                title: 'pages.checkout.projectPositionDisposal.pages.prices.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'validity',
            name: 'checkout-project-disposal-hazardous-validity',
            component: ProjectPositionValidityAndStatusPage,
            meta: {
                previous: 'checkout-project-disposal-hazardous-prices',
                next: 'checkout-project-disposal-hazardous-check-position',
                title: 'pages.checkout.projectValidityAndStatus.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'check-position',
            name: 'checkout-project-disposal-hazardous-check-position',
            component: SummaryPageDisposal,
            meta: {
                previous: 'checkout-project-disposal-hazardous-validity',
                title: 'pages.checkout.projectPositionDisposal.pages.checkPosition',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
    ],
};

// NON-HAZARDOUS
const projectPositionDisposalNonHazardous = {
    path: 'project-disposal-non-hazardous',
    name: 'checkout-project-disposal-non-hazardous',
    component: ChildRenderer,
    redirect: to => {
        return {
            name: `${to.name}__checkout-project-disposal-non-hazardous-constructionSite-selection`,
            query: to.query,
        };
    },
    meta: {
        name: 'checkout-project-disposal-non-hazardous',
        requiresAuth: true,
        requiredAbilities: ['haveProjectManagement'],
    },
    children: [
        {
            path: '',
            name: 'checkout-project-disposal-non-hazardous-constructionSite-selection',
            component: ConstructionSiteSelectionPage,
            meta: {
                previous: 'checkout-project-position-type-selection',
                next: 'checkout-project-disposal-non-hazardous-choose-disposer',
                title: 'pages.checkout.constructionSiteSelection.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'choose-disposer',
            name: 'checkout-project-disposal-non-hazardous-choose-disposer',
            component: ChooseDisposer,
            meta: {
                previous: 'checkout-project-disposal-non-hazardous-constructionSite-selection',
                next: 'checkout-project-disposal-non-hazardous-choose-waste',
                title: 'pages.checkout.projectPositionDisposal.pages.chooseDisposer.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'choose-waste',
            name: 'checkout-project-disposal-non-hazardous-choose-waste',
            component: ChooseWaste,
            meta: {
                previous: 'checkout-project-disposal-non-hazardous-choose-disposer',
                next: 'checkout-project-disposal-non-hazardous-waste-details',
                title: 'pages.checkout.projectPositionDisposal.pages.chooseWaste.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'waste-details',
            name: 'checkout-project-disposal-non-hazardous-waste-details',
            component: WasteDetails,
            meta: {
                previous: 'checkout-project-disposal-non-hazardous-choose-waste',
                next: 'checkout-project-disposal-non-hazardous-analysis',
                title: 'pages.checkout.projectPositionDisposal.pages.wasteDetails.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'analysis',
            name: 'checkout-project-disposal-non-hazardous-analysis',
            component: Analysis,
            meta: {
                previous: 'checkout-project-disposal-non-hazardous-waste-details',
                next: 'checkout-project-disposal-non-hazardous-choose-quantity',
                title: 'pages.checkout.projectPositionDisposal.pages.wasteAnalysisDocuments',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'quantity',
            name: 'checkout-project-disposal-non-hazardous-choose-quantity',
            component: QuantitySelection,
            meta: {
                previous: 'checkout-project-disposal-non-hazardous-analysis',
                next: 'checkout-project-disposal-non-hazardous-choose-carrier',
                title: 'pages.checkout.projectPositionDisposal.pages.quantity',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'choose-carrier',
            name: 'checkout-project-disposal-non-hazardous-choose-carrier',
            component: CustomCarrierSelectionPage,
            meta: {
                previous: 'checkout-project-disposal-non-hazardous-choose-quantity',
                next: 'checkout-project-disposal-non-hazardous-prices',
                title: 'pages.checkout.projectPositionDisposal.pages.chooseCarrier.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'prices',
            name: 'checkout-project-disposal-non-hazardous-prices',
            component: CustomPriceAdjustmentsPageDisposal,
            meta: {
                previous: 'checkout-project-disposal-non-hazardous-choose-carrier',
                next: 'checkout-project-disposal-non-hazardous-validity',
                title: 'pages.checkout.projectPositionDisposal.pages.prices.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'validity',
            name: 'checkout-project-disposal-non-hazardous-validity',
            component: ProjectPositionValidityAndStatusPage,
            meta: {
                previous: 'checkout-project-disposal-non-hazardous-prices',
                next: 'checkout-project-disposal-non-hazardous-check-position',
                title: 'pages.checkout.projectValidityAndStatus.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'check-position',
            name: 'checkout-project-disposal-non-hazardous-check-position',
            component: SummaryPageDisposal,
            meta: {
                previous: 'checkout-project-disposal-non-hazardous-validity',
                title: 'pages.checkout.projectPositionDisposal.pages.checkPosition',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
    ],
};

const projectPositionShipmentRoute = {
    path: 'project-shipment',
    name: 'checkout-project-shipment',
    component: ChildRenderer,
    redirect: to => {
        return {
            name: `${to.name}__checkout-project-shipment-loading`,
            query: to.query,
        };
    },
    meta: {
        name: 'checkout-project-shipment',
        requiresAuth: true,
        requiredAbilities: ['haveProjectManagement'],
    },
    children: [
        {
            path: '',
            name: 'checkout-project-shipment-loading',
            component: ShipmentLoadingPage,
            meta: {
                previous: 'checkout-project-position-type-selection',
                next: 'checkout-project-shipment-unloading',
                title: 'pages.checkout.shipmentLoading.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'unloading',
            name: 'checkout-project-shipment-unloading',
            component: ShipmentUnloadingPage,
            meta: {
                previous: 'checkout-project-shipment-loading',
                next: 'checkout-project-shipment-freight',
                nextLabel: 'pages.checkout.nextLabel.freight',
                title: 'pages.checkout.shipmentUnloading.title',
            },
            beforeEnter: checkoutGuard(['clientInfo', 'shipmentLoading']),
        },
        {
            path: 'freight',
            name: 'checkout-project-shipment-freight',
            component: ShipmentDocumentPage,
            meta: {
                previous: 'checkout-project-shipment-unloading',
                next: 'checkout-project-shipment-quantity-selection',
                title: 'pages.checkout.shipmentFreight.title',
            },
            beforeEnter: checkoutGuard(['clientInfo', 'shipmentLoading', 'shipmentUnloading']),
        },
        {
            path: 'quantity',
            name: 'checkout-project-shipment-quantity-selection',
            component: ProjectContingentSelectionPageMaterial,
            meta: {
                previous: 'checkout-project-shipment-freight',
                next: 'checkout-project-shipment-carrier-selection',
                title: 'pages.checkout.quantitySelection.title',
            },
            beforeEnter: checkoutGuard([
                'clientInfo',
                'shipmentLoading',
                'shipmentUnloading',
                'shipmentQtyAndDocument',
            ]),
        },
        {
            path: 'carrier-selection',
            name: 'checkout-project-shipment-carrier-selection',
            component: CustomCarrierSelectionPage,
            meta: {
                previous: 'checkout-project-shipment-quantity-selection',
                next: 'checkout-project-shipment-billing-selection',
                title: 'pages.checkout.carrierSelection.title',
            },
            beforeEnter: checkoutGuard([
                'clientInfo',
                'shipmentLoading',
                'shipmentUnloading',
                'shipmentQtyAndDocument',
                'qty',
            ]),
        },
        {
            path: 'billing',
            name: 'checkout-project-shipment-billing-selection',
            component: ShipmentBillingDetailsPage,
            meta: {
                previous: 'checkout-project-shipment-carrier-selection',
                next: 'checkout-project-shipment-price-adjustments',
                title: 'pages.checkout.shipmentBillingDetails.title',
            },
            beforeEnter: checkoutGuard([
                'clientInfo',
                'shipmentLoading',
                'shipmentUnloading',
                'shipmentQtyAndDocument',
                'qty',
            ]),
        },
        {
            path: 'price-adjustments',
            name: 'checkout-project-shipment-price-adjustments',
            component: CustomPriceAdjustmentsPageShipment,
            meta: {
                previous: 'checkout-project-shipment-billing-selection',
                next: 'checkout-project-shipment-validity',
                title: 'pages.checkout.projectShipmentPriceAdjustments.flyoutTitle',
            },
            beforeEnter: checkoutGuard([
                'clientInfo',
                'shipmentLoading',
                'shipmentUnloading',
                'shipmentQtyAndDocument',
                'qty',
                'shipmentBillingDetails',
            ]),
        },
        {
            path: 'validity',
            name: 'checkout-project-shipment-validity',
            component: ProjectPositionValidityAndStatusPage,
            meta: {
                previous: 'checkout-project-shipment-price-adjustments',
                next: 'checkout-project-shipment-summary',
                title: 'pages.checkout.projectValidityAndStatus.title',
            },
        },
        {
            path: 'summary',
            name: 'checkout-project-shipment-summary',
            component: SummaryPage,
            meta: {
                previous: 'checkout-project-shipment-validity',
                next: 'checkout-main',
                noHeader: true,
                title: 'pages.checkout.summary.title',
            },
            beforeEnter: checkoutGuard([
                'clientInfo',
                'shipmentLoading',
                'shipmentUnloading',
                'shipmentQtyAndDocument',
                'qty',
                'shipmentBillingDetails',
                'pricesData',
            ]),
        },
    ],
    beforeEnter(to, from, next) {
        store.state.basket.type ? next() : next({ name: 'home' });
    },
};

const projectPositionDeliveryRoute = {
    path: 'project-delivery',
    name: 'checkout-project-delivery',
    component: ChildRenderer,
    redirect: to => {
        return {
            name: `${to.name}__checkout-project-delivery-constructionSite-selection`,
            query: to.query,
        };
    },
    meta: {
        name: 'checkout-project-delivery',
        requiresAuth: true,
        requiredAbilities: ['haveProjectManagement'],
    },
    children: [
        {
            path: '',
            name: 'checkout-project-delivery-constructionSite-selection',
            component: ConstructionSiteSelectionPage,
            meta: {
                previous: 'checkout-project-position-type-selection',
                next: 'checkout-project-delivery-product-selection',
                title: 'pages.checkout.constructionSiteSelection.title',
            },
            beforeEnter: checkoutGuard(['clientInfo']),
        },
        {
            path: 'product-selection',
            name: 'checkout-project-delivery-product-selection',
            component: ProductSelectionPage,
            meta: {
                previous: 'checkout-project-delivery-constructionSite-selection',
                next: 'checkout-project-delivery-product-definition',
                nextSellerProfile: 'checkout-project-delivery-seller-profile',
                nextCategoryDetail: 'checkout-project-delivery-category-details',
                nextCategoriesList: 'checkout-project-delivery-categories-list',
                title: 'pages.checkout.productSelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite']),
        },
        {
            path: 'categories-list',
            name: 'checkout-project-delivery-categories-list',
            component: CategoriesListPage,
            meta: {
                previous: 'checkout-project-delivery-product-selection',
                next: 'checkout-project-delivery-product-definition',
                nextCategoryDetail: 'checkout-project-delivery-category-details',
                title: 'pages.checkout.productSelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite']),
        },
        {
            path: 'category-details',
            name: 'checkout-project-delivery-category-details',
            component: CategoryDetailsPage,
            meta: {
                previous: 'checkout-project-delivery-product-selection',
                previousCategoriesList: 'checkout-project-delivery-categories-list',
                next: 'checkout-project-delivery-product-definition',
                title: 'pages.checkout.categorySelection.title',
            },
        },
        {
            path: 'seller-profile',
            name: 'checkout-project-delivery-seller-profile',
            component: SellerProfilePage,
            meta: {
                previous: 'checkout-project-delivery-product-selection',
                next: 'checkout-project-delivery-quantity-selection',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'supplierInfo']),
        },
        {
            path: 'product-definition',
            name: 'checkout-project-delivery-product-definition',
            component: ProductDefinitionPage,
            meta: {
                previous: 'checkout-project-delivery-product-selection',
                previousWithCategorySelected: 'checkout-project-delivery-category-details',
                next: 'checkout-project-delivery-quantity-selection',
                title: 'pages.checkout.productDefinition.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product']),
        },
        {
            path: 'quantity',
            name: 'checkout-project-delivery-quantity-selection',
            component: ProjectContingentSelectionPageMaterial,
            meta: {
                previous: 'checkout-project-delivery-product-definition',
                previousWithSellerSelected: 'checkout-project-delivery-seller-profile',
                next: 'checkout-project-delivery-supplier-selection',
                title: 'pages.checkout.quantitySelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product']),
        },
        {
            path: 'supplier-selection',
            name: 'checkout-project-delivery-supplier-selection',
            component: DeliverySupplierSelectionPage,
            meta: {
                previous: 'checkout-project-delivery-quantity-selection',
                previousDateSelect: 'checkout-project-delivery-time-selection',
                previousWindowSelect: 'checkout-project-delivery-window-selection',
                next: 'checkout-project-delivery-carrier-selection',
                title: 'pages.checkout.deliverySupplierSelection.title',
            },
            beforeEnter: (to, from, next) => {
                const isAllowed = hasStoreEntries(['constructionSite', 'product', 'qty']);

                if (!isAllowed) {
                    next({ name: findRouteName(to.meta.previous) });
                    return false;
                }

                const isSellerSelected = store.state.basket.isSellerSelected;

                if (isSellerSelected) {
                    return next({ name: findRouteName(to.meta.next) });
                }

                return next();
            },
        },
        {
            path: 'carrier-selection',
            name: 'checkout-project-delivery-carrier-selection',
            component: CustomCarrierSelectionPage,
            meta: {
                previous: 'checkout-project-delivery-supplier-selection',
                previousWithSellerSelected: 'checkout-project-delivery-quantity-selection',
                next: 'checkout-project-delivery-price-adjustments',
                title: 'pages.checkout.carrierSelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product', 'qty']),
        },
        {
            path: 'price-adjustments',
            name: 'checkout-project-delivery-price-adjustments',
            component: CustomPriceAdjustmentsPageMaterial,
            meta: {
                previous: 'checkout-project-delivery-carrier-selection',
                next: 'checkout-project-delivery-validity',
                title: 'pages.checkout.priceAdjustments.flyoutTitle',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product', 'qty', 'supplierInfo']),
        },
        {
            path: 'validity',
            name: 'checkout-project-delivery-validity',
            component: ProjectPositionValidityAndStatusPage,
            meta: {
                previous: 'checkout-project-delivery-price-adjustments',
                next: 'checkout-project-delivery-summary',
                title: 'pages.checkout.projectValidityAndStatus.title',
            },
        },
        {
            path: 'summary',
            name: 'checkout-project-delivery-summary',
            component: SummaryPage,
            meta: {
                previous: 'checkout-project-delivery-validity',
                next: 'checkout-main',
                title: 'pages.checkout.summary.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product', 'qty', 'supplierInfo', 'pricesData']),
        },
    ],
    beforeEnter(to, from, next) {
        store.state.basket.type ? next() : next({ name: 'home' });
    },
};

const projectCheckoutRoutes = {
    path: 'process',
    name: 'checkout-project',
    component: CheckoutRenderer,
    redirect: to => {
        return {
            name: `${to.name}__checkout-project-position-type-selection`,
            query: to.query,
        };
    },
    meta: {
        requiresAuth: true,
        requiredAbilities: ['haveProjectManagement'],
    },
    children: [
        {
            path: '',
            name: 'checkout-project-position-type-selection',
            component: ProjectPositionTypeSelectionPage,
            meta: {
                previous: CONSTRUCTION_PROJECT_ADMIN_POSITIONS_ROUTE,
                title: 'pages.checkout.projectPositionTypeSelection.title',
            },
            props: route => {
                return {
                    ...route.params,
                };
            },
            beforeEnter(to, from, next) {
                store.dispatch('basket/reset');
                next();
            },
        },
        projectPositionDeliveryRoute,
        projectPositionShipmentRoute,
        projectPositionDisposalHazardous,
        projectPositionDisposalNonHazardous,
        {
            path: 'confirmation',
            name: 'order-confirmation',
            component: ConfirmationPage,
            meta: {
                noHeader: true,
            },
            beforeEnter(to, from, next) {
                const confirmedQuote = store.state.basket.confirmedQuote;

                if (!confirmedQuote || !['submitted', 'pending', 'open'].includes(confirmedQuote.status)) {
                    next({ name: 'home' });
                    return;
                }

                next();
            },
        },
    ],
};

export const projectPositionAnalysisRoute = {
    path: 'analysis',
    name: 'analysis',
    component: ProjectAnalysisAndDocumentsFlyout,
    props: true,
};

export const constructionProjectAdminRoutes = [
    {
        path: '/order-management/projects',
        name: CONSTRUCTION_PROJECT_ADMIN_LIST_ROUTE,
        component: ConstructionProjectAdminListPage,
        meta: {
            requiresAuth: true,
            requiredAbilities: ['listProjects'],
            context: CONTEXT_PLATFORM,
        },
        children: [
            {
                path: 'create',
                name: CONSTRUCTION_PROJECT_ADMIN_CREATE_ROUTE,
                component: ConstructionProjectAdminFlyout,
                meta: {
                    requiredAbilities: ['haveProjectManagement'],
                },
            },
        ],
    },
    {
        path: '/order-management/projects/:id/positions',
        name: CONSTRUCTION_PROJECT_ADMIN_POSITIONS_ROUTE,
        component: ConstructionProjectAdminPositionsPage,
        meta: {
            requiresAuth: true,
            requiredAbilities: ['listProjects'],
            context: CONTEXT_PLATFORM,
        },
        props: route => {
            return {
                ...route.params,
                parentRoute: CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE,
            };
        },
        children: [
            {
                path: 'checkout',
                name: CONSTRUCTION_PROJECT_ADMIN_CHECKOUT_ROUTE,
                component: ConstructionProjectAdminCheckoutProjectPage,
                children: [projectCheckoutRoutes],
            },
        ],
    },
    {
        path: '/order-management/projects/:id/document-list',
        name: CONSTRUCTION_PROJECT_ADMIN_DOCUMENTS_ROUTE, //'project-document-list',
        component: ConstructionProjectAdminDocumentsPage,
        props: route => {
            return {
                ...route.params,
                parentRoute: CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE,
            };
        },
    },
    {
        path: '/order-management/projects/:id',
        name: CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE,
        component: ConstructionProjectAdminDetailsPage,
        meta: {
            requiresAuth: true,
            requiredAbilities: ['listProjects'],
            context: CONTEXT_PLATFORM,
        },
        props: route => {
            return {
                ...route.params,
                parentRoute: CONSTRUCTION_PROJECT_ADMIN_LIST_ROUTE,
            };
        },
        children: [
            // {
            //     path: 'checkout',
            //     name: CONSTRUCTION_PROJECT_ADMIN_CHECKOUT_ROUTE,
            //     component: ConstructionProjectAdminCheckoutProjectPage,
            //     children: [projectCheckoutRoutes],
            // },
            {
                path: 'settings',
                name: CONSTRUCTION_PROJECT_ADMIN_SETTINGS_ROUTE,
                component: ConstructionProjectAdminSettingsPage,
                children: [
                    {
                        path: 'edit-construction-project',
                        name: CONSTRUCTION_PROJECT_ADMIN_EDIT_ROUTE,
                        component: ConstructionProjectAdminEditFlyout,
                        meta: {
                            requiredAbilities: ['haveProjectManagement'],
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/order-management/projects/:id/generic-positions/:positionId',
        name: CONSTRUCTION_PROJECT_ADMIN_POSITION_DETAILS_ROUTE,
        component: ProjectGenericPositionDetailsPage,
        meta: {
            requiresAuth: true,
            requiredAbilities: ['listProjects'],
            context: CONTEXT_PLATFORM,
        },
        props: route => {
            return {
                ...route.params,
                parentRoute: 'project-position-list',
            };
        },
        children: [
            {
                path: 'generic-order/:orderId',
                name: 'generic-order-details',
                component: GenericOrderDetails,
                props: route => ({
                    orderId: route.params.orderId,
                    onPanelClose: () => {
                        routerInstance
                            .get()
                            .push({ name: CONSTRUCTION_PROJECT_ADMIN_POSITION_DETAILS_ROUTE, query: route.query });
                    },
                }),
            },
        ],
    },
    {
        path: '/order-management/projects/:id/positions/:positionId',
        name: CONSTRUCTION_PROJECT_ADMIN_LEGACY_POSITION_DETAILS_ROUTE,
        component: ProjectPositionViewPage,
        meta: {
            context: CONTEXT_PLATFORM,
        },
        props: route => {
            return {
                ...route.params,
                parentRoute: CONSTRUCTION_PROJECT_ADMIN_POSITIONS_ROUTE,
            };
        },
        children: [
            {
                include: 'order-view',
            },
            {
                path: 'analysis',
                name: 'analysis',
                component: ProjectAnalysisAndDocumentsFlyout,
                props: route => {
                    return {
                        ...route.params,
                        parentRoute: 'position-view',
                    };
                },
            },
        ],
    },
];

export const projectPositionListRoute = {
    path: 'positions',
    name: 'project-position-list',
    component: ProjectPositionListPage,
    meta: {
        requiresAuth: true,
        requiredAbilities: ['listProjectPositions'],
    },
    children: [
        {
            path: ':positionId/analysis',
            name: 'analysis',
            component: ProjectAnalysisAndDocumentsFlyout,
            props: route => {
                return {
                    ...route.params,
                    parentRoute: 'project-position-list',
                };
            },
        },
    ],
};

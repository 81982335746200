<template>
    <div :class="classList" @click="$emit('click', $event)">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'GridRow',
    props: {
        classes: {
            type: String,
            default: '',
        },
        spacing: {
            type: String,
            default: 'default',
            validator: value => ['none', 'base', 'default', 'small', 'tiny', 'large'].indexOf(value) !== -1,
        },
        count: {
            type: Number,
            default: 2,
        },
        gap: {
            type: String,
            default: 'default',
            validator: value => ['default', 'large', 'medium', 'small', 'none'].indexOf(value) !== -1,
        },
        marginLess: {
            type: Boolean,
            default: false,
        },
        align: {
            type: String,
            default: 'auto',
            validator: value => ['auto', 'start', 'end', 'center', 'stretch'].indexOf(value) !== -1,
        },
        spacedTop: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classList() {
            return [
                this.classes,
                'grid-row',
                `grid-row--gap-${this.gap}`,
                `grid-row--spacing-${this.spacing}`,
                `grid-row--count-${this.count}`,
                `grid-row--align-${this.align}`,
                this.marginLess ? 'grid-row--margin-less' : '',
                this.spacedTop ? 'grid-row--spaced-top' : '',
            ];
        },
    },
};
</script>

<style lang="scss">
.grid-row {
    display: grid;
}

.grid-row--bordered > * {
    border-bottom: 2px solid $color-lightGrey;
}

.grid-row--spacing-none {
    grid-row-gap: 0;
}

.grid-row--spacing-small {
    grid-row-gap: 10px;
    margin-bottom: 10px;

    &.grid-row--bordered {
        grid-row-gap: 5px;

        & > * {
            padding-bottom: 5px;
        }
    }

    &.grid-row--spaced-top {
        margin-top: 10px;
    }
}

.grid-row--spacing-tiny {
    grid-row-gap: 5px;
    margin-bottom: 5px;

    &.grid-row--bordered {
        grid-row-gap: 5px;

        & > * {
            padding-bottom: 5px;
        }
    }

    &.grid-row--spaced-top {
        margin-top: 5px;
    }
}

.grid-row--spacing-base {
    grid-row-gap: 8px;

    &.grid-row--bordered {
        grid-row-gap: 5px;

        & > * {
            padding-bottom: 5px;
        }
    }

    &.grid-row--spaced-top {
        margin-top: 5px;
    }
}

.grid-row--spacing-medium {
    grid-row-gap: 16px;
    margin-bottom: 16px;

    &.grid-row--bordered {
        grid-row-gap: 10px;

        & > * {
            padding-bottom: 10px;
        }
    }

    &.grid-row--spaced-top {
        margin-top: 16px;
    }
}

.grid-row--spacing-default {
    grid-row-gap: 16px;
    margin-bottom: 16px;

    &.grid-row--bordered {
        grid-row-gap: 8px;

        & > * {
            padding-bottom: 8px;
        }
    }

    &.grid-row--spaced-top {
        margin-top: 16px;
    }
}

.grid-row--spacing-large {
    grid-row-gap: 40px;
    margin-bottom: 40px;

    &.grid-row--bordered {
        grid-row-gap: 20px;

        & > * {
            padding-bottom: 20px;
        }
    }

    &.grid-row--spaced-top {
        margin-top: 40px;
    }
}

.grid-row--gap-small {
    grid-column-gap: 10px;

    &.grid-row--bordered {
        grid-column-gap: 5px;
    }
}

.grid-row--gap-none {
    grid-column-gap: 0;
}

.grid-row--gap-default {
    grid-column-gap: 16px;

    &.grid-row--bordered {
        grid-column-gap: 30px;
    }
}

.grid-row--gap-medium {
    grid-column-gap: 16px;

    &.grid-row--bordered {
        grid-column-gap: 20px;
    }
}

.grid-row--gap-large {
    grid-column-gap: 40px;

    &.grid-row--bordered {
        grid-column-gap: 20px;
    }
}

@for $i from 1 through 12 {
    .grid-row--count-#{$i} {
        grid-template-columns: repeat(#{$i}, 1fr);
    }
}

@for $i from 1 through 12 {
    .grid-row > .span-#{$i} {
        grid-column: span #{$i};
    }
}

@for $i from 1 through 12 {
    @media screen and (min-width: $screen-sm) {
        .grid-row > .span-sm-#{$i} {
            grid-column: span #{$i};
        }
    }
}

@for $i from 1 through 12 {
    @media screen and (min-width: $screen-md) {
        .grid-row > .span-md-#{$i} {
            grid-column: span #{$i};
        }
    }
}

@for $i from 1 through 12 {
    @media screen and (min-width: $screen-lg) {
        .grid-row > .span-lg-#{$i} {
            grid-column: span #{$i};
        }
    }
}

@for $i from 1 through 12 {
    @media screen and (min-width: $screen-xl) {
        .grid-row > .span-xl-#{$i} {
            grid-column: span #{$i};
        }
    }
}

.grid-row--align-auto {
    align-items: auto;
}

.grid-row--align-start {
    align-items: start;
}

.grid-row--align-end {
    align-items: end;
}

.grid-row--align-center {
    align-items: center;
}

.grid-row--align-stretch {
    align-items: stretch;
}

.grid-row--margin-less {
    margin-bottom: 0;
}
</style>

<template>
    <FormWrapper class="factory-location-type" data-test="FactoryLocationType">
        <template #title>
            <div class="factory-location-type__container">
                {{ $t('components.factoryManagement.factoryLocationType.title') }}
            </div>
        </template>
        <FormFieldGroup
            class="factory-location-type__container"
            :class="{ 'divider-bottom': isUsageDisposalActive }"
            :small-bottom-margin="true"
        >
            <template #title>
                <Words bold>{{
                    $t('components.factoryManagement.factoryLocationType.sectionLocationUsage.title')
                }}</Words>
            </template>
            <ComponentWrapper :border-bottom="disposalNumberActive">
                <template #componentText>
                    <Words v-if="isDisposalActiveInCurrentMarket"
                        >{{ $t('components.factoryManagement.factoryLocationType.sectionLocationUsage.description') }}
                    </Words>
                    <Words v-else>
                        {{
                            $t(
                                'components.factoryManagement.factoryLocationType.sectionLocationUsage.nonGermanMarketDescription'
                            )
                        }}
                    </Words>
                </template>
                <template #component>
                    <div class="mb-0 flex flex-col gap-4 md:flex-row">
                        <div class="md:w-1/2">
                            <CheckboxCard
                                class="h-full"
                                :model-value="form.usages"
                                :title="
                                    $t(
                                        'components.factoryManagement.factoryLocationType.sectionLocationUsage.cards.selling.title'
                                    )
                                "
                                :description="
                                    $t(
                                        'components.factoryManagement.factoryLocationType.sectionLocationUsage.cards.selling.description'
                                    )
                                "
                                :is-read-only="isReadOnly"
                                :is-disabled="isUsageSellerDisabled"
                                checkbox-value="selling"
                                is-icon-full-size
                                data-test="factory-location-type-checkbox-selling"
                                @change="update('usages', $event)"
                            >
                                <template #icon>
                                    <SellingTransportIcon />
                                </template>
                            </CheckboxCard>
                            <ButtonLink
                                v-if="isUsageSellerDisabled"
                                :title="
                                    $t(
                                        'components.factoryManagement.factoryLocationType.sectionLocationUsage.cards.notUnlocked'
                                    )
                                "
                                align-right
                                has-arrow-right
                                spaced-top
                                @click="openContactFlyout"
                            />
                        </div>
                        <div v-if="isDisposalActiveInCurrentMarket" class="md:w-1/2">
                            <CheckboxCard
                                class="h-full"
                                :model-value="form.usages"
                                :title="
                                    $t(
                                        'components.factoryManagement.factoryLocationType.sectionLocationUsage.cards.disposal.title'
                                    )
                                "
                                :description="
                                    $t(
                                        'components.factoryManagement.factoryLocationType.sectionLocationUsage.cards.disposal.description'
                                    )
                                "
                                :is-read-only="isReadOnly"
                                :is-disabled="isUsageDisposalDisabled"
                                checkbox-value="disposal"
                                is-icon-full-size
                                data-test="factory-location-type-checkbox-disposal"
                                @change="update('usages', $event)"
                            >
                                <template #icon>
                                    <DisposalTransportIcon />
                                </template>
                            </CheckboxCard>
                            <ButtonLink
                                v-if="isUsageDisposalDisabled"
                                :title="
                                    $t(
                                        'components.factoryManagement.factoryLocationType.sectionLocationUsage.cards.notUnlocked'
                                    )
                                "
                                align-right
                                has-arrow-right
                                spaced-top
                                @click="openContactFlyout"
                            />
                        </div>
                    </div>
                </template>
            </ComponentWrapper>
        </FormFieldGroup>

        <FormFieldGroup v-if="showUsageConfigurationForm" class="factory-location-type__container">
            <template #title>
                <Words bold>{{
                    $t('components.factoryManagement.factoryLocationType.sectionWasteAcceptance.title')
                }}</Words>
            </template>

            <div class="factory-location-type__configuration">
                <ComponentWrapper>
                    <template #componentText>
                        <div class="mb-1 flex justify-between gap-4 leading-5">
                            {{
                                $t(
                                    'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.disposalTypeConfiguration.description'
                                )
                            }}
                        </div>
                    </template>
                    <template #componentHelpLink>
                        <HelpLink type="helpLocationType" data-test="help-link-disposal-type" />
                    </template>
                    <template #component>
                        <RadioCardSet
                            :value="form.disposalSettings.factoryType"
                            :options="factoryTypeOptions"
                            name="disposal-type"
                            :is-read-only="isReadOnly"
                            data-test="radio-card-disposal-type"
                            :error="
                                firstError['disposalSettings.factoryType'] ||
                                getError('form.disposalSettings.factoryType', submitted)
                            "
                            @input="update('disposalSettings.factoryType', $event)"
                        />
                    </template>
                </ComponentWrapper>

                <ComponentWrapper
                    v-if="showLandFillClassifications"
                    :error="
                        firstError['disposalSettings.landfillClass'] ||
                        getError('form.disposalSettings.landfillClass', submitted)
                    "
                >
                    <template #componentText>
                        <div class="mb-1 flex justify-between gap-4 leading-5">
                            <Words block
                                >{{
                                    $t(
                                        'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.disposalTypeConfiguration.landfillClasses.description'
                                    )
                                }}
                            </Words>
                        </div>
                    </template>
                    <template #componentHelpLink>
                        <HelpLink type="helpLandfillClass" data-test="help-link-landfill-classes" />
                    </template>
                    <template #component>
                        <RadioCardSet
                            :value="form.disposalSettings.landfillClass"
                            :options="landfillClassOptions"
                            name="landfill-classes"
                            data-test="landfill-classes"
                            :is-read-only="isReadOnly"
                            :is-disabled="!allowDkChange"
                            :error="
                                firstError['disposalSettings.landfillClass'] ||
                                getError('form.disposalSettings.landfillClass', submitted)
                            "
                            @input="update('disposalSettings.landfillClass', $event)"
                        />
                    </template>
                </ComponentWrapper>

                <ComponentWrapper v-if="showFileUpload">
                    <template #componentText>
                        <div class="mb-1 flex justify-between gap-4 leading-5">
                            <Words>{{ fileUploaderMessage }}</Words>
                        </div>
                    </template>
                    <template #componentHelpLink>
                        <HelpLink type="helpFileUploader" data-test="help-link-file-uploader" />
                    </template>
                    <template #component>
                        <FileUploader
                            :existing-file="form.disposalPrincipleDocument"
                            :upload-file-message="uploadFileMessage"
                            :file-upload-progress="progress"
                            :accepted-file-types="acceptedFileTypes"
                            :response="fileUploadResponse"
                            :error="
                                firstError['disposalPrincipleDocument.uuid'] ||
                                getError('form.disposalPrincipleDocument.uuid', submitted)
                            "
                            data-test="file-uploader"
                            @fileUpload="uploadFile"
                            @cancelUpload="cancelUpload"
                        />
                    </template>
                </ComponentWrapper>

                <ComponentWrapper v-if="showDisposerNumberField">
                    <template #componentText>
                        <Words>{{
                            $t('components.factoryManagement.factoryLocationType.sectionWasteAcceptance.description')
                        }}</Words>
                    </template>
                    <template #componentTitle>
                        <Words>
                            {{
                                $t(
                                    'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.factoryDisposerNumber.title'
                                )
                            }}
                        </Words>
                    </template>
                    <template #component>
                        <DisposalNumber
                            :location="form.location"
                            :address="form.address"
                            :disposal-number-string="form.disposerNumber"
                            :is-read-only="isReadOnly"
                            :error="firstError['disposerNumber'] || getError('form.disposerNumber', submitted)"
                            data-test="DisposerNumber"
                            @disposalNumber="setDisposerNumber"
                        />
                    </template>
                </ComponentWrapper>

                <ComponentWrapper v-if="showFileUpload">
                    <template #componentTitle>
                        <div class="mb-1 flex justify-between gap-4 leading-5">
                            <Words bold>
                                {{
                                    $t(
                                        'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.disposalTypeConfiguration.specialHint.title'
                                    )
                                }}
                            </Words>
                        </div>
                    </template>
                    <template #componentHelpLink>
                        <HelpLink type="helpSpecialHint" data-test="help-link-special-hint" />
                    </template>
                    <template #component>
                        <TextField
                            :value="form.disposalSettings.specialHint"
                            :label="
                                $t(
                                    'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.disposalTypeConfiguration.specialHint.placeholder'
                                )
                            "
                            type="textarea"
                            :readonly="isReadOnly"
                            :has-error="
                                !!firstError['disposalSettings.specialHint'] ||
                                hasErrors('form.disposalSettings.specialHint', submitted)
                            "
                            :error="
                                firstError['disposalSettings.specialHint'] ||
                                getError('form.disposalSettings.specialHint', submitted)
                            "
                            :rows="4"
                            @input="update('disposalSettings.specialHint', $event)"
                        />
                    </template>
                </ComponentWrapper>
            </div>
        </FormFieldGroup>
    </FormWrapper>
</template>

<script>
import { computed } from 'vue';
import { useDocumentUpload } from '@/services/useClientFile';
import _camelCase from 'lodash/camelCase';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';

import nameAddressMixin from '@/plugins/mixins/factoryForm/nameAddressMixin';
import validate from '@/services/validation/mixin';
import sectionResetMixin from '@/plugins/mixins/factoryForm/sectionResetMixin';
import sectionMixin from '@/plugins/mixins/factoryForm/sectionMixin';

import { DOCUMENT_TYPES } from '@/constants/documentTypes';
import { FACTORY_TYPES, USAGES, LANDFILL_CLASSES } from '@/constants/disposal';

import { isDisposerNumberFormatDE, isRequired, minLength } from '@/services/validation/rules';

import ComponentWrapper from '@/_components/ComponentWrapper/ComponentWrapper';
import ButtonLink from '@/_components/ButtonLink/ButtonLink';
import CheckboxCard from '@/_components/CheckboxCard/CheckboxCard';
import DisposalNumber from '@/_components/DisposalNumber/DisposalNumber';
import FileUploader from '@/_components/FileUploader/FileUploader';
import FormFieldGroup from '@/_components/FormFieldGroup/FormFieldGroup';
import FormWrapper from '@/_components/FormWrapper/FormWrapper';
import HelpLink from '@/_components/HelpLink/HelpLink';
import TextField from '@/components/Form/TextField.v2';
import Words from '@/components/Typography/Words';

import SellingTransportIcon from '@/assets/icons/trucks/usage-selling.svg';
import DisposalTransportIcon from '@/assets/icons/trucks/usage-disposal.svg';
import RadioCardSet from '@/_components/RadioCardSet/RadioCardSet';
import { getEmptyStructure } from '@/views/LocationView/defaultData';
import { stateIdentifiers } from '@/services/Disposal/StateIdentifiers';
import { mapGetters } from 'vuex';

export default {
    name: 'FactoryLocationType',
    components: {
        RadioCardSet,
        ComponentWrapper,
        ButtonLink,
        CheckboxCard,
        DisposalTransportIcon,
        DisposalNumber,
        FileUploader,
        FormFieldGroup,
        FormWrapper,
        HelpLink,
        SellingTransportIcon,
        TextField,
        Words,
    },
    mixins: [validate, sectionResetMixin, nameAddressMixin, sectionMixin],
    props: {
        value: {
            type: Object,
            default: getEmptyStructure(),
        },
        organization: {
            type: [Object, null],
            default: null,
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        allowDkChange: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, { emit }) {
        const { uploadDocument, onEveryError, progress, uploadedDocumentData } = useDocumentUpload();

        onEveryError(error => {
            emit('error', error);
        });

        const uploadFile = async file => {
            emit('uploadingStarted');
            await uploadDocument({
                file,
                meta: {
                    productUseCase: 'disposal_certificate_document',
                    documentTypeCode: DOCUMENT_TYPES.DISPOSAL_LIMIT_TABLE_OR_ACCEPTANCE_CRITERIA,
                },
            });
            emit('uploadingFinished');
        };

        const fileUploadResponse = computed(() =>
            uploadedDocumentData.value
                ? {
                      type: 'success',
                      response: uploadedDocumentData.value,
                  }
                : null
        );

        return {
            fileUploadResponse,
            uploadFile,
            progress,
        };
    },
    data() {
        return {
            acceptedFileTypes: 'application/pdf',
            confirmationModal: null,
            factoryTypeEdited: false,
            FACTORY_TYPES,
            LANDFILL_CLASSES,
        };
    },
    computed: {
        ...mapGetters('platform', { platformInfo: 'info' }),
        validationRules() {
            let rules = {
                'form.usages': [isRequired(), minLength(1)],
            };

            if (this.form?.usages?.includes(USAGES.DISPOSAL)) {
                rules = {
                    ...rules,
                    'form.disposalSettings.factoryType': [isRequired()],
                };

                if (this.form?.disposalSettings?.factoryType !== FACTORY_TYPES.DUMPING_SITE) {
                    const correctWasteStateLetter = stateIdentifiers.find(stateIdentifier => {
                        return stateIdentifier.shortName.toLowerCase() === this.form.address?.state?.toLowerCase();
                    })?.wasteStateLetter;
                    rules = {
                        ...rules,
                        'form.disposerNumber': [
                            minLength(9),
                            isRequired(),
                            isDisposerNumberFormatDE(correctWasteStateLetter),
                        ],
                    };
                }

                if (this.form?.disposalSettings?.factoryType === FACTORY_TYPES.LANDFILL) {
                    rules = {
                        ...rules,
                        'form.disposalSettings.landfillClass': [isRequired()],
                    };
                }

                if (this.form?.disposalSettings?.factoryType !== FACTORY_TYPES.PLANT) {
                    rules = {
                        ...rules,
                        'form.disposalPrincipleDocument.uuid': [isRequired()],
                    };
                }
            }

            return rules;
        },
        mandatoryFieldsValid() {
            return !(
                (this.form.disposalSettings.factoryType === FACTORY_TYPES.LANDFILL &&
                    _isEmpty(this.form.disposalSettings.landfillClass)) ||
                _isEmpty(this.form.usages)
            );
        },
        factoryTypeOptions() {
            return [
                {
                    value: FACTORY_TYPES.PLANT,
                    title: this.$t(
                        'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.disposalTypeConfiguration.radioButtonCard.recyclingFacility'
                    ),
                },
                {
                    value: FACTORY_TYPES.LANDFILL,
                    title: this.$t(
                        'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.disposalTypeConfiguration.radioButtonCard.landfill'
                    ),
                },
                {
                    value: FACTORY_TYPES.DUMPING_SITE,
                    title: this.$t(
                        'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.disposalTypeConfiguration.radioButtonCard.tippingSite'
                    ),
                },
            ];
        },
        landfillClassOptions() {
            return LANDFILL_CLASSES.map(value => ({
                value,
                title: this.$t(
                    `components.factoryManagement.factoryLocationType.sectionWasteAcceptance.disposalTypeConfiguration.landfillClasses.radioButtonCard.${value}`
                ),
            }));
        },
        isUsageSellerDisabled() {
            const features = this.organization?.features;
            return !(features?.supplier_delivery?.active || features?.supplier_pickup?.active);
        },
        isUsageDisposalActive() {
            return this.form?.usages?.includes(USAGES.DISPOSAL);
        },
        isUsageDisposalDisabled() {
            return !this.organization?.features?.supplier_disposal?.active;
        },
        isDisposalActiveInCurrentMarket() {
            //TODO : Remove or adjust this market check when the value stream disposal decides to start onboarding partners in the new markets [SCHUTT-12422]
            return this.platformInfo?.market?.code === 'DE';
        },
        uploadFileMessage() {
            return this.$t(
                'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.fileUploader.uploadOrChoose'
            );
        },
        showFileUpload() {
            return (
                this.form?.disposalSettings?.factoryType === FACTORY_TYPES.DUMPING_SITE ||
                this.form?.disposalSettings?.factoryType === FACTORY_TYPES.PLANT ||
                this.form?.disposalSettings?.landfillClass
            );
        },
        fileUploaderMessage() {
            return this.pdfUploadIsRequired
                ? this.$t(
                      'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.disposalTypeConfiguration.pdfUpload.mandatory'
                  )
                : this.$t(
                      'components.factoryManagement.factoryLocationType.sectionWasteAcceptance.disposalTypeConfiguration.pdfUpload.optional'
                  );
        },
        pdfUploadIsRequired() {
            return this.form?.disposalSettings?.factoryType !== FACTORY_TYPES.PLANT;
        },
        showLandFillClassifications() {
            return this.form?.disposalSettings?.factoryType === FACTORY_TYPES.LANDFILL;
        },
        showDisposerNumberField() {
            const sectionData = this.form;
            const isDisposal = sectionData?.usages?.includes(USAGES.DISPOSAL);
            const notDumpingSite = [FACTORY_TYPES.LANDFILL, FACTORY_TYPES.PLANT].includes(
                sectionData.disposalSettings.factoryType
            );

            // If type Landfill, then must also have Landfill class
            const correctLandfillClass =
                sectionData.disposalSettings.factoryType === FACTORY_TYPES.LANDFILL
                    ? sectionData.disposalSettings.landfillClass
                    : true;

            return isDisposal && notDumpingSite && correctLandfillClass;
        },
        showUsageConfigurationForm() {
            return (this.form?.usages || []).includes(USAGES.DISPOSAL);
        },
        disposalNumberActive() {
            return this.form?.usage?.includes(USAGES.DISPOSAL);
        },
    },
    watch: {
        value: {
            deep: true,
            handler(data) {
                this.sectionDataBackupOpenStatusReset(data.open);
            },
        },
        form: {
            deep: true,
            handler(sectionData) {
                this.$emit('dirty-check', this.checkDirty(sectionData, this.sectionDataBackup));

                const usagesModal = this.usagesModal(sectionData?.usages);
                const factoryTypeModal = this.factoryTypeModal(sectionData?.disposalSettings?.factoryType);
                this.configureModal(usagesModal, factoryTypeModal);
                const modal =
                    this.confirmationModal && this.mandatoryFieldsValid
                        ? `locationTypeConfirm${this.confirmationModal}`
                        : null;

                this.$emit('confirmation-dialog', modal);
                this.$emit('valid-check', this.isValid(false));
            },
        },
        fileUploadResponse: {
            deep: true,
            handler(data) {
                if (data?.response) {
                    this.update('disposalPrincipleDocument', data.response);
                }
            },
        },
    },
    methods: {
        openContactFlyout() {
            this.$eventHub.$emit('page.platformContact', true);
        },

        async cancelUpload() {
            this.progress = 0;
            this.$set(this.value, 'disposalPrincipleDocument', null);
            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
        },

        usagesModal(usages) {
            if (!usages) {
                return;
            }
            const baseData = _cloneDeep(this.sectionDataBackup?.usages);
            return baseData.filter(v => !usages?.includes(v));
        },
        factoryTypeModal(factoryType) {
            const baseData = _cloneDeep(this.sectionDataBackup?.disposalSettings?.factoryType);
            return factoryType !== baseData ? baseData : null;
        },

        configureModal(usagesModal, factoryTypeModal) {
            const capitalize = function (string) {
                if (_isEmpty(string)) return null;
                const [first, ...rest] = string;
                return first.toUpperCase() + rest.join('');
            };

            const usages = Array.isArray(usagesModal) && usagesModal.length > 0 ? usagesModal[0] : null;

            this.confirmationModal = null;

            const sellingChanged = usagesModal.includes(USAGES.SELLING);
            const disposalChanged = usagesModal.includes(USAGES.DISPOSAL) || !!factoryTypeModal;

            if (!sellingChanged && !disposalChanged) {
                return;
            }

            if (sellingChanged && disposalChanged) {
                this.confirmationModal = 'Combined';
                return;
            }

            if (usages !== null && !!factoryTypeModal) {
                this.confirmationModal = capitalize(usages);
                return;
            }

            if (usages !== null && !factoryTypeModal) {
                this.confirmationModal = capitalize(usages);
                return;
            }

            this.confirmationModal = capitalize(_camelCase(factoryTypeModal));
        },

        setDisposerNumber(value) {
            this.update('disposerNumber', value.string);
        },
    },
};
</script>

<style lang="scss" scoped>
.factory-location-type {
    .text-highlight {
        color: $color-red;
    }

    .divider-bottom {
        border-bottom: 1px solid #d2d4d5;
    }
}
</style>

<template>
    <div class="waste-product-variant">
        <Card spaceless no-padding class="card" :class="{ 'card--inactive': isGreyState }">
            <div class="head" @click="openAccordion(stateKey)">
                <CheckRoundedIcon
                    v-if="isGreenOrGreyState"
                    width="22"
                    height="22"
                    class="icon--inline"
                    :class="{
                        'icon--grey': hasMaintainedVariants && !hasMinOneActiveVariant,
                        'icon--red': !hasMaintainedVariants && !hasMinOneActiveVariant,
                        'icon--green': hasMaintainedVariants && hasMinOneActiveVariant,
                    }"
                />
                <CloseRoundedIcon v-else width="22" height="22" class="icon--inline icon--red" />
                <Words block>
                    {{ value.name }}
                </Words>
                <MinusIcon v-if="isOpen" class="icon" width="14" height="14" />
                <PlusIcon v-else class="icon" width="14" height="14" />
            </div>
            <template v-if="isLoading">
                <LoadingSpinner class="waste-product-variant__loading-spinner" dark />
            </template>
            <template v-if="isOpen && factoryTypes">
                <div class="body">
                    <WasteProductVariantsTable
                        v-for="(factoryType, index) in factoryTypes"
                        :key="factoryType.title"
                        v-model="factoryTypes[index]"
                        :row-index="index"
                        :validation-id="factoryTypes[index].type"
                        @onValidation="registerValidationState"
                    />
                </div>
                <div class="button-group">
                    <BaseButton :disabled="!isValidForm" type="click" primary @click="save()">
                        <CheckIcon slot="left" width="14" height="14" class="icon--inline icon--white" />
                        {{ $t('pages.productManagement.wasteProductVariantPage.save') }}
                    </BaseButton>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import BaseButton from '@/components/Button/Button';
import Card from '@/components/Layout/Card';
import WasteProductVariantsTable from './WasteProductVariantsTable';
import Words from '@/components/Typography/Words';
import LoadingSpinner from '@/components/LoadingSpinner';
import validate from '@/services/validation/mixin';
import MinusIcon from '@/assets/icons/micro/minus.svg';
import WasteProductVariants from '@/services/Api/Waste/WasteProductVariants';
import PlusIcon from '@/assets/icons/micro/plus.svg';
import CloseRoundedIcon from '@/assets/icons/regular/close--rounded.svg';
import CheckRoundedIcon from '@/assets/icons/regular/check-mark--rounded.svg';
import CheckIcon from '@/assets/icons/regular/check-mark.svg';
import Toaster from '@/services/Toaster';
import { MARGIN_TYPE_ABSOLUTE } from '@/services/Api/Platform/Product';

export default {
    name: 'WasteProductFactoryTypes',
    components: {
        BaseButton,
        Card,
        Words,
        WasteProductVariantsTable,
        CloseRoundedIcon,
        CheckRoundedIcon,
        MinusIcon,
        PlusIcon,
        CheckIcon,
        LoadingSpinner,
    },
    mixins: [validate],
    props: {
        value: {
            type: Object,
            required: true,
        },
        productId: {
            type: Number,
            default: null,
        },
        stateKey: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            isLoading: false,
            invalidInputFields: [],
            isOpen: false,
            isChecked: true,
            isInactive: false,
            factoryTypes: [],
        };
    },
    computed: {
        hasMaintainedVariants() {
            const currentState = this.$store.state.productManagement.wasteStates.filter(state => {
                return state.key === this.stateKey;
            });
            return currentState[0]?.hasMaintainedVariants;
        },
        hasMinOneActiveVariant() {
            const currentState = this.$store.state.productManagement.wasteStates.filter(state => {
                return state.key === this.stateKey;
            });
            return currentState[0]?.hasMinOneActiveVariant;
        },
        isGreyState() {
            return this.hasMaintainedVariants && !this.hasMinOneActiveVariant && !this.isOpen;
        },
        isGreenOrGreyState() {
            return this.hasMaintainedVariants || this.hasMinOneActiveVariant;
        },
        isValidForm() {
            return this.invalidInputFields.length === 0;
        },
    },
    methods: {
        async openAccordion(stateKey) {
            this.isLoading = true;
            if (!this.isOpen) await this.loadFactoryTypesData(stateKey);
            this.isOpen = !this.isOpen;
            this.isLoading = false;
        },
        async loadFactoryTypesData(stateKey) {
            try {
                const factories = await WasteProductVariants.getWasteProductVariants(this.productId, stateKey);
                this.factoryTypes = factories.factoryTypes;

                this.factoryTypes.map(factory => {
                    for (const productVariant of factory.productVariants) {
                        productVariant.minimumQuantity = productVariant.minimumQuantity / 1000;
                        productVariant.maximumQuantity = productVariant.maximumQuantity / 1000;

                        if (productVariant.productMarginType === MARGIN_TYPE_ABSOLUTE) {
                            productVariant.productMargin = productVariant.productMargin / 1000;
                        } else {
                            productVariant.productMargin = productVariant.productMargin * 100;
                        }
                    }
                });
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(err);
            }
        },
        registerValidationState(obj) {
            if (obj.isValid) {
                const index = this.invalidInputFields.indexOf(obj.validationId);
                if (index >= 0) {
                    this.invalidInputFields.splice(index, 1);
                }
            } else if (!this.invalidInputFields.includes(obj.validationId)) {
                this.invalidInputFields.push(obj.validationId);
            }
        },
        async save() {
            if (this.invalidInputFields.length) return;
            this.isOpen = !this.isOpen;

            const productVariantsToSendToApi = { items: [] };
            this.factoryTypes.map(factory => {
                for (const productVariant of factory.productVariants) {
                    delete productVariant.name;
                    delete productVariant.title;

                    const variant = {
                        ...productVariant,
                        minimumQuantity: productVariant.minimumQuantity * 1000,
                        maximumQuantity: productVariant.maximumQuantity * 1000,
                    };

                    if (productVariant.productMarginType === MARGIN_TYPE_ABSOLUTE) {
                        variant.productMargin = productVariant.productMargin * 1000;
                    } else {
                        variant.productMargin = productVariant.productMargin / 1000;
                    }

                    productVariantsToSendToApi.items.push(variant);
                }
            });
            //To avoid wasteProductVariants min and max quantity updated with 0 or empty values,
            //we do not send these fields to the api at all and remove them from the payload. The decision was taken by product and tech together
            //This may lead to cases where a user deletes the contents of min/max input field, sets the variant
            //to disabled and then saves. Since the blank input field will not be sent to the db, data in the db will
            //not be updated and the user will therefore see the previous value of the input field upon refresh of the page
            productVariantsToSendToApi.items.forEach(obj => {
                for (const [key, value] of Object.entries(obj)) {
                    if (key !== 'productMargin' && (value === null || value === 0 || value === '')) {
                        delete obj[key];
                    } else if (key === 'productMargin' && value === '') {
                        delete obj[key];
                    }
                }
            });

            try {
                await WasteProductVariants.patch({ productId: this.productId }, productVariantsToSendToApi);
                Toaster.success(this.$t('pages.productManagement.actions.wasteProducts.save.success'));
                await this.$store.dispatch('productManagement/fetchWasteProductVariantStates', {
                    productId: this.productId,
                });
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(this.$t(err.message));
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.waste-product-variant {
    display: block;
    margin: 0 0 20px;
    padding: 0;

    &__loading-spinner {
        display: flex;
        justify-content: center;
        padding-bottom: 24px;
    }
}
.card {
    &.card--inactive {
        opacity: 0.5;
        background: $color-lightMediumGrey;
    }
    .head {
        display: grid;
        grid-template-columns: 22px auto 18px;
        gap: 20px;
        align-items: center;
        padding: 26px 18px;
        cursor: pointer;

        .icon {
            display: block;
            justify-self: start;
        }
    }
    .body {
        border-top: 1px solid $color-border;
        margin: 0 20px;
        padding: 30px 0 18px;
    }
    .button-group {
        padding: 10px 20px 30px;
    }
}
.mt30 {
    margin-top: 30px;
}
</style>

<template>
    <div class="nav-overlay">
        <div class="nav-overlay__title">
            <div v-html="$t(`components.navigation.labels.${item.title}`)" />
            <CloseIcon width="32" height="18" @click="$emit('close')" />
        </div>
        <div
            v-for="(child, i) in item.items"
            :key="i"
            :exact="child.exact"
            class="nav-overlay__item"
            :class="{
                'nav-overlay__item--no-icon': child.hideIconExpanded,
                'router-link-active': child.alias && child.alias.includes($router.currentRoute.name),
            }"
            :data-test="child.title"
            @click="handleClick(child)"
        >
            <div v-if="!child.hideIconExpanded" class="nav-overlay__item-icon-container">
                <NavigationIcon :icon="child.icon" class="nav-overlay__item-icon" width="24" />
                <div v-if="child.count" class="nav-overlay__item-count">
                    {{ child.count }}
                </div>
            </div>
            <div>
                <div class="nav-overlay__item-title" v-html="$t(`components.navigation.labels.${child.title}`)" />
                <div class="nav-overlay__item-description">
                    {{ $t(`components.navigation.descriptions.${child.title}`) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavigationIcon from './NavigationIcon';
import CloseIcon from '@/assets/icons/regular/close.svg';

export default {
    name: 'NavigationOverlay',
    components: {
        NavigationIcon,
        CloseIcon,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    methods: {
        handleClick(item) {
            if (item.handler) {
                item.handler();
            } else if (item.route) {
                this.$router
                    .push({
                        name: item.route,
                    })
                    .catch(() => {});
            }

            this.$emit('selectnav');
        },
    },
};
</script>

<style lang="scss">
.nav-overlay {
    position: fixed;
    top: var(--view-top-safe-area, 0);
    left: 0;
    right: 0;
    bottom: 73px;
    z-index: 1000;
    background-color: $color-white;
    display: flex;
    flex-flow: column nowrap;
    overflow: auto;
    box-shadow: inset 0px -12px 12px -12px rgba(0, 0, 0, 0.2);

    // iOS 11.0
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
        bottom: calc(73px + var(--impersonation-bar-height) + constant(safe-area-inset-bottom));
    }

    // iOS 11.2
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
        bottom: calc(73px + var(--impersonation-bar-height) + env(safe-area-inset-bottom));
    }

    @media screen and (min-width: $layout-desktop-min) {
        display: none;
    }

    .nav-overlay__item:last-child {
        @media screen and (max-width: $layout-desktop-min) {
            margin-bottom: 20px;
        }
    }
}

.nav-overlay__title {
    font-size: 18px;
    font-weight: $font-weight-bold;
    margin: 25px 15px;
    display: flex;
    justify-content: space-between;
}

.nav-overlay__item {
    padding: 10px 15px;
    display: grid;
    grid-template-columns: 25px 1fr;
    grid-gap: 15px;
    color: $color-black;
    text-decoration: none;

    &.router-link-active {
        background: $color-lightMediumGrey;
    }
}

.nav-overlay__item--no-icon {
    display: block;
    margin-left: 40px;
}

.nav-overlay__item-icon-container {
    position: relative;
}

.nav-overlay__item-count {
    background: $color-red;
    min-width: 19px;
    border-radius: 4px;
    text-align: center;
    color: $color-white;
    margin-left: auto;
    padding: 0 2px;
    font-size: 10px;
    position: absolute;
    top: 0;
    right: 17%;
}

.nav-overlay__item-title {
    font-weight: $font-weight-bold;
    font-size: 14px;
    padding: 2px 0 4px;

    .nav-overlay__item--no-icon & {
        color: $color-red;
    }

    &::after {
        content: ' ›';
    }
}

.nav-overlay__item-description {
    font-size: 12px;
}
</style>

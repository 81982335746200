const initialState = {
    productSelection: null,
};
const getters = {
    productSelection: state => state.productSelection,
};
const mutations = {
    setProductSelection(state, payload) {
        state.productSelection = payload;
    },
    clearProductSelection(state) {
        state.productSelection = null;
    },
    mergeProductSelection(state, payload) {
        if (!state.productSelection) {
            state.productSelection = payload;
        }
        state.productSelection = {
            ...state.productSelection,
            ...payload,
        };
    },
};
const m = {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
};
export default m;

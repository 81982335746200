import { useQueries } from '@tanstack/react-query';
import AbilityChecker from '@/services/AbilityChecker/AbilityChecker';
import Context from '@/services/AbilityChecker/Context';
import customAbilities from '@/services/AbilityChecker/customAbilities';
import { ensurePHPTimestamp } from '@/services/utils/date';
import { abilitiesQuery, userInfoQuery } from './queryClient';
import { useCallback, useMemo } from 'react';
/**
 * Can be used to programmatically check if a user has the ability to do something.
 *
 * @param subject
 * @returns can function
 */
export function useCan(subject = undefined) {
    const [abilities, userInfo] = useQueries({
        queries: [
            {
                ...abilitiesQuery,
                suspense: true,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
            },
            {
                ...userInfoQuery,
                suspense: true,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
            },
        ],
    });
    const abilityChecker = useMemo(() => new AbilityChecker({
        ...abilities.data,
        ...customAbilities,
    }), [abilities.data]);
    const context = useMemo(() => {
        const ctx = new Context(userInfo.data);
        ctx.set('subject', subject);
        ctx.set('time', ensurePHPTimestamp(Date.now()));
        return ctx;
    }, [subject, userInfo.data]);
    return useCallback((check) => {
        if (!abilities.data || !userInfo.data) {
            return false;
        }
        return abilityChecker.can(check, context) ?? false;
    }, [abilities.data, abilityChecker, context, userInfo.data]);
}
/**
 * useAbility is a hook to check if a user has the ability to do something.
 *
 * @param check the ability to check
 * @param subject
 * @returns boolean indicating if the user has the ability
 */
export function useAbility(check, subject = undefined) {
    const can = useCan(subject);
    return can(check);
}

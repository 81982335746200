function updateScrollPosition() {
    if (!this.parentNode) return;
    this.classList.toggle('js--scrollable-top', this.scrollTop > 0);
    this.parentNode.classList.toggle('js--scrollable-top', this.scrollTop > 0);

    this.classList.toggle('js--scrollable-bottom', this.scrollHeight - this.scrollTop > this.clientHeight);
    this.parentNode.classList.toggle('js--scrollable-bottom', this.scrollHeight - this.scrollTop > this.clientHeight);
}

export default {
    bind(el) {
        const checkScrollContainer = () => {
            const activeClass = 'js--scrollable';
            const activeParentClass = 'js--scrollable-parent';
            if (el.clientHeight < el.scrollHeight) {
                el.classList.add(activeClass);
                el.parentNode.classList.add(activeParentClass);
            } else {
                el.classList.remove(activeClass);
                el.parentNode.classList.remove(activeParentClass);
            }
        };
        window.addEventListener('resize', checkScrollContainer);

        const onScroll = () => {
            requestAnimationFrame(updateScrollPosition.bind(el));
        };

        el.addEventListener('scroll', onScroll);
        const observer = new MutationObserver(() => {
            checkScrollContainer();
            onScroll();
        });
        observer.observe(el, { childList: true, subtree: true });

        el.$destroy = () => {
            window.removeEventListener('resize', checkScrollContainer);
            el.removeEventListener('scroll', onScroll);
            el?.classList.remove('js--scrollable', 'js--scrollable-top', 'js--scrollable-bottom');
            el?.parentNode?.classList.remove('js--scrollable-parent', 'js--scrollable-top', 'js--scrollable-bottom');
            observer.disconnect();
        };
        setTimeout(checkScrollContainer, 100);
        setTimeout(onScroll, 100);

        // WORKAROUND: some calculation issues with the mutation observer, sometimes the calculation isn't triggered
        setInterval(onScroll, 100);
    },
    unbind(el) {
        el.$destroy();
    },
};

import { LOADING_TYPE_LOADING } from '@/constants/loadingTypes';
import store from '@/store';
import TransportApi from '@/services/Api/Transport';
import WeighingApi from '@/services/Api/Weighing';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';

import { TransportLogService as LogService } from '@/logger/TransportLogService';

import Toaster from '@/services/Toaster';
import { CONFIRMED_LOADING, CONFIRM_ARRIVAL_CONFIRM, UPDATE_ORIGINAL_DELIVERY_NOTE } from '@/services/TransportActions';
import { TrackingService, statusMapping } from '@/services/Tracking/TrackingService';
import i18n from '@/i18n';
import _toArray from 'lodash/toArray';
import _debounce from 'lodash/debounce';
import _unionBy from 'lodash/unionBy';
import _intersectionBy from 'lodash/intersectionBy';

const DEFAULT_DEBOUNCE_TIME = 1000; // 1sec

/**
 * Main transport service to handle most of the transport related actions
 * glue between api and components
 *
 * @class TransportService
 */
class TransportService {
    constructor() {
        this.isPending = false;
        this.log = new LogService('services/Transport');

        this.debouncedFunctionsMap = {};
        this.imagesToUpload = [];
        this.documentsToUpload = [];
        this.imagesToDelete = [];
        this.documentsToDelete = [];

        this.endpointsBycontext = {};
    }

    /**
     * Creates a debounced function and immediately calls it.
     *
     * @param {string} key
     * @param {Function} callbackFunction
     * @param {object} options
     * @param {number} options.debounceTime
     */
    useDebouncedFunction(key, callbackFunction, { debounceTime = DEFAULT_DEBOUNCE_TIME } = {}) {
        this.debouncedFunctionsMap[key] = this.debouncedFunctionsMap[key] || _debounce(cb => cb(), debounceTime);
        this.debouncedFunctionsMap[key](callbackFunction);
    }

    /**
     *
     * @param {string} context
     * @returns {import('./Api/Transport').default} TransportApi
     */
    api(context) {
        if (!this.endpointsBycontext[context]) {
            this.endpointsBycontext[context] = new TransportApi(context);
        }

        return this.endpointsBycontext[context];
    }

    /**
     * Stop Tracking
     */
    stopTracking() {
        if (!store.getters['platform/isApp']) return;

        try {
            TrackingService.stop();
            TrackingService.sync();
        } catch (err) {
            this.log.error(err);
            Toaster.error(err);
        }
    }

    /**
     * Start Tracking for Transport
     *
     * @param {*} transport
     * @memberof Transport
     */
    startTrackingForTransport(transport) {
        if (!store.getters['platform/isApp'] || !transport) return;

        if (statusMapping[transport.type] && statusMapping[transport.type].includes(transport.status)) {
            TrackingService.start(transport.id, transport.status);
        }
    }

    /**
     * Start transport
     *
     * Roles:
     * - carrier, driver of transport
     *
     * @param {*} context
     * @param {*} transportId
     * @param {boolean} isDriverSelfService
     * @returns
     * @memberof Transport
     */
    async startTrip(context, transportId, isDriverSelfService = false) {
        if (this.isPending) return;
        this.isPending = true;

        try {
            const transport = await this.api(context).startTrip(transportId, { isDriverSelfService });

            store.commit('transportActions/updateTransport', transport);

            // set analytics event
            AnalyticsService.trackEvent('transport', {
                step: 'start_transport',
                transport_id: transportId,
                assinged_driver_id: transport.driver.id,
                vehicle_id: transport.vehicle.id,
            });

            // start tracking service in app
            this.startTrackingForTransport(transport);
        } catch (err) {
            this.log.error(err);
        }

        this.isPending = false;
    }

    /**
     * Confirm loading
     *
     * Roles:
     * - carrier, driver of transport
     *
     * @param {*} transportId
     * @returns
     * @memberof Transport
     */
    async confirmLoading(context, transportId) {
        if (this.isPending) return;
        this.isPending = true;

        try {
            const transport = await WeighingApi.confirm(transportId);
            store.dispatch('transportActions/set', { action: CONFIRMED_LOADING, transport, update: true, context });
        } catch (err) {
            this.log.warn(err);
            Toaster.error(err);
        }

        this.isPending = false;
    }

    /**
     * Confirm loading with delivery note
     *
     * Roles:
     * - carrier, driver of transport
     *
     * @param {*} transportId,
     * @param {object} payload
     * @returns
     * @memberof Transport
     */

    async confirmLoadingWithOriginalDeliveryNote(context, transportId, payload) {
        if (this.isPending) return;
        this.isPending = true;

        try {
            const transport = await WeighingApi.confirmLoadingWithOriginalDeliveryNote(transportId, payload);
            store.dispatch('transportActions/set', {
                action: CONFIRMED_LOADING,
                transport,
                update: true,
                context,
            });
        } catch (err) {
            this.log.warn(err);
            Toaster.error(err);
        }

        this.isPending = false;
    }

    async updateOriginalDeliveryNote(context, transportId, payload) {
        if (this.isPending) return;
        this.isPending = true;

        try {
            const transport = await WeighingApi.updateOriginalDeliveryNote(transportId, payload);
            store.dispatch('transportActions/set', {
                action: UPDATE_ORIGINAL_DELIVERY_NOTE,
                transport,
                update: true,
                context,
            });
        } catch (err) {
            this.log.warn(err);
            Toaster.error(err);
        }

        this.isPending = false;
    }

    /**
     * Decline loading
     *
     * Roles:
     * - carrier, driver of transport
     *
     * @param {*} transportId
     * @param {*} declineLoadingMessage
     * @returns
     * @memberof TransportService
     */
    async declineLoading(context, transportId, declineLoadingMessage) {
        if (this.isPending) return;
        this.isPending = true;

        try {
            const transport = await WeighingApi.reject(transportId, declineLoadingMessage);
            store.commit('transportActions/updateTransport', transport);
        } catch (err) {
            this.log.warn(err);
            Toaster.error(err);
            throw err;
        }

        this.isPending = false;
    }

    /**
     * Start delivery
     *
     * Roles:
     * - carrier, driver of transport
     *
     * @param {*} transportId
     * @returns
     * @memberof Transport
     */
    async startDelivery(context, transportId) {
        if (this.isPending) return;
        this.isPending = true;

        try {
            const transport = await this.api(context).startDelivery(transportId);
            store.commit('transportActions/updateTransport', transport);
            store.commit('transportActions/resetAction', [CONFIRMED_LOADING]);

            // set analytics event
            AnalyticsService.trackEvent('transport', {
                step: 'start_delivery',
                transport_id: transportId,
                assinged_driver_id: transport.driver.id,
                vehicle_id: transport.vehicle.id,
            });

            // start tracking service in app
            this.startTrackingForTransport(transport);
        } catch (err) {
            this.log.warn(err);
        }

        this.isPending = false;
    }

    async confirmArrival(context, transportId) {
        if (this.isPending) return;
        this.isPending = true;

        this.stopTracking();

        try {
            const transport = await this.api(context).confirmArrival(transportId);
            store.dispatch('transportActions/set', {
                action: CONFIRM_ARRIVAL_CONFIRM,
                transport,
                update: true,
                context,
            });

            // set analytics event
            AnalyticsService.trackEvent('transport', {
                step: 'confirm_arrival',
                transport_id: transportId,
                assinged_driver_id: transport.driver.id,
                vehicle_id: transport.vehicle.id,
            });
        } catch (err) {
            this.log.warn(err);
            Toaster.error(err);
            throw err;
        }

        this.isPending = false;
    }

    /**
     * Checkin shipment
     *
     * Roles:
     * - carrier, driver of transport
     *
     * @param context
     * @param {*} transportId
     * @returns
     * @memberof Transport
     */
    async checkinShipment(context, transportId) {
        if (this.isPending) return;
        this.isPending = true;

        this.stopTracking();

        try {
            const transport = await this.api(context).checkinShipment(transportId);
            store.commit('transportActions/updateTransport', transport);
            Toaster.success(i18n.t('actions.transportCheckinShipment'));
        } catch (err) {
            this.log.error(err);
            Toaster.error(err);
        }

        this.isPending = false;
    }

    /**
     * Checkin shipment
     *
     * Roles:
     * - carrier, driver of transport
     *
     * @param {import('@/constants/context').Context} context
     * @param {number} transportId
     * @returns
     * @memberof Transport
     */
    async discardCheckinShipment(context, transportId) {
        if (this.isPending) return;
        this.isPending = true;

        try {
            const transport = await this.api(context).discardCheckinShipment(transportId);
            store.commit('transportActions/updateTransport', transport);

            // start tracking service in app
            this.startTrackingForTransport(transport);

            Toaster.info(i18n.t('actions.transportDiscardCheckinShipment'));
        } catch (err) {
            this.log.error(err);
            Toaster.error(err);
        }

        this.isPending = false;
    }

    /**
     * Update freight info
     *
     * @param {*} context
     * @param {*} transportId
     * @param {*} payload
     * @param {string} type
     * @param {boolean} isUpdate
     */
    async updateFreightShipment(context, transportId, payload, type = LOADING_TYPE_LOADING, isUpdate = false) {
        if (this.isPending) return;
        this.isPending = true;

        let endpoint;

        if (LOADING_TYPE_LOADING === type) {
            endpoint = isUpdate ? this.api(context).updateLoadingShipment : this.api(context).confirmLoadingShipment;
        } else {
            endpoint = isUpdate
                ? this.api(context).updateUnloadingShipment
                : this.api(context).confirmUnloadingShipment;
        }

        try {
            const transport = await endpoint.apply(this.api(context), [transportId, payload]);
            store.commit('transportActions/updateTransport', transport);
            Toaster.success(i18n.t(`actions.transportUpdateFreight.${type}.${isUpdate ? 'updated' : 'confirmed'}`));
            this.isPending = false;
        } catch (err) {
            this.isPending = false;
            throw err;
        }
    }

    /**
     * Update freight info
     *
     * @param {*} context
     * @param {*} transportId
     * @param {*} payload
     * @param {string} type
     */
    async platformConfirmFreightShipment(context, transportId, payload, type = LOADING_TYPE_LOADING) {
        if (this.isPending) return;
        this.isPending = true;

        let endpoint;

        if (LOADING_TYPE_LOADING === type) {
            endpoint = this.api(context).platformConfirmLoadingShipment;
        } else {
            endpoint = this.api(context).platformConfirmDeliveryShipment;
        }

        try {
            const transport = await endpoint.apply(this.api(context), [transportId, payload]);
            store.commit('transportActions/updateTransport', transport);
            Toaster.success(i18n.t(`actions.transportUpdateFreight.${type}.confirmed`));
            this.isPending = false;
        } catch (err) {
            this.isPending = false;
            throw err;
        }
    }

    /**
     * Update transport load windows (loading and unloading) for shipment transports
     *
     * @param {*} context
     * @param {*} transportId
     * @param {*} payload
     */
    async updateLoadWindowShipment(context, transportId, payload) {
        if (this.isPending) return;
        this.isPending = true;

        try {
            const transport = await this.api(context).updateLoadWindowShipment(transportId, payload);
            store.commit('transportActions/updateTransport', transport);
            Toaster.success(i18n.t('actions.transportUpdateLoadWindowShipment'));
            this.isPending = false;
        } catch (err) {
            this.log.error(err);
            this.isPending = false;
            throw err;
        }
    }

    /**
     * Update transport's planned shipping date (loading or unloading planning base) for shipment transports
     *
     * @param {string} context
     * @param {number} transportId
     * @param {number} shippingDate
     * @param {string} planningBase
     */
    async updateShippingDateShipment(context, transportId, shippingDate, planningBase = 'loading') {
        if (this.isPending) return;
        this.isPending = true;

        try {
            const transport = await this.api(context).updateShippingDateShipment(transportId, shippingDate);
            store.commit('transportActions/updateTransport', transport);
            Toaster.success(i18n.t(`actions.transportUpdateShippingDateShipment_${planningBase}`));
            this.isPending = false;
        } catch (err) {
            this.log.error(err);
            this.isPending = false;
            throw err;
        }
    }

    /**
     * Confirm arrival shipment
     *
     * Roles:
     * - carrier, driver of transport
     *
     * @param {*} transportId
     * @returns
     * @memberof Transport
     */
    async confirmArrivalShipment(context, transportId) {
        if (this.isPending) return;
        this.isPending = true;

        this.stopTracking();

        try {
            const transport = await this.api(context).confirmArrivalShipment(transportId);
            store.commit('transportActions/updateTransport', transport);

            // set analytics event
            AnalyticsService.trackEvent('transport', {
                step: 'confirm_arrival',
                transport_id: transportId,
                assinged_driver_id: transport.driver.id,
                vehicle_id: transport.vehicle.id,
            });

            Toaster.success(i18n.t('actions.transportConfirmArrivalShipment'));
        } catch (err) {
            this.log.error(err);
            Toaster.error(err);
        }

        this.isPending = false;
    }
    /**
     * Fail transport with a reason
     *
     * @param {*} context
     * @param {*} transportId
     * @param {*} failCode
     * @param {*} failMessage
     */
    async fail(context, transportId, failCode, failMessage) {
        if (this.isPending) return;
        this.isPending = true;

        try {
            const transport = await this.api(context).failTransport(transportId, failCode, failMessage);
            store.commit('transportActions/updateTransport', transport);

            if (transport.status === 'in_transit' && transport.type === 'delivery') {
                TrackingService.stop(transportId).catch(error => {
                    Toaster.error(error);
                });
            }
        } catch (err) {
            this.log.error(err);
            Toaster.error(err);
        }

        this.isPending = false;
    }

    /**
     * Assign transport
     *
     * @param {*} context
     * @param {*} transportId
     */
    async assignTransport(context, transportId) {
        if (this.isPending) return;
        this.isPending = true;

        try {
            const transport = await this.api(context).assign(transportId);
            store.commit('transportActions/updateTransport', transport);
        } catch (err) {
            this.log.error(err);
        }

        this.isPending = false;
    }

    /**
     * Update transport prices for shipment transports
     *
     * @param {import('@/constants/context').Context} context
     * @param {number} transportId
     * @param {object} payload
     * @param {number} payload.purchasePrice
     * @param {number} payload.retailPrice
     * @param {boolean} payload.bulkUpdate
     */
    async updateTransportPriceShipment(
        context,
        transportId,
        payload = {
            purchasePrice: null,
            retailPrice: null,
            bulkUpdate: false,
        }
    ) {
        if (this.isPending) return;
        this.isPending = true;

        try {
            const transport = await this.api(context).updateTransportPriceShipment(transportId, payload);
            store.commit('transportActions/updateTransport', transport);
            Toaster.success(i18n.t('actions.transportUpdatedTransportPrices'));
            this.isPending = false;
        } catch (err) {
            this.log.error(err);
            this.isPending = false;
            throw err;
        }
    }

    /**
     * Cancel transport
     *
     * @param {*} context
     * @param {*} transportId
     */
    async cancelTransport(context, transportId) {
        if (this.isPending) return;
        this.isPending = true;

        try {
            const transport = await this.api(context).cancelTransport(transportId);
            store.commit('transportActions/updateTransport', transport);
            Toaster.info(
                i18n.t('pages.transport.cancelTransport.successMessage', {
                    number: transport.number,
                })
            );
        } catch (err) {
            this.log.error(err);
            Toaster.error(err);
        }

        this.isPending = false;
    }

    /**
     * Update transport freight information (description, images and documents) for type shipment
     *
     * @param {String} context
     * @param {Number} transportId
     * @param {Object} freightInformation
     * @param {Boolean} bulkUpdate
     */
    async updateTransportFreightInformationShipment(
        context,
        transportId,
        freightInformation = {
            description: '',
            documents: [],
            images: [],
            type: '',
        },
        bulkUpdate = false
    ) {
        if (this.isPending) return;
        this.isPending = true;

        try {
            const payload = {
                freightDescription: freightInformation.description,
                freightType: freightInformation.type,
                freightDocuments: freightInformation.documents?.map(document => {
                    return { uuid: document.uuid };
                }),
                freightImages: freightInformation.images?.map(image => {
                    return { uuid: image.uuid };
                }),
                bulkUpdate,
            };
            const transport = await this.api(context).updateTransportFreightInformationShipment(transportId, payload);
            store.commit('transportActions/updateTransport', transport);
            Toaster.success(i18n.t('actions.transportUpdateFreightInformationShipment'));
            this.isPending = false;
        } catch (err) {
            this.log.error(err);
            this.isPending = false;
            throw err;
        }
    }

    /**
     * Update disposal transport accompanying document number
     *
     * @param {Object} payload
     * @param {import('@/constants/context').Context | null} payload.context
     * @param {Number} payload.transportId
     * @param {String} payload.accompanyingDocumentNumber
     */
    async updateDisposalTransportAccompanyingDocumentNumber({ context, transportId, accompanyingDocumentNumber }) {
        if (this.isPending) return;
        this.isPending = true;
        try {
            const transport = await this.api(context).updateDisposalTransportAccompanyingDocumentNumber(
                transportId,
                accompanyingDocumentNumber
            );
            store.commit('transportActions/updateTransport', {
                id: transportId,
                ...transport,
            });
            Toaster.success(i18n.t('actions.updateDisposalTransportAccompanyingDocumentNumber'));
            this.isPending = false;
        } catch (err) {
            this.log.error(err);
            this.isPending = false;
            throw err;
        }
    }

    /**
     * Upload carrier accompanying related image
     *
     * @param {String} context
     * @param transportId
     * @param {import('@/types/Files.types').ServerDocumentFile | import('@/types/Files.types').ServerImageFile} file
     * @return {Promise<*>}
     */
    uploadCarrierAccompanyingImage(context, transportId, file) {
        return new Promise((resolve, reject) => {
            const { uuid } = file;
            this.imagesToUpload = [...this.imagesToUpload, { uuid, resolve, reject }];

            this.useDebouncedFunction('uploadCarrierAccompanyingImage', async () => {
                const imagesToUpload = this.imagesToUpload;
                this.imagesToUpload = [];

                try {
                    const uuids = imagesToUpload.map(({ uuid }) => uuid);

                    const carrierAccompanyingImages = await this.api(context).addCarrierAccompanyingImages(
                        transportId,
                        uuids
                    );

                    const transport = store.getters['transportActions/transport'];
                    store.commit('transportActions/updateTransport', {
                        ...transport,
                        carrierAccompanyingImages: _unionBy(
                            carrierAccompanyingImages,
                            transport.carrierAccompanyingImages,
                            'uuid'
                        ),
                    });

                    imagesToUpload.forEach(({ resolve }) => resolve?.());
                } catch (err) {
                    this.log.error(err);
                    imagesToUpload.forEach(({ reject }) => reject?.(err));
                }
            });
        });
    }

    /**
     * Upload carrier accompanying related document
     *
     * @param {String} context
     * @param transportId
     * @param {File} file
     * @return {Promise<*>}
     */
    uploadCarrierAccompanyingDocument(context, transportId, file) {
        return new Promise((resolve, reject) => {
            const { uuid } = file;
            this.documentsToUpload = [...this.documentsToUpload, { uuid, resolve, reject }];

            this.useDebouncedFunction('uploadCarrierAccompanyingDocument', async () => {
                const documentsToUpload = this.documentsToUpload;
                this.documentsToUpload = [];

                try {
                    const uuids = documentsToUpload.map(({ uuid }) => uuid);

                    const carrierAccompanyingDocuments = await this.api(context).addCarrierAccompanyingDocuments(
                        transportId,
                        uuids
                    );

                    const transport = store.getters['transportActions/transport'];
                    store.commit('transportActions/updateTransport', {
                        ...transport,
                        carrierAccompanyingDocuments: _unionBy(
                            carrierAccompanyingDocuments,
                            transport.carrierAccompanyingDocuments,
                            'uuid'
                        ),
                    });

                    documentsToUpload.forEach(({ resolve }) => resolve?.());
                } catch (err) {
                    this.log.error(err);
                    documentsToUpload.forEach(({ reject }) => reject?.(err));
                }
            });
        });
    }

    /**
     * Delete carrier accompanying related document or image
     *
     * @param {String} context
     * @param transportId
     * @param {import('@/types/Files.types').ServerDocumentFile | import('@/types/Files.types').ServerImageFile} file
     * @param {'image' | 'pdf'} uploadType
     * @param {object} acceptedUploadTypes
     * @return {Promise<*>}
     */
    deleteCarrierAccompanyingDocumentOrImage(context, transportId, file, uploadType) {
        const fileTypeDataMap = {
            image: {
                filesToDeleteKey: 'imagesToDelete',
                debouncedFunctionKey: 'deleteCarrierAccompanyingImage',
                apiMethodName: 'deleteCarrierAccompanyingImages',
                transportProperty: 'carrierAccompanyingImages',
            },
            pdf: {
                filesToDeleteKey: 'documentsToDelete',
                debouncedFunctionKey: 'deleteCarrierAccompanyingDocument',
                apiMethodName: 'deleteCarrierAccompanyingDocuments',
                transportProperty: 'carrierAccompanyingDocuments',
            },
        };

        return new Promise((resolve, reject) => {
            const fileTypeData = fileTypeDataMap[uploadType];
            if (!fileTypeData) return reject(new Error(i18n.t('components.fileUploader.file.validation.fileType')));

            this[fileTypeData.filesToDeleteKey].push({ uuid: file.uuid, resolve, reject });

            this.useDebouncedFunction(fileTypeData.debouncedFunctionKey, async () => {
                const filesToDelete = this[fileTypeData.filesToDeleteKey];
                this[fileTypeData.filesToDeleteKey] = [];

                try {
                    const uuids = filesToDelete.map(({ uuid }) => uuid);

                    const updatedFilesArray = _toArray(
                        await this.api(context)[fileTypeData.apiMethodName](transportId, uuids)
                    );

                    const transport = store.getters['transportActions/transport'];
                    store.commit('transportActions/updateTransport', {
                        ...transport,
                        [fileTypeData.transportProperty]: _intersectionBy(
                            transport[fileTypeData.transportProperty],
                            updatedFilesArray,
                            'uuid'
                        ),
                    });

                    filesToDelete.forEach(({ resolve }) => resolve?.());
                } catch (err) {
                    this.log.error(err);
                    filesToDelete.forEach(({ reject }) => reject?.(err));
                }
            });
        });
    }

    /**
     *
     * @param {object} params
     * @param {string} params.context
     * @param {string | number} params.transportId
     */
    async confirmDisposalLoading({ context, transportId } = {}) {
        try {
            const transport = await this.api(context).checkInLoading(transportId);
            store.commit('transportActions/updateTransport', transport);
            Toaster.success(i18n.t('components.activityCards.transport.action.loading.success'));
        } catch (err) {
            this.log.error(err);
            Toaster.error(err?.message || err);
        }
    }

    async updateUnloadingInformation(context, transportId, unloadingInformationPayload) {
        if (this.isPending) return;
        this.isPending = true;
        try {
            await this.api(context).updateUnloadingInformation(transportId, unloadingInformationPayload);

            Toaster.success(i18n.t('actions.updateUnloadingInformation'));
            this.isPending = false;
        } catch (err) {
            this.log.error(err);
            this.isPending = false;
            throw err;
        }
    }

    async updateLoadingInformation(context, transportId, loadingInformationPayload) {
        if (this.isPending) return;
        this.isPending = true;
        try {
            await this.api(context).updateLoadingInformation(transportId, loadingInformationPayload);

            Toaster.success(i18n.t('actions.updateLoadingInformation'));
            this.isPending = false;
        } catch (err) {
            this.log.error(err);
            this.isPending = false;
            throw err;
        }
    }
}

export default new TransportService();

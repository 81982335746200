export const setFactoryAvailability = product => {
    // check if product.factoryData.isAvailable has to be deactivated
    if (!product.isVariantsDisposalLoading) {
        const validAndActiveWasteVariants = (product.variantsDisposal || []).filter(variant => !!variant.isAvailable);

        if (!validAndActiveWasteVariants.length) {
            product.factoryData.isAvailable = false;
        } else {
            product.factoryData.isAvailable = true;
        }
    }
};

export const getPrices = variants => {
    return variants.map(variant => variant.pricePerTon).filter(pricePerTon => ![NaN, null].includes(pricePerTon));
};

export const getMinimumPrice = prices => {
    return prices.length ? Math.min(...prices) : null;
};

export const getMaximumPrice = prices => {
    return prices.length ? Math.max(...prices) : null;
};

export const getMinAndMaxPrices = prices => {
    if (!prices.length) return {};

    return {
        from: getMinimumPrice(prices),
        to: getMaximumPrice(prices),
    };
};

export const setPriceRangeBasedOnVariants = product => {
    product.factoryData.hasFactoryAnyAvailableProductVariant = !!(product.variantsDisposal || []).filter(
        variant => variant.isAvailable
    ).length;

    /* manipulate price range from and to based on product price ranges */
    if ((product.variantsDisposal || []).length) {
        // get current price range
        const prices = getPrices((product.variantsDisposal || []).filter(variant => variant.isAvailable));
        const range = getMinAndMaxPrices(prices);

        if ('from' in range === false) {
            delete product.factoryData.pricePerTonRange.from;
        }
        if ('to' in range === false) {
            delete product.factoryData.pricePerTonRange.to;
        }

        if (range.from >= 0 || range.to >= 0) {
            product.factoryData.pricePerTonRange.to = range.to;
            product.factoryData.pricePerTonRange.from = range.from;
        }
    }
};

const getters = {
    getCategories: state => {
        return state.categories.map(category => ({
            ...category,
            hasContent: state.products.findIndex(product => product.categoryIds.includes(category.id)) !== -1,
            counter: ((state.products || []).filter(product => product.categoryIds.includes(category.id)) || []).length,
        }));
    },
    getFactoryId: state => state.factoryId,
    getProductsOrderedByCategory: state => {
        const productsOrderedByCategories = [];

        state.products.forEach(product => {
            product.categoryIds.forEach(categoryId => {
                if (!productsOrderedByCategories[categoryId]) productsOrderedByCategories[categoryId] = [];

                if (!product.isVariantsDisposalLoading) {
                    setFactoryAvailability(product);
                    setPriceRangeBasedOnVariants(product);
                }

                productsOrderedByCategories[categoryId].push(product);
            });
        });

        return productsOrderedByCategories;
    },
};

export default getters;

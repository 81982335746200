<template>
    <CustomPriceAdjustmentsPage
        :state="basket"
        :prices="pricesData"
        :transports="currentTransports"
        :waste-meta-data="wasteMetaData"
        :can-save-section="canSaveSection"
        :section="section"
        is-project
        :project-position-id="projectPositionId"
        @edit-project-position="editDisposalProjectPosition"
        @calculate-project-position="calculateDisposalProjectPosition"
        @close-section="closeSection"
        @save-section="saveSection"
        @save-project-position="saveProjectPosition"
        @reset-price-data="resetPriceData"
        @submit="submit"
        @close="close"
    >
        <template #paymentTerms>
            <ReactBridge
                :react-component="PaymentTermsContainer"
                :props="{
                    organizations: getPaymentTerms({ disposer, carrierInfo }, 'disposal'),
                    paymentTermsProp: paymentTermsFromApi || requestCollection.paymentTerms,
                    paymentTermsProp: isProjectPositionEditMode
                        ? requestCollection.paymentTerms
                        : paymentTermsFromApi || requestCollection.paymentTerms,
                }"
                @change="handlePaymentTermsChange"
                @defaultPaymentTerms="setDefaultPaymentTerms"
            />
        </template>

        <template #billingMethod>
            <div class="mt-12">
                <span class="font-copy-md-strong mb-4">{{
                    $t('pages.checkout.disposal.summary.tiles.billingMethod.title')
                }}</span>
                <span class="font-copy-md">{{ getBillingMethod }}</span>
            </div>
            <HorizontalDivider size="12" />
        </template>

        <template #productCosts="{ error }">
            <WasteProductSection
                v-if="hasWaste"
                :prices-data="pricesData"
                :error="error"
                :waste-meta-data="wasteMetaData"
                @edit="editSection('waste')"
            />
        </template>

        <template #transports>
            <TransportSection
                v-if="hasTransport"
                class="mt-12"
                :basket-type="getBasketType"
                :fixed-price="hasFixedPrice"
                :transport-distance="pricesData.distance"
                :transport-duration="pricesData.duration"
                :transport-price-unit="transportPriceUnit"
                :transports="currentTransports"
                @edit="editSection('transport')"
            />
        </template>

        <template #customPosition>
            <ProjectCustomPositionsSection
                class="mt-12"
                :custom-positions="current.customPositions"
                :is-pending="isPending()"
                @edit="editCustomPosition"
                @remove="removeCustomPosition"
                @add="addCustomPosition"
            />
        </template>

        <template #materialTransport />
        <template #shipping />

        <template #costPreview>
            <CostPreviewSection
                v-if="
                    hasPreparedTransporstForCustomPositions &&
                    pricesDataFromStore.totalPricesPerSelectedVehicleClass &&
                    current &&
                    current.waste
                "
                class="mt-12"
                :transports="pricesDataFromStore.totalPricesPerSelectedVehicleClass"
                :material="{
                    ...current.waste.unfold(),
                    extension: `${wasteMetaData.wasteCode}${isHazardous ? '*' : ''}, ${
                        wasteMetaData.pollutionType.label
                    }`,
                }"
                :fixed-price="hasFixedPrice"
            />
        </template>

        <template #totals>
            <TotalsSection
                v-if="hasPreparedTransporstForCustomPositions && pricesDataFromStore.totalPrice"
                class="mt-12"
                :fixed-price="hasFixedPrice"
                :current="current"
                :material="{
                    qty: requestCollection.qty || 0,
                    info: `${wasteMetaData.wasteCode}${isHazardous ? '*' : ''}, ${wasteMetaData.pollutionType.label}`,
                }"
                :max-payload-transport="current.maxPayloadTransport"
                :total-transport-count="pricesDataFromStore.totalPrice.transportsOfLargestVehicleClassNeeded"
                :total-purchase-price="
                    hasFixedPrice
                        ? pricesDataFromStore.totalPrice.purchasePriceByTransport
                        : pricesDataFromStore.totalPrice.purchasePriceByTon
                "
                :total-retail-price="
                    hasFixedPrice
                        ? pricesDataFromStore.totalPrice.retailPriceByTransport
                        : pricesDataFromStore.totalPrice.retailPriceByTon
                "
                :type="'project-disposal'"
            />
        </template>

        <template #editWaste>
            <WasteProductSection
                v-if="section === 'waste'"
                :prices-data="pricesData"
                is-edit-mode
                :waste-meta-data="wasteMetaData"
                @edit="editSection('waste')"
            />
        </template>

        <template #editTransports>
            <section v-if="section === 'transport'">
                <WasteTransportEditSection
                    v-for="(vehicleClass, index) in current.transport.collection"
                    :key="vehicleClass.id"
                    v-model="current.transport.collection[index]"
                    :original-vehicle-class="original.transport.collection[index]"
                    :fixed-price="hasFixedPrice"
                    show-enable-controls
                    @selected-custom-price-unit="setCustomTransportPriceUnit"
                />
            </section>
        </template>

        <template #editCustomPosition>
            <CustomPositionEditSection
                v-if="section === 'customPosition'"
                v-model="customPositionData"
                is-disposal
                process-i18n-key="projectShipmentPriceAdjustments"
                @change="checkIfCanSaveSection"
            />
        </template>
    </CustomPriceAdjustmentsPage>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import _cloneDeep from 'lodash/cloneDeep';
import _omit from 'lodash/omit';
import _isNull from 'lodash/isNull';

import { toKgPrice } from '@/services/utils/units';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import { navigationFailure } from '@/services/utils/router';

import PriceAdjustmentView from '@/pages/Checkout/components/PriceAdjustment/models/PriceAdjustmentView';
import CustomPositionPricingSetView from '@/pages/Checkout/components/PriceAdjustment/models/CustomPositionPricingSetView';
import CustomPositionPricingItemView from '@/pages/Checkout/components/PriceAdjustment/models/CustomPositionPricingItemView';

import CustomPriceAdjustmentsPage from '@/pages/Checkout/CustomPriceAdjustmentsPage.vue';
import CostPreviewSection from '@/pages/Checkout/components/PriceAdjustment/CostPreviewSection.vue';
import TransportSection from '@/pages/Checkout/components/PriceAdjustment/TransportSection.vue';
import ProjectCustomPositionsSection from '@/pages/Checkout/components/PriceAdjustment/ProjectCustomPositionsSection.vue';
import WasteProductSection from '@/pages/Checkout/Disposal/ProjectPosition/PriceAdjustment/WasteProductSection.vue';
import TotalsSection from '@/pages/Checkout/components/PriceAdjustment/TotalsSection';

import CustomPositionEditSection from '@/pages/Checkout/components/PriceAdjustment/CustomPositionEditSection.vue';
import WasteTransportEditSection from '@/pages/Checkout/Disposal/ProjectPosition/PriceAdjustment/WasteTransportEditSection.vue';

import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import Toaster from '@/services/Toaster';
import { PaymentTermsContainer } from '@/pages/Checkout/PaymentTerms/PaymentTermsContainer';
import ReactBridge from '@/reactBridge/ReactBridge';
import HorizontalDivider from '@/_components/HorizontalDivider/HorizontalDivider';
import { usePaymentTerms } from '@/pages/Checkout/PaymentTerms/usePaymentTerms';

export default {
    name: 'CustomPriceAdjustmentsPageDisposal',
    components: {
        HorizontalDivider,
        CustomPriceAdjustmentsPage,

        CostPreviewSection,
        TransportSection,
        WasteProductSection,
        TotalsSection,

        CustomPositionEditSection,
        ProjectCustomPositionsSection,
        WasteTransportEditSection,
        ReactBridge,
    },
    mixins: [statefulMixin],
    props: {
        projectPositionId: {
            type: [Number, String],
            default: null,
        },
    },
    setup() {
        const { getPaymentTerms, preparePaymentTermDataForStore, paymentTermsFromApi } = usePaymentTerms();
        return {
            PaymentTermsContainer,
            getPaymentTerms,
            preparePaymentTermDataForStore,
            paymentTermsFromApi,
        };
    },
    data() {
        return {
            pricesData: null,
            toasterError: null,
            wasteMetaData: null,
            section: null,
            canSaveSection: null,
            disposalPrices: null,
            customPositionData: null,
            customTransportPriceUnit: [],
        };
    },
    computed: {
        ...mapState('projectPosition', {
            requestCollection: 'requestCollection',
            pricesDataFromStore: 'pricesData',
            customPrices: 'customPrices',
            temporaryTransportPrices: 'temporaryTransportPrices',
            disposer: 'disposer',
            carrierInfo: 'carrierInfo',
            defaultPaymentTerms: 'defaultPaymentTerms',
            error: 'error',
        }),
        ...mapActions('projectPosition', ['fetchProjectPositionPrices']),
        basket() {
            return {
                isCustom: true,
                constructionSiteId: this.requestCollection.constructionSite?.id,
                factoryProductVariantId: this.requestCollection.factoryProductVariantId,
                projectId: this.requestCollection.project?.id,
                qty: this.requestCollection.qty,
                type: this.getBasketType,
                shipmentBillingDetails: {
                    billingMethod: this.requestCollection.billingMethod,
                    weighingAtUnloadingPoint: this.requestCollection.weighingAtUnloadingPoint,
                    weighingAtLoadingPoint: false,
                    weighingBillingBasis: this.requestCollection.weighingBillingBasis,
                },
                billingByStateWasteCompanyId: this.isBilledByStateWasteCompany,
            };
        },
        isProjectPositionEditMode() {
            return !!this.projectPositionId;
        },
        current() {
            return this.pricesData?.current;
        },
        original() {
            return this.pricesData?.original;
        },
        currentTransports() {
            const transports = _cloneDeep(this.current?.transport);

            return this.temporaryTransportPrices ?? transports;
        },
        hasFixedPrice() {
            return this.requestCollection.billingMethod === 'fixedPrice';
        },
        getBasketType() {
            return this.requestCollection.type;
        },
        getBillingMethod() {
            if (this.requestCollection?.billingMethod === 'weight') {
                return this.$t('pages.checkout.shipmentBillingDetails.billingMethodLabel.weight');
            }
            return this.$t('pages.checkout.shipmentBillingDetails.billingMethodLabel.fixedPrice');
        },
        hasWaste() {
            return !!this.original.waste;
        },
        hasTransport() {
            return !!this.original.transport;
        },
        hasOneEnabledTransport() {
            return this.current.enabledTransports?.length > 0;
        },
        hasPreparedTransporstForCustomPositions() {
            return !!this.current?.enabledTransports?.length;
        },
        hasCustomPositions() {
            return !!this.current?.customPositions?.collection?.length;
        },
        isHazardous() {
            return this.requestCollection.type === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS;
        },
        transportPriceUnit() {
            return this.hasFixedPrice ? 'transport' : 'ton';
        },
        transportsForCalculateWaste() {
            const priceUnits = this.customTransportPriceUnit;
            return this.current?.transport?.collection
                .filter(t => t.enabled === true)
                .map(t => {
                    const priceUnitObject = priceUnits.filter(unit => t.vehicleClassId === unit.vehicleClassId);
                    const unit = priceUnitObject?.[0]?.unit || 'ton';
                    return {
                        vehicleClassId: t.vehicleClassId,
                        purchasePrice:
                            unit === 'ton'
                                ? toKgPrice(t.purchaseUnitPrice.getPerTon())
                                : t.purchaseUnitPrice.getPerTransport(),
                        retailPrice:
                            unit === 'ton'
                                ? toKgPrice(t.retailUnitPrice.getPerTon())
                                : t.retailUnitPrice.getPerTransport(),
                        priceUnit: unit === 'ton' ? 'kg' : 'transport',
                    };
                });
        },
        transportsForPostProjectPosition() {
            return this.current?.transport?.collection
                .filter(t => t.enabled === true)
                .map(t => {
                    return {
                        id: t.vehicleClassId,
                        purchasePrice: toKgPrice(t.purchaseUnitPrice.getPerTon()),
                        retailPrice: toKgPrice(t.retailUnitPrice.getPerTon()),
                    };
                });
        },
        customPositionsForPost() {
            return this.current?.customPositions?.collection.map(vehicleClassPositions => {
                return vehicleClassPositions.positions.map(position => {
                    return {
                        name: position.name,
                        unit: position.unit,
                        qty: position.qty,
                        unitPurchasePrice: position.purchaseUnitPrice,
                        unitRetailPrice: position.retailUnitPrice,
                        creditTo: position.creditTo,
                    };
                });
            })[0];
        },
    },
    methods: {
        setDefaultPaymentTerms(terms) {
            this.$store.commit('projectPosition/SET_DEFAULT_PAYMENT_TERMS', terms);
        },
        handlePaymentTermsChange(paymentTerm) {
            const paymentTermsForStore = this.preparePaymentTermDataForStore(
                paymentTerm,
                this.requestCollection?.paymentTerms
            );
            this.$store.commit('projectPosition/SET_PAYMENT_TERMS', paymentTermsForStore);
        },
        async editDisposalProjectPosition() {
            await this.$store.dispatch('projectPosition/fetchProjectPosition', {
                projectPositionId: this.projectPositionId,
            });

            this.wasteMetaData = _omit(this.pricesDataFromStore.product, ['originalPrices', 'customPrices']);

            this.paymentTermsFromApi = this.requestCollection?.paymentTerms;
            this.$store.commit('projectPosition/SET_PAYMENT_TERMS', this.paymentTermsFromApi);
            this.setDefaultPaymentTerms(this.paymentTermsFromApi);
            this.pricesData = PriceAdjustmentView.createFromDisposalPositionPayload(
                parseInt(this.requestCollection.qty),
                this.pricesDataFromStore
            );
        },
        async saveSection() {
            if (this.section === 'transport') {
                this.$store.commit('projectPosition/SET_TEMPORARY_TRANSPORT_PRICES', this.current.transport);
            }

            if (this.section === 'customPosition') {
                if (!this.current?.customPositions?.collection?.length) {
                    await this.initializeCustomPositions();
                }

                const position = this.customPositionData;
                if (position.positionIndex !== null) {
                    this.current.customPositions
                        .get(position.vehicleClassId)
                        .setPosition(position.positionIndex, position);
                } else {
                    this.current.customPositions.get(position.vehicleClassId).addPosition(position);
                }
                this.updatePriceDataWithCustomPositionsInStore(position.vehicleClassId);
            } else {
                const noPricesSpecified = Object.keys(this.pricesDataFromStore.product.originalPrices).length === 0;
                const noCustomPrices =
                    _isNull(this.customPrices.purchaseUnitPrice) && _isNull(this.customPrices.retailUnitPrice);

                if (noPricesSpecified && noCustomPrices) {
                    this.$store.commit('projectPosition/SET_PRICES_DATA', {
                        ...this.pricesDataFromStore,
                        product: {
                            ...this.pricesDataFromStore.product,
                            customPrices: {
                                purchaseUnitPrice: null,
                                retailUnitPrice: null,
                            },
                        },
                    });
                } else {
                    this.$store.commit('projectPosition/SET_PRICES_DATA', {
                        ...this.pricesDataFromStore,
                        product: {
                            ...this.pricesDataFromStore.product,
                            customPrices: {
                                purchaseUnitPrice:
                                    this.customPrices.purchaseUnitPrice ??
                                    toKgPrice(this.pricesData.current.waste.purchaseUnitPrice) ??
                                    this.pricesDataFromStore.product.originalPrices.purchaseUnitPrice,
                                retailUnitPrice:
                                    this.customPrices.retailUnitPrice ??
                                    toKgPrice(this.pricesData.current.waste.retailUnitPrice) ??
                                    this.pricesDataFromStore.product.originalPrices.retailUnitPrice,
                            },
                        },
                    });
                }
            }

            await this.fetchWastePriceData();

            this.closeSection();
        },
        updatePriceDataWithCustomPositionsInStore(vehicleClassId) {
            const customPositions = this.current?.customPositions.unfold()[vehicleClassId].positions;
            this.$store.commit('projectPosition/SET_PRICES_DATA', {
                ...this.pricesDataFromStore,
                customPositions,
            });
        },
        async calculateDisposalProjectPosition() {
            if (!this.pricesDataFromStore || !this.pricesDataFromStore?.product?.customPrices) {
                await this.$store.dispatch('projectPosition/fetchProjectPositionPrices', {
                    customPurchaseUnitPrice: null,
                    customRetailUnitPrice: null,
                    enabledVehicleClassPrices: null,
                    customPositions: [],
                });
            }

            this.wasteMetaData = _omit(this.pricesDataFromStore.product, ['originalPrices', 'customPrices']);

            this.pricesData = PriceAdjustmentView.createFromDisposalPositionPayload(
                parseInt(this.requestCollection.qty),
                this.pricesDataFromStore
            );
        },
        async submit() {
            if (!this.hasOneEnabledTransport) return;

            this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(navigationFailure);
        },

        editSection(section) {
            this.section = section;
        },
        closeSection() {
            this.canSaveSection = false;
            this.section = null;
        },
        async fetchWastePriceData() {
            const wasteInKg = this.pricesDataFromStore?.product?.customPrices
                ? this.pricesDataFromStore?.product?.customPrices
                : this.pricesDataFromStore?.product?.originalPrices;

            await this.$store.dispatch('projectPosition/fetchProjectPositionPrices', {
                customPurchaseUnitPrice: wasteInKg.purchaseUnitPrice,
                customRetailUnitPrice: wasteInKg.retailUnitPrice,
                enabledVehicleClassPrices: this.transportsForCalculateWaste,
                customPositions: this.customPositionsForPost,
            });

            this.$store.commit('projectPosition/SET_CUSTOM_PRICE_DATA', {
                wastePrices: {
                    purchaseUnitPrice: this.current?.waste?.purchaseUnitPrice,
                    retailUnitPrice: this.current?.waste?.retailUnitPrice,
                },
                transportPrices: this.transportsForPostProjectPosition.map(vehicleClass => ({
                    vehicleClass: { id: vehicleClass.id },
                    purchasePrice: vehicleClass.purchasePrice,
                    retailPrice: vehicleClass.retailPrice,
                })),
                customPositions: this.customPositionsForPost,
            });

            this.pricesData = PriceAdjustmentView.createFromDisposalPositionPayload(
                parseInt(this.requestCollection.qty),
                this.pricesDataFromStore
            );
        },
        resetPriceData() {
            this.$store.commit('projectPosition/RESET_CUSTOM_PRICE_DATA');
            this.$store.commit('projectPosition/RESET_PAYMENT_TERMS');
            this.pricesData.current = _cloneDeep(this.pricesData.original);
            this.calculateDisposalProjectPosition();
        },
        setCustomTransportPriceUnit({ unit, vehicleClassId }) {
            if (!this.customTransportPriceUnit.find(priceUnit => priceUnit.vehicleClassId === vehicleClassId)) {
                this.customTransportPriceUnit.push({
                    vehicleClassId,
                    unit,
                });
            } else {
                this.customTransportPriceUnit.find(priceUnit => priceUnit.vehicleClassId === vehicleClassId).unit =
                    unit;
            }
        },
        initializeCustomPositions() {
            this.pricesData.current.customPositions =
                CustomPositionPricingSetView.createFromEnabledVehicleClassesDisposal(
                    this.hasPreparedTransporstForCustomPositions,
                    // customPositions
                    this.current.customPositions
                );
        },
        editCustomPosition({ cid, position }) {
            const pos = position.clone();
            pos.positionIndex = cid;

            this.customPositionData = pos;

            this.section = 'customPosition';
        },
        async addCustomPosition({ vid, count, vehiclePayload }) {
            const position = new CustomPositionPricingItemView();

            position.vehicleClassId = vid;
            position.positionIndex = null;
            position.transportDistance = this.pricesData.distance;
            position.vehiclePayload = vehiclePayload;
            position.count = count;
            position.creditTo = 'none';

            this.customPositionData = position;
            this.section = 'customPosition';
            await this.fetchWastePriceData();
        },
        async removeCustomPosition({ vid, cid }) {
            this.current.customPositions.get(vid).removePosition(cid);

            this.updatePriceDataWithCustomPositionsInStore(vid);

            await this.fetchWastePriceData();
        },
        checkIfCanSaveSection({ isValid, value }) {
            this.canSaveSection = isValid;

            this.customPositionData = value;
        },
        async saveProjectPosition() {
            await this.stateful('saveProjectPosition', async () => {
                try {
                    const projectPosition = await this.$store.dispatch('projectPosition/postProjectPosition', {
                        projectPositionId: this.projectPositionId,
                    });

                    if (this.error) {
                        Toaster.error(this.error);
                        return;
                    }

                    Toaster.success(
                        this.$t('pages.checkout.priceAdjustments.savePricePositionSuccessMessage', {
                            number: projectPosition.number,
                        })
                    );

                    this.$emit('projectPositionSaved');
                } catch (err) {
                    Toaster.error(err);
                }
            });
        },
        close() {
            this.$emit('close');
        },
    },
};
</script>

<template>
    <div class="interval-fieldset">
        <div class="interval-fieldset__pills">
            <button
                v-for="m in INTERVAL_MODES"
                :key="m"
                :class="{ 'interval-fieldset__pill--active': selectedMode === m }"
                class="interval-fieldset__pill"
                @click="changeMode(m)"
            >
                <Words small bold>{{ $tc(`components.intervalFieldset.units.${m}`, 2) }}</Words>
            </button>
        </div>
        <div class="interval-fieldset__controls">
            <div class="interval-fieldset__stage">
                <Words bold>{{ visibleValue }}</Words>
                <Words class="interval-fieldset__unit">
                    {{ $tc(`components.intervalFieldset.units.${selectedMode}`, visibleValue) }}
                </Words>
            </div>
            <div class="interval-fieldset__buttons">
                <BaseButton :disabled="value === 0" orb @click="decrease">
                    <MinusIcon class="icon--red" />
                </BaseButton>
                <BaseButton :disabled="false" orb @click="increase">
                    <PlusIcon />
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
import Words from '@/components/Typography/Words';
import BaseButton from '@/components/Button/Button';
import PlusIcon from '@/assets/icons/micro/plus.svg';
import MinusIcon from '@/assets/icons/micro/minus.svg';

/**
 * Enum for direction
 * @enum {string}
 */
const Direction = {
    DECREASE: 'decrease',
    INCREASE: 'increase',
};

const INTERVAL_MODES = ['minutes', 'hours', 'days'];
const INTERVAL_MODES_LIMIT = {
    minutes: 60,
    hours: 480,
    days: Infinity,
};

const INTERVAL_MODES_DEFAULTS = {
    minutes: 30,
    hours: 60,
    days: 24 * 60,
};

const INTERVAL_MODES_INCREMENTS = {
    /**
     * Interval steps for minutes
     * @param {Direction} direction
     * @param {number} fromValue
     * @return {number}
     */
    minutes: (direction, fromValue) => {
        /* 5 minutes steps below 15 and 15 minutes steps above -> 5, 10, 15, 30, 45 */
        const threshold = 15;
        const stepsBelow = 5;
        const stepsAbove = 15;

        if (fromValue < threshold) {
            return stepsBelow;
        } else if (fromValue === threshold) {
            return direction === Direction.INCREASE ? stepsAbove : stepsBelow;
        } else {
            return stepsAbove;
        }
    },
    hours: () => 60,
    days: () => 24 * 60,
};

const DEFAULT_MODE = INTERVAL_MODES[0];

export default {
    name: 'IntervalFieldset',
    components: {
        BaseButton,
        PlusIcon,
        MinusIcon,
        Words,
    },
    props: {
        value: {
            type: Number,
            default: null,
        },
        mode: {
            type: String,
            default: DEFAULT_MODE,
            validator: v => INTERVAL_MODES.includes(v),
        },
        limits: {
            type: Object,
            default: () => ({}),
            required: false,
        },
    },
    data() {
        return {
            INTERVAL_MODES,
            selectedMode: this.mode,
        };
    },
    computed: {
        visibleValue() {
            const value = this.value || 0;

            switch (this.selectedMode) {
                case 'minutes':
                    return value;
                case 'hours':
                    return value / 60;
                case 'days':
                    return value / (24 * 60);
                default:
                    return value;
            }
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(newValue) {
                if (newValue === null) {
                    this.changeMode(DEFAULT_MODE);
                }
            },
        },
    },
    methods: {
        /**
         * @param {Direction} direction
         * @param {string} mode
         * @return {number}
         */
        getModifierValue(direction, mode = null) {
            const currentMode = mode || this.selectedMode;
            const modifier = INTERVAL_MODES_INCREMENTS[currentMode];

            return modifier(direction, this.value);
        },

        getDefaultValue(mode = null) {
            return INTERVAL_MODES_DEFAULTS[mode || this.selectedMode];
        },

        getModeLimit(mode = null) {
            const limits = Object.assign({}, INTERVAL_MODES_LIMIT, this.limits);
            return limits[mode || this.selectedMode];
        },

        changeMode(mode) {
            this.selectedMode = mode;
            this.reset();
        },

        reset() {
            this.$emit('input', this.getDefaultValue());
        },

        increase() {
            const value = this.value || 0;
            let nextValue = value + this.getModifierValue(Direction.INCREASE);
            let nextMode;

            if (nextValue >= this.getModeLimit(this.selectedMode)) {
                const index = INTERVAL_MODES.indexOf(this.selectedMode);
                nextMode = INTERVAL_MODES[index + 1] ? INTERVAL_MODES[index + 1] : this.selectedMode;
                nextValue = this.getDefaultValue(nextMode);
                this.selectedMode = nextMode;
            }

            this.$emit('input', nextValue);
        },

        decrease() {
            const value = this.value || 0;
            let nextValue = value - this.getModifierValue(Direction.DECREASE);
            let nextMode;
            const currIndex = INTERVAL_MODES.indexOf(this.selectedMode);

            if (nextValue === 0 && currIndex > 0) {
                nextMode = INTERVAL_MODES[currIndex - 1] ? INTERVAL_MODES[currIndex - 1] : this.selectedMode;
                nextValue = this.getModeLimit(nextMode) - this.getModifierValue(Direction.DECREASE, nextMode);
                this.selectedMode = nextMode;
            }

            this.$emit('input', nextValue > 0 ? nextValue : 0);
        },
    },
};
</script>

<style lang="scss">
.interval-fieldset {
}

.interval-fieldset__pills {
    margin: -20px 0 25px -20px;
}

.interval-fieldset__pill {
    display: inline-block;
    border: 2px solid $color-black;
    padding: 8px 12px 7px;
    border-radius: 7px;
    margin: 20px 0 0 20px;
    background-color: transparent;
    opacity: 0.3;
    outline: none;
    cursor: pointer;
    transform: scale(1);
    transition:
        opacity 0.1s ease-out,
        transform 0.2s ease-out;

    &:active {
        transform: scale(0.9);
    }
}

.interval-fieldset__pill--active {
    opacity: 1;
}

.interval-fieldset__controls {
    display: flex;
    justify-content: space-between;
}

.interval-fieldset__buttons {
    max-width: 120px;
    width: 100%;
    margin: 0 20px;
    display: flex;
    justify-content: space-between;
}

.interval-fieldset__stage {
    font-size: 20px;
}

.interval-fieldset__unit {
    display: inline-block;
    margin-left: 10px;
}
</style>

<template>
    <ModalBox
        v-if="isEnabled"
        :active="deleteOrganizationOpen"
        :headline="$t('pages.organization.delete.headline')"
        spaceless
        class="organization-delete-action"
        @closed="close()"
    >
        <div class="organization-delete-action__block">
            <Tile v-if="canDeleteOrganization === null" no-border-bottom transparent>
                <LoadingSpinner block dark />
            </Tile>

            <template v-else-if="canDeleteOrganization">
                <Tile transparent no-border>
                    <Words block bold>{{ subject.name }} ({{ subject.customerNumber }})</Words>
                    <Words block muted small>
                        <FormattedAddress :address="subject.billingAddress" />
                    </Words>
                </Tile>
                <Tile transparent no-border>
                    <Words block spaced-bottom>
                        <i18n path="pages.organization.delete.message" tag="span">
                            <Words place="keyword" bold>{{ deleteKeyword }}</Words>
                        </i18n>
                    </Words>

                    <TextField v-model="confirmText" :label="$t('pages.organization.delete.confirmationLabel')" />
                </Tile>
            </template>

            <template v-else>
                <Tile transparent no-border>
                    <Words block bold>{{ subject.name }} ({{ subject.customerNumber }})</Words>
                    <Words block muted small>
                        <FormattedAddress :address="subject.billingAddress" />
                    </Words>
                </Tile>

                <Tile transparent no-border>
                    <Words block spaced-bottom>
                        {{ $t('pages.organization.delete.unableToDelete') }}
                    </Words>
                </Tile>

                <Tile v-if="openOrders && openOrders.count > 0" transparent no-border>
                    <Words block bold spaced-bottom>{{ $t('pages.organization.delete.ordersHeadline') }}</Words>
                    <div class="organization-delete-action__list">
                        <div
                            v-for="order in openOrders.items"
                            :key="order.id"
                            class="organization-delete-action__item"
                            @click="viewOrder(order.id)"
                        >
                            {{ order.number }}
                            <Words bold tiny yellow>
                                {{ $t(`pages.order.orderStatus.${order.status}`) }}
                            </Words>
                        </div>
                    </div>
                    <Words v-if="openOrders.count > openOrders.appliedFilter.perPage" block spaced-top muted centered>
                        {{
                            $tc(
                                'pages.organization.delete.moreOrdersLabel',
                                openOrders.count - openOrders.appliedFilter.perPage
                            )
                        }}
                    </Words>
                </Tile>

                <Tile v-if="openTransports && openTransports.count > 0" transparent no-border>
                    <Words block bold spaced-bottom>{{ $t('pages.organization.delete.transportsHeadline') }}</Words>
                    <div class="organization-delete-action__list">
                        <div
                            v-for="transport in openTransports.items"
                            :key="transport.id"
                            class="organization-delete-action__item"
                            @click="viewTransport(transport.id)"
                        >
                            {{ transport.number }}
                            <Words bold tiny yellow>
                                {{ statusText(transport.status) }}
                            </Words>
                        </div>
                    </div>
                    <Words
                        v-if="openTransports.count > openTransports.appliedFilter.perPage"
                        block
                        spaced-top
                        muted
                        centered
                    >
                        {{
                            $tc(
                                'pages.organization.delete.moreTransportsLabel',
                                openTransports.count - openTransports.appliedFilter.perPage
                            )
                        }}
                    </Words>
                </Tile>
            </template>
        </div>

        <ButtonGroup slot="actions">
            <BaseButton :is-pending="isPending" place-left primary arrow-left light @click="close()">
                {{ $t('pages.organization.delete.actions.cancel') }}
            </BaseButton>

            <BaseButton
                v-if="canDeleteOrganization"
                data-test="button-delete-organization"
                :is-pending="isPending"
                :disabled="!canDelete"
                primary
                @click="deleteOrganization()"
            >
                {{ $t('pages.organization.delete.actions.delete') }}
            </BaseButton>
        </ButtonGroup>
    </ModalBox>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderApi from '@/services/Api/Order';
import TransportListApi from '@/services/Api/TransportList';
import OrganizationApi from '@/services/Api/Organization';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { EVENT_ORGANIZATION_DELETED } from '@/constants/events';
import { ACTIONS } from '@/constants/actions';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import FormattedAddress from '@/components/FormattedAddress';
import LoadingSpinner from '@/components/LoadingSpinner';
import ModalBox from '@/components/Modal/ModalBox';
import TextField from '@/components/Form/TextField.v2';
import Tile from '@/components/Layout/Tile';
import Words from '@/components/Typography/Words';
import { transportStatus, statusText } from '@/services/utils/transport';
import Toaster from '@/services/Toaster';
import routeContext from '@/plugins/mixins/routeContext';

const transportListApi = new TransportListApi('platform');

export default {
    name: 'OrganizationDeleteAction',
    components: {
        BaseButton,
        ButtonGroup,
        FormattedAddress,
        LoadingSpinner,
        ModalBox,
        TextField,
        Tile,
        Words,
    },
    mixins: [eventHubMixin, routeContext],
    data() {
        return {
            isPending: false,
            confirmText: null,
            canDeleteOrganization: false,

            openOrders: null,
            openTransports: null,
        };
    },
    computed: {
        ...mapGetters('globalActions', ['subject', 'action']),

        isEnabled() {
            return this.deleteOrganizationOpen && this.isActionAvailable;
        },

        deleteKeyword() {
            return this.$t('pages.organization.delete.keyword');
        },

        deleteOrganizationOpen() {
            return this.action === ACTIONS.ORGANIZATION_DELETE;
        },

        canDelete() {
            return this.confirmText === this.deleteKeyword;
        },

        /**
         * Should this action be available based on the current conditions
         */
        isActionAvailable() {
            const organization = this.subject;

            if (!organization) {
                return false;
            }

            return this.$can('deleteOrganization', organization);
        },
    },
    watch: {
        deleteOrganizationOpen(status) {
            if (this.isActionAvailable && status) {
                this.checkOpenOperationsForOrganization();
            }
        },
    },
    methods: {
        statusText(status) {
            return statusText(this.routeContext, status);
        },

        close() {
            this.confirmText = null;
            this.$store.commit('globalActions/resetAction', ACTIONS.ORGANIZATION_DELETE);
        },

        async checkOpenOperationsForOrganization() {
            if (this.subject.types.includes('platform')) return;

            this.canDeleteOrganization = null;
            this.openOrders = null;
            this.openTransports = null;

            try {
                // Client
                if (this.subject.types.includes('client')) {
                    this.openOrders = await OrderApi.filter({
                        status: ['new', 'in_progress'],
                        organization: this.subject.id,
                    });
                    this.canDeleteOrganization = this.canDeleteOrganization !== false && this.openOrders.count === 0;
                }

                // Carrier
                if (this.subject.types.includes('carrier')) {
                    this.openTransports = await transportListApi.filter({
                        status: transportStatus.filter(
                            status => !['delivered', 'failed', 'cancelled'].includes(status)
                        ),
                        carrierOrganization: this.subject.id,
                    });

                    this.canDeleteOrganization =
                        this.canDeleteOrganization !== false && this.openTransports.count === 0;
                }

                // Supplier
                if (this.subject.types.includes('supplier')) {
                    this.openTransports = await transportListApi.filter({
                        status: ['new', 'assigned', 'planned', 'started', 'checked_in', 'checked_in_loading', 'loaded'],
                        supplierOrganization: this.subject.id,
                    });

                    this.canDeleteOrganization =
                        this.canDeleteOrganization !== false && this.openTransports.count === 0;
                }

                // No types
                if (this.subject.types.length === 0) {
                    this.canDeleteOrganization = true;
                }
            } catch (err) {
                window.err = err;
                this.canDeleteOrganization = null;
                Toaster.error(this.$t('pages.organization.delete.errors.loading'));
            }
        },

        viewOrder(orderId) {
            const nextRoute = this.$router.resolve({
                name: this.$root.findRouteName('order-list__order-view'),
                params: { orderId },
            });

            window.open(nextRoute.href, '_blank');
        },

        viewTransport(transportId) {
            const nextRoute = this.$router.resolve({
                name: this.$root.findRouteName('transport-hub__transport-view'),
                params: { transportId },
            });

            window.open(nextRoute.href, '_blank');
        },

        async deleteOrganization() {
            if (this.isPending) return;
            this.isPending = true;
            this.confirmText = null;

            try {
                await OrganizationApi.delete(this.subject.id);
                Toaster.success(this.$t('pages.organization.delete.success.delete', { name: this.subject.name }));
                this.$eventHub.$emit(EVENT_ORGANIZATION_DELETED, this.subject);
            } catch (err) {
                Toaster.error(this.$t('pages.organization.delete.errors.delete'));
            }

            this.isPending = false;
            this.close();
        },
    },
};
</script>

<style lang="scss">
.organization-delete-action__block {
    border-top: 1px solid $color-mediumGrey;
    background-color: $color-lightGrey;
}

.organization-delete-action__list {
    margin: 2px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    justify-items: start;
}

.organization-delete-action__item {
    cursor: pointer;
    display: flex;
    flex-flow: column;
}

.organization-delete-action__item:hover {
    opacity: 0.5;
}
</style>

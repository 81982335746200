<template>
    <section>
        <Accordion v-for="license in $options.licenseList" :key="license.name" :open-on-init="false" class="license">
            <template #header>
                {{ license.name }}
            </template>
            <template #default>
                <div class="license__content">
                    <LicenseText :license-path="license.licenseFile" />
                </div>
            </template>
        </Accordion>
    </section>
</template>

<script>
import licenseListFrontend from '@/assets/licenseListFrontend.json';
import licenseListBackend from '@/assets/licenseListBackend.json';
import licenseListInfrastructure from '@/assets/licenseListInfrastructure.json';
import Accordion from '@/_components/Accordion/Accordion';
import LicenseText from '@/components/LegalInformation/LicenseText';

const licenseList = [...licenseListFrontend, ...licenseListBackend, ...licenseListInfrastructure];

export default {
    licenseList,
    components: {
        Accordion,
        LicenseText,
    },
};
</script>

<style lang="scss" scoped>
.license {
    margin-bottom: 1rem;
    &__content {
        padding: 1rem;
    }
}
</style>

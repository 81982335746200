<template>
    <div>
        <div data-test="help-link" class="ml-2 flex cursor-pointer flex-row gap-1 md:ml-0" @click="handleClick">
            <HelpIcon />
            <Words class="text-dark-gray" block bold small>{{ title }}</Words>
        </div>

        <DialogModal v-if="dialogModalOptions.active" :options="dialogModalOptions" @close="closeModal">
            <component :is="type" />
        </DialogModal>
    </div>
</template>

<script>
import HelpIcon from '@/assets/icons/regular/help-dark.svg';

import DialogModal from '@/_components/DialogModal/DialogModal';
import HelpFileUploader from '@/_components/DialogModal/modalContent/HelpFileUploader';
import HelpLandfillClass from '@/_components/DialogModal/modalContent/HelpLandfillClass';
import HelpLocationType from '@/_components/DialogModal/modalContent/HelpLocationType';
import HelpSpecialHint from '@/_components/DialogModal/modalContent/HelpSpecialHint';
import Words from '@/components/Typography/Words';

export default {
    name: 'HelpLink',
    components: {
        DialogModal,
        HelpLocationType,
        HelpFileUploader,
        HelpLandfillClass,
        HelpSpecialHint,
        HelpIcon,
        Words,
    },
    props: {
        title: {
            type: String,
            default: 'Hilfe',
        },
        type: {
            type: String,
            default: null,
            required: false,
        },
    },
    data() {
        return {
            showDialogModal: false,
            dialogModalOptions: {
                active: false,
                closeWithCross: true,
            },
        };
    },
    methods: {
        handleClick() {
            if (this.type) {
                this.dialogModalOptions.active = true;
            }
        },
        closeModal() {
            this.dialogModalOptions.active = false;
        },
    },
};
</script>

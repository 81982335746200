<template>
    <div class="note-section my-6">
        <HorizontalDivider v-if="showDivider" class="my-6" />

        <h3 class="font-copy-md-strong">
            {{ $t('pages.checkout.summary.projectPositionNoteLabel') }}
        </h3>
        <h4 class="font-copy-md my-2">
            {{ $t('pages.checkout.summary.projectPositionNoteHint') }}
        </h4>

        <TextField
            v-model="internalValue"
            type="textarea"
            :label="$t('pages.checkout.summary.projectPositionNotePlaceholder')"
            :has-error="hasErrors('internalValue')"
            :error="getError('internalValue')"
            :name="name"
            :rows="4"
            @input="updateNote"
        />
    </div>
</template>

<script type="ts">
import Vue from 'vue';

import validation from '@/services/validation/mixin';
import { maxLength } from '@/services/validation/rules';

import TextField from '@/components/Form/TextField.v2.vue';
import HorizontalDivider from '@/_components/HorizontalDivider/HorizontalDivider.vue';

export default Vue.extend({
    name: 'NoteSection',
    components: {
        TextField,
        HorizontalDivider,
    },
    mixins: [validation],
    props: {
        value: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: 'summary.projectPositionNote',
        },
        parentValidationRules: {
            type: Object,
            default: () => ({}),
        },
        showDivider: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            internalValue: this.value,
        };
    },
    computed: {
        validationRules() {
            const rules = {
                'internalValue': [...(this.parentValidationRules[this.name] || []), maxLength(255)]
            };
            return rules;
        },
    },
    methods: {
        updateNote() {
            this.partialValidation('internalValue');
            this.$emit('input', this.internalValue);
        }
    },
});
</script>

<template>
    <div
        :class="{
            'datetime-picker--active': isVisuallyOpen,
            'datetime-picker--invalid': isInvalid,
            'datetime-picker--desktop': $root.isDesktop,
        }"
        class="datetime-picker"
    >
        <div data-test="transport-plan-time-picker" class="datetime-picker__trigger" @click="open()">
            <slot :is-invalid="isInvalid">Please provide a default</slot>
        </div>
        <template v-if="isActive">
            <div class="datetime-picker__backdrop" @click="closeByBackdrop" />
            <div class="datetime-picker__body" @click="closeByBackdrop">
                <div class="datetime-picker__content" @click="preventContentClicks">
                    <div
                        v-scrollable
                        :class="{
                            'datetime-picker__inner--no-footer': !$slots.bottom,
                        }"
                        class="datetime-picker__inner scroll-container"
                    >
                        <div v-if="$root.isDesktop" class="datetime-picker__desktop-wrapper">
                            <Tile background-transparent>
                                <Calendar
                                    :value="activeDayCalendar"
                                    type="single"
                                    :allowed-dates="availableDates"
                                    :min-date="min ? ensureDate(min) : null"
                                    :max-date="max ? ensureDate(max) : null"
                                    @input="handleCalendarInput"
                                />
                            </Tile>
                            <Tile v-if="isTypeDatetime" no-border background-transparent>
                                <Words spaced-bottom block bold>{{ $t('components.datetimePicker.timeLabel') }}</Words>
                                <div class="datetime-picker__time-fallback">
                                    <SelectBox
                                        v-model.number="activeHour"
                                        :label="$t('components.datetimePicker.hourLabel')"
                                        :disabled="!activeDay"
                                        data-test="time-picker-hour"
                                        @input="adjustActiveMinutes()"
                                    >
                                        <option
                                            v-for="(hour, i) in validHours"
                                            :key="i"
                                            :value="hour"
                                            :selected="activeHour === hour"
                                        >
                                            {{ formatTimeDigit(hour) }}
                                        </option>
                                    </SelectBox>
                                    <SelectBox
                                        v-model.number="activeMinute"
                                        :label="$t('components.datetimePicker.minuteLabel')"
                                        :disabled="!activeDay"
                                        data-test="time-picker-minute"
                                    >
                                        <option
                                            v-for="(minute, j) in validMinutes"
                                            :key="j"
                                            :value="minute"
                                            :selected="activeMinute === minute"
                                        >
                                            {{ formatTimeDigit(minute) }}
                                        </option>
                                    </SelectBox>
                                </div>
                            </Tile>
                        </div>
                        <div v-else class="datetime-picker__wrapper">
                            <div
                                :id="getTrackId('day')"
                                :class="{ 'datetime-picker__track--disabled': isScrollDisabled }"
                                class="datetime-picker__track"
                                @touchstart="handleTouchStart"
                                @scroll="handleScroll('day', $event)"
                                @touchend="handleTouchEnd"
                            >
                                <div class="datetime-picker__track-placeholder" />
                                <div v-for="(date, i) in validDates" :key="i" class="datetime-picker__item">
                                    <span class="datetime-picker__item-text">
                                        {{ formatDay(date.start) }}
                                    </span>
                                </div>
                                <div class="datetime-picker__track-placeholder" />
                            </div>

                            <div v-if="isTypeDatetime" class="datetime-picker__time-tracks">
                                <div
                                    :id="getTrackId('hour')"
                                    :class="{ 'datetime-picker__track--disabled': isScrollDisabled }"
                                    class="datetime-picker__track"
                                    @touchstart="handleTouchStart"
                                    @scroll="handleScroll('hour', $event)"
                                    @touchend="handleTouchEnd"
                                >
                                    <div class="datetime-picker__track-placeholder" />
                                    <div v-for="(hour, i) in validHours" :key="i" class="datetime-picker__item">
                                        <span class="datetime-picker__item-text">
                                            {{ formatTimeDigit(hour) }}
                                        </span>
                                    </div>
                                    <div class="datetime-picker__track-placeholder" />
                                </div>
                                :
                                <div
                                    :id="getTrackId('minute')"
                                    :class="{ 'datetime-picker__track--disabled': isScrollDisabled }"
                                    class="datetime-picker__track"
                                    @touchstart="handleTouchStart"
                                    @scroll="handleScroll('minute', $event)"
                                    @touchend="handleTouchEnd"
                                >
                                    <div class="datetime-picker__track-placeholder" />
                                    <div v-for="(minute, i) in validMinutes" :key="i" class="datetime-picker__item">
                                        <span class="datetime-picker__item-text">
                                            {{ formatTimeDigit(minute) }}
                                        </span>
                                    </div>
                                    <div class="datetime-picker__track-placeholder" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="datetime-picker__footer">
                        <ButtonGroup>
                            <BaseButton v-if="enableReset" primary light place-left @click="clear">
                                {{ $t('components.datetimePicker.clear') }}
                            </BaseButton>
                            <BaseButton primary data-test="time-picker-submit-button" @click="submit">
                                {{ $t('components.datetimePicker.submit') }}
                            </BaseButton>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import _sortBy from 'lodash/sortBy';
import { ensureJSTimestamp } from '@/services/utils/date';
import {
    addDays,
    subDays,
    startOfDay,
    endOfDay,
    format,
    getDay,
    getHours,
    getMinutes,
    addHours,
    addMinutes,
    differenceInDays,
    differenceInMinutes,
    isWithinRange,
} from 'date-fns';
import ResizeObserver from 'resize-observer-polyfill';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Words from '@/components/Typography/Words';
import Tile from '@/components/Layout/Tile';
import Calendar from '@/components/Form/Calendar';
import SelectBox from '@/components/Form/SelectBox.v2';

const CLOSE_DELAY = 500;
const OPEN_DELAY = 250;
const PLACEHOLDER_COUNT = 1;
const INTERVAL_MINUTES = 5;
const DEFAULT_DAYS_BACK = 365;
const DEFAULT_DAYS_FORWARD = 60;

const take = (value, fallback = null) => {
    return value ? value : fallback;
};

export default {
    name: 'DatetimePicker',
    components: {
        BaseButton,
        ButtonGroup,
        Calendar,
        SelectBox,
        Words,
        Tile,
    },
    props: {
        type: {
            type: String,
            default: 'datetime',
            validator: v => ['datetime', 'date'].includes(v),
        },
        active: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Number, Date],
            default: null,
        },
        min: {
            type: [Number, Date],
            default: null,
        },
        max: {
            type: [Number, Date],
            default: null,
        },
        minuteSteps: {
            type: Number,
            default: INTERVAL_MINUTES,
        },
        rangeConfig: {
            type: Array,
            default: null,
        },
        enableReset: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            eid: `el${this._uid}`,
            isActive: this.active,
            isVisuallyOpen: false,

            activeDay: null,
            activeHour: null,
            activeMinute: null,
            isScrollDisabled: false,
        };
    },
    computed: {
        isTypeDatetime() {
            return this.type === 'datetime';
        },

        validDates() {
            if (this.rangeConfig) {
                return this.rangeConfig;
            }

            const now = new Date();

            const from = this.min ? this.ensureDate(this.min) : startOfDay(subDays(now, DEFAULT_DAYS_BACK));
            const to = this.max ? this.ensureDate(this.max) : endOfDay(addDays(now, DEFAULT_DAYS_FORWARD));
            const numOfDays = Math.abs(differenceInDays(from, to));

            return Array.from({ length: numOfDays + 1 })
                .fill('')
                .map((o, i) => ({
                    start: i === 0 ? from : startOfDay(addDays(from, i)),
                    end: i === numOfDays ? to : endOfDay(addDays(from, i)),
                }));
        },

        validHours() {
            if (!this.activeDay) {
                return [];
            }

            const hours = [];

            const current = getHours(this.activeDay.start);
            const last = getHours(this.activeDay.end);

            for (let i = current; i <= last; i++) {
                hours.push(i);
            }

            return hours;
        },

        validMinutes() {
            if (!this.activeDay) {
                return [];
            }

            const minutes = [];
            const activeHour = this.activeHour || getHours(this.activeDay.start);
            const { start, end } = this.activeDay;

            for (let i = 0; i < 60; i += this.minuteSteps) {
                const nextDate = addMinutes(addHours(startOfDay(start), activeHour), i);

                if (isWithinRange(nextDate, start, end)) {
                    minutes.push(i);
                }
            }

            return minutes;
        },

        isInvalid() {
            // Check if value is present
            if (this.value === null) {
                return false;
            }

            const value = this.ensureDate(this.value);

            if (value.getMinutes() % this.minuteSteps !== 0) {
                return true;
            }

            // Check range configuration
            if (this.rangeConfig !== null) {
                return !this.rangeConfig.some(o => isWithinRange(value, o.start, o.end));
            }

            const now = new Date();
            const from = this.min ? this.ensureDate(this.min) : startOfDay(subDays(now, DEFAULT_DAYS_BACK));
            const to = this.max ? this.ensureDate(this.max) : endOfDay(addDays(now, DEFAULT_DAYS_FORWARD));

            return !isWithinRange(value, from, to);
        },

        // required for fallback calendar
        availableDates() {
            if (this.rangeConfig === null) {
                return null;
            }

            return this.rangeConfig.map(o => format(ensureJSTimestamp(o.start), 'YYYY-MM-DD'));
        },

        // required for fallback calendar
        activeDayCalendar() {
            return this.activeDay ? [format(this.activeDay.start, 'YYYY-MM-DD')] : [];
        },

        // required for fallback time picker
        validTimespan() {
            if (!this.activeDay) {
                return [];
            }

            const activeDay = this.activeDay;

            this.activeDay.start;

            const entries = [];

            const { start, end } = activeDay;
            const current = getHours(start);
            const last = getHours(end);

            for (let h = current; h <= last; h++) {
                for (let m = 0; m < 60; m += this.minuteSteps) {
                    const nextDate = addMinutes(addHours(startOfDay(start), h), m);

                    if (isWithinRange(nextDate, start, end)) {
                        entries.push({
                            hour: h,
                            minute: m,
                            label: `${this.formatTimeDigit(h)} : ${this.formatTimeDigit(m)}`,
                        });
                    }
                }
            }

            return entries;
        },
    },
    watch: {
        active(state) {
            this.changeState(state);
        },
    },
    beforeDestroy() {
        this.resizeObserver && this.resizeObserver.disconnect();
    },
    mounted() {
        this.isVisuallyOpen && this.onVisuallyOpen();

        this.resizeObserver = new ResizeObserver(() => {
            this.isVisuallyOpen && this.onVisuallyOpen();
        });
        this.resizeObserver.observe(this.$el);

        this.active ? this.open(false) : this.close(false);
    },
    methods: {
        getRangeBestMatch(targetDate = null) {
            targetDate = targetDate === null ? new Date() : this.ensureDate(targetDate);

            const found = this.rangeConfig.find(o => {
                return isWithinRange(targetDate, o.start, o.end);
            });

            if (found) return targetDate;

            let bestMatch = null;
            let bestScore = -Infinity;
            const rangeConfig = _sortBy(this.rangeConfig, ['start']);

            for (let i = 0, l = rangeConfig.length; i < l; i++) {
                const curr = rangeConfig[i];

                const score = differenceInMinutes(curr.start, targetDate);

                if (score > bestScore) {
                    bestScore = score;
                    bestMatch = curr.start;
                }

                if (score > 0) {
                    break;
                }
            }

            return bestMatch;
        },

        getNextValidDate(targetDate = null) {
            targetDate = targetDate === null ? new Date() : this.ensureDate(targetDate);

            // Check range configuration
            // Detect next valid date
            if (this.rangeConfig !== null) {
                return this.getRangeBestMatch(targetDate);
            }

            const now = new Date();
            const from = this.min ? this.ensureDate(this.min) : startOfDay(subDays(now, DEFAULT_DAYS_BACK));
            const to = this.max ? this.ensureDate(this.max) : endOfDay(addDays(now, DEFAULT_DAYS_FORWARD));

            // min max range check
            if (targetDate < from) {
                return from;
            } else if (targetDate > to) {
                return to;
            }

            return targetDate;
        },

        preselectDate() {
            const now = this.value === null ? this.getNextValidDate() : this.getNextValidDate(this.value);
            let selectIndex = null;

            for (let i = 0, l = this.validDates.length; i < l; i++) {
                const curr = this.validDates[i];

                if (isWithinRange(now, curr.start, curr.end)) {
                    selectIndex = i;
                    this.activeDay = curr;
                    break;
                }
            }

            this.scrollToIndex('day', selectIndex);
        },

        preselectTime() {
            const now = this.value === null ? this.getNextValidDate() : this.getNextValidDate(this.value);

            const hours = getHours(now);
            const minutes = Math.floor(getMinutes(now) / this.minuteSteps) * this.minuteSteps;

            this.updateTimeSelection(hours, minutes);
        },

        updateTimeSelection(hours = null, minutes = null) {
            let hourIndex = 0;
            let minuteIndex = 0;

            if (hours !== null) {
                this.validHours.forEach((h, i) => {
                    if (h === hours) {
                        hourIndex = i;
                        this.activeHour = h;
                    }
                });
            }

            if (minutes !== null) {
                this.validMinutes.forEach((m, i) => {
                    if (m === minutes) {
                        minuteIndex = i;
                        this.activeMinute = m;
                    }
                });
            }

            // do not scroll to index on fallback calendar
            if (this.$root.isDesktop) {
                return;
            }

            this.scrollToIndex('hour', hourIndex);
            this.scrollToIndex('minute', minuteIndex);
        },

        submit() {
            let date;
            if (this.isTypeDatetime) {
                date = startOfDay(this.ensureDate(this.activeDay.start).getTime());
                date = addHours(date, this.activeHour);
                date = addMinutes(date, this.activeMinute);
            } else {
                date = this.ensureDate(this.activeDay.start).getTime();
            }

            if (isNaN(date)) {
                date = null;
            }

            this.$emit('input', date);
            this.close();
        },

        detectCenteredItem(type) {
            const $track = document.getElementById(this.getTrackId(type));
            const $firstNode = $track.children[0];

            const centerIndex = Math.floor(($track.scrollTop + $track.offsetHeight / 2) / $firstNode.offsetHeight);
            const $centerNode = $track.children[centerIndex];

            if (type === 'day') {
                this.activeDay = take(this.validDates[centerIndex - PLACEHOLDER_COUNT]);

                if (!this.validHours.includes(parseInt(this.activeHour))) {
                    this.activeHour = this.validHours[0];
                }

                if (!this.validMinutes.includes(parseInt(this.activeMinute))) {
                    this.activeMinute = this.validMinutes[0];
                }
            } else if (type === 'hour') {
                this.activeHour = take(this.validHours[centerIndex - PLACEHOLDER_COUNT]);
            } else if (type === 'minute') {
                this.activeMinute = take(this.validMinutes[centerIndex - PLACEHOLDER_COUNT]);
            }

            // Add active class to center node
            Array.from($track.querySelectorAll('.datetime-picker__item--active')).forEach(item => {
                if (item !== $centerNode) {
                    item.classList.remove('datetime-picker__item--active');
                }
            });
            if (!$centerNode.classList.contains('datetime-picker__item--active')) {
                $centerNode.classList.add('datetime-picker__item--active');
            }
        },

        onVisuallyOpen() {
            this.preselectDate();

            setTimeout(() => {
                this.preselectTime();
            }, 200);

            if (this.$root.isDesktop) {
                return;
            }

            this.detectCenteredItem('day');
            if (this.isTypeDatetime) {
                this.detectCenteredItem('hour');
                this.detectCenteredItem('minute');
            }
        },

        onClose() {
            this.activeDay = null;
            this.activeHour = null;
            this.activeMinute = null;
        },

        ensureDate(date) {
            return date && date.getTime ? date : new Date(ensureJSTimestamp(date));
        },

        handleCalendarInput(calendarValue) {
            // no value provided
            if (!calendarValue) {
                this.activeDay = null;
                this.activeHour = null;
                this.activeMinute = null;
                return;
            }

            const stringDate = calendarValue[0];

            // range config available
            if (this.rangeConfig) {
                const found = this.rangeConfig.find(el => {
                    return format(el.start, 'YYYY-MM-DD') === stringDate;
                });

                this.activeDay = found || null;
            } else {
                let start = startOfDay(new Date(stringDate));
                let end = endOfDay(new Date(stringDate));

                if (this.min && this.min > start) {
                    start = this.min * 1;
                }

                if (this.max && this.max < end) {
                    end = this.max * 1;
                }

                this.activeDay = { start, end };
            }

            if (!this.validHours.includes(parseInt(this.activeHour))) {
                this.activeHour = this.validHours[0];
            }

            if (!this.validMinutes.includes(parseInt(this.activeMinute))) {
                this.activeMinute = this.validMinutes[0];
            }
        },

        adjustActiveMinutes() {
            // Adjust activeMinute since it can be out of valid minutes after hour change
            if (!this.validMinutes.includes(parseInt(this.activeMinute))) {
                this.activeMinute = this.validMinutes[this.validMinutes.length - 1];
            }
        },

        clear() {
            this.close();
            this.$emit('input', null);
        },

        // Utils
        // --------------------------------------------------------
        scrollToIndex(type, index) {
            const $track = document.getElementById(this.getTrackId(type));
            const $child = $track && $track.children[index + PLACEHOLDER_COUNT];

            if ($child) {
                $track.scrollTo(0, $child.offsetTop - $child.offsetHeight);
            }
        },

        getTrackId(type) {
            return `${this.eid}-track-${type}`;
        },

        formatDay(timestamp) {
            const weekDay = this.$t(`weekdays.${getDay(timestamp)}`).substring(0, 2);
            const suffix = format(timestamp, 'DD.MM.');

            return `${weekDay}., ${suffix}`;
        },

        formatTimeDigit(num) {
            return ('0' + num).slice(-2);
        },

        preventScroll: _debounce(function () {
            this.isScrollDisabled = false;
        }, 50),

        handleTouchStart(e) {
            if (this.isScrollDisabled) {
                e.preventDefault();
                e.stopPropagation();
            }
        },

        handleTouchEnd() {
            this.isScrollDisabled = true;
            this.preventScroll();
        },

        handleScroll(type, e) {
            this.detectCenteredItem('day');

            if (type === 'day') {
                this.updateTimeSelectionDebounce();
            } else {
                this.detectCenteredItem('hour');
                this.detectCenteredItem('minute');
            }

            if (this.isScrollDisabled) {
                e.preventDefault();
                e.stopPropagation();
                this.preventScroll();
            }
        },

        updateTimeSelectionDebounce: _debounce(function () {
            this.updateTimeSelection(this.activeHour, this.activeMinute);
        }, 10),

        // Flyout Like Mechanic
        // -----------------------------------------------

        open(notify = true) {
            notify && this.$emit('opening');
            this.isActive = true;

            setTimeout(
                () => {
                    this.isVisuallyOpen = true;
                    this.onVisuallyOpen();

                    notify && this.$emit('visually-opened');
                },
                notify ? OPEN_DELAY : 0
            );
            notify && this.$emit('opened');
        },

        close(notify = true) {
            notify && this.$emit('closing');
            this.isVisuallyOpen = false;
            this.onClose();

            setTimeout(
                () => {
                    this.isActive = false;
                },
                notify ? CLOSE_DELAY : 0
            );

            notify && this.$emit('closed');
        },

        changeState(state) {
            if (this.isActive !== state) {
                state ? this.open() : this.close();
            }
        },

        closeByBackdrop() {
            if (this.isVisuallyOpen) {
                this.changeState(false);
            }
        },

        preventContentClicks(e) {
            e.stopPropagation();
        },
    },
};
</script>

<style lang="scss">
body[class*='js--flyout-open'] {
    overflow: hidden;
}

.datetime-picker__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color-darkGrey, 0.9);
    z-index: 1000;
    min-height: var(--view-height);

    visibility: hidden;
    opacity: 0;

    transition:
        visibility 0s 0.3s,
        opacity 0.3s ease-out;

    .datetime-picker--active > & {
        visibility: visible;
        opacity: 1;

        transition: opacity 0.3s ease-out;
    }
}

.datetime-picker__body {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    z-index: 1000;

    .datetime-picker--desktop > & {
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .datetime-picker--active > & {
        opacity: 1;
        animation-name: flipInX;
        animation-duration: 0.3s;
        animation-timing-function: ease-out;
    }
}

@keyframes flipInX {
    from {
        opacity: 0;
        transform: perspective(400px) rotate3d(1, 0, 0, -5deg) translateY(40px);
        animation-timing-function: ease-out;
    }

    to {
        opacity: 1;
        transform:
            perspective(400px),
            rotate3d(1, 0, 0, 0deg) translateY(0px);
    }
}

.datetime-picker__router-content,
.datetime-picker__content {
    width: 100%;
    max-width: 100%;
    background-color: $color-white;
    // height: var(--view-height);
    max-height: var(--view-height);
}

.datetime-picker__content {
    display: flex;
    flex-flow: column nowrap;

    .datetime-picker--desktop & {
        max-width: 600px;
    }
}

.datetime-picker__header,
.datetime-picker__footer {
    flex: 0 0 auto;
}

.datetime-picker__inner {
    overflow: auto;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
}

.datetime-picker__inner-spacer {
    display: block;
    width: 100%;
    height: 0;
    overflow: hidden;
    flex-shrink: 0;

    // iOS 11.0
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
        height: constant(safe-area-inset-bottom);
    }

    // iOS 11.2
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
        height: env(safe-area-inset-bottom);
    }
}

.datetime-picker__footer {
    border-top: 1px solid $color-mediumGrey;
}

.datetime-picker__wrapper {
    position: relative;
    margin: 10px;
    display: flex;
    flex-flow: row nowrap;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.8);
        height: 50px;
        pointer-events: none;
        z-index: 1;
    }

    &::before {
        top: 0;
        border-bottom: 1px solid $color-mediumGrey;
    }

    &::after {
        bottom: 0;
        border-top: 1px solid $color-mediumGrey;
    }
}

.datetime-picker__track {
    height: 150px;
    width: 100%;
    overflow: scroll;
    scroll-snap-type: y mandatory;
    display: grid;
    grid-auto-rows: 50px;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
}

.datetime-picker__desktop-wrapper {
    background-color: $color-lightGrey;
}

.datetime-picker__track-placeholder,
.datetime-picker__item {
    height: 50px;
    width: 100%;
    line-height: 50px;
    text-align: center;
    scroll-snap-align: center;
    position: relative;
}

.datetime-picker__item-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
}

.datetime-picker__time-tracks {
    display: grid;
    grid-template-columns: 1fr 5px 1fr;
    width: 100%;
    font-weight: bold;
    align-items: center;
}

.datetime-picker__time-fallback {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;

    > * {
        width: 100%;
    }
}
</style>

<template>
    <div class="offerAndInvoiceTab">
        <!-- invoice card -->
        <div v-if="!order.invoiceData" class="card mx-6 my-6">
            <span class="font-copy-sm-strong mb-6">
                {{ $t('pages.order.orderView.offerAndInvoiceTab.invoice') }}
            </span>

            <div class="offerAndInvoiceTab__empty-section">
                <span class="font-copy-sm text-center text-subdued">
                    {{ $t('pages.order.orderView.offerAndInvoiceTab.invoicePendingText') }}
                </span>
            </div>
        </div>

        <div v-else class="p-6">
            <div class="card mt-6">
                <InvoiceBlock :order="order" />
            </div>
            <BaseButton v-if="order.invoiceDocument.url" :href="order.invoiceDocument.url" primary block>
                {{ $t('pages.order.orderView.invoiceButton') }}
                <template #right>
                    <SfDownloadIcon theme="dark" size="xxs" />
                </template>
            </BaseButton>
        </div>

        <!-- invoice card ends -->

        <!-- offer card -->
        <div class="card mx-6 mb-6">
            <OfferBlock :data="offerBlock" :project-position="projectPosition" />
        </div>
        <!-- offer card ends -->
    </div>
</template>

<script>
import OfferBlockView from '@/pages/Order/components/OfferBlockView';

import BaseButton from '@/components/Button/Button';
import InvoiceBlock from './InvoiceBlock';
import OfferBlock from '@/pages/Order/components/OfferBlock.v2';
import { SfDownloadIcon } from '@schuettflix/vue-components';

export default {
    name: 'OfferAndInvoiceTab',

    components: {
        BaseButton,
        InvoiceBlock,
        OfferBlock,
        SfDownloadIcon,
    },

    props: {
        order: {
            type: Object,
            required: true,
        },
        projectPosition: {
            type: Object,
            default: null,
            required: false,
        },
    },

    data() {
        return {
            offerBlock: null,
        };
    },

    computed: {
        pollutionType() {
            return this.offerBlock?.product?.variantDisposal?.pollutionType?.label;
        },
    },
    watch: {
        order: {
            immediate: true,
            handler(order) {
                if (!order) return;
                this.offerBlock = OfferBlockView.createFromOrder(this.order);
            },
        },
    },
};
</script>

<style lang="scss">
.offerAndInvoiceTab__empty-section {
    padding: 16px;
    background-color: $color-lightGrey;
    border: 1px dashed $color-littleDarkerThanMediumGrey;
}
</style>

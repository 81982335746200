<template>
    <div class="address-finder">
        <div class="address-finder__inputs">
            <div class="address-finder__inputs-wrapper">
                <EnhancedAddressField
                    :value="address"
                    :allow-incomplete-address="allowIncompleteAddress"
                    list-locality
                    focus-street-number
                    data-test="address-finder-address-input"
                    :initial-address="existingAddress"
                    :include-state="includeState"
                    :include-country="includeCountry"
                    :label="$t('components.factoryManagement.factoryNameAddress.textFieldPlaceholder')"
                    :readonly="isReadOnly"
                    :is-loading="isLoading"
                    :allowed-countries="allowedCountries"
                    @input="handleAddressUpdateBySearch"
                    @focus="handleMapFocus"
                    @plus-code="handleAddressUpdateByLocation"
                />
                <OpenLocationCodeField
                    ref="location"
                    class="address-finder__open-location-field"
                    :location="existingLocation"
                    is-dark-theme
                    hide-icon
                    default-value=""
                />
            </div>
            <div v-if="error && !mapActive && mapIsDirty" class="address-finder__error-messages">
                <ErrorMessage v-for="(message, index) in errorMessages" :key="index" :message="message" />
            </div>
        </div>

        <div class="location-picker__map-wrapper" :class="{ 'is-focused': mapActive, 'is-dirty': mapIsDirty }">
            <div :id="`${eid}-map`" ref="locationPickerMap" class="location-picker__map" />
            <div v-if="editMode" class="location-picker__flag"><FlagIcon width="92" /></div>
            <LayerControls v-if="!isReadOnly" class="location-picker__layer-controls" :type="mapType" :map="map" />
            <CurrentLocationControl v-if="!isReadOnly" :map="map" />
            <BaseButton
                v-if="mapActive && !buttonIsHidden && hasAddress"
                class="location-picker__map__button"
                data-test="location-picker-map-button"
                arrow-right
                light
                primary
                @click="setLocation"
            >
                {{ $t('components.factoryView.defineLocation') }}
            </BaseButton>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _isEmpty from 'lodash/isEmpty';
import {
    locationToStructurizedAddress,
    normalizeLocation,
    replacePlaceLabelWithFormattedAddress,
    retrieveZipFromLocation,
} from '@/services/utils/map';

import GoogleMaps, { maxZoomService } from '@/services/GoogleMaps';
import { formatAddressObject } from '@/services/utils/address';

import googleMapMixin from '@/plugins/mixins/googleMapMixin.js';

import BaseButton from '@/components/Button/Button';
import CurrentLocationControl from '@/components/Map/CurrentLocationControl';
import EnhancedAddressField from '@/pages/Login/components/EnhancedAddressField';
import ErrorMessage from '@/components/Form/ErrorMessage';
import LayerControls from '@/components/Map/LayerControls';
import OpenLocationCodeField from '@/components/Form/OpenLocationCodeField';

import FlagIcon from '@/assets/icons/flag-centered.svg';

const DEFAULT_ZOOM_LEVEL_WITHOUT_LOCATION = 11;
const DEFAULT_ZOOM_WITH_LOCATION = 17;

export default {
    name: 'AddressFinder',
    components: {
        BaseButton,
        CurrentLocationControl,
        EnhancedAddressField,
        ErrorMessage,
        FlagIcon,
        LayerControls,
        OpenLocationCodeField,
    },
    mixins: [googleMapMixin],
    props: {
        existingAddress: {
            type: Object,
            default: () => ({}),
        },
        existingLocation: {
            type: Object,
            default: () => ({}),
        },
        enableSearch: {
            type: Boolean,
            default: true,
        },
        error: {
            type: [Array, String],
            default: null,
        },
        includeState: {
            type: Boolean,
            default: false,
        },
        includeCountry: {
            type: Boolean,
            default: false,
        },
        allowIncompleteAddress: {
            type: Boolean,
            default: false,
        },
        mapType: {
            type: String,
            default: 'satellite',
            validator: v => ['roadmap', 'satellite', 'terrain'].includes(v),
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        allowedCountries: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            eid: `e${this._uid}`,
            initialAddress: null,
            map: null,
            locationValue: {},
            editMode: true,
            mapActive: false,
            mapIsDirty: false,
            buttonIsHidden: false,
            centerLocation: {},
            address: {},
            location: {},
        };
    },
    computed: {
        ...mapGetters('platform', ['initialMapLocation']),

        hasAddress() {
            return !_isEmpty(this.address);
        },
        addressLabel() {
            return formatAddressObject({
                address: this.existingAddress,
                includeState: this.includeState,
                includeCountry: this.includeCountry,
            });
        },
        errorMessages() {
            if (!this.error) {
                return null;
            }
            if (Array.isArray(this.error)) {
                return this.error;
            }
            return [this.error];
        },
        initialZoom() {
            return this.existingLocation ? DEFAULT_ZOOM_WITH_LOCATION : DEFAULT_ZOOM_LEVEL_WITHOUT_LOCATION;
        },
    },
    watch: {
        existingLocation: {
            immediate: true,
            handler() {
                this.centerLocation =
                    this.existingLocation?.lat !== undefined ? this.existingLocation : this.initialMapLocation;
            },
        },
        mapActive() {
            setTimeout(() => this.$refs.locationPickerMap.scrollIntoView({ behavior: 'smooth', block: 'center' }), 350);
            this.map.setOptions({ scrollwheel: this.mapActive });
        },
        existingAddress() {
            if (!_isEmpty(this.existingAddress) && !_isEmpty(this.existingLocation)) {
                this.address = this.existingAddress;
                this.address.location = this.existingLocation;
                this.locationValue = this.existingAddress;
                this.mapIsDirty = true;
                this.centerLocationOnMap(this.existingLocation);
            }
        },
    },
    created() {
        this.createMap();

        if (!_isEmpty(this.existingAddress)) {
            this.address = this.existingAddress;
            this.mapIsDirty = true;
        }
    },
    methods: {
        createMap() {
            const options = {};

            if (this.isReadOnly) {
                options.draggable = false;
            }
            if (!this.mapActive) {
                options.scrollwheel = false;
            }

            GoogleMaps.then(google => {
                this.google = google;
                this.map = this.createGoogleMap(google, this.initialZoom, options);

                if (!this.editMode) {
                    this.destinationMarker = this.createDestinationMarker(google);
                }

                if (!this.isReadOnly) {
                    this.eventListenerDrag();
                    this.eventListenerCenterChanged();
                }
            });
        },
        eventListenerDrag() {
            this.map.addListener('click', () => {
                this.buttonIsHidden = true;
                this.mapActive = true;
            });

            this.map.addListener('drag', () => {
                this.buttonIsHidden = true;
                this.mapActive = true;
            });

            this.map.addListener('dragend', () => {
                this.buttonIsHidden = false;
            });
        },
        async handleAddressUpdateByLocation(location) {
            this.$logger().log('update by location');

            if (this.enableSearch) {
                const place = await locationToStructurizedAddress(location);
                replacePlaceLabelWithFormattedAddress(place);
                place.zip = place.postalCode;

                this.address = {
                    ...place,
                };
            }
            this.centerLocation = location;

            // google maps is not firing center_changed event initially
            this.programaticMapUpdate = true;
            this.centerLocationOnMap(location);
            this.changeSearchFieldFocused(false);
        },

        changeSearchFieldFocused(state) {
            this.searchFieldFocused = state;
        },
        centerLocationOnMap(location) {
            if (!this.map) {
                return;
            }

            this.$logger().log('Applied change: center position');
            // here was code to set the zoom level that was deleted for SCHUTT-5544
            this.map.setCenter(normalizeLocation(location));
        },
        async handleAddressUpdateBySearch(address) {
            // search box removed input
            if (address === null) {
                this.address = null;
                this.locationValue = {};
                return;
            }

            this.$logger().log('update by address');
            const zipCode = await retrieveZipFromLocation(address.location);
            address.zipCode = zipCode;
            address.postalCode = zipCode;

            this.address = address;
            this.centerLocation = address.location;

            this.programaticMapUpdate = true;
            this.centerPlaceOnMap(this.address.place);
            await this.getMaxZoomAtLocation(this.address.location);

            this.buttonIsHidden = false;

            this.$nextTick(function () {
                this.updateAddressFromLocation();
                this.changeSearchFieldFocused(false);
            });
        },

        async getMaxZoomAtLocation(location) {
            await maxZoomService(this.google).getMaxZoomAtLatLng(location, data => {
                const { zoom } = data;

                // reset zoom only if no imagery available
                if (zoom < this.initialZoom) {
                    this.map.setZoom(zoom);
                }
            });
        },

        centerPlaceOnMap(place) {
            const northeast = place.geometry.viewport.getNorthEast();
            const southwest = place.geometry.viewport.getSouthWest();

            this.map.fitBounds(
                new this.google.maps.LatLngBounds(
                    new this.google.maps.LatLng(southwest.lat(), southwest.lng()), // SW
                    new this.google.maps.LatLng(northeast.lat(), northeast.lng()) // NE
                )
            );
        },
        async updateAddressFromLocation() {
            if (this.address?.location) {
                const place = await locationToStructurizedAddress(this.address.location);
                replacePlaceLabelWithFormattedAddress(place);
                place.zip = place.postalCode;

                this.address = {
                    ...place,
                };
            }
        },
        handleMapFocus() {
            if (this.isReadOnly) {
                return;
            }
            this.mapActive = true;
            this.mapIsDirty = true;
        },
        setLocation() {
            this.mapActive = false;
            this.buttonIsHidden = true;
            this.$emit('handleAddressUpdate', { address: this.formatAddressData(), location: this.address?.location });
        },
        formatAddressData() {
            return {
                city: this.address?.city || null,
                country: this.address?.countryCode || this.address?.country || null,
                number: this.address?.street_number || this.address?.number || null,
                state: this.address?.stateCode || this.address?.state || null,
                street: this.address?.street || null,
                zip: this.address?.postalCode || this.address?.zip || null,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.address-finder {
    &__inputs {
        margin: 0 auto;
        max-width: 720px;
        padding: 0 16px;
        position: relative;
        width: 100%;
        z-index: 1;

        @media screen and (min-width: 1330px) {
            padding: 0;
        }

        &-wrapper {
            display: flex;
        }
    }

    &__error-messages {
        position: absolute;
        width: 100%;
    }

    .location-picker__layer-controls {
        ::v-deep button {
            top: 40px;
        }
    }

    .location-picker__map {
        &-wrapper {
            height: 0;
            margin: 0 auto;
            opacity: 0;
            pointer-events: none;
            position: relative;
            top: -35px;
            transition:
                width 450ms ease-in,
                height 450ms ease-in,
                opacity 200ms ease-in 200ms;
            max-width: 1172px;
            overflow: hidden;
            width: 100%;

            &.is-dirty {
                height: 250px;
                opacity: 1;
                pointer-events: all;
                transition: all 200ms ease-in;
                width: 100%;

                @media screen and (min-width: 1330px) {
                    width: 720px;
                }
            }

            &.is-focused {
                width: 100%;
                height: 500px;
                transition: width 350ms ease-in height 350ms ease-in;
            }
        }

        &__button {
            bottom: 30%;
            color: $color-darkGrey;
            font-weight: 700;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
        }
    }

    .enhanced-address-field {
        flex: 1;

        ::v-deep .text-field__input-group {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__open-location-field {
        align-self: flex-start;
        flex: 0 0 193px;

        .icon--mono {
            .f-highlight,
            .f-base {
                fill: $color-darkGrey;
            }
        }

        ::v-deep .text-field__input-group {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0;
        }
    }
}
</style>

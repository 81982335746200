<template>
    <div>
        <Words class="mb-6" bold block>{{ $t('pages.checkout.priceAdjustments.shipping.editHeadline') }}</Words>
        <div
            class="checkout-price-adjustments__price-table checkout-price-adjustments__price-table--edit"
            :class="{ 'checkout-price-adjustments__price-table--col-2': !showTotals }"
        >
            <TextField
                v-model.number="value.purchaseUnitPrice"
                type="number"
                step="0.01"
                :label="$t('pages.checkout.priceAdjustments.newPurchasePriceLabel')"
                class="mb-4 h-16"
            />
        </div>
        <div
            class="checkout-price-adjustments__price-table checkout-price-adjustments__price-table--edit"
            :class="{ 'checkout-price-adjustments__price-table--col-2': !showTotals }"
        >
            <TextField
                v-model.number="value.retailUnitPrice"
                type="number"
                step="0.01"
                :label="$t('pages.checkout.priceAdjustments.newRetailPriceLabel')"
                class="mb-4 h-16"
            />
        </div>
    </div>
</template>

<script>
import PricingDataView from '@/pages/Checkout/components/PriceAdjustment/models/PricingDataView';
import ShippingPricingView from '@/pages/Checkout/components/PriceAdjustment/models/ShippingPricingView';

import Words from '@/components/Typography/Words';
import TextField from '@/components/Form/TextField.v2';

export default {
    name: 'ShippingEditSection',
    components: {
        Words,
        TextField,
    },
    props: {
        value: {
            type: ShippingPricingView,
            required: true,
        },
        original: {
            type: PricingDataView,
            required: true,
        },
        showTotals: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        value() {
            this.$emit('input', this.value);
        },
    },
};
</script>

<style lang="scss"></style>

<template>
    <div>
        <p class="font-copy-md-strong m-0 mb-2 lg:font-copy-lg-strong">
            {{ $t('pages.homeV2.documentsTitle') }}
        </p>
        <p class="font-copy-sm mb-[12px] lg:font-copy-md lg:mb-6">
            {{ $t('pages.homeV2.documentsDescription') }}
        </p>

        <div class="grid gap-x-[20px] gap-y-[20px] lg:grid-cols-[1fr,1fr,1fr,1fr]">
            <StatusCard
                v-if="invoiceCount"
                icon="InvoiceIcon"
                :title="$t('pages.homeV2.invoiceCardTitle')"
                :description="$tc('pages.homeV2.invoiceCardDescription', invoiceCount)"
                :count="invoiceCount"
                @click="goToInvoiceListPage()"
            />
            <StatusCard
                v-if="creditNoteCount"
                icon="CreditIcon"
                :title="$t('pages.homeV2.creditNoteCardTitle')"
                :description="
                    $tc(
                        selectCreditNoteDocKey({
                            creditNote: `pages.homeV2.creditNoteDocCardDescription.creditNote`,
                            selfBilling: `pages.homeV2.creditNoteDocCardDescription.selfBilling`,
                        }),
                        creditNoteCount
                    )
                "
                :count="creditNoteCount"
                @click="goToCreditNotePage()"
            />
        </div>
        <EmptyState
            v-if="!invoiceCount && !creditNoteCount"
            class="mb-4"
            icon="DocumentEmptyIcon"
            :text="$t('pages.homeV2.noNewDocuments')"
        />
    </div>
</template>

<script>
import _get from 'lodash/get';
import { assembleQueryFilter } from '@/plugins/mixins/persistentFiltersMixin';
import { ensurePHPTimestamp } from '@/services/utils/date';
import endOfToday from 'date-fns/end_of_today';
import ReceiptApi from '@/services/Api/Receipt';
import startOfDay from 'date-fns/start_of_day';
import subDays from 'date-fns/sub_days';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';

import EmptyState from '@/pages/Home/components/EmptyState';
import StatusCard from '@/pages/Home/components/StatusCard';

const POLLING_INTERVAL = 60000;

export default {
    name: 'HomeSectionDocuments',
    components: {
        EmptyState,
        StatusCard,
    },
    setup() {
        const { selectCreditNoteDocKey } = useLegalTerms();
        return {
            selectCreditNoteDocKey,
        };
    },
    data() {
        return {
            invoiceCount: null,
            invoiceCountCancelSource: null,
            creditNoteCount: null,
            creditNoteCountCancelSource: null,
        };
    },
    mounted() {
        this.refreshData();
        this.$gracefulInterval(this.refreshData.bind(this), POLLING_INTERVAL);
    },
    beforeDestroy() {
        this.invoiceCountCancelSource && this.invoiceCountCancelSource.cancel('canceled-previous-call');
        this.creditNoteCountCancelSource && this.creditNoteCountCancelSource.cancel('canceled-previous-call');
    },
    methods: {
        async refreshData() {
            if (!this.$can('haveReceipts')) {
                return;
            }

            this.refreshInvoiceCount();
            this.refreshCreditNoteCount();
        },

        async refreshInvoiceCount() {
            this.invoiceCountCancelSource && this.invoiceCountCancelSource.cancel('canceled-previous-call');
            this.invoiceCountCancelSource = ReceiptApi.createCancelTokenSource();

            try {
                const response = await ReceiptApi.filter(
                    { type: 'invoice', status: 'created', countOnly: true },
                    null,
                    null,
                    this.invoiceCountCancelSource
                );

                this.invoiceCount = _get(response, 'count', 0);
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }
        },

        async refreshCreditNoteCount() {
            this.creditNoteCountCancelSource && this.creditNoteCountCancelSource.cancel('canceled-previous-call');
            this.creditNoteCountCancelSource = ReceiptApi.createCancelTokenSource();

            try {
                const response = await ReceiptApi.filter(
                    {
                        type: 'credit_note',
                        countOnly: true,
                        startCreated: ensurePHPTimestamp(startOfDay(subDays(Date.now(), 3)) * 1),
                        endCreated: ensurePHPTimestamp(endOfToday() * 1),
                    },
                    null,
                    null,
                    this.creditNoteCountCancelSource
                );

                this.creditNoteCount = _get(response, 'count', 0);
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }
        },

        goToInvoiceListPage() {
            this.$router
                .push({
                    name: this.$root.findRouteName('management__documents'),
                    query: { documents: assembleQueryFilter({ type: 'invoice', status: 'created' }) },
                })
                .catch(() => {});
        },

        goToCreditNotePage() {
            this.$router
                .push({
                    name: this.$root.findRouteName('management__documents'),
                    query: {
                        documents: assembleQueryFilter({
                            type: 'credit_note',
                            startCreated: ensurePHPTimestamp(startOfDay(subDays(Date.now(), 3)) * 1),
                            endCreated: ensurePHPTimestamp(endOfToday() * 1),
                        }),
                    },
                })
                .catch(() => {});
        },
    },
};
</script>

<template>
    <section class="transport-section mt-4 first:mt-0">
        <div class="font-copy-md-strong">
            {{ $t('pages.checkout.projectPositionDisposal.prices.transports.label') }}
        </div>

        <div class="font-copy-md mb-6 mt-2 flex flex-wrap items-start justify-between gap-2">
            <div class="sf flex items-center">
                {{ $t('pages.checkout.projectPositionDisposal.prices.transports.calculatedDistance') }}
            </div>
            <div class="ml-auto flex flex-shrink-0 items-start">
                <div v-if="distance" class="flex items-center">
                    <FlagIcon />
                    <span class="ml-2 mr-4 block">
                        {{ $n(distance / 1000, 'distance') }} {{ $t('units.kilometer') }}
                    </span>
                </div>
                <div v-if="duration" class="flex shrink-0 items-center">
                    <ClockIcon />
                    <span class="ml-2 block">{{ getFormattedDuration(duration) }}</span>
                </div>
            </div>
        </div>

        <AddSectionPlaceholder
            v-if="!hasTransportsSelected"
            :label="$t('pages.checkout.projectPositionDisposal.pages.transportPrices')"
            @add="openTransportFlyout"
        />
        <div v-else>
            <template v-for="vehicleClass in transports.collection">
                <TransportItem
                    v-if="vehicleClass.enabled"
                    :key="vehicleClass.id"
                    :vehicle-class="vehicleClass"
                    :fixed-price="fixedPrice"
                    :transport-price-unit="priceUnit"
                    :show-distance-notice="showDistanceNotice"
                />
            </template>
            <div class="mt-2 block text-right">
                <Button arrow-right @click="openTransportFlyout">
                    {{ $t('pages.checkout.priceAdjustments.transport.changeLabel') }}
                </Button>
            </div>
        </div>
    </section>
</template>

<script>
import { getFormattedDuration } from '@/services/utils/date';

import Button from '@/components/Button/Button';
import AddSectionPlaceholder from '@/pages/Checkout/components/ProjectPosition/AddSectionPlaceholder';
import TransportItem from '@/pages/Checkout/components/PriceAdjustment/TransportItem';

import FlagIcon from '@/assets/icons/micro/flag-transparent.svg';
import ClockIcon from '@/assets/icons/micro/clock-transparent.svg';

export default {
    name: 'SelectedTransports',
    components: {
        Button,

        AddSectionPlaceholder,
        TransportItem,

        FlagIcon,
        ClockIcon,
    },
    props: {
        distance: {
            type: Number,
            default: null,
        },
        duration: {
            type: Number,
            default: null,
        },
        transports: {
            type: Object,
            default: () => ({}),
        },
        priceUnit: {
            type: String,
            default: null,
        },
        fixedPrice: {
            type: Boolean,
            default: false,
        },
        showDistanceNotice: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasTransportsSelected() {
            return !!this.transports?.enabled?.length || false;
        },
    },
    methods: {
        getFormattedDuration,

        openTransportFlyout() {
            this.$emit('edit');
        },
    },
};
</script>

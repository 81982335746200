/**
 * Make first character of string uppercase
 * @param {string} s
 * @returns {string}
 */
export const capitalize = s => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * @param {string|undefined|null} input
 * @returns {boolean}
 */
export function isEmptyString(input) {
    return ['', null, undefined].includes(input);
}

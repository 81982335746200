<template>
    <div class="search-filter-block" @click="$emit('select', searchFilter)">
        <div class="search-filter-block__head">
            <Words v-if="searchFilter.default" block tiny bold spaced-bottom>
                {{ $t('pages.transportHub.searchFilter.defaultSearchLabel') }}
            </Words>
            <Words v-if="count > 0" block red tiny bold spaced-bottom>
                <Tag red tiny>{{ count }}</Tag>
                &nbsp;&nbsp;
                {{ $t('pages.transportHub.searchFilter.newTransportsLabel') }}
            </Words>
            <Words block>{{ searchFilter.name }}</Words>
        </div>
        <div class="search-filter-block__right">
            <Button :disabled="isPending()" @click.stop="deleteSearchFilter()">
                <DeleteIcon />
            </Button>
        </div>
        <div class="search-filter-block__actions" @click.stop>
            <ToggleSwitchField
                v-model="formData.notificationEnabled"
                :disabled="isPending()"
                @input="updateNotification"
            >
                {{
                    formData.notificationEnabled
                        ? $t('pages.transportHub.searchFilter.notificationsActive')
                        : $t('pages.transportHub.searchFilter.notificationsInactive')
                }}
            </ToggleSwitchField>
            <CheckboxField v-model="formData.default" :disabled="isPending()" @input="updateDefault">
                {{ $t('pages.transportHub.searchFilter.defaultSearchLabel') }}
            </CheckboxField>
        </div>
    </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import SearchFilterApi from '@/services/Api/SearchFilter';
import TransportListApi from '@/services/Api/TransportList';
import { asyncDelay } from '@/services/utils';
import { createFilterFromSearch } from '@/services/utils/search-filter';
import routeContext from '@/plugins/mixins/routeContext';

import Button from '@/components/Button/Button';
import CheckboxField from '@/components/Form/CheckboxField';
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import Tag from '@/components/Typography/Tag';
import Words from '@/components/Typography/Words';

import DeleteIcon from '@/assets/icons/regular/garbage.svg';

export default {
    name: 'SearchFilterBlock',
    components: {
        Button,
        CheckboxField,
        ToggleSwitchField,
        Tag,
        Words,
        DeleteIcon,
    },
    mixins: [statefulMixin, routeContext],
    props: {
        searchFilter: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            count: null,
            formData: _cloneDeep(this.searchFilter),
            transportListApi: null,
        };
    },
    watch: {
        searchFilter() {
            this.resetForm();
        },
    },
    created() {
        this.transportListApi = new TransportListApi(this.routeContext);
        this.refreshFilterCount();
    },
    methods: {
        resetForm() {
            this.formData = _cloneDeep(this.searchFilter);
        },

        async refreshFilterCount() {
            const filter = createFilterFromSearch(this.searchFilter);

            // TODO: this breaks encapsulation
            filter.status = ['new'];
            try {
                const result = await this.transportListApi.filter({ ...filter, countOnly: true });
                this.count = result.count;
            } catch (err) {
                this.$logger().error(err);
            }
        },

        async deleteSearchFilter() {
            await this.stateful('delete', async () => {
                try {
                    await SearchFilterApi.delete(this.searchFilter.id);
                    this.$emit('deleted');
                    await asyncDelay(1000);
                } catch (err) {
                    this.$logger().error(err);
                    this.resetForm();
                }
            });
        },

        async updateNotification(status) {
            await this.stateful('updateNotification', async () => {
                try {
                    await SearchFilterApi.save({
                        ...this.searchFilter,
                        notificationEnabled: status,
                    });
                    this.$emit('updated');
                } catch (err) {
                    this.$logger().error(err);
                    this.resetForm();
                }
            });
        },

        async updateDefault(status) {
            await this.stateful('updateDefault', async () => {
                try {
                    await SearchFilterApi.save({
                        ...this.searchFilter,
                        default: status,
                    });
                    this.$emit('updated');
                } catch (err) {
                    this.$logger().error(err);
                    this.resetForm();
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.search-filter-block {
    display: grid;
    grid-template-columns: 1fr 100px;
    grid-template-areas:
        'head right'
        'actions actions';
    gap: 25px;
}

.search-filter-block__head {
    grid-area: head;
}

.search-filter-block__right {
    grid-area: right;
    justify-self: flex-end;
}

.search-filter-block__actions {
    grid-area: actions;
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 15px;

    @media screen and (max-width: $screen-md) {
        grid-template-columns: 1fr;
    }
}
</style>

/**
 * check if filetype is accepted
 * @param {String} acceptedTypes eg.g `image/jpg, image/png, image/gif`
 * @param {String} fileType eg. `image/gif` or `application/pdf`
 */
export function isFileTypeAccepted(acceptedTypes, fileType) {
    if (!acceptedTypes || !fileType) {
        return false;
    }
    const fileTypes = acceptedTypes.split(', ');
    return fileTypes.includes(fileType);
}

/**
 * return comma separated string of filetypes based on the array of acceptedFileTypes
 * @param {Array} fileTypes eg. ['image/jpg', 'image/png', 'image/gif']
 * @return {String} eg. '.jpg, .png, .gif'
 */
export function fileTypeString(fileTypes) {
    return fileTypes.map(type => `.${type.split('/')[1]}`).join(', ');
}

export function setHeaders(file) {
    return {
        'Content-Type': `multipart/form-data;`,
        'File-Name': encodeURIComponent(file.name),
    };
}

export function setFileOnFormData(file) {
    const formData = new FormData();
    formData.append('file', file);
    return formData;
}

<template>
    <div class="supervisor-selection">
        <div v-if="showHeadline" class="supervisor-selection__intro">
            <Headline :level="3">
                {{ $t('components.supervisorSelection.headline') }}
            </Headline>

            <Words>
                {{ $t('components.supervisorSelection.description') }}
            </Words>
        </div>

        <MultiselectBox
            v-model="selectedSupervisor"
            :endpoint="endpoint"
            :filter="filter"
            :option-value-renderer="option => option.id"
            :label="$t('components.supervisorSelection.label')"
            :search-label-renderer="option => option.firstName + ' ' + option.lastName"
            :search-field-label="$t('components.supervisorSelection.searchFieldLabel')"
            class="supervisor-selection__multiselect"
            searchable
            disable-reset
            image
            :disabled="!canEdit"
            @input="changed"
        >
            <PersonInner slot="button" slot-scope="buttonScope" mode="button" :scope="buttonScope" />
            <PersonInner slot="option" slot-scope="optionScope" mode="option" :scope="optionScope" />
        </MultiselectBox>
    </div>
</template>

<script>
import UserApi from '@/services/Api/UserV2';

import Headline from '@/components/Typography/Headline';
import MultiselectBox from '@/components/Form/MultiselectBox';
import PersonInner from '@/components/Form/MultiselectBox/PersonInner';
import Words from '@/components/Typography/Words';

export default {
    name: 'SupervisorSelection',
    components: {
        PersonInner,
        MultiselectBox,
        Headline,
        Words,
    },
    props: {
        selectedSupervisorId: {
            type: Number,
            default: null,
        },
        showHeadline: {
            type: Boolean,
            default: false,
        },
        canEdit: {
            type: Boolean,
            default: true,
        },
        verifierOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            endpoint: UserApi,
            selectedSupervisor: null,
        };
    },
    computed: {
        filter() {
            const filter = {
                isActive: true,
                orPermissions: ['order', 'quote'],
            };

            if (this.verifierOnly) {
                filter.orPermissions = ['order'];
            }

            return filter;
        },
    },
    watch: {
        selectedSupervisorId: {
            immediate: true,
            handler(newSelectedSupervisorId) {
                this.selectedSupervisor = newSelectedSupervisorId;
            },
        },
    },
    methods: {
        changed() {
            this.$emit('input', this.selectedSupervisor);
        },
    },
};
</script>

<style lang="scss" scoped>
.supervisor-selection__intro {
    padding: 0 20px;
}
</style>

import { preciseFloat } from '@/services/utils';
import _groupBy from 'lodash/groupBy';
import _sumBy from 'lodash/sumBy';
import _uniq from 'lodash/uniq';
import _orderBy from 'lodash/orderBy';
import { TransportPrice } from '@/services/utils/transport';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';

export default class OfferBlockVehicleClassView {
    constructor() {
        this._name = null;
        this._classId = null;
        this._payload = null;
        this._qty = null;
        this._price = null;
        this._purchaseTotalPrice = null;
        this._unitPrice = null;
        this._purchaseUnitPrice = null;
        this._isEqual = null;
        this._count = 0;
        this._retailPrice = new TransportPrice({ distance: 1 });
        this._purchasePrice = new TransportPrice({ distance: 1 });
    }

    /**
     * Extracted from `utils/order.js/lineItemsByVehicleClass()`
     *
     * @param lineItemGroup
     * @return {OfferBlockVehicleClassView[]}
     */
    static createCollectionFromLineItemGroup(lineItemGroup) {
        const collection = [];

        if (
            !lineItemGroup.lineItems ||
            !lineItemGroup.lineItems.length ||
            [ORDER_LINE_ITEM_GROUP_TYPE.PICKUP, ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT].includes(lineItemGroup.type)
        ) {
            return collection;
        }

        const groups = _groupBy(lineItemGroup.lineItems, 'vehicleClassId');

        Object.values(groups).forEach(g => {
            const unitPrice = _sumBy(g, o => o.retailUnitPrice) / g.length;
            const purchaseUnitPrice = _sumBy(g, o => o.purchaseUnitPrice) / g.length;
            const qty = _sumBy(g, o => o.qty);
            const price = unitPrice * qty;
            const purchaseTotalPrice = purchaseUnitPrice * qty;
            const isEqual = _uniq(g.map(o => o.retailUnitPrice)).length === 1;

            const view = new OfferBlockVehicleClassView();

            view.name = g[0].vehicleName;
            view.classId = g[0].vehicleClassId;
            view.payload = g[0].vehiclePayload;
            view.qty = qty;
            view.price = price;
            view.purchaseTotalPrice = purchaseTotalPrice;
            view.unitPrice = preciseFloat(unitPrice * 1000, 2);
            view.purchaseUnitPrice = preciseFloat(purchaseUnitPrice * 1000, 2);
            view.isEqual = isEqual;
            view.count = g.length;

            view.retailPrice.setPerTon(price);
            view.purchasePrice.setPerTon(purchaseTotalPrice);

            collection.push(view);
        });

        return _orderBy(collection, ['payload'], ['desc']);
    }

    /**
     * Extracted from `utils/order.js/lineItemsByVehicleClass()`
     *
     * @param lineItemGroup
     * @return {OfferBlockVehicleClassView[]}
     */
    static createCollectionFromShipmentLineItemGroup(lineItemGroup) {
        const collection = [];

        if (
            !lineItemGroup.lineItems ||
            !lineItemGroup.lineItems.length ||
            lineItemGroup.type !== ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT
        ) {
            return collection;
        }

        const firstLineItem = lineItemGroup.lineItems[0];
        const groups = _groupBy(lineItemGroup.lineItems, 'vehicleClassId');

        const isBillingPerTransport = lineItemGroup.billingMethod === 'fixedPrice';

        Object.values(groups).forEach(g => {
            const transportCount = g.length;
            const qty = firstLineItem.qty;
            const totalQty = qty * transportCount;
            let retailUnitPrice;
            let purchaseUnitPrice;
            let retailTotalPrice;
            let purchaseTotalPrice;

            if (isBillingPerTransport) {
                retailUnitPrice = firstLineItem.transportRetailPrice;
                purchaseUnitPrice = firstLineItem.transportPurchasePrice;
                retailTotalPrice = retailUnitPrice * transportCount;
                purchaseTotalPrice = purchaseUnitPrice * transportCount;
            } else {
                retailUnitPrice = firstLineItem.retailUnitPrice;
                purchaseUnitPrice = firstLineItem.purchaseUnitPrice;
                retailTotalPrice = retailUnitPrice * totalQty;
                purchaseTotalPrice = purchaseUnitPrice * totalQty;
            }

            const view = new OfferBlockVehicleClassView();

            view.name = g[0].vehicleName;
            view.classId = g[0].vehicleClassId;
            view.payload = g[0].vehiclePayload;
            view.qty = totalQty;
            view.price = retailTotalPrice;
            view.purchaseTotalPrice = purchaseTotalPrice;
            view.unitPrice = preciseFloat(retailUnitPrice * 1000, 2);
            view.purchaseUnitPrice = preciseFloat(purchaseUnitPrice * 1000, 2);
            view.isEqual = true;
            view.count = transportCount;

            view.retailPrice.distance = qty;
            view.retailPrice.payload = view.payload;
            view.retailPrice.setPerTransport(isBillingPerTransport ? retailUnitPrice : qty * retailUnitPrice);

            view.purchasePrice.distance = qty;
            view.purchasePrice.payload = view.payload;
            view.purchasePrice.setPerTransport(isBillingPerTransport ? purchaseUnitPrice : qty * purchaseUnitPrice);

            collection.push(view);
        });

        return _orderBy(collection, ['payload'], ['desc']);
    }

    /**
     * Get vc name
     * @return {string|null}
     */
    get name() {
        return this._name;
    }

    /**
     * Set vc name
     * @param value
     */
    set name(value) {
        this._name = value ?? null;
    }

    /**
     * Get vc id
     * @return {number|null}
     */
    get classId() {
        return this._classId;
    }

    /**
     * Set vc ic
     * @param {number} value
     */
    set classId(value) {
        this._classId = value ?? null;
    }

    /**
     * Get payload
     * @return {number|null}
     */
    get payload() {
        return this._payload;
    }

    /**
     * Set payload
     * @param {number} value
     */
    set payload(value) {
        this._payload = value ?? null;
    }

    /**
     * Get qty
     * @return {number|null}
     */
    get qty() {
        return this._qty;
    }

    /**
     * Set qty
     * @param {number} value
     */
    set qty(value) {
        this._qty = value ?? null;
        this.retailPrice.payload = value ?? 0;
        this.purchasePrice.payload = value ?? 0;
    }

    /**
     * Get vc price
     * @return {number|null}
     */
    get price() {
        return this._price;
    }

    /**
     * Set vc price
     * @param {number} value
     */
    set price(value) {
        this._price = value ?? null;
    }

    /**
     * Get vc total purchase price
     * @return {number|null}
     */
    get purchaseTotalPrice() {
        return this._purchaseTotalPrice;
    }

    /**
     * Set vc total purchase price
     * @param {number} value
     */
    set purchaseTotalPrice(value) {
        this._purchaseTotalPrice = value ?? null;
    }

    /**
     * Get vc unit price
     * @return {number|null}
     */
    get unitPrice() {
        return this._unitPrice;
    }

    /**
     * Set vc unit price
     * @param {number} value
     */
    set unitPrice(value) {
        this._unitPrice = value ?? null;
    }

    /**
     * Get vc purchase unit price
     * @return {number|null}
     */
    get purchaseUnitPrice() {
        return this._purchaseUnitPrice;
    }

    /**
     * Set vc purchase unit price
     * @param {number} value
     */
    set purchaseUnitPrice(value) {
        this._purchaseUnitPrice = value ?? null;
    }

    /**
     * Get is equal
     * @return {boolean}
     */
    get isEqual() {
        return !!this._isEqual;
    }

    /**
     * Set is equal
     * @param {boolean} value
     */
    set isEqual(value) {
        this._isEqual = !!value;
    }

    /**
     * Get count of VCs
     * @return {number}
     */
    get count() {
        return this._count;
    }

    /**
     * Set count of VCs
     * @param value
     */
    set count(value) {
        this._count = value ?? 0;
    }

    /**
     * Get price transformer for retail prices
     * @return {TransportPrice}
     */
    get retailPrice() {
        return this._retailPrice;
    }

    /**
     * Get price transformer for purchase prices
     * @return {TransportPrice}
     */
    get purchasePrice() {
        return this._purchasePrice;
    }
}

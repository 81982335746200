/* eslint-disable complexity */
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { getDeliverySellers } from '@/pages/Checkout/queries';
import { FlyoutPage } from '@/constructionProjects/components/FlyoutPage';
import { findRouteName } from '@/pages/routerUtils';
import { Button, Tag, TextField } from '@schuettflix/react-components';
import { SearchIcon, RocketIcon, SustainabilityIcon } from '@schuettflix/icons-react';
import clsx from 'clsx';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { useQuery } from '@tanstack/react-query';
import LoadingSpinner from '@/pages/CertificateManagement/components/LoadingSpinner';
import { useVuexState } from '@/reactBridge/useVuex';
import store from '@/store';
import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
import { useAddressFormatter } from '@/utils/useAddressFormatter';
import SupplierApi from '@/services/Api/Supplier';
import { useOrderScreenName } from '@/pages/Checkout/analytics/react/useOrderScreenName';
import { useAbility } from '@/reactBridge/useAbility';
import useSupplierRequestPlayload from '@/pages/Checkout/useSupplierRequestPlayload';
const SupplierTile = ({ sellerOrganizationName, price, address, masterProductName, productName, distanceInMeters, expanded, sellerProductId, factoryId, organizationLogo, ecoFriendly, cheapestPrice, onClick, }) => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const { formatPerTon } = useMoneyFormatter();
    const { format } = useAddressFormatter();
    const canSeePrices = useAbility('seePrices');
    const selectSupplierProduct = async (supplierProductId, factoryId) => {
        const supplierInfo = await SupplierApi.getOneById(factoryId);
        store.commit('basket/setSupplierInfo', {
            ...supplierInfo,
            sellerProductId: supplierProductId,
        });
        // Set the product name, in case it was not selected from the product search results
        store.commit('basket/updateProductFields', {
            productName,
        });
        await vueRouter.push({ name: findRouteName(vueRouter.currentRoute?.meta?.next) }).catch(() => { });
    };
    return (<div className="w-full cursor-pointer rounded bg-surface p-4 shadow-low" onClick={onClick} data-test="seller-tile">
            <div className="mb-2 flex justify-between">
                <img src={organizationLogo.small} alt={sellerOrganizationName} className="max-h-12 max-w-full"/>
                {canSeePrices && (<div className={clsx(['text-right transition-all duration-500', expanded ? 'ml-2' : 'ml-4'])}>
                        <p className="font-headline-md-strong">{formatPerTon(price)}</p>
                        <p className="font-copy-sm text-subdued">
                            {t('pages.checkout.deliverySupplierSelection.supplierCard.francoText')}
                        </p>
                    </div>)}
            </div>

            <p className="font-headline-md-strong" data-test="seller-name">
                {sellerOrganizationName}
            </p>
            <div className={clsx(['transition-all duration-500', expanded ? '' : 'max-h-0 overflow-y-hidden opacity-0'])}>
                <p className="font-copy-md mt-1">{format(address)}</p>
                <p className="font-copy-md mt-1 text-subdued">
                    {t('pages.checkout.recentProducts.supplierSelectionPage.supplierCard.distance', {
            distance: (distanceInMeters / 1000).toFixed(1),
        })}
                </p>
            </div>

            <hr className="my-4 border border-divider"/>

            <div>
                <div className={clsx(['flex justify-between gap-4 transition-all', !expanded && 'items-center'])}>
                    <div className={clsx(['min-w-0  flex-1'])} data-test="master-product-name">
                        <div className="font-headline-md-strong">{masterProductName}</div>
                        <div className={clsx('font-copy-md', !expanded && 'truncate text-subdued')}>{productName}</div>
                    </div>
                    <div className={clsx([
            'font-copy-md',
            'transition-all duration-500',
            expanded && 'translate-y-6 opacity-0',
        ])}>
                        <div className="flex justify-end">
                            {ecoFriendly && (<Tag label="" leadingIcon={() => <SustainabilityIcon />} className={clsx('mr-2', cheapestPrice && 'mb-2')} type="positive"/>)}
                            {canSeePrices && cheapestPrice && (<Tag label="" leadingIcon={() => <RocketIcon on="primary"/>} className="text-white" type="error"/>)}
                        </div>
                    </div>
                </div>

                <div className={clsx([
            'flex justify-between gap-4 transition-all duration-500',
            !expanded ? 'max-h-0 opacity-0' : 'opacity-1 max-h-[100px] pt-4 delay-150',
        ])}>
                    <div className={clsx(['flex flex-1', 'font-headline-md-strong'])}>
                        <div className={clsx('transition-all duration-500', !expanded && '-translate-y-6 opacity-0')}>
                            {ecoFriendly && (<Tag label={t('pages.checkout.recentProducts.supplierCard.ecoFriendlyTag.label')} leadingIcon={() => <SustainabilityIcon />} className={clsx('mr-2', cheapestPrice && 'mb-2')} type="positive"/>)}
                            {canSeePrices && cheapestPrice && (<Tag label={t('pages.checkout.recentProducts.suppler1Card.bestPriceTag.label')} leadingIcon={() => <RocketIcon on="primary"/>} type="error"/>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className={clsx([
            'overflow-y-hidden align-bottom transition-all duration-300',
            expanded ? 'mt-4 h-12 delay-150' : 'h-0',
        ])}>
                <Button label={t('pages.checkout.recentProducts.supplierCard.selectSupplierButton')} size="sm" className="w-full rounded" onClick={() => selectSupplierProduct(sellerProductId, factoryId)} data-test="select-seller-button"/>
            </div>
        </div>);
};
const SupplierTileList = () => {
    const { t } = useTranslation();
    const locationId = useVuexState(store, state => state.basket.constructionSite.constructionProjectLocationId);
    const masterProductId = useVuexState(store, state => state.basket.product.masterProductId);
    const { generateDeliveryPayload } = useSupplierRequestPlayload();
    const { data: suppliers, isLoading } = useQuery({
        ...getDeliverySellers(locationId, masterProductId, generateDeliveryPayload()),
    });
    const [expandedTile, setExpandedTile] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const filteredSuppliers = useMemo(() => suppliers?.filter(supplier => supplier.sellerOrganizationName.toLowerCase().includes(searchTerm.toLowerCase())), [suppliers, searchTerm]);
    if (isLoading) {
        return <LoadingSpinner spaced/>;
    }
    if (!isLoading && suppliers?.length === 0) {
        return (<div className="font-copy-md flex justify-center pt-8">
                {t('pages.checkout.deliverySupplierSelection.noSuppliers')}
            </div>);
    }
    return (<div className="w-full px-4 py-6">
            <TextField label={t('pages.checkout.deliverySupplierSelection.supplierSearch.label')} className="w-full pb-4" leadingIcon={props => <SearchIcon {...props}/>} value={searchTerm} onChange={event => setSearchTerm(event.target.value)}/>

            {filteredSuppliers?.map((supplier, index) => (<div className="mb-4" key={supplier.sellerProductId}>
                    <SupplierTile {...supplier} expanded={index === expandedTile} onClick={() => setExpandedTile(index)}/>
                </div>))}
        </div>);
};
const _DeliverySupplierSelectionPage = () => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const screenName = useOrderScreenName('client-orderprocess-delivery-supplierselection');
    const handleBackButton = async () => {
        return await vueRouter.push({ name: findRouteName(vueRouter.currentRoute?.meta?.previous) }).catch(() => { });
    };
    return (<>
            <FlyoutPage onCloseAnimationEnd={handleBackButton} headerTitle={t('pages.checkout.recentProducts.supplierSelectionPage.title')} body={<div className="flex h-full flex-col justify-between">
                        <div className="flex h-full flex-col overflow-auto bg">
                            <SupplierTileList />
                        </div>
                    </div>} screenName={screenName}/>
        </>);
};
export const DeliverySupplierSelectionPage = asVueComponent(_DeliverySupplierSelectionPage);

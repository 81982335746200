export const EVENT_ORDER_CANCELED = 'event.order.canceled';
export const EVENT_ORDER_CLOSED = 'event.order.closed';
export const EVENT_ORDER_UPDATED = 'event.order.updated';
export const EVENT_ORGANIZATION_DELETED = 'event.organization.deleted';
export const EVENT_PROJECT_CREATED = 'event.project.created';
export const EVENT_PROJECT_POSITION_CLOSED = 'event.projectPosition.closed';
export const EVENT_PROJECT_POSITION_UPDATED = 'event.projectPosition.updated';
export const EVENT_VEHICLE_CLASS_UPDATED = 'event.vehicleClass.updated';
export const EVENT_QUOTE_UPDATED = 'event.quote.updated';
export const EVENT_VEHICLE_CREATED = 'event.vehicle.created';
export const EVENT_VEHICLE_UPDATED = 'event.vehicle.updated';
export const EVENT_VEHICLE_DELETED = 'event.vehicle.deleted';
export const EVENT_TRACKING_PERMISSIONS_REQUEST = 'event.tracking.permissions.request';
export const EVENT_TRACKING_PERMISSIONS_REQUEST_TIMEOUT = 'event.tracking.permissions.request.timeout';

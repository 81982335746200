import React, { useEffect, useRef, useState, } from 'react';
import { CheckIcon } from '@/constructionProjects/components/icons/CheckIcon';
import clsx from 'clsx';
import { ChevronRightIcon } from '@/constructionProjects/components/icons/ChevronRightIcon';
const SLIDE_BUTTON_WIDTH = 72;
export const SwipeButton = ({ idleMessage, processingMessage, successMessage, onSuccess, isProcessing = true, disabled = false, }) => {
    const isTouchDevice = useRef('ontouchstart' in document.documentElement);
    const isDragging = useRef(false);
    const startX = useRef(0);
    const sliderLeft = useRef(0);
    const containerWidth = useRef(0);
    const slider = useRef(null);
    const container = useRef(null);
    const [isLocked, setIsLocked] = useState(false);
    const [isSwipedToSuccess, setIsSwipedToSuccess] = useState(false);
    useEffect(() => {
        const removeListener = () => {
            document.removeEventListener('touchmove', onDrag);
            document.removeEventListener('touchend', stopDrag);
            document.removeEventListener('mousemove', onDrag);
            document.removeEventListener('mouseup', stopDrag);
            window.removeEventListener('resize', handleResize);
        };
        const addListener = () => {
            window.addEventListener('resize', handleResize);
            if (isTouchDevice.current) {
                document.addEventListener('touchmove', onDrag);
                document.addEventListener('touchend', stopDrag);
            }
            else {
                document.addEventListener('mousemove', onDrag);
                document.addEventListener('mouseup', stopDrag);
            }
        };
        const handleResize = () => {
            containerWidth.current = (container?.current?.offsetWidth ?? 0) - SLIDE_BUTTON_WIDTH;
        };
        handleResize();
        if (isLocked) {
            removeListener();
        }
        else {
            addListener();
        }
        return () => {
            removeListener();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLocked]);
    let animationTimer;
    let slideOutTimer;
    let slideInTimer;
    const ANIMATION_DURATION = 150;
    const slideOut = () => {
        if (!slider.current)
            return;
        slider.current.style.left = '85px';
        slider.current.style.transition = `left ${ANIMATION_DURATION}ms ease-in-out`;
    };
    const slideIn = () => {
        if (!slider.current)
            return;
        slider.current.style.left = `${SLIDE_BUTTON_WIDTH}px`;
        slider.current.style.transition = `left ${ANIMATION_DURATION}ms ease-in-out`;
    };
    const slideInAndOut = () => {
        slideOutTimer = setTimeout(() => {
            slideOut();
        });
        slideInTimer = setTimeout(() => {
            slideIn();
        }, ANIMATION_DURATION);
        setTimeout(() => {
            clearTimeout(slideOutTimer);
            clearTimeout(slideInTimer);
        }, ANIMATION_DURATION * 2);
    };
    const stopAnimation = () => {
        if (!slider.current)
            return;
        clearInterval(animationTimer);
        clearTimeout(slideInTimer);
        clearTimeout(slideOutTimer);
        slider.current.style.transition = '';
    };
    const startAnimation = () => {
        stopAnimation();
        if (isDragging.current)
            return;
        animationTimer = setInterval(() => {
            slideInAndOut();
            setTimeout(() => {
                slideInAndOut();
            }, ANIMATION_DURATION * 2);
        }, 2000);
    };
    useEffect(() => {
        if (disabled)
            return;
        startAnimation();
        return () => {
            stopAnimation();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onDrag = (e) => {
        if (isLocked)
            return;
        if (isDragging.current) {
            if (isTouchDevice.current && 'touches' in e) {
                sliderLeft.current = Math.min(Math.max(0, e.touches[0].clientX - startX.current), containerWidth.current);
            }
            else if ('clientX' in e) {
                sliderLeft.current = Math.min(Math.max(0, e.clientX - startX.current), containerWidth.current);
            }
            updateSliderStyle();
        }
    };
    const shouldLock = () => {
        return sliderLeft.current > containerWidth.current * 0.9;
    };
    const updateSliderStyle = () => {
        if (isLocked)
            return;
        if (slider?.current) {
            slider.current.style.left = sliderLeft.current + SLIDE_BUTTON_WIDTH + 'px';
            if (shouldLock()) {
                setIsSwipedToSuccess(true);
                stopAnimation();
            }
            else {
                setIsSwipedToSuccess(false);
                startAnimation();
            }
        }
    };
    const stopDrag = () => {
        if (isLocked)
            return;
        if (isDragging.current) {
            isDragging.current = false;
            if (shouldLock()) {
                sliderLeft.current = containerWidth.current;
                setIsLocked(true);
                onSuccess();
            }
            else {
                sliderLeft.current = 0;
            }
            updateSliderStyle();
        }
    };
    const startDrag = (e) => {
        if (isLocked)
            return;
        isDragging.current = true;
        stopAnimation();
        if (isTouchDevice.current && 'touches' in e) {
            startX.current = e.touches[0].clientX;
        }
        else if ('clientX' in e) {
            startX.current = e.clientX;
        }
    };
    const getText = () => {
        return isProcessing ? processingMessage : successMessage;
    };
    return (<div className="relative h-18 w-full">
            <div className={clsx('relative flex h-full w-full w-full items-center justify-center overflow-hidden', {
            'bg-brand-primary': !disabled,
            'pointer-events-none bg-surface-subdued-selected': disabled,
        })} ref={container}>
                <div className={clsx('absolute left-18 top-0 z-[100] -ml-[100%] flex flex h-18 w-full cursor-grab cursor-pointer items-center items-center justify-center justify-center transition-[background] duration-1000', {
            'bg-surface-primary-hovered': !isSwipedToSuccess && !disabled,
            'bg-surface-success': isSwipedToSuccess && !disabled,
            'cursor-not-allowed bg-surface-subdued-selected': disabled,
        })} ref={slider} data-testid="slider-button" onMouseDown={startDrag} onTouchStart={startDrag}>
                    <span className="absolute right-[45px] top-[24px] z-20">
                        <CheckIcon size="sm" strokeCssClass="stroke-icon" className={`${isSwipedToSuccess ? 'fill-icon opacity-100' : 'opacity-0'} absolute cursor-grab transition-[opacity] duration-1000`}/>
                        <ChevronRightIcon size="sm" strokeCssClass="stroke-icon-on-critical" className={clsx('absolute cursor-grab transition-[opacity] duration-1000', {
            'opacity-0': isSwipedToSuccess,
            'opacity-100': !isSwipedToSuccess,
        })}/>
                    </span>
                    <span className={clsx('font-copy-md-strong absolute z-10 text-on-success transition-[color_opacity] duration-1000', {
            'opacity-100': isSwipedToSuccess,
            'opacity-0': !isSwipedToSuccess,
        })}>
                        {getText()}
                    </span>
                    <span className={clsx('absolute right-0 top-0 h-18 w-18 cursor-grab transition-[background] duration-1000', {
            'bg-surface-success': isSwipedToSuccess,
            'bg-surface-primary-hovered': !isSwipedToSuccess && !disabled,
            'cursor-not-allowed bg-surface-subdued-selected': disabled,
        })}/>
                </div>
                <div className="font-copy-md-strong z-20 text-on-primary">{idleMessage}</div>
            </div>
        </div>);
};

<template>
    <div
        class="flex h-full w-full"
        data-test="factory-container"
        :class="{ 'factory-container--flyout': usedInFlyout }"
    >
        <aside
            v-if="hasSideBar"
            class="factory-container__sidebar"
            :class="{ 'factory-container__sidebar--hidden': subNavigationIsHidden }"
            data-test="factory-container-sidebar"
        >
            <slot name="sidebar" />
        </aside>
        <div class="factory-container__main" :class="{ 'factory-container--flyout': isFlyoutOpen }">
            <div
                ref="factoryContainerContentWrapper"
                class="factory-container__content-wrapper"
                :class="{ 'factory-container__content-wrapper--flyout': usedInFlyout }"
            >
                <div
                    ref="factoryContainerContent"
                    class="py-4 md:py-12"
                    :class="{ 'pb-[120px]': usedInFlyout }"
                    data-test="factory-container-content"
                >
                    <slot />
                </div>
            </div>
            <div
                v-if="hasButtons"
                class="factory-container__buttons-container"
                :class="{
                    'factory-container__buttons-container--overflow': contentOverflow,
                    'factory-container__buttons-container--flyout': usedInFlyout,
                }"
                :style="{ paddingRight: `${elementAdjustment}px` }"
                data-test="factory-container-buttons-container"
            >
                <ContentWrapper max-width="752" :gutter="false">
                    <div
                        class="buttons-container__shadow"
                        :class="{
                            'buttons-container__shadow--active': showShadow && contentOverflow && !scrolledToBottom,
                        }"
                    />
                    <slot name="buttons" />
                </ContentWrapper>
            </div>
        </div>
    </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill';

import eventHubMixin from '@/plugins/mixins/eventHubMixin';

import ContentWrapper from '@/_components/ContentWrapper/ContentWrapper';

import { computed } from 'vue';

export default {
    name: 'FactoryContainer',
    components: {
        ContentWrapper,
    },
    mixins: [eventHubMixin],
    props: {
        showShadow: {
            type: Boolean,
            default: true,
        },
        usedInFlyout: {
            type: Boolean,
            default: false,
        },
        subNavigationIsHidden: {
            type: Boolean,
            default: false,
        },
    },

    setup(props, { slots }) {
        const hasSideBar = computed(() => !!slots.sidebar);
        const hasButtons = computed(() => !!slots.buttons);

        return {
            hasSideBar,
            hasButtons,
        };
    },

    data() {
        return {
            resizeObserver: null,
            contentOverflow: false,
            scrolledToBottom: false,
            elementAdjustment: 0,
            isFlyoutOpen: false,
        };
    },

    watch: {
        contentOverflow(hasOverflowContent) {
            if (!this.isDesktop) {
                return;
            }

            this.elementAdjustment = 0;

            if (hasOverflowContent) {
                if (!this.usedInFlyout) {
                    this.elementAdjustment =
                        this.$refs.factoryContainerContentWrapper.offsetWidth -
                        this.$refs.factoryContainerContentWrapper.clientWidth;
                } else {
                    this.elementAdjustment =
                        document.querySelector('.flyout__content').offsetWidth -
                        document.querySelector('.flyout__inner').clientWidth;
                }
            }
        },
    },

    mounted() {
        this.registerOnscrollEvent();
        this.resizeObserver = new ResizeObserver(() => this.calculateContentOverflow());

        this.resizeObserver.observe(this.$refs.factoryContainerContent);
        this.resizeObserver.observe(this.$refs.factoryContainerContentWrapper);
        this.subscribe('dialogModalStatus', status => (this.isFlyoutOpen = status));
    },

    beforeDestroy() {
        this.resizeObserver && this.resizeObserver.disconnect();
    },

    methods: {
        registerOnscrollEvent() {
            this.$refs.factoryContainerContentWrapper.onscroll = () => {
                const obj = this.$refs.factoryContainerContentWrapper;
                if (obj.scrollTop === obj.scrollHeight - obj.offsetHeight) {
                    this.scrolledToBottom = true;
                    return;
                }
                this.scrolledToBottom = false;
            };
        },
        calculateContentOverflow() {
            const { clientHeight } = !this.usedInFlyout
                ? this.$refs.factoryContainerContentWrapper
                : document.querySelector('.flyout__content');
            const { scrollHeight } = this.$refs.factoryContainerContent;

            this.contentOverflow = scrollHeight > clientHeight;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

$buttonsContainerHeight: 111px;
$headerTitleBarHeight: 80px;
$mobileNavContainerHeight: 73px;

.factory-container {
    &__sidebar {
        height: 100%;
        min-width: 320px;
        width: 320px;
        z-index: 1;

        @media screen and (max-width: $screen-sm) {
            min-width: 100vw;
            width: 100vw;
            transition: transform 0.3s ease-out;

            &--hidden {
                transform: translateX(-100vw);
            }
        }
    }

    &__main {
        height: calc(
            100vh - (#{$buttonsContainerHeight} + #{$mobileNavContainerHeight})
        ); // button height + mobile nav height
        width: 100%;

        @media screen and (min-width: $screen-xl) {
            height: 100%;
        }

        @media screen and (max-width: $screen-sm) {
            &:not(.factory-container--flyout) {
                transform: translateX(-100vw);
                min-width: 100vw;
            }

            .factory-container--flyout & {
                transform: none;
                min-width: auto;
            }
        }
    }

    &__content-wrapper {
        height: calc(
            100vh - (#{$buttonsContainerHeight} + #{$headerTitleBarHeight} + #{$mobileNavContainerHeight})
        ); // button height + header bar height + mobile nav height
        overflow-y: auto;
        position: relative;

        @media screen and (min-width: $layout-desktop-min) {
            height: calc(100vh - (91px + #{$headerTitleBarHeight})); // button height + header bar height
        }

        &--flyout {
            height: auto;
        }
    }

    &__buttons-container {
        box-sizing: border-box;
        height: $buttonsContainerHeight;
        width: 100%;

        @media screen and (min-width: $layout-desktop-min) {
            height: 91px;
        }

        &--flyout {
            background: $color-lightMediumGrey;
            bottom: 0;
            padding-right: 0;
            position: fixed;
        }

        .buttons-container__shadow {
            box-shadow: none;
            transition: box-shadow 0.2s ease-in;
            background: $color-darkGrey;
            height: 20px;
            left: 26px;
            opacity: 0.25;
            position: absolute;
            right: 26px;
            top: 1px;

            &--active {
                transition: box-shadow 0.2s ease-in;
                box-shadow:
                    0 0 0 $color-darkGrey,
                    0 0 26px $color-darkGrey;
            }
        }
    }

    &--flyout {
        overflow: visible;
    }
}
</style>

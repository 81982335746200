import React, { Suspense } from 'react';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { ConstructionProjectAdminDetailsMobile } from './ConstructionProjectAdminDetailsMobile';
import { ConstructionProjectAdminDetailsDesktop } from './ConstructionProjectAdminDetailsDesktop';
import { useScreenName } from '@schuettflix/analytics-react';
import { LoadingSpinner } from '@schuettflix/react-components';
import { ConstructionProjectAdminDetailsHeader } from './ConstructionProjectAdminDetailsHeader';
const _ConstructionProjectAdminDetailsPage = () => {
    useScreenName('platform-constructionproject-detail');
    return (<div className="flex h-full flex-col bg">
            <Suspense fallback={<LoadingSpinner block spaced/>}>
                <ConstructionProjectAdminDetailsHeader />
                <ConstructionProjectAdminDetailsMobile className="lg:hidden"/>
                <ConstructionProjectAdminDetailsDesktop className="hidden lg:flex"/>
            </Suspense>
        </div>);
};
export const ConstructionProjectAdminDetailsPage = asVueComponent(_ConstructionProjectAdminDetailsPage);

import AbstractFilterableResource from './AbstractFilterableResource';
class SellerApi extends AbstractFilterableResource {
    async getSellerProfile(factoryId, locationId) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/order/supplier-profile/factory/${factoryId}/location/${locationId}`);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
}
export default new SellerApi('');

<template>
    <SfMessageBox class="disposal-cost-section" :headline="$t('pages.checkout.disposalCostSection.title')" type="info">
        <div class="font-copy-md mb-4">
            {{
                isPricePerTransport
                    ? $t('pages.checkout.disposalCostSection.message.perTransport')
                    : $t('pages.checkout.disposalCostSection.message.perTon')
            }}
        </div>
        <TextField
            :value="value"
            small
            type="number"
            step="0.01"
            :label="`${getCurrencySign()} / ${isPricePerTransport ? $t('units.transport') : $t('units.ton')}`"
            class="disposal-cost-section__input max-w-[200px]"
            @input="handleDisposalPrice"
        />
    </SfMessageBox>
</template>

<script>
import TextField from '@/components/Form/TextField.v2';
import { isEmptyString } from '@/services/utils/string';
import { getCurrencySign } from '@/services/utils/currency';

import { SfMessageBox } from '@schuettflix/vue-components';

export default {
    name: 'DisposalCostSection',
    components: {
        TextField,

        SfMessageBox,
    },
    props: {
        value: {
            type: Number,
            default: null,
        },
        isPricePerTransport: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getCurrencySign,

        handleDisposalPrice(price) {
            const formattedPrice = isEmptyString(price) ? null : Number(price);
            this.$emit('input', formattedPrice);
        },
    },
};
</script>

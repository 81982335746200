<template>
    <div class="create-user-cta">
        <Headline :level="2">
            {{ $t('pages.user.userList.addUserCtaTitle') }}
        </Headline>
        <Words block spaced-bottom>
            {{ $t('pages.user.userList.addUserCtaMessage', { name: organizationName }) }}
        </Words>
        <Button primary @click="$emit('onClickAddUserButton')">
            <PlusIcon slot="left" class="icon--inline" />
            {{ $t('pages.user.userList.actions.addUser') }}
        </Button>
    </div>
</template>

<script>
import Headline from '@/components/Typography/Headline';
import Button from '@/components/Button/Button';
import Words from '@/components/Typography/Words';

import PlusIcon from '@/assets/icons/regular/plus.svg';

export default {
    name: 'CreateUserCTA',
    components: {
        Headline,
        Button,
        Words,

        PlusIcon,
    },
    props: {
        organizationName: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.create-user-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
</style>

import _uniq from 'lodash/uniq';

const ATTRIBUTE_IDENTIFIER_SEPARATOR = '.';

function walk(options, path, separator = ATTRIBUTE_IDENTIFIER_SEPARATOR) {
    const [key, ...rest] = path.split(separator);
    const option = options[key] ?? null;

    if (option === null) {
        return null;
    }

    if (rest.length === 0) {
        return option;
    }

    // does not have children, but path can not be resolved
    if (!option.options) {
        return null;
    }

    return walk(option.options, rest.join(separator), separator);
}

function backtrackOptions(state) {
    let next = [...state];

    state.forEach(key => {
        const parts = key.split(ATTRIBUTE_IDENTIFIER_SEPARATOR);
        if (parts.length === 1) return;

        const inbetweens = [];

        for (let i = 1; i < parts.length - 1; i++) {
            inbetweens.push(parts.slice(0, i * -1).join(ATTRIBUTE_IDENTIFIER_SEPARATOR));
        }

        next = next.concat(inbetweens);
    });

    return _uniq(next);
}

function addDefaults(state, options, isMultiple = false) {
    let next = [...state];

    const optionList = Object.values(options);

    const defaultKeys = optionList.filter(o => o.isDefault).map(o => o.value);

    // List of currently active values from current level of iteration
    const includedKeys = optionList.map(o => o.value).filter(k => next.includes(k));

    let relevantKeys;

    if (isMultiple) {
        // Multiple mode, can have multiple defaults
        relevantKeys = _uniq(defaultKeys.concat(includedKeys));
    } else {
        relevantKeys = includedKeys.length === 0 ? defaultKeys : includedKeys;
    }

    next = _uniq(next.concat(relevantKeys));

    const relevantOptions = optionList.filter(o => relevantKeys.includes(o.value));

    // Iterate over all new/old keys to check it's children
    relevantOptions.forEach(option => {
        if (option.options) {
            next = addDefaults(next, option.options, option.isMultiple);
        }
    });

    return next;
}

export default class AttributeManager {
    #attributeSet;

    constructor(attributeSet) {
        this.#attributeSet = attributeSet;
    }

    get attributeSet() {
        return this.#attributeSet;
    }

    /**
     * @param {Array} state
     * @return {Array}
     */
    cleanup(state) {
        const prev = Array.isArray(state) ? state : [];
        const next = [];

        // Remove all unknown keys
        prev.forEach(key => {
            const option = this.getOption(key);
            if (option !== null) {
                next.push(option.value);
            }
        });

        return next;
    }

    /**
     * @param {Array} state
     * @return {Array}
     */
    prepare(state = []) {
        let next = this.cleanup(state);

        // Re-add in-between options, which get removed by the backend
        next = backtrackOptions(next);

        // top level can have multiple defaults, independent of the attribute mode
        Object.values(this.#attributeSet.optionGroups).forEach(optionGroup => {
            next = addDefaults(next, optionGroup.options);
        });
        return next;
    }

    /**
     * @param {string} key
     * @return {null|Object}
     */
    getOption(key) {
        if (typeof key !== 'string') return null;

        return walk(this.#attributeSet.optionGroups, key);
    }

    /**
     * @param {string} key
     * @return {null|Object}
     */
    getParent(key) {
        const parentKey = key.split(ATTRIBUTE_IDENTIFIER_SEPARATOR).slice(0, -1).join(ATTRIBUTE_IDENTIFIER_SEPARATOR);
        if (parentKey === '') return null;

        return this.getOption(parentKey);
    }

    /**
     *
     * @param {Array} state
     * @param {string} key
     * @return {boolean}
     */
    isActive(state, key) {
        return state.includes(key);
    }

    /**
     * @param {Array} state
     * @param {string} key
     * @return {Array}
     */
    add(state, key) {
        let next = this.cleanup(state);
        const option = this.getOption(key);
        if (!option) {
            throw new Error(`Unable to add ${key}, since there is no matching option in current attributeSet`);
        }

        const parent = this.getParent(option.value);

        if (parent && !parent.isMultiple) {
            const parentKey = parent.value;
            next = next.filter(n => !n.startsWith(`${parentKey}.`));
        }

        next.push(key);

        if (option.options) {
            return addDefaults(next, option.options, option.isMultiple);
        }

        return next;
    }

    /**
     * @param {Array} state
     * @param {string} key
     * @return {Array}
     */
    remove(state, key) {
        let next = this.cleanup(state);

        const option = this.getOption(key);
        if (!option) {
            throw new Error(`Unable to remove ${key}, since there is no matching option in current attributeSet`);
        }

        const parent = this.getParent(option.value);

        if (parent && !parent.isMultiple) {
            const parentKey = parent.value;
            next = next.filter(n => !n.startsWith(`${parentKey}.`));
        }

        next = next.filter(n => n !== option.value);

        if (option.options) {
            return next.filter(n => n !== key && !n.startsWith(`${key}.`));
        }

        return next;
    }

    /**
     * @param {Array} state
     * @param {string} key
     * @return {Array}
     */
    toggle(state, key) {
        const next = this.cleanup(state);
        return this.isActive(next, key) ? this.remove(next, key) : this.add(next, key);
    }

    /**
     * @param {Array} state
     * @return {Array}
     */
    finalize(state) {
        const next = this.cleanup(state);

        // Strip all non-edged options
        return next.filter(key => {
            const option = this.getOption(key);
            return !option.options;
        });
    }
}

import i18n from '@/i18n';
import _intersection from 'lodash/intersection';

/**
 * @param {string} value
 * @return {string}
 */
export function getTransformedAttributeKey(value) {
    // attribute option identifier are separated by '.' which can not be used for translations,
    // since they collide with the default label example "drive_mode" and "drive_mode.standard"
    return value.replaceAll('.', '--');
}

function getTranslationKey(value, messageType) {
    const key = getTransformedAttributeKey(value);
    return `pages.vehicleClass.attributes.${messageType}.${key}`;
}

/**
 * @param {string} value
 * @return {string}
 */
export function translateAttributeName(value) {
    return i18n.t(getTranslationKey(value, 'name'));
}

/**
 * @param {string} value
 * @return {boolean}
 */
export function hasAttributeDescription(value) {
    return i18n.te(getTranslationKey(value, 'description'));
}

/**
 * @param {string} value
 * @return {string}
 */
export function translateAttributeDescription(value) {
    return i18n.t(getTranslationKey(value, 'description'));
}

/**
 * @param {Array} attributeList
 * @param {string} scopedValue
 * @return {boolean}
 */
export function isNotPurchasableAttribute(attributeList, scopedValue) {
    const list = attributeList || [];
    const options = Array.isArray(scopedValue) ? scopedValue : [scopedValue];
    return _intersection(list, options).length === 0;
}

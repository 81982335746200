<template>
    <Flyout
        v-if="isActionAvailable"
        :active="isActive"
        :headline="$t('components.orderChangeDisposalPriceAction.title')"
        size="small"
        background-grey
        @visually-closed="resetAction()"
    >
        <Tile transparent no-border>
            <DisposalCostSection v-model="disposalPrice" :is-price-per-transport="isPerTransport" />
        </Tile>

        <template #bottom>
            <ButtonGroup>
                <Button primary block light :disabled="isPending()" @click="closeModal()">
                    {{ $t('components.orderChangeDisposalPriceAction.cancel') }}
                </Button>
                <Button block :disabled="isPending()" :is-loading="isPending('save')" primary @click="save">
                    {{ $t('components.orderChangeDisposalPriceAction.save') }}
                </Button>
            </ButtonGroup>
        </template>
    </Flyout>
</template>

<script>
import { mapGetters } from 'vuex';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import { ACTIONS } from '@/constants/actions';
import DisposalPrice from '@/services/Api/Platform/DisposalPrice';
import { EVENT_ORDER_UPDATED, EVENT_PROJECT_POSITION_UPDATED } from '@/constants/events';
import PubSubEvent from '@/services/PubSub/PubSubEvent';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import ProjectPositionView from '@/models/ProjectPositionView';
import Toaster from '@/services/Toaster';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import DisposalCostSection from '@/pages/Checkout/components/PriceAdjustment/DisposalCostSection';
import Flyout from '@/components/Layout/Flyout';
import Tile from '@/components/Layout/Tile';

export default {
    name: 'OrderChangeDisposalPriceAction',
    components: {
        Button,
        ButtonGroup,
        DisposalCostSection,
        Flyout,
        Tile,
    },

    mixins: [statefulMixin, eventHubMixin],

    data() {
        return {
            disposalPrice: null,
            isActive: false,
        };
    },

    computed: {
        ...mapGetters('globalActions', ['subject', 'action']),

        isActionAvailable() {
            switch (this.action) {
                case ACTIONS.ORDER_DISPOSAL_PRICE_CHANGE:
                    return this.$can('updateDisposalPriceOnShipmentOrder', this.subject);
                case ACTIONS.PROJECT_POSITION_DISPOSAL_PRICE_CHANGE:
                    return this.$can('updateDisposalPriceOnShipmentPosition', this.subject);
                default:
                    return false;
            }
        },

        subjectRoot() {
            // Depending on the type of the subject (order/projectPosition) most of the data
            // is located on lineItemGroups or the subject itself
            // This can be changed when there is an OrderView which can flatten those properties
            return this.action === ACTIONS.ORDER_DISPOSAL_PRICE_CHANGE ? this.subject?.lineItemGroups[0] : this.subject;
        },

        isPerTransport() {
            return this.subjectRoot?.billingMethod === 'fixedPrice';
        },
    },

    watch: {
        isActionAvailable(status) {
            if (!status) {
                return;
            }
            this.loadDisposalPrice();
            this.isActive = true;
        },
    },

    methods: {
        resetAction() {
            this.disposalPrice = null;
            this.$store.commit('globalActions/resetAction', this.action);
        },

        closeModal() {
            this.isActive = false;
        },

        async save() {
            await this.stateful('save', async () => {
                try {
                    if (this.action === ACTIONS.ORDER_DISPOSAL_PRICE_CHANGE) {
                        const order = await DisposalPrice.updateOrder(
                            this.subject.id,
                            this.disposalPrice,
                            this.isPerTransport
                        );
                        this.$eventHub.$emit(EVENT_ORDER_UPDATED, new PubSubEvent(order));
                    } else if (this.action === ACTIONS.PROJECT_POSITION_DISPOSAL_PRICE_CHANGE) {
                        const projectPosition = await DisposalPrice.updateProjectPosition(
                            this.subject.id,
                            this.disposalPrice,
                            this.isPerTransport
                        );
                        this.$eventHub.$emit(
                            EVENT_PROJECT_POSITION_UPDATED,
                            new PubSubEvent(ProjectPositionView.create(projectPosition))
                        );
                    }

                    this.closeModal();
                    Toaster.success(this.$t('components.orderChangeDisposalPriceAction.success'));
                } catch (err) {
                    this.$logger().error(err);
                    Toaster.error(this.$t('components.orderChangeDisposalPriceAction.error'));
                }
            });
        },

        loadDisposalPrice() {
            this.disposalPrice = this.isPerTransport
                ? this.subjectRoot?.disposalPricePerTransport
                : this.subjectRoot?.disposalPricePerTon;
        },
    },
};
</script>

<style></style>

<template>
    <section class="card card--md">
        <div class="transport-list-loading-block">
            <div class="transport-list-loading-block__head">
                <LazyLoadingIndicator />
            </div>
            <div class="transport-list-loading-block__body">
                <div class="transport-list-loading-block__address-progress">
                    <ul class="transport-list-loading-block__address--list">
                        <li class="transport-list-loading-block__address-item" :class="computedItemClasses">
                            <LazyLoadingIndicator />
                            <LazyLoadingIndicator class="transport-list-loading-block__spaced-top" />
                        </li>
                        <li class="transport-list-loading-block__address-item" :class="computedItemClasses">
                            <LazyLoadingIndicator />
                            <LazyLoadingIndicator class="transport-list-loading-block__spaced-top" />
                        </li>
                    </ul>

                    <div
                        :class="[
                            'absolute bottom-[26px] left-[7px] top-4 w-[10px]',
                            'border-l border-l-4 border-l-white',
                            'border-r border-r-4 border-r-white',
                        ]"
                    >
                        <div :class="['absolute inset-0 w-1', 'border-l border-l-2 border-dotted']" />
                    </div>
                </div>
            </div>
            <div v-if="!noFooter" class="transport-list-loading-block__footer">
                <LazyLoadingIndicator />
            </div>
        </div>
    </section>
</template>

<script>
import LazyLoadingIndicator from '@/components/LazyLoadingIndicator';

export default {
    name: 'TransportListLoadingBlock',

    components: {
        LazyLoadingIndicator,
    },

    props: {
        noFooter: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        computedItemClasses() {
            return [
                'relative pl-12 mb-0 mt-6 first:mt-0',

                "before:content-[''] before:hidden first:before:block last:before:block before:absolute before:top-2 before:left-2",
                'before:rounded-full before:w-2 before:h-2',

                {
                    'before:bg-surface-active': !this.muted,
                    'before:bg-surface-disabled': this.muted,
                },

                "after:content-[''] after:hidden first:after:block last:after:block after:absolute after:top-0 after:left-0",
                'after:rounded-full after:w-6 after:h-6 after:border',

                {
                    'after:border': !this.muted,
                    'after:border-disabled': this.muted,
                },
            ];
        },
    },
};
</script>

<style lang="scss">
.transport-list-loading-block {
}

.transport-list-loading-block__body {
    border-top: 1px solid $color-littleDarkerThanMediumGrey;
    margin-top: 24px;
    padding-top: 24px;
}

.transport-list-loading-block__footer {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $color-littleDarkerThanMediumGrey;
}

.transport-list-loading-block__address-progress {
    position: relative;
}

.transport-list-loading-block__address--list {
    list-style: none;
    padding-left: 0;
}

.transport-list-loading-block__spaced-top {
    margin-top: 10px;
}
</style>

import { forwardRef } from 'react';
// TODO extract to component library, this is just temporary until icon PR is merged
export const DeliveryOrderIcon = forwardRef(({ className, ...props }, ref) => {
    return (<svg width={80} height={42} fill="none" className={className} ref={ref} xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#a)" strokeWidth={1.2} strokeLinecap="round" strokeLinejoin="round">
                <path d="M16 25.01a7.444 7.444 0 0 0-4.32 7.53M11.66 23.748c-1.58.508-4.14 1.534-4.32 6.588" stroke="red"/>
                <path d="M18.701 37.142H3.821" stroke="#1F1920"/>
                <path d="M9.251 31.531c-.395-.33-2.014-1.967-5.035 0M9.496 34.252a3.765 3.765 0 0 1 4.706-1.073M5.044 34.016s-1.957-2.438-3.934 0M11.152 26.317a4.047 4.047 0 0 0-1.035 2.269M3.077 29.837l-1.252-1.26M13.618 31.268l.584-.602M43.755 3.381s-4.706-2.165-8.132.998M34.597 6.129s-7.802-3.482-12.95 4.103" stroke="red"/>
                <path d="M22.71 10.477a2.532 2.532 0 0 0-3.322 1.186" stroke="red"/>
                <path d="M77.176 31.578H74.26v-1.42a.32.32 0 0 1 .32-.311h2.597a.141.141 0 0 1 .142.131v1.469a.141.141 0 0 1-.142.131v0ZM76.291 22.845v-7.228a.659.659 0 0 0-.659-.659h-12.31a.677.677 0 0 0-.63.461l-.255.79a1.967 1.967 0 0 0 0 1.253 5.214 5.214 0 0 0 2.683 3.2l10.174 1.656a1.76 1.76 0 0 1 1.496 1.75v5.77M59.294 9.603h-.941a.593.593 0 0 0-.593.583v24.207" stroke="#1F1920"/>
                <path d="M76.17 15.24a12.71 12.71 0 0 0-.876-3.275 1.74 1.74 0 0 0-1.13-.941A39.322 39.322 0 0 0 65.234 9.8l-5.94-.197" stroke="#1F1920"/>
                <path d="m67.981 9.98-5.299-2.382a1.78 1.78 0 0 0-1.412-.188 1.704 1.704 0 0 0-1.1 1.176l-.302 1.017 6.532.263M66.344 15.24v5.421M77.063 31.58v1.401l-1.478.622-1.816.78h-4.772M41.637 33.556h-6.852M68.997 32.972a3.417 3.417 0 1 0-6.834 0 3.417 3.417 0 0 0 6.834 0ZM26.259 32.972a3.416 3.416 0 1 0-6.833 0 3.416 3.416 0 0 0 6.833 0v0ZM34.823 32.972a3.416 3.416 0 1 0-6.831 0 3.416 3.416 0 0 0 6.831 0v0ZM50.823 32.972a3.407 3.407 0 1 0-3.407 3.416 3.417 3.417 0 0 0 3.407-3.416v0ZM28.038 29.254h-1.694M37.346 33.536h-2.523M17.299 34.035v-4.998h2.635M76.687 24.642l-1.675-.291M73.364 13.753v3.19M74.993 36.821H56.197M51.36 37.047H24.028M51.783 29.301h3.925V13.65M43.35 29.254h-7.727M55.708 33.536h-4.895" stroke="#1F1920"/>
                <path d="M78.71 26.985H67.719M66.032 26.985h-.15" stroke="red"/>
                <path d="m18.513 27.485-2.494-12.706L50.654 3.212l-.085 14.353-32.056 9.92ZM55.698 7.344v6.306M62.211 34.384H60.64M43.66 9.01l-22.136 7.162M51.783 29.3h1.478V19.288h-1.478V29.3ZM52.518 19.278V4.765M50.654 3.212h2.607M16.018 14.778l-2.4 8.97M46.785 40.482H17.91" stroke="#1F1920"/>
                <path d="m10.625 9.282-3.68 3.68M6.937 9.282l3.689 3.68M73.364 5.339h-3.219M71.755 3.73v3.218" stroke="red"/>
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" transform="translate(0 .765)" d="M0 0h80v40.471H0z"/>
                </clipPath>
            </defs>
        </svg>);
});
DeliveryOrderIcon.displayName = 'DeliveryOrderIcon';

<template>
    <div class="container-off-canvas-sm relative h-full w-full w-full py-6">
        <div
            v-for="lineItemGroup in order.lineItemGroups"
            :key="lineItemGroup.id"
            class="p-0"
            :class="computedCardClasses"
        >
            <OverviewCard :line-item-group="lineItemGroup" :order-id="order.id" />

            <div v-if="lineItemGroup.lineItems.length > 0">
                <SfButton class="w-full" size="sm" @click="showTransports(order.id, lineItemGroup.id)">
                    {{ $t('pages.order.orderView.overview.showDetail') }}
                </SfButton>
            </div>
        </div>

        <div v-if="isHazardousWaste" :class="computedCardClasses">
            <IdentifiersCard :identifiers="disposalIdentifiers" />
        </div>

        <DisposerCard v-if="isDisposal" :disposer="disposerInfo" has-title preview>
            <template #card-action>
                <SfLink class="mt-6" bold type="secondary" size="md" @click="openSupplierDetail()">
                    {{ $t('components.supplierTile.detailPageLabel') }}
                </SfLink>
            </template>
        </DisposerCard>

        <SiteLocation
            v-if="firstLineItemGroup.loadingSiteLocation && isDisposal"
            class="card my-6"
            :item="firstLineItemGroup"
        />

        <!-- dummy comment to generate diff for the PR/instance: to be removed later on -->

        <LoadingSpinner v-if="!hasLoadedProjectPosition && isProjectPosition" dark class="mb-6" />
        <ProjectPositionInfoCard
            v-else-if="hasLoadedProjectPosition && isProjectPosition"
            :class="computedCardClasses"
            :project-position-id="projectPosition.id"
            :project-position="projectPosition"
        />

        <BillingMethodCard
            v-if="isDisposal && hasBillingMethod"
            class="mb-6"
            :billing="orderShipmentBillingDetails"
            :editable="false"
        />

        <section v-if="supplierInfo && isDelivery" :class="computedCardClasses">
            <SupplierTile
                :supplier-info="supplierInfo"
                spaceless
                show-address
                show-headline
                show-material-rating
                show-more
                no-border
                @more-action="openSupplierDetail()"
            />
        </section>

        <section v-if="supplierInfo && isPickup" :class="computedCardClasses">
            <SupplierTile
                :supplier-info="supplierInfo"
                :construction-site="firstLineItemGroup.constructionSite"
                :show-distance="['new', 'in_progress'].includes(order.status)"
                spaceless
                show-headline
                show-more
                show-address
                show-contact-orb
                show-overall-rating
                no-border
                business-hours-type="default"
                @more-action="openSupplierDetail()"
            />
        </section>

        <section v-if="firstLineItemGroup.carrier && order.customOrder" :class="computedCardClasses">
            <CarrierTile :carrier="firstLineItemGroup.carrier" show-headline show-address />
        </section>

        <template v-if="isShipment">
            <section :class="computedCardClasses">
                <SiteTile
                    :item="firstLineItemGroup"
                    type="loading"
                    spaceless
                    show-headline
                    show-address
                    show-date
                    show-thumbnails
                    show-information
                    show-contact-info
                />
            </section>

            <section :class="computedCardClasses">
                <SiteTile
                    :item="firstLineItemGroup"
                    type="unloading"
                    spaceless
                    show-headline
                    show-address
                    show-date
                    show-thumbnails
                    show-information
                    show-contact-info
                />
            </section>
            <section :class="computedCardClasses">
                <ShipmentBillingDetailsTile :shipment-billing-details="orderShipmentBillingDetails" />
            </section>
        </template>
        <section v-else-if="firstLineItemGroup.unloadingSiteLocation" :class="computedCardClasses">
            <SiteLocation site-type="unloadingSite" :item="firstLineItemGroup" />
        </section>

        <section v-if="isPickup" :class="computedCardClasses">
            <ValidityTile :expire-date="orderExpireDate" />
        </section>

        <section v-if="order.costCenter" :class="computedCardClasses">
            <CostCenterTile :order="order" />
        </section>
        <section :class="computedCardClasses">
            <People :item="order" is-overview />
        </section>
    </div>
</template>

<script>
import SupplierApi from '@/services/Api/Supplier';
import _get from 'lodash/get';
import { navigationFailure } from '@/services/utils/router';

import CarrierTile from '@/components/Carrier/CarrierTile';
import CostCenterTile from '@/pages/Order/components/CostCenterTile';
import IdentifiersCard from '@/_components/IdentifiersCard/IdentifiersCard.vue';
import DisposerCard from '@/pages/Checkout/Disposal/Components/DisposerCard.vue';
import OverviewCard from './OverviewCard';
import People from '@/components/Order/People';
import ProjectPositionInfoCard from '@/components/Project/ProjectPositionInfoCard';
import ShipmentBillingDetailsTile from '@/components/ShipmentBillingDetailsTile';
import BillingMethodCard from '@/pages/Checkout/Disposal/ProjectPosition/SummaryPage/BillingMethodCard';
import SiteTile from '@/components/SiteTile';
import SupplierTile from '@/components/Supplier/SupplierTile';
import SiteLocation from '@/components/Order/SiteLocation';
import ValidityTile from './ValidityTile';

import { SfButton, SfLink } from '@schuettflix/vue-components';
import LoadingSpinner from '@/components/LoadingSpinner';
import { isOrderLineItemGroupTypeDisposal } from '@/constants/disposal';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';

export default {
    name: 'OrderOverviewTab',

    components: {
        CarrierTile,
        CostCenterTile,
        IdentifiersCard,
        DisposerCard,
        OverviewCard,
        People,
        ProjectPositionInfoCard,
        ShipmentBillingDetailsTile,
        BillingMethodCard,
        SiteTile,
        SupplierTile,
        SiteLocation,
        ValidityTile,
        LoadingSpinner,

        SfButton,
        SfLink,
    },

    props: {
        order: {
            type: Object,
            default: null,
        },
        projectPosition: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            supplierInfo: null,
            hasLoadedProjectPosition: false,
        };
    },

    computed: {
        disposerInfo() {
            const supplier = this.firstLineItemGroup.supplier;

            return {
                name: supplier?.name,
                supplierId: supplier?.customerNumber,
                factoryId: this.firstLineItemGroup?.factoryId,
                logo: supplier?.logo,
                factoryAddress: {
                    ...supplier?.factoryAddress,
                },
                factoryType: supplier?.factoryType,
                factoryName: supplier?.location?.factoryName,
                disposerNumber: supplier?.disposerNumber,
                landfillClass: supplier?.landfillClass,
            };
        },

        computedCardClasses() {
            return 'card mb-6 last:mb-0';
        },
        disposalIdentifiers() {
            const { disposalProofNumber, producerNumber, supplier, carrier } = this.firstLineItemGroup;

            const disposalIdentifiers = [
                {
                    number: disposalProofNumber,
                    title: 'disposal.disposalCertificateNumber.title',
                },
                {
                    number: producerNumber,
                    title: 'disposal.producer.title',
                },
                {
                    number: supplier?.disposerNumber,
                    title: 'disposal.disposer.title',
                },
            ];

            if (carrier?.carrierNumber) {
                disposalIdentifiers.push({ number: carrier.carrierNumber, title: 'disposal.carrier.title' });
            }

            return disposalIdentifiers;
        },

        firstLineItemGroup() {
            return _get(this.order, 'lineItemGroups[0]');
        },

        isPickup() {
            return this.firstLineItemGroup.type === ORDER_LINE_ITEM_GROUP_TYPE.PICKUP;
        },

        isDelivery() {
            return this.firstLineItemGroup.type === ORDER_LINE_ITEM_GROUP_TYPE.DELIVERY;
        },

        isShipment() {
            return this.firstLineItemGroup.type === ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT;
        },

        isDisposal() {
            return isOrderLineItemGroupTypeDisposal(this.firstLineItemGroup.type);
        },

        isHazardousWaste() {
            return [ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_HAZARDOUS_WASTE].includes(this.firstLineItemGroup.type);
        },

        orderExpireDate() {
            return this.firstLineItemGroup.expireDate;
        },
        hasBillingMethod() {
            return (
                this.orderShipmentBillingDetails?.billingMethod &&
                typeof this.orderShipmentBillingDetails?.billingMethod !== 'undefined'
            );
        },

        orderShipmentBillingDetails() {
            const {
                billingMethod,
                weighingBillingBasis,
                weighingAtLoadingPoint,
                weighingAtUnloadingPoint,
                billingByStateWasteCompany,
            } = this.firstLineItemGroup;

            return {
                billingMethod,
                weighingBillingBasis,
                weighingAtLoadingPoint,
                weighingAtUnloadingPoint,
                billedByStateWasteCompany: billingByStateWasteCompany,
            };
        },
        isProjectPosition() {
            return this.firstLineItemGroup?.projectPositionId;
        },
    },
    watch: {
        projectPosition(newValue) {
            if (newValue !== null) {
                this.hasLoadedProjectPosition = true;
            }
        },
    },

    created() {
        if (!this.isShipment) {
            this.fetchSupplierInfo();
        }
    },

    methods: {
        async fetchSupplierInfo() {
            this.supplierInfo = await SupplierApi.getOneById(this.firstLineItemGroup.factoryId);
        },

        openSupplierDetail(showMapInitially = false) {
            this.$router
                .push({
                    name: this.$root.findRouteName('order-supplier-view'),
                    params: {
                        orderId: this.order.id,
                        factoryId: this.firstLineItemGroup.factoryId,
                        showMapInitially,
                    },
                })
                .catch(navigationFailure);
        },

        showTransports(orderId, lineItemGroupId) {
            this.$router
                .push({
                    name: this.$root.findRouteName('order-view__order-transports'),
                    params: {
                        ...this.$route.params,
                        orderId,
                        lineItemGroupId,
                    },
                    query: this.$route.query,
                })
                .catch(err => {
                    this.$logger().error(err);
                });
        },
    },
};
</script>

<template>
    <div class="location-field">
        <EnhancedAddressField
            :value="place"
            :autocomplete="false"
            :label="label"
            search-address=""
            :street-less="streetLess"
            :no-sublines="noSublines"
            no-buttons
            :is-loading="isLoading"
            :list-locality="listLocality"
            :allowed-countries="allowedCountries"
            class="location-field__input"
            input-class-name="combined-input"
            :data-test="dataTest"
            @input="emitAddressChange"
        />
        <SelectBox :value="radius" class="combined-select" @input="emitRadiusChange">
            <option :disabled="strictRadiusOptions" value>{{ $t('components.locationField.radius') }}</option>
            <option v-for="option in radiusOptions" :key="option" :value="option">{{ option }} km</option>
        </SelectBox>
    </div>
</template>

<script>
import EnhancedAddressField from '@/pages/Login/components/EnhancedAddressField';
import SelectBox from './SelectBox.v2';

export default {
    name: 'LocationField',
    components: {
        EnhancedAddressField,
        SelectBox,
    },
    props: {
        place: {
            type: Object,
            default: () => {},
        },
        radius: {
            type: [String, Number],
            default: null,
        },
        radiusOptions: {
            type: Array,
            default: () => [],
        },
        radiusOptionsDefault: {
            type: Number,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        noSublines: {
            type: Boolean,
            default: false,
        },
        streetLess: {
            type: Boolean,
            default: false,
        },
        strictRadiusOptions: {
            type: Boolean,
            default: false,
        },
        listLocality: {
            type: Boolean,
            default: false,
        },
        dataTest: {
            type: String,
            default: '',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        allowedCountries: {
            type: Array,
            default: null,
        },
    },
    watch: {
        radius: {
            immediate: true,
            handler(value) {
                this.emitRadiusChange(value);
            },
        },
    },
    methods: {
        emitAddressChange(address) {
            this.emitRadiusChange(!address ? null : this.radius || this.radiusOptionsDefault);
            this.$emit('placeChange', address);
        },
        emitRadiusChange(radius) {
            this.$emit('radiusChange', radius !== '' ? radius : null);
        },
    },
};
</script>

<style lang="scss">
.location-field {
    display: flex;
    flex-flow: row nowrap;

    .combined-select .select-box__input-group {
        border-left: 0 !important;

        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        /**
         * Fix for new components height
         */
        // height: 72px !important;
    }
}

.location-field__input {
    flex-grow: 1;

    .combined-input input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>

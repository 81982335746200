import AbstractResource from './AbstractResource';

class Location extends AbstractResource {
    /**
     * Get Open Location Code for given location (latitude, longitude)
     * @param lat
     * @param lng
     * @return {Promise<string>}
     */
    async getOpenLocationCode({ lat, lng }) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/open-location-code`, {
                params: {
                    lat,
                    lng,
                },
            });
            return response.data.openLocationCode;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new Location('/location');

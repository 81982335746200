import Context from './Context';
import rules from './rules';
import LogService from '@schuettflix/util-log';

const Log = new LogService('service/AbilityChecker');

export default class AbilityChecker {
    constructor(abilities = {}) {
        this.abilities = abilities;
    }

    checkRule([ruleName, ...data], context) {
        if (!(context instanceof Context)) {
            throw new Error(`given context is not of type Context`);
        }

        if (!Object.prototype.hasOwnProperty.call(rules, ruleName)) {
            Log.error(`rule with name ${ruleName} doesn't exist`);
            return false;
        }

        return this.trace(ruleName, data, context, () => {
            return rules[ruleName].call(this, data, context);
        });
    }

    can(abilityName, context) {
        if (!Object.prototype.hasOwnProperty.call(this.abilities, abilityName)) {
            Log.error(`ability with name ${abilityName} doesn't exist`);
            return false;
        }

        context.setAbilityName(abilityName);

        const rule = this.abilities[abilityName];
        return this.checkRule(rule, context);
    }

    /**
     * Generate a trace for a call
     *
     * Usage: Provide {_trace: true} as an additional subject property to enable tracing
     * Note: the trace will only show the executed path
     *
     * @param {*} ruleName
     * @param {*} data
     * @param {*} context
     * @param {*} cb
     */
    trace(ruleName, data, context, cb) {
        // Skip trace if not requested
        if (!context._subject || (context._subject && !context._subject._trace)) {
            return cb();
        }

        // Store previous trace
        const prevTrace = context.trace;

        // Start recording of nested trace
        context.trace = [];

        context.traceDepth++;
        const depth = context.traceDepth;
        const abilityName = context.abilityName;

        const result = cb();

        // Reset ability name in case a nested ability changed it
        context.setAbilityName(abilityName);

        // Read recorded nested trace
        const childTrace = [...context.trace];

        // Restore original trace
        context.trace = prevTrace;

        // Push trace resuls
        context.trace.push({
            abilityName,
            ruleName,
            result,
            data,
            childTrace,
            depth,
        });

        context.traceDepth--;

        // End of tree traversal
        if (context.traceDepth === -1) {
            // Is nested context? Pass trace to parent
            if (context.parentContext !== null) {
                context.parentContext.trace.push(...context.trace);
            } else {
                printTrace(context.trace);
            }
        }

        return result;
    }
}

/**
 * Pretty print a trace in the console
 * @param {*} trace
 */
function printTrace(trace) {
    /* eslint-disable no-console */
    const PRINT_TRACE_SPACE = '    ';

    trace.forEach(t => {
        const hasChildTrace = t.childTrace.length > 0;
        let prepend = '';
        const prependColors = [];

        for (let i = 0; i < t.depth; i++) {
            prepend += PRINT_TRACE_SPACE.replace(/^./, '%c|%c');
            prependColors.push(i % 2 == 0 ? 'color:#009cde;' : 'color:purple');
            prependColors.push('color:#ddd');
        }

        const bracketColor = t.depth % 2 == 0 ? 'color:#009cde;' : 'color:purple';

        console.log(
            `${prepend}%c${t.result} %c<-- %c${t.abilityName}::%c${t.ruleName}%c(${
                hasChildTrace ? '%c' : `%c${JSON.stringify(t.data)}`
            }%c)${!hasChildTrace ? '%c' : ' %c{'}`,
            ...prependColors,
            t.result ? 'color:green;' : 'color:red;',
            'color:#000;',
            'color:#999;',
            'color:#000;',
            'color: #999;',
            'color: #999;',
            'color: #999;',
            bracketColor
        );

        if (hasChildTrace) {
            printTrace(t.childTrace);
            console.log(`${prepend}%c}`, ...prependColors, bracketColor);
        }
    });
}

<template>
    <input
        :value="value"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        :style="style"
        class="range"
        @input="handleInput"
    />
</template>

<script>
export default {
    name: 'Range',
    props: {
        value: {
            type: Number,
            default: null,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
        step: {
            type: Number,
            default: 10,
        },
        thumbIconPath: {
            type: String,
            default: null,
        },
    },
    computed: {
        style() {
            if (!this.thumbIconPath) return;

            return {
                '--thumb-icon-url': `url('../${this.thumbIconPath}')`,
            };
        },
    },
    methods: {
        handleInput(e) {
            const value = e.target.value ? parseFloat(e.target.value) : null;
            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss">
.range {
    -webkit-appearance: none;
    margin: 19px 0;
    width: 100%;
}
.range:focus {
    outline: none;
}

// Webkit
.range::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: #cccccc;
}
.range::-webkit-slider-thumb {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: $color-red;
    background-image: var(--thumb-icon-url);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
}
.range:focus::-webkit-slider-runnable-track {
}

// Firefox
.range::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: #cccccc;
}
.range::-moz-range-thumb {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 0;
    background-color: $color-red;
    background-image: var(--thumb-icon-url);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    margin-top: -20px;
}

// IE
.range::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 40px 0;
    color: transparent;
}
.range::-ms-fill-lower {
    background: #cccccc;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow:
        1px 1px 1px #000000,
        0px 0px 1px #0d0d0d;
}
.range::-ms-fill-upper {
    background: #cccccc;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow:
        1px 1px 1px #000000,
        0px 0px 1px #0d0d0d;
}
.range::-ms-thumb {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: $color-red;
    background-image: var(--thumb-icon-url);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}
.range:focus::-ms-fill-lower {
}
.range:focus::-ms-fill-upper {
}
</style>

<template>
    <Flyout no-header :active="true">
        <HeaderBar slot="header">
            <HeaderBarItem slot="left" button @click="closeUsernameLost">
                <ArrowIcon width="32" height="18" />
            </HeaderBarItem>
            <div slot="headline">
                {{ $t('pages.login.usernameLost.headline') }}
            </div>
        </HeaderBar>

        <Tile no-border>
            <Words block>
                {{ $t('pages.login.usernameLost.description') }}
            </Words>

            <div class="login__username-lost-number">
                <SelectBox v-model="countryCode" dark class="login__username-lost-country-code">
                    <option v-for="(code, i) in countryCodes" :key="i" :value="code.code">
                        {{ code.code }}
                    </option>
                </SelectBox>

                <TextField
                    v-model="usernameLostNumber"
                    :label="$t('pages.login.usernameLost.usernameLostNumberPlaceholder')"
                    :has-error="hasErrors('usernameLostNumber')"
                    :error="getError('usernameLostNumber')"
                    type="text"
                    class="login__username-lost-number-input"
                />
            </div>
        </Tile>

        <ButtonGroup slot="bottom">
            <Button
                :disabled="!usernameLostNumber || usernameLostPending"
                type="submit"
                primary
                @click="handleUsernameLost"
            >
                {{ $t('pages.login.usernameLost.submitUsernameLost') }}
            </Button>
        </ButtonGroup>
    </Flyout>
</template>
<script>
import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Flyout from '@/components/Layout/Flyout';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import SelectBox from '@/components/Form/SelectBox';
import TextField from '@/components/Form/TextField.v2';
import Tile from '@/components/Layout/Tile';
import Words from '@/components/Typography/Words';

import Authenticator from '@/services/Api/Authenticator';
import validate from '@/services/validation/mixin';
import { isRequired, isPhoneNumber, minLength, maxLength } from '@/services/validation/rules';
import Toaster from '@/services/Toaster';

import countryCodes from '@/config/countryCodes';

import ArrowIcon from '@/assets/icons/regular/arrow.svg';

export default {
    name: 'UserNameResetFlyout',
    components: {
        Button,
        ButtonGroup,
        Flyout,
        HeaderBar,
        HeaderBarItem,
        SelectBox,
        TextField,
        Tile,
        Words,

        ArrowIcon,
    },

    mixins: [validate],

    emits: ['close', 'messageSent'],

    data: () => ({
        countryCode: '+49',
        usernameLostNumber: '',
        usernameLostPending: false,
    }),

    computed: {
        countryCodes: () => countryCodes,
        validationRules: () => ({
            usernameLostNumber: [
                isRequired('pages.login.validation.phoneNumberIsRequired'),
                isPhoneNumber(),
                minLength(3),
                maxLength(30),
            ],
        }),
    },

    methods: {
        async handleUsernameLost() {
            if (this.isValid()) {
                this.usernameLostPending = true;
                const phoneNumber = this.usernameLostNumber.replace(/\D/g, '').replace(/^0+/, '');

                try {
                    await Authenticator.usernameLost(this.countryCode, phoneNumber);
                    this.$emit('messageSent');
                } catch (err) {
                    this.$logger().error(err);
                    Toaster.error(err);
                } finally {
                    this.usernameLostNumber = '';
                    this.countryCode = '+49';
                    this.usernameLostPending = false;
                }
            }
        },
        closeUsernameLost() {
            this.usernameLostNumber = '';
            this.countryCode = '+49';
            this.$emit('close');
        },
    },
};
</script>

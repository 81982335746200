import CreditNoteListPage from '@/pages/CreditNote/CreditNoteListPage';
import CreditNoteListSupplierPage from '@/pages/CreditNote/CreditNoteListSupplierPage';
import CreditNoteListCarrierPage from '@/pages/CreditNote/CreditNoteListCarrierPage';
import CreditNoteViewPage from '@/pages/CreditNote/CreditNoteViewPage';

export default [
    {
        path: 'credit-note/supplier',
        name: 'credit-note-supplier',
        component: CreditNoteListSupplierPage,
        props: true,
        meta: {
            requiresAuth: true,
            noBackArrow: true,
            requiredAbilities: ['haveCreditNoteManagement'],
        },
        children: [
            {
                path: ':creditNoteId',
                name: 'credit-note-supplier-view',
                component: CreditNoteViewPage,
                props: true,
            },
            {
                include: 'order-view',
            },
            {
                include: 'transport-view',
            },
        ],
    },
    {
        path: 'credit-note/carrier',
        name: 'credit-note-carrier',
        component: CreditNoteListCarrierPage,
        props: true,
        meta: {
            requiresAuth: true,
            noBackArrow: true,
            requiredAbilities: ['haveCreditNoteManagement'],
        },
        children: [
            {
                path: ':creditNoteId',
                name: 'credit-note-carrier-view',
                component: CreditNoteViewPage,
                props: true,
            },
            {
                include: 'order-view',
            },
            {
                include: 'transport-view',
            },
        ],
    },
    {
        path: 'credit-note',
        name: 'credit-note',
        component: CreditNoteListPage,
        props: true,
        meta: {
            requiresAuth: true,
            noBackArrow: true,
            requiredAbilities: ['haveCreditNoteManagement'],
        },
        children: [
            {
                path: ':creditNoteId',
                name: 'credit-note-view',
                component: CreditNoteViewPage,
                props: true,
            },
        ],
    },
];

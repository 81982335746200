<template>
    <LayoutPage
        is-flyout
        :screen-name="screenName"
        class="checkout-additional-information"
        data-test="unloading-site-page"
    >
        <FlyoutHeader slot="flyoutHeader" />

        <div class="container-off-canvas-md my-6">
            <span class="font-copy-md mt-4">
                {{ getDescriptionLabel }}
            </span>

            <SfMessageBox v-if="isDisposal" class="mb-6 mt-4" type="warning">
                <span class="font-copy-md">
                    {{ $t(`pages.checkout.disposal.additionalInformation.warning`) }}
                </span>
            </SfMessageBox>
            <span class="font-copy-md-strong mb-2 mt-8">
                {{ $t(`pages.checkout.${disposalDomain}additionalInformation.selectMapHeader`) }}
            </span>
            <LocationPicker
                v-model="unloadingSiteLocation"
                :screen-name="unloadingSiteLocationPickerScreenName"
                :title="$t(`pages.checkout.${disposalDomain}additionalInformation.mapTitle`)"
                data-test="unloading-site-location"
                enable-reset
                map-type="satellite"
                @resetMap="resetUnloadingSiteLocation"
            >
                <template #default="{ editLocation, showLocation }">
                    <Card no-padding spaceless>
                        <div class="checkout-additional-information__tile-button">
                            <div
                                v-if="locationIsConstructionSite"
                                slot="left"
                                class="checkout-additional-information__tile-button-placeholder"
                                @click="editLocation"
                            >
                                <Words small centered>{{
                                    $t(`pages.checkout.${disposalDomain}additionalInformation.mapPlaceholder`)
                                }}</Words>
                            </div>
                            <div v-else class="checkout-additional-information__location-thumbnail">
                                <Thumbnail
                                    :img-src="staticMap"
                                    :modal-action="showLocation"
                                    :inline="false"
                                    class="checkout-additional-information__tile-thumbnail"
                                    modal
                                />
                                <FlagIcon
                                    :height="52"
                                    :width="52"
                                    class="checkout-additional-information__location-flag"
                                />
                            </div>

                            <BaseButton arrow-right place-left class="ml-4" @click="editLocation">
                                {{
                                    locationIsConstructionSite
                                        ? $t(`pages.checkout.${disposalDomain}additionalInformation.mapButtonText`)
                                        : $t(
                                              `pages.checkout.${disposalDomain}additionalInformation.mapButtonTextChange`
                                          )
                                }}
                            </BaseButton>

                            <BaseButton
                                v-if="!locationIsConstructionSite"
                                slot="right"
                                @click="resetUnloadingSiteLocation"
                            >
                                <DeleteIcon />
                            </BaseButton>
                        </div>
                    </Card>
                </template>
            </LocationPicker>

            <span class="font-copy-md-strong mb-2 mt-8">
                {{ $t(`pages.checkout.${disposalDomain}additionalInformation.selectPhotoHeader`) }}
            </span>
            <Card no-padding spaceless>
                <div class="checkout-additional-information__tile-button">
                    <Thumbnail
                        v-if="photo"
                        slot="left"
                        :src="photo.url"
                        :inline="false"
                        class="checkout-additional-information__tile-thumbnail"
                        modal
                    />
                    <div
                        v-else
                        slot="left"
                        class="checkout-additional-information__tile-button-placeholder"
                        @click="handleUploadImage"
                    >
                        <Words small centered>{{ $t('pages.checkout.additionalInformation.photoPlaceholder') }}</Words>
                    </div>

                    <BaseButton
                        arrow-right
                        place-left
                        class="checkout-additional-information__tile-button-text"
                        data-test="unloading-site-upload-photo-button"
                        @click="handleUploadImage"
                    >
                        {{
                            photo
                                ? $t('pages.checkout.additionalInformation.photoButtonTextChange')
                                : $t('pages.checkout.additionalInformation.photoButtonText')
                        }}
                    </BaseButton>

                    <BaseButton v-if="photo" slot="right" @click.prevent.stop="deleteUploadedImage">
                        <DeleteIcon />
                    </BaseButton>
                </div>
            </Card>

            <SfValidationMessage :text="fileError" type="error">
                <div class="checkout-additional-information__tile-button-hidden-img-uploader">
                    <ImageUpload
                        ref="imageUploader"
                        :disable-upload="true"
                        @error="handleUploadError"
                        @input="handleFileSelect"
                    />
                </div>
            </SfValidationMessage>

            <span class="font-copy-md-strong mb-2 mt-8">
                {{ $t(`pages.checkout.${disposalDomain}additionalInformation.hintHeader`) }}
            </span>
            <TextField
                v-model="description"
                :label="$t('pages.checkout.additionalInformation.descriptionPlaceholder')"
                :has-error="hasErrors('description')"
                :error="getError('description')"
                :rows="4"
                type="textarea"
            />
        </div>

        <ButtonGroup slot="sticky">
            <BaseButton
                :disabled="isPending"
                data-test="unloading-site-confirm-button"
                primary
                type="button"
                @click="handleSubmission"
            >
                {{
                    isCustom
                        ? $t('pages.checkout.additionalInformation.customButtonLabel')
                        : $t('pages.checkout.additionalInformation.buttonLabel')
                }}
                <ArrowRightIcon slot="right" class="icon--inline" />
            </BaseButton>
        </ButtonGroup>
    </LayoutPage>
</template>

<script>
import { getCurrentInstance, watch } from 'vue';
import { useImageUpload } from '@/services/useClientFile';

import { getStaticMapUrl } from '@/services/GoogleMaps';
import { mapState, mapGetters } from 'vuex';
import { locationsEqual } from '@/services/utils/map';
import { maxLength } from '@/services/validation/rules';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import Toaster from '@/services/Toaster';
import validate from '@/services/validation/mixin';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Card from '@/components/Layout/Card';
import LayoutPage from '@/components/Layout/Page.v2';
import FlyoutHeader from './components/Header';
import ImageUpload from '@/components/Form/ImageUpload';
import LocationPicker from './components/LocationPicker';
import TextField from '@/components/Form/TextField.v2';
import Thumbnail from '@/components/Thumbnail';
import Words from '@/components/Typography/Words';
import { SfValidationMessage, SfMessageBox } from '@schuettflix/vue-components';

import ArrowRightIcon from '@/assets/icons/micro/arrow.svg';
import DeleteIcon from '@/assets/icons/regular/garbage.svg';
import FlagIcon from '@/assets/icons/flag-centered.svg';
import QuoteService from '@/services/QuoteService';
import { trackCheckoutEvent } from './trackCheckoutEvent';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';

export default {
    name: 'AdditionalInformationPage',
    components: {
        BaseButton,
        ButtonGroup,
        Card,
        LayoutPage,
        FlyoutHeader,
        ImageUpload,
        LocationPicker,
        TextField,
        Thumbnail,
        Words,

        ArrowRightIcon,
        DeleteIcon,
        FlagIcon,

        SfMessageBox,
        SfValidationMessage,
    },

    mixins: [eventHubMixin, validate],

    setup() {
        const { uploadImage, onEverySuccess, onEveryError, progress, uploadedImageData } = useImageUpload();

        const instance = getCurrentInstance().proxy;

        onEverySuccess(() => {
            instance.$eventHub.$emit('imageEditorClose');
        });

        onEveryError(error => {
            instance.$eventHub.$emit('imageEditorClose');
            Toaster.error(instance.$t(error));
        });

        watch(progress, value => instance.$eventHub.$emit('imageEditorUploadProgress', value));

        return {
            screenName: useOrderScreenName('unloadingdescription'),
            unloadingSiteLocationPickerScreenName: useOrderScreenName('unloadingdescription-map'),
            uploadImage,
            photo: uploadedImageData,
        };
    },

    data() {
        return {
            description: null,
            fileError: null,
            isPending: false,
            unloadingSiteLocation: null,
            validationRules: {
                description: [maxLength(1000)],
            },
        };
    },

    computed: {
        ...mapState('basket', {
            selectedConstructionSite: 'constructionSite',
            isCustom: 'isCustom',
            isDisposal: 'isDisposal',
            quote: 'quote',
        }),
        ...mapGetters('basket', ['isDisposal']),

        disposalDomain() {
            return this.isDisposal ? 'disposal.' : '';
        },

        getDescriptionLabel() {
            if (this.disposalDomain === 'disposal.') {
                return this.$t('pages.checkout.disposal.additionalInformation.descriptionLabelLoading');
            } else {
                return this.$t('pages.checkout.additionalInformation.descriptionLabel');
            }
        },
        locationIsConstructionSite() {
            if (!this.selectedConstructionSite) return false;

            return locationsEqual(this.selectedConstructionSite.location, this.unloadingSiteLocation);
        },

        staticMap() {
            if (!this.unloadingSiteLocation) return null;
            return getStaticMapUrl(this.unloadingSiteLocation, 16, 200, 200, 'satellite');
        },
    },

    created() {
        this.resetUnloadingSiteLocation();
        this.loadSavedInformation();

        this.subscribe('image-editor-upload', file => {
            this.uploadImage({
                file,
                meta: {
                    productUseCase: 'location_image',
                },
            });
        });

        this.subscribe('imageEditorClosed', () => {
            this.$refs.imageUploader.resetInput();
        });
    },

    methods: {
        resetUnloadingSiteLocation() {
            let location = null;

            if (this.selectedConstructionSite) {
                location = { ...this.selectedConstructionSite.location };
            }
            this.unloadingSiteLocation = location;
        },

        loadSavedInformation() {
            const additionalInformation = this.$store.state.basket.additionalInformation;
            if (additionalInformation !== null) {
                const { description, photo, unloadingSiteLocation, loadingSiteLocation } = additionalInformation;
                this.description = description || null;
                this.photo = photo || null;
                this.unloadingSiteLocation = unloadingSiteLocation || loadingSiteLocation || null;
            }
        },

        async handleSubmission() {
            if (!this.isValid()) {
                return;
            }

            if (this.isPending) return;

            this.isPending = true;

            const { description, photo, unloadingSiteLocation } = this;

            const payload = {
                description,
                photo,
                ...(this.isDisposal ? { loadingSiteLocation: unloadingSiteLocation } : { unloadingSiteLocation }),
            };
            this.$store.commit('basket/setAdditionalInformation', payload);

            try {
                await QuoteService.saveQuote();
            } catch (err) {
                if (err.code) {
                    this.$store.commit('basket/setServerError', err);
                } else {
                    this.$logger().error(err);
                }
            }

            trackCheckoutEvent('additionalInfo', 'entered', {
                description: !!description,
                photo: !!photo,
                unloadingSiteLocation:
                    unloadingSiteLocation.lat !== this.selectedConstructionSite.location.lat ||
                    unloadingSiteLocation.lng !== this.selectedConstructionSite.location.lng,
            });

            this.isPending = false;
            this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(() => {});
        },

        handleFileSelect(file) {
            if (!file) return;
            this.$eventHub.$emit('image-editor-open', file);
        },

        handleUploadImage() {
            this.$refs.imageUploader.triggerFileSelect();
            this.fileError = null;
        },

        deleteUploadedImage() {
            this.photo = null;
        },

        handleUploadError(error) {
            this.fileError = error;
        },
    },
};
</script>

<style lang="scss">
.checkout-additional-information {
    background-color: $color-lightMediumGrey;
}

.checkout-additional-information__tile-button {
    position: relative;

    display: grid;
    grid-template-columns: 74px 1fr 30px;
    align-items: center;
}

.checkout-additional-information__tile-button-text {
    margin-left: 20px;
}

.checkout-additional-information__tile-button-wrapper {
    display: flex;
    align-items: center;
}

.checkout-additional-information__tile-button-placeholder {
    width: 75px;
    height: 75px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $color-lightGrey;
    border: 1px dashed rgba(0, 0, 0, 0.2);
}

.checkout-additional-information__tile-thumbnail {
    width: 75px;
    height: 75px;
}

.checkout-additional-information__tile-button-hidden-img-uploader {
    height: 0px;
    overflow: hidden;
}

.checkout-additional-information__tile-button-square-img {
    width: 75px;
    height: 75px;
    margin-right: 25px;
}

.checkout-additional-information__tile-empty {
    width: 100%;
    height: 70px;
}

.checkout-additional-information__location-thumbnail {
    position: relative;
}

.checkout-additional-information__location-flag {
    position: absolute;
    top: 12px;
    left: 12px;
    pointer-events: none;
}
</style>

<template>
    <div>
        <ModalBox
            v-if="isActionAvailable"
            :active="isActive"
            :headline="$t('pages.order.action.close.headline')"
            @closed="reset()"
        >
            <Words>
                {{ $t('pages.order.action.close.message') }}
            </Words>

            <ButtonGroup slot="actions">
                <Button place-left primary arrow-left light @click="reset()">
                    {{ $t('pages.order.action.close.actions.cancel') }}
                </Button>

                <Button :is-loading="isPending" primary @click="closeOrder()">
                    {{ $t('pages.order.action.close.actions.confirm') }}
                </Button>
            </ButtonGroup>
        </ModalBox>
    </div>
</template>

<script>
import OrderApi from '@/services/Api/Order';
import PubSubEvent from '@/services/PubSub/PubSubEvent';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { ACTIONS } from '@/constants/actions';
import { EVENT_ORDER_CLOSED, EVENT_ORDER_UPDATED } from '@/constants/events';
import { mapGetters } from 'vuex';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ModalBox from '@/components/Modal/ModalBox';
import Toaster from '@/services/Toaster';
import Words from '@/components/Typography/Words';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';

export default {
    name: 'OrderCloseAction',
    components: {
        Button,
        ButtonGroup,
        ModalBox,
        Words,
    },
    mixins: [eventHubMixin],
    data() {
        return {
            isPending: false,
        };
    },
    computed: {
        ...mapGetters('globalActions', ['subject', 'action']),

        isActive() {
            return this.action === ACTIONS.ORDER_CLOSE;
        },

        /**
         * Should this action be available based on the current conditions
         */
        isActionAvailable() {
            if (!this.subject || !this.isActive) return false;

            let canBeClosed = true;

            this.subject.lineItemGroups.forEach(lineItemGroup => {
                lineItemGroup.lineItems.forEach(lineItem => {
                    if (!['failed', 'delivered'].includes(lineItem.transportStatus)) {
                        canBeClosed = false;
                    }
                });
            });

            return canBeClosed;
        },
    },
    watch: {
        isActive() {
            if (this.isActive && !this.isActionAvailable) {
                Toaster.info(this.$t('pages.order.action.close.openTransportsAvailableMessage'));
                this.reset();
            }
        },
    },
    methods: {
        reset() {
            this.$store.dispatch('globalActions/reset', ACTIONS.ORDER_CLOSE);
        },

        async closeOrder() {
            if (this.isPending) return;
            this.isPending = true;

            try {
                await OrderApi.closeOrder(this.subject.id);
                Toaster.success(this.$t('pages.order.action.close.success', { number: this.subject.number }));
                this.$eventHub.$emit(EVENT_ORDER_UPDATED, new PubSubEvent(this.subject));
                this.$eventHub.$emit(EVENT_ORDER_CLOSED, new PubSubEvent(this.subject));
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(this.$t(err.message));
            }

            AnalyticsService.trackClick('orderDetailView', 'closeOrder', { orderId: this.subject.id });

            this.isPending = false;
            this.reset();
        },
    },
};
</script>

<template>
    <LayoutPage class="transport-hub">
        <template slot="pageTitle">{{ $t('pages.transportHub.title.platform') }}</template>

        <HeaderBar slot="mobileHeader">
            <div slot="headline">{{ $t(`pages.transportHub.title.platform`) }}</div>

            <template slot="right">
                <HeaderBarItem v-if="!$root.isDesktop">
                    <FilterBox
                        v-model="transportListing.filter"
                        no-padding
                        :default-filter="transportListing.defaultFilter"
                        :endpoint="transportListing.endpoint"
                        :forced-filter="transportListing.restriction"
                        :ignore-in-count="ignoreInCount"
                        @save="transportListing.refreshList(false, true, true)"
                    >
                        <template #default="{ filter: filterFromSlot, updateFilter }">
                            <TransportFilterSet
                                class="container-deprecated transport-hub__flyout-filters"
                                :filter-scope="{ filter: filterFromSlot }"
                                :endpoint="transportListing.endpoint"
                                :whitelisted-fields="whitelistedFields"
                                @updateFilter="updateFilter"
                            />
                        </template>
                    </FilterBox>
                </HeaderBarItem>

                <HeaderBarItem v-if="!$root.isDesktop">
                    <FilterSort
                        :sort-by="transportListing.filter.sortBy"
                        :sort-direction="transportListing.filter.sortDirection"
                        :sort-options="transportListing.sortOptions"
                        :option-label-renderer="value => $t(`pages.transportHub.sortings.${value}`)"
                        @sort-by-update="transportListing.filter.sortBy = $event"
                        @sort-direction-update="transportListing.filter.sortDirection = $event"
                        @updated-sorting="transportListing.refreshList(false, true, true)"
                    />
                </HeaderBarItem>
            </template>
        </HeaderBar>

        <RoundedTabNavigation
            :value="transportListing.activeRestriction"
            :tabs="transportListing.restrictionsLabelSet"
            :full-width-tabs="!$root.isDesktop"
            @input="transportListing.selectRestriction($event)"
        />

        <FilterBox
            v-model="transportListing.filter"
            :default-filter="transportListing.defaultFilter"
            :forced-filter="transportListing.restriction"
            :endpoint="transportListing.endpoint"
            inline-mode
            class="transport-hub__inline-filter"
            @update="transportListing.refreshList(false, true, true)"
        >
            <template slot-scope="filterScope">
                <AdvancedPlatformFilterSet
                    :filter-scope="filterScope"
                    :endpoint="transportListing.endpoint"
                    :forced-filter="transportListing.restriction"
                    :whitelisted-fields="whitelistedAdvancedFilterFields"
                />

                <FilterSortPagination
                    :result="transportListing.result"
                    :filter="filterScope.filter"
                    :sort-options="transportListing.sortOptions"
                    :option-label-renderer="value => $t(`pages.transportHub.sortings.${value}`)"
                    :hide-sort="!$root.isDesktop"
                    show-refresh
                    :loading="transportListing.isLoading"
                    @refresh="transportListing.refreshList(false, true, true)"
                    @pageNumberUpdated="transportListing.updatePageNumber($event)"
                >
                    <FilterBox
                        v-if="$root.isDesktop"
                        v-model="transportListing.filter"
                        no-padding
                        :default-filter="transportListing.defaultFilter"
                        :forced-filter="transportListing.restriction"
                        :endpoint="transportListing.endpoint"
                        :ignore-in-count="ignoreInCount"
                        :button-label="$t('pages.transportHub.filter.moreFilterButtonLabel')"
                        @save="transportListing.refreshList(false, true, true)"
                    >
                        <template #default="{ filter: filterFromSlot, updateFilter }">
                            <TransportFilterSet
                                class="container-deprecated transport-hub__flyout-filters"
                                :filter-scope="{ filter: filterFromSlot }"
                                :endpoint="transportListing.endpoint"
                                :whitelisted-fields="whitelistedFields"
                                @updateFilter="updateFilter"
                            />
                        </template>
                    </FilterBox>
                </FilterSortPagination>
            </template>
        </FilterBox>

        <transition name="fade" mode="out-in">
            <div v-if="transportListing.isLoading" key="transportLoadingIndicatorBlocks">
                <TransportListLoadingBlock
                    v-for="(item, i) in Array(transportListing.defaultFilter.perPage)"
                    :key="i"
                    :class="{
                        'mt-4': i > 0,
                    }"
                />
            </div>
            <div v-else-if="transportListing.result && transportListing.result.count > 0" key="transportBlocks">
                <Card
                    v-for="transport in transportListing.result.items"
                    :key="transport.id"
                    clickable
                    @click="selectTransport(transport.id)"
                >
                    <TransportListBlock
                        context="platform"
                        :transport="transport"
                        :is-mobile="!$root.isDesktop"
                        @address-item="selectTransport(transport.id, $event)"
                    />
                </Card>

                <Card spaceless class="transport-hub__bottom-pagination">
                    <Pagination
                        align-right
                        :result="transportListing.result"
                        @pageNumberUpdated="transportListing.updatePageNumber($event)"
                    />
                </Card>
            </div>
            <Hint v-else-if="transportListing.result && transportListing.result.count === 0" center transparent>
                {{ $t('pages.transportHub.noResults') }}
            </Hint>
        </transition>

        <Flyout route="transport-view" size="small" no-header />
    </LayoutPage>
</template>

<script>
import TransportListApi from '@/services/Api/TransportList';
import { ensureJSTimestamp, ensurePHPTimestamp, getFormattedDuration } from '@/services/utils/date';
import { transportProgress, transportStatus } from '@/services/utils/transport';
import { revertLocalizedValue } from '@/services/utils/localization';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { PLATFORM_UNPLAN_TRANSPORT } from '@/services/TransportActions';

import AdvancedPlatformFilterSet from '@/pages/TransportHub/components/AdvancedPlatformFilterSet';
import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Card from '@/components/Layout/Card';
import FilterBox from '@/components/Filter/FilterBox';
import FilterSort from '@/components/Filter/FilterSort';
import FilterSortPagination from '@/components/Filter/FilterSortPagination';
import Flyout from '@/components/Layout/Flyout';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Hint from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import ModalBox from '@/components/Modal/ModalBox';
import Pagination from '@/components/Pagination';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation';
import TransportFilterSet from '@/pages/Transport/components/TransportFilterSet';
import TransportListBlock from '@/components/Transport/TransportListBlock.vue';
import TransportListLoadingBlock from '@/components/Transport/TransportListLoadingBlock.vue';
import Words from '@/components/Typography/Words';

import FilterableListing from '@/services/FilterableListing/FilterableListing';

const isLateStatus = transportStatus.filter(
    item => !['delivered', 'failed', 'confirmation_pending', 'cancelled'].includes(item)
);

const UPDATE_INTERVAL = 5000;

export default {
    name: 'FilterableListingPlaygroundPage',
    components: {
        AdvancedPlatformFilterSet,
        Button,
        ButtonGroup,
        Card,
        FilterBox,
        FilterSort,
        FilterSortPagination,
        Flyout,
        HeaderBar,
        HeaderBarItem,
        Hint,
        LayoutPage,
        ModalBox,
        Pagination,
        RoundedTabNavigation,
        TransportFilterSet,
        TransportListBlock,
        TransportListLoadingBlock,
        Words,
    },
    mixins: [persistentFiltersMixin, eventHubMixin],
    data() {
        const transportListing = new FilterableListing({
            namespace: 'TRANSPORT',
            endpoint: new TransportListApi('platform'),
            defaultFilter: {
                page: 1,
                perPage: 25,
                sortBy: 'created',
                sortDirection: 'desc',
            },
            defaultRestriction: {
                seedSortBy: 'status',
            },
            sortOptions: [
                'urgency',
                'created',
                'totalDistance',
                'vehiclePayload',
                'deliveryTime',
                'preferredTransport',
                'rating',
                'transportNumber',
                'transportPurchasePrice',
                'transportRetailPrice',
                'shippingWindowStart',
                'shippingWindowEnd',
            ],
            // intervalPolling: 10000,
            // intervalRestrictionCount: 10000,
            restrictionLabelRenderer: (restrictionKey, count) => {
                return this.$t(`pages.transportHub.pills.delivery.${restrictionKey}`) + (count ? ` (${count})` : '');
            },
        });

        // Add presets
        transportListing
            .addRestriction('open', {
                status: ['new'],
            })
            .addRestriction('inProgress', {
                status: [
                    'assigned',
                    'planned',
                    'started',
                    'checked_in',
                    'checked_in_loading',
                    'loaded',
                    'ready_to_deliver',
                    'in_transit',
                    'destination_waiting',
                ],
            })
            .addRestriction('isLate', {
                isLate: true,
                status: isLateStatus,
            })
            .addRestriction('closed', {
                status: ['delivered', 'cancelled'],
            })
            .addRestriction('failed', {
                status: ['failed'],
            })
            .addRestriction('all', {
                status: [],
            });

        return {
            transportListing,
        };
    },
    computed: {
        ignoreInCount() {
            return [
                'organizationType',
                'organization',
                'user',
                'preferred',
                'startShippingWindow',
                'endShippingWindow',
                'transportNumber',
                'status',
                'isLate',
                'type',
                'seedSortBy',

                'transportNumber',
                'type',
                'status',
                'startShippingWindow',
                'endShippingWindow',
                'originGeo',
                'destinationGeo',
            ];
        },

        whitelistedAdvancedFilterFields() {
            return [
                'transportNumber',
                'type',
                'status',
                'startShippingWindow',
                'endShippingWindow',
                'originGeo',
                'destinationGeo',
            ];
        },

        whitelistedFields() {
            return [
                'clientOrganization',
                'carrierOrganization',
                'supplierOrganization',
                'vehicleClass',
                'creditNoteCarrierStatus',
                'creditNoteSupplierStatus',
            ];
        },

        predefinedFilterTabs() {
            const tabs = {};

            Object.keys(this.transportListing.restrictions).forEach(key => {
                let suffix = '';

                if (this.transportListing.restrictionCounts[key]) {
                    suffix = ` (${this.transportListing.restrictionCounts[key]})`;
                }

                tabs[key] = this.$t(`pages.transportHub.pills.delivery.${key}`) + suffix;
            });

            return tabs;
        },
    },
    watch: {
        $route() {
            this.transportListing.onRouteChanged();
        },
    },
    created() {
        this.transportListing.pairComponent(this);
        this.transportListing.refreshList(true);

        // this.$interval(this.checkHighlightState.bind(this), UPDATE_INTERVAL);

        this.subscribe('transport-returned', () => {
            this.transportListing.refreshList();
        });

        this.subscribeMutation('transportActions/updateTransport', () => {
            this.transportListing.refreshList();
        });
    },
    methods: {
        ensurePHPTimestamp,
        ensureJSTimestamp,
        getFormattedDuration,
        revertLocalizedValue,
        transportProgress,

        selectTransport(transportId, preselectedNavigation = null) {
            const route = {
                name: `${this.$route.name}__transport-view`,
                params: { transportId: transportId },
                query: this.$route.query,
            };

            // preselectTab on transport view
            if (preselectedNavigation) {
                route.params.preselectedNavigation = preselectedNavigation;
            }

            this.$router.push(route).catch(() => {});
        },

        async openUnplanTransport(partialTransport) {
            const transport = await TransportListApi.getOneById(partialTransport.id);
            this.$store.dispatch('transportActions/set', {
                action: PLATFORM_UNPLAN_TRANSPORT,
                transport,
                context: 'platform',
            });
        },

        // checkHighlightState() {
        //     const now = new Date() / 1000;
        //
        //     this.$nextTick(() => {
        //         if (!this.transports) {
        //             return;
        //         }
        //
        //         this.transports.items.forEach(transport => {
        //             if (transport.preferredUntil) {
        //                 this.$set(transport, '__preferedRemainingSeconds', parseInt(transport.preferredUntil - now));
        //             }
        //         });
        //     });
        // },

        // showPreferredCarriers(transport) {
        //     if (this.$refs.preferredCarriersInfo) {
        //         this.selectedPreferredCarriers = transport.preferredCarriers;
        //         this.$refs.preferredCarriersInfo.$emit('open');
        //     }
        // },

        // hidePreferredCarriers() {
        //     setTimeout(() => {
        //         this.selectedPreferredCarriers = null;
        //     }, 300);
        // },
    },
};
</script>

<style lang="scss">
.transport-hub__advanced-filter-bar {
    .select-input {
        display: none;
    }
}

.transport-hub__flyout-filters {
    margin-top: 15px;
}

.transport-hub__inline-filter {
    background-color: $color-white;
    box-shadow: $boxShadow-bottomShort;
    margin-bottom: 30px;
}

.transport-hub__more-results-button {
    margin-bottom: 20px;
}

.transport-hub__planed-details {
    margin-left: 20px;
    display: inline-block;

    > * {
        margin-right: 10px;
    }
}

.transport-hub__highlight-icon--exclusive-info {
    margin-left: 10px;
    margin-bottom: 2px;
}

.transport-hub__adress-inline + .transport-hub__adress-inline {
    margin-top: 20px;
}

.transport-hub-platform__rating + .transport-hub-platform__rating {
    margin-top: 10px;
}

.transport-hub-platform__rating-text {
    margin-bottom: 5px;
}
</style>

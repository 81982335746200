<template>
    <div class="products-form">
        <Tile no-padding-bottom no-border>
            <GridRow :count="4" spacing="large" align="center">
                <div class="span-1">
                    <img
                        v-if="product.image && product.image.url"
                        :src="product.image && product.image.url.medium"
                        class="img-responsive"
                    />
                    <ProductPlaceHolder v-else class="img-responsive" />
                </div>

                <div class="span-3">
                    <Headline :level="3" style="margin-top: 0">
                        {{ revertLocalizedValue(product.productName) }}
                    </Headline>

                    <!-- commenting out the feature, just in case we decide to re-enable it again -->
                    <!-- <Words block spaced>
                        {{ revertLocalizedValue(product.description) }}
                    </Words> -->

                    <ul
                        v-if="product.categoryList && Object.keys(product.categoryList).length"
                        class="products-form__categories"
                    >
                        <li v-for="(categoryList, i) in product.categoryList" :key="`${product.id}-${i}`">
                            {{ implodeCategoryList(categoryList.items) }}
                        </li>
                    </ul>

                    <Words v-if="product.tags.length" block small muted class="products-form__tags">
                        {{ product.tags.map(item => revertLocalizedValue(item.text)).join(', ') }}
                    </Words>

                    <Words v-if="product.sku" small muted spaced-top-tiny>
                        {{ $t('pages.productManagement.headlines.sku') }}: {{ product.sku }}
                    </Words>

                    <div v-if="product.isActive">
                        <Words block bold green>
                            {{ $t('pages.products.productsForm.status.active') }}
                        </Words>
                    </div>
                    <div v-else>
                        <Words block bold muted> {{ $t('pages.products.productsForm.status.inactive') }}<br /> </Words>
                        <Words block muted>
                            {{ $t('pages.products.productsForm.disclaimer') }}
                        </Words>
                    </div>
                </div>
            </GridRow>

            <Hint v-if="product.isRC">
                <Words red bold>
                    {{ $t('pages.products.productsForm.RCHintLabel') }}
                </Words>
                <Words>
                    {{ $t('pages.products.productsForm.RCHint') }}
                </Words>
            </Hint>

            <Headline :level="6" class="products-form__headline">
                {{ $t('pages.products.productsForm.netPrice') }}
            </Headline>

            <GridRow :count="2" class="span-2" spacing="large">
                <TextField
                    v-model="supplierProductForm.price"
                    :label="$t('pages.products.productsForm.placeholder.priceNet')"
                    :has-error="hasErrors('supplierProductForm.price')"
                    :error="getError('supplierProductForm.price')"
                    step="0.001"
                    type="number"
                />

                <Words class="products-form__calculated-value">
                    {{
                        $t('pages.products.productsForm.pricePerCbm', {
                            price: $n(getCbmPrice(supplierProductForm.price, product.density), 'floatPrice'),
                        })
                    }}
                </Words>
            </GridRow>

            <Headline :level="6" class="products-form__headline">
                {{ $t('pages.products.productsForm.availableAmount') }}
            </Headline>

            <GridRow :count="2" class="span-2" spacing="large">
                <TextField
                    v-model="supplierProductForm.minQty"
                    :label="$t('pages.products.productsForm.placeholder.availableAmount')"
                    :has-error="hasErrors('supplierProductForm.minQty')"
                    :error="getError('supplierProductForm.minQty')"
                    step="1"
                    type="number"
                />

                <Words class="products-form__calculated-value">
                    {{
                        $t('pages.products.productsForm.availableCbm', {
                            amount: $n(getCbmAmount(supplierProductForm.minQty, product.density), 'float'),
                        })
                    }}
                </Words>
            </GridRow>

            <Headline :level="6" class="products-form__headline">
                {{ $t('pages.products.productsForm.availability.headline') }}
            </Headline>

            <GridRow :count="1" class="span-2" spacing="large">
                <ToggleSwitchField v-model="supplierProductForm.isAvailable">
                    {{
                        supplierProductForm.isAvailable
                            ? $t('pages.products.productsForm.availability.available')
                            : $t('pages.products.productsForm.availability.unavailable')
                    }}
                </ToggleSwitchField>
            </GridRow>
        </Tile>

        <ButtonGroup slot="bottom">
            <BaseButton :disabled="isPendingSave" primary light arrow-left @click="$emit('close')">
                {{ $t('pages.user.userForm.actions.cancel') }}
            </BaseButton>
            <BaseButton :disabled="isPendingSave" primary @click="save">
                <TickIcon slot="left" class="icon--inline" />
                {{ $t('pages.user.userForm.actions.save') }}
            </BaseButton>
        </ButtonGroup>
    </div>
</template>

<script>
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import SupplierProductApi from '@/services/Api/Supplier/Product';
import validate from '@/services/validation/mixin';
import { isRequired, isFloat, isNumber } from '@/services/validation/rules';
import { revertLocalizedValue } from '@/services/utils/localization';
import { convert_kg_to_cbm, convert_tonsPrice_to_cbmPrice } from '@/services/utils/units';

import Tile from '@/components/Layout/Tile';
import GridRow from '@/components/Layout/GridRow';
import Headline from '@/components/Typography/Headline';
import Hint from '@/components/Typography/Hint';
import Words from '@/components/Typography/Words';
import TextField from '@/components/Form/TextField.v2';
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';

import TickIcon from '@/assets/icons/micro/tick.svg';
import ProductPlaceHolder from '@/assets/product_placeholder.svg';

export default {
    name: 'ProductForm',
    components: {
        Tile,
        GridRow,
        Headline,
        Hint,
        Words,
        TextField,
        ToggleSwitchField,
        BaseButton,
        ButtonGroup,
        TickIcon,
        ProductPlaceHolder,
    },
    mixins: [validate],
    props: {
        product: {
            type: Object,
            required: true,
        },
        activeListType: {
            type: String,
            default: null,
        },
        factoryId: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            isPendingSave: false,
            supplierProductForm: null,
            validationRules: {
                'supplierProductForm.minQty': [isRequired(), isNumber()],
                'supplierProductForm.price': [isRequired(), isFloat()],
            },
        };
    },
    watch: {
        product() {
            this.updateProduct();
        },
    },
    created() {
        this.updateProduct();
    },
    methods: {
        revertLocalizedValue,

        updateProduct() {
            const supplierProductForm = {
                id: this.product.id,
                ...this.product.factoryData,
            };

            supplierProductForm.minQty = supplierProductForm.minQty ? supplierProductForm.minQty / 1000 : null;
            supplierProductForm.price = ((supplierProductForm.price || 0) * 1000).toFixed(2);

            this.supplierProductForm = supplierProductForm;
        },

        getCbmAmount(availableAmount, density) {
            return convert_kg_to_cbm(availableAmount * 1000, density);
        },

        getCbmPrice(pricePerTon, density) {
            return convert_tonsPrice_to_cbmPrice(density, pricePerTon);
        },

        implodeCategoryList(categoryList) {
            const list = categoryList.map(item => {
                return revertLocalizedValue(item.name);
            });

            return list.join(' › ');
        },

        async save() {
            if (this.isValid()) {
                this.isPendingSave = true;

                const data = _cloneDeep(this.supplierProductForm);
                if (data.minQty) {
                    data.minQty = data.minQty * 1000;
                }

                data.price = data.price / 1000;
                data.factoryId = this.factoryId;

                try {
                    const productNew = await SupplierProductApi.save(data);
                    this.$emit('updated', productNew);
                    this.$emit('close');
                } catch (err) {
                    this.$logger().error(err);
                    this.parseServerValidation(_get(err, 'response.data.errors', {}), {
                        'supplierProductForm.priceNet': 'priceNet',
                        'supplierProductForm.availableAmount': 'availableAmount',
                    });
                } finally {
                    this.isPendingSave = false;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped src="./ProductForm.scss" />

<template>
    <div :class="{ 'draggable--horizontal': horizontal }" class="draggable">
        <slot name="topLess" />
        <div v-if="$slots.top" ref="topContainer" :class="topClass" class="draggable__top">
            <slot name="top" />
        </div>
        <div
            ref="handle"
            :class="handleClass"
            class="draggable__handle"
            @touchstart="handleTouchstart"
            @touchmove="handleTouchmove"
            @touchend="handleTouchend"
            @click="handleToggle"
        >
            <slot />
        </div>
        <div v-if="$slots.bottom" ref="bottomContainer" :class="bottomClass" class="draggable__bottom">
            <slot name="bottom" />
        </div>
        <slot name="bottomLess" />
    </div>
</template>

<script>
import gsap from 'gsap';

const DIRECTION_UP = -1;
const DIRECTION_DOWN = 1;
const DEBUG_LOGGING = false;

const log = (...args) => {
    /* eslint-disable-next-line no-console */
    DEBUG_LOGGING && console.log(...args);
};

export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        animationSpeed: {
            type: Number,
            default: 1,
        },
        horizontal: {
            type: Boolean,
            default: false,
        },
        dragDirection: {
            type: Number,
            default: 0,
        },
        dragTolerance: {
            type: Number,
            default: 0.1,
        },
        handleClass: {
            type: String,
            default: null,
        },
        topClass: {
            type: String,
            default: null,
        },
        bottomClass: {
            type: String,
            default: null,
        },
        isSmall: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            lastY: null,
            direction: this.value ? 1 : -1,
            eventCount: 0,
            boundTop: 0,
            boundBottom: 0,

            pageKey: this.horizontal ? 'pageX' : 'pageY',
            possitionKey: this.horizontal ? 'left' : 'top',
            offsetKey: this.horizontal ? 'offsetLeft' : 'offsetTop',
            offsetSizeKey: this.horizontal ? 'offsetWidth' : 'offsetHeight',
        };
    },
    watch: {
        value(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.value ? this.open() : this.close();
            }
        },
    },
    mounted() {
        setTimeout(this.reset, 100);
    },
    methods: {
        handleToggle(e) {
            e.stopPropagation();
            if (this.disabled) return;
            !this.value ? this.open() : this.close();
        },

        handleTouchstart(e) {
            e.cancelable && e.preventDefault();
            if (this.disabled) return;
            this.handleStart(e.touches[0][this.pageKey], 'touchstart');
        },
        handleStart(pageY, type) {
            log(type, pageY);
            this.lastY = pageY;
            this.boundBottom = this.$el.parentNode[this.offsetSizeKey];
            this.eventCount = 0;
        },

        handleTouchend(e) {
            e.cancelable && e.preventDefault();
            if (this.disabled) return;
            this.handleEnd();
        },
        handleEnd() {
            log('end | Eventcount:', this.eventCount);
            this.boundBottom = this.$el.parentNode[this.offsetSizeKey];
            if (this.eventCount < 12) {
                log('JUST A CLICK');
                this.direction = this.value ? 1 : -1;
            }

            // fixed drag direction
            if (this.dragDirection !== 0) {
                const currentPosition = parseInt(this.$el.style[this.possitionKey]);
                const handleSize = this.getHandle()[this.offsetSizeKey];
                const parentHeight = this.$el.parentNode[this.offsetSizeKey];
                const dragTolerance = parentHeight * this.dragTolerance;

                if (this.dragDirection === DIRECTION_DOWN) {
                    // check if current position is inside drag tolenrance
                    const isAbleToFinish = currentPosition + handleSize > this.getBoundsBottom() - dragTolerance;
                    this.direction = isAbleToFinish ? DIRECTION_DOWN : DIRECTION_UP;
                    log('DRAG CLOSE', isAbleToFinish);
                } else if (this.dragDirection === DIRECTION_UP) {
                    const isAbleToFinish = currentPosition < dragTolerance;
                    this.direction = isAbleToFinish ? DIRECTION_UP : DIRECTION_DOWN;
                    log('DRAG OPEN', isAbleToFinish);
                }
            }

            this.direction === DIRECTION_UP ? this.open() : this.close();
        },
        reset() {
            this.boundBottom = this.$el.parentNode[this.offsetSizeKey];
            this.value ? this.open(true) : this.close(true);
        },

        handleTouchmove(e) {
            e.cancelable && e.preventDefault();
            if (this.disabled) return;
            this.handleMove(e.touches[0][this.pageKey]);
        },
        handleMove(pageY) {
            this.direction = this.lastY > pageY ? DIRECTION_UP : DIRECTION_DOWN;
            this.updatePosition(pageY);
            this.lastY = pageY;
            this.eventCount++;
        },

        getHandle() {
            // sometimes $refs are not bound on the right tick
            return this.$refs.handle ? this.$refs.handle : {};
        },
        updatePosition(pageY) {
            const handle = this.getHandle();
            const el = this.$el;
            const boundTop = this.getBoundsTop();
            const boundBottom = this.getBoundsBottom();

            const diff = Math.abs(this.lastY - pageY);
            const handleHeight = handle[this.offsetSizeKey];
            let top = el[this.offsetKey] + diff * this.direction;

            if (this.direction === DIRECTION_UP) {
                log(boundTop <= top, boundTop, top);
                if (boundTop >= top) {
                    top = boundTop;
                }
            } else if (this.direction === DIRECTION_DOWN) {
                if (boundBottom <= top + handleHeight) {
                    top = boundBottom - handleHeight;
                }
            }

            // emit current progress
            this.$emit('progress', (top * 100) / (this.$el.parentNode[this.offsetSizeKey] - handleHeight));

            el.style[this.possitionKey] = `${top}px`;
        },
        getBoundsTop() {
            let top = this.boundTop;

            if (this.$refs.bottomContainer) {
                top =
                    this.boundBottom -
                    (this.$refs.bottomContainer[this.offsetSizeKey] + this.getHandle()[this.offsetSizeKey]);
            }

            if (this.$refs.topContainer) {
                top = this.boundTop - this.getHandle()[this.offsetKey];
            }

            return top;
        },
        getBoundsBottom() {
            let bottom = this.boundBottom;

            if (this.$refs.topContainer) {
                bottom = this.getHandle()[this.offsetSizeKey];
            }

            return bottom;
        },

        open(instant = false) {
            const handleHeight = this.getHandle()[this.offsetSizeKey];
            let top = this.getBoundsTop();

            // Adding 4px to keep the orb inside the track
            top = top === 0 ? 4 : top;
            log('Finish OPEN', top);

            // skip animation
            if (instant) {
                this.$el.style[this.possitionKey] = `${top}px`;
                // emit current progress
                this.$emit('progress', (top * 100) / (this.$el.parentNode[this.offsetSizeKey] - handleHeight));
            } else {
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const me = this;
                const progress =
                    (parseFloat(this.$el.style[this.possitionKey]) * 100) /
                    (this.$el.parentNode[this.offsetSizeKey] - handleHeight);
                log('OPEN PROGRESS', progress, top);
                gsap.to(this.$el, this.getAnimationSpeed(this.$el.style[this.possitionKey], top), {
                    [this.possitionKey]: top,
                }).eventCallback('onUpdate', function () {
                    log('open', progress);
                    // eslint-disable-next-line vue/no-undef-properties
                    me.$emit('progress', (1 - this.progress()) * progress);
                });
            }

            this.$emit('input', true);
        },

        close(instant = false) {
            const handleHeight = this.getHandle()[this.offsetSizeKey];
            const top = this.getBoundsBottom() - handleHeight;
            log('Finish CLOSE', top, this.getBoundsBottom());

            // skip animation
            if (instant) {
                this.$el.style[this.possitionKey] = `${top}px`;
                // emit current progress
                this.$emit('progress', (top * 100) / (this.$el.parentNode[this.offsetSizeKey] - handleHeight));
            } else {
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const me = this;
                const progress =
                    (parseFloat(this.$el.style[this.possitionKey]) * 100) /
                    (this.$el.parentNode[this.offsetSizeKey] - handleHeight);
                log('CLOSE PROGRESS', progress, top);
                gsap.to(this.$el, this.getAnimationSpeed(this.$el.style[this.possitionKey], top), {
                    [this.possitionKey]: top,
                }).eventCallback('onUpdate', function () {
                    log('close', progress, (1 - this.progress()) * progress);
                    me.$emit('progress', 100 - (1 - this.progress()) * progress);
                });
            }

            this.$emit('input', false);
        },

        getAnimationSpeed(currentPosition, nextPosition) {
            const parentHeight = this.$el.parentNode[this.offsetSizeKey];
            const travelDistance = Math.abs(parseInt(currentPosition) - nextPosition);

            return (this.animationSpeed * travelDistance) / parentHeight;
        },
    },
};
</script>

<style>
.draggable {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.draggable--horizontal {
    left: 0;
    right: auto;
    top: 0;
    bottom: 0;
    display: flex;
    margin-left: -4px;
}

.draggable__handle {
    user-select: none;
}
</style>

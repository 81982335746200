<template>
    <article class="checkout-price-adjustments__vehicle-class card mt-6 first:mt-0">
        <header class="mb-8 flex items-start justify-between">
            <h3 class="font-copy-md-strong">{{ vehicleClass.count }} x {{ vehicleClass.info }}</h3>
            <span v-if="vehicleClass.info" class="font-copy-md">
                {{ $t('pages.checkout.priceAdjustments.transport.payload') }}: {{ vehicleClass.payload | tons }}
            </span>
        </header>
        <ul class="font-copy-md m-0 list-none p-0">
            <li>
                <PriceRow
                    :label="$t('general.purchasePrice.abbreviation')"
                    :price-per-weight="purchaseUnitPrice"
                    :price-per-transport="purchaseUnitPricePerTransport"
                    :fixed-price="fixedPrice"
                />
            </li>
            <li>
                <PriceRow
                    class="mt-2"
                    :label="$t('general.retailPrice.abbreviation')"
                    :price-per-weight="retailUnitPrice"
                    :price-per-transport="retailUnitPricePerTransport"
                    :fixed-price="fixedPrice"
                />
            </li>
        </ul>
    </article>
</template>

<script>
import PriceRow from '@/pages/Checkout/Disposal/Components/PriceRow';
import { mapState } from 'vuex';

export default {
    name: 'TransportItem',
    components: {
        PriceRow,
    },
    props: {
        vehicleClass: {
            type: Object,
            required: true,
        },
        fixedPrice: {
            type: Boolean,
            default: false,
        },
        transportPriceUnit: {
            type: String,
            required: true,
            validator: v => ['ton', 'transport'].includes(v),
        },
    },
    computed: {
        ...mapState('projectPosition', {
            requestCollection: 'requestCollection',
        }),
        purchaseUnitPrice() {
            return this.vehicleClass.purchaseUnitPrice.getPerTon();
        },
        retailUnitPrice() {
            return this.vehicleClass.retailUnitPrice.getPerTon();
        },
        purchaseUnitPricePerTransport() {
            return this.vehicleClass.purchaseUnitPrice.getPerTransport();
        },
        retailUnitPricePerTransport() {
            return this.vehicleClass.retailUnitPrice.getPerTransport();
        },
    },
};
</script>

<style lang="scss"></style>

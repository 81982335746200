export const BASKET_TYPE_PROJECT = {
    BASKET_TYPE_PROJECT_DELIVERY: 'project-delivery',
    BASKET_TYPE_PROJECT_POSITION_DELIVERY: 'project-position-delivery',
    BASKET_TYPE_PROJECT_SHIPMENT: 'project-shipment',
    BASKET_TYPE_PROJECT_POSITION_SHIPMENT: 'project-position-shipment',
    BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS: 'dangerousWaste',
    BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS: 'waste',
    BASKET_TYPE_PROJECT_POSITION_DISPOSAL_HAZARDOUS: 'project-position-dangerousWaste',
    BASKET_TYPE_PROJECT_POSITION_DISPOSAL_NON_HAZARDOUS: 'project-position-waste',
    BASKET_TYPE_GENERIC_PROJECT_POSITION: 'generic-project-position',
};

import ProjectPositionView from '@/models/ProjectPositionView';
import ProjectView from '@/models/ProjectView';
import { differenceInCalendarDays } from 'date-fns';
import { ensureJSTimestamp, formatDateRange } from '@/services/utils/date';

const PROJECT_STATUS_CLOSED = 'closed';
const PROJECT_STATUS_OPEN = 'open';
const PROJECT_VALIDITY_THRESHOLD = 7;

/** Class representing project view model */
export default class ProjectFacadeView {
    /**
     * Create a view model
     */
    constructor() {
        this._id = null;
        this._number = null;
        this._client = null;
        this._name = null;
        this._status = null;
        this._costCenter = null;
        this._validFrom = null;
        this._validTo = null;
        this._supervisor = null;
        this._createdBy = null;
        this._responsibleEmployee = null;
        this._showClientData = true;
        this._openPositionCount = null;
        this._closedPositionCount = null;
        this._positions = null;
        this._project = new ProjectView();
    }

    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     */
    static create(data) {
        if (!data) return null;

        const view = new ProjectFacadeView();
        this._project = ProjectView.create(data);

        view._id = this._project.id;
        view._number = this._project.number;
        view._name = this._project.name;
        view._status = this._project.status;
        view._costCenter = this._project.costCenter;
        view._validFrom = this._project.validFrom;
        view._validTo = this._project.validTo;
        view._client = this._project.client;
        view._createdBy = this._project.createdBy;
        view._supervisor = this._project.supervisor;
        view._constructionProjectId = this._project.constructionProjectId;
        view._responsibleEmployee = this._project.responsibleEmployee;

        view.openPositionCount = data.openPositionCount;
        view.closedPositionCount = data.closedPositionCount;

        if (data.positions) {
            view.positions = data.positions?.map(position => {
                return ProjectPositionView.create({
                    ...position,
                    project: position.project ?? data,
                });
            });
        }

        return view;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        const positions = {
            openPositionCount: this.openPositionCount,
            closedPositionCount: this.closedPositionCount,
            positions: this.positions?.unfold(),
        };

        return {
            ...positions,
            ...this._project?.unfold(),
        };
    }

    /**
     * Clone the current view
     * @return {ProjectFacadeView}
     */
    clone() {
        return ProjectFacadeView.create(this.unfold());
    }

    /**
     * Get id
     * @return {number}
     */
    get id() {
        return this._id;
    }

    /**
     * Set id
     * @param {number} value
     */
    set id(value) {
        this._id = value ?? null;
    }

    /**
     * Get project
     * @return {number}
     */
    get project() {
        return this._project;
    }

    /**
     * Set id
     * @param {number} value
     */
    set project(instance) {
        this._project = instance;
    }

    /**
     * Get number
     * @return {string}
     */
    get number() {
        return this._number;
    }

    /**
     * Set number
     * @param {string} value
     */
    set number(value) {
        this._number = value ?? null;
    }

    /**
     * Get client
     * @return {OrganizationView}
     */
    get client() {
        return this._client;
    }

    /**
     * Set client
     * @param {OrganizationView} value
     */
    set client(value) {
        this._client = value ?? null;
    }

    /**
     * Get name
     * @return {string}
     */
    get name() {
        return this._name;
    }

    /**
     * Set name
     * @param {string} value
     */
    set name(value) {
        this._name = value ?? null;
    }

    /**
     * Get status
     * @return {string}
     */
    get status() {
        return this._status;
    }

    /**
     * Set status
     * @param {string} value
     */
    set status(value) {
        this._status = value ?? null;
    }

    /**
     * Get cost center
     * @returns {string}
     */
    get costCenter() {
        return this._costCenter;
    }

    /**
     * Set cost center
     * @param value
     */
    set costCenter(value) {
        this._costCenter = value ?? null;
    }

    /**
     * Get construction project id
     * @returns {string}
     */
    get constructionProjectId() {
        return this._constructionProjectId;
    }

    /**
     * Set construction project id
     * @param value
     */
    set constructionProjectId(value) {
        this._constructionProjectId = value ?? null;
    }

    /**
     * Get openPositionCount
     * @return {number}
     */
    get openPositionCount() {
        return this._openPositionCount;
    }

    /**
     * Set openPositionCount
     * @param {number} value
     */
    set openPositionCount(value) {
        this._openPositionCount = value ?? null;
    }

    /**
     * Get closedPositionCount
     * @return {number}
     */
    get closedPositionCount() {
        return this._closedPositionCount;
    }

    /**
     * Set closedPositionCount
     * @param {number} value
     */
    set closedPositionCount(value) {
        this._closedPositionCount = value ?? null;
    }

    /**
     * Get validFrom timestamp
     * @return {number}
     */
    get validFrom() {
        return this._validFrom;
    }

    /**
     * Set validFrom timestamp
     * @param {number} value
     */
    set validFrom(value) {
        this._validFrom = ensureJSTimestamp(value);
    }

    /**
     * Get validTo timestamp
     * @return {number}
     */
    get validTo() {
        return this._validTo;
    }

    /**
     * Set validTo timestamp
     * @param {number} value
     */
    set validTo(value) {
        this._validTo = ensureJSTimestamp(value);
    }

    /**
     * Get supervisor
     * @returns {UserView}
     */
    get supervisor() {
        return this._supervisor;
    }

    /**
     * Set supervisor
     * @param {UserView} value
     */
    set supervisor(value) {
        this._supervisor = value ?? null;
    }

    /**
     * Get responsibleEmployee
     * @returns {UserView}
     */
    get responsibleEmployee() {
        return this._responsibleEmployee;
    }

    /**
     * Set responsibleEmployee
     * @param {UserView} value
     */
    set responsibleEmployee(value) {
        this._responsibleEmployee = value ?? null;
    }

    /**
     * Get positions
     * @returns {[ProjectPositionView]}
     */
    get positions() {
        return this._positions;
    }

    /**
     * Set positions
     * @param {[ProjectPositionView]} value
     */
    set positions(value) {
        this._positions = value ?? [];
    }

    /**
     * Get show client data flag
     * @return {boolean}
     */
    get showClientData() {
        return this.client && this._howClientData;
    }

    /**
     * Set show client data flag
     * @param {boolean} value
     */
    set showClientData(value) {
        this._howClientData = value ?? null;
    }

    /**
     * Get created by
     * @returns {UserView}
     */
    get createdBy() {
        return this._createdBy;
    }

    /**
     * Set created by
     * @param {UserView} value
     */
    set createdBy(value) {
        this._createdBy = value ?? null;
    }

    /* ---------- */

    /**
     * Get formatted validity range
     * @return {string}
     */
    get validityRange() {
        return formatDateRange(this.validFrom, this.validTo);
    }

    /**
     * Get remaining validity in days
     * @return {number}
     */
    get remainingValidityInDays() {
        return differenceInCalendarDays(this.validTo, new Date()) + 1;
    }

    /**
     * Get open status
     * @return {boolean}
     */
    get isOpen() {
        return this.status === PROJECT_STATUS_OPEN;
    }

    /**
     * Get closed status
     * @return {boolean}
     */
    get isClosed() {
        return this.status === PROJECT_STATUS_CLOSED;
    }

    /**
     * Check if the remaining validity is below threshold
     * @return {boolean}
     */
    get isRemainingValidityBelowThreshold() {
        return this.remainingValidityInDays <= PROJECT_VALIDITY_THRESHOLD;
    }

    /**
     * Check if the current date is inside the validity range
     * @returns {boolean}
     */
    get isInsideValidityRange() {
        const now = new Date();
        return this.validFrom <= now && this.validTo >= now;
    }

    /**
     * Get show remaining validity
     * @return {boolean}
     */
    get showRemainingValidity() {
        return this.isOpen && this.isInsideValidityRange && this.isRemainingValidityBelowThreshold;
    }

    /**
     * Get total position count
     * @returns {number}
     */
    get positionCount() {
        return this.openPositionCount + this.closedPositionCount;
    }

    /**
     * Get status tag
     * @return {object}
     */
    get statusTag() {
        return {
            type: this.status === PROJECT_STATUS_OPEN ? 'green' : 'red',
            label: `status.project.${this.status}`,
        };
    }
}

import _isObject from 'lodash/isObject';
import i18n from '@/i18n';
import { availableLanguageCodes, getLanguageFromLocale, findClosestMatchingSupportedLocale } from '@/i18n';

/**
 * Transform an object value to ensure localized structure
 * Example: Hello -> 成
 *
 * @param {*} value
 */
export const localize = value => {
    const data = {};
    const isObject = _isObject(value);
    const fallbackLocale = i18n.fallbackLocale;

    availableLanguageCodes.forEach(locale => {
        let localeValue = null;

        if (isObject && value[locale] !== undefined) {
            localeValue = value[locale];
        } else if (isObject && value[findClosestMatchingSupportedLocale(locale)] !== undefined) {
            localeValue = value[findClosestMatchingSupportedLocale(locale)];
        } else if (fallbackLocale === locale) {
            localeValue = value;
        }

        data[locale] = localeValue;
    });

    return data;
};

/**
 * Transform an object value to ensure localized structure
 * Example: {Hello} -> {成}
 *
 * @param {Object} object
 * @param {String[]} keys
 */
export const localizeObject = (object, keys = []) => {
    keys.forEach(key => {
        object[key] = localize(object[key]);
    });

    return object;
};

/**
 * Transform an object value to ensure localized structure
 * Example: [{Hello}] -> [{成}]
 *
 * @param {Object[]} collection
 * @param {String[]} keys
 */
export const localizeCollection = (collection, keys = []) => {
    return collection.map(object => localizeObject(object, keys));
};

/**
 * Get localized value based on the current locale
 * Example: 成 -> Hello
 * - supports fallback language
 *
 * @param {*} value
 * @param {*} defaultValue
 */
export const revertLocalizedValue = (value, defaultValue = null) => {
    if (!_isObject(value)) {
        return value;
    }

    const newLocale = value[getLanguageFromLocale(i18n.locale)] || value[getLanguageFromLocale(i18n.fallbackLocale)];
    const oldLocale = value[i18n.locale] || value[i18n.fallbackLocale];

    return oldLocale || newLocale || defaultValue;
};

/**
 * Mutate object values to ensure localized structure
 * Example: {成} -> {Hello}
 * !!! mutates object
 *
 * @param {Object} obj
 * @param {String[]} valueKeys
 */
export const revertLocalizedObjectValues = (obj, valueKeys = []) => {
    valueKeys.forEach(key => {
        obj[key] = revertLocalizedValue(obj[key]);
    });

    return obj;
};

/**
 * Mutate a collection's object values to ensure localized structure
 * Example: [{成}] -> [{Hello}]
 * !!! mutates object
 *
 * @param {Object[]} collection
 * @param {String[]} valueKeys
 */
export const revertLocalizedCollectionValues = (collection, valueKeys = []) => {
    return collection.map(item => revertLocalizedObjectValues(item, valueKeys));
};

export default {
    localize,
    localizeObject,
    localizeCollection,
    revertLocalizedValue,
    revertLocalizedObjectValues,
    revertLocalizedCollectionValues,
};

import axios from 'axios';
import _cloneDeep from 'lodash/cloneDeep';
import ApiService from '@/services/Api/ApiService';
import { AbstractResourceErrorHandler } from './AbstractResourceErrorHandler';

/**
 * Global callback type `apiUploadProgressCallback`
 *
 * @callback apiUploadProgressCallback
 * @param {number} percentCompleted
 */

/**
 * AbstractResource
 */
export default class AbstractResource extends AbstractResourceErrorHandler {
    /**
     * @param {string|null} resourcePath
     * @param {Object} messages
     */
    constructor(resourcePath = null, messages = {}) {
        super(messages);
        this.apiService = ApiService;
        this.resourcePath = resourcePath;
    }

    createCancelTokenSource() {
        return axios.CancelToken.source();
    }

    /**
     * Get all resource objects
     * @param {int} id
     */
    async getAll() {
        try {
            const response = await this.apiService.get(this.resourcePath);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Get one resource object
     * @template T
     * @param {int} id
     * @return {Promise<T>}
     */
    async getOneById(id) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/${id}`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Save one resource object
     * @param {object} resourceObject
     */
    async save(resourceObject) {
        try {
            const object = _cloneDeep(resourceObject);
            const id = object.id;
            delete object.id;
            const suffix = id ? `/${id}` : '';
            const response = await this.apiService.post(`${this.resourcePath}${suffix}`, object);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async put(url, data) {
        try {
            const response = await this.apiService.put(url, data);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Save data
     * @param {string} url
     * @param {object} data
     */
    async patch(url, data) {
        try {
            const response = await this.apiService.patch(url, data);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Delete one resource object
     * @param {int} id
     */
    async delete(id) {
        try {
            return this.apiService.delete(`${this.resourcePath}/${id}`);
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

<template>
    <GridRow :count="2" spacing="large" class="platform-order-filter-set">
        <TextField
            v-if="supportsFilter('search')"
            v-model="search"
            :label="$t('pages.order.platformFilter.search')"
            class="span-2"
        />

        <TextField
            v-if="supportsFilter('orderNumberSearch')"
            v-model="orderNumberSearch"
            :label="$t('pages.order.platformFilter.orderNumber')"
            class="span-2"
        />

        <div v-if="supportsFilter(['startCreated', 'endCreated'])" class="span-2">
            <Words bold block spaced>{{ $t('pages.order.platformFilter.dateRange') }}</Words>
            <TimeRangeFieldset
                :from="filterScope.filter.startCreated"
                :to="filterScope.filter.endCreated"
                type="date"
                @update-from="$set(filterScope.filter, 'startCreated', ensurePHPTimestamp($event))"
                @update-to="$set(filterScope.filter, 'endCreated', ensurePHPTimestamp($event))"
            />
        </div>

        <div v-if="supportsFilter('type')" class="span-2">
            <Words bold block spaced>{{ $t('pages.order.platformFilter.orderType') }}</Words>
            <SelectBox v-model="type" dark class="span-2">
                <option value="" disabled selected>
                    {{ $t('pages.order.platformFilter.selectOrderType') }}
                </option>
                <option value="transport-pickup">
                    {{ $t('pages.order.platformOrderList.filter.type.options.transport-pickup') }}
                </option>
                <option value="transport-delivery">
                    {{ $t('pages.order.platformOrderList.filter.type.options.transport-delivery') }}
                </option>
                <option value="transport-shipment">
                    {{ $t('pages.order.platformOrderList.filter.type.options.transport-shipment') }}
                </option>
                <option value="">{{ $t('pages.order.platformFilter.all') }}</option>
            </SelectBox>
        </div>

        <TextField
            v-if="supportsFilter('transportNumberSearch')"
            v-model="transportNumberSearch"
            :label="$t('pages.order.platformFilter.transportNumber')"
            class="span-2"
        />

        <TextField
            v-if="supportsFilter('deliveryNoteNumberSearch')"
            v-model="deliveryNoteNumberSearch"
            :label="$t('pages.order.platformFilter.deliveryNoteNumber')"
            class="span-2"
        />

        <TextField
            v-if="supportsFilter('clientSearch')"
            v-model="clientSearch"
            :label="$t('pages.order.platformFilter.client')"
            class="span-2"
        />

        <TextField
            v-if="supportsFilter('supplierSearch')"
            v-model="supplierSearch"
            :label="$t('pages.order.platformFilter.supplier')"
            class="span-2"
        />

        <TextField
            v-if="supportsFilter('carrierSearch')"
            v-model="carrierSearch"
            :label="$t('pages.order.platformFilter.carrier')"
            class="span-2"
        />

        <TextField
            v-if="supportsFilter('destinationSearch')"
            v-model="destinationSearch"
            :label="$t('pages.order.platformFilter.destination')"
            class="span-2"
        />

        <TextField
            v-if="supportsFilter('startRetailPrice')"
            v-model="startRetailPrice"
            :label="$t('pages.order.platformFilter.startRetailPrice')"
            class="span-1"
        />

        <TextField
            v-if="supportsFilter('endRetailPrice')"
            v-model="endRetailPrice"
            :label="$t('pages.order.platformFilter.endRetailPrice')"
            class="span-1"
        />

        <div v-if="supportsFilter('invoiceStatus')" class="span-2">
            <Words bold block spaced>{{ $t('pages.order.platformFilter.invoiceStatusHeadline') }}</Words>
            <MultiselectBox
                v-if="endpoint.supportedFilters && endpoint.supportedFilters.invoiceStatus"
                v-model="invoiceStatus"
                :options="endpoint.supportedFilters.invoiceStatus.options"
                :option-value-renderer="option => option"
                :search-label-renderer="option => $t(`status.invoice.${option}`)"
                :label="$t('pages.order.platformFilter.invoiceStatusHeadline')"
                multiple
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1">
                            {{ buttonScope.label }}
                            <Words muted middle> ({{ $t(`status.invoice.${buttonScope.selectedOptions[0]}`) }}) </Words>
                        </span>
                        <span
                            v-else-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length > 1"
                            muted
                            middle
                        >
                            {{ buttonScope.label }}
                            <Words muted middle>
                                ({{ $t(`status.invoice.${buttonScope.selectedOptions[0]}`) }}, +{{
                                    buttonScope.selectedOptions.length - 1
                                }})
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    {{ $t(`status.invoice.${optionScope.option}`) }}
                </template>
            </MultiselectBox>
        </div>
    </GridRow>
</template>

<script>
import { ensurePHPTimestamp } from '@/services/utils/date';
import _isArray from 'lodash/isArray';
import _intersection from 'lodash/intersection';

import DefaultInner from '@/components/Form/MultiselectBox/DefaultInner';
import GridRow from '@/components/Layout/GridRow';
import MultiselectBox from '@/components/Form/MultiselectBox';
import TextField from '@/components/Form/TextField.v2';
import TimeRangeFieldset from '@/components/Filter/TimeRangeFieldset.v2';
import Words from '@/components/Typography/Words';
import SelectBox from '@/components/Form/SelectBox';

import { useTwoWayBindingComputedFilter } from '@/components/Filter/FilterBox';

export default {
    name: 'PlatformOrderFilterSet',
    components: {
        DefaultInner,
        GridRow,
        MultiselectBox,
        TextField,
        TimeRangeFieldset,
        Words,
        SelectBox,
    },
    props: {
        filterScope: {
            type: Object,
            required: true,
        },
        endpoint: {
            type: Object,
            required: true,
        },
        whitelistedFields: {
            type: Array,
            default: null,
        },
    },
    setup(props, { emit }) {
        const createTwoWayBindingComputedFilter = useTwoWayBindingComputedFilter({ props, emit });

        return {
            search: createTwoWayBindingComputedFilter('search'),
            orderNumberSearch: createTwoWayBindingComputedFilter('orderNumberSearch'),
            type: createTwoWayBindingComputedFilter('type'),
            transportNumberSearch: createTwoWayBindingComputedFilter('transportNumberSearch'),
            deliveryNoteNumberSearch: createTwoWayBindingComputedFilter('deliveryNoteNumberSearch'),
            clientSearch: createTwoWayBindingComputedFilter('clientSearch'),
            supplierSearch: createTwoWayBindingComputedFilter('supplierSearch'),
            carrierSearch: createTwoWayBindingComputedFilter('carrierSearch'),
            destinationSearch: createTwoWayBindingComputedFilter('destinationSearch'),
            startRetailPrice: createTwoWayBindingComputedFilter('startRetailPrice'),
            endRetailPrice: createTwoWayBindingComputedFilter('endRetailPrice'),
            invoiceStatus: createTwoWayBindingComputedFilter('invoiceStatus'),
        };
    },
    methods: {
        ensurePHPTimestamp,

        /**
         * Note: May be extracted into a FilterSet Mixin/Component
         */
        supportsFilter(filterName) {
            if (!this.endpoint.supportedFilters) {
                return false;
            }

            const checkProps = _isArray(filterName) ? filterName : [filterName];

            // if filterName does not appear fully in whitelistedFields,.. then the filter is not supported
            if (
                _isArray(this.whitelistedFields) &&
                _intersection(this.whitelistedFields, checkProps).length !== checkProps.length
            ) {
                return false;
            }

            return _intersection(Object.keys(this.endpoint.supportedFilters), checkProps).length === checkProps.length;
        },
    },
};
</script>

<style lang="scss">
.platform-order-filter-set {
    margin: 15px;

    @media only screen and (min-width: $layout-desktop-min) {
        margin-left: 25px;
        margin-right: 25px;
    }
}
</style>

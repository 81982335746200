import React from 'react';
import { MapMarkerIcon } from '@/constructionProjects/components/icons/MapMarkerIcon';
import { VueRouterLink } from '@/reactBridge/VueRouterLink';
import { useAbility } from '@/reactBridge/useAbility';
import { Button, AvatarList } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
import { CONSTRUCTION_PROJECT_DETAILS_ROUTE, DAYS_LEFT_NOTIFICATION_THRESHOLD } from '../constants';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { useBreakpoints } from '@/constructionProjects/hooks/useBreakpoints';
import { Tag } from '@schuettflix/react-components';
import { calculateValidityInDays } from '../utils/validity';
const ColoredHeadline = ({ backgroundColor }) => {
    return <div className="h-4 w-full rounded-b" style={{ backgroundColor }}/>;
};
export const FavoriteProjectCard = ({ id, name, zip, city, color = 'inherit', description, teamMembers, onOrderClick, responsibleUser, isActive, validTo, }) => {
    const canSubmitProjectOrder = useAbility('createProjectPositionQuote');
    const canCreateDeliveryQuote = useAbility('createDeliveryQuote');
    const canCreateShipmentQuote = useAbility('createShipmentQuote');
    const canCreatePickupQuote = useAbility('createPickupQuote');
    const canOrder = canSubmitProjectOrder || canCreateDeliveryQuote || canCreateShipmentQuote || canCreatePickupQuote;
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const { isSmallerOrEqual } = useBreakpoints();
    const RootElement = isSmallerOrEqual('md') && isActive ? VueRouterLink : 'div';
    const daysLeft = calculateValidityInDays(validTo);
    const showWarning = daysLeft > 0 && daysLeft <= DAYS_LEFT_NOTIFICATION_THRESHOLD;
    return (<RootElement to={{ name: CONSTRUCTION_PROJECT_DETAILS_ROUTE, params: { id } }} className="h-full">
            <div className="flex h-full w-full flex-col">
                <ColoredHeadline backgroundColor={color}/>
                <p className="font-copy-lg-strong mb-2 mt-2 line-clamp-2 h-12 px-2" title={name}>
                    {name}
                </p>

                {showWarning ? (<div className="flex items-center px-2">
                        <Tag type="warning" label={t('pages.constructionProject.projectCard.expirationWarning', {
                count: daysLeft,
            })}/>
                    </div>) : (<p className="h-6 truncate px-2" title={description}>
                        {description}
                    </p>)}

                <hr className="border-1 mx-2 my-4 border-solid border-divider"/>
                <div className="flex items-center justify-between px-2">
                    {city && (<div className="flex items-center">
                            <MapMarkerIcon />
                            <p className="ml-1">{`${zip || ''} ${city}`.trim()}</p>
                        </div>)}
                    <AvatarList users={[responsibleUser, ...(teamMembers ?? [])]} size="sm"/>
                </div>
                {isActive && (<div className={`mt-4 flex gap-4 duration-200 ease-in ${isActive ? '' : 'opacity-0'}`}>
                        <Button variant="secondary" className="transition-background hidden w-1/2 rounded border border-black duration-200 ease-in md:block" size="sm" onClick={event => {
                event.preventDefault();
                vueRouter.push({ name: CONSTRUCTION_PROJECT_DETAILS_ROUTE, params: { id } });
            }} label={t('pages.constructionProject.favorites.button.details')} data-test="construction-project-details-button"/>
                        {canOrder && (<Button className="transition-background w-full rounded duration-200 ease-in md:w-1/2" size="sm" label={t('pages.constructionProject.favorites.button.placeOrder')} data-test="construction-project-place-order-button" onClick={event => {
                    event.preventDefault();
                    onOrderClick(id);
                }}/>)}
                    </div>)}
            </div>
        </RootElement>);
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(!_vm.summary.isCustom && !_vm.summary.isNewQuote)?_c('Tile',[_c('div',{staticClass:"checkout-summery__order-status"},[_c('span',{staticClass:"font-copy-sm-strong"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.summary.orderStatus.title'))+" ")]),_c('span',{class:[
                    'font-copy-sm-strong',
                    {
                        'text-brand-primary': _vm.summary.isRejectedQuote || _vm.summary.isCancelledByPlatform,
                        'text-warning': _vm.summary.isPendingQuote,
                    },
                ]},[_vm._v(" "+_vm._s(_vm.$t(`pages.checkout.summary.orderStatus.status.${_vm.summary.status}`))+" ")])]),(_vm.summary.isCancelledByPlatform)?_c('span',{staticClass:"font-copy-sm-strong text-subdued"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.summary.orderStatus.platformRejectionTitle'))+" ")]):_vm._e()]):_vm._e(),_c('ProductTitleSection',{staticClass:"mt-4",attrs:{"prefix":_vm.productPrefix,"title":_vm.productTitle,"tags":_vm.productTags,"description":_vm.productDescription}},[(_vm.summary.lineItems)?_c('OrderVehicleTrack',{staticClass:"checkout-summary__transports",attrs:{"line-items":_vm.summary.lineItems,"vehicle-layout":"horizontal","hide-loading-info":_vm.summary.isTypeShipment}}):_vm._e()],1),_c('HorizontalDivider',{staticClass:"mb-0 mt-2"})],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
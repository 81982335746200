<template>
    <section class="custom-positions">
        <div class="font-copy-md-strong mb-6">
            {{ $t('pages.checkout.projectShipmentPriceAdjustments.customPosition.headline') }}
        </div>

        <template v-if="customPositions">
            <template v-for="(cp, collectionId) in customPositions.collection">
                <AddSectionPlaceholder
                    :key="collectionId"
                    :label="$t('pages.checkout.projectPositionDisposal.pages.additionalPositions')"
                    data-test="add-custom-cost-position"
                    @add="
                        $emit('add', {
                            vid: cp.vehicleClassId,
                            count: cp.count,
                            vehiclePayload: cp.payload,
                        })
                    "
                />
                <template v-if="cp.positions && cp.positions.length">
                    <ProjectCustomPositionItem
                        v-for="(position, cid) in cp.positions"
                        :key="`cp-${cp.vehicleClassId}-${cid}`"
                        :vehicle-index="cp.vehicleClassId"
                        :position-index="cid"
                        :position="position"
                        :is-pending="isPending"
                        @edit="$emit('edit', { vid: cp.vehicleClassId, cid, position })"
                        @remove="$emit('remove', { vid: cp.vehicleClassId, cid })"
                    />
                </template>
            </template>
        </template>
    </section>
</template>

<script>
import CustomPositionPricingSetView from '@/pages/Checkout/components/PriceAdjustment/models/CustomPositionPricingSetView';

import ProjectCustomPositionItem from '@/pages/Checkout/components/PriceAdjustment/ProjectCustomPositionItem';
import AddSectionPlaceholder from '@/pages/Checkout/components/ProjectPosition/AddSectionPlaceholder';

export default {
    name: 'ProjectCustomPositionsSection',
    components: {
        ProjectCustomPositionItem,
        AddSectionPlaceholder,
    },
    props: {
        customPositions: {
            type: CustomPositionPricingSetView,
            required: true,
        },
        isPending: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isSingleCustomPosition() {
            return this.customPositions.collection.length === 1;
        },
    },
};
</script>

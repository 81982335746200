<template>
    <Flyout v-if="isActionAvailable" :active="isActive" size="small" no-header @closed="closeFlyout">
        <ProjectPositionAnalysis
            :project-position-id="subject.id"
            @projectPositionSaved="handleProjectPositionSaved"
            @close="closeFlyout"
        />
    </Flyout>
</template>

<script>
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { ACTIONS } from '@/constants/actions';
import { EVENT_PROJECT_POSITION_UPDATED } from '@/constants/events';
import { PROJECT_POSITION_TYPE_DANGEROUS_WASTE, PROJECT_POSITION_TYPE_WASTE } from '@/constants/projectPositionTypes';
import { mapGetters } from 'vuex';

import Flyout from '@/components/Layout/Flyout';
import ProjectPositionAnalysis from '@/pages/Checkout/Disposal/ProjectPosition/Analysis';

export default {
    name: 'ProjectPositionAnalysisChangeAction',
    components: { ProjectPositionAnalysis, Flyout },
    mixins: [eventHubMixin],
    computed: {
        ...mapGetters('globalActions', ['subject', 'action']),

        isActive() {
            return this.action === ACTIONS.PROJECT_POSITION_DISPOSAL_ANALYSIS_CHANGE;
        },

        /**
         * Should this action be available based on the current conditions
         */
        isActionAvailable() {
            return [PROJECT_POSITION_TYPE_DANGEROUS_WASTE, PROJECT_POSITION_TYPE_WASTE].includes(this.subject?.type);
        },
    },
    methods: {
        closeFlyout() {
            this.$store.dispatch('globalActions/reset', ACTIONS.PROJECT_POSITION_DISPOSAL_ANALYSIS_CHANGE);
        },
        handleProjectPositionSaved() {
            this.$eventHub.$emit(EVENT_PROJECT_POSITION_UPDATED, this.subject);
            this.closeFlyout();
        },
    },
};
</script>

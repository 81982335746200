import AbstractFilterableResource from '../AbstractFilterableResource';

class Tag extends AbstractFilterableResource {
    async getTagsList(searchTerm) {
        const response = await this.apiService.get(`/v1/seller-product-tags?term=${searchTerm}`);
        return response.data;
    }

    async createTag(tagLabel) {
        try {
            const response = await this.apiService.post('/v1/seller-product-tags', { label: tagLabel });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new Tag('/v1/seller-product-tags');

/**
 * OutOfBoundsError is thrown whenever a range is exceeded
 * In the context of the PlanningAssistant, it's when the requested count of dates can not be fulfilled
 */
export default class OutOfBoundsError extends Error {
    constructor(message, collection) {
        super(message);
        this._collection = collection;
    }

    /**
     * Shorthand for the last possible item of a collection
     * @return {*|null}
     */
    get lastEntry() {
        return this._collection.length > 0 ? this._collection[this._collection.length - 1] : null;
    }
}

import i18n from '@/i18n';

const UNIT_KM = 'units.kilometer';
const UNIT_MINUTE = 'pages.vehicleClassFormPage.perMinute';
const UNIT_HOUR = 'pages.vehicleClassFormPage.perHour';
const UNIT_TRANSPORT = 'units.transport';
const UNIT_TON = 'units.ton';

function formatPriceLabel(price, perUnitTranslationKey) {
    if (price === null) return '';
    return `${i18n.n(price, 'currency')} / ${i18n.t(perUnitTranslationKey)}`;
}

export default class ScopeItemView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     */
    static create(data) {
        if (!data) return null;

        const view = new ScopeItemView();

        view.isDefault = data.isDefault ?? false;
        view.isDoubleTransportEffort = data.isDoubleTransportEffort ?? false;
        view.minPurchasePriceTransport = data.minPurchasePriceTransport ?? null;
        view.minSalesPriceTransport = data.minSalesPriceTransport ?? null;
        view.purchasePriceKm = data.purchasePriceKm ?? null;
        view.salesPriceKm = data.salesPriceKm ?? null;
        view.country = data.country ?? null;
        view.scope = data.scope ?? null;

        if (data.purchasePriceMinute) {
            view.purchasePriceMinute = data.purchasePriceMinute;
            view.purchasePriceHour = data.purchasePriceMinute * 60;
        } else if (data.purchasePriceHour) {
            view.purchasePriceHour = data.purchasePriceHour;
            view.purchasePriceMinute = Math.round((data.purchasePriceHour / 60) * 100) / 100;
        }

        if (data.salesPriceMinute) {
            view.salesPriceMinute = data.salesPriceMinute;
            view.salesPriceHour = data.salesPriceMinute * 60;
        } else if (data.salesPriceHour) {
            view.salesPriceHour = data.salesPriceHour;
            view.salesPriceMinute = Math.round((data.salesPriceHour / 60) * 100) / 100;
        }

        // Required for calculation of formatted labels
        view.payload = data.payload ?? null;

        return view;
    }

    unfold() {
        return {
            isDefault: this.isDefault,
            isDoubleTransportEffort: this.isDoubleTransportEffort,
            minPurchasePriceTransport: this.minPurchasePriceTransport,
            minSalesPriceTransport: this.minSalesPriceTransport,
            purchasePriceHour: this.purchasePriceMinute * 60,
            purchasePriceKm: this.purchasePriceKm,
            salesPriceHour: this.salesPriceMinute * 60,
            salesPriceKm: this.salesPriceKm,
            country: this.country,
            scope: this.scope,
        };
    }

    unfoldToLocalForm() {
        return {
            ...this.unfold(),
            purchasePriceMinute: this.purchasePriceMinute,
            salesPriceMinute: this.salesPriceMinute,
        };
    }

    constructor() {
        this.isDefault = null;
        this.isDoubleTransportEffort = null;
        this.minPurchasePriceTransport = null;
        this.minSalesPriceTransport = null;
        this.purchasePriceMinute = null;
        this.purchasePriceHour = null;
        this.purchasePriceKm = null;
        this.salesPriceHour = null;
        this.salesPriceMinute = null;
        this.salesPriceKm = null;
        this.country = null;
        this.scope = null;

        // required for calculation of formatted labels
        this.payload = null;
    }

    get formattedPurchasePriceKm() {
        return formatPriceLabel(this.purchasePriceKm, UNIT_KM);
    }

    get formattedSalesPriceKm() {
        return formatPriceLabel(this.salesPriceKm, UNIT_KM);
    }

    get formattedPurchasePriceMinute() {
        return formatPriceLabel(this.purchasePriceMinute, UNIT_MINUTE);
    }

    get formattedPurchasePriceHour() {
        return formatPriceLabel(this.purchasePriceHour, UNIT_HOUR);
    }

    get formattedSalesPriceMinute() {
        return formatPriceLabel(this.salesPriceMinute, UNIT_MINUTE);
    }

    get formattedSalesPriceHour() {
        return formatPriceLabel(this.salesPriceHour, UNIT_HOUR);
    }

    get formattedMinPurchasePriceTransport() {
        return formatPriceLabel(this.minPurchasePriceTransport, UNIT_TRANSPORT);
    }

    get formattedMinPurchasePriceTon() {
        return formatPriceLabel(this.minPurchasePriceTransport / (this.payload / 1000), UNIT_TON);
    }

    get formattedMinSalesPriceTransport() {
        return formatPriceLabel(this.minSalesPriceTransport, UNIT_TRANSPORT);
    }
    get formattedMinSalesPriceTon() {
        return formatPriceLabel(this.minSalesPriceTransport / (this.payload / 1000), UNIT_TON);
    }
}

import { ScanbotSDK } from 'capacitor-plugin-scanbot-sdk';
import LogService from '@schuettflix/util-log';
import { isLdActivated } from '@/services/LaunchDarkly';
import i18n from '@/i18n';
const Log = new LogService('services/Image/NativeScanBot');
export async function initScanbotSdk() {
    const config = {
        loggingEnabled: true,
        licenseKey: window.scanbotkey || '',
    };
    try {
        const result = await ScanbotSDK.initializeSDK(config);
        if (result.status !== 'OK')
            Log.log(`ScanBot could not be initialized: ${JSON.stringify(result.status)}`);
    }
    catch (e) {
        Log.log(`Error on ScanBot initialization: ${JSON.stringify(e)}`);
    }
}
export async function checkScanbotSdkLicense() {
    try {
        return await ScanbotSDK.getLicenseInfo();
    }
    catch (e) {
        Log.log(`Error on check ScanBot SDK license: ${JSON.stringify(e)}`);
        return {
            isLicenseValid: false,
        };
    }
}
export async function isDocumentAssistantAvailable() {
    const isDocumentAssistantAvailable = await checkScanbotSdkLicense();
    return isLdActivated('scanbot-usages') && isDocumentAssistantAvailable.isLicenseValid;
}
function getDocumentScannerConfiguration() {
    const configuration = isLdActivated('scanbot-document-scanner-configuration');
    return (configuration || {});
}
function getImageFilterConfiguration() {
    const configuration = isLdActivated('scanbot-image-filter-configuration');
    return configuration;
}
export async function startDocumentScanner(type = 'PDF', config = { multiPageEnabled: false, multiPageButtonHidden: true }) {
    const overwriteConfig = getDocumentScannerConfiguration();
    const configuration = {
        cameraPreviewMode: 'FIT_IN',
        cameraBackgroundColor: '#000000',
        cameraUnavailableExplanationText: String(i18n.t('plugin.scanBot.cameraUnavailableExplanationText')),
        cancelButtonTitle: String(i18n.t('plugin.scanBot.cancelButtonTitle')),
        ignoreBadAspectRatio: false,
        topBarBackgroundColor: '#000000',
        topBarButtonsActiveColor: '#FF0000',
        topBarButtonsInactiveColor: '#FFFFFF',
        bottomBarBackgroundColor: '#000000',
        bottomBarButtonsColor: '#FFFFFF',
        userGuidanceBackgroundColor: '#FFFFFF',
        userGuidanceTextColor: '#000000',
        pageCounterButtonTitle: String(i18n.t('plugin.scanBot.pageCounterButtonTitle')),
        multiPageButtonHidden: true,
        multiPageEnabled: false,
        multiPageButtonTitle: String(i18n.t('plugin.scanBot.multiPageButtonTitle')),
        autoSnappingButtonTitle: String(i18n.t('plugin.scanBot.autoSnappingButtonTitle')),
        autoSnappingEnabled: true,
        enableCameraButtonTitle: String(i18n.t('plugin.scanBot.enableCameraButtonTitle')),
        enableCameraExplanationText: String(i18n.t('plugin.scanBot.enableCameraExplanationText')),
        flashEnabled: false,
        polygonBackgroundColor: '#FF939340',
        polygonBackgroundColorOK: '#EBFBEC40',
        polygonColor: '#FF0000',
        polygonColorOK: '#31D141',
        polygonLineWidth: 5,
        polygonCornerRadius: 12,
        polygonAutoSnapProgressColor: '#FFF84E',
        polygonAutoSnapProgressLineWidth: 5,
        polygonAutoSnapProgressEnabled: true,
        textHintBadAngles: String(i18n.t('plugin.scanBot.textHintBadAngles')),
        textHintBadAspectRatio: String(i18n.t('plugin.scanBot.textHintBadAspectRatio')),
        textHintNothingDetected: String(i18n.t('plugin.scanBot.textHintNothingDetected')),
        textHintOffCenter: String(i18n.t('plugin.scanBot.textHintOffCenter')),
        textHintOK: String(i18n.t('plugin.scanBot.textHintOK')),
        textHintTooDark: String(i18n.t('plugin.scanBot.textHintTooDark')),
        textHintTooNoisy: String(i18n.t('plugin.scanBot.textHintTooNoisy')),
        textHintTooSmall: String(i18n.t('plugin.scanBot.textHintTooSmall')),
        textHintEnergySavingActive: String(i18n.t('plugin.scanBot.textHintEnergySavingActive')),
        accessibilityConfiguration: {
            flashButtonAccessibilityLabel: String(i18n.t('plugin.scanBot.flashButtonAccessibilityLabel')),
            flashButtonAccessibilityHint: String(i18n.t('plugin.scanBot.flashButtonAccessibilityHint')),
            multiPageButtonAccessibilityLabel: '',
            multiPageButtonAccessibilityHint: '',
            autoSnappingButtonAccessibilityLabel: '',
            autoSnappingButtonAccessibilityHint: '',
            cancelButtonAccessibilityLabel: '',
            cancelButtonAccessibilityHint: '',
            pageCounterButtonAccessibilityLabel: '',
            pageCounterAccessibilityHint: '',
            shutterButtonAccessibilityLabel: '',
            shutterButtonAccessibilityHint: '',
        },
        ...overwriteConfig,
    };
    const result = await ScanbotSDK.startDocumentScanner({ ...configuration, ...config });
    if (result.status !== 'OK')
        return {};
    const filter = getImageFilterConfiguration();
    // Apply filter to all pages, pick the imageFileUri of each page
    const imageFileUris = await Promise.all((result.pages || []).map(async (p) => {
        const imageFileUri = p.documentImageFileUri || p.originalImageFileUri;
        if (!filter)
            return imageFileUri;
        return (await ScanbotSDK.applyImageFilter({
            imageFileUri,
            filter,
        })).imageFileUri;
    }));
    const path = await {
        // jpeg doesn't support multipage, so we only return the first page
        // we don't need to process the image, because jpg is already the default
        JPEG: () => imageFileUris[0],
        TIFF: async () => (await ScanbotSDK.writeTIFF({
            imageFileUris,
            options: {
                oneBitEncoded: true,
                dpi: 300,
                compression: 'CCITT_T6',
            },
        })).tiffFileUri,
        PDF: async () => (await ScanbotSDK.createPDF({
            imageFileUris,
            options: {
                pageSize: 'A4',
            },
        })).pdfFileUri,
    }[type]();
    return {
        path,
    };
}

export const stateIdentifiers = [
    {
        longName: 'Schleswig-Holstein',
        shortName: 'SH',
        wasteStateLetter: 'A',
    },
    {
        longName: 'Hamburg',
        shortName: 'HH',
        wasteStateLetter: 'B',
    },
    {
        longName: 'Niedersachsen',
        shortName: 'NI',
        wasteStateLetter: 'C',
    },
    {
        longName: 'Bremen',
        shortName: 'HB',
        wasteStateLetter: 'D',
    },
    {
        longName: 'Nordrhein-Westfalen',
        shortName: 'NW',
        wasteStateLetter: 'E',
    },
    {
        longName: 'Hessen',
        shortName: 'HE',
        wasteStateLetter: 'F',
    },
    {
        longName: 'Rheinland-Pfalz',
        shortName: 'RP',
        wasteStateLetter: 'G',
    },
    {
        longName: 'Baden-Württemberg',
        shortName: 'BW',
        wasteStateLetter: 'H',
    },
    {
        longName: 'Bayern',
        shortName: 'BY',
        wasteStateLetter: 'I',
    },
    {
        longName: 'Saarland',
        shortName: 'SL',
        wasteStateLetter: 'K',
    },
    {
        longName: 'Berlin',
        shortName: 'BE',
        wasteStateLetter: 'L',
    },
    {
        longName: 'Mecklenburg-Vorpommern',
        shortName: 'MV',
        wasteStateLetter: 'M',
    },
    {
        longName: 'Sachsen-Anhalt',
        shortName: 'ST',
        wasteStateLetter: 'N',
    },
    {
        longName: 'Brandenburg',
        shortName: 'BB',
        wasteStateLetter: 'P',
    },
    {
        longName: 'Thüringen',
        shortName: 'TH',
        wasteStateLetter: 'R',
    },
    {
        longName: 'Sachsen',
        shortName: 'SN',
        wasteStateLetter: 'S',
    },
];

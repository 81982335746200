import { toKgPrice, toTonPrice } from '@/services/utils/units';
import { TransportPrice } from '@/services/utils/transport';
import _isNull from 'lodash/isNull';

export default class WasteTransportPricingView {
    constructor() {
        this._vehicleClassId = null;
        this._info = null;
        this._payload = null;
        this._count = 1;
        this._purchaseUnitPrice = null;
        this._retailUnitPrice = null;
        this._purchasePrice = null;
        this._retailPrice = null;
        this._duration = null;
        this._distance = null;
        this._enabled = true;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {WasteTransportPricingView|null}
     */
    static createFromDisposalPricesPayload(data) {
        if (!data) return null;

        const view = new WasteTransportPricingView();

        view.distance = data.distance;
        view.duration = data.duration;
        view.info = data.vehicleName;
        view.payload = data.payload;
        view.vehicleClassId = parseInt(data.vehicleClassId);
        view.enabled = !_isNull(data?.customPrices?.purchaseUnitPrice) && data?.customPrices?.purchaseUnitPrice >= 0;

        if (typeof data.transportPrices.purchaseUnitPrice === 'number') {
            view.purchaseUnitPrice = new TransportPrice();
            view.purchaseUnitPrice.distance = data.distance;
            view.purchaseUnitPrice.payload = data.payload;
            view.purchaseUnitPrice.setPerTon(toTonPrice(data.transportPrices.purchaseUnitPrice));
        }

        if (typeof data.transportPrices.retailUnitPrice === 'number') {
            view.retailUnitPrice = new TransportPrice();
            view.retailUnitPrice.distance = data.distance;
            view.retailUnitPrice.payload = data.payload;
            view.retailUnitPrice.setPerTon(toTonPrice(data.transportPrices.retailUnitPrice));
        }

        view.purchasePrice = new TransportPrice();
        view.purchasePrice.setPerTransport(data.transportPrices.purchasePrice);
        view.retailPrice = new TransportPrice();
        view.retailPrice.setPerTransport(data.transportPrices.retailPrice);

        return view;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            duration: this.duration,
            distance: this.distance,
            info: this.info,
            payload: this.payload,
            vehicleClassId: this.vehicleClassId,
            enabled: this.enabled,
            purchaseUnitPrice: toKgPrice(this.purchaseUnitPrice.perTon),
            purchasePrice: this.purchasePrice,
            retailUnitPrice: toKgPrice(this.retailUnitPrice.perTon),
            retailPrice: this.retailUnitPrice,
        };
    }

    get info() {
        return this._info;
    }

    set info(value) {
        this._info = value ?? null;
    }

    get payload() {
        return this._payload;
    }

    set payload(value) {
        this._payload = value ?? null;
    }

    get purchaseUnitPrice() {
        return this._purchaseUnitPrice;
    }

    set purchaseUnitPrice(value) {
        this._purchaseUnitPrice = value ?? null;
    }

    get purchasePrice() {
        return this._purchasePrice;
    }

    set purchasePrice(value) {
        this._purchasePrice = value ?? null;
    }

    get retailUnitPrice() {
        return this._retailUnitPrice;
    }

    set retailUnitPrice(value) {
        this._retailUnitPrice = value ?? null;
    }

    get retailPrice() {
        return this._retailPrice;
    }

    set retailPrice(value) {
        this._retailPrice = value ?? null;
    }

    get duration() {
        return this._duration;
    }

    set duration(value) {
        this._duration = value ?? null;
    }

    get distance() {
        return this._distance;
    }

    set distance(value) {
        this._distance = value ?? null;
    }

    get enabled() {
        return this._enabled;
    }

    set enabled(value) {
        this._enabled = value ?? false;
    }

    get vehicleClassId() {
        return this._vehicleClassId;
    }

    set vehicleClassId(value) {
        this._vehicleClassId = value ?? null;
    }

    get count() {
        return this._count;
    }

    get purchasePriceTotals() {
        return {
            getPerKm: () => {
                return this.purchaseUnitPrice.getPerKm() * this.count;
            },
            getPerTransport: () => {
                return this.purchaseUnitPrice.getPerTransport() * this.count;
            },
            getPerTon: () => {
                return (this.purchaseUnitPrice.getPerTon() * this.qty) / 1000;
            },
        };
    }

    get retailPriceTotals() {
        return {
            getPerKm: () => {
                return this.retailUnitPrice.getPerKm() * this.count;
            },
            getPerTransport: () => {
                return this.retailUnitPrice.getPerTransport() * this.count;
            },
            getPerTon: () => {
                return (this.retailUnitPrice.getPerTon() * this.qty) / 1000;
            },
        };
    }
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"order-type-item-v2 card card--hovered card--focussed",attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{class:[
            'order-type-item-v2__container',
            { 'order-type-item-v2__container--break': _vm.additionalIconProperties },
            { 'order-type-item-v2__container--no-icon': _vm.external },
        ]},[(!_vm.external)?_c(_vm.icon,_vm._b({tag:"component",class:[
                'order-type-item-v2__icon',
                { 'order-type-item-v2__icon--default': !_vm.additionalIconProperties },
            ]},'component',_vm.additionalIconProperties,false)):_vm._e(),(_vm.external)?[_c('div',{staticClass:"flex flex-1 flex-col items-baseline"},[_c('p',{staticClass:"font-copy-sm-strong pb-1"},[_vm._v(" "+_vm._s(_vm.$t('pages.homeV2.allGenericOrders'))+" ")]),_c('p',{staticClass:"font-copy-sm text-subdued"},[_vm._v(" "+_vm._s(_vm.$t('pages.platformTools.lookerProductSearch.description'))+" ")])]),_c('SfExternalLinkIcon',{attrs:{"size":"xxs"}})]:[_c('Words',{staticClass:"text-left",attrs:{"block":"","small":"","bold":""},domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('ArrowIcon',{staticClass:"order-type-item-v2__arrow-icon"})]],2)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
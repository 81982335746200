import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { updateFavoriteStatus } from '../api/projects';
import { constructionProjectByIdQuery, constructionProjectKeys } from '../queries';
import { StarFilledIcon } from './icons/StarFilledIcon';
import { StarIcon } from './icons/StarIcon';
import { useRouteParams } from '@/reactBridge/useRouteParams';
import clsx from 'clsx';
export const FavoriteStar = ({ activeClass = 'fill-icon', className }) => {
    const { id: constructionProjectId } = useRouteParams();
    const queryClient = useQueryClient();
    const { data: isFavorite } = useQuery({
        ...constructionProjectByIdQuery(constructionProjectId),
        suspense: true,
        select: data => data.isFavorite,
    });
    const setFavoriteStatus = useMutation({
        mutationFn: updateFavoriteStatus,
        onMutate: async ({ isFavorite }) => {
            await queryClient.cancelQueries({
                queryKey: constructionProjectKeys.byId(constructionProjectId),
            });
            const previousConstructionProject = queryClient.getQueryData([
                'construction-project-details',
                constructionProjectId,
            ]);
            queryClient.setQueryData(constructionProjectKeys.byId(constructionProjectId), old => (old !== undefined ? { ...old, isFavorite } : undefined));
            return { previousConstructionProject };
        },
        onError: (_, __, context) => {
            queryClient.setQueryData(constructionProjectKeys.byId(constructionProjectId), context?.previousConstructionProject);
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: constructionProjectKeys.byId(constructionProjectId),
            });
        },
    });
    const handleClick = () => {
        setFavoriteStatus.mutate({
            constructionProjectId,
            isFavorite: !isFavorite,
        });
    };
    return (<button className={clsx('grid h-[40px] w-[40px] place-content-center rounded-full bg-transparent hover:bg-surface-hovered', className)} onClick={handleClick}>
            {isFavorite ? (<StarFilledIcon data-test="construction-project-details-page-star-icon" className={clsx('cursor-pointer', activeClass)} size={24}/>) : (<StarIcon data-test="construction-project-details-page-star-icon" className="cursor-pointer stroke-icon" size={24}/>)}
        </button>);
};

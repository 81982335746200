import CourtListPage from '@/pages/CourtList/CourtListPage';

export default {
    path: 'court-list',
    name: 'court-list',
    component: CourtListPage,
    meta: {
        requiresAuth: true,
        requiredAbilities: ['haveCourtList'],
    },
    children: [
        {
            include: 'transport-view',
        },
    ],
};

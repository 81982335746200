var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('div',{staticClass:"card mt-4 w-full text-left",class:[
            {
                'card--hovered card--focussed ': !_vm.preview,
            },
        ]},[_c('span',{staticClass:"font-copy-sm-strong mb-6"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.disposal.summary.tiles.waste.title'))+" ")]),_c('WasteCode',{attrs:{"is-bold":"","is-block":"","font-size":"md","code":_vm.waste.code,"is-hazardous":_vm.waste.isHazardous}}),_c('span',{staticClass:"font-copy-sm mt-4 block"},[_vm._v(_vm._s(_vm.waste.description))])],1),(_vm.editable)?_c('div',{staticClass:"mt-2 text-right"},[_c('SfLink',{attrs:{"bold":""},on:{"click":function($event){return _vm.$emit('edit', {
                    section: 'waste',
                    routeName: 'checkout-project-disposal-hazardous-choose-waste',
                })}}},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.disposal.summary.actions.change.waste'))+" ")])],1):_vm._e()])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
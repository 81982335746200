import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { FlyoutPage } from '@/constructionProjects/components/FlyoutPage';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { ConstructionProjectClientEditForm } from '@/constructionProjects/pages/ConstructionProjectEdit/ConstructionProjectClientEditForm';
import { LoadingSpinner } from '@schuettflix/react-components';
const _ConstructionProjectClientEditForm = () => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    return (<FlyoutPage screenName="client-constructionproject-settings-edit" headerTitle={t('pages.constructionProject.editForm.header.title')} body={<Suspense fallback={<LoadingSpinner block spaced/>}>
                    <ConstructionProjectClientEditForm />
                </Suspense>} onCloseAnimationEnd={() => vueRouter.back()}/>);
};
export const ConstructionProjectClientEditFlyout = asVueComponent(_ConstructionProjectClientEditForm);

<template>
    <div class="notification-alert">
        <Words block bold class="notification-alert__title">{{ message.title }}</Words>
        <Words v-if="message.body !== ''" block class="notification-alert__message" v-html="nl2br(message.body)" />
        <div class="notification-alert__buttons">
            <Button v-if="hasAction" class="notification-alert__accept" arrow-right @click="open">
                {{ $t('components.notificationAlert.show') }}
            </Button>
            <Button class="notification-alert__dismiss" arrow-right @click="dismiss">
                {{ $t('components.notificationAlert.dismiss') }}
            </Button>
        </div>
    </div>
</template>

<script>
import DeepLinkService from '@/services/Notification/DeepLinkService';
import { handleNotificationMessage } from '@/services/Notification/NotificationService';
import { nl2br } from '@/services/utils';

import Words from '@/components/Typography/Words';
import Button from '@/components/Button/Button';

export default {
    name: 'NotificationAlert',
    components: {
        Words,
        Button,
    },
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
    computed: {
        hasAction() {
            return DeepLinkService.hasMessageRoute(this.message);
        },
    },
    methods: {
        nl2br,

        dismiss() {
            this.$store.commit('notification/removeNotification', this.message.hash);
        },
        open() {
            handleNotificationMessage(this.message);
            this.$store.commit('notification/removeNotification', this.message.hash);
        },
    },
};
</script>

<style lang="scss">
.notification-alert {
    background-color: $color-white;
    color: $color-base;
    padding: 10px 15px;
    box-shadow: $boxShadow-full;
    margin: 10px;
}

.notification-alert__title,
.notification-alert__message {
    margin-bottom: 1em;
}

.notification-alert__buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.notification-alert__accept {
    margin-right: auto;
}

.notification-alert__dismiss {
    margin-left: auto;
}
</style>

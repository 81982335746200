<template>
    <GridRow :count="2" spacing="large">
        <div v-if="supportsFilter('type')" class="span-2">
            <Words bold block spaced>{{ $t('pages.creditNoteDoc.filter.typeHeadline') }}</Words>
            <MultiselectBox
                v-model="type"
                :options="Object.keys(endpoint.supportedFilters.type.options)"
                :option-value-renderer="option => option"
                :search-label-renderer="option => option"
                :label="$t('pages.creditNoteDoc.filter.typeHeadline')"
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length > 0">
                            <span class="font-copy-sm-strong block">{{ buttonScope.label }}</span>
                            <span class="font-copy-md-strong mt-1 block text-subdued">
                                {{ $t(getTypeKey(buttonScope.selectedOptions[0])) }}
                                <template v-if="buttonScope.selectedOptions.length > 1">
                                    , +{{ buttonScope.selectedOptions.length - 1 }}
                                </template>
                            </span>
                        </span>
                        <span v-else class="font-copy-md-strong block text-subdued">
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <span
                        :class="[
                            'multiselect__option--spaced',
                            'font-copy-md-strong',
                            'block',
                            { 'text-brand-primary': optionScope.isActive },
                        ]"
                    >
                        {{ $t(getTypeKey(optionScope.option)) }}
                        <TickIcon v-if="optionScope.isActive" class="icon--inline icon--red" />
                    </span>
                </template>
            </MultiselectBox>
        </div>

        <div v-if="supportsFilter('organizationSearch')" class="span-2 span-md-2">
            <TextField v-model="organizationSearch" :label="$t('pages.creditNoteDoc.filter.organizationSearch')" />
        </div>

        <div v-if="supportsFilter('numberSearch')" class="span-2 span-md-2">
            <TextField
                v-model="numberSearch"
                :label="
                    $t(
                        selectCreditNoteDocKey({
                            creditNote: `pages.creditNoteDoc.filter.numberSearch.creditNote`,
                            selfBilling: `pages.creditNoteDoc.filter.numberSearch.selfBilling`,
                        })
                    )
                "
            />
        </div>

        <div v-if="supportsFilter('transportNumberSearch')" class="span-2 span-md-2">
            <TextField
                v-model="transportNumberSearch"
                :label="$t('pages.creditNoteDoc.filter.transportNumberSearch')"
            />
        </div>

        <div>
            <div class="span-2 my-4 font-bold">{{ $t('pages.creditNoteDoc.filter.documentTypeHeadline') }}</div>
            <SelectBox v-model="documentType" small>
                <option :value="null" :label="$t('pages.creditNoteDoc.filter.documentType.all')" />
                <option value="transport" :label="$t('pages.creditNoteDoc.filter.documentType.systemic')" />
                <option value="manual" :label="$t('pages.creditNoteDoc.filter.documentType.manual')" />
            </SelectBox>
        </div>

        <div v-if="supportsFilter(['startCancelled', 'endCancelled'])" class="span-2">
            <Words bold block spaced>{{ $t('pages.creditNoteDoc.filter.dateCancelledHeadline') }}</Words>
            <TimeRangeFieldset
                :from="filterScope.filter.startCancelled"
                :to="filterScope.filter.endCancelled"
                type="date"
                @update-from="$set(filterScope.filter, 'startCancelled', ensurePHPTimestamp($event))"
                @update-to="$set(filterScope.filter, 'endCancelled', ensurePHPTimestamp($event))"
            />
        </div>

        <div v-if="supportsFilter(['startCompleted', 'endCompleted'])" class="span-2">
            <Words bold block spaced>{{ $t('pages.creditNoteDoc.filter.dateCompletedHeadline') }}</Words>
            <TimeRangeFieldset
                :from="filterScope.filter.startCompleted"
                :to="filterScope.filter.endCompleted"
                type="date"
                @update-from="$set(filterScope.filter, 'startCompleted', ensurePHPTimestamp($event))"
                @update-to="$set(filterScope.filter, 'endCompleted', ensurePHPTimestamp($event))"
            />
        </div>

        <div v-if="supportsFilter(['startCreated', 'endCreated'])" class="span-2">
            <Words bold block spaced>{{ $t('pages.creditNoteDoc.filter.dateCreatedHeadline') }}</Words>
            <TimeRangeFieldset
                :from="filterScope.filter.startCreated"
                :to="filterScope.filter.endCreated"
                type="date"
                @update-from="$set(filterScope.filter, 'startCreated', ensurePHPTimestamp($event))"
                @update-to="$set(filterScope.filter, 'endCreated', ensurePHPTimestamp($event))"
            />
        </div>

        <div v-if="supportsFilter('minTotalNet')" class="span-2 span-md-1">
            <TextField v-model="minTotalNet" :label="$t('pages.creditNoteDoc.filter.minTotalNet')" type="number" />
        </div>

        <div v-if="supportsFilter('maxTotalNet')" class="span-2 span-md-1">
            <TextField v-model="maxTotalNet" :label="$t('pages.creditNoteDoc.filter.maxTotalNet')" type="number" />
        </div>

        <div v-if="supportsFilter('status')" class="span-2">
            <Words bold block spaced>{{ $t('pages.creditNoteDoc.filter.statusHeadline') }}</Words>
            <MultiselectBox
                v-model="status"
                :options="Object.keys(endpoint.supportedFilters.status.options)"
                :option-value-renderer="option => option"
                :label="$t('pages.creditNoteDoc.filter.statusHeadline')"
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length > 0">
                            <span class="font-copy-sm-strong block">{{ buttonScope.label }}</span>
                            <span class="font-copy-md-strong mt-1 block text-subdued">
                                {{ $t(`status.creditNoteDoc.${buttonScope.selectedOptions[0]}`) }}
                                <template v-if="buttonScope.selectedOptions.length > 1">
                                    , +{{ buttonScope.selectedOptions.length - 1 }}
                                </template>
                            </span>
                        </span>
                        <span v-else class="font-copy-md-strong block text-subdued">
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <span
                        :class="[
                            'multiselect__option--spaced',
                            'font-copy-md-strong',
                            'block',
                            { 'text-brand-primary': optionScope.isActive },
                        ]"
                    >
                        {{ $t(`status.creditNoteDoc.${optionScope.option}`) }}
                        <TickIcon v-if="optionScope.isActive" class="icon--inline icon--red" />
                    </span>
                </template>
            </MultiselectBox>
        </div>
    </GridRow>
</template>

<script>
import DefaultInner from '@/components/Form/MultiselectBox/DefaultInner';
import GridRow from '@/components/Layout/GridRow';
import MultiselectBox from '@/components/Form/MultiselectBox';
import TimeRangeFieldset from '@/components/Filter/TimeRangeFieldset.v2';
import TextField from '@/components/Form/TextField.v2';
import Words from '@/components/Typography/Words';
import { ensurePHPTimestamp } from '@/services/utils/date';
import _isArray from 'lodash/isArray';
import _intersection from 'lodash/intersection';
import SelectBox from '@/components/Form/SelectBox.v2';

import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
import { useTypes } from '../useTypes';
import { useTwoWayBindingComputedFilter } from '@/components/Filter/FilterBox';

import TickIcon from '@/assets/icons/micro/tick.svg';

export default {
    name: 'CreditNoteFilterSet',
    components: {
        DefaultInner,
        GridRow,
        MultiselectBox,
        TimeRangeFieldset,
        TextField,
        Words,
        TickIcon,
        SelectBox,
    },
    props: {
        filterScope: {
            type: Object,
            required: true,
        },
        endpoint: {
            type: Object,
            required: true,
        },
        whitelistedFields: {
            type: Array,
            default: null,
        },
    },
    setup(props, { emit }) {
        const createTwoWayBindingComputedFilter = useTwoWayBindingComputedFilter({ props, emit });
        const { selectCreditNoteDocKey } = useLegalTerms();
        const { getTypeKey } = useTypes();
        return {
            selectCreditNoteDocKey,
            getTypeKey,
            type: createTwoWayBindingComputedFilter('type'),
            organizationSearch: createTwoWayBindingComputedFilter('organizationSearch'),
            numberSearch: createTwoWayBindingComputedFilter('numberSearch'),
            transportNumberSearch: createTwoWayBindingComputedFilter('transportNumberSearch'),
            minTotalNet: createTwoWayBindingComputedFilter('minTotalNet'),
            maxTotalNet: createTwoWayBindingComputedFilter('maxTotalNet'),
            status: createTwoWayBindingComputedFilter('status'),
            documentType: createTwoWayBindingComputedFilter('documentType'),
        };
    },
    methods: {
        ensurePHPTimestamp,

        supportsFilter(filterName) {
            if (!this.endpoint.supportedFilters) {
                return false;
            }

            const checkProps = _isArray(filterName) ? filterName : [filterName];

            // if filterName does not appear fully in whitelistedFields,.. then the filter is not supported
            if (
                _isArray(this.whitelistedFields) &&
                _intersection(this.whitelistedFields, checkProps).length !== checkProps.length
            ) {
                return false;
            }

            return _intersection(Object.keys(this.endpoint.supportedFilters), checkProps).length === checkProps.length;
        },
    },
};
</script>

<style lang="scss"></style>

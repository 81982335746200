import PricingDataView from '@/pages/Checkout/components/PriceAdjustment/models/PricingDataView';
import _cloneDeep from 'lodash/cloneDeep';
import _isNull from 'lodash/isNull';

export default class PriceAdjustmentView {
    static TYPE_CUSTOM_ORDER = 'customOrder';
    static TYPE_PROJECT_DELIVERY = 'project-delivery';
    static TYPE_PROJECT_SHIPMENT = 'project-shipment';
    static TYPE_PROJECT_DISPOSAL = 'project-disposal';

    /**
     * Constructor
     * @param {string} type
     */
    constructor(type) {
        this._type = type;
        this._original = null;
        this._current = null;
    }

    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} quote
     * @param {object} original Original calculation object
     * @param {object} current Current calculation object
     * @returns {PriceAdjustmentView}
     */
    static createFromCustomOrder(quote, { original, current }) {
        const instance = new PriceAdjustmentView(this.TYPE_CUSTOM_ORDER);

        instance.original = PricingDataView.createFromCustomOrder(quote, original);

        instance.current = PricingDataView.createFromCustomOrder(quote, current);

        return instance;
    }

    /**
     * Create a view model from data (e.g. API response data) of type TYPE_PROJECT_DELIVERY
     *
     * @param {object} original Loaded project position
     * @param {object} calculationData original Original calculation object
     * @param {object|null} [current] The current calculation object if empty a copy of the original is created
     * @returns {PriceAdjustmentView}
     */
    static createFromProjectDeliveryPositionPayload(original, calculationData, current = null) {
        const instance = new PriceAdjustmentView(this.TYPE_PROJECT_DELIVERY);
        instance.initializeFromProjectPositionPayload(original, calculationData, current);
        return instance;
    }

    /**
     * Create a view model from data (e.g. API response data) of type TYPE_PROJECT_SHIPMENT
     *
     * @param {object} original Loaded project position
     * @param {object} calculationData original Original calculation object
     * @param {object|null} [current] The current calculation object if empty a copy of the original is created
     * @returns {PriceAdjustmentView}
     */
    static createFromProjectShipmentPositionPayload(original, calculationData, current = null) {
        const instance = new PriceAdjustmentView(this.TYPE_PROJECT_SHIPMENT);
        instance.initializeFromProjectPositionPayload(original, calculationData, current);
        instance.original.extendFromProjectShipmentPositionPayload(original, { ...original }, calculationData);
        instance.current.extendFromProjectShipmentPositionPayload(
            original,
            { ...(current || original) },
            calculationData
        );
        return instance;
    }

    /**
     * Initialize a view model from data (e.g. API response data)
     *
     * @param {object} original Loaded project position
     * @param {object} calculationData original Original calculation object
     * @param {object|null} [current] The current calculation object if empty a copy of the original is created
     * @returns {PriceAdjustmentView}
     */
    initializeFromProjectPositionPayload(original, calculationData, current = null) {
        this.original = PricingDataView.createFromProjectPositionPayload(original, calculationData);
        this.current = PricingDataView.createFromProjectPositionPayload(current || original, calculationData);
    }

    /**
     * Create a view model from data (e.g. API response data) of type TYPE_PROJECT_DELIVERY
     *
     * @param {number} qty
     * @param {object} original Original calculation object
     * @param {object|null} [current] The current calculation object if empty a copy of the original is created
     * @returns {PriceAdjustmentView}
     */
    static createFromProjectDeliveryPositionBasket(qty, original, current = null) {
        const instance = new PriceAdjustmentView(this.TYPE_PROJECT_DELIVERY);
        instance.initializeFromProjectPositionBasket(qty, original, current);
        return instance;
    }

    /**
     * Create a view model from data (e.g. API response data) of type TYPE_PROJECT_SHIPMENT
     *
     * @param {number} qty
     * @param {object} original Original calculation object
     * @param {string|null} freightDescription The description of the freight
     * @param {object|null} [current] The current calculation object if empty a copy of the original is created
     * @returns {PriceAdjustmentView}
     */
    static createFromProjectShipmentPositionBasket(qty, freightDescription, original, current = null) {
        const instance = new PriceAdjustmentView(this.TYPE_PROJECT_SHIPMENT);
        instance.initializeFromProjectPositionBasket(qty, original, current);
        instance.original.extendFromProjectShipmentPositionBasket(freightDescription, { ...original });
        instance.current.extendFromProjectShipmentPositionBasket(freightDescription, {
            ...(current || original),
        });
        return instance;
    }

    /**
     * Initialize a view model from data (e.g. API response data)
     *
     * @param {number} qty
     * @param {object} original Original calculation object
     * @param {object|null} [current] The current calculation object if empty a copy of the original is created
     * @returns {PriceAdjustmentView}
     */
    initializeFromProjectPositionBasket(qty, original, current = null) {
        this.original = PricingDataView.createFromProjectPositionBasket({ ...original, qty });

        this.current = PricingDataView.createFromProjectPositionBasket({
            ...(current || original),
            qty,
        });
    }

    /**
     * Create a view model from data (e.g. API response data) of type TYPE_PROJECT_DISPOSAL
     *
     * @param {number} qty
     * @param {object} payload
     * @returns {PriceAdjustmentView}
     */
    static createFromDisposalPositionPayload(qty, payload) {
        const instance = new PriceAdjustmentView(this.TYPE_PROJECT_DISPOSAL);
        instance.initializeFromDisposalProjectPositionPayload(qty, payload);
        return instance;
    }

    /**
     * Initialize a view model from data (e.g. API response data)
     *
     * @param {number} qty
     * @param {object} payload
     * @returns {PriceAdjustmentView}
     */
    initializeFromDisposalProjectPositionPayload(qty, payload) {
        const originalTransport = _cloneDeep(payload.transport);
        const customTransport = _cloneDeep(payload.transport);
        let originalTransportPrices = null;
        let customTransportPrices = null;
        if (originalTransport?.length > 0) {
            originalTransportPrices = originalTransport.map(t => {
                if ('customPrices' in t) {
                    delete t.customPrices;
                }
                return {
                    transportPrices: t.originalPrices,
                    ...t,
                };
            });
            customTransportPrices = customTransport.map(t => {
                return {
                    transportPrices: t?.customPrices ?? t.originalPrices,
                    ...t,
                };
            });
        }

        let customProductPrices;
        if (
            payload.product?.customPrices?.purchaseUnitPrice >= 0 &&
            !_isNull(payload.product?.customPrices?.purchaseUnitPrice)
        ) {
            customProductPrices = payload.product.customPrices;
        } else {
            customProductPrices = payload.product.originalPrices;
        }

        this.original = PricingDataView.createFromDisposalPricesPayload({
            productPrices: payload.product?.originalPrices ?? null,
            transport: originalTransportPrices,
            customPositions: null,
            qty,
        });

        this.current = PricingDataView.createFromDisposalPricesPayload({
            productPrices: customProductPrices,
            transport: customTransportPrices,
            customPositions: payload?.customPositions,
            qty,
        });
    }

    /** @return {string} */
    get type() {
        return this._type;
    }

    /** @param {string} value */
    set type(value) {
        this._type = value;
    }

    /** @return {PricingDataView|null} */
    get original() {
        return this._original;
    }

    /** @param {PricingDataView|null} value */
    set original(value) {
        this._original = value;
    }

    /** @return {PricingDataView|null} */
    get current() {
        return this._current;
    }

    /** @param {PricingDataView|null} value */
    set current(value) {
        this._current = value;
    }

    /**
     * @return {number|null}
     */
    get distance() {
        return this.original?.distance;
    }

    /**
     * @return {number|null}
     */
    get duration() {
        return this.original?.duration;
    }
}

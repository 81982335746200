<template>
    <div v-if="addressList" class="last-used-customer-address-selection">
        <Words block bold spaced-bottom>
            <slot name="headline">
                {{ $t('components.lastUsedCustomerAddressSelection.headline') }}
            </slot>
        </Words>
        <div
            v-for="(address, aid) in addressList"
            :key="aid"
            class="last-used-customer-address-selection__tile"
            @click="$emit('click', address)"
        >
            <UsedAddressItem :item="address" />
        </div>
    </div>
</template>

<script>
import AddressApi from '@/services/Api/Address';
import Toaster from '@/services/Toaster';

import Words from '@/components/Typography/Words';
import UsedAddressItem from '@/components/List/UsedAddressItem';

export default {
    name: 'LastUsedCustomerAddressSelection',

    components: {
        Words,
        UsedAddressItem,
    },

    props: {
        clientOrganizationId: {
            type: [Number, String],
            required: true,
        },
        type: {
            type: String,
            required: true,
            validator: v => ['loading', 'unloading'].includes(v),
        },
        operations: {
            type: String || Array,
            required: false,
        },
    },

    data() {
        return {
            addressList: null,
        };
    },

    created() {
        this.refreshAddressList();
    },

    methods: {
        async refreshAddressList() {
            try {
                this.addressList = await AddressApi.getUsed({
                    type: this.type,
                    clientOrganizationId: this.clientOrganizationId,
                    operations: this.operations,
                });
            } catch (err) {
                Toaster.error(this.$t('components.lastUsedCustomerAddressSelection.error'));
            }
        },
    },
};
</script>

<style lang="scss">
.last-used-customer-address-selection {
    margin: 40px 0;
}

.last-used-customer-address-selection__tile {
    cursor: pointer;
    padding: 15px 0;
}
</style>

import { forwardRef } from 'react';
import { useEvent } from './useEvent';
import { useVueRouter } from './VueRouterProvider';
// Adapted from vue-router link
// See: https://github.com/vuejs/vue-router/blob/e3273b3db905f0d162d57725fba0c459ea5bb463/src/components/link.js#L192
function guardEvent(e) {
    // don't redirect with control keys
    if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) {
        return false;
    }
    // don't redirect when preventDefault called
    if (e.defaultPrevented) {
        return false;
    }
    // don't redirect on right click
    if (e.button !== undefined && e.button !== 0) {
        return false;
    }
    // don't redirect if `target="_blank"`
    const target = e.currentTarget.getAttribute('target');
    if (target && /\b_blank\b/i.test(target)) {
        return false;
    }
    if (e.preventDefault) {
        e.preventDefault();
    }
    return true;
}
function noop() { }
export const VueRouterLink = forwardRef(({ to, replace, append, ...rest }, ref) => {
    const { vueRouter } = useVueRouter();
    const { location, href } = vueRouter.resolve(to, vueRouter.currentRoute, append);
    const handleClick = useEvent((event) => {
        rest.onClick?.(event);
        if (guardEvent(event)) {
            if (replace) {
                vueRouter.replace(location, noop);
            }
            else {
                vueRouter.push(location, noop);
            }
        }
    });
    return <a ref={ref} href={href} onClick={handleClick} {...rest}/>;
});
VueRouterLink.displayName = 'VueRouterLink';

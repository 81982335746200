<template>
    <span
        class="m-0"
        :class="[{ 'cursor-pointer': isPlatform(), 'pointer-events-none': !isPlatform() }]"
        @click.stop="$emit('preferred-counter', $event)"
    >
        <SfTag v-if="preferedRemainingSeconds() && !isUnavailable" type="error" class="whitespace-nowrap">
            <template v-if="preferedRemainingSeconds() > 0">
                {{ $t('pages.transportHub.preferredCarrier.exclusive') }}
                <SfTimeIcon size="xxs" class="mx-2" theme="dark" />
                {{ calculatedTimeString() }}
            </template>
            <template v-else>
                <SfWarningIcon size="xxs" class="mr-2" theme="dark" />
                {{ $t('pages.transportHub.preferredCarrier.freeForAll') }}
            </template>
        </SfTag>
    </span>
</template>

<script>
import { getExclusivityLevel } from '@/services/utils/transport';
import routeContext from '@/plugins/mixins/routeContext';
import { CONTEXT_PLATFORM } from '@/constants/context';

import { getFormattedDuration } from '@/services/utils/date';

import { SfTag, SfTimeIcon, SfWarningIcon } from '@schuettflix/vue-components';

/**
 * !!! ATTENTION !!!
 * This component and all of it's children can not have computed
 * properties since they introduce a performance issues on unmounting
 */
export default {
    name: 'TransportListBlockPreferredTag',
    components: {
        SfTag,
        SfTimeIcon,
        SfWarningIcon,
    },
    mixins: [routeContext],
    props: {
        transport: {
            type: Object,
            required: true,
        },
        isUnavailable: {
            type: Boolean,
            default: false,
        },
        context: {
            type: String,
            default: 'client',
        },
    },
    methods: {
        isPlatform() {
            return this.inRouteContext(CONTEXT_PLATFORM) || this.context === CONTEXT_PLATFORM;
        },

        preferedRemainingSeconds() {
            return this.transport.__preferedRemainingSeconds || 0;
        },

        exclusivityLevel() {
            return getExclusivityLevel(this.preferedRemainingSeconds());
        },

        calculatedTimeString() {
            return getFormattedDuration(this.preferedRemainingSeconds(), true);
        },
    },
};
</script>

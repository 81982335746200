<template>
    <div>
        <div class="organization-form__fieldset organization-form__fieldset--spaced">
            <Alert type="info" icon-type="warning" class="organization-form__alert">
                {{ $t('pages.organization.form.settingNotSeenByOrganization') }}
            </Alert>

            <Alert
                v-if="!organization.isExported"
                type="info"
                icon-type="warning"
                dark
                class="organization-form__alert"
            >
                {{ $t('pages.organization.form.organizationNotYetExported') }}
            </Alert>

            <Alert v-else type="success" class="organization-form__alert">
                {{
                    $t('pages.organization.form.organizationHasBeenExported', {
                        date: $d(ensureJSTimestamp(organization.exportedAt), 'short'),
                        time: $t('timeSuffix', {
                            time: $d(ensureJSTimestamp(organization.exportedAt), 'time'),
                        }),
                    })
                }}
            </Alert>
        </div>

        <GridRow class="organization-form__fieldset" :count="2">
            <div v-can:updateOrganizationSalesStaff="organization" class="span-2 span-sm-1">
                <Words block bold small spaced-bottom>
                    {{ $t('components.organizationForm.employees.salesStaff') }}
                </Words>

                <MultiselectBox
                    v-if="organization"
                    v-model="organization.salesStaff.id"
                    :options="availableEmployees"
                    :option-value-renderer="option => option.id"
                    :label="$t('components.organizationForm.employees.labelSalesStaff')"
                    :search-label-renderer="option => option.firstName + ' ' + option.lastName"
                    :search-field-label="$t('components.organizationForm.employees.searchSalesStaff')"
                    data-test="company-settings-select-sales-staff-user"
                    class="organization-form__multiselect"
                    searchable
                    disable-reset
                    image
                >
                    <PersonInner slot="button" slot-scope="buttonScope" mode="button" :scope="buttonScope" />
                    <PersonInner slot="option" slot-scope="optionScope" mode="option" :scope="optionScope" />
                </MultiselectBox>
            </div>

            <div class="span-2 span-sm-1">
                <Words block bold small spaced-bottom>
                    {{ $t('components.organizationForm.employees.accountManager') }}
                </Words>

                <MultiselectBox
                    v-if="organization"
                    v-model="organization.accountManager.id"
                    :options="availableEmployees"
                    :option-value-renderer="option => option.id"
                    :label="$t('components.organizationForm.employees.labelAccountManager')"
                    :search-label-renderer="option => option.firstName + ' ' + option.lastName"
                    :search-field-label="$t('components.organizationForm.employees.searchAccountManager')"
                    class="organization-form__multiselect"
                    searchable
                    image
                >
                    <PersonInner slot="button" slot-scope="buttonScope" mode="button" :scope="buttonScope" />
                    <PersonInner slot="option" slot-scope="optionScope" mode="option" :scope="optionScope" />
                </MultiselectBox>
            </div>

            <div class="span-2 span-sm-2">
                <SelectBoxV3
                    v-if="organization"
                    v-model="organization.keyAccount"
                    :options="keyAccountList"
                    :option-value-renderer="option => option.value"
                    :option-label-renderer="option => option.label || 'test'"
                    :label="$t('components.organizationForm.keyAccountLabel')"
                />
            </div>
        </GridRow>

        <GridRow class="organization-form__fieldset" :count="2">
            <Words block bold small spaced-bottom class="span-2">
                {{ $t('components.organizationForm.constructionProjectSettings.headline') }}
            </Words>

            <div class="span-2 span-sm-1">
                <ToggleSwitchField
                    v-model="organization.costcenterMandatory"
                    name="organization-costcenter-mandatory-switch"
                    class="organization-form__costcenter-mandatory-input-switch"
                >
                    {{
                        organization.costcenterMandatory
                            ? $t('components.organizationForm.constructionProjectSettings.costcenterMandatory')
                            : $t('components.organizationForm.constructionProjectSettings.costcenterOptional')
                    }}
                </ToggleSwitchField>
            </div>
        </GridRow>

        <GridRow class="organization-form__fieldset" :count="2">
            <Words block bold small spaced-bottom class="span-2">
                {{ $t('components.organizationForm.organizationLock.headline') }}
            </Words>

            <div class="span-2 span-sm-1">
                <ToggleSwitchField
                    :value="!organization.isActive"
                    name="organization-lock-switch"
                    class="organization-form__time-input-switch"
                    @input="value => (organization.isActive = !value)"
                >
                    {{
                        !organization.isActive
                            ? $t('components.organizationForm.organizationLock.lockActive')
                            : $t('components.organizationForm.organizationLock.lockInactive')
                    }}
                </ToggleSwitchField>
            </div>

            <div class="span-2 span-sm-1">
                <ToggleSwitchField
                    v-model="organization.isBlocked"
                    name="credit-block-switch"
                    class="organization-form__time-input-switch"
                    :disabled="!editBlockOrdersForNewOrganizationsEnabled"
                >
                    {{
                        organization.isBlocked
                            ? $t('components.organizationForm.organizationLock.organizationBlocked')
                            : $t('components.organizationForm.organizationLock.organizationNotBlocked')
                    }}
                </ToggleSwitchField>
            </div>
        </GridRow>

        <div
            v-if="!isNewOrganization && $can('deleteOrganization', organization)"
            class="organization-form__fieldset organization-form__fieldset--delete"
        >
            <BaseButton data-test="company-settings-modal-open-delete-organization" @click="deleteOrganization()">
                <Words>{{ $t('pages.organization.form.deleteOrganization') }}</Words>
            </BaseButton>
        </div>

        <div class="organization-form__settings">
            <Words block>
                {{ $t('pages.organization.form.settingsDescription') }}
            </Words>

            <Alert type="error" icon-type="info" primary colorized class="feature-manager__type-hint">
                {{ $t('pages.organization.form.hintNoRevertingPossible') }}
            </Alert>

            <FeatureManager
                v-if="definition && organization"
                :definition="definition"
                :types="organization.types"
                :original-types="originalOrganization.types"
                :original-features="originalOrganization.features"
                :features="organization.features"
                @input-types="organization.types = $event"
                @input-features="organization.features = $event"
            />

            <ReactBridge
                v-if="market.code === 'DE'"
                :react-component="PlatformActionSection"
                :props="{
                    allPlatformActionCodes: allPlatformActions,
                    organizationPlatformActionCodes: organization.platformActionCodes,
                }"
            />
        </div>
    </div>
</template>

<script>
import ReactBridge from '@/reactBridge/ReactBridge.vue';
import { ensureJSTimestamp } from '@/services/utils/date';
import { useEditBlockOrdersForNewOrganizationsEnabled } from '@/services/FeatureFlags/useFeatureFlags';
import { ACTIONS } from '@/constants/actions';

import Alert from '@/components/Alert';
import BaseButton from '@/components/Button/Button';
import FeatureManager from '../components/FeatureManager';
import GridRow from '@/components/Layout/GridRow';
import MultiselectBox from '@/components/Form/MultiselectBox';
import PersonInner from '@/components/Form/MultiselectBox/PersonInner';
import SelectBoxV3 from '@/components/Form/SelectBox.v3';
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import Words from '@/components/Typography/Words';
import PlatformActionSection from '@/pages/Organization/components/PlatformActionSection';

import { useNamespacedGetters } from 'vuex-composition-helpers';

export default {
    name: 'TabSettings',

    components: {
        ReactBridge,
        Alert,
        BaseButton,
        FeatureManager,
        GridRow,
        MultiselectBox,
        PersonInner,
        SelectBoxV3,
        ToggleSwitchField,
        Words,
    },

    props: {
        organization: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        originalOrganization: {
            type: Object,
            default: () => ({}),
        },
        definition: {
            type: Object,
            default: null,
        },
        isNewOrganization: {
            type: Boolean,
            default: false,
        },
        availableEmployees: {
            type: Array,
            default: () => [],
        },
        keyAccountList: {
            type: Array,
            default: () => [],
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },

    setup() {
        const { market } = useNamespacedGetters('user', ['market']);
        const { allPlatformActions } = useNamespacedGetters('platform', ['allPlatformActions']);

        return {
            editBlockOrdersForNewOrganizationsEnabled: useEditBlockOrdersForNewOrganizationsEnabled(),
            market,
            allPlatformActions,
        };
    },

    data() {
        return {
            PlatformActionSection,
        };
    },

    methods: {
        ensureJSTimestamp,

        deleteOrganization() {
            if (!this.$can('deleteOrganization', this.organization)) return;
            this.$store.dispatch('globalActions/set', {
                action: ACTIONS.ORGANIZATION_DELETE,
                subject: this.organization,
            });
        },
    },
};
</script>

<style lang="scss" scoped src="./TabSettings.scss" />

<template>
    <div v-if="price || unitPrice" class="font-copy-md">
        <span v-if="isPlatform" class="text-xs">
            {{ $t('pages.transportHub.platform.totalRetailPrice') }}
        </span>
        <span v-if="price" class="whitespace-nowrap font-bold">{{ $n(price, 'currency') }}</span>
        <span v-if="unitPrice" class="font-copy-xs whitespace-nowrap">
            <template v-if="transport.billingMethod === 'fixedPrice'">
                {{ $t('components.transportListBlock.shipmentFixedPriceLabel') }}
            </template>
            <template v-else> {{ $n(unitPrice, 'currency') }} / {{ $t('units.ton') }} </template>
        </span>
    </div>
</template>

<script>
import routeContext from '@/plugins/mixins/routeContext';
import { CONTEXT_PLATFORM, CONTEXT_CLIENT, CONTEXT_SUPPLIER } from '@/constants/context';

import { TRANSPORT_TYPE } from '@/constants/transportTypes';

/**
 * !!! ATTENTION !!!
 * This component and all of it's children can not have computed
 * properties since they introduce a performance issues on unmounting
 */
export default {
    name: 'TransportListBlockPrice',
    mixins: [routeContext],
    props: {
        transport: {
            type: Object,
            required: true,
        },
        context: {
            type: String,
            default: 'client',
        },
    },
    data() {
        const { transport, context } = this;

        const isPlatform = this.inRouteContext(CONTEXT_PLATFORM) || context === CONTEXT_PLATFORM;
        const isClient = this.inRouteContext(CONTEXT_CLIENT) || context === CONTEXT_CLIENT;
        const isSupplier = this.inRouteContext(CONTEXT_SUPPLIER) || context === CONTEXT_SUPPLIER;

        const type = transport.type;

        const price = (() => {
            if (!this.$can('seePrices')) return null;

            if (isSupplier) {
                return transport.materialPurchasePrice;
            }

            const totalRetailPrice =
                transport.invoicePrice !== undefined ? transport.invoicePrice : transport.totalRetailPrice;

            const materialRetailPrice =
                transport.invoicePrice !== undefined ? transport.invoicePrice : transport.materialRetailPrice;

            switch (type) {
                case TRANSPORT_TYPE.SHIPMENT:
                    return isPlatform || isClient
                        ? totalRetailPrice
                        : transport.transportPurchasePrice +
                              (transport.carrierPurchasePrice ?? transport.shippingPurchasePrice);
                case TRANSPORT_TYPE.DELIVERY:
                    return isPlatform || isClient
                        ? totalRetailPrice
                        : transport.transportPurchasePrice + transport.shippingPurchasePrice;
                case TRANSPORT_TYPE.PICKUP:
                    return isPlatform || isClient ? materialRetailPrice : transport.materialPurchasePrice;
                case TRANSPORT_TYPE.DISPOSAL:
                    return isPlatform || isClient
                        ? totalRetailPrice
                        : isSupplier
                        ? transport.materialPurchasePrice
                        : transport.transportPurchasePrice;
                default:
                    return null;
            }
        })();

        const unitPrice = (() => {
            if (!this.$can('seePrices')) return null;

            if (isSupplier) {
                return null;
            }

            switch (type) {
                case TRANSPORT_TYPE.SHIPMENT:
                    return isPlatform || isClient
                        ? transport.transportRetailUnitPrice * 1000
                        : transport.transportPurchaseUnitPrice * 1000;
                case TRANSPORT_TYPE.DELIVERY:
                    return isPlatform || isClient
                        ? (transport.totalRetailPrice * 1000) / transport.qty
                        : (transport.transportPurchasePrice * 1000) / transport.qty;
                case TRANSPORT_TYPE.PICKUP:
                    return isPlatform || isClient
                        ? (transport.materialRetailPrice * 1000) / transport.qty
                        : (transport.materialPurchasePrice * 1000) / transport.qty;
                case TRANSPORT_TYPE.DISPOSAL:
                    return isPlatform || isClient
                        ? transport.totalRetailPricePerTon
                        : transport.transportPurchasePricePerTon;
                default:
                    return null;
            }
        })();

        return {
            type,
            isPlatform,
            isClient,
            isSupplier,
            price,
            unitPrice,
        };
    },
};
</script>

<style lang="scss"></style>

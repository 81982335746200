import { forwardRef } from 'react';
import clsx from 'clsx';
// TODO extract to component library, this is just temporary until icon PR is merged
export const TagIcon = forwardRef(({ className, ...svgProps }, ref) => {
    const iconSize = '16';
    return (<svg width={iconSize} height={iconSize} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} className={clsx('stroke-icon', className)} {...svgProps}>
            <path d="m19.003 9.828 2.591 1.061-.568 1.388" strokeWidth={2} strokeMiterlimit={10}/>
            <path d="m20.454 13.675-1.66 4.053" strokeWidth={2} strokeMiterlimit={10} strokeDasharray="3.62 1.51"/>
            <path d="m18.509 18.427-.568 1.388-1.389-.568" strokeWidth={2} strokeMiterlimit={10}/>
            <path d="m15.297 18.734-7.912-3.238" strokeWidth={2} strokeMiterlimit={10} strokeDasharray="3.26 1.36"/>
            <path d="m6.757 15.239-1.388-.568-.493-1.417" strokeWidth={2} strokeMiterlimit={10}/>
            <path d="m4.574 12.382-.879-2.53" strokeWidth={2} strokeMiterlimit={10} strokeDasharray="2.22 0.92"/>
            <path d="M3.543 9.417 3.051 8M19 12H6.815L3 8l3.815-4H19v8Z" strokeWidth={2} strokeMiterlimit={10}/>
            <path d="M7.502 8A.5.5 0 1 1 6.5 8a.5.5 0 0 1 1.002 0Z"/>
        </svg>);
});
TagIcon.displayName = 'TagIcon';

<template>
    <LayoutPage
        class="project-list-page"
        screen-name="client-constructionproject-documents"
        :back-title="backTitle"
        :parent-route="parentRoute"
    >
        <template #pageTitle>
            {{ $t('pages.documents.title') }}
        </template>
        <template #mobileHeader>
            <HeaderBar>
                <template #headline>{{ $t('pages.documents.title') }}</template>
                <template #left>
                    <HeaderBarItem v-if="parentRoute" button @click="$router.push(parentRoute)">
                        <SfSysArrowLeftIcon size="xs" :multi-color="true" />
                    </HeaderBarItem>
                </template>
                <template #right>
                    <HeaderBarItem v-if="!$root.isDesktop">
                        <FilterBox
                            v-model="documentListing.filter"
                            screen-name="client-constructionproject-documents-filter"
                            :endpoint="documentListing.endpoint"
                            :default-filter="documentListing.defaultFilter"
                        >
                            <template #default="{ filter: filterFromSlot, updateFilter }">
                                <ProjectDocumentFilterSet
                                    :endpoint="documentListing.endpoint"
                                    :filter-scope="{ filter: filterFromSlot }"
                                    :whitelisted-fields="whitelistedFields"
                                    :document-type-options="documentTypeOptions"
                                    :status-options="statusOptions"
                                    @updateFilter="updateFilter"
                                />
                            </template>
                        </FilterBox>
                    </HeaderBarItem>
                    <HeaderBarItem v-if="!$root.isDesktop">
                        <FilterSort
                            :sort-by="documentListing.filter.sortBy"
                            :sort-direction="documentListing.filter.sortDirection"
                            :sort-options="documentListing.sortOptions"
                            :option-label-renderer="value => $t(`pages.invoice.sortings.${value}`)"
                            @sortByUpdate="documentListing.filter.sortBy = $event"
                            @sortDirectionUpdate="documentListing.filter.sortDirection = $event"
                            @updatedSorting="documentListing.refreshList(false, true, true)"
                        /> </HeaderBarItem
                ></template>
            </HeaderBar>
        </template>

        <CardComponent class="mt-6">
            <FilterBox
                v-model="documentListing.filter"
                :default-filter="documentListing.defaultFilter"
                :endpoint="documentListing.endpoint"
                inline-mode
                no-padding
                @update="documentListing.refreshList(false, true, true)"
            >
                <template #default="filterScope">
                    <GridRow v-if="$root.isDesktop" :count="12">
                        <TextField
                            v-model="filterScope.filter.search"
                            :label="$t('pages.projects.documents.filter.searchLabel')"
                            class="span-6"
                        >
                            <template #icon>
                                <SearchIcon />
                            </template>
                        </TextField>

                        <MultiselectBox
                            v-model="filterScope.filter.documentType"
                            :options="documentTypeOptions"
                            :option-value-renderer="option => option"
                            :label="$t('pages.projects.documents.type.label')"
                            multiple
                            class="span-4"
                        >
                            <template #button="buttonScope">
                                <DefaultInner
                                    mode="button"
                                    :scope="buttonScope"
                                    :label-renderer="option => $t(getDocumentTypeTranslationKey(option))"
                                />
                            </template>
                            <template #option="optionScope">
                                <DefaultInner
                                    mode="option"
                                    :scope="optionScope"
                                    :label-renderer="option => $t(getDocumentTypeTranslationKey(optionScope.option))"
                                />
                            </template>
                        </MultiselectBox>

                        <SelectBox
                            v-model="filterScope.filter.status"
                            class="span-2"
                            :label="$t('pages.invoice.filter.statusHeadline')"
                            :options="statusOptions"
                            :option-value-renderer="option => option"
                            :option-label-renderer="
                                option =>
                                    option == null
                                        ? $t('pages.invoice.filter.statusAllLabel')
                                        : $t(`pages.projects.documents.status.${option}`)
                            "
                        />
                    </GridRow>
                    <FilterSortPagination
                        :result="documentListing.result"
                        :filter="filterScope.filter"
                        :sort-options="documentListing.sortOptions"
                        :option-label-renderer="value => $t(`pages.invoice.sortings.${value}`)"
                        :hide-sort="!$root.isDesktop"
                        show-refresh
                        spaceless
                        :loading="documentListing.isLoading"
                        @refresh="documentListing.refreshList(false, true, true)"
                        @pageNumberUpdated="documentListing.updatePageNumber($event)"
                    >
                        <FilterBox
                            v-if="$root.isDesktop"
                            v-model="documentListing.filter"
                            :default-filter="documentListing.defaultFilter"
                            :endpoint="documentListing.endpoint"
                            :headline="$t('pages.invoice.filterTitle')"
                            :button-label="$t(`pages.projects.documents.filterLabel`)"
                            :ignore-in-count="['status', 'documentType', 'search']"
                            @save="documentListing.refreshList(false, true, true)"
                        >
                            <template #default="{ filter: filterFromSlot, updateFilter }">
                                <ProjectDocumentFilterSet
                                    :endpoint="documentListing.endpoint"
                                    :filter-scope="{ filter: filterFromSlot }"
                                    :whitelisted-fields="whitelistedFields"
                                    @updateFilter="updateFilter"
                                />
                            </template>
                        </FilterBox>
                    </FilterSortPagination>
                </template>
            </FilterBox>
        </CardComponent>

        <LoadingSpinner v-if="documentListing.isLoading" block dark />
        <div v-else>
            <div class="mb-4 mt-8 hidden justify-end md:flex">
                <SfButton v-if="documentListing.resultCount > 0" type="primary" size="sm" @click="downloadAllDocuments">
                    {{ $t('pages.projects.documents.downloadAllLabel') }}
                    <template #trailing-icon="iconProps">
                        <SfDownloadIcon v-bind="iconProps" />
                    </template>
                </SfButton>
            </div>

            <CardComponent v-for="item in documentListing.resultItems" :key="item.id" spaceless-y no-padding>
                <GridRow
                    spacing="base"
                    align="start"
                    margin-less
                    class="relative grid-cols-1 gap-4 border-b p-4 md:grid-cols-[5fr_3fr_4fr] md:gap-6"
                >
                    <div class="md:gap-3 flex items-start justify-items-start pr-6 md:grid md:grid-cols-[20px_2fr_1fr]">
                        <SfPdfIcon size="xs" class="hidden min-h-6 min-w-6 md:block" />
                        <div class="mr-4 md:mr-0">
                            <div class="font-copy-md-strong">
                                {{ $t(getDocumentTypeTranslationKey(item.type)) }} | Nr {{ item.number }}
                            </div>
                            <div class="text-light font-copy-sm mt-1 text-subdued">
                                {{ getDocumentDescriptionLabel(item) }}
                            </div>
                        </div>

                        <SfTag
                            v-if="item.status"
                            :type="
                                item.status === 'created' || item.status === 'paid'
                                    ? 'positive'
                                    : item.status === 'cancelled'
                                    ? 'default'
                                    : 'warning'
                            "
                        >
                            {{ $t(`pages.projects.documents.status.${item.status}`) }}
                        </SfTag>
                    </div>

                    <div>
                        <div v-if="item.projectPosition" class="font-copy-md">
                            {{ item.projectPosition.number }}
                        </div>
                        <div class="font-copy-sm mt-1 text-subdued">{{ item.recipient.name }}</div>
                    </div>
                    <div class="md:text-center">
                        <div class="font-copy-md">
                            {{ $d(ensureJSTimestamp(item.created), 'short') }}
                        </div>
                    </div>
                    <div class="absolute right-4 top-4 md:top-[calc(50%-12px)] md:top-[calc(50%-12px)]">
                        <BaseButton @click="downloadSingleDocument(item)">
                            <SfDownloadIcon size="xs" :multi-color="true"
                        /></BaseButton>
                    </div>
                </GridRow>
            </CardComponent>
        </div>
        <HintComponent v-if="documentListing.resultCount === 0" center transparent>
            {{ $t('pages.documents.noResults') }}
        </HintComponent>
    </LayoutPage>
</template>

<script>
import FilterableListing from '@/services/FilterableListing/FilterableListing';
import ConstructionProjectDocumentsApi from '@/services/Api/ConstructionProjectDocuments';
import { ensureJSTimestamp } from '@/services/utils/date';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { format } from 'date-fns';

import BaseButton from '@/components/Button/Button';
import CardComponent from '@/components/Layout/Card';
import DefaultInner from '@/components/Form/MultiselectBox/DefaultInner.v2';
import FilterBox from '@/components/Filter/FilterBox';
import FilterSort from '@/components/Filter/FilterSort';
import FilterSortPagination from '@/components/Filter/FilterSortPagination';
import GridRow from '@/components/Layout/GridRow';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import HintComponent from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import MultiselectBox from '@/components/Form/MultiselectBox';
import ProjectDocumentFilterSet from '@/pages/Project/components/ProjectDocumentFilterSet';
import SearchIcon from '@/assets/icons/micro/search.svg';
import SelectBox from '@/components/Form/SelectBox.v3';
import TextField from '@/components/Form/TextField.v2';
import { SfDownloadIcon, SfPdfIcon, SfButton, SfTag, SfSysArrowLeftIcon } from '@schuettflix/vue-components';
import { getConstructionProjectById } from '@/constructionProjects/api/projects';

export default {
    name: 'ConstructionProjectDocumentListPage',
    components: {
        BaseButton,
        CardComponent,
        DefaultInner,
        FilterBox,
        FilterSort,
        FilterSortPagination,
        GridRow,
        HeaderBar,
        HeaderBarItem,
        HintComponent,
        LayoutPage,
        MultiselectBox,
        ProjectDocumentFilterSet,
        SelectBox,
        SfButton,
        SfTag,
        TextField,

        LoadingSpinner,
        SearchIcon,
        SfDownloadIcon,
        SfPdfIcon,
        SfSysArrowLeftIcon,
    },
    props: {
        parentRoute: {
            type: String,
            default: null,
        },
    },

    setup() {
        const { selectCreditNoteDocKey } = useLegalTerms();
        return {
            selectCreditNoteDocKey,
        };
    },
    data() {
        const documentListing = new FilterableListing({
            namespace: 'documents',
            endpoint: new ConstructionProjectDocumentsApi(this.$route.params.id),
            defaultFilter: {
                page: 1,
                perPage: 25,
                search: '',
                status: null,
                documentType: null,
                sortDirection: 'desc',
            },
            sortOptions: ['created'],
        });

        return {
            documentListing,
            project: null,
        };
    },
    computed: {
        documentTypeOptions() {
            return this.changeableDocumentTypeOptions ?? [];
        },

        changeableDocumentTypeOptions() {
            const options = this.documentListing.endpoint.supportedFilters.documentType?.options;
            return options ? Object.keys(this.documentListing.endpoint.supportedFilters.documentType.options) : [];
        },

        statusOptions() {
            const options = this.changeableStatusOptions ?? [];
            return { all: null, ...options };
        },

        changeableStatusOptions() {
            return this.documentListing.result?.supportedFilters?.status?.options ?? [];
        },

        whitelistedFields() {
            const mobile = this.$root.isDesktop ? [] : ['search', 'documentType', 'status'];

            return [...mobile, 'projectPosition', 'createdFrom', 'createdTo', 'status', 'sortDirection'];
        },

        downloadUrl() {
            return this.documentListing.result?.responseData?.downloadUrl;
        },

        backTitle() {
            return this.project?.name
                ? `${this.$t('pages.checkout.costCenters.types.project')} ${this.project.name}`
                : 'P...';
        },
    },
    watch: {
        $route() {
            this.documentListing.onRouteChanged();
        },
    },
    created() {
        this.documentListing.pairComponent(this);
        this.documentListing.refreshList(true);
        this.refreshProject();
    },
    methods: {
        ensureJSTimestamp,
        getDocumentDescriptionLabel(item) {
            if (item.type === 'delivery-note') {
                return `${this.$t('pages.projects.documents.transportNumberLabel')}  ${item.transport?.number}`;
            } else {
                return item.order?.number
                    ? `${this.$t('pages.projects.documents.orderNumberLabel')}  ${item.order?.number}`
                    : '';
            }
        },

        getDocumentTypeTranslationKey(type) {
            if (type === 'credit-note') {
                return this.selectCreditNoteDocKey({
                    creditNote: 'pages.projects.documents.type.creditNote',
                    selfBilling: 'pages.projects.documents.type.selfBilling',
                });
            }
            return `pages.projects.documents.type.${type}`;
        },

        async refreshProject() {
            this.project = await getConstructionProjectById(this.$route.params.id);
        },

        downloadSingleDocument(item) {
            window.location.href = item.document.url;
            AnalyticsService.trackEvent('construction_project', {
                step: 'project_document_list_download',
                fileExtension: 'pdf',
                fileName: item.document.fileName,
                linkUrl: item.document.url,
            });
        },

        downloadAllDocuments() {
            window.location.href = this.downloadUrl;
            AnalyticsService.trackEvent('construction_project', {
                step: 'project_document_list_download',
                fileExtension: 'zip',
                linkUrl: this.downloadUrl,
                fileName: this.project.number + '_' + format(new Date(), 'DD.MM.YYYY') + '.zip',
            });
        },
    },
};
</script>

<template>
    <div class="supplier-rating-feedback-form">
        <Tile>
            <StarRating
                v-model="material"
                :count="3"
                :label="$t('components.supplierRatingFeedbackForm.fields.material')"
                @input="clearValidation()"
            />
        </Tile>
        <Tile>
            <StarRating
                v-model="speed"
                :count="3"
                :label="$t('components.supplierRatingFeedbackForm.fields.speed')"
                @input="clearValidation()"
            />
        </Tile>
        <Tile>
            <StarRating
                v-model="friendliness"
                :count="3"
                :label="$t('components.supplierRatingFeedbackForm.fields.friendliness')"
                @input="clearValidation()"
            />
        </Tile>
        <Tile no-border>
            <Words block bold small>{{ $t('components.supplierRatingFeedbackForm.fields.commentLabel') }}</Words>
        </Tile>
        <TextField
            v-model="message"
            :label="$t('components.supplierRatingFeedbackForm.fields.comment')"
            type="textarea"
        />

        <ErrorMessage v-if="error" :message="error" />
    </div>
</template>

<script>
import TransportApi from '@/services/Api/Transport';

import ErrorMessage from '@/components/Form/ErrorMessage';
import StarRating from '@/components/Form/StarRating';
import TextField from '@/components/Form/TextField.v2';
import Tile from '@/components/Layout/Tile';
import Words from '@/components/Typography/Words';
import statefulMixin from '@/plugins/mixins/statefulMixin';

export default {
    name: 'SupplierRatingFeedbackForm',
    components: {
        ErrorMessage,
        StarRating,
        Tile,
        TextField,
        Words,
    },
    mixins: [statefulMixin],
    props: {
        transportId: {
            type: [Number, String],
            required: true,
        },
        context: {
            type: String,
            default: 'client',
        },
    },
    data() {
        return {
            material: 3,
            speed: 3,
            friendliness: 3,
            message: null,
            error: null,
        };
    },
    methods: {
        isValid() {
            const valid = [this.material, this.speed, this.friendliness].some(o => o > 0);
            if (!valid) {
                this.error = this.$t('components.supplierRatingFeedbackForm.validation.ratingRequired');
            } else {
                this.error = null;
            }
            return valid;
        },

        clearValidation() {
            this.error = false;
        },

        reset() {
            this.material = 3;
            this.speed = 3;
            this.friendliness = 3;
            this.message = null;
        },

        async submit() {
            return this.stateful('save', async () => {
                if (!this.isValid()) {
                    return false;
                }

                const transportApi = new TransportApi(this.context);

                const transport = await transportApi.submitFeedback(this.transportId, {
                    material: this.material || null,
                    speed: this.speed || null,
                    friendliness: this.friendliness || null,
                    message: this.message,
                });
                this.$store.commit('transportActions/updateTransport', transport);

                this.reset();
                return true;
            });
        },
    },
};
</script>

<style lang="scss"></style>

import TransportApi from '@/services/Api/Transport';
import { computed, ref, unref, watch } from 'vue';
import i18n from '@/i18n';
import { revertLocalizedValue } from '@/services/utils/localization';
import tons from '@/plugins/filters/tons';
import WeighingApi from '@/services/Api/Weighing';
import { TRANSPORT_TYPE_PICKUP } from '@/constants/transportTypes';
import { weighingMethods } from '@/constants/weighingMethods';
import { convert_kg_to_cbm } from '@/services/utils/units';
import { useAsyncState } from '@vueuse/core';
/**
 * Interact with a single transport
 */
export function useTransport(context, transportId) {
    const transportApi = new TransportApi(context);
    // TODO: find proper type structure for transports
    const transport = ref(null);
    const error = ref(null);
    const refresh = async () => {
        try {
            transport.value = await transportApi.getOneById(transportId);
        }
        catch (err) {
            error.value = err;
        }
    };
    refresh();
    return {
        transport,
        refresh,
        error,
    };
}
/**
 * Detect changes of a reference and reset its detection with a debounced delay
 */
export function useChanged(target, resetDuration = 500) {
    const hasChanged = ref(false);
    let timeoutId = null;
    watch(() => target.value, () => {
        hasChanged.value = true;
        if (timeoutId) {
            clearTimeout(timeoutId);
            timeoutId = null;
        }
        timeoutId = setTimeout(() => {
            hasChanged.value = false;
        }, resetDuration);
    });
    return hasChanged;
}
/**
 * Format proper headline and subline for the weighing pages
 */
export function useLoadingTitle(transport) {
    const headline = computed(() => {
        const number = transport?.number;
        return i18n.t('pages.transport.weighing.title', { number });
    });
    const subline = computed(() => {
        const productName = revertLocalizedValue(transport?.product?.productName) ?? null;
        const qty = transport?.qty;
        if (!productName)
            return null;
        return `${tons(qty)} ${productName}`;
    });
    return {
        headline,
        subline,
    };
}
/**
 * Handle weighing configurations
 */
export function useWeighingConfiguration({ immediate = false } = {}) {
    let hasExecutedOnce = false;
    const { state: configuration, execute } = useAsyncState(async () => {
        hasExecutedOnce = true;
        return WeighingApi.getConfiguration();
    }, { maximumShovelCount: 0 }, {
        immediate,
    });
    const executeOnce = () => {
        if (!hasExecutedOnce)
            execute();
    };
    return {
        configuration,
        execute,
        executeOnce,
    };
}
/**
 * Check if the loaded transport has incorrect loading
 */
export function hasIncorrectLoading(transport) {
    if (transport.type === TRANSPORT_TYPE_PICKUP) {
        return false;
    }
    const { totalLoading = 0, weighingBuffer = 0, qty } = transport;
    return totalLoading < qty * (1 - weighingBuffer) || totalLoading > qty * (1 + weighingBuffer);
}
/**
 * Properly format CBM computed/reactive value
 */
export const visualizeCBM = (cbm) => {
    return () => {
        const value = unref(cbm);
        if (!value) {
            return '-';
        }
        return `${i18n.n(value, 'float')} ${i18n.t('units.cbm')}`;
    };
};
/**
 * Properly format KG computed/reactive value
 */
export const visualizeKG = (kg) => {
    return () => {
        const value = unref(kg);
        if (!value) {
            return '-';
        }
        return `${i18n.n(value, 'float')} ${i18n.t('units.kilogram')}`;
    };
};
/**
 * Handle weighing loading warnings for all weighing types
 */
export function useWeighingLoadingWarning(transport, net, ordered) {
    const weighingBufferFactor = transport.weighingBuffer ?? null;
    const inCMB = transport.weighingMethod === weighingMethods.CBM;
    const diff = computed(() => {
        if (ordered === null || net.value === null)
            return null;
        return Math.abs(ordered - net.value);
    });
    const diffCBM = computed(() => {
        if (!inCMB || !diff.value)
            return null;
        return convert_kg_to_cbm(diff.value, transport.product.density);
    });
    const diffVisual = computed(visualizeKG(diff));
    const diffCBMVisual = computed(visualizeCBM(diffCBM));
    return computed(() => {
        if (net.value === null || weighingBufferFactor === null) {
            return null;
        }
        const buffer = ordered * weighingBufferFactor;
        let visualValue = diffVisual.value;
        if (inCMB) {
            visualValue = `${diffCBMVisual.value} (${diffVisual.value})`;
        }
        if (net.value < ordered - buffer) {
            return i18n.t('pages.transport.weighing.tooLittleError', { value: visualValue });
        }
        if (net.value > ordered + buffer) {
            return i18n.t('pages.transport.weighing.tooMuchError', { value: visualValue });
        }
        return null;
    });
}

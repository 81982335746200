<template>
    <LayoutPage is-flyout class="checkout-project-position-type-selection-page">
        <Header slot="flyoutHeader" />

        <div class="container-off-canvas-sm my-6">
            <div class="card card--selected p-4">
                <p class="font-copy-md-strong mb-2">{{ product.wasteCode }}<span v-if="product.isDangerous">*</span></p>
                <p class="font-copy-sm">
                    {{ revertLocalizedValue(product.productName) }}
                </p>
            </div>

            <!-- PRODUCT VARIANTS -->
            <hr class="my-12 border-t-0 border-solid border-divider" />
            <section>
                <p class="font-copy-md-strong mb-2">
                    {{ $t('pages.checkout.projectPositionDisposal.pages.wasteDetails.availableProductVariants.title') }}
                </p>
                <p class="font-copy-md mb-6">
                    {{
                        $t(
                            'pages.checkout.projectPositionDisposal.pages.wasteDetails.availableProductVariants.description'
                        )
                    }}
                </p>

                <LoadingCard v-if="!getProductVariants.length" />
                <template v-for="(variant, i) in getProductVariants">
                    <button
                        v-if="variant.isAvailable"
                        :key="variant.id"
                        class="card card--hovered card--focussed w-full text-left"
                        :class="[
                            {
                                'card--selected': isSelectedProductVariant(variant),
                                'mt-4': i > 0,
                            },
                        ]"
                        :data-test="`project-position-waste-details-product-variant-${variant.id}`"
                        @click="selectProductVariant(variant)"
                        @keyup.enter="selectProductVariant(variant)"
                    >
                        <p
                            class="my-0 flex flex-wrap items-center md:flex-nowrap"
                            data-test="contamination-level-selection-item"
                            :class="[{ 'mb-4': variant.note || variant.description }]"
                        >
                            <!-- POLLUTION TYPE NAME -->
                            <span class="font-copy-md-strong w-full md:w-auto">
                                {{ variant.pollutionType }}
                            </span>
                            <!-- WASTE CAPACITY -->
                            <span v-if="variant.wasteCapacityPerDay" class="font-copy-sm md:ml-4">
                                {{
                                    $t(
                                        'pages.checkout.projectPositionDisposal.pages.wasteDetails.availableProductVariants.tonsPerDay',
                                        { tons: variant.wasteCapacityPerDay }
                                    )
                                }}
                            </span>

                            <!-- PRICE -->
                            <span
                                class="ml-2 flex-1 justify-self-end text-right"
                                :class="[
                                    {
                                        'font-copy-sm-strong': priceInformationExists(variant.price),
                                        'font-copy-sm text-subdued': !priceInformationExists(variant.price),
                                    },
                                ]"
                            >
                                {{
                                    priceInformationExists(variant.price)
                                        ? `${$n(variant.price, 'currency')} / ${$t('units.ton')}`
                                        : $t('pages.products.productsList.supplier.noPrice')
                                }}
                            </span>
                        </p>

                        <template v-if="variant.note">
                            <p class="font-copy-sm my-0 text-subdued">
                                {{
                                    $t(
                                        'pages.checkout.projectPositionDisposal.pages.wasteDetails.availableProductVariants.note'
                                    )
                                }}
                            </p>

                            <ShowMore :lines="2">
                                <p class="font-copy-md mb-0 mt-2">
                                    {{ variant.note }}
                                </p>
                            </ShowMore>
                        </template>
                    </button>
                </template>
                <ErrorMessage
                    v-if="hasErrors('requestCollection.productVariant.id')"
                    :message="getError('requestCollection.productVariant.id')"
                />
            </section>
            <input
                type="hidden"
                readonly
                name="requestCollection.factoryProductVariantId"
                :value="selectedProductVariant"
            />

            <!-- PRETREATMENT -->
            <template v-if="isProductVariantSelected">
                <section class="mt-12">
                    <p class="font-copy-md-strong mb-2">
                        {{ $t('pages.checkout.projectPositionDisposal.pages.wasteDetails.pretreatment.title') }}
                    </p>
                    <p class="font-copy-md mb-6">
                        {{ $t('pages.checkout.projectPositionDisposal.pages.wasteDetails.pretreatment.description') }}
                    </p>
                    <div class="-mb-6 flex flex-wrap">
                        <RadioField
                            v-model="requestCollection.isPretreated"
                            :option="true"
                            name="requestCollection.isPretreated"
                            :error="!!getError('requestCollection.isPretreated')"
                            data-test="project-position-waste-details-pretreatment-yes-radio"
                            small
                            class="mb-6 mr-16"
                        >
                            <span class="font-copy-md-strong">{{ $t('general.yes') }}</span>
                        </RadioField>

                        <RadioField
                            v-model="requestCollection.isPretreated"
                            :option="false"
                            name="requestCollection.isPretreated"
                            :error="!!getError('requestCollection.isPretreated')"
                            data-test="project-position-waste-details-pretreatment-no-radio"
                            small
                            class="mb-6"
                        >
                            <span class="font-copy-md-strong">{{ $t('general.no') }}</span>
                        </RadioField>
                    </div>
                    <ErrorMessage
                        v-if="hasErrors('requestCollection.isPretreated')"
                        :message="getError('requestCollection.isPretreated')"
                    />
                    <TextField
                        v-show="isPretreated"
                        v-model="requestCollection.pretreatmentDescription"
                        :label="
                            $t('pages.checkout.projectPositionDisposal.pages.wasteDetails.pretreatment.placeholder')
                        "
                        :error="getError('requestCollection.pretreatmentDescription')"
                        data-test="project-position-waste-details-is-pretreatment"
                        class="mt-4"
                        name="requestCollection.pretreatmentDescription"
                    />
                </section>
            </template>

            <!-- CONSISTENCY -->
            <template v-if="isPretreatmentSelected">
                <section class="mt-12">
                    <p class="font-copy-md-strong mb-2">
                        {{ $t('pages.checkout.projectPositionDisposal.pages.wasteDetails.consistency.title') }}
                    </p>
                    <p class="font-copy-md mb-6">
                        {{ $t('pages.checkout.projectPositionDisposal.pages.wasteDetails.consistency.description') }}
                    </p>
                    <div class="-mb-6 flex flex-wrap">
                        <RadioField
                            v-for="consistency in ['firm', 'semi_solid', 'muddy', 'dusty', 'liquid']"
                            :key="consistency"
                            v-model="requestCollection.consistency"
                            :option="consistency"
                            name="requestCollection.consistency"
                            :error="!!getError('requestCollection.consistency')"
                            :data-test="`project-position-waste-details-pretreatment-radio-${consistency}`"
                            small
                            class="mb-6 mr-16"
                        >
                            <span class="font-copy-md-strong">
                                {{
                                    $t(
                                        `pages.checkout.projectPositionDisposal.pages.wasteDetails.consistency.radios.${consistency}`
                                    )
                                }}
                            </span>
                        </RadioField>
                    </div>
                    <ErrorMessage
                        v-if="hasErrors('requestCollection.consistency')"
                        :message="getError('requestCollection.consistency')"
                    />
                </section>
            </template>

            <!-- ADDITIONAL INTERNAL TITLE -->
            <template v-if="isConsistencySelected">
                <section class="mt-12">
                    <p class="font-copy-md-strong mb-2">
                        {{
                            $t(
                                'pages.checkout.projectPositionDisposal.pages.wasteDetails.additionalInternalTitle.title'
                            )
                        }}
                    </p>
                    <p class="font-copy-md mb-6">
                        {{
                            $t(
                                'pages.checkout.projectPositionDisposal.pages.wasteDetails.additionalInternalTitle.description'
                            )
                        }}
                    </p>
                    <TextField
                        v-model="requestCollection.additionalInternalTitle"
                        :label="
                            $t(
                                'pages.checkout.projectPositionDisposal.pages.wasteDetails.additionalInternalTitle.placeholder'
                            )
                        "
                        :error="getError('requestCollection.additionalInternalTitle')"
                        data-test="project-position-waste-details-additional-internal-title"
                        name="requestCollection.additionalInternalTitle"
                    />
                </section>
            </template>
        </div>

        <SlideUp slot="sticky" active>
            <ButtonGroup>
                <Button primary type="button" data-test="confirm-waste-details-button" @click="save()">
                    {{ $t('forms.buttons.label.confirmAndContinue') }}
                    <ArrowRightIcon slot="right" class="icon--inline" />
                </Button>
            </ButtonGroup>
        </SlideUp>
    </LayoutPage>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import _isNull from 'lodash/isNull';
import _isNaN from 'lodash/isNaN';

import validate from '@/services/validation/mixin';
import { revertLocalizedValue } from '@/services/utils/localization';
import { navigationFailure } from '@/services/utils/router';

import SlideUp from '@/components/Animation/SlideUp.vue';
import Button from '@/components/Button/Button.vue';
import ButtonGroup from '@/components/Button/ButtonGroup.vue';
import ErrorMessage from '@/components/Form/ErrorMessage.vue';
import Header from '@/pages/Checkout/components/Header.vue';
import LayoutPage from '@/components/Layout/Page.v2.vue';
import RadioField from '@/components/Form/RadioField.vue';
import TextField from '@/components/Form/TextField.v2.vue';
import ShowMore from '@/components/ShowMore';

import LoadingCard from '@/pages/Checkout/Disposal/ProjectPosition/Partials/LoadingCard.vue';

import ArrowRightIcon from '@/assets/icons/micro/arrow.svg';

export default {
    name: 'WasteDetails',
    components: {
        SlideUp,
        Button,
        ButtonGroup,
        ErrorMessage,
        Header,
        LayoutPage,
        RadioField,
        TextField,
        ShowMore,

        LoadingCard,

        ArrowRightIcon,
    },
    mixins: [validate],
    computed: {
        ...mapState('projectPosition', ['requestCollection', 'product', 'disposer']),
        ...mapState('basket', {
            typeFromBasket: 'type',
            constructionSiteFromBasket: 'constructionSite',
        }),
        ...mapGetters('projectPosition', ['getProductVariants']),
        ...mapGetters('projectPosition', {
            validationRules: 'validationRules',
            validationGroups: 'validationGroups',
        }),

        selectedProductVariant() {
            return this.requestCollection.factoryProductVariantId;
        },
        isProductVariantSelected() {
            return this.selectedProductVariant > -1;
        },

        isPretreated() {
            return this.requestCollection.isPretreated;
        },
        getPretreatmentDescription() {
            return this.requestCollection.pretreatmentDescription;
        },
        isPretreatmentSelected() {
            return (
                !_isNull(this.isPretreated) &&
                ((this.isPretreated &&
                    !_isNull(this.getPretreatmentDescription) &&
                    this.getPretreatmentDescription.length) ||
                    !this.isPretreated) &&
                this.isProductVariantSelected
            );
        },

        selectedConsistency() {
            return this.requestCollection.consistency;
        },

        isConsistencySelected() {
            return !_isNull(this.selectedConsistency) && this.isPretreatmentSelected;
        },

        productVariants() {
            return this.getProductVariants;
        },
    },
    watch: {
        isProductVariantSelected(isVisible) {
            this.scrollToBottom(isVisible);
        },
        isPretreatmentSelected(isVisible) {
            this.scrollToBottom(isVisible);
        },
        isPretreated(value) {
            if (value === false) {
                this.$store.commit('projectPosition/RESET_PRETREATMENT');
            }
        },
        isConsistencySelected(isVisible) {
            this.scrollToBottom(isVisible);
        },
    },
    async created() {
        await this.$store.dispatch('projectPosition/fetchProductVariants', {
            productId: this.product.id,
        });

        if (!this.isAnyProductVariantSelected()) {
            this.$store.commit('projectPosition/RESET_REQUEST_COLLECTION', {
                property: 'factoryProductVariantId',
            });
        }
    },
    methods: {
        revertLocalizedValue,
        scrollToBottom(isVisible) {
            if (isVisible)
                this.$nextTick(() => {
                    const container = this.$el.querySelector('.page__main');
                    const top = container.scrollHeight;
                    container.scrollTo({
                        top,
                        left: 0,
                        behavior: 'smooth',
                    });
                });
        },

        isSelectedProductVariant(variant) {
            return this.selectedProductVariant === variant.id;
        },

        isAnyProductVariantSelected() {
            return this.productVariants.some(variant => this.isSelectedProductVariant(variant));
        },

        save() {
            if (
                !this.isGroupValidAndGetErrors('wasteDetailsStepProductSelected') ||
                !this.isGroupValidAndGetErrors('wasteDetailsStepProductVariantSelected') ||
                !this.isGroupValidAndGetErrors('wasteDetailsStepIsPretreatmentSelected') ||
                !this.isGroupValidAndGetErrors('wasteDetailsStepIsConsistencySelected')
            )
                return;

            this.$router
                .push({
                    name: this.$root.findRouteName(this.$route.meta.next),
                })
                .catch(navigationFailure);
        },

        priceInformationExists(price) {
            return !_isNull(price) && !_isNaN(price);
        },

        selectProductVariant(productVariant) {
            this.$store.commit('projectPosition/SET_PROJECT_POSITION_PRODUCT_VARIANT', productVariant);
        },
    },
};
</script>

<template>
    <div
        :class="{
            'select-box-v3--invalid': error || hasError,
            'select-box-v3--focused': false && isFocused,
            'select-box-v3--filled': hasValue,
            'select-box-v3--dark': dark,
            'select-box-v3--medium': medium,
            'select-box-v3--small': small,
            'select-box-v3--tiny': tiny,
        }"
        class="select-box-v3"
    >
        <div class="select-box-v3__input-group">
            <!--
                Sometimes vue is not able to sync the variable properly with the input/select value which leads to a mixed state.
                By providing, the key, it’s node is forced to re-render whenever the key changes. In our case, every time the processed value changes

                Force rerender: `:key="processedValue"`

                Note: does not work in iOS
            -->
            <select
                :id="eid"
                :key="processedValue"
                :class="{
                    'select-box-v3__input--filled': hasValue,
                    'select-box-v3__input--iconized': $slots.icon,
                    'select-box-v3__input--readonly': readonly,
                    'select-box-v3__input--without-label': !label,
                }"
                :value="processedValue"
                :name="name"
                :disabled="disabled || readonly"
                :data-test="`${dataTest}-select`"
                class="select-box-v3__input"
                @change="update"
                @blur="blured"
                @focus="focused"
            >
                <option v-for="(option, i) in options" :key="i" :value="i" :selected="i === selectedKey">
                    {{ optionLabelRenderer(option, i) }}
                </option>
            </select>
            <label v-if="label" :for="eid" class="select-box-v3__label">
                {{ label }}
            </label>
            <div v-if="$slots.icon" class="select-box-v3__icon">
                <slot name="icon" />
            </div>
            <ArrowDownIcon
                :class="{ 'select-box-v3__arrow--readonly': readonly }"
                class="select-box-v3__arrow select-box-v3__arrow--down"
            />
            <Button
                v-if="hasValue && !preventReset && !readonly"
                class="select-box-v3__reset"
                tabindex="-1"
                @click="$emit('input', null)"
            >
                <ResetIcon width="13" />
            </Button>
        </div>
        <ErrorMessage v-if="error && error.length" :message="error" :dark="dark" />
    </div>
</template>

<script>
import _isBoolean from 'lodash/isBoolean';
import _isEqual from 'lodash/isEqual';

import Button from '@/components/Button/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';

import ResetIcon from '@/assets/icons/micro/error.svg';
import ArrowDownIcon from '@/assets/icons/regular/arrow-stripeless--down.svg';
import { isEmptyString } from '@/services/utils/string';

export default {
    name: 'SelectBoxV3',
    components: {
        Button,
        ErrorMessage,

        ResetIcon,
        ArrowDownIcon,
    },
    props: {
        value: {
            type: [String, Number, Boolean, Array, Object],
            default: null,
        },
        options: {
            type: [Array, Object],
            required: true,
        },
        optionValueRenderer: {
            type: Function,
            default: (option, i) => i,
        },
        optionLabelRenderer: {
            type: Function,
            default: option => option,
        },
        label: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        error: {
            type: [String, Boolean],
            default: null,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        preventReset: {
            type: Boolean,
            default: true,
        },
        focusInitially: {
            type: Boolean,
            default: false,
        },
        medium: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        tiny: {
            type: Boolean,
            default: false,
        },
        castBoolean: {
            type: Boolean,
            default: false,
        },
        noFallback: {
            type: Boolean,
            default: false,
        },
        dataTest: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            eid: `el${this._uid}`,
            isFocused: false,
        };
    },
    computed: {
        processedValue() {
            if (_isBoolean(this.value)) {
                return this.value ? '1' : '0';
            }

            return this.selectedKey;
        },

        hasValue() {
            if (this.value === null && this.noFallback) return false;
            if (this.value !== null && this.value !== '' && this.value !== undefined) return true;

            return this.selectedKey !== null;
        },

        selectedKey() {
            let selectedKey = null;

            Object.keys(this.options).some(key => {
                const option = this.options[key];
                const value = this.optionValueRenderer(option, key);

                const isInputValueEmpty = this.isEmpty(this.value);
                const isOptionValueEmpty = this.isEmpty(value);

                if (_isEqual(this.value, value) || (isInputValueEmpty && isOptionValueEmpty)) {
                    selectedKey = key;
                    return true;
                }

                return false;
            });

            return selectedKey;
        },
    },
    mounted() {
        if (this.focusInitially && !this.$root.isIOS) {
            setTimeout(() => {
                const $el = document.getElementById(this.eid);
                $el && $el.focus();
            }, 300);
        }
    },
    methods: {
        focused(e) {
            this.isFocused = true;
            this.$emit('focus', e);
        },
        blured(e) {
            this.isFocused = false;
            this.$emit('blur', e);
        },
        update(e) {
            const key = e.target.value;
            const option = this.options[key];

            let value = null;

            if (!isEmptyString(option)) {
                value = this.optionValueRenderer(option, key);
            }

            if (this.castBoolean) {
                switch (value) {
                    case '1':
                    case 1:
                        value = true;
                        break;
                    case '0':
                    case 0:
                        value = false;
                        break;
                    default:
                        value = null;
                }
            }

            this.$emit('input', value);
        },

        isEmpty(value) {
            return (Array.isArray(value) && value.length === 0) || [null, undefined].includes(value);
        },
    },
};
</script>

<style lang="scss">
.select-box-v3 {
    display: block;
    position: relative;
}

.select-box-v3__input-group {
    display: flex;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;
    overflow: hidden;
    height: 70px;
    transition: border-color 0.2s ease;

    .select-box-v3--dark & {
        border-color: transparent;
    }

    .select-box-v3--focused & {
        border-color: rgba(0, 0, 0, 1);
    }

    .select-box-v3--invalid & {
        border-color: $color-red;
    }

    .select-box-v3--small & {
        height: 50px;
    }

    .select-box-v3--tiny & {
        height: 40px;
    }
}

.select-box-v3__slot--action {
    padding-top: 25px;
    padding-right: 15px;
    transition: padding-top 0.2s ease;

    > .button {
        font-size: 11px;
    }

    > * {
        font-weight: $font-weight-regular;
    }
}

.select-box-v3__input {
    display: block;
    width: 100%;
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 27px 40px 22px 15px;
    font-size: 14px;
    line-height: $line-height-base;
    font-family: $font-family;
    outline: none;
    border-radius: 0;
    transition: padding 0.2s ease;
    font-family: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 100%;

    // Force repaint because of rendering issues
    transform: rotate(360deg);

    &:invalid {
        box-shadow: none;
    }
}

.select-box-v3__input--readonly {
    color: $color-black;
}

.select-box-v3__input--without-label {
    padding: 10px 40px 10px 15px;

    .select-box-v3--tiny & {
        padding-top: 8px;
    }
}

.select-box-v3__input--iconized {
    padding-left: 40px;

    & + .select-box-v3__label {
        left: 40px;
    }
}

.select-box-v3--focused,
.select-box-v3--filled {
    .select-box-v3__label {
        opacity: 0.7;
        font-size: 10px;
        top: 20px;
        left: 15px;
    }

    .select-box-v3__input {
        padding-bottom: 6px;
    }

    .select-box-v3__icon {
        top: 37px;
    }

    &.select-box-v3--small {
        .select-box-v3__label {
            top: 13px;
        }

        .select-box-v3__icon,
        .select-box-v3__reset {
            top: 27px;
        }
    }

    &.select-box-v3--tiny {
        .select-box-v3__label {
            top: 8px;
        }

        .select-box-v3__icon,
        .select-box-v3__reset {
            top: 22px;
        }
    }
}

.select-box-v3__input:invalid {
    background-color: red;
}

.select-box-v3__label {
    font-size: 14px;
    line-height: 1;
    position: absolute;
    color: inherit;
    top: 30px;
    left: 15px;
    transition: all 0.2s ease;
}

.select-box-v3__arrow {
    width: 14px;
    height: 14px;
    pointer-events: none;
    position: absolute;
    top: 37px;
    transform: translateY(-50%);
    right: 17px;
    z-index: 11;
    line-height: 1;
    position: absolute;
    transition: top 0.2s ease;

    .select-box-v3--small & {
        top: 28px;
    }

    .select-box-v3--tiny & {
        top: 22px;
    }
}

.select-box-v3__arrow--readonly {
    opacity: 0.3;
}

.select-box-v3__icon,
.select-box-v3__reset {
    line-height: 1;
    position: absolute;
    top: 30px;
    transition: top 0.2s ease;
}

.select-box-v3__icon {
    left: 15px;

    svg {
        width: 13px;
        height: 13px;
    }
}

.select-box-v3__reset {
    right: 17px;
    opacity: 0;
    transition: opacity 0.2s ease;
    background-color: #fff;
    outline: 3px solid #fff;
    z-index: 12;

    .select-box-v3:hover &,
    .select-box-v3--focused & {
        opacity: 1;
    }
}

.select-box-v3--invalid {
    .select-box-v3__textarea,
    .select-box-v3__input {
        color: $color-red;
        opacity: 1 !important;
        border-color: $color-red;
    }

    .select-box-v3__slot--action {
        border-color: $color-red;
    }

    .select-box-v3__input-group {
        svg {
            .f-base {
                fill: $color-red;
            }

            .s-base {
                stroke: $color-red;
            }
        }
    }
}

.select-box-v3--dark {
    .select-box-v3__textarea,
    .select-box-v3__input-group {
        box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
    }
}

.select-box-v3--medium {
    .select-box-v3__input-group {
        height: 45px;

        .select-box-v3__input--filled + .select-box-v3__label {
            display: none;
        }
    }
    .select-box-v3__input {
        padding: 0px 10px;
        line-height: 1;
    }
    .select-box-v3__label {
        top: 16px;
    }
    .select-box-v3__arrow {
        top: 23px;
    }

    &.select-box-v3--focused {
        .select-box-v3__label {
            opacity: 1;
            font-size: 14px;
            top: 16px;
        }
    }
}
</style>

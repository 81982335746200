import { StarIcon } from '@/constructionProjects/components/icons/StarIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
export const FavoritableHint = ({ className }) => {
    const { t } = useTranslation();
    return (<div className={clsx('flex flex-col items-center border border-dashed border-disabled p-6', className)}>
            <StarIcon className="mb-2 stroke-icon" size={24}/>
            <p className="font-copy-md-strong mb-1">
                {t('components.constructionProjects.constructionProjectsList.favoritableHint.title')}
            </p>
            <p className="font-copy-sm text-subdued">
                {t('components.constructionProjects.constructionProjectsList.favoritableHint.description')}
            </p>
        </div>);
};

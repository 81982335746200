import React from 'react';
import { PdfIcon } from '@/constructionProjects/components/icons/PdfIcon';
import { MapMarkerIcon } from '@/constructionProjects/components/icons/MapMarkerIcon';
import { MaterialIcon } from '@/constructionProjects/components/icons/MaterialIcon';
import { ProjectsIcon } from '@/constructionProjects/components/icons/ProjectsIcon';
import { SettingsIcon } from '@/constructionProjects/components/icons/SettingsIcon';
import { TagIcon } from '@/constructionProjects/components/icons/TagIcon';
import { VehicleIcon } from '@/constructionProjects/components/icons/VehicleIcon';
import { MenuTile } from '@/constructionProjects/components/MenuTile';
import { useTranslation } from 'react-i18next';
import { useQueries, useQuery } from '@tanstack/react-query';
import { Button } from '@schuettflix/react-components';
import store from '@/store';
import { useAbility } from '@/reactBridge/useAbility';
import { useRouteParams } from '@/reactBridge/useRouteParams';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { assembleQueryFilter } from '@/plugins/composables/usePersistentFilters';
import { ConstructionProjectStatus } from '@/constructionProjects/api/projects';
import { CONSTRUCTION_PROJECT_CLIENT_SETTINGS_ROUTE, CONSTRUCTION_PROJECT_DETAILS_ROUTE, CONSTRUCTION_PROJECT_DOCUMENTS_ROUTE, CONSTRUCTION_PROJECT_POSITIONS_ROUTE, } from '@/constructionProjects/constants';
import clsx from 'clsx';
import { formatAddress } from '@/constructionProjects/utils/formatAddress';
import { constructionProjectByIdQuery, constructionProjectCountsQuery } from '@/constructionProjects/queries';
import { useRoute } from '@/reactBridge/useRoute';
import { WarningSection } from '@/constructionProjects/pages/ConstructionProjectDetails/WarningSection';
const DetailsHeader = () => {
    const { vueRouter } = useVueRouter();
    const { t } = useTranslation();
    const { id } = useRouteParams();
    const canSubmitProjectOrder = useAbility('createProjectPositionQuote');
    const canCreateDeliveryQuote = useAbility('createDeliveryQuote');
    const canCreateShipmentQuote = useAbility('createShipmentQuote');
    const canCreatePickupQuote = useAbility('createPickupQuote');
    const canOrder = canSubmitProjectOrder || canCreateDeliveryQuote || canCreateShipmentQuote || canCreatePickupQuote;
    const query = useQuery({
        ...constructionProjectByIdQuery(id),
        suspense: true,
    });
    if (!query.data) {
        return null;
    }
    const isProjectActive = query.data.status === ConstructionProjectStatus.Active;
    const handleOrderButtonClicked = async () => {
        store.commit('constructionProject/setConstructionProjectId', id);
        store.commit('constructionProject/setEntryPoint', { name: CONSTRUCTION_PROJECT_DETAILS_ROUTE, params: { id } });
        await vueRouter.push({ name: 'order__checkout' });
    };
    const formattedAddress = formatAddress(query.data);
    const costCenter = query.data.costCenter;
    const name = query.data.name;
    return (<section className="bg-surface p-4">
            <p className="font-copy-lg-strong mb-2">{name}</p>
            <div className="font-copy-md grid grid-cols-[16px_minmax(0,_1fr)] items-start gap-2 leading-4">
                {formattedAddress && (<>
                        <MapMarkerIcon />
                        <p>{formattedAddress}</p>
                    </>)}
                {costCenter && (<>
                        <TagIcon />
                        <p>{costCenter}</p>
                    </>)}
            </div>
            {canOrder && isProjectActive && (<Button data-test="construction-project-details-page-order-button" label={t('pages.constructionProject.details.button.placeOrder')} className="mt-6 w-full rounded" size="sm" onClick={handleOrderButtonClicked}/>)}
        </section>);
};
const Details = () => {
    const { id } = useRouteParams();
    const { t } = useTranslation();
    const route = useRoute();
    const canListProjectPositions = useAbility('listProjectPositions');
    const canListTransports = useAbility('listClientTransports');
    const canListOrders = useAbility('listOrders');
    const [counts, constructionProject] = useQueries({
        queries: [
            {
                ...constructionProjectCountsQuery(id),
                suspense: true,
            },
            {
                ...constructionProjectByIdQuery(id),
                suspense: true,
            },
        ],
    });
    if (!counts.data || !constructionProject.data) {
        return null;
    }
    const parentRoute = {
        name: route.name,
        params: route.params,
        backTitle: constructionProject.data.name,
    };
    return (<>
            <section className="m-4 rounded bg-surface shadow-low">
                <ul className="divide-y divide-light-gray-400">
                    {canListProjectPositions && (<MenuTile dataTest="construction-project-details-page-positions-link" to={{
                name: CONSTRUCTION_PROJECT_POSITIONS_ROUTE,
                params: { id },
            }} icon={ProjectsIcon} label={t('pages.constructionProject.details.links.positionsLabel')} description={t('pages.constructionProject.details.links.positionsDescription', {
                count: counts.data.projectPositionCount,
            })}/>)}
                    {canListOrders && (<MenuTile dataTest="construction-project-details-page-orders-link" to={{
                name: 'order__order-list',
                query: {
                    parentRoute: JSON.stringify(parentRoute),
                    order: assembleQueryFilter({
                        constructionProject: id,
                        status: ['in_progress', 'new'],
                    }),
                    quote: assembleQueryFilter({
                        constructionProject: id,
                    }),
                },
            }} icon={MaterialIcon} label={t('pages.constructionProject.details.links.ordersLabel')} description={t('pages.constructionProject.details.links.ordersDescription', {
                count: counts.data.orderCount,
            })}/>)}
                    {canListTransports && (<MenuTile dataTest="construction-project-details-page-transports-link" to={{
                name: 'order__transport-list',
                query: {
                    parentRoute: JSON.stringify(parentRoute),
                    transport: assembleQueryFilter({
                        constructionProject: id,
                    }),
                },
            }} icon={VehicleIcon} label={t('pages.constructionProject.details.links.transportsLabel')} description={t('pages.constructionProject.details.links.transportsDescription', {
                count: counts.data.transportCount,
            })}/>)}
                    <MenuTile dataTest="construction-project-details-page-documents-link" to={{ name: CONSTRUCTION_PROJECT_DOCUMENTS_ROUTE, params: { id } }} icon={PdfIcon} label={t('pages.constructionProject.details.links.documentsLabel')}/>
                </ul>
            </section>
            <section className="m-4 mt-2 rounded bg-surface shadow-low">
                <ul className="divide-y divide-light-gray-400">
                    <MenuTile dataTest="construction-project-details-page-project-settings-link" to={{
            name: `${CONSTRUCTION_PROJECT_DETAILS_ROUTE}__${CONSTRUCTION_PROJECT_CLIENT_SETTINGS_ROUTE}`,
            params: { id },
        }} appendPath icon={() => <SettingsIcon multicolor size={'sm'}/>} label={t('pages.constructionProject.details.button.projectSettings.title')} description={t('pages.constructionProject.details.button.projectSettings.subtitle')}/>
                </ul>
            </section>
        </>);
};
const Footer = () => {
    const { id } = useRouteParams();
    const { t } = useTranslation();
    const query = useQuery({
        ...constructionProjectByIdQuery(id),
        suspense: true,
        select: data => data.projectCode,
    });
    if (!query.data) {
        return null;
    }
    return (<footer className="font-numbers-md my-6 flex flex-grow items-end justify-center text-center text-subdued">
            {t('pages.constructionProject.details.footer.projectCode', {
            code: query.data,
        })}
        </footer>);
};
export const ConstructionProjectDetailsMobile = ({ className }) => {
    return (<div className={clsx('flex flex-grow flex-col overflow-auto', className)}>
            <DetailsHeader />
            <div className="p-4">
                <WarningSection />
            </div>
            <Details />
            <Footer />
        </div>);
};

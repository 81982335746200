import React from 'react';
import { Tag } from '@schuettflix/react-components';
import { MapMarkerIcon } from '@/constructionProjects/components/icons/MapMarkerIcon';
import { StarFilledIcon } from '@/constructionProjects/components/icons/StarFilledIcon';
import { StarIcon } from '@/constructionProjects/components/icons/StarIcon';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AvatarList } from '@schuettflix/react-components';
import { DAYS_LEFT_NOTIFICATION_THRESHOLD } from '../constants';
import { calculateValidityInDays } from '../utils/validity';
const ColoredHeadline = ({ backgroundColor }) => {
    return <div className="h-4 w-full rounded-b" style={{ backgroundColor }}/>;
};
export const ProjectCard = ({ name, city, color, zip, isFavorite, responsibleUser, teamMembers, validTo, onCardClick, onStarClick, className, }) => {
    const { t } = useTranslation();
    const handleIconClick = (e) => {
        e.stopPropagation();
        onStarClick();
    };
    const daysLeft = calculateValidityInDays(validTo);
    const showWarning = daysLeft > 0 && daysLeft <= DAYS_LEFT_NOTIFICATION_THRESHOLD;
    return (<div data-test="construction-project-card" className={clsx('shrink-0 grow snap-center overflow-visible rounded bg-surface p-4 pt-0 shadow-low', className)} onClick={onCardClick}>
            <div className="flex h-full w-full flex-col">
                <ColoredHeadline backgroundColor={color}/>
                <div className="px-1">
                    <div className="mt-2 grid grid-cols-[1fr_auto] gap-0">
                        <div className="overflow-hidden">
                            <p className="font-copy-xl-strong mb-2 line-clamp-2 min-h-[48px] font-bold" title={name}>
                                {name}
                            </p>
                        </div>
                        <div className="flex h-[40px] w-[40px] flex-shrink-0 items-center justify-center" onClick={handleIconClick}>
                            {isFavorite ? (<StarFilledIcon className="fill-icon-accent" size={24}/>) : (<StarIcon className="stroke-icon" size={24}/>)}
                        </div>
                    </div>
                    <div className="flex items-center">
                        {showWarning && (<Tag type="warning" label={t('pages.constructionProject.projectCard.expirationWarning', {
                count: daysLeft,
            })}/>)}
                    </div>
                    <div className="mt-4 flex items-center justify-between">
                        {city && (<div className="flex items-center">
                                <MapMarkerIcon />
                                <p className="ml-1">{`${zip || ''} ${city}`.trim()}</p>
                            </div>)}
                        <AvatarList users={[responsibleUser, ...(teamMembers ?? [])]} size="sm"/>
                    </div>
                </div>
            </div>
        </div>);
};

<template>
    <div class="feature-manager">
        <div v-for="featureGroup in featureGroups" :key="featureGroup.__type" class="feature-manager__group">
            <Words v-if="featureGroup.__type === 'global'" block bold large spaced>
                {{ $t('pages.organization.featureManager.furtherFeatures') }}
            </Words>
            <FeatureAccordion
                v-else
                v-model="activeAccordions"
                :identifier="featureGroup.__type"
                :title="
                    $t('pages.organization.featureManager.typeLabelPrefixPlural', { featureGroup: featureGroup.title })
                "
                dark
            >
                <template #before>
                    <SuccessIcon
                        v-if="isTypeActive(featureGroup.__type)"
                        width="22"
                        height="22"
                        class="icon--inline icon--green"
                    />
                    <InactiveIcon v-else width="22" height="22" class="icon--inline icon--red" />
                </template>
                <template>
                    <Words block spaced-bottom>{{ featureGroup.description }}</Words>
                    <div
                        v-if="!isTypeActive(featureGroup.__type) || canChangeType(featureGroup.__type)"
                        class="feature-manager__actions"
                    >
                        <Button
                            v-if="!isTypeActive(featureGroup.__type)"
                            weight-normal
                            arrow-right
                            @click="addType(featureGroup.__type)"
                        >
                            {{ $t('pages.organization.featureManager.addFeature') }}
                        </Button>
                        <Button
                            v-else-if="
                                getMandatoryTypeLabel(featureGroup.__type) === null &&
                                canChangeType(featureGroup.__type)
                            "
                            weight-normal
                            arrow-right
                            @click="removeType(featureGroup.__type)"
                        >
                            {{ $t('pages.organization.featureManager.removeFeature') }}
                        </Button>
                    </div>
                    <Alert
                        v-if="getMandatoryTypeLabel(featureGroup.__type)"
                        type="error"
                        icon-type="info"
                        primary
                        colorized
                        class="feature-manager__type-hint"
                    >
                        {{ getMandatoryTypeLabel(featureGroup.__type) }}
                    </Alert>
                </template>
            </FeatureAccordion>

            <FeatureAccordion
                v-for="(feature, featureKey) in featureGroup.features"
                :key="`${featureGroup.__type}-${featureKey}`"
                v-model="activeAccordions"
                :identifier="`${featureGroup.__type}-${featureKey}`"
                :title="feature.title"
                :content-changes="contentUpdates[featureKey] || 0"
            >
                <template #before>
                    <SuccessIcon
                        v-if="isFeatureActive(featureKey)"
                        width="22"
                        height="22"
                        :class="isFeatureEnabled(featureKey) ? 'icon--green' : 'icon--muted'"
                        class="icon--inline"
                    />
                    <InactiveIcon v-else width="22" height="22" class="icon--inline icon--red" />
                </template>

                <Words block spaced-bottom>{{ feature.description }}</Words>
                <div v-if="canManageFeature(featureGroup.__type, featureKey)" class="feature-manager__actions">
                    <Button weight-normal arrow-right @click="toggleFeature(featureKey)">
                        {{
                            isFeatureActive(featureKey)
                                ? $t('pages.organization.featureManager.removeFeature')
                                : $t('pages.organization.featureManager.addFeature')
                        }}
                    </Button>
                </div>
                <Alert
                    v-if="getMandatoryFeatureLabel(featureKey)"
                    type="error"
                    icon-type="info"
                    primary
                    colorized
                    class="feature-manager__type-hint"
                >
                    {{ getMandatoryFeatureLabel(featureKey) }}
                </Alert>

                <div v-if="isFeatureActive(featureKey) && hasFeatureSettings(feature)" slot="footer">
                    <FeatureSettings
                        :feature="features[featureKey]"
                        :feature-definition="feature"
                        @input="handleFeatureSettingsUpdate(featureKey, $event)"
                    />
                </div>
            </FeatureAccordion>
        </div>
    </div>
</template>

<script>
import Toaster from '@/services/Toaster';
import FeatureManager, { DependencyError } from '@/services/FeatureManager/FeatureManager';

import Alert from '@/components/Alert';
import Button from '@/components/Button/Button';
import FeatureAccordion from './FeatureAccordion';
import FeatureSettings from './FeatureSettings';
import Words from '@/components/Typography/Words';

import InactiveIcon from '@/assets/icons/regular/close--rounded.svg';
import SuccessIcon from '@/assets/icons/regular/check-mark--rounded.svg';

const featureManager = new FeatureManager();

export default {
    name: 'FeatureManager',
    components: {
        Alert,
        Button,
        FeatureAccordion,
        FeatureSettings,
        Words,

        InactiveIcon,
        SuccessIcon,
    },
    props: {
        definition: {
            type: Object,
            required: true,
        },
        types: {
            type: Array,
            required: true,
        },
        originalTypes: {
            type: Array,
            required: true,
        },
        originalFeatures: {
            type: Object,
            required: true,
        },
        features: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            activeAccordions: [],
            contentUpdates: {},
        };
    },
    computed: {
        /**
         * A list of feature groups
         */
        featureGroups() {
            const groups = [];

            if (this.definition === null) {
                return groups;
            }

            const { organizationTypes, globalFeatures } = this.definition;

            Object.keys(organizationTypes).forEach(type => {
                const typeConfig = organizationTypes[type];
                groups.push({
                    ...typeConfig,
                    __type: type,
                });
            });

            if (Object.keys(globalFeatures).length > 0) {
                groups.push({
                    __type: 'global',
                    features: globalFeatures,
                });
            }

            return groups;
        },
    },
    created() {
        featureManager.loadDefinition(this.definition);
    },
    methods: {
        incrementContentUpdate(featureKey) {
            if (featureKey in this.contentUpdates) ++this.contentUpdates[featureKey];
            else this.contentUpdates[featureKey] = 1;
        },
        canManageFeature(type, featureKey) {
            return (
                (this.isTypeActive(type) || type === 'global') &&
                !(this.isFeatureActive(featureKey) && !this.canRemoveFeature(featureKey))
            );
        },

        canRemoveFeature(featureKey) {
            try {
                const nextFeatures = featureManager.removeFeature(featureKey, this.features, this.types);

                return Object.keys(this.originalFeatures).every(k => Object.keys(nextFeatures).includes(k));
            } catch (err) {
                if (err instanceof DependencyError) {
                    return false;
                }
            }
        },

        isTypeActive(type) {
            return this.types.includes(type);
        },

        addType(type) {
            try {
                const [nextTypes, nextFeatures] = featureManager.addType(type, this.types, this.features);
                this.$emit('input-types', nextTypes);
                this.$emit('input-features', nextFeatures);
            } catch (err) {
                Toaster.error(err);
            }
        },

        removeType(type) {
            try {
                const [nextTypes, nextFeatures] = featureManager.removeType(type, this.types, this.features);
                this.$emit('input-types', nextTypes);
                this.$emit('input-features', nextFeatures);
            } catch (err) {
                Toaster.error(err);
            }
        },

        getMandatoryTypeLabel(type) {
            const requiredByTypes = featureManager.getTypeDependencies(type, this.types);

            if (requiredByTypes.length === 0) {
                return null;
            }

            const parts = requiredByTypes.map(t => {
                return `${this.$t('pages.organization.featureManager.typeLabelPrefix')} ${
                    this.definition.organizationTypes[t].title
                }`;
            });

            return this.$t('pages.organization.featureManager.requiredFeature', {
                features: parts.join('", "'),
            });
        },

        getMandatoryFeatureLabel(featureKey) {
            const [requiredByTypes, requiredByFeatures] = featureManager.getFeatureDependencies(
                featureKey,
                this.features,
                this.types
            );

            if (requiredByTypes.length === 0 && requiredByFeatures.length === 0) {
                return null;
            }

            const parts = [].concat(
                requiredByTypes.map(
                    type =>
                        `${this.$t('pages.organization.featureManager.typeLabelPrefix')} ${
                            this.definition.organizationTypes[type].title
                        }`
                ),
                requiredByFeatures.map(featureKey => featureManager.getFeatureConfig(featureKey).title)
            );

            return this.$t('pages.organization.featureManager.requiredFeature', {
                features: parts.join('", "'),
            });
        },

        canChangeType(type) {
            // Can change type unless the organization is not saved/persistet
            return !this.originalTypes.includes(type) && this.types.includes(type);
        },

        isFeatureActive(featureKey) {
            return featureManager.isFeatureActive(featureKey, this.features);
        },

        hasFeatureSettings(feature) {
            return !feature?.settings.length;
        },

        isFeatureEnabled(featureKey) {
            // Features without active setting are always active
            if (!Object.prototype.hasOwnProperty.call(this.features[featureKey], 'active')) {
                return true;
            }

            return this.features[featureKey].active;
        },

        toggleFeature(featureKey, status = null) {
            // toggle feature if not explicitly set
            if (status === null) {
                status = !this.features[featureKey];
            }

            try {
                let nextFeatures;

                if (status) {
                    nextFeatures = featureManager.addFeature(featureKey, this.features, this.types);
                } else {
                    nextFeatures = featureManager.removeFeature(featureKey, this.features, this.types);
                }

                this.$emit('input-features', nextFeatures);

                this.incrementContentUpdate(featureKey);
            } catch (err) {
                this.$logger().log(err);
                Toaster.error(err);
            }
        },

        handleFeatureSettingsUpdate(featureKey, featureSettings) {
            this.$emit('input-features', {
                ...this.features,
                [featureKey]: featureSettings,
            });
        },
    },
};
</script>

<style lang="scss">
.feature-manager__group {
    margin-top: 50px;
}

.feature-manager__actions {
    text-align: right;
}

.feature-manager__type-hint {
    margin-top: 20px;
}
</style>

<template>
    <DialogModalBase data-test="successful-creation">
        <template #bannerImage>
            <Banner />
        </template>
        <template #header>
            {{ $t('components.dialogModal.successfulCreation.headerText') }}
        </template>
        <template #content>
            <Words line-height-large large bold block spaced-bottom>{{
                $t('components.dialogModal.successfulCreation.headline')
            }}</Words>
            <Words line-height-small block spaced-bottom-large>{{
                $t('components.dialogModal.successfulCreation.message')
            }}</Words>

            <ToggleSwitch :value="toggleSwitchValue" small color="green" @input="handleToggleSwitch($event)" /><Words
                bold
                spaced-left-small
                >{{ $t('components.dialogModal.successfulCreation.toggleSwitchText') }}</Words
            >
            <Words v-if="isSellerActive || isDisposalActive" block spaced-top-large>
                <SfButton type="secondary" :size="`${$root.isDesktop ? 'sm' : 'md'}`" @click="goToProductCatalog()">
                    <Words v-if="isSellerActive && isDisposalActive" bold>{{
                        $t('components.dialogModal.successfulCreation.goToSellingMaterialsDisposalCatalog')
                    }}</Words>
                    <Words v-if="isSellerActive && !isDisposalActive" bold>{{
                        $t('components.dialogModal.successfulCreation.goToSellingMaterialsCatalog')
                    }}</Words>
                    <Words v-if="isDisposalActive && !isSellerActive" bold>{{
                        $t('components.dialogModal.successfulCreation.goToDisposalMaterialsCatalog')
                    }}</Words>
                </SfButton>
            </Words>
        </template>
        <template #footer>
            <div class="flex flex-col justify-end md:flex-row">
                <SfButton
                    type="secondary"
                    :size="`${$root.isDesktop ? 'sm' : 'md'}`"
                    :class="{ 'border-t border-light-gray-400': !$root.isDesktop }"
                    data-test="back-to-site-overview-button"
                    @click="$emit('secondaryClick')"
                >
                    {{ $t('components.dialogModal.successfulCreation.secondaryButtonText') }}
                </SfButton>
                <SfButton
                    :size="`${$root.isDesktop ? 'sm' : 'md'}`"
                    :class="{ 'ml-4': $root.isDesktop }"
                    data-test="back-to-site-button"
                    @click="$emit('primaryClick')"
                >
                    {{ $t('components.dialogModal.successfulCreation.primaryButtonText') }}
                </SfButton>
            </div>
        </template>
    </DialogModalBase>
</template>
<script>
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';

import Banner from '@/assets/banners/success-banner.svg';
import { SfButton } from '@schuettflix/vue-components';
import DialogModalBase from '@/_components/DialogModal/DialogModalBase';
import Words from '@/components/Typography/Words';
import ToggleSwitch from '@/components/Form/ToggleSwitch';

export default {
    name: 'SuccessfulCreation',
    components: {
        Banner,
        SfButton,
        DialogModalBase,
        Words,
        ToggleSwitch,
    },
    mixins: [persistentFiltersMixin],
    props: {
        factory: {
            type: Object,
            default: () => ({}),
            required: true,
        },
    },
    data() {
        return {
            toggleSwitchValue: false,
        };
    },
    computed: {
        isSellerActive() {
            return this.factory?.usages?.includes('selling');
        },
        isDisposalActive() {
            return this.factory?.usages?.includes('disposal');
        },
    },
    methods: {
        handleToggleSwitch($event) {
            this.toggleSwitchValue = $event;
            this.$emit('toggleSwitch', $event);
        },
        goToProductCatalog() {
            this.$router
                .push({
                    name: 'management__supplier-products',
                    query: {
                        factory: this.assembleQueryFilter(this.factory.id),
                        productType: this.assembleQueryFilter(
                            this.isDisposalActive ? 'disposal_type' : 'construction_type'
                        ),
                    },
                })
                .catch(() => {});
        },
    },
};
</script>

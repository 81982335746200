<template>
    <div
        class="dialog-modal-base max-h-[calc(theme(height.screen)-calc(theme(space.4)*2))] overflow-auto md:p-6"
        :class="{ 'md:p-0': hasHeaderSlot }"
    >
        <header v-if="hasHeaderSlot && $root.isDesktop" class="relative" data-test="dialog-modal-header">
            <slot name="bannerImage" />
            <div
                class="absolute inset-0 flex items-center justify-center px-16 py-0 text-center text-[48px] font-bold leading-10 text-white"
                data-test="header-text"
            >
                <slot name="header" />
            </div>
        </header>
        <div v-if="hasContentSlot" class="p-6" :class="hasHeaderSlot ? 'md:p-6' : 'md:p-0'">
            <slot name="content" />
        </div>

        <footer
            v-if="hasFooterSlot"
            data-test="modalFooter"
            :class="hasHeaderSlot ? 'md:px-6 md:pb-6' : 'md:px-0 md:pb-0'"
        >
            <slot name="footer" />
        </footer>
    </div>
</template>

<script>
export default {
    name: 'DialogModalBase',
    computed: {
        hasHeaderSlot() {
            return !!this.$slots.header;
        },
        hasContentSlot() {
            return !!this.$slots.content;
        },
        hasFooterSlot() {
            return !!this.$slots.footer;
        },
    },
};
</script>

<style lang="scss" scoped>
.dialog-modal-base {
    &__header {
        svg {
            object-fit: cover;
        }
    }
}
</style>

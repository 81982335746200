var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"checkbox-field",class:{
        'checkbox-field--boxed': _vm.boxed,
        'checkbox-field--active': _vm.isActive,
        'checkbox-field--disabled': _vm.disabled,
        'checkbox-field--black': _vm.black,
    },attrs:{"data-test":"checkbox-field"}},[_c('Checkbox',{staticClass:"checkbox-field__input",attrs:{"value":_vm.value,"name":_vm.name,"option":_vm.option,"disabled":_vm.disabled,"small":_vm.boxed || _vm.black || _vm.onDarkSurface,"multiple":_vm.multiple,"black":_vm.black,"on-dark-surface":_vm.onDarkSurface},on:{"input":function($event){return _vm.$emit('input', $event)}}}),_c('div',{staticClass:"checkbox-field__label",class:{
            'checkbox-field__label--disabled': _vm.disabled,
        }},[_vm._t("default")],2)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
import AbstractFilterableResource from './AbstractFilterableResource';
import { patchFilterResponse, patchFilterParams } from '@/services/utils/transport';
import {
    CONTEXT_PLATFORM,
    CONTEXT_CARRIER,
    CONTEXT_SUPPLIER,
    CONTEXT_CLIENT,
    CONTEXT_CARRIER_DRIVER,
} from '@/constants/context';

const OLD_ENDPOINT = '/transport-list';
const NEW_ENDPOINT = '/v1/transport-search';

/**
 * NOTE: This class is no longer a Singleton
 */
export default class TransportList extends AbstractFilterableResource {
    constructor(context, { useLegacySearch = true } = {}) {
        super(useLegacySearch ? OLD_ENDPOINT : NEW_ENDPOINT);

        const supportedContexts = [
            CONTEXT_PLATFORM,
            CONTEXT_CARRIER,
            CONTEXT_SUPPLIER,
            CONTEXT_CLIENT,
            CONTEXT_CARRIER_DRIVER,
        ];
        if (!supportedContexts.includes(context)) {
            throw Error(
                `Please instantiate a new version of the TransportListApi with one of the following supported context types: [${supportedContexts.join(
                    ', '
                )}]`
            );
        }

        this.context = context;
    }

    /**
     * Filter Transport Lists
     *
     * @param {Object} params
     * @param {String|null} facetFilterKey
     * @param {Array} facetFilterValues
     * @param {import('axios').CancelTokenSource|null} cancelSource
     * @param {Object} forcedParams
     * @param {Boolean} silence
     * @returns
     */
    async filter(
        params = {},
        facetFilterKey = null,
        facetFilterValues = [],
        cancelSource = null,
        forcedParams = {},
        silence = false
    ) {
        const resourcePath = this.getResourcePathByContext();

        const response = await this.filterEndpoint(
            resourcePath,
            patchFilterParams(this.context, params),
            facetFilterKey,
            facetFilterValues,
            cancelSource,
            patchFilterParams(this.context, forcedParams),
            silence
        );

        // Map status based on context
        if (response.isFacetatedResult) {
            response.items.forEach(filterResult => {
                patchFilterResponse(this.context, filterResult);
            });
        }

        patchFilterResponse(this.context, response);
        return response;
    }

    getResourcePathByContext() {
        switch (this.context) {
            case CONTEXT_PLATFORM:
            case CONTEXT_CARRIER:
            case CONTEXT_SUPPLIER:
            case CONTEXT_CLIENT:
                return `${this.resourcePath}/${this.context}`;
            case CONTEXT_CARRIER_DRIVER:
                return `${this.resourcePath}/carrier-driver`;
            default:
                return this.resourcePath;
        }
    }

    /**
     *
     * @param transportIds
     * @returns {Promise<any>}
     */
    async checkTransportAvailability(transportIds) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/transportavailability`, {
                items: transportIds,
            });
            return response.data.items;
        } catch ({ code, message }) {
            throw this.messages[code] || message;
        }
    }
}

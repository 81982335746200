<template>
    <div
        :class="{ [`preferred-carrier-inner--${mode}`]: true, 'preferred-carrier-inner-dark': dark }"
        class="preferred-carrier-inner"
    >
        <template v-if="mode === 'button'">
            <span v-if="scope.selectedOption" class="preferred-carrier-inner__selected">
                <Words bold block small>
                    {{ scope.label }}
                </Words>
                <Words muted block>
                    {{ scope.selectedOption.name }}
                </Words>
            </span>
            <span v-else>{{ scope.label }}</span>
            <ArrowIcon width="14" height="14" class="preferred-carrier-inner__arrow" />
        </template>
        <template v-else>
            <Words :red="scope.isActive">
                <Words block>{{ scope.option.name }}</Words>
                <Words block muted>
                    <FormattedAddress decorate-location-code :address="scope.option.billingAddress" />
                </Words>
            </Words>
            <TickIcon v-if="scope.isActive" class="preferred-carrier-inner__active-icon" />
        </template>
    </div>
</template>

<script>
import FormattedAddress from '@/components/FormattedAddress';
import Words from '@/components/Typography/Words';

import ArrowIcon from '@/assets/icons/regular/arrow-stripeless--down.svg';
import TickIcon from '@/assets/icons/micro/tick.svg';

export default {
    name: 'PreferredCarrierInner',
    components: {
        FormattedAddress,
        Words,

        ArrowIcon,
        TickIcon,
    },
    props: {
        mode: {
            type: String,
            required: true,
            validator: v => ['button', 'option'].includes(v),
        },
        scope: {
            type: Object,
            required: true,
        },
        dark: {
            type: Boolean,
            default: null,
        },
    },
};
</script>

<style lang="scss">
.preferred-carrier-inner {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 10px;
    align-items: center;
}

.preferred-carrier-inner--button {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;
    overflow: hidden;
    height: 70px;
    transition:
        border-color 0.2s ease,
        padding 0.2s ease;

    padding: 27px 40px 22px 15px;
    font-size: 14px;
    line-height: $line-height-base;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;

    &.preferred-carrier-inner--dark {
        border-color: transparent;
        box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
    }
}

.preferred-carrier-inner__arrow {
    width: 14px;
    height: 14px;
    pointer-events: none;
    position: absolute;
    top: 37px;
    transform: translateY(-50%);
    right: 17px;
    z-index: 11;
    line-height: 1;
    position: absolute;
    transition: top 0.2s ease;
}
</style>

import ReportingPage from './ReportingPage';

export default {
    path: 'reporting',
    name: 'reporting',
    component: ReportingPage,
    meta: {
        requiresAuth: true,
        requiredAbilites: ['haveReports'],
    },
};

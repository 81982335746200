<template>
    <div class="container-deprecated mb-12 mt-6 flex items-center">
        <div class="page-meta-head__items">
            <div v-if="$slots.tags" class="page-meta-head__tags">
                <slot name="tags" />
            </div>
            <slot name="item" />
        </div>
        <div v-if="$slots.menu" class="page-meta-head__menu">
            <slot name="menu" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageMetaHead',
};
</script>

<style lang="scss" scoped>
$grid-gap-sm: 24px;
$grid-gap-at-column-layout: 25px;
$menu-visible-at: $layout-desktop-min;
$column-layout-at: $screen-md;

.page-meta-head {
    display: flex;
    margin-top: 20px;
    margin-bottom: 48px;
}

.page-meta-head__items {
    flex: 1;
    display: grid;
    gap: $grid-gap-sm;
    align-items: start;

    @media screen and (min-width: $column-layout-at) {
        grid-auto-flow: column;
        gap: $grid-gap-at-column-layout;
    }
}

.page-meta-head__item {
    overflow: hidden;
}

.page-meta-head__tags {
    display: flex;
    align-self: center;
    justify-content: flex-end;
    margin-bottom: -5px;

    > * + * {
        margin-left: 15px;
    }

    @media screen and (min-width: $column-layout-at) {
        order: 1;
        margin-bottom: 0;
    }
}

.page-meta-head__menu {
    display: none;
    margin-left: $grid-gap-sm;

    @media screen and (min-width: $menu-visible-at) {
        display: block;
    }

    &:empty {
        display: none;
    }
}
</style>

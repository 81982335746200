var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ReactBridge',{attrs:{"react-component":_vm.ReactCombobox,"props":{
        value: _vm.$props.value,
        label: _vm.$props.label,
        options: _vm.$props.options,
        multiple: _vm.$props.multiple,
        searchFn: _vm.$props.searchFn,
        dataTest: _vm.$props.dataTest,
    }},on:{"change":_vm.handleChange}})
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
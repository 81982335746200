import React, { Suspense, useState } from 'react';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { useRouteParams } from '@/reactBridge/useRouteParams';
import { deleteLocation } from '@/constructionProjects/api/projects';
import { useTranslation } from 'react-i18next';
import { Flyout } from '@/constructionProjects/components/Flyout';
import { CreateLocation } from '@/constructionProjects/components/CreateLocation';
import { useAbility } from '@/reactBridge/useAbility';
import { LocationCard } from '@/constructionProjects/components/LocationCard';
import { LocationEmptyState } from '@/constructionProjects/components/LocationEmptyState';
import { constructionProjectByIdQuery, constructionProjectKeys, constructionProjectLocationsQuery } from '../queries';
import { EditLocation } from '@/constructionProjects/components/EditLocation';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { queryClient } from '@/reactBridge/queryClient';
import Toaster from '@/services/Toaster';
import { PlusIcon } from '../components/icons/PlusIcon';
import { Modal } from '@/constructionProjects/components/Modal';
import { DeleteIcon } from '@schuettflix/icons-react';
import { ContextMenu } from '@schuettflix/react-components';
import { FlyoutPage } from '../components/FlyoutPage';
const LocationsList = ({ onClickLocation, onClickCreateFromLocationDraft }) => {
    const { id } = useRouteParams();
    const locations = useQuery({
        ...constructionProjectLocationsQuery(id),
        suspense: true,
    });
    if (!locations.data) {
        return null;
    }
    const renderContent = () => {
        if (locations.data.locations.length === 0) {
            return (<LocationEmptyState constructionProjectId={id} onCreateLocationClick={onClickCreateFromLocationDraft}/>);
        }
        return locations.data.locations.map(location => (<LocationCard key={location.id} location={location} onClick={() => onClickLocation(location)}/>));
    };
    return <div className="flex h-full flex-col gap-4 overflow-auto p-4">{renderContent()}</div>;
};
const _ConstructionProjectLocationsPage = () => {
    const canCreateConstructionProjectLocation = useAbility('createConstructionProject');
    const { vueRouter } = useVueRouter();
    const { id } = useRouteParams();
    const { t } = useTranslation();
    const [currentLocationDraft, setCurrentLocationDraft] = useState();
    const [createLocationFlyoutOpen, setCreateLocationFlyoutOpen] = useState(false);
    const [editLocationFlyoutOpen, setEditLocationFlyoutOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] = useState(false);
    const [constructionProject, locations] = useQueries({
        queries: [
            {
                ...constructionProjectByIdQuery(id),
                suspense: true,
            },
            {
                ...constructionProjectLocationsQuery(id),
                suspense: true,
            },
        ],
    });
    const closeCreateLocationFlyout = () => {
        setCreateLocationFlyoutOpen(false);
    };
    const handleLocationCreated = (location) => {
        AnalyticsService.trackEvent('construction_project', {
            step: 'location_added',
            constructionProjectId: location.constructionProjectId,
        });
        closeCreateLocationFlyout();
    };
    const openCreateLocationFlyout = () => {
        AnalyticsService.trackEvent('construction_project', {
            step: 'location_addform_opened',
            constructionProjectId: id,
        });
        setCreateLocationFlyoutOpen(true);
    };
    const deleteLocationMutation = useMutation({
        mutationFn: deleteLocation,
        onSuccess: async () => {
            AnalyticsService.trackEvent('construction_project', {
                step: 'location_delete_success',
                locationId: selectedLocation?.id,
                constructionProjectId: id,
            });
            setSelectedLocation(null);
            await queryClient.invalidateQueries(constructionProjectKeys.locations(id));
            Toaster.success(t('pages.constructionProject.deleteLocation.toaster.success'));
        },
        onError: (error) => {
            const errorMessage = error.response?.data?.message;
            if (error.response?.status == 409) {
                return Toaster.error(errorMessage);
            }
            return Toaster.error(t('pages.constructionProject.deleteLocation.toaster.unspecifiedServerError'));
        },
    });
    const handleLocationDeletion = () => {
        setIsOpenDeleteConfirmation(false);
        if (!selectedLocation)
            return;
        deleteLocationMutation.mutate(selectedLocation.id);
        AnalyticsService.trackEvent('construction_project', {
            step: 'location_delete_confirmation',
            locationId: selectedLocation?.id,
            constructionProjectId: id,
        });
    };
    const triggerLocationDeleteModal = () => {
        setIsOpenDeleteConfirmation(true);
        AnalyticsService.trackEvent('construction_project', {
            step: 'location_delete_trigger',
            locationId: selectedLocation?.id,
            constructionProjectId: id,
        });
    };
    if (!constructionProject.data || !locations.data) {
        return null;
    }
    return (<>
            <FlyoutPage screenName="client-location-list" headerTitle={constructionProject.data.name} headerSubtitle={t('pages.constructionProject.projectLocations.header.title')} headerBackgroundColor={constructionProject.data.color} headerActions={canCreateConstructionProjectLocation && (<PlusIcon className="cursor-pointer" onClick={openCreateLocationFlyout} size="sm" data-test="create-construction-project-location"/>)} body={<LocationsList onClickLocation={location => {
                setSelectedLocation(location);
                setEditLocationFlyoutOpen(true);
            }} onClickCreateFromLocationDraft={locationDraft => {
                setCurrentLocationDraft(locationDraft);
                openCreateLocationFlyout();
            }}/>} onCloseAnimationEnd={() => vueRouter.back()}/>
            <Flyout screenName="client-location-create" show={createLocationFlyoutOpen} onClose={closeCreateLocationFlyout} body={<CreateLocation constructionProjectId={id} onCreate={handleLocationCreated} onCancel={closeCreateLocationFlyout} locationDraft={currentLocationDraft} disableSubmit={!createLocationFlyoutOpen}/>} headerBackgroundColor={constructionProject.data.color} headerTitle={t('pages.constructionProject.projectLocations.createLocationFlyout.title')}/>
            {selectedLocation && (<Flyout screenName="client-location-edit" appear show={editLocationFlyoutOpen} body={<EditLocation onEdit={() => setEditLocationFlyoutOpen(false)} onCancel={() => setEditLocationFlyoutOpen(false)} location={selectedLocation}/>} onClose={() => setEditLocationFlyoutOpen(false)} onCloseAnimationEnd={() => setSelectedLocation(null)} headerTitle={t('pages.constructionProject.projectLocations.editLocationFlyout.title')} headerSubtitle={selectedLocation.name} headerBackgroundColor={constructionProject.data.color} headerActions={<ContextMenu align="left">
                            <ContextMenu.Item label={t('pages.constructionProject.deleteLocation.contextMenu.deleteButton')} icon={DeleteIcon} onClick={triggerLocationDeleteModal} disabled={!canCreateConstructionProjectLocation}/>
                        </ContextMenu>}/>)}
            <Modal isOpen={isOpenDeleteConfirmation} onClose={() => setIsOpenDeleteConfirmation(false)} onConfirm={handleLocationDeletion} title={t('pages.constructionProject.deleteLocation.dialog.title')} subtitle={t('pages.constructionProject.deleteLocation.dialog.subtitle')} cancelButtonLabel={t('pages.constructionProject.deleteLocation.dialog.cancelButton')} confirmButtonLabel={t('pages.constructionProject.deleteLocation.dialog.confirmButton')}/>
        </>);
};
export const ConstructionProjectLocationsPage = asVueComponent(() => (<Suspense>
        <_ConstructionProjectLocationsPage />
    </Suspense>));

import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { updateFavoriteStatus } from '../api/projects';
import { ProjectCard } from './ProjectCard';
import { CONSTRUCTION_PROJECT_DETAILS_ROUTE } from '../constants';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { constructionProjectKeys } from '../queries';
import { FavoritableHint } from '@/constructionProjects/components/FavoritableHint';
export const ConstructionProjectsList = ({ className, title, projects, favoritable = true, emptyState = null, showFavoritableHint = false, }) => {
    const { vueRouter } = useVueRouter();
    const queryClient = useQueryClient();
    const [indexLastFavoriteProject] = React.useState(projects.filter(project => project.isFavorite).length - 1);
    const mutation = useMutation(updateFavoriteStatus, {
        onMutate: ({ constructionProjectId, isFavorite }) => {
            const previousResponse = queryClient.getQueryData(constructionProjectKeys.list());
            if (previousResponse) {
                queryClient.setQueryData(constructionProjectKeys.list(), previousProjects => {
                    if (!previousProjects) {
                        return previousProjects;
                    }
                    return {
                        ...previousProjects,
                        constructionProjects: previousProjects.constructionProjects.map(project => project.id === constructionProjectId ? { ...project, isFavorite } : project),
                    };
                });
            }
            return { previousResponse };
        },
        onError: (_error, _variables, context) => {
            queryClient.setQueryData(constructionProjectKeys.list(), context?.previousResponse);
        },
    });
    const handleCardClick = (constructionProjectId) => {
        vueRouter.push({ name: CONSTRUCTION_PROJECT_DETAILS_ROUTE, params: { id: constructionProjectId } });
    };
    const handleUpdateFavoriteStatus = (constructionProjectId, isFavorite) => {
        if (favoritable) {
            mutation.mutate({ constructionProjectId, isFavorite });
            AnalyticsService.trackEvent('construction_project', {
                step: isFavorite ? 'construction_project_favorite' : 'construction_project_unfavorite',
                constructionProjectId,
            });
        }
    };
    return (<div className={className}>
            {title && <h2 className="font-copy-lg-strong pb-4">{title}</h2>}
            {showFavoritableHint && <FavoritableHint className="mb-8"/>}
            {projects.length > 0 ? (<div className="flex flex-col gap-4">
                    {projects.map((project, index) => (<ProjectCard key={project.id} onCardClick={() => handleCardClick(project.id)} onStarClick={() => handleUpdateFavoriteStatus(project.id, !project.isFavorite)} className={index === indexLastFavoriteProject ? 'mb-4' : ''} {...project}/>))}
                </div>) : (emptyState)}
        </div>);
};

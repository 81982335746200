import _every from 'lodash/every';
import OfferBlockVehicleClassView from '@/pages/Order/components/OfferBlockVehicleClassView';
import { revertLocalizedValue } from '@/services/utils/localization';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import ProductView from '@/models/ProductView';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';
import { isOrderLineItemGroupTypeDisposal } from '@/constants/disposal';

export default class OfferBlockView {
    constructor() {
        this._type = null;
        this._qty = null;
        this._productNameLocalized = null;
        this._product = null;
        this._billingMethod = null;
        this._materialPurchasePrice = null;
        this._materialRetailPrice = null;
        this._transportPurchasePrice = null;
        this._transportRetailPrice = null;
        this._shippingPurchasePrice = null;
        this._shippingRetailPrice = null;
        this._totalPurchasePrice = null;
        this._totalRetailPrice = null;
        this._totalGrossRetailPrice = null;
        this._totalTransports = 0;
        this._totalTons = 0;
        this._groupedVehicleClasses = [];
        this._taxRate = null;
        this._accompanyingAnalysisImageUuids = [];
        this._documents = [];
        this._factoryProductVariantNote = null;
        this._loadingSiteLocation = null;
        this._loadingSiteInformation = null;
        this._loadingSiteImage = null;
        this._customPositions = null;
    }

    /**
     * Create a view model from quote data (e.g. API response data)
     * @param quote
     * @return {OfferBlockView|null}
     */
    static createFromQuote(quote) {
        return this.createFromOrder(quote);
    }

    /**
     * Create a view model from order data (e.g. API response data)
     * @param order
     * @return {OfferBlockView|null}
     */
    static createFromOrder(order) {
        if (!order) return null;

        const firstLineItemGroup = order.lineItemGroups[0];

        const block = new OfferBlockView();

        if (firstLineItemGroup.projectId) {
            if (firstLineItemGroup.type === ORDER_LINE_ITEM_GROUP_TYPE.DELIVERY) {
                block.type = BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DELIVERY;
            }
            if (firstLineItemGroup.type === ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT) {
                block.type = BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_SHIPMENT;
            }
            if (firstLineItemGroup.type === ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_NON_HAZARDOUS_WASTE) {
                block.type = BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DISPOSAL_NON_HAZARDOUS;
            }
            if (firstLineItemGroup.type === ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_HAZARDOUS_WASTE) {
                block.type = BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DISPOSAL_HAZARDOUS;
            }
        } else {
            block.type = firstLineItemGroup.type;
        }

        block.qty = firstLineItemGroup.qty;
        block.materialPurchasePrice = firstLineItemGroup.materialPurchasePrice;
        block.materialRetailPrice = firstLineItemGroup.materialRetailPrice;
        block.transportPurchasePrice = firstLineItemGroup.transportPurchasePrice;
        block.transportRetailPrice = firstLineItemGroup.transportRetailPrice;
        block.shippingPurchasePrice = firstLineItemGroup.shippingPurchasePrice;
        block.shippingRetailPrice = firstLineItemGroup.shippingRetailPrice;
        block.totalPurchasePrice = firstLineItemGroup.totalPurchasePrice;
        block.totalRetailPrice = firstLineItemGroup.totalRetailPrice;
        block.totalGrossRetailPrice = firstLineItemGroup.totalGrossRetailPrice;
        block.totalTransports = firstLineItemGroup.lineItems.length;

        if (firstLineItemGroup.lineItems.length > 0) {
            block.totalTons = firstLineItemGroup.lineItems.reduce((acc, curr) => {
                const qty = curr.type === ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT ? curr.vehiclePayload : curr.qty;
                return acc + qty;
            }, 0);
        }

        block.groupedVehicleClasses =
            firstLineItemGroup.type === ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT
                ? OfferBlockVehicleClassView.createCollectionFromShipmentLineItemGroup(firstLineItemGroup)
                : OfferBlockVehicleClassView.createCollectionFromLineItemGroup(firstLineItemGroup);
        block.productNameLocalized = revertLocalizedValue(firstLineItemGroup?.product?.productName);
        block.billingMethod = firstLineItemGroup.billingMethod;

        if (firstLineItemGroup.type === ORDER_LINE_ITEM_GROUP_TYPE.PICKUP) {
            block.taxRate = firstLineItemGroup.taxRate;
        } else if (firstLineItemGroup.lineItems.length > 0) {
            block.taxRate = firstLineItemGroup.lineItems[0].taxRate;
        }

        if (isOrderLineItemGroupTypeDisposal(firstLineItemGroup.type)) {
            block.customPositions = firstLineItemGroup?.customPositions;
            block.lineItemTotalRetailPrice = firstLineItemGroup?.lineItems[0].totalRetailPrice;
            block.product = ProductView.create(firstLineItemGroup?.product, firstLineItemGroup?.productVariant);
            // For disposal, the total puchase/retail price per transport, is to be found only inside
            // a specific line item, according to the response.
            // @TODO: In case the value of this property is calculated the same as the total purchase/retail
            // price for the other order types, then we should be getting it from the same object.
            block.purchasePricePerTransport = firstLineItemGroup?.lineItems[0].totalPurchasePrice;
            block.retailPricePerTransport = firstLineItemGroup?.lineItems[0].totalRetailPrice;
        }

        return block;
    }

    /**
     * Get type
     * @return {string|null}
     */
    get type() {
        return this._type;
    }

    /**
     * Set type
     * @param {string|null} value
     */
    set type(value) {
        this._type = value ?? null;
    }

    /**
     * Get qty
     * @return {number|null}
     */
    get qty() {
        return this._qty;
    }

    /**
     * Set number
     * @param {number} value
     */
    set qty(value) {
        this._qty = value ?? null;
    }

    /**
     * Get productNameLocalized
     * @return {string}
     */
    get productNameLocalized() {
        return this._productNameLocalized;
    }

    /**
     * Set productNameLocalized
     * @param {string} value
     */
    set productNameLocalized(value) {
        this._productNameLocalized = value ?? null;
    }

    /**
     * Get billing method
     * @return {string|null}
     */
    get billingMethod() {
        return this._billingMethod;
    }

    /**
     * Set billing method
     * @param {string} value
     */
    set billingMethod(value) {
        this._billingMethod = value ?? null;
    }

    /**
     * Get material purchase price
     * @return {number|null}
     */
    get materialPurchasePrice() {
        return this._materialPurchasePrice;
    }

    /**
     * Set material purchase price
     * @param {number} value
     */
    set materialPurchasePrice(value) {
        this._materialPurchasePrice = value ?? null;
    }

    /**
     * Get transport purchase price
     * @return {number|null}
     */
    get transportPurchasePrice() {
        return this._transportPurchasePrice;
    }

    /**
     * Set transport purchase price
     * @param {number} value
     */
    set transportPurchasePrice(value) {
        this._transportPurchasePrice = value ?? null;
    }

    /**
     * Get material retail price
     * @return {number|null}
     */
    get materialRetailPrice() {
        return this._materialRetailPrice;
    }

    /**
     * Set material retail price
     * @param {number} value
     */
    set materialRetailPrice(value) {
        this._materialRetailPrice = value ?? null;
    }

    /**
     * Get transport retail price
     * @return {number|null}
     */
    get transportRetailPrice() {
        return this._transportRetailPrice;
    }

    /**
     * Set transport retail price
     * @param {number} value
     */
    set transportRetailPrice(value) {
        this._transportRetailPrice = value ?? null;
    }

    /**
     * Get line items grouped by vehicle classes
     * @return {OfferBlockVehicleClassView[]}
     */
    get groupedVehicleClasses() {
        return this._groupedVehicleClasses;
    }

    /**
     * Set line items grouped by vehicle classes
     * @param {OfferBlockVehicleClassView[]} value
     */
    set groupedVehicleClasses(value) {
        this._groupedVehicleClasses = value ?? [];
    }

    /**
     * Get shipping retail price
     * @return {number|null}
     */
    get shippingRetailPrice() {
        return this._shippingRetailPrice;
    }

    /**
     * Set shipping retail price
     * @param {number} value
     */
    set shippingRetailPrice(value) {
        this._shippingRetailPrice = value ?? null;
    }

    /**
     * Get shipping purchase price
     * @return {number|null}
     */
    get shippingPurchasePrice() {
        return this._shippingPurchasePrice;
    }

    /**
     * Set shipping purchase price
     * @param {number} value
     */
    set shippingPurchasePrice(value) {
        this._shippingPurchasePrice = value ?? null;
    }
    /**
     * Get total purchase price
     * @return {number|null}
     */
    get totalPurchasePrice() {
        return this._totalPurchasePrice;
    }

    /**
     * Set total purchase price
     * @param {number} value
     */
    set totalPurchasePrice(value) {
        this._totalPurchasePrice = value ?? null;
    }

    /**
     * Get total retail price
     * @return {number|null}
     */
    get totalRetailPrice() {
        return this._totalRetailPrice;
    }

    /**
     * Set total retail price
     * @param {number} value
     */
    set totalRetailPrice(value) {
        this._totalRetailPrice = value ?? null;
    }

    /**
     * Get total gross retail price
     * @return {number|null}
     */
    get totalGrossRetailPrice() {
        return this._totalGrossRetailPrice;
    }

    /**
     * Set total gross retail price
     * @param value
     */
    set totalGrossRetailPrice(value) {
        this._totalGrossRetailPrice = value ?? null;
    }

    /**
     * Get totalTransports
     * @return {number|null}
     */
    get totalTransports() {
        return this._totalTransports;
    }

    /**
     * Set totalTransports
     * @param {number} value
     */
    set totalTransports(value) {
        this._totalTransports = value ?? null;
    }

    /**
     * Get total tons
     * @return {number}
     */
    get totalTons() {
        return this._totalTons;
    }

    /**
     * Set total tons
     * @param {number} value
     */
    set totalTons(value) {
        this._totalTons = value ?? null;
    }

    /**
     * Get tax rate
     * @return {number|null}
     */
    get taxRate() {
        return this._taxRate;
    }

    /**
     * Set tax rate
     * @param {number} value
     */
    set taxRate(value) {
        this._taxRate = value ?? null;
    }

    // -----------------------------------------------------------------------------------------------------------------

    get isTypeDelivery() {
        return this.type === ORDER_LINE_ITEM_GROUP_TYPE.DELIVERY;
    }

    get isTypeShipment() {
        return this.type === ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT;
    }

    get isTypePickup() {
        return this.type === ORDER_LINE_ITEM_GROUP_TYPE.PICKUP;
    }

    get isTypeProjectPositionDisposal() {
        return ['project-position-waste', 'project-position-dangerousWaste'].includes(this.type);
    }

    get isTypeProjectPositionDelivery() {
        return this.type === 'project-position-delivery';
    }

    get isTypeProjectPositionShipment() {
        return this.type === 'project-position-shipment';
    }

    get isBaseTypeShipment() {
        return this.isTypeShipment || this.isTypeProjectPositionShipment;
    }

    get purchaseUnitPrice() {
        if (this.isTypePickup) {
            return this.materialPurchasePrice / (this.qty / 1000);
        } else {
            return (this.materialPurchasePrice + this.transportPurchasePrice) / (this.qty / 1000);
        }
    }

    get unitPrice() {
        if (this.isTypePickup) {
            return this.materialRetailPrice / (this.qty / 1000);
        } else {
            return (this.materialRetailPrice + this.transportRetailPrice) / (this.qty / 1000);
        }
    }

    get initialRetailPrice() {
        return this.materialRetailPrice + this.transportRetailPrice;
    }

    get totalPurchaseMaterialTransportPrice() {
        return this.materialPurchasePrice + this.transportPurchasePrice;
    }

    get totalRetailMaterialTransportPrice() {
        return this.materialRetailPrice + this.transportRetailPrice;
    }

    /**
     * Check if all the vehicle classes have no mixed prices in their respective groups
     * @return {boolean}
     */
    get hasEqualPrices() {
        if (this.isTypePickup) return true;
        return _every(this.groupedVehicleClasses, 'isEqual') && this.groupedVehicleClasses.length === 1;
    }

    /**
     * Get total purchase price incl. taxes
     * @return {number}
     */
    get totalPurchasePriceInclTax() {
        return this.totalPurchasePrice + this.totalPurchasePrice * (this.taxRate / 100);
    }

    /**
     * Get accompanyingAnalysisImageUuids
     * @return {array}
     */
    get accompanyingAnalysisImageUuids() {
        return this._accompanyingAnalysisImageUuids;
    }

    /**
     * Set accompanyingAnalysisImageUuids
     * @return {object}
     */
    set accompanyingAnalysisImageUuids(analysis) {
        this._accompanyingAnalysisImageUuids = analysis;
    }

    /**
     * Get documents
     * @return {array}
     */
    get documents() {
        return this._documents;
    }

    /**
     * Set documents
     * @return {object}
     */
    set documents(document) {
        this._documents = document;
    }

    /**
     * Get factoryProductVariantNote
     * @return {string|null}
     */
    get factoryProductVariantNote() {
        return this._factoryProductVariantNote;
    }

    /**
     * Set factoryProductVariantNote
     * @return {string}
     */
    set factoryProductVariantNote(note) {
        this._factoryProductVariantNote = note;
    }

    /**
     * Get loadingSiteLocation
     * @return {object|null}
     */
    get loadingSiteLocation() {
        return this._loadingSiteLocation;
    }

    /**
     * Set loadingSiteLocation
     * @return {objet}
     */
    set loadingSiteLocation(site) {
        this._loadingSiteLocation = site;
    }

    /**
     * Get laodingSiteInformation
     * @return {string|null}
     */
    get loadingSiteInformation() {
        return this._loadingSiteInformation;
    }

    /**
     * Set loadingSiteInformation
     * @return {string}
     */
    set loadingSiteInformation(site) {
        this._loadingSiteInformation = site;
    }

    /**
     * Get loadingSiteImage
     * @return {object|null}
     */
    get loadingSiteImage() {
        return this._loadingSiteImage;
    }

    /**
     * Set loadingSiteImage
     * @return {objet}
     */
    set loadingSiteImage(site) {
        this._loadingSiteImage = site;
    }

    /**
     * Get customPositions
     * @return {object|null}
     */
    get customPositions() {
        return this._customPositions;
    }

    /**
     * Set customPositions
     * @return {objet}
     */
    set customPositions(site) {
        this._customPositions = site;
    }

    /**
     * Get productd
     * @return {object|null}
     */
    get productd() {
        return this._product;
    }

    /**
     * Set productd
     * @return {objet}
     */
    set productd(site) {
        this._product = site;
    }
}

<template>
    <component :is="getTripComponent()" />
</template>

<script>
import { CONTEXT_CLIENT, CONTEXT_CARRIER_DRIVER } from '@/constants/context';
import routeContext from '@/plugins/mixins/routeContext';

import CarrierActiveTrips from './CarrierActiveTrips';
import PickupActiveTrips from './PickupActiveTrips';

export default {
    name: 'TransportListPage',
    components: {
        CarrierActiveTrips,
        PickupActiveTrips,
    },
    mixins: [routeContext],
    methods: {
        getTripComponent() {
            if (this.inRouteContext(CONTEXT_CARRIER_DRIVER) && this.$can('listActiveTrips')) {
                return CarrierActiveTrips;
            }
            if (this.inRouteContext(CONTEXT_CLIENT) && this.$can('listActivePickupTrips')) {
                return PickupActiveTrips;
            }

            return 'div';
        },
    },
};
</script>

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { disableNotifications, enableNotifications, archiveConstructionProject, ConstructionProjectStatus, } from '@/constructionProjects/api/projects';
import { useRouteParams } from '@/reactBridge/useRouteParams';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { InfoIcon } from '../components/icons/InfoIcon';
import { MenuTile } from '@/constructionProjects/components/MenuTile';
import { DirectionIcon } from '@/constructionProjects/components/icons/DirectionIcon';
import { constructionProjectByIdQuery, constructionProjectKeys, constructionProjectNotificationSettingQuery, } from '@/constructionProjects/queries';
import { queryClient } from '@/reactBridge/queryClient';
import Toaster from '@/services/Toaster';
import { Link, ToggleSwitch } from '@schuettflix/react-components';
import { FlyoutPage } from '@/constructionProjects/components/FlyoutPage';
import { Modal } from '@/constructionProjects/components/Modal';
import { CONSTRUCTION_PROJECT_CLIENT_SETTINGS_EDIT_ROUTE, CONSTRUCTION_PROJECT_DETAILS_ROUTE, CONSTRUCTION_PROJECT_CLIENT_SETTINGS_ROUTE, CONSTRUCTION_PROJECT_LOCATIONS_ROUTE, CONSTRUCTION_PROJECT_LIST_ROUTE, } from '@/constructionProjects/constants';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import store from '@/store';
import { useVuexState } from '@/reactBridge/useVuex';
const PageContent = () => {
    const { vueRouter } = useVueRouter();
    const { t } = useTranslation();
    const { id } = useRouteParams();
    const [isOpenArchiveConfirmation, setIsOpenArchiveConfirmation] = useState(false);
    const entryPoint = useVuexState(store, state => state.constructionProject.entryPoint);
    const constructionProjectQuery = useQuery({
        ...constructionProjectByIdQuery(id),
        suspense: true,
        enabled: !!id,
    });
    const notificationQuery = useQuery({
        ...constructionProjectNotificationSettingQuery(id),
        suspense: true,
        enabled: !!id,
    });
    const { mutate: toggleNotification } = useMutation({
        mutationFn: async (value) => {
            if (value) {
                await enableNotifications(id);
            }
            else {
                await disableNotifications(id);
            }
        },
        onMutate: async (value) => {
            await queryClient.cancelQueries({
                queryKey: constructionProjectKeys.notificationSetting(id),
            });
            const previousNotificationSetting = queryClient.getQueryData(constructionProjectKeys.notificationSetting(id));
            queryClient.setQueryData(constructionProjectKeys.notificationSetting(id), old => (old !== undefined ? { ...old, enabled: value } : undefined));
            return { previousNotificationSetting };
        },
        onError: (_, __, context) => {
            queryClient.setQueryData(constructionProjectKeys.notificationSetting(id), context?.previousNotificationSetting);
            Toaster.error(t('pages.constructionProject.projectSettings.sections.notifications.errors.updateFailed'));
        },
        onSettled: () => {
            return queryClient.invalidateQueries(constructionProjectKeys.notificationSetting(id));
        },
    });
    const archiveProjectMutation = useMutation({
        mutationFn: archiveConstructionProject,
        onSuccess: async () => {
            AnalyticsService.trackEvent('construction_project', {
                step: 'construction_project_archived',
                constructionProjectId: id,
            });
            queryClient.invalidateQueries(['construction-projects', 'list']);
            await vueRouter.push({
                name: CONSTRUCTION_PROJECT_LIST_ROUTE,
                query: { activeTab: ConstructionProjectStatus.Archived },
            });
            Toaster.success(t('pages.constructionProject.projectSettings.archive.onSuccess'));
        },
        onError: () => {
            return Toaster.error(t('pages.constructionProject.projectSettings.archive.onError'));
        },
    });
    const isNotArchivedProject = constructionProjectQuery.data?.status !== ConstructionProjectStatus.Archived;
    const handleArchiveProject = () => {
        setIsOpenArchiveConfirmation(false);
        archiveProjectMutation.mutate(id);
    };
    const handleBackButton = () => {
        if (entryPoint?.name) {
            vueRouter.push({ name: entryPoint?.name });
        }
        else {
            vueRouter.back();
        }
    };
    return (<FlyoutPage screenName="client-constructionproject-settings" onCloseAnimationEnd={handleBackButton} headerTitle={constructionProjectQuery.data?.name || ''} headerSubtitle={t('pages.constructionProject.projectSettings.header.title')} headerBackgroundColor={constructionProjectQuery.data?.color} body={constructionProjectQuery.data && (<div className="flex h-full flex-col overflow-auto bg p-4">
                        <section className="pb-12">
                            <p className="font-headline-md-strong mb-2">
                                {t('pages.constructionProject.projectSettings.sections.general.title')}
                            </p>
                            <p className="font-copy-md mb-6">
                                {t('pages.constructionProject.projectSettings.sections.general.description')}
                            </p>
                            <ul className="divide-y divide-light-gray-400 rounded bg-surface shadow-low">
                                <MenuTile dataTest="construction-project-settings-page-project-edit-link" to={{
                name: `${CONSTRUCTION_PROJECT_DETAILS_ROUTE}__${CONSTRUCTION_PROJECT_CLIENT_SETTINGS_ROUTE}__${CONSTRUCTION_PROJECT_CLIENT_SETTINGS_EDIT_ROUTE}`,
                params: { id },
            }} icon={() => <InfoIcon multicolor size={'sm'}/>} label={t('pages.constructionProject.projectSettings.button.projectInfos.title')} description={t('pages.constructionProject.projectSettings.button.projectInfos.subtitle')}/>
                            </ul>
                        </section>

                        <section className="pb-12">
                            <p className="font-headline-md-strong mb-2">
                                {t('pages.constructionProject.projectSettings.sections.locations.title')}
                            </p>
                            <p className="font-copy-md mb-6">
                                {t('pages.constructionProject.projectSettings.sections.locations.description')}
                            </p>
                            <ul className="divide-y divide-light-gray-400 rounded bg-surface shadow-low">
                                <MenuTile dataTest="construction-project-settings-page-locations-link" to={{
                name: `${CONSTRUCTION_PROJECT_DETAILS_ROUTE}__${CONSTRUCTION_PROJECT_CLIENT_SETTINGS_ROUTE}__${CONSTRUCTION_PROJECT_LOCATIONS_ROUTE}`,
                params: { id },
            }} appendPath icon={() => <DirectionIcon multicolor size={'sm'}/>} label={t('pages.constructionProject.projectSettings.button.locations.title')} description={t('pages.constructionProject.projectSettings.button.locations.description')}/>
                            </ul>
                        </section>

                        <section className="pb-12">
                            <p className="font-headline-md-strong mb-2">
                                {t('pages.constructionProject.projectSettings.sections.notifications.title')}
                            </p>
                            <p className="font-copy-md mb-6">
                                {t('pages.constructionProject.projectSettings.sections.notifications.description')}
                            </p>
                            <div className="flex justify-between">
                                <p className="font-copy-md-strong">
                                    {t('pages.constructionProject.projectSettings.sections.notifications.toggle.headline')}
                                </p>
                                <ToggleSwitch checked={notificationQuery.data?.enabled ?? false} onChange={toggleNotification}/>
                            </div>
                        </section>

                        {isNotArchivedProject && (<section className="pb-12">
                                <p className="font-headline-md-strong mb-2">
                                    {t('pages.constructionProject.projectSettings.sections.archive.title')}
                                </p>
                                <p className="font-copy-md mb-6">
                                    {t('pages.constructionProject.projectSettings.sections.archive.description')}
                                </p>
                                <div className="flex justify-end">
                                    <Link label={t('pages.constructionProject.projectSettings.sections.archive.button.text')} onClick={() => setIsOpenArchiveConfirmation(true)} className="font-copy-md-strong"/>
                                </div>
                            </section>)}
                        {isNotArchivedProject && (<Modal isOpen={isOpenArchiveConfirmation} onClose={() => setIsOpenArchiveConfirmation(false)} onConfirm={handleArchiveProject} title={t('pages.constructionProject.projectSettings.archive.modal.title')} subtitle={t('pages.constructionProject.projectSettings.archive.modal.subtitle')} cancelButtonLabel={t('pages.constructionProject.projectSettings.archive.modal.onCancel')} confirmButtonLabel={t('pages.constructionProject.projectSettings.archive.modal.onConfirm')}/>)}
                    </div>)}/>);
};
const _ConstructionProjectSettingsPage = () => {
    return <PageContent />;
};
export const ConstructionProjectSettings = asVueComponent(_ConstructionProjectSettingsPage);

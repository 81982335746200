<template>
    <section>
        <div class="card w-full text-left">
            <span class="font-copy-sm-strong block">
                {{ $t('pages.checkout.disposal.summary.tiles.constructionSite.title') }}
            </span>
            <span class="font-copy-md-strong mt-6 block">{{ clientName }}</span>
            <HorizontalDivider class="my-4" />
            <div class="flex">
                <BuildingConstructionSiteIcon class="flex-shrink-0" width="32" height="32" />
                <div class="ml-2 flex flex-col overflow-hidden">
                    <span class="font-copy-sm-strong block truncate">{{ constructionSiteName }}</span>
                    <FormattedAddress class="mt-1 block" decorate-location-code :address="constructionSiteAddress" />
                </div>
            </div>
        </div>
        <div v-if="editable" class="mt-2 text-right">
            <Button
                arrow-right
                @click="
                    $emit('edit', {
                        section: 'construction-site',
                        routeName: 'checkout-project-disposal-hazardous-constructionSite-selection',
                    })
                "
            >
                {{ $t('pages.checkout.disposal.summary.actions.change.constructionSite') }}
            </Button>
        </div>
    </section>
</template>

<script>
import Button from '@/components/Button/Button';
import FormattedAddress from '@/components/FormattedAddress';
import BuildingConstructionSiteIcon from '@/assets/icons/flag.svg';
import HorizontalDivider from '@/_components/HorizontalDivider/HorizontalDivider';

export default {
    name: 'ConstructionSite',
    components: {
        HorizontalDivider,
        FormattedAddress,
        Button,
        BuildingConstructionSiteIcon,
    },
    props: {
        constructionSiteAddress: {
            type: Object,
            default: null,
        },
        constructionSiteName: {
            type: String,
            default: null,
        },
        clientName: {
            type: String,
            default: null,
        },
        editable: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

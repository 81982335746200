import GlobalVariables from '@/services/GlobalVariables';
const orgMarketFeatureTemplates = async () => {
    try {
        const parsedJSON = JSON.parse(await GlobalVariables.get('organization_market_feature_template', ''));
        return parsedJSON;
    }
    catch (error) {
        return {};
    }
};
export const getPreselectedFeatures = async () => {
    const featureTemplates = await orgMarketFeatureTemplates();
    const preselectedFeatures = {};
    for (const [orgType, featureTemplate] of Object.entries(featureTemplates)) {
        preselectedFeatures[orgType] = [];
        for (const [featureName, isPreselected] of Object.entries(featureTemplate)) {
            if (isPreselected) {
                preselectedFeatures[orgType]?.push(featureName);
            }
        }
    }
    return preselectedFeatures;
};

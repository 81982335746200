<template>
    <div
        :class="{
            'checkbox-input--active': isActive,
            'checkbox-input--disabled': disabled,
            'checkbox-input--small': small,
            'checkbox-input--black': black,
            'checkbox-input--on-dark-surface': onDarkSurface,
        }"
        class="checkbox-input"
        @click.stop
    >
        <input
            :id="eid"
            ref="checkbox"
            :checked="isActive"
            :disabled="disabled"
            :name="name"
            :value="option"
            type="checkbox"
            class="checkbox-input__input"
            @change="handleChange"
        />
        <label
            :for="eid"
            class="checkbox-input__label block"
            :class="{
                'checkbox-input__label--black': black && isActive,
                'checkbox-input__label--on-dark-surface': onDarkSurface && isActive,
            }"
        >
            <CheckMarkIcon
                v-if="isActive"
                class="checkbox-input__orb"
                :class="{
                    'checkbox-input__orb--black': black,
                    'checkbox-input__orb--on-dark-surface': onDarkSurface,
                }"
            />
            <IntermediateIcon
                v-else-if="isPartiallyActive"
                class="checkbox-input__orb"
                :class="{
                    'checkbox-input__orb--on-dark-surface--partly': onDarkSurface,
                }"
            />
        </label>
    </div>
</template>

<script>
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import _intersection from 'lodash/intersection';

import CheckMarkIcon from '@/assets/icons/micro/tick.svg';
import IntermediateIcon from '@/assets/icons/micro/minus.svg';

const INTERSECTION_LEVEL_NONE = 'intersection.none';
const INTERSECTION_LEVEL_PARTIALLY = 'intersection.partially';
const INTERSECTION_LEVEL_FULL = 'intersection.full';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Checkbox',
    components: {
        CheckMarkIcon,
        IntermediateIcon,
    },
    props: {
        name: {
            type: String,
            default: null,
        },
        value: {
            type: [Boolean, Array],
            default: null,
        },
        option: {
            type: [String, Boolean, Number, Object, Array],
            default: null,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        black: {
            type: Boolean,
            default: false,
        },
        onDarkSurface: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            eid: `el${this._uid}`,
        };
    },
    computed: {
        isListInput() {
            return Array.isArray(this.value) && this.option !== null;
        },
        intersectionLevel() {
            if (!this.isListInput || !this.multiple || !Array.isArray(this.option)) {
                return INTERSECTION_LEVEL_NONE;
            }

            const intersections = _intersection(this.value, this.option).length;

            if (intersections === 0) {
                return INTERSECTION_LEVEL_NONE;
            }

            if (intersections < this.option.length) {
                return INTERSECTION_LEVEL_PARTIALLY;
            }

            return INTERSECTION_LEVEL_FULL;
        },
        isPartiallyActive() {
            return this.intersectionLevel === INTERSECTION_LEVEL_PARTIALLY;
        },
        isActive() {
            if (!this.isListInput) {
                return !!this.value;
            }

            if (this.multiple) {
                return this.intersectionLevel === INTERSECTION_LEVEL_FULL;
            }

            // if it's an array, check if it contains the option
            let active = false;
            this.value.forEach(item => {
                if (_isEqual(item, this.option)) {
                    active = true;
                }
            });
            return active;
        },
    },
    methods: {
        handleChange(e) {
            if (this.isListInput) {
                return this.multiple ? this.handleMultipleListChange() : this.handleSingleListChange();
            }

            return this.handleBinaryChange(e);
        },

        handleMultipleListChange() {
            const { option } = this;
            let value = _cloneDeep(this.value);

            if (this.intersectionLevel === INTERSECTION_LEVEL_NONE) {
                value = value.concat(option);
            } else {
                value = value.filter(v => !option.includes(v));
            }

            return this.$emit('input', value);
        },

        handleSingleListChange() {
            const { option } = this;
            const value = _cloneDeep(this.value);

            let index = -1;

            value.forEach((v, i) => {
                if (_isEqual(v, this.option)) {
                    index = i;
                }
            });

            if (index === -1) {
                value.push(option);
            } else {
                value.splice(index, 1);
            }

            return this.$emit('input', value);
        },

        handleBinaryChange(e) {
            return this.$emit('input', e.target.checked ? true : null);
        },
    },
};
</script>

<style lang="scss">
.checkbox-input {
    --input-width: 33px;
    --orb-width: 18px;

    display: inline-block;
    vertical-align: middle;
    width: var(--input-width);
    height: var(--input-width);
    position: relative;
    top: -1px;
}

.checkbox-input {
    &--disabled {
        .checkbox-input__label {
            background-color: $color-mediumGrey;
            border-color: rgba(0, 0, 0, 0.2);
            opacity: 0.3;

            &--black {
                background-color: $sflx-grey-100;
                border: 1px solid $sflx-grey-400;
            }
        }

        .checkbox-input__input {
            cursor: initial;
        }

        .checkbox-input__orb--black {
            .f-base {
                fill: $sflx-grey-400;
            }
        }
    }
}

.checkbox-input__input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 1;
    width: var(--input-width);
    height: var(--input-width);
    padding: 0;
    margin: 0;
}

.checkbox-input__input:focus,
.checkbox-input__input:active {
    outline: 0;

    &--black {
        border: 0;
    }
}

.checkbox-input__label {
    width: var(--input-width);
    height: var(--input-width);
    border: 1px solid $color-grey;
    position: relative;
    transition:
        border 0.2s ease-in,
        background-color 0.2s ease-in;
    background-color: transparent;

    &--black {
        background: $sflx-grey-800;
        border: 0;
    }

    &--on-dark-surface {
        background: white;
        border: 0;
    }
}

.checkbox-input__orb {
    display: inline-block;
    background-color: transparent;
    width: var(--orb-width);
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .f-base {
        fill: $color-red;
    }

    &--black {
        .f-base {
            fill: white;
        }
    }

    &--on-dark-surface {
        .f-base {
            fill: $sflx-grey-800;
        }
    }

    &--on-dark-surface--partly {
        .f-base {
            fill: white;
        }
    }
}

.checkbox-input--small {
    --input-width: 20px;
    --orb-width: 15px;
}
</style>

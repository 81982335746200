var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vehicle-track",class:{
        'vehicle-track--small': _vm.small,
        'vehicle-track--overflow': _vm.overflow,
        'vehicle-track--spaceless': _vm.spaceless,
        [`vehicle-track--vehicle-layout-${_vm.vehicleLayout}`]: true,
    }},[(_vm.vehicles.length)?_c('div',{staticClass:"vehicle-track__inner"},_vm._l((_vm.vehicles),function(vehicle,index){return _c('div',{key:index,staticClass:"vehicle-track__item"},[_c('VehicleClassIconSet',{staticClass:"vehicle-track__icon",attrs:{"icon":vehicle.icon}}),_c('div',{staticClass:"vehicle-track__info"},[_c('span',{staticClass:"font-copy-sm-strong whitespace-nowrap"},[_vm._v(" "+_vm._s(vehicle.count)+" × "+_vm._s(vehicle.name)+" ")]),_c('ReactBridge',{staticClass:"mt-2",attrs:{"react-component":_vm.ProgressBar,"props":{
                        primary: vehicle.loadPercentage,
                        size: 'sm',
                    }}})],1)],1)}),0):_c('div',{staticClass:"vehicle-track__inner"},[_c('div',{staticClass:"vehicle-track__item"},[_c('VehicleClassIconSet',{staticClass:"vehicle-track__icon"}),_vm._m(0)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vehicle-track__info"},[_c('div',{staticClass:"vehicle-track__weight-indicator bg-surface-success"},[_c('div',{staticClass:"w-0 bg"})]),_c('span',{staticClass:"font-copy-sm-strong whitespace-nowrap"},[_vm._v(" ")])])
}]
render._withStripped = true
export { render, staticRenderFns }
<template>
    <div
        :class="{
            'hint--tiny': tiny,
            'hint--center': center,
            'hint--spaceless': spaceless,
            'hint--spaceless-top': spacelessTop,
            'hint--spaceless-x': spacelessX,
            'hint--transparent': transparent,
        }"
        class="hint"
    >
        <slot v-if="showLabel" name="label">
            <span :class="{ 'font-copy-sm-strong': !tiny, 'font-copy-xs-strong': tiny }" class="inline text-critical">
                {{ $t('components.hint.label') }}&nbsp;
            </span>
        </slot>
        <span :class="{ 'font-copy-sm': !tiny, 'font-copy-xs': tiny }" class="inline">
            <slot />
        </span>
    </div>
</template>

<script>
export default {
    name: 'HintComponent',
    props: {
        center: {
            type: Boolean,
            default: false,
        },
        spaceless: {
            type: Boolean,
            default: false,
        },
        spacelessTop: {
            type: Boolean,
            default: false,
        },
        spacelessX: {
            type: Boolean,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        showLabel: {
            type: Boolean,
            default: false,
        },
        tiny: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.hint {
    padding: 20px 15px;
    border-bottom: $border-solid-2px;
    background-color: $color-lightGrey;

    @media only screen and (min-width: $layout-desktop-min) {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.hint--tiny {
    padding-top: 10px;
    padding-bottom: 10px;
}

.hint--transparent {
    background-color: transparent;
    border-color: transparent;
}

.hint--spaceless {
    padding: 0;
}

.hint--spaceless-x {
    padding-right: 0;
    padding-left: 0;
}

.hint--spaceless-top {
    padding-top: 0;
}

.hint--center {
    text-align: center;
}
</style>

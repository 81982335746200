import SimpleDimensionView from '@/models/Vehicle/SimpleDimensionView';
import UserView from '@/models/UserView';
import OrganizationView from '@/models/OrganizationView';

export default class VehicleView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     */
    static create(data) {
        if (!data) return null;

        const view = new VehicleView();

        view.id = data.id ?? null;
        view.deleted = data.deleted ?? false;
        view.isActive = data.isActive ?? false;
        view.licensePlate = data.licensePlate ?? null;
        view.emptyWeight = data.emptyWeight ?? null;
        view.permissibleTotalWeight = data.permissibleTotalWeight ?? null;
        view.image = data.image;
        view.dimension = SimpleDimensionView.createFromCM(data.dimension);
        view.vehicleClass = data.vehicleClass ?? null;
        view.vehicleClassId = data.vehicleClassId ?? null;
        view.lastDriver = UserView.create(data.lastDriver);
        view.organization = OrganizationView.create(data.organization);
        view.organizationId = data.organizationId;
        view.attributes = data.attributes ?? [];

        return view;
    }

    unfold() {
        return {
            id: this.id,
            deleted: this.deleted,
            isActive: this.isActive,
            licensePlate: this.licensePlate.toUpperCase(),
            emptyWeight: this.emptyWeight,
            permissibleTotalWeight: this.permissibleTotalWeight,
            image: this.image,
            dimension: this.dimension?.unfoldToCM(),
            vehicleClass: this.vehicleClass,
            vehicleClassId: this.vehicleClassId,
            lastDriver: this.lastDriver?.unfold(),
            organization: this.organization?.unfold(),
            organizationId: this.organizationId,
            attributes: this.attributes,
        };
    }

    unfoldToEditForm() {
        return {
            id: this.id,
            isActive: this.isActive,
            licensePlate: this.licensePlate.toUpperCase(),
            emptyWeight: this.emptyWeight,
            permissibleTotalWeight: this.permissibleTotalWeight,
            image: this.image,
            dimension: this.dimension?.unfoldToCM(),
            vehicleClassId: this.vehicleClassId,
            attributes: this.attributes,
        };
    }

    constructor() {
        this.id = null;
        this.deleted = null;
        this.isActive = null;
        this.licensePlate = null;
        this.emptyWeight = null;
        this.permissibleTotalWeight = null;
        this.image = null;
        this.dimension = null;
        this.vehicleClassId = null;
        this.lastDriver = null;
        this.organization = null;
        this.organizationId = null;
        this.attributes = [];
    }
}

import { preciseCeil, preciseFloat, preciseFloor } from '@/services/utils';

/**
 * Price data instance
 */
export default class ClientPricingDataView {
    constructor() {
        this._billingMethod = null;
        this._min = null;
        this._max = null;
        this._suggestedPrice = null;
        this._price = null;
        this._transportCount = null;
        this._vehicleClassName = null;
        this._vehiclePayload = null;
        this._vehicleNumAxes = null;
    }

    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} quote
     * @returns {ClientPricingDataView}
     */
    static createFromQuote(quote) {
        const instance = new ClientPricingDataView();

        const firstLineItemGroup = quote.lineItemGroups[0];
        const firstLineItem = firstLineItemGroup?.lineItems[0] ?? null;
        const billingMethod = firstLineItemGroup?.billingMethod;
        const isPricePerTransport = billingMethod === 'fixedPrice';
        const min = firstLineItem?.priceLimitLower;
        const max = firstLineItem?.priceLimitUpper;
        const price = firstLineItem?.transportRetailPrice;
        const vehiclePayload = firstLineItem.vehiclePayload;
        const suggestedPrice = firstLineItem?.priceSuggested;

        instance.billingMethod = billingMethod;
        instance.min = isPricePerTransport ? min : preciseCeil(min / (vehiclePayload / 1000), 1);
        instance.max = isPricePerTransport ? max : preciseFloor(max / (vehiclePayload / 1000), 1);
        instance.suggestedPrice = isPricePerTransport
            ? Math.round(suggestedPrice)
            : preciseFloat(suggestedPrice / (vehiclePayload / 1000), 1);

        instance.price = isPricePerTransport ? Math.round(price) : preciseFloat(price / (vehiclePayload / 1000), 1);

        instance.transportCount = firstLineItemGroup.lineItems?.length;
        instance.vehicleClassName = firstLineItem.vehicleName;
        instance.vehiclePayload = firstLineItem.vehiclePayload;
        instance.vehicleNumAxes = firstLineItem.vehicleNumAxes;

        return instance;
    }

    /**
     * Create a view model from a transport (e.g. API response data)
     * @param {import('@/types/TransportListItem').TransportListItem} transport
     * @returns {ClientPricingDataView}
     */
    static createFromTransport(transport) {
        const instance = new ClientPricingDataView();

        const billingMethod = transport?.billingMethod;
        const isPricePerTransport = billingMethod === 'fixedPrice';
        const min = transport?.priceLimitLower;
        const max = transport?.priceLimitUpper;
        const price = transport?.transportRetailPrice;
        const vehiclePayload = transport.vehiclePayload;
        const suggestedPrice = transport?.transportRetailPrice;

        instance.billingMethod = billingMethod;
        instance.min = isPricePerTransport ? min : preciseCeil(min / (vehiclePayload / 1000), 1);
        instance.max = isPricePerTransport ? max : preciseFloor(max / (vehiclePayload / 1000), 1);
        instance.suggestedPrice = isPricePerTransport
            ? Math.round(suggestedPrice)
            : preciseFloat(suggestedPrice / (vehiclePayload / 1000), 1);

        instance.price = isPricePerTransport ? Math.round(price) : preciseFloat(price / (vehiclePayload / 1000), 1);

        instance.transportCount = 1;
        instance.vehicleClassName = transport.vehicleName;
        instance.vehiclePayload = transport.vehiclePayload;
        instance.vehicleNumAxes = transport.vehicleNumAxes;

        return instance;
    }

    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     * @returns {ClientPricingDataView}
     */
    static create(data) {
        const instance = new ClientPricingDataView();

        instance.billingMethod = data.billingMethod;
        instance.min = data.min;
        instance.max = data.max;
        instance.suggestedPrice = data.suggestedPrice;
        instance.price = data.price;
        instance.transportCount = data.transportCount;
        instance.vehicleClassName = data.vehicleClassName;
        instance.vehiclePayload = data.vehiclePayload;
        instance.vehicleNumAxes = data.vehicleNumAxes;

        return instance;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            billingMethod: this.billingMethod,
            min: this.min,
            max: this.max,
            suggestedPrice: this.suggestedPrice,
            price: this.price,
            transportCount: this.transportCount,
            vehicleClassName: this.vehicleClassName,
            vehicleNumAxes: this.vehicleNumAxes,
        };
    }

    get billingMethod() {
        return this._billingMethod;
    }

    set billingMethod(value) {
        this._billingMethod = value ?? null;
    }

    get min() {
        return this._min;
    }

    set min(value) {
        this._min = value ?? null;
    }

    get max() {
        return this._max;
    }

    set max(value) {
        this._max = value ?? null;
    }

    get suggestedPrice() {
        return this._suggestedPrice;
    }

    set suggestedPrice(value) {
        this._suggestedPrice = value ?? null;
    }

    get price() {
        return this._price;
    }

    set price(value) {
        this._price = value ?? null;
    }

    get transportCount() {
        return this._transportCount;
    }

    set transportCount(value) {
        this._transportCount = value ?? null;
    }

    get vehicleClassName() {
        return this._vehicleClassName;
    }

    set vehicleClassName(value) {
        this._vehicleClassName = value ?? null;
    }

    get vehiclePayload() {
        return this._vehiclePayload;
    }

    set vehiclePayload(value) {
        this._vehiclePayload = value ?? null;
    }

    get vehicleNumAxes() {
        return this._vehicleNumAxes;
    }

    set vehicleNumAxes(value) {
        this._vehicleNumAxes = value;
    }

    // ----------------------------------------------------------------
    get isPricePerTransport() {
        return this.billingMethod === 'fixedPrice';
    }

    get inputPrice() {
        return this.price;
    }

    set inputPrice(value) {
        this.price = value;
    }

    get transportUnitPrice() {
        return this.price;
    }

    get transportTotalPrice() {
        return this.transportRetailPrice * this.transportCount;
    }

    get totalRetailPrice() {
        return this.transportTotalPrice;
    }

    get transportRetailPrice() {
        return this.isPricePerTransport
            ? this.transportUnitPrice
            : (this.transportUnitPrice * this.vehiclePayload) / 1000;
    }

    get progress() {
        return ((this.inputPrice - this.min) * 100) / (this.max - this.min);
    }
}

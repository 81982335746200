<template>
    <div class="unloading-map">
        <div id="unloading-map" class="unloading-map__map" style="background-color: #eee" />
        <LayerControls :type="mapType" :map="map" />
    </div>
</template>

<script>
import GoogleMaps, { createMap } from '@/services/GoogleMaps';
import LayerControls from '@/components/Map/LayerControls';

export default {
    name: 'UnloadingMap',
    components: {
        LayerControls,
    },
    props: {
        location: {
            type: Object,
            required: true,
        },
        mapType: {
            type: String,
            default: 'roadmap',
            validator: v => ['roadmap', 'satellite', 'terrain'].includes(v),
        },
        zoom: {
            type: Number,
            default: 17,
        },
    },
    data() {
        return {
            map: null,
            centerLocation: { ...this.value },
        };
    },
    created() {
        this.createMap();
    },
    methods: {
        createMap() {
            GoogleMaps.then(google => {
                this.map = createMap(google, 'unloading-map', {
                    zoom: this.zoom,
                    center: this.location,
                    disableDefaultUI: true,
                    mapTypeId: this.mapType,
                });

                new google.maps.Marker({
                    position: this.location,
                    map: this.map,
                    icon: '/img/flag.svg',
                });
            });
        },
    },
};
</script>

<style lang="scss">
.unloading-map {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}

.unloading-map__map {
    flex-grow: 1;
}
</style>

<template>
    <span
        v-if="isQueryStringLink"
        class="sub-navigation__link"
        :class="{
            'sub-navigation__active': $route.query.route === navItem.to.query.route,
        }"
        :data-test="`subNavigationSpanLink-${navItem.to.query.route}`"
        @click="() => $emit('routerChange', navItem.to)"
    >
        <slot />
    </span>
    <router-link
        v-else
        :to="navItem.to"
        :exact="navItem.exact"
        :title="navItem.title"
        :active-class="'sub-navigation__active'"
        class="sub-navigation__link"
        :data-test="`subNavigationRouterLink-${routerLinkTitle(navItem.title)}`"
    >
        <slot />
    </router-link>
</template>

<script>
export default {
    name: 'SubNavigationRouterLink',
    props: {
        isQueryStringLink: {
            type: Boolean,
            default: false,
        },
        navItem: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
        routerLinkTitle(title) {
            return title.replace(/\s+/g, '-').toLowerCase();
        },
    },
};
</script>

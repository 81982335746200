<template>
    <div class="flyout__content flex flex-col flex-nowrap overflow-hidden bg">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'FlyoutWrapperPage',
    methods: {
        closeFlyout() {
            this.$emit('close', true);
        },
    },
};
</script>

<template>
    <FormWrapper data-test="factoryVehicleRequirements">
        <template #title>
            <Words spaced-right-tiny>
                {{ $t('components.factoryManagement.factoryVehicleRequirements.title') }}
            </Words>
            <Words weight-normal>
                {{ $t('components.factoryManagement.factoryVehicleRequirements.optional') }}
            </Words>
        </template>
        <ComponentWrapper>
            <template #componentText>
                <Words>{{ $t('components.factoryManagement.factoryVehicleRequirements.subtitle') }}</Words>
            </template>
            <template #component>
                <div class="mb-0 grid grid-cols-1 gap-4 md:grid-cols-2">
                    <CheckboxCard
                        :model-value="form.vehicleRequirements.allWheelDrive"
                        :title="$t('components.factoryManagement.factoryVehicleRequirements.options.allWheelDrive')"
                        :value="true"
                        data-test="vehicle-requirements-checkbox-allWheelDrive"
                        @change="update('vehicleRequirements.allWheelDrive', $event)"
                    />
                    <CheckboxCard
                        :model-value="form.vehicleRequirements.hydroDrive"
                        :title="$t('components.factoryManagement.factoryVehicleRequirements.options.hydroDrive')"
                        :value="true"
                        data-test="vehicle-requirements-checkbox-hydroDrive"
                        @change="update('vehicleRequirements.hydroDrive', $event)"
                    />
                </div>
            </template>
        </ComponentWrapper>
    </FormWrapper>
</template>

<script>
import validate from '@/services/validation/mixin';
import sectionResetMixin from '@/plugins/mixins/factoryForm/sectionResetMixin';
import sectionMixin from '@/plugins/mixins/factoryForm/sectionMixin';

import CheckboxCard from '@/_components/CheckboxCard/CheckboxCard';
import ComponentWrapper from '@/_components/ComponentWrapper/ComponentWrapper';
import FormWrapper from '@/_components/FormWrapper/FormWrapper';
import Words from '@/components/Typography/Words';

export default {
    name: 'FactoryVehicleRequirements',
    components: {
        CheckboxCard,
        ComponentWrapper,
        FormWrapper,
        Words,
    },
    mixins: [validate, sectionResetMixin, sectionMixin],
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    watch: {
        value: {
            handler(data) {
                this.sectionDataBackupOpenStatusReset(data.open);
            },
        },
        form: {
            deep: true,
            immediate: true,
            handler(sectionData) {
                if (!this.sectionDataBackup) {
                    return;
                }
                this.$emit(
                    'dirty-check',
                    this.checkDirty(sectionData.vehicleRequirements, this.sectionDataBackup.vehicleRequirements)
                );
                this.$emit('confirmation-dialog', null);
                this.$emit('valid-check', true);
            },
        },
    },
};
</script>

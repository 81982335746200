import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { differenceInCalendarDays } from 'date-fns';
import _uniq from 'lodash/uniq';

import i18n from '@/i18n';
import { ensureJSTimestamp, getFormattedDurationFromMS } from '@/services/utils/date';
import { preciseFloat } from '@/services/utils';
import {
    CONTEXT_SUPPLIER,
    CONTEXT_PLATFORM,
    CONTEXT_CLIENT,
    CONTEXT_CARRIER,
    CONTEXT_CARRIER_DRIVER,
    CONTEXT_CLIENT_DRIVER,
} from '@/constants/context';
import { TRANSPORT_TYPE } from '@/constants/transportTypes';

export const transportStatus = [
    'new',
    'assigned',
    'planned',
    'planned_subcontractor',
    'started',
    'checked_in',
    'loaded',
    'ready_to_deliver',
    'in_transit',
    'destination_waiting',
    'unloading_delayed',
    'confirmation_pending',
    'delivered',
    'failed',
    'cancelled',
];

export const disposalLifecycle = [
    'new',
    'assigned',
    'planned',
    'started',
    'checked_in_loading',
    'delivered',
    'failed',
    'cancelled',
];

/**
 * Get correct status text depending on users role
 * @param {string} status
 * @param {string} count
 */
export function statusText(context, fakeStatus, count = null) {
    const internalStatusText = i18n.t(`status.transport.${context}.${fakeStatus}`);
    const countText = count ? ` (${count})` : '';

    return `${internalStatusText}${countText}`;
}

const contextStatusFilterMap = {
    [CONTEXT_PLATFORM]: [
        'assigned',
        'cancelled',
        'checked_in',
        'checked_in_loading',
        'confirmation_pending',
        'delivered',
        'destination_waiting',
        'failed',
        'in_transit',
        'loaded',
        'new',
        'pickup_loaded',
        'planned',
        'ready_to_deliver',
        'started',
        'unloading_delayed',
    ],
    [CONTEXT_CLIENT]: [
        'assigned',
        'cancelled',
        'checked_in',
        'checked_in_loading',
        'confirmation_pending',
        'delivered',
        'destination_waiting',
        'failed',
        'in_loading',
        'in_progress',
        'in_transit',
        'loaded',
        'new',
        'pickup_loaded',
        'planned',
        'ready_to_deliver',
        'started',
        'unloading_delayed',
    ],
    [CONTEXT_CLIENT_DRIVER]: [
        'assigned',
        'cancelled',
        'checked_in',
        'checked_in_loading',
        'confirmation_pending',
        'delivered',
        'destination_waiting',
        'failed',
        'in_loading',
        'in_progress',
        'in_transit',
        'loaded',
        'new',
        'pickup_loaded',
        'planned',
        'ready_to_deliver',
        'started',
        'unloading_delayed',
    ],
    [CONTEXT_CARRIER]: [
        'assigned',
        'cancelled',
        'checked_in',
        'checked_in_loading',
        'confirmation_pending',
        'delivered',
        'destination_waiting',
        'failed',
        'in_loading',
        'in_progress',
        'in_transit',
        'loaded',
        'new',
        'planned',
        'ready_to_deliver',
        'started',
        'unloading_delayed',
    ],
    [CONTEXT_SUPPLIER]: [
        'new',
        'started',
        'checked_in',
        'checked_in_loading',
        'in_loading',
        'loaded',
        'in_progress',
        'ready_to_deliver',
        'cancelled',
        'failed',
        'delivered',
    ],
    [CONTEXT_CARRIER_DRIVER]: [
        'cancelled',
        'checked_in',
        'checked_in_loading',
        'confirmation_pending',
        'delivered',
        'destination_waiting',
        'failed',
        'in_loading',
        'in_progress',
        'in_transit',
        'loaded',
        'planned',
        'ready_to_deliver',
        'started',
        'unloading_delayed',
    ],
};

/**
 * Get filtered status history for given context
 * @param {string} context
 * @param {array} statusArray
 */
export function filteredStatusList(context, statusArray) {
    return statusArray.filter(status => contextStatusFilterMap[context].includes(status.status));
}

/**
 * Get progress from transport status
 * @param {string} status
 */
export function transportProgress(status = '') {
    switch (status) {
        case 'new':
        case 'assigned':
        case 'planned':
        case 'planned_subcontractor':
            return -1;
        case 'started':
        case 'checked_in':
        case 'checked_in_loading':
        case 'loaded':
        case 'ready_to_deliver':
            return 0;
        case 'in_transit':
            return 50;
        case 'destination_waiting':
        case 'unloading_delayed':
        case 'confirmation_pending':
        case 'delivered':
            return 100;
        default:
            return -1;
    }
}

/**
 * Check if given status is between two status including the given status
 *
 * @param {string} status
 * @param {string} statusFrom
 * @param {string} statusTo
 * @param {string} type Transport Type
 */
export function statusIsBetween(status, statusFrom, statusTo, type = TRANSPORT_TYPE.DEFAULT) {
    if (status === 'pickup_loaded') {
        status = 'loaded';
    }

    return statusRange(statusFrom, statusTo, type).includes(status);
}

/**
 * Get status range from given start to end (end included)
 *
 * @param {string} statusFrom
 * @param {string} statusTo
 * @param {string} type Transport Type
 */
export function statusRange(statusFrom, statusTo, type = TRANSPORT_TYPE.DEFAULT) {
    if (type === TRANSPORT_TYPE.DISPOSAL)
        return disposalLifecycle.slice(disposalLifecycle.indexOf(statusFrom), disposalLifecycle.indexOf(statusTo) + 1);
    return transportStatus.slice(transportStatus.indexOf(statusFrom), transportStatus.indexOf(statusTo) + 1);
}

/**
 * Check if given status is before status including the given status
 *
 * @param {string} status
 * @param {string} statusTo
 * @param {string} type Transport Type
 */
export function statusIsBefore(status, statusTo, type = TRANSPORT_TYPE.DEFAULT) {
    if (status === 'pickup_loaded') {
        status = 'loaded';
    }

    if (type === TRANSPORT_TYPE.DISPOSAL)
        return disposalLifecycle.slice(0, disposalLifecycle.indexOf(statusTo) + 1).includes(status);

    return transportStatus.slice(0, transportStatus.indexOf(statusTo) + 1).includes(status);
}

/**
 * Check if given status is after status including the given status
 *
 * @param {string} status
 * @param {string} statusFrom
 * @param {string} type Transport Type
 */
export function statusIsAfter(status, statusFrom, type = TRANSPORT_TYPE.DEFAULT) {
    if (status === 'pickup_loaded') {
        status = 'loaded';
    }

    if (type === TRANSPORT_TYPE.DISPOSAL)
        return disposalLifecycle.slice(disposalLifecycle.indexOf(statusFrom)).includes(status);

    return transportStatus.slice(transportStatus.indexOf(statusFrom)).includes(status);
}

/**
 * Return exclusivity level color coding for transport
 *
 * @param {Number} remainingSeconds
 */
export function getExclusivityLevel(remainingSeconds) {
    const minutes = 60;
    let level = 'dark-red';

    if (remainingSeconds <= 30 * minutes) {
        level = 'yellow';
    }

    if (remainingSeconds <= 15 * minutes) {
        level = 'red';
    }

    if (remainingSeconds <= 0) {
        level = 'free';
    }

    return level;
}

/**
 * get unloading delay time based on transport status
 * @param {Object} transport
 */
export function getUnloadingDelayTime(transport) {
    const thresholdMS = transport.destinationWaitingThreshold * 1000;
    const destinationWaitingTimestamp = ensureJSTimestamp(transport.destinationWaitingTimestamp) + thresholdMS;

    switch (transport.status) {
        case 'destination_waiting':
        case 'unloading_delayed':
            return getFormattedDurationFromMS(Date.now() - destinationWaitingTimestamp);
        case 'confirmation_pending':
            return getFormattedDurationFromMS(
                ensureJSTimestamp(transport.confirmationPendingTimestamp) - destinationWaitingTimestamp
            );
        case 'delivered':
        case 'cancelled':
        case 'failed':
            if (transport.chargeableDelay > 0) {
                return getFormattedDurationFromMS(transport.chargeableDelay * 1000);
            }

            if (transport.deliveredTimestamp) {
                return getFormattedDurationFromMS(
                    ensureJSTimestamp(transport.deliveredTimestamp) - destinationWaitingTimestamp
                );
            }

            return getFormattedDurationFromMS(
                ensureJSTimestamp(transport.failedTimestamp) - destinationWaitingTimestamp
            );
        default:
            return getFormattedDurationFromMS(0);
    }
}

/**
 * Get the remaining validity (if available) in days
 * @param transport
 * @return {null|number}
 */
export function getRemainingValidityInDays(transport) {
    if (!transport.validity) {
        return null;
    }

    return differenceInCalendarDays(ensureJSTimestamp(transport.validity), new Date()) + 1;
}

export function isStatusPositive(context, status) {
    if (context === CONTEXT_SUPPLIER) {
        return statusIsAfter(status, 'ready_to_deliver');
    }

    return ['delivered'].includes(status);
}

export function isStatusNeutral(status) {
    return [
        'new',
        'assigned',
        'planned',
        'planned_subcontractor',
        'started',
        'checked_in',
        'checked_in_loading',
        'loaded',
        'ready_to_deliver',
        'in_transit',
        'destination_waiting',
        'confirmation_pending',
    ].includes(status);
}

export function isStatusNegative(status) {
    return ['unloading_delayed', 'failed', 'cancelled'].includes(status);
}

/**
 * Get mapped status for a real one by context
 * where context is the legacy role
 *
 * @param {string} statusContext
 * @param {string} realStatus
 *
 * @returns string|null
 */
export function getFakeStatus(statusContext, realStatus) {
    const map = statusMap[statusContext] ? statusMap[statusContext] : {};

    let result = null;

    Object.keys(map).forEach(fakeStatus => {
        if (map[fakeStatus].some(s => s === realStatus)) {
            result = fakeStatus;
        }
    });

    return result;
}

/**
 * Get real status list for a fake list by context
 *
 * @param {string} statusContext
 * @param {Array} statusList
 */
export function getRealStatusList(statusContext, statusList) {
    const map = statusMap[statusContext];

    if (!map) {
        throw new Error(`Status Context "${statusContext}" not exiosts`);
    }

    if (!Array.isArray(statusList)) {
        statusList = [statusList];
    }

    let list = [];

    statusList.forEach(f => {
        if (map[f]) {
            list = list.concat(map[f]);
        }
    });

    return _uniq(list);
}

/**
 * Convert response status to mapped status by context
 *
 * @param {*} filterResponse
 * @param {*} statusContext
 * @param {boolean} isGrouped
 */
export function patchFilterResponse(statusContext, filterResponse, isGrouped = false) {
    // Patch status filter options
    if (_get(filterResponse, 'supportedFilters.status.options')) {
        const options = {};
        Object.keys(filterResponse.supportedFilters.status.options).forEach(realStatus => {
            const fakeStatus = getFakeStatus(statusContext, realStatus);

            // This status may not exist
            if (!fakeStatus) return;

            if (!options[fakeStatus]) {
                options[fakeStatus] = [];
            }
            options[fakeStatus].push(realStatus);
        });

        filterResponse.supportedFilters.status.options = options;
    }

    // Patch transport items
    if (isGrouped) {
        filterResponse.items.map(group => {
            group.transports.forEach(t => patchTransport(statusContext, t));
        });
    } else {
        filterResponse.items.forEach(t => patchTransport(statusContext, t));
    }

    return filterResponse;
}

export function patchFilterParams(statusContext, filter) {
    // If status is not set, skip
    if (!filter.status) return filter;

    const newFilter = _cloneDeep(filter);

    if (Array.isArray(newFilter.status)) {
        newFilter.status = getRealStatusList(statusContext, newFilter.status);
    } else {
        const realStatusList = getRealStatusList(statusContext, filter.status);
        newFilter.status = realStatusList[0];
    }

    return newFilter;
}

export function patchTransport(statusContext, transport) {
    transport.statusLabel = getFakeStatus(statusContext, transport.status);

    return transport;
}

/**
 * Status mapping based on context
 */
const statusMap = {
    platform: {
        new: ['new'],
        assigned: ['assigned'],
        planned: ['planned', 'planned_subcontractor'],
        started: ['started'],
        checked_in: ['checked_in'],
        checked_in_loading: ['checked_in_loading'],
        loaded: ['loaded', 'pickup_loaded'],
        ready_to_deliver: ['ready_to_deliver'],
        in_transit: ['in_transit'],
        destination_waiting: ['destination_waiting'],
        unloading_delayed: ['unloading_delayed'],
        confirmation_pending: ['confirmation_pending'],
        delivered: ['delivered'],
        failed: ['failed'],
        cancelled: ['cancelled'],
    },
    client: {
        new: ['new'],
        assigned: ['assigned'],
        planned: ['planned', 'planned_subcontractor'],
        started: ['started'],
        checked_in: ['checked_in', 'loaded'],
        checked_in_loading: ['checked_in_loading'],
        pickup_loaded: ['pickup_loaded'],
        ready_to_deliver: ['ready_to_deliver'],
        in_transit: ['in_transit'],
        destination_waiting: ['destination_waiting'],
        unloading_delayed: ['unloading_delayed'],
        confirmation_pending: ['confirmation_pending'],
        delivered: ['delivered'],
        failed: ['failed'],
        cancelled: ['cancelled'],
    },
    supplier: {
        new: ['new', 'assigned', 'planned', 'planned_subcontractor', 'checked_in_loading'],
        started: ['started'],
        checked_in: ['checked_in'],
        loaded: ['loaded', 'pickup_loaded'],
        ready_to_deliver: [
            'ready_to_deliver',
            'in_transit',
            'destination_waiting',
            'unloading_delayed',
            'confirmation_pending',
            'delivered',
        ],
        failed: ['failed'],
        cancelled: ['cancelled'],
    },
    carrier: {
        new: ['new'],
        assigned: ['assigned'],
        planned: ['planned', 'planned_subcontractor'],
        postponed: ['postponed'],
        started: ['started'],
        checked_in: ['checked_in'],
        checked_in_loading: ['checked_in_loading'],
        loaded: ['loaded'],
        ready_to_deliver: ['ready_to_deliver'],
        in_transit: ['in_transit'],
        destination_waiting: ['destination_waiting'],
        unloading_delayed: ['unloading_delayed'],
        confirmation_pending: ['confirmation_pending'],
        delivered: ['delivered'],
        failed: ['failed'],
        cancelled: ['cancelled'],
    },
    carrierDriver: {
        planned: ['planned'],
        started: ['started'],
        checked_in: ['checked_in'],
        checked_in_loading: ['checked_in_loading'],
        loaded: ['loaded'],
        ready_to_deliver: ['ready_to_deliver'],
        in_transit: ['in_transit'],
        destination_waiting: ['destination_waiting'],
        unloading_delayed: ['unloading_delayed'],
        confirmation_pending: ['confirmation_pending'],
        delivered: ['delivered'],
        failed: ['failed'],
        cancelled: ['cancelled'],
    },
};

const DEFAULT_FLOAT_PRECISION = 25;

/**
 * Enclosed calculations for a transport price based on certan key values
 */
export class TransportPrice {
    constructor(base = { distance: 0, payload: 0 }, precision = DEFAULT_FLOAT_PRECISION) {
        this.distance = base?.distance;
        this.payload = base?.payload;

        this.perKm = 0;
        this.perTransport = 0;
        this.perTon = 0;
        this.precision = precision;
    }

    /**
     * Set distance
     * @param {number} value distance in meters
     */
    set distance(value) {
        this._distance = value || 0;
    }

    /**
     * Set payload
     * @param {number} value weight in kg
     */
    set payload(value) {
        this._payload = value || 0;
    }

    /**
     * setPerKm
     * @param {number} price in euro
     */
    setPerKm(priceKm) {
        this.perKm = priceKm;
        this.perTransport = (priceKm * this._distance) / 1000;
        this.perTon = (this.perTransport / this._payload) * 1000;
    }

    /**
     * setPerTransport
     * @param {number} price in euro
     */
    setPerTransport(priceTransport) {
        this.perTransport = priceTransport;
        this.perKm = (priceTransport / this._distance) * 1000;
        this.perTon = (priceTransport / this._payload) * 1000;
    }

    /**
     * setPerTransport
     * @param priceTon
     */
    setPerTon(priceTon) {
        this.perTon = priceTon;
        this.perTransport = (priceTon * this._payload) / 1000;
        this.perKm = (this.perTransport / this._distance) * 1000;
    }

    getPerKm(precision = this.precision) {
        if (!this.perKm) return 0;
        return preciseFloat(this.perKm, precision);
    }

    getPerTransport(precision = this.precision) {
        if (!this.perTransport) return 0;
        return preciseFloat(this.perTransport, precision);
    }

    getPerTon(precision = this.precision) {
        if (!this.perTon) return 0;
        return preciseFloat(this.perTon, precision);
    }
}

import { ensureJSTimestamp, ensurePHPTimestamp } from '@/services/utils/date';
import ProductView from '@/models/ProductView';
import OrganizationView from '@/models/OrganizationView';
import ConstructionSiteView from '@/models/ConstructionSiteView';
import ImageView from '@/models/ImageView';
import AddressView from '@/models/AddressView';
import PhoneNumberView from '@/models/PhoneNumberView';
import UserView from '@/models/UserView';
import _get from 'lodash/get';
import DocumentView from '@/models/DocumentView';
import SupplierInfoView from '@/models/SupplierInfoView';
import SummaryLineItemView from '@/pages/Checkout/SummaryLineItemView';
import { isOrderLineItemGroupTypeDisposal } from '@/constants/disposal';

export default class SummaryView {
    /**
     * Create a view model from quote data (e.g. API response data)
     *
     * @param {object} quote
     * @returns {SummaryView}
     */
    static createFromQuote(quote) {
        const lineItemGroup = quote.lineItemGroups[0];

        const summary = new SummaryView();

        summary.quoteId = quote.id;
        summary.orderId = quote.orderId;
        summary.number = quote.number;
        summary.created = quote.created;
        summary.status = quote.status;
        summary.type = lineItemGroup.type.replace('transport-', '');
        summary.isCustom = quote.customOrder;
        summary.product = ProductView.create(lineItemGroup.product, lineItemGroup?.productVariant);
        summary.projectName = quote.projectName ?? null;
        summary.projectId = lineItemGroup.projectId;
        summary.projectPositionNote = quote.note ?? null;
        summary.projectPositionValidityRange = quote.validityRange ?? null;
        summary.projectPositionStatus = quote.status ?? null;

        summary.lineItems = lineItemGroup.lineItems.map(li => SummaryLineItemView.createFromQuoteLineItem(li));

        summary.qty = lineItemGroup.qty;
        summary.shippingMethod = lineItemGroup.shippingMethod;

        // People.vue -- reject/accept quote message
        summary.requestMessage = quote.requestMessage;
        summary.rejectionMessage = quote.rejectionMessage;
        summary.createdBy = UserView.create(quote.createdBy);
        summary.verifiedBy = quote.verifiedBy;
        summary.supervisor = UserView.create(quote.supervisor);

        summary.client = OrganizationView.create(quote.client);
        summary.nonPickupPenalty = lineItemGroup.nonPickupPenalty;
        summary.constructionSite = ConstructionSiteView.create(lineItemGroup.constructionSite);
        summary.factoryId = lineItemGroup.factoryId;

        // Loading site / SiteTile
        summary.loadingName = lineItemGroup.loadingName ?? null; // eg. Factory Name
        summary.loadingSiteInformation = lineItemGroup.loadingSiteInformation;
        summary.loadingSiteImage = ImageView.create(lineItemGroup.loadingSiteImage);
        summary.loadingSiteLocation = lineItemGroup.loadingLocation || lineItemGroup.loadingSiteLocation; // TODO: check location / siteLocation
        summary.loadingDateFrom = lineItemGroup.loadingDateFrom;
        summary.loadingDateTo = lineItemGroup.loadingDateTo;
        summary.loadingTimeFrom = lineItemGroup.loadingTimeFrom;
        summary.loadingTimeTo = lineItemGroup.loadingTimeTo;
        summary.loadingAddress = AddressView.create(lineItemGroup.loadingAddress);
        summary.loadingAddressCostCenter = lineItemGroup.loadingAddressCostCenter ?? null;
        summary.loadingAddressType = lineItemGroup.loadingAddressType ?? null;
        summary.loadingContactPerson = lineItemGroup.loadingContactPerson;
        summary.loadingContactPhone = PhoneNumberView.create(lineItemGroup.loadingContactPhone);

        // Unloading site / SiteTile
        summary.unloadingName = lineItemGroup.unloadingName ?? null;
        summary.unloadingSiteInformation = lineItemGroup.unloadingSiteInformation;
        summary.unloadingSiteImage = ImageView.create(lineItemGroup.unloadingSiteImage);
        summary.unloadingSiteLocation = lineItemGroup.unloadingLocation || lineItemGroup.unloadingSiteLocation;
        summary.unloadingDateFrom = lineItemGroup.unloadingDateFrom;
        summary.unloadingDateTo = lineItemGroup.unloadingDateTo;
        summary.unloadingTimeFrom = lineItemGroup.unloadingTimeFrom;
        summary.unloadingTimeTo = lineItemGroup.unloadingTimeTo;
        summary.unloadingAddress = AddressView.create(lineItemGroup.unloadingAddress);
        summary.unloadingAddressCostCenter = lineItemGroup.unloadingAddressCostCenter ?? null;
        summary.unloadingAddressType = lineItemGroup.unloadingAddressType ?? null;
        summary.unloadingContactPerson = lineItemGroup.unloadingContactPerson;
        summary.unloadingContactPhone = PhoneNumberView.create(lineItemGroup.unloadingContactPhone);

        summary.freightDescription = lineItemGroup.freightDescription;
        summary.freightType = lineItemGroup.freightType;
        summary.freightImages = (lineItemGroup.freightImages || []).map(image => ImageView.create(image));
        summary.freightDocuments = (lineItemGroup.freightDocuments || []).map(document =>
            DocumentView.create(document)
        );
        summary.carrier = OrganizationView.create(lineItemGroup.carrier);

        summary.shipmentValidityDate = lineItemGroup.lineItems?.[0]?.validity;

        summary.planningMethod = lineItemGroup.planningMethod;
        summary.planningBase = lineItemGroup.planningBase;

        summary.shipmentBillingDetails = {
            billingMethod: lineItemGroup.billingMethod,
            weighingBillingBasis: lineItemGroup.weighingBillingBasis,
            weighingAtLoadingPoint: lineItemGroup.weighingAtLoadingPoint,
            weighingAtUnloadingPoint: lineItemGroup.weighingAtUnloadingPoint,
        };

        if (isOrderLineItemGroupTypeDisposal(lineItemGroup.type)) {
            summary.supplier = lineItemGroup.supplier;
            summary.factoryProductVariantNote = lineItemGroup.factoryProductVariantNote;
            summary.additionalInternalTitle = lineItemGroup.additionalInternalTitle;
            summary.consistency = lineItemGroup.consistency;
            summary.disposalProofNumber = lineItemGroup.disposalProofNumber;
            summary.isPretreated = lineItemGroup.isPretreated;
            summary.pretreatmentDescription = lineItemGroup.pretreatmentDescription;
            summary.isDeclarationAnalysisRequired = lineItemGroup.isDeclarationAnalysisRequired;
            summary.exemption = lineItemGroup.exemption;
            summary.billingMethod = lineItemGroup.billingMethod;
            summary.billedByStateWasteCompany = lineItemGroup.billedByStateWasteCompany;
        }
        summary.documents = lineItemGroup.documents || [];
        summary.accompanyingAnalysisImages = lineItemGroup.accompanyingAnalysisImages || [];

        return summary;
    }

    /**
     * Create a view model from basket data
     *
     * @param {object} basket
     * @returns {SummaryView}
     */
    // eslint-disable-next-line complexity
    static createFromBasket(basket) {
        const summary = new SummaryView();

        summary.status = basket.isCustom ? 'custom' : 'new';
        summary.type = basket.type;
        summary.isCustom = basket.isCustom;
        summary.product = ProductView.create(basket.product);
        summary.qty = basket.qty;
        summary.shippingMethod = basket.deliveryMethod?.name;
        summary.created = new Date().getTime();
        summary.projectPositionValidityRange = basket.validityRange || null;
        summary.projectPositionStatus = basket.status || null;

        summary.lineItems = basket.transports?.transports.map(basketTransport =>
            SummaryLineItemView.createFromBasketTransport(basket, basketTransport)
        );

        if (basket.shipmentBillingDetails) {
            summary.shipmentBillingDetails = basket.shipmentBillingDetails;
        }

        if (basket.clientInfo) {
            const supervisor = _get(basket, 'clientInfo.purchaser', null);
            summary.supervisor = UserView.create(supervisor);

            summary.client = OrganizationView.create(basket.clientInfo.client);
            summary.projectName = basket.clientInfo.projectName ?? null;
        }

        if (basket.constructionSite) {
            summary.constructionSite = ConstructionSiteView.create(basket.constructionSite);
        }

        if (basket.supplierInfo) {
            summary.supplierInfo = SupplierInfoView.create(basket.supplierInfo);
            summary.factoryId = basket.supplierInfo.factoryId;
        }

        // Loading site / SiteTile
        if (basket.shipmentLoading) {
            // Construction Site Name, Factory Name
            const shipmentLoading = basket.shipmentLoading;

            if (shipmentLoading.address && shipmentLoading.address.name) {
                summary.loadingName = shipmentLoading.address.name;
            }

            summary.loadingSiteInformation = shipmentLoading.description;
            summary.loadingSiteImage = ImageView.create(shipmentLoading.photo);
            summary.loadingSiteLocation = shipmentLoading.loadingLocation || shipmentLoading.location || null;

            if (shipmentLoading.date) {
                summary.loadingDateFrom = ensurePHPTimestamp(shipmentLoading.date.start);
                summary.loadingDateTo =
                    ensurePHPTimestamp(shipmentLoading.date.end) || ensurePHPTimestamp(shipmentLoading.date.start);
            }

            if (shipmentLoading.time) {
                summary.loadingTimeFrom = shipmentLoading.time.startTime;
                summary.loadingTimeTo = shipmentLoading.time.endTime;
            }

            summary.loadingAddress = AddressView.create(shipmentLoading.address);
            summary.loadingAddressCostCenter = shipmentLoading.costCenter;
            summary.loadingAddressType = shipmentLoading.address?.type ?? null;

            if (shipmentLoading.contact) {
                summary.loadingContactPerson = shipmentLoading.contact.name;

                if (shipmentLoading.contact.phone) {
                    summary.loadingContactPhone = PhoneNumberView.create(shipmentLoading.contact.phone);
                }
            }
        }

        // UnloadingSite for Delivery
        if (basket.additionalInformation) {
            summary.unloadingSiteLocation = basket.additionalInformation.unloadingSiteLocation ?? null;
            if (basket.additionalInformation.photo) {
                summary.unloadingSiteImage = ImageView.create(basket.additionalInformation.photo);
            }
            summary.unloadingSiteInformation = basket.additionalInformation.description ?? null;
        }

        // Unloading site / SiteTile
        if (basket.shipmentUnloading) {
            // Construction Site Name, Factory Name
            const shipmentUnloading = basket.shipmentUnloading;

            if (shipmentUnloading.address && shipmentUnloading.address.name) {
                summary.unloadingName = shipmentUnloading.address.name;
            }

            summary.unloadingSiteInformation = shipmentUnloading.description;
            if (shipmentUnloading.photo) {
                summary.unloadingSiteImage = ImageView.create(shipmentUnloading.photo);
            }
            summary.unloadingSiteLocation = shipmentUnloading.loadingLocation || shipmentUnloading.location || null;

            if (shipmentUnloading.date) {
                summary.unloadingDateFrom = ensurePHPTimestamp(shipmentUnloading.date.start);
                summary.unloadingDateTo =
                    ensurePHPTimestamp(shipmentUnloading.date.end) || ensurePHPTimestamp(shipmentUnloading.date.start);
            }

            if (shipmentUnloading.time) {
                summary.unloadingTimeFrom = shipmentUnloading.time.startTime;
                summary.unloadingTimeTo = shipmentUnloading.time.endTime;
            }

            summary.unloadingAddress = AddressView.create(shipmentUnloading.address);
            summary.unloadingAddressCostCenter = shipmentUnloading.costCenter;
            summary.unloadingAddressType = shipmentUnloading.address?.type ?? null;

            if (shipmentUnloading.contact) {
                summary.unloadingContactPerson = shipmentUnloading.contact.name;

                if (shipmentUnloading.contact.phone) {
                    summary.unloadingContactPhone = PhoneNumberView.create(shipmentUnloading.contact.phone);
                }
            }
        }

        summary.freightDescription = _get(basket, 'shipmentQtyAndDocument.description', null);
        summary.freightType = _get(basket, 'shipmentQtyAndDocument.type', null);
        summary.freightImages = _get(basket, 'shipmentQtyAndDocument.images', []).map(image => ImageView.create(image));
        summary.freightDocuments = _get(basket, 'shipmentQtyAndDocument.documents', []).map(document =>
            DocumentView.create(document)
        );
        summary.carrier = OrganizationView.create(basket.carrierInfo);

        return summary;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._quoteId = null;
        this._orderId = null;
        this._number = null;
        this._created = null;
        this._status = null;
        this._type = null;
        this._isCustom = null;
        this._product = null;
        this._projectName = null;
        this._projectId = null;
        this._projectPositionNote = null;
        this._projectPositionValidityRange = null;
        this._projectPositionStatus = null;

        // transport items or line items
        // OrderVehicleTrack.vue
        // TransportTable.vue
        this._lineItems = [];

        this._qty = null;
        this._shippingMethod = null;
        this._shipmentBillingDetails = null;

        // People.vue -- reject/accept quote message
        this._requestMessage = null;
        this._rejectionMessage = null;
        this._createdBy = null;
        this._verifiedBy = null;
        this._supervisor = null;

        this._client = null;
        this._nonPickupPenalty = null;
        this._constructionSite = null;
        this._supplierInfo = null;
        this._factoryId = null;

        // UnloadingSite
        this._unloadingSiteLocation = null;
        this._unloadingSiteImage = null;
        this._unloadingSiteInformation = null;

        // Loading site / SiteTile
        this._loadingName = null;
        this._loadingSiteInformation = null;
        this._loadingSiteImage = null;
        this._loadingSiteLocation = null;
        this._loadingDateFrom = null;
        this._loadingDateTo = null;
        this._loadingTimeFrom = null;
        this._loadingTimeTo = null;
        this._loadingAddress = null;
        this._loadingAddressCostCenter = null;
        this._loadingAddressType = null;
        this._loadingContactPerson = null;
        this._loadingContactPhone = null;

        // Unloading site / SiteTile
        this._unloadingName = null;
        this._unloadingSiteInformation = null;
        this._unloadingSiteImage = null;
        this._unloadingSiteLocation = null;
        this._unloadingDateFrom = null;
        this._unloadingDateTo = null;
        this._unloadingTimeFrom = null;
        this._unloadingTimeTo = null;
        this._unloadingAddress = null;
        this._unloadingAddressCostCenter = null;
        this._unloadingAddressType = null;
        this._unloadingContactPerson = null;
        this._unloadingContactPhone = null;

        this._freightDescription = null;
        this._freightType = null;
        this._freightImages = null;
        this._freightDocuments = null;
        this._carrier = null;

        // billing with weighing info
        this._weighingAtLoadingPoint = null;
        this._weighingAtUnloadingPoint = null;
        this._weighingBillingBasis = null;

        this._unloadingContactPhone = null;
        this._unloadingContactPhone = null;
        this._unloadingContactPhone = null;
        this._unloadingContactPhone = null;

        // pickup related
        this._pickupExpireDate = null;

        this._shipmentValidityDate = null;
        this._planningMethod = null;
        this._planningBase = null;

        // disposal related
        this._supplier = null;
    }

    get quoteId() {
        return this._quoteId;
    }

    set quoteId(value) {
        this._quoteId = value ?? null;
    }

    get orderId() {
        return this._orderId;
    }

    set orderId(value) {
        this._orderId = value ?? null;
    }

    get number() {
        return this._number;
    }

    set number(value) {
        this._number = value ?? null;
    }

    get created() {
        return ensureJSTimestamp(this._created);
    }

    set created(value) {
        this._created = value ?? null;
    }

    get status() {
        return this._status;
    }

    set status(value) {
        this._status = value ?? null;
    }

    get type() {
        return this._type;
    }

    set type(value) {
        this._type = value ?? null;
    }

    get isCustom() {
        return this._isCustom;
    }

    set isCustom(value) {
        this._isCustom = value ?? null;
    }

    get product() {
        return this._product;
    }

    set product(value) {
        this._product = value ?? null;
    }

    get projectName() {
        return this._projectName;
    }

    set projectName(value) {
        this._projectName = value ?? null;
    }

    get projectId() {
        return this._projectId;
    }

    set projectId(value) {
        this._projectId = value ?? null;
    }

    get projectPositionNote() {
        return this._projectPositionNote;
    }

    set projectPositionNote(value) {
        this._projectPositionNote = value ?? null;
    }

    get projectPositionValidityRange() {
        return this._projectPositionValidityRange;
    }

    set projectPositionValidityRange(value) {
        this._projectPositionValidityRange = value ?? null;
    }

    get projectPositionStatus() {
        return this._projectPositionStatus;
    }

    set projectPositionStatus(value) {
        this._projectPositionStatus = value ?? null;
    }

    get lineItems() {
        return this._lineItems;
    }

    set lineItems(value) {
        this._lineItems = value ?? null;
    }

    get qty() {
        return this._qty;
    }

    set qty(value) {
        this._qty = value ?? null;
    }

    get shippingMethod() {
        return this._shippingMethod;
    }

    set shippingMethod(value) {
        this._shippingMethod = value ?? null;
    }

    get shipmentBillingDetails() {
        return this._shipmentBillingDetails;
    }

    set shipmentBillingDetails(value) {
        this._shipmentBillingDetails = value ?? null;
    }

    get requestMessage() {
        return this._requestMessage;
    }

    set requestMessage(value) {
        this._requestMessage = value ?? null;
    }

    get rejectionMessage() {
        return this._rejectionMessage;
    }

    set rejectionMessage(value) {
        this._rejectionMessage = value ?? null;
    }

    /**
     * Get created by
     * @return {UserView}
     */
    get createdBy() {
        return this._createdBy;
    }

    /**
     * Set created by
     * @param {UserView} value
     */
    set createdBy(value) {
        this._createdBy = value ?? null;
    }

    get verifiedBy() {
        return this._verifiedBy;
    }

    set verifiedBy(value) {
        this._verifiedBy = value ?? null;
    }

    get supervisor() {
        return this._supervisor;
    }

    set supervisor(value) {
        this._supervisor = value ?? null;
    }

    get client() {
        return this._client;
    }

    set client(value) {
        this._client = value ?? null;
    }

    get nonPickupPenalty() {
        return this._nonPickupPenalty;
    }

    set nonPickupPenalty(value) {
        this._nonPickupPenalty = value ?? null;
    }

    get constructionSite() {
        return this._constructionSite;
    }

    set constructionSite(value) {
        this._constructionSite = value ?? null;
    }

    get supplierInfo() {
        return this._supplierInfo;
    }

    set supplierInfo(value) {
        this._supplierInfo = value ?? null;
    }

    get factoryId() {
        return this._factoryId;
    }

    set factoryId(value) {
        this._factoryId = value ?? null;
    }

    /**
     * Get loading name
     * Mostly construction site name or factory name
     * @returns {string}
     */
    get loadingName() {
        return this._loadingName;
    }

    /**
     * Set loading name
     * Mostly construction site name or factory name
     * @param value
     */
    set loadingName(value) {
        this._loadingName = value ?? null;
    }

    get loadingSiteInformation() {
        return this._loadingSiteInformation;
    }

    set loadingSiteInformation(value) {
        this._loadingSiteInformation = value ?? null;
    }

    get loadingSiteImage() {
        return this._loadingSiteImage;
    }

    set loadingSiteImage(value) {
        this._loadingSiteImage = value ?? null;
    }

    get loadingSiteLocation() {
        return this._loadingSiteLocation;
    }

    set loadingSiteLocation(value) {
        this._loadingSiteLocation = value ?? null;
    }

    get loadingDateFrom() {
        return this._loadingDateFrom;
    }

    set loadingDateFrom(value) {
        this._loadingDateFrom = value ?? null;
    }

    get loadingDateTo() {
        return this._loadingDateTo;
    }

    set loadingDateTo(value) {
        this._loadingDateTo = value ?? null;
    }

    get loadingTimeFrom() {
        return this._loadingTimeFrom;
    }

    set loadingTimeFrom(value) {
        this._loadingTimeFrom = value ?? null;
    }

    get loadingTimeTo() {
        return this._loadingTimeTo;
    }

    set loadingTimeTo(value) {
        this._loadingTimeTo = value ?? null;
    }

    get loadingAddress() {
        return this._loadingAddress;
    }

    set loadingAddress(value) {
        this._loadingAddress = value ?? null;
    }

    get loadingAddressCostCenter() {
        return this._loadingAddressCostCenter;
    }

    set loadingAddressCostCenter(value) {
        this._loadingAddressCostCenter = value ?? null;
    }

    get loadingAddressType() {
        return this._loadingAddressType;
    }

    set loadingAddressType(value) {
        this._loadingAddressType = value ?? null;
    }

    get loadingContactPerson() {
        return this._loadingContactPerson;
    }

    set loadingContactPerson(value) {
        this._loadingContactPerson = value ?? null;
    }

    get loadingContactPhone() {
        return this._loadingContactPhone;
    }

    set loadingContactPhone(value) {
        this._loadingContactPhone = value ?? null;
    }

    /**
     * Get unloading name
     * Mostly construction site name or factory name
     * @returns {string}
     */
    get unloadingName() {
        return this._unloadingName;
    }

    /**
     * Set unloading name
     * Mostly construction site name or factory name
     * @param {string} value
     */
    set unloadingName(value) {
        this._unloadingName = value ?? null;
    }

    get unloadingSiteInformation() {
        return this._unloadingSiteInformation;
    }

    set unloadingSiteInformation(value) {
        this._unloadingSiteInformation = value ?? null;
    }

    get unloadingSiteImage() {
        return this._unloadingSiteImage;
    }

    set unloadingSiteImage(value) {
        this._unloadingSiteImage = value ?? null;
    }

    get unloadingSiteLocation() {
        return this._unloadingSiteLocation;
    }

    set unloadingSiteLocation(value) {
        this._unloadingSiteLocation = value ?? null;
    }

    get unloadingDateFrom() {
        return this._unloadingDateFrom;
    }

    set unloadingDateFrom(value) {
        this._unloadingDateFrom = value ?? null;
    }

    get unloadingDateTo() {
        return this._unloadingDateTo;
    }

    set unloadingDateTo(value) {
        this._unloadingDateTo = value ?? null;
    }

    get unloadingTimeFrom() {
        return this._unloadingTimeFrom;
    }

    set unloadingTimeFrom(value) {
        this._unloadingTimeFrom = value ?? null;
    }

    get unloadingTimeTo() {
        return this._unloadingTimeTo;
    }

    set unloadingTimeTo(value) {
        this._unloadingTimeTo = value ?? null;
    }

    get unloadingAddress() {
        return this._unloadingAddress;
    }

    set unloadingAddress(value) {
        this._unloadingAddress = value ?? null;
    }

    get unloadingAddressCostCenter() {
        return this._unloadingAddressCostCenter;
    }

    set unloadingAddressCostCenter(value) {
        this._unloadingAddressCostCenter = value ?? null;
    }

    get unloadingAddressType() {
        return this._unloadingAddressType;
    }

    set unloadingAddressType(value) {
        this._unloadingAddressType = value ?? null;
    }

    get unloadingContactPerson() {
        return this._unloadingContactPerson;
    }

    set unloadingContactPerson(value) {
        this._unloadingContactPerson = value ?? null;
    }

    get freightDescription() {
        return this._freightDescription;
    }

    set freightDescription(value) {
        this._freightDescription = value ?? null;
    }

    get freightType() {
        return this._freightType;
    }

    set freightType(value) {
        this._freightType = value ?? null;
    }

    get freightImages() {
        return this._freightImages;
    }

    set freightImages(value) {
        this._freightImages = value ?? null;
    }

    get freightDocuments() {
        return this._freightDocuments;
    }

    set freightDocuments(value) {
        this._freightDocuments = value ?? null;
    }

    /**
     * Set weighingAtLoadingPoint
     * @param {string} value
     */
    set weighingAtLoadingPoint(value) {
        this._weighingAtLoadingPoint = value;
    }

    /**
     * Get weighingAtLoadingPoint
     * @returns {string}
     */
    get weighingAtLoadingPoint() {
        return this._weighingAtLoadingPoint;
    }

    /**
     * Set weighingAtUnloadingPoint
     * @param {string} value
     */
    set weighingAtUnloadingPoint(value) {
        this._weighingAtUnloadingPoint = value;
    }

    /**
     * Get weighingAtUnloadingPoint
     * @returns {string}
     */
    get weighingAtUnloadingPoint() {
        return this._weighingAtUnloadingPoint;
    }

    /**
     * Set weighingBillingBasis
     * @param {string} value
     */
    set weighingBillingBasis(value) {
        this._weighingBillingBasis = value;
    }

    /**
     * Get weighingBillingBasis
     * @returns {string}
     */
    get weighingBillingBasis() {
        return this._weighingBillingBasis;
    }

    get carrier() {
        return this._carrier;
    }

    set carrier(value) {
        this._carrier = value ?? null;
    }

    get unloadingContactPhone() {
        return this._unloadingContactPhone;
    }

    set unloadingContactPhone(value) {
        this._unloadingContactPhone = value ?? null;
    }

    /**
     * Get pickup expire timestamp
     * @returns {null|number}
     */
    get pickupExpireDate() {
        return ensureJSTimestamp(this._pickupExpireDate);
    }

    /**
     * Set pickup expire timestamp
     * @param {number} value
     */
    set pickupExpireDate(value) {
        this._pickupExpireDate = value ?? null;
    }

    /**
     * Get shipment validity timestamp
     * @returns {null|number}
     */
    get shipmentValidityDate() {
        return this._shipmentValidityDate;
    }

    /**
     * Set shipment validity timestamp
     * @param {number} value
     */
    set shipmentValidityDate(value) {
        this._shipmentValidityDate = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get planning method
     * @return {string}
     */
    get planningMethod() {
        return this._planningMethod;
    }

    /**
     * Set planning method
     * @param {string} value
     */
    set planningMethod(value) {
        this._planningMethod = value ?? null;
    }

    /**
     * Get planning base
     * @return {string}
     */
    get planningBase() {
        return this._planningBase;
    }

    /**
     * Set planning base
     * @param {string} value
     */
    set planningBase(value) {
        this._planningBase = value ?? null;
    }

    // purchase unit price offer block
    //isNewQuote
    //isPendingQuote
    //isRejectedQuote
    //isCancelledByPlatform
    get isNewQuote() {
        return this._status === 'new';
    }

    get isPendingQuote() {
        return this._status === 'pending';
    }

    get isRejectedQuote() {
        return this._status === 'rejected';
    }

    get isCancelledByPlatform() {
        return this._status === 'cancelled';
    }

    get isSubmittedQuote() {
        return this._status === 'submitted';
    }

    get isTypeDelivery() {
        return this.type === 'delivery';
    }

    get isTypeDisposal() {
        return this.type === 'waste' || this.type === 'dangerous-waste';
    }

    get isTypeProjectPositionDelivery() {
        return !!(this.isTypeDelivery && this.projectId);
    }

    get isTypeProjectPositionDisposal() {
        return !!(this.isTypeDisposal && this.projectId);
    }

    get isTypeProjectPositionShipment() {
        return !!(this.isTypeShipment && this.projectId);
    }

    get isTypeShipment() {
        return this.type === 'shipment';
    }

    /**
     * Get if view is in general for any kind of project position
     * @return {boolean}
     */
    get isTypeProjectPosition() {
        return (
            this.isTypeProjectPositionDelivery ||
            this.isTypeProjectPositionShipment ||
            this.isTypeProjectPositionDisposal
        );
    }

    get isTypePickup() {
        return this.type === 'pickup';
    }

    /**
     * Get if view is for a project delivery
     * @return {boolean}
     */
    get isTypeProjectDelivery() {
        return this.type === 'project-delivery';
    }

    /**
     * Get if view is for a project shipment
     * @return {boolean}
     */
    get isTypeProjectShipment() {
        return this.type === 'project-shipment';
    }

    /**
     * Get if view is for a project shipment
     * @return {boolean}
     */
    get isTypeProjectDisposal() {
        return this.type === 'project-disposal';
    }

    /**
     * Get if view is in general for any kind of project
     * @return {boolean}
     */
    get isTypeProject() {
        return this.isTypeProjectDelivery || this.isTypeProjectShipment || this.isTypeProjectDisposal;
    }

    get supplier() {
        return this._supplier;
    }

    set supplier(value) {
        this._supplier = value;
    }

    get factoryProductVariantNote() {
        return this._factoryProductVariantNote;
    }

    set factoryProductVariantNote(value) {
        this._factoryProductVariantNote = value;
    }

    get additionalInternalTitle() {
        return this._additionalInternalTitle;
    }

    set additionalInternalTitle(value) {
        this._additionalInternalTitle = value;
    }

    get consistency() {
        return this._consistency;
    }

    set consistency(value) {
        this._consistency = value;
    }

    get disposalProofNumber() {
        return this._disposalProofNumber;
    }

    set disposalProofNumber(value) {
        this._disposalProofNumber = value;
    }

    get isPretreated() {
        return this._isPretreated;
    }

    set isPretreated(value) {
        this._isPretreated = value;
    }

    get pretreatmentDescription() {
        return this._pretreatmentDescription;
    }

    set pretreatmentDescription(value) {
        this._pretreatmentDescription = value;
    }

    get isDeclarationAnalysisRequired() {
        return this._isDeclarationAnalysisRequired;
    }

    set isDeclarationAnalysisRequired(value) {
        this._isDeclarationAnalysisRequired = value;
    }

    get exemption() {
        return this._exemption;
    }

    set exemption(value) {
        this._exemption = value;
    }

    get documents() {
        return this._documents;
    }

    set documents(value) {
        this._documents = value;
    }

    get accompanyingAnalysisImages() {
        return this._accompanyingAnalysisImages;
    }

    set accompanyingAnalysisImages(value) {
        this._accompanyingAnalysisImages = value;
    }

    get billingMethod() {
        return this._billingMethod;
    }

    set billingMethod(value) {
        this._billingMethod = value;
    }

    get billedByStateWasteCompany() {
        return this._billedByStateWasteCompany;
    }

    set billedByStateWasteCompany(value) {
        this._billedByStateWasteCompany = value;
    }
}

import { useQuery } from '@tanstack/react-query';
import { constructionProjectKeys } from '@/constructionProjects/queries';
import { getConstructionProjectCountsAsAdmin, } from '@/constructionProjects/api/projects';
import { listGenericPositions } from '@/modules/constructionProjects/api/admin/listGenericPositions';
import { useLd } from '@/services/LaunchDarkly';
export function useConstructionProjectCountsAsAdmin(constructionProjectId) {
    const isGenericPositionEnabled = useLd('show-generic-positions-to-pa');
    return useQuery({
        queryKey: constructionProjectKeys.counts(constructionProjectId),
        queryFn: async () => {
            const [counts, genericPositionsCount] = await Promise.all([
                getConstructionProjectCountsAsAdmin(constructionProjectId),
                getGenericPositionsCounts(constructionProjectId, isGenericPositionEnabled.value),
            ]);
            return {
                ...counts,
                projectPositionCount: counts.projectPositionCount + genericPositionsCount,
            };
        },
        suspense: true,
    });
}
async function getGenericPositionsCounts(constructionProjectId, isEnabled) {
    if (!isEnabled) {
        return 0;
    }
    const result = await listGenericPositions({
        page: 1,
        perPage: 1,
        constructionProjectId,
        status: 'OPEN',
        active: 1,
    });
    return result.total;
}

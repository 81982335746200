import React from 'react';
import clsx from 'clsx';
import { escapeStringRegexp } from '@/services/utils';
export const Highlighter = ({ highlight = '', text = '', className }) => {
    if (!highlight) {
        return <>{text}</>;
    }
    const escapedHighlight = escapeStringRegexp(highlight);
    const tokens = escapedHighlight.toLowerCase().split(' ');
    const parts = text?.split(new RegExp(`(${tokens.join('|')})`, 'gi'));
    return (<span className={className}>
            {parts.map((part, i) => {
            const isHighlighted = tokens.includes(part.toLowerCase());
            return (<span key={i} className={clsx({ 'bg-surface-subdued': isHighlighted })}>
                        {part}
                    </span>);
        })}
        </span>);
};

/**
 * Class representing image view model
 */
export default class ImageView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     * @return {ImageView|null}
     */
    static create(data) {
        if (!data) return null;

        const image = new ImageView();

        image.uuid = data.uuid;
        image.url = data.url;
        image.fileName = data.fileName;

        return image;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._uuid = null;
        this._url = null;
        this._fileName = null;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            uuid: this.uuid,
            url: this.url,
            fileName: this.fileName,
        };
    }

    /**
     * Clone the current view
     * @return {ImageView}
     */
    clone() {
        return ImageView.create(this.unfold());
    }

    /**
     * Get uuid
     * @returns {string|null}
     */
    get uuid() {
        return this._uuid;
    }

    /**
     * Sett uuid
     * @param {string} value
     */
    set uuid(value) {
        this._uuid = value ?? null;
    }

    /**
     * Get url
     * @returns {object|null}
     */
    get url() {
        return this._url;
    }

    /**
     * Set url
     * @param {object} value
     */
    set url(value) {
        this._url = value ?? null;
    }

    /**
     * Get fileName
     * @returns {string|null}
     */
    get fileName() {
        return this._fileName;
    }

    /**
     * Set fileName
     * @param {string} value
     */
    set fileName(value) {
        this._fileName = value ?? null;
    }
}

import { AdministrationIcon, CopyIcon, MaterialIcon, OrderManagementIcon, OrdersIcon, SwitchIcon, TagManagementIcon, WeightTonsIcon, } from '@schuettflix/icons-react';
import { GRAIN_SIZE_TYPE, ProductAttributeType } from '@/services/ProductCatalog/constants';
import React from 'react';
export const attributeSetIconMapping = [
    {
        key: ProductAttributeType.BRAND,
        icon: <CopyIcon size="sm" className="m-2"/>,
        iconDisabled: <CopyIcon size="sm" className="m-2" disabled/>,
    },
    {
        key: ProductAttributeType.GEOGRAPHY,
        icon: <MaterialIcon size="sm" className="m-2"/>,
        iconDisabled: <MaterialIcon size="sm" className="m-2" disabled/>,
    },
    {
        key: ProductAttributeType.GEOLOGY,
        icon: <MaterialIcon size="sm" className="m-2"/>,
        iconDisabled: <MaterialIcon size="sm" className="m-2" disabled/>,
    },
    {
        key: ProductAttributeType.COLOR,
        icon: <TagManagementIcon size="sm" className="m-2"/>,
        iconDisabled: <TagManagementIcon size="sm" className="m-2" disabled/>,
    },
    {
        key: ProductAttributeType.CHARACTERISTICS,
        icon: <AdministrationIcon size="sm" className="m-2"/>,
        iconDisabled: <AdministrationIcon size="sm" className="m-2" disabled/>,
    },
    {
        key: ProductAttributeType.CERT_1,
        icon: <OrderManagementIcon size="sm" className="m-2"/>,
        iconDisabled: <OrderManagementIcon size="sm" className="m-2" disabled/>,
    },
    {
        key: ProductAttributeType.CERT_2,
        icon: <OrdersIcon size="sm" className="m-2"/>,
        iconDisabled: <OrdersIcon size="sm" className="m-2" disabled/>,
    },
    {
        key: ProductAttributeType.CERT_3,
        icon: <AdministrationIcon size="sm" className="m-2"/>,
        iconDisabled: <AdministrationIcon size="sm" className="m-2" disabled/>,
    },
    {
        key: ProductAttributeType.PROCESSING,
        icon: <SwitchIcon size="sm" className="m-2"/>,
        iconDisabled: <SwitchIcon size="sm" className="m-2" disabled/>,
    },
    {
        key: GRAIN_SIZE_TYPE,
        icon: <WeightTonsIcon size="sm" className="m-2"/>,
        iconDisabled: <WeightTonsIcon size="sm" className="m-2" disabled/>,
    },
];

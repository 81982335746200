<template>
    <div class="location-picker">
        <div class="location-picker__trigger-button">
            <slot :edit-location="() => open(true)" :show-location="() => open(false)" />
        </div>

        <Flyout
            :active="flyoutActive"
            :size="flyoutSize"
            :screen-name="screenName"
            :headline="title || $t('components.locationPicker.header')"
            appear-from="bottom"
            close-by-x
            @flyoutOpen="$emit('flyoutOpen', $event)"
            @closed="flyoutActive = false"
        >
            <div class="location-picker__body">
                <div v-if="enableSearch && editMode" class="location-picker__address-field">
                    <EnhancedAddressField
                        :value="address"
                        :allow-incomplete-address="allowIncompleteAddress"
                        :error="getError('forbidStateChange')"
                        list-locality
                        focus-initially
                        focus-street-number
                        :include-state="includeState"
                        :include-country="includeCountry"
                        :allowed-countries="allowedCountries"
                        :label="$t('pages.register.steps.companyBillingAddress.fields.address')"
                        @focus="changeSearchFieldFocused(true)"
                        @blur="changeSearchFieldFocused(false)"
                        @input="handleAddressUpdateBySearch"
                        @plus-code="handleAddressUpdateByLocation"
                    />
                </div>

                <div class="location-picker__map-wrapper">
                    <div :id="`${eid}-map`" class="location-picker__map" />
                    <div v-if="editMode" class="location-picker__flag"><FlagIcon width="92" /></div>
                    <LayerControls :type="mapType" :map="map" />
                    <CurrentLocationControl :map="map" @input="handleCurrentLocation" />
                </div>

                <div
                    :class="{ 'location-picker__backdrop--active': searchFieldFocused }"
                    class="location-picker__backdrop"
                />
            </div>

            <template #bottom>
                <SlideUp :active="!editMode || (editMode && isValid)">
                    <ButtonGroup v-if="editMode">
                        <BaseButton
                            v-if="enableReset"
                            :is-loading="isPending()"
                            primary
                            light
                            place-left
                            @click="$emit(events.resetMap)"
                        >
                            {{ $t('components.locationPicker.reset') }}
                        </BaseButton>
                        <BaseButton
                            :is-loading="isPending('retrieveState')"
                            data-test="button-apply-location"
                            primary
                            @click="handleLocationUpdate"
                        >
                            {{ $t('components.locationPicker.save') }}
                        </BaseButton>
                    </ButtonGroup>
                    <ButtonGroup v-else>
                        <BaseButton primary @click="flyoutActive = false">
                            {{ $t('components.locationPicker.close') }}
                        </BaseButton>
                    </ButtonGroup>
                </SlideUp>
            </template>
        </Flyout>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import GoogleMaps, { createMap } from '@/services/GoogleMaps';
import {
    locationsEqual,
    locationToStructurizedAddress,
    normalizeLocation,
    replacePlaceLabelWithFormattedAddress,
    retrieveStateByLocation,
} from '@/services/utils/map';
import { emptyAddress } from '@/services/utils/address';
import statefulMixin from '@/plugins/mixins/statefulMixin';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Flyout from '@/components/Layout/Flyout';
import LayerControls from '@/components/Map/LayerControls';
import CurrentLocationControl from '@/components/Map/CurrentLocationControl';
import EnhancedAddressField from '@/pages/Login/components/EnhancedAddressField';
import SlideUp from '@/components/Animation/SlideUp';

import FlagIcon from '@/assets/icons/flag-centered.svg';
import FlagIconPath from '@/assets/icons/flag.svg?external';

const DEFAULT_ZOOM_LEVEL_WITHOUT_LOCATION = 11;
const DEFAULT_ZOOM_WITH_LOCATION = 17;
const DEBOUNCE_CHANGED_MAP_CENTER_MS = 500;

export default {
    name: 'LocationPicker',
    components: {
        BaseButton,
        ButtonGroup,
        Flyout,
        LayerControls,
        CurrentLocationControl,
        EnhancedAddressField,
        SlideUp,

        FlagIcon,
    },
    mixins: [statefulMixin],
    props: {
        screenName: {
            type: String,
            default: undefined,
        },
        value: {
            type: Object,
            default: null,
        },
        initialAddress: {
            type: Object,
            default: null,
        },
        mapType: {
            type: String,
            default: 'roadmap',
            validator: v => ['roadmap', 'satellite', 'terrain'].includes(v),
        },
        enableSearch: {
            type: Boolean,
            default: false,
        },
        enableReset: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
        focusInitially: {
            type: Boolean,
            default: false,
        },
        ensureState: {
            type: Boolean,
            default: false,
        },
        allowIncompleteAddress: {
            type: Boolean,
            default: false,
        },
        flyoutSize: {
            type: String,
            default: 'small',
        },
        includeState: {
            type: Boolean,
            default: false,
        },
        includeCountry: {
            type: Boolean,
            default: false,
        },
        forbidStateChange: {
            type: [Boolean, String],
            default: false,
        },
        doLogging: {
            type: Boolean,
            default: true,
        },
        allowedCountries: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            eid: `e${this._uid}`,
            events: {
                input: 'input',
                inputAddress: 'input-address',
                resetMap: 'resetMap',
            },
            map: null,
            centerLocation: { ...this.value },
            flyoutActive: false,
            editMode: true,

            address: null,
            lockedState: null,
            searchFieldFocused: false,
            google: null,
            programaticMapUpdate: null,
        };
    },
    computed: {
        ...mapGetters('platform', ['initialMapLocation']),

        isValid() {
            if (!this.hasValidState) return false;
            const hasLocation = _get(this, 'centerLocation.lat');
            const hasAddress = !_isEmpty(this.address);

            if (this.allowIncompleteAddress) {
                return !!hasLocation;
            } else {
                return !!(this.enableSearch ? hasLocation && hasAddress : hasLocation);
            }
        },
        hasValidState() {
            const hasState = _get(this, 'address.stateCode') || _get(this, 'address.state');
            return !this.forbidStateChange || this.lockedState === hasState;
        },
    },
    watch: {
        value(value) {
            if (this.flyoutActive && value && value.lat && value.lng && !locationsEqual(this.centerLocation, value)) {
                this.centerLocationOnMap(value);
            }
        },
        flyoutActive(value) {
            if (value) {
                setTimeout(() => {
                    this.createMap();

                    // reverse geocode provided location, if search is enabled
                    this.initializeAddressUpdate(this.initialAddress, this.value);
                }, 500);
            }
        },
    },
    created() {
        this.lockedState = this.forbidStateChange ? this.initialAddress.state : null;
    },
    methods: {
        getError(key) {
            if (key === 'forbidStateChange') {
                const errorMessage =
                    typeof this.forbidStateChange === 'string'
                        ? this.forbidStateChange
                        : this.$t('components.locationPicker.errors.forbidStateChange');
                return this.hasValidState ? null : errorMessage;
            }
        },
        changeSearchFieldFocused(state) {
            this.searchFieldFocused = state;
        },
        createMap() {
            this.centerLocation = this.value?.lat !== undefined ? this.value : this.initialMapLocation;
            const initialZoom = this.value ? DEFAULT_ZOOM_WITH_LOCATION : DEFAULT_ZOOM_LEVEL_WITHOUT_LOCATION;

            GoogleMaps.then(google => {
                this.google = google;
                this.map = createMap(google, `${this.eid}-map`, {
                    zoom: initialZoom,
                    center: this.centerLocation,
                    disableDefaultUI: true,
                    mapTypeId: this.mapType,
                });

                if (!this.editMode) {
                    const icon = {
                        url: FlagIconPath,
                        scaledSize: new google.maps.Size(50, 50), // maps adds weird 16px to the marker
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(14, 44.6),
                    };

                    this.destinationMarker = new google.maps.Marker({
                        position: this.centerLocation,
                        map: this.map,
                        icon: icon,
                        zIndex: 2,
                    });
                }

                this.map.addListener(
                    'center_changed',
                    _debounce(async () => {
                        if (this.doLogging) this.$logger().log('Detected change: center position');

                        if (!this.editMode) {
                            if (this.doLogging) this.$logger().log('// skip update, read only');
                            return;
                        }

                        // skip update, map was updated as a side effect
                        if (this.programaticMapUpdate) {
                            this.programaticMapUpdate = false;
                            return;
                        }

                        // skip update if already equals
                        if (locationsEqual(this.map.getCenter(), this.centerLocation)) {
                            if (this.doLogging) this.$logger().log('// skip update if already equals');
                            return;
                        }

                        // reverse gocode address, if map was updated by hand and not by address search
                        if (this.enableSearch) {
                            if (this.doLogging) this.$logger().log('reverse geocode map center');
                            this.handleAddressUpdateByLocation(this.map.getCenter());
                            return;
                        }

                        if (this.doLogging) this.$logger().log('Updated: center position');
                        this.centerLocation = this.map.getCenter();
                    }, DEBOUNCE_CHANGED_MAP_CENTER_MS)
                );
            });
        },
        open(editMode) {
            this.editMode = editMode;
            this.flyoutActive = true;
        },

        centerLocationOnMap(location) {
            if (!this.map) {
                return;
            }

            if (this.doLogging) this.$logger().log('Applied change: center position');
            // here was code to set the zoom level that was deleted for SCHUTT-5544
            this.map.setCenter(normalizeLocation(location));
        },

        centerPlaceOnMap(place) {
            const northeast = place.geometry.viewport.getNorthEast();
            const southwest = place.geometry.viewport.getSouthWest();

            this.map.fitBounds(
                new this.google.maps.LatLngBounds(
                    new this.google.maps.LatLng(southwest.lat(), southwest.lng()), // SW
                    new this.google.maps.LatLng(northeast.lat(), northeast.lng()) // NE
                )
            );
        },

        async handleLocationUpdate() {
            if (this.doLogging) this.$logger().log('location update - handleLocationUpdate');

            const location = normalizeLocation(this.centerLocation);
            this.$emit(this.events.input, location);

            if (this.enableSearch) {
                // hotfix: ensure that every selected location has proper address data if search is enabled (address data are mandatory)
                const place = await locationToStructurizedAddress(location);
                replacePlaceLabelWithFormattedAddress(place);

                this.address = {
                    ...place,
                };

                if (this.ensureState && !this.address.stateCode) {
                    await this.stateful('retrieveState', async () => {
                        this.address.stateCode = await retrieveStateByLocation(location);
                    });
                }
                this.$emit(this.events.inputAddress, this.address);
            }

            this.flyoutActive = false;
        },

        getLocationInfo(location) {
            return new Promise((resolve, reject) => {
                GoogleMaps.then(google => {
                    const geocoder = new google.maps.Geocoder();
                    geocoder.geocode({ location }, (results, status) =>
                        this.resolveGeocodePromise(resolve, reject, results, status)
                    );
                });
            });
        },

        resolveGeocodePromise(resolve, reject, results, status) {
            if (results?.length > 0 && status === 'OK') {
                resolve(results[0]);
            } else {
                reject(null);
            }
        },

        async initializeAddressUpdate(address, location) {
            if (this.doLogging) this.$logger().log('initialize address location');
            const useDefaultAddress = _isEmpty(location);

            if (useDefaultAddress) {
                location = this.initialMapLocation;
            }

            if (this.enableSearch) {
                if (address) {
                    this.address = address;
                } else {
                    const place = await locationToStructurizedAddress(location);
                    replacePlaceLabelWithFormattedAddress(place);

                    // to avoid display of the default location the
                    // address returned from google is overwritten
                    this.address = useDefaultAddress ? emptyAddress : place;
                }
            }

            this.centerLocation = location;

            // google maps is not firing center_changed event initially
            this.centerLocationOnMap(location);
        },

        handleAddressUpdateBySearch(address) {
            if (this.doLogging) this.$logger().log('update by address');

            // search box removed input
            if (address === null) {
                this.address = null;
                return;
            }

            this.address = address;
            this.centerLocation = address.location;

            this.programaticMapUpdate = true;
            this.centerPlaceOnMap(this.address.place);

            this.$nextTick(() => {
                this.updateAddressFromLocation();
                this.changeSearchFieldFocused(false);
            });
        },

        async updateAddressFromLocation() {
            if (this.address?.location) {
                const place = await locationToStructurizedAddress(this.address.location);

                replacePlaceLabelWithFormattedAddress(place);
                this.address = {
                    ...place,
                };
            }
        },

        async handleAddressUpdateByLocation(location) {
            if (this.doLogging) this.$logger().log('update by location');

            if (this.enableSearch) {
                const place = await locationToStructurizedAddress(location);
                replacePlaceLabelWithFormattedAddress(place);
                this.address = {
                    ...place,
                };
            }
            this.centerLocation = location;

            // google maps is not firing center_changed event initially
            this.programaticMapUpdate = true;
            this.centerLocationOnMap(location);
            this.changeSearchFieldFocused(false);
        },

        handleCurrentLocation(location) {
            if (this.doLogging) this.$logger().log('update by current location');
            this.handleAddressUpdateByLocation(location);
        },
    },
};
</script>

<style lang="scss">
.location-picker__intro {
    padding: 0 20px;
}

.location-picker__address {
    background-color: $color-white;
    padding: 10px 15px;
    margin: 0 20px 2px;
    box-shadow: $boxShadow-bottomShort;
}

.location-picker__state-feld {
    position: relative;
    z-index: 1;
    box-shadow: $boxShadow-bottomShort;
    margin: 0 20px -30px;
}

.location-picker__location-button {
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 36px;
    height: 36px;
}

@keyframes spinning {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.location-picker__location-button--loading {
    animation: spinning 1s linear infinite;
}

.location-picker__map-wrapper {
    height: 100%;
    position: relative;
    flex: 1 1;
    display: flex;
}

.location-picker__map {
    background-color: #eee;
    min-height: 100%;
    flex: 1 1;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
}

.location-picker__flag {
    position: absolute;
    width: 92px;
    height: 92px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.location-picker__headline {
    margin-left: 15px;
}

.location-picker__address-field {
    padding: 10px;
    position: relative;
    z-index: 1;
}

.location-picker__body {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-flow: column;
}

.location-picker__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    transition: opacity 0.2s ease-out;
    pointer-events: none;
    opacity: 0;
}

.location-picker__backdrop--active {
    opacity: 0.5;
    background-color: $color-darkGrey;
    pointer-events: all;
}

.keyboard-will-open,
.keyboard-open:not(.keyboard-will-close) {
    .hide-me--keyboard-open {
        display: none;
    }
}
</style>

<template>
    <div class="mx-4 flex flex-col rounded bg-surface px-4 md:px-6 lg:mx-0">
        <div class="align-center flex-col">
            <div class="align-center mt-6 flex justify-center">
                <div v-if="projectPosition.type !== 'shipment'" class="mr-6 hidden md:inline-block md:inline-block">
                    <img
                        v-if="projectPosition.image"
                        :src="projectPosition.image.url.small"
                        class="h-[80px] w-[112px] max-w-none object-cover text-[0px]"
                    />
                    <ProductPlaceHolder
                        v-else
                        :alt="projectPosition.freightDescription || projectPosition.product.productNameLocalized"
                        class="mr-8 h-[80px] w-[112px] object-fill"
                    />
                </div>

                <div
                    v-if="projectPosition.isDisposal"
                    class="flex w-full flex-col-reverse justify-between sm:items-stretch md:flex-row md:items-center"
                >
                    <div class="w-full flex-col justify-center">
                        <div class="mb-2 flex items-center xs-max:flex-wrap">
                            <span
                                class="font-headline-lg-strong whitespace-nowrap"
                                :class="{ 'font-copy-xl-strong': $root.isDesktop }"
                                >{{ projectPosition.product.wasteCode
                                }}<span v-if="projectPosition.product.isHazardousWaste" class="p-0">*</span>
                            </span>

                            <div class="ml-4 flex flex-row xs-max:ml-0 xs-max:whitespace-nowrap">
                                <SfTag class="mr-4 whitespace-nowrap">
                                    {{ projectPosition.product.variantDisposal.pollutionType.label }}
                                </SfTag>

                                <SfTag
                                    v-if="projectPosition.product.isHazardousWaste"
                                    type="error"
                                    class="whitespace-nowrap bg-surface-critical-dark-subdued"
                                >
                                    <SfWarningIcon size="xxs" class="mr-1 shrink-0 bg-surface-critical-subdued" />
                                    <span class="text-on-critical-subdued">
                                        {{ $t('pages.project.position.view.freightType.waste_dangerous_plural') }}
                                    </span>
                                </SfTag>
                            </div>
                        </div>

                        <div
                            v-if="projectPosition.product && projectPosition.product.productNameLocalized"
                            class="font-copy-sm flex justify-between"
                            :class="{ 'font-copy-xl-strong': $root.isDesktop }"
                        >
                            <span>{{ projectPosition.product.productNameLocalized }}</span>
                        </div>
                    </div>

                    <HorizontalDivider v-if="projectPosition.disposalProofNumber && !$root.isDesktop" class="my-6" />
                </div>

                <div v-if="!projectPosition.isDisposal" class="mr-auto flex items-center justify-center">
                    <span
                        v-if="projectPosition.freightDescription"
                        class="left font-copy-sm"
                        :class="{ 'font-copy-xl-strong': $root.isDesktop }"
                    >
                        {{ projectPosition.freightDescription }}
                    </span>
                    <span
                        v-if="projectPosition.product && projectPosition.product.productNameLocalized"
                        class="font-headline-lg-strong"
                        :class="{ 'font-copy-xl-strong': $root.isDesktop }"
                    >
                        {{ projectPosition.product.productNameLocalized }}
                    </span>
                    <div>
                        <SfTag v-if="projectPosition.freightType" class="font-copy-md ml-4">
                            {{ getTranslationKey }}
                        </SfTag>
                    </div>
                </div>
                <div v-if="validityRange" class="ml-auto flex flex-col items-end justify-center pl-4">
                    <span class="font-headline-lg whitespace-nowrap"> {{ validityRange }}</span>
                    <span class="font-copy-sm mt-2 text-subdued">{{ $t('pages.project.form.validity.title') }}</span>
                </div>
            </div>

            <div class="mt-6 flex flex-col md:mt-12 md:flex-row md:items-end">
                <ContingentStatistics class="flex gap-x-6 md:justify-start" :project-position="projectPosition" />
                <ProjectPositionContingentProgress
                    class="mt-4 md:ml-18 md:mt-0"
                    :project-position="projectPosition"
                    size="lg"
                />
                <div v-if="projectPosition.showRemainingValidity" class="mt-4 md:ml-12 md:mt-0">
                    <SfTag type="warning">
                        <SfWarningIcon size="xxs" />
                        <span class="font-copy-sm ml-1 whitespace-nowrap">
                            {{
                                $tc(
                                    'components.ProjectRemainingValidityTag.remaining',
                                    projectPosition.remainingValidityInDays
                                )
                            }}
                        </span>
                    </SfTag>
                </div>
            </div>

            <HorizontalDivider v-if="projectPosition.note" class="my-6 md:mb-6 md:mt-12" />

            <div v-if="projectPosition.note" class="flex flex-col">
                <span class="font-copy-md-strong mb-1">
                    {{ $t('pages.project.position.view.main.noteLabel') }}
                </span>

                <ShowMoreText :lines="2">
                    {{ projectPosition.note }}
                </ShowMoreText>
            </div>

            <HorizontalDivider class="mb-6 mt-6" />

            <div class="mb-6 flex-col md:flex-row">
                <SfLink
                    v-if="showQuotePricesAction"
                    class="font-copy-md-strong mb-6 mr-12 text md:mb-0"
                    @click.stop="$emit('showQuotePrices')"
                >
                    {{ $t('pages.project.position.quotePrices.modal') }}
                </SfLink>

                <SfLink
                    v-if="projectPosition.isDisposal"
                    class="font-copy-md-strong mr-12 text"
                    @click="$emit('openAnalysis')"
                >
                    {{ $t('pages.project.position.quotePrices.analysisModal') }}
                </SfLink>
            </div>
        </div>
    </div>
</template>

<script>
import ProjectPositionView from '@/models/ProjectPositionView';
import ProjectFacadeView from '@/models/ProjectFacadeView';
import { formatDateRange } from '@/services/utils/date';

import ContingentStatistics from '@/pages/Checkout/components/ContingentSelection/ContingentStatistics';
import ProjectPositionContingentProgress from '@/components/Project/ProjectPositionContingentProgress';
import { SfWarningIcon, SfTag, SfLink } from '@schuettflix/vue-components';
import HorizontalDivider from '@/_components/HorizontalDivider/HorizontalDivider.vue';
import ShowMoreText from '@/_components/ShowMoreText/ShowMoreText.vue';
import ProductPlaceHolder from '@/assets/product_placeholder.svg';

export default {
    name: 'ProjectPositionMainInfo',
    components: {
        ContingentStatistics,
        ProjectPositionContingentProgress,
        HorizontalDivider,
        ShowMoreText,

        SfWarningIcon,
        SfTag,
        SfLink,
        ProductPlaceHolder,
    },
    props: {
        project: {
            type: ProjectFacadeView,
            required: true,
        },
        projectPosition: {
            type: ProjectPositionView,
            required: true,
        },
    },
    computed: {
        showQuotePricesAction() {
            return this.$can('seePrices');
        },
        getTranslationKey() {
            return this.$t(
                {
                    material: 'pages.project.position.view.freightType.material',
                    waste_dangerous: 'pages.project.position.view.freightType.waste_dangerous',
                    waste_dangerous_plural: 'pages.project.position.view.freightType.waste_dangerous_plural',
                    waste_non_dangerous: 'pages.project.position.view.freightType.waste_non_dangerous',
                    rc_material: 'pages.project.position.view.freightType.rc_material',
                }[this.projectPosition.freightType]
            );
        },
        validityRange() {
            return formatDateRange(
                new Date(this.projectPosition.validFrom).getTime(),
                new Date(this.projectPosition.validTo).getTime()
            );
        },
    },
    methods: {
        formatDateRange,
    },
};
</script>

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import user from './user';
import i18n from './i18n';
import tracking from './tracking';
import analytics from './analytics';
import notification from './notification';
import basket from './basket';
import localPreferences from './localPreferences';
import platform from './platform';
import request from './request';
import transportActions from './transportActions';
import globalActions from './globalActions';
import abilities from './abilities';
import session from './session';
import productManagement from './productManagement';
import validation from './validation';
import waste from './waste';
import constructionProject from './constructionProject';
import projectPosition from './waste/projectPosition';
import factoryManagement from './factoryManagement';
import transportPlan from './transportPlan';
import { storeInstance } from '@/utils/instances';

Vue.use(Vuex);

/**
 * @type {import('vuex').Store<any>}
 */
const store = new Vuex.Store({
    modules: {
        user,
        i18n,
        tracking,
        analytics,
        notification,
        basket,
        localPreferences,
        platform,
        request,
        transportActions,
        globalActions,
        abilities,
        session,
        productManagement,
        validation,
        waste,
        projectPosition,
        factoryManagement,
        transportPlan,
        constructionProject,
    },

    plugins: [
        createPersistedState({
            paths: ['waste', 'factoryManagement'],
        }),

        createPersistedState({
            storage: localStorage,
            paths: ['user', 'i18n', 'tracking', 'notification', 'platform', 'session', 'abilities', 'localPreferences'],
        }),

        createPersistedState({
            storage: sessionStorage,
            paths: ['basket', 'projectPosition', 'constructionProject'],
        }),
    ],
});

storeInstance.set(store);

export default store;

<template>
    <div class="reporting__section reporting-vehicles">
        <Words block bold class="reporting-vehicles__headline">
            {{ $t('pages.reporting.panels.vehicleClassList.headline') }}
        </Words>

        <ol class="reporting-vehicles__list">
            <li v-for="(item, index) in items" :key="index" class="reporting-vehicles__list-item">
                <Words bold>{{ item.name }}</Words>
                <VehicleClassIconSet :icon="item.icon" />
                <span class="reporting-vehicles__list-item-spacer" />
                <span>{{ $n(item.sum, 'currency') }}</span>
            </li>
        </ol>
    </div>
</template>

<script>
import VehicleClassIconSet from '@/components/IconSet/VehicleClassIconSet';
import Words from '@/components/Typography/Words';

export default {
    name: 'VehicleClassList',
    components: {
        VehicleClassIconSet,
        Words,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.reporting-vehicles__headline {
    margin-bottom: 30px;
}

.reporting-vehicles__list {
    list-style: none;
    padding: 0;
    margin: 0;
    counter-reset: vehicle-list;

    svg {
        width: auto;
        height: 30px;
    }
}

.reporting-vehicles__list-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 25px 0;

    &::before {
        counter-increment: vehicle-list;
        content: counter(vehicle-list) '.';
        color: $color-grey;
        display: block;
        margin-right: 10px;
    }

    > * {
        margin: 0 10px;

        &:last-child {
            margin-left: auto;
            margin-right: 0;
        }
    }
}

.reporting-vehicles__list-item-spacer {
    flex-grow: 1;
    height: 1px;
    background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;
}
</style>

import * as firebase from 'firebase/app';
import { AnalyticsService } from '@schuettflix/analytics-core';
import { ConsoleAnalyticsProvider } from '@schuettflix/analytics-core/providers/console';
import { WebFirebaseAnalyticsProvider } from '@schuettflix/analytics-core/providers/firebase';
const provider = process.env.NODE_ENV !== 'production'
    ? new ConsoleAnalyticsProvider()
    : new WebFirebaseAnalyticsProvider({
        firebaseApp: firebase.initializeApp(window.firebaseConfig),
    });
export const analyticsService = new AnalyticsService({
    provider,
});

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select-box-v3",class:{
        'select-box-v3--invalid': _vm.error || _vm.hasError,
        'select-box-v3--focused': false && _vm.isFocused,
        'select-box-v3--filled': _vm.hasValue,
        'select-box-v3--dark': _vm.dark,
        'select-box-v3--medium': _vm.medium,
        'select-box-v3--small': _vm.small,
        'select-box-v3--tiny': _vm.tiny,
    }},[_c('div',{staticClass:"select-box-v3__input-group"},[_c('select',{key:_vm.processedValue,staticClass:"select-box-v3__input",class:{
                'select-box-v3__input--filled': _vm.hasValue,
                'select-box-v3__input--iconized': _vm.$slots.icon,
                'select-box-v3__input--readonly': _vm.readonly,
                'select-box-v3__input--without-label': !_vm.label,
            },attrs:{"id":_vm.eid,"name":_vm.name,"disabled":_vm.disabled || _vm.readonly,"data-test":`${_vm.dataTest}-select`},domProps:{"value":_vm.processedValue},on:{"change":_vm.update,"blur":_vm.blured,"focus":_vm.focused}},_vm._l((_vm.options),function(option,i){return _c('option',{key:i,domProps:{"value":i,"selected":i === _vm.selectedKey}},[_vm._v(" "+_vm._s(_vm.optionLabelRenderer(option, i))+" ")])}),0),(_vm.label)?_c('label',{staticClass:"select-box-v3__label",attrs:{"for":_vm.eid}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.$slots.icon)?_c('div',{staticClass:"select-box-v3__icon"},[_vm._t("icon")],2):_vm._e(),_c('ArrowDownIcon',{staticClass:"select-box-v3__arrow select-box-v3__arrow--down",class:{ 'select-box-v3__arrow--readonly': _vm.readonly }}),(_vm.hasValue && !_vm.preventReset && !_vm.readonly)?_c('Button',{staticClass:"select-box-v3__reset",attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.$emit('input', null)}}},[_c('ResetIcon',{attrs:{"width":"13"}})],1):_vm._e()],1),(_vm.error && _vm.error.length)?_c('ErrorMessage',{attrs:{"message":_vm.error,"dark":_vm.dark}}):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
    <div class="pagination flex" :class="{ 'pagination--align-right': alignRight }">
        <p class="pagination__info shrink-0">
            {{
                $t('components.pagination.infoText', {
                    start,
                    end,
                    total: count,
                })
            }}
        </p>

        <div v-if="pageCount > 0" class="pagination__page-select">
            <Words>{{ $t('components.pagination.page') }}</Words>
            <SelectBox :value="page" tiny :disabled="disabled" class="pagination__page-select-box" @input="loadPage">
                <option v-for="i in pageCount" :key="i" :value="i">
                    {{ i }}
                </option>
            </SelectBox>
            <Words unbreakable>{{ $t('components.pagination.of', { count: pageCount }) }}</Words>
        </div>
        <div v-else />

        <div v-if="pageCount > 0" class="pagination__page-nav">
            <button
                type="button"
                class="pagination__nav-button"
                :class="{ 'pagination__nav-button--inactive': page <= 1 }"
                @click="loadPage(page - 1)"
            >
                <ArrowRight class="icon--inline pagination__arrow-left" :height="12" />
            </button>

            <button
                type="button"
                class="pagination__nav-button"
                :class="{ 'pagination__nav-button--inactive': page >= pageCount }"
                @click="loadPage(page + 1)"
            >
                <ArrowRight class="icon--inline" :height="12" />
            </button>
        </div>
        <div v-else />
    </div>
</template>

<script>
import _get from 'lodash/get';

import FilterResult from '@/services/Api/FilterResult';

import SelectBox from '@/components/Form/SelectBox.v2';
import Words from '@/components/Typography/Words';

import ArrowRight from '@/assets/icons/regular/arrow-stripeless.svg';

export default {
    name: 'Pagination',

    components: {
        SelectBox,
        Words,

        ArrowRight,
    },

    props: {
        result: {
            type: FilterResult,
            required: true,
        },
        marginPages: {
            type: Number,
            default: 1,
        },
        pageRange: {
            type: Number,
            default: 5,
        },
        alignRight: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        start() {
            if (this.result.position?.start !== null) {
                return this.result.position.start;
            }

            return this.page * this.perPage - this.perPage;
        },

        end() {
            if (this.result.position?.end !== null) {
                return this.result.position.end;
            }

            return this.page === this.pageCount ? this.count : this.page * this.perPage;
        },

        pageCount() {
            return _get(this.result, 'supportedFilters.page.max', 0);
        },

        perPage() {
            return _get(this.result, 'appliedFilter.perPage', 0);
        },

        page() {
            return _get(this.result, 'appliedFilter.page', 0);
        },

        count() {
            return _get(this.result, 'count', 0);
        },

        disabled() {
            return this.result.isLoadingMore;
        },
    },

    methods: {
        loadPage(page) {
            if (page < 1 || page > this.pageCount) return;
            if (this.disabled) return;

            this.$emit('pageNumberUpdated', page);
        },
    },
};
</script>

<style lang="scss">
.pagination {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(min-content, max-content));
    grid-template-areas: 'pagination pagination navigation';
    grid-gap: 20px;
    align-items: center;

    @media screen and (min-width: $screen-xxl) {
        grid-template-areas: 'items pagination navigation';
    }
}

.pagination--align-right {
    @media screen and (min-width: $layout-desktop-min) {
        justify-content: flex-end;
    }
}

.pagination__info {
    display: none;
    grid-area: items;

    @media screen and (min-width: $screen-xxl) {
        display: block;
        justify-self: end;
    }
}

.pagination__page-select {
    grid-area: pagination;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0 -5px;

    > * {
        margin: 0 5px;
    }
}

.pagination__page-select-box {
    min-width: 65px;
}

.pagination__page-nav {
    grid-area: navigation;
    justify-self: end;
    align-self: stretch;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
}

.pagination__nav-button {
    display: flex;
    color: $color-darkGrey;
    text-align: center;
    cursor: pointer;
    position: relative;
    background-color: transparent;
    font-weight: inherit;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    -webkit-appearance: none;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    width: 40px;

    &:hover {
        opacity: 0.5;
    }
}

.pagination__nav-button--inactive {
    color: $color-mediumGrey;
    cursor: inherit;

    svg {
        .f-base {
            fill: $color-mediumGrey;
        }
        .s-base {
            stroke: $color-mediumGrey;
        }
    }

    &:hover {
        opacity: 1;
    }
}

.pagination__arrow-left {
    transform: rotate(180deg);
}
</style>

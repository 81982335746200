<template>
    <ModalBox class="tracking-info-modal" :active="isActive" @closed="decline()">
        <IllustrationIcon class="tracking-info-modal__icon" />
        <Words block pre>{{ $t('components.trackingInfoModal.message') }}</Words>
        <ul>
            <li>
                <Words>{{ $t('components.trackingInfoModal.benefit1') }}</Words>
            </li>
            <li>
                <Words>{{ $t('components.trackingInfoModal.benefit2') }}</Words>
            </li>
        </ul>

        <Words block>{{ $t('components.trackingInfoModal.permissionQuestion') }}</Words>

        <ButtonGroup slot="actions" inline>
            <Button primary light @click="decline()">
                {{ $t('components.trackingInfoModal.decline') }}
            </Button>
            <Button primary @click="accept()">
                {{ $t('components.trackingInfoModal.accept') }}
            </Button>
        </ButtonGroup>
    </ModalBox>
</template>

<script>
import { EventBus } from '@/services/EventBus';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { EVENT_TRACKING_PERMISSIONS_REQUEST, EVENT_TRACKING_PERMISSIONS_REQUEST_TIMEOUT } from '@/constants/events';

import ModalBox from '@/components/Modal/ModalBox';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Button from '@/components/Button/Button';

import IllustrationIcon from '@/assets/icons/transport-overview--130px.svg';
import Words from '@/components/Typography/Words';
import { SECONDS } from '@/services/utils/time';

/**
 * Request pre-permission dialog for permissions to access users location
 * NOTE: it's not the system confirmation!
 * @return {Promise<unknown>}
 */
export const requestTrackingPermission = async function () {
    return new Promise((resolve, reject) => {
        // Fallback mechanism, if the user is not handling the prompt
        const timeoutId = setTimeout(() => {
            reject();
            EventBus.$emit(EVENT_TRACKING_PERMISSIONS_REQUEST_TIMEOUT);
        }, 60 * SECONDS);

        EventBus.$emit(EVENT_TRACKING_PERMISSIONS_REQUEST, resolve, () => {
            clearTimeout(timeoutId);
            reject();
        });
    });
};

export default {
    name: 'TrackingInfoModal',
    components: {
        Words,
        Button,
        ButtonGroup,
        ModalBox,

        IllustrationIcon,
    },
    mixins: [eventHubMixin],
    data() {
        return {
            isActive: false,
            resolveHandler: null,
            rejectHandler: null,
        };
    },
    created() {
        this.subscribe(EVENT_TRACKING_PERMISSIONS_REQUEST, this.handlePermissionRequest.bind(this));
        this.subscribe(EVENT_TRACKING_PERMISSIONS_REQUEST_TIMEOUT, this.timeoutHandler.bind(this));
    },
    methods: {
        handlePermissionRequest(resolve, reject) {
            // cleanup old handler, if available
            this.rejectHandler && this.rejectHandler();

            this.isActive = true;
            this.resolveHandler = resolve;
            this.rejectHandler = reject;
        },
        accept() {
            this.isActive = false;
            const handler = this.resolveHandler;
            this.resolveHandler = null;
            handler && handler();
        },
        decline() {
            this.isActive = false;
            const handler = this.rejectHandler;
            this.rejectHandler = null;
            handler && handler();
        },
        timeoutHandler() {
            this.isActive = false;
            this.rejectHandler = null;
            this.resolveHandler = null;
        },
    },
};
</script>

<style scoped>
.tracking-info-modal__icon {
    width: 130px;
    height: 130px;
    margin: 0 auto 30px;
}
</style>

<template>
    <div
        :class="{
            'people ml-auto flex items-center justify-between md:ml-0 lg:ml-auto lg:ml-auto  lg:justify-end ':
                !isOverview,
            'flex-col': item.isCustom,
        }"
    >
        <span v-if="!hideTitle" class="font-copy-sm-strong mb-4 block" :class="{ 'self-baseline': item.isCustom }">
            {{ $t('pages.checkout.summary.people.headline') }}
        </span>

        <div
            v-if="singleOrNoMessage || hideMessage"
            class="w-full"
            :class="{ 'flex w-full items-center justify-center ': !isOverview }"
        >
            <div v-if="isOverview" class="flex flex-col">
                <div class="mb-6 flex items-center">
                    <Avatar :src="item.createdBy.image && item.createdBy.image.url" size="md" class="mr-4" />
                    <div class="flex flex-col items-center">
                        <span class="font-copy-sm mb-2 w-full text-left" :class="{ 'text-subdued': !darkLabels }">
                            {{ $t('pages.checkout.summary.people.createdBy') }}
                        </span>
                        <div class="w-full text-left">
                            <span class="font-copy-md-strong whitespace-nowrap text-left">
                                {{ item.createdBy.firstName }}
                                <span class="whitespace-nowrap">{{ item.createdBy.lastName }}</span>
                            </span>
                        </div>
                    </div>
                </div>

                <div v-if="item.verifiedBy" class="flex items-center">
                    <Avatar :src="item.verifiedBy.image && item.verifiedBy.image.url" size="md" class="mr-4" />
                    <div class="flex flex-col items-center">
                        <span class="font-copy-sm mb-2 w-full text-left" :class="{ 'text-subdued': !darkLabels }">
                            {{ $t('pages.checkout.summary.people.verifiedBy') }}
                        </span>
                        <div class="w-full text-left">
                            <span class="font-copy-md-strong whitespace-nowrap">
                                {{ item.verifiedBy.firstName }}
                                <span class="whitespace-nowrap">{{ item.verifiedBy.lastName }}</span>
                            </span>
                        </div>
                    </div>
                </div>

                <div v-else class="inline-block text-right lg:mr-18">
                    <span
                        v-if="item.customOrder || item.isCustom"
                        class="font-copy-sm mb-2"
                        :class="{ 'text-subdued': !darkLabels }"
                    >
                        {{ $t('pages.checkout.summary.people.discussedWith') }}
                    </span>
                    <span v-else class="font-copy-sm mb-2" :class="{ 'text-subdued': !darkLabels }">
                        {{ $t('pages.checkout.summary.people.supervisor') }}
                    </span>

                    <div class="flex flex-nowrap items-start justify-center">
                        <div class="pl-0 pr-2">
                            <span class="font-copy-md-strong">
                                {{ item.supervisor.firstName }}<br />
                                <span class="font-copy-md-strong whitespace-nowrap">
                                    {{ item.supervisor.lastName }}
                                </span>
                            </span>
                        </div>
                        <Avatar
                            :src="item.supervisor.image && item.supervisor.image.url"
                            class="people__image"
                            size="small"
                        />
                    </div>
                </div>
            </div>

            <div v-else class="flex w-full items-center justify-between">
                <div class="inline-block w-1/2 lg:mr-18">
                    <span class="font-copy-sm mb-2" :class="{ 'text-subdued': !darkLabels }">
                        {{ $t('pages.checkout.summary.people.createdBy') }}
                    </span>
                    <div class="flex flex-nowrap items-start justify-center">
                        <Avatar
                            :src="item.createdBy.image && item.createdBy.image.url"
                            class="people__image"
                            size="small"
                        />
                        <div class="grow pl-2 align-middle">
                            <span class="font-copy-md-strong">
                                {{ item.createdBy.firstName }}<br />
                                <span class="whitespace-nowrap">{{ item.createdBy.lastName }}</span>
                            </span>
                        </div>
                    </div>
                </div>

                <div v-if="item.verifiedBy" class="inline-block whitespace-nowrap text-right lg:ml-18">
                    <span
                        v-if="item.status === 'rejected'"
                        class="font-copy-sm mb-2"
                        :class="{ 'text-subdued': !darkLabels }"
                    >
                        {{ $t('pages.checkout.summary.people.rejectedBy') }}
                    </span>

                    <span
                        v-else-if="item.customOrder || item.isCustom"
                        class="font-copy-sm mb-2"
                        :class="{ 'text-subdued': !darkLabels }"
                    >
                        {{ $t('pages.checkout.summary.people.discussedWith') }}
                    </span>

                    <span v-else class="font-copy-sm mb-2" :class="{ 'text-subdued': !darkLabels }">
                        {{ $t('pages.checkout.summary.people.verifiedBy') }}
                    </span>

                    <div class="flex flex-nowrap items-start justify-center">
                        <div class="grow pl-2 align-middle">
                            <span class="font-copy-md-strong">
                                {{ item.verifiedBy.firstName }}<br />
                                <span class="font-copy-md-strong whitespace-nowrap">
                                    {{ item.verifiedBy.lastName }}
                                </span>
                            </span>
                        </div>
                        <Avatar
                            :src="item.verifiedBy.image && item.verifiedBy.image.url"
                            class="people__image ml-2"
                            size="small"
                        />
                    </div>
                </div>

                <div v-else class="inline-block w-1/2 text-right">
                    <span
                        v-if="item.customOrder || item.isCustom"
                        class="font-copy-sm mb-2"
                        :class="{ 'text-subdued': !darkLabels }"
                    >
                        {{ $t('pages.checkout.summary.people.discussedWith') }}
                    </span>
                    <span v-else class="font-copy-sm mb-2" :class="{ 'text-subdued': !darkLabels }">
                        {{ $t('pages.checkout.summary.people.supervisor') }}
                    </span>

                    <div class="flex flex-nowrap items-start justify-end">
                        <div class="pl-0 pr-2">
                            <span class="font-copy-md-strong">
                                {{ item.supervisor.firstName }}<br />
                                <span class="font-copy-md-strong whitespace-nowrap">
                                    {{ item.supervisor.lastName }}
                                </span>
                            </span>
                        </div>
                        <Avatar
                            :src="item.supervisor.image && item.supervisor.image.url"
                            class="people__image"
                            size="small"
                        />
                    </div>
                </div>
            </div>

            <Message v-if="item.requestMessage && !hideMessage" :message="item.requestMessage" />

            <Message v-if="item.rejectionMessage && !hideMessage" :message="item.rejectionMessage" bubble-right />
        </div>

        <div v-else>
            <div class="flex">
                <div class="inline-block w-1/2">
                    <span class="font-copy-sm mb-2" :class="{ 'text-subdued': !darkLabels }">
                        {{ $t('pages.checkout.summary.people.createdBy') }}
                    </span>
                    <div class="flex flex-nowrap items-start justify-center">
                        <Avatar
                            :src="item.createdBy.image && item.createdBy.image.url"
                            class="people__image"
                            size="small"
                        />
                        <div class="grow pl-2 align-middle">
                            <span class="font-copy-md-strong">
                                {{ item.createdBy.firstName }}<br />
                                <span class="font-copy-md-strong whitespace-nowrap">
                                    {{ item.createdBy.lastName }}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <Message :message="item.requestMessage" />

            <div class="flex">
                <div class="inline-block w-1/2" />

                <div v-if="item.verifiedBy" class="inline-block w-1/2 text-right">
                    <span
                        v-if="item.status === 'rejected'"
                        class="font-copy-sm mb-2"
                        :class="{ 'text-subdued': !darkLabels }"
                    >
                        {{ $t('pages.checkout.summary.people.rejectedBy') }}
                    </span>

                    <span v-else class="font-copy-sm mb-2" :class="{ 'text-subdued': !darkLabels }">
                        {{ $t('pages.checkout.summary.people.verifiedBy') }}
                    </span>

                    <div class="flex flex-nowrap items-start justify-center">
                        <div class="grow pl-2 align-middle">
                            <span class="font-copy-md-strong">
                                {{ item.verifiedBy.firstName }}<br />
                                <span class="font-copy-md-strong whitespace-nowrap">
                                    {{ item.verifiedBy.lastName }}
                                </span>
                            </span>
                        </div>
                        <Avatar
                            :src="item.verifiedBy.image && item.verifiedBy.image.url"
                            class="people__image"
                            size="small"
                        />
                    </div>
                </div>

                <div v-else class="inline-block w-1/2 text-right">
                    <span class="font-copy-sm mb-2" :class="{ 'text-subdued': !darkLabels }">
                        {{ $t('pages.checkout.summary.people.supervisor') }}
                    </span>

                    <div class="flex flex-nowrap items-start justify-center">
                        <div class="ml-0 mr-2">
                            <span class="font-copy-md-strong">
                                {{ item.supervisor.firstName }}<br />
                                <span class="font-copy-md-strong whitespace-nowrap">
                                    {{ item.supervisor.lastName }}
                                </span>
                            </span>
                        </div>
                        <Avatar
                            :src="item.supervisor.image && item.supervisor.image.url"
                            class="people__image"
                            size="small"
                        />
                    </div>
                </div>
            </div>

            <Message :message="item.rejectionMessage" bubble-right />
        </div>
    </div>
</template>

<script>
import SummaryView from '@/pages/Checkout/SummaryView';

import Message from '@/components/Order/Message';
import Avatar from '@/components/Avatar';

export default {
    name: 'PeopleComponent',
    components: {
        Message,
        Avatar,
    },
    props: {
        item: {
            type: [Object, SummaryView],
            required: true,
        },
        hideTitle: {
            type: Boolean,
            default: false,
        },
        hideMessage: {
            type: Boolean,
            default: false,
        },
        darkLabels: {
            type: Boolean,
            default: false,
        },
        isOverview: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        singleOrNoMessage() {
            return (
                (!this.item.requestMessage && !this.item.rejectionMessage) ||
                (this.item.requestMessage && !this.item.rejectionMessage) ||
                (!this.item.requestMessage && this.item.rejectionMessage)
            );
        },
    },
};
</script>

<style lang="scss">
.people__image {
    flex: 0 0 32px;
    display: inline-block;
    vertical-align: middle;
    min-width: 32px;
}
</style>

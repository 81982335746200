<template>
    <GridRow class="container" :count="2" spacing="large" spaced-top>
        <TextField
            v-if="supportsFilter('search')"
            v-model="search"
            :label="$t('pages.projects.documents.filter.searchLabel')"
            class="span-2"
        />

        <MultiselectBox
            v-if="supportsFilter('documentType')"
            v-model="documentType"
            :options="documentTypeOptions"
            :option-value-renderer="option => option"
            :label="$t('pages.projects.documents.type.label')"
            multiple
            class="span-2"
        >
            <DefaultInner
                slot="button"
                slot-scope="buttonScope"
                mode="button"
                :scope="buttonScope"
                :label-renderer="option => $t(getDocumentTypeTranslationKey(option))"
            />
            <DefaultInner
                slot="option"
                slot-scope="optionScope"
                mode="option"
                :scope="optionScope"
                :label-renderer="option => $t(getDocumentTypeTranslationKey(optionScope.option))"
            />
        </MultiselectBox>

        <SelectBox
            v-if="supportsFilter('status')"
            v-model="status"
            class="span-2"
            :label="$t('pages.invoice.filter.statusHeadline')"
            :options="statusOptions"
            :option-value-renderer="option => option"
            :option-label-renderer="
                option =>
                    option == null
                        ? $t('pages.invoice.filter.statusAllLabel')
                        : $t(`pages.projects.documents.status.${option}`)
            "
        />

        <div v-if="supportsFilter('projectPosition')" class="span-2">
            <MultiselectBox
                v-model="projectPosition"
                :options="endpoint.supportedFilters.projectPosition.options"
                :option-value-renderer="
                    option =>
                        Object.keys(endpoint.supportedFilters.projectPosition.options).find(
                            key => endpoint.supportedFilters.projectPosition.options[key] === option
                        )
                "
                :label="$t('pages.documents.filter.projectPositionHeadline')"
                multiple
            >
                <DefaultInner
                    slot="button"
                    slot-scope="buttonScope"
                    mode="button"
                    :scope="buttonScope"
                    :label-renderer="option => option"
                />
                <DefaultInner
                    slot="option"
                    slot-scope="optionScope"
                    mode="option"
                    :scope="optionScope"
                    :label-renderer="option => option"
                />
            </MultiselectBox>
        </div>
        <div v-if="supportsFilter(['createdFrom', 'createdTo'])" class="span-2">
            <Words bold block spaced>{{ $t('pages.invoice.filter.dateRangeHeadline') }}</Words>
            <TimeRangeFieldset
                :from="filterScope.filter.createdFrom"
                :to="filterScope.filter.createdTo"
                type="date"
                show-quarter-options
                show-year-options
                @update-from="$set(filterScope.filter, 'createdFrom', ensurePHPTimestamp($event))"
                @update-to="$set(filterScope.filter, 'createdTo', ensurePHPTimestamp($event))"
            />
        </div>
    </GridRow>
</template>

<script>
import _get from 'lodash/get';
import _intersection from 'lodash/intersection';
import _isArray from 'lodash/isArray';
import { ensurePHPTimestamp } from '@/services/utils/date';
import { revertLocalizedValue } from '@/services/utils/localization';
import { useTwoWayBindingComputedFilter } from '@/components/Filter/FilterBox';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';

import DefaultInner from '@/components/Form/MultiselectBox/DefaultInner.v2';
import GridRow from '@/components/Layout/GridRow';
import MultiselectBox from '@/components/Form/MultiselectBox';
import SelectBox from '@/components/Form/SelectBox.v3';
import TextField from '@/components/Form/TextField.v2';
import TimeRangeFieldset from '@/components/Filter/TimeRangeFieldset.v2';
import Words from '@/components/Typography/Words';

export default {
    name: 'ProjectDocumentFilterSet',
    components: {
        DefaultInner,
        GridRow,
        MultiselectBox,
        SelectBox,
        TextField,
        TimeRangeFieldset,
        Words,
    },
    props: {
        filterScope: {
            type: Object,
            required: true,
        },
        endpoint: {
            type: Object,
            required: true,
        },
        whitelistedFields: {
            type: Array,
            default: null,
        },
        documentTypeOptions: {
            type: Array,
            default: null,
        },
        statusOptions: {
            type: Object,
            default: null,
        },
    },
    setup(props, { emit }) {
        const createTwoWayBindingComputedFilter = useTwoWayBindingComputedFilter({ props, emit });
        const { selectCreditNoteDocKey } = useLegalTerms();
        return {
            createdFrom: createTwoWayBindingComputedFilter('createdFrom'),
            createdTo: createTwoWayBindingComputedFilter('createdTo'),
            projectPosition: createTwoWayBindingComputedFilter('projectPosition'),
            search: createTwoWayBindingComputedFilter('search'),
            documentType: createTwoWayBindingComputedFilter('documentType'),
            status: createTwoWayBindingComputedFilter('status'),
            selectCreditNoteDocKey,
        };
    },
    methods: {
        ensurePHPTimestamp,
        revertLocalizedValue,
        _get,

        supportsFilter(filterName) {
            if (!this.endpoint.supportedFilters) {
                return false;
            }

            const checkProps = _isArray(filterName) ? filterName : [filterName];

            // if filterName does not appear fully in whitelistedFields,.. then the filter is not supported
            if (
                _isArray(this.whitelistedFields) &&
                _intersection(this.whitelistedFields, checkProps).length !== checkProps.length
            ) {
                return false;
            }

            return _intersection(Object.keys(this.endpoint.supportedFilters), checkProps).length === checkProps.length;
        },

        getDocumentTypeTranslationKey(type) {
            if (type === 'credit-note') {
                return this.selectCreditNoteDocKey({
                    creditNote: 'pages.projects.documents.type.creditNote',
                    selfBilling: 'pages.projects.documents.type.selfBilling',
                });
            }
            return `pages.projects.documents.type.${type}`;
        },
    },
};
</script>

<template>
    <div class="legal-information">
        <Flyout
            :active="termsOfServiceFlyoutActive"
            :headline="$t(`legalInformation.termsOfService.headline`)"
            size="large"
            @opening="loadPage('termsOfService')"
            @closed="closeFlyout('termsOfService')"
        >
            <Tile>
                <transition name="fade" mode="out-in">
                    <LoadingSpinner v-if="!content.termsOfService" block dark />
                    <div v-else v-html="content.termsOfService" />
                </transition>
            </Tile>
        </Flyout>

        <Flyout
            :active="privacyPolicyFlyoutActive"
            :headline="$t('legalInformation.privacyPolicy.headline')"
            size="large"
            @opening="loadPage('privacyPolicy')"
            @closed="closeFlyout('privacyPolicy')"
        >
            <Tile>
                <transition name="fade" mode="out-in">
                    <LoadingSpinner v-if="!content.privacyPolicy" block dark />
                    <div v-else v-html="content.privacyPolicy" />
                </transition>
            </Tile>
        </Flyout>

        <Flyout
            :active="termsOfUseFlyoutActive"
            :headline="$t('legalInformation.termsOfUse.headline')"
            size="large"
            @opening="loadPage('termsOfUse')"
            @closed="closeFlyout('termsOfUse')"
        >
            <Tile>
                <transition name="fade" mode="out-in">
                    <LoadingSpinner v-if="!content.termsOfUse" block dark />
                    <div v-else v-html="content.termsOfUse" />
                </transition>
            </Tile>
        </Flyout>

        <Flyout
            screen-name="useraccount-opensourcelicenses"
            :active="licensesFlyoutActive"
            :headline="$t('legalInformation.licenses.headline')"
            size="large"
            @closed="closeFlyout('licenses')"
        >
            <Tile>
                <transition name="fade" mode="out-in">
                    <LicenseList />
                </transition>
            </Tile>
        </Flyout>

        <Flyout
            :active="imprintFlyoutActive"
            :headline="$t('legalInformation.imprint.headline')"
            size="small"
            @closed="closeFlyouts"
        >
            <Tile>
                <ImprintContent />
            </Tile>
        </Flyout>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContentApi from '@/services/Api/Content';

import ImprintContent from '@/components/ImprintContent';
import Flyout from '@/components/Layout/Flyout';
import Tile from '@/components/Layout/Tile';
import LoadingSpinner from '@/components/LoadingSpinner';
import LicenseList from '@/components/LegalInformation/LicenseList';

export default {
    name: 'LegalInformation',
    components: {
        ImprintContent,
        Flyout,
        Tile,
        LoadingSpinner,
        LicenseList,
    },
    props: {
        imprintFlyoutActive: {
            type: Boolean,
            default: false,
        },
        privacyPolicyFlyoutActive: {
            type: Boolean,
            default: false,
        },
        termsOfServiceFlyoutActive: {
            type: Boolean,
            default: false,
        },
        termsOfUseFlyoutActive: {
            type: Boolean,
            default: false,
        },
        licensesFlyoutActive: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            content: {},
        };
    },
    computed: {
        ...mapGetters('user', ['type']),
    },
    methods: {
        closeFlyouts() {
            this.$eventHub.$emit('page.imprint', false);
            this.$eventHub.$emit('page.privacyPolicy', false);
            this.$eventHub.$emit('page.termsOfService', false);
        },

        closeFlyout(name) {
            this.$eventHub.$emit(`page.${name}`, false);
            // clear large data from memory
            this.$set(this.content, name, '');
        },

        async loadPage(name) {
            let content;
            try {
                content = await ContentApi.getPage(name);
            } catch (err) {
                this.$logger().error(err);
                content = 'No content available';
            }

            this.$set(this.content, name, content);
        },
    },
};
</script>
<style lang="scss" scoped>
* {
    word-break: break-word;
}
</style>

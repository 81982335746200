<template>
    <div class="shipment-freight-information sfi">
        <Words bold block spaced-top>{{ $t('components.shipmentFreightInformation.freightType.title') }}</Words>

        <Words block small spaced-top spaced-bottom>
            {{ $t('components.shipmentFreightInformation.freightType.subtitle') }}
        </Words>
        <div
            class="shipment-freight-type-radio-group"
            :class="{ 'shipment-freight-type-radio-group--client': !info.types.includes('platform') }"
        >
            <RadioField
                v-model="freightInformation.type"
                class="radio-item"
                data-test="button-radio-material"
                option="material"
                boxed
                label-before-radio
                @input="handleTypeUpdate()"
            >
                <Words>{{ $t('components.shipmentFreightInformation.freightType.material') }}</Words>
            </RadioField>

            <RadioField
                v-model="freightInformation.type"
                data-test="button-radio-rc-material"
                class="radio-item"
                option="rc_material"
                boxed
                label-before-radio
                @input="handleTypeUpdate()"
            >
                <Words>{{ $t('components.projectPositionListBlock.freightType.rc_material') }}</Words>
            </RadioField>

            <RadioField
                v-model="freightInformation.type"
                data-test="button-radio-non-dangerous"
                class="radio-item"
                option="waste_non_dangerous"
                boxed
                label-before-radio
                @input="handleTypeUpdate()"
            >
                <Words>{{ $t('components.shipmentFreightInformation.freightType.wasteNonDangerous') }}</Words>
            </RadioField>

            <RadioField
                v-if="info.types.includes('platform')"
                v-model="freightInformation.type"
                data-test="button-radio-dangerous"
                class="radio-item"
                option="waste_dangerous"
                boxed
                label-before-radio
                @input="handleTypeUpdate()"
            >
                <Words>{{ $t('components.shipmentFreightInformation.freightType.wasteDangerous') }}</Words>
            </RadioField>
        </div>

        <!-- Waste disposal selection -->
        <div v-if="freightInformation.type === 'waste_dangerous' || freightInformation.type === 'waste_non_dangerous'">
            <Words bold block spaced-top class="shipment-freight-information-title">
                {{ $t('components.shipmentFreightInformation.freightType.disposalProcedure.title') }}
            </Words>
            <Words block spaced-top-tiny spaced-bottom-small>
                {{ $t('components.shipmentFreightInformation.freightType.disposalProcedure.description') }}
            </Words>
            <div class="mt-4 grid grid-rows-2 gap-4">
                <SfRadioButtonSimple
                    id="recycling"
                    v-model="freightInformation.disposalProcedure"
                    theme="light"
                    input-value="recycling"
                    :label="$t('components.shipmentFreightInformation.freightType.disposalProcedure.recycling')"
                    :description="
                        $t('components.shipmentFreightInformation.freightType.disposalProcedure.recyclingDescription')
                    "
                    name="disposalProcedure"
                    @input="handleChange()"
                />
                <SfRadioButtonSimple
                    id="disposal"
                    v-model="freightInformation.disposalProcedure"
                    theme="light"
                    input-value="disposal"
                    :label="$t('components.shipmentFreightInformation.freightType.disposalProcedure.disposal')"
                    :description="
                        $t('components.shipmentFreightInformation.freightType.disposalProcedure.disposalDescription')
                    "
                    name="disposalProcedure"
                    @input="handleChange()"
                />
            </div>
        </div>
        <!-- description input -->
        <Words bold block spaced-top class="shipment-freight-information-title">
            {{ $t('components.shipmentFreightInformation.title') }}
        </Words>
        <Words block spaced-top-tiny spaced-bottom-small>
            <Hint show-label spaceless transparent>
                {{ $t('components.shipmentFreightInformation.hint') }}
            </Hint>
        </Words>
        <TextField
            v-model="freightInformation.description"
            :label="$t('components.shipmentFreightInformation.description.label')"
            :has-error="hasErrors('freightInformation.description')"
            :error="getError('freightInformation.description')"
            data-test="freight-description"
            @update="partialValidation('freightInformation.description')"
            @input="handleChange()"
        />

        <!-- document or photo uploader -->
        <ImageDocumentUploader
            :images="freightInformation.images"
            :product-use-cases="{
                image: 'freight_image',
                document: 'freight_document',
            }"
            :documents="freightInformation.documents"
            :limit="5"
            class="sfi__image-document-uploader"
            @updateImages="handleImagesUpdate($event)"
            @updateDocuments="handleDocumentsUpdate($event)"
        >
            <template #default="{ count }">
                <Words bold block spaced-bottom-small>
                    {{ $tc('components.shipmentFreightInformation.imageDocumentUploader.label', count) }}
                </Words>
            </template>
        </ImageDocumentUploader>
    </div>
</template>

<script>
import validate from '@/services/validation/mixin';
import { isRequired, maxLength } from '@/services/validation/rules';

import Hint from '@/components/Typography/Hint';
import ImageDocumentUploader from '@/components/Form/ImageDocumentUploader';
import RadioField from '@/components/Form/RadioField';
import TextField from '@/components/Form/TextField.v2';
import Words from '@/components/Typography/Words';
import { mapGetters } from 'vuex';
import { SfRadioButtonSimple } from '@schuettflix/vue-components';
import i18n from '@/i18n';

export default {
    name: 'ShipmentFreightInformation',
    components: {
        Hint,
        ImageDocumentUploader,
        RadioField,
        TextField,
        Words,
        SfRadioButtonSimple,
    },
    mixins: [validate],
    props: {
        value: {
            type: Object,
            default: () => {
                // This is intentional
            },
        },
    },
    data() {
        return {
            freightInformation: {},
            validationRules: {
                'freightInformation.description': [isRequired(), maxLength(1000)],
                'freightInformation.type': [isRequired()],
                'freightInformation.disposalProcedure': [
                    value => {
                        if (
                            (this.freightInformation.type === 'waste_dangerous' ||
                                this.freightInformation.type === 'waste_non_dangerous') &&
                            !value
                        ) {
                            return i18n.t('validation.isRequired');
                        }
                        return true;
                    },
                ],
            },
        };
    },

    computed: {
        ...mapGetters('user', ['info']),
    },
    watch: {
        value: {
            immediate: true,
            handler(newValue) {
                this.freightInformation = {
                    type: null,
                    description: '',
                    images: [],
                    documents: [],
                    disposalProcedure: null,
                    ...newValue,
                };
            },
        },
    },
    mounted() {
        this.handleChange();
    },
    methods: {
        handleImagesUpdate(images) {
            this.freightInformation.images = images;
            this.handleChange();
        },

        handleDocumentsUpdate(documents) {
            this.freightInformation.documents = documents;
            this.handleChange();
        },

        handleTypeUpdate() {
            this.$set(this.freightInformation, 'disposalProcedure', null);
            this.handleChange();
        },

        handleChange() {
            this.$set(this.freightInformation, 'isValid', this.isValid(true));
            this.$emit('input', this.freightInformation);
        },
    },
};
</script>

<style lang="scss" scoped>
.sfi {
}

.sfi__image-document-uploader {
    margin-top: 30px;
}

.shipment-freight-information-title {
    margin-top: 30px;
}

.shipment-freight-type-radio-group {
    .radio-item {
        margin-bottom: 15px;
        box-shadow: $boxShadow-card;
    }
}
</style>

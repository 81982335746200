<template>
    <div class="range-wizard">
        <div class="range-wizard__price-track">
            <div :style="{ width: `calc(${percent}%)` }" data-test="price-range-track" class="range-wizard__spacer" />
            <div class="range-wizard__price">
                <Words bold large>{{ $n(value, currencyFormat) }}</Words>
            </div>
        </div>

        <Range
            class="range-wizard__range"
            data-test="range-price"
            :value="value"
            :min="min"
            :max="max"
            :step="step"
            :thumb-icon-path="thumbIconPath"
            @input="handleInput"
        />

        <div class="range-wizard__prices">
            <Words tiny>{{ $n(min, currencyFormat) }}</Words>
            <Words tiny>{{ $n(max, currencyFormat) }}</Words>
        </div>
    </div>
</template>

<script>
import Range from '@/components/Form/Range';
import Words from '@/components/Typography/Words';

import TruckIconPath from '@/assets/icons/rangeThumbs/truck.svg?external';
import WeightIconPath from '@/assets/icons/rangeThumbs/weight.svg?external';

export default {
    name: 'RangeWizard',
    components: { Words, Range },
    props: {
        value: {
            type: Number,
            default: null,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
        step: {
            type: Number,
            default: 10,
        },
        billingMethod: {
            type: String,
            required: true,
            validator: v => ['weight', 'fixedPrice'].includes(v),
        },
    },
    computed: {
        currencyFormat() {
            return Number.isInteger(this.step) ? 'currencyNoCents' : 'currency';
        },
        percent() {
            // Actual percent, but not what we need
            return ((this.value - this.min) * 100) / (this.max - this.min);
        },
        thumbIconPath() {
            return {
                weight: WeightIconPath,
                fixedPrice: TruckIconPath,
            }[this.billingMethod];
        },
    },
    methods: {
        handleInput(value) {
            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss" scoped>
.range-wizard {
}

.range-wizard__price-track {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
}

.range-wizard__prices {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.range-wizard__price {
    margin-left: -20px;
    margin-right: -20px;
}
</style>

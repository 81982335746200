import { accessToken, baseUrl } from '@/store/reactiveApiData';
import { setupApiSubscriptionVue, Path as SubscriptionPath, FetchApiError } from '@schuettflix/util-api-subscription';
import i18n from '@/i18n';
export const generateTranslatedErrorMessage = (error) => {
    const errors = {
        '401': 'abstractresource.errors.unauthorized',
        '403': 'abstractresource.errors.forbidden',
        '404': 'abstractresource.errors.notFound',
        '408': 'abstractresource.errors.requestTimeout',
        '500': 'abstractresource.errors.serverError',
    };
    if (error instanceof FetchApiError && i18n.te(errors[error.message]))
        return i18n.t(errors[error.message]);
    return i18n.t('abstractresource.errors.serverError');
};
const useApiSubscription = setupApiSubscriptionVue({
    baseUrl: baseUrl,
    accessToken: accessToken,
});
export { useApiSubscription, SubscriptionPath };

<template>
    <LayoutPage class="credit-note-list">
        <template #pageTitle>
            {{
                $t(
                    selectCreditNoteDocKey({
                        creditNote: `pages.creditNoteDoc.title.creditNote`,
                        selfBilling: `pages.creditNoteDoc.title.selfBilling`,
                    })
                )
            }}
        </template>

        <CreditNoteNavigation />

        <FilterBar :loading="isLoading" show-refresh transparent @refresh="refreshList()">
            <template #filter>
                <FilterBox
                    v-model="filter"
                    :default-filter="defaultFilter"
                    :forced-filter="forcedFilter"
                    :endpoint="dataEndpoint"
                    :button-label="$t('pages.transportHub.actions.filter')"
                    :ignore-in-count="['organizationType', 'organization', 'user', 'isAvailableForCreditNote']"
                    @save="refreshList()"
                >
                    <template #default="{ filter: filterFromSlot, updateFilter }">
                        <TransportFilterSet
                            :filter-scope="{ filter: filterFromSlot }"
                            :endpoint="dataEndpoint"
                            :whitelisted-fields="[
                                'status',
                                'type',
                                'carrierOrganization',
                                'transportNumber',
                                'orderNumber',
                                'page',
                                'perPage',
                                'startShippingWindow',
                                'endShippingWindow',
                                'licensePlate',
                                'startServiceDateTimestamp',
                                'endServiceDateTimestamp',
                                'minCreditNoteTotalCarrier',
                                'maxCreditNoteTotalCarrier',
                            ]"
                            @updateFilter="updateFilter"
                        />
                    </template>
                </FilterBox>
            </template>

            <template #sorting>
                <FilterBox
                    v-model="filter"
                    :default-filter="defaultFilter"
                    :forced-filter="forcedFilter"
                    :endpoint="dataEndpoint"
                    inline-mode
                    @update="refreshList()"
                >
                    <template #default="filterScope">
                        <SortingSelectBox
                            v-model="filterScope.filter.sortBy"
                            :direction="filterScope.filter.sortDirection"
                            :label="$t('pages.creditNoteDoc.actions.sortBy')"
                            primary
                            light
                            small
                            @direction-update="filterScope.filter.sortDirection = $event"
                        >
                            <option v-for="option in sortOptions" :key="option" :value="option">
                                {{ $t(getSortingTranslationKey(option)) }}
                            </option>
                        </SortingSelectBox>
                    </template>
                </FilterBox>
            </template>

            <template #actions>
                <SfButton
                    v-can:createCreditNote
                    :disabled="isCreationPending || selectedTransports.length === 0"
                    size="sm"
                    @click="createCreditNote"
                >
                    <template #leading-icon="iconProps">
                        <SfSysPlusIcon v-bind="iconProps" />
                    </template>
                    {{
                        $t(
                            selectCreditNoteDocKey({
                                creditNote: `pages.creditNoteDoc.actions.create.creditNote`,
                                selfBilling: `pages.creditNoteDoc.actions.create.selfBilling`,
                            })
                        )
                    }}
                </SfButton>
            </template>
        </FilterBar>

        <LoadingSpinner v-if="!transports" block dark />

        <transition name="fade">
            <GridTable
                v-if="transports && transports.count > 0"
                columns="50px 1fr 1fr 1fr 1fr 1fr 120px 120px 150px 150px 150px"
            >
                <template #head>
                    <GridTableHead v-stuckable="20">
                        <div />
                        <div>{{ $t('pages.creditNoteDoc.headlines.number') }}</div>
                        <div>{{ $t('pages.creditNoteDoc.headlines.paymentTerms') }}</div>
                        <div>{{ $t('pages.creditNoteDoc.headlines.transportStatus') }}</div>
                        <div>{{ $t('pages.creditNoteDoc.headlines.shippingWindow') }}</div>
                        <div>{{ $t('pages.creditNoteDoc.headlines.serviceRenderedTimestamp') }}</div>
                        <div>{{ $t('pages.creditNoteDoc.headlines.distance') }}</div>
                        <div>{{ $t('pages.creditNoteDoc.headlines.totalLoading') }}</div>
                        <div>{{ $t('pages.creditNoteDoc.headlines.licensePlate') }}</div>
                        <div class="grid-table__cell--right">
                            {{
                                $t('pages.creditNoteDoc.headlines.unitPriceWithCurrency', {
                                    currency: getCurrencySign(),
                                })
                            }}
                        </div>
                        <div class="grid-table__cell--right">
                            {{ $t('pages.creditNoteDoc.headlines.totalNet') }}
                        </div>
                    </GridTableHead>
                </template>

                <template v-for="(transport, i) in transports.items">
                    <GridTableLabel
                        v-if="showSectionHeading(transport.carrier.id, i)"
                        :key="`${transport.id}-headline`"
                        v-stuckable="10"
                        class="flex flex-col"
                    >
                        <div class="flex w-full items-center justify-between">
                            <div class="flex items-center">
                                <div class="font-copy-md-strong">{{ transport.carrier.name }}</div>
                                <div
                                    v-if="
                                        selectedOrganization &&
                                        selectedOrganization.id === transport.carrier.id &&
                                        selectedTransports.length
                                    "
                                    class="font-copy-sm pl-4 text-subdued"
                                >
                                    <i18n path="pages.creditNoteDoc.selectedOrganizationTransportsLabel" tag="span">
                                        <strong place="count">{{ selectedTransports.length }}</strong>
                                    </i18n>
                                </div>
                            </div>

                            <div class="flex items-center">
                                <div
                                    v-for="{ paymentTerm } in getPaymentTermsOfOrganizationTransports(
                                        transport.carrier.id
                                    )"
                                    :key="paymentTerm.externalId"
                                    class="ml-6 flex items-center"
                                >
                                    <Checkbox
                                        :value="
                                            isOrganizationSectionSelected(transport.carrier.id, paymentTerm.externalId)
                                        "
                                        :option="true"
                                        @input="
                                            triggerOrganizationSection(transport.carrier.id, paymentTerm.externalId)
                                        "
                                    />
                                    <div class="font-copy-md-strong pl-4">{{ paymentTerm.description }}</div>
                                </div>
                            </div>
                        </div>
                    </GridTableLabel>

                    <GridTableRow
                        :key="transport.id"
                        spaced
                        @click="
                            addTransportSelection(transport.carrier, transport.id, transport.paymentTerm.externalId)
                        "
                    >
                        <div class="grid-table__cell">
                            <Checkbox
                                v-model="selectedTransports"
                                :option="transport.id"
                                @input="
                                    addedTransportSelection(
                                        transport.carrier,
                                        transport.id,
                                        transport.paymentTerm.externalId
                                    )
                                "
                            />
                        </div>

                        <div class="grid-table__cell">
                            <Words block>{{ transport.number }}</Words>
                            <Words block muted small>{{ transport.orderNumber }}</Words>
                        </div>

                        <div class="grid-table__cell font-copy-sm-strong">
                            {{ transport.paymentTerm.description }}
                        </div>

                        <div class="grid-table__cell">
                            <Words block small>
                                {{ $t(`pages.creditNoteDoc.transport.type.${transport.type}`) }}
                            </Words>
                            <Words
                                :green="transport.status === 'delivered'"
                                :yellow="transport.status === 'confirmationPending'"
                                :red="transport.status === 'failed' || transport.status === 'cancelled'"
                                block
                                small
                            >
                                {{ statusText(transport.status) }}
                            </Words>
                        </div>

                        <div class="grid-table__cell">
                            <template v-if="transport.type === 'shipment'">
                                <template v-if="transport.planningBase">
                                    <Words block muted small>
                                        {{ $t(`pages.creditNoteDoc.shipment.plannedFix.${transport.planningBase}`) }}
                                    </Words>
                                    <Words>
                                        {{ $d(ensureJSTimestamp(transport.shippingDate), 'short') }}
                                        <Words block muted small>
                                            {{
                                                $t('timeSuffix', {
                                                    time: $d(ensureJSTimestamp(transport.shippingDate), 'time'),
                                                })
                                            }}
                                        </Words>
                                    </Words>
                                </template>
                                <template v-else>
                                    <Words block muted small>
                                        {{ $t('pages.creditNoteDoc.shipment.loadingWindow') }}
                                    </Words>
                                    <Words block spaced-bottom-small>
                                        {{ $d(ensureJSTimestamp(transport.loadingDateFrom), 'short') }}
                                        <template
                                            v-if="
                                                transport.loadingDateTo &&
                                                transport.loadingDateTo !== transport.loadingDateFrom
                                            "
                                        >
                                            - {{ $d(ensureJSTimestamp(transport.loadingDateTo), 'short') }}
                                        </template>
                                        &nbsp; &nbsp;
                                        {{
                                            $t('timeSuffix', {
                                                time: `${transport.loadingTimeFrom} - ${transport.loadingTimeTo}`,
                                            })
                                        }}
                                    </Words>
                                    <Words block muted small>
                                        {{ $t('pages.creditNoteDoc.shipment.unloadingWindow') }}
                                    </Words>
                                    <Words block spaced-bottom-small>
                                        {{ $d(ensureJSTimestamp(transport.unloadingDateFrom), 'short') }}
                                        <template
                                            v-if="
                                                transport.unloadingDateTo &&
                                                transport.unloadingDateTo !== transport.unloadingDateFrom
                                            "
                                        >
                                            - {{ $d(ensureJSTimestamp(transport.loadingDateTo), 'short') }}
                                        </template>
                                        &nbsp; &nbsp;
                                        {{
                                            $t('timeSuffix', {
                                                time: `${transport.unloadingTimeFrom} - ${transport.unloadingTimeTo}`,
                                            })
                                        }}
                                    </Words>
                                </template>
                            </template>
                            <template v-else>
                                <Words block muted small>
                                    {{ $t(`pages.creditNoteDoc.shippingMethods.${transport.shippingMethod}`) }}
                                </Words>
                                <Words v-if="transport.shippingMethod === 'flix'">
                                    {{ $d(transport.shippingDate * 1000, 'short') }}
                                    <Words block muted small>
                                        {{ $t('timeSuffix', { time: $d(transport.shippingDate * 1000, 'time') }) }}
                                    </Words>
                                </Words>
                                <Words v-else-if="transport.shippingMethod === 'default'">
                                    {{ $d(transport.shippingWindowStart * 1000, 'short') }} -
                                    {{ $d(transport.shippingWindowEnd * 1000, 'short') }}
                                </Words>
                                <Words v-else>–</Words>
                            </template>
                        </div>

                        <div class="grid-table__cell">
                            <Words v-if="transport.confirmUnloadingTimestamp" middle>
                                {{ $d(transport.confirmUnloadingTimestamp * 1000, 'short') }}
                                <Words block muted small>
                                    {{
                                        $t('timeSuffix', {
                                            time: $d(transport.confirmUnloadingTimestamp * 1000, 'time'),
                                        })
                                    }}
                                </Words>
                            </Words>
                            <Words v-else-if="transport.confirmationPendingTimestamp" middle>
                                {{ $d(transport.confirmationPendingTimestamp * 1000, 'short') }}
                                <Words block muted small>
                                    {{
                                        $t('timeSuffix', {
                                            time: $d(transport.confirmationPendingTimestamp * 1000, 'time'),
                                        })
                                    }}
                                </Words>
                            </Words>
                            <Words v-else-if="transport.deliveredTimestamp" middle>
                                {{ $d(transport.deliveredTimestamp * 1000, 'short') }}
                                <Words block muted small>
                                    {{ $t('timeSuffix', { time: $d(transport.deliveredTimestamp * 1000, 'time') }) }}
                                </Words>
                            </Words>
                            <Words v-else>–</Words>
                        </div>

                        <div class="grid-table__cell">
                            <Words block>
                                {{ $n(transport.totalDistance / 1000, 'distance') }} {{ $t('units.kilometer') }}
                            </Words>
                            <Words block muted small>
                                {{ getFormattedDuration(transport.totalDuration) }}
                            </Words>
                        </div>

                        <div class="grid-table__cell">
                            <Words v-if="['shipment', 'disposal'].includes(transport.type)" block>
                                {{
                                    transport.billingMethod === 'weight'
                                        ? `${$n(transportLoading(transport) / 1000)} ${$t('units.ton')}`
                                        : transport.vehicleName
                                }}
                            </Words>
                            <Words v-else-if="transport.totalLoading" block>
                                {{ $n(transport.totalLoading / 1000, 'qty') }} t
                            </Words>
                            <Words v-else>–</Words>
                        </div>

                        <div class="grid-table__cell">
                            <Words v-if="transport.vehicle" bold unbreakable>
                                {{ transport.vehicle.licensePlate }}
                            </Words>
                            <Words v-else>–</Words>
                        </div>

                        <div class="grid-table__cell grid-table__cell--right">
                            <Words v-if="transport.type === 'shipment' && transport.billingMethod === 'fixedPrice'">
                                –
                            </Words>
                            <Words v-else block>
                                {{ $n(transportUnitPrice(transport), 'currency') }}
                            </Words>
                        </div>

                        <div class="grid-table__cell grid-table__cell--right">
                            <Words middle>
                                {{ $n(transport.creditNoteTotalCarrier, 'currency') }}
                            </Words>
                        </div>

                        <GridTableActionCell>
                            <SfButton type="secondary" size="sm" @click="viewOrder(transport.orderId)">
                                {{ $t('pages.creditNoteDoc.actions.viewOrder') }}
                            </SfButton>
                            <SfButton size="sm" @click="viewTransport(transport.id)">
                                {{ $t('pages.creditNoteDoc.actions.viewTransport') }}
                            </SfButton>
                        </GridTableActionCell>
                    </GridTableRow>
                </template>
                <MoreResultsButton
                    v-if="transports.count - transports.items.length > 0"
                    :result="transports"
                    fade-out
                    class="transport-hub__more-results-button"
                />
            </GridTable>

            <Hint v-if="transports && transports.count === 0" center>
                {{ $t('pages.creditNoteDoc.noResults') }}
            </Hint>
        </transition>

        <template #subpages>
            <Flyout route="credit-note-carrier__order-view" size="small" no-header />
            <Flyout route="credit-note-carrier__transport-view" size="small" no-header />
            <Flyout
                route="credit-note-carrier__credit-note-carrier-view"
                size="medium"
                no-header
                @closed="refreshList()"
            />
        </template>
    </LayoutPage>
</template>

<script>
import _intersection from 'lodash/intersection';
import TransportListApi from '@/services/Api/TransportList';
import CreditNoteApi from '@/services/Api/CreditNote';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import { statusText } from '@/services/utils/transport';
import { ensureJSTimestamp, getFormattedDuration } from '@/services/utils/date';
import { revertLocalizedValue } from '@/services/utils/localization';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
import routeContext from '@/plugins/mixins/routeContext';
import { CONTEXT_PLATFORM } from '@/constants/context';
import { getCurrencySign } from '@/services/utils/currency';

import { useSortingOptions } from './useSortingOptions';
import { getPaymentTermsByOrderId } from './getPaymentTermsByOderId';

import Checkbox from '@/components/Form/Checkbox';
import CreditNoteNavigation from '@/pages/CreditNote/components/CreditNoteNavigation';
import FilterBar from '@/components/Filter/FilterBar';
import FilterBox from '@/components/Filter/FilterBox';
import Flyout from '@/components/Layout/Flyout';
import GridTable from '@/components/Table/GridTable';
import GridTableActionCell from '@/components/Table/GridTableActionCell';
import GridTableLabel from '@/components/Table/GridTableLabel';
import GridTableRow from '@/components/Table/GridTableRow';
import GridTableHead from '@/components/Table/GridTableHead';
import Hint from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import MoreResultsButton from '@/components/Filter/MoreResultsButton';
import SortingSelectBox from '@/components/Form/SortingSelectBox';
import Words from '@/components/Typography/Words';
import TransportFilterSet from '@/pages/Transport/components/TransportFilterSet';

import Toaster from '@/services/Toaster';
import { useFeatureFlag } from '@/services/FeatureFlags/useFeatureFlags';

import { SfButton, SfSysPlusIcon } from '@schuettflix/vue-components';

const availableStatus = ['confirmation_pending', 'delivered', 'failed'];

const transportListApi = new TransportListApi(CONTEXT_PLATFORM);

export default {
    name: 'CreditNoteListCarrierPage',
    components: {
        Checkbox,
        CreditNoteNavigation,
        FilterBar,
        FilterBox,
        Flyout,
        GridTable,
        GridTableActionCell,
        GridTableLabel,
        GridTableRow,
        GridTableHead,
        Hint,
        LayoutPage,
        LoadingSpinner,
        MoreResultsButton,
        SortingSelectBox,
        Words,
        TransportFilterSet,

        SfButton,
        SfSysPlusIcon,
    },
    mixins: [persistentFiltersMixin, routeContext],
    setup() {
        const { getSortingTranslationKey } = useSortingOptions();
        const { selectCreditNoteDocKey } = useLegalTerms();
        const { isEnabled: isDisposalFeatureEnabled } = useFeatureFlag('disposal_project_order');
        return {
            getSortingTranslationKey,
            selectCreditNoteDocKey,
            isDisposalFeatureEnabled,
        };
    },
    data() {
        return {
            isLoading: false,
            isCreationPending: false,
            selectedOrganization: null,
            selectedPaymentTermId: null,
            selectedTransports: [],
            pendingTimeout: null,

            dataEndpoint: transportListApi,
            transports: null,
            filter: this.assembleFilter('transport', {
                page: 1,
                perPage: 25,
            }),
            defaultFilter: {
                page: 1,
                perPage: 25,
            },
        };
    },
    computed: {
        sortOptions() {
            return _intersection(transportListApi.supportedSorts, [
                'urgency',
                'created',
                'transportNumber',
                'serviceDateTimestamp',
            ]);
        },
        forcedFilter() {
            return {
                status: availableStatus,
                seedSortBy: 'carrierOrganizationName',
                isAvailableForCreditNote: 'carrier',
                type: ['delivery', 'shipment', ...(this.isDisposalFeatureEnabled ? ['disposal'] : [])],
            };
        },
    },
    created() {
        this.refreshList(true);
    },
    methods: {
        revertLocalizedValue,
        ensureJSTimestamp,
        getFormattedDuration,
        getCurrencySign,
        getPaymentTermsOfOrganizationTransports(orgId) {
            return this.transports.items.reduce((accumulator, current) => {
                if (current.carrier.id !== orgId || current.paymentTerm === null) {
                    return accumulator;
                }
                if (!accumulator.find(t => t.paymentTerm.externalId === current.paymentTerm.externalId)) {
                    accumulator.push(current);
                }
                return accumulator;
            }, []);
        },

        reset() {
            this.selectedTransports = [];
            this.selectedPaymentTermId = null;
            this.selectedOrganization = null;
        },

        async refreshList(isInitial = false) {
            this.isLoading = true;

            // persist filter
            this.persistFilter('transport', this.filter, this.defaultFilter);

            // cancel previous request
            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.cancelSource = transportListApi.createCancelTokenSource();

            try {
                const result = await transportListApi.filter(
                    this.filter,
                    null,
                    null,
                    this.cancelSource,
                    this.forcedFilter,
                    false
                );
                await result.transformAsync(async transport => {
                    return {
                        ...transport,
                        paymentTerm: (await getPaymentTermsByOrderId(transport.orderId, transport.id))
                            .carrierPaymentTerm,
                    };
                }),
                    (this.transports = result);

                if (isInitial === true) {
                    this.filter = {
                        ...this.filter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }

            this.isLoading = false;
        },

        async createCreditNote() {
            if (this.selectedOrganization === null || this.selectedTransports.length === 0) {
                Toaster.error(
                    this.$t(
                        this.selectCreditNoteDocKey({
                            creditNote: `pages.creditNoteDoc.messages.missingSelection.creditNote`,
                            selfBilling: `pages.creditNoteDoc.messages.missingSelection.selfBilling`,
                        })
                    )
                );
                return;
            }

            this.isCreationPending = true;
            this.$router
                .push({
                    name: this.$root.findRouteName('credit-note-carrier-view'),
                    params: {
                        creditNoteId: 'created',
                        organizationName: this.selectedOrganization.name,
                    },
                    query: this.$route.query,
                })
                .catch(() => {});

            try {
                const creditNote = await CreditNoteApi.createForOrganization(
                    this.selectedOrganization.id,
                    this.selectedTransports,
                    'carrier'
                );

                this.$router
                    .replace({
                        name: this.$root.findRouteName('credit-note-carrier-view'),
                        params: { creditNoteId: creditNote.id },
                        query: this.$route.query,
                    })
                    .catch(() => {});
                this.reset();
                this.refreshList();
            } catch (err) {
                this.$logger().error(err);
                this.$root.routeBack();
                Toaster.error(this.$t(err.message));
            }

            this.isCreationPending = false;
        },

        addedTransportSelection(organization, transportId, paymentTermId) {
            const selectedOrganizationId = (this.selectedOrganization && this.selectedOrganization.id) || null;
            if (
                selectedOrganizationId !== organization.id ||
                !this.selectedPaymentTermId ||
                this.selectedPaymentTermId !== paymentTermId
            ) {
                this.selectedTransports = [transportId];
            }
            this.selectedPaymentTermId = paymentTermId;
            this.selectedOrganization = organization;

            if (this.selectedTransports.length === 0) {
                this.selectedPaymentTermId = null;
                this.selectedOrganization = null;
            }
        },

        addTransportSelection(organization, transportId, paymentTermId) {
            const selectedOrganizationId = (this.selectedOrganization && this.selectedOrganization.id) || null;
            if (
                selectedOrganizationId !== organization.id ||
                !this.selectedPaymentTermId ||
                this.selectedPaymentTermId !== paymentTermId
            ) {
                this.selectedTransports = [transportId];
            } else {
                if (this.selectedTransports.includes(transportId)) {
                    this.selectedTransports.splice(this.selectedTransports.indexOf(transportId), 1);
                } else {
                    this.selectedTransports.push(transportId);
                }
            }
            this.addedTransportSelection(organization, transportId, paymentTermId);
        },

        showSectionHeading(organizationId, currentIndex) {
            if (currentIndex === 0) {
                return true;
            }

            return parseInt(this.transports.items[currentIndex - 1].carrier.id) !== parseInt(organizationId);
        },

        viewTransport(transportId) {
            this.$router
                .push({
                    name: `${this.$route.name}__transport-view`,
                    params: { transportId },
                    query: this.$route.query,
                })
                .catch(() => {});
        },

        viewOrder(orderId) {
            this.$router
                .push({
                    name: `${this.$route.name}__order-view`,
                    params: { orderId },
                    query: this.$route.query,
                })
                .catch(() => {});
        },

        isOrganizationSectionSelected(organizationId, paymentTermId) {
            let transportIds = this.transports.items.filter(transport => transport.carrier.id === organizationId);

            // Filter also optionally against the payment term
            if (paymentTermId) {
                transportIds = transportIds.filter(transport => transport.paymentTerm.externalId === paymentTermId);
            }

            transportIds = transportIds.map(transport =>
                transport.carrier.id === organizationId ? transport.id : null
            );

            return _intersection(this.selectedTransports, transportIds).length === transportIds.length;
        },

        transportLoading(transport) {
            if (transport.billingMethod === 'weight') {
                return transport.weighingBillingBasis === 'loading'
                    ? transport.loadingWeight
                    : transport.unloadingWeight;
            }

            return transport.qty;
        },

        transportUnitPrice(transport) {
            // Shipment
            if (transport.billingMethod === 'weight') {
                return transport.transportPurchaseUnitPrice * 1000;
            }
            return transport.transportPurchasePrice / (transport.qty / 1000);
        },

        triggerOrganizationSection(organizationId, paymentTermId) {
            if (this.isOrganizationSectionSelected(organizationId, paymentTermId)) {
                this.selectedOrganization = null;
                this.selectedPaymentTermId = null;
                this.selectedTransports = [];
                return;
            }
            let organization = null;

            let transportIds = this.transports.items.filter(transport => transport.carrier.id === organizationId);

            // Filter also optionally against the payment term
            if (paymentTermId) {
                transportIds = transportIds.filter(transport => transport.paymentTerm.externalId === paymentTermId);
            }

            transportIds = transportIds.map(transport => {
                organization = transport.carrier;
                return transport.carrier.id === organizationId ? transport.id : null;
            });

            this.selectedOrganization = organization;
            this.selectedPaymentTermId = paymentTermId;
            this.selectedTransports = transportIds;
            return;
        },

        statusText(status) {
            return statusText(this.routeContext, status);
        },
    },
};
</script>

import { useIconSize } from '@/services/utils/useIconSize';
export function SfPdfIcon({ size, ...svgProps }) {
    const iconSize = useIconSize(size);
    return (<svg width={iconSize} height={iconSize} viewBox="0 0 24 24" aria-hidden="true" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path d="M19 9.01314V22H5V2H11.999" className="stroke-icon" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M19.9924 5.99847H14.9948V1M15.2871 1.71484 19.2871 5.71271" className="stroke-icon" strokeWidth="2" strokeMiterlimit="10"/>
            <g clipPath="url(#PdfIcon__a)">
                <mask id="PdfIcon__b" fill="#fff">
                    <path d="M16.5005 16.0039V16.5905H14.951V17.2362H16.4674V17.8228H14.951V18.996H14.2488V16.0039H16.5005Z"/>
                </mask>
                <path d="M16.5005 16.0039V16.5905H14.951V17.2362H16.4674V17.8228H14.951V18.996H14.2488V16.0039H16.5005Z" className="stroke-icon" strokeWidth="2" mask="url(#PdfIcon__b)"/>
                <mask id="PdfIcon__c" fill="#fff">
                    <path d="M13.0496 16.3912C12.687 16.1102 12.226 15.9704 11.7594 16H10.6478V18.9922H11.7235C12.2082 19.0296 12.6889 18.8836 13.0612 18.5858C13.2105 18.4406 13.3264 18.2674 13.4018 18.0773C13.4772 17.8872 13.5103 17.6843 13.4991 17.4814C13.5109 17.2796 13.4769 17.0777 13.3994 16.8896C13.3218 16.7014 13.2025 16.5314 13.0496 16.3912V16.3912ZM12.5353 18.1707C12.3293 18.336 12.064 18.4189 11.7951 18.4019H11.3501V16.5827H11.7414C12.0285 16.5603 12.3134 16.6456 12.5353 16.8204C12.625 16.9104 12.6941 17.0171 12.7384 17.1338C12.7828 17.2504 12.8013 17.3745 12.7929 17.4983C12.8018 17.6213 12.7835 17.7447 12.7392 17.8606C12.6948 17.9764 12.6254 18.0821 12.5353 18.1707V18.1707Z"/>
                </mask>
                <path d="M13.0496 16.3912C12.687 16.1102 12.226 15.9704 11.7594 16H10.6478V18.9922H11.7235C12.2082 19.0296 12.6889 18.8836 13.0612 18.5858C13.2105 18.4406 13.3264 18.2674 13.4018 18.0773C13.4772 17.8872 13.5103 17.6843 13.4991 17.4814C13.5109 17.2796 13.4769 17.0777 13.3994 16.8896C13.3218 16.7014 13.2025 16.5314 13.0496 16.3912V16.3912ZM12.5353 18.1707C12.3293 18.336 12.064 18.4189 11.7951 18.4019H11.3501V16.5827H11.7414C12.0285 16.5603 12.3134 16.6456 12.5353 16.8204C12.625 16.9104 12.6941 17.0171 12.7384 17.1338C12.7828 17.2504 12.8013 17.3745 12.7929 17.4983C12.8018 17.6213 12.7835 17.7447 12.7392 17.8606C12.6948 17.9764 12.6254 18.0821 12.5353 18.1707V18.1707Z" className="stroke-icon" strokeWidth="2" mask="url(#PdfIcon__c)"/>
                <mask id="PdfIcon__d" fill="#fff">
                    <path d="M9.66488 16.2738C9.35633 16.071 8.98341 15.9765 8.6098 16.0064H7.49997V18.9996H8.20054V18.164H8.61878C8.98472 18.1906 9.34955 18.1006 9.65583 17.9081C9.77476 17.8072 9.86734 17.6814 9.92622 17.5405C9.9851 17.3997 10.0087 17.2477 9.99501 17.0966C10.0078 16.9447 9.98474 16.792 9.92763 16.6497C9.87052 16.5073 9.78075 16.3789 9.66488 16.2738V16.2738ZM9.14985 17.452C9.0842 17.504 9.00811 17.5428 8.92621 17.5659C8.84432 17.589 8.75833 17.596 8.67349 17.5865H8.20058V16.5805H8.60169C8.77907 16.5679 8.95638 16.6056 9.11129 16.6888C9.169 16.7323 9.21434 16.7889 9.24321 16.8534C9.27207 16.9179 9.28353 16.9882 9.27655 17.058C9.28705 17.1997 9.2419 17.3401 9.14985 17.452V17.452Z"/>
                </mask>
                <path d="M9.66488 16.2738C9.35633 16.071 8.98341 15.9765 8.6098 16.0064H7.49997V18.9996H8.20054V18.164H8.61878C8.98472 18.1906 9.34955 18.1006 9.65583 17.9081C9.77476 17.8072 9.86734 17.6814 9.92622 17.5405C9.9851 17.3997 10.0087 17.2477 9.99501 17.0966C10.0078 16.9447 9.98474 16.792 9.92763 16.6497C9.87052 16.5073 9.78075 16.3789 9.66488 16.2738V16.2738ZM9.14985 17.452C9.0842 17.504 9.00811 17.5428 8.92621 17.5659C8.84432 17.589 8.75833 17.596 8.67349 17.5865H8.20058V16.5805H8.60169C8.77907 16.5679 8.95638 16.6056 9.11129 16.6888C9.169 16.7323 9.21434 16.7889 9.24321 16.8534C9.27207 16.9179 9.28353 16.9882 9.27655 17.058C9.28705 17.1997 9.2419 17.3401 9.14985 17.452V17.452Z" className="stroke-icon" strokeWidth="2" mask="url(#PdfIcon__d)"/>
            </g>
            <defs>
                <clipPath id="PdfIcon__a">
                    <path fill="#fff" transform="translate(7.5 16)" d="M0 0H9V3H0z"/>
                </clipPath>
            </defs>
        </svg>);
}

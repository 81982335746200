var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkbox-input",class:{
        'checkbox-input--active': _vm.isActive,
        'checkbox-input--disabled': _vm.disabled,
        'checkbox-input--small': _vm.small,
        'checkbox-input--black': _vm.black,
        'checkbox-input--on-dark-surface': _vm.onDarkSurface,
    },on:{"click":function($event){$event.stopPropagation();}}},[_c('input',{ref:"checkbox",staticClass:"checkbox-input__input",attrs:{"id":_vm.eid,"disabled":_vm.disabled,"name":_vm.name,"type":"checkbox"},domProps:{"checked":_vm.isActive,"value":_vm.option},on:{"change":_vm.handleChange}}),_c('label',{staticClass:"checkbox-input__label block",class:{
            'checkbox-input__label--black': _vm.black && _vm.isActive,
            'checkbox-input__label--on-dark-surface': _vm.onDarkSurface && _vm.isActive,
        },attrs:{"for":_vm.eid}},[(_vm.isActive)?_c('CheckMarkIcon',{staticClass:"checkbox-input__orb",class:{
                'checkbox-input__orb--black': _vm.black,
                'checkbox-input__orb--on-dark-surface': _vm.onDarkSurface,
            }}):(_vm.isPartiallyActive)?_c('IntermediateIcon',{staticClass:"checkbox-input__orb",class:{
                'checkbox-input__orb--on-dark-surface--partly': _vm.onDarkSurface,
            }}):_vm._e()],1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
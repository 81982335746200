/**
 * SupplierInfo aka FactoryInfo
 */
import SupplierFactoryInfoView from '@/models/SupplierFactoryInfoView';

export default class SupplierInfoView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     */
    static create(data) {
        const info = new SupplierInfoView();

        info.supplier = SupplierFactoryInfoView.create(data.supplier);
        info.distance = data.distance;
        info.duration = data.duration;
        info.closingTimestamp = data.closingTimestamp;
        info.openingTimestamp = data.openingTimestamp;
        info.rating = data.rating;

        return info;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._supplier = null;
        this._distance = null;
        this._duration = null;
        this._closingTimestamp = null;
        this._openingTimestamp = null;
        this._rating = null;
    }

    /**
     * Get SupplierFactoryInfo
     * @returns {SupplierFactoryInfoView}
     */
    get supplier() {
        return this._supplier;
    }

    /**
     * Set SupplierFactoryInfo
     * @param {SupplierFactoryInfoView} value
     */
    set supplier(value) {
        this._supplier = value ?? null;
    }

    /**
     * Get distance
     * @returns {number}
     */
    get distance() {
        return this._distance;
    }

    /**
     * Set distance
     * @param {number} value
     */
    set distance(value) {
        this._distance = value ?? null;
    }

    /**
     * Get duration
     * @returns {number}
     */
    get duration() {
        return this._duration;
    }

    /**
     * Set duration
     * @param {number} value
     */
    set duration(value) {
        this._duration = value ?? null;
    }

    /**
     * Get closing timestamp
     * @returns {number}
     */
    get closingTimestamp() {
        return this._closingTimestamp;
    }

    /**
     * Set closing timestamp
     * @param value
     */
    set closingTimestamp(value) {
        this._closingTimestamp = value ?? null;
    }

    /**
     * Get opening timestamp
     * @returns {number}
     */
    get openingTimestamp() {
        return this._openingTimestamp;
    }

    /**
     * Set opening timestamp
     * @param value
     */
    set openingTimestamp(value) {
        this._openingTimestamp = value ?? null;
    }

    /**
     * Get rating
     * @returns {object}
     */
    get rating() {
        return this._rating;
    }

    /**
     * Set rating
     * @param {object|null} value
     */
    set rating(value) {
        this._rating = value ?? null;
    }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['pop-up', `pop-up--${_vm.position}`, `pop-up--variant-${_vm.variant}`, `pop-up--arrow-size-${_vm.arrowSize}`],on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave}},[_vm._t("default"),(_vm.popupActive)?_c('div',{staticClass:"pop-up__content",class:`pop-up__content--${_vm.position}`},[(['right', 'bottom'].includes(_vm.position))?_c('div',{class:[
                'pop-up__arrow',
                {
                    'pop-up__arrow--top': _vm.position === 'bottom',
                    'pop-up__arrow--left': _vm.position === 'right',
                },
            ]}):_vm._e(),_c('div',{class:['pop-up__content-box', `pop-up__content-box--${_vm.position}`]},[_vm._t("content")],2),(['left', 'top'].includes(_vm.position))?_c('div',{class:[
                'pop-up__arrow',
                {
                    'pop-up__arrow--right': _vm.position === 'left',
                    'pop-up__arrow--bottom': _vm.position === 'top',
                },
            ]}):_vm._e()]):_vm._e()],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
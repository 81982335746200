var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LayoutPage',{staticClass:"checkout-constructionSite-selection",attrs:{"is-flyout":"","screen-name":_vm.screenName}},[_c('FlyoutHeader',{attrs:{"slot":"flyoutHeader","headline":!_vm.isDisposal
                ? _vm.$t('pages.checkout.constructionProjectLocationSelection.title')
                : _vm.$t('pages.checkout.constructionProjectLocationSelection.disposalTitle')},slot:"flyoutHeader"}),(
            _vm.constructionSites &&
            _vm.constructionSites.count === 0 &&
            !_vm.isPlatformAdministrator &&
            !_vm.$can('createConstructionProject')
        )?_c('ReactBridge',{staticClass:"h-full",attrs:{"react-component":_vm.CreateLocationMissingPermission}}):_c('div',{ref:"constructionSiteSelectionRef",staticClass:"checkout-constructionSite-selection__content container-off-canvas-sm mb-6",class:{ 'mt-6': !_vm.$can('requestPhoneOrder') },attrs:{"data-test":"construction-site-selection-page"}},[_c('portal-target',{attrs:{"name":"checkoutGuideConstructionSiteSelectionMessage"}}),(_vm.isCustom)?_c('p',{staticClass:"font-copy-md mb-6"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.constructionProjectLocationSelection.custom.description'))+" ")]):_vm._e(),_c('FilterBox',{ref:"constructionSiteSelectionFilter",staticClass:"checkout-constructionSite-selection__filter",class:{
                'checkout-constructionSite-selection__filter-sticky': _vm.showHeaderFilter,
            },attrs:{"data-test":"construction-site-filter","default-filter":_vm.filter,"endpoint":_vm.dataEndpoint,"inline-mode":""},on:{"update":function($event){return _vm.refreshList()}},scopedSlots:_vm._u([{key:"default",fn:function(filterScope){return [_c('TextField',{attrs:{"label":!_vm.isDisposal
                            ? _vm.$t('pages.checkout.constructionProjectLocationSelection.search')
                            : _vm.$t('pages.checkout.constructionProjectLocationSelection.disposalSearch')},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SearchIcon')]},proxy:true}],null,true),model:{value:(filterScope.filter.search),callback:function ($$v) {_vm.$set(filterScope.filter, "search", $$v)},expression:"filterScope.filter.search"}})]}}]),model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),(_vm.$can('createConstructionSite') || _vm.isPlatformAdministrator)?_c('BaseButton',{staticClass:"mb-4",attrs:{"data-test":"add-new-unloading-point-button"},on:{"click":_vm.openCreateFlyout},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('PlusIcon',{staticClass:"icon--inline"})]},proxy:true}],null,false,4175973606)},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.constructionProjectLocationSelection.createLocation.button'))+" ")]):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.constructionSites && _vm.isLoading)?_c('LoadingCard'):(_vm.constructionSites && _vm.constructionSites.count > 0)?_c('List',{staticClass:"bg-transparent"},[_vm._l((_vm.constructionSites.items),function(constructionSite,index){return _c('button',{key:index,staticClass:"card card--hovered card--focussed mb-4 w-full text-left",class:[
                        {
                            'card--selected !cursor-pointer': _vm.selectedConstructionSite === constructionSite.id,
                        },
                    ],attrs:{"data-test":`project-position-waste-details-factory-${index}`},on:{"click":function($event){return _vm.selectConstructionSite(constructionSite)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.selectConstructionSite(constructionSite)}}},[_c('ConstructionSiteItem',{attrs:{"item":constructionSite,"data-test":"construction-site-selection-item"}})],1)}),(_vm.constructionSites.count - _vm.constructionSites.items.length > 0)?_c('MoreResultsButton',{attrs:{"result":_vm.constructionSites,"fade-out":""}}):_vm._e()],2):_vm._e(),(_vm.constructionSites && _vm.constructionSites.count === 0 && !_vm.isPlatformAdministrator)?_c('ReactBridge',{staticClass:"h-full",attrs:{"react-component":_vm.LocationEmptyState,"props":{
                    constructionProjectId: _vm.constructionProjectId,
                    onCreateLocationClick: _vm.onCreateLocationFromDraft,
                }}}):_vm._e()],1),(!_vm.$can('createConstructionSite') && !_vm.isCustom)?_c('p',{staticClass:"font-copy-sm text-subdued",domProps:{"innerHTML":_vm._s(_vm.$t('pages.checkout.constructionProjectLocationSelection.notice'))}}):_vm._e(),_c('Flyout',{attrs:{"active":_vm.isCreateOpen,"headline":_vm.$t('pages.checkout.constructionProjectLocationSelection.createLocationFlyout.title'),"screen-name":"client-location-create","no-spacer":"","size":"small"},on:{"closed":_vm.closeCreateFlyout}},[_c('ReactBridge',{staticClass:"h-full",attrs:{"react-component":_vm.CreateLocation,"props":{
                    constructionProjectId: _vm.constructionProjectId,
                    onCreate: _vm.onLocationCreate,
                    locationDraft: _vm.locationDraft,
                    onCancel: () => {
                        _vm.isCreateOpen = false;
                    },
                }}})],1)],1)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
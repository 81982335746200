import { PAYMENT_METHODS } from '@/constants/paymentMethods';
import _isEqual from 'lodash/isEqual';

export const isClient = types => {
    if (!types) return false;
    return types.includes('client');
};

export const isSupplier = types => {
    if (!types) return false;
    return types.includes('supplier');
};

export const isCarrier = types => {
    if (!types) return false;
    return types.includes('carrier');
};

export const isPlatform = types => {
    if (!types) return false;
    return types.includes('platform');
};

export const isDirectDebit = organization => {
    return organization?.paymentInfo.method === PAYMENT_METHODS.DIRECT_DEBIT;
};

export const isChangedPaymentInfo = (updatedOrganization, originalOrganization) => {
    if (!updatedOrganization) {
        return false;
    }
    return !_isEqual(originalOrganization.paymentInfo, updatedOrganization.paymentInfo);
};

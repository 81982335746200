<template>
    <div class="transport-delivery-rating-feedback-form" data-test="transport-delivery-rating-form">
        <Tile>
            <StarRating
                v-model="productQuality"
                :count="3"
                :label="$t('pages.transport.feedbackForm.qualityLabel')"
                :error="getError('productQuality')"
                @input="clearSingleValidation('productQuality')"
            />
        </Tile>

        <Tile no-padding-bottom>
            <Words block small bold spaced-bottom>
                {{ $t('pages.transport.feedbackForm.correctPlace.headline') }}
            </Words>

            <div class="transport-delivery-rating-feedback-form__correct-place-options">
                <RadioField :value="correctPlace" :option="true" name="correctPlace" @input="correctPlace = true">
                    {{ $t('pages.transport.feedbackForm.correctPlace.optionYes') }}
                </RadioField>
                <RadioField :value="correctPlace" :option="false" name="correctPlace" @input="correctPlace = false">
                    {{ $t('pages.transport.feedbackForm.correctPlace.optionNo') }}
                </RadioField>
            </div>
        </Tile>

        <SlideUpDown ref="imageSlideDown" :active="showImageUpload">
            <Tile>
                <Words block spaced-bottom>{{ $t('pages.transport.feedbackForm.correctPlace.noDescription') }}</Words>

                <ImageUploadTile
                    v-model="image"
                    inline
                    product-use-case="delivery_rating_attachment_image"
                    @input="handleImage"
                />

                <ErrorMessage v-if="hasErrors('image')" :message="getError('image')" />
            </Tile>
        </SlideUpDown>

        <Tile no-border>
            <Words block bold small spaced-bottom>{{ $t('pages.transport.feedbackForm.messageLabel') }}</Words>
            <TextField
                v-model="message"
                :label="$t('pages.transport.feedbackForm.messagePlaceholder')"
                type="textarea"
            />
        </Tile>
    </div>
</template>

<script>
import validation from '@/services/validation/mixin';
import { greaterThan } from '@/services/validation/rules';
import TransportApi from '@/services/Api/Transport';
import { CONTEXT_CLIENT } from '@/constants/context';

import ErrorMessage from '@/components/Form/ErrorMessage';
import ImageUploadTile from '@/components/Form/ImageUploadTile';
import RadioField from '@/components/Form/RadioField';
import SlideUpDown from '@/components/Animation/SlideUpDown';
import StarRating from '@/components/Form/StarRating';
import TextField from '@/components/Form/TextField.v2';
import Tile from '@/components/Layout/Tile';
import Words from '@/components/Typography/Words';
import statefulMixin from '@/plugins/mixins/statefulMixin';

export default {
    name: 'TransportDeliveryRatingFeedbackForm',
    components: {
        ErrorMessage,
        ImageUploadTile,
        RadioField,
        SlideUpDown,
        StarRating,
        Tile,
        TextField,
        Words,
    },
    mixins: [validation, statefulMixin],
    props: {
        transportId: {
            type: [Number, String],
            required: true,
        },
        context: {
            type: String,
            default: CONTEXT_CLIENT,
        },
    },
    data() {
        return {
            productQuality: 3,
            correctPlace: true,
            image: null,
            message: null,

            validationRules: {
                productQuality: [greaterThan(0, 'pages.transport.feedbackForm.validation.ratingRequired')],
                image: [this.imageCheck()],
            },
        };
    },
    computed: {
        showImageUpload() {
            return (this.productQuality > 0 && this.productQuality < 3) || !this.correctPlace;
        },
    },
    watch: {
        errors() {
            this.$nextTick(() => {
                this.$refs.imageSlideDown.layout();
            });
        },

        correctPlace() {
            this.clearSingleValidation('image');
        },
    },
    methods: {
        async submit() {
            return this.stateful('save', async () => {
                if (!this.isValid()) {
                    return false;
                }

                const transportApi = new TransportApi(this.context);
                const transport = await transportApi.submitFeedback(this.transportId, {
                    material: this.productQuality,
                    correctPlace: this.correctPlace,
                    image: this.image,
                    message: this.message,
                });
                this.$store.commit('transportActions/updateTransport', transport);

                this.reset();
                return true;
            });
        },

        reset() {
            this.productQuality = 3;
            this.correctPlace = true;
            this.image = null;
            this.message = null;
        },

        handleImage(value) {
            this.image = value;
            this.clearSingleValidation('image');

            this.$nextTick(() => {
                this.$refs.imageSlideDown.layout();
            });
        },

        imageCheck() {
            return v => {
                return (
                    (this.correctPlace && this.productQuality === 3) ||
                    !!v ||
                    this.$t('pages.transport.feedbackForm.validation.imageRequired')
                );
            };
        },
    },
};
</script>

<style lang="scss">
.transport-delivery-rating-feedback-form__correct-place-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin-bottom: 20px;
}
</style>

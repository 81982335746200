import AbstractFilterableResource from './AbstractFilterableResource';

class Organization extends AbstractFilterableResource {
    async getPermissionDefinition() {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/permission-definition`);
            return response.data;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }
    async getOneById(id) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/${id}`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async getAvailablePermissions(organizationId) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/${organizationId}/permission-definition`);
            return response.data;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }

    async getDefinition() {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/definition`);
            return response.data;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }

    async acceptTerms() {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/confirmterms`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async filterReducedList(...args) {
        return this.filterEndpoint(`${this.resourcePath}/list`, ...args);
    }

    async getCarrierList() {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/carrierlist`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async updateStatus(status, organizationId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${status}/${organizationId}`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async approve(organizationId) {
        return this.updateStatus('approve', organizationId);
    }

    async postpone(organizationId) {
        return this.updateStatus('postpone', organizationId);
    }

    async reject(organizationId) {
        return this.updateStatus('reject', organizationId);
    }

    async deleteDisposal(organizationId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${organizationId}/delete-disposal`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new Organization('/v3/organization');

<template>
    <div
        :class="{
            'star-rating--view': view,
        }"
        class="star-rating"
    >
        <Words v-if="label" small bold block class="star-rating__headline">{{ label }}</Words>

        <div class="star-rating__options">
            <div v-for="n in count" :key="n" class="star-rating__option" @click="setRating(n)">
                <Star
                    :class="{ 'star-rating__star--active': roundedValue >= n }"
                    :width="size"
                    :height="size"
                    class="star-rating__star"
                />
            </div>
        </div>
        <ErrorMessage v-if="error" :message="error" />
    </div>
</template>

<script>
import Words from '@/components/Typography/Words';
import ErrorMessage from '@/components/Form/ErrorMessage';

import Star from '@/assets/icons/star_rating.svg';

export default {
    name: 'StarRating',
    components: {
        Words,
        ErrorMessage,
        Star,
    },
    props: {
        value: {
            type: Number,
            default: 0,
        },
        count: {
            type: Number,
            default: 3,
        },
        label: {
            type: String,
            default: null,
        },
        view: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        roundedValue() {
            return Math.round(this.value);
        },

        size() {
            if (this.view) {
                return this.small ? 16 : 24;
            }
            return 40;
        },
    },
    methods: {
        setRating(num) {
            !this.view && this.$emit('input', num);
        },
    },
};
</script>

<style lang="scss">
.star-rating__headline {
    margin-top: 0;
    margin-bottom: 15px;
}

.star-rating__options {
    display: flex;
    flex-flow: row wrap;
}

.star-rating__option {
    margin-left: 20px;

    .star-rating--view & {
        margin-left: 10px;
    }

    &:first-child {
        margin-left: 0;
    }
}

.star-rating__star {
    path {
        transition: fill 0.2s ease;
    }
}

.star-rating__star--active {
    path {
        fill: #ffa302;
    }
}
</style>

<template>
    <div :class="{ 'multiselect-inner--dark': dark }" class="multiselect-inner">
        <slot />
        <ArrowIcon width="14" height="14" />
    </div>
</template>

<script>
import ArrowIcon from '@/assets/icons/regular/arrow-stripeless--down.svg';

export default {
    name: 'DefaultInner',
    components: {
        ArrowIcon,
    },
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.multiselect-inner {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 26px 20px;
}

.multiselect-inner--dark {
    background-color: $color-lightGrey;
}
</style>

export const FACTORY_COMPONENTS = {
    FACTORY_NAME_ADDRESS: 'FactoryNameAddress',
    FACTORY_LOCATION_TYPE: 'FactoryLocationType',
    FACTORY_CONTACT: 'FactoryContact',
    FACTORY_OPENING_HOURS: 'FactoryOpeningHours',
    FACTORY_WEIGHING_TYPES: 'FactoryWeighingTypes',
    FACTORY_VEHICLE_REQUIREMENTS: 'FactoryVehicleRequirements',
    FACTORY_PLATFORM_SETTINGS: 'FactoryPlatformSettings',
};
export const PARENT_ROUTE_NAME = 'factory-management';
export const PARENT_ADMIN_ROUTE_NAME = 'admin-factory-management';
export const FACTORY_MANAGEMENT_META_TYPES = {
    FACTORY_EDIT: 'factoryEdit',
    FACTORY_DRAFT_EDIT: 'factoryDraftEdit',
};
export const FACTORY_ROUTE_NAMES = {
    FACTORY_EDIT: 'factoryEdit',
    NAME_ADDRESS: 'name-address',
    LOCATION_TYPE: 'location-type',
    CONTACT: 'contact',
    OPENING_HOURS: 'opening-hours',
    PLATFORM_SETTINGS: 'platform-settings',
    PARENT_ROUTE_NAME,
    PARENT_ADMIN_ROUTE_NAME,
};

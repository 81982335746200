<template>
    <div
        :class="{
            'alert-dialog--error': isType('error'),
            'alert-dialog--success': isType('success'),
            'alert-dialog--warning': isType('warning'),
            'alert-dialog--info': isType('info'),
            [`alert-dialog--${type}`]: true,
            'alert-dialog--micro': micro,
            'alert-dialog--dark': dark,
        }"
        class="alert-dialog"
    >
        <div :class="{ 'alert-dialog__icon--primry': primary }" class="alert-dialog__icon">
            <StopIcon v-if="isIconType('error')" width="12" class="icon--inline" />
            <CheckmarkIcon v-if="isIconType('success')" width="12" class="icon--inline" />
            <WarningIcon v-if="isIconType('warning')" width="12" class="icon--inline" />
            <InfoIconPrimary v-if="isIconType('info') && primary" class="icon--inline" />
            <InfoIcon v-else-if="isIconType('info')" width="12" class="icon--inline" />
        </div>
        <div class="alert-dialog__message">
            <slot />

            <div v-if="$slots.action && vertical" class="alert-dialog__button">
                <slot name="action" />
            </div>
        </div>

        <div v-if="$slots.action && !vertical" class="alert-dialog__button">
            <slot name="action" />
        </div>
    </div>
</template>

<script>
import StopIcon from '@/assets/icons/micro/stop.svg';
import CheckmarkIcon from '@/assets/icons/micro/tick.svg';
import WarningIcon from '@/assets/icons/micro/warning.svg';
import InfoIcon from '@/assets/icons/micro/info.svg';
import InfoIconPrimary from '@/assets/icons/regular/info.svg';

export default {
    name: 'Alert',
    components: {
        StopIcon,
        CheckmarkIcon,
        WarningIcon,
        InfoIcon,
        InfoIconPrimary,
    },
    props: {
        type: {
            type: String,
            default: 'error',
            validator: value => {
                return ['error', 'success', 'warning', 'info'].indexOf(value) !== -1;
            },
        },
        iconType: {
            type: String,
            default: null,
            validator: value => {
                return ['error', 'success', 'warning', 'info'].indexOf(value) !== -1;
            },
        },
        iconSize: {
            type: String,
            default: 'micro',
            validator: value => {
                return ['micro', 'regular'].indexOf(value) !== -1;
            },
        },
        vertical: {
            type: Boolean,
            default: false,
        },
        micro: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        primary: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        isType(expectedType) {
            return this.type === expectedType;
        },
        isIconType(expectedType) {
            const type = this.iconType !== null ? this.iconType : this.type;
            return type === expectedType;
        },
    },
};
</script>

<style lang="scss">
.alert-dialog {
    display: flex;
    flex-direction: row;
    padding: 20px 20px 17px;
    background-color: $color-white;
}

.alert-dialog--primary {
    background-color: #d2d2d2;
    padding: 15px;

    .alert-dialog__message {
        margin-left: 10px;
    }
}

.alert-dialog--micro {
    background-color: transparent;
    font-size: 10px;
    color: $color-red;
}

.alert-dialog--error {
    color: $color-red;
    background-color: #f2c8c7;

    svg {
        .f-base {
            fill: $color-red;
        }
        .s-base {
            stroke: $color-red;
        }
    }
}

.alert-dialog--success {
    color: $color-green;
    background-color: rgba(2, 151, 12, 0.1);

    svg {
        .f-base {
            fill: $color-green;
        }
        .s-base {
            stroke: $color-green;
        }
    }
}

.alert-dialog--warning {
    color: $color-yellow;
    background-color: #fff2db;

    svg {
        .f-base {
            fill: $color-yellow;
        }
        .s-base {
            stroke: $color-yellow;
        }
    }
}

.alert-dialog--info {
    color: $color-base;
    background-color: rgba(53, 52, 61, 0.15);

    svg {
        .f-base {
            fill: $color-base;
        }
        .s-base {
            stroke: $color-base;
        }
    }
}

.alert-dialog--dark {
    color: $color-white;
    background-color: #444;

    &.alert-dialog--error {
        background-color: $color-red;
    }

    &.alert-dialog--info {
        background-color: #444;
    }

    &.alert-dialog--success {
        background-color: $color-green;
    }

    svg {
        .f-base,
        .f-highlight {
            fill: $color-white;
        }
        .s-base,
        .s-highlight {
            stroke: $color-white;
        }
    }

    .button {
        color: $color-white;
    }
}

.alert-dialog__icon {
    flex: 0 0 22px;
}

.alert-dialog__icon--primry {
    flex: 0 0 34px;
}

.alert-dialog__message {
    flex: 1 1;
    padding-right: 5px;
    display: flex;
    align-items: center;
}

.alert-dialog__button {
    flex: 0 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 15px;

    > * {
        white-space: nowrap;
        font-weight: $font-weight-regular;
    }
}
</style>

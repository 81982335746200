<template>
    <ProjectPositionInfoBlock
        v-if="doRender"
        v-show="isLoaded"
        :project-position-id="projectPositionId"
        :project-position-prop="projectPosition"
        class="my-6"
        is-card
        @loaded="handleLoaded($event)"
    />
</template>

<script>
import ProjectPositionInfoBlock from '@/components/Project/ProjectPositionInfoBlock';

export default {
    name: 'ProjectPositionInfoCard',
    components: {
        ProjectPositionInfoBlock,
    },
    props: {
        projectPositionId: {
            type: Number,
            default: null,
        },
        projectPosition: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            doRender: true,
            isLoaded: false,
        };
    },
    methods: {
        /**
         * Handle loaded state
         * @param {boolean} isLoaded
         */
        handleLoaded(isLoaded) {
            this.isLoaded = isLoaded;
            this.doRender = isLoaded;
        },
    },
};
</script>

<template>
    <div class="organization-list-advanced-filter-set">
        <div v-if="supportsFilter('search')" data-test="search-organization">
            <TextField v-model="search" :label="$t('pages.organization.list.filter.search')" />
        </div>

        <div v-if="supportsFilter('status')">
            <MultiselectBox
                v-model="status"
                :options="['pending', 'postponed', 'rejected']"
                :option-value-renderer="option => option"
                :search-label-renderer="option => option"
                :label="$t('pages.organization.list.pendingApproval.registrationStatus')"
                multiple
            >
                <DefaultInner
                    slot="button"
                    slot-scope="buttonScope"
                    mode="button"
                    :scope="buttonScope"
                    :label-renderer="option => $t(`pages.organization.list.pendingApproval.filter.${option}`)"
                />

                <DefaultInner
                    slot="option"
                    slot-scope="optionScope"
                    mode="option"
                    :scope="optionScope"
                    :label-renderer="option => $t(`pages.organization.list.pendingApproval.filter.${option}`)"
                />
            </MultiselectBox>
        </div>

        <div v-if="supportsFilter('isActive')">
            <MultiselectBox
                v-model="isActive"
                :options="[true, false]"
                :option-value-renderer="option => option"
                :search-label-renderer="option => option"
                :label="$t('pages.organization.list.filter.isActive')"
            >
                <DefaultInner
                    slot="button"
                    slot-scope="buttonScope"
                    mode="button"
                    :scope="buttonScope"
                    :label-renderer="
                        option =>
                            option
                                ? $t(`pages.organization.list.filter.isBlockedActive`)
                                : $t(`pages.organization.list.filter.isBlockedInactive`)
                    "
                />

                <DefaultInner
                    slot="option"
                    slot-scope="optionScope"
                    mode="option"
                    :scope="optionScope"
                    :label-renderer="
                        option =>
                            option
                                ? $t(`pages.organization.list.filter.isBlockedActive`)
                                : $t(`pages.organization.list.filter.isBlockedInactive`)
                    "
                />
            </MultiselectBox>
        </div>

        <div v-if="supportsFilter('isBlocked')">
            <CheckboxField v-model="isBlocked" :option="true">
                {{ $t('pages.organization.list.filter.isBlocked') }}
            </CheckboxField>
        </div>
    </div>
</template>

<script>
import _get from 'lodash/get';
import _intersection from 'lodash/intersection';
import _isArray from 'lodash/isArray';
import { ensurePHPTimestamp } from '@/services/utils/date';
import { revertLocalizedValue } from '@/services/utils/localization';

import DefaultInner from '@/components/Form/MultiselectBox/DefaultInner.v2';
import MultiselectBox from '@/components/Form/MultiselectBox';
import TextField from '@/components/Form/TextField.v2';
import CheckboxField from '@/components/Form/CheckboxField';
import { useTwoWayBindingComputedFilter } from '@/components/Filter/FilterBox';

export default {
    name: 'AdvancedFilterSet',
    components: {
        DefaultInner,
        MultiselectBox,
        TextField,
        CheckboxField,
    },
    props: {
        filterScope: {
            type: Object,
            required: true,
        },
        endpoint: {
            type: Object,
            required: true,
        },
        forcedFilter: {
            type: Object,
            required: true,
        },
        whitelistedFields: {
            type: Array,
            default: null,
        },
    },
    setup(props, { emit }) {
        const createTwoWayBindingComputedFilter = useTwoWayBindingComputedFilter({ props, emit });

        return {
            search: createTwoWayBindingComputedFilter('search'),
            status: createTwoWayBindingComputedFilter('status'),
            isActive: createTwoWayBindingComputedFilter('isActive'),
            isBlocked: createTwoWayBindingComputedFilter('isBlocked'),
        };
    },
    computed: {
        availableOptionsForStatus() {
            if (this.forcedFilter.status && this.forcedFilter.status.length > 0) {
                return this.forcedFilter.status;
            }

            return Object.keys(this.endpoint.supportedFilters.status.options);
        },
    },
    methods: {
        ensurePHPTimestamp,
        revertLocalizedValue,
        _get,

        supportsFilter(filterName) {
            if (!this.endpoint.supportedFilters) {
                return false;
            }

            const checkProps = _isArray(filterName) ? filterName : [filterName];

            // if filterName does not appear fully in whitelistedFields,.. then the filter is not supported
            if (
                _isArray(this.whitelistedFields) &&
                _intersection(this.whitelistedFields, checkProps).length !== checkProps.length
            ) {
                return false;
            }

            return _intersection(Object.keys(this.endpoint.supportedFilters), checkProps).length === checkProps.length;
        },
    },
};
</script>

<style lang="scss">
.organization-list-advanced-filter-set {
    display: grid;
    grid-template-columns: 2fr 1fr 0.5fr;
    gap: 15px;
    align-items: center;

    @media screen and (max-width: $layout-desktop-max) {
        grid-template-columns: 1fr;
    }
}
</style>

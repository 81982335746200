<template>
    <SelectedTransports
        :transports="transports"
        :distance="transportDistance"
        :duration="transportDuration"
        :price-unit="transportPriceUnit"
        :fixed-price="fixedPrice"
        :show-distance-notice="showDistanceNotice"
        @edit="$emit('edit')"
    />
</template>

<script>
import TransportPricingCollectionView from '@/pages/Checkout/components/PriceAdjustment/models/TransportPricingCollectionView';

import SelectedTransports from '@/pages/Checkout/components/PriceAdjustment/SelectedTransports';

export default {
    name: 'TransportSection',
    components: {
        SelectedTransports,
    },
    props: {
        transports: {
            type: TransportPricingCollectionView,
            required: true,
        },
        fixedPrice: {
            type: Boolean,
            default: false,
        },
        transportDistance: {
            type: Number,
            default: null,
        },
        transportDuration: {
            type: Number,
            default: null,
        },
        transportPriceUnit: {
            type: String,
            required: true,
            validator: v => ['ton', 'transport'].includes(v),
        },
        showDistanceNotice: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

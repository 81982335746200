import OrderApi from '@/services/Api/Order';
const orderCache = {};
export const getPaymentTermsByOrderId = async (orderId, transportId) => {
    if (!orderCache[orderId]) {
        orderCache[orderId] = OrderApi.getOneById(orderId);
    }
    const order = await orderCache[orderId];
    for (const lineItemGroup of order?.lineItemGroups ?? []) {
        for (const lineItem of lineItemGroup?.lineItems ?? []) {
            if (lineItem.transportId === transportId) {
                return {
                    carrierPaymentTerm: lineItem?.carrierPaymentTerm,
                    supplierPaymentTerm: lineItemGroup?.supplierPaymentTerm,
                };
            }
        }
    }
    throw new Error(`No payment terms found for order ${orderId} and transport ${transportId}`);
};

<template>
    <div class="optional-data-section">
        <!-- ORIGIN -->
        <p v-if="showOriginHeadline" class="font-headline-md-strong">
            {{ $t('pages.products.supplierProductsEdit.origin') }}
        </p>

        <div class="optional-data-section__item">
            <ProductAttribute
                v-if="getValueByField(Field.GEOLOGY)"
                :label="getFieldLabel(Field.GEOLOGY)"
                :value="getValueByField(Field.GEOLOGY)"
                data-test="geology-attribute"
            />
            <slot v-else name="geology" />
        </div>

        <div class="optional-data-section__item">
            <ProductAttribute
                v-if="getValueByField(Field.GEOGRAPHY)"
                :label="getFieldLabel(Field.GEOGRAPHY)"
                :value="getValueByField(Field.GEOGRAPHY)"
                class="optional-data-section__item"
                data-test="geography-attribute"
            />
            <slot v-else name="geography" />
        </div>

        <!-- PRODUCT CHARACTERISTICS -->
        <p class="font-headline-md-strong mt-12">
            {{ $t('pages.products.supplierProductsEdit.productCharacteristics') }}
        </p>

        <div class="optional-data-section__item">
            <ProductAttribute
                v-if="getValueByField(Field.CERT_1)"
                :label="getFieldLabel(Field.CERT_1)"
                :value="getValueByField(Field.CERT_1)"
                class="optional-data-section__item"
                data-test="functions-attribute"
            />
            <slot v-else name="cert1" />
        </div>

        <div class="optional-data-section__item">
            <ProductAttribute
                v-if="getValueByField(Field.CERT_2)"
                :label="getFieldLabel(Field.CERT_2)"
                :value="getValueByField(Field.CERT_2)"
                class="optional-data-section__item"
            />
            <slot v-else name="cert2" />
        </div>

        <div class="optional-data-section__item">
            <ProductAttribute
                v-if="getValueByField(Field.PROCESSING)"
                :label="getFieldLabel(Field.PROCESSING)"
                :value="getValueByField(Field.PROCESSING)"
                class="optional-data-section__item"
                data-test="processing-attribute"
            />
            <slot v-else name="processing" />
        </div>

        <div class="optional-data-section__item">
            <ProductAttribute
                v-if="getValueByField(Field.CHARACTERISTICS)"
                :label="getFieldLabel(Field.CHARACTERISTICS)"
                :value="getValueByField(Field.CHARACTERISTICS)"
                class="optional-data-section__item"
                data-test="characteristics-attribute"
            />
            <slot v-else name="characteristics" />
        </div>

        <div class="optional-data-section__item">
            <slot name="color" />
        </div>

        <div class="optional-data-section__item">
            <ProductAttribute
                v-if="getValueByField(Field.BRAND)"
                :label="getFieldLabel(Field.BRAND)"
                :value="getValueByField(Field.BRAND)"
                class="optional-data-section__item"
                data-test="brand-attribute"
            />
            <slot v-else name="brand" />
        </div>

        <!-- Tags -->
        <slot name="tags" />

        <!-- INTERNAL INFORMATION -->
        <p class="font-headline-md-strong mt-12">
            {{ $t('pages.products.supplierProductsEdit.internalInformation') }}
        </p>

        <!-- Custom Name -->
        <slot name="custom-name" />

        <!-- Internal Reference -->
        <slot name="internal-reference" />
    </div>
</template>

<script>
import ProductAttribute from '@/pages/SupplierProducts/components/ProductEditPage/ProductAttribute';
import { AttributeSetMapper } from '@/services/ProductCatalog/AttributeSetMapper';
import { ProductAttributeType as Field } from '@/services/ProductCatalog/constants';

export default {
    name: 'OptionalDataSection',
    components: {
        ProductAttribute,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
        attributes: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            mapper: null,
            Field,
        };
    },
    computed: {
        certificates() {
            const cert1 = this.getValueByField(Field.CERT_2);
            const cert2 = this.getValueByField(Field.CERT_3);

            if (!cert1 && !cert2) {
                return '';
            }

            if (cert1 && cert2) {
                return `${cert1}, ${cert2}`;
            }

            return cert1 || cert2;
        },
        tags() {
            return this.product.tags.map(tag => tag.label).join(', ');
        },
        hasOrigin() {
            return this.getValueByField(Field.GEOLOGY) || this.getValueByField(Field.GEOGRAPHY);
        },
        hasCharacteristics() {
            if (this.tags) {
                return true;
            }

            const fieldsToCheck = [
                Field.CERT_1,
                Field.CERT_2,
                Field.CERT_3,
                Field.PROCESSING,
                Field.COLOR,
                Field.BRAND,
            ];

            return fieldsToCheck.some(field => this.getValueByField(field));
        },
        showOriginHeadline() {
            return this.attributes.map(
                attribute => attribute.key.includes('geology') || attribute.key.includes('geography')
            );
        },
    },
    created() {
        this.mapper = new AttributeSetMapper(this.product, this.attributes);
    },
    methods: {
        getValueByField(field) {
            if (!this.mapper) {
                return '';
            }

            const value = this.mapper.getFieldData(field);

            if (!value) {
                return '';
            }

            if (value.multiple) {
                return value.valueLabel.join(', ');
            }

            return value.valueLabel;
        },

        getFieldLabel(fieldKey) {
            const field = this.getFieldAttributes(fieldKey);

            return field?.label || '';
        },

        getFieldAttributes(fieldKey) {
            if (!this.attributes) {
                return null;
            }

            return this.attributes.find(attribute => attribute.key === fieldKey);
        },
    },
};
</script>

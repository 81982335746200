<template>
    <LayoutPage is-flyout :screen-name="screenName" class="checkout-shipment-price-adjustments-page">
        <Header slot="flyoutHeader" />

        <template v-if="pricesData">
            <Tile transparent no-border>
                {{ $t('pages.checkout.shipmentPriceAdjustments.description') }}
            </Tile>

            <Card>
                <div class="checkout-shipment-price-adjustments-page__summary">
                    <div class="checkout-shipment-price-adjustments-page__summary-row">
                        <Words block bold>
                            {{ pricesData.transportCount }} &times; {{ pricesData.vehicleClassName }}
                        </Words>
                        <Words
                            block
                            bold
                            large
                            align-right
                            class="checkout-shipment-price-adjustments-page__summary-totals"
                        >
                            {{ $n(pricesData.totalRetailPrice, 'currency') }}
                        </Words>
                    </div>
                    <div class="checkout-shipment-price-adjustments-page__summary-row">
                        <Words block tiny>
                            {{ $t('pages.checkout.shipmentPriceAdjustments.payloadLabel') }}
                            {{ pricesData.vehiclePayload | tons }}
                        </Words>
                        <Words block tiny align-right>
                            {{ $n(pricesData.transportUnitPrice, 'currency') }} /
                            {{ pricesData.isPricePerTransport ? $t('units.transport') : $t('units.ton') }}
                        </Words>
                    </div>
                </div>

                <div class="checkout-shipment-price-adjustments-page__illustration">
                    <PriceIllustration :progress="pricesData.progress" :axes="pricesData.vehicleNumAxes" />
                </div>

                <RangeWizard
                    v-model="pricesData.inputPrice"
                    :min="pricesData.min"
                    :max="pricesData.max"
                    :step="pricesData.isPricePerTransport ? 1 : 0.1"
                    :billing-method="pricesData.billingMethod"
                    class="checkout-shipment-price-adjustments-page__range-wizard"
                />
            </Card>

            <ButtonGroup slot="sticky">
                <Button
                    primary
                    :disabled="isPending()"
                    :is-loading="isPending('submit')"
                    data-test="button-set-price"
                    @click="submit()"
                >
                    {{ $t('pages.checkout.shipmentPriceAdjustments.submit') }}
                </Button>
            </ButtonGroup>
        </template>
    </LayoutPage>
</template>

<script>
import { mapState } from 'vuex';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import Toaster from '@/services/Toaster';
import QuoteService from '@/services/QuoteService';
import ClientPricingDataView from '@/pages/Checkout/components/ShipmentPriceAdjustments/ClientPricingDataView';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Card from '@/components/Layout/Card';
import Header from './components/Header';
import LayoutPage from '@/components/Layout/Page.v2';
import PriceIllustration from '@/pages/Checkout/components/ShipmentPriceAdjustments/PriceIllustration';
import RangeWizard from '@/pages/Checkout/components/ShipmentPriceAdjustments/RangeWizard';
import Tile from '@/components/Layout/Tile';
import Words from '@/components/Typography/Words';
import { trackCheckoutEvent } from './trackCheckoutEvent';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';

export default {
    name: 'ShipmentPriceAdjustmentsPage',
    components: {
        Button,
        ButtonGroup,
        Card,
        Header,
        LayoutPage,
        PriceIllustration,
        RangeWizard,
        Tile,
        Words,
    },
    mixins: [statefulMixin],
    setup() {
        return {
            screenName: useOrderScreenName('priceadjustments'),
        };
    },
    data() {
        return {
            pricesData: null,
        };
    },
    computed: {
        ...mapState('basket', ['quote', 'isCustom']),
    },
    created() {
        this.pricesData = ClientPricingDataView.createFromQuote(this.quote);
    },
    methods: {
        async submit() {
            await this.stateful('submit', async () => {
                if (!this.isCustom) {
                    const difference = this.pricesData.price - this.pricesData.suggestedPrice;
                    trackCheckoutEvent('shipmentPriceAdjustments', 'selectPrice', {
                        base: this.pricesData.isPricePerTransport ? 'transport' : 'ton',
                        suggested: this.pricesData.suggestedPrice,
                        selected: this.pricesData.price,
                        difference: Math.abs(difference),
                        differenceModifier: difference < 0 ? 'negative' : 'positive',
                    });
                }
                try {
                    await QuoteService.updateShipmentPrice(this.pricesData.transportRetailPrice);
                    this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(() => {});
                } catch (err) {
                    this.$logger().error(err);
                    Toaster.error(err);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.checkout-shipment-price-adjustments-page__illustration {
    max-width: 340px;
    width: 80%;
    margin: auto;
}

.checkout-shipment-price-adjustments-page__range-wizard {
    margin-top: 20px;
}

.checkout-shipment-price-adjustments-page__summary {
    padding: 12px;
    margin-bottom: 30px;
    background-color: $theme-dark-background-color;
    color: $theme-dark-color;
}

.checkout-shipment-price-adjustments-page__summary-row {
    display: flex;
    justify-content: space-between;

    > * + * {
        margin-left: 20px;
    }

    & + & {
        margin-top: 5px;
    }
}

.checkout-shipment-price-adjustments-page__summary-totals {
    font-size: 20px;
}
</style>

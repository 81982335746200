<template>
    <div
        class="form-field-group"
        data-test="FormFieldGroup"
        :class="{ 'form-field-group--no-margin': noBottomMargin, 'form-field-group--small-margin': smallBottomMargin }"
    >
        <div
            v-if="hasSlotContent"
            class="form-field-group__title"
            data-test="FormFieldGroupTitle"
            :class="{ 'form-field-group__title--no-padding': noBottomPadding }"
        >
            <slot name="title" />
        </div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'FormFieldGroup',
    props: {
        noBottomMargin: {
            type: Boolean,
            default: false,
        },
        noBottomPadding: {
            type: Boolean,
            default: false,
        },
        smallBottomMargin: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasSlotContent() {
            return !!this.$slots.title;
        },
    },
};
</script>

<style lang="scss" scoped>
.form-field-group {
    margin-bottom: 48px;

    &--no-margin {
        margin-bottom: 0;
    }

    &--small-margin {
        margin-bottom: 40px;
    }

    p {
        margin-bottom: 24px;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        padding-bottom: 8px;

        &--no-padding {
            padding-bottom: 0;
        }

        a {
            color: $color-darkGrey;
            text-decoration: none;
            font-weight: 700;
        }
    }
}
</style>

import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Combobox } from '@schuettflix/react-components';
import { ConstructionProjectStatus, updateConstructionProjectAdminDetail, } from '../../api/projects';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import Toaster from '@/services/Toaster';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { adminConstructionProjectOrganizationOptionsQuery, constructionProjectByIdAsAdminQuery, constructionProjectKeys, } from '@/constructionProjects/queries';
import { ConstructionProjectForm, useConstructionProjectForm, } from '@/constructionProjects/pages/ConstructionProjectCreate/ConstructionProjectForm';
import { FormField } from '@/constructionProjects/components/FormField';
import { useAbility } from '@/reactBridge/useAbility';
import { wait } from '@/services/utils/wait';
import { projectsKeys } from '@/reactBridge/queryClient';
import { useRouteParams } from '@/reactBridge/useRouteParams';
export const ConstructionProjectAdminEditForm = () => {
    const { vueRouter } = useVueRouter();
    const { t } = useTranslation();
    const { id: projectId } = useRouteParams();
    const queryClient = useQueryClient();
    const { data: constructionProjectQuery } = useQuery({
        ...constructionProjectByIdAsAdminQuery(projectId),
        suspense: true,
        staleTime: Infinity,
    });
    // Fine to assert that it is not undefined due to suspense & query never disabled
    const constructionProjectInitialDetails = constructionProjectQuery;
    const [selectedPlatformAdminId, setSelectedPlatformAdminId] = useState(constructionProjectInitialDetails.responsibleEmployee.id);
    const { data: adminOrganizationOptions } = useQuery({
        ...adminConstructionProjectOrganizationOptionsQuery(constructionProjectInitialDetails.organizationId ?? -1),
        enabled: !!constructionProjectInitialDetails.organizationId,
    });
    const { form } = useConstructionProjectForm({
        defaultValues: {
            active: constructionProjectInitialDetails.status === ConstructionProjectStatus.Active,
            name: constructionProjectInitialDetails.name,
            description: constructionProjectInitialDetails.description,
            responsibleUserId: constructionProjectInitialDetails.responsibleUser.id,
            costCenter: constructionProjectInitialDetails.costCenter,
            validFrom: format(new Date(constructionProjectInitialDetails.validFrom), 'YYYY-MM-DD'),
            validTo: format(new Date(constructionProjectInitialDetails.validTo), 'YYYY-MM-DD'),
            teamMembers: Array.from(new Set(constructionProjectInitialDetails.teamMembers
                .map(member => member.id)
                .filter(id => id !== constructionProjectInitialDetails.responsibleUser.id))),
            color: constructionProjectInitialDetails.color ?? null,
            location: {
                address: {
                    formatted: null,
                    country: constructionProjectInitialDetails.country ?? null,
                    state: constructionProjectInitialDetails.state ?? null,
                    city: constructionProjectInitialDetails.city ?? undefined,
                    street: constructionProjectInitialDetails.street ?? null,
                    streetNumber: constructionProjectInitialDetails.addressNumber ?? null,
                    zipCode: constructionProjectInitialDetails.zip ?? undefined,
                    placeId: null,
                },
                coordinates: constructionProjectInitialDetails.geoCoordinate,
            },
            orderConfirmationMails: constructionProjectInitialDetails.orderConfirmationMails || [],
        },
        costCenterMandatory: adminOrganizationOptions?.organizationMetadata?.costCenterMandatory ?? false,
    });
    const { formState: { dirtyFields }, } = form;
    const mutation = useMutation({
        mutationFn: (data) => updateConstructionProjectAdminDetail(projectId, data),
        onSuccess: () => {
            AnalyticsService.trackEvent('construction_project', {
                step: 'construction_project_edit',
                constructionProjectId: projectId,
                fieldsChanges: Object.keys(dirtyFields).join(', '),
                // Extra params for these 2 fields to allow easier filtering in analytics
                statusChanged: dirtyFields['active'] !== undefined,
                teamMembersChanged: dirtyFields['teamMembers'] !== undefined,
            });
            queryClient.invalidateQueries(constructionProjectKeys.byId(projectId));
            Toaster.success(t('pages.constructionProject.editForm.toaster.success'));
            // Legacy projects are synced via CDC which is an async process. We need to wait a bit
            // to make sure the project is updated in the legacy system so we are polling for a few
            // seconds.
            for (let i = 0; i < 3; i++) {
                wait(i * 2000).then(() => queryClient.invalidateQueries(projectsKeys.namespace));
            }
        },
        onError: (error) => {
            if (!error.response || error.response.status > 400) {
                Toaster.error(t('pages.constructionProject.editForm.toaster.unspecifiedServerError'));
            }
        },
    });
    const onSubmitConstructionProjectCreateForm = (formData) => {
        const data = {
            name: formData.name,
            description: formData.description,
            validFrom: new Date(formData.validFrom).toISOString(),
            validTo: new Date(formData.validTo).toISOString(),
            costCenter: formData.costCenter,
            color: formData.color ?? undefined,
            active: formData.active,
            bounds: [],
            responsibleUserId: formData.responsibleUserId,
            teamMembers: Array.from(new Set(formData.teamMembers)),
            city: formData.location.address.city,
            zip: formData.location.address.zipCode,
            street: formData.location.address.street ?? undefined,
            addressNumber: formData.location.address.streetNumber ?? undefined,
            geoCoordinate: formData.location.coordinates,
            organizationId: constructionProjectInitialDetails.organizationId,
            responsibleEmployeeId: selectedPlatformAdminId ?? -1,
            orderConfirmationMails: formData.orderConfirmationMails,
        };
        mutation.mutate(data, {
            onSuccess: () => {
                vueRouter.back();
            },
            onError: (error) => {
                switch (error.response?.status) {
                    case 400: {
                        const fieldErrors = error.response?.data?.fieldErrors;
                        for (const [key, error] of Object.entries(fieldErrors)) {
                            form.setError(
                            // @ts-expect-error These are backend keys and are untypable
                            key, {
                                type: 'server',
                                message: error.message,
                            }, { shouldFocus: true });
                        }
                        break;
                    }
                }
            },
        });
    };
    const responsibleEmployeeOptions = adminOrganizationOptions?.platformUsers?.map(user => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
    }));
    const canManageProjects = useAbility('haveProjectManagement');
    return (<>
            <div className="container-content flex flex-col bg py-6">
                <FormField title={t('pages.constructionProject.createForm.responsibleEmployeeId.title')} description={t('pages.constructionProject.createForm.responsibleEmployeeId.description')}>
                    <Combobox className="mb-4 w-full" label={t('pages.constructionProject.createForm.personField.label')} multiple={false} options={responsibleEmployeeOptions ?? []} value={selectedPlatformAdminId} onChange={value => setSelectedPlatformAdminId(value)}/>
                </FormField>
            </div>
            {adminOrganizationOptions?.organizationUsers && (<ConstructionProjectForm form={form} organizationUsers={adminOrganizationOptions.organizationUsers} organizationEmail={adminOrganizationOptions.organizationMetadata.email} submissionLoading={mutation.isLoading} onCancelClick={() => vueRouter.back()} onSubmit={onSubmitConstructionProjectCreateForm} submitEnabled={canManageProjects} submitButtonLabel={t('pages.constructionProject.editForm.saveButton.label')} cancelButtonLabel={t('pages.constructionProject.editForm.cancelButton.label')} costCenterMandatory={adminOrganizationOptions.organizationMetadata.costCenterMandatory}/>)}
        </>);
};

class UrlBuilder {
    replaceUrlParamsWithValues(url, urlParameters) {
        for (const [key, value] of Object.entries(urlParameters)) {
            url = url.replace(`{${key}}`, encodeURIComponent(value));
        }
        return url;
    }
}

export default new UrlBuilder();

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"font-copy-md grid grid-cols-2 grid-rows-1 gap-0 md:grid-cols-12"},[_c('div',{staticClass:"font-copy-md",class:{
            'order-0': _vm.reversePrices,
            'col-span-1 whitespace-nowrap md:col-span-4': _vm.hasPricePerTransport && _vm.hasPricePerWeight,
            'col-span-1 md:col-span-4': !_vm.hasPricePerTransport || !_vm.hasPricePerWeight,
        }},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.hasPricePerWeight)?_c('div',{staticClass:"truncate",class:{
            'col-span-1 text-left md:col-span-8 md:text-right': !_vm.hasPricePerTransport,
            'col-span-2 text-left md:col-span-3 md:text-right': _vm.hasPricePerTransport,
            'order-2': _vm.reversePrices,
        }},[_c('span',{class:{ 'text-subdued': _vm.fixedPrice & !_vm.muteFixedPriceHighlights }},[_vm._v(" "+_vm._s(_vm.$n(_vm.pricePerWeight, 'currency'))+" "),(_vm.showUnitPerWeight)?[_vm._v(" / "+_vm._s(_vm.unitPerWeight || _vm.$t('units.ton')))]:_vm._e()],2)]):_vm._e(),(_vm.hasPricePerTransport)?_c('div',{staticClass:"truncate",class:{
            'col-span-1 text-right md:col-span-8': !_vm.hasPricePerWeight,
            'col-span-2 text-left md:col-span-5 md:text-right': _vm.hasPricePerWeight,
            'order-1': _vm.reversePrices,
        }},[_c('span',{class:{ 'text-subdued': !_vm.fixedPrice && !_vm.muteFixedPriceHighlights }},[_vm._v(" "+_vm._s(_vm.$n(_vm.pricePerTransport, 'currency'))+" "),(_vm.showUnitPerTransport)?[_vm._v(" / "+_vm._s(_vm.unitPerTransport || _vm.$t('units.transport')))]:_vm._e()],2)]):_vm._e(),(_vm.showNoPriceAvailableHint)?_c('span',{staticClass:"font-copy-md col-span-8 text-right text-subdued"},[_vm._v(" "+_vm._s(_vm.$t('pages.products.productsList.supplier.noPrice'))+" ")]):_vm._e()])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
export const LOADING_STAGES = {
    FEATHER_LIGHT: 'featherLight',
    LIGHT_TOUR: 'lightTour',
    ALMOST_FULL: 'almostFull',
    POINT_CHARGE: 'pointCharge',
    HEAVY_WEIGHT: 'heavyWeight',
    EXCEED_MAXIMUM: 'exceedMaximum',
    BELOW_MINIMUM: 'belowMinimum',
};
export const LOADING_STAGE_MAX_PERCENT = {
    FEATHER_LIGHT: 20,
    LIGHT_TOUR: 50,
    ALMOST_FULL: 90,
    POINT_CHARGE: 102,
};

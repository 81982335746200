import { forwardRef } from 'react';
// TODO extract to component library, this is just temporary until icon PR is merged
export const ForwardingOrderIcon = forwardRef(({ className, ...props }, ref) => {
    return (<svg width={80} height={42} className={className} ref={ref} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#a)" strokeWidth={1.2} strokeLinecap="round" strokeLinejoin="round">
                <path d="m35.699 15.626-6.485-5.516.65 3.68H16.687l.65 3.68h13.167l.65 3.68 4.545-5.524Z" stroke="#1F1920"/>
                <path d="m6.918 23.993 6.494 5.515-.65-3.67H25.93l-.65-3.68H12.113l-.65-3.68-4.545 5.515Z" stroke="red"/>
                <path d="M73.864 31.381H71.04v-1.374a.3.3 0 0 1 .31-.31h2.542a.132.132 0 0 1 .132.131v1.421a.132.132 0 0 1-.16.132Z" stroke="#1F1920"/>
                <path d="M72.979 22.91v-7.002a.63.63 0 0 0-.64-.64H60.405a.65.65 0 0 0-.612.442l-.245.762c-.134.394-.134.82 0 1.214a5.016 5.016 0 0 0 2.57 3.106l9.892 1.62a1.731 1.731 0 0 1 1.449 1.703v5.6M56.47 10.063h-.94a.574.574 0 0 0-.574.575V34.11" stroke="#1F1920"/>
                <path d="M72.857 15.532a12.294 12.294 0 0 0-.838-3.172 1.704 1.704 0 0 0-1.092-.941 38.264 38.264 0 0 0-8.659-1.186l-5.797-.17" stroke="#1F1920"/>
                <path d="m64.941 10.44-5.157-2.306a1.675 1.675 0 0 0-1.365-.189 1.647 1.647 0 0 0-1.073 1.14l-.292.94 6.334.264M63.332 15.531v5.261M73.722 31.38v1.356l-1.43.603-1.76.762h-4.63M39.379 33.292h-6.645M65.882 32.765a3.302 3.302 0 1 0-6.604.018 3.302 3.302 0 0 0 6.604-.018ZM24.47 32.765a3.313 3.313 0 1 0-6.624 0 3.313 3.313 0 0 0 6.625 0v0ZM32.734 32.765a3.302 3.302 0 1 0-6.604.018 3.302 3.302 0 0 0 6.604-.018ZM48.282 32.765a3.313 3.313 0 1 0-6.626 0 3.313 3.313 0 0 0 6.626 0v0ZM26.193 29.132h-1.638M35.219 33.273h-2.447M15.784 33.753v-4.847h2.55M73.356 24.651l-1.62-.282M70.137 14.092v3.096M71.718 36.464H53.497M10.57 39.513H4.573M48.8 36.68H12.838M49.214 29.169h3.803V13.997M41.045 29.132h-7.501M53.017 33.273h-4.735" stroke="#1F1920"/>
                <path d="M75.323 26.92H64.659M63.03 26.92h-.15" stroke="red"/>
                <path d="M15.04 13.79h-4.546l.555 2.419M53.017 9.226v4.771M16.424 40.69h47.02M59.332 34.1h-1.525" stroke="#1F1920"/>
                <path d="m71.925 3.38-3.577 3.568M68.348 3.38l3.577 3.568M6.381 30.778H3.257M4.819 29.217v3.125M56.602 19.269H41.6M39.69 19.269h-.565M49.101 9.612a11.163 11.163 0 0 0-12.499-.066l-.112.066M37.083 6.28a6.71 6.71 0 0 0-12.528 3.36" stroke="red"/>
                <path d="M37.318 10.628s-2.494-3.275-5.421-3.53M23.586 6.28a8.038 8.038 0 0 0-8.81 4.066M42.353 5.81a4.395 4.395 0 0 0-5.054-2.476M26.41 10.346h-4.151" stroke="red"/>
                <path d="M37.083 13.546h13.195" stroke="#1F1920"/>
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" transform="translate(0 .765)" d="M0 0h80v40.471H0z"/>
                </clipPath>
            </defs>
        </svg>);
});
ForwardingOrderIcon.displayName = 'ForwardingOrderIcon';

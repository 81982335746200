<template>
    <div class="empty-state">
        <div v-if="$root.isDesktop">
            <component :is="icon" />
        </div>
        <Words block centered :spaced-top="$root.isDesktop">
            {{ text }}
        </Words>
    </div>
</template>

<script>
import Words from '@/components/Typography/Words';

import LoadingEmptyIcon from '@/assets/icons/homeIcons/loading_empty_state.svg';
import PickupEmptyIcon from '@/assets/icons/homeIcons/pickup_empty_state.svg';
import TripsEmptyIcon from '@/assets/icons/homeIcons/trips_empty_state.svg';
import DocumentEmptyIcon from '@/assets/icons/homeIcons/document_empty_state.svg';

export default {
    name: 'EmptyState',

    components: {
        Words,

        LoadingEmptyIcon,
        PickupEmptyIcon,
        TripsEmptyIcon,
        DocumentEmptyIcon,
    },

    props: {
        icon: {
            type: String,
            default: 'LoadingEmptyIcon',
            validator: value =>
                ['LoadingEmptyIcon', 'PickupEmptyIcon', 'TripsEmptyIcon', 'DocumentEmptyIcon'].includes(value),
        },
        text: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss">
.empty-state {
    padding: 30px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px dashed $color-grey;
    background-color: $color-white;

    @media screen and (min-width: $layout-desktop-min) {
        padding: 0;
        height: 180px;
    }
}
</style>

export default function HeaderBar({ headline, subline, arrow, action, }) {
    return (<div className={'container-content flex h-[80px] min-h-[80px] items-center gap-4 border-b border-divider bg-surface py-[20px]'}>
            {arrow && <div className="flex items-center">{arrow}</div>}
            <div className="flex grow flex-col items-stretch justify-center gap-1">
                {headline && <p className="font-copy-md-strong text">{headline}</p>}
                {subline && <p className="font-copy-sm text-subdued">{subline}</p>}
            </div>
            {action && <div className="flex items-center gap-2">{action}</div>}
        </div>);
}

<template>
    <div class="totals-sections">
        <div class="font-copy-md-strong mb-2">{{ $t('pages.checkout.priceAdjustments.totalsHeadline') }}</div>

        <div v-if="isProject && maxPayloadTransport" class="font-copy-md mb-6">
            {{ $t('pages.checkout.priceAdjustments.totalsProjectHint', { qty: material.qty / 1000 }) }}
        </div>

        <div class="card">
            <header v-if="isProject && maxPayloadTransport" class="mb-8 flex items-start justify-between">
                <h3 class="font-copy-md-strong">
                    {{ computedTotalTransportCount }} x {{ maxPayloadTransport.info }}
                    <template v-if="material.info"> - {{ material.info }}</template>
                </h3>
            </header>
            <ul class="font-copy-md m-0 list-none p-0">
                <li>
                    <div class="font-copy-md flex w-full flex-wrap justify-between">
                        <div class="font-copy-md">
                            {{ $t('pages.checkout.priceAdjustments.totalPurchasePrice') }}
                        </div>
                        <div class="flex-grow truncate whitespace-nowrap pl-4 text-right">
                            <span> {{ $n(computedTotalPurchasePrice, 'currency') }} </span>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="font-copy-md mt-2 flex w-full flex-wrap justify-between">
                        <div class="font-copy-md">
                            {{ $t('pages.checkout.priceAdjustments.totalRetailPrice') }}
                        </div>
                        <div class="flex-grow truncate whitespace-nowrap pl-4 text-right">
                            <span> {{ $n(computedTotalRetailPrice, 'currency') }} </span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import PriceAdjustmentView from '@/pages/Checkout/components/PriceAdjustment/models/PriceAdjustmentView';
import PricingDataView from '@/pages/Checkout/components/PriceAdjustment/models/PricingDataView';
import _isNull from 'lodash/isNull';

export default {
    name: 'TotalsSection',
    components: {},
    props: {
        current: {
            type: PricingDataView,
            required: true,
        },
        type: {
            type: String,
            required: true,
            validator: v =>
                [
                    PriceAdjustmentView.TYPE_PROJECT_DELIVERY,
                    PriceAdjustmentView.TYPE_PROJECT_SHIPMENT,
                    PriceAdjustmentView.TYPE_CUSTOM_ORDER,
                    PriceAdjustmentView.TYPE_PROJECT_DISPOSAL,
                ].includes(v),
        },
        material: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        fixedPrice: {
            type: Boolean,
            default: false,
        },
        totalPurchasePrice: {
            type: Number,
            default: null,
        },
        totalRetailPrice: {
            type: Number,
            default: null,
        },
        totalTransportCount: {
            type: Number,
            default: null,
        },
        maxPayloadTransport: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isProject() {
            return this.isProjectDelivery || this.isProjectShipment || this.isProjectDisposal;
        },
        isProjectDelivery() {
            return PriceAdjustmentView.TYPE_PROJECT_DELIVERY === this.type;
        },
        isProjectShipment() {
            return PriceAdjustmentView.TYPE_PROJECT_SHIPMENT === this.type;
        },
        isProjectDisposal() {
            return PriceAdjustmentView.TYPE_PROJECT_DISPOSAL === this.type;
        },
        computedTotalPurchasePrice() {
            if (!_isNull(this.totalPurchasePrice)) return this.totalPurchasePrice;
            if (this.isProjectShipment) return this.current.purchaseTotalPriceForContingentAndCustomPositions;
            if (this.isProjectDelivery) return this.current.purchaseTotalPriceForContingent;
            if (this.fixedPrice) return this.current.transportPurchasePriceTotal;

            return this.current.purchaseTotalPrice + this.current.customPositionsPurchasePriceTotal;
        },
        computedTotalRetailPrice() {
            if (!_isNull(this.totalRetailPrice)) return this.totalRetailPrice;
            if (this.isProjectShipment) return this.current.retailTotalPriceForContingentAndCustomPositions;
            if (this.isProjectDelivery) return this.current.retailTotalPriceForContingent;
            if (this.fixedPrice) return this.current.transportRetailPriceTotal;

            return this.current.retailTotalPrice + this.current.customPositionsRetailPriceTotal;
        },
        computedTotalTransportCount() {
            if (this.totalTransportCount) return this.totalTransportCount;
            if (!this.maxPayloadTransport) return 0;

            return Math.ceil(this.material.qty / this.maxPayloadTransport.payload);
        },
    },
};
</script>

import { forwardRef } from 'react';
import clsx from 'clsx';
import { useIconSize } from '@/services/utils/useIconSize';
export const SearchIcon = forwardRef(({ className, size = 'sm', ...svgProps }, ref) => {
    const iconSize = useIconSize(size);
    return (<svg width={iconSize} height={iconSize} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} className={clsx('stroke-icon', className)} {...svgProps}>
            <path d="M11.667 6.833a4.818 4.818 0 0 0-1.416-3.418A4.818 4.818 0 0 0 6.833 2a4.818 4.818 0 0 0-3.418 1.415A4.818 4.818 0 0 0 2 6.833a4.819 4.819 0 0 0 1.415 3.418 4.82 4.82 0 0 0 3.418 1.416 4.822 4.822 0 0 0 4.41-2.853c.28-.623.425-1.298.424-1.98Z" strokeWidth={1.244} strokeMiterlimit={10}/>
            <path d="m14.667 14.667-4-4" strokeWidth={1.333} strokeMiterlimit={10}/>
        </svg>);
});
SearchIcon.displayName = 'SearchIcon';

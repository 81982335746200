import { forwardRef } from 'react';
import { useIconSize } from '@/services/utils/useIconSize';
// TODO we need this extra plus icon cause the clipPath property from the plus icon in the cmp lib is causing
// icons to disappear when on mobile in the flyout header
export const PlusIcon = forwardRef(({ className, size = 'xs', ...svgProps }, ref) => {
    const iconSize = useIconSize(size);
    return (<svg width={iconSize} height={iconSize} className={className} viewBox="0 0 48 48" ref={ref} fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <g className="svg-icon stroke-icon" strokeWidth={4} strokeMiterlimit={10}>
                <path d="M24 5v37.999M5 23.999h37.998"/>
            </g>
        </svg>);
});
PlusIcon.displayName = 'PlusIcon';

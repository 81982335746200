import { httpClient } from '@/constructionProjects/api/client/http-client';
import apiService from '@/services/Api/ApiService';
export const getAdminConstructionProjects = async ({ page, size, status, searchTerm, responsibleEmployeeId, organizationList, }) => {
    const response = await httpClient.get('/admin/v1/construction-projects', {
        params: {
            page,
            size,
            status,
            searchTerm,
            responsibleEmployeeId,
            ...(organizationList?.length ? { organizationList: organizationList?.join(',') } : {}),
        },
    });
    return response.data;
};
export const getAdminConstructionProjectsMetadata = async () => {
    const response = await httpClient.get('/admin/v1/construction-projects/metadata');
    return response.data;
};
export const getFavoriteConstructionProjects = async () => {
    const response = await httpClient.get('/v1/construction-projects/favorites');
    return response.data;
};
export const getConstructionProjectById = async (id) => {
    const response = await httpClient.get(`/v1/construction-projects/${id}`);
    return response.data;
};
export const getConstructionProjectAdminById = async (id) => {
    const response = await httpClient.get(`/admin/v1/construction-projects/${id}`);
    return response.data;
};
export const getConstructionProjectsByOrganization = async (organizationId) => {
    const response = await httpClient.get(`/admin/v1/organizations/${organizationId}/construction-projects`);
    return response.data;
};
export const getNotificationSetting = async (constructionProjectId) => {
    const response = await httpClient.get(`/v1/construction-projects/${constructionProjectId}/notification`);
    return response.data;
};
export const enableNotifications = async (constructionProjectId) => {
    await httpClient.put(`/v1/construction-projects/${constructionProjectId}/notification`);
};
export const disableNotifications = async (constructionProjectId) => {
    await httpClient.delete(`/v1/construction-projects/${constructionProjectId}/notification`);
};
export var ConstructionProjectStatus;
(function (ConstructionProjectStatus) {
    ConstructionProjectStatus["Active"] = "ACTIVE";
    ConstructionProjectStatus["Inactive"] = "INACTIVE";
    ConstructionProjectStatus["Archived"] = "ARCHIVED";
})(ConstructionProjectStatus || (ConstructionProjectStatus = {}));
export const getConstructionProjectCounts = async (id) => {
    const response = await httpClient.get(`/v1/construction-projects/${id}/counts`);
    return response.data;
};
export const getConstructionProjectCountsAsAdmin = async (id) => {
    const response = await httpClient.get(`/admin/v1/construction-projects/${id}/counts`);
    return response.data;
};
export const updateConstructionProjectClientDetail = async (constructionProjectId, data) => {
    const response = await httpClient.put(`/v1/construction-projects/${constructionProjectId}`, data);
    return response.data;
};
export const updateConstructionProjectAdminDetail = async (constructionProjectId, data) => {
    const response = await httpClient.put(`/admin/v1/construction-projects/${constructionProjectId}`, data);
    return response.data;
};
export const getProjects = async () => {
    const response = await httpClient.get('/v1/construction-projects');
    return response.data;
};
export const createConstructionProject = async (data) => {
    const response = await httpClient.post('v1/construction-projects', data);
    return response.data;
};
export const createAdminConstructionProject = async (data) => {
    const response = await httpClient.post('admin/v1/construction-projects', data);
    return response.data;
};
export const updateFavoriteStatus = async ({ constructionProjectId, isFavorite, }) => {
    if (isFavorite) {
        await httpClient.put(`/v1/construction-projects/${constructionProjectId}/favorite`);
    }
    else {
        await httpClient.delete(`/v1/construction-projects/${constructionProjectId}/favorite`);
    }
};
export const getOrganizationOptions = async () => {
    const response = await httpClient.get('/v1/organizations/options');
    return response.data;
};
export const getAdminOrganizations = async () => {
    const response = await httpClient.get(`/admin/v1/organizations`);
    return response.data;
};
export const getAdminOrganizationOptions = async (organizationId) => {
    const response = await httpClient.get(`/admin/v1/organizations/${organizationId}/options`);
    return response.data;
};
export const createLocation = async (data, constructionProjectId) => {
    const response = await httpClient.post(`/v1/construction-projects/${constructionProjectId}/locations`, data);
    return response.data;
};
export const createLocationAsAdmin = async (data, constructionProjectId) => {
    const response = await httpClient.post(`/admin/v1/construction-projects/${constructionProjectId}/locations`, data);
    return response.data;
};
export const deleteLocation = async (locationId) => {
    await httpClient.delete(`/v1/locations/${locationId}`);
};
export const editLocation = async (data, locationId) => {
    const response = await httpClient.put(`/v1/locations/${locationId}`, data);
    return response.data;
};
export const getLocations = async (constructionProjectId) => {
    const response = await httpClient.get(`/v1/construction-projects/${constructionProjectId}/locations`);
    return response.data;
};
export const getLocationDraftByConstructionProjectId = async (constructionProjectId) => {
    const response = await httpClient.get(`/v1/construction-projects/${constructionProjectId}/location-draft`);
    return response.data;
};
export const archiveConstructionProject = async (constructionProjectId) => {
    await httpClient.put(`/v1/construction-projects/${constructionProjectId}/archived`);
};
export const archiveAdminConstructionProject = async (constructionProjectId) => {
    await httpClient.put(`/admin/v1/construction-projects/${constructionProjectId}/archived`);
};
export const getOldProjectIdByUuid = async (uuid) => {
    const response = await apiService.get(`/project/construction-project/${uuid}/get-project-id`);
    return response.data.projectId;
};
export const updateFavoriteStatusAsAdmin = async ({ constructionProjectId, isFavorite, }) => {
    const query = isFavorite ? httpClient.put : httpClient.delete;
    return await query(`/admin/v1/construction-projects/${constructionProjectId}/favorite`);
};

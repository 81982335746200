import AbstractFilterableResource from '@/services/Api/AbstractFilterableResource';

export default class Quote extends AbstractFilterableResource {
    resourcePath = null;

    constructor(path) {
        const resourcePath = path ?? '/client/quote';
        super(resourcePath);
        this.resourcePath = resourcePath;
    }

    async action(action, resourceObject) {
        try {
            const requestData = { ...resourceObject };
            delete requestData.id;

            const response = await this.apiService.post(
                `${this.resourcePath}/${resourceObject.id}/${action}`,
                requestData
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async submit(resourceObject) {
        return this.action('submit', resourceObject);
    }

    async accept(resourceObject) {
        return this.action('accept', resourceObject);
    }

    async reject(resourceObject) {
        return this.action('reject', resourceObject);
    }

    async updateShipmentPrice(resourceObject) {
        return this.action('shipment-price', resourceObject);
    }

    async updateCostCenter(quoteId, costCenter) {
        try {
            const payload = {
                cost_center: costCenter,
            };
            const response = await this.apiService.patch(`${this.resourcePath}/${quoteId}/cost-center`, payload);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export const clientQuoteApi = new Quote('/client/quote');
export const wasteClientQuoteApi = new Quote('/waste/client/quote');

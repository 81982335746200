<template>
    <div
        :class="{
            'vehicle-track--small': small,
            'vehicle-track--overflow': overflow,
            'vehicle-track--spaceless': spaceless,
            [`vehicle-track--vehicle-layout-${vehicleLayout}`]: true,
        }"
        class="vehicle-track"
    >
        <div v-if="vehicles.length" class="vehicle-track__inner">
            <div v-for="(vehicle, index) in vehicles" :key="index" class="vehicle-track__item">
                <VehicleClassIconSet :icon="vehicle.icon" class="vehicle-track__icon" />
                <div class="vehicle-track__info">
                    <span class="font-copy-sm-strong whitespace-nowrap">
                        {{ vehicle.count }} &times; {{ vehicle.name }}
                    </span>
                    <ReactBridge
                        :react-component="ProgressBar"
                        :props="{
                            primary: vehicle.loadPercentage,
                            size: 'sm',
                        }"
                        class="mt-2"
                    />
                </div>
            </div>
        </div>
        <div v-else class="vehicle-track__inner">
            <div class="vehicle-track__item">
                <VehicleClassIconSet class="vehicle-track__icon" />
                <div class="vehicle-track__info">
                    <div class="vehicle-track__weight-indicator bg-surface-success">
                        <div class="w-0 bg" />
                    </div>
                    <span class="font-copy-sm-strong whitespace-nowrap">&nbsp;</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _orderBy from 'lodash/orderBy';
import _groupBy from 'lodash/groupBy';

import VehicleClassIconSet from '@/components/IconSet/VehicleClassIconSet';
import ReactBridge from '@/reactBridge/ReactBridge.vue';

import { ProgressBar } from '@schuettflix/react-components';

export default {
    name: 'VehicleTrack',
    components: {
        VehicleClassIconSet,
        ReactBridge,
    },
    props: {
        transports: {
            type: Array,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        spaceless: {
            type: Boolean,
            default: false,
        },
        hideLoadingInfo: {
            type: Boolean,
            default: false,
        },
        vehicleLayout: {
            type: String,
            default: 'vertical',
            validator: v => ['horizontal', 'vertical'].includes(v),
        },
    },
    data() {
        return {
            overflow: false,
            ProgressBar,
        };
    },
    computed: {
        vehicles() {
            const groupedVehicles = _groupBy(this.transports, transport => {
                return `${transport.id}-${transport.actualLoad}`;
            });

            const vehicles = Object.values(groupedVehicles).map(group => {
                const vehicle = group[0];
                return {
                    name: vehicle.name,
                    icon: vehicle.icon,
                    loadPercentage: vehicle.loadPercentage,
                    payload: vehicle.payload,
                    numAxes: vehicle.numAxes,
                    count: group.length,
                };
            });

            return _orderBy(vehicles, ['count', 'payload'], ['desc', 'desc']);
        },
    },
    mounted() {
        this.checkOverflow();
    },
    updated() {
        this.checkOverflow();
    },
    methods: {
        checkOverflow() {
            this.$nextTick(() => {
                if (!this.$el.children) {
                    return;
                }

                const trackInnerEl = this.$el.children[0];
                this.overflow = trackInnerEl.scrollWidth > trackInnerEl.offsetWidth;
            });
        },
    },
};
</script>

<style lang="scss">
.vehicle-track {
    overflow: hidden;
}

.vehicle-track--overflow {
    position: relative;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 10%;
        bottom: 10%;
        width: 1px;
        z-index: 1;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);
        border-radius: 50%;
    }

    &:before {
        left: -1px;
    }

    &:after {
        right: -1px;
    }

    &.vehicle-track--small {
        &:before {
            display: none;
        }
    }
}

.vehicle-track__inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0 12px;
    flex: 1 1 auto;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }

    .vehicle-track--small & {
        &::before {
            margin-left: 0;
        }

        &::after {
            margin-right: 0;
        }
    }

    .vehicle-track--spaceless & {
        &::before,
        &::after {
            display: none;
        }
    }
}

.vehicle-track__item {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    & + & {
        margin-left: 45px;

        .vehicle-track--small & {
            margin-left: 20px;
        }
    }

    .vehicle-track--small & {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.vehicle-track__icon {
    height: 40px;
    width: auto;

    .vehicle-track--small & {
        height: 26px;
    }
}

.vehicle-track__info {
    margin-top: 10px;
    min-width: 40px;
    text-align: center;

    .vehicle-track--small & {
        margin-top: 7px;
    }
}

.vehicle-track__weight-indicator {
    width: 100%;
    height: 5px;
    margin-bottom: 4px;
    display: flex;
    justify-content: flex-end;
    border-radius: 5px;
    overflow: hidden;

    > div {
        height: 5px;
        margin-bottom: 3px;
        background-color: $color-mediumGrey;
        width: 100%;
    }
}

.vehicle-track__name {
    white-space: nowrap;
}

.vehicle-track--vehicle-layout-horizontal {
    .vehicle-track__item {
        flex-flow: row nowrap;
    }

    .vehicle-track__icon {
        height: 26px;
    }

    .vehicle-track__info {
        margin-left: 10px;
        margin-top: 8px;
    }
}
</style>

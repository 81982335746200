<template>
    <div>
        <div
            v-if="productImage"
            class="overview-card__product-image"
            :style="{ 'background-image': `url(${productImage})` }"
        >
            &nbsp;
        </div>
        <div class="bg-surface px-4 pb-6 pt-4">
            <div class="flex items-center">
                <span class="font-copy-sm-strong">
                    {{ orderType }}
                </span>
                <SfTag class="ml-auto" :type="orderStatusTagType">
                    {{ $t(orderStatus) }}
                </SfTag>
            </div>
            <div class="mt-12 flex items-center">
                <span v-if="!isDisposal && !isShipment" class="font-headline-xl-strong whitespace-nowrap">
                    {{ $n(lineItemGroup.qty / 1000, 'qty') }} {{ $t(`units.ton`) }}
                </span>
                <div v-if="isDisposal" class="flex items-center">
                    <DisposalOrderTitle
                        font-size="xl"
                        is-heading
                        :is-hazardous="lineItemGroup.product.isDangerous"
                        :waste-code="lineItemGroup.product.wasteCode"
                        :pollution-type-label="pollutionTypeLabel"
                        :quantity-in-kg="lineItemGroup.qty"
                    />
                </div>
                <div v-if="isShipment" class="flex items-center">
                    <span class="font-headline-xl-strong"> {{ totalTransportAmount }} &times; {{ vehicleName }} </span>
                    <SfTag
                        v-if="lineItemGroup.freightType"
                        :theme="isDangerousWaste ? 'dark' : 'light'"
                        :type="isDangerousWaste ? 'error' : 'default'"
                        :subdued="isDangerousWaste"
                        :outline="!isDangerousWaste"
                        :action="false"
                        class="ml-4 whitespace-nowrap"
                    >
                        {{ $t(`productTypes.tags.${lineItemGroup.freightType}`) }}
                    </SfTag>
                </div>
                <span
                    v-if="!isDisposal && !isShipment"
                    class="font-headline-xl-strong ml-4"
                    v-html="formattedProductName"
                />
            </div>
            <span v-if="isDisposal" class="font-copy-md mt-2" v-html="formattedProductName" />
            <span v-if="isShipment" class="font-copy-md mt-2">
                {{ lineItemGroup.freightDescription }}
            </span>

            <HorizontalDivider size="6" />

            <div v-if="isDisposal || isDelivery || isPickup || isShipment" class="flex items-center">
                <span v-if="showStartFirstPickupTransportLabel && isPickup" class="font-copy-md">
                    {{ $t('pages.order.orderView.overview.startFirstTransport') }}
                </span>
                <template v-else>
                    <template v-if="!isShipment">
                        <SfMaterialIcon size="sm" multi-color />
                        <div class="ml-4 flex flex-col">
                            <span class="font-copy-md-strong whitespace-nowrap">
                                {{ $n(completedAmount / 1000, 'qty') }} {{ $t(`units.ton`) }}
                            </span>
                            <span v-if="isDisposal" class="font-copy-md">
                                {{ $t('components.projectPositionSelectionBlock.stat.disposal.delivered') }}
                            </span>
                            <span v-else-if="isDelivery" class="font-copy-md">
                                {{ $t('pages.order.orderView.overview.supplied') }}
                            </span>
                            <span v-else class="font-copy-md">
                                {{ $t('pages.order.orderView.overview.pickupSupplied') }}
                            </span>
                        </div>
                    </template>

                    <template v-if="!isPickup">
                        <SfVehicleIcon class="mb-" :class="{ 'ml-12': !isShipment }" size="sm" multi-color />
                        <div class="ml-4 flex flex-col">
                            <span class="font-copy-md-strong">
                                {{
                                    $t('pages.order.orderView.overview.noOfTransportsCompleted', {
                                        completed: completedTransportAmount,
                                        total: totalTransportAmount,
                                    })
                                }}
                            </span>
                            <span class="font-copy-md">{{ $t('pages.order.orderView.overview.completed') }}</span>
                        </div>
                    </template>
                </template>
            </div>

            <SfButton
                v-if="$can('createPickupTransport', lineItemGroup)"
                class="mt-8 w-full"
                size="sm"
                @click="startPickup()"
            >
                <template #left>
                    <PlusIcon width="16" height="16" />
                </template>

                {{ $t('pages.order.orderView.actions.startPickup') }}
            </SfButton>
        </div>
    </div>
</template>

<script>
import _get from 'lodash/get';
import { revertLocalizedValue } from '@/services/utils/localization';
import routeContext from '@/plugins/mixins/routeContext';
import { CONTEXT_PLATFORM } from '@/constants/context';

import { SfTag, SfButton, SfMaterialIcon, SfVehicleIcon } from '@schuettflix/vue-components';
import HorizontalDivider from '@/_components/HorizontalDivider/HorizontalDivider.vue';
import DisposalOrderTitle from '@/_components/DisposalOrderTitle/DisposalOrderTitle';

import PlusIcon from '@/assets/icons/regular/plus.svg';
import { isOrderLineItemGroupTypeDisposal } from '@/constants/disposal';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';
import { formatProductNameWithZeroWidthSpace } from '@/pages/SupplierProducts/utils';

export default {
    name: 'OverviewCard',

    components: {
        HorizontalDivider,
        DisposalOrderTitle,

        PlusIcon,
        SfButton,
        SfMaterialIcon,
        SfTag,
        SfVehicleIcon,
    },

    mixins: [routeContext],

    props: {
        lineItemGroup: {
            type: Object,
            required: true,
        },

        orderId: {
            type: [String, Number],
            required: true,
        },
    },

    computed: {
        isDangerousWaste() {
            return this.lineItemGroup.freightType === 'waste_dangerous';
        },

        pollutionTypeLabel() {
            return this.lineItemGroup?.productVariant?.pollutionType?.label;
        },

        isDisposal() {
            return isOrderLineItemGroupTypeDisposal(this.lineItemGroup.type);
        },

        orderStatusTagType() {
            return {
                new: 'warning',
                in_progress: 'warning',
                canceled: 'red',
                closed: 'positive',
            }[this.lineItemGroup.status];
        },

        orderStatus() {
            return {
                new: 'pages.order.orderStatus.new',
                in_progress: 'pages.order.orderStatus.in_progress',
                canceled: 'pages.order.orderStatus.canceled',
                cancelled: 'pages.order.orderStatus.cancelled',
                pending: 'pages.order.orderStatus.pending',
                rejected: 'pages.order.orderStatus.rejected',
                closed: 'pages.order.orderStatus.closed',
            }[this.lineItemGroup.status];
        },

        isPlatform() {
            return this.inRouteContext(CONTEXT_PLATFORM);
        },

        orderType() {
            return this.$t(`pages.order.orderType.${this.lineItemGroup.type}`);
        },

        isPickup() {
            return this.lineItemGroup.type === ORDER_LINE_ITEM_GROUP_TYPE.PICKUP;
        },

        isDelivery() {
            return this.lineItemGroup.type === ORDER_LINE_ITEM_GROUP_TYPE.DELIVERY;
        },

        isShipment() {
            return this.lineItemGroup.type === ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT;
        },

        completedAmount() {
            return this.lineItemGroup.lineItems.reduce((total, currentLineItem) => {
                if (['delivered', 'confirmation_pending'].includes(currentLineItem.transportStatus)) {
                    return total + currentLineItem['loadedQty'];
                }
                return total;
            }, 0);
        },

        totalAmount() {
            return this.lineItemGroup.lineItems.reduce((total, currentLineItem) => {
                return total + currentLineItem.qty;
            }, 0);
        },

        totalTransportAmount() {
            return this.lineItemGroup.lineItems.length;
        },

        completedTransportAmount() {
            return this.lineItemGroup.lineItems.reduce((total, currentLineItem) => {
                if (currentLineItem.status === 'closed') return total + 1;
                return total;
            }, 0);
        },

        showStartFirstPickupTransportLabel() {
            return this.$can('createPickupTransport', this.lineItemGroup) && this.totalTransportAmount === 0;
        },

        productImage() {
            return _get(this.lineItemGroup, 'product.image.url.medium', null);
        },

        vehicleName() {
            return this.lineItemGroup.lineItems[0].vehicleName;
        },

        formattedProductName() {
            const localized = this.revertLocalizedValue(this.lineItemGroup.product.productName);
            return this.formatProductNameWithZeroWidthSpace(localized);
        },
    },

    methods: {
        revertLocalizedValue,

        formatProductNameWithZeroWidthSpace,

        startPickup() {
            this.$router
                .push({
                    name: this.$root.findRouteName('order-pickup-start'),
                    params: { orderId: this.orderId },
                })
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss">
.overview-card {
    .freight-type-tag {
        margin-left: 5px;
    }
}
.overview-card__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.overview-card__info-box {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.overview-card__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.overview-card__new-pickup-button {
    margin: 20px 0px 0px 0px;
}

.overview-card__product-image {
    height: 150px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: $screen-md) {
        height: 160px;
    }
}
</style>

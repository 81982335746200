import { toKgPrice, toTonPrice } from '@/services/utils/units';
import _isNull from 'lodash/isNull';
import { countDecimals } from '@/services/utils';

export default class WastePricingView {
    constructor() {
        this._purchaseUnitPrice = null;
        this._retailUnitPrice = null;
        this._retailUnitPriceWithStateWasteCompanyFee = null;
        this._qty = null; // in kg
    }

    /**
     * Create a view model for waste pricing
     *
     * @param data
     * @param qty
     * @return {WastePricingView|null}
     */
    static create(data, qty) {
        if (!data) return null;

        const view = new WastePricingView();

        let purchaseUnitPriceInTons = toTonPrice(data?.purchaseUnitPrice);
        let retailUnitPriceInTons = toTonPrice(data?.retailUnitPrice);

        if (countDecimals(purchaseUnitPriceInTons) > 2) {
            purchaseUnitPriceInTons = purchaseUnitPriceInTons.toFixed(2);
        }

        if (countDecimals(retailUnitPriceInTons) > 2) {
            retailUnitPriceInTons = retailUnitPriceInTons.toFixed(2);
        }

        view.purchaseUnitPrice = purchaseUnitPriceInTons;
        view.retailUnitPrice = retailUnitPriceInTons;
        view.retailUnitPriceWithStateWasteCompanyFee = toTonPrice(data?.retailUnitPriceWithStateWasteCompanyFee);
        view.qty = qty;

        return view;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            purchaseUnitPrice: !_isNull(this.purchaseUnitPrice) ? toKgPrice(this.purchaseUnitPrice) : null,
            retailUnitPrice: !_isNull(this.retailUnitPrice) ? toKgPrice(this.retailUnitPrice) : null,
            retailUnitPriceWithStateWasteCompanyFee: !_isNull(this.retailUnitPriceWithStateWasteCompanyFee)
                ? toKgPrice(this.retailUnitPriceWithStateWasteCompanyFee)
                : null,
            qty: this.qty,
        };
    }

    get purchaseUnitPrice() {
        return this._purchaseUnitPrice;
    }

    set purchaseUnitPrice(value) {
        this._purchaseUnitPrice = value;
    }

    get retailUnitPrice() {
        return this._retailUnitPrice;
    }

    set retailUnitPrice(value) {
        this._retailUnitPrice = value;
    }

    get qty() {
        return this._qty;
    }

    set qty(value) {
        this._qty = value;
    }

    get retailUnitPriceWithStateWasteCompanyFee() {
        return this._retailUnitPriceWithStateWasteCompanyFee;
    }

    set retailUnitPriceWithStateWasteCompanyFee(value) {
        this._retailUnitPriceWithStateWasteCompanyFee = value;
    }

    // Getter --------------------

    get purchasePriceTotal() {
        return this.purchaseUnitPrice * (this.qty / 1000);
    }

    get retailPriceTotal() {
        return this.retailUnitPrice * (this.qty / 1000);
    }
}

<template>
    <LayoutPage
        is-flyout
        class="checkout-price-adjustments"
        :close-by-x="isProjectPositionEditMode"
        @close="closeFlyout()"
    >
        <template v-if="isProjectPositionEditMode" #pageTitle>
            {{ $t('pages.checkout.priceAdjustments.titleChangePrices') }}
        </template>
        <template v-if="!isProjectPositionEditMode" #flyoutHeader>
            <FlyoutHeader v-if="!isProjectPositionEditMode" />
        </template>

        <div v-if="!current" class="container-off-canvas-sm my-6">
            <LoadingCard />
        </div>

        <transition name="fade">
            <div v-if="current" class="container-off-canvas-sm my-6" data-test="project-vehicle-class-list">
                <DisposalCostSection
                    v-if="hasDisposalCost"
                    class="mb-8"
                    :is-price-per-transport="state.shipmentBillingDetails.billingMethod === 'fixedPrice'"
                    :value="state.disposalPrice"
                    @input="$emit('set-disposal-price', $event)"
                />

                <slot name="paymentTerms" />

                <slot name="billingMethod" />

                <slot name="productCosts" :edit-section="editSection" :error="getPricesErrors" />

                <slot name="transports" :edit-section="editSection" />

                <slot name="customPosition" :edit-section="editSection" />

                <slot name="materialTransport">
                    <MaterialTransportSection
                        v-if="basketType === 'delivery'"
                        class="mt-12"
                        :current="current"
                        :transport-distance="prices.distance"
                        :transport-duration="prices.duration"
                    />
                </slot>

                <slot name="shipping">
                    <ShippingSection
                        v-if="current.shipping && current.shipping.shippingMethod === 'flix'"
                        class="mt-12"
                        :shipping="current.shipping"
                        :show-totals="prices.type !== prices.constructor.TYPE_PROJECT_DELIVERY"
                        @edit="editSection('shipping')"
                    />
                </slot>

                <slot name="costPreview" />

                <slot name="totals" />

                <SfMessageBox
                    v-if="isProjectPositionEditMode"
                    class="mt-6"
                    :headline="$t('pages.checkout.shipmentBillingDetails.hintLabel')"
                    type="warning"
                >
                    {{ $t('pages.checkout.priceAdjustments.changeProjectPositionPricesHint') }}
                </SfMessageBox>
            </div>
        </transition>

        <SlideUp slot="sticky" :active="hasValidData">
            <ButtonGroup>
                <BaseButton :disabled="isPending()" primary light place-left @click="resetPriceData()">
                    {{ $t('pages.checkout.priceAdjustments.resetData') }}
                </BaseButton>
                <BaseButton
                    v-if="isProjectPositionEditMode"
                    :disabled="isPending()"
                    :is-loading="isPending('saveProjectPosition')"
                    primary
                    @click="saveProjectPosition()"
                >
                    {{ $t('pages.checkout.priceAdjustments.savePricePosition') }}
                </BaseButton>
                <BaseButton
                    v-else
                    :disabled="isPending()"
                    :is-loading="isPending('submit')"
                    primary
                    data-test="check-position-button"
                    @click="submit"
                >
                    {{ submitButtonLabel }}
                </BaseButton>
            </ButtonGroup>
        </SlideUp>

        <template #subpages>
            <Flyout v-if="current" :active="section !== null" size="small" background-grey @closed="closeSection">
                <HeaderBar slot="header">
                    <div v-if="section" slot="headline">
                        <span v-if="section === 'transport' && hasEnabledTransport">
                            {{ $t(`pages.checkout.priceAdjustments.transport.changeLabel`) }}
                        </span>
                        <span v-else-if="section === 'transport' && !hasEnabledTransport">
                            {{ $t(`pages.checkout.priceAdjustments.transport.addLabel`) }}
                        </span>
                        <span v-else-if="section === 'waste'">
                            {{ $t(`pages.checkout.priceAdjustments.waste.changeLabel`) }}
                        </span>
                        <span v-else-if="section === 'shipping'">
                            {{ $t(`pages.checkout.priceAdjustments.shipping.changeLabel`) }}
                        </span>
                        <span v-else-if="section === 'customPosition'">
                            {{ $t(`pages.checkout.priceAdjustments.waste.addCustomPositions`) }}
                        </span>
                        <span v-else-if="section === 'material'">
                            {{ $t(`pages.checkout.priceAdjustments.material.changeLabel`) }}
                        </span>
                        <span v-else>
                            {{ $tc(`pages.checkout.priceAdjustments.${section}.addLabel`, 0) }}
                        </span>
                    </div>
                    <template #right>
                        <HeaderBarItem button @click="closeSection">
                            <CloseIcon width="18" height="18" />
                        </HeaderBarItem>
                    </template>
                </HeaderBar>

                <div class="container-off-canvas-sm my-6">
                    <slot name="editMaterial" />

                    <slot name="editWaste" />

                    <slot name="editTransports" />

                    <slot name="editShipping" />

                    <slot name="editCustomPosition" />
                </div>

                <template #bottom>
                    <ButtonGroup>
                        <BaseButton
                            :disabled="isPending() || !canSaveSubpage"
                            primary
                            data-test="submit-price-adjustment-button"
                            @click="saveSection"
                        >
                            <span>
                                {{ $t('pages.checkout.priceAdjustments.submitData') }}
                            </span>
                        </BaseButton>
                    </ButtonGroup>
                </template>
            </Flyout>
        </template>
    </LayoutPage>
</template>

<script>
import statefulMixin from '@/plugins/mixins/statefulMixin';
import {
    TRANSPORT_FREIGHT_TYPE_WASTE_DANGEROUS,
    TRANSPORT_FREIGHT_TYPE_WASTE_NON_DANGEROUS,
} from '@/constants/transportFreightTypes';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import DisposalCostSection from '@/pages/Checkout/components/PriceAdjustment/DisposalCostSection';
import Flyout from '@/components/Layout/Flyout';
import FlyoutHeader from './components/Header';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import LayoutPage from '@/components/Layout/Page.v2';
import MaterialTransportSection from '@/pages/Checkout/components/PriceAdjustment/MaterialTransportSection';
import ShippingSection from '@/pages/Checkout/components/PriceAdjustment/ShippingSection';
import SlideUp from '@/components/Animation/SlideUp';
import validate from '@/services/validation/mixin';
import { mapGetters, mapState } from 'vuex';

import CloseIcon from '@/assets/icons/regular/close.svg';
import DisposalPrice from '@/services/Api/Platform/DisposalPrice';
import LoadingCard from '@/pages/Checkout/Disposal/ProjectPosition/Partials/LoadingCard';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';

import { SfMessageBox } from '@schuettflix/vue-components';

export default {
    name: 'CustomPriceAdjustmentsPage',
    components: {
        LoadingCard,
        BaseButton,
        ButtonGroup,
        DisposalCostSection,
        Flyout,
        FlyoutHeader,
        HeaderBar,
        HeaderBarItem,
        LayoutPage,
        MaterialTransportSection,
        ShippingSection,
        SlideUp,
        // TransportEditSection,

        CloseIcon,

        // Components
        SfMessageBox,
    },
    mixins: [statefulMixin, validate],
    props: {
        projectPositionId: {
            type: [Number, String],
            default: null,
        },
        state: {
            type: Object,
            default: () => ({
                constructionSiteId: null,
                isCustom: null,
                productId: null,
                factoryProductVariantId: null,
                projectId: null,
                shipmentBillingDetails: null,
                shipmentQtyAndDocument: null,
                type: null,
                loadedPricesData: null,
                disposalPrice: null,
            }),
        },
        prices: {
            type: Object,
            default: null,
        },
        wasteMetaData: {
            type: Object,
            default: null,
        },
        section: {
            type: String,
            default: null,
        },
        canSaveSection: {
            type: Boolean,
            default: false,
        },
        isProject: {
            type: Boolean,
            default: false,
        },
        transports: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            projectPosition: null,
            calculationData: null,
            sectionToEdit: null,
        };
    },
    computed: {
        ...mapState('projectPosition', ['requestCollection', 'disposer', 'pricesData']),
        ...mapGetters('projectPosition', {
            validationRulesFromBasket: 'validationRules',
            validationGroups: 'validationGroups',
        }),
        ...mapGetters('basket', ['isDisposal']),
        validationRules() {
            return this.isDisposal ? { ...this.validationRulesFromBasket } : null;
        },

        current() {
            return this.prices?.current;
        },

        original() {
            return this.prices?.original;
        },

        hasCustomPositions() {
            return this.prices?.current?.customPositions?.collection?.length > 0;
        },

        hasEnabledTransport() {
            return this.prices?.original?.enabledTransports?.length > 0;
        },

        basketType() {
            return this.isProjectPositionEditMode ? BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DELIVERY : this.state.type;
        },

        isProjectPositionEditMode() {
            return !!this.projectPositionId;
        },

        hasValidData() {
            if (this.isProject) {
                return this.basketType === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DELIVERY
                    ? this.prices?.current?.enabledTransports?.length > 0
                    : this.transports?.collection?.some(t => t.enabled === true);
            }

            return true;
        },

        canSaveSubpage() {
            /* currently only the customPosition subpage is validated */
            if (this.section === 'customPosition') {
                return this.canSaveSection;
            }

            return true;
        },

        hasDisposalCost() {
            const isShipment = this.basketType === 'shipment';
            const hasWasteFreight = [
                TRANSPORT_FREIGHT_TYPE_WASTE_NON_DANGEROUS,
                TRANSPORT_FREIGHT_TYPE_WASTE_DANGEROUS,
            ].includes(this.state.shipmentQtyAndDocument?.type);
            return this.state.isCustom && isShipment && hasWasteFreight && !this.isProjectPositionEditMode;
        },

        getPricesErrors() {
            if (this.isDisposal) {
                return (
                    this.getError('pricesData.product.originalPrices.purchaseUnitPrice') ||
                    this.getError('pricesData.product.originalPrices.retailUnitPrice') ||
                    this.getError('pricesData.product.customPrices.purchaseUnitPrice') ||
                    this.getError('pricesData.product.customPrices.retailUnitPrice')
                );
            }

            return (
                this.getError('prices.current.material.purchaseUnitPrice') ||
                this.getError('prices.current.material.retailUnitPrice') ||
                this.getError('prices.original.material.purchaseUnitPrice') ||
                this.getError('prices.original.material.retailUnitPrice')
            );
        },
        submitButtonLabel() {
            return this.isProject
                ? this.$t('components.pagination.next')
                : this.$t('pages.checkout.priceAdjustments.submitData');
        },
    },
    async mounted() {
        await this.refreshPriceData();
    },
    methods: {
        async refreshPriceData() {
            if (!this.state.isCustom && !this.isProjectPositionEditMode) return;

            if (this.isProjectPositionEditMode) {
                this.$emit('edit-project-position', this.projectPositionId);
                return;
            }

            if (this.isProject) {
                this.$emit('calculate-project-position', this.projectPositionId);
                return;
            }

            this.$emit('calculate-custom-order', this.projectPositionId);
        },

        async resetPriceData() {
            this.$emit('reset-price-data');
        },

        closeSection() {
            this.$emit('close-section');
        },

        editSection(section) {
            this.$emit('edit-section', section);
        },

        async saveSection() {
            this.$emit('save-section');
        },

        async removeCustomPosition({ vid, cid }) {
            this.current.customPositions.get(vid).removePosition(cid);
            this.$emit('save-prices-data');
        },

        getCustomPositionsPositionsLength(vid) {
            return this.current?.customPositions?.[vid]?.positions?.length;
        },

        async submit() {
            if (!this.isValid()) return;

            this.$emit('submit', { hasValidData: this.hasValidData, hasDisposalCost: this.hasDisposalCost });
        },

        async saveProjectPosition() {
            this.$emit('save-project-position');
        },

        closeFlyout() {
            if (!this.isProjectPositionEditMode) return;
            this.$emit('close');
        },

        async updateDisposalPrice() {
            if (!this.hasDisposalCost) return;

            await DisposalPrice.updateQuote(
                this.state.quote.id,
                this.state.disposalPrice,
                this.state.shipmentBillingDetails.billingMethod === 'fixedPrice'
            );
        },
    },
};
</script>

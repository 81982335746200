import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useVuexState } from '@/reactBridge/useVuex';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import store from '@/store';
import { getAttributeOptionsAvailable, getProductByAttributes } from '@/pages/Checkout/queries';
import LoadingSpinner from '@/pages/CertificateManagement/components/LoadingSpinner';
import { AdministrationIcon, ChevronRightIcon, MaterialIcon } from '@schuettflix/icons-react';
import { findRouteName } from '@/pages/routerUtils';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { Button } from '@schuettflix/react-components';
import { attributeSetIconMapping } from '@/pages/Checkout/components/AttributeSetIconMapping';
import { useAbility } from '@/reactBridge/useAbility';
import { FolderIcon } from '@/pages/Checkout/components/FolderIcon';
import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
import clsx from 'clsx';
import { Flyout } from '@/constructionProjects/components/Flyout';
import { useOrderScreenName } from '@/pages/Checkout/analytics/react/useOrderScreenName';
import { difference, sortBy } from 'lodash';
import { GRAIN_SIZE_TYPE, ProductAttributeType } from '@/services/ProductCatalog/constants';
import { trackCheckoutEvent } from '@/pages/Checkout/trackCheckoutEvent';
import { differenceInDays } from 'date-fns';
const ProductLabel = ({ label }) => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const lastOrderedAt = vueRouter.currentRoute.query.lastOrderedAt;
    const daysCount = lastOrderedAt ? calculateDaysDifference(Number(lastOrderedAt)) : null;
    return (<>
            <div className="mt-6 grid grid-cols-[auto_1fr] grid-rows-[1fr_auto] items-center gap-2">
                <MaterialIcon size="sm" className="m-2 shrink-0"/>
                <p className="font-headline-xl-strong">{label}</p>
            </div>
            {daysCount !== null && (<p className="ml-12">
                    {daysCount === 0 &&
                t('pages.checkout.recentProducts.definitionPage.lastOrder.label.zero', { count: daysCount })}
                    {daysCount !== 0 &&
                t('pages.checkout.recentProducts.definitionPage.lastOrder.label', { count: daysCount })}
                </p>)}
        </>);
};
function calculateDaysDifference(timestamp) {
    const currentTime = new Date();
    const orderDate = new Date(timestamp * 1000);
    return Math.abs(differenceInDays(orderDate, currentTime));
}
const DefinitionItemStatic = ({ icon, title, description, onClick }) => {
    const isClickable = !!onClick;
    return (<div className={clsx('-mx-4 flex items-center justify-between px-4 py-4 last:mb-4', isClickable && 'cursor-pointer hover:bg-surface-hovered')} onClick={onClick}>
            <div className="flex items-center gap-2">
                {icon || <AdministrationIcon size="sm" className="m-2"/>}
                <div className="flex flex-col gap-1">
                    <p className="font-copy-md-strong">{title}</p>
                    <p className={clsx(isClickable ? 'font-copy-md-strong text-red-800' : 'text-subdued ')}>
                        {description}
                    </p>
                </div>
            </div>
            {isClickable && <ChevronRightIcon size="xs" className="m-2"/>}
        </div>);
};
const DefinitionItem = ({ data, onClick }) => {
    const { t } = useTranslation();
    const isAttributeSelected = data.type === 'range' ? !!data.attributeRange : !!data.attribute?.attributeValues?.length;
    const isAvailable = data.availableAttributesCount > 0;
    const isClickable = !!onClick && isAvailable;
    const iconEntry = attributeSetIconMapping.find(icon => icon.key === data?.attributeSetKey);
    const icon = (isClickable ? iconEntry?.icon : iconEntry?.iconDisabled) || (<AdministrationIcon size="sm" className="m-2" disabled={!isClickable}/>);
    let description = '';
    if (!isAttributeSelected) {
        description =
            data.availableAttributesCount < 1
                ? t('pages.checkout.recentProducts.configurationPage.availabilityOptions.unavailable')
                : t('pages.checkout.recentProducts.configurationPage.availabilityOptions.amount', {
                    count: data.availableAttributesCount,
                });
    }
    else {
        description =
            data.type === 'range'
                ? `${data.attributeRange?.min}/${data.attributeRange?.max} ${data.attributeRange?.unit}`
                : data.attribute?.attributeValues?.map(item => item.attributeValueLabel)?.join(', ') || '';
    }
    return (<div className={clsx('-mx-4 flex items-center justify-between px-4 py-4 last:mb-4', isClickable && 'cursor-pointer hover:bg-surface-hovered')} onClick={() => onClick?.()}>
            <div className="flex items-center gap-2">
                {icon}
                <div className="flex flex-col gap-1">
                    <p className={clsx(isClickable ? 'font-copy-md-strong' : 'text-disabled')}>
                        {data.attributeSetLabel}
                    </p>
                    <p className={clsx(isAttributeSelected ? 'font-copy-md-strong text-red-800' : 'text-subdued ')}>
                        {description}
                    </p>
                </div>
            </div>
            {isClickable && (<ChevronRightIcon size="xs" className={clsx('m-2', { 'fill-icon-on-secondary-disabled': !isClickable })}/>)}
        </div>);
};
const DefinitionView = ({ label, categories, availableOptions, attributes, attributeRange, onAttributeSelect, }) => {
    const { t } = useTranslation();
    const categoriesLabels = categories?.map(category => category.label) || [];
    const selectAttribute = (attributeSetId) => {
        onAttributeSelect(attributeSetId);
    };
    const mappedAttributes = useMemo(() => {
        const result = availableOptions?.map(option => {
            const selectedAttribute = attributes?.find(attribute => attribute.attributeSetId === option.attributeSetId) || null;
            let type = 'single_choice';
            let range;
            switch (option.attributeSetKey) {
                case ProductAttributeType.COLOR:
                    type = 'color';
                    break;
                case GRAIN_SIZE_TYPE:
                    type = 'range';
                    range = attributeRange;
                    break;
                default:
                    break;
            }
            return {
                attribute: selectedAttribute,
                attributeRange: range,
                type,
                displayOrder: option.displayOrder,
                availableAttributesCount: option.availableAttributesCount,
                attributeSetLabel: option.attributeSetLabel,
                attributeSetId: option.attributeSetId,
                attributeSetKey: option.attributeSetKey,
            };
        }) || [];
        return sortBy(result, 'displayOrder');
    }, [attributes, availableOptions, attributeRange]);
    return (<>
            <ProductLabel label={label}/>
            <DefinitionItemStatic icon={<FolderIcon className="m-2"/>} title={t('pages.checkout.recentProducts.definitionPage.category.title')} description={categoriesLabels.join(', ')}/>

            {mappedAttributes?.map(entry => {
            return (<DefinitionItem data={entry} key={entry?.attributeSetKey} onClick={entry.availableAttributesCount > 0
                    ? () => selectAttribute(entry.attributeSetId || null)
                    : undefined}/>);
        })}
        </>);
};
const ProductAvailability = ({ price: minimumPrice, sellers: sellerCount }) => {
    const { t } = useTranslation();
    let canSeePrices = useAbility('seePrices');
    const { formatPerTon } = useMoneyFormatter();
    if (!minimumPrice) {
        canSeePrices = false;
    }
    const formattedPrice = canSeePrices ? formatPerTon(minimumPrice) : '';
    return (<div className="font-copy-md flex h-[52px] items-center justify-center bg-surface px-2 py-4 text-center" data-test="price-seller-count">
            {canSeePrices && (<span dangerouslySetInnerHTML={{
                __html: t('pages.checkout.recentProducts.definitionPage.productAvailability.priceAndCount', {
                    formattedPrice,
                    count: sellerCount,
                }),
            }}/>)}
            {!canSeePrices && (<span dangerouslySetInnerHTML={{
                __html: t('pages.checkout.recentProducts.definitionPage.productAvailability.count', {
                    count: sellerCount,
                }),
            }}/>)}
        </div>);
};
const AvailableOptionsList = ({ attributeSetId, attributeSetLabel, productConfiguration, onOptionSelect, }) => {
    const canSeePrices = useAbility('seePrices');
    const orderType = useVuexState(store, state => state.basket.type);
    const locationId = useVuexState(store, state => state.basket.constructionSite?.constructionProjectLocationId);
    const masterProductId = useVuexState(store, state => state.basket.product.masterProductId);
    const attributeRange = useVuexState(store, state => state.basket.product.attributeRange);
    const attributes = useVuexState(store, state => state.basket.product.attributeIds);
    const product = useVuexState(store, state => state.basket.product);
    const analyticsPayload = useVuexState(store, state => state.analytics.productSelection || {});
    const { data: availableAttributeSet, isLoading } = useQuery({
        ...getAttributeOptionsAvailable(orderType, locationId, masterProductId, attributeRange, attributes, attributeSetId),
    });
    const unselectOption = () => {
        let dataToUpdate = {};
        if (availableAttributeSet?.attributeSetType === 'color') {
            const idsToRemove = productConfiguration?.attributes
                .filter(attribute => attribute.attributeSetId === attributeSetId)
                .map(attribute => attribute.attributeValues.map(value => value.id))
                .flat() || [];
            const idsToKeep = difference(product.attributeIds, idsToRemove);
            dataToUpdate = {
                attributeIds: idsToKeep,
            };
        }
        if (availableAttributeSet?.attributeSetType === 'single_choice') {
            const idsToRemove = productConfiguration?.attributes
                .filter(attribute => attribute.attributeSetId === attributeSetId)
                .map(attribute => attribute.attributeValues[0].id) || [];
            const idsToKeep = difference(product.attributeIds, idsToRemove);
            dataToUpdate = {
                attributeIds: idsToKeep,
            };
        }
        if (availableAttributeSet?.attributeSetType === 'range') {
            dataToUpdate = {
                attributeRange: undefined,
            };
        }
        const newProduct = {
            ...product,
            ...dataToUpdate,
        };
        store.commit('basket/setProduct', newProduct);
        trackOptionChange('none');
        onOptionSelect();
    };
    const selectNewOption = (option) => {
        let dataToUpdate = {};
        let trackingOptionValue = '';
        if (availableAttributeSet?.attributeSetType === 'range' && 'min' in option) {
            dataToUpdate = {
                attributeRange: {
                    min: option.min,
                    max: option.max,
                    unit: option.unit,
                },
            };
            trackingOptionValue = `${option.min}/${option.max} ${option.unit}`;
        }
        if (availableAttributeSet?.attributeSetType === 'color' && 'attributeValueColor' in option) {
            const idsToRemove = productConfiguration?.attributes
                .filter(attribute => attribute.attributeSetId === attributeSetId)
                .map(attribute => attribute.attributeValues.map(value => value.id))
                .flat() || [];
            const idsToKeep = difference(product.attributeIds, idsToRemove);
            dataToUpdate = {
                attributeIds: [...idsToKeep, ...option.attributeValueColor.map(color => color.attributeValueId)],
            };
            trackingOptionValue = option.attributeValueColor.map(color => color.attributeValueLabel).join(' / ');
        }
        if (availableAttributeSet?.attributeSetType === 'single_choice' && 'attributeValueId' in option) {
            const idsToRemove = productConfiguration?.attributes
                .filter(attribute => attribute.attributeSetId === attributeSetId)
                .map(attribute => attribute.attributeValues[0].id) || [];
            const idsToKeep = difference(product.attributeIds, idsToRemove);
            dataToUpdate = {
                attributeIds: [...idsToKeep, option.attributeValueId],
            };
            trackingOptionValue = option.attributeValueLabel;
        }
        const newProduct = {
            ...product,
            ...dataToUpdate,
        };
        store.commit('basket/setProduct', newProduct);
        trackOptionChange(trackingOptionValue);
        onOptionSelect();
    };
    const trackOptionChange = (value) => {
        trackCheckoutEvent('product', 'product_configuration', {
            ...(analyticsPayload?.searchTerm ? { searchTerm: analyticsPayload?.searchTerm } : {}),
            ...(analyticsPayload?.selected_by ? { selected_by: analyticsPayload?.selected_by } : {}),
            attribute_name: attributeSetLabel,
            attribute_value: value,
            master_product_id: productConfiguration?.masterProductId,
            product_name: product?.name?.en || productConfiguration?.masterProductName,
        });
    };
    const compareColor = (option, product) => {
        const colorsListOption = option.attributeValueColor?.map(color => color.attributeValueId.toLowerCase()) || [];
        colorsListOption.sort();
        const productColorsList = product.attributes
            .find(attribute => attribute.attributeSetId === attributeSetId)
            ?.attributeValues?.map(attribute => attribute.id.toLowerCase()) || [];
        productColorsList.sort();
        return colorsListOption.join('') === productColorsList.join('');
    };
    const compareRange = (option, product) => {
        return (product.attributeRange?.min === option.min &&
            product.attributeRange?.max === option.max &&
            product.attributeRange?.unit === option.unit);
    };
    const compareSingleChoice = (option, product) => {
        return !!product.attributes.find(attribute => {
            return option.attributeValueId === attribute.attributeValues[0].id;
        });
    };
    const isOptionSelected = (option) => {
        if (!productConfiguration) {
            return false;
        }
        switch (availableAttributeSet?.attributeSetType) {
            case 'color':
                return compareColor(option, productConfiguration);
            case 'range':
                return compareRange(option, productConfiguration);
            case 'single_choice':
                return compareSingleChoice(option, productConfiguration);
            default:
                return false;
        }
    };
    return isLoading || !availableAttributeSet ? (<LoadingSpinner spaced/>) : (<div className="py-2">
            {availableAttributeSet?.options?.map(option => {
            const selected = isOptionSelected(option);
            let key = '';
            if ('attributeValueId' in option) {
                key = option.attributeValueId;
            }
            if ('min' in option) {
                key = `${option.min}-${option.max}-${option.unit}`;
            }
            if ('attributeValueColor' in option) {
                key = option.attributeValueColor.map(color => color.attributeValueId).join('-');
            }
            return (<AvailableOption option={option} key={key} selected={selected} showPrice={canSeePrices} onSelect={() => (selected ? unselectOption() : selectNewOption(option))}/>);
        })}
        </div>);
};
const AvailableOption = ({ option, selected, onSelect, showPrice }) => {
    const { t } = useTranslation();
    const { formatPerTon } = useMoneyFormatter();
    let label = '';
    if ('unit' in option) {
        //Range
        label = `${option.min}/${option.max} ${option.unit}`;
    }
    else if ('attributeValueColor' in option) {
        //Color
        const colors = option.attributeValueColor.map(color => color.attributeValueLabel);
        label = colors.length ? colors.join(' / ') : t('pages.checkout.recentProducts.configurationPage.colorSetup');
    }
    else {
        // Single choice
        label = option.attributeValueLabel;
    }
    const onOptionClick = () => {
        onSelect(option);
    };
    return (<div className={clsx('border-1 drop-shadow-light mt-4 flex h-[68px] max-w-full cursor-pointer items-center justify-between rounded border bg-surface p-4', {
            'bg-surface-active': selected,
        })} onClick={() => onOptionClick()}>
            <div className={clsx('font-copy-md-strong line-clamp-2 break-all', selected ? 'text-on-primary' : 'text-interactive ')}>
                {label}
            </div>
            {showPrice && (<div className="pl-2">
                    <div className={clsx('font-copy-sm', selected ? 'text-on-primary' : 'text-subdued ')}>
                        {t('pages.checkout.recentProducts.configurationPage.minimumPrice.from')}
                    </div>
                    <div className={clsx('font-copy-md', { 'text-on-primary': selected })}>
                        {formatPerTon(option.price)}
                    </div>
                </div>)}
        </div>);
};
const _ProductDefinitionPage = () => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const orderType = useVuexState(store, state => state.basket.type);
    const locationId = useVuexState(store, state => state.basket.constructionSite?.constructionProjectLocationId);
    const masterProductId = useVuexState(store, state => state.basket.product?.masterProductId);
    const attributeRange = useVuexState(store, state => state.basket.product?.attributeRange);
    const attributes = useVuexState(store, state => state.basket.product?.attributeIds);
    const analyticsPayload = useVuexState(store, state => state.analytics.productSelection || {});
    const isCategorySelected = useVuexState(store, state => !!state.basket.categoryInfo);
    const masterProductName = useVuexState(store, state => {
        return state.basket.product.name?.en || state.basket.product.masterProductName;
    });
    const { data: product, isLoading } = useQuery({
        ...getProductByAttributes(orderType, locationId, masterProductId, attributeRange, attributes),
    });
    const [selectedAttributeSet, setSelectedAttributeSet] = useState(null);
    const [showFlyout, setShowFlyout] = useState(true);
    const headerTitle = useMemo(() => {
        if (!selectedAttributeSet) {
            return t('pages.checkout.definitionPage.header.title');
        }
        return selectedAttributeSet.attributeSetLabel;
    }, [selectedAttributeSet, t]);
    useEffect(() => {
        if (!selectedAttributeSet) {
            return;
        }
    }, [selectedAttributeSet]);
    const goNextPage = () => {
        store.commit('basket/updateProductFields', {
            density: product?.productConfiguration.density,
        });
        trackCheckoutEvent('product', 'productSelect', {
            ...analyticsPayload,
            master_product_id: masterProductId,
            product_name: masterProductName || product?.productConfiguration?.masterProductName,
            seller_amount: product?.productAvailability?.sellers,
            price: product?.productAvailability?.price,
        });
        return vueRouter.push({ name: findRouteName(vueRouter.currentRoute?.meta?.next) });
    };
    const handleBackButton = () => {
        if (!selectedAttributeSet) {
            setShowFlyout(false);
            // This is a hack to make the back animation work
            setTimeout(() => {
                if (isCategorySelected) {
                    return vueRouter.push({
                        name: findRouteName(vueRouter.currentRoute?.meta?.previousWithCategorySelected),
                    });
                }
                return vueRouter.push({ name: findRouteName(vueRouter.currentRoute?.meta?.previous) });
            }, 300);
            return;
        }
        setSelectedAttributeSet(null);
    };
    const onAttributeSelect = (attributeSetId) => {
        if (!attributeSetId) {
            return;
        }
        const attribute = product?.availableOptions?.find(attribute => attribute.attributeSetId === attributeSetId);
        if (!attribute) {
            return;
        }
        setSelectedAttributeSet(attribute);
    };
    return (<Flyout show={showFlyout} headerTitle={headerTitle} body={<div className="flex h-full flex-col justify-between">
                    {/* CONTENT SECTION */}
                    <div className="flex h-full flex-col overflow-auto bg px-4" data-test="product-attribute-list">
                        {isLoading && <LoadingSpinner spaced/>}

                        {/* Show options list */}
                        {!isLoading && selectedAttributeSet && (<AvailableOptionsList attributeSetId={selectedAttributeSet.attributeSetId} attributeSetLabel={selectedAttributeSet.attributeSetLabel} productConfiguration={product?.productConfiguration || null} onOptionSelect={() => {
                    setSelectedAttributeSet(null);
                }}/>)}

                        {/* Show Product definition */}
                        {!isLoading && !selectedAttributeSet && (<DefinitionView label={product?.productConfiguration?.masterProductName || ''} categories={product?.productConfiguration?.categories} attributeRange={product?.productConfiguration?.attributeRange} availableOptions={product?.availableOptions || []} attributes={product?.productConfiguration?.attributes} onAttributeSelect={onAttributeSelect}/>)}
                    </div>

                    {/* BOTTOM SECTION */}
                    {isLoading && <LoadingSpinner spaced/>}

                    {!isLoading && !selectedAttributeSet && (<>
                            {product?.productAvailability && <ProductAvailability {...product.productAvailability}/>}
                            <Button className="w-full" disabled={isLoading || !product?.productAvailability?.sellers} label={t('pages.checkout.recentProducts.definitionPage.selectButton.label')} onClick={goNextPage} data-test="select-product-button"/>
                        </>)}
                </div>} onCloseAnimationEnd={handleBackButton} onClose={handleBackButton} screenName={useOrderScreenName('productdefinition')}/>);
};
export const ProductDefinitionPage = asVueComponent(_ProductDefinitionPage);
// COMPONENTS SCHEME
//
// <ProductDefinitionPage />
//     - <DefinitionView />
//         - <ProductLabel />
//         - <DefinitionItemStatic />
//         - <DefinitionItem />
//      OR
//     - <AvailableOptionsList />
//         - <AvailableOption />

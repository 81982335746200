<template>
    <RenderlessAddressPrediction
        :value="value"
        :input-id="eid"
        :list-locality="listLocality"
        :focus-street-number="focusStreetNumber && !allowIncompleteAddress"
        :street-less="streetLess"
        :include-state="includeState"
        :include-country="includeCountry"
        :allowed-countries="allowedCountries"
        @input="$emit('input', $event)"
        @plus-code="$emit('plus-code', $event)"
    >
        <div
            slot-scope="{
                inputAttrs,
                inputEvents,
                predictions,
                reset,
                selectPrediction,
                predictionIsStreet,
                predictionHasStreetNumber,
                predictionIsArea,
                highlightedIndex,
                highlightIndex,
                handleBlur,
            }"
            class="enhanced-address-field"
        >
            <SfTextField
                v-bind="inputAttrs"
                :id="eid"
                :class="inputClassName"
                data-test="input-location-address"
                autocomplete="off"
                :readonly="readonly"
                :label="label || String($t('components.addressField.searchLabel'))"
                :placeholder="String($t('components.addressField.searchLabel'))"
                :validation-message="error || (hasError && ' ')"
                @focus="focused"
                @keypress="handleKeypress"
                @keydown="handleKeypress"
                @blur="delayedBlured($event, handleBlur)"
                @input="value => !value && reset()"
                v-on="inputEvents"
            >
                <template #leading-icon="iconProps">
                    <SfReloadIcon v-if="isLoading" v-bind="iconProps" class="animate-spin" />
                    <SfMapMarkerIcon v-else v-bind="iconProps" />
                </template>
            </SfTextField>

            <ul
                v-show="isFocused"
                v-if="predictions && predictions.length > 0"
                class="enhanced-address-field__suggestion-list"
            >
                <li
                    v-for="(prediction, index) in predictions"
                    :key="index"
                    :class="{
                        'enhanced-address-field__suggestion--active': false,
                        'enhanced-address-field__suggestion--highlighted': highlightedIndex === index,
                    }"
                    class="enhanced-address-field__suggestion"
                    @mouseover="highlightIndex(index)"
                    @mousedown="cancelDelayedBlur(allowIncompleteAddress || predictionHasStreetNumber(prediction))"
                    @click.stop.prevent="selectPrediction(prediction, true)"
                    @touchmove="handleTouchMove"
                    @touchend.stop.prevent="
                        handleTouchEnd(() =>
                            selectPrediction(
                                prediction,
                                // trigger street number focus in input field
                                !allowIncompleteAddress && !predictionHasStreetNumber(prediction),

                                // unfocus
                                predictionIsArea(prediction) ||
                                    allowIncompleteAddress ||
                                    predictionHasStreetNumber(prediction)
                            )
                        )
                    "
                >
                    <div class="enhanced-address-field__suggestion-main-part" data-test="address-dropdown">
                        {{ prediction.structured_formatting.main_text }}
                    </div>
                    <div
                        v-if="!noSublines && prediction.structured_formatting.secondary_text"
                        class="enhanced-address-field__suggestion-sub-part"
                    >
                        {{ prediction.structured_formatting.secondary_text }}
                    </div>
                    <template v-if="!noButtons">
                        <Words
                            v-if="predictionIsStreet(prediction) && !allowIncompleteAddress"
                            small
                            bold
                            block
                            spaced-top-small
                        >
                            {{ $t('components.addressField.addStreetNumber') }}
                        </Words>
                        <Words
                            v-else-if="predictionIsArea(prediction) || allowIncompleteAddress"
                            small
                            bold
                            block
                            spaced-top-small
                        >
                            {{ $t('components.addressField.selectOnMap') }}
                        </Words>
                        <Words
                            v-else-if="
                                !predictionIsArea(prediction) &&
                                predictionHasStreetNumber(prediction) &&
                                predictions.length === 1
                            "
                            small
                            bold
                            block
                            spaced-top-small
                        >
                            {{ $t('components.addressField.selectAddress') }}
                        </Words>
                    </template>
                </li>
            </ul>
        </div>
    </RenderlessAddressPrediction>
</template>

<script>
import Words from '@/components/Typography/Words';
import RenderlessAddressPrediction from '@/pages/Login/components/RenderlessAddressPrediction';

import { SfTextField, SfReloadIcon, SfMapMarkerIcon } from '@schuettflix/vue-components';

export default {
    name: 'EnhancedAddressField',
    components: {
        Words,
        RenderlessAddressPrediction,
        SfTextField,
        SfReloadIcon,
        SfMapMarkerIcon,
    },
    props: {
        inputClassName: {
            type: String,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        searchAddress: {
            type: String,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            type: Boolean,
            default: true,
        },
        listLocality: {
            type: Boolean,
            default: false,
        },
        focusInitially: {
            type: Boolean,
            default: false,
        },
        focusStreetNumber: {
            type: Boolean,
            default: false,
        },
        streetLess: {
            type: Boolean,
            default: false,
        },
        noSublines: {
            type: Boolean,
            default: false,
        },
        noButtons: {
            type: Boolean,
            default: false,
        },
        allowIncompleteAddress: {
            type: Boolean,
            default: false,
        },
        includeState: {
            type: Boolean,
            default: false,
        },
        includeCountry: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        dataTest: {
            type: String,
            default: '',
        },
        allowedCountries: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            eid: `el${this._uid}`,
            isFocused: false,
            moveEventCount: 0,
            showPredictions: false,
        };
    },
    mounted() {
        if (this.focusInitially && !this.$root.isIOS) {
            setTimeout(() => {
                const $el = document.getElementById(this.eid);
                $el && $el.focus();
            }, 300);
        }
    },
    methods: {
        focused(e) {
            this.cancelDelayedBlur();
            this.isFocused = !this.readonly;
            this.$emit('focus', e);
        },
        delayedBlured(...args) {
            this.blurTimeout = setTimeout(() => {
                this.blured(...args);
            }, 200);
        },
        cancelDelayedBlur(preventCancel) {
            if (preventCancel) return;
            this.blurTimeout && clearTimeout(this.blurTimeout);
        },
        blured(e, handler) {
            this.isFocused = false;
            handler();
            this.$emit('blur', e);
        },
        handleKeypress(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                this.$emit('done');
            }
        },
        handleTouchEnd(callback) {
            if (this.moveEventCount < 3) {
                callback();
            }
            this.moveEventCount = 0;
        },
        handleTouchMove() {
            this.moveEventCount++;
        },
    },
};
</script>

<style lang="scss">
.enhanced-address-field {
    position: relative;

    /**
    * Fix for old components height
   */
    input[data-test='input-location-address'] {
        height: 70px !important;
    }
}

.enhanced-address-field__suggestion-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 200;
    background-color: $color-white;
    list-style: none;
    padding: 0;
    margin: 0;
    box-shadow: $boxShadow-bottom;
}

.enhanced-address-field__suggestion {
    padding: 15px 10px;
    cursor: pointer;
}

.enhanced-address-field__suggestion--highlighted {
    background-color: $color-lightGrey;
}

.enhanced-address-field__suggestion-sub-part {
    font-size: 10px;
}

.enhanced-address-field__prediction-action {
    margin-top: 5px;
}
</style>

import React from 'react';
export const FolderIcon = ({ className }) => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g clipPath="url(#clip0_6570_73006)">
                <path d="M2 19.997V5H7.885L9.904 7H21.5" stroke="#35343D" strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M14.505 18.983L21.999 11.489V10.5H9.92L2 18V18.999L14.505 18.983Z" stroke="#35343D" strokeWidth="2" strokeMiterlimit="10"/>
            </g>
            <defs>
                <clipPath id="clip0_6570_73006">
                    <rect width="21.9987" height="16.0008" fill="white" transform="translate(1 4)"/>
                </clipPath>
            </defs>
        </svg>);
};

/**
 * @deprectated Use the useRouteContext() composable instead
 */
export default {
    computed: {
        routeContext() {
            return this.$route.matched
                .map(r => r.meta.context)
                .filter(r => !!r)
                .slice(-1)[0];
        },
    },

    methods: {
        inRouteContext(...contexts) {
            return contexts.includes(this.routeContext);
        },
    },
};

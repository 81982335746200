<template>
    <Pane>
        <template #head>
            <strong>GeoLocationTester</strong>
        </template>
        <template #body>
            <Dump :data="trackingStatus" />
        </template>
    </Pane>
</template>

<script>
import Pane from '@/components/Debug/Pane/Pane.vue';
import Dump from '@/components/Debug/Dump/Dump';
import { mapState } from 'vuex';

export default {
    name: 'GeoLocationTester',
    components: {
        Dump,
        Pane,
    },
    computed: {
        ...mapState('tracking', ['isEnabled', 'isInit', 'isAllowed']),

        trackingStatus() {
            return {
                isEnabled: this.isEnabled,
                isInit: this.isInit,
                isAllowed: this.isAllowed,
            };
        },
    },
};
</script>

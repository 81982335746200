<template>
    <div>
        <!-- VARIANTS -->
        <Words spaced-bottom bold block>
            {{ $t(`${i18nKey}.headline`) }}
        </Words>

        <!-- TABLE - START -->
        <GridTable columns="193px 120px 220px auto" class="table">
            <!-- TABLE HEADER -->
            <GridTableHead>
                <Words small bold block>
                    {{ $t(`${i18nKey}.burdening`) }}
                </Words>
                <Words small bold block>
                    {{ $t(`${i18nKey}.pricePerTon`) }}
                </Words>
                <Words small bold block>
                    {{ $t(`${i18nKey}.acceptedQuantityPerDay`) }}
                </Words>
            </GridTableHead>

            <!-- VARIANT -->
            <SupplierWasteProductDetailsVariant
                v-for="(variant, i) in product.variantsDisposal"
                :key="`${i}_${variant.id}`"
                :variant="variant"
                :pollution-types="product.pollutionTypes"
                @update="updateVariant"
                @update-note="openAddNoteModal"
                @delete="clickDeleteWasteVariant"
            />

            <!-- ADD NEW VARIANT -->
            <SupplierWasteProductDetailsAddNewVariant :product="product" @add-new="createNewVariant" />

            <!-- ACTIONS -->
            <SupplierWasteProductDetailsActions
                :product="product"
                :compared-variants-disposal="variantsDisposal"
                @discard="discardWaste()"
                @save="saveWaste()"
                @delete="openDeleteWasteProductModal()"
            />
        </GridTable>
        <SupplierWasteProductDetailsAddNoteModal ref="modalAddNote" v-bind="modal.editNote" @update-note="updateNote" />
        <SupplierWasteProductDetailsDeleteProductModal ref="modalDeleteProduct" @accept="deleteWaste" />
        <SupplierWasteProductDetailsDeletedLastProductVariantModal
            ref="modalDeletedLastProductVariant"
            @accept="deleteWaste"
        />
    </div>
</template>

<script>
import Words from '@/components/Typography/Words';
import _cloneDeep from 'lodash/cloneDeep';
import GridTable from '@/components/Table/GridTable';
import GridTableHead from '@/components/Table/GridTableHead';

import SupplierWasteProductDetailsAddNoteModal from '@/pages/SupplierProducts/components/SupplierWasteProductDetailsAddNoteModal/SupplierWasteProductDetailsAddNoteModal';
import SupplierWasteProductDetailsDeleteProductModal from '@/pages/SupplierProducts/components/SupplierWasteProductDetailsDeleteProductModal/SupplierWasteProductDetailsDeleteProductModal';
import SupplierWasteProductDetailsDeletedLastProductVariantModal from '@/pages/SupplierProducts/components/SupplierWasteProductDetailsDeletedLastProductVariantModal/SupplierWasteProductDetailsDeletedLastProductVariantModal';
import SupplierWasteProductDetailsActions from '@/pages/SupplierProducts/components/SupplierWasteProductDetailsActions/SupplierWasteProductDetailsActions';
import SupplierWasteProductDetailsAddNewVariant from '@/pages/SupplierProducts/components/SupplierWasteProductDetailsAddNewVariant/SupplierWasteProductDetailsAddNewVariant';
import SupplierWasteProductDetailsVariant from '@/pages/SupplierProducts/components/SupplierWasteProductDetailsVariant/SupplierWasteProductDetailsVariant';

export default {
    name: 'SupplierWasteProductDetailsVariantTable',
    components: {
        GridTable,
        GridTableHead,
        Words,
        SupplierWasteProductDetailsAddNoteModal,
        SupplierWasteProductDetailsDeleteProductModal,
        SupplierWasteProductDetailsDeletedLastProductVariantModal,
        SupplierWasteProductDetailsActions,
        SupplierWasteProductDetailsAddNewVariant,
        SupplierWasteProductDetailsVariant,
    },

    props: {
        i18nKey: {
            type: String,
            default: 'pages.products.supplierProductsList.wasteProductDetails',
        },
        product: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            modal: {
                editNote: {
                    variantId: 0,
                    note: null,
                },
            },
            variantsDisposal: _cloneDeep(this.product.variantsDisposal),
        };
    },

    computed: {
        hasVariants() {
            return !!this.variantsDisposal.length;
        },
    },

    methods: {
        async createNewVariant({ pollutionTypeId, pricePerTon, wasteCapacityPerDayInTon }) {
            const response = await this.$store.dispatch('waste/createNewVariant', {
                categoryId: this.categoryId,
                productId: this.product.id,
                newVariant: {
                    pollutionTypeId,
                    isAvailable: true,
                    pricePerTon,
                    wasteCapacityPerDayInTon,
                    note: null,
                },
            });

            // add new variant to internal stored variants
            this.variantsDisposal.push(response);
        },
        clickDeleteWasteVariant({ id }) {
            if (this.product.variantsDisposal.length === 1) this.openDeletedLastWasteVariantProduct();
            else this.deleteWasteVariants([{ id: id }]);
        },
        deleteWaste() {
            const variantsToDelete = [];
            this.product?.variantsDisposal.forEach(variantToDelete =>
                variantsToDelete.push({ id: variantToDelete.id })
            );
            this.deleteWasteVariants(variantsToDelete);
        },
        async deleteWasteVariants(variantIdsToDelete) {
            await this.$store.dispatch('waste/deleteProductVariants', {
                categoryId: this.categoryId,
                productId: this.product.id,
                variants: variantIdsToDelete,
            });
            //Sync the internal stored variants with the state variants, such that the internal variants and state variants
            //are the same to avoid rendering the "discard" changes option.
            this.variantsDisposal = this.product.variantsDisposal;

            const product = _cloneDeep(this.product);
            this.$store.commit('waste/UPDATE_PRODUCT', { product, productId: this.product.id });

            this.$emit('deleted-waste-variants');
        },
        openAddNoteModal({ id, note }) {
            this.modal.editNote.variantId = id;
            this.modal.editNote.note = note;

            this.$nextTick(() => {
                this.$refs.modalAddNote.$refs.addNoteModal.$emit('open');
            });
        },
        async saveWaste() {
            const variants = _cloneDeep(this.product?.variantsDisposal || []);

            const response = await this.$store.dispatch('waste/updateProductVariants', {
                categoryId: this.categoryId,
                productId: this.product.id,
                variants: variants,
            });

            // update responded variants from internal stored variants
            if (Object.prototype.hasOwnProperty.call(response, 'items'))
                this.variantsDisposal = _cloneDeep(response.items);
        },
        discardWaste() {
            const product = {
                ...this.product,
                variantsDisposal: this.variantsDisposal,
            };
            this.modal.editNote = {
                variantId: 0,
                note: null,
            };

            this.$store.commit('waste/SET_PRODUCT_VARIANTS', {
                productId: this.product.id,
                variantsDisposal: product.variantsDisposal,
            });
        },
        openDeleteWasteProductModal() {
            if (!this.hasVariants) this.$emit('deleted-waste-variants');
            this.$refs.modalDeleteProduct.$refs.deleteProductModal.$emit('open');
        },
        openDeletedLastWasteVariantProduct() {
            this.$refs.modalDeletedLastProductVariant.$refs.deletedLastProductVariantModal.$emit('open');
        },
        updateVariant({ id, key, value, type = 'string' }) {
            const product = _cloneDeep(this.product);
            const variant = product.variantsDisposal.find(v => v.id === id);

            if (!variant) return;

            switch (type) {
                case 'string':
                    if (!value) variant[key] = null;
                    else variant[key] = value;
                    break;
                case 'number':
                    if (value === '' || !value) variant[key] = null;
                    else variant[key] = Number(value);
                    break;
                case 'boolean':
                    variant[key] = Boolean(value);
                    break;
                default:
                    variant[key] = value;
            }

            this.$store.commit('waste/UPDATE_PRODUCT', { product, productId: this.product.id });
        },
        updateNote({ id, value }) {
            this.updateVariant({ id, key: 'note', value, type: 'string' });
        },
    },
};
</script>

<style lang="scss" scoped src="./SupplierWasteProductDetailsVariantTable.scss" />

import { ensureJSTimestamp } from '@/services/utils/date';

export default class SummaryLineItemView {
    /**
     * Create a view model from quote line item (e.g. API response data)
     *
     * @param {object} quoteLineItem
     * @returns {SummaryLineItemView}
     */
    static createFromQuoteLineItem(quoteLineItem) {
        const lineItem = new SummaryLineItemView();

        lineItem.qty = quoteLineItem.qty;
        lineItem.vehicleClassId = quoteLineItem.vehicleClassId;
        lineItem.vehicleClassIcon = quoteLineItem.vehicleClassIcon;
        lineItem.vehicleName = quoteLineItem.vehicleName;
        lineItem.vehicleNumAxes = quoteLineItem.vehicleNumAxes;
        lineItem.vehiclePayload = quoteLineItem.vehiclePayload;

        lineItem.shippingDate = quoteLineItem.shippingDate;
        lineItem.shippingWindowStart = quoteLineItem.shippingWindowStart;
        lineItem.shippingWindowEnd = quoteLineItem.shippingWindowEnd;

        return lineItem;
    }

    /**
     * Create a view model from basket transport line item
     *
     * @param {object} basket
     * @param {object} basketTransport
     * @returns {SummaryLineItemView}
     */
    static createFromBasketTransport(basket, basketTransport) {
        const lineItem = new SummaryLineItemView();

        lineItem.qty = basketTransport.actualLoad;
        lineItem.vehicleClassId = basketTransport.id;
        lineItem.vehicleName = basketTransport.name;
        lineItem.vehicleNumAxes = basketTransport.numAxes;
        lineItem.vehiclePayload = basketTransport.payload;

        // TODO: handle basket conversion for delivery time
        // lineItem.shippingDate = basketTransport.date;
        // lineItem.shippingWindowStart = basketTransport.shippingWindowStart;
        // lineItem.shippingWindowEnd = basketTransport.shippingWindowEnd;

        return lineItem;
    }

    constructor() {
        this._qty = null;
        this._vehicleClassId = null;
        this._vehicleClassIcon = null;
        this._vehicleName = null;
        this._vehicleNumAxes = null;
        this._vehiclePayload = null;

        // TransportsTable.vue
        this._shippingDate = null;
        this._shippingWindowStart = null;
        this._shippingWindowEnd = null;
    }

    get qty() {
        return this._qty;
    }

    set qty(value) {
        this._qty = value ?? null;
    }

    get vehicleClassId() {
        return this._vehicleClassId;
    }

    set vehicleClassId(value) {
        this._vehicleClassId = value ?? null;
    }

    get vehicleClassIcon() {
        return this._vehicleClassIcon;
    }

    set vehicleClassIcon(value) {
        this._vehicleClassIcon = value ?? null;
    }

    get vehicleName() {
        return this._vehicleName;
    }

    set vehicleName(value) {
        this._vehicleName = value ?? null;
    }

    get vehicleNumAxes() {
        return this._vehicleNumAxes;
    }

    set vehicleNumAxes(value) {
        this._vehicleNumAxes = value ?? null;
    }

    get vehiclePayload() {
        return this._vehiclePayload;
    }

    set vehiclePayload(value) {
        this._vehiclePayload = value ?? null;
    }

    get shippingDate() {
        return ensureJSTimestamp(this._shippingDate);
    }

    set shippingDate(value) {
        this._shippingDate = value ?? null;
    }

    get shippingWindowStart() {
        return ensureJSTimestamp(this._shippingWindowStart);
    }

    set shippingWindowStart(value) {
        this._shippingWindowStart = value ?? null;
    }

    get shippingWindowEnd() {
        return ensureJSTimestamp(this._shippingWindowEnd);
    }

    set shippingWindowEnd(value) {
        this._shippingWindowEnd = value ?? null;
    }
}

<template>
    <div class="transport-map">
        <div class="transport-map__map-wrapper">
            <div id="map" class="transport-map__map" />
        </div>

        <BaseButton orb class="transport-map__center-all-button" @click.stop="centerAllPoints">
            <CenterAllIcon width="24" />
        </BaseButton>

        <DraggableContainer v-if="focusedTransport" v-model="isDetailsOpen" class="transport-map__details">
            <BaseButton
                v-if="focusedTransport && !hasArrived(focusedTransport.status)"
                :class="{
                    'transport-map__center-focus-button--active': isFollowing,
                }"
                class="transport-map__center-focus-button"
                pill
                @click.stop="followTransport"
            >
                {{ $t('components.transportMap.centerTransportLabel') }}
            </BaseButton>
            <div v-if="!hasArrived(focusedTransport.status)" class="transport-map__details-head">
                <small
                    :class="{ 'transport-map__details-arrival--late': focusedTransport.isLate }"
                    class="transport-map__details-arrival"
                >
                    <FlagIcon height="12" class="icon--inline" />
                    <Words :green="!focusedTransport.isLate" :red="focusedTransport.isLate" bold small middle>
                        {{ formatArrivalTime(focusedTransport.arrivalTime) }}
                    </Words>
                </small>
                <span class="transport-map__details-head-seperator" />
                <Words small>
                    {{ $n(focusedTransport.remainingDistance / 1000, 'distance') }} {{ $t('units.kilometer') }}
                </Words>
                <span class="transport-map__details-head-seperator" />
                <Words small>
                    {{
                        $t('components.transportMap.timeSuffix', {
                            time: $d(focusedTransport.arrivalTime * 1000, 'time'),
                        })
                    }}
                </Words>
                <span class="transport-map__details-head-seperator" />
                <Words bold tiny muted>{{ statusText(focusedTransport.status) }}</Words>
                <!-- 23min | 20km | 12:23 | unterwegs -->
            </div>
            <div v-else class="transport-map__details-head">
                <small
                    :class="{ 'transport-map__details-arrival--late': focusedTransport.isLate }"
                    class="transport-map__details-arrival"
                >
                    <TickIcon height="12" class="icon--inline" />
                    <Words :green="!focusedTransport.isLate" :red="focusedTransport.isLate" bold small middle>
                        {{ $d(focusedTransport.destinationWaitingTimestamp * 1000, 'short') }}
                        &nbsp;&nbsp;&nbsp;
                        {{
                            $t('timeSuffix', {
                                time: $d(focusedTransport.destinationWaitingTimestamp * 1000, 'time'),
                            })
                        }}
                    </Words>
                </small>
                <span class="transport-map__details-head-seperator" />
                <Words bold tiny muted>{{ statusText(focusedTransport.status) }}</Words>
                <!-- 20.10.10 10:20 | unterwegs -->
            </div>
            <template #bottom>
                <div class="transport-map__details-body">
                    <TransportListBlockAddress :transport="focusedTransport.rawTransport" />
                </div>
            </template>
        </DraggableContainer>

        <DraggableContainer
            v-else-if="noTrackingInformation && !showTransportTrackingDetails"
            v-model="isTrackingNoteOpen"
            class="transport-map__details"
        >
            <div class="transport-map__details-head">
                <Words bold small middle>
                    {{ $t(`components.transportMap.noData`) }}
                </Words>
            </div>
            <template #bottom>
                <div class="transport-map__details-body transport-map__details-body--center">
                    <Words tiny small middle>
                        {{ $t(`components.transportMap.noDataDescription`) }}
                    </Words>
                </div>
            </template>
        </DraggableContainer>

        <LayerControls
            :type="mapType"
            :mode="detailMode"
            :map="map"
            @update-type="mapType = $event"
            @update-mode="detailMode = $event"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { transportProgress, statusText, statusIsAfter } from '@/services/utils/transport';
import GoogleMaps, { createMap } from '@/services/GoogleMaps';
import Words from '@/components/Typography/Words';
import BaseButton from '@/components/Button/Button';
import FlagIcon from '@/assets/icons/micro/flag.svg';
import TickIcon from '@/assets/icons/micro/tick.svg';
import RadarIconPath from '@/assets/icons/radar.svg?external';
import DraggableContainer from '@/components/Draggable/DraggableContainer';
import LayerControls from '@/components/Map/LayerControls';
import CenterAllIcon from '@/assets/icons/regular/zoom-out.svg';
import TransportListBlockAddress from '@/components/Transport/TransportListBlock/TransportListBlockAddress';
import routeContext from '@/plugins/mixins/routeContext';
import transportMap from '@/plugins/mixins/transportMap';
import { CONTEXT_CARRIER, CONTEXT_CARRIER_DRIVER } from '@/constants/context';

import TransportApi from '@/services/Api/Transport';
import Transport from '@/components/Map/Transport';
import _debounce from 'lodash/debounce';
import { normalizeLocation, locationsEqual } from '@/services/utils/map';
import { formatArrivalTime } from '@/services/utils/date';

const DEBOUNCE_CHANGED_MAP_CENTER_MS = 0;

const ZOOM_INITIAL = 15;
const ZOOM_CENTER_LOCATION = 15;
const ZOOM_ASSISTIVE_MIN = 13;
const ZOOM_ASSISTIVE_MAX = 25;
const ZOOM_ALL_TRANSPORT_MAX = 18;
const ZOOM_TRANSPORT_FOCUS = 15;

const DELAY_INITIAL_ALL_TRANSPORTS_ZOOM_MS = 1000;

const TRANSPORT_POLLING_INTERVAL_MS = 20 * 1000;
const TRANSPORT_POLLING_WAYPOINTS_INTERVAL_MS = 3 * 1000;

export default {
    name: 'TransportMap',
    components: {
        Words,
        BaseButton,
        FlagIcon,
        TickIcon,
        DraggableContainer,
        LayerControls,
        CenterAllIcon,
        TransportListBlockAddress,
    },
    mixins: [routeContext, transportMap],
    props: {
        destination: {
            type: Object,
            default: null,
        },
        source: {
            type: Object,
            default: null,
        },
        transports: {
            type: Array,
            default: () => [],
        },
        focusDestination: {
            type: Boolean,
            default: false,
        },
        focusSource: {
            type: Boolean,
            default: false,
        },
        focusTransportId: {
            type: Number,
            default: null,
        },
        focusAll: {
            type: Boolean,
            default: false,
        },
        transportTrackingStatus: {
            type: String,
            default: null,
        },
        showTransportTrackingDetails: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            map: null,
            mapType: 'roadmap',
            detailMode: 'default',
            destinationMarker: null,
            sourceMarker: null,
            transportSet: {},
            convertedTransports: {},
            focusedTransportId: null,
            isFollowing: false,
            isDetailsOpen: false,

            isTrackingNoteOpen: false,
            delayedFocus: false,
            transportApi: null,
        };
    },
    computed: {
        ...mapGetters('platform', ['initialMapLocation']),

        focusedTransport() {
            if (this.focusedTransportId === null) {
                return null;
            }

            return this.transportSet[this.focusedTransportId].transport;
        },
        noTrackingInformation() {
            let hasTracking = null;
            Object.keys(this.convertedTransports).forEach(transportId => {
                const transport = this.convertedTransports[transportId];

                if (
                    hasTracking === null &&
                    transport.status === 'in_transit' &&
                    transport.currentLocation.lat === undefined
                ) {
                    hasTracking = false;
                }
                if (transport.currentLocation.lat !== undefined) {
                    hasTracking = true;
                }
            });

            return !hasTracking;
        },
    },
    created() {
        this.transportApi = new TransportApi(this.routeContext);
        this.detailMode = this.inRouteContext(CONTEXT_CARRIER, CONTEXT_CARRIER_DRIVER) ? 'traffic' : 'default';
        this.createMap();
        this.updateDestination(this.destination);
        this.updateSource(this.source);
        this.getConvertedTransports().then(this.addTransports.bind(this));
        this.$gracefulInterval(this.updateTransportData.bind(this), TRANSPORT_POLLING_INTERVAL_MS);
        this.$gracefulInterval(this.updateTransportWaypoints.bind(this), TRANSPORT_POLLING_WAYPOINTS_INTERVAL_MS);
        this.updateTransportWaypoints();
    },
    methods: {
        transportProgress,

        statusText(status) {
            return statusText(this.routeContext, status);
        },

        async createMap() {
            if (this.map !== null) {
                return this.map;
            }

            const google = await GoogleMaps;

            this.map = createMap(google, 'map', {
                zoom: ZOOM_INITIAL,
                center: this.initialMapLocation,
                disableDefaultUI: true,
                mapTypeId: this.mapType,
            });

            this.map.addListener(
                'center_changed',
                _debounce(() => {
                    const transportSet = this.transportSet[this.focusedTransportId];

                    // no transport selected
                    if (this.focusedTransportId == null || !transportSet) {
                        return;
                    }

                    // check if center change was called by transport movement
                    if (!locationsEqual(transportSet.transport.latestPosition, this.map.getCenter(), 6)) {
                        this.$logger().log('Unfollow focused transport');
                        this.isFollowing = false;
                    }
                }, DEBOUNCE_CHANGED_MAP_CENTER_MS)
            );

            return this.map;
        },

        async centerLocationOnMap({ lat, lng }, zoom = ZOOM_CENTER_LOCATION) {
            const map = await this.createMap();

            this.$logger().log('Applied change: center position');
            map.setZoom(zoom);
            map.setCenter({ lat, lng });
        },

        async addTransports(transports = []) {
            transports.forEach(transport => {
                this.addTransport(transport);
            });

            // focus all transports initially
            if (this.focusTransportId !== null) {
                setTimeout(this.focusTransport.bind(this, this.focusTransportId), DELAY_INITIAL_ALL_TRANSPORTS_ZOOM_MS);
            }

            // focus all transports initially
            if (this.focusAll || this.focusTransportId !== null) {
                setTimeout(this.centerAllPoints, DELAY_INITIAL_ALL_TRANSPORTS_ZOOM_MS);
            }
        },

        async addTransport(transport) {
            const google = await GoogleMaps;
            const map = await this.createMap();

            if (!transport.currentLocation) {
                return false;
            }

            const { lat, lng } = transport.currentLocation;

            // cant show transport on map without position
            if (!lat || !lng || this.transportSet[transport.id]) {
                return false;
            }

            this.$logger().log(`Added transport #${transport.id}`, transport);

            /* @type {google.maps.Symbol} */
            const symbol = {
                path:
                    'M6.5,10.4l-5.9-3c-0.3-0.2-0.7-0.2-1.1,0l-5.9,3c-0.6,0.3-1.3,0.1-1.6-0.5c-0.2-0.3-0.2-0.7-0.1-1 ' +
                    'l7-18.6c0.2-0.6,0.9-0.9,1.6-0.7c0.3,0.1,0.6,0.4,0.7,0.7l7,18.6c0.2,0.6-0.1,1.3-0.7,1.6C7.1,10.6,6.8,10.5,6.5,10.4',
                fillColor: '#f00',
                fillOpacity: 1,
                scale: 1,
                strokeWeight: 0,
                rotation: 0, // 0 = north
            };

            const marker = new google.maps.Marker({
                position: { lat, lng },
                map: map,
                title: 'Destination',
                icon: symbol,
                zIndex: transport.id + 3,
            });

            const route = new google.maps.Polyline({
                geodesic: true,
                strokeColor: '#f00',
                strokeOpacity: 1.0,
                strokeWeight: 4,
                clickable: true,
            });

            const radarIcon = {
                url: RadarIconPath,
                scaledSize: new google.maps.Size(60, 60), // maps adds wierd 16px to the marker
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(30, 30),
            };

            const radar = new google.maps.Marker({
                position: { lat, lng },
                map: map,
                title: 'Radar',
                icon: radarIcon,
                zIndex: transport.id + 2,
            });

            const transportFocusHandler = () => {
                this.unfocusAll(transport.id);
                if (this.focusedTransportId !== transport.id) {
                    this.$logger().log(`Focus transport #${transport.id}`);
                    this.focusTransport(transport.id);
                } else {
                    this.$logger().log(`Unfocus current transport #${transport.id}`);
                    this.unfocusTransport(transport.id);
                }
            };

            marker.addListener('click', transportFocusHandler);
            radar.addListener('click', transportFocusHandler);

            route.addListener('click', () => {
                if (!this.isFollowing) {
                    this.isFollowing = true;
                    this.focusTransport(transport.id);
                } else {
                    this.unfocusTransport(transport.id);
                }
            });

            transport.onLocationChange = newLocation => {
                this.$logger().log(`Location change for transport #${transport.id}`, newLocation);
                // update marker on map
                marker.setPosition(newLocation);
                radar.setPosition(newLocation);

                // apply rotation
                const icon = { ...marker.icon };
                icon.rotation = newLocation.rotation;
                marker.setIcon(icon);

                const waypoints = transport.waypoints.map(item => normalizeLocation(item));
                route.setPath(waypoints);

                // check if the current transport is being followd, update as well
                if (
                    (this.focusedTransportId === transport.id && this.isFollowing) ||
                    this.delayedFocus === true ||
                    this.delayedFocus === transport.id
                ) {
                    this.delayedFocus = false;
                    this.focusTransport(transport.id, true);
                }
            };

            transport.onDataChange = () => {
                this.$logger().log(`Transport data changed #${transport.id}`);
            };

            this.$set(this.transportSet, transport.id, {
                marker,
                route,
                transport,
                radar,
            });

            return true;
        },

        async assistiveZoom(zoom, minZoomLevel = ZOOM_ASSISTIVE_MIN, maxZoomLevel = ZOOM_ASSISTIVE_MAX) {
            const map = await this.createMap();

            this.$logger().log(`Current zoom: ${map.getZoom()} — Zoom to: ${zoom} - Max: ${maxZoomLevel}`);

            const currentZoom = map.getZoom();

            if (currentZoom < minZoomLevel || currentZoom > maxZoomLevel) {
                map.setZoom(zoom);
            }
        },

        async centerAllPoints() {
            const map = await this.createMap();
            const google = await GoogleMaps;

            this.isFollowing = false;

            const bounds = new google.maps.LatLngBounds();

            if (Object.keys(this.transportSet).length == 0) {
                // no transport data available
                this.delayedFocus = true;
                this.$logger().log('No transports available');
            }

            Object.keys(this.transportSet).forEach(transportId => {
                const transportSet = this.transportSet[transportId];
                bounds.extend(transportSet.marker.position);
            });

            if (this.sourceMarker) {
                bounds.extend(this.sourceMarker.getPosition());
            }

            if (this.destinationMarker) {
                bounds.extend(this.destinationMarker.getPosition());
            }

            map.fitBounds(bounds);
            if (map.getZoom() > ZOOM_ALL_TRANSPORT_MAX) {
                map.setZoom(ZOOM_TRANSPORT_FOCUS);
            }

            return true;
        },

        async focusTransport(transportId, panToPoint = false) {
            const map = await this.createMap();
            const transportSet = this.transportSet[transportId];
            if (!transportSet) {
                this.focusedTransportId = null;
                // zoom out to some other location, eg. unloading site
            }

            // transport not found in transport set
            if (!this.transportSet[transportId]) {
                // no transport data available
                this.delayedFocus = transportId;

                let fallbackCenter = this.initialMapLocation;

                if (this.sourceMarker) {
                    fallbackCenter = this.sourceMarker.getPosition();
                }

                if (this.destinationMarker) {
                    fallbackCenter = this.destinationMarker.getPosition();
                }

                this.$logger().log('No transports available, center fallback location');

                this.centerLocationOnMap(normalizeLocation(fallbackCenter));
                return;
            }

            // only zoom in if the transport is not focused
            if (this.focusedTransportId !== transportId) {
                this.focusedTransportId = transportId;
            }

            transportSet.route.setMap(map);

            panToPoint && map.panTo(transportSet.marker.position);
        },

        unfocusTransport(transportId = null) {
            this.$logger().log(`Unfocus transport #${transportId}`);
            if (transportId === null) {
                transportId = this.focusedTransportId;
            }

            if (transportId === this.focusedTransportId) {
                this.focusedTransportId = null;
            }

            const transportSet = this.transportSet[transportId];
            if (!transportSet) {
                return;
            }

            transportSet.route.setMap(null);
        },

        unfocusAll(exceptId) {
            Object.keys(this.transportSet).forEach(transportId => {
                if (exceptId !== parseFloat(transportId)) {
                    this.unfocusTransport(transportId);
                }
            });
        },

        followTransport() {
            this.isFollowing = !this.isFollowing;
            if (this.isFollowing) {
                this.focusTransport(this.focusedTransportId, true);
            }
        },

        /**
         * Poll data for all transport in the current set
         */
        updateTransportData() {
            this.getConvertedTransports().then(transports => {
                transports.forEach(transport => {
                    transport.onDataChange = () => {
                        if (['in_transit', 'destination_waiting'].includes(transport.status)) {
                            this.addTransport(transport);
                        }
                    };
                    transport.refreshData();
                    this.$emit('update-refresh-timestamp', Date.now());
                });
            });
        },

        /**
         * Poll waypoint data for all transport in the current set
         */
        updateTransportWaypoints() {
            Object.keys(this.transportSet).forEach(transportId => {
                const transportSet = this.transportSet[transportId];

                let statusToStartTracking;
                switch (transportSet.transport.rawTransport.type) {
                    case 'shipment': {
                        statusToStartTracking = 'started';
                        break;
                    }
                    default: {
                        statusToStartTracking = 'in_transit';
                    }
                }

                if (statusIsAfter(transportSet.transport.status, statusToStartTracking)) {
                    transportSet.transport.refreshWaypoints(this.transportTrackingStatus);
                    this.$emit('update-refresh-timestamp', Date.now());
                }
            });
        },

        async getConvertedTransports() {
            const transports = [];
            for (const i in this.transports) {
                try {
                    transports.push(await this.transportApi.getOneById(this.transports[i].id));
                } catch (err) {
                    this.$logger().error(err);
                }
            }

            return transports.map(transport => {
                if (!this.convertedTransports[transport.id]) {
                    this.$logger().log(`Convert Transport #${transport.id}`);
                    const transportObject = new Transport(this.routeContext, transport);
                    transportObject.refreshWaypoints(this.transportTrackingStatus);
                    this.convertedTransports[transport.id] = transportObject;
                } else {
                    this.$logger().log(`Transport #${transport.id} already a Transport`);
                }

                return this.convertedTransports[transport.id];
            });
        },

        hasArrived(status) {
            return statusIsAfter(status, 'destination_waiting');
        },

        formatArrivalTime,
    },
};
</script>

<style lang="scss">
.transport-map {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    flex: 1 1;
    display: flex;
}

.transport-map__map-wrapper {
    position: relative;
    flex: 1 1;
    display: flex;
}

.transport-map__map {
    background-color: #eee;
    min-height: 100px;
    flex: 1 1;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
}

.transport-map__details {
    position: absolute;
    top: 200vh;
    bottom: 0;
    left: 20px;
    right: 20px;
    background-color: #fff;
    box-shadow: $boxShadow-bottomShort;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;

    &::after {
        content: '';
        display: block;
        position: absolute;
        left: -20px;
        right: -20px;
        top: 70px;
        bottom: -70px;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+25&0+0,1+25 */
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 25%);
    }
}

.transport-map__details--open {
    display: block;
}

.transport-map__details-head {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 30px 10px 20px;
    box-shadow: $boxShadow-bottom;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 9px;
        height: 4px;
        background-color: #d8d8d8;
        width: 34px;
        border-radius: 2px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.transport-map__details-head-seperator {
    width: 1px;
    height: 18px;
    background-color: #e2e2e2;
}

.transport-map__details-arrival {
    svg {
        .f-base {
            fill: $color-green;
        }
        .s-base {
            stroke: $color-green;
        }
    }
}

.transport-map__details-arrival--late {
    svg {
        .f-base {
            fill: $color-red;
        }
        .s-base {
            stroke: $color-red;
        }
    }
}

.transport-map__details-body {
    position: relative;
    z-index: 1;
    padding: 30px 20px;
}

.transport-map__details-body--center {
    text-align: center;
}

.transport-map__center-focus-button {
    position: absolute;
    top: -40px;
    right: 0;
    border: 2px solid transparent;
    transition:
        transform 0.2s ease-out,
        box-shadow 0.2s ease-out;
    transform: scale(1);

    &:active {
        transform: scale(0.7);
    }

    &.transport-map__center-focus-button {
        opacity: 1;
    }
}

.transport-map__center-focus-button--active {
    border-color: $color-red;
}

.transport-map__center-all-button {
    position: absolute;
    right: 100px;
    top: 20px;
    width: 50px;
    height: 50px;
    box-shadow: $boxShadow-bottomShort;
    transition:
        transform 0.2s ease-out,
        box-shadow 0.2s ease-out;
    transform: scale(1);

    &:active {
        transform: scale(0.7);
    }
}
</style>

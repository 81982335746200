<template>
    <Flyout :active="true" no-header @close="$emit('close')">
        <HeaderBar>
            <HeaderBarItem slot="left" button @click="$emit('close')">
                <ArrowIcon width="32" height="18" />
            </HeaderBarItem>
            <div slot="headline">
                {{ $t('pages.login.passwordLost.headline') }}
            </div>
        </HeaderBar>

        <Tile no-border>
            <Words block>
                {{ $t('pages.login.passwordLost.description') }}
            </Words>

            <TextField
                v-model="passwordResetUsername"
                :label="$t('pages.login.passwordLost.passwordResetUsernamePlaceholder')"
                type="text"
                class="login__password-lost-username"
            />
        </Tile>

        <ButtonGroup slot="bottom">
            <Button :disabled="!passwordResetUsername" type="submit" primary @click="handlePasswordReset">
                {{ $t('pages.login.passwordLost.submitPasswordReset') }}
            </Button>
        </ButtonGroup>
    </Flyout>
</template>
<script>
import throttle from 'lodash/throttle';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Flyout from '@/components/Layout/Flyout';
import TextField from '@/components/Form/TextField.v2';
import Tile from '@/components/Layout/Tile';
import Words from '@/components/Typography/Words';

import Authenticator from '@/services/Api/Authenticator';
import Toaster from '@/services/Toaster';

import ArrowIcon from '@/assets/icons/regular/arrow.svg';

const THROTTLE_DELAY = 5000;

export default {
    name: 'PasswordResetFlyout',

    components: {
        Button,
        ButtonGroup,
        HeaderBar,
        HeaderBarItem,
        Flyout,
        TextField,
        Tile,
        Words,

        ArrowIcon,
    },

    emits: ['close', 'messageSent'],

    data: () => ({
        passwordResetUsername: '',
    }),

    computed: {
        throttledPasswordReset() {
            return throttle(
                (async () => {
                    try {
                        await Authenticator.resetPassword(this.passwordResetUsername);
                        this.$emit('messageSent');
                    } catch (err) {
                        this.$logger().error(err);
                        Toaster.error(err);
                    } finally {
                        this.passwordResetUsername = '';
                    }
                }).bind(this),
                THROTTLE_DELAY,
                {
                    trailing: false,
                }
            );
        },
    },

    methods: {
        handlePasswordReset() {
            this.throttledPasswordReset();
        },
    },
};
</script>

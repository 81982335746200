<template>
    <div class="transport-item relative" data-test="transport-item">
        <div class="transport-item__tag-container">
            <SfProjectsIcon v-if="showProjectIndicator" size="xs" multi-color />
        </div>

        <div v-if="isSupplier" class="transport-item__tile transport-item--supplier">
            <div class="transport-item__image">
                <Thumbnail
                    v-if="item.vehicle && item.vehicle.image"
                    :src="item.vehicle.image.url"
                    class="transport-item__thumbnail"
                    modal
                />
                <TruckPlaceholderThumbnail v-else />
            </div>
            <div class="transport-item__text" @click="$emit('click', $event)">
                <GridRow>
                    <Words
                        block
                        bold
                        class="transport-item__vehicle-identifier"
                        v-html="formattedLicensePlate(item.vehicle.licensePlate)"
                    />
                    <Words block bold small class="transport-item__order-number">
                        {{ item.loadingName }}
                    </Words>
                </GridRow>

                <GridRow>
                    <div>
                        <Words bold class="transport-item__vehicle-name">
                            {{ item.vehicleName }}
                        </Words>
                        <Words muted bold class="transport-item__vehicle-payload">
                            / {{ getEmptyWeight(item.vehicle) | tons }}
                        </Words>
                    </div>
                    <Words class="transport-item__order-number" block>
                        <TransportTypeIcon :type="item.type" class="transport-item__icon" />
                        {{ item.number }}
                    </Words>
                </GridRow>

                <GridRow>
                    <Words muted block class="transport-item__product">
                        {{ item.qty | tons }} {{ revertLocalizedValue(item.product.productName) }}
                    </Words>
                    <Words block small muted class="transport-item__order-number">
                        {{ $t(`components.transportItem.supplier.type.${item.type}`) }}
                    </Words>
                </GridRow>
            </div>
        </div>

        <div v-else class="transport-item" @click="$emit('click', $event)">
            <div class="transport-item__text">
                <Words v-if="showDate && item.status !== 'inProgress'" class="transport-item__date" bold block>
                    {{
                        $t('components.transportItem.shippingWindowStartDate', {
                            date: $d(item.shippingDate * 1000, 'short'),
                        })
                    }}
                </Words>
                <div class="transport-item__data transport-item__data--axes">
                    <div class="transport-item__axes">
                        <VehicleClassIconSet :icon="item.vehicleClassIcon" />
                    </div>
                    <div class="transport-item__product-info">
                        <Words bold>
                            {{ computedQty | tons }}
                        </Words>
                        <Words>
                            {{ revertLocalizedValue(item.product.productName) }}
                        </Words>
                    </div>
                </div>
                <div class="transport-item__data">
                    <PinIcon class="transport-item__icon transport-item__pin-icon" />
                    <Words
                        v-if="
                            item.constructionSite.address.street ||
                            item.constructionSite.address.zip ||
                            item.constructionSite.address.city
                        "
                        small
                        muted
                        class="transport-item__data-multiline"
                    >
                        <FormattedAddress :address="item.constructionSite.address" />
                    </Words>
                    <Words v-else small muted>
                        {{ item.constructionSite.location.lat }},{{ item.constructionSite.location.lng }}
                    </Words>
                </div>

                <div
                    v-if="item && isInTransit"
                    class="transport-item__estimated-arrival transport-item__estimated-arrival--client span-2"
                >
                    <DrivingTime :transport="item" weight-normal />
                </div>

                <ArrivalTime :transport="item" list-element />

                <div class="transport-item__transport-number transport-item__transport-number--client">
                    <NumberIcon
                        class="transport-item__icon transport-item__number-icon transport-item__icon--small-margin"
                    />
                    <Words small muted>
                        {{ $t('components.transportItem.transportNumber.client', { number: item.number }) }}
                    </Words>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _get from 'lodash/get';
import { statusIsBefore, statusIsAfter } from '@/services/utils/transport';
import { revertLocalizedValue } from '@/services/utils/localization';
import { mapFilters } from '@/plugins/filters';
import { ensureJSTimestamp } from '@/services/utils/date';

import ArrivalTime from '@/components/Transport/ArrivalTime';
import DrivingTime from '@/components/Transport/DrivingTime';
import FormattedAddress from '@/components/FormattedAddress';
import GridRow from '@/components/Layout/GridRow';
import Thumbnail from '@/components/Thumbnail';
import TransportTypeIcon from '@/components/IconSet/TransportTypeIcon';
import TruckPlaceholderThumbnail from '@/pages/Vehicle/components/TruckPlaceholderThumbnail';
import VehicleClassIconSet from '@/components/IconSet/VehicleClassIconSet';
import Words from '@/components/Typography/Words';

import NumberIcon from '@/assets/icons/micro/number.svg';
import PinIcon from '@/assets/icons/micro/pin.svg';

import { SfProjectsIcon } from '@schuettflix/vue-components';

import { TRANSPORT_TYPE } from '@/constants/transportTypes';

export default {
    name: 'TransportItem',
    components: {
        ArrivalTime,
        DrivingTime,
        FormattedAddress,
        GridRow,
        Thumbnail,
        TransportTypeIcon,
        TruckPlaceholderThumbnail,
        VehicleClassIconSet,
        Words,

        NumberIcon,
        PinIcon,

        SfProjectsIcon,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        isCarrier: {
            type: Boolean,
            default: false,
        },
        isSupplier: {
            type: Boolean,
            default: false,
        },
        searchString: {
            type: String,
            default: null,
        },
        showDate: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isInTransit() {
            return this.item.status === 'in_transit';
        },

        isInProgress() {
            return statusIsBefore(this.item.status, 'unloading_delayed');
        },

        isDelivered() {
            return statusIsAfter(this.item.status, 'destination_waiting');
        },

        isDelivery() {
            return this.item.type === TRANSPORT_TYPE.DELIVERY;
        },

        isShipment() {
            return this.item.type === TRANSPORT_TYPE.SHIPMENT;
        },

        isDisposal() {
            return this.item.type === TRANSPORT_TYPE.DISPOSAL;
        },

        showProjectIndicator() {
            return this.item?.projectPositionId;
        },

        hasDeliveredTimestamp() {
            if (this.item.status === 'failed') {
                return _get(this.item, 'failedTimestamp', null) !== null;
            }

            return _get(this.item, 'serviceDateTimestamp', null) !== null;
        },

        computedQty() {
            return statusIsAfter(this.item.status, 'ready_to_deliver') && this.item.totalLoading
                ? this.item.totalLoading
                : this.item.qty;
        },

        fromAddressCity() {
            if (this.isShipment) {
                return this.item.loadingAddress.city;
            } else if (this.isDisposal) {
                return this.item.loadingAddress.city || this.item.constructionSite.address.city;
            } else {
                return this.item.loadingAddress.city || this.transport.supplier.billingAddress.city;
            }
        },

        fromAddressCountry() {
            if (this.isShipment) {
                return this.item.loadingAddress.country;
            } else if (this.isDisposal) {
                return this.item.loadingAddress.country || this.item.constructionSite.address.country;
            } else {
                return this.item.loadingAddress.country || this.transport.supplier.billingAddress.country;
            }
        },

        toAddressCity() {
            if (this.isShipment) {
                return this.item.unloadingAddress.city;
            } else if (this.isDisposal) {
                return this.item.unloadingAddress.city || this.transport.supplier.billingAddress.city;
            } else if (this.item.constructionSite.address) {
                return this.item.constructionSite.address.city;
            } else if (this.item.constructionSite.location) {
                return `${this.item.constructionSite.location.lat}, ${this.item.constructionSite.location.lng}`;
            }

            return null;
        },

        toAddressCountry() {
            if (this.isShipment) {
                return this.item.unloadingAddress.country;
            } else if (this.isDisposal) {
                return this.item.unloadingAddress.country || this.transport.supplier.billingAddress.country;
            } else if (this.item.constructionSite.address) {
                return this.item.constructionSite.address.country;
            }

            return null;
        },
    },
    methods: {
        ...mapFilters(['tons']),
        revertLocalizedValue,
        statusIsBefore,
        statusIsAfter,
        ensureJSTimestamp,

        getEmptyWeight(vehicle) {
            return vehicle.permissibleTotalWeight - vehicle.emptyWeight;
        },

        formattedLicensePlate(value) {
            if (this.searchString) {
                const reg = new RegExp(`${this.searchString}`, 'gi');

                return value.replace(reg, function replace(match) {
                    return `<span class="text-critical">${match}</span>`;
                });
            }

            return value;
        },

        shipmentLoadingDate(transport) {
            if (!transport.loadingDateFrom && !transport.loadingDateTo) return null;

            return `${this.$d(ensureJSTimestamp(transport.loadingDateFrom), 'short')} - ${this.$d(
                ensureJSTimestamp(transport.loadingDateTo),
                'short'
            )}`;
        },

        shipmentLoadingTime(transport) {
            if (!transport.loadingTimeFrom && !transport.loadingTimeTo) return null;

            return `${this.$t('timeSuffix', { time: transport.loadingTimeFrom })} - ${this.$t('timeSuffix', {
                time: transport.loadingTimeTo,
            })}`;
        },

        shipmentUnloadingTime(transport) {
            if (!transport.plannedDeliveryTime) return null;

            return `${this.$d(ensureJSTimestamp(transport.plannedDeliveryTime), 'short')} - ${this.$t('timeSuffix', {
                time: this.$d(ensureJSTimestamp(transport.plannedDeliveryTime), 'time'),
            })}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.transport-item {
    position: relative;
}

.transport-item__tile {
    cursor: pointer;
    padding: 18px 10px 13px;
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
}

.transport-item__text {
    width: 100%;
}

.transport-item__date {
    padding: 10px 0;
    margin-bottom: 5px;
    width: 100%;
    display: inline-block;
}

.transport-item__data {
    padding: 5px 0;
}

.transport-item__data-multiline {
    vertical-align: middle;
    display: inline-block;
    width: calc(100% - 24px);
}

.transport-item__axes {
    width: 27%;
}

.transport-item__data--axes {
    display: flex;
    align-items: center;

    svg {
        height: 30px;
        width: auto;
    }
}

.transport-item__icon {
    flex: 0 0 14px;
    display: inline-block;
    vertical-align: middle;
    min-width: 14px;
    margin-right: 8px;

    svg {
        width: 100%;
        height: auto;
    }
}

.transport-item__pin-icon,
.transport-item__number-icon {
    path {
        fill: $color-grey;
    }
}

.transport-item--carrier {
    .transport-item__text {
        margin-bottom: 0;
    }

    .transport-item__date {
        display: flex;
        align-items: center;
        border: 0 none;
        padding: 0;
    }
}

.transport-item--supplier {
    align-items: stretch;

    .transport-item__image + .transport-item__text {
        padding: 0 47px 0 20px;

        @media screen and (max-width: $layout-desktop-min) {
            padding: 0 10px 0 20px;
        }
    }

    .grid-row {
        margin-bottom: 5px;
    }

    .transport-item__order-number {
        text-align: right;
    }
}

.arrival-time {
    padding: 5px 0;
}

.transport-item__product {
    margin-top: 5px;
}

.transport-item__estimated-arrival--client {
    margin: 3px 0;
}

.transport-item__icon--small-margin {
    margin-right: 7px;
}

.transport-item__date--no-margin {
    margin: 0;

    .arrival-time {
        padding: 0;
    }
}

.transport-item__transport-number {
    @media screen and (max-width: $layout-desktop-min) {
        grid-column: span 2;
    }

    .words {
        vertical-align: middle;
    }
}

.transport-item__transport-number--client {
    margin-top: 5px;
}

.transport-item__thumbnail {
    width: 70px;
    height: 70px;

    @media screen and (min-width: 768px) {
        width: 50px;
        height: 50px;
    }
}

.transport-item__tag-container {
    position: absolute;
    top: 15px;
    right: 15px;
    display: grid;
    grid-auto-flow: column;
    gap: 15px;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: $layout-desktop-min) {
        position: relative;
        margin-bottom: 10px;
    }
}
</style>

import Vue from 'vue';
import ProductListPage from './ProductListPage';
import ProductAddPage from '@/pages/SupplierProducts/ProductAddPage';
import ProductEditPage from '@/pages/SupplierProducts/ProductEditPage';
import DisposalListPage from '@/pages/SupplierProducts/DisposalListPage';

export default [
    {
        path: 'supplier/products',
        component: ProductListPage,
        name: 'supplier-products',
        props: route => ({ page: route.query.page, factoryId: Vue.observable(Number(route.query.factoryId)) }),
        meta: {
            requiresAuth: true,
            requiredAbilities: ['viewSupplierProductPage'],
        },
        children: [
            {
                path: 'add',
                component: ProductAddPage,
                name: 'add',
                props: route => ({
                    factoryId: route.query.factoryId,
                    copyProductId: route.query.copy || null,
                }),
            },
        ],
    },
    {
        path: 'supplier/products/:id',
        name: 'supplier-products-edit',
        component: ProductEditPage,
        props: route => {
            return {
                productId: route.params.id,
                parentRoute: 'supplier-products',
            };
        },
    },
    {
        path: 'supplier/disposal',
        component: DisposalListPage,
        name: 'supplier-disposal',
        props: route => ({ factoryId: Vue.observable(Number(route.query.factoryId)) }),
        meta: {
            requiresAuth: true,
            requiredAbilities: ['viewSupplierProductPage'],
        },
    },
];

<template>
    <ModalBox
        :active="isActive"
        :headline="$t('contextMenu.projectPosition.changeFreightDescription')"
        optional-dialog
        @closed="closeModal"
    >
        <template v-if="isActionAvailable">
            <SfTextField
                v-model="freightDescription"
                :label="$t('components.shipmentFreightInformation.description.label')"
                :validation-message="getError('freightDescription')"
            />

            <SfMessageBox class="mt-4" :headline="$t('components.hint.label')" type="warning">
                <p>{{ $t('components.shipmentFreightInformation.updateDescription.hint') }}</p>
            </SfMessageBox>
        </template>
        <template #actions>
            <ButtonGroup>
                <SfButton
                    size="sm"
                    :disabled="isPending()"
                    :is-loading="isPending('saveFreightDescription')"
                    @click="saveFreightDescription"
                >
                    {{ $t('components.shipmentFreightInformation.save.button') }}
                </SfButton>
            </ButtonGroup>
        </template>
    </ModalBox>
</template>

<script>
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import validation from '@/services/validation/mixin';
import { ACTIONS } from '@/constants/actions';
import { EVENT_PROJECT_POSITION_UPDATED } from '@/constants/events';
import { mapGetters } from 'vuex';
import ProjectPositionView from '@/models/ProjectPositionView';
import Toaster from '@/services/Toaster';
import { isRequired, maxLength } from '@/services/validation/rules';
import statefulMixin from '@/plugins/mixins/statefulMixin';

import ButtonGroup from '@/components/Button/ButtonGroup';
import ModalBox from '@/components/Modal/ModalBox';
import ProjectPositionApi from '@/services/Api/ProjectPosition';

import { SfButton, SfMessageBox, SfTextField } from '@schuettflix/vue-components';

export default {
    name: 'ProjectPositionFreightDescriptionChangeAction',
    components: {
        ButtonGroup,
        ModalBox,
        SfMessageBox,
        SfButton,
        SfTextField,
    },
    mixins: [eventHubMixin, statefulMixin, validation],
    data() {
        return {
            freightDescription: null,
            validationRules: {
                freightDescription: [isRequired(), maxLength(1000)],
            },
        };
    },
    computed: {
        ...mapGetters('globalActions', ['subject', 'action']),

        isActive() {
            return this.action === ACTIONS.PROJECT_POSITION_FREIGHT_DESCRIPTION_CHANGE;
        },

        isActionAvailable() {
            return this.subject instanceof ProjectPositionView;
        },
    },
    watch: {
        isActive(value) {
            if (value) {
                this.freightDescription = this.subject.freightDescription;
            }
        },
    },
    methods: {
        closeModal() {
            this.clearValidation();
            this.$store.dispatch('globalActions/reset', ACTIONS.PROJECT_POSITION_FREIGHT_DESCRIPTION_CHANGE);
        },
        async saveFreightDescription() {
            if (!this.isValid()) return;
            await this.stateful('saveFreightDescription', async () => {
                try {
                    const payload = await ProjectPositionApi.transformProjectPosition({
                        ...this.subject.unfold(),
                        freightDescription: this.freightDescription,
                    });
                    await ProjectPositionApi.save(payload);

                    Toaster.success(this.$t('components.shipmentFreightInformation.save.successToaster'));

                    this.$eventHub.$emit(EVENT_PROJECT_POSITION_UPDATED, this.subject);
                    this.closeModal();
                } catch (err) {
                    Toaster.error(err);
                }
            });
        },
    },
};
</script>

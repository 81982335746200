<template>
    <div
        class="flyout__content flyout__content--page"
        :class="{
            'flyout__content--grey-background': backgroundGrey,
            'flyout__content--no-flex': noFlex,
        }"
    >
        <div v-if="showHeader || $slots.header" class="flyout__header">
            <slot name="header">
                <HeaderBar v-if="showHeader">
                    <template v-if="!closeByX" #left>
                        <HeaderBarItem button data-test="flyout-back-button" @click="closeFlyout">
                            <SfSysArrowLeftIcon size="xs" />
                        </HeaderBarItem>
                    </template>

                    <template #headline>{{ headline || $t($route.meta.title) }}</template>
                    <template v-if="subline" #subline>{{ subline }}</template>

                    <template v-if="closeByX" #right>
                        <HeaderBarItem button data-test="flyout-close-button" @click="closeFlyout">
                            <SfSysCloseIcon size="xs" />
                        </HeaderBarItem>
                    </template>
                </HeaderBar>
            </slot>
            <slot name="subheader" />
        </div>

        <div
            v-scrollable
            :class="{
                'flyout__inner--no-header': !$slots.header,
                'flyout__inner--no-footer': !$slots.footer,
            }"
            class="flyout__inner scroll-container"
            v-on="scrollContainerEvents"
        >
            <slot />
            <slot name="additional" />
            <div class="flyout__inner-spacer">&nbsp;</div>
        </div>

        <div v-if="$slots.footer" class="flyout__footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';

import { SfSysArrowLeftIcon, SfSysCloseIcon } from '@schuettflix/vue-components';

export default {
    name: 'FlyoutPage',
    components: {
        HeaderBar,
        HeaderBarItem,
        SfSysCloseIcon,
        SfSysArrowLeftIcon,
    },
    props: {
        noFlex: {
            type: Boolean,
            default: false,
        },
        scrollContainerEvents: {
            type: Object,
            default: null,
        },
        closeByX: {
            type: Boolean,
            default: false,
        },
        showHeader: {
            type: Boolean,
            default: false,
        },
        headline: {
            type: String,
            default: null,
        },
        subline: {
            type: String,
            default: null,
        },
        backgroundGrey: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        closeFlyout() {
            this.$emit('close', true);
        },
    },
};
</script>

<style lang="scss">
.flyout__content--page {
    background-color: $color-lightGrey;
    display: flex;
    flex-flow: column nowrap;
    height: var(--view-height);
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
}

.flyout__content--grey-background {
    background-color: $color-lightMediumGrey;
}

.flyout__content--no-flex {
    .flyout__inner {
        display: block;
    }
}
</style>

import LogService from '@schuettflix/util-log';

import ConstructionSiteSelectionPage from './ConstructionSiteSelectionPage';
import QuantitySelectionPage from './QuantitySelectionPage';
import PickupQuantitySelectionPage from './PickupQuantitySelectionPage';
import AdditionalInformationPage from './AdditionalInformationPage';
import SchedulingPage from './SchedulingPage';
import DeliveryTimeSelectionPage from './DeliveryTimeSelectionPage';
import DeliveryWindowSelectionPage from './DeliveryWindowSelectionPage';
import { DeliverySupplierSelectionPage } from './DeliverySupplierSelectionPage';
import { PickupSupplierSelectionPage } from './PickupSupplierSelectionPage';
import SummaryPage from './SummaryPage/SummaryPage';
import ConfirmationPage from './ConfirmationPage';
import ChildRenderer from '@/components/Router/ChildRenderer';
import CheckoutRenderer from './components/CheckoutRenderer';
import ShipmentLoadingPage from './ShipmentLoadingPage';
import ShipmentUnloadingPage from './ShipmentUnloadingPage';
import ShipmentVehicleClassPage from './ShipmentVehicleClassPage';
import ShipmentDocumentPage from './ShipmentDocumentPage';
import ShipmentSchedulingPage from './ShipmentSchedulingPage';
import ShipmentWindowSelectionPage from './ShipmentWindowSelectionPage';
import ShipmentTimeSelectionPage from './ShipmentTimeSelectionPage';
import ShipmentPriceAdjustmentsPage from './ShipmentPriceAdjustmentsPage';
import ShipmentBillingDetailsPage from './ShipmentBillingDetailsPage';
import ProjectPositionSelectionPage from '@/pages/Checkout/ProjectPositionSelectionPage';
import EntryPage from './EntryPage';
import { CategoriesListPage } from './CategoriesListPage';

import store from '@/store';
import QuoteService from '@/services/QuoteService';
import {
    CHECKOUT_PROJECT_POSITION_DELIVERY_ROUTE_ENTRY_POINT,
    CHECKOUT_PROJECT_POSITION_SHIPMENT_ROUTE_ENTRY_POINT,
    CHECKOUT_PROJECT_POSITION_DISPOSAL_HAZARDOUS_ROUTE_ENTRY_POINT,
    CHECKOUT_PROJECT_POSITION_DISPOSAL_NON_HAZARDOUS_ROUTE_ENTRY_POINT,
} from '@/constants/routeNames';
import { ProductDefinitionPage } from '@/pages/Checkout/ProductDefinitionPage';
import { findRouteName } from '@/pages/routerUtils';
import _ from 'lodash';
import { ProductSelectionPage } from '@/pages/Checkout/ProductSelectionPage';
import { CategoryDetailsPage } from '@/pages/Checkout/CategoryDetailsPage';

const Log = new LogService('pages/Checkout/index');

/**
 * Checkout guard validator
 * @param {string[]} selectors
 * @return {boolean}
 */
export function hasStoreEntries(selectors = []) {
    return selectors.every(selector => {
        return _.get(store.state.basket, selector) !== null && _.get(store.state.basket, selector) !== undefined;
    });
}

export function checkoutGuard(selectors = []) {
    return (to, from, next) => {
        const isValid = hasStoreEntries(selectors);

        if (!isValid) {
            next({ name: findRouteName(to.meta.previous) });
            return false;
        }

        next();
        return true;
    };
}

const deliveryRoute = {
    path: 'delivery',
    name: 'delivery',
    component: ChildRenderer,
    meta: {
        name: 'checkout-delivery',
        requiresAuth: true,
        requiredAbilities: ['createDeliveryQuote'],
    },
    children: [
        {
            path: '',
            name: 'checkout-delivery',
            component: ConstructionSiteSelectionPage,
            meta: {
                previous: 'checkout',
                next: 'checkout-delivery-product-selection',
                title: 'pages.checkout.constructionSiteSelection.title',
            },
        },
        {
            path: 'product-selection',
            name: 'checkout-delivery-product-selection',
            component: ProductSelectionPage,
            meta: {
                previous: 'checkout-delivery',
                next: 'checkout-delivery-product-definition',
                nextCategoryDetail: 'checkout-delivery-category-details',
                nextCategoriesList: 'checkout-delivery-categories-list',
                title: 'pages.checkout.productSelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite']),
        },
        {
            path: 'categories-list',
            name: 'checkout-delivery-categories-list',
            component: CategoriesListPage,
            meta: {
                previous: 'checkout-delivery-product-selection',
                next: 'checkout-delivery-product-definition',
                nextCategoryDetail: 'checkout-delivery-category-details',
                title: 'pages.checkout.productSelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite']),
        },
        {
            path: 'category-details',
            name: 'checkout-delivery-category-details',
            component: CategoryDetailsPage,
            meta: {
                previous: 'checkout-delivery-product-selection',
                previousCategoriesList: 'checkout-delivery-categories-list',
                next: 'checkout-delivery-product-definition',
                title: 'pages.checkout.categorySelection.title',
            },
        },
        {
            path: 'product-definition',
            name: 'checkout-delivery-product-definition',
            component: ProductDefinitionPage,
            meta: {
                previous: 'checkout-delivery-product-selection',
                previousWithCategorySelected: 'checkout-delivery-category-details',
                next: 'checkout-delivery-quantity-selection',
            },
            beforeEnter: checkoutGuard(['product', 'constructionSite', 'type']),
        },
        {
            path: 'quantity',
            name: 'checkout-delivery-quantity-selection',
            component: QuantitySelectionPage,
            meta: {
                previous: 'checkout-delivery-product-definition',
                next: 'checkout-delivery-shipping-method',
                title: 'pages.checkout.quantitySelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product']),
        },
        {
            path: 'delivery-type',
            name: 'checkout-delivery-shipping-method',
            component: SchedulingPage,
            meta: {
                previous: 'checkout-delivery-quantity-selection',
                next: 'checkout-delivery-supplier-selection',
                nextDateSelect: 'checkout-delivery-time-selection',
                nextWindowSelect: 'checkout-delivery-window-selection',
                title: 'pages.checkout.schedulingPage.delivery.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product', 'transports']),
        },
        {
            path: 'delivery-window',
            name: 'checkout-delivery-window-selection',
            component: DeliveryWindowSelectionPage,
            meta: {
                previous: 'checkout-delivery-shipping-method',
                next: 'checkout-delivery-supplier-selection',
                title: 'pages.checkout.deliveryWindowSelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product', 'transports']),
        },
        {
            path: 'delivery-time',
            name: 'checkout-delivery-time-selection',
            component: DeliveryTimeSelectionPage,
            meta: {
                previous: 'checkout-delivery-shipping-method',
                next: 'checkout-delivery-supplier-selection',
                title: 'pages.checkout.deliveryTimeSelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product', 'transports', 'deliveryMethod']),
        },
        {
            path: 'supplier-selection',
            name: 'checkout-delivery-supplier-selection',
            component: DeliverySupplierSelectionPage,
            meta: {
                previous: 'checkout-delivery-shipping-method',
                previousDateSelect: 'checkout-delivery-time-selection',
                previousWindowSelect: 'checkout-delivery-window-selection',
                next: 'checkout-delivery-additional-information',
                title: 'pages.checkout.deliverySupplierSelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product', 'transports', 'deliveryMethod']),
        },
        {
            path: 'additional-information',
            name: 'checkout-delivery-additional-information',
            component: AdditionalInformationPage,
            meta: {
                previous: 'checkout-delivery-supplier-selection',
                next: 'checkout-delivery-summary',
                title: 'pages.checkout.additionalInformation.title',
                subtitle: 'pages.checkout.additionalInformation.subtitle',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product', 'transports', 'deliveryMethod', 'supplierInfo']),
        },
        {
            path: 'summary',
            name: 'checkout-delivery-summary',
            component: SummaryPage,
            meta: {
                previous: 'checkout-delivery-additional-information',
                next: 'checkout-main',
                noHeader: true,
                title: 'pages.checkout.summary.title',
            },
            beforeEnter: checkoutGuard([
                'constructionSite',
                'product',
                'transports',
                'additionalInformation',
                'deliveryMethod',
                'supplierInfo',
            ]),
        },
    ],
    beforeEnter(to, from, next) {
        store.state.basket.type ? next() : next({ name: 'home' });
    },
};

const projectPositionDeliveryRoute = {
    path: 'project-position-delivery',
    name: 'project-position-delivery',
    component: ChildRenderer,
    meta: {
        name: 'checkout-project-position-delivery',
        requiresAuth: true,
        requiredAbilities: ['createProjectPositionQuote'],
    },
    children: [
        {
            path: 'quantity',
            name: CHECKOUT_PROJECT_POSITION_DELIVERY_ROUTE_ENTRY_POINT,
            component: QuantitySelectionPage,
            meta: {
                previous: 'checkout-project-position',
                next: 'checkout-project-position-delivery-shipping-method',
                title: 'pages.checkout.quantitySelection.title',
            },
            beforeEnter: checkoutGuard(['projectPosition', 'constructionSite', 'product']),
        },
        {
            path: 'delivery-type',
            name: 'checkout-project-position-delivery-shipping-method',
            component: SchedulingPage,
            meta: {
                previous: CHECKOUT_PROJECT_POSITION_DELIVERY_ROUTE_ENTRY_POINT,
                next: 'checkout-project-position-delivery-additional-information',
                nextDateSelect: 'checkout-project-position-delivery-time-selection',
                nextWindowSelect: 'checkout-project-position-delivery-window-selection',
                title: 'pages.checkout.schedulingPage.delivery.title',
            },
            beforeEnter: checkoutGuard(['projectPosition', 'constructionSite', 'product', 'transports']),
        },
        {
            path: 'delivery-window',
            name: 'checkout-project-position-delivery-window-selection',
            component: DeliveryWindowSelectionPage,
            meta: {
                previous: 'checkout-project-position-delivery-shipping-method',
                next: 'checkout-project-position-delivery-additional-information',
                title: 'pages.checkout.deliveryWindowSelection.title',
            },
            beforeEnter: checkoutGuard(['projectPosition', 'constructionSite', 'product', 'transports']),
        },
        {
            path: 'delivery-time',
            name: 'checkout-project-position-delivery-time-selection',
            component: DeliveryTimeSelectionPage,
            meta: {
                previous: 'checkout-project-position-delivery-shipping-method',
                next: 'checkout-project-position-delivery-additional-information',
                title: 'pages.checkout.deliveryTimeSelection.title',
            },
            beforeEnter: checkoutGuard([
                'projectPosition',
                'constructionSite',
                'product',
                'transports',
                'deliveryMethod',
            ]),
        },
        {
            path: 'additional-information',
            name: 'checkout-project-position-delivery-additional-information',
            component: AdditionalInformationPage,
            meta: {
                previous: 'checkout-project-position-delivery-shipping-method',
                previousDateSelect: 'checkout-project-position-delivery-time-selection',
                previousWindowSelect: 'checkout-project-position-delivery-window-selection',
                next: 'checkout-project-position-delivery-summary',
                title: 'pages.checkout.additionalInformation.title',
                subtitle: 'pages.checkout.additionalInformation.subtitle',
            },
            beforeEnter: checkoutGuard([
                'projectPosition',
                'constructionSite',
                'product',
                'transports',
                'deliveryMethod',
            ]),
        },
        {
            path: 'summary',
            name: 'checkout-project-position-delivery-summary',
            component: SummaryPage,
            meta: {
                previous: 'checkout-project-position-delivery-additional-information',
                next: 'checkout-main',
                noHeader: true,
                title: 'pages.checkout.summary.title',
            },
            beforeEnter: checkoutGuard([
                'projectPosition',
                'constructionSite',
                'product',
                'transports',
                'additionalInformation',
                'deliveryMethod',
            ]),
        },
    ],
    beforeEnter(to, from, next) {
        store.state.basket.type ? next() : next({ name: 'home' });
    },
};

const projectPositionShipmentRoute = {
    path: 'project-position-shipment',
    name: 'project-position-shipment',
    component: ChildRenderer,
    meta: {
        name: 'checkout-project-position-shipment',
        requiresAuth: true,
        requiredAbilities: ['createProjectPositionQuote'],
    },
    children: [
        {
            path: 'vehicle-class',
            name: CHECKOUT_PROJECT_POSITION_SHIPMENT_ROUTE_ENTRY_POINT,
            component: ShipmentVehicleClassPage,
            meta: {
                previous: 'checkout-project-position',
                next: 'checkout-project-position-shipment-quantity',
                title: 'pages.checkout.shipmentVehicleClassPage.title',
            },
            beforeEnter: checkoutGuard(['projectPosition']),
        },
        {
            path: 'quantity',
            name: 'checkout-project-position-shipment-quantity',
            component: ShipmentDocumentPage,
            meta: {
                previous: CHECKOUT_PROJECT_POSITION_SHIPMENT_ROUTE_ENTRY_POINT,
                next: 'checkout-project-position-shipment-planning-type',
                title: 'pages.checkout.shipmentDocumentPage.onlyQuantity.title',
            },
            beforeEnter: checkoutGuard(['projectPosition', 'shipmentVehicleClassId']),
        },
        {
            path: 'planning-type',
            name: 'checkout-project-position-shipment-planning-type',
            component: ShipmentSchedulingPage,
            meta: {
                previous: 'checkout-project-position-shipment-quantity',
                next: 'checkout-project-position-shipment-summary',
                nextDateSelect: 'checkout-project-position-shipment-time-selection',
                nextWindowSelect: 'checkout-project-position-shipment-window-selection',
                title: 'pages.checkout.shipmentSchedulingPage.title',
            },
            beforeEnter: checkoutGuard(['projectPosition', 'shipmentVehicleClassId', 'shipmentQtyAndDocument']),
        },
        {
            path: 'shipment-window',
            name: 'checkout-project-position-shipment-window-selection',
            component: ShipmentWindowSelectionPage,
            meta: {
                previous: 'checkout-project-position-shipment-planning-type',
                next: 'checkout-project-position-shipment-summary',
                title: 'pages.checkout.shipmentWindowSelection.title',
            },
            beforeEnter: checkoutGuard([
                'projectPosition',
                'shipmentVehicleClassId',
                'shipmentQtyAndDocument',
                'planningMethod',
            ]),
        },
        {
            path: 'shipment-time',
            name: 'checkout-project-position-shipment-time-selection',
            component: ShipmentTimeSelectionPage,
            meta: {
                previous: 'checkout-project-position-shipment-planning-type',
                next: 'checkout-project-position-shipment-summary',
                title: 'pages.checkout.shipmentTimeSelection.title',
            },
            beforeEnter: checkoutGuard([
                'projectPosition',
                'shipmentVehicleClassId',
                'shipmentQtyAndDocument',
                'planningMethod',
            ]),
        },

        {
            path: 'summary',
            name: 'checkout-project-position-shipment-summary',
            component: SummaryPage,
            meta: {
                previous: 'checkout-project-position-shipment-planning-type',
                next: 'checkout-main',
                noHeader: true,
                title: 'pages.checkout.summary.title',
            },
            beforeEnter: async (to, from, next) => {
                const requiredBasketProperties = [
                    'projectPosition',
                    'shipmentVehicleClassId',
                    'shipmentQtyAndDocument',
                    'planningMethod',
                ];

                const isValid = hasStoreEntries(requiredBasketProperties);

                if (isValid) {
                    try {
                        await QuoteService.saveQuote();
                    } catch (err) {
                        if (err.code) {
                            store.commit('basket/setServerError', err);
                        } else {
                            Log.error(err);
                        }
                    }
                }

                checkoutGuard(requiredBasketProperties)(to, from, next);
            },
        },
    ],
};
const projectPositionDisposalNonHazardousRoute = {
    path: 'project-position-disposal-non-hazardous',
    name: 'project-position-disposal-non-hazardous',
    component: ChildRenderer,
    meta: {
        name: 'checkout-project-position-disposal',
        requiresAuth: true,
        requiredAbilities: ['createProjectPositionQuote'],
    },
    children: [
        {
            path: 'vehicle-class',
            name: CHECKOUT_PROJECT_POSITION_DISPOSAL_NON_HAZARDOUS_ROUTE_ENTRY_POINT,
            component: ShipmentVehicleClassPage,
            meta: {
                previous: 'checkout-project-position',
                next: 'checkout-project-position-disposal-non-hazardous-quantity',
                title: 'pages.checkout.shipmentVehicleClassPage.title',
            },
            beforeEnter: checkoutGuard(['projectPosition']),
        },
        {
            path: 'quantity',
            name: 'checkout-project-position-disposal-non-hazardous-quantity',
            component: ShipmentDocumentPage,
            meta: {
                previous: CHECKOUT_PROJECT_POSITION_DISPOSAL_HAZARDOUS_ROUTE_ENTRY_POINT,
                next: 'checkout-project-position-disposal-non-hazardous-shipping-method',
                title: 'pages.checkout.shipmentDocumentPage.onlyQuantity.title',
            },
            beforeEnter: checkoutGuard(['projectPosition', 'disposalVehicleClassId']),
        },
        {
            path: 'planning-type',
            name: 'checkout-project-position-disposal-non-hazardous-shipping-method',
            component: SchedulingPage,
            meta: {
                previous: 'checkout-project-position-disposal-non-hazardous-quantity',
                next: 'checkout-project-position-disposal-non-hazardous-summary',
                nextDateSelect: 'checkout-project-position-disposal-non-hazardous-time-selection',
                nextWindowSelect: 'checkout-project-position-disposal-non-hazardous-window-selection',
                title: 'pages.checkout.shipmentSchedulingPage.title',
            },
            beforeEnter: checkoutGuard(['projectPosition', 'disposalVehicleClassId', 'disposalQtyAndDocument']),
        },
        {
            path: 'disposal-window',
            name: 'checkout-project-position-disposal-non-hazardous-window-selection',
            component: DeliveryWindowSelectionPage,
            meta: {
                previous: 'checkout-project-position-disposal-non-hazardous-shipping-method',
                next: 'checkout-project-position-disposal-non-hazardous-additional-information',
                title: 'pages.checkout.shipmentWindowSelection.title',
            },
            beforeEnter: checkoutGuard([
                'projectPosition',
                'disposalVehicleClassId',
                'disposalQtyAndDocument',
                'deliveryMethod',
            ]),
        },
        {
            path: 'disposal-time',
            name: 'checkout-project-position-disposal-non-hazardous-time-selection',
            component: DeliveryTimeSelectionPage,
            meta: {
                previous: 'checkout-project-position-disposal-non-hazardous-shipping-method',
                next: 'checkout-project-position-disposal-non-hazardous-additional-information',
                title: 'pages.checkout.shipmentTimeSelection.title',
            },
            beforeEnter: checkoutGuard([
                'projectPosition',
                'disposalVehicleClassId',
                'disposalQtyAndDocument',
                'deliveryMethod',
            ]),
        },
        {
            path: 'additional-information',
            name: 'checkout-project-position-disposal-non-hazardous-additional-information',
            component: AdditionalInformationPage,
            meta: {
                previous: 'checkout-project-position-disposal-non-hazardous-shipping-method',
                previousDateSelect: 'checkout-project-position-disposal-non-hazardous-time-selection',
                previousWindowSelect: 'checkout-project-position-disposal-non-hazardous-window-selection',
                next: 'checkout-project-position-disposal-non-hazardous-summary',
                title: 'pages.checkout.disposal.additionalInformation.title',
                subtitle: 'pages.checkout.additionalInformation.subtitle',
            },
            beforeEnter: checkoutGuard([
                'projectPosition',
                'disposalVehicleClassId',
                'disposalQtyAndDocument',
                'deliveryMethod',
            ]),
        },
        {
            path: 'summary',
            name: 'checkout-project-position-disposal-non-hazardous-summary',
            component: SummaryPage,
            meta: {
                previous: 'checkout-project-position-disposal-non-hazardous-additional-information',
                next: 'checkout-main',
                noHeader: true,
                title: 'pages.checkout.summary.title',
            },
            beforeEnter: async (to, from, next) => {
                const requiredBasketProperties = [
                    'projectPosition',
                    'disposalVehicleClassId',
                    'disposalQtyAndDocument',
                    'deliveryMethod',
                    'additionalInformation',
                ];

                checkoutGuard(requiredBasketProperties)(to, from, next);
            },
        },
    ],
};

const projectPositionDisposalHazardousRoute = {
    path: 'project-position-disposal-hazardous',
    name: 'project-position-disposal-hazardous',
    component: ChildRenderer,
    meta: {
        name: 'checkout-project-position-disposal',
        requiresAuth: true,
        requiredAbilities: ['createProjectPositionQuote'],
    },
    children: [
        {
            path: 'vehicle-class',
            name: CHECKOUT_PROJECT_POSITION_DISPOSAL_HAZARDOUS_ROUTE_ENTRY_POINT,
            component: ShipmentVehicleClassPage,
            meta: {
                previous: 'checkout-project-position',
                next: 'checkout-project-position-disposal-hazardous-quantity',
                title: 'pages.checkout.shipmentVehicleClassPage.title',
            },
            beforeEnter: checkoutGuard(['projectPosition']),
        },
        {
            path: 'quantity',
            name: 'checkout-project-position-disposal-hazardous-quantity',
            component: ShipmentDocumentPage,
            meta: {
                previous: CHECKOUT_PROJECT_POSITION_DISPOSAL_HAZARDOUS_ROUTE_ENTRY_POINT,
                next: 'checkout-project-position-disposal-hazardous-shipping-method',
                title: 'pages.checkout.shipmentDocumentPage.onlyQuantity.title',
            },
            beforeEnter: checkoutGuard(['projectPosition', 'disposalVehicleClassId']),
        },
        {
            path: 'planning-type',
            name: 'checkout-project-position-disposal-hazardous-shipping-method',
            component: SchedulingPage,
            meta: {
                previous: 'checkout-project-position-disposal-hazardous-quantity',
                next: 'checkout-project-position-disposal-hazardous-summary',
                nextDateSelect: 'checkout-project-position-disposal-hazardous-time-selection',
                nextWindowSelect: 'checkout-project-position-disposal-hazardous-window-selection',
                title: 'pages.checkout.shipmentSchedulingPage.title',
            },
            beforeEnter: checkoutGuard(['projectPosition', 'disposalVehicleClassId', 'disposalQtyAndDocument']),
        },
        {
            path: 'disposal-window',
            name: 'checkout-project-position-disposal-hazardous-window-selection',
            component: DeliveryWindowSelectionPage,
            meta: {
                previous: 'checkout-project-position-disposal-hazardous-shipping-method',
                next: 'checkout-project-position-disposal-hazardous-additional-information',
                title: 'pages.checkout.shipmentWindowSelection.title',
            },
            beforeEnter: checkoutGuard([
                'projectPosition',
                'disposalVehicleClassId',
                'disposalQtyAndDocument',
                'deliveryMethod',
            ]),
        },
        {
            path: 'disposal-time',
            name: 'checkout-project-position-disposal-hazardous-time-selection',
            component: DeliveryTimeSelectionPage,
            meta: {
                previous: 'checkout-project-position-disposal-hazardous-shipping-method',
                next: 'checkout-project-position-disposal-hazardous-additional-information',
                title: 'pages.checkout.shipmentTimeSelection.title',
            },
            beforeEnter: checkoutGuard([
                'projectPosition',
                'disposalVehicleClassId',
                'disposalQtyAndDocument',
                'deliveryMethod',
            ]),
        },
        {
            path: 'additional-information',
            name: 'checkout-project-position-disposal-hazardous-additional-information',
            component: AdditionalInformationPage,
            meta: {
                previous: 'checkout-project-position-disposal-hazardous-shipping-method',
                previousDateSelect: 'checkout-project-position-disposal-hazardous-time-selection',
                previousWindowSelect: 'checkout-project-position-disposal-hazardous-window-selection',
                next: 'checkout-project-position-disposal-hazardous-summary',
                title: 'pages.checkout.disposal.additionalInformation.title',
                subtitle: 'pages.checkout.additionalInformation.subtitle',
            },
            beforeEnter: checkoutGuard([
                'projectPosition',
                'disposalVehicleClassId',
                'disposalQtyAndDocument',
                'deliveryMethod',
            ]),
        },
        {
            path: 'summary',
            name: 'checkout-project-position-disposal-hazardous-summary',
            component: SummaryPage,
            meta: {
                previous: 'checkout-project-position-disposal-hazardous-additional-information',
                next: 'checkout-main',
                noHeader: true,
                title: 'pages.checkout.summary.title',
            },
            beforeEnter: async (to, from, next) => {
                const requiredBasketProperties = [
                    'projectPosition',
                    'disposalVehicleClassId',
                    'disposalQtyAndDocument',
                    'deliveryMethod',
                    'additionalInformation',
                ];

                checkoutGuard(requiredBasketProperties)(to, from, next);
            },
        },
    ],
};

const projectPositionRoute = {
    path: 'project-position',
    name: 'project-position',
    component: ChildRenderer,
    meta: {
        name: 'checkout-project-position',
        requiresAuth: true,
        requiredAbilities: ['createProjectPositionQuote'],
    },
    children: [
        {
            path: '',
            name: 'checkout-project-position',
            component: ProjectPositionSelectionPage,
            meta: {
                previous: 'checkout',
                title: 'pages.checkout.projectPositionSelection.title',
            },
        },
        projectPositionDeliveryRoute,
        projectPositionShipmentRoute,
        projectPositionDisposalHazardousRoute,
        projectPositionDisposalNonHazardousRoute,
    ],
};

const pickupRoute = {
    path: 'pickup',
    name: 'pickup',
    component: ChildRenderer,
    meta: {
        name: 'checkout-pickup',
        requiresAuth: true,
        requiredAbilities: ['createPickupQuote'],
    },
    children: [
        {
            path: '',
            name: 'checkout-pickup',
            component: ConstructionSiteSelectionPage,
            meta: {
                previous: 'checkout',
                next: 'checkout-pickup-product-selection',
                title: 'pages.checkout.constructionSiteSelection.title',
            },
        },
        {
            path: 'product-selection',
            name: 'checkout-pickup-product-selection',
            component: ProductSelectionPage,
            meta: {
                previous: 'checkout-pickup',
                next: 'checkout-pickup-product-definition',
                nextCategoryDetail: 'checkout-pickup-category-details',
                nextCategoriesList: 'checkout-pickup-categories-list',
                title: 'pages.checkout.productSelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite']),
        },
        {
            path: 'categories-list',
            name: 'checkout-pickup-categories-list',
            component: CategoriesListPage,
            meta: {
                previous: 'checkout-pickup-product-selection',
                next: 'checkout-pickup-product-definition',
                nextCategoryDetail: 'checkout-pickup-category-details',
                title: 'pages.checkout.productSelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite']),
        },
        {
            path: 'category-details',
            name: 'checkout-pickup-category-details',
            component: CategoryDetailsPage,
            meta: {
                previous: 'checkout-pickup-product-selection',
                previousCategoriesList: 'checkout-pickup-categories-list',
                next: 'checkout-pickup-product-definition',
                title: 'pages.checkout.categorySelection.title',
            },
        },
        {
            path: 'product-definition',
            name: 'checkout-pickup-product-definition',
            component: ProductDefinitionPage,
            meta: {
                previous: 'checkout-pickup-product-selection',
                previousWithCategorySelected: 'checkout-pickup-category-details',
                next: 'checkout-pickup-quantity-selection',
            },
            beforeEnter: checkoutGuard(['product', 'constructionSite']),
        },
        {
            path: 'quantity',
            name: 'checkout-pickup-quantity-selection',
            component: PickupQuantitySelectionPage,
            meta: {
                previous: 'checkout-pickup-product-definition',
                next: 'checkout-pickup-supplier-selection',
                title: 'pages.checkout.quantitySelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product']),
        },
        {
            path: 'supplier',
            name: 'checkout-pickup-supplier-selection',
            component: PickupSupplierSelectionPage,
            meta: {
                previous: 'checkout-pickup-quantity-selection',
                next: 'checkout-pickup-summary',
                title: 'pages.checkout.supplierSelection.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product', 'qty']),
        },
        {
            path: 'summary',
            name: 'checkout-pickup-summary',
            component: SummaryPage,
            meta: {
                previous: 'checkout-pickup-supplier-selection',
                next: 'checkout-main',
                noHeader: true,
                title: 'pages.checkout.summary.title',
            },
            beforeEnter: checkoutGuard(['constructionSite', 'product', 'qty', 'supplierInfo']),
        },
    ],
    beforeEnter(to, from, next) {
        store.state.basket.type ? next() : next({ name: 'home' });
    },
};

const shipmentRoute = {
    path: 'shipment',
    name: 'shipment',
    component: ChildRenderer,
    meta: {
        name: 'checkout-shipment',
        requiresAuth: true,
        requiredAbilities: ['createShipmentQuote'],
    },
    children: [
        {
            path: '',
            name: 'checkout-shipment',
            component: ShipmentLoadingPage,
            meta: {
                previous: 'checkout',
                next: 'checkout-shipment-unloading',
                title: 'pages.checkout.shipmentLoading.title',
            },
        },
        {
            path: 'unloading',
            name: 'checkout-shipment-unloading',
            component: ShipmentUnloadingPage,
            meta: {
                previous: 'checkout-shipment',
                next: 'checkout-shipment-vehicle-class',
                title: 'pages.checkout.shipmentUnloading.title',
            },
        },
        {
            path: 'vehicle-class',
            name: 'checkout-shipment-vehicle-class',
            component: ShipmentVehicleClassPage,
            meta: {
                previous: 'checkout-shipment-unloading',
                next: 'checkout-shipment-vehicle-and-document',
                title: 'pages.checkout.shipmentVehicleClassPage.title',
            },
        },
        {
            path: 'document',
            name: 'checkout-shipment-vehicle-and-document',
            component: ShipmentDocumentPage,
            meta: {
                previous: 'checkout-shipment-vehicle-class',
                next: 'checkout-shipment-planning-type',
                title: 'pages.checkout.shipmentDocumentPage.title',
            },
        },
        {
            path: 'planning-type',
            name: 'checkout-shipment-planning-type',
            component: ShipmentSchedulingPage,
            meta: {
                previous: 'checkout-shipment-vehicle-and-document',
                next: 'checkout-shipment-billing-selection',
                nextDateSelect: 'checkout-shipment-time-selection',
                nextWindowSelect: 'checkout-shipment-window-selection',
                title: 'pages.checkout.shipmentSchedulingPage.title',
            },
        },
        {
            path: 'shipment-window',
            name: 'checkout-shipment-window-selection',
            component: ShipmentWindowSelectionPage,
            meta: {
                previous: 'checkout-shipment-planning-type',
                next: 'checkout-shipment-billing-selection',
                title: 'pages.checkout.shipmentWindowSelection.title',
            },
        },
        {
            path: 'shipment-time',
            name: 'checkout-shipment-time-selection',
            component: ShipmentTimeSelectionPage,
            meta: {
                previous: 'checkout-shipment-planning-type',
                next: 'checkout-shipment-billing-selection',
                title: 'pages.checkout.shipmentTimeSelection.title',
            },
        },
        {
            path: 'billing',
            name: 'checkout-shipment-billing-selection',
            component: ShipmentBillingDetailsPage,
            meta: {
                previous: 'checkout-shipment-planning-type',
                previousDateSelect: 'checkout-shipment-time-selection',
                previousWindowSelect: 'checkout-shipment-window-selection',
                next: 'checkout-shipment-price-adjustments',
                title: 'pages.checkout.shipmentBillingDetails.title',
            },
        },
        {
            path: 'price-adjustments',
            name: 'checkout-shipment-price-adjustments',
            component: ShipmentPriceAdjustmentsPage,
            meta: {
                previous: 'checkout-shipment-billing-selection',
                next: 'checkout-shipment-summary',
                title: 'pages.checkout.shipmentPriceAdjustments.title',
            },
        },
        {
            path: 'summary',
            name: 'checkout-shipment-summary',
            component: SummaryPage,
            meta: {
                previous: 'checkout-shipment-price-adjustments',
                next: 'checkout-main',
                noHeader: true,
                title: 'pages.checkout.summary.title',
            },
            beforeEnter: checkoutGuard(['shipmentLoading', 'shipmentUnloading', 'shipmentQtyAndDocument']),
        },
    ],
    beforeEnter(to, from, next) {
        store.state.basket.type ? next() : next({ name: 'home' });
    },
};

const checkoutRoutes = {
    path: 'process',
    name: 'checkout-main',
    component: CheckoutRenderer,
    meta: {
        requiresAuth: true,
        requiredAbilities: ['createAnyQuoteInCheckout'],
    },
    children: [
        deliveryRoute,
        projectPositionRoute,
        pickupRoute,
        shipmentRoute,
        {
            path: 'summary/:quoteId',
            name: 'checkout-summary-check',
            component: SummaryPage,
            props: true,
            meta: {
                next: 'checkout-main',
                noHeader: true,
            },
            beforeEnter(to, from, next) {
                if (from && from.name) {
                    localStorage.setItem('checkout-summary-check-previous', from.name);
                }
                to.meta.previous = localStorage.getItem('checkout-summary-check-previous');
                next();
            },
        },
        {
            path: 'confirmation',
            name: 'order-confirmation',
            component: ConfirmationPage,
            meta: {
                noHeader: true,
            },
            beforeEnter(to, from, next) {
                const confirmedQuote = store.state.basket.confirmedQuote;

                if (!confirmedQuote || ['submitted', 'pending'].indexOf(confirmedQuote.status) < 0) {
                    next({ name: 'home' });
                    return;
                }

                next();
            },
        },
    ],
    beforeEnter(to, from, next) {
        const partialRoutesWithoutMandatoryBasket = ['order-confirmation', 'checkout-project-position'];
        const matchedExcludedRoutes = partialRoutesWithoutMandatoryBasket.filter(partialRoute =>
            to.name.match(partialRoute)
        );

        if (matchedExcludedRoutes.length) {
            next();
            return;
        }

        store.state.basket.type ? next() : next({ name: 'home' });
    },
};

export default {
    path: 'checkout',
    name: 'checkout',
    component: EntryPage,
    meta: {
        requiresAuth: true,
    },
    children: [checkoutRoutes],
    beforeEnter(to, from, next) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const constructionProjectId = store.getters['constructionProject/constructionProjectId'];
        const isPlatformAdmin = store.getters['user/isPlatformAdministrator'];

        if (constructionProjectId || (isPlatformAdmin && to.name !== 'order__checkout')) {
            next();
            return;
        }
        next({ name: 'home' });
    },
};

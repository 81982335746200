import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteParams } from '@/reactBridge/useRouteParams';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { useQuery } from '@tanstack/react-query';
import { constructionProjectByIdQuery } from '@/constructionProjects/queries';
import store from '@/store';
import { CONSTRUCTION_PROJECT_CLIENT_SETTINGS_EDIT_ROUTE, CONSTRUCTION_PROJECT_CLIENT_SETTINGS_ROUTE, CONSTRUCTION_PROJECT_DETAILS_ROUTE, } from '@/constructionProjects/constants';
import { MessageBox } from '@schuettflix/react-components';
export const WarningSection = ({ className }) => {
    const { t } = useTranslation();
    const { id: constructionProjectId } = useRouteParams();
    const { vueRouter } = useVueRouter();
    const { data: orderConfirmationMails } = useQuery({
        ...constructionProjectByIdQuery(constructionProjectId),
        suspense: true,
        select: data => data?.orderConfirmationMails,
    });
    if (orderConfirmationMails?.length) {
        return null;
    }
    const navigateToForm = async () => {
        store.commit('constructionProject/setDetailsPageEntryPoint', {
            name: CONSTRUCTION_PROJECT_DETAILS_ROUTE,
        });
        await vueRouter.push({
            name: `${CONSTRUCTION_PROJECT_DETAILS_ROUTE}__${CONSTRUCTION_PROJECT_CLIENT_SETTINGS_ROUTE}__${CONSTRUCTION_PROJECT_CLIENT_SETTINGS_EDIT_ROUTE}`,
            params: { id: constructionProjectId },
        });
    };
    return (<MessageBox type="warning" className={className}>
            <p className="font-copy-md-strong pb-1">
                {t('pages.constructionProject.detailsPage.warning.missingEmail.title')}
            </p>
            <p className="font-copy-md">
                {t('pages.constructionProject.detailsPage.warning.missingEmail.description')}
            </p>
            <div className="mt-4 flex">
                <button onClick={navigateToForm}>
                    <span className="font-copy-md-strong">
                        {t('pages.constructionProject.detailsPage.warning.missingEmail.button.label')} ›
                    </span>
                </button>
            </div>
        </MessageBox>);
};

import AbstractFilterableResource from './AbstractFilterableResource';

class User extends AbstractFilterableResource {
    /**
     * Get all active transports for a user
     * @param {int} userId
     */
    async getActiveTransports(userId) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/${userId}/activetransports`);
            return response.data;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }

    /**
     * Send new password to user
     * @param {int} userId
     */
    async resetPassword(userId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${userId}/resetpassword`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async saveUserData(id, userData) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${id}`, userData);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async acceptTerms() {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/confirmterms`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new User('/v2/user');

<script>
import _throttle from 'lodash/throttle';
import GoogleMaps from '@/services/GoogleMaps';
import { structurizePlaceResult } from '@/services/utils/map';
import {
    formatAddress,
    allowedPlaceAutocompleteCountriesValidator,
    getFallbackPlaceAutocompletionCountries,
} from '@/services/utils/address';

const UPDATE_PREDICTION_THROTTLE_MS = 500;

export default {
    name: 'RenderlessAddressPrediction',
    props: {
        // Address from LocationPicker
        value: {
            type: Object,
            default: null,
        },
        inputId: {
            type: String,
            default: null,
        },
        listLocality: {
            type: Boolean,
            default: false,
        },
        streetLess: {
            type: Boolean,
            default: false,
        },
        focusStreetNumber: {
            type: Boolean,
            default: false,
        },
        includeState: {
            type: Boolean,
            default: false,
        },
        includeCountry: {
            type: Boolean,
            default: false,
        },
        allowedCountries: {
            type: Array,
            default: null,
            validator: allowedPlaceAutocompleteCountriesValidator,
        },
    },
    data() {
        return {
            addressInput: '',
            google: null,
            predictions: [],
            isPredictionsShown: false,
            hidePredictionsTimeout: null,
            autocompleteService: null,
            placeService: null,
            sessionToken: null,
            selectedPrediction: null,

            highlightedIndex: -1,
        };
    },
    computed: {
        visiblePredictions() {
            return this.isPredictionsShown ? this.predictions : null;
        },
        hasValue() {
            return this.addressInput !== null && this.addressInput !== '';
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.selectedPrediction = value;

                    if (value.label) {
                        this.addressInput = value.label;
                        this.hidePredictions();
                    } else {
                        this.addressInput = formatAddress({ address: this.value });
                    }
                } else {
                    this.addressInput = null;
                    this.selectedPrediction = null;
                }
            },
        },
        addressInput(value, oldValue) {
            if (value === '') {
                this.$emit('input', null);
                this.hidePredictions();
            } else if (value === oldValue || this.hidePredictionsTimeout !== null) {
                this.hidePredictions();
            } else {
                this.suggest(value);
            }
        },
    },
    created() {
        GoogleMaps.then(google => {
            this.google = google;
            this.autocompleteService = new google.maps.places.AutocompleteService();
            this.placeService = new google.maps.places.PlacesService(document.createElement('div'));
            this.geocodeService = new google.maps.Geocoder();
            this.sessionToken = new google.maps.places.AutocompleteSessionToken();
        });
    },
    methods: {
        reset() {
            this.addressInput = null;
            this.$emit('input', null);
        },

        suggest(value) {
            this.$nextTick(function () {
                if (this.google && this.autocompleteService && value) {
                    this.updatePredictions(value);
                } else if (value === null) {
                    this.$emit('input', null);
                }
            });
        },

        updatePredictions: _throttle(function (value) {
            const allowedCountries =
                this.allowedCountries !== null ? this.allowedCountries : getFallbackPlaceAutocompletionCountries();

            this.autocompleteService.getPlacePredictions(
                {
                    input: value,
                    componentRestrictions: { country: allowedCountries },
                    sessionToken: this.sessionToken,
                    types: [this.streetLess ? '(regions)' : 'geocode'],
                },
                (predictions, status) => {
                    if (status !== this.google.maps.places.PlacesServiceStatus.OK) {
                        this.$emit('error', status);
                        return;
                    }

                    this.predictions = this.filterPredictions(predictions);
                    this.highlightedIndex = -1;
                    this.showPredictions();
                }
            );
        }, UPDATE_PREDICTION_THROTTLE_MS),

        filterPredictions(predictions) {
            return predictions.filter(item => {
                // Skip countries
                if (item.types.includes('country')) {
                    return false;
                }

                const isStreetLike =
                    item.types.includes('route') ||
                    item.types.includes('premise') ||
                    item.types.includes('street_address');

                if (this.streetLess) {
                    return !isStreetLike;
                } else {
                    return this.listLocality || isStreetLike;
                }
            });
        },

        predictionIsArea(prediction) {
            return !(
                prediction.types.includes('route') ||
                prediction.types.includes('premise') ||
                prediction.types.includes('street_address')
            );
        },

        predictionHasStreetNumber(prediction) {
            return prediction.types.includes('premise') || prediction.types.includes('street_address');
        },

        predictionIsStreet(prediction) {
            return prediction?.types?.includes('route');
        },

        clearPredictions() {
            this.predictions = [];
        },

        showPredictions() {
            this.isPredictionsShown = true;
            this.hidePredictionsTimeout && clearTimeout(this.hidePredictionsTimeout);
        },

        hidePredictions() {
            this.hidePredictionsTimeout = setTimeout(() => {
                this.isPredictionsShown = false;
                this.hidePredictionsTimeout = null;
            }, 200);
        },

        getDetailsFromAddress(address) {
            return new Promise((resolve, reject) => {
                this.geocodeService.geocode(
                    {
                        address,
                    },
                    (detail, status) => {
                        if (status !== 'OK') {
                            reject(status);
                        } else {
                            resolve(detail);
                        }
                    }
                );
            });
        },

        getPlaceDetails(placeId) {
            return new Promise((resolve, reject) => {
                this.placeService.getDetails(
                    {
                        placeId,
                        fields: ['geometry', 'address_component', 'formatted_address'],
                    },
                    (place, status) => {
                        if (status !== this.google.maps.places.PlacesServiceStatus.OK) {
                            reject(status);
                        } else {
                            resolve(place);
                        }
                    }
                );
            });
        },

        async selectPrediction(prediction, triggerStreetNumberFocus, unfocus = false) {
            this.clearPredictions();

            try {
                const place = await this.getPlaceDetails(prediction.place_id);

                const data = structurizePlaceResult(place, {
                    placeId: prediction.place_id,
                    label: prediction.description,
                });

                window.place = place;

                if (
                    this.focusStreetNumber &&
                    triggerStreetNumberFocus &&
                    !data.hasStreetNumber &&
                    this.predictionIsStreet(prediction)
                ) {
                    // insert additional space after street
                    data.label = data.label.replace(
                        data.streetComponent.short_name,
                        `${data.streetComponent.short_name} `
                    );

                    const startIndex = data.label.indexOf(data.streetComponent.short_name) + 1;
                    const endIndex = startIndex + data.streetComponent.short_name.length;

                    setTimeout(() => {
                        const node = document.getElementById(this.inputId);
                        if (node) {
                            node.focus();
                            node.setSelectionRange(endIndex, endIndex);
                        }
                    }, 300);
                }

                this.addressInput = data.label;

                this.$emit('input', data);
                const el = document.activeElement;
                if (unfocus && el && el.nodeName === 'INPUT') {
                    el.blur();
                }
            } catch (err) {
                this.$logger().error(err);
                this.$emit('error', err);
            }
        },
        async handleKeyInput(e) {
            const length = this.predictions.length;

            try {
                if (
                    (e.key === 'Enter' && length === 0) ||
                    (e.key === 'Enter' && length > 0 && this.highlightedIndex === -1)
                ) {
                    const place = await this.getDetailsFromAddress(this.addressInput);
                    if (place[0].types.includes('plus_code')) {
                        this.$emit('plus-code', place[0].geometry.location);
                        return;
                    }

                    this.selectPrediction(
                        {
                            place_id: place[0].place_id,
                            description: place[0].formatted_address,
                        },
                        true
                    );

                    this.clearPredictions();
                    this.hidePredictions();
                } else if (e.key === 'Enter' && length > 0 && this.highlightedIndex !== -1) {
                    this.handleEnterEventOnPrediction();
                } else if (e.key === 'ArrowDown' && length > 0) {
                    this.handleArrowDownEvent(e, length);
                } else if (e.key === 'ArrowUp' && length > 0) {
                    this.handleArrowUpEvent(e);
                }
            } catch (error) {
                this.$logger().error(error);
            }
        },

        handleEnterEventOnPrediction() {
            const prediction = this.predictions[this.highlightedIndex] || this.predictions[0];
            const isStreet = this.predictionIsStreet(prediction);
            const hasStreetNumber = this.predictionHasStreetNumber(prediction);

            this.selectPrediction(
                prediction,
                this.predictionIsStreet(prediction),
                !isStreet || (isStreet && hasStreetNumber)
            );
            this.hidePredictions();
        },

        handleArrowUpEvent(event) {
            event.preventDefault();
            let nextIndex = this.highlightedIndex - 1;

            if (nextIndex < 0) {
                nextIndex = -1;
            }

            this.highlightedIndex = nextIndex;
        },

        handleArrowDownEvent(event, length) {
            event.preventDefault();
            let nextIndex = this.highlightedIndex + 1;

            if (nextIndex >= length) {
                nextIndex = length - 1;
            }

            this.highlightedIndex = nextIndex;
        },

        highlightIndex(index) {
            this.highlightedIndex = index;
        },

        handleInputExit() {
            // no value → no selected address/location → reset input
            if (!this.selectedPrediction) {
                this.reset();
                return;
            }

            const label = this.selectedPrediction.label || formatAddress({ address: this.selectedPrediction });

            // input has changed but no prediction was selected
            if (this.addressInput !== label) {
                // reset input value to selected prediction label
                this.addressInput = label;
            }
        },
    },
    render() {
        return this.$scopedSlots.default({
            inputAttrs: { value: this.addressInput },
            inputEvents: {
                input: e => {
                    if (e.target) {
                        this.addressInput = e.target.value;
                    } else {
                        this.addressInput = e;
                    }
                },
                keydown: this.handleKeyInput,
                focus: this.showPredictions,
            },
            predictions: this.visiblePredictions,
            selectPrediction: this.selectPrediction,
            getPlaceDetails: this.getPlaceDetails,
            predictionHasStreetNumber: this.predictionHasStreetNumber,
            predictionIsStreet: this.predictionIsStreet,
            predictionIsArea: this.predictionIsArea,
            highlightedIndex: this.highlightedIndex,
            highlightIndex: this.highlightIndex,
            hasValue: this.hasValue,
            reset: this.reset,
            handleBlur: this.handleInputExit,
        });
    },
};
</script>

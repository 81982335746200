<template>
    <label
        :class="{
            'checkbox-field--boxed': boxed,
            'checkbox-field--active': isActive,
            'checkbox-field--disabled': disabled,
            'checkbox-field--black': black,
        }"
        class="checkbox-field"
        data-test="checkbox-field"
    >
        <Checkbox
            :value="value"
            :name="name"
            :option="option"
            :disabled="disabled"
            :small="boxed || black || onDarkSurface"
            :multiple="multiple"
            :black="black"
            :on-dark-surface="onDarkSurface"
            class="checkbox-field__input"
            @input="$emit('input', $event)"
        />
        <div
            :class="{
                'checkbox-field__label--disabled': disabled,
            }"
            class="checkbox-field__label"
        >
            <slot />
        </div>
    </label>
</template>

<script>
import Checkbox from '@/components/Form/Checkbox';

export default {
    name: 'CheckboxField',
    components: {
        Checkbox,
    },
    props: {
        name: {
            type: String,
            default: null,
        },
        value: {
            type: [Boolean, Array],
            default: null,
        },
        option: {
            type: [String, Boolean, Number, Object, Array],
            default: null,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        boxed: {
            type: Boolean,
            default: false,
        },
        black: {
            type: Boolean,
            default: false,
        },
        onDarkSurface: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isListInput() {
            return Array.isArray(this.value) && this.option !== null;
        },
        isActive() {
            // if it's an array, check if it contains the option
            if (this.isListInput) {
                return this.value.indexOf(this.option) !== -1;
            }

            return !!this.value;
        },
    },
};
</script>

<style lang="scss">
.checkbox-field {
    display: flex;
}

.checkbox-field--boxed {
    background-color: $color-white;
    border: 1px solid $color-littleDarkerThanMediumGrey;
    padding: 15px 12px;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
}

.checkbox-field--boxed.checkbox-field--active {
    border-color: $color-red;
}

.checkbox-field__input {
    flex: 0 0;
    margin-right: 15px;
}

.checkbox-field__label {
    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: initial;
    flex: 1 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
}

.checkbox-field--boxed .checkbox-field__label {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.checkbox-field--disabled {
    cursor: initial;
}

.checkbox-field__label--disabled {
    color: $color-grey;
    cursor: initial;
}

.checkbox-field__image {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    margin: 0 5px;

    img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid $color-mediumGrey;
    }
}

.checkbox-field__icon {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    margin-right: 20px;

    svg {
        width: auto;
        height: 30px;
    }
}

.checkbox-field--black {
    &.checkbox-field__label--disabled {
        color: $sflx-grey-200;
    }
}
</style>

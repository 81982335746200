import AbstractFilterableResource from './AbstractFilterableResource';

class Supplier extends AbstractFilterableResource {
    async getExpireDateById(factoryId) {
        try {
            const response = await this.apiService.get(`factory/${factoryId}/pickupexpiredate`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new Supplier('/supplier');

<template>
    <div class="vehicle-class-scope-form">
        <Words bold small spaced-bottom block>{{ $t('pages.vehicleClass.scopeForm.headline') }}</Words>

        <GridRow :count="4" spacing="large">
            <SelectBox
                v-model="scopeForm.country"
                :label="$t('pages.vehicleClass.scopeForm.countryLabel')"
                :error="getError('scopeForm.country')"
                :options="countryOptions"
                :disabled="scopeForm.isDefault"
                :option-value-renderer="option => option.value"
                :option-label-renderer="option => option.label"
                class="span-2"
            />

            <TextField
                v-model="scopeForm.scope"
                :error="getError('scopeForm.scope')"
                :disabled="scopeForm.isDefault"
                class="span-2"
                type="text"
                :label="$t('pages.vehicleClass.scopeForm.scopeLabel')"
            />

            <TextField
                v-model="scopeForm.purchasePriceKm"
                :error="getError('scopeForm.purchasePriceKm')"
                class="span-2 span-lg-1"
                type="number"
                step="0.01"
                :label="$t('pages.vehicleClass.scopeForm.purchasePriceKmWithCurrency', { currency: getCurrencySign() })"
            />

            <TextField
                v-model.number="scopeForm.salesPriceKm"
                :error="getError('scopeForm.salesPriceKm')"
                class="span-2 span-lg-1"
                type="number"
                step="0.01"
                :label="$t('pages.vehicleClass.scopeForm.salesPriceKmWithCurrency', { currency: getCurrencySign() })"
            />

            <TextField
                v-model.number="scopeForm.purchasePriceMinute"
                :error="getError('scopeForm.purchasePriceMinute')"
                class="span-2 span-lg-1"
                type="number"
                step="0.01"
                :label="
                    $t('pages.vehicleClass.scopeForm.purchasePriceMinuteWithCurrency', { currency: getCurrencySign() })
                "
            />

            <TextField
                v-model.number="scopeForm.salesPriceMinute"
                :error="getError('scopeForm.salesPriceMinute')"
                class="span-2 span-lg-1"
                type="number"
                step="0.01"
                :label="
                    $t('pages.vehicleClass.scopeForm.salesPriceMinuteWithCurrency', { currency: getCurrencySign() })
                "
            />

            <TextField
                v-model.number="scopeForm.minPurchasePriceTransport"
                :error="getError('scopeForm.minPurchasePriceTransport')"
                class="span-2 span-lg-1"
                type="number"
                step="0.01"
                :label="$t('pages.vehicleClass.scopeForm.minPurchasePriceTransportLabel')"
            />

            <TextField
                v-model.number="scopeForm.minSalesPriceTransport"
                :error="getError('scopeForm.minSalesPriceTransport')"
                class="span-2 span-lg-1"
                type="number"
                step="0.01"
                :label="$t('pages.vehicleClass.scopeForm.minSalesPriceTransportLabel')"
            />

            <ToggleSwitchField
                v-model="scopeForm.isDoubleTransportEffort"
                :error="getError('scopeForm.isDoubleTransportEffort')"
                class="span-4 span-lg-2"
            >
                {{ $t('pages.vehicleClass.scopeForm.isDoubleTransportEffortLabel') }}
            </ToggleSwitchField>

            <ButtonGroup class="span-4" inline>
                <Button v-if="index !== -1" primary light @click="reset">
                    {{ $t('pages.vehicleClass.scopeForm.resetBtnLabel') }}
                </Button>
                <Button primary @click="saveScope">
                    <span v-if="index === -1">{{ $t('pages.vehicleClass.scopeForm.saveBtnLabel') }}</span>
                    <span v-else>{{ $t('pages.vehicleClass.scopeForm.updateBtnLabel') }}</span>

                    <SmallArrowIcon slot="right" class="icon--inline" />
                </Button>
            </ButtonGroup>
        </GridRow>
    </div>
</template>

<script>
import validate from '@/services/validation/mixin';
import { isRequired, isFloat } from '@/services/validation/rules';
import { getCurrencySign } from '@/services/utils/currency';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import GridRow from '@/components/Layout/GridRow';
import SelectBox from '@/components/Form/SelectBox.v3';
import TextField from '@/components/Form/TextField.v2';
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import Words from '@/components/Typography/Words';

import SmallArrowIcon from '@/assets/icons/micro/arrow.svg';

export default {
    name: 'VehicleClassScopeForm',
    components: {
        Button,
        ButtonGroup,
        GridRow,
        SelectBox,
        TextField,
        ToggleSwitchField,
        Words,

        SmallArrowIcon,
    },
    mixins: [validate],
    props: {
        scope: {
            type: Object,
            default: () => {},
        },
        availableCountries: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            index: -1,
            scopeForm: this.getEmptyScope(),
        };
    },
    computed: {
        validationRules() {
            const rules = {
                'scopeForm.purchasePriceKm': [isRequired(), isFloat()],
                'scopeForm.salesPriceKm': [isRequired(), isFloat()],
                'scopeForm.purchasePriceMinute': [isRequired(), isFloat()],
                'scopeForm.salesPriceMinute': [isRequired(), isFloat()],
                'scopeForm.minPurchasePriceTransport': [isRequired(), isFloat()],
                'scopeForm.minSalesPriceTransport': [isRequired(), isFloat()],
            };

            if (this.scopeForm.isDefault) {
                rules['scopeForm.country'] = [];
                rules['scopeForm.scope'] = [];
            } else {
                rules['scopeForm.country'] = [isRequired()];
                rules['scopeForm.scope'] = [isRequired()];
            }

            return rules;
        },

        countryOptions() {
            if (this.scopeForm.isDefault) {
                return [
                    {
                        label: this.$t(`pages.vehicleClass.scopeForm.globalScope`),
                        value: null,
                    },
                ];
            }

            return this.availableCountries.map(country => {
                return {
                    label: this.$t(`countries.${country.toLowerCase()}.name`),
                    value: country,
                };
            });
        },
    },
    watch: {
        scope(newVal) {
            this.index = newVal.id;
            this.scopeForm = { ...newVal.scope };
        },
    },
    methods: {
        getCurrencySign,

        getEmptyScope() {
            return {
                country: null,
                scope: null,
                purchasePriceKm: null,
                salesPriceKm: null,
                purchasePriceMinute: null,
                salesPriceMinute: null,
                isDefault: false,
                isDoubleTransportEffort: false,
                minPurchasePriceTransport: null,
                minSalesPriceTransport: null,
            };
        },
        reset() {
            this.index = -1;
            this.scopeForm = this.getEmptyScope();
            this.clearValidation();
        },
        saveScope() {
            if (!this.isValid()) {
                return;
            }

            this.$emit('submit', this.index, this.scopeForm);
            this.reset();
        },
    },
};
</script>

<style lang="scss"></style>

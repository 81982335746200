import _get from 'lodash/get';
import { ensurePHPTimestamp } from '@/services/utils/date';
import TransportPricingCollectionView from '@/pages/Checkout/components/PriceAdjustment/models/TransportPricingCollectionView';
import { toKgPrice } from '@/services/utils/units';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';

/**
 * Get wrapped id by selector
 *
 * @param {Object} state
 * @param {string} selector
 *
 * @return {*}|null
 */
export function getIdObject(state, selector) {
    const id = _get(state, selector, null);

    return id === null ? null : { id };
}

/**
 * Generate line items from transport step for delivery transport
 *
 * @param {Object} transportStep
 *
 * @return {Array}
 */
export function generateDeliveryTransportLineItems(transportStep) {
    if (!transportStep || !Array.isArray(transportStep?.transports)) return [];

    return transportStep.transports.map(transport => {
        return {
            shippingDate: transport.date,
            qty: transport.actualLoad,
            vehicleClassId: transport.id,
        };
    });
}

/**
 * Get base line item Group data
 *
 * @param {Object} state
 *
 * @return {{constructionSite: *, factory: *, product: *, qty: *}|{}}
 */
export function getDeliveryPickupBaseLineItemGroupData(state) {
    if (!state) {
        return {};
    }

    const isProjectPositionDelivery = state.type === 'project-position-delivery';

    const factory = isProjectPositionDelivery
        ? state.projectPosition.factory.id
        : getIdObject(state, 'supplierInfo.supplier.factoryId');

    return {
        constructionSite: getIdObject(state, 'constructionSite.id'),
        qty: _get(state, 'qty', null),
        product: getIdObject(state, 'product.id'),
        factory,
    };
}

/**
 * Custom position
 * @typedef {Object} CustomPosition
 * @property {string|*} name
 * @property {string|*} unit
 * @property {number|*} qty
 * @property {number|*} count
 * @property {number|*} unitPurchasePrice
 * @property {number|*} unitRetailPrice
 * @property {boolean|*} unitRetailPrice
 */

/**
 * Normalize custom position
 *
 * @param {object} entireCustomPosition
 * @return CustomPosition
 */
export function getNormalizedCustomPosition({ name, unit, qty, count, purchaseUnitPrice, retailUnitPrice, creditTo }) {
    return {
        name,
        unit,
        qty,
        count,
        unitPurchasePrice: purchaseUnitPrice,
        unitRetailPrice: retailUnitPrice,
        creditTo,
    };
}

/**
 * Get flat list of custom positions from pricesData
 *
 * @param {object} state
 * @return {CustomPosition[]}
 */
export function getCustomPositions(state) {
    if (!state) {
        return [];
    }

    const allPositions = Object.values(state.pricesData?.customPositions || [])
        .map(customPositions => customPositions.positions)
        .flat();

    return allPositions.map(getNormalizedCustomPosition);
}

/**
 * Normalize shipment address
 *
 * @param address
 * @return {{zip: *, country: *, number: *, city: *, street: *, state: *, additionalAddress: *}}
 */
export function getNormalizedShipmentAddress({ country, state, city, zip, street, number, additionalAddress }) {
    return { country, state, city, zip, street, number, additionalAddress };
}

/**
 * Assemble shipment location info based on type
 *
 * @param {string} type
 * @param {Object} state
 *
 * @return {Object}
 */
export function getShipmentLocationInfo(type, state) {
    if (!['loading', 'unloading'].includes(type)) {
        throw new Error('Invalid type for location info');
    }

    const key = `shipment${type.charAt(0).toUpperCase()}${type.slice(1)}`;
    const getValue = (search, defaultValue = null) => {
        return _get(state, `${key}.${search}`, defaultValue);
    };

    return {
        [`${type}SiteLocation`]: getValue('location'),
        [`${type}SiteInformation`]: getValue('description'),
        [`${type}SiteImage`]: getValue('photo'),
        [`${type}Name`]: getValue('address.name'),
        [`${type}Address`]: getNormalizedShipmentAddress(getValue('address', {})),
        [`${type}AddressType`]: getValue('address.type'),
        [`${type}AddressCostCenter`]: getValue('costCenter'),
        [`${type}Location`]: getValue('loadingLocation') || getValue('location'),
        [`${type}ContactPerson`]: getValue('contact.name'),
        [`${type}ContactPhone`]: getValue('contact.phone'),
        [`${type}ImportantInformation`]: getValue('loadingImportantInformation'),
        [`${type}AddressConstructionSiteId`]: getValue(`${type}AddressConstructionSiteId`),
    };
}

/**
 * Assemble shipment shipping window data
 *
 * @param {Object} state
 *
 * @return {Object}
 */
export function getShipmentShippingWindow(state) {
    if (!state) {
        return {};
    }

    if (!['default', 'loading', 'unloading'].includes(state.planningMethod)) {
        throw new Error('Invalid planning method provided');
    }

    const getValue = search => _get(state, `shipmentShippingWindow.${search}`);

    if (state.planningMethod === 'default') {
        return {
            [`loadingDateFrom`]: ensurePHPTimestamp(getValue('loading.date.start')),
            [`loadingDateTo`]:
                ensurePHPTimestamp(getValue('loading.date.end')) || ensurePHPTimestamp(getValue('loading.date.start')),
            [`loadingTimeFrom`]: getValue('loading.time.startTime'),
            [`loadingTimeTo`]: getValue('loading.time.endTime'),

            [`unloadingDateFrom`]: ensurePHPTimestamp(getValue('unloading.date.start')),
            [`unloadingDateTo`]:
                ensurePHPTimestamp(getValue('unloading.date.end')) ||
                ensurePHPTimestamp(getValue('unloading.date.start')),
            [`unloadingTimeFrom`]: getValue('unloading.time.startTime'),
            [`unloadingTimeTo`]: getValue('unloading.time.endTime'),
        };
    }

    if (state.planningMethod === 'loading') {
        return {
            [`loadingTimeFrom`]: state.timeConstraintFrom ?? null,
            [`loadingTimeTo`]: state.timeConstraintTo ?? null,
        };
    }

    return {
        [`unloadingTimeFrom`]: state.timeConstraintFrom ?? null,
        [`unloadingTimeTo`]: state.timeConstraintTo ?? null,
    };
}

/**
 * Get shipment planning method
 * @param state
 * @return {string}
 */
export function getShipmentPlanningMethod(state) {
    return state.planningMethod === 'default' ? 'flexible' : 'planned';
}

/**
 * Get shipment planning base
 * @param state
 * @return {string|null}
 */
export function getShipmentPlanningBase(state) {
    return state.planningMethod !== 'default' ? state.planningMethod : null;
}

/**
 * Generate shipment line items
 *
 * @param {Object} state
 *
 * @return {Array}
 */
export function generateShipmentLineItems(state) {
    if (!state) {
        return [];
    }

    if (!['default', 'loading', 'unloading'].includes(state.planningMethod)) {
        throw new Error('Invalid planning method provided');
    }

    if (state.planningMethod === 'default') {
        return Array.from({ length: _get(state, 'shipmentQtyAndDocument.transportCount', 0) }, () => {
            return {
                vehicleClassId: state.shipmentVehicleClassId,
            };
        });
    }

    return state.transports.transports.map(transport => {
        return {
            shippingDate: transport.date,
            vehicleClassId: transport.id,
        };
    });
}

/**
 * Pick uuid from object
 *
 * @param {Object}
 *
 * @return {{uuid: *}}
 */
export function pickUuid({ uuid }) {
    return { uuid };
}

/**
 * Generate line item groups
 *
 * @param {Object} state
 *
 * @return {Array}
 */
export function generateLineItemGroups(state) {
    switch (state.type) {
        case 'pickup':
            return [
                {
                    ...getDeliveryPickupBaseLineItemGroupData(state),
                    type: ORDER_LINE_ITEM_GROUP_TYPE.PICKUP,
                    sellerProductId: _get(state, 'supplierInfo.sellerProductId', null),
                },
            ];
        case 'shipment':
            return [
                {
                    type: ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT,
                    ...getShipmentLocationInfo('loading', state),
                    ...getShipmentLocationInfo('unloading', state),
                    ...getShipmentShippingWindow(state),
                    freightDescription: _get(state, 'shipmentQtyAndDocument.description', null),
                    carrier: getIdObject(state, 'carrierInfo.id'),
                    lineItems: generateShipmentLineItems(state),
                    freightDocuments: _get(state, 'shipmentQtyAndDocument.documents', []).map(pickUuid),
                    freightImages: _get(state, 'shipmentQtyAndDocument.images', []).map(pickUuid),
                    freightType: _get(state, 'shipmentQtyAndDocument.type', null),
                    freightDisposalProcedure: _get(state, 'shipmentQtyAndDocument.disposalProcedure', null),
                    customPositions: _get(state, 'quote.lineItemGroups[0].customPositions', null),
                    billingMethod: _get(state, 'shipmentBillingDetails.billingMethod', null),
                    weighingBillingBasis: _get(state, 'shipmentBillingDetails.weighingBillingBasis', null),
                    weighingAtLoadingPoint: _get(state, 'shipmentBillingDetails.weighingAtLoadingPoint', null),
                    weighingAtUnloadingPoint: _get(state, 'shipmentBillingDetails.weighingAtUnloadingPoint', null),
                    planningMethod: getShipmentPlanningMethod(state),
                    planningBase: getShipmentPlanningBase(state),
                },
            ];

        case 'project-position-delivery':
            return [
                {
                    projectPositionId: state.projectPosition.id,
                    type: 'project-position-transport-delivery',
                    unloadingSiteLocation: _get(state, 'additionalInformation.unloadingSiteLocation', null),
                    unloadingSiteInformation: _get(state, 'additionalInformation.description', null),
                    unloadingSiteImage: _get(state, 'additionalInformation.photo', null),
                    shippingMethod: _get(state, 'deliveryMethod.name', null),
                    shippingWindowStart: _get(state, 'deliveryMethod.deliveryWindow.start', null),
                    shippingWindowEnd: _get(state, 'deliveryMethod.deliveryWindow.end', null),
                    lineItems: generateDeliveryTransportLineItems(state.transports),
                },
            ];

        case 'project-position-shipment':
            return [
                {
                    projectPositionId: state.projectPosition.id,
                    type: 'project-position-transport-shipment',
                    ...getShipmentShippingWindow(state),
                    lineItems: generateShipmentLineItems(state),
                    planningMethod: getShipmentPlanningMethod(state),
                    planningBase: getShipmentPlanningBase(state),
                },
            ];

        case 'project-position-waste':
        case 'project-position-dangerousWaste':
            return [
                {
                    projectPositionId: state.projectPosition.id,
                    type: replaceTypeForProjectOrder(state.type),
                    loadingSiteLocation: _get(state, 'additionalInformation.loadingSiteLocation', null),
                    loadingSiteInformation: _get(state, 'additionalInformation.description', null),
                    loadingSiteImage: _get(state, 'additionalInformation.photo', null),
                    shippingMethod: _get(state, 'deliveryMethod.name', null),
                    shippingWindowStart: _get(state, 'deliveryMethod.deliveryWindow.start', null),
                    shippingWindowEnd: _get(state, 'deliveryMethod.deliveryWindow.end', null),
                    lineItems: generateDeliveryTransportLineItems(state.transports),
                },
            ];

        case 'delivery':
        default:
            return [
                {
                    ...getDeliveryPickupBaseLineItemGroupData(state),
                    type: ORDER_LINE_ITEM_GROUP_TYPE.DELIVERY,
                    cutOff: _get(state, 'deliveryMethod.cutOffTime', null),
                    shippingWindowStart: _get(state, 'deliveryMethod.deliveryWindow.start', null),
                    shippingWindowEnd: _get(state, 'deliveryMethod.deliveryWindow.end', null),
                    unloadingSiteLocation: _get(state, 'additionalInformation.unloadingSiteLocation', null),
                    unloadingSiteInformation: _get(state, 'additionalInformation.description', null),
                    unloadingSiteImage: _get(state, 'additionalInformation.photo', null),
                    inputType: _get(state, 'transports.inputType', null),
                    shippingMethod: _get(state, 'deliveryMethod.name', null),
                    sellerProductId: _get(state, 'supplierInfo.sellerProductId', null),
                    carrier: getIdObject(state, 'carrierInfo.id'),
                    lineItems: generateDeliveryTransportLineItems(state.transports),
                },
            ];
    }
}

/**
 * Generate partial (delta) data for project position
 *
 * @param {string} type
 * @return {string}
 */
function replaceTypeForProjectOrder(type) {
    return type.includes('dangerousWaste')
        ? type.replace('dangerousWaste', ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_HAZARDOUS_WASTE)
        : type.replace('waste', ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_NON_HAZARDOUS_WASTE);
}

/**
 * Generate partial (delta) data for project position
 *
 * @param {object} state
 * @return {object}
 */
function generatePartialProjectPositionRequestPayload(state) {
    const payload = {};

    payload.project = { id: state.projectId };
    payload.constructionProjectId = state.constructionProjectId;
    payload.sellerProductId = state.sellerProductId;
    payload.qty = state.qty;
    payload.validFrom = state.validityRange.start;
    payload.validTo = state.validityRange.end;
    payload.status = state.status;
    payload.paymentTerms = state.paymentTerms;

    if (state.carrierInfo) {
        payload.carrier = { id: state.carrierInfo.id };
    }

    const transportCollection = TransportPricingCollectionView.create(state.pricesData.transport);

    const vehicleClasses = [];

    transportCollection.collection.forEach(vc => {
        if (!vc.enabled) return;

        vehicleClasses.push({
            vehicleClass: {
                id: vc.id,
            },
            purchasePrice: toKgPrice(vc.purchaseUnitPrice.getPerTon()),
            retailPrice: toKgPrice(vc.retailUnitPrice.getPerTon()),
        });
    });

    payload.vehicleClasses = vehicleClasses;

    return payload;
}

/**
 * Generate data for delivery project position
 *
 * @param {object} state
 * @return {object}
 */
export function generateProjectDeliveryPositionRequestPayload(state) {
    const payload = generatePartialProjectPositionRequestPayload(state);
    payload.type = 'delivery';

    payload.constructionSite = { id: state.constructionSite.id };
    payload.product = { id: state.product.id };
    payload.factory = { id: state.supplierInfo.supplier.factoryId };
    payload.materialPurchaseUnitPrice = state.pricesData.material.purchaseUnitPrice;
    payload.materialRetailUnitPrice = state.pricesData.material.retailUnitPrice;
    payload.shippingPurchasePrice = state.pricesData.shipping.purchasePrice;
    payload.shippingRetailPrice = state.pricesData.shipping.retailPrice;
    payload.sellerProductId = state.supplierInfo.sellerProductId;

    return payload;
}

/**
 * Return disposal price as a subset of a projectPosition
 * @param state
 * @return {{disposalPricePerTon: null|Number, disposalPricePerTransport: null|Number}|{}}
 */
export function getDisposalPricePart(state) {
    const price = state.disposalPrice;

    if (price === null || price === undefined) return {};

    const billingMethod = state.shipmentBillingDetails?.billingMethod;

    switch (billingMethod) {
        case 'fixedPrice':
            return {
                disposalPricePerTon: null,
                disposalPricePerTransport: price,
            };
        case 'weight':
            return {
                disposalPricePerTon: price,
                disposalPricePerTransport: null,
            };
        default:
            throw new Error(`billingMethod ${billingMethod} is not supported by getDisposalPricePart`);
    }
}

/**
 * Generate data for shipment project position
 * @param {object} state
 * @return {object}
 */
export function generateProjectShipmentPositionRequestPayload(state) {
    let payload = generatePartialProjectPositionRequestPayload(state);
    payload.type = 'shipment';

    payload.customPositions = getCustomPositions(state);

    payload.billingMethod = _get(state, 'shipmentBillingDetails.billingMethod', null);
    payload.weighingAtLoadingPoint = _get(state, 'shipmentBillingDetails.weighingAtLoadingPoint', null);
    payload.weighingAtUnloadingPoint = _get(state, 'shipmentBillingDetails.weighingAtUnloadingPoint', null);
    payload.weighingBillingBasis = _get(state, 'shipmentBillingDetails.weighingBillingBasis', null);

    payload.freightDescription = _get(state, 'shipmentQtyAndDocument.description', null);
    payload.freightDocuments = _get(state, 'shipmentQtyAndDocument.documents', []).map(pickUuid);
    payload.freightImages = _get(state, 'shipmentQtyAndDocument.images', []).map(pickUuid);
    payload.freightType = _get(state, 'shipmentQtyAndDocument.type', null);
    payload.freightDisposalProcedure = _get(state, 'shipmentQtyAndDocument.disposalProcedure', null);

    payload = { ...payload, ...getShipmentLocationInfo('loading', state) };
    payload = { ...payload, ...getShipmentLocationInfo('unloading', state) };

    payload = { ...payload, ...getDisposalPricePart(state) };

    return payload;
}

<template>
    <div
        :class="{
            'image-upload--inline': inline,
            'image-upload--light': light,
        }"
        class="image-upload"
    >
        <button
            :class="{
                'progress-button--loading': isStateSaving,
                'progress-button--success': isStateSuccess,
                'progress-button--error': isStateError,
                'progress-button--hidden': passiveMode,
                needsclick: true,
            }"
            :disabled="isStateSaving"
            class="progress-button"
            type="button"
            data-test="button-confirm-delivery-upload-photo"
            @click="triggerFileSelect"
        >
            <span class="progress-button__content">
                <span v-if="isStateSaving">{{ $t('components.imageUpload.uploading') }}</span>
                <span v-else>
                    <ImageIcon class="progress-button__icon icon--inline" />&nbsp;&nbsp;&nbsp;
                    {{ label || $t('components.imageUpload.selectImage') }}
                </span>
            </span>
            <span class="progress-button__progress">
                <span :style="{ width: `${progress}%` }" class="progress-button__progress-inner" />
            </span>
        </button>

        <ModalActions v-if="$root.isApp" ref="selectSource">
            <BaseButton primary @click="appSelectImage">
                {{ $t('components.imageUpload.selectExistingImage') }}
                <ImageIcon slot="left" class="progress-button__icon icon--inline" />
            </BaseButton>
            <BaseButton primary @click="appTakeImage">
                {{ $t('components.imageUpload.newImage') }}
                <CameraIcon slot="left" class="progress-button__icon icon--inline" />
            </BaseButton>
        </ModalActions>
        <input
            v-else
            id="file"
            ref="imageUploader"
            type="file"
            class="image-upload__input needsclick"
            :accept="acceptedFileTypes"
            @change="onFileChange"
        />
    </div>
</template>

<script>
import { computed, watch, ref, getCurrentInstance } from 'vue';
import { STATES, useImageUpload } from '@/services/useClientFile';

import loadImage from 'blueimp-load-image';
import { dataURItoBlob } from '@/services/utils';

import NativeImage from '@/services/Image/NativeImage';
import { isFileTypeAccepted, fileTypeString } from '@/services/utils/file';

import BaseButton from '@/components/Button/Button';
import ModalActions from '@/components/Modal/ModalActions';
import ImageIcon from '@/assets/icons/regular/image.svg';
import CameraIcon from '@/assets/icons/regular/camera.svg';

export default {
    name: 'ImageUpload',
    components: {
        ModalActions,
        BaseButton,
        ImageIcon,
        CameraIcon,
    },
    props: {
        maxSize: {
            type: Number,
            default: 25,
        },
        data: {
            type: Object,
            default: () => {},
        },
        inline: {
            type: Boolean,
            default: false,
        },
        light: {
            type: Boolean,
            default: false,
        },
        disableUpload: {
            type: Boolean,
            default: false,
        },
        passiveMode: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        productUseCase: {
            type: String,
            default: null,
        },
    },
    setup(props, { emit }) {
        const { uploadImage, onEverySuccess, onEveryError, progress, uploadedImageData, state } = useImageUpload();

        const isStateSaving = computed(() => state.value === STATES.SAVING);
        const isStateSuccess = computed(() => state.value === STATES.SUCCESS);
        const isStateError = computed(() => state.value === STATES.ERROR);
        const imageUploader = ref(null);

        const instance = getCurrentInstance().proxy;

        onEverySuccess(data => {
            if (!instance.$root.isApp) {
                imageUploader.value = '';
            }
            emit('input', data);
            emit('uploadingFinished');
        });

        onEveryError(() => {
            emit('error', instance.$t('components.imageUpload.validationFailed'));
        });

        watch(progress, value => emit('progress', value));

        const upload = async file => {
            emit('uploadingStarted');
            await uploadImage({
                file,
                meta: {
                    productUseCase: props.productUseCase,
                },
            });
        };

        return {
            upload,
            progress,
            uploadedImageData,
            imageUploader,
            isStateSaving,
            isStateSuccess,
            isStateError,
        };
    },
    computed: {
        acceptedFileTypes() {
            return this.acceptedFileTypesAsArray.join(', ');
        },
        acceptedFileTypesAsArray() {
            return ['image/jpeg', 'image/jpg', 'image/png'];
        },
    },
    methods: {
        openModal(refName) {
            this.$refs[refName].$emit('open');
        },

        closeModal(refName) {
            this.$refs[refName].$emit('close');
        },

        triggerFileSelect() {
            if (this.$root.isApp) {
                this.openModal('selectSource');
            } else {
                const clickEvent = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: false, // disable: fastclick's cancel
                });

                this.$refs.imageUploader.value = null;
                this.$refs.imageUploader.dispatchEvent(clickEvent);
            }
        },

        onFileChange($event) {
            this.$emit('error', null);
            const files = $event.target.files;
            const file = (files.length > 0 && files[0]) || null;

            const maxFileSize = this.maxSize * 1024 * 1024;

            if (!file) {
                return;
            }
            if (!isFileTypeAccepted(this.acceptedFileTypes, file.type)) {
                this.$emit(
                    'error',
                    this.$t('components.imageDocumentUploader.errors.dataTypeNotSupported', {
                        fileTypeString: fileTypeString(this.acceptedFileTypesAsArray),
                    })
                );
                return;
            }

            if (file.size > maxFileSize) {
                this.$emit('error', this.$t('components.imageUpload.limitExceeded', { size: this.maxSize }));
            } else if (this.disableUpload) {
                loadImage(
                    file,
                    img => {
                        const orientedFile = dataURItoBlob(img.toDataURL());
                        this.$emit('input', orientedFile);
                    },
                    { orientation: true, canvas: true }
                );
            } else {
                this.upload(file);
            }
        },

        appFetchImage(sourceType) {
            this.$emit('error', null);
            this.closeModal('selectSource');

            NativeImage.getAppPicture(sourceType)
                .then(({ image }) => {
                    if (this.disableUpload) {
                        this.$emit('input', image);
                    } else {
                        this.upload(image);
                    }
                })
                .catch(error => {
                    if (NativeImage.NO_IMAGE_SELECTED_ERRORS.indexOf(error) < 0) {
                        this.$emit('error', this.$t('components.imageUpload.nativeError'));
                    }
                });
        },

        appTakeImage() {
            this.appFetchImage(NativeImage.CAMERA);
        },

        appSelectImage() {
            this.appFetchImage(NativeImage.LIBRARY);
        },

        resetInput() {
            if (!this.$root.isApp) {
                this.$refs.imageUploader.value = '';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.image-upload__input {
    display: none;
}

.progress-button {
    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: initial;
    position: relative;
    display: block;
    padding: 0 20px;
    outline: none;
    border: none;
    background: $color-red !important;
    color: $color-white;
    overflow: hidden;
    line-height: 70px;
    font-family: $font-family;
    font-weight: $font-weight-regular;
    font-size: $font-size-base;
    min-width: 200px;
    width: 100%;

    @media screen and (min-width: $screen-md) {
        line-height: 50px;
    }

    &[disabled],
    &[disabled].progress-button--loading {
        cursor: default;
    }

    &:not(.progress-button--loading):not(.progress-button--success):not(.progress-button--error) {
        &:hover {
            .progress-button__content {
                transform: scale(1.1);
            }
        }
    }
}

.progress-button__icon {
    height: 15px;
}

.image-upload--inline {
    .progress-button {
        display: inline-block;
        width: auto;
    }
}

.progress-button__content {
    position: relative;
    display: block;
    z-index: 10;
    transition: transform 0.3s;

    &::before,
    &::after {
        position: absolute;
        top: 100%;
        left: 50%;
        color: $color-white;
        opacity: 0;
        transition: opacity 0.3s;
        transform: translateX(-50%);
    }

    &::before {
        content: '✓';
    }

    &::after {
        content: '✗';
    }

    svg {
        .f-base,
        .f-highlight {
            fill: $color-white;
        }

        .s-base,
        .s-highlight {
            stroke: $color-white;
        }
    }
}

.progress-button--success,
.progress-button--error {
    .progress-button__content {
        transform: translateY(-100%);
    }
}

.progress-button--success .progress-button__content::before,
.progress-button--error .progress-button__content::after {
    opacity: 1;
}

.progress-button--hidden {
    display: none;
}

.progress-button__progress {
    background: $color-red;
}

.progress-button__progress-inner {
    position: absolute;
    top: 0;
    width: 0;
    left: 0;
    height: 100%;
    background: $color-darkRed;
    transition:
        width 0.3s,
        opacity 0.3s;
}

.image-upload--light {
    .progress-button {
        background-color: $color-white;
        color: $color-base;

        .progress-button__content {
            svg {
                .f-base {
                    fill: inherit;
                }

                .f-highlight {
                    fill: $color-red;
                }

                .s-base {
                    stroke: inherit;
                }

                .s-highlight {
                    stroke: $color-red;
                }
            }
        }
    }

    .progress-button__progress-inner {
        background: $color-lightGrey;
    }
}
</style>

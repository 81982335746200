<template>
    <div class="progress-display" data-test="progress-display">{{ progressOutput }}</div>
</template>

<script>
export default {
    name: 'ProgressDisplay',
    props: {
        progress: {
            type: [Number, String],
            default: 0,
        },
    },
    computed: {
        progressOutput() {
            return `${this.progress}%`;
        },
    },
};
</script>

<style lang="scss" scoped>
.progress-display {
    align-self: center;
    background: linear-gradient(90deg, #619b52 0%, #a0cd40 100%);
    border-radius: 4px;
    color: $color-white;
    font-size: 10px;
    font-weight: 700;
    line-height: 1.219;
    margin-right: 16px;
    padding: 4px 8px;
}
</style>

export const defaultState = () => ({
    error: false,
    carrierInfo: null,
    clientName: null,
    constructionSite: null,
    constructionSiteState: null,
    disposer: {},
    documents: [],
    exemptionTranslation: null,
    factoryProductVariantNote: null,
    images: [],
    isBilledByStateWasteCompany: null,
    otherDocuments: [],
    pricesData: null,
    temporaryTransportPrices: null,
    customPrices: {
        purchaseUnitPrice: null,
        retailUnitPrice: null,
    },
    product: {},
    projectPositionSelectionErrors: null,
    putToTransportMarket: false,
    stateWasteCompanies: [],
    carrierNumberFromOrganization: null,
    defaultPaymentTerms: [],
    requestCollection: {
        analysis: {
            exemption: null,
        },
        accompanyingAnalysisImageUuids: [],
        additionalInternalTitle: null,
        automaticAccompanyingDocumentCreation: false,
        billingMethod: null,
        billingByStateWasteCompanyId: -1,
        carrier: {
            id: -1,
        },
        carrierNumber: null,
        consistency: null,
        constructionSite: {
            id: -1,
        },
        customPositions: null,
        disposalProofNumber: null,
        documentUuids: [],
        factoryProductVariantId: -1,
        qty: 0,
        isPretreated: null,
        paymentTerms: [],
        pretreatmentDescription: null,
        producerNumber: null,
        project: {
            id: -1,
        },
        type: null,
        vehicleClasses: [],
        note: null,
        validityRange: null,
        status: null,
    },
});

const state = defaultState();

export default state;

<template>
    <div class="chat-bubble">
        <svg class="chat-bubble__icon" viewBox="0 0 9.25232 8.99141">
            <path d="M9.25232,8.99141Q3.93856,6.56944,3.93856,0S.61328,2.494,0,8.9912Z" />
        </svg>
        <div class="chat-bubble__content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChatBubble',
};
</script>

<style lang="scss">
.chat-bubble {
    position: relative;
    padding-top: 9px;
}

.chat-bubble__content {
    background: $color-red;
    border-radius: 7px;
    color: $color-white;
    padding: 12px 14px;
}

.chat-bubble__icon {
    position: absolute;
    top: 0;
    left: 16px;
    width: 10px;
    height: 9px;

    path {
        fill: $color-red;
    }
}
</style>

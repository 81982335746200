import Vue from 'vue';
import NotificationApi from '@/services/Api/Notification';

const NOTIFICATION_UPDATE_INTERVAL = 30000;
let notificationInterval = null;
let notificationPending = null;

const state = {
    token: null,
    oldToken: null,
    registered: false,
    notifications: {},
    unreadCount: 0,
};

const getters = {
    token: state => state.token,
    notifications: state => state.notifications,
    unreadCount: state => state.unreadCount,
};

const mutations = {
    addNotification(state, payload) {
        Vue.set(state.notifications, payload.hash, payload);
    },
    removeNotification(state, payload) {
        Vue.delete(state.notifications, payload);
    },
    clearNotifications(state) {
        state.notifications = {};
    },
    register(state) {
        state.registered = true;
    },
    unregister(state) {
        state.registered = false;
    },
    setToken(state, payload) {
        state.oldToken = state.token;
        state.token = payload;
    },
    setUnreadCount(state, payload) {
        state.unreadCount = payload;
    },
    deleteOldToken(state) {
        state.oldToken = null;
    },
};

const actions = {
    registerToken({ state, dispatch, commit, rootGetters }, registerToken = null) {
        const token = registerToken || state.token;

        if (token === null || token === undefined || rootGetters['abilities/can']('unimpersonate')) {
            return;
        }

        if (token !== state.token) {
            commit('unregister');
            commit('setToken', token);
        }

        if (!rootGetters['user/isLoggedIn']) {
            return;
        }

        if (state.oldToken !== null) {
            dispatch('deleteOldToken');
        }

        NotificationApi.updatePushNotificationToken(token)
            .then(() => {
                commit('register');
            })
            .catch(() => {
                commit('unregister');
            });
    },

    deleteCurrentToken({ state, commit, rootGetters }) {
        if (state.registered && !rootGetters['abilities/can']('unimpersonate')) {
            NotificationApi.deletePushNotificationToken(state.token);
            commit('deleteOldToken');
            commit('unregister');
        }
    },

    deleteOldToken({ state, commit, rootGetters }) {
        if (rootGetters['abilities/can']('unimpersonate')) {
            return;
        }

        NotificationApi.deletePushNotificationToken(state.oldToken)
            .then(() => {
                commit('deleteOldToken');
            })
            .catch(() => {});
    },

    fetchUnreadCount({ commit, rootGetters }) {
        if (notificationInterval) {
            return;
        }

        const fetchUnreadCount = () => {
            if (!rootGetters['user/isLoggedIn'] || notificationPending) return;

            notificationPending = true;
            NotificationApi.filter({ unread: true }, null, [], null, {}, true)
                .then(result => {
                    commit('setUnreadCount', result.count);
                })
                .finally(() => {
                    notificationPending = false;
                });
        };

        notificationInterval = setInterval(fetchUnreadCount, NOTIFICATION_UPDATE_INTERVAL);
        fetchUnreadCount();
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

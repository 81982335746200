<template>
    <Card no-padding spaceless class="cardion">
        <button class="cardion__trigger" @click="toggle()">
            <Words block bold>{{ headline }}</Words>
            <MinusIcon v-if="isOpen" class="icon--mono" />
            <PlusIcon v-else class="icon--mono" />
        </button>
        <div v-if="isOpen" class="cardion__content">
            <slot />
        </div>
    </Card>
</template>

<script>
import Card from '@/components/Layout/Card';
import Words from '@/components/Typography/Words';

import PlusIcon from '@/assets/icons/micro/plus.svg';
import MinusIcon from '@/assets/icons/micro/minus.svg';

export default {
    name: 'Cardion',
    components: {
        Card,
        Words,

        PlusIcon,
        MinusIcon,
    },
    props: {
        headline: {
            type: String,
            default: null,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    watch: {
        active(next) {
            this.isOpen = next;
        },
    },
    mounted() {
        this.isOpen = this.active;
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>

<style scoped lang="scss">
.cardion + .cardion {
    margin-top: 1px;
}

.cardion__trigger {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 0;
    background-color: transparent;
    cursor: pointer;
}

.cardion__trigger,
.cardion__content {
    padding: 15px;
}
</style>

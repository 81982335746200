import AbstractResource from '../AbstractResource';

class Category extends AbstractResource {
    /**
     * Get sort configuration by id
     * @param {int} id
     */
    async getSortById(id = null) {
        const url = id === null ? `${this.resourcePath}/sort` : `${this.resourcePath}/sort/${id}`;
        try {
            const response = await this.apiService.get(url);
            return response.data;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }

    /**
     * Update sort configuration by id
     * @param {int} id
     */
    async updateSortById(id = null, data = {}) {
        const url = id === null ? `${this.resourcePath}/sort` : `${this.resourcePath}/sort/${id}`;
        try {
            const response = await this.apiService.post(url, data);
            return response.data;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }

    /**
     * get categories of type
     */
    async getCategoryByType(type) {
        try {
            const response = await this.apiService.get(this.resourcePath, { params: { type } });
            return response.data;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }

    /**
     * Save category
     * @param {object} category
     */
    async saveCategory(category, type) {
        try {
            const { id, ...categoryData } = category;
            const suffix = id ? `/${id}` : '';
            const response = await this.apiService.post(`${this.resourcePath}${suffix}`, {
                ...categoryData,
                type,
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new Category('/platform/category', {
    404: 'category.errors.notFound',
    500: 'category.errors.serverError',
});

<template>
    <LayoutPage
        id="vehicle-class-list"
        :parent-route="parentRoute"
        screen-name="platform-vehicleclassmanagement"
        background-grey
        :child-routes="childRoutes"
    >
        <div slot="desktopHeaderNavigation">
            <Button arrow-left small class="page__navigation-back-button" @click="goToSettingsPage">
                {{ $t('pages.settingsV2.title') }}
            </Button>
        </div>
        <div slot="pageTitle">
            {{ $route.params.pageTitle ? $route.params.pageTitle : $t('pages.vehicleClass.title') }}
        </div>

        <DataTable clickable>
            <template #header>
                <tr>
                    <th scope="col">{{ $t('pages.vehicleClass.heading.identifier') }}</th>
                    <th scope="col">{{ $t('pages.vehicleClass.heading.activeMarkets') }}</th>
                    <th scope="col">{{ $t('pages.vehicleClass.heading.status') }}</th>
                </tr>
            </template>

            <tr v-for="item in vehicleClasses.items" :key="item.id" @click="edit(item)">
                <td>{{ item.name }} ({{ item.identifier }})</td>

                <!-- Country name will be dynamic in the future after the API is ready and i18n is implemented -->
                <td>
                    <Words small>{{ getMarketNames(item) }}</Words>
                </td>

                <td>
                    <Tag :green="item.isActive" :muted="!item.isActive">
                        {{
                            item.isActive
                                ? $t('pages.vehicleClass.status.active')
                                : $t('pages.vehicleClass.status.inactive')
                        }}
                    </Tag>
                </td>
            </tr>
        </DataTable>
    </LayoutPage>
</template>

<script>
import { EVENT_VEHICLE_CLASS_UPDATED } from '@/constants/events';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import VehicleClassApi from '@/services/Api/VehicleClass';
import Toaster from '@/services/Toaster';
import { navigationFailure } from '@/services/utils/router';

import Button from '@/components/Button/Button';
import LayoutPage from '@/components/Layout/Page.v2';
import DataTable from '@/components/Table/DataTable';
import Tag from '@/components/Typography/Tag';
import Words from '@/components/Typography/Words';

export default {
    name: 'VehicleClassListPage',
    components: {
        Button,
        DataTable,
        LayoutPage,
        Tag,
        Words,
    },
    mixins: [eventHubMixin],
    props: {
        parentRoute: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            vehicleClasses: [],
        };
    },

    computed: {
        childRoutes() {
            return ['management__settings__vehicle-class-management__vehicle-class-edit'];
        },
    },

    created() {
        this.updateList();

        this.subscribe(EVENT_VEHICLE_CLASS_UPDATED, () => {
            this.updateList();
        });
    },
    methods: {
        toggleFlyout() {
            this.active = !this.active;
        },
        async updateList() {
            try {
                const result = await VehicleClassApi.filter({
                    sortBy: 'id',
                });
                this.vehicleClasses = result;
            } catch (err) {
                Toaster.error(err);
            }
        },
        edit(item) {
            this.$router
                .push({
                    name: this.$root.findRouteName('vehicle-class-edit'),
                    params: {
                        id: item.id,
                    },
                })
                .catch(navigationFailure);
        },
        itemTypeLabel(item) {
            const types = [];

            item.isTypeDelivery && types.push('L');
            item.isTypePickup && types.push('A');
            item.isTypeShipment && types.push('S');

            return types.join(', ');
        },
        goToSettingsPage() {
            this.$router.push({ name: this.$root.findRouteName('settings') }).catch(navigationFailure);
        },
        getMarketNames(vehicleClass) {
            return (vehicleClass.marketUsage || [])
                .map(marketCode => this.$t(`markets.${marketCode.toLowerCase()}.name`))
                .join(', ');
        },
    },
};
</script>

<style lang="scss"></style>

<template>
    <div class="cost-preview-sections">
        <div class="font-copy-md-strong mb-2 flex justify-between">
            {{ $t('pages.checkout.priceAdjustments.costPreview.headline') }}
            <span v-if="note" class="font-copy-md text-subdued">{{ note }}</span>
        </div>

        <div class="font-copy-md mb-6">
            {{ $t('pages.checkout.priceAdjustments.costPreview.hint') }}
        </div>

        <div v-for="(vehicleClass, vid) in selectedTransports" :key="vid" :class="['card', 'mt-6', 'first:mt-0']">
            <header class="mb-8 flex items-start justify-between">
                <h3 class="font-copy-md-strong">
                    {{ vehicleClass.count }} x {{ vehicleClass.name }}
                    <template v-if="material.extension"> - {{ material.extension }}</template>
                </h3>
            </header>
            <ul class="font-copy-md m-0 list-none p-0">
                <li>
                    <PriceRow
                        :label="$t('general.purchasePrice.abbreviation')"
                        :price-per-weight="vehicleClass.purchasePricePerTon"
                        :price-per-transport="vehicleClass.purchasePricePerTransport"
                        :fixed-price="fixedPrice"
                    />
                </li>
                <li>
                    <PriceRow
                        class="mt-2"
                        :label="$t('general.retailPrice.abbreviation')"
                        :price-per-weight="vehicleClass.retailPricePerTon"
                        :price-per-transport="vehicleClass.retailPricePerTransport"
                        :fixed-price="fixedPrice"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import MaterialPricingView from '@/pages/Checkout/components/PriceAdjustment/models/MaterialPricingView';
import TransportPricingCollectionView from '@/pages/Checkout/components/PriceAdjustment/models/TransportPricingCollectionView';

import PriceRow from '@/pages/Checkout/Disposal/Components/PriceRow';

export default {
    name: 'CostPreviewSection',
    components: {
        PriceRow,
    },
    props: {
        transports: {
            type: [TransportPricingCollectionView, Array],
            required: true,
        },
        material: {
            type: [MaterialPricingView, Object],
            required: true,
        },
        fixedPrice: {
            type: Boolean,
            default: false,
        },
        note: {
            type: String,
            default: null,
        },
    },
    computed: {
        selectedTransports() {
            if (this.transports instanceof TransportPricingCollectionView)
                return this.transports.collection
                    .map(transport => ({
                        ...transport,
                        count: transport.count,
                        name: `${transport.info} - ${this.material.info}`,
                        purchasePricePerTon: transport.getPurchaseUnitPriceForMaterial(this.material),
                        purchasePricePerTransport: transport.getPurchasePriceTotalsForMaterial(this.material),
                        retailPricePerTon: transport.getRetailUnitPriceForMaterial(this.material),
                        retailPricePerTransport: transport.getRetailPriceTotalsForMaterial(this.material),
                    }))
                    .filter(transport => transport._enabled);

            return this.transports.map(transport => ({
                ...transport,
                count: transport.count || 1,
                name: transport.vehicleClassName || transport.name,
                purchasePricePerTon: transport.customPrice.purchasePricePerTon,
                purchasePricePerTransport: transport.customPrice.purchasePricePerTransport,
                retailPricePerTon: transport.customPrice.retailPricePerTon,
                retailPricePerTransport: transport.customPrice.retailPricePerTransport,
            }));
        },
    },
};
</script>

<style lang="scss"></style>

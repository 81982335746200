import AbstractFilterableResource from '@/services/Api/AbstractFilterableResource';

class ConstructionSite extends AbstractFilterableResource {
    /**
     * @returns {Promise<number[]>}
     */
    async getTrafficabilityOptions() {
        try {
            const response = await this.apiService.get(`/v1/construction-site-trafficability`);

            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new ConstructionSite('/constructionsite');

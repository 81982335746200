<template>
    <LayoutPage is-flyout class="bg">
        <template #flyoutHeader>
            <HeaderBar>
                <template #left>
                    <HeaderBarItem button @click="goBack">
                        <SfSysArrowLeftIcon size="xs" />
                    </HeaderBarItem>
                </template>
                <template #headline>
                    {{ $t('pages.checkout.projectPositionDisposal.pages.chooseCarrier.title') }}
                </template>
            </HeaderBar>
        </template>

        <transition name="fade">
            <div class="container-off-canvas-sm my-6">
                <template v-if="isCarrierSearchMode">
                    <div v-if="isGermanMarket" class="mb-4">
                        {{ $t('pages.checkout.projectPositionDisposal.pages.chooseCarrier.hint') }}
                    </div>
                    <TextField v-model="searchTerm" :label="$t('pages.invoice.selectOrganization.searchLabel')">
                        <template #icon>
                            <SearchIcon />
                        </template>
                    </TextField>
                    <LoadingCard v-if="isLoading" class="mt-4" />
                    <div v-else-if="carriers && carriers.count > 0" class="mt-4">
                        <button
                            v-for="(organization, i) in carriers.items"
                            :key="i"
                            class="card w-full text-left"
                            :class="{
                                'mt-4': i > 0,
                                'bg-surface-disabled': isCarrierInvalidForSelection(organization),
                                'card--hovered': !isCarrierInvalidForSelection(organization),
                                'card--focussed': !isCarrierInvalidForSelection(organization),
                            }"
                            :disabled="isCarrierInvalidForSelection(organization)"
                            :data-test="`project-position-carrier-selection-${i}`"
                            @click="selectOrganization(organization)"
                        >
                            <span
                                class="font-copy-md-strong"
                                :class="{ 'text-disabled': isCarrierInvalidForSelection(organization) }"
                                >{{ organization.name }}</span
                            >
                            <span class="font-copy-sm mt-1">
                                <FormattedAddress
                                    :class="{ 'text-disabled': isCarrierInvalidForSelection(organization) }"
                                    :address="organization.billingAddress"
                                />
                            </span>
                            <ReactBridge
                                v-if="isGermanMarket"
                                class="mt-4"
                                :react-component="DisposalCertificateValidityInfo"
                                :props="{
                                    certificates: organization.certificates,
                                }"
                            />
                        </button>
                        <MoreResultsButton :result="carriers" />
                    </div>
                    <div v-else-if="carriers" class="font-copy-sm mt-4">
                        {{ $t('pages.invoice.selectOrganization.noOrganizationResults') }}
                    </div>
                </template>
                <template v-else>
                    <RadioButtonCard
                        name="carrier-selection"
                        value="transport-market"
                        radio-data-test="transport-market-radio"
                        :model-value="selectedRadioOption"
                        :title="$t('pages.checkout.carrierSelection.actions.useMarketplace')"
                        @change="putToTransferMarket"
                    />
                    <RadioButtonCard
                        name="carrier-selection"
                        value="carrier-selection"
                        :model-value="selectedRadioOption"
                        radio-data-test="carrier-selection-radio"
                        class="mt-4"
                        :is-checked="(isCarrierSelected || hasVisitedCarrierSearch) && !shouldAddToTransportMarket"
                        :title="$t('pages.checkout.carrierSelection.actions.selectCarrier')"
                        @change="searchCarrier"
                    />
                    <ErrorMessage
                        v-if="hasErrors('missingAnyCarrierSelection')"
                        :message="getError('missingAnyCarrierSelection')"
                    />

                    <div v-if="hasVisitedCarrierSearch && !isCarrierSelected && !addToTransportMarket">
                        <div class="font-copy-md-strong mt-12">
                            {{ $t('components.carrierTile.headline') }}
                        </div>
                        <div class="font-copy-md mt-2">
                            {{
                                $t(
                                    'pages.checkout.projectPositionDisposal.pages.chooseCarrier.chooseCarrierDescription'
                                )
                            }}
                        </div>
                        <div class="empty-carrier-box mt-6 flex w-full justify-center bg-surface p-6">
                            {{ $t('pages.checkout.projectPositionDisposal.pages.chooseCarrier.noCarrierChosen') }}
                        </div>
                        <div class="font-copy-md mt-2 text-right">
                            <BaseButton arrow-right @click="searchCarrier()">
                                {{ $t('pages.checkout.carrierSelection.actions.chooseCarrier') }}
                            </BaseButton>
                        </div>

                        <ErrorMessage
                            v-if="hasErrors('missingSpecificCarrierSelection')"
                            :message="getError('missingSpecificCarrierSelection')"
                        />
                    </div>
                    <div v-if="showSelectedCarrier" class="mt-12">
                        <div class="font-copy-md-strong">
                            {{ $t('components.carrierTile.headline') }}
                        </div>
                        <div class="font-copy-md mt-2">
                            {{
                                $t(
                                    'pages.checkout.projectPositionDisposal.pages.chooseCarrier.chooseCarrierDescription'
                                )
                            }}
                        </div>
                        <div class="card card--selected mt-6 p-4">
                            <p class="font-copy-md-strong mb-2">
                                {{ carrierInfo.name }}
                            </p>
                            <div class="font-copy-sm mt-1">
                                <FormattedAddress :address="carrierInfo.billingAddress" />
                            </div>
                        </div>
                        <div class="font-copy-md mt-2 text-right">
                            <BaseButton arrow-right @click="searchCarrier()">
                                {{ $t('pages.checkout.carrierSelection.actions.changeCarrier') }}
                            </BaseButton>
                        </div>

                        <template v-if="isDisposal">
                            <div class="font-copy-md-strong mt-12">
                                {{
                                    isHazardousWasteProcess
                                        ? $t('disposal.carrierNumber.title')
                                        : $t('disposal.carrierNumber.titleOptional')
                                }}
                            </div>
                            <div class="font-copy-md mt-2">{{ $t('disposal.carrierNumber.description') }}</div>
                            <DisposalNumber
                                :address="carrierInfo.billingAddress"
                                :disposal-number-string="
                                    requestCollection.carrierNumber || carrierNumberFromOrganization
                                "
                                :is-read-only="!!carrierNumberFromOrganization"
                                :is-disabled="!!carrierNumberFromOrganization"
                                is-location-state-read-only
                                :accept-empty-disposal-number="!isHazardousWasteProcess"
                                class="mt-6"
                                error-message="components.disposalNumber.carrierNumber.errorMessage"
                                name="requestCollection.carrierNumber"
                                @disposalNumber="setCarrierNumber"
                            />
                            <ErrorMessage
                                v-if="hasErrors('isCarrierNumberValid')"
                                :message="getError('isCarrierNumberValid')"
                            />
                        </template>
                    </div>
                </template>
            </div>
        </transition>

        <template #sticky>
            <SlideUp v-if="!isCarrierSearchMode" :active="true">
                <ButtonGroup>
                    <BaseButton
                        primary
                        type="button"
                        :disabled="basketType === 'shipment' && !addToTransportMarket && carrierBasket === null"
                        data-test="project-submit-job-assign"
                        @click="handleSubmission"
                    >
                        {{ $t('forms.buttons.label.saveAndContinue') }}
                        <template #right>
                            <ArrowRightIcon class="icon--inline" />
                        </template>
                    </BaseButton>
                </ButtonGroup>
            </SlideUp>
        </template>
    </LayoutPage>
</template>

<script>
import ReactBridge from '@/reactBridge/ReactBridge.vue';
import { mapGetters, mapState } from 'vuex';
import { revertLocalizedValue } from '@/services/utils/localization';
import { AvailableMarkets } from '@/services/MarketPermission/constants';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import { CHECKOUT_BASE_TYPE_DELIVERY, CHECKOUT_BASE_TYPE_SHIPMENT } from '@/constants/checkoutTypes';
import _debounce from 'lodash/debounce';
import AvailableCarriersApi from '@/services/Api/AvailableCarriers.ts';
import Toaster from '@/services/Toaster';
import QuoteService from '@/services/QuoteService';

import BaseButton from '@/components/Button/Button';
import FormattedAddress from '@/components/FormattedAddress';
import LayoutPage from '@/components/Layout/Page.v2';
import MoreResultsButton from '@/components/Filter/MoreResultsButton';
import TextField from '@/components/Form/TextField.v2';
import validate from '@/services/validation/mixin';

import SearchIcon from '@/assets/icons/micro/search.svg';
import ArrowRightIcon from '@/assets/icons/micro/arrow.svg';

import RadioButtonCard from '@/_components/RadioButtonCard/RadioButtonCard';
import DisposalNumber from '@/_components/DisposalNumber/DisposalNumber';
import SlideUp from '@/components/Animation/SlideUp';
import ButtonGroup from '@/components/Button/ButtonGroup';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import DisposalCertificateValidityInfo from '@/pages/Checkout/Disposal/ProjectPosition/Partials/DisposalCertificateValidityInfo.tsx';

import LoadingCard from '@/pages/Checkout/Disposal/ProjectPosition/Partials/LoadingCard.vue';
import { isNotNullOrUndefined, isRequired } from '@/services/validation/rules';
import { isDisposerNumberFormat } from '@/services/Disposal/utils';
import ErrorMessage from '@/components/Form/ErrorMessage';
import { SfSysArrowLeftIcon } from '@schuettflix/vue-components';
import {
    TRANSPORT_FREIGHT_TYPE_WASTE_DANGEROUS,
    TRANSPORT_FREIGHT_TYPE_WASTE_NON_DANGEROUS,
} from '@/constants/transportFreightTypes';
import { CARRIER_PLATFORM_ACTIONS } from '@/constants/platformActions';

const SEARCH_DEBOUNCE_MS = 300;

export default {
    name: 'CustomCarrierSelectionPage',
    components: {
        ReactBridge,
        ErrorMessage,
        HeaderBarItem,
        HeaderBar,
        ButtonGroup,
        SlideUp,
        DisposalNumber,
        RadioButtonCard,
        BaseButton,
        FormattedAddress,
        LayoutPage,
        MoreResultsButton,
        TextField,
        LoadingCard,
        ArrowRightIcon,
        SearchIcon,
        SfSysArrowLeftIcon,
    },
    mixins: [validate],
    data() {
        return {
            isPending: false,
            isLoading: false,
            isCarrierSearchMode: false,
            hasVisitedCarrierSearch: false,
            selectedOrganization: null,
            isCarrierNumberValid: false,
            addToTransportMarket: false,
            searchTerm: '',
            carriers: null,
            DisposalCertificateValidityInfo,
        };
    },
    computed: {
        ...mapState('basket', {
            carrierBasket: 'carrierInfo',
            isCustom: 'isCustom',
            basketType: 'type',
            quote: 'quote',
            shipmentQtyAndDocument: 'shipmentQtyAndDocument',
            isSellerSelected: 'isSellerSelected',
        }),
        ...mapState('projectPosition', {
            requestCollection: 'requestCollection',
            disposalCarrierInfo: 'carrierInfo',
            storePutToTransportMarket: 'putToTransportMarket',
            carrierNumberFromOrganization: 'carrierNumberFromOrganization',
        }),
        ...mapGetters('projectPosition', ['getProjectPositionType', 'getPutToTransportMarket']),
        ...mapGetters('user', ['organizationMarket']),
        ...mapGetters('basket', ['baseType']),
        validationRules() {
            let rules = {};
            if (!this.isCarrierSelected && !this.addToTransportMarket) {
                if (!this.hasVisitedCarrierSearch) {
                    rules = {
                        ...rules,
                        ...{
                            missingAnyCarrierSelection: [isRequired(), isNotNullOrUndefined],
                        },
                    };
                } else {
                    rules = {
                        ...rules,
                        ...{
                            missingSpecificCarrierSelection: [isRequired(), isNotNullOrUndefined],
                        },
                    };
                }
            }

            if (this.isHazardousWasteProcess && this.isCarrierSelected) {
                rules = {
                    ...rules,
                    ...{
                        isCarrierNumberValid: [isRequired(), isDisposerNumberFormat],
                    },
                };
            }
            return rules;
        },
        isGermanMarket() {
            return this.organizationMarket.code == AvailableMarkets.DE;
        },
        isDisposal() {
            return [
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS,
            ].includes(this.getProjectPositionType);
        },
        isHazardousWasteProcess() {
            return BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS === this.getProjectPositionType;
        },
        carrierInfo() {
            return this.carrierBasket || this.disposalCarrierInfo;
        },
        isCarrierSelected() {
            return !!this.selectedOrganization || !!this.disposalCarrierInfo || !!this.carrierInfo;
        },
        shouldAddToTransportMarket() {
            return this.addToTransportMarket || this.getPutToTransportMarket;
        },
        selectedRadioOption() {
            if ((this.isCarrierSelected || this.hasVisitedCarrierSearch) && !this.shouldAddToTransportMarket) {
                return 'carrier-selection';
            }
            if (this.shouldAddToTransportMarket) {
                return 'transport-market';
            }
            return null;
        },
        showSelectedCarrier() {
            return this.isCarrierSelected && !this.shouldAddToTransportMarket;
        },
        hasQuote() {
            return !!this?.quote?.id;
        },
    },
    watch: {
        searchTerm() {
            this.updateOrganizations();
        },
    },
    async created() {
        await this.refreshCarriers();
        this.addToTransportMarket = this.getPutToTransportMarket;
        this.isCarrierNumberValid = isDisposerNumberFormat(this.requestCollection.carrierNumber);
    },
    methods: {
        revertLocalizedValue,
        isForwardingOfMaterial() {
            return (
                [BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_SHIPMENT, CHECKOUT_BASE_TYPE_SHIPMENT].includes(
                    this.basketType
                ) && this.shipmentQtyAndDocument?.type == 'material'
            );
        },
        isForwardingOfDangerousWaste() {
            return (
                [BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_SHIPMENT, CHECKOUT_BASE_TYPE_SHIPMENT].includes(
                    this.basketType
                ) && [TRANSPORT_FREIGHT_TYPE_WASTE_DANGEROUS].includes(this.shipmentQtyAndDocument?.type)
            );
        },
        isForwardingOfNonDangerousWaste() {
            return (
                [BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_SHIPMENT, CHECKOUT_BASE_TYPE_SHIPMENT].includes(
                    this.basketType
                ) && [TRANSPORT_FREIGHT_TYPE_WASTE_NON_DANGEROUS].includes(this.shipmentQtyAndDocument?.type)
            );
        },
        isDisposalOfDangerousWaste() {
            return this.baseType == BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS;
        },
        isDisposalOfNonDangerousWaste() {
            return this.baseType == BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS;
        },
        isDangerousWasteContext() {
            return this.isForwardingOfDangerousWaste() || this.isDisposalOfDangerousWaste();
        },
        isNonDangerousWasteContext() {
            return this.isForwardingOfNonDangerousWaste() || this.isDisposalOfNonDangerousWaste();
        },
        isDelivery() {
            return [CHECKOUT_BASE_TYPE_DELIVERY, BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DELIVERY].includes(
                this.basketType
            );
        },
        isDeliveryOrForwardingOfMaterial() {
            return this.isDelivery() || this.isForwardingOfMaterial();
        },
        isCarrierInvalidForSelection(organization) {
            if (!this.isGermanMarket) return false;
            if (!organization.platformActionCodes.length) return true;

            if (this.isDeliveryOrForwardingOfMaterial())
                return !organization.platformActionCodes.includes(
                    CARRIER_PLATFORM_ACTIONS.CARRIER_MOVE_MATERIAL_IN_DE_MARKET
                );
            if (this.isDangerousWasteContext()) {
                return !organization.platformActionCodes.includes(
                    CARRIER_PLATFORM_ACTIONS.CARRIER_MOVE_HAZARDOUS_WASTE_IN_DE_MARKET
                );
            }
            if (this.isNonDangerousWasteContext()) {
                return !organization.platformActionCodes.includes(
                    CARRIER_PLATFORM_ACTIONS.CARRIER_MOVE_NON_HAZARDOUS_WASTE_IN_DE_MARKET
                );
            }

            return false;
        },

        goBack() {
            if (this.isCarrierSearchMode) {
                this.isCarrierSearchMode = !this.isCarrierSearchMode;
            }

            if (this.isSellerSelected) {
                this.$router
                    .push({ name: this.$root.findRouteName(this.$route.meta.previousWithSellerSelected) })
                    .catch(() => {});
            } else {
                this.$router.push({ name: this.$root.findRouteName(this.$route.meta.previous) }).catch(() => {});
            }
        },

        async isAnyCarrierSelectedFromAPI() {
            const carrierName = this.selectedOrganization?.name ?? this.carrierInfo?.name;
            return ((await this.getFilteredOrganizations(carrierName).items) || []).some(
                carrier => this.carrierInfo && this.carrierInfo.id === carrier.id
            );
        },

        updateOrganizations: _debounce(function () {
            this.refreshCarriers();
        }, SEARCH_DEBOUNCE_MS),

        async getFilteredOrganizations(searchTerm = this.searchTerm) {
            return AvailableCarriersApi.filter({
                search: searchTerm,
            });
        },

        async refreshCarriers() {
            if (!this.isCustom) return;
            this.isLoading = true;

            try {
                this.carriers = await this.getFilteredOrganizations();
            } catch (err) {
                Toaster.error(err);
            }

            if (this.isCarrierSelected && !this.isAnyCarrierSelectedFromAPI()) {
                this.$store.commit('projectPosition/RESET_REQUEST_COLLECTION', {
                    property: 'carrierNumber',
                });
                this.$store.commit('projectPosition/RESET_REQUEST_COLLECTION', {
                    property: 'carrier',
                });
                this.$store.commit('projectPosition/RESET_CARRIER_INFO');

                this.hasVisitedCarrierSearch = true;
            }

            this.isLoading = false;
        },

        resetSelectedCarrier(organization) {
            if (organization?.id !== this.requestCollection?.carrier?.id) {
                this.$store.commit('projectPosition/RESET_REQUEST_COLLECTION', {
                    property: 'carrierNumber',
                });
            }
            this.$store.commit('projectPosition/RESET_REQUEST_COLLECTION', {
                property: 'carrier',
            });
            this.$store.commit('projectPosition/RESET_CARRIER_INFO');
            this.$store.commit('basket/setCarrierInfo', null);
            this.$store.commit('projectPosition/SET_CARRIER_NUMBER_FROM_ORGANIZATION');
            this.$store.commit('basket/SET_PAYMENT_TERMS', null);
            this.$store.commit('projectPosition/SET_PAYMENT_TERMS', null);
            this.addToTransportMarket = false;
        },

        async selectCarrier(organization) {
            this.resetSelectedCarrier(organization);
            this.isCarrierNumberValid = isDisposerNumberFormat(this.requestCollection.carrierNumber);

            if (this.isDisposal && organization) {
                this.$store.commit('projectPosition/SET_CARRIER_INFO', organization);
                this.$store.commit('projectPosition/SET_REQUEST_COLLECTION', {
                    requestCollection: { carrier: { id: organization.id } },
                    property: 'carrier',
                });
                if (this.isCarrierNumberValid || organization.carrierNumber) {
                    this.$store.commit('projectPosition/SET_REQUEST_COLLECTION', {
                        requestCollection: { carrierNumber: organization.carrierNumber },
                        property: 'carrierNumber',
                    });
                    this.$store.commit(
                        'projectPosition/SET_CARRIER_NUMBER_FROM_ORGANIZATION',
                        organization.carrierNumber
                    );
                }
            } else {
                this.$store.commit('basket/setCarrierInfo', organization);
            }

            this.isCarrierSearchMode = false;
        },

        async selectOrganization(organization) {
            if (!this.isCustom) return;
            this.searchTerm = '';
            this.selectedOrganization = organization;
            await this.selectCarrier(organization);
        },

        searchCarrier() {
            if (!this.isCustom) return;
            this.$store.commit('projectPosition/SET_PROJECT_PUT_POSITION_TO_TRANSPORT_MARKET', false);
            this.isCarrierSearchMode = true;
            this.hasVisitedCarrierSearch = true;
            this.addToTransportMarket = false;
        },

        setCarrierNumber(carrierNumber) {
            this.$store.commit('projectPosition/SET_REQUEST_COLLECTION', {
                requestCollection: { carrierNumber: carrierNumber.string },
                property: 'carrierNumber',
            });
            if (this.isHazardousWasteProcess || carrierNumber.object.alphanumerics[0].alphanumeric !== '') {
                this.isCarrierNumberValid = carrierNumber.isValid;
            } else {
                this.isCarrierNumberValid = true; //since its optional
            }
        },
        async handleSubmission() {
            if (!this.isValid()) return;
            //we are purposefully disabling errors of the navigation here
            //as vue router in its current version throws and error when redirecting
            //see the index.js file in project for the billing-method route for when
            //the redirect happens.
            if (this.isPending) return;
            this.isPending = true;
            if (this.basketType === 'shipment') {
                try {
                    await QuoteService.saveQuote();
                } catch (err) {
                    this.addToTransportMarket = false;
                    this.selectedOrganization = null;
                    this.hasVisitedCarrierSearch = false;
                    this.$store.commit('basket/setCarrierInfo', null);
                    this.$store.commit('projectPosition/RESET_CARRIER_SELECTION', err);
                    if (err.code) {
                        this.$store.commit('basket/setServerError', err);
                    } else {
                        this.$logger().error(err);
                    }
                }
            }
            this.isPending = false;

            this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(() => {});
        },
        putToTransferMarket() {
            this.selectOrganization(null);
            this.$store.commit('projectPosition/SET_PROJECT_PUT_POSITION_TO_TRANSPORT_MARKET', true);
            this.addToTransportMarket = true;
            this.selectedOrganization = null;
        },
    },
};
</script>

<style lang="scss">
.checkout-custom-carrier-selection {
    background-color: $color-lightMediumGrey;
}

.empty-carrier-box {
    border: 1px dashed #d2d4d5;
    color: #64656c;
}
</style>

import { preciseFloat } from '@/services/utils';

export function convert_kg_to_cbm(kg, density, precision = 2) {
    if (kg <= 0) return 0;
    return preciseFloat(kg / density, precision);
}

export function get_cbm_per_ton(weightInKg, cubicMeterDensityPerKg, volumeInKg = null, precision = 2) {
    if (volumeInKg !== null && volumeInKg <= 0) return 0;
    if (volumeInKg > 0) {
        return preciseFloat(volumeInKg / 1000, precision);
    }

    if (!weightInKg || !cubicMeterDensityPerKg) return 0;
    if (weightInKg > 0 && cubicMeterDensityPerKg > 0) {
        return preciseFloat(weightInKg / 1000 / cubicMeterDensityPerKg, precision);
    }
}

export function convert_cbm_to_kg(cbm, density, precision = 0) {
    if (cbm <= 0) return 0;
    return preciseFloat(cbm * density, precision);
}

export function convert_tonsPrice_to_cbmPrice(density, pricePerTon) {
    return ((pricePerTon * density) / 1000).toFixed(2);
}

/**
 * @param {number} value
 * @returns {number}
 */
export function toTonPrice(value) {
    if (value === null || value === undefined) return null;
    // NOTE: This might introduce a rounding issue with the clone behaviour of views
    // Fix: precision 2 -> 25
    return preciseFloat(value * 1000, 25);
}

/**
 * @param {number} value
 * @returns {number}
 */
export function toKgPrice(value) {
    return value / 1000;
}

import React, { Suspense } from 'react';
import { useQuery } from '@tanstack/react-query';
import store from '@/store';
import { useVuexState } from '@/reactBridge/useVuex';
import { useRouteParams } from '@/reactBridge/useRouteParams';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { Header } from '@/constructionProjects/components/Header';
import { constructionProjectByIdQuery } from '../../queries';
import { ConstructionProjectDetailsMobile } from './ConstructionProjectDetailsMobile';
import { ConstructionProjectDetailsDesktop } from './ConstructionProjectDetailsDesktop';
import { FavoriteStar } from '@/constructionProjects/components/FavoriteStar';
import { useTranslation } from 'react-i18next';
import { CONSTRUCTION_PROJECT_LIST_ROUTE } from '@/constructionProjects/constants';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { useScreenName } from '@schuettflix/analytics-react';
import { LoadingSpinner } from '@schuettflix/react-components';
function useDetailsPageEntryPoint() {
    return useVuexState(store, state => state.constructionProject.detailsPageEntryPoint);
}
const HeaderSection = () => {
    const { id } = useRouteParams();
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const entrypoint = useDetailsPageEntryPoint();
    const constructionProject = useQuery({
        ...constructionProjectByIdQuery(id),
        suspense: true,
    });
    if (!constructionProject.data) {
        return null;
    }
    const hasEntrypoint = entrypoint !== null && [CONSTRUCTION_PROJECT_LIST_ROUTE, 'home'].includes(entrypoint);
    return (<Header desktopTitle={constructionProject.data.name} mobileTitle={constructionProject.data.name} navigationTitle={entrypoint === CONSTRUCTION_PROJECT_LIST_ROUTE
            ? t('pages.constructionProject.projects.headline')
            : t('pages.homeV2.title')} onBack={hasEntrypoint ? () => vueRouter.push({ name: entrypoint }) : undefined} backgroundColor={constructionProject.data.color}>
            <div className="flex space-x-4">
                <FavoriteStar />
            </div>
        </Header>);
};
const _ConstructionProjectDetailsPage = () => {
    useScreenName('client-constructionproject-detail');
    return (<div className="flex h-full flex-col bg">
            <Suspense fallback={<LoadingSpinner block spaced/>}>
                <HeaderSection />
                <ConstructionProjectDetailsMobile className="lg:hidden"/>
                <ConstructionProjectDetailsDesktop className="hidden lg:flex"/>
            </Suspense>
        </div>);
};
export const ConstructionProjectDetailsPage = asVueComponent(_ConstructionProjectDetailsPage);

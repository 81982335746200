<template>
    <div
        v-if="isVisible"
        class="message-bar"
        :class="{
            'message-bar--error': error,
            'message-bar--info': info,
            'message-bar--success': success,
            'message-bar--warning': warning,
            'message-bar--padded-bottom': action,
        }"
    >
        <div class="message-bar__icon">
            <div v-if="useInfoIcon" data-test="info-icon">
                <InfoIcon width="18" height="18" />
            </div>
            <div v-else data-test="warning-icon">
                <WarningIcon width="18" height="18" />
            </div>
        </div>

        <div class="message-bar__container">
            <header v-if="hasHeadline" class="message-bar__headline">
                <slot name="header" />
            </header>
            <main class="message-bar__content">
                <slot />
            </main>
            <footer v-if="action">
                <slot name="action">
                    <a :href="action.href" class="message-bar__link">
                        <Words span bold>{{ action.label }}</Words>
                        <Words span class="message-bar__link-icon">❯</Words>
                    </a>
                </slot>
            </footer>
        </div>

        <div v-if="closable" class="message-bar__icon-cancel">
            <Button @click="clear">
                <CancelCrossIcon width="18" height="18" />
            </Button>
        </div>
    </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import Words from '@/components/Typography/Words.vue';

import CancelCrossIcon from '@/assets/icons/regular/cancel-cross.svg';
import InfoIcon from '@/assets/icons/productCatalog/info.svg';
import WarningIcon from '@/assets/icons/productCatalog/warning.svg';

export default {
    name: 'MessageBar',
    components: {
        Button,
        Words,

        CancelCrossIcon,
        InfoIcon,
        WarningIcon,
    },
    props: {
        info: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        warning: {
            type: Boolean,
            default: false,
        },
        action: {
            type: Object,
            default: null,
        },
        closable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isVisible: true,
        };
    },
    computed: {
        hasHeadline() {
            return !!this.$slots.header;
        },
        useInfoIcon() {
            return this.info || this.success;
        },
    },
    methods: {
        clear() {
            this.isVisible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.message-bar {
    display: flex;
    justify-content: flex-start;
    padding: 16px 16px 0 16px;

    &__headline {
        margin-bottom: 4px;
    }

    &__content {
        margin-bottom: 16px;
    }

    &__icon {
        margin-right: 10px;

        &-cancel {
            margin-right: unset;
        }
    }

    &__link {
        text-decoration: none;
        color: #35343d;

        &-icon {
            position: relative;
            bottom: 2px;
            margin-left: 8px;
            font-size: 8px;
        }
    }

    &--error {
        background: #ff9393;
        border: 1px solid #990000;
    }

    &--info {
        background: #d2d4d5;
        border: 1px solid #35343d;
    }

    &--success {
        background: #ebfbec;
        border: 1px solid #168844;
    }

    &--warning {
        background: #fffcbc;
        border: 1px solid #fad828;
    }

    &--padded-bottom {
        padding-bottom: 16px;
    }
}
</style>

import React from 'react';
import { CancelIllustration } from './icons/CancelIllustration';
import { useTranslation } from 'react-i18next';
export const CreateLocationMissingPermission = () => {
    const { t } = useTranslation();
    return (<div className="flex flex-col items-center justify-center px-8 py-6 text-center">
            <CancelIllustration />
            <p className="font-headline-md-strong mt-4">
                {t('pages.constructionProject.projectLocations.locationMissingPermission.title')}
            </p>
            <p className="font-copy-md mt-2">
                {t('pages.constructionProject.projectLocations.locationMissingPermission.description')}
            </p>
        </div>);
};

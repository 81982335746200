import AbstractFilterableResource from '../AbstractFilterableResource';
export const MARGIN_TYPE_PERCENTAGE = 'percentage';
export const MARGIN_TYPE_ABSOLUTE = 'absolute';
class Product extends AbstractFilterableResource {
    async getProductAttributes(masterProductId) {
        const response = await this.apiService.get(`/v1/master-products/${masterProductId}/attribute-sets`);
        return response.data;
    }
    async getProductSchema(productSchemaId) {
        const response = await this.apiService.get(`/v1/product-schemas/${productSchemaId}/attribute-sets`);
        return response.data;
    }
    async getProductList(pageNumber = 1) {
        const response = await this.apiService.get('/v1/master-products', {
            params: { pageNumber },
        });
        return response.data;
    }
    async getProductDetails(productId) {
        const response = await this.apiService.get(`/v1/master-products/${productId}`);
        return response.data;
    }
    async updateProduct(productId, data) {
        const response = await this.apiService.put(`/v1/master-products/${productId}`, data);
        return response.data;
    }
    async getProductCategories() {
        const response = await this.apiService.get(`/v1/master-product-categories`);
        return response.data;
    }
}
export function getImageFromProduct(product) {
    return product.image ?? product.productCategories.find(category => category.image)?.image ?? null;
}
export default new Product('/platform/product', {
    404: 'product.errors.notFound',
    500: 'product.errors.serverError',
});

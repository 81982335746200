<template>
    <!-- Document Lists -->
    <SfFileList
        size="sm"
        has-background
        class="document-block"
        :headline="title"
        :groups="groups"
        :download-all-files="!!downloadAllFiles"
        @download-all-files="onDownloadAllFiles"
    >
        <!-- Empty -->
        <slot v-if="documents === null && !hideEmptyMessage">
            <slot name="empty">
                <div
                    class="font-copy-sm flex justify-center border border-dashed border-disabled bg-surface-disabled p-4 text-center !text-disabled"
                >
                    {{ emptyMessage ? emptyMessage : $t('components.documentBlock.defaultEmptyMessage') }}
                </div>
            </slot>
        </slot>
    </SfFileList>
</template>

<script>
import { ensureJSTimestamp } from '@/services/utils/date';

import { SfFileList } from '@schuettflix/vue-components';

export default {
    name: 'DocumentBlock',
    components: {
        // components
        SfFileList,
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        emptyMessage: {
            type: String,
            default: null,
        },
        hideEmptyMessage: {
            type: Boolean,
            default: false,
        },
        documents: {
            type: Array,
            default: null,
        },
        downloadAllFiles: {
            type: String,
            default: null,
        },
    },
    computed: {
        computedFileProps() {
            return {
                statusLabel: this.$t('components.documentBlock.creditNoteStatusLabel'),
                statusTranslations: {
                    new: this.$t('status.creditNote.created'),
                    created: this.$t('status.creditNote.created'),
                    cancelled: this.$t('status.creditNote.cancelled'),
                    completed: this.$t('status.creditNote.paid'),
                },
                thumbnailTranslations: {
                    noImage: this.$t('components.thumbnail'),
                },
                actionTranslation: {
                    download: this.$t('components.file.download'),
                    edit: this.$t('components.file.edit'),
                    delete: this.$t('components.file.delete'),
                },
            };
        },
        groups() {
            const filesGroupedByHeadlineAndNote = this.files.reduce(
                (groupedFiles, item) => ({
                    ...groupedFiles,
                    [`${item.headline}_${item.note}`]: groupedFiles[`${item.headline}_${item.note}`]
                        ? [...groupedFiles[`${item.headline}_${item.note}`], item]
                        : [item],
                }),
                {}
            );

            return Object.values(filesGroupedByHeadlineAndNote).map(group => ({
                headline: group[0].headline,
                note: group[0].note,
                isNew: !!group.filter(file => file.isNew).length,
                files: group,
            }));
        },
        files() {
            if (!this.documents) return [];
            return this.documents.map(document => this.formatDocument(document));
        },
    },
    methods: {
        ensureJSTimestamp,
        formatDocument(file) {
            const [name, type] = file.name.split('.');

            const formattedFile = {
                ...file,
                name,
                type,
                formattedCreatedDate: this.$d(ensureJSTimestamp(file.created), 'short'),
                downloadProps: {
                    tag: 'a',
                    href: file.url,
                    target: '_blank',
                    download: true,
                },
                ...this.computedFileProps,
            };

            // overwrite label with transmitted file specific status label
            if (file.statusLabel) formattedFile.statusTranslations[file.status] = file.statusLabel;
            return formattedFile;
        },
        onDownloadAllFiles() {
            if (!this.downloadAllFiles) return;
            window.location.href = this.downloadAllFiles;
        },
    },
};
</script>

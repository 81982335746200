import AbstractFilterableResource from './AbstractFilterableResource';

class Vehicle extends AbstractFilterableResource {
    /**
     * Get all active transports for a vehicle
     * @param {int} vehicleId
     */
    async getActiveTransports(vehicleId) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/${vehicleId}/activetransports`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new Vehicle('/vehicle');

<template>
    <div v-if="transport.type === 'delivery'" class="arrival-time">
        <div v-if="listElement && isSupplier">
            <div class="arrival-time__data">
                <CalendarIcon :class="{ 'arrival-time__calendar-icon': !dark }" class="arrival-time__icon" />
                <Words
                    v-if="transport.shippingMethod === 'flix'"
                    :muted="!dark"
                    :small="!singleLine"
                    class="arrival-time__data-multiline"
                >
                    {{ $d(transport.shippingDate * 1000, 'short') }}&nbsp;&nbsp;&nbsp;{{
                        $t('timeSuffix', { time: $d(transport.shippingDate * 1000, 'time') })
                    }}
                </Words>
                <Words
                    v-else
                    :muted="!dark"
                    :small="!singleLine"
                    :class="{ 'arrival-time__data-multiline': !singleLine }"
                >
                    {{ $d(transport.shippingWindowStart * 1000, 'short') }} -
                    {{ $d(transport.shippingWindowEnd * 1000, 'short') }}
                </Words>
            </div>
        </div>
        <div v-else-if="listElement && !isSupplier">
            <div v-if="hasArrived && transport.destinationWaitingTimestamp" class="arrival-time__data">
                <TickIcon class="arrival-time__icon arrival-time__tick-icon" />
                <Words small green>
                    {{ $d(transport.destinationWaitingTimestamp * 1000, 'short') }}
                    &nbsp;&nbsp;&nbsp;
                    {{ $t('timeSuffix', { time: $d(transport.destinationWaitingTimestamp * 1000, 'time') }) }}
                </Words>
            </div>
            <div v-else class="arrival-time__data">
                <CalendarIcon :class="{ 'arrival-time__calendar-icon': !dark }" class="arrival-time__icon" />
                <Words
                    v-if="transport.shippingMethod === 'flix'"
                    :muted="!dark"
                    :small="!singleLine"
                    class="arrival-time__data-multiline"
                >
                    {{ $d(transport.shippingDate * 1000, 'short') }}&nbsp;&nbsp;&nbsp;{{
                        $t('timeSuffix', { time: $d(transport.shippingDate * 1000, 'time') })
                    }}
                </Words>
                <Words
                    v-else-if="'plannedDeliveryTime' in transport && isClient"
                    :muted="!dark"
                    :small="!singleLine"
                    :class="{ 'arrival-time__data-multiline': !singleLine }"
                >
                    {{ $d(transport.plannedDeliveryTime * 1000, 'short') }}&nbsp;&nbsp;&nbsp;
                    {{
                        $d(subMinutes(transport.plannedDeliveryTime * 1000, transport.plannedDeliveryRange / 2), 'time')
                    }}
                    -
                    {{
                        $t('timeSuffix', {
                            time: $d(
                                addMinutes(transport.plannedDeliveryTime * 1000, transport.plannedDeliveryRange / 2),
                                'time'
                            ),
                        })
                    }}
                </Words>

                <Words
                    v-else
                    :muted="!dark"
                    :small="!singleLine"
                    :class="{ 'arrival-time__data-multiline': !singleLine }"
                >
                    {{ $d(transport.shippingWindowStart * 1000, 'short') }} -
                    {{ $d(transport.shippingWindowEnd * 1000, 'short') }}
                </Words>
            </div>
        </div>
        <div v-else>
            <div v-if="transport.status === 'in_transit'" class="arrival-time__data">
                <DrivingTime :transport="transport" />
            </div>
            <div v-else-if="hasArrived" class="arrival-time__data">
                <TickIcon class="arrival-time__icon arrival-time__tick-icon" />
                <Words small green bold>
                    {{ $t('components.arrivalTime.arrived') }}<br />
                    {{ $d(transport.destinationWaitingTimestamp * 1000, 'short') }}
                    &nbsp;&nbsp;&nbsp;
                    {{ $t('timeSuffix', { time: $d(transport.destinationWaitingTimestamp * 1000, 'time') }) }}
                </Words>
            </div>
        </div>
    </div>
</template>

<script>
import routeContext from '@/plugins/mixins/routeContext';
import { CONTEXT_SUPPLIER, CONTEXT_CLIENT } from '@/constants/context';
import { statusIsBetween } from '@/services/utils/transport';
import addMinutes from 'date-fns/add_minutes';
import subMinutes from 'date-fns/sub_minutes';

import DrivingTime from '@/components/Transport/DrivingTime';
import Words from '@/components/Typography/Words';

import CalendarIcon from '@/assets/icons/micro/calendar.svg';
import TickIcon from '@/assets/icons/micro/tick.svg';

export default {
    name: 'ArrivalTime',
    components: {
        DrivingTime,
        Words,
        CalendarIcon,
        TickIcon,
    },
    mixins: [routeContext],
    props: {
        transport: {
            type: Object,
            required: true,
        },
        listElement: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        singleLine: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isSupplier() {
            return this.inRouteContext(CONTEXT_SUPPLIER);
        },

        isClient() {
            return this.inRouteContext(CONTEXT_CLIENT);
        },

        hasArrived() {
            return statusIsBetween(this.transport.status, 'destination_waiting', 'delivered');
        },
    },
    methods: {
        addMinutes,
        subMinutes,
    },
};
</script>

<style lang="scss">
.arrival-time__icon {
    flex: 0 0 14px;
    display: inline-block;
    vertical-align: middle;
    min-width: 14px;
    margin-right: 10px;

    svg {
        width: 100%;
        height: auto;
    }
}

.arrival-time__data {
    display: flex;
    flex-flow: row nowrap;
}

.arrival-time__product-info {
    display: inline;
}

.arrival-time__calendar-icon {
    path {
        fill: $color-grey;
    }
}

.arrival-time__flag-icon,
.arrival-time__tick-icon {
    path {
        fill: $color-green;
    }
}

.arrival-time__data-multiline {
    vertical-align: middle;
    display: inline-block;
    width: calc(100% - 24px);
}
</style>

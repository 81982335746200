<template>
    <CustomPriceAdjustmentsPage
        :state="basket"
        :prices="pricesData"
        :section-to-edit="sectionToEdit"
        :section="section"
        :can-save-section="canSaveSection"
        :is-project="isProject"
        :project-position-id="projectPositionId"
        @edit-project-position="editProjectDeliveryPosition"
        @calculate-project-position="calculateMaterialProjectPosition"
        @calculate-custom-order="calculateMaterialCustomOrder"
        @edit-section="editSection"
        @close-section="closeSection"
        @reset-price-data="resetPriceData"
        @save-prices-data="savePriceData"
        @save-section="saveSection"
        @save-project-position="saveProjectPosition"
        @set-disposal-price="setDisposalPrice"
        @submit="submit"
        @close="close"
    >
        <template #paymentTerms>
            <ReactBridge
                v-if="!isCustomDeliveryOrForwarding"
                :react-component="PaymentTermsContainer"
                :props="{
                    organizations: getPaymentTerms({ supplierInfo, carrierInfo }, 'delivery'),
                    paymentTermsProp: isProjectPositionEditMode
                        ? paymentTermsFromStore
                        : paymentTermsFromApi || paymentTermsFromStore,
                }"
                @change="handlePaymentTermsChange"
                @defaultPaymentTerms="setDefaultPaymentTerms"
            />
            <HorizontalDivider v-if="!isCustomDeliveryOrForwarding" size="12" />
        </template>

        <template #productCosts="{ error }">
            <MaterialSection
                v-if="hasMaterial"
                v-model="current.material"
                :error="error"
                @edit="editSection('material')"
            />
        </template>

        <template #transports>
            <TransportSection
                v-if="hasTransport"
                class="mt-12"
                :basket-type="basket.type"
                :fixed-price="hasFixedPrice"
                :transport-distance="pricesData.distance"
                :transport-duration="pricesData.duration"
                :transport-price-unit="transportPriceUnit"
                :transports="current.transport"
                :show-distance-notice="
                    type === 'shipment' || pricesData.type === pricesData.constructor.TYPE_PROJECT_DELIVERY
                "
                @edit="editSection('transport')"
            />
        </template>

        <template #customPosition>
            <CustomPositionsSection
                v-if="hasCustomPositions"
                class="mt-12"
                :custom-positions="current.customPositions"
                @edit="editCustomPosition"
                @remove="removeCustomPosition"
                @add="addCustomPosition"
            />
        </template>

        <template #costPreview>
            <CostPreviewSection
                v-if="isProject && current.transport && current.transport.enabled.length > 0"
                class="mt-12"
                :transports="current.transport"
                :material="current.material"
                :fixed-price="hasFixedPrice"
            />
        </template>

        <template #totals>
            <TotalsSection
                v-if="current.transport && current.transport.enabled.length > 0"
                class="mt-12"
                :fixed-price="isFixedPrice"
                :current="current"
                :material="
                    current.material || {
                        qty: 0,
                        info: null,
                    }
                "
                :max-payload-transport="current.maxPayloadTransport"
                :type="pricesData.type"
            />
        </template>

        <template #editMaterial>
            <MaterialSection
                v-if="section === 'material'"
                v-model="sectionData"
                is-edit-mode
                :original="original"
                :original-prices="originalPricesData"
            />
        </template>

        <template v-if="section === 'transport'" #editTransports>
            <TransportEditSection
                v-for="(vehicleClass, index) in sectionData.collection"
                :key="vehicleClass.id"
                v-model="sectionData.collection[index]"
                :original-vehicle-class="original.transport.collection[index]"
                :fixed-price="isFixedPrice"
                :transport-price-unit="transportPriceUnit"
                :show-enable-controls="vehiclesCanBeEnabled"
            />
        </template>

        <template #editShipping>
            <!-- @TODO: refactor Stylings -->
            <ShippingEditSection
                v-if="section === 'shipping'"
                v-model="sectionData"
                :original="original"
                :type="pricesData.type"
                :show-totals="pricesData.type !== pricesData.constructor.TYPE_PROJECT_DELIVERY"
            />
        </template>

        <template #editCustomPosition>
            <CustomPositionEditSection
                v-if="section === 'customPosition'"
                v-model="sectionData"
                @change="checkIfCanSaveSection($event)"
            />
        </template>
    </CustomPriceAdjustmentsPage>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import _cloneDeep from 'lodash/cloneDeep';
import statefulMixin from '@/plugins/mixins/statefulMixin';

import CustomPriceAdjustmentsPage from '@/pages/Checkout/CustomPriceAdjustmentsPage';
import ProjectPositionApi from '@/services/Api/ProjectPosition';
import ProjectApi from '@/services/Api/Project';
import PriceAdjustmentView from '@/pages/Checkout/components/PriceAdjustment/models/PriceAdjustmentView';
import CustomOrder from '@/services/Api/CustomOrder';
import Toaster from '@/services/Toaster';
import { navigationFailure } from '@/services/utils/router';
import DisposalPrice from '@/services/Api/Platform/DisposalPrice';
import MaterialSection from '@/pages/Checkout/components/PriceAdjustment/MaterialSection';
import TransportSection from '@/pages/Checkout/components/PriceAdjustment/TransportSection';
import CostPreviewSection from '@/pages/Checkout/components/PriceAdjustment/CostPreviewSection.vue';
import MaterialPricingView from '@/pages/Checkout/components/PriceAdjustment/models/MaterialPricingView';
import ShippingPricingView from '@/pages/Checkout/components/PriceAdjustment/models/ShippingPricingView';
import TransportPricingCollectionView from '@/pages/Checkout/components/PriceAdjustment/models/TransportPricingCollectionView';
import CustomPositionPricingSetView from '@/pages/Checkout/components/PriceAdjustment/models/CustomPositionPricingSetView';
import CustomPositionPricingItemView from '@/pages/Checkout/components/PriceAdjustment/models/CustomPositionPricingItemView';

import TotalsSection from '@/pages/Checkout/components/PriceAdjustment/TotalsSection';
import TransportEditSection from '@/pages/Checkout/components/PriceAdjustment/TransportEditSection';
import ShippingEditSection from '@/pages/Checkout/components/PriceAdjustment/ShippingEditSection';
import CustomPositionsSection from '@/pages/Checkout/components/PriceAdjustment/ProjectCustomPositionsSection';
import CustomPositionEditSection from '@/pages/Checkout/components/PriceAdjustment/CustomPositionEditSection';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import { PaymentTermsContainer } from '@/pages/Checkout/PaymentTerms/PaymentTermsContainer';
import ReactBridge from '@/reactBridge/ReactBridge';
import HorizontalDivider from '@/_components/HorizontalDivider/HorizontalDivider';
import { usePaymentTerms } from '@/pages/Checkout/PaymentTerms/usePaymentTerms';
import { CHECKOUT_BASE_TYPE_DELIVERY, CHECKOUT_BASE_TYPE_SHIPMENT } from '@/constants/checkoutTypes';

export default {
    name: 'CustomPriceAdjustmentsPageMaterial',
    components: {
        HorizontalDivider,
        ShippingEditSection,
        TransportEditSection,
        TransportSection,
        CustomPositionsSection,
        MaterialSection,
        CostPreviewSection,
        TotalsSection,
        CustomPriceAdjustmentsPage,
        CustomPositionEditSection,
        ReactBridge,
    },
    mixins: [statefulMixin],
    props: {
        projectPositionId: {
            type: [Number, String],
            default: null,
        },
    },
    setup() {
        const { paymentTermsFromApi, preparePaymentTermDataForStore, getPaymentTerms } = usePaymentTerms();
        return {
            PaymentTermsContainer,
            getPaymentTerms,
            preparePaymentTermDataForStore,
            paymentTermsFromApi,
        };
    },
    data() {
        return {
            pricesData: null,
            toasterError: null,
            sectionToEdit: null,
            section: null,
            sectionData: null,
            canSaveSection: null,
        };
    },
    computed: {
        ...mapState('basket', {
            constructionSite: 'constructionSite',
            isCustom: 'isCustom',
            product: 'product',
            projectId: 'projectId',
            shipmentBillingDetails: 'shipmentBillingDetails',
            shipmentQtyAndDocument: 'shipmentQtyAndDocument',
            supplierInfo: 'supplierInfo',
            qty: 'qty',
            quote: 'quote',
            type: 'type',
            pricesDataFromStore: 'pricesData',
            disposalPrice: 'disposalPrice',
            originalPricesData: 'originalPricesData',
            carrierInfo: 'carrierInfo',
            paymentTerms: 'paymentTerms',
            defaultPaymentTerms: 'defaultPaymentTerms',
            paymentTermsFromStore: 'paymentTerms',
        }),

        basket() {
            return {
                constructionSiteId: this.constructionSite?.id,
                isCustom: this.isCustom,
                productId: this.product?.id,
                projectId: this.projectId,
                shipmentBillingDetails: this.shipmentBillingDetails,
                shipmentQtyAndDocument: this.shipmentQtyAndDocument,
                qty: this.qty,
                quote: this.quote,
                type: this.type,
                pricesDataFromStore: this.pricesDataFromStore,
                disposalPrice: this.disposalPrice,
                supplierInfo: this.supplierInfo,
            };
        },
        current() {
            return this.pricesData?.current;
        },
        original() {
            return this.pricesData?.original;
        },

        isProject() {
            return (
                this.basketType === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS ||
                this.basketType === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS ||
                this.basketType === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DELIVERY ||
                this.isProjectPositionEditMode
            );
        },

        hasCustomPositions() {
            return this.pricesData?.current?.customPositions?.collection?.length > 0;
        },
        hasMaterial() {
            const { material } = this.current;
            return material && material.info !== null;
        },
        hasTransport() {
            return !!this.pricesData?.current?.transport;
        },
        hasPreparedTransporstForCustomPositions() {
            return !!this.current?.enabledTransports?.length;
        },
        isFixedPrice() {
            return (
                !!this.basket.shipmentBillingDetails &&
                this.basket.shipmentBillingDetails.billingMethod === 'fixedPrice'
            );
        },
        isProjectPositionEditMode() {
            return !!this.projectPositionId;
        },
        hasValidData() {
            if (this.isProject) {
                return this.pricesData?.current?.enabledTransports?.length > 0;
            }

            return true;
        },
        basketType() {
            return this.isProjectPositionEditMode ? 'project-delivery' : this.basket.type;
        },
        isCustomDeliveryOrForwarding() {
            return [CHECKOUT_BASE_TYPE_DELIVERY, CHECKOUT_BASE_TYPE_SHIPMENT].includes(this.basket.type);
        },
        transportPriceUnit() {
            if (!this.basket.shipmentBillingDetails) return 'ton';
            const { billingMethod } = this.basket.shipmentBillingDetails;
            return this.basketType === CHECKOUT_BASE_TYPE_SHIPMENT && billingMethod === 'fixedPrice'
                ? 'transport'
                : 'ton';
        },
        hasFixedPrice() {
            return (
                !!this.basket?.shipmentBillingDetails?.billingMethod &&
                this.basket?.shipmentBillingDetails?.billingMethod === 'fixedPrice'
            );
        },
        vehiclesCanBeEnabled() {
            return (
                this.basketType === CHECKOUT_BASE_TYPE_SHIPMENT ||
                this.pricesData.type === this.pricesData.constructor.TYPE_PROJECT_DELIVERY ||
                this.pricesData.type === this.pricesData.constructor.TYPE_PROJECT_DISPOSAL
            );
        },
    },
    methods: {
        ...mapMutations('basket', ['setDisposalPrice']),

        setDefaultPaymentTerms(terms) {
            this.$store.commit('basket/SET_DEFAULT_PAYMENT_TERMS', terms);
        },

        handlePaymentTermsChange(paymentTerm) {
            const paymentTermsForStore = this.preparePaymentTermDataForStore(paymentTerm, this.paymentTermsFromStore);
            this.$store.commit('basket/SET_PAYMENT_TERMS', paymentTermsForStore);
        },
        editSection(section) {
            const sectionView = this.current[section];

            if (
                sectionView instanceof MaterialPricingView ||
                sectionView instanceof ShippingPricingView ||
                sectionView instanceof TransportPricingCollectionView
            ) {
                this.sectionData = sectionView.clone();
            } else {
                // Whatever this is, we probably do not support it...
                this.$logger().error(`"${section}" is currently not supported`);
                return;
            }

            this.section = section;
        },
        async editProjectDeliveryPosition(projectPositionId) {
            this.projectPosition = await ProjectPositionApi.getOneById(projectPositionId);

            const calculationData = await ProjectApi.calculateDeliveryPosition(
                this.projectPosition.project.id,
                this.projectPosition.constructionSite.id,
                this.projectPosition.product.id,
                this.projectPosition.sellerProductId,
                this.projectPosition.factory.id
            );

            this.$store.commit('basket/setOriginalPricesData', calculationData);
            this.paymentTermsFromApi = this.projectPosition.paymentTerms;
            this.$store.commit('basket/SET_PAYMENT_TERMS', this.paymentTermsFromApi);
            this.setDefaultPaymentTerms(this.paymentTermsFromApi);

            this.pricesData = PriceAdjustmentView.createFromProjectDeliveryPositionPayload(
                this.projectPosition,
                calculationData
            );
        },
        async calculateMaterialProjectPosition() {
            const original = await ProjectApi.calculateDeliveryPosition(
                this.basket.projectId,
                this.basket.constructionSiteId,
                this.basket.productId,
                this.basket.supplierInfo.sellerProductId,
                this.basket.supplierInfo.supplier.factoryId
            );

            const current = this.pricesDataFromStore || original;

            this.pricesData = PriceAdjustmentView.createFromProjectDeliveryPositionBasket(
                parseInt(this.qty),
                original,
                current
            );
        },
        async calculateMaterialCustomOrder() {
            await this.stateful('refreshData', async () => {
                try {
                    const data = await CustomOrder.getOneById(this.basket.quote.id);
                    this.pricesData = PriceAdjustmentView.createFromCustomOrder(this.basket.quote, data);
                } catch (err) {
                    Toaster.error(this.$t('pages.checkout.priceAdjustments.quoteSaveError'));
                }
            });
        },
        async saveSection() {
            if (this.section === 'customPosition') {
                if (!this.current?.customPositions?.collection?.length) {
                    await this.initializeCustomPositions();
                }

                const position = this.sectionData;
                if (position.positionIndex !== null) {
                    this.current.customPositions
                        .get(position.vehicleClassId)
                        .setPosition(position.positionIndex, position);
                } else {
                    this.current.customPositions.get(position.vehicleClassId).addPosition(position);
                }
            } else {
                this.$set(this.current, this.section, this.sectionData);
            }

            await this.savePriceData();
            this.closeSection();
        },

        initializeCustomPositions() {
            this.pricesData.current.customPositions = CustomPositionPricingSetView.createFromEnabledVehicleClasses(
                this.hasPreparedTransporstForCustomPositions,
                // customPositions
                this.current.customPositions
            );
        },

        editCustomPosition({ cid, position }) {
            const pos = position.clone();
            pos.positionIndex = cid;
            this.sectionData = pos;
            this.section = 'customPosition';
        },

        async removeCustomPosition({ vid, cid }) {
            this.current.customPositions.get(vid).removePosition(cid);
            await this.savePriceData();
        },

        addCustomPosition(vehicleClass) {
            const position = new CustomPositionPricingItemView();
            position.vehicleClassId = vehicleClass.vid;

            this.sectionData = position;
            this.section = 'customPosition';
        },

        async savePriceData() {
            if (!this.basket.isCustom || this.isProject) return;

            await this.stateful('save', async () => {
                try {
                    await this.saveData(
                        this.current.unfoldToCustomOrder(this.basket.shipmentBillingDetails?.billingMethod)
                    );
                } catch (err) {
                    Toaster.error(err);
                }
            });
        },

        async saveData(data) {
            const response = await CustomOrder.save({
                id: this.basket.quote.id,
                ...data,
            });
            this.pricesData = PriceAdjustmentView.createFromCustomOrder(this.basket.quote, response);
            return response;
        },
        async resetProjectDeliveryPrices() {
            this.pricesData.current = this.pricesData.original.clone();
        },
        async submit({ hasValidData, hasDisposalCost }) {
            if (!hasValidData) return;

            await this.stateful('submit', async () => {
                this.$store.commit('basket/setPricesData', this.current.unfold());

                if (hasDisposalCost) {
                    try {
                        await DisposalPrice.updateQuote(
                            this.basket.quote.id,
                            this.basket.disposalPrice,
                            this.basket.shipmentBillingDetails.billingMethod === 'fixedPrice'
                        );
                    } catch (err) {
                        Toaster.error(err);
                        return;
                    }
                }

                this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(navigationFailure);
            });
        },
        async resetPriceData() {
            if (this.isProject) {
                this.pricesData.current = this.pricesData.original.clone();
                this.$store.commit('basket/RESET_PAYMENT_TERMS');
                return;
            }

            // The try-catch block below is probably obsolete but this needs to be verified before removing.
            await this.stateful('save', async () => {
                try {
                    this.$emit(
                        'saveData',
                        this.pricesData.original.unfoldToCustomOrder(this.basket.shipmentBillingDetails?.billingMethod)
                    );
                    await this.saveData(
                        this.pricesData.original.unfoldToCustomOrder(this.basket.shipmentBillingDetails?.billingMethod)
                    );
                    Toaster.info(this.$t('pages.checkout.priceAdjustments.resetted'));
                } catch (err) {
                    Toaster.error(err);
                }
            });
        },
        close() {
            this.$emit('close');
        },
        closeSection() {
            this.canSaveSection = false;
            this.section = null;
        },
        checkIfCanSaveSection({ isValid }) {
            this.canSaveSection = isValid;
        },
        async saveProjectPosition() {
            if (!this.hasValidData) return;
            await this.stateful('saveProjectPosition', async () => {
                const projectPosition = _cloneDeep(this.projectPosition);
                const rawData = this.current.unfold();

                projectPosition.materialPurchaseUnitPrice = rawData.material.purchaseUnitPrice;
                projectPosition.materialRetailUnitPrice = rawData.material.retailUnitPrice;
                projectPosition.shippingPurchasePrice = rawData.shipping.purchasePrice;
                projectPosition.shippingRetailPrice = rawData.shipping.retailPrice;
                projectPosition.paymentTerms = this.paymentTerms;

                const vehicleClasses = [];

                this.current.transport.enabled.forEach(transportPricing => {
                    const vc = transportPricing.unfold();

                    vehicleClasses.push({
                        purchasePrice: vc.purchaseUnitPrice,
                        retailPrice: vc.retailUnitPrice,
                        vehicleClass: {
                            id: vc.id,
                        },
                    });
                });

                projectPosition.vehicleClasses = vehicleClasses;

                try {
                    await ProjectPositionApi.save(projectPosition);
                    Toaster.success(
                        this.$t('pages.checkout.priceAdjustments.savePricePositionSuccessMessage', {
                            number: projectPosition.number,
                        })
                    );
                    this.$emit('projectPositionSaved');
                } catch (err) {
                    Toaster.error(err);
                }
            });
        },
    },
};
</script>

import { ref } from 'vue';
import MarketApi, { MarketPermissionOperand } from '@/services/Api/Market';
import LogService from '@schuettflix/util-log';
export { MarketPermissionOperand };
const Log = new LogService('services/MarketPermission/useMarketPermission');
export function useMarketPermission(operation) {
    const isLoading = ref(false);
    const error = ref(null);
    const allowedCountries = ref(null);
    const refresh = async () => {
        isLoading.value = true;
        try {
            const info = await MarketApi.getPermission(operation);
            allowedCountries.value = info.allowedCountries;
        }
        catch (err) {
            Log.error(err);
            error.value = err;
            allowedCountries.value = null;
        }
        isLoading.value = false;
    };
    refresh();
    return {
        allowedCountries,
        refresh,
        isLoading,
        error,
    };
}
export function useMarketPermissions(operand, operations) {
    const isLoading = ref(false);
    const error = ref(null);
    const allowedCountries = ref(null);
    const refresh = async () => {
        isLoading.value = true;
        try {
            const info = await MarketApi.getPermissions(operand, operations);
            allowedCountries.value = info.allowedCountries;
        }
        catch (err) {
            Log.error(err);
            error.value = err;
            allowedCountries.value = null;
        }
        isLoading.value = false;
    };
    refresh();
    return {
        allowedCountries,
        refresh,
        isLoading,
        error,
    };
}

<template>
    <FlyoutPage>
        <HeaderBar slot="header">
            <HeaderBarItem slot="left" button @click="$root.routeBack()">
                <ArrowIcon width="32" height="18" />
            </HeaderBarItem>
            <div slot="headline">{{ $t('pages.productManagement.wasteProductVariantsPage.headline') }}</div>
        </HeaderBar>
        <div class="waste-product-variants container-deprecated">
            <form novalidate>
                <Headline :level="6">
                    {{ $t('pages.productManagement.wasteProductVariantsPage.subHeadline') }}
                </Headline>
                <WasteProductFactoryTypes
                    v-for="(state, index) in states"
                    :key="state.key"
                    v-model="states[index]"
                    :product-id="productId"
                    :state-key="state.key"
                />
            </form>
        </div>
    </FlyoutPage>
</template>

<script>
import FlyoutPage from '@/components/Layout/FlyoutPage';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Headline from '@/components/Typography/Headline';
import WasteProductFactoryTypes from './WasteProductFactoryTypes';
import ArrowIcon from '@/assets/icons/regular/arrow.svg';
import { mapGetters } from 'vuex';

export default {
    name: 'WasteProductStates',
    components: {
        FlyoutPage,
        HeaderBar,
        HeaderBarItem,
        Headline,
        WasteProductFactoryTypes,
        ArrowIcon,
    },
    data() {
        return {
            productId: null,
            states: null,
        };
    },
    computed: {
        ...mapGetters('productManagement', ['getWasteStates']),
    },
    created() {
        this.productId = Number(this.$route.params.id);
        this.populateStates(this.$route.params.states);
    },
    methods: {
        async populateStates(statesFromRoute) {
            // If the user clicks to show the variants within a states, we fetch
            // the states being passed through the route from WasteFormPage. Otherwise,
            // we fetch what we saved in the store before (necessary for page reload case).
            if (statesFromRoute) {
                this.$store.commit('productManagement/SET_WASTE_STATES', statesFromRoute);
            } else if (this.getWasteStates.length === 0) {
                await this.$store.dispatch('productManagement/fetchWasteProductVariantStates', {
                    productId: this.productId,
                });
            }
            this.states = this.getWasteStates;
        },
    },
};
</script>
<style lang="scss" scoped>
.waste-product-variants {
    padding-top: 20px;
}
.card {
    &:hover {
        cursor: pointer;
    }
    .head {
        background-color: $color-darkGrey;
        padding: 18px;
        display: block;
        color: $color-white;
        cursor: pointer;

        .icon {
            display: block;
            justify-self: end;
        }
    }
}
</style>

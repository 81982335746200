import AbstractFilterableResource from './AbstractFilterableResource';

class CreditNote extends AbstractFilterableResource {
    async createForOrganization(organizationId, transportIds, context) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/transports/${organizationId}`, {
                transportIds,
                context,
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async createCancellation(data) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/cancellation`, data);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new CreditNote('/creditnote');

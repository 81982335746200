import AbstractProductResource from '../AbstractProductResource';
import UrlBuilder from '@/services/Api/UrlBuilder';

class WasteProductVariants extends AbstractProductResource {
    /**
     * get products of type
     */
    async getWasteProductVariants(productId, stateKey) {
        try {
            const response = await this.apiService.get(
                UrlBuilder.replaceUrlParamsWithValues('/waste/product/{productId}/variants/{stateKey}', {
                    productId,
                    stateKey,
                })
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }
}

export default new WasteProductVariants('/waste/product/{productId}/variants', {
    404: 'pages.productManagement.wasteProductVariantPage.errors.notFound',
    500: 'pages.productManagement.wasteProductVariantPage.errors.serverError',
});

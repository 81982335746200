<template>
    <Flyout
        screen-name="useraccount-notifications"
        :active="active"
        size="small"
        class="notification-management"
        show-header
        :headline="$t('components.notificationManagement.headline')"
        @closed="closeFlyout"
        @opening="refreshSubscriptions()"
    >
        <LoadingSpinner v-if="!subscriptions" block dark />

        <template v-else>
            <Tile class="notification-management__tile notification-management__tile--grey">
                <SfMessageBox v-if="isEmailDisabled" type="info" class="mb-6">
                    {{ $t(`components.notificationManagement.channels.email.hintMessage`) }}

                    <SfLink class="mt-2" @click="openMyAccount">
                        {{ $t(`components.notificationManagement.channels.email.hintAction`) }}
                    </SfLink>
                </SfMessageBox>

                <Words small muted>{{ $t('components.notificationManagement.description') }}</Words>
            </Tile>
            <Tile v-for="(subscription, i) in subscriptions" :key="i">
                <Words bold block spaced-bottom>
                    {{ $t(`components.notificationManagement.sections.${subscription.category}.headline`) }}
                </Words>
                <Words small block spaced-bottom>
                    {{ $t(`components.notificationManagement.sections.${subscription.category}.description`) }}
                </Words>
                <div v-for="(channel, c) in subscription.channels" :key="c" class="notifiaction-management__channel">
                    <ToggleSwitchField
                        v-model="subscription.channels[c]"
                        :disabled="isChannelDisabled(c)"
                        reverse-label
                    >
                        {{ $t(`components.notificationManagement.channels.${c}.label`) }}
                    </ToggleSwitchField>
                </div>
            </Tile>
        </template>

        <template #bottom>
            <ButtonGroup v-if="hasChanges">
                <Button primary :is-loading="isPending" @click="submit()">
                    {{ $t(`components.notificationManagement.actions.submit`) }}
                </Button>
            </ButtonGroup>
        </template>
    </Flyout>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { mapGetters } from 'vuex';
import NotificationSubscriptionService from '@/services/Notification/NotificationSubscriptionService';
import Toaster from '@/services/Toaster';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Flyout from '@/components/Layout/Flyout';
import LoadingSpinner from '@/components/LoadingSpinner';
import Tile from '@/components/Layout/Tile';
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import Words from '@/components/Typography/Words';

import { SfLink, SfMessageBox } from '@schuettflix/vue-components';

export default {
    name: 'NotificationManagement',
    components: {
        // eslint-disable-next-line vue/no-reserved-component-names
        Button,
        ButtonGroup,
        Flyout,
        LoadingSpinner,
        Tile,
        ToggleSwitchField,
        Words,
        SfMessageBox,
        SfLink,
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isPending: false,
            subscriptions: null,
            subscriptionsCheck: null,
        };
    },
    computed: {
        ...mapGetters('user', ['info']),

        isEmailDisabled() {
            return this.isChannelDisabled('email') && _get(this, 'subscriptions.0.channels.email', null) !== null;
        },

        hasChanges() {
            return !_isEqual(this.subscriptions, this.subscriptionsCheck);
        },
    },
    methods: {
        closeFlyout() {
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$eventHub.$emit('page.notificationManagement', false);
        },

        isChannelDisabled(identifier) {
            if (identifier === 'email') {
                return !this.info.user.email;
            }

            if (identifier === 'push') {
                return false;
            }

            return false;
        },

        openMyAccount() {
            this.closeFlyout();
            this.$eventHub.$emit('myAccountToggled', true);
        },

        async refreshSubscriptions() {
            try {
                this.subscriptions = await NotificationSubscriptionService.getSubscriptions();
                this.subscriptionsCheck = _cloneDeep(this.subscriptions);
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(this.$t('components.notificationManagement.errors.refresh'));
            }
        },

        async submit() {
            this.isPending = true;
            try {
                await NotificationSubscriptionService.updateSubscriptions(this.subscriptions);
                await this.refreshSubscriptions();
                Toaster.success(this.$t('components.notificationManagement.success.update'));
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(this.$t('components.notificationManagement.errors.update'));
            }
            this.isPending = false;
        },
    },
};
</script>

<style lang="scss">
.notification-management__tile--grey {
    background-color: $color-lightGrey;
}

.notifiaction-management__channel {
    margin: 20px 0;
}

.notifiaction-management__hint {
    margin-top: 10px;
    margin-right: 83px;
}

.notifiaction-management__hint-message {
    margin-bottom: 5px;
}
</style>

<template>
    <div>
        <LoadingSpinner v-if="isLoading" spaced dark />
        <GridRow v-else class="organization-form__fieldset" :count="4" spacing="small">
            <Words
                v-if="showPaymentOptionChoice"
                bold
                size-normal
                class="span-4 organization-form-payment__payment-method-title"
            >
                {{ $t('components.organizationForm.paymentInfo.paymentMethod.title') }}
            </Words>

            <PaymentMethodChoice
                v-if="showPaymentOptionChoice"
                :organization="organization"
                :original-organization="originalOrganization"
                :is-global-variable-direct-debit-active="isGlobalVariableDirectDebitActive"
                :readonly="readonly"
                @block-save-by-modal="$emit('block-save-by-modal', $event)"
                @update-organization="updatePaymentChoice"
            />

            <Card
                v-if="showDirectDebitDisabledHint"
                no-padding
                spaceless
                flex
                class="organization-form-payment__disabled-global-variable-hint span-4"
            >
                <WarningRectangleIcon
                    class="icon--mono organization-form-payment__disabled-global-variable-hint__warningIcon"
                    width="24"
                    height="24"
                />
                <Words v-html="$t('components.organizationForm.paymentInfo.modal.paymentMethod.disabledDirectDebit')" />
            </Card>

            <hr v-if="showPaymentOptionChoice" class="organization-form-payment__horizontal-separator span-4" />

            <Words v-if="isClient && !isDirectDebit && isNotSupplierOrCarrier" bold size-normal class="span-4">
                {{ $t('components.organizationForm.paymentInfo.titleOptional') }}
            </Words>

            <Words v-else bold size-normal class="span-4">
                {{ $t('components.organizationForm.paymentInfo.title') }}
            </Words>

            <Words
                v-if="!isClient || (isClient && isSupplierOrCarrier)"
                spaced-bottom-medium
                size-normal
                block
                class="span-4"
                >{{
                    $t(
                        selectCreditNoteDocKey({
                            creditNote: `components.organizationForm.paymentInfo.paymentMethod.requiredPaymentInfo-2.creditNote`,
                            selfBilling: `components.organizationForm.paymentInfo.paymentMethod.requiredPaymentInfo-2.selfBilling`,
                        })
                    )
                }}
            </Words>

            <Words
                v-if="isDirectDebit && isClient && isNotSupplierOrCarrier"
                spaced-bottom-medium
                size-normal
                block
                class="span-4"
                >{{ $t('components.organizationForm.paymentInfo.paymentMethod.directDebit.description') }}
            </Words>

            <Words
                v-if="!isDirectDebit && isClient && isNotSupplierOrCarrier"
                spaced-bottom-medium
                size-normal
                block
                class="span-4"
                >{{ $t('components.organizationForm.paymentInfo.paymentMethod.invoice.description') }}
            </Words>

            <BankDetailsForm
                :errors="errors"
                :organization="organization"
                :original-organization="originalOrganization"
                :readonly="readonly"
                :is-global-variable-direct-debit-active="isGlobalVariableDirectDebitActive"
                @update-payment-details="updatePaymentDetails"
                @block-save-by-modal="$emit('block-save-by-modal', $event)"
            />

            <CheckboxField
                v-if="isDirectDebit"
                :value="isMandateCheckboxChecked"
                :disabled="isMandateCheckboxDisabled"
                :error="errors.mandateReference"
                black
                class="span-4 mt-8"
                @input="toggleCheckBox($event)"
            >
                <div>
                    <span class="font-copy-sm-strong block" :class="{ 'text-subdued': isMandateCheckboxDisabled }">
                        {{ $t('components.organizationForm.paymentInfo.mandateCheckbox.title') }}
                    </span>
                    <span class="font-copy-sm mt-1 block" :class="{ 'text-subdued': isMandateCheckboxDisabled }">
                        {{ $t('components.organizationForm.paymentInfo.mandateCheckbox.text') }}
                    </span>
                </div>
            </CheckboxField>
            <ErrorMessage v-if="errors.mandateReference" class="span-4" :message="errors.mandateReference" />

            <MandateReference
                v-if="isDirectDebit && hasMandateReference"
                :mandate-reference="organization.mandateReference"
                :organization="organization"
            />
        </GridRow>
        <PaymentInfoModalWrapper
            :organization="organization"
            :original-organization="originalOrganization"
            :modal-ref-to-trigger="modalRefToTrigger"
            @save-from-modal="$emit('save-from-modal')"
            @update-organization="$emit('update-organization', $event)"
            @block-save-by-modal="$emit('block-save-by-modal', $event)"
        />
    </div>
</template>

<script>
import GridRow from '@/components/Layout/GridRow';
import Words from '@/components/Typography/Words';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import { isClient, isSupplier, isCarrier, isDirectDebit, isChangedPaymentInfo } from '@/services/utils/organization';
import Utils from '@/services/Api/Platform/Utils';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
import LoadingSpinner from '@/components/LoadingSpinner';
import PaymentInfoModalWrapper from '@/pages/Organization/partials/TabPaymentInfo/PaymentInfoModalWrapper';
import Card from '@/components/Layout/Card';
import PaymentMethodChoice from '@/pages/Organization/partials/TabPaymentInfo/PaymentMethodChoice';
import BankDetailsForm from '@/pages/Organization/partials/TabPaymentInfo/BankDetailsForm';
import MandateReference from '@/pages/Organization/partials/TabPaymentInfo/MandateReference';

import WarningRectangleIcon from '@/assets/icons/regular/warningRectangle.svg';
import CheckboxField from '@/components/Form/CheckboxField';
import ErrorMessage from '@/components/Form/ErrorMessage';

export default {
    name: 'TabPaymentInfo',

    components: {
        ErrorMessage,
        CheckboxField,
        MandateReference,
        BankDetailsForm,
        PaymentMethodChoice,
        Card,
        PaymentInfoModalWrapper,
        LoadingSpinner,
        GridRow,
        Words,

        WarningRectangleIcon,
    },

    props: {
        organization: {
            type: Object,
            default: null,
        },
        originalOrganization: {
            type: Object,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Object,
            default: () => ({
                iban: false,
                bic: false,
                name: false,
                owner: false,
                mandateReference: false,
            }),
        },
        modalRefToTrigger: {
            type: String,
            default: '',
        },
        isNewOrganization: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { selectCreditNoteDocKey } = useLegalTerms();
        return {
            selectCreditNoteDocKey,
        };
    },
    data() {
        return {
            isGlobalVariableDirectDebitActive: false,
            isLoading: false,
        };
    },
    computed: {
        isDirectDebit() {
            return isDirectDebit(this.organization);
        },
        isChangedPaymentInfo() {
            return isChangedPaymentInfo(this.organization, this.originalOrganization);
        },
        isMandateCheckboxDisabled() {
            return (
                this.readonly ||
                !this.isGlobalVariableDirectDebitActive ||
                (!!this.organization?.mandateReference?.acceptedAt && !this.isChangedPaymentInfo) ||
                this.hasMandateReference
            );
        },
        isMandateCheckboxChecked() {
            return !!this.organization?.mandateReference?.acceptedAt;
        },
        isSupplier() {
            return isSupplier(this.organization?.types);
        },
        isClient() {
            return isClient(this.organization?.types);
        },
        isCarrier() {
            return isCarrier(this.organization?.types);
        },
        isNotSupplierOrCarrier() {
            return !this.isSupplier && !this.isCarrier;
        },
        isSupplierOrCarrier() {
            return this.isSupplier || this.isCarrier;
        },
        isSchuettflixOrga() {
            return this.organization.types.includes('platform');
        },
        hasMandateReference() {
            return !!this.organization?.mandateReference?.id;
        },
        showDirectDebitDisabledHint() {
            return !this.isGlobalVariableDirectDebitActive && this.isDirectDebit && !this.isSchuettflixOrga;
        },
        showPaymentOptionChoice() {
            return (
                (this.isClient && this.isGlobalVariableDirectDebitActive) ||
                (!this.isGlobalVariableDirectDebitActive && this.isDirectDebit)
            );
        },
    },
    async created() {
        this.isLoading = true;
        const platformInfo = await Utils.getPlatformInfo();
        this.isGlobalVariableDirectDebitActive = platformInfo.directDebitActive;
        this.isLoading = false;
    },
    methods: {
        toggleCheckBox(doAcceptMandate) {
            const updatedOrganization = _cloneDeep(this.organization);
            if (doAcceptMandate) {
                updatedOrganization.mandateReference = this.acceptMandate();
            } else {
                delete updatedOrganization.mandateReference;
            }
            this.$emit('update-organization', updatedOrganization);
        },
        updatePaymentChoice(updatedOrganization) {
            if (this.isChangedPaymentInfo && 'mandateReference' in updatedOrganization.paymentInfo) {
                delete updatedOrganization.mandateReference;
            }
            this.$emit('update-organization', updatedOrganization);
        },
        updatePaymentDetails(updatedOrganization) {
            if (!_isEqual(updatedOrganization.paymentInfo, this.originalOrganization.paymentInfo)) {
                delete updatedOrganization.mandateReference;
            } else {
                updatedOrganization.mandateReference = this.acceptMandate();
            }
            this.$emit('update-organization', updatedOrganization);
        },
        acceptMandate() {
            return {
                acceptedAt: new Date().toISOString(),
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_mixins.scss';

.organization-form-payment {
    &__fieldset {
        & + & {
            margin-top: 60px;
        }
    }

    &__horizontal-separator {
        @include create-separator(2, $color-mediumGrey, (32px 0));
    }

    &__disabled-global-variable-hint {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px;

        background-color: #fffcbc;
        border: 1px solid #fad828;

        &__warningIcon {
            flex-shrink: 0;
            margin-right: 11px;
        }
    }

    &__payment-method-title {
        margin-bottom: 4px;
    }
}
</style>

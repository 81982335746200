<template>
    <div class="app__container" :class="{ 'app__container--nav-collapsed': navigationCollapsed }">
        <Toaster />
        <div class="app__content">
            <router-view class="app__route-content" />
        </div>
        <Navigation class="app__navigation" />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import Navigation from '@/components/Layout/Navigation.v2';
import Toaster from '@/components/Alert/Toaster';

export default {
    name: 'AppRenderer',

    components: {
        Navigation,
        Toaster,
    },

    computed: {
        ...mapState('session', ['navigationCollapsed']),
    },
};
</script>

<style lang="scss">
.app__container {
    display: grid;
    width: 100%;
    height: var(--view-height);

    grid-template-rows: 1fr auto;
    grid-template-columns: 100%;
    grid-template-areas:
        'content'
        'nav';

    @media screen and (min-width: $layout-desktop-min) {
        grid-template-columns: var(--nav-width, 240px) 1fr;
        grid-template-rows: 100%;

        grid-template-areas: 'nav content';
    }
}

.app__container--nav-collapsed {
    --nav-width: 88px;
}

.app__navigation {
    grid-area: nav;
    background-color: $color-white;
    box-shadow: 5px 0 12px 0 rgba(0, 0, 0, 0.1);

    @media screen and (min-width: $layout-desktop-min) {
        max-height: var(--view-height);
        height: var(--view-height);
        width: var(--nav-width);
        box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2);
    }
}

.app__content {
    grid-area: content;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
}

.app__route-content {
    flex-grow: 1;
    height: 100%;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-deprecated",class:{
        'card-deprecated--levitate': _vm.levitate,
        'card-deprecated--levitate-hover': _vm.levitateHover,
        'cursor-pointer': _vm.clickable,
        'card-deprecated--small': _vm.small,
        'card-deprecated--spaceless': _vm.spaceless,
        'card-deprecated--spaceless-x': _vm.spacelessX,
        'card-deprecated--spaceless-y': _vm.spacelessY,
        'card-deprecated--disabled': _vm.disabled,
        'card-deprecated--dark': _vm.dark,
        'card-deprecated--shadowless': _vm.shadowless,
        'card-deprecated--selected': _vm.selected,
    },on:{"click":function($event){!_vm.disabled && _vm.$emit('click', $event)}}},[_c('div',{staticClass:"card-deprecated__content",class:{
            'card-deprecated__content--no-padding': _vm.noPadding,
            'card-deprecated__content--flex': _vm.flex,
        }},[_vm._t("default")],2),(_vm.$slots.action)?_c('div',[_vm._t("action")],2):_vm._e()])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
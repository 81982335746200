<template>
    <HeaderBar class="checkout-header">
        <template #left>
            <slot name="left">
                <HeaderBarItem button @click="goBack">
                    <SfSysArrowLeftIcon size="xs" />
                </HeaderBarItem>
            </slot>
        </template>
        <template #headline>
            <slot name="headline">
                {{ headline || $t($route.meta.title) }}
            </slot>
        </template>
        <template v-if="subline || $route.meta.subtitle" #subline>
            <slot name="subline">
                {{ subline || $t($route.meta.subtitle) }}
            </slot>
        </template>
        <template #right>
            <CheckoutGuide v-if="$can('requestPhoneOrder')" />
            <slot name="right" />
        </template>
    </HeaderBar>
</template>

<script>
import CheckoutGuide from '@/pages/Checkout/components/CheckoutGuide';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';

import { SfSysArrowLeftIcon } from '@schuettflix/vue-components';
import { mapState } from 'vuex';

export default {
    name: 'CheckoutHeader',
    components: {
        CheckoutGuide,
        HeaderBar,
        HeaderBarItem,
        SfSysArrowLeftIcon,
    },
    props: {
        headline: {
            type: String,
            default: null,
        },
        subline: {
            type: String,
            default: null,
        },
    },
    computed: {
        ...mapState('basket', ['isSellerSelected']),
    },
    methods: {
        goBack() {
            const deliveryMethod = this.$store.state.basket.deliveryMethod;
            const planningMethod = this.$store.state.basket.planningMethod;

            let routeName = this.$route.meta.previous;

            if (deliveryMethod?.methodName === 'flix' || ['loading', 'unloading'].includes(planningMethod)) {
                routeName = this.$route.meta.previousDateSelect || this.$route.meta.previous;
            }

            if (deliveryMethod?.type === 'default' || planningMethod === 'default') {
                routeName = this.$route.meta.previousWindowSelect || this.$route.meta.previous;
            }

            if (this.isSellerSelected && this.$route.meta.previousWithSellerSelected) {
                routeName = this.$root.findRouteName(this.$route.meta.previousWithSellerSelected);
            }

            this.$router.push({ name: this.$root.findRouteName(routeName) }).catch(() => {});
        },
    },
};
</script>

<style lang="scss" scoped></style>

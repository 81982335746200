import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { createI18n } from 'vue-i18n-composable';
import VueI18nPhraseInContextEditor from 'vue-i18n-phrase-in-context-editor';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { dateTimeFormats, getNumberFormatsForCurrency, numberFormats } from './formats';
import { convertPhraseMessagesToVueI18nMessages } from './helpers';
import { defaultLocale, findClosestMatchingSupportedLocale } from './languages';
import german from './translations/de-DE.json';
export { availableLocales, availableLanguageCodes, defaultLocale, getLanguageFromLocale, findClosestMatchingSupportedLocale, } from './languages';
Vue.use(VueI18n);
const loadedLanguages = new Set([defaultLocale]);
const i18n = createI18n({
    locale: defaultLocale,
    fallbackLocale: defaultLocale,
    // initially we only load the default locale
    messages: {
        'de-DE': convertPhraseMessagesToVueI18nMessages(german),
    },
    dateTimeFormats,
    numberFormats,
});
i18next.use(initReactI18next).init({
    resources: {
        'de-DE': { translation: german },
    },
    lng: defaultLocale,
    fallbackLng: defaultLocale,
    pluralSeparator: '.',
    interpolation: {
        prefix: '{',
        suffix: '}',
        escapeValue: false,
    },
});
new VueI18nPhraseInContextEditor(i18n, {
    phraseEnabled: new URLSearchParams(window.location.search).has('t'),
    projectId: '099f46f3cfc8a558a67210ffa55b6224',
    autoLowercase: false,
});
function setI18nLanguage(lang) {
    i18n.locale = lang;
    document.querySelector('html')?.setAttribute('lang', lang);
    return lang;
}
export async function loadLanguageAsync(lang) {
    const locale = findClosestMatchingSupportedLocale(lang);
    if (loadedLanguages.has(locale)) {
        return;
    }
    const messages = await import(
    /* webpackChunkName: "lang-[request]" */
    `./translations/${locale}.json`);
    i18n.setLocaleMessage(locale, convertPhraseMessagesToVueI18nMessages(messages));
    i18next.addResourceBundle(locale, 'translation', messages, true, true);
    loadedLanguages.add(locale);
}
export async function setLanguageAsync(lang = defaultLocale) {
    const locale = findClosestMatchingSupportedLocale(lang);
    await loadLanguageAsync(locale);
    await i18next.changeLanguage(new URLSearchParams(window.location.search).has('t') ? 'cimode' : locale);
    return setI18nLanguage(locale);
}
export function setCurrency(currency) {
    const nextNumberFormats = getNumberFormatsForCurrency(currency, numberFormats);
    for (const locale in nextNumberFormats) {
        i18n.setNumberFormat(locale, nextNumberFormats[locale]);
    }
}
export default i18n;

// @preval

// Returns a pre-evaluated map of screen sizes taken from the tailwind config
// Example:
// {
//     sm: '375px',
//     md: '768px',
//     ...
// },
const tailwindConfig = require('../../tailwind.config');
const resolveConfig = require('tailwindcss/resolveConfig');
const _isString = require('lodash/isString');

const fullConfig = resolveConfig(tailwindConfig);

const filterForOnlyRealScreens = screens =>
    Object.fromEntries(Object.entries(screens).filter(([, value]) => _isString(value)));

const realScreens = filterForOnlyRealScreens(fullConfig.theme?.screens);

const realScreensAsNumbers = realScreens;
for (const [screen, width] of Object.entries(realScreens)) {
    realScreensAsNumbers[screen] = Number(width.replace('px', ''));
}

module.exports = { realScreens, realScreensAsNumbers };

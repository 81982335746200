import React from 'react';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { FlyoutPage } from '@/constructionProjects/components/FlyoutPage';
import { useQuery } from '@tanstack/react-query';
import { useOrderScreenName } from '@/pages/Checkout/analytics/react/useOrderScreenName';
import { useTranslation } from 'react-i18next';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { findRouteName } from '@/pages/routerUtils';
import { useVuexState } from '@/reactBridge/useVuex';
import { getMasterProductsByCategory } from './queries';
import { LoadingSpinner } from '@schuettflix/react-components';
import { useMoneyFormatter } from '@/utils/useMoneyFormatter';
import store from '@/store';
import { useAbility } from '@/reactBridge/useAbility';
const CategoryListCard = ({ title, optionsCount, price, onClick }) => {
    const { t } = useTranslation();
    const { formatPerTon } = useMoneyFormatter();
    const formattedPrice = formatPerTon(price);
    const hasPrice = useAbility('seePrices') && price !== undefined && price !== null && !isNaN(price);
    return (<section className="font-copy-md mb-4 flex w-full cursor-pointer justify-between gap-4 rounded border bg-surface p-4 shadow-low hover:shadow-high" onClick={onClick}>
            <div>
                <p className="font-copy-md-strong pb-1">{title}</p>
                <p className="text-subdued">
                    {t('pages.checkout.categorySelection.categoryCard.optionsCount', {
            count: optionsCount,
        })}
                </p>
            </div>
            {hasPrice && (<div>
                    <p className="text-subdued">{t('pages.checkout.categorySelection.fromPrice')}</p>
                    <p>{formattedPrice}</p>
                </div>)}
        </section>);
};
const _CategoryDetailsPage = () => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const { categoryId, categoryName, imageUrl, searchTerm, fromCategoriesList } = useVuexState(store, state => state.basket.categoryInfo);
    const orderType = useVuexState(store, state => state.basket.type);
    const locationId = useVuexState(store, state => state.basket.constructionSite.constructionProjectLocationId);
    const { data: category, isLoading } = useQuery(getMasterProductsByCategory(categoryId, orderType, locationId, searchTerm));
    const hasProducts = category && category.masterProducts?.length > 0;
    const totalOptionsAvailable = category?.masterProducts?.reduce((acc, product) => {
        return acc + product.availableOptionsCount;
    }, 0);
    const handleBackButton = () => {
        if (fromCategoriesList) {
            return vueRouter.push({
                name: findRouteName(vueRouter.currentRoute?.meta?.previousCategoriesList),
            });
        }
        return vueRouter.push({
            name: findRouteName(vueRouter.currentRoute?.meta?.previous),
        });
    };
    const handleProductSelection = (product) => {
        store.commit('basket/setProductDefinition', {
            masterProductId: product.id,
            masterProductName: product.name,
        });
        vueRouter.push({
            name: findRouteName(vueRouter.currentRoute?.meta?.next),
        });
    };
    const renderBody = () => {
        if (isLoading && (!categoryName || !imageUrl)) {
            return (<div>
                    <LoadingSpinner className="mx-auto mt-8"/>
                </div>);
        }
        return (<main className="flex h-full flex-col items-center px-4 py-6">
                <img src={imageUrl || category?.image.small} alt={category?.name || categoryName} className="aspect-square h-[100px] rounded-full shadow-high"/>
                <p className="font-headline-xl-strong mt-4">{category?.name || categoryName}</p>

                {isLoading && <LoadingSpinner className="mx-auto mt-8"/>}

                {!isLoading && (<p className="font-copy-lg mt-2 text-center">
                        {t('pages.checkout.categorySelection.subHeader', {
                    count: totalOptionsAvailable,
                    category: category?.name,
                })}
                    </p>)}

                {!isLoading && hasProducts && (<article className="mt-8 w-full" data-test="category-list">
                        {category.masterProducts.map(product => {
                    return (<CategoryListCard key={product.id} title={product.name} optionsCount={product.availableOptionsCount} price={product.cheapestPrice} onClick={() => handleProductSelection(product)}/>);
                })}
                    </article>)}
            </main>);
    };
    return (<FlyoutPage onCloseAnimationEnd={handleBackButton} headerTitle={t('pages.checkout.categorySelection.title')} body={renderBody()} screenName={useOrderScreenName('categorydetails')}/>);
};
export const CategoryDetailsPage = asVueComponent(_CategoryDetailsPage);

export const defaultState = {
    /**
     * weather categories API is loading
     * @type {boolean}
     */
    isLoading: false,

    /**
     * unique factory id
     * @type {integer}
     */
    factoryId: null,

    /**
     * waste categories
     * @type {array}
     */
    categories: [],

    /**
     * waste products
     * @type {array}
     */
    products: [],

    /**
     * waste response messages
     * @type {array}
     */
    messages: [],
};
const state = {
    ...defaultState,
};

export default state;

<template>
    <div
        :class="{
            'select-box--invalid': error || hasError,
            'select-box--focused': false && isFocused,
            'select-box--filled': hasValue,
            'select-box--dark': dark,
            'select-box--medium': medium,
            'select-box--small': small,
            'select-box--tiny': tiny,
        }"
        class="select-box"
    >
        <div class="select-box__input-group">
            <select
                :id="eid"
                :class="{
                    'select-box__input--filled': hasValue,
                    'select-box__input--iconized': $slots.icon,
                    'select-box__input--readonly': readonly,
                    'select-box__input--without-label': !label,
                }"
                :value="processedValue"
                :disabled="disabled || readonly"
                class="select-box__input"
                :data-test="`${dataTest}-select`"
                @change="update"
                @blur="blured"
                @focus="focused"
            >
                <slot />
            </select>
            <label v-if="label" :for="eid" class="select-box__label">
                {{ label }}
            </label>
            <div v-if="$slots.icon" class="select-box__icon">
                <slot name="icon" />
            </div>
            <ArrowDownIcon
                :class="{ 'select-box__arrow--readonly': readonly }"
                class="select-box__arrow select-box__arrow--down"
            />
            <Button
                v-if="hasValue && !preventReset && !readonly"
                class="select-box__reset"
                tabindex="-1"
                @click="$emit('input', null)"
            >
                <ResetIcon width="13" />
            </Button>
        </div>
        <ErrorMessage v-if="error" :message="error" :dark="dark" />
    </div>
</template>

<script>
import _get from 'lodash/get';
import _isBoolean from 'lodash/isBoolean';

import Button from '@/components/Button/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';

import ResetIcon from '@/assets/icons/micro/error.svg';
import ArrowDownIcon from '@/assets/icons/regular/arrow-stripeless--down.svg';

export default {
    name: 'SelectBox',
    components: {
        Button,
        ErrorMessage,

        ResetIcon,
        ArrowDownIcon,
    },
    props: {
        value: {
            type: [String, Number, Boolean],
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        dataTest: {
            type: String,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        preventReset: {
            type: Boolean,
            default: true,
        },
        focusInitially: {
            type: Boolean,
            default: false,
        },
        medium: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        tiny: {
            type: Boolean,
            default: false,
        },
        castBoolean: {
            type: Boolean,
            default: false,
        },
        noFallback: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            eid: `el${this._uid}`,
            isFocused: false,
        };
    },
    computed: {
        processedValue() {
            if (_isBoolean(this.value)) {
                return this.value ? '1' : '0';
            }

            return this.value;
        },
        hasValue() {
            if (this.value === null && this.noFallback) return false;
            if (this.value !== null && this.value !== '') return true;

            const childs = _get(this, '$slots.default', []);
            let hasValue = false;

            childs.forEach(vNode => {
                const value = _get(vNode, 'domProps.value', _get(vNode, 'data.attrs.value', null));
                if ([null, ''].includes(value)) {
                    hasValue = true;
                }
            });

            return hasValue;
        },
    },
    mounted() {
        if (this.focusInitially && !this.$root.isIOS) {
            setTimeout(() => {
                const $el = document.getElementById(this.eid);
                $el && $el.focus();
            }, 300);
        }
    },
    methods: {
        focused(e) {
            this.isFocused = true;
            this.$emit('focus', e);
        },
        blured(e) {
            this.isFocused = false;
            this.$emit('blur', e);
        },
        update(e) {
            let value = e.target.value !== '' ? e.target.value : null;

            if (this.castBoolean) {
                switch (value) {
                    case '1':
                    case 1:
                        value = true;
                        break;
                    case '0':
                    case 0:
                        value = false;
                        break;
                    default:
                        value = null;
                }
            }

            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss">
.select-box {
    display: block;
    position: relative;
}

.select-box__textarea {
    display: block;
    width: 100%;
    padding: 15px 15px 13px;
    font-size: 14px;
    line-height: $line-height-base;
    font-family: $font-family;
    outline: none;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-appearance: none;
    transition: border-color 0.2s ease;
    resize: vertical;

    @media only screen and (min-width: $layout-desktop-min) {
        padding-left: 25px;
        padding-right: 25px;
    }

    .select-box--focused & {
        border-color: rgba(0, 0, 0, 1);
    }

    .select-box--invalid & {
        border-color: $color-red;
    }
}

.select-box__input-group {
    display: flex;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;
    overflow: hidden;
    height: 70px;
    transition: border-color 0.2s ease;
    isolation: isolate;

    .select-box--dark & {
        border-color: transparent;
    }

    .select-box--focused & {
        border-color: rgba(0, 0, 0, 1);
    }

    .select-box--invalid & {
        border-color: $color-red;
    }

    .select-box--small & {
        height: 50px;
    }

    .select-box--tiny & {
        height: 40px;
    }
}

.select-box__slot--action {
    padding-top: 25px;
    padding-right: 15px;
    transition: padding-top 0.2s ease;

    > .button {
        font-size: 11px;
    }

    > * {
        font-weight: $font-weight-regular;
    }
}

.select-box__input {
    display: block;
    width: 100%;
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 11px 40px 6px 15px;
    font-size: 14px;
    line-height: $line-height-base;
    font-family: $font-family;
    outline: none;
    border-radius: 0;
    transition: padding 0.2s ease;
    font-family: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:invalid {
        box-shadow: none;
    }
}

.select-box__input--readonly {
    color: $color-black;
}

.select-box__input--iconized {
    padding-left: 40px;

    & + .select-box__label {
        left: 40px;
    }
}

.select-box--focused,
.select-box--filled {
    .select-box__label {
        opacity: 0.7;
        font-size: 10px;
        top: 20px;
        left: 15px;
    }

    .select-box__input {
        padding-top: 27px;

        &.select-box__input--without-label {
            padding-top: inherit;
            padding-bottom: inherit;
        }
    }

    .select-box__icon {
        top: 37px;
    }

    &.select-box--small {
        .select-box__label {
            top: 13px;
        }

        .select-box__icon,
        .select-box__reset {
            top: 27px;
        }
    }

    &.select-box--tiny {
        .select-box__label {
            top: 8px;
        }

        .select-box__icon,
        .select-box__reset {
            top: 22px;
        }
    }
}

.select-box__input--without-label {
    padding: 10px 40px 10px 15px;

    .select-box--tiny & {
        padding-top: 8px;
        padding-bottom: 6px;
    }
}

.select-box__input:invalid {
    background-color: red;
}

.select-box__label {
    font-size: 14px;
    line-height: 1;
    position: absolute;
    color: inherit;
    top: 30px;
    left: 15px;
    transition: all 0.2s ease;
}

.select-box__arrow {
    width: 14px;
    height: 14px;
    pointer-events: none;
    position: absolute;
    top: 37px;
    transform: translateY(-50%);
    right: 17px;
    z-index: 11;
    line-height: 1;
    position: absolute;
    transition: top 0.2s ease;

    .select-box--small & {
        top: 28px;
    }

    .select-box--tiny & {
        top: 22px;
    }
}

.select-box__arrow--readonly {
    opacity: 0.3;
}

.select-box__icon,
.select-box__reset {
    line-height: 1;
    position: absolute;
    top: 30px;
    transition: top 0.2s ease;
}

.select-box__icon {
    left: 15px;

    svg {
        width: 13px;
        height: 13px;
    }
}

.select-box__reset {
    right: 17px;
    opacity: 0;
    transition: opacity 0.2s ease;
    background-color: #fff;
    outline: 3px solid #fff;
    z-index: 12;

    .select-box:hover &,
    .select-box--focused & {
        opacity: 1;
    }
}

.select-box--invalid {
    .select-box__textarea,
    .select-box__input {
        color: $color-red;
        opacity: 1 !important;
        border-color: $color-red;
    }

    .select-box__slot--action {
        border-color: $color-red;
    }

    .select-box__input-group {
        svg {
            .f-base {
                fill: $color-red;
            }

            .s-base {
                stroke: $color-red;
            }
        }
    }
}

.select-box--dark {
    .select-box__textarea,
    .select-box__input-group {
        box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
    }
}

.select-box--medium {
    .select-box__input-group {
        height: 45px;

        .select-box__input--filled + .select-box__label {
            display: none;
        }
    }
    .select-box__input {
        padding: 0px 10px;
        line-height: 1;
    }
    .select-box__label {
        top: 16px;
    }
    .select-box__arrow {
        top: 23px;
    }

    &.select-box--focused {
        .select-box__label {
            opacity: 1;
            font-size: 14px;
            top: 16px;
        }
    }
}
</style>

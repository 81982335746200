import AbstractResource from './AbstractResource';
import { ensurePHPTimestamp } from '../utils/date';

class Planning extends AbstractResource {
    /**
     * Get available times for planning
     * @param {{
     *  transportType:string,
     *  zip:string,
     *  country?:string|string[],
     *  from?:string,
     *  state?:string|string[],
     *  projectId?:number|null
     * }} options
     * @return {Promise<*>}
     */
    async getTimes({ transportType, zip, country, from = null, state = null, projectId = null }) {
        const params = {
            transportType,
            zip,
            state,
            country,
            projectId,
        };

        if (from !== null) {
            params.from = ensurePHPTimestamp(from);
        }

        try {
            const response = await this.apiService.get(`${this.resourcePath}/times`, {
                params,
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new Planning('/planning');

import clsx from 'clsx';
import { Fragment, useRef, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SfSysPlusIcon } from '../icons/SfSysPlusIcon';
import FilePreview from './FilePreview';
const FormDropZone = ({ control, name, className, multipleUpload = false, maximumFileSizeInMb = 20, translations, errorMessage, disabled = false, defaultValue, }) => {
    const { field, fieldState } = useController({ control, name, defaultValue });
    const [active, setActive] = useState(false);
    const [errors, setErrors] = useState({
        maxDataSize: false,
        dataTypeNotSupported: false,
        multipleFilesNotSupported: false,
    });
    const fileUploader = useRef();
    const acceptedFileTypes = ['application/pdf'];
    const acceptedFileTypesAsString = acceptedFileTypes.join(', ');
    const { t } = useTranslation();
    const handleOnChange = (e) => {
        checkAndSetFile(e.target.files);
    };
    const onDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (disabled)
            return;
        setActive(true);
    };
    const onDragEnter = (e) => {
        e.stopPropagation();
        if (disabled)
            return;
        setActive(true);
    };
    const onDragLeave = (e) => {
        e.stopPropagation();
        if (disabled)
            return;
        setActive(false);
    };
    const onDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (disabled)
            return;
        setActive(false);
        const files = e.dataTransfer?.files;
        checkAndSetFile(files);
    };
    const checkAndSetFile = (files) => {
        if (!files?.length)
            return;
        const maxFileSize = maximumFileSizeInMb * 1024 * 1024;
        const fileArray = [...files];
        const errors = {
            dataTypeNotSupported: fileArray.some(({ type }) => !acceptedFileTypes.includes(type)),
            maxDataSize: fileArray.some(({ size }) => size > maxFileSize),
            multipleFilesNotSupported: !multipleUpload && files.length > 1,
        };
        setErrors(errors);
        if (Object.values(errors).some(v => v)) {
            field.onChange('');
            return;
        }
        field.onChange(multipleUpload ? files : files[0]);
    };
    const removeFile = () => {
        setErrors(Object.fromEntries(Object.entries(errors).map(([key]) => [key, false])));
        field.onChange('');
        fileUploader.current.value = '';
    };
    return (<div>
            {field.value ? (<FilePreview disabled={disabled} file={field.value} removeAction={removeFile} editAction={() => fileUploader?.current?.click()}/>) : (<div>
                    <div onClick={() => !disabled && fileUploader?.current?.click()} onDragEnter={onDragEnter} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop} className={clsx('flex min-h-12 w-full items-center justify-center gap-x-2 gap-y-2 bg-surface p-6 sm-max:card md:flex-col md:border md:border-dashed', (errors.dataTypeNotSupported ||
                errors.multipleFilesNotSupported ||
                errors.maxDataSize ||
                fieldState.error) &&
                'md:border-critical', !disabled && 'cursor-pointer sm-max:card--hovered sm-max:card--focussed', !active && !disabled && 'bg-surface hover:bg-surface-hovered', active && !disabled && 'bg-surface-hovered', disabled && 'cursor-not-allowed bg-surface-disabled', className)}>
                        <SfSysPlusIcon size="xs" disabled={disabled}/>
                        <div className={clsx('font-copy-md-strong', disabled ? 'text-disabled' : 'text-interactive-hovered')}>
                            {active ? (<span>{translations.drop}</span>) : (<Fragment>
                                    <span className="hidden md:block">{translations.drag.desktop}</span>
                                    <span className="block md:hidden">{translations.drag.mobile}</span>
                                </Fragment>)}
                        </div>
                        <div className="font-copy-sm hidden text-disabled md:block">
                            {t('pages.certificateManagement.add.dropZone.maximumFileSize', {
                size: `${maximumFileSizeInMb}`,
            })}
                        </div>
                    </div>
                    <div className="font-copy-sm mt-2 text-critical">
                        {errorMessage && <p>{errorMessage}</p>}
                        {errors.dataTypeNotSupported && (<p>
                                {t('pages.certificateManagement.add.dropzone.dataTypeNotSupported', {
                    fileTypeString: acceptedFileTypesAsString,
                })}
                            </p>)}
                        {errors.multipleFilesNotSupported && (<p>{t('pages.certificateManagement.add.dropzone.multipleFilesNotSupported')}</p>)}
                        {errors.maxDataSize && (<p>
                                {t('pages.certificateManagement.add.dropzone.maximumFileSizeExceeded', {
                    size: maximumFileSizeInMb,
                })}
                            </p>)}
                    </div>
                </div>)}
            <input name={name} ref={fileUploader} type="file" className="hidden" accept={acceptedFileTypesAsString} multiple={multipleUpload} onChange={handleOnChange}/>
        </div>);
};
export default FormDropZone;

<template>
    <FormWrapper v-if="!!value" data-test="FactoryContact">
        <template #title>
            {{ $t('components.factoryManagement.factoryContact.title') }}

            <FormCopyOrganizationData
                v-if="!isReadOnly"
                place-right
                :field-names="fields"
                object-to-replace="contact"
                data-test="form-copy-organization-data-contact"
                @datatransfer="updateDataFromOrganization($event)"
            >
                <Button arrow-right>
                    <span class="text-sm md:text-md">{{
                        $t('components.factoryManagement.factoryView.transferFromMasterData')
                    }}</span>
                </Button>
            </FormCopyOrganizationData>
        </template>

        <ComponentWrapper>
            <template #componentText>
                <Words>{{ $t('components.factoryManagement.factoryContact.content') }}</Words>
            </template>
            <template #componentTitle>
                <Words>{{ $t('components.factoryManagement.factoryContact.telephoneFaxTitle') }}</Words>
            </template>
            <template #component>
                <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <PhoneFieldset
                        :value="form.contact.phone"
                        joined-style
                        :readonly="isReadOnly"
                        :label="$t('components.factoryManagement.factoryContact.phone')"
                        :prefix-error="
                            firstError['contact.phone.countryCode'] || getError('form.phone.countryCode', submitted)
                        "
                        :error="firstError['contact.phone.number'] || getError('form.phone.number', submitted)"
                        data-test="phone-number"
                        @input="update('contact.phone', $event)"
                    />
                    <PhoneFieldset
                        :value="form.contact.mobile"
                        joined-style
                        :readonly="isReadOnly"
                        :label="$t('components.factoryManagement.factoryContact.mobile')"
                        :prefix-error="
                            firstError['contact.mobile.countryCode'] || getError('form.mobile.countryCode', submitted)
                        "
                        :error="firstError['contact.mobile.number'] || getError('form.mobile.number', submitted)"
                        data-test="cell-phone-number"
                        @input="update('contact.mobile', $event)"
                    />
                    <PhoneFieldset
                        :value="form.contact.fax"
                        joined-style
                        :readonly="isReadOnly"
                        :label="$t('components.factoryManagement.factoryContact.fax')"
                        :prefix-error="
                            firstError['contact.fax.countryCode'] || getError('form.fax.countryCode', submitted)
                        "
                        :error="firstError['contact.fax.number'] || getError('form.fax.number', submitted)"
                        data-test="fax-number"
                        @input="update('contact.fax', $event)"
                    />
                </div>
            </template>
        </ComponentWrapper>

        <componentWrapper>
            <template #componentTitle>
                <Words bold>{{ $t('components.factoryManagement.factoryContact.emailWebsiteTitle') }}</Words>
            </template>
            <template #component>
                <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <TextField
                        :value="form.contact.email"
                        :readonly="isReadOnly"
                        :label="$t('components.factoryManagement.factoryContact.email')"
                        :has-error="!!firstError['contact.email'] || hasErrors('form.email', submitted)"
                        :error="firstError['contact.email'] || getError('form.email', submitted)"
                        data-test="email-address"
                        @input="update('contact.email', $event)"
                    />
                    <TextField
                        :value="form.contact.website"
                        :readonly="isReadOnly"
                        :label="$t('components.factoryManagement.factoryContact.website')"
                        :has-error="!!firstError['contact.website'] || hasErrors('form.website', submitted)"
                        :error="firstError['contact.website'] || getError('form.website', submitted)"
                        data-test="website-address"
                        @input="update('contact.website', $event)"
                    />
                </div>
            </template>
        </componentWrapper>
    </FormWrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';

import { isRequired, isPhoneNumber, minLength, maxLength, isEmail } from '@/services/validation/rules';

import validate from '@/services/validation/mixin';
import sectionResetMixin from '@/plugins/mixins/factoryForm/sectionResetMixin';
import sectionMixin from '@/plugins/mixins/factoryForm/sectionMixin';
import { findCountryCodeByLocale } from '@/config/countryCodes';

import Button from '@/components/Button/Button';
import ComponentWrapper from '@/_components/ComponentWrapper/ComponentWrapper';
import FormCopyOrganizationData from '@/_components/FormCopyOrganizationData/FormCopyOrganizationData';
import FormWrapper from '@/_components/FormWrapper/FormWrapper';
import PhoneFieldset from '@/components/Form/PhoneFieldset';
import TextField from '@/components/Form/TextField.v2';
import Words from '@/components/Typography/Words';

export default {
    name: 'FactoryContact',
    components: {
        Button,
        ComponentWrapper,
        FormCopyOrganizationData,
        FormWrapper,
        PhoneFieldset,
        TextField,
        Words,
    },
    mixins: [validate, sectionResetMixin, sectionMixin],
    props: {
        value: {
            type: [Object, null],
            default: null,
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('user', {
            organization: 'organization',
            market: 'market',
        }),
        fields() {
            return ['phone', 'mobile', 'fax', 'email', 'website'];
        },
        validationRules() {
            let rules = {
                // Contact validation
                'form.contact.phone.countryCode': [isRequired()],
                'form.contact.phone.number': [isRequired(), isPhoneNumber(), minLength(3), maxLength(30)],
                'form.contact.email': [isRequired(), isEmail()],
            };

            if (_get(this.value, 'contact.fax.number', false)) {
                rules = {
                    ...rules,
                    'form.contact.fax.countryCode': [isRequired()],
                    'form.contact.fax.number': [isPhoneNumber(), minLength(3), maxLength(30)],
                };
            }

            if (_get(this.value, 'contact.mobile.number', false)) {
                rules = {
                    ...rules,
                    'form.contact.mobile.countryCode': [isRequired()],
                    'form.contact.mobile.number': [isPhoneNumber(), minLength(3), maxLength(30)],
                };
            }

            return rules;
        },
    },
    watch: {
        value: {
            deep: true,
            handler(data) {
                this.sectionDataBackupOpenStatusReset(data.open);
            },
        },
        form: {
            deep: true,
            immediate: true,
            handler(sectionData) {
                if (!this.sectionDataBackup) {
                    return;
                }
                this.$emit('confirmation-dialog', null);
                this.$emit('dirty-check', this.checkDirty(sectionData, this.sectionDataBackup));
                this.$emit('valid-check', this.isValid(false));
            },
        },
    },
    mounted() {
        this.setDefaultCountryCodes();
        this.$emit('dirty-check', false);
    },
    methods: {
        updateDataFromOrganization(data) {
            this.$emit('datatransfer', {
                contact: {
                    ..._cloneDeep(this.form.contact),
                    ...data.contact,
                },
            });
        },
        setDefaultCountryCodes() {
            const fields = ['phone', 'mobile', 'fax'];

            fields.forEach(field => {
                if (field in this.form.contact === false || this.form.contact[field].countryCode === '') {
                    this.form.contact = {
                        ...this.form.contact,
                        [field]: {
                            countryCode: this.market?.code
                                ? findCountryCodeByLocale(this.market?.code)
                                : this.organization?.[field]?.countryCode || '+49',
                            number: '',
                        },
                    };
                }
            });

            this.$set(this, 'sectionDataBackup', _cloneDeep(this.form));
        },
    },
};
</script>

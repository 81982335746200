<template>
    <ModalBox
        ref="deletedLastProductVariantModal"
        class="deleted-last-product-variant-modal"
        optional-dialog
        :border="false"
        has-large-headline
        :headline="
            $t('pages.products.supplierProductsList.wasteProductDetails.deletedLastProductVariantModal.headline')
        "
    >
        <Words block class="intro">
            {{ $t('pages.products.supplierProductsList.wasteProductDetails.deletedLastProductVariantModal.intro1') }}
        </Words>

        <Words block class="intro">
            {{ $t('pages.products.supplierProductsList.wasteProductDetails.deletedLastProductVariantModal.intro2') }}
        </Words>

        <ButtonGroup slot="actions">
            <Button primary arrow-right @click="closeDeletedLastWasteVariantProduct()">
                {{
                    $t('pages.products.supplierProductsList.wasteProductDetails.deletedLastProductVariantModal.confirm')
                }}
            </Button>
        </ButtonGroup>
    </ModalBox>
</template>

<script>
import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ModalBox from '@/components/Modal/ModalBox';
import Words from '@/components/Typography/Words';

export default {
    name: 'SupplierWasteProductDetailsDeletedLastProductVariantModal',
    components: {
        Button,
        ButtonGroup,
        ModalBox,
        Words,
    },
    methods: {
        closeDeletedLastWasteVariantProduct() {
            this.$refs.deletedLastProductVariantModal.$emit('close');

            this.$emit('accept');
        },
    },
};
</script>

<style lang="scss" scoped src="./SupplierWasteProductDetailsDeletedLastProductVariantModal.scss" />

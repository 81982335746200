const ENV_STRING = {
    int: 'int.',
    ppe: 'ppe.',
    review: 'review.',
    feature1: 'feature1.',
    feature2: 'feature2.',
};
const ENV_CLASS = {
    int: 'js--env-int',
    ppe: 'js--env-ppe',
    review: 'js--env-review',
};

export default function environmentHint(baseUrl) {
    const { classList } = document.body;

    classList.remove(ENV_CLASS.int, ENV_CLASS.ppe, ENV_CLASS.review);
    if (localStorage.getItem('disable-env-hint')) {
        return;
    }

    if (baseUrl === '/api') {
        baseUrl = window.location.host;
    }

    if (baseUrl.includes(ENV_STRING.int)) {
        classList.add(ENV_CLASS.int);
    } else if (baseUrl.includes(ENV_STRING.ppe)) {
        classList.add(ENV_CLASS.ppe);
    } else if ([ENV_STRING.review, ENV_STRING.feature1, ENV_STRING.feature2].some(el => baseUrl.includes(el))) {
        classList.add(ENV_CLASS.review);
    }
    return;
}

import { computed, getCurrentInstance } from 'vue';
import { useRoute } from 'vue-router/composables';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { BrowserService } from '@/services/Browser/BrowserService';
export function useCurrentInstance() {
    const vm = getCurrentInstance();
    if (!vm) {
        throw new Error('getCurrentInstance() must be called within setup()');
    }
    return vm;
}
export function useRoot() {
    const vm = useCurrentInstance();
    return vm.proxy.$root;
}
export function useCan() {
    const { useGetters } = createNamespacedHelpers('abilities');
    const { can } = useGetters(['can']);
    return can;
}
export function useGetCountedAbilityCheck() {
    const can = useCan();
    function getCountedAbilityCheck({ abilityName, context = [], falsy = false, }) {
        if (!context || context.length === 0) {
            return 0;
        }
        if (falsy) {
            return context.filter(item => !can.value(abilityName, item)).length;
        }
        return context.filter(item => can.value(abilityName, item)).length;
    }
    return getCountedAbilityCheck;
}
export function useRouteContext() {
    const route = useRoute();
    const routeContext = computed(() => route.matched
        .map(r => r.meta.context)
        .filter(r => !!r)
        .slice(-1)[0]);
    const inRouteContext = (...contexts) => {
        return contexts.includes(routeContext.value);
    };
    return { routeContext, inRouteContext };
}
// Helper to open a webpage in the native app (e.g. SafariViewController)
export function useInAppBrowser() {
    return {
        openInAppBrowser: BrowserService.open,
    };
}

<template>
    <button
        type="button"
        class="context-menu-entry"
        :class="{ 'context-menu-entry--disabled': disabled }"
        @click="emitAction"
    >
        <div class="context-menu-entry__content">
            <slot name="left" />
            <Words small><slot /></Words>
            <slot name="right" />
        </div>
    </button>
</template>

<script>
import Words from '@/components/Typography/Words';

export default {
    name: 'ContextMenuEntry',
    components: {
        Words,
    },
    props: {
        action: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        emitAction() {
            if (this.disabled) return;
            this.$emit('click', this.action);
        },
    },
};
</script>

<style lang="scss">
.context-menu-entry {
    position: relative;
    display: block;
    background-color: transparent !important;
    font-weight: $font-weight-regular;
    border: 0;
    outline: none;
    padding: 0;
    margin: 0;
    font-family: $font-family;
    font-size: $font-size-base;
    line-height: $line-height-base;
    -webkit-appearance: none;
    text-decoration: none;
    cursor: pointer;
    padding: 12px 20px;
    white-space: nowrap;
    width: 100%;
    text-align: left;

    @media screen and (max-width: $layout-desktop-max) {
        padding: 28px 20px;
        background-color: $color-red !important;
        color: $color-white;
        transition: opacity 0.2s ease-in-out;

        svg {
            .f-base,
            .f-highlight {
                fill: $color-white;
            }

            .s-base,
            .s-highlight {
                stroke: $color-white;
            }
        }
    }
}

.context-menu-entry--disabled {
    opacity: 0.5;

    @media screen and (max-width: $layout-desktop-max) {
        background-color: $color-grey !important;
        color: $color-white;
    }
}

.context-menu-entry + .context-menu-entry {
    margin-top: 1px;
}

.context-menu-entry:hover {
    background-color: $color-littleDarkerThanLightMediumGrey !important;

    @media screen and (max-width: $layout-desktop-max) {
        background-color: $color-red !important;
        opacity: 0.5;
    }

    &.context-menu-entry--disabled {
        background: transparent !important;
        cursor: default;

        @media screen and (max-width: $layout-desktop-max) {
            background-color: $color-grey !important;
            opacity: 0.5;
        }
    }
}

.context-menu-entry__content {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 10px;
    align-items: center;
}
</style>

<template>
    <div class="app-loader">
        <svg
            class="app-loader__logo"
            width="300"
            height="42"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 391.35756 47.5893"
            @click="$emit('logoClick')"
        >
            <path
                fill="#f00"
                d="M72.22955,45.64041A30.66536,30.66536,0,0,1,61.04076,47.5893c-13.326,0-23.2576-7.29157-23.2576-20.30354A27.63577,27.63577,0,0,1,45.829,7.92538,26.32078,26.32078,0,0,1,64.56083.697a30.211,30.211,0,0,1,13.892,3.26864L76.37821,15.84554A25.93032,25.93032,0,0,0,64.058,12.19975a12.93882,12.93882,0,0,0-9.36618,3.64579A14.68762,14.68762,0,0,0,50.732,25.966c0,6.663,4.71409,10.11991,11.5028,10.11991a28.52173,28.52173,0,0,0,12.19509-2.95406Z"
            />
            <path
                fill="#f00"
                d="M85.8684,1.38811H98.81722L95.92574,17.79443h15.58887L114.4061,1.38811h12.94882l-8.04587,45.50946H106.36023l3.20549-18.16578H93.97684L90.77135,46.89757H77.82253Z"
            />
            <path
                fill="#f00"
                d="M171.35465,25.90288c-1.32031,7.48043-3.14349,12.00623-6.349,15.33744-4.02294,4.14865-9.9942,6.349-16.7829,6.349-10.87479,0-18.9838-5.65725-18.9838-15.90345a42.37824,42.37824,0,0,1,.62915-6.78871l4.14809-23.509H146.9656l-4.14922,23.57217a27.00883,27.00883,0,0,0-.43973,4.33695c0,4.5258,2.2629,7.04014,6.977,7.04014a8.22542,8.22542,0,0,0,6.53727-2.70263c1.63489-1.82318,2.38919-4.21179,3.08034-8.109l4.27437-24.13762h12.446Z"
            />
            <path
                fill="#f00"
                d="M192.0962,12.32547H177.82764L179.776,1.38811h35.66146L213.4891,12.32547h-8.4435l-6.09755,34.5721H185.99923Z"
            />
            <path
                fill="#f00"
                d="M224.7127,12.32547h-8.4435l1.94833-10.93736H253.879l-1.94833,10.93736H237.6621l-6.09755,34.5721H218.61572Z"
            />
            <path
                fill="#f00"
                d="M258.66723,1.38811h29.66915l-1.94833,10.93736H269.66716l-1.194,6.663H282.7417l-1.82261,10.30877H266.65l-3.07978,17.60034H250.62136Z"
            />
            <path fill="#f00" d="M294.24393,1.38811h12.94882l-6.09754,34.57211h18.35464l-1.94832,10.93735H286.19806Z" />
            <path fill="#f00" d="M330.00892,1.38811h12.94883l-8.04588,45.50946H321.96305Z" />
            <path
                fill="#f00"
                d="M376.27155,1.38811h15.086L373.00291,23.5774l11.18822,23.32017H369.35656l-2.32548-5.46839c-1.19459-2.89149-2.45176-5.90869-3.52007-8.7376h-.12572c-2.13718,2.89149-4.33751,5.783-6.60041,8.7376l-4.21123,5.46839H337.36193l19.423-23.32017L346.351,1.38811h14.64572l2.01146,4.77724c1.13146,2.70319,2.32548,5.65725,3.33121,8.2973h.12629c1.94832-2.64005,4.14808-5.59411,6.15955-8.23416Z"
            />
            <path
                fill="#f00"
                d="M148.99479,6.91965a5.59364,5.59364,0,0,1,5.59468-5.53154,4.96408,4.96408,0,0,1,5.02867,5.02867,5.594,5.594,0,0,1-5.59468,5.53154A4.926,4.926,0,0,1,148.99479,6.91965Z"
            />
            <path
                fill="#f00"
                d="M146.23492,22.42868a5.59363,5.59363,0,0,1,5.59468-5.53154,4.96409,4.96409,0,0,1,5.02867,5.02867,5.594,5.594,0,0,1-5.59468,5.53154A4.926,4.926,0,0,1,146.23492,22.42868Z"
            />
            <path
                fill="#f00"
                d="M2.20033,29.85744c3.7715,3.26864,9.93162,6.28584,14.45742,6.28584a4.5908,4.5908,0,0,0,2.95406-.81687,2.595,2.595,0,0,0,1.00574-2.13719c0-2.57748-3.07978-3.26863-7.98273-5.90869-5.15439-2.76576-9.05161-5.783-9.05161-11.94309A14.43547,14.43547,0,0,1,9.303,3.83408,19.559,19.559,0,0,1,21.435,0,28.35174,28.35174,0,0,1,35.7667,4.02294L33.69209,15.65145c-4.02294-2.64005-9.55447-4.903-13.13712-4.903a4.9454,4.9454,0,0,0-2.64005.62858,2.52976,2.52976,0,0,0-1.194,2.2629c0,2.2629,2.13661,2.76577,7.29157,5.40582,5.71982,2.95463,9.74276,6.09755,9.74276,12.5091a14.35244,14.35244,0,0,1-5.2801,11.18879,20.04978,20.04978,0,0,1-12.88625,4.14866A29.29459,29.29459,0,0,1,0,42.3034Z"
            />
        </svg>
        <template v-if="loading">
            <Words large spaced-bottom>{{ $t('components.appLoader.loadingLabel') }}</Words>
            <div class="app-loader__spinner" data-test="pending-request">&nbsp;</div>
        </template>
        <template v-else>
            <Words large spaced-bottom>{{ $t('components.appLoader.errorLabel') }}</Words>
            <Button primary @click="$emit('retry')">{{ $t('components.appLoader.retryButtonLabel') }}</Button>
        </template>
    </div>
</template>

<script>
import Button from '@/components/Button/Button';
import Words from '@/components/Typography/Words';

export default {
    name: 'AppLoader',
    components: {
        Button,
        Words,
    },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style lang="scss"></style>

<template>
    <LayoutPage :parent-route="parentRoute" screen-name="seller-tokenmanagement" :child-routes="childRoutes">
        <div slot="pageTitle">
            {{ $t('pages.settingsV2.titles.tokenManagement') }}
        </div>

        <div class="text-light mx-auto mt-[56px] flex min-h-[200px] w-full flex-col px-4 md:w-[720px]">
            <template v-if="listHasFetched">
                <HelpLink
                    v-if="readyForRelease"
                    :title="$t('pages.tokenManagement.tokens.helpLink')"
                    class="ml-auto flex"
                    data-test="token-help-link"
                />
                <template v-if="hasTokens">
                    <div
                        v-for="(token, tokenIndex) in tokens"
                        :key="`${tokenIndex}-token`"
                        class="card mt-6 w-full"
                        :data-test="`token-item_${tokenIndex}`"
                    >
                        <div class="mb-4 flex justify-between">
                            <span class="mb-5 text-md font-bold">{{ token.tokenName }}</span>
                            <SfTag :action="false" size="sm" class="self-start">{{ token.tokenTypeLabel }}</SfTag>
                        </div>
                        <div
                            v-for="(factory, factoryIndex) in token.factories"
                            :key="`${factoryIndex}-factory`"
                            class="mb-2 flex text-subdued"
                        >
                            <SfFacilitiesIcon class="icon-facilities" size="xxs" />
                            <span class="ml-2 text-sm">{{ factory.name }}</span>
                        </div>
                        <div v-if="readyForRelease" class="mt-4 flex justify-end">
                            <SfLink bold :data-test="`token-item-delete_${tokenIndex}`">{{
                                $t('pages.tokenManagement.tokens.delete')
                            }}</SfLink>
                        </div>
                    </div>
                </template>
                <NoTokens v-else />
            </template>
            <LoadingSpinner v-else :dark="true" />
        </div>
    </LayoutPage>
</template>

<script>
import { mapGetters } from 'vuex';
import { SfFacilitiesIcon, SfLink, SfTag } from '@schuettflix/vue-components';
import LayoutPage from '@/components/Layout/Page.v2';
import HelpLink from '@/_components/HelpLink/HelpLink';
import NoTokens from '@/pages/TokenManagement/partials/NoTokens';
import Token from '@/services/Api/Token';
import LoadingSpinner from '@/components/LoadingSpinner';

export default {
    name: 'TokenList',
    components: {
        HelpLink,
        LayoutPage,
        NoTokens,
        SfFacilitiesIcon,
        SfTag,
        SfLink,
        LoadingSpinner,
    },
    props: {
        parentRoute: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            tokens: null,
        };
    },
    computed: {
        ...mapGetters('user', ['organizationId']),
        childRoutes() {
            return [];
        },
        hasTokens() {
            return !!this.tokens?.length;
        },
        listHasFetched() {
            return !!this.tokens;
        },
        readyForRelease() {
            return false;
        },
    },
    async created() {
        await this.fetchTokenList();
    },
    methods: {
        async fetchTokenList() {
            this.tokens = (await Token.getTokensBySupplierId(this.organizationId)) || [];
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

::v-deep .icon-facilities path {
    stroke: $color-dark-grey-400;
}
</style>

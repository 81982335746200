<template>
    <label class="text-field__label" :class="{ 'text-field__label--big': bigPlaceholderActive }">
        {{ placeholder }}
    </label>
</template>

<script>
export default {
    name: 'WasteLabel',
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        bigPlaceholderActive: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.text-field__label {
    font-size: 10px;
    opacity: 0.7;
    line-height: 1;
    position: absolute;
    color: inherit;
    top: 20px;
    left: 16px;
    transition: top 0.2s ease;

    &--big {
        font-size: 14px;
        top: 30px;
        opacity: 1;
        transition: top 0.2s ease;
    }
}
</style>

import { GenericPositionHTTPClient } from '@/modules/constructionProjects/services/GenericPositionHTTPClient';
import { GetPositionOverviewResponseSchema } from '@/modules/constructionProjects/api/position-service-interfaces';
export const getGenericPositionOverviewById = async (positionId) => {
    return GenericPositionHTTPClient.get(`/v1/position/${positionId}/overview`).then(res => GetPositionOverviewResponseSchema.parse(res.data));
};
export function getGenericPositionOverviewByIdKey(positionId) {
    return ['generic-positions', positionId, 'summary'];
}
export function getGenericPositionOverviewByIdQuery(positionId) {
    return {
        queryKey: getGenericPositionOverviewByIdKey(positionId),
        queryFn: () => getGenericPositionOverviewById(positionId),
        refetchOnWindowFocus: false,
        suspense: true,
    };
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-group",class:[
        `button-group--mobile-bp-${_vm.mobileBreakpoint}`,
        {
            'button-group--vertical': _vm.vertical,
            'button-group--floating': _vm.floating,
            'button-group--inline': _vm.inline || _vm.floating,
            'button-group--transparent': _vm.transparent,
            'button-group--bordered': _vm.bordered,
            'button-group--children-spaced': _vm.childrenSpaced,
            'button-group--spacing-large': _vm.spacingLarge,
            'flex w-full flex-col md:flex-row': _vm.isCssFlex,
        },
    ]},[_vm._t("default")],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
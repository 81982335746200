<script>
import ProjectPositionApi from '@/services/Api/ProjectPosition';
import ProjectPositionView from '@/models/ProjectPositionView';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { ACTIONS } from '@/constants/actions';
import { EVENT_PROJECT_POSITION_UPDATED } from '@/constants/events';
import { mapGetters } from 'vuex';
import Toaster from '@/services/Toaster';

export default {
    name: 'ProjectPositionStatusChangeAction',

    mixins: [eventHubMixin],
    data() {
        return {
            isPending: false,
        };
    },
    computed: {
        ...mapGetters('globalActions', ['subject', 'action']),

        isActive() {
            return this.action === ACTIONS.PROJECT_POSITION_STATUS_CHANGE;
        },

        isActionAvailable() {
            return this.subject instanceof ProjectPositionView;
        },
    },
    watch: {
        isActive() {
            if (this.isActive) {
                this.toggleProjectPositionStatus();
                this.resetAction();
            }
        },
    },
    methods: {
        resetAction() {
            this.$store.dispatch('globalActions/reset', ACTIONS.PROJECT_POSITION_STATUS_CHANGE);
        },

        async toggleProjectPositionStatus() {
            if (this.isPending) return;
            this.isPending = true;

            try {
                if (this.subject.status === 'open') {
                    await ProjectPositionApi.updateStatus(this.subject.id, 'deactivate');
                    Toaster.success(
                        this.$t('pages.project.position.action.changeStatus.deactivate', {
                            number: this.subject?.number,
                        })
                    );
                } else {
                    await ProjectPositionApi.updateStatus(this.subject.id, 'activate');
                    Toaster.success(
                        this.$t('pages.project.position.action.changeStatus.activate', {
                            number: this.subject?.number,
                        })
                    );
                }

                this.$eventHub.$emit(EVENT_PROJECT_POSITION_UPDATED, this.subject);
            } catch (err) {
                Toaster.error(this.$t('pages.project.position.action.changeStatus.error'));
            }

            this.isPending = false;
            this.resetAction();
        },
    },
};
</script>

<template>
    <GridRow class="container-deprecated" :count="2" spacing="large" spaced-top>
        <div v-if="supportsFilter(['startTotal', 'endTotal'])" class="span-2">
            <Words bold block spaced>{{ $t('pages.invoice.filter.totalHeadline') }}</Words>
            <GridRow :count="2" spacing="small" margin-less>
                <div class="span-2 span-md-1">
                    <TextField v-model="startTotal" :label="$t('pages.invoice.filter.startTotal')" type="number" />
                </div>

                <div class="span-2 span-md-1">
                    <TextField v-model="endTotal" :label="$t('pages.invoice.filter.endTotal')" type="number" />
                </div>
            </GridRow>
        </div>
        <div>
            <div class="span-2 my-4 font-bold">{{ $t('pages.invoice.filter.documentTypeHeadline') }}</div>
            <SelectBox v-model="documentType" tiny>
                <option :value="null" :label="$t('pages.invoice.filter.documentType.all')" />
                <option value="order" :label="$t('pages.invoice.filter.documentType.systemic')" />
                <option value="manual" :label="$t('pages.invoice.filter.documentType.manual')" />
            </SelectBox>
        </div>

        <div v-if="supportsFilter(['startCreated', 'endCreated'])" class="span-2">
            <Words bold block spaced>{{ $t('pages.invoice.filter.dateRangeHeadline') }}</Words>
            <TimeRangeFieldset
                :from="filterScope.filter.startCreated"
                :to="filterScope.filter.endCreated"
                type="date"
                @update-from="$set(filterScope.filter, 'startCreated', ensurePHPTimestamp($event))"
                @update-to="$set(filterScope.filter, 'endCreated', ensurePHPTimestamp($event))"
            />
        </div>
    </GridRow>
</template>

<script>
import { ensurePHPTimestamp } from '@/services/utils/date';
import { revertLocalizedValue } from '@/services/utils/localization';
import _isArray from 'lodash/isArray';
import _intersection from 'lodash/intersection';

import GridRow from '@/components/Layout/GridRow';
import TextField from '@/components/Form/TextField.v2';
import SelectBox from '@/components/Form/SelectBox.v2';

import TimeRangeFieldset from '@/components/Filter/TimeRangeFieldset.v2';
import Words from '@/components/Typography/Words';

import { useTwoWayBindingComputedFilter } from '@/components/Filter/FilterBox';

export default {
    name: 'InvoiceFilterSet',
    components: {
        GridRow,
        TextField,
        TimeRangeFieldset,
        Words,
        SelectBox,
    },
    props: {
        filterScope: {
            type: Object,
            required: true,
        },
        endpoint: {
            type: Object,
            required: true,
        },
        whitelistedFields: {
            type: Array,
            default: null,
        },
    },
    setup(props, { emit }) {
        const createTwoWayBindingComputedFilter = useTwoWayBindingComputedFilter({ props, emit });
        return {
            startTotal: createTwoWayBindingComputedFilter('startTotal'),
            endTotal: createTwoWayBindingComputedFilter('endTotal'),
            documentType: createTwoWayBindingComputedFilter('documentType'),
        };
    },
    methods: {
        ensurePHPTimestamp,
        revertLocalizedValue,

        supportsFilter(filterName) {
            if (!this.endpoint.supportedFilters) {
                return false;
            }

            const checkProps = _isArray(filterName) ? filterName : [filterName];

            // if filterName does not appear fully in whitelistedFields,.. then the filter is not supported
            if (
                _isArray(this.whitelistedFields) &&
                _intersection(this.whitelistedFields, checkProps).length !== checkProps.length
            ) {
                return false;
            }

            return _intersection(Object.keys(this.endpoint.supportedFilters), checkProps).length === checkProps.length;
        },
    },
};
</script>

<style lang="scss"></style>

<template>
    <div class="flex w-full flex-col items-center text-center">
        <SfJobsIcon size="lg" multi-color class="mt-[68px]" />
        <p class="font-headline-xl-strong mt-8">
            {{ $t('pages.tokenManagement.emptyState.headline') }}
        </p>
        <p class="font-copy-md mt-2">{{ $t('pages.tokenManagement.emptyState.description') }}</p>
        <SfButton
            class="mt-6"
            theme="light"
            type="primary"
            size="sm"
            data-test="no-token-contact-us-button"
            @click="openContactFlyout"
            >{{ $t('pages.tokenManagement.emptyState.contactUs') }}
        </SfButton>
    </div>
</template>

<script>
import { SfButton, SfJobsIcon } from '@schuettflix/vue-components';

export default {
    name: 'NoTokens',
    components: {
        SfJobsIcon,
        SfButton,
    },
    methods: {
        openContactFlyout() {
            this.$eventHub.$emit('page.platformContact', true);
        },
    },
};
</script>

<style scoped></style>

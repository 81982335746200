import QuoteApi from '@/services/Api/Quote';
import store from '@/store';
import { generateLineItemGroups } from '@/services/utils/basket';
import _get from 'lodash/get';
import SupplierApi from '@/services/Api/Supplier';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import { clearNullableValues } from './Api/AbstractFilterableResource';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';

class QuoteService {
    clientQuoteApi = null;
    wasteClientQuoteApi = null;

    constructor({ clientQuoteApi, wasteClientQuoteApi }) {
        this.clientQuoteApi = clientQuoteApi;
        this.wasteClientQuoteApi = wasteClientQuoteApi;
    }

    getQuoteApi() {
        return store.getters['basket/isDisposal'] ? this.wasteClientQuoteApi : this.clientQuoteApi;
    }

    async loadById(id) {
        return await this.getQuoteApi().getOneById(id);
    }

    /**
     * Save the current quote from the store
     * @return {Promise<void>}
     */
    async saveQuote() {
        let quoteRequestPayload;

        if (
            [
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DELIVERY,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_SHIPMENT,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS,
            ].includes(store.state.basket.type)
        ) {
            quoteRequestPayload = this.generateProjectPositionQuoteRequestFromBasket();
        } else {
            quoteRequestPayload = this.generateQuoteRequestFromBasket();
        }

        const quote = await this.getQuoteApi().save(clearNullableValues(quoteRequestPayload));

        store.commit('basket/setQuote', quote);
        store.commit('basket/setServerError', null);
    }

    async updateCostCenter() {
        let quoteRequestPayload;

        if (
            [
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DELIVERY,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_SHIPMENT,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DISPOSAL_NON_HAZARDOUS,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DISPOSAL_HAZARDOUS,
            ].includes(store.state.basket.type)
        ) {
            quoteRequestPayload = this.generateProjectPositionQuoteRequestFromBasket();
        } else {
            quoteRequestPayload = this.generateQuoteRequestFromBasket();
        }

        const quote = await this.getQuoteApi().updateCostCenter(quoteRequestPayload.id, quoteRequestPayload.costCenter);

        store.commit('basket/setQuote', quote);
        store.commit('basket/setServerError', null);
    }

    /**
     * Submit a quote
     *
     * @param verifierId
     * @param requestMessage
     * @return {Promise<undefined>}
     */
    async submitQuote({ verifierId, requestMessage }) {
        const result = await this.getQuoteApi().submit({
            id: store.state.basket.quote.id,
            verifiedBy: verifierId ? { id: verifierId } : null,
            requestMessage,
        });

        await store.dispatch('basket/resetAndSetConfirmedQuote', result);
        return result;
    }

    /**
     * Accept a quote
     *
     * @return {Promise<undefined>}
     */
    async acceptQuote() {
        const result = await this.getQuoteApi().accept({
            id: store.state.basket.quote.id,
        });

        await store.dispatch('basket/resetAndSetConfirmedQuote', result);
        return result;
    }

    /**
     * Reject a quote
     *
     * @param rejectionMessage
     * @return {Promise<undefined>}
     */
    async rejectQuote(rejectionMessage) {
        const result = await this.getQuoteApi().reject({
            id: store.state.basket.quote.id,
            rejectionMessage,
        });

        store.commit('basket/reset');
        return result;
    }

    /**
     * Update quote's transport price
     *
     * @param {number} transportRetailPrice
     * @return {Promise<undefined>}
     */
    async updateShipmentPrice(transportRetailPrice) {
        const quote = await this.getQuoteApi().updateShipmentPrice({
            id: store.state.basket.quote.id,
            transportRetailPrice,
        });

        store.commit('basket/setQuote', quote);
        store.commit('basket/setServerError', null);
    }

    /**
     * Generate quote request from store
     *
     * @return {object}
     */
    generateQuoteRequestFromBasket() {
        const state = store.state.basket;
        const lineItemGroups = generateLineItemGroups(state);
        const constructionProjectId = store.state.constructionProject.constructionProjectId;

        let customAdditions = {};
        if (state.isCustom) {
            customAdditions = {
                customOrder: true,
                projectName: _get(state, 'clientInfo.projectName', null),
                client: { id: _get(state, 'clientInfo.client.id', null) },
                supervisor: { id: _get(state, 'clientInfo.purchaser.id', null) },
            };
        }

        return {
            ...customAdditions,
            id: _get(state, 'quote.id', null),
            lineItemGroups,
            costCenter: _get(state, 'costCenter', null),
            constructionProjectId,
        };
    }

    generateProjectPositionQuoteRequestFromBasket() {
        const state = store.state.basket;
        const constructionProjectId = store.state.constructionProject.constructionProjectId;
        const lineItemGroups = generateLineItemGroups(state);

        return {
            id: _get(state, 'quote.id', null),
            lineItemGroups,
            costCenter: state.costCenter ?? null,
            constructionProjectId,
        };
    }

    /**
     * Restore basket state from quote
     *
     * @param quote
     * @return {Promise<*>}
     */
    async restoreBasketFromQuote(quote) {
        const firstLineItemGroup = quote.lineItemGroups[0];
        const sellerProductId = store.state.basket.supplierInfo?.sellerProductId;
        const productHash = store.state.basket.product?.hash;
        const locationId = store.state.basket.constructionSite?.constructionProjectLocationId;

        // new pending and custom states can not be restored?
        if (['new', 'pending', 'custom'].indexOf(quote.status) === -1) {
            return;
        }

        switch (firstLineItemGroup.type) {
            case ORDER_LINE_ITEM_GROUP_TYPE.DELIVERY:
                store.commit('basket/init', {
                    type: quote.lineItemGroups[0]?.projectId ? 'project-position-delivery' : 'delivery',
                    isCustom: quote.customOrder,
                });
                break;
            case ORDER_LINE_ITEM_GROUP_TYPE.PICKUP:
                store.commit('basket/init', { type: 'pickup', isCustom: quote.customOrder });
                break;
            case ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_NON_HAZARDOUS_WASTE:
                store.commit('basket/init', {
                    type: firstLineItemGroup?.projectId ? 'project-position-waste' : 'waste',
                    isCustom: quote.customOrder,
                });
                break;
            case ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_HAZARDOUS_WASTE:
                store.commit('basket/init', {
                    type: firstLineItemGroup?.projectId ? 'project-position-dangerousWaste' : 'dangerousWaste',
                    isCustom: quote.customOrder,
                });
                break;
        }

        store.commit('basket/setQuote', quote);

        store.commit('basket/setConstructionSite', {
            ...firstLineItemGroup.constructionSite,
            constructionProjectLocationId: locationId,
        });
        store.commit('basket/setProduct', { ...firstLineItemGroup.product, hash: productHash });
        store.commit('basket/setQty', firstLineItemGroup.qty);

        if (
            [
                ORDER_LINE_ITEM_GROUP_TYPE.DELIVERY,
                ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_HAZARDOUS_WASTE,
                ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_NON_HAZARDOUS_WASTE,
            ].includes(firstLineItemGroup.type)
        ) {
            const transports = firstLineItemGroup.lineItems.map(lineItem => {
                return {
                    id: lineItem.vehicleClassId,
                    actualLoad: lineItem.qty,
                    date: lineItem.shippingDate,
                    name: lineItem.vehicleName,
                    icon: lineItem.vehicleClassIcon,
                    numAxes: lineItem.vehicleNumAxes,
                    payload: lineItem.vehiclePayload,
                    loadPercentage: (lineItem.qty / lineItem.vehiclePayload) * 100,
                };
            });
            const siteType =
                firstLineItemGroup.type === ORDER_LINE_ITEM_GROUP_TYPE.DELIVERY ? 'unloadingSite' : 'loadingSite';
            const siteTypeInformation = `${siteType}Information`;
            const siteTypeImage = `${siteType}Image`;
            const siteTypeLocation = `${siteType}Location`;
            store.commit('basket/setTransports', {
                inputType: firstLineItemGroup.inputType,
                qty: firstLineItemGroup.qty,
                transports,
            });
            store.commit('basket/setAdditionalInformation', {
                description: firstLineItemGroup[siteTypeInformation],
                photo: firstLineItemGroup[siteTypeImage],
                [siteTypeLocation]: firstLineItemGroup[siteTypeLocation],
            });
            store.commit('basket/setDeliveryMethod', {
                cutOffTime: firstLineItemGroup.cutOff,
                name: firstLineItemGroup.shippingMethod,
                type: firstLineItemGroup.type,
                deliveryWindow: {
                    start: firstLineItemGroup.shippingWindowStart,
                    end: firstLineItemGroup.shippingWindowEnd,
                },
            });
        }

        if (
            [
                ORDER_LINE_ITEM_GROUP_TYPE.PICKUP,
                ORDER_LINE_ITEM_GROUP_TYPE.DELIVERY,
                ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_NON_HAZARDOUS_WASTE,
                ORDER_LINE_ITEM_GROUP_TYPE.DISPOSAL_HAZARDOUS_WASTE,
            ].includes(firstLineItemGroup.type)
        ) {
            const supplierInfo = await SupplierApi.getOneById(firstLineItemGroup.factoryId);
            store.commit('basket/setSupplierInfo', {
                ...supplierInfo,
                sellerProductId: sellerProductId,
            });
        }
    }
}

export default new QuoteService({
    clientQuoteApi: new QuoteApi('/client/quote'),
    wasteClientQuoteApi: new QuoteApi('/waste/client/quote'),
});

import AbstractFilterableResource from '../AbstractFilterableResource';

class Pickup extends AbstractFilterableResource {
    async startPickup(lineItemGroupId, vehicleId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${lineItemGroupId}`, {
                vehicle: vehicleId,
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * change the vehicle of a pickup trasport order
     * @param {string} transportId id of the transport id
     * @param {string} vehicleId new vechicle id
     */
    async changeVehicle(transportId, vehicleId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/changevehicle`, {
                vehicle: vehicleId,
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new Pickup('/client/pickup');

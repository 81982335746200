import { TransportPrice } from '@/services/utils/transport';
import { toKgPrice, toTonPrice } from '@/services/utils/units';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';

export default class TransportPricingView {
    constructor() {
        this._id = null;
        this._info = null;
        this._qty = null;
        this._count = null;
        this._payload = null;
        this._purchaseUnitPrice = null;
        this._retailUnitPrice = null;
        this._duration = null;
        this._distance = null;
        this._enabled = true;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param quote
     * @param data
     * @return {TransportPricingView|null}
     */
    static createFromCustomOrder(quote, data) {
        if (!data) return null;

        const type = quote.lineItemGroups[0].type;
        const billingMethod = quote.lineItemGroups[0].billingMethod;
        const distance =
            type === ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT ? quote.lineItemGroups[0].lineItems[0].totalDistance : 1000;

        const view = this.create({
            ...data,
            purchaseUnitPrice: data.purchasePrice,
            retailUnitPrice: data.retailPrice,
            distance,
        });

        view.enabled = true;

        if (type === ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT && billingMethod === 'fixedPrice') {
            view.purchaseUnitPrice.setPerKm(data.purchasePrice * 1000);
            view.retailUnitPrice.setPerKm(data.retailPrice * 1000);
        }

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {TransportPricingView|null}
     */
    static createFromProjectPositionPayload(data) {
        if (!data) return null;

        const view = new TransportPricingView();

        view.distance = data.distance;
        view.duration = data.duration;
        view.info = data.vehicleClass.name;
        view.payload = data.vehicleClass.payload;
        view.qty = data.vehicleClass.payload;
        view.id = data.vehicleClass.id ? parseInt(data.vehicleClass.id) : null;
        view.count = 1;
        view.enabled = true;

        if (typeof data.purchasePrice === 'number') {
            view.purchaseUnitPrice = new TransportPrice();
            view.purchaseUnitPrice.distance = data.distance;
            view.purchaseUnitPrice.payload = data.vehicleClass.payload;
            view.purchaseUnitPrice.setPerTon(toTonPrice(data.purchasePrice));
        }

        if (typeof data.retailPrice === 'number') {
            view.retailUnitPrice = new TransportPrice();
            view.retailUnitPrice.distance = data.distance;
            view.retailUnitPrice.payload = data.vehicleClass.payload;
            view.retailUnitPrice.setPerTon(toTonPrice(data.retailPrice));
        }

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {TransportPricingView|null}
     */
    static createFromProjectPositionBasket(data) {
        if (!data) return null;

        const view = this.create(data);
        view.enabled = data.enabled ?? false;

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {TransportPricingView|null}
     */
    static createFromVehicleClass(data) {
        if (!data) return null;

        const view = new TransportPricingView();

        view.info = data.vehicleClass.name;
        view.payload = data.vehicleClass.payload;
        view.qty = data.vehicleClass.payload;
        view.id = data.vehicleClass.id ? parseInt(data.vehicleClass.id) : null;
        view.count = 1;
        view.enabled = true;

        if (typeof data.purchasePrice === 'number') {
            view.purchaseUnitPrice = new TransportPrice();
            view.purchaseUnitPrice.payload = data.vehicleClass.payload;
            view.purchaseUnitPrice.setPerTon(toTonPrice(data.purchasePrice));
        }

        if (typeof data.retailPrice === 'number') {
            view.retailUnitPrice = new TransportPrice();
            view.retailUnitPrice.payload = data.vehicleClass.payload;
            view.retailUnitPrice.setPerTon(toTonPrice(data.retailPrice));
        }

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {TransportPricingView|null}
     */
    static create(data) {
        if (!data) return null;

        const view = new TransportPricingView();

        view.distance = data.distance;
        view.duration = data.duration;
        view.info = data.info;
        view.payload = data.payload;
        view.qty = data.qty;
        view.id = data.id ? parseInt(data.id) : null;
        view.count = data.count;
        view.enabled = data.enabled;

        if (typeof data.purchaseUnitPrice === 'number') {
            view.purchaseUnitPrice = new TransportPrice();
            view.purchaseUnitPrice.distance = view.distance;
            view.purchaseUnitPrice.payload = data.payload;
            view.purchaseUnitPrice.setPerTon(toTonPrice(data.purchaseUnitPrice));
        }

        if (typeof data.retailUnitPrice === 'number') {
            view.retailUnitPrice = new TransportPrice();
            view.retailUnitPrice.distance = view.distance;
            view.retailUnitPrice.payload = data.payload;
            view.retailUnitPrice.setPerTon(toTonPrice(data.retailUnitPrice));
        }

        return view;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            id: this.id,
            info: this.info,
            qty: this.qty,
            count: this.count,
            payload: this.payload,
            duration: this.duration,
            distance: this.distance,
            enabled: this.enabled,
            purchaseUnitPrice: toKgPrice(this.purchaseUnitPrice.perTon),
            retailUnitPrice: toKgPrice(this.retailUnitPrice.perTon),
        };
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfoldToCustomOrder(billingMethod) {
        return {
            info: this.info,
            qty: this.qty,
            count: this.count,
            payload: this.payload,
            purchasePrice: toKgPrice(
                billingMethod === 'fixedPrice' ? this.purchaseUnitPrice.perKm : this.purchaseUnitPrice.perTon
            ),
            retailPrice: toKgPrice(
                billingMethod === 'fixedPrice' ? this.retailUnitPrice.perKm : this.retailUnitPrice.perTon
            ),
        };
    }

    /**
     * Clone current instance
     * @return {TransportPricingView|null}
     */
    clone() {
        return TransportPricingView.create(this.unfold());
    }

    get info() {
        return this._info;
    }

    set info(value) {
        this._info = value ?? null;
    }

    get qty() {
        return this._qty;
    }

    set qty(value) {
        this._qty = value ?? null;
    }

    get count() {
        return this._count;
    }

    set count(value) {
        this._count = value ?? null;
    }

    get payload() {
        return this._payload;
    }

    set payload(value) {
        this._payload = value ?? null;
    }

    get purchaseUnitPrice() {
        return this._purchaseUnitPrice;
    }

    set purchaseUnitPrice(value) {
        this._purchaseUnitPrice = value ?? null;
    }

    get retailUnitPrice() {
        return this._retailUnitPrice;
    }

    set retailUnitPrice(value) {
        this._retailUnitPrice = value ?? null;
    }

    get duration() {
        return this._duration;
    }

    set duration(value) {
        this._duration = value ?? null;
    }

    get distance() {
        return this._distance;
    }

    set distance(value) {
        this._distance = value ?? null;
    }

    get enabled() {
        return this._enabled;
    }

    set enabled(value) {
        this._enabled = value ?? false;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value ?? null;
    }

    get purchasePriceTotals() {
        return {
            getPerKm: () => {
                return this.purchaseUnitPrice.getPerKm() * this.count;
            },
            getPerTransport: () => {
                return this.purchaseUnitPrice.getPerTransport() * this.count;
            },
            getPerTon: () => {
                return (this.purchaseUnitPrice.getPerTon() * this.qty) / 1000;
            },
        };
    }

    get retailPriceTotals() {
        return {
            getPerKm: () => {
                return this.retailUnitPrice.getPerKm() * this.count;
            },
            getPerTransport: () => {
                return this.retailUnitPrice.getPerTransport() * this.count;
            },
            getPerTon: () => {
                return (this.retailUnitPrice.getPerTon() * this.qty) / 1000;
            },
        };
    }

    /**
     * @param {MaterialPricingView} material
     */
    getPurchaseUnitPriceForMaterial(material) {
        return this.purchaseUnitPrice.getPerTon() + material.purchaseUnitPrice;
    }

    /**
     * @param {MaterialPricingView} material
     */
    getPurchasePriceTotalsForMaterial(material) {
        return (this.getPurchaseUnitPriceForMaterial(material) * this.payload) / 1000;
    }

    /**
     * @param {MaterialPricingView} material
     */
    getRetailUnitPriceForMaterial(material) {
        return this.retailUnitPrice.getPerTon() + material.retailUnitPrice;
    }

    /**
     * @param {MaterialPricingView} material
     */
    getRetailPriceTotalsForMaterial(material) {
        return (this.getRetailUnitPriceForMaterial(material) * this.payload) / 1000;
    }
}

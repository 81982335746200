import LogService from '@schuettflix/util-log';
import Toaster from '@/services/Toaster';

import StateWasteCompaniesAPI from '@/services/Api/Waste/StateWasteCompanies';
import FactoryApi from '@/services/Api/FilterableFactory';
import ProjectApi from '@/services/Api/Project';
import ProjectPositionApi from '@/services/Api/ProjectPosition';

import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import { DISPOSAL_ANALYSIS_DOCUMENT_TYPE } from '@/constants/disposal';

const Log = new LogService('store/projectPosition');

const actions = {
    async fetchStateWasteCompanies({ commit }) {
        try {
            const stateWasteCompanies = await StateWasteCompaniesAPI.getAll();
            commit('SET_STATE_WASTE_COMPANIES', stateWasteCompanies);
        } catch (errors) {
            commit('SET_PROJECT_POSITION_SELECTION_ERRORS', errors);
        }
    },

    async fetchProductVariants({ state, commit }, { productId }) {
        const factoryId = state.disposer.factoryId;

        try {
            const FilterableFactory = new FactoryApi(factoryId);
            const responseVariants = await FilterableFactory.getActiveWasteProductVariants(factoryId, productId);
            const responsePollutionTypes = await FilterableFactory.getPollutionTypesForWasteProduct(productId);

            commit(
                'SET_PROJECT_POSITION_PRODUCT_VARIANTS',
                responseVariants.items.map(item => ({
                    ...item,
                    pollutionType: responsePollutionTypes.items.find(
                        pollutionType => pollutionType.id === item.pollutionTypeId
                    )?.label,
                }))
            );
        } catch (err) {
            Log.log(err);
            Toaster.error(err);
        }
    },

    async fetchProjectPositionPrices(
        { state, commit },
        { customPurchaseUnitPrice, customRetailUnitPrice, enabledVehicleClassPrices, customPositions }
    ) {
        const stateWasteCompanyId =
            state.requestCollection.billingByStateWasteCompanyId > -1
                ? state.requestCollection.billingByStateWasteCompanyId
                : null;

        try {
            const disposalPrices = await ProjectApi.calculateDisposalPosition(
                state.requestCollection.project.id,
                state.requestCollection.constructionSite.id,
                state.requestCollection.factoryProductVariantId,
                stateWasteCompanyId,
                customPurchaseUnitPrice,
                customRetailUnitPrice,
                enabledVehicleClassPrices,
                customPositions,
                state.requestCollection.qty
            );

            commit('SET_PRICES_DATA', {
                ...disposalPrices,
                customPositions: disposalPrices.customPositions.map(position => ({
                    ...position,
                    purchaseUnitPrice: position.unitPurchasePrice,
                    retailUnitPrice: position.unitRetailPrice,
                })),
            });
        } catch (err) {
            Log.log(err);
            Toaster.error(err);
        }
    },

    async postProjectPosition({ state, commit }, { projectPositionId, isAutomaticBGSCreationEnabled } = {}) {
        commit('SET_ERROR', false);

        try {
            let payload = { ...state.requestCollection };

            // Ids come with a value of -1 from the requestCollection when there is no entity belonging to that id
            if (payload.billingByStateWasteCompanyId === -1) {
                delete payload.billingByStateWasteCompanyId;
            }
            if (payload.carrier?.id === -1) {
                delete payload.carrier.id;
            }

            if (payload.carrierNumber?.id === -1) {
                delete payload.carrierNumber;
            }

            payload.vehicleClasses = payload.vehicleClasses.map(t => ({
                vehicleClass: { id: t.vehicleClass?.id },
                purchasePrice: t.purchasePrice,
                retailPrice: t.retailPrice,
            }));

            if (payload.type === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS) {
                delete payload.analysis;
            }
            if (payload.type === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS) {
                delete payload.disposalProofNumber;
                delete payload.producerNumber;
            }

            if (payload.validityRange) {
                payload.validFrom = payload.validityRange.start;
                payload.validTo = payload.validityRange.end;

                delete payload.validityRange;
            }

            payload = {
                ...payload,
                documentUuids: [...state.documents.map(d => d.uuid), ...state.otherDocuments.map(d => d.uuid)],
            };

            if (
                !isAutomaticBGSCreationEnabled ||
                payload.type !== BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS
            ) {
                delete payload.automaticAccompanyingDocumentCreation;
            }

            if (projectPositionId) {
                return await ProjectPositionApi.updateProjectPosition(projectPositionId, payload);
            }
            delete payload.analysis;
            return await ProjectPositionApi.createProjectPosition(payload);
        } catch (e) {
            commit('SET_ERROR', e);
        }
    },

    async fetchProjectPosition({ commit, dispatch }, { projectPositionId }) {
        try {
            const projectPosition = await ProjectPositionApi.getOneById(projectPositionId);

            const { materialPurchaseUnitPrice, materialRetailUnitPrice, customPositions } = projectPosition;

            const FilterableFactory = new FactoryApi(projectPosition.factory.id);
            const responseVariants = await FilterableFactory.getActiveWasteProductVariants(
                projectPosition.factory.id,
                projectPosition.product.id
            );

            const factoryProductVariantId = responseVariants.items.find(
                ({ pollutionTypeId }) => pollutionTypeId === projectPosition.productVariant.pollutionType.id
            ).id;

            const documents =
                projectPosition.documents?.filter(
                    doc => doc?.documentType?.code === DISPOSAL_ANALYSIS_DOCUMENT_TYPE.DECLARATION
                ) ?? [];
            const otherDocuments =
                projectPosition.documents?.filter(
                    doc => doc?.documentType?.code === DISPOSAL_ANALYSIS_DOCUMENT_TYPE.ACCOMPANYING
                ) ?? [];
            const images = projectPosition.accompanyingAnalysisImages ?? [];

            commit('RESET', {
                carrierInfo: projectPosition.carrier,
                clientName: projectPosition.project?.client?.name,
                constructionSite: projectPosition.constructionSite,
                constructionSiteState: projectPosition.constructionSite?.address?.state,
                disposer: {
                    id: projectPosition.factory?.supplierOrganizationId,
                    factoryId: projectPosition.factory?.id,
                },
                product: projectPosition.product,
                documents,
                otherDocuments,
                images,
                requestCollection: {
                    analysis: {
                        exemption: projectPosition.exemption,
                    },
                    accompanyingAnalysisImageUuids: projectPosition.accompanyingAnalysisImageUuids ?? [],
                    additionalInternalTitle: projectPosition.additionalInternalTitle ?? null,
                    billingMethod: projectPosition.billingMethod,
                    billingByStateWasteCompanyId: projectPosition.billingByStateWasteCompany?.id ?? -1,
                    carrier: projectPosition.carrier,
                    carrierNumber: projectPosition.carrier?.carrierNumber ?? { id: -1 },
                    consistency: projectPosition.consistency,
                    constructionSite: projectPosition.constructionSite ?? { id: -1 },
                    customPositions,
                    disposalProofNumber: projectPosition.disposalProofNumber,
                    documentUuids: projectPosition.documentUuids ?? [],
                    factoryProductVariantId,
                    qty: projectPosition.qty,
                    isPretreated: projectPosition.isPretreated,
                    paymentTerms: projectPosition.paymentTerms,
                    pretreatmentDescription: projectPosition.pretreatmentDescription,
                    producerNumber: projectPosition.producerNumber,
                    project: projectPosition.project ?? { id: -1 },
                    type: projectPosition.type,
                    unloadingWithoutDisposerPresent: projectPosition.unloadingWithoutDisposerPresent,
                    weighingAtUnloadingPoint: projectPosition.weighingAtUnloadingPoint,
                    weighingBillingBasis: projectPosition.weighingBillingBasis,
                    vehicleClasses: projectPosition.vehicleClasses,
                    note: projectPosition.note,
                    materialPurchaseUnitPrice,
                    materialRetailUnitPrice,
                    validityRange: projectPosition.validFrom
                        ? { start: projectPosition.validFrom, end: projectPosition.validTo }
                        : null,
                    status: projectPosition.status,
                },
            });

            const enabledVehicleClassPrices = projectPosition.vehicleClasses.map(t => ({
                vehicleClassId: t?.vehicleClass?.id,
                purchasePrice: t?.purchasePrice,
                retailPrice: t?.retailPrice,
                priceUnit: 'kg',
            }));

            await dispatch('fetchProjectPositionPrices', {
                customPurchaseUnitPrice: materialPurchaseUnitPrice,
                customRetailUnitPrice: materialRetailUnitPrice,
                enabledVehicleClassPrices,
                customPositions,
            });

            return projectPosition;
        } catch (e) {
            commit('SET_ERROR', e);
        }
    },
};

export default actions;

import LogbookPage from './LogbookPage';

export default {
    path: 'logbook',
    name: 'logbook',
    component: LogbookPage,
    meta: {
        requiresAuth: true,
        requiredAbilities: ['haveLogBook'],
    },
    children: [
        {
            include: 'transport-view',
        },
    ],
};

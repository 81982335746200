import AbstractFilterableResource from './AbstractFilterableResource';
import { ensurePHPTimestamp } from '@/services/utils/date';
import { TransportLogService as LogService } from '@/logger/TransportLogService';
import Toaster from '@/services/Toaster';
import i18n from '@/i18n';
import {
    CONTEXT_CARRIER,
    CONTEXT_CARRIER_DRIVER,
    CONTEXT_CLIENT,
    CONTEXT_PLATFORM,
    CONTEXT_SUPPLIER,
} from '@/constants/context';
import { mapValues } from 'lodash';

const logger = new LogService('services/Api/Transport');

/**
 * NOTE: This class is no longer a Singleton
 */
export default class Transport extends AbstractFilterableResource {
    /**
     * @param {import('@/constants/context').Context} context
     */
    constructor(context, messages = {}) {
        super('/transport', messages);

        const supportedContexts = [
            CONTEXT_PLATFORM,
            CONTEXT_CARRIER,
            CONTEXT_SUPPLIER,
            CONTEXT_CLIENT,
            CONTEXT_CARRIER_DRIVER,
        ];
        if (!supportedContexts.includes(context)) {
            throw Error(
                'Please instantiate a new version of the TransportApi with one of the following supported context types. '
            );
        }

        this.context = context;
    }

    filter() {
        throw new Error('Transport.filter is deprecated. Use TransportList.filter instead');
    }

    async getTrackingByTimestamp(transportId, timestamp = null, status = null) {
        const params = { since: ensurePHPTimestamp(timestamp) };

        if (status) {
            params.status = status;
        }

        try {
            const response = await this.apiService.get(`${this.resourcePath}/${transportId}/tracking`, {
                params,
            });
            return response.data.items;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async assign(transportId) {
        /**
         * Centralized error handling
         */
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/assign`);

            Toaster.success(i18n.t('pages.transportHub.pickedByCarrier'));

            return response.data;
        } catch (err) {
            const error = this.handleError(err);

            logger.error(error);
            Toaster.error(
                (i18n.te(error.translationKey) && i18n.t(error.translationKey)) ||
                    error.message ||
                    i18n.t('pages.transportHub.notAvailable')
            );

            throw error;
        }
    }

    /**
     *
     * @param {number} transportId
     * @param {import('../Transport/CaptureTransportService').TCaptureDeliveryTransportPayload} payload
     * @returns {Promise<import('@/types/Transport').DeliveryTransport>}
     */
    async captureDelivery(transportId, payload) {
        /**
         * Centralized error handling
         */
        try {
            const response = await this.apiService.post(`v2/transport-capture/delivery/${transportId}`, payload);

            Toaster.success(
                i18n.t('pages.transport.transportCapture.successfulDelivery', { number: response.data.number })
            );

            return response.data;
        } catch (err) {
            const error = this.handleError(err);

            logger.error(error);
            Toaster.error((i18n.te(error.translationKey) && i18n.t(error.translationKey)) || error.message || error);

            throw error;
        }
    }

    /**
     *
     * @param {number} transportId
     * @param {import('../Transport/CaptureTransportService').TCaptureShipmentTransportPayload} payload
     * @returns {Promise<import('@/types/Transport').ShipmentTransport>}
     */
    async captureShipment(transportId, payload) {
        /**
         * Centralized error handling
         */
        try {
            const response = await this.apiService.post(`v2/transport-capture/shipment/${transportId}`, payload);

            Toaster.success(
                i18n.t('pages.transport.transportCapture.successfulDelivery', { number: response.data.number })
            );

            return response.data;
        } catch (err) {
            const error = this.handleError(err);

            logger.error(error);
            Toaster.error((i18n.te(error.translationKey) && i18n.t(error.translationKey)) || error.message || error);

            throw error;
        }
    }

    async plan({ transportId, vehicleId, driverId, plannedDeliveryTime, plannedLoadingTime, forSubcontractor }) {
        /**
         * Centralized error handling
         */
        const removeFalsyValuesFromObject = obj => mapValues(obj, value => value || undefined);
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${transportId}/plan`,
                removeFalsyValuesFromObject({
                    vehicleId,
                    driverId,
                    plannedDeliveryTime: ensurePHPTimestamp(plannedDeliveryTime),
                    plannedLoadingTime: ensurePHPTimestamp(plannedLoadingTime),
                    forSubcontractor,
                })
            );
            return response.data;
        } catch (err) {
            const error = this.handleError(err);

            logger.error(error);
            Toaster.error((i18n.te(error.translationKey) && i18n.t(error.translationKey)) || error.message || error);

            throw error;
        }
    }

    async startTrip(transportId, additionalData = {}) {
        /**
         * Centralized error handling
         */
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/start`, additionalData);

            Toaster.success(i18n.t('components.transportActivator.success'));

            return response.data;
        } catch (err) {
            const error = this.handleError(err);

            logger.error(error);
            Toaster.error((i18n.te(error.translationKey) && i18n.t(error.translationKey)) || err.message || err);

            throw error;
        }
    }

    async startDelivery(transportId) {
        /**
         * Centralized error handling
         */
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/startdelivery`);

            Toaster.success(i18n.t('components.transportActivator.success'));

            return response.data;
        } catch (err) {
            const error = this.handleError(err);

            logger.error(error);
            Toaster.error((i18n.te(error.translationKey) && i18n.t(error.translationKey)) || error.message || error);

            throw error;
        }
    }

    async confirmArrival(transportId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/confirmarrival`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async driverConfirmDelivery(transportId, driverConfirmDeliveryImage) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/driverconfirmdelivery`, {
                driverConfirmDeliveryImage,
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * @return {import('@/types').Transport} transport
     */
    async driverConfirmLoadingForDelivery(transportId, payload) {
        try {
            const response = await this.apiService.post(
                `v1${this.resourcePath}/${transportId}/driver-confirm-loading`,
                payload
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async confirmUnloadingDelay(transportId, code, message = '') {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/confirmunloadingdelay`, {
                unloadingDelayCode: code,
                unloadingDelayMessage: message,
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async submitFeedback(transportId, data) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/feedback`, data);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async clientConfirmDelivery(transportId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/clientconfirmdelivery`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async failTransport(transportId, failCode, failMessage) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/fail`, {
                failCode,
                failMessage,
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async renewTransport(transportId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/renew`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async replanTransport(transportId) {
        /**
         * Centralized error handling
         */
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/replan`);
            return response.data;
        } catch (err) {
            const error = this.handleError(err);

            logger.error(error);
            Toaster.error((i18n.te(error.translationKey) && i18n.t(error.translationKey)) || error.message || error);

            throw error;
        }
    }

    async unplanTransport(transportId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/unplan`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async revokePreferredStatus(transportId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/revokepreferredstatus`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async cancelTransport(transportId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/cancel`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async getPlanningData(transportId) {
        const params = {
            includeHolidaysInResponseData: 1,
        };

        try {
            const response = await this.apiService.get(`${this.resourcePath}/${transportId}/planningdata`, { params });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async changeConfirmDeliveryImage(transportId, driverConfirmDeliveryImage) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${transportId}/updatedriverconfirmdeliveryimage`,
                {
                    driverConfirmDeliveryImage,
                }
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async getActivityStream(transportId) {
        try {
            const response = await this.apiService.get(
                `${this.resourcePath}/${transportId}/activity-stream/${this.getContextForResourcePath()}`
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async checkinShipment(transportId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/checkin`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
    async discardCheckinShipment(transportId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/discard`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async confirmLoadingShipment(transportId, payload) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${transportId}/confirmloading-shipment`,
                payload
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async updateLoadingShipment(transportId, payload) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${transportId}/update-loading-shipment`,
                payload
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async confirmUnloadingShipment(transportId, payload) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${transportId}/driverconfirmdelivery-shipment`,
                payload
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async updateUnloadingShipment(transportId, payload) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${transportId}/update-unloading-shipment`,
                payload
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async updateUnloadingInformation(transportId, payload) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${transportId}/update-unloading-information`,
                payload
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async updateLoadingInformation(transportId, payload) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${transportId}/update-loading-information`,
                payload
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async platformConfirmLoadingShipment(transportId, payload) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${transportId}/platform-confirm-loading-shipment`,
                payload
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async platformConfirmDeliveryShipment(transportId, payload) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${transportId}/platform-confirm-delivery-shipment`,
                payload
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async updateLoadWindowShipment(transportId, payload) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${transportId}/update-load-window-shipment`,
                payload
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async updateShippingDateShipment(transportId, shippingDate) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${transportId}/change-planned-loading-or-unloading-date`,
                {
                    shippingDate,
                }
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async confirmArrivalShipment(transportId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/confirmarrival`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async getNextWeighingNumber(transportId) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/${transportId}/nextweighingnumber`);
            return response.data.number;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async changeDeliveryWindow(transportId, newDeliveryData) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/changedeliverywindow`, {
                shippingWindowStart: newDeliveryData.shippingWindowStart,
                shippingWindowEnd: newDeliveryData.shippingWindowEnd,
                shippingMethod: newDeliveryData.shippingMethod,
                shippingDate: newDeliveryData.shippingDate,
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async updateTransportPriceShipment(
        transportId,
        payload = {
            purchasePrice: null,
            retailPrice: null,
            bulkUpdate: false,
        }
    ) {
        try {
            const { purchasePrice, retailPrice, bulkUpdate = false } = payload;
            const response = await this.apiService.post(
                `${this.resourcePath}/${transportId}/update-transport-price-shipment`,
                {
                    purchasePrice,
                    retailPrice,
                    bulkUpdate,
                }
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async updateDisposalTransportAccompanyingDocumentNumber(transportId, accompanyingDocumentNumber) {
        try {
            const response = await this.apiService.patch(
                `v1/disposal${this.resourcePath}/${transportId}/accompanying-document-number`,
                { accompanyingDocumentNumber }
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Increase view count of transport
     * @param {Number} transportId
     * @return {Promise<Number>}
     */
    async increaseViewCount(transportId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/increase-view-count`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Update transport freight information (description, images and documents) for type shipment
     * @param transportId
     * @param {Object} payload
     * @return {Promise<*>}
     */
    async updateTransportFreightInformationShipment(
        transportId,
        payload = {
            freightDescription: '',
            freightType: '',
            freightDocuments: [],
            freightImages: [],
            bulkUpdate: false,
        }
    ) {
        try {
            const { freightDescription, freightType, freightDocuments, freightImages, bulkUpdate = false } = payload;
            const response = await this.apiService.post(
                `${this.resourcePath}/${transportId}/update-transport-shipment-freight-documents`,
                {
                    freightDescription,
                    freightType,
                    freightDocuments,
                    freightImages,
                    bulkUpdate,
                }
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Add carrier accompanying related documents
     *
     * @param transportId
     * @param {Array} documentUuids Array of uuids
     * @return {Promise<*>}
     */
    async addCarrierAccompanyingDocuments(transportId, documentUuids) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${transportId}/carrier-accompanying-document`,
                { documentUuids }
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Add carrier accompanying related images
     *
     * @param transportId
     * @param {Array} imageUuids Array of uuids
     * @return {Promise<*>}
     */
    async addCarrierAccompanyingImages(transportId, imageUuids) {
        try {
            const response = await this.apiService.post(
                `${this.resourcePath}/${transportId}/carrier-accompanying-image`,
                { imageUuids }
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Delete carrier accompanying related documents
     *
     * @param transportId
     * @param {Array} documentUuids Array of uuids
     * @return {Promise<*>}
     */
    async deleteCarrierAccompanyingDocuments(transportId, documentUuids) {
        try {
            const response = await this.apiService.delete(
                `${this.resourcePath}/${transportId}/carrier-accompanying-document`,
                { documentUuids }
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Delete carrier accompanying related images
     *
     * @param transportId
     * @param {Array} imageUuids Array of uuids
     * @return {Promise<*>}
     */
    async deleteCarrierAccompanyingImages(transportId, imageUuids) {
        try {
            const response = await this.apiService.delete(
                `${this.resourcePath}/${transportId}/carrier-accompanying-image`,
                { imageUuids }
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Transition transport to the state `check_in_loading`
     *
     * @param {string | number} transportId
     * @return {Promise<*>}
     */
    async checkInLoading(transportId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/checkinloading`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Validation of loaded or unloaded weight
     *
     * @param {string | number} transportId
     * @param {number} weight netto weight of loaded
     * @param {number|undefined} optionalVehicleClassId optional over ruling vehicle class id
     * @param {import('axios').CancelTokenSource|null} cancelSource
     * @return {Promise<*>}
     */
    async validateWeight(transportId, weight, optionalVehicleClassId = undefined, cancelSource = null) {
        const args = {};

        // cancel previous requests
        if (cancelSource) args.cancelToken = cancelSource.token;
        if (typeof optionalVehicleClassId !== 'undefined') args.params = { vehicleClassId: optionalVehicleClassId };
        try {
            const response = await this.apiService.get(
                `${this.resourcePath}/${transportId}/validation/weight/${weight}`,
                args
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Record weighing at unloading point
     *
     * @param {number} transportId ID of transport
     * @param {object} payload POST Payload
     * @param {number} payload.weightInKg netto weight in kilogram
     * @param {string} payload.weighingNoteNumber weighing note number
     * @param {string} payload.weighingNoteImageUuid weighing slip image uuid
     * @param {string} payload.weighingNoteDocumentUuid weighing slip document uuid
     * @param {string[]} payload.additionalWeighingDocumentUuids array of additional documents uuids
     * @param {string[]} payload.additionalWeighingImageUuids array of additional images uuids
     * @param {string} payload.serviceDate date string (with timezone) of service date
     */
    async recordWeighingAtUnloadingPoint(
        transportId,
        {
            weightInKg,
            weighingNoteNumber,
            weighingNoteImageUuid,
            weighingNoteDocumentUuid,
            additionalWeighingDocumentUuids,
            additionalWeighingImageUuids,
            serviceDate,
        },
        method = 'post'
    ) {
        /**
         * Centralized error handling
         */
        /**
         * POST: used for transport transitions from `checked_in_loading` to `delivered``
         * PUT: used to update `delivered` transports
         */
        const isNotCapturing = this.context === CONTEXT_CARRIER_DRIVER || method === 'put';
        const url = isNotCapturing
            ? `v1${this.resourcePath}/${transportId}/weighing/unloading`
            : `v1${this.resourcePath}/${transportId}/capture-by-weighing`;
        try {
            const response = await this.apiService[method](url, {
                weightInKg,
                weighingNoteNumber,
                weighingNoteImageUuid,
                weighingNoteDocumentUuid,
                additionalWeighingDocumentUuids,
                additionalWeighingImageUuids,
                serviceDate,
            });

            Toaster.success(i18n.t('pages.transport.transportViewV2.transportCompleted.all'));

            return response.data;
        } catch (err) {
            const error = this.handleError(err);

            logger.error(error);
            Toaster.error((i18n.te(error.translationKey) && i18n.t(error.translationKey)) || error.message || error);

            throw error;
        }
    }

    /**
     * Record unloading signature (price/transport)
     *
     * @param {number} transportId ID of transport
     * @param {object} payload POST Payload
     * @param {string} payload.signatureImageUuid file uuid uploaded image of the signature
     * @param {string} payload.signatureUserName name of the person that did the signature
     * @param {string[]} payload.additionalSignatureDocumentUuids array of additional documents uuids
     * @param {string[]} payload.additionalSignatureImageUuids array of additional images uuids
     * @param {string} payload.serviceDate date string (with timezone) of service date
     */
    async recordUnloadingSignature(
        transportId,
        {
            signatureImageUuid,
            signatureUserName,
            additionalSignatureDocumentUuids,
            additionalSignatureImageUuids,
            serviceDate,
        }
    ) {
        /**
         * Centralized error handling
         */
        const url =
            this.context === CONTEXT_CARRIER_DRIVER
                ? `v1${this.resourcePath}/${transportId}/signature/unloading`
                : `v1${this.resourcePath}/${transportId}/capture-by-signature`;
        try {
            const response = await this.apiService.post(url, {
                signatureImageUuid,
                signatureUserName,
                serviceDate,
                additionalSignatureImageUuids,
                additionalSignatureDocumentUuids,
            });
            return response.data;
        } catch (err) {
            const error = this.handleError(err);

            logger.error(error);
            Toaster.error((i18n.te(error.translationKey) && i18n.t(error.translationKey)) || error.message || error);

            throw error;
        }
    }

    /**
     * @typedef {object} CheckWeighingNoteDuplicateReturn
     * @property {boolean} isDuplicate
     */

    /**
     * Check whether a weighing note is a potential duplicate
     *
     * @param {object} args
     * @param {string} args.weighingNumber weighingNote number
     * @param {number} args.weight weight in kg
     * @param {Date} args.weighingDate date of the weighing, as Date Object
     * @param {AbortSignal} [args.signal] abort signal of an `AbortController`
     * @returns {Promise<CheckWeighingNoteDuplicateReturn>}
     */
    async checkWeighingNoteDuplicate({ weighingNumber, weight, weighingDate, signal }) {
        const url = `${this.resourcePath}/validation/weighingnote`;
        try {
            const response = await this.apiService.get(url, {
                params: {
                    number: weighingNumber,
                    weight,
                    datetime: weighingDate.toISOString(),
                },
                signal,
            });
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Persist user's reaction to the warning from duplicate weighing note check
     *
     * @param {object} args
     * @param {number} args.transportId the id of the transport
     * @param {'no duplicate' | 'ignored' | 'corrected'} args.status whether a duplication warning was shown to the user and how he reacted to it.
     * @returns {Promise<void>}
     */
    async submitWeighingNoteDuplicateStatus({ transportId, status }) {
        const url = `v1${this.resourcePath}/${transportId}/weighingNote/is-duplicate`;
        try {
            await this.apiService.post(url, {
                status,
            });
        } catch (err) {
            throw this.handleError(err);
        }
    }

    /**
     * Persist the app that was used to capture the weighing note
     *
     * @param {object} args
     * @param {number} args.transportId the id of the transport
     * @param {import('../../pages/Transport/Actions/components/useCameraAppUsed').CameraAppUsedType} args.type the app that was used to capture the weighing note
     * @returns {Promise<void>}
     */
    async recordCameraAppUsed({ transportId, type }) {
        const url = `v1${this.resourcePath}/${transportId}/camera-app-used/${type}`;
        try {
            await this.apiService.post(url);
        } catch (err) {
            throw this.handleError(err);
        }
    }

    getContextForResourcePath() {
        switch (this.context) {
            case CONTEXT_PLATFORM:
            case CONTEXT_CARRIER:
            case CONTEXT_SUPPLIER:
            case CONTEXT_CLIENT:
                return this.context;
            case CONTEXT_CARRIER_DRIVER:
                return 'carrier-driver';
            default:
                return '';
        }
    }
}

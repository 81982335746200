<template>
    <FormWrapper data-test="factory-weighing-types">
        <template #title>
            {{ $t('components.factoryManagement.factoryWeighingTypes.title') }}
        </template>
        <ContentWrapper>
            <!-- General Weighing Types -->
            <FormFieldGroup>
                <template #title>
                    <div class="font-copy-md-strong">
                        {{ $t('components.factoryManagement.factoryWeighingTypes.product.weighingTypeTitle') }}
                    </div>
                </template>
                <ComponentWrapper>
                    <template #componentText>
                        <p class="font-copy-md">
                            {{ $t('components.factoryManagement.factoryWeighingTypes.product.weighingTypeText') }}
                        </p>
                    </template>
                    <template #component>
                        <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
                            <div v-for="weighingType in weighingTypes" :key="`product_${weighingType.type}`">
                                <RadioButtonCard
                                    v-model="form.weighingTransmissionType"
                                    :value="weighingType.type"
                                    :name="`product_${$t(weighingType.title)}`"
                                    :data-test="`radio-card-entry-${weighingType.type}`"
                                    :is-disabled="automaticWeighingDisabled(weighingType.type) || !hasSelling"
                                    :is-read-only="isReadOnly"
                                    :title="$t(weighingType.title)"
                                    :description="$t(weighingType.description)"
                                    @change="update('weighingTransmissionType', $event)"
                                />
                                <SfLink
                                    v-if="automaticWeighingDisabled(weighingType.type)"
                                    class="ml-auto mt-2 !flex justify-end"
                                    bold
                                    @click="openContactFlyout"
                                >
                                    {{ $t('components.factoryManagement.factoryWeighingTypes.cards.notUnlocked') }}
                                </SfLink>
                            </div>
                        </div>
                    </template>
                </ComponentWrapper>
            </FormFieldGroup>

            <!-- Disposal Weighing Types -->
            <FormFieldGroup v-if="hasDisposal">
                <template #title>
                    <div class="font-copy-md-strong">
                        {{ $t('components.factoryManagement.factoryWeighingTypes.disposal.weighingTypeTitle') }}
                    </div>
                </template>
                <ComponentWrapper>
                    <template #componentText>
                        <p class="font-copy-md">
                            {{ $t('components.factoryManagement.factoryWeighingTypes.disposal.weighingTypeText') }}
                        </p>
                    </template>
                    <template #component>
                        <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
                            <div v-for="weighingType in disposalWeighingTypes" :key="`disposal_${weighingType.type}`">
                                <RadioButtonCard
                                    v-model="form.disposalWeighingTransmissionType"
                                    :value="weighingType.type"
                                    :name="`disposal_${$t(weighingType.title)}`"
                                    :data-test="`radio-card-entry-disposal-${weighingType.type}`"
                                    :is-disabled="automaticWeighingDisabled(weighingType.type) || !hasDisposal"
                                    :is-read-only="isReadOnly"
                                    :title="$t(weighingType.title)"
                                    :description="$t(weighingType.description)"
                                    @change="update('disposalWeighingTransmissionType', $event)"
                                />
                                <SfLink
                                    v-if="automaticWeighingDisabled(weighingType.type)"
                                    class="ml-auto mt-2 !flex justify-end"
                                    bold
                                    @click="openContactFlyout"
                                >
                                    {{
                                        $t(
                                            'components.factoryManagement.factoryWeighingTypes.disposal.cards.notUnlocked'
                                        )
                                    }}
                                </SfLink>
                            </div>
                        </div>
                        <template v-for="weighingType in disposalWeighingTypes">
                            <template v-for="weighingMethod in weighingType.weighingMethods">
                                <div
                                    :key="`disposal_${weighingType.type}_${weighingMethod.type}`"
                                    class="invisible absolute hidden"
                                >
                                    <input
                                        v-model="disposalWeighingMethod"
                                        type="radio"
                                        :name="`disposal_${weighingType.type}_${weighingMethod.type}`"
                                        :value="weighingMethod.type"
                                        @change="update('disposalWeighingMethod', $event.target.value)"
                                    />
                                </div>
                            </template>
                        </template>
                        <SfCheckboxSimple
                            v-model="disposalWeighingCapture"
                            class="mt-4"
                            name="disposal_weighing-capture"
                            value="driverByPhoto"
                            size="sm"
                            disabled
                            :headline="
                                $t(
                                    'components.factoryManagement.factoryWeighingTypes.disposal.capture.driverByPhoto.headline'
                                )
                            "
                            :description="
                                $t(
                                    'components.factoryManagement.factoryWeighingTypes.disposal.capture.driverByPhoto.description'
                                )
                            "
                        />
                    </template>
                </ComponentWrapper>
            </FormFieldGroup>
        </ContentWrapper>
    </FormWrapper>
</template>

<script>
import validate from '@/services/validation/mixin';
import sectionResetMixin from '@/plugins/mixins/factoryForm/sectionResetMixin';
import sectionMixin from '@/plugins/mixins/factoryForm/sectionMixin';
import { weighingTransmissionTypes } from '@/constants/weighingTransmissionTypes';
import { weighingMethods } from '@/constants/weighingMethods';

import { FACTORY_TYPES, USAGES } from '@/constants/disposal';

import ComponentWrapper from '@/_components/ComponentWrapper/ComponentWrapper';
import ContentWrapper from '@/_components/ContentWrapper/ContentWrapper';
import FormFieldGroup from '@/_components/FormFieldGroup/FormFieldGroup';
import FormWrapper from '@/_components/FormWrapper/FormWrapper';
import RadioButtonCard from '@/_components/RadioButtonCard/RadioButtonCard';

import { SfCheckboxSimple, SfLink } from '@schuettflix/vue-components';

export default {
    name: 'FactoryWeighingTypes',
    components: {
        ComponentWrapper,
        ContentWrapper,
        FormFieldGroup,
        FormWrapper,
        RadioButtonCard,

        // components
        SfCheckboxSimple,
        SfLink,
    },
    mixins: [validate, sectionResetMixin, sectionMixin],
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        features: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        usages: {
            type: Array,
            default: () => [],
        },
        disposalSettings: {
            type: Object,
            default: () => ({}),
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            weighingTypes: [
                {
                    type: weighingTransmissionTypes.MANUAL,
                    title: 'components.factoryManagement.factoryWeighingTypes.card.manual.title',
                    description: 'components.factoryManagement.factoryWeighingTypes.card.manual.description',
                },
                {
                    type: weighingTransmissionTypes.AUTOMATIC,
                    title: 'components.factoryManagement.factoryWeighingTypes.card.automatic.title',
                    description: 'components.factoryManagement.factoryWeighingTypes.card.automatic.description',
                },
            ],
            disposalWeighingTypes: [],
            disposalWeighingCapture: ['driverByPhoto'],
            disposalWeighingMethod: null,
        };
    },
    computed: {
        hasDisposal() {
            return this.features?.supplier_disposal?.active && this.usages?.includes(USAGES.DISPOSAL);
        },
        hasSelling() {
            return (
                (this.features?.supplier_delivery?.active || this.features?.supplier_pickup?.active) &&
                this.usages.includes(USAGES.SELLING)
            );
        },
    },
    watch: {
        value: {
            handler(data) {
                this.sectionDataBackupOpenStatusReset(data.open);
            },
        },
        disposalWeighingTypes: {
            deep: true,
            immediate: true,
            handler() {
                this.checkDisposalWeighingMethod();
            },
        },
        form: {
            deep: true,
            immediate: true,
            handler(sectionData) {
                if (!this.sectionDataBackup) {
                    this.checkDisposalWeighingMethod();
                    return;
                }

                this.checkDisposalWeighingMethod();

                this.$emit('dirty-check', this.checkDirty(sectionData, this.sectionDataBackup));
                this.$emit('confirmation-dialog', null);
                this.$emit('valid-check', true);
            },
        },
    },
    mounted() {
        this.setDisposalWeighingTypes();
    },
    methods: {
        setDisposalWeighingTypes() {
            const disposalWeighingTypes = [
                {
                    type: weighingTransmissionTypes.MANUAL,
                    title: 'components.factoryManagement.factoryWeighingTypes.disposal.card.manual.title',
                    description: 'components.factoryManagement.factoryWeighingTypes.disposal.card.manual.description',
                    weighingMethods: [
                        {
                            type: weighingMethods.EMPTY_FULL,
                            preselected: true,
                            title: 'components.factoryManagement.factoryWeighingMethods.disposal.card.emptyFull.title',
                            description:
                                'components.factoryManagement.factoryWeighingMethods.disposal.card.emptyFull.description',
                        },
                    ],
                },
                {
                    type: weighingTransmissionTypes.AUTOMATIC,
                    title: 'components.factoryManagement.factoryWeighingTypes.disposal.card.automatic.title',
                    description:
                        'components.factoryManagement.factoryWeighingTypes.disposal.card.automatic.description',
                },
            ];

            if (this.disposalSettings?.factoryType === FACTORY_TYPES.DUMPING_SITE) {
                disposalWeighingTypes.unshift({
                    type: weighingTransmissionTypes.NONE,
                    title: 'components.factoryManagement.factoryWeighingTypes.disposal.card.none.title',
                    description: 'components.factoryManagement.factoryWeighingTypes.disposal.card.none.description',
                });
            }

            this.disposalWeighingTypes = disposalWeighingTypes;
        },
        checkDisposalWeighingMethod() {
            let update = false;
            if (
                !this.form.disposalWeighingMethod &&
                this.form.disposalWeighingTransmissionType === weighingTransmissionTypes.MANUAL
            ) {
                this.disposalWeighingMethod = weighingMethods.EMPTY_FULL;
                update = true;
            } else if (this.form.disposalWeighingTransmissionType !== weighingTransmissionTypes.MANUAL) {
                this.disposalWeighingMethod = null;
                update = true;
            } else {
                this.disposalWeighingMethod = this.form.disposalWeighingMethod;
            }

            if (update && this.form.disposalWeighingMethod !== this.disposalWeighingMethod)
                this.update('disposalWeighingMethod', this.disposalWeighingMethod);
        },
        automaticWeighingDisabled(weighingType) {
            return (
                !this.features?.supplier_automatic_weighing?.active &&
                weighingType === weighingTransmissionTypes.AUTOMATIC
            );
        },
        openContactFlyout() {
            this.$eventHub.$emit('page.platformContact', true);
        },
    },
};
</script>

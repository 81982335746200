import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import isEqual from 'lodash/isEqual';

const DEFAULT_PLANNING_INTERVAL = 15;

const initialState = () => ({
    constructionSite: null,
    product: null,
    disposalProduct: null,
    disposalProductVariant: null,
    additionalInformation: null,
    transports: null,
    qty: null,
    deliveryMethod: null,
    availableShippingMethods: null,
    quote: null,
    confirmedQuote: null,
    type: null,
    isCustom: false,
    supplierInfo: null,
    categoryInfo: null,
    clientInfo: null,
    carrierInfo: null,
    serverError: null,
    isPlanningAssistantActive: false,
    shipmentLoading: null,
    shipmentUnloading: null,
    shipmentQtyAndDocument: null,
    disposalLoading: null,
    disposalQtyAndDocument: null,
    shipmentVehicleClassId: null,
    disposalVehicleClassId: null,
    shipmentBillingDetails: null,
    planningInterval: DEFAULT_PLANNING_INTERVAL,
    planningMethod: null,
    shipmentShippingWindow: null,
    disposalShippingWindow: null,
    shipmentPlanningConfig: null,
    timeConstraint: null,
    projectId: null,
    pricesData: null,
    originalPricesData: null,
    projectPosition: null,
    projectPositionSelectionErrors: null,
    costCenter: null,
    disposalPrice: null,
    exemptionTranslation: null,
    validityRange: null,
    status: null,
    defaultPaymentTerms: null,
    paymentTerms: null,
    selectedSupplier: null,
    isSellerSelected: false,
    searchTerm: null,
    searchType: null,
});

/**
 * Check if products are the same
 * @param state
 * @param product
 */
const resetProductIfDifferent = (state, product) => {
    if (
        state?.product?.masterProductId === product.masterProductId &&
        isEqual(state?.product?.attributeRange, product.attributeRange) &&
        isEqual(state?.product?.attributeIds, product.attributeIds)
    ) {
        return;
    }

    state.transports = null;
    state.deliveryMethod = null;
    state.qty = null;
    state.supplierInfo = null;
    state.isPlanningAssistantActive = false;
    state.pricesData = null;
};

const mutations = {
    init(state, { type, isCustom = false }) {
        state.type = type;
        state.isCustom = isCustom;
        state.isPlanningAssistantActive = false;
    },
    setConstructionSite(state, constructionSite) {
        if (state.constructionSite && state.constructionSite.id !== constructionSite.id) {
            state.product = null;
            state.disposalProduct = null;
            state.disposalProductVariant = null;
            state.transports = null;
            state.qty = null;
            state.additionalInformation = null;
            state.supplierInfo = null;
            state.categoryInfo = null;
            state.isPlanningAssistantActive = false;
            state.pricesData = null;
        }
        state.constructionSite = constructionSite;
    },
    setProduct(state, product) {
        resetProductIfDifferent(state, product);

        state.product = {
            ...(state.product || {}),
            ...product,
        };
    },
    updateProductFields(state, fields) {
        state.product = {
            ...(state.product || {}),
            ...fields,
        };
    },
    setProductRestrictions(state, payload) {
        state.product = {
            ...(state.product || {}),
            minimumQty: payload.minimumAmount,
            maximumQty: payload.maximumAmount,
        };
    },
    setSearchTerm(state, payload) {
        state.searchTerm = payload;
    },
    setSearchType(state, payload) {
        state.searchType = payload;
    },

    /**
     * @param state
     * @param {Object} product
     */
    setProductDefinition(state, product) {
        resetProductIfDifferent(state, product);
        state.product = product;
    },
    setAdditionalInformation(state, information) {
        state.additionalInformation = information;
    },
    setTransports(state, transports) {
        state.transports = transports;
    },
    setDeliveryMethod(state, deliveryMethod) {
        if (deliveryMethod?.name !== state.deliveryMethod?.name) {
            state.timeConstraint = null;
        }

        state.deliveryMethod = deliveryMethod;
    },
    setAvailableShippingMethods(state, availableShippingMethods) {
        state.availableShippingMethods = availableShippingMethods;
    },
    setDeliveryWindow(state, deliveryWindow) {
        state.deliveryMethod.deliveryWindow = deliveryWindow;
    },
    setQuote(state, quote) {
        state.quote = quote;
    },
    setConfirmedQuote(state, confirmedQuote) {
        state.confirmedQuote = confirmedQuote;
    },
    setCostCenter(state, costCenter) {
        state.costCenter = costCenter;
    },
    setDisposalPrice(state, disposalPrice) {
        state.disposalPrice = disposalPrice;
    },
    reset(state) {
        Object.assign(state, initialState());
    },

    setTransportError(state, { index, error }) {
        state.transports.transports[index].error = error;
    },
    resetTransportError(state, index) {
        state.transports.transports[index].error = null;
    },

    setQty(state, qty) {
        state.qty = qty;
    },

    setSellerSelected(state) {
        state.isSellerSelected = true;
    },

    unsetSellerSelected(state) {
        state.isSellerSelected = false;
    },

    setSupplierInfo(state, supplierInfo) {
        state.supplierInfo = supplierInfo;
    },

    setCategoryInfo(state, categoryInfo) {
        state.categoryInfo = categoryInfo;
    },

    setServerError(state, error) {
        state.serverError = error;
    },

    setIsPlanningAssistantActive(state, value) {
        state.isPlanningAssistantActive = value;
    },

    setClientInfo(state, value) {
        state.clientInfo = value;
    },

    setCarrierInfo(state, value) {
        state.carrierInfo = value;
    },

    setShipmentLoading(state, payload) {
        // reset planned transports and shipping window
        state.shipmentShippingWindow = null;
        state.transports = null;

        state.shipmentLoading = payload;
    },

    setShipmentUnloading(state, payload) {
        // reset planned transports and shipping window
        state.shipmentShippingWindow = null;
        state.transports = null;

        state.shipmentUnloading = payload;
    },

    setShipmentQtyAndDocument(state, payload) {
        state.shipmentQtyAndDocument = payload;
    },

    setDisposalQtyAndDocument(state, payload) {
        state.disposalQtyAndDocument = payload;
    },

    setShipmentVehicleClassId(state, payload) {
        state.shipmentVehicleClassId = payload;
    },

    setDisposalVehicleClassId(state, payload) {
        state.disposalVehicleClassId = payload;
    },

    setShipmentBillingDetails(state, payload) {
        state.shipmentBillingDetails = payload;
    },

    setShipmentShippingWindow(state, payload) {
        state.shipmentShippingWindow = payload;
    },
    setDisposalShippingWindow(state, payload) {
        state.disposalShippingWindow = payload;
    },

    setPlanningInterval(state, value) {
        state.planningInterval = value || DEFAULT_PLANNING_INTERVAL;
    },
    setPlanningMethod(state, payload) {
        // reset planned transports and shipping window if changed
        if (state.planningMethod !== payload) {
            state.shipmentShippingWindow = null;
            state.disposalShippingWindow = null;
            state.transports = null;
            state.timeConstraint = null;
        }

        state.planningMethod = payload;
    },

    setShipmentPlanningConfig(state, payload) {
        state.shipmentPlanningConfig = payload;
    },

    setTimeConstraint(state, payload) {
        state.timeConstraint = {
            startTime: payload?.startTime ?? null,
            endTime: payload?.endTime ?? null,
        };
    },

    setProjectId(state, value) {
        state.projectId = value;
    },

    setPricesData(state, value) {
        state.pricesData = value;
    },

    setOriginalPricesData(state, value) {
        state.originalPricesData = value;
    },

    /**
     * Set delivery project position
     * @param {object} state
     * @param {ProjectPositionView} projectPosition
     */
    setProjectPositionDelivery(state, projectPosition) {
        const unfoldedProjectPosition = projectPosition.unfold();

        state.projectPosition = unfoldedProjectPosition;
        state.projectId = projectPosition.project.id;

        state.constructionSite = unfoldedProjectPosition.constructionSite;
        state.product = unfoldedProjectPosition.product;

        state.transports = null;
        state.qty = null;
    },

    /**
     * Set shipment project position
     * @param {object} state
     * @param {ProjectPositionView} projectPosition
     */
    setProjectPositionShipment(state, projectPosition) {
        const unfoldedProjectPosition = projectPosition.unfold();

        state.projectPosition = unfoldedProjectPosition;
        state.projectId = projectPosition.project.id;

        state.shipmentLoading = { address: projectPosition.loadingAddress.unfold() };
        state.shipmentUnloading = { address: projectPosition.unloadingAddress.unfold() };

        state.transports = null;
        state.qty = null;
    },

    /**
     * Set disposal project position
     * @param {object} state
     * @param {ProjectPositionView} projectPosition
     */
    setProjectPositionDisposal(state, projectPosition) {
        const unfoldedProjectPosition = projectPosition.unfold();

        state.projectPosition = unfoldedProjectPosition;
        state.projectId = projectPosition.project.id;

        state.constructionSite = unfoldedProjectPosition.constructionSite;
        state.disposalProduct = unfoldedProjectPosition.disposalProduct;
        state.disposalProductVariant = unfoldedProjectPosition.disposalProductVariant;
        state.disposalLoading = { address: projectPosition.loadingAddress.unfold() };

        state.transports = null;
        state.qty = null;
    },

    setProjectPositionValidityRange(state, payload) {
        state.validityRange = payload;
    },

    setProjectPositionStatus(state, payload) {
        state.status = payload;
    },

    setProjectPositionSelectionErrors(state, payload) {
        state.projectPositionSelectionErrors = payload;
    },
    SET_PROJECT_ORDER_EXEMPTION_TRANSLATION(state, translation) {
        state.exemptionTranslation = translation;
    },

    SET_PAYMENT_TERMS(state, paymentTerms) {
        state.paymentTerms = paymentTerms;
    },

    SET_DEFAULT_PAYMENT_TERMS(state, defaultPaymentTerms) {
        state.defaultPaymentTerms = defaultPaymentTerms;
    },

    RESET_PAYMENT_TERMS(state) {
        state.paymentTerms = state.defaultPaymentTerms;
    },
};

const getters = {
    allowedMinuteSteps: state => Math.min(state.planningInterval, DEFAULT_PLANNING_INTERVAL),
    baseType: state => state.type?.replace('project-position-', ''),
    isDisposal: state =>
        state.type === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS ||
        state.type === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS ||
        state.type === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DISPOSAL_HAZARDOUS ||
        state.type === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DISPOSAL_NON_HAZARDOUS,
};

const actions = {
    /**
     * Initialize checkout
     *
     * @param commit
     * @param type
     * @param isCustom
     * @param project
     */
    init({ commit, rootGetters }, { type, isCustom = false, project = null }) {
        commit('reset');
        commit('init', { type, isCustom });

        // preselect data
        if (type === 'shipment' && !isCustom) {
            commit('setClientInfo', {
                client: rootGetters['user/organization'],
                purchaserId: rootGetters['user/id'],
            });
        }

        // init for project position
        if (
            project &&
            [
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DELIVERY,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_SHIPMENT,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS,
                BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS,
            ].includes(type)
        ) {
            commit('setProjectId', project.id);
            commit('setClientInfo', {
                client: project.client.unfold(),
            });
        }
    },

    reset({ state, dispatch }) {
        const { type, isCustom } = state;

        // type project-delivery
        const project = state.confirmedQuote?.project ?? null;

        dispatch('init', { type, isCustom, project });
    },

    // Required for confirmation page and continuous checkout experience?!?!
    resetAndSetConfirmedQuote({ commit, dispatch }, confirmedQuote) {
        dispatch('reset');
        commit('setConfirmedQuote', confirmedQuote);
    },

    requestPlanningAssistance({ commit, state }) {
        AnalyticsService.trackEvent(`checkout_${state.type}`, {
            step: 'planningAssistant',
            type: 'open',
        });

        commit('setIsPlanningAssistantActive', true);
    },

    discardPlanningAssistance({ commit, state }) {
        AnalyticsService.trackEvent(`checkout_${state.type}`, {
            step: 'planningAssistant',
            type: 'close',
        });

        commit('setIsPlanningAssistantActive', false);
    },
};

export default {
    namespaced: true,
    state: initialState(),
    getters,
    mutations,
    actions,
};

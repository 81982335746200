import ProjectPositionApi, { ProjectPosition } from '@/services/Api/ProjectPosition';
import { listGenericPositions, } from '@/modules/constructionProjects/api/admin/listGenericPositions';
import FilterResult from '@/services/Api/FilterResult';
import { processParams } from '@/services/Api/AbstractFilterableResource';
export class ProjectPositionWithGenericPositionsApi extends ProjectPosition {
    constructor() {
        super(ProjectPositionApi.resourcePath);
    }
    async filter(params = {}, facetFilterKey = null, facetFilterValues = [], cancelSource = null, forcedParams = {}, silence = false) {
        const [result, genericPositionsResult] = await Promise.all([
            super.filter(params, facetFilterKey, facetFilterValues, cancelSource, forcedParams, silence),
            listGenericPositions(this.convertFilterParamsToCriteria(params, forcedParams)),
        ]);
        return this.createMergedResults(result, genericPositionsResult);
    }
    convertFilterParamsToCriteria(params, forcedParams) {
        // @ts-expect-error processParams is not properly typed
        const mergedParams = processParams(params, forcedParams);
        const statusFilterValue = mergedParams.status;
        const filter = {
            page: params.page ?? 1,
            perPage: params.perPage ?? 25,
            sortBy: 'positionNumber',
            sortDirection: params.sortDirection ?? 'desc',
            constructionProjectId: mergedParams.constructionProject,
        };
        if (statusFilterValue === 'open') {
            return {
                ...filter,
                status: 'OPEN',
                active: 1,
            };
        }
        if (statusFilterValue === 'closed') {
            return {
                ...filter,
                status: 'CLOSED',
            };
        }
        return {
            ...filter,
            status: 'OPEN',
            active: 0,
        };
    }
    createMergedResults(result, genericPositionsResult) {
        const items = result.responseData.items.concat(genericPositionsResult.positions);
        const sortBy = result.appliedFilter.sortBy;
        const sortDirection = result.appliedFilter.sortDirection;
        if (sortBy === 'number') {
            items.sort((a, z) => {
                const aNumber = a.number || a.positionNumber;
                const zNumber = z.number || z.positionNumber;
                return sortDirection === 'asc' ? aNumber.localeCompare(zNumber) : zNumber.localeCompare(aNumber);
            });
        }
        return new FilterResult({
            ...result.responseData,
            count: result.responseData.count + genericPositionsResult.total,
            items: items,
        }, result.requestedFilter, result.requestedForcedFilter);
    }
}

// Use this mixin to declare/perform stateful actions
import { asyncDelay } from '@/services/utils';
import { ref } from 'vue';
import _without from 'lodash/without';
/**
 * Stateful actions
 *
 * Track async actions, keep track of open actions and prevent multiple executions
 */
export function useStateful() {
    const statefulList = ref([]);
    /**
     * Check if the current state is pending
     * Example arguments: [AND AND AND] or [AND AND AND]
     */
    const isPending = (...conditions) => {
        // check all pending actions
        if (conditions === undefined || conditions.length === 0) {
            return statefulList.value.length > 0;
        }
        return conditions.some(curr => {
            if (!Array.isArray(curr)) {
                curr = [curr];
            }
            return curr.every(i => statefulList.value.includes(i));
        });
    };
    /**
     * Start state tracking
     */
    const startStateTracking = (stateKey) => {
        if (statefulList.value.includes(stateKey)) {
            return false;
        }
        statefulList.value = [...statefulList.value, stateKey];
        return true;
    };
    /**
     * Stop state tracking
     */
    const stopStateTracking = (stateKey) => {
        const index = statefulList.value.indexOf(stateKey);
        if (index === -1) {
            return false;
        }
        statefulList.value = _without(statefulList.value, stateKey);
        return true;
    };
    /**
     * Attempt to perform a stateful interaction, if statelist is empty
     */
    const stateful = async (stateKey, fn) => {
        if (isPending())
            return;
        startStateTracking(stateKey);
        const result = await fn();
        stopStateTracking(stateKey);
        return result;
    };
    /**
     * Perform a stateful interaction, ignore current statelist
     * @param {string} stateKey
     * @param {function} fn
     * @return {Promise<*>}
     */
    const statefulRun = async (stateKey, fn) => {
        startStateTracking(stateKey);
        const result = await fn();
        stopStateTracking(stateKey);
        return result;
    };
    /**
     * Insert busy stateKey for given delay in ms
     * @param stateKey
     * @param delayMS
     */
    async function blockState(stateKey, delayMS = 1000) {
        await stateful(stateKey, async () => {
            await asyncDelay(delayMS);
        });
    }
    return {
        blockState,
        stateful,
        statefulRun,
        isPending,
    };
}

import AbstractFilterableResource from '../AbstractFilterableResource';

class Product extends AbstractFilterableResource {
    async createSellerProduct(factoryId, data) {
        const response = await this.apiService.post(`/v2/factories/${factoryId}/seller-products`, data);

        return response.data;
    }

    async getSellerProductList(factoryId, page = 1) {
        const response = await this.apiService.get(`/v1/factories/${factoryId}/seller-products?page=${page}`);

        return response.data;
    }

    async getSellerProduct(productId) {
        const response = await this.apiService.get(`/v2/seller-products/${productId}`);

        return response.data;
    }

    async updateSellerProduct(productId, data) {
        const response = await this.apiService.patch(`/v1/seller-products/${productId}`, data);

        return response.data;
    }

    deleteProduct(productId) {
        return this.apiService.delete(`/v1/seller-products/${productId}`);
    }
}

export default new Product('/supplier/product');

export const dateTimeFormats = {
    de: {
        shortDateTime: {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
        },
        short: {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        },
        shortWithWeekday: {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            weekday: 'long',
        },
        shortest: {
            month: '2-digit',
            day: '2-digit',
        },
        time: {
            hour: 'numeric',
            minute: 'numeric',
        },
        hour: {
            hour: 'numeric',
        },
        minutes: {
            minute: 'numeric',
        },
        medium: {
            year: '2-digit',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        },
        long: {
            year: '2-digit',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
        },
        calendar: {
            month: 'long',
            year: 'numeric',
        },
    },
    en: {
        shortDateTime: {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
        },
        short: {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        },
        shortWithWeekday: {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            weekday: 'long',
        },
        shortest: {
            month: '2-digit',
            day: '2-digit',
        },
        time: {
            hour: 'numeric',
            minute: 'numeric',
        },
        hour: {
            hour: 'numeric',
        },
        minutes: {
            minute: 'numeric',
        },
        medium: {
            year: '2-digit',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        },
        long: {
            year: '2-digit',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
        },
        calendar: {
            month: 'long',
            year: 'numeric',
        },
    },
    cs: {
        shortDateTime: {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
        },
        short: {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        },
        shortWithWeekday: {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            weekday: 'long',
        },
        shortest: {
            month: '2-digit',
            day: '2-digit',
        },
        time: {
            hour: 'numeric',
            minute: 'numeric',
        },
        hour: {
            hour: 'numeric',
        },
        minutes: {
            minute: 'numeric',
        },
        medium: {
            year: '2-digit',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        },
        long: {
            year: '2-digit',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
        },
        calendar: {
            month: 'long',
            year: 'numeric',
        },
    },
    pl: {
        shortDateTime: {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
        },
        short: {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        },
        shortWithWeekday: {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            weekday: 'long',
        },
        shortest: {
            month: '2-digit',
            day: '2-digit',
        },
        time: {
            hour: 'numeric',
            minute: 'numeric',
        },
        hour: {
            hour: 'numeric',
        },
        minutes: {
            minute: 'numeric',
        },
        medium: {
            year: '2-digit',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        },
        long: {
            year: '2-digit',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
        },
        calendar: {
            month: 'long',
            year: 'numeric',
        },
    },
    uk: {
        shortDateTime: {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
        },
        short: {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        },
        shortWithWeekday: {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            weekday: 'long',
        },
        shortest: {
            month: '2-digit',
            day: '2-digit',
        },
        time: {
            hour: 'numeric',
            minute: 'numeric',
        },
        hour: {
            hour: 'numeric',
        },
        minutes: {
            minute: 'numeric',
        },
        medium: {
            year: '2-digit',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        },
        long: {
            year: '2-digit',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
        },
        calendar: {
            month: 'long',
            year: 'numeric',
        },
    },
};
export const numberFormats = {
    de: {
        currency: {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'narrowSymbol',
        },
        currencyNoCents: {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'narrowSymbol',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        },
        currency_signless: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
        currency_long: {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'narrowSymbol',
            minimumFractionDigits: 2,
            maximumFractionDigits: 10,
        },
        float: {
            style: 'decimal',
        },
        floatPrice: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 3,
        },
        weight: {
            style: 'decimal',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        },
        weight_precise: {
            style: 'decimal',
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
        },
        qty: {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 3,
        },
        distance: {
            style: 'decimal',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        },
        volume: {
            style: 'decimal',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        },
    },
    en: {
        currency: {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'narrowSymbol',
        },
        currency_signless: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
        float: {
            style: 'decimal',
        },
        floatPrice: {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 3,
        },
        weight: {
            style: 'decimal',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        },
        volume: {
            style: 'decimal',
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
        },
    },
};
// NOTE: workaround for the i18n library 💩
export function getNumberFormatsForCurrency(currency, numberFormatsBase) {
    const nextNumberFormats = {};
    for (const locale in numberFormatsBase) {
        nextNumberFormats[locale] = {};
        for (const numberFormatKey in numberFormatsBase[locale]) {
            nextNumberFormats[locale][numberFormatKey] = {
                ...numberFormatsBase[locale][numberFormatKey],
            };
            if (nextNumberFormats[locale][numberFormatKey].currency) {
                nextNumberFormats[locale][numberFormatKey].currency = currency;
            }
        }
    }
    return nextNumberFormats;
}

import { ensureJSTimestamp } from '@/services/utils/date';

/**
 * Class representing document view model
 */
export default class DocumentView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     */
    static create(data) {
        const document = new DocumentView();

        document.uuid = data.uuid;
        document.url = data.url;
        document.created = data.created;

        if (data.fileName) {
            document.fileName = data.fileName;
        }

        return document;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._uuid = null;
        this._url = null;
        this._fileName = null;
        this._created = null;
    }

    /**
     * Get uuid
     * @returns {string}
     */
    get uuid() {
        return this._uuid;
    }

    /**
     * Sett uuid
     * @param {string} value
     */
    set uuid(value) {
        this._uuid = value ?? null;
    }

    /**
     * Get url
     * @returns {object|null}
     */
    get url() {
        return this._url;
    }

    /**
     * Set url
     * @param {object} value
     */
    set url(value) {
        this._url = value ?? null;
    }

    /**
     * Get fileName
     * @returns {string}
     */
    get fileName() {
        return this._fileName;
    }

    /**
     * Set fileName
     * @param {string} value
     */
    set fileName(value) {
        this._fileName = value ?? null;
    }

    /**
     * Get created timestamp
     * @return {number}
     */
    get created() {
        return ensureJSTimestamp(this._created);
    }

    /**
     * Set created timestamp
     * @param {number} value
     */
    set created(value) {
        this._created = value ?? null;
    }
}

<template>
    <div v-if="showComponent" class="transport-list-block-shipping-dates">
        <TransportDate
            v-if="transport.planningBase && showPlannedDateAndTime()"
            :label="planningBaseLabel"
            :date-from="transport.shippingDate"
            is-warning
        />
        <template v-else-if="transport.type === TRANSPORT_TYPE.SHIPMENT">
            <TransportDate
                v-for="(site, siteType) in sites()"
                :key="siteType"
                class="mt-8 first:mt-0"
                :label="siteTypeLabel(siteType)"
                :date-from="site.dateFrom"
                :date-to="site.dateTo ? site.dateTo : 0"
                :time-from="site.timeFrom ? site.timeFrom : 0"
                :time-to="site.timeTo ? site.timeTo : 0"
            />
        </template>

        <template v-else-if="showPickupExpiryDate()">
            <TransportDate
                v-if="transport.shippingMethod !== 'flix'"
                :label="$t(`components.transportListBlock.timerangeLabel.pickup`)"
                :date-from="pickupExpireDate()"
                :time-from="pickupExpireDate() ? pickupExpireDate() : 0"
            />
        </template>

        <template
            v-else-if="
                showShippingDates &&
                transport.type !== TRANSPORT_TYPE.PICKUP &&
                typeof transport.shippingMethod !== 'undefined'
            "
        >
            <!-- DATE RANGE -->
            <TransportDate
                v-if="transport.shippingMethod !== 'flix'"
                :label="shipmentMethodLabel"
                :date-from="transport.shippingWindowStart"
                :date-to="transport.shippingWindowEnd ? transport.shippingWindowEnd : 0"
                hide-time
            />
            <TransportDate v-else :label="shipmentMethodLabel" :date-from="transport.shippingDate" is-warning />
        </template>
    </div>
</template>

<script>
import { ensureJSTimestamp } from '@/services/utils/date';
import { statusIsBefore } from '@/services/utils/transport';
import { TRANSPORT_TYPE } from '@/constants/transportTypes';

import TransportDate from '../partials/TransportDate';

/**
 * !!! ATTENTION !!!
 * This component and all of it's children can not have computed
 * properties since they introduce a performance issues on unmounting
 */
export default {
    name: 'TransportListBlockShippingDates',
    components: {
        TransportDate,
    },
    props: {
        transport: {
            type: Object,
            required: true,
        },
        showLoading: {
            type: Boolean,
            default: false,
        },
        showUnloading: {
            type: Boolean,
            default: false,
        },
        showShippingDates: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            TRANSPORT_TYPE: TRANSPORT_TYPE,
            showComponent:
                (this.transport.planningBase && this.showPlannedDateAndTime()) ||
                (this.transport.type === TRANSPORT_TYPE.SHIPMENT && !!Object.keys(this.sites()).length) ||
                (this.showPickupExpiryDate() && this.transport.shippingMethod !== 'flix') ||
                (this.showShippingDates &&
                    this.transport.type !== TRANSPORT_TYPE.PICKUP &&
                    typeof this.transport.shippingMethod !== 'undefined'),
        };
    },

    computed: {
        planningBaseLabel() {
            return {
                loading: this.$t('components.transportListBlock.timerangeLabel.plannedFix.loading'),
                unloading: this.$t('components.transportListBlock.timerangeLabel.plannedFix.unloading'),
            }[this.transport.planningBase];
        },

        shipmentMethodLabel() {
            if (this.transport.type === 'disposal')
                return {
                    default: this.$t('components.transportListBlock.timerangeLabel.disposal.default'),
                    flix: this.$t('components.transportListBlock.timerangeLabel.disposal.flix'),
                }[this.transport.shippingMethod];

            return {
                default: this.$t('components.transportListBlock.timerangeLabel.default'),
                flix: this.$t('components.transportListBlock.timerangeLabel.flix'),
            }[this.transport.shippingMethod];
        },
    },

    // TransportListBlock and all of it's child components must be written without computed properties, since they introduce a huge performance impact on unmount on transport listings.

    methods: {
        ensureJSTimestamp,

        siteTypeLabel(siteType) {
            return {
                loading: this.$t('components.transportListBlock.timerangeLabel.loading'),
                unloading: this.$t('components.transportListBlock.timerangeLabel.unloading'),
            }[siteType];
        },

        sites() {
            const sites = {};
            if (this.showLoading) {
                sites.loading = this.calculateLoading();
            }
            if (this.showUnloading) {
                sites.unloading = this.calculateUnloading();
            }

            return sites;
        },

        pickupExpireDate() {
            return this.transport.expireDate || this.transport.lineItemGroupExpireTimestamp || null;
        },

        showPickupExpiryDate() {
            if (!this.showShippingDates || this.transport.type !== 'pickup') {
                return false;
            }
            return statusIsBefore(this.transport.status, 'confirmation_pending');
        },

        showPlannedDateAndTime() {
            return (
                (this.transport.planningBase === 'loading' && this.showLoading) ||
                (this.transport.planningBase === 'unloading' && this.showUnloading)
            );
        },

        calculateLoading() {
            return {
                dateFrom: ensureJSTimestamp(this.transport.loadingDateFrom),
                dateTo: ensureJSTimestamp(this.transport.loadingDateTo),
                timeFrom: this.transport.loadingTimeFrom || null,
                timeTo: this.transport.loadingTimeTo || null,
            };
        },

        calculateUnloading() {
            return {
                dateFrom: ensureJSTimestamp(this.transport.unloadingDateFrom),
                dateTo: ensureJSTimestamp(this.transport.unloadingDateTo),
                timeFrom: this.transport.unloadingTimeFrom || null,
                timeTo: this.transport.unloadingTimeTo || null,
            };
        },
    },
};
</script>

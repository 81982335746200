<template>
    <FlyoutPage class="credit-note-view">
        <template #header>
            <HeaderBar>
                <template #left>
                    <HeaderBarItem button @click="$root.routeBack()">
                        <ArrowIcon width="32" height="18" />
                    </HeaderBarItem>
                </template>

                <template #headline>
                    <div v-if="creditNoteId === 'created'">
                        {{
                            $t(
                                selectCreditNoteDocKey({
                                    creditNote: `pages.creditNoteDoc.view.pendingTitle.creditNote`,
                                    selfBilling: `pages.creditNoteDoc.view.pendingTitle.selfBilling`,
                                })
                            )
                        }}
                    </div>
                    <div v-else-if="creditNote">
                        <span v-if="creditNote.type === 'cancellation'">
                            {{ $t('pages.creditNoteDoc.view.cancellationTitle', { number: creditNote.number }) }}
                        </span>
                        <span v-else>{{
                            $t(
                                selectCreditNoteDocKey({
                                    creditNote: `pages.creditNoteDoc.view.title.creditNote`,
                                    selfBilling: `pages.creditNoteDoc.view.title.selfBilling`,
                                }),
                                { number: creditNote.number }
                            )
                        }}</span>
                    </div>
                </template>

                <template #subline>
                    <div v-if="creditNote">
                        <Words muted>
                            {{
                                $t('pages.creditNoteDoc.view.sublineCreated', {
                                    date: $d(creditNote.created * 1000, 'short'),
                                })
                            }}
                        </Words>
                    </div>
                </template>
            </HeaderBar>
        </template>

        <Tile v-if="creditNoteId === 'created'" no-border background-transparent class="credit-note-view__pending-info">
            <VoucherIcon width="100" height="100" />

            <Headline :level="3">{{
                $t(
                    selectCreditNoteDocKey({
                        creditNote: `pages.creditNoteDoc.view.pendingTitle.creditNote`,
                        selfBilling: `pages.creditNoteDoc.view.pendingTitle.selfBilling`,
                    })
                )
            }}</Headline>

            <i18n
                :path="
                    selectCreditNoteDocKey({
                        creditNote: `pages.creditNoteDoc.view.pendingMessage.creditNote`,
                        selfBilling: `pages.creditNoteDoc.view.pendingMessage.selfBilling`,
                    })
                "
                tag="div"
            >
                <Words place="name" bold>{{ $router.currentRoute.params.organizationName }}</Words>
            </i18n>

            <LoadingSpinner dark class="credit-note-view__pending-indicator" />
        </Tile>
        <div v-else-if="creditNote">
            <Tile v-if="creditNote.invoiceNumber" no-border>
                <Words bold block spaced>
                    {{
                        creditNote.actualType === 'invoice-correction'
                            ? $t('pages.creditNote.view.invoiceCorrectionHeadline', {
                                  number: creditNote.invoiceNumber,
                              })
                            : $t('pages.creditNoteDoc.view.cancellationHeadline', { number: creditNote.invoiceNumber })
                    }}
                </Words>
                <Words muted>
                    {{
                        $t('pages.creditNoteDoc.view.cancellationSubline', {
                            date: $d(ensureJSTimestamp(creditNote.invoiceDateTimestamp), 'short'),
                        })
                    }}
                </Words>
            </Tile>
            <Tile no-border>
                <Words bold block spaced>{{ $t('pages.creditNoteDoc.view.detailsHeadline') }}</Words>
                <div class="credit-note-view__details">
                    <Words>
                        {{ $t('pages.creditNoteDoc.view.totalAmount') }}&nbsp;&nbsp;&nbsp;
                        {{ $n(creditNote.totalGross, 'currency') }}
                    </Words>
                    <div>
                        <Words
                            :yellow="creditNote.status === 'booked'"
                            :green="creditNote.status === 'paid'"
                            :red="creditNote.status === 'cancelled'"
                        >
                            {{ $t(`status.creditNoteDoc.${creditNote.status}`) }}
                        </Words>
                        <Words v-if="statusTimestamp">
                            ({{ $d(statusTimestamp, 'short') }} &nbsp;
                            {{ $t('timeSuffix', { time: $d(statusTimestamp, 'time') }) }})
                        </Words>
                    </div>
                    <BaseButton v-if="creditNote.document" arrow-right @click="openDocument">
                        <template #left>
                            <PdfIcon class="icon--inline" />
                        </template>
                        {{
                            $t(
                                selectCreditNoteDocKey({
                                    creditNote: `pages.creditNoteDoc.actions.downloadPdf.creditNote`,
                                    selfBilling: `pages.creditNoteDoc.actions.downloadPdf.selfBilling`,
                                })
                            )
                        }}
                    </BaseButton>
                </div>
            </Tile>
            <Tile no-border>
                <Words bold block spaced>{{ $t('pages.creditNoteDoc.view.additionalInfoHeadline') }}</Words>
                <table
                    v-if="
                        creditNote.organization.paymentInfo.owner &&
                        creditNote.organization.paymentInfo.iban &&
                        creditNote.organization.paymentInfo.bic
                    "
                    class="credit-note-view__payment-info"
                >
                    <tr>
                        <td>
                            <Words>{{ $t('pages.creditNoteDoc.view.paymentRecipient') }}</Words>
                        </td>
                        <td>
                            <Words selectable>{{ creditNote.organization.paymentInfo.owner }}</Words>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Words>{{ $t('pages.creditNoteDoc.view.iban') }}</Words>
                        </td>
                        <td>
                            <Words selectable>{{ creditNote.organization.paymentInfo.iban }}</Words>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Words>{{ $t('pages.creditNoteDoc.view.bic') }}</Words>
                        </td>
                        <td>
                            <Words selectable>{{ creditNote.organization.paymentInfo.bic }}</Words>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Words>{{ $t('pages.creditNoteDoc.view.purpose') }}</Words>
                        </td>
                        <td>
                            <Words selectable>
                                {{
                                    $t(
                                        selectCreditNoteDocKey({
                                            creditNote: `pages.creditNoteDoc.purpose.creditNote`,
                                            selfBilling: `pages.creditNoteDoc.purpose.selfBilling`,
                                        }),
                                        { number: creditNote.number }
                                    )
                                }}
                            </Words>
                        </td>
                    </tr>
                </table>

                <table v-else class="credit-note-view__payment-info">
                    <tr>
                        <td>
                            <Words>{{ $t('pages.creditNoteDoc.view.paymentInfo') }}</Words>
                        </td>
                        <td>
                            <Words selectable>{{ $t('pages.creditNoteDoc.view.noPaymentInfo') }}</Words>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Words>{{ $t('pages.creditNoteDoc.view.purpose') }}</Words>
                        </td>
                        <td>
                            <Words selectable>
                                {{
                                    $t(
                                        selectCreditNoteDocKey({
                                            creditNote: `pages.creditNoteDoc.purpose.creditNote`,
                                            selfBilling: `pages.creditNoteDoc.purpose.selfBilling`,
                                        }),
                                        { number: creditNote.number }
                                    )
                                }}
                            </Words>
                        </td>
                    </tr>
                </table>
            </Tile>
        </div>
        <LoadingSpinner v-else block dark />
    </FlyoutPage>
</template>

<script>
import CreditNoteApi from '@/services/Api/CreditNote';
import { ensureJSTimestamp } from '@/services/utils/date';
import { statusTimestamp } from '@/services/utils/creditNote';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';

import BaseButton from '@/components/Button/Button';
import FlyoutPage from '@/components/Layout/FlyoutPage';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Headline from '@/components/Typography/Headline';
import LoadingSpinner from '@/components/LoadingSpinner';
import Tile from '@/components/Layout/Tile';
import Words from '@/components/Typography/Words';

import ArrowIcon from '@/assets/icons/regular/arrow.svg';
import PdfIcon from '@/assets/icons/regular/pdf.svg';
import VoucherIcon from '@/assets/icons/regular/voucher.svg';

export default {
    name: 'CreditNoteViewPage',
    components: {
        BaseButton,
        FlyoutPage,
        HeaderBar,
        HeaderBarItem,
        Headline,
        LoadingSpinner,
        Tile,
        Words,

        ArrowIcon,
        PdfIcon,
        VoucherIcon,
    },
    props: {
        creditNoteId: {
            type: [String, Number],
            default: null,
        },
    },
    setup() {
        const { selectCreditNoteDocKey } = useLegalTerms();
        return {
            selectCreditNoteDocKey,
        };
    },
    data() {
        return {
            isConfirmed: false,
            isPending: false,
            creditNote: null,
        };
    },
    computed: {
        statusTimestamp() {
            return statusTimestamp(this.creditNote);
        },
    },
    watch: {
        $route() {
            this.refresh();
        },
    },
    created() {
        this.refresh();
    },
    methods: {
        async refresh() {
            // created (new) --- fake loading indicator
            // 1 -- load product
            if (this.creditNoteId !== 'created') {
                this.creditNote = await CreditNoteApi.getOneById(this.creditNoteId);
            }
        },

        openDocument() {
            window.open(this.creditNote.document.url);
        },

        ensureJSTimestamp,
    },
};
</script>

<style lang="scss">
.credit-note-view__pending-info {
    text-align: center;
    margin-top: 50px;
}

.credit-note-view__pending-indicator {
    margin-top: 50px;
}

.credit-note-view__details {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.credit-note-view__payment-info {
    td {
        padding-right: 40px;
        padding-bottom: 20px;
        vertical-align: top;
    }
}
</style>

<template>
    <ModalBox
        ref="addNoteModal"
        class="add-note-modal"
        optional-dialog
        :border="false"
        has-large-headline
        :headline="$t('pages.products.supplierProductsList.wasteProductDetails.addNoteModal.headline')"
    >
        <Words block class="intro">
            {{ $t('pages.products.supplierProductsList.wasteProductDetails.addNoteModal.intro') }}
        </Words>

        <Words block bold small unbreakable>
            {{ $t('pages.products.supplierProductsList.wasteProductDetails.addNoteModal.subheading') }}
        </Words>

        <TextField
            v-model="text"
            class="add-note-textarea"
            :label="$t('pages.products.supplierProductsList.wasteProductDetails.addNoteModal.placeholder')"
            :rows="4"
            type="textarea"
        />

        <ButtonGroup slot="actions">
            <Button class="cancel-button" primary light arrow-right @click="closeAddNoteModal()">
                {{ $t('pages.products.supplierProductsList.wasteProductDetails.addNoteModal.cancel') }}
            </Button>
            <Button primary arrow-right @click="confirmAddNoteSave()">
                {{ $t('pages.products.supplierProductsList.wasteProductDetails.addNoteModal.save') }}
            </Button>
        </ButtonGroup>
    </ModalBox>
</template>

<script>
import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ModalBox from '@/components/Modal/ModalBox';
import TextField from '@/components/Form/TextField.v2';
import Words from '@/components/Typography/Words';

export default {
    name: 'SupplierWasteProductDetailsAddNoteModal',
    components: {
        Button,
        ButtonGroup,
        ModalBox,
        TextField,
        Words,
    },
    props: {
        variantId: {
            type: Number,
            default: 0,
        },
        note: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            text: this.note,
        };
    },
    watch: {
        variantId() {
            this.text = this.note;
        },
    },
    methods: {
        closeAddNoteModal() {
            this.$refs.addNoteModal.$emit('close');
        },
        confirmAddNoteSave() {
            const value = this.text.trim() || null;

            this.$emit('update-note', { id: this.variantId, value });
            this.$refs.addNoteModal.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped src="./SupplierWasteProductDetailsAddNoteModal.scss" />

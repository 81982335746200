<template>
    <article class="transport-edit-section card mt-4 first:mt-0">
        <CheckboxField v-if="showEnableControls" :value="model.enabled" class="mb-8" @input="model.enabled = !!$event">
            <header class="flex w-full flex-wrap items-start justify-between">
                <h3 class="font-copy-md-strong">{{ model.count }} &times; {{ model.info }}</h3>
                <span class="font-copy-md">
                    {{ $t('pages.checkout.priceAdjustments.transport.payload') }}: {{ model.payload | tons }}
                </span>
            </header>
        </CheckboxField>
        <header v-else class="mb-8 flex w-full items-start justify-between">
            <h3 class="font-copy-md-strong">{{ model.count }} &times; {{ model.info }}</h3>
            <span class="font-copy-md">
                {{ $t('pages.checkout.priceAdjustments.transport.payload') }}: {{ model.payload | tons }}
            </span>
        </header>
        <h4 class="font-copy-md mb-4 text-subdued">
            {{ $t('pages.checkout.priceAdjustments.transport.initialCalculatedPrices') }}
        </h4>
        <ul class="font-copy-md m-0 list-none p-0">
            <li>
                <PriceRow
                    :label="$t('general.purchasePrice.abbreviation')"
                    :price-per-weight="originalVehicleClass.purchaseUnitPrice.getPerTon()"
                    :price-per-transport="originalVehicleClass.purchaseUnitPrice.getPerTransport()"
                    :fixed-price="fixedPrice"
                />
            </li>
            <li>
                <PriceRow
                    class="mt-2"
                    :label="$t('general.retailPrice.abbreviation')"
                    :price-per-weight="originalVehicleClass.retailUnitPrice.getPerTon()"
                    :price-per-transport="originalVehicleClass.retailUnitPrice.getPerTransport()"
                    :fixed-price="fixedPrice"
                />
            </li>
        </ul>

        <div v-if="model.enabled" class="transport-edit-section__calc">
            <hr class="my-8 border-t-0 border-solid border-divider" />
            <div class="font-copy-md mb-4 text-subdued">
                {{ $t('pages.checkout.priceAdjustments.newPrices') }}
            </div>

            <div class="font-copy-md-strong mb-2">
                {{ $t('pages.checkout.priceAdjustments.purchasePriceLabel') }}
            </div>
            <div class="grid grid-cols-5">
                <TextField
                    class="col-span-2"
                    :value="model.purchaseUnitPrice.getPerTon()"
                    small
                    inline-label
                    type="number"
                    step="0.01"
                    :label="pricePerTonLabel"
                    :disabled="!model.enabled"
                    @input="model.purchaseUnitPrice.setPerTon($event)"
                />
                <TextField
                    class="col-span-3"
                    :value="model.purchaseUnitPrice.getPerTransport()"
                    small
                    inline-label
                    type="number"
                    step="0.01"
                    :label="pricePerTransportLabel"
                    :disabled="!model.enabled"
                    @input="model.purchaseUnitPrice.setPerTransport($event)"
                />
            </div>
            <div class="font-copy-md-strong mb-2 mt-6">
                {{ $t('pages.checkout.priceAdjustments.retailPriceLabel') }}
            </div>
            <div class="grid grid-cols-5">
                <TextField
                    class="col-span-2"
                    :value="model.retailUnitPrice.getPerTon()"
                    small
                    inline-label
                    inline-label-reduced-padding
                    type="number"
                    step="0.01"
                    :label="pricePerTonLabel"
                    :disabled="!model.enabled"
                    @input="model.retailUnitPrice.setPerTon($event)"
                />
                <TextField
                    :value="model.retailUnitPrice.getPerTransport()"
                    class="col-span-3"
                    small
                    inline-label
                    inline-label-reduced-padding
                    type="number"
                    step="0.01"
                    :label="pricePerTransportLabel"
                    :disabled="!model.enabled"
                    @input="model.retailUnitPrice.setPerTransport($event)"
                />
            </div>
        </div>
    </article>
</template>

<script>
import TransportPricingView from '@/pages/Checkout/components/PriceAdjustment/models/TransportPricingView';
import { getCurrencySign } from '@/services/utils/currency';

import CheckboxField from '@/components/Form/CheckboxField';
import TextField from '@/components/Form/TextField.v2';
import PriceRow from '@/pages/Checkout/Disposal/Components/PriceRow';

export default {
    name: 'TransportEditSection',
    components: {
        PriceRow,
        CheckboxField,
        TextField,
    },
    props: {
        value: {
            type: TransportPricingView,
            required: true,
        },
        originalVehicleClass: {
            type: TransportPricingView,
            required: true,
        },
        fixedPrice: {
            type: Boolean,
            default: false,
        },
        transportPriceUnit: {
            type: String,
            required: true,
            validator: v => ['ton', 'transport'].includes(v),
        },
        showEnableControls: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set() {
                this.$emit('input', this.value);
            },
        },
        pricePerTransportLabel() {
            return this.getCurrencySign() + ` / ${this.$t('units.transport')}`;
        },
        pricePerTonLabel() {
            return this.getCurrencySign() + ` / ${this.$t('units.ton')}`;
        },
    },
    methods: {
        getCurrencySign,
    },
};
</script>

<style lang="scss" scoped>
.transport-edit-section__calc {
    padding-bottom: 20px;
}
</style>

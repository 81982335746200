<template>
    <LayoutPage is-flyout :screen-name="screenName" class="scheduling-page" data-test="scheduling-page">
        <Header slot="flyoutHeader" :headline="getHeadline" />
        <div class="container-deprecated scheduling-page__content">
            <transition name="fade">
                <div v-if="!isLoading">
                    <div class="flex flex-col">
                        <Words v-if="isDisposal" spaced-bottom>
                            {{ $t(`pages.checkout.schedulingPage.description`) }}
                        </Words>
                        <TileTrigger
                            v-if="shippingMethodIsAvailable(shippingMethods.flix)"
                            :class="{ 'order-2': isDisposal }"
                            :spaced-top="isDisposal"
                            size="big"
                            data-test="plan-schedule-button"
                            @click="setShippingMethod(shippingMethods.flix)"
                        >
                            <Words bold>
                                {{ getFlixShippingMethodTranslation }}
                            </Words>
                        </TileTrigger>

                        <TileTrigger
                            v-if="shippingMethodIsAvailable(shippingMethods.default)"
                            :class="{ 'order-1': isDisposal }"
                            :spaced-top="!isDisposal"
                            size="big"
                            data-test="flexible-schedule-button"
                            @click="setShippingMethod(shippingMethods.default)"
                        >
                            <Words bold>{{ getDefaultShippingMethodTranslation }}</Words>
                        </TileTrigger>
                    </div>
                    <template v-if="shippingMethodIsAvailable(shippingMethods.immediate) && !isDisposal">
                        <div class="scheduling-page__fast-delivery">
                            <FastDeliveryIcon />
                            <Words large>
                                <span
                                    v-html="
                                        availableShippingMethods.immediate.price > 0
                                            ? $t('pages.checkout.schedulingPage.fastDeliveryTitle')
                                            : $t('pages.checkout.schedulingPage.deliveryWithoutCostTitle')
                                    "
                                />
                            </Words>
                        </div>

                        <TileTrigger
                            size="big"
                            spaced-top
                            primary
                            data-test="immediate-schedule-button"
                            @click="setShippingMethod(shippingMethods.immediate)"
                        >
                            <Words bold>
                                {{ $t(`pages.checkout.schedulingPage.immidiateDelivery.${earliestDateType}.prefix`) }},
                            </Words>
                            <Words>
                                {{
                                    $t(`pages.checkout.schedulingPage.immidiateDelivery.${earliestDateType}.text`, {
                                        time: $d(immediateDate, 'time'),
                                        date: $t(`weekdays.${getDay(immediateDate)}`),
                                    })
                                }}
                            </Words>
                        </TileTrigger>

                        <div class="scheduling-page__hint">
                            <Words muted tiny>
                                <Words red bold>{{ $t('pages.checkout.schedulingPage.hint') }}</Words>
                                {{
                                    $tc('pages.checkout.schedulingPage.hintText', immediateDeliveryDeviation(), {
                                        deviation: immediateDeliveryDeviation(),
                                    })
                                }}
                            </Words>
                        </div>
                    </template>
                </div>
            </transition>

            <LoadingSpinner v-if="isLoading" block dark />
        </div>
    </LayoutPage>
</template>

<script>
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { isToday, isTomorrow, getDay } from 'date-fns';
import { mapActions, mapState, mapGetters } from 'vuex';
import { revertLocalizedValue } from '@/services/utils/localization';
import { ensureJSTimestamp } from '@/services/utils/date';
import ShippingMethodApi from '@/services/Api/ShippingMethod';

import LayoutPage from '@/components/Layout/Page.v2';
import Header from './components/Header';
import TileTrigger from '@/components/Button/TileTrigger';
import Words from '@/components/Typography/Words';
import LoadingSpinner from '@/components/LoadingSpinner';
import Toaster from '@/services/Toaster';

import FastDeliveryIcon from '@/assets/icons/regular/fast-delivery.svg';
import { trackCheckoutEvent } from './trackCheckoutEvent';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';

const SHIPPING_METHODS = {
    default: 'default',
    flix: 'flix',
    immediate: 'immediate',
};

export default {
    name: 'SchedulingPage',

    components: {
        LayoutPage,
        Header,
        TileTrigger,
        Words,
        LoadingSpinner,

        FastDeliveryIcon,
    },

    setup() {
        return {
            screenName: useOrderScreenName('typeselection'),
        };
    },

    data() {
        return {
            isLoading: true,
            isPending: false,
        };
    },

    computed: {
        ...mapState('basket', [
            'constructionSite',
            'qty',
            'availableShippingMethods',
            'transports',
            'isCustom',
            'projectPosition',
            'disposalQtyAndDocument',
        ]),
        ...mapGetters('basket', ['isDisposal']),
        shippingMethods: () => SHIPPING_METHODS,
        immediateDate() {
            if (!this.availableShippingMethods) return null;

            const immediateShipping = _get(this.availableShippingMethods, `${this.shippingMethods.immediate}`, null);
            if (!immediateShipping) return null;

            return ensureJSTimestamp(Math.min(...immediateShipping.deliveryDays.map(o => o.start)));
        },
        earliestDateType() {
            if (!this.immediateDate) return null;
            if (isToday(this.immediateDate)) return 'today';
            if (isTomorrow(this.immediateDate)) return 'tomorrow';
            return 'future';
        },
        getHeadline() {
            if (this.isDisposal) {
                return this.$tc(
                    'pages.checkout.schedulingPage.loading.title',
                    this.disposalQtyAndDocument.transportCount
                );
            } else {
                return this.$tc('pages.checkout.schedulingPage.delivery.title', this.transports.transports.length);
            }
        },
        getDefaultShippingMethodTranslation() {
            if (this.isDisposal) {
                return this.$t('pages.checkout.schedulingPage.flexibleDelivery.loading');
            } else {
                return this.$t('pages.checkout.schedulingPage.flexibleDelivery.delivery');
            }
        },
        getFlixShippingMethodTranslation() {
            if (this.isDisposal) {
                return this.$tc(
                    'pages.checkout.schedulingPage.planDeliveryDate.loading',
                    this.disposalQtyAndDocument.transportCount
                );
            } else {
                return this.$tc(
                    'pages.checkout.schedulingPage.planDeliveryDate.delivery',
                    this.transports.transports.length
                );
            }
        },
    },

    created() {
        this.getAvailableShippingMethods();
    },

    methods: {
        revertLocalizedValue,
        getDay,
        ...mapActions('basket', ['requestPlanningAssistance']),

        async getAvailableShippingMethods() {
            this.isLoading = true;

            try {
                const { state, zip, country } = this.constructionSite.address;
                const customOrder = this.isCustom;
                const projectId = this.projectPosition?.project?.id;
                const projectPositionId = this.projectPosition?.id;

                const availableShippingMethods = await ShippingMethodApi.getAvailableMethods({
                    state,
                    zip,
                    country,
                    customOrder,
                    projectId,
                    projectPositionId,
                });

                this.$store.commit('basket/setAvailableShippingMethods', availableShippingMethods);
            } catch (err) {
                Toaster.error(err);
                this.$logger().error(err);
            }

            this.isLoading = false;
        },

        shippingMethodIsAvailable(type) {
            return _get(this.availableShippingMethods, `${type}.type`, null);
        },

        setShippingMethod(method) {
            if (this.isPending) return;
            this.isPending = true;

            const shippingMethod = {
                methodName: method,
                name: this.availableShippingMethods[method].type,
                ...this.availableShippingMethods[method],
            };

            const currentDeliveryMethod = this.$store.state.basket.deliveryMethod;

            if (
                currentDeliveryMethod &&
                currentDeliveryMethod.name === shippingMethod.name &&
                currentDeliveryMethod.deliveryWindow
            ) {
                shippingMethod.deliveryWindow = currentDeliveryMethod.deliveryWindow;
            }

            // Route for default shipping method
            let nextRoute = this.$route.meta.nextWindowSelect;

            // Reset shipping dates
            const transports = _cloneDeep(this.$store.state.basket.transports);

            if (!currentDeliveryMethod || currentDeliveryMethod.name !== shippingMethod.name) {
                transports.transports = transports.transports.map(transport => {
                    transport.date = null;
                    transport.error = null;
                    return transport;
                });
            }

            if (method === SHIPPING_METHODS.immediate) {
                shippingMethod.deliveryWindow = shippingMethod.deliveryDays[0];

                transports.transports = transports.transports.map(transport => {
                    transport.date = shippingMethod.deliveryDays[0].start;
                    transport.error = null;
                    return transport;
                });
                nextRoute = this.$route.meta.next;
            } else if (method === SHIPPING_METHODS.flix) {
                shippingMethod.deliveryWindow = {
                    start: Math.min(...shippingMethod.deliveryDays.map(o => o.start)),
                    end: Math.max(...shippingMethod.deliveryDays.map(o => o.end)),
                };

                if (
                    transports.transports.length === 1 ||
                    transports.transports.every(t => t.date && t.date !== null && t.date !== '')
                ) {
                    nextRoute = this.$route.meta.nextDateSelect;
                } else {
                    this.requestPlanningAssistance();
                    nextRoute = false;
                }
            }

            this.$store.commit('basket/setTransports', transports);

            trackCheckoutEvent('planning', 'select', {
                option: method,
                type: shippingMethod.type,
                price: shippingMethod.price,
            });

            this.$store.commit('basket/setDeliveryMethod', shippingMethod);
            nextRoute && this.$router.push({ name: this.$root.findRouteName(nextRoute) }).catch(() => {});
            this.isPending = false;
        },

        immediateDeliveryDeviation() {
            return _get(
                this.availableShippingMethods,
                `${this.shippingMethods.immediate}.additionalInformation.possibleDeliveryDeviation`,
                0
            );
        },
    },
};
</script>

<style lang="scss">
.scheduling-page {
    background-color: $color-lightMediumGrey;
}

.scheduling-page__content {
    margin-top: 20px;
    margin-bottom: 20px;
}

.scheduling-page__fast-delivery {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 65px 1fr;
    align-items: center;
}

.scheduling-page__hint {
    margin-top: 22px;
}

.scheduling-page-flix-order-bottom {
    order: 2;
}

.scheduling-page-default-order-top {
    order: 1;
}
</style>

<template>
    <LayoutPage is-flyout :screen-name="screenName" class="checkout-delivery-time-selection">
        <Header slot="flyoutHeader" />

        <div class="container-deprecated">
            <Headline :level="3">
                {{ displayHeadline }}
            </Headline>

            <template v-if="transports.transports.length > 1">
                <Words block spaced-top small muted>
                    {{ $t('pages.checkout.deliveryTimeSelection.subLine') }}
                </Words>

                <Button
                    class="checkout-delivery-time-selection__planing-assistant-btn"
                    @click="requestPlanningAssistance"
                >
                    {{ $t('pages.checkout.deliveryTimeSelection.planningAssistant') }} &rsaquo;
                </Button>
            </template>
        </div>

        <div class="checkout-delivery-time-selection__transports">
            <DatetimePicker
                v-for="(transport, i) in transports.transports"
                :key="i"
                :value="ensureJSTimestamp(transport.date)"
                :minute-steps="allowedMinuteSteps"
                :range-config="dateRanges"
                :active="hasSingleTransport"
                class="checkout-delivery-time-selection__transport-container"
                @input="updateTransport(i, $event)"
                @visually-opened="resetTransportErrors(i)"
            >
                <div class="checkout-delivery-time-selection__transport">
                    <div class="checkout-delivery-time-selection__truck-icon">
                        <VehicleClassIconSet :icon="transport.icon" />
                    </div>
                    <Words bold small class="checkout-delivery-time-selection__truck-name">
                        {{ transport.name }}
                    </Words>
                    <div class="checkout-delivery-time-selection__date-picker">
                        <span v-if="transport.date">
                            {{ $d(ensureJSTimestamp(transport.date), 'short') }},
                            {{ $t('timeSuffix', { time: $d(ensureJSTimestamp(transport.date), 'time') }) }}
                        </span>
                        <span v-else>
                            {{ $t('pages.checkout.deliveryTimeSelection.chooseDateAndTime') }}
                        </span>
                    </div>
                    <TickIcon
                        v-if="transport.date"
                        width="16"
                        height="16"
                        class="checkout-delivery-time-selection__tick-icon"
                    />
                </div>

                <div
                    v-if="transport.error && transport.error.shippingDate"
                    class="checkout-delivery-time-selection__tranport-errors"
                >
                    <ErrorMessage
                        v-for="(error, index) in transport.error.shippingDate"
                        :key="index"
                        :message="error"
                        style="margin-top: 0; margin-bottom: 7px"
                    />
                </div>
            </DatetimePicker>
        </div>

        <SlideUp slot="sticky" :active="hasValidData && !isPending">
            <ButtonGroup>
                <Button :disabled="!hasValidData || isPending" primary type="button" @click="submitSelection">
                    {{ $t('pages.checkout.deliveryTimeSelection.submitButtonLabel') }}
                    <ArrowRightIcon slot="right" class="icon--inline" />
                </Button>
            </ButtonGroup>
        </SlideUp>
    </LayoutPage>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapState, mapGetters } from 'vuex';
import { ensureJSTimestamp, ensurePHPTimestamp } from '@/services/utils/date';
import getTime from 'date-fns/get_time';

import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import DatetimePicker from '@/components/Form/DatetimePicker';
import ErrorMessage from '@/components/Form/ErrorMessage';
import LayoutPage from '@/components/Layout/Page.v2';
import Header from './components/Header';
import Headline from '@/components/Typography/Headline';
import SlideUp from '@/components/Animation/SlideUp';
import VehicleClassIconSet from '@/components/IconSet/VehicleClassIconSet';
import Words from '@/components/Typography/Words';

import ArrowRightIcon from '@/assets/icons/micro/arrow.svg';
import TickIcon from '@/assets/icons/micro/tick.svg';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';
import { useGetters } from 'vuex-composition-helpers';

export default {
    name: 'DeliveryTimeSelectionPage',
    components: {
        Button,
        ButtonGroup,
        DatetimePicker,
        ErrorMessage,
        LayoutPage,
        Header,
        Headline,
        SlideUp,
        VehicleClassIconSet,
        Words,

        ArrowRightIcon,
        TickIcon,
    },

    setup() {
        const { isDisposal } = useGetters('basket', ['isDisposal']);

        const screenName = useOrderScreenName(isDisposal.value ? 'loadingtimeselection' : 'deliverytimeselection');

        return {
            screenName,
        };
    },

    data() {
        return {
            isPending: false,
        };
    },

    computed: {
        ...mapState('basket', ['deliveryMethod', 'transports', 'isCustom']),
        ...mapGetters('basket', ['allowedMinuteSteps', 'isDisposal']),

        hasValidData() {
            return this.transports?.transports?.every(transport => !!transport.date);
        },

        displayHeadline() {
            if (this.isCustom) {
                return this.$t('pages.checkout.deliveryTimeSelection.headlineCustom');
            }
            return this.isDisposal
                ? this.$tc('pages.checkout.disposalTimeSelection.headline', this.transports?.transports?.length)
                : this.$tc('pages.checkout.deliveryTimeSelection.headline', this.transports?.transports?.length);
        },

        dateRanges() {
            return this.assembleValidRangeConfig(this.deliveryMethod.deliveryDays);
        },
        hasSingleTransport() {
            return this.transports?.transports?.length === 1;
        },
    },

    methods: {
        ...mapActions('basket', ['requestPlanningAssistance']),
        ensureJSTimestamp,

        resetTransportErrors(index) {
            this.$store.commit('basket/resetTransportError', index);
        },

        submitSelection() {
            if (this.isPending) return;
            this.isPending = true;

            this.$logger().log('Submit selected dates');
            if (this.hasValidData) {
                this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(() => {});
            }
        },

        updateTransport(i, date) {
            const transports = _cloneDeep(this.transports);
            transports.transports[i].date = ensurePHPTimestamp(getTime(date));
            this.$store.commit('basket/setTransports', transports);
        },

        assembleValidRangeConfig(range) {
            return range.map(o => {
                return {
                    start: ensureJSTimestamp(o.start),
                    end: ensureJSTimestamp(o.end),
                };
            });
        },
    },
};
</script>

<style lang="scss">
.checkout-delivery-time-selection {
    background-color: $color-lightMediumGrey;
}

.checkout-delivery-time-selection__main-date {
    background-color: $color-white;
    padding: 20px 15px;
    display: flex;
    flex-flow: row nowrap;
}

.checkout-delivery-time-selection__main-date-toggle {
    flex: 0 0;
}

.checkout-delivery-time-selection__main-date-picker {
    flex: 1 0;
    padding-left: 15px;
    display: flex;
    align-items: center;
}

.checkout-delivery-time-selection__transports--disabled {
    color: $color-grey;

    .checkout-delivery-time-selection__truck-icon {
        opacity: 0.4;
    }
}

.checkout-delivery-time-selection__transport {
    background-color: $color-white;
    padding: 21px 10px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-top: 2px;
}
.checkout-delivery-time-selection__truck-icon {
    flex: 0 0 52px;
    text-align: right;
    max-width: 52px;

    > * {
        height: 25px;
        width: auto;
    }
}
.checkout-delivery-time-selection__truck-name {
    flex: 1 1 15%;
    text-align: center;
    padding: 0 10px;
}
.checkout-delivery-time-selection__date-picker {
    flex: 1 0 20%;
}

.checkout-delivery-time-selection__note {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 0 10px;
    line-height: 1.2;
}
.checkout-delivery-time-selection__planing-assistant-btn {
    margin: 20px 0px;
}

.checkout-delivery-time-selection__tick-icon {
    fill: $color-green;
    margin-right: 10px;
}
</style>

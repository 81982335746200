<template>
    <div class="grid-table">
        <slot name="head" />
        <div class="grid-table__body">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'GridTable',
    props: {
        columns: {
            type: String,
            default: '1fr',
        },
    },
};
</script>

<style lang="scss">
.grid-table__head {
    background-color: $color-mediumGrey;
    display: grid;
    box-shadow: $boxShadow-bottomShort;
    position: relative;
    z-index: 1;

    > * {
        padding: 15px;
        font-weight: $font-weight-bold;
        font-size: 0.9em;
    }
}

.grid-table__row {
    display: grid;
    background-color: $color-white;
    position: relative;
    cursor: pointer;

    &.grid-table__row--hover {
        z-index: 2;
        box-shadow: $boxShadow-full;
        outline: 2px solid $color-mediumGrey;

        .grid-table__cell--actions {
            display: flex;
        }
    }
}

.grid-table__row--pointless {
    cursor: default;
}

.grid-table__row--spaced {
    margin-bottom: 20px;
}

.grid-table__row--tabled {
    margin-bottom: 3px;
}

.grid-table__cell {
    padding: 15px;
}

.grid-table__cell--center {
    align-self: center;
}

.grid-table__cell--right {
    text-align: right;
}

.grid-table__cell--title {
    grid-column: 1 / -1;
    margin: 10px 15px 0 15px;
    padding: 0 0 10px;
    border-bottom: 1px solid $color-mediumGrey;
}

.grid-table__cell--footer {
    grid-column: 1 / -1;
    margin: 10px 15px 0 15px;
    padding: 12px 0 14px;
    border-top: 1px solid $color-mediumGrey;
}

.grid-table__cell--footer-mobile {
    background-color: white;
    padding: 12px 12px 14px 12px;
    margin: 0;
}

.grid-table__cell--total {
    grid-column: 1 / -1;
    margin: 15px;
    padding: 10px 0;
    text-align: right;
}

.grid-table__cell--foot {
    grid-column: 1 / -1;
    margin: 0 15px 10px 15px;
    padding: 10px 0 10px;
    border-top: 1px solid $color-mediumGrey;
}

.grid-table__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 15px 10px;
    background-color: $color-littleDarkerThanLightMediumGrey;
    box-shadow: $boxShadow-bottomShort;
    position: relative;
    z-index: 1;
}

.grid-table__cell--actions {
    display: none;
    position: absolute;
    top: 1px;
    right: 0;
    bottom: 1px;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    background-color: $color-white;
    pointer-events: none;

    > * {
        pointer-events: auto;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -100px;
        width: 100px;

        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    }
}

.grid-table__cell--actions-spaced {
    > * + * {
        margin-left: 20px;
    }
}

.grid-table__cell--centered {
    text-align: center;
}
</style>

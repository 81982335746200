<template>
    <div
        :class="{
            'card-deprecated--levitate': levitate,
            'card-deprecated--levitate-hover': levitateHover,
            'cursor-pointer': clickable,
            'card-deprecated--small': small,
            'card-deprecated--spaceless': spaceless,
            'card-deprecated--spaceless-x': spacelessX,
            'card-deprecated--spaceless-y': spacelessY,
            'card-deprecated--disabled': disabled,
            'card-deprecated--dark': dark,
            'card-deprecated--shadowless': shadowless,
            'card-deprecated--selected': selected,
        }"
        class="card-deprecated"
        @click="!disabled && $emit('click', $event)"
    >
        <div
            class="card-deprecated__content"
            :class="{
                'card-deprecated__content--no-padding': noPadding,
                'card-deprecated__content--flex': flex,
            }"
        >
            <slot />
        </div>

        <div v-if="$slots.action">
            <slot name="action" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardComponent',
    props: {
        levitate: {
            type: Boolean,
            default: false,
        },

        levitateHover: {
            type: Boolean,
            default: false,
        },

        clickable: {
            type: Boolean,
            default: false,
        },

        small: {
            type: Boolean,
            default: false,
        },

        noPadding: {
            type: Boolean,
            default: false,
        },

        shadowless: {
            type: Boolean,
            default: false,
        },

        spaceless: {
            type: Boolean,
            default: false,
        },

        spacelessX: {
            type: Boolean,
            default: false,
        },

        spacelessY: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        dark: {
            type: Boolean,
            default: false,
        },
        flex: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<!--
    Adding `scoped` increses the specificity which means override styles from the
    parent component, e.g. margins, are not applied.

    The fix for this is to convert all scss to Tailwind classes.
-->
<style lang="scss">
.card-deprecated {
    margin: 0 15px 20px 15px;
    background-color: white;
    box-shadow: $boxShadow-card;
}
.card-deprecated--dark {
    background-color: $color-darkGrey;
    color: white;
}
.card-deprecated--spaceless {
    margin: 0;
}
.card-deprecated--shadowless {
    box-shadow: none;
}
.card-deprecated--spaceless-x {
    margin-left: 0;
    margin-right: 0;
}
.card-deprecated--spaceless-y {
    margin-top: 0;
    margin-bottom: 0;
}
.card-deprecated--levitate {
    box-shadow: $boxShadow-card--levitate;
    cursor: pointer;
    transition:
        transform 0.2s ease,
        box-shadow 0.2s ease;
}
.card-deprecated--levitate:active:not(:focus-within) {
    transform: translateY(5px);
    box-shadow: $boxShadow-card;
}
.card-deprecated--levitate-hover {
    transition:
        transform 0.2s ease,
        box-shadow 0.2s ease;
    transform: translateY(0);
}
.card-deprecated--levitate-hover:hover {
    box-shadow: $boxShadow-card--levitate;
}
.card-deprecated:not(.card-deprecated--spaceless):not(.card-deprecated--spaceless-y):first-of-type {
    margin-top: 20px;
}
.card-deprecated:not(.card-deprecated--spaceless):not(.card-deprecated--spaceless-y):last-of-type {
    margin-bottom: 20px;
}
.card-deprecated__content {
    padding: 15px;
    .card-deprecated--small > & {
        padding: 6px;
    }
}
.card-deprecated__content--no-bottom-padding {
    padding-bottom: 0;
}
.card-deprecated__content--no-padding {
    padding: 0;
}
.card-deprecated--disabled {
    cursor: default;
    opacity: 0.5;
}
.card-deprecated__content--flex {
    display: flex;
}
.card-deprecated--selected {
    border: 2px solid #35343d;
}
</style>

import clsx from 'clsx';
import { useIconSize } from '@/services/utils/useIconSize';
import { forwardRef } from 'react';
// TODO extract to component library, this is just temporary until icon PR is merged
export const HelpIcon = forwardRef(({ size = 'xs', className, ...props }, ref) => {
    const iconSize = useIconSize(size);
    return (<svg width={iconSize} height={iconSize} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={ref} className={clsx('svg-icon', className)} {...props}>
            <path className="f-base" d="M12.00042,22.65A10.65,10.65,0,1,1,22.65081,12.0001,10.66241,10.66241,0,0,1,12.00042,22.65Zm0-19.8a9.15,9.15,0,1,0,9.15039,9.15015A9.16082,9.16082,0,0,0,12.00042,2.85Z"/>
            <path className="f-highlight" d="M11.54383,12.5876a6.33679,6.33679,0,0,1,1.001-1.085,5.56747,5.56747,0,0,0,.749-.77735,1.22069,1.22069,0,0,0,.24415-.749,1.14368,1.14368,0,0,0-.47559-.95215A2.04875,2.04875,0,0,0,11.788,8.66084,2.62136,2.62136,0,0,0,9.50673,9.739l-1.3584-.88282A3.93821,3.93821,0,0,1,9.702,7.58955a5.28785,5.28785,0,0,1,2.24023-.44141,4.15179,4.15179,0,0,1,2.499.67969A2.18431,2.18431,0,0,1,15.372,9.697a2.50424,2.50424,0,0,1-.18848,1.00782,2.77712,2.77712,0,0,1-.45507.73437,8.92178,8.92178,0,0,1-.7002.69336,5.44162,5.44162,0,0,0-.791.83984,1.45828,1.45828,0,0,0-.25878.86817H11.20008A2.14954,2.14954,0,0,1,11.54383,12.5876Zm-.25195,4.28418a1.05227,1.05227,0,0,1-.31543-.77735,1.02873,1.02873,0,0,1,.31543-.76953,1.10339,1.10339,0,0,1,.80469-.30859,1.087,1.087,0,0,1,.791.30859,1.03154,1.03154,0,0,1,.31446.76953,1.05518,1.05518,0,0,1-.31446.77735,1.07234,1.07234,0,0,1-.791.31445A1.08834,1.08834,0,0,1,11.29188,16.87178Z" fill="#f00"/>
        </svg>);
});
HelpIcon.displayName = 'HelpIcon';

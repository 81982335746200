<template>
    <div v-if="attributeSet">
        <VehicleAttributeSelection
            v-for="option in attributeSet.optionGroups"
            :key="option.value"
            :value="value"
            :purchasable-attributes="purchasableAttributes"
            :attribute-option="option"
            @change="handleChange($event)"
        />
    </div>
</template>

<script>
import VehicleAttributeSelection from '@/pages/Vehicle/components/VehicleAttributeSelection';
import AttributeManager from '@/services/AttributeManager/AttributeManager';

export default {
    name: 'VehicleAttributeManager',
    components: {
        VehicleAttributeSelection,
    },
    props: {
        attributeSet: {
            type: Object,
            required: true,
        },
        value: {
            type: Array,
            default: null,
        },
        purchasableAttributes: {
            type: Array,
            default: null,
        },
    },
    data() {
        return {
            attributeManager: null,
        };
    },
    watch: {
        attributeSet: {
            immediate: true,
            handler(nextAttributeSet, prevAttributeSet) {
                if (nextAttributeSet === prevAttributeSet) return;
                this.prepareAttributeManager(nextAttributeSet);
            },
        },
    },
    methods: {
        prepareAttributeManager(attributeSet) {
            if (!attributeSet) {
                this.attributeManager = null;
                return;
            }

            this.attributeManager = new AttributeManager(attributeSet);
            const next = this.attributeManager.prepare(this.value);
            this.$emit('input', next);
        },

        handleChange(changedOption) {
            if (this.attributeManager === null) return;
            const next = this.attributeManager.toggle(this.value, changedOption.value);
            this.$emit('input', next);
        },
    },
};
</script>

<style scoped></style>

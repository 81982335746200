var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"date-picker",class:{
        'date-picker--active': _vm.isVisuallyOpen,
        'date-picker--invalid': _vm.isInvalid,
        'date-picker--disabled': _vm.disabled,
        'date-picker--desktop': _vm.$root.isDesktop,
    }},[_c('div',{staticClass:"date-picker__trigger",on:{"click":function($event){return _vm.open()}}},[_vm._t("default",function(){return [_vm._v("Please provide a default")]},{"isInvalid":_vm.isInvalid})],2),(_vm.isActive)?[_c('div',{staticClass:"date-picker__backdrop",on:{"click":_vm.closeByBackdrop}}),_c('div',{staticClass:"date-picker__body",on:{"click":_vm.closeByBackdrop}},[_c('div',{staticClass:"date-picker__content",on:{"click":_vm.preventContentClicks}},[_c('div',{directives:[{name:"scrollable",rawName:"v-scrollable"}],staticClass:"date-picker__inner scroll-container",class:{
                        'date-picker__inner--no-footer': !_vm.$slots.bottom,
                    }},[_c('div',{staticClass:"date-picker__desktop-wrapper"},[_c('Tile',{attrs:{"background-transparent":""}},[_c('Calendar',{attrs:{"type":_vm.calendarType,"allowed-dates":_vm.availableDates,"max-range":_vm.maxRange,"max-range-in-work-days":_vm.maxRangeInWorkDays,"min-date":_vm.min ? _vm.ensureDate(_vm.min) : null,"max-date":_vm.max ? _vm.ensureDate(_vm.max) : null},model:{value:(_vm.selectedDates),callback:function ($$v) {_vm.selectedDates=$$v},expression:"selectedDates"}})],1)],1)]),_c('div',{staticClass:"date-picker__footer"},[_c('ButtonGroup',[(_vm.enableReset)?_c('Button',{attrs:{"primary":"","light":"","place-left":""},on:{"click":_vm.clear}},[_vm._v(" "+_vm._s(_vm.$t('components.datetimePicker.clear'))+" ")]):_vm._e(),_c('Button',{attrs:{"disabled":!_vm.canSubmit,"primary":"","data-test":"button-apply-calendar"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('components.datetimePicker.submit')))])],1)],1)])])]:_vm._e()],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
import ChildRenderer from '@/components/Router/ChildRenderer';
import TransportCostCalculatorPage from './TransportCostCalculator';
import { getLd } from '@/services/LaunchDarkly';

export default {
    path: 'tool-flix',
    component: ChildRenderer,
    meta: {
        requiresAuth: true,
        noBackArrow: true,
        requiredAbilities: ['canUseToolTransportCalculation'],
    },
    children: [
        {
            path: '',
            name: 'transport-cost-calculator',
            component: TransportCostCalculatorPage,
            meta: {
                requiredAbilities: ['canUseToolTransportCalculation'],
            },
            beforeEnter: (to, from, next) => {
                const ld = getLd();
                next(ld?.flags?.transport_cost_calculator || '/');
            },
        },
    ],
};

const state = {
    pendingRequests: 0,
};

const getters = {
    isRequestPending: state => state.pendingRequests > 0,
};

const mutations = {
    reset(state) {
        state.pendingRequests = 0;
    },
    startRequest(state) {
        state.pendingRequests++;
    },

    finishRequest(state) {
        state.pendingRequests--;
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

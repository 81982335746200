import HomePage from './HomePage';
import store from '@/store';
import CheckoutRenderer from '@/pages/Checkout/components/CheckoutRenderer.vue';
import ConfirmationPage from '@/pages/Checkout/ConfirmationPage.vue';
import { customDeliveryRoute, customShipmentRoute } from '@/pages/Home/custom-order-routes';

const checkoutRoutes = {
    path: 'order',
    name: 'checkout-main',
    component: CheckoutRenderer,
    meta: {
        requiresAuth: true,
        requiredAbilities: ['createAnyQuoteInCheckout'],
    },
    children: [
        customDeliveryRoute,
        customShipmentRoute,
        {
            path: 'confirmation',
            name: 'order-confirmation',
            component: ConfirmationPage,
            meta: {
                noHeader: true,
            },
            beforeEnter(to, from, next) {
                const confirmedQuote = store.state.basket.confirmedQuote;

                if (!confirmedQuote || ['submitted', 'pending'].indexOf(confirmedQuote.status) < 0) {
                    next({ name: 'home' });
                    return;
                }

                next();
            },
        },
    ],
    beforeEnter(to, from, next) {
        const partialRoutesWithoutMandatoryBasket = ['order-confirmation', 'checkout-project-position'];
        const matchedExcludedRoutes = partialRoutesWithoutMandatoryBasket.filter(partialRoute =>
            to.name.match(partialRoute)
        );

        if (matchedExcludedRoutes.length) {
            next();
            return;
        }

        store.state.basket.type ? next() : next({ name: 'home' });
    },
};

export default {
    path: '/dashboard',
    name: 'home',
    component: HomePage,
    meta: {
        requiresAuth: true,
    },
    children: [checkoutRoutes],
};

const initialState = {
    supplierOrganizationId: null,
};

const getters = {
    getSupplierOrganizationId: state => state.supplierOrganizationId,
};

const mutations = {
    SET_SUPPLIER_ORGANIZATION_ID(state, id) {
        state.supplierOrganizationId = id;
    },
};

const actions = {
    async setSupplierOrganizationId({ commit }, id) {
        commit('SET_SUPPLIER_ORGANIZATION_ID', id);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

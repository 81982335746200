<template>
    <div
        v-on-clickaway="close"
        class="context-menu"
        :class="{ 'context-menu--active': isOpen, 'context-menu--visually-open': isVisuallyOpen }"
        @click.prevent.stop="closeClickaways"
    >
        <button type="button" class="context-menu__trigger" @click="toggle()">
            <SfSysCloseIcon v-if="isVisuallyOpen" size="xs" />
            <SfSysContextIcon v-else multi-color size="xs" />
        </button>

        <div v-if="!$root.isDesktop" class="context-menu__backdrop" @click.prevent.stop="close()" />

        <div class="context-menu__entries" :class="`context-menu__entries--${position}`" :style="menuStyle">
            <template v-if="isOpen">
                <template v-if="isLoading">
                    <ContextMenuEntry v-for="id in [1, 2, 3]" :key="id" disabled>
                        <template #left><div class="h-4 w-4 animate-pulse rounded bg" /></template>
                        <div class="h-4 w-18 animate-pulse rounded bg" />
                    </ContextMenuEntry>
                </template>
                <slot v-else :select-action="selectAction" />
            </template>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from '@/plugins/mixins/clickAway';

import ContextMenuEntry from '@/components/Button/ContextMenuEntry.vue';
import { SfSysContextIcon, SfSysCloseIcon } from '@schuettflix/vue-components';

const ANIMATION_TIME = 300;

export default {
    name: 'ContextMenu',
    components: {
        ContextMenuEntry,
        SfSysContextIcon,
        SfSysCloseIcon,
    },
    mixins: [clickaway],
    props: {
        position: {
            type: String,
            default: 'bottom-left',
            validator: v => ['top-left', 'top-right', 'bottom-left', 'bottom-right'].includes(v),
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isOpen: false,
            isVisuallyOpen: false,
            top: false,
            left: false,
            observer: null,
        };
    },
    computed: {
        menuStyle() {
            return this.isOpen
                ? {
                      top: !this.top ? '80%' : 'auto',
                      right: this.left ? '80%' : 'auto',
                      bottom: this.top ? '80%' : 'auto',
                      left: !this.left ? '80%' : 'auto',
                  }
                : null;
        },
    },
    watch: {
        isLoading: {
            immediate: true,
            handler() {
                this.positionMenu();
            },
        },
    },
    mounted() {
        this.observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type !== 'childList') return;
                this.$nextTick(() => this.positionMenu());
            });
        });
    },
    methods: {
        toggle() {
            this.isOpen ? this.close() : this.open();
        },

        open() {
            this.observer.observe(this.$el, { childList: true, subtree: true });

            this.$nextTick(() => {
                this.positionMenu();
                this.isOpen = true;
                this.isVisuallyOpen = true;
                this.$emit('opened');
            });
        },

        positionMenu() {
            const boundingRect = this.$el?.querySelector('.context-menu__entries').getBoundingClientRect();

            this.left = boundingRect?.left + boundingRect?.width > window.innerWidth;
            this.top = boundingRect?.top + boundingRect?.height > window.innerHeight;
        },

        close() {
            this.observer.disconnect();

            this.$nextTick(() => {
                this.isVisuallyOpen = false;
                setTimeout(() => {
                    this.isOpen = false;
                    this.$emit('closed');
                }, ANIMATION_TIME);
            });
        },

        selectAction(key) {
            this.close();
            this.$emit('click', key);
        },
    },
};
</script>

<style lang="scss">
.context-menu {
    position: relative;
    overflow: hidden;
}

.context-menu--active {
    overflow: visible;
}

.context-menu__trigger {
    position: relative;
    background-color: transparent;
    font-weight: $font-weight-regular;
    border: 0;
    outline: none;
    padding: 0;
    margin: 0;
    font-family: $font-family;
    font-size: $font-size-base;
    line-height: $line-height-base;
    -webkit-appearance: none;
    text-decoration: none;
    cursor: pointer;
    border-radius: 100%;
    width: 36px;
    height: 36px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
}

.context-menu__trigger:disabled {
    cursor: default;
}

.context-menu__trigger:hover,
.context-menu--visually-open .context-menu__trigger {
    background-color: $color-littleDarkerThanLightMediumGrey;
    z-index: 1001;

    @media screen and (max-width: $layout-desktop-max) {
        background-color: transparent;
    }
}

@media screen and (max-width: $layout-desktop-max) {
    .context-menu--visually-open .context-menu__trigger {
        svg {
            .f-base {
                fill: $color-white;
            }

            .s-base {
                stroke: $color-white;
            }
        }
    }
}

.context-menu__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-darkGrey;
    z-index: 1000;
    min-height: var(--view-height);
    opacity: 0;
    visibility: hidden;
    transition:
        opacity 0.3s ease,
        visibility 0s linear 0.3s;
    pointer-events: none;

    .context-menu--visually-open > & {
        visibility: visible;
        opacity: 0.9;
        transition:
            opacity 0.3s ease,
            visibility 0s linear 0s;
        pointer-events: auto;
    }
}

.context-menu__entries {
    position: absolute;
    display: block;
    visibility: hidden;
    background-color: white;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    padding: 5px 0;
    z-index: 1001;
    opacity: 0;
    transition:
        opacity 0.3s ease,
        visibility 0s linear 0.3s;
    pointer-events: none;

    @media screen and (max-width: $layout-desktop-max) {
        position: fixed;
        top: auto !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        padding: 0;

        // iOS 11.0
        @supports (padding-bottom: constant(safe-area-inset-bottom)) {
            bottom: constant(safe-area-inset-bottom) !important;
        }

        // iOS 11.2
        @supports (padding-bottom: env(safe-area-inset-bottom)) {
            bottom: env(safe-area-inset-bottom) !important;
        }
    }
}

.context-menu--visually-open .context-menu__entries {
    visibility: visible;
    opacity: 1;
    transition:
        opacity 0.3s ease,
        visibility 0s linear 0s;
    pointer-events: auto;
}
</style>

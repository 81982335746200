<template>
    <LayoutPage
        class="transport-hub transport-hub--platform"
        screen-name="platform-job-list"
        data-test="transport-hub-platform-page"
        :parent-route="parentRoute"
        :back-title="parentRoute?.backTitle"
    >
        <template #pageTitle>{{ $t('pages.transportHub.title.platform') }}</template>

        <template #mobileHeader>
            <HeaderBar>
                <template v-if="parentRoute" #left>
                    <HeaderBarItem button @click="goToParent()">
                        <ArrowIcon width="32" height="18" />
                    </HeaderBarItem>
                </template>

                <template #headline>
                    <div>{{ $t(`pages.transportHub.title.platform`) }}</div>
                </template>

                <template #right>
                    <HeaderBarItem v-if="!$root.isDesktop">
                        <FilterBox
                            v-model="filter"
                            screen-name="platform-job-filter"
                            no-padding
                            :default-filter="defaultFilter"
                            :endpoint="transportListApi"
                            :forced-filter="forcedFilter"
                            :ignore-in-count="ignoreInCount"
                            @save="refreshList(false, true, true)"
                        >
                            <template #default="{ filter: filterFromSlot, updateFilter }">
                                <TransportFilterSet
                                    class="container-deprecated transport-hub__flyout-filters"
                                    :filter-scope="{ filter: filterFromSlot }"
                                    :endpoint="transportListApi"
                                    :whitelisted-fields="whitelistedFields"
                                    @updateFilter="updateFilter"
                                />
                            </template>
                        </FilterBox>
                    </HeaderBarItem>

                    <HeaderBarItem v-if="!$root.isDesktop">
                        <FilterSort
                            :sort-by="filter.sortBy"
                            :sort-direction="filter.sortDirection"
                            :sort-options="sortOptions"
                            :option-label-renderer="value => $t(`pages.transportHub.sortings.${value}`)"
                            has-sort-set-options
                            @sort-by-update="filter.sortBy = $event"
                            @sort-direction-update="filter.sortDirection = $event"
                            @updated-sorting="refreshList(false, true, true)"
                        />
                    </HeaderBarItem>
                </template>
            </HeaderBar>
        </template>

        <RoundedTabNavigation
            :value="activePredefinedFilter"
            :tabs="predefinedFilterTabs"
            :full-width-tabs="!$root.isDesktop"
            @input="updatePredefinedForcedFilter"
        />

        <FilterBox
            v-model="filter"
            :default-filter="filter"
            :forced-filter="forcedFilter"
            :endpoint="transportListApi"
            inline-mode
            class="transport-hub__inline-filter"
            @update="refreshList(false, true, true)"
        >
            <template #default="filterScope">
                <AdvancedPlatformFilterSet
                    :disable-subcontractor-filter="activePredefinedFilter === 'open'"
                    :filter-scope="filterScope"
                    :endpoint="transportListApi"
                    :forced-filter="forcedFilter"
                    :whitelisted-fields="whitelistedAdvancedFilterFields"
                />

                <FilterSortPagination
                    :result="transports"
                    has-sort-set-options
                    :filter="filterScope.filter"
                    :sort-options="sortOptions"
                    :option-label-renderer="value => $t(`pages.transportHub.sortings.${value}`)"
                    :hide-sort="!$root.isDesktop"
                    show-grouping-toggle
                    show-refresh
                    :loading="isLoading"
                    @refresh="refreshList(false, true, true)"
                    @pageNumberUpdated="pageNumberUpdated"
                >
                    <div class="transport-hub__grouping-wrapper">
                        <FilterBox
                            v-if="$root.isDesktop"
                            v-model="filter"
                            screen-name="platform-job-filter"
                            no-padding
                            :default-filter="defaultFilter"
                            :forced-filter="forcedFilter"
                            :endpoint="transportListApi"
                            :ignore-in-count="ignoreInCount"
                            :button-label="$t('pages.transportHub.filter.moreFilterButtonLabel')"
                            @save="refreshList(false, true, true)"
                        >
                            <template #default="{ filter: filterFromSlot, updateFilter }">
                                <TransportFilterSet
                                    class="container-deprecated transport-hub__flyout-filters"
                                    :filter-scope="{ filter: filterFromSlot }"
                                    :endpoint="transportListApi"
                                    :whitelisted-fields="whitelistedFields"
                                    @updateFilter="updateFilter"
                                />
                            </template>
                        </FilterBox>
                        <IconizedToggleSwitchField
                            :value="isTransportHubGrouped"
                            :disabled="isLoading"
                            :hide-label="!$root.isDesktop"
                            @input="handleListGrouping"
                        >
                            <template #inactive>
                                <ListUngroupedIcon />
                            </template>
                            <template #active>
                                <ListGroupedIcon />
                            </template>
                            {{ $t('pages.transportHub.filter.grouping') }}
                        </IconizedToggleSwitchField>
                    </div>
                </FilterSortPagination>
            </template>
        </FilterBox>

        <div class="px-4 lg:px-0">
            <transition name="fade" mode="out-in">
                <div v-if="isLoading" key="transportLoadingIndicatorBlocks">
                    <TransportListLoadingBlock
                        v-for="(item, i) in Array(defaultFilter.perPage)"
                        :key="i"
                        :class="{
                            'mt-4': i > 0,
                        }"
                    />
                </div>
                <div v-else-if="transports && transports.count > 0" key="transportBlocks">
                    <div v-if="isTransportHubGrouped" class="space-y-6">
                        <TransportListGroup
                            v-for="group in transportsItemGroups"
                            :key="group.orderNumber"
                            :group="group"
                            context="platform"
                        >
                            <TransportListBlockList
                                :show-preferred-carriers-handler="showPreferredCarriers"
                                is-grouped
                                :transports="group.transports"
                                context="platform"
                                @select-transport="selectTransport"
                                @open-unplan-transport="openUnplanTransport"
                            />
                        </TransportListGroup>
                    </div>

                    <TransportListBlockList
                        v-else
                        :transports="transportsItemGroups[0].transports"
                        :show-preferred-carriers-handler="showPreferredCarriers"
                        context="platform"
                        @select-transport="selectTransport"
                        @open-unplan-transport="openUnplanTransport"
                    />

                    <div class="card mt-6">
                        <Pagination align-right :result="transports" @pageNumberUpdated="pageNumberUpdated" />
                    </div>
                </div>
                <Hint v-else-if="transports && transports.count === 0" center transparent>
                    {{ $t('pages.transportHub.noResults') }}
                </Hint>
            </transition>
        </div>

        <ModalBox
            ref="preferredCarriersInfo"
            :headline="$t('pages.transportHub.platform.preferredCarriers.headline')"
            @closed="hidePreferredCarriers"
        >
            <div v-if="selectedPreferredCarriers && selectedPreferredCarriers.length > 0">
                <div v-for="(carrier, i) in selectedPreferredCarriers" :key="i" class="transport-hub__adress-inline">
                    <Words block>{{ carrier.name }}</Words>
                    <Words block muted>
                        <FormattedAddress :address="carrier.billingAddress" />
                    </Words>
                </div>
            </div>
            <Words v-else block muted>{{ $t('pages.transportHub.platform.preferredCarriers.noInfo') }}</Words>

            <template #actions>
                <BaseButton primary @click="$refs.preferredCarriersInfo.$emit('close')">
                    {{ $t('pages.transportHub.platform.preferredCarriers.buttonLabel') }}
                </BaseButton>
            </template>
        </ModalBox>

        <Flyout route="transport-view" size="small" no-header />
    </LayoutPage>
</template>

<script>
import { defineComponent } from 'vue';
import TransportListApi from '@/services/Api/TransportList';
import TransportListGroupedApi from '@/services/Api/TransportListGrouped';
import { ensureJSTimestamp, ensurePHPTimestamp, getFormattedDuration } from '@/services/utils/date';
import { disposalLifecycle, transportProgress, transportStatus } from '@/services/utils/transport';
import { revertLocalizedValue } from '@/services/utils/localization';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import Toaster from '@/services/Toaster';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import { PLATFORM_UNPLAN_TRANSPORT } from '@/services/TransportActions';
import { mapState } from 'vuex';
import { getWhitelistedSortSetOptions, joinSortSet, splitSortSet } from '@/services/utils/filter';
import { useLd } from '@/services/LaunchDarkly';
import TransportListGroupView from '@/components/Transport/TransportListGroupView';
import _union from 'lodash/union';

import AdvancedPlatformFilterSet from '@/pages/TransportHub/components/AdvancedPlatformFilterSet';
import BaseButton from '@/components/Button/Button';
import FilterBox from '@/components/Filter/FilterBox';
import FilterSort from '@/components/Filter/FilterSort';
import FilterSortPagination from '@/components/Filter/FilterSortPagination';
import FormattedAddress from '@/components/FormattedAddress';
import Flyout from '@/components/Layout/Flyout';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Hint from '@/components/Typography/Hint';
import IconizedToggleSwitchField from '@/components/Form/IconizedToggleSwitchField';
import LayoutPage from '@/components/Layout/Page.v2';
import ModalBox from '@/components/Modal/ModalBox';
import Pagination from '@/components/Pagination';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation';
import TransportFilterSet from '@/pages/Transport/components/TransportFilterSet';
import TransportListBlockList from '@/pages/Transport/components/TransportListBlockList';
import TransportListGroup from '@/components/Transport/TransportListGroup';
import TransportListLoadingBlock from '@/components/Transport/TransportListLoadingBlock.vue';
import Words from '@/components/Typography/Words';

import ListGroupedIcon from '@/assets/icons/list-grouped--24px.svg';
import ListUngroupedIcon from '@/assets/icons/list-ungrouped--24px.svg';
import ArrowIcon from '@/assets/icons/regular/arrow.svg';
import { useRoute } from 'vue-router/composables';

const isLateStatus = _union(transportStatus, disposalLifecycle).filter(
    item => !['delivered', 'failed', 'confirmation_pending', 'cancelled'].includes(item)
);

const DEFAULT_PAGE_COUNT = 25;
const UPDATE_INTERVAL = 5000;
const TAB_COUNT_UPDATE_INTERVAL = 30000;

let skipReAssembleFilter = false;

const SORT_GROUPS = {
    ungrouped: {
        open: ['urgency__asc', 'created__desc', 'created__asc'],
        inProgress: ['urgency__asc'],
        isLate: ['urgency__asc'],
        closed: ['closed__desc', 'created__desc', 'created__asc'],
        failed: ['closed__desc'],
        all: ['created__desc', 'created__asc'],
    },
    grouped: {
        open: ['transportNumber__desc', 'transportDate__desc'],
        inProgress: ['transportNumber__desc', 'transportDate__desc'],
        isLate: ['transportNumber__desc', 'transportDate__desc'],
        closed: ['transportNumber__desc', 'transportDate__desc'],
        failed: ['transportNumber__desc', 'transportDate__desc'],
        all: ['transportNumber__desc', 'transportDate__desc'],
    },
};

export default defineComponent({
    name: 'TransportHubPlatformPage',
    components: {
        ArrowIcon,
        AdvancedPlatformFilterSet,
        BaseButton,
        FilterBox,
        FilterSort,
        FilterSortPagination,
        Flyout,
        FormattedAddress,
        HeaderBar,
        HeaderBarItem,
        Hint,
        IconizedToggleSwitchField,
        LayoutPage,
        ModalBox,
        Pagination,
        RoundedTabNavigation,
        TransportFilterSet,
        TransportListBlockList,
        TransportListGroup,
        TransportListLoadingBlock,
        Words,

        ListGroupedIcon,
        ListUngroupedIcon,
    },
    mixins: [persistentFiltersMixin, eventHubMixin],
    setup() {
        // The parent route is used to redirect back to the screen that initially linked to the transport list.
        // Otherwise, on mobile, when we add the transport list from another page the user has no way to get back.
        // We use a query param to pass the parent route to the transport list so that we can link back even we the
        // use has opened subpages like transport details in the mean time.
        let parentRoute;

        const route = useRoute();
        try {
            parentRoute = JSON.parse(route.query.parentRoute);
        } catch {
            // noop parent route stays undefined
        }

        return {
            ld: useLd(),
            parentRoute,
        };
    },
    data() {
        return {
            transports: null,
            isLoading: false,
            cancelSource: null,
            selectedPreferredCarriers: null,
            currentApi: null,
            filter: this.assembleFilter('transport', {
                page: 1,
                perPage: DEFAULT_PAGE_COUNT,
                seedSortBy: 'status',
            }),
            defaultFilter: {
                page: 1,
                perPage: DEFAULT_PAGE_COUNT,
                seedSortBy: 'status',
            },
            forcedFilter: this.assembleFilter('transportForced', {
                perPage: DEFAULT_PAGE_COUNT,
                seedSortBy: 'status',
                status: ['new'],
            }),
            activePredefinedFilter: 'open',
            predefinedFilterCounts: {},
            predefinedFilterCancelSources: {},
        };
    },
    computed: {
        ...mapState('localPreferences', ['isTransportHubGrouped']),

        transportListApi() {
            return this.getApi();
        },

        predefinedFilters() {
            return {
                open: {
                    ...splitSortSet(this.isTransportHubGrouped ? 'transportNumber__desc' : 'urgency__asc'),
                },
                inProgress: {
                    ...splitSortSet(this.isTransportHubGrouped ? 'transportNumber__desc' : 'urgency__asc'),
                },
                isLate: {
                    ...splitSortSet(this.isTransportHubGrouped ? 'transportNumber__desc' : 'urgency__asc'),
                },
                closed: {
                    ...splitSortSet(this.isTransportHubGrouped ? 'transportNumber__desc' : 'closed__desc'),
                },
                failed: {
                    ...splitSortSet(this.isTransportHubGrouped ? 'transportNumber__desc' : 'closed__desc'),
                },
                all: {
                    ...splitSortSet(this.isTransportHubGrouped ? 'transportNumber__desc' : 'created__desc'),
                },
            };
        },

        predefinedForcedFilters() {
            return {
                open: {
                    status: ['new'],
                },
                inProgress: {
                    status: [
                        'assigned',
                        'planned',
                        'started',
                        'checked_in',
                        'checked_in_loading',
                        'loaded',
                        'ready_to_deliver',
                        'in_transit',
                        'destination_waiting',
                    ],
                },
                isLate: {
                    isLate: true,
                    status: isLateStatus,
                },
                closed: {
                    status: ['delivered', 'cancelled'],
                },
                failed: {
                    status: ['failed'],
                },
                all: {
                    status: [],
                },
            };
        },

        sortOptions() {
            const currentSortingConfiguration = SORT_GROUPS[this.isTransportHubGrouped ? 'grouped' : 'ungrouped'];
            const currentSorting = currentSortingConfiguration[this.activePredefinedFilter];

            return getWhitelistedSortSetOptions(currentSorting, this.transportListApi.supportedSorts);
        },

        selectedSortOption() {
            return joinSortSet(this.filter.sortBy, this.filter.sortDirection);
        },

        ignoreInCount() {
            return [
                'organizationType',
                'organization',
                'user',
                'preferred',
                'startShippingWindow',
                'endShippingWindow',
                'transportNumber',
                'status',
                'isLate',
                'type',
                'seedSortBy',

                'transportNumber',
                'type',
                'status',
                'startShippingWindow',
                'endShippingWindow',
                'originGeo',
                'destinationGeo',
                'includeSubcontractor',
                'constructionProject',
            ];
        },

        whitelistedAdvancedFilterFields() {
            return [
                'transportNumber',
                'type',
                'status',
                'startShippingWindow',
                'endShippingWindow',
                'originGeo',
                'destinationGeo',
            ];
        },

        whitelistedFields() {
            return [
                'clientOrganization',
                'carrierOrganization',
                'supplierOrganization',
                'weighingNoteNumber',
                'haveProject',
            ];
        },

        predefinedFilterTabs() {
            const tabs = {};

            Object.keys(this.predefinedForcedFilters).forEach(key => {
                let suffix = '';

                if (this.predefinedFilterCounts[key]) {
                    suffix = ` (${this.predefinedFilterCounts[key]})`;
                }

                tabs[key] = this.$t(`pages.transportHub.pills.delivery.${key}`) + suffix;
            });

            return tabs;
        },

        transportsItemGroups() {
            if (this.isTransportHubGrouped) {
                return this.transports.items;
            }

            return [
                {
                    orderNumber: '00000',
                    transports: this.transports.items,
                },
            ];
        },
    },
    watch: {
        $route() {
            if (skipReAssembleFilter) {
                skipReAssembleFilter = false;
                return;
            }

            this.updateActivePredefinedFilter();

            this.filter = this.assembleFilter('transport', this.defaultFilter);
            this.forcedFilter = this.assembleFilter(
                'transportForced',
                this.predefinedForcedFilters[this.activePredefinedFilter]
            );
            this.refreshList(true);
        },
    },
    async created() {
        this.updateDefaultSort();
        await this.refreshList(true);
        this.updateActivePredefinedFilter();

        // pull counts
        this.refreshCounts();
        this.$gracefulInterval(this.refreshCounts.bind(this), TAB_COUNT_UPDATE_INTERVAL);

        this.$interval(this.checkHighlightState.bind(this), UPDATE_INTERVAL);

        this.subscribe('transport-returned', () => {
            this.refreshList();
        });

        this.subscribeMutation('transportActions/updateTransport', () => {
            this.refreshList();
        });
    },
    methods: {
        ensurePHPTimestamp,
        ensureJSTimestamp,
        getFormattedDuration,
        revertLocalizedValue,
        transportProgress,
        updatePredefinedForcedFilter(filterKey) {
            this.activePredefinedFilter = filterKey;

            // Move the construction project filter over to other tabs
            // when coming from the construction project page
            const constructionProjectFilter = this.filter.constructionProject
                ? { constructionProject: this.filter.constructionProject }
                : {};

            this.$router
                .push({
                    name: 'order-management__transport-hub',
                    query: {
                        transportForced: this.assembleQueryFilter(this.predefinedForcedFilters[filterKey]),
                        transport: this.assembleQueryFilter({
                            ...constructionProjectFilter,
                            ...this.defaultFilter,
                            ...this.predefinedFilters[filterKey],
                        }),
                    },
                })
                .catch(() => {});
        },

        updateActivePredefinedFilter() {
            this.activePredefinedFilter = 'open';
            Object.keys(this.predefinedForcedFilters).forEach(key => {
                if (this.isQueryFilterActive('transportForced', this.predefinedForcedFilters[key])) {
                    this.activePredefinedFilter = key;
                }
            });

            this.updateDefaultSort();
        },

        pageNumberUpdated(number) {
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$eventHub.$emit('page.actions.scroll-top');
            this.filter.page = number;
            this.refreshList();
        },

        getApi() {
            const useOpensearch = this.ld?.flags?.['use-opensearch-on-platform-transport-hub'];
            this.currentApi = this.isTransportHubGrouped
                ? new TransportListGroupedApi('platform', { useLegacySearch: !useOpensearch })
                : new TransportListApi('platform', { useLegacySearch: !useOpensearch });
            return this.currentApi;
        },

        async refreshList(isInitial = false, resetPagination = false, skipApplyFilter = false) {
            this.isLoading = true;

            // check if we have to reset the pagination
            if (resetPagination) {
                this.filter.page = 1;
            }

            if (this.transports?.items) {
                this.transports.items = [];
            }

            // persist filter
            this.persistFilter('transport', this.filter, this.defaultFilter);
            this.persistFilter('transportForced', this.forcedFilter, this.defaultFilter);
            skipReAssembleFilter = skipApplyFilter;

            // cancel previous request
            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.cancelSource = this.transportListApi.createCancelTokenSource();

            try {
                const result = await this.transportListApi.filter(
                    this.filter,
                    null,
                    null,
                    this.cancelSource,
                    this.forcedFilter
                );

                // transform items into views
                if (this.isTransportHubGrouped) {
                    result.transform(item => TransportListGroupView.createFromPayload(item));
                }

                this.transports = result;
                this.checkHighlightState();

                if (isInitial === true) {
                    this.filter = {
                        ...this.filter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                    Toaster.error(this.$t(err.message));
                }
            }

            this.isLoading = false;
        },

        selectTransport(transportId, preselectedNavigation = null) {
            const route = {
                name: `${this.$route.name}__transport-view`,
                params: { transportId: transportId },
                query: this.$route.query,
            };

            // preselectTab on transport view
            if (preselectedNavigation) {
                route.params.preselectedNavigation = preselectedNavigation;
            }

            this.$router.push(route).catch(() => {});
        },

        async openUnplanTransport(transport) {
            this.$store.dispatch('transportActions/set', {
                action: PLATFORM_UNPLAN_TRANSPORT,
                transport,
                context: 'platform',
            });
        },

        checkHighlightState() {
            const now = Date.now() / 1000;

            this.$nextTick(() => {
                if (!this.transports) {
                    return;
                }

                // Handle grouped transports
                this.transportsItemGroups.forEach(group => {
                    group.transports.forEach(transport => {
                        if (transport.preferredUntil) {
                            this.$set(
                                transport,
                                '__preferedRemainingSeconds',
                                parseInt(transport.preferredUntil - now)
                            );
                        }
                    });
                });
            });
        },

        showPreferredCarriers(transport) {
            if (this.$refs.preferredCarriersInfo) {
                this.selectedPreferredCarriers = transport.preferredCarriers;
                this.$refs.preferredCarriersInfo.$emit('open');
            }
        },

        hidePreferredCarriers() {
            setTimeout(() => {
                this.selectedPreferredCarriers = null;
            }, 300);
        },

        async refreshCounts() {
            Object.keys(this.predefinedForcedFilters).forEach(async key => {
                this.$set(
                    this.predefinedFilterCounts,
                    key,
                    await this.fetchFilterCount(this.predefinedForcedFilters[key], key)
                );
            });
        },

        async fetchFilterCount(predefinedFilterItem, key) {
            this.predefinedFilterCancelSources[key] &&
                this.predefinedFilterCancelSources[key].cancel('canceled-previous-preferred-count-call');
            this.predefinedFilterCancelSources[key] = this.transportListApi.createCancelTokenSource();

            try {
                const result = await this.transportListApi.filter(
                    predefinedFilterItem,
                    null,
                    null,
                    this.predefinedFilterCancelSources[key],
                    {
                        countOnly: true,
                    }
                );

                return result.count;
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-preferred-count-call') {
                    this.$logger().error(err);
                }
            }
        },

        handleListGrouping(value) {
            if (this.transports?.items) {
                this.transports.items = [];
            }
            this.$store.commit('localPreferences/setIsTransportHubGrouped', value);
            this.updateDefaultSort();
            this.refreshList(false, true, true);
        },

        updateDefaultSort() {
            const isCurrentSortAvailable = this.sortOptions.includes(this.selectedSortOption);

            if (!this.filter.sortBy || !isCurrentSortAvailable) {
                const sortFilter = this.predefinedFilters[this.activePredefinedFilter];
                this.filter.sortBy = sortFilter.sortBy;
                this.filter.sortDirection = sortFilter.sortDirection;
            }
        },

        goToParent() {
            if (this.parentRoute) {
                this.$router.push(this.parentRoute).catch(() => {});
            }
        },
    },
});
</script>

<style lang="scss">
.transport-hub__advanced-filter-bar {
    .select-input {
        display: none;
    }
}

.transport-hub__flyout-filters {
    margin-top: 15px;
}

.transport-hub__inline-filter {
    background-color: $color-white;
    box-shadow: $boxShadow-bottomShort;
    margin-bottom: 30px;
}

.transport-hub__more-results-button {
    margin-bottom: 20px;
}

.transport-hub__planed-details {
    margin-left: 20px;
    display: inline-block;

    > * {
        margin-right: 10px;
    }
}

.transport-hub__highlight-icon--exclusive-info {
    margin-left: 10px;
    margin-bottom: 2px;
}

.transport-hub__adress-inline + .transport-hub__adress-inline {
    margin-top: 20px;
}

.transport-hub-platform__rating + .transport-hub-platform__rating {
    margin-top: 10px;
}

.transport-hub-platform__rating-text {
    margin-bottom: 5px;
}

.transport-hub--platform {
    .transport-hub__grouping-wrapper {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 20px;
        align-items: center;
    }
}
</style>

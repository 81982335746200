import { UPDATE_ACCOMPANYING_DOCUMENT_NUMBER } from '@/services/TransportActions';
import { useNamespacedActions } from 'vuex-composition-helpers';
import { useCan, useRouteContext } from '@/utils/composition-helper';
import { unref, computed } from 'vue';
import { CONTEXT_CLIENT, CONTEXT_CARRIER, CONTEXT_PLATFORM } from '@/constants/context';
export const useAccompanyingDocumentNumber = (transportMaybeRef) => {
    const { set: setTransportActions } = useNamespacedActions('transportActions', ['set']);
    const { routeContext, inRouteContext } = useRouteContext();
    const openUpdateAccompanyingDocumentNumberAction = () => {
        setTransportActions({
            action: UPDATE_ACCOMPANYING_DOCUMENT_NUMBER,
            transport: unref(transportMaybeRef),
            context: routeContext.value,
        });
    };
    const can = useCan().value;
    const hasGeneralPrivilegeForAccompanyingDocumentNumber = (transport) => {
        if (transport.type !== 'disposal')
            return false;
        return can('updateDisposalTransportAccompanyingDocumentNumber', transport) && !!transport?.product?.isDangerous;
    };
    const isAccompanyingDocumentNumberUpdatable = computed(() => {
        const transport = unref(transportMaybeRef);
        if (transport.type !== 'disposal')
            return false;
        if (!hasGeneralPrivilegeForAccompanyingDocumentNumber(transport))
            return false;
        return !!transport.accompanyingDocumentNumber;
    });
    const isAccompanyingDocumentNumberAddable = computed(() => {
        const transport = unref(transportMaybeRef);
        if (transport.type !== 'disposal')
            return false;
        if (!hasGeneralPrivilegeForAccompanyingDocumentNumber(transport))
            return false;
        return !transport.accompanyingDocumentNumber && inRouteContext(CONTEXT_CARRIER, CONTEXT_PLATFORM);
    });
    const shouldShowWarningAboutMissingAccompanyingNumber = computed(() => {
        const transport = unref(transportMaybeRef);
        if (transport.type !== 'disposal')
            return false;
        if (transport.accompanyingDocumentNumber)
            return false;
        if (!inRouteContext(CONTEXT_CARRIER))
            return false;
        return isAccompanyingDocumentNumberAddable.value && transport?.status !== 'assigned';
    });
    const shouldShowNoticeForClientToCreateAccompanyingDocumentNumber = computed(() => {
        const transport = unref(transportMaybeRef);
        if (transport.type !== 'disposal')
            return false;
        const isClient = inRouteContext('client');
        return isClient && transport?.product?.isDangerous && !transport?.accompanyingDocumentNumber;
    });
    const showAccompanyingDocumentNumberForDetailedTransportItemView = computed(() => {
        const transport = unref(transportMaybeRef);
        if (transport.type !== 'disposal')
            return false;
        const isClientOrPlatformAdmin = inRouteContext(CONTEXT_CLIENT, CONTEXT_PLATFORM);
        return isClientOrPlatformAdmin && transport?.product?.isDangerous;
    });
    return {
        openUpdateAccompanyingDocumentNumberAction,
        isAccompanyingDocumentNumberUpdatable,
        isAccompanyingDocumentNumberAddable,
        shouldShowWarningAboutMissingAccompanyingNumber,
        shouldShowNoticeForClientToCreateAccompanyingDocumentNumber,
        showAccompanyingDocumentNumberForDetailedTransportItemView,
    };
};

import { useLayoutEffect, useRef } from 'react';
// See https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md#internal-implementation
// See https://github.com/scottrippey/react-use-event-hook/blob/86f09668232801ceb3d14e9592e7a3094b585113/src/useEvent.ts
export function useEvent(handler) {
    const handlerRef = useRef(null);
    useLayoutEffect(() => {
        handlerRef.current = handler;
    });
    const stableRef = useRef(null);
    if (!stableRef.current) {
        stableRef.current = function () {
            // eslint-disable-next-line prefer-rest-params
            return handlerRef.current?.apply(this, arguments);
        };
    }
    return stableRef.current;
}

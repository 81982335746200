import { ChevronLeftIcon, ChevronRightIcon } from '@schuettflix/icons-react';
import React, { useMemo } from 'react';
import { Combobox } from '@schuettflix/react-components';
export const Pagination = ({ pageNumber = 1, pageSize = 25, totalPages, totalItems, onChangePageNumber, }) => {
    const pageOptions = useMemo(() => Array.from({ length: totalPages }, (_val, i) => ({
        value: i + 1,
        label: (i + 1).toString(),
    })), [totalPages]);
    const nextPage = pageNumber === totalPages ? pageNumber : pageNumber + 1;
    const previousPage = pageNumber === 1 ? pageNumber : pageNumber - 1;
    const calculatePageRange = useMemo(() => {
        const startRange = (pageNumber - 1) * pageSize + 1;
        let endRange;
        if (startRange + pageSize - 1 > totalItems) {
            endRange = totalItems;
        }
        else {
            endRange = startRange + pageSize - 1;
        }
        return `${startRange}-${endRange}`;
    }, [pageNumber, pageSize, totalItems]);
    return (<div className="flex items-center gap-6">
            <p>{`${calculatePageRange} of ${totalItems}`}</p>
            <Combobox variant="sm" className="mr-2 h-12 w-[160px] rounded px-4 outline-none" label={''} value={pageNumber} options={pageOptions} onChange={(value) => {
            if (value !== null) {
                onChangePageNumber(value);
            }
        }}/>
            <p>{`of ${totalPages}`}</p>
            <button>
                <ChevronLeftIcon onClick={() => onChangePageNumber(previousPage)} disabled={pageNumber === 1}/>
            </button>
            <button>
                <ChevronRightIcon onClick={() => onChangePageNumber(nextPage)} disabled={pageNumber === totalPages}/>
            </button>
        </div>);
};

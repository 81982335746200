<template>
    <HeaderBarItem v-if="isVisible" class="contact-options">
        <SfIconButton :has-background="false" @click="openContactFlyout">
            <template #icon="iconProps"><SfContactIcon v-bind="iconProps" /></template>
        </SfIconButton>

        <Flyout
            v-if="isVisible"
            :active="isOpen"
            :headline="$t('components.contactOptions.headline')"
            size="small"
            @closed="closeContactFlyout()"
        >
            <Tile v-if="isPlatform && item" no-border>
                <Words
                    bold
                    muted
                    class="contact-options__description"
                    v-html="$t(`components.contactOptions.headlines.client`)"
                />

                <List class="contact-options__list">
                    <ContactItem :organization="item.client" type="company" />
                    <ContactItem v-if="item.supervisor" :person="item.supervisor" type="supervisor" />
                    <ContactItem
                        v-if="item.supervisor && item.supervisor.id !== item.createdBy.id"
                        :person="item.createdBy"
                        type="applicant"
                    />
                </List>

                <div v-if="item.carrier">
                    <Words
                        bold
                        muted
                        class="contact-options__description"
                        v-html="$t(`components.contactOptions.headlines.carrier`)"
                    />

                    <List class="contact-options__list">
                        <ContactItem :organization="item.carrier" type="company" />
                        <ContactItem v-if="item.driver" :person="item.driver" type="driver" />
                    </List>
                </div>

                <div v-if="item.supplier">
                    <Words
                        bold
                        muted
                        class="contact-options__description"
                        v-html="$t(`components.contactOptions.headlines.supplier`)"
                    />

                    <List class="contact-options__list">
                        <ContactItem :organization="item.supplier" type="company" />
                    </List>
                </div>

                <div v-else-if="item.lineItemGroups[0] && item.lineItemGroups[0].supplier">
                    <Words
                        bold
                        muted
                        class="contact-options__description"
                        v-html="$t(`components.contactOptions.headlines.supplier`)"
                    />

                    <List class="contact-options__list">
                        <ContactItem :organization="item.lineItemGroups[0].supplier" type="company" />
                    </List>
                </div>
            </Tile>

            <Tile v-if="isCarrier" no-border>
                <Words
                    class="contact-options__description"
                    v-html="
                        $t(`components.contactOptions.descriptions.carrierToClient`, {
                            number: item.number,
                        })
                    "
                />

                <List class="contact-options__list">
                    <ContactItem :person="item.supervisor" type="supervisor" />
                    <ContactItem
                        v-if="item.supervisor.id !== item.createdBy.id"
                        :person="item.createdBy"
                        type="applicant"
                    />
                    <ContactItem :organization="item.client" type="company" />
                </List>
            </Tile>

            <Tile v-if="isClient" no-border>
                <Words
                    class="contact-options__description"
                    v-html="
                        $t(`components.contactOptions.descriptions.clientToCarrier`, {
                            number: item.number,
                        })
                    "
                />

                <List class="contact-options__list">
                    <ContactItem :person="item.driver" type="driver" />
                    <ContactItem :organization="item.carrier" type="company" />
                </List>
            </Tile>
        </Flyout>
    </HeaderBarItem>
</template>

<script>
import { statusIsBetween } from '@/services/utils/transport';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import routeContext from '@/plugins/mixins/routeContext';

import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Flyout from '@/components/Layout/Flyout';
import Tile from '@/components/Layout/Tile';
import List from '@/components/List/List';
import ContactItem from '@/components/List/ContactItem';
import Words from '@/components/Typography/Words';
import { CONTEXT_CARRIER, CONTEXT_CARRIER_DRIVER, CONTEXT_CLIENT, CONTEXT_PLATFORM } from '@/constants/context';
import { SfContactIcon, SfIconButton } from '@schuettflix/vue-components';

export default {
    name: 'ContactOptions',
    components: {
        HeaderBarItem,
        Flyout,
        Tile,
        List,
        ContactItem,
        Words,
        SfContactIcon,
        SfIconButton,
    },
    mixins: [eventHubMixin, routeContext],
    props: {
        item: {
            type: Object,
            required: true,
        },
        contactOpen: {
            type: Boolean,
            default: false,
        },
        identifier: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        isCarrier() {
            return this.inRouteContext(CONTEXT_CARRIER, CONTEXT_CARRIER_DRIVER);
        },

        isClient() {
            return this.inRouteContext(CONTEXT_CLIENT);
        },

        isPlatform() {
            return this.inRouteContext(CONTEXT_PLATFORM);
        },

        isVisible() {
            return this.identifier === 'transport'
                ? (this.isClient &&
                      this.item.type === 'delivery' &&
                      statusIsBetween(this.item.status, 'planned', 'confirmation_pending')) ||
                      (this.isCarrier && statusIsBetween(this.item.status, 'assigned', 'confirmation_pending')) ||
                      this.isPlatform
                : true;
        },
    },
    created() {
        this.subscribe(`contactFlyoutOpen.${this.identifier}`, openState => {
            this.isOpen = openState;
        });
    },
    methods: {
        openContactFlyout() {
            this.$eventHub.$emit(`contactFlyoutOpen.${this.identifier}`, true);
        },

        closeContactFlyout() {
            this.$eventHub.$emit(`contactFlyoutOpen.${this.identifier}`, false);
        },
    },
};
</script>

<style lang="scss">
.contact-options {
}

.contact-options__description strong {
    color: $color-red;
}

.contact-options__list {
    padding-top: 20px;
    margin-bottom: 40px;
}
</style>

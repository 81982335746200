import { useTranslation } from 'react-i18next';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import store from '@/store';
import { CONSTRUCTION_PROJECT_ADMIN_EDIT_ROUTE, CONSTRUCTION_PROJECT_CLIENT_SETTINGS_EDIT_ROUTE, CONSTRUCTION_PROJECT_CLIENT_SETTINGS_ROUTE, CONSTRUCTION_PROJECT_DETAILS_ROUTE, } from '@/constructionProjects/constants';
import { MessageBox } from '@schuettflix/react-components';
import { CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE, CONSTRUCTION_PROJECT_ADMIN_SETTINGS_ROUTE, } from '@/modules/constructionProjects/pages/routes';
export function WarningSection({ constructionProject, className }) {
    const { vueRouter } = useVueRouter();
    if (constructionProject.orderConfirmationMails?.length > 0) {
        return null;
    }
    const navigateToForm = async () => {
        store.commit('constructionProject/setDetailsPageEntryPoint', {
            name: CONSTRUCTION_PROJECT_DETAILS_ROUTE,
        });
        await vueRouter.push({
            name: `${CONSTRUCTION_PROJECT_DETAILS_ROUTE}__${CONSTRUCTION_PROJECT_CLIENT_SETTINGS_ROUTE}__${CONSTRUCTION_PROJECT_CLIENT_SETTINGS_EDIT_ROUTE}`,
            params: { id: constructionProject.id },
        });
    };
    return <MissingEmailWarning onClick={navigateToForm} className={className}/>;
}
export function WarningSectionAdmin({ constructionProject, className }) {
    const { vueRouter } = useVueRouter();
    if (constructionProject.orderConfirmationMails?.length > 0) {
        return null;
    }
    const navigateToForm = async () => {
        store.commit('constructionProject/setDetailsPageEntryPoint', {
            name: CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE,
        });
        await vueRouter.push({
            name: `${CONSTRUCTION_PROJECT_ADMIN_DETAILS_ROUTE}__${CONSTRUCTION_PROJECT_ADMIN_SETTINGS_ROUTE}__${CONSTRUCTION_PROJECT_ADMIN_EDIT_ROUTE}`,
            params: { id: constructionProject.id },
        });
    };
    return <MissingEmailWarning onClick={navigateToForm} className={className}/>;
}
function MissingEmailWarning({ className, onClick }) {
    const { t } = useTranslation();
    return (<MessageBox type="warning" className={className}>
            <p className="font-copy-md-strong pb-1">
                {t('pages.constructionProject.detailsPage.warning.missingEmail.title')}
            </p>
            <p className="font-copy-md">
                {t('pages.constructionProject.detailsPage.warning.missingEmail.description')}
            </p>
            <div className="mt-4 flex">
                <button onClick={onClick}>
                    <span className="font-copy-md-strong">
                        {t('pages.constructionProject.detailsPage.warning.missingEmail.button.label')} ›
                    </span>
                </button>
            </div>
        </MessageBox>);
}

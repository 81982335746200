/**
 * Defines to which entity a (document) number belongs to.
 *
 * @see https://github.com/schuettflix/backend/blob/main/src/DocumentNumber/Enum/DocumentType.php
 */
export var NumberType;
(function (NumberType) {
    NumberType[NumberType["UNKNOWN"] = 0] = "UNKNOWN";
    NumberType[NumberType["INVOICE"] = 1] = "INVOICE";
    NumberType[NumberType["CREDIT_NOTE"] = 2] = "CREDIT_NOTE";
    NumberType[NumberType["ORDER"] = 3] = "ORDER";
    NumberType[NumberType["PROJECT"] = 4] = "PROJECT";
    NumberType[NumberType["JOB"] = 5] = "JOB";
})(NumberType || (NumberType = {}));
const regularExpressions = new Map([
    [NumberType.INVOICE, /^[A-Z]{2}[156]\d{9}$/],
    [NumberType.CREDIT_NOTE, /^[A-Z]{2}[2347]\d{9}$/],
    [NumberType.ORDER, /^[A-Z]{2}\d{9}$/],
    [NumberType.PROJECT, /^PJ\d{2}-\d{5}$/],
    [NumberType.JOB, /^[A-Z]{2}\d{9}-\d{3}$/],
]);
/**
 * Tries to find out what kind of entity the number belongs to.
 */
export function fromNumberAsString(input) {
    for (const [type, regex] of regularExpressions) {
        if (regex.test(input)) {
            return type;
        }
    }
    return NumberType.UNKNOWN;
}

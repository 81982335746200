var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isVisible)?_c('div',{staticClass:"message-bar",class:{
        'message-bar--error': _vm.error,
        'message-bar--info': _vm.info,
        'message-bar--success': _vm.success,
        'message-bar--warning': _vm.warning,
        'message-bar--padded-bottom': _vm.action,
    }},[_c('div',{staticClass:"message-bar__icon"},[(_vm.useInfoIcon)?_c('div',{attrs:{"data-test":"info-icon"}},[_c('InfoIcon',{attrs:{"width":"18","height":"18"}})],1):_c('div',{attrs:{"data-test":"warning-icon"}},[_c('WarningIcon',{attrs:{"width":"18","height":"18"}})],1)]),_c('div',{staticClass:"message-bar__container"},[(_vm.hasHeadline)?_c('header',{staticClass:"message-bar__headline"},[_vm._t("header")],2):_vm._e(),_c('main',{staticClass:"message-bar__content"},[_vm._t("default")],2),(_vm.action)?_c('footer',[_vm._t("action",function(){return [_c('a',{staticClass:"message-bar__link",attrs:{"href":_vm.action.href}},[_c('Words',{attrs:{"span":"","bold":""}},[_vm._v(_vm._s(_vm.action.label))]),_c('Words',{staticClass:"message-bar__link-icon",attrs:{"span":""}},[_vm._v("❯")])],1)]})],2):_vm._e()]),(_vm.closable)?_c('div',{staticClass:"message-bar__icon-cancel"},[_c('Button',{on:{"click":_vm.clear}},[_c('CancelCrossIcon',{attrs:{"width":"18","height":"18"}})],1)],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
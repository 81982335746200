import { computed, onUnmounted, ref, unref, watch } from 'vue';
import { useCan } from '@/utils/composition-helper';
import { CAPTURE_TRANSPORT } from '@/services/TransportActions';
import { isRequired, minLength, maxLength } from '@/services/validation/rules';
import { useStateful } from '@/services/stateful/stateful';
import useValidator from '@/services/validation/validator';
import { useTransportAction } from '@/pages/Transport/Actions/composables/useTransportAction';
import { makeCaptureTransportService, } from '@/services/Transport/CaptureTransportService';
import { useVehicleClasses } from '@/pages/Transport/Actions/TransportCapture/useVehicleClasses';
import { ensurePHPTimestamp } from '@/services/utils/date';
import mitt from 'mitt';
function weighingAtPointRequired(options) {
    const { weighingAtLoadingPoint, weighingAtUnloadingPoint, billingMethod } = options;
    if (billingMethod !== 'weight') {
        return { loading: false, unloading: false };
    }
    return {
        loading: weighingAtLoadingPoint,
        unloading: weighingAtUnloadingPoint,
    };
}
export function useTransportCaptureShipmentAction({ onCloseHandler, initialLoadingValues, initialUnloadingValues, }) {
    const can = useCan();
    const { updateTransport, transport: transportAllTypes, context } = useTransportAction(CAPTURE_TRANSPORT);
    const transport = transportAllTypes;
    if (!transport.value)
        throw Error('transport is not defined');
    if (transport.value.type !== 'shipment')
        throw Error('transport is not shipment');
    if (!can.value('transportCapture', transport.value)) {
        throw new Error('Unable to capture transport');
    }
    const { isPending, stateful, statefulRun } = useStateful();
    const serviceDate = ref(null);
    const externalLicensePlate = ref();
    const externalCarrierOrganizationName = ref(null);
    const loadingQty = ref();
    const loadingImages = ref([]);
    const loadingDocuments = ref([]);
    const loadingDate = ref(null);
    const loadingSignee = ref(null);
    const loadingWeighingNumber = ref(null);
    const unloadingQty = ref();
    const unloadingImages = ref([]);
    const unloadingDocuments = ref([]);
    const unloadingDate = ref(null);
    const unloadingSignee = ref(null);
    const unloadingWeighingNumber = ref(null);
    const { vehicleClass, refresh, vehicleClassList } = useVehicleClasses({
        vehicleClassId: transport.value.vehicleClassId,
    });
    statefulRun('preload', async () => {
        await refresh();
    });
    const loadingDateMax = Date.now();
    const unloadingDateMax = Date.now();
    const { loading: isWeighingRequiredAtLoadingPoint, unloading: isWeighingRequiredAtUnloadingPoint } = weighingAtPointRequired({
        billingMethod: transport.value.billingMethod,
        weighingAtLoadingPoint: !!transport.value.weighingAtLoadingPoint,
        weighingAtUnloadingPoint: !!transport.value.weighingAtUnloadingPoint,
    });
    const getProductUseCases = (isWeighingRequired) => {
        // if weighing is required, we do ocr, so we need to have ocr activated product use cases
        if (unref(isWeighingRequired))
            return {
                image: 'weighing_note_image',
                document: 'weighing_note_document',
            };
        // if weighing is not required, we use not ocr activated product use cases
        return {
            image: 'delivery_confirmation_image',
            document: 'delivery_confirmation_document',
        };
    };
    const loadingProductUseCases = computed(() => {
        return getProductUseCases(isWeighingRequiredAtLoadingPoint);
    });
    const unloadingProductUseCases = computed(() => {
        return getProductUseCases(isWeighingRequiredAtUnloadingPoint);
    });
    /**
     * OCR Tracking
     */
    const changedLoadingValuesToBeTracked = ref([]);
    watch(initialLoadingValues, () => {
        changedLoadingValuesToBeTracked.value = [];
    }, { deep: true });
    const changedUnloadingValuesToBeTracked = ref([]);
    watch(initialUnloadingValues, () => {
        changedUnloadingValuesToBeTracked.value = [];
    }, { deep: true });
    watch([loadingQty, loadingDate, loadingWeighingNumber], ([loadingQty, loadingDate, loadingWeighingNumber]) => {
        const previous = unref(initialLoadingValues);
        if (!changedLoadingValuesToBeTracked.value.includes('quantity') &&
            'quantity' in previous &&
            loadingQty !== previous.quantity)
            changedLoadingValuesToBeTracked.value.push('quantity');
        if (!changedLoadingValuesToBeTracked.value.includes('date') &&
            'date' in previous &&
            loadingDate !== previous.date)
            changedLoadingValuesToBeTracked.value.push('date');
        if (!changedLoadingValuesToBeTracked.value.includes('number') &&
            'number' in previous &&
            loadingWeighingNumber !== previous.number)
            changedLoadingValuesToBeTracked.value.push('number');
    });
    watch([unloadingQty, unloadingDate, unloadingWeighingNumber], ([unloadingQty, unloadingDate, unloadingWeighingNumber]) => {
        const previous = unref(initialUnloadingValues);
        if (!changedUnloadingValuesToBeTracked.value.includes('quantity') &&
            'quantity' in previous &&
            unloadingQty !== previous.quantity)
            changedUnloadingValuesToBeTracked.value.push('quantity');
        if (!changedUnloadingValuesToBeTracked.value.includes('date') &&
            'date' in previous &&
            unloadingDate !== previous.date)
            changedUnloadingValuesToBeTracked.value.push('date');
        if (!changedUnloadingValuesToBeTracked.value.includes('number') &&
            'number' in previous &&
            unloadingWeighingNumber !== previous.number)
            changedUnloadingValuesToBeTracked.value.push('number');
    });
    const validationRules = computed(() => {
        const rules = {
            serviceDate: [isRequired()],
        };
        const loadingFilesCount = loadingDocuments.value.length + loadingImages.value.length;
        const unloadingFilesCount = unloadingDocuments.value.length + unloadingImages.value.length;
        if (!loadingFilesCount)
            rules.loadingImages = [isRequired()];
        if (!unloadingFilesCount)
            rules.unloadingImages = [isRequired()];
        if (isWeighingRequiredAtLoadingPoint) {
            rules.loadingQty = [isRequired()];
            rules.loadingDate = [isRequired()];
            rules.loadingWeighingNumber = [isRequired()];
        }
        if (isWeighingRequiredAtUnloadingPoint) {
            rules.unloadingQty = [isRequired()];
            rules.unloadingDate = [isRequired()];
            rules.unloadingWeighingNumber = [isRequired()];
        }
        if (transport.value?.forSubcontractor) {
            rules.externalLicensePlate = [isRequired()];
            rules.externalCarrierOrganizationName = [isRequired(), minLength(3), maxLength(100)];
        }
        return rules;
    });
    const validator = useValidator(validationRules, {
        serviceDate,
        externalLicensePlate,
        externalCarrierOrganizationName,
        loadingQty,
        loadingDate,
        loadingWeighingNumber,
        unloadingQty,
        unloadingDate,
        unloadingWeighingNumber,
    });
    const hasValidData = computed(() => validator.validate(true));
    // Reset weighing information, since vehicle class changed
    watch(() => vehicleClass.value, (prev, next) => {
        if (prev?.id !== next?.id) {
            loadingQty.value = null;
            unloadingQty.value = null;
        }
    });
    const preparePayloadFromState = () => ({
        vehicleClassId: transport.value?.forSubcontractor ? vehicleClass.value?.id : undefined,
        externalLicensePlate: transport.value?.forSubcontractor ? externalLicensePlate.value : undefined,
        externalCarrierOrganizationName: transport.value?.forSubcontractor
            ? externalCarrierOrganizationName.value
            : undefined,
        confirmLoadingDocuments: loadingDocuments.value,
        confirmLoadingImages: loadingImages.value,
        confirmLoadingName: loadingSignee.value ?? undefined,
        loadingDate: ensurePHPTimestamp(loadingDate.value),
        loadingWeight: loadingQty.value ?? undefined,
        loadingWeighingNumber: loadingWeighingNumber.value ?? undefined,
        confirmUnloadingDocuments: unloadingDocuments.value,
        confirmUnloadingImages: unloadingImages.value,
        confirmUnloadingName: unloadingSignee.value ?? undefined,
        unloadingDate: ensurePHPTimestamp(unloadingDate.value),
        unloadingWeight: unloadingQty.value ?? undefined,
        unloadingWeighingNumber: unloadingWeighingNumber.value ?? undefined,
        serviceDate: ensurePHPTimestamp(serviceDate.value),
    });
    const emitter = mitt();
    const onSubmissionSuccessful = (callback) => {
        emitter.on('submissionSuccessful', callback);
    };
    onUnmounted(() => emitter.all.clear());
    const handleSubmission = async () => {
        await validator.validate();
        if (validator.hasAnyErrors() && !validator.isValid.value)
            return;
        await stateful('handleSubmission', async () => {
            if (!context.value)
                throw Error('context is not defined');
            if (!transport.value)
                throw Error('transport is not defined');
            const payload = preparePayloadFromState();
            try {
                const captureTransportService = makeCaptureTransportService(context.value);
                const nextTransport = await captureTransportService.captureShipmentTransport(transport.value.id, payload);
                updateTransport(nextTransport);
                onCloseHandler && onCloseHandler();
                emitter.emit('submissionSuccessful');
            }
            catch (err) {
                validator.applyServerValidation(err, {
                    general: ['#'],
                    // loading
                    loadingImages: ['loading.confirmLoadingImages', 'loading.confirmLoadingSignature'],
                    loadingDocuments: ['loading.confirmLoadingDocuments'],
                    loadingQty: 'loading.loadingWeight',
                    loadingSignee: 'loading.confirmLoadingName',
                    loadingWeighingNumber: 'loading.loadingWeighingNumber',
                    loadingDate: 'loadingDate',
                    // unloading
                    unloadingImages: ['loading.confirmUnloadingImages', 'loading.confirmUnloadingSignature'],
                    unloadingDocuments: ['loading.confirmUnloadingDocuments'],
                    unloadingQty: 'unloading.unloadingWeight',
                    unloadingSignee: 'unloading.confirmUnloadingName',
                    unloadingWeighingNumber: 'unloading.unloadingWeighingNumber',
                    unloadingDate: 'unloadingDate',
                    // others
                    vehicleClass: 'vehicleClassId',
                    externalLicensePlate: 'externalLicensePlate',
                    externalCarrierOrganizationName: 'externalCarrierOrganizationName',
                    serviceDate: 'serviceDate',
                });
            }
        });
    };
    const handleFileDeletedLoading = () => {
        loadingQty.value = undefined;
        loadingDate.value = null;
        loadingSignee.value = null;
        loadingWeighingNumber.value = null;
    };
    const handleFileDeletedUnloading = () => {
        unloadingQty.value = undefined;
        unloadingDate.value = null;
        unloadingSignee.value = null;
        unloadingWeighingNumber.value = null;
    };
    return {
        transport,
        // Loading
        loadingQty,
        loadingImages,
        loadingDocuments,
        loadingSignee,
        loadingDate,
        loadingDateMax,
        loadingWeighingNumber,
        isWeighingRequiredAtLoadingPoint,
        loadingProductUseCases,
        // Unloading
        unloadingQty,
        unloadingImages,
        unloadingDocuments,
        unloadingSignee,
        unloadingDate,
        unloadingDateMax,
        unloadingWeighingNumber,
        isWeighingRequiredAtUnloadingPoint,
        unloadingProductUseCases,
        vehicleClass,
        vehicleClassList,
        serviceDate,
        externalLicensePlate,
        externalCarrierOrganizationName,
        isPending,
        validator,
        hasValidData,
        handleSubmission,
        handleFileDeletedLoading,
        handleFileDeletedUnloading,
        onSubmissionSuccessful,
        changedLoadingValuesToBeTracked,
        changedUnloadingValuesToBeTracked,
    };
}

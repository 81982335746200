<template>
    <LayoutPage is-flyout class="checkout-error-page">
        <template #flyoutHeader>
            <HeaderBar>
                <template #left>
                    <HeaderBarItem v-if="canGoBack" button @click="goBack">
                        <SfSysArrowLeftIcon size="xs" />
                    </HeaderBarItem>
                </template>

                <template #headline>
                    {{ $t('pages.checkout.error.title') }}
                </template>

                <template #right>
                    <HeaderBarItem v-if="!canGoBack" button @click="handleErrorNavigation()">
                        <SfSysCloseIcon size="xs" />
                    </HeaderBarItem>
                </template>
            </HeaderBar>
        </template>

        <div v-if="hasErrors() && getError(getErrorKey())" class="checkout-error-page__error-page">
            <ThumbDownIcon class="checkout-error-page__error-icon" />
            <Headline :level="3">{{ getErrorHeadline(getErrorKey()) }}</Headline>
        </div>

        <template #sticky>
            <ButtonGroup v-if="hasErrors()">
                <ButtonComponent primary @click="handleErrorNavigation()">
                    <template #left>
                        <SfSysArrowLeftIcon size="xs" class="icon--left" theme="dark" />
                    </template>
                    {{ $t(`pages.checkout.error.buttonLabels.${getRedirectErrorRouteName()}`) }}
                </ButtonComponent>
            </ButtonGroup>
        </template>
    </LayoutPage>
</template>

<script>
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import validation from '@/services/validation/mixin';
import { mapGetters, mapState } from 'vuex';
import { ensureJSTimestamp } from '@/services/utils/date';
import { getCheckoutRedirectErrorRouteName } from '@/services/utils/checkout';
import { navigationFailure } from '@/services/utils/router';

import ButtonComponent from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import LayoutPage from '@/components/Layout/Page.v2';
import HeaderBar from '@/components/Header/HeaderBar';
import Headline from '@/components/Typography/Headline';

import ThumbDownIcon from '@/assets/icons/confirmation/error-order.svg';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import { SfSysArrowLeftIcon, SfSysCloseIcon } from '@schuettflix/vue-components';

export default {
    name: 'ErrorPage',
    components: {
        HeaderBarItem,
        ButtonComponent,
        ButtonGroup,
        LayoutPage,
        HeaderBar,
        Headline,
        ThumbDownIcon,
        SfSysArrowLeftIcon,
        SfSysCloseIcon,
    },
    mixins: [validation],
    computed: {
        ...mapState('basket', ['type', 'quote', 'serverError', 'isCustom', 'planningMethod']),
        ...mapGetters('user', {
            currentUserId: 'id',
        }),

        routeType() {
            return `${this.isCustom ? 'custom-' : ''}${this.type}`;
        },

        canGoBack() {
            return !this.serverError.code;
        },
    },
    mounted() {
        // previous errors
        if (this.serverError) {
            this.parseErrors(this.serverError);
        }
    },
    methods: {
        ensureJSTimestamp,

        getErrorKey() {
            const list = [
                'serverError',
                'temporaryUnavailable',
                'requestTimeout',
                'constructionSiteId',
                'productId',
                'inputType',
                'qty',
                'vehicleClassId',
                'shippingMethod',
                'billingMethod',
                'cutOff',
                'shippingDate',
                'deliveryType',
                'organization',
                'type',
                'supplier',
                'factory',
                'supervisor',
                'carrier',
                'loadingSite',
                'unloadingSite',
                'shippingWindow',
                'freightDetails',
                'projectPositionId',
            ];

            return list.find(key => Object.prototype.hasOwnProperty.call(this.errors, key));
        },

        getRedirectErrorRouteName() {
            return getCheckoutRedirectErrorRouteName(
                this.routeType,
                this.getErrorKey(),
                this.planningMethod,
                this.type
            );
        },

        getRedirectErrorRouteParams() {
            let routeParams = {};

            if (this.getErrorKey() === 'supervisor') {
                routeParams = { id: _get(this, 'quote.constructionProjectId') };
            }

            return routeParams;
        },

        getErrorHeadline(errorKey) {
            if (errorKey === 'supervisor') {
                return this.$t('pages.checkout.error.headlines.supervisorDeleted');
            }

            return this.getError(errorKey);
        },

        handleErrorNavigation() {
            this.$store.commit('basket/setServerError', null);

            if (this.getErrorKey() === 'requestTimeout') {
                window.location.reload();
                return;
            }

            this.$router
                .replace({
                    name: this.$root.findRouteName(this.getRedirectErrorRouteName()),
                    params: { ...this.getRedirectErrorRouteParams() },
                })
                .catch(navigationFailure);
        },

        parseErrors(err) {
            this.parseServerValidation(_get(err, 'response.data.errors', {}), {
                constructionSiteId: 'lineItemGroups.*.constructionSite.id',
                loadingSite: [
                    'lineItemGroups.*.loadingLocation.lat',
                    'lineItemGroups.*.loadingLocation.lng',
                    'lineItemGroups.*.loadingAddress.country',
                    'lineItemGroups.*.loadingAddress.state',
                    'lineItemGroups.*.loadingAddress.zip',
                    'lineItemGroups.*.loadingSiteLocation.lat',
                    'lineItemGroups.*.loadingSiteLocation.lng',
                    'lineItemGroups.*.loadingLocation',
                ],
                unloadingSite: [
                    'lineItemGroups.*.unloadingLocation.lat',
                    'lineItemGroups.*.unloadingLocation.lng',
                    'lineItemGroups.*.unloadingAddress.country',
                    'lineItemGroups.*.unloadingAddress.state',
                    'lineItemGroups.*.unloadingAddress.zip',
                    'lineItemGroups.*.unloadingSiteLocation.lat',
                    'lineItemGroups.*.unloadingSiteLocation.lng',
                    'lineItemGroups.*.unloadingLocation',
                ],
                productId: 'lineItemGroups.*.product.id',
                shippingMethod: 'lineItemGroups.*.shippingMethod',
                billingMethod: 'lineItemGroups.*.billingMethod',
                cutOff: 'lineItemGroups.*.cutOff',
                inputType: 'lineItemGroups.*.inputType',
                shippingDate: 'lineItemGroups.*.lineItems.*.shippingDate',
                shippingWindow: [
                    'lineItemGroups.*.loadingDateFrom',
                    'lineItemGroups.*.loadingDateTo',
                    'lineItemGroups.*.loadingTimeFrom',
                    'lineItemGroups.*.loadingTimeTo',
                    'lineItemGroups.*.unloadingDateFrom',
                    'lineItemGroups.*.unloadingDateTo',
                    'lineItemGroups.*.unloadingTimeFrom',
                    'lineItemGroups.*.unloadingTimeTo',
                ],
                qty: 'lineItemGroups.*.qty',
                vehicleClassId: 'lineItemGroups.*.lineItems.*.vehicleClassId',
                organization: 'client.id',
                createdBy: 'createdBy.id',
                supervisor: 'supervisor.id',
                type: 'lineItemGroups.*.type',
                supplier: 'lineItemGroups.*.supplier.id',
                factory: 'lineItemGroups.*.factory.id',
                carrier: ['lineItemGroups.*.carrier', 'lineItemGroups.*.carrier.id'],
                freightDetails: 'lineItemGroups.*.freightDescription',
                projectPositionId: 'lineItemGroups.*.projectPositionId',
            });

            if (
                this.getErrorKey() === 'shippingDate' &&
                (this.deliveryMethod.type === 'flix' || ['loading', 'unloading'].includes(this.planningMethod))
            ) {
                _forEach(err.response.data.errors.lineItemGroups, group => {
                    _forEach(group.lineItems, (error, index) => {
                        this.$store.commit('basket/setTransportError', { index, error });
                    });
                });
            }

            if (err.code === 424) {
                this.errors.temporaryUnavailable = [this.$t('pages.checkout.productSelection.temporaryUnavailable')];
            }

            if (err.code === 408) {
                this.errors.requestTimeout = [this.$t('abstractresource.errors.requestTimeout')];
            }

            if (err.code === 500) {
                this.errors.serverError = [this.$t('abstractresource.errors.serverError')];
            }
        },

        goBack() {
            const deliveryMethod = this.$store.state.basket.deliveryMethod;

            let routeName = this.$route.meta.previous;

            if (!deliveryMethod) {
                routeName = this.$route.meta.previous;
            } else if (deliveryMethod.methodName && deliveryMethod.methodName === 'immediate') {
                routeName = this.$route.meta.previous;
            } else if (deliveryMethod.methodName && deliveryMethod.methodName === 'flix') {
                routeName = this.$route.meta.previousDateSelect || this.$route.meta.previous;
            } else if (deliveryMethod.type && deliveryMethod.type === 'default') {
                routeName = this.$route.meta.previousWindowSelect || this.$route.meta.previous;
            }

            this.$store.commit('basket/setServerError', null);
            this.$router.push({ name: this.$root.findRouteName(routeName) }).catch(navigationFailure);
        },
    },
};
</script>

<style lang="scss" scoped>
.checkout-error-page {
    background-color: $color-lightMediumGrey;
}

.checkout-error-page__error-page {
    display: flex;
    flex-flow: column nowrap;
    align-content: center;
    min-height: 70vh;
    padding-left: 20px;
    padding-right: 20px;
}

.checkout-error-page__error-icon {
    width: auto;
    margin: 30px auto 10px;
}
</style>

import React, { Suspense } from 'react';
import { asVueComponent } from '@/reactBridge/asVueComponent';
import { LoadingSpinner } from '@schuettflix/react-components';
import { GenericPositionDetails } from './components/GenericPositionDetails';
const _GenericPositionDetailsPage = () => {
    return (<div className="flex flex-col">
            <Suspense fallback={<LoadingSpinner block spaced/>}>
                <GenericPositionDetails />
            </Suspense>
        </div>);
};
export const GenericPositionDetailsPage = asVueComponent(_GenericPositionDetailsPage);

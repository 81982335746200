import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Link } from '@schuettflix/react-components';
import { VueRouterLink } from '@/reactBridge/VueRouterLink';
import { FavoriteProjectCard } from '@/constructionProjects/components/FavoriteProjectCard';
import { FavoriteProjectsIcon } from '@/constructionProjects/components/icons/FavoriteProjectsIcon';
import { StarFilledIcon } from '@/constructionProjects/components/icons/StarFilledIcon';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { Grid, Keyboard, Mousewheel, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import './FavoriteProjectSection.scss';
import store from '@/store';
import { CONSTRUCTION_PROJECT_LIST_ROUTE } from '../constants';
import { constructionProjectFavoritesQuery } from '../queries';
import clsx from 'clsx';
import { ChevronRightIcon } from '@/constructionProjects/components/icons/ChevronRightIcon';
const FavoriteProjectsPlaceholder = () => {
    const { t } = useTranslation();
    return (<div className="mb-12 rounded border border-dashed border-disabled px-6 pb-6 text-center">
            <FavoriteProjectsIcon className="inline-block"/>
            <p className="font-copy-md mb-4">{t('pages.constructionProject.list.emptyState.message')}</p>
            <VueRouterLink className="flex h-12 w-full items-center justify-center rounded bg-surface-primary" data-test="select-favorite-construction-project-link" to={{ name: CONSTRUCTION_PROJECT_LIST_ROUTE }}>
                <StarFilledIcon className="mr-2 fill-icon-on-primary"/>
                <span className="font-copy-md-strong text-on-primary">
                    {t('pages.constructionProject.list.emptyState.cta')}
                </span>
            </VueRouterLink>
        </div>);
};
const FavoriteProjectsList = ({ projects }) => {
    const { vueRouter } = useVueRouter();
    const swiperRef = useRef();
    const [prevDisabled, setPrevDisabled] = useState(false);
    const [nextDisabled, setNextDisabled] = useState(false);
    const navigateToOrderPage = async (constructionProjectId) => {
        store.commit('constructionProject/setConstructionProjectId', constructionProjectId);
        store.commit('constructionProject/setEntryPoint', { name: 'home' });
        await vueRouter.push({ name: 'order__checkout' });
    };
    return (<div className="favorites-section mb-8 flex items-center justify-between">
            <div className="mr-4 hidden h-[40px] w-[40px] shrink-0 items-center justify-center rounded-full bg-white shadow-low md:flex">
                <ChevronRightIcon className={clsx('rotate-180', {
            'pointer-events-none cursor-default opacity-40': prevDisabled,
        })} size="sm" onClick={() => swiperRef.current?.slidePrev()}/>
            </div>
            <Swiper autoHeight={true} spaceBetween={16} slidesPerView="auto" modules={[Pagination, Mousewheel, Keyboard, Grid]} mousewheel={{
            thresholdDelta: 10,
        }} slideToClickedSlide={true} centeredSlides threshold={5} keyboard pagination={{
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 2,
        }} onSwiper={swiper => {
            swiperRef.current = swiper;
            setPrevDisabled(swiper.isBeginning);
            setNextDisabled(swiper.isEnd);
        }} onSlideChange={() => {
            setPrevDisabled(swiperRef.current?.isBeginning ?? false);
            setNextDisabled(swiperRef.current?.isEnd ?? false);
        }} className="grid flex-shrink pb-8">
                {projects.map(project => (<SwiperSlide key={project.id} className="rounded bg-surface p-4 pt-0 shadow-low" data-test="construction-project-card">
                        {({ isActive }) => (<FavoriteProjectCard {...project} isActive={isActive} onOrderClick={navigateToOrderPage}/>)}
                    </SwiperSlide>))}
                <SwiperSlide className="overflow-visible rounded bg-surface p-4 shadow-low lg:w-1/6">
                    <AllProjectsCard />
                </SwiperSlide>
            </Swiper>
            <div className="ml-4 hidden h-[40px] w-[40px] shrink-0 items-center justify-center rounded-full bg-white shadow-low md:flex">
                <ChevronRightIcon className={clsx('', {
            'pointer-events-none cursor-default opacity-40': nextDisabled,
        })} size="sm" onClick={() => swiperRef.current?.slideNext()}/>
            </div>
        </div>);
};
const AllProjectsCard = () => {
    const { t } = useTranslation();
    const query = useQuery({
        ...constructionProjectFavoritesQuery,
        select: data => data.countNotFavoriteProjects,
    });
    const projectsCount = query.data;
    return (<VueRouterLink to={{ name: CONSTRUCTION_PROJECT_LIST_ROUTE }} className="flex h-full w-full shrink-0 flex-col overflow-visible">
            {projectsCount !== 0 && (<>
                    <p className="font-headline-xl-strong">+{projectsCount}</p>
                    <p className="font-copy-md">{t('pages.constructionProject.favoriteProjects.allProjects.title')}</p>
                </>)}
            <div className="flex flex-grow items-end justify-end">
                <Link strong className="h-auto px-0 py-0" size="sm" label={t('pages.constructionProject.favoriteProjects.allProjects.button')}/>
            </div>
        </VueRouterLink>);
};
export const FavoriteProjects = () => {
    const query = useQuery({
        ...constructionProjectFavoritesQuery,
        select: data => data.favoritedConstructionProjects,
    });
    if (query.data) {
        return query.data.length ? <FavoriteProjectsList projects={query.data}/> : <FavoriteProjectsPlaceholder />;
    }
    return null;
};

<template>
    <div :class="{ [`person-inner--${mode}`]: true, 'person-inner--dark': dark }" class="person-inner">
        <template v-if="mode === 'button'">
            <span v-if="scope.selectedOption" class="person-inner__selected">
                <Avatar
                    :src="scope.selectedOption.image && scope.selectedOption.image.url"
                    class="person-inner__image"
                    size="small"
                />
                <span class="person-inner__name">
                    {{ scope.selectedOption.firstName }}
                    {{ scope.selectedOption.lastName }}
                </span>
            </span>
            <span v-else>{{ scope.label }}</span>
            <ArrowIcon width="14" height="14" class="person-inner__arrow" />
        </template>
        <template v-else>
            <Avatar :src="scope.option.image && scope.option.image.url" class="person-inner__image" size="small" />
            <Words v-if="scope.isActive" red class="person-inner__text">
                {{ scope.option.firstName }} {{ scope.option.lastName }}
            </Words>
            <Words v-else class="person-inner__text"> {{ scope.option.firstName }} {{ scope.option.lastName }} </Words>
            <TickIcon v-if="scope.isActive" class="person-inner__active-icon" />
        </template>
    </div>
</template>

<script>
import Avatar from '@/components/Avatar';

import Words from '@/components/Typography/Words';

import ArrowIcon from '@/assets/icons/regular/arrow-stripeless--down.svg';
import TickIcon from '@/assets/icons/micro/tick.svg';

export default {
    name: 'PersonInner',
    components: {
        Avatar,
        Words,

        ArrowIcon,
        TickIcon,
    },
    props: {
        mode: {
            type: String,
            required: true,
            validator: v => ['button', 'option'].includes(v),
        },
        scope: {
            type: Object,
            required: true,
        },
        dark: {
            type: Boolean,
            default: null,
        },
    },
};
</script>

<style lang="scss">
.person-inner--button {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    // display: flex;
    // width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;
    overflow: hidden;
    height: 70px;
    transition:
        border-color 0.2s ease,
        padding 0.2s ease;

    padding: 27px 40px 22px 15px;
    font-size: 14px;
    font-weight: normal;
    line-height: $line-height-base;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    // -webkit-appearance: none;
    // -moz-appearance: none;

    &.person-inner--dark {
        border-color: transparent;
        box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.1);
    }
}

.person-inner--option {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.person-inner__multiselect {
    > .button {
        width: 100%;
        text-align: left;
    }
}

.person-inner__selected {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.person-inner__image {
    margin-right: 0.5em;
    flex: 0 0 32px;
}

.person-inner__name {
    flex: 1 1;
    text-align: left;
}

.person-inner__button--edit {
    flex: 0 0 32px;
}

.person-inner__text {
    flex-grow: 1;
}

.person-inner__arrow {
    width: 14px;
    height: 14px;
    pointer-events: none;
    position: absolute;
    top: 37px;
    transform: translateY(-50%);
    right: 17px;
    z-index: 11;
    line-height: 1;
    position: absolute;
    transition: top 0.2s ease;
}
</style>

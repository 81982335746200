/**
 * Scroll the viewport to an element
 *
 * @param el
 * @param offsetY
 */
export function scrollToElement(el, offsetY = 90) {
    if (!el) return;

    const parent = reverseQuerySelector(el, '.page__main, body');

    if (!parent) return;

    parent.scrollTo({
        top: el.offsetTop - offsetY,
        behavior: 'smooth',
    });
}

/**
 * Reverse search parent by query
 *
 * @param el
 * @param parentSelector
 * @return {*}
 */
export function reverseQuerySelector(el, parentSelector) {
    const parent = el.parentNode;
    const result = parent.querySelector(parentSelector);
    return result ? result : reverseQuerySelector(parent, parentSelector);
}

/**
 * Focus top error on page (not component aware)
 *
 * @param {number} delay
 */
export function focusTopError(delay = 200) {
    setTimeout(() => {
        const firstError = document.querySelector(
            '.text-field--invalid, .input-field--invalid, .select-input--invalid, .fake-picker--invalid, .time-picker--invalid, .multiselect--invalid, [class*="--invalid"]'
        );

        if (!firstError) {
            return;
        }

        if (firstError.classList.contains('input-field--invalid')) {
            firstError.querySelector('input')?.focus();
        } else if (firstError.classList.contains('select-input--invalid')) {
            firstError.querySelector('select')?.focus();
        } else if (firstError.classList.contains('multiselect--invalid')) {
            firstError.querySelector('button')?.focus();
        } else {
            scrollToElement(firstError);
        }
    }, delay);
}

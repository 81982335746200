<template>
    <div class="playground playground--styleguide">
        <h1>Welcome to the Playground</h1>

        <ReactBridge
            :react-component="SwipeButton"
            :props="{
                idleMessage: $t('pages.checkout.summary.actions.sendRequest'),
                successMessage: $t('pages.checkout.summary.actions.placeOrderSuccess'),
                processingMessage: $t('pages.checkout.summary.actions.placeOrderProcessingSuccess'),
                onSuccess: () => {},
                disabled: false,
            }"
        />

        <ToggleSwitch v-model="isToggled" />

        <br /><br />

        <ToggleSwitch v-model="isToggled" disabled />

        <br />

        {{ isToggled }}

        <br /><br />

        <button @click="triggerSuccessAlert()">Trigger success</button><br />
        <button @click="triggerErrorAlert()">Trigger error</button><br />
        <button @click="triggerInfoAlert()">Trigger info</button><br />
        <button @click="triggerWarningAlert()">Trigger warning</button>
    </div>
</template>

<script>
import ToggleSwitch from '@/components/Form/ToggleSwitch';
import { SwipeButton } from '@/_components/SwipeButton/SwipeButton';
import ReactBridge from '@/reactBridge/ReactBridge';

import Toaster from '@/services/Toaster';

export default {
    name: 'ButtonsPlaygroundPage',
    components: {
        ToggleSwitch,
        ReactBridge,
    },
    data() {
        return {
            isOpen: true,
            progress: null,
            isComplete: null,
            SwipeButton,
            isToggled: false,
        };
    },
    methods: {
        triggerSuccessAlert() {
            Toaster.success('Lorem ipsum dolor sit amet consectetur, adipisicing elit. Lorem ipsumd olor sit amet');
        },
        triggerErrorAlert() {
            Toaster.error('Lorem ipsum dolor sit amet consectetur, adipisicing elit. Lorem ipsumd olor sit amet');
        },
        triggerInfoAlert() {
            Toaster.info('Lorem ipsum dolor sit amet consectetur, adipisicing elit. Lorem ipsumd olor sit amet');
        },
        triggerWarningAlert() {
            Toaster.warning('Lorem ipsum dolor sit amet consectetur, adipisicing elit. Lorem ipsumd olor sit amet');
        },

        toggleSwitch() {
            this.isToggled = !this.isToggled;
        },
    },
};
</script>

<style lang="scss">
.playground--styleguide {
    padding: 20px;
}

.purchase-button {
    position: relative;
    background-color: red;
    padding: 5px;
    margin: 70px 0;
    min-height: 70px;
    width: 100%;
    overflow: hidden;
    transition: background-color 0.4s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 12px;
}

.purchase-button__success-bg,
.purchase-button__success-handle {
    background-color: green;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.purchase-button--active .purchase-button__handle-inner {
    background-color: green;

    svg {
        .f-base {
            fill: green;
        }
    }
}

.purchase-button__draggable-container {
    display: flex;
    width: 100%;
    justify-content: stretch;
}

.purchase-button__handle {
    flex: 0 0 70px;
    width: 70px;
    height: 70px;
    background-color: rgba(255, 0, 0, 1);
    transition: background-color 0.2s ease-in;
}

.purchase-button__handle-inner {
    width: 70px;
    height: 70px;
    background-color: rgba(255, 0, 0, 1);
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.purchase-button__handle-icon {
    background-color: white;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;

    svg {
        .f-base {
            fill: red;
        }
    }
}

.purchase-button__right {
    width: 100%;
    height: 70px;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
}

.purchase-button__left {
    position: absolute;
    top: 0;
    left: 0;
    right: 70px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

<template>
    <AddressProgress :list="addressList" :progress="progress()" :orb-padding="56" spaceless>
        <template #default="{ item, isFirst, isLast }">
            <p
                v-if="item.maximumTrafficability && showMaximumTrafficability"
                :class="[
                    'font-copy-sm',
                    'mt-2',
                    {
                        'text-critical': !isUnavailable,
                        'text-subdued': isUnavailable,
                    },
                ]"
            >
                {{
                    $t('pages.transportHub.trafficability', {
                        trafficability: item.maximumTrafficability / 1000,
                    })
                }}
            </p>

            <TransportListBlockShippingDates
                v-if="
                    (showShippingDates && !showOnlyLast() && !showOnlyFirst()) ||
                    (showShippingDates && showOnlyLast() && isLast) ||
                    (showShippingDates && showOnlyFirst() && isFirst)
                "
                :show-loading="
                    item.type === 'loading' && (!isShipmentTransport() || shouldShipmentLocationShown('loading'))
                "
                :show-unloading="
                    item.type === 'unloading' && (!isShipmentTransport() || shouldShipmentLocationShown('unloading'))
                "
                show-shipping-dates
                :transport="transport"
                class="mt-4"
            />

            <p v-if="item.hasInformation && showInformationButtons" class="font-copy-sm mt-2">
                <BaseButton
                    weight-normal
                    adjust-icon
                    align-left
                    :disabled="isUnavailable"
                    @click.stop="handleAddressClick(item)"
                >
                    <SfWarningIcon slot="left" size="xxs" multi-color />
                    {{
                        item.type === 'loading'
                            ? $t('pages.transportHub.informationHint.loading')
                            : $t('pages.transportHub.informationHint.unloading')
                    }}&nbsp;›
                </BaseButton>
            </p>
        </template>
    </AddressProgress>
</template>

<script>
import { transportProgress } from '@/services/utils/transport';

import AddressProgress from '@/components/Transport/AddressProgress';
import BaseButton from '@/components/Button/Button';
import TransportListBlockShippingDates from './TransportListBlockShippingDates';

import TransportAddressListMixin from '@/plugins/mixins/TransportAddressListMixin';

import { SfWarningIcon } from '@schuettflix/vue-components';

import { TRANSPORT_TYPE } from '@/constants/transportTypes';

/**
 * !!! ATTENTION !!!
 * This component and all of it's children can not have computed
 * properties since they introduce a performance issues on unmounting
 */
export default {
    name: 'TransportListBlockAddress',

    components: {
        AddressProgress,
        BaseButton,
        TransportListBlockShippingDates,

        SfWarningIcon,
    },

    mixins: [TransportAddressListMixin],

    props: {
        transport: {
            type: Object,
            required: true,
        },
        isUnavailable: {
            type: Boolean,
            default: false,
        },
        showShippingDates: {
            type: Boolean,
            default: false,
        },
        showMaximumTrafficability: {
            type: Boolean,
            default: false,
        },
        showInformationButtons: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        transportProgress,

        handleAddressClick(item) {
            this.$emit('address-item', item.type || null);
        },

        progress() {
            return transportProgress(this.transport.status);
        },

        isShipmentTransport() {
            return this.transport.type === TRANSPORT_TYPE.SHIPMENT;
        },

        shouldShipmentLocationShown(planningBase) {
            if (this.transport.planningMethod !== 'planned') {
                return true;
            }

            return this.transport.planningBase === planningBase;
        },

        showOnlyFirst() {
            return this.transport.type === TRANSPORT_TYPE.DISPOSAL;
        },

        showOnlyLast() {
            return this.transport.type !== TRANSPORT_TYPE.SHIPMENT && this.transport.type !== TRANSPORT_TYPE.DISPOSAL;
        },
    },
};
</script>

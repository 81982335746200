import { computed, reactive, ref } from 'vue';
import { integer, maxLength, minValue, numeric, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { getEmptyProductForm } from '@/pages/SupplierProducts/factories/product';
import { mapErrors, remapErrors } from '@/services/validation/serverErrorsMapper';
import { GRAIN_SIZE_TYPE, ProductAttributeField as Field } from '@/services/ProductCatalog/constants';
import { optional } from '@/services/validation/validators';
const form = reactive(getEmptyProductForm());
const attributes = ref([]);
const rules = computed(() => {
    return {
        price: {
            required,
        },
        minimumGuaranteedAmount: {
            required,
            integer,
            minValue: minValue(0),
        },
        grainSize: {
            min: {
                required,
                numeric,
            },
            max: {
                required,
                numeric,
            },
        },
        masterProductId: { required },
        category: { required },
        tags: { optional },
        customName: {
            optional,
            maxLength: maxLength(256),
        },
        partnerSku: {
            optional,
            maxLength: maxLength(256),
        },
        available: { optional },
        isBlockedOnSpotMarket: { optional },
        [Field.COLOR]: { optional },
        [Field.GEOLOGY]: { optional },
        [Field.GEOGRAPHY]: { optional },
        [Field.CHARACTERISTICS]: { optional },
        [Field.CERT_1]: { optional },
        [Field.CERT_2]: { optional },
        [Field.CERT_3]: { optional },
        [Field.PROCESSING]: { optional },
        [Field.BRAND]: { optional },
    };
});
const $externalResults = ref({});
const hasGrainSizesAttribute = computed(() => {
    return attributes.value?.some(attribute => attribute.key === GRAIN_SIZE_TYPE);
});
const setServerErrors = (errors) => {
    const remappedErrors = remapErrors(errors);
    $externalResults.value = mapErrors(remappedErrors);
};
const isSubmitted = ref(false);
const v$ = useVuelidate(rules, form, { $externalResults });
export { form, attributes, isSubmitted, setServerErrors, hasGrainSizesAttribute, v$ };

<template>
    <Flyout
        class="factory-draft-flyout"
        screen-name="seller-factory-create"
        size="full"
        :headline="$t('components.factoryManagement.factoryDraftFlyout.addLocation')"
        close-by-x
        :close-text="$t('components.factoryManagement.factoryDraftFlyout.close')"
        background-grey
        :active="isActive"
        appear-from="bottom"
        @closed="clearDataOnClose"
    >
        <FactoryContainer used-in-flyout>
            <FactoryNameAddress
                v-model="factory"
                :submitted="formSubmitted"
                @dirty-check="handleDirtyState"
                @valid-check="handleValidState"
                @datatransfer="updateDataFromOrganization"
            />

            <template #buttons>
                <ButtonGroupWrapper items-right class="factory-draft-flyout__buttons">
                    <i18n
                        path="components.transportListBlock.termsAndPrivacyLabel"
                        tag="span"
                        class="font-copy-xs px-4 text-subdued md:ml-auto"
                    >
                        <a
                            :href="linkLegalTermsAndConditionsSeller"
                            target="_blank"
                            class="font-copy-xs inline text-subdued underline"
                            place="gtc"
                            >{{ $t('components.transportListBlock.termsAndPrivacy.gtcLabel') }}</a
                        >
                        <a
                            :href="linkLegalDataProtectionPolicyGlobal"
                            target="_blank"
                            class="font-copy-xs inline text-subdued underline"
                            place="dpp"
                        >
                            {{ $t('components.transportListBlock.termsAndPrivacy.dppLabel') }}</a
                        >
                    </i18n>
                    <BaseButton
                        primary
                        :disabled="!canSave"
                        data-test="factory-draft-flyout-button"
                        @click="handleFormDataSubmission()"
                        >{{ $t('components.factoryManagement.factoryDraftFlyout.save') }}</BaseButton
                    >
                </ButtonGroupWrapper>
            </template>
        </FactoryContainer>

        <DialogModal v-if="dialogModal.active" :options="dialogModal" @close="closeModal">
            <component
                :is="dialogModal.type"
                @primaryClick="dialogModalFunctionCall({ modalType: dialogModal.type, buttonType: 'primary' })"
                @secondaryClick="dialogModalFunctionCall({ modalType: dialogModal.type, buttonType: 'secondary' })"
            />
        </DialogModal>
    </Flyout>
</template>

<script>
import _clone from 'lodash/clone';

import { mapGetters, mapActions } from 'vuex';

import factoryMixin from '@/plugins/mixins/factoryForm/factory';
import nameAddressMixin from '@/plugins/mixins/factoryForm/nameAddressMixin';
import validate from '@/services/validation/mixin';
import dialogModalMixin from '@/_components/DialogModal/mixins/dialogModal';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';

import FactoryDraftsService from '@/services/FactoryDraftsService';

import DraftCreation from '@/_components/DialogModal/modalContent/DraftCreation';
import BaseButton from '@/components/Button/Button';
import ButtonGroupWrapper from '@/_components/ButtonGroupWrapper/ButtonGroupWrapper';
import DialogModal from '@/_components/DialogModal/DialogModal';
import FactoryContainer from '@/_components/FactoryContainer/FactoryContainer';
import FactoryNameAddress from '@/views/FactoryNameAddress/FactoryNameAddress';
import Flyout from '@/components/Layout/Flyout';
import Toaster from '@/services/Toaster';

import { getEmptyStructure } from '@/views/LocationView/defaultData';

export default {
    name: 'LocationDraftFlyout',
    components: {
        DraftCreation,
        BaseButton,
        ButtonGroupWrapper,
        DialogModal,
        FactoryContainer,
        FactoryNameAddress,
        Flyout,
    },
    mixins: [validate, nameAddressMixin, factoryMixin, dialogModalMixin, eventHubMixin],
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        organizationId: {
            type: [Number, String, null],
            default: null,
        },
    },
    data() {
        return {
            formIsValid: false,
            isDirty: false,
            dialogModal: {},
            formSubmitted: false,
            currentSectionData: null,
            factory: getEmptyStructure(),
            factoryBackup: getEmptyStructure(),
        };
    },
    computed: {
        ...mapGetters('user', ['isPlatformAdministrator']),
        ...mapGetters('factoryManagement', {
            supplierOrganizationId: 'getSupplierOrganizationId',
        }),
        ...mapGetters('platform', ['linkLegalDataProtectionPolicyGlobal', 'linkLegalTermsAndConditionsSeller']),
        canSave() {
            return this.isDirty && this.formIsValid;
        },
    },
    watch: {
        isActive(status) {
            if (status) {
                this.$eventHub.$emit('dialogModalStatus', status);
            }
        },
    },
    methods: {
        ...mapActions('validation', {
            populateErrors: 'populate',
            cleanupErrors: 'cleanup',
        }),
        async saveDraft() {
            try {
                const requestData =
                    !this.isPlatformAdministrator || !this.organizationId
                        ? this.factory
                        : {
                              ...this.factory,
                              supplierOrganizationId: this.supplierOrganizationId || this.organizationId,
                          };

                const factory = await FactoryDraftsService.save(requestData);
                const existingFactory = _clone(this.factory);
                this.$set(this, 'factory', { ...factory, ...existingFactory });
                this.$emit('updateList');
                this.openDialogModalConfirmation();
                this.cleanupErrors();
                return true;
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(this.$t('components.toaster.callbackErrorMessage'));

                if (err.response) {
                    this.populateErrors(err.response?.data);
                }

                return false;
            }
        },
        handleDirtyState(isDirty) {
            this.isDirty = isDirty;
        },
        handleValidState(isValid) {
            this.formIsValid = isValid;
        },
        handleFormDataSubmission() {
            this.$set(this, 'factory', { ...this.factory, ...this.currentSectionData });
            this.saveDraft();
        },
        closeModal() {
            this.dialogModal = {};
        },
        openDialogModalConfirmation() {
            this.dialogModal = {
                type: 'draftCreation',
                active: true,
            };
        },
        clearDataOnClose() {
            const factoryBackupClone = _clone(this.factoryBackup);
            this.$set(this, 'factory', factoryBackupClone);
            this.closeModal();
            this.$emit('closed');
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.factory-draft-flyout {
    &__container {
        max-width: 720px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    &__content {
        background-color: $color-base-grey;
    }
}

.factory__form {
    &__buttons {
        max-width: 760px;
        position: relative;
        width: 100%;

        &.content-overflow {
            margin-left: -15px;
        }

        button {
            margin-left: 40px;

            &.button--default {
                color: $color-darkGrey;
            }
        }

        &__shadow {
            height: 13px;
            left: 20px;
            right: 20px;
            background: linear-gradient(
                to bottom,
                rgba(53, 52, 61, 0) 0%,
                rgba(53, 52, 61, 0.01) 2%,
                rgba(53, 52, 61, 0.64) 98%,
                rgba(53, 52, 61, 0.65) 100%
            );
            position: absolute;
            top: -13px;
            opacity: 10%;
        }
    }
}
</style>

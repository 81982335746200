<template>
    <div class="calendar">
        <div class="calendar__track calendar__track--controls">
            <Button
                class="calendar__control"
                orb
                levitate
                data-test="calendar-button-previous-month"
                :disabled="previousMonthDisabled"
                @click="selectPreviousMonth()"
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.8932 10.95796" width="6" height="12">
                    <polygon
                        points="5.479 0 6.893 1.414 2.828 5.478 6.893 9.543 5.479 10.957 0 5.478 5.479 0"
                        style="fill: #222830"
                    />
                </svg>
            </Button>
            <div class="calendar__current-month" data-test="calendar-current-month">
                {{ $d(getCurrentMonthDate(), 'calendar') }}
            </div>
            <Button
                class="calendar__control"
                orb
                levitate
                place-right
                data-test="calendar-button-next-month"
                :disabled="nextMonthDisabled"
                @click="selectNextMonth()"
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.8932 10.95796" width="6" height="12">
                    <polygon
                        points="1.414 10.957 0 9.543 4.065 5.479 0 1.414 1.414 0 6.893 5.479 1.414 10.957"
                        style="fill: #222830"
                    />
                </svg>
            </Button>
        </div>

        <div class="calendar__track calendar__track--weekdays">
            <div v-for="day in daysOfWeek" :key="day" class="calendar__item calendar__item--day-of-week">
                {{ $t(`weekdaysShort.${day}`) }}
            </div>
        </div>

        <div data-test="calendar-days" class="calendar__track calendar__track--days">
            <div
                v-for="day in daysInView"
                :key="day"
                :style="firstDayOffsetStyles(day)"
                :class="{
                    'calendar__item--disabled': isDayDisabled(day),
                    'calendar__item--selected': isDaySelected(day),
                    'calendar__item--in-range': !isDaySelected(day) && isInRange(day),
                    'calendar__item--is-range-start': isDaySelected(day) && isRangeStart(day),
                    'calendar__item--is-range-end': isDaySelected(day) && isRangeEnd(day),
                    'calendar__item--is-different-month': isDayDifferentMonth(day),
                }"
                :data-key="day"
                class="calendar__item calendar__item--day"
                @click="selectDate(day)"
            >
                <div class="calendar__item-day">{{ formatDay(day) }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    getYear,
    getMonth,
    getDay,
    isAfter,
    isBefore,
    isWithinRange,
    format,
    lastDayOfMonth,
    lastDayOfWeek,
    subMonths,
    addMonths,
    startOfWeek,
    eachDay,
    isSameMonth,
    startOfMonth,
    startOfDay,
    endOfDay,
    min,
    max,
    addDays,
    subDays,
    isFirstDayOfMonth,
} from 'date-fns';
import _without from 'lodash/without';

import Button from '@/components/Button/Button';

export default {
    name: 'Calendar',

    components: {
        Button,
    },

    props: {
        value: {
            type: Array,
            default: () => [],
        },
        allowedDates: {
            type: Array,
            default: null,
        },
        disabledDates: {
            type: Array,
            default: () => [],
        },
        minDate: {
            type: [String, Date],
            default: null,
        },
        maxDate: {
            type: [String, Date],
            default: null,
        },
        type: {
            type: String,
            validator: v => ['single', 'range', 'multiple'].indexOf(v) !== -1,
            default: 'single',
        },
        maxRange: {
            type: Number,
            default: null,
        },
        maxRangeInWorkDays: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            currentMonth: getMonth(new Date()),
            currentYear: getYear(new Date()),
            daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
        };
    },

    computed: {
        possibleDates() {
            return this.allowedDates ? [...this.allowedDates].sort() : null;
        },

        daysInView() {
            const firstOfMonth = this.getCurrentMonthDate();
            const lastOfMonth = lastDayOfMonth(firstOfMonth);

            const firstDate = this.previousMonthDisabled
                ? firstOfMonth
                : startOfWeek(firstOfMonth, { weekStartsOn: 1 });
            const lastDate = this.nextMonthDisabled ? lastOfMonth : lastDayOfWeek(lastOfMonth, { weekStartsOn: 1 });

            const dates = eachDay(firstDate, lastDate);

            return dates.map(date => format(date, 'YYYY-MM-DD'));
        },

        previousMonthDisabled() {
            if (!this.possibleDates && !this.minimumDate) return false;

            const prevMonthDate = lastDayOfMonth(subMonths(this.getCurrentMonthDate(), 1));

            return (
                (this.minimumDate && isBefore(prevMonthDate, startOfDay(this.minimumDate))) ||
                (this.possibleDates && isBefore(prevMonthDate, this.possibleDates[0]))
            );
        },

        nextMonthDisabled() {
            if (!this.possibleDates && !this.maximumDate) return false;

            const nextMonthDate = startOfMonth(addMonths(this.getCurrentMonthDate(), 1));

            return (
                (this.maximumDate && isAfter(nextMonthDate, endOfDay(this.maximumDate))) ||
                (this.possibleDates &&
                    isAfter(nextMonthDate, lastDayOfMonth(this.possibleDates[this.possibleDates.length - 1])))
            );
        },

        maximumDate() {
            if (!this.maxDate && !this.maxRange) return null;

            if (this.maxRange && this.type === 'range' && this.value && this.value.length === 1) {
                let maxRangeDate;

                if (this.maxRangeInWorkDays) {
                    const { possibleDates } = this;
                    const selectedIndex = possibleDates.indexOf(this.value[0]);
                    const nextIndex = selectedIndex + this.maxRange - 1;
                    maxRangeDate =
                        nextIndex >= possibleDates.length
                            ? possibleDates[possibleDates.length]
                            : possibleDates[nextIndex];
                } else {
                    maxRangeDate = addDays(this.value[0], this.maxRange - 1);
                }

                return this.maxDate ? min(this.maxDate, maxRangeDate) : maxRangeDate;
            }

            return this.maxDate;
        },

        minimumDate() {
            if (!this.minDate && !this.maxRange) return null;

            if (this.maxRange && this.type === 'range' && this.value && this.value.length === 1) {
                const minRangeDate = subDays(this.value[0], this.maxRange - 1);
                return this.minDate ? max(this.minDate, minRangeDate) : minRangeDate;
            }

            return this.minDate;
        },
    },

    methods: {
        isDayDisabled(day) {
            if (!this.possibleDates && !this.disabledDates && !this.maximumDate && !this.minimumDate) return false;
            return (
                (this.possibleDates && !this.possibleDates.includes(day)) ||
                (this.disabledDates && this.disabledDates.includes(day)) ||
                (this.maximumDate && isAfter(day, endOfDay(this.maximumDate))) ||
                (this.minimumDate && isBefore(day, startOfDay(this.minimumDate)))
            );
        },

        getCurrentMonthDate(day = 1) {
            return new Date(this.currentYear, this.currentMonth, day);
        },

        selectDate(day) {
            if (!isSameMonth(day, this.getCurrentMonthDate())) {
                this.currentMonth = getMonth(day);
                this.currentYear = getYear(day);
            }

            let dates;

            if (this.value.includes(day)) {
                dates = _without(this.value, day);
            } else {
                if (this.isDayDisabled(day)) return;
                switch (this.type) {
                    case 'multiple':
                        dates = [...this.value, day];
                        break;
                    case 'range':
                        dates = this.value.length >= 2 ? [day] : [...this.value, day];
                        break;
                    default:
                        dates = [day];
                        break;
                }
            }

            dates.sort();

            this.$emit('input', dates);
        },

        firstDayOffsetStyles(day) {
            return isFirstDayOfMonth(day) ? { 'grid-column-start': getDay(day) || 7 } : {};
        },

        selectPreviousMonth() {
            if (this.previousMonthDisabled) return;
            const newDate = subMonths(this.getCurrentMonthDate(), 1);
            this.currentMonth = getMonth(newDate);
            this.currentYear = getYear(newDate);
        },

        selectNextMonth() {
            if (this.nextMonthDisabled) return;
            const newDate = addMonths(this.getCurrentMonthDate(), 1);
            this.currentMonth = getMonth(newDate);
            this.currentYear = getYear(newDate);
        },

        isDaySelected(day) {
            return this.value.includes(day);
        },

        isInRange(day) {
            if (this.type !== 'range' || this.value.length < 2) return false;
            return isWithinRange(day, this.value[0], this.value[1]);
        },

        isRangeStart(day) {
            if (this.type !== 'range' || this.value.length < 2) return false;
            return this.value[0] === day;
        },

        isRangeEnd(day) {
            if (this.type !== 'range' || this.value.length < 2) return false;
            return this.value[1] === day;
        },

        isDayDifferentMonth(day) {
            return !isSameMonth(day, this.getCurrentMonthDate());
        },

        formatDay(day) {
            return format(day, 'D');
        },
    },
};
</script>

<style lang="scss">
$calendar-row-spacing: 4px;

.calendar {
    font-size: 16px;
}

.calendar__track {
    display: grid;
    grid-template-columns: repeat(7, minmax(44px, 1fr));
    grid-auto-rows: 40px + ($calendar-row-spacing * 2);
    grid-row-gap: 0;
    justify-content: center;
}

.calendar__track--controls {
    padding-bottom: 5px;
}

.calendar__current-month {
    font-weight: $font-weight-bold;
}

.calendar__track--weekdays {
    grid-auto-rows: 50px;
    margin-bottom: 10px;
    font-weight: $font-weight-bold;
}

.calendar__item--day-of-week {
    border-bottom: 1px solid currentColor;
    padding-bottom: 5px;
}

.calendar__track--weekdays,
.calendar__track--days {
    font-size: 17px;
}

.calendar__control {
    height: 42px;
    width: 42px;
}

.calendar__current-month {
    justify-self: center;
    align-self: center;
    grid-column: span 5;
}

.calendar__item {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: transparent;
    margin: 0;
    padding: $calendar-row-spacing 0;
}

.calendar__item--day {
    background-color: #fff;
    font-weight: $font-weight-bold;
}

.calendar__item-day {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.calendar__item--is-different-month {
    background-color: transparent;
}

.calendar__item--disabled {
    font-weight: $font-weight-regular;
    color: rgba($color-black, 0.2);

    .calendar__item-day {
        cursor: default;
    }
}

.calendar__item--in-range {
    .calendar__item-day {
        background-color: $color-mediumGrey;
    }

    &.calendar__item--disabled {
        &::after {
            content: '';
            position: absolute;
            left: 15%;
            top: 50%;
            right: 15%;
            border-top: 1px solid currentColor;
            transform: rotate(-15deg);
        }
    }
}

.calendar__item--selected {
    font-weight: $font-weight-bold;

    .calendar__item-day {
        border-radius: 50%;
        background-color: $color-red;
        color: $color-white;
        position: relative;
        z-index: 1;
        width: 40px;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: $calendar-row-spacing;
        bottom: $calendar-row-spacing;
        background-color: $color-mediumGrey;
    }

    &.calendar__item--is-range-start {
        &::before {
            left: 50%;
            right: 0;
        }
    }

    &.calendar__item--is-range-end {
        &::before {
            left: 0;
            right: 50%;
        }
    }

    &.calendar__item--is-different-month {
        .calendar__item-day {
            color: rgba($color-white, 0.4);
        }
    }
}
</style>

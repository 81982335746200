import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CloseIcon } from '@/constructionProjects/components/icons/CloseIcon';
import { Button } from '@schuettflix/react-components';
import { useBreakpoints } from '@/constructionProjects/hooks/useBreakpoints';
export const Modal = ({ cancelButtonLabel, confirmButtonLabel, isOpen, onClose, onConfirm, subtitle, title, }) => {
    const { isGreaterOrEqual } = useBreakpoints();
    return (<Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose}>
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black bg-opacity-75"/>
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center py-4 text-center sm:p-4">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="bg-surface">
                                <div className="px-6 pt-6">
                                    <div className="flex justify-between">
                                        <Dialog.Title as="h3" className="font-headline-lg-strong text-left">
                                            {title}
                                        </Dialog.Title>
                                        <CloseIcon size="sm" onClick={onClose} className="ml-6"/>
                                    </div>
                                    <div className="mt-4">
                                        <p className="font-copy-sm text-left">{subtitle}</p>
                                    </div>
                                </div>

                                <div className="mt-[40px] flex flex-col justify-end border-t sm:flex-row sm:gap-4 sm:border-none sm:px-6 sm:pb-6">
                                    <Button size={isGreaterOrEqual('sm') ? 'sm' : 'md'} data-test="cancel-delete-location-button" variant="secondary" label={cancelButtonLabel} onClick={onClose}/>
                                    <Button size={isGreaterOrEqual('sm') ? 'sm' : 'md'} data-test="confirm-delete-location-button" label={confirmButtonLabel} onClick={onConfirm}/>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>);
};

import { useVueEmit } from '@/reactBridge/EmitVueEventProvider';
import { useAbility } from '@/reactBridge/useAbility';
import store from '@/store';
import { Button } from '@schuettflix/react-components';
import { Trans, useTranslation } from 'react-i18next';
import { SfSysPlusIcon } from './icons/SfSysPlusIcon';
import SfDocumentsIcon from './icons/SfDocumentsIcon';
import CertificateList from './components/CertificateList';
import { useVuexState } from '@/reactBridge/useVuex';
import { useAppDimension } from '@/reactBridge/useAppDimension';
import { useQuery } from '@tanstack/react-query';
import CertificateApi, { certificateListCacheKey } from '@/services/Api/Certificate';
import useIsPlatformAdmin from '@/hooks/useIsPlatformAdmin';
export default function CertificateManagementListReactPage({ organizationId }) {
    const canManageCertificates = useAbility('manageDisposalCertificates');
    const { emitVueEvent } = useVueEmit();
    const { t } = useTranslation();
    const serviceEmail = useVuexState(store, state => state.platform.serviceEmail);
    const servicePhone = useVuexState(store, state => state.platform.servicePhone);
    const isPlatformAdmin = useIsPlatformAdmin();
    const { data: certificates, isLoading } = useQuery(certificateListCacheKey(organizationId, isPlatformAdmin), () => {
        if (isPlatformAdmin) {
            return CertificateApi.getAllByOrganizationForPlatform(organizationId);
        }
        return CertificateApi.getAll();
    });
    const { isDesktop } = useAppDimension();
    const emitCertificateAddEvent = () => emitVueEvent('addCertificate');
    const EmptyContent = () => (<div className="flex max-w-2xl flex-col items-center">
            <SfDocumentsIcon size="xxl" className="mb-6"/>
            <p className="font-headline-xl-strong mb-2 text-center">
                {t('pages.certificateManagement.emptyState.title')}
            </p>
            <p className="mb-6 whitespace-pre-line text-center">
                <Trans t={t} i18nKey="pages.certificateManagement.emptyState.copy.text" values={{
            phone: servicePhone,
            email: serviceEmail,
        }} components={{
            phone: <a href={`tel:${servicePhone.replace(/ /g, '')}`}/>,
            email: <a className="underline" href={`mailto:${serviceEmail}`}/>,
        }}/>
            </p>

            {canManageCertificates && (<Button size="sm" variant="secondary" leadingIcon={props => <SfSysPlusIcon {...props}/>} label={t('pages.certificateManagement.addCertificateButton')} onClick={emitCertificateAddEvent}/>)}
        </div>);
    return (<div className="flex flex-col items-end gap-6">
            {canManageCertificates && (isDesktop || !!certificates?.length) && (<Button size="sm" onClick={emitCertificateAddEvent} leadingIcon={props => <SfSysPlusIcon {...props} dark/>} label={t('pages.certificateManagement.addCertificateButton')}/>)}

            <CertificateList certificates={certificates} isLoading={isLoading} onRemove={id => emitVueEvent('deleteCertificate', { value: id })} onEdit={id => emitVueEvent('editCertificate', { value: id })} emptyContent={EmptyContent}/>
        </div>);
}

<template>
    <div>
        <span class="font-copy-sm-strong mb-6">
            {{ $t('pages.order.orderView.costCenter.title') }}
        </span>
        <span class="font-copy-md">{{ costCenter }}</span>
        <LoadingSpinner v-if="isLoading" block dark />
        <span v-else-if="hasTags" class="font-copy-md mb-4 mt-6 flex flex-wrap gap-2" data-test="tagsList">
            <span> {{ $t('pages.order.orderView.costCenter.text') }} </span>
            <div class="flex gap-x-4 gap-y-2">
                <template v-for="(tag, index) in costCenterTags">
                    <SfTag v-if="tag.costCenter" :key="index" :data-test="tag.dataTestTag">
                        {{ tag.translationKey }}
                    </SfTag>
                </template>
            </div>
        </span>
    </div>
</template>

<script>
import ProjectApi from '@/services/Api/Project';
import Toaster from '@/services/Toaster';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import { COST_CENTER_TYPE } from '@/constants/costCenterTypes';
import { locationsEqual } from '@/services/utils/map';

import LoadingSpinner from '@/components/LoadingSpinner';
import { SfTag } from '@schuettflix/vue-components';

export default {
    name: 'CostCenterTile',
    components: {
        LoadingSpinner,
        SfTag,
    },
    mixins: [statefulMixin],
    props: {
        order: {
            type: Object,
            required: true,
            validator: value => {
                return !!value.costCenter;
            },
        },
    },
    data() {
        return {
            isLoading: false,
            isCostCenterFromProject: false,
        };
    },
    computed: {
        costCenter() {
            return this.order?.costCenter;
        },
        costCenterTags() {
            return [
                {
                    costCenter: this.isCostCenterFromProject,
                    translationKey: this.$t('pages.checkout.costCenters.types.project'),
                    dataTestTag: 'projectTag',
                },
                {
                    costCenter: this.isCostCenterFromConstructionSite,
                    translationKey: this.$t('pages.checkout.costCenters.types.constructionSite'),
                    dataTestTag: 'constructionSiteTag',
                },
                {
                    costCenter: this.isCostCenterFromLoadingAddress,
                    translationKey: this.$t('pages.checkout.costCenters.types.loadingSite'),
                    dataTestTag: 'loadingAddressTag',
                },
                {
                    costCenter: this.isCostCenterFromUnloadingAddress,
                    translationKey: this.$t('pages.checkout.costCenters.types.unloadingSite'),
                    dataTestTag: 'unloadingAddressTag',
                },
            ];
        },
        projectId() {
            return this.lineItemGroup?.projectId;
        },
        lineItemGroup() {
            return this.order?.lineItemGroups?.[0];
        },
        constructionSiteLocation() {
            return this.lineItemGroup?.constructionSite?.location;
        },
        hasTags() {
            return (
                this.isCostCenterFromUnloadingAddress ||
                this.isCostCenterFromLoadingAddress ||
                this.isCostCenterFromConstructionSite ||
                this.isCostCenterFromProject
            );
        },
        isCostCenterFromUnloadingAddress() {
            return (
                this.lineItemGroup?.unloadingAddressCostCenter === this.costCenter ||
                (this.isCostCenterFromConstructionSite &&
                    locationsEqual(this.constructionSiteLocation, this.lineItemGroup?.unloadingSiteLocation))
            );
        },
        isCostCenterFromLoadingAddress() {
            return (
                this.lineItemGroup?.loadingAddressCostCenter === this.costCenter ||
                (this.isCostCenterFromConstructionSite &&
                    locationsEqual(this.constructionSiteLocation, this.lineItemGroup?.loadingLocation))
            );
        },
        isCostCenterFromConstructionSite() {
            return (
                (this.lineItemGroup?.loadingAddressType === COST_CENTER_TYPE.CONSTRUCTION_SITE &&
                    !this.isCostCenterFromProject) ||
                this.costCenter === this.lineItemGroup?.constructionSite?.costcenter
            );
        },
    },
    async created() {
        if (this.projectId) {
            await this.loadProject();
        }
    },
    methods: {
        async loadProject() {
            this.isLoading = true;
            await this.stateful('loadProject', async () => {
                try {
                    const result = await ProjectApi.getOneById(this.projectId);
                    this.isCostCenterFromProject = result.costCenter && result.costCenter === this.costCenter;
                } catch (err) {
                    Toaster.error(err);
                } finally {
                    this.isLoading = false;
                }
            });
        },
    },
};
</script>

import i18n from '@/i18n';

export function getCountryName(countryCode, fallback = null) {
    const short = countryCode?.toLowerCase();

    if (i18n.te(`countries.${short}.name`)) {
        return i18n.t(`countries.${short}.name`);
    }

    return fallback;
}

export function getCountryCode(country) {
    if (!country) return;
    return country;
}

<template>
    <div>
        <Words class="mb-6" bold block> {{ $t('pages.checkout.summary.costTable.additionalCost') }} </Words>

        <TextField
            v-model="model.name"
            input-label
            :error="getError('model.name')"
            type="text"
            :label="$t(`pages.checkout.${processI18nKey}.customPosition.nameLabel`)"
            class="mb-4 h-16"
        />

        <TextField
            v-model="model.unit"
            :error="getError('model.unit')"
            type="text"
            :label="$t(`pages.checkout.${processI18nKey}.customPosition.unitLabel`)"
            class="mb-4 h-16"
        />

        <TextField
            v-model.number="model.qty"
            type="number"
            :error="getError('model.qty')"
            step="0.01"
            :label="$t(`pages.checkout.${processI18nKey}.customPosition.qtyLabel`)"
            class="mb-4 h-16"
        />

        <TextField
            v-model.number="model.purchaseUnitPrice"
            type="number"
            :error="getError('model.purchaseUnitPrice')"
            step="0.01"
            :label="$t(`pages.checkout.${processI18nKey}.customPosition.unitPurchasePriceLabel`)"
            class="mb-4 h-16"
        />

        <TextField
            v-model.number="model.retailUnitPrice"
            type="number"
            :error="getError('model.retailUnitPrice')"
            step="0.01"
            :label="$t(`pages.checkout.${processI18nKey}.customPosition.unitRetailPriceLabel`)"
            class="mb-4 h-16"
        />
        <span class="font-copy-md mt-12 font-bold">{{
            $t('pages.checkout.priceAdjustments.customPosition.creditTo.title')
        }}</span>
        <div class="relative mt-4 flex flex-wrap">
            <SfRadioButtonSimple
                id="1"
                :value="model.creditTo || ''"
                class="mb-4 mr-4"
                input-value="none"
                name="creditTo"
                :label="$t('pages.checkout.priceAdjustments.customPosition.creditTo.none')"
                @input="model.creditTo = $event"
            />
            <SfRadioButtonSimple
                id="2"
                :value="model.creditTo || ''"
                class="mb-4 mr-4"
                input-value="carrier"
                name="creditTo"
                :label="$t('pages.checkout.priceAdjustments.customPosition.creditTo.carrier')"
                @input="model.creditTo = $event"
            />
            <SfRadioButtonSimple
                v-if="isDisposal"
                id="3"
                :value="model.creditTo || ''"
                class="mb-4 mr-4"
                input-value="supplier"
                name="creditTo"
                :label="$t('pages.checkout.priceAdjustments.customPosition.creditTo.supplier')"
                @input="model.creditTo = $event"
            />
        </div>
    </div>
</template>

<script>
import Words from '@/components/Typography/Words';
import TextField from '@/components/Form/TextField.v2';
import validate from '@/services/validation/mixin';
import { isRequired } from '@/services/validation/rules';
import { SfRadioButtonSimple } from '@schuettflix/vue-components';

/**
 * Events that can be emitted by this component
 * @type {{key: string}}
 */
const EVENTS = {
    change: 'change',
};

export default {
    name: 'CustomPositionEditSection',
    components: {
        SfRadioButtonSimple,
        Words,
        TextField,
    },
    mixins: [validate],
    props: {
        value: {
            type: Object,
            required: true,
        },
        isDisposal: {
            type: Boolean,
            required: false,
        },
        processI18nKey: {
            type: String,
            required: false,
            default: 'priceAdjustments',
            validator: v => v,
        },
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set() {
                this.$emit('input', this.value);
            },
        },
        validationRules() {
            return {
                'model.name': [isRequired()],
                'model.unit': [isRequired()],
                'model.qty': [isRequired()],
                'model.purchaseUnitPrice': [isRequired()],
                'model.retailUnitPrice': [isRequired()],
                'model.creditTo': [isRequired()],
            };
        },
    },
    watch: {
        value: {
            handler() {
                this.$emit(EVENTS.change, { value: this.value, isValid: this.isValid(true) });
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>

<template>
    <div>
        <p class="font-copy-md-strong m-0 mb-2 lg:font-copy-lg-strong" v-html="$t('pages.homeV2.loadingTitle')" />
        <p class="font-copy-sm mb-[12px] lg:font-copy-md lg:mb-6">
            {{ $t('pages.homeV2.loadingDescription') }}
        </p>

        <template v-if="supplierInLoadingTransports">
            <Card
                v-for="transport in supplierInLoadingTransports.items"
                :key="transport.id"
                spaceless-x
                clickable
                @click="goToSupplierTransportView(transport.id)"
            >
                <TransportListBlock :transport="transport" context="supplier" :is-mobile="!$root.isDesktop" />
            </Card>
            <EmptyState
                v-if="supplierInLoadingTransports.count === 0"
                class="mb-4"
                icon="LoadingEmptyIcon"
                :text="$t('pages.homeV2.noActiveInLoadingTransport')"
            />
        </template>

        <div class="grid gap-x-[20px] gap-y-[20px] lg:grid-cols-[1fr,1fr,1fr,1fr]">
            <StatusCard
                v-if="upcomingLoadTransportsCount"
                icon="MyLoadingsIcon"
                data-test="my-loadings-button"
                :title="$t('pages.homeV2.myLoadingsCardTitle')"
                :description="$tc('pages.homeV2.myLoadingsCardDescription', upcomingLoadTransportsCount)"
                :count="upcomingLoadTransportsCount"
                @click="goToSupplierUpcomingTransportList()"
            />
        </div>
    </div>
</template>

<script>
import _get from 'lodash/get';
import TransportListApi from '@/services/Api/TransportList';

import Card from '@/components/Layout/Card';
import EmptyState from '@/pages/Home/components/EmptyState';
import StatusCard from '@/pages/Home/components/StatusCard';
import TransportListBlock from '@/components/Transport/TransportListBlock';

const POLLING_INTERVAL = 60000;

const transportListApi = new TransportListApi('supplier');

export default {
    name: 'HomeSectionLoading',
    components: {
        Card,
        EmptyState,
        StatusCard,
        TransportListBlock,
    },
    data() {
        return {
            supplierInLoadingTransports: null,
            supplierInLoadingTransportsCancelSource: null,
            upcomingLoadTransportsCount: null,
            upcomingLoadTransportsCountCancelSource: null,
        };
    },
    mounted() {
        this.refreshData();
        this.$gracefulInterval(this.refreshData.bind(this), POLLING_INTERVAL);
    },
    beforeDestroy() {
        this.supplierInLoadingTransportsCancelSource &&
            this.supplierInLoadingTransportsCancelSource.cancel('canceled-previous-call');
        this.upcomingLoadTransportsCountCancelSource &&
            this.upcomingLoadTransportsCountCancelSource.cancel('canceled-previous-call');
    },
    methods: {
        async refreshData() {
            if (this.$can('haveCourtList')) {
                this.refreshSupplierInLoadingTransports();
            }
            if (this.$can('haveCourtList')) {
                this.refreshUpcomingLoadTransportsCount();
            }
        },

        async refreshSupplierInLoadingTransports() {
            this.supplierInLoadingTransportsCancelSource &&
                this.supplierInLoadingTransportsCancelSource.cancel('canceled-previous-call');
            this.supplierInLoadingTransportsCancelSource = transportListApi.createCancelTokenSource();

            try {
                this.supplierInLoadingTransports = await transportListApi.filter(
                    {
                        status: ['checked_in', 'checked_in_loading'],
                    },
                    null,
                    null,
                    this.supplierInLoadingTransportsCancelSource
                );
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }
        },

        async refreshUpcomingLoadTransportsCount() {
            this.upcomingLoadTransportsCountCancelSource &&
                this.upcomingLoadTransportsCountCancelSource.cancel('canceled-previous-call');
            this.upcomingLoadTransportsCountCancelSource = transportListApi.createCancelTokenSource();

            try {
                const response = await transportListApi.filter(
                    {
                        status: ['started'],
                        countOnly: true,
                    },
                    null,
                    null,
                    this.upcomingLoadTransportsCountCancelSource
                );

                this.upcomingLoadTransportsCount = _get(response, 'count', 0);
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }
        },

        goToSupplierTransportView(transportId) {
            this.$router
                .push({
                    name: this.$root.findRouteName('materials__court-list__transport-view'),
                    params: { transportId: transportId },
                })
                .catch(() => {});
        },

        goToSupplierUpcomingTransportList() {
            this.$router
                .push({
                    name: this.$root.findRouteName('materials__court-list'),
                })
                .catch(() => {});
        },
    },
};
</script>

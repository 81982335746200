<template>
    <LayoutPage is-flyout class="checkout-project-position-type-selection-page" data-test="disposer-selection-page">
        <FlyoutHeader slot="flyoutHeader" />

        <div class="container-off-canvas-sm my-6">
            <div v-if="isGermanMarket() && isDisposal()" class="font-copy-md mb-6">
                {{ $t('pages.checkout.projectPositionDisposal.pages.chooseDisposer.hint') }}
            </div>
            <p v-else class="font-copy-md mb-6">
                {{ $t('pages.checkout.projectPositionDisposal.pages.chooseDisposer.subtitle') }}
            </p>
            <FilterBox
                ref="supplierSelectionFilter"
                v-model="filter"
                :default-filter="filter"
                :endpoint="dataEndpoint"
                inline-mode
                no-padding
                @update="fetchSuppliers()"
            >
                <template #default="filterScope">
                    <TextField
                        v-model="filterScope.filter.search"
                        data-test="disposer-search"
                        :label="$t('pages.checkout.projectPositionDisposal.pages.chooseDisposer.placeholder')"
                    >
                        <SearchIcon slot="icon" />
                    </TextField>
                </template>
            </FilterBox>
            <LoadingCard v-if="!supplierList && isLoading" class="mt-4" />
            <section
                v-else-if="supplierList.count === 0 && !isLoading"
                data-test="disposer-selection-empty-list-hint"
                class="flex h-full w-full flex-col items-center justify-center"
            >
                {{ $t('pages.checkout.projectPositionDisposal.pages.chooseDisposer.noSupplierAvailable.description') }}
            </section>
            <transition v-else name="fade" mode="out-in">
                <List
                    v-if="supplierList && supplierList.count > 0 && !isLoading"
                    class="bg-transparent"
                    data-test="disposer-selection-list"
                >
                    <DisposerCard
                        v-for="(supplier, index) in supplierList.items"
                        :key="`supplier-${index}`"
                        :is-selected="isSelectedSupplier(supplier)"
                        :disposer="supplier"
                        :disabled="isDisposerInvalidForSelection(supplier)"
                        @select-disposer="selectDisposer(supplier.supplier)"
                    />

                    <MoreResultsButton
                        v-if="supplierList.count - supplierList.items.length > 0"
                        :result="supplierList"
                        fade-out
                    />
                </List>
            </transition>
        </div>
    </LayoutPage>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import FlyoutHeader from '@/pages/Checkout/components/Header.vue';
import LayoutPage from '@/components/Layout/Page.v2.vue';

import SearchIcon from '@/assets/icons/micro/search.svg';

import { navigationFailure } from '@/services/utils/router';
import SupplierListApi from '@/services/Api/Supplier/List';
import Toaster from '@/services/Toaster';
import _debounce from 'lodash/debounce';
import TextField from '@/components/Form/TextField.v2.vue';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import FilterBox from '@/components/Filter/FilterBox.vue';
import List from '@/components/List/List.vue';
import MoreResultsButton from '@/components/Filter/MoreResultsButton.vue';
import { FACTORY_TYPES } from '@/constants/disposal';

import LoadingCard from '@/pages/Checkout/Disposal/ProjectPosition/Partials/LoadingCard.vue';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import DisposerCard from '@/pages/Checkout/Disposal/Components/DisposerCard';
import { AvailableMarkets } from '@/services/MarketPermission/constants';

const SEARCH_DEBOUNCE_MS = 300;

export default {
    name: 'ChooseDisposerPage',
    components: {
        DisposerCard,
        MoreResultsButton,
        List,
        FilterBox,
        TextField,
        FlyoutHeader,
        LayoutPage,

        LoadingCard,

        SearchIcon,
    },
    mixins: [persistentFiltersMixin],
    data() {
        return {
            supplierList: null,
            searchTerm: '',
            isLoading: false,
            dataEndpoint: SupplierListApi,
            filter: this.assembleFilter('supplierList', {
                page: 1,
                perPage: 25,
                search: '',
            }),
            defaultFilter: {
                page: 1,
                perPage: 25,
                search: '',
            },
        };
    },
    computed: {
        ...mapState('basket', {
            typeFromBasket: 'type',
            projectIdFromBasket: 'projectId',
        }),

        ...mapState('projectPosition', ['requestCollection', 'disposer', 'constructionSite']),

        ...mapGetters('user', ['organizationMarket']),

        isHazardousWaste() {
            return this.requestCollection.type === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS;
        },
        selectedFactory() {
            return this.disposer.factoryId;
        },
    },
    watch: {
        searchTerm() {
            this.updateSuppliers();
        },
    },
    created() {
        // if page was accessed without selected type, project, or construction site
        // redirect to previous step to enter data
        if (!this.didCompletePreviousSteps()) {
            this.$router
                .push({
                    name: this.$root.findRouteName('checkout-project'),
                })
                .catch(navigationFailure);
        }

        this.fetchSuppliers();
    },
    methods: {
        isGermanMarket() {
            return this.organizationMarket.code == AvailableMarkets.DE;
        },
        isDisposal() {
            return this.isDisposalOfDangerousWaste() || this.isDisposalOfNonDangerousWaste();
        },
        isDisposalOfDangerousWaste() {
            return this.typeFromBasket == BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_HAZARDOUS;
        },
        isDisposalOfNonDangerousWaste() {
            return this.typeFromBasket == BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DISPOSAL_NON_HAZARDOUS;
        },
        isDisposerInvalidForSelection(organization) {
            if (!this.isGermanMarket()) return false;
            if (!this.isDisposal()) return false;
            if (!organization.certificates.length) return true;

            let certificatesWithValidStatus = [];

            if (this.isDisposalOfDangerousWaste()) {
                certificatesWithValidStatus = organization.certificates.filter(
                    c =>
                        ['valid', 'expires_soon'].includes(c.status) &&
                        [
                            'certificate_of_waste_management_company',
                            'disposal_form_bimschg',
                            'disposal_form_construction',
                        ].includes(c.code)
                );
            }
            if (this.isDisposalOfNonDangerousWaste()) {
                certificatesWithValidStatus = organization.certificates.filter(
                    c =>
                        ['valid', 'expires_soon'].includes(c.status) &&
                        [
                            'certificate_of_waste_management_company',
                            'disposal_form_installation_permit',
                            'disposal_form_bimschg',
                            'disposal_form_construction',
                        ].includes(c.code)
                );
            }

            return !certificatesWithValidStatus.length;
        },
        didCompletePreviousSteps() {
            return (
                this.typeFromBasket ||
                this.constructionSite?.id ||
                (this.isHazardousWaste && this.requestCollection.producerNumber)
            );
        },

        isSelectedSupplier(supplier) {
            return this.selectedFactory === supplier.supplier.factoryId;
        },

        isAnySupplierSelected() {
            const suppliers = this.supplierList?.items || [];
            return suppliers.some(supplier => this.isSelectedSupplier(supplier));
        },

        updateSuppliers: _debounce(() => {
            if (typeof this === 'undefined') return;

            this.fetchSuppliers();
        }, SEARCH_DEBOUNCE_MS),

        async fetchSuppliers() {
            this.isLoading = true;

            try {
                this.supplierList = await SupplierListApi.filterSupplierInfos({
                    ...this.filter,
                    transportType: 'disposal',
                    supportsDangerousWaste: this.isHazardousWaste,
                });

                if (!this.isAnySupplierSelected()) {
                    this.$store.commit('projectPosition/RESET_PROJECT_POSITION_DISPOSER');
                }
            } catch (err) {
                Toaster.error(err.message);
            }

            this.isLoading = false;
        },
        selectDisposer(disposer) {
            this.$store.commit('projectPosition/SET_PROJECT_POSITION_DISPOSER', disposer);
            if (this.isHazardousWaste) {
                this.$store.commit('projectPosition/SET_BILLING_METHOD', 'weight');
            } else if (disposer.factoryType === FACTORY_TYPES.DUMPING_SITE) {
                this.$store.commit('projectPosition/SET_BILLING_METHOD', disposer.disposalBillingMethod);
            } else {
                this.$store.commit('projectPosition/SET_BILLING_METHOD', 'weight');
            }

            this.$router
                .push({
                    name: this.$root.findRouteName(this.$route.meta.next),
                })
                .catch(navigationFailure);
        },
    },
};
</script>

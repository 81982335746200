import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from '@schuettflix/react-components';
import { ConstructionProjectStatus } from '@/constructionProjects/api/projects';
export const ProjectStatusTag = ({ status }) => {
    const { t } = useTranslation();
    const typesMap = {
        [ConstructionProjectStatus.Active]: 'positive',
        [ConstructionProjectStatus.Inactive]: 'error',
        [ConstructionProjectStatus.Archived]: 'default',
    };
    return (<Tag type={typesMap[status] || 'default'} subdued label={t(`pages.projectList.projectCard.${status.toLowerCase()}.label`)}/>);
};

<template>
    <div
        :class="{
            'tile--no-padding': noPadding,
            'tile--no-padding-top': noPaddingTop,
            'tile--no-padding-left-right': noPaddingLeftRight,
            'tile--no-padding-bottom': noPaddingBottom,
            'tile--no-border': noBorder,
            'tile--no-border-bottom': noBorderBottom,
            'tile--background-transparent': backgroundTransparent || transparent,
            'tile--border-top': borderTop,
            'tile--border-dark': borderDark,
            'tile--border-spaced': borderSpaced,
            'tile--clickable': clickable,
            'tile--dark': dark,
        }"
        class="tile"
        @click="$emit('click', $event)"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Tile',
    props: {
        noPadding: {
            type: Boolean,
            default: false,
        },
        noPaddingTop: {
            type: Boolean,
            default: false,
        },
        noPaddingLeftRight: {
            type: Boolean,
            default: false,
        },
        noPaddingBottom: {
            type: Boolean,
            default: false,
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        noBorderBottom: {
            type: Boolean,
            default: false,
        },
        backgroundTransparent: {
            type: Boolean,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        borderTop: {
            type: Boolean,
            default: false,
        },
        borderDark: {
            type: Boolean,
            default: false,
        },
        borderSpaced: {
            type: Boolean,
            default: false,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
.tile {
    background-color: $color-white;
    padding: 20px 15px;
    border-bottom: $border-solid-2px;

    @media only screen and (min-width: $layout-desktop-min) {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.tile--dark {
    background-color: $color-darkGrey;
    color: white;
}

.tile--border-spaced {
    padding-left: 0;
    padding-right: 0;
    margin-left: 15px;
    margin-right: 15px;

    @media only screen and (min-width: $layout-desktop-min) {
        margin-left: 25px;
        margin-right: 25px;
    }
}

.tile--no-padding-top {
    padding-top: 0;
}

.tile--no-padding-left-right {
    padding-left: 0;
    padding-right: 0;
}

.tile--no-padding-bottom {
    padding-bottom: 0;
}

.tile--no-padding {
    padding: 0;
}

.tile--no-border {
    border: 0 none;
}

.tile--no-border-bottom {
    border-bottom: 0;
}

.tile--background-transparent {
    background-color: transparent;
}

.tile--border-top {
    border-top: $border-solid-2px;
}

.tile--border-dark {
    border-color: $color-lightMediumGrey;
}

.tile--clickable {
    cursor: pointer;
}
</style>

<template>
    <div>
        <OrderForceCancelAction />
        <OrderCancelAction />
        <OrderCloseAction />
        <OrderChangeDisposalPriceAction />
    </div>
</template>

<script>
import OrderCancelAction from '@/pages/Order/Actions/OrderCancelAction';
import OrderChangeDisposalPriceAction from '@/pages/Order/Actions/OrderChangeDisposalPriceAction';
import OrderCloseAction from '@/pages/Order/Actions/OrderCloseAction';
import OrderForceCancelAction from '@/pages/Order/Actions/OrderForceCancelAction';

export default {
    name: 'OrderActions',
    components: {
        OrderCancelAction,
        OrderChangeDisposalPriceAction,
        OrderCloseAction,
        OrderForceCancelAction,
    },
};
</script>

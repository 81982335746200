import OrganizationView from '@/models/OrganizationView';
import UserView from '@/models/UserView';
import { differenceInCalendarDays } from 'date-fns';
import { ensureJSTimestamp, formatDateRange } from '@/services/utils/date';
import { isUserDeleted } from '@/services/utils/user';

const PROJECT_STATUS_CLOSED = 'closed';
const PROJECT_STATUS_OPEN = 'open';
const PROJECT_VALIDITY_THRESHOLD = 7;

/** Class representing project view model */
export default class ProjectView {
    /**
     * Create a view model
     */
    constructor() {
        this._id = null;
        this._number = null;
        this._client = null;
        this._name = null;
        this._status = null;
        this._costCenter = null;
        this._validFrom = null;
        this._validTo = null;
        this._supervisor = null;
        this._createdBy = null;
        this._showClientData = true;
    }

    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     */
    static create(data) {
        if (!data) return null;

        const view = new ProjectView();

        view.id = data.id;
        view.number = data.number;
        view.name = data.name;
        view.status = data.status;
        view.costCenter = data.costCenter;
        view.validFrom = data.validFrom;
        view.validTo = data.validTo;
        view.constructionProjectId = data.constructionProjectId;
        view.client = OrganizationView.create(data.client);

        if (data.supervisor && isUserDeleted(data.supervisor)) {
            // Fallback for deleted user
            const fallbackSupervisorName = data.supervisorName ?? '';
            view.supervisor = UserView.create({
                firstName: fallbackSupervisorName.split(' ').slice(0, -1).join(' '),
                lastName: fallbackSupervisorName.split(' ').slice(-1).join(' '),
            });
        } else {
            view.supervisor = UserView.create(data.supervisor);
        }

        if (data.createdBy && isUserDeleted(data.createdBy)) {
            // Fallback for deleted user
            const fallbackCreatedByName = data.createdByName ?? '';
            view.createdBy = UserView.create({
                firstName: fallbackCreatedByName.split(' ').slice(0, -1).join(' '),
                lastName: fallbackCreatedByName.split(' ').slice(-1).join(' '),
            });
        } else {
            view.createdBy = UserView.create(data.createdBy);
        }

        if (data.responsibleEmployee && isUserDeleted(data.responsibleEmployee)) {
            // Fallback for deleted user
            const fallbackResponsibleEmployeeName = data.responsibleEmployeeName ?? '';
            view.responsibleEmployee = UserView.create({
                firstName: fallbackResponsibleEmployeeName.split(' ').slice(0, -1).join(' '),
                lastName: fallbackResponsibleEmployeeName.split(' ').slice(-1).join(' '),
            });
        } else {
            view.responsibleEmployee = UserView.create(data.responsibleEmployee);
        }

        return view;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            id: this.id,
            number: this.number,
            client: this.client?.unfold(),
            name: this.name,
            status: this.status,
            costCenter: this.costCenter,
            validFrom: this.validFrom,
            validTo: this.validTo,
            supervisor: this.supervisor?.unfold(),
            createdBy: this.createdBy?.unfold(),
            showClientData: this.showClientData,
        };
    }

    /**
     * Clone the current view
     * @return {ProjectFacadeView}
     */
    clone() {
        return ProjectView.create(this.unfold());
    }

    /**
     * Get id
     * @return {number}
     */
    get id() {
        return this._id;
    }

    /**
     * Set id
     * @param {number} value
     */
    set id(value) {
        this._id = value ?? null;
    }

    /**
     * Get number
     * @return {string}
     */
    get number() {
        return this._number;
    }

    /**
     * Set number
     * @param {string} value
     */
    set number(value) {
        this._number = value ?? null;
    }

    /**
     * Get client
     * @return {OrganizationView}
     */
    get client() {
        return this._client;
    }

    /**
     * Set client
     * @param {OrganizationView} value
     */
    set client(value) {
        this._client = value ?? null;
    }

    /**
     * Get name
     * @return {string}
     */
    get name() {
        return this._name;
    }

    /**
     * Set name
     * @param {string} value
     */
    set name(value) {
        this._name = value ?? null;
    }

    /**
     * Get status
     * @return {string}
     */
    get status() {
        return this._status;
    }

    /**
     * Set status
     * @param {string} value
     */
    set status(value) {
        this._status = value ?? null;
    }

    /**
     * Get cost center
     * @returns {string}
     */
    get costCenter() {
        return this._costCenter;
    }

    /**
     * Set cost center
     * @param value
     */
    set costCenter(value) {
        this._costCenter = value ?? null;
    }

    /**
     * Get construction project id
     * @returns {string}
     */
    get constructionProjectId() {
        return this._constructionProjectId;
    }

    /**
     * Set construction project id
     * @param value
     */
    set constructionProjectId(value) {
        this._constructionProjectId = value ?? null;
    }

    /**
     * Get validFrom timestamp
     * @return {number}
     */
    get validFrom() {
        return this._validFrom;
    }

    /**
     * Set validFrom timestamp
     * @param {number} value
     */
    set validFrom(value) {
        this._validFrom = ensureJSTimestamp(value);
    }

    /**
     * Get validTo timestamp
     * @return {number}
     */
    get validTo() {
        return this._validTo;
    }

    /**
     * Set validTo timestamp
     * @param {number} value
     */
    set validTo(value) {
        this._validTo = ensureJSTimestamp(value);
    }

    /**
     * Get supervisor
     * @returns {UserView}
     */
    get supervisor() {
        return this._supervisor;
    }

    /**
     * Set supervisor
     * @param {UserView} value
     */
    set supervisor(value) {
        this._supervisor = value ?? null;
    }

    /**
     * Get show client data flag
     * @return {boolean}
     */
    get showClientData() {
        return this.client && this._showClientData;
    }

    /**
     * Set show client data flag
     * @param {boolean} value
     */
    set showClientData(value) {
        this._showClientData = value ?? null;
    }

    /**
     * Get created by
     * @returns {UserView}
     */
    get createdBy() {
        return this._createdBy;
    }

    /**
     * Set created by
     * @param {UserView} value
     */
    set createdBy(value) {
        this._createdBy = value ?? null;
    }

    /* ---------- */

    /**
     * Get formatted validity range
     * @return {string}
     */
    get validityRange() {
        return formatDateRange(this.validFrom, this.validTo);
    }

    /**
     * Get remaining validity in days
     * @return {number}
     */
    get remainingValidityInDays() {
        return differenceInCalendarDays(this.validTo, new Date()) + 1;
    }

    /**
     * Get open status
     * @return {boolean}
     */
    get isOpen() {
        return this.status === PROJECT_STATUS_OPEN;
    }

    /**
     * Get closed status
     * @return {boolean}
     */
    get isClosed() {
        return this.status === PROJECT_STATUS_CLOSED;
    }

    /**
     * Check if the remaining validity is below threshold
     * @return {boolean}
     */
    get isRemainingValidityBelowThreshold() {
        return this.remainingValidityInDays <= PROJECT_VALIDITY_THRESHOLD;
    }

    /**
     * Check if the current date is inside the validity range
     * @returns {boolean}
     */
    get isInsideValidityRange() {
        const now = new Date();
        return this.validFrom <= now && this.validTo >= now;
    }

    /**
     * Get show remaining validity
     * @return {boolean}
     */
    get showRemainingValidity() {
        return this.isOpen && this.isInsideValidityRange && this.isRemainingValidityBelowThreshold;
    }

    /**
     * Get status tag
     * @return {object}
     */
    get statusTag() {
        return {
            type: this.status === PROJECT_STATUS_OPEN ? 'green' : 'red',
            label: `status.project.${this.status}`,
        };
    }
}

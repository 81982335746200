<template>
    <div class="tile-select">
        <button
            v-for="(tile, index) in tiles"
            :key="tile.value"
            class="tile-select__tile"
            :class="{
                'tile-select__tile--selected': tile.value === selected,
                'tile-select__tile--disabled': disabled || tile.disabled,
            }"
            :disabled="disabled || tile.disabled"
            :data-test="`tile-select-option-${index}`"
            @click="select(tile.value, tile.disabled)"
        >
            <Words>
                {{ tile.label }}
            </Words>
        </button>
    </div>
</template>

<script>
import Words from '@/components/Typography/Words';

export default {
    name: 'TileSelect',
    components: { Words },
    props: {
        value: {
            type: [String, Number, Boolean, Array, Object],
            default: null,
        },
        tiles: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        selected() {
            return this.value;
        },
    },
    methods: {
        select(value, tileDisabled = false) {
            if (this.disabled || tileDisabled) {
                return;
            }

            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss" scoped>
.tile-select {
    display: flex;
    width: 100%;
    gap: 16px;
    flex-wrap: wrap;

    &__tile {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 52px;
        border-radius: 4px;
        background-color: $color-white;
        font-size: 14px;
        font-weight: normal;
        cursor: pointer;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        transition: box-shadow 0.2s ease-in-out;
        border: none;
        padding: 0 24px;
        font-family: $font-family;

        &:hover {
            box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
        }

        &:focus:not(:focus-visible) {
            outline: none;
        }

        &:focus-visible {
            outline: 1px solid $sflx-grey-800;
        }

        &--selected {
            background-color: $sflx-grey-800;
            text-shadow:
                -0.06ex 0 $color-white,
                0.06ex 0 $color-white;
            color: $color-white;
            box-shadow: none;

            &:hover {
                box-shadow: none;
            }
        }

        &--disabled {
            opacity: 0.5;
            box-shadow: none;
            cursor: not-allowed;

            &:hover {
                box-shadow: none;
            }
        }
    }
}
</style>

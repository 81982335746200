var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"radio-field",class:{
        'radio-field--boxed': _vm.boxed,
        'radio-field--error': _vm.error,
        'radio-field--active': _vm.isActive,
        'radio-field--disabled': _vm.disabled,
    },attrs:{"data-test":_vm.dataTest}},[(_vm.labelBeforeRadio)?_c('div',{staticClass:"radio-field__label",class:{
            'radio-field__label--disabled': _vm.disabled,
        }},[_vm._t("default")],2):_vm._e(),_c('Radio',{staticClass:"radio-field__input",attrs:{"value":_vm.value,"name":_vm.name,"option":_vm.option,"disabled":_vm.disabled,"error":_vm.error,"small":_vm.small || _vm.boxed || (_vm.dynamicSize && _vm.$root.isDesktop),"data-test":_vm.dataTest},on:{"input":function($event){return _vm.$emit('input', $event)}}}),(!_vm.labelBeforeRadio)?_c('div',{staticClass:"radio-field__label",class:{
            'radio-field__label--disabled': _vm.disabled,
        }},[_vm._t("default")],2):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
import AbstractResource from '../AbstractResource';

class DisposalPrice extends AbstractResource {
    /**
     * Update disposal price for order
     * @param {int} orderId
     * @param {number} disposalPrice
     * @param {boolean} isPerTransport
     */
    async updateOrder(orderId, disposalPrice, isPerTransport) {
        try {
            const payload = this.buildPayload(disposalPrice, isPerTransport);
            const response = await this.apiService.post(`/v1/disposal-price/order/${orderId}`, payload);
            return response.data;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }

    /**
     * Update disposal price for quote
     * @param {int} quoteId
     * @param {number} disposalPrice
     * @param {boolean} isPerTransport
     */
    async updateQuote(quoteId, disposalPrice, isPerTransport) {
        try {
            const payload = this.buildPayload(disposalPrice, isPerTransport);
            const response = await this.apiService.post(`/v1/disposal-price/quote/${quoteId}`, payload);
            return response.data;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }

    /**
     * Update disposal price for project position
     * @param {int} projectPositionId
     * @param {number} disposalPrice
     * @param {boolean} isPerTransport
     */
    async updateProjectPosition(projectPositionId, disposalPrice, isPerTransport) {
        try {
            const payload = this.buildPayload(disposalPrice, isPerTransport);
            const response = await this.apiService.post(
                `/v1/disposal-price/project-position/${projectPositionId}`,
                payload
            );
            return response.data;
        } catch (err) {
            throw this.handleError(err).message;
        }
    }

    /**
     * build disposal price update payload
     * @param {number} disposalPrice
     * @param {boolean} isPerTransport
     * @return {object} payload
     */
    buildPayload(disposalPrice, isPerTransport) {
        if (isPerTransport) {
            return {
                disposalPricePerTon: null,
                disposalPricePerTransport: disposalPrice,
            };
        } else {
            return {
                disposalPricePerTon: disposalPrice,
                disposalPricePerTransport: null,
            };
        }
    }
}

export default new DisposalPrice();

import { EventBus } from '@/services/EventBus';
class Toaster {
    success(message, opts = {}) {
        this.insertBread('success', message, opts);
    }
    info(message, opts = {}) {
        this.insertBread('info', message, opts);
    }
    error(message, opts = {}) {
        this.insertBread('error', message, opts);
    }
    warning(message, opts = {}) {
        this.insertBread('warning', message, opts);
    }
    insertBread(type, message, opts = {}) {
        // eslint-disable-next-line vue/custom-event-name-casing
        EventBus.$emit('toaster.toast', {
            ...opts,
            type,
            message,
        });
    }
}
export default new Toaster();

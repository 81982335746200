<template>
    <div class="confirmation-screen">
        <Button class="confirmation-screen__close" @click="$emit('close')">
            <CloseIcon width="16" height="16" />
        </Button>

        <div class="confirmation-screen__icon">
            <slot name="icon" />
        </div>

        <slot name="content" />

        <div v-if="$slots['additional-content']" class="confirmation-screen__additional-content">
            <slot name="additional-content" />
        </div>
    </div>
</template>

<script>
import Button from '@/components/Button/Button';

import CloseIcon from '@/assets/icons/regular/close.svg';

export default {
    name: 'ConfirmationScreen',
    components: {
        Button,
        CloseIcon,
    },
};
</script>

<style lang="scss">
.confirmation-screen {
    position: relative;
    padding: 20px 15px;

    @media only screen and (min-width: $layout-desktop-min) {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.confirmation-screen__close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
}

.confirmation-screen__icon {
    svg {
        display: block;
        width: 95px;
        height: auto;
        margin: 0 auto;
        padding: 40px 0 50px;
    }
}

.confirmation-screen__additional-content {
    margin-top: 40px;

    textarea {
        border-top: 1px solid $color-mediumGrey;
        margin-top: 20px;
        padding: 10px;
    }
}
</style>

export default class CustomPositionPricingItemView {
    constructor() {
        this._name = null;
        this._unit = null;
        this._qty = null;
        this._count = null;
        this._purchaseUnitPrice = null;
        this._retailUnitPrice = null;
        this._creditTo = null;
        this._vehicleClassId = null;
        this._positionIndex = null;
        this._transportDistance = null;
        this._vehiclePayload = null;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {CustomPositionPricingItemView|null}
     */
    static createFromCustomOrder(data) {
        if (!data) return null;

        const view = new CustomPositionPricingItemView();

        view.name = data.name;
        view.unit = data.unit;
        view.qty = data.qty;
        view.purchaseUnitPrice = data.unitPurchasePrice;
        view.retailUnitPrice = data.unitRetailPrice;
        view.creditTo = data.creditTo;
        view.vehicleClassId = data.vehicleClassId;

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {CustomPositionPricingItemView|null}
     */
    static createFromProjectShipmentPosition(data) {
        if (!data) return null;

        const view = new CustomPositionPricingItemView();

        view.name = data.name;
        view.unit = data.unit;
        view.qty = data.qty;
        view.count = data.count;
        view.purchaseUnitPrice = data.unitPurchasePrice;
        view.retailUnitPrice = data.unitRetailPrice;
        view.creditTo = data.creditTo;
        view.vehicleClassId = data.vehicleClassId;
        view.transportDistance = data.transportDistance;
        view.vehiclePayload = data.vehiclePayload;
        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {CustomPositionPricingItemView|null}
     */
    static createFromDisposalPayload(data) {
        if (!data) return null;

        const view = new CustomPositionPricingItemView();

        view.name = data.name;
        view.unit = data.unit;
        view.qty = data.qty;
        view.count = data.count;
        view.purchaseUnitPrice = data.purchaseUnitPrice;
        view.retailUnitPrice = data.retailUnitPrice;
        view.creditTo = data.creditTo;
        view.vehicleClassId = data.vehicleClassId;
        view.transportDistance = data.transportDistance;
        view.vehiclePayload = data.vehiclePayload;

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {CustomPositionPricingItemView|null}
     */
    static create(data) {
        if (!data) return null;

        const view = new CustomPositionPricingItemView();

        view.name = data.name;
        view.unit = data.unit;
        view.qty = data.qty;
        view.count = data.count;
        view.purchaseUnitPrice = data.purchaseUnitPrice;
        view.retailUnitPrice = data.retailUnitPrice;
        view.creditTo = data.creditTo;
        view.vehicleClassId = data.vehicleClassId;
        view.transportDistance = data.transportDistance;
        view.vehiclePayload = data.vehiclePayload;

        return view;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfoldToCustomOrder() {
        return {
            name: this.name,
            unit: this.unit,
            qty: this.qty,
            unitPurchasePrice: this.purchaseUnitPrice,
            unitRetailPrice: this.retailUnitPrice,
            creditTo: this.creditTo,
        };
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            name: this.name,
            unit: this.unit,
            qty: this.qty,
            count: this.count,
            purchaseUnitPrice: this.purchaseUnitPrice,
            retailUnitPrice: this.retailUnitPrice,
            creditTo: this.creditTo,
            vehicleClassId: this.vehicleClassId,
            transportDistance: this.transportDistance,
            vehiclePayload: this.vehiclePayload,
        };
    }

    /**
     * Clone current instance
     * @return {CustomPositionPricingItemView|null}
     */
    clone() {
        return CustomPositionPricingItemView.create(this.unfold());
    }

    get name() {
        return this._name;
    }

    set name(value) {
        this._name = value ?? null;
    }

    get unit() {
        return this._unit;
    }

    set unit(value) {
        this._unit = value ?? null;
    }

    get qty() {
        return this._qty;
    }

    set qty(value) {
        this._qty = value ?? null;
    }

    get count() {
        return this._count;
    }

    set count(value) {
        this._count = value ?? null;
    }

    get purchaseUnitPrice() {
        return this._purchaseUnitPrice;
    }

    set purchaseUnitPrice(value) {
        this._purchaseUnitPrice = value ?? null;
    }

    get purchaseUnitPriceTotal() {
        return this._purchaseUnitPrice * this.qty;
    }

    get purchaseUnitPricePerKm() {
        return (this.purchaseUnitPrice / this.transportDistance) * 1000;
    }

    get purchaseUnitPricePerTransport() {
        return this.purchaseUnitPrice;
    }

    get purchaseUnitPricePerTon() {
        return (this.purchaseUnitPricePerTransport / this.vehiclePayload) * 1000;
    }

    get retailUnitPrice() {
        return this._retailUnitPrice;
    }

    set retailUnitPrice(value) {
        this._retailUnitPrice = value ?? null;
    }

    get retailUnitPriceTotal() {
        return this.retailUnitPrice * this.qty;
    }

    get retailUnitPricePerKm() {
        return (this.retailUnitPrice / this.transportDistance) * 1000;
    }

    get retailUnitPricePerTransport() {
        return this.retailUnitPrice;
    }

    get retailUnitPricePerTon() {
        return (this.retailUnitPricePerTransport / this.vehiclePayload) * 1000;
    }

    get creditTo() {
        return this._creditTo;
    }

    set creditTo(value) {
        this._creditTo = value ?? null;
    }

    get vehicleClassId() {
        return this._vehicleClassId;
    }

    set vehicleClassId(value) {
        this._vehicleClassId = value ?? null;
    }

    get positionIndex() {
        return this._positionIndex;
    }

    set positionIndex(value) {
        this._positionIndex = value;
    }

    get transportDistance() {
        return this._transportDistance;
    }

    set transportDistance(value) {
        this._transportDistance = value ?? null;
    }

    get vehiclePayload() {
        return this._vehiclePayload;
    }

    set vehiclePayload(value) {
        this._vehiclePayload = value ?? null;
    }
}

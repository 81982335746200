import InvoiceListPage from './InvoiceListPage';
import InvoiceDocumentListPage from './InvoiceDocumentListPage';

export default {
    path: 'invoice',
    name: 'invoice-list',
    component: InvoiceListPage,
    meta: {
        requiresAuth: true,
        noBackArrow: true,
        requiredAbilities: ['haveInvoiceManagement'],
    },
    children: [
        {
            path: ':invoiceId/document-list',
            name: 'invoice-document-list',
            component: InvoiceDocumentListPage,
            props: route => {
                return {
                    ...route.params,
                    parentRoute: 'invoice-list',
                };
            },
        },
    ],
};

import React, { createContext, useContext } from 'react';
const VueRouterContext = createContext(null);
export const useVueRouter = () => {
    const vueRouter = useContext(VueRouterContext);
    if (!vueRouter) {
        throw new Error('useVueRouter must be used within a VueRouterProvider');
    }
    return {
        vueRouter,
    };
};
export const VueRouterProvider = ({ vueRouter, children }) => {
    return <VueRouterContext.Provider value={vueRouter}>{children}</VueRouterContext.Provider>;
};

import { ensureJSTimestamp } from '@/services/utils/date';
import userflow from 'userflow.js';
import { getUserflowLocaleByLocaleCode } from '@/i18n/languages';
if (window.userflowToken) {
    userflow.init(window.userflowToken);
    userflow.setResourceCenterLauncherHidden(true);
}
else if (process.env.NODE_ENV !== 'test') {
    // eslint-disable-next-line no-console
    console.warn(' No userflow token set.');
}
export const identify = async ({ id, locale, permissions, created }, organization) => {
    await userflow.identify(id.toString(), {
        locale_code: getUserflowLocaleByLocaleCode(locale),
        name: id.toString(),
        userId: id.toString(),
        signed_up_at: new Date(ensureJSTimestamp(created)).toISOString(),
        app_version: window.appVersion.number,
        app_version_normalized: window.appVersion.normalized,
        device_type: window.innerWidth > 1023 ? 'desktop' : 'mobile',
        ...Object.fromEntries([
            'administration',
            'accounting',
            'product_management',
            'custom_order',
            'organization_management',
            'project_management',
            'order_management',
            'price_access',
            'drive',
            'manage_construction_project',
            'project_order',
            'quote',
            'order',
            'load',
            'finance-api',
        ].map(permission => [permission, null])),
        ...Object.fromEntries(permissions.map(permission => [permission, true])),
    });
    const activeSupplierType = organization.features.supplier_type?.active && organization.features.supplier_type;
    const features = Object.entries(organization.features)
        .filter(([featureName, { active }]) => active === true && featureName !== 'supplier_type')
        .map(([featureName]) => featureName)
        .concat(activeSupplierType
        ? activeSupplierType.isProducer
            ? ['supplier_type_producer']
            : ['supplier_type_reseller']
        : [])
        .join(', ');
    await userflow.group(organization.id.toString(), {
        organizationId: organization.id.toString(),
        organizationName: organization.name,
        name: organization.id.toString(),
        market: organization.market.code,
        paymentMethod: organization.paymentInfo.method,
        organization_plz_region: organization.billingAddress.zip,
        ...Object.fromEntries(['platform', 'carrier', 'supplier', 'client'].map(type => [type, null])),
        ...Object.fromEntries(organization.types.map(type => [type, true])),
        ...Object.fromEntries([
            'carrier_delivery',
            'carrier_disposal',
            'carrier_shipment',
            'client_delivery',
            'client_pickup',
            'client_project',
            'client_shipment',
            'preferred_carrier',
            'supplier_delivery',
            'supplier_disposal',
            'supplier_pickup',
            'supplier_type_producer',
            'supplier_type_reseller',
        ].map(feature => [feature, null])),
        features,
    });
};

import { ensureJSTimestamp } from '@/services/utils/date';
import _get from 'lodash/get';
import { computed, onUnmounted, reactive, ref, unref, watch } from 'vue';
import { useCan } from '@/utils/composition-helper';
import { RECORD_WEIGHING_BY_DRIVER } from '@/services/TransportActions';
import { useStateful } from '@/services/stateful/stateful';
import { useTransportAction } from '@/pages/Transport/Actions/composables/useTransportAction';
import Toaster from '@/services/Toaster';
import i18n from '@/i18n';
import { weighingMethods } from '@/constants/weighingMethods';
import { required, requiredIf, requiredUnless, integer } from '@vuelidate/validators';
import { useValidation } from '@/pages/Transport/Actions/composables/useValidation';
import { removeFalsyValuesFromObject } from '@/utils/helpers';
import mitt from 'mitt';
import Transport from '@/services/Api/Transport';
import { getValidationMessage } from '@/_components/composables/validationMessages';
import { useNow } from '@vueuse/core';
import { scrollToFirstError } from '@/services/validation/error-scroller';
const getEmptyWeighing = () => ({
    // weighing based
    weighingDate: null,
    weighingNoteImages: [],
    weighingNoteDocuments: [],
    // weighing method based
    weighingNumber: null,
    weight: undefined,
    tare: undefined,
    full: undefined,
    shovels: undefined,
    cbm: undefined,
});
export const getEmptyForm = () => ({
    weighingMethod: '',
    ...getEmptyWeighing(),
});
export function useTransportRecordWeighingByDriverAction({ initialValues }) {
    const { transport: transportAllTypes, closeAction, isActionOpen, onActionOpen, onActionClose, context, } = useTransportAction(RECORD_WEIGHING_BY_DRIVER);
    const transport = transportAllTypes;
    const { isPending, stateful } = useStateful();
    const now = useNow({
        interval: 60000,
    });
    const serviceAndWeighingDateMax = computed(() => now.value.getTime());
    const form = reactive({
        ...getEmptyForm(),
    });
    onActionClose(() => {
        Object.assign(form, getEmptyForm());
    });
    const isEmptyFullWeighing = () => form.weighingMethod === weighingMethods.EMPTY_FULL;
    const isShovelWeighing = () => form.weighingMethod === weighingMethods.SHOVEL;
    const isCMBWeighing = () => form.weighingMethod === weighingMethods.CBM;
    /**
     * OCR Tracking
     */
    const changedValuesToBeTracked = ref([]);
    watch(initialValues, () => {
        changedValuesToBeTracked.value = [];
    }, { deep: true });
    watch(form, ({ weight, weighingDate, tare, full, shovels, cbm, weighingNumber }) => {
        const previous = unref(initialValues);
        if (!changedValuesToBeTracked.value.includes('quantity') && weight !== previous.quantity)
            changedValuesToBeTracked.value.push('quantity');
        if (!changedValuesToBeTracked.value.includes('date') && weighingDate !== previous.date)
            changedValuesToBeTracked.value.push('date');
        if (!changedValuesToBeTracked.value.includes('tare') && tare !== previous.tare)
            changedValuesToBeTracked.value.push('tare');
        if (!changedValuesToBeTracked.value.includes('full') && full !== previous.full)
            changedValuesToBeTracked.value.push('full');
        if (!changedValuesToBeTracked.value.includes('cbm') && cbm !== previous.cbm)
            changedValuesToBeTracked.value.push('cbm');
        if (!changedValuesToBeTracked.value.includes('shovels') && shovels !== previous.shovels)
            changedValuesToBeTracked.value.push('shovels');
        if (!changedValuesToBeTracked.value.includes('number') && weighingNumber !== previous.number)
            changedValuesToBeTracked.value.push('number');
    }, { deep: true });
    const { setServerErrors, v$ } = useValidation({
        rules: computed(() => ({
            weighingMethod: { required },
            // weighing based
            weighingDate: { required },
            weighingNoteImages: {
                required: requiredUnless(() => !!form.weighingNoteDocuments.length),
            },
            weighingNoteDocuments: {
                required: requiredUnless(() => !!form.weighingNoteImages.length),
            },
            // weighing method based
            weighingNumber: { required },
            weight: { required: requiredIf(() => isShovelWeighing()), integer },
            shovels: { required: requiredIf(() => isShovelWeighing()), integer },
            tare: { required: requiredIf(() => isEmptyFullWeighing()), integer },
            full: { required: requiredIf(() => isEmptyFullWeighing()), integer },
            cbm: {
                required: requiredIf(() => isCMBWeighing() && !form.cbm?.cbm),
            },
        })),
        data: form,
        serverErrorMapping: {
            weighingMethod: ['weighingMethod'],
            weighingNoteImages: ['', '#', 'weighingNoteImageUuid'],
            weighingNoteDocuments: ['', '#', 'weighingNoteDocumentUuid'],
            weighingNumber: ['weighingNoteNumber'],
            weight: ['totalLoadingInKg'],
            tare: ['tareWeightInKg'],
            full: ['fullWeightInKg'],
            shovels: ['shovelCount'],
            cbm: ['totalLoadingInKg'],
            weighingDate: ['weighingCompletedDateTime'],
        },
    });
    const isCaptureWeightVisible = computed(() => !!form.weighingMethod);
    // reset weighing data when user deletes uploaded document or image
    const handleFileDeleted = () => {
        if (!form.weighingMethod)
            return;
        startWeighing(form.weighingMethod);
    };
    onActionClose(() => {
        v$.value.$clearExternalResults();
        v$.value.$reset();
    });
    const hasValidData = computed(() => {
        return !v$.value.$invalid;
    });
    const resetWeighing = () => {
        Object.assign(form, getEmptyWeighing());
        form.cbm = getEmptyWeighing().cbm;
        form.weighingNoteDocuments = [];
        form.weighingNoteImages = [];
    };
    const startWeighing = (nextWeighingMethod) => {
        resetWeighing();
        Object.assign(form, {
            cbm: {
                weighingNumber: '',
                cbm: {
                    cbm: null,
                    cbmInKg: 0,
                    density: transport.value?.product.density || 0,
                },
            },
            'empty-full': {
                weighingNumber: '',
                tare: null,
                full: null,
            },
            shovel: {
                weighingNumber: '',
                weight: null,
                shovels: null,
            },
        }[nextWeighingMethod]);
    };
    const preparePayloadFromState = () => removeFalsyValuesFromObject({
        weighingNoteDocumentUuid: form.weighingNoteDocuments[0]?.uuid,
        weighingNoteImageUuid: form.weighingNoteImages[0]?.uuid,
        weighingCompletedDateTime: new Date(ensureJSTimestamp(form.weighingDate || 0)).toISOString(),
        weighingMethod: form.weighingMethod,
        ...removeFalsyValuesFromObject({
            weighingNoteNumber: form.weighingNumber,
            totalLoadingInKg: form.weight || form.cbm?.cbmInKg,
            tareWeightInKg: form.tare,
            fullWeightInKg: form.full,
            shovelCount: form.shovels,
        }),
    });
    const emitter = mitt();
    const onSubmissionSuccessful = (callback) => {
        emitter.on('submissionSuccessful', callback);
    };
    onUnmounted(() => emitter.all.clear());
    const can = useCan();
    const handleSubmission = async () => {
        v$.value.$clearExternalResults();
        v$.value.$touch();
        if (!(await v$.value.$validate())) {
            scrollToFirstError();
            return;
        }
        if (!can.value('transportRecordLoadingWeighing', transport.value)) {
            throw new Error('Unable to record weighing of transport by driver');
        }
        await stateful('handleSubmission', async () => {
            if (!context.value)
                throw Error('context is not defined');
            if (!transport.value)
                throw Error('transport is not defined');
            const payload = preparePayloadFromState();
            try {
                const transportService = new Transport(context.value);
                await transportService.driverConfirmLoadingForDelivery(transport.value.id, payload);
                Toaster.success(String(i18n.t('components.activityCards.delivery.checkedIn.checkedInDriverNotice', {
                    number: transport.value.number,
                })));
                emitter.emit('submissionSuccessful');
                closeAction();
            }
            catch (err) {
                const serverErrors = _get(err, 'response.data', {});
                setServerErrors(serverErrors);
                const message = getValidationMessage(err);
                Toaster.error(message);
            }
        });
    };
    return {
        transport,
        closeAction,
        isActionOpen,
        onActionOpen,
        onActionClose,
        // Weighing
        startWeighing,
        isCaptureWeightVisible,
        handleFileDeleted,
        serviceAndWeighingDateMax,
        isPending,
        v$,
        hasValidData,
        handleSubmission,
        onSubmissionSuccessful,
        changedValuesToBeTracked,
    };
}

<template>
    <LayoutPage
        class="product-overview-page"
        screen-name="platform-productmanagement-waste-list"
        :parent-route="parentRoute"
        data-test="product-overview-page"
    >
        <div slot="pageTitle">{{ $t(`pages.productManagement.tiles.titles.waste`) }}</div>

        <RoundedTabNavigation
            :value="activeTab"
            :tabs="orderViewTabs"
            data-test="tab-navigation-item"
            @input="updateTabNavigation"
        />

        <Card spaceless>
            <portal-target name="waste-management-filter-portal" />
        </Card>

        <transition mode="out-in">
            <router-view />
        </transition>
    </LayoutPage>
</template>

<script>
import LayoutPage from '@/components/Layout/Page.v2';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation';
import Card from '@/components/Layout/Card';

const ROUTES = {
    products: 'waste-product-list',
    categories: 'waste-category',
    sort: 'waste-sort',
};

export default {
    name: 'WasteOverviewPage',
    components: {
        LayoutPage,
        Card,
        RoundedTabNavigation,
    },
    props: {
        parentRoute: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            currentStage: this.$route.name,
        };
    },
    computed: {
        orderViewTabs() {
            return {
                [ROUTES.products]: this.$t('pages.productManagement.wasteOverviewPage.tabsNavigation.products'),
                [ROUTES.categories]: this.$t('pages.productManagement.wasteOverviewPage.tabsNavigation.category'),
                [ROUTES.sort]: this.$t('pages.productManagement.wasteOverviewPage.tabsNavigation.sort'),
            };
        },

        activeTab() {
            const productsRoute = ROUTES.products;
            const categoriesRoute = ROUTES.categories;

            if (this.$route.name.includes(productsRoute)) {
                return productsRoute;
            }

            if (this.$route.name.includes(categoriesRoute)) {
                return categoriesRoute;
            }

            return ROUTES.sort;
        },
    },
    methods: {
        updateTabNavigation(tab) {
            this.$router.push({ name: this.$root.findRouteName(tab) }).catch(() => {});
        },
    },
};
</script>

<style lang="scss">
.product-overview-page {
    background-color: $color-lightMediumGrey;
}
</style>

<template>
    <hr :class="`my-${size} border-t border-divider`" />
</template>

<script>
export default {
    name: 'HorizontalDivider',
    props: {
        size: {
            type: String,
            default: '8',
            validator: value => {
                return ['2', '4', '6', '8', '12'].includes(value);
            },
        },
    },
};
</script>

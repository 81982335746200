var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AddressProgress',{attrs:{"list":_vm.addressList,"progress":_vm.progress(),"orb-padding":56,"spaceless":""},scopedSlots:_vm._u([{key:"default",fn:function({ item, isFirst, isLast }){return [(item.maximumTrafficability && _vm.showMaximumTrafficability)?_c('p',{class:[
                'font-copy-sm',
                'mt-2',
                {
                    'text-critical': !_vm.isUnavailable,
                    'text-subdued': _vm.isUnavailable,
                },
            ]},[_vm._v(" "+_vm._s(_vm.$t('pages.transportHub.trafficability', { trafficability: item.maximumTrafficability / 1000, }))+" ")]):_vm._e(),(
                (_vm.showShippingDates && !_vm.showOnlyLast() && !_vm.showOnlyFirst()) ||
                (_vm.showShippingDates && _vm.showOnlyLast() && isLast) ||
                (_vm.showShippingDates && _vm.showOnlyFirst() && isFirst)
            )?_c('TransportListBlockShippingDates',{staticClass:"mt-4",attrs:{"show-loading":item.type === 'loading' && (!_vm.isShipmentTransport() || _vm.shouldShipmentLocationShown('loading')),"show-unloading":item.type === 'unloading' && (!_vm.isShipmentTransport() || _vm.shouldShipmentLocationShown('unloading')),"show-shipping-dates":"","transport":_vm.transport}}):_vm._e(),(item.hasInformation && _vm.showInformationButtons)?_c('p',{staticClass:"font-copy-sm mt-2"},[_c('BaseButton',{attrs:{"weight-normal":"","adjust-icon":"","align-left":"","disabled":_vm.isUnavailable},on:{"click":function($event){$event.stopPropagation();return _vm.handleAddressClick(item)}}},[_c('SfWarningIcon',{attrs:{"slot":"left","size":"xxs","multi-color":""},slot:"left"}),_vm._v(" "+_vm._s(item.type === 'loading' ? _vm.$t('pages.transportHub.informationHint.loading') : _vm.$t('pages.transportHub.informationHint.unloading'))+" › ")],1)],1):_vm._e()]}}])})
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
    <div v-if="showComponent" class="loading-site">
        <div class="font-copy-sm-strong mb-6">
            {{ title || siteTypeHeading }}
        </div>

        <div class="unloading-site__thumbs flex flex-wrap gap-8">
            <div v-if="item[siteTypeLocation]" class="relative">
                <SfThumbnail
                    :file-src="staticMap"
                    :translations="{
                        noImage: $t('components.thumbnail'),
                    }"
                    @click="openMap"
                />
            </div>

            <SfThumbnail
                v-if="item[siteTypeImage]"
                :file-thumbnail-src="item[siteTypeImage].url.thumbnail"
                :file-src="item[siteTypeImage].url.original"
                :translations="{
                    noImage: $t('components.thumbnail'),
                }"
                modal
            />
        </div>

        <span v-if="item[siteTypeInformation]" class="font-copy-md mt-6 break-words">
            {{ item[siteTypeInformation] }}
        </span>

        <Flyout :active="mapOpen" :headline="siteTypeHeading" @closed="closeMap">
            <UnloadingMap :location="item[siteTypeLocation]" map-type="satellite" />
        </Flyout>
    </div>
</template>

<script>
import { getStaticMapUrl } from '@/services/GoogleMaps';

import Flyout from '@/components/Layout/Flyout';
import UnloadingMap from './UnloadingMap';

import SummaryView from '@/pages/Checkout/SummaryView';

import { SfThumbnail } from '@schuettflix/vue-components';

export default {
    name: 'SiteLocation',
    components: {
        Flyout,
        UnloadingMap,

        SfThumbnail,
    },
    props: {
        item: {
            type: [Object, SummaryView],
            required: true,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
        siteType: {
            type: String,
            default: 'loadingSite',
            validator: v => ['loadingSite', 'unloadingSite'].includes(v),
        },
        title: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            mapOpen: false,
            siteTypeLocation: null,
            siteTypeInformation: null,
            siteTypeImage: null,
        };
    },
    computed: {
        showComponent() {
            return (
                this.item[this.siteTypeLocation] || this.item[this.siteTypeInformation] || this.item[this.siteTypeImage]
            );
        },
        staticMap() {
            if (!this.item[this.siteTypeLocation]) return null;
            return getStaticMapUrl(this.item[this.siteTypeLocation], 16, 200, 200, 'satellite');
        },
        siteTypeHeading() {
            return this.$t(
                {
                    unloadingSite: 'pages.checkout.summary.unloadingSite.headline',
                    loadingSite: 'components.transportTile.loadingSite',
                }[this.siteType]
            );
        },
    },
    mounted() {
        this.siteTypeLocation = `${this.siteType}Location`;
        this.siteTypeImage = `${this.siteType}Image`;
        this.siteTypeInformation = `${this.siteType}Information`;
    },
    methods: {
        openMap() {
            this.mapOpen = true;
        },
        closeMap() {
            this.mapOpen = false;
        },
        selectUnloadingSiteLocation() {
            this.$emit('change-action');
        },
    },
};
</script>

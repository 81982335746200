<template>
    <div>
        <Words v-if="headline" block bold spaced-bottom>{{ headline }}</Words>
        <Words v-if="description" block spaced-bottom>{{ description }}</Words>
        <DatePicker
            ref="datePicker"
            :value="value"
            type="mixed"
            :range-config="availableDays"
            :max-range="range"
            :disabled="disabled"
            data-test="button-select-flexible-period"
            @input="updateValue"
        >
            <template #default="{ isInvalid }">
                <TextField
                    :value="formattedDateRange"
                    :label="$t('pages.checkout.shipmentLoading.date.label')"
                    readonly
                    disabled
                    :error="isInvalid ? $t('validation.isDayMatchingRangeConfig') : null"
                >
                    <CalendarIcon slot="icon" />
                </TextField>
            </template>
        </DatePicker>
    </div>
</template>

<script>
import Words from '@/components/Typography/Words';
import DatePicker from '@/components/Form/DatePicker';
import TextField from '@/components/Form/TextField.v2';

import CalendarIcon from '@/assets/icons/micro/calendar.svg';

export default {
    name: 'ShipmentDatePicker',

    components: {
        Words,
        DatePicker,
        TextField,

        CalendarIcon,
    },

    props: {
        value: {
            type: Object,
            default: null,
        },
        headline: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        availableDays: {
            type: Array,
            default: null,
        },
        range: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        formattedDateRange() {
            if (!this.value) return null;

            const parts = [];
            parts.push(this.$d(this.value.start, 'short'));

            if (this.value.end) {
                parts.push(this.$d(this.value.end, 'short'));
            }

            return parts.join(' - ');
        },
    },

    methods: {
        updateValue(value) {
            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss"></style>

let provider = null;

// The reason the methods start, stop, sync & geCurrentLocation are async,
// is that this way they have a consistent return type even in the cases where
// the handlers aren't implemented and undefined is returned.
export const TrackingService = {
    setProvider: newProvider => {
        provider = newProvider;
    },
    init: async () => {
        return provider?.init?.();
    },
    start: async (...args) => {
        return provider?.start?.(...args);
    },
    stop: async () => {
        return provider?.stop?.();
    },
    sync: async () => {
        return provider?.sync?.();
    },
    startOnActiveTransports: async () => {
        return provider?.startOnActiveTransports?.();
    },
    hasTrackingPermissions: async () => {
        return provider?.hasTrackingPermissions?.();
    },
};

export const statusMapping = {
    shipment: ['started', 'in_transit'],
    delivery: ['in_transit'],
    pickup: [],
};

<template>
    <div class="tab-stages">
        <div
            :style="{
                width: 100 + '%',
                left: '-' + activeIndex * 100 + '%',
            }"
            class="tab-stages__track"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TabStages',
    props: {
        activeStage: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            activeIndex: 0,
            stagesCount: 0,
        };
    },
    watch: {
        activeStage: {
            initialised: true,
            handler(newVal) {
                this.notifyChildren('change-stage', newVal);
            },
        },
    },
    created() {
        this.$on('select-stage', activeIndex => {
            this.$set(this, 'activeIndex', activeIndex);
        });
    },
    mounted() {
        this.notifyChildren('change-stage', this.activeStage);
        this.stagesCount = this.$children.length;
    },
    methods: {
        notifyChildren(eventType, ...args) {
            this.$children.forEach((child, index) => {
                child.$emit(eventType, index, ...args);
            });
        },
    },
};
</script>

<style lang="scss">
.tab-stages {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.tab-stages__track {
    display: flex;
    position: relative;
    left: 0;
    will-change: left;
    transition: left 0.3s ease-in-out;
    height: 100%;
}
</style>

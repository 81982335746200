import { useVuexGetter } from '@/reactBridge/useVuex';
import store from '@/store';
import { useCallback } from 'react';
import { BrowserService } from '@/services/Browser/BrowserService';
export function useGenericPositionBrowser() {
    const genericOrderUrl = useVuexGetter(store, useCallback(getters => getters['platform/genericOrderUrl'], []));
    return {
        openEditPositionProducts(positionId) {
            return BrowserService.open(`${genericOrderUrl}/position/${positionId}/edit?section=products`);
        },
        openEditPositionSettings(positionId) {
            return BrowserService.open(`${genericOrderUrl}/position/${positionId}/edit?section=settings`);
        },
        openEditPositionTerms(positionId) {
            return BrowserService.open(`${genericOrderUrl}/position/${positionId}/edit?section=terms`);
        },
    };
}

import React from 'react';
export const CopyIcon = ({ size }) => {
    return (<svg viewBox="0 0 24 24" aria-hidden="true" fill="none" className={`svg-icon h-icon-size-${size} cursor-pointer w-icon-size-${size}`} xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#CopyIcon__a)" strokeWidth="2" strokeMiterlimit="10">
                <path d="M15.999 21.999H5V5" className={size === 'xxs' ? 'stroke-icon-dark' : 'stroke-icon-dark-accent'}/>
                <path d="M19 2H9V18H19V2Z" className={'stroke-icon'}/>
            </g>
            <defs>
                <clipPath id="CopyIcon__a">
                    <path fill="#fff" transform="translate(4 1)" d="M0 0H15.999V21.999H0z"/>
                </clipPath>
            </defs>
        </svg>);
};

import { useGenericPositionOverviewById } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/hooks/useGenericPositionOverviewById';
import { SummarySection } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/components/SummarySection';
import { CustomerProductsSection } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/components/CustomerProductsSection';
import { SupplierProductsSection } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/components/SupplierProductsSection';
export function PositionOverviewContent({ positionId, marketCode }) {
    const { data } = useGenericPositionOverviewById(positionId);
    if (!data) {
        return null;
    }
    return (<div className="flex flex-col gap-[40px]">
            <CustomerProductsSection organizations={data.organizations} marketCode={marketCode}/>
            <SupplierProductsSection organizations={data.organizations} marketCode={marketCode}/>
            <SummarySection organizations={data.organizations}/>
        </div>);
}

/**
 * Comment out node (like v-if does)
 *
 * @param {HTMLElement} el Element binded
 * @param {VNode} vnode VNode
 * @author https://stackoverflow.com/questions/43003976/a-custom-directive-similar-to-v-if-in-vuejs#43543814
 */
const commentNode = (el, vnode) => {
    const comment = document.createComment('');
    Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined,
    });

    vnode.elm = comment;
    vnode.text = '';
    vnode.isComment = true;
    vnode.context = undefined;
    vnode.tag = undefined;
    vnode.data.directives = undefined;

    if (vnode.componentInstance) {
        vnode.componentInstance.$el = comment;
    }

    if (el.parentNode) {
        el.parentNode.replaceChild(comment, el);
    }
};

const checkDirective = (store, check, subject, modifiers, el, vnode) => {
    const { disable = false, not = false } = modifiers;

    const allowed = store.getters['abilities/can'](check, subject);

    el.disabled = false;

    if ((allowed && not) || (!allowed && !not)) {
        if (disable) {
            el.disabled = true;
        } else {
            commentNode(el, vnode);
        }
    }
};

export default {
    /**
     * Installation method to bind to Vue instance
     *
     * @param {Vue} Vue Vue instance
     * @param {Object} param1 Vuex Store
     */
    install(Vue, { store }) {
        /**
         * Add Vue directive for v-can check
         *
         * @example v-can:[ABILITY NAME]
         * @example v-can:[ABILITY NAME]="[SUBJECT]"
         * @example v-can:[ABILITY NAME].disable Disables the element instead of removing it from dom
         * @example v-can:[ABILITY NAME].not Switch check to falsy
         */
        Vue.directive('can', {
            bind(el, binding, vnode) {
                checkDirective(store, binding.arg, binding.value, binding.modifiers, el, vnode);

                const unwatch = store.watch(
                    state => state.abilities.abilites,
                    () => {
                        checkDirective(store, binding.arg, binding.value, binding.modifiers, el, vnode);
                    }
                );

                el.__ability_unwatch__ = unwatch;
            },
            unbind(el) {
                el.__ability_unwatch__ && el.__ability_unwatch__();
            },
        });

        /**
         * Add $can check to use in code directly
         *
         * @example $can(ABILITY NAME, SUBJECT)
         */
        Vue.prototype.$can = (key, subject = undefined) => store.getters['abilities/can'](key, subject);
    },
};

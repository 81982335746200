<template>
    <div class="checkout-guide">
        <div class="checkout-guide__button" @click="openOrderTelephoneContact">
            <TelephoneContactAvatar position-header />
        </div>

        <portal to="checkoutGuideConstructionSiteSelectionMessage">
            <div class="container-deprecated checkout-guide__speech">
                <div class="checkout-guide__bubble">
                    <BubbleAnchor class="checkout-guide__anchor" />
                    <Words>
                        {{ $t('pages.checkout.checkoutGuide.bubbleText') }}
                    </Words>
                </div>
            </div>
        </portal>
    </div>
</template>

<script>
import { EventBus } from '@/services/EventBus';

import Words from '@/components/Typography/Words';
import TelephoneContactAvatar from '@/components/TelephoneContactAvatar';

import BubbleAnchor from '@/assets/icons/bubble-anchor.svg';

export default {
    name: 'CheckoutGuide',

    components: {
        Words,
        TelephoneContactAvatar,

        BubbleAnchor,
    },

    methods: {
        openOrderTelephoneContact() {
            EventBus.$emit('page.telephoneOrderContact', true);
        },
    },
};
</script>

<style lang="scss">
$checkout-header-size: 80px;

.checkout-guide {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkout-guide__button:active {
    transform: scale(0.9);
}

.checkout-guide__button {
    -webkit-appearance: none;
    background: none;
    outline: none;
    cursor: pointer;
}

.checkout-guide__speech {
    padding-top: 15px;
    padding-bottom: 15px;
}

.checkout-guide__bubble {
    padding: 15px 12px;
    background-color: $color-red;
    color: $color-white;
    border-radius: 5px;
    position: relative;
}

.checkout-guide__anchor {
    position: absolute;
    right: 40px;
    top: -10px;
    width: 14px;
}
</style>

<template>
    <LayoutPage
        class="user-list-page"
        :screen-name="isPlatformAdministrator ? 'platform-user-list' : 'user-list'"
        :parent-route="parentRoute"
    >
        <div slot="desktopHeaderNavigation">
            <BaseButton
                v-if="fromSettingsPage"
                arrow-left
                small
                class="page__navigation-back-button"
                @click="$router.back()"
            >
                {{ $t('pages.settingsV2.title') }}
            </BaseButton>
        </div>
        <div slot="pageTitle">
            {{ $route.params.pageTitle ? $route.params.pageTitle : $t('pages.user.userList.title') }}
        </div>

        <RoundedTabNavigation
            v-can:listAllUsers
            :value="activeListType"
            :tabs="userListTab"
            @input="updateTabNavigation"
        />

        <FilterBox
            v-model="filter"
            :default-filter="filter"
            :endpoint="dataEndpoint"
            inline-mode
            class="user-list-page__inline-filter"
            @update="refreshList(false, true)"
        >
            <template #default="filterScope">
                <TextField v-model="filterScope.filter.search" small :label="$t('pages.user.userList.filter.search')" />

                <FilterSortPagination
                    :result="users"
                    :filter="filterScope.filter"
                    :sort-options="sortOptions"
                    :option-label-renderer="value => $t(`pages.user.userList.sortings.${value}`)"
                    :hide-sort="!$root.isDesktop"
                    :loading="isLoading"
                    show-refresh
                    @refresh="refreshList(false, true)"
                    @pageNumberUpdated="pageNumberUpdated"
                >
                    <FilterBox
                        v-if="$root.isDesktop"
                        v-model="filter"
                        no-padding
                        screen-name="user-list-filter"
                        :default-filter="defaultFilter"
                        :endpoint="dataEndpoint"
                        :ignore-in-count="ignoreInCountFields"
                        :button-label="$t('pages.transportHub.filter.moreFilterButtonLabel')"
                        @save="refreshList(false, true)"
                    >
                        <UserFilterSet slot-scope="filterSetScope" :filter-scope="filterSetScope" />
                    </FilterBox>
                </FilterSortPagination>
            </template>
        </FilterBox>

        <div v-if="!showAddCTA" class="user-list-page__new-user-row">
            <Words bold block :spaced-bottom-small="!$root.isDesktop">
                {{ $tc('pages.user.userList.users', userCount) }}
            </Words>
            <BaseButton v-can:createUser primary @click="openOrganizationSelector">
                <PlusIcon slot="left" class="icon--inline" />
                {{ $t('pages.user.userList.actions.addUser') }}
            </BaseButton>
        </div>

        <CreateUserCTA
            v-if="showAddCTA"
            :organization-name="info.organization.name"
            class="user-list-page__add-user-cta"
            @onClickAddUserButton="openOrganizationSelector"
        />
        <template v-else-if="showUsersList">
            <UserList
                :items="groupedOrganizationUserList"
                :active-organization-type="activeListType"
                @onClickUser="user => editUser(user)"
                @onClickPermissions="user => openPermissionModal(user)"
                @onClickImitateUser="id => impersonateUser(id)"
            />

            <section class="mt-4">
                <Card spaceless class="user-list-page__pagination-bottom">
                    <Pagination :result="users" align-right @pageNumberUpdated="pageNumberUpdated" />
                </Card>
            </section>
        </template>

        <LoadingSpinner v-if="isLoading && !users" block dark />

        <OrganizationSelector
            :organization-selector-active="organizationSelectorActive"
            :selected-organization-type="selectedOrganizationType"
            @updateSelectedOrganizationType="type => (selectedOrganizationType = type)"
            @createUserForOrganizationType="(type, id, locale) => createUserForOrganizationType(type, id, locale)"
            @closed="organizationSelectorActive = false"
        />

        <UserForm
            :user-id="selectedUserId"
            :organization-id="selectedOrganizationId"
            :organization-locale="selectedOrganizationLocale"
            :organization-type="selectedOrganizationType"
            :active="userFormOpen"
            @closed="closedUserForm"
        />

        <ModalBox
            ref="permissionModal"
            :headline="$t('pages.user.userForm.permissionLabel')"
            @closed="closedPermissionModal()"
        >
            <LoadingSpinner v-if="isPending('loadingOrganizationPermission')" block dark />
            <template v-for="(permission, i) in selectedUserPermissions">
                <Words :key="i" block bold>{{ permission.title }}</Words>
                <Words :key="`d_${i}`" block spaced-bottom>{{ permission.description }}</Words>
            </template>
        </ModalBox>
    </LayoutPage>
</template>

<script>
import _groupBy from 'lodash/groupBy';
import _intersection from 'lodash/intersection';
import _isArray from 'lodash/isArray';
import _map from 'lodash/map';
import { assemblePhoneNumber } from '@/services/utils';
import { mapGetters, mapActions } from 'vuex';
import { defaultLocale } from '@/i18n';
import UserApi from '@/services/Api/UserV2';
import OrganizationApi from '@/services/Api/Organization';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';

import BaseButton from '@/components/Button/Button';
import Card from '@/components/Layout/Card';
import CreateUserCTA from './components/CreateUserCTA';
import FilterBox from '@/components/Filter/FilterBox';
import FilterSortPagination from '@/components/Filter/FilterSortPagination';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import ModalBox from '@/components/Modal/ModalBox';
import OrganizationSelector from './components/OrganizationSelector';
import Pagination from '@/components/Pagination';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation';
import TextField from '@/components/Form/TextField.v2';
import UserFilterSet from './components/UserFilterSet';
import UserForm from './components/UserForm';
import UserList from './components/UserList';
import Words from '@/components/Typography/Words';

import PlusIcon from '@/assets/icons/regular/plus.svg';

const DEFAULT_ORGANISATION_TYPE = 'client';
const DEFAULT_ORGANISATION_LIST = 'all';

export default {
    name: 'UserListPage',

    components: {
        BaseButton,
        Card,
        CreateUserCTA,
        FilterBox,
        FilterSortPagination,
        LayoutPage,
        LoadingSpinner,
        ModalBox,
        OrganizationSelector,
        Pagination,
        RoundedTabNavigation,
        TextField,
        UserFilterSet,
        UserForm,
        UserList,
        Words,

        PlusIcon,
    },

    mixins: [persistentFiltersMixin, statefulMixin, eventHubMixin],

    props: {
        parentRoute: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            filter: this.assembleFilter('user', {
                page: 1,
                perPage: 25,
                sortBy: 'id',
            }),
            defaultFilter: {
                page: 1,
                perPage: 25,
                sortBy: 'id',
            },
            dataEndpoint: UserApi,
            isLoading: false,
            activeListType: DEFAULT_ORGANISATION_LIST,
            organizationSelectorActive: false,
            selectedOrganizationId: null,
            selectedOrganizationLocale: defaultLocale,
            selectedOrganizationType: DEFAULT_ORGANISATION_TYPE,
            selectedUserId: null,
            selectedUserPermissions: [],
            showAddCTA: false,
            userFormOpen: false,
            users: null,
        };
    },

    computed: {
        ...mapGetters('user', {
            info: 'info',
            isPlatformAdministrator: 'isPlatformAdministrator',
            platformOrganizationId: 'organizationId',
            organizationTypes: 'organizationTypes',
            organization: 'organization',
        }),

        userCount() {
            return this.users ? this.users.count : 0;
        },

        showUsersList() {
            return this.userCount > 0;
        },

        sortOptions() {
            const options = ['organizationName', 'username', 'lastName'];

            if (this.$can('listAllUsers')) {
                options.push('id');
            }

            return _intersection(UserApi.supportedSorts, options);
        },

        ignoreInCountFields() {
            const fields = ['organizationType', 'search', 'organization'];

            if (!this.$can('listAllUsers')) {
                fields.push('organization');
            }

            return fields;
        },

        groupedOrganizationUserList() {
            if (this.users && this.users.items.length > 0) {
                const groupped = _groupBy(this.users.items, 'organization.id');
                const mapped = _map(groupped, value => ({ organization: value[0].organization, users: value }));
                return mapped;
            }
            return [];
        },

        userListTab() {
            return {
                all: this.$t('pages.user.userList.organizationTypes.all'),
                client: this.$t('pages.user.userList.organizationTypes.client'),
                supplier: this.$t('pages.user.userList.organizationTypes.supplier'),
                carrier: this.$t('pages.user.userList.organizationTypes.carrier'),
                platform: this.$t('pages.user.userList.organizationTypes.platform'),
            };
        },

        fromSettingsPage() {
            return this.$route.name.includes('settings__');
        },
    },

    watch: {
        selectedOrganizationType(value, oldValue) {
            if (value !== oldValue && value === 'platform') {
                this.createUserForOrganizationType('platform', this.platformOrganizationId);
            }
        },

        activeListType(value) {
            this.$set(this.filter, 'organizationType', value === 'all' ? null : value);
            this.refreshList(true, true);
        },

        filter(value) {
            if (value.organizationType) {
                this.activeListType = _isArray(value.organizationType)
                    ? value.organizationType[0]
                    : value.organizationType;
            } else {
                this.activeListType = 'all';
            }
        },
    },

    created() {
        this.checkShowAddCTA();
        this.refreshList(true);
    },

    methods: {
        assemblePhoneNumber,
        ...mapActions('user', ['impersonate']),

        pageNumberUpdated(number) {
            this.$eventHub.$emit('page.actions.scroll-top');
            this.filter.page = number;
            this.refreshList();
        },

        editUser(user) {
            if (!this.$can('updateUser', user)) {
                return;
            }

            this.selectedUserId = user.id;
            this.userFormOpen = true;

            setTimeout(() => {
                this.organizationSelectorActive = false;
            }, 500);
        },

        closedUserForm() {
            this.userFormOpen = false;
            this.refreshList();
            this.checkShowAddCTA();

            setTimeout(() => {
                this.userFormOpen = false;
            }, 200);
            setTimeout(() => {
                this.selectedUserId = null;
            }, 500);
        },

        openOrganizationSelector() {
            // the user is not a platform user
            // user can only create user for his/her own organization
            if (!this.$can('listAllUsers')) {
                this.selectedOrganizationId = this.organization.id;
                this.selectedOrganizationLocale = this.organization.market.defaultLocale;
                this.userFormOpen = true;
                return;
            }

            if (this.activeListType === 'all') {
                this.selectedOrganizationType = DEFAULT_ORGANISATION_TYPE;
            } else if (this.activeListType === 'platform') {
                this.createUserForOrganizationType('platform', this.platformOrganizationId);
                return;
            } else {
                this.selectedOrganizationType = this.activeListType;
            }

            this.organizationSelectorActive = true;
        },

        createUserForOrganizationType(organizationType, organizationId, organizationLocale = defaultLocale) {
            this.selectedOrganizationType = organizationType;
            this.selectedOrganizationId = organizationId;
            this.selectedOrganizationLocale = organizationLocale;
            this.userFormOpen = true;

            setTimeout(() => {
                this.organizationSelectorActive = false;
            }, 500);
        },

        async refreshList(isInitial = false, resetPagination = false) {
            this.isLoading = true;

            // check if we have to reset the pagination
            if (resetPagination) {
                this.filter.page = 1;
            }

            // persist filter
            this.persistFilter('user', this.filter, this.defaultFilter);

            // cancel previous request
            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.cancelSource = UserApi.createCancelTokenSource();

            try {
                const result = await UserApi.filter(this.filter, null, null, this.cancelSource);
                this.users = result;

                if (isInitial === true) {
                    this.filter = {
                        ...this.filter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }

            this.isLoading = false;
        },

        async checkShowAddCTA() {
            if (this.$can('listAllUsers')) return false;

            try {
                const result = await UserApi.filter({ countOnly: true });
                this.showAddCTA = result.count <= 1;
            } catch (err) {
                this.$logger().error(err);
            }
        },

        async impersonateUser(userId) {
            await this.impersonate(userId);
            this.$router.push({ name: 'home' }).catch(() => {});
        },

        updateTabNavigation(tab) {
            this.activeListType = tab;
        },

        async openPermissionModal(userObj) {
            this.$refs.permissionModal.$emit('open');

            try {
                let orgFeatures = null;

                await this.stateful('loadingOrganizationPermission', async () => {
                    orgFeatures = await OrganizationApi.getAvailablePermissions(userObj.organization.id);
                });

                const userPermissions = [];

                _map(orgFeatures, eachFeature => {
                    Object.keys(eachFeature).forEach(permission => {
                        if (userObj.permissions.includes(permission)) {
                            userPermissions.push(eachFeature[permission]);
                        }
                    });
                });

                this.selectedUserPermissions = userPermissions;
            } catch (err) {
                this.$logger().error(err);
            }
        },

        closedPermissionModal() {
            setTimeout(() => (this.selectedUserPermissions = []), 500);
        },
    },
};
</script>

<style lang="scss">
.user-list-page {
    background-color: $color-lightMediumGrey;
}

.user-list-page__inline-filter {
    background-color: $color-white;
    box-shadow: $boxShadow-bottomShort;
    margin-bottom: 30px;
}

.user-list-page__add-user-cta {
    @media screen and (max-width: $layout-desktop-max) {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.user-list-page__new-user-row {
    margin-bottom: 30px;

    @media screen and (min-width: $layout-desktop-min) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    @media screen and (max-width: $layout-desktop-max) {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.user-list-page__pagination-bottom {
    margin-top: 30px;
}
</style>

import ProjectPositionView from '@/models/ProjectPositionView';

/**
 * Class representing contingent statistics view model
 */
export default class ContingentStatisticsViewModel {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {ProjectPositionView} projectPosition
     * @returns {ContingentStatisticsViewModel}
     */
    static createFromProjectPositionViewModel(projectPosition) {
        const contingentStatistics = new ContingentStatisticsViewModel();

        if (projectPosition instanceof ProjectPositionView) {
            contingentStatistics.deliveredQty = projectPosition.deliveredQty;
            contingentStatistics.reservedQty = projectPosition.reservedQty;
            contingentStatistics.remainingQty = projectPosition.remainingQty;
        }

        return contingentStatistics;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._deliveredQty = null;
        this._reservedQty = null;
        this._remainingQty = null;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfoldIntoProjectPositionViewModel(projectPosition) {
        if (projectPosition instanceof ProjectPositionView) {
            projectPosition.deliveredQty = this.deliveredQty;
            projectPosition.reservedQty = this.reservedQty;
            // projectPosition.remainingQty is a getter computed from the above two fields
        }
    }

    /**
     * Get deliveredQty
     * @returns {number|null}
     */
    get deliveredQty() {
        return this._deliveredQty;
    }

    /**
     * Set deliveredQty
     * @param {number|null} value
     */
    set deliveredQty(value) {
        this._deliveredQty = value ?? null;
    }

    /**
     * Get reservedQty
     * @returns {number|null}
     */
    get reservedQty() {
        return this._reservedQty;
    }

    /**
     * Set reservedQty
     * @param {number|null} value
     */
    set reservedQty(value) {
        this._reservedQty = value ?? null;
    }

    /**
     * Get remainingQty
     * @returns {number|null}
     */
    get remainingQty() {
        return this._remainingQty;
    }

    /**
     * Set remainingQty
     * @param {number|null} value
     */
    set remainingQty(value) {
        this._remainingQty = value ?? null;
    }
}

import { defaultLocale, availableLocales, setLanguageAsync } from '@/i18n';
import LogService from '@schuettflix/util-log';

const Log = new LogService('store/i18n');

const state = {
    locale: defaultLocale,
    fallbackLocale: defaultLocale,
};

const getters = {
    locale: state => state.locale,
    fallbackLocale: state => state.fallbackLocale,
    availableLocales: () => availableLocales,
};

const mutations = {
    setLocale(state, locale) {
        state.locale = locale;
    },
};

const actions = {
    async changeLocale(context, { locale }) {
        try {
            context.commit('setLocale', await setLanguageAsync(locale));
        } catch (err) {
            Log.log(err);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

<template>
    <span>
        <Tile v-if="!costCenterIsSet" no-border class="pl-0 pr-0">
            <span class="font-copy-sm-strong my-4 block">
                {{ $t('pages.checkout.costCenters.sectionTitle') }}
            </span>

            <SfMessageBox v-if="costCenterMandatory" type="warning">
                <p>{{ $t('pages.checkout.costCenters.messageBox.text') }}</p>
            </SfMessageBox>

            <div class="cost-center__change-action">
                <BaseButton arrow-right place-right default @click="activeCostCenterFlyout">
                    <Words bold>
                        {{ $t('pages.checkout.costCenters.buttonText') }}
                    </Words>
                </BaseButton>
            </div>
        </Tile>
        <Tile v-else class="border-b-0 pl-0 pr-0">
            <span class="font-copy-sm-strong my-4 mt-4 block" @click="activeCostCenterFlyout">
                {{ $t('pages.checkout.costCenters.sectionTitle') }}
            </span>
            <span />
            <DropDownTrigger @click="activeCostCenterFlyout">
                <Words
                    block
                    weight-normal
                    align-left
                    data-test="active-cost-center"
                    class="cost-center__active-cost-center"
                >
                    {{ activeCostCenterText }}
                </Words>
            </DropDownTrigger>
        </Tile>
        <Flyout
            :screen-name="screenName"
            :active="costCenterFlyoutActive"
            :headline="$t('pages.checkout.costCenters.buttonText')"
            size="small"
            close-by-x
            data-test="costCenter-flyout"
            @closed="costCenterFlyoutActive = false"
        >
            <div class="container-deprecated cost-center__container">
                <div class="cost-center__field-group">
                    <RadioField
                        v-for="(costCenterItem, index) in costCenterRadioOptions"
                        :key="index"
                        v-model="selectedCostCenter"
                        :option="index"
                        :hidden="costCenterItem.name === '' && costCenterMandatory"
                        boxed
                        @input="toggleCostCenterForm(costCenterItem.name)"
                    >
                        <Words v-if="costCenterItem.name === ''" block>
                            {{ $t('pages.checkout.costCenters.costCenterSelections.noSelection') }}
                        </Words>
                        <Words v-else-if="costCenterItem.name === null" block>
                            {{ $t('pages.checkout.costCenters.costCenterSelections.enterNewCostCenter') }}
                        </Words>
                        <template v-else>
                            <Words block spaced-bottom-tiny>
                                {{ costCenterItem.name }}
                            </Words>
                            <Words block small>
                                <span>{{ $t('pages.checkout.costCenters.costCenterSelections.from') }}</span
                                >:
                                <Words tiny>
                                    <span class="cost-center__pip">
                                        {{ $t(`pages.checkout.costCenters.types.${costCenterItem.type}`) }}
                                    </span>
                                </Words>
                                <Words v-if="costCenterItem.source !== 'unknown'" tiny>
                                    <span class="cost-center__pip">
                                        {{ $t(`pages.checkout.costCenters.types.${costCenterItem.source}`) }}
                                    </span>
                                </Words>
                            </Words>
                        </template>
                    </RadioField>
                </div>

                <div v-if="showNewCostCenterField" class="cost-center__field-group">
                    <Words block bold tiny>
                        {{ $t('pages.checkout.costCenters.costCenterSelections.newCostCenterTitle') }}
                    </Words>
                    <TextField
                        id="costCenterName"
                        v-model="textFieldContent"
                        :label="$t('pages.checkout.costCenters.costCenterSelections.placeholder')"
                        type="text"
                        name="cost-center-name"
                    />
                </div>
            </div>

            <div class="cost-center__actions">
                <SlideUp :active="hasValidInput">
                    <ButtonGroup>
                        <BaseButton
                            :disabled="!hasValidInput"
                            primary
                            type="button"
                            data-test="costCenterButton"
                            @click="submit"
                        >
                            {{ $t('pages.checkout.costCenters.buttonText') }}
                        </BaseButton>
                    </ButtonGroup>
                </SlideUp>
            </div>
        </Flyout>
    </span>
</template>

<script>
import QuoteService from '@/services/QuoteService';

import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import { COST_CENTER_SOURCE, COST_CENTER_TYPE } from '@/constants/costCenterTypes';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Flyout from '@/components/Layout/Flyout';
import RadioField from '@/components/Form/RadioField';
import SlideUp from '@/components/Animation/SlideUp';
import TextField from '@/components/Form/TextField.v2';
import Tile from '@/components/Layout/Tile';
import Words from '@/components/Typography/Words';
import DropDownTrigger from '@/components/Button/DropDownTrigger';
import { SfMessageBox } from '@schuettflix/vue-components';
import { useOrderScreenName } from '../analytics/vue/useOrderScreenName';

export default {
    name: 'CostCenter',
    components: {
        BaseButton,
        ButtonGroup,
        Flyout,
        RadioField,
        SlideUp,
        TextField,
        Tile,
        Words,
        DropDownTrigger,

        SfMessageBox,
    },

    props: {
        costCenters: {
            type: Array,
            default: () => [],
        },
        activeCostCenter: {
            type: String,
            default: null,
        },
        basketType: {
            type: String,
            default: '',
        },
        costCenterMandatory: {
            type: Boolean,
            default: false,
        },
    },

    setup() {
        return {
            screenName: useOrderScreenName('ordersummary-costcenterselection'),
        };
    },

    data() {
        return {
            costCenterFlyoutActive: false,
            selectedCostCenter: 0,
            showNewCostCenterField: false,
            textFieldContent: null,
            costCenterList: [],
        };
    },

    computed: {
        filteredCostCenters() {
            return this.costCenters.filter(
                costCenter => costCenter.name !== undefined && costCenter.name.trim() !== ''
            );
        },

        activeCostCenterText() {
            if (this.costCenterIsSet && this.activeCostCenter === null) {
                return this.costCenterRadioOptions[this.selectedCostCenter].name;
            } else {
                return this.activeCostCenter;
            }
        },

        costCenterRadioOptions() {
            return [{ name: '' }, ...this.filteredCostCenters, { name: null }];
        },

        hasValidInput() {
            return this.selectedCostCenter !== this.manualCostCenterIndex || this.textFieldContent !== null;
        },

        costCenterIsSet() {
            return this.selectedCostCenter > 0;
        },

        manualCostCenterIndex() {
            return this.costCenterRadioOptions.map(el => el.name).lastIndexOf(null);
        },

        unloadingCostCenterExists() {
            return this.costCenters.some(costCenter => costCenter.source === COST_CENTER_SOURCE.UNLOADING_SITE);
        },

        loadingCostCenterExists() {
            return this.costCenters.some(costCenter => costCenter.source === COST_CENTER_SOURCE.LOADING_SITE);
        },

        projectCostCenterExists() {
            return this.costCenters.some(costCenter => costCenter.type === COST_CENTER_TYPE.PROJECT);
        },
        isProjectOrder() {
            return Object.values(BASKET_TYPE_PROJECT).includes(this.basketType);
        },
        hasCostCenter() {
            return this.projectCostCenterExists && (this.loadingCostCenterExists || this.unloadingCostCenterExists);
        },
    },

    watch: {
        costCenters: {
            handler() {
                this.$nextTick(() => this.determineInitialSelectedCostCenter());
            },
        },
    },

    mounted() {
        this.$nextTick(() => this.determineInitialSelectedCostCenter());

        if (this.costCenterIsSet && this.filteredCostCenters.length === 1) {
            this.submit();
        }
    },

    methods: {
        async determineInitialSelectedCostCenter() {
            if (this.activeCostCenter === null && this.filteredCostCenters.length === 1) {
                this.selectedCostCenter = 1;
            } else if (this.activeCostCenter === null && this.isProjectOrder && this.hasCostCenter) {
                this.selectedCostCenter = this.costCenterRadioOptions.findIndex(
                    option => option.type === COST_CENTER_TYPE.PROJECT
                );
            } else {
                // Standardise activeCostCenter by setting null to an empty string for ease or the stored cost center string.
                const costCenter = this.activeCostCenter ?? '';
                const index = this.costCenterRadioOptions.findIndex(option => option.name === costCenter);

                // If we don't find the cost center in the array then it must be the last null item.
                if (index === -1) {
                    // Find the index of the item in the array that has null as the name and set it to the selectedCostCenter.
                    this.selectedCostCenter = this.manualCostCenterIndex;
                    this.textFieldContent = this.activeCostCenter;
                    this.showNewCostCenterField = true;
                } else {
                    this.selectedCostCenter = index;
                }
            }
            this.$store.commit(
                'basket/setCostCenter',
                this.costCenterRadioOptions[this.selectedCostCenter].name || this.activeCostCenter
            );
            await QuoteService.updateCostCenter();
        },

        toggleCostCenterForm(name) {
            this.showNewCostCenterField = name === null;
        },

        activeCostCenterFlyout() {
            this.costCenterFlyoutActive = true;
        },

        async submit() {
            const submittableCostCenter =
                this.selectedCostCenter === this.manualCostCenterIndex
                    ? this.textFieldContent
                    : this.costCenterRadioOptions[this.selectedCostCenter].name;

            this.$store.commit('basket/setCostCenter', submittableCostCenter);

            try {
                await QuoteService.updateCostCenter();
                this.costCenterFlyoutActive = false;
                if (this.selectedCostCenter !== this.manualCostCenterIndex) {
                    this.textFieldContent = null;
                }
            } catch (err) {
                if (err.code) {
                    this.$store.commit('basket/setServerError', err);
                } else {
                    this.$logger().error(err);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.cost-center__container {
    height: 100%;
}

.cost-center__change-action {
    text-align: right;
    margin: 15px 0 30px;
}

.cost-center__pip {
    background-color: $color-black;
    color: $color-white;
    padding: 2px 8px;
    border-radius: 8px;
    margin-left: 10px;
}

.cost-center__actions {
    background-color: $color-white;
    position: sticky;
    bottom: 0;
    border-top: 1px solid $color-lightMediumGrey;
    z-index: 1;

    // iOS 11.0
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
        padding-bottom: constant(safe-area-inset-bottom);
    }

    // iOS 11.2
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
        padding-bottom: env(safe-area-inset-bottom);
    }
}

.cost-center__field-group {
    margin-top: 30px;
    margin-bottom: 30px;

    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 10px;
}

.cost-center__card--empty {
    box-shadow: none;
    padding: 10px 45px;
    background-color: $color-lightGrey;
    border: 1px dashed $color-littleDarkerThanMediumGrey;
}

.cost-center__active-cost-center {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
</style>

import { ensureJSTimestamp } from '@/services/utils/date';

/**
 * Get timestamp based on status
 * @param {Object} creditNote
 * @return {Number|null} timestamp
 */

export function statusTimestamp(creditNote) {
    if (!creditNote) return null;

    switch (creditNote.status) {
        case 'booked':
            return ensureJSTimestamp(creditNote.bookedTimestamp);
        case 'paid':
            return ensureJSTimestamp(creditNote.paidTimestamp);
        case 'cancelled':
            return ensureJSTimestamp(creditNote.cancelledTimestamp);
        default:
            return null;
    }
}

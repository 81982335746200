<template>
    <LayoutPage is-flyout :screen-name="screenName" class="pickup-checkout-quantity-selection">
        <template #flyoutHeader>
            <FlyoutHeader />
            <Words block small spaced-top spaced-bottom class="container-deprecated">
                {{ $t('pages.checkout.quantitySelection.pickup.contingentInfo') }}
            </Words>

            <div v-if="isProductLoaded" class="checkout-quantity-selection__inputs" @click="switchQtyType">
                <div
                    :class="{ 'checkout-quantity-selection__input--active': isTypeWeight }"
                    class="checkout-quantity-selection__input checkout-quantity-selection__input--tons"
                >
                    <div
                        :style="{ 'font-size': `${numberFontSizeFactor(quantityWeight, 'weight')}em` }"
                        class="checkout-quantity-selection__input-value"
                        data-test="checkout-quantity-input-tons"
                    >
                        <span v-if="isTypeWeight">{{ quantityWeight | numpadNumber(1) }}</span>
                        <span v-else>{{ $n(parseFloat(quantityWeight), 'weight') }}</span>
                    </div>
                    <div class="checkout-quantity-selection__input-suffix">t</div>
                </div>
                <div class="checkout-quantity-selection__input-separator">=</div>
                <div
                    :class="{ 'checkout-quantity-selection__input--active': isTypeVolume }"
                    class="checkout-quantity-selection__input checkout-quantity-selection__input--cubic"
                >
                    <div
                        :style="{ 'font-size': `${numberFontSizeFactor(quantityVolume, 'volume')}em` }"
                        class="checkout-quantity-selection__input-value"
                    >
                        <span v-if="isTypeVolume">{{ quantityVolume | numpadNumber(1) }}</span>
                        <span v-else>{{ $n(parseFloat(quantityVolume), 'volume') }}</span>
                    </div>
                    <div class="checkout-quantity-selection__input-suffix">m<sup>3</sup></div>
                </div>
            </div>
        </template>

        <div class="checkout-quantity-selection__input-errors">
            <ErrorMessage v-if="amountError" :message="amountError" />
        </div>
        <div v-if="isProductLoaded" class="checkout-quantity-selection__numpad-wrapper">
            <NumpadInput
                :value="quantity"
                :precision="1"
                :disabled-keys="quantityWeight * 1000 >= product.maximumQty"
                class="checkout-quantity-selection__numpad"
                @input="handleQuantityUpdate"
            />
        </div>
        <LoadingSpinner v-else block dark />

        <template #sticky>
            <SlideUp :active="showSubmitButton">
                <ButtonGroup>
                    <BaseButton
                        :disabled="hasInvalidValues || isLoading"
                        primary
                        type="button"
                        class="checkout-quantity-selection__process-button"
                        data-test="quantity-checkout-button"
                        @click="handleSubmission"
                    >
                        {{ $t('pages.checkout.quantitySelection.pickup.submitButton') }}
                        <template #right>
                            <ArrowRightIcon class="icon--inline" />
                        </template>
                    </BaseButton>
                </ButtonGroup>
            </SlideUp>
        </template>
    </LayoutPage>
</template>

<script>
import _debounce from 'lodash/debounce';
import { convert_kg_to_cbm, convert_cbm_to_kg } from '@/services/utils/units';
import { mapFilters } from '@/plugins/filters';
import { mapState } from 'vuex';
import { revertLocalizedValue } from '@/services/utils/localization';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ErrorMessage from '@/components/Form/ErrorMessage';
import LayoutPage from '@/components/Layout/Page.v2';
import FlyoutHeader from './components/Header';
import LoadingSpinner from '@/components/LoadingSpinner';
import NumpadInput from '@/components/Form/NumpadInput';
import SlideUp from '@/components/Animation/SlideUp';
import Words from '@/components/Typography/Words';

import ArrowRightIcon from '@/assets/icons/micro/arrow.svg';
import { useQuery } from '@tanstack/vue-query';
import { getOrderRestrictions, getProductByAttributes } from '@/pages/Checkout/queries';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';

const QTY_TYPE_WEIGHT = 'weight';
const QTY_TYPE_VOLUME = 'volume';
const ERROR_MESSAGE_TIMEOUT = 300;

export default {
    name: 'PickupQuantitySelectionPage',
    components: {
        BaseButton,
        ButtonGroup,
        ErrorMessage,
        LayoutPage,
        FlyoutHeader,
        LoadingSpinner,
        NumpadInput,
        SlideUp,
        Words,

        ArrowRightIcon,
    },
    setup() {
        return {
            screenName: useOrderScreenName('quantityselection'),
        };
    },
    data() {
        return {
            quantityType: QTY_TYPE_WEIGHT,
            quantityWeight: '0',
            quantityVolume: '0',
            amountError: null,
            isLoading: false,
            maxAvailableAmount: Infinity,
        };
    },
    computed: {
        ...mapState('basket', ['constructionSite', 'product', 'type']),
        isProductLoaded() {
            return !!(
                this.product?.density &&
                this.product?.maximumQty &&
                this.product?.minimumQty &&
                this.maxAvailableAmount !== Infinity
            );
        },
        isMaximumQuantityReached() {
            return this.quantity * 1000 > this.maxAvailableAmount;
        },
        quantity() {
            return this.isTypeWeight ? this.quantityWeight : this.quantityVolume;
        },
        isTypeWeight() {
            return this.quantityType === QTY_TYPE_WEIGHT;
        },
        isTypeVolume() {
            return this.quantityType === QTY_TYPE_VOLUME;
        },
        hasInvalidValues() {
            const { quantityWeight, product } = this;

            return (
                parseFloat(quantityWeight) * 1000 > product.maximumQty ||
                parseFloat(quantityWeight) * 1000 < product.minimumQty ||
                parseFloat(quantityWeight) === 0
            );
        },
        showSubmitButton() {
            return this.quantityWeight > 0 && !this.amountError;
        },
    },
    created() {
        useQuery({
            ...getProductByAttributes(
                this.type,
                this.constructionSite.constructionProjectLocationId,
                this.product.masterProductId,
                this.product.attributeRange,
                this.product.attributeIds
            ),
            enabled: !this.isProductLoaded,
            placeholderData: null,
            onSuccess: product => {
                this.maxAvailableAmount = product.productAvailability?.maxAvailableAmount || 10000000;
                this.$store.commit('basket/setProduct', product.productConfiguration);
            },
        });
        useQuery({
            ...getOrderRestrictions(this.type),
            enabled: !this.isProductLoaded,
            placeholderData: null,
            onSuccess: restrictions => {
                this.$store.commit('basket/setProductRestrictions', restrictions);
            },
        });

        this.loadSavedAmountData();
    },
    methods: {
        ...mapFilters(['tons']),
        revertLocalizedValue,
        loadSavedAmountData() {
            const savedQty = this.$store.state.basket.qty;
            if (savedQty) {
                this.quantityWeight = (savedQty / 1000).toString();
                this.quantityVolume = convert_kg_to_cbm(this.quantityWeight * 1000, this.product.density, 1).toString();
                this.validateAmount(true);
            }
        },

        getValidateMessage(minError = false, maxError = false, maxAvailableReached = false) {
            let message = null;

            if (minError) {
                message = this.$t('pages.checkout.quantitySelection.minimumQtyExceededError', {
                    qty: this.tons(this.product.minimumQty),
                });
            } else if (maxError) {
                message = this.$t('pages.checkout.quantitySelection.maximumQtyExceededError', {
                    qty: this.tons(this.product.maximumQty),
                });
            } else if (maxAvailableReached) {
                message = this.$t('pages.checkout.quantitySelection.noSellersAvailable', {
                    qty: this.tons(this.maxAvailableAmount),
                });
            }

            return message;
        },

        validateAmount(displayError = false) {
            const weight = parseFloat(this.quantityWeight);
            const minError = weight < this.product.minimumQty / 1000;
            const maxError = weight > this.product.maximumQty / 1000;

            const message = this.getValidateMessage(minError, maxError, this.isMaximumQuantityReached);

            if (weight === 0) return;

            if (!message) {
                this.amountError = null;
            }

            if (displayError) {
                this.showAmountError(message);
            }
        },
        showAmountError: _debounce(function (error) {
            this.amountError = error;
        }, ERROR_MESSAGE_TIMEOUT),

        switchQtyType() {
            this.quantityType = this.quantityType === QTY_TYPE_WEIGHT ? QTY_TYPE_VOLUME : QTY_TYPE_WEIGHT;
        },
        handleQuantityUpdate(value) {
            this.validateAmount(false);

            if (this.quantityType === QTY_TYPE_WEIGHT) {
                this.quantityWeight = value;
                this.quantityVolume = convert_kg_to_cbm(this.quantityWeight * 1000, this.product.density, 1).toString();
            } else {
                this.quantityVolume = value;
                this.quantityWeight = (
                    Math.round(convert_cbm_to_kg(this.quantityVolume, this.product.density, 1) / 100) / 10
                ).toString();
            }

            this.validateAmount(true);
        },
        numberFontSizeFactor(value, type) {
            const str = this.$n(value, type);
            let factor = 1;

            if (str.length > 8) {
                factor += str.length / 20;
            } else if (str.length > 5) {
                factor += str.length / 25;
            } else if (str.length > 3) {
                factor += str.length / 30;
            }

            return parseFloat((1.6 / factor).toFixed(2));
        },
        handleSubmission() {
            if (this.hasInvalidValues) {
                return;
            }

            this.$store.commit('basket/setQty', this.quantityWeight * 1000);
            this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(() => {});
        },
    },
};
</script>

<style lang="scss">
.checkout-quantity-selection__numpad-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding-top: 3em;
}
</style>

<template>
    <component :is="component" />
</template>

<script>
import DeliveryIcon from '@/assets/icons/regular/arrow_delivery.svg';
import DumpingIcon from '@/assets/icons/regular/arrow_dumping.svg';
import PickupIcon from '@/assets/icons/regular/arrow_pickup.svg';

export default {
    name: 'TransportTypeIcon',

    components: {
        DeliveryIcon,
        DumpingIcon,
        PickupIcon,
    },

    props: {
        type: {
            type: String,
            required: true,
        },
    },

    computed: {
        component() {
            switch (this.type) {
                case 'delivery':
                    return DeliveryIcon;
                case 'pickup':
                    return PickupIcon;
                default:
                    return DumpingIcon;
            }
        },
    },
};
</script>

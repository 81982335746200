import { Avatar } from '@schuettflix/react-components';
export function Person({ profileImageUrl, firstName, lastName, role }) {
    return (<div className="flex items-center gap-4">
            <Avatar className="h-12 w-12" firstName={firstName} lastName={lastName} imageUrl={profileImageUrl}/>
            <div className="flex flex-col gap-2">
                <p className="font-copy-sm text-subdued">{role}</p>
                <p className="font-copy-md-strong text">{`${firstName} ${lastName}`}</p>
            </div>
        </div>);
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"tag",class:{
        'tag--red': _vm.red,
        'tag--white': _vm.white,
        'tag--yellow': _vm.yellow,
        'tag--green': _vm.green,
        'tag--black': _vm.black,
        'tag--small': _vm.small,
        'tag--tiny': _vm.tiny,
        'tag--bold': _vm.bold,
        'tag--unbreakable': _vm.unbreakable,
        'tag--outlined': _vm.outlined,
    }},[_vm._t("default")],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
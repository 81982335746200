var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Card',{key:"waste-table",class:['waste-table'],attrs:{"shadowless":true,"spaceless":"","no-padding":""}},[_c('div',{staticClass:"waste-table__head"},[_c('Words',{staticClass:"pl-4",attrs:{"tiny":"","bold":""}},[_vm._v(_vm._s(_vm.$t('pages.products.productsList.tableHeadings.wasteCode')))]),_c('Words',{staticClass:"pl-[12px]",attrs:{"tiny":"","bold":""}},[_vm._v(_vm._s(_vm.$t('pages.products.productsList.tableHeadings.name')))]),_c('Words',{staticClass:"pr-4",attrs:{"tiny":"","bold":""}},[_vm._v(_vm._s(_vm.$t('pages.products.productsList.tableHeadings.availability')))])],1)]),(_vm.products && _vm.products.length > 0)?_c('div',{staticClass:"waste-groups-iteration"},_vm._l((_vm.categories),function(category){return _c('Accordion',{key:category.id,ref:"accordion",refInFor:true,class:['waste-groups', { 'waste-groups--empty': !category.hasContent }],attrs:{"has-content":category.hasContent},scopedSlots:_vm._u([{key:"preheader",fn:function(){return [_c('Words',{staticClass:"with-separator",class:{
                        'with-separator--hidden': !category.wasteCode,
                    },attrs:{"block":"","bold":"","white":""}},[_vm._v(" "+_vm._s(category.wasteCode)+" ")])]},proxy:true},{key:"header",fn:function(){return [_c('Words',{attrs:{"bold":"","white":""}},[_vm._v(" "+_vm._s(_vm.revertLocalizedValue(category.name))+" ")]),(!category.isProductsLoading)?_c('Words',{attrs:{"bold":"","red":""}},[_vm._v(" ("+_vm._s(category.counter)+") ")]):_vm._e()]},proxy:true}],null,true)},_vm._l((_vm.products[category.id]),function(product,i){return _c('Accordion',{key:`waste-item-${i}`,staticClass:"accordion-waste-subcategory",attrs:{"open-on-init":false,"type":"secondary"},on:{"accordion-click":params =>
                        _vm.fetchWasteVariants(
                            !product.isVariantsDisposalLoading,
                            category.id,
                            product.id,
                            params.isOpen
                        ),"accordion-update":params =>
                        _vm.fetchWasteVariants(
                            !product.isVariantsDisposalLoading,
                            category.id,
                            product.id,
                            params.isOpen
                        )},scopedSlots:_vm._u([{key:"preheader",fn:function(){return [_c('WasteCode',{attrs:{"block":"","bold":"","code":product.wasteCode,"is-dangerous":product.isDangerous}})]},proxy:true},{key:"header",fn:function(){return [_c('div',{staticClass:"header-wrap"},[_c('Words',[_vm._v(_vm._s(_vm.revertLocalizedValue(product.productName)))]),(_vm.priceInformationExists(_vm.returnPriceInformation(product)))?_c('Words',{staticClass:"price-range-header",attrs:{"bold":"","unbreakable":""}},[_vm._v(" "+_vm._s(_vm.returnPriceInformation(product))+" ")]):_c('Words',{staticClass:"price-range-header",attrs:{"unbreakable":""}},[_vm._v(" "+_vm._s(_vm.$t('pages.products.productsList.supplier.noPrice'))+" ")])],1)]},proxy:true},{key:"postheader",fn:function(){return [_c('div',{staticClass:"dot-indicator",class:{
                            'dot-indicator--available': product.factoryData.isAvailable,
                            'dot-indicator--not-available': !product.factoryData.isAvailable,
                        }})]},proxy:true}],null,true)},[_c('div',{staticClass:"accordion-subcategory-body"},[_c('SupplierWasteProductDetails',{attrs:{"category-id":category.id,"factory-id":_vm.factoryId,"product":product}})],1)])}),1)}),1):_c('Hint',{attrs:{"center":""}},[_vm._v(" "+_vm._s(_vm.$t('pages.products.productsList.noResults'))+" ")])],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
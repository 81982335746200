<template>
    <span class="terms-hint font-copy-sm">
        <i18n :path="translationPath" tag="span">
            <a
                :href="linkLegalTermsAndConditionsCustomer"
                target="_blank"
                class="font-copy-sm inline cursor-pointer underline"
                place="termsLink"
                >{{ $t('pages.checkout.summary.termsHint.termsLink') }}</a
            >
            <a
                :href="linkLegalDataProtectionPolicyGlobal"
                target="_blank"
                class="font-copy-sm inline cursor-pointer underline"
                place="privacyLink"
            >
                {{ $t('pages.checkout.summary.termsHint.privacyLink.text') }}
            </a>
            <a
                v-if="showExtendedLegalTermLink"
                :href="linkLegalAoesp"
                target="_blank"
                class="font-copy-sm inline cursor-pointer underline"
                place="aoesp"
            >
                {{ $t('pages.checkout.summary.termsHint.aoesp') }}
            </a>
            <Words bold place="clientName">{{ clientName }}</Words>
        </i18n>
    </span>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import Words from '@/components/Typography/Words';

export default defineComponent({
    name: 'TermsHint',
    components: {
        Words,
    },

    props: {
        translationPath: {
            type: String,
            required: true,
        },
        clientName: {
            type: String,
            default: '',
        },
        showExtendedLegalTermLink: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapGetters('platform', [
            'linkLegalDataProtectionPolicyGlobal',
            'linkLegalTermsAndConditionsCustomer',
            'linkLegalAoesp',
        ]),
    },
});
</script>

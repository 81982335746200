const calculateTakeRatePercentage = (revenue, cost) => {
    // if both are negative, we need to swap them as revenue is actually a cost and vice versa
    if (revenue < 0 && cost < 0) {
        const originalRevenue = revenue;
        revenue = Math.abs(cost);
        cost = Math.abs(originalRevenue);
    }
    // if revenue is negative, the revenue is actually a cost
    if (revenue < 0) {
        cost = cost + Math.abs(revenue);
        revenue = 0;
    }
    // if cost is negative, the cost is actually a revenue
    if (cost < 0) {
        revenue = revenue + Math.abs(cost);
        cost = 0;
    }
    if (cost === 0 && revenue === 0) {
        return 0;
    }
    // without any cost, the take rate is 100%
    if (cost === 0) {
        return 1;
    }
    // without any revenue, the take rate is -100%
    if (revenue === 0) {
        return -1;
    }
    return (revenue - cost) / revenue;
};
export function calculateSummaryVales(totals) {
    return totals
        .filter(it => it.totalType === 'ORDER')
        .reduce((stats, it) => {
        it.transactionalTotals.forEach(({ transactionalRole, totalPriceAmount }) => {
            if (transactionalRole === 'DEBTOR') {
                stats.revenue += totalPriceAmount;
            }
            if (transactionalRole === 'CREDITOR') {
                stats.costs += totalPriceAmount;
            }
            stats.profit = stats.revenue - stats.costs;
            stats.takeRate = calculateTakeRatePercentage(stats.revenue, stats.costs);
        });
        return stats;
    }, { revenue: 0, costs: 0, profit: 0, takeRate: 0 });
}

<template>
    <div
        :class="{
            'notification-item--has-action': hasAction,
            'notification-item--unread': !notification.read,
        }"
        class="notification-item"
        @click="handleClick"
    >
        <Words tiny muted block class="notification-item__time">
            {{ distanceInWordsToNow(notification.created * 1000) }}
        </Words>
        <div class="notification-item__text">
            <Words bold block small class="notification-item__title">
                {{ notification.title }}
            </Words>
            <Words block small class="notification-item__message" v-html="nl2br(notification.body)" />
        </div>
        <Button v-if="hasAction" align-left arrow-right weight-normal class="notification-item__action">
            {{ $t(`pages.notifications.buttonLabels.showDetails`) }}
        </Button>
    </div>
</template>

<script>
import { distanceInWordsToNow } from '@/services/utils/date';
import { nl2br } from '@/services/utils';

import Words from '@/components/Typography/Words';
import Button from '@/components/Button/Button';

export default {
    name: 'NotificationItem',
    components: {
        Words,
        Button,
    },
    props: {
        notification: {
            type: Object,
            required: true,
        },
        hasAction: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        nl2br,
        distanceInWordsToNow,
        handleClick($event) {
            if (this.hasAction) {
                this.$emit('click', $event);
            }
        },
    },
};
</script>

<style lang="scss">
.notification-item {
    padding: 10px 0 0;
    list-style: none;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    position: relative;
}

.notification-item--has-action {
    cursor: pointer;
}

.notification-item--unread {
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 10px;
        left: 10px;
        background: $color-red;
        border-radius: 50%;
        width: 0.75em;
        height: 0.75em;
    }
}

.notification-item__time {
    padding: 0 10px;
    text-align: right;
    margin-bottom: 10px;
    width: 100%;
}

.notification-item__text {
    flex-grow: 1;
    padding: 0 10px 10px;
    width: 100%;

    .notification-item--has-action & {
        border-bottom: $border-solid-2px;
    }
}

.notification-item__title {
    margin-bottom: 1em;
}

.notification-item__message {
    width: 80%;
    line-height: 1.45;
}

.notification-item__action {
    padding: 13px 10px;
    font-size: $font-size-small;
}
</style>

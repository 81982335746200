<template>
    <PickupStartedConfirmationScreen v-if="transport" :transport="transport" :order-id="orderId" />
    <FlyoutPage v-else class="order-pickup-start">
        <HeaderBar slot="header">
            <HeaderBarItem slot="left" button @click="$root.routeBack()">
                <ArrowIcon width="32" height="18" />
            </HeaderBarItem>

            <div slot="headline">
                {{ $t('pages.order.orderView.pickupStart.title') }}
            </div>
        </HeaderBar>

        <LoadingSpinner v-if="order === null" block dark />

        <transition name="fade">
            <div v-if="order !== null">
                <Tile>
                    <StartTransportIcon width="100%" height="140px" class="order-pickup-start__stage-image" />

                    <Words bold block spaced-bottom>
                        {{ $t('pages.order.orderView.pickupStart.headline') }}
                    </Words>
                    <Words block>
                        {{ $t('pages.order.orderView.pickupStart.message') }}
                    </Words>
                </Tile>

                <Tile no-border>
                    <Words bold block class="transport-planning__headline">
                        {{ $t('pages.order.orderView.pickupStart.selectVehicleLabel') }}
                    </Words>

                    <MultiselectBox
                        v-model="vehicleId"
                        :endpoint="vehicleEndpoint"
                        :filter="vehicleFilter"
                        :option-value-renderer="option => option.id"
                        :label="$t('pages.transport.transportPlanning.vehicle')"
                        :search-label-renderer="option => option.licensePlate + option.vehicleClass.name"
                        :search-field-label="$t('pages.transport.transportPlanning.vehicleSearchLabel')"
                        searchable
                        :disabled="noVehiclesAvailable"
                        :disable-option-handler="option => option.inUse"
                        option-class="order-pickup-start__option"
                        disable-reset
                        custom-save-button
                        data-test="select-pickup-vehicle-button"
                        @input="handleVehicleInput"
                    >
                        <template #button="buttonScope">
                            <DefaultInner dark>
                                <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1">
                                    <Words bold block small>
                                        {{ buttonScope.label }}
                                    </Words>
                                    <Words muted block>
                                        {{ buttonScope.selectedOptions[0].licensePlate }} ({{
                                            buttonScope.selectedOptions[0].vehicleClass.name
                                        }})
                                    </Words>
                                </span>
                                <span v-else muted middle>
                                    {{
                                        $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label })
                                    }}
                                </span>
                            </DefaultInner>
                        </template>

                        <template #option="optionScope">
                            <div class="order-pickup-start__option--inner transport-planning__multiselect-grid-wrapper">
                                <div class="transport-planning__multiselect-grid flex items-center gap-2">
                                    <div class="transport-planning__multiselect-cell w-[80px]">
                                        <Thumbnail
                                            v-if="optionScope.option.image && optionScope.option.image.url"
                                            :modal="false"
                                            :src="optionScope.option.image && optionScope.option.image.url"
                                            class="transport-planning__multiselect-thumbnail"
                                        />
                                        <TruckPlaceholderThumbnail v-else />
                                    </div>
                                    <div class="transport-planning__multiselect-cell w-[80px]">
                                        <VehicleClassIconSet
                                            :icon="optionScope.option.vehicleClass.icon"
                                            class="transport-planning__multiselect-truck-icon h-[30px] w-full"
                                        />
                                    </div>
                                    <div class="transport-planning__multiselect-cell">
                                        <Words :red="optionScope.isActive">
                                            {{ optionScope.option.vehicleClass.name }}
                                        </Words>
                                        <Words :red="optionScope.isActive" :muted="!optionScope.isActive">
                                            / {{ getEmptyWeight(optionScope.option) | tons }}
                                        </Words>
                                        <Words block :red="optionScope.isActive">
                                            {{ optionScope.option.licensePlate }}
                                        </Words>
                                    </div>
                                </div>
                                <TickIcon v-if="optionScope.isActive" class="icon--inline icon--red" />
                            </div>
                            <div v-if="optionScope.option.inUse" class="order-pickup-start__option--hint">
                                <Words tiny unbreakable>
                                    {{
                                        $t('pages.order.orderView.pickupStart.vehicleInUse', {
                                            number: optionScope.option.currentTransportNumber,
                                        })
                                    }}
                                </Words>
                            </div>
                        </template>

                        <div slot="additionalInformation" slot-scope="scope">
                            <Hint v-if="scope.remoteOptions && !scope.remoteOptions.length" center>
                                <Words>
                                    {{ $t('pages.order.orderView.pickupStart.noVehiclesAvailable') }}
                                </Words>
                            </Hint>

                            <div v-if="$can('createVehicle')" class="order-pickup-start__add-vehicle-button">
                                <ButtonGroup floating>
                                    <BaseButton primary light block @click="() => openAddNewVehicleFlyout(scope)">
                                        <PlusIcon slot="left" height="22" class="icon--inline" />
                                        <Words bold>
                                            {{ $t('pages.order.orderView.pickupStart.addVehicle') }}
                                            <ArrowRightIcon height="10" width="10" class="icon--inline" />
                                        </Words>
                                    </BaseButton>
                                </ButtonGroup>
                            </div>
                            <Hint v-else center>
                                <Words>
                                    {{ $t('pages.order.orderView.pickupStart.addVehicleNotAvailable') }}
                                </Words>
                            </Hint>
                        </div>
                    </MultiselectBox>
                    <ErrorMessage
                        v-if="getError('vehicleId')"
                        :message="$t('pages.order.orderView.pickupStart.vehicleInUseError')"
                    />
                </Tile>
                <Hint v-if="noVehiclesAvailable" center>
                    <Words>
                        {{ $t('pages.order.orderView.pickupStart.noVehiclesAvailable') }}
                    </Words>

                    <div v-if="$can('createVehicle')" class="order-pickup-start__add-vehicle-button">
                        <ButtonGroup floating>
                            <BaseButton primary light block @click="() => openAddNewVehicleFlyout()">
                                <PlusIcon slot="left" height="22" class="icon--inline" />
                                <Words bold>
                                    {{ $t('pages.order.orderView.pickupStart.addVehicle') }}
                                    <ArrowRightIcon height="10" width="10" class="icon--inline" />
                                </Words>
                            </BaseButton>
                        </ButtonGroup>
                    </div>
                </Hint>
            </div>
        </transition>

        <ButtonGroup v-if="order !== null && !selectedVehicle" slot="footer">
            <BaseButton :disabled="isPending()" place-left primary light arrow-left @click="$root.routeBack()">
                {{ $t('pages.order.orderView.pickupStart.actions.back') }}
            </BaseButton>
            <BaseButton :disabled="!vehicleId || isPending()" :is-loading="isPending('save')" primary @click="save()">
                {{ $t('pages.order.orderView.pickupStart.actions.submit') }}
            </BaseButton>
        </ButtonGroup>

        <ButtonGroup v-if="order !== null && selectedVehicle" slot="footer" class="justify-between">
            <ToggleSwitchField v-model="licensePlateConfirmed" name="confirmlicensePlate" class="pl-4 lg:pl-0">
                <Words bold>
                    {{ selectedVehicle.licensePlate }}
                </Words>
            </ToggleSwitchField>

            <BaseButton
                :disabled="!licensePlateConfirmed || !vehicleId || isPending()"
                :is-loading="isPending('save')"
                primary
                data-test="confirm-pickup-transport-button"
                @click="save()"
            >
                {{ $t('pages.order.orderView.pickupStart.actions.submit') }}
            </BaseButton>
        </ButtonGroup>

        <Flyout route="vehicle-new" size="full" close-by-x no-header appear-from="bottom" />
    </FlyoutPage>
</template>

<script>
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { getFormattedDuration } from '@/services/utils/date';
import { mapGetters } from 'vuex';
import { revertLocalizedValue } from '@/services/utils/localization';
import { transportProgress } from '@/services/utils/transport';
import ClientPickupApi from '@/services/Api/Client/Pickup';
import OrderApi from '@/services/Api/Order';
import PubSubEvent from '@/services/PubSub/PubSubEvent';
import Toaster from '@/services/Toaster';
import validate from '@/services/validation/mixin';
import VehicleApi from '@/services/Api/Vehicle';
import PlusIcon from '@/assets/icons/regular/plus.svg';
import ArrowRightIcon from '@/assets/icons/regular/arrow-stripeless.svg';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import { EVENT_ORDER_UPDATED, EVENT_VEHICLE_CREATED } from '@/constants/events';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import DefaultInner from '@/components/Form/MultiselectBox/DefaultInner';
import ErrorMessage from '@/components/Form/ErrorMessage';
import Flyout from '@/components/Layout/Flyout';
import FlyoutPage from '@/components/Layout/FlyoutPage';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Hint from '@/components/Typography/Hint';
import LoadingSpinner from '@/components/LoadingSpinner';
import MultiselectBox from '@/components/Form/MultiselectBox';
import PickupStartedConfirmationScreen from '@/pages/Order/components/PickupStartedConfirmationScreen';
import Thumbnail from '@/components/Thumbnail';
import Tile from '@/components/Layout/Tile';
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import TruckPlaceholderThumbnail from '@/pages/Vehicle/components/TruckPlaceholderThumbnail';
import VehicleClassIconSet from '@/components/IconSet/VehicleClassIconSet';
import Words from '@/components/Typography/Words';

import ArrowIcon from '@/assets/icons/regular/arrow.svg';
import StartTransportIcon from '@/assets/icons/confirmation/start-transport-gate.svg';
import TickIcon from '@/assets/icons/micro/tick.svg';

import { navigationFailure } from '@/services/utils/router';

export default {
    name: 'OrderStartPickupPage',
    components: {
        BaseButton,
        ButtonGroup,
        DefaultInner,
        ErrorMessage,
        Flyout,
        FlyoutPage,
        HeaderBar,
        HeaderBarItem,
        Hint,
        LoadingSpinner,
        MultiselectBox,
        PickupStartedConfirmationScreen,
        Thumbnail,
        Tile,
        ToggleSwitchField,
        TruckPlaceholderThumbnail,
        VehicleClassIconSet,
        Words,

        ArrowIcon,
        ArrowRightIcon,
        PlusIcon,
        StartTransportIcon,
        TickIcon,
    },
    mixins: [validate, statefulMixin, eventHubMixin],
    props: {
        orderId: {
            type: [Number, String],
            required: true,
        },
    },
    data() {
        return {
            order: null,
            vehicleEndpoint: VehicleApi,
            vehicleFilter: {
                active: true,
                sortBy: 'permissibleTotalWeight',
            },
            vehicleId: null,
            selectedVehicle: null,
            transport: null,
            noVehiclesAvailable: false,

            licensePlateConfirmed: false,
        };
    },
    computed: {
        ...mapGetters('user', ['lastVehicleId']),
    },
    async mounted() {
        await this.prepareData();

        this.subscribe(EVENT_VEHICLE_CREATED, event => {
            this.selectNewVehicle(event.subject);
        });
    },
    methods: {
        getFormattedDuration,
        revertLocalizedValue,
        transportProgress,

        async prepareData() {
            try {
                this.$store.dispatch('user/updateUserInfo');
                this.order = await OrderApi.getOneById(this.orderId);
                await this.checkVehicleAvailability();
                await this.preselectVehicle();
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(err);
            }
        },

        getEmptyWeight(option) {
            return option.permissibleTotalWeight - option.emptyWeight;
        },

        async save() {
            if (!this.$can('createPickupTransport', this.order.lineItemGroups[0])) return;

            await this.stateful('save', async () => {
                try {
                    this.transport = await ClientPickupApi.startPickup(this.order.lineItemGroups[0].id, this.vehicleId);
                } catch (err) {
                    this.parseServerValidation(_get(err, 'response.data.errors', {}), {
                        expireDate: 'expireDate',
                        status: 'status',
                        vehicleId: 'vehicleId',
                    });

                    this.$logger().error(err);

                    if (this.hasErrors('vehicleId')) {
                        this.vehicleId = null;
                    }
                    if (this.hasErrors('vehicle')) {
                        Toaster.error(this.getError('vehicle'));
                    } else if (this.hasErrors('expireDate')) {
                        Toaster.error(this.getError('expireDate'));
                        this.$eventHub.$emit(EVENT_ORDER_UPDATED, new PubSubEvent(this.order));
                        this.$router.back();
                    } else if (this.hasErrors('status')) {
                        Toaster.error(this.getError('status'));
                        this.$eventHub.$emit(EVENT_ORDER_UPDATED, new PubSubEvent(this.order));
                        this.$router.back();
                    } else {
                        Toaster.error(err.message);
                    }
                }
            });
        },

        /**
         * Preselect the last used vehicle of the user – if it is not already in use
         */
        async preselectVehicle() {
            if (this.lastVehicleId) {
                try {
                    const vehicle = await this.vehicleEndpoint
                        .filter({
                            ...this.vehicleFilter,
                            ids: [this.lastVehicleId],
                        })
                        .then(result => {
                            return result?.items[0];
                        });

                    if (vehicle && !vehicle.inUse) {
                        this.vehicleId = this.lastVehicleId;
                    }
                } catch (err) {
                    this.$logger().error(err);
                    Toaster.error(err);
                }
            }
        },

        /**
         * Handle vehicle input/change
         * @param {number} vehicleId
         * @param {object} vehicleData
         */
        handleVehicleInput(vehicleId, vehicleData) {
            this.licensePlateConfirmed = false;
            this.selectedVehicle = _cloneDeep(vehicleData);
        },

        openAddNewVehicleFlyout(multiselectScope) {
            // close the vehicle selection multiselect flyout
            multiselectScope && multiselectScope.closeFlyout();

            // open vehicle creation flyout
            this.$router
                .push({
                    name: this.$root.findRouteName('vehicle-new'),
                    query: this.$route.query,
                })
                .catch(navigationFailure);
        },

        /**
         * Try to select a new created vehicle
         *
         * @param {object} vehicleData
         */
        async selectNewVehicle(vehicleData) {
            const { id } = vehicleData;

            this.vehicleId = null;

            if (id) {
                try {
                    const vehicle = await this.vehicleEndpoint
                        .filter({
                            ...this.vehicleFilter,
                            ids: [id],
                        })
                        .then(result => {
                            return result?.items[0];
                        });

                    if (vehicle) {
                        this.vehicleId = vehicle.id;
                        this.noVehiclesAvailable = false;
                    }
                } catch (err) {
                    this.$logger().error(err);
                    Toaster.error(err);
                }
            }
        },

        /**
         * Check whether vehicles are available for the defined filter and set the noVehiclesAvailable flag accordingly
         */
        async checkVehicleAvailability() {
            try {
                const vehicles = await this.vehicleEndpoint.filter({
                    ...this.vehicleFilter,
                    countOnly: true,
                });

                this.noVehiclesAvailable = vehicles.count === 0;
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(err);
            }
        },
    },
};
</script>

<style lang="scss">
.order-pickup-start__stage-image {
    margin: 20px auto;
}

.order-pickup-start__option {
    position: relative;
}

.order-pickup-start__option--hint {
    position: absolute;
    top: calc(50% - 10px);
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 10px 7px;
    background-color: $color-mediumGrey;
    max-width: 90%;
}

.order-pickup-start__option:disabled {
    opacity: 1;

    .order-pickup-start__option--inner {
        opacity: 0.5;
    }
}

.order-pickup-start__add-vehicle-button {
    background-color: $color-lightGrey;
    padding: 2px 0px;
}
</style>

<template>
    <div>
        <WasteDropdown
            :error="error"
            :all-choices-number="nrOfOtherChoices"
            :big-placeholder-active="isBigPlaceholderActive"
            :placeholder="placeholder"
            :first-option="firstOption"
            @toggle="$emit('toggle')"
        />
        <SelectionTree
            v-if="isOpen"
            class="selection-tree"
            :value="value"
            :tree="tree"
            multiple
            name="parent"
            @input="$emit('input', $event)"
        />
        <ErrorMessage v-if="error" :message="error" />
    </div>
</template>

<script>
import WasteDropdown from '@/pages/ProductManagement/components/WasteDropdownRenderer/WasteDropdown';
import ErrorMessage from '@/components/Form/ErrorMessage';
import SelectionTree from '@/components/Tree/SelectionTree';
import TreeNode from '@/components/Tree/TreeNode';

export default {
    name: 'WasteDropdownRenderer',
    components: {
        WasteDropdown,
        ErrorMessage,
        SelectionTree,
    },
    props: {
        nrOfOtherChoices: {
            type: String,
            default: '',
        },
        isBigPlaceholderActive: {
            type: Boolean,
            default: true,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        firstOption: {
            type: String,
            default: '',
        },
        tree: {
            type: TreeNode,
            default: null,
        },
        product: {
            type: Array,
            default: null,
        },
        value: {
            type: [Number, Array],
            default: null,
        },
    },
};
</script>
<style lang="scss" scoped>
.selection-tree {
    margin-block-start: 0;
    border-radius: 5px;
    background: #fff;
}
</style>

<template>
    <HeavyWeightIcon :id="getDomId" />
</template>

<script>
import { animateGroupShake } from '@/services/Animation/Animation';
import HeavyWeightIcon from '@/assets/icons/freight/heavy-weight.svg';

export default {
    name: 'AnimatedHeavyWeight',
    components: {
        HeavyWeightIcon,
    },
    computed: {
        getDomId() {
            return `AnimatedHeavyWeightSVG${this._uid}`;
        },
    },
    mounted() {
        animateGroupShake(`#${this.getDomId} > .svg-heavy-weight__head`);
    },
};
</script>

<style lang="scss" scoped></style>

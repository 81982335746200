<template>
    <GridRow :count="2" spacing="large" spaced-top data-test="transport-filter-set">
        <div v-if="supportsFilter('haveProject')" class="span-2">
            <CheckboxField v-model="haveProject">
                {{ $t('pages.transport.filter.haveProject') }}
            </CheckboxField>
        </div>

        <div v-if="supportsFilter('transportNumber')" class="span-2">
            <TextField
                v-model="transportNumber"
                data-test="transport-filter-order-number"
                :label="$t('pages.transport.filter.transportNumber')"
            />
        </div>

        <div v-if="supportsFilter('deliveryNoteNumber')" class="span-2">
            <TextField v-model="deliveryNoteNumber" :label="$t('pages.documents.filter.deliveryNoteNumber')" />
        </div>

        <div v-if="supportsFilter('weighingNoteNumber')" class="span-2">
            <TextField
                v-model="weighingNoteNumber"
                :label="$t('pages.transport.loading.numberLabel')"
                data-test="transport-filter-weighing-note-number"
            />
        </div>

        <div v-if="supportsFilter(['startCreated', 'endCreated'])" class="span-2">
            <Words bold block spaced>{{ $t('pages.transport.filter.createdDateRangeHeadline') }}</Words>
            <TimeRangeFieldset
                :from="filterScope.filter.startCreated"
                :to="filterScope.filter.endCreated"
                type="date"
                @update-from="$set(filterScope.filter, 'startCreated', ensurePHPTimestamp($event))"
                @update-to="$set(filterScope.filter, 'endCreated', ensurePHPTimestamp($event))"
            />
        </div>

        <div v-if="supportsFilter(['startShippingWindow', 'endShippingWindow'])" class="span-2">
            <Words bold block spaced>{{ $t('pages.transport.filter.dateRangeHeadline') }}</Words>
            <TimeRangeFieldset
                :from="filterScope.filter.startShippingWindow"
                :to="filterScope.filter.endShippingWindow"
                type="date"
                @update-from="$set(filterScope.filter, 'startShippingWindow', ensurePHPTimestamp($event))"
                @update-to="$set(filterScope.filter, 'endShippingWindow', ensurePHPTimestamp($event))"
            />
        </div>

        <div v-if="supportsFilter(['startConfirmLoadingTimestamp', 'endConfirmLoadingTimestamp'])" class="span-2">
            <Words bold block spaced>{{ $t('pages.transport.filter.confirmLoadingTimestampDateRangeHeadline') }}</Words>
            <TimeRangeFieldset
                :from="filterScope.filter.startConfirmLoadingTimestamp"
                :to="filterScope.filter.endConfirmLoadingTimestamp"
                type="date"
                @update-from="$set(filterScope.filter, 'startConfirmLoadingTimestamp', ensurePHPTimestamp($event))"
                @update-to="$set(filterScope.filter, 'endConfirmLoadingTimestamp', ensurePHPTimestamp($event))"
            />
        </div>

        <div v-if="supportsFilter('productName')" class="span-2 span-md-2">
            <TextField v-model="productName" :label="$t('pages.transport.filter.productName')" />
        </div>

        <div v-if="supportsFilter('minQty')" class="span-2 span-md-1">
            <TextField v-model="minQty" :label="$t('pages.transport.filter.minQty')" type="number" />
        </div>

        <div v-if="supportsFilter('maxQty')" class="span-2 span-md-1">
            <TextField v-model="maxQty" :label="$t('pages.transport.filter.maxQty')" type="number" />
        </div>

        <div v-if="supportsFilter('minTotalLoading')" class="span-2 span-md-1">
            <TextField v-model="minTotalLoading" :label="$t('pages.transport.filter.minTotalLoading')" type="number" />
        </div>

        <div v-if="supportsFilter('maxTotalLoading')" class="span-2 span-md-1">
            <TextField v-model="maxTotalLoading" :label="$t('pages.transport.filter.maxTotalLoading')" type="number" />
        </div>

        <div v-if="supportsFilter('hasCompletedCreditNoteSupplier')" class="span-2">
            <MultiselectBox
                v-model="hasCompletedCreditNoteSupplier"
                :options="[true, false]"
                :option-value-renderer="option => option"
                :label="
                    $t(
                        selectCreditNoteDocKey({
                            creditNote: `pages.transport.filter.hasCompletedCreditNoteDocSupplierHeadline.creditNote`,
                            selfBilling: `pages.transport.filter.hasCompletedCreditNoteDocSupplierHeadline.selfBilling`,
                        })
                    )
                "
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions.length > 0">
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{
                                    $t(
                                        getHasCompletedCreditNoteDocSupplierStatusKey(
                                            buttonScope.selectedOptions[0] ? 'active' : 'inactive'
                                        )
                                    )
                                }}
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <Words :red="optionScope.isActive" bold block class="multiselect__option--spaced">
                        {{
                            $t(
                                getHasCompletedCreditNoteDocSupplierStatusKey(
                                    optionScope.option ? 'active' : 'inactive'
                                )
                            )
                        }}
                        <TickIcon v-if="optionScope.isActive" class="icon--inline icon--red" />
                    </Words>
                </template>
            </MultiselectBox>
        </div>

        <div v-if="supportsFilter('status')" class="span-2">
            <Words bold block spaced>{{ $t('pages.transport.filter.statusHeadline') }}</Words>
            <MultiselectBox
                v-model="status"
                :options="Object.keys(endpoint.supportedFilters.status.options)"
                :option-value-renderer="option => option"
                :search-label-renderer="option => statusText(option)"
                :label="$t('pages.transport.filter.statusHeadline')"
                multiple
                searchable
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1">
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ statusText(buttonScope.selectedOptions[0]) }}
                            </Words>
                        </span>
                        <span
                            v-else-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length > 1"
                            muted
                            middle
                        >
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ statusText(buttonScope.selectedOptions[0]) }}, +{{
                                    buttonScope.selectedOptions.length - 1
                                }}
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <Words bold block>{{ statusText(optionScope.option) }}</Words>
                </template>
            </MultiselectBox>
        </div>

        <div v-if="supportsFilter('type')" class="span-2">
            <Words bold block spaced>{{ $t('pages.transport.filter.typeHeadline') }}</Words>
            <MultiselectBox
                v-model="type"
                :options="Object.keys(endpoint.supportedFilters.type.options)"
                :option-value-renderer="option => option"
                :label="$t('pages.transport.filter.typeHeadline')"
                multiple
                searchable
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1">
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ getTransportTypeLabel(buttonScope.selectedOptions[0]) }}
                            </Words>
                        </span>
                        <span
                            v-else-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length > 1"
                            muted
                            middle
                        >
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ getTransportTypeLabel(buttonScope.selectedOptions[0]) }}, +{{
                                    buttonScope.selectedOptions.length - 1
                                }}
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <Words bold block>{{ getTransportTypeLabel(optionScope.option) }}</Words>
                </template>
            </MultiselectBox>
        </div>

        <div v-if="supportsFilter('constructionProject')" class="span-2">
            <Words bold block spaced>{{ $t('pages.transport.filter.constructionProjectHeadline') }}</Words>
            <MultiselectBox
                v-model="constructionProject"
                :options="constructionProjects"
                :option-value-renderer="option => option.id"
                :search-label-renderer="option => option.name"
                :label="$t('pages.transport.filter.constructionProjectHeadline')"
                searchable
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1">
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ buttonScope.selectedOptions[0].name }}
                            </Words>
                        </span>
                        <span
                            v-else-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length > 1"
                            muted
                            middle
                        >
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ buttonScope.selectedOptions[0].name }}, +{{ buttonScope.selectedOptions.length - 1 }}
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <div class="flex-cols flex items-center justify-between">
                        <div class="flex flex-col">
                            <Words :red="optionScope.isActive" bold block>
                                {{ optionScope.option.name }}
                            </Words>
                            <Words small muted block>
                                <FormattedAddress
                                    :is-highlighted="optionScope.isActive"
                                    :address="optionScope.option.address"
                                />
                            </Words>
                        </div>
                        <TickIcon v-if="optionScope.isActive" class="icon--inline icon--red mr-4" />
                    </div>
                </template>
            </MultiselectBox>
        </div>

        <div v-if="supportsFilter('constructionSite')" class="span-2">
            <Words bold block spaced>{{ $t('pages.transport.filter.constructionSiteHeadline') }}</Words>
            <MultiselectBox
                v-model="constructionSite"
                :options="endpoint.supportedFilters.constructionSite.options"
                :option-value-renderer="option => option.id"
                :search-label-renderer="option => option.name"
                :label="$t('pages.transport.filter.constructionSiteHeadline')"
                multiple
                searchable
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1">
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ buttonScope.selectedOptions[0].name }}
                            </Words>
                        </span>
                        <span
                            v-else-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length > 1"
                            muted
                            middle
                        >
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ buttonScope.selectedOptions[0].name }}, +{{ buttonScope.selectedOptions.length - 1 }}
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <div style="line-height: 1.4em">
                        <Words bold block>{{ optionScope.option.name }}</Words>
                        <Words small muted block>
                            <FormattedAddress :address="optionScope.option.address" />
                        </Words>
                    </div>
                </template>
            </MultiselectBox>
        </div>

        <div v-if="supportsFilter('carrierOrganizationSearch')" class="span-2 span-md-2">
            <TextField
                v-model="carrierOrganizationSearch"
                :label="$t('pages.transport.filter.carrierOrganizationSearch')"
            />
        </div>

        <div v-if="supportsFilter('originSearch')" class="span-2 span-md-1">
            <TextField v-model="originSearch" :label="$t('pages.transport.filter.originSearch')" />
        </div>

        <div v-if="supportsFilter('destinationSearch')" class="span-2 span-md-1">
            <TextField v-model="destinationSearch" :label="$t('pages.transport.filter.destinationSearch')" />
        </div>

        <div v-if="supportsFilter('licensePlate')" class="span-2">
            <Words bold block spaced>{{ $t('pages.transport.filter.licensePlateHeadline') }}</Words>
            <MultiselectBox
                v-model="licensePlate"
                :options="endpoint.supportedFilters.licensePlate.options"
                :label="$t('pages.transport.filter.licensePlateHeadline')"
                multiple
                searchable
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1">
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ buttonScope.selectedOptions[0] }}
                            </Words>
                        </span>
                        <span
                            v-else-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length > 1"
                            muted
                            middle
                        >
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ buttonScope.selectedOptions[0] }}, +{{ buttonScope.selectedOptions.length - 1 }}
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <div style="line-height: 1.4em">
                        <Words bold block>{{ optionScope.option }}</Words>
                    </div>
                </template>
            </MultiselectBox>
        </div>

        <div v-if="supportsFilter('minDistance')" class="span-2 span-md-1">
            <TextField v-model="minDistance" :label="$t('pages.transport.filter.minDistance')" type="number" />
        </div>

        <div v-if="supportsFilter('maxDistance')" class="span-2 span-md-1">
            <TextField v-model="maxDistance" :label="$t('pages.transport.filter.maxDistance')" type="number" />
        </div>

        <div v-if="supportsFilter('vehicleClass')" class="span-2">
            <Words bold block spaced>{{ $t('pages.transport.filter.vehicleClassHeadline') }}</Words>
            <MultiselectBox
                v-model="vehicleClass"
                :options="endpoint.supportedFilters.vehicleClass.options"
                :option-value-renderer="option => option.id"
                :search-value-renderer="option => option.name"
                :label="$t('pages.transport.filter.vehicleClass')"
                search
                multiple
                image
                class="vehicle-form__vehicle-select"
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span
                            v-if="buttonScope.selectedOption && buttonScope.selectedOptions.length === 1"
                            class="supervisor-selection__selected"
                        >
                            <VehicleClassIconSet
                                :icon="buttonScope.selectedOption.icon"
                                width="60"
                                class="icon--inline"
                            />
                            <span class="vehicle-form__vehicle-title">
                                {{ buttonScope.selectedOption.name }}
                                <span v-if="getVehicleClassTypeLabel(buttonScope.selectedOption)">
                                    / {{ getVehicleClassTypeLabel(buttonScope.selectedOption) }}
                                </span>
                            </span>
                        </span>
                        <span v-else-if="buttonScope.selectedOption" class="supervisor-selection__selected">
                            <VehicleClassIconSet
                                :icon="buttonScope.selectedOption.icon"
                                width="60"
                                class="icon--inline"
                            />
                            <span class="vehicle-form__vehicle-title">
                                {{ buttonScope.selectedOption.name }}
                                <span v-if="getVehicleClassTypeLabel(buttonScope.selectedOption)">
                                    / {{ getVehicleClassTypeLabel(buttonScope.selectedOption) }}
                                </span>
                                , +{{ buttonScope.selectedOptions.length - 1 }}
                            </span>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <div class="vehicle-form__vehicle-option">
                        <VehicleClassIconSet :icon="optionScope.option.icon" width="60" class="icon--inline" />
                        <span class="vehicle-form__vehicle-title">
                            <Words :red="optionScope.isActive">
                                {{ optionScope.option.name }}
                                <span v-if="getVehicleClassTypeLabel(optionScope.option)">
                                    / {{ getVehicleClassTypeLabel(optionScope.option) }}
                                </span>
                            </Words>
                            <TickIcon v-if="optionScope.isActive" class="icon--red" />
                        </span>
                    </div>
                </template>
            </MultiselectBox>
        </div>

        <div v-if="supportsFilter('minRevenueCarrier')" class="span-2 span-md-1">
            <TextField
                v-model="minRevenueCarrier"
                :label="$t('pages.transport.filter.minRevenueCarrier')"
                type="number"
            />
        </div>

        <div v-if="supportsFilter('maxRevenueCarrier')" class="span-2 span-md-1">
            <TextField
                v-model="maxRevenueCarrier"
                :label="$t('pages.transport.filter.maxRevenueCarrier')"
                type="number"
            />
        </div>

        <div v-if="supportsFilter('minRevenueSupplier')" class="span-2 span-md-1">
            <TextField
                v-model="minRevenueSupplier"
                :label="$t('pages.transport.filter.minRevenueSupplier')"
                type="number"
            />
        </div>

        <div v-if="supportsFilter('maxRevenueSupplier')" class="span-2 span-md-1">
            <TextField
                v-model="maxRevenueSupplier"
                :label="$t('pages.transport.filter.maxRevenueSupplier')"
                type="number"
            />
        </div>

        <div v-if="supportsFilter('shippingMethod')" class="span-2">
            <Words bold block spaced>{{ $t('pages.transport.filter.shippingMethodHeadline') }}</Words>
            <MultiselectBox
                v-model="shippingMethod"
                :options="endpoint.supportedFilters.shippingMethod.options"
                :label="$t('pages.transport.filter.shippingMethod')"
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1">
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ $t(`pages.transport.filter.shippingMethods.${buttonScope.selectedOptions[0]}`) }}
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <div style="line-height: 1.4em">
                        <Words :red="optionScope.isActive" bold>
                            {{ $t(`pages.transport.filter.shippingMethods.${optionScope.option}`) }}
                        </Words>
                        &nbsp;
                        <TickIcon v-if="optionScope.isActive" class="icon--inline icon--red" />
                    </div>
                </template>
            </MultiselectBox>
        </div>

        <div v-if="supportsFilter('originGeo')" class="span-2">
            <LocationField
                :place="_get(filterScope.filter, 'originGeo.place', null)"
                :radius="_get(filterScope.filter, 'originGeo.radius', null)"
                :radius-options="[5, 25, 50, 100, 200]"
                :label="$t('pages.transport.filter.originGeo')"
                street-less
                @placeChange="$set(filterScope.filter, 'originGeo.place', $event)"
                @radiusChange="$set(filterScope.filter, 'originGeo.radius', $event)"
            />
        </div>

        <div v-if="supportsFilter('destinationGeo')" class="span-2">
            <LocationField
                :place="_get(filterScope.filter, 'destinationGeo.place', null)"
                :radius="_get(filterScope.filter, 'destinationGeo.radius', null)"
                :radius-options="[5, 25, 50, 100, 200]"
                :label="$t('pages.transport.filter.destinationGeo')"
                street-less
                @placeChange="$set(filterScope.filter, 'destinationGeo.place', $event)"
                @radiusChange="$set(filterScope.filter, 'destinationGeo.radius', $event)"
            />
        </div>

        <div v-if="supportsFilter('driver')" class="span-2">
            <Words bold block spaced>{{ $t('pages.transport.filter.driverHeadline') }}</Words>
            <MultiselectBox
                v-model="driver"
                :options="endpoint.supportedFilters.driver.options"
                :option-value-renderer="option => option.id"
                :label="$t('pages.transport.filter.driver')"
                :search-label-renderer="option => option.firstName + ' ' + option.lastName"
                :search-field-label="$t('pages.transport.filter.driverSearchFieldLabel')"
                class="supervisor-selection__multiselect"
                searchable
                image
                multiple
            >
                <template #button="buttonScope">
                    <div class="supervisor-selection__button">
                        <DefaultInner dark>
                            <span v-if="buttonScope.selectedOption" class="supervisor-selection__selected">
                                <Avatar
                                    :src="buttonScope.selectedOption.image && buttonScope.selectedOption.image.url"
                                    class="supervisor-selection__image"
                                    size="small"
                                />
                                <span class="supervisor-selection__name">
                                    {{ buttonScope.selectedOption.firstName }}
                                    {{ buttonScope.selectedOption.lastName }}
                                </span>
                            </span>
                            <span v-else>{{ buttonScope.label }}</span>
                        </DefaultInner>
                    </div>
                </template>

                <template #option="optionScope">
                    <div class="supervisor-selection__option">
                        <Avatar
                            :src="optionScope.option.image && optionScope.option.image.url"
                            class="supervisor-selection__image"
                            size="small"
                        />
                        <Words v-if="optionScope.isActive" red class="supervisor-selection__text">
                            {{ optionScope.option.firstName }} {{ optionScope.option.lastName }}
                        </Words>
                        <Words v-else class="supervisor-selection__text">
                            {{ optionScope.option.firstName }} {{ optionScope.option.lastName }}
                        </Words>
                        <TickIcon v-if="optionScope.isActive" class="supervisor-selection__active-icon" />
                    </div>
                </template>
            </MultiselectBox>
        </div>

        <div v-if="supportsFilter('orderNumber') && !supportsFilter('transportNumber')" class="span-2">
            <TextField v-model="orderNumber" :label="$t('pages.transport.filter.orderNumber')" />
        </div>

        <div v-if="supportsFilter('clientOrganization')" class="span-2">
            <TextField v-model="clientOrganization" :label="$t('pages.transport.filter.clientOrganizationHeadline')" />
        </div>

        <div v-if="supportsFilter('supplierOrganization')" class="span-2">
            <TextField
                v-model="supplierOrganization"
                :label="$t('pages.transport.filter.supplierOrganizationHeadline')"
            />
        </div>

        <div v-if="supportsFilter('carrierOrganization')" class="span-2">
            <TextField
                v-model="carrierOrganization"
                :label="$t('pages.transport.filter.carrierOrganizationHeadline')"
            />
        </div>

        <div v-if="supportsFilter('minTransportPurchasePrice')" class="span-2 span-md-1">
            <TextField
                v-model="minTransportPurchasePrice"
                :label="$t('pages.transport.filter.minTransportPurchasePrice')"
                type="number"
            />
        </div>

        <div v-if="supportsFilter('maxTransportPurchasePrice')" class="span-2 span-md-1">
            <TextField
                v-model="maxTransportPurchasePrice"
                :label="$t('pages.transport.filter.maxTransportPurchasePrice')"
                type="number"
            />
        </div>

        <div v-if="supportsFilter('minTransportRetailPrice')" class="span-2 span-md-1">
            <TextField
                v-model="minTransportRetailPrice"
                :label="$t('pages.transport.filter.minTransportRetailPrice')"
                type="number"
            />
        </div>

        <div v-if="supportsFilter('maxTransportRetailPrice')" class="span-2 span-md-1">
            <TextField
                v-model="maxTransportRetailPrice"
                :label="$t('pages.transport.filter.maxTransportRetailPrice')"
                type="number"
            />
        </div>

        <div v-if="supportsFilter('failReason')" class="span-2">
            <Words bold block spaced>{{ $t('pages.transport.filter.failReasonHeadline') }}</Words>
            <MultiselectBox
                v-model="failReason"
                :options="endpoint.supportedFilters.failReason.options"
                :label="$t('pages.transport.filter.failReasonHeadline')"
                :search-label-renderer="option => option + $t(`pages.transport.transportFailed.reasons.${option}`)"
                multiple
                searchable
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1">
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ buttonScope.selectedOptions[0] }}
                            </Words>
                        </span>
                        <span
                            v-else-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length > 1"
                            muted
                            middle
                        >
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ buttonScope.selectedOptions[0] }}, +{{ buttonScope.selectedOptions.length - 1 }}
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <div style="line-height: 1.4em">
                        <Words bold block>{{ optionScope.option }}</Words>
                        <Words muted block small>
                            {{ $t(`pages.transport.transportFailed.reasons.${optionScope.option}`) }}
                        </Words>
                    </div>
                </template>
            </MultiselectBox>
        </div>

        <div v-if="supportsFilter('minCreditNoteTotalSupplier')" class="span-2 span-md-1">
            <TextField
                v-model="minCreditNoteTotalSupplier"
                :label="$t('pages.transport.filter.minCreditNoteTotalSupplier')"
                type="number"
            />
        </div>

        <div v-if="supportsFilter('maxCreditNoteTotalSupplier')" class="span-2 span-md-1">
            <TextField
                v-model="maxCreditNoteTotalSupplier"
                :label="$t('pages.transport.filter.maxCreditNoteTotalSupplier')"
                type="number"
            />
        </div>

        <div v-if="supportsFilter(['startServiceDateTimestamp', 'endServiceDateTimestamp'])" class="span-2">
            <Words bold block spaced>{{ $t('pages.transport.filter.serviceDateTimestampHeadline') }}</Words>
            <TimeRangeFieldset
                :from="filterScope.filter.startServiceDateTimestamp"
                :to="filterScope.filter.endServiceDateTimestamp"
                type="date"
                @updateFrom="$set(filterScope.filter, 'startServiceDateTimestamp', ensurePHPTimestamp($event))"
                @updateTo="$set(filterScope.filter, 'endServiceDateTimestamp', ensurePHPTimestamp($event))"
            />
        </div>

        <div v-if="supportsFilter('minCreditNoteTotalCarrier')" class="span-2 span-md-1">
            <TextField
                v-model="minCreditNoteTotalCarrier"
                :label="$t('pages.transport.filter.minCreditNoteTotalCarrier')"
                type="number"
            />
        </div>

        <div v-if="supportsFilter('maxCreditNoteTotalCarrier')" class="span-2 span-md-1">
            <TextField
                v-model="maxCreditNoteTotalCarrier"
                :label="$t('pages.transport.filter.maxCreditNoteTotalCarrier')"
                type="number"
            />
        </div>

        <div v-if="supportsFilter(['startPickupableDate', 'endPickupableDate'])" class="span-2">
            <Words bold block spaced>{{ $t('pages.transport.filter.pickupableRangeHeadline') }}</Words>
            <TimeRangeFieldset
                :from="filterScope.filter.startPickupableDate"
                :to="filterScope.filter.endPickupableDate"
                type="date"
                @updateFrom="$set(filterScope.filter, 'startPickupableDate', ensurePHPTimestamp($event))"
                @updateTo="$set(filterScope.filter, 'endPickupableDate', ensurePHPTimestamp($event))"
            />
        </div>

        <div v-if="supportsFilter('rating')" class="span-2">
            <MultiselectBox
                v-model="rating"
                :options="[0, 1, 2, 3]"
                :option-value-renderer="option => option"
                :label="$t('pages.transport.filter.ratingHeadline')"
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions.length > 0">
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ $tc(`pages.transport.filter.ratingOption`, buttonScope.selectedOptions[0]) }}
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <Words :red="optionScope.isActive" bold block class="multiselect__option--spaced">
                        {{ $tc(`pages.transport.filter.ratingOption`, optionScope.option) }}
                        <TickIcon v-if="optionScope.isActive" class="icon--inline icon--red" />
                    </Words>
                </template>
            </MultiselectBox>
        </div>

        <div v-if="supportsFilter('isMissingAccompanyingDocumentNumber')" class="span-2">
            <CheckboxField v-model="isMissingAccompanyingDocumentNumber">
                {{ $t('pages.transport.filter.isMissingAccompanyingDocumentNumber') }}
            </CheckboxField>
        </div>

        <div v-if="supportsFilter('isProductDangerous')" class="span-2">
            <CheckboxField v-model="isProductDangerous">
                {{ $t('pages.transport.filter.isProductDangerous') }}
            </CheckboxField>
        </div>
    </GridRow>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { statusText } from '@/services/utils/transport';
import { ensurePHPTimestamp } from '@/services/utils/date';
import { revertLocalizedValue } from '@/services/utils/localization';
import _isArray from 'lodash/isArray';
import _intersection from 'lodash/intersection';
import _get from 'lodash/get';
import routeContext from '@/plugins/mixins/routeContext';
import { CONTEXT_SUPPLIER } from '@/constants/context';

import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
import { useTwoWayBindingComputedFilter } from '@/components/Filter/FilterBox';

import Avatar from '@/components/Avatar';
import CheckboxField from '@/components/Form/CheckboxField';
import DefaultInner from '@/components/Form/MultiselectBox/DefaultInner';
import FormattedAddress from '@/components/FormattedAddress';
import GridRow from '@/components/Layout/GridRow';
import LocationField from '@/components/Form/LocationField.v2';
import MultiselectBox from '@/components/Form/MultiselectBox';
import TextField from '@/components/Form/TextField.v2';
import TimeRangeFieldset from '@/components/Filter/TimeRangeFieldset.v2';
import VehicleClassIconSet from '@/components/IconSet/VehicleClassIconSet';
import Words from '@/components/Typography/Words';

import TickIcon from '@/assets/icons/micro/tick.svg';
import { useConstructionProjectList } from '@/constructionProjects/composables/useConstructionProjectList';

export default defineComponent({
    name: 'TransportFilterSet',
    components: {
        Avatar,
        CheckboxField,
        DefaultInner,
        FormattedAddress,
        GridRow,
        LocationField,
        MultiselectBox,
        TextField,

        TickIcon,
        TimeRangeFieldset,
        VehicleClassIconSet,
        Words,
    },
    mixins: [routeContext],
    props: {
        filterScope: {
            type: Object,
            required: true,
        },
        endpoint: {
            type: Object,
            required: true,
        },
        whitelistedFields: {
            type: Array,
            default: null,
        },
    },
    setup(props, { emit }) {
        const isConstructionProjectEnabled = computed(() => props.whitelistedFields.includes('constructionProject'));

        const constructionProjects = useConstructionProjectList({
            enabled: isConstructionProjectEnabled,
            placeholderData: {
                constructionProjects: [],
            },
            select: response => {
                return response.constructionProjects.map(project => ({
                    id: project.id,
                    name: project.name,
                    address: {
                        street: project.street,
                        number: project.addressNumber,
                        city: project.city,
                        zip: project.zip,
                        state: project.state,
                        countryCode: project.country,
                    },
                }));
            },
        });

        const createTwoWayBindingComputedFilter = useTwoWayBindingComputedFilter({ props, emit });

        const { selectCreditNoteDocKey } = useLegalTerms();
        const getHasCompletedCreditNoteDocSupplierStatusKey = status => {
            return {
                active: selectCreditNoteDocKey({
                    creditNote: 'pages.transport.filter.hasCompletedCreditNoteDocSupplier.active.creditNote',
                    selfBilling: 'pages.transport.filter.hasCompletedCreditNoteDocSupplier.active.selfBilling',
                }),
                inactive: selectCreditNoteDocKey({
                    creditNote: 'pages.transport.filter.hasCompletedCreditNoteDocSupplier.inactive.creditNote',
                    selfBilling: 'pages.transport.filter.hasCompletedCreditNoteDocSupplier.inactive.selfBilling',
                }),
            }[status];
        };

        return {
            isConstructionProjectEnabled,
            constructionProjects: constructionProjects.data,
            isMissingAccompanyingDocumentNumber: createTwoWayBindingComputedFilter(
                'isMissingAccompanyingDocumentNumber'
            ),
            isProductDangerous: createTwoWayBindingComputedFilter('isProductDangerous'),
            haveProject: createTwoWayBindingComputedFilter('haveProject'),
            transportNumber: createTwoWayBindingComputedFilter('transportNumber'),
            deliveryNoteNumber: createTwoWayBindingComputedFilter('deliveryNoteNumber'),
            productName: createTwoWayBindingComputedFilter('productName'),
            minQty: createTwoWayBindingComputedFilter('minQty'),
            maxQty: createTwoWayBindingComputedFilter('maxQty'),
            minTotalLoading: createTwoWayBindingComputedFilter('minTotalLoading'),
            maxTotalLoading: createTwoWayBindingComputedFilter('maxTotalLoading'),
            hasCompletedCreditNoteSupplier: createTwoWayBindingComputedFilter('hasCompletedCreditNoteSupplier'),
            status: createTwoWayBindingComputedFilter('status'),
            type: createTwoWayBindingComputedFilter('type'),
            constructionSite: createTwoWayBindingComputedFilter('constructionSite'),
            constructionProject: createTwoWayBindingComputedFilter('constructionProject'),
            carrierOrganizationSearch: createTwoWayBindingComputedFilter('carrierOrganizationSearch'),
            originSearch: createTwoWayBindingComputedFilter('originSearch'),
            destinationSearch: createTwoWayBindingComputedFilter('destinationSearch'),
            licensePlate: createTwoWayBindingComputedFilter('licensePlate'),
            minDistance: createTwoWayBindingComputedFilter('minDistance'),
            maxDistance: createTwoWayBindingComputedFilter('maxDistance'),
            vehicleClass: createTwoWayBindingComputedFilter('vehicleClass'),
            minRevenueCarrier: createTwoWayBindingComputedFilter('minRevenueCarrier'),
            maxRevenueCarrier: createTwoWayBindingComputedFilter('maxRevenueCarrier'),
            minRevenueSupplier: createTwoWayBindingComputedFilter('minRevenueSupplier'),
            maxRevenueSupplier: createTwoWayBindingComputedFilter('maxRevenueSupplier'),
            shippingMethod: createTwoWayBindingComputedFilter('shippingMethod'),
            driver: createTwoWayBindingComputedFilter('driver'),
            orderNumber: createTwoWayBindingComputedFilter('orderNumber'),
            weighingNoteNumber: createTwoWayBindingComputedFilter('weighingNoteNumber'),
            clientOrganization: createTwoWayBindingComputedFilter('clientOrganization'),
            supplierOrganization: createTwoWayBindingComputedFilter('supplierOrganization'),
            carrierOrganization: createTwoWayBindingComputedFilter('carrierOrganization'),
            minTransportPurchasePrice: createTwoWayBindingComputedFilter('minTransportPurchasePrice'),
            maxTransportPurchasePrice: createTwoWayBindingComputedFilter('maxTransportPurchasePrice'),
            minTransportRetailPrice: createTwoWayBindingComputedFilter('minTransportRetailPrice'),
            maxTransportRetailPrice: createTwoWayBindingComputedFilter('maxTransportRetailPrice'),
            failReason: createTwoWayBindingComputedFilter('failReason'),
            minCreditNoteTotalSupplier: createTwoWayBindingComputedFilter('minCreditNoteTotalSupplier'),
            maxCreditNoteTotalSupplier: createTwoWayBindingComputedFilter('maxCreditNoteTotalSupplier'),
            minCreditNoteTotalCarrier: createTwoWayBindingComputedFilter('minCreditNoteTotalCarrier'),
            maxCreditNoteTotalCarrier: createTwoWayBindingComputedFilter('maxCreditNoteTotalCarrier'),
            rating: createTwoWayBindingComputedFilter('rating'),

            getHasCompletedCreditNoteDocSupplierStatusKey,
            selectCreditNoteDocKey,
        };
    },
    methods: {
        ensurePHPTimestamp,
        revertLocalizedValue,
        _get,

        statusText(status) {
            return statusText(this.routeContext, status);
        },

        /**
         * Note: May be extracted into a FilterSet Mixin/Component
         */
        supportsFilter(filterName) {
            if (!this.endpoint.supportedFilters) {
                return false;
            }

            const checkProps = _isArray(filterName) ? filterName : [filterName];

            // if filterName does not appear fully in whitelistedFields,.. then the filter is not supported
            if (
                _isArray(this.whitelistedFields) &&
                _intersection(this.whitelistedFields, checkProps).length !== checkProps.length
            ) {
                return false;
            }

            return _intersection(Object.keys(this.endpoint.supportedFilters), checkProps).length === checkProps.length;
        },

        getVehicleClassTypeLabel(vehicleClass) {
            const labels = [];

            vehicleClass.isTypeDelivery && labels.push(this.$t('pages.vehicle.list.type.delivery'));
            vehicleClass.isTypePickup && labels.push(this.$t('pages.vehicle.list.type.pickup'));

            return labels.join(', ');
        },

        getTransportTypeLabel(option) {
            if (this.routeContext === CONTEXT_SUPPLIER) {
                return this.$t(`pages.transport.filter.supplierTypes.${option}`);
            }

            return this.$t(`pages.transport.filter.defaultTypes.${option}`);
        },
    },
});
</script>

import { computed, reactive, ref } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, integer, maxLength, requiredUnless } from '@vuelidate/validators';
export const getEmptyWeighingForm = () => ({
    method: null,
    number: null,
    quantity: null,
    tare: null,
    full: null,
    shovels: null,
    cbm: undefined,
    images: [],
    documents: [],
    date: undefined,
    name: null,
});
export const useCaptureWeight = (captureFormArgsRef) => {
    const isWeighingRequired = computed(() => captureFormArgsRef.value.isWeighingRequired);
    const additional = computed(() => captureFormArgsRef.value.additional);
    const form = reactive(getEmptyWeighingForm());
    const cbm = computed(() => form.cbm?.cbm);
    // eslint-disable-next-line complexity
    const rules = computed(() => ({
        form: {
            method: {},
            /**
             * Minimal Values
             */
            images: {
                required: requiredUnless(() => !!form.documents.length),
            },
            documents: {
                required: requiredUnless(() => !!form.images.length),
            },
            date: {
                ...(isWeighingRequired.value ? { required } : {}),
            },
            /**
             * Additional Values
             */
            quantity: {
                ...(isWeighingRequired.value && additional.value.includes('quantity') ? { required, integer } : {}),
            },
            tare: {
                ...(isWeighingRequired.value && additional.value.includes('tare') ? { required, integer } : {}),
            },
            full: {
                ...(isWeighingRequired.value && additional.value.includes('full') ? { required, integer } : {}),
            },
            shovels: {
                ...(isWeighingRequired.value && additional.value.includes('shovels') ? { required, integer } : {}),
            },
            cbm: {
                cbm: {
                    ...(isWeighingRequired.value && additional.value.includes('cbm') ? { required, integer } : {}),
                },
                cbmInKg: {
                    ...(isWeighingRequired.value && additional.value.includes('cbm') ? { required, integer } : {}),
                },
                density: {
                    ...(isWeighingRequired.value && additional.value.includes('cbm') ? { required, integer } : {}),
                },
            },
            number: {
                ...(isWeighingRequired.value && additional.value.includes('number')
                    ? { required, maxLength: maxLength(40) }
                    : {}),
            },
            name: {
                ...(isWeighingRequired.value && additional.value.includes('name') ? {} : {}),
            },
        },
        cbm: {
            ...(isWeighingRequired.value && additional.value.includes('cbm') ? { required, integer } : {}),
        },
    }));
    const setServerErrors = (errors) => {
        $externalResults.value = errors;
    };
    const $externalResults = ref({});
    const v$ = useVuelidate(rules, { form, cbm }, { $externalResults, $stopPropagation: true });
    return { form, v$, setServerErrors };
};

<template>
    <LayoutPage class="documents-list" screen-name="documents">
        <div slot="pageTitle">{{ $t('pages.documents.title') }}</div>

        <HeaderBar slot="mobileHeader">
            <span slot="headline">
                {{ $t('pages.documents.title') }}
            </span>

            <template #right>
                <HeaderBarItem>
                    <FilterBox
                        v-model="filter"
                        :default-filter="defaultFilter"
                        :endpoint="dataEndpoint"
                        :ignore-in-count="['organizationType', 'organization', 'user']"
                        @save="refreshList()"
                    >
                        <template #default="{ filter: filterFromSlot, updateFilter }">
                            <DocumentsFilterSet
                                :filter-scope="{ filter: filterFromSlot }"
                                :endpoint="dataEndpoint"
                                :whitelisted-fields="whitelistedFilterFields"
                                @updateFilter="updateFilter"
                            />
                        </template>
                    </FilterBox>
                </HeaderBarItem>

                <HeaderBarItem>
                    <FilterBox
                        v-model="filter"
                        :default-filter="defaultFilter"
                        :endpoint="dataEndpoint"
                        inline-mode
                        no-padding
                        @update="refreshList()"
                    >
                        <template #default="filterScope">
                            <SortingSelectBoxMobile
                                v-model="filterScope.filter.sortBy"
                                :direction="filterScope.filter.sortDirection"
                                :label="$t('pages.documents.actions.sortBy')"
                                :sort-options="sortOptions"
                                :option-label-renderer="value => $t(`pages.documents.sortings.${value}`)"
                                primary
                                light
                                small
                                @direction-update="filterScope.filter.sortDirection = $event"
                                @sort-update="filterScope.filter.sortBy = $event"
                            />
                        </template>
                    </FilterBox>
                </HeaderBarItem>
            </template>
        </HeaderBar>

        <div v-if="$root.isDesktop">
            <FilterBar :loading="isLoading" show-refresh transparent @refresh="refreshList()">
                <FilterBox
                    slot="filter"
                    v-model="filter"
                    :default-filter="defaultFilter"
                    :endpoint="dataEndpoint"
                    :ignore-in-count="['organizationType', 'organization', 'user']"
                    :button-label="$t('pages.documents.actions.filter')"
                    @save="refreshList()"
                >
                    <template #default="{ filter: filterFromSlot, updateFilter }">
                        <DocumentsFilterSet
                            :filter-scope="{ filter: filterFromSlot }"
                            :endpoint="dataEndpoint"
                            :whitelisted-fields="whitelistedFilterFields"
                            @updateFilter="updateFilter"
                        />
                    </template>
                </FilterBox>

                <FilterBox
                    slot="sorting"
                    v-model="filter"
                    :default-filter="defaultFilter"
                    :endpoint="dataEndpoint"
                    inline-mode
                    @update="refreshList()"
                >
                    <template #default="filterScope">
                        <SortingSelectBox
                            v-model="filterScope.filter.sortBy"
                            :direction="filterScope.filter.sortDirection"
                            :label="$t('pages.documents.actions.sortBy')"
                            primary
                            light
                            small
                            @direction-update="filterScope.filter.sortDirection = $event"
                        >
                            <option v-for="option in sortOptions" :key="option" :value="option">
                                {{ $t(`pages.documents.sortings.${option}`) }}
                            </option>
                        </SortingSelectBox>
                    </template>
                </FilterBox>
            </FilterBar>

            <LoadingSpinner v-if="!receipts" block dark />

            <transition name="fade">
                <div>
                    <GridTable v-if="receipts && receipts.count > 0" columns="20px 1fr 1fr 1fr 1fr 1fr">
                        <GridTableHead slot="head" v-stuckable="20">
                            <div />
                            <div>{{ $t('pages.documents.headlines.document') }}</div>
                            <div>{{ $t('pages.documents.headlines.created') }}</div>
                            <div>{{ $t('pages.documents.headlines.transfered') }}</div>
                            <div>{{ $t('pages.documents.headlines.status') }}</div>
                            <div class="grid-table__cell--right">{{ $t('pages.documents.headlines.totalGross') }}</div>
                        </GridTableHead>

                        <template v-for="receipt in receipts.items">
                            <GridTableRow :key="`${receipt.id}-item`" spaced class="grid-table__row--pointless">
                                <div class="grid-table__cell">
                                    <DocumentIcon width="14" height="14" class="icon--inline" />
                                </div>

                                <div class="grid-table__cell">
                                    <Words block>{{ receipt.number }}</Words>
                                    <Words block muted small>{{ $t(getDocumentTypeKey(receipt.type)) }}</Words>
                                </div>

                                <div class="grid-table__cell">
                                    <Words middle>
                                        {{ $d(receipt.created * 1000, 'short') }}
                                    </Words>
                                </div>

                                <div class="grid-table__cell">
                                    <Words v-if="receipt.paidTimestamp" middle>
                                        {{ $d(receipt.paidTimestamp * 1000, 'short') }}
                                    </Words>
                                    <Words v-else>–</Words>
                                </div>

                                <div class="grid-table__cell">
                                    <Words
                                        :red="receipt.status === 'cancelled'"
                                        :yellow="receipt.status === 'created'"
                                        :green="receipt.status === 'paid' || receipt.status === 'booked'"
                                        middle
                                        bold
                                        small
                                    >
                                        {{ $t(`pages.documents.status.${receipt.status}`) }}
                                    </Words>
                                </div>

                                <div class="grid-table__cell grid-table__cell--right">
                                    <Words middle unbreakable bold>
                                        {{ $n(receipt.total, 'currency') }}
                                    </Words>
                                </div>

                                <div class="grid-table__cell--foot">
                                    <div class="invoice-list__buttons">
                                        <BaseButton
                                            v-if="receipt.document"
                                            :href="receipt.document.url"
                                            arrow-right
                                            class="invoice-list__button"
                                            @click.stop
                                        >
                                            <VoucherIcon
                                                v-if="receipt.type === 'credit_note'"
                                                slot="left"
                                                class="icon--inline"
                                            />
                                            <CancelIcon
                                                v-else-if="receipt.type === 'cancellation'"
                                                slot="left"
                                                class="icon--inline"
                                            />
                                            <InvoiceIcon
                                                v-else-if="receipt.type === 'invoice'"
                                                slot="left"
                                                class="icon--inline"
                                            />

                                            <template v-if="receipt.type === 'credit_note'">
                                                {{
                                                    $t(
                                                        selectCreditNoteDocKey({
                                                            creditNote: `pages.documents.actions.downloadCreditNoteDocPdf.creditNote`,
                                                            selfBilling: `pages.documents.actions.downloadCreditNoteDocPdf.selfBilling`,
                                                        })
                                                    )
                                                }}
                                            </template>
                                            <template v-else-if="receipt.type === 'cancellation'">
                                                {{ $t('pages.documents.actions.downloadCancelCreditNotePdf') }}
                                            </template>
                                            <template v-else-if="receipt.type === 'invoice'">
                                                {{ $t('pages.documents.actions.downloadInvoicePdf') }}
                                            </template>
                                        </BaseButton>
                                    </div>
                                </div>
                            </GridTableRow>
                        </template>
                        <MoreResultsButton
                            v-if="receipts.count - receipts.items.length > 0"
                            :result="receipts"
                            fade-out
                            class="receipts__more-results-button"
                        />
                    </GridTable>

                    <Hint v-if="receipts && receipts.count === 0" center>
                        {{ $t('pages.documents.noResults') }}
                    </Hint>
                </div>
            </transition>
        </div>

        <div v-else>
            <LoadingSpinner v-if="!receipts" block dark />

            <transition name="fade">
                <div>
                    <GridTable v-if="receipts && receipts.count > 0" columns="20px 2fr 1fr">
                        <template v-for="(receipt, index) in receipts.items">
                            <Tile
                                v-if="showDate(receipt.created, index - 1, receipts.items)"
                                :key="`${receipt.id}-date`"
                                no-border
                                no-padding-bottom
                            >
                                <Words bold>
                                    {{
                                        $t('pages.documents.created', {
                                            date: $d(ensureJSTimestamp(receipt.created), 'short'),
                                        })
                                    }}
                                </Words>
                            </Tile>
                            <a :key="`${receipt.id}-item`" :href="receipt.document.url" class="documents-list__link">
                                <GridTableRow class="documents-list__item--mobile grid-table__row--disabled-highlight">
                                    <div class="grid-table__cell">
                                        <DocumentIcon width="14" height="14" class="icon--inline" />
                                    </div>

                                    <div class="grid-table__cell">
                                        <Words block middle class="documents-item__words--margin">
                                            {{ $t(getDocumentTypeKey(receipt.type)) }} {{ receipt.number }}
                                        </Words>
                                        <Words v-if="receipt.paid_timestamp" muted block tiny>
                                            {{
                                                $t('pages.documents.paid', {
                                                    date: $d(receipt.paid_timestamp, 'short'),
                                                })
                                            }}
                                        </Words>
                                    </div>

                                    <div class="grid-table__cell grid-table__cell--right">
                                        <Words middle unbreakable bold block class="documents-item__words--margin">
                                            {{ $n(receipt.total, 'currency') }}
                                        </Words>
                                        <Words
                                            :red="receipt.status === 'cancelled'"
                                            :yellow="receipt.status === 'created'"
                                            :green="receipt.status === 'paid' || receipt.status === 'booked'"
                                            middle
                                            bold
                                            block
                                            tiny
                                        >
                                            {{ $t(`pages.documents.status.${receipt.status}`) }}
                                        </Words>
                                    </div>
                                </GridTableRow>
                            </a>
                        </template>
                        <MoreResultsButton
                            v-if="receipts.count - receipts.items.length > 0"
                            :result="receipts"
                            fade-out
                            class="receipts__more-results-button"
                        />
                    </GridTable>

                    <Hint v-if="receipts && receipts.count === 0" center>
                        {{ $t('pages.documents.noResults') }}
                    </Hint>
                </div>
            </transition>
        </div>
    </LayoutPage>
</template>

<script>
import _intersection from 'lodash/intersection';
import { ensureJSTimestamp } from '@/services/utils/date';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import ReceiptApi from '@/services/Api/Receipt';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
import { useDocumentTypes } from './useDocumentTypes';

import BaseButton from '@/components/Button/Button';
import DocumentsFilterSet from './components/DocumentsFilterSet';
import FilterBar from '@/components/Filter/FilterBar';
import FilterBox from '@/components/Filter/FilterBox';
import GridTable from '@/components/Table/GridTable';
import GridTableHead from '@/components/Table/GridTableHead';
import GridTableRow from '@/components/Table/GridTableRow';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Hint from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import MoreResultsButton from '@/components/Filter/MoreResultsButton';
import SortingSelectBox from '@/components/Form/SortingSelectBox';
import SortingSelectBoxMobile from '@/components/Form/SortingSelectBoxMobile';
import Tile from '@/components/Layout/Tile';
import Words from '@/components/Typography/Words';

import CancelIcon from '@/assets/icons/regular/cancel.svg';
import DocumentIcon from '@/assets/icons/micro/pdf.svg';
import InvoiceIcon from '@/assets/icons/regular/billing.svg';
import VoucherIcon from '@/assets/icons/regular/voucher.svg';

export default {
    name: 'DocumentsListPage',
    components: {
        BaseButton,
        DocumentsFilterSet,
        FilterBar,
        FilterBox,
        GridTable,
        GridTableRow,
        GridTableHead,
        HeaderBar,
        HeaderBarItem,
        Hint,
        LayoutPage,
        LoadingSpinner,
        MoreResultsButton,
        SortingSelectBox,
        SortingSelectBoxMobile,
        Tile,
        Words,

        CancelIcon,
        DocumentIcon,
        InvoiceIcon,
        VoucherIcon,
    },
    mixins: [persistentFiltersMixin],
    setup() {
        const { getDocumentTypeKey } = useDocumentTypes();
        const { selectCreditNoteDocKey } = useLegalTerms();

        return {
            selectCreditNoteDocKey,
            getDocumentTypeKey,
        };
    },
    data() {
        const whitelistedFilterFields = [
            'startCreated',
            'endCreated',
            'startPaid',
            'endPaid',
            'startTotal',
            'endTotal',
            'number',
            'transportNumber',
            'deliveryNoteNumber',
            'type',
            'status',
        ];

        if (this.$can('listOrders')) {
            whitelistedFilterFields.push('orderNumber');
        }

        return {
            isLoading: false,
            pendingTimeout: null,

            dataEndpoint: ReceiptApi,
            receipts: null,
            filter: this.assembleFilter('documents', {
                page: 1,
                perPage: 25,
            }),
            defaultFilter: {
                page: 1,
                perPage: 25,
            },
            whitelistedFilterFields,
        };
    },
    computed: {
        sortOptions() {
            return _intersection(ReceiptApi.supportedSorts, ['created', 'paid_timestamp', 'number', 'total', 'status']);
        },
    },
    created() {
        this.refreshList(true);
    },
    methods: {
        async refreshList(isInitial = false) {
            this.isLoading = true;

            // persist filter
            this.persistFilter('documents', this.filter, this.defaultFilter);

            // cancel previous request
            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.cancelSource = ReceiptApi.createCancelTokenSource();

            try {
                const result = await ReceiptApi.filter(this.filter, null, null, this.cancelSource);
                this.receipts = result;

                if (isInitial === true) {
                    this.filter = {
                        ...this.filter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }

            this.isLoading = false;
        },

        showDate(currentTimestamp, previousIndex, itemList) {
            if (previousIndex >= 0) {
                const current = this.$d(this.ensureJSTimestamp(currentTimestamp), 'short');
                const previous = this.$d(this.ensureJSTimestamp(itemList[previousIndex].created), 'short');

                return current !== previous;
            }

            return true;
        },

        ensureJSTimestamp,
    },
};
</script>

<style lang="scss">
.documents-list__item--mobile {
    border-bottom: 1px solid $color-mediumGrey;
}

.documents-item__words--margin {
    margin-bottom: 5px;
}

.documents-list__sorting {
    .filter-bar__sorting {
        width: 100%;

        .filter-box {
            width: 100%;
        }
    }
}

.documents-list__link {
    color: $color-black;
}
</style>

<template>
    <div>
        <p class="font-copy-md-strong m-0 mb-2 lg:font-copy-lg-strong" v-html="$t('pages.homeV2.orderTitle')" />
        <p class="font-copy-sm mb-[12px] lg:font-copy-md lg:mb-6">
            {{ $t('pages.homeV2.orderDescription') }}
        </p>

        <div v-if="$can('createAnyQuoteInCheckout')">
            <Alert v-if="isBlocked" type="warning" class="mx-0 my-[20px]">
                <i18n path="pages.home.client.isBlockedNotice" tag="span">
                    <template #name>
                        <span>{{ organizationName }}</span>
                    </template>
                    <template #contactLink>
                        <BaseButton @click="openGlobalPage('page.platformContact')">
                            {{ $t('pages.transportHub.blockedContactLink') }}
                        </BaseButton>
                    </template>
                </i18n>
            </Alert>
            <div
                class="grid gap-x-[20px] gap-y-[20px] lg:grid-cols-[1fr,1fr] lg:grid-rows-[1fr,1fr] xl:grid-cols-[1fr,1fr,1fr,1fr] xl:grid-rows-[1fr]"
            >
                <template v-if="$can('createCustomQuote')">
                    <OrderTypeItemV2
                        :title="$t('pages.homeV2.newOrderDelivery')"
                        icon-height="40"
                        icon-width="90"
                        icon="DeliveryIcon"
                        data-test="new-delivery-order-button"
                        @click="selectCheckout('delivery', true)"
                    />
                    <OrderTypeItemV2
                        :title="$t('pages.homeV2.newOrderShipment')"
                        icon-height="40"
                        icon-width="90"
                        icon="ShipmentOrderIcon"
                        data-test="new-shipment-order-button"
                        @click="selectCheckout('shipment', true)"
                    />

                    <OrderTypeItemV2
                        v-if="isGenericOrderAccessEnabled && isPlatformAdministrator"
                        :title="$t('pages.homeV2.orderV2')"
                        icon-height="40"
                        icon-width="90"
                        icon="DeliveryIcon"
                        @click="navigateToGenericOrder"
                    />
                </template>
            </div>
        </div>

        <div
            v-if="$can('haveClientOrders') && $can('listOrders')"
            class="mt-[20px] grid gap-x-[20px] gap-y-[20px] lg:grid-cols-[1fr,1fr] lg:grid-rows-[1fr,1fr] xl:grid-cols-[1fr,1fr,1fr,1fr] xl:grid-rows-[1fr]"
        >
            <StatusCard
                v-if="activeOrderRequestsCount"
                icon="OrderIcon"
                :title="$t('pages.homeV2.orderRequestsCardTitle')"
                :description="$tc('pages.homeV2.orderRequestsCardDescription', activeOrderRequestsCount)"
                :count="activeOrderRequestsCount"
                @click="goToOrderListPage('requests')"
            />
            <StatusCard
                v-if="activeOrdersCount"
                icon="OrderIcon"
                data-test="active-order-count-button"
                :title="$t('pages.homeV2.activeOrderCardTitle')"
                :description="$tc('pages.homeV2.activeOrderCardDescription', activeOrdersCount)"
                :count="activeOrdersCount"
                @click="goToOrderListPage('inProgress')"
            />
            <StatusCard
                v-if="activeDeliveryTransportsCount"
                icon="TransportIcon"
                :title="$t('pages.homeV2.activeDeliveryTransportCardTitle')"
                :description="$tc('pages.homeV2.activeDeliveryTransportCardDescription', activeDeliveryTransportsCount)"
                :count="activeDeliveryTransportsCount"
                @click="goToOrderTransportList('delivery')"
            />
            <StatusCard
                v-if="activeShipmentTransportsCount"
                icon="TransportIcon"
                :title="$t('pages.homeV2.activeShipmentCardTitle')"
                :description="$tc('pages.homeV2.activeShipmentCardDescription', activeShipmentTransportsCount)"
                :count="activeShipmentTransportsCount"
                @click="goToOrderTransportList('shipment')"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import OrderApi from '@/services/Api/Order';
import { clientQuoteApi } from '@/services/Api/Quote';
import TransportListApi from '@/services/Api/TransportList';
import _get from 'lodash/get';
import endOfToday from 'date-fns/end_of_today';
import pageMixin from '@/plugins/mixins/pageMixin';
import startOfToday from 'date-fns/start_of_today';
import { assembleQueryFilter } from '@/plugins/mixins/persistentFiltersMixin';
import { ensurePHPTimestamp } from '@/services/utils/date';
import { mapGetters } from 'vuex';
import { navigationFailure } from '@/services/utils/router';

import Alert from '@/components/Alert';
import BaseButton from '@/components/Button/Button';
import OrderTypeItemV2 from '@/components/List/OrderTypeItemV2';
import StatusCard from '@/pages/Home/components/StatusCard';
import { useLd } from '@/services/LaunchDarkly';
import { useInAppBrowser } from '@/utils/composition-helper';

const transportListApi = new TransportListApi('client');

export default defineComponent({
    name: 'HomeSectionOrder',
    components: {
        Alert,
        BaseButton,
        OrderTypeItemV2,
        StatusCard,
    },
    mixins: [pageMixin],
    setup() {
        const isGenericOrderAccessEnabled = useLd('enable_generic_order_access');
        const { openInAppBrowser } = useInAppBrowser();

        return {
            isGenericOrderAccessEnabled,
            openInAppBrowser,
        };
    },
    data() {
        return {
            activeOrderRequestsCount: null,
            activeOrderRequestsCountCancelSource: null,
            activeOrdersCount: null,
            activeOrdersCountCancelSource: null,
            activeDeliveryTransportsCount: null,
            activeDeliveryTransportsCountCancelSource: null,
            activeShipmentTransportsCount: null,
            activeShipmentTransportsCountCancelSource: null,
        };
    },
    computed: {
        ...mapGetters('platform', ['orderPhoneNumber', 'genericOrderUrl']),
        ...mapGetters('user', {
            isBlocked: 'isBlocked',
            organizationName: 'organizationName',
            isPlatformAdministrator: 'isPlatformAdministrator',
        }),
    },
    mounted() {
        this.refreshData();
        // Polling of the transport list endpoints here is temporarily disabled to reduce load on our database.
        // Corresponding incident: https://schuettflix.atlassian.net/wiki/spaces/SCHUT/pages/571572225/2022-06-07+-+System+downtime
        // this.$gracefulInterval(this.refreshData.bind(this), POLLING_INTERVAL);
    },
    beforeDestroy() {
        this.activeOrderRequestsCountCancelSource &&
            this.activeOrderRequestsCountCancelSource.cancel('canceled-previous-call');
        this.activeOrdersCountCancelSource && this.activeOrdersCountCancelSource.cancel('canceled-previous-call');
        this.activeDeliveryTransportsCountCancelSource &&
            this.activeDeliveryTransportsCountCancelSource.cancel('canceled-previous-call');
        this.activeShipmentTransportsCountCancelSource &&
            this.activeShipmentTransportsCountCancelSource.cancel('canceled-previous-call');
    },
    methods: {
        async refreshData() {
            if (!this.$can('haveClientOrders') || !this.$can('listOrders')) {
                return;
            }

            this.refreshActiveOrderRequestsCount();
            this.refreshActiveOrdersCount();
            this.refreshActiveDeliveryTransportsCount();
            this.refreshActiveShipmentTransportsCount();
        },

        async refreshActiveOrderRequestsCount() {
            if (!this.$can('listQuotes')) {
                return;
            }

            this.activeOrderRequestsCountCancelSource &&
                this.activeOrderRequestsCountCancelSource.cancel('canceled-previous-call');
            this.activeOrderRequestsCountCancelSource = clientQuoteApi.createCancelTokenSource();

            try {
                const response = await clientQuoteApi.filter(
                    {
                        status: ['pending'],
                        countOnly: true,
                    },
                    null,
                    null,
                    this.activeOrderRequestsCountCancelSource
                );

                this.activeOrderRequestsCount = _get(response, 'count', 0);
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }
        },

        async refreshActiveOrdersCount() {
            this.activeOrdersCountCancelSource && this.activeOrdersCountCancelSource.cancel('canceled-previous-call');
            this.activeOrdersCountCancelSource = OrderApi.createCancelTokenSource();

            try {
                const response = await OrderApi.filter(
                    {
                        status: ['new', 'in_progress'],
                        countOnly: true,
                    },
                    null,
                    null,
                    this.activeOrdersCountCancelSource
                );

                this.activeOrdersCount = _get(response, 'count', 0);
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }
        },

        async refreshActiveDeliveryTransportsCount() {
            this.activeDeliveryTransportsCountCancelSource &&
                this.activeDeliveryTransportsCountCancelSource.cancel('canceled-previous-call');
            this.activeDeliveryTransportsCountCancelSource = transportListApi.createCancelTokenSource();

            try {
                const response = await transportListApi.filter(
                    {
                        startShippingWindow: ensurePHPTimestamp(startOfToday() * 1),
                        endShippingWindow: ensurePHPTimestamp(endOfToday() * 1),
                        type: ['delivery'],
                        status: ['new'],
                        countOnly: true,
                    },
                    null,
                    null,
                    this.activeDeliveryTransportsCountCancelSource
                );

                this.activeDeliveryTransportsCount = _get(response, 'count', 0);
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }
        },

        async refreshActiveShipmentTransportsCount() {
            this.activeShipmentTransportsCountCancelSource &&
                this.activeShipmentTransportsCountCancelSource.cancel('canceled-previous-call');
            this.activeShipmentTransportsCountCancelSource = transportListApi.createCancelTokenSource();

            try {
                const response = await transportListApi.filter(
                    {
                        startShippingWindow: ensurePHPTimestamp(startOfToday() * 1),
                        endShippingWindow: ensurePHPTimestamp(endOfToday() * 1),
                        type: ['shipment'],
                        status: ['new'],
                        countOnly: true,
                    },
                    null,
                    null,
                    this.activeShipmentTransportsCountCancelSource
                );

                this.activeShipmentTransportsCount = _get(response, 'count', 0);
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }
        },

        selectCheckout(type, isCustom) {
            this.$store.dispatch('basket/init', { type, isCustom });
            this.$store.commit('projectPosition/RESET');

            let routeName;

            if (isCustom) {
                routeName = this.$root.findRouteName(`checkout-custom-${type}`);
            } else {
                routeName = this.$root.findRouteName(`checkout-${type}`);
            }

            this.$router
                .push({
                    name: routeName,
                })
                .catch(navigationFailure);
        },

        navigateToGenericOrder() {
            this.openInAppBrowser(this.genericOrderUrl);
        },
        openPhoneCheckout() {
            window.location.href = `tel:${this.orderPhoneNumber}`;
        },

        goToCheckoutEntryPage() {
            this.$router.push({ name: this.$root.findRouteName('order__checkout') }).catch(navigationFailure);
        },

        goToOrderListPage(predefinedTab) {
            const query = {};

            if (predefinedTab === 'requests') {
                query.quote = assembleQueryFilter({
                    status: ['pending'],
                });
            }

            this.$router
                .push({
                    name: this.$root.findRouteName('order-list'),
                    params: { predefinedTab },
                    query,
                })
                .catch(navigationFailure);
        },

        goToOrderTransportList(type) {
            this.$router
                .push({
                    name: this.$root.findRouteName('order__transport-list'),
                    query: {
                        transportForced: assembleQueryFilter({
                            status: ['new'],
                        }),
                        transport: assembleQueryFilter({
                            startShippingWindow: ensurePHPTimestamp(startOfToday() * 1),
                            endShippingWindow: ensurePHPTimestamp(endOfToday() * 1),
                            type: [type],
                        }),
                    },
                })
                .catch(navigationFailure);
        },
    },
});
</script>

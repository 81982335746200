<template>
    <div class="category-management">
        <GridRow :count="4" spacing="large">
            <ButtonGroup v-can:createCategories class="span-3" inline>
                <Button
                    primary
                    data-test="create-category-button"
                    @click="$router.push({ name: $root.findRouteName(categoryAddRoute) })"
                >
                    {{ $t('pages.categoryListPage.newCategory') }}
                </Button>
            </ButtonGroup>
            <DataTable clickable class="span-3" data-test="data-table">
                <template #header>
                    <tr>
                        <th>{{ $t('pages.categoryListPage.table.header.category') }}</th>
                        <th>{{ $t('pages.categoryListPage.table.header.status') }}</th>
                    </tr>
                </template>

                <tr
                    v-for="category in categories"
                    :key="category.id"
                    @click="
                        $router
                            .push({
                                name: $root.findRouteName(categoryEditRoute),
                                params: { id: category.id },
                            })
                            .catch(() => {
                                // Error handled somewere
                            })
                    "
                >
                    <td>
                        <Thumbnail
                            :src="category.image && category.image.url"
                            inline
                            style="vertical-align: middle; margin-right: 10px"
                        />
                        {{ revertLocalizedValue(category.name) }}
                    </td>
                    <td>
                        {{
                            category.isActive
                                ? $t('pages.categoryListPage.table.body.status.active')
                                : $t('pages.categoryListPage.table.body.status.inactive')
                        }}
                    </td>
                </tr>
            </DataTable>

            <Tree :tree="categoryTree" :click-handler="editCategory" />
        </GridRow>

        <Flyout :route="categoryAddRoute" no-header @closed="loadCategories()" />
        <Flyout :route="categoryEditRoute" no-header @closed="loadCategories()" />
    </div>
</template>

<script>
import { revertLocalizedValue, revertLocalizedCollectionValues } from '@/services/utils/localization';
import { CONSTRUCTION_PRODUCT, WASTE_PRODUCT } from '@/constants/productTypes';

import Category from '@/services/Api/Platform/Category';
import Tree from '@/components/Tree/Tree';
import TreeNode from '@/components/Tree/TreeNode';
import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Flyout from '@/components/Layout/Flyout';
import GridRow from '@/components/Layout/GridRow';
import DataTable from '@/components/Table/DataTable';
import Thumbnail from '@/components/Thumbnail';

const routeMap = {
    [CONSTRUCTION_PRODUCT]: 'construction',
    [WASTE_PRODUCT]: 'waste',
};

export default {
    name: 'CategoryListPage',
    components: {
        Tree,
        Button,
        ButtonGroup,
        Flyout,
        GridRow,
        DataTable,
        Thumbnail,
    },
    props: {
        listType: {
            type: String,
            validator: v => [CONSTRUCTION_PRODUCT, WASTE_PRODUCT].includes(v),
            default: CONSTRUCTION_PRODUCT,
        },
    },
    data() {
        return {
            categories: [],
        };
    },
    computed: {
        categoryTree() {
            return TreeNode.factory(revertLocalizedCollectionValues(this.categories, ['name']));
        },
        categoryEditRoute() {
            return this.$root.findRouteName(`${routeMap[this.listType]}-category-edit`);
        },
        categoryAddRoute() {
            return this.$root.findRouteName(`${routeMap[this.listType]}-category-add`);
        },
    },
    created() {
        this.loadCategories();
    },
    methods: {
        loadCategories() {
            Category.getCategoryByType(this.listType).then(categories => {
                this.categories = categories;
            });
        },
        editCategory(category) {
            if (!this.$can('updateCategories')) return;

            this.$router
                .push({
                    name: this.$root.findRouteName(this.categoryEditRoute),
                    params: {
                        id: category.id,
                    },
                })
                .catch(() => {
                    // Error handled somewere
                });
        },
        revertLocalizedValue,
    },
};
</script>

<style lang="scss">
.category-management {
    margin-top: 20px;
}

.category-management__table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #cbcbcb;
}

.category-management__td,
.category-management__th {
    border: 0;
    border-bottom: 1px solid #cbcbcb;
    font-size: inherit;
    margin: 0;
    overflow: visible;
    padding: 0.5em 10px;

    &:first-child {
        border-left-width: 0;
    }
}

.category-management__tbody > tr:last-child > td {
    border-bottom-width: 0;
}

.category-management__thead {
    background-color: #e0e0e0;
    color: #000;
    text-align: left;
    vertical-align: bottom;
}
</style>

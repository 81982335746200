<template>
    <div class="grid-table__label" @click="$emit('click', $event)">
        <slot />
        <slot name="action" @click="$emit('click', $event)" />
    </div>
</template>

<script>
export default {
    name: 'GridTableLabel',
};
</script>

import store from '@/store';
import { setCurrency } from '@/i18n/index';
/**
 * Prepare i18n initialization
 */
export function i18nSetup() {
    // ============================================================
    // Watch for store update to change currency format for market
    // ============================================================
    store.watch(state => state.platform.info?.market?.currencyCode, (newValue, oldValue) => {
        if (!newValue || newValue === oldValue)
            return;
        setCurrency(newValue);
    }, {
        immediate: true,
    });
}

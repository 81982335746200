import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from '@/constructionProjects/components/icons/SearchIcon';
export const SearchHeader = ({ searchQuery, onChangeSearchQuery, className }) => {
    const { t } = useTranslation();
    const handleInputKeyUp = event => {
        if (event.key === 'Enter') {
            event.currentTarget.blur();
        }
    };
    return (<div className={clsx(['flex h-full items-center rounded border border-divider bg-surface px-4', className])} data-test="search-input">
            <SearchIcon size="sm"/>
            <input className="font-copy-md mx-2 my-2 h-full flex-1 outline-none placeholder:font-copy-md placeholder:text-subdued" placeholder={t('pages.constructionProject.search.placeholder')} value={searchQuery} onKeyUp={handleInputKeyUp} onChange={e => onChangeSearchQuery(e.target.value)} autoFocus/>
        </div>);
};

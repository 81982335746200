import { useTranslation } from 'react-i18next';
import { usePaymentTerms } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/hooks/usePaymentTerms';
import { useTaxClasses } from '@/modules/constructionProjects/pages/Admin/ConstructionProjectAdminGenericPositionDetailsPage/components/PositionOverviewContent/hooks/useTaxClasses';
import { formatCurrency } from '@/services/utils';
const BILLING_TYPE_LABEL = {
    CREDIT_NOTE: 'billingMethod.CREDIT_NOTE',
    INVOICE: 'billingMethod.INVOICE',
    PARTNER_INVOICE: 'billingMethod.PARTNER_INVOICE',
};
export function ProductSummary(props) {
    const { t } = useTranslation();
    const financeSettingsForTransaction = props.financeSettings.find(it => it.transactionalRole === props.transaction.transactionalRole);
    const { data: paymentTermsResponse } = usePaymentTerms();
    const { data: taxClassesResponse } = useTaxClasses(props.marketCode);
    if (!paymentTermsResponse || !taxClassesResponse) {
        return null;
    }
    const matchingPaymentTerm = financeSettingsForTransaction?.paymentTermId
        ? paymentTermsResponse.paymentTerms.find(it => it.id === financeSettingsForTransaction?.paymentTermId)
        : null;
    const paymentTermDescription = matchingPaymentTerm?.description;
    const billingMethodName = financeSettingsForTransaction?.billingMethod
        ? t(BILLING_TYPE_LABEL[financeSettingsForTransaction?.billingMethod])
        : null;
    return (<div className="font-copy-md divide-y divide-divider">
            <div className="flex flex-row justify-between gap-4 py-2">
                <div>{props.transaction.productName}</div>
                <div className="flex flex-row gap-4">
                    <span>{taxClassesResponse.find(it => it.name === props.transaction.unitTaxClassId)?.rate}%</span>
                    <span>
                        {formatCurrency(props.transaction.unitPriceAmount, props.transaction.unitPriceCurrency)}/
                        {t(`units.${props.transaction.unit.toLowerCase()}`)}
                    </span>
                </div>
            </div>
            <div className="flex flex-row justify-between gap-4 py-2">
                <div className="text-subdued">{t('pages.genericPosition.paymentTerms')}</div>
                <div>{paymentTermDescription ? paymentTermDescription : '-'}</div>
            </div>
            <div className="flex flex-row justify-between gap-4 py-2">
                <div className="text-subdued">{t('pages.genericPosition.billingMethod')}</div>
                <div>{billingMethodName ? billingMethodName : '-'}</div>
            </div>
        </div>);
}

import { revertLocalizedValue } from '@/services/utils/localization';
import { toTonPrice } from '@/services/utils/units';

/** Class representing quotation prices view model */
export default class QuotationPricesView {
    /**
     * Create a view model
     */
    constructor() {
        this._material = null;
        this._transports = null;
        this._shipping = null;
    }

    /**
     * Create a view model from project position payload
     * @param {object} data
     * @returns {QuotationPricesView}
     */
    static createFromProjectPositionPayload(data) {
        if (!data) return null;

        const quotationPrices = new QuotationPricesView();

        quotationPrices.material = PricePositionView.create({
            purchasePrice: toTonPrice(data.materialPurchaseUnitPrice),
            retailPrice: toTonPrice(data.materialRetailUnitPrice),
            retailPriceWithStateWasteCompanyFee: toTonPrice(data.materialRetailUnitPriceWithStateWasteCompanyFee),
            title: revertLocalizedValue(data.product?.productName),
        });

        quotationPrices.transports = data.vehicleClasses?.map(v => {
            /**
             * NEED TO BE FIX: ensure that vehicleClass object is available for every
             * project position
             */
            return PricePositionView.create({
                purchasePrice: toTonPrice(v.purchasePrice),
                retailPrice: toTonPrice(v.retailPrice),
                title: v.vehicleClass?.name || 'NEEDS TO BE FIXED: missing vehicleClass object',
            });
        });

        quotationPrices.shipping = PricePositionView.create({
            purchasePrice: data.shippingPurchasePrice,
            retailPrice: data.shippingRetailPrice,
        });

        return quotationPrices;
    }

    /**
     * Create view representation
     *
     * @param data
     * @return {null|QuotationPricesView}
     */
    static create(data) {
        if (!data) return null;

        const view = new QuotationPricesView();

        view.material = PricePositionView.create(data.material);
        view.transports = data.transports?.map(item => PricePositionView.create(item));
        view.shipping = PricePositionView.create(data.shipping);

        return view;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            material: this.material?.unfold(),
            transports: this.transports?.map(item => item.unfold()),
            shipping: this.shipping?.unfold(),
        };
    }

    /**
     * Clone the current view
     * @return {QuotationPricesView}
     */
    clone() {
        return QuotationPricesView.create(this.unfold());
    }

    /**
     * Get material price position
     * @returns {PricePositionView|null}
     */
    get material() {
        return this._material;
    }

    /**
     * Set material price position
     * @param {PricePositionView} value
     */
    set material(value) {
        this._material = value ?? null;
    }

    /**
     * Get transports price position
     * @returns {PricePositionView[]|null}
     */
    get transports() {
        return this._transports;
    }

    /**
     * Set transports price position
     * @param {PricePositionView[]} value
     */
    set transports(value) {
        this._transports = value ?? null;
    }

    /**
     * Get shipping price position
     * @returns {PricePositionView|null}
     */
    get shipping() {
        return this._shipping;
    }

    /**
     * Set shipping price position
     * @param {PricePositionView} value
     */
    set shipping(value) {
        this._shipping = value ?? null;
    }
}

/** Class representing a price position */
export class PricePositionView {
    /**
     * Create a view model
     */
    constructor() {
        this._purchasePrice = null;
        this._retailPrice = null;
        this._retailPriceWithStateWasteCompanyFee = null;
        this._title = null;
    }

    /**
     * Create view representation
     *
     * @param data
     * @return {null|PricePositionView}
     */
    static create(data) {
        if (!data) return null;

        const view = new PricePositionView();

        view.purchasePrice = data.purchasePrice;
        view.retailPrice = data.retailPrice;
        view.retailPriceWithStateWasteCompanyFee = data.retailPriceWithStateWasteCompanyFee;
        view.title = data.title;

        return view;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            purchasePrice: this.purchasePrice,
            retailPrice: this.retailPrice,
            retailPriceWithStateWasteCompanyFee: this.retailPriceWithStateWasteCompanyFee,
            title: this.title,
        };
    }

    /**
     * Clone the current view
     * @return {PricePositionView}
     */
    clone() {
        return PricePositionView.create(this.unfold());
    }

    /**
     * Get purchase price
     * @returns {number|null}
     */
    get purchasePrice() {
        return this._purchasePrice;
    }

    /**
     * Set purchase price
     * @param {number} value
     */
    set purchasePrice(value) {
        this._purchasePrice = value ?? null;
    }

    /**
     * Get retail price
     * @returns {number|null}
     */
    get retailPrice() {
        return this._retailPrice;
    }

    /**
     * Set retail price
     * @param {number} value
     */
    set retailPrice(value) {
        this._retailPrice = value ?? null;
    }

    /**
     * Get waste retail price including state waste company fee
     * @returns {number|null}
     */
    get retailPriceWithStateWasteCompanyFee() {
        return this._retailPriceWithStateWasteCompanyFee;
    }

    /**
     * Set waste retail price including state waste company fee
     * @param {number} value
     */
    set retailPriceWithStateWasteCompanyFee(value) {
        this._retailPriceWithStateWasteCompanyFee = value ?? null;
    }

    /**
     * Get title
     * @returns {string|null}
     */
    get title() {
        return this._title;
    }

    /**
     * Set title
     * @param {string} value
     */
    set title(value) {
        this._title = value ?? null;
    }
}

import ConfirmationGatePage from '@/pages/Legal/ConfirmationGatePage';

export default {
    path: '/legal',
    name: 'legal-check',
    component: ConfirmationGatePage,
    meta: {
        requiresAuth: true,
    },
};

class Instance {
    instance = null;
    get() {
        return this.instance;
    }
    set(value) {
        this.instance = value;
    }
}
export const storeInstance = new Instance();
export const routesInstance = new Instance();
export const routerInstance = new Instance();

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { CheckIcon } from '@schuettflix/icons-react';
export const TrafficabilitySelection = React.forwardRef(
// eslint-disable-next-line @typescript-eslint/no-unused-vars
({ value, options, onChange, disabled = false }, _ref) => {
    const { t, i18n } = useTranslation();
    const [selectedValue, setSelectedValue] = useState(value);
    const handleClick = (selection) => {
        if (disabled) {
            return;
        }
        setSelectedValue(selection);
        onChange(selection);
    };
    const formatKgToTons = (value) => {
        const locale = i18n.resolvedLanguage;
        return (value / 1000).toLocaleString(locale);
    };
    const isHighlighted = (selection) => {
        return selectedValue && selectedValue >= selection;
    };
    return (<>
                <div className="grid grid-cols-4 gap-6">
                    {options?.map(trafficability => (<div key={trafficability} onClick={() => handleClick(trafficability)} data-test="trafficability-selection" className={clsx('relative flex h-[56px] w-[56px] items-center justify-center place-self-center rounded-full border-[5px] bg-surface transition-all', {
                'cursor-pointer ': !disabled,
                'cursor-not-allowed': disabled,
            }, isHighlighted(trafficability) ? 'border-brand' : 'border-disabled')}>
                            <p className="font-copy-md-strong">
                                {formatKgToTons(trafficability)}
                                {t('units.ton')}
                            </p>
                            {isHighlighted(trafficability) && (<div className="absolute -right-[13px] -top-[9px] flex h-6 w-6 items-center justify-center rounded-full bg-surface-active">
                                    <CheckIcon on="primary"/>
                                </div>)}
                        </div>))}
                </div>
            </>);
});
TrafficabilitySelection.displayName = 'TrafficabilitySelection';

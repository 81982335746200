import React from 'react';
import { TransparentFlagIcon } from '@/constructionProjects/components/icons/TransparentFlagIcon';
import { useTranslation } from 'react-i18next';
import { Tag } from '@schuettflix/react-components';
export const LocationCard = ({ location, onClick }) => {
    const { t } = useTranslation();
    return (<div onClick={onClick} className="flex cursor-pointer items-start rounded bg-surface p-4 shadow-low" data-test="location-card">
            <TransparentFlagIcon className="mt-2 h-6 w-6"/>
            <div className="ml-4 mt-2 flex-1 overflow-hidden">
                <p className="font-copy-md-strong line-clamp-2" title={location.name}>
                    {location.name}
                </p>
                <p className="font-copy-sm mt-1">
                    {[
            [location.street, location.addressNumber].filter(Boolean).join(' '),
            [location.zip, location.city].filter(Boolean).join(' '),
        ]
            .filter(Boolean)
            .join(', ')}
                </p>
                <p className="font-copy-sm text-subdued">
                    {t('pages.constructionProject.projectLocations.locationCard.openLocationCode', {
            code: location.openLocationCode,
        })}
                </p>
                <Tag className="mt-2" subdued label={t('pages.constructionProject.projectLocations.locationCard.vehicleAccessibility', {
            tons: location.vehicleAccessibility / 1000,
        })}/>
            </div>
        </div>);
};

export const TRANSPORT_TYPE_DEFAULT = 'all';
export const TRANSPORT_TYPE_DELIVERY = 'delivery';
export const TRANSPORT_TYPE_PICKUP = 'pickup';
export const TRANSPORT_TYPE_SHIPMENT = 'shipment';
export const TRANSPORT_TYPE_DISPOSAL = 'disposal';
export const TRANSPORT_TYPE_DISPOSAL_HAZARDOUS_WASTE = 'dangerousWaste';
export const TRANSPORT_TYPE_DISPOSAL_NON_HAZARDOUS_WASTE = 'waste';
export const TRANSPORT_TYPE = {
    DEFAULT: TRANSPORT_TYPE_DEFAULT,
    DELIVERY: TRANSPORT_TYPE_DELIVERY,
    PICKUP: TRANSPORT_TYPE_PICKUP,
    SHIPMENT: TRANSPORT_TYPE_SHIPMENT,
    DISPOSAL: TRANSPORT_TYPE_DISPOSAL,
};
export const isDisposalTransport = (type) => [TRANSPORT_TYPE_DISPOSAL_HAZARDOUS_WASTE, TRANSPORT_TYPE_DISPOSAL_NON_HAZARDOUS_WASTE].includes(type);

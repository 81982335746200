<template>
    <div>
        <Words v-if="headline" block bold spaced-bottom>{{ headline }}</Words>
        <Card no-padding spaceless>
            <div class="checkout-additional-information__tile-button">
                <Thumbnail
                    v-if="value"
                    slot="left"
                    :src="value.url"
                    :inline="false"
                    class="checkout-additional-information__tile-thumbnail"
                    modal
                />
                <div
                    v-else
                    slot="left"
                    class="checkout-additional-information__tile-button-placeholder"
                    @click="handleUploadImage"
                >
                    <Words small centered>{{ $t('pages.checkout.additionalInformation.photoPlaceholder') }}</Words>
                </div>

                <BaseButton
                    arrow-right
                    place-left
                    class="checkout-additional-information__tile-button-text"
                    @click="handleUploadImage"
                >
                    {{
                        value
                            ? $t('pages.checkout.additionalInformation.photoButtonTextChange')
                            : $t('pages.checkout.additionalInformation.photoButtonText')
                    }}
                </BaseButton>

                <BaseButton v-if="value" slot="right" @click.prevent.stop="deleteUploadedImage">
                    <DeleteIcon />
                </BaseButton>
            </div>
        </Card>
        <div class="checkout-additional-information__tile-button-hidden-img-uploader">
            <ImageUpload
                ref="imageUploader"
                :disable-upload="true"
                @error="handleUploadError"
                @input="handleFileSelect"
            />
        </div>
    </div>
</template>

<script>
import eventHubMixin from '@/plugins/mixins/eventHubMixin';

import { getCurrentInstance, watch } from 'vue';
import { useImageUpload } from '@/services/useClientFile';
import Toaster from '@/services/Toaster';

import Words from '@/components/Typography/Words';
import Card from '@/components/Layout/Card';
import Thumbnail from '@/components/Thumbnail';
import BaseButton from '@/components/Button/Button';
import ImageUpload from '@/components/Form/ImageUpload';

import DeleteIcon from '@/assets/icons/regular/garbage.svg';

export default {
    name: 'ShipmentImageUpload',

    components: {
        Words,
        Card,
        Thumbnail,
        BaseButton,
        ImageUpload,

        DeleteIcon,
    },

    mixins: [eventHubMixin],

    props: {
        value: {
            type: Object,
            default: null,
        },
        headline: {
            type: String,
            default: null,
        },
    },

    setup(props, { emit }) {
        const { uploadImage, onEverySuccess, onEveryError, progress } = useImageUpload();

        const instance = getCurrentInstance().proxy;

        onEverySuccess(data => {
            emit('input', data);
            instance.$eventHub.$emit('imageEditorClose');
        });

        onEveryError(error => {
            instance.$eventHub.$emit('imageEditorClose');
            Toaster.error(instance.$t(error));
        });

        watch(progress, value => instance.$eventHub.$emit('imageEditorUploadProgress', value));

        return {
            uploadImage,
        };
    },

    data() {
        return {
            fileError: null,
        };
    },

    created() {
        this.subscribe('image-editor-upload', file => {
            this.uploadImage({
                file,
                meta: {
                    productUseCase: 'location_image',
                },
            });
        });
    },

    methods: {
        handleImageUploadErrors(err) {
            this.$logger().log(err);
        },

        handleFileSelect(file) {
            if (!file) return;
            this.$eventHub.$emit('image-editor-open', file);
        },

        handleUploadImage() {
            this.$refs.imageUploader.triggerFileSelect();
            this.fileError = null;
        },

        deleteUploadedImage() {
            this.$emit('input', null);
        },

        handleUploadError(error) {
            this.fileError = error;
        },
    },
};
</script>

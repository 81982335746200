import OrganizationView from '@/models/OrganizationView';
import UserView from '@/models/UserView';
import { ensureJSTimestamp } from '@/services/utils/date';

/**
 * Class representing a transport view model
 */
export default class TransportView {
    /**
     * Create a view model from data source
     * @param {object} data
     */
    static create(data) {
        if (!data) return null;

        const view = new TransportView();

        view.id = data.id;
        view.number = data.number;
        view.type = data.type;
        view.created = data.created;
        view.status = data.status;
        view.statusLabel = data.statusLabel;
        view.vehicleNumAxes = data.vehicleNumAxes;
        view.vehicleName = data.vehicleName;
        view.qty = data.qty;
        view.totalLoading = data.totalLoading;
        view.driver = UserView.create(data.driver);
        view.carrier = OrganizationView.create(data.carrier);
        view.arrivalTime = data.arrivalTime;
        view.shippingMethod = data.shippingMethod;
        view.shippingDate = data.shippingDate;
        view.shippingWindowStart = data.shippingWindowStart;
        view.shippingWindowEnd = data.shippingWindowEnd;
        view.plannedDeliveryTime = data.plannedDeliveryTime;
        view.plannedDeliveryRange = data.plannedDeliveryRange;
        view.destinationWaitingTimestamp = data.destinationWaitingTimestamp;
        view.confirmationPendingTimestamp = data.confirmationPendingTimestamp;
        view.deliveredTimestamp = data.deliveredTimestamp;
        view.failedTimestamp = data.failedTimestamp;
        view.confirmLoadingTimestamp = data.confirmLoadingTimestamp;
        view.confirmUnloadingTimestamp = data.confirmUnloadingTimestamp;
        view.loadingDateFrom = data.loadingDateFrom;
        view.loadingDateTo = data.loadingDateTo;
        view.loadingTimeFrom = data.loadingTimeFrom;
        view.loadingTimeTo = data.loadingTimeTo;
        view.unloadingDateFrom = data.unloadingDateFrom;
        view.unloadingDateTo = data.unloadingDateTo;
        view.unloadingTimeFrom = data.unloadingTimeFrom;
        view.unloadingTimeTo = data.unloadingTimeTo;
        view.weighingStartedTimestamp = data.weighingStartedTimestamp;
        view.weighingCompletedTimestamp = data.weighingCompletedTimestamp;
        view.projectPositionId = data.projectPositionId;
        view.validity = data.validity;

        return view;
    }

    /**
     * Create a view model from lineItem
     * @param {object} data
     */
    static createFromLineItem(data) {
        if (!data) return null;

        const view = new TransportView();

        view.id = data.transportId;
        view.number = data.transportNumber;
        view.type = data.type;
        view.status = data.transportStatus;
        view.statusLabel = data.transportStatus;
        view.vehicleNumAxes = data.vehicleNumAxes;
        view.vehicleName = data.vehicleName;
        view.vehicleClassIcon = data.vehicleClassIcon;
        view.qty = data.loadedQty;
        view.validity = data.validity;

        return view;
    }

    /**
     * Create a view model
     */
    constructor() {
        /**
         * @type {?number}
         * @private
         */
        this._id = null;
        /**
         * @type {?string}
         * @private
         */
        this._number = null;
        /**
         * @type {?string}
         * @private
         */
        this._type = null;
        /**
         * @type {?number}
         * @private
         */
        this._created = null;
        /**
         * @type {?string}
         * @private
         */
        this._status = null;
        /**
         * @type {?string}
         * @private
         */
        this._statusLabel = null;
        /**
         * @type {?number}
         * @private
         */
        this._vehicleNumAxes = null;
        /**
         * @type {?string}
         * @private
         */
        this._vehicleName = null;
        /**
         * @type {?string}
         * @private
         */
        this._vehicleClassIcon = null;
        /**
         * @type {?number}
         * @private
         */
        this._qty = null;
        /**
         * @type {?number}
         * @private
         */
        this._totalLoading = null;
        /**
         * @type {?UserView}
         * @private
         */
        this._driver = null;
        /**
         * @type {?OrganizationView}
         * @private
         */
        this._carrier = null;
        /**
         * @type {?number}
         * @private
         */
        this._arrivalTime = null;
        /**
         * @type {?string}
         * @private
         */
        this._shippingMethod = null;
        /**
         * @type {?number}
         * @private
         */
        this._shippingDate = null;
        /**
         * @type {?number}
         * @private
         */
        this._shippingWindowStart = null;
        /**
         * @type {?number}
         * @private
         */
        this._shippingWindowEnd = null;
        /**
         * @type {?number}
         * @private
         */
        this._plannedDeliveryTime = null;
        /**
         * @type {?number}
         * @private
         */
        this._plannedDeliveryRange = null;
        /**
         * @type {?number}
         * @private
         */
        this._destinationWaitingTimestamp = null;
        /**
         * @type {?number}
         * @private
         */
        this._confirmationPendingTimestamp = null;
        /**
         * @type {?number}
         * @private
         */
        this._deliveredTimestamp = null;
        /**
         * @type {?number}
         * @private
         */
        this._failedTimestamp = null;
        /**
         * @type {?number}
         * @private
         */
        this._confirmLoadingTimestamp = null;
        /**
         * @type {?number}
         * @private
         */
        this._confirmUnloadingTimestamp = null;
        /**
         * @type {?number}
         * @private
         */
        this._loadingDateFrom = null;
        /**
         * @type {?number}
         * @private
         */
        this._loadingDateTo = null;
        /**
         * @type {?string}
         * @private
         */
        this._loadingTimeFrom = null;
        /**
         * @type {?string}
         * @private
         */
        this._loadingTimeTo = null;
        /**
         * @type {?number}
         * @private
         */
        this._unloadingDateFrom = null;
        /**
         * @type {?number}
         * @private
         */
        this._unloadingDateTo = null;
        /**
         * @type {?string}
         * @private
         */
        this._unloadingTimeFrom = null;
        /**
         * @type {?string}
         * @private
         */
        this._unloadingTimeTo = null;
        /**
         * @type {?number}
         * @private
         */
        this._weighingStartedTimestamp = null;
        /**
         * @type {?number}
         * @private
         */
        this._weighingCompletedTimestamp = null;
        /**
         * @type {?number}
         * @private
         */
        this._projectPositionId = null;
        /**
         * @type {?number}
         * @private
         */
        this._validity = null;
    }

    /**
     * Unfold view
     * @return {object}
     */
    unfold() {
        return {
            id: this.id,
            number: this.number,
            type: this.type,
            created: this.created,
            status: this.status,
            statusLabel: this.statusLabel,
            vehicleNumAxes: this.vehicleNumAxes,
            vehicleName: this.vehicleName,
            qty: this.qty,
            totalLoading: this.totalLoading,
            driver: this.driver?.unfold(),
            carrier: this.carrier?.unfold(),
            arrivalTime: this.arrivalTime,
            shippingMethod: this.shippingMethod,
            shippingDate: this.shippingDate,
            shippingWindowStart: this.shippingWindowStart,
            shippingWindowEnd: this.shippingWindowEnd,
            plannedDeliveryTime: this.plannedDeliveryTime,
            plannedDeliveryRange: this.plannedDeliveryRange,
            destinationWaitingTimestamp: this.destinationWaitingTimestamp,
            confirmationPendingTimestamp: this.confirmationPendingTimestamp,
            deliveredTimestamp: this.deliveredTimestamp,
            failedTimestamp: this.failedTimestamp,
            confirmLoadingTimestamp: this.confirmLoadingTimestamp,
            confirmUnloadingTimestamp: this.confirmUnloadingTimestamp,
            loadingDateFrom: this.loadingDateFrom,
            loadingDateTo: this.loadingDateTo,
            loadingTimeFrom: this.loadingTimeFrom,
            loadingTimeTo: this.loadingTimeTo,
            unloadingDateFrom: this.unloadingDateFrom,
            unloadingDateTo: this.unloadingDateTo,
            unloadingTimeFrom: this.unloadingTimeFrom,
            unloadingTimeTo: this.unloadingTimeTo,
            weighingStartedTimestamp: this.weighingStartedTimestamp,
            weighingCompletedTimestamp: this.weighingCompletedTimestamp,
            projectPositionId: this.projectPositionId,
            validity: this.validity,
        };
    }

    /**
     * Clone view
     * @return {UserView}
     */
    clone() {
        return TransportView.create(this.unfold());
    }

    /**
     * Get id
     * @returns {?number}
     */
    get id() {
        return this._id;
    }

    /**
     * Set id
     * @param {number} value
     */
    set id(value) {
        this._id = value ?? null;
    }

    /**
     * Get number
     * @return {?string}
     */
    get number() {
        return this._number;
    }

    /**
     * Set number
     * @param {string} value
     */
    set number(value) {
        this._number = value ?? null;
    }

    /**
     * Get type
     * @return {?string}
     */
    get type() {
        return this._type;
    }

    /**
     * Set type
     * @param {string} value
     */
    set type(value) {
        this._type = value ?? null;
    }

    /**
     * Get created timestamp
     * @return {?number}
     */
    get created() {
        return this._created;
    }

    /**
     * Set created timestamp
     * @param value
     */
    set created(value) {
        this._created = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get status
     * @return {?string}
     */
    get status() {
        return this._status;
    }

    /**
     * Set status
     * @param {string} value
     */
    set status(value) {
        this._status = value ?? null;
    }

    /**
     * Get status label
     * @return {?string}
     */
    get statusLabel() {
        return this._statusLabel;
    }

    /**
     * Set status label
     * @param {string} value
     */
    set statusLabel(value) {
        this._statusLabel = value ?? null;
    }

    /**
     * Get vehicle axes count
     * @returns {?number}
     */
    get vehicleNumAxes() {
        return this._vehicleNumAxes;
    }

    /**
     * Set vehicle axes count
     * @param {number} value
     */
    set vehicleNumAxes(value) {
        this._vehicleNumAxes = value ?? null;
    }

    /**
     * Get vehicle name
     * @return {?string}
     */
    get vehicleName() {
        return this._vehicleName;
    }

    /**
     * Set vehicle name
     * @param {string} value
     */
    set vehicleName(value) {
        this._vehicleName = value ?? null;
    }

    /**
     * Get vehicle class icon
     * @return {?string}
     */
    get vehicleClassIcon() {
        return this._vehicleClassIcon;
    }

    /**
     * Set vehicle class icon
     * @param {string} value
     */
    set vehicleClassIcon(value) {
        this._vehicleClassIcon = value ?? null;
    }

    /**
     * Get quantity (in kg)
     * @returns {?number}
     */
    get qty() {
        return this._qty;
    }

    /**
     * Set quantity (in kg)
     * @param {number} value
     */
    set qty(value) {
        this._qty = value ?? null;
    }

    /**
     * Get total loading weight (in kg)
     * @returns {?number}
     */
    get totalLoading() {
        return this._totalLoading;
    }

    /**
     * Set total loading weight (in kg)
     * @param {number} value
     */
    set totalLoading(value) {
        this._totalLoading = value ?? null;
    }

    /**
     * Get driver
     * @return {?UserView}
     */
    get driver() {
        return this._driver;
    }

    /**
     * Set driver
     * @param {UserView} value
     */
    set driver(value) {
        this._driver = value ?? null;
    }

    /**
     * Get carrier
     * @return {?OrganizationView}
     */
    get carrier() {
        return this._carrier;
    }

    /**
     * Set carrier
     * @param {OrganizationView} value
     */
    set carrier(value) {
        this._carrier = value ?? null;
    }

    /**
     * Get arrival timestamp
     * @return {number}
     */
    get arrivalTime() {
        return this._arrivalTime;
    }

    /**
     * Set arrival timestamp
     * @param {number} value
     */
    set arrivalTime(value) {
        this._arrivalTime = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get shipping method
     * @return {?string}
     */
    get shippingMethod() {
        return this._shippingMethod;
    }

    /**
     * Set shipping method
     * @param {string} value
     */
    set shippingMethod(value) {
        this._shippingMethod = value ?? null;
    }

    /**
     * Get shipping date timestamp
     * @return {number}
     */
    get shippingDate() {
        return this._shippingDate;
    }

    /**
     * Set shipping date timestamp
     * @param {number} value
     */
    set shippingDate(value) {
        this._shippingDate = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get shipping window start date timestamp
     * @return {number}
     */
    get shippingWindowStart() {
        return this._shippingWindowStart;
    }

    /**
     * Set shipping window start date timestamp
     * @param {number} value
     */
    set shippingWindowStart(value) {
        this._shippingWindowStart = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get shipping window end date timestamp
     * @return {number}
     */
    get shippingWindowEnd() {
        return this._shippingWindowEnd;
    }

    /**
     * Set shipping window end date timestamp
     * @param {number} value
     */
    set shippingWindowEnd(value) {
        this._shippingWindowEnd = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get planned delivery timestamp
     * @return {number}
     */
    get plannedDeliveryTime() {
        return this._plannedDeliveryTime;
    }

    /**
     * Set planned delivery timestamp
     * @param {number} value
     */
    set plannedDeliveryTime(value) {
        this._plannedDeliveryTime = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get planned delivery range
     * @return {number}
     */
    get plannedDeliveryRange() {
        return this._plannedDeliveryRange;
    }

    /**
     * Set planned delivery range
     * @param {number} value
     */
    set plannedDeliveryRange(value) {
        this._plannedDeliveryRange = value ?? null;
    }

    /**
     * Get destination waiting timestamp
     * @return {number}
     */
    get destinationWaitingTimestamp() {
        return this._destinationWaitingTimestamp;
    }

    /**
     * Set destination waiting timestamp
     * @param {number} value
     */
    set destinationWaitingTimestamp(value) {
        this._destinationWaitingTimestamp = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get confirmation pending timestamp
     * @return {number}
     */
    get confirmationPendingTimestamp() {
        return this._confirmationPendingTimestamp;
    }

    /**
     * Set confirmation pending timestamp
     * @param {number} value
     */
    set confirmationPendingTimestamp(value) {
        this._confirmationPendingTimestamp = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get delivered timestamp
     * @return {number}
     */
    get deliveredTimestamp() {
        return this._deliveredTimestamp;
    }

    /**
     * Set delivered timestamp
     * @param {number} value
     */
    set deliveredTimestamp(value) {
        this._deliveredTimestamp = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get failed timestamp
     * @return {number}
     */
    get failedTimestamp() {
        return this._failedTimestamp;
    }

    /**
     * Set failed timestamp
     * @param {number} value
     */
    set failedTimestamp(value) {
        this._failedTimestamp = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get confirm loading timestamp
     * @return {number}
     */
    get confirmLoadingTimestamp() {
        return this._confirmLoadingTimestamp;
    }

    /**
     * Set confirm loading timestamp
     * @param {number} value
     */
    set confirmLoadingTimestamp(value) {
        this._confirmLoadingTimestamp = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get confirm unloading timestamp
     * @return {number}
     */
    get confirmUnloadingTimestamp() {
        return this._confirmUnloadingTimestamp;
    }

    /**
     * Set confirm unloading timestamp
     * @param {number} value
     */
    set confirmUnloadingTimestamp(value) {
        this._confirmUnloadingTimestamp = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get loading from date timestamp
     * @return {number}
     */
    get loadingDateFrom() {
        return this._loadingDateFrom;
    }

    /**
     * Set loading from date timestamp
     * @param {number} value
     */
    set loadingDateFrom(value) {
        this._loadingDateFrom = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get loading to date timestamp
     * @return {number}
     */
    get loadingDateTo() {
        return this._loadingDateTo;
    }

    /**
     * Set loading to date timestamp
     * @param {number} value
     */
    set loadingDateTo(value) {
        this._loadingDateTo = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get loading from time
     * @return {?string}
     */
    get loadingTimeFrom() {
        return this._loadingTimeFrom;
    }

    /**
     * Set loading from time
     * @param {string} value
     */
    set loadingTimeFrom(value) {
        this._loadingTimeFrom = value ?? null;
    }

    /**
     * Get loading to time
     * @return {?string}
     */
    get loadingTimeTo() {
        return this._loadingTimeTo;
    }

    /**
     * Set loading to time
     * @param {string} value
     */
    set loadingTimeTo(value) {
        this._loadingTimeTo = value ?? null;
    }

    /**
     * Get unloading from date timestamp
     * @return {number}
     */
    get unloadingDateFrom() {
        return this._unloadingDateFrom;
    }

    /**
     * Set unloading from date timestamp
     * @param {number} value
     */
    set unloadingDateFrom(value) {
        this._unloadingDateFrom = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get unloading to date timestamp
     * @return {number}
     */
    get unloadingDateTo() {
        return this._unloadingDateTo;
    }

    /**
     * Set unloading to date timestamp
     * @param {number} value
     */
    set unloadingDateTo(value) {
        this._unloadingDateTo = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get unloading from time
     * @return {?string}
     */
    get unloadingTimeFrom() {
        return this._unloadingTimeFrom;
    }

    /**
     * Set unloading from time
     * @param {string} value
     */
    set unloadingTimeFrom(value) {
        this._unloadingTimeFrom = value ?? null;
    }

    /**
     * Get unloading to time
     * @return {?string}
     */
    get unloadingTimeTo() {
        return this._unloadingTimeTo;
    }

    /**
     * Set unloading to time
     * @param {string} value
     */
    set unloadingTimeTo(value) {
        this._unloadingTimeTo = value ?? null;
    }

    /**
     * Get project position id
     * @return {?number}
     */
    get projectPositionId() {
        return this._projectPositionId;
    }

    /**
     * Set project position id
     * @param {number} value
     */
    set projectPositionId(value) {
        this._projectPositionId = value ?? null;
    }

    /**
     * Get weighing started timestamp
     * @return {number}
     */
    get weighingStartedTimestamp() {
        return this._weighingStartedTimestamp;
    }

    /**
     * Set weighing started timestamp
     * @param {number} value
     */
    set weighingStartedTimestamp(value) {
        this._weighingStartedTimestamp = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get weighing completed timestamp
     * @return {number}
     */
    get weighingCompletedTimestamp() {
        return this._weighingCompletedTimestamp;
    }

    /**
     * Set weighing completed timestamp
     * @param {number} value
     */
    set weighingCompletedTimestamp(value) {
        this._weighingCompletedTimestamp = ensureJSTimestamp(value) ?? null;
    }

    /**
     * Get validity timestamp
     * @return {?number}
     */
    get validity() {
        return this._validity;
    }

    /**
     * Set validity timestamp
     * @param value
     */
    set validity(value) {
        this._validity = ensureJSTimestamp(value) ?? null;
    }
}

<template>
    <div>
        <div class="order-view-map" @click="openMap()">
            <img
                :src="orderViewMapUrl(constructionSitelocation, supplierLocation, width, height)"
                :width="width"
                :height="height"
                :alt="alt"
                class="order-view-map__image"
            />
        </div>
        <Flyout :active="isMapFlyoutOpen" size="medium" no-header @closed="isMapFlyoutOpen = false">
            <HeaderBar slot="header">
                <HeaderBarItem slot="left" button @click="isMapFlyoutOpen = false">
                    <ArrowIcon width="32" height="18" />
                </HeaderBarItem>
                <div slot="headline">
                    <template v-if="isShipment">
                        {{ lineItemGroup.freightDescription }}
                    </template>
                    <template v-else>
                        {{ lineItemGroup.qty | tons }}
                        {{ revertLocalizedValue(lineItemGroup.product.productName) }}
                    </template>
                </div>
                <div v-if="mapRefreshTimestamp" slot="subline">
                    <RefreshIcon width="10" class="icon--inline icon--grey" />
                    &ensp;
                    <Words muted middle>
                        {{ $d(mapRefreshTimestamp, 'short') }}&nbsp;&nbsp;&nbsp;{{
                            $t('timeSuffix', { time: $d(mapRefreshTimestamp, 'time') })
                        }}
                    </Words>
                </div>
            </HeaderBar>
            <TransportMap
                :destination="constructionSitelocation"
                :source="supplierLocation"
                :transports="transportsIds"
                :show-transport-tracking-details="showTransportTrackingDetails"
                focus-all
                @update-refresh-timestamp="mapRefreshTimestamp = $event"
            />
        </Flyout>
    </div>
</template>

<script>
import { orderViewMapUrl } from '@/services/GoogleMaps';
import { revertLocalizedValue } from '@/services/utils/localization';

import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Flyout from '@/components/Layout/Flyout';
import TransportMap from '@/components/Map/TransportMap';
import Words from '@/components/Typography/Words';

import ArrowIcon from '@/assets/icons/regular/arrow.svg';
import RefreshIcon from '@/assets/icons/micro/refresh.svg';
import { isOrderLineItemGroupTypeDisposal } from '@/constants/disposal';
import { ORDER_LINE_ITEM_GROUP_TYPE } from '@/constants/orderLineItemGroupTypes';

export default {
    name: 'OrderViewMap',
    components: {
        HeaderBar,
        HeaderBarItem,
        Flyout,
        TransportMap,
        Words,

        ArrowIcon,
        RefreshIcon,
    },
    props: {
        constructionSitelocation: {
            type: Object,
            required: true,
        },
        supplierLocation: {
            type: Object,
            required: true,
        },
        alt: {
            type: String,
            default: '',
        },
        width: {
            type: Number,
            default: 800,
        },
        height: {
            type: Number,
            default: 400,
        },
        order: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isMapFlyoutOpen: false,
            mapRefreshTimestamp: new Date(),
        };
    },
    computed: {
        showTransportTrackingDetails() {
            return isOrderLineItemGroupTypeDisposal(this.order.lineItemGroups[0].type);
        },

        lineItemGroup() {
            return this.order ? this.order.lineItemGroups[0] : null;
        },

        transportsIds() {
            const transports = [];

            if (!this.order) return transports;

            this.order.lineItemGroups.forEach(lineItemGroup => {
                lineItemGroup.lineItems.forEach(lineItem => {
                    transports.push({ id: lineItem.transportId });
                });
            });

            return transports;
        },

        isShipment() {
            return this.order.lineItemGroups.every(g => g.type === ORDER_LINE_ITEM_GROUP_TYPE.SHIPMENT);
        },
    },
    methods: {
        orderViewMapUrl,
        revertLocalizedValue,

        openMap() {
            this.isMapFlyoutOpen = this.order != null;
        },
    },
};
</script>

<style lang="scss">
.order-view-map {
    position: relative;
    background-color: #f6f6f6;
}

.order-view-map__image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.order-view-map__flag {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>

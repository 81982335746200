<template>
    <section v-if="customPositions.length" class="additional-items__section">
        <span class="font-copy-sm-strong">
            {{ $t('components.projectPositionOfferBlock.customPositionsHeadline') }}
        </span>
        <div
            v-for="(position, i) in customPositions"
            :key="i"
            class="additional-item"
            :class="{ 'additional-item--in-one-line': !showFullPrices }"
        >
            <span :key="`headline-${i}`" class="font-copy-sm-strong mt-1 inline" :class="{ 'mb-1': !showFullPrices }">
                {{ position.qty }} &times;
                {{ position.name }}
            </span>
            <span v-if="showCreditTo && position.creditTo" class="font-copy-sm inline">
                ({{ $t(`pages.checkout.priceAdjustments.customPosition.creditTo.${position.creditTo}`) }}
                {{ $t('pages.checkout.priceAdjustments.customPosition.creditTo.label') }})
            </span>

            <div
                :key="`body-${i}`"
                class="additional-item-data"
                :class="{ 'additional-item-data--grid': showFullPrices }"
            >
                <template v-if="showFullPrices">
                    <span class="font-copy-sm">
                        {{ $t('components.projectPositionOfferBlock.purchasePriceLabel') }}
                    </span>

                    <span class="font-copy-sm">
                        {{ $n(calculateUnitPurchasePrice(position), 'currency') }} / {{ $t('units.transport') }}
                    </span>

                    <span class="font-copy-sm">
                        {{ $t('components.projectPositionOfferBlock.retailPriceLabel') }}
                    </span>

                    <span class="font-copy-sm">
                        {{ $n(calculateUnitRetailPrice(position), 'currency') }} / {{ $t('units.transport') }}
                    </span>
                </template>
                <template v-else>
                    <span class="font-copy-sm">
                        {{
                            $n(
                                showPurchasePrices
                                    ? calculateUnitPurchasePrice(position)
                                    : calculateUnitRetailPrice(position),
                                'currency'
                            )
                        }}
                        / {{ $t('units.transport') }}
                    </span>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ProjectPositionAdditionalItems',
    props: {
        customPositions: {
            type: Array,
            required: true,
        },
        showCreditTo: {
            type: Boolean,
            default: false,
        },
        showFullPrices: {
            type: Boolean,
            default: false,
        },
        showPurchasePrices: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        calculateUnitPurchasePrice(position) {
            return position ? position.qty * position.unitPurchasePrice : null;
        },
        calculateUnitRetailPrice(position) {
            return position ? position.qty * position.unitRetailPrice : null;
        },
    },
};
</script>
<style lang="scss">
.additional-items__section {
    padding: 20px;

    & + & {
        border-top: $border-solid-2px;
    }
}

.additional-item {
    & + & {
        margin-top: 15px;
        border-top: $border-solid-1px;
    }
}

.additional-item__headline-hint {
    font-weight: normal;
}

.additional-item--in-one-line {
    display: grid;
    grid-template-columns: 3fr max-content;
    grid-column-gap: 20px;
    grid-row-gap: 5px;
    & + & {
        margin-top: 15px;
    }
}

.additional-item-data {
    margin-top: 15px;
}

.additional-item-data--grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-row-gap: 5px;

    > *:nth-child(2n) {
        text-align: right;
    }
}
</style>

import { reduce } from 'lodash';

const initialState = {
    errors: {},
};

const mutations = {
    setErrors(state, errors) {
        state.errors = errors;
    },
};

const actions = {
    /**
     * Prepare response errors and store
     * @param commit
     * @param errors
     * @param mapping
     */
    populate({ commit }, errors, mapping = {}) {
        const preparedErrors = errors?.reduce?.((result, error) => {
            const mappedKey = mapping[error.property_path] || error.property_path;

            if (!result[mappedKey]) {
                result[mappedKey] = [];
            }

            result[mappedKey].push(error.message);

            return result;
        }, {});

        commit('setErrors', preparedErrors);
    },
    cleanup({ commit }) {
        commit('setErrors', {});
    },
};

const getters = {
    firstError: state => {
        return reduce(
            state.errors,
            (result, error, key) => {
                if (!result[key]) {
                    result[key] = error[0];
                }

                return result;
            },
            {}
        );
    },
    componentErrors: state => key => {
        return Object.keys(state.errors).reduce((errorList, k) => {
            if (k.includes(key)) {
                return errorList.concat(state.errors[k]);
            }
        }, []);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions,
};

import { CONSTRUCTION_PROJECT_DETAILS_ROUTE } from '@/constructionProjects/constants';

/**
 * Get error route name for checkout redirect
 * @param {string} routeType
 * @param {string} errorKey
 * @param {string} planningMethod
 * @param {string} quoteType
 * @return {string}
 */
export function getCheckoutRedirectErrorRouteName(routeType, errorKey, planningMethod, quoteType) {
    const defaultResultMap = {
        constructionSiteId: `checkout-${routeType}`,
        productId: `checkout-${routeType}-product-selection`,
        inputType: `checkout-${routeType}-quantity-selection`,
        qty: `checkout-${routeType}-quantity-selection`,
        vehicleClassId: `checkout-${routeType}-quantity-selection`,
        shippingMethod: `checkout-${routeType}-shipping-method`,
        supplier: `checkout-${routeType}-supplier-selection`,
        factory: `checkout-${routeType}-supplier-selection`,
        carrier: `checkout-${routeType}-carrier-selection`,
        supervisor: CONSTRUCTION_PROJECT_DETAILS_ROUTE,
        requestTimeout: 'refresh',
        billingMethod: `checkout-${routeType}-billing-selection`,
        loadingSite: `checkout-${routeType}-loading`,
        freightDetails: `checkout-${routeType}-vehicle-and-document`,
    };

    if (Object.keys(defaultResultMap).includes(errorKey)) {
        return defaultResultMap[errorKey];
    }

    if (['cutOff', 'shippingDate'].includes(errorKey)) {
        return quoteType === 'shipment'
            ? `checkout-${routeType}-time-selection`
            : `checkout-${routeType}-shipping-method`;
    }

    if ('unloadingSite' === errorKey) {
        return quoteType === 'shipment' ? `checkout-${routeType}-unloading` : `checkout-${routeType}`;
    }

    if ('shippingWindow' === errorKey) {
        return planningMethod === 'default'
            ? `checkout-${routeType}-window-selection`
            : `checkout-${routeType}-time-selection`;
    }

    return 'home';
}

import LogService from '@schuettflix/util-log';
import customAbilities from '@/services/AbilityChecker/customAbilities';
import AbilityChecker from '@/services/AbilityChecker/AbilityChecker';
import Context from '@/services/AbilityChecker/Context';
import { ensurePHPTimestamp } from '@/services/utils/date';
import { abilitiesQuery, queryClient } from '@/reactBridge/queryClient';

const Log = new LogService('store/abilities');

const state = () => {
    return {
        abilities: null,
        loading: false,
    };
};

const getters = {
    abilityChecker: state => {
        return new AbilityChecker({ ...state.abilities, ...customAbilities });
    },

    defaultContext: (_state, _getters, _rootState, rootGetters) => {
        return {
            features: rootGetters['user/features'],
            organization: rootGetters['user/organization'],
            permissions: rootGetters['user/permissions'],
            types: rootGetters['user/organizationTypes'],
            user: rootGetters['user/user'],
        };
    },

    can: (_state, getters) => {
        return (check, subject = {}) => {
            const context = new Context(getters.defaultContext);
            context.set('subject', subject);
            context.set('time', ensurePHPTimestamp(Date.now()));
            return getters.abilityChecker.can(check, context);
        };
    },
};

const mutations = {
    setAbilities(state, abilities) {
        state.abilities = abilities;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
};

const actions = {
    async updateAbilities({ commit }) {
        commit('setLoading', true);
        try {
            // Abilities are fetched through the Query Client to keep both Vuex and the Query Client in sync
            const result = await queryClient.fetchQuery(abilitiesQuery);
            commit('setAbilities', result);
        } catch (err) {
            Log.log(err);
        }
        commit('setLoading', false);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

<template>
    <VehicleTrack
        :transports="transports"
        :small="small"
        :spaceless="spaceless"
        :hide-loading-info="hideLoadingInfo"
        :vehicle-layout="vehicleLayout"
    />
</template>

<script>
import VehicleTrack from '@/components/Transport/VehicleTrack';

export default {
    name: 'OrderVehicleTrack',
    components: {
        VehicleTrack,
    },
    props: {
        lineItems: {
            type: Array,
            required: true,
        },
        small: {
            type: Boolean,
            default: false,
        },
        spaceless: {
            type: Boolean,
            default: false,
        },
        hideLoadingInfo: {
            type: Boolean,
            default: false,
        },
        groupByClass: {
            type: Boolean,
            default: false,
        },
        vehicleLayout: {
            type: String,
            default: 'vertical',
            validator: v => ['horizontal', 'vertical'].includes(v),
        },
    },
    computed: {
        transports() {
            return this.lineItems.map(lineItem => {
                const vehicleClassId = lineItem.vehicleClassId || lineItem.transportVehicleClassId;

                return {
                    // vehicles will be grouped by actual load
                    actualLoad: this.groupByClass ? vehicleClassId : lineItem.qty,
                    id: vehicleClassId,
                    // show full trucks for pickup
                    loadPercentage: (lineItem.qty / (lineItem.vehiclePayload || lineItem.qty)) * 100,
                    name: lineItem.vehicleName || lineItem.transportVehicleName,
                    numAxes: lineItem.vehicleNumAxes || lineItem.transportVehicleNumAxes,
                    payload: lineItem.vehiclePayload || lineItem.loadedQty,
                    icon: lineItem.vehicleClassIcon || null,
                };
            });
        },
    },
};
</script>

<style lang="scss"></style>

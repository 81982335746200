import { setViewHeight } from '@/services/utils/visual';
import _debounce from 'lodash/debounce';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

export function setupWeb() {
    setViewHeight();
    window.addEventListener('resize', _debounce(setViewHeight, 150));

    defineCustomElements(window);
}

<template>
    <div
        :style="gridRowColumnsRows"
        :class="{
            'grid-table__row--spaced': spaced,
            'grid-table__row--hover': !disabledHighlight && hover && $root.isDesktop,
            'grid-table__row--pointless': pointless,
            'grid-table__row--tabled': tabled,
        }"
        class="grid-table__row"
        @mouseover="hover = true"
        @mouseout="hover = false"
        @click="$emit('click', $event)"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'GridTableRow',
    props: {
        spaced: {
            type: Boolean,
            default: false,
        },
        disabledHighlight: {
            type: Boolean,
            default: false,
        },
        pointless: {
            type: Boolean,
            default: false,
        },
        tabled: {
            type: Boolean,
            default: false,
        },
        useMobileRows: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            hover: false,
            columns: null,
        };
    },
    computed: {
        gridRowColumnsRows() {
            if (!this.useMobileRows) {
                return `grid-template-columns: ${this.getColumns()}`;
            }
            return 'grid-auto-rows: 1fr';
        },
    },
    methods: {
        getColumns() {
            if (this.columns !== null) {
                return this.columns;
            }

            this.columns = null;
            let parent = null;

            while (this.columns === null) {
                parent = parent === null ? this.$parent : parent.parentNode;

                if (!parent) {
                    break;
                }

                this.columns = parent.columns ? parent.columns : null;
            }

            return this.columns;
        },
    },
};
</script>

<template>
    <Pane>
        <div slot="head">
            <button class="align-right" type="button" @click="requestInfo">Request api status</button>
        </div>
        <div slot="body" class="debug-apiinfo-stage">
            <div v-if="!status">API status unknown</div>
            <div v-if="status">
                <Dump v-if="typeof status === 'object'" :data="status" />
                <pre v-if="typeof status === 'string'">
                    <code>{{ status }}</code>
                </pre>
            </div>
        </div>
    </Pane>
</template>

<script>
import Pane from '@/components/Debug/Pane/Pane.vue';
import Dump from '@/components/Debug/Dump/Dump.vue';
import Authenticator from '@/services/Api/Authenticator';

export default {
    name: 'ApiInfo',
    components: {
        Pane,
        Dump,
    },
    data() {
        return {
            status: undefined,
        };
    },
    methods: {
        requestInfo() {
            Authenticator.requestInfo().then(res => {
                this.status = res;
            });
        },
    },
};
</script>

<style lang="scss">
.debug-apiinfo-stage {
    overflow: hidden;
}
</style>

export function sortByProductCategory(a, b) {
    const order = {
        MATERIAL_TRANSPORT: 1,
        MATERIAL: 2,
        TRANSPORT: 3,
        MATERIAL_SERVICE: 4,
        TRANSPORT_SERVICE: 5,
    };
    const priorityA = order[a.productCategory];
    const priorityB = order[b.productCategory];
    if (priorityA < priorityB) {
        return -1;
    }
    else if (priorityA > priorityB) {
        return 1;
    }
    else {
        return 0;
    }
}

<template>
    <div class="active-order-item" @click="$emit('click', $event)">
        <div class="active-order-item__content">
            <Words block>
                {{ revertLocalizedValue(lineItemGroup.product.productName) }}
            </Words>
            <div class="active-order-item__progress">
                <Words bold tiny unbreakable class="active-order-item__progress-number">
                    {{ $n(lineItemGroup.qty - lineItemGroup.remainingContingent) }} kg
                </Words>
                <div class="active-order-item__progress-bar">
                    <div
                        class="active-order-item__progress-bar-inner"
                        :style="{ width: `${getRemainingProgress()}%` }"
                    />
                </div>
                <Words bold tiny unbreakable class="active-order-item__progress-number">
                    {{ $n(lineItemGroup.qty) }} kg
                </Words>
            </div>
            <div class="active-order-item__label-table">
                <ConstructionSiteIcon width="14" class="icon--muted" />
                <Words small muted block>{{ lineItemGroup.constructionSite.name }}</Words>

                <PinIcon width="14" class="icon--muted" />
                <Words small muted>{{ lineItemGroup.supplier.name }}</Words>

                <ClockIcon v-if="supplierInfo" width="14" class="icon--muted" />
                <BusinessHours
                    v-if="supplierInfo"
                    :opening-timestamp="supplierInfo.openingTimestamp"
                    :closing-timestamp="supplierInfo.closingTimestamp"
                />
            </div>
        </div>
        <div class="active-order-item__action">
            <Button v-if="canStartPickup" arrow-right @click.stop="$emit('add', order, lineItemGroup)">
                <Words small>
                    {{ $t('pages.homeV2.pickupStart') }}
                </Words>
            </Button>
        </div>
    </div>
</template>

<script>
import { statusIsBefore, statusIsAfter } from '@/services/utils/transport';
import { revertLocalizedValue } from '@/services/utils/localization';
import SupplierApi from '@/services/Api/Supplier';

import Button from '@/components/Button/Button';
import BusinessHours from '@/components/Supplier/BusinessHours';
import Words from '@/components/Typography/Words';

import PinIcon from '@/assets/icons/micro/pin2.svg';
import ClockIcon from '@/assets/icons/micro/clock.svg';
import ConstructionSiteIcon from '@/assets/icons/micro/construction-site.svg';
import { ensureJSTimestamp } from '@/services/utils/date';

const NOW_INTERVAL = 5000;

export default {
    name: 'ActiveOrderPickupItem',
    components: {
        Button,
        BusinessHours,
        Words,

        PinIcon,
        ConstructionSiteIcon,
        ClockIcon,
    },
    props: {
        order: {
            type: Object,
            default: () => {},
        },
        lineItemGroup: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            supplierInfo: null,
            currentTimestamp: Date.now(),
        };
    },
    computed: {
        isInTransit() {
            return this.item.status === 'in_transit';
        },

        isInProgress() {
            return statusIsBefore(this.item.status, 'unloading_delayed');
        },

        isDelivered() {
            return statusIsAfter(this.transport.status, 'destination_waiting');
        },
        canStartPickup() {
            return (
                ensureJSTimestamp(this.lineItemGroup.expireDate) > new Date() &&
                this.lineItemGroup.remainingContingent > 0
            );
        },
    },
    created() {
        this.$interval(() => {
            this.currentTimestamp = Date.now();
        }, NOW_INTERVAL);
    },
    mounted() {
        this.refreshSupplierInfo();
    },
    methods: {
        revertLocalizedValue,
        statusIsBefore,
        statusIsAfter,

        async refreshSupplierInfo() {
            const factoryId = this.lineItemGroup.factoryId;
            if (factoryId && !this.supplierInfo) {
                try {
                    this.supplierInfo = await SupplierApi.getOneById(factoryId);
                } catch (err) {
                    this.$logger().error(err);
                }
            }
        },

        getRemainingProgress() {
            return (this.lineItemGroup.remainingContingent * 100) / this.lineItemGroup.qty;
        },
    },
};
</script>

<style lang="scss">
.active-order-item {
    cursor: pointer;
    padding: 18px 10px 13px;
    display: grid;
    grid-template-columns: 1fr;

    @media screen and (min-width: $layout-desktop-min) {
        grid-template-columns: 3fr 1fr;
    }
}

.active-order-item__action {
    justify-self: end;
    align-self: end;
}

.active-order-item__split-between {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}

.active-order-item__label-table {
    display: grid;
    grid-template-columns: 14px 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-top: 10px;
}

.active-order-item__vehicle-info {
    display: grid;
    grid-template-columns: 2fr 3fr;
}

.active-order-item__orb {
    padding: 10px;
    width: 43px;
    height: 43px;

    svg {
        .f-base {
            fill: $color-black;
        }

        .s-base {
            stroke: $color-black;
        }
    }
}

.active-order-item__progress {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 8px 0;
}

.active-order-item__progress-number {
    flex-grow: 1;
    flex-shrink: 0;
}

.active-order-item__progress-bar {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0 10px;
    width: 100%;
    height: 5px;
    display: flex;
    border-radius: 50px;
    overflow: hidden;
    align-self: center;
    display: flex;
    justify-content: flex-end;
    background: $color-red;
    background: linear-gradient(to right, rgba(255, 0, 0, 1) 0%, rgba(59, 0, 0, 1) 100%);
}

.active-order-item__progress-bar-inner {
    background-color: $color-mediumGrey;
    width: 100%;
}
</style>

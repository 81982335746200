var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('div',{staticClass:"card mt-4 w-full text-left",class:[
            {
                'card--hovered card--focussed ': !_vm.preview,
            },
        ]},[_c('span',{staticClass:"font-copy-sm-strong mb-6"},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.disposal.summary.tiles.generalInformation.title'))+" ")]),_c('span',{staticClass:"font-copy-sm mt-4 block text-subdued"},[_vm._v(" "+_vm._s(_vm.$t('disposal.producerNumber.title'))+" ")]),_c('span',{staticClass:"font-copy-md mt-2 block"},[_vm._v(" "+_vm._s(_vm.info.producerNumber)+" ")]),_c('span',{staticClass:"font-copy-sm mt-4 block text-subdued"},[_vm._v(" "+_vm._s(_vm.$t('disposal.disposalCertificateNumber.placeholder'))+" ")]),(_vm.info.disposalProofNumber)?[(_vm.info.disposalProofNumber)?_c('span',{staticClass:"font-copy-md mt-2 block"},[_vm._v(" "+_vm._s(_vm.info.disposalProofNumber)+" ")]):_vm._e()]:_c('span',{staticClass:"mt-2 block text-subdued"},[_vm._v(" "+_vm._s(_vm.$t('pages.transport.transportViewV2.loadingDetail.notSpecifiedLabel'))+" ")]),_c('span',{staticClass:"font-copy-sm mt-4 text-subdued"},[_vm._v(" "+_vm._s(_vm.$t('disposal.stateWasteCompany.title'))+" ")]),(_vm.info.stateWasteCompanyText)?[_c('div',{staticClass:"flex justify-between"},[_c('span',{staticClass:"font-copy-md mt-2 block overflow-hidden text-ellipsis whitespace-nowrap"},[_vm._v(_vm._s(_vm.info.stateWasteCompanyText))]),_c('span',{staticClass:"font-copy-md ml-4 mt-2 block whitespace-nowrap"},[_vm._v(_vm._s(_vm.info.stateWasteCompanyFee)+" %")])])]:_c('span',{staticClass:"mt-2 block text-subdued"},[_vm._v(" "+_vm._s(_vm.$t('pages.transport.transportViewV2.loadingDetail.notSpecifiedLabel'))+" ")])],2),_c('div',{staticClass:"mt-2 text-right"},[_c('Button',{attrs:{"arrow-right":""},on:{"click":function($event){return _vm.$emit('edit', {
                    section: 'generalDescription',
                    routeName: 'checkout-project-disposal-hazardous-general-information',
                })}}},[_vm._v(" "+_vm._s(_vm.$t('pages.checkout.disposal.summary.actions.change.generalInformation'))+" ")])],1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
import { forwardRef } from 'react';
// TODO extract to component library, this is just temporary until icon PR is merged
export const PickupOrderIcon = forwardRef(({ className, ...props }, ref) => {
    return (<svg width={80} height={42} className={className} ref={ref} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#a)" strokeWidth={1.2} strokeLinecap="round" strokeLinejoin="round">
                <path d="M77.59 31.525h-2.823v-1.356a.31.31 0 0 1 .31-.3h2.495a.132.132 0 0 1 .132.13v1.394a.132.132 0 0 1-.113.132Z" stroke="#1F1920"/>
                <path d="M76.725 23.214v-6.88a.63.63 0 0 0-.63-.63H64.375a.631.631 0 0 0-.593.441l-.244.744c-.13.388-.13.808 0 1.195a4.894 4.894 0 0 0 2.55 3.04l9.676 1.582a1.685 1.685 0 0 1 1.412 1.665v5.544M60.508 10.602h-.94a.565.565 0 0 0-.566.565v23.03" stroke="#1F1920"/>
                <path d="M76.603 15.967a12.394 12.394 0 0 0-.82-3.115 1.693 1.693 0 0 0-1.072-.875 37.53 37.53 0 0 0-8.47-1.158l-5.704-.217" stroke="#1F1920"/>
                <path d="m68.819 10.97-5.054-2.268a1.675 1.675 0 0 0-1.337-.18 1.6 1.6 0 0 0-1.044 1.12l-.292.942 6.212.254M67.256 15.967v5.157M77.459 31.524v1.328l-1.403.602-1.74.743h-4.537M43.736 33.407h-5.58M69.779 32.852a3.247 3.247 0 1 0-3.247 3.247 3.257 3.257 0 0 0 3.247-3.247v0ZM30.043 32.852a3.247 3.247 0 1 0-6.495 0 3.247 3.247 0 0 0 6.495 0v0ZM38.155 32.852a3.247 3.247 0 1 0-6.494 0 3.247 3.247 0 0 0 6.494 0ZM52.48 32.852a3.247 3.247 0 1 0-3.247 3.247 3.257 3.257 0 0 0 3.247-3.247ZM31.746 29.313h-1.619M40.602 33.379h-2.41M21.515 31.148v-2.051h2.504M77.092 24.918l-1.591-.273M73.939 14.556v3.04M75.482 36.513H57.6M16.038 39.703h-5.883M52.988 36.73H21.515M53.393 29.35h3.736V14.461M45.374 29.313h-6.419M57.13 33.379h-4.65" stroke="#1F1920"/>
                <path d="M79.021 27.148H68.555M66.965 27.148h-.151" stroke="red"/>
                <path d="M54.843 14.264H17.308l2.909 12.593M57.12 8.456v6.005M22.155 40.663H67.36M63.322 34.197h-1.496" stroke="#1F1920"/>
                <path d="M52.48 7.383a2.07 2.07 0 1 0 0-4.14 2.07 2.07 0 0 0 0 4.14ZM17.61 3.534h3.068M19.144 2v3.059M11.972 16.146a1.77 1.77 0 1 0 0-3.539 1.77 1.77 0 0 0 0 3.54Z" stroke="red"/>
                <path d="M48.066 18.226h-20.8" stroke="#1F1920"/>
                <path d="m7.144 37.002 5.016-6.456a1.092 1.092 0 0 1 1.346-.302l4.32 1.883a1.11 1.11 0 0 0 1.308-.254V29.81M18.438 32.334l3.934 1.892M9.864 33.087A5.552 5.552 0 0 0 2.88 37.02" stroke="red"/>
                <path d="M24.79 36.74h14.504M19.144 36.964l-2.1-2.023a.941.941 0 0 0-1.223 0l-2.644 2.268H.715" stroke="#1F1920"/>
                <path d="m14.532 30.593 3.642-4.244M7.021 32.899l5.356-7.153a1.046 1.046 0 0 1 1.571-.113l2.26 2.287M16.621 22.508l-2.673 3.125M51.257 11.713s-4.198-3.821-8.904-1.562M40.744 11.619s-7.069-6.174-14.909.103" stroke="red"/>
                <path d="M26.833 12.306a2.824 2.824 0 0 0-3.868.169" stroke="red"/>
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" transform="translate(0 .765)" d="M0 0h80v40.471H0z"/>
                </clipPath>
            </defs>
        </svg>);
});
PickupOrderIcon.displayName = 'PickupOrderIcon';

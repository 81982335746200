import _sortBy from 'lodash/sortBy';
import { getCountryFromLocale } from '@/i18n/languages';
const countryCodesList = [
    { /* Albania */ code: '+355', isoCode: 'AL' },
    { /* Andorra */ code: '+376', isoCode: 'AD' },
    { /* Austria */ code: '+43', isoCode: 'AT' },
    { /* Belgium */ code: '+32', isoCode: 'BE' },
    { /* Belorussia */ code: '+375', isoCode: 'BY' },
    { /* Bosnia Herzegovina */ code: '+387', isoCode: 'BA' },
    { /* Bulgaria */ code: '+359', isoCode: 'BG' },
    { /* Croatia */ code: '+385', isoCode: 'HR' },
    { /* Cyprus */ code: '+357', isoCode: 'CY' },
    { /* Czech Republic */ code: '+420', isoCode: 'CZ' },
    { /* Denmark */ code: '+45', isoCode: 'DK' },
    { /* Estonia */ code: '+372', isoCode: 'EE' },
    { /* Finland */ code: '+358', isoCode: 'FI' },
    { /* France */ code: '+33', isoCode: 'FR' },
    { /* Germany */ code: '+49', isoCode: 'DE' },
    { /* Greece */ code: '+30', isoCode: 'GR' },
    { /* Hungary */ code: '+36', isoCode: 'HU' },
    { /* Ireland */ code: '+353', isoCode: 'IE' },
    { /* Italy */ code: '+39', isoCode: 'IT' },
    { /* Kosovo */ code: '+381', isoCode: 'XK' },
    { /* Latvia */ code: '+371', isoCode: 'LV' },
    { /* Lithuania */ code: '+370', isoCode: 'LT' },
    { /* Luxembourg */ code: '+352', isoCode: 'LU' },
    { /* Macedonia */ code: '+389', isoCode: 'MK' },
    { /* Malta */ code: '+356', isoCode: 'MT' },
    { /* Monaco */ code: '+377', isoCode: 'MC' },
    { /* Montenegro */ code: '+382', isoCode: 'ME' },
    { /* Netherlands */ code: '+31', isoCode: 'NL' },
    { /* Norway */ code: '+47', isoCode: 'NO' },
    { /* Poland */ code: '+48', isoCode: 'PL' },
    { /* Portugal */ code: '+351', isoCode: 'PT' },
    { /* Romania */ code: '+40', isoCode: 'RO' },
    { /* Russia */ code: '+7', isoCode: 'RU' },
    { /* San Marino */ code: '+378', isoCode: 'SM' },
    { /* Serbia */ code: '+381', isoCode: 'RS' },
    { /* Slovakia */ code: '+421', isoCode: 'SK' },
    { /* Spain */ code: '+34', isoCode: 'ES' },
    { /* Sweden */ code: '+46', isoCode: 'SE' },
    { /* Switzerland */ code: '+41', isoCode: 'CH' },
    { /* Turkey */ code: '+90', isoCode: 'TR' },
    { /* Ukraine */ code: '+380', isoCode: 'UA' },
    { /* United Kingdom */ code: '+44', isoCode: 'GB' },
];
const countryCodes = _sortBy(countryCodesList, [countryCode => countryCode.code]);
export function findCountryCodeByLocale(locale) {
    const isoCode = getCountryFromLocale(locale);
    if (isoCode === null) {
        return undefined;
    }
    return findCountryCodeByISOCode(isoCode);
}
function findCountryCodeByISOCode(isoCode) {
    return countryCodes.find(countryCode => countryCode.isoCode.toUpperCase() === isoCode)?.code;
}
export default countryCodes;

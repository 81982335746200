<template>
    <div
        class="telephone-contact-avatar"
        :class="{
            'telephone-contact-avatar--header': positionHeader,
        }"
        @click="$emit('click')"
    >
        <div class="telephone-contact-avatar__container">
            <img src="@/assets/people/dagmar_grossenbach--small.jpg" class="telephone-contact-avatar__image" />
        </div>
        <div class="telephone-contact-avatar__phone-container">
            <PhoneIcon width="18" height="18" />
        </div>
    </div>
</template>

<script>
import PhoneIcon from '@/assets/icons/regular/phone.svg';

export default {
    name: 'TelephoneContactAvatar',

    components: {
        PhoneIcon,
    },

    props: {
        positionHeader: {
            type: Boolean,
            default: false,
        },

        clickable: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
$avatarSize: 80px;
$headerAvatarSize: 55px;
$telephoneSize: 28px;

.telephone-contact-avatar {
    position: relative;
    width: $avatarSize;
    height: $avatarSize;
}

.telephone-contact-avatar__container {
    border-radius: $avatarSize;
    overflow: hidden;
    width: $avatarSize;
    height: $avatarSize;
    border: 1px solid $color-mediumGrey;

    display: flex;
    justify-content: center;
    align-items: center;
}

.telephone-contact-avatar__image {
    width: $avatarSize;
    height: $avatarSize;

    border: 4px solid white;
    border-radius: $avatarSize;
}

.telephone-contact-avatar__phone-container {
    background-color: $color-red;
    width: $telephoneSize;
    height: $telephoneSize;
    border-radius: $telephoneSize;
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: 0;
    right: 0;

    svg {
        .s-base,
        .s-highlight {
            stroke: $color-white;
        }

        .f-base,
        .f-highlight {
            fill: $color-white;
        }
    }
}

.telephone-contact-avatar--header {
    width: $headerAvatarSize;
    height: $headerAvatarSize;

    .telephone-contact-avatar__container,
    .telephone-contact-avatar__image {
        width: $headerAvatarSize;
        height: $headerAvatarSize;
    }

    .telephone-contact-avatar__phone-container {
        bottom: -5px;
        right: -5px;
    }
}
</style>

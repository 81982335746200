var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.block ? 'p' : 'span',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.tooltip),expression:"tooltip",modifiers:{"top":true}}],tag:"component",staticClass:"words",class:{
        'words--block': _vm.block,
        'words--bold': _vm.bold,
        'words--red': _vm.red,
        'words--white': _vm.white,
        'words--yellow': _vm.yellow,
        'words--green': _vm.green,
        'words--muted': _vm.muted,
        'words--error': _vm.isError,
        'words--tiny': _vm.tiny,
        'words--size-normal': _vm.sizeNormal,
        'words--large': _vm.large,
        'words--x-large': _vm.xLarge,
        'words--small': _vm.small,
        'words--middle': _vm.middle,
        'words--spaced': _vm.spaced,
        'words--ellipsis': _vm.ellipsis,
        'words--unbreakable': _vm.unbreakable,
        'words--selectable': _vm.selectable,
        'words--spaced-bottom': _vm.spacedBottom,
        'words--spaced-bottom-small': _vm.spacedBottomSmall,
        'words--spaced-bottom-medium': _vm.spacedBottomMedium,
        'words--spaced-bottom-tiny': _vm.spacedBottomTiny,
        'words--spaced-bottom-large': _vm.spacedBottomLarge,
        'words--spaced-top': _vm.spacedTop,
        'words--spaced-top-small': _vm.spacedTopSmall,
        'words--spaced-top-tiny': _vm.spacedTopTiny,
        'words--spaced-top-large': _vm.spacedTopLarge,
        'words--spaced-right': _vm.spacedRight,
        'words--spaced-right-tiny': _vm.spacedRightTiny,
        'words--spaced-left': _vm.spacedLeft,
        'words--spaced-left-small': _vm.spacedLeftSmall,
        'words--spaced-left-tiny': _vm.spacedLeftTiny,
        'words--underlined': _vm.underlined,
        'words--centered': _vm.centered,
        'words--align-left': _vm.alignLeft,
        'words--align-right': _vm.alignRight,
        'words--pre': _vm.pre,
        'words--weight-normal': _vm.weightNormal,
        'words--break-words': _vm.breakWords,
        'words--line-height--small': _vm.lineHeightSmall,
        'words--line-height--large': _vm.lineHeightLarge,
    }},[_vm._t("default")],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
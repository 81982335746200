import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';

export default {
    props: {
        isDraft: {
            type: Boolean,
            default: false,
        },
        isFactory: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        form() {
            return _cloneDeep(this.value);
        },
    },
    mounted() {
        this.$emit('dirty-check', false);
    },
    methods: {
        update(key, value) {
            const formClone = _cloneDeep(this.form);
            _set(formClone, key, value);
            this.$emit('input', formClone);
        },
    },
};

import GoogleMaps, { createMapMarker } from '@/services/GoogleMaps';
import FlagIconPath from '@/assets/icons/flag.svg?external';
import PointIconPath from '@/assets/icons/pin--38px.svg?external';
import { normalizeLocation } from '@/services/utils/map';

export default {
    methods: {
        async updateDestination(location = null) {
            // remove destination marker if no location given
            if (location === null) {
                if (this.destinationMarker !== null) {
                    this.destinationMarker.setMap(null);
                    this.destinationMarker = null;
                }
                return;
            }

            const map = await this.createMap();

            if (this.destinationMarker === null) {
                this.destinationMarker = await createMapMarker(FlagIconPath, location, map);

                this.destinationMarker.addListener('click', () => {
                    this.centerLocationOnMap(normalizeLocation(this.destinationMarker.getPosition()));
                });

                // focus initially
                if (this.focusDestination) {
                    this.centerLocationOnMap(normalizeLocation(this.destinationMarker.getPosition()));
                }
            }

            this.destinationMarker.setPosition(location);
        },

        async updateSource(location = null) {
            // remove source marker if no location given
            if (location === null) {
                if (this.sourceMarker !== null) {
                    this.sourceMarker.setMap(null);
                    this.sourceMarker = null;
                }
                return;
            }

            const google = await GoogleMaps;
            const map = await this.createMap();

            if (this.sourceMarker === null) {
                /* @type {google.maps.Icon} */
                const icon = {
                    url: PointIconPath,
                    scaledSize: new google.maps.Size(40, 40), // maps adds wierd 16px to the marker
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(20, 37.8), // 0.945 factor
                };

                this.sourceMarker = new google.maps.Marker({
                    position: location,
                    map: map,
                    title: 'Source',
                    icon: icon,
                    zIndex: 1,
                });

                this.sourceMarker.addListener('click', () => {
                    this.centerLocationOnMap(normalizeLocation(this.sourceMarker.getPosition()));
                });

                // focus initially
                if (this.focusSource) {
                    this.centerLocationOnMap(normalizeLocation(this.sourceMarker.getPosition()));
                }
            }

            this.sourceMarker.setPosition(location);
        },
    },
};

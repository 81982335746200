<template>
    <div class="transport-search">
        <GridRow :count="1" spacing="none" align="center" class="transport-search__search">
            <TextField
                v-model="searchString"
                :label="$t('components.transportSearch.searchLabel')"
                class="span-1"
                @input="$emit('input', $event)"
            >
                <SearchIcon slot="icon" width="18" height="18" />
            </TextField>
        </GridRow>
        <List v-show="searchString">
            <TransportItem
                v-for="(transport, i) in visibleTransports"
                :key="i"
                :search-string="searchString"
                :item="transport"
                is-supplier
                @click="selectTransport(transport.id)"
            />
        </List>
    </div>
</template>

<script>
import GridRow from '@/components/Layout/GridRow';
import TextField from '@/components/Form/TextField.v2';
import List from '@/components/List/List';
import TransportItem from '@/components/List/TransportItem';

import SearchIcon from '@/assets/icons/micro/search.svg';

import _filter from 'lodash/filter';
import _isArray from 'lodash/isArray';

export default {
    name: 'TransportSearch',
    components: {
        GridRow,
        TextField,
        List,
        TransportItem,
        SearchIcon,
    },
    props: {
        transports: {
            type: Array,
            required: true,
        },
        routeName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            searchString: null,
        };
    },
    computed: {
        visibleTransports() {
            return _filter(this.transports, transport => {
                return this.searchString
                    ? transport.vehicle.licensePlate.toLowerCase().includes(String(this.searchString).toLowerCase())
                    : true;
            });
        },
    },
    methods: {
        getSelectedOptions() {
            return _filter(this.options, option => {
                if (_isArray(this.value)) {
                    return this.value.includes(this.optionValueRenderer(option));
                } else {
                    return this.optionValueRenderer(option) === this.value;
                }
            });
        },
        selectTransport(transportId) {
            this.$router
                .push({ name: this.$root.findRouteName(this.routeName), params: { transportId } })
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss">
.transport-search__search {
    padding: 0 10px;
}
</style>

import { faro } from '@grafana/faro-web-sdk';
import { isAxiosError, isCancel } from 'axios';
export function setUser(user) {
    if (!faro?.api) {
        return;
    }
    if (!user) {
        faro.api.resetUser();
        return;
    }
    const attributes = {};
    if (user.impersonatedUserId) {
        attributes['impersonatedUserId'] = user.impersonatedUserId.toString();
    }
    faro.api.setUser({
        id: user.id.toString(),
        username: user.username,
        attributes,
    });
}
export function setScreen(name) {
    if (!faro?.api) {
        return;
    }
    faro.api.setView({
        name,
    });
}
export function reportNetworkError(error) {
    if (!faro?.api) {
        return;
    }
    let context = {};
    if (isAxiosError(error)) {
        context = Object.assign(context, mapAxiosErrorToExceptionContext(error));
    }
    faro.api?.pushError(error, {
        type: 'network',
        context,
    });
}
export function instrumentAxios(instance) {
    instance.interceptors.response.use(res => res, err => {
        if (!isCancel(err)) {
            reportNetworkError(err);
        }
        return Promise.reject(err);
    });
}
function mapAxiosErrorToExceptionContext(error) {
    const context = {};
    if (error.response) {
        context['status'] = error.response.status.toString();
        context['statusText'] = error.response.statusText;
        if (error.response.config.method) {
            context['method'] = error.response.config.method.toUpperCase();
        }
        if (error.response.config.url) {
            context['url'] = error.response.config.url;
        }
    }
    return context;
}

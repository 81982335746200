<template>
    <div class="reporting__section two-numbers">
        <Words block bold class="two-numbers__headline">{{ $t('pages.reporting.panels.punctuality.headline') }}</Words>
        <div class="two-numbers__content two-numbers__content--left">
            <div class="two-numbers__info">
                <Words bold class="two-numbers__number">
                    {{ count }}
                </Words>
                <Words class="two-numbers__text">
                    {{ $t('pages.reporting.panels.punctuality.numberText') }}
                </Words>
            </div>
        </div>
        <div class="two-numbers__content two-numbers__content--right">
            <div class="two-numbers__info">
                <Words class="two-numbers__text" :tiny="!$root.isDesktop">
                    {{ $t('pages.reporting.panels.punctuality.valueText') }}&nbsp;&nbsp;
                </Words>
                <Words unbreakable>
                    <Words class="two-numbers__number" bold>
                        {{ $n(sum, 'currency_signless') }}
                    </Words>
                    <Words bold class="two-numbers__text"> {{ getCurrencySign() }} </Words>
                </Words>
            </div>
        </div>
    </div>
</template>

<script>
import { getCurrencySign } from '@/services/utils/currency';
import Words from '@/components/Typography/Words';

export default {
    name: 'TwoNumbers',
    components: {
        Words,
    },
    props: {
        count: {
            type: Number,
            required: true,
        },
        sum: {
            type: Number,
            required: true,
        },
    },
    methods: {
        getCurrencySign,
    },
};
</script>

<style lang="scss">
.two-numbers__headline {
    margin-bottom: 10px;
}

.two-numbers__content {
    display: flex;
    align-items: baseline;
}

.two-numbers__content + .two-numbers__content {
    margin-bottom: 10px;
}

.two-numbers__content--left {
    justify-content: flex-start;
}

.two-numbers__content--right {
    justify-content: flex-end;
}

.two-numbers__Text {
    font-size: 20px;
}

.two-numbers__number {
    font-size: 40px;
}
</style>

<template>
    <div :class="`vehicle-class-icon-inner--${mode}`" class="vehicle-class-icon-inner">
        <template v-if="mode === 'button'">
            <VehicleClassIconSet :icon="scope.selectedOption || null" class="ml-4 h-[40px] w-auto" />
            <SfSysChevronDownIcon size="xxs" class="vehicle-class-icon-inner__arrow" />
        </template>
        <template v-else>
            <VehicleClassIconSet :icon="scope.option" class="ml-4 h-[40px] w-auto" height="40" width="auto" />
            <SfSysCheckIcon v-if="scope.isActive" size="xs" />
        </template>
    </div>
</template>

<script>
import VehicleClassIconSet from '@/components/IconSet/VehicleClassIconSet';

import { SfSysCheckIcon, SfSysChevronDownIcon } from '@schuettflix/vue-components';

export default {
    name: 'VehicleClassIconInner',
    components: {
        VehicleClassIconSet,

        SfSysCheckIcon,
        SfSysChevronDownIcon,
    },
    props: {
        mode: {
            type: String,
            required: true,
            validator: v => ['button', 'option'].includes(v),
        },
        scope: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.vehicle-class-icon-inner--button {
    height: 70px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: #fff;

    display: grid;
    grid-template-columns: 1fr 40px;
    column-gap: 15px;
    align-items: center;
    padding-left: 15px;
}

.vehicle-class-icon-inner--option {
    display: grid;
    grid-template-columns: 1fr 40px;
    column-gap: 15px;
    align-items: center;
}
</style>

<template>
    <FlyoutPage show-header :headline="String($t('pages.platformSettingsItem.headline'))" @close="$emit('close')">
        <div class="container-off-canvas-md my-6">
            <h3 class="font-copy-md-strong mb-2">{{ $t(item.name) }}</h3>
            <p class="font-copy-sm mb-4">{{ $t(item.description) }}</p>

            <ErrorMessage v-if="error" class="mb-2" :message="$t(error)" />

            <GridRow :count="1" spacing="medium">
                <TextField
                    v-if="type === 'string'"
                    v-model="value"
                    :has-error="hasErrors('value')"
                    :error="getError('value')"
                    :label="$t('pages.platformSettings.marketValue')"
                    type="text"
                    @input="isValid()"
                />
                <SelectBox
                    v-if="type === 'location_provider_choice'"
                    v-model="value"
                    :error="getError('value')"
                    :has-error="hasErrors('value')"
                    :label="$t(item.name)"
                    :value="value"
                    @input="isValid()"
                >
                    <option :key="0" value="aws">AWS</option>
                    <option :key="1" value="google">Google</option>
                </SelectBox>
                <TextField
                    v-if="type === 'integer'"
                    v-model.number="value"
                    :has-error="hasErrors('value')"
                    :error="getError('value')"
                    :label="$t('pages.platformSettings.marketValue')"
                    type="number"
                    step="1"
                    pattern="\d*"
                    @input="isValid()"
                />
                <TextField
                    v-if="type === 'float'"
                    v-model.number="value"
                    :has-error="hasErrors('value')"
                    :error="getError('value')"
                    :label="$t('pages.platformSettings.marketValue')"
                    type="number"
                    step="0.01"
                    @input="isValid()"
                />

                <label v-if="type === 'boolean'">
                    <ToggleSwitch v-model="value" name="switch" />
                    <Words bold style="margin-left: 10px">{{ $t('pages.platformSettings.marketValue') }}</Words>
                </label>
            </GridRow>

            <GridRow :count="1" spacing="medium">
                <TextField
                    v-if="type === 'string'"
                    v-model="defaultValue"
                    :has-error="hasErrors('defaultValue')"
                    :error="getError('defaultValue')"
                    :label="$t('pages.platformSettings.defaultValue')"
                    type="text"
                    :readonly="!$can('updateGlobalVariableDefaultValue')"
                    @input="isValid()"
                />
                <TextField
                    v-if="type === 'integer'"
                    v-model.number="defaultValue"
                    :has-error="hasErrors('defaultValue')"
                    :error="getError('defaultValue')"
                    :label="$t('pages.platformSettings.defaultValue')"
                    type="number"
                    step="1"
                    pattern="\d*"
                    :readonly="!$can('updateGlobalVariableDefaultValue')"
                    @input="isValid()"
                />
                <TextField
                    v-if="type === 'float'"
                    v-model.number="defaultValue"
                    :has-error="hasErrors('defaultValue')"
                    :error="getError('defaultValue')"
                    :label="$t('pages.platformSettings.defaultValue')"
                    type="number"
                    step="0.01"
                    :readonly="!$can('updateGlobalVariableDefaultValue')"
                    @input="isValid()"
                />

                <label v-if="type === 'boolean'">
                    <ToggleSwitch
                        v-model="defaultValue"
                        label=""
                        name="switch"
                        :disabled="!$can('updateGlobalVariableDefaultValue')"
                    />
                    <Words bold style="margin-left: 10px">{{ $t('pages.platformSettings.defaultValue') }}</Words>
                </label>
            </GridRow>

            <template v-if="item.isScoped">
                <GridRow :count="1" spacing="default">
                    <h3 class="font-copy-md-strong mt-6">
                        {{ $t('pages.platformSettingsItem.countryScopeTitle') }}
                    </h3>

                    <SelectBox v-model="selectedCountry" :label="$t('pages.platformSettingsItem.selectCountryLabel')">
                        <option v-for="country in availableCountries" :key="country" :value="country">
                            {{ $t(`countries.${country.toLowerCase()}.name`) }}
                        </option>
                    </SelectBox>
                </GridRow>

                <GridRow :count="1" spacing="default">
                    <h3 class="font-copy-md-strong mt-6">
                        {{ $t('pages.platformSettingsItem.addScope') }}
                    </h3>

                    <TextField
                        v-model="newScope"
                        :has-error="hasErrors('newScope')"
                        :error="getError('newScope')"
                        :label="$t('pages.platformSettingsItem.scope')"
                        type="text"
                        @input="resetNewScopeValidation"
                    />

                    <ButtonComponent primary @click="addScope">
                        <template #left><PlusIcon width="18" height="18" class="icon--inline" /></template>
                        {{ $t('pages.platformSettingsItem.scopes') }}
                    </ButtonComponent>
                </GridRow>

                <Words
                    v-if="!!scopedValuesBySelectedCountry.length"
                    block
                    bold
                    class="global-variable-item__scope-title"
                >
                    {{ $t('pages.platformSettingsItem.scopes') }}
                </Words>

                <GridRow
                    v-for="(scope, i) in scopedValuesBySelectedCountry"
                    :key="scope.scope"
                    :count="1"
                    spacing="default"
                >
                    <TextField
                        v-if="type === 'string'"
                        v-model="scope.value"
                        :has-error="hasErrors(`scopeValues.${i}.value`)"
                        :error="getError(`scopeValues.${i}.value`)"
                        :label="$t('pages.platformSettingsItem.scopeExplicit', { scope: scope.scope })"
                        type="text"
                        @input="isValid()"
                    />
                    <TextField
                        v-if="type === 'integer'"
                        v-model.number="scope.value"
                        :has-error="hasErrors(`scopeValues.${i}.value`)"
                        :error="getError(`scopeValues.${i}.value`)"
                        :label="$t('pages.platformSettingsItem.scopeExplicit', { scope: scope.scope })"
                        type="number"
                        step="1"
                        pattern="\d*"
                        @input="isValid()"
                    />
                    <TextField
                        v-if="type === 'float'"
                        v-model.number="scope.value"
                        :has-error="hasErrors(`scopeValues.${i}.value`)"
                        :error="getError(`scopeValues.${i}.value`)"
                        :label="$t('pages.platformSettingsItem.scopeExplicit', { scope: scope.scope })"
                        type="number"
                        step="0.01"
                        @input="isValid()"
                    />

                    <SelectBox
                        v-if="type === 'boolean'"
                        v-model="scope.value"
                        cast-boolean
                        :label="$t('pages.platformSettingsItem.scopeExplicit', { scope: scope.scope })"
                    >
                        <option value>
                            {{ $t('pages.platformSettingsItem.useDefault') }}
                        </option>
                        <option :value="1">
                            {{ $t('pages.platformSettingsItem.on') }}
                        </option>
                        <option :value="0">
                            {{ $t('pages.platformSettingsItem.off') }}
                        </option>
                    </SelectBox>
                </GridRow>
            </template>
        </div>

        <template #footer>
            <ButtonGroup>
                <ButtonComponent primary :is-loading="isPending" @click="save">
                    <template #left><TickIcon width="18" height="18" class="icon--inline" /></template>
                    {{ $t('pages.platformSettingsItem.save') }}
                </ButtonComponent>
            </ButtonGroup>
        </template>
    </FlyoutPage>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import GlobalVariablesApi from '@/services/Api/GlobalVariables';

import FlyoutPage from '@/components/Layout/FlyoutPage';
import Words from '@/components/Typography/Words';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ButtonComponent from '@/components/Button/Button';
import TextField from '@/components/Form/TextField.v2';
import ToggleSwitch from '@/components/Form/ToggleSwitch';
import GridRow from '@/components/Layout/GridRow';
import ErrorMessage from '@/components/Form/ErrorMessage';
import SelectBox from '@/components/Form/SelectBox.v2';

import validate from '@/services/validation/mixin';
import { isRequired, isNumber, isFloat, isOneOf } from '@/services/validation/rules';

import TickIcon from '@/assets/icons/micro/tick.svg';
import PlusIcon from '@/assets/icons/micro/plus.svg';

export default {
    name: 'SettingsItemPage',
    components: {
        FlyoutPage,
        Words,
        ButtonGroup,
        TextField,
        ToggleSwitch,
        ButtonComponent,
        GridRow,
        ErrorMessage,
        SelectBox,
        TickIcon,
        PlusIcon,
    },
    mixins: [validate],
    props: {
        item: {
            type: Object,
            default: null,
        },
        availableCountries: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            value: null,
            defaultValue: null,
            error: false,
            scopeValues: [],
            // the inital selected country is the current market of the user
            selectedCountry: this.item.marketCode,
            newScope: null,
            isPending: false,
        };
    },
    computed: {
        type() {
            return this.item && this.item.type;
        },
        validationRules() {
            let valueRules;
            let defaultValueRules;
            let scopeRules = [];

            switch (this.type) {
                case 'integer':
                    valueRules = [isNumber()];
                    defaultValueRules = [isNumber(), isRequired()];
                    scopeRules = [isNumber()];
                    break;
                case 'float':
                    valueRules = [isFloat()];
                    defaultValueRules = [isFloat(), isRequired()];
                    scopeRules = [isFloat()];
                    break;
                case 'string':
                    valueRules = [];
                    defaultValueRules = [isRequired()];
                    break;
                case 'location_provider_choice':
                    valueRules = [isOneOf(null, ['aws', 'google'])];
                    defaultValueRules = [isRequired()];
                    break;
                default:
                    valueRules = [];
                    defaultValueRules = [];
                    scopeRules = [];
            }

            const rules = {
                value: valueRules,
                defaultValue: defaultValueRules,
            };

            this.scopeValues.forEach((scope, i) => {
                rules[`scopeValues.${i}.value`] = scopeRules;
            });

            return rules;
        },
        scopedValuesBySelectedCountry() {
            return this.scopeValues.filter(scope => scope.country === this.selectedCountry);
        },
    },
    created() {
        this.scopeValues = _cloneDeep(this.item.scope || []);
        this.value = _cloneDeep(this.item.value);
        this.defaultValue = _cloneDeep(this.item.defaultValue);
    },
    methods: {
        isScopeUnique(v) {
            if (!v) return true;
            return (
                !this.scopeValues.map(item => item.scope).includes(v) || 'Der Gültigkeitsbereich ist bereits vorhanden.'
            );
        },

        addScope() {
            if (!this.isValid(false, { newScope: [isRequired(), this.isScopeUnique] })) return;

            this.scopeValues.push({
                scope: this.newScope,
                country: this.selectedCountry,
                value: null,
            });
            this.newScope = null;
        },

        resetNewScopeValidation() {
            this.clearSingleValidation('newScope');
        },

        async save() {
            if (!this.isValid() || this.isPending) return;
            this.isPending = true;

            const confirmation = window.confirm(this.$t('globalvariables.confirmation'));
            if (!confirmation) {
                this.isPending = false;
                return;
            }

            try {
                const data = {
                    value: this.value,
                    defaultValue: this.defaultValue,
                    isScoped: this.isScoped,
                };

                if (this.item.isScoped) {
                    data.scope = this.scopeValues
                        ? _filter(this.scopeValues, item => item.value !== null && item.value !== '')
                        : [];
                }

                await GlobalVariablesApi.updateById(this.item.id, data);

                this.$emit('saved');
                this.$emit('close');
            } catch (err) {
                this.error = err;
                this.parseServerValidation(_get(err, 'response.data.errors', {}), {
                    'scopeValues.*.value': 'scope.*.scope',
                });
            }

            this.isPending = false;
        },
    },
};
</script>

<style lang="scss">
.global-variable-item__scope-title {
    margin-top: 3em;
    margin-bottom: 1em;
}
</style>

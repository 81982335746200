<template>
    <div class="disposal-number" data-test="disposal-number">
        <div
            class="disposal-number__wrapper"
            :class="{ 'disposal-number__wrapper--focused': disposalNumberFocused }"
            data-test="disposal-number-wrapper"
        >
            <input
                ref="disposalNumberText"
                data-test="disposal-number-location-state"
                :value="disposalNumber.locationState"
                :readonly="isReadOnly || isLocationStateReadOnly"
                :disabled="isDisabled"
                v-bind="getTextInputProperties"
                @paste="pasteDisposalString($event, true)"
                @click="focusOnDisposerState"
                @blur="blurDisposerNumberGroup"
                @focus="focusOnDisposerState"
                @input="updateDisposerState"
            />
            <input
                v-for="(value, index) in disposalNumber.alphanumerics"
                ref="disposalNumberNumber"
                :key="`disposalNumberNumber_${index}`"
                :data-test="`disposal-number-alphanumeric-${index}`"
                :disabled="isDisabled"
                v-bind="getNumberInputProperties(value.alphanumeric, index)"
                @paste="pasteDisposalString($event, false)"
                @keydown="allowAlphanumericsOnly"
                @blur="blurDisposerNumberGroup"
                @focus="focusOnDisposerNumber(index)"
                @input="updateDisposerNumber($event, index)"
            />

            <Cancel
                v-if="showValidationErrors"
                class="disposal-number__validation-mark disposal-number__validation-mark--cancel"
            />
            <Check
                v-else-if="showValidationSuccess"
                class="disposal-number__validation-mark disposal-number__validation-mark--check"
            />
        </div>

        <input type="hidden" :name="name" :value="disposalNumberString" />

        <div v-if="showValidationErrors && !hasError" class="disposal-number__error" data-test="disposal-number-error">
            {{ $t(errorMessage) }}
        </div>
        <div
            v-if="!disposalNumberValid || (hasError && !disposalNumberValid)"
            class="disposal-number__error"
            data-test="disposal-number-error"
        >
            {{ error }}
        </div>
    </div>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';

import { stateIdentifiers } from '@/services/Disposal/StateIdentifiers';
import numberMixins from '@/services/utils/inputValidation.js';

import Cancel from '@/assets/icons/regular/cancel-cross.svg';
import Check from '@/assets/icons/regular/checkmark.svg';

export default {
    Name: 'DisposalNumber',
    components: {
        Cancel,
        Check,
    },
    mixins: [numberMixins],
    props: {
        address: {
            type: Object,
            default: () => ({}),
        },
        value: {
            type: String,
            default: null,
        },
        disposalNumberString: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isLocationStateReadOnly: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: null,
        },
        errorMessage: {
            type: String,
            default: 'components.disposalNumber.disposer.errorMessage',
        },
        acceptEmptyDisposalNumber: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            disposalNumberFocused: false,
            disposalNumberValid: false,
            validationProcessed: false,
            readonly: true,
            regex: {
                lettersOnly: /^[a-z]$/i,
                backspace: /^[\b]*$/,
                alphanumeric: /^[a-z0-9]$/i,
            },
            numberField: {
                newIndex: 0,
                index: 0,
            },
            stateIdentifiers: stateIdentifiers,
            disposalNumber: {
                locationState: '',
                alphanumerics: [
                    { alphanumeric: '' },
                    { alphanumeric: '' },
                    { alphanumeric: '' },
                    { alphanumeric: '' },
                    { alphanumeric: '' },
                    { alphanumeric: '' },
                    { alphanumeric: '' },
                    { alphanumeric: '' },
                ],
            },
        };
    },

    computed: {
        showValidationErrors() {
            return this.validationProcessed && !this.disposalNumberValid && !this.disposalNumberFocused;
        },

        showValidationSuccess() {
            if (this.acceptEmptyDisposalNumber) {
                return this.validationProcessed && this.disposalNumberValid && this.disposalNumberString;
            }
            return this.validationProcessed && this.disposalNumberValid;
        },

        getTextInputProperties() {
            return {
                class: ['disposal-number__input', 'disposal-number__input--text'],
                name: 'locationState',
                type: 'text',
                maxlength: 1,
                autocomplete: 'off',
                placeholder: 'A',
                'data-test': 'disposal-number-text',
            };
        },
        hasError() {
            return !!this.error;
        },
    },

    watch: {
        disposalNumberFocused() {
            this.validationProcessed = true;
            this.disposalNumberValid = !!(this.acceptEmptyDisposalNumber && !this.disposalNumberString);

            if (this.validateStateIdentifier() && this.validateDisposalNumber()) {
                this.disposalNumberValid = true;
                this.emitDisposerNumber();
            }
        },

        disposalNumberValid(isValid) {
            this.$emit('disposalNumberValid', isValid);
        },

        isReadOnly: {
            immediate: true,
            handler() {
                if (this.isReadOnly) {
                    this.readonly = true;
                }
            },
        },

        hasError(is) {
            this.disposalNumberValid = !is;
        },

        disposalNumberString: {
            immediate: true,
            handler() {
                if (this.acceptEmptyDisposalNumber) {
                    if (this.disposalNumberString === null || this.disposalNumberString?.length === 1) {
                        this.disposalNumber.alphanumerics.forEach(key => {
                            key.alphanumeric = '';
                        });
                        this.disposalNumberValid = true;
                    } else {
                        const disposalNumberArray = [...this.disposalNumberString];
                        if (disposalNumberArray.length === 9) {
                            this.disposalNumber.locationState = disposalNumberArray[0];
                            disposalNumberArray.splice(0, 1);
                            Object.keys(disposalNumberArray).forEach(key => {
                                this.disposalNumber.alphanumerics[key] = { alphanumeric: disposalNumberArray[key] };
                            });
                            this.disposalNumberValid = true;
                        } else {
                            this.disposalNumberValid = false;
                        }
                    }

                    this.validationProcessed = true;
                    this.emitDisposerNumber();
                    this.$emit('disposalNumberValid', this.value);
                } else {
                    this.$emit('disposalNumberValid', !_isEmpty(this.value));
                }
            },
        },
    },
    mounted() {
        this.disposalNumber.locationState = this.stateIdentifiers.find(stateIdentifier => {
            return stateIdentifier.shortName.toLowerCase() === this.address?.state?.toLowerCase();
        })?.wasteStateLetter;
        window.addEventListener('click', this.documentBlurEvent);
        window.addEventListener('keyup', this.handleBlurEvent);

        if (this.disposalNumberString) {
            const disposalNumberArray = [...this.disposalNumberString];
            if (disposalNumberArray.length === 9) {
                this.disposalNumber.locationState = disposalNumberArray[0];
                disposalNumberArray.splice(0, 1);
                Object.keys(disposalNumberArray).forEach(key => {
                    this.disposalNumber.alphanumerics[key] = { alphanumeric: disposalNumberArray[key] };
                });
                this.validationProcessed = true;
                this.disposalNumberValid = true;
                this.emitDisposerNumber();
            }
        }
    },

    beforeDestroy() {
        window.removeEventListener('click', this.documentBlurEvent);
        window.removeEventListener('keyup', this.handleBlurEvent);
    },

    methods: {
        pasteDisposalString($event, withState = false) {
            const disposalString = $event.clipboardData.getData('text');
            const requiredLength = withState ? 9 : 8;
            if (disposalString.length !== requiredLength) {
                return;
            }
            [...disposalString].forEach((char, idx) => {
                if (withState && idx === 0) {
                    if (!this.validateStateIdentifier()) {
                        return;
                    }
                    this.disposalNumber.locationState = char;
                    return;
                }

                this.disposalNumber.alphanumerics[idx - 1] = { alphanumeric: char };
            });
        },
        getNumberInputProperties(value = '', index = 0) {
            return {
                class: ['disposal-number__input', 'disposal-number__input--number'],
                value,
                name: `alphanumeric${index}`,
                type: 'text',
                maxlength: 1,
                min: 0,
                max: 9,
                step: 1,
                autocomplete: 'off',
                readonly: this.readonly,
                placeholder: +index + 1,
                'data-test': 'disposal-number-number',
            };
        },

        validateStateIdentifier() {
            return stateIdentifiers.some(identifiers => {
                return identifiers.wasteStateLetter.toLowerCase() === this.disposalNumber?.locationState?.toLowerCase();
            });
        },

        validateDisposalNumber() {
            let valid = true;
            this.disposalNumber.alphanumerics.forEach(value => {
                if (value.alphanumeric === '') {
                    valid = false;
                }
            });

            return valid;
        },

        emitDisposerNumber() {
            this.$emit('disposalNumber', {
                object: this.disposalNumber,
                string: this.getDisposerNumberString(),
                isValid: this.disposalNumberValid,
            });
            this.$emit('input', this.getDisposerNumberString());
        },

        getDisposerNumberString() {
            return `${this.disposalNumber.locationState}${this.disposalNumber.alphanumerics
                .map(value => value.alphanumeric)
                .join('')}`;
        },

        focusOnDisposerState() {
            if (this.isReadOnly) {
                return;
            }
            this.activateDisposerNumberGroup();
            this.$refs.disposalNumberText.focus();
        },

        focusOnDisposerNumber(index = 0) {
            if (this.isReadOnly) {
                return;
            }
            this.activateDisposerNumberGroup();
            this.$refs.disposalNumberNumber[index].focus();
            this.$refs.disposalNumberNumber[index].select();
        },

        updateDisposerState($event) {
            const value = $event?.target?.value;
            if (value.length <= 1) {
                this.updateTextField($event);
                this.disposalNumberValid = this.validateStateIdentifier() && this.validateDisposalNumber();
                this.emitDisposerNumber();
            }
            this.$forceUpdate();
        },

        updateDisposerNumber($event, index = null) {
            const value = $event?.target?.value;
            if (value.length <= 1) {
                this.updateAlphanumeric($event, index);
                this.disposalNumberValid = this.validateStateIdentifier() && this.validateDisposalNumber();
                this.emitDisposerNumber();
            }
            this.$forceUpdate();
        },

        activateDisposerNumberGroup() {
            this.disposalNumberFocused = true;
            this.readonly = false;
        },

        blurDisposerNumberGroup() {
            this.disposalNumberFocused = false;
        },

        updateTextField($event) {
            const value = $event?.target.value;
            if (this.regex.lettersOnly.test(value) || this.regex.backspace.test(value)) {
                const firstDisposerNumberInArray = this.$refs.disposalNumberNumber[0];
                this.disposalNumber.locationState = value.toUpperCase();
                firstDisposerNumberInArray.focus();
                firstDisposerNumberInArray.select();
            }
        },

        updateAlphanumeric($event, index) {
            if ($event.data === '') return;

            const value = $event?.target?.value;
            const disposalNumberField = this.$refs.disposalNumberNumber;
            const isAlphanumeric = this.regex.alphanumeric.test(value);
            const isBackSpace = this.regex.backspace.test(value);

            if (!isAlphanumeric && !isBackSpace) {
                return;
            }
            this.disposalNumber.alphanumerics[index].alphanumeric = value.toString().toUpperCase();

            const newIndex = index + 1;
            if (newIndex < this.disposalNumber.alphanumerics.length) {
                if (!isBackSpace) {
                    disposalNumberField[newIndex].focus();
                    disposalNumberField[newIndex].select();
                }
            } else if (newIndex === this.disposalNumber.alphanumerics.length && !isBackSpace) {
                disposalNumberField[index].blur();
                this.disposalNumberFocused = false;
            }
        },

        handleBlurEvent($event) {
            if ($event?.key === 'Tab' && $event?.target?.nodeName !== 'INPUT') {
                this.disposalNumberFocused = false;
            }
        },

        documentBlurEvent(event) {
            if (![...event.target.parentElement.classList].includes('disposal-number__wrapper')) {
                this.disposalNumberFocused = false;
                this.readonly = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.disposal-number {
    width: 100%;

    &__title {
        span {
            display: block;
            padding-bottom: 8px;
        }
    }

    &__error {
        color: $color-error;
        font-size: 12px;
        font-weight: 500;
        margin-top: 8px;
    }

    &__validation-mark {
        margin-left: 16px;

        &--check {
            color: $color-success;
            height: 24px;
            width: 24px;
        }

        &--cancel {
            color: $color-error;
            height: 24px;
            width: 24px;
        }
    }

    &__input {
        outline: 0;
        width: 48px;
        height: 72px;
        text-align: center;
        font-size: 18px;
        -moz-appearance: textfield;
        border: 1px solid $color-paleGrey;
        color: $color-darkGrey;
        font-weight: $font-weight-bold;
        font-family: $font-family;
        flex-shrink: 1;

        &::placeholder {
            color: $color-paleGrey;
            opacity: 1;
        }

        &--number {
            width: calc((100% - 48px - 16px) / 8);
            max-width: 48px;
            &:not(:last-of-type) {
                border-right: 0;
            }
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &--text {
            margin-right: 16px;
            border-right: 1px solid $color-paleGrey;
        }
    }

    &__wrapper {
        align-items: center;
        display: flex;
        width: 100%;

        &--focused {
            .disposal-number__input {
                &--text {
                    border: 1px solid $color-darkGrey;
                }

                &--number {
                    border-top-color: $color-darkGrey;
                    border-bottom-color: $color-darkGrey;

                    &:nth-of-type(2) {
                        border-left-color: $color-darkGrey;
                    }

                    &:last-of-type {
                        border-right-color: $color-darkGrey;
                    }
                }
            }
        }
    }
}
</style>

import { forwardRef } from 'react';
import clsx from 'clsx';
import { useIconSize } from '@/services/utils/useIconSize';
export const WarningIcon = forwardRef(({ className, size = 'xs', strokeCssClass = 'stroke-icon', ...svgProps }, ref) => {
    const iconSize = useIconSize(size);
    return (<svg width={iconSize} height={iconSize} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} className={clsx('cursor-pointer', className, strokeCssClass)} {...svgProps}>
                <path d="M19.4104 21H4.58953C4.30543 21 4.02652 20.9173 3.78183 20.7604C3.53714 20.6035 3.33561 20.3782 3.19822 20.1079C3.06083 19.8377 2.9926 19.5324 3.00064 19.2237C3.00867 18.9151 3.09267 18.6145 3.24389 18.3531L10.6662 3.80793C10.8094 3.56039 11.0081 3.35642 11.2437 3.21511C11.4792 3.0738 11.7439 2.99979 12.013 3C12.282 3.00021 12.5466 3.07464 12.782 3.21632C13.0174 3.358 13.2158 3.56229 13.3587 3.81005L20.7573 18.3552C20.9081 18.6166 20.9917 18.9172 20.9994 19.2256C21.0071 19.534 20.9387 19.8391 20.8012 20.109C20.6637 20.379 20.4622 20.604 20.2177 20.7607C19.9731 20.9174 19.6944 21 19.4104 21V21Z" className={strokeCssClass} strokeWidth="2" strokeMiterlimit="10"/>
                <path d="M12 8V15.0065M12 17V18.9999" className={strokeCssClass} strokeWidth="2" strokeMiterlimit="10"/>
            </svg>);
});
WarningIcon.displayName = 'WarningIcon';

<template>
    <div ref="component" class="dropdown-select">
        <div
            ref="select"
            class="dropdown-select__select hover:border-hovered"
            :class="[
                {
                    'dropdown-select__select--disabled': disabled,
                    'dropdown-select__select--focused': isActivated && !disabled,
                    'dropdown-select__select--opened': isOpen,
                    'dropdown-select__select--error': hasError,
                },
            ]"
            :aria-disabled="disabled"
            :aria-expanded="isOpen"
            @focusin="onFocus"
            @focusout="onBlur"
            @keydown.esc="close"
            @keydown.down.prevent="hoverNext"
            @keydown.up.prevent="hoverPrev"
        >
            <div v-if="showFilter" class="dropdown-select__input-wrapper">
                <input
                    ref="filter"
                    v-model="search"
                    type="text"
                    role="searchbox"
                    class="dropdown-select__filter-input"
                    data-test="filterable"
                    :disabled="disabled"
                    @keydown.enter.prevent.stop.exact="selectHovered"
                />
            </div>
            <button
                v-else
                ref="button"
                class="dropdown-select__label-area"
                :class="{ 'dropdown-select__label-area--disabled': disabled }"
                @click="toggle"
                @keydown.space.prevent.exact="toggle"
                @keydown.enter.prevent.stop.exact="selectHovered"
            >
                <span
                    v-if="label"
                    class="dropdown-select__label"
                    :class="{
                        'dropdown-select__label--full': !selectedValueExistsInOptions,
                    }"
                >
                    {{ label }}
                </span>
                <div v-if="selectedValueExistsInOptions" class="dropdown-select__selected-value">
                    {{ selectedValue }}
                </div>
            </button>
            <div class="dropdown-select__select__caret">
                <slot name="right" />
                <ArrowUpIcon
                    class="dropdown-select__select__caret-icon"
                    :class="{
                        'icon--muted': disabled,
                        'icon--rotate-180': !isOpen,
                    }"
                />
            </div>
        </div>
        <ul
            v-if="isOpen"
            ref="dropdown"
            class="dropdown-select__dropdown"
            :class="{ 'dropdown-select__dropdown--top': positionTop }"
            role="listbox"
        >
            <li
                v-for="(option, index) in filteredOptions"
                :key="`${option.value}-${option.label}`"
                role="option"
                class="dropdown-select__dropdown__option"
                :data-test="`dropdownSelect-option-${index}`"
                :class="{
                    'dropdown-select__dropdown__option--selected': value === option.value,
                    'dropdown-select__dropdown__option--hovered': hoveredOption === index,
                }"
                @click.stop="select(option.value)"
                @mouseover="hoveredOption = index"
            >
                <slot name="option" :option="option" :label="option.label">{{ option.label }}</slot>
            </li>
        </ul>
        <div v-if="isOpen" class="dropdown-select__backdrop" @click="close" />
    </div>
</template>

<script>
import ArrowUpIcon from '@/assets/icons/regular/arrow-stripeless--up.svg';

export default {
    name: 'DropdownSelect',
    components: {
        ArrowUpIcon,
    },
    props: {
        value: {
            type: [String, Boolean, Number, Object, Array],
            default: null,
        },
        options: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        filterable: {
            type: Boolean,
            default: false,
        },
        tabindex: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            isOpen: false,
            isFocused: false,
            positionTop: false,
            search: '',
            hoveredOption: null,
        };
    },
    computed: {
        selectedValue() {
            const found = this.options.find(option => option.value === this.value);

            return found ? found.label : null;
        },
        filteredOptions() {
            if (!this.filterable) {
                return this.options;
            }

            return this.options.filter(option => {
                return option.label.toLowerCase().includes(this.search.toLowerCase());
            });
        },
        showFilter() {
            return this.filterable && this.isOpen;
        },
        isActivated() {
            return this.showFilter || this.isFocused || this.isOpen;
        },
        selectedValueExistsInOptions() {
            if (!Array.isArray(this.options)) {
                return false;
            }

            return this.options.some(option => option.value === this.value);
        },
        handledTabindex() {
            if (this.disabled) {
                return -1;
            }

            return this.tabindex;
        },
    },
    watch: {
        search() {
            this.hoveredOption = this.filteredOptions.length ? 0 : null;
        },
        isOpen(isOpened) {
            if (isOpened) {
                this.$nextTick(this.positionDropdown);
            }
        },
    },
    methods: {
        toggle() {
            this.isOpen ? this.close() : this.open();
        },

        close() {
            this.isOpen = false;
            this.positionTop = false;
            this.$emit('leave-search');

            setTimeout(() => {
                this.hoveredOption = null;
                this.search = '';
                if (this.$refs.button) {
                    this.$refs.button.focus();
                }
            }, 50);
        },
        open() {
            if (this.disabled) {
                return;
            }

            this.isOpen = true;
            this.hoveredOption = 0;
            if (this.filterable) {
                this.$nextTick(() => {
                    this.$refs.filter.focus();
                });
            }
        },
        positionDropdown() {
            const { dropdown } = this.$refs;

            const { top, height } = dropdown.getBoundingClientRect();

            const bottomFringe = top + height;

            this.positionTop = bottomFringe > window.innerHeight;
        },
        select(option) {
            if (this.disabled) {
                return;
            }

            this.$emit('input', option);
            this.close();
        },
        hoverNext() {
            if (!this.isOpen) {
                return;
            }

            if (this.hoveredOption === null) {
                this.hoveredOption = 0;
                this.scrollToTop();
                return;
            }

            if (this.hoveredOption === this.filteredOptions.length - 1) {
                this.hoveredOption = 0;
                this.scrollToTop();
                return;
            }

            this.hoveredOption++;
        },
        hoverPrev() {
            if (!this.isOpen) {
                return;
            }

            if (this.hoveredOption === null) {
                this.hoveredOption = this.filteredOptions.length - 1;
                this.scrollToBottom();
                return;
            }

            if (this.hoveredOption === 0) {
                this.hoveredOption = this.filteredOptions.length - 1;
                this.scrollToBottom();
                return;
            }

            this.hoveredOption--;
        },
        scrollToBottom() {
            const { dropdown } = this.$refs;

            dropdown.scroll({
                top: dropdown.scrollHeight,
                behavior: 'smooth',
            });
        },
        scrollToTop() {
            const { dropdown } = this.$refs;

            dropdown.scroll({
                top: 0,
                behavior: 'smooth',
            });
        },
        selectHovered() {
            if (!this.isOpen) {
                return;
            }

            if (this.hoveredOption === null) {
                return;
            }

            this.select(this.filteredOptions[this.hoveredOption].value);
        },
        onFocus() {
            if (this.disabled) {
                return;
            }

            this.isFocused = true;
        },
        onBlur() {
            this.isFocused = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.dropdown-select {
    position: relative;
    width: 100%;

    &__select {
        border: 1px solid $sflx-grey-400;
        border-radius: 4px;
        padding: 0 16px;
        height: 72px;
        width: 100%;
        background-color: $color-white;
        cursor: pointer;
        position: relative;
        outline: none;

        &--disabled {
            background-color: $sflx-grey-100;
            cursor: not-allowed;

            &#{&} {
                border-color: $sflx-grey-400;
            }
        }
        &--error {
            border: 1px solid $sflx-red-800;
        }

        &--focused {
            border: 1px solid $sflx-grey-800;
        }

        &:focus {
            outline: none;
        }

        &__caret {
            position: absolute;
            right: 16px;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            gap: 16px;
            pointer-events: none;

            &--disabled {
                fill: $sflx-grey-400;
            }
        }

        &__caret-icon {
            transition: all 0.2s ease-out;
        }
    }

    &__label-area {
        background-color: transparent;
        border: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        justify-content: center;
        width: 100%;
        border-radius: 4px;

        &--disabled {
            background-color: $sflx-grey-100;
            color: $color-subdued;
            cursor: not-allowed;
        }
    }

    &__label {
        font-size: 12px;
        color: #64656c;
        text-align: left;
        font-family: $font-family;

        &--full {
            font-size: 14px;
            color: unset;
        }
    }

    &__selected-value {
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        padding-right: 32px;
        text-align: left;
        font-family: $font-family;
    }

    &__input-wrapper {
        display: flex;
        align-items: center;
        height: 72px;
        flex-grow: 1;
    }

    &__filter-input {
        border: 0;
        height: 60px;
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: -16px;
        padding: 16px;
        font-family: $font-family;
        font-size: $font-size-base;
        position: relative;
        z-index: 200;

        &:focus {
            outline: none;
        }

        &:focus:not(:focus-visible) {
            outline: 1px solid $sflx-grey-800;
        }
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 200;
        background-color: $color-white;
        list-style: none;
        padding: 0;
        margin: 0;
        box-shadow: $boxShadow-bottom;
        max-height: 312px;
        border: 1px solid #eaeaea;
        overflow-y: auto;

        &--top {
            top: unset;
            bottom: 100%;
        }

        &__option {
            height: 56px;
            padding: 0 16px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;
            font-family: $font-family;

            &--selected {
                background-color: $sflx-grey-400;
            }

            &--hovered {
                background-color: $sflx-grey-100;
            }
        }
    }

    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background-color: transparent;
    }
}
</style>

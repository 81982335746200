<template>
    <div class="shipment-address-picker">
        <Words block bold spaced-bottom>
            <slot name="headline" />
        </Words>
        <Words block muted spaced-bottom>
            <slot name="subline" />
        </Words>

        <div class="shipment-address-picker__address-container">
            <LocationPicker
                v-model="newLocation"
                :screen-name="locationPickerScreenName"
                :initial-address="selectedAddress"
                :allowed-countries="allowedCountries"
                map-type="satellite"
                enable-search
                ensure-state
                allow-incomplete-address
                @input="handleLocationUpdate"
                @input-address="handleAddressUpdateByPlace"
            >
                <div slot-scope="{ editLocation }" class="shipment-address-picker__address-field" @click="editLocation">
                    <TextField
                        :label="$t('components.addressPicker.addressFieldLabel')"
                        :value="addressLabel"
                        readonly
                        disabled
                        :error="error"
                        data-test="location-picker"
                    >
                        <template #icon>
                            <CircleSpinner v-if="isAllowedCountriesLoading" dark tiny />
                            <AddressIcon v-else />
                        </template>
                    </TextField>
                </div>
            </LocationPicker>

            <BaseButton
                icon
                class="shipment-address-picker__addressbook-button"
                data-test="addressbook-button"
                @click="clickAddressBookButton"
            >
                <AddressBookIcon />
            </BaseButton>
        </div>

        <OpenLocationCodeField :location="newLocation" class="constructionSite-add-step-top-margin mt-2" />

        <TextField
            v-if="showAdditionalAddressTextField"
            v-model="selectedAddress.additionalAddress"
            class="shipment-address-picker__additional-field"
            :label="$t('components.addressPicker.additionalAddress.inputLabel')"
            @input="$emit('update-address', selectedAddress)"
        />

        <LastUsedCustomerAddressSelection
            v-if="type && !address"
            :client-organization-id="clientInfo.client.id"
            :type="type"
            :operations="operation"
            @click="selectLastUsedAddress"
        >
            <template #headline>
                <slot name="lastUsedAddressHeadline" />
            </template>
        </LastUsedCustomerAddressSelection>

        <Flyout
            :active="showAddressBook"
            :screen-name="addressBookScreenName"
            no-header
            size="small"
            appear-from="bottom"
            class="shipment-address-picker__book"
            @opening="fetchConstructionSiteAndSuppliers"
        >
            <template #header>
                <HeaderBar>
                    <div slot="headline">
                        {{ $t('pages.checkout.shipmentLoading.addressbook.title') }}
                    </div>
                    <HeaderBarItem slot="right" button @click="clickAddressBookFlyoutCloseButton">
                        <CloseIcon />
                    </HeaderBarItem>
                </HeaderBar>

                <TabNavigation
                    v-if="isCustom"
                    v-model="visibleList"
                    :stages="{
                        constructionSite: $t('pages.checkout.shipmentLoading.addressbook.tabConstructionSites'),
                        supplier: $t('pages.checkout.shipmentLoading.addressbook.tabSuppliers'),
                    }"
                    :stretch="true"
                    spaced
                    lineless
                    align-left
                />
                <template v-if="visibleList === 'constructionSite'">
                    <FilterBox
                        v-model="constructionSiteFilter"
                        :default-filter="constructionSiteDefaultFilter"
                        :endpoint="constructionSiteDataEndpoint"
                        inline-mode
                        @update="refreshConstructionSiteList()"
                    >
                        <template #default="filterScope">
                            <TextField
                                v-model="filterScope.filter.search"
                                :label="$t('pages.constructionSite.constructionSiteList.search')"
                            >
                                <SearchIcon slot="icon" />
                            </TextField>
                        </template>
                    </FilterBox>
                </template>
                <template v-else-if="isCustom && visibleList === 'supplier'">
                    <FilterBox
                        v-model="suppliersFilter"
                        :default-filter="suppliersDefaultFilter"
                        :endpoint="suppliersDataEndpoint"
                        inline-mode
                        @update="refreshSuppliersList()"
                    >
                        <template #default="filterScope">
                            <TextField
                                v-model="filterScope.filter.search"
                                :label="$t('pages.checkout.shipmentLoading.addressbook.supplierSearch')"
                            >
                                <SearchIcon slot="icon" />
                            </TextField>
                        </template>
                    </FilterBox>
                </template>
            </template>

            <template v-if="visibleList === 'constructionSite'">
                <transition name="fade" mode="out-in">
                    <LoadingSpinner v-if="!constructionSites" block dark />
                    <List v-else-if="constructionSites.count > 0">
                        <Card
                            v-for="(constructionSite, index) in constructionSites.items"
                            :key="index"
                            levitate
                            class="container-deprecated"
                        >
                            <ConstructionSiteItem
                                :item="constructionSite"
                                @click="selectConstructionSite(constructionSite)"
                            />
                        </Card>

                        <MoreResultsButton
                            v-if="constructionSites.count - constructionSites.items.length > 0"
                            :result="constructionSites"
                            fade-out
                        />
                    </List>
                    <Words v-else centered spaced block small muted>
                        {{ $t('pages.constructionSite.constructionSiteList.noResults') }}
                    </Words>
                </transition>
            </template>
            <template v-else-if="isCustom && visibleList === 'supplier'">
                <transition name="fade" mode="out-in">
                    <LoadingSpinner v-if="!suppliers" block dark />
                    <List v-else-if="suppliers.count > 0">
                        <Card
                            v-for="(supplierInfo, i) in suppliers.items"
                            :key="i"
                            class="container-deprecated"
                            levitate
                            @click="selectSupplier(supplierInfo)"
                        >
                            <SupplierTile
                                :supplier-info="supplierInfo"
                                clickable
                                show-address
                                spaceless
                                disable-distance-fetch
                                show-important-info
                            />
                        </Card>

                        <MoreResultsButton
                            v-if="suppliers.count - suppliers.items.length > 0"
                            :result="suppliers"
                            fade-out
                        />
                    </List>
                    <Words v-else centered spaced block small muted>
                        {{ $t('pages.invoice.selectOrganization.noOrganizationResults') }}
                    </Words>
                </transition>
            </template>
        </Flyout>
    </div>
</template>

<script>
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import { formatAddressObject } from '@/services/utils/address';
import { isRequired } from '@/services/validation/rules';
import validate from '@/services/validation/mixin';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import ConstructionSiteApi from '@/services/Api/ConstructionSite';
import SupplierApi from '@/services/Api/Supplier';
import SupplierListApi from '@/services/Api/Supplier/List';
import Toaster from '@/services/Toaster';
import { retrieveStateByLocation } from '@/services/utils/map';

import LocationPicker from '@/pages/Checkout/components/LocationPicker';
import Flyout from '@/components/Layout/Flyout';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import TabNavigation from '@/components/Tab/TabNavigation';
import TextField from '@/components/Form/TextField.v2';
import Words from '@/components/Typography/Words';
import BaseButton from '@/components/Button/Button';
import FilterBox from '@/components/Filter/FilterBox';
import List from '@/components/List/List';
import Card from '@/components/Layout/Card';
import ConstructionSiteItem from '@/components/List/ConstructionSiteItem';
import MoreResultsButton from '@/components/Filter/MoreResultsButton';
import SupplierTile from '@/components/Supplier/SupplierTile';
import LastUsedCustomerAddressSelection from './LastUsedCustomerAddressSelection';
import LoadingSpinner from '@/components/LoadingSpinner';
import OpenLocationCodeField from '@/components/Form/OpenLocationCodeField';
import CircleSpinner from '@/components/Spinners/CircleSpinner.vue';

import AddressIcon from '@/assets/icons/micro/house.svg';
import CloseIcon from '@/assets/icons/regular/close.svg';
import SearchIcon from '@/assets/icons/micro/search.svg';
import AddressBookIcon from '@/assets/icons/regular/addressbook.svg';
import {
    MPS_CUSTOMER_ORDER_SHIPMENT_LOADING_UNLOADING_LOCATIONS,
    MPS_PLATFORM_ORDER_PROJECT_SHIPMENT_LOADING_UNLOADING_LOCATIONS,
    MPS_PLATFORM_ORDER_CUSTOM_SHIPMENT_LOADING_UNLOADING_LOCATIONS,
} from '@/constants/marketPermissions';
import { useMarketPermission } from '@/services/MarketPermission/useMarketPermission';
import { computed, defineComponent } from 'vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { CONSTRUCTION_PROJECT_LIST_ROUTE } from '@/constructionProjects/constants';
import { useOrderScreenName } from '../analytics/vue/useOrderScreenName';

export default defineComponent({
    name: 'ShipmentAddressPicker',
    components: {
        Words,
        Flyout,
        HeaderBar,
        HeaderBarItem,
        TabNavigation,
        TextField,
        LocationPicker,
        BaseButton,
        FilterBox,
        List,
        Card,
        ConstructionSiteItem,
        MoreResultsButton,
        SupplierTile,
        LastUsedCustomerAddressSelection,
        LoadingSpinner,
        OpenLocationCodeField,
        CircleSpinner,

        AddressIcon,
        CloseIcon,
        SearchIcon,
        AddressBookIcon,
    },
    mixins: [validate, persistentFiltersMixin],
    props: {
        address: {
            type: Object,
            default: null,
        },
        location: {
            type: Object,
            default: null,
        },
        error: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: null,
        },
    },
    setup(props) {
        const { useState } = createNamespacedHelpers('basket');
        const { clientInfo, isCustom, basketType } = useState({
            clientInfo: 'clientInfo',
            isCustom: 'isCustom',
            basketType: 'type',
        });

        const operation = computed(() => {
            if (isCustom.value && basketType.value === 'shipment') {
                return MPS_PLATFORM_ORDER_CUSTOM_SHIPMENT_LOADING_UNLOADING_LOCATIONS;
            }
            if (isCustom.value && basketType.value === 'project-shipment') {
                return MPS_PLATFORM_ORDER_PROJECT_SHIPMENT_LOADING_UNLOADING_LOCATIONS;
            }
            if (!isCustom.value && basketType.value === 'shipment') {
                return MPS_CUSTOMER_ORDER_SHIPMENT_LOADING_UNLOADING_LOCATIONS;
            }

            return null;
        });

        const { allowedCountries, isLoading: isAllowedCountriesLoading } = useMarketPermission(operation.value);

        const locationPickerScreenName = computed(() => useOrderScreenName(`${props.type}-map`));
        const addressBookScreenName = computed(() => useOrderScreenName(`${props.type}-addressbook`));

        return {
            clientInfo,
            isCustom,
            basketType,
            operation,
            allowedCountries,
            isAllowedCountriesLoading,
            locationPickerScreenName,
            addressBookScreenName,
        };
    },
    data() {
        return {
            newLocation: { ...this.location },

            place: null,
            isWaitingForLocation: false,

            centerLocation: { ...this.location },
            selectedAddress: { ...this.address },
            showAddressBook: false,
            showAdditionalAddressTextField: !!this.address || false,
            visibleList: 'constructionSite',

            validationRules: {
                selectedAddress: [isRequired('pages.constructionSite.addressPicker.validation.placeIsRequired')],
            },

            constructionSites: null,
            constructionSiteCancelSource: null,
            constructionSiteDataEndpoint: ConstructionSiteApi,
            constructionSiteFilter: {
                page: 1,
                perPage: 25,
                isActive: 1,
            },
            constructionSiteDefaultFilter: {
                page: 1,
                perPage: 25,
                isActive: 1,
            },

            suppliers: null,
            suppliersCancelSource: null,
            suppliersDataEndpoint: SupplierApi,
            suppliersFilter: {
                page: 1,
                perPage: 25,
            },
            suppliersDefaultFilter: {
                page: 1,
                perPage: 25,
            },
        };
    },
    computed: {
        addressLabel() {
            const selectedAddress = _omit(this.selectedAddress, 'additionalAddress');

            const address = formatAddressObject({ address: selectedAddress });

            switch (_get(this, 'selectedAddress.type', 'custom')) {
                case 'constructionSite':
                case 'supplier':
                    return _get(this, 'selectedAddress.name') ? `${this.selectedAddress.name}, ${address}` : address;
                default:
                    return address;
            }
        },
    },
    methods: {
        handleAddressUpdateByPlace(place) {
            this.showAdditionalAddressTextField = true;
            this.validateCountry(place.countryCode);

            this.selectedAddress = {
                country: place.countryCode,
                state: place.stateCode,
                city: place.city,
                zip: place.postalCode,
                street: place.street,
                number: place.street_number,
            };

            this.$emit('update-address', this.selectedAddress);
        },

        handleLocationUpdate(location) {
            this.$emit('update-location', location);
        },

        validateCountry(countryCode) {
            if (this.allowedCountries === null) return;

            const errorMessage = this.allowedCountries.includes(countryCode)
                ? null
                : this.$t('components.addressPicker.countryNotAllowedLabel');

            this.$emit('error-message', errorMessage);
        },

        clickAddressBookButton() {
            this.showAddressBook = true;
            this.showAdditionalAddressTextField = false;
        },

        requestValidData() {
            if (!this.isValid()) {
                return null;
            }

            return {
                address: this.selectedAddress,
                location: this.centerLocation,
            };
        },

        fetchConstructionSiteAndSuppliers() {
            if (!this.constructionSites) {
                this.refreshConstructionSiteList(true);
            }
            if (!this.suppliers && this.isCustom) {
                this.refreshSuppliersList(true);
            }
        },

        async refreshConstructionSiteList(isInitial = false) {
            this.constructionSiteCancelSource && this.constructionSiteCancelSource.cancel('canceled-previous-call');
            this.constructionSiteCancelSource = ConstructionSiteApi.createCancelTokenSource();

            try {
                const additionalFilter = {
                    clientOrganization: this.clientInfo.client.id,
                    operations: [this.operation],
                };

                const result = await ConstructionSiteApi.filter(
                    {
                        ...this.constructionSiteFilter,
                        ...additionalFilter,
                    },
                    null,
                    null,
                    this.constructionSiteCancelSource
                );
                this.constructionSites = result;

                if (isInitial === true) {
                    this.constructionSiteFilter = {
                        ...this.constructionSiteFilter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                    Toaster.error(err.message);
                }
            }
        },

        async refreshSuppliersList(isInitial = false) {
            if (!this.isCustom) return;

            this.suppliersCancelSource && this.suppliersCancelSource.cancel('canceled-previous-call');
            this.suppliersCancelSource = SupplierListApi.createCancelTokenSource();
            this.suppliersFilter = {
                ...this.suppliersFilter,
                operations: [this.operation],
            };
            try {
                const result = await SupplierListApi.filterSupplierInfos(
                    this.suppliersFilter,
                    null,
                    null,
                    this.suppliersCancelSource
                );

                this.suppliers = result;

                if (isInitial === true) {
                    this.suppliersFilter = {
                        ...this.suppliersFilter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                    Toaster.error(err.message);
                }
            }
        },

        async selectConstructionSite(constructionSite) {
            const { supervisor } = constructionSite;

            // The supervisor is probably deleted
            if (!supervisor.isActive && supervisor.firstName === 'Gelöschter' && supervisor.lastName === 'Benutzer') {
                Toaster.info(this.$t('pages.constructionSite.constructionSiteList.changeSupervisorErrorMessage'), {
                    actionCallback: () => {
                        this.$router
                            .push({
                                name: CONSTRUCTION_PROJECT_LIST_ROUTE,
                            })
                            .catch(err => {
                                this.$logger().error(err);
                            });
                    },
                    actionLabel: this.$t('pages.constructionSite.constructionSiteList.changeSupervisorErrorAction'),
                });
                return;
            }

            const selectedAddress = {
                ...constructionSite.address,
                id: constructionSite.id,
                name: constructionSite.name,
                type: 'constructionSite',
                importantInformation: constructionSite.importantInformation,
                costCenter: constructionSite.costcenter,
            };

            if (!selectedAddress.state) {
                selectedAddress.state = await retrieveStateByLocation(constructionSite.location);
            }

            this.selectedAddress = selectedAddress;
            this.newLocation = constructionSite.location;
            this.showAddressBook = false;

            this.$emit('update-address', this.selectedAddress);
            this.$emit('update-location', constructionSite.location);
            this.$emit('update-contact', supervisor);
        },

        async selectSupplier(supplierInfo) {
            const selectedAddress = {
                ...supplierInfo.supplier.factoryAddress,
                id: supplierInfo.supplier.factoryId,
                name: supplierInfo.supplier.name,
                type: 'supplier',
                importantInformation: supplierInfo.supplier.factoryImportantInformation,
            };

            if (!selectedAddress.state) {
                selectedAddress.state = await retrieveStateByLocation(supplierInfo.supplier.location);
            }

            this.selectedAddress = selectedAddress;
            this.newLocation = supplierInfo.supplier.location;
            this.showAddressBook = false;

            this.$emit('update-address', this.selectedAddress);
            this.$emit('update-location', supplierInfo.supplier.location);
        },

        async selectLastUsedAddress(item) {
            this.validateCountry(item.address.country);

            const selectedAddress = {
                ...item.address,
                name: item.name,
                type: item.type,
                id: item.constructionSiteId,
            };

            if (item.costCenter) {
                selectedAddress.costCenter = item.costCenter;
            }

            if (!selectedAddress.state) {
                selectedAddress.state = await retrieveStateByLocation(item.location);
            }

            this.selectedAddress = selectedAddress;
            this.newLocation = item.location;

            this.$emit('update-address', this.selectedAddress);
            this.$emit('update-location', item.location);

            if (item.contact) {
                this.$emit('update-contact', item.contact);
            }
            this.showAdditionalAddressTextField = false;
        },

        clickAddressBookFlyoutCloseButton() {
            this.showAddressBook = false;
            this.showAdditionalAddressTextField = !!this.address;
        },
    },
});
</script>

<style lang="scss">
.shipment-address-picker .list {
    background-color: transparent;
}

.shipment-address-picker__intro {
    padding: 0 20px;
}

.shipment-address-picker__address-container {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 -10px;

    > * {
        flex-grow: 1;
        margin: 0 10px;
    }
}

.shipment-address-picker__address-field {
    input,
    label,
    svg {
        cursor: pointer;
    }
}

.shipment-address-picker__additional-field {
    margin-top: 15px;
}

.shipment-address-picker__book .flyout__content {
    background-color: $color-lightMediumGrey;
}

.shipment-address-picker__addressbook-button {
    flex-grow: 0;
    flex-shrink: 0;
    align-self: center;
    width: 60px;
    height: 60px;
}
</style>

<template>
    <Flyout
        :active="active"
        size="small"
        class="constructionSite-subscriptions"
        @closed="closeFlyout"
        @opening="refreshList()"
    >
        <HeaderBar slot="header">
            <HeaderBarItem slot="left" button @click="closeFlyout">
                <ArrowIcon width="32" height="18" />
            </HeaderBarItem>
            <div slot="headline">
                {{ $t('components.constructionSiteSubscriptions.headline') }}
            </div>
        </HeaderBar>

        <Tile class="notification-management__tile notification-management__tile--grey">
            <Words small muted>{{ $t('components.constructionSiteSubscriptions.description') }}</Words>
        </Tile>

        <FilterBox
            v-model="filter"
            :default-filter="filter"
            :endpoint="dataEndpoint"
            inline-mode
            class="constructionSite-subscriptions__inline-filter"
            @update="refreshList()"
        >
            <template #default="{ filter: filterFromSlot }">
                <TextField
                    v-model="filterFromSlot.search"
                    class="constructionSite-list__search-bar"
                    :label="$t('pages.constructionSite.constructionSiteList.search')"
                />
                <div
                    :class="{
                        'filter-bar__refresh--loading': isLoading,
                    }"
                    class="filter-bar__refresh"
                >
                    <BaseButton primary transparent icon @click="refreshList()">
                        <RefreshIcon />
                    </BaseButton>
                </div>
            </template>
        </FilterBox>

        <LoadingSpinner v-if="!constructionSites" block dark />

        <template v-else>
            <div v-if="constructionSites && constructionSites.count > 0" class="constructionSite-list__content">
                <div v-for="(facet, index) in constructionSiteFacets" :key="index">
                    <ListLabel
                        v-if="facet.appliedFilter.isActive"
                        v-stuckable="10"
                        class="constructionSite-list__label"
                    >
                        {{ $tc('components.constructionSiteSubscriptions.facetStatus.active', facet.count) }}
                    </ListLabel>
                    <ListLabel v-else v-stuckable="10" class="constructionSite-list__label">
                        {{ $tc('components.constructionSiteSubscriptions.facetStatus.inactive', facet.count) }}
                    </ListLabel>
                    <List inner-shadow>
                        <div
                            v-for="(constructionSite, j) in facet.items"
                            :key="j"
                            :class="{ 'constructionSite-subscriptions__item--inactive': !facet.appliedFilter.isActive }"
                            class="constructionSite-subscriptions__item"
                        >
                            <div class="constructionSite-subscriptions__item-content">
                                <Words block bold>{{ constructionSite.name }}</Words>
                                <Words block muted small>
                                    <FormattedAddress :address="constructionSite.address" />
                                </Words>
                                <Words v-if="constructionSite.costcenter" block muted small ellipsis>
                                    {{ $t(`components.constructionSiteSubscriptions.costcenter`) }}
                                    {{ constructionSite.costcenter }}
                                </Words>
                            </div>
                            <ToggleSwitch
                                :disabled="subscribedConstructionSites === null"
                                :value="hasNotificationSubscription(constructionSite.id)"
                                @input="updateConstructionSiteSubscription(constructionSite.id, $event)"
                            />
                        </div>
                        <MoreResultsButton v-if="facet.count - facet.items.length > 0" :result="facet" fade-out />
                    </List>
                </div>
            </div>

            <Hint v-if="constructionSites && constructionSites.count === 0" center transparent>
                {{ $t('pages.constructionSite.constructionSiteList.noResults') }}
            </Hint>
        </template>
    </Flyout>
</template>

<script>
import { mapGetters } from 'vuex';
import NotificationSubscriptionService from '@/services/Notification/NotificationSubscriptionService';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import ConstructionSiteApi from '@/services/Api/ConstructionSite';
import Toaster from '@/services/Toaster';

import BaseButton from '@/components/Button/Button';
import FormattedAddress from '@/components/FormattedAddress';
import FilterBox from '@/components/Filter/FilterBox';
import Flyout from '@/components/Layout/Flyout';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Hint from '@/components/Typography/Hint';
import List from '@/components/List/List';
import ListLabel from '@/components/List/ListLabel';
import LoadingSpinner from '@/components/LoadingSpinner';
import MoreResultsButton from '@/components/Filter/MoreResultsButton';
import TextField from '@/components/Form/TextField.v2';
import Tile from '@/components/Layout/Tile';
import ToggleSwitch from '@/components/Form/ToggleSwitch';
import Words from '@/components/Typography/Words';

import ArrowIcon from '@/assets/icons/regular/arrow.svg';
import RefreshIcon from '@/assets/icons/regular/refresh.svg';

export default {
    name: 'ConstructionSiteSubscriptions',
    components: {
        BaseButton,
        FilterBox,
        FormattedAddress,
        Flyout,
        HeaderBar,
        HeaderBarItem,
        Hint,
        List,
        ListLabel,
        LoadingSpinner,
        MoreResultsButton,
        TextField,
        Tile,
        ToggleSwitch,
        Words,

        ArrowIcon,
        RefreshIcon,
    },
    mixins: [persistentFiltersMixin],
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isPending: false,
            constructionSites: null,
            isLoading: false,
            cancelSource: null,
            dataEndpoint: ConstructionSiteApi,
            filter: this.assembleFilter('constructionSite', {
                page: 1,
                perPage: 50,
            }),
            defaultFilter: {
                page: 1,
                perPage: 50,
            },
            subscribedConstructionSites: null,
        };
    },
    computed: {
        ...mapGetters('user', ['info']),

        constructionSiteFacets() {
            return this.constructionSites.items.filter(item => item.count > 0);
        },
    },
    methods: {
        closeFlyout() {
            this.$eventHub.$emit('page.constructionSiteSubscriptions', false);
        },

        async refreshConstructionSiteSubscriptions() {
            try {
                this.subscribedConstructionSites =
                    await NotificationSubscriptionService.getSubscribedConstructionSiteIds();
            } catch (err) {
                this.$logger().error(err);
            }
        },

        hasNotificationSubscription(constructionSiteId) {
            if (this.subscribedConstructionSites === null) {
                return null;
            }

            return this.subscribedConstructionSites.includes(constructionSiteId);
        },

        async refreshList(isInitial = false) {
            this.isLoading = true;
            this.refreshConstructionSiteSubscriptions();

            this.persistFilter('constructionSite', this.filter, this.defaultFilter);

            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.cancelSource = ConstructionSiteApi.createCancelTokenSource();

            try {
                const result = await ConstructionSiteApi.filter(
                    this.filter,
                    'isActive',
                    [true, false],
                    this.cancelSource
                );
                this.constructionSites = result;

                if (isInitial === true) {
                    this.filter = {
                        ...this.filter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                    Toaster.error(err.message);
                }
            }

            this.isLoading = false;
        },

        async updateConstructionSiteSubscription(constructionSiteId, status) {
            // optimistic behavior
            if (status) {
                this.subscribedConstructionSites.push(parseInt(constructionSiteId));
            } else {
                this.subscribedConstructionSites.splice(
                    this.subscribedConstructionSites.indexOf(parseInt(constructionSiteId)),
                    1
                );
            }
            try {
                await NotificationSubscriptionService.updateConstructionSiteSubscription(constructionSiteId, status);
                this.refreshConstructionSiteSubscriptions();
                this.$eventHub.$emit('constructionSite.refresh-list');
            } catch (err) {
                // optimistic behavior
                if (status) {
                    this.subscribedConstructionSites.splice(
                        this.subscribedConstructionSites.indexOf(parseInt(constructionSiteId)),
                        1
                    );
                } else {
                    this.subscribedConstructionSites.push(parseInt(constructionSiteId));
                }
            }
        },
    },
};
</script>

<style lang="scss">
.constructionSite-subscriptions__tile--grey {
    background-color: $color-lightGrey;
}

.constructionSite-subscriptions__item {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 68px;
    grid-column-gap: 10px;
}

.constructionSite-subscriptions__item-content {
    overflow: hidden;
}

.constructionSite-subscriptions__item--inactive {
    .constructionSite-subscriptions__item-content {
        opacity: 0.5;
    }
}

.constructionSite-subscriptions__inline-filter {
    position: relative;
    margin-bottom: 0;
    z-index: 11;

    .filter-box__body {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.constructionSite-list__search-bar {
    width: 100%;
}

.constructionSite-list__label {
    padding-left: 20px;
}
</style>

<template>
    <ReactBridge
        :react-component="ReactCombobox"
        :props="{
            value: $props.value,
            label: $props.label,
            options: $props.options,
            multiple: $props.multiple,
            searchFn: $props.searchFn,
            dataTest: $props.dataTest,
        }"
        @change="handleChange"
    />
</template>

<script>
import { defineComponent } from 'vue';
import ReactBridge from '@/reactBridge/ReactBridge.vue';
import { ReactCombobox } from '@/reactBridge/Wrappers/ReactCombobox/ReactCombobox.tsx';

export default defineComponent({
    name: 'ReactCombobox',
    components: {
        ReactBridge,
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Array],
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            default: () => [],
        },
        searchFn: {
            type: Function,
            default: function defaultSearchFn(options, query) {
                if (query.trim() === '') {
                    return options;
                }
                return options.filter(option => option.label.toLowerCase().includes(query.toLowerCase()));
            },
        },
    },
    setup(props, { emit }) {
        return {
            ReactCombobox,
            props,

            handleChange: $event => {
                emit('change', $event);
            },
        };
    },
});
</script>

<template>
    <div>
        <GridRow class="organization-form__fieldset" :count="1">
            <Words block bold small>{{ getCompanyProfileTranslationForKey('logo') }}</Words>

            <ImageUploadTile
                v-model="organization.logo"
                inline
                :readonly="readonly"
                product-use-case="organization_logo"
            />
        </GridRow>
        <GridRow class="organization-form__fieldset" :count="1">
            <Words block bold small middle>
                <span>{{ getCompanyProfileTranslationForKey('description') }}</span>
                &nbsp;&nbsp;
                <Button data-test="company-description-modal-open-button" @click="openModal('companyDescriptionInfo')">
                    <InfoIcon class="icon--inline" width="18" height="18" />
                </Button>
            </Words>

            <TextField
                v-model="organization.description"
                :readonly="readonly"
                class="span-11"
                type="textarea"
                :error="errors.description"
                :label="getCompanyProfileTranslationForKey('description')"
            />
        </GridRow>
        <ModalBox
            ref="companyDescriptionInfo"
            :headline="$t('components.organizationForm.companyProfile.descriptionInfoHeadline')"
        >
            {{ $t('components.organizationForm.companyProfile.descriptionInfoDescription') }}
        </ModalBox>
    </div>
</template>

<script>
import GridRow from '@/components/Layout/GridRow';
import ModalBox from '@/components/Modal/ModalBox';
import Words from '@/components/Typography/Words';
import TextField from '@/components/Form/TextField.v2';
import ImageUploadTile from '@/components/Form/ImageUploadTile';
import InfoIcon from '@/assets/icons/regular/info.svg';
import Button from '@/components/Button/Button';

export default {
    name: 'TabCompanyProfile',

    components: {
        GridRow,
        ModalBox,
        TextField,
        Words,
        ImageUploadTile,
        InfoIcon,
        Button,
    },

    props: {
        organization: {
            type: Object,
            default: () => ({}),
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Object,
            default: () => ({
                description: false,
            }),
        },
    },
    methods: {
        getCompanyProfileTranslationForKey(translationKey) {
            return this.$t(`components.organizationForm.companyProfile.${translationKey}`);
        },

        openModal(refName) {
            this.$refs[refName].$emit('open');
        },

        closeModal(refName) {
            this.$refs[refName].$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.organization-form__fieldset {
    & + & {
        margin-top: 60px;
    }
}
</style>

import AddressView from '@/models/AddressView';

/**
 * Class representing constructionSite view model
 */
export default class ConstructionSiteView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     * @return {ConstructionSiteView|null}
     */
    static create(data) {
        if (!data) return null;
        const constructionSiteView = new ConstructionSiteView();

        constructionSiteView.id = data.id;
        constructionSiteView.name = data.name;
        constructionSiteView.location = data.location;
        constructionSiteView.costcenter = data.costcenter;
        constructionSiteView.maximumTrafficability = data.maximumTrafficability;
        constructionSiteView.address = AddressView.create(data.address);
        constructionSiteView.isActive = data.isActive;
        constructionSiteView.importantInformation = data.importantInformation;
        constructionSiteView.deleted = data.deleted;

        return constructionSiteView;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._id = null;
        this._name = null;
        this._address = null;
        this._location = null;
        this._costcenter = null;
        this._maximumTrafficability = null;
        this._isActive = null;
        this._importantInformation = null;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            id: this.id,
            name: this.name,
            address: this.address?.unfold(),
            location: this.location,
            costcenter: this.costcenter,
            maximumTrafficability: this.maximumTrafficability,
            isActive: this.isActive,
            importantInformation: this.importantInformation,
        };
    }

    /**
     * Clone the current view
     * @return {ConstructionSiteView}
     */
    clone() {
        return ConstructionSiteView.create(this.unfold());
    }

    /**
     * Get id
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * Set id
     * @param {number} value
     */
    set id(value) {
        this._id = value;
    }

    /**
     * Get name
     * @returns {string}
     */
    get name() {
        return this._name;
    }

    /**
     * Set name
     * @param {string} value
     */
    set name(value) {
        this._name = value;
    }

    /**
     * Get address
     * @returns {AddressView}
     */
    get address() {
        return this._address;
    }

    /**
     * Set address
     * @param {AddressView} value
     */
    set address(value) {
        this._address = value;
    }

    /**
     * Get location
     * @returns {object}
     */
    get location() {
        return this._location;
    }

    /**
     * Set location
     * @param {object|null} value
     */
    set location(value) {
        this._location = value;
    }

    /**
     * Get cost center
     * @returns {string}
     */
    get costcenter() {
        return this._costcenter;
    }

    /**
     * Set cost center
     * @param {string} value
     */
    set costcenter(value) {
        this._costcenter = value;
    }

    /**
     * Get maximumTrafficability
     * @returns {number}
     */
    get maximumTrafficability() {
        return this._maximumTrafficability;
    }

    /**
     * Set maximumTrafficability
     * @param {number} value
     */
    set maximumTrafficability(value) {
        this._maximumTrafficability = value;
    }

    /**
     * Get active state
     * @returns {boolean|null}
     */
    get isActive() {
        return this._isActive ?? null;
    }

    /**
     * Set active state
     * @param {boolean} value
     */
    set isActive(value) {
        this._isActive = value;
    }

    /**
     * Get important information
     * @returns {string}
     */
    get importantInformation() {
        return this._importantInformation;
    }

    /**
     * Set important information
     * @param {string} value
     */
    set importantInformation(value) {
        this._importantInformation = value;
    }
}

import { computed, reactive, ref } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, requiredUnless, integer, maxLength } from '@vuelidate/validators';
import { mapErrors } from '@/services/validation/serverErrorsMapper';
import { ensureJSTimestamp } from '@/services/utils/date';
export const getEmptyForm = () => ({
    weightInKg: null,
    weighingNoteNumber: null,
    weighingNoteImage: null,
    weighingNoteDocument: null,
    weighingAt: null,
    additionalDocuments: [],
    additionalImages: [],
});
export const useRecordWeighing = () => {
    const form = reactive(getEmptyForm());
    const setForm = (data) => {
        form.weighingNoteNumber = data.weighingNoteNumber || form.weighingNoteNumber;
        form.weightInKg = data.weightInKg || form.weightInKg;
        form.weighingNoteImage = data.weighingNoteImage || form.weighingNoteImage;
        form.weighingNoteDocument = data.weighingNoteDocument || form.weighingNoteDocument;
        form.weighingAt = ensureJSTimestamp(data.weighingAt) || form.weighingAt;
        form.additionalDocuments = data.additionalDocuments || form.additionalDocuments;
        form.additionalImages = data.additionalImages || form.additionalImages;
    };
    const rules = computed(() => ({
        weighingNoteNumber: {
            required,
            maxLength: maxLength(40),
        },
        weightInKg: {
            required,
            integer,
        },
        weighingNoteImage: { required: requiredUnless(() => !!form.weighingNoteDocument) },
        weighingNoteDocument: {},
        weighingAt: { required },
        additionalDocuments: {},
        additionalImages: {},
    }));
    const setServerErrors = (errors) => {
        $externalResults.value = mapErrors(errors);
    };
    const $externalResults = ref({});
    const v$ = useVuelidate(rules, form, { $externalResults });
    return { form, setForm, v$, setServerErrors };
};

import CustomPositionPricingItemView from '@/pages/Checkout/components/PriceAdjustment/models/CustomPositionPricingItemView';

export default class CustomPositionPricingView {
    constructor() {
        this._vehicleClassId = null;
        this._count = null;
        this._info = null;
        this._payload = null;
        this._positions = [];
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {CustomPositionPricingView|null}
     */
    static createFromCustomOrder(data) {
        const view = CustomPositionPricingView.createBaseCustomPositionPricingView(data);

        if (view && data.positions) {
            data.positions.forEach(position => {
                view.addPosition(CustomPositionPricingItemView.createFromCustomOrder(position));
            });
        }

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {CustomPositionPricingView|null}
     */
    static createFromProjectShipmentPositionBasket(data) {
        const view = CustomPositionPricingView.createBaseCustomPositionPricingView(data);

        if (view && data.positions) {
            data.positions.forEach(position => {
                view.addPosition(CustomPositionPricingItemView.create(position));
            });
        }

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {CustomPositionPricingView|null}
     */
    static createFromProjectShipmentPositionPayload({ data, vehicleClass, vehicleClassId, transport }) {
        const view = CustomPositionPricingView.createBaseCustomPositionPricingView({
            vehicleClassId: vehicleClassId,
            count: 1,
            info: vehicleClass?.vehicleClass?.name,
            payload: vehicleClass?.vehicleClass?.payload,
        });

        if (data) {
            data.forEach(position => {
                view.addPosition(
                    CustomPositionPricingItemView.createFromProjectShipmentPosition({
                        ...position,
                        count: 1,
                        vehiclePayload: vehicleClass?.vehicleClass?.payload,
                        transportDistance: transport[vehicleClassId]?.distance,
                    })
                );
            });
        }

        return view;
    }

    /**
     * Create a view model for transport pricing and initialize vehicleClassId, count, info and payload
     *
     * @param data
     * @return {CustomPositionPricingView|null}
     */
    static createBaseCustomPositionPricingView(data) {
        if (!data) return null;
        const view = new CustomPositionPricingView();

        view.vehicleClassId = data.vehicleClassId ? parseInt(data.vehicleClassId) : null;
        view.count = data.count || 0;
        view.info = data.info || null;
        view.payload = data.payload || {};

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {CustomPositionPricingView|null}
     */
    static createFromDisposalPayload(data) {
        const view = CustomPositionPricingView.createBaseCustomPositionPricingView(data);

        if (view && data && data.positions) {
            data.positions.forEach(position => {
                view.addPosition(CustomPositionPricingItemView.createFromDisposalPayload(position));
            });
        }

        return view;
    }

    /**
     * Create a view model for transport pricing
     *
     * @param data
     * @return {CustomPositionPricingView|null}
     */
    static create(data) {
        const view = CustomPositionPricingView.createBaseCustomPositionPricingView(data);

        if (view && data.positions) {
            data.positions.forEach(position => {
                view.addPosition(CustomPositionPricingItemView.create(position));
            });
        }

        return view;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfoldToCustomOrder() {
        return {
            count: this.count,
            info: this.info,
            payload: this.payload,
            positions: this.positions.map(position => position.unfoldToCustomOrder()).filter(v => v),
        };
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            vehicleClassId: this.vehicleClassId,
            count: this.count,
            info: this.info,
            payload: this.payload,
            positions: this.positions.map(position => position.unfold()).filter(v => v),
        };
    }

    /**
     * Clone current instance
     * @return {CustomPositionPricingView|null}
     */
    clone() {
        return CustomPositionPricingView.create(this.unfold());
    }

    get vehicleClassId() {
        return this._vehicleClassId;
    }

    set vehicleClassId(value) {
        this._vehicleClassId = value ?? null;
    }

    get count() {
        return this._count;
    }

    set count(value) {
        this._count = value ?? null;
    }

    get info() {
        return this._info;
    }

    set info(value) {
        this._info = value ?? null;
    }

    get payload() {
        return this._payload;
    }

    set payload(value) {
        this._payload = value ?? null;
    }

    get positions() {
        return this._positions;
    }

    set positions(value) {
        this._positions = value ?? [];
    }

    /**
     * Add a new position based on data.
     * Caution! That method has a side effect: It replaces the argument by a
     * CustomPositionPricingItemView if it is an object!
     * @param {CustomPositionPricingItemView|object} position
     */
    addPosition(position) {
        if (!(position instanceof CustomPositionPricingItemView)) {
            position = CustomPositionPricingItemView.create(position);
        }
        position.vehicleClassId = this.vehicleClassId;
        this.positions.push(position);
    }

    /**
     * Set a position at a given index
     * @param {number} index
     * @param {CustomPositionPricingItemView} position
     */
    setPosition(index, position) {
        if (!(position instanceof CustomPositionPricingItemView)) {
            position = CustomPositionPricingItemView.create(position);
        }
        position.vehicleClassId = this.vehicleClassId;

        this.positions[index] = position;
    }

    removePosition(index) {
        this.positions.splice(index, 1);
    }
}

<template>
    <section>
        <Tile v-if="!summary.isCustom && !summary.isNewQuote">
            <div class="checkout-summery__order-status">
                <span class="font-copy-sm-strong">
                    {{ $t('pages.checkout.summary.orderStatus.title') }}
                </span>
                <span
                    :class="[
                        'font-copy-sm-strong',
                        {
                            'text-brand-primary': summary.isRejectedQuote || summary.isCancelledByPlatform,
                            'text-warning': summary.isPendingQuote,
                        },
                    ]"
                >
                    {{ $t(`pages.checkout.summary.orderStatus.status.${summary.status}`) }}
                </span>
            </div>

            <span v-if="summary.isCancelledByPlatform" class="font-copy-sm-strong text-subdued">
                {{ $t('pages.checkout.summary.orderStatus.platformRejectionTitle') }}
            </span>
        </Tile>
        <ProductTitleSection
            :prefix="productPrefix"
            :title="productTitle"
            :tags="productTags"
            :description="productDescription"
            class="mt-4"
        >
            <OrderVehicleTrack
                v-if="summary.lineItems"
                :line-items="summary.lineItems"
                vehicle-layout="horizontal"
                :hide-loading-info="summary.isTypeShipment"
                class="checkout-summary__transports"
            />
        </ProductTitleSection>
        <HorizontalDivider class="mb-0 mt-2" />
    </section>
</template>

<script>
import Tile from '@/components/Layout/Tile';
import HorizontalDivider from '@/_components/HorizontalDivider/HorizontalDivider';
import OrderVehicleTrack from '@/components/Order/OrderVehicleTrack';
import SummaryView from '@/pages/Checkout/SummaryView';
import ProductTitleSection from '@/pages/Checkout/SummaryPage/partials/ProductTitleSection';

export default {
    name: 'ProductTitle',
    components: {
        HorizontalDivider,
        OrderVehicleTrack,
        ProductTitleSection,
        Tile,
    },
    props: {
        productDescription: {
            type: String,
            default: null,
        },
        productPrefix: {
            type: [Number, String],
            required: true,
        },
        productTitle: {
            type: String,
            default: null,
        },
        productTags: {
            type: Array,
            default: () => [],
        },
        summary: {
            type: SummaryView,
            default: null,
        },
    },
};
</script>

<template>
    <div class="reporting__section number-with-icon">
        <Words block bold>{{ $t('pages.reporting.panels.revenue.headline') }}</Words>
        <div class="number-with-icon__content">
            <div class="number-with-icon__icon">
                <RevenueIcon />
            </div>
            <div class="number-with-icon__info">
                <Words middle>
                    <Words block bold middle class="number-with-icon__subheader">
                        {{ $tc('pages.reporting.panels.revenue.subheader', transports, { value: transports }) }}
                    </Words>
                    <Words block bold middle class="number-with-icon__value">
                        {{ $n(revenue, 'currency_signless') }}
                        <Words class="number-with-icon__currency"> {{ getCurrencySign() }} </Words>
                    </Words>
                </Words>
            </div>
        </div>
    </div>
</template>

<script>
import { getCurrencySign } from '@/services/utils/currency';
import Words from '@/components/Typography/Words';

import RevenueIcon from '@/assets/icons/revenue.svg';

export default {
    name: 'NumberWithIcon',
    components: {
        Words,
        RevenueIcon,
    },
    props: {
        transports: {
            type: [Number, String],
            required: true,
        },
        revenue: {
            type: [Number, String],
            required: true,
        },
    },
    methods: {
        getCurrencySign,
    },
};
</script>

<style lang="scss">
.number-with-icon__content {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.number-with-icon__icon {
    margin-right: 30px;

    svg {
        width: 100%;
        height: auto;
    }
}

.number-with-icon__subheader {
    font-size: 20px;
    margin-bottom: 10px;
}

.number-with-icon__value {
    font-size: 40px;
    white-space: nowrap;
}

.number-with-icon__currency {
    font-size: 20px;
}
</style>

<template>
    <div class="debug-toolar">
        <button
            class="debug-toolbar__toggle flex h-[70px] w-[70px] items-center justify-center rounded-full bg-surface-primary p-4 shadow transition-all hover:bg-surface-primary-hovered"
            type="button"
            @click="toggleToolbar"
        >
            <span class="font-copy-sm text-dark">Toggle Toolbar</span>
        </button>

        <Flyout :active="isOpen" headline="Debug Toolbar" size="large" @closed="close">
            <EndpointSwitcher />
            <RoutInspector />
            <GeoLocationTester />

            <Pane>
                <div slot="head">
                    <strong>Store:Dump</strong>
                </div>
                <Dump v-if="isOpenStoreDump" slot="body" :data="$store.state" />
                <button v-else slot="body" @click="isOpenStoreDump = true">Display store dump</button>
            </Pane>
            <ApiInfo />
            <MobileDeviceInfo v-if="$root.isApp" />
        </Flyout>
    </div>
</template>

<script>
import EndpointSwitcher from '@/components/Debug/EndpointSwitcher/EndpointSwitcher';
import RoutInspector from '@/components/Debug/RoutInspector/RoutInspector';
import Dump from '@/components/Debug/Dump/Dump';
import ApiInfo from '@/components/Debug/ApiInfo/ApiInfo';
import Flyout from '@/components/Layout/Flyout';
import MobileDeviceInfo from '@/components/Debug/MobileDeviceInfo/MobileDeviceInfo';
import Pane from '@/components/Debug/Pane/Pane.vue';
import GeoLocationTester from '@/components/Debug/GeoLocationTester/GeoLocationTester';

export default {
    name: 'ToolbarComponent',
    components: {
        GeoLocationTester,
        Flyout,
        EndpointSwitcher,
        RoutInspector,
        Dump,
        ApiInfo,
        MobileDeviceInfo,
        Pane,
    },
    data() {
        return {
            isOpen: false,
            isOpenStoreDump: false,
        };
    },
    created() {
        document.onkeyup = e => {
            // 68 => D
            if (e.ctrlKey && e.key === 'd') {
                this.toggleToolbar();
            }
        };
    },
    methods: {
        toggleToolbar() {
            this.isOpen = !this.isOpen;
        },
        close() {
            this.isOpen = false;
            this.isOpenStoreDump = false;
        },
    },
};
</script>

<style lang="scss">
.debug-toolar {
    .flyout {
        z-index: 2000; // higher z-index to show in every situation
    }
}

.debug-toolbar__toggle {
    z-index: 500;
    position: fixed;
    right: 20px;
    bottom: 90px;
}
</style>

import AbstractFilterableResource from '../AbstractFilterableResource';

class PartnerSearch extends AbstractFilterableResource {
    async getMapList() {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/map-list`);
            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new PartnerSearch('/partner-search');

import AbstractResource from './AbstractFilterableResource';
class Weighing extends AbstractResource {
    async getConfiguration() {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/configuration`);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    /**
     * Get difference reasons
     */
    async getDifferenceReasons() {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/difference-reasons`);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async updateEmptyFull(transportId, tare, full, weighingNumber) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/empty-full`, {
                tare,
                full,
                weighingNumber,
            });
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async updateCbm(transportId, weight, weighingNumber) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/cbm`, {
                weight,
                weighingNumber,
            });
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async updateShovel(transportId, weight, shovels, weighingNumber) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/shovel`, {
                weight,
                shovels,
                weighingNumber,
            });
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async start(transportId, weighingMethod) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/start`, {
                weighingMethod,
            });
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async complete(transportId, reasonCode = null, loadingIncorrectMessage = null) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/complete`, {
                weighingDifferenceReasonCode: reasonCode,
                weighingDifferenceReasonText: loadingIncorrectMessage,
            });
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    async cancel(transportId, weighingCancelReasonText) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/cancel`, {
                weighingCancelReasonText,
            });
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    /**
     * Reject weighing as driver of transport, carrier
     */
    async reject(transportId, rejectLoadingReasonText) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/reject`, {
                rejectLoadingReasonText,
            });
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    /**
     * Confirm weighing as driver of transport, carrier
     */
    async confirm(transportId) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/confirm`);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    /**
     * Confirm weighing with original delivery note data as driver of transport, carrier
     */
    async confirmLoadingWithOriginalDeliveryNote(transportId, payload) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/confirm`, payload);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    /**
     * update original delivery note data as platform admin or driver of transport
     */
    async updateOriginalDeliveryNote(transportId, payload) {
        try {
            const response = await this.apiService.post(`${this.resourcePath}/${transportId}/update-original-delivery-note`, payload);
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
}
export default new Weighing('v2/weighing');

import AbstractResource from './AbstractResource';
class ShippingMethod extends AbstractResource {
    /**
     * Get available shipping methods. The payload also includes available delivery days.
     * If a projectId is provided the delivery days are limited by the project validity date.
     * The `transportType` is optional but not passing it is deprecated.
     *
     */
    async getAvailableMethods({ state, zip, country, transportType, customOrder = false, projectId, projectPositionId, }) {
        const path = transportType ? `/v1/${transportType}/shipping-methods` : `${this.resourcePath}`;
        try {
            const response = await this.apiService.get(path, {
                params: { state, zip, customOrder, projectId, projectPositionId, country },
            });
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
}
export default new ShippingMethod('/shippingmethod');

<template>
    <div>
        <div class="font-copy-md-strong mb-6">
            {{ $t('components.projectPositionOfferBlock.customPositionsHeadline') }}
        </div>

        <div v-for="(customPosition, cid) in customPositions" :key="cid">
            <ul v-if="isPlatformAdministrator">
                <li>
                    <span class="grid-cols[max-content_1fr] font-copy-md-strong mb-2 mt-6 whitespace-nowrap">
                        {{ customPositionLabel(customPosition) }}
                    </span>
                    <LineItem>
                        <template #label>
                            {{ $t('general.purchasePrice.fullTitle') }}
                        </template>
                        <template #middleSection>
                            {{ $n(customPosition.unitPurchasePrice, 'currency') }} / {{ customPosition.unit }}
                        </template>
                        <template #endSection>
                            {{ $n(customPosition.unitPurchasePrice * customPosition.qty * noOfTransports, 'currency') }}
                        </template>
                    </LineItem>
                </li>
                <li>
                    <LineItem>
                        <template #label>
                            {{ $t('general.retailPrice.fullTitle') }}
                        </template>
                        <template #middleSection>
                            {{ $n(customPosition.unitRetailPrice, 'currency') }} / {{ customPosition.unit }}
                        </template>
                        <template #endSection>
                            {{ $n(customPosition.unitRetailPrice * customPosition.qty * noOfTransports, 'currency') }}
                        </template>
                    </LineItem>
                </li>
            </ul>
            <ul v-else>
                <li>
                    <LineItem has-bold-label class="mt-2 first:mt-0">
                        <template #label>
                            {{ customPositionLabel(customPosition) }}
                        </template>
                        <template #middleSection>
                            {{ $n(customPosition.unitRetailPrice, 'currency') }} / {{ customPosition.unit }}
                        </template>
                        <template #endSection>
                            {{ $n(customPosition.unitRetailPrice * customPosition.qty * noOfTransports, 'currency') }}
                        </template>
                    </LineItem>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LineItem from '@/_components/LineItem/LineItem.vue';
export default {
    name: 'CustomPositionOverviewSection',
    components: { LineItem },
    props: {
        customPositions: {
            type: Array,
            required: true,
        },
        noOfTransports: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    computed: {
        ...mapGetters('user', ['isPlatformAdministrator']),
    },
    methods: {
        customPositionLabel(position) {
            return `${position.qty} x ${position.name}`;
        },
    },
};
</script>

<template>
    <div
        :class="{
            'loading-spinner--dark': dark,
            'loading-spinner--block': block,
            'loading-spinner--spaced': spaced,
        }"
        class="loading-spinner"
    >
        <div class="loading-spinner__bounce loading-spinner__bounce--1" />
        <div class="loading-spinner__bounce loading-spinner__bounce--2" />
        <div class="loading-spinner__bounce loading-spinner__bounce--3" />
    </div>
</template>

<script>
export default {
    name: 'LoadingSpinner',
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
        block: {
            type: Boolean,
            default: false,
        },
        spaced: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss">
@keyframes bouncedelay {
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

.loading-spinner {
    display: inline-block;
    text-align: center;
    color: $color-white;
}

.loading-spinner--dark {
    color: $color-darkGrey;
}

.loading-spinner--block {
    display: block;
    margin: 20px 10px;
}

.loading-spinner__bounce {
    width: 10px;
    height: 10px;
    background-color: currentColor;
    border-radius: 50%;
    display: inline-block;
    animation: bouncedelay 1.4s infinite ease-in-out both;
    margin: 0 1px;
}

.loading-spinner__bounce--1 {
    animation-delay: -0.32s;
}

.loading-spinner__bounce--2 {
    animation-delay: -0.16s;
}

.loading-spinner--spaced {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

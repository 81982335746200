<template>
    <div>
        <Words v-if="headline" block bold spaced-bottom>{{ headline }}</Words>
        <Words v-if="message" block spaced-bottom muted>{{ message }}</Words>
        <TextField
            :value="value"
            :label="$t('pages.checkout.additionalInformation.descriptionPlaceholder')"
            :has-error="hasErrors('description')"
            :error="getError('description')"
            :rows="4"
            type="textarea"
            @input="updateDescription"
        />
    </div>
</template>

<script>
import validate from '@/services/validation/mixin';
import { maxLength } from '@/services/validation/rules';

import Words from '@/components/Typography/Words';
import TextField from '@/components/Form/TextField.v2';

export default {
    name: 'ShipmentDescription',

    components: {
        Words,
        TextField,
    },

    mixins: [validate],

    props: {
        value: {
            type: String,
            default: null,
        },
        headline: {
            type: String,
            default: null,
        },
        message: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            validationRules: {
                value: [maxLength(1000)],
            },
        };
    },

    methods: {
        updateDescription(value) {
            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss"></style>

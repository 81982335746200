<template>
    <section class="summary-offer-section my-6">
        <h3 class="font-headline-lg-strong">{{ $t('components.projectPositionOfferBlock.title') }}</h3>

        <CostOverviewSection
            v-if="pricesData.totalPricesPerSelectedVehicleClass"
            class="mt-8"
            :transports="pricesData.totalPricesPerSelectedVehicleClass"
            :material="{
                extension: `${waste.code}${isHazardous ? '*' : ''}, ${wasteDescription.pollutionType}`,
            }"
            :fixed-price="hasFixedPrice"
        />

        <!--
            The number of transports when creating a project position will always 1.
            When an order is placed against this position, the number of transports will be
            the number of whatever the user chose for the order of course.
        -->
        <CustomPositionOverviewSection
            v-if="hasCustomPosition"
            class="mt-12"
            :custom-positions="pricesData.customPositions"
            :no-of-transports="1"
        />

        <BaseButton
            class="mt-6 w-full text-right"
            arrow-right
            @click="
                $emit('edit', {
                    section: 'prices',
                    routeName: 'checkout-project-disposal-hazardous-prices',
                })
            "
        >
            {{ $t('pages.checkout.priceAdjustments.titleChangePrices') }}
        </BaseButton>
    </section>
</template>

<script>
import BaseButton from '@/components/Button/Button.vue';

import CostOverviewSection from '@/pages/Checkout/components/PriceAdjustment/CostOverviewSection.vue';
import CustomPositionOverviewSection from '@/pages/Checkout/components/PriceAdjustment/CustomPositionOverviewSection.vue';

export default {
    name: 'OfferSection',
    components: {
        BaseButton,

        CostOverviewSection,
        CustomPositionOverviewSection,
    },
    props: {
        pricesData: {
            type: Object,
            default: () => ({}),
        },
        waste: {
            type: Object,
            default: () => ({}),
        },
        wasteDescription: {
            type: Object,
            default: () => ({}),
        },
        hasFixedPrice: {
            type: Boolean,
            default: false,
        },
        isHazardous: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasCustomPosition() {
            return !!this.pricesData?.customPositions?.length;
        },
    },
};
</script>

import clsx from 'clsx';
import { useIconSize } from '@/services/utils/useIconSize';
import { forwardRef, useId } from 'react';
export const ChevronRightIcon = forwardRef(({ className, size, strokeCssClass = 'stroke-icon', ...svgProps }, ref) => {
    const id = useId();
    const iconSize = useIconSize(size);
    return (<svg width={iconSize} height={iconSize} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} className={clsx('cursor-pointer', className, strokeCssClass)} {...svgProps}>
                <g clipPath={`url(#a-${id})`}>
                    <path d="m4.47 14.47 6.345-6.332L4.47 1.806" strokeWidth={1.333} strokeMiterlimit={10}/>
                </g>
                <defs>
                    <clipPath id={`a-${id}`}>
                        <path fill="#fff" transform="translate(4 1.333)" d="M0 0h7.758v13.609H0z"/>
                    </clipPath>
                </defs>
            </svg>);
});
ChevronRightIcon.displayName = 'ChevronRightIcon';

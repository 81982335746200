var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"avatar-box",class:{
        'avatar-box--huge': _vm.size === 'huge',
        'avatar-box--big': _vm.size === 'big',
        'avatar-box--md': _vm.size === 'md',
        'avatar-box--medium': _vm.size === 'medium',
        'avatar-box--small': _vm.size === 'small',
        'avatar-box--xsmall': _vm.size === 'xsmall',
        'avatar-box--tiny': _vm.size === 'tiny',
        'avatar-box--middle': _vm.middle,
    }},[_c('div',{staticClass:"avatar-box__cage"},[(_vm.hasImage)?_c('img',{staticClass:"avatar-box__image",attrs:{"src":_vm.thumbnail}}):_c('FallbackImage',{staticClass:"avatar-box__icon"})],1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
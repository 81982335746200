<template>
    <div :id="`slide-up-animation-${eid}`" class="h-0 overflow-hidden">
        <slot />
    </div>
</template>

<script>
import gsap from 'gsap';
import { POWER4_EASEOUT, POWER4_EASEIN } from '@/constants/animations';

export default {
    name: 'SlideUp',

    props: {
        active: {
            type: Boolean,
            default: false,
        },
        duration: {
            type: Number,
            default: 0.3,
        },
        ease: {
            type: String,
            default: 'easeOut',
            validator: v => ['easeOut', 'easeIn', 'linear'].includes(v),
        },
    },

    data() {
        return {
            eid: `e${this._uid}`,
            $content: null,
        };
    },

    watch: {
        active(newValue, oldValue) {
            if (!oldValue && newValue) {
                this.openContent();
            }

            if (!newValue) {
                this.closeContent();
            }
        },
    },

    mounted() {
        this.$content = document.querySelector(`#slide-up-animation-${this.eid}`);

        if (this.active) {
            this.openContent();
        }
    },

    methods: {
        closeContent() {
            gsap.to(this.$content, {
                duration: this.duration,
                height: 0,
                minHeight: 0,
                ease: this.getEaseFunction(),
            });
        },

        openContent() {
            gsap.set(this.$content, { height: 'auto' });
            gsap.from(this.$content, {
                duration: this.duration,
                height: 0,
                minHeight: 0,
                ease: this.getEaseFunction(),
            });
        },

        updateContent() {
            this.$nextTick(() => {
                gsap.set(this.$content, { height: 'auto' });
                gsap.from(this.$content, {
                    duration: this.duration,
                    height: this.$content.offsetHeight,
                    minHeight: 0,
                    ease: this.getEaseFunction(),
                });
            });
        },

        updateContentImmediately() {
            this.$nextTick(() => {
                gsap.set(this.$content, { height: 'auto' });
                gsap.to(this.$content, {
                    duration: this.duration,
                    height: this.$content.offsetHeight,
                    minHeight: 0,
                    ease: this.getEaseFunction(),
                });
            });
        },

        getEaseFunction() {
            switch (this.ease) {
                case 'easeOut':
                    return POWER4_EASEOUT;
                case 'easeIn':
                    return POWER4_EASEIN;
                case 'linear':
                    return null;
            }
        },
    },
};
</script>

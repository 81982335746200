import DocumentsListPage from '@/pages/Documents/DocumentsListPage';

export default {
    path: 'documents',
    name: 'documents',
    component: DocumentsListPage,
    props: true,
    meta: {
        noBackArrow: true,
        requiredAbilities: ['haveReceipts'],
    },
};

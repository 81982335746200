import { useAbility } from '@/reactBridge/useAbility';
import { zodResolver } from '@hookform/resolvers/zod';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import FormDropZone from './FormDropZone';
import { ProductUseCaseDocument, uploadDocument } from '@schuettflix/client-file';
import CertificateApi, { certificateFactoriesCacheKey, certificateMutationKey, } from '@/services/Api/Certificate';
import Toaster from '@/services/Toaster';
import { useMutation, useQuery } from '@tanstack/react-query';
import FactoryApi from '@/services/Api/Factory';
import { Combobox, RadioButtonSimple, TextField } from '@schuettflix/react-components';
import clsx from 'clsx';
import ComboboxLoading from './ComboboxLoading';
import { formatAddress } from '@/services/utils/address';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { addYears, format } from 'date-fns';
import { getErrorMessage } from '@/utils/getErrorMessage';
const CertificateForm = ({ formId, organizationId, certificateId, certificateType, formData, onSaved, onDirtyChange, defaultDate }, forwardRef) => {
    const canListFactories = useAbility('listFactories');
    const { t } = useTranslation();
    const { data: factories, isLoading: isFactoriesLoading } = useQuery(certificateFactoriesCacheKey(organizationId), async () => {
        const factories = await FactoryApi.filter({ supplierId: organizationId });
        return factories.items;
    }, {
        enabled: certificateType?.hasFactoryLink && canListFactories,
    });
    const { isWasteTransportationMandatory } = certificateType;
    const maxValidityDate = function () {
        const currentDate = new Date();
        const maxValidity = addYears(currentDate, 30);
        return format(maxValidity, 'YYYY-MM-DD');
    };
    const mutation = useMutation(certificateMutationKey(certificateId), async (data) => {
        AnalyticsService.trackEvent('account_management', {
            step: certificateId ? 'change_certificate' : 'add_certificate',
            certificateType: certificateType.id,
            certificateTypeLabel: certificateType.documentType.label,
            certificateId: certificateId,
            success: true,
        });
        let document;
        if (data.document instanceof File) {
            const { data: fileData } = await uploadDocument({
                file: data.document,
                meta: {
                    productUseCase: ProductUseCaseDocument.DisposalCertificateDocument,
                    documentTypeCode: certificateType.documentType.code,
                },
            });
            document = fileData;
        }
        else {
            document = data.document;
        }
        const certificateData = {
            id: certificateId,
            organization: {
                id: organizationId,
            },
            certificateType: { id: certificateType.id },
            document: { uuid: document.uuid },
        };
        if (data.factories) {
            certificateData.factories = data.factories.map(f => ({ id: Number(f) }));
        }
        if (data.validityEndDate) {
            certificateData.validityEndDate = data.validityEndDate;
        }
        if (data.canTransportWaste) {
            certificateData.canTransportWaste = data.canTransportWaste;
        }
        return CertificateApi.save(certificateData);
    }, {
        onSuccess() {
            onSaved?.();
            Toaster.success(t('pages.certificateManagement.add.success'));
        },
        onError(error) {
            Toaster.error(getErrorMessage(error));
        },
    });
    const dateSchema = (mandatory = false) => {
        const schema = z
            .date({
            invalid_type_error: t('pages.certificateManagement.add.validity.error.invalidType'),
            required_error: t('pages.certificateManagement.add.validity.error.required'),
        })
            .min(new Date(), {
            message: t('pages.certificateManagement.add.validity.error.minDate'),
        })
            .transform(val => val.toISOString().substring(0, 10));
        return z.preprocess(arg => {
            if (arg instanceof Date) {
                return arg;
            }
            if (typeof arg == 'string' && arg !== '') {
                return new Date(arg);
            }
            return null;
        }, !mandatory ? schema.nullable() : schema);
    };
    const { register, formState, handleSubmit, reset, control } = useForm({
        defaultValues: {
            factories: formData?.factories,
            validityEndDate: formData?.validityEndDate ?? defaultDate,
            canTransportWaste: formData?.canTransportWaste,
            document: formData?.document,
        },
        resolver: zodResolver(z.object({
            factories: z.string().array().optional(),
            validityEndDate: certificateType?.hasValidityEndDate
                ? dateSchema(certificateType?.isValidityEndDateMandatory)
                : z.null(),
            canTransportWaste: isWasteTransportationMandatory
                ? z.enum(['no', 'yes_non_hazardous', 'yes'])
                : z.undefined(),
            document: z.union([
                z.instanceof(File, { message: t('pages.certificateManagement.add.dropzone.required') }),
                z.object({
                    uuid: z.string(),
                    fileName: z.string(),
                }),
            ]),
        })),
    });
    useEffect(() => {
        reset({
            validityEndDate: defaultDate,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultDate]);
    const submit = async (data) => {
        mutation.mutate(data);
    };
    const onInvalid = errors => {
        const timestamp = new Date().getTime();
        Object.entries(errors).forEach(([key, values]) => {
            AnalyticsService.trackEvent('account_management', {
                step: certificateId ? 'change_certificate' : 'add_certificate',
                certificateType: certificateType.id,
                certificateTypeLabel: certificateType.documentType.label,
                certificateId: certificateId,
                validationError: `${key}-${values.type}`,
                reference: timestamp,
                success: false,
            });
        });
    };
    useImperativeHandle(forwardRef, () => {
        return {
            reset,
        };
    }, [reset]);
    useEffect(() => {
        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [certificateType]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onDirtyChange?.(formState.isDirty), [formState.isDirty]);
    return (<form id={formId} key={certificateType.id} className="flex flex-col gap-12" onSubmit={handleSubmit(submit, onInvalid)}>
            <hr className="border-divider"/>
            <div>
                <p className="font-copy-lg-strong mb-6">{t('pages.certificateManagement.add.meta.title')}</p>
                {/* dropzone */}
                <p className="font-copy-md-strong mb-2">{t('pages.certificateManagement.add.title')}</p>
                <p className="mb-6">{t('pages.certificateManagement.add.dropzone.copy')}</p>

                <FormDropZone disabled={mutation.isLoading} control={control} name="document" errorMessage={formState?.errors?.document?.message} maximumFileSizeInMb={20} translations={{
            drag: {
                desktop: t('pages.certificateManagement.add.dropzone.drag.desktop'),
                mobile: t('pages.certificateManagement.add.dropzone.drag.mobile'),
            },
            drop: t('pages.certificateManagement.add.dropzone.drop'),
            maximumFileSize: t('pages.certificateManagement.add.dropzone.maximumFileSize'),
            maximumFileSizeExceeded: t('pages.certificateManagement.add.dropzone.maximumFileSizeExceeded'),
            dataTypeNotSupported: t('pages.certificateManagement.add.dropzone.dataTypeNotSupported'),
        }}/>
            </div>
            {/* validity */}
            {certificateType.hasValidityEndDate && (<div>
                    <p className="font-copy-md-strong mb-2">
                        {`${t('pages.certificateManagement.add.validity.title')}${certificateType.isValidityEndDateMandatory
                ? ''
                : ` (${t('pages.transport.transportFailed.optionalLabel')})`}`}
                    </p>
                    <p className="mb-6">{t('pages.certificateManagement.add.validity.copy')}</p>
                    <TextField {...register('validityEndDate')} max={maxValidityDate()} disabled={mutation.isLoading} errorMessage={formState.errors?.validityEndDate?.message} type="date" variant="md" label={t('pages.certificateManagement.add.validity.label')}/>
                </div>)}

            {/* transporting waste in certificate */}
            {isWasteTransportationMandatory && (<div>
                    <Controller render={({ field }) => (<div data-test="waste-transportation-input">
                                <p className="font-copy-md-strong mb-2">
                                    {t('pages.certificateManagement.add.wasteTransportation.title')}
                                </p>
                                <p className="mb-6">
                                    {t('pages.certificateManagement.add.wasteTransportation.subtitle')}
                                </p>
                                <div className="flex flex-col gap-6">
                                    <RadioButtonSimple inputValue={'no'} label={t('pages.certificateManagement.add.wasteTransportation.option.no')} name="waste-transporting-no" value={field.value || null} data-test="waste-transportation-no" onChange={field.onChange}/>
                                    <RadioButtonSimple inputValue={'yes_non_hazardous'} label={t('pages.certificateManagement.add.wasteTransportation.option.nonHazardous')} name="waste-transporting-yes-non-hazardous" value={field.value || null} data-test="waste-transportation-yes-non-hazardous" onChange={field.onChange}/>
                                    <RadioButtonSimple inputValue={'yes'} label={t('pages.certificateManagement.add.wasteTransportation.option.yes')} name="waste-transporting-yes" value={field.value || null} data-test="waste-transportation-yes" onChange={field.onChange}/>
                                </div>
                            </div>)} control={control} name="canTransportWaste" defaultValue={formData?.canTransportWaste}/>
                    {formState.errors?.canTransportWaste && (<p className="font-copy-sm mt-2 text-critical">
                            {formState.errors?.canTransportWaste?.message}
                        </p>)}
                </div>)}
            {/* factories */}
            {certificateType.hasFactoryLink && canListFactories && (<div>
                    <p className="font-copy-md-strong mb-2">{t('pages.certificateManagement.add.factory.title')}</p>
                    <p className="mb-6">{t('pages.certificateManagement.add.factory.copy')}</p>
                    {isFactoriesLoading ? (<ComboboxLoading />) : (<Controller render={({ field }) => (<Combobox className={clsx({
                        'pointer-events-none brightness-95': mutation.isLoading || factories?.length === 0,
                    })} noOptionFoundLabel={t('pages.certificateManagement.add.factory.noOptionFoundLabel')} options={factories?.map(item => ({
                        label: item.name,
                        description: formatAddress({ address: item.address }),
                        value: item.id.toString(),
                    }))} label={factories?.length === 0
                        ? t('pages.certificateManagement.add.factory.emptyLabel')
                        : t('pages.certificateManagement.add.factory.label')} value={field.value || []} onChange={field.onChange} multiple/>)} control={control} name="factories" defaultValue={formData?.factories}/>)}
                    {formState.errors?.factories && (<p className="font-copy-sm mt-2 text-critical">{formState.errors?.factories?.message}</p>)}
                </div>)}
        </form>);
};
export default forwardRef(CertificateForm);

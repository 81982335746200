<template>
    <component :is="orderListComponent" />
</template>

<script>
import PlatformOrderList from './components/PlatformOrderList';
import ClientOrderList from './components/ClientOrderList';

export default {
    name: 'OrderListPage',
    components: {
        PlatformOrderList,
        ClientOrderList,
    },
    computed: {
        orderListComponent() {
            if (this.$route.matched.some(route => route.meta.context === 'platform') && this.$can('listAllOrders')) {
                return PlatformOrderList;
            }

            if (this.$can('listOrders')) {
                return ClientOrderList;
            }

            return 'div';
        },
    },
};
</script>

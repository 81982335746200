<template>
    <GridRow :count="2" spacing="large">
        <TextField
            v-if="endpoint.supportedFilters && endpoint.supportedFilters.number"
            v-model="number"
            :label="$t('pages.order.filter.quoteNumber')"
            class="span-2"
        />

        <div class="span-2">
            <Words bold block spaced>{{ $t('pages.order.filter.constructionProjectHeadline') }}</Words>
            <MultiselectBox
                v-if="endpoint.supportedFilters && endpoint.supportedFilters.constructionProject"
                v-model="constructionProject"
                :options="constructionProjectOptions"
                :option-value-renderer="option => option.id"
                :search-label-renderer="option => option.name"
                :label="$t('pages.order.filter.constructionProjectHeadline')"
                searchable
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1">
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ buttonScope.selectedOptions[0].name }}
                            </Words>
                        </span>
                        <span
                            v-else-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length > 1"
                            muted
                            middle
                        >
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ buttonScope.selectedOptions[0].name }}, +{{ buttonScope.selectedOptions.length - 1 }}
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <div style="line-height: 1.4em">
                        <Words bold block>{{ optionScope.option.name }}</Words>
                        <Words small muted block>
                            <FormattedAddress :address="optionScope.option.address" />
                        </Words>
                    </div>
                </template>
            </MultiselectBox>
        </div>

        <div class="span-2">
            <Words bold block spaced>{{ $t('pages.order.filter.statusHeadline') }}</Words>
            <MultiselectBox
                v-model="status"
                :options="Object.keys(endpoint.supportedFilters.status.options)"
                :option-value-renderer="option => option"
                :search-label-renderer="option => $t(`status.quote.${option}`)"
                :label="$t('pages.order.filter.statusHeadline')"
                multiple
                searchable
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1">
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ $t(`status.quote.${buttonScope.selectedOptions[0]}`) }}
                            </Words>
                        </span>
                        <span
                            v-else-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length > 1"
                            muted
                            middle
                        >
                            <Words bold block small>
                                {{ buttonScope.label }}
                            </Words>
                            <Words muted block>
                                {{ $t(`status.quote.${buttonScope.selectedOptions[0]}`) }}, +{{
                                    buttonScope.selectedOptions.length - 1
                                }}
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <Words bold block>{{ $t(`status.quote.${optionScope.option}`) }}</Words>
                </template>
            </MultiselectBox>
        </div>

        <div class="span-2">
            <Words bold block spaced>{{ $t('pages.order.filter.type') }}</Words>
            <MultiselectBox
                v-if="endpoint.supportedFilters && endpoint.supportedFilters.type"
                v-model="type"
                :options="endpoint.supportedFilters.type.options"
                :option-value-renderer="option => option"
                :search-label-renderer="option => $t(`pages.order.orderType.${option}`)"
                :label="$t('pages.order.filter.type')"
                class="span-2"
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <Words v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1" bold block>
                            {{ $t(`pages.order.orderType.${buttonScope.selectedOptions[0]}`) }}
                        </Words>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    <Words :red="optionScope.isActive" bold block class="multiselect__option--spaced">
                        {{ $t(`pages.order.orderType.${optionScope.option}`) }}
                        <TickIcon v-if="optionScope.isActive" class="icon--inline icon--red" />
                    </Words>
                </template>
            </MultiselectBox>
        </div>

        <div class="span-2">
            <Words bold block spaced>{{ $t('pages.order.filter.createdBy') }}</Words>
            <MultiselectBox
                v-if="endpoint.supportedFilters && endpoint.supportedFilters.createdBy"
                v-model="createdBy"
                :options="endpoint.supportedFilters.createdBy.options"
                :option-value-renderer="option => option.id"
                :search-label-renderer="option => `${option.firstName} ${option.lastName}`"
                :label="$t('pages.order.filter.createdBy')"
                multiple
                searchable
                class="span-2"
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1">
                            {{ buttonScope.label }}
                            <Words muted middle>
                                ({{ buttonScope.selectedOptions[0].firstName }}
                                {{ buttonScope.selectedOptions[0].lastName }})
                            </Words>
                        </span>
                        <span
                            v-else-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length > 1"
                            muted
                            middle
                        >
                            {{ buttonScope.label }}
                            <Words muted middle>
                                ({{ buttonScope.selectedOptions[0].firstName }}, +{{
                                    buttonScope.selectedOptions.length - 1
                                }})
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    {{ optionScope.option.firstName }} {{ optionScope.option.lastName }}
                </template>
            </MultiselectBox>
        </div>

        <div class="span-2">
            <Words bold block spaced>{{ $t('pages.order.filter.supervisor') }}</Words>
            <MultiselectBox
                v-if="endpoint.supportedFilters && endpoint.supportedFilters.supervisor"
                v-model="supervisor"
                :options="endpoint.supportedFilters.supervisor.options"
                :option-value-renderer="option => option.id"
                :search-label-renderer="option => `${option.firstName} ${option.lastName}`"
                :label="$t('pages.order.filter.supervisor')"
                multiple
                searchable
                class="span-2"
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1">
                            {{ buttonScope.label }}
                            <Words muted middle>
                                ({{ buttonScope.selectedOptions[0].firstName }}
                                {{ buttonScope.selectedOptions[0].lastName }})
                            </Words>
                        </span>
                        <span
                            v-else-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length > 1"
                            muted
                            middle
                        >
                            {{ buttonScope.label }}
                            <Words muted middle>
                                ({{ buttonScope.selectedOptions[0].firstName }}, +{{
                                    buttonScope.selectedOptions.length - 1
                                }})
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    {{ optionScope.option.firstName }} {{ optionScope.option.lastName }}
                </template>
            </MultiselectBox>
        </div>

        <div class="span-2">
            <Words bold block spaced>{{ $t('pages.order.filter.verifiedBy') }}</Words>
            <MultiselectBox
                v-if="endpoint.supportedFilters && endpoint.supportedFilters.verifiedBy"
                v-model="verifiedBy"
                :options="endpoint.supportedFilters.verifiedBy.options"
                :option-value-renderer="option => option.id"
                :search-label-renderer="option => `${option.firstName} ${option.lastName}`"
                :label="$t('pages.order.filter.verifiedBy')"
                multiple
                searchable
                class="span-2"
            >
                <template #button="buttonScope">
                    <DefaultInner dark>
                        <span v-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length == 1">
                            {{ buttonScope.label }}
                            <Words muted middle>
                                ({{ buttonScope.selectedOptions[0].firstName }}
                                {{ buttonScope.selectedOptions[0].lastName }})
                            </Words>
                        </span>
                        <span
                            v-else-if="buttonScope.selectedOptions && buttonScope.selectedOptions.length > 1"
                            muted
                            middle
                        >
                            {{ buttonScope.label }}
                            <Words muted middle>
                                ({{ buttonScope.selectedOptions[0].firstName }}, +{{
                                    buttonScope.selectedOptions.length - 1
                                }})
                            </Words>
                        </span>
                        <span v-else muted middle>
                            {{ $t('components.multiselectBox.defaultSelectLabel', { label: buttonScope.label }) }}
                        </span>
                    </DefaultInner>
                </template>

                <template #option="optionScope">
                    {{ optionScope.option.firstName }} {{ optionScope.option.lastName }}
                </template>
            </MultiselectBox>
        </div>

        <div class="span-2">
            <Words bold block spaced>{{ $t('pages.order.filter.dateRange') }}</Words>
            <TimeRangeFieldset
                :from="filterScope.filter.startCreated"
                :to="filterScope.filter.endCreated"
                type="date"
                @update-from="$set(filterScope.filter, 'startCreated', ensurePHPTimestamp($event))"
                @update-to="$set(filterScope.filter, 'endCreated', ensurePHPTimestamp($event))"
            />
        </div>
    </GridRow>
</template>

<script>
import DefaultInner from '@/components/Form/MultiselectBox/DefaultInner';
import GridRow from '@/components/Layout/GridRow';
import MultiselectBox from '@/components/Form/MultiselectBox';
import TimeRangeFieldset from '@/components/Filter/TimeRangeFieldset.v2';
import FormattedAddress from '@/components/FormattedAddress';
import TextField from '@/components/Form/TextField.v2';
import Words from '@/components/Typography/Words';
import { useConstructionProjectList } from '@/constructionProjects/composables/useConstructionProjectList';
import TickIcon from '@/assets/icons/micro/tick.svg';
import { ensurePHPTimestamp } from '@/services/utils/date';
import { revertLocalizedValue } from '@/services/utils/localization';
import { useTwoWayBindingComputedFilter } from '@/components/Filter/FilterBox.vue';

export default {
    name: 'QuoteFilterSet',
    components: {
        DefaultInner,
        GridRow,
        MultiselectBox,
        TimeRangeFieldset,
        TextField,
        Words,
        TickIcon,
        FormattedAddress,
    },
    props: {
        filterScope: {
            type: Object,
            required: true,
        },
        endpoint: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        const createTwoWayBindingComputedFilter = useTwoWayBindingComputedFilter({ props, emit });

        const constructionProjectOptions = useConstructionProjectList({
            placeholderData: {
                constructionProjects: [],
            },
            select: response => {
                return response.constructionProjects.map(project => ({
                    id: project.id,
                    name: project.name,
                    address: {
                        street: project.street,
                        number: project.addressNumber,
                        city: project.city,
                        zip: project.zip,
                        state: project.state,
                        countryCode: project.country,
                    },
                }));
            },
        });

        return {
            constructionProjectOptions: constructionProjectOptions.data,
            number: createTwoWayBindingComputedFilter('number'),
            status: createTwoWayBindingComputedFilter('status'),
            type: createTwoWayBindingComputedFilter('type'),
            createdBy: createTwoWayBindingComputedFilter('createdBy'),
            supervisor: createTwoWayBindingComputedFilter('supervisor'),
            verifiedBy: createTwoWayBindingComputedFilter('verifiedBy'),
            constructionProject: createTwoWayBindingComputedFilter('constructionProject'),
        };
    },
    methods: {
        ensurePHPTimestamp,
        revertLocalizedValue,
    },
};
</script>

<style lang="scss"></style>

import { reactive, onScopeDispose, computed, unref } from 'vue';
import FeatureFlagsService from '@/services/FeatureFlags/FeatureFlagsService';
export function useFeatureFlags() {
    const flags = reactive({ ...FeatureFlagsService.getFlags() });
    const applyNewFlags = () => {
        Object.assign(flags, FeatureFlagsService.getFlags());
    };
    applyNewFlags();
    const unsubscribe = FeatureFlagsService.subscribe(applyNewFlags);
    onScopeDispose(() => {
        unsubscribe();
    });
    return flags;
}
export function useFeatureFlag(featureNameMaybeRef) {
    const flags = useFeatureFlags();
    const isEnabled = computed(() => {
        const featureName = unref(featureNameMaybeRef);
        return !!flags?.[featureName];
    });
    return { isEnabled };
}
export function useEditBlockOrdersForNewOrganizationsEnabled() {
    const flags = useFeatureFlags();
    return computed(() => !flags.block_orders_for_new_organizations);
}

import SpectatorService from '@/services/SpectatorService';
import { CONTEXT_PLATFORM, CONTEXT_CARRIER, CONTEXT_SUPPLIER, CONTEXT_CLIENT } from '@/constants/context';
import store from '@/store/index';

/**
 * Get all documents from an order as a collection
 * @param {*} order
 */
export function getAllDocumentsForOrder(order) {
    const documents = [];
    const uuids = [];

    order.lineItemGroups.forEach(lineItemGroup => {
        lineItemGroup.lineItems.forEach(lineItem => {
            //delivery notes
            if (lineItem.deliveryNoteDocument) {
                documents.push({
                    type: 'deliveryNote',
                    ...lineItem.deliveryNoteDocument,
                });
            }

            // creditNote
            const carrierDocument = lineItem.carrierCreditNoteDocument;
            if (carrierDocument && !uuids.includes(carrierDocument.uuid)) {
                uuids.push(carrierDocument.uuid);
                documents.push({
                    type: 'creditNote--carrier',
                    ...carrierDocument,
                });
            }

            const supplierDocument = lineItem.supplierCreditNoteDocument;
            if (supplierDocument && !uuids.includes(supplierDocument.uuid)) {
                uuids.push(supplierDocument.uuid);
                documents.push({
                    type: 'creditNote--supplier',
                    ...supplierDocument,
                });
            }
        });
    });

    //invoice
    const invoice = order.invoiceDocument;
    if (invoice) {
        documents.push({
            type: 'invoice',
            ...invoice,
        });
    }

    return documents;
}

/**
 * Get all documents from an order as a collection
 * @param {*} order
 */
export function getAllDocumentsForTransport(transport) {
    const documents = [];
    const uuids = [];

    //delivery notes
    if (transport.deliveryNoteDocument) {
        documents.push({
            type: 'deliveryNote',
            ...transport.deliveryNoteDocument,
        });
    }

    // creditNote
    const carrierDocument = transport.creditNoteCarrierDocument;
    if (carrierDocument && !uuids.includes(carrierDocument.uuid)) {
        uuids.push(carrierDocument.uuid);
        documents.push({
            type: 'creditNote--carrier',
            ...carrierDocument,
        });
    }

    const supplierDocument = transport.creditNoteSupplierDocument;
    if (supplierDocument && !uuids.includes(supplierDocument.uuid)) {
        uuids.push(supplierDocument.uuid);
        documents.push({
            type: 'creditNote--supplier',
            ...supplierDocument,
        });
    }

    return documents;
}

/**
 * Get latest document
 * @param {*} documents
 * @param {*} types
 */
export function getLatestDocument(documents, types = []) {
    let document = null;
    let created = 0;

    documents.forEach(doc => {
        if (doc.created > created && types.includes(doc.type)) {
            document = doc;
            created = doc.created;
        }
    });

    return document;
}

/**
 * Check if latest document has been seen
 * @param {*} identifier
 * @param {*} latestDocument
 */
function hasNewDocuments(identifier, latestDocument) {
    if (!latestDocument) {
        // track further documents
        SpectatorService.markSeen(identifier, 0);
        return false;
    }

    SpectatorService.markSeenIfNew(identifier, latestDocument.created);

    //check if already seen?
    if (SpectatorService.hasSeen(identifier, latestDocument.created)) {
        return false;
    }

    return true;
}

/**
 * Mark latest document as seen
 * @param {*} identifier
 * @param {*} latestDocument
 */
function markDocumentsAsSeen(identifier, latestDocument) {
    if (!latestDocument) {
        // track further documents
        SpectatorService.markSeen(identifier, 0);
        return;
    }

    SpectatorService.markSeen(identifier, latestDocument.created);
}

/**
 * Get document types for current user role
 */
function getTypes(context) {
    if ([CONTEXT_PLATFORM].includes(context)) {
        return ['deliveryNote', 'invoice', 'creditNote--carrier', 'creditNote--supplier'];
    }

    if ([CONTEXT_CARRIER].includes(context)) {
        return ['deliveryNote', 'creditNote--carrier'];
    }

    if ([CONTEXT_SUPPLIER].includes(context)) {
        return ['deliveryNote', 'creditNote--supplier'];
    }

    if ([CONTEXT_CLIENT].includes(context)) {
        return store.getters['abilities/can']('seePrices') ? ['deliveryNote', 'invoice'] : ['deliveryNote'];
    }

    return [];
}

/**
 * Helper for getLatestDocument
 * @param {*} order
 */
function getLatestDocumentForOrder(context, order = null) {
    if (order === null) {
        return null;
    }

    const types = getTypes(context);
    const documents = getAllDocumentsForOrder(order);
    return getLatestDocument(documents, types);
}

/**
 * Helper for getLatestDocument
 * @param {*} transport
 */
function getLatestDocumentForTransport(context, transport = null) {
    if (transport === null) {
        return null;
    }

    const types = getTypes(context);
    const documents = getAllDocumentsForTransport(transport);
    return getLatestDocument(documents, types);
}

// ORDER
// --------------------------------------------------------------------------------
/**
 * Check if order has new documents
 *
 * @param {*} order
 */
export function orderHasNewDocuments(context, order = null) {
    if (order === null) {
        return false;
    }

    return hasNewDocuments(`o${order.id}`, getLatestDocumentForOrder(context, order));
}

/**
 * Check if a document timestamp is new for order
 *
 * @export
 * @param {*} orderId
 * @param {*} timestamp
 * @returns
 */
export function isDocumentNewForOrder(orderId, timestamp) {
    //check if already seen?
    return !SpectatorService.hasSeen(`o${orderId}`, timestamp);
}

/**
 * Mark documents as seen for order
 *
 * @param {*} order
 */
export function markDocumentsAsSeenForOrder(context, order = null) {
    if (order === null) return;

    markDocumentsAsSeen(`o${order.id}`, getLatestDocumentForOrder(context, order));
}

// TRANSPORT
// --------------------------------------------------------------------------------
/**
 * Check if transport has new documents
 *
 * @param {*} transport
 */
export function transportHasNewDocuments(context, transport = null) {
    if (transport === null) {
        return false;
    }

    return hasNewDocuments(`t${transport.id}`, getLatestDocumentForTransport(context, transport));
}

/**
 * Check if a document timestamp is new for transport
 *
 * @export
 * @param {*} transportId
 * @param {*} timestamp
 * @returns
 */
export function isDocumentNewForTransport(transportId, timestamp) {
    //check if already seen?
    return !SpectatorService.hasSeen(`t${transportId}`, timestamp);
}

/**
 *  Mark documents as seen for transport
 *
 * @param {*} transport
 */
export function markDocumentsAsSeenForTransport(context, transport = null) {
    if (transport === null) return;

    markDocumentsAsSeen(`t${transport.id}`, getLatestDocumentForTransport(context, transport));
}

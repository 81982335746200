<template>
    <FormWrapper data-test="factoryPlatformSettings">
        <template #title>
            <Words spaced-right-tiny>
                {{ $t('components.factoryManagement.factoryPlatformSettings.title') }}
            </Words>
        </template>
        <ComponentWrapper>
            <template #componentText>
                <Words bold block spaced-bottom-small>{{
                    $t('components.factoryManagement.factoryPlatformSettings.subtitle')
                }}</Words>
                <Words block>{{ $t('components.factoryManagement.factoryPlatformSettings.text') }}</Words>
            </template>
            <template #component>
                <div class="mb-0 grid grid-cols-1 md:grid-cols-2">
                    <CheckboxCard
                        :model-value="form.deliveryAllowed"
                        is-icon-full-size
                        :title="$t('components.factoryManagement.factoryPlatformSettings.options.deliveryAllowed')"
                        :is-disabled="isDeliveryDisabled"
                        :is-read-only="isReadOnly"
                        :value="true"
                        data-test="platform-settings-checkbox-deliveryAllowed"
                        @change="update('deliveryAllowed', $event)"
                    >
                        <template #icon>
                            <DisposalTransportIcon />
                        </template>
                    </CheckboxCard>
                    <CheckboxCard
                        :model-value="form.pickupAllowed"
                        is-icon-full-size
                        :title="$t('components.factoryManagement.factoryPlatformSettings.options.pickupAllowed')"
                        :is-disabled="isPickupDisabled"
                        :is-read-only="isReadOnly"
                        :value="true"
                        data-test="platform-settings-checkbox-pickupAllowed"
                        @change="update('pickupAllowed', $event)"
                    >
                        <template #icon>
                            <SellingTransportIcon />
                        </template>
                    </CheckboxCard>
                </div>
            </template>
        </ComponentWrapper>
    </FormWrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import validate from '@/services/validation/mixin';
import sectionResetMixin from '@/plugins/mixins/factoryForm/sectionResetMixin';
import sectionMixin from '@/plugins/mixins/factoryForm/sectionMixin';

import { FACTORY_ROUTE_NAMES } from '@/constants/factoryForm';
import CheckboxCard from '@/_components/CheckboxCard/CheckboxCard';
import ComponentWrapper from '@/_components/ComponentWrapper/ComponentWrapper';
import FormWrapper from '@/_components/FormWrapper/FormWrapper';
import Words from '@/components/Typography/Words';

import SellingTransportIcon from '@/assets/icons/trucks/usage-selling.svg';
import DisposalTransportIcon from '@/assets/icons/trucks/usage-disposal.svg';

export default {
    name: 'FactoryPlatformSettings',
    components: {
        CheckboxCard,
        ComponentWrapper,
        FormWrapper,
        Words,
        SellingTransportIcon,
        DisposalTransportIcon,
    },
    mixins: [validate, sectionResetMixin, sectionMixin],
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        features: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        isReadOnly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('user', ['isPlatformAdministrator']),
        isDeliveryDisabled() {
            return !this.features?.supplier_delivery?.active;
        },
        isPickupDisabled() {
            return !this.features?.supplier_pickup?.active;
        },
    },
    watch: {
        value: {
            handler(data) {
                this.sectionDataBackupOpenStatusReset(data.open);
            },
        },
        form: {
            deep: true,
            immediate: true,
            handler(sectionData) {
                if (!this.sectionDataBackup) {
                    return;
                }
                this.$emit('dirty-check', this.checkDirty(sectionData, this.sectionDataBackup));
                this.$emit('confirmation-dialog', null);
                this.$emit('valid-check', true);
            },
        },
        submitted(formSubmitted) {
            if (formSubmitted) {
                this.createSectionDataBackup();
            }
        },
    },
    created() {
        if (!this.isPlatformAdministrator) {
            this.$emit('dirty-check', false);
            this.$router.push({
                name: this.$root.findRouteName(FACTORY_ROUTE_NAMES.PARENT_ROUTE_NAME),
            });
        }
    },
};
</script>

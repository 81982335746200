export function parseToken(token) {
    try {
        // backend is returning base64url, converting to proper base64
        const cleanedToken = token.replace(/-/g, '+').replace(/_/g, '/');
        // see https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
        return JSON.parse(decodeURIComponent(escape(atob(cleanedToken.split('.')[1]))));
    }
    catch (err) {
        return null;
    }
}
export function checkJWTValid(token) {
    return token.split('.').length >= 3;
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LayoutPage',{attrs:{"back-title":_vm.$t('pages.invoice.title'),"parent-route":_vm.parentRoute},scopedSlots:_vm._u([{key:"pageTitle",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('pages.documents.title'))+" ")]},proxy:true},{key:"mobileHeader",fn:function(){return [_c('HeaderBar',{scopedSlots:_vm._u([{key:"headline",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('pages.documents.title')))]},proxy:true}])})]},proxy:true}])},[(_vm.documentListing.isLoading)?_c('LoadingSpinner',{attrs:{"block":"","dark":""}}):_c('div',{staticClass:"container-content lg:px-0"},[_c('div',{staticClass:"mt-8 hidden justify-end md:flex"},[(_vm.documentListing.resultCount > 0 || _vm.documentListing.resultItems.length > 0)?_c('SfButton',{attrs:{"type":"primary","size":"sm"},on:{"click":_vm.downloadAllDocuments},scopedSlots:_vm._u([{key:"trailing-icon",fn:function(iconProps){return [_c('SfDownloadIcon',_vm._b({},'SfDownloadIcon',iconProps,false))]}}],null,false,1540274919)},[_vm._v(" "+_vm._s(_vm.$t('pages.projects.documents.downloadAllLabel'))+" ")]):_vm._e()],1),(!!_vm.documentListing.resultCount)?_c('SfFileList',{staticClass:"mt-8",attrs:{"files":_vm.documentListing.resultItems.map(item => ({
                    download: true,
                    type: 'pdf',
                    url: item.document.url,
                    created: item.document.created,
                    name: item.document.fileName,
                    document: item.document,
                })),"has-background":"","translations":{
                preview: {
                    delete: 'Delete',
                    download: 'Download',
                    edit: 'Edit',
                },
            }},on:{"download-file":_vm.onDownloadFile}}):_vm._e()],1),(_vm.documentListing.resultCount === 0 && _vm.documentListing.resultItems.length === 0)?_c('HintComponent',{attrs:{"center":"","transparent":""}},[_vm._v(" "+_vm._s(_vm.$t('pages.documents.noResults'))+" ")]):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
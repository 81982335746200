import AddressView from '@/models/AddressView';
import PhoneNumberView from '@/models/PhoneNumberView';

/**
 * Class representing factory view model
 */
export default class FactoryView {
    /**
     * Create a view model from data (e.g. API response data)
     * @param {object} data
     */
    static create(data) {
        if (!data) return null;

        const factory = new FactoryView();

        factory.id = data.id;
        factory.name = data.name;
        factory.factoryNumber = data.factoryNumber;
        factory.location = data.location;
        factory.open = data.open;
        factory.openingTimes = data.openingTimes;
        factory.supplierOrganizationId = data.supplierOrganizationId;
        factory.address = AddressView.create(data.address);
        factory.fax = PhoneNumberView.create(data.fax);
        factory.mobile = PhoneNumberView.create(data.mobile);
        factory.phone = PhoneNumberView.create(data.phone);
        factory.disposerNumber = data?.disposerNumber;

        return factory;
    }

    /**
     * Create a view model
     */
    constructor() {
        this._id = null;
        this._name = null;
        this._address = null;
        this._factoryNumber = null;
        this._location = null;
        this._fax = null;
        this._mobile = null;
        this._phone = null;
        this._open = null;
        this._openingTimes = null;
        this._supplierOrganizationId = null;
        this._disposerNumber = null;
    }

    /**
     * Unfold data
     * @return {object}
     */
    unfold() {
        return {
            id: this.id,
            name: this.name,
            address: this.address?.unfold(),
            factoryNumber: this.factoryNumber,
            location: this.location,
            disposerNumber: this.disposerNumber,
            fax: this.fax?.unfold(),
            mobile: this.mobile?.unfold(),
            phone: this.phone?.unfold(),
            open: this.open,
            openingTimes: this.openingTimes,
            supplierOrganizationId: this.supplierOrganizationId,
        };
    }

    /**
     * Clone the current view
     * @return {FactoryView}
     */
    clone() {
        return FactoryView.create(this.unfold());
    }

    /**
     * Get id
     * @returns {number}
     */
    get id() {
        return this._id;
    }

    /**
     * Set id
     * @param {number} value
     */
    set id(value) {
        this._id = value ?? null;
    }

    /**
     * Get name
     * @returns {string}
     */
    get name() {
        return this._name;
    }

    /**
     * Set name
     * @param {string} value
     */
    set name(value) {
        this._name = value ?? null;
    }

    /**
     * Get address
     * @returns {AddressView}
     */
    get address() {
        return this._address;
    }

    /**
     * Set address
     * @param {AddressView} value
     */
    set address(value) {
        this._address = value ?? null;
    }

    /**
     *  Get factory number
     * @returns {string}
     */
    get factoryNumber() {
        return this._factoryNumber;
    }

    /**
     * Set factory number
     * @param {string} value
     */
    set factoryNumber(value) {
        this._factoryNumber = value ?? null;
    }

    /**
     * Get location
     * @returns {object}
     */
    get location() {
        return this._location;
    }

    /**
     * Set location
     * @param {object|null} value
     */
    set location(value) {
        this._location = value ?? null;
    }

    /**
     * Get fax
     * @returns {PhoneNumberView}
     */
    get fax() {
        return this._fax;
    }

    /**
     * Set fax
     * @param {PhoneNumberView} value
     */
    set fax(value) {
        this._fax = value ?? null;
    }

    /**
     * Get mobile
     * @returns {PhoneNumberView}
     */
    get mobile() {
        return this._mobile;
    }

    /**
     * Set mobile
     * @param {PhoneNumberView} value
     */
    set mobile(value) {
        this._mobile = value ?? null;
    }

    /**
     * Get phone
     * @returns {PhoneNumberView}
     */
    get phone() {
        return this._phone;
    }

    /**
     * Set phone
     * @param {PhoneNumberView} value
     */
    set phone(value) {
        this._phone = value ?? null;
    }

    /**
     * Check if it's open
     * @returns {boolean}
     */
    get open() {
        return this._open;
    }

    /**
     * Set open
     * @param {boolean} value
     */
    set open(value) {
        this._open = value ?? null;
    }

    /**
     * Check if it's open (alias)
     * @returns {boolean}
     */
    get isOpen() {
        return this.open;
    }

    /**
     * Get opening times
     * @returns {Array}
     */
    get openingTimes() {
        return this._openingTimes;
    }

    /**
     * Set opening times
     * @param {Array} value
     */
    set openingTimes(value) {
        this._openingTimes = value ?? null;
    }

    /**
     * Get supplier organization id
     * @returns {number}
     */
    get supplierOrganizationId() {
        return this._supplierOrganizationId;
    }

    /**
     * Set supplier organization id
     * @param {number} value
     */
    set supplierOrganizationId(value) {
        this._supplierOrganizationId = value ?? null;
    }

    /**
     * Get disposerNumber
     * @returns {object}
     */
    get disposerNumber() {
        return this._disposerNumber;
    }

    /**
     * Set disposerNumber
     * @param {object} value
     */
    set disposerNumber(value) {
        this._disposerNumber = value;
    }
}

import clsx from 'clsx';
import { useIconSize } from '@/services/utils/useIconSize';
import { forwardRef } from 'react';
// TODO extract to component library, this is just temporary until icon PR is merged
export const ArrowLeftIcon = forwardRef(({ size = 'xs', className, ...props }, ref) => {
    const iconSize = useIconSize(size);
    return (<svg width={iconSize} viewBox="0 0 24 24" aria-hidden="true" fill="none" className={clsx('svg-icon', className)} xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
                <path d="M22 12H3.5M8 16.5 3.5 12 8 7.5" className="stroke-icon" strokeWidth="2" strokeMiterlimit="10"/>
            </svg>);
});
ArrowLeftIcon.displayName = 'ArrowLeftIcon';

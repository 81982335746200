<template>
    <LayoutPage
        class="vehicle-class-form-page"
        screen-name="platform-vehicleclassmanagement-edit"
        :parent-route="parentRoute"
        @close="close()"
    >
        <template #pageTitle>
            {{ item && item.name }}
        </template>

        <div id="vehicle-class-form-page" class="vehicle-class-form-page__container">
            <Alert v-if="item.updatedBy || item.marketValueUpdatedBy" type="info">
                <div>
                    <Words v-if="item.updatedBy" block>
                        {{
                            $t('pages.vehicleClassFormPage.updatedPlatformAttributesMessage', {
                                date: $d(item.updated, 'short'),
                                name: item.updatedBy.fullName,
                            })
                        }}
                    </Words>
                    <Words v-if="item.marketValueUpdatedBy" block :spaced-top-small="!!item.updatedBy">
                        {{
                            $t('pages.vehicleClassFormPage.updatedMarketAttributesMessage', {
                                date: $d(item.marketValueUpdated, 'short'),
                                name: item.marketValueUpdatedBy.fullName,
                            })
                        }}
                    </Words>
                </div>
            </Alert>

            <Alert v-if="error" type="error">{{ error.message }}</Alert>

            <GridRow :count="8" spacing="large" align="start" spaced-top>
                <div class="vehicle-class-form-page__status span-8">
                    <ToggleSwitchField
                        v-model="item.isActive"
                        :disabled="!$can('updateVehicleClassesGlobalData')"
                        :small="$root.isDesktop"
                    >
                        <Words bold>{{ $t('pages.vehicleClassFormPage.isActive') }}</Words>
                    </ToggleSwitchField>
                    <ErrorMessage v-if="hasErrors('item.isActive')" :message="getError('item.isActive')" />
                </div>
            </GridRow>

            <div class="vehicle-class-form-page__section">
                <Words bold small spaced-bottom block>{{ $t('pages.vehicleClassFormPage.general') }}</Words>
                <GridRow :count="6" spacing="large" align="start">
                    <SelectBox
                        v-model="item.numAxes"
                        :options="numAxesOptions"
                        :label="$t('pages.vehicleClassFormPage.numAxes')"
                        :option-value-renderer="option => option"
                        :option-label-renderer="option => $tc(`pages.vehicleClassFormPage.axesSuffix`, option)"
                        class="span-6 span-sm-2"
                    />

                    <div class="span-6 span-sm-2">
                        <MultiselectBox
                            :value="getVehicleIconClass(item.icon)"
                            :options="iconOptions"
                            :label="$t('pages.vehicleClassFormPage.iconLabel')"
                            @input="$event => (item.icon = remapDeprecatedIconKeys($event))"
                        >
                            <template #button="buttonScope">
                                <VehicleClassIconInner mode="button" :scope="buttonScope" />
                            </template>
                            <template #option="optionScope">
                                <VehicleClassIconInner mode="option" :scope="optionScope" />
                            </template>
                        </MultiselectBox>
                        <ErrorMessage v-if="hasErrors('item.icon')" :message="getError('item.icon')" />
                    </div>
                </GridRow>
            </div>

            <div class="vehicle-class-form-page__section">
                <Words bold small spaced-bottom block>{{ $t('pages.vehicleClassFormPage.weight') }}</Words>
                <GridRow :count="6" spacing="large" align="start">
                    <TextField
                        v-model.number="item.permissibleTotalWeight.value"
                        :error="getError('item.permissibleTotalWeight.value')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="1"
                        :label="$t('pages.vehicleClassFormPage.permissibleTotalWeight.value')"
                    />
                    <TextField
                        v-model.number="item.permissibleTotalWeight.min"
                        :error="getError('item.permissibleTotalWeight.min')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="1"
                        :label="$t('pages.vehicleClassFormPage.permissibleTotalWeight.min')"
                    />
                    <TextField
                        v-model.number="item.permissibleTotalWeight.max"
                        :error="getError('item.permissibleTotalWeight.max')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="1"
                        :label="$t('pages.vehicleClassFormPage.permissibleTotalWeight.max')"
                    />

                    <TextField
                        v-model.number="item.emptyWeight.value"
                        :error="getError('item.emptyWeight.value')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="1"
                        :label="$t('pages.vehicleClassFormPage.emptyWeight.value')"
                    />
                    <TextField
                        v-model.number="item.emptyWeight.min"
                        :error="getError('item.emptyWeight.min')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="1"
                        :label="$t('pages.vehicleClassFormPage.emptyWeight.min')"
                    />
                    <TextField
                        v-model.number="item.emptyWeight.max"
                        :error="getError('item.emptyWeight.max')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="1"
                        :label="$t('pages.vehicleClassFormPage.emptyWeight.max')"
                    />

                    <TextField
                        v-model.number="item.payload.value"
                        :error="getError('item.payload.value')"
                        class="span-6 span-sm-2 span-lg-2"
                        data-test="vehicle-class-form-page__payload"
                        type="number"
                        step="1"
                        :label="$t('pages.vehicleClassFormPage.payload.value')"
                    />

                    <TextField
                        v-model.number="item.payload.min"
                        :error="getError('item.payload.min')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="1"
                        :label="$t('pages.vehicleClassFormPage.payload.min')"
                    />

                    <TextField
                        v-model.number="item.payload.max"
                        :error="getError('item.payload.max')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="1"
                        :label="$t('pages.vehicleClassFormPage.payload.max')"
                    />

                    <TextField
                        v-model.number="item.loadingVolume.value"
                        :error="getError('item.loadingVolume.value')"
                        class="span-6 span-sm-2 span-lg-2"
                        data-test="vehicle-class-form-page__loading-volume"
                        type="number"
                        step="0.01"
                        :label="$t('pages.vehicleClassFormPage.loadingVolume.value')"
                    />

                    <TextField
                        v-model.number="item.loadingVolume.min"
                        :error="getError('item.loadingVolume.min')"
                        class="span-6 span-sm-2 span-lg-2"
                        data-test="vehicle-class-form-page__loading-volume"
                        type="number"
                        step="0.01"
                        :label="$t('pages.vehicleClassFormPage.loadingVolume.min')"
                    />

                    <TextField
                        v-model.number="item.loadingVolume.max"
                        :error="getError('item.loadingVolume.max')"
                        class="span-6 span-sm-2 span-lg-2"
                        data-test="vehicle-class-form-page__loading-volume"
                        type="number"
                        step="0.01"
                        :label="$t('pages.vehicleClassFormPage.loadingVolume.max')"
                    />
                </GridRow>
            </div>

            <div class="vehicle-class-form-page__section">
                <Words bold small spaced-bottom block>{{ $t('pages.vehicleClassFormPage.dimensions') }}</Words>
                <GridRow :count="6" spacing="large" align="start">
                    <TextField
                        v-model.number="item.dimension.length.value"
                        :error="getError('item.dimension.length.value')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="0.01"
                        :label="$t('pages.vehicleClassFormPage.dimension.length.value')"
                    />
                    <TextField
                        v-model.number="item.dimension.length.min"
                        :error="getError('item.dimension.length.min')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="0.01"
                        :label="$t('pages.vehicleClassFormPage.dimension.length.min')"
                    />
                    <TextField
                        v-model.number="item.dimension.length.max"
                        :error="getError('item.dimension.length.max')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="0.01"
                        :label="$t('pages.vehicleClassFormPage.dimension.length.max')"
                    />

                    <TextField
                        v-model.number="item.dimension.width.value"
                        :error="getError('item.dimension.width.value')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="0.01"
                        :label="$t('pages.vehicleClassFormPage.dimension.width.value')"
                    />
                    <TextField
                        v-model.number="item.dimension.width.min"
                        :error="getError('item.dimension.width.min')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="0.01"
                        :label="$t('pages.vehicleClassFormPage.dimension.width.min')"
                    />
                    <TextField
                        v-model.number="item.dimension.width.max"
                        :error="getError('item.dimension.width.max')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="0.01"
                        :label="$t('pages.vehicleClassFormPage.dimension.width.max')"
                    />

                    <TextField
                        v-model.number="item.dimension.height.value"
                        :error="getError('item.dimension.height.value')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="0.01"
                        :label="$t('pages.vehicleClassFormPage.dimension.height.value')"
                    />
                    <TextField
                        v-model.number="item.dimension.height.min"
                        :error="getError('item.dimension.height.min')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="0.01"
                        :label="$t('pages.vehicleClassFormPage.dimension.height.min')"
                    />
                    <TextField
                        v-model.number="item.dimension.height.max"
                        :error="getError('item.dimension.height.max')"
                        class="span-6 span-sm-2 span-lg-2"
                        type="number"
                        step="0.01"
                        :label="$t('pages.vehicleClassFormPage.dimension.height.max')"
                    />
                </GridRow>
            </div>

            <div class="vehicle-class-form-page__section">
                <Words bold small spaced-bottom block>{{ $t('pages.vehicleClassFormPage.loading') }}</Words>
                <GridRow :count="8" spacing="large" align="start">
                    <TextField
                        v-model.number="item.supportedTotalWeight"
                        :error="getError('item.supportedTotalWeight')"
                        class="span-8 span-sm-4 span-lg-2"
                        data-test="vehicle-class-form-page__supported-total-weight"
                        type="number"
                        step="1"
                        :label="$t('pages.vehicleClassFormPage.supportedTotalWeight')"
                    />

                    <TextField
                        v-model.number="item.supportedMinimumLoad"
                        :error="getError('item.supportedMinimumLoad')"
                        class="span-8 span-sm-4 span-lg-2"
                        data-test="vehicle-class-form-page__supported-minimum-load"
                        type="number"
                        step="1"
                        :label="$t('pages.vehicleClassFormPage.supportedMinimumLoad')"
                    />

                    <TextField
                        v-model="item.loadingDurationBuffer"
                        :error="getError('item.loadingDurationBuffer')"
                        class="span-8 span-sm-4 span-lg-2 service"
                        data-test="vehicle-class-form-page__loading-duration"
                        type="number"
                        step="1"
                        :label="$t('pages.vehicleClassFormPage.loadingDurationBuffer')"
                    />

                    <TextField
                        v-model="item.unloadingDurationBuffer"
                        :error="getError('item.unloadingDurationBuffer')"
                        class="span-8 span-sm-4 span-lg-2"
                        data-test="vehicle-class-form-page__unloading-duration"
                        type="number"
                        step="1"
                        :label="$t('pages.vehicleClassFormPage.unloadingDurationBuffer')"
                    />
                </GridRow>
            </div>

            <div class="vehicle-class-form-page__section vehicle-class-form-page__section--bordered-top">
                <Words bold small spaced-bottom block>{{ $t('pages.vehicleClassFormPage.attributes') }}</Words>
                <Alert v-if="!attributeSet" type="info">{{
                    $t('pages.vehicleClassFormPage.attributes_pending')
                }}</Alert>
                <template v-else>
                    <AttributeSetAvailabilitySelection
                        v-for="set in attributeSet.optionGroups"
                        :key="set.value"
                        v-model="item.availableAttributes"
                        :readonly="!$can('updateVehicleClassesGlobalData')"
                        :attribute-set="set"
                    />
                </template>
            </div>

            <div class="vehicle-class-form-page__section vehicle-class-form-page__section--bordered-top">
                <Words bold small spaced-bottom block>{{ $t('pages.vehicleClassFormPage.orderTypes') }}</Words>
                <Alert v-if="!orderTypes" type="info">{{ $t('pages.vehicleClassFormPage.orderTypes_pending') }}</Alert>
                <template v-else>
                    <Cardion
                        v-for="group in orderTypes.optionGroups"
                        :key="group.value"
                        :headline="$t(`pages.vehicle.availableMethods.${group.identifier}`)"
                        :active="groupHasActiveOrderTypes(group)"
                    >
                        <GridRow :count="4" spacing="large" margin-less>
                            <CheckboxField
                                v-for="orderType in group.options"
                                :key="orderType.value"
                                v-model="item.availableOrderTypes"
                                :name="orderType.value"
                                :option="orderType.value"
                                class="span-lg-1 span-sm-2 span-4"
                            >
                                {{ $t(`pages.vehicle.availableOrderTypes.${orderType.identifier}`) }}
                            </CheckboxField>
                        </GridRow>
                    </Cardion>
                </template>
            </div>

            <div class="vehicle-class-form-page__section vehicle-class-form-page__section--bordered-top">
                <Alert v-if="!availableCountries" type="info">{{
                    $t('pages.vehicleClassFormPage.available_countries_pending')
                }}</Alert>
                <VehicleClassScopeForm
                    v-else
                    :scope="currentScope"
                    :available-countries="availableCountries"
                    @submit="saveScope"
                />
            </div>

            <div class="vehicle-class-form-page__section">
                <ErrorMessage
                    v-if="hasErrors('scopeItems.unknownErrorReference')"
                    :message="getError('scopeItems.unknownErrorReference')"
                    class="mb-4"
                />
                <ErrorMessage
                    v-else-if="hasErrors('scopeItems.*')"
                    :message="$t('pages.vehicleClassFormPage.pleaseCorrectErrors')"
                    class="mb-4"
                />

                <VehicleClassScopeTable
                    :vehicle-class="item"
                    :parent-errors="errors"
                    @edit="editScope($event)"
                    @remove="removeScope($event)"
                />
            </div>
        </div>

        <template #sticky>
            <ButtonGroup>
                <Button primary light arrow-left :disabled="isPending()" @click="close">
                    {{ $t('pages.vehicleClassFormPage.cancel') }}
                </Button>

                <Button
                    primary
                    data-test="vehicle-class-save-button"
                    :disabled="isPending()"
                    :is-loading="isPending('save')"
                    @click="save"
                >
                    <template #left><TickIcon width="18" height="18" class="icon--inline" /></template>
                    {{ $t('pages.vehicleClassFormPage.save') }}
                </Button>
            </ButtonGroup>
        </template>
    </LayoutPage>
</template>

<script>
import { EVENT_VEHICLE_CLASS_UPDATED } from '@/constants/events';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import validate from '@/services/validation/mixin';
import { isRequired, isFloat, isNumber } from '@/services/validation/rules';
import {
    getPublishedIconOptions,
    mapDeprecatedIconKeys,
    remapDeprecatedIconKeys,
} from '@/components/IconSet/VehicleClassIconSet';
import _intersection from 'lodash/intersection';
import VehicleClassView from '@/models/VehicleClass/VehicleClassView';
import ScopeItemView from '@/models/VehicleClass/ScopeItemView';
import statefulMixin from '@/plugins/mixins/statefulMixin';

import Alert from '@/components/Alert';
import AttributeSetAvailabilitySelection from '@/pages/VehicleClass/components/AttributeSetAvailabilitySelection';
import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Cardion from '@/pages/VehicleClass/components/Cardion';
import CheckboxField from '@/components/Form/CheckboxField';
import ErrorMessage from '@/components/Form/ErrorMessage';
import GridRow from '@/components/Layout/GridRow';
import LayoutPage from '@/components/Layout/Page.v2';
import MultiselectBox from '@/components/Form/MultiselectBox';
import SelectBox from '@/components/Form/SelectBox.v3';
import TextField from '@/components/Form/TextField.v2';
import ToggleSwitchField from '@/components/Form/ToggleSwitchField';
import VehicleClassApi from '@/services/Api/VehicleClass';
import VehicleClassIconInner from '@/components/Form/MultiselectBox/VehicleClassIconInner';
import VehicleClassScopeForm from '@/pages/VehicleClass/components/VehicleClassScopeForm';
import VehicleClassScopeTable from '@/pages/VehicleClass/components/VehicleClassScopeTable';
import Words from '@/components/Typography/Words';

import TickIcon from '@/assets/icons/micro/tick.svg';

export default {
    name: 'VehicleClassFormPage',
    components: {
        Alert,
        AttributeSetAvailabilitySelection,
        Button,
        ButtonGroup,
        Cardion,
        CheckboxField,
        ErrorMessage,
        GridRow,
        LayoutPage,
        MultiselectBox,
        SelectBox,
        TextField,
        ToggleSwitchField,
        VehicleClassIconInner,
        VehicleClassScopeForm,
        VehicleClassScopeTable,
        Words,

        TickIcon,
    },
    mixins: [validate, statefulMixin],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        parentRoute: {
            type: String,
            default: null,
        },
    },
    setup() {
        const getVehicleIconClass = icon => {
            const iconConfig = mapDeprecatedIconKeys(icon);
            if (iconConfig.attribute) return `${iconConfig.icon}_${iconConfig.attribute}`;
            return `${iconConfig.icon}`;
        };
        return {
            remapDeprecatedIconKeys,
            getVehicleIconClass,
        };
    },
    data() {
        return {
            attributeSet: null,
            availableCountries: null,
            orderTypes: null,
            item: VehicleClassView.create({}),
            error: false,
            currentScope: {},
            validationRules: {
                'item.icon': [isRequired()],

                'item.permissibleTotalWeight.value': [isRequired(), isFloat()],
                'item.permissibleTotalWeight.min': [isRequired(), isFloat()],
                'item.permissibleTotalWeight.max': [isRequired(), isFloat()],

                'item.emptyWeight.value': [isRequired(), isFloat()],
                'item.emptyWeight.min': [isRequired(), isFloat()],
                'item.emptyWeight.max': [isRequired(), isFloat()],

                'item.payload.value': [isRequired(), isFloat()],
                'item.payload.min': [isRequired(), isFloat()],
                'item.payload.max': [isRequired(), isFloat()],

                'item.loadingVolume.value': [isRequired(), isFloat()],
                'item.loadingVolume.min': [isRequired(), isFloat()],
                'item.loadingVolume.max': [isRequired(), isFloat()],

                'item.dimension.length.value': [isRequired(), isFloat()],
                'item.dimension.length.min': [isRequired(), isFloat()],
                'item.dimension.length.max': [isRequired(), isFloat()],

                'item.dimension.width.value': [isRequired(), isFloat()],
                'item.dimension.width.min': [isRequired(), isFloat()],
                'item.dimension.width.max': [isRequired(), isFloat()],

                'item.dimension.height.value': [isRequired(), isFloat()],
                'item.dimension.height.min': [isRequired(), isFloat()],
                'item.dimension.height.max': [isRequired(), isFloat()],

                'item.supportedTotalWeight': [isRequired(), isFloat()],
                'item.supportedMinimumLoad': [isRequired(), isFloat()],
                'item.loadingDurationBuffer': [isRequired(), isNumber()],
                'item.unloadingDurationBuffer': [isRequired(), isNumber()],
            },
        };
    },
    computed: {
        numAxesOptions() {
            return [0, 1, 2, 3, 4, 5];
        },
        iconOptions() {
            return getPublishedIconOptions();
        },
    },
    created() {
        this.refresh();
    },
    methods: {
        async refresh() {
            this.refreshAvailableCountries();
            this.refreshOrderTypes();
            await this.refreshVehicleClass();
            this.refreshAttributeSet();
        },
        async refreshVehicleClass() {
            try {
                const data = await VehicleClassApi.getOneById(this.id);
                this.item = VehicleClassView.create(data);
            } catch (err) {
                this.error = err;
            }
        },
        async refreshOrderTypes() {
            try {
                this.orderTypes = await VehicleClassApi.getOrderTypes();
            } catch (err) {
                this.error = err;
            }
        },
        async refreshAttributeSet() {
            try {
                this.attributeSet = await VehicleClassApi.getAttributeSet(this.item.identifier);
            } catch (err) {
                this.error = err;
            }
        },
        async refreshAvailableCountries() {
            try {
                this.availableCountries = await VehicleClassApi.getAvailableCountries();
            } catch (err) {
                this.error = err;
            }
        },
        close() {
            this.$router.back();
        },
        async save() {
            if (!this.isValid()) return;
            await this.stateful('save', async () => {
                try {
                    await VehicleClassApi.save(_omit(this.item.unfold(), ['name']));
                    this.close();
                    this.$eventHub.$emit(EVENT_VEHICLE_CLASS_UPDATED);
                } catch (err) {
                    this.parseServerValidation(_get(err, 'response.data.errors', {}), {
                        'item.permissibleTotalWeight.value': 'permissibleTotalWeight',
                        'item.permissibleTotalWeight.min': 'permissibleTotalWeightMin',
                        'item.permissibleTotalWeight.max': 'permissibleTotalWeightMax',
                        'item.supportedTotalWeight': 'supportedTotalWeight',
                        'item.supportedMinimumLoad': 'supportedMinimumLoad',
                        'item.emptyWeight.value': 'emptyWeight',
                        'item.emptyWeight.min': 'emptyWeightMin',
                        'item.emptyWeight.max': 'emptyWeightMax',
                        'item.payload.value': 'payload',
                        'item.payload.min': 'payloadMin',
                        'item.payload.max': 'payloadMax',
                        'item.loadingVolume.value': 'loadingVolume',
                        'item.loadingVolume.min': 'loadingVolumeMin',
                        'item.loadingVolume.max': 'loadingVolumeMax',
                        'item.isActive': 'isActive',
                        'item.loadingDurationBuffer': 'loadingDurationBuffer',
                        'item.unloadingDurationBuffer': 'unloadingDurationBuffer',
                        'scopeItems.*.country': 'scopeItems.*.country',
                        'scopeItems.*.scope': 'scopeItems.*.scope',
                        'scopeItems.*.purchasePriceKm': 'scopeItems.*.purchasePriceKm',
                        'scopeItems.*.salesPriceKm': 'scopeItems.*.salesPriceKm',
                        'scopeItems.*.purchasePriceHour': 'scopeItems.*.purchasePriceHour',
                        'scopeItems.*.salesPriceHour': 'scopeItems.*.salesPriceHour',
                        'scopeItems.*.minPurchasePriceTransport': 'scopeItems.*.minPurchasePriceTransport',
                        'scopeItems.*.minSalesPriceTransport': 'scopeItems.*.minSalesPriceTransport',
                        // edge-case which can not be remapped properly by the endpoint
                        'scopeItems.unknownErrorReference': 'scopeItems.#',
                    });
                }
            });
        },
        editScope(scope) {
            this.$set(this, 'currentScope', {
                id: this.item.scopeItems.indexOf(scope),
                scope: scope.unfoldToLocalForm(),
            });
        },
        removeScope(scope) {
            const scopeItems = this.item.scopeItems;
            const index = scopeItems.indexOf(scope);

            if (index > -1) {
                scopeItems.splice(index, 1);
                this.$set(this.item, 'scopeItems', scopeItems);
            }
        },
        saveScope(index, data) {
            const view = ScopeItemView.create({
                ...data,
                payload: this.item.payload.value,
            });

            if (index === -1) {
                this.item.scopeItems.push(view);
            } else {
                this.$set(this.item.scopeItems, index, view);
            }
        },
        groupHasActiveOrderTypes(orderTypesGroup) {
            if (!orderTypesGroup?.options) return false;

            const values = Object.values(orderTypesGroup?.options).map(o => o.value);

            return _intersection(this.item.availableOrderTypes, values).length > 0;
        },
    },
};
</script>

<style scoped lang="scss">
.vehicle-class-form-page__status {
    display: flex;
    justify-content: flex-end;
}

.vehicle-class-form-page__container {
    max-width: 900px;
    margin: 60px auto 0;

    @media only screen and (max-width: $layout-desktop-max) {
        max-width: 100%;
        margin-left: 15px;
        margin-right: 15px;
    }
}

.vehicle-class-form-page__section {
    margin-top: 60px;
}

.vehicle-class-form-page__section--bordered-top {
    border-top: 1px solid $color-littleDarkerThanLittleDarkerThanMediumGrey;
    padding-top: 60px;
}
</style>

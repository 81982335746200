<template>
    <div
        v-if="canAddNewVariant || hasNoValidPollutionType"
        :class="[
            'row',
            { 'row--disabled': !value.pollutionTypeId, 'row--no-valid-pollution-types': hasNoValidPollutionType },
        ]"
    >
        <SelectBox
            v-model="value.pollutionTypeId"
            :options="pollutionTypesOptions"
            :option-value-renderer="option => option.value"
            :option-label-renderer="option => option.label"
            class="select"
            :disabled="!canAddNewVariant"
            small
        />

        <TextField
            v-model.number="value.pricePerTon"
            small
            inline-label
            type="number"
            step="0.01"
            class="tf"
            :label="getCurrencySign()"
            :disabled="!value.pollutionTypeId"
            :precision="2"
        />

        <TextField
            v-model.number="value.wasteCapacityPerDayInTon"
            small
            inline-label
            type="number"
            step="0.01"
            class="tf"
            label="t"
            :disabled="!value.pollutionTypeId"
            :precision="2"
        />

        <div class="cell4">
            <PopUp class="pop-up-info" variant="fixed-width-220" position="top" arrow-size="small">
                <InfoIcon :width="14" :height="14" class="icon--inline" />
                <div slot="content">
                    <Words tiny block class="info-words">
                        {{ $t(`${i18nKey}.hint`) }}
                    </Words>
                </div>
            </PopUp>
            <Words ellipsis unbreakable tiny class="info-words">0 {{ $t(`${i18nKey}.tonPerWeek`) }}</Words>
        </div>

        <div class="cell5" />
        <div class="cell6">
            <Button
                variant="reduced-padding"
                :disabled="!value.pollutionTypeId"
                primary
                @click="clickAddBurdeningButton"
            >
                {{ $t(`${i18nKey}.addBurdening`) }}
            </Button>
        </div>
    </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';

import Button from '@/components/Button/Button';
import PopUp from '@/_components/PopUp/PopUp';
import SelectBox from '@/components/Form/SelectBox.v3';
import TextField from '@/components/Form/TextField.v2';
import Toaster from '@/services/Toaster';
import Words from '@/components/Typography/Words';

import InfoIcon from '@/assets/icons/regular/_info.svg';
import { getCurrencySign } from '@/services/utils/currency';

const SUPPLIER_WASTE_PRODUCT_DETAILS_ADD_NEW_VARAINT_DATA_DEFAULTS = {
    pollutionTypeId: null,
    pricePerTon: null,
    wasteCapacityPerDayInTon: null,
};

export default {
    name: 'SupplierWasteProductDetailsAddNewVariant',
    components: {
        Button,
        PopUp,
        SelectBox,
        TextField,
        Words,

        InfoIcon,
    },
    props: {
        i18nKey: {
            type: String,
            default: 'pages.products.supplierProductsList.wasteProductDetails',
        },
        product: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            value: _cloneDeep(SUPPLIER_WASTE_PRODUCT_DETAILS_ADD_NEW_VARAINT_DATA_DEFAULTS),
        };
    },

    computed: {
        hasVariantsDisposal() {
            return this.product?.variantsDisposal?.length;
        },
        hasPollutionTypes() {
            return this.product?.pollutionTypes?.length;
        },
        hasNoValidPollutionType() {
            return !this.canAddNewVariant && !this.hasVariantsDisposal;
        },
        canAddNewVariant() {
            return this.hasVariantsDisposal < this.hasPollutionTypes && this.validPollutionTypes.length;
        },
        validPollutionTypes() {
            return (this.product?.pollutionTypes || []).filter(
                pollutionType =>
                    pollutionType.isActive &&
                    !this.product.variantsDisposal?.filter(variant => variant.pollutionTypeId === pollutionType.id)
                        .length
            );
        },
        pollutionTypesOptions() {
            const options = [
                { value: null, label: this.$t('pages.products.supplierProductsList.wasteProductDetails.selectLabel') },
            ];

            this.validPollutionTypes.forEach(pollutionType => {
                options.push({ value: pollutionType.id, label: pollutionType.label });
            });

            return options;
        },
    },

    mounted() {
        if (!this.canAddNewVariant && !this.hasVariantsDisposal) {
            Toaster.info(this.$t('pages.products.supplierProductsList.wasteProductDetails.noPollutionTypes'));
        }
    },

    methods: {
        getCurrencySign,
        clickAddBurdeningButton() {
            this.$emit('add-new', { ...this.value });

            // reset select box
            this.value = _cloneDeep(SUPPLIER_WASTE_PRODUCT_DETAILS_ADD_NEW_VARAINT_DATA_DEFAULTS);
        },
    },
};
</script>

<style lang="scss" scoped src="./SupplierWasteProductDetailsAddNewVariant.scss" />

import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVuexState } from '@/reactBridge/useVuex';
import { CancelIllustration } from '@/constructionProjects/components/icons/CancelIllustration';
import { Avatar, LoadingSpinner } from '@schuettflix/react-components';
import { ChevronDownIcon, MaterialIcon, FolderIcon, FacilitiesIcon } from '@schuettflix/icons-react';
import { Highlighter } from './components/Highlighter';
import useIsPlatformAdmin from '@/hooks/useIsPlatformAdmin';
import store from '@/store';
import clsx from 'clsx';
import { trackCheckoutEvent } from '@/pages/Checkout/trackCheckoutEvent';
const SearchResultAccordion = ({ header, subHeader, isOpen, icon, isDisabled, onClick, children, }) => {
    return (<details open={isOpen} className={clsx('mt-4', { 'pointer-events-none': isDisabled })} onClick={onClick}>
            <summary className="flex items-center justify-between py-1">
                <div className="flex items-center gap-2">
                    {icon}
                    <span className={clsx('font-headline-md-strong', { 'text-disabled': isDisabled })}>{header}</span>
                    <span className={clsx('text-subdued', { 'text-disabled': isDisabled })}>({subHeader})</span>
                </div>
                <ChevronDownIcon className={clsx({ 'rotate-180 transition-transform': isOpen })}/>
            </summary>
            {children}
        </details>);
};
const EmptySearchResults = () => {
    const { t } = useTranslation();
    return (<div className="flex flex-col items-center justify-center px-4 text-center" data-test="search-results-empty-state">
            <CancelIllustration />
            <p className="font-headline-md-strong mt-4">
                {t('pages.checkout.productSelection.searchResults.empty.title')}
            </p>
            <p className="font-copy-md mt-2">{t('pages.checkout.productSelection.searchResults.empty.text')}</p>
        </div>);
};
export const ProductSearchResults = ({ searchTerm = '', isLoading = false, products = [], sellers = [], categories = [], onClick, }) => {
    const { t } = useTranslation();
    const hasProducts = products && products.length > 0;
    const hasSellers = sellers && sellers.length > 0;
    const hasCategories = categories && categories.length > 0;
    const hasSearchResults = hasProducts || hasSellers || hasCategories;
    const isPlatformAdmin = useIsPlatformAdmin();
    const savedSearchType = useVuexState(store, state => state.basket.searchType);
    const [selectedAccordion, setSelectedAccordion] = useState(savedSearchType || (isPlatformAdmin && hasSellers ? 'seller' : 'product'));
    const refinedProducts = useMemo(() => {
        return products.map(product => {
            const attributeSetEntries = Object.entries(product.attributes.reduce((acc, attribute) => {
                const attributes = acc[attribute.attributeSetId] ?? [];
                attributes.push(attribute);
                acc[attribute.attributeSetId] = attributes;
                return acc;
            }, {}));
            const sortedAttributeEntries = attributeSetEntries.sort((a, b) => {
                return a[1][0].attributeSetDisplayOrder - b[1][0].attributeSetDisplayOrder;
            });
            const attributeSets = sortedAttributeEntries.map(([attributeSetId, attributes]) => {
                return {
                    attributeSetId,
                    attributeSetName: attributes[0]?.attributeSetName,
                    attributeSetDisplayOrder: attributes[0]?.attributeSetDisplayOrder,
                    attributes,
                };
            });
            return {
                product,
                attributeSets,
            };
        });
    }, [products]);
    const handleSearchSelection = (selectedType, selectedResult, index) => {
        const selection = { ...selectedResult, selectedType };
        const getSelectedInformation = () => {
            if (selection.selectedType === 'product') {
                return selection.attributeRange ? selection.attributes.length + 1 : selection.attributes.length;
            }
            return selection.availableProductsCount;
        };
        trackCheckoutEvent('product', 'search_result_selected', {
            search_term: searchTerm,
            search_product_results: products.length,
            search_partner_results: sellers.length,
            search_category_results: categories.length,
            search_selected_section: selectedType,
            search_selected_name: selection.selectedType === 'seller' ? selection.organizationName : selection.name,
            search_selected_information: getSelectedInformation(),
            search_list_position: index + 1,
        });
        onClick(selection);
    };
    const SearchResultsList = () => {
        const searchResultsList = useRef(null);
        return (<main className="-mt-4" ref={searchResultsList} data-test="search-results-list">
                {/* Products */}
                <SearchResultAccordion header={t('pages.checkout.productSelection.searchResults.products.header')} subHeader={t('pages.checkout.productSelection.searchResults.products.subHeader', {
                count: products.length,
            })} icon={<MaterialIcon monochrome size="md"/>} isOpen={selectedAccordion === 'product'} isDisabled={!hasProducts} onClick={() => {
                selectedAccordion !== 'product' ? setSelectedAccordion('product') : setSelectedAccordion(null);
            }} data-test="search-results-list-products">
                    <ul className="mt-4">
                        {refinedProducts.map(({ product, attributeSets }, index) => {
                return (<li key={`${index}-${product.name}`} className="cursor-pointer pb-4 pt-2" onClick={() => handleSearchSelection('product', product, index)} data-test="search-results-list-products-item">
                                    <p className="font-copy-md-strong pb-1">
                                        <Highlighter highlight={searchTerm} text={product.masterProduct.label}/>
                                    </p>

                                    {product.availableProducts && (<p className="font-copy-md pb-1">
                                            {t('pages.checkout.productSelection.searchResults.availableProducts.label', {
                            count: product.availableProducts,
                        })}
                                        </p>)}
                                    {product.attributeRange && (<p key={`{product.attributeRange.min}/{product.attributeRange.max} {product.attributeRange.unit}`} className="font-copy-md">
                                            {t('pages.checkout.productSelection.searchResults.attributes.grainSize.label')}
                                            :{' '}
                                            <span className="text-subdued">
                                                <Highlighter highlight={searchTerm} text={`${product.attributeRange.min}/${product.attributeRange.max}`} className="mr-1"/>
                                                <Highlighter highlight={searchTerm} text={product.attributeRange.unit}/>
                                            </span>
                                        </p>)}
                                    {attributeSets.map(attributeSet => (<p key={attributeSet.attributeSetId} className="font-copy-md">
                                            {attributeSet.attributeSetName}:{' '}
                                            <span className="text-subdued">
                                                <Highlighter highlight={searchTerm} text={attributeSet.attributes
                            .map(attribute => attribute.attributeValueName)
                            .join(', ')}/>
                                            </span>
                                        </p>))}
                                </li>);
            })}
                    </ul>
                </SearchResultAccordion>

                {/* Categories */}
                <>
                    <hr className="my-4 -ml-[32px] -mr-[32px]"/>

                    <SearchResultAccordion header={t('pages.checkout.productSelection.searchResults.categories.header')} subHeader={t('pages.checkout.productSelection.searchResults.categories.subHeader', {
                count: categories.length,
            })} icon={<FolderIcon monochrome size="md"/>} isOpen={selectedAccordion === 'category'} isDisabled={!hasCategories} onClick={() => {
                selectedAccordion !== 'category'
                    ? setSelectedAccordion('category')
                    : setSelectedAccordion(null);
            }} data-test="search-results-list-categories">
                        <ul className="mt-4">
                            {categories.map((category, index) => {
                return (<li key={category.id} className={clsx('flex cursor-pointer items-center pb-4 pl-8 pt-2')} onClick={() => handleSearchSelection('category', category, index)} data-test="search-results-list-categories-item">
                                        <Avatar className="mr-2" imageUrl={category.image.small}/>
                                        <div>
                                            <p className="font-copy-md-strong pb-1">
                                                <Highlighter highlight={searchTerm} text={category.name}/>
                                            </p>
                                            <p className="font-copy-md text-subdued">
                                                {t('pages.checkout.productSelection.searchResults.categories.label', {
                        count: category.availableProductsCount,
                    })}
                                            </p>
                                        </div>
                                    </li>);
            })}
                        </ul>
                    </SearchResultAccordion>
                </>

                {isPlatformAdmin && (<>
                        <hr className="my-4 -ml-[32px] -mr-[32px]"/>

                        {/* Sellers */}
                        <SearchResultAccordion header={t('pages.checkout.productSelection.searchResults.sellers.header')} subHeader={t('pages.checkout.productSelection.searchResults.sellers.subHeader', {
                    count: sellers.length,
                })} icon={<FacilitiesIcon monochrome size="md"/>} isOpen={selectedAccordion === 'seller'} isDisabled={!hasSellers} onClick={() => {
                    selectedAccordion !== 'seller'
                        ? setSelectedAccordion('seller')
                        : setSelectedAccordion(null);
                    searchResultsList.current?.scrollIntoView({ behavior: 'auto' });
                }} data-test="search-results-list-sellers">
                            <ul className="mt-4">
                                {sellers.map((seller, index) => {
                    return (<li key={`${index}-${seller.organizationName}`} className="cursor-pointer pb-4 pt-2" onClick={e => {
                            e.stopPropagation();
                            handleSearchSelection('seller', seller, index);
                        }} data-test="search-results-list-sellers-item">
                                            <p className="font-copy-md-strong">
                                                <Highlighter highlight={searchTerm} text={seller.organizationName}/>
                                            </p>
                                            <p className="font-copy-md">
                                                <Highlighter highlight={searchTerm} text={seller.locationName}/>
                                            </p>
                                            <p className="font-copy-md text-subdued">
                                                {t('pages.checkout.productSelection.searchResults.sellerProducts.label', {
                            count: seller.availableProductsCount,
                        })}
                                            </p>
                                        </li>);
                })}
                            </ul>
                        </SearchResultAccordion>
                    </>)}
            </main>);
    };
    return (<>
            {!isLoading ? (<main className="h-full p-8">{hasSearchResults ? <SearchResultsList /> : <EmptySearchResults />}</main>) : (<LoadingSpinner className="mx-auto p-4"/>)}
        </>);
};

<template>
    <div v-if="hasDefaultSlotContent">
        <div
            ref="detail"
            class="font-copy-md"
            :class="{ default: !expanded, expanded: expanded }"
            :style="`--lines: ${lines}`"
        >
            <slot />
        </div>
        <SfLink v-if="hasMore && triggerShowMore" class="mt-2 flex items-center" @click="onClick">
            <template #leading-icon>
                <SfSysPlusIcon size="xxs" />
            </template>
            <span class="font-copy-md-strong">{{ labelMoreText }}</span>
        </SfLink>
        <SfLink v-if="hasMore && expanded" class="mt-2 flex items-center" @click="onClick">
            <template #leading-icon>
                <SfSysMinusIcon size="xxs" />
            </template>
            <span class="font-copy-md-strong">{{ labelLessText }}</span>
        </SfLink>
    </div>
</template>

<script>
import { SfLink, SfSysPlusIcon, SfSysMinusIcon } from '@schuettflix/vue-components';

export default {
    name: 'ShowMoreText',
    components: {
        SfLink,
        SfSysPlusIcon,
        SfSysMinusIcon,
    },
    props: {
        lines: {
            type: Number,
            default: 2,
        },
        hasMore: {
            type: Boolean,
            default: true,
        },
        labelMoreText: {
            type: String,
            default: 'Show more',
        },
        labelLessText: {
            type: String,
            default: 'Show less',
        },
    },
    data() {
        return {
            expanded: false,
            triggerShowMore: false,
        };
    },
    computed: {
        hasDefaultSlotContent() {
            return !!this.$slots.default;
        },
    },
    mounted() {
        this.determineShowMore();
    },
    updated() {
        this.determineShowMore();
    },
    methods: {
        onClick() {
            this.expanded = !this.expanded;
            this.$emit('click', this.expanded);
        },
        determineShowMore() {
            this.$nextTick(function () {
                this.triggerShowMore =
                    this.$refs.detail && this.$refs.detail.offsetHeight < this.$refs.detail.scrollHeight;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.default {
    --lines: 3;
    // white-space: pre-line;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: var(--lines);
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.expanded {
    display: block;
    display: -webkit-box;
    // white-space: pre-line;
}
.btn::v-deep {
    .button__content {
        display: flex;
        height: 28px;
        margin: 6px 0;
        align-items: center;
    }
}
.icon {
    display: inline-block;
    margin: 0 8px 0 0;
}
.label {
    display: inline-block;
    margin: 0;
}
</style>

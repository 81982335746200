<template>
    <div
        v-if="isActive"
        :class="{
            'flyout--active': isVisuallyOpen,
            'flyout--small': size === 'small',
            'flyout--medium': size === 'medium',
            'flyout--large': size === 'large',
            'flyout--full': size === 'full',
            'flyout--grey-background': backgroundGrey,
            [`flyout--from-${appearFrom}`]: true,
        }"
        class="flyout"
        :data-test="dataTest"
    >
        <div class="flyout__backdrop" />
        <div class="flyout__body">
            <div v-if="route" class="flyout__router-content" @click="preventContentClicks">
                <slot name="router-view">
                    <router-view />
                </slot>
            </div>
            <div v-else class="flyout__content" @click="preventContentClicks">
                <div v-if="!noHeader || $slots.header" class="flyout__header">
                    <slot name="header" :close-flyout="closeFlyout">
                        <HeaderBar v-if="!noHeader" :transparent="headerTransparent">
                            <template #left>
                                <HeaderBarItem v-if="!closeByX" button @click="closeFlyout">
                                    <SfSysArrowLeftIcon size="xs" />
                                </HeaderBarItem>
                            </template>

                            <template #headline>{{ headline || $t($route.meta.title) }}</template>
                            <template v-if="subline" #subline>{{ subline }}</template>

                            <template #right>
                                <HeaderBarItem v-if="closeByX" button @click="closeFlyout">
                                    <Words v-if="closeText" bold>{{ closeText }}</Words>
                                    <SfSysCloseIcon size="xs" />
                                </HeaderBarItem>
                            </template>
                        </HeaderBar>
                    </slot>
                    <slot name="subheader" />
                </div>
                <div
                    v-scrollable
                    :class="{
                        'flyout__inner--no-header': noHeader && !$slots.header,
                        'flyout__inner--no-footer': !$slots.bottom,
                    }"
                    class="flyout__inner scroll-container"
                >
                    <slot />
                    <div v-if="!noSpacer" class="flyout__inner-spacer">&nbsp;</div>
                </div>
                <div v-if="$slots.bottom" class="flyout__footer" :class="{ 'flyout__footer-border': !noBorder }">
                    <slot name="bottom" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _findIndex from 'lodash/findIndex';

import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Words from '@/components/Typography/Words';

import { useStyleTag } from '@vueuse/core';
import { SfSysArrowLeftIcon, SfSysCloseIcon } from '@schuettflix/vue-components';
import { useAnalyticsService } from '@schuettflix/analytics-vue';

const CLOSE_DELAY = 500;
const OPEN_DELAY = 250;

export default {
    name: 'FlyoutComponent',
    components: {
        HeaderBar,
        HeaderBarItem,
        Words,
        SfSysArrowLeftIcon,
        SfSysCloseIcon,
    },
    props: {
        screenName: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
        route: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: 'medium',
            validator: value => ['small', 'medium', 'large', 'full'].indexOf(value) !== -1,
        },
        headline: {
            type: String,
            default: null,
        },
        subline: {
            type: String,
            default: null,
        },
        noHeader: {
            type: Boolean,
            default: false,
        },
        noSpacer: {
            type: Boolean,
            default: false,
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        headerTransparent: {
            type: Boolean,
            default: false,
        },
        backgroundGrey: {
            type: Boolean,
            default: false,
        },
        appearFrom: {
            type: String,
            default: 'right',
            validator: value => ['right', 'bottom'].indexOf(value) !== -1,
        },
        closeByX: {
            type: Boolean,
            default: false,
        },
        dataTest: {
            type: String,
            default: 'flyout',
        },
        closeText: {
            type: String,
            default: '',
        },
    },
    setup() {
        const analyticsService = useAnalyticsService();

        // see https://github.com/schuettflix/frontend/pull/519#issue-1409581970
        const { load: hideMaintenanceWarning, unload: unhideMaintenanceWarning } = useStyleTag(
            /*css*/ `
            .userflowjs-launcher[style*="z-index: 5"] {
                display: none;
            }
        `,
            { immediate: false }
        );

        return { analyticsService, hideMaintenanceWarning, unhideMaintenanceWarning };
    },
    data() {
        return {
            eid: `el${this._uid}`,
            isOpen: this.isInitiallyActive(),
            isVisuallyOpen: false,
            isActive: this.isInitiallyActive(),
        };
    },
    watch: {
        $route(to) {
            if (this.route !== null) {
                const nextName = to.name || '';
                const isMatching =
                    nextName === this.route ||
                    nextName.includes(this.route) ||
                    _findIndex(to.matched, { name: this.route }) !== -1;

                if (this.isActive !== isMatching) {
                    this.changeState(isMatching);
                }
            }
        },
        active(state) {
            this.changeState(state);
        },
        isActive: {
            handler(isActive, wasActive) {
                if (this.screenName && isActive && !wasActive && !this.isVisuallyOpen) {
                    this.analyticsService.enterScreen(this.screenName);
                }

                if (isActive) {
                    this.hideMaintenanceWarning();
                } else {
                    this.unhideMaintenanceWarning();
                }
            },
            immediate: true,
        },
        isVisuallyOpen(newValue, oldValue) {
            if (this.screenName && !newValue && oldValue) {
                this.analyticsService.leaveScreen(this.screenName);
            }
        },
    },
    mounted() {
        this.isInitiallyActive() ? this.openFlyout(false) : this.closeFlyout(false);
        this.$on('close', () => this.closeByEvent());
    },
    methods: {
        isInitiallyActive() {
            const isCurrentRoute =
                this.$route.name && (this.$route.name === this.route || this.$route.name.includes(this.route));

            return (
                this.active ||
                isCurrentRoute ||
                _findIndex(this.$router.currentRoute.matched, { name: this.route }) !== -1
            );
        },

        changeState(state) {
            if (this.isActive !== state) {
                this.$emit('flyoutOpen', state);
                state ? this.openFlyout() : this.closeFlyout();
            }
        },

        closeFlyout(notify = true) {
            notify && this.$emit('closing');
            this.isVisuallyOpen = false;

            setTimeout(
                () => {
                    this.isActive = false;
                    notify && this.$emit('visually-closed');
                },
                notify ? CLOSE_DELAY : 0
            );

            notify && this.$emit('closed');
        },

        openFlyout(notify = true) {
            notify && this.$emit('opening');
            this.isActive = true;

            setTimeout(
                () => {
                    this.isVisuallyOpen = true;

                    notify && this.$emit('visually-opened');
                },
                notify ? OPEN_DELAY : 0
            );
            notify && this.$emit('opened');
        },

        preventContentClicks(e) {
            e.stopPropagation();
        },

        closeByEvent() {
            if (this.route !== null) {
                this.$root.routeBack();
            } else {
                this.closeFlyout(true);
            }
        },
    },
};
</script>

<style lang="scss">
body[class*='js--flyout-open'] {
    overflow: hidden;
}

.flyout__backdrop {
    @apply bg-overlay;
    position: fixed;
    top: var(--view-top-safe-area);
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    min-height: var(--view-height);

    visibility: hidden;
    opacity: 0;

    transition:
        visibility 0s 0.3s,
        opacity 0.3s linear;

    .flyout--active > & {
        visibility: visible;
        opacity: 1;

        transition: opacity 0.3s linear;
    }
}

// NOTE: Handle flyout nesting, by resetting --view-top-safe-area to 0 on child flyouts
.flyout .flyout {
    --view-top-safe-area: 0;
}

.flyout__body {
    position: fixed;
    top: var(--view-top-safe-area);
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    height: var(--view-height);

    .flyout--active > & {
        transform: translateX(0);
    }

    .flyout--from-bottom > & {
        transform: translate(0, 100%);
    }

    .flyout--from-bottom.flyout--active > & {
        transform: translate(0, 0);
    }

    @media screen and (min-width: 800px) {
        left: 0;
        padding-left: 50vw;

        .flyout--small > & {
            // padding-left: 60vw;
            padding-left: calc(100vw - 500px);
        }

        .flyout--medium > & {
            padding-left: 50vw;
            padding-left: calc(100vw - 960px);
        }

        .flyout--large > & {
            padding-left: 25vw;
        }

        .flyout--full > & {
            padding-left: 0;
        }
    }
}

.flyout__router-content,
.flyout__content {
    width: 100%;
    max-width: 100%;
    background-color: $color-white;
    height: var(--view-height);

    .flyout--grey-background & {
        @apply bg;
    }
}

.flyout__router-content > * {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
}

.flyout__content {
    display: flex;
    flex-flow: column nowrap;
}

.flyout__header,
.flyout__footer {
    flex: 0 0 auto;
    position: relative;
}

.flyout__inner {
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
}

.flyout__inner-spacer {
    display: block;
    width: 100%;
    height: 0;
    overflow: hidden;
    flex-shrink: 0;

    // iOS 11.0
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
        height: constant(safe-area-inset-bottom);
    }

    // iOS 11.2
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
        height: env(safe-area-inset-bottom);
    }
}

.flyout__footer.flyout__footer-border {
    border-top: 1px solid $color-mediumGrey;
}

.flyout__content.js--scrollable-parent {
    > .flyout__footer::before,
    > .flyout__header::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 20px;
        transition: opacity 0.1s ease-out;
        pointer-events: none;
        z-index: 9;
        opacity: 0;
    }

    > .flyout__footer::before {
        top: -20px;
        background: linear-gradient(
            to top,
            hsl(0, 0%, 0%) 0%,
            hsla(0, 0%, 0%, 0.6) 19%,
            hsla(0, 0%, 0%, 0.4) 34%,
            hsla(0, 0%, 0%, 0.3) 47%,
            hsla(0, 0%, 0%, 0.2) 56.5%,
            hsla(0, 0%, 0%, 0.12) 65%,
            hsla(0, 0%, 0%, 0.1) 73%,
            hsla(0, 0%, 0%, 0.05) 80.2%,
            hsla(0, 0%, 0%, 0.038) 86.1%,
            hsla(0, 0%, 0%, 0.018) 91%,
            hsla(0, 0%, 0%, 0.005) 95.2%,
            hsla(0, 0%, 0%, 0.001) 98.2%,
            hsla(0, 0%, 0%, 0) 100%
        );
    }

    > .flyout__header::after {
        bottom: -20px;
        background: linear-gradient(
            to bottom,
            hsl(0, 0%, 0%) 0%,
            hsla(0, 0%, 0%, 0.6) 19%,
            hsla(0, 0%, 0%, 0.4) 34%,
            hsla(0, 0%, 0%, 0.3) 47%,
            hsla(0, 0%, 0%, 0.2) 56.5%,
            hsla(0, 0%, 0%, 0.12) 65%,
            hsla(0, 0%, 0%, 0.1) 73%,
            hsla(0, 0%, 0%, 0.05) 80.2%,
            hsla(0, 0%, 0%, 0.038) 86.1%,
            hsla(0, 0%, 0%, 0.018) 91%,
            hsla(0, 0%, 0%, 0.005) 95.2%,
            hsla(0, 0%, 0%, 0.001) 98.2%,
            hsla(0, 0%, 0%, 0) 100%
        );
    }

    &.js--scrollable-bottom > .flyout__footer::before {
        opacity: 0.1;
    }

    &.js--scrollable-top > .flyout__header::after {
        opacity: 0.1;
    }

    &.js--scrollable-top.js--scrollable-bottom {
        > .flyout__footer::before,
        > .flyout__footer::after {
            opacity: 0.1;
        }
    }

    > .js--scrollable {
        &::before,
        &::after {
            display: none;
            position: static;
        }
    }
}
</style>

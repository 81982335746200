import React, { useCallback } from 'react';
import clsx from 'clsx';
import { EventBus } from '@/services/EventBus';
import { ArrowLeftIcon } from './icons/ArrowLeftIcon';
import store from '@/store';
import { ChevronDownIcon } from '@schuettflix/icons-react';
import { NotificationIcon } from '@/constructionProjects/components/icons/NotificationIcon';
import { HelpIcon } from '@/constructionProjects/components/icons/HelpIcon';
import { useTranslation } from 'react-i18next';
import userflow from 'userflow.js';
import { AvatarIcon } from '@/constructionProjects/components/icons/AvatarIcon';
import { useVuexGetter } from '@/reactBridge/useVuex';
import { ChevronRightIcon } from '@/constructionProjects/components/icons/ChevronRightIcon';
const MobileTitle = ({ title, description, className, onBack }) => {
    return (<div className={clsx('flex items-center overflow-hidden', className)}>
            {onBack && <ArrowLeftIcon size="sm" className="mr-4 flex-shrink-0 cursor-pointer" onClick={onBack}/>}
            <div className="flex flex-col overflow-hidden">
                <p className="font-copy-md-strong truncate pr-4">{title}</p>
                {description && <p className="font-copy truncate text-subdued">{description}</p>}
            </div>
        </div>);
};
const DesktopTitle = ({ title, navigationTitle, className, onBack }) => {
    return (<div className={clsx('flex flex-col overflow-hidden pr-12', className)}>
            {onBack && (<div className="mb-1 flex cursor-pointer hover:opacity-50" onClick={onBack}>
                    <ArrowLeftIcon size="xs" className="mr-2 flex flex-shrink-0"/>
                    {navigationTitle && <p className="truncate lg:font-copy-sm-strong">{navigationTitle}</p>}
                </div>)}
            <p className="font-headline-lg truncate" title={title}>
                {title}
            </p>
        </div>);
};
export const Header = ({ mobileTitle, desktopTitle, navigationTitle, mobileDescription, backgroundColor, children, onBack, }) => {
    const { t } = useTranslation();
    const fullName = useVuexGetter(store, useCallback(getters => getters['user/fullName'], []));
    const profileImage = useVuexGetter(store, useCallback(getters => getters['user/profileImage'], []))?.url?.thumbnail;
    const unreadNotificationsCount = useVuexGetter(store, useCallback(getters => getters['notification/unreadCount'], []));
    const openUserMenu = () => {
        EventBus.$emit('userNavigationToggled', true);
    };
    const openNotificationCenter = () => {
        // eslint-disable-next-line vue/custom-event-name-casing
        EventBus.$emit('page.notifications', true);
    };
    const openResourceCenter = (event) => {
        event.stopPropagation();
        userflow.toggleResourceCenter();
    };
    return (<header className="flex h-[80px] flex-shrink-0 items-center border-b border-b-divider bg-surface px-4 py-2 lg:!bg-surface" style={{ backgroundColor }} data-test="header">
            <MobileTitle className="lg:hidden" title={mobileTitle} description={mobileDescription} onBack={onBack}/>
            <DesktopTitle className="hidden lg:flex" title={desktopTitle} navigationTitle={navigationTitle} onBack={onBack}/>

            <div className="ml-auto flex h-full flex-shrink-0 items-center">
                <div className="lg:hidden">{children}</div>
                <div className="hidden h-full items-center lg:flex">
                    <div className="mr-8 flex cursor-pointer items-center gap-2 hover:opacity-50" data-test="resource-center" onClick={openResourceCenter}>
                        <HelpIcon size="sm"/>
                        <p className="font-copy-sm">{t('components.layout.page.header.help')}</p>
                        <ChevronRightIcon className="h-2 w-2"/>
                    </div>
                    <div className="relative mr-[28px] flex h-full cursor-pointer items-center hover:opacity-50" onClick={openNotificationCenter}>
                        <NotificationIcon multicolor size="sm"/>
                        {!!unreadNotificationsCount && (<span className="font-copy-xs-strong absolute left-[14px] top-[14px] flex h-4 w-4 items-center justify-center rounded-full bg-brand-500 text-white">
                                {unreadNotificationsCount}
                            </span>)}
                    </div>

                    <div onClick={openUserMenu} className="flex h-full cursor-pointer items-center border-l border-light-gray-200 pl-[20px] hover:opacity-50">
                        {profileImage ? (<img src={profileImage} className="h-7 w-7 rounded-full ring ring-1 ring-offset-1"/>) : (<AvatarIcon className="h-7 w-7 rounded-full ring ring-1 ring-offset-1"/>)}
                        <p className="font-copy-md-strong mx-2" data-test="header-user-name">
                            {fullName}
                        </p>
                        <ChevronDownIcon size="xs"/>
                    </div>
                </div>
            </div>
        </header>);
};

import { useTranslation } from 'react-i18next';
import { SfPdfIcon } from '../icons/SfPdfIcon';
import { SfEditIcon } from '../icons/SfEditIcon';
import { SfDownloadIcon } from '../icons/SfDownloadIcon';
import { SfDeleteIcon } from '../icons/SfDeleteIcon';
import { SlideUpDown } from './SlideUpDown';
import LoadingSpinner from './LoadingSpinner';
import { useAbility } from '@/reactBridge/useAbility';
import { useAppDimension } from '@/reactBridge/useAppDimension';
import { format, parse } from 'date-fns';
export default function CertificateList({ certificates, isLoading, onRemove, onEdit, emptyContent: EmptyContent, }) {
    const { isDesktop } = useAppDimension();
    if (isLoading) {
        return (<div className="flex w-full justify-center p-18">
                <LoadingSpinner dark/>
            </div>);
    }
    if (certificates?.length === 0) {
        return (<div className="flex w-full justify-center px-4 py-18 md:px-18">
                <EmptyContent />
            </div>);
    }
    const Component = isDesktop ? TableView : CardView;
    return <Component certificates={certificates} onRemove={onRemove} onEdit={onEdit}/>;
}
function formatDate(date) {
    return format(parse(date), 'DD.MM.YYYY');
}
function TableView({ certificates, onRemove, onEdit }) {
    const canListFactories = useAbility('listFactories');
    const { t } = useTranslation();
    return (<table className="w-full text-left">
            <thead className="border-spacing-8 bg-surface-subdued">
                <tr className="font-copy-md-strong table-row">
                    <th className="px-4 py-2">{t('pages.certificateManagement.list.thead.name')}</th>
                    {canListFactories && (<th className="px-4 py-2">{t('pages.certificateManagement.list.thead.factories')}</th>)}
                    <th className="px-4 py-2">{t('pages.certificateManagement.list.thead.lastUpdated')}</th>
                    <th className="px-4 py-2">{t('pages.certificateManagement.list.thead.validityEndDate')}</th>
                    <th className="w-px whitespace-nowrap px-4 py-2"/>
                </tr>
            </thead>

            <tbody className="align-top">
                {certificates?.map(certificate => (<tr key={certificate.id} className="border-b border-divider bg-white">
                        <td className="max-w-[350px] p-4">
                            <div className="flex flex-row items-start gap-2">
                                <SfPdfIcon size="sm" className="flex-shrink-0"/>
                                <div className="flex flex-col gap-1">
                                    <p className="font-copy-md-strong">{certificate.document.fileName}</p>
                                    <p className="font-copy-sm text-subdued">
                                        {certificate.certificateType.documentType.label}
                                    </p>
                                </div>
                            </div>
                        </td>
                        {canListFactories && (<td className="max-w-[350px] p-4">
                                <FactoryInfo factories={certificate.factories} fallback={<p className="text-subdued">---</p>}/>
                            </td>)}
                        <td className="p-4">
                            {certificate.updated ? (<p className="whitespace-nowrap">
                                    {format(new Date(certificate.updated * 1000), 'DD.MM.YYYY')}
                                </p>) : (<p className="text-subdued">---</p>)}
                        </td>
                        <td className="p-4">
                            {certificate.validityEndDate ? (<p className="whitespace-nowrap">{formatDate(certificate.validityEndDate)}</p>) : (<p className="text-subdued">---</p>)}
                        </td>
                        <td className="p-4">
                            <Actions id={certificate.id} url={certificate.document.url} editAction={onEdit} removeAction={onRemove}/>
                        </td>
                    </tr>))}
            </tbody>
        </table>);
}
function CardView({ certificates, onRemove, onEdit }) {
    const canHaveFactories = useAbility('haveFactories');
    return (<div className="flex w-full flex-col gap-2">
            {certificates?.map(certificate => (<div key={certificate.id} className="card flex flex-col gap-4">
                    <div className="flex flex-row items-center justify-between">
                        <div className="flex flex-col">
                            <p className="font-copy-md-strong">{certificate.document.fileName}</p>
                            <p className="font-copy-sm text-subdued">
                                {certificate.certificateType.documentType.label}
                            </p>
                        </div>
                        <Actions id={certificate.id} url={certificate.document.url} editAction={onEdit} removeAction={onRemove}/>
                    </div>
                    {canHaveFactories && <FactoryInfo factories={certificate.factories}/>}
                    {certificate.validityEndDate && (<p className="whitespace-nowrap">{formatDate(certificate.validityEndDate)}</p>)}
                </div>))}
        </div>);
}
function FactoryInfo({ factories, fallback }) {
    const { t } = useTranslation();
    if (!factories?.length) {
        return fallback ? <>{fallback}</> : null;
    }
    return (<SlideUpDown header={`${t('pages.certificateManagement.list.factories.showAll')} (${factories.length})`}>
            <div className="text-sm text-subdued">
                <span>{factories.map(factory => factory.name).join(', ')}</span>
            </div>
        </SlideUpDown>);
}
function ActionButton({ icon: Icon, onClick, }) {
    return (<button type="button" className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full border-0 hover:bg-surface-hovered" onClick={onClick}>
            <Icon size="sm"/>
        </button>);
}
function Actions({ id, url, editAction, removeAction, }) {
    const canManageCertificates = useAbility('manageDisposalCertificates');
    return (<div className="flex h-full flex-row items-center justify-end">
            {url && <ActionButton onClick={() => window.open(url, '_blank')} icon={SfDownloadIcon}/>}
            {canManageCertificates && (<>
                    <ActionButton onClick={() => editAction(id)} icon={SfEditIcon}/>
                    <ActionButton onClick={() => removeAction(id)} icon={SfDeleteIcon}/>
                </>)}
        </div>);
}

<template>
    <section class="mt-4">
        <span class="font-copy-md-strong">
            {{ $t('pages.checkout.priceAdjustments.materialTransport.headline') }}
        </span>
        <div class="mt-2">
            <span class="font-copy-sm inline text-critical">
                {{ $t('pages.checkout.priceAdjustments.materialTransport.hintText') }}
            </span>
            <span class="font-copy-sm inline text-subdued">
                {{ $t('pages.checkout.priceAdjustments.materialTransport.hintTextFirst') }}
            </span>
            <span class="font-copy-sm-strong inline">
                {{ $n(transportDistance / 1000, 'distance') }}
                {{ $t('units.kilometer') }}
            </span>
            <span class="font-copy-sm inline text-subdued">
                {{ $t('pages.checkout.priceAdjustments.materialTransport.hintTextSecond') }}
            </span>
            <span class="font-copy-sm-strong inline">
                {{ getFormattedDuration(transportDuration) }}
            </span>
        </div>
        <ul class="card font-copy-md m-0 mt-6 list-none">
            <li>
                <PriceRow
                    :label="$t('general.purchasePrice.abbreviation')"
                    :price-per-weight="current.materialTransportPurchasePrice / (current.material.qty / 1000)"
                    :price-per-transport="current.materialTransportPurchasePrice"
                    :fixed-price="false"
                />
            </li>
            <li>
                <PriceRow
                    class="mt-2"
                    :label="$t('general.retailPrice.abbreviation')"
                    :price-per-weight="current.materialTransportRetailPrice / (current.material.qty / 1000)"
                    :price-per-transport="current.materialTransportRetailPrice"
                    :fixed-price="false"
                />
            </li>
        </ul>
    </section>
</template>

<script>
import { getFormattedDuration } from '@/services/utils/date';
import PricingDataView from '@/pages/Checkout/components/PriceAdjustment/models/PricingDataView';

import PriceRow from '@/pages/Checkout/Disposal/Components/PriceRow';

export default {
    name: 'MaterialTransportSection',
    components: {
        PriceRow,
    },
    props: {
        current: {
            type: PricingDataView,
            required: true,
        },
        transportDistance: {
            type: Number,
            default: null,
        },
        transportDuration: {
            type: Number,
            default: null,
        },
    },
    methods: {
        getFormattedDuration,
    },
};
</script>

<style lang="scss"></style>

import LogService from '@schuettflix/util-log';

import { isDocumentAssistantAvailable, startDocumentScanner } from '@/services/Image/NativeScanBot';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

const Log = new LogService('services/Image/NativeImage');

/**
 * @param {import('@capacitor/camera').ImageOptions & {shouldUseDocumentAssistant?: boolean}} config
 */
const getPicture = async config => {
    const useDocumentAssistant = config.shouldUseDocumentAssistant ? await isDocumentAssistantAvailable() : false;

    try {
        if (useDocumentAssistant && config.source === CameraSource.Camera) {
            return {
                image: await startDocumentScanner('JPEG'),
                usedCameraApp: 'scanbot',
            };
        }
        return {
            image: await Camera.getPhoto(config),
            usedCameraApp: config.source === CameraSource.Camera ? 'native-camera' : null,
        };
    } catch (error) {
        Log.log(`Error fetching picture: ${JSON.stringify(error)}`);
        throw error;
    }
};

function getFileEntryFromFileUri(fileUri) {
    return new Promise((resolve, reject) => {
        // "cordova-plugin-file" package is adding the resolveLocalFileSystemURL function to the window object
        window.resolveLocalFileSystemURL(
            fileUri,
            fileEntry => {
                resolve(fileEntry);
            },
            error => {
                Log.log(`Error resolving file: ${JSON.stringify(error)}`);
                reject(error);
            }
        );
    });
}

function getFileObjectFromFileEntry(fileEntry) {
    return new Promise((resolve, reject) => {
        fileEntry.file(
            fileObject => {
                resolve(fileObject);
            },
            error => {
                Log.log(`File Entry Error: ${JSON.stringify(error)}`);
                reject(error);
            }
        );
    });
}

function getFileFromFileObject(fileObject) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onloadend = result => {
            const arrayBuffer = result.target.result;

            const file = new Blob([arrayBuffer], {
                type: 'image/jpeg',
            });
            resolve(file);
        };

        fileReader.onerror = error => {
            Log.log(`FileReader Error: ${JSON.stringify(error)}`);
            reject(error);
        };

        fileReader.readAsArrayBuffer(fileObject);
    });
}

function resetStatusBar() {
    // if statusbar plugin is available do a hide and show to repaint view
    if (window.StatusBar) {
        window.StatusBar.hide();
        window.StatusBar.show();
    }
}

class NativeImage {
    constructor() {
        this.NO_IMAGE_SELECTED_ERRORS = ['No Image Selected', 'has no access to assets'];

        this.config = {};

        document.addEventListener('deviceready', () => {
            this.config = {
                quality: 61,
                resultType: CameraResultType.Uri,
                source: CameraSource.Camera,
                width: 2000,
                height: 2000,
            };
        });

        this.CAMERA = 'CAMERA';
        this.LIBRARY = 'LIBRARY';
    }

    /**
     *
     * @param {string} sourceType
     * @param {{shouldUseDocumentAssistant?: boolean}} [param1]
     * @returns {Promise<{image: File, usedCameraApp: 'scanbot' | 'native-camera' | null }>}
     */
    async getAppPicture(sourceType, { shouldUseDocumentAssistant = false } = {}) {
        if (sourceType === 'LIBRARY') {
            sourceType = CameraSource.Photos;
        } else {
            sourceType = CameraSource.Camera;
        }

        const config = {
            ...this.config,
            source: sourceType,
            shouldUseDocumentAssistant,
        };

        try {
            const { image, usedCameraApp } = await getPicture(config);

            // App context
            if (window.resolveLocalFileSystemURL) {
                const fileEntry = await getFileEntryFromFileUri(image.path);
                const fileObject = await getFileObjectFromFileEntry(fileEntry);
                const file = await getFileFromFileObject(fileObject);

                return {
                    image: file,
                    usedCameraApp,
                };
            }
            // Web context
            const url = image.dataUrl || image.webPath || `data:image/${image.format};base64,${image.base64String}`;
            const res = await fetch(url);
            const blob = await res.blob();
            return {
                image: new File([blob], `photo.${image.format}`, { type: `image/${image.format}` }),
                usedCameraApp,
            };
        } finally {
            // Implemented hack for ios where the statusbar overlaps the webview after the image dialog
            resetStatusBar();
        }
    }
}

export default new NativeImage();

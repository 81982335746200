import { asVueComponent } from '@/reactBridge/asVueComponent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { FlyoutPage } from '@/constructionProjects/components/FlyoutPage';
import { findRouteName } from '@/pages/routerUtils';
import { useOrderScreenName } from '@/pages/Checkout/analytics/react/useOrderScreenName';
import { SellerProfileCard } from './SellerProfilePage/SellerProfileCard';
import { SellerProductsSection } from './SellerProfilePage/SellerProductsSection';
import { useQuery } from '@tanstack/react-query';
import { supplierProfile } from './queries';
import { useVuexState } from '@/reactBridge/useVuex';
import store from '@/store';
import { LoadingSpinner } from '@schuettflix/react-components';
import SupplierApi from '@/services/Api/Supplier';
import cloneDeep from 'lodash/cloneDeep';
import { trackCheckoutEvent } from '@/pages/Checkout/trackCheckoutEvent';
const _SellerProfilePage = () => {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    const screenName = useOrderScreenName('sellerprofile');
    const locationId = useVuexState(store, state => state.basket.constructionSite?.constructionProjectLocationId);
    const factoryId = useVuexState(store, state => state.basket.supplierInfo?.supplier?.factoryId || null);
    const { data: profileData, isLoading } = useQuery(supplierProfile(factoryId, locationId));
    const handleBackButton = () => {
        return vueRouter.push({ name: findRouteName(vueRouter.currentRoute?.meta?.previous) });
    };
    const handleProductSelection = async (product) => {
        const supplierInfo = await SupplierApi.getOneById(factoryId);
        trackCheckoutEvent('product', 'product_select', {
            master_product_id: 'masterProductId',
            seller_product_id: 'sellerProductId',
            product_name: product.name,
            price: product.price,
            search_term: 'searchTerm',
            selected_by: 'search',
        });
        const preparedProduct = { ...cloneDeep(product), productName: product.name };
        // Delete ID from product to avoid conflicts with the API
        delete preparedProduct.id;
        store.commit('basket/setProduct', preparedProduct);
        store.commit('basket/setSupplierInfo', {
            ...supplierInfo,
            sellerProductId: product.id,
        });
        store.commit('basket/setSellerSelected');
        return vueRouter.push({ name: findRouteName(vueRouter.currentRoute?.meta?.next) });
    };
    return (<FlyoutPage onCloseAnimationEnd={handleBackButton} headerTitle={t('pages.checkout.supplierProfilePage.header')} body={<div className="flex h-full flex-col justify-between">
                    <div className="flex h-full flex-col gap-6 overflow-auto bg px-4 py-6">
                        {isLoading && <LoadingSpinner className="mx-auto"/>}
                        {!isLoading && profileData && (<>
                                <SellerProfileCard {...profileData}/>
                                <SellerProductsSection items={profileData.masterProducts} onSelect={handleProductSelection}/>
                            </>)}
                    </div>
                </div>} screenName={screenName}/>);
};
export const SellerProfilePage = asVueComponent(_SellerProfilePage);

<template>
    <aside class="LicenseText">
        <LoadingSpinner v-if="!text" block dark />
        <pre v-else v-html="text" />
    </aside>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import axios from 'axios';

export default {
    components: {
        LoadingSpinner,
    },
    props: {
        licensePath: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            text: null,
        };
    },
    watch: {
        licensePath() {
            this.fetchLicense();
        },
    },
    created() {
        this.fetchLicense();
    },
    methods: {
        async fetchLicense() {
            try {
                const { data } = await axios.get(this.licensePath, { responseType: 'text' });
                this.text = data;
            } catch (error) {
                this.text = 'Unable to load license text.';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.LicenseText pre {
    white-space: pre-wrap;
}
</style>

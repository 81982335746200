import { bootstrapApplication } from '@/bootstrapApplication';
import { setupWeb } from '@/setup/web/web.setup';
import { TrackingService } from '@/services/Tracking/TrackingService';
import { WebTrackingProvider } from '@/services/Tracking/WebTrackingProvider';
import { setAnalyticsService } from '@/services/Analytics/AnalyticsService';
import { WebAnalyticsService } from '@/services/Analytics/WebAnalyticsService';
import { setGeolocationService } from '@/services/Geolocation/GeolocationService';
import { WebGeolocationProvider } from '@/services/Geolocation/WebGeolocationProvider';
import { setBrowserService } from '@/services/Browser/BrowserService';
import { WebBrowserProvider } from './services/Browser/WebBrowserProvider';
setAnalyticsService(WebAnalyticsService);
setGeolocationService(WebGeolocationProvider);
setBrowserService(WebBrowserProvider);
TrackingService.setProvider(WebTrackingProvider);
bootstrapApplication({
    isApp: false,
    onCreate: setupWeb,
});

import clsx from 'clsx';
import { useIconSize } from '@/services/utils/useIconSize';
export default function SfDocumentsIcon({ size, className, ...props }) {
    const iconSize = useIconSize(size);
    return (<svg width={iconSize} height={iconSize} viewBox="0 0 24 24" aria-hidden="true" fill="none" className={clsx('svg-icon', className)} xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M19 9.01314V22H5V2H11.999" className="stroke-icon" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M19.9924 5.99847H14.9948V1" className="stroke-icon-accent" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M7.99811 10H15.9943M7.99811 5.99805H11.9962M7.99811 13.9941H15.9943M7.99811 17.9922H15.9943" className="stroke-icon" strokeWidth="2" strokeMiterlimit="10"/>
            <path d="M15.2871 1.71484L19.2871 5.71271" className="stroke-icon-accent" strokeWidth="2" strokeMiterlimit="10"/>
        </svg>);
}

<template>
    <LightTourIcon :id="getDomId" />
</template>

<script>
import { animateSwingForthAndBack } from '@/services/Animation/Animation';
import LightTourIcon from '@/assets/icons/freight/light-tour.svg';

export default {
    name: 'AnimatedLightTour',
    components: {
        LightTourIcon,
    },
    computed: {
        getDomId() {
            return `AnimatedLightTourSVG${this._uid}`;
        },
    },
    mounted() {
        animateSwingForthAndBack(`#${this.getDomId} > .svg-light-tour__hammock`);
    },
};
</script>

<style lang="scss" scoped>
.svg-light-tour__hammock {
    --angle: 0deg;
    transform: rotate3d(1, 0, 0, var(--angle));
}
</style>

import { EventBus } from '@/services/EventBus';

/**
 * @deprecated Consider using the useEventHub() composable instead
 */
export default {
    data() {
        return {
            eventUnsubscriptionCallbacks: [],
        };
    },
    beforeDestroy() {
        this.eventUnsubscriptionCallbacks.forEach(unsubscribe => unsubscribe());
    },
    methods: {
        subscribe(eventName, callback) {
            EventBus.$on(eventName, callback);

            const unsubscribe = () => {
                EventBus.$off(eventName, callback);
            };

            this.eventUnsubscriptionCallbacks.push(unsubscribe);

            return unsubscribe;
        },

        subscribeMutation(mutationName, callback) {
            const unsubscribe = this.$store.subscribe(({ type, payload }) => {
                if (type === mutationName) {
                    callback(payload);
                }
            });

            this.eventUnsubscriptionCallbacks.push(unsubscribe);

            return unsubscribe;
        },
    },
};

<template>
    <ButtonGroup>
        <Button arrow-right primary light @click="$emit('close')">
            {{ $t('components.organizationForm.paymentInfo.modal.paymentInfo.cancel') }}
        </Button>
        <Button icon primary @click="$emit('confirm')">
            {{ $t('components.organizationForm.paymentInfo.modal.paymentInfo.confirm') }}
        </Button>
    </ButtonGroup>
</template>

<script>
import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';

export default {
    name: 'PaymentInfoChangeModal',

    components: {
        Button,
        ButtonGroup,
    },
    props: {
        text: {
            type: String,
            default: '',
        },
    },
};
</script>

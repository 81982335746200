<template>
    <LayoutPage is-flyout :screen-name="screenName" class="checkout-summary" data-test="checkout-summary-page">
        <template #flyoutHeader>
            <HeaderBar>
                <template #left>
                    <HeaderBarItem button @click="goBack">
                        <SfSysArrowLeftIcon size="xs" />
                    </HeaderBarItem>
                </template>
                <template v-if="summary" #headline>
                    {{ pageTitle }}
                </template>
                <template v-if="pageSubtitle" #subline>
                    <Words muted small>{{ pageSubtitle }}</Words>
                </template>
                <template #right>
                    <CheckoutGuide v-if="$can('requestPhoneOrder')" />
                </template>
            </HeaderBar>
        </template>

        <div v-if="summary == null" class="checkout-summary__loader">
            <LoadingSpinner dark />
        </div>

        <transition name="fade">
            <div v-if="summary" class="flex flex-col">
                <div class="checkout-summary__main container-off-canvas-sm bg-white py-6">
                    <div class="checkout-summary__container">
                        <!-- DELIVERY / SHIPMENT / DISPOSAL -->
                        <ProductTitle
                            v-if="
                                summary.isTypeDelivery ||
                                summary.isTypeShipment ||
                                summary.isTypeDisposal ||
                                summary.isTypeProject
                            "
                            :product-description="productDescription"
                            :product-prefix="productPrefix"
                            :product-tags="productTags"
                            :product-title="productTitle"
                            :summary="summary"
                        />

                        <Tile v-if="pricingData" no-border no-padding-left-right>
                            <ProjectPositionOfferBlock
                                :pricing-data="pricingData"
                                :has-fixed-price="summaryShipmentBillingDetailsBillingMethod === 'fixedPrice'"
                            />
                        </Tile>
                        <Tile
                            v-else-if="$can('seePrices') && offerBlock && offerBlock.totalRetailPrice !== null"
                            no-border
                            no-padding-left-right
                            no-padding-bottom
                        >
                            <OfferBlock
                                :data="offerBlock"
                                :has-action="canChangePrices"
                                spaceless
                                @action="changePrices()"
                            />
                        </Tile>

                        <!-- PICKUP 1-->
                        <Tile v-if="summary.isTypePickup" no-padding-left-right>
                            <span class="font-copy-sm-strong mb-1">
                                {{ $t('pages.checkout.summary.expireDateHeadline') }}
                            </span>
                            <span class="font-copy-md inline">
                                {{ $t('pages.checkout.summary.expiresUntil') }}
                            </span>
                            <span v-if="summary.pickupExpireDate" class="font-copy-md-strong ml-1 inline">
                                {{ $d(summary.pickupExpireDate, 'short') }}
                            </span>
                            <LoadingSpinner v-else dark />
                            <span class="font-copy-sm mt-1 text-subdued">
                                <span red bold>{{ $t('pages.checkout.summary.expireDateHintLabel') }}:</span>
                                {{ $t('pages.checkout.summary.expireDateHintMessage') }}
                            </span>
                        </Tile>

                        <Tile v-if="doRequireVerifier" no-padding-bottom no-border no-padding-left-right>
                            <span class="font-copy-sm-strong mb-1">
                                {{ $t('pages.checkout.summary.supervisor.headline') }}
                            </span>
                            <GridRow :count="1" margin-less spacing="small">
                                <SupervisorSelection
                                    v-model="verifierId"
                                    verifier-only
                                    :selected-supervisor-id="verifierId"
                                />

                                <div class="checkout-summary__messagefield">
                                    <TextField
                                        v-model="message"
                                        :label="$t('pages.checkout.summary.messageLabel')"
                                        type="textarea"
                                    />
                                </div>
                            </GridRow>
                        </Tile>

                        <CostCenter
                            v-if="quote"
                            :active-cost-center="costCenter"
                            :cost-centers="costCenters"
                            :cost-center-mandatory="costCenterMandatory"
                            :basket-type="basketType"
                        />
                        <TermsHint
                            v-if="summary.isTypeDisposal"
                            translation-path="pages.checkout.summary.termsHint.hint"
                        />
                        <Tile v-if="summary.isTypeShipment && !summary.carrier" no-border no-padding-left-right>
                            <span class="font-copy-sm-strong mb-4">
                                {{ $t('pages.checkout.summary.validity.title') }}
                            </span>
                            <span class="font-copy-sm">
                                {{ validityText }}
                            </span>
                        </Tile>
                        <Tile v-if="!summary.isNewQuote && !summary.isTypeProject" no-padding-left-right>
                            <People :item="summary" />
                        </Tile>

                        <!-- DELIVERY -->
                        <Tile v-if="summary.isTypeDelivery" no-border no-padding-left-right>
                            <Words
                                v-if="(summary.isNewQuote || summary.isPendingQuote) && !$can('createDeliveryQuote')"
                                tiny
                                muted
                            >
                                {{ $t('pages.checkout.summary.orderDeliveryUnavailableHint') }}
                            </Words>

                            <TermsHint
                                v-if="(summary.isNewQuote || summary.isPendingQuote) && $can('createDeliveryQuote')"
                                :showExtendedLegalTermLink="isAustrianMarket"
                                translation-path="pages.checkout.summary.termsHint.hint"
                            />

                            <TermsHint
                                v-if="summary.isCustom && $can('createCustomQuote')"
                                :showExtendedLegalTermLink="isAustrianMarket"
                                :translation-path="getCustomOrderLegalTerms"
                                :client-name="summary.client.name"
                            />
                        </Tile>

                        <!-- SHIPMENT -->
                        <Tile v-if="summary.isTypeShipment" no-border no-padding-left-right>
                            <TermsHint
                                v-if="summary.isCustom"
                                :client-name="summary.client.name"
                                :showExtendedLegalTermLink="isAustrianMarket"
                                :translation-path="getCustomOrderLegalTerms"
                            />
                            <TermsHint
                                v-if="summary.isNewQuote || summary.isPendingQuote"
                                :showExtendedLegalTermLink="isAustrianMarket"
                                :translation-path="getCustomOrderLegalTerms"
                            />
                        </Tile>

                        <!-- PICKUP -->
                        <Tile v-if="summary.isTypePickup" no-border no-padding-left-right>
                            <Words muted tiny block>
                                <Words red bold>{{ $t('pages.checkout.summary.contingentHint.label') }}:</Words>
                                {{ $t('pages.checkout.summary.contingentHint.message') }}
                            </Words>
                            <Words v-if="summary.nonPickupPenalty > 0" muted tiny block>
                                <br />
                                {{
                                    $t('pages.checkout.summary.contingentHint.penaltyNote', {
                                        penalty: $n(summary.nonPickupPenalty, 'currency'),
                                    })
                                }}
                            </Words>

                            <div class="checkout-summary__tile-seperator" />

                            <Hint
                                v-if="(summary.isNewQuote || summary.isPendingQuote) && !$can('createDeliveryQuote')"
                                center
                                spaceless
                            >
                                {{ $t('pages.checkout.summary.orderDeliveryUnavailableHint') }}
                            </Hint>

                            <TermsHint
                                v-if="(summary.isNewQuote || summary.isPendingQuote) && $can('createDeliveryQuote')"
                                :showExtendedLegalTermLink="isAustrianMarket"
                                translation-path="pages.checkout.summary.termsHint.hint"
                                spaceless
                            />
                        </Tile>
                        <NoteSection
                            v-if="summary.isTypeProject"
                            v-model="summary.projectPositionNote"
                            :name="'summary.projectPositionNote'"
                            :parent-validation-rules="validationRules"
                        />
                    </div>
                </div>

                <div class="flex flex-col">
                    <!-- ... GREY AREA BENEATH THE CALL TO ACTION STARTS HERE ... -->

                    <div class="container-off-canvas-sm my-6">
                        <span class="font-headline-lg mb-12 mt-6">
                            {{ $t('pages.checkout.summary.detailsHeadline') }}
                        </span>

                        <template v-if="projectPosition">
                            <div class="card flex w-full flex-col text-left">
                                <span class="font-copy-sm-strong">
                                    {{ $t('pages.checkout.summary.projectAndPosition.headline') }}
                                </span>
                                <div class="mt-6 flex justify-between">
                                    <div class="mt-2 flex flex-col">
                                        <span class="font-copy-sm mb-2 text-subdued">
                                            {{ $t('pages.checkout.summary.projectAndPosition.project.headline') }}
                                        </span>
                                        <span class="font-copy-md-strong">
                                            {{ projectPosition.project.name }}
                                        </span>
                                    </div>
                                    <div class="mt-2 flex flex-col">
                                        <span class="font-copy-sm mb-2 text-subdued">
                                            {{ $t('pages.checkout.summary.projectAndPosition.position.headline') }}
                                        </span>
                                        <span class="font-copy-md-strong">
                                            {{ projectPosition.number }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-if="summary.isTypeProject">
                            <!-- Validity And Status Summary -->

                            <ValidityAndStatusSection
                                :status="projectPositionStatus"
                                :validity-range="projectPositionValidityRange"
                            />
                            <div class="checkout-summary__change-action">
                                <BaseButton arrow-right @click="changeValidityAndStatus()">{{
                                    $t('pages.checkout.summary.actions.change.positionValidity')
                                }}</BaseButton>
                            </div>
                        </template>
                        <!-- SHIPMENT -->
                        <template v-if="summary.isCustom && summary.isTypeShipment">
                            <div class="card mt-12">
                                <ClientTile :organization="summary.client" show-headline show-address spaceless />
                            </div>
                            <div v-if="canEdit('client')" class="checkout-summary__change-action">
                                <BaseButton arrow-right @click="changeClient()">
                                    {{ $t('pages.checkout.summary.actions.change.client') }}
                                </BaseButton>
                            </div>
                        </template>

                        <!-- DELIVERY/PICKUP/DISPOSAL -->
                        <template v-if="summary.constructionSite">
                            <ConstructionSite
                                class="mt-12"
                                :construction-site-address="summary.constructionSite.address"
                                :construction-site-name="summary.constructionSite.name"
                                :client-name="summary.client.name"
                                :editable="false"
                            />
                            <div
                                v-if="canEdit('constructionSite') && !summary.isTypeDisposal"
                                class="checkout-summary__change-action"
                            >
                                <BaseButton arrow-right @click="changeConstructionSite()">
                                    {{ $t('pages.checkout.summary.actions.change.constructionSite') }}
                                </BaseButton>
                            </div>
                        </template>
                        <!-- DISPOSAL -->
                        <template v-if="summary.isTypeDisposal">
                            <template v-if="summary.supplierInfo">
                                <DisposerCard class="mt-12" has-title :disposer="disposerInfo" preview />
                            </template>
                            <WasteCard class="mt-12" :waste="wasteInfo" :editable="false" preview />
                            <WasteDescription
                                class="mt-12"
                                :waste="wasteDescription"
                                :is-hazardous="summary.product.isHazardousWaste"
                                :editable="false"
                                preview
                            />
                            <AnalysisDocuments
                                class="mt-12"
                                :documents="wasteAnalyses.documents"
                                :other-documents="wasteAnalyses.otherDocuments"
                                :images="wasteAnalyses.images"
                                :editable="false"
                                preview
                            />
                        </template>

                        <!-- DELIVERY/PICKUP -->
                        <template v-if="summary.supplierInfo && !summary.isTypeDisposal">
                            <div class="card mt-12">
                                <SupplierTile
                                    :supplier-info="summary.supplierInfo"
                                    :construction-site="summary.constructionSite"
                                    show-address
                                    show-headline
                                    :show-distance="summary.isTypePickup"
                                    :business-hours-type="summary.isTypePickup ? 'default' : 'none'"
                                    :show-overall-rating="summary.isTypePickup"
                                    :show-material-rating="summary.isTypeDelivery || summary.isTypeProjectDelivery"
                                    spaceless
                                />
                            </div>
                            <div v-if="canEdit('supplier')" class="checkout-summary__change-action">
                                <BaseButton arrow-right @click="changeSupplier()">
                                    {{ $t('pages.checkout.summary.actions.change.supplier') }}
                                </BaseButton>
                            </div>
                        </template>

                        <!-- DELIVERY -->
                        <template v-if="summary.isTypeDelivery">
                            <div class="card mt-12">
                                <UnloadingSite :item="summary" site-type="unloadingSite" />
                            </div>
                            <div v-if="canEdit('additionalInformation')" class="checkout-summary__change-action">
                                <BaseButton arrow-right @click="changeAdditionalInformation()">
                                    {{ $t('pages.checkout.summary.actions.change.additionalInformation') }}
                                </BaseButton>
                            </div>

                            <div class="card mt-12">
                                <TransportsTable :line-item-group="summary" />
                            </div>
                            <div v-if="canEdit('shippingMethod')" class="checkout-summary__change-action">
                                <BaseButton arrow-right @click="changeShippingMethod()">
                                    {{ $t('pages.checkout.summary.actions.change.shippingMethod') }}
                                </BaseButton>
                            </div>
                        </template>

                        <!-- SHIPMENT -->
                        <template v-if="summary.isTypeShipment || summary.isTypeProjectShipment">
                            <div class="card mt-12">
                                <SiteTile
                                    :item="summary"
                                    type="loading"
                                    spaceless
                                    show-headline
                                    show-address
                                    :show-date="!summary.isTypeProjectShipment"
                                    show-thumbnails
                                    show-information
                                    show-contact-info
                                />
                            </div>
                            <div v-if="canEdit('loadingSite')" class="checkout-summary__change-action">
                                <BaseButton arrow-right @click="changeLoading()">
                                    {{ $t('pages.checkout.summary.actions.change.loadingSite') }}
                                </BaseButton>
                            </div>

                            <div class="card mt-12">
                                <SiteTile
                                    :item="summary"
                                    type="unloading"
                                    spaceless
                                    show-headline
                                    show-address
                                    :show-date="!summary.isTypeProjectShipment"
                                    show-thumbnails
                                    show-information
                                    show-contact-info
                                />
                            </div>
                            <div v-if="canEdit('unloadingSite')" class="checkout-summary__change-action">
                                <BaseButton arrow-right @click="changeUnloading()">
                                    {{ $t('pages.checkout.summary.actions.change.unloadingSite') }}
                                </BaseButton>
                            </div>

                            <template v-if="!summary.isCustom">
                                <div class="card mt-12">
                                    <span class="font-copy-sm-strong mb-6">
                                        {{ $t('pages.checkout.summary.vehicleClass.title') }}
                                    </span>
                                    <template v-if="offerBlock && offerBlock.groupedVehicleClasses">
                                        <span
                                            v-for="vehicleClass in offerBlock.groupedVehicleClasses"
                                            :key="vehicleClass.classId"
                                            class="font-copy-md"
                                        >
                                            {{ vehicleClass.name }}
                                        </span>
                                    </template>
                                    <span v-else class="font-copy-md text-subdued">
                                        {{ $t('pages.checkout.summary.freightDescription.empty') }}
                                    </span>
                                </div>
                                <div v-if="canEdit('vehicleClass')" class="checkout-summary__change-action">
                                    <BaseButton arrow-right @click="changeVehicleClass()">
                                        {{ $t('pages.checkout.summary.actions.change.vehicleClass') }}
                                    </BaseButton>
                                </div>
                            </template>

                            <template v-if="offerBlock && offerBlock.qty && !summary.isCustom">
                                <div class="card mt-12">
                                    <span class="font-copy-sm-strong mb-6">
                                        {{ $t('pages.checkout.summary.qtyAndFreightDescription.title') }}
                                    </span>
                                    <span v-if="summary.freightDescription" class="font-copy-md mr-4 inline">
                                        {{ offerBlock.totalTons | tons }}
                                        {{ summary.freightDescription }}
                                    </span>
                                    <span v-else class="font-copy-md mr-4 inline text-subdued">
                                        {{ $t('pages.checkout.summary.qtyAndFreightDescription.empty') }}
                                    </span>
                                    <SfTag v-if="summary.freightType">
                                        {{ $t(`pages.checkout.summary.freightType.${summary.freightType}`) }}
                                    </SfTag>
                                </div>
                                <div v-if="canEdit('freightDescription')" class="checkout-summary__change-action">
                                    <BaseButton arrow-right @click="changeFreightDescription()">
                                        {{ $t('pages.checkout.summary.actions.change.qtyAndFreightDescription') }}
                                    </BaseButton>
                                </div>
                            </template>
                            <template v-else>
                                <div class="card mt-12">
                                    <span class="font-copy-sm-strong mb-6">
                                        {{ $t('pages.checkout.summary.freightDescription.title') }}
                                    </span>
                                    <span v-if="summary.freightDescription" class="font-copy-sm mr-4 inline">
                                        {{ summary.freightDescription }}
                                    </span>
                                    <span v-else class="font-copy-sm mr-4 inline text-subdued">
                                        {{ $t('pages.checkout.summary.freightDescription.empty') }}
                                    </span>
                                    <SfTag v-if="summary.freightType">
                                        {{ $t(`pages.checkout.summary.freightType.${summary.freightType}`) }}
                                    </SfTag>
                                </div>
                                <div v-if="canEdit('freightDescription')" class="checkout-summary__change-action">
                                    <BaseButton arrow-right @click="changeFreightDescription()">
                                        {{ $t('pages.checkout.summary.actions.change.freightDescription') }}
                                    </BaseButton>
                                </div>
                            </template>

                            <div class="card mt-12">
                                <span class="font-copy-sm-strong mb-6">
                                    {{ $t('pages.checkout.summary.documents.title') }}
                                </span>

                                <DocumentsGalleryBlock
                                    :images="summary.freightImages"
                                    :documents="summary.freightDocuments"
                                />
                            </div>
                            <div v-if="canEdit('freightDocuments')" class="checkout-summary__change-action">
                                <BaseButton arrow-right @click="changeFreightDescription()">
                                    {{ $t('pages.checkout.summary.actions.change.freightDocuments') }}
                                </BaseButton>
                            </div>

                            <template v-if="summary.shipmentBillingDetails">
                                <div class="card mt-12">
                                    <ShipmentBillingDetailsTile
                                        :shipment-billing-details="summary.shipmentBillingDetails"
                                    />
                                </div>
                                <div v-if="canEdit('billingMethod')" class="checkout-summary__change-action">
                                    <BaseButton arrow-right @click="changeBillingMethod()">
                                        {{ $t('pages.checkout.summary.actions.change.billingMethod') }}
                                    </BaseButton>
                                </div>
                            </template>

                            <template
                                v-if="summary.lineItems && !summary.isCustom && summary.planningMethod === 'planned'"
                            >
                                <div class="card mt-12">
                                    <TransportsTable :line-item-group="summary" />
                                </div>
                                <div v-if="canEdit('shipmentTime')" class="checkout-summary__change-action">
                                    <BaseButton arrow-right @click="changeShipmentTime()">
                                        {{ $t('pages.checkout.summary.actions.change.shipmentTime') }}
                                    </BaseButton>
                                </div>
                            </template>
                        </template>

                        <!-- DISPOSAL -->
                        <template v-if="summary.isTypeDisposal">
                            <template v-if="disposalLoadingSiteInfo.loadingSiteLocation">
                                <div class="card mt-12">
                                    <SiteLocation :item="disposalLoadingSiteInfo" site-type="loadingSite" />
                                </div>
                                <div v-if="canEdit('unloadingSite')" class="checkout-summary__change-action">
                                    <BaseButton arrow-right @click="changeAdditionalInformation()">
                                        {{ $t('pages.checkout.summary.actions.change.loadingSite') }}
                                    </BaseButton>
                                </div>
                            </template>

                            <div class="card mt-12">
                                <TransportsTable :line-item-group="summary" />
                            </div>
                            <div v-if="canEdit('shipmentTime')" class="checkout-summary__change-action">
                                <BaseButton arrow-right @click="changeShipmentTime()">
                                    {{ $t('pages.checkout.summary.actions.change.shipmentTime') }}
                                </BaseButton>
                            </div>
                        </template>

                        <template v-if="showCarrierInfo">
                            <CarrierCard
                                v-if="summary.carrier"
                                class="mt-12"
                                :name="summary.carrier.name"
                                :address="summary.carrier.billingAddress"
                                :disposal-number="carrierDisposalNumber"
                                :editable="false"
                            />
                            <div
                                v-if="summary.carrier && canEdit('carrier') && !summary.isTypeDisposal"
                                class="checkout-summary__change-action"
                            >
                                <BaseButton arrow-right @click="changeCarrier()">
                                    {{ $t('pages.checkout.summary.actions.change.carrier') }}
                                </BaseButton>
                            </div>
                        </template>

                        <template v-if="summary.isTypeDisposal">
                            <BillingMethodCard
                                v-if="showBillingMethodCard"
                                class="mt-12"
                                :billing="billingMethodInfo"
                                :editable="false"
                            />
                        </template>
                    </div>

                    <!-- ... GREY AREA BENEATH THE CALL TO ACTION ENDS HERE ... -->
                    <div v-if="$can('createQuote')" class="checkout-summary__actions">
                        <ButtonGroup v-if="$can('createOrder', summary) || summary.isTypeProjectPosition">
                            <SfButton
                                v-if="!$root.isApp"
                                data-test="checkout-summary-confirm-button"
                                size="sm"
                                :disabled="hasInvalidValues || (!costCenter && costCenterMandatory)"
                                :is-loading="isPending()"
                                @click="placeOrder"
                            >
                                {{
                                    summary.isTypeProject
                                        ? $t('pages.checkout.summary.actions.placePosition')
                                        : $t('pages.checkout.summary.actions.placeOrder')
                                }}
                            </SfButton>
                            <ReactBridge
                                v-if="$root.isApp"
                                :react-component="SwipeButton"
                                :props="{
                                    idleMessage: summary.isTypeProject
                                        ? $t('pages.checkout.summary.actions.placePosition')
                                        : $t('pages.checkout.summary.actions.placeOrder'),
                                    successMessage: $t('pages.checkout.summary.actions.placeOrderSuccess'),
                                    processingMessage: $t('pages.checkout.summary.actions.placeOrderProcessingSuccess'),
                                    onSuccess: placeOrder,
                                }"
                            />
                        </ButtonGroup>
                        <ButtonGroup v-else-if="$can('submitQuote', summary)">
                            <SfButton
                                v-if="!$root.isApp"
                                size="sm"
                                :disabled="hasInvalidValues"
                                :is-loading="isPending()"
                                @click="placeOrder"
                            >
                                {{ $t('pages.checkout.summary.actions.sendRequest') }}
                            </SfButton>
                            <ReactBridge
                                v-if="$root.isApp"
                                :react-component="SwipeButton"
                                :props="{
                                    idleMessage: $t('pages.checkout.summary.actions.sendRequest'),
                                    successMessage: $t('pages.checkout.summary.actions.placeOrderSuccess'),
                                    processingMessage: $t('pages.checkout.summary.actions.placeOrderProcessingSuccess'),
                                    onSuccess: placeOrder,
                                    disabled: hasInvalidValues,
                                }"
                            />
                        </ButtonGroup>
                        <ButtonGroup v-if="$can('acceptQuote', summary)">
                            <SfButton class="mr-auto" type="secondary" size="sm" @click="rejectionOpen = true">
                                {{ $t('pages.checkout.summary.actions.declineRequest') }}
                            </SfButton>
                            <SfButton size="sm" :is-loading="isPending()" @click="acceptRequest">
                                {{ $t('pages.checkout.summary.actions.acceptRequest') }}
                            </SfButton>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
        </transition>

        <Flyout
            v-if="summary && summary.isPendingQuote && $can('acceptQuote', summary)"
            :active="rejectionOpen"
            size="small"
            no-header
            @closed="rejectionOpen = false"
        >
            <ConfirmationScreen @close="rejectionOpen = false">
                <template #icon>
                    <DeclineCheckoutIcon />
                </template>
                <template #content>
                    <div>
                        <Headline :level="3">
                            {{ $t('pages.checkout.summary.rejection.headline') }}
                        </Headline>

                        <span class="m-[0px] block" v-html="$t('pages.checkout.summary.rejection.description')" />
                    </div>
                </template>

                <template #additional-content>
                    <div>
                        <Words block bold small>
                            {{ $t('pages.checkout.summary.rejection.messageLabel') }}
                        </Words>

                        <TextField
                            v-model="message"
                            :label="$t('pages.checkout.summary.rejection.messageText')"
                            type="textarea"
                        />
                    </div>
                </template>
            </ConfirmationScreen>

            <template #bottom>
                <ButtonGroup>
                    <ButtonGroup>
                        <BaseButton primary light @click="rejectionOpen = false">
                            {{ $t('pages.checkout.summary.actions.back') }}
                        </BaseButton>
                        <BaseButton primary @click="rejectRequest">
                            {{ $t('pages.checkout.summary.actions.sendRejectRequest') }}
                        </BaseButton>
                    </ButtonGroup>
                </ButtonGroup>
            </template>
        </Flyout>

        <template #sticky>
            <OffscreenInfo v-if="summary && summary.isPendingQuote && !$can('updateQuote', summary)">
                <template #head>
                    <RegularInfoIcon class="icon--inline" />&nbsp;&nbsp;&nbsp;
                    <Words bold>
                        {{ $t('pages.checkout.summary.statusHint.pedingHeadline') }}
                    </Words>
                </template>
                <Words small red>
                    {{ $t('pages.checkout.summary.statusHint.pendingMessage') }}
                </Words>
            </OffscreenInfo>
        </template>
    </LayoutPage>
</template>

<script>
import Vue from 'vue';
import CostCenterViewModel from '@/pages/Checkout/components/CostCenter/models/CostCenterViewModel';
import OfferBlockView from '@/pages/Order/components/OfferBlockView';
import PricingDataView from '@/pages/Checkout/components/PriceAdjustment/models/PricingDataView';
import ProjectApi from '@/services/Api/Project';
import ProjectPositionApi from '@/services/Api/ProjectPosition';
import QuoteService from '@/services/QuoteService';
import SummaryView from '@/pages/Checkout/SummaryView';
import SupplierApi from '@/services/Api/Supplier';
import SupplierInfoView from '@/models/SupplierInfoView';
import Toaster from '@/services/Toaster';
import UserV2Api from '@/services/Api/UserV2';
import WasteExemptionsApi from '@/services/Api/Waste/WasteExemptions';
import WasteCard from '@/pages/Checkout/Disposal/ProjectPosition/SummaryPage/WasteCard';
import WasteDescription from '@/pages/Checkout/Disposal/ProjectPosition/SummaryPage/WasteDescription';
import BillingMethodCard from '@/pages/Checkout/Disposal/ProjectPosition/SummaryPage/BillingMethodCard';
import AnalysisDocuments from '@/pages/Checkout/Disposal/ProjectPosition/SummaryPage/AnalysisDocuments';
import _get from 'lodash/get';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import statefulMixin from '@/plugins/mixins/statefulMixin';
import validation from '@/services/validation/mixin';
import { BASKET_TYPE_PROJECT } from '@/constants/basketTypes';
import { EVENT_QUOTE_UPDATED } from '@/constants/events';
import {
    generateProjectDeliveryPositionRequestPayload,
    generateProjectShipmentPositionRequestPayload,
} from '@/services/utils/basket';
import { mapGetters, mapState } from 'vuex';
import { navigationFailure } from '@/services/utils/router';
import { DISPOSAL_ANALYSIS_DOCUMENT_TYPE } from '@/constants/disposal';

import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import CheckoutGuide from '@/pages/Checkout/components/CheckoutGuide';
import ClientTile from '@/components/Client/ClientTile';
import ConfirmationScreen from '@/components/Layout/ConfirmationScreen';
import ConstructionSite from '@/pages/Checkout/SummaryPage/partials/ConstructionSite';
import CarrierCard from '@/pages/Checkout/Disposal/ProjectPosition/SummaryPage/CarrierCard';
import DisposerCard from '@/pages/Checkout/Disposal/Components/DisposerCard.vue';
import DocumentsGalleryBlock from '@/pages/Checkout/components/DocumentsGalleryBlock';
import Flyout from '@/components/Layout/Flyout';
import GridRow from '@/components/Layout/GridRow';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Headline from '@/components/Typography/Headline';
import Hint from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import OfferBlock from '@/pages/Order/components/OfferBlock.v2';
import OffscreenInfo from '@/components/Layout/OffscreenInfo';
import People from '@/components/Order/People';
import ProductTitle from '@/pages/Checkout/SummaryPage/partials/ProductTitle';
import SiteTile from '@/components/SiteTile';
import SupervisorSelection from '@/components/Form/SupervisorSelection';
import SupplierTile from '@/components/Supplier/SupplierTile';
import TermsHint from '@/pages/Checkout/components/TermsHint';
import TextField from '@/components/Form/TextField.v2';
import Tile from '@/components/Layout/Tile';
import TransportsTable from '@/components/Order/TransportsTable';
import UnloadingSite from '@/components/Order/UnloadingSite';
import SiteLocation from '@/components/Order/SiteLocation';
import ShipmentBillingDetailsTile from '@/components/ShipmentBillingDetailsTile';
import Words from '@/components/Typography/Words';
import CostCenter from '@/pages/Checkout/components/CostCenter';
import DeclineCheckoutIcon from '@/assets/icons/confirmation/decline-checkout.svg';
import RegularInfoIcon from '@/assets/icons/regular/info.svg';
import { maxLength } from '@/services/validation/rules';
import { getLandfillClassName } from '@/services/Disposal/utils';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { SfTag, SfButton, SfSysArrowLeftIcon } from '@schuettflix/vue-components';
import { AvailableMarkets } from '@/services/MarketPermission/constants';

import ProjectPositionOfferBlock from '@/pages/Checkout/SummaryPage/partials/ProjectPositionOfferBlock';
import NoteSection from '@/pages/Checkout/Disposal/ProjectPosition/SummaryPage/NoteSection';

import { revertLocalizedValue } from '@/services/utils/localization';
import { FACTORY_TYPES } from '@/constants/disposal';
import ReactBridge from '@/reactBridge/ReactBridge';
import ValidityAndStatusSection from '@/pages/Checkout/SummaryPage/partials/ValidityAndStatusSection';
import { SwipeButton } from '@/_components/SwipeButton/SwipeButton';
import { useOrderScreenName } from '../analytics/vue/useOrderScreenName';
import { CONSTRUCTION_PROJECT_ADMIN_POSITIONS_ROUTE } from '@/modules/constructionProjects/pages/routes';

export default {
    name: 'SummaryPage',
    components: {
        ReactBridge,
        ValidityAndStatusSection,
        UnloadingSite,
        SiteLocation,
        BaseButton,
        ButtonGroup,
        CarrierCard,
        CheckoutGuide,
        ClientTile,
        ConfirmationScreen,
        ConstructionSite,
        DisposerCard,
        DocumentsGalleryBlock,
        Flyout,
        GridRow,
        HeaderBar,
        HeaderBarItem,
        Headline,
        Hint,
        LayoutPage,
        LoadingSpinner,
        OfferBlock,
        OffscreenInfo,
        People,
        ProductTitle,
        ProjectPositionOfferBlock,
        SiteTile,
        SupervisorSelection,
        SupplierTile,
        TermsHint,
        TextField,
        Tile,
        TransportsTable,
        ShipmentBillingDetailsTile,
        Words,
        WasteCard,
        WasteDescription,
        BillingMethodCard,
        AnalysisDocuments,
        CostCenter,
        DeclineCheckoutIcon,
        RegularInfoIcon,
        NoteSection,
        SfButton,
        SfTag,
        SfSysArrowLeftIcon,
    },
    mixins: [validation, statefulMixin, eventHubMixin],
    props: {
        quoteId: {
            type: [String, Number],
            default: null,
        },
    },
    setup() {
        return {
            screenName: useOrderScreenName('ordersummary'),
        };
    },
    data() {
        return {
            verifierId: null,
            message: null,
            rejectionOpen: false,

            summary: null,
            project: null,
            pricingData: null,
            offerBlock: null,
            SwipeButton,
        };
    },
    computed: {
        getCustomOrderLegalTerms() {
            return this.isAustrianMarket
                ? 'pages.checkout.summary.customOrderPlatformExtendedHintWithLinks'
                : 'pages.checkout.summary.customOrderPlatformHintWithLinks';
        },
        isAustrianMarket() {
            return this.quote?.client?.market?.code === AvailableMarkets.AT;
        },
        ...mapState('basket', {
            basketType: 'type',
            quote: 'quote',
            deliveryMethod: 'deliveryMethod',
            isCustom: 'isCustom',
            loadedPricesData: 'pricesData',
            projectId: 'projectId',
            projectPosition: 'projectPosition',
            costCenter: 'costCenter',
            product: 'product',
            additionalInformation: 'additionalInformation',
            storedExemptionTranslation: 'exemptionTranslation',
            projectPositionStatus: 'status',
            projectPositionValidityRange: 'validityRange',
        }),
        ...mapGetters('user', {
            currentUserId: 'id',
        }),
        ...mapGetters('basket', ['isDisposal']),

        validityText() {
            if (this.summary.lineItems[0].shippingDate !== null && this.summary.planningBase === 'loading') {
                return this.$t('pages.checkout.summary.validity.textLoading');
            } else if (this.summary.lineItems[0].shippingDate !== null && this.summary.planningBase === 'unloading') {
                return this.$t('pages.checkout.summary.validity.textUnloading');
            }
            return this.$t('pages.checkout.summary.validity.textFlexible');
        },

        hasInvalidValues() {
            return this.doRequireVerifier && !this.verifierId;
        },

        showCarrierInfo() {
            return this.summary?.carrier || (this.summary.isCustom && this.$can('createCustomQuote'));
        },

        routeType() {
            if (this.basketType.substr(0, 8) === 'project-') {
                if (this.summary.isTypeDisposal) {
                    return this.basketType.replace(/(dangerousWaste|waste)/, 'disposal');
                }
                return this.basketType;
            }

            return `${this.isCustom ? 'custom-' : ''}${this.basketType}`;
        },

        pageTitle() {
            if (this.summary.isPendingQuote) {
                return this.$t('pages.checkout.summary.titlePending', { number: this.summary.number });
            }

            if (this.summary.isRejectedQuote) {
                return this.$t('pages.checkout.summary.titleRejected', { number: this.summary.number });
            }

            if (!this.$can('submitQuote', this.summary)) {
                return this.$t('pages.checkout.summary.titleRequest');
            }

            if (this.summary.isTypeProject) {
                return this.$t('pages.checkout.summary.titleProjectPosition');
            }

            return this.$t('pages.checkout.summary.title');
        },

        pageSubtitle() {
            if (this.summary && !this.summary.isTypeProject) {
                return this.$d(this.summary.created, 'short');
            }

            if (!this.project || !this.summary) {
                return null;
            }

            return `${this.project.number} (${this.project.name})`;
        },

        canChangePrices() {
            return (
                (this.$can('createCustomQuote') && this.summary.isCustom) ||
                (this.summary.isTypeShipment && !this.summary.isTypeProjectPositionShipment && !this.summary.isCustom)
            );
        },

        isClientApplicant() {
            return this.$can('submitQuote', this.summary) && !this.$can('createOrder', this.summary);
        },

        doRequireVerifier() {
            return this.isClientApplicant && !this.summary.isTypeProjectPosition;
        },

        summaryShipmentBillingDetailsBillingMethod() {
            return this.summary?.shipmentBillingDetails?.billingMethod;
        },

        costCenters() {
            const costCenterViewModel = CostCenterViewModel.createFromSummaryViewModelAndProject(
                this.summary,
                this.project
            );
            return costCenterViewModel.costCenters.map(costCenter => {
                return {
                    name: costCenter.costCenter,
                    type: costCenter.costCenterTypes[0].type,
                    source: costCenter.costCenterTypes[0].source,
                };
            });
        },

        costCenterMandatory() {
            return this.$store.state.user?.info?.organization?.costcenterMandatory;
        },

        validationRules() {
            const rules = {};

            if (this.summary.isTypeProject) {
                rules['summary.projectPositionNote'] = [maxLength(255)];
            }

            return rules;
        },

        productPrefix() {
            if (
                this.summary.isTypeDelivery ||
                this.summary.isTypeProjectDelivery ||
                this.summary.isTypeProjectShipment ||
                this.summary.isTypeDisposal
            )
                return Vue.filter('tons')(this.summary.qty);

            if (this.summary.isTypeDisposal) {
                return `${Vue.filter('tons')(this.summary.qty)} ${this.summary.product.wasteCode} ${
                    this.summary.product.isHazardousWaste ? '*' : ''
                }`;
            }
            return this.summary.lineItems.length;
        },

        productTitle() {
            if (this.summary.isTypeDelivery || this.summary.isTypeProjectDelivery) {
                return `${this.summary.product.productNameLocalized}`;
            }

            if (this.summary.isTypeDisposal) {
                return `${this.summary.product.wasteCode}${this.summary.product.isHazardousWaste ? '*' : ''}`;
            }

            if (this.summary.isTypeProjectShipment) return `${this.summary.freightDescription}`;

            return `${this.$tc('pages.checkout.summary.shipmentTransportsTitle', this.summary.lineItems.length)}`;
        },
        productTags() {
            if (this.summary.isTypeProjectShipment)
                return [{ text: `${this.$t(`pages.checkout.summary.freightType.${this.summary.freightType}`)}` }];

            if (this.summary.isTypeDisposal) {
                return [{ text: this.summary?.product?.variantDisposal?.pollutionType?.label }];
            }

            return [];
        },
        productDescription() {
            if (this.summary.isTypeDisposal) {
                return this.summary?.product?.productNameLocalized;
            }
            return null;
        },
        disposerInfo() {
            return {
                factoryId: this.summary?.supplier?.factoryId,
                factoryName: this.summary?.supplier?.factoryName,
                name: this.summary?.supplier?.name,
                factoryType: this.summary?.supplier?.factoryType,
                factoryAddress: this.summary?.supplier?.factoryAddress,
                disposerNumber: this.summary?.supplier?.disposerNumber,
                landfillClass: this.summary?.supplier?.landfillClass,
                logo: this.summary?.supplier?.logo,
            };
        },
        wasteInfo() {
            return {
                code: this.summary.product.wasteCode,
                description: this.revertLocalizedValue(this.summary.product.productName),
                isHazardous: this.summary.product.isHazardousWaste,
            };
        },
        wasteDescription() {
            return {
                pollutionType: this.summary?.product?.variantDisposal?.pollutionType?.label,
                note: this.summary.factoryProductVariantNote,
                isPretreated: this.summary.isPretreated,
                consistency: this.summary.consistency,
                pretreatmentDescription: this.summary.pretreatmentDescription,
                additionalInternalTitle: this.summary.additionalInternalTitle,
            };
        },
        wasteAnalyses() {
            const documents = this.summary.documents.filter(
                d => d?.documentType?.code === DISPOSAL_ANALYSIS_DOCUMENT_TYPE.DECLARATION
            );
            const otherDocuments = this.summary.documents.filter(
                d => d?.documentType?.code === DISPOSAL_ANALYSIS_DOCUMENT_TYPE.ACCOMPANYING
            );
            const images = this.summary.accompanyingAnalysisImages;
            return {
                documents,
                otherDocuments,
                images,
            };
        },
        showBillingMethodCard() {
            return (
                !this.summary.product.isHazardousWaste &&
                this.summary?.supplier?.factoryType === FACTORY_TYPES.DUMPING_SITE
            );
        },
        billingMethodInfo() {
            return {
                billingMethod: this.summary.billingMethod,
                billedByStateWasteCompany: this.summary.billedByStateWasteCompany,
            };
        },
        pollutionType() {
            return this.summary?.product?.variantDisposal?.pollutionType?.label;
        },
        carrierDisposalNumber() {
            return this.summary?.carrier?.disposalNumber;
        },
        disposalLoadingSiteInfo() {
            return {
                ...this.summary,
                loadingSiteLocation: this.additionalInformation?.loadingSiteLocation,
                loadingSiteImage: this.additionalInformation?.photo,
                loadingSiteInformation: this.additionalInformation?.description,
            };
        },
    },

    mounted() {
        this.refreshQuote();
        this.refreshProject();
        this.subscribe(EVENT_QUOTE_UPDATED, () => {
            this.refreshQuote();
        });
    },
    methods: {
        getLandfillClassName,
        revertLocalizedValue,
        doRoute() {
            let routeName = null;

            // Can access order detail page?
            if (this.$can('havePlatformOrderManagement')) {
                routeName = 'order-management__order-list__order-view';
            } else if (this.$can('listOrders')) {
                routeName = 'order__order-list__order-view';
            }

            if (routeName === null) {
                return;
            }
            // Redirect to order detail page
            this.$router
                .push({
                    name: routeName,
                    params: { orderId: this.summary.orderId },
                })
                .catch(navigationFailure);
        },
        generateExemption(isDeclarationAnalysisRequired) {
            if (!isDeclarationAnalysisRequired) {
                this.exemption = this.storedExemptionTranslation ?? this.storeExemptionInBasket();
            }
        },
        async storeExemptionInBasket() {
            const allExemptions = await WasteExemptionsApi.getAll();
            this.$store.commit(
                'basket/SET_PROJECT_ORDER_EXEMPTION_TRANSLATION',
                allExemptions.find(e => e.key === this.summary.exemption)?.title
            );
        },
        async setVerifierId() {
            // Preselect verifier
            if (this.summary.supervisor && !this.verifierId) {
                const apiReturn = await UserV2Api.filter({
                    isActive: true,
                    orPermissions: ['order'],
                    ids: [this.summary.supervisor.id],
                });

                if (apiReturn.items[0].id !== null) {
                    this.verifierId = apiReturn?.items[0].id;
                }
            }
        },

        handleError(err) {
            if (err.code) {
                this.$store.commit('basket/setServerError', err);
            } else {
                this.$logger().error(err);
            }
        },
        async refreshQuote() {
            const quoteId = this.quoteId || _get(this.quote, 'id');

            if (!quoteId) {
                this.summary = SummaryView.createFromBasket(this.$store.state.basket);

                if (this.summary.isTypeProject) {
                    this.pricingData = PricingDataView.createFromProjectPositionBasket(this.loadedPricesData);
                }
            } else {
                try {
                    const quote = await QuoteService.loadById(quoteId);
                    this.summary = SummaryView.createFromQuote(quote);
                    this.offerBlock = OfferBlockView.createFromQuote(quote);

                    // Restore basket state
                    await QuoteService.restoreBasketFromQuote(quote);

                    if (this.summary.isSubmittedQuote) {
                        this.doRoute();
                        return;
                    }

                    // Has access to summary page?
                    if (
                        this.summary.isNewQuote &&
                        this.summary.createdBy &&
                        this.currentUserId !== this.summary.createdBy.id
                    ) {
                        this.$router.push({ name: 'home' }).catch(navigationFailure);
                        return;
                    }

                    this.generateExemption(this.summary.isDeclarationAnalysisRequired);

                    await this.setVerifierId();

                    this.hydrateExpireDate(this.summary);
                    this.hydrateSupplierInfo(this.summary);
                } catch (err) {
                    this.handleError(err);
                }
            }
        },

        cleanupTrackingData() {
            this.$store.commit('analytics/clearProductConfiguration');
            this.$store.commit('analytics/clearProductSearch');
        },

        async refreshProject() {
            if (this.projectId) {
                this.project = await ProjectApi.getOneById(this.projectId);
            }
        },

        async placeOrder() {
            if (!this.$can('updateQuote', this.summary)) return;
            if (!this.isValid()) return;
            await this.stateful('placeOrder', async () => {
                try {
                    if (this.summary.isTypeProject) {
                        let payload;

                        const payloadState = {
                            ...this.$store.state.basket,
                            constructionProjectId: this.$store.state.constructionProject.constructionProjectId,
                        };

                        switch (this.summary.type) {
                            case BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_DELIVERY:
                                payload = generateProjectDeliveryPositionRequestPayload(payloadState);
                                break;
                            case BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_SHIPMENT:
                                payload = generateProjectShipmentPositionRequestPayload(payloadState);
                                break;
                        }

                        if (this.summary.isTypeProject && this.summary.projectPositionNote) {
                            payload.note = this.summary.projectPositionNote;
                        }

                        const order = await ProjectPositionApi.save(payload);

                        Toaster.success(
                            this.$t('pages.checkout.summary.projectPositionSuccessMessage', { number: order.number })
                        );
                        this.$router
                            .push({
                                name: this.$root.findRouteName(CONSTRUCTION_PROJECT_ADMIN_POSITIONS_ROUTE),
                                params: {
                                    projectId: order.project.id,
                                },
                                query: this.$route.query,
                            })
                            .catch(navigationFailure);
                        this.$store.commit('basket/reset');
                    } else {
                        // (custom) Delivery / Pickup / Shipment
                        const order = await QuoteService.submitQuote({
                            verifierId: this.verifierId,
                            requestMessage: this.message,
                        });

                        AnalyticsService.trackEvent('order_created', {
                            orderId: order.orderId,
                            orderNumber: order.orderNumber,
                            organizationId: order.client.id,
                            organizationName: order.client.name,
                            customOrder: order.customOrder,
                        });

                        this.$router
                            .push({ name: this.$root.findRouteName('order-confirmation') })
                            .catch(navigationFailure);
                    }

                    this.cleanupTrackingData();
                } catch (err) {
                    this.$store.commit('basket/setServerError', err);
                }
            });
        },

        async acceptRequest() {
            if (!this.$can('acceptQuote', this.summary)) return;
            await this.stateful('acceptRequest', async () => {
                try {
                    const order = await QuoteService.acceptQuote();

                    AnalyticsService.trackEvent('order_accepted', {
                        orderId: order.orderId,
                        orderNumber: order.orderNumber,
                        organizationId: order.client.id,
                        organizationName: order.client.name,
                        customOrder: order.customOrder,
                    });

                    this.$router
                        .push({ name: this.$root.findRouteName('order-confirmation') })
                        .catch(navigationFailure);
                } catch (err) {
                    this.$store.commit('basket/setServerError', err);
                }
            });
        },

        async rejectRequest() {
            if (!this.$can('acceptQuote', this.summary) || this.isPending()) {
                return;
            }

            try {
                await QuoteService.rejectQuote(this.message);
                this.$router.push({ name: 'home' }).catch(navigationFailure);
            } catch (err) {
                this.$store.commit('basket/setServerError', err);
            }

            this.rejectionOpen = false;
        },

        /**
         * @param {SummaryView} summary
         */
        async hydrateSupplierInfo(summary) {
            if (!summary.isTypeShipment) {
                const data = await SupplierApi.getOneById(summary.factoryId);
                summary.supplierInfo = SupplierInfoView.create(data);
            }
        },

        /**
         * @param {SummaryView} summary
         */
        async hydrateExpireDate(summary) {
            if (summary.isTypePickup) {
                const data = await SupplierApi.getExpireDateById(summary.factoryId);
                if (data.pickupExpireDate) {
                    summary.pickupExpireDate = data.pickupExpireDate;
                }
            }
        },

        // TODO: look up if it's still working
        goBack() {
            if (this.$route.name === 'order-list__order-quote-view') {
                return this.$root.routeBack();
            }

            if (this.isCustom && !this.summary.isTypeProject && this.$can('createCustomQuote')) {
                return this.$router
                    .push({
                        name: this.$root.findRouteName(`checkout-${this.routeType}-price-adjustments`),
                    })
                    .catch(navigationFailure);
            }

            const deliveryMethod = this.$store.state.basket.deliveryMethod;
            if (!this.isCustom && deliveryMethod && deliveryMethod.type === 'flix') {
                return this.$router
                    .push({
                        name: this.$root.findRouteName(
                            this.$route.meta.previousDateSelect || this.$route.meta.previous
                        ),
                    })
                    .catch(navigationFailure);
            } else {
                return this.$router
                    .push({ name: this.$root.findRouteName(this.$route.meta.previous) })
                    .catch(navigationFailure);
            }
        },

        changeClient() {
            if (!this.$can('createCustomQuote')) return;

            this.$router
                .push({
                    name: this.$root.findRouteName(`checkout-${this.routeType}`),
                })
                .catch(navigationFailure);
        },

        changeConstructionSite() {
            if (!this.canEdit('constructionSite')) return;

            this.resetCostCenter();

            this.$router
                .push({
                    name: this.$root.findRouteName(`checkout-${this.routeType}`),
                })
                .catch(navigationFailure);
        },

        changeValidityAndStatus() {
            this.$router.push({ name: this.$root.findRouteName(`checkout-${this.routeType}-validity`) });
        },

        changeSupplier() {
            if (!this.canEdit('supplier')) return;

            this.$router
                .push({
                    name: this.$root.findRouteName(`checkout-${this.routeType}-supplier-selection`),
                })
                .catch(navigationFailure);
        },

        changeCarrier() {
            if (!this.canEdit('carrier')) return;

            this.$router
                .push({
                    name: this.$root.findRouteName(`checkout-${this.routeType}-carrier-selection`),
                })
                .catch(navigationFailure);
        },

        changePrices() {
            if (!this.canChangePrices) return;

            this.$router
                .push({
                    name: this.$root.findRouteName(`checkout-${this.routeType}-price-adjustments`),
                })
                .catch(navigationFailure);
        },

        changeAdditionalInformation() {
            if (!this.canEdit('additionalInformation')) return;

            this.$router
                .push({
                    name: this.$root.findRouteName(`checkout-${this.routeType}-additional-information`),
                })
                .catch(navigationFailure);
        },

        changeLoading() {
            if (!this.canEdit('loadingSite')) return;

            this.resetCostCenter();

            this.$router
                .push({
                    name: this.$root.findRouteName(`checkout-${this.routeType}`),
                })
                .catch(navigationFailure);
        },

        changeUnloading() {
            if (!this.canEdit('unloadingSite')) return;

            this.resetCostCenter();

            this.$router
                .push({
                    name: this.$root.findRouteName(`checkout-${this.routeType}-unloading`),
                })
                .catch(navigationFailure);
        },

        changeVehicleClass() {
            if (!this.canEdit('vehicleClass')) return;

            this.$router
                .push({
                    name: this.$root.findRouteName(`checkout-${this.routeType}-vehicle-class`),
                })
                .catch(navigationFailure);
        },

        changeFreightDescription() {
            if (!this.canEdit('freightDescription')) return;

            const partialRouteName = this.summary.isTypeProjectShipment
                ? 'checkout-project-shipment-freight'
                : `checkout-${this.routeType}-vehicle-and-document`;

            this.$router
                .push({
                    name: this.$root.findRouteName(partialRouteName),
                })
                .catch(navigationFailure);
        },

        changeBillingMethod() {
            if (!this.canEdit('billingMethod')) return;

            this.$router
                .push({
                    name: this.$root.findRouteName(`checkout-${this.routeType}-billing-selection`),
                })
                .catch(navigationFailure);
        },

        changeShippingMethod() {
            if (!this.canEdit('shippingMethod')) return;

            this.$router
                .push({
                    name: this.$root.findRouteName(`checkout-${this.routeType}-shipping-method`),
                })
                .catch(navigationFailure);
        },

        changeShipmentTime() {
            if (!this.canEdit('shipmentTime')) return;
            const rootPartial = this.isDisposal ? 'shipping-method' : 'time-selection';

            this.$router
                .push({
                    name: this.$root.findRouteName(`checkout-${this.routeType}-${rootPartial}`),
                })
                .catch(navigationFailure);
        },

        canEdit(section) {
            if (!this.$can('updateQuote', this.summary)) return false;

            let sectionsAllowedToEdit;

            if (this.basketType === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_DELIVERY) {
                sectionsAllowedToEdit = ['additionalInformation', 'shippingMethod'];
            }

            if (this.basketType === BASKET_TYPE_PROJECT.BASKET_TYPE_PROJECT_POSITION_SHIPMENT) {
                sectionsAllowedToEdit = ['vehicleClass', 'shipmentTime'];

                if (!this.isClientApplicant) {
                    sectionsAllowedToEdit.push('shipmentValidity');
                }
            }

            return sectionsAllowedToEdit ? sectionsAllowedToEdit.includes(section) : true;
        },

        resetCostCenter() {
            this.$store.commit('basket/setCostCenter', null);
        },
    },
};
</script>

<style lang="scss" scoped>
.checkout-summary {
    background-color: $color-lightMediumGrey;
}

.checkout-summary--has-errors {
    background-color: transparent;
}

.checkout-summary__loader {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkout-summary__product-name {
    text-align: center;
    margin: 0 0 5px;
}

.checkout-summary__product-tile {
    padding-bottom: 10px;
}

.checkout-summary__product-tile-freight-type {
    margin: 10px 0;
}

.checkout-summary__error-page {
    display: flex;
    flex-flow: column nowrap;
    align-content: center;
    min-height: 70vh;
    padding-left: 20px;
    padding-right: 20px;
}

.checkout-summary__error-icon {
    width: 100px;
    margin: 30px auto 30px;
}

.checkout-summary__tile-seperator {
    margin: 20px 0;
    height: 0;
    width: 100%;
    border-bottom: 1px solid $color-mediumGrey;
}

.checkout-summery__order-status {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.checkout-summary__change-action {
    text-align: right;
    margin: 15px 0 30px;
}

.checkout-summary__freight-description {
    .freight-description-word {
        display: inline-block;
        margin: 0 16px 4px 0;
    }
}

.checkout-summary__actions {
    background-color: $color-white;
    position: sticky;
    bottom: 0;
    border-top: 1px solid $color-lightMediumGrey;
    z-index: 1;

    // iOS 11.0
    @supports (padding-bottom: constant(safe-area-inset-bottom)) {
        padding-bottom: constant(safe-area-inset-bottom);
    }

    // iOS 11.2
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
        padding-bottom: env(safe-area-inset-bottom);
    }
}

.checkout-summary__bell .bell-container {
    stroke: #fff;
}

.checkout-summary__validity-tile {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
}

.checkout-summary__validity-content {
    display: grid;
    grid-template-columns: 1fr 10px;
    column-gap: 15px;
    align-items: center;
}

.checkout-summary__validity-message {
    display: inline-block;
    margin: 5px 0;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-wrap items-center justify-between gap-4",class:{
        'filter-sort-pagination--show-grouping-toggle': _vm.showGroupingToggle,
        'filter-sort-pagination--show-refresh': _vm.showRefresh,
        'mt-4': !_vm.spaceless,
    }},[_c('div',{staticClass:"w-auto md:shrink-0"},[_vm._t("default",null,{"filter":_vm.filter,"result":_vm.result})],2),_c('div',{staticClass:"filter-sort-pagination flex flex-row flex-wrap gap-4"},[(!_vm.hideSort && _vm.filter)?_c('FilterSort',{staticClass:"filter-sort-pagination__sorting",class:{ 'filter-sort-pagination__sorting--separated': !_vm.hasSortSetOptions },attrs:{"sort-by":_vm.filter.sortBy,"sort-direction":_vm.filter.sortDirection,"sort-options":_vm.sortOptions,"option-label-renderer":_vm.optionLabelRenderer,"has-sort-set-options":_vm.hasSortSetOptions},on:{"sort-by-update":function($event){_vm.filter.sortBy = $event},"sort-direction-update":function($event){_vm.filter.sortDirection = $event}}}):_vm._e(),(_vm.result)?_c('Pagination',{staticClass:"filter-sort-pagination__pagination shrink-0 grow basis-0",attrs:{"result":_vm.result},on:{"pageNumberUpdated":page => _vm.$emit('pageNumberUpdated', page)}}):_vm._e(),(_vm.showRefresh)?_c('div',{staticClass:"filter-sort-pagination__refresh",class:{
                'filter-sort-pagination__refresh--loading': _vm.loading,
            }},[_c('Button',{attrs:{"primary":"","light":"","icon":""},on:{"click":function($event){return _vm.$emit('refresh')}}},[_c('RefreshIcon')],1)],1):_vm._e()],1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
import { forwardRef } from 'react';
import clsx from 'clsx';
import { useIconSize } from '@/services/utils/useIconSize';
export const StopIcon = forwardRef(({ className, size = 'xs', strokeCssClass = 'stroke-icon', ...svgProps }, ref) => {
    const iconSize = useIconSize(size);
    return (<svg width={iconSize} height={iconSize} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} className={clsx('cursor-pointer', className, strokeCssClass)} {...svgProps}>
                <g clipPath={`url(#a-path)`} className={strokeCssClass} strokeWidth={1.333} strokeMiterlimit={10}>
                    <path d="M14.667 8A6.667 6.667 0 1 0 1.333 8a6.667 6.667 0 0 0 13.334 0ZM5.643 5.643l4.714 4.714M5.643 10.357l4.714-4.714"/>
                </g>
                <defs>
                    <clipPath id={`b-path`}>
                        <path fill="#fff" transform="translate(.667 .666)" d="M0 0h14.667v14.667H0z"/>
                    </clipPath>
                </defs>
            </svg>);
});
StopIcon.displayName = 'StopIcon';

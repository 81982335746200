var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CustomPriceAdjustmentsPage',{attrs:{"state":_vm.basket,"prices":_vm.pricesData,"section-to-edit":_vm.sectionToEdit,"section":_vm.section,"can-save-section":_vm.canSaveSection,"is-project":_vm.isProject,"project-position-id":_vm.projectPositionId},on:{"edit-project-position":_vm.editProjectDeliveryPosition,"calculate-project-position":_vm.calculateMaterialProjectPosition,"calculate-custom-order":_vm.calculateMaterialCustomOrder,"edit-section":_vm.editSection,"close-section":_vm.closeSection,"reset-price-data":_vm.resetPriceData,"save-prices-data":_vm.savePriceData,"save-section":_vm.saveSection,"save-project-position":_vm.saveProjectPosition,"set-disposal-price":_vm.setDisposalPrice,"submit":_vm.submit,"close":_vm.close},scopedSlots:_vm._u([{key:"paymentTerms",fn:function(){return [(!_vm.isCustomDeliveryOrForwarding)?_c('ReactBridge',{attrs:{"react-component":_vm.PaymentTermsContainer,"props":{
                organizations: _vm.getPaymentTerms({ supplierInfo: _vm.supplierInfo, carrierInfo: _vm.carrierInfo }, 'delivery'),
                paymentTermsProp: _vm.isProjectPositionEditMode
                    ? _vm.paymentTermsFromStore
                    : _vm.paymentTermsFromApi || _vm.paymentTermsFromStore,
            }},on:{"change":_vm.handlePaymentTermsChange,"defaultPaymentTerms":_vm.setDefaultPaymentTerms}}):_vm._e(),(!_vm.isCustomDeliveryOrForwarding)?_c('HorizontalDivider',{attrs:{"size":"12"}}):_vm._e()]},proxy:true},{key:"productCosts",fn:function({ error }){return [(_vm.hasMaterial)?_c('MaterialSection',{attrs:{"error":error},on:{"edit":function($event){return _vm.editSection('material')}},model:{value:(_vm.current.material),callback:function ($$v) {_vm.$set(_vm.current, "material", $$v)},expression:"current.material"}}):_vm._e()]}},{key:"transports",fn:function(){return [(_vm.hasTransport)?_c('TransportSection',{staticClass:"mt-12",attrs:{"basket-type":_vm.basket.type,"fixed-price":_vm.hasFixedPrice,"transport-distance":_vm.pricesData.distance,"transport-duration":_vm.pricesData.duration,"transport-price-unit":_vm.transportPriceUnit,"transports":_vm.current.transport,"show-distance-notice":_vm.type === 'shipment' || _vm.pricesData.type === _vm.pricesData.constructor.TYPE_PROJECT_DELIVERY},on:{"edit":function($event){return _vm.editSection('transport')}}}):_vm._e()]},proxy:true},{key:"customPosition",fn:function(){return [(_vm.hasCustomPositions)?_c('CustomPositionsSection',{staticClass:"mt-12",attrs:{"custom-positions":_vm.current.customPositions},on:{"edit":_vm.editCustomPosition,"remove":_vm.removeCustomPosition,"add":_vm.addCustomPosition}}):_vm._e()]},proxy:true},{key:"costPreview",fn:function(){return [(_vm.isProject && _vm.current.transport && _vm.current.transport.enabled.length > 0)?_c('CostPreviewSection',{staticClass:"mt-12",attrs:{"transports":_vm.current.transport,"material":_vm.current.material,"fixed-price":_vm.hasFixedPrice}}):_vm._e()]},proxy:true},{key:"totals",fn:function(){return [(_vm.current.transport && _vm.current.transport.enabled.length > 0)?_c('TotalsSection',{staticClass:"mt-12",attrs:{"fixed-price":_vm.isFixedPrice,"current":_vm.current,"material":_vm.current.material || {
                    qty: 0,
                    info: null,
                },"max-payload-transport":_vm.current.maxPayloadTransport,"type":_vm.pricesData.type}}):_vm._e()]},proxy:true},{key:"editMaterial",fn:function(){return [(_vm.section === 'material')?_c('MaterialSection',{attrs:{"is-edit-mode":"","original":_vm.original,"original-prices":_vm.originalPricesData},model:{value:(_vm.sectionData),callback:function ($$v) {_vm.sectionData=$$v},expression:"sectionData"}}):_vm._e()]},proxy:true},(_vm.section === 'transport')?{key:"editTransports",fn:function(){return _vm._l((_vm.sectionData.collection),function(vehicleClass,index){return _c('TransportEditSection',{key:vehicleClass.id,attrs:{"original-vehicle-class":_vm.original.transport.collection[index],"fixed-price":_vm.isFixedPrice,"transport-price-unit":_vm.transportPriceUnit,"show-enable-controls":_vm.vehiclesCanBeEnabled},model:{value:(_vm.sectionData.collection[index]),callback:function ($$v) {_vm.$set(_vm.sectionData.collection, index, $$v)},expression:"sectionData.collection[index]"}})})},proxy:true}:null,{key:"editShipping",fn:function(){return [(_vm.section === 'shipping')?_c('ShippingEditSection',{attrs:{"original":_vm.original,"type":_vm.pricesData.type,"show-totals":_vm.pricesData.type !== _vm.pricesData.constructor.TYPE_PROJECT_DELIVERY},model:{value:(_vm.sectionData),callback:function ($$v) {_vm.sectionData=$$v},expression:"sectionData"}}):_vm._e()]},proxy:true},{key:"editCustomPosition",fn:function(){return [(_vm.section === 'customPosition')?_c('CustomPositionEditSection',{on:{"change":function($event){return _vm.checkIfCanSaveSection($event)}},model:{value:(_vm.sectionData),callback:function ($$v) {_vm.sectionData=$$v},expression:"sectionData"}}):_vm._e()]},proxy:true}],null,true)})
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
    <Flyout
        screen-name="useraccount-settings"
        :active="myAccountFlyoutActive"
        :headline="$t('pages.userNavigation.myAccount.headline')"
        size="small"
        show-header
        @closed="closeMyAccountFlyout"
    >
        <Tile v-if="newUserData" no-border class="my-account__image-upload">
            <AvatarUpload v-model="newUserData.image" show-text-button size="huge" @input="submitUserData()" />
        </Tile>

        <Tile no-border no-padding-bottom>
            <Words block tiny muted>
                {{ $t('pages.userNavigation.myAccount.labels.firstName') }}
            </Words>
        </Tile>
        <Tile v-if="newUserData" no-padding class="my-account__button-tile">
            <SfButton
                type="secondary"
                size="sm"
                class="w-full justify-between font-normal"
                @click="openFormFlyout('userData', 'firstName')"
            >
                {{ userData.firstName }}
                <template #trailing-icon="iconProps">
                    <SfEditIcon v-bind="iconProps" />
                </template>
            </SfButton>
        </Tile>

        <Tile no-border no-padding-bottom>
            <Words block tiny muted>
                {{ $t('pages.userNavigation.myAccount.labels.lastName') }}
            </Words>
        </Tile>
        <Tile v-if="newUserData" no-padding class="my-account__button-tile">
            <SfButton
                type="secondary"
                size="sm"
                class="w-full justify-between font-normal"
                @click="openFormFlyout('userData', 'lastName')"
            >
                {{ userData.lastName }}
                <template #trailing-icon="iconProps">
                    <SfEditIcon v-bind="iconProps" />
                </template>
            </SfButton>
        </Tile>

        <Tile no-border no-padding-bottom>
            <Words block tiny muted>
                {{ $t('pages.userNavigation.myAccount.labels.username') }}
            </Words>
        </Tile>
        <Tile v-if="newUserData" no-padding class="my-account__button-tile">
            <SfButton
                type="secondary"
                size="sm"
                class="w-full justify-between font-normal"
                @click="openFormFlyout('userData', 'username')"
            >
                {{ userData.username }}
                <template #trailing-icon="iconProps">
                    <SfEditIcon v-bind="iconProps" />
                </template>
            </SfButton>
        </Tile>

        <Tile no-border no-padding-bottom>
            <Words block tiny muted>
                {{ $t('pages.userNavigation.myAccount.labels.email') }}
            </Words>
        </Tile>
        <Tile v-if="newUserData" no-padding class="my-account__button-tile">
            <SfButton
                type="secondary"
                size="sm"
                class="w-full justify-between font-normal"
                @click="openFormFlyout('userData', 'email')"
            >
                {{ userData.email }}
                <template #trailing-icon="iconProps">
                    <SfEditIcon v-bind="iconProps" />
                </template>
            </SfButton>
        </Tile>

        <Tile no-border no-padding-bottom>
            <Words block tiny muted>
                {{ $t('pages.userNavigation.myAccount.labels.mobile') }}
            </Words>
        </Tile>

        <Tile v-if="userData && userData.mobile" no-padding class="my-account__button-tile">
            <SfButton
                type="secondary"
                size="sm"
                class="w-full justify-between font-normal"
                @click="openFormFlyout('userData', 'mobile')"
            >
                {{ userData.mobile.countryCode }}{{ userData.mobile.number }}
                <template #trailing-icon="iconProps">
                    <SfEditIcon v-bind="iconProps" />
                </template>
            </SfButton>
        </Tile>

        <Tile no-border no-padding-bottom>
            <Words block tiny muted>
                {{ $t('pages.userNavigation.myAccount.labels.password') }}
            </Words>
        </Tile>
        <Tile v-if="newUserData" no-padding class="my-account__button-tile">
            <SfButton
                type="secondary"
                size="sm"
                class="w-full justify-between font-normal"
                @click="openFormFlyout('password')"
            >
                ***********
                <template #trailing-icon="iconProps">
                    <SfEditIcon v-bind="iconProps" />
                </template>
            </SfButton>
        </Tile>

        <Tile no-border no-padding-bottom>
            <Words block tiny muted>
                {{ $t('pages.userNavigation.myAccount.labels.locale') }}
            </Words>
        </Tile>
        <Tile v-if="userData && userData.locale" no-padding class="my-account__button-tile">
            <SfButton
                type="secondary"
                size="sm"
                class="w-full justify-between font-normal"
                @click="openFormFlyout('locale')"
            >
                {{ languageName }}
                <template #trailing-icon="iconProps">
                    <SfEditIcon v-bind="iconProps" />
                </template>
            </SfButton>
        </Tile>
        <Tile no-border no-padding-bottom>
            <Words block tiny muted>
                {{ $t('pages.userNavigation.myAccount.labels.account') }}
            </Words>
        </Tile>
        <Tile v-if="userData && userData.locale" no-padding class="my-account__button-tile">
            <SfButton
                type="secondary"
                size="sm"
                class="w-full justify-between font-normal"
                @click="$eventHub.$emit('page.deleteAccount', true)"
            >
                {{ $t('pages.userNavigation.myAccount.labels.deleteAccount') }}
                <template #trailing-icon="iconProps">
                    <SfSysArrowRightIcon v-bind="iconProps" />
                </template>
            </SfButton>
        </Tile>

        <Flyout
            screen-name="useraccount-settings-password-edit"
            :active="flyoutStates['password']"
            :headline="$t('pages.userNavigation.myPassword.headline')"
            size="small"
            @closed="closeFormFlyout('password')"
        >
            <Tile no-border>
                <i18n block path="pages.userNavigation.myPassword.description" tag="Words">
                    <br />
                </i18n>

                <TextField
                    id="oldPassword"
                    v-model.trim="oldPassword"
                    :label="$t('pages.userNavigation.myPassword.oldPasswordPlaceholder')"
                    :has-error="hasErrors('oldPassword')"
                    :error="getError('oldPassword')"
                    type="password"
                    name="old-password"
                    class="my-account__password-input"
                >
                    <template #icon>
                        <LockIcon width="18" height="18" />
                    </template>
                </TextField>

                <TextField
                    id="newPassword"
                    v-model.trim="newPassword"
                    :label="$t('pages.userNavigation.myPassword.newPasswordPlaceholder')"
                    :has-error="hasErrors('newPassword')"
                    :error="getError('newPassword')"
                    type="password"
                    name="new-password"
                    class="my-account__password-input"
                >
                    <template #icon>
                        <LockIcon width="18" height="18" />
                    </template>
                </TextField>

                <TextField
                    id="confirmPassword"
                    v-model.trim="confirmPassword"
                    :label="$t('pages.userNavigation.myPassword.confirmPasswordPlaceholder')"
                    :has-error="hasErrors('confirmPassword')"
                    :error="getError('confirmPassword')"
                    type="password"
                    name="confirm-password"
                    class="my-account__password-input"
                >
                    <template #icon>
                        <LockIcon width="18" height="18" />
                    </template>
                </TextField>
            </Tile>

            <ButtonGroup>
                <Button
                    :disabled="!passwordsValid || myPasswordPending"
                    type="submit"
                    primary
                    @click="handlePasswordChange"
                >
                    {{ $t('pages.userNavigation.myPassword.submitPasswordChange') }}
                </Button>
            </ButtonGroup>
        </Flyout>

        <Flyout
            :active="newUserData && whitelistedField && flyoutStates['userData']"
            :headline="
                $t('pages.userNavigation.myAccount.flyoutTitle', {
                    value: $t(`pages.userNavigation.myAccount.labels.${whitelistedField}`),
                })
            "
            size="small"
            @closed="closeFormFlyout('userData')"
        >
            <Tile no-border>
                <Words
                    v-if="
                        whitelistedField === 'username' ||
                        whitelistedField === 'mobile' ||
                        whitelistedField === 'locale'
                    "
                    block
                    spaced
                >
                    {{ $t(`pages.userNavigation.myAccount.descriptions.${whitelistedField}`) }}
                </Words>

                <TextField
                    v-if="whitelistedField === 'firstName' || hasErrors('newUserData.firstName')"
                    v-model="newUserData.firstName"
                    :has-errors="hasErrors('newUserData.firstName')"
                    :error="getError('newUserData.firstName')"
                    :label="$t('pages.userNavigation.myAccount.labels.firstName')"
                    class="my-account__text-field"
                />

                <TextField
                    v-if="whitelistedField === 'lastName' || hasErrors('newUserData.lastName')"
                    v-model="newUserData.lastName"
                    :has-errors="hasErrors('newUserData.lastName')"
                    :error="getError('newUserData.lastName')"
                    :label="$t('pages.userNavigation.myAccount.labels.lastName')"
                    class="my-account__text-field"
                />

                <TextField
                    v-if="whitelistedField === 'username' || hasErrors('newUserData.username')"
                    v-model="newUserData.username"
                    :has-errors="hasErrors('newUserData.username')"
                    :error="getError('newUserData.username')"
                    :label="$t('pages.userNavigation.myAccount.labels.username')"
                    class="my-account__text-field"
                />

                <TextField
                    v-if="whitelistedField === 'email' || hasErrors('newUserData.email')"
                    v-model.trim="newUserData.email"
                    :has-errors="hasErrors('newUserData.email')"
                    :error="getError('newUserData.email')"
                    :label="$t('pages.userNavigation.myAccount.labels.email')"
                    class="my-account__text-field"
                />

                <SelectBox
                    v-if="whitelistedField === 'locale' || hasErrors('newUserData.locale')"
                    v-model="newUserData.locale"
                    class="span-12"
                    :label="$t('pages.user.userForm.placeholder.locale')"
                    :options="localeOptions"
                    :option-value-renderer="option => option.locale"
                    :option-label-renderer="option => option.displayName"
                />

                <PhoneFieldset
                    v-if="
                        whitelistedField === 'mobile' ||
                        hasErrors('newUserData.mobile.countryCode') ||
                        hasErrors('newUserData.mobile.number')
                    "
                    v-model="newUserData.mobile"
                    :label="$t('pages.user.userForm.placeholder.mobileNumber')"
                    :prefix-error="getError('newUserData.mobile.countryCode')"
                    :error="getError('newUserData.mobile.number')"
                    class="my-account__text-field"
                    @blur="partialValidation('newUserData.mobile.number')"
                />
            </Tile>

            <ButtonGroup>
                <Button :disabled="myDataPending" type="submit" primary @click="submitUserData">
                    {{
                        $t('pages.userNavigation.myAccount.buttonLabel', {
                            value: $t(`pages.userNavigation.myAccount.labels.${whitelistedField}`),
                        })
                    }}
                </Button>
            </ButtonGroup>
        </Flyout>

        <LanguageSelectFlyout
            v-if="newUserData"
            :active="!myDataPending && flyoutStates.locale"
            :value="newUserData.locale"
            @select="setUserLocale($event)"
            @closed="flyoutStates.locale = false"
        />
    </Flyout>
</template>

<script>
import { ref, watch } from 'vue';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _throttle from 'lodash/throttle';

import Authenticator from '@/services/Api/Authenticator';
import Toaster from '@/services/Toaster';
import UserApi from '@/services/Api/UserV2';
import validate from '@/services/validation/mixin';
import { isRequired, isEmail, isPhoneNumber, isUsername, minLength, maxLength } from '@/services/validation/rules';
import { mapGetters } from 'vuex';

import AvatarUpload from '@/components/Form/AvatarUpload';
import Button from '@/components/Button/Button.vue';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Flyout from '@/components/Layout/Flyout';
import LanguageSelectFlyout from '@/components/LanguageSelectFlyout';
import PhoneFieldset from '@/components/Form/PhoneFieldset';
import TextField from '@/components/Form/TextField.v2';
import Tile from '@/components/Layout/Tile';
import Words from '@/components/Typography/Words';
import SelectBox from '@/components/Form/SelectBox.v3';

import { getAllLanguagesInLocale, getDisplayNameOfLanguage } from '@/i18n/languages';

import LockIcon from '@/assets/icons/micro/lock.svg';
import { SfButton, SfEditIcon, SfSysArrowRightIcon } from '@schuettflix/vue-components';
import { useAnalyticsService } from '@schuettflix/analytics-vue';

const THROTTLE_DELAY = 5000;

export default {
    name: 'MyAccount',
    components: {
        AvatarUpload,
        Button,
        ButtonGroup,
        Flyout,
        LanguageSelectFlyout,
        LockIcon,
        PhoneFieldset,
        SelectBox,
        TextField,
        Tile,
        Words,
        SfEditIcon,
        SfButton,
        SfSysArrowRightIcon,
    },
    mixins: [validate],
    props: {
        myAccountFlyoutActive: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const analyticsService = useAnalyticsService();
        const whitelistedField = ref(null);

        const whitelistedFieldToScreenName = field => {
            switch (field) {
                case 'firstName': {
                    return 'useraccount-settings-firstname-edit';
                }
                case 'lastName': {
                    return 'useraccount-settings-lastname-edit';
                }
                case 'username': {
                    return 'useraccount-settings-username-edit';
                }
                case 'email': {
                    return 'useraccount-settings-email-edit';
                }
                case 'mobile': {
                    return 'useraccount-settings-phonenumber-edit';
                }
                default: {
                    return undefined;
                }
            }
        };

        watch(
            () => whitelistedField.value,
            (newValue, oldValue) => {
                if (oldValue) {
                    const screenName = whitelistedFieldToScreenName(oldValue);

                    if (screenName) {
                        analyticsService.leaveScreen(screenName);
                    }
                }

                if (newValue) {
                    const screenName = whitelistedFieldToScreenName(newValue);

                    if (screenName) {
                        analyticsService.enterScreen(screenName);
                    }
                }
            }
        );

        return {
            whitelistedField,
        };
    },
    data() {
        return {
            flyoutStates: {
                locale: false,
                password: false,
                userData: false,
            },

            userData: {
                image: {},
            },
            newUserData: null,
            myPasswordPending: false,
            myDataPending: false,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        };
    },
    computed: {
        ...mapGetters('user', ['profileImage', 'info']),
        ...mapGetters('i18n', {
            currentLocale: 'locale',
        }),

        localeOptions() {
            return getAllLanguagesInLocale(this.newUserData.locale);
        },

        languageName() {
            return getDisplayNameOfLanguage(this.currentLocale, this.currentLocale);
        },

        validationRules() {
            let rules = {};

            if (this.flyoutStates.password) {
                rules = {
                    newPassword: [
                        isRequired('pages.userNavigation.myPassword.isRequired'),
                        minLength(8),
                        maxLength(999),
                    ],
                    confirmPassword: [this.passwordsMatching()],
                };
            }

            if (this.flyoutStates.userData) {
                rules = {
                    'newUserData.firstName': [isRequired('validation.isRequired'), maxLength(72)],
                    'newUserData.lastName': [isRequired('validation.isRequired'), maxLength(72)],
                    'newUserData.username': [
                        minLength(5),
                        maxLength(40),
                        isUsername(),
                        isRequired('validation.isRequired'),
                    ],
                    'newUserData.email': [isEmail()],
                    'newUserData.mobile.countryCode': [isRequired()],
                    'newUserData.mobile.number': [
                        isRequired('validation.phoneNumberIsRequired'),
                        isPhoneNumber(),
                        minLength(3),
                        maxLength(30),
                    ],
                };
            }

            return rules;
        },

        passwordsValid() {
            return (
                this.oldPassword &&
                this.newPassword &&
                this.confirmPassword &&
                this.passwordsMatching(this.confirmPassword)
            );
        },
    },
    mounted() {
        this.updateData();
    },
    methods: {
        async updateData() {
            try {
                await this.$store.dispatch('user/updateUserInfo');
                await this.$store.dispatch('user/updateUserLinks');
            } catch (err) {
                this.$logger().error(err);
                Toaster.error(this.$t(err));
            } finally {
                this.setUserData();
                this.closeFormFlyout('userData');
            }
        },

        setUserData() {
            if (!this.info || !this.info.user) return;

            this.userData.id = this.info.user.id;
            this.userData.firstName = this.info.user.firstName;
            this.userData.lastName = this.info.user.lastName;
            this.userData.username = this.info.user.username;
            this.userData.email = this.info.user.email;
            this.userData.mobile = this.info.user.mobile;
            this.userData.locale = this.currentLocale;
            this.userData.image = this.profileImage;

            this.newUserData = _cloneDeep(this.userData);
            this.clearValidation();
        },

        setUserLocale(locale) {
            this.newUserData.locale = locale;
            this.submitUserData();
            // optimistic update so that the user sees the new locale immediately
            this.$store.dispatch('i18n/changeLocale', { locale });
            this.closeFormFlyout('locale');
        },

        openFormFlyout(type, field = null) {
            this.flyoutStates[type] = true;

            if (field) {
                this.whitelistedField = field;
            }
        },

        closeFormFlyout(type) {
            this.setUserData();
            this.flyoutStates[type] = false;
            this.whitelistedField = null;
            this.myDataPending = false;
            this.myPasswordPending = false;
        },

        closeMyAccountFlyout() {
            this.$eventHub.$emit('myAccountToggled', false);
        },

        passwordsMatching() {
            return v => {
                return this.newPassword === v || this.$t('validation.passwordsNotMatching');
            };
        },

        handlePasswordChange: _throttle(
            async function () {
                if (!this.isValid()) {
                    return;
                }

                this.myPasswordPending = true;

                try {
                    await Authenticator.changePassword(this.oldPassword, this.newPassword);
                    this.closeFormFlyout('password');
                } catch (err) {
                    this.$logger().error(err);
                    this.parseServerValidation(_get(err, 'response.data.errors', {}), {
                        oldPassword: 'password',
                        newPassword: 'newPassword',
                    });
                } finally {
                    this.oldPassword = '';
                    this.newPassword = '';
                    this.confirmPassword = '';
                    this.myPasswordPending = false;
                }
            },
            THROTTLE_DELAY,
            {
                trailing: false,
            }
        ),

        submitUserData: _throttle(
            async function () {
                if (!this.isValid()) {
                    return;
                }

                this.myDataPending = true;

                try {
                    const { id: userId, ...data } = this.newUserData;
                    data.isActive = this.info.user.isActive;
                    data.permissions = this.info.user.permissions;

                    await UserApi.saveUserData(userId, data);
                    this.updateData();
                } catch (err) {
                    this.$logger().error(err);
                    this.parseServerValidation(_get(err, 'response.data.errors', {}), {
                        'newUserData.firstName': 'firstName',
                        'newUserData.lastName': 'lastName',
                        'newUserData.username': 'username',
                        'newUserData.email': 'email',
                        'newUserData.mobile.countryCode': 'mobile.countryCode',
                        'newUserData.mobile.number': 'mobile.number',
                        'newUserData.locale': 'locale',
                    });
                } finally {
                    this.myDataPending = false;
                }
            },
            THROTTLE_DELAY,
            {
                trailing: false,
            }
        ),
    },
};
</script>

<style lang="scss">
.my-account__text-field + .my-account__text-field {
    margin-top: 10px;
}

.my-account__image-upload {
    text-align: center;
}

.my-account__button-tile {
    .button:hover {
        background-color: $color-white;
    }
}

.my-account__password-input {
    margin-top: 30px;
}

.my-account__option {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.my-account__options-text {
    flex-grow: 1;
}
</style>

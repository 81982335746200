<template>
    <LayoutPage class="credit-note-list">
        <template #pageTitle>
            {{
                $t(
                    selectCreditNoteDocKey({
                        creditNote: `pages.creditNoteDoc.title.creditNote`,
                        selfBilling: `pages.creditNoteDoc.title.selfBilling`,
                    })
                )
            }}
        </template>

        <CreditNoteNavigation />

        <FilterBar :loading="isLoading" show-refresh transparent @refresh="refreshList()">
            <template #filter>
                <FilterBox
                    v-model="filter"
                    :default-filter="defaultFilter"
                    :endpoint="dataEndpoint"
                    :ignore-in-count="['organizationType', 'organization', 'user', 'isAvailableForCreditNote']"
                    :button-label="$t('pages.transportHub.actions.filter')"
                    @save="refreshList()"
                >
                    <template #default="{ filter: filterFromSlot, updateFilter }">
                        <CreditNoteFilterSet
                            :filter-scope="{ filter: filterFromSlot }"
                            :endpoint="dataEndpoint"
                            :whitelisted-fields="[
                                'startCancelled',
                                'endCancelled',
                                'startCompleted',
                                'endCompleted',
                                'startCreated',
                                'endCreated',
                                'minTotalNet',
                                'maxTotalNet',
                                'numberSearch',
                                'organizationSearch',
                                'status',
                                'transportNumberSearch',
                                'type',
                            ]"
                            @updateFilter="updateFilter"
                        />
                    </template>
                </FilterBox>
            </template>
            <template #sorting>
                <FilterBox
                    v-model="filter"
                    :default-filter="defaultFilter"
                    :endpoint="dataEndpoint"
                    inline-mode
                    @update="refreshList()"
                >
                    <template #default="filterScope">
                        <SortingSelectBox
                            v-model="filterScope.filter.sortBy"
                            :direction="filterScope.filter.sortDirection"
                            :label="$t('pages.creditNoteDoc.actions.sortBy')"
                            primary
                            light
                            small
                            @direction-update="filterScope.filter.sortDirection = $event"
                        >
                            <option v-for="option in sortOptions" :key="option" :value="option">
                                {{ $t(getSortingTranslationKey(option)) }}
                            </option>
                        </SortingSelectBox>
                    </template>
                </FilterBox>
            </template>
            <template #actions>
                <SfButton v-can:createCreditNote size="sm" @click="showAccountingWizard = true">
                    <template #leading-icon="iconProps">
                        <SfSysPlusIcon v-bind="iconProps" />
                    </template>
                    {{
                        $t(
                            selectCreditNoteDocKey({
                                creditNote: `pages.creditNoteDoc.actions.create.creditNote`,
                                selfBilling: `pages.creditNoteDoc.actions.create.selfBilling`,
                            })
                        )
                    }}
                </SfButton>
            </template>
        </FilterBar>

        <LoadingSpinner v-if="!creditNotes" block dark />

        <transition name="fade">
            <div>
                <GridTable v-if="creditNotes && creditNotes.count > 0" columns="1fr 1fr 1fr 3fr 1fr 1fr">
                    <template #head>
                        <GridTableHead v-stuckable="20">
                            <div>{{ $t('pages.creditNoteDoc.headlines.document') }}</div>
                            <div>{{ $t('pages.creditNoteDoc.headlines.created') }}</div>
                            <div>{{ $t('pages.creditNoteDoc.headlines.transfered') }}</div>
                            <div>{{ $t('pages.creditNoteDoc.headlines.companyName') }}</div>
                            <div>{{ $t('pages.creditNoteDoc.headlines.category') }}</div>
                            <div class="grid-table__cell--right">
                                {{ $t('pages.creditNoteDoc.headlines.totalGross') }}
                            </div>
                        </GridTableHead>
                    </template>

                    <template v-for="creditNoteFacet in creditNoteFacets">
                        <GridTableLabel :key="`${creditNoteFacet.appliedFacetValue}-headline`" v-stuckable="10">
                            {{
                                $t(`pages.creditNoteDoc.statusHeadline.${creditNoteFacet.appliedFacetValue}`, {
                                    count: creditNoteFacet.count,
                                })
                            }}
                        </GridTableLabel>

                        <GridTableRow
                            v-for="creditNote in creditNoteFacet.items"
                            :key="`${creditNote.id}-item`"
                            spaced
                            @click="viewCreditNote(creditNote.id)"
                        >
                            <div class="grid-table__cell">
                                <Words block>{{ creditNote.number }}</Words>
                                <Words block muted small>{{
                                    $t(getTypeKey(creditNote.type, creditNote.organizationType))
                                }}</Words>
                            </div>

                            <div class="grid-table__cell">
                                {{ $d(creditNote.created * 1000, 'short') }}
                            </div>

                            <div class="grid-table__cell">
                                <Words v-if="creditNote.completedTimestamp">
                                    {{ $d(creditNote.completedTimestamp * 1000, 'short') }}
                                </Words>
                                <Words v-else>–</Words>
                            </div>

                            <div class="grid-table__cell">
                                <Words>{{ creditNote.billingAddressName }}</Words>
                            </div>

                            <div class="grid-table__cell">
                                <Words>{{ $t(`pages.creditNoteDoc.category.${creditNote.organizationType}`) }}</Words>
                            </div>

                            <div class="grid-table__cell grid-table__cell--right">
                                <Words>{{ $n(creditNote.totalGross, 'currency') }}</Words>
                            </div>

                            <div class="grid-table__cell--foot">
                                <div class="invoice-list__buttons">
                                    <SfLink v-if="creditNote.document" :href="creditNote.document.url" bold @click.stop>
                                        <template #leading-icon="iconProps">
                                            <SfCancelIcon
                                                v-if="creditNote.type === 'cancellation'"
                                                v-bind="iconProps"
                                            />
                                            <SfCreditNotesIcon v-else v-bind="iconProps" />
                                        </template>
                                        <template v-if="creditNote.type === 'cancellation'">
                                            {{ $t('pages.creditNoteDoc.actions.downloadCancellationPdf') }}
                                        </template>
                                        <template v-else>
                                            {{
                                                $t(
                                                    selectCreditNoteDocKey({
                                                        creditNote: `pages.creditNoteDoc.actions.downloadPdf.creditNote`,
                                                        selfBilling: selectSpecificSelfBillingKeyByOrgType(
                                                            {
                                                                selfBilling: `pages.creditNoteDoc.actions.downloadPdf.selfBilling`,
                                                                invoiceCorrection: `pages.creditNoteDoc.actions.downloadPdf.invoiceCorrection`,
                                                            },
                                                            creditNote.organizationType
                                                        ),
                                                    })
                                                )
                                            }}
                                        </template>
                                    </SfLink>
                                </div>
                            </div>
                        </GridTableRow>
                        <MoreResultsButton
                            v-if="creditNoteFacet.count - creditNoteFacet.items.length > 0"
                            :key="`${creditNoteFacet.appliedFacetValue}-more`"
                            :result="creditNoteFacet"
                            fade-out
                            class="transport-hub__more-results-button"
                        />
                    </template>
                </GridTable>

                <Hint v-if="creditNotes && creditNotes.count === 0" center>
                    {{ $t('pages.creditNoteDoc.noResults') }}
                </Hint>
            </div>
        </transition>

        <template #subpages>
            <Flyout route="credit-note-view" size="medium" no-header @closed="refreshList()" />
            <CreateCreditNote :active.sync="showAccountingWizard" @success="() => refreshList()" />
        </template>
    </LayoutPage>
</template>

<script>
import _intersection from 'lodash/intersection';
import CreditNoteApi from '@/services/Api/CreditNote';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import { ensureJSTimestamp } from '@/services/utils/date';
import { revertLocalizedValue } from '@/services/utils/localization';
import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';

import { useSortingOptions } from './useSortingOptions';
import { useTypes } from './useTypes';

import CreditNoteFilterSet from '@/pages/CreditNote/components/CreditNoteFilterSet';
import CreditNoteNavigation from '@/pages/CreditNote/components/CreditNoteNavigation';
import FilterBar from '@/components/Filter/FilterBar';
import FilterBox from '@/components/Filter/FilterBox';
import Flyout from '@/components/Layout/Flyout';
import GridTable from '@/components/Table/GridTable';
import GridTableHead from '@/components/Table/GridTableHead';
import GridTableLabel from '@/components/Table/GridTableLabel';
import GridTableRow from '@/components/Table/GridTableRow';
import Hint from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import LoadingSpinner from '@/components/LoadingSpinner';
import MoreResultsButton from '@/components/Filter/MoreResultsButton';
import SortingSelectBox from '@/components/Form/SortingSelectBox';
import Words from '@/components/Typography/Words';

import CreateCreditNote from './CreateCreditNote';

import { SfButton, SfLink, SfSysPlusIcon, SfCancelIcon, SfCreditNotesIcon } from '@schuettflix/vue-components';

export default {
    name: 'CreditNoteListPage',
    components: {
        CreateCreditNote,
        CreditNoteFilterSet,
        CreditNoteNavigation,
        FilterBar,
        FilterBox,
        Flyout,
        GridTable,
        GridTableHead,
        GridTableLabel,
        GridTableRow,
        Hint,
        LayoutPage,
        LoadingSpinner,
        MoreResultsButton,
        SortingSelectBox,
        Words,
        SfButton,
        SfLink,
        SfSysPlusIcon,
        SfCancelIcon,
        SfCreditNotesIcon,
    },
    mixins: [persistentFiltersMixin],
    setup() {
        const { getSortingTranslationKey } = useSortingOptions();
        const { getTypeKey, selectSpecificSelfBillingKeyByOrgType } = useTypes();
        const { selectCreditNoteDocKey } = useLegalTerms();
        return {
            getSortingTranslationKey,
            getTypeKey,
            selectSpecificSelfBillingKeyByOrgType,
            selectCreditNoteDocKey,
        };
    },
    data() {
        return {
            showAccountingWizard: false,
            isLoading: false,
            pendingTimeout: null,
            dataEndpoint: CreditNoteApi,
            creditNotes: null,
            filter: this.assembleFilter('credit-notes', {
                page: 1,
                perPage: 25,
                sortBy: 'number',
                sortDirection: 'desc',
            }),
            defaultFilter: {
                page: 1,
                perPage: 25,
                sortBy: 'number',
                sortDirection: 'desc',
            },
        };
    },
    computed: {
        sortOptions() {
            return _intersection(CreditNoteApi.supportedSorts, [
                'number',
                'created',
                'organization',
                'totalGross',
                'status',
            ]);
        },
        creditNoteFacets() {
            return this.creditNotes.items.filter(item => item.count > 0);
        },
    },
    created() {
        this.refreshList(true);
    },
    methods: {
        revertLocalizedValue,
        ensureJSTimestamp,

        reset() {
            this.selectedOrganization = null;
        },

        async refreshList(isInitial = false) {
            this.isLoading = true;

            // persist filter
            this.persistFilter('credit-notes', this.filter, this.defaultFilter);

            // cancel previous request
            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.cancelSource = CreditNoteApi.createCancelTokenSource();

            try {
                const result = await CreditNoteApi.filter(
                    this.filter,
                    'status',
                    ['created', 'booked', 'paid', 'cancelled'],
                    this.cancelSource
                );
                this.creditNotes = result;

                if (isInitial === true) {
                    this.filter = {
                        ...this.filter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }

            this.isLoading = false;
        },

        showSectionHeading(organizationId, currentIndex) {
            if (currentIndex === 0) {
                return true;
            }

            return parseInt(this.transports.items[currentIndex - 1].supplier.id) !== parseInt(organizationId);
        },

        viewCreditNote(creditNoteId) {
            this.$router
                .push({
                    name: this.$root.findRouteName('credit-note-view'),
                    params: { creditNoteId },
                    query: this.$route.query,
                })
                .catch(() => {});
        },
    },
};
</script>

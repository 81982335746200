<template>
    <g id="price-ill__truck">
        <!-- 2-AXES -->
        <template v-if="axes === 2">
            <g>
                <path
                    class="price-illustration--truck-9"
                    d="M137,71.8c10.6-7.3,19.9-7.2,29.2-3.1l.3.2"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-9"
                    d="M164.9,59.5A18.98746,18.98746,0,0,1,200.3,69"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-9"
                    d="M164.2,71.8s7.1-9.2,15.3-10"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-9"
                    d="M203,59.5s16.1-4.1,24.9,11.5"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-9"
                    d="M148.7,57.4s3.5-9,14.3-7"
                    transform="translate(0.02 -0.02)"
                />
                <line class="price-illustration--truck-10" x1="195.02" y1="70.98" x2="206.82" y2="70.98" />
            </g>
            <g>
                <path
                    class="price-illustration--truck-11"
                    d="M288.6,120.6h6.9a.43014.43014,0,0,1,.4.4h0v3.9a.43016.43016,0,0,1-.4.4h-7.8v-3.7C287.8,120.9,288.1,120.6,288.6,120.6Z"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-11"
                    d="M293.1,102.2v-19a1.68548,1.68548,0,0,0-1.7-1.7H259a1.8392,1.8392,0,0,0-1.7,1.2l-.7,2.1a6.1627,6.1627,0,0,0,0,3.3c.8,2.4,2.8,6.7,7.1,8.4l26.7,4.4a4.62334,4.62334,0,0,1,3.9,4.6v15.2"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-11"
                    d="M248.3,67.3h-2.5a1.47269,1.47269,0,0,0-1.5,1.5h0v63.7"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-11"
                    d="M292.8,82.1a31.22436,31.22436,0,0,0-2.3-8.6,4.5293,4.5293,0,0,0-3-2.4A103.89868,103.89868,0,0,0,264,67.9l-15.8-.6"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-11"
                    d="M271.3,68.3l-14-6.3a4.41746,4.41746,0,0,0-3.7-.5,4.51445,4.51445,0,0,0-2.9,3.1l-.8,2.7,17.2.7"
                    transform="translate(0.02 -0.02)"
                />
                <line class="price-illustration--truck-11" x1="266.91999" y1="82.08" x2="266.91999" y2="96.38" />
                <polyline
                    class="price-illustration--truck-11"
                    points="295.12 125.08 295.12 128.78 291.32 130.48 286.52 132.48 273.92 132.48"
                />
                <circle class="price-illustration--truck-11" cx="264.91999" cy="128.78" r="9" />
                <circle class="price-illustration--truck-11" cx="165.91999" cy="128.78" r="9" />
                <line class="price-illustration--truck-11" x1="194.72" y1="118.98" x2="192.82" y2="118.98" />
                <line class="price-illustration--truck-11" x1="182.62001" y1="118.98" x2="178.12001" y2="118.98" />
                <polyline class="price-illustration--truck-11" points="151.32 131.58 151.32 118.38 158.32 118.38" />
                <line class="price-illustration--truck-11" x1="294.12001" y1="106.88" x2="289.72001" y2="106.08" />
                <line class="price-illustration--truck-11" x1="285.41999" y1="78.18" x2="285.41999" y2="86.58" />
                <line class="price-illustration--truck-9" x1="299.52" y1="112.98" x2="270.52" y2="112.98" />
                <line class="price-illustration--truck-9" x1="266.12001" y1="112.98" x2="265.72001" y2="112.98" />
                <polyline
                    class="price-illustration--truck-11"
                    points="145.52 118.38 142.32 118.38 132.72 77.38 230.42 77.38"
                />
                <line class="price-illustration--truck-11" x1="238.91999" y1="61.38" x2="238.91999" y2="77.98" />
                <line class="price-illustration--truck-11" x1="256.12001" y1="132.48" x2="251.91999" y2="132.48" />
                <line class="price-illustration--truck-11" x1="173.22" y1="89.98" x2="131.41999" y2="89.98" />
                <polyline
                    class="price-illustration--truck-11"
                    points="200.72 119.08 238.92 119.08 238.92 97.98 238.92 77.98"
                />
                <line class="price-illustration--truck-11" x1="236.32" y1="130.28" x2="180.32" y2="130.28" />
            </g>
        </template>
        <!-- 2-AXES -->

        <!-- 3-AXES -->
        <template v-if="axes === 3">
            <g>
                <path
                    class="price-illustration--truck-9"
                    d="M136.9,68.9h0a31.545,31.545,0,0,1,35.3-.2l.3.2"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-9"
                    d="M170.9,59.5A18.98746,18.98746,0,0,1,206.3,69"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-9"
                    d="M180.2,71.8s-2.9-9.2,5.3-10"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-9"
                    d="M209,59.5s16.1-4.1,24.9,11.5"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-9"
                    d="M154.7,57.4s3.5-9,14.3-7"
                    transform="translate(0.02 -0.02)"
                />
                <line class="price-illustration--truck-10" x1="201.02" y1="70.98" x2="212.82" y2="70.98" />
            </g>
            <g>
                <path
                    class="price-illustration--truck-11"
                    d="M294.6,120.6h6.9a.43014.43014,0,0,1,.4.4h0v3.9a.43016.43016,0,0,1-.4.4h-7.8v-3.7C293.8,120.9,294.1,120.6,294.6,120.6Z"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-11"
                    d="M299.1,102.2v-19a1.68548,1.68548,0,0,0-1.7-1.7H265a1.8392,1.8392,0,0,0-1.7,1.2l-.7,2.1a6.1627,6.1627,0,0,0,0,3.3c.8,2.4,2.8,6.7,7.1,8.4l26.7,4.4a4.62334,4.62334,0,0,1,3.9,4.6v15.2"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-11"
                    d="M254.3,67.3h-2.5a1.47269,1.47269,0,0,0-1.5,1.5h0v63.7"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-11"
                    d="M298.8,82.1a31.22436,31.22436,0,0,0-2.3-8.6,4.5293,4.5293,0,0,0-3-2.4A103.89868,103.89868,0,0,0,270,67.9l-15.8-.6"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-11"
                    d="M277.3,68.3l-14-6.3a4.41747,4.41747,0,0,0-3.7-.5,4.51447,4.51447,0,0,0-2.9,3.1l-.8,2.7,17.2.7"
                    transform="translate(0.02 -0.02)"
                />
                <line class="price-illustration--truck-11" x1="272.91999" y1="82.08" x2="272.91999" y2="96.38" />
                <polyline
                    class="price-illustration--truck-11"
                    points="301.12 125.08 301.12 128.78 297.32 130.48 292.52 132.48 279.92 132.48"
                />
                <line class="price-illustration--truck-11" x1="244.91999" y1="130.28" x2="190.32" y2="130.28" />
                <circle class="price-illustration--truck-11" cx="270.91999" cy="128.78" r="9" />
                <circle class="price-illustration--truck-11" cx="158.91999" cy="128.78" r="9" />
                <circle class="price-illustration--truck-11" cx="181.41999" cy="128.78" r="9" />
                <line class="price-illustration--truck-11" x1="194.72" y1="118.98" x2="192.82" y2="118.98" />
                <line class="price-illustration--truck-11" x1="172.62001" y1="118.98" x2="168.12001" y2="118.98" />
                <line class="price-illustration--truck-11" x1="197.12001" y1="130.28" x2="190.41999" y2="130.28" />
                <polyline class="price-illustration--truck-11" points="144.32 131.58 144.32 118.38 151.32 118.38" />
                <line class="price-illustration--truck-11" x1="300.12001" y1="106.88" x2="295.72001" y2="106.08" />
                <line class="price-illustration--truck-11" x1="291.41999" y1="78.18" x2="291.41999" y2="86.58" />
                <polyline
                    class="price-illustration--truck-11"
                    points="200.62 119.08 244.92 119.08 244.92 97.98 244.92 77.98"
                />
                <line class="price-illustration--truck-9" x1="305.52" y1="112.98" x2="276.52" y2="112.98" />
                <line class="price-illustration--truck-9" x1="272.12001" y1="112.98" x2="271.72001" y2="112.98" />
                <polyline class="price-illustration--truck-11" points="236.42 77.38 132.72 77.38 140.72 112.18" />
                <line class="price-illustration--truck-11" x1="244.91999" y1="61.38" x2="244.91999" y2="77.98" />
                <line class="price-illustration--truck-11" x1="262.12001" y1="132.48" x2="257.91999" y2="132.48" />
                <line class="price-illustration--truck-11" x1="183.22" y1="89.98" x2="131.41999" y2="89.98" />
            </g>
        </template>
        <!-- 3-AXES -->

        <!-- 4-AXES -->
        <template v-if="axes === 4">
            <path
                class="price-illustration--truck-5"
                d="M308.6,120.6h6.9a.43014.43014,0,0,1,.4.4v3.8a.43016.43016,0,0,1-.4.4h-7.8v-3.7q.15-.9.9-.9Z"
                transform="translate(0.02 -0.02)"
            />
            <path
                class="price-illustration--truck-5"
                d="M313.1,102.2v-19a1.68548,1.68548,0,0,0-1.7-1.7H279a1.8392,1.8392,0,0,0-1.7,1.2l-.7,2.1a6.1627,6.1627,0,0,0,0,3.3c.8,2.4,2.8,6.7,7.1,8.4l26.7,4.4a4.62334,4.62334,0,0,1,3.9,4.6v15.2"
                transform="translate(0.02 -0.02)"
            />
            <path
                class="price-illustration--truck-5"
                d="M268.3,67.3h-2.5a1.47268,1.47268,0,0,0-1.5,1.5h0v63.7"
                transform="translate(0.02 -0.02)"
            />
            <path
                class="price-illustration--truck-5"
                d="M312.8,82.1a31.22436,31.22436,0,0,0-2.3-8.6,4.5293,4.5293,0,0,0-3-2.4A103.89868,103.89868,0,0,0,284,67.9l-15.8-.6"
                transform="translate(0.02 -0.02)"
            />
            <path
                class="price-illustration--truck-5"
                d="M291.2,68.3l-14-6.3a4.41747,4.41747,0,0,0-3.7-.5,4.51445,4.51445,0,0,0-2.9,3.1l-.8,2.7L287,68"
                transform="translate(0.02 -0.02)"
            />
            <line class="price-illustration--truck-5" x1="286.91999" y1="82.08" x2="286.91999" y2="96.38" />
            <polyline
                class="price-illustration--truck-5"
                points="315.12 125.08 315.12 128.78 311.32 130.48 306.52 132.48 293.92 132.48"
            />
            <line class="price-illustration--truck-5" x1="221.91999" y1="130.28" x2="190.32" y2="130.28" />
            <circle class="price-illustration--truck-5" cx="284.91999" cy="128.78" r="9" />
            <circle class="price-illustration--truck-5" cx="158.91999" cy="128.78" r="9" />
            <circle class="price-illustration--truck-5" cx="181.41999" cy="128.78" r="9" />
            <circle class="price-illustration--truck-5" cx="237.12001" cy="128.78" r="9" />
            <line class="price-illustration--truck-5" x1="194.72" y1="118.98" x2="192.91999" y2="118.98" />
            <line class="price-illustration--truck-5" x1="172.62001" y1="118.98" x2="168.12001" y2="118.98" />
            <line class="price-illustration--truck-5" x1="197.12001" y1="130.28" x2="190.41999" y2="130.28" />
            <polyline class="price-illustration--truck-5" points="144.32 131.58 144.32 118.38 151.32 118.38" />
            <line class="price-illustration--truck-5" x1="314.12001" y1="106.88" x2="309.72001" y2="106.08" />
            <line class="price-illustration--truck-5" x1="305.41999" y1="78.18" x2="305.41999" y2="86.58" />
            <polyline
                class="price-illustration--truck-5"
                points="248.62 119.08 258.92 119.08 258.92 97.98 258.92 77.98"
            />
            <line class="price-illustration--truck-5" x1="228.22" y1="118.98" x2="202.91999" y2="118.98" />
            <line class="price-illustration--truck-5" x1="258.91999" y1="130.28" x2="246.12001" y2="130.28" />
            <line class="price-illustration--truck-3" x1="319.52" y1="112.98" x2="290.52" y2="112.98" />
            <line class="price-illustration--truck-3" x1="286.12001" y1="112.98" x2="285.72001" y2="112.98" />
            <polyline class="price-illustration--truck-5" points="250.42 77.38 132.72 77.38 140.72 112.18" />
            <line class="price-illustration--truck-5" x1="258.91999" y1="61.38" x2="258.91999" y2="77.98" />
            <line class="price-illustration--truck-5" x1="276.12001" y1="132.48" x2="271.91999" y2="132.48" />
            <line class="price-illustration--truck-5" x1="183.22" y1="89.98" x2="131.41999" y2="89.98" />
            <line class="price-illustration--truck-5" x1="202.91999" y1="118.98" x2="198.62001" y2="118.98" />
            <g id="Haufen">
                <path
                    class="price-illustration--truck-3"
                    d="M150.9,68.9h0a31.36483,31.36483,0,0,1,35.3-.2l.3.2"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-3"
                    d="M184.9,59.5A19.001,19.001,0,0,1,220.3,69"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-3"
                    d="M184.2,71.8s7.1-9.2,15.3-10"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-3"
                    d="M223,59.5s16.1-4.1,24.9,11.5"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-3"
                    d="M168.7,57.4s3.5-9,14.3-7"
                    transform="translate(0.02 -0.02)"
                />
                <line class="price-illustration--truck-8" x1="215.12001" y1="70.98" x2="226.82" y2="70.98" />
            </g>
        </template>
        <!-- 4-AXES -->

        <!-- 5-AXES -->
        <template v-if="axes === 5">
            <g>
                <path
                    class="price-illustration--truck-9"
                    d="M150.9,68.9h0a31.545,31.545,0,0,1,35.3-.2l.3.2"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-9"
                    d="M184.9,59.5A18.98746,18.98746,0,0,1,220.3,69"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-9"
                    d="M184.2,71.8s7.1-9.2,15.3-10"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-9"
                    d="M223,59.5s16.1-4.1,24.9,11.5"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-9"
                    d="M168.7,57.4s3.5-9,14.3-7"
                    transform="translate(0.02 -0.02)"
                />
                <line class="price-illustration--truck-10" x1="215.02" y1="70.98" x2="226.82" y2="70.98" />
            </g>
            <g>
                <path
                    class="price-illustration--truck-11"
                    d="M318.6,120.6h6.9a.43014.43014,0,0,1,.4.4h0v3.9a.43016.43016,0,0,1-.4.4h-7.8v-3.7C317.8,120.9,318.1,120.6,318.6,120.6Z"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-11"
                    d="M323.1,102.2v-19a1.68548,1.68548,0,0,0-1.7-1.7H289a1.8392,1.8392,0,0,0-1.7,1.2l-.7,2.1a6.1627,6.1627,0,0,0,0,3.3c.8,2.4,2.8,6.7,7.1,8.4l26.7,4.4a4.62334,4.62334,0,0,1,3.9,4.6v15.2"
                    transform="translate(0.02 -0.02)"
                />
                <line class="price-illustration--truck-11" x1="236.12001" y1="119.08" x2="208.62001" y2="119.08" />
                <path
                    class="price-illustration--truck-11"
                    d="M278.3,67.3h-2.5a1.47268,1.47268,0,0,0-1.5,1.5h0v63.7"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-11"
                    d="M322.8,82.1a31.22436,31.22436,0,0,0-2.3-8.6,4.5293,4.5293,0,0,0-3-2.4A103.89868,103.89868,0,0,0,294,67.9l-15.8-.6"
                    transform="translate(0.02 -0.02)"
                />
                <path
                    class="price-illustration--truck-11"
                    d="M301.3,68.3l-14-6.3a4.41747,4.41747,0,0,0-3.7-.5,4.51447,4.51447,0,0,0-2.9,3.1l-.8,2.7,17.2.7"
                    transform="translate(0.02 -0.02)"
                />
                <line class="price-illustration--truck-11" x1="296.91999" y1="82.08" x2="296.91999" y2="96.38" />
                <polyline
                    class="price-illustration--truck-11"
                    points="325.12 125.08 325.12 128.78 321.32 130.48 316.52 132.48 303.92 132.48"
                />
                <line class="price-illustration--truck-11" x1="209.32" y1="130.28" x2="190.32" y2="130.28" />
                <circle class="price-illustration--truck-11" cx="294.91999" cy="128.78" r="9" />
                <circle class="price-illustration--truck-11" cx="158.91999" cy="128.78" r="9" />
                <circle class="price-illustration--truck-11" cx="181.41999" cy="128.78" r="9" />
                <circle class="price-illustration--truck-11" cx="247.12001" cy="128.78" r="9" />
                <circle class="price-illustration--truck-11" cx="224.02" cy="128.78" r="9" />
                <line class="price-illustration--truck-11" x1="204.72" y1="118.98" x2="202.82" y2="118.98" />
                <line class="price-illustration--truck-11" x1="172.62001" y1="118.98" x2="168.12001" y2="118.98" />
                <line class="price-illustration--truck-11" x1="207.12001" y1="130.28" x2="190.41999" y2="130.28" />
                <polyline class="price-illustration--truck-11" points="144.32 131.58 144.32 118.38 151.32 118.38" />
                <line class="price-illustration--truck-11" x1="324.12001" y1="106.88" x2="319.72001" y2="106.08" />
                <line class="price-illustration--truck-11" x1="315.41999" y1="78.18" x2="315.41999" y2="86.58" />
                <polyline
                    class="price-illustration--truck-11"
                    points="258.62 119.08 268.92 119.08 268.92 97.98 268.92 77.98"
                />
                <line class="price-illustration--truck-11" x1="268.91999" y1="130.28" x2="256.12001" y2="130.28" />
                <line class="price-illustration--truck-9" x1="329.52" y1="112.98" x2="300.52" y2="112.98" />
                <line class="price-illustration--truck-9" x1="296.12001" y1="112.98" x2="295.72001" y2="112.98" />
                <polyline class="price-illustration--truck-11" points="260.42 77.38 132.72 77.38 140.72 112.18" />
                <line class="price-illustration--truck-11" x1="268.91999" y1="61.38" x2="268.91999" y2="77.98" />
                <line class="price-illustration--truck-11" x1="286.12001" y1="132.48" x2="281.91999" y2="132.48" />
                <line class="price-illustration--truck-11" x1="183.22" y1="89.98" x2="131.41999" y2="89.98" />
            </g>
        </template>
        <!-- 5-AXES -->
    </g>
</template>

<script>
export default {
    name: 'PriceIllustrationTruck',
    props: {
        axes: {
            type: Number,
            default: 2,
            validator: v => [2, 3, 4, 5].includes(v),
        },
    },
};
</script>

<style lang="scss">
.price-illustration--truck-1,
.price-illustration--truck-2,
.price-illustration--truck-6 {
    fill: #fff;
}

.price-illustration--truck-1,
.price-illustration--truck-10,
.price-illustration--truck-2,
.price-illustration--truck-3,
.price-illustration--truck-4,
.price-illustration--truck-6,
.price-illustration--truck-7,
.price-illustration--truck-8,
.price-illustration--truck-9 {
    stroke: red;
}

.price-illustration--truck-1,
.price-illustration--truck-10,
.price-illustration--truck-11,
.price-illustration--truck-2,
.price-illustration--truck-3,
.price-illustration--truck-4,
.price-illustration--truck-5,
.price-illustration--truck-6,
.price-illustration--truck-7,
.price-illustration--truck-8,
.price-illustration--truck-9 {
    stroke-linecap: round;
    stroke-linejoin: round;
}

.price-illustration--truck-1,
.price-illustration--truck-3 {
    stroke-width: 2.88px;
}

.price-illustration--truck-2 {
    stroke-width: 2.88001px;
}

.price-illustration--truck-10,
.price-illustration--truck-11,
.price-illustration--truck-3,
.price-illustration--truck-4,
.price-illustration--truck-5,
.price-illustration--truck-7,
.price-illustration--truck-8,
.price-illustration--truck-9 {
    fill: none;
}

.price-illustration--truck-4 {
    stroke-width: 2.96px;
}

.price-illustration--truck-11,
.price-illustration--truck-5 {
    stroke: #000;
}

.price-illustration--truck-5 {
    stroke-width: 2.84px;
}

.price-illustration--truck-6,
.price-illustration--truck-7 {
    stroke-width: 2.91px;
}

.price-illustration--truck-8 {
    stroke-width: 3px;
}

.price-illustration--truck-9 {
    stroke-width: 2.8831px;
}

.price-illustration--truck-10 {
    stroke-width: 3.001px;
}

.price-illustration--truck-11 {
    stroke-width: 2.8381px;
}
</style>

<template>
    <LayoutPage
        is-flyout
        :screen-name="screenName"
        class="checkout-quantity-selection"
        data-test="quantity-selection-page"
    >
        <template #flyoutHeader>
            <FlyoutHeader />

            <template v-if="isProductLoaded">
                <RoundedTabNavigation
                    v-model="inputType"
                    full-width-tabs
                    :tabs="inputTypes"
                    class="checkout-quantity-selection__tabs"
                />

                <TabStages :active-stage="inputType" class="checkout-quantity-selection__stages">
                    <TabStage stage="vehicles">
                        <div class="checkout-quantity-selection__inputs container-deprecated">
                            <div class="checkout-quantity-selection__input">
                                <div
                                    :style="{
                                        'font-size': `${numberFontSizeFactor(vehicleQuantityWeight, 'weight')}em`,
                                    }"
                                    class="checkout-quantity-selection__input-value"
                                    data-test="checkout-quantity-input-value"
                                >
                                    {{ vehicleQuantityWeight === 0 ? 0 : $n(vehicleQuantityWeight, 'weight') }}
                                </div>
                                <div class="checkout-quantity-selection__input-suffix">t</div>
                            </div>
                            <div
                                class="checkout-quantity-selection__input-separator checkout-quantity-selection__input-separator--inactive"
                            >
                                =
                            </div>
                            <div class="checkout-quantity-selection__input">
                                <div
                                    :style="{
                                        'font-size': `${numberFontSizeFactor(vehicleQuantityVolume, 'volume')}em`,
                                    }"
                                    class="checkout-quantity-selection__input-value"
                                >
                                    {{ vehicleQuantityVolume === '0' ? 0 : $n(vehicleQuantityVolume, 'volume') }}
                                </div>
                                <div class="checkout-quantity-selection__input-suffix">m<sup>3</sup></div>
                            </div>
                        </div>
                    </TabStage>

                    <TabStage v-if="!isProjectPositionDelivery" stage="amount">
                        <div
                            class="checkout-quantity-selection__inputs checkout-quantity-selection__inputs--amount container-deprecated"
                            @click="switchQtyType"
                        >
                            <div>&nbsp;</div>
                            <div
                                :class="{ 'checkout-quantity-selection__input--active': isTypeWeight }"
                                class="checkout-quantity-selection__input checkout-quantity-selection__input--inactive"
                            >
                                <div
                                    :style="{ 'font-size': `${numberFontSizeFactor(quantityWeight, 'weight')}em` }"
                                    data-test="quantity-selection-display"
                                    class="checkout-quantity-selection__input-value"
                                >
                                    <span v-if="isTypeWeight">{{ quantityWeight | numpadNumber(1) }}</span>
                                    <span v-else>{{ $n(parseFloat(quantityWeight), 'weight') }}</span>
                                </div>
                                <div class="checkout-quantity-selection__input-suffix">t</div>
                            </div>
                            <div class="checkout-quantity-selection__input-separator">=</div>
                            <div
                                :class="{ 'checkout-quantity-selection__input--active': isTypeVolume }"
                                class="checkout-quantity-selection__input checkout-quantity-selection__input--inactive"
                            >
                                <div
                                    :style="{ 'font-size': `${numberFontSizeFactor(quantityVolume, 'volume')}em` }"
                                    class="checkout-quantity-selection__input-value"
                                >
                                    <span v-if="isTypeVolume">
                                        {{ quantityVolume === '0' ? 0 : quantityVolume | numpadNumber(1) }}
                                    </span>
                                    <span v-else>
                                        {{ quantityVolume === '0' ? 0 : $n(parseFloat(quantityVolume), 'volume') }}
                                    </span>
                                </div>
                                <div class="checkout-quantity-selection__input-suffix">m<sup>3</sup></div>
                            </div>
                            <BaseButton orb levitate>
                                <ArrowLeftRightIcon
                                    :class="{ 'icon--rotate-180': isTypeVolume }"
                                    class="checkout-quantity-selection__arrow-icon"
                                />
                            </BaseButton>
                        </div>
                    </TabStage>
                </TabStages>
            </template>
            <LoadingSpinner v-else block dark />
        </template>

        <TabStages v-if="isProductLoaded" :active-stage="inputType" class="checkout-quantity-selection__stages">
            <TabStage stage="vehicles">
                <div class="checkout-quantity-selection__input-errors-block">
                    <Hint v-if="!isLoading && availableVehicleClasses.length === 0">
                        {{ $t('pages.checkout.quantitySelection.emptyAvailableClasses') }}
                    </Hint>
                    <ErrorMessage v-if="vehiclesError" :message="vehiclesError" />
                </div>
                <Alert v-if="isProjectPositionMayQuantityReached" type="info">
                    {{ $t('pages.checkout.quantitySelection.projectPositionMayQuantityHint') }}
                </Alert>
                <div class="checkout-quantity-selection__vehicle-input">
                    <div
                        v-for="(vehicleClass, i) in availableVehicleClasses"
                        :key="i"
                        class="checkout-quantity-selection__vehicle-class container-deprecated"
                    >
                        <div class="checkout-quantity-selection__vehicle-class-weight">
                            <div class="checkout-quantity-selection__vehicle-class-logo">
                                <div class="checkout-quantity-selection__vehicle-class-logo-container mr-6">
                                    <VehicleClassIconSet
                                        :icon="vehicleClass.icon"
                                        class="checkout-quantity-selection__vehicle-class-icon"
                                    />
                                </div>
                                <div class="checkout-quantity-selection__vehicle-class-info-wrapper">
                                    <span class="font-copy-sm-strong">{{ vehicleClass.name }}</span>
                                    <span class="font-copy-sm">
                                        {{ $t('pages.checkout.quantitySelection.payload') }}
                                        {{ vehicleClass.payload | tons }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="checkout-quantity-selection__vehicle-class-controls">
                            <SfNumberCounter
                                :count="vehicleClass.count"
                                :max="isMaxReached ? vehicleClass.count : Infinity"
                                @change="$event => setVehicleQty($event, vehicleClass)"
                            />
                        </div>
                    </div>
                </div>
            </TabStage>

            <TabStage v-if="!isProjectPositionDelivery" stage="amount">
                <div class="checkout-quantity-selection__input-errors">
                    <ErrorMessage v-if="amountError" :message="amountError" />
                </div>
                <div class="checkout-quantity-selection__transports container-deprecated">
                    <span class="checkout-quantity-selection__transports-title font-copy-sm-strong">
                        {{ $t('pages.checkout.quantitySelection.transportsTitle') }}
                    </span>
                    <VehicleTrack
                        :transports="transports"
                        class="checkout-quantity-selection__transports-track"
                        small
                    />
                </div>

                <NumpadInput
                    :value="quantity"
                    :precision="1"
                    data-test="quantity-number-pad"
                    :disabled-keys="quantityWeight * 1000 >= maxQty || vehicleCount > maxTransportsCount"
                    @input="handleQuantityUpdate"
                />
            </TabStage>
        </TabStages>

        <template #sticky>
            <SlideUp :active="showSubmitButton">
                <ButtonGroup>
                    <BaseButton
                        :disabled="hasInvalidValues || isLoading"
                        primary
                        type="button"
                        data-test="quantity-selection-confirm-button"
                        class="checkout-quantity-selection__process-button"
                        @click="handleSubmission"
                    >
                        {{ $t('pages.checkout.quantitySelection.submitButton') }}
                        <template #right>
                            <ArrowRightIcon class="icon--inline" />
                        </template>
                    </BaseButton>
                </ButtonGroup>
            </SlideUp>
        </template>
    </LayoutPage>
</template>

<script>
import _debounce from 'lodash/debounce';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import _cloneDeep from 'lodash/cloneDeep';
import { convert_kg_to_cbm, convert_cbm_to_kg } from '@/services/utils/units';
import { mapFilters } from '@/plugins/filters';
import { mapState } from 'vuex';
import { revertLocalizedValue } from '@/services/utils/localization';
import GlobalVariables from '@/services/GlobalVariables';
import ShippingMethodApi from '@/services/Api/ShippingMethod';
import Toaster from '@/services/Toaster';
import TransportOptimization from '@/services/TransportOptimization';
import VehicleClassApi from '@/services/Api/VehicleClass';

import Alert from '@/components/Alert';
import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FlyoutHeader from './components/Header';
import Hint from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import NumpadInput from '@/components/Form/NumpadInput';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation';
import SlideUp from '@/components/Animation/SlideUp';
import TabStage from '@/components/Tab/TabStage';
import TabStages from '@/components/Tab/TabStages';
import VehicleClassIconSet from '@/components/IconSet/VehicleClassIconSet';
import VehicleTrack from '@/components/Transport/VehicleTrack';
import { SfNumberCounter } from '@schuettflix/vue-components';

import ArrowLeftRightIcon from '@/assets/icons/regular/arrow-left-right.svg';
import ArrowRightIcon from '@/assets/icons/micro/arrow.svg';
import { ORDER_TYPE_CLIENT, ORDER_TYPE_CUSTOM, ORDER_TYPE_PROJECT } from '@/constants/orderTypes';
import { trackCheckoutEvent } from './trackCheckoutEvent';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { useQuery } from '@tanstack/vue-query';
import { getOrderRestrictions, getProductByAttributes } from '@/pages/Checkout/queries';
import { useOrderScreenName } from './analytics/vue/useOrderScreenName';

const QTY_TYPE_WEIGHT = 'weight';
const QTY_TYPE_VOLUME = 'volume';
const ERROR_MESSAGE_TIMEOUT = 300;
const SIMPLEX_DEBOUNCE = 300;

export default {
    name: 'QuantitySelectionPage',
    components: {
        Alert,
        BaseButton,
        ButtonGroup,
        ErrorMessage,
        FlyoutHeader,
        Hint,
        LayoutPage,
        NumpadInput,
        RoundedTabNavigation,
        SlideUp,
        TabStage,
        TabStages,
        VehicleTrack,
        VehicleClassIconSet,

        LoadingSpinner,

        ArrowLeftRightIcon,
        ArrowRightIcon,

        SfNumberCounter,
    },
    setup() {
        return {
            screenName: useOrderScreenName('quantityselection'),
        };
    },
    data() {
        return {
            inputType: null,
            quantityType: QTY_TYPE_WEIGHT,
            quantityWeight: '0',
            quantityVolume: '0',
            vehicleQuantityVolume: 0.0,
            transports: [],
            customTransports: [],
            availableVehicleClasses: [],
            inputMadeAmount: false,
            inputMadeVehicles: false,
            amountError: null,
            vehiclesError: null,
            isLoading: false,
            customOrderMaximumQty: null,
            maxAvailableAmount: Infinity,
            maxTransportsCount: Infinity,
        };
    },
    computed: {
        ...mapState('basket', {
            constructionSite: 'constructionSite',
            product: 'product',
            isCustom: 'isCustom',
            basketType: 'type',
            projectPosition: 'projectPosition',
        }),
        isProductLoaded() {
            return !!(
                (this.product?.density &&
                    this.product?.maximumQty &&
                    this.product?.minimumQty &&
                    this.maxAvailableAmount !== Infinity) ||
                this.isProjectPositionDelivery
            );
        },
        isMaximumQuantityReached() {
            const amountSelected = this.inputType === 'amount' ? this.quantity : this.vehicleQuantityWeight;
            return amountSelected * 1000 > this.maxAvailableAmount;
        },
        quantity() {
            return this.isTypeWeight ? this.quantityWeight : this.quantityVolume;
        },
        maxQty() {
            if (this.isProjectPositionDelivery) {
                const { qty, deliveredQty, reservedQty } = this.projectPosition;
                return qty - deliveredQty - reservedQty;
            }
            return this.customOrderMaximumQty !== null ? this.customOrderMaximumQty : this.product.maximumQty;
        },
        isTypeWeight() {
            return this.quantityType === QTY_TYPE_WEIGHT;
        },
        isTypeVolume() {
            return this.quantityType === QTY_TYPE_VOLUME;
        },
        hasInvalidValues() {
            const { quantityWeight, product, vehicleQuantityWeight } = this;

            if (this.inputType === 'amount') {
                return (
                    this.vehicleCount > this.maxTransportsCount ||
                    parseFloat(quantityWeight) * 1000 > this.maxQty ||
                    parseFloat(quantityWeight) * 1000 < product.minimumQty ||
                    parseFloat(quantityWeight) === 0
                );
            }

            if (this.inputType === 'vehicles') {
                return (
                    this.vehicleCount > this.maxTransportsCount ||
                    (!this.isProjectPositionMayQuantityReached && vehicleQuantityWeight * 1000 > this.maxQty) ||
                    vehicleQuantityWeight * 1000 < product.minimumQty ||
                    vehicleQuantityWeight === 0
                );
            }

            return true;
        },
        vehicleQuantityWeight() {
            let qty = 0.0;

            this.customTransports.forEach(item => {
                qty += (item.payload / 1000) * item.count;
            });

            return qty;
        },
        vehicleCount() {
            let count = 0;

            if (this.inputType === 'amount') {
                return this.transports.length;
            }

            this.customTransports.forEach(item => {
                count += item.count;
            });

            return count;
        },
        showSubmitButton() {
            if (this.inputType === 'amount') {
                return this.quantityWeight > 0 && !this.amountError;
            }

            if (this.inputType === 'vehicles') {
                return this.vehicleQuantityVolume > 0 && !this.vehiclesError;
            }

            return true;
        },
        isProjectPositionDelivery() {
            return this.basketType === 'project-position-delivery';
        },
        inputTypes() {
            const types = {
                vehicles:
                    this.inputType === 'vehicles'
                        ? this.$t('pages.checkout.quantitySelection.cars')
                        : this.$t('pages.checkout.quantitySelection.carsInactive'),
            };

            if (!this.isProjectPositionDelivery) {
                types.amount =
                    this.inputType === 'amount'
                        ? this.$t('pages.checkout.quantitySelection.weightVolume')
                        : this.$t('pages.checkout.quantitySelection.weightVolumeInactive');
            }

            return types;
        },
        isMaxReached() {
            return this.vehicleQuantityWeight >= this.maxQty / 1000 || this.vehicleCount > this.maxTransportsCount;
        },
        /**
         * Check if the selected contingent for a project position is in range of the contingent
         *
         * It is possible to load more than the contingent as long as it is only one vehicle that increases the
         * total quantity above the contingent. To check this, iterate over all requested vehicle classes,
         * subtract one vehicle at a time and see if the total amount does not exceed the contingent.
         * @return {boolean}
         */
        isProjectPositionMayQuantityReached() {
            if (!this.isProjectPositionDelivery || this.vehicleQuantityWeight * 1000 < this.maxQty) {
                return false;
            }

            const relevantVehicleClasses = this.customTransports.filter(item => {
                return item.count > 0;
            });

            if (!relevantVehicleClasses.length) {
                return false;
            }

            return relevantVehicleClasses.some((item, index) => {
                const relevantVehicleClassesCopy = _cloneDeep(relevantVehicleClasses);
                relevantVehicleClassesCopy[index].count--;

                const qty = relevantVehicleClassesCopy.reduce((carry, relevantVehicleClass) => {
                    return carry + relevantVehicleClass.count * relevantVehicleClass.payload;
                }, 0);

                return qty <= this.maxQty;
            });
        },
    },
    watch: {
        vehicleQuantityWeight() {
            this.vehicleQuantityVolume = convert_kg_to_cbm(
                this.vehicleQuantityWeight * 1000,
                this.product.density,
                1
            ).toString();
        },

        inputType() {
            this.trackAnalyticEvent();
        },

        quantityType() {
            this.trackAnalyticEvent();
        },
    },
    async created() {
        useQuery({
            ...getProductByAttributes(
                this.$store.state.basket.type,
                this.$store.state.basket.constructionSite.constructionProjectLocationId,
                this.$store.state.basket.product.masterProductId,
                this.$store.state.basket.product.attributeRange,
                this.$store.state.basket.product.attributeIds
            ),
            enabled: !this.isProductLoaded,
            placeholderData: null,
            onSuccess: product => {
                const maxAmount = product.productAvailability?.maxAvailableAmount;

                this.maxAvailableAmount =
                    maxAmount === null || maxAmount === undefined
                        ? Infinity
                        : product.productAvailability?.maxAvailableAmount;

                product.productConfiguration.attributeIds = this.$store.state.basket.product.attributeIds;

                this.$store.commit('basket/setProduct', product.productConfiguration);
            },
        });
        useQuery({
            ...getOrderRestrictions(this.$store.state.basket.type),
            placeholderData: null,
            onSuccess: restrictions => {
                this.$store.commit('basket/setProductRestrictions', restrictions);
            },
        });

        if (this.isProjectPositionDelivery) {
            this.inputType = 'vehicles';
        } else {
            this.inputType = this.$store.getters['user/weightPreference'] || 'vehicles';
        }

        this.loadSavedAmountData();

        await this.updateData();
    },
    methods: {
        ...mapFilters(['tons']),
        revertLocalizedValue,
        async updateData() {
            this.isLoading = true;

            try {
                if (this.isProjectPositionDelivery) {
                    await this.setupAvailableVehiclesForProjectPositionDelivery();
                } else {
                    await this.setupAvailableVehiclesForDelivery();
                }

                this.fetchConfigForCustomOrder();
                this.loadSavedVehicleData();
            } catch (err) {
                if (err.code) {
                    Toaster.error(err.message);
                }
            }

            this.isLoading = false;
        },

        async setupAvailableVehiclesForProjectPositionDelivery() {
            const result = await VehicleClassApi.filter({
                zip: this.constructionSite.address.zip,
                country: this.constructionSite.address.country,
                type: 'delivery',
                orderType: ORDER_TYPE_PROJECT,
            });

            this.maxTransportsCount = _get(result, 'items[0].maximumTransportCount', Infinity);

            const vehicleClassMap = new Map(result.items.map(item => [item.id, item]));

            const availableIds = result.items.map(item => item.id);

            // Ensure that the given vehicle classes are still active
            const vehicles = _cloneDeep(this.projectPosition.vehicleClasses)
                .map(vcInfo => vcInfo.vehicleClass)
                .map(vcInfo => {
                    const icon = vehicleClassMap.get(vcInfo.id)?.icon;

                    if (!icon) {
                        return vcInfo;
                    }

                    return {
                        ...vcInfo,
                        icon,
                    };
                })
                .filter(vc => availableIds.includes(vc.id));

            this.availableVehicleClasses = _orderBy(vehicles, ['numAxes', 'payload'], ['desc', 'desc']);
        },

        async setupAvailableVehiclesForDelivery() {
            const result = await VehicleClassApi.filter({
                maximumTrafficability: this.constructionSite.maximumTrafficability,
                zip: this.constructionSite.address.zip,
                country: this.constructionSite.address.country,
                type: 'delivery',
                orderType: this.isCustom ? ORDER_TYPE_CUSTOM : ORDER_TYPE_CLIENT,
            });

            const vehicles = _orderBy(result.items, ['numAxes', 'payload'], ['desc', 'desc']);

            this.maxTransportsCount = _get(vehicles, '[0].maximumTransportCount', Infinity);

            this.availableVehicleClasses = vehicles;
            TransportOptimization.setVehicles(vehicles);

            const { state, zip, country } = this.constructionSite.address;
            const availableShippingMethods = await ShippingMethodApi.getAvailableMethods({
                state,
                zip,
                country,
            });

            if (Object.keys(availableShippingMethods).length > 0) {
                TransportOptimization.setFixedPriceFactor(
                    _orderBy(availableShippingMethods, ['price'], ['desc'])[0].price
                );
            }

            this.updateTransports();
        },

        async fetchConfigForCustomOrder() {
            // fallback for non platform admins
            this.customOrderMaximumQty = await GlobalVariables.get('custom_order_maximum_order_amount', null);
            const loadedValues = this.$store.state.basket.qty;
            if (!loadedValues) return;

            if (this.inputType === 'amount') {
                this.validateAmount(true);
            } else {
                this.validateVehicles(true);
            }
        },

        loadSavedAmountData() {
            const savedTransports = this.$store.state.basket.transports;
            const savedQty = this.$store.state.basket.qty;
            if (savedTransports && savedTransports.inputType === 'amount' && savedQty !== null) {
                this.inputType = savedTransports.inputType;
                this.quantityWeight = (savedQty / 1000).toString();
                this.quantityVolume = convert_kg_to_cbm(this.quantityWeight * 1000, this.product.density, 1).toString();
                this.inputMadeAmount = true;

                if (!this.isCustom) {
                    this.validateAmount(true);
                }
            }
        },
        loadSavedVehicleData() {
            const { transports, inputType } = this.$store.state.basket.transports;
            if (transports && inputType === 'vehicles') {
                this.inputType = inputType;
                transports.forEach(transport => {
                    const vehicle = _find(this.availableVehicleClasses, { id: transport.id });
                    if (vehicle) {
                        this.increaseVehicleQty(vehicle);
                    }
                });
                if (!this.isCustom) {
                    this.validateVehicles(true);
                }
            }
        },

        getValidateMessage(minError = false, maxError = false, maxReached = false, maxTransportsCount = false) {
            let message = null;

            if (maxTransportsCount) {
                message = this.$t('pages.checkout.quantitySelection.maximumTransportCountExceededError', {
                    count: this.maxTransportsCount,
                });
            } else if (minError) {
                message = this.$t('pages.checkout.quantitySelection.minimumQtyExceededError', {
                    qty: this.tons(this.product.minimumQty),
                });
            } else if (maxError && this.isProjectPositionDelivery) {
                message = this.$t('pages.checkout.quantitySelection.contingentExceededError', {
                    qty: this.tons(this.maxQty),
                });
            } else if (maxError) {
                message = this.$t('pages.checkout.quantitySelection.maximumQtyExceededError', {
                    qty: this.tons(this.maxQty),
                });
            } else if (!this.isCustom && this.isMaximumQuantityReached) {
                message = this.$t('pages.checkout.deliveryQuantitySelection.noSellersAvailable', {
                    qty: this.tons(this.maxAvailableAmount),
                });
            } else if (maxReached) {
                message = this.$t('pages.checkout.quantitySelection.maximumQtyReachedError', {
                    qty: this.tons(this.maxQty),
                });
            }

            return message;
        },

        validateAmount(displayError = false) {
            const weight = parseFloat(this.quantityWeight);
            const minError = this.inputMadeAmount && weight < this.product.minimumQty / 1000;
            const maxError = this.inputMadeAmount && weight > this.maxQty / 1000;
            // Max reached should be removed
            const maxReached = this.inputMadeAmount && weight > this.maxQty / 1000;
            const maxTransportsCount = this.vehicleCount > this.maxTransportsCount;

            const message = this.getValidateMessage(minError, maxError, maxReached, maxTransportsCount);

            if (weight === 0) {
                this.amountError = null;
                return;
            }

            if (!message) {
                this.amountError = null;
            }

            if (displayError) {
                this.showAmountError(message);
            }
        },
        showAmountError: _debounce(function (error) {
            this.amountError = error;
        }, ERROR_MESSAGE_TIMEOUT),

        validateVehicles(displayError = false) {
            const minError = this.inputMadeVehicles && this.vehicleQuantityWeight * 1000 < this.product.minimumQty;
            const maxError =
                this.inputMadeVehicles &&
                !this.isProjectPositionMayQuantityReached &&
                this.vehicleQuantityWeight * 1000 > this.maxQty;
            // Max reached should be removed
            const maxReached =
                this.inputMadeVehicles &&
                !this.isProjectPositionDelivery &&
                this.vehicleQuantityWeight * 1000 > this.maxQty;
            const maxTransportsCount = this.vehicleCount > this.maxTransportsCount;

            const message = this.getValidateMessage(minError, maxError, maxReached, maxTransportsCount);

            if (this.vehicleQuantityWeight === 0) return;

            if (!message) {
                this.vehiclesError = null;
            }

            if (displayError) {
                this.showVehiclesError(message);
            }
        },
        showVehiclesError: _debounce(function (error) {
            this.vehiclesError = error;
        }, ERROR_MESSAGE_TIMEOUT),

        switchQtyType() {
            this.quantityType = this.quantityType === QTY_TYPE_WEIGHT ? QTY_TYPE_VOLUME : QTY_TYPE_WEIGHT;
        },
        updateTransports: _debounce(function (validate = false) {
            const { transports } = TransportOptimization.getTransports(this.quantityWeight * 1000);
            this.transports = transports;
            if (validate) {
                this.validateAmount(true);
            }
        }, SIMPLEX_DEBOUNCE),
        handleQuantityUpdate(value) {
            this.inputMadeAmount = true;
            this.validateAmount(false);

            if (this.quantityType === QTY_TYPE_WEIGHT) {
                this.quantityWeight = value;
                this.quantityVolume = convert_kg_to_cbm(this.quantityWeight * 1000, this.product.density, 1).toString();
            } else {
                this.quantityVolume = value;
                this.quantityWeight = (
                    Math.round(convert_cbm_to_kg(this.quantityVolume, this.product.density, 1) / 100) / 10
                ).toString();
            }
            if (this.availableVehicleClasses.length) {
                this.updateTransports(true);
            }
            this.validateAmount(true);
        },
        numberFontSizeFactor(value, type) {
            const str = this.$n(value, type);
            let factor = 1;

            if (str.length > 8) {
                factor += str.length / 20;
            } else if (str.length > 5) {
                factor += str.length / 25;
            } else if (str.length > 3) {
                factor += str.length / 30;
            }

            return parseFloat((1.6 / factor).toFixed(2));
        },
        getVehicleQty(vehicleClass) {
            return vehicleClass.count;
        },
        increaseVehicleQty(vehicleClass) {
            this.inputMadeVehicles = true;
            this.validateVehicles(false);
            if (!vehicleClass.count) {
                this.$set(vehicleClass, 'count', 0);
            }

            if (!this.customTransports.includes(vehicleClass)) {
                this.customTransports.push(vehicleClass);
            }

            vehicleClass.count++;
            this.validateVehicles(true);
        },
        setVehicleQty(count, vehicleClass) {
            this.inputMadeVehicles = true;
            this.validateVehicles(false);

            if (!this.customTransports.includes(vehicleClass) && count !== 0) {
                this.customTransports.push(vehicleClass);
            }

            this.$set(vehicleClass, 'count', count);

            if (vehicleClass.count === 0 && this.customTransports.includes(vehicleClass)) {
                const index = this.customTransports.indexOf(vehicleClass);

                this.customTransports.splice(index, 1);
            }

            this.validateVehicles(true);
        },
        handleSubmission() {
            if (this.hasInvalidValues) {
                return;
            }

            const data = {
                transports: [],
                inputType: this.inputType,
            };
            const qty = this.inputType === 'amount' ? this.quantityWeight * 1000 : this.vehicleQuantityWeight * 1000;

            if (this.inputType === 'amount') {
                data.transports = this.transports;
            } else {
                this.customTransports.forEach(vehicleClass => {
                    for (let i = 0; i < vehicleClass.count; i++) {
                        data.transports.push({
                            id: vehicleClass.id,
                            icon: vehicleClass.icon,
                            actualLoad: vehicleClass.payload,
                            name: vehicleClass.name,
                            numAxes: vehicleClass.numAxes,
                            payload: vehicleClass.payload,
                            loadPercentage: 100,
                        });
                    }
                });
            }

            if (data.transports.length === 0) {
                Toaster.error('No transports prefilled for quantity configuration');
                return;
            }
            this.$store.commit('basket/setTransports', data);
            this.$store.commit('basket/setQty', qty);
            this.$store.commit('user/updateWeightPreference', this.inputType);

            this.$router.push({ name: this.$root.findRouteName(this.$route.meta.next) }).catch(() => {});
        },

        trackAnalyticEvent() {
            trackCheckoutEvent('quantity', 'input', {
                inputType: this.inputType,
                qtyType: this.inputType === 'amount' ? this.quantityType : null,
            });
        },
    },
};
</script>

<style lang="scss">
.checkout-quantity-selection {
    .page__main {
        background-color: $color-white;
    }

    .page__header {
        border-bottom: 0;
    }

    > .scroll-container {
        &::before,
        &::after {
            display: none;
        }
    }
}

.checkout-quantity-selection__headline {
    @media screen and (min-height: 670px) {
        margin-bottom: 3em;
    }
}

.checkout-quantity-selection__inputs {
    background-color: $color-white;
    display: grid;
    grid-template-columns: 1fr 20px 1fr;
    align-items: center;
    font-size: 18px;
    padding: 16px 0px 4px 0px;
    border-bottom: $border-solid-2px;
    height: 98px;
}

.checkout-quantity-selection__inputs--amount {
    grid-template-columns: 28px 1fr 40px 1fr 28px;
}

.checkout-quantity-selection__input {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: baseline;
    opacity: 0.25;
    line-height: 2.6rem;
    overflow: hidden;
}

.checkout-quantity-selection__input--inactive {
    font-size: 12px;
}

.checkout-quantity-selection__input--active {
    opacity: 1;
    font-size: 18px;
}

.checkout-quantity-selection__input-value {
    font-size: 1.6em;
    text-align: center;
}

.checkout-quantity-selection__input-suffix {
    line-height: 1;
    margin-left: 8px;
}

.checkout-quantity-selection__input-separator {
    text-align: center;
    background-color: $color-white;
    border: 1px solid $color-mediumGrey;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkout-quantity-selection__vehicle-class-icon {
    height: 40px;
    width: auto;
    margin-left: auto;
}

.checkout-quantity-selection__input-separator--inactive {
    opacity: 0.25;
}

.checkout-quantity-selection__transports {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 100vw;
    overflow-x: auto;
    overflow-y: hidden;
}

.checkout-quantity-selection__transports-title {
    flex: 1 0;
}

.checkout-quantity-selection__transports-track {
    flex: 1 1 auto;
}

.checkout-quantity-selection__input-errors {
    position: relative;

    > * {
        position: absolute;
        top: -9px;
        left: 0;
        right: 0;
        z-index: 50;
        box-shadow: $boxShadow-bottomShort;
    }
}

.checkout-quantity-selection__input-errors-block {
    > * {
        margin: 0 0 10px 0;
    }
}

.checkout-quantity-selection__vehicle-input {
    margin-top: 10px;
    margin-bottom: 15px;
}

.checkout-quantity-selection__vehicle-class {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 375px) {
    .checkout-quantity-selection__vehicle-class {
        flex-direction: column;
    }
}

.checkout-quantity-selection__vehicle-class-info-wrapper {
    display: flex;
    flex-direction: column;
}

.checkout-quantity-selection__vehicle-class-logo-container {
    width: 75px;
}

.checkout-quantity-selection__vehicle-class-name {
    margin-top: 4px;
    position: absolute;
    bottom: -16px;
}

.checkout-quantity-selection__vehicle-class-weight {
    flex: 1 0 50%;
    position: relative;
}

.checkout-quantity-selection__vehicle-class-controls {
    flex: 1 0 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
}

.checkout-quantity-selection__stages {
    max-width: 100vw;
}

.checkout-quantity-selection__vehicle-class-logo {
    display: flex;
    align-items: center;
}

.checkout-quantity-selection__arrow-icon {
    transition: transform 0.3s ease-out;
}

.checkout-quantity-selection__tabs {
    background-color: $color-lightMediumGrey;
}
</style>

import AbstractResource from './AbstractResource';

class Logbook extends AbstractResource {
    async createPdf(from, to) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/pdf`, {
                params: {
                    startTimestamp: from,
                    endTimestamp: to,
                },
            });

            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    async sendEmail(email, from, to) {
        try {
            const response = await this.apiService.get(`${this.resourcePath}/email`, {
                params: {
                    email,
                    startTimestamp: from,
                    endTimestamp: to,
                },
            });

            return response.data;
        } catch (err) {
            throw this.handleError(err);
        }
    }
}

export default new Logbook('/logbook');

<template>
    <Flyout
        screen-name="useraccount-overview"
        :active="userNavigationFlyoutActive"
        size="small"
        class="user-navigation"
        show-header
        @closed="closeFlyout"
    >
        <template #header>
            <HeaderBar>
                <template #left>
                    <HeaderBarItem button @click="closeFlyout">
                        <SfSysArrowLeftIcon size="xs" />
                    </HeaderBarItem>
                </template>
                <template #right>
                    <HeaderBarItem>
                        <SfLink bold data-test="button-logout" @click="performLogout">
                            {{ $t('pages.userNavigation.logout') }}
                        </SfLink>
                    </HeaderBarItem>
                </template>
            </HeaderBar>
        </template>

        <Tile no-border no-padding-top class="user-navigation__avatar">
            <Avatar :src="profileImage && profileImage.url" size="huge" />
        </Tile>

        <Tile no-border no-padding-top>
            <Words block centered>{{ username }}</Words>
        </Tile>

        <Tile v-if="hasLinkedUsers" no-padding-top>
            <Button arrow-right block @click="openGlobalPage('userLinkListFlyoutToggled')">
                <div slot="left" class="user-navigation__change-user">
                    <ChangeUserIcon class="icon--inline" />
                    <div
                        v-if="linkedUsersNotificationCount > 0"
                        class="user-navigation__change-user-notification-hint"
                    />
                </div>
                {{ $t('pages.userNavigation.actions.changeUser') }}
            </Button>
        </Tile>

        <Tile no-padding>
            <Button primary light stretched align-left @click="openGlobalPage('myAccountToggled')">
                {{ $t('pages.userNavigation.actions.myData') }}
                <ArrowRightIcon slot="right" width="14" height="14" class="icon--inline" />
            </Button>
        </Tile>

        <Tile no-padding>
            <Button primary light stretched align-left @click="$eventHub.$emit('userLinkAdd')">
                {{ $t('pages.userNavigation.actions.addUserLink') }}
                <ArrowRightIcon slot="right" width="14" height="14" class="icon--inline" />
            </Button>
        </Tile>

        <Tile no-padding>
            <Button primary light stretched align-left @click="openGlobalPage('page.notificationManagement')">
                {{ $t('pages.userNavigation.actions.notifications') }}
                <ArrowRightIcon slot="right" width="14" height="14" class="icon--inline" />
            </Button>
        </Tile>

        <Tile no-padding>
            <Button primary light stretched align-left @click="openGlobalPage('page.platformContact')">
                {{ $t('pages.userNavigation.actions.contact') }}
                <ArrowRightIcon slot="right" width="14" height="14" class="icon--inline" />
            </Button>
        </Tile>

        <Tile no-padding>
            <Button
                primary
                light
                stretched
                align-left
                :href="$root.isApp ? null : linkLegalImprintGlobal"
                :target="$root.isApp ? null : '_blank'"
                @click="() => $root.isApp && openInAppBrowser(linkLegalImprintGlobal)"
            >
                {{ $t('pages.userNavigation.actions.imprint') }}
                <ArrowRightIcon slot="right" width="14" height="14" class="icon--inline" />
            </Button>
        </Tile>

        <template v-if="$can('viewTermsOfServiceLink')">
            <Tile v-if="isClient(organizationTypes)" no-padding>
                <Button
                    primary
                    light
                    stretched
                    align-left
                    :href="$root.isApp ? null : linkLegalTermsAndConditionsCustomer"
                    :target="$root.isApp ? null : '_blank'"
                    @click="() => $root.isApp && openInAppBrowser(linkLegalTermsAndConditionsCustomer)"
                >
                    {{ $t(`pages.userNavigation.actions.customer.termsOfService`) }}
                    <template #right>
                        <ArrowRightIcon width="14" height="14" class="icon--inline" />
                    </template>
                </Button>
            </Tile>

            <Tile v-if="isCarrier(organizationTypes)" no-padding>
                <Button
                    primary
                    light
                    stretched
                    align-left
                    :href="$root.isApp ? null : linkLegalTermsAndConditionsCarrier"
                    :target="$root.isApp ? null : '_blank'"
                    @click="() => $root.isApp && openInAppBrowser(linkLegalTermsAndConditionsCarrier)"
                >
                    {{ $t(`pages.userNavigation.actions.carrier.termsOfService`) }}
                    <template #right>
                        <ArrowRightIcon width="14" height="14" class="icon--inline" />
                    </template>
                </Button>
            </Tile>

            <Tile v-if="isSupplier(organizationTypes)" no-padding>
                <Button
                    primary
                    light
                    stretched
                    align-left
                    :href="$root.isApp ? null : linkLegalTermsAndConditionsSeller"
                    :target="$root.isApp ? null : '_blank'"
                    @click="() => $root.isApp && openInAppBrowser(linkLegalTermsAndConditionsSeller)"
                >
                    {{ $t(`pages.userNavigation.actions.seller.termsOfService`) }}
                    <template #right>
                        <ArrowRightIcon width="14" height="14" class="icon--inline" />
                    </template>
                </Button>
            </Tile>
        </template>

        <Tile no-padding>
            <Button
                primary
                light
                stretched
                align-left
                :href="$root.isApp ? null : linkLegalTermsOfUse"
                :target="$root.isApp ? null : '_blank'"
                @click="() => $root.isApp && openInAppBrowser(linkLegalTermsOfUse)"
            >
                {{ $t('components.layout.page.footer.termsOfUse') }}
                <ArrowRightIcon slot="right" width="14" height="14" class="icon--inline" />
            </Button>
        </Tile>

        <Tile no-padding>
            <Button
                primary
                light
                stretched
                align-left
                :href="$root.isApp ? null : linkLegalDataProtectionPolicyGlobal"
                :target="$root.isApp ? null : '_blank'"
                @click="() => $root.isApp && openInAppBrowser(linkLegalDataProtectionPolicyGlobal)"
            >
                {{ $t('pages.userNavigation.actions.privacyPolicy') }}
                <ArrowRightIcon slot="right" width="14" height="14" class="icon--inline" />
            </Button>
        </Tile>

        <Tile no-padding>
            <Button primary light stretched align-left @click="openGlobalPage('page.licenses')">
                {{ $t('pages.userNavigation.actions.licenses') }}
                <ArrowRightIcon slot="right" width="14" height="14" class="icon--inline" />
            </Button>
        </Tile>

        <Words block small muted class="user-navigation__version-info">v{{ appVersion.number }}</Words>
    </Flyout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import pageMixin from '@/plugins/mixins/pageMixin';
import { isClient, isSupplier, isCarrier } from '@/services/utils/organization';
import { useInAppBrowser } from '@/utils/composition-helper';

import Avatar from '@/components/Avatar';
import Button from '@/components/Button/Button';
import Flyout from '@/components/Layout/Flyout';
import HeaderBar from '@/components/Header/HeaderBar';
import HeaderBarItem from '@/components/Header/HeaderBarItem';
import Tile from '@/components/Layout/Tile';
import Words from '@/components/Typography/Words';

import ArrowRightIcon from '@/assets/icons/regular/arrow-stripeless.svg';
import ChangeUserIcon from '@/assets/icons/regular/change-user.svg';
import { SfLink, SfSysArrowLeftIcon } from '@schuettflix/vue-components';

export default {
    name: 'UserNavigation',

    components: {
        Avatar,
        Button,
        Flyout,
        HeaderBar,
        HeaderBarItem,
        Tile,
        Words,
        ArrowRightIcon,
        ChangeUserIcon,
        SfLink,
        SfSysArrowLeftIcon,
    },

    mixins: [pageMixin],

    props: {
        userNavigationFlyoutActive: {
            type: Boolean,
            default: false,
        },
    },

    setup() {
        const { openInAppBrowser } = useInAppBrowser();
        return {
            openInAppBrowser,
        };
    },

    computed: {
        ...mapGetters('user', [
            'info',
            'profileImage',
            'username',
            'hasLinkedUsers',
            'linkedUsersNotificationCount',
            'organizationTypes',
        ]),

        ...mapGetters('platform', [
            'linkLegalDataProtectionPolicyGlobal',
            'linkLegalImprintGlobal',
            'linkLegalTermsAndConditionsCarrier',
            'linkLegalTermsAndConditionsCustomer',
            'linkLegalTermsAndConditionsSeller',
            'linkLegalTermsOfUse',
        ]),

        appVersion() {
            return window.appVersion;
        },
    },

    methods: {
        ...mapActions('user', ['logout']),

        isClient,
        isSupplier,
        isCarrier,

        performLogout() {
            this.closeFlyout();
            this.logout().then(shouldRedirect => {
                if (shouldRedirect) {
                    this.$router
                        .push({
                            name: 'login',
                        })
                        .catch(() => {});
                }
            });
        },

        closeFlyout() {
            this.$eventHub.$emit('userNavigationToggled', false);
        },
    },
};
</script>

<style lang="scss">
.user-navigation {
    background-color: $color-white;
}

.user-navigation .header-bar {
    border-color: $color-white;
}

.user-navigation__avatar {
    text-align: center;
}

.user-navigation__version-info {
    padding: 10px 15px;
    text-align: right;
}

.user-navigation__change-user {
    position: relative;
    display: inline-block;
}

.user-navigation__change-user-notification-hint {
    display: block;
    width: 12px;
    height: 12px;
    background-color: var(--color-red);
    border-radius: 100%;
    position: absolute;
    top: 0;
    right: -6px;
}
</style>

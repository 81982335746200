<template>
    <button
        class="card card--hovered card--focussed font-copy-md-strong flex w-full items-center justify-center p-6"
        :data-test="dataTest"
        @click="$emit('add')"
    >
        <PlusSvg class="mr-2" /> {{ label }}
    </button>
</template>

<script>
import PlusSvg from '@/assets/icons/micro/plus.svg';

export default {
    name: 'AddSectionPlaceholder',
    components: {
        PlusSvg,
    },
    props: {
        label: {
            type: String,
            default: null,
        },
        dataTest: {
            type: String,
            default: 'add-transport-costs',
        },
    },
};
</script>

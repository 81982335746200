<template>
    <div v-if="editorOpen" class="image-upload-editor__editor">
        <div ref="stageParent" class="image-upload-editor__stage-container scroll-container">
            <div :id="`${eid}-stage`" class="image-upload-editor__stage" />

            <div class="image-upload-editor__stage-actions">
                <Button light @click="close">
                    <ArrowIcon class="image-upload-editor__stage-actions-icon" />
                </Button>
                <Button light @click="clear">
                    <DeleteIcon class="image-upload-editor__stage-actions-icon" />
                </Button>
                <Button light @click="placePin">
                    <PinIcon
                        :class="{ 'image-upload-editor__stage-actions-icon--active': pinMode }"
                        class="image-upload-editor__stage-actions-icon"
                    />
                </Button>
                <Button light @click="draw">
                    <PenIcon
                        :class="{ 'image-upload-editor__stage-actions-icon--active': drawMode }"
                        class="image-upload-editor__stage-actions-icon"
                    />
                </Button>
            </div>

            <div v-if="drawMode" class="image-upload-editor__stage-draw-colors">
                <button
                    v-for="color in drawColors"
                    :key="color"
                    :style="`background-color: ${color}`"
                    :class="{
                        'image-upload-editor__stage-draw-color--active': selectedColor === color,
                    }"
                    class="image-upload-editor__stage-draw-color"
                    @click="changeColor(color)"
                />
            </div>
        </div>
        <Button :progress="uploadProgress" primary class="image-upload-editor__save" @click="save">
            {{ $t('pages.checkout.additionalInformation.components.editorSubmit') }}
            <ArrowMicroIcon slot="right" class="icon--inline" />
        </Button>
    </div>
</template>

<script>
import Konva from 'konva';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock';
import { dataURItoBlob } from '@/services/utils';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';

import Button from '@/components/Button/Button';

import ArrowMicroIcon from '@/assets/icons/micro/arrow.svg';
import ArrowIcon from '@/assets/icons/regular/arrow.svg';
import DeleteIcon from '@/assets/icons/regular/garbage.svg';
import PinIcon from '@/assets/icons/regular/pin.svg';
import PenIcon from '@/assets/icons/regular/pen.svg';

export default {
    name: 'ImageUploadEditor',
    components: {
        Button,
        ArrowMicroIcon,
        ArrowIcon,
        DeleteIcon,
        PinIcon,
        PenIcon,
    },
    mixins: [eventHubMixin],
    data() {
        return {
            eid: `el${this._uid}`,
            stageParent: null,
            stage: null,
            imageLayer: null,
            pinLayer: null,
            pinImg: null,
            trPin: null,
            pinPlaced: false,
            pinMode: false,
            drawInit: false,
            drawMode: false,
            drawLayer: null,
            drawCanvas: null,
            drawContext: null,
            drawImage: null,
            editorOpen: false,
            uploadProgress: 0,
            drawColors: ['#ffffff', '#000000', '#ff0000', '#00ff00', '#0000ff'],
            selectedColor: '#ffffff',
            originalImgWidth: null,
            originalImgHeight: null,
            isPaint: false,
            lastPointerPosition: null,
        };
    },
    created() {
        this.subscribe('image-editor-open', file => {
            this.open(file);
        });

        this.subscribe('imageEditorClose', () => {
            this.close();
        });

        this.subscribe('imageEditorUploadProgress', progress => {
            this.uploadProgress = progress;
        });
    },
    methods: {
        clear() {
            if (this.drawInit) {
                this.drawContext.clearRect(0, 0, this.drawCanvas.width, this.drawCanvas.height);
                this.drawContext.beginPath();
                this.stage.draw();
                this.drawInit = false;
                this.drawMode = false;
                this.unbindDrawEvents();
            }
            if (this.pinPlaced) {
                this.pinLayer.removeChildren();
                this.pinPlaced = false;
                this.pinMode = false;
                this.stage.draw();
            }
        },

        open(file) {
            this.editorOpen = true;
            this.$nextTick(() => {
                this.setupEditor();
                this.placeImage(file);
                disableBodyScroll(this.$el.querySelector('.image-upload-editor__stage-container'));
            });
        },

        close() {
            this.editorOpen = false;
            this.clear();
            enableBodyScroll(this.$el.querySelector('.image-upload-editor__stage-container'));
            this.$eventHub.$emit('imageEditorClosed');
        },

        setupEditor() {
            this.stageParent = this.$refs.stageParent;

            Konva.pixelRatio = window.devicePixelRatio;

            this.stage = new Konva.Stage({
                container: `${this.eid}-stage`,
                width: window.innerWidth,
                height: 100,
            });

            this.imageLayer = new Konva.Layer();

            this.pinLayer = new Konva.Layer();
            this.stage.add(this.pinLayer);

            this.drawLayer = new Konva.Layer();
            this.stage.add(this.drawLayer);
        },

        placeImage(file) {
            const fileReader = new FileReader();
            fileReader.onloadend = f => {
                if (f.target.readyState !== FileReader.DONE) {
                    return;
                }

                const imgObj = new Image();

                imgObj.onload = () => {
                    this.originalImgWidth = imgObj.width;
                    this.originalImgHeight = imgObj.height;
                    let height;
                    let width = this.stageParent.offsetWidth;
                    let scale = this.stageParent.offsetWidth / imgObj.width;

                    if (imgObj.height * scale > this.stageParent.offsetHeight) {
                        height = this.stageParent.offsetHeight;
                        scale = this.stageParent.offsetHeight / imgObj.height;
                        width = imgObj.width * scale;
                    } else {
                        height = imgObj.height * scale;
                    }

                    // Resize stage to actual image size
                    this.stage.width(width);
                    this.stage.height(height);
                    this.stage.draw();

                    const image = new Konva.Image({
                        x: this.stage.width() / 2,
                        y: this.stage.height() / 2,
                        image: imgObj,
                        width: width,
                        height: height,
                        offsetX: width / 2,
                        offsetY: height / 2,
                    });

                    this.imageLayer.add(image);
                    this.stage.add(this.imageLayer);
                    this.imageLayer.moveToBottom();
                };

                imgObj.src = f.target.result;
            };

            fileReader.readAsDataURL(file);
        },

        placePin() {
            if (this.pinMode) {
                return;
            }

            this.pinLayer.moveToTop();
            this.pinMode = true;
            this.drawMode = false;

            if (this.pinPlaced) {
                this.pinImg.draggable(true);
                this.trPin.attachTo(this.pinImg);
                this.stage.draw();
                return;
            }

            this.pinImg = new Konva.Group({
                draggable: true,
                x: this.stage.getWidth() / 2,
                y: this.stage.getHeight() / 2,
                width: 100,
                height: 98,
                offsetX: 50,
                offsetY: 49,
            });

            const pinRect = new Konva.Rect({
                x: 0,
                y: 0,
                width: 100,
                height: 98,
            });

            const pinSVG = new Konva.Path({
                data: `
M90.75 20.662l5.788-9.915a1.862 1.862 0 0 0 0-2.063 2.153 2.153 0 0 0-1.647-.832H76.692V2.063C76.692.816 75.86 0 73.797
0H43.636a1.947 1.947 0 0 0-2.063 2.063v74.796l-3.31 1.647c-.416.416-1.248 0-1.648-.415L26.7 66.928a5.655 5.655 0 0
0-4.958-1.647 5.607 5.607 0 0 0-4.541 2.894L.249 96.274a2.788 2.788 0 0 0 .831 3.31c.416 0 .832.416 1.248.416a2.1 2.1 0
0 0 1.647-.832L20.91 71.07c.416-.416.831-.832 1.247-.832a1.528 1.528 0 0 1 1.248.416l9.915 11.163a.407.407 0 0 1
.416.416l-5.39 2.894c-1.647-.416-2.063.832-1.647 2.063a2.28 2.28 0 0 0 2.063
1.248h.832l13.641-6.605c.416-.416.832-.416.832-1.248l19.83-9.915c.416-.416 1.248 0 1.647.416l21.478 27.667a2.496 2.496 0
0 0 3.31.415 2.467 2.467 0 0 0 1.248-3.726L70.087 68.175a6.222 6.222 0 0 0-7.852-1.647l-17.368
8.684v-48.36h18.6v5.373a1.947 1.947 0 0 0 2.062 2.063h28.099a2.183 2.183 0 0 0 1.647-1.248 1.862 1.862 0 0 0
0-2.063zM46.53 4.542h25.62v17.767H46.53V4.542zm22.726 25.204V26.85h5.373a1.947 1.947 0 0 0
2.063-2.063V12.394h14.057l-4.542 7.852c-.415.416-.415 1.248.416 2.063l4.542 7.853h-21.91z`,
                fill: '#ffffff',
                x: 0,
                y: 0,
                width: 100,
                height: 98,
                shadowEnabled: true,
                shadowBlur: 10,
                shadowOffset: {
                    x: 2,
                    y: 2,
                },
                shadowColor: '#000000',
            });

            this.pinImg.add(pinRect);
            this.pinImg.add(pinSVG);

            this.trPin = new Konva.Transformer({
                resizeEnabled: true,
                keepRatio: true,
                rotateEnabled: false,
                anchorSize: 20,
                enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
                boundBoxFunc: (oldBoundBox, newBoundBox) => {
                    if (newBoundBox.width > this.stage.width() || newBoundBox.height > this.stage.height()) {
                        return oldBoundBox;
                    }

                    return newBoundBox;
                },
            });

            this.pinLayer.add(this.trPin);
            this.pinLayer.add(this.pinImg);
            this.trPin.attachTo(this.pinImg);
            this.stage.add(this.pinLayer);
            this.pinPlaced = true;
        },

        onDrawMousedown() {
            this.isPaint = true;
            this.lastPointerPosition = this.stage.getPointerPosition();
        },

        onDrawMouseup() {
            this.isPaint = false;
        },

        onDrawMousemove() {
            if (!this.drawMode || !this.isPaint) {
                return;
            }

            this.drawContext.globalCompositeOperation = 'source-over';
            this.drawContext.beginPath();

            let localPos = {
                x: this.lastPointerPosition.x - this.drawImage.x(),
                y: this.lastPointerPosition.y - this.drawImage.y(),
            };

            this.drawContext.moveTo(localPos.x, localPos.y);
            const pos = this.stage.getPointerPosition();
            localPos = {
                x: pos.x - this.drawImage.x(),
                y: pos.y - this.drawImage.y(),
            };

            this.drawContext.lineTo(localPos.x, localPos.y);
            this.drawContext.closePath();
            this.drawContext.stroke();

            this.lastPointerPosition = pos;
            this.drawLayer.batchDraw();
        },

        draw() {
            if (this.drawMode) {
                return;
            }

            this.drawLayer.moveToTop();
            this.drawMode = true;
            this.pinMode = false;

            if (this.pinPlaced) {
                this.trPin.detach(this.pinImg);
                this.pinImg.draggable(false);
                this.stage.draw();
            }

            if (this.drawInit) {
                return;
            }

            this.drawInit = true;

            this.drawCanvas = document.createElement('canvas');
            this.drawCanvas.width = this.stage.width();
            this.drawCanvas.height = this.stage.height();

            this.drawImage = new Konva.Image({
                image: this.drawCanvas,
                width: this.stage.width(),
                height: this.stage.height(),
                scale: this.originalImgWidth / this.stage.width(),
            });

            this.drawLayer.add(this.drawImage);
            this.stage.draw();

            this.drawContext = this.drawCanvas.getContext('2d');
            this.drawContext.strokeStyle = this.selectedColor;
            this.drawContext.lineJoin = 'round';
            this.drawContext.lineWidth = 5;

            this.isPaint = false;
            this.lastPointerPosition = null;

            this.bindDrawEvents();
        },

        bindDrawEvents() {
            this.drawImage.addEventListener('mousedown touchstart', this.onDrawMousedown.bind(this));
            this.stage.addEventListener('mouseup mouseleave touchend', this.onDrawMouseup.bind(this));
            this.stage.addEventListener('mousemove touchmove', this.onDrawMousemove.bind(this));
        },

        unbindDrawEvents() {
            this.drawImage.removeEventListener('mousedown touchstart', this.onDrawMousedown.bind(this));
            this.stage.removeEventListener('mouseup mouseleave touchend', this.onDrawMouseup.bind(this));
            this.stage.removeEventListener('mousemove touchmove', this.onDrawMousemove.bind(this));
        },

        changeColor(color) {
            this.selectedColor = color;
            this.drawContext.strokeStyle = color;
        },

        save() {
            if (this.pinPlaced) {
                this.trPin.detach(this.pinImg);
            }

            const imageDataURL = this.stage.toDataURL({
                mimeType: 'image/jpeg',
                quality: 1,
                pixelRatio: this.originalImgWidth / this.stage.width(),
            });

            const file = dataURItoBlob(imageDataURL);

            this.$eventHub.$emit('image-editor-upload', file);
        },
    },
};
</script>

<style lang="scss">
.image-upload-editor--shadow {
    .image-upload-editor__button-inner {
        margin: 20px;
        box-shadow: $boxShadow-bottom;
    }
}

.image-upload-editor__results {
    background: $color-white;
    padding: 6px 20px;
    display: flex;
    justify-content: space-between;

    .image-upload-editor--inline & {
        padding: 0 0 4px 0;
    }
}

.image-upload-editor__button-hidden {
    .image-upload-editor__button-inner {
        margin: 0;
        box-shadow: none;
    }

    .image-upload .progress-button {
        display: none;
    }
}

.image-upload-editor__thumbnail {
    flex: 0 0 64px;
}

.image-upload-editor__change-button {
    padding-left: 20px;
    flex: 1 1;
    text-align: left;
}

.image-upload-editor__delete-button {
    flex: 0 0 20px;
}

.image-upload-editor__editor {
    position: fixed;
    top: var(--view-top-safe-area);
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000000; // fix impersonation
    display: flex;
    flex-flow: column nowrap;
    background-color: $color-white;
}

.image-upload-editor__stage-container {
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    background-color: #000;
    overflow: hidden;
    position: relative;
}

.image-upload-editor__stage-actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 20px 20px 10px;
}

.image-upload-editor__stage-actions {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.image-upload-editor__stage-actions-icon {
    filter: drop-shadow(1px 1px 2px #000);

    .f-base,
    .f-highlight {
        fill: $color-white;
    }
}

.image-upload-editor__stage-actions-icon--active {
    .f-base,
    .f-highlight {
        fill: $color-red;
    }
}

.image-upload-editor__stage-draw-colors {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.image-upload-editor__stage-draw-color {
    border: 2px solid #fff;
    background-image: none;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    margin: 0 10px;
}

.image-upload-editor__stage-draw-color--active {
    border-color: #f00;
    box-shadow: inset 0 0 15px 0 rgba(#000, 0.4);
}
</style>

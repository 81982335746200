<template>
    <Flyout :active="true" size="small" no-header>
        <ConfirmationScreen @close="$emit('close')">
            <SmsSentIcon slot="icon" />

            <div slot="content">
                <Headline :level="3">
                    {{ headline }}
                </Headline>

                <Words block>
                    {{ description }}
                </Words>
            </div>
        </ConfirmationScreen>

        <ButtonGroup slot="bottom">
            <Button type="submit" primary @click="$emit('close')">
                {{ buttonText }}
            </Button>
        </ButtonGroup>
    </Flyout>
</template>
<script>
import Button from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import ConfirmationScreen from '@/components/Layout/ConfirmationScreen';
import Flyout from '@/components/Layout/Flyout';
import Words from '@/components/Typography/Words';

import SmsSentIcon from '@/assets/icons/confirmation/sms-sent.svg';

export default {
    name: 'MessageConfirmFlyout',

    components: {
        Button,
        ButtonGroup,
        ConfirmationScreen,
        Flyout,
        Words,

        SmsSentIcon,
    },

    props: {
        buttonText: {
            type: String,
            required: true,
        },
        headline: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
    },
};
</script>

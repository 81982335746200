import _isEmpty from 'lodash/isEmpty';

export default {
    computed: {
        hasAddressLocation() {
            return !_isEmpty(this.form?.address) && !_isEmpty(this.form?.location);
        },
        hasName() {
            return !_isEmpty(this.form?.name);
        },
    },
};

import { useMutation } from '@tanstack/react-query';
import { archiveAdminConstructionProject, archiveConstructionProject, ConstructionProjectStatus, } from '@/constructionProjects/api/projects';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { queryClient } from '@/reactBridge/queryClient';
import { CONSTRUCTION_PROJECT_LIST_ROUTE } from '@/constructionProjects/constants';
import Toaster from '@/services/Toaster';
import { useTranslation } from 'react-i18next';
import { constructionProjectKeys } from '@/constructionProjects/queries';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
export function useConstructionProjectClientArchive(constructionProjectId, onSuccessHandler) {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    return useMutation({
        mutationFn: () => archiveConstructionProject(constructionProjectId),
        onSuccess: async () => {
            AnalyticsService.trackEvent('construction_project', {
                step: 'construction_project_archived',
                constructionProjectId,
            });
            queryClient.invalidateQueries(constructionProjectKeys.list());
            await vueRouter.push({
                name: CONSTRUCTION_PROJECT_LIST_ROUTE,
                query: { activeTab: ConstructionProjectStatus.Archived },
            });
            if (onSuccessHandler) {
                await onSuccessHandler();
            }
            Toaster.success(t('pages.constructionProject.projectSettings.archive.onSuccess'));
        },
        onError: () => {
            return Toaster.error(t('pages.constructionProject.projectSettings.archive.onError'));
        },
    });
}
export function useConstructionProjectAdminArchive(constructionProjectId, onSuccessHandler) {
    const { t } = useTranslation();
    const { vueRouter } = useVueRouter();
    return useMutation({
        mutationFn: () => archiveAdminConstructionProject(constructionProjectId),
        onSuccess: async () => {
            queryClient.invalidateQueries(constructionProjectKeys.list());
            await vueRouter.push({
                name: CONSTRUCTION_PROJECT_LIST_ROUTE,
                query: { activeTab: ConstructionProjectStatus.Archived },
            });
            if (onSuccessHandler) {
                await onSuccessHandler();
            }
            Toaster.success(t('pages.constructionProject.projectSettings.archive.onSuccess'));
        },
        onError: () => {
            return Toaster.error(t('pages.constructionProject.projectSettings.archive.onError'));
        },
    });
}

// Validation mixin for container components
// ---------------------------------------------------
import flatten, { unflatten } from 'flat';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _last from 'lodash/last';
import _trim from 'lodash/trim';
import { scrollToElement } from '@/services/utils/dom';

import { mapGetters } from 'vuex';

/**
 * Parse server validation
 *
 * @param {object} data
 * @param {object} mapping
 * @return {object}
 */
export function parseServerValidation(data, mapping) {
    const errors = {};
    const flatData = flatten(data);

    Object.keys(mapping).forEach(to => {
        let fromPatterns = mapping[to];
        if (!Array.isArray(fromPatterns)) {
            fromPatterns = [fromPatterns];
        }

        fromPatterns.forEach(from => {
            const fromRegex = new RegExp(`${from.replace(/\./g, '\\.').replace(/\*/g, '(.*)')}(\\..*)?`);

            let toIndex = 0;
            const toRegex = to.replace(/\*/g, () => {
                toIndex++;
                return `$${toIndex}`;
            });

            Object.keys(flatData).forEach(key => {
                if (!fromRegex.test(key)) return;

                const newKey = key.replace(fromRegex, toRegex);
                const subKey = _trim(_last(key.match(fromRegex)), '.');

                if (!errors[newKey]) {
                    errors[newKey] = [];
                }

                const newErrors = subKey
                    ? Object.values(unflatten({ [subKey]: flatData[key] }))
                    : Object.values(flatData[key]);

                errors[newKey] = [...errors[newKey], ...newErrors];
            });
        });
    });

    return errors;
}

export default {
    props: {
        submitted: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            errors: {},
        };
    },
    computed: {
        ...mapGetters('validation', ['firstError', 'componentErrors']),
    },
    methods: {
        checkDirty(current, initial) {
            return !_isEqual(current, initial);
        },
        /**
         * Validate configured rules against the current data store
         * passive: do not apply errors to the data store
         * validationRules: validate against provided set of rules
         */
        isValid(passive = false, validationRules = null, extendStore = false) {
            const errors = this.validateState(validationRules);

            if (!passive) {
                if (extendStore) {
                    this.errors = { ...this.errors, ...errors };
                } else {
                    this.errors = errors;
                }
                if (Object.keys(this.errors).length > 0) {
                    this.focusTopError();
                }
            }
            return Object.keys(errors).length === 0;
        },

        /**
         * Validate configured rules and return errors object
         * @param {*} validationRules
         */
        validateState(validationRules = null) {
            const dataKeys = validationRules || this.validationRules || {};

            // collect all errors
            const errors = {};
            Object.keys(dataKeys).forEach(key => {
                const rules = dataKeys[key];
                const messages = Object.keys(rules)
                    .map(ruleName => {
                        const ruleCallback = rules[ruleName];
                        const value = _get(this, key);
                        return ruleCallback.call(this, value);
                    })
                    .filter(result => typeof result !== 'boolean');

                if (messages.length) {
                    errors[key] = messages;
                }
            });

            return errors;
        },

        /**
         * Parital validation, will apply errors to a given key
         * @param {*} key
         */
        partialValidation(key) {
            const rules = this.validationRules;
            if (rules && rules[key]) {
                const errors = this.validateState({
                    [key]: rules[key],
                });

                if (errors[key]) {
                    this.$set(this.errors, key, errors[key]);
                } else {
                    const newErrors = { ...this.errors };
                    delete newErrors[key];
                    this.$set(this, 'errors', newErrors);
                }
            }
        },

        /**
         * Clear error bag
         */
        clearValidation() {
            this.errors = {};
        },

        clearSingleValidation(key) {
            delete this.errors[key];
        },

        /**
         * @param {any} data
         * @param {object} mapping
         */
        parseServerValidation(data, mapping) {
            const errors = parseServerValidation(data, mapping);
            this.errors = errors;

            if (Object.keys(errors).length > 0) {
                this.focusTopError();
            }
        },

        /**
         * Check if there are any errors
         * filter by key
         * @param {string} key
         * @param {boolean} show - if false, errors will not be returned. i.e. Used to display only when submitted
         */
        hasErrors(key = false, show = true) {
            if (!show) {
                return false;
            }
            const { errors } = this;

            if (/\.\*$/.test(key)) {
                key = key.slice(0, -1);

                const checkErrors = Object.keys(errors).filter(errorKey => {
                    return errorKey.startsWith(key);
                });

                return checkErrors.length > 0;
            }

            if (key) {
                return errors[key] && errors[key].length > 0;
            }

            return Object.keys(errors).length > 0;
        },

        /**
         * Get an error by key
         * @param {string} key
         */
        getErrors(key) {
            return this.errors[key] || [];
        },

        /**
         * Get all errors
         */
        getAllErrors() {
            return this.errors;
        },

        /**
         * Get first error for a key
         * @param {string} key
         * @param {boolean} show - if false, errors will not be returned. i.e. Used to display only when submitted
         */
        getError(key, show = true) {
            if (!show) {
                return null;
            }

            const errors = this.getErrors(key);
            return errors.length > 0 ? errors[0] : null;
        },

        focusTopError() {
            this.$nextTick(() => {
                const firstError = this.$el.querySelector(
                    '.text-field--invalid, .input-field--invalid, .select-input--invalid, .fake-picker--invalid, .time-picker--invalid, .multiselect--invalid, [class*="--invalid"]'
                );

                if (!firstError) {
                    return;
                }

                if (firstError.classList.contains('input-field--invalid')) {
                    firstError.querySelector('input').focus();
                } else if (firstError.classList.contains('select-input--invalid')) {
                    firstError.querySelector('select').focus();
                } else if (firstError.classList.contains('multiselect--invalid')) {
                    firstError.querySelector('button').focus();
                } else {
                    scrollToElement(firstError);
                }
            });
        },

        hasGroupErrors(groupName) {
            const groupRules = this.validationGroups[groupName] || {};
            return Object.keys(this.errors).some(key => groupRules.includes(key));
        },

        isGroupValidAndGetErrors(groupName) {
            const groupRules = this.validationGroups[groupName] || {};
            const validationResults = [];
            groupRules.forEach((ruleName, index) => {
                validationResults.push(this.isValid(false, { [ruleName]: this.validationRules[ruleName] }, !!index));
            });
            return !validationResults.includes(false);
        },

        isGroupValid(groupName) {
            const groupRules = this.validationGroups[groupName] || {};
            return groupRules.every(ruleName => this.isValid(true, { [ruleName]: this.validationRules[ruleName] }));
        },
    },
};

import { SfPdfIcon } from '../icons/SfPdfIcon';
import { SfEditIcon } from '../icons/SfEditIcon';
import { SfDeleteIcon } from '../icons/SfDeleteIcon';
import clsx from 'clsx';
function PreviewLink({ file }) {
    if (!file) {
        return null;
    }
    if (file instanceof File) {
        return (<a href={URL.createObjectURL(file)} target="_blank" rel="noreferrer" className="font-copy-md-strong cursor-pointer">
                {file.name}&nbsp;&nbsp;›
            </a>);
    }
    return (<a href={file.url} target="_blank" className="font-copy-md-strong cursor-pointer" rel="noreferrer">
            {file.fileName}&nbsp;&nbsp;›
        </a>);
}
export default function FilePreview({ className, file, editAction, removeAction, disabled }) {
    return (<div className={clsx('file card flex items-center justify-between', disabled && 'pointer-events-none bg-surface-disabled text-disabled', className)}>
            <div className="flex items-center gap-4 break-all">
                <SfPdfIcon size="lg"/>
                <PreviewLink file={file}/>
            </div>
            <div className="flex items-center">
                <a onClick={editAction} className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full border-0 hover:bg-surface-hovered">
                    <SfEditIcon size="sm"/>
                </a>
                <a onClick={removeAction} className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full border-0 hover:bg-surface-hovered">
                    <SfDeleteIcon size="sm"/>
                </a>
            </div>
        </div>);
}
